import { useState, useContext, useEffect } from "react"

import { Link, useHistory } from "react-router-dom"

import search from "../../../assets/images/search.svg"
import arrowUpDown from "../../../assets/images/backendAdmin/arrow-up-down.svg"
import riarrownext from "../../../assets/images/right-arrow-next.svg"
import tabImgInventory from "../../../assets/images/tab-img-inventory.png"
import tableFilter from "../../../assets/images/table-filter.svg"
import arrowUp from "../../../assets/images/arrow-up.svg"
import filterSvg from "../../../assets/images/filter-svg.svg"
import filterArrowSvg from "../../../assets/images/filter-arrow-svg.svg"

import tabSlide1 from "../../../assets/images/tab-Slide1.png"
import TopBar from "../schools/components/topBar"
import AppContextObject from "../../../common/context/common"

import OnboardingBackBtn from "../schools/components/onboardingBackBtn"
import InventoryCard from "../schools/updateDetails/inventory/inventoryCard"
import Pagination from "../../../components/addon/pagination"
import CampaignApprovalModal from "../../../components/addon/campaignApprovalModal"
import ThankyouModal from "../../../components/addon/thankyouModal"
import RejectBrandRequestModal from "../../../components/addon/rejectBrandRequestModal"
import backBtn from "../../../assets/images/back-arrow.svg";
import DataContext from "../../../service/contextProvider"

import { PublicContextInitiator } from "../../apis/publicContextInitiator"

import { Steps } from "intro.js-react"
import { formatDateToDDMMMYY, isObjectEmpty } from "../../../service/helper"

import toast from "react-hot-toast"
import SchoolTable from "./components/SchoolTable"
import CategoryFilterSchool from "./components/CategoryFilterSchool"
import SearchInput from "./components/SearchInput"
import config from "../../../service/config"
import SingleSelect from "../../../components/formFields/singleSelect/singleSelect"
import Loader from "../../../components/addon/loader"
import BackendTopBar from "./components/backendTopBar"
import SchoolTableReject from "./components/SchoolTableReject"

var campaignRejectGlobalVar = 1


const CampaignRejectedBySchool = () => {
    const parentData = useContext<any>(DataContext)
    const history = useHistory()
    const context = useContext(AppContextObject)
    const publicContextInitiator = new PublicContextInitiator(context)
    const [pageLimit, setPageLimit] = useState(10)
    const [overlay, setOverlay] = useState(false)
    const [allSchoolList, setSchoolList] = useState([]);
    const [totalPageCount, setTotalPageCount] = useState<any>([0]);
    const [totalPage, setTotalPage] = useState([0]);
    const [currentPage, setCurrentpage] = useState(1);
    const [clearSearchIcon, setClearSearchIcon] = useState(false);
    const [searchInpuOpen, setSearchInpuOpen] = useState(false);
    const [searchKeys, setSearchKeys] = useState("");
    const [category, setCategory] = useState([]);
    const [inventoryStatus, setInventoryStatus] = useState([]);
    const [location, setLocation] = useState('');
    const [selectedTab, setSelectedTab] = useState("all")
    const [alphabaticOrder, setAlphabaticOrder] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [city, setCity] = useState({name:''});
    const[rejectedcampaignSchools,setRejectedcampaignSchools]=useState(0)
    const [locationByCities,setLocationByCities] = useState([])
    const [sortingData, setSortingData] = useState({id: -1})
    

    useEffect(() => {
        getAllSchoolsList();
    }, [currentPage, searchKeys])

    const getAllSchoolsList = async () => {
        setIsLoading(true)
        // let publicContextInitiator = new PublicContextInitiator(context)
        var localVar = Math.random()
        campaignRejectGlobalVar = localVar
        let resp = await context.apis[context.user.role].getSchoolsRejectingMultipleCampaigns(
            context.validator, {searchValue: searchKeys === "" ? null : searchKeys, campaignStatus: "rejected"}, { limit: pageLimit, page: currentPage, withCount: true, "sort": { "_id": -1 } }
        )
        console.log('getSchoolsRejectingMultipleCampaigns', resp)

        if (resp && resp.done) {
            if(localVar === campaignRejectGlobalVar){
                setSchoolList(resp.response.rows);
                setRejectedcampaignSchools(resp.response.count);
                let total = Math.ceil(resp.response.count / pageLimit);
                console.log(total);
                setTotalPageCount(resp.response.count);
                let arr = []
                for (let i = 0; i < total; i++) {
                    arr.push(i);
                }
                setTotalPage([...arr])
            }
        }
        setIsLoading(false);
    }

    const onPageChange = (page) => {
        setCurrentpage(page)
    }
    const onNextClick = () => {
        setCurrentpage(prev => prev + 1)
    }
    const onPrevClick = () => {
        setCurrentpage(prev => prev - 1)
    }

    const changeActiveTab = (tab) => {
        if(tab === selectedTab){
            return
        }
        setSelectedTab(tab)
        setSearchKeys("")
        setInventoryStatus([])
    }

    return (
        <>
            <div className={`ovelap ${overlay === true ? "active" : ""}`}></div>
            <div className="col-xl-10 col-lg-12 col-md-12 col-xs-12">
                <div className="rightPart noBack">
                    <div className="row">
                        <BackendTopBar from='Multiple Campaigns Rejected' redirectPage={true}/>
                        {/* <Link to="/backend/backend-details/school-list" className="backbtn dblock">
                            <h1><img src={backBtn} />                                
                            </h1>

                        </Link>  */}
                        <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-20 mb-10 campainRejectFilter">
                            <h2 className="htx2 clearfix">Total multiple campaigns rejected schools - {rejectedcampaignSchools}</h2>
                            <div className="inventorySearch schoolFilter">
                                    <SearchInput
                                        searchInpuOpen={searchInpuOpen} //true/false
                                        clearSearchIcon={clearSearchIcon} //true/false
                                        setSearchInpuOpen={setSearchInpuOpen} //function=>true/false
                                        setSearchKeys={setSearchKeys} //function=>string
                                        searchKeys={searchKeys} //string
                                        setClearSearchIcon={setClearSearchIcon}//function=>true/false
                                        palaceholder='School name'
                                        setCurrentpage={setCurrentpage}
                                    />
                            </div>
                        </div>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                            <div className="inventory-tableTabs">                                
                                <SchoolTableReject
                                    allSchoolList={allSchoolList}
                                    currentPage={currentPage}
                                    totalPage={totalPage}
                                    onPageChange={onPageChange}
                                    onNextClick={onNextClick}
                                    onPrevClick={onPrevClick}
                                    category={category}
                                    setCategory={setCategory}
                                    setInventoryStatus={setInventoryStatus}
                                    alphabaticOrder={alphabaticOrder}
                                    setAlphabaticOrder={setAlphabaticOrder}
                                    sortingData={sortingData}
                                    setSortingData={setSortingData}
                                    isLoading={isLoading}
                                    inventoryStatus={inventoryStatus}
                                    city={city}
                                    setLocation={setLocation}
                                    location={location}
                                    locationByCities={locationByCities}
                                    count={rejectedcampaignSchools}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}


export default CampaignRejectedBySchool