import { useState, useContext, useEffect } from "react"
import useClickOutside from '../../../service/useClickOutside';
import AppContextObject from "../../../common/context/common"
import OnboardingBackBtn from "../schools/components/onboardingBackBtn"
import CampaignApprovalModal from "../../../components/addon/campaignApprovalModal"
import ThankyouModal from "../../../components/addon/thankyouModal"
import RejectBrandRequestModal from "../../../components/addon/rejectBrandRequestModal"
import DataContext from "../../../service/contextProvider"
import { PublicContextInitiator } from "../../apis/publicContextInitiator"
import FOSTable from "./components/FOSTable";
import NewFOS from "./components/NewFOS";
import EditFOS from "./components/EditFOS";
import SearchInput from "./components/SearchInput"
import UserTable from "./components/UserTable"
import NewUser from "./components/NewUser"
import EditUSer from "./components/EditUser";
import BackendTopBar from "./components/backendTopBar";
import BackendBackBtn from "./components/backendBackBtn";
import SuccessUserCreateModal from "../../../components/addon/SuccessUserCreateModal";

var userListGlobalVar = 1

const UserList = () => {
    const context = useContext(AppContextObject)
    const [isCustomInventoryEdit, setIsCustomInventoryEdit] = useState(false)
    const [overlay, setOverlay] = useState(false)
    const [searchKeys, setSearchKeys] = useState("");
    const [searchInpuOpen, setSearchInpuOpen] = useState(false);
    const [filterCity, setFilterCity] = useState([])
    const [allUsers, setAllUsers] = useState([]);
    const [newUserCreated, setNewUserCreated] = useState(true);
    const [totalPage, setTotalPage] = useState([0])
    const [currentPage, setCurrentpage] = useState(1)
    const [totalPageCount, setTotalPageCount] = useState<any>([0])
    const [role, setRole] = useState('all');
    const [isFOSEdit, setIsFOSEdit] = useState(false);
    const [userId, setUserId] = useState("");
    const [cityFilterModal, setCityFilterModal] = useState(false);
    const [clearSearchIcon, setClearSearchIcon] = useState(false);
    const [roleCount, setRoleCount] = useState<any>({});
    const [loading, setLoading] = useState(false);    
    const [userCreateSuccessfully, setUserCreateSuccessfully] = useState(false);
    const [userCreateedEmail, setUserCreatedEmail] = useState("");

    const addNewInventory = () => {
        setIsCustomInventoryEdit(true)
        setOverlay(true)
    }

    const hideModal = () => {
        setIsCustomInventoryEdit(false);
        setOverlay(false);
    }
    const hideEditModal = () => {
        setIsFOSEdit(false);
        setOverlay(false);
    }
    useEffect(() => {
        getAllUsers();
    }, [newUserCreated, currentPage, role, searchKeys, filterCity])

    const getAllUsers = async () => {
        setLoading(true);
        let resp;
        var localVar = Math.random()
        userListGlobalVar = localVar
        if (role === 'all') {
            resp = await context.apis.public.getAllUsersForBackend(
                context.validator, { search: searchKeys, city: filterCity }, { limit: 10, page: currentPage, withCount: true, "sort": { "_id": -1 } }
            )
        } else {
            resp = await context.apis.public.getAllUsersForBackend(
                context.validator, { search: searchKeys, city: filterCity, role: role }, { limit: 10, page: currentPage, withCount: true, "sort": { "_id": -1 } }
            )
        }

        console.log('getAllUsersForBackend', resp)

        if (resp && resp.done) {
            if(userListGlobalVar === localVar){
                setRoleCount(resp.allCounts);
                setAllUsers(resp.response.rows)
                let total = Math.ceil(resp.response.count / 10);
                setTotalPageCount(resp.response.count);
                let arr = []
                for (let i = 0; i < total; i++) {
                    arr.push(i)
                }
                setTotalPage([...arr])
            }
        }
        setLoading(false);
    }
    const onPageChange = (page) => {
        setCurrentpage(page)
    }
    const onNextClick = () => {
        setCurrentpage(prev => prev + 1)
    }
    const onPrevClick = () => {
        setCurrentpage(prev => prev - 1)
    }

    const sortByRole = (role) => {
        if (role !== 'all') {
            setRole(role);
            setCurrentpage(1);
        }
        else {
            setRole('all');
            setCurrentpage(1);
        }
    }
    const cityFilter = () => {
        setCityFilterModal(!cityFilterModal)
    }



    return (
        <>
            <div className={`ovelap ${overlay === true ? "active" : ""}`}></div>
            <div className="col-xl-10 col-lg-12 col-md-12 col-xs-12">
                <div className="rightPart noBack">
                    <div className="row">
                        <BackendTopBar />
                        <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-10 mtt-30">
                            <h2 className="htx2 clearfix">Full List of Users</h2>
                        </div>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                            <div className="inventory-tableTabs">
                                <ul className="tabsinventory">
                                    <li onClick={() => sortByRole('all')}><a href="javascript:void(0);" className={role === 'all' ? "active" : ""}>All ({roleCount.all})</a></li>
                                    <li onClick={() => sortByRole('backendOperations')}><a href="javascript:void(0);" className={role === 'backendOperations' ? "active" : ""}>Ops ({roleCount.ops})</a></li>
                                    <li onClick={() => sortByRole('backendSales')}><a href="javascript:void(0);" className={role === 'backendSales' ? "active" : ""}>Sales ({roleCount.sales})</a></li>
                                    <li onClick={() => sortByRole('backendFinance')}><a href="javascript:void(0);" className={role === 'backendFinance' ? "active" : ""}>Finance ({roleCount.finance})</a></li>
                                    <li onClick={() => sortByRole('backendTech')}><a href="javascript:void(0);" className={role === 'backendTech' ? "active" : ""}>Tech ({roleCount.tech})</a></li>
                                    <li onClick={() => sortByRole('backendAdmin')}><a href="javascript:void(0);" className={role === 'backendAdmin' ? "active" : ""}>Super Admin ({roleCount.backendAdmin})</a></li>
                                </ul>
                                <div className="inventorySearch schoolFilter">
                                    <SearchInput
                                        searchInpuOpen={searchInpuOpen} //true/false
                                        clearSearchIcon={clearSearchIcon} //true/false
                                        setSearchInpuOpen={setSearchInpuOpen} //function=>true/false
                                        setSearchKeys={setSearchKeys} //function=>string
                                        searchKeys={searchKeys} //string
                                        setClearSearchIcon={setClearSearchIcon}//function=>true/false
                                        palaceholder='Full Name'
                                        setCurrentpage={setCurrentpage}
                                    />

                                    <a href="javascript:void(0)" className="btn btn-orange spaceL" onClick={addNewInventory}>new user</a>

                                </div>
                                <UserTable
                                    dataList={allUsers}
                                    setCurrentpage={setCurrentpage}
                                    currentPage={currentPage}
                                    totalPage={totalPage}
                                    onPageChange={onPageChange}
                                    onNextClick={onNextClick}
                                    onPrevClick={onPrevClick}
                                    cityFilter={cityFilter}
                                    setIsFOSEdit={setIsFOSEdit}
                                    setUserId={setUserId}
                                    cityFilterModal={cityFilterModal}
                                    setOverlay={setOverlay}
                                    setFilterCity={setFilterCity}
                                    filterCity={filterCity}
                                    setCityFilterModal={setCityFilterModal}
                                    tabRole={role}
                                    loading={loading}
                                    totalPageCount={totalPageCount}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {userCreateedEmail!=="" && <SuccessUserCreateModal
                userCreateSuccessfully={userCreateSuccessfully}
                setOverlay={setOverlay}
                setUserCreateSuccessfully={setUserCreateSuccessfully}
                setNewUserCreated={setNewUserCreated}
                newUserCreated={newUserCreated}
                userCreateedEmail={userCreateedEmail}
            />}
            <NewUser
                isCustomInventoryEdit={isCustomInventoryEdit}
                hideModal={hideModal}
                setUserCreateSuccessfully={setUserCreateSuccessfully}
                setOverlay={setOverlay}                
                setUserCreatedEmail={setUserCreatedEmail}
            />
            <EditUSer
                isFOSEdit={isFOSEdit}
                hideEditModal={hideEditModal}
                setNewUserCreated={setNewUserCreated}
                newUserCreated={newUserCreated}
                userId={userId}
                setOverlay={setOverlay}
            />
        </>
    )
}


export default UserList;
