import React, { useEffect, useState, forwardRef } from 'react'
import { Bar } from 'react-chartjs-2';

interface Props {
    data?: any,
    categories?: any,
    totalCampaigns?:any,
}

const HorizentalBarChartBackend = forwardRef(({
    data, categories,totalCampaigns
}: Props, ref) => {
    const [chartData, setChartData] = useState()
    useEffect(() => {
        setChartData(data)
    }, [data])
    const labelsData=totalCampaigns && totalCampaigns.filter(item=>item.count!==0).map(totalCampaign=>totalCampaign.category);
    const dataSet=totalCampaigns && totalCampaigns.filter(item=>item.count!==0).map(totalCampaign=>totalCampaign.count);
    // console.log(labelsData,dataSet)
    let data1 = {
        labels: labelsData,
        datasets: [
            {
                data: dataSet,
                // boarderWidth: 1
                backgroundColor: "#F19DFF",
                borderColor: "#F19DFF",
                tension: 0,
                barThickness: 4
            }],
    }

    return (
        <>
            {data1 &&
                <Bar
                    data={data1}
                    // options={{
                    //     indexAxis: 'y',

                    // }}
                    options={{
                        indexAxis: 'y',
                        scales: {
                            y: {
                                beginAtZero: true,
                                title: {
                                    padding: 20,
                                    display: true,
                                    text: "Brand Category",
                                    font: {
                                        family: 'Arial, Helvetica, sans-serif',
                                        size: 13,
                                      }
                                },
                                ticks: {
                                    autoSkip: false,
                                }
                            },
                            x: {
                                beginAtZero: true,
                                title: {
                                    padding: 20,
                                    display: true,
                                    text: "No. of Campaigns",
                                    font: {
                                        family: 'Arial, Helvetica, sans-serif',
                                        size: 13,
                                      }
                                },
                                ticks: {
                                    autoSkip: false,
                                    stepSize: 1
                                } 
                            }
                        },
                        plugins: {
                            legend: {
                                display: false
                            }
                        }

                    }}
                    height="100"
                />
            }
        </>
    )
})

export default HorizentalBarChartBackend
