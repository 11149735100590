import { useState, useEffect, useContext } from "react"

import search from "../../../assets/images/search.svg"
import logo from "../../../assets/images/logo.png"
import menu from "../../../assets/images/menu.svg"
import OnboardingBackBtn  from "../schools/components/onboardingBackBtn"
import waveBoot from "../../../assets/images/wavebot.png"
import AppContextObject from "../../../common/context/common"
import { eraseAllCookies } from "../../../service/helper"
import downArrow from "../../../assets/images/down-arrow1.svg"
// import excelJS from 'exceljs'
import * as XLSX from 'xlsx'

const SchoolListing = () => {
    const context = useContext(AppContextObject)

    const [schoolDetails, setSchoolDetails] = useState([])
    const [isApproved, setIsApproved] = useState(false)
    const [showSidemenu, setShowSidemenu] = useState(false)    
    const [searchValue, setSearchValue] = useState("")
    if (!context.apis || !context?.user?.role || !context?.socketManager.socket.connected) {
        document.location.reload()
    }
    useEffect(() => {
        getSchool();
    }, [isApproved])
    const getSchool = async () =>{
        let resp = await context.apis[context.user.role].getAllOnboardingSchools(
            context.validator,{searchValue},{sort: {_id: -1}}
        )
        if(resp && resp.done){
            setSchoolDetails(resp.response.rows)
        }
        
    }

    const resetPassword = async (userId, email, schoolId, fullName, role, schoolName, city, username) =>{
        let updatingData = {
            email: email,
            fullName: fullName,
            username: username,
            role: role,
        }

        let query = {
            _id: userId, 
            schoolId:schoolId,
            schoolName: schoolName,
            city: city
        }
        let resp = await context.apis[context.user.role].resetUserPassword(
            context.validator, query, updatingData
        )
        if(resp && resp.done){
            setIsApproved(!isApproved)
        }
    }

    const handleSearch = async (e) =>{
        setSearchValue(e.target.value)
        let resp = await context.apis[context.user.role].getAllOnboardingSchools(
            context.validator,{searchValue:e.target.value},{sort: {_id: -1}}
        )
        if(resp && resp.done){
            setSchoolDetails(resp.response.rows)
        }
    }

    const logoutUser = () =>{
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
        };

        fetch("/api/logout", requestOptions)
        .then(response => response.json())
        .then(data => {
            if(data.done){
                eraseAllCookies();
                context.socketManager.socket.disconnect();
                document.location.href = "/login"
                sessionStorage.clear()
            }
        }).catch(err=>{
            console.log(err);
        })
    }

    const toggleMenu = () => {
        setShowSidemenu(!showSidemenu)
    }

    const getExportableData = async () => {
        // const requestOptions = {
        //     method: 'POST',
        //     headers: { 'Content-Type': 'application/json' },
        // };
        // let response = await (await fetch('/api/exportSchoolData', requestOptions)).json();
        window.open(
            `/api/exportSchoolData`,
            "_blank"
        );
        
        // downloadURI(response.result.fileURL, `school-data-${response.result.month}.xlsx`);
    }

    function downloadURI(uri, name) {
        var link = document.createElement("a");
        link.download = name;
        link.href = uri;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        // delete link;
    }

    return (
        <>
            <div className={`ovelap ${showSidemenu === true ? "active" : ""}`}></div>
            <div className="main-wapper">
                <div className="container">
                    <div className="row">                    
                        <div className="col-xl-2 col-lg-3 col-md-12 col-xs-12">
                        <div className={`sliderbar updatebar ${showSidemenu === true ? "no_highlight" : ""}`}>
                        <div className="row logo between-xs bottom-xs"><img src={logo} alt="logo" /></div> 
                        <ul>
                            <li><a href="javascript:void(0);" className="active"><span><svg width="21" height="16" viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.16812 7.05759V12.4559C4.16812 12.7914 4.33324 13.1073 4.62625 13.2708C6.75938 14.4607 11.3216 15.7567 16.0095 13.2723C16.3228 13.1063 16.5093 12.7755 16.5093 12.4209V7.05759" stroke="#070707" strokeWidth="1.2"/>
                            <path d="M9.95253 1.62885L2.43441 4.43323C1.57663 4.75319 1.5641 5.96197 2.41506 6.29966L9.9334 9.28313C10.1677 9.37612 10.4286 9.37716 10.6637 9.28605L18.3608 6.30266C19.2216 5.96902 19.2091 4.74671 18.3417 4.43067L10.6444 1.62621C10.4208 1.54475 10.1755 1.54569 9.95253 1.62885Z" stroke="#070707" strokeWidth="1.2"/>
                            <path d="M18.8 6.27609V8.94256" stroke="#070707" strokeWidth="1.2" strokeLinecap="round"/>
                            </svg></span>School List</a></li>
                            <li><a href="javascript:void(0)" className="dblock" onClick={() => getExportableData()}>
                                        <img src={downArrow} />
                                        Export Recent School Data
                                    </a></li>
                            <li><a href="javascript:void(0);" onClick={logoutUser}><span><svg className="logoutsvg" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0)">
                            <path d="M0.793711 8.60133C0.790037 8.605 0.790037 8.605 0.786362 8.60868C0.77534 8.62337 0.764318 8.6344 0.756969 8.64909C0.753295 8.65277 0.753294 8.65644 0.74962 8.66012C0.742272 8.67481 0.731249 8.68951 0.7239 8.70421C0.7239 8.70788 0.720227 8.71155 0.720227 8.71155C0.712878 8.72625 0.70553 8.74095 0.698181 8.75932C0.698181 8.76299 0.698181 8.76299 0.694507 8.76667C0.687158 8.78136 0.683484 8.79974 0.676136 8.81811C0.676136 8.82178 0.676136 8.82546 0.672461 8.82546C0.668787 8.84383 0.661439 8.85852 0.661439 8.87689C0.661439 8.88424 0.661439 8.88792 0.657765 8.89527C0.654091 8.90996 0.65409 8.92466 0.650416 8.93936C0.646742 8.9614 0.646743 8.97977 0.646743 9.00182C0.646743 9.02386 0.646742 9.04223 0.650416 9.06428C0.650416 9.07898 0.654091 9.09367 0.657765 9.10837C0.657765 9.11572 0.657765 9.11939 0.661439 9.12674C0.665113 9.14511 0.668787 9.15981 0.672461 9.17818C0.672461 9.18185 0.672462 9.18553 0.676136 9.18553C0.67981 9.2039 0.687158 9.2186 0.694507 9.23697C0.694507 9.24064 0.694507 9.24064 0.698181 9.24432C0.70553 9.25901 0.712878 9.27738 0.720227 9.29208C0.720227 9.29576 0.7239 9.29943 0.7239 9.29943C0.731249 9.31413 0.738598 9.32882 0.74962 9.34352C0.753294 9.34719 0.753295 9.35087 0.756969 9.35454C0.767992 9.36924 0.77534 9.38394 0.786362 9.39496C0.790037 9.39863 0.790037 9.39863 0.793711 9.40231C0.808408 9.417 0.81943 9.43538 0.837801 9.45007L4.4716 13.0802C4.71778 13.3264 5.11827 13.3264 5.36444 13.0802C5.61061 12.834 5.61061 12.4335 5.36444 12.1874L2.80718 9.63011L12.9737 9.63011C13.3228 9.63011 13.6057 9.34719 13.6057 9.00182C13.6057 8.65277 13.3228 8.36985 12.9737 8.36985H2.80351L5.34239 5.83097C5.58857 5.58479 5.58857 5.1843 5.34239 4.93813C5.22114 4.81688 5.05948 4.75442 4.89781 4.75442C4.73615 4.75442 4.57448 4.81688 4.45323 4.93813L0.841476 8.54989C0.823105 8.57193 0.808408 8.58663 0.793711 8.60133Z" fill="black"/>
                            <path d="M13.9328 16.7397H9.22982C8.88077 16.7397 8.59785 17.0227 8.59785 17.368C8.59785 17.7171 8.88077 18 9.22982 18H13.9328C15.8177 18 17.3535 16.4642 17.3535 14.5793L17.3535 3.4207C17.3535 1.53582 15.8177 4.80184e-07 13.9328 4.80184e-07H9.30698C8.95792 4.80184e-07 8.67501 0.282916 8.67501 0.628292C8.67501 0.977343 8.95792 1.26026 9.30698 1.26026H13.9328C15.1233 1.26026 16.0933 2.23025 16.0933 3.4207V14.5793C16.0896 15.7734 15.1233 16.7397 13.9328 16.7397Z" fill="black"/>
                            </g>
                            </svg></span> Log Out</a></li>
                        </ul>
                        <img src={waveBoot} className="wavebot" alt="wave" /> </div>                           
                        </div>
                        <div className="col-xl-10 col-lg-9 col-md-12 col-xs-12">
                            <div className="rightPart noBack">
                                <div className="row">
                                <div className="col-xs-12 col-lg-12 col-md-12 col-xs-12">
                                <>
                                    <a onClick={toggleMenu} href="javascript:vid(0)" className={`hummob ${showSidemenu === true ? "no_highlight" : ""}`}>
                                        <img src={menu} />
                                    </a>
                                    {/* <Link to={url} className="backbtn">
                                        <img src={backBtn} />
                                        {btnText}
                                    </Link> */}
                                    <div className="clearfix"></div>        
                                </>
                                </div>
                                    <div className="row col-xs-8 col-lg-7 col-md-6 col-xs-12 pl middle-xs margTop">
                                    <h2 className="htx1 clearfix">Schools List</h2>
                                    </div>
                                    <div className="col-xs-4 col-lg-5 col-md-6 col-xs-12 pr end-md middle-xs margTop">
                                    <div className="searchbox">
                                        <div className="searchinput">
                                        <input name="" type="button" />
                                        <img src={search} alt="search" />
                                        <input name="" onChange={(e)=>handleSearch(e)} value={searchValue} placeholder="Search by School,Address,City" type="text" />
                                        </div>
                                         </div>
                                    </div>
                                    <div className="col-xs-12 col-lg-12 col-md-12 col-xs-12 mt-40">
                                    <h3 className="htx2 mb20">School details</h3>
                                    <div className="invetory-table-list school-list">
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                <th scope="col">School Name</th>
                                                <th scope="col">Address </th>
                                                <th scope="col">City</th>
                                                <th scope="col">Username</th>
                                                <th scope="col">Email</th>
                                                <th scope="col">Full Name</th>
                                                <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    schoolDetails && schoolDetails.length > 0 &&
                                                    schoolDetails.map((el, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>{el.schoolName}</td>
                                                                <td>{el.address}</td>
                                                                <td>
                                                                    <div>{el.city}</div>
                                                                </td>
                                                                <td>{el.username}</td>
                                                                <td>{el.email}</td>
                                                                <td>{el.fullName}</td>
                                                                <td>
                                                                    {
                                                                        el.initialApproval && el.initialApproval
                                                                        ?
                                                                        "Approved"
                                                                        :
                                                                        <a href="javascript:void(0)" 
                                                                        onClick={()=>resetPassword(el.userId, el.email, el._id, el.fullName, el.role, el.schoolName, el.city, el.username)}
                                                                        >
                                                                            Approve
                                                                        </a>
                                                                    }
                                                                    
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    </div>
                                </div>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SchoolListing
