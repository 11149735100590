import React, { useEffect, useState, forwardRef } from 'react'
import { Bar } from 'react-chartjs-2';

interface Props{
    data?: any,
    xAxisText?: any,
    yAxisText?: any,
    max?: any
}

const HorizentalBarChart = forwardRef(({
    data,
    xAxisText,
    yAxisText,
    max
}: Props, ref) => {
    const [chartData, setChartData] = useState()
    useEffect(() => {
        setChartData(data)
    }, [data])
    // let data1={
    //     labels: ["Classroom", "School Bus", "Auditorium", "Hall"],
    //     datasets: [
    //         {
    //             data: [10, 20, 5, 3],
    //             boarderWidth: 1
    //         }],
    //     options: {}
    const xAxisData = {
        beginAtZero: true,
        title: {
            display: true,
            text: xAxisText
        }
    }
    if(max){
        xAxisData["max"] = max
    }
    // }
    return (
        <>
        {data &&
            <Bar
                data={data}
                options={{
                    scales: {
                        y: {
                            beginAtZero: true,
                            title: {
                                display: true,
                                text: yAxisText
                            }
                        },
                        x: {...xAxisData}
                    },
                    indexAxis: 'y'
                }}
                height="100"
            />
        }
        </>
    )
})

export default HorizentalBarChart
