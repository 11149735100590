import React, { useState } from "react";
import filterArrowSvg from "../../../../assets/images/filter-arrow-svg.svg";
import config from "../../../../service/config";
import errorSVG from "../../../../assets/images/error.svg";
import { titleCase } from "../../../../service/helper";

const SchoolsNameCategoryFilter = ({ openShoolNameFilter, setCategory,category, alphabaticOrder, setAlphabaticOrder,city,locationByCities,
    setLocation,location, sortingData, setSortingData }) => {
    const [openCategory, setOpenCategory] = useState(false);
    const [openLocation, setOpenLocation] = useState(false);
    const [categoryType, setCategoryType] = useState('');

    const filterByNames = (type,val) => {
        // console.log(val)
        if (type==='category') {
            let array = [...category]
        if (array.includes(val)) {
            array.splice(array.indexOf(val), 1)
        }
        else {
            array.push(val)
        }
        setCategory([...array])

        }else if (type==='location') {
            let array = [...location]
        if (array.includes(val)) {
            array.splice(array.indexOf(val), 1)
        }
        else {
            array.push(val)
        }
        setLocation([...array])

        } else {
            if (val = "sortByName") {
                setSortingData({schoolName: alphabaticOrder ? -1 : 1})
                setAlphabaticOrder(!alphabaticOrder)
            }
        }
    }
    const clearAllFilter = () => {
        setCategory([]);
        setCategoryType('');
        setLocation([]);
    }
    // console.log('location===>',location)
    // let locationArray= location.map(locationss=>locationss)
    // console.log('locationArray',locationArray)
    // console.log(categoryType === 'B')
    // console.log(categoryType === 'C')
    return (

        <div className={`textNone filterDropDown${openShoolNameFilter ? '-school open' : ''}`}>
            <div className="filterDropHead">
                <h2>Filter <a href="javascript:void(0);" className="viewwLink" onClick={clearAllFilter}>Clear All</a></h2>
            </div>
            <div className="filterDropClose">
                <h3><a href="javascript:void(0);" onClick={() => filterByNames('sortByName','sort')} >A &#8594; Z</a></h3>
            </div>
            <div className="filterDropBody">
                <div className={`filterDropClose ${openCategory ? 'open' : ''}`}>
                    <h3><a href="javascript:void(0);"
                        onClick={() => setOpenCategory(!openCategory)}
                    >{`Category`}  <img src={filterArrowSvg} /></a></h3>
                    <div className="filtercheckbox">
                        {config.categories.map((item, index) => (
                            <div className="checkbox fnone" key={index} >
                                <input type="checkbox" id={item.value}
                                checked={category.includes(item.value)}
                                />
                                <label htmlFor={item.value}
                                onClick={() => filterByNames('category',item.value)}
                                >{item.label}</label>
                            </div>
                        ))}
                    </div>
                </div>
                <div className={`filterDropClose ${openLocation ? 'open' : ''}`}>
                    <h3><a href="javascript:void(0);"
                        onClick={() =>{setOpenLocation(!openLocation)}}
                    >{`Location`}  <img src={filterArrowSvg} /></a></h3>
                    {openLocation && city.name === "" && <span className="valError"><img src={errorSVG} />First choose any city</span>}
                    <div className="filtercheckbox">
                        {city.name != "" && locationByCities && locationByCities.map((item, index) => (
                            <div className="checkbox fnone" key={index} >
                                <input type="checkbox" id={item}
                                checked={location.includes(item)}
                                />
                                <label htmlFor={item}
                                onClick={() => filterByNames('location',item)}
                                >{item.length>15 ?  titleCase(item.slice(0,15)+`...`) : titleCase(item)}</label>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
        // <div className={`filterDropDown r-auto tableFilter  ${openShoolNameFilter ? 'open' : ''} `}>
        //     <div className="filterDropBody">
        //     <div className="filterDropHead">
        //         <h2>Filter <a href="javascript:void(0);" className="viewwLink" onClick={clearAllFilter}>Clear All</a></h2>
        //     </div>
        //         <div className="filterDropClose">
        //             <h3><a href="javascript:void(0);"onClick={() => filterByNames('sortByName')} >A &#8594; Z</a></h3>
        //         </div>
        //         <div className={`filterDropClose ${openCategory ? 'open' : ''}`}>
        //             <h3><a href="javascript:void(0);" onClick={() => setOpenCategory(!openCategory)}>{`Category`}  <img src={filterArrowSvg} /></a></h3>
        //             <div className="filtercheckbox mt-10">
        //                 <div className="radioBtn">
        //                     <input type="radio" id="az1" name="radio-group" value={'A'} checked={categoryType==='A' ? true :false }  onChange={() => filterByNames('A')}/>
        //                     <label htmlFor="az1">Category A</label>
        //                 </div>
        //                 <div className="radioBtn">
        //                     <input type="radio" id="az2" name="radio-group" value={'B'} checked={categoryType==='B'} onChange={() => filterByNames('B')}/>
        //                     <label htmlFor="az2">Category B</label>
        //                 </div>
        //                 <div className="radioBtn">
        //                     <input type="radio" id="az3" name="radio-group" value={'C'} checked={categoryType==='C'} onChange={() => filterByNames('C')}/>
        //                     <label htmlFor="az3">Category C</label>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </div>
    )
}
export default SchoolsNameCategoryFilter;