import React, { useState, useContext, useEffect, useRef } from 'react'
import { useHistory, useLocation } from "react-router-dom"
// import OnGoingCampaigns from "./onGoingCampaigns"
import Pagination from "../../../../../components/addon/pagination"
import arrowView from "../../../../../assets/images/arrow-view.svg"
import arrowViewRound from "../../../../../assets/images/arrow-view-round.svg"
import tableFilter from "../../../../../assets/images/table-filter.svg"
// import TopBar from "../../schools/components/topBar"
import AppContextObject from "../../../../../common/context/common"
import { checkSpecialCharacter, compareObjs, formatDateShowMonth } from "../../../../../service/helper"
import DataContext from "../../../../../service/backendContext"
import SimpleReactValidator from "simple-react-validator"
import errorSVG from "../../../../../assets/images/error.svg"
import editSvg from "../../../../../assets/images/edit-btn.svg"
import ConfirmDialog from "../../../../../components/addon/confirmDialog/confirmDialog"
import { DebounceInput } from 'react-debounce-input'
// import BrandBackBtn from "../components/brandBackBtn"
import CustomSearchInput from "../../../../../components/formFields/customSearchInput"
// import CarouselContainer from "../../../../components/addon/carosel"
// import gallery from "../../../../assets/images/gallery.png"
import helpImg from "../../../../../assets/images/help.svg"
import moment from "moment"
import BackendBackBtn from '../../components/backendBackBtn'

const NonWisrCampaignInventory = ({ setShowProgressSideMenu }) => {
    const history = useHistory()
    const context = useContext(AppContextObject)
    const [formValidator] = useState(useRef(new SimpleReactValidator({
        element: (message, className) =>
            <span className="valError"><img src={errorSVG} />{message}</span>
        })
    ))
    const location = useLocation()
    const [searchInpuOpen, setSearchInpuOpen] = useState(false)
    const [clearSearchIcon, setClearSearchIcon] = useState(false)
    const [customSearchInpuOpen, setCustomSearchInpuOpen] = useState(false)
    const [customClearSearchIcon, setCustomClearSearchIcon] = useState(false)
    const [update, forceUpdate] = useState(false)
    const parentData = useContext<any>(DataContext)
    const [formData, setFormData] = useState({...parentData.nonWisrCampaignData.inventory})
    const [inventoryList, setInventoryList] = useState([])
    const [recomendedInventoryList, setRecomendedInventoryList] = useState([])
    const [allInventoryList, setAllInventoryList] = useState([])
    const [customInventoryList, setCustomInventoryList] = useState([])
    const [customClassRoomInventory, setCustomClassRoomInventory] = useState([])
    const [customOtherRoomInventory, setCustomOtherRoomInventory] = useState([])
    const [customCommonAreaInventory, setCustomCommonAreaInventory] = useState([])
    const [customOutdoorInventory, setCustomOutdoorInventory] = useState([])
    // const [inventoryCategoryList, setInventoryCategoryList] = useState([])
    const [classRoomInventoryList, setClassRoomInventoryList] = useState([])
    const [otherRoomsAndStaffInventoryList, setOtherRoomsAndStaffInventoryList] = useState([])
    const [outdoorInventoryList, setOutdoorInventoryList] = useState([])
    const [commonAreaInventoryList, setCommonAreaInventoryList] = useState([])
    const [showOtherInventories, setShowOtherInventories] = useState(true)
    const [showExitPopup, setShowExitPopup] = useState(false)
    const [overlay, setOverlay] = useState(false)
    const [selectAll, setSelectAll] = useState(false)
    const [searchValue, setSearchValue] = useState("")
    const [customSearchValue, setCustomSearchValue] = useState("")
    const [showCustomInventory, setShowCustomInventory] = useState(false)
    const [noOtherInventoryFound, setNoOtherInventoryFound] = useState(false)
    const [noCustomInventoryFound, setNoCustomInventoryFound] = useState(false)
    const [selectedInventory, setSelectedInventory] = useState({})
    const [isAllInventorySelected, setIsAllInventorySelected] = useState(false)
    const [isAllCustomInventorySelected, setIsAllCustomInventorySelected] = useState(false)
    const [isAllRecommendedInventorySelected, setIsAllRecommendedInventorySelected] = useState(false)
    const [showAlert, setShowAlert] = useState(false)
    const [allCustomInventorySelecetd, setAllCustomInventorySelecetd] = useState(false)
    const [allInventorySelected, setAllInventorySelected] = useState(false)
    const [type, setType] = useState("add")
    const [confirmDialogBtnLoading, setConfirmDialogBtnLoading] = useState(false)
    const [inventoryError, setInventoryError] = useState(false)

    // useEffect(() => {
    //     if (formData?.inventories.length > 0) {
    //         setInventoryError(false)
    //     }
    // }, [formData])
    useEffect(() => {
        if(recomendedInventoryList.length > 0 && allInventoryList.length > 0){
            let allInventory = allInventoryList.filter(inventory => {
                let found = recomendedInventoryList.find(item => item._id === inventory._id)
                if(found){
                    return false
                }
                else{
                    return true
                }
            })
            setAllInventoryList([...allInventory])
            let classRoomInventory = classRoomInventoryList.filter(inventory => {
                let found = recomendedInventoryList.find(item => item._id === inventory._id)
                if(found){
                    return false
                }
                else{
                    return true
                }
            })
            setClassRoomInventoryList([...classRoomInventory])
            let otherRommInventory = otherRoomsAndStaffInventoryList.filter(inventory => {
                let found = recomendedInventoryList.find(item => item._id === inventory._id)
                if(found){
                    return false
                }
                else{
                    return true
                }
            })
            setOtherRoomsAndStaffInventoryList([...otherRommInventory])
            let outdoorInventory = outdoorInventoryList.filter(inventory => {
                let found = recomendedInventoryList.find(item => item._id === inventory._id)
                if(found){
                    return false
                }
                else{
                    return true
                }
            })
            setOutdoorInventoryList([...outdoorInventory])
            let commonAreaInventory = commonAreaInventoryList.filter(inventory => {
                let found = recomendedInventoryList.find(item => item._id === inventory._id)
                if(found){
                    return false
                }
                else{
                    return true
                }
            })
            setCommonAreaInventoryList([...commonAreaInventory])
        }
    }, [recomendedInventoryList])
    useEffect(() => {
        const isSummary = new URLSearchParams(location.search).get("isSummary")
        if (isSummary === "true") {
            console.log("object")
            parentData.setNonWisrCampaignProgress({
                brandDetails: true,
                inventory: false,
                school: false,
                event: false,
                campaignCreative: false,
                preview: false
            })
        }
    }, [])
    useEffect(() => {
        setShowProgressSideMenu(true)
        if (parentData.setNonWisrCampaignActiveModule) {
            parentData.setNonWisrCampaignActiveModule({
                ...parentData.nonWisrCampaignActiveModule,
                module: "campaignPlacement",
                subModule: "inventory"
            })
        }
        getAllInventoryList()
        getCustomInventoryList()
    }, [])
    useEffect(() => {
        setFormData({
            ...formData,
            inventories: [...parentData.nonWisrCampaignData.inventory.inventories]
        })
    }, [])
    useEffect(() => {
        if(parentData?.nonWisrCampaignData?.brandDetail?.brandCategory.label !== "other"){
            getSuggestedInventoryList()
        }
    }, [parentData?.nonWisrCampaignData?.brandDetail?.brandCategory])
    const getSuggestedInventoryList = async() => {
        let startDate = moment(new Date(parentData.nonWisrCampaignData.brandDetail.startDate));
        let endDate = moment(new Date(parentData.nonWisrCampaignData.brandDetail.endDate));
        let duration = moment.duration(endDate.diff(startDate))
        let days = duration.asDays() + 1;
        let data = parentData?.nonWisrCampaignData?.brandDetail?.brandCategory._id
        console.log("10", data)
        const resp = await context.apis.public.getSuggestedInventoriesForBrandCampaign(
            context.validator, {brandCategory: data, numOfDays: days}, {}
        )
        if(resp && resp.done){
            console.log("getSuggestedInventoriesForBrandCampaign", resp)
            let allInventory = [...resp.response.rows.filter(data => data.name !== "Staff Uniforms")]
            let sortInventory = allInventory.sort((a, b) => {
                return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
            })
            setRecomendedInventoryList([...sortInventory])
            // if(formData.inventories.length === 0){
            //     setFormData({
            //         ...formData,
            //         inventories: [...sortInventory.filter(data => data.isDisabled !== true)]
            //     })
            // }
        }
        console.log(resp)
    }

    const getAllInventoryList = async () => {
        let startDate = moment(new Date(parentData.nonWisrCampaignData.brandDetail.startDate));
        let endDate = moment(new Date(parentData.nonWisrCampaignData.brandDetail.endDate));
        let duration = moment.duration(endDate.diff(startDate))
        let days = duration.asDays() + 1;
        const resp = await context.apis.public.getAllInventoriesFromMaster(
            context.validator, { numOfDays: days }, {}
        )
        console.log("getAllInventoriesFromMaster", resp)
        if (resp && resp.done) {
            let allInventory = [...resp.response.rows.filter(inventory => inventory.type !== "events&Occasions" && inventory.name !== "Staff Uniforms")]
            let sortInventory = allInventory.sort((a, b) => {
                return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
            })
            setAllInventoryList([...sortInventory.filter(inventory => inventory.type !== "events&Occasions")])
            // let category = []
            // resp.response.rows.forEach((inventory) => {
            //     if(!category.includes(inventory.type)){
            //         category.push(inventory.type)
            //     }
            // })
            // setInventoryCategoryList([...category])
            setClassRoomInventoryList([...sortInventory.filter(inventory => inventory.type === "classroom")])
            setCommonAreaInventoryList([...sortInventory.filter(inventory => inventory.type === "commonArea")])
            setOutdoorInventoryList([...sortInventory.filter(inventory => inventory.type === "outdoor")])
            setOtherRoomsAndStaffInventoryList([...sortInventory.filter(inventory => inventory.type === "otherRooms&Staff")])
            // setEventsAndOccasionsInventoryList([...sortInventory.filter(inventory => inventory.type === "events&Occasions")])
        }
    }
    const getCustomInventoryList = async () => {
        const resp = await context.apis[context.user.role].getCustomInventoriesFromAllSchools(
            context.validator, {}, {}
        )
        console.log("getCustomInventoriesFromAllSchools", resp)
        if (resp && resp.done) {
            let allInventory = [...resp.response]
            let sortInventory = allInventory.sort((a, b) => {
                return a.parentName.toLowerCase() > b.parentName.toLowerCase() ? 1 : -1
            })
            setCustomInventoryList([...sortInventory.filter(inventory => (inventory.type === "classroom" || inventory.type === "commonArea" || inventory.type === "outdoor" || inventory.type === "otherRooms&Staff"))])
            setCustomClassRoomInventory([...sortInventory.filter(inventory => inventory.type === "classroom")])
            setCustomCommonAreaInventory([...sortInventory.filter(inventory => inventory.type === "commonArea")])
            setCustomOutdoorInventory([...sortInventory.filter(inventory => inventory.type === "outdoor")])
            setCustomOtherRoomInventory([...sortInventory.filter(inventory => inventory.type === "otherRooms&Staff")])
        }
    }
    const handleInventoryClick = (e: any, item) => {
        console.log(e.target.checked)
        let arr = formData.inventories
        if (e.target.checked) {
            // if(parentData?.nonWisrCampaignData?.school?.schools && parentData?.nonWisrCampaignData?.school?.schools.length > 0){
            //     setShowAlert(true)
            //     setOverlay(true)
            //     setIsAllCustomInventorySelected(false)
            //     setIsAllRecommendedInventorySelected(false)
            //     setIsAllInventorySelected(false)
            //     setSelectedInventory(item)
            //     setType("add")
            //     return
            // }
            arr.push(item)
            setFormData({
                ...formData,
                inventories: [...arr]
            })
        }
        else {
            // if(parentData?.nonWisrCampaignData?.school?.schools && parentData?.nonWisrCampaignData?.school?.schools.length > 0){
            //     setShowAlert(true)
            //     setOverlay(true)
            //     setIsAllCustomInventorySelected(false)
            //     setIsAllRecommendedInventorySelected(false)
            //     setIsAllInventorySelected(false)
            //     setSelectedInventory(item)
            //     setType("remove")
            //     return
            // }
            arr = [...arr.filter(inventory => inventory._id !== item._id)]
            setFormData({
                ...formData,
                inventories: [...arr]
            })
        }
    }
    const isInventorySelcted = (_id) => {
        if (formData.inventories && formData.inventories.length > 0) {
            let found = formData.inventories.find(item => item._id === _id)
            if (found) {
                return true
            }
            else return false
        }
        return false
    }

    const selectAllRecommendedInventory = (e) => {
        if(e.target.checked){
            let remainingInventory = formData.inventories.filter(inventory => {
                let found = recomendedInventoryList.find(item => item._id === inventory._id)
                if(found){
                    return false
                }
                else{
                    return true
                }
            })
            // allInventory = allInventory.filter
            setFormData({
                ...formData,
                inventories: [...remainingInventory, ...recomendedInventoryList.filter(item => item.isDisabled !== true)]
            })
            // setAllInventorySelected(true)
        }
        else{
            let remainingInventory = formData.inventories.filter(inventory => {
                let found = recomendedInventoryList.find(item => item._id === inventory._id)
                if(found){
                    return false
                }
                else{
                    return true
                }
            })
            setFormData({
                ...formData,
                inventories: [...remainingInventory]
            })
            // setAllInventorySelected(false)
        }
    }
    const selectAllInventory = (e) => {
        if (e.target.checked) {
            let remainingInventory = formData.inventories.filter(inventory => {
                let found = allInventoryList.find(item => item._id === inventory._id)
                if(found){
                    return false
                }
                else{
                    return true
                }
            })
            // allInventory = allInventory.filter
            setFormData({
                ...formData,
                inventories: [...remainingInventory, ...allInventoryList.filter(item => item.isDisabled !== true)]
            })
            setAllInventorySelected(true)
        }
        else {
            let remainingInventory = formData.inventories.filter(inventory => {
                let found = allInventoryList.find(item => item._id === inventory._id)
                if (found) {
                    return false
                }
                else {
                    return true
                }
            })
            setFormData({
                ...formData,
                inventories: [...remainingInventory]
            })
            setAllInventorySelected(false)
        }
    }
    const selectAllCustomInventory = (e) => {
        if (e.target.checked) {
            let remainingInventory = formData.inventories.filter(inventory => {
                let found = customInventoryList.find(item => item._id === inventory._id)
                if (found) {
                    return false
                }
                else {
                    return true
                }
            })
            setFormData({
                ...formData,
                inventories: [...remainingInventory, ...customInventoryList]
            })
            setAllCustomInventorySelecetd(true)
        }
        else {
            let remainingInventory = formData.inventories.filter(inventory => {
                let found = customInventoryList.find(item => item._id === inventory._id)
                if (found) {
                    return false
                }
                else {
                    return true
                }
            })
            setFormData({
                ...formData,
                inventories: [...remainingInventory]
            })
            setAllCustomInventorySelecetd(false)
        }
    }

    const handleSearch = async(e) => {
        // if(checkSpecialCharacter(e.target.value)){
        //     console.log("working----+++")
        //     e.preventDefault()
        //     return
        // }
        if(e === ""){
            setSearchValue("")
        }
        else{
            setSearchValue(e.target.value.trim())
        }
        let startDate = moment(new Date(parentData.nonWisrCampaignData.brandDetail.startDate));
        let endDate = moment(new Date(parentData.nonWisrCampaignData.brandDetail.endDate));
        let duration = moment.duration(endDate.diff(startDate))
        let days = duration.asDays() + 1;
        
        const resp = await context.apis.public.getAllInventoriesFromMaster(
            context.validator, {searchValue: e === "" ? "" : e.target.value, numOfDays: days}, {}
        )
        console.log(resp)
        if(resp && resp.done){
            let filterArray = resp.response.rows.filter(inventory => {
                let found = recomendedInventoryList.find(item => item._id === inventory._id)
                if(found){
                    return false
                }
                else{
                    return true
                }
            })
            setAllInventoryList([...filterArray.filter(inventory => inventory.type !== "events&Occasions" && inventory.name !== "Staff Uniforms")])
            setClassRoomInventoryList([...filterArray.filter(inventory => inventory.type === "classroom" && inventory.name !== "Staff Uniforms")])
            setCommonAreaInventoryList([...filterArray.filter(inventory => inventory.type === "commonArea" && inventory.name !== "Staff Uniforms")])
            setOutdoorInventoryList([...filterArray.filter(inventory => inventory.type === "outdoor" && inventory.name !== "Staff Uniforms")])
            setOtherRoomsAndStaffInventoryList([...filterArray.filter(inventory => inventory.type === "otherRooms&Staff" && inventory.name !== "Staff Uniforms")])
        }
    }
    const handleCustomSearch = async(e) => {
        if(e === ""){
            setCustomSearchValue("")
        }
        else{
            setCustomSearchValue(e.target.value)
        }
        const resp = await context.apis[context.user.role].getCustomInventoriesFromAllSchools(
            context.validator, {searchValue: e === "" ? "" : e.target.value}, {}
        )
        console.log("custom", resp)
        if(resp && resp.done){
            setCustomInventoryList(resp.response.filter(inventory => (inventory.type === "classroom" || inventory.type === "commonArea" || inventory.type === "outdoor" || inventory.type === "otherRooms&Staff")))
            setCustomClassRoomInventory([...resp.response.filter(inventory => inventory.type === "classroom")])
            setCustomCommonAreaInventory([...resp.response.filter(inventory => inventory.type === "commonArea")])
            setCustomOutdoorInventory([...resp.response.filter(inventory => inventory.type === "outdoor")])
            setCustomOtherRoomInventory([...resp.response.filter(inventory => inventory.type === "otherRooms&Staff")])
        }
    }
    const allRecommendedInventorySelected = () => {
        if(formData.inventories.length === 0 || recomendedInventoryList.length === 0){
            return false
        }
        let notFound = false
        recomendedInventoryList.forEach(inventory => {
            let found = formData.inventories.find(item => item._id === inventory._id)
            if(!found && inventory.isDisabled !== true){
                notFound = true
            }
        })
        return !notFound
    }
    const checkAllInventorySelected = () => {
        // let allInventories = allInventoryList.map(inventory => inventory._id)
        if(formData.inventories.length === 0 || allInventoryList.length === 0){
            return false
        }
        let notFound = false
        allInventoryList.forEach(inventory => {
            let found = formData.inventories.find(item => item._id === inventory._id)
            if(!found && inventory.isDisabled !== true){
                notFound = true
            }
        })
        return !notFound
    }
    const checkAllCustomInventorySelected = () => {
        if(formData.inventories.length === 0 || customInventoryList.length === 0){
            return false
        }
        let notFound = false
        customInventoryList.forEach(inventory => {
            let found = formData.inventories.find(item => item._id === inventory._id)
            if(!found && inventory.isDisabled !== true){
                notFound = true
            }
        })
        return !notFound
    }
    const openExit = () => {
        // setShowExitPopup(true)
        // setOverlay(true)
    }
    const closeInventoryModal = () => {
        // setShowInventoryModal(false)
    }

    const onSave = (e, proceed, optimisedData?) => {
        if(proceed){
            e.preventDefault()
            // if(formData.inventories.length === 0){
            //     setInventoryError(true)
            //     return
            // }
            // else{
                // if(parentData?.nonWisrCampaignData?.school?.schools?.length > 0 && !compareObjs(parentData.nonWisrCampaignData.inventory.inventories, formData.inventories)){
                //     console.log(compareObjs(parentData.nonWisrCampaignData.inventory.inventories, formData.inventories))
                //     if (parentData) {
                //         if (parentData.setNonWisrCampaignProgress) {
                //             parentData.setNonWisrCampaignProgress({
                //                 ...parentData.progress,
                //                 inventory: true,
                //             })
                //         }
                //         if(parentData.setNonWisrCampaignData){
                //             parentData.setNonWisrCampaignData({
                //                 ...parentData.nonWisrCampaignData,
                //                 inventory: {...formData},
                //                 school:{schools: [], locations: []}
                //             })
                //         }
                //     }
                // }
                // else{
                    if (parentData) {
                        if (parentData.setNonWisrCampaignProgress) {
                            parentData.setNonWisrCampaignProgress({
                                ...parentData.nonWisrCampaignProgress,
                                inventory: true,
                            })
                        }
                        if(parentData.setNonWisrCampaignData){
                            parentData.setNonWisrCampaignData({
                                ...parentData.nonWisrCampaignData,
                                inventory: {...formData}
                            })
                        }
                    }
                // }
                if(parentData?.nonWisrCampaignData?.brandDetail?.eventAndOccations){
                    if(parentData.nonWisrCampaignData.brandDetail.isTrial){
                        history.push('/backend/backend-details/nonWisr-create-trial-campaign/nonWisr-campaign-event')
                    }
                    else{
                        history.push('/backend/backend-details/nonWisr-create-campaign/nonWisr-campaign-event')
                    }
                }
                else{
                    if(parentData.nonWisrCampaignData.brandDetail.isTrial){
                        history.push('/backend/backend-details/nonWisr-create-trial-campaign/nonWisr-campaign-school')
                    }
                    else{
                        history.push('/backend/backend-details/nonWisr-create-campaign/nonWisr-campaign-school')
                    }
                }
            // }
        }
    }
    return (
        <>
            <div className={`ovelap ${overlay === true && "active"}`}></div>
            {/* <TopBar isDashboard={true} /> */}
            <div className="col-xl-9 col-lg-12 col-md-12 col-xs-12">
                <div className="rightPart noBack">
                    <div className="row">
                        <div className="col-xs-12 col-lg-12 col-md-12 col-xs-12">
                            <BackendBackBtn />
                        </div>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-20"><h2 className="htx5 clearfix mt-10 mb-20">Create a Campaign</h2></div>
                        <div className="col-xl-7 col-lg-7 col-md-12 col-xs-12 pl pr">
                            <div className="row">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                    <div className="brandTeamMemberTable w100">
                                        <div className="row">
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-10">
                                                <h2 className="htx2 clearfix">Campaign Details <a href="javascript:void(0)" className="editbtn" onClick={() => history.push({ pathname: parentData.nonWisrCampaignData.brandDetail.isTrial ? '/backend/backend-details/nonWisr-create-trial-campaign/set-up-nonWisr-campaign' : '/backend/backend-details/nonWisr-create-campaign/set-up-nonWisr-campaign', state: { from: "createCampaignFlow" } })}><img src={editSvg} /> Edit</a></h2>
                                            </div>
                                            <div className="col-xl-12 col-lg-12 col-md-9 col-xs-12 mt-20"><div className="line-full"></div></div>
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 pl pr">
                                                <div className="row generalview">
                                                    <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12 mt-30">
                                                        <div className="inventory-overHead">
                                                            <p>Name</p>
                                                            <h3>{parentData?.nonWisrCampaignData?.brandDetail?.campaign?.name}</h3>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12 mt-30">
                                                        <div className="inventory-overHead">
                                                            <p>Description</p>
                                                            <h3>{parentData.nonWisrCampaignData.brandDetail?.description}</h3>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12 mt-30">
                                                        <div className="inventory-overHead">
                                                            <p>Medium of Study</p>
                                                            <span><h3>
                                                                {parentData.nonWisrCampaignData.brandDetail.studyMedium.length !== 0 && parentData.nonWisrCampaignData.brandDetail.studyMedium.map((item, index) => (
                                                                    <b style={{ "wordBreak": "initial" }} key={index}>{index < parentData.nonWisrCampaignData.brandDetail.studyMedium.length - 1 ? `${item}, ` : item}</b>
                                                                ))}
                                                            </h3>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12 mt-30">
                                                        <div className="inventory-overHead">
                                                            <p>Brand Category</p>
                                                            {/* {parentData.nonWisrCampaignData.brandDetail.brandCategory.length !== 0 && parentData.nonWisrCampaignData.brandDetail.brandCategory.map((item, index) => (
                                                                <span key={index}><h3>{index < parentData.nonWisrCampaignData.brandDetail.brandCategory.length -1 ? `${item.value}, ` : item.value}</h3></span>
                                                            ))} */}
                                                            {parentData.nonWisrCampaignData.brandDetail.brandCategory && parentData.nonWisrCampaignData.brandDetail.brandCategory.label && <span><h3>{parentData.nonWisrCampaignData.brandDetail.brandCategory.label}</h3></span>

                                                            }
                                                        </div>
                                                    </div>
                                                    {/* <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12 mt-30">
                                                        <div className="inventory-overHead">
                                                            <p>Events</p>
                                                            <h3>Sports Day</h3>
                                                        </div>
                                                    </div> */}
                                                    <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12 mt-30">
                                                        <div className="inventory-overHead">
                                                            <p>Duration</p>
                                                            <h3>{parentData.nonWisrCampaignData.brandDetail.startDate && parentData.nonWisrCampaignData.brandDetail.endDate && `${formatDateShowMonth(parentData.nonWisrCampaignData.brandDetail.startDate, 4, true)} - ${formatDateShowMonth(parentData.nonWisrCampaignData.brandDetail.endDate, 4, true)}`}</h3>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12 mt-30">
                                                        <div className="inventory-overHead">
                                                            <p>Target Audience Gender</p>
                                                            <h3>{parentData.nonWisrCampaignData.brandDetail.targetGender && parentData.nonWisrCampaignData.brandDetail.targetGender === "both" ? "All" : parentData.nonWisrCampaignData.brandDetail.targetGender}</h3>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12 mt-30">
                                                        <div className="inventory-overHead">
                                                            <p>Target Audience Age</p>
                                                            <h3>{parentData.nonWisrCampaignData.brandDetail.targetAgeGroup.min && parentData.nonWisrCampaignData.brandDetail.targetAgeGroup.max && `${parentData.nonWisrCampaignData.brandDetail.targetAgeGroup.min} y.o to ${parentData.nonWisrCampaignData.brandDetail.targetAgeGroup.max} y.o`}</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="reco-inventory">
                                        <div className="row">
                                            {parentData.nonWisrCampaignData.brandDetail.brandCategory.label !== "Others" &&
                                                <>
                                                    <div className="col-xl-9 col-lg-9 col-md-12 col-xs-12  mt-40">
                                                        <h2 className="htx2 clearfix">{`Recommended Inventories (${recomendedInventoryList.length > 0 ? recomendedInventoryList.length : 0})`}</h2>
                                                    </div>
                                                    <div className="col-xl-3 col-lg-3 col-md-12 col-xs-12 mt-40">
                                                        <div className="inventorySearch schoolFilter inventoryBackAdmin campaignSelect searchCustome">
                                                            <div className="checkbox">
                                                                <input type="checkbox" id="all-3" checked={allRecommendedInventorySelected()} onClick={(e) => selectAllRecommendedInventory(e)} />
                                                                <label htmlFor="all-3">Select All</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                                        <div className="forminpt checkboxinput">
                                                            <div className="checkboxinputList">
                                                                {recomendedInventoryList.length > 0 && recomendedInventoryList.map((item, index) => (
                                                                    <div key={index} className={`checkbox ${item.isDisabled && "disabled-grey"}`}>
                                                                        <input type="checkbox" id={item._id} disabled={item.isDisabled} checked={item.isDisabled === true ? false : isInventorySelcted(item._id)} onClick={(e) => handleInventoryClick(e, item)} />
                                                                        <label htmlFor={item._id}>{item.name} {item.isDisabled && <a style={{ cursor: "pointer" }} title={`This inventory is available only for campaign duration more than ${item.minNumOfDays} days.`} className="tooltip"><img src={helpImg} /></a>}</label>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                            <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12 mt-20">
                                                <h2 className="htx2 clearfix">{`${parentData.nonWisrCampaignData.brandDetail.brandCategory.label !== "Others" ? "Other " : ""} Inventory (${allInventoryList.length})`} <span className={`other-arrow ${showOtherInventories && "other-downarrow"}`} onClick={() => setShowOtherInventories(!showOtherInventories)}><svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M11 6L6 1L1 6" stroke="#8C8C8C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /> </svg></span></h2>
                                            </div>
                                            <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12 mt-20">
                                                <div className="inventorySearch schoolFilter inventoryBackAdmin campaignSelect searchCustome">
                                                    {/* <a href="javascript:void(0);"><img src={search}/></a>  */}
                                                    <CustomSearchInput
                                                        searchInpuOpen={searchInpuOpen}
                                                        setSearchInpuOpen={setSearchInpuOpen}
                                                        clearSearchIcon={clearSearchIcon}
                                                        setClearSearchIcon={setClearSearchIcon}
                                                        searchKeys={searchValue}
                                                        handleChange={handleSearch}
                                                        palaceholder="Search"
                                                    />
                                                    <div className="checkbox">
                                                        <input type="checkbox" id="all-1" checked={checkAllInventorySelected()} onClick={(e) => selectAllInventory(e)} />
                                                        <label htmlFor="all-1">Select All</label>
                                                    </div>
                                                </div>
                                            </div>
                                            {showOtherInventories && (allInventoryList.length > 0 ?
                                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 otherDisplay">
                                                    <div className="row">
                                                        {classRoomInventoryList.length > 0 &&
                                                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 pl pr mt-30">
                                                                <div className="forminpt checkboxinput">
                                                                    <h2 className="htx3 clearfix">Classroom Inventory</h2>
                                                                    <div className="checkboxinputList">
                                                                        {classRoomInventoryList.length !== 0 && classRoomInventoryList.map(item => (
                                                                            <div key={item._id} className={`checkbox ${item.isDisabled && "disabled-grey"}`}>
                                                                                <input type="checkbox" id={item._id} disabled={item.isDisabled} checked={item.isDisabled === true ? false : isInventorySelcted(item._id)} onClick={(e) => handleInventoryClick(e, item)} />
                                                                                <label htmlFor={item._id}>{item.name} {item.isDisabled && <a style={{ cursor: "pointer" }} title={`This inventory is available only for campaign duration more than ${item.minNumOfDays - 1} days.`} className="tooltip"><img src={helpImg} /></a>}</label>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                        {commonAreaInventoryList.length > 0 &&
                                                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 pl pr mt-30">
                                                                <div className="forminpt checkboxinput">
                                                                    <h2 className="htx3 clearfix">Common Area Inventory</h2>
                                                                    <div className="checkboxinputList">
                                                                        {commonAreaInventoryList.length !== 0 && commonAreaInventoryList.map(item => (
                                                                            <div key={item._id} className="checkbox">
                                                                                <input type="checkbox" id={item._id} checked={isInventorySelcted(item._id)} onClick={(e) => handleInventoryClick(e, item)} />
                                                                                <label htmlFor={item._id}>{item.name}</label>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                        {otherRoomsAndStaffInventoryList.length > 0 &&
                                                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 pl pr mt-30">
                                                                <div className="forminpt checkboxinput">
                                                                    <h2 className="htx3 clearfix">Other Rooms and Staff</h2>
                                                                    <div className="checkboxinputList">
                                                                        {otherRoomsAndStaffInventoryList.length !== 0 && otherRoomsAndStaffInventoryList.map(item => (
                                                                            <div key={item._id} className="checkbox">
                                                                                <input type="checkbox" id={item._id} checked={isInventorySelcted(item._id)} onClick={(e) => handleInventoryClick(e, item)} />
                                                                                <label htmlFor={item._id}>{item.name}</label>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                        {outdoorInventoryList.length > 0 &&
                                                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 pl pr mt-30">
                                                                <div className="forminpt checkboxinput">
                                                                    <h2 className="htx3 clearfix">Outdoor Inventory</h2>
                                                                    <div className="checkboxinputList">
                                                                        {outdoorInventoryList.length !== 0 && outdoorInventoryList.map(item => (
                                                                            <div key={item._id} className="checkbox">
                                                                                <input type="checkbox" id={item._id} checked={isInventorySelcted(item._id)} onClick={(e) => handleInventoryClick(e, item)} />
                                                                                <label htmlFor={item._id}>{item.name}</label>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                                :
                                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 otherDisplay">
                                                    <div className="row">
                                                        No data found
                                                    </div>
                                                </div>
                                            )}
                                            <div className={`col-xl-6 col-lg-6 col-md-12 col-xs-12 mt-20 ${inventoryError && !showCustomInventory ? "mb-20" : ""}`}>
                                                <h2 className="htx2 clearfix">{`Custom Inventories (${customInventoryList.length})`} <span className={`other-arrow ${showCustomInventory && "other-downarrow"}`} onClick={() => setShowCustomInventory(!showCustomInventory)}><svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M11 6L6 1L1 6" stroke="#8C8C8C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /> </svg></span></h2>
                                            </div>
                                            <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12 mt-20">
                                                <div className="inventorySearch schoolFilter inventoryBackAdmin campaignSelect searchCustome">
                                                    {/* <a href="javascript:void(0);"><img src={search}/></a>  */}
                                                    {/* <DebounceInput debounceTimeout={500} type="text" placeholder="Search" value={customSearchValue} onChange={handleCustomSearch} /> */}
                                                    <CustomSearchInput
                                                        searchInpuOpen={customSearchInpuOpen}
                                                        setSearchInpuOpen={setCustomSearchInpuOpen}
                                                        clearSearchIcon={customClearSearchIcon}
                                                        setClearSearchIcon={setCustomClearSearchIcon}
                                                        searchKeys={customSearchValue}
                                                        handleChange={handleCustomSearch}
                                                        palaceholder="Search"
                                                    />
                                                    <div className="checkbox">
                                                        <input type="checkbox" id="all-custom" checked={checkAllCustomInventorySelected()} onClick={(e) => selectAllCustomInventory(e)} />
                                                        <label htmlFor="all-custom">Select All</label>
                                                    </div>
                                                </div>
                                            </div>
                                            {showCustomInventory && (customInventoryList.length > 0 ?
                                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 otherDisplay">
                                                    <div className="row">
                                                        {customClassRoomInventory.length > 0 &&
                                                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 pl pr mt-30">
                                                                <div className="forminpt checkboxinput">
                                                                    <h2 className="htx3 clearfix">Classroom Inventory</h2>
                                                                    <div className="checkboxinputList">
                                                                        {customClassRoomInventory.length !== 0 && customClassRoomInventory.map(item => (
                                                                            <div key={item._id} className="checkbox">
                                                                                <input type="checkbox" id={item._id} checked={isInventorySelcted(item._id)} onClick={(e) => handleInventoryClick(e, item)} />
                                                                                <label htmlFor={item._id}>{item.parentName}</label>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                        {customCommonAreaInventory.length > 0 &&
                                                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 pl pr mt-30">
                                                                <div className="forminpt checkboxinput">
                                                                    <h2 className="htx3 clearfix">Common Area Inventory</h2>
                                                                    <div className="checkboxinputList">
                                                                        {customCommonAreaInventory.length !== 0 && customCommonAreaInventory.map(item => (
                                                                            <div key={item._id} className="checkbox">
                                                                                <input type="checkbox" id={item._id} checked={isInventorySelcted(item._id)} onClick={(e) => handleInventoryClick(e, item)} />
                                                                                <label htmlFor={item._id}>{item.parentName}</label>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                        {customOtherRoomInventory.length > 0 &&
                                                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 pl pr mt-30">
                                                                <div className="forminpt checkboxinput">
                                                                    <h2 className="htx3 clearfix">Other Rooms and Staff</h2>
                                                                    <div className="checkboxinputList">
                                                                        {customOtherRoomInventory.length !== 0 && customOtherRoomInventory.map(item => (
                                                                            <div key={item._id} className="checkbox">
                                                                                <input type="checkbox" id={item._id} checked={isInventorySelcted(item._id)} onClick={(e) => handleInventoryClick(e, item)} />
                                                                                <label htmlFor={item._id}>{item.parentName}</label>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                        {customOutdoorInventory.length > 0 &&
                                                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 pl pr mt-30">
                                                                <div className="forminpt checkboxinput">
                                                                    <h2 className="htx3 clearfix">Outdoor Inventory</h2>
                                                                    <div className="checkboxinputList">
                                                                        {customOutdoorInventory.length !== 0 && customOutdoorInventory.map(item => (
                                                                            <div key={item._id} className="checkbox">
                                                                                <input type="checkbox" id={item._id} checked={isInventorySelcted(item._id)} onClick={(e) => handleInventoryClick(e, item)} />
                                                                                <label htmlFor={item._id}>{item.parentName}</label>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                                :
                                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 otherDisplay">
                                                    <div className="row">
                                                        No data found
                                                    </div>
                                                </div>
                                            )}
                                            {/* {inventoryError && <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 otherDisplay"><span className="valError"><img src={errorSVG} />Please select atleast one inventory.</span></div>} */}
                                            <div className="col-xl-5 col-lg-5 col-md-6 col-xs-12 delete-user mt-30">
                                                <a className="viewwLink" style={{ cursor: "pointer" }} onClick={openExit}>Save & Continue Later</a>
                                            </div>
                                            <div className="col-xl-7 col-lg-7 col-md-6 col-xs-12 end-md mt-30">
                                                <div className="forminpt">
                                                    <input name="Save &amp; next" className="btn btn-orange" type="button" value="Save &amp; next" onClick={(e) => onSave(e, true)} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <ConfirmDialog
                togglePopup={showExitPopup}
                onModalClose={onExit}
                componentName={""}
                confirmTitle="Do you want to save and exit?"
                isLoading={confirmDialogBtnLoading}
            />
            <ConfirmDialog
                togglePopup={showAlert}
                onModalClose={onModalClose}
                componentName={""}
                confirmTitle="This may reset your selected schools"
            /> */}
        </>
    )
}

export default NonWisrCampaignInventory