import React, { useState, useEffect, useRef, useContext} from 'react'
import errorSVG from "../../../../assets/images/error.svg"
import deleteImg from "../../../../assets/images/delete.svg"

import SimpleReactValidator from "simple-react-validator"
import TextField from '../../../../components/formFields/textField/textField'
import { PublicContextInitiator } from '../../../apis/publicContextInitiator'
import AppContextObject from '../../../../common/context/common'
import toast from "react-hot-toast"
import TextFieldWithPrefix from '../../../../components/formFields/textFieldWithPrefix/textFieldWithPrefix'
import SingleSelect from '../../../../components/formFields/singleSelect/singleSelect'
import editSvg from "../../../../assets/images/edit-btn.svg"
import config from '../../../../service/config'
import { hasOnlyLettersAndSpaces, hasOnlyLettersNumberAndSpaces } from "../../../../service/helper"

const EditContact = (props) => {
    const {
        contactDetail,
        setContactDetail,
        setOverlay,
        refetch,
        setRefetch,
        profileData,
        isBackendUser,
        backendCompanyId,
        backendUserRole
    } = props
    const context = useContext(AppContextObject)
    const [formValidator] = useState(useRef(new SimpleReactValidator({
        element: (message, className) =>
            <p className="valError"><img src={errorSVG} />{
                message==="The contact number must be a valid phone number."
                ?
                "The mobile no must be a valid mobile no."
                :
                message==="The contact number field is required."
                ?
                "The mobile no field is required."
                :
                message
            }</p>
    })))
    const [formData, setFormData] = useState(contactDetail)
    const [editingData, setEditingData] = useState(false)
    const [isPhoneVerified, setIsPhoneVerified] = useState(false)
    const [isPincodeValid, setIsPincodeValid] = useState(true)
    const [update, forceUpdate] = useState(false)
    const [editingContacts, setEditingContacts] = useState(false)
    const onFormDataChange = (event) => {
        if(event.target.id === "state") {
            if(hasOnlyLettersAndSpaces(event.target.value)){
                setFormData({ ...formData, [event.target.id]: event.target.value })
            }
        }
        else if(event.target.id === "city") {
            // if(context.utils.cityNameRegex.test(event.target.value)){
            if(hasOnlyLettersNumberAndSpaces(event.target.value)){
                setFormData({ ...formData, [event.target.id]: event.target.value })
            }
        }
        else{
            setFormData({
                ...formData,
                [event.target.id]: event.target.value
            })
        }
    }
    const onCountryChange = (data) => {
        setFormData({
            ...formData,
            country: data.value
        })
    }
    useEffect(() => {
        fetchCityState()  
    }, [formData.zip])
    const fetchCityState = async () => {
        if(formData.zip && formData.zip.length == 6) {
            const publicContextInitiator = new PublicContextInitiator(context)
            let resp = await publicContextInitiator.getPincodeDetails(
                context.validator, {"pincode": parseInt(formData.zip)}, {}
            )

            if(resp && resp.done){
                onStateCityChange(resp.response.stateName, resp.response.district)
                setIsPincodeValid(true)
            }else{
                setIsPincodeValid(false)
            }
        } else{
            setIsPincodeValid(true)
            onStateCityChange("", "")
        }
    }
    const onStateCityChange = (dataState, dataCity) => {
        dataCity = dataCity.toLowerCase()
        setFormData({...formData, "state": dataState, "city": dataCity})
    }
    const handleSave = async(confirm) => {
        if(confirm){
            const result = formValidator.current.allValid()

            // if(isPhoneVerified==false) {
            //     setMsgDetails('You need to verify your mobile number to continue, Just click on verify to get OTP.')
            //     setOverlay(true)
            //     setToggleAlertPopUp(true)
            //     return
            // }
            if (result === false || isPincodeValid == false) {
                formValidator.current.showMessages()
                forceUpdate(true)
                return
            }
            else {
                formValidator.current.hideMessages()
                forceUpdate(!update)
            }
            if(!isPincodeValid){
                return
            }
            let apiData = transformObj()
            setEditingContacts(true)
            let updateResp = await context.apis[context.user.role].updateBrand(
                context.validator, {_id: (isBackendUser ? backendCompanyId : (context.user.parentBrand || context.user.brand)), sectionName: 'contact details'}, apiData
            )

            if(updateResp && updateResp.done){
                toast.success("Brand edited successfully")
                setEditingContacts(false)
                setRefetch(!refetch)
                setEditingData(false)
                setFormData({})
                setContactDetail({})
            }
        }
        else{
            setEditingContacts(false)
            setEditingData(false)
            setFormData({...contactDetail})
        }
    }
    const transformObj = () => {
        const obj = {
            address: formData.address,
            contactNumber: formData.contactNumber,
            country: formData.country,
            city: formData.city,
            state: formData.state,
            zip: formData.zip,
        }
        return obj
    }

    formValidator.current.rules['city_name'] = {
        message: 'Please enter valid city name.',
        rule: (val) => {
            let returnValue = context.utils.cityNameRegex.test(val)
            return returnValue
        }
    }
    return (
        <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
            <div className="row">
                <div className="col-xl-4 col-lg-4 col-md-12 col-xs-12">
                    <div className="profile-general">
                        {isBackendUser ? 
                        <h2 className="htx3 clearfix">Contact {!editingData && (backendUserRole === "backendAdmin" || backendUserRole === "backendSales" || backendUserRole === "backendTech") && <a href="javascript:void(0)" className="editbtn" onClick={() => setEditingData(true)}><img src={editSvg} /> Edit</a>}</h2>
                        :
                        <h2 className="htx3 clearfix">Contact {!editingData && <a href="javascript:void(0)" className="editbtn" onClick={() => setEditingData(true)}><img src={editSvg} /> Edit</a>}</h2>
                        }
                    </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12 brand-general pl pr ">
                    <div className="row">
                        {editingData ?
                            <>
                                <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12">
                                    {/* <TextField
                                        formData={formData}
                                        formKey='country'
                                        onDataChange={onFormDataChange}
                                        label='Country'
                                        placeholder='Enter Country Name'
                                        isRequired={false}
                                        formValidator={formValidator}
                                        validation='required'
                                        isDisabled={!editingData}
                                    /> */}
                                    <SingleSelect
                                        formData={formData}
                                        formKey='country'
                                        onDataChange={onCountryChange}
                                        label='Country'
                                        placeholder='Enter Country Name'
                                        isRequired={false}
                                        options={config.country}
                                        formValidator={formValidator}
                                        validation='required'
                                    />
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12 clearfix">
                                    <TextField
                                        formData={formData}
                                        formKey="zip"
                                        maxLength={6}
                                        onDataChange={onFormDataChange}
                                        label="Zip"
                                        placeholder="Enter Pincode"
                                        isRequired={false}
                                        formValidator={formValidator}
                                        validation="required|min:6|numeric"
                                        isPincodeValid={isPincodeValid}
                                        isDisabled={!editingData}
                                    />
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12">
                                    <TextField
                                        formData={formData}
                                        formKey="state"
                                        onDataChange={onFormDataChange}
                                        label="State"
                                        placeholder="Enter State"
                                        isRequired={false}
                                        formValidator={formValidator}
                                        validation="required|alpha_space"
                                        isDisabled={!editingData}
                                        maxLength={50}
                                    />
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12">
                                    <TextField
                                        formData={formData}
                                        formKey="city"
                                        onDataChange={onFormDataChange}
                                        label="City"
                                        placeholder="Enter City"
                                        isRequired={false}
                                        formValidator={formValidator}
                                        validation="required|city_name"
                                        isDisabled={!editingData}
                                        maxLength={50}
                                    />
                                </div>
                                
                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 clearfix">
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12 clearfix">

                                    <TextFieldWithPrefix
                                        formData={formData}
                                        formKey='contactNumber'
                                        onDataChange={onFormDataChange}
                                        label='Mobile No'
                                        placeholder='Eg. 9999 999 999'
                                        isRequired={false}
                                        // onVerifyClick={onVerifyMobileClick}
                                        isVerified={isPhoneVerified}
                                        formValidator={formValidator}
                                        validation='required|phone'
                                        isDisabled={!editingData}
                                    />
                                </div>
                            </> 
                            :
                            <>
                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                    <div className="inventory-overHead mt-0"><p>Location</p><h3>{`${contactDetail.address} , 
                                        ${contactDetail && contactDetail.city.charAt(0).toUpperCase() + contactDetail.city.slice(1)} ${contactDetail.zip}, ${contactDetail.country}`}</h3>
                                    </div>
                                </div>
                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 clearfix mt-20"></div>
                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                    <div className="inventory-overHead"><p>Mobile No</p><h3>{`+91 ${contactDetail.contactNumber}`}</h3></div>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
            {editingData &&
                <div className="col-xl-10 col-lg-10 col-md-12 col-xs-12 mb-20">
                    <div className="btn-general">
                        <a style={{cursor: "pointer"}} className="btn btn-outline" onClick={() => handleSave(false)}>Cancel</a>
                        <a style={{cursor: "pointer"}} className={`btn btn-orange ${editingContacts && "button--loading btn-disabled"}`} onClick={() => handleSave(true)}>Save Changes</a>
                    </div>
                </div>
            }
        </div>
    )
}

export default EditContact
