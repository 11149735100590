import { useContext, useEffect } from "react"

import {
    useLocation,
    useRouteMatch,
    Link
} from "react-router-dom"

import DataContext from "../../../../service/contextProvider"

import check from "../../../../assets/images/check.svg"

const SchoolOnboardProgress = ({doNotShow}) => {

    const parentData = useContext<any>(DataContext)
    const location = useLocation()
    const router = useRouteMatch()
    useEffect(()=>{
        if(parentData && parentData?.progress && parentData?.progress?.outdoor){
            if(document.getElementById("eventMenu")){
                document.getElementById("eventMenu").scrollIntoView();
            }
        }
    },[parentData.progress])

    return (
        <>
            {
                // location.pathname !== `${router.url}/onboarding-summary` &&
                <ul>
                    {!(doNotShow === true) &&
                        <>
                            <li>
                                <Link
                                    to={`${router.url}/your-details`}
                                    className={
                                        `${parentData.activeModule.module === "yourDetails" && "active"}
                                        ${parentData?.progress?.personalDetails === true && "complete"}`
                                    }
                                >
                                    <span><img src={check} />1</span> Your Details
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to={`${router.url}/general-details`}
                                    className={
                                        `${parentData.activeModule.module === "schoolDetails" && "active"}
                                        ${parentData?.progress?.schoolGeneral === true
                                            && parentData?.progress?.studentSpecific === true
                                            && "complete"}
                                        ${parentData?.progress?.personalDetails === true ? "" : "disabledLink"}`
                                    }
                                >
                                    <span><img src={check} />2</span> School Details
                                </Link>
                                { parentData.activeModule.module === "schoolDetails" &&
                                    <ul>
                                        <li>
                                            <Link
                                                to={`${router.url}/general-details`}
                                                className={`${parentData.activeModule.subModule === "general" && "active"}
                                                    ${parentData?.progress?.personalDetails === true ? "" : "disabledLink"}
                                                `}
                                            >
                                                General
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to={`${router.url}/student-specific`}
                                                className={`${parentData.activeModule.subModule === "students" && "active"}
                                                ${parentData?.progress?.schoolGeneral === true ? "" : "disabledLink"}
                                                `}
                                            >
                                                Student Specific
                                            </Link>
                                        </li>
                                    </ul>
                                }
                            </li>
                            <li>
                                <Link
                                    to={`${router.url}/classroom-inventories`}
                                    className={
                                        `${parentData.activeModule.module === "inventoryDetails" && "active"}
                                        ${parentData?.progress?.classroom === true
                                            && parentData?.progress?.commonArea === true
                                            && parentData?.progress?.otherRoomsAndStaff === true
                                            && parentData?.progress?.outdoor === true
                                            && parentData?.progress?.eventsAndOccasions === true
                                            && "complete"}
                                            ${(parentData?.progress?.schoolGeneral && parentData?.progress?.studentSpecific) ? "" : "disabledLink"}
                                            `
                                    }
                                >
                                    <span><img src={check} />3</span> Inventory Details
                                </Link>
                                {
                                    parentData.activeModule.module === "inventoryDetails" &&
                                    <ul>
                                        <li>
                                            <Link
                                                to={`${router.url}/classroom-inventories`}
                                                className={`${parentData.activeModule.subModule === "classrooms" && "active"}
                                                ${(parentData?.progress?.schoolGeneral && parentData?.progress?.studentSpecific) ? "" : "disabledLink"}
                                                `}
                                            >
                                                Classroom
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to={`${router.url}/common-area-inventories`}
                                                className={`${parentData.activeModule.subModule === "commonArea" && "active"}
                                                ${parentData?.progress?.classroom === true ? "" : "disabledLink"}
                                                `}
                                            >
                                                Common Area
                                            </Link>
                                        <li>
                                        </li>
                                            <Link
                                                to={`${router.url}/other-area-and-staff-inventories`}
                                                className={`${parentData.activeModule.subModule === "otherRoomsAndStaff" && "active"}
                                                ${parentData?.progress?.commonArea === true ? "" : "disabledLink"}
                                                `}
                                            >
                                                Staff and Miscellaneous Rooms
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to={`${router.url}/outdoor-inventories`}
                                                className={`${parentData.activeModule.subModule === "outdoor" && "active"}
                                                ${parentData?.progress?.otherRoomsAndStaff === true ? "" : "disabledLink"}
                                                `}
                                            >
                                                Outdoor
                                            </Link>
                                        </li>
                                        <li id="eventMenu">
                                            <Link
                                                to={`${router.url}/events-and-occasions`}
                                                className={`${parentData.activeModule.subModule === "events" && "active"}
                                                ${parentData?.progress?.outdoor === true ? "" : "disabledLink"}
                                                `}
                                            >
                                                Events and Occasions
                                            </Link>
                                        </li>                                                            
                                        {/* <li>
                                            <a
                                                href="javascript:void(0)"
                                                className={`${location.pathname === `${router.url}/school-specific` && "active"}`}
                                            >
                                                School Specific
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="javascript:void(0)"
                                                className={`${location.pathname === `${router.url}/classroom-specific` && "active"}`}
                                            >
                                                Classroom Specific
                                            </a>
                                        </li> */}
                                    </ul>
                                }
                            </li>
                            <li>
                                <Link
                                    to={`${router.url}/onboarding-summary`}
                                    className={
                                        `${parentData.activeModule.module === "preview" && "active"}
                                        ${(parentData?.progress?.classroom === true
                                            && parentData?.progress?.commonArea === true
                                            && parentData?.progress?.otherRoomsAndStaff === true
                                            && parentData?.progress?.outdoor === true
                                            && parentData?.progress?.eventsAndOccasions === true)
                                            ? "complete" : "disabledLink"}
                                        `
                                    }
                                >
                                    <span><img src={check} />4</span> Preview
                                </Link>
                            </li>
                        </>
                    }
                </ul>
            }
        </>
    )
}

export default SchoolOnboardProgress
