import React, { useRef } from "react";
import useClickOutside from '../../../../service/useClickOutside';
import { Link } from "react-router-dom";
import arrowUpDown from "../../../../assets/images/backendAdmin/arrow-up-down.svg";
import editBtn from "../../../../assets/images/backendAdmin/edit-btn.svg";
import tableFilter from "../../../../assets/images/table-filter.svg";
import Pagination from "../../../../components/addon/pagination";
// import CategoryFilter from "./CategoryFilter";
import CategoryFilterUser from "./categoryFilterUser";

const UserTable = ({ dataList, setCurrentpage, currentPage, totalPage, onPageChange, onNextClick, onPrevClick,
    cityFilter, setIsFOSEdit, setUserId, cityFilterModal, setOverlay, setCityFilterModal,
    setFilterCity, filterCity, tabRole, loading, totalPageCount
}) => {
    const ref = useRef(null);
    useClickOutside(ref, () => { setCityFilterModal(false) })

    return (
        <div className="table-container table-inventory mt-10">
            <table className="table">
                <thead>
                    <tr ref={ref}>
                        <th>Sl no</th>
                        <th>Full name</th>
                        <th>role</th>
                        <th className="p-relative tableDropdown">city <a href="javascript:void(0);"><img src={tableFilter} onClick={cityFilter} /></a>
                            {cityFilterModal && <CategoryFilterUser
                                setFilterCity={setFilterCity}
                                filterCity={filterCity}
                                setCurrentpage={setCurrentpage}
                                tabRole={tabRole}
                            />}
                        </th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {!loading ?
                        <>
                            {dataList && dataList.length > 0 ?
                                <>
                                    {dataList.map((user, key) => {
                                        return (
                                            <tr key={key}>
                                                <td>{(key + 1) + (currentPage - 1) * 10}</td>
                                                <td>{user.fullName ? user.fullName : '--'}</td>
                                                {/* <td className="table-status" >{user.role === 'backendSales' ? 'Sales' : user.role === 'backendOperations' ? 'Ops' : user.role === 'backendFinance' ? 'Finance' : user.role === 'backendTech' ? 'Tech' : user.role === 'backendAdmin' ? 'Super Admin' : '--'}</td> */}
                                                <td className="table-status" >{user.actualRole === 'backendSalesPerson' ? 'Sales Person': user.actualRole === 'backendSalesAdmin' ? 'Sales Admin' : user.actualRole === 'backendOperations' ? 'Ops' : user.actualRole === 'backendFinance' ? 'Finance' : user.actualRole === 'backendTech' ? 'Tech' : user.actualRole === 'backendAdmin' ? 'Super Admin' : '--'}</td>
                                                <td>{user.city ? user.city : '--'}</td>
                                                <td><a href="javascript:void(0);"><img src={editBtn} onClick={() => { setIsFOSEdit(true); setUserId(user._id); setOverlay(true); }} /></a>
                                                    {/* <Link
                                            to={{
                                                pathname: "/backend/backend-details/fos-details",
                                                state: { userId: user._id }
                                            }}> <img className="updownarrow" src={arrowUpDown} onClick={() => setUserId(user._id)} /> </Link> */}
                                                </td>
                                            </tr>
                                        )
                                    })
                                    }
                                </>
                                :
                                <tr><td colSpan={7}>No User found</td></tr>
                            }

                        </>
                        :
                        [...Array(10)].map((el, index) => {
                            return (
                                <tr>
                                    {
                                        [...Array(6)].map((el, index) => {
                                            return (
                                                <td>
                                                    <div className="content-loader-block"></div>
                                                </td>
                                            )
                                        })
                                    }
                                </tr>
                            )
                        })
                    }

                </tbody>
            </table>
            {totalPageCount>10 &&<Pagination
                currentPage={currentPage}
                totalPage={totalPage}
                onPageChange={onPageChange}
                onNextClick={onNextClick}
                onPrevClick={onPrevClick}
                cityFilterModal={cityFilterModal}
                loading={loading}
            />}
        </div>
    )
}
export default UserTable;

