import React from "react"

const InventorySVG = () => {

    return (
        <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.3703 8.55556V3C14.3703 1.89543 13.4749 1 12.3703 1H7.2222C6.11763 1 5.2222 1.89543 5.2222 3V8.55556" stroke="#070707" strokeWidth="1.2"/>
            <path d="M8.0368 1H11.5553V3.11111C11.5553 3.6634 11.1076 4.11111 10.5553 4.11111H9.0368C8.48451 4.11111 8.0368 3.6634 8.0368 3.11111V1Z" stroke="#070707" strokeWidth="1.2"/>
            <path d="M1 10.5555C1 9.45097 1.89543 8.55554 3 8.55554H8.14814C9.25271 8.55554 10.1481 9.45097 10.1481 10.5555V15C10.1481 16.1046 9.25272 17 8.14815 17H3C1.89543 17 1 16.1046 1 15V10.5555Z" stroke="#070707" strokeWidth="1.2"/>
            <path d="M3.81434 8.55554H7.33286V10.6667C7.33286 11.2189 6.88514 11.6667 6.33286 11.6667H4.81434C4.26205 11.6667 3.81434 11.2189 3.81434 10.6667V8.55554Z" stroke="#070707" strokeWidth="1.2"/>
            <path d="M10.1478 10.5555C10.1478 9.45097 11.0432 8.55554 12.1478 8.55554H17.2959C18.4005 8.55554 19.2959 9.45097 19.2959 10.5555V15C19.2959 16.1046 18.4005 17 17.2959 17H12.1478C11.0432 17 10.1478 16.1046 10.1478 15V10.5555Z" stroke="#070707" strokeWidth="1.2"/>
            <path d="M12.9633 8.55554H16.4818V10.6667C16.4818 11.2189 16.0341 11.6667 15.4818 11.6667H13.9633C13.411 11.6667 12.9633 11.2189 12.9633 10.6667V8.55554Z" stroke="#070707" strokeWidth="1.2"/>
        </svg>        
    )
}

export default InventorySVG
