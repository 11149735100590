import React, { useEffect, useState, useContext, useRef } from "react";
import { useHistory } from "react-router-dom";
import uploadSvg from "../../../../assets/images/upload-svg.svg";
import sucessCheck from "../../../../assets/images/sucess-check.svg";
import closeSvg from "../../../../assets/images/close-svg.svg";
import blockSvg from "../../../../assets/images/block-svg.svg";
import reloadSvg from "../../../../assets/images/reload-svg.svg";
import filterSvg from "../../../../assets/images/filter-svg.svg";
import filterArrowSvg from "../../../../assets/images/filter-arrow-svg.svg";
import mapsImg from "../../../../assets/images/maps.jpg";
import creativeImgViewOne from "../../../../assets/images/img-view-one.jpg";
import creativeImgViewTwo from "../../../../assets/images/img-view-two.jpg";
import creativeImgViewThree from "../../../../assets/images/img-view-three.jpg";
import imgInventory from "../../../../assets/images/blank-inventory-img.jpg";
import AppContextObject from "../../../../common/context/common";
import { formatDateShowMonth, titleCase } from "../../../../service/helper";
import DataContext from "../../../../service/brandContext";
import CampaignBudget from "../components/campaignBudget";
import GenderSplit from "../components/genderSplit";
import CampaignReach from "../components/campaignReach";
import CampaignImpression from "../components/campaignImpression";
import { returnReadableStatus } from "../../../../service/helper";
import TextField from "../../../../components/formFields/textField/textField";
import SimpleReactValidator from "simple-react-validator";
import errorSVG from "../../../../assets/images/error.svg";
import editSvg from "../../../../assets/images/edit-btn.svg";
import TextArea from "../../../../components/formFields/textArea";
import BrandMou from "../components/brandMou";
import AlertDialog from "../../../../components/addon/alertDialog/alertDialog";
import BrandBackBtn from "../components/brandBackBtn";
import ConfirmDialog from "../../../../components/addon/confirmDialog/confirmDialog";
import { ReactInternetSpeedMeter } from "react-internet-meter";

const CampaignPreview = ({ reason, setShowProgressSideMenu }) => {
	const history = useHistory();
	const [formValidator] = useState(
		useRef(
			new SimpleReactValidator({
				element: (message, className) => (
					<span className="valError">
						<img src={errorSVG} />
						{message}
					</span>
				),
			})
		)
	);
	const context = useContext(AppContextObject);
	const parentData = useContext<any>(DataContext);

	const [update, forceUpdate] = useState(false);
	const [showAlertMessage, setShowAlertMessage] = useState(false);
	const [pageToRedirect, setPageToRedirect] = useState("");
	const [totalBudget, setTotalBudget] = useState(
		parentData?.data?.campaignDetail?.totalBudget
	);
	const [totalReach, setTotalReach] = useState(
		parentData?.data?.campaignDetail?.totalReach
	);
	const [boysRatio, setBoysRatio] = useState(
		parentData?.data?.campaignDetail?.boysRatio
	);
	const [girlsRatio, setGirlsRatio] = useState(
		parentData?.data?.campaignDetail?.girlsRatio
	);
	const [totalImpression, setTotalImpression] = useState(
		parentData?.data?.campaignDetail?.totalImpression
	);
	const [overlay, setOverlay] = useState(false);
	const [isTermAndConditionCheck, setIsTermAndConditionCheck] = useState(false);
	const [showEditBrandDetail, setShowEditBrandDetail] = useState(false);
	const [campaignName, setCampaignName] = useState({ name: "" });
	const [campaignReason, setCampaignReason] = useState({ reason: "" });
	const [campaignDescription, setCampaignDescription] = useState({
		description: "",
	});
	const [showMou, setShowMou] = useState(false);
	const [location, setLocation] = useState([]);
	const [alertMessage, setAlertMessage] = useState("");
	const [title, setTitle] = useState("");
	const [buttonText, setButtonText] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [wifiSpeed, setwifiSpeed] = useState<any>(null);
	const [pingIntervalTime, setPingIntervalTime] = useState(1000);
	const [checkSpeed, setCheckSpeed] = useState(false);
	const [canSaveData, setCanSaveData] = useState<any>(null);
	const [toggleNetworkDialog, setToggleNetworkDialog] = useState(false);
	const [isInternetOK, setIsInternetOK] = useState(false);
	const [loading, setLoading] = useState(false);
	const [apiHited, setApiHited] = useState(false);

	// const [reason, setReason] = useState({
	// 	reason: parentData?.data?.brandDetail?.reason,
	// });

	useEffect(() => {
		if (
			parentData.data.school.schools &&
			parentData.data.school.schools.length > 0
		) {
			setLocation([...parentData.data.school.locations]);
		}
		if (parentData.data.brandDetail.isTrial) {
			setIsTermAndConditionCheck(true);
		}
	}, []);
	useEffect(() => {
		setShowProgressSideMenu(true);
		if (parentData.setActiveModule) {
			parentData.setActiveModule({
				...parentData.activeModule,
				module: "preview",
			});
		}
		if (parentData && parentData.data) {
			setCampaignName({ name: parentData.data.brandDetail.name });
			setCampaignDescription({
				description: parentData.data.brandDetail.description,
			});
			setCampaignReason({ reason: parentData.data.brandDetail.reason });
		}
		setTotalBudget(parentData.data.campaignDetail.totalBudget);
		setTotalReach(parentData.data.campaignDetail.totalReach);
		setTotalImpression(parentData.data.campaignDetail.totalImpression);
		setGirlsRatio(parentData.data.campaignDetail.girlsRatio);
		setBoysRatio(parentData.data.campaignDetail.boysRatio);
	}, []);
	useEffect(() => {
		setwifiSpeed(null);
		setCanSaveData(null);
	}, []);

	useEffect(() => {
		console.log("wifiSpeed=====>", wifiSpeed, history.location.pathname);
		if (
			history.location.pathname ===
				"/brands/brand-details/create-campaign/preview" ||
			history.location.pathname ===
				"/brands/brand-details/create-trial-campaign/preview"
		) {
			console.log("wifiSpeed--->>>>>", wifiSpeed);
			if (wifiSpeed !== null) {
				if (Number(wifiSpeed) < context.utils.internetSpeedLimit) {
					console.log("wifiSpeed and networkOK 1", wifiSpeed, isInternetOK);
					setIsInternetOK(false);
					setCanSaveData(false);
					setIsLoading(false);
					setToggleNetworkDialog(true);
					setOverlay(true);
				} else {
					if (!apiHited) {
						console.log("wifiSpeed and networkOK 2", wifiSpeed, isInternetOK);
						setIsInternetOK(true);
						console.log("wifiSpeed>>>>", wifiSpeed);
						setCanSaveData(true);
						setApiHited(true);
						handleSubmit();
					}
				}
			} else {
			}
		}
	}, [wifiSpeed]);
	console.log("canSaveData", canSaveData);

	const testSpeed = () => {
		setCheckSpeed(true);
		console.log("test speed hitted");
	};

	const networkConfirmClose = (confirm) => {
		setToggleNetworkDialog(false);
		setwifiSpeed(null);
		setCanSaveData(null);
		setCheckSpeed(false);
		setOverlay(false);
	};

	const onCampaignNameChange = (e) => {
		setCampaignName({ name: e.target.value });
	};
	const onDescriprionChange = (e) => {
		setCampaignDescription({ description: e.target.value });
	};
	const openEditModal = () => {
		setCampaignName({ name: parentData.data.brandDetail.name });
		setCampaignDescription({
			description: parentData.data.brandDetail.description,
		});
		setOverlay(true);
		setShowEditBrandDetail(true);
	};
	const saveCampaignDetail = () => {
		const result = formValidator.current.allValid();
		if (result === false) {
			formValidator.current.showMessages();
			forceUpdate(true);
			return;
		} else {
			formValidator.current.hideMessages();
			forceUpdate(!update);
		}
		if (parentData && parentData.setData) {
			parentData.setData({
				...parentData.data,
				brandDetail: {
					...parentData.data.brandDetail,
					name: campaignName.name,
					description: campaignDescription.description,
				},
			});
		}
		setOverlay(false);
		setShowEditBrandDetail(false);
	};
	const handleSubmit = async () => {
		if (parentData) {
			if (parentData.setProgress) {
				parentData.setProgress({
					...parentData.progress,
					preview: true,
				});
			}
			try {
				let apiData = formateData();
				console.log("apiData-----", apiData);
				if (apiData["_id"]) {
					console.log(apiData);
					if (!parentData.editFromConfirmationPage) {
						apiData["triggerBackendAdminNotification"] = true;
					}
					let resp = await context.apis[context.user.role].editCampaign(
						context.validator,
						apiData
					);
					console.log("resp---", resp);
					if (resp && resp.done) {
						if (parentData.data.brandDetail.isTrial) {
							history.push(
								"/brands/brand-details/create-trial-campaign/confirm"
							);
						} else {
							history.push("/brands/brand-details/create-campaign/confirm");
						}
						setIsLoading(false);
					}
				} else {
					let resp = await context.apis[context.user.role].addCampaign(
						context.validator,
						apiData
					);
					console.log("resp---", resp);
					if (resp && resp.done) {
						let _id = resp.campaign._id;
						parentData.setData({
							...parentData.data,
							brandDetail: { ...parentData.data.brandDetail, _id: _id },
						});
						if (parentData.data.brandDetail.isTrial) {
							history.push(
								"/brands/brand-details/create-trial-campaign/confirm"
							);
						} else {
							history.push("/brands/brand-details/create-campaign/confirm");
						}
						setIsLoading(false);
					}
				}
			} catch (err) {
				console.log("error", err);
				setIsLoading(false);
			}
		}
	};

	const onSave = () => {
		if (isLoading) {
			return;
		}
		setIsLoading(true);
		testSpeed();
	};

	const formateData = () => {
		let inventoriesIdArray = [];
		parentData.data.inventory.usedInventories.forEach((inventory) => {
			let attributesIdArray = [];
			inventory.placements &&
				Array.isArray(inventory.placements) &&
				inventory.placements.length > 0 &&
				inventory.placements.map((item) => {
					attributesIdArray.push(item.name);
				});
			let obj = {
				_id: inventory._id,
				name: inventory.parentName || inventory.name,
				attributes: [...attributesIdArray],
				_custom: inventory._custom || false,
			};
			inventoriesIdArray.push(obj);
		});
		let eventIdArray = [];
		parentData.data.eventAndOccation.usedEvents.forEach((event) => {
			let attributesIdArray = [];
			event.placements &&
				Array.isArray(event.placements) &&
				event.placements.map((item) => {
					attributesIdArray.push(item._id || item.name);
				});
			let obj = {
				_id: event._id,
				name: event.name,
				attributes: [...attributesIdArray],
			};
			eventIdArray.push(obj);
		});

		let imageArray = [];
		if (parentData.data.campaignCreative.creatives.image1.key) {
			imageArray.push(parentData.data.campaignCreative.creatives.image1.key);
		}
		if (parentData.data.campaignCreative.creatives.image2.key) {
			imageArray.push(parentData.data.campaignCreative.creatives.image2.key);
		}
		if (parentData.data.campaignCreative.creatives.image3.key) {
			imageArray.push(parentData.data.campaignCreative.creatives.image3.key);
		}
		let schoolIdArray = parentData.data.school.schools.map(
			(school) => school._id
		);
		console.log("parentData ----", parentData);
		let obj = {
			brandId: parentData.data.brandDetail.brandId,
			name: parentData.data.brandDetail.name.trim(),
			description: parentData.data.brandDetail.description.trim(),
			objective: parentData.data.brandDetail.objective.trim(),
			startDate: parentData.data.brandDetail.startDate,
			endDate: parentData.data.brandDetail.endDate,
			locations: [...parentData?.data?.school?.locations],
			targetGender:
				parentData.data.brandDetail.targetGender === "both"
					? "co-ed"
					: parentData.data.brandDetail.targetGender,
			targetAgeGroup: [
				parentData.data.brandDetail.targetAgeGroup.min,
				parentData.data.brandDetail.targetAgeGroup.max,
			],
			studyMedium: [...parentData.data.brandDetail.studyMedium],
			states: [...parentData.data.brandDetail.state],
			cities: [...parentData.data.brandDetail.city],
			brandCategory: parentData.data.brandDetail.brandCategory._id,
			brandSubcategory: parentData.data.brandDetail.brandSubcategory.value,
			brandName: parentData.data.brandDetail.brandName,
			recommendEvents: false,
			campaignStatus: parentData.data.brandDetail.isTrial
				? "trial"
				: "in-review",
			isApproved: false,
			campaignCreatives: [...imageArray],
			budget: totalBudget,
			totalImpression: totalImpression,
			totalReach: totalReach,
			isTrial: parentData.data.brandDetail.isTrial,
			wisrOffering: {
				inventory: parentData.data.brandDetail.inventory,
				eventAndOccations: parentData.data.brandDetail.eventAndOccations,
				csr: parentData.data.brandDetail.csr,
				sampling: parentData.data.brandDetail.sampling,
				custom: parentData.data.brandDetail.custom,
			},
			campaignData: {
				inventories: [...inventoriesIdArray],
				schools: [...schoolIdArray],
				activities: [...eventIdArray],
				schoolListDetail: [...parentData.data.school.schools],
				reason: parentData?.data?.campaignData?.reason,
			},
		};
		if (parentData.data.brandDetail._id) {
			obj["_id"] = parentData.data.brandDetail._id;
		}
		return obj;
	};
	const onToggle = () => {
		setShowMou(false);
	};
	const onAlertMessageClose = (confirm) => {
		if (confirm) {
			history.push({
				pathname: parentData.data.brandDetail.isTrial
					? `/brands/brand-details/create-trial-campaign/${pageToRedirect}`
					: `/brands/brand-details/create-campaign/${pageToRedirect}`,
				search: `isSummary=${true}`,
				state: { from: "createCampaignFlow" },
			});
		}
		setShowAlertMessage(false);
		setOverlay(false);
		setAlertMessage("");
	};
	const openAlert = (page) => {
		if (page === "set-up-campaign") {
			setAlertMessage(
				"The inventory list is recommended based on the campaign requirements. If you proceed to edit, an updated inventory list will appear."
			);
			setTitle("");
			setButtonText("");
		} else if (page === "inventory") {
			setAlertMessage(
				"The schools are recommended based on the availability of inventory. If you proceed to edit the inventory, a revised school list will appear."
			);
			setTitle("Heads Up!");
			setButtonText("EDIT INVENTORIES");
		} else if (page === "events") {
			setAlertMessage(
				"The events are selected on the basis of availability & school selected. By proceeding to edit the events  you will have to reselect your schools for this campaign."
			);
			setTitle("");
			setButtonText("");
		}
		setPageToRedirect(page);
		setShowAlertMessage(true);
		setOverlay(true);
	};
	return (
		<>
			<div className={`ovelap ${overlay === true && "active"}`}></div>
			{checkSpeed && (
				<ReactInternetSpeedMeter
					txtSubHeading="Internet is too slow"
					outputType="alert"
					customClassName={"slowInternetMsg"}
					txtMainHeading="Opps..."
					pingInterval={pingIntervalTime} // milliseconds
					thresholdUnit="megabyte" // "byte" , "kilobyte", "megabyte"
					threshold={100}
					imageUrl={context.utils.internetSpeedTestingImg}
					downloadSize="2550420" //bytes
					callbackFunctionOnNetworkDown={(speed) =>
						console.log(`Internet speed is down ${speed}`)
					}
					callbackFunctionOnNetworkTest={(speed) => {
						console.log(`Internet Speed is ${speed} MBps`);
						setwifiSpeed(speed);
						setCheckSpeed(false);
					}}
				/>
			)}
			{/* <TopBar isDashboard={true} /> */}
			<div className="col-xl-9 col-lg-12 col-md-12 col-xs-12">
				<div className="rightPart noBack">
					<div className="row">
						<div className="col-xs-12 col-lg-12 col-md-12 col-xs-12">
							<BrandBackBtn />
						</div>
						<div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-20">
							<h2 className="htx5 clearfix mt-10 mb-20">Create a Campaign</h2>
						</div>
						<div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-30">
							<h2 className="htx2 clearfix mb-10">Campaign Preview</h2>
						</div>
						<div className="col-xl-8 col-lg-8 col-md-12 col-xs-12 mb-20 pl">
							<div className="row">
								<div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
									<div className="personal-box">
										<div className="row personal-box-top">
											<div className="row col-xl-8 col-lg-8 col-md-8 col-xs-8 start-md">
												<h3 className="htx2 mb20">Campaign Details</h3>
											</div>
											<div className="row col-xl-4 col-lg-4 col-md-4 col-xs-4 end-xs">
												<a
													href="javascript:void(0)"
													className="editbtn"
													onClick={openEditModal}
												>
													<img src={editSvg} /> Edit
												</a>
											</div>
										</div>
										<div className="personal-bottom">
											<div className="row personal-box-bot">
												<div className="col-xl-5 col-lg-5 col-md-6 col-xs-12">
													<p>Name</p>
												</div>
												<div className="col-xl-7 col-lg-7 col-md-6 col-xs-12">
													<b>{parentData?.data?.brandDetail?.name}</b>
												</div>
												<div className="col-xl-5 col-lg-5 col-md-6 col-xs-12">
													<p>Description</p>
												</div>
												<div className="col-xl-7 col-lg-7 col-md-6 col-xs-12">
													<b>{parentData?.data?.brandDetail?.description}</b>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
									<div className="personal-box">
										<div className="row personal-box-top">
											<div className="row col-xl-8 col-lg-8 col-md-8 col-xs-8 start-md">
												<h3 className="htx2 mb20">Campaign Specifications</h3>
											</div>
											<div className="row col-xl-4 col-lg-4 col-md-4 col-xs-4 end-xs">
												<a
													href="javascript:void(0)"
													className="editbtn"
													onClick={() => openAlert("set-up-campaign")}
												>
													<img src={editSvg} /> Edit
												</a>
											</div>
										</div>
										<div className="personal-bottom">
											<div className="row personal-box-bot">
												<div className="col-xl-5 col-lg-5 col-md-6 col-xs-12">
													<p>Start Date</p>
												</div>
												<div className="col-xl-7 col-lg-7 col-md-6 col-xs-12">
													<b>
														{parentData?.data?.brandDetail?.startDate !== ""
															? formatDateShowMonth(
																	parentData?.data?.brandDetail?.startDate,
																	2
															  )
															: ""}
													</b>
												</div>
												<div className="col-xl-5 col-lg-5 col-md-6 col-xs-12">
													<p>End Date</p>
												</div>
												<div className="col-xl-7 col-lg-7 col-md-6 col-xs-12">
													<b>
														{parentData?.data?.brandDetail?.endDate !== ""
															? formatDateShowMonth(
																	parentData?.data?.brandDetail?.endDate,
																	2
															  )
															: ""}
													</b>
												</div>
												<div className="col-xl-5 col-lg-5 col-md-6 col-xs-12">
													<p>Location</p>
												</div>
												<div className="col-xl-7 col-lg-7 col-md-6 col-xs-12">
													<b>
														{location &&
															location.length > 0 &&
															location.map((item, index) => (
																<React.Fragment key={index}>
																	{index === location.length - 1
																		? `${titleCase(item)}`
																		: `${titleCase(item)}, `}
																</React.Fragment>
															))}
													</b>
												</div>
												<div className="col-xl-5 col-lg-5 col-md-6 col-xs-12">
													<p>Target Gender</p>
												</div>
												<div className="col-xl-7 col-lg-7 col-md-6 col-xs-12">
													<b>
														{parentData?.data?.brandDetail?.targetGender ===
														"both"
															? "Boys & Girls"
															: returnReadableStatus(
																	parentData?.data?.brandDetail?.targetGender
															  )}
													</b>
												</div>

												<div className="col-xl-5 col-lg-5 col-md-6 col-xs-12">
													<p>Target Age Group</p>
												</div>
												<div className="col-xl-7 col-lg-7 col-md-6 col-xs-12">
													<b>{`${parentData?.data?.brandDetail?.targetAgeGroup?.min} yo - ${parentData?.data?.brandDetail?.targetAgeGroup?.max} yo`}</b>
												</div>
												<div className="col-xl-5 col-lg-5 col-md-6 col-xs-12">
													<p>Medium of Study</p>
												</div>
												<div className="col-xl-7 col-lg-7 col-md-6 col-xs-12">
													<span className="medium-break">
														{parentData.data.brandDetail.studyMedium.length !==
															0 &&
															parentData.data.brandDetail.studyMedium.map(
																(item, index) => (
																	<b
																		style={{ wordBreak: "initial" }}
																		key={index}
																	>
																		{index <
																		parentData.data.brandDetail.studyMedium
																			.length -
																			1
																			? `${item}, `
																			: item}
																	</b>
																)
															)}
													</span>
												</div>
												<div className="col-xl-5 col-lg-5 col-md-6 col-xs-12">
													<p>Brand Category</p>
												</div>
												<div className="col-xl-7 col-lg-7 col-md-6 col-xs-12">
													<b>
														{parentData?.data?.brandDetail?.brandCategory
															.label &&
															parentData?.data?.brandDetail?.brandCategory
																.label}
													</b>
												</div>
												<div className="col-xl-5 col-lg-5 col-md-6 col-xs-12">
													<p>No of Schools Occupied</p>
												</div>
												<div className="col-xl-7 col-lg-7 col-md-6 col-xs-12">
													<b>{parentData?.data?.school?.schools.length}</b>
												</div>
											</div>
										</div>
									</div>
								</div>
								{parentData.data.brandDetail.inventory && (
									<div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
										<div className="personal-box">
											<div className="row personal-box-top">
												<div className="row col-xl-8 col-lg-8 col-md-8 col-xs-8 start-md">
													<h3 className="htx2 mb20">Inventory Details</h3>
												</div>
												<div className="row col-xl-4 col-lg-4 col-md-4 col-xs-4 end-xs">
													<a
														href="javascript:void(0)"
														className="editbtn"
														onClick={() => openAlert("inventory")}
													>
														<img src={editSvg} /> Edit
													</a>
												</div>
											</div>
											<div className="personal-bottom">
												<div className="row personal-box-bot">
													<div className="col-xl-12 col-lg-12 col-md-6 col-xs-12">
														<h4 className="htx3 mb-10">Inventory Added</h4>
													</div>
													<div className="col-xl-12 col-lg-12 col-md-6 col-xs-12">
														<div className="schoolNameTable">
															{parentData?.data?.inventory.usedInventories
																.length > 0 &&
																parentData?.data?.inventory.usedInventories.map(
																	(inventory, index) => (
																		<span key={inventory._id}>
																			{inventory.parentName || inventory.name}
																		</span>
																	)
																)}
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								)}
								{parentData.data.brandDetail.eventAndOccations && (
									<div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
										<div className="personal-box">
											<div className="row personal-box-top">
												<div className="row col-xl-8 col-lg-8 col-md-8 col-xs-8 start-md">
													<h3 className="htx2 mb20">Event Details</h3>
												</div>
												<div className="row col-xl-4 col-lg-4 col-md-4 col-xs-4 end-xs">
													<a
														href="javascript:void(0)"
														className="editbtn"
														onClick={() => openAlert("events")}
													>
														<img src={editSvg} /> Edit
													</a>
												</div>
											</div>
											<div className="personal-bottom">
												<div className="row personal-box-bot">
													<div className="col-xl-12 col-lg-12 col-md-6 col-xs-12">
														<h4 className="htx3 mb-10">Events Selected</h4>
													</div>
													<div className="col-xl-12 col-lg-12 col-md-6 col-xs-12">
														<div className="schoolNameTable">
															{parentData?.data?.eventAndOccation.usedEvents
																.length > 0 &&
																parentData?.data?.eventAndOccation.usedEvents.map(
																	(event, index) => (
																		<span key={event._id}>{event.name}</span>
																	)
																)}
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								)}

								<div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
									<div className="personal-box">
										<div className="row personal-box-top">
											<div className="row col-xl-8 col-lg-8 col-md-8 col-xs-8 start-md">
												<h3 className="htx2 mb20">Campaign Creatives</h3>
											</div>
											<div className="row col-xl-4 col-lg-4 col-md-4 col-xs-4 end-xs">
												<a
													href="javascript:void(0)"
													className="editbtn"
													onClick={() =>
														history.push({
															pathname: parentData.data.brandDetail.isTrial
																? "/brands/brand-details/create-trial-campaign/creative"
																: "/brands/brand-details/create-campaign/creative",
															search: `isSummary=${true}`,
														})
													}
												>
													<img src={editSvg} /> Edit
												</a>
											</div>
										</div>
										<div className="personal-bottom">
											<div className="row personal-box-bot">
												<div className="col-xl-12 col-lg-12 col-md-6 col-xs-12">
													<h4 className="htx3 mb-10">Creatives Uploaded</h4>
												</div>
												<div className="col-xl-12 col-lg-12 col-md-6 col-xs-12">
													<div className="schoolNameTable">
														<div className="creativeImgView">
															<div className="creativeImg">
																<img
																	src={
																		parentData?.data?.campaignCreative
																			?.creatives?.image1?.image || imgInventory
																	}
																/>
															</div>
															<div className="creativeImg">
																<img
																	src={
																		parentData?.data?.campaignCreative
																			?.creatives?.image2?.image || imgInventory
																	}
																/>
															</div>
															<div className="creativeImg">
																<img
																	src={
																		parentData?.data?.campaignCreative
																			?.creatives?.image3?.image || imgInventory
																	}
																/>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								{/* } */}
								<div className="row col-xl-8 col-lg-8 col-md-8 col-xs-12 middle-xs">
									<div className="forminpt">
										{!parentData.data.brandDetail.isTrial && (
											<div className="checkbox onboardcheck">
												<input
													type="checkbox"
													id="html-term"
													checked={isTermAndConditionCheck}
													onClick={(e: any) =>
														setIsTermAndConditionCheck(e.target.checked)
													}
												/>
												<label
													style={{ display: "inline" }}
													htmlFor="html-term"
												></label>{" "}
												<b>I have read and agree to the </b>
												<a
													style={{ cursor: "pointer", color: "#fe6e00" }}
													onClick={() => setShowMou(true)}
												>
													Terms &amp; Conditions
												</a>
											</div>
										)}
									</div>
								</div>
								<div className="row col-xl-4 col-lg-4 col-md-4 col-xs-12 end-md">
									<div className="forminpt ">
										<a
											href="javascript:void(0);"
											onClick={onSave}
											className={`btn btn-orange comp-seting ${
												!isTermAndConditionCheck && "btn-disabled"
											} ${
												isLoading &&
												"btn btn-orange button--loading btn-disabled"
											}`}
										>
											SET UP CAMPAIGN
										</a>
									</div>
								</div>
							</div>
						</div>
						<div className="col-xl-4 col-lg-4 col-md-12 col-xs-12 mb-20">
							<div className="w90">
								<CampaignBudget
									totalBudget={totalBudget}
									setTotalBudget={setTotalBudget}
									setOverlay={setOverlay}
									disable={true}
								/>
								<GenderSplit girlsRatio={girlsRatio} boysRatio={boysRatio} />
								<CampaignReach
									totalReach={totalReach}
									setTotalReach={setTotalReach}
									disable={true}
								/>
								<CampaignImpression
									totalImpression={totalImpression}
									setTotalImpression={setTotalImpression}
									disable={true}
									setEventAndInventory = {parentData.data.brandDetail}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			<BrandMou showMou={showMou} onToggle={onToggle} />
			<div
				id="editCampaignDetail"
				className={`popup ${showEditBrandDetail === false ? "hide" : ""}`}
			>
				<div className="popup-body">
					<div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
						<h2>
							Campaign Details{" "}
							<a
								style={{ cursor: "pointer" }}
								onClick={() => {
									setShowEditBrandDetail(false);
									setOverlay(false);
								}}
							>
								Cancel
							</a>
							<a
								style={{ cursor: "pointer", marginRight: "15px" }}
								onClick={saveCampaignDetail}
							>
								<svg
									width="10"
									height="7"
									viewBox="0 0 10 7"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									{" "}
									<path
										d="M1 3.5191L3.37713 5.9L8.27462 1"
										stroke="#070707"
										stroke-width="1.5"
										stroke-linecap="round"
										stroke-linejoin="round"
									/>{" "}
								</svg>{" "}
								Save
							</a>
						</h2>
					</div>
					<div className="row">
						<div className="col-xl-4 col-lg-4 col-md-12 col-xs-12">
							<label className="lableEdit">Name</label>
						</div>
						<div className="col-xl-8 col-lg-8 col-md-12 col-xs-12">
							<div className="forminpt">
								<TextField
									formData={campaignName}
									formKey="name"
									onDataChange={onCampaignNameChange}
									placeholder="Enter Campaign Name"
									isRequired={false}
									formValidator={formValidator}
									validation="required"
								/>
							</div>
						</div>
						<div className="col-xl-4 col-lg-4 col-md-12 col-xs-12">
							<label className="lableEdit">Description</label>
						</div>
						<div className="col-xl-8 col-lg-8 col-md-12 col-xs-12">
							<div className="forminpt">
								<TextArea
									formData={campaignDescription}
									formKey="description"
									onDataChange={onDescriprionChange}
									placeholder="Write a few sentences about the campaign"
									isRequired={false}
									formValidator={formValidator}
									validation="required"
									maxLength={500}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			<AlertDialog
				onModalClose={onAlertMessageClose}
				title={title}
				togglePopup={showAlertMessage}
				displayMsg={alertMessage}
				showBtn={true}
			/>
			<ConfirmDialog
				confirmTitle="Poor Internet Connection Detected"
				componentName="We've detected issues with your internet connection that could affect campaign creation & data loss. Please check your internet connection & try again."
				confirmText="Got it"
				denyText="Cancel"
				togglePopup={toggleNetworkDialog}
				onModalClose={networkConfirmClose}
				onConfirmBtn={true}
			/>
		</>
	);
};

export default CampaignPreview;
