import React, { useContext, useEffect, useState } from 'react'
import { Route, Switch, useHistory } from 'react-router'
import AppContextObject from '../../../../common/context/common'
import ProfileAddUsers from './addUsers'
import ProfileContact from './contact'
import ProfileDescription from './description'
import ProfileGeneral from './general'
import BrandProfileSidemenu from './sidemenu'
import ProfileVerification from './verification'
import DataContext, { DataContextProvider }  from "../../../../service/brandContext"
import { useRouteMatch } from 'react-router-dom'

function CreateBrandBackend(props) {
    
    const context = useContext(AppContextObject)
    const parentData = useContext(DataContext)
    const history = useHistory()
    const router = useRouteMatch()

    const [brandProfile, setBrandProfile] = useState<any>({
        "general": {
            "companyName": "",
            "companyCategory": "",
            "companyWebsite": "",
            "socialMedia": {
                "twitter": "",
                "linkedIn": "",
                "other": []
            }
        },
        "companyDescription": {
            "description": ""
        },
        "contact": {
            "country": "",
            "state": "",
            "city": "",
            "address": "",
            "pincode": "",
            "mobileNo": ""
        },
        "verification": {
            "gst": {
                "image": "",
                "documentNo": "",
                "status": "required"
            },
            "pan": {
                "image": "",
                "documentNo": "",
                "status": "required"
            }
        },
        "users": [
            {
                "memberName": "",
                "memberEmail": "",
                "memberDesignation": ""
            },
        ],
        "removeUserIds": [],
        "subCategories": [],
        "subCategoryList": [],
        "uniqueSubCategoryList": [],
        "brandNameList": [],
        "myBrandDetails": {},
        "otherBrandCategory": false
    })
    const [refetch, setRefetch] = useState(false)
    const [otherBrandCategory, setOtherBrandCategory] = useState(false)
    const [progress, setProgress] = useState({
        general: false,
        description: false,
        verification: false,
        contact: false,
        addUsers: false
    })

    const [activeModule, setActiveModule] = useState({
        module: "",
        subModule: ""
    })
    useEffect(()=>{
        if(context.user.role!=='backendAdmin' && context.user.role!=='backendTech' && context.user.role!=='backendSales'){
            checkBrandSetup()
        }
    },[history.location.pathname])

    const checkBrandSetup = async () => {
        const resp = await context.apis[context.user.role].getBrandSetupByUserId(
            context.validator, {}, {}
        )   
        console.log("checkBrandSetup resp>>>>>>",resp);

        if(resp && resp.done){
            // if(context.user.role === "brandCMO"){
            //     if(resp.response.haveCategory){
            //     }else{
            //         history.push("/brands/brand-details/profile")
            //         // toast.error("Please complete your profile")
            //     }
            // }else if(context.user.role === "brandManager"){
                if(resp.response.haveCategory){
                    history.push("/brands/brand-details/profile")
                }
            // }
        }
    }

    const myValue:any = {
        brandProfile,
        setBrandProfile,
        refetch,
        setRefetch,
        otherBrandCategory,
        setOtherBrandCategory
    }
    console.log("context,parentData",context,parentData, `${router.url}/general`)
    return (
        <>
        <DataContextProvider value={myValue}>
            <BrandProfileSidemenu
                progress={progress}
                activeModule={activeModule}
            />

            <Switch>
                <Route path={`${router.url}/general`}>
                    <ProfileGeneral
                        events={{
                            "setProgress": setProgress,
                            "setActiveModule": setActiveModule,
                            "setBrandProfile": setBrandProfile
                        }}
                        progress={progress}
                        activeModule={activeModule}
                        brandProfile={brandProfile}
                        parentData={parentData}
                        isBackendUser={true}
                    />
                </Route>

                <Route path={`${router.url}/description`}>
                    <ProfileDescription
                        events={{
                            "setProgress": setProgress,
                            "setActiveModule": setActiveModule,
                            "setBrandProfile": setBrandProfile
                        }}
                        progress={progress}
                        activeModule={activeModule}
                        brandProfile={brandProfile}
                        isBackendUser={true}
                    />
                </Route>

                <Route path={`${router.url}/contact`}>
                    <ProfileContact
                        events={{
                            "setProgress": setProgress,
                            "setActiveModule": setActiveModule,
                            "setBrandProfile": setBrandProfile
                        }}
                        progress={progress}
                        activeModule={activeModule}
                        brandProfile={brandProfile}
                        isBackendUser={true}
                    />
                </Route>

                <Route path={`${router.url}/verification`}>
                    <ProfileVerification
                        events={{
                            "setProgress": setProgress,
                            "setActiveModule": setActiveModule,
                            "setBrandProfile": setBrandProfile
                        }}
                        progress={progress}
                        activeModule={activeModule}
                        brandProfile={brandProfile}
                        parentData={parentData}
                        isBackendUser={true}
                    />
                </Route>

                <Route path={`${router.url}/add-users`}>
                    <ProfileAddUsers
                        events={{
                            "setProgress": setProgress,
                            "setActiveModule": setActiveModule,
                            "setBrandProfile": setBrandProfile
                        }}
                        progress={progress}
                        activeModule={activeModule}
                        brandProfile={brandProfile}
                        isBackendUser={true}
                    />
                </Route>
            </Switch>
            </DataContextProvider>
        </>
    )
}

export default CreateBrandBackend;
