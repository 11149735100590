import React, { useEffect, useState, useContext, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import uploadSvg from "../../../../assets/images/upload-svg.svg"
import sucessCheck from "../../../../assets/images/sucess-check.svg"
import closeSvg from "../../../../assets/images/close-svg.svg"
import blockSvg from "../../../../assets/images/block-svg.svg"
import reloadSvg from "../../../../assets/images/reload-svg.svg"
import filterSvg from "../../../../assets/images/filter-svg.svg"
import filterArrowSvg from "../../../../assets/images/filter-arrow-svg.svg"
import mapsImg from "../../../../assets/images/maps.jpg"
import creativeImgViewOne from "../../../../assets/images/img-view-one.jpg"
import creativeImgViewTwo from "../../../../assets/images/img-view-two.jpg"
import creativeImgViewThree from "../../../../assets/images/img-view-three.jpg"
import AppContextObject from "../../../../common/context/common"
import { formatDateShowMonth, titleCase } from "../../../../service/helper"
import DataContext from "../../../../service/brandContext"
import CampaignBudget from '../components/campaignBudget'
import GenderSplit from '../components/genderSplit'
import CampaignReach from '../components/campaignReach'
import CampaignImpression from '../components/campaignImpression'
import { returnReadableStatus } from '../../../../service/helper'
import TextField from '../../../../components/formFields/textField/textField'
import SimpleReactValidator from "simple-react-validator"
import errorSVG from "../../../../assets/images/error.svg"
import TextArea from '../../../../components/formFields/textArea'
import AlertDialog from '../../../../components/addon/alertDialog/alertDialog'

const BrandMou = (props) => {
    const context = useContext(AppContextObject)
    const parentData = useContext<any>(DataContext)
    useEffect(() => {
      let element = document.getElementById("termsBox")
      console.log("element", element)
      element && element.scrollIntoView({block: "start"})
    }, [props.showMou])
    return (
        <>
      <div id="termsPopup" className={`onboarding-terms popup brand show `} style={{display: `${props.showMou ? "" : "none"}`}} >
        <div className="popup-header">{/*<span className="toggle left-back" data-target="termsPopup">close</span>*/} <span onClick={props.onToggle} className="close toggle" data-target="termsPopup">close</span> </div>
        <div className="popup-body">
          <div className="facili-avai-in clearfix">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="termsBox" id='termsBox'>
                  <h2>STANDARD TERMS & CONDITIONS</h2>
                  <div className="bullet"><p>1</p><p><b>DEFINITIONS</b></p></div>
                  <div className="bullet"><p>(a)</p><p><b>“Confidential Information”</b> includes but is not limited to information which is or fairly can be considered to be of a confidential nature, specially data with respect to customers/users of either Party and which may be obtained whether (without limitation) in graphic, written, electronic or machine readable form on any media, by the Receiving Party from the Disclosing Party and also includes all Intellectual Property but does not include information:</p></div>
                  <div className="bullet"><p>(i)</p><p>that which is or becomes generally available in the public domain other than by the Receiving Party’s breach, of this Agreement, or of any other confidentiality agreement or non-disclosure agreement;</p></div>
                  <div className="bullet"><p>(ii)</p><p>that which was previously known as established by records of the Receiving Party prior to receipt from the Disclosing Party and in possession of the Receiving Party prior to the date of this Agreement;</p></div>
                  <div className="bullet"><p>(iii)</p><p>that which was lawfully obtained by the Receiving Party from a third party;</p></div>
                  <div className="bullet"><p>(iv)</p><p>that which was developed independently by the Receiving Party without reference to the Confidential Information provided by the Disclosing Party;</p></div>
                  <div className="bullet"><p>(v)</p><p>that which is or becomes available to the Receiving Party by any third party that did not have corresponding confidentiality obligations and is prior to disclosure by the Disclosing Party; or</p></div>
                  <div className="bullet"><p>(vi)</p><p>that which is disclosed or released in the public domain by the Receiving Party with the prior written approval of the Disclosing Party.</p></div>
                  <div className="bullet"><p>(b)</p><p><b>“Disclosing Party”</b> means the Party disclosing the Confidential Information to the Receiving Party, including but not limited to, any subsidiaries, affiliates, employees, consultants and other personnel;</p></div>
                  <div className="bullet"><p>(c)</p><p><b>“Force Majeure Event”</b> means as any act of god, lightening, flood, drought, earthquake, landslide, hurricane, cyclone, typhoon, pandemic/epidemic, famine, extremely adverse weather conditions which are in excess of statistical measures of last 100 years, fire, explosion, chemical or radioactive contamination, ionising radiation, volcanic eruption, riots or civil disturbance, war (whether declared or undeclared), act of public enmity, terrorist act, military action, lockdown, curfew declared by government or regulatory order/notification, other action of government/other authorities, court order, or industry-wide/ region-wide/ nation-wide strike, work-to-rule action, go slow or similar labour action general failure of electricity or other supply, technical failure, accidental or mechanical or electrical breakdown, computer/network failure or failure of any money transmission or payment gateway or core banking system</p></div>
                  <div className="bullet"><p>(d)</p><p><b>“Intellectual Property”</b> means and includes any and all intellectual property created specifically by the Service Provider including its school platform “WISR”;</p></div>
                  <div className="bullet"><p>(e)</p><p><b>“Receiving Party”</b> means the Party receiving the Confidential Information from the Disclosing Party, including but not limited to, any subsidiaries, affiliates, employees, consultants and other personnel.</p></div>
                  <div className="bullet"><p>(f)</p><p><b>“School”</b> means school/ trust/ legal entity engaged in the business of education and runs a school or similar facility </p></div>
                  <div className="bullet"><p>(g)</p><p><b>“School platform assets”</b> means and includes the assets provided by School and covered under this Agreement; or as amended by the Service Provider or the school from time to time.</p></div>
                  <div className="bullet"><p>(h)</p><p><b>“Term”</b> shall mean a period of three years from the date of execution of this Agreement.</p></div>
                  <div className="bullet"><p>(2)</p><p><b>Engagement of Services:</b></p></div>
                  <div className="bullet"><p>(2.1)</p><p>The Client hereby agrees to use the WISR platform of Service Provider to access school assets available on the WISR Platform and to market Client’s Products using such school assets The Client hereby agrees to  use such school assets to its on terms and conditions as agreed by the Service Provider with the selected Schools from time to time.</p></div>
                  <div className="bullet"><p>(2.2)</p><p>Post onboarding process of Client is completed, brands have access to self-campaign creation tool where they can select inventory as well as schools of their choice and upload creatives for their campaign Further, the School can choose to accept or reject the proposed campaign within a period of 5  business days from the date of campaign creation. All the creatives for the campaign will first have to be approved by the school within the period of 5 working days Once School permission is granted; Service Provider will initiate the campaign as per the agreed terms & conditions with the Client.</p></div>
                  <div className="bullet"><p>(2.3)</p><p>Schools approve the campaign basis their internal policy. WISR is not accountable for any approval/rejection of a campaign by the school. However the decision of the school will be final and binding.</p></div>
                  <div className="bullet"><p>(2.4)</p><p>The creative language and engagement plan should be in line with the school engagement guidelines</p></div>
                  <div className="bullet"><p>(2.5)</p><p>Number of reach and estimation is calculated bases mathematical modelling, the Service Provider is not accountable for conversion of consumer on actual basis</p></div>
                  <div className="bullet"><p>(2.6)</p><p>Minimum revenue Commitment: Service Provider engages with schools on a revenue commitment; hence the Client will need to engage with minimum of 20 schools for 1 month or 10 schools for 2 months for any campaign. The Service Provider may decide to cancel or terminate the Agreement if the minimum revenue commitment is not met by the Client.</p></div>
                  <div className="bullet"><p>(2.7)</p><p>The media plan recommended by the Service Provider for any campaign is suggestive and not prescriptive.</p></div>
                  <div className="bullet"><p>(2.8)</p><p>If there is any damage to the School property during the campaign roll out phase; the execution partner will be accountable for the expenses that will incur in any repairs. In absence of execution partner failing to reimburse for any such repair costs; the same shall be borne by the Client. </p></div>
                  <div className="bullet"><p>(2.9)</p><p>All the creatives will need to be approved by the school before it is approved for execution. Decisions or recommendation by the School with respect to the creatives will be final and binding. Client agrees to accept such changes or recommendations to creatives as suggested by the School.</p></div>
                  <div className="bullet"><p>(3)</p><p><b>Remuneration, Billing and Payment Terms</b></p></div>
                  <div className="bullet"><p>(3.1)</p><p>Client shall pay to Service Provider amount per school and school asset as mentioned on the WISR platform based accordingly on the nos of school and school assets selected.</p></div>
                  <div className="bullet"><p>(3.2)</p><p>The Client will pay 50% advance immediately. Out of balance 50%,  25% will be paid during roll out of the campaign and balance 25% on completion immediately. The Service Provider will furnish valid tax invoices for each milestone.</p></div>
                  <div className="bullet"><p>(3.3)</p><p>The compensation as mentioned in Clause 3.2 is exclusive of all present and future taxes/ duties/levies including but not limited to GST, works contract tax, VAT/GST etc (“Taxes”) as applicable from time to time. All such applicable Taxes  on the compensation shall be borne and paid by  Client to Agency.</p></div>
                  <div className="bullet"><p>(3.4)</p><p>All payments shall be subject to deduction of TDS as per the applicable law and  the TDS certificate will be issued in favour of Wondrlab Martech Platforms Pvt Ltd within the statutory time and that should be reconciled with Form 26AS. Client agrees to indemnify Agency against all consequences whatsoever due to non-issue of the TDS certificate or non-deposit if TDS with the statutory authorities.</p></div>
                  <div className="bullet"><p>(3.5)</p><p>All payments shall be made within due dates.</p></div>
                  <div className="bullet"><p>(3.6)</p><p>All payments shall be made by “at par payable cheques”/ local cheques/ demand drafts/ payorders payable at Mumbai drawn in favour of Wondrlab Martech Platforms Pvt Ltd. or by electronic fund transfer to the Bank account of Service Provider.</p></div>
                  <div className="bullet"><p>(3.7)</p><p>Client shall honour all the cheques issued to the Service provider and shall bear the bank charges, interest etc in case of any dishonour of the cheques without prejudice to the other rights and remedies available to Service provider in law.</p></div>
                  <div className="bullet"><p>(3.8)</p><p>Service provider shall make the necessary payments to the School/vendors/sub-contractors only after receipt of payments from the Client. </p></div>
                  <div className="bullet"><p>(3.9)</p><p>In case of non-receipt of payment from the Client; the Client shall be solely liable to the School/vendors/sub-contractors engaged by the Service provider for rendering services pursuant to this Agreement.</p></div>
                  <div className="bullet"><p>(3.10)</p><p>Exclusions to the compensation in Clause 3.2:</p></div>
                  <div className="moulist">
                       <p><span></span>Any travel, board, lodging requirements for team will be chargeable basis actuals incurred</p> 
                       <p><span></span>Media innovations, banners creation, will be chargeable over and above</p>
                       <p><span></span>Experiential & Activation ideas are part of scope but execution cost will be at actuals.</p>
                       <p><span></span>All costs in this document do not include taxes/GST.</p>
                  </div>

                  <div className="bullet"><p>(4)</p><p><b>Cancellation Policy</b></p></div>
                  <div className="bullet"><p>(4.1)</p><p>If the Client decides to cancel a campaign 30 days prior to the roll out; then they will be given a credit for advances that can be used in their next campaign with WISR</p></div>
                  <div className="bullet"><p>(4.2)</p><p>If the Client cancels the campaign after the 30 days window; then  no refund will be provided and the amounts paid will be forfeited.</p></div>
                  <div className="bullet"><p>(4.3)</p><p>If the School initiates a cancellation 30 days prior to the campaign roll out then the Client can reschedule the campaign for a future day as per their convenience</p></div>
                  <div className="bullet"><p>(4.4)</p><p>If the school cancels the campaign after the 30 days window; then  the Service Provider will refund the amount or provide a Credit note to adjust against future projects.</p></div>
                  <div className="bullet"><p>(5)</p><p><b>General Terms & Conditions</b> </p></div>
                  <div className="bullet"><p>(5.1)</p><p>The Client acknowledges and agrees that the Service Provider shall retain all right, title, and interest in WISR platform and the right to marketing of School assets and to any material/proprietary information in the School assets which may be provided by the Service Provider to the Client.  Additionally, all the variations, modifications or derivatives of such material/proprietary information made by the Service Provider in the course of providing the School assets or performing the Services and shall belong exclusively to the Service Provider..</p></div>
                  <div className="bullet"><p>(5.2)</p><p>The Client hereby agrees that the execution of this Agreement shall not create any license, right, title or interest in respect of any Intellectual Property of the Service Provider in favour of the Client and it shall not make any claims to the same.</p></div>
                  <div className="bullet"><p>(5.3)</p><p>Creative materials prepared by the Service Provider, but not paid for by Client, shall remain Service provider’s exclusive property. </p></div>
                  <div className="bullet"><p>(5.4)</p><p>The Service Provider shall not be liable for any consequential, indirect, exemplary, incidental, special or punitive damages based on any claim arising out of this Agreement. The liability of Service Provider is limited to value under this Agreement. The Service Provider agrees to defend, indemnify and hold harmless the Client; with respect to any claim arising from the actual or alleged act, failure to act, error, or omission in the performance of their obligations under this Agreement </p></div>
                  <div className="bullet"><p>(5.5)</p><p>The Client further hereby agrees to indemnify the Service Provider for any losses or damages caused due to any action of the Client or breach of this Agreement or breach by Client for any agreed engagement with the School.</p></div>
                  <div className="bullet"><p>(5.6)</p><p>Confidentiality, indemnity, limitation of liability and payment obligations under this Agreement shall survive expiry of this Agreement.</p></div>
                  <div className="bullet"><p>(5.7)</p><p>The Service Provider and the Client represent and warrant that each of them has full authority, power and capacity to enter into and carry out its obligations under this Agreement. In addition to, and without limiting the provisions of this Agreement, both the Parties shall perform its obligations hereunder in accordance with the highest applicable industry standards and in compliance with all applicable laws, rules and regulations as applicable.</p></div>
                  <div className="bullet"><p>(5.8)</p><p>Any Confidential Information received or acquired by the Receiving party from Disclosing Party in pursuance of this Agreement shall be treated as confidential and shall not be used or disclosed to any third party by the Receiving Party without the prior written consent of the Disclosing Party.</p></div>
                  <div className="bullet"><p>(5.9)</p><p>Any notice (or other communication) shall be sent by certified or registered mail, email, telex, facsimile or by hand delivery.</p></div>
                  <div className="bullet"><p>(5.10)</p><p>This Agreement shall be interpreted and governed in all respects by the laws of India and the competent courts of Mumbai alone and no other courts shall be entitled to entertain and try any dispute or matter relating to or arising out of this Agreement.</p></div>
                  <div className="bullet"><p>(5.11)</p><p>The Service Provider shall have the right to assign all or any part of its rights and obligations hereunder to any of its Affiliates without the prior consent of or notice to the Client. All assignments, warranties, indemnities and confidentiality obligations provided herein shall survive expiration of this Agreement.</p></div>
                  <div className="bullet"><p>(5.12)</p><p>If the performance of any part of this Agreement by either Party is prevented, hindered, delayed or otherwise made impracticable by reason of any Force Majeure event or event beyond the reasonable control of any Party, such Party shall be excused from such performance to the extent that it is prevented, hindered or delayed by such cause. Notwithstanding anything herein to the contrary, the Party prevented from performing hereunder by a force majeure event shall nevertheless use its best efforts to recommence its performance hereunder as soon as reasonably practicable and to mitigate any damages resulting from its non-performance hereunder.</p></div>
                  <div className="bullet"><p>(5.13)</p><p>If any dispute arises between the parties hereto during the subsistence or thereafter, in connection with validity, interpretation, implementation or alleged material breach of any of the provisions of this Agreement or regarding any question, the parties hereto shall endeavor to settle such disputes amicably.  In case of failure of the parties to settle such disputes within 15 days, either party shall be entitled to refer the dispute to any arbitrator.  The arbitration shall be conducted by a sole Arbitrator mutually appointed, or in case of disagreement as to the appointment of a sole Arbitrator, by three (3) Arbitrators of which each Party shall appoint one (1) Arbitrator and the third Arbitrator shall be appointed by the two appointed Arbitrators. The arbitration proceedings shall be conducted in Mumbai and the same shall be governed by the provisions of the Indian Arbitration & Conciliation Act, 1996, or any statutory modification as may be then in force. The language of Arbitration shall be English.</p></div>





                  
                  <h3>SCHEDULE:</h3>
                  <div className="yellow-text"><span>(Detailed description of Schools, School assets and services to be provided)</span></div>
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col" colSpan={2}>Campaign Details</th>
                      </tr>
                    </thead>
                    <tbody>
                    <tr>
                            <td><span className="caps">Name</span></td>
                            <td><span className="caps">{parentData.data.brandDetail.name}</span></td>
                            </tr>
                    <tr>
                            <td><span className="caps">Description</span></td>
                            <td><span className="caps">{parentData.data.brandDetail.description}</span></td>
                    </tr>
                    {/* Campaign Specifications*/}
                    <tr>
                        <td className='head' scope="col" colSpan={2}>Campaign Specifications</td>
                      </tr>
                      <tr>
                            <td><span className="caps">Start Date</span></td>
                            <td><span className="caps">{parentData?.data?.brandDetail?.startDate !== "" ? formatDateShowMonth(parentData?.data?.brandDetail?.startDate, 2) : ""}</span></td>
                    </tr>
                    <tr>
                            <td><span className="caps">End Date</span></td>
                            <td><span className="caps">{parentData?.data?.brandDetail?.endDate !== "" ? formatDateShowMonth(parentData?.data?.brandDetail?.endDate, 2) : ""}</span></td>
                    </tr>
                    <tr>
                            <td><span className="caps">Location</span></td>
                            <td>
                              <span className="caps">{parentData.data.school.locations && parentData.data.school.locations.length > 0 && parentData.data.school.locations.map((item, index) => (
                                    <React.Fragment key={index}>
                                        {index === parentData.data.school.locations.length - 1 ? `${titleCase(item)}.` : `${titleCase(item)}, `}
                                    </React.Fragment>
                                  ))}
                                </span>
                              </td>
                    </tr>
                    <tr>
                            <td><span className="caps">Brand Category</span></td>
                            <td><span className="caps">{parentData.data.brandDetail.brandCategory.label && parentData.data.brandDetail.brandCategory.label}</span></td>
                    </tr>
                    <tr>
                            <td><span className="caps">No of Schools Occupied</span></td>
                            <td><span className="caps">{parentData.data.school.schools.length}</span></td>
                    </tr>
                    {/* Inventory Details */}
                    <tr>
                        <td className='head' scope="col" colSpan={2}>Inventory Details</td>
                      </tr>
                      <tr>
                            <td><span className="caps">Inventory Added</span></td>
                            <td>
                              {parentData?.data?.inventory.usedInventories.length > 0 && parentData?.data?.inventory.usedInventories.map((inventory, index) => (
                                  <span key={inventory._id} className='caps'>{index === parentData.data.inventory.usedInventories.length - 1 ? `${inventory.name}.` : `${inventory.name}, `}</span>
                              ))}
                            </td>
                    </tr>
                    {/* Inventory Details */}
                    {parentData.data.brandDetail.eventAndOccations &&
                      <>
                        <tr>
                            <td className='head' scope="col" colSpan={2}>Events Details</td>
                        </tr>
                        <tr>
                              <td><span className="caps">Events Selected</span></td>
                              <td>
                                {parentData?.data?.eventAndOccation.usedEvents.length > 0 && parentData?.data?.eventAndOccation.usedEvents.map((event, index) => (
                                    <span key={event._id} className='caps'>{index === parentData.data.eventAndOccation.usedEvents.length - 1 ? `${event.name}.` : `${event.name}, `}</span>
                                ))}
                              </td>
                        </tr>
                      </>
                    }
                    </tbody>
                    </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`blurBackground`} style={{display: `${props.showMou ? "" : "none"}`}}></div>
    </>
    )
}

export default BrandMou
