import {
    useEffect,
    useState,
    useContext,
    useRef
} from "react"

import { useHistory, useLocation } from "react-router"

import AppContextObject from "../../../../../common/context/common"
import DataContext from "../../../../../service/contextProvider"

import Loader from "../../../../../components/addon/loader"

import AvailableFacilities from "../../components/availableFacilities/availableFacilities"
import { formatInventories, objectDeepClone } from "../../../../../service/helper"

import toast from "react-hot-toast"
import ConfirmDialog from "../../../../../components/addon/confirmDialog/confirmDialog"

interface Props {
    setShowMenu?: Function,
    fromBackend?: boolean,
    schoolId?: any
}

const RemainingInventories = ({
    setShowMenu,
    fromBackend,
    schoolId
}: Props) => {

    const context = useContext(AppContextObject)
    const parentData = useContext <any> (DataContext)
    const refFacilities = useRef()
    const history = useHistory()
    const location = useLocation()    

    const [inventoryList, setInventoryList] = useState([])
    const [loader, setLoader] = useState(false)
    const [selectedInventories] = useState(objectDeepClone(parentData.selectedInventories))
    const [showQuitButton, setShowQuitButton] = useState(true)

    const [errorMessage, setErrorMessage] = useState("")
    const [showQuitModal, setShowQuitModal] = useState(false)
    const [overlay, setOverlay] = useState(false)

    useEffect(() => {
        if (setShowMenu) {
            setShowMenu(true)
        }
        fetchRemainingInventories()
    }, [])

    const fetchRemainingInventories = async () => {
        setLoader(true)
        // if selected remaining inventories are 0 then fetch from the api
        let resp
        if(fromBackend){
            resp = await context.apis[context.user.role].getSchoolInventoriesToBeAddedExternal(
                context.validator, {school: schoolId,statusDiff: "remaining"}, {page: 1, limit: 50, withCount: true}
            )
        }
        else{
            resp = await context.apis[context.user.role].getSchoolInventoriesToBeAdded(
                context.validator, {statusDiff: "remaining"}, {page: 1, limit: 50, withCount: true}
            )
        }
        console.log("getSchoolInventoriesToBeAdded", resp)
        if(resp && resp.done) {
            console.log(resp?.response?.rows)
            let inventoriesArr = resp.response.rows.map(inventory => {
                inventory.placements = inventory.placements.map( placement => {
                    placement.data.length = placement.data.length != '' ? placement.data.length : 0;
                    placement.data.breadth = placement.data.breadth != '' ? placement.data.breadth : 0;
                    placement.data.height = placement.data.height != '' ? placement.data.height : 0;
                    placement.data.quantity = placement.data.quantity != '' ? placement.data.quantity : 0;

                    return placement;
                });
                return inventory;
            })
            let list = objectDeepClone(inventoriesArr)
            console.log("formatInventories", list)
            const finalList  = formatInventories(list, "")
            finalList.forEach(((row, index) => {
                const found = inventoriesArr.findIndex(f => f.name.toLowerCase() === row.name.toLowerCase())
                if (found >= 0) {
                    finalList[index] = {
                        ...finalList[index],
                        ...objectDeepClone(inventoriesArr[found])
                    }
                }
            }))
            setInventoryList(objectDeepClone(finalList))
        } else {
            setInventoryList([])
        }
        setLoader(false)
    }

    const submitForm = async (proceed) => {
        let facilities = []
        if (refFacilities && refFacilities.current) {
            const ref: any = refFacilities.current
            facilities = ref.fetchFacilities()
            facilities.forEach((e) => {
                e.data.forEach((d, index) => {
                    d.name = e.name + " " + (index + 1)
                })
            })
            setErrorMessage("")
            if (proceed === false) {
                if (facilities.length == 0) {
                    setErrorMessage("Please select atleast one inventory")
                    return
                } else {
                    facilities.forEach(facility => {
                        facility.data.forEach(d => {
                            d["type"] = facility.type
                        })
                        facility["status"] = "Not Added"
                    })
                    let data = facilities.filter(e=> e._custom);

                    let response = await context.apis.public.upsertSchoolInventoriesDetailsBySchoolId(
                        context.validator, {
                        "school": schoolId ? schoolId : context.user.school,
                        "user": schoolId ? null : context.user._id,
                        "amenities": objectDeepClone(data),
                        "removeInventoriesArray": [],
                        "saveInventory": true
                        }, {}
                    )
                    if (response) {
                        if (response.done === true) {
                            toast.success(response.msg)
                            history.push("/schools/school-details/inventory")
                        }
                        else toast.error(response.msg)
                    } else {
                        toast.error("Something went wrong")
                    }
                }
            } else {
                if(fromBackend){
                    if (facilities.length == 0) {
                        setErrorMessage("Please select atleast one inventory")
                        return
                    }
                }
                const isSummary = new URLSearchParams(location.search).get("isSummary")
                if (parentData) {
                    if (parentData?.setInventoryList) {
                        parentData.setNewlyAddedInventories(inventoryList)
                    }
                    if (parentData.setSelectedInventories) {
                        parentData.setSelectedInventories(objectDeepClone(facilities))
                        if(facilities && facilities.length === 0){
                            if(fromBackend){
                                history.push(`/backend/backend-details/add-invetory/events-and-occasions${isSummary === "true" ? "?isSummary=true" : ""}`)
                            } else{
                                history.push(`/schools/school-details/add-invetory/events-and-occasions${isSummary === "true" ? "?isSummary=true" : ""}`)
                            }
                        }
                        else{
                            if(fromBackend){
                                history.push(`/backend/backend-details/add-invetory/invetories-details${isSummary === "true" ? "?isSummary=true" : ""}`)
                            } else{
                                history.push(`/schools/school-details/add-invetory/invetories-details${isSummary === "true" ? "?isSummary=true" : ""}`)
                            }
                        }
                    }
                }
            }
        }
    }
    const openQuitModal = () => {
        setOverlay(true)
        setShowQuitModal(true)
    }
    const onQuitModalClose = (confirm) => {
        if(confirm){
            if(fromBackend){
                history.push({pathname: "/backend/backend-details/school-details", state: { schoolId: schoolId }})
            }
            else{
                history.push("/schools/school-details/inventory")
            }
        }
        setShowQuitModal(false)
        setOverlay(false)
    }

    return(
        <>
            <div className={`ovelap ${overlay === true ? "active" : ""}`}></div>
            <div className="col-xl-6 col-lg-9 col-md-12 col-xs-12">
                <div className="rightPart">
                    {
                        loader === true ?
                        <Loader />
                        :
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-20"><a style={{cursor: "pointer"}} className="fR blackC" onClick={openQuitModal}>Quit</a></div>
                            <AvailableFacilities
                                ref={refFacilities}
                                availableFacilities={inventoryList}
                                isEdit={false}
                                isCustomEdit={true}
                                selectedInventories={selectedInventories}
                                showQuitButton={showQuitButton}
                                inDashboard={true}
                            />
                            <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-20 end-xs'>
                                <div className="bottom-error-btn">
                                    <p className="valError">
                                        {errorMessage}
                                    </p>
                                    <div className='forminpt'>
                                        {!fromBackend &&
                                            <input
                                                onClick={() => submitForm(false)}
                                                name='Save'
                                                value='Save'
                                                className='btn btn-outline sc-ameties'
                                                type='button'
                                            />
                                        }
                                        <input
                                            onClick={() => submitForm(true)}
                                            name='Save and Continue'
                                            value='Save and Continue'
                                            className='btn btn-orange sc-ameties'
                                            type='button'
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
            <ConfirmDialog
                confirmTitle="Do you want to quit"
                togglePopup={showQuitModal}
                onModalClose={onQuitModalClose}
                componentName={""}
            />
        </>
    )
}

export default RemainingInventories
