import React, { useEffect } from "react";
import search from "../../assets/images/search.svg";
import { DebounceInput } from 'react-debounce-input';
import { checkSpecialCharacter } from "../../service/helper";
import closeCustome from "../../assets/images/close-1.svg";
// searchInpuOpen //true/false
// clearSearchIcon//true/false
// setSearchInpuOpen//function=>true/false
// setSearchKeys//function=>string
// searchKeys//string
// setClearSearchIcon//function=>true/false
// const checkSpecialChar = (e) => {
//     // if(checkSpecialCharacter(e.key)){
//         e.preventDefault()
//     // }
// }

interface Props{
    searchInpuOpen?: any,
    clearSearchIcon?: any,
    setSearchInpuOpen?: any,
    handleChange?: any,
    searchKeys?: any,
    setClearSearchIcon?: any,
    palaceholder?: any,
    doNotShowClearIcon?: any
    width?: any,
    align?: any
}

const CustomSearchInput = ({ searchInpuOpen, clearSearchIcon, setSearchInpuOpen, handleChange, searchKeys, setClearSearchIcon, palaceholder, doNotShowClearIcon, width, align }: Props) => {
    useEffect(()=>{
        if(searchInpuOpen){
            setClearSearchIcon(true);
        }else{
            setClearSearchIcon(false);
        }
    },[searchInpuOpen])
    
    // const onchange = (e) => {
    //     // if(e.target.value === ""){
    //     //     setClearSearchIcon(false)
    //     // }
    //     // else{
    //     //     setClearSearchIcon(true)
    //     // }
    //     handleChange(e)
    // }
    return (
            <div className={searchInpuOpen ? width ? "searchinput" : "searchinput searchinput-backendAdmin" : "searchinput searchinput-fos"} style={{width: width ? "100%" : doNotShowClearIcon ? '100%' : 'auto', marginBottom: doNotShowClearIcon ? '15px' : 'auto', float: align === 'right' ? 'right' : "left"}}>
                <a href="javascript:void(0);" onClick={() => setSearchInpuOpen(true)}><img src={search} /></a>
                <form onSubmit={(e) => e.preventDefault()}>
                    {searchInpuOpen && <DebounceInput debounceTimeout={500} type="text" className="search-box" placeholder= {palaceholder ? palaceholder : "Search..."} value={searchKeys} onChange={handleChange} />}
                    {clearSearchIcon && !doNotShowClearIcon && <button className="close-icon serachClose" type="reset" onClick={() => { handleChange(""); setClearSearchIcon(false);setSearchInpuOpen(false) }}><img src={closeCustome} /></button>}
                </form>

            </div>
    )
}
export default CustomSearchInput;