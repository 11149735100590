import {
    useEffect,
    useState,
    useContext,
    useRef,
    createRef,
} from "react"

import { DebounceInput } from 'react-debounce-input'

import { useLocation } from "react-router-dom"

import { validation } from "../../../../../service/validators"

import scriptLoader from 'react-async-script-loader'

import SimpleReactValidator from "simple-react-validator"

import AlertDialog from "../../../../../components/addon/alertDialog/alertDialog"
import ConfirmAlert from "../../../../../components/addon/confirmAlert"
import Numberfield from "../../../../../components/formFields/numberField"
import NumWithPlusMinus from "../../../../../components/formFields/numWithPlusMinus/numWithPlusMinus"
import Textfield from "../../../../../components/formFields/textField/textField"
import SingleSelect from "../../../../../components/formFields/singleSelect/singleSelect"
import MultiSelect from "../../../../../components/formFields/multiSelect/multiSelect"
import SliderComponent from "../../../../../components/addon/rangeSlider"
import TextFieldWithValidation from "../../../../../components/formFields/textField/textFieldWithValidation"
import GMaps from "../../../../../components/addon/gMaps"

import config from "../../../../../service/config"

import AppContextObject from "../../../../../common/context/common"
import { PublicContextInitiator } from "../../../../apis/publicContextInitiator"

import DataContext from "../../../../../service/contextProvider"

import { checkIfNumberOnly, compareObjs } from "../../../../../service/helper"

import schoolImg from "../../../../../assets/images/school-img.jpg"
import errorSVG from "../../../../../assets/images/error.svg"
import SchoolGeneralDetail from "../../components/schoolGeneralDetail"

interface Props {
    isEdit?: boolean
    onProceed?: any
    isScriptLoaded?: any
    isScriptLoadSucceed?: any,
    toggleSidemenu ? : Function,
    showSidemenu ? : boolean,
    addSchoolFromBackend ? : any,
}

const SchoolGeneral = ({
    isEdit,
    onProceed,
    isScriptLoaded,
    isScriptLoadSucceed,
    toggleSidemenu,
    showSidemenu,
    addSchoolFromBackend
}: Props) => {

    const parentData = useContext<any>(DataContext)

    const context = useContext(AppContextObject)

    const location = useLocation()

    let mapRef = useRef()
    const generalDetailRef = useRef({formValidator : {current: {allValid: () => false, showMessages: () => {return}, hideMessages: () => {return}}}})
    // const [formValidator] = useState(useRef(new SimpleReactValidator({
    //     element: (message, className) =>
    //         <span className="valError"><img src={errorSVG} />{message}</span>
    //     })))
    const [update, forceUpdate] = useState(false) // to re-render the dom after updating the validation

    const maxBuiltupArea = 1000000
    const minbuiltupArea = 1

    const [errorMessage, setErrorMessage] = useState("")
    const [oldFormData] = useState(parentData.data.schoolGeneral)
    const [showPrompt, setShowPrompt] = useState(false)
    const [toggleAlertPopUp, setToggleAlertPopUp] = useState(false)
    const [formData, setFormData] = useState(parentData.data.schoolGeneral)
    const [currentClassTypeIndex, setCurrentClassTypeIndex] = useState(0)
    const [builtError, setBuiltError] = useState(false)
    const [floorError, setFloorError] = useState(false)
    const [overlay, setOverlay] = useState(false)
    const [stateName, setStateName] = useState('State')
    const [districtName, setDistrictName] = useState('City')
    const [isPincodeValid, setIsPincodeValid] = useState(true)
    const [localArea, setLocalArea] = useState(parentData?.data?.schoolGeneral?.localArea)

    const [boardOfStudyData, setBoardOfStudy] = useState({
        boardOfStudy: ""
    })

    const [otherBoardRef, setOtherBoardRef] = useState([])
    const [recheck, setRecheck] = useState(false)
    const [showBuildUpError, setShowBuildUpError] = useState(false)
    const otherBoardReference = useRef()
    
    const submitForm = async (event) => {
        let otherBoardValidation = []
        otherBoardRef.length !== 0 && otherBoardRef.forEach(e => {
            if (e?.current?.validateField) {
                otherBoardValidation.push(e.current.validateField())
            }
        })
        let otherResult
        let otherValidation:any = otherBoardReference
        if(otherValidation?.current?.validateField){
            otherResult = otherValidation?.current?.validateField()
        }
        event.preventDefault()
        const updateProgress = {
            page: "schoolGeneral",
            value: false
        }
        const result = generalDetailRef?.current?.formValidator.current?.allValid()

        if (otherResult === false || result === false || !(otherBoardValidation.length === 0 || otherBoardValidation.indexOf(false) === -1) || isPincodeValid == false || (formData.builtUpArea === "" || formData.builtUpArea < 100)) {
            console.log(result, otherBoardValidation, generalDetailRef?.current?.formValidator.current, otherResult)
            updateProgress.value = false
            generalDetailRef?.current?.formValidator.current?.showMessages()
            forceUpdate(!update)
            if(formData.builtUpArea === "" || formData.builtUpArea < 100){
                setBuiltError(true)
                setShowBuildUpError(true)
            }
            setErrorMessage("Please fill all the data")
            return
        } else {
            generalDetailRef?.current?.formValidator.current?.hideMessages()
            setShowBuildUpError(false)
            forceUpdate(!update)
            setErrorMessage("")
        }
        if(isPincodeValid){
        }else{
            setOverlay(true)
            setToggleAlertPopUp(true)            
            return
        }
        updateProgress.value = true
        // setShowPrompt(false)

        const json = {...formData, state: formData.state.trim(), city: formData.city.trim()}

        if (json.board.board === "Other") {
            json.board.board = boardOfStudyData.boardOfStudy.trim().replace(/  +/g, ' ')
        }

        if (json.board.board === "Custom") {
            for (let i = 0; i < json.board.classWiseDetails.length; i++) {
                if (json.board.classWiseDetails[i].board === "Other") {
                    json.board.classWiseDetails[i].board = json.board.classWiseDetails[i].otherBoard
                    delete json.board.classWiseDetails[i].otherBoard
                }
            }
        }

        if (parentData) {
            if (parentData.setProgress) {
                parentData.setProgress({
                    ...parentData.progress,
                    schoolGeneral: true
                })
            }
            if (parentData.setData) {
                parentData.setData({
                    ...parentData.data,
                    schoolGeneral: json,
                    algorithmData: {
                        ...parentData.data.algorithmData, 
                        teachers: Number(formData.noOfTeachers) || 0
                    }
                })
            }
            let resp = await context.apis.public.getOnboardingRevenue(
                context.validator, {
                    ...parentData.data.algorithmData, 
                    teachers: Number(formData.noOfTeachers) || 0
                }
            )
            console.log("getOnboardingRevenue resp----", resp)
            if(resp && resp.done){
                if(parentData && parentData.setNetRevenue){
                    parentData.setNetRevenue(resp.netRevenue || 0)
                }
            }

            //  for total schools revenue calculation
             console.log("listofinventories",parentData.listOfInventories);

            let allInvData = []

            if(parentData.listOfInventories){
                for(let inventory of parentData.listOfInventories){
                    if(inventory.type !== "events&Occasions"){
                        let data = {
                            "placement": [],
                            "cpi": [],
                            "materialCost": [],
                            "size": [],
                            "unit": [],
                            "quantity": [],
                            "changes": [],
                            "parentInventory": "",
                            "uniqueName": ""
                          }
                        for(let placement of inventory.placements){
                            data['placement'].push(placement.name)
                            console.log("placement---",placement);
                            if(placement.data.cpi){
                                for(let cpi of placement.data.cpi){
                                    if(cpi.category === parentData.data.otherSchoolAlgorithmData.category){
                                        data['cpi'].push(cpi.value)
                                    }
                                }
                            } else if(placement.data.costPerImpression){
                                for(let costPerImpression of placement.data.costPerImpression){
                                    if(costPerImpression.category === parentData.data.otherSchoolAlgorithmData.category){
                                        data['cpi'].push(costPerImpression.value)
                                    }
                                }
                            } else {
                                data['costPerImpression'].push(0)
                            }
                            data['materialCost'].push(placement.data.materialCost)
                            data['size'].push(placement.defaultSize)
                            data['unit'].push(placement.defaultUnits)
                            data['quantity'].push(placement.defaultQuantity)
                            data['changes'].push(placement.data.noOfChangesYearly)
                            data['parentInventory'] = inventory.name
                            data['uniqueName'] = inventory.name
                        }
                        console.log("data---xxxx",data);
                        allInvData.push(data)
                    }
                    
                }
            }

            let otherSchoolRevenueData = {
                ...parentData.data.otherSchoolAlgorithmData,
                teachers: Number(formData.noOfTeachers) || 0,
                inventories: allInvData
            }

            console.log("parentData.data.otherSchoolAlgorithmData----",parentData.data.otherSchoolAlgorithmData);

            console.log("otherSchoolRevenueData----",otherSchoolRevenueData);
            let totalFee: number = 0;
            let category: string;
            let totalStudents = 0;
            let classroomLength = 0;
    
            for(let classroom of parentData.data.schoolAmenities.classrooms){
                totalFee = totalFee + ( + Number(classroom.data.avgAnnualFee)); 
                totalStudents += ((Number(classroom.data.girls) || 0) + (Number(classroom.data.boys) || 0))
                classroomLength += classroom.data.noOfSections
            }
            let avgFee: number = totalFee / (parentData.data.schoolAmenities.classrooms.length);
            console.log("avgFee>>>",avgFee,totalFee,parentData.data.schoolAmenities.classrooms.length);
            if (avgFee > 100000) {
                category = "A"
            }
            else if (avgFee > 40000 && avgFee < 100000){
                category = "B"
            }
            else if (avgFee < 40000){ 
                category = "C"
            }
            // let otherSchoolResp = await context.apis.public.getOnboardingRevenue(
            //     context.validator, otherSchoolRevenueData
            // )

            // if(otherSchoolResp && otherSchoolResp.done){
            //     if(parentData && parentData.setOtherSchoolsRevenue){
            //         console.log("working",parentData.setOtherSchoolsRevenue);
                    // parentData.setOtherSchoolsRevenue(otherSchoolResp.netRevenue || 0)
                    // otherSchoolsRevenue
                    // let revenueAmount = category === "A" ? context.utils.categoryAmounts.A : category === "B" ? context.utils.categoryAmounts.B : category === "C" ? context.utils.categoryAmounts.C : context.utils.categoryAmounts.C
                    let revenueAmount = context.utils.categoryAmounts.C
                    if(resp.schoolCategory){
                        revenueAmount = resp.schoolCategory === "A" ? context.utils.categoryAmounts.A : resp.schoolCategory === "B" ? context.utils.categoryAmounts.B : resp.schoolCategory === "C" ? context.utils.categoryAmounts.C : context.utils.categoryAmounts.C
                    }
                    parentData.setOtherSchoolsRevenue((resp.maxPotentialRevenue && resp.maxPotentialRevenue.total) || 0)
            //     }
            // }
            
            // console.log("otherSchoolResp======",otherSchoolResp);


            console.log("algo resp->>>>", resp);
        }
        setBoardOfStudy({boardOfStudy: ""})
        setShowPrompt(false)
        setRecheck(true)
        // process.nextTick(() => {    
        // if (isEdit === true) {
        //     if (isSummary === "true") {
        //         onProceed("/schools/school-details/details/school-details/amenities")
        //     } else {
        //         onProceed("/schools/school-details/details/school-details/amenities")
        //     }
        // } else {
        //     setRecheck(true)
        //         if (isSummary === "true") {
        //             onProceed("/schools/onboarding/student-specific?isSummary=true")
        //         } else {
        //             onProceed("/schools/onboarding/student-specific")
        //         }
        //     }
        // })
    }
    useEffect(() => {
        if(recheck===true && showPrompt===false) {
            const isSummary = new URLSearchParams(location.search).get("isSummary")
            process.nextTick(() => {    
                if (isEdit === true) {
                    if (isSummary === "true") {
                        onProceed("/schools/school-details/details/school-details/amenities")
                    } else {
                        onProceed("/schools/school-details/details/school-details/amenities")
                    }
                } else {
                    setRecheck(true)
                        if (isSummary === "true") {
                            onProceed("/schools/onboarding/student-specific?isSummary=true")
                        } else {
                            onProceed("/schools/onboarding/student-specific")
                        }
                    }
                })
        }
      },[recheck, showPrompt])

    const confirmAlertCallback = (data) => {
        setOverlay(data.overlay)
    }

    return (
        <>
            <div className={`ovelap ${overlay === true ? "active" : ""}`}></div>

            <div className={`${isEdit === false ? "col-xl-9 col-lg-9 col-md-12 col-xs-12" : ""}`}>
                <div className={`${isEdit === false ? "content mCustomScrollbar fixhight newMvp-pages-css" : ""}`}>
                    <div className={`${isEdit === false ? "rightPart w80" : ""}`}>
                        {
                            isScriptLoaded && isScriptLoadSucceed &&
                            <form action="">
                                <SchoolGeneralDetail 
                                    ref={generalDetailRef}
                                    isEdit={isEdit}
                                    onProceed={onProceed}
                                    formData={formData}
                                    setFormData={setFormData}
                                    showPrompt={showPrompt}
                                    setShowPrompt={setShowPrompt}
                                    otherBoardRef={otherBoardRef}
                                    setOtherBoardRef={setOtherBoardRef}
                                    isPinCodeValid={isPincodeValid}
                                    setIsPincodeValid={setIsPincodeValid}
                                    toggleAlertPopUp={toggleAlertPopUp}
                                    setToggleAlertPopUp={setToggleAlertPopUp}
                                    boardOfStudyData={boardOfStudyData}
                                    setBoardOfStudy={setBoardOfStudy}
                                    recheck={recheck}
                                    localArea={localArea}
                                    setLocalArea={setLocalArea}
                                    builtError={builtError}
                                    setBuiltError={setBuiltError}
                                    showBuildUpError={showBuildUpError}
                                    otherBoardReference={otherBoardReference}
                                    toggleSidemenu={toggleSidemenu}
                                    showSidemenu={showSidemenu}
                                    isBackendUser={addSchoolFromBackend ? true : false}
                                />
                                <div className='forminpt end-md'>
                                    <input
                                        onClick={submitForm}
                                        name='Proceed'
                                        value='Proceed'
                                        className='btn btn-orange sc-ameties'
                                        type='button'
                                    />
                                </div>
                            </form>
                        }
                    </div>
                </div>
            </div>
             
            <ConfirmAlert
                when={showPrompt}
                title="Leave page without saving..."
                cancelText="Cancel"
                okText="Confirm"
                onOK={() => true}
                onCancel={() => false}
                parentCallback={confirmAlertCallback}
            />            
        </>
    )
}

// export default SchoolGeneral

export default scriptLoader(
    [
        'https://maps.googleapis.com/maps/api/js?key=' + config.GOOGLE_MAPS_API_KEY
    ],
)(SchoolGeneral)