import React, { useState, useRef, useContext, useEffect } from "react";
import SimpleReactValidator from "simple-react-validator";
import TextArea from "../../../../components/formFields/textArea";
import errorSVG from "../../../../assets/images/error.svg";
import { PublicContextInitiator } from '../../../apis/publicContextInitiator';
import AppContextObject from '../../../../common/context/common';

const BrandDocsRejectModal = ({ openRejectModal, closeModal, docType, setOverlay, brandDocumentDetails, brandId, updateBrandDetails, setUpdateBrandDetails }) => {
    const context = useContext(AppContextObject);
    const [formData, setFormData] = useState({ reason: "" });
    const [update, forceUpdate] = useState(false);
    const [rejectingDocument, setRejectingDocument] = useState(false)
    const [formValidator] = useState(useRef(new SimpleReactValidator({
        element: (message) =>
            <span className="valError">{console.log(message)}<img src={errorSVG} />{message}</span>
    })))

    useEffect(() => {
        if(update === true){
            console.log('111111111')
            formValidator.current.showMessages()
        }else{
            console.log('2222222222')
            formValidator.current.hideMessages()
        }
    }, [update])
    const approvedDocs = async (docType) => {
        // console.log('brandDocumentDetails===>',brandDocumentDetails)
        const result = formValidator.current.allValid()

        console.log(result)
        if (result === false) {
            formValidator.current.showMessages()
            forceUpdate(!update)
        } else {
            formValidator.current.hideMessages()
            let approvalDocs;
            console.log("brandDocumentDetails", brandDocumentDetails)
            
            if (docType === 'gst') {
                approvalDocs = {
                    gst: {
                        file: brandDocumentDetails.gst.file && brandDocumentDetails.gst.file.length !== 0 ? brandDocumentDetails.gst.file[0].key : "",
                        number: brandDocumentDetails.gst.number,
                        status: "rejected",
                        rejectionReason: formData.reason
                    },
                    pan: {
                        file: brandDocumentDetails.pan.file && brandDocumentDetails.pan.file.length !== 0 ? brandDocumentDetails.pan.file[0].key : "",
                        number: brandDocumentDetails.pan.number,
                        status: brandDocumentDetails.pan.status,
                        rejectionReason:brandDocumentDetails.pan?.rejectionReason
                    }
                }
            } else {
                approvalDocs = {
                    gst: {
                        file: brandDocumentDetails.gst.file && brandDocumentDetails.gst.file.length !== 0 ? brandDocumentDetails.gst.file[0].key : "",
                        number: brandDocumentDetails.gst.number,
                        status: brandDocumentDetails.gst.status,
                        rejectionReason:brandDocumentDetails.gst?.rejectionReason
                    },
                    pan: {
                        file: brandDocumentDetails.pan.file && brandDocumentDetails.pan.file.length !== 0 ? brandDocumentDetails.pan.file[0].key : "",
                        number: brandDocumentDetails.pan.number,
                        status: "rejected",
                        rejectionReason: formData.reason
                    }
                }
    
            }
            console.log(brandId)
            setRejectingDocument(true)
            let resp = await context.apis[context.user.role].updateBrandDocumentStatus(
                context.validator, { _id: brandId, statusAction: 'reject' }, { documents: approvalDocs }
            )
            console.log('resp======>', resp)
    
            if (resp && resp.done) {
                setRejectingDocument(false)
            }
            console.log(approvalDocs)
            closeModal();
            setOverlay(false);
            setFormData({ reason: "" })
            setUpdateBrandDetails(!updateBrandDetails);
            forceUpdate(!update)
        }

        
        
    }
// console.log('brandDocumentDetails,formData===>',brandDocumentDetails,formData)
    return (
        <div id="confirmRejectModal" className={`popup rejectPopup ${openRejectModal === false ? "hide" : ""}`}>
            <div className="popup-header">
                <h3 className="htx2 pophead">Document Rejection</h3>
                <span className="close toggle" onClick={() => { closeModal(); setOverlay(false);setFormData({ reason: "" });formValidator.current.hideMessages() }} data-target="myPopup">close</span>
            </div>
            <div className="popup-body">
                <div className="viewdetailsHead">
                    <div className="approveActive">
                        
                        <form onSubmit={(e) => e.preventDefault()}>
                            <div className="row">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                    <TextArea
                                        formData={formData}
                                        formKey='reason'
                                        onDataChange={(event) => setFormData({ reason: event.target.value })}
                                        label='Reason'
                                        placeholder='Enter Reason'
                                        isRequired={false}
                                        formValidator={formValidator}
                                        validation='required'
                                        maxLength={500}
                                    />
                                </div>
                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 fR">
                                    <a onClick={() => approvedDocs(docType)} href="javascript:void(0)" className={`btn btn-orange ${rejectingDocument && "button--loading btn-disabled"}`}>Done</a>
                                </div>
                            </div>
                        </form>
                        {/* <div className="brand-btn">
                            <a onClick={() => approvedDocs(docType)} href="javascript:void(0)" className="btn btn-orange">Done</a>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default BrandDocsRejectModal;