import { useState } from "react";

import Select from "react-select";

import errorSVG from "../../../assets/images/error.svg";
import helpImg from "../../../assets/images/help.svg";

const SingleSelect = (props) => {
	const {
		formData,
		formKey,
		formErrors,
		onDataChange,
		label,
		placeholder,
		options,
		wrapperClasses,
		formValidator,
		validation,
		showInfoIcon,
		isDisable,
		infoText,
		onKeyPress,
		isSearchable,
		isClearable,
		specialClass,
		noScroll,
		matchById,
		onChange,
	} = props;
	const [wrapperClassList, setWrapperClassList] = useState(
		wrapperClasses ? wrapperClasses : ""
	);
	const customStyles = {
		option: (provided, state) => ({
			...provided,
			fontSize: "14px",
			padding: "13px 12px",
			backgroundColor: state.isSelected
				? "#ECECEC"
				: state.isFocused
				? "#F8F8F8"
				: "#fff",
			color: "#000",
			wordWrap: "break-word",
			whiteSpace: "normal",
			textOverflow: "ellipsis",
		}),
	};
	return (
		<>
			<div
				className={`${label ? "forminpt" : ""} ${wrapperClassList} ${
					showInfoIcon ? "tooltip-relative" : ""
				}`}
			>
				{label && (
					<label>
						{label} &nbsp;
						{showInfoIcon && (
							<a
								style={{ cursor: "pointer" }}
								title={infoText}
								className="tooltip"
							>
								<img src={helpImg} />
							</a>
						)}
					</label>
				)}
				<Select
					id={formKey}
					classNamePrefix="wisr-react-select"
					className={`${
						formValidator?.current?.messagesShown === true &&
						formValidator?.current?.fields?.[formKey] === false
							? "onFocus"
							: ""
					} ${specialClass ? "bigSelect" : ""} ${noScroll ? "noScroll" : ""}`}
					styles={customStyles}
					options={options}
					onChange={onDataChange}
					placeholder={placeholder}
					value={
						options &&
						options.length > 0 &&
						options.filter((option) => {
							// value={options && Array.isArray(options)  && options.length > 0 && options.filter((option) => {
							return matchById
								? option._id === formData[formKey]
								: option.value === formData[formKey];
						})
					}
					// value={
					// 	options &&
					// 	options.find((option) => option.value === formData[formKey])
					// }
					// value={
					// 	options &&
					// 	(Array.isArray(formData[formKey])
					// 		? options.filter((option) =>
					// 				formData[formKey].includes(option.value)
					// 		  )
					// 		: options.find((option) => option.value === formData[formKey]))
					// }
					isDisabled={isDisable}
					onKeyPress={onKeyPress}
					isSearchable={isSearchable}
					isClearable={isClearable}
				/>
				{formValidator?.current?.message(
					formKey,
					formData[formKey],
					validation
				)}
			</div>
		</>
	);
};

export default SingleSelect;
