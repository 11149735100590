import React, { useEffect, useState } from 'react'
import CampaignCreative from './campaignCreative';
import { Carousel } from "react-bootstrap";

const CreativeCampaignCarousel = (props) => {

  console.log("CreativeCampaignCarousel Props ------", props)
  // console.log("CreativeCampaignCarousel Props ------", props)
  // console.log("CreativeCampaignCarousel Props ------", props)

  // const {
  //   onModalClose,
  //   togglePopup,
  //   displayImage
  // } = props

  const [index, setIndex] = useState(props.id);
  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex)
  }

  useEffect(() => {
    return () => {
      setIndex("")
    }
  }, [])

  useEffect(() => {
    setIndex(props.id)
  }, [props.id])
  return (
    <>

      <div className=''>
        <Carousel
          activeIndex={index}
          onSelect={handleSelect}
          interval={null}
        >
          {
            props.data.length > 0 && props.data.map((item, currentIndex) => {
              return (
                <Carousel.Item key={currentIndex} style={{ height: "400px", width: "100%", position: "relative" }}>
                  <h4 style={{ position: "absolute", bottom: 0, left: "1%", color: "white", fontSize: "12px", backgroundColor: "rgba(0, 0, 0, 0.6)", padding: "3px", zIndex: "999", width: "100%", textAlign: "center"
 }}> {item.inventoryName.replace(/_/g, " ")} </h4>
                  <img src={item.image} alt="" style={{

                    maxHeight: "100%",
                    maxWidth: "100%",
                    width: "auto",
                    height: "auto",
                    position: "absolute",
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    margin: "auto",

                  }} />
                </Carousel.Item>
              );
            })
          }
        </Carousel>
      </div>
    </>
  )
}

export default CreativeCampaignCarousel