import { useState } from "react";
import search from "../../../../assets/images/search.svg"
import nikeimg from "../../../../assets/images/nike-logo.svg"

interface Props {
    addSearchShow?: any,
}
const SearchTopBar = ({
    addSearchShow
}: Props) => {

    let campaignList = ["Nike - Product Launch", "Nike - Summer", "Nike - Diwali 2021"];
    let inventoryList = ["Auditorium - Nike Product Launch", "Libary - Nike Summer"];
    const [searchTerm, setSearchTerm] = useState("");

    return (
        <>
                <div className={`dropdownShow showNotificatonbox ${addSearchShow === true ? "active" : ""}`}>
                    <div className="searchboxtop">
                    <div className="searchinput">
                        <a href="javascript:void(0);"><img src={search}/></a> 
                        <input type="text" placeholder="Nike" onChange ={(events) => {
                            setSearchTerm(events.target.value);
                        }} /> 
                    </div> 
                    <div className="searchlist">
                        <h3>Campaigns</h3>
                        <ul>
                            {
                                campaignList.filter((val) => {
                                    if(searchTerm == "") {
                                        return val
                                    }
                                    else if (val.toLowerCase().includes(searchTerm.toLowerCase())) {
                                        return val
                                    }
                                }).map((val,key) => {
                                    return (
                                        <li><a href={"/schools/school-details/my-school"}><span><img src={nikeimg}></img></span> {val} </a></li>
                                    )
                                })
                            }
                        </ul>
                    </div> 
                    <div className="searchlist searchlistimg">
                        <h3>Inventory Occupied</h3>
                        <ul>
                            {
                                inventoryList.filter((val) => {
                                    if (searchTerm == "") {
                                        return val
                                    }
                                    else if (val.toLowerCase().includes(searchTerm.toLowerCase())) {
                                        return val
                                    }
                                }).map((val,key) => {
                                    return (
                                        <li><a href={"/schools/school-details/inventory"}> {val} </a></li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                    </div>
                </div>
        </>
    )
}

export default SearchTopBar