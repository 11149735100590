import React, { useContext, useState, useEffect } from "react";
import {
	Route,
	useHistory,
	useRouteMatch,
	Switch,
	useLocation,
} from "react-router-dom";
import { DataContextProvider } from "../../../../service/contextProvider";
import AppContextObject from "../../../../common/context/common";
import SetUpCampaign from "./setUpCampaign";
import DataContext from "../../../../service/brandContext";
import CampaignInventory from "./campaignInventory";
import CampaignEvents from "./campaignEvents";
import CampaignRecomendedSchool from "./campaignRecomendedSchool";
import CampaignSchool from "./campaignSchool";
import CampaignCreative from "./campaignCreative";
import Confirmation from "./confirmation";
import CreateCampaignProgress from "../components/createCampaignProgress";
import CampaignPreview from "./campaignPreview";
import Loader from "../../../../components/addon/loader";
import { isValidId } from "../../../../service/helper";
import moment from "moment";
// import CustomSchoolList from "./customSchoolList";
import CustomComments from "./customComments";

const CreateCampaign = (props) => {
	const context = useContext(AppContextObject);
	const history = useHistory();
	const location = useLocation();
	const router = useRouteMatch();
	const parentData = useContext<any>(DataContext);
	const [showProgressSideMenu, setShowProgressSideMenu] = useState(false);

	const [reason, setReason] = useState({
		reason: parentData?.data?.brandDetail?.reason,
	});

	const [loading, setLoading] = useState(false);
	const [isTrailCampaign, setIsTrailCampaign] = useState(false);
	const [isCreativeRework, setIsCreativeRework] = useState(false);
	useEffect(() => {
		if (parentData && parentData.setShowSidemenu) {
			parentData.setShowSidemenu(false);
		}
	}, []);
	useEffect(() => {
		window.addEventListener("beforeunload", handleBeforeUnload);
		return () => {
			window.removeEventListener("beforeunload", handleBeforeUnload);
		};
	}, []);
	useEffect(() => {
		if (history.location.state) {
			let data: any = history.location.state;
			if (data.campaignId) {
				console.log(data.campaignId);
				getCampaignData(data.campaignId);
			}
			if (data.campaignType) {
				setLoading(true);
				setIsTrailCampaign(true);
				parentData.setData({
					brandDetail: {
						name: "",
						startDate: "",
						endDate: "",
						description: "",
						objective: "",
						inventory: false,
						eventAndOccations: false,
						csr: false,
						sampling: false,
						custom: false,
						// budget: 1,
						targetGender: "both",
						targetAgeGroup: { min: 3, max: 4 },
						studyMedium: [],
						brandCategory: { value: "", label: "" },
						brandSubcategory: { value: "", label: "" },
						brandName: "",
						state: [],
						city: [],
						brandNameLabel: "",
						isTrial: true,
					},
					algoData: {
						classroomData: [],
						inventoryData: [],
						eventData: [],
						schoolData: [],
						noOfDaysInYear: 0,
						percentageDiscountInWISR: 0,
						percentageIncreaseInReach: 0,
						totalInternalCostPerSchool: 0,
						budgetRatio: { CatA: 0, CatB: 0, CatC: 0 },
					},
					campaignDetail: {
						wisrOption: true,
						totalImpression: 0,
						totalReach: 0,
						totalBudget: 0,
						boysRatio: 0,
						girlsRatio: 0,
					},
					campaignCreative: {
						creatives: { image1: {}, image2: {}, image3: {} },
						inventoryCreativeData: [],
						inventoryCreativeDataGenerated: false,
					},
					inventory: {
						inventories: [],
						notUsedInventories: [],
						usedInventories: [],
					},
					school: {
						schools: [],
						locations: [],
					},
					eventAndOccation: {
						eventAndOccations: [],
						usedEvents: [],
					},
					schoolListDetail: [],
				});
				setLoading(false);
			}
		} else {
			setLoading(true);
			parentData.setData({
				brandDetail: {
					name: "",
					startDate: "",
					endDate: "",
					description: "",
					objective: "",
					inventory: false,
					eventAndOccations: false,
					csr: false,
					sampling: false,
					custom: false,
					// budget: 1,
					targetGender: "both",
					targetAgeGroup: { min: 3, max: 4 },
					studyMedium: [],
					brandCategory: { value: "", label: "" },
					brandSubcategory: { value: "", label: "" },
					brandName: "",
					state: [],
					city: [],
					brandNameLabel: "",
					isTrial: false,
				},
				algoData: {
					classroomData: [],
					inventoryData: [],
					eventData: [],
					schoolData: [],
					noOfDaysInYear: 0,
					percentageDiscountInWISR: 0,
					percentageIncreaseInReach: 0,
					totalInternalCostPerSchool: 0,
					budgetRatio: { CatA: 0, CatB: 0, CatC: 0 },
				},
				campaignDetail: {
					wisrOption: true,
					totalImpression: 0,
					totalReach: 0,
					totalBudget: 0,
					boysRatio: 0,
					girlsRatio: 0,
				},
				campaignCreative: {
					creatives: { image1: {}, image2: {}, image3: {} },
					inventoryCreativeData: [],
					inventoryCreativeDataGenerated: false,
				},
				inventory: {
					inventories: [],
					notUsedInventories: [],
					usedInventories: [],
				},
				school: {
					schools: [],
					locations: [],
				},
				eventAndOccation: {
					eventAndOccations: [],
					usedEvents: [],
				},
				schoolListDetail: [],
			});
			setLoading(false);
		}
		// history.push("/brands/brand-details/create-campaign/set-up-campaign")
	}, []);
	const getCampaignData = async (_id) => {
		setLoading(true);
		const resp = await context.apis.public.getCampaignById(
			context.validator,
			{ _id },
			{}
		);
		console.log("objectivenew", resp);
		if (resp && resp.done) {
			let data: any = history.location.state;
			let prevData = resp.response;
			let _id = prevData._id && prevData._id;
			let name = prevData.name ? prevData.name : "";
			let brandId = prevData.brandId && prevData.brandId;
			let description = prevData.description ? prevData.description : "";
			let objective = prevData.objective ? prevData.objective : "";
			let startDate = prevData.startDate ? prevData.startDate : "";
			let endDate = prevData.endDate ? prevData.endDate : "";
			let locations = data.creativeRework ? [...prevData.locations] : [];
			let targetGender = prevData.targetGender
				? prevData.targetGender === "co-ed"
					? "both"
					: prevData.targetGender
				: "both";
			let targetAgeGroupMax = prevData.targetAgeGroup
				? prevData.targetAgeGroup.length > 1
					? prevData.targetAgeGroup[1]
					: 4
				: 4;
			let targetAgeGroupMin = prevData.targetAgeGroup
				? prevData.targetAgeGroup.length > 0
					? prevData.targetAgeGroup[0]
					: 3
				: 3;
			let studyMedium = prevData.studyMedium ? prevData.studyMedium : [];
			let state = prevData.states ? prevData.states : [];
			let city = prevData.cities ? prevData.cities : [];
			let brandCategory = prevData.brandCategory ? prevData.brandCategory : "";
			// let brandSubcategory = prevData.brandSubcategory ? prevData.brandSubcategory : ""
			let brandSubcategory = prevData.brandSubcategory
				? prevData.brandSubcategory
				: "";
			let brandSubcategoryLabel = prevData.brandSubcategoryName
				? prevData.brandSubcategoryName
				: "";
			let brandNameLabel = prevData.brandName ? prevData.brandName : "";
			let brandName = prevData.brandId;
			let budget = prevData?.budget;
			let totalReach = prevData.totalReach ? prevData.totalReach : 0;
			let inventories = [],
				schools = [],
				events = [];
			if (prevData.campaignData) {
				inventories =
					prevData.campaignData.inventories &&
					prevData.campaignData.inventories.map((inventory) => {
						let data = inventory ? { ...inventory } : {};
						delete data.attributes;
						data.placements =
							inventory.attributes && Array.isArray(inventory.attributes)
								? inventory.attributes.reduce((acc, current) => {
										let obj = { name: current };
										acc.push(obj);
										return acc;
								  }, [])
								: [];
						return data;
					});
				schools = data.creativeRework
					? prevData.campaignData.schools
						? [...prevData.campaignData.schools]
						: []
					: [];
				events =
					prevData.campaignData.activities &&
					prevData.campaignData.activities.map((event) => {
						let data = event ? { ...event } : {};
						delete data.attributes;
						data.placements =
							event.attributes && Array.isArray(event.attributes)
								? event.attributes.reduce((acc, current) => {
										let obj = {};
										if (isValidId(current)) {
											obj = { _id: current };
										} else {
											obj = { name: current };
										}
										// let obj = {_id: current}
										acc.push(obj);
										return acc;
								  }, [])
								: [];
						return data;
					});
			}
			let creatives = { image1: {}, image2: {}, image3: {} };
			if (
				prevData.campaignCreativesRevised &&
				prevData.campaignCreativesRevised.length > 0
			) {
				prevData.campaignCreativesRevised.forEach((item, index) => {
					if (index === 0) {
						creatives.image1 = item;
					} else if (index === 1) {
						creatives.image2 = item;
					} else if (index === 2) {
						creatives.image3 = item;
					}
				});
			} else if (
				prevData.campaignCreatives &&
				prevData.campaignCreatives.length > 0
			) {
				prevData.campaignCreatives.forEach((item, index) => {
					if (index === 0) {
						creatives.image1 = item;
					} else if (index === 1) {
						creatives.image2 = item;
					} else if (index === 2) {
						creatives.image3 = item;
					}
				});
			}
			let inventory = false,
				eventAndOccations = false,
				csr = false,
				sampling = false,
				custom = false;
			if (prevData.wisrOffering) {
				inventory = prevData.wisrOffering.inventory;
				eventAndOccations = prevData.wisrOffering.eventAndOccations;
				csr = prevData.wisrOffering.csr;
				sampling = prevData.wisrOffering.sampling;
				custom = prevData.wisrOffering.custom;
			}
			if (prevData.isTrial) {
				setIsTrailCampaign(true);
			}
			if (data.creativeRework) {
				setIsCreativeRework(true);
			} else {
				if (
					!data?.creativeRework &&
					moment(startDate, "YYYY-MM-DD").diff(moment(), "days") < 10
				) {
					console.log("startDateWorking");
					startDate = "";
				}
				if (
					!data?.creativeRework &&
					moment(endDate, "YYYY-MM-DD").diff(moment(), "days") < 10
				) {
					console.log("startDateWorking2");
					endDate = "";
				}
			}
			parentData.setData({
				...parentData.data,
				brandDetail: {
					...parentData.data.brandDetail,
					_id: _id,
					name: name,
					brandId: brandId,
					description: description,
					objective: objective,
					inventory: inventory,
					eventAndOccations: eventAndOccations || false,
					csr: csr || false,
					sampling: sampling || false,
					custom: custom || false,
					startDate: startDate,
					endDate: endDate,
					targetGender: targetGender,
					targetAgeGroup: { max: targetAgeGroupMax, min: targetAgeGroupMin },
					studyMedium: studyMedium,
					brandCategory: {
						value: brandCategory,
						_id: brandCategory,
						label: "",
					},
					brandSubcategory: {
						value: brandSubcategory,
						label: brandSubcategoryLabel,
					},
					brandName: brandName,
					state: [...state],
					city: [...city],
					brandNameLabel: brandNameLabel,
					isTrial: prevData.isTrial,
				},
				campaignCreative: {
					creatives: creatives,
					inventoryCreativeData: [],
					inventoryCreativeDataGenerated: false,
				},
				campaignDetail: {
					...parentData.data.campaignDetail,
					totalBudget: budget,
					totalReach: totalReach,
				},
				inventory: {
					...parentData.data.inventory,
					inventories: inventories,
				},
				school: {
					...parentData.data.school,
					schools: schools,
					locations: locations,
				},
				eventAndOccation: {
					...parentData.data.eventAndOccation,
					eventAndOccations: events,
				},
			});
			if (
				resp.response.campaignCreationProgress &&
				resp.response.campaignCreationProgress.progress
			) {
				if (startDate === "" || endDate === "") {
				} else {
					parentData.setProgress({
						...parentData.progress,
						...resp.response.campaignCreationProgress.progress,
					});
				}
			}
		}
		setLoading(false);
	};
	const handleBeforeUnload = (e) => {
		if (context && context.inactive) {
			window.onbeforeunload = null;
			return true;
		}
		const message =
			"Are you sure you want to reload the page? All provided data will be lost.";
		e.returnValue = message;
		return message;
	};
	useEffect(() => {
		if (history.location.state) {
			let data: any = history.location.state;
			if (data.creativeRework) {
				return;
			}
		}
		switch (location.pathname) {
			case "/brands/brand-details/create-campaign/inventory":
				if (parentData.progress.brandDetails === false) {
					history.push("/brands/brand-details/create-campaign/set-up-campaign");
				}
				break;
			case "/brands/brand-details/create-campaign/events":
				if (parentData?.data?.brandDetail?.inventory === true) {
					if (parentData.progress.inventory === false) {
						history.push(
							"/brands/brand-details/create-campaign/set-up-campaign"
						);
					}
				} else {
					if (parentData.progress.brandDetails === false) {
						history.push(
							"/brands/brand-details/create-campaign/set-up-campaign"
						);
					}
				}
				break;
			case "/brands/brand-details/create-campaign/schools":
				if (parentData?.data?.brandDetail?.eventAndOccations === true) {
					if (parentData.progress.event === false) {
						history.push(
							"/brands/brand-details/create-campaign/set-up-campaign"
						);
					}
				} else {
					if (parentData.progress.inventory === false) {
						history.push(
							"/brands/brand-details/create-campaign/set-up-campaign"
						);
					}
				}
				break;
			case "/brands/brand-details/create-campaign/recommended-school":
				if (parentData.progress.school === false) {
					history.push("/brands/brand-details/create-campaign/set-up-campaign");
				}
				break;
			case "/brands/brand-details/create-campaign/creative":
				if (parentData.progress.recommendedSchool === false) {
					history.push("/brands/brand-details/create-campaign/set-up-campaign");
				}

				break;
			case "/brands/brand-details/create-campaign/preview":
				if (parentData.progress.campaignCreative === false) {
					history.push("/brands/brand-details/create-campaign/set-up-campaign");
				}

				break;
			case "/brands/brand-details/create-campaign/confirm":
				if (parentData.progress.preview === false) {
					history.push("/brands/brand-details/campaigns");
				}
				break;
			case "/brands/brand-details/create-trial-campaign/inventory":
				if (parentData.progress.brandDetails === false) {
					history.push(
						"/brands/brand-details/create-trial-campaign/set-up-campaign"
					);
				}
				break;
			case "/brands/brand-details/create-trial-campaign/events":
				if (parentData?.data?.brandDetail?.inventory === true) {
					if (parentData.progress.inventory === false) {
						history.push(
							"/brands/brand-details/create-trial-campaign/set-up-campaign"
						);
					}
				} else {
					if (parentData.progress.brandDetails === false) {
						history.push(
							"/brands/brand-details/create-trial-campaign/set-up-campaign"
						);
					}
				}
				break;
			case "/brands/brand-details/create-trial-campaign/schools":
				if (parentData?.data?.brandDetail?.eventAndOccations === true) {
					if (parentData.progress.event === false) {
						history.push(
							"/brands/brand-details/create-trial-campaign/set-up-campaign"
						);
					}
				} else {
					if (parentData.progress.inventory === false) {
						history.push(
							"/brands/brand-details/create-trial-campaign/set-up-campaign"
						);
					}
				}
				break;
			case "/brands/brand-details/create-trial-campaign/recommended-school":
				if (parentData.progress.school === false) {
					history.push(
						"/brands/brand-details/create-trial-campaign/set-up-campaign"
					);
				}
				break;
			case "/brands/brand-details/create-trial-campaign/creative":
				if (parentData.progress.recommendedSchool === false) {
					history.push(
						"/brands/brand-details/create-trial-campaign/set-up-campaign"
					);
				}

				break;
			case "/brands/brand-details/create-trial-campaign/preview":
				if (parentData.progress.campaignCreative === false) {
					history.push(
						"/brands/brand-details/create-trial-campaign/set-up-campaign"
					);
				}

				break;
			case "/brands/brand-details/create-trial-campaign/confirm":
				if (parentData.progress.preview === false) {
					history.push("/brands/brand-details/campaigns");
				}
				break;
		}
	}, [location.pathname]);
	return (
		<>
			<div className="main-wapper">
				<div className="container">
					{loading ? (
						<Loader />
					) : (
						<div className="row">
							{showProgressSideMenu && (
								<CreateCampaignProgress
									isTrail={isTrailCampaign}
									isCreativeRework={isCreativeRework}
								/>
							)}
							<Switch>
								<Route path={`${router.url}/set-up-campaign`}>
									<SetUpCampaign
										setShowProgressSideMenu={setShowProgressSideMenu}
										isTrailCampaign={isTrailCampaign}
										toolTipLoaded={props.toolTipLoaded}
									/>
								</Route>
								<Route path={`${router.url}/inventory`}>
									<CampaignInventory
										setShowProgressSideMenu={setShowProgressSideMenu}
									/>
								</Route>
								<Route path={`${router.url}/events`}>
									<CampaignEvents
										setShowProgressSideMenu={setShowProgressSideMenu}
									/>
								</Route>
								<Route path={`${router.url}/recommended-school`}>
									<CampaignRecomendedSchool
										setShowProgressSideMenu={setShowProgressSideMenu}
									/>
								</Route>
								<Route path={`${router.url}/schools`}>
									<CampaignSchool
										setShowProgressSideMenu={setShowProgressSideMenu}
									/>
								</Route>
								<Route path={`${router.url}/creative`}>
									<CampaignCreative
										setShowProgressSideMenu={setShowProgressSideMenu}
									/>
								</Route>
								<Route path={`${router.url}/custom-comments`}>
									<CustomComments
										setShowProgressSideMenu={setShowProgressSideMenu}
									/>
								</Route>
								{/* <Route path={`${router.url}/custom-school-list`}>
									<CustomSchoolList
										setShowProgressSideMenu={setShowProgressSideMenu}
									/>
								</Route> */}
								<Route path={`${router.url}/preview`}>
									<CampaignPreview
										reason={reason}
										setShowProgressSideMenu={setShowProgressSideMenu}
									/>
								</Route>
								<Route path={`${router.url}/confirm`}>
									<Confirmation
										setShowProgressSideMenu={setShowProgressSideMenu}
									/>
								</Route>
							</Switch>
						</div>
					)}
				</div>
			</div>
		</>
	);
};

export default CreateCampaign;
