import {
    useEffect,
    useState,
    useContext,
    useRef
} from "react"

import { useLocation } from "react-router-dom"
import { useHistory } from "react-router-dom"

import SimpleReactValidator from "simple-react-validator"

import AppContextObject from "../../../common/context/common"
import { PublicContextInitiator } from "../../apis/publicContextInitiator"

import menu from "../../../assets/images/menu.svg"
import waveBootNew from "../../../assets/images/wave-1.png"
import helpImg from "../../../assets/images/help.svg"

import { validation } from "../../../service/validators"
import Textfield from "../../../components/formFields/textField/textField"
import TextArea from "../../../components/formFields/textArea"
import TextfieldWithPrefix from "../../../components/formFields/textFieldWithPrefix/textFieldWithPrefix"
import SingleSelect from "../../../components/formFields/singleSelect/singleSelect"
import OTPVerifyModal from "../../../components/addon/otpVerifyModal"
import ConfirmAlert from "../../../components/addon/confirmAlert"
import AlertDialog from "../../../components/addon/alertDialog/alertDialog"

// import DataContext from "../../../service/contextProvider"

import { compareObjs, hasOnlyLettersAndSpaces, hasOnlyLettersNumberAndSpaces, specialCharOnly, validateActName, validateFullName, validatePrincipalName, validateSchoolName} from "../../../service/helper"

import errorSVG from "../../../assets/images/error.svg"
import logoNew from "../../../assets/images/logo-new.svg"

import config from "../../../service/config"
import TextFieldWithValidation from "../../../components/formFields/textField/textFieldWithValidation"
import { formatDiagnosticsWithColorAndContext } from "typescript"
import TextFieldWithPrefix from "../../../components/formFields/textFieldWithPrefix/textFieldWithPrefix"
import { DebounceInput } from "react-debounce-input"
import Loader from "../../../components/addon/loader"
import SchoolOnboardProgress from "../schools/components/schoolOnboardProgress"
import toast from "react-hot-toast"
import BackendTopBar from "./components/backendTopBar"
import NumberField from "../../../components/formFields/numberField"

const AddNonWisrSchool = (props) => {
    const {setShowSidemenu, showSidemenu, toggleSidemenu} = props
    const context = useContext(AppContextObject)
    const location = useLocation()
    const history = useHistory()
    const otherBoardReference = useRef({validateField: () => {return}, showMessages: () => {return}, hideMessages: () => {return}})
    // const otherBoardReference = useRef()
    const [formValidator] = useState(useRef(new SimpleReactValidator({
        element: (message, className) =>
            <span className="valError">
                <img src={errorSVG} />
                {message==='The phone field is required.'
                ?
                'The contact number field is required.'
                :
                message==='The role field is required.'
                ?
                'The designation field is required.'
                :
                message
                }
            </span>
        })))
    const [overlay, setOverlay] = useState(false)
    const [showMakeWisr, setShowMakeWisr] = useState(false)
    const [formData, setFormData] = useState({
        fullName: "",
        board: "",
        language: "",
        pincode: "",
        state: "",
        city: "",
        address: "",
        schoolName: "",
        email: "",
        phone: "",
        role: "",
        comment: "",
        noOfBoys: 0,
        noOfGirls: 0,
        totalStudents: 0,
        hasSchoolOnboarded: false
    })
    const [boardOptions] = useState(config.boardOptions.filter(item => item.value.toLowerCase() !== "custom"))
    const [langOptions] = useState(config.languages.filter(item => item.value.toLowerCase() !== "custom"))
    const [otherBoardOfStudy, setOtherBoardOfStudy] = useState({boardOfStudy: ""})
    const [isPinCodeValid, setIsPinCodeValid] = useState(true)
    const [loading, setLoading] = useState(true)
    const [isEdit, setIsEdit] = useState(false)
    const [submittingData, setSubmittingData] = useState(false)
    const [schoolId, setSchoolId] = useState("")
    const [showFaq, setShowFaq] = useState(false)

    const useForceUpdate = () => {
        const [value, setValue] = useState(0);
        return () => setValue(value => value + 1);
    }
    const forceUpdate1 = useForceUpdate();
    useEffect(() => {
        setShowSidemenu(false)
        if(history.location.state){
            let data: any = history.location.state
            if(data.isEdit && data._id){
                setIsEdit(true)
                fetchSchoolDetails(data._id)
                setSchoolId(data._id)
                setShowMakeWisr(true)
            }
            else{
                setLoading(false)
            }
        }
        else{
            setLoading(false)
        }
    }, [])
    useEffect(() => {
        if(!loading){
            fetchCityState()
        }
    }, [formData.pincode])

    const fetchSchoolDetails = async(_id) => {
        setLoading(true)
        let resp = await context.apis[context.user.role].getNonWisrSchool(
            context.validator, {_id}
        )
        console.log("getNonWisrSchool", resp)
        if(resp && resp.done){
            let regex = new RegExp(/principal/, "i");
            setFormData({
                fullName: resp.response.contactName,
                board: resp.response.board === "" ? "" : boardOptions.find(item => item.value.toLowerCase() === resp.response.board.toLowerCase()) ? resp.response.board.toLowerCase() : "Other",
                language: resp.response.language,
                pincode: resp.response.pincode && resp.response.pincode.toString() || "",
                state: resp.response.state,
                city: resp.response.city,
                address: resp.response.address,
                schoolName: resp.response.schoolName,
                email: resp.response.contactEmail,
                phone: resp.response.contactNumber,
                role: regex.test(resp.response.designation) ? "schoolPrincipal" : "schoolAdmin",
                comment: resp.response.commentsOrInventoryDetails,
                noOfBoys: resp.response.totalBoys || 0,
                noOfGirls: resp.response.totalGirls || 0,
                totalStudents: resp.response.totalReach || 0,
                hasSchoolOnboarded: resp.response.hasSchoolOnboarded || false
            })
        }
        if(!boardOptions.find(item => item.value.toLowerCase() === resp.response.board.toLowerCase()) && resp.response.board !== ""){
            setOtherBoardOfStudy({boardOfStudy: resp.response.board})
        }
        setLoading(false)
    }

    const onStateCityChange = (dataState, dataCity) => {
        dataCity = dataCity.toLowerCase()
        setFormData({ ...formData, "state": dataState, "city": dataCity })
    }

    const fetchCityState = async () => {
        if (formData.pincode.length == 6) {
            try {
                let publicContextInitiator = new PublicContextInitiator(context)
                let resp = await publicContextInitiator.getPincodeDetails(
                    context.validator, { "pincode": parseInt(formData.pincode) }, {}
                )
                console.log('resp======>', resp)
                if (resp && resp.done) {
                    onStateCityChange(resp.response.stateName, resp.response.district)
                    setIsPinCodeValid(true)
                } else {
                    const error = validation({ pincode: "invalid" })
                    if (error) {
                        setIsPinCodeValid(false)
                    }
                }
            }
            catch (error) {
                console.log("error", error)
            }
        } else {
            setIsPinCodeValid(true)
            onStateCityChange("", "")
        }
    }

    const onFormDataChange = async (event) => {
        if(event.target.id === "state"){
            if(hasOnlyLettersAndSpaces(event.target.value)){
                setFormData({ ...formData, [event.target.id]: event.target.value })
            }
        }
        else if(event.target.id === "city"){
            if(hasOnlyLettersNumberAndSpaces(event.target.value)){
                setFormData({ ...formData, [event.target.id]: event.target.value })
            }
        }
        else{
            setFormData({ ...formData, [event.target.id]: event.target.value })
        }
        if (event.target.id === "pincode") {
            // if (event.target.value && event.target.value.length === 6) {
            //     if (mapRef && mapRef.current) {
            //         const ref: any = mapRef.current
            //         ref.setMarkerFromPincode(event.target.value)
            //     }
            // }
        }
    }

    const onLangChange = (data) => {
        setFormData({
            ...formData,
            language: data.value
        })
    }

    const onBoardChange = (data) => {
        setFormData({
            ...formData,
            board: data.value
        })
    }

    const onRoleChange = (data) => {
        setFormData({
            ...formData,
            role: data.value
        })
    }
    const onOtherBoardChange = (e) => {
        setOtherBoardOfStudy({boardOfStudy: e.target.value})
    }

    const submitForm = async(event) => {
        event.preventDefault()
        setSubmittingData(true)
        let otherBoardValid: any = true
        const result = formValidator.current.allValid()
        if(formData.board === "Other"){
            otherBoardValid = otherBoardReference?.current?.validateField()
        }
        if(result === false || otherBoardValid === false){
            console.log("failed ", result, otherBoardValid, formData)
            formValidator.current.showMessages()
            setSubmittingData(false)
            forceUpdate1();
            return
        }
        else{
            formValidator.current.hideMessages()
            forceUpdate1()
        }
        let apiData = {
            schoolName: formData.schoolName,
            board: formData.board === "Other" ? otherBoardOfStudy.boardOfStudy : formData.board,
            language: formData.language,
            pincode: formData.pincode,
            state: formData.state,
            city: formData.city,
            address: formData.address,
            contactName: formData.fullName,
            contactEmail: formData.email,
            contactNumber: formData.phone,
            designation: formData.role,
            totalBoys: formData.noOfBoys,
            totalGirls: formData.noOfGirls,
            totalReach: formData.totalStudents,
            commentsOrInventoryDetails: formData.comment,
            hasSchoolOnboarded: formData.hasSchoolOnboarded
        }
        let resp
        if(isEdit){
            resp = await context.apis[context.user.role].updateNonWisrSchool(
                context.validator, {_id: schoolId}, apiData
            )
        }
        else{
            resp = await context.apis[context.user.role].addNonWisrSchool(
                context.validator, apiData
            )
        }
        console.log("addNonWisrSchool", resp)
        if(resp && resp.done){
            toast.success(isEdit ? "School updated successfully. " : "School added successfully. ")
            if(isEdit){
                history.push({pathname: "/backend/backend-details/nonWisr-school-details", state: {schoolId: schoolId}})
            }
            else{
                history.push('/backend/backend-details/nonWisr-school-list')
            }
        }
        setSubmittingData(false)
    }
    const toggleFaq = () => {
        setShowFaq(!showFaq)
    }
    const handleSchoolOnboardingStatus = (e) => {
        setFormData({
            ...formData,
            hasSchoolOnboarded: e.target.checked
        })
    }

    // formValidator.current.rules['full_name'] = {
    //     message: 'Please enter valid full name.',
    //     rule: (val) => {
    //         let returnValue = validateFullName(val)

    //         return returnValue
    //     },
    // }
    formValidator.current.rules['school_name'] = {
        message: 'Please enter valid school name.',
        rule: (val) => {
            let returnValue = validateSchoolName(val)

            return returnValue
        },
    }
    formValidator.current.rules['city_name'] = {
        message: 'Please enter valid city name.',
        rule: (val) => {
            let returnValue = context.utils.cityNameRegex.test(val)
            return returnValue
        }
    }
    return (
        <>
            <div className={`ovelap ${(overlay === true) ? "active" : ""}`}></div>
            <div className="main-wapper">
                {loading === true ?
                    <Loader />
                    :
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-3 col-lg-3 col-md-12 col-xs-12 pl-0">
                                <div className={`sliderbar onboarding-bar newMvp-sidebar ${showSidemenu === true ? "no_highlight" : ""}`}>
                                    <div className="row logo between-xs bottom-xs"><img src={logoNew} alt="logo" />
                                        <a href="javascript:void(0);" onClick={toggleFaq}>Help</a>
                                    </div>
                                    <SchoolOnboardProgress doNotShow={true} />
                                    <img src={waveBootNew} className="wavebot" alt="wave" />
                                </div>
                            </div>
                            <div className='col-xl-9 col-lg-9 col-md-12 col-xs-12'>
                                <div className="content mCustomScrollbar fixhight">
                                    <div className="rightPart w80 noBack">
                                        <div className='row'>
                                            <BackendTopBar redirectPage={true} from="non-wisr-school" />
                                            <div className={`col-xl-12 col-lg-12 col-md-12 col-xs-12`}>
                                                <a onClick={() => toggleSidemenu()} href='javascript:void(0)' className={`hummob ${showSidemenu === true ? "no_highlight" : ""}`}>
                                                    <img src={menu} />
                                                </a>
                                                <h2 className='htx1 mb40'>Tell us about yourself!</h2>
                                            </div>
                                            <div className={`col-xl-12 col-lg-12 col-md-12 col-xs-12`}>
                                                <Textfield
                                                    maxLength={50}
                                                    formData={formData}
                                                    formKey='fullName'
                                                    onDataChange={onFormDataChange}
                                                    label='Your Name'
                                                    placeholder='Enter Full Name'
                                                    isRequired={false}
                                                    formValidator={formValidator}
                                                    validation='required'
                                                />
                                            </div>
                                            <div className={`col-xl-6 col-lg-6 col-md-6 col-xs-12`}>
                                                <Textfield
                                                    formData={formData}
                                                    formKey='email'
                                                    onDataChange={onFormDataChange}
                                                    label='Contact Email'
                                                    placeholder='E.g. anju@abcschool.com'
                                                    isRequired={false}
                                                    formValidator={formValidator}
                                                    validation='email'
                                                />
                                            </div>
                                            <div className={`col-xl-6 col-lg-6 col-md-6 col-xs-12`}>
                                                <TextfieldWithPrefix
                                                    formData={formData}
                                                    formKey='phone'
                                                    onDataChange={onFormDataChange}
                                                    label='Contact Number'
                                                    placeholder='E.g. 99999 99999'
                                                    isRequired={false}
                                                    formValidator={formValidator}
                                                    validation='phone'
                                                />
                                            </div>
                                            <div className={`col-xl-12 col-lg-12 col-md-12 col-xs-12`}>
                                                <Textfield
                                                    maxLength={50}
                                                    formData={formData}
                                                    formKey='schoolName'
                                                    onDataChange={onFormDataChange}
                                                    label="Your School’s Name"
                                                    placeholder='E.g. Delhi Public School'
                                                    isRequired={false}
                                                    formValidator={formValidator}
                                                    validation='required|school_name'
                                                />
                                            </div>
                                            <div className={`col-xl-12 col-lg-12 col-md-12 col-xs-12`}>
                                                <SingleSelect
                                                    formData={formData}
                                                    formKey='role'
                                                    onDataChange={onRoleChange}
                                                    label='Your Designation'
                                                    placeholder='Select Your Designation'
                                                    isRequired={false}
                                                    options={config.schoolRoles}
                                                    formValidator={formValidator}
                                                    validation='required'
                                                />
                                            </div>
                                            <div className={`col-xl-6 col-lg-6 col-md-6 col-xs-12`}>
                                                <SingleSelect
                                                    formData={formData}
                                                    formKey="board"
                                                    onDataChange={onBoardChange}
                                                    label="Board Of Study"
                                                    placeholder="Select Your Board"
                                                    isRequired={false}
                                                    options={boardOptions}
                                                />
                                                {
                                                    formData.board === "Other" &&
                                                    <TextFieldWithValidation
                                                        ref={otherBoardReference}
                                                        formData={otherBoardOfStudy}
                                                        formKey='boardOfStudy'
                                                        onDataChange={onOtherBoardChange}
                                                        placeholder='Enter Board Name'
                                                        isRequired={false}
                                                        // formValidator={formValidator}
                                                        validation='required'
                                                        maxLength={50}
                                                        label="board"
                                                    />
                                                }
                                            </div>
                                            <div className={`col-xl-6 col-lg-6 col-md-6 col-xs-12`}>
                                                <SingleSelect
                                                    formData={formData}
                                                    formKey="language"
                                                    onDataChange={onLangChange}
                                                    label="Medium of Study"
                                                    placeholder="Select Medium of study"
                                                    isRequired={false}
                                                    options={langOptions}
                                                />
                                            </div>
                                            <div className="col-xl-6 col-lg-6 col-md-7 col-xs-12">
                                                <div className="forminpt">
                                                    <label>Number Of Boys</label>
                                                    <NumberField
                                                        formData={formData}
                                                        formKey="noOfBoys"
                                                        onDataChange={onFormDataChange}
                                                        placeholder='Enter the Number of Boys'
                                                        isRequired={false}
                                                        formValidator={formValidator}
                                                        validation="required"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6 col-md-7 col-xs-12">
                                                <div className="forminpt">
                                                    <label>Number Of Girls</label>
                                                    <NumberField
                                                        formData={formData}
                                                        formKey="noOfGirls"
                                                        onDataChange={onFormDataChange}
                                                        placeholder='Enter the Number of Girls'
                                                        isRequired={false}
                                                        formValidator={formValidator}
                                                        validation="required"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6 col-md-7 col-xs-12">
                                                <div className="forminpt">
                                                    <label>Total Students</label>
                                                    <NumberField
                                                        formData={formData}
                                                        formKey="totalStudents"
                                                        onDataChange={onFormDataChange}
                                                        placeholder='Enter total no of students'
                                                        isRequired={false}
                                                        formValidator={formValidator}
                                                        validation="required"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6 col-md-6 col-xs-12">
                                                <Textfield
                                                    formData={formData}
                                                    formKey="pincode"
                                                    maxLength={6}
                                                    onDataChange={onFormDataChange}
                                                    label="Pin Code"
                                                    placeholder="Enter the pin code"
                                                    isRequired={false}
                                                    formValidator={formValidator}
                                                    validation="required|min:6"
                                                    isPincodeValid={isPinCodeValid}
                                                />
                                            </div>
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                                <div className="forminpt">
                                                    <label>Address</label>
                                                    <DebounceInput
                                                        debounceTimeout={1000}
                                                        id="address"
                                                        placeholder="Enter your School Address"
                                                        value={formData.address}
                                                        onChange={onFormDataChange}
                                                        className={(formValidator?.current?.messagesShown === true && formValidator?.current?.fields?.["address"] === false) ? "onFocus" : ""}
                                                    />
                                                    {formValidator?.current?.message("address", formData.address, "required")}
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6 col-md-6 col-xs-12">
                                                <Textfield
                                                    formData={formData}
                                                    formKey="state"
                                                    onDataChange={onFormDataChange}
                                                    label="State"
                                                    placeholder="Select your State"
                                                    isRequired={false}
                                                    formValidator={formValidator}
                                                    validation="required|alpha_space"
                                                    maxLength={50}
                                                />
                                            </div>
                                            <div className="col-xl-6 col-lg-6 col-md-6 col-xs-12">
                                                <Textfield
                                                    formData={formData}
                                                    formKey="city"
                                                    onDataChange={onFormDataChange}
                                                    label="City"
                                                    placeholder="Select your City"
                                                    isRequired={false}
                                                    formValidator={formValidator}
                                                    validation="required|city_name"
                                                    maxLength={50}
                                                />
                                            </div>
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                                <TextArea
                                                    formData={formData}
                                                    formKey="comment"
                                                    onDataChange={onFormDataChange}
                                                    label="Comment"
                                                    placeholder="Add some details"
                                                />
                                            </div>
                                            {showMakeWisr &&
                                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                    <div className="ava-brad ava-school"> <strong>Is school onboarded? </strong>
                                                        <div className="content">
                                                            <span style={{color: formData.hasSchoolOnboarded ? "#8A8A8A" : "#171717", margin: "10px"}}>No</span>
                                                            <label className="switch">
                                                                <input
                                                                    type="checkbox"
                                                                    className="shohide"
                                                                    data-cls="onclass1"
                                                                    // id={eventIndex + "-allowForBrandsToProvide"}
                                                                    onChange={handleSchoolOnboardingStatus}
                                                                    checked={formData.hasSchoolOnboarded}
                                                                />
                                                                <span className="slider round"></span> 
                                                            </label>
                                                            <span style={{color: formData.hasSchoolOnboarded ? "#171717" : "#8A8A8A", margin: "10px"}}>Yes</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            <div className='row col-xl-12 col-lg-12 col-md-12 col-xs-12 end-md'>
                                                <div className='forminpt'>
                                                    <input
                                                        onClick={submitForm}
                                                        name={isEdit ? 'Save Changes' : 'Submit'}
                                                        value={isEdit ? 'Save Changes' : 'Submit'}
                                                        className={`btn btn-orange datasubmit`}
                                                        type='button'
                                                        disabled={submittingData}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <ConfirmAlert
                                    when={showPrompt}
                                    title="Leave page without saving..."
                                    cancelText="Cancel"
                                    okText="Confirm"
                                    onOK={() => true}
                                    onCancel={() => false}
                                    parentCallback={confirmAlertCallback}
                                /> */}
                            </div>
                        </div>
                    </div>
                }
            </div>
        </>
    )
}

export default AddNonWisrSchool