import classes from "*.module.css"
import { useState } from "react"
import check from "../../assets/images/check.svg"
const TextFieldWithIcon = (props) => {

    const {
        icon,
        formData,
        formKey,
        placeholder,
        isRequired,
        type,
        label,
        onFormDataChange,
        formErrors,
        validation,
        forgotPasswordEmailError,
        onVerifyClick,
        isVerified,
        maxLength,
        inProgress,
        onlyProgressNoVerify,
        onKeyPress,
        autoFocus,
        isEditable
    } = props


    return (
        <div className="forminpt">
            <label>{label}
            {
                isRequired === true &&
                <span className="mandate">*</span>
            }
            </label>
            <em><img src={icon} className="inputicon" alt="Wisr" /></em>
            <input
                value={formData[formKey]}
                name={formKey}
                placeholder={placeholder}
                type={type}
                id={formKey}
                onChange={onFormDataChange}
                className={`${(formErrors && formErrors[formKey]) || (formErrors?.current?.messagesShown === true && formErrors?.current?.fields?.[formKey] === false) ? "onFocus" : ""}`}
                data-isRequired={isRequired}
                data-validation={validation}
                maxLength={maxLength}
                onKeyPress={onKeyPress}
                autoFocus={autoFocus}
                disabled={isEditable === false ? true : false}
            />
            {
                formErrors && formErrors[formKey] &&
                <span id="for_fullname" className="valError">{formErrors[formKey]}</span> 
            }
            {
                forgotPasswordEmailError &&
                <span id="for_fullname" className="valError">Email not found</span> 
            }
            {
                onVerifyClick &&
                <a onClick={onVerifyClick} href="javascript:void(0)" className={inProgress ? "verifyLoader active" : "verifyLoader"}>{ inProgress ? <span className="button--loading"></span> : "" }<b className={`vertext vrifiyotp ${isVerified === true ? "complete" : ""}`}>verify <img src={check} /></b></a>
            }
            {
                onlyProgressNoVerify &&
                <a className={inProgress ? "verifyLoader active" : "verifyLoader"}><span className={inProgress ? "button--loading" : ""}></span> </a>
            }
            {formErrors?.current?.message(formKey, formData[formKey], validation)}
        </div>
    )
}

export default TextFieldWithIcon
