import * as React from 'react';
import { SocketManager } from '../utils/socketmanager';
import { Utilities } from '../utils/utilities';
import { Validator } from '../utils/validator';

export interface AppContext {
    microservices: string;
    socketManager?: SocketManager;
    history: any;
    apis?: any;
    user?: any;
    utils?: Utilities;
    validator?: Validator;
    showSidemenu?: any,
    inactive?: boolean,
}
export let appContext: AppContext = {
    microservices: "",
    socketManager: null,
    history: null,
    apis: null,
    utils: new Utilities,
    validator: null,
    showSidemenu: false,
    inactive: false
}
let AppContextObject = React.createContext(appContext);

export let AppContextProvider = AppContextObject.Provider
export default AppContextObject
