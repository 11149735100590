import moment, { months } from "moment"

export function getNameAbbr(name: string) {
    const namearr = name.split(' ');
    const letterArr = namearr.map((word) => word.charAt(0).toUpperCase());
    return letterArr.join('').substring(0, 2);
}

export function slugify(text: string) {
    return text
        .toString()
        .toLowerCase()
        .replace(/\s+/g, '-') // Replace spaces with -
        .replace(/[^\w-]+/g, '') // Remove all non-word chars
        .replace(/--+/g, '-') // Replace multiple - with single -
        .replace(/^-+/, '') // Trim - from start of text
        .replace(/-+$/, ''); // Trim - from end of text
}

export function objectDeepClone(obj) {
    return JSON.parse(JSON.stringify(obj));
}

export function getRelativeDays(date) {
    if (date) {
        let eventDate = moment(date)
        let todaysDate = moment()
        return eventDate.diff(todaysDate, 'days')
    }
    return 0
}

export function formatDateShowMonth(date, year, addComma?) {
    if (date) {
        if(year === 2){
            let value = moment(date).format("DD MMM YY")
            if(addComma){
                let array = value.split("")
                array.splice(6,1,"'")
                return array.join("")
            }
            return value
        }
        else if(year === 4){
            let value = moment(date).format("DD MMM YYYY")
            if(addComma){
                let array = value.split("")
                array.splice(6,1,"'")
                return array.join("")
            }
            return value
        }
    }
    return "-"
}
export function formatDateShowMonthBackendFOS(date, year, addComma?) {
    if (date) {
        if(year === 2){
            let value = moment(date).format("DD MMM YY")
            if(addComma){
                let array = value.split("")
                array.splice(6,1,"'")
                return array.join("")
            }
            return value
        }
        else if(year === 4){
            let value = moment(date).format("DD MMM YYYY")
            if(addComma){
                let array = value.split("")
                array.splice(6,1,", ")
                return array.join("")
            }
            return value
        }
    }
    return "-"
}
export function formatDateShowMonthBackend(date, year) {
    if (date) {
        if(year === 2)
            return moment(date).format("DD MMM, YY")
        else if(year === 4)
            return moment(date).format("DD MMM' YYYY")
    }
    return "-"
}

export function setCookie(cookieName, cookieValue) {
    document.cookie = cookieName + "=" + cookieValue + ";path=/"
}

export function getCookie(cookieName) {
    const nameEQ = cookieName + "="
    const cookieArr = document.cookie.split(';')
    for (let value of cookieArr) {
        let c = value
        while (c.charAt(0) === ' ') {
            c = c.substring(1, c.length)
        }
        if (c.indexOf(nameEQ) === 0) {
            return c.substring(nameEQ.length, c.length)
        }
    }
    return null
}

export function eraseCookie(cookieName) {
    document.cookie = cookieName + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;'
}

export function eraseAllCookies() {
    var cookies = document.cookie.split(";");
    for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var eqPos = cookie.indexOf("=");
        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
}

export function checkIfNumberOnly(value) {
    const str = /^[0-9\b]+$/
    return  value ? str.test(value) : false
}

export function titleCase(str) {
    var splitStr = str ? str.toLowerCase().split(' ') : []
    for (var i = 0; i < splitStr.length; i++) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
    }
    return splitStr.join(' ')
}

export function compareObjs(obj1, obj2) {
    let str1 = (obj1 && typeof obj1 === "object") ? JSON.stringify(obj1) : "str1"
    let str2 = (obj2 && typeof obj2 === "object") ? JSON.stringify(obj2) : "str2"

    str1 = str1.replace(/\s+/g, '')
    str2 = str2.replace(/\s+/g, '')

    return str1.toLowerCase() === str2.toLowerCase()
}

export function checkValue(value, not) {
    if(value) {
        return value
    }
    return not ? not : null
}

export function rad(x) {
    return x * Math.PI / 180;
}

export function getDistance(p1, p2) {
    let R = 6378137 // Earth’s mean radius in meter

    let dLat = rad(p2.lat - p1.lat)
    let dLong = rad(p2.lng - p1.lng)

    let a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(rad(p1.lat)) * Math.cos(rad(p2.lat)) *
      Math.sin(dLong / 2) * Math.sin(dLong / 2)

    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
    let d = R * c

    const mi = d * 0.000621371192
    return mi.toFixed(1) + " miles" // returns the distance in meter
}
export const duplicateInventoryName = (inventories, parentName) => {
    let array = inventories.map(inventory => inventory.name.replace(/[A-Za-z]/g, '').trim())
    return parentName + " " + (Math.max(...array) + 1)
}
export const specialCharOnly = (val) => {
    const regx = /[a-zA-Z0-9.!@#$%^&*(),.?":{}|<>]*[a-zA-Z0-9][a-zA-Z0-9.!@#$%^&*(),.?":{}|<>]*/ //alphabets with numbers and special chars allowed but not only special chars allowed
    return regx.test(val)
}

export function formatInventories(allInventories, type, skipFormatName?: boolean, isDuplicate?: boolean, duplicateName?: String) {
    const attrStructure = {
        name: "",
        images: [],
        count: 1,
        customName: "",
        attr: {},
        _id: ""
    }
    const inventoryStructure = {
        name: "",
        _checked: false,
        quantity: 1,
        data: [],
        bookedPeriods: []
    }
    const inventories = type ? allInventories.filter(t => t.type.toLowerCase() === type.toLowerCase()) : objectDeepClone(allInventories)
    // console.log("inventoriesBookedPeriod", inventories)
    const finalList = []
    inventories.forEach(inventory => {
        let json : any = objectDeepClone(inventoryStructure)
        if(inventory.bookedPeriods){
            console.log("inventory.bookedPeriods", inventory.bookedPeriods)
            json.bookedPeriods = [...inventory.bookedPeriods]
        }
        if(isDuplicate){
            json.name = inventory.parentName || duplicateName
        }
        else json.name = inventory.parentName || inventory.name
        if (inventory.placements && inventory.placements.length > 0) {
            let attrData :any = objectDeepClone(attrStructure)
            inventory.placements.forEach((placement, pIndex) => {
                if(!isDuplicate){
                    attrData._id = inventory._id;
                    if(allInventories[0].images){
                        attrData.images = [...allInventories[0].images.map(el => el.key)]
                    }
                }
                else{
                    delete attrData._id;
                    attrData.name = duplicateName
                    if(allInventories[0].images){
                        attrData.images = [...allInventories[0].images.map(el => el.key)]
                    }
                }
                if (skipFormatName !== true && !isDuplicate) {
                    // attrData.name = `${inventory.name}`
                    attrData.name = !stringWithoutNumber(inventory.name) ? inventory.name + " " + 1 : inventory.name
                }
                if(isDuplicate){
                    let data = {...placement.data}
                    Object.keys(data).forEach((pKey) => {
                        if(pKey !== "_id" && pKey !== "name" && pKey !== "units"){
                            // data[pKey] = 0
                        }
                    })
                    delete data._id
                    attrData.attr[placement.name] = {
                        quantity: data.quantity,
                        units: data.units || "feet",
                        length: data.length,
                        height: data.height,
                        breadth: data.breadth,
                        inventoryMasterAttrSlug: placement.attrSlug || "",
                        noOfChangesYearly: placement.noOfChangesYearly || 0,
                        cpi: placement.cpi || 0,
                        materialCost: placement.materialCost || 1,
                        opportunitiesToSee: placement.opportunitiesToSee || 1,
                        ...data
                    }
                }
                else{
                    let data = placement.data || {...placement}
                    delete data.name
                    attrData.attr[placement.name] = {
                        quantity: data.quantity,
                        units: data.units || "feet",
                        length: data.length,
                        height: data.height,
                        breadth: data.breadth,
                        inventoryMasterAttrSlug: placement.attrSlug || "",
                        noOfChangesYearly: placement.noOfChangesYearly || 0,
                        cpi: placement.cpi || 0,
                        materialCost: placement.materialCost || 1,
                        opportunitiesToSee: placement.opportunitiesToSee || 1,
                        ...data
                    }
                    console.log("attrData--->",attrData.attr[placement.name])

                }
            })
            json.data.push(attrData)
        }
        finalList.push(json)
    })
    return finalList
}

export function formatInventoriesForEvents(allInventories, eventType?: any) {
    const type = "events&Occasions"
    const eventStructure = {
        id: "",
        name: "",
        custom: false,
        availableForBranding: false,
        venue: [],
        otherVenueList: [],
        months: [],
        amenities: [],
    }
    const inventoryStructure = {
        name: "",
        availableForBranding: false,
        allowBrandsToProvide: false,
        _checked: false,
        images: [],
        count: 1,
        isSelected: false,
        isCustom: false,
        data: {}
    }
    const inventories = eventType ? objectDeepClone(allInventories) : allInventories.filter(t => t.type.toLowerCase() === type.toLowerCase())
    const finalList = []
    inventories.forEach(inventory => {
        let json : any = objectDeepClone(eventStructure)
        json.name = inventory.name
        json._id = inventory._id
        if(eventType === "events&Occasions"){
            json.availableForBranding = inventory.status === "Unavailable" ? false : true
            if(inventory.months && inventory.months.length !== 0){
                json.months = [...inventory.months]
            }
            if(inventory.venue && inventory.venue.length !== 0){
                json.venue = [...inventory.venue]
            }
            if(inventory.otherVenueList && inventory.otherVenueList.length !== 0){
                json.otherVenueList = [...inventory.otherVenueList]
            }
        }
        if (inventory.placements && inventory.placements.length > 0) {
            inventory.placements.forEach((placement, pIndex) => {
                let attrData :any = objectDeepClone(inventoryStructure)
                attrData.name = placement.name
                // if(eventType === "events&Occasions"){

                // }
                if(eventType === "events&Occasions"){
                    if(inventory?.assetsProvidedBy && inventory?.assetsProvidedBy.length !== 0)
                    attrData.allowBrandsToProvide = inventory?.assetsProvidedBy[pIndex] === "Self" ? false : true
                    if(placement.data.images && placement.data.images.length > 0){
                        attrData.images = [...placement.data.images]
                    }
                    attrData._id = placement.data._id
                    Object.keys(placement.data).forEach((key) => {
                        attrData[key] = placement.data[key]
                    })
                }
                attrData.data = {
                    quantity: placement.data.quantity,
                    units: "feet",
                    length: placement.data.length,
                    height: placement.data.height,
                    breadth: placement.data.breadth,
                    ...placement.data
                }
                delete attrData.data.images
                delete attrData.data._id
                json.amenities.push(objectDeepClone(attrData))
            })
            // json.data = objectDeepClone(attrData)
        }
        finalList.push(json)
    })
    return finalList
}

export function validateFullName(val) {
    let returnValue = true
    if (!val || val.trim() === "") {
        returnValue = false
    }

    // const index = val.trim().split("").findIndex(e => e === " ")
    // if (index <= 0 || index >= val.trim().split("").length - 1) {
    //     returnValue = false
    // }

    // const regex = /^([a-zA-Z]+\s)*[a-zA-Z]+$/;
    // if (!regex.test(val)) {
    //     returnValue = false
    // }
    // input string must be at least two word
    const regex = /\b\w+\b.*\w+\b/;
        if (!regex.test(val)) {
        returnValue = false
    }
    
    const regex2 = /^[a-zA-Z\s]*$/;
    if (!regex2.test(val)) {
    returnValue = false
     }
    
    // const regex1 = /^.{2,250}$/;
    // if(regex1.test(val)) {
    //     returnValue = false
    // }
    return returnValue

}

export function stringWithoutNumber(val) {
    let str = val.replace(/[A-Za-z]/g, '').trim()
    return val ? str.length > 0 ? true : false : false
}

export function validateActName(val) {
    let returnValue = true
    if (!val || val.trim() === "") {
        returnValue = false
    }
    
    var regex = /^[A-Za-z0-9 ]+$/ //alphabets with numbers allowed
    var regNumbers=/^[0-9]*$/ //only numbers not allowed
    console.log(regNumbers.test(val))
    if (!regex.test(val) || regNumbers.test(val)) {
        returnValue = false
    }
    // if(val.length>50){
    //     returnValue = false
    // }

    // const regex1 = /^.{2,250}$/;
    // if(regex1.test(val)) {
    //     returnValue = false
    // }
    return returnValue

}

export function validateSchoolName(val) {
    let returnValue = true
    if (!val || val.trim() === "") {
        returnValue = false
    }

    // const index = val.trim().split("").findIndex(e => e === " ")
    // if (index <= 0 || index >= val.trim().split("").length - 1) {
    //     returnValue = false
    // }
    
    // var regNumbers=/^[0-9]*$/ //only numbers not allowed
    // // var regAllowed=/^[ A-Za-z0-9_@./%!$#&+-]*$/ //alphabets with numbers and special chars allowed
    // var regex=/[a-zA-Z0-9., &:-]*[a-zA-Z0-9][a-zA-Z0-9., &:-]*/ //alphabets with numbers and special chars allowed but not only special chars allowed
    // var regexNumberWithChars= /^[0-9-!@#$%*?]/;
    // console.log(regexNumberWithChars.test(val))
    // if (regNumbers.test(val) || !regex.test(val) || regexNumberWithChars.test(val)) {
    //     returnValue = false
    // }

    const regex2 = /[a-zA-Z]/;
    if (!regex2.test(val)) {
        returnValue = false
    }


    // const regex1 = /^.{2,250}$/;
    // if(regex1.test(val)) {
    //     returnValue = false
    // }
    return returnValue

}
export const checkSpecialCharacter = (val) => {
    const regex = /[!@#$%^&*(),.?":{}|<>]/
    return regex.test(val)
}
export const validateCampaignName = (val) => {
    let returnValue = true
    if (!val || val.trim() === "") {
        returnValue = false
    }
    // var regNumbers=/^[0-9]*$/ //only numbers not allowed
    // // var regAllowed=/^[ A-Za-z0-9_@./%!$#&+-]*$/ //alphabets with numbers and special chars allowed
    // var regex=/[a-zA-Z0-9., &:-]*[a-zA-Z0-9][a-zA-Z0-9., &:-]*/ //alphabets with numbers and special chars allowed but not only special chars allowed
    // var regexNumberWithChars= /^[0-9-!@#$%*?]/;
    // console.log(regexNumberWithChars.test(val))
    // if (regNumbers.test(val) || !regex.test(val) || regexNumberWithChars.test(val)) {
    //     returnValue = false
    // }
    const regex2 = /[a-zA-Z]/;
    if (!regex2.test(val)) {
        returnValue = false
    }

    return returnValue
}
export function validatePrincipalName(val) {
    let returnValue = true
    if (!val || val.trim() === "") {
        returnValue = false
    }

    // const index = val.trim().split("").findIndex(e => e === " ")
    // if (index <= 0 || index >= val.trim().split("").length - 1) {
    //     returnValue = false
    // }

    // const regex = /^([a-zA-Z]+\s)*[a-zA-Z]+$/;
    // if (!regex.test(val)) {
    //     returnValue = false
    // }
    
    const regex = /\b\w+\b.*\w+\b/;
    if (!regex.test(val)) {
    returnValue = false
    }

    const regex2 = /^[a-zA-Z\s-]*$/;
    if (!regex2.test(val)) {
        returnValue = false
    }
    
    // let returnValue = true
    // if (!val || val.trim() === "") {
    //     returnValue = false
    // }
    
    // const regex = /[A-Za-z]/;
    // if (!regex.test(val)) {
    //     returnValue = false
    // }

    // const regex1 = /^.{2,250}$/;
    // if(regex1.test(val)) {
    //     returnValue = false
    // }
    return returnValue

}
export function isObjectEmpty(obj){
    return Object.keys(obj).length === 0 && obj.constructor === Object;
}

export function formatDateToDDMMMYY(date){
    if (date) {
        return moment(date).format("DD MMM YY")
    }
    return "-"
}

export function getPlaceFromAddressComponents(addressComponents) {
    let place = ""
    addressComponents.forEach((element2) => {
        element2.types.forEach((element3) => {
            switch(element3){
                case 'locality':
                    place = element2.long_name
                    break
            }
        })
    })
    return place
}

export function getSublocalityFromAddressComponents(addressComponents) {
    let place = ""
    addressComponents.forEach((element2) => {
        element2.types.forEach((element3) => {
            switch(element3){
                case 'sublocality':
                    place = element2.long_name
                    break
            }
        })
    })
    return place
}
export const calculateImpression = (totalImpression, startDate, endDate, pauseDateArray, resumeDateArray) => {
    if(moment().isBefore(moment(startDate))){
        return 0
    }
    else if(moment().isAfter(moment(endDate))){
        return totalImpression
    }
    else{
        let edleDays = 0
        for(let i = 0; i < pauseDateArray.length && i < resumeDateArray.length; i++){
            edleDays += moment(resumeDateArray[i]).diff(pauseDateArray[i], 'days')
        }
        let totalDays = moment(endDate).diff(moment(startDate), "days") + 1
        let daysCompleted = moment().diff(moment(startDate), "days") + 1
        let actualDaysCompleted = totalDays - edleDays
        if(pauseDateArray.length > resumeDateArray.length){
            edleDays += moment(pauseDateArray[pauseDateArray.length]).diff(moment(), "days") 
        }
        return ((daysCompleted - edleDays)/totalDays)*totalImpression
    }
    
}
export const getPercentageComplition = (startDate, endDate) => {
    const start = moment(startDate, 'YYYY-MM-DD')
    const end = moment(endDate, 'YYYY-MM-DD')
    const today = moment()
    // if(today.diff(start, 'second') < 0){
    //     return 0
    // }
    if(moment().isBefore(moment(startDate))){
        return 0
    }
    const totalDays = end.diff(start, 'days') + 1
    const daysCompleted = today.diff(start, 'days') + 1
    console.log("new-------------------", totalDays, daysCompleted, startDate, endDate)
    let value = Math.round((daysCompleted/totalDays)*100)
    return value > 100 ? 100 : value
}
export function returnReadableStatus (status) {
    if(status == 'in-progress') {
        return 'In Progress';
    }
    else if(status == "in-review"){
        return "Review"
    }
    else {
        return status.charAt(0).toUpperCase() + status.slice(1);
    }
}
export function hasOnlyLettersAndSpaces(string) {
    return /^[a-zA-Z\s]*$/.test(string);
}
export function hasOnlyLettersNumberAndSpaces(string) {
    return /^[a-zA-Z0-9\s]*$/.test(string);
}
export function numberWithCommas(num) {
    return num.toString().replace(/\B(?=(\d{2})+(?!\d))/g, ",");
}
export const findMonthsBetweenDate = (startDate, endDate) => {
    if(startDate && endDate){
        let monthArray = []
        let start = moment(startDate, "YYYY-MM-DD")
        let end = moment(endDate, "YYYY-MM-DD")
        while(start.format("MM-YYYY") !== end.format("MM-YYYY")){
            if(monthArray.includes(start.format("MMM")))
                break;
            monthArray.push(start.format("MMM"))
            start = start.clone().add(1, "month")
        }
        if(!monthArray.includes(end.format("MMM"))){
            monthArray.push(end.format("MMM"))
        }
        console.log("checkfirst1", monthArray)
        return monthArray
    }
    return []
}

export function revenueFormatter(num) {
    if (num >= 100000000) {
       return (Math.floor(num) / 10000000).toFixed(2).replace(/\.0$/, '') + 'C';
    }
    if (num >= 100000) {
        return (Math.floor(num) / 100000).toFixed(2).replace(/\.0$/, '') + 'L';
    }
    if (num >= 1000) {
       return (Math.floor(num) / 1000).toFixed(2).replace(/\.0$/, '') + 'K';
    }
    return num;
}
export function isValidId (str) {
    return (new RegExp("^[0-9a-fA-F]{24}$")).test(str);
}
export function createUniqueUsername (name) {
    return `${name.split(' ')[0].toLowerCase()}${String(new Date().getTime()).substr(9,4)}`
}
export const sortEduCationLevel = (arr) => {
    let sortedArray = ['Nursery/Playgroup', 'Junior Kg/Senior Kg', 'Primary (1 - 4)', 'Secondary (5 - 10)', 'Higher/Senior Secondary(10+2)']
    sortedArray = [...sortedArray.filter(eduLevel => arr.find(item => item === eduLevel))]
    console.log(sortedArray)
    return [...sortedArray]
}
export const sortMonths = (arr) => {
    let sortedArray = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    sortedArray = [...sortedArray.filter(eduLevel => arr.find(item => item === eduLevel))]
    console.log(sortedArray)
    return [...sortedArray]
}

export function isBase64(str) {
    // console.log("isBase64 str", str)
    // let base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
    // return base64regex.test("normal string");  
    console.log("str>>>>",str)
    if(str === undefined) return false
    if (str ==='' || str.trim() ==='' || str === undefined){ return false; }
    try {
        window.atob(str)
        return false
    } catch (err) {
        console.log("isBase64", err)
        return true;
    }
}

export function signOutUser(context, redirectUrl){
    console.log("singOutUser", context, redirectUrl)
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };
    fetch("/api/logout", requestOptions)
    .then(response => response.json())
    .then(data => {
        console.log("data>>>>>",data);
        if(data.done){
            localStorage.setItem("prevUserId", context.user._id)
            eraseAllCookies();
            eraseCookie("session-wisr-core-microservice-backend")
            context.socketManager.socket.disconnect();
            document.location.href = redirectUrl
            sessionStorage.clear()
        }
        return true
    }).catch(err=>{
        console.log(err);
        return false
    })
}
export const readAbleBoard = (board) => {
    if(board.toLowerCase() === "state"){
        return board.charAt(0).toUpperCase() + board.slice(1)
    }
    else{
        return board.toUpperCase()
    }
}
export const numDifferentiation = (value) => {
    var val:any = Math.abs(value)
    if (val >= 10000000) {
      val = (val / 10000000).toFixed(2) + ' Cr';
      return(val)
    } else if (val >= 100000) {
      val = (val / 100000).toFixed(2) + ' Lac';
      return(val)
    }
}
export const downloadFile = (arrayBuffer, filename)=>{
    let binary = '';
    const bytes = new Uint8Array(arrayBuffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    const file = window.btoa(binary);
    const mimType = "application/xlsx"
    const url = `data:${mimType};base64,` + file;

    // download the file
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
}
export const formatDateDDMonth =(input)=> {
    let monthString;
    var datePart = input.match(/\d+/g),
        year = datePart[0].substring(2), // get only two digits
        month = datePart[1], day = datePart[2];
    if (month === '01') monthString = 'JAN';
    if (month === '02') monthString = 'FEB';
    if (month === '03') monthString = 'MAR';
    if (month === '04') monthString = 'APR';
    if (month === '05') monthString = 'MAY';
    if (month === '06') monthString = 'JUN';
    if (month === '07') monthString = 'JUL';
    if (month === '08') monthString = 'AUG';
    if (month === '09') monthString = 'SEP';
    if (month === '10') monthString = 'OCT';
    if (month === '11') monthString = 'NOV';
    if (month === '12') monthString = 'DEC';
    return day + "' " + monthString;
}
export const addHttpsInUrl = (url) => {
    if(url.startsWith("https://") || url.startsWith("http://")){
        return url
    }
    else{
        return "https://" + url;
    }
}

export const allowDecimalUpto = (number, decimal) => {
    let newNumber = Math.pow(10, decimal) * number
    let str = newNumber.toString()
    let newStr = ''
    for(let i = 0; i<str.length && str[i] !== '.'; i++){
        newStr += str[i]
    }
    return Number(newStr) / Math.pow(10, decimal)
}
    
// function numberWithCommas(num) {
//     return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
//   }