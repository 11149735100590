import React, {
	useState,
	useEffect,
	useContext,
	createRef,
	useRef,
} from "react";
import { useHistory, useLocation } from "react-router-dom";
import MultiPointMap from "../../../../components/addon/multiPointMap";
import scriptLoader from "react-async-script-loader";
import uploadSvg from "../../../../assets/images/upload-svg.svg";
import sucessCheck from "../../../../assets/images/sucess-check.svg";
import closeSvg from "../../../../assets/images/close-svg.svg";
import blockSvg from "../../../../assets/images/block-svg.svg";
import reloadSvg from "../../../../assets/images/reload-svg.svg";
import filterSvg from "../../../../assets/images/filter-svg.svg";
import filterArrowSvg from "../../../../assets/images/filter-arrow-svg.svg";
import mapsImg from "../../../../assets/images/maps.jpg";
import AppContextObject from "../../../../common/context/common";
import DataContext from "../../../../service/brandContext";
import Pagination from "../../../../components/addon/pagination";
import CampaignBudget from "../components/campaignBudget";
import GenderSplit from "../components/genderSplit";
import CampaignReach from "../components/campaignReach";
import CampaignImpression from "../components/campaignImpression";
import {
	compareObjs,
	objectDeepClone,
	returnReadableStatus,
	titleCase,
} from "../../../../service/helper";
import config from "../../../../service/config";
import AlertDialog from "../../../../components/addon/alertDialog/alertDialog";
import ConfirmDialog from "../../../../components/addon/confirmDialog/confirmDialog";
import errorSVG from "../../../../assets/images/error.svg";
import searchSvg from "../../../../assets/images/search.svg";
import editSvg from "../../../../assets/images/edit-btn.svg";
import { DebounceInput } from "react-debounce-input";
import CustomSearchInput from "../../../../components/formFields/customSearchInput";
import useClickOutside from "../../../../service/useClickOutside";
import BrandBackBtn from "../components/brandBackBtn";
import moment from "moment";
import { WisrOptionService } from "../../../../service/wisrOptions";
import SchoolTable from "../components/schoolTable";
import school from "../school";
import toast from "react-hot-toast";
// import CustomSchoolTable from "./customSchoolTable";
import TextArea from "../../../../components/formFields/textArea";
import SimpleReactValidator from "simple-react-validator";
import CampaignPreview from "./campaignPreview";
import campaignDetails from "../campaignDetails";

var algoSchoolListGlobalVar = 1;

const CustomComments = ({ setShowProgressSideMenu }) => {
	const [WisrOption, setWisrOption] = useState<any>();
	const filterRef = useRef(null);
	const filterOptionsRef = useRef(null);
	const location = useLocation();
	const history = useHistory();
	const context = useContext(AppContextObject);
	const parentData = useContext<any>(DataContext);

	console.log("DataContextParentData", parentData);

	const [loadingFirstTime, setLoadingFirstTime] = useState(true);

	//location data
	const [stateList, setStateList] = useState([]);
	const [selectedState, setSelectedState] = useState([
		...parentData.data.brandDetail.state,
	]);
	const [cityList, setCityList] = useState([
		...parentData.data.brandDetail.city.sort((a, b) =>
			a.toLowerCase() > b.toLowerCase() ? 1 : -1
		),
	]);
	const [cityStateData, setCityStateData] = useState({});

	//search related data
	const [searchInpuOpen, setSearchInpuOpen] = useState(false);
	const [clearSearchIcon, setClearSearchIcon] = useState(false);
	const [searchValue, setSearchValue] = useState("");

	const [tableEditMode, setTableEditMode] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPage, setTotalPage] = useState([0]);
	const [totalBudget, setTotalBudget] = useState(
		parentData.data.campaignDetail.totalBudget
	);
	const [totalReach, setTotalReach] = useState(
		parentData.data.campaignDetail.totalReach
	);
	const [boysRatio, setBoysRatio] = useState(0);
	const [girlsRatio, setGirlsRatio] = useState(0);
	const [boysCount, setBoysCount] = useState(0);
	const [girlsCount, setGirlsCount] = useState(0);
	const [totalImpression, setTotalImpression] = useState(
		parentData.data.campaignDetail.totalImpression
	);
	const [pincodeList, setPincodeList] = useState([]);
	const [saveAndContinue, setSaveAndContinue] = useState(false);

	const [formData, setFormData] = useState({
		schools: [...parentData.data.school.schools],
		locations: [...parentData.data.school.locations],
	});

	console.log("consoleParentAndFormData Parent", parentData)
	console.log("consoleParentAndFormData formData", formData)

	const [reason, setReason] = useState({
		
		...parentData?.data?.campaignData,
	});

	const [schoolList, setSchoolList] = useState([]);
	const [paginationSchool, setPaginationSchool] = useState([]);
	const [initialSchools, setInitialSchools] = useState([]);
	const [overlay, setOverlay] = useState(false);
	const [filterByCity, setFilterByCity] = useState([
		...parentData.data.brandDetail.city,
	]);
	const [filterByLanguage, setFilterByLanguage] = useState([]);
	const [filterByBoard, setFilterByBoard] = useState([]);
	const [filterBySchoolType, setFilterBySchoolType] = useState([]);
	const [showFilterModal, setShowFilterModal] = useState(false);
	const [showCityFilter, setShowCityFilter] = useState(false);
	const [showLanguageFilter, setShowLanguageFilter] = useState(false);
	const [showBoardFilter, setShowBoardFilter] = useState(false);
	const [showSchoolTypeFilter, setShowSchoolTypeFilter] = useState(false);
	const [showStateFilter, setShowStateFilter] = useState(false);
	const [dataPerPage] = useState(25);
	// const [countryArray] = useState([{label:"Hyderabad", value: "hyderabad"},{label:"Chennai", value: "chennai"}, {label:"Kolkata", value: "kolkata"}, {label:"Mumbai", value: "mumbai"}, {label:"Bengaluru", value: "bengaluru"}])
	const [pageLoading, setPageLoading] = useState(true);
	const [showLimitExceeded, setShowLimitExceeded] = useState(false);
	const [showExitPopup, setShowExitPopup] = useState(false);
	const [showErrorMessage, setShowErrorMessage] = useState(false);
	const [budgetExceeded, setBudgetExceeded] = useState(false);
	const [totalSchoolCount, setTotalSchoolCount] = useState(0);
	const [schoolCount, setSchoolCount] = useState(0);
	const [confirmDialogBtnLoading, setConfirmDialogBtnLoading] = useState(false);
	const [allSchoolList, setAllSchoolList] = useState([]);
	const [minReach, setMinReach] = useState(0);
	const [maxReach, setMaxReach] = useState(0);
	const [minImpression, setMinImpression] = useState(0);
	const [maxImpression, setMaxImpression] = useState(0);
	const [increasedBudget, setIncreasedBudget] = useState(0);
	const [customSchoolList, setCustomSchoolList] = useState([]);
	const [algoDataset, setAlgoDataset] = useState({
		classroomData: [...parentData.data.algoData.classroomData],
		inventoryData: [...parentData.data.algoData.inventoryData],
		eventData: [...parentData.data.algoData.eventData],
		schoolData: [...parentData.data.algoData.schoolData],
		noOfDaysInYear: parentData.data.algoData.noOfDaysInYear,
		percentageDiscountInWISR: parentData.data.algoData.percentageDiscountInWISR,
		percentageIncreaseInReach:
			parentData.data.algoData.percentageIncreaseInReach,
		totalInternalCostPerSchool:
			parentData.data.algoData.totalInternalCostPerSchool,
		budgetRatio: { ...parentData.data.algoData.budgetRatio },
	});
	useClickOutside(filterOptionsRef, (event) => {
		const el = filterRef?.current;

		if (!el || el.contains(event.target)) {
			return;
		}
		setShowFilterModal(false);
	});
	useEffect(() => {
		const isSummary = new URLSearchParams(location.search).get("isSummary");
		console.log("isSummary", isSummary);
		if (isSummary === "true") {
			console.log("object");
			parentData.setProgress({
				brandDetails: true,
				inventory: true,
				event: true,
				school: true,
				recommendedSchool: false,
				campaignCreative: false,
				preview: false,
				customComments: true,
			});
		}
	}, []);
	useEffect(() => {
		if (cityStateData && Object.keys(cityStateData).length > 0) {
			fetchCityByState();
		}
	}, [selectedState]);
	useEffect(() => {
		if (
			cityStateData &&
			Object.keys(cityStateData).length > 0 &&
			selectedState &&
			selectedState.length
		) {
			let newState = [];
			selectedState.forEach((item) => {
				let cities = [...cityStateData[item]];
				let found = 0;
				cities.forEach((data) => {
					if (filterByCity.includes(data)) {
						found++;
					}
				});
				if (found) {
					newState.push(item);
				}
			});
			setSelectedState([...newState]);
		}
	}, [filterByCity]);
	const fetchCityByState = async () => {
		let city = [];
		selectedState.forEach((state) => {
			if (cityStateData[state] && cityStateData[state].length > 0) {
				cityStateData[state].forEach((item) => {
					if (!city.find((data) => data.toLowerCase() === item.toLowerCase())) {
						city.push(item);
					}
				});
			}
		});
		city = city.sort((a, b) => {
			return a.toLowerCase() > b.toLowerCase() ? 1 : -1;
		});
		console.log("cityFirst", city);
		setCityList([...city]);
	};

	useEffect(() => {
		setShowProgressSideMenu(true);
		if (parentData.setActiveModule) {
			parentData.setActiveModule({
				...parentData.activeModule,
				module: "custom",
				subModule: "custom",
			});
		}
	}, []);

	const onNextClick = (page) => {
		setCurrentPage(page);
		let schools = customSchoolList.slice(
			(page - 1) * dataPerPage,
			page * dataPerPage
		);
		let city = [];
		schools.forEach((item) => {
			city.push(item.city);
		});
		setPaginationSchool([...schools]);
	};
	const onPrevClick = (page) => {
		setCurrentPage(page);
		let schools = customSchoolList.slice(
			(page - 1) * dataPerPage,
			page * dataPerPage
		);
		let city = [];
		schools.forEach((item) => {
			city.push(item.city);
		});
		setPaginationSchool([...schools]);
	};
	const onPageChange = (page) => {
		setCurrentPage(page);
		let schools = customSchoolList.slice(
			(page - 1) * dataPerPage,
			page * dataPerPage
		);
		let city = [];
		schools.forEach((item) => {
			city.push(item.city);
		});
		setPaginationSchool([...schools]);
	};

	console.log("parentData-->>", parentData);
	console.log("customSchoolList from customSchool component", customSchoolList);

	useEffect(() => {
		if (!loadingFirstTime) {
			getFilteredSchoolList();
		}
	}, [
		filterByBoard,
		filterByCity,
		filterByLanguage,
		filterBySchoolType,
		searchValue,
		selectedState,
	]);
	useEffect(() => {
		if (currentPage !== 1) {
			setCurrentPage(1);
		}
	}, [
		filterByBoard,
		filterByCity,
		filterByLanguage,
		filterBySchoolType,
		searchValue,
		selectedState,
	]);
	const getFilteredSchoolList = async () => {
		let startDate = moment(new Date(parentData.data.brandDetail.startDate));
		let endDate = moment(new Date(parentData.data.brandDetail.endDate));
		let duration = moment.duration(endDate.diff(startDate));
		let days = duration.asDays();
		let dataset = {
			startDate: parentData.data.brandDetail.startDate,
			endDate: parentData.data.brandDetail.endDate,
			_id: null,
			brandCategoryId: parentData.data.brandDetail.brandCategory._id,
			searchValue: searchValue,
			budget: parentData.data.campaignDetail.totalBudget,
			ageGroup: [
				parentData.data.brandDetail.targetAgeGroup.min,
				parentData.data.brandDetail.targetAgeGroup.max,
			],
			locations: filterByCity,
			states: selectedState,
			inventories: !parentData?.data?.brandDetail?.inventory
				? []
				: [...parentData.data.inventory.inventories],
			events: !parentData?.data?.brandDetail?.eventAndOccations
				? []
				: [...parentData.data.eventAndOccation.eventAndOccations],
			gender:
				filterBySchoolType.length > 0
					? filterBySchoolType
					: parentData.data.brandDetail.targetGender === "both"
					? ["boys", "girls", "co-ed"]
					: parentData.data.brandDetail.targetGender === "girls"
					? ["girls", "co-ed"]
					: ["boys", "co-ed"],
			language:
				filterByLanguage.length > 0
					? filterByLanguage
					: parentData.data.brandDetail.studyMedium,
			board: filterByBoard,
			campaignDuration: days,
		};
		setPageLoading(true);
		var localVar = Math.random();
		algoSchoolListGlobalVar = localVar;
		let state: any = history.location.state;
		console.log("historyLocation", history.location);
		let resp;
		if (state && state.showNewData) {
			resp = await context.apis[
				context.user.role
			].getRecommendedDataByCampaignDataV2(context.validator, {
				data: dataset,
			});
		} else {
			resp = await context.apis[
				context.user.role
			].getRecommendedDataByCampaignData(context.validator, { data: dataset });
		}

		console.log("customSchoolListGetRecommendedDataByCampaignData", dataset);
		if (resp && resp.done) {
			if (localVar === algoSchoolListGlobalVar) {
				let startDate = moment(new Date(parentData.data.brandDetail.startDate));
				let endDate = moment(new Date(parentData.data.brandDetail.endDate));
				let duration = moment.duration(endDate.diff(startDate));
				let days = duration.asDays();
				let inventoryName = !parentData?.data?.brandDetail?.inventory
					? []
					: parentData.data.inventory.inventories.reduce((acc, current) => {
							if (!acc.includes(current.name)) {
								acc.push(current.name);
							}
							return acc;
					  }, []);
				let eventName = !parentData?.data?.brandDetail?.eventAndOccations
					? []
					: parentData.data.eventAndOccation.eventAndOccations.reduce(
							(acc, current) => {
								if (current.parentName) {
									if (!acc.includes(current.parentName)) {
										acc.push(current.parentName);
									}
								} else {
									if (!acc.includes(current.name)) {
										acc.push(current.name);
									}
								}
								return acc;
							},
							[]
					  );
				console.log("inventoryName-----", eventName, inventoryName);
				console.log("data-------", algoDataset, totalBudget, days);
				const WisrOption = new WisrOptionService({
					TargetAudience:
						parentData.data.brandDetail.targetGender === "both"
							? "co-ed"
							: parentData.data.brandDetail.targetGender,
					EventsNames: [...eventName],
					InventoriesNames: [...inventoryName],
					budgetRatio: { ...resp.response.rows.budgetRatio },
					campaignBudget: totalBudget,
					campaignDurationInDays: days + 1,
					classroomData: [...resp.response.rows.classrooms], //List of classrooms
					eventData: [...resp.response.rows.events], //List of events
					inventoryData: [...resp.response.rows.inventories], //List of inventories
					schoolData: [...resp.response.rows.schools], //List of schools
					noOfDaysInYear: resp.response.rows.noOfDaysInYear,
					percentageDiscountInWISR:
						resp.response.rows.percentageIncreaseInBudget,
					percentageIncreaseInReach:
						resp.response.rows.percentageIncreaseInReach,
					totalInternalCostPerSchool:
						resp.response.rows.totalInternalCostPerSchool,
				});
				WisrOption.$OptimizedSchool.subscribe((school) => {
					console.log("optimisedSchoolIds", school);
					let sortedSchool = school.sort((a) => {
						let found = formData.schools.find((item) => item._id === a._id);
						return found ? -1 : 1;
					});
					setCustomSchoolList([...sortedSchool]);
					let schoolData = [];
					for (let i = 0; i < dataPerPage && i < sortedSchool.length; i++) {
						schoolData.push(sortedSchool[i]);
						
					}
					
					let page = Math.ceil(school.length / dataPerPage);
					let arr = [];
					for (let i = 0; i < page; i++) {
						arr.push(i);
					}
					setTotalPage([...arr]);
					setPaginationSchool([...schoolData]);
					if (loadingFirstTime) {
						setTotalSchoolCount(school.length);
					}
					console.log("OptimizedSchool", school);
				});

				setPageLoading(false);
			} else {
				setPageLoading(false);
			}
		}
	};
	useEffect(() => {
		if (parentData.data.campaignDetail.wisrOption) {
			if (increasedBudget > parentData.data.campaignDetail.totalBudget) {
				if (totalBudget > increasedBudget) {
					setBudgetExceeded(true);
				} else {
					setBudgetExceeded(false);
				}
			} else {
				if (totalBudget > parentData.data.campaignDetail.totalBudget) {
					setBudgetExceeded(true);
				} else {
					setBudgetExceeded(false);
				}
			}
		} else {
			if (totalBudget > parentData.data.campaignDetail.totalBudget) {
				setBudgetExceeded(true);
			} else {
				setBudgetExceeded(false);
			}
		}
	}, [totalBudget]);
	const manuallyChangeBudget = (budgetData) => {
		setPageLoading(true);

		let maxBudget = 0;
		WisrOption.$MaxBudget.subscribe((budget) => {
			maxBudget = budget;
			if (maxBudget < budgetData) {
				WisrOption.$SetBudget.next(maxBudget);
			}
			console.log("maxBudget", budget);
		});
		let selectedSchool = [];
		if (budgetData <= maxBudget) {
			WisrOption.$SetBudget.next(budgetData);
		}

		WisrOption.$GetReach.subscribe((reach) => {
			setTotalReach(reach);
			console.log("GetReach", reach);
		});
		WisrOption.$GetBudget.subscribe((budget) => {
			if (budgetData > maxBudget) {
				setTotalBudget(maxBudget);
			} else {
				setTotalBudget(budget);
			}
		});
		WisrOption.$GetImpression.subscribe((impression) => {
			setTotalImpression(impression);
			console.log("GetImpression", impression);
		});
		WisrOption.$FilteredSchool.subscribe((school) => {
			selectedSchool = [...school];

			let location = [];
			school.forEach((data) => {
				if (!location.includes(data.parentCity)) {
					location.push(data?.parentCity);
				}
			});
			setFormData({
				...parentData.data.school,
				schools: [...school],
				locations: [...location],
			});
			setInitialSchools([...school]);

			console.log("Filtered", school);
		});
		let sortedSchool = customSchoolList.sort((a) => {
			let found = selectedSchool.find((item) => item._id === a._id);
			return found ? -1 : 1;
		});
		setCustomSchoolList([...sortedSchool]);
		let schoolData = [];
		for (let i = 0; i < dataPerPage && i < sortedSchool.length; i++) {
			schoolData.push(sortedSchool[i]);
		}
		let page = Math.ceil(customSchoolList.length / dataPerPage);
		let arr = [];
		for (let i = 0; i < page; i++) {
			arr.push(i);
		}
		setTotalPage([...arr]);
		setPaginationSchool([...schoolData]);
		setPageLoading(false);
	};
	const manuallyChangeImpression = (impressionData) => {
		setPageLoading(true);
		setTotalImpression(impressionData);

		WisrOption.$SetImpressions.next(impressionData);
		let maxBudget = 0;
		WisrOption.$MaxBudget.subscribe((budget) => {
			maxBudget = budget;
			console.log(
				"beforeIf",
				budget,
				parentData.data.campaignDetail.totalBudget
			);
			if (maxBudget < totalBudget) {
				console.log(
					"afterIf",
					budget,
					parentData.data.campaignDetail.totalBudget
				);
				// setTotalBudget(maxBudget)
				WisrOption.$SetBudget.next(maxBudget);
			}
			console.log("maxBudget", budget);
		});
		let selectedSchool = [];

		WisrOption.$GetReach.subscribe((reach) => {
			setTotalReach(reach);
			console.log("GetReach", reach);
		});
		WisrOption.$GetBudget.subscribe((budget) => {
			if (budget > maxBudget) {
				setTotalBudget(maxBudget);
			} else {
				setTotalBudget(budget);
			}
		});
		WisrOption.$GetImpression.subscribe((impression) => {
			setTotalImpression(impression);
			// setImpression(impression)
			console.log("GetImpression", impression);
		});
		WisrOption.$FilteredSchool.subscribe((school) => {
			// if(parentData.data.school.schools.length === 0){
			let location = [];
			school.forEach((data) => {
				if (!location.includes(data.parentCity)) {
					location.push(data?.parentCity);
				}
			});
			selectedSchool = [...school];
			setFormData({
				...parentData.data.school,
				schools: [...school],
				locations: [...location],
			});
			setInitialSchools([...school]);

			console.log("Filtered", school);
		});
		let sortedSchool = customSchoolList.sort((a) => {
			let found = selectedSchool.find((item) => item._id === a._id);
			return found ? -1 : 1;
		});
		setCustomSchoolList([...sortedSchool]);
		let schoolData = [];
		for (let i = 0; i < dataPerPage && i < sortedSchool.length; i++) {
			schoolData.push(sortedSchool[i]);
		}

		let page = Math.ceil(customSchoolList.length / dataPerPage);
		let arr = [];
		for (let i = 0; i < page; i++) {
			arr.push(i);
		}
		setTotalPage([...arr]);
		setPaginationSchool([...schoolData]);
		setPageLoading(false);
	};
	const manuallyChangeReach = (reach) => {
		setPageLoading(true);
		setTotalReach(reach);

		WisrOption.$SetReach.next(reach);
		let maxBudget = 0;
		WisrOption.$MaxBudget.subscribe((budget) => {
			maxBudget = budget;
			console.log(
				"beforeIf",
				budget,
				parentData.data.campaignDetail.totalBudget
			);
			if (maxBudget < totalBudget) {
				console.log(
					"afterIf",
					budget,
					parentData.data.campaignDetail.totalBudget
				);
				// setTotalBudget(maxBudget)
				WisrOption.$SetBudget.next(maxBudget);
			}
			console.log("maxBudget", budget);
		});
		let selectedSchool = [];
		WisrOption.$GetReach.subscribe((reachData) => {
			setTotalReach(reachData);
			console.log("reachmanually", reachData);
		});

		WisrOption.$GetBudget.subscribe((budget) => {
			if (budget > maxBudget) {
				setTotalBudget(maxBudget);
			} else {
				setTotalBudget(budget);
			}
		});
		WisrOption.$GetImpression.subscribe((impression) => {
			setTotalImpression(impression);
			console.log("GetImpression", impression);
		});
		WisrOption.$FilteredSchool.subscribe((school) => {
			selectedSchool = [...school];
			let location = [];
			school.forEach((data) => {
				if (!location.includes(data.parentCity)) {
					location.push(data?.parentCity);
				}
			});

			setFormData({
				...parentData.data.school,
				schools: [...school],
				locations: [...location],
			});
			setInitialSchools([...school]);

			console.log("Filtered", school);
		});
		let sortedSchool = customSchoolList.sort((a) => {
			let found = selectedSchool.find((item) => item._id === a._id);
			return found ? -1 : 1;
		});
		setCustomSchoolList([...sortedSchool]);
		let schoolData = [];
		for (let i = 0; i < dataPerPage && i < sortedSchool.length; i++) {
			schoolData.push(sortedSchool[i]);
		}

		let page = Math.ceil(customSchoolList.length / dataPerPage);
		let arr = [];
		for (let i = 0; i < page; i++) {
			arr.push(i);
		}
		console.log("arrarrarr", arr);
		setTotalPage([...arr]);
		setPaginationSchool([...schoolData]);
		setPageLoading(false);
	};
	const handleAddRemoveFilter = (type, data) => {
		if (type === "state") {
			let array = [...selectedState];
			if (array.includes(data)) {
				if (array.length < 2) {
					toast.error("You need to select atlest one state");
					return;
				}
				array.splice(array.indexOf(data), 1);
				if (filterByCity.length !== 0) {
					let citiesToRemove = [...cityStateData[data]],
						newCitiesData = [...filterByCity];
					console.log(
						"citiesToRemove",
						citiesToRemove,
						"newCitiesData",
						newCitiesData
					);
					for (let city of citiesToRemove) {
						console.log(
							"=====newCitiesData.indexOf(city)==>",
							newCitiesData.indexOf(city)
						);

						if (newCitiesData.indexOf(city) !== -1) {
							newCitiesData.splice(newCitiesData.indexOf(city), 1);
						}
					}

					setFilterByCity([...newCitiesData]);
				}
			} else {
				array.push(data);
				setFilterByCity([...filterByCity, ...cityStateData[data]]);
			}
			setSelectedState([...array]);
		}
		if (type === "city") {
			let array = [...filterByCity];
			if (array.includes(data)) {
				if (array.length < 2) {
					toast.error("You need to select atlest one city");
					return;
				}
				array.splice(array.indexOf(data), 1);
			} else {
				array.push(data);
			}
			setFilterByCity([...array]);
		} else if (type === "board") {
			let array = [...filterByBoard];
			if (array.includes(data)) {
				array.splice(array.indexOf(data), 1);
			} else {
				array.push(data);
			}
			setFilterByBoard([...array]);
		} else if (type === "language") {
			let array = [...filterByLanguage];
			if (array.includes(data)) {
				array.splice(array.indexOf(data), 1);
			} else {
				array.push(data);
			}
			setFilterByLanguage([...array]);
		} else if (type === "schoolType") {
			let array = [...filterBySchoolType];
			if (array.includes(data)) {
				array.splice(array.indexOf(data), 1);
			} else {
				array.push(data);
			}
			setFilterBySchoolType([...array]);
		}
	};

	const getAllSchools = async () => {
		setPageLoading(true);
		console.log("loadingFirstTime", loadingFirstTime);
		let startDate = moment(new Date(parentData.data.brandDetail.startDate));
		let endDate = moment(new Date(parentData.data.brandDetail.endDate));
		let duration = moment.duration(endDate.diff(startDate));
		let days = duration.asDays();

		// customSchoolList;

		
		let allSelectedSchoolsIds = formData.schools.map((selectedSchools) =>
			console.log("selectedSchoolsNew", selectedSchools._id)
		);

		console.log("allSelectedSchoolsIds", allSelectedSchoolsIds);

		let dataset = {
			startDate: parentData.data.brandDetail.startDate,
			endDate: parentData.data.brandDetail.endDate,
			_id: null,
			
			searchValue: "",
			brandCategoryId: parentData.data.brandDetail.brandCategory._id,
			budget: parentData.data.campaignDetail.totalBudget,
			ageGroup: [
				parentData.data.brandDetail.targetAgeGroup.min,
				parentData.data.brandDetail.targetAgeGroup.max,
			],
			locations: [...parentData.data.brandDetail.city]
				? [...parentData.data.brandDetail.city]
				: [],
			states: [...parentData.data.brandDetail.state]
				? [...parentData.data.brandDetail.state]
				: [],

			
			inventories: !parentData?.data?.brandDetail?.inventory
				? []
				: [...parentData.data.inventory.inventories],
			events: !parentData?.data?.brandDetail?.eventAndOccations
				? []
				: [...parentData.data.eventAndOccation.eventAndOccations],
			
			gender:
				parentData.data.brandDetail.targetGender === "both"
					? ["boys", "girls", "co-ed"]
					: parentData.data.brandDetail.targetGender === "girls"
					? ["girls", "co-ed"]
					: ["boys", "co-ed"],
			language: parentData.data.brandDetail.studyMedium,
			board: [],
			campaignDuration: days + 1,
			

			selectedSchools: formData.schools.map((selectedSchools) => [
				selectedSchools._id,
			]),
			
		};

		let inventoryName = !parentData?.data?.brandDetail?.inventory
			? []
			: parentData.data.inventory.inventories.reduce((acc, current) => {
					if (!acc.includes(current.name)) {
						acc.push(current.name);
					}
					return acc;
			  }, []);
		let eventName = !parentData?.data?.brandDetail?.eventAndOccations
			? []
			: parentData.data.eventAndOccation.eventAndOccations.reduce(
					(acc, current) => {
						if (current.parentName) {
							if (!acc.includes(current.parentName)) {
								acc.push(current.parentName);
							}
						} else {
							if (!acc.includes(current.name)) {
								acc.push(current.name);
							}
						}
						return acc;
					},
					[]
			  );

		const wisrOption = new WisrOptionService({
			TargetAudience:
				parentData.data.brandDetail.targetGender === "both"
					? "co-ed"
					: parentData.data.brandDetail.targetGender,
			EventsNames: [...eventName],
			InventoriesNames: [...inventoryName],
			budgetRatio: { ...algoDataset.budgetRatio },
			campaignBudget: parentData.data.campaignDetail.totalBudget,
			campaignDurationInDays: days + 1,
			classroomData: algoDataset.classroomData, //List of classrooms
			eventData: algoDataset.eventData, //List of events
			inventoryData: algoDataset.inventoryData, //List of inventories
			schoolData: algoDataset.schoolData, //List of schools
			noOfDaysInYear: algoDataset.noOfDaysInYear,
			percentageDiscountInWISR: algoDataset.percentageDiscountInWISR,
			percentageIncreaseInReach: algoDataset.percentageIncreaseInReach,
			totalInternalCostPerSchool: algoDataset.totalInternalCostPerSchool,
		});
		setWisrOption(wisrOption);
		let maxBudget = 0;
		wisrOption.$MaxBudget.subscribe((budget) => {
			maxBudget = budget;
			
			if (maxBudget < parentData.data.campaignDetail.totalBudget) {
				
				wisrOption.$SetBudget.next(maxBudget);
			}
			console.log("maxBudget", budget);
		});
		let selectedSchool = [];
		wisrOption.$MinReach.subscribe((minReach) => {
			
			console.log("minReach", minReach);
			setMinReach(minReach);
		});
		wisrOption.$MaxReach.subscribe((maxReach) => {
			
			console.log("maxReach", maxReach);
			setMaxReach(maxReach);
		});
		wisrOption.$MinImpressions.subscribe((minImpression) => {
		
			console.log("minImpression", minImpression);
			setMinImpression(minImpression);
		});
		wisrOption.$MaxImpressions.subscribe((maxImpression) => {
			
			console.log("maxImpression", maxImpression);
			setMaxImpression(maxImpression);
		});
		wisrOption.$MinBudget.subscribe((minBudget) => {
			
		});
		wisrOption.$MaxBudget.subscribe((maxBudget) => {
			
		});
		if (parentData.data.campaignDetail.wisrOption) {
			
			wisrOption.$IncreasedBudget.subscribe((increasedBudget) => {
				if (parentData.data.campaignDetail.totalBudget > maxBudget) {
					if (parentData.data.school.schools.length === 0) {
						setTotalBudget(maxBudget);
					}
					setIncreasedBudget(maxBudget);
					console.log("setting budget", maxBudget);
				} else {
					if (parentData.data.school.schools.length === 0) {
						setTotalBudget(increasedBudget);
					}
					setIncreasedBudget(increasedBudget);
					console.log("setting budget2", increasedBudget);
				}
			});
			wisrOption.$IncreasedImpressions.subscribe((increasedImpression) => {
				if (parentData.data.school.schools.length === 0) {
					setTotalImpression(increasedImpression);
				}
				console.log("settingImpression", increasedImpression);
			});
			wisrOption.$IncreasedReach.subscribe((IncreasedReach) => {
				if (parentData.data.school.schools.length === 0) {
					setTotalReach(IncreasedReach);
				}
				console.log("IncreasedReach", IncreasedReach);
			});
			wisrOption.$SchoolsForWisrOptions.subscribe((school) => {
				selectedSchool =
					parentData.data.school.schools.length === 0
						? [...school]
						: [...parentData.data.school.schools];
				let location = [];
				school.forEach((data) => {
					if (!location.includes(data.parentCity)) {
						location.push(data?.parentCity);
					}
				});
				if (parentData.data.school.schools.length === 0) {
					selectedSchool = [...school];
					setFormData({
						...parentData.data.school,
						schools: [...school],
						locations: [...location],
					});
					setInitialSchools([...school]);
				}
			});
		} else {
			wisrOption.$GetReach.subscribe((reach) => {
				if (parentData.data.school.schools.length === 0) {
					setTotalReach(reach);
				}
				console.log("GetReach", reach);
			});
			wisrOption.$GetBudget.subscribe((budget) => {
				if (parentData.data.school.schools.length === 0) {
					if (parentData.data.campaignDetail.totalBudget > maxBudget) {
						setTotalBudget(maxBudget);
					} else {
						setTotalBudget(budget);
					}
				}
			});
			wisrOption.$GetImpression.subscribe((impression) => {
				if (parentData.data.school.schools.length === 0) {
					setTotalImpression(impression);
				}
				console.log("GetImpression", impression);
			});
			wisrOption.$FilteredSchool.subscribe((school) => {
				selectedSchool =
					parentData.data.school.schools.length === 0
						? [...school]
						: [...parentData.data.school.schools];
				if (parentData.data.school.schools.length === 0) {
					let location = [];
					school.forEach((data) => {
						if (!location.includes(data.parentCity)) {
							location.push(data?.parentCity);
						}
					});
					selectedSchool = [...school];
					setFormData({
						...parentData.data.school,
						schools: [...school],
						locations: [...location],
					});
					setInitialSchools([...school]);
				}
			});
		}
		wisrOption.$OptimizedSchool.subscribe((school) => {
			let stateCityData = {},
				state = [];
			school.forEach((item) => {
				if (
					item.parentCity &&
					item.parentCity !== "" &&
					item.parentCity !== null
				) {
					if (stateCityData[item.state.toLowerCase()]) {
						if (
							!stateCityData[item.state.toLowerCase()].find(
								(data) => data.toLowerCase() === item?.parentCity?.toLowerCase()
							)
						) {
							stateCityData[item.state.toLowerCase()].push(
								item.parentCity.toLowerCase()
							);
						}
					} else {
						stateCityData[item.state.toLowerCase()] = [
							item.parentCity.toLowerCase(),
						];
					}
					if (!state.includes(item.state.toLowerCase())) {
						state.push(item.state.toLowerCase());
					}
				}
			});
			state = state.sort((a, b) => {
				return a.toLowerCase() > b.toLowerCase() ? 1 : -1;
			});
			console.log("stateFirst", state);
			setStateList([...state]);
			setCityStateData({ ...stateCityData });

			let sortedSchool = school.sort((a) => {
				let found = selectedSchool.find((item) => item._id === a._id);
				return found ? -1 : 1;
			});

			console.log("sortedSchools", sortedSchool);

			setCustomSchoolList([...sortedSchool]);
			let schoolData = [];
			for (let i = 0; i < dataPerPage && i < sortedSchool.length; i++) {
				schoolData.push(sortedSchool[i]);
				
			}
			
			let arr = [];
			
			setTotalPage([...arr]);
			setPaginationSchool([...schoolData]);
			if (loadingFirstTime) {
				setTotalSchoolCount(school.length);
			}
			console.log("OptimizedSchool", school);
		});
		setPageLoading(false);
		setLoadingFirstTime(false);

		// API starts

		let resp = await context.apis[
			context.user.role
		].getSchoolsForCustomCampaign(context.validator, { data: dataset });
		console.log("getSchoolsForCustomCampaign", resp);

		if (resp && resp.done) {
			setCustomSchoolList([...resp.response.rows]);
			let total = Math.ceil(resp.response.count / 25);
			let arr = [];
			for (let i = 0; i < total; i++) {
				arr.push(i);
			}
			setTotalPage([...arr]);
		}

		if (resp && resp.done) {
			let states = [],
				cityStateObj = {};
			resp.response.rows.forEach((school) => {
				if (!states.includes(school.state.toLowerCase())) {
					states.push(school.state.toLowerCase());
				}
				if (cityStateObj[school.state.toLowerCase()]) {
					if (
						!cityStateObj[school.state.toLowerCase()].includes(
							school.city.toLowerCase()
						)
					) {
						cityStateObj[school.state.toLowerCase()].push(
							school.city.toLowerCase()
						);
					}
				} else {
					cityStateObj[school.state.toLowerCase()] = [
						school.city.toLowerCase(),
					];
				}
			});
			
			setCityStateData({ ...cityStateObj });
			if (loadingFirstTime) {
				setLoadingFirstTime(false);
				setTotalSchoolCount(resp.response.count);
			}
		}
	};

	useEffect(() => {
		getAllSchools();
	}, []);

	
	useEffect(() => {
		let city = [];
		formData.schools.forEach((school) => {
			
			if (
				school.parentCity != "undefined" &&
				school.parentCity != "" &&
				school.parentCity != null
			) {
				city.push(school.parentCity);
			}
		});
		setPincodeList([...city]);
	}, [formData.schools]);

	const onSave = async (confirm, budgetChecked) => {
		if (confirm) {
			if (formData.schools && formData.schools.length === 0) {
				setShowErrorMessage(true);
				return;
			} else if (formData.schools.length > 0) {
				setShowErrorMessage(false);
			}

			let usedEvent = [];
			formData.schools.forEach((item) => {
				item.events.forEach((data) => {
					if (!data._custom) {
						if (!usedEvent.includes(data.name)) {
							usedEvent.push(data.name);
						}
					} else {
						if (!usedEvent.includes(data.parentName)) {
							usedEvent.push(data.parentName);
						}
					}
				});
			});
			usedEvent = parentData.data.eventAndOccation.eventAndOccations.filter(
				(event) => {
					let found = usedEvent.find(
						(item) => item === event.parentName || item === event.name
					);
					// console.log(found)
					if (found) {
						return true;
					} else {
						return false;
					}
				}
			);
			console.log("usedEvent", usedEvent);
			usedEvent = usedEvent.filter(
				(item, index) =>
					index === usedEvent.findIndex((data) => data.name === item.name)
			);
			console.log("usedEvent1", usedEvent);

			let usedInventories = [];
			formData.schools.forEach((item) => {
				item.inventories.forEach((data) => {
					if (!data._custom) {
						if (!usedInventories.includes(data.name)) {
							usedInventories.push(data.name);
						}
					} else {
						if (!usedInventories.includes(data.parentName)) {
							usedInventories.push(data.parentName);
						}
					}
				});
			});
			usedInventories = parentData.data.inventory.inventories.filter(
				(inventory) => {
					let found = usedInventories.find(
						(item) => item === inventory.parentName || item === inventory.name
					);
					// console.log(found)
					if (found) {
						return true;
					} else {
						return false;
					}
				}
			);
			// usedInventories = usedInventories.filter((item, index) => index === usedInventories.findIndex(data => data.parentName === item.parentName || data.name === item.name))
			console.log("usedinventories", usedInventories);
			if (parentData) {
				if (parentData.setProgress) {
					parentData.setProgress({
						...parentData.progress,
						// recommendedSchool: true,
						customComments: true,
					});
				}
				if (parentData.setData) {
					parentData.setData({
						...parentData.data,
						school: { ...formData },
						campaignDetail: {
							...parentData.data.campaignDetail,
							totalBudget: totalBudget,
							totalReach: totalReach,
							totalImpression: totalImpression,
							girlsRatio: girlsRatio,
							boysRatio: boysRatio,
						},
						inventory: {
							...parentData.data.inventory,
							usedInventories: [...usedInventories],
						},
						eventAndOccation: {
							...parentData.data.eventAndOccation,
							eventAndOccations: [
								...parentData.data.eventAndOccation.eventAndOccations,
							],
							usedEvents: [...usedEvent],
						},
					});
				}
			}

			

			if (parentData.data.brandDetail.custom && parentData.data.brandDetail.isTrial) {
				history.push("/brands/brand-details/create-trial-campaign/preview");
			} else {
				history.push("/brands/brand-details/create-campaign/preview");
			}

			
		}
	};
	const handleSearch = async (e) => {
		if (e === "") {
			setSearchValue("");
		} else {
			setSearchValue(e.target.value.trim());
		}
	};
	const openFilter = () => {
		setShowBoardFilter(false);
		setShowCityFilter(false);
		setShowSchoolTypeFilter(false);
		setShowLanguageFilter(false);
		setShowFilterModal(!showFilterModal);
	};
	const showFilterDropDown = (type) => {
		if (type === "state") {
			setShowStateFilter(!showStateFilter);
			setShowBoardFilter(false);
			setShowLanguageFilter(false);
			setShowSchoolTypeFilter(false);
		}
		if (type === "city") {
			setShowBoardFilter(false);
			setShowLanguageFilter(false);
			setShowSchoolTypeFilter(false);
			setShowCityFilter(!showCityFilter);
		} else if (type === "board") {
			setShowStateFilter(false);
			setShowBoardFilter(!showBoardFilter);
			setShowLanguageFilter(false);
			setShowSchoolTypeFilter(false);
			setShowCityFilter(false);
		} else if (type === "language") {
			setShowStateFilter(false);
			setShowBoardFilter(false);
			setShowLanguageFilter(!showLanguageFilter);
			setShowSchoolTypeFilter(false);
			setShowCityFilter(false);
		} else if (type === "schoolType") {
			setShowStateFilter(false);
			setShowBoardFilter(false);
			setShowLanguageFilter(false);
			setShowSchoolTypeFilter(!showSchoolTypeFilter);
			setShowCityFilter(false);
		}
	};
	const clearAllFilter = () => {
		setFilterBySchoolType([]);
		setFilterByBoard([]);
		setFilterByCity([...parentData.data.brandDetail.city]);
		setFilterByLanguage([]);
		setSelectedState([...parentData.data.brandDetail.state]);
		setShowStateFilter(false);
		setShowFilterModal(false);
		setShowBoardFilter(false);
		setShowLanguageFilter(false);
		setShowSchoolTypeFilter(false);
		setShowCityFilter(false);
	};
	const onExit = async (confirm) => {
		if (confirm) {
			setConfirmDialogBtnLoading(true);
			let apiData = formateData();
			console.log(apiData);
			if (apiData["_id"]) {
				console.log(apiData);
				let resp = await context.apis[context.user.role].editCampaign(
					context.validator,
					apiData
				);
				console.log("resp---", resp);
				if (resp && resp.done) {
					setConfirmDialogBtnLoading(false);
					history.push({
						pathname: "/brands/brand-details/campaigns",
						state: { campaignCreated: true },
					});
				}
			} else {
				let resp = await context.apis[context.user.role].addCampaign(
					context.validator,
					apiData
				);
				console.log("resp---", resp);
				if (resp && resp.done) {
					setConfirmDialogBtnLoading(false);
					history.push({
						pathname: "/brands/brand-details/campaigns",
						state: { campaignCreated: true },
					});
				}
			}
		}
		setShowExitPopup(false);
		setOverlay(false);
	};
	const openExit = () => {
		setShowExitPopup(true);
		setOverlay(true);
	};
	const formateData = () => {
		let inventoriesIdArray = [];
		parentData.data.inventory.inventories.forEach((inventory) => {
			let attributesIdArray = [];
			inventory.placements &&
				Array.isArray(inventory.placements) &&
				inventory.placements.length > 0 &&
				inventory.placements.map((item) => {
					attributesIdArray.push(item.name);
				});
			let obj = {
				_id: inventory._id,
				name: inventory.parentName || inventory.name,
				attributes: [...attributesIdArray],
				_custom: inventory._custom || false,
			};
			inventoriesIdArray.push(obj);
		});
		let eventIdArray = [];
		parentData.data.eventAndOccation.eventAndOccations.forEach((event) => {
			let attributesIdArray = [];
			event.placements &&
				Array.isArray(event.placements) &&
				event.placements.map((item) => {
					attributesIdArray.push(item._id || item.name);
				});
			let obj = {
				_id: event._id,
				name: event.name,
				attributes: [...attributesIdArray],
			};
			eventIdArray.push(obj);
		});
		let imageArray = [];
		if (parentData.data.campaignCreative.creatives.image1.key) {
			imageArray.push(parentData.data.campaignCreative.creatives.image1.key);
		}
		if (parentData.data.campaignCreative.creatives.image2.key) {
			imageArray.push(parentData.data.campaignCreative.creatives.image2.key);
		}
		if (parentData.data.campaignCreative.creatives.image3.key) {
			imageArray.push(parentData.data.campaignCreative.creatives.image3.key);
		}
		let obj = {
			brandId: parentData.data.brandDetail.brandId,
			name: parentData.data.brandDetail.name.trim(),
			description: parentData.data.brandDetail.description.trim(),
			objective: parentData.data.brandDetail.objective.trim(),
			startDate: parentData.data.brandDetail.startDate,
			endDate: parentData.data.brandDetail.endDate,
			locations: [],
			targetGender:
				parentData.data.brandDetail.targetGender === "both"
					? "co-ed"
					: parentData.data.brandDetail.targetGender,
			targetAgeGroup: [
				parentData.data.brandDetail.targetAgeGroup.min,
				parentData.data.brandDetail.targetAgeGroup.max,
			],
			studyMedium: [...parentData.data.brandDetail.studyMedium],
			states: [...parentData.data.brandDetail.state],
			cities: [...parentData.data.brandDetail.city],
			brandCategory: parentData.data.brandDetail.brandCategory._id,
			brandSubcategory: parentData.data.brandDetail.brandSubcategory.value,
			brandName: parentData.data.brandDetail.brandName,
			recommendEvents: false,
			campaignStatus: "draft",
			isApproved: false,
			campaignCreatives: [...imageArray],
			budget: totalBudget,
			isTrial: parentData.data.brandDetail.isTrial,
			wisrOffering: {
				inventory: parentData.data.brandDetail.inventory,
				eventAndOccations: parentData.data.brandDetail.eventAndOccations,
				csr: parentData.data.brandDetail.csr,
				sampling: parentData.data.brandDetail.sampling,
				custom: parentData.data.brandDetail.custom,
			},
			campaignCreationProgress: {
				progress: {
					...parentData.progress,
					customComments: true,
				},
				currentPage: "customComments",
			},
			campaignData: {
				inventories: [...inventoriesIdArray],
				schools: [],
				activities: [...eventIdArray],
				schoolListDetail: [],
				// customData: {
				// 	schools: [
				// 		{
				// 			_id: 1,
				// 			schoolType: "WisrTest",
				// 			isRecommend: "True",
				// 		},
				// 	],
				// 	price: 1,
				// 	purpose: "TEsting only",
				// },
			},
		};
		if (parentData.data.brandDetail._id) {
			obj["_id"] = parentData.data.brandDetail._id;
		}
		return obj;
	};
	useEffect(() => {
		if (formData.schools && formData.schools.length > 0) {
			setShowErrorMessage(false);
		}
	}, [formData?.schools]);

	const [formValidator] = useState(
		useRef(
			new SimpleReactValidator({
				element: (message) => (
					<span className="valError">
						<img src={errorSVG} />
						{message}
					</span>
				),
			})
		)
	);

	const onFormDataChange = (event) => {
		setReason({ ...reason, [event.target.id]: event.target.value });
	
	};

	

	return (
		<>
			<div className={`ovelap ${overlay === true && "active"}`}></div>

			<div className="col-xl-9 col-lg-12 col-md-12 col-xs-12">
				<div className="rightPart noBack">
					<div className="row">
						<div className="col-xs-12 col-lg-12 col-md-12 col-xs-12">
							<BrandBackBtn />
						</div>
						<div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-20">
							<div className="row">
								<div
									className="col-xl-6 col-lg-6 col-md-6 col-xs-12"
									style={{ padding: "0px" }}
								>
									<h2 className="htx5 clearfix mt-10 mb-20">
										Create a Campaign
									</h2>
								</div>
							</div>
						</div>
						<div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-10">
							<h2 className="htx2 clearfix mb-10">
								{/* Please comment in below box, if any. */}
								Share a custom brief / Share details of a customized Event
							</h2>
						</div>
						<div className="col-xl-8 col-lg-8 col-md-12 col-xs-12 mb-20">
							<div className="row">
								<div className="col-xl-6 col-lg-9 col-md-9 col-xs-12 mb-10 pr">
									
								</div>

								{/* Put comment box here */}
								<div
									className="forminpt tooltip-relative"
									style={{ marginBottom: "0px !important" }}
								>
									<TextArea
										formData={reason}
										formKey="reason"
										onDataChange={(event) => {
											setReason((prevFormData) => ({
												...prevFormData,
												reason: event.target.value,
											}));
											parentData.setData({
												...parentData.data,
												campaignData: {
													...parentData?.data?.campaigndata,
													reason: event.target.value,
												},
											});
										}}
										
										placeholder="Enter Reason"
										isRequired={false}
										formValidator={formValidator}
										validation="required"
										maxLength={5000}
									/>
									
								</div>

								<div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 pl pr"></div>
								<div className="col-xl-5 col-lg-5 col-md-6 col-xs-12 delete-user mt-30">
									<a
										className="viewwLink"
										style={{ cursor: "pointer" }}
										onClick={openExit}
									>
										Save & Continue Later
									</a>
								</div>
								<div className="col-xl-7 col-lg-7 col-md-6 col-xs-12 end-md mt-30">
									<div className="forminpt">
										<input
											name="Save &amp; next"
											className="btn btn-orange"
											type="button"
											value="Save &amp; next"
											onClick={() => onSave(true, false)}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<ConfirmDialog
				togglePopup={showExitPopup}
				onModalClose={onExit}
				componentName={""}
				confirmTitle="Do you want to save and exit?"
				isLoading={confirmDialogBtnLoading}
			/>
		</>
	);
};

export default CustomComments;
