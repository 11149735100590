const AlertDialog = (props) => {
    const {
        onModalClose,
        togglePopup,
        displayMsg,
        showBtn,
        title,
        buttonText
    } = props



    return (
        <div id="closePopup" className={`popup popup-center ${togglePopup === true ? "" : "hide"}`}>
            <div className="popup-header">
            {title && title !== "" && <h3 className="htx2">{title}</h3>}
                <span onClick={() => onModalClose()} className="close toggle" data-target="closePopup">close</span>
            </div>
            <div className="popup-body">
                <div className="facili-avai-in clearfix">
                    <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <h3 className="htx2">{displayMsg}</h3>
                            </div>
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-20 end-xs">
                                <div className="forminpt">
                            {
                                showBtn && <a href="javascript:void(0);" className="btn btn-orange ml" onClick={() => onModalClose(true)}>{buttonText && buttonText !== "" ? buttonText : "OK"}</a>
                            }
                            </div>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AlertDialog