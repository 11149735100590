import { useState, useContext, useEffect } from "react"

import { Link, useHistory } from "react-router-dom"
import errorSVG from "../../../../assets/images/error.svg";
import search from "../../../assets/images/search.svg"
import arrowUpDown from "../../../assets/images/backendAdmin/arrow-up-down.svg"
import riarrownext from "../../../assets/images/right-arrow-next.svg"
import imgViewTwo from "../../../../assets/images/backendAdmin/img-view-two.jpg"
import tabImgInventory from "../../../assets/images/tab-img-inventory.png"
import tableFilter from "../../../../assets/images/table-filter.svg"
import arrowUp from "../../../assets/images/arrow-up.svg"
import filterSvg from "../../../assets/images/filter-svg.svg"
import filterArrowSvg from "../../../assets/images/filter-arrow-svg.svg"
import closeCustome from "../../../../assets/images/close-1.svg";

import tabSlide1 from "../../../assets/images/tab-Slide1.png"
import TopBar from "../../schools/components/topBar"
import AppContextObject from "../../../../common/context/common"

import OnboardingBackBtn from "../../schools/components/onboardingBackBtn"
import InventoryCard from "../../schools/updateDetails/inventory/inventoryCard"
import Pagination from "../../../../components/addon/pagination"
import CampaignApprovalModal from "../../../../components/addon/campaignApprovalModal"
import ThankyouModal from "../../../../components/addon/thankyouModal"
import RejectBrandRequestModal from "../../../../components/addon/rejectBrandRequestModal"
import backBtn from "../../../../assets/images/back-arrow.svg";
import DataContext from "../../../../service/contextProvider"

import { PublicContextInitiator } from "../../../apis/publicContextInitiator"

import { Steps } from "intro.js-react"
import { formatDateToDDMMMYY, isObjectEmpty } from "../../../../service/helper"

import toast from "react-hot-toast"
import BrandCampaignsTable from "../components/BrandCampaignsTable"
import SearchInput from "../components/SearchInput";
import SingleSelect from "../../../../components/formFields/singleSelect/singleSelect"
import config from "../../../../service/config";
import CustomDatePicker from "../../../../components/formFields/datePicker"
import moment from "moment"
import BackendBackBtn from "../components/backendBackBtn";

const CampaignsReject = () => {
    const parentData = useContext<any>(DataContext)
    const history = useHistory()
    const context = useContext(AppContextObject)
    const publicContextInitiator = new PublicContextInitiator(context)

    const [showModalApproveActive, setshowModalApproveActive] = useState(false)
    const [showThankyouModal, setShowThankyouModal] = useState(false)
    const [thankyouTitle, setThankyouTitle] = useState("")
    const [showConfirmRejectModal, setShowConfirmRejectModal] = useState(false)
    const [thankyouDescription, setThankyouDescription] = useState("")

    const [inventoryList, setInventoryList] = useState([])
    const [searchValue, setSearchValue] = useState("")
    const [sortingValue, setSortingValue] = useState(true)
    const [refetch, setRefetch] = useState(false)
    const [paginationCount, setPaginationCount] = useState([])
    const [activeStatusName, setActiveStatusName] = useState("")
    const [pageLimit, setPageLimit] = useState(10)
    const [showModal, setShowModal] = useState(false)
    const [showModalapproveActive, setshowModalapproveActive] = useState(false)
    const [overlay, setOverlay] = useState(false)
    const [openFisrtToBeAdded, setOpenFisrtToBeAdded] = useState(false)
    const [searchInpuOpen, setSearchInpuOpen] = useState(false);
    const [clearSearchIcon, setClearSearchIcon] = useState(false);
    const [searchKeys, setSearchKeys] = useState("");
    const [countOfStatus, setCountOfStatus] = useState({
        all: 0,
        occupied: 0,
        available: 0,
        toBeAdded: 0,
        event: 0
    })

    const [brandRequest, setBrandRequest] = useState<any>({})

    const [activeCard, setActiveCard] = useState<any>("")
    const [currentPage, setCurrentpage] = useState(1);
    const [fetchAllCounts, setFetchAllCounts] = useState(false)
    const [requestedInventoryName, setRequestedInventoryName] = useState("")
    const [isInventoryListLoading, setIsInventoryListLoading] = useState(false)
    const [toolTip1Completed, setToolTip1Completed] = useState(false);
    const [addButtonToolTip, setAddButtonToolTip] = useState(false);
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({ dateFilter: "" });
    const [formSheduledData, setFormSheduledData] = useState({
        startSheduledDate: "",
        endSheduledDate: ""
    })
    const [sheduledFilterErr, setSheduleddFilterErr] = useState({
        startDate: false,
        endDate: false
    })
    const [customSheduledFilter, setCustomSheduledFilter] = useState(false);
    const [campaigns, setCampaigns] = useState([]);
    const [customDateErr, setCustomDateErr] = useState(false)
    useEffect(() => {
        if (formSheduledData.startSheduledDate !== '' && formSheduledData.endSheduledDate !== '' && formData.dateFilter === 'custom') {
            getCampaigns()
        } else if (formData.dateFilter !== 'custom' || searchKeys) {
            getCampaigns()
        }
    }, [formData, searchKeys, formSheduledData])
    const getCampaigns = async () => {
        setLoading(true);
        let publicContextInitiator = new PublicContextInitiator(context)
        let resp = await publicContextInitiator.getCampaignsByBrandExternal(
            context.validator, { brandId: history.location.state['brandId'], campaignType: history.location.state['type'], dateFilter: formData.dateFilter, searchValue: searchKeys, startDate: formSheduledData }, {}
        )
        console.log('resp======>', resp)

        if (resp && resp.done) {
            setLoading(false);
            setCampaigns(resp.response.rows)
        }
    }
    const onCategoryChange = (data) => {
        // console.log(data);

        if (data.value === 'custom') {
            setCustomSheduledFilter(true);
            setCustomDateErr(true);
        } else {
            setCustomSheduledFilter(false);
            setFormSheduledData({ startSheduledDate: "", endSheduledDate: "" });
            setCustomDateErr(false);
        }
        setFormData({ dateFilter: data.value });

    }

    console.log("working", formSheduledData);

    return (
        <>
            <div className={`ovelap ${overlay === true ? "active" : ""}`}></div>
            <div className="col-xl-10 col-lg-12 col-md-12 col-xs-12">

                <div className="rightPart noBack">

                    <div className="row">
                        <div className="col-xs-12 col-lg-12 col-md-12 col-xs-12">
                            <BackendBackBtn />
                        </div>
                        <div className="col-xs-12 col-lg-12 col-md-12 col-xs-12">
                            <Link to="/backend/backend-details/brand-list" className="backbtn dblock">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-10">
                                    <img src={backBtn} />
                                    <a className="backbtn dblock" href="javascript:void(0);">{history.location.state['type'] === "create" ? 'Created' : 'Trial'} Campaigns</a>
                                </div>
                            </Link>
                        </div>


                        <div className="col-xl-5 col-lg-5 col-md-12 col-xs-12 mt-30"><h2 className="htx3 clearfix mt-10">Total Campaigns: {campaigns.length}</h2></div>
                        <div className="col-xl-7 col-lg-7 col-md-12 col-xs-12 mt-30 mb-10">
                            <div className="inventorySearch schoolFilter inventoryBackAdmin">
                                {/* <a href="javascript:void(0)" className="btn">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"> <circle cx="8.58478" cy="8.58478" r="7.58478" stroke="#070707" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M13.8599 14.254L16.8335 17.22" stroke="#070707" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                                </a> */}
                                <SearchInput
                                    searchInpuOpen={searchInpuOpen} //true/false
                                    clearSearchIcon={clearSearchIcon} //true/false
                                    setSearchInpuOpen={setSearchInpuOpen} //function=>true/false
                                    setSearchKeys={setSearchKeys} //function=>string
                                    searchKeys={searchKeys} //string
                                    setClearSearchIcon={setClearSearchIcon}//function=>true/false
                                    palaceholder='Campaign name'
                                    setCurrentpage={setCurrentpage}
                                />
                                <div className="forminpt-dateFilter">
                                    <SingleSelect
                                        formData={formData}
                                        formKey='dateFilter'
                                        onDataChange={onCategoryChange}
                                        placeholder='Schedule date'
                                        isRequired={false}
                                        options={config.monthFilterSheduleDate}
                                    />
                                    {customDateErr && (formSheduledData.startSheduledDate === "" || formSheduledData.endSheduledDate === "") &&
                                        <p className="valError">
                                            <img src={errorSVG} />
                                            {'Date required.'}
                                        </p>
                                    }
                                </div>
                                {customSheduledFilter && <div className={`filterDropDown r-auto tableFilter custom-w open`}>
                                    <div className="filterDropBody">
                                        <div className="filterDropHead">
                                            <a href="javascript:void(0);" className="viewwLink" onClick={() => setCustomSheduledFilter(false)} ><img src={closeCustome} /></a>
                                        </div>
                                        <div className="filterDropClose open">
                                            <div className="filtercheckbox">
                                                <CustomDatePicker
                                                    formData={formSheduledData}
                                                    formKey='startSheduledDate'
                                                    onDataChange={(e) => { setFormSheduledData({ ...formSheduledData, startSheduledDate: e.target.value }); }}
                                                    isRequired={false}
                                                // formValidator={formValidator}
                                                // validation='required'
                                                />
                                                {/* {sheduledFilterErr.startDate && <span className="valError"><img src={errorSVG} />{sheduledFilterErrMsg.startDateMsg}</span>} */}
                                                {/* </div> */}
                                                <CustomDatePicker
                                                    formData={formSheduledData}
                                                    formKey='endSheduledDate'
                                                    onDataChange={(e) => { setFormSheduledData({ ...formSheduledData, endSheduledDate: e.target.value }); }}
                                                    isRequired={false}
                                                    // formValidator={formValidator}
                                                    // validation='required'
                                                    min={(formSheduledData.startSheduledDate && moment(formSheduledData.startSheduledDate).add(1, "days").clone().format("YYYY-MM-DD")) || moment().add(11, "days").clone().format("YYYY-MM-DD")}
                                                />
                                                {/* {sheduledFilterErr.endDate && <span className="valError"><img src={errorSVG} />{sheduledFilterErrMsg.endDateMsg}</span>} */}
                                                <div className="filterDropHead-campaign">
                                                    <a href="javascript:void(0);" className="viewwLink" onClick={() => { setFormSheduledData({ startSheduledDate: "", endSheduledDate: "" }); }} >Clear</a>
                                                </div>
                                                {/* {formSheduledData.startSheduledDate!=='' && formSheduledData.endSheduledDate!=='' && <div className="filterDropHead-campaign">
                                                            <a href="javascript:void(0);" className="viewwLink" onClick={() => setScheduleSearch(true) } >Search</a>
                                                        </div>} */}

                                            </div>
                                        </div>
                                    </div>
                                </div>}
                                {/* <select><option>This Month</option></select> */}
                            </div>
                        </div>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                            <div className="inventory-tableTabs">
                                <BrandCampaignsTable
                                    campaigns={campaigns}
                                    campaignType={history.location.state['type']}
                                    loading={loading}
                                />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div id="approveActive" className={`popup popup-center ${showModalapproveActive === false ? "hide" : ""}`}>
                <div className="popup-header">
                    <h3 className="htx2 pophead">Request Sent Successfully!</h3>
                    <span className="close toggle" data-target="myPopup">close</span> </div>
                <div className="popup-body">
                    <div className="viewdetailsHead">
                        <div className="approveActive">
                            <p>A request to add {requestedInventoryName} has been sent to your school's admin. </p>
                            <div className="brand-btn">
                                <a href="javascript:void(0)" className="btn btn-orange">DONE</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <Steps
                enabled={onBoarding.stepsEnabled}
                steps={onBoarding.steps}
                initialStep={onBoarding.initialStep}
                onComplete={hanldeOnboardingComplete}
                onExit={exitOnBoarding}
                options={onBoarding.options}
            /> */}

            <CampaignApprovalModal
                showModalApproveActive={showModalApproveActive}
            // closeWithoutSaving={closeApprovalModal}
            // viewDetails={viewBrandDetails}
            // approveRequest={approveRequest}
            />

            <ThankyouModal
                showThankyouModal={showThankyouModal}
                // closeModal={closeThankyouModal}
                modalTitle={thankyouTitle}
                description={thankyouDescription}
            />

            <RejectBrandRequestModal
                showConfirmRejectModal={showConfirmRejectModal}
            // closeModal={closeConfirmRejectModal}
            // onRejectRequest={onRejectRequest}
            />

        </>
    )
}


export default CampaignsReject
