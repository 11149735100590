import React, { useEffect, useState, useContext, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import uploadSvg from "../../../../../assets/images/upload-svg.svg"
import sucessCheck from "../../../../../assets/images/sucess-check.svg"
import closeSvg from "../../../../../assets/images/close-svg.svg"
import blockSvg from "../../../../../assets/images/block-svg.svg"
import reloadSvg from "../../../../../assets/images/reload-svg.svg"
import AppContextObject from "../../../../../common/context/common"
import DataContext from "../../../../../service/backendContext"
import Compress from "compress.js"
import { PublicContextInitiator } from '../../../../apis/publicContextInitiator'
import ImageModal from '../../../../../components/addon/imageModal/imageModal'

const CampaignCreative = ({setShowProgressSideMenu}) => {
    const compress = new Compress()
    const creative1Ref = useRef()
    const creative2Ref = useRef()
    const creative3Ref = useRef()
    const history = useHistory()
    const parentData = useContext<any>(DataContext)
    const context = useContext(AppContextObject)
    const [formData, setFormData] = useState(parentData?.data?.campaignCreative?.creatives)
    const [image1Rejected, setImage1Rejected] = useState(false)
    const [image2Rejected, setImage2Rejected] = useState(false)
    const [image3Rejected, setImage3Rejected] = useState(false)
    const [loading, setLoading] = useState(false)
    const [toggleShowImage1, setToggleShowImage1] = useState(false)
    const [toggleShowImage2, setToggleShowImage2] = useState(false)
    const [toggleShowImage3, setToggleShowImage3] = useState(false)
    useEffect(() => {
        setShowProgressSideMenu(true)
        // if (parentData.setActiveModule) {
        //     parentData.setActiveModule({
        //         ...parentData.activeModule,
        //         module: "creative",
        //     })
        // }
    }, [])
    // const handleCreativeUpload = async(e, type) => {
    //     if(e.target.files && e.target.files.length > 0){
    //         setLoading(true)
    //         const formData1 = new FormData();
    //         const resizedImage = await compress.compress([e.target.files[0]], {
    //             size: 2, // the max size in MB, defaults to 2MB
    //             quality: 0.4, // the quality of the image, max is 1,
    //             resize: false // defaults to true, set false if you do not want to resize the image width and height
    //         }).catch(err => {
    //             console.log("Error while compressing....", err)
    //         })
    //         const img = resizedImage[0];
    //         const base64str = img.data
    //         const imgExt = img.ext
    //         let compressedFile = Compress.convertBase64ToFile(base64str, imgExt)
    //         formData1.append(`image`,compressedFile, img.alt)

    //         // formData1.append(`image`, e.target.files[0]);
    //         const requestOptions = {
    //             method: 'POST',
    //             body: formData1
    //         };
        
    //         let publicContextInitiator = new PublicContextInitiator(context)
    //         fetch('/api/uploadFil', requestOptions)
    //             .then(response => {
    //                 response.json()
    //                 .then(res=>{
    //                     let data = [];
    //                     res["response"].map(async item=>{
    //                         data.push(item.Key)
    //                     })
    //                     publicContextInitiator.getUploadedImages(
    //                         context.validator, {keys: data}
    //                     ).then(res=>{
    //                         let updateImageObj = []
    //                             let requests = res.response.map(item=>{
    //                             updateImageObj.push({image: item.image, key: item.key});
    //                             console.log(updateImageObj)
    //                             if(type === 1){
    //                                 console.log("setImage1", updateImageObj)
    //                                 setFormData({...formData, image1: updateImageObj[0]})
    //                                 setImage1Rejected(false)
    //                             }
    //                             else if(type === 2){
    //                                 setFormData({...formData, image2: updateImageObj[0]})
    //                                 setImage2Rejected(false)
    //                             }
    //                             else if(type === 3){
    //                                 setFormData({...formData, image3: updateImageObj[0]})
    //                                 setImage3Rejected(false)
    //                             }
    //                         })
    //                         Promise.all(requests).then(() => {
    //                             setLoading(false)
    //                         });                       
    //                     })
    //                 })
    //                 .catch(error => {
    //                     if(type === 1){
    //                         setImage1Rejected(true)
    //                         const dt = new DataTransfer()
    //                         e.target.files = dt.files
    //                     }
    //                     else if(type === 2){
    //                         setImage2Rejected(true)
    //                         const dt = new DataTransfer()
    //                         e.target.files = dt.files
    //                     }
    //                     else if(type === 3){
    //                         setImage3Rejected(true)
    //                         const dt = new DataTransfer()
    //                         e.target.files = dt.files
    //                     }
    //                 })
    //             })
    //             .catch(error => {
    //                 if(type === 1){
    //                     setImage1Rejected(true)
    //                     const dt = new DataTransfer()
    //                     e.target.files = dt.files
    //                 }
    //                 else if(type === 2){
    //                     setImage2Rejected(true)
    //                     const dt = new DataTransfer()
    //                     e.target.files = dt.files
    //                 }
    //                 else if(type === 3){
    //                     setImage3Rejected(true)
    //                     const dt = new DataTransfer()
    //                     e.target.files = dt.files
    //                 }
    //             })
    //             setLoading(false)
    //     }
    // }
    // const handleClick = async(e, type) => {
    //     if(type === 1){
    //         let ref:any = creative1Ref
    //         ref.current.click()
    //     }
    //     else if(type === 2){
    //         let ref:any = creative2Ref
    //         ref.current.click()
    //     }
    //     else if(type === 3){
    //         let ref:any = creative3Ref
    //         ref.current.click()
    //     }
    // }
    // const confirmClose = (type) => {
    //     if(type === 1){
    //         setToggleShowImage1(false)
    //     }
    //     else if(type === 2){
    //         setToggleShowImage2(false)
    //     }
    //     else if(type === 3){
    //         setToggleShowImage3(false)
    //     }
    // }
    // const openImageModal = (type) => {
    //     if(type === 1){
    //         setToggleShowImage1(true)
    //     }
    //     else if(type === 2){
    //         setToggleShowImage2(true)
    //     }
    //     else if(type === 3){
    //         setToggleShowImage3(true)
    //     }
    // }
    // const onSave = async(confirm) => {
    //     if(confirm){
    //         if (parentData) {
    //             if (parentData.setProgress) {
    //                 parentData.setProgress({
    //                     ...parentData.progress,
    //                     campaignCreative: true,
    //                 })
    //             }
    //             if(parentData.setData){
    //                 parentData.setData({
    //                     ...parentData.data,
    //                     campaignCreative: {
    //                         ...parentData.data.campaignCreative, 
    //                         creatives: {...formData}
    //                     }
    //                 })
    //             }
    //         }
    //         history.push("/brands/brand-details/create-campaign/preview")
    //     }
    //     else{

    //     }
    // }
    return (
        <>
            <div className={`ovelap`}></div>
            {/* <TopBar isDashboard={true} /> */}
            <div className="col-xl-9 col-lg-9 col-md-12 col-xs-12">
            <div className="rightPart noBack">
                <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-20"><h2 className="htx1 clearfix mt-10 mb-10">Create a Campaign</h2></div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-30">
                    <h2 className="htx2 clearfix mb-10">Upload Campaign Creatives</h2>
                    <p className="ptx1">Upload your key campaign visuals in 3 different sizes. These will then be sent to the schools for a review.</p>
                </div>
                   <div className="col-xl-8 col-lg-8 col-md-12 col-xs-12 mb-20">
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 pl pr">
                                <h2 className="htx3 clearfix">Total No of Creatives: 3</h2>
                            </div>
                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-20 pl pr">
                                <div className="table-container mt-10 addAdmininput">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">sr no</th>
                                            <th scope="col">Type of Creative</th>
                                            <th scope="col">Dimensions</th>
                                            <th scope="col">REFERENCE CREATIVE</th>
                                            <th scope="col">ACTIONS</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>1:1</td>
                                            <td>500px X 500px</td>
                                            <td><a style={{cursor: "pointer"}} className="view-anchor text-decoration" 
                                            // onClick={() => openImageModal(1)}
                                            >View</a></td>
                                            <td>{image1Rejected ? <><a style={{cursor: "pointer"}} className="uploadSvg"><img src={blockSvg} /></a><a style={{cursor: "pointer"}} className="uploadSvg" 
                                            // onClick={(e) => handleClick(e, 1)}
                                            ><img src={reloadSvg} /></a></> : <a style={{cursor: "pointer"}} className="uploadSvg" 
                                            // onClick={(e) => handleClick(e, 1)}
                                            ><img src={uploadSvg} />
                                            {
                                            // formData.image1 && formData.image1.image 
                                            // && 
                                            <img src={sucessCheck} />
                                            }
                                            </a>}</td>
                                            
                                        </tr>
                                        <tr>
                                            <td>2</td>
                                            <td>1:2</td>
                                            <td>1200px X 1000 px</td>
                                            <td><a style={{cursor: "pointer"}} className="view-anchor text-decoration" 
                                            // onClick={() => openImageModal(2)}
                                            >View</a></td>
                                            <td>{image2Rejected ? <><a style={{cursor: "pointer"}} className="uploadSvg"><img src={blockSvg} /></a><a style={{cursor: "pointer"}} className="uploadSvg" 
                                            // onClick={(e) => handleClick(e, 2)}
                                            ><img src={reloadSvg} /></a></> : <a style={{cursor: "pointer"}} className="uploadSvg" 
                                            // onClick={(e) => handleClick(e, 2)}
                                            ><img src={uploadSvg} />{
                                            // formData.image2 && formData.image2.image 
                                            // && 
                                            <img src={sucessCheck} />
                                            }</a>}</td>
                                            
                                        </tr>
                                        <tr>
                                            <td>3</td>
                                            <td>2:1</td>
                                            <td>1200px X 1200px</td>
                                            <td><a style={{cursor: "pointer"}} className="view-anchor text-decoration" 
                                            // onClick={() => openImageModal(1)}
                                            >View</a></td>
                                            <td>{image3Rejected ? <><a style={{cursor: "pointer"}} className="uploadSvg"><img src={blockSvg} /></a><a style={{cursor: "pointer"}} className="uploadSvg" 
                                            // onClick={(e) => handleClick(e, 3)}
                                            ><img src={reloadSvg} /></a></> : <a style={{cursor: "pointer"}} className="uploadSvg" 
                                            // onClick={(e) => handleClick(e, 3)}
                                            ><img src={uploadSvg}/>{
                                            // formData.image3 && formData.image3.image && 
                                            <img src={sucessCheck} />}</a>}</td>
                                            
                                        </tr>
                                    </tbody>
                                </table>
                                {/* <ImageModal 
                                    togglePopup={toggleShowImage1}
                                    displayImage={formData.image1 && formData.image1.image}
                                    // onModalClose={() => confirmClose(1)}
                                />
                                <ImageModal 
                                    togglePopup={toggleShowImage2}
                                    displayImage={formData.image2 && formData.image2.image}
                                    // onModalClose={() => confirmClose(2)}
                                />
                                <ImageModal 
                                    togglePopup={toggleShowImage3}
                                    displayImage={formData.image3 && formData.image3.image}
                                    // onModalClose={() => confirmClose(3)}
                                /> */}
                                <input type='file' accept='image/*' 
                                // onChange={(e) => handleCreativeUpload(e, 1)} 
                                ref={creative1Ref}/>
                                <input type='file' accept='image/*' 
                                // onChange={(e) => handleCreativeUpload(e, 2)} 
                                ref={creative2Ref}/>
                                <input type='file' accept='image/*' 
                                // onChange={(e) => handleCreativeUpload(e, 3)} 
                                ref={creative3Ref}/>
                                </div>
                            </div>
                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 pl pr mt-20"></div>
                            <div className="col-xl-5 col-lg-5 col-md-12 col-xs-12 delete-user mt-30"><a className="viewwLink" style={{cursor: "pointer"}} 
                            // onClick={() => onSave(false)}
                                >Save &amp; continue later</a></div>
                            <div className="col-xl-7 col-lg-7 col-md-12 col-xs-12 end-md mt-30">
                                <div className="forminpt">
                                    <input name="Save &amp; next" className="btn btn-orange" type="button" value="Save &amp; next"
                                    //  onClick={() => onSave(true)}
                                     />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </>
    )
}

export default CampaignCreative