import React, { useEffect, useState, useContext, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import AppContextObject from "../../../../../common/context/common"
import DataContext from "../../../../../service/backendContext"
// import CampaignBudget from '../components/campaignBudget'
// import GenderSplit from '../components/genderSplit'
// import CampaignReach from '../components/campaignReach'
// import CampaignImpression from '../components/campaignImpression'
import { compareObjs, findMonthsBetweenDate, sortMonths } from '../../../../../service/helper'
import EventsTable from './components/eventsTable'
import Pagination from '../../../../../components/addon/pagination'
import ConfirmDialog from '../../../../../components/addon/confirmDialog/confirmDialog'
// import BrandBackBtn from "../components/brandBackBtn"
import helpImg from "../../../../../assets/images/help.svg"
import tableFilter from "../../../../../assets/images/table-filter.svg"
import BackendBackBtn from '../../components/backendBackBtn'

const NonWisrCampaignEvent = ({ setShowProgressSideMenu }) => {
    const location = useLocation()
    const history = useHistory()
    const context = useContext(AppContextObject)
    const parentData = useContext<any>(DataContext)
    const [formData, setFormData] = useState(parentData?.nonWisrCampaignData?.eventAndOccation)
    const [totalBudget, setTotalBudget] = useState(parentData?.nonWisrCampaignData?.campaignDetail?.totalBudget)
    const [totalReach, setTotalReach] = useState(parentData?.nonWisrCampaignData?.campaignDetail?.totalReach)
    const [boysRatio, setBoysRatio] = useState(parentData?.nonWisrCampaignData?.campaignDetail?.boysRatio)
    const [girlsRatio, setGirlsRatio] = useState(parentData?.nonWisrCampaignData?.campaignDetail?.girlsRatio)
    const [totalImpression, setTotalImpression] = useState(parentData?.nonWisrCampaignData?.campaignDetail?.totalImpression)
    const [overlay, setOverlay] = useState(false)
    const [eventList, setEventList] = useState([])
    const [recommendedEventList, setRecommendedEventList] = useState([])
    const [customEventList, setCustomEventList] = useState([])
    const [pageLoaded, setPageLoaded] = useState(false)
    const [totalPage, setTotalPage] = useState([0])
    const [currentPage, setCurrentPage] = useState(1)
    const [dataPerPage] = useState(10)
    const [showExitPopup, setShowExitPopup] = useState(false)
    const [confirmDialogBtnLoading, setConfirmDialogBtnLoading] = useState(false)

    useEffect(() => {
        const isSummary = new URLSearchParams(location.search).get("isSummary")
        if (isSummary === "true") {
            console.log("object")
            parentData.setNonWisrCampaignProgress({
                brandDetails: true,
                inventory: true,
                event: false,
                school: false,
                campaignCreative: false,
                preview: false
            })
        }
    }, [])
    useEffect(() => {
        setShowProgressSideMenu(true)
        if (parentData.setNonWisrCampaignActiveModule) {
            parentData.setNonWisrCampaignActiveModule({
                ...parentData.nonWisrCampaignActiveModule,
                module: "campaignPlacement",
                subModule: "events"
            })
        }
    }, [])
    useEffect(() => {
        setFormData({
            ...formData,
            eventAndOccations: [...parentData.nonWisrCampaignData.eventAndOccation.eventAndOccations]
        })
        // setTotalBudget(parentData.nonWisrCampaignData.campaignDetail.totalBudget)
        // setTotalReach(parentData.nonWisrCampaignData.campaignDetail.totalReach)
        // setTotalImpression(parentData.nonWisrCampaignData.campaignDetail.totalImpression)
        // setGirlsRatio(parentData.nonWisrCampaignData.campaignDetail.girlsRatio)
        // setBoysRatio(parentData.nonWisrCampaignData.campaignDetail.boysRatio)
    }, [])
    useEffect(() => {
        getEventAndOccationList()
    }, [currentPage])
    const onNextClick = () => {
        setCurrentPage(prev => prev + 1)
    }
    const onPrevClick = () => {
        setCurrentPage(prev => prev - 1)
    }
    const onPageChange = (page) => {
        setCurrentPage(page)
    }
    const getEventAndOccationList = async () => {
        setPageLoaded(false)
        let monthsArray = []
        // let schoolIdArray = []
        // 619cc68ebc9cb529b8dd0fd7
        console.log("parentdata", parentData)
        monthsArray = findMonthsBetweenDate(parentData?.nonWisrCampaignData?.brandDetail?.startDate, parentData?.nonWisrCampaignData?.brandDetail?.endDate)
        // parentData?.nonWisrCampaignData?.school?.schools.length > 0 && parentData?.nonWisrCampaignData?.school?.schools.forEach((school, index) =>{
        //     schoolIdArray.push(school._id)
        // })
        const resp = await context.apis.public.getAllActivitiesWithRecommendation(
            context.validator, { months: sortMonths(monthsArray), brandCategory: parentData.nonWisrCampaignData.brandDetail.brandCategory._id }, { page: currentPage, limit: dataPerPage, withCount: true }
        )
        console.log("getAllActivitiesWithRecommendation", resp)
        if (resp && resp.done) {
            let recommendedEvent = [...resp.response.recommendedActivities.rows.sort((a, b) => {
                return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
            })]
            let otherEvent = [...resp.response.otherActivities.rows]
            let filterEvent = otherEvent.filter(event => {
                let found = recommendedEvent.find(item => item._id === event._id)
                if(found){
                    return false
                }
                else{
                    return true
                }
            })
            setEventList([...filterEvent.sort((a, b) => {
                return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
            })])
            setRecommendedEventList([...recommendedEvent])
            setCustomEventList([...resp.response.customActivities.rows.sort((a, b) => {
                return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
            })])
            // ([...resp.response.recommendedActivities.rows])
            // setFormData({
            //     ...formData,
            //     eventAndOccations: [...resp.response.recommendedActivities.rows]
            // })
            let total = Math.floor(Number(resp.response.otherActivities.count) / dataPerPage) + (Number(resp.response.otherActivities.count) % dataPerPage > 0 ? 1 : 0)
            let arr = []
            for (let i = 0; i < total; i++) {
                arr.push(i)
            }
            setTotalPage([...arr])
        }
        setPageLoaded(true)
    }
    const onSave = async (confirm, optimisedData?) => {
        if (confirm) {
            // if(parentData.nonWisrCampaignData.brandDetail.isTrial){
            //     let allInventory = [...parentData.nonWisrCampaignData.inventory.inventories]
            //     let inventory = parentData.nonWisrCampaignData.inventory.inventories.length > 0 ? parentData.nonWisrCampaignData.inventory.inventories.map(item => item.name) : []
            //     let schools = parentData.nonWisrCampaignData.school.schools.length > 0 ? parentData.nonWisrCampaignData.school.schools.map(item => item._id) : []
            //     let resp = await context.apis[context.user.role].checkSelectedInventories(
            //         context.validator, {inventories: inventory, schools: schools}
            //     )
            //     console.log(resp)
            //     if(resp && resp.length > 0){
            //         let usedInventory = allInventory && allInventory.length > 0 && allInventory.filter(inventory => {
            //             let found = resp.find(item => item === inventory.name)
            //             console.log(found)
            //             if(found){
            //                 return false
            //             }
            //             else{
            //                 return true
            //             }
            //         })
            //         console.log(usedInventory)
            //         allInventory = [...usedInventory]
            //     }
            //     if (parentData) {
            //         if (parentData.setNonWisrCampaignProgress) {
            //             parentData.setNonWisrCampaignProgress({
            //                 ...parentData.progress,
            //                 event: true,
            //             })
            //         }
            //         if(parentData.setNonWisrCampaignData){
            //             parentData.setNonWisrCampaignData({
            //                 ...parentData.nonWisrCampaignData,
            //                 eventAndOccation: {...formData},
            //                 campaignDetail: {
            //                     ...parentData.nonWisrCampaignData.campaignDetail,
            //                     totalBudget: totalBudget,
            //                     totalReach: totalReach,
            //                     totalImpression: totalImpression,
            //                     girlsRatio: girlsRatio,
            //                     boysRatio: boysRatio
            //                 },
            //                 inventory: {
            //                     ...parentData.nonWisrCampaignData.inventory,
            //                     usedInventories: [...allInventory],
            //                     notUsedInventories: [...resp]
            //                 }
            //             })
            //         }
            //     }
            // }
            // else{
            // if(parentData?.nonWisrCampaignData?.school?.schools?.length > 0 && !compareObjs(parentData.nonWisrCampaignData.eventAndOccation.eventAndOccations, formData.eventAndOccations)){
            //     if (parentData) {
            //         if (parentData.setNonWisrCampaignProgress) {
            //             parentData.setNonWisrCampaignProgress({
            //                 ...parentData.progress,
            //                 event: true,
            //             })
            //         }
            //         if(parentData.setNonWisrCampaignData){
            //             parentData.setNonWisrCampaignData({
            //                 ...parentData.nonWisrCampaignData,
            //                 eventAndOccation: {...formData},
            //                 school:{schools: [], locations: []}
            //                 // campaignDetail: {
            //                 //     ...parentData.nonWisrCampaignData.campaignDetail,
            //                 //     totalBudget: totalBudget,
            //                 //     totalReach: totalReach,
            //                 //     totalImpression: totalImpression,
            //                 //     girlsRatio: girlsRatio,
            //                 //     boysRatio: boysRatio
            //                 // }
            //             })
            //         }
            //     }
            // }
            // else{
            if (parentData) {
                if (parentData.setNonWisrCampaignProgress) {
                    parentData.setNonWisrCampaignProgress({
                        ...parentData.nonWisrCampaignProgress,
                        event: true,
                    })
                }
                if (parentData.setNonWisrCampaignData) {
                    parentData.setNonWisrCampaignData({
                        ...parentData.nonWisrCampaignData,
                        eventAndOccation: { ...formData },
                        // campaignDetail: {
                        //     ...parentData.nonWisrCampaignData.campaignDetail,
                        //     totalBudget: totalBudget,
                        //     totalReach: totalReach,
                        //     totalImpression: totalImpression,
                        //     girlsRatio: girlsRatio,
                        //     boysRatio: boysRatio
                        // }
                    })
                }
            }
            // }
            // }
            // if(parentData.nonWisrCampaignData.brandDetail.isTrial){
            //     history.push('/brands/brand-details/create-campaign/preview')
            // }
            // else{
            //     history.push('/brands/brand-details/create-campaign/creative')
            // }
            if (parentData.nonWisrCampaignData.brandDetail.isTrial) {
                history.push('/backend/backend-details/nonWisr-create-trial-campaign/nonWisr-campaign-school')
            }
            else {
                history.push('/backend/backend-details/nonWisr-create-campaign/nonWisr-campaign-school')
            }
        }
    }
    const openExit = () => {
        // setOverlay(true)
        // setShowExitPopup(true)
    }
    const selectAllRecommendedEvent = (e) => {
        if(recommendedEventList.length === 0){
            return
        }
        if(e.target.checked){
            let remainingEvents = formData.eventAndOccations.filter(event => {
                let found = recommendedEventList.find(item => item._id === event._id)
                if(found){
                    return false
                }
                else{
                    return true
                }
            })
            setFormData({
                ...formData,
                eventAndOccations: [...remainingEvents, ...recommendedEventList]
            })
        }
        else{
            let remainingEvents = formData.eventAndOccations.filter(event => {
                let found = recommendedEventList.find(item => item._id === event._id)
                if(found){
                    return false
                }
                else{
                    return true
                }
            })
            setFormData({
                ...formData,
                eventAndOccations: [...remainingEvents]
            })
        }
    }
    const selectAllEvents = (e) => {
        if(eventList.length === 0){
            return
        }
        if(e.target.checked){
            let remainingEvents = formData.eventAndOccations.filter(event => {
                let found = eventList.find(item => item._id === event._id)
                if(found){
                    return false
                }
                else{
                    return true
                }
            })
            setFormData({
                ...formData,
                eventAndOccations: [...remainingEvents, ...eventList]
            })
        }
        else{
            let remainingEvents = formData.eventAndOccations.filter(event => {
                let found = eventList.find(item => item._id === event._id)
                if(found){
                    return false
                }
                else{
                    return true
                }
            })
            setFormData({
                ...formData,
                eventAndOccations: [...remainingEvents]
            })
        }
    }
    const selectAllCustomEvent = (e) => {
        if(customEventList.length === 0){
            return
        }
        if(e.target.checked){
            let remainingEvents = formData.eventAndOccations.filter(event => {
                let found = customEventList.find(item => item._id === event._id)
                if(found){
                    return false
                }
                else{
                    return true
                }
            })
            setFormData({
                ...formData,
                eventAndOccations: [...remainingEvents, ...customEventList]
            })
        }
        else{
            let remainingEvents = formData.eventAndOccations.filter(event => {
                let found = customEventList.find(item => item._id === event._id)
                if(found){
                    return false
                }
                else{
                    return true
                }
            })
            setFormData({
                ...formData,
                eventAndOccations: [...remainingEvents]
            })
        }

    }
    const checkAllEventSelected = () => {
        if(formData.eventAndOccations.length === 0 || eventList.length === 0){
            return false
        }
        let notFound = false
        eventList.forEach(event => {
            let found = formData.eventAndOccations.find(item => item._id === event._id)
            if(!found){
                notFound = true
            }
        })
        return !notFound
    }
    const allRecommendedEventSelected = () => {
        if(formData.eventAndOccations.length === 0 || recommendedEventList.length === 0){
            return false
        }
        let notFound = false
        recommendedEventList.forEach(event => {
            let found = formData.eventAndOccations.find(item => item._id === event._id)
            if(!found){
                notFound = true
            }
        })
        return !notFound
    }
    const checkAllCustomEventSelected = () => {
        if(formData.eventAndOccations.length === 0 || customEventList.length === 0){
            return false
        }
        let notFound = false
        customEventList.forEach(event => {
            let found = formData.eventAndOccations.find(item => item._id === event._id)
            if(!found){
                notFound = true
            }
        })
        return !notFound
    }
    return (
        <>
            <div className={`ovelap ${overlay === true && "active"}`}></div>
            {/* <TopBar isDashboard={true} /> */}
            <div className="col-xl-9 col-lg-12 col-md-12 col-xs-12">
                <div className="rightPart noBack">
                    <div className="row">
                        <div className="col-xs-12 col-lg-12 col-md-12 col-xs-12">
                            <BackendBackBtn />
                        </div>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-20"><h2 className="htx5 clearfix mt-10 mb-20">Create a Campaign</h2></div>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-20"><h2 className="htx2 clearfix mb-10">Events & Occasions</h2></div>
                        <div className="col-xl-8 col-lg-8 col-md-12 col-xs-12 mb-20">
                            <div className="row">
                                {parentData.nonWisrCampaignData.brandDetail.brandCategory.label !== "Others" &&
                                    <>
                                        <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 pl pr">
                                            <h2 className="htx3 clearfix">Recommended Events</h2>
                                        </div>
                                        <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-20 pl pr">
                                            <div className="table-container mt-10 addAdmininput">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">
                                                                <div className="checkbox fnone">
                                                                    <input type="checkbox" id="allRecomEvents" checked={allRecommendedEventSelected()} onClick={(e) => selectAllRecommendedEvent(e)} />
                                                                    <label htmlFor="allRecomEvents"></label>
                                                                </div>
                                                            </th>
                                                            <th scope="col">sr no</th>
                                                            <th scope="col">Event name</th>
                                                            <th scope="col">placements</th>
                                                            <th scope="col"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {pageLoaded ? recommendedEventList.length > 0 ? recommendedEventList.map((event, index) => (
                                                            <EventsTable
                                                                event={event}
                                                                index={index}
                                                                formData={formData}
                                                                setFormData={setFormData}
                                                                setOverlay={setOverlay}
                                                                tableName="table1"
                                                            />
                                                        ))
                                                            :
                                                            <tr><td colSpan={5}>No Recommended event found</td></tr>
                                                            :
                                                            [...Array(10)].map((el, index) => {
                                                                return (
                                                                    <tr>
                                                                        {
                                                                            [...Array(5)].map((el, index) => {
                                                                                return (
                                                                                    <td>
                                                                                        <div className="content-loader-block"></div>
                                                                                    </td>
                                                                                )
                                                                            })
                                                                        }
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </>
                                }
                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 pl pr mt-20"></div>
                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 pl pr">
                                    <h2 className="htx3 clearfix">Events & Occasions</h2>
                                </div>
                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 pl pr">
                                    <div className="table-container mt-10 addAdmininput">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">
                                                        <div className="checkbox fnone">
                                                            <input type="checkbox" id="allEvents" checked={checkAllEventSelected()} onClick={(e) => selectAllEvents(e)} />
                                                            <label htmlFor="allEvents"></label>
                                                        </div>
                                                    </th>
                                                    <th scope="col">sr no</th>
                                                    <th scope="col">Event name</th>
                                                    <th scope="col">placements</th>
                                                    <th scope="col"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {pageLoaded ? eventList.length > 0 ? eventList.map((event, index) => (
                                                    <EventsTable
                                                        event={event}
                                                        index={index}
                                                        formData={formData}
                                                        setFormData={setFormData}
                                                        setOverlay={setOverlay}
                                                        tableName="table2"
                                                    />
                                                ))
                                                    :
                                                    <tr><td colSpan={5}>No Other event found</td></tr>
                                                    :
                                                    [...Array(10)].map((el, index) => {
                                                        return (
                                                            <tr>
                                                                {
                                                                    [...Array(5)].map((el, index) => {
                                                                        return (
                                                                            <td>
                                                                                <div className="content-loader-block"></div>
                                                                            </td>
                                                                        )
                                                                    })
                                                                }
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 pl pr mt-20"></div>
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 pl pr">
                                        <h2 className="htx3 clearfix">Custom Events & Occasions</h2>
                                    </div>
                                    <div className="table-container mt-10 addAdmininput">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">
                                                        <div className="checkbox fnone">
                                                            <input type="checkbox" checked={checkAllCustomEventSelected()} id="allCustom" onClick={(e) => selectAllCustomEvent(e)} />
                                                            <label htmlFor="allCustom"></label>
                                                        </div>
                                                    </th>
                                                    <th scope="col">sr no</th>
                                                    <th scope="col">Event name</th>
                                                    <th scope="col">placements</th>
                                                    <th scope="col"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {pageLoaded ? customEventList.length > 0 ? customEventList.map((event, index) => (
                                                    <EventsTable
                                                        event={event}
                                                        index={index}
                                                        formData={formData}
                                                        setFormData={setFormData}
                                                        setOverlay={setOverlay}
                                                        tableName="table3"
                                                    />
                                                ))
                                                    :
                                                    <tr><td colSpan={5}>No Custom event found</td></tr>
                                                    :
                                                    [...Array(10)].map((el, index) => {
                                                        return (
                                                            <tr>
                                                                {
                                                                    [...Array(5)].map((el, index) => {
                                                                        return (
                                                                            <td>
                                                                                <div className="content-loader-block"></div>
                                                                            </td>
                                                                        )
                                                                    })
                                                                }
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    {totalPage.length > 1 &&
                                        <Pagination
                                            currentPage={currentPage}
                                            totalPage={totalPage}
                                            onPageChange={onPageChange}
                                            onNextClick={onNextClick}
                                            onPrevClick={onPrevClick}
                                            loading={!pageLoaded}
                                        />
                                    }
                                </div>
                                <div className="col-xl-5 col-lg-5 col-md-6 col-xs-12 delete-user mt-30"><a className="viewwLink" style={{ cursor: "pointer" }} onClick={openExit}>Save & Continue Later</a></div>
                                <div className="col-xl-7 col-lg-7 col-md-6 col-xs-12 end-md mt-30">
                                    <div className="forminpt">
                                        <input name="Save &amp; next" className="btn btn-orange" type="button" value="Save &amp; next" onClick={() => onSave(true)} />
                                    </div>
                                </div>
                                {/* <div className="col-xl-7 col-lg-7 col-md-6 col-xs-12 end-md mt-30">
                                    <div className="forminpt">
                                        <input name="Save &amp; next for optimised data" className="btn btn-orange" type="button" value="Save &amp; next for optimised data" onClick={(e) => onSave(true, 'optimisedData')} />
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-12 col-xs-12 mb-20 mt-30">
                            {/* <CampaignBudget
                            totalBudget={totalBudget}
                            setTotalBudget={setTotalBudget}
                            setOverlay={setOverlay}
                        />
                        <GenderSplit
                            girlsRatio={girlsRatio}
                            boysRatio={boysRatio}
                        />
                        <CampaignReach
                            totalReach={totalReach}
                            setTotalReach={setTotalReach}
                            disable={false}
                        />
                        <CampaignImpression
                            totalImpression={totalImpression}
                            setTotalImpression={setTotalImpression}
                            disable={false}
                        /> */}
                        </div>
                    </div>
                </div>
            </div>
            {/* <ConfirmDialog
                togglePopup={showExitPopup}
                onModalClose={onExit}
                componentName={""}
                confirmTitle="Do you want to save and exit?"
                isLoading={confirmDialogBtnLoading}
            /> */}
        </>
    )
}

export default NonWisrCampaignEvent