import React, { useEffect, useContext, useState } from 'react';
import { Link, useHistory } from 'react-router-dom'
import { ToWords } from 'to-words';
import creativeImgViewOne from "../../../../../assets/images/img-view-one.jpg"
import creativeImgViewTwo from "../../../../../assets/images/img-view-two.jpg"
import creativeImgViewThree from "../../../../../assets/images/img-view-three.jpg"
import imgInventory from "../../../../../assets/images/blank-inventory-img.jpg"
import DataContext from "../../../../../service/backendContext";
import AppContextObject from "../../../../../common/context/common";
import school from '../../../brands/school'
import RangeSlider from '../../../../../components/addon/rangeSlider';
import { titleCase } from '../../../../../service/helper';

const CampaignPreview = ({ setShowProgressSideMenu }) => {
    const history = useHistory()
    const parentData = useContext<any>(DataContext);
    const toWords = new ToWords();
    const context = useContext(AppContextObject)
    const [brandCategory, setBrandCategory] = useState([]);
    useEffect(() => {
        parentData.setActiveModule({
            ...parentData.activeModule,
            module: "preview",
            subModule: ""
        })
        setShowProgressSideMenu(true);
        getBrandCategoryList();
    }, [])
    const getBrandCategoryList = async () => {
        const resp = await context.apis.public.listBrandCategoriesMaster(
            context.validator, {}, {}
        )
        console.log(resp)
        if (resp && resp.done) {
            setBrandCategory(resp.response.rows.filter((item => item._id === parentData.data.brandDetail.brandcategoryId)))
            // setBrandCategoryList([...setBrandCategoryList])
        }
    }
    const finish = () => {

        parentData.setShowSidemenu(true);
        console.log('parentData.data.formparentData.data.form', parentData.data.from)
        if (parentData.data.from === 'brand') {
            history.push({
                pathname: "/backend/backend-details/create-trial-campaign-details",
                state: { campaignType: parentData.data.isTrial ? 'trial' : 'create', from: "brand", campaignId: parentData.data.campaignId }
            })
        } else {
            history.push({
                pathname: "/backend/backend-details/create-trial-campaign-details",
                state: { campaignType: parentData.data.isTrial ? 'trial' : 'create', from: "campaign", campaignId: parentData.data.campaignId }
            })
        }
    }
    console.log('campaign preview page====>', parentData, brandCategory)
    return (
        <>
            <div className={`ovelap`}></div>
            {/* <TopBar isDashboard={true} /> */}
            <div className="col-xl-9 col-lg-9 col-md-12 col-xs-12">
                <div className="rightPart noBack">
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-20"><h2 className="htx1 clearfix mt-10 mb-10">Create a Campaign</h2></div>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-30">
                            <h2 className="htx2 clearfix mb-10">Campaign Preview</h2>
                        </div>
                        <div className="col-xl-8 col-lg-8 col-md-12 col-xs-12 mb-20 pl">
                            <div className="row">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                    <div className="personal-box">
                                        <div className="row personal-box-top">
                                            <div className="row col-xl-8 col-lg-8 col-md-8 col-xs-8 start-md">
                                                <h3 className="htx2 mb20">Campaign Details</h3>
                                            </div>
                                            {/* <div className="row col-xl-4 col-lg-4 col-md-4 col-xs-4 end-xs">
                                            <a href="javascript:void(0)" className="editbtn"><img src="/static/media/edit-btn.c928a066.svg" /> Edit</a>
                                        </div> */}
                                        </div>
                                        <div className="personal-bottom">
                                            <div className="row personal-box-bot">
                                                <div className="col-xl-5 col-lg-5 col-md-6 col-xs-12">
                                                    <p>Name</p>
                                                </div>
                                                <div className="col-xl-7 col-lg-7 col-md-6 col-xs-12">
                                                    <b>{parentData.data.brandDetail.name}</b>
                                                </div>
                                                <div className="col-xl-5 col-lg-5 col-md-6 col-xs-12">
                                                    <p>Description</p>
                                                </div>
                                                <div className="col-xl-7 col-lg-7 col-md-6 col-xs-12">
                                                    <b>{parentData.data.brandDetail.description}</b>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                    <div className="personal-box">
                                        <div className="row personal-box-top">
                                            <div className="row col-xl-8 col-lg-8 col-md-8 col-xs-8 start-md">
                                                <h3 className="htx2 mb20">Campaign Specifications</h3>
                                            </div>
                                            {/* <div className="row col-xl-4 col-lg-4 col-md-4 col-xs-4 end-xs">
                                            <a href="javascript:void(0)" className="editbtn"><img src="/static/media/edit-btn.c928a066.svg" /> Edit</a>
                                        </div> */}
                                        </div>
                                        <div className="personal-bottom">
                                            <div className="row personal-box-bot">
                                                <div className="col-xl-5 col-lg-5 col-md-6 col-xs-12">
                                                    <p>Start Date</p>
                                                </div>
                                                <div className="col-xl-7 col-lg-7 col-md-6 col-xs-12">
                                                    <b>{parentData.data.brandDetail.startDate}</b>
                                                </div>
                                                <div className="col-xl-5 col-lg-5 col-md-6 col-xs-12">
                                                    <p>End Date</p>
                                                </div>
                                                <div className="col-xl-7 col-lg-7 col-md-6 col-xs-12">
                                                    <b>{parentData.data.brandDetail.endDate}</b>
                                                </div>
                                                <div className="col-xl-5 col-lg-5 col-md-6 col-xs-12">
                                                    <p>Location</p>
                                                </div>
                                                <div className="col-xl-7 col-lg-7 col-md-6 col-xs-12">
                                                    <b>
                                                    {parentData.data.brandDetail.location.map((item, key) => {
                                                        return (
                                                            <span key={key}><span>{titleCase(item)}</span>{key+1!==parentData.data.brandDetail.location.length ? ',' :''} {` `}</span>
                                                        )
                                                    })}
                                                    </b>


                                                </div>
                                                <div className="col-xl-5 col-lg-5 col-md-6 col-xs-12">
                                                    <p>Target Gender</p>
                                                </div>
                                                <div className="col-xl-7 col-lg-7 col-md-6 col-xs-12">
                                                    <b>{parentData.data.brandDetail.targetGender}</b>
                                                </div>

                                                <div className="col-xl-5 col-lg-5 col-md-6 col-xs-12">
                                                    <p>Target Age Group</p>
                                                </div>
                                                <div className="col-xl-7 col-lg-7 col-md-6 col-xs-12">
                                                    <b>{`${parentData.data.brandDetail.tagetAgeAudiance[0]} yo - ${parentData.data.brandDetail.tagetAgeAudiance[1]} yo`}</b>

                                                </div>
                                                <div className="col-xl-5 col-lg-5 col-md-6 col-xs-12">
                                                    <p>Medium of Study</p>
                                                </div>
                                                <div className="col-xl-7 col-lg-7 col-md-6 col-xs-12">
                                                    <span className="medium-break">
                                                        {parentData.data.brandDetail.studyMedium.length !== 0 && parentData.data.brandDetail.studyMedium.map((item, index) => (
                                                            <b style={{"wordBreak": "initial"}} key={index}>{index < parentData.data.brandDetail.studyMedium.length -1 ? `${item}, ` : item}</b>
                                                        ))}
                                                            
                                                    </span>
                                                </div>
                                                <div className="col-xl-5 col-lg-5 col-md-6 col-xs-12">
                                                    <p>Brand Category</p>
                                                </div>
                                                <div className="col-xl-7 col-lg-7 col-md-6 col-xs-12">
                                                    {brandCategory.map((item, key) => {
                                                        return <b key={key}>
                                                            <span>{item.name}</span>{key+1!==brandCategory.length ? ',' :''} {` `}
                                                        </b>
                                                    })}

                                                </div>
                                                {/* {parentData.data.brandDetail.wisrOffering.eventAndOccations &&
                                                    <>
                                                        <div className="col-xl-5 col-lg-5 col-md-6 col-xs-12">
                                                            <p>Events Selected</p>
                                                        </div>
                                                        <div className="col-xl-7 col-lg-7 col-md-6 col-xs-12">
                                                            <b>Sports Day</b>
                                                        </div>
                                                    </>
                                                } */}
                                                <div className="col-xl-5 col-lg-5 col-md-6 col-xs-12">
                                                    <p>No of Schools Occupied</p>
                                                </div>
                                                <div className="col-xl-7 col-lg-7 col-md-6 col-xs-12">
                                                    <b>{parentData.data.school.schools.length}</b>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                    <div className="personal-box">
                                        <div className="row personal-box-top">
                                            <div className="row col-xl-8 col-lg-8 col-md-8 col-xs-8 start-md">
                                                <h3 className="htx2 mb20">Inventory Details</h3>
                                            </div>
                                            {/* <div className="row col-xl-4 col-lg-4 col-md-4 col-xs-4 end-xs">
                                                <a href="javascript:void(0)" className="editbtn"><img src="/static/media/edit-btn.c928a066.svg" /> Edit</a>
                                            </div> */}
                                        </div>
                                        <div className="personal-bottom">
                                            <div className="row personal-box-bot">
                                                <div className="col-xl-12 col-lg-12 col-md-6 col-xs-12">
                                                    <h4 className="htx3 mb-10">Inventory Added</h4>
                                                </div>
                                                <div className="col-xl-12 col-lg-12 col-md-6 col-xs-12">
                                                    <div className="schoolNameTable">
                                                        {parentData.data.inventory.inventories.map((item, key) => {
                                                            return <span key={key}>{item.name}</span>
                                                        })}
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {parentData.data.brandDetail.wisrOffering.eventAndOccations &&
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                        <div className="personal-box">
                                            <div className="row personal-box-top">
                                                <div className="row col-xl-8 col-lg-8 col-md-8 col-xs-8 start-md">
                                                    <h3 className="htx2 mb20">Event Details</h3>
                                                </div>
                                                {/* <div className="row col-xl-4 col-lg-4 col-md-4 col-xs-4 end-xs">
                                                <a href="javascript:void(0)" className="editbtn"><img src="/static/media/edit-btn.c928a066.svg" /> Edit</a>
                                            </div> */}
                                            </div>
                                            <div className="personal-bottom">
                                                <div className="row personal-box-bot">
                                                    <div className="col-xl-12 col-lg-12 col-md-6 col-xs-12">
                                                        <h4 className="htx3 mb-10">Events Selected</h4>
                                                    </div>
                                                    <div className="col-xl-12 col-lg-12 col-md-6 col-xs-12">
                                                        <div className="schoolNameTable">
                                                            {parentData.data.activities.events.map((event, key) => {
                                                                return <span key={key}>{event.name}</span>
                                                            })}

                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>}
                                {!parentData.data?.isTrial && <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                    <div className="personal-box">
                                        <div className="row personal-box-top">
                                            <div className="row col-xl-8 col-lg-8 col-md-8 col-xs-8 start-md">
                                                <h3 className="htx2 mb20">Campaign Creatives</h3>
                                            </div>
                                            {/* <div className="row col-xl-4 col-lg-4 col-md-4 col-xs-4 end-xs">
                                                <a href="javascript:void(0)" className="editbtn"><img src="/static/media/edit-btn.c928a066.svg" /> Edit</a>
                                            </div> */}
                                        </div>
                                        <div className="personal-bottom">
                                            <div className="row personal-box-bot">
                                                <div className="col-xl-12 col-lg-12 col-md-6 col-xs-12">
                                                    <h4 className="htx3 mb-10">Creatives Uploaded</h4>
                                                </div>
                                                <div className="col-xl-12 col-lg-12 col-md-6 col-xs-12">
                                                    <div className="schoolNameTable">
                                                        <div className="creativeImgView">
                                                            <div className="creativeImg">
                                                                <img src={parentData.data.campaignCreative.creatives.image1 || imgInventory} />
                                                            </div>
                                                            <div className="creativeImg">
                                                                <img src={parentData.data.campaignCreative.creatives.image2 || imgInventory} />
                                                            </div>
                                                            <div className="creativeImg">
                                                                <img src={parentData.data.campaignCreative.creatives.image3 || imgInventory} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>}
                                {/* <div className="col-xl-8 col-lg-8 col-md-8 col-xs-12 middle-xs">
                                    <div className='forminpt'>
                                        <div className='checkbox onboardcheck'>
                                            <input type="checkbox" id="html-term" checked disabled/>
                                            <label style={{ display: "inline" }} htmlFor='html-term'></label> <b>I have read and agree to the{" "}</b>
                                            <a style={{ cursor: "pointer", color: "#fe6e00" }}>Terms &amp; Conditions</a>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="col-xl-5 col-lg-5 col-md-12 col-xs-12 delete-user mt-30">
                                    <Link className="btn btn-orange quit-btn" to={{
                                        pathname: "/backend/backend-details/create-trial-campaign-details",
                                        state: { campaignType: parentData.data.isTrial ? 'trial' : 'create', from: parentData.data.from, campaignId: parentData.data.campaignId }
                                    }}>QUIT</Link>
                                </div>
                                <div className="row col-xl-7 col-lg-7 col-md-12 col-xs-12 end-md mt-30">
                                    <div className="forminpt ">
                                        <input name="Finish" className="btn btn-orange comp-seting" type="button" value="Finish" onClick={finish} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-12 col-xs-12 mb-20">
                            <div className="w90">

                                <div className="box-design mb-20 overheadThree">
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-10">
                                        <h2 className="htx3 h-achor clearfix">Budget
                                            {/* <a href="javascript:void(0)" className="editbtn">
                                            <img src="/static/media/edit-btn.c928a066.svg" /> Edit
                                            </a> */}
                                        </h2>
                                        <div className="inventory-overHead">
                                            <h3>₹ {parentData.data.brandDetail.budget.toLocaleString('en-IN')}</h3>
                                            <p className="mt-10">{`Rupees ${toWords.convert(Number(parentData.data.brandDetail.budget))}`}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="box-design mb-20 overheadThree">
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-10">
                                        <h2 className="htx3 h-achor clearfix">Average Gender Split</h2>
                                        <div className="inventory-overHead camp-performance genderSplit">
                                            <p>Boys <b>{parentData.data.campaignDetail.boysRatio}%</b></p><p className="fR">Girls <b>{parentData.data.campaignDetail.girlsRatio}%</b></p>
                                            <div className="camp-performance-bar"><span style={{ width: `${parentData.data.campaignDetail.boysRatio}%` }}></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="box-design mb-20 overheadThree">
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-10">
                                        <h2 className="htx3 h-achor clearfix">Estimated Campaign Reach</h2>
                                        <div className="inventory-overHead camp-performance genderSplit">
                                            <h3>{parentData?.data?.campaignDetail?.totalReach.toLocaleString('en-IN')} <em>Students</em></h3>
                                            {/* <RangeSlider
                                                min={0}
                                                max={10000}
                                                value={parentData?.data?.campaignDetail?.totalReach}
                                                disable={true}
                                            /> */}
                                            {/* <div className="tol-built-in campaign-reach-scroll">
                                                <span><strong></strong></span>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="box-design mb-20 overheadThree">
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-10">
                                        <h2 className="htx3 h-achor clearfix">Estimated Campaign Impressions</h2>
                                        <div className="inventory-overHead camp-performance genderSplit">
                                            <h3>{parentData?.data?.campaignDetail?.totalImpression.toLocaleString('en-IN')}</h3>
                                            {/* <RangeSlider
                                                min={0}
                                                max={10000}
                                                value={parentData?.data?.campaignDetail?.totalReach}
                                                disable={true}
                                            /> */}
                                            {/* <div className="tol-built-in campaign-reach-scroll">
                                                <span><strong></strong></span>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CampaignPreview