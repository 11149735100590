
export class Validator {
    keyKeeper: any;
    constructor(public onError:()=>void, public onSuccess:()=>void) {
        this.keyKeeper = {};
    }
    add(key: string, value: any) {
        this.keyKeeper[key] = value;
    }
    check() {
        for(let key in this.keyKeeper) {
            if(!this.keyKeeper[key]) {
                this.onError();
                console.log('error occured in validator', key, this);
                // document.location.reload()
                return false;
            }
        }
        this.onSuccess();
        return true;
    }
    empty() {
        this.keyKeeper = {};
    }
    dropKey(keyName: string) {
        delete this.keyKeeper[keyName];
    }
    isNotOk(key: string, component: any) {
        if(this.keyKeeper.hasOwnProperty(key)) {
            if(this.keyKeeper[key]) {
                return ;
            } else {
                return component;
            }   
        }
        return ;
    }
}