import { useState, useContext, useEffect, useRef } from "react"

import { useHistory } from "react-router-dom"
import { readAbleBoard, returnReadableStatus, titleCase } from '../../../../service/helper'
import BackendTopBar from '../components/backendTopBar'
import AppContextObject from "../../../../common/context/common"
import proEdit from "../../../../assets/images/edit-profile.svg"
import Loader from "../../../../components/addon/loader"
import ConfirmDialog from "../../../../components/addon/confirmDialog/confirmDialog"
import toast from "react-hot-toast"

const NonWisrSchoolDetails = () => {
    const context = useContext(AppContextObject)
    const history = useHistory()
    const [overlay, setOverlay] = useState(false)
    const [schoolDetail, setSchoolDetail] = useState<any>()
    const [localArea, setLocalArea] = useState()
    const [city, setCity] = useState()
    const [state, setState] = useState()
    const [pincode, setPincode] = useState()
    const [address, setAddress] = useState()
    const [schoolEduLevel, setSchoolEduLevel] = useState([])
    const [schoolLang, setSchoolLang] = useState([])
    const [schoolBoard, setSchoolBoard] = useState([])
    const [schoolId, setSchoolId] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [showSchoolDeleteModal, setShowSchoolDeleteModal] = useState(false)

    useEffect(() => {
        if(history.location.state){
            let data: any = history.location.state
            if(data.schoolId){
                setSchoolId(data.schoolId)
                getSchoolDetails(data.schoolId)
            }
        }
    }, [])

    const getSchoolDetails = async(_id) => {
        setIsLoading(true)
        const resp = await context.apis[context.user.role].getNonWisrSchool(
            context.validator, {_id}
        )
        console.log("getNonWisrSchool", resp)
        if(resp && resp.done){
            setLocalArea(resp.response.localArea)
            setCity(resp.response.city)
            setState(resp.response.state)
            setPincode(resp.response.pincode)
            setAddress(resp.response.address)
            setSchoolLang([resp.response.language])
            setSchoolBoard([resp.response.board])
            setSchoolDetail(resp.response)
        }
        setIsLoading(false)
    }

    const openModal = () => {
        history.push({pathname: "/backend/backend-details/edit-nonWisr-school", state: {isEdit: true, _id: schoolDetail._id}})
    }
    const openDeleteModal = () => {
        setShowSchoolDeleteModal(true)
        setOverlay(true)
    }
    const deleteNonWisrSchool = async(confirm) => {
        if(confirm){
            let resp = await context.apis[context.user.role].deleteNonWisrSchool(
                context.validator, {_id: schoolId}
            )
            console.log("deleteNonWisrSchool", resp)
            if(resp && resp.done){
                toast.success("school deleted successfully.")
                history.push('/backend/backend-details/nonWisr-school-list')
            }
        }
        setOverlay(false)
        setShowSchoolDeleteModal(false)
    }
    return (
        <>
            <div className={`ovelap ${overlay === true ? "active" : ""}`}></div>
            {isLoading ? 
                <Loader />
                :
                <div className="col-xl-10 col-lg-12 col-md-12 col-xs-12">
                    <div className="rightPart noBack">
                        <div className="row">
                            <BackendTopBar redirectPage={true} from="non-wisr-school-detail" />
                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-10">
                                <div className="inventory-overHead my-school-features">
                                    <p>school name</p>
                                    <h3 className="htx4 clearfix">{schoolDetail && schoolDetail.schoolName && schoolDetail.schoolName}{(context.user.role === "backendAdmin" || context.user.role === "backendOperations" || context.user.role === "backendTech" || context.user.role === "backendFOS") && <><a className="editSchool" style={{ cursor: "pointer" }} onClick={openModal}><img src={proEdit} /> Edit</a><a className="editSchool" style={{ cursor: "pointer" }} onClick={openDeleteModal}><i className="fa fa-trash" style={{cursor: "pointer", marginLeft: "10px"}}></i> Delete</a></>}</h3>
                                    {/* <ul>
                                        <li>{schoolDetail && schoolDetail._id}</li>
                                        <li>{schoolLang.length !== 0 && schoolLang.map((language, index) => (
                                            index == schoolLang.length - 2 ? `${language} & ` : index == schoolLang.length - 1 ? language : `${language}, `
                                        ))} Medium
                                        </li>
                                        <li>{schoolBoard && Array.isArray(schoolBoard) && schoolBoard.length !== 0 && schoolBoard.map((board, index) => (
                                            index == schoolBoard.length - 2 ? `${readAbleBoard(board)} & ` : index == schoolBoard.length - 1 ? readAbleBoard(board) : `${readAbleBoard(board)}, `
                                        ))} Board
                                        </li>
                                        <li>Cat {schoolDetail && schoolDetail.category && schoolDetail.category}</li>
                                        <li>{schoolDetail && schoolDetail.totalStudents && schoolDetail.totalStudents} Students</li>
                                        <li> {schoolDetail && schoolDetail.builtUpArea && schoolDetail.builtUpArea} Sq Ft. School area</li>
                                        <li> {`${address && address}, ${localArea && localArea}, ${city && city}, ${pincode && pincode}, ${state && state}`}</li>
                                    </ul> */}
                                </div>
                            </div>
                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-10 mb-10">
                                <div className="line-full"></div>
                            </div>
                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-10">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                    <div className="forminpt">
                                        <div className="row">
                                            <div className="col-xl-3 col-lg-3 col-md-3 col-xs-12">
                                                School Name: 
                                            </div>
                                            <div className="col-xl-9 col-lg-9 col-md-9 col-xs-12" style={{color: "#444"}}>
                                                {(schoolDetail && schoolDetail.schoolName && returnReadableStatus(schoolDetail.schoolName)) || "-"}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="forminpt">
                                        <div className="row">
                                            <div className="col-xl-3 col-lg-3 col-md-3 col-xs-12">
                                                Board: 
                                            </div>
                                            <div className="col-xl-9 col-lg-9 col-md-9 col-xs-12" style={{color: "#444"}}>
                                                {(schoolDetail && schoolDetail.board && readAbleBoard(schoolDetail.board)) || "-"}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="forminpt">
                                        <div className="row">
                                            <div className="col-xl-3 col-lg-3 col-md-3 col-xs-12">
                                                Language of Study: 
                                            </div>
                                            <div className="col-xl-9 col-lg-9 col-md-9 col-xs-12" style={{color: "#444"}}>
                                                {(schoolDetail && schoolDetail.language && titleCase(schoolDetail.language)) || "-"}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="forminpt">
                                        <div className="row">
                                            <div className="col-xl-3 col-lg-3 col-md-3 col-xs-12">
                                                No of Boys: 
                                            </div>
                                            <div className="col-xl-9 col-lg-9 col-md-9 col-xs-12" style={{color: "#444"}}>
                                                {schoolDetail && schoolDetail.totalBoys || "-"}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="forminpt">
                                        <div className="row">
                                            <div className="col-xl-3 col-lg-3 col-md-3 col-xs-12">
                                                No of Girls: 
                                            </div>
                                            <div className="col-xl-9 col-lg-9 col-md-9 col-xs-12" style={{color: "#444"}}>
                                                {schoolDetail && schoolDetail.totalGirls || "-"}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="forminpt">
                                        <div className="row">
                                            <div className="col-xl-3 col-lg-3 col-md-3 col-xs-12">
                                                Total Students: 
                                            </div>
                                            <div className="col-xl-9 col-lg-9 col-md-9 col-xs-12" style={{color: "#444"}}>
                                                {schoolDetail && schoolDetail.totalReach || "-"}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="forminpt">
                                        <div className="row">
                                            <div className="col-xl-3 col-lg-3 col-md-3 col-xs-12">
                                                State: 
                                            </div>
                                            <div className="col-xl-9 col-lg-9 col-md-9 col-xs-12" style={{color: "#444"}}>
                                                {(schoolDetail && schoolDetail.state && titleCase(schoolDetail.state)) || "-"}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="forminpt">
                                        <div className="row">
                                            <div className="col-xl-3 col-lg-3 col-md-3 col-xs-12">
                                                City: 
                                            </div>
                                            <div className="col-xl-9 col-lg-9 col-md-9 col-xs-12" style={{color: "#444"}}>
                                                {(schoolDetail && schoolDetail.city && titleCase(schoolDetail.city)) || "-"}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="forminpt">
                                        <div className="row">
                                            <div className="col-xl-3 col-lg-3 col-md-3 col-xs-12">
                                                Address: 
                                            </div>
                                            <div className="col-xl-9 col-lg-9 col-md-9 col-xs-12" style={{color: "#444"}}>
                                                {(schoolDetail && schoolDetail.address) || "-"}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="forminpt">
                                        <div className="row">
                                            <div className="col-xl-3 col-lg-3 col-md-3 col-xs-12">
                                                Pincode: 
                                            </div>
                                            <div className="col-xl-9 col-lg-9 col-md-9 col-xs-12" style={{color: "#444"}}>
                                                {(schoolDetail && schoolDetail.pincode) || "-"}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="forminpt">
                                        <div className="row">
                                            <div className="col-xl-3 col-lg-3 col-md-3 col-xs-12">
                                                Contact Email: 
                                            </div>
                                            <div className="col-xl-9 col-lg-9 col-md-9 col-xs-12" style={{color: "#444"}}>
                                                {(schoolDetail && schoolDetail.contactEmail) || "-"}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="forminpt">
                                        <div className="row">
                                            <div className="col-xl-3 col-lg-3 col-md-3 col-xs-12">
                                                Contact Number: 
                                            </div>
                                            <div className="col-xl-9 col-lg-9 col-md-9 col-xs-12" style={{color: "#444"}}>
                                                {(schoolDetail && schoolDetail.contactNumber) || "-"}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="forminpt">
                                        <div className="row">
                                            <div className="col-xl-3 col-lg-3 col-md-3 col-xs-12">
                                                Name: 
                                            </div>
                                            <div className="col-xl-9 col-lg-9 col-md-9 col-xs-12" style={{color: "#444"}}>
                                                {(schoolDetail && schoolDetail.contactName && returnReadableStatus(schoolDetail.contactName)) || "-"}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="forminpt">
                                        <div className="row">
                                            <div className="col-xl-3 col-lg-3 col-md-3 col-xs-12">
                                                Designation: 
                                            </div>
                                            <div className="col-xl-9 col-lg-9 col-md-9 col-xs-12" style={{color: "#444"}}>
                                                {(schoolDetail && schoolDetail.designation && (schoolDetail.designation === "schoolPrincipal" ? "Principal/Trustee" : schoolDetail.designation === "schoolAdmin" ? "Admin" : returnReadableStatus(schoolDetail.designation))) || "-"}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="forminpt">
                                        <div className="row">
                                            <div className="col-xl-3 col-lg-3 col-md-3 col-xs-12">
                                                Comments: 
                                            </div>
                                            <div className="col-xl-9 col-lg-9 col-md-9 col-xs-12" style={{color: "#444"}}>
                                                {(schoolDetail && schoolDetail.commentsOrInventoryDetails) || "-"}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <ConfirmDialog
                confirmTitle="Do you want to delete this school ?"
                confirmText="Yes"
                denyText="No"
                onModalClose={deleteNonWisrSchool}
                togglePopup={showSchoolDeleteModal}
            />
        </>
    )
}

export default NonWisrSchoolDetails