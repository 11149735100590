import { useState, useContext, useRef, useEffect } from "react"

import { validation } from "../../../service/validators"

import TextFieldWithIcon from "../../../components/formFields/textfieldWithIcon"

import loginWave from "../../../assets/images/login-wave.svg"
import envelope from "../../../assets/images/envolpe.svg"
import logo from "../../../assets/images/logo.png"
import AppContextObject from "../../../common/context/common"
import toast from "react-hot-toast"
import SimpleReactValidator from "simple-react-validator"
import errorSVG from "../../../assets/images/error.svg";
import username from "../../../assets/images/user-icon.svg"
import DataContext from "../../../service/contextProvider"
import { useHistory } from "react-router-dom"

const ForgotPassword = () => {
    const history = useHistory()
    const context = useContext(AppContextObject)
    let [formErrors, setFormErrors] = useState<any>({})
    const [emailError, setEmailError] = useState(false)
    const [data, setData] = useState({
        username: ""
    })
    const [update, forceUpdate] = useState(false)
    const [usernameError, setUsernameError] = useState(false)
    const [usernameErrMsg, setUsernameErrMsg] = useState("The username entered does not exist. Please enter correct username.")
    const [formValidator] = useState(useRef(new SimpleReactValidator({
        element: (message, className) =>
            <span className="valError"><img src={errorSVG} />{message}</span>
    })))

    const parentData = useContext<any>(DataContext)
    const [inProgressUsername, setInProgressUsername] = useState(null);
    useEffect(() => {
        sessionStorage.removeItem("otpVerified")
        if(sessionStorage.getItem("forgotUsername")) {
            let temp = JSON.parse(sessionStorage.getItem("forgotUsername"))
            setData({
                ...data,
                username: temp.username
            })
        }
    }, [])

    const onFormDataChange = (event) => {
        setData({
            ...data,
            [event.target.id] : event.target.value
        })
        if (event.target.id === "username") {
            setUsernameError(false)
            setData({
                ...data,
                username: event.target.value.toLowerCase()
            })
        }
    }
    const onSubmit = async () => {
        const result = formValidator.current.allValid()
        if (result === false) {
            formValidator.current.showMessages()
            forceUpdate(true)
            return
        } else {
            formValidator.current.hideMessages()
            forceUpdate(!update)
        }
        setInProgressUsername(true)
        let resp = await context.apis.public.findUserByUsername(
            context.validator, { "username": data.username }
        )
        console.log("resp.response", resp.response, "parentData", parentData)
        if (resp && resp.done === false) {
            let found = resp.response.filter(element => element._id !== parentData.data.userId);
            if (found.length > 0) {
                
                // let resetPassResp = await context.apis.public.sendResetPasswordLinkOnEmail(
                //         context.validator, {"email": data.email}
                //     )
                // if(resetPassResp && resetPassResp.done){
                //     setEmailError(false)
                //     toast.success("Reset password link is sent to your email address")
                // }else{
                //     setEmailError(true)
                // }
                //block unverified user
                if(resp.response && resp.response.length > 0 && resp.response[0].isVerified === false){
                    setInProgressUsername(false)
                    formValidator.current.showMessageFor("username")
                    setUsernameError(true)
                    setUsernameErrMsg("You can only Resend OTP from a Verified Account")
                }
                else if(resp.response && resp.response.length > 0 && resp.response[0].school && resp.response[0].initialApproval == false){
                    setInProgressUsername(false)
                    formValidator.current.showMessageFor("username")
                    setUsernameError(true)
                    setUsernameErrMsg("School review is under process. You would be able to reset password once your school is approved.")
                }
                else{
                    let respOtp = await context.apis.public.reSendOTPOnEmail(
                        context.validator, {"username":data.username}
                    )
                    console.log("email otp resp", respOtp)
                    if(respOtp && respOtp.done) {
                        console.log("email otp respOtp", respOtp.generatedOtp)
                        history.push({
                            pathname: '/enter-otp',
                            state: {
                                username :data.username
                            },
                        });
                    }
                    let obj = {otpVerified: false}
                    sessionStorage.setItem("otpVerified", JSON.stringify(obj))
                    let obj1 ={username: data.username}
                    sessionStorage.setItem("forgotUsername", JSON.stringify(obj1))
                    return
                }
            }
            else {
                if(!parentData.data.userId){
                    if(resp.response && resp.response.length > 0 && resp.response[0].isVerified === false){
                        setInProgressUsername(false)
                        formValidator.current.showMessageFor("username")
                        setUsernameError(true)
                        setUsernameErrMsg("You can only Resend OTP from an Verified Account")
                    }
                    else if(resp.response && resp.response.length > 0 && resp.response[0].school && resp.response[0].initialApproval == false){
                        setInProgressUsername(false)
                        formValidator.current.showMessageFor("username")
                        setUsernameError(true)
                        setUsernameErrMsg("School review is under process. You would be able to reset password once your school is approved.")
                    }
                    else{
                        let respOtp = await context.apis.public.reSendOTPOnEmail(
                            context.validator, {"username":data.username}
                        )
                        console.log("email otp resp", respOtp)
                        if(respOtp && respOtp.done) {
                            console.log("email otp respOtp", respOtp.generatedOtp)
                        history.push({
                            pathname: '/enter-otp',
                            state: {
                                username :data.username
                            },
                          });
                        }
                        return
                    }

                }else{
                    setInProgressUsername(false)
                    formValidator.current.showMessageFor("username")
                    setUsernameError(true)
                    setUsernameErrMsg("The username entered does not exist. Please enter correct username.")
                }
                
            }
        }
        else {
            setInProgressUsername(false)
            formValidator.current.showMessageFor("username")
            setUsernameError(true)
            setUsernameErrMsg("The username entered does not exist. Please enter correct username.")
        }

    }
    let error = ""
    formValidator.current.rules['user_name'] = {
        message: error === "required" ? 'The user name field is required.' : usernameError ? usernameErrMsg : "" ,
        rule: (val) => {
            let returnValue = true
            if (!val || val.trim() === "") {
                error = "required"
                returnValue = false
            }
            // const chars = "!#$%^&*()-{}[]+=<>?/,~'`"
            // for (let i = 0; i < val.split("").length; i++) {
            //     const v = val.split("")[i]
            //     if (!v || v.trim() === " " || chars.split("").find(ch => ch === v)) {
            //         error = ""
            //         returnValue = false
            //         break
            //     }
            // }
            if (usernameError) {
                error = ""
                returnValue = false
            }

            if (usernameError === null) {
                error = ""
                returnValue = false
            }

            if (usernameError === undefined) {
                error = ""
                returnValue = false
            }

            return returnValue
        },
    }

    const handleOnKeyPress = (event) => {
        if (event.key.toLowerCase() === "enter") {
          const form = event.target.form;
          const index = [...form].indexOf(event.target);
          form.elements[index + 1].focus();
          event.preventDefault();
          if(event.target.id === "username")
          {
            onSubmit();
          }
        }
    }

    return (
        <>
            <div className="ovelap"></div>
            <div className="main-wapper">
                <div className="loginbox">
                    <div className="login-wave"> <img src={loginWave} alt="login-wave" /> </div>
                    <div className="login-form">
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb10">
                                <img src={logo} alt="logo" />
                                <h2 className="htx1">Forgot Password?</h2>
                            </div>
                        </div>
                        <form>
                            <div className="row">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                    {/* <TextFieldWithIcon
                                        label="Email ID"
                                        placeholder="abhi@wisr.com"
                                        isRequired={true}
                                        type="text"
                                        icon={envelope}
                                        formData={data}
                                        formKey="email"
                                        onFormDataChange={onFormDataChange}
                                        formErrors={formErrors}
                                        forgotPasswordEmailError={emailError}
                                    /> */}
                                     <TextFieldWithIcon
                                        label='Enter your username'
                                        placeholder='Username'
                                        isRequired={false}
                                        icon={username}
                                        formErrors={formValidator}
                                        formData={data}
                                        formKey="username"
                                        validation="required|user_name"
                                        onFormDataChange={onFormDataChange}
                                        maxLength={16}
                                        onKeyPress={(event) => handleOnKeyPress(event)}
                                        autoFocus={true}
                                        inProgress={inProgressUsername}
                                        onlyProgressNoVerify={true}
                                    />
                                    
                                </div>
                                <div className="row col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                    <div className="forminpt">
                                        <input
                                            onClick={onSubmit}
                                            name="Login"
                                            value="Continue"
                                            className="btn btn-orange datasubmit"
                                            type="button"
                                        />
                                    </div>
                                </div>
                                <p className="centerText"><a style={{cursor: "pointer", color: "rgb(254, 110, 0)"}} onClick={() => history.push("/login")}>Login</a></p>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ForgotPassword
