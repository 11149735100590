
const DashboardSVG = () => {

    return (
        <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="1" y="1.5" width="6.24373" height="3.85449" rx="1" stroke="#070707" strokeWidth="1.2"/>
            <rect x="9.70712" y="12.6455" width="6.24373" height="3.85449" rx="1" stroke="#070707" strokeWidth="1.2"/>
            <rect x="1" y="7.81577" width="6.24373" height="8.6842" rx="1" stroke="#070707" strokeWidth="1.2"/>
            <rect x="9.70712" y="1.5" width="6.24373" height="8.6842" rx="1" stroke="#070707" strokeWidth="1.2"/>
        </svg>
    )
}

export default DashboardSVG
