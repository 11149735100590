import React, { useState, useEffect, useContext } from 'react'

import ImageModal from '../../../components/addon/imageModal/imageModal'
import ImagePreview from '../../../components/addon/imagePreview'
import { PublicContextInitiator } from "../../apis/publicContextInitiator"
import AppContextObject from "../../../common/context/common"
// import exmapleImage from "../../../assets/images/"
import checkSign from "../../../assets/images/smallSignedCheck.svg"
import sendButton from "../../../assets/images/commentSendButton.svg"
import approved from "../../../assets/images/Approved.svg"
import Compress from "compress.js"
import { objectDeepClone } from '../../../service/helper'
import ConfirmDialog from '../../../components/addon/confirmDialog/confirmDialog'
import moment from 'moment'
import { Carousel } from "react-bootstrap";

const CampaignPreviewFosCarousel = (props) => {
    const {
        setShowRetakeModal,
        activeImageIndex,
        setActiveImageIndex,
        activeSetIndex,
        activeInventoryIndex,
        formData,
        setFormData,
        onDelete,
        onSave,
        uploadView,
        setUploadView,
        showImagePreview
    } = props
    // const [index, setIndex] = useState(0)
    const [overlay, setOverlay] = useState(false)
    const context = useContext(AppContextObject)

    const handleSelect = (selectedIndex, e) => {
        setActiveImageIndex(selectedIndex)
    }

    return (
        <>
            <div className='crFosImageView'>
                <Carousel
                    activeIndex={activeImageIndex}
                    onSelect={handleSelect}
                    interval={null}
                >
                    {formData[activeSetIndex].inventories ?
                        formData[activeSetIndex].inventories[activeInventoryIndex].images.map((videoObj, currentIndex) => {
                            return (
                                <Carousel.Item key={currentIndex}>
                                    <ExampleImage
                                        setShowRetakeModal={setShowRetakeModal}
                                        showImagePreview={showImagePreview}
                                        activeImageIndex={activeImageIndex}
                                        setActiveImageIndex={setActiveImageIndex}
                                        activeSetIndex={activeSetIndex}
                                        activeInventoryIndex={activeInventoryIndex}
                                        formData={formData}
                                        setFormData={setFormData}
                                        onDelete={onDelete}
                                        onSave={onSave}
                                        uploadView={uploadView}
                                        setUploadView={setUploadView}
                                        currentImage={formData[activeSetIndex].inventories[activeInventoryIndex].images[currentIndex]}
                                        currentInventory={formData[activeSetIndex].inventories[activeInventoryIndex]}
                                    />
                                </Carousel.Item>
                            );
                        })
                        :
                        formData[activeSetIndex].events[activeInventoryIndex].images.map((videoObj, currentIndex) => {
                            return (
                                <Carousel.Item key={currentIndex}>
                                    <ExampleImage
                                        setShowRetakeModal={setShowRetakeModal}
                                        showImagePreview={showImagePreview}
                                        activeImageIndex={activeImageIndex}
                                        setActiveImageIndex={setActiveImageIndex}
                                        activeSetIndex={activeSetIndex}
                                        activeInventoryIndex={activeInventoryIndex}
                                        formData={formData}
                                        setFormData={setFormData}
                                        onDelete={onDelete}
                                        onSave={onSave}
                                        uploadView={uploadView}
                                        setUploadView={setUploadView}
                                        currentImage={formData[activeSetIndex].events[activeInventoryIndex].images[currentIndex]}
                                        currentInventory={formData[activeSetIndex].events[activeInventoryIndex]}
                                    />
                                </Carousel.Item>
                            );
                        })
                    }
                </Carousel>
            </div>
        </>
    );
};

export default CampaignPreviewFosCarousel

const ExampleImage = ({ activeSetIndex, setActiveImageIndex, activeInventoryIndex, activeImageIndex, formData, setFormData, onSave, setShowRetakeModal, uploadView, setUploadView, onDelete, currentImage, currentInventory, showImagePreview }) => {
    const context = useContext(AppContextObject)
    const compress = new Compress()
    const [refetch, setRefetch] = useState(false)
    const [allComments, setAllComments] = useState([])
    const [newComment, setNewComment] = useState("")
    const [savingComment, setSavingComment] = useState(false)
    const [showDeletePopup, setShowDeletePopup] = useState(false)
    const [overlay, setOverlay] = useState(false)
    const [commentLoading, setCommentLoading] = useState(false)

    useEffect(() => {
        if (formData[activeSetIndex].inventories) {
            if (formData[activeSetIndex].inventories[activeInventoryIndex].images[activeImageIndex]._id) {
                getAllComments()
            }
        }
        else {
            if (formData[activeSetIndex].events[activeInventoryIndex].images[activeImageIndex]._id) {
                getAllComments()
            }
        }
    }, [refetch, showImagePreview, activeImageIndex])

    const getAllComments = async () => {
        setCommentLoading(true)
        if (formData[activeSetIndex].inventories) {
            let resp = await context.apis[context.user.role].getCommentsForCampaignReport(
                context.validator, { imageId: formData[activeSetIndex].inventories[activeInventoryIndex].images[activeImageIndex]._id }, {}
            )
            console.log("getCommentsForCampaignReport", resp)
            if (resp && resp.done) {
                setAllComments([...resp.response.rows])
            }
        }
        else {
            let resp = await context.apis[context.user.role].getCommentsForCampaignReport(
                context.validator, { imageId: formData[activeSetIndex].events[activeInventoryIndex].images[activeImageIndex]._id }, {}
            )
            console.log("getCommentsForCampaignReport", resp)
            if (resp && resp.done) {
                setAllComments([...resp.response.rows])
            }
        }
        setCommentLoading(false)
    }

    const formateFileName = (name, postFix) => {
        let array = name.split(".")
        let newStr = ''
        array.forEach((item, index) => {
            if (index < array.length - 2) {
                newStr += item
            }
            else if (index === array.length - 2) {
                newStr += item + postFix
            }
            else if (index === array.length - 1) {
                newStr += '.' + item
            }
        })
        return newStr
    }
    const handleUpload = async (event, files, events?) => {
        const formData1 = new FormData();
        // for (let i = 0; i < files.length; i++) {
        if (!files[0].type.startsWith("image/")) {
            event.target.value = null;
            return
        }
        for (let i = 0; i < 3; i++) {
            const resizedImage = await compress.compress([files[0]], {
                size: 20, // the max size in MB, defaults to 2MB
                quality: i === 0 ? 0.4 : i === 1 ? 0.2 : 0.1, // the quality of the image, max is 1,
                resize: false // defaults to true, set false if you do not want to resize the image width and height
            })
            const img = resizedImage[0];
            const base64str = img.data
            const imgExt = img.ext
            let fileName = (i === 0 ? img.alt : i === 1 ? formateFileName(img.alt, "_mt") : formateFileName(img.alt, "_st"))
            let compressedFile = Compress.convertBase64ToFile(base64str, imgExt);
            formData1.append(`image`, compressedFile, fileName);
        }
        // }
        event.target.value = null
        let reqBody = formData1
        const requestOptions = {
            method: 'POST',
            body: reqBody
        };

        let publicContextInitiator = new PublicContextInitiator(context)
        fetch('/api/uploadFiles', requestOptions)
            .then(response => {
                response.json()
                    .then(res => {
                        let data = [];
                        res["response"].map(async item => {
                            data.push(item.Key)
                        })
                        publicContextInitiator.getUploadedImagesSignedUrl(
                            context.validator, { keys: data }
                        ).then(res => {
                            let updateImageObj = []
                            // if ((formData[activeSetIndex].inventories[activeInventoryIndex].months || formData[activeSetIndex].inventories[activeInventoryIndex].venue)) {
                            // updateImageObj = [...formData.placements[index].images]
                            // }
                            // else {
                            updateImageObj = [...formData[activeSetIndex].inventories[activeInventoryIndex].images]
                            updateImageObj.splice(activeImageIndex, 1, { isRetake: true, uploadDate: moment().format("DD-MM-YYYY"), uploadTime: moment().format("HH:mm:ss"), image: !(res.response[0].key.includes('_mt') || res.response[0].key.includes('_st')) ? res.response[0].image : !(res.response[1].key.includes('_mt') || res.response[1].key.includes('_st')) ? res.response[1].image : res.response[2].image, key: !(res.response[0].key.includes('_mt') || res.response[0].key.includes('_st')) ? res.response[0].key : !(res.response[1].key.includes('_mt') || res.response[1].key.includes('_st')) ? res.response[1].key : res.response[2].key, imageMt: res.response[0].key.includes("_mt") ? res.response[0].key : res.response[1].key.includes("_mt") ? res.response[1].key : res.response[2].key, imageSt: res.response[0].key.includes("_st") ? res.response[0].key : res.response[1].key.includes("_st") ? res.response[1].key : res.response[2].key, fosRemarks: "", salesRemarks: "", approvalStatus: "pending" })
                            // }
                            // let requests = res.response.map(item => {
                            // updateImageObj.push({ image: !(res.response[0].key.includes('_mt') || res.response[0].key.includes('_st')) ? res.response[0].image : !(res.response[1].key.includes('_mt') || res.response[1].key.includes('_st')) ? res.response[1].image : res.response[2].image, key: !(res.response[0].key.includes('_mt') || res.response[0].key.includes('_st')) ? res.response[0].key : !(res.response[1].key.includes('_mt') || res.response[1].key.includes('_st')) ? res.response[1].key : res.response[2].key, imageMt: res.response[0].key.includes("_mt") ? res.response[0].key : res.response[1].key.includes("_mt") ? res.response[1].key : res.response[2].key, imageSt: res.response[0].key.includes("_st") ? res.response[0].key : res.response[1].key.includes("_st") ? res.response[1].key : res.response[2].key, fosRemarks: "", salesRemarks: "", approvalStatus: "pending" });
                            // if ((formData[activeSetIndex].inventories[activeInventoryIndex].months || formData[activeSetIndex].inventories[activeInventoryIndex].venue)) {
                            //     // let placementObj = [...formData.placements]
                            //     // placementObj[index]["images"] = [...updateImageObj]
                            //     // setFormData({...formData, placements: [...placementObj]})
                            // }
                            // else {
                            console.log("kjnbvcdfghjklkjhgf", formData, activeSetIndex, activeInventoryIndex)
                            let newFormData = objectDeepClone(formData)
                            let selectedSet = objectDeepClone(formData[activeSetIndex])
                            let selectedInventory = !events ? objectDeepClone(formData[activeSetIndex].inventories[activeInventoryIndex]) : objectDeepClone(formData[activeSetIndex].events[activeInventoryIndex])
                            selectedInventory.images = [...updateImageObj]
                            !events ? selectedSet.inventories.splice(activeInventoryIndex, 1, selectedInventory) : selectedSet.events.splice(activeInventoryIndex, 1, selectedInventory)
                            newFormData.splice(activeSetIndex, 1, selectedSet)
                            setUploadView(true)
                            // let apiData = formateData(newFormData, activeSetIndex)
                            // console.log("checkafter", newFormData, apiData)
                            // uploadSet(newFormData, apiData)
                            // setActiveImageIndex(newFormData[activeSetIndex].inventories[activeInventoryIndex].images.length - 1)
                            setFormData([...newFormData])
                            // setShowRetakeModal(true)
                            // setOverlay(true)
                            // console.log("bvbnm,lkjhg", apiData)
                            // setFormData([...newFormData])
                            // }
                            // let placementObj = [...formData.placements]
                            // placementObj[index]["images"] = [...updateImageObj]
                            // setFormData({...formData, images: [...updateImageObj]})
                            // })
                            // Promise.all(requests).then(() => {
                            //     // setIsImageLoading(false)
                            //     // setLoadingImgIndex(-1)
                            // });
                        })
                    })
            }
            );
    }

    const deleteImage = () => {
        let newFormData = objectDeepClone(formData)
        let selectedSet = objectDeepClone(formData[activeSetIndex])
        let selectedInventory = formData[activeSetIndex].inventories ? objectDeepClone(formData[activeSetIndex].inventories[activeInventoryIndex]) : objectDeepClone(formData[activeSetIndex].events[activeInventoryIndex])
        selectedInventory.images.splice(activeImageIndex, 1)
        formData[activeSetIndex].inventories ? selectedSet.inventories.splice(activeInventoryIndex, 1, selectedInventory) : selectedSet.events.splice(activeInventoryIndex, 1, selectedInventory)
        newFormData.splice(activeSetIndex, 1, selectedSet)
        if (!uploadView) {
            onDelete(newFormData, activeInventoryIndex)
        }
        else {
            setFormData([...newFormData])
        }
        setShowRetakeModal(false)
    }

    const addComment = (e, data?) => {
        let newFormData = objectDeepClone(formData)
        let selectedSet = objectDeepClone(formData[activeSetIndex])
        let selectedInventory = !data ? objectDeepClone(formData[activeSetIndex].inventories[activeInventoryIndex]) : objectDeepClone(formData[activeSetIndex].events[activeInventoryIndex])
        let selectedImage = !data ? objectDeepClone(formData[activeSetIndex].inventories[activeInventoryIndex].images[activeImageIndex]) : objectDeepClone(formData[activeSetIndex].events[activeInventoryIndex].images[activeImageIndex])
        if (context.user.role === "backendFOS") {
            selectedImage.fosRemarks = e.target.value
        }
        else {
            selectedImage.salesRemarks = e.target.value
        }
        selectedInventory.images.splice(activeImageIndex, 1, selectedImage)
        !data ? selectedSet.inventories.splice(activeInventoryIndex, 1, selectedInventory) : selectedSet.events.splice(activeInventoryIndex, 1, selectedInventory)
        newFormData.splice(activeSetIndex, 1, selectedSet)
        setFormData([...newFormData])
    }
    const addNewComment = async (data?) => {
        if (newComment && newComment !== "") {
            setSavingComment(true)
            let imageId = data ? formData[activeSetIndex].events[activeInventoryIndex].images[activeImageIndex]._id ? formData[activeSetIndex].events[activeInventoryIndex].images[activeImageIndex]._id : formData[activeSetIndex].events[activeInventoryIndex].images[activeImageIndex].key : formData[activeSetIndex].inventories[activeInventoryIndex].images[activeImageIndex]._id ? formData[activeSetIndex].inventories[activeInventoryIndex].images[activeImageIndex]._id : formData[activeSetIndex].inventories[activeInventoryIndex].images[activeImageIndex].key
            let resp = await context.apis[context.user.role].addCommentForCampaignReport(
                context.validator, { setId: formData[activeSetIndex]._id, imageId: imageId, comment: newComment, userRole: "backendFOS", commentBy: context.user.fullName }
            )
            console.log("addCommentForCampaignReport", resp)
            if (resp && resp.done) {
                setNewComment("")
                setRefetch(!refetch)
            }
            setSavingComment(false)
        }
    }
    const handleDelete = (confirm) => {
        if (confirm) {
            deleteImage()
        }
        setShowDeletePopup(false)
        setOverlay(false)
    }

    const handleSave = async () => {
        onSave(newComment, "imageUploaded")
    }
    return (
        <>
            <div className={`ovelap ${overlay ? "active" : ""}`}></div>
            <div className="" style={{ width: "100%" }}>

                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12" style={{ padding: '0' }}>
                    <div id="closePopup" className="" style={{ padding: "15px", background: "#000000", width: "100% !important", borderRadius: "0" }}>
                        {/* <div className="popup-header">
                            <span onClick={() => setShowRetakeModal(false)} className="close toggle" data-target="closePopup">close</span>
                        </div> */}
                        <div className="flex-container1" style={{ display: "flex", justifyContent: "space-between" }}>
                            <h4 style={{ color: "#ffffff", fontFamily: "nexaxbold", fontSize: "20px" }}> {formData[activeSetIndex].inventories ? formData[activeSetIndex].inventories[activeInventoryIndex].name : formData[activeSetIndex].events[activeInventoryIndex].name} </h4>
                            {
                                currentImage?.approvalStatus === "pending" ?
                                    <img onClick={() => { setOverlay(true); setShowDeletePopup(true) }} style={{ height: "20px", filter: "brightness(100%)" }} src="/assets/FOSimg/delete.png" alt="Delete" />
                                    :
                                    currentImage?.approvalStatus === "rejected" ?
                                        <>
                                            <img style={{ height: "20px", filter: "brightness(100%)", marginLeft: "auto", paddingRight: "5px" }} src="/assets/FOSimg/close.png" alt="Close" />
                                            <img style={{ height: "20px", filter: "brightness(100%)" }} src="/assets/FOSimg/rejected.png" alt="Rejected" />
                                        </>
                                        :
                                        currentImage?.approvalStatus === "approved" &&
                                        <>
                                            <img style={{ height: "20px", filter: "brightness(100%)", marginLeft: "auto", paddingRight: "5px" }} src={checkSign} alt="Close" />
                                            <img style={{ height: "20px", filter: "brightness(100%)" }} src={approved} alt="Approved" />
                                        </>
                            }
                        </div>



                        <div className="popup-header">

                            {/* <span onClick={() => onModalClose()} className="close toggle" data-target="closePopup">close</span> */}
                        </div>
                        <div className="popup-body">

                            <div className="clearfix">

                                <div className="" style={{height: "350px", width: "300px", position: "relative", margin:"auto"}}>
                                    <div className="">
                                        <img src={currentImage.image} className="upload-campaignImg" style={{maxHeight: "100%", maxWidth: "100%", width: "auto", height: "auto", position: "absolute", top: "0", bottom: "0", left: "0", right: "0", margin: "auto"}}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div className="cl-row">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="ws-container mb-20" style={{ padding: "10px 0" }}>
                                    {commentLoading ?
                                    ""
                                    :
                                    allComments && allComments.length > 0 && allComments.map(item => (
                                        <div className={item.userRole === "backendFOS" ? "commentRight" : "commentLeft"} style={item.userRole === "backendFOS" ? { backgroundColor: "#F8F8F8", marginTop: "20px", marginBottom: "20px", marginLeft: "auto", paddingLeft: "10px", paddingTop: "10px", width: "300px", height: "80px", borderRadius: "5px" } : { backgroundColor: "#F8F8F8", width: "300px", height: "80px", marginTop: "20px", marginBottom: "20px", paddingLeft: "10px", paddingTop: "10px", borderRadius: "5px" }}>
                                            <div style={{ display: "flex" }}>
                                                <div>
                                                    <div style={{ display: "flex" }}>
                                                        <h1 style={{ fontSize: "18px", fontFamily: "nexaxbold", color: "#070707", paddingRight: "5px" }}> {item.commentBy}  </h1>
                                                        <span style={{ fontSize: "12px", paddingTop: "5px", color: "#8C8C8C" }}>{moment(item.createdOn).fromNow()}</span>
                                                    </div>


                                                </div>
                                                {/* <div style={{ width: "5%", marginRight: "10px" }}>
                                                    <img style={{ height: "18px", filter: "brightness(100%)" }} src="/assets/FOSimg/delete.png" alt="Delete" />
                                                </div> */}
                                            </div>
                                            <p style={{ paddingRight: "25px" }}>
                                                {item.comment}
                                            </p>
                                        </div>
                                    ))}
                                    <span style={{ position: "relative" }}>
                                        {
                                            currentImage.approvalStatus !== "approved" && <textarea style={{ height: "100px", position: 'relative' }} name="retakeImage" placeholder="Add Comment" value={newComment} onChange={(e) => setNewComment(e.target.value)} />
                                        }
                                        {!uploadView ?
                                            currentImage.approvalStatus !== "approved" && <img onClick={() => addNewComment()} style={{ width: "30px", height: "auto", position: "absolute", right: "2%", bottom: "80%", float: "right" }} src={sendButton} />
                                            :
                                            ""
                                        }
                                    </span>
                                    {!uploadView ?
                                        currentImage.approvalStatus !== "approved" &&
                                        <div className="forminpt">
                                            <div className="image uploadImgDesign" style={{ width: '100%', marginTop: "6px" }}>
                                                <label className="uploadImgPlus retake" style={{ backgroundColor: "#FE6E02", color: "#FFFFFF" }}>RETAKE IMAGE</label>
                                                <input name="retakeImageButton" capture="environment" type="file" accept="image/*" title="" onChange={(e) => handleUpload(e, e.target.files)} />
                                            </div>
                                        </div>
                                        :
                                        ""
                                    }
                                </div>
                            </div>
                        </div>
                        {/* {uploadView ?
                            currentImage?.approvalStatus !== "approved" &&
                            <div className="cl-row">
                                <div className="col-xs-6 col-sm-6 col-md-12 col-lg-12" style={{ paddingRight: "4px" }}>
                                    <div className="forminpt">
                                        <input onClick={uploadView ? handleSave : onSave} name="Finish" style={{ fontSize: "16px", fontFamily: "nexaxbold" }} className="btn btn-orange comp-seting" type="button" value={context.user.role === "backendFOS" ? "Save" : "Approve"} />
                                    </div>
                                </div>

                                <div className="col-xs-6 col-sm-6 col-md-12 col-lg-12" style={{ paddingLeft: "4px" }}>
                                    <div className="forminpt" style={{ paddingLeft: "5px" }}>

                                        <div className="image uploadImgDesign" style={{ width: '100%' }}>
                                            <label className="uploadImgPlus retake" style={{}}>RETAKE</label>
                                            <input name="amenityImages" capture="environment" type="file" accept="image/*" title="" onChange={(e) => handleUpload(e, e.target.files)} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            ""
                        } */}
                    </div>
                </div>
            </div>
            <ConfirmDialog
                togglePopup={showDeletePopup}
                onModalClose={handleDelete}
                componentName={""}
                confirmTitle="Do you want to delete the image?"
            />
        </>
    )
}