import React, { useEffect, useState, useContext, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import uploadSvg from "../../../../../assets/images/upload-svg.svg"
import uploadGreenSvg from "../../../../../assets/images/uploadGreen-svg.svg"
import sucessCheck from "../../../../../assets/images/sucess-check.svg"
import closeSvg from "../../../../../assets/images/close-svg.svg"
import blockSvg from "../../../../../assets/images/block-svg.svg"
import reloadSvg from "../../../../../assets/images/reload-svg.svg"
import AppContextObject from "../../../../../common/context/common"
import DataContext from "../../../../../service/backendContext"
import Compress from "compress.js"
import { PublicContextInitiator } from '../../../../apis/publicContextInitiator'
import ImageModal from '../../../../../components/addon/imageModal/imageModal'
import ConfirmDialog from '../../../../../components/addon/confirmDialog/confirmDialog'
import errorSVG from "../../../../../assets/images/error.svg"
import { compareObjs } from '../../../../../service/helper'
import BackendBackBtn from '../../components/backendBackBtn'
// import BrandBackBtn from "../components/brandBackBtn"

const NonWisrCampaignCreative = ({ setShowProgressSideMenu }) => {
    const location = useLocation()
    const compress = new Compress()
    // const creative1Ref = useRef()
    // const creative2Ref = useRef()
    // const creative3Ref = useRef()
    const history = useHistory()
    const parentData = useContext<any>(DataContext)
    const context = useContext(AppContextObject)
    const [formData, setFormData] = useState(parentData?.nonWisrCampaignData?.campaignCreative?.creatives)
    const [image1Rejected, setImage1Rejected] = useState(false)
    const [image2Rejected, setImage2Rejected] = useState(false)
    const [image3Rejected, setImage3Rejected] = useState(false)
    // const [loading, setLoading] = useState(false)
    const [loading1, setLoading1] = useState(false)
    const [loading2, setLoading2] = useState(false)
    const [loading3, setLoading3] = useState(false)
    const [toggleShowImage1, setToggleShowImage1] = useState(false)
    const [toggleShowImage2, setToggleShowImage2] = useState(false)
    const [toggleShowImage3, setToggleShowImage3] = useState(false)
    const [showExitPopup, setShowExitPopup] = useState(false)
    const [overlay, setOverlay] = useState(false)
    const [errorMessage, setErrorMessage] = useState(false)
    const [creativeNeeded, setCreativeNeeded] = useState(false)
    const [showCreativeNeededPopup, setShowCreativeNeededPopup] = useState(false)
    const [showImageError, setShowImageError] = useState(false)
    const [confirmDialogBtnLoading, setConfirmDialogBtnLoading] = useState(false)

    useEffect(() => {
        const isSummary = new URLSearchParams(location.search).get("isSummary")
        if(isSummary === "true"){
            console.log("object")
            parentData.setNonWisrCampaignProgress({
                brandDetails: true,
                inventory: true,
                school: true,
                event: true,
                campaignCreative: false,
                preview: false
            })
        }
        // console.log(history)
        if(history.location.state){
            let data: any = history.location.state
            if(data.creativeRework){
                setCreativeNeeded(true)
            }
        }
    }, [])
    useEffect(() => {
        setShowProgressSideMenu(true)
        if (parentData.setNonWisrCampaignActiveModule) {
            parentData.setNonWisrCampaignActiveModule({
                ...parentData.nonWisrCampaignActiveModule,
                module: "creative",
                subModule: ""
            })
        }
    }, [])
    const handleCreativeUpload = async(e, type) => {
        if(e.target.files && e.target.files.length > 0){
            if(!e.target.files[0].type.startsWith("image/")){
                setShowImageError(true)
                return
            }
            else{
                setShowImageError(false)
            }
            setErrorMessage(false)
            if(type === 1){
                setLoading1(true)
            }
            else if(type === 2){
                setLoading2(true)
            }
            else if(type === 3){
                setLoading3(true)
            }
            const formData1 = new FormData();
            const resizedImage = await compress.compress([e.target.files[0]], {
                size: 2, // the max size in MB, defaults to 2MB
                quality: 0.4, // the quality of the image, max is 1,
                resize: false // defaults to true, set false if you do not want to resize the image width and height
            }).catch(err => {
                console.log("Error while compressing....", err)
            })
            const img = resizedImage[0];
            const base64str = img.data
            const imgExt = img.ext
            let compressedFile = Compress.convertBase64ToFile(base64str, imgExt)
            formData1.append(`image`,compressedFile, img.alt)
            let reqBody = formData1
            if(parentData?.nonWisrCampaignData?.brandDetail?.brandNameLabel){
                // let destinationFolder = `brands/${context?.user?.parentBrand ? context?.user?.parentBrand : context?.user?.brand}-${context?.user?.companyName ? context?.user?.companyName : context?.user?.brandName}/${parentData?.nonWisrCampaignData?.brandDetail?.brandNameLabel}/campaign-creatives`
                let destinationFolder = `brands/${context?.user?.parentBrand ? context?.user?.parentBrand : context?.user?.brand}/${parentData?.nonWisrCampaignData?.brandDetail?.brandName}/campaign-creatives`
                reqBody.append('destinationFolder',destinationFolder)
            }
            // formData1.append(`image`, e.target.files[0]);
            const requestOptions = {
                method: 'POST',
                body: reqBody
            };

            
        
            let publicContextInitiator = new PublicContextInitiator(context)
            fetch('/api/uploadFiles', requestOptions)
                .then(response => {
                    response.json()
                    .then(res=>{
                        let data = [];
                        res["response"].map(async item=>{
                            data.push(item.Key)
                        })
                        publicContextInitiator.getUploadedImagesSignedUrl(
                            context.validator, {keys: data}
                        ).then(res=>{
                            let updateImageObj = []
                                let requests = res.response.map(item=>{
                                updateImageObj.push({image: item.image, key: item.key});
                                console.log(updateImageObj)
                                if(type === 1){
                                    console.log("setImage1", updateImageObj)
                                    setFormData({...formData, image1: updateImageObj[0]})
                                    setImage1Rejected(false)
                                }
                                else if(type === 2){
                                    setFormData({...formData, image2: updateImageObj[0]})
                                    setImage2Rejected(false)
                                }
                                else if(type === 3){
                                    setFormData({...formData, image3: updateImageObj[0]})
                                    setImage3Rejected(false)
                                }
                            })
                            Promise.all(requests).then(() => {
                                // setLoading(false)
                                if(type === 1){
                                    setLoading1(false)
                                }
                                else if(type === 2){
                                    setLoading2(false)
                                }
                                else if(type === 3){
                                    setLoading3(false)
                                }
                            });                       
                        })
                    })
                    .catch(error => {
                        if(type === 1){
                            setImage1Rejected(true)
                            setLoading1(false)
                            // const dt = new DataTransfer()
                            e.target.value = null
                        }
                        else if(type === 2){
                            setImage2Rejected(true)
                            setLoading2(false)
                            // const dt = new DataTransfer()
                            e.target.value = null
                        }
                        else if(type === 3){
                            setImage3Rejected(true)
                            setLoading3(false)
                            // const dt = new DataTransfer()
                            e.target.value = null
                        }
                    })
                })
                .catch(error => {
                    if(type === 1){
                        setImage1Rejected(true)
                        setLoading1(false)
                        // const dt = new DataTransfer()
                        e.target.value = null
                    }
                    else if(type === 2){
                        setImage2Rejected(true)
                        setLoading2(false)
                        // const dt = new DataTransfer()
                        e.target.value = null
                    }
                    else if(type === 3){
                        setImage3Rejected(true)
                        setLoading3(false)
                        // const dt = new DataTransfer()
                        e.target.value = null
                    }
                })
                // setLoading(false)
                // if(type === 1){
                //     setLoading1(false)
                // }
                // else if(type === 2){
                //     setLoading2(false)
                // }
                // else if(type === 3){
                //     setLoading3(false)
                // }
        }
    }
    const confirmClose = (type) => {
        if(type === 1){
            setToggleShowImage1(false)
        }
        else if(type === 2){
            setToggleShowImage2(false)
        }
        else if(type === 3){
            setToggleShowImage3(false)
        }
        setOverlay(false)
    }
    const openImageModal = (type) => {
        setOverlay(true)
        if(type === 1){
            setToggleShowImage1(true)
        }
        else if(type === 2){
            setToggleShowImage2(true)
        }
        else if(type === 3){
            setToggleShowImage3(true)
        }
        // setOverlay(true)
    }
    const onSave = async(confirm) => {
        if(confirm){
            if (parentData) {
                // if(!(formData.image1.key || formData.image2.key || formData.image3.key)){
                //     setErrorMessage(true)
                //     return
                // }
                if (parentData.setNonWisrCampaignProgress) {
                    parentData.setNonWisrCampaignProgress({
                        ...parentData.nonWisrCampaignProgress,
                        campaignCreative: true,
                    })
                }
                // let allInventory = [...parentData.nonWisrCampaignData.inventory.inventories]
                // let inventory = parentData.nonWisrCampaignData.inventory.inventories.length > 0 ? parentData.nonWisrCampaignData.inventory.inventories.map(item => item.parentName || item.name) : []
                // let schools = parentData.nonWisrCampaignData.school.schools.length > 0 ? parentData.nonWisrCampaignData.school.schools.map(item => item._id) : []
                // let resp = await context.apis[context.user.role].checkSelectedInventories(
                //     context.validator, {inventories: inventory, schools: schools}
                // )
                // console.log("checkSelectedInventories", resp)
                // if(resp && resp.length > 0){
                    // let usedInventory = allInventory && allInventory.length > 0 && allInventory.filter(inventory => {
                    //     let found = resp.find(item => item === inventory.name)
                    //     console.log(found)
                    //     if(found){
                    //         return false
                    //     }
                    //     else{
                    //         return true
                    //     }
                    // })
                //     console.log(usedInventory)
                //     allInventory = [...usedInventory]
                // }
                if(parentData.setNonWisrCampaignData){
                    parentData.setNonWisrCampaignData({
                        ...parentData.nonWisrCampaignData,
                        campaignCreative: {
                            ...parentData.nonWisrCampaignData.campaignCreative, 
                            creatives: {...formData}
                        }
                    })
                }
            }
            if(parentData.nonWisrCampaignData.brandDetail.isTrial){
                history.push("/backend/backend-details/nonWisr-create-trial-campaign/nonWisr-campaign-preview")
            }
            else{
                history.push("/backend/backend-details/nonWisr-create-campaign/nonWisr-campaign-preview")
            }
        }
        else{

        }
    }
    const openExit = () => {
        // setShowExitPopup(true)
        // setOverlay(true)
    }
    const openCreativeNeededPopop = () => {
        setShowCreativeNeededPopup(true)
        setOverlay(true)
    }
    return (
        <>
            <div className={`ovelap ${overlay === true && "active"}`}></div>
            {/* <TopBar isDashboard={true} /> */}
            <div className="col-xl-9 col-lg-12 col-md-12 col-xs-12">
            <div className="rightPart noBack">
                <div className="row">
                <div className="col-xs-12 col-lg-12 col-md-12 col-xs-12">
                    <BackendBackBtn />
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-20"><h2 className="htx5 clearfix mt-10 mb-20">{creativeNeeded ? "Upload campaign creatives" : "Create a Campaign"}</h2></div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-30 creativepage">
                    <h2 className="htx2 clearfix mb-10">Upload Campaign Creatives</h2>
                    <p className="ptx1">Upload your key campaign visuals in 3 different sizes.</p> <p className="ptx1">These will then be sent to the schools for a review.</p>
                </div>
                   <div className="col-xl-8 col-lg-8 col-md-12 col-xs-12 mb-20 creativepage">
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 pl pr">
                                {/* <h2 className="htx3 clearfix">Total No of Creatives: 3</h2> */}
                            </div>
                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-20 pl pr">
                                <div className="table-container mt-10 addAdmininput">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">sr no</th>
                                            <th scope="col">Type of Creative</th>
                                            <th scope="col">Dimensions</th>
                                            <th scope="col">REFERENCE CREATIVE</th>
                                            <th scope="col">ACTIONS</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>1:1</td>
                                            <td>500px X 500px</td>
                                            <td><a style={{cursor: formData.image1 && formData.image1.image ? "pointer" : "default"}} className="view-anchor text-decoration" onClick={() => formData.image1 && formData.image1.image && openImageModal(1)}>View</a></td>
                                            <td>{image1Rejected ? 
                                                    <>
                                                        <a className="uploadSvg"><img src={blockSvg} /></a>
                                                        <a style={{cursor: "pointer"}} className="uploadSvg uploadBrose"><img src={reloadSvg} /><input type='file' accept='image/*' onChange={(e) => handleCreativeUpload(e, 1)}/></a>
                                                    </> 
                                                    : 
                                                    loading1 ? 
                                                    <div className="image imageLoader" style={{height:'20px'}}>
                                                        <div className="loader-imgupload"></div>
                                                    </div>
                                                    : 
                                                    <>
                                                        <a style={{cursor: "pointer"}} className="uploadSvg uploadBrose" ><img src={uploadSvg} />{formData.image1 && formData.image1.image && <img className='sucessCheck' src={sucessCheck} />} <input type='file' accept='image/*' onChange={(e) => handleCreativeUpload(e, 1)} /></a>  
                                                    </>
                                                }
                                            </td>
                                            
                                        </tr>
                                        <tr>
                                            <td>2</td>
                                            <td>1:2</td>
                                            <td>1200px X 1000 px</td>
                                            <td><a style={{cursor: formData.image2 && formData.image2.image ? "pointer" : "default"}} className="view-anchor text-decoration" onClick={() => formData.image2 && formData.image2.image && openImageModal(2)}>View</a></td>
                                            <td>{image2Rejected ? 
                                                    <>
                                                        <a className="uploadSvg uploadBrose"><img src={blockSvg} /></a>
                                                        <a style={{cursor: "pointer"}} className="uploadSvg"><img src={reloadSvg} /><input type='file' accept='image/*' onChange={(e) => handleCreativeUpload(e, 2)}/></a>
                                                    </> 
                                                    : 
                                                    loading2 ? 
                                                    <div className="image imageLoader" style={{height:'20px'}}>
                                                        <div className="loader-imgupload"></div>
                                                    </div>
                                                    : 
                                                    <>
                                                        <a style={{cursor: "pointer"}} className="uploadSvg uploadBrose" ><img src={uploadSvg} />{formData.image2 && formData.image2.image && <img className='sucessCheck' src={sucessCheck} />} <input type='file' accept='image/*' onChange={(e) => handleCreativeUpload(e, 2)} /></a>  
                                                    </>
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>3</td>
                                            <td>2:1</td>
                                            <td>1200px X 1200px</td>
                                            <td><a style={{cursor: formData.image3 && formData.image3.image ? "pointer" : "default"}} className="view-anchor text-decoration" onClick={() => formData.image3 && formData.image3.image && openImageModal(3)}>View</a></td>
                                            <td>{image3Rejected ? 
                                                    <>
                                                        <a className="uploadSvg"><img src={blockSvg} /></a>
                                                        <a style={{cursor: "pointer"}} className="uploadSvg uploadBrose"><img src={reloadSvg} /><input type='file' accept='image/*' onChange={(e) => handleCreativeUpload(e, 3)}/></a>
                                                    </> 
                                                    : 
                                                    loading3 ? 
                                                    <div className="image imageLoader" style={{height:'20px'}}>
                                                        <div className="loader-imgupload"></div>
                                                    </div>
                                                    : 
                                                    <>
                                                        <a style={{cursor: "pointer"}} className="uploadSvg uploadBrose" ><img src={uploadSvg} />{formData.image3 && formData.image3.image && <img className='sucessCheck' src={sucessCheck} />} <input type='file' accept='image/*' onChange={(e) => handleCreativeUpload(e, 3)} /></a>  
                                                    </>
                                                }
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                {/* {errorMessage &&
                                    <span className="valError"><img src={errorSVG} />Please upload atleast one image.</span>
                                } */}
                                {showImageError &&
                                    <span className="valError"><img src={errorSVG} />The uploaded file is not supported. Please upload image file.</span>
                                }
                                <ImageModal 
                                    togglePopup={toggleShowImage1}
                                    displayImage={formData.image1 && formData.image1.image}
                                    onModalClose={() => confirmClose(1)}
                                />
                                <ImageModal 
                                    togglePopup={toggleShowImage2}
                                    displayImage={formData.image2 && formData.image2.image}
                                    onModalClose={() => confirmClose(2)}
                                />
                                <ImageModal 
                                    togglePopup={toggleShowImage3}
                                    displayImage={formData.image3 && formData.image3.image}
                                    onModalClose={() => confirmClose(3)}
                                />
                                </div>
                            </div>
                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 pl pr mt-20"></div>
                            <div className="col-xl-5 col-lg-5 col-md-6 col-xs-12 delete-user mt-30">{!creativeNeeded &&<a className="viewwLink" style={{cursor: "pointer"}} onClick={openExit}>Save & Continue Later</a>}</div>
                            <div className="col-xl-7 col-lg-7 col-md-6 col-xs-12 end-md mt-30">
                                <div className="forminpt">
                                    {/* {creativeNeeded ? 
                                        <input name="Save" className="btn btn-orange" type="button" value="Save" onClick={() => !compareObjs(formData, parentData.nonWisrCampaignData.campaignCreative.creatives) ? openCreativeNeededPopop() : onExit(true, true)}/>
                                        : 
                                        <input name="Save &amp; next" className="btn btn-orange" type="button" value="Save &amp; next" onClick={() => onSave(true)}/>
                                    } */}
                                    <input name="Save &amp; next" className="btn btn-orange" type="button" value="Save &amp; next" onClick={() => onSave(true)}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            {/* <ConfirmDialog
                togglePopup={showExitPopup}
                onModalClose={onExit}
                componentName={""}
                confirmTitle="Do you want to save and exit?"
                isLoading={confirmDialogBtnLoading}
            />
            <ConfirmDialog
                togglePopup={showCreativeNeededPopup}
                onModalClose={onExit}
                componentName={""}
                confirmTitle="Do you want to save changes?"
                isLoading={confirmDialogBtnLoading}
            /> */}
        </>
    )
}

export default NonWisrCampaignCreative