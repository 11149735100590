import { useEffect, useState } from "react"

import { objectDeepClone } from "../../service/helper"

const Pagination = (props) => {

    const {
        totalPage,
        currentPage,
        onNextClick,
        onPrevClick,
        onPageChange,
        loading
    } = props

    const [showDots, setShowDots] = useState(false)
    const [pages, setPages] = useState([])
    const [minPageLength] = useState(6)

    useEffect(() => {
        renderPagination()
    }, [totalPage])

    const renderPagination = () => {
        let pages = []
        if (totalPage.length <= minPageLength) {
            setShowDots(false)
            totalPage.forEach(page => {
                pages.push(page)
            })
            setPages(pages)
        } else {
            if ((pages.length > 0 && currentPage === pages[0]) || pages.length === 0) {
                let pageList = objectDeepClone(totalPage.slice((currentPage - 1), currentPage + 3))
                if(pageList.length !== 4){
                    for(let i = 2; pageList.length < 4 ; i++){
                        if(currentPage - i > 1){
                            pageList = [currentPage - i, ...pageList]
                        }
                    }
                }
                if (currentPage < totalPage.length - 4) {
                    setShowDots(true)
                    // pageList.push(totalPage.length - 1)
                } else {
                    setShowDots(false)
                }
                setPages(pageList)
            }
        }
    }

    const onPageNoClick = (pageNo, index) => {
        if (totalPage.length > minPageLength) {
            renderPageList(pageNo, index)
        }
        onPageChange(pageNo + 1)
    }

    const renderPageList = (pageNo, index) => {
        if (index === pages.length - 1 && pageNo !== totalPage[totalPage.length - 1]) {
            let list = objectDeepClone(totalPage.slice(pageNo, (pageNo + 4))) // we want next set of 4 pages
            if(list.length !== 4){
                for(let i = 1; list.length < 4 ; i++){
                    if(pageNo - i > 1){
                        list = [pageNo - i, ...list]
                    }
                }
            }
            setPages(list)
            if (list[list.length - 1] === totalPage[totalPage.length - 1]) {
                setShowDots(false)
            } else {
                setShowDots(true)
            }
        }
    } 
    const onPrevBtnClick = (pageNo) => {
        if (pageNo > 0) {
            const foundIndex = pages.findIndex(page => page === pageNo)
            if (foundIndex > 0) {
                renderPageList(pageNo, foundIndex)
            } else {
                let list = objectDeepClone(totalPage.slice(pageNo - 1, (pageNo + 3)))
                if(list.length !== 4){
                    for(let i = 2; list.length < 4 ; i++){
                        if(pageNo - i > 1){
                            list = [pageNo - i, ...list]
                        }
                    }
                }
                setPages(list)
                if (list[list.length - 1] === totalPage[totalPage.length - 1]) {
                    setShowDots(false)
                } else {
                    setShowDots(true)
                }
            }
            onPrevClick()
        }
    }

    const onNextBtnClick = (pageNo) => {
        if (pageNo < totalPage.length) {
            const foundIndex = pages.findIndex(page => page === pageNo)
            if (foundIndex === -1) {
                let list = objectDeepClone(totalPage.slice(pageNo, (pageNo + 4)))
                if(list.length !== 4){
                    for(let i = 1; list.length < 4 ; i++){
                        if(pageNo - i > 1){
                            list = [pageNo - i, ...list]
                        }
                    }
                }
                setPages(list)
                if (list[list.length - 1] === totalPage[totalPage.length - 1]) {
                    setShowDots(false)
                } else {
                    setShowDots(true)
                }
            }
            onNextClick()
        }
    }
    return (
        <>
            <div className="paginationInventory">
                {loading ? "" : 
                    <ul>
                        <li>
                            <a
                                href="javascript:void(0)"
                                onClick={() => onPrevBtnClick(currentPage - 1)}
                            ><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z"/></svg>
                        </a></li>
                        {!pages.includes(0) && <li><a href="javascript:void(0)" className={`${currentPage === 1 ? "active" : ""}`} onClick={() => onPageNoClick(0, 0)}>{1}</a></li>}
                        {!pages.includes(0) && !pages.includes(1) && <li style={{paddingTop: "6px"}}>. . .</li>}
                        {
                            pages.map((page,i)=>{
                                return(
                                    <li key={i}><a href="javascript:void(0)" className={`${currentPage === page + 1 ? "active" : ""}`} onClick={() => onPageNoClick(page, i)}>{page+1}</a></li>
                                )
                            })
                        }
                        {showDots === true &&<li style={{paddingTop: "6px"}}>. . .</li>}
                        {!pages.includes(totalPage[totalPage.length - 1]) && <li><a href="javascript:void(0)" className={`${currentPage === totalPage.length ? "active" : ""}`} onClick={() => onPageNoClick((totalPage.length - 1), (totalPage.length - 1))}>{totalPage.length}</a></li>}
                        <li>
                            <a
                                href="javascript:void(0)"
                                onClick={() => onNextBtnClick(currentPage)}
                            ><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z"/></svg>
                            </a>
                        </li>
                    </ul>
                }
            </div>

        </>
    )
}

export default Pagination
