import React, {useState, useEffect} from 'react'
import RangeSlider from "../../../../components/addon/rangeSlider"

interface Props {
    totalReach?: any,
    setTotalReach?: any,
    reach?: any,
    setReach?: any,
    disable?: any,
    manuallyChangeReach?: any,
    min?: any,
    max?: any,
    setPageLoading?: any
}

const CampaignReach = ({
    totalReach, setTotalReach, disable, manuallyChangeReach, min, max, setPageLoading
}: Props) => {
    const [reachError, setReachError] = useState(false)
    const [reach, setReach] = useState(totalReach)
    const [timeOutId, setTimeOutId] = useState<any>()
    useEffect(() => {
        setReach(totalReach)
    }, [totalReach])
    const onRangeChange = (data) => {
        if(!disable){
            setReach(data)
            if(timeOutId){
                console.log("timeOutId", timeOutId)
                clearTimeout(timeOutId)
                // setTimeOutId(undefined)
            }
            setTimeOutId(setTimeout(() => {
                // setTotalReach(data)
                // setReach(data)
                manuallyChangeReach(data)
                // setTimeOutId(undefined)
            }, 1000))
        }
    }
    const changeError = () => {
        setReachError(false)
    }
    return (
        <div className="box-design mb-20 overheadThree">
            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-10">
                <h2 className="htx3 h-achor clearfix">Estimated Campaign Reach</h2>
                <div className="inventory-overHead camp-performance genderSplit">
                    <h3>{reach.toLocaleString('en-IN')} <em>Student(s)</em></h3>
                    {disable !== true &&
                        <RangeSlider
                            min={min}
                            max={max}
                            value={reach}
                            onSliderValueChange={onRangeChange}
                            changeError={changeError}
                            disable={disable || false}
                        />
                    }
                    {/* <div className="tol-built-in campaign-reach-scroll"> 
                        <span><strong></strong></span> 
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default CampaignReach
