import React, { useState, useEffect } from 'react';
import Slider from "react-slick";

const PREFIX_URL = 'https://raw.githubusercontent.com/xiaolin/react-image-gallery/master/static/';
interface Props {
  showModalTwoMore?: any,
  inventoriesList?: any,
  closeWithoutSaving?: any

}

const settingsThumbs = {
  slidesToShow: 3,
  slidesToScroll: 1,
  asNavFor: '.slider-for',
  centerMode: true,
  swipeToSlide: true,
  focusOnSelect: true,
  
};



const CarouselContainer = ({
  showModalTwoMore,
  //inventoriesList,
  closeWithoutSaving
}: Props) => {
  //console.log(inventoriesList)
  console.log(showModalTwoMore)
  console.log(closeWithoutSaving)

  const [open, setopen] = React.useState(true);
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [slider1, setSlider1] = useState(null);
  const [slider2, setSlider2] = useState(null);

  useEffect(() => {

    setNav1(slider1);
    setNav2(slider2);

  });

  const settingsMain = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    asNavFor: '.slider-nav'
  };

  

  const slidesData = [
    {
      id: 1,
      title: 'Classroom Benches',
      label: 'Classroom Benches',
      Image: 'https://wisr-json.s3.ap-south-1.amazonaws.com/wisr-inventoryImages/Benches.jpg'
    },
     {
      id: 2,
      title: 'Classroom Boards',
      label: 'Classroom Boards',
      Image: 'https://wisr-json.s3.ap-south-1.amazonaws.com/wisr-inventoryImages/Classroom+Boards.jpg'
    }, 
    {
      id: 3,
      title: 'Computer Lab',
      label: 'Computer Lab',
      Image: 'https://wisr-json.s3.ap-south-1.amazonaws.com/wisr-inventoryImages/Computer+lab+1.jpg'
    }, {
      id: 4,
      title: 'Computer Lab',
      label: 'Computer Lab',
      Image: 'https://wisr-json.s3.ap-south-1.amazonaws.com/wisr-inventoryImages/Computer+lab+2.jpg'
    }, 
    {
      id: 5,
      title: 'Play Area',
      label: 'Play Area',
      Image: 'https://wisr-json.s3.ap-south-1.amazonaws.com/wisr-inventoryImages/Kids+garden+area+1.jpg'
    },
    {
      id: 6,
      title: 'Play Area',
      label: 'Play Area',
      Image: 'https://wisr-json.s3.ap-south-1.amazonaws.com/wisr-inventoryImages/Kids+Garden+area.jpg'
    }, 
    {
      id: 7,
      title: 'Library',
      label: 'Library',
      Image: 'https://wisr-json.s3.ap-south-1.amazonaws.com/wisr-inventoryImages/Library+1.jpg'
    }, {
      id: 8,
      title: 'Library',
      label: 'Library',
      Image: 'https://wisr-json.s3.ap-south-1.amazonaws.com/wisr-inventoryImages/Library+2.jpg'
    }, {
      id: 9,
      title: 'Washroom Mirrors',
      label: 'Washroom Mirrors',
      Image: 'https://wisr-json.s3.ap-south-1.amazonaws.com/wisr-inventoryImages/Mirror.jpg'
    }, {
      id: 10,
      title: 'Music Class',
      label: 'Music Class',
      Image: 'https://wisr-json.s3.ap-south-1.amazonaws.com/wisr-inventoryImages/Music+Class+(2).jpg'
    }, 
    {
      id: 11,
      title: 'Music Class',
      label: 'Music Class',
      Image: 'https://wisr-json.s3.ap-south-1.amazonaws.com/wisr-inventoryImages/Music+class.jpg'
    },
    {
      id: 12,
      title: 'Passage Way',
      label: 'Passage Way',
      Image: 'https://wisr-json.s3.ap-south-1.amazonaws.com/wisr-inventoryImages/Passage+way+1.jpg'
    }, {
      id: 13,
      title: 'Passage Way',
      label: 'Passage Way',
      Image: 'https://wisr-json.s3.ap-south-1.amazonaws.com/wisr-inventoryImages/Passage+way+2.jpg'
    }, {
      id: 14,
      title: 'School Bus',
      label: 'School Bus',
      Image: 'https://wisr-json.s3.ap-south-1.amazonaws.com/wisr-inventoryImages/School+Bus+1.jpg'
    }, {
      id: 15,
      title: 'School Bus',
      label: 'School Bus',
      Image: 'https://wisr-json.s3.ap-south-1.amazonaws.com/wisr-inventoryImages/School+Bus+2.jpg'
    }, {
      id: 16,
      title: 'School Bus',
      label: 'School Bus',
      Image: 'https://wisr-json.s3.ap-south-1.amazonaws.com/wisr-inventoryImages/School+Bus+3.jpg'
    }, 
    {
      id: 17,
      title: 'School Bus Seat Covers',
      label: 'School Bus Seat Covers',
      Image: 'https://wisr-json.s3.ap-south-1.amazonaws.com/wisr-inventoryImages/School+Bus+seat+branding+1.jpg'
    },
    {
      id: 18,
      title: 'School Bus Seat Covers',
      label: 'School Bus Seat Covers',
      Image: 'https://wisr-json.s3.ap-south-1.amazonaws.com/wisr-inventoryImages/School+Bus+seat+branding+2.jpg'
    }, {
      id: 19,
      title: 'School Gate',
      label: 'School Gate',
      Image: 'https://wisr-json.s3.ap-south-1.amazonaws.com/wisr-inventoryImages/School+Gate+1.jpg'
    }, {
      id: 20,
      title: 'School Gate',
      label: 'School Gate',
      Image: 'https://wisr-json.s3.ap-south-1.amazonaws.com/wisr-inventoryImages/School+Gate+2.jpg'
    }, {
      id: 21,
      title: 'Sports Ground',
      label: 'Sports Ground',
      Image: 'https://wisr-json.s3.ap-south-1.amazonaws.com/wisr-inventoryImages/Sports+ground+1.jpg'
    }, {
      id: 22,
      title: 'Sports Ground',
      label: 'Sports Ground',
      Image: 'https://wisr-json.s3.ap-south-1.amazonaws.com/wisr-inventoryImages/Sports+ground+2.jpg'
    }, 
    {
      id: 23,
      title: 'Washroom Doors',
      label: 'Washroom Doors',
      Image: 'https://wisr-json.s3.ap-south-1.amazonaws.com/wisr-inventoryImages/Washroom+door+1.jpg'
    },
    {
      id: 24,
      title: 'Washroom Doors',
      label: 'Washroom Doors',
      Image: 'https://wisr-json.s3.ap-south-1.amazonaws.com/wisr-inventoryImages/Washroom+door+2.jpg'
    }, {
      id: 25,
      title: 'Washroom Doors',
      label: 'Washroom Doors',
      Image: 'https://wisr-json.s3.ap-south-1.amazonaws.com/wisr-inventoryImages/Washroom+door+3.jpg'
    }, {
      id: 26,
      title: 'Washroom Doors',
      label: 'Washroom Doors',
      Image: 'https://wisr-json.s3.ap-south-1.amazonaws.com/wisr-inventoryImages/Washroom+door+4.jpg'
    }, {
      id: 27,
      title: 'Water Coolers',
      label: 'Water Coolers',
      Image: 'https://wisr-json.s3.ap-south-1.amazonaws.com/wisr-inventoryImages/Water+cooler+1.jpg'
    }, 
    {
      id: 28,
      title: 'Water Coolers',
      label: 'Water Coolers',
      Image: 'https://wisr-json.s3.ap-south-1.amazonaws.com/wisr-inventoryImages/Watercooler+2.jpg'
    },
  ];


  return (
    <>
      <div className={`ovelap ${showModalTwoMore === true ? "active" : ""}`}></div>
      <div id='galleryModal' 
      style={{height:'auto'}} 
      className={`popup ${showModalTwoMore === false ? "hide" : ""} galleryContainer`}>
        <div className="popup-header">
          <span className="close toggle" onClick={closeWithoutSaving} data-target="myPopup" id="galleryClose" >close</span>
        </div>

        <div className="slider-wrapper" style={{maxWidth:'650px'}}>
          <Slider
            {...settingsMain}
            asNavFor={nav2}
            ref={slider => (setSlider1(slider))}
          >

            {slidesData.map((slide) =>
              <div className="slick-slide" key={slide.id} >
                <img className="slick-slide-image" src={`${slide.Image}`} style={{ maxHeight: '400px',boxShadow:'none'}} />
                
                <div style={{display:"block", textAlign:"center", width:"100%"}}> 
                <label className="slick-slide-label" style={{ textAlign: 'center', fontSize:'0.8em' }}>{slide.label}</label>
                </div>
              </div>

            )}

          </Slider>
          
              <div className="thumbnail-slider-wrap imggallery" style={{height:'auto'}}>
            <Slider
              {...settingsThumbs}
              asNavFor={nav1}
              ref={slider => (setSlider2(slider))}>

              {slidesData.map((slide) =>

                <div className="slick-slide" key={slide.id}>
                  {/* <img className="slick-slide-image" src={`${slide.Image}`} style={{ height: '100px',boxShadow:'none' }} /> */}
                </div>
              )}

            </Slider>
          </div>
        </div>
      </div>
    </>
  )
}

export default CarouselContainer 