import * as helper from "./helper"

const auth = {
    // User object will let us check authentication status
    user: {
        authenticated: false,
        emailID : ''
    },

    login (data) {
        return new Promise((resolve, reject) => {
            // api call to verify credentials
            this.user.authenticated = true
            // this.loginSuccess(data)
            resolve(true)
        })
    },

    logout () {
        this.user.authenticated = false
        helper.eraseCookie("session-wisr-core-microservice-backend")
        window.location.reload()
    },
    
    loginSuccess (data) {
        helper.setCookie("session-wisr-core-microservice-backend", data.authToken);
    },

    checkAuth () {
        return helper.getCookie("session-wisr-core-microservice-backend")
    }
}

export default auth
