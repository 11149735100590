import {
    useEffect,
    useState,
    useContext,
    useRef
} from "react"

import ConfirmAlert from "../../../../../components/addon/confirmAlert"
import AvailableFacilities from "../../components/availableFacilities/availableFacilities"
import Loader from "../../../../../components/addon/loader"

import DataContext from "../../../../../service/contextProvider"

import { compareObjs, objectDeepClone, formatInventories, stringWithoutNumber } from "../../../../../service/helper"

import config from "../../../../../service/config"

import { useHistory, useLocation } from "react-router-dom"
import { Steps} from "intro.js-react"
import menu from "../../../../../assets/images/menu.svg"
import errorSVG from "../../../../../assets/images/error.svg"

interface Props {
    isEdit?: boolean,
    onProceed?: any,
    toggleSidemenu ? : Function,
    showSidemenu ? : boolean
}

const CommonAreaInventories = ({
    isEdit,
    onProceed,
    toggleSidemenu,
    showSidemenu
}: Props) => {

    const history = useHistory()
    const location = useLocation()
    const parentData = useContext<any>(DataContext)
    const refFacilities = useRef()

    const [showPrompt, setShowPrompt] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")

    const [formData, setFormData] = useState(parentData?.data?.schoolAmenities)
    const [inventories, setInventories] = useState(parentData?.data?.schoolAmenities?.commonAreaAmenities)
    let [overlay, setOverlay] = useState(false)

    const [loader, setLoader] = useState(false)
    const [commonAreaMasterList, setClassroomMasterList] = useState([])
    const [recheck, setRecheck] = useState(false)
    const [url, setUrl] = useState("")
    const [onBoarding, setOnboarding] = useState({
        stepsEnabled: false,
        initialStep: 0,
        steps: [
            {element: "#amenity0", intro: "Select one or more inventories on this page to add them for brand on Wisr", position: "right"},
            {element: "#customAdd", intro: "If you don't find an inventory that you can offer, create a unique one by clicking here", position: "right"}
        ],
        options: {
            exitOnOverlayClick: false,
            showStepNumbers: true,
            hidePrev: true,
            hideNext: false,
            nextLabel: "Next",
            prevLabel: "Prev",
            doneLabel: "Got it",
            autoPosition: false,
            exitOnEsc: false,
            scrollToElement: true,
            disableInteraction: true
        },
    })
    useEffect(() => {
        setTimeout(() => {
            setOnboarding({...onBoarding, stepsEnabled: parentData?.tooltipProgress?.inventory})
        }, 100)
    }, [])

    useEffect(() => {
        if (parentData.setActiveModule) {
            parentData.setActiveModule({
                module: "inventoryDetails",
                subModule: "commonArea"
            })
        }
        if (parentData.listOfInventories && parentData.listOfInventories.length > 0) {
            setLoader(true)
            const finalList = formatInventories(parentData.listOfInventories, "commonArea")
            setClassroomMasterList(finalList)
            process.nextTick(() => {
                setLoader(false)
            })
        }        
    }, [])

    useEffect(() => {
        setInventories(parentData?.data?.schoolAmenities?.commonAreaAmenities)
    }, [parentData?.data?.schoolAmenities?.commonAreaAmenities])

    const onFacilitiesChange = (facilities) => {
        const obj1 = parentData?.data?.schoolAmenities?.commonAreaAmenities
        const obj2 = facilities
        const isSame = compareObjs(obj1, obj2)
        if (isSame === false) {
            setShowPrompt(true)
        } else {
            setShowPrompt(false)
        }
    }

    const submitForm = async (event) => {
        let data = objectDeepClone(formData)
        let facilities = []
        if (refFacilities && refFacilities.current) {
            const ref: any = refFacilities.current
            facilities = ref.fetchFacilities()
            facilities.forEach((e) => {
                e.data.forEach((d, index) => {
                    d.name = !stringWithoutNumber(d.name) ? e.name + " " + (index + 1) : d.name
                })
            })            
            data = {
                ...data,
                commonAreaAmenities: facilities,
            }
            setFormData({ ...formData,
                commonAreaAmenities: facilities
            })
        }
        setShowPrompt(false)
        if (parentData) {
            if (facilities.length === 0) {
                if (parentData.setProgress) {
                    parentData.setProgress({
                        ...parentData.progress,
                        commonArea: true
                    })
                }
            }
            if (parentData.setData) {
                parentData.setData({
                    ...parentData.data,
                    schoolAmenities: data
                })
            }
        }
        setRecheck(true)
        const isSummary = new URLSearchParams(location.search).get("isSummary")
        // process.nextTick(() => {
            if (isEdit === true) {
                // if (isSummary === "true") {
                //     onProceed("/schools/school-details/details/update-details-summary")
                // } else {
                //     onProceed("/schools/school-details/details/activities/student-life")
                // }
                
            } else {
                if (isSummary === "true") {
                    const url1 = facilities.length > 0 ? "/schools/onboarding/common-area-details?isSummary=true"
                    : "/schools/onboarding/onboarding-summary" 
                    setUrl(url1)                   
                    // onProceed(url)
                } else {
                    const url1 = facilities.length > 0 ? "/schools/onboarding/common-area-details"
                    : "/schools/onboarding/other-area-and-staff-inventories"     
                    setUrl(url1)               
                    // onProceed(url)
                }
            }            
        // })
    }

    useEffect(() => {
        if(recheck===true && showPrompt===false) {
            const isSummary = new URLSearchParams(location.search).get("isSummary")
            process.nextTick(() => {
                if (isEdit === true) {
                    if (isSummary === "true") {
                        onProceed("/schools/school-details/details/update-details-summary")
                    } else {
                        onProceed("/schools/school-details/details/activities/student-life")
                    }
                } else {
                    if (isSummary === "true") {
                        onProceed(url)
                    } else {
                        onProceed(url)
                    }
                }            
            })
        }
      },[recheck, showPrompt,url])

    const confirmAlertCallback = (data) => {
        setOverlay(data.overlay)
    }

    const hanldeOnboardingComplete = () => {
        parentData.setTooltipProgress({
            ...parentData.tooltipProgress,
            inventory: false
        })
        let progress = {
            ...parentData.tooltipProgress,
            inventory: false
        }
        localStorage.setItem("tooltipProgress", JSON.stringify(progress))
    }
    const exitOnBoarding = (e) => {
        console.log(e)
    }

    return (
        <>
            <div className={`ovelap ${overlay === true ? "active" : ""}`}></div>
            <div className={`${isEdit === false ? "col-xl-6 col-lg-9 col-md-12 col-xs-12" : ""}`}>
                <div className={`${isEdit === false ? "rightPart" : ""}`}>
                    {isEdit === false &&
                        <>
                            <>
                                <div className="clearfix"></div>
                            </>
                            <a onClick={() => toggleSidemenu()} href='javascript:void(0)' className={`hummob ${showSidemenu === true ? "no_highlight" : ""}`}>
                                <img src={menu} />
                            </a>
                            <h2 className='htx1 mb40'>
                            Common Area Inventory
                            </h2>
                        </>
                    }
                    {
                        loader === true ?
                        <Loader />
                        :
                        <div className='row'>
                            <AvailableFacilities
                                ref={refFacilities}
                                availableFacilities={commonAreaMasterList}
                                isEdit={isEdit}
                                selectedInventories={inventories}
                                onFacilitiesChange={onFacilitiesChange}
                                showPrompt={showPrompt}
                            />
                            <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-20 end-xs'>
                                <div className="bottom-error-btn">
                                    <p className="valError">
                                        {
                                            errorMessage &&
                                            <>
                                                <img src={errorSVG} />
                                                {errorMessage}
                                            </>
                                        }
                                    </p>
                                    <div className='forminpt'>
                                        <input
                                            onClick={submitForm}
                                            name='Proceed'
                                            value='Proceed'
                                            className='btn btn-orange sc-ameties'
                                            type='button'
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
            <ConfirmAlert
                when={showPrompt}
                title="Leave page without saving..."
                cancelText="Cancel"
                okText="Confirm"
                onOK={() => true}
                onCancel={() => false}
                parentCallback={confirmAlertCallback}
            />
            <Steps
                enabled={onBoarding.stepsEnabled}
                steps={onBoarding.steps}
                initialStep={onBoarding.initialStep}
                onComplete={hanldeOnboardingComplete}
                onExit={exitOnBoarding}
                options={onBoarding.options}
            />
        </>
    )
}

export default CommonAreaInventories
