import { calendarFormat } from 'moment'
import React, { useState, useEffect } from 'react'
import calendararrowL from "../../../../assets/images/calendar-arrowL.svg"
import calendararrowR from "../../../../assets/images/calendar-arrowR.svg"
import daybrand from "../../../../assets/images/logo512.png"
import noImg from "../../../../assets/images/blank-inventory-img.jpg"

interface Props {
    value?: any,
    setValue?: any,
    brandCampaignList?: any,
    holidayList?: any,
    schoolEventList?: any,
    selectedCampaignId?: any,
    setSelectedCampaignId?: any,
    modalCampaignList?: any,
    setModalCampaignList?: any
}
const Calander = ({
    value,
    setValue,
    brandCampaignList,
    holidayList,
    schoolEventList,
    selectedCampaignId,
    setSelectedCampaignId,
    modalCampaignList,
    setModalCampaignList
}: Props) => {
    const [calander, setCalander] = useState([])
    const [showModalTwoMore, setShowModalTwoMore] = useState(false)
    const [events, setEvents] = useState([])
    const [schoolEvents, setSchoolEvents] = useState([])
    const [showMoreSchoolEvents, setShowMoreSchoolEvents] = useState(false)
    const [showMoreEvents, setShowMoreEvents] = useState(false)
    useEffect(() => {
        buildCalander()
        // checkSchoolEvents()
    }, [value])
    const buildCalander = () => {
        const startDay = value.clone().startOf("month").startOf("week")
        const endDay = value.clone().endOf("month").endOf("week")
        const day = startDay.clone().subtract(1, "day")
        const a = []
        while(day.isBefore(endDay, "day")){
            a.push(
                Array(7).fill(0).map(() => day.add(1, "day").clone())
            )
        }
        setCalander([...a])
    }
    const beforeToday = (day) => {
        return day.isBefore(value.clone().startOf("month"), "day")
    }
    const afterToday = (day) => {
        return day.isAfter(value.clone().endOf("month"), "day")
    }
    const isToday = (day) => {
        return day.isSame(new Date(), "day")
    }
    const isSelected = (day) => {
        return value.isSame(day, "day")
    }
    //style
    const dayStyle = (day) => {
        if(beforeToday(day)){
            return "calendar-day day-disable"
        }
        if(afterToday(day)){
            return "calendar-day day-disable"
        }
        if(isToday(day)){
            return "calendar-day current-day"
        }
        if(isSelected(day)){
            return "calendar-day selected"
        }
        return "calendar-day"
    }
    //header
    const currMonthName = () => {
        return value.format("MMMM")
    }
    const currYear = () => {
        return value.format("YYYY")
    }
    const prevMonth = () => {
        setValue(value.clone().subtract(1, "month"))
    }
    const nextMonth = () => {
        setValue(value.clone().add(1, "month"))
    }
    const setCurrentBrand = (e) => {
        setSelectedCampaignId(e.target.id)
        e.stopPropagation()
    }
    const checkAnyBrandCampaign = (day) => {
        let brandCampaigns = []
        brandCampaignList.length !== 0 && brandCampaignList.forEach((e) => {
            if((day.isSame(new Date(e.startDate), "day") || day.isAfter(new Date(e.startDate), "day")) && (day.isSame(new Date(e.endDate), "day") || day.isBefore(new Date(e.endDate), "day"))){
                brandCampaigns.push({id: e._id, logo: e.logo})
            }
        })
        return brandCampaigns.length !== 0 ? brandCampaigns[0].id : ""
    }
    const manageMoreCampaign = (e, campaigns) => {
        let list = []
        campaigns.forEach((campaign) => {
            let item = brandCampaignList.filter((brandCampaign) => campaign.id == brandCampaign._id)
            if(item.length !== 0){
                list.push(...item)
            }
        })
        setModalCampaignList([...list])
        setShowModalTwoMore(true)
        e.stopPropagation()
    }
    const manageMoreEvents = (e, events) => {
        // let list = []
        // events.forEach((event) => {
        //     let item = 
        // })
        setEvents([...events])
        setShowMoreEvents(true)
    }
    const manageMoreSchoolEvents = (e,events) => {
        setSchoolEvents([...events])
        setShowMoreSchoolEvents(true)
    }
    // const checkHoliday = (day) => {
    //     let holidays = []
    //     holidayList.length !== 0 && holidayList.forEach((e) => {
    //         if(day.isSame(new Date(e.startDate), "day")){
    //             holidays.push(e.holiday)
    //         }
    //     })
    //     return (
    //         <>
    //             {holidays.length !== 0 && holidays.map(e => (
    //                 <b>{e}</b>
    //             ))}
    //         </>
    //     )
    // }
    const checkBrandCampaign = (day) => {
        let brandCampaigns = []
        brandCampaignList.length !== 0 && brandCampaignList.forEach((e) => {
            if((day.isSame(new Date(e.startDate), "day") || day.isAfter(new Date(e.startDate), "day")) && (day.isSame(new Date(e.endDate), "day") || day.isBefore(new Date(e.endDate), "day"))){
                brandCampaigns.push({id: e._id, logo: e.logo})
            }
        })
        return (
            <div className="day-brand" id={brandCampaigns.length !== 0 ? brandCampaigns[0].id : ""} onClick={(e) => setCurrentBrand(e)}>
                {brandCampaigns.length !== 0 && brandCampaigns.map((e, index) => (
                    index > 2 ? "" : <span id={e.id} onClick={(e) => setCurrentBrand(e)}><img id={e.id} src={e.logo || noImg} /></span>
                ))}
                {brandCampaigns.length >= 4 && <a style={{cursor: "pointer"}} data-target="brands-popup" className="brands-popup-count" onClick={(e) => manageMoreCampaign(e, brandCampaigns)} >+ {brandCampaigns.length - 3}</a>}
            </div>
        )
    }
    const checkSchoolEventHoliday = (day) => {
        let schoolEvents = []
        // schoolEventList.length !== 0 && schoolEventList.forEach((e) => {
        //     if(Array.isArray(e.months)){
        //         console.log(e.months, day.format("MMM"))
        //         let found = e.months.find((month) => month == day.format("MMM"))
        //         if(found){
        //             schoolEvents.push(e.name)
        //         }
        //     }
        //     else{
        //         if(e.months == day.format("MMM")){
        //             schoolEvents.push(e.name)
        //         }
        //     }
        //     // if(day.isSame(new Date(e.startDate), "day")){
        //     //     schoolEvents.push(e.event)
        //     // }
        // })
        holidayList.length !== 0 && holidayList.forEach((e) => {
            if(day.isSame(new Date(e.startDate), "day")){
                schoolEvents.push(e.holiday)
            }
        })
        return (
            <>
                {schoolEvents.length !== 0 && schoolEvents.map((e, index) => (
                    index > 0 ? "" : <b onClick={(e) => e.stopPropagation()}>{e}</b>
                ))}
                {schoolEvents.length >= 2 && <div><a href="javascript:void(0);" className="cale-today" style={{cursor: "pointer"}} onClick={(e) => manageMoreEvents(e, schoolEvents)}>+ {schoolEvents.length - 1} more</a></div>}
            </>
        )
    }
    const handleMoreBrandClick = (e, item) => {
        setSelectedCampaignId(item._id)
        setShowModalTwoMore(false)
        setModalCampaignList([])
        e.stopPropagation()
    }
    const closeModal = () => {
        setShowModalTwoMore(false)
        setModalCampaignList([])
    }
    const closeEventsModal = () => {
        setShowMoreEvents(false)
        setEvents([])
    }
    // const popups = document.getElementsByClassName('campaignPopup');

    window.addEventListener('click', (event) => {
        const target:any = event.target
        console.log(target.id)
        const campaignPopUp = target.closest('#brands-popup');
        const eventPopUp = target.closest('#events-popup');
        const schoolEventPopUp = target.closest('#school-events-popup')
        if(!campaignPopUp){
            setShowModalTwoMore(false)
        }
        if(!eventPopUp){
            setShowMoreEvents(false)
        }
        if(!schoolEventPopUp && target.id !== "openSchoolEvent"){
            setShowMoreSchoolEvents(false)
        }
    })
    const checkSchoolEvents = () => {
        let schoolEvents = []
        console.log(schoolEventList)
        schoolEventList.length !== 0 && schoolEventList.forEach((e) => {
            if(Array.isArray(e.months)){
                // console.log(e.months, day.format("MMM"))
                let found = e.months.find((month) => month == value.format("MMM"))
                if(found){
                    schoolEvents.push(e.name)
                }
            }
            else{
                if(e.months == value.format("MMM")){
                    schoolEvents.push(e.name)
                }
            }
            // if(day.isSame(new Date(e.startDate), "day")){
            //     schoolEvents.push(e.event)
            // }
            // console.log(schoolEvents)
            // setSchoolEvents([...schoolEvents])
        })
        return (
            <div className="school-events">
                {schoolEvents.length !== 0 && schoolEvents.map((e, index) => (
                    index > 3 ? "" : <b onClick={(e) => e.stopPropagation()}>{e}</b>
                ))}
                {schoolEvents.length >= 5 && <a id="openSchoolEvent" href="javascript:void(0);" className="cale-today" style={{cursor: "pointer"}} onClick={(e) => manageMoreSchoolEvents(e, schoolEvents)}>+ {schoolEvents.length - 4} more</a>}
            </div>
        )
    }

    return (
        <>
            <div className="schoolcalendar">
                {/* <div className="header">
                    <div className="left-arrow" onClick={() => prevMonth()}>{String.fromCharCode(171)}</div>
                    <div className="current">{`${currMonthName()} ${currYear()}`}</div>
                    <div className="right-arrow" onClick={() => nextMonth()}>{String.fromCharCode(187)}</div>
                </div> */}
                <div className="schoolcalendar-head">
                    <div className="cale-today">Today</div>
                    <div className="cale-months">
                        <a href="javascript:void(0);"><img src={calendararrowL} onClick={() => prevMonth()} /></a><span>{`${currMonthName()} ${currYear()}`}</span><a href="javascript:void(0);"><img src={calendararrowR} onClick={() => nextMonth()} /></a>
                    </div>
                    <div>{checkSchoolEvents()}</div>
                </div>
                <div className="schoolcalendar-body">
                    {/* {modalCampaignList.length !== 0 && 
                        <div className="searchlist cale-popup-tue">
                            <h3>Campaigns</h3>
                            <ul>
                                {modalCampaignList.map((item, index) => (
                                    <li onClick={(e) => handleMoreBrandClick(e, item)}><a href="javascript:void(0);"><span><img src="/static/media/nike-logo.2fad7ec7.svg" /></span>{item.name}</a></li>
                                ))}
                            </ul>
                        </div>
                    } */}
                    <div className="calendar-month-head">
                        <div className="calendar-month">Sun</div>
                        <div className="calendar-month">Mon</div>
                        <div className="calendar-month">Tue</div>
                        <div className="calendar-month">Wed</div>
                        <div className="calendar-month">Thu</div>
                        <div className="calendar-month">Fri</div>
                        <div className="calendar-month">Sat</div>
                    </div>
                    <div className="calendar-days-head">
                        {calander.length !== 0 && calander.map((week) => (
                            <>
                                {week.length !==0 && week.map((day) => (
                                    <div className={dayStyle(day)} id={checkAnyBrandCampaign(day)} onClick={(e) => setCurrentBrand(e)}>
                                        <p id={checkAnyBrandCampaign(day)} onClick={(e) => setCurrentBrand(e)}>{day.format("D").toString()}</p>
                                        {checkSchoolEventHoliday(day)}
                                        {checkBrandCampaign(day)}
                                    </div>
                                ))}
                            </>
                        ))}
                        <div id="brands-popup" className={`popup ${showModalTwoMore === false ? "hide" : ""}`}>
                            <div className="popup-header">
                                <h3 className="htx2 pophead">Brand Partners for the Month</h3>
                                <span className="close toggle" onClick={closeModal} data-target="myPopup">close</span> 
                            </div>
                            <div className="popup-body">
                                <div className="invetorypopblock">
                                    <div className="searchlist cale-popup-tue">
                                        <h3>Campaigns</h3>
                                        <ul>
                                            {modalCampaignList.map((item, index) => (
                                                <li onClick={(e) => handleMoreBrandClick(e, item)}><a href="javascript:void(0);"><span><img src={item.logo || noImg} /></span>{item.name}</a></li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div id="events-popup" className={`popup ${showMoreEvents === false ? "hide" : ""}`}>
                            <div className="popup-header">
                                <h3 className="htx2 pophead">Holidays of the Month</h3>
                                <span className="close toggle" onClick={closeEventsModal} data-target="myPopup">close</span> 
                            </div>
                            <div className="popup-body">
                                <div className="invetorypopblock">
                                    <div className="searchlist cale-popup-tue">
                                        <h3>Holiday</h3>
                                        <ul>
                                            {events.map((item, index) => (
                                                <li><a href="javascript:void(0);"><span><img src={noImg} /></span>{item}</a></li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <div id="school-events-popup" className={`popup ${showMoreSchoolEvents === false ? "hide" : ""}`}>
                            <div className="popup-header">
                                <h3 className="htx2 pophead">Events of the Month</h3>
                                <span className="close toggle" onClick={() => setShowMoreSchoolEvents(false)} data-target="myPopup">close</span> 
                            </div>
                            <div className="popup-body">
                                <div className="invetorypopblock">
                                    <div className="searchlist cale-popup-tue">
                                        <h3>Events</h3>
                                        <ul>
                                            {schoolEvents.length !== 0 && schoolEvents.map((item, index) => (
                                                <li><a href="javascript:void(0);">{item}</a></li>
                                            ))
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <div id="school-events-popup" className={`popup ${showMoreSchoolEvents === false ? "hide" : ""}`}>
                            <div className="popup-header">
                                <h3 className="htx2 pophead">Events of the Month</h3>
                                <span className="close toggle" onClick={() => setShowMoreSchoolEvents(false)} data-target="myPopup">close</span> 
                            </div>
                            <div className="popup-body">
                                <div className="searchlist cale-popup-tue">
                                    <h3>Events</h3>
                                    <ul>
                                        {schoolEvents.length !== 0 && schoolEvents.map((item, index) => (
                                            <li><a href="javascript:void(0);"><span><img src={noImg} /></span>{item}</a></li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </>
    )
}

export default Calander
