import { useState, useContext, useEffect, useRef } from "react"
import search from "../../../../assets/images/search.svg"
import notification from "../../../../assets/images/noti-bell.svg"
import OnboardingBackBtn  from "../components/onboardingBackBtn"
import backBtn from "../../../../assets/images/back-arrow.svg"
import proEdit from "../../../../assets/images/edit-profile.svg"
import addAdmin from "../../../../assets/images/add-admin1.svg"
import studentImgbg from "../../../../assets/images/student-imgbg.png"
import watchVideo from "../../../../assets/images/watch-video.png"
import faqArrow from "../../../../assets/images/faq-arrow.svg"
import AppContextObject from "../../../../common/context/common"
import SuccessDialog from "../../../../components/addon/successDialog"

import ReactPlayer from 'react-player'
import TopBar from "../components/topBar"
import { DebounceInput } from 'react-debounce-input'
import CustomSearchInput from "../../../../components/formFields/customSearchInput"
import VideoImageCarousel from "../../brands/components/videImageCarousel"

const  Support = () => {
    const context = useContext(AppContextObject)
    const [inventoryList, setSupportList] = useState([])
    const [searchValue, setSearchValue] = useState("")
    const [sortingValue, setSortingValue] = useState(true)
    const [refetch, setRefetch] = useState(false)
    const [allQuestions, setAllQuestions] = useState([])
    const [wisrQuestions, setWisrQuestions] = useState([])
    const [campaigning, setCampaigning] = useState([])
    const [grievancesQuestions, setGrievancesQuestions] = useState([])
    const [billing, setBilling] = useState([])
    const [administrative, setAdministrative] = useState([])
    const [questionTab, setQuestionTab] = useState(1)
    const [userQuestion, setUserQuestion] = useState("")
    const [showSuccessModal, setShowSuccessModal] = useState(false)
    const [videoLoading, setVideoLoading] = useState(true)
    const [videoUrl, setVideoUrl] = useState("")
    const [category, setCategory] = useState("all")
    const [uploadingQuestion, setUploadingQuestion] = useState(false)
    const [searchInpuOpen, setSearchInpuOpen] = useState(false)
    const [clearSearchIcon, setClearSearchIcon] = useState(false)

    useEffect(() => {
        getQuestions()
        getWisrVideo()
    }, [])

    useEffect(() => {
        getQuestions()
    }, [category])

    const getWisrVideo = async () =>{
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({type: 'school'})
        };

        fetch("/api/getUploadedVideo", requestOptions)
        .then(response => response.json())
        .then(async data => {
            console.log("dataaaaaaa", data)
            // setVideoLoading(false)
            setVideoUrl(data.result.response.image)
        }).catch(err=>{
            console.log("error111", err);
        }).catch(err => {
            console.log("error222", err)
        })
    }
    const getQuestions = async() => {
        let allQuestionResp = await context.apis[context.user.role].listFAQ(
            context.validator, {searchValue: searchValue, type: "school"}, {}
            )
        let wisrQuestionResp = await context.apis[context.user.role].listFAQ(
            context.validator, {searchValue: searchValue, category: "wisrPlatform", type: "school"}, {}
            )
        let campaigningQuestionResp = await context.apis[context.user.role].listFAQ(
            context.validator, {searchValue: searchValue, category: "campaigning", type: "school"}, {}
            )
        let billingQuestionResp = await context.apis[context.user.role].listFAQ(
            context.validator, {searchValue: searchValue, category: "billing", type: "school"}, {}
            )
        let administrativeQuestionResp = await context.apis[context.user.role].listFAQ(
            context.validator, {searchValue: searchValue, category: "administrative", type: "school"}, {}
            )
        if(allQuestionResp && allQuestionResp.done && allQuestionResp.response.rows){
            setAllQuestions(allQuestionResp.response.rows)
        }
        if(wisrQuestionResp && wisrQuestionResp.done && wisrQuestionResp.response.rows){
            setWisrQuestions(wisrQuestionResp.response.rows)
        }
        if(campaigningQuestionResp && campaigningQuestionResp.done && campaigningQuestionResp.response.rows){
            setCampaigning(campaigningQuestionResp.response.rows)
        }
        if(billingQuestionResp && billingQuestionResp.done && billingQuestionResp.response.rows){
            setBilling(billingQuestionResp.response.rows)
        }
        if(administrativeQuestionResp && administrativeQuestionResp.done && administrativeQuestionResp.response.rows){
            setAdministrative(administrativeQuestionResp.response.rows)
        }
    }

    const handleSearch = async (e) =>{
        if(e === ""){
            setSearchValue("")
        }
        else{
            setSearchValue(e.target.value.trim())
        }
        let resp = await context.apis[context.user.role].listFAQ(
            context.validator, {searchValue: e === "" ? "" : e.target.value, category, type: "school"}, {}
            )
        if(resp && resp.done && resp.response.rows){
            if(questionTab === 1)
                setAllQuestions(resp.response.rows)
            else if(questionTab === 2)
                setWisrQuestions(resp.response.rows)
            else if(questionTab === 3)
                // setRevenueQuestions(resp.response.rows)
                setCampaigning(resp.response.rows)
            else if(questionTab === 4)
                setBilling(resp.response.rows)
            else if(questionTab === 5)
                setAdministrative(resp.response.rows)
        }
    }

    const handleClick = (e, index) => {
        const faqs = document.getElementsByClassName("faq-tablist")
        for(let i=0; i<faqs.length; i++){
            if(i !== index){
                faqs[i].classList.remove("active")
            }
        }
        let parentElement = e.target.parentNode
        if(e.target.id === "img"){
            for(let j=0; j<2; j++){
                parentElement = parentElement.parentNode
            }
            if(parentElement.classList.contains("active")){
                parentElement.classList.remove("active")
            }
            else{
                parentElement.classList.add("active")
            }
        }
        else if(e.target.id === "anchor"){
            parentElement = parentElement.parentNode
            if(parentElement.classList.contains("active")){
                parentElement.classList.remove("active")
            }
            else{
                parentElement.classList.add("active")
            }
        }
    }
    const submitUserQuestion = async() => {
        if(userQuestion === "" || uploadingQuestion){
            return
        }
        setUploadingQuestion(true)
        let resp = await context.apis[context.user.role].saveUserQuery(
            context.validator, {query: userQuestion, userType: "school"}, {}
            )
        if(resp && resp.done){
            setUserQuestion("")
            setShowSuccessModal(true)
        }
        setUploadingQuestion(false)
    }
    const closeSuccessModal = () => {
        setShowSuccessModal(false)
    }

    const onVideoReady = () => {
        setVideoLoading(false)
    }

    const handleTabChange = (tabNo, type) => {
        setQuestionTab(tabNo)
        setCategory(type)
    }

    const data = [
        {
          id: 1,
          title: "AV video",
          video: 'SzoBkZu0ijo',
          credit: "Video by cottonbro from Pexels",
        },
        {
          id: 2,
          title: "SFA video",
          video: 'YwfvKLUMEVU',
          credit: "Video by cottonbro from Pexels",
        },
        {
          id: 3,
          title: "School video",
          video: 'hq7os19Y_dg',
          credit: "Video by cottonbro from Pexels",
        }
    ];

    return (
        <>
        <div className={`ovelap ${showSuccessModal === true ? "active" : ""}`}></div>
            <div className="col-xl-10 col-lg-12 col-md-12 col-xs-12">
                <div className="rightPart noBack">
                <div className="row">
                <div className="col-xs-12 col-lg-12 col-md-12 col-xs-12">
                        <OnboardingBackBtn
                            link="/"
                            text="Log Out"
                        />
                    </div>
                    <TopBar />
                    <div className="col-xl-9 col-lg-12 col-md-12 col-xs-12 mt-20">
                        <h2 className="htx2 clearfix mb30">Watch how to make the most of  WISR!</h2>  
                         <div>
                            {/* {
                                videoLoading === true &&
                                <p>Loading Video.....</p>
                            }
                            <ReactPlayer
                                url={videoUrl}
                                controls={true}
                                // width={1123}
                                // height={462}
                                onReady={() => onVideoReady()}
                            /> */}
                            {/* <img src={watchVideo} /> */}
                            <VideoImageCarousel data={data} supportPage={true} brand={false} />
                        </div>
                    </div>
                    <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-50"></div>
                    <div className="col-xl-9 col-lg-12 col-md-12 col-xs-12 mb-50 mt-10">
                        <div className="have-question">
                            <h2 className="htx2 clearfix">Have Questions?</h2>
                            <p className="ptx2">Send in your queries below and someone from our team will resolve them within 24 hours</p>
                            <div className="forminpt">
                                <textarea placeholder="Enter text here" maxLength={500} value={userQuestion} onChange={(e) => setUserQuestion(e.target.value)}></textarea>
                                <small>{`${userQuestion.length}/500`}</small>
                            </div>
                            <div className="forminpt"><a href="javascript:void(0);" className={`btn btn-orange ${uploadingQuestion ? "button--loading" : ""}`} onClick={submitUserQuestion}>Submit</a></div>
                        </div>
                    </div>
                    <div className="col-xl-9 col-lg-12 col-md-12 col-xs-12 mt-30">
                        <h2 className="htx2 clearfix">FAQs</h2>
                        <div className="faq-view">
                            <ul className="tabsinventory" style={{width: "calc(100% - 50px)"}}>
                                <li onClick={() => handleTabChange(1, "all")}><a href="javascript:void(0);" className={questionTab === 1 ? "active" : ""}>All</a></li>
                                <li onClick={() => handleTabChange(2, "wisrPlatform")}><a href="javascript:void(0);" className={questionTab === 2 ? "active" : ""}>{`WISR Plateform(${wisrQuestions.length})`}</a></li>
                                {/* <li onClick={() => setQuestionTab(3)}><a href="javascript:void(0);" className={questionTab === 3 ? "active" : ""}>{`Revenue(${revenueQuestions.length})`}</a></li> */}
                                <li onClick={() => handleTabChange(3, "campaigning")}><a href="javascript:void(0);" className={questionTab === 3 ? "active" : ""}>{`Campaigning(${campaigning.length})`}</a></li>
                                <li onClick={() => handleTabChange(4, "billing")}><a href="javascript:void(0);" className={questionTab === 4 ? "active" : ""}>{`Billing(${billing.length})`}</a></li>
                                <li onClick={() => handleTabChange(5, "administrative")}><a href="javascript:void(0);" className={questionTab === 5 ? "active" : ""}>{`Administrative(${administrative.length})`}</a></li>
                            </ul>
                            <div className="inventorySearch">
                                <CustomSearchInput
                                    searchInpuOpen={searchInpuOpen}
                                    setSearchInpuOpen={setSearchInpuOpen}
                                    clearSearchIcon={clearSearchIcon}
                                    setClearSearchIcon={setClearSearchIcon}
                                    searchKeys={searchValue}
                                    handleChange={handleSearch}
                                    palaceholder="Search"
                                />
                            </div>
                            <div className="faq-tabs-list">
                                {questionTab === 1 && 
                                    (allQuestions.length !== 0 ? allQuestions.map((question, index) => (
                                            <div key={index} className={index === 0 ? "faq-tablist active" : "faq-tablist"}>
                                                <h4><a id="anchor" style={{cursor: "pointer"}} onClick={(e) => handleClick(e, index)}>{`Q${index+1} ${question.question}`} <img id="img" src={faqArrow} /></a></h4>
                                                <div className="faq-tablistView">
                                                    <p>{question.answer}</p>
                                                </div>
                                            </div>
                                        ))
                                        :
                                        <div>No data found</div>
                                    )
                                }
                                {questionTab === 2 && 
                                    (wisrQuestions.length !== 0 ? wisrQuestions.map((question, index) => (
                                            <div key={index} className={index === 0 ? "faq-tablist active" : "faq-tablist"}>
                                                <h4><a id="anchor" style={{cursor: "pointer"}} onClick={(e) => handleClick(e, index)}>{`Q${index+1} ${question.question}`} <img id="img" src={faqArrow} /></a></h4>
                                                <div className="faq-tablistView">
                                                    <p>{question.answer}</p>
                                                </div>
                                            </div>
                                        ))
                                        :
                                        <div>No data found</div>
                                    )
                                }
                                {/* {questionTab === 3 && 
                                    (revenueQuestions.length !== 0 ? revenueQuestions.map((question, index) => (
                                            <div key={index} className={index === 0 ? "faq-tablist active" : "faq-tablist"}>
                                                <h4><a id="anchor" style={{cursor: "pointer"}} onClick={(e) => handleClick(e, index)}>{`Q${index+1} ${question.question}`} <img id="img" src={faqArrow} /></a></h4>
                                                <div className="faq-tablistView">
                                                    <p>{question.answer}</p>
                                                </div>
                                            </div>
                                        ))
                                        :
                                        <div>No data found</div>
                                    )
                                } */}
                                {questionTab === 3 && 
                                    (campaigning.length !== 0 ? campaigning.map((question, index) => (
                                            <div key={index} className={index === 0 ? "faq-tablist active" : "faq-tablist"}>
                                                <h4><a id="anchor" style={{cursor: "pointer"}} onClick={(e) => handleClick(e, index)}>{`Q${index+1} ${question.question}`} <img id="img" src={faqArrow} /></a></h4>
                                                <div className="faq-tablistView">
                                                    <p>{question.answer}</p>
                                                </div>
                                            </div>
                                        ))
                                        :
                                        <div>No data found</div>
                                    )
                                }
                                {questionTab === 4 && 
                                    (billing.length !== 0 ? billing.map((question, index) => (
                                            <div key={index} className={index === 0 ? "faq-tablist active" : "faq-tablist"}>
                                                <h4><a id="anchor" style={{cursor: "pointer"}} onClick={(e) => handleClick(e, index)}>{`Q${index+1} ${question.question}`} <img id="img" src={faqArrow} /></a></h4>
                                                <div className="faq-tablistView">
                                                    <p>{question.answer}</p>
                                                </div>
                                            </div>
                                        ))
                                        :
                                        <div>No data found</div>
                                    )
                                }
                                {questionTab === 5 && 
                                    (administrative.length !== 0 ? administrative.map((question, index) => (
                                            <div key={index} className={index === 0 ? "faq-tablist active" : "faq-tablist"}>
                                                <h4><a id="anchor" style={{cursor: "pointer"}} onClick={(e) => handleClick(e, index)}>{`Q${index+1} ${question.question}`} <img id="img" src={faqArrow} /></a></h4>
                                                <div className="faq-tablistView">
                                                    <p>{question.answer}</p>
                                                </div>
                                            </div>
                                        ))
                                        :
                                        <div>No data found</div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    
                </div>
                </div>
            </div>  
            <SuccessDialog 
                confirmTitle={"Message submitted successfully"}
                componentName={"Thank You! Our team member will get in touch with you shortly. "}
                confirmText={"Done"}
                togglePopup={showSuccessModal}
                onModalClose={closeSuccessModal}
            />
        </>
    )
}

export default Support
