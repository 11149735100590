import { useState, useContext, useEffect, useRef } from "react"
import toast from "react-hot-toast"
import errorSVG from "../../../../assets/images/error.svg";
import { Link, useHistory } from "react-router-dom"
import { PublicContextInitiator } from "../../../apis/publicContextInitiator";
import riarrownext from "../../../../assets/images/arrow-view.svg";
import SimpleReactValidator from "simple-react-validator";
import AppContextObject from "../../../../common/context/common";
import { downloadFile, getPercentageComplition, objectDeepClone } from "../../../../service/helper";
import scriptLoader from 'react-async-script-loader';
import config from "../../../../service/config";
import MultiPointMap from "../../../../components/addon/multiPointMap";
import InventoryMore from "../../../../components/addon/inventoryMore";
import backBtn from "../../../../assets/images/back-arrow.svg";
import SchoolList from "../components/SchoolsList";
import DataContext from "../../../../service/backendContext";
import CampaignRejetionModal from "../components/CampaignRejectionModal";
import blankImg from '../../../../assets/images/blank-inventory-img.jpg';
import { formatDateShowMonthBackend } from "../../../../service/helper";
import ImageModal from "../../../../components/addon/imageModal/imageModal";
import SingleSelect from "../../../../components/formFields/singleSelect/singleSelect";
import ConfirmDialog from "../../../../components/addon/confirmDialog/confirmDialog";
import BackendBackBtn from "../components/backendBackBtn";
import ImagePreview from "../../../../components/addon/imagePreview";
import AlertDialog from "../../../../components/addon/alertDialog/alertDialog"
import ArrowView from "../../../../assets/images/arrow-view.svg"
import linkImg from "../../../../assets/images/linkImage.svg"
import moment from "moment";
import Loader from "../../../../components/addon/loader";
import CreateAlbumPopUp from "../../../../components/addon/createAlbumPopUp";
import proEdit from "../../../../assets/images/edit-profile.svg"
import deleteImg from "../../../../assets/images/smallDelete.svg"
import uploadSvg from "../../../../assets/images/download.svg"
import CustomViewList from "../components/customViewList";
// import downloadImg from "../../../../assets/images/download.svg"

const CreateTrialCampaignDetails = (props) => {
    const parentData = useContext<any>(DataContext)
    const {
        isScriptLoaded,
        isScriptLoadSucceed
    } = props
    const context = useContext(AppContextObject)
    const history = useHistory()
    const [showCreateAlbumPopUp, setShowCreateAlbumPopUp] = useState(false)
    const [showEditAlbumPopUp, setShowEditAlbumPopUp] = useState(false)
    const [showPaymentChangeAlert, setShowPaymentChangeAlert] = useState(false)
    const [campaignDetails, setCampaignDetails] = useState<any>({})
    const [reachChartData, setReachChartData] = useState<any>()
    const [impressionChartData, setImpressionChartData] = useState<any>()
    // const [tab, setTab] = useState("reach")
    const [pincodeList, setPincodeList] = useState([])
    const [campaignCompletion, setCampaignCompletion] = useState(0)
    const [showInventoryModal, setShowInventoryModal] = useState(false)
    const [inventoryList, setInventoryList] = useState([]);
    const [eventList, setEventList] = useState([])
    const [showSchoolModal, setShowSchoolModal] = useState<any>(false);
    const [showCustomModal, setShowCustomModal] = useState<any>(false);
    // const [isApproved, setIsApproved] = useState(null);
    const [flag, setFlag] = useState(false);
    const [openRejectedModal, setOpenRejectedModal] = useState(false);
    const [rejectionMsg, setRejectionMsg] = useState({ reason: "" });
    const [overlay, setOverlay] = useState(false);
    const [campaignId, setCampaignId] = useState("");
    const [update, forceUpdate] = useState(false);
    const [toggleImage, setToggleImage] = useState(false);
    const [imageIndex, setImageIndex] = useState(0);
    const [toggleReworkCreativeImage, setToggleReworkCreativeImage] = useState(false);
    const [imageReworkCreativeIndex, setImageReworkCreativeIndex] = useState(0);
    const [paymentStatus, setPaymentStatus] = useState({ value: "" })
    const [keepPaymentStatus, setKeepPaymentStatus] = useState("")
    const [acceptingCampaign, setAcceptingCampaign] = useState(false)
    const [rejectingCampaign, setRejectingCampaign] = useState(false)
    const paymentStatusOption = [{ label: "Pending", value: "pending" }, { label: "Paid", value: "paid" }]
    const [campaignPauseStatus, setCampaignPauseStatus] = useState({ value: "" })
    // const campaignPauseStatusOptions = [{label: "Release Inventories", value : "Release Inventories"}, {label: "Resume Campaign", value: "Resume Campaign"}]
    const [keepCampaignPauseStatus, setKeepCampaignPauseStatus] = useState("")
    const [showCampaignPauseStatusChangeAlert, setShowCampaignPauseStatusChangeAlert] = useState(false)
    const [showReleaseInventoriesAlert, setShowReleaseInventoriesAlert] = useState(false)
    const [isCampaignPauseLoading, setIsCampaignPauseLoading] = useState(false)
    const [isInventoryReleaseLoading, setIsInventoryReleaseLoading] = useState(false)
    const [showAlertMessage, setShowAlertMessage] = useState(false)
    const [alertMessage, setAlertMessage] = useState("Campaigns Inventories Released")
    const [isLoading, setIsloading] = useState(false)
    const [assignedFosList, setAssignedFosList] = useState([])
    const [assignedFosListLoading, setAssignedFosListLoading] = useState(false)
    const [albumList, setAlbumList] = useState([])
    const [albumListLoading, setAlbumListLoading] = useState(false)
    const [approvedSetData, setApprovedSetData] = useState([])
    const [approvedSetDataLoading, setApprovedSetDataLoading] = useState(false)
    const [selectedSets, setSelectedSets] = useState([])
    const [albumName, setAlbumName] = useState('')
    const [creatingAlbum, setCreatingAlbum] = useState(false)
    const [refetchAlbumList, setRefetchAlbumList] = useState(false)
    const [searchApprovedSet, setSearchApprovedSet] = useState('')
    const [showDeleteAlbum, setShowDeleteAlbum] = useState(false)
    const [deleteAlbumId, setDeleteAlbumId] = useState('')
    const [editAlbumId, setEditAlbumId] = useState('')
    const [showPublishPopUp, setShowPublishPopUp] = useState(false)
    const [publishAlbumId, setPublishAlbumId] = useState('')
    const [showPublishToBrandPopUp, setShowPublishToBrandPopUp] = useState(false)
    const [publishToBrandAlbumId, setPublishToBrandAlbumId] = useState('')
    const [downloadingAlbum, setDownloadingAlbum] = useState(false)

    const [formValidator] = useState(useRef(new SimpleReactValidator({
        element: (message) =>
            <span className="valError"><img src={errorSVG} />{message}</span>
    })))
    const chartData = [
        {
            name: "ClassRoom",
            reach: "10000",
            impression: "20000"
        },
        {
            name: "School bus",
            reach: "30000",
            impression: "15000"
        },
        {
            name: "Auditorium",
            reach: "40000",
            impression: "24000"
        },
        {
            name: "Canteen",
            reach: "8000",
            impression: "5000"
        },
        {
            name: "Hall",
            reach: "12000",
            impression: "8000"
        }
    ]
    // let campaignId = ""
    useEffect(() => {
        parentData.setShowSidemenu(true);
        configureChartData()
    }, [])
    useEffect(() => {
        console.log(history.location.state)
        if (history.location.state) {
            let data: any = history.location.state
            if (data.campaignId) {
                setCampaignId(data.campaignId)
                console.log(parentData)
                parentData.setData({
                    ...parentData.data,
                    campaignId: data.campaignId,
                    from: history.location.state['from'] === 'brand' ? 'brand' : 'campaign'
                })
                getCampaignDetails(data.campaignId)
            }
        }
    }, [flag])
    useEffect(() => {
        getAssinedFosList()
    }, [campaignId])
    useEffect(() => {
        getAlbumList()
    }, [campaignId, refetchAlbumList])

    const getAssinedFosList = async () => {
        if (campaignId) {
            setAssignedFosListLoading(true)
            let resp = await context.apis[context.user.role].getAssignedFOSByCampaign(
                context.validator, { campaignId: campaignId }
            )
            console.log("getAssignedFOSByCampaign", resp)
            if (resp && resp.done) {
                setAssignedFosList([...resp.response])
            }
            setAssignedFosListLoading(false)
        }
    }
    const getAlbumList = async () => {
        if (campaignId) {
            setAlbumListLoading(true)
            let resp = await context.apis[context.user.role].getCampaignReportingAlbumsByCampaign(
                context.validator, { campaignId: campaignId, searchValue: "" }
            )
            console.log("getCampaignReportingAlbumsByCampaign", resp)
            if (resp && resp.done) {
                if (context.user.role === "backendSales") {
                    setAlbumList([...resp.response.rows.filter(item => item.status === "published")])
                }
                else {
                    setAlbumList([...resp.response.rows])
                }
            }
            setAlbumListLoading(false)
        }
    }
    const configureChartData = () => {
        let label = [], impression = [], reach = []
        chartData.forEach((data) => {
            label.push(data.name)
            reach.push(Number(data.reach) / 1000)
            impression.push(Number(data.impression) / 1000)
        })
        setReachChartData({
            labels: label,
            datasets: [
                {
                    label: "Reach (No of students * 1000)",
                    axis: "y",
                    data: reach,
                    backgroundColor: "#fe6e00",
                    borderRadius: "50",
                    boarderWidth: 1,
                    barThickness: 10
                }],
            options: {}
        })
        setImpressionChartData({
            labels: label,
            datasets: [
                {
                    label: "Impression (No of students * 1000)",
                    axis: "y",
                    data: impression,
                    backgroundColor: "#fe6e00",
                    borderRadius: "50",
                    boarderWidth: 1,
                    barThickness: 10
                }],
            options: {}
        })
    }
    const getCampaignDetails = async (_id) => {
        setIsloading(true)
        const resp = await context.apis.public.getCampaignById(
            context.validator, { _id: _id ? _id : campaignId }, {}
        )
        console.log('campaignDetails====>', resp)
        if (resp && resp.done) {
            console.log(resp)
            setPaymentStatus({ value: resp.response.paymentStatus })
            setCampaignPauseStatus({ value: resp.response.campaignStatus.toLowerCase() === "on-hold" ? "resume" : "pause" })
            setCampaignDetails(resp.response)
            let completion = getPercentageComplition(resp.response.startDate, resp.response.endDate)
            setCampaignCompletion(completion)
            let inventories = [], events = []
            // let schools = []
            resp.response.schoolCampaignDatas && Array.isArray(resp.response.schoolCampaignDatas) && resp.response.schoolCampaignDatas.forEach(school => {
                if (school.inventories && Array.isArray(school.inventories)) {
                    school.inventories.forEach(inventory => {
                        if (inventory.parentName) {
                            let data = {
                                name: inventory.parentName,
                                image: (inventory.images && inventory.images.length > 0 && inventory.images[0].image) || "no image"
                            }
                            if (!inventories.find(inventory => inventory.name === data.name)) {
                                inventories.push(data)
                            }
                        }
                    })
                }
            })
            resp.response.schoolCampaignDatas && Array.isArray(resp.response.schoolCampaignDatas) && resp.response.schoolCampaignDatas.forEach(school => {
                // let schoolInventory = []
                // schools.push(school.schoolDetails)
                if (school.activities && Array.isArray(school.activities)) {
                    school.activities.forEach(inventory => {
                        if (inventory.name) {
                            let data = {
                                name: inventory.name,
                                // image: (inventory.images && inventory.images.length > 0 && inventory.images[0].image) || "no image"
                            }
                            if (!events.find(inventory => inventory.name === data.name)) {
                                events.push(data)
                            }
                        }
                    })
                }
            })
            setEventList([...events])
            setInventoryList([...inventories])
            let array = resp.response.schoolCampaignDatas.map((data) => data?.schoolDetails?.city || "")
            setPincodeList([...array])
            setIsloading(false)
        }
    }
    const rejectPayment = async () => {
        console.log('rejectPayment', rejectionMsg, campaignId)
        const result = formValidator.current.allValid();
        console.log(result)
        if (!result) {
            formValidator.current.showMessages()
            forceUpdate(true)
            return
        } else {
            formValidator.current.hideMessages();
            setRejectingCampaign(true)
            let resp
            if (campaignDetails.campaignCreativesRevised && campaignDetails.campaignCreativesRevised.length > 0) {
                resp = await context.apis[context.user.role].updateCampaignStatus(
                    context.validator, { _id: campaignId }, { isApproved: false, campaignStatus: 'in-review', creativeReworkNeeded: true, rejectionMessage: rejectionMsg.reason }
                )
            }
            else {
                resp = await context.apis[context.user.role].updateCampaignStatus(
                    context.validator, { _id: campaignId }, { isApproved: false, campaignStatus: 'rejected', rejectionMessage: rejectionMsg.reason }
                )
            }
            console.log('approved resp======>', resp)
            if (resp && resp.done) {
                setFlag(!flag);
                setOpenRejectedModal(false);
                setRejectingCampaign(false)
                setRejectionMsg({ reason: "" });
                setOverlay(false);
            }
        }
    }
    const acceptRejectPayment = async (val, campaignId) => {
        console.log(campaignId)
        // setCampaignID(campaignId);
        if (val === 'rejected') {
            setOpenRejectedModal(true);
            setOverlay(true);
        }
        else if (val === 'accepted') {
            setAcceptingCampaign(true)
            // setFlag(!flag);
            let resp = await context.apis[context.user.role].updateCampaignStatus(
                context.validator, { _id: campaignId }, { isApproved: true, campaignStatus: 'in-review' }
            )
            console.log('approved resp======>', resp)

            if (resp && resp.done) {
                setFlag(!flag);
                setAcceptingCampaign(false)
            }
        }
    }
    const closeInventoryModal = () => {
        setShowInventoryModal(false)
    }
    const closeSchoolModal = () => {
        setShowSchoolModal(false);
    }
    const closeCustomModal = () => {
        setShowCustomModal(false);
    }
    const hideModal = () => {
        setOpenRejectedModal(false);
        setOverlay(false);
        setRejectionMsg({ reason: "" });
    }
    const openImageModal = (index) => {
        setToggleImage(true)
        setImageIndex(index)
        setOverlay(true)
    }
    const openReworkCreativeImageModal = (index) => {
        setToggleReworkCreativeImage(true)
        setImageReworkCreativeIndex(index)
        setOverlay(true)
    }
    const confirmClose = (index) => {
        setToggleImage(false)
        setImageIndex(index)

        setOverlay(false)
    }
    const confirmReworkCreativeClose = (index) => {
        setToggleReworkCreativeImage(false)
        setImageReworkCreativeIndex(index)
        setOverlay(false)
    }
    const onPaymentStatusChange = async (confirm) => {
        if (confirm) {
            console.log(keepPaymentStatus)
            let resp = await context.apis[context.user.role].updateCampaignStatus(
                context.validator, { _id: history.location.state["campaignId"] }, { paymentStatus: keepPaymentStatus }
            )
            console.log(resp)
            setPaymentStatus({ value: keepPaymentStatus })
        }
        setKeepPaymentStatus("")
        setShowPaymentChangeAlert(false)
        setOverlay(false)
    }
    const onCampaignPauseStatusChange = async (confirm) => {
        if (confirm) {
            console.log(keepCampaignPauseStatus)
            setIsCampaignPauseLoading(true)
            let resp = await context.apis[context.user.role].updateCampaignStatus(
                context.validator, { _id: history.location.state["campaignId"] }, { campaignStatus: keepCampaignPauseStatus }
            ).catch(err => {
                setIsCampaignPauseLoading(false)
            })
            console.log("updateCampaignStatus..onpause..", resp)
            if (resp && resp?.done) {
                setIsCampaignPauseLoading(false)
                // setCampaignPauseStatus({ value: keepCampaignPauseStatus === 'pause' ? "resume" : 'pause' })
                setFlag(!flag);
            }
        }
        setKeepCampaignPauseStatus("")
        setShowCampaignPauseStatusChangeAlert(false)
        setOverlay(false)
    }
    const onInventoriesRelease = async (confirm) => {
        if (confirm) {
            setIsInventoryReleaseLoading(true)
            let resp = await context.apis[context.user.role].releaseCampaignOccupiedInventory(
                context.validator, { _id: history.location.state["campaignId"] }, { campaignStatus: campaignDetails.campaignStatus }
            ).catch(err => {
                setOverlay(false)
                setIsInventoryReleaseLoading(false)
            })
            console.log("releaseCampaignOccupiedInventory...", resp)
            if (resp && resp?.done) {
                if (resp.response.isInventoryReleased === true) {
                    setIsInventoryReleaseLoading(false)
                    setAlertMessage(resp.response?.message)
                    setShowAlertMessage(true)
                    setFlag(!flag);
                }
            }
            else {
                setOverlay(false)
            }
        }
        else {
            setOverlay(false)
        }
        setShowReleaseInventoriesAlert(false)
    }
    const onAlertMessageClose = (confirm) => {
        setAlertMessage("")
        setShowAlertMessage(false)
        setOverlay(false)
    }
    console.log("campaignDetails--->> new", campaignDetails)

    const goToAssignSchoolProcess = () => {
        console.log("came here")
        history.push({ pathname: '/backend/backend-details/assignSchoolToFos', state: { campaignId: history.location.state['campaignId'] } })
    }
    const goToAssignSchoolListProcess = (e, name, _id) => {
        e.stopPropagation()
        console.log("right page")
        history.push({ pathname: '/backend/backend-details/assignSchoolToFos', state: { campaignId: history.location.state['campaignId'], fosName: name, fosId: _id, pageView: "list" } })
    }
    const goToViewCampaignReport = () => {
        history.push({ pathname: '/backend/backend-details/campaign-reporting-view', state: { campaignId: history.location.state['campaignId'] } })
    }

    // useEffect( () => {
    //     setShowCreateAlbumPopUp(true);
    //     setOverlay(true);
    // }, [])
    const openCreateAlbumPopUp = () => {
        setShowCreateAlbumPopUp(true);
        setOverlay(true);
        getSetsData()
    }
    const openEditAlbumPopUp = () => {
        setShowEditAlbumPopUp(true);
        setOverlay(true);
        getSetsData()
    }

    const getSetsData = async (searchData?) => {
        let data: any = history.location.state
        setApprovedSetDataLoading(true)
        console.log("history.location.state", data.campaignId)
        let resp = await context.apis[context.user.role].getSetsFromCampaignReports(
            context.validator, { campaignId: data.campaignId, searchValue: searchData, setApprovalStatus: "approved" }, {}
        )
        console.log("getSetsFromCampaignReports", resp)
        if (resp && resp.done) {
            setApprovedSetData([...resp.response])
        }
        setApprovedSetDataLoading(false)
    }

    const createAlbum = async () => {
        setCreatingAlbum(true)
        let data: any = history.location.state
        let resp = await context.apis[context.user.role].addCampaignReportingAlbum(
            context.validator, { title: albumName, setIds: [...selectedSets.map(item => item._id)], campaignId: data.campaignId, status: "pending", publishedToBrand: false }, {}
        )
        console.log("addCampaignReportingAlbum", resp, campaignId, data.campaignId)
        if (resp && resp.done) {
            setCreatingAlbum(false)
            setAlbumName("")
            setSelectedSets([])
            setRefetchAlbumList(!refetchAlbumList)
            setShowCreateAlbumPopUp(false)
            setOverlay(false)
            toast.success("Album created successfully.")
        }
    }
    const editAlbum = async () => {
        setCreatingAlbum(true)
        let data: any = history.location.state
        let resp = await context.apis[context.user.role].updateCampaignReportingAlbum(
            context.validator, { _id: editAlbumId }, { title: albumName, setIds: [...selectedSets.map(item => item._id)] }
        )
        console.log("updateCampaignReportingAlbum", resp, campaignId, data.campaignId)
        if (resp && resp.done) {
            setCreatingAlbum(false)
            setAlbumName("")
            setSelectedSets([])
            setEditAlbumId("")
            setRefetchAlbumList(!refetchAlbumList)
            setShowEditAlbumPopUp(false)
            setOverlay(false)
            toast.success("Album edited successfully.")
        }
    }

    const closeAlbumModal = () => {
        setSelectedSets([])
        setShowCreateAlbumPopUp(false);
        setOverlay(false);
    }
    const closeEditAlbumModal = () => {
        setSelectedSets([])
        setShowEditAlbumPopUp(false);
        setEditAlbumId("")
        setOverlay(false);
    }

    const goToCampaignReporting = (schoolId, schoolName) => {
        setShowCreateAlbumPopUp(false)
        setOverlay(false)
        if (context.user.role === "backendSales") {
            // history.push({pathname: ""})
        }
        else if (context.user.role === "backendOperations" || context.user.role === "backendAdmin" || context.user.role === "backendTech") {
            history.push({ pathname: "/backend/backend-details/campaign-reporting", state: { campaignId: campaignId, schoolId: schoolId, schoolName: schoolName } })
        }
    }

    const openDeleteAlbumModal = (_id) => {
        setShowDeleteAlbum(true)
        setOverlay(true)
        setDeleteAlbumId(_id)
    }

    const deleteAlbum = async (confirm) => {
        if (confirm) {
            let resp = await context.apis[context.user.role].removeCampaignReportAlbum(
                context.validator, { _id: deleteAlbumId }, {}
            )
            if (resp && resp.done) {
                setRefetchAlbumList(!refetchAlbumList)
            }
        }
        setDeleteAlbumId("")
        setShowDeleteAlbum(false)
        setOverlay(false)
    }

    const goToAlbumDetailPage = (albumId) => {
        // history.push({pathname: '/backend/backend-details/campaign-reporting-view', state: {campaignId: history.location.state['campaignId'], albumId: albumId}})
        window.open('/backend/backend-details/campaign-reporting-view?' + albumId)
    }

    const openEditAlbumModal = async (albumId) => {
        setEditAlbumId(albumId)
        let resp = await context.apis[context.user.role].getCampaignReportingAlbum(
            context.validator, { _id: albumId }, {}
        )
        console.log("getCampaignReportingAlbum", resp)
        if (resp && resp.done) {
            setAlbumName(resp.response[0].title)
            setSelectedSets([...resp.response[0].reportingSet])
            openEditAlbumPopUp()
        }
    }

    const reRouteToDetailPage = (schoolId, schoolName) => {
        history.push({ pathname: "/backend/backend-details/campaign-reporting", state: { campaignId: campaignId, schoolId: schoolId, schoolName: schoolName } })
    }

    const openPublishModal = (albumId) => {
        setPublishAlbumId(albumId)
        setShowPublishPopUp(true)
        setOverlay(true)
    }
    const openPublishToBrandModal = (albumId) => {
        setPublishToBrandAlbumId(albumId)
        setShowPublishToBrandPopUp(true)
        setOverlay(true)
    }

    const publishAlbum = async (confirm) => {
        if (confirm) {
            let resp = await context.apis[context.user.role].updateCampaignReportingAlbum(
                context.validator, { _id: publishAlbumId }, { status: "published" }
            )
            if (resp && resp.done) {
                toast.success("Album published successfully.")
            }
        }
        setPublishAlbumId("")
        setShowPublishPopUp(false)
        setRefetchAlbumList(!refetchAlbumList)
        setOverlay(false)
    }

    const publishAlbumToBrand = async (confirm) => {
        if (confirm) {
            let resp = await context.apis[context.user.role].updateCampaignReportingAlbum(
                context.validator, { _id: publishToBrandAlbumId }, { publishedToBrand: true }
            )
            if (resp && resp.done) {
                toast.success("Album published to brand successfully.")
            }
        }
        setPublishToBrandAlbumId("")
        setShowPublishToBrandPopUp(false)
        setRefetchAlbumList(!refetchAlbumList)
        setOverlay(false)
    }

    const downloadAlbum = async (albumId, subAlbumName?) => {
        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                campaignId: campaignId,
                albumId: albumId,
                groupBy: "school",
                subAlbumName: subAlbumName
            })
        }
        console.log("optionsabcd", options)
        fetch('/api/downloadCampaignReportingDocByAlbum', options)
            .then(response => response.json())
            .then(data => {
                console.log("downloadCampaignReportingDocByAlbum", data)
                if (data) {
                    // setDownloadSchoolLoading(false);
                    // let file = data.result.file.data;
                    // downloadFile(file, data.result.filename)
                    window.open(data.result.pdfData.image)
                }
            })
            .catch(err => console.error("downloadCampaignReportingDocByAlbum", err))
            .catch(err => console.error("downloadCampaignReportingDocByAlbum", err))
    }

    const openTableInner = (_id) => {
        // console.log(inventory)
        const id = _id
        const rowContainer = document.getElementById(id);
        const rowBtn = document.getElementById(`${id}-btn`);
        const rowUpArrow = document.getElementById(`${id}-up-arrow`);
        const rowDownArrow = document.getElementById(`${id}-down-arrow`);
        if (rowContainer) rowContainer.style.display = "revert";
        if (rowBtn) rowBtn.style.display = "revert";
        if (rowUpArrow) rowUpArrow.style.display = "revert";
        if (rowDownArrow) rowDownArrow.style.display = "none";
        // let fourChildNode = rowContainer.querySelector('.four');

        // if (isEdit === true) {
        //     let obj = objectDeepClone(inventory)
        //     if(!Array.isArray(obj.months)){
        //         obj.months = [obj.months]
        //     }
        //     // obj.placements.forEach((placement) => { // remove this loop once the api starts giving units attribute
        //     //     placement["units"] = "feet"
        //     // })
        //     setFormData(objectDeepClone(obj))
        //     setReadMode(false)
        // }
    }
    const closeTableInner = (_id) => {
        const id = _id
        const rowContainer = document.getElementById(id);
        const rowBtn = document.getElementById(`${id}-btn`);
        const rowUpArrow = document.getElementById(`${id}-up-arrow`);
        const rowDownArrow = document.getElementById(`${id}-down-arrow`);
        if (rowContainer) rowContainer.style.display = "none";
        if (rowBtn) rowBtn.style.display = "none";
        if (rowUpArrow) rowUpArrow.style.display = "none";
        if (rowDownArrow) rowDownArrow.style.display = "revert";
        // setShowForm(false)
    }

    // getCampaignById


    console.log("campaignDetails createTrialCampaignDetailsNew", campaignDetails)

    return (
        <>
            <div className={`ovelap ${overlay === true ? "active" : ""}`}></div>
            {isLoading ?
                <Loader />
                :
                <div className="col-xl-10 col-lg-12 col-md-12 col-xs-12">
                    <div className="rightPart noBack">
                        <div className="row">
                            {/* <div className="col-xs-12 col-lg-12 col-md-12 col-xs-12">
                                <BackendBackBtn />
                            </div> */}
                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-10">
                                <Link to={parentData.data.from === 'brand' ? "/backend/backend-details/brand-list" : "/backend/backend-details/campaign-list"} className="backbtn dblock">
                                    <img src={backBtn} />
                                    {/* Back */}
                                </Link>
                            </div>
                            <div className="col-xl-8 col-lg-8 col-md-12 col-xs-12 mt-10">
                                <div className="inventory-overHead overheadTwo">
                                    <p>CAMPAIGN NAME</p>
                                    <h3>{campaignDetails.name} 
									<span className={`table-status ${campaignDetails.campaignStatus === 'rejected' ? 'redbg' : campaignDetails.campaignStatus === "executed" ? "graybg" : campaignDetails.campaignStatus === "scheduled" ? "orangebg" : campaignDetails.campaignStatus === 'ongoing' ? 'yellowbg' : campaignDetails.campaignStatus === 'in-review' && campaignDetails.isApproved ? "greenbg" : campaignDetails.campaignStatus === "on-hold" ? "onholdbg" : "orangebg"} `}>
										{campaignDetails.campaignStatus === "in-review" ? campaignDetails.isApproved === true ? "Approved" : campaignDetails.campaignStatus : campaignDetails.campaignStatus}
										</span>
									</h3>
                                </div>
                            </div>
                            {(history.location.state['from'] === 'campaign' && campaignDetails.campaignStatus === 'in-review' && campaignDetails.isApproved === false && !campaignDetails.creativeReworkNeeded) && <div className="col-xl-4 col-lg-4 col-md-12 col-xs-12 mt-30">
                                <div className="schoolFilter campaignDetailsbtn mb-0 fR">
                                    {context.user.role === "backendAdmin" && <a href="javascript:void(0)" className={`btn btn-outline ${(campaignDetails.campaignStatus === 'rejected' || campaignDetails.isApproved) ? '' : ''}`}
                                        onClick={() => { acceptRejectPayment('rejected', campaignDetails._id); }}
                                    >{campaignDetails.campaignStatus === 'rejected' ? 'rejected' : campaignDetails.campaignCreativesRevised && campaignDetails.campaignCreativesRevised.length > 0 ? 'reject creatives' : 'reject'}</a>}
                                    {context.user.role === "backendAdmin" && <a href="javascript:void(0)" className={`btn btn-orange ${(campaignDetails.isApproved || campaignDetails.campaignStatus === 'rejected') ? '' : ''} ${acceptingCampaign && "button--loading btn-disabled"}`}
                                        onClick={() => { acceptRejectPayment('accepted', campaignDetails._id); }}
                                    >{campaignDetails.isApproved ? 'approved' : campaignDetails.campaignCreativesRevised && campaignDetails.campaignCreativesRevised.length > 0 ? 'approve creatives' : 'approve'}</a>}
                                </div>
                            </div>}
                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-20 mb-20"><div className="line-full"></div></div>
                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 pl pr">
                                <div className="row">
                                    <div className="col-xl-9 col-lg-9 col-md-12 col-xs-12 mb-10 pl">
                                        <div className="row">
                                            {campaignDetails && campaignDetails.campaignCreatives && campaignDetails.campaignCreatives.length > 0 &&
                                                <div className="col-xl-4 col-lg-4 col-md-12 col-xs-12 mb-10">
                                                    <div className="campaingCretivesTwo">
                                                        <div className="inventory-overHead trial-left">
                                                            <p>Campaign Creatives</p>
                                                        </div>
                                                        <div className="campaignRequestSlide campaignDetailSlide">
                                                            <div className="campaignRequestSlideLarge">
                                                                <img style={{ cursor: "pointer", height: '100%' }} src={(campaignDetails && campaignDetails.campaignCreatives && campaignDetails.campaignCreatives.length > 0 && campaignDetails.campaignCreatives[0].image) ? campaignDetails.campaignCreatives[0].image : blankImg} onClick={() => (campaignDetails && campaignDetails.campaignCreatives && campaignDetails.campaignCreatives.length > 0 && campaignDetails.campaignCreatives[0].image) && openImageModal(0)} />
                                                            </div>
                                                            <div className="campaignRequestSlideSmall">
                                                                <img style={{ cursor: "pointer", height: '100%' }} src={(campaignDetails && campaignDetails.campaignCreatives && campaignDetails.campaignCreatives.length > 1 && campaignDetails.campaignCreatives[1].image) ? campaignDetails.campaignCreatives[1].image : blankImg} onClick={() => (campaignDetails && campaignDetails.campaignCreatives && campaignDetails.campaignCreatives.length > 1 && campaignDetails.campaignCreatives[1].image) && openImageModal(1)} />
                                                                <img style={{ cursor: "pointer", height: '100%' }} src={(campaignDetails && campaignDetails.campaignCreatives && campaignDetails.campaignCreatives.length > 2 && campaignDetails.campaignCreatives[2].image) ? campaignDetails.campaignCreatives[2].image : blankImg} onClick={() => (campaignDetails && campaignDetails.campaignCreatives && campaignDetails.campaignCreatives.length > 2 && campaignDetails.campaignCreatives[2].image) && openImageModal(2)} />
                                                            </div>
                                                            <p>Original Creatives</p>
                                                        </div>
                                                    </div>
                                                    {history.location.state['campaignType'] === "create" && campaignDetails && campaignDetails.campaignCreativesRevised && campaignDetails.campaignCreativesRevised.length > 0 &&
                                                        <div className="campaingCretivesTwo">
                                                            <div className="campaignRequestSlide campaignDetailSlide">
                                                                <div className="campaignRequestSlideLarge">
                                                                    <img style={{ cursor: "pointer", height: '100%' }} src={(campaignDetails && campaignDetails.campaignCreativesRevised && campaignDetails.campaignCreativesRevised.length > 0) ? campaignDetails.campaignCreativesRevised[0].image : blankImg} onClick={() => (campaignDetails && campaignDetails.campaignCreativesRevised && campaignDetails.campaignCreativesRevised.length > 0 && campaignDetails.campaignCreativesRevised[0].image) && openReworkCreativeImageModal(0)} />
                                                                </div>
                                                                <div className="campaignRequestSlideSmall">
                                                                    <img style={{ cursor: "pointer", height: '100%' }} src={(campaignDetails && campaignDetails.campaignCreativesRevised && campaignDetails.campaignCreativesRevised.length > 1) ? campaignDetails.campaignCreativesRevised[1].image : blankImg} onClick={() => (campaignDetails && campaignDetails.campaignCreativesRevised && campaignDetails.campaignCreativesRevised.length > 1 && campaignDetails.campaignCreativesRevised[1].image) && openReworkCreativeImageModal(1)} />
                                                                    <img style={{ cursor: "pointer", height: '100%' }} src={(campaignDetails && campaignDetails.campaignCreativesRevised && campaignDetails.campaignCreativesRevised.length > 2) ? campaignDetails.campaignCreativesRevised[2].image : blankImg} onClick={() => (campaignDetails && campaignDetails.campaignCreativesRevised && campaignDetails.campaignCreativesRevised.length > 2 && campaignDetails.campaignCreativesRevised[2].image) && openReworkCreativeImageModal(2)} />
                                                                </div>
                                                                <p>Rework Creatives</p>
                                                            </div>
                                                        </div>
                                                    }

                                                </div>
                                            }
                                            <ImagePreview
                                                setShowImagePreview={() => confirmClose(imageIndex)}
                                                showImagePreview={toggleImage}
                                                images={campaignDetails.campaignCreatives && campaignDetails.campaignCreatives}
                                                selectedImageIndex={imageIndex}
                                            />
                                            <ImagePreview
                                                setShowImagePreview={() => confirmReworkCreativeClose(imageReworkCreativeIndex)}
                                                showImagePreview={toggleReworkCreativeImage}
                                                images={campaignDetails.campaignCreativesRevised && campaignDetails.campaignCreativesRevised}
                                                selectedImageIndex={imageReworkCreativeIndex}
                                            />
                                            <div className="col-xl-8 col-lg-8 col-md-12 col-xs-12 mb-10  trial-right">
                                                <div className="row">
                                                    <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-20 ">
                                                        <div className="inventory-overHead overheadOne">
                                                            <p>Campaign Description</p>
                                                            <h3 className="descrText">{campaignDetails.description}</h3>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12 mb-20">
                                                        <div className="inventory-overHead overheadOne">
                                                            <p>Budget</p>
                                                            <h3>₹ {campaignDetails.budget && campaignDetails.budget.toLocaleString('en-IN')}</h3>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12 mb-20">
                                                        <div className="inventory-overHead overheadOne">
                                                            <p>Schools</p>
                                                            <h3>{(campaignDetails.schoolCampaignDatas && campaignDetails.schoolCampaignDatas.length)}</h3>
                                                            {campaignDetails.schoolCampaignDatas && campaignDetails.schoolCampaignDatas.length > 0 &&
                                                                <a className="viewwLink camp-link bottom0" style={{ cursor: "pointer" }}
                                                                    onClick={() => setShowSchoolModal(true)}
                                                                >VIEW list <img className="arrowView" src={ArrowView} />
                                                                </a>}
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12 mb-20">
                                                        <div className="inventory-overHead overheadOne">
                                                            <p>Schedule</p>
                                                            <h3>{formatDateShowMonthBackend(campaignDetails.startDate, 4)} - {formatDateShowMonthBackend(campaignDetails.endDate, 4)}</h3>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12 mb-20">
                                                        <div className="inventory-overHead overheadOne">
                                                            <p>Inventories / Events Occupied</p>
                                                            <h3>{inventoryList && eventList && inventoryList.length + eventList.length}</h3>
                                                            {inventoryList && eventList && (inventoryList.length + eventList.length) > 0 &&
                                                                <a className="viewwLink camp-link bottom0" style={{ cursor: "pointer" }} onClick={() => setShowInventoryModal(true)}>VIEW list
                                                                    <img className="arrowView" src={ArrowView} />
                                                                </a>}
                                                        </div>
                                                    </div>
                                                   

                                                    {(history.location.state['campaignType'] === 'create' && campaignDetails.campaignStatus !== 'rejected') &&
                                                        <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12 mb-10">
                                                            <div className="inventory-overHead overheadOne">
                                                                <p>Payment Status</p>
                                                                <div className="mt-10">
                                                                    {context.user.role === "backendFinance" || context.user.role === "backendAdmin" ?
                                                                        <SingleSelect
                                                                            formData={paymentStatus}
                                                                            formKey="value"
                                                                            onDataChange={(data) => { setKeepPaymentStatus(data.value); setShowPaymentChangeAlert(true); setOverlay(true) }}
                                                                            isRequired={false}
                                                                            options={paymentStatusOption}
                                                                        />
                                                                        :
                                                                        <h3>{paymentStatus.value === "paid" ? "Paid" : "Pending"}</h3>

                                                                    }
                                                                </div>

                                                            </div>
                                                        </div>
                                                    }

                                                    {/* custom brief starts */}
                                                        {/* <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12 mb-20">
                                                            <div className="inventory-overHead overheadOne">
                                                                <p>Custom Brief</p>
                                                                
                                                                 <p>{campaignDetails?.campaignData?.reason}</p>
                                                               
                                                                <a className="viewwLink camp-link bottom0" style={{ cursor: "pointer" }} onClick={() => setShowCustomModal(true)}>Custom Details
                                                                <img className="arrowView" src={ArrowView} />
                                                                </a>
                                                            </div>
                                                        </div> */}

{ 
campaignDetails?.wisrOffering?.custom && (
<div className="col-xl-6 col-lg-6 col-md-12 col-xs-12 mb-20">
                                                        <div className="inventory-overHead overheadOne">
                                                            <p>Custom Brief</p>
                                                            {/* <h3>{(campaignDetails.schoolCampaignDatas && campaignDetails.schoolCampaignDatas.length)} --- 1</h3> */}
                                                            
                                                                <a className="viewwLink camp-link bottom0" style={{ cursor: "pointer" }}
                                                                    onClick={() => setShowCustomModal(true)}
                                                                >Custom Details <img className="arrowView" src={ArrowView} />
                                                                </a>
                                                        </div>
                                                    </div>
                                                    )
                                                    
}
                                                    {/* custom brief ends */}
                                                    

                                                    {/* {campaignDetails.isApproved && (context.user.role === "backendAdmin" || context.user.role === "backendOperations") ?
                                                        <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12 mb-20">
                                                            <div className="inventory-overHead">
                                                                <p>Campaign Reporting</p>
                                                                <a href="" className="viewwLink fN" onClick={goToAssignSchoolProcess}>
                                                                    <svg style={{marginRight: '5px'}} width="10" height="10" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M6 1V11" stroke="#FE6E00" stroke-width="2" stroke-linecap="round"/> <path d="M1 6L11 6" stroke="#FE6E00" stroke-width="2" stroke-linecap="round"/> </svg>
                                                                    Assign FOS 
                                                                </a>
                                                                <div className="row">
                                                                    {assignedFosList.length > 0 && assignedFosList.map((item, index) => (
                                                                        <div onClick={(e) => goToAssignSchoolListProcess(e, item.fosName, item.fosId)} style={{color: '#FE6E00', cursor: 'pointer', backgroundColor: "white", width: 'auto', height: 'auto', border: '1px solid #FE6E00', borderRadius: '5px', padding: '8px 8px 5px 8px', margin: "5px 10px 5px 0", fontSize: '14px'}}>
                                                                            <span>{`${item.fosName} (${item.schools.length})`}</span>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        context.user.role === "backendSales" &&
                                                        <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12 mb-20">
                                                            <div className="inventory-overHead">
                                                                <p>Campaign Reporting</p>
                                                                <a href="" className="viewwLink fN" onClick={goToViewCampaignReport}>
                                                                    View campaign report
                                                                </a>
                                                            </div>
                                                        </div>
                                                    } */}
                                                    {(campaignDetails.campaignStatus === "ongoing" || campaignDetails.campaignStatus === "on-hold") && campaignDetails.isInventoryReleased !== true && (!moment().isAfter(moment(campaignDetails.endDate), 'day')) && <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-20 ">
                                                        <div className="inventory-overHead overheadOne pause-campaign">
                                                            {(context.user.role === "backendAdmin") &&
                                                                <a href="javascript:void(0)" className={`btn btn-orange`}
                                                                    onClick={() => { setKeepCampaignPauseStatus(campaignPauseStatus.value); setShowCampaignPauseStatusChangeAlert(true); setOverlay(true) }}
                                                                >
                                                                    {campaignDetails.campaignStatus === "on-hold" ? "Resume Campaign" : "Pause Campaign"}
                                                                </a>
                                                            }

                                                            {context.user.role === "backendAdmin" &&
                                                                <a href="javascript:void(0)" className={`btn btn-orange ${(campaignPauseStatus.value === "pause" || (campaignDetails.isInventoryReleased === true)) ? "btn-disabled" : ""}`}
                                                                    onClick={() => { setShowReleaseInventoriesAlert(true); setOverlay(true) }}
                                                                >
                                                                    Release Inventories
                                                                </a>
                                                            }
                                                        </div>
                                                    </div>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-20 mb-20"><div className="line-full"></div></div>
                                        <div className="greyText mb-10" style={{ fontSize: "16px" }}>Campaign reporting</div>
                                        {campaignDetails.isApproved && (context.user.role === "backendAdmin" || context.user.role === "backendOperations") &&
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-20 pl">
                                                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', fontSize: "14px", marginBottom: "10px" }}>
                                                    <div>
                                                        Assign FOS
                                                    </div>
                                                    <div onClick={goToAssignSchoolProcess} style={{ color: "#FE6E00", cursor: "pointer" }}>
                                                        + ASSIGN
                                                    </div>
                                                </div>
                                                <div className="table-container table-inventory tableArrowRight">
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th>FOS</th>
                                                                <th style={{ textAlign: 'center' }}>No of school assigned</th>
                                                                <th style={{ textAlign: 'center' }}>View Images</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {!assignedFosListLoading ? assignedFosList.length > 0 ? assignedFosList.map((item, index) => (
                                                                <tr>
                                                                    <td>{item.fosName}</td>
                                                                    <td style={{ textAlign: 'center' }}>{item.schools.length}</td>
                                                                    <td style={{ textAlign: 'center' }}><img style={{ cursor: "pointer" }} onClick={(e) => goToAssignSchoolListProcess(e, item.fosName, item.fosId)} className="arrowView" src={linkImg} /></td>
                                                                </tr>
                                                            ))
                                                                :
                                                                <tr>
                                                                    <td style={{ textAlign: "center" }} colSpan={3}>NO FOS ASSIGNED</td>
                                                                </tr>
                                                                :
                                                                ""
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        }
                                        {campaignDetails.isApproved && (context.user.role === "backendAdmin" || context.user.role === "backendOperations" || context.user.role === "backendTech") &&
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-20 pl">
                                                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', fontSize: "14px", marginBottom: "10px" }}>
                                                    <div>
                                                        Album for brands
                                                    </div>
                                                    {context.user.role !== "backendSales" &&
                                                        <div onClick={openCreateAlbumPopUp} style={{ color: "#FE6E00", cursor: "pointer" }}>
                                                            + CREATE ALBUM
                                                        </div>
                                                    }
                                                </div>
                                                <div className="table-container table-inventory tableArrowRight">
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th>Name</th>
                                                                <th style={{ textAlign: 'center' }}>Created on</th>
                                                                <th style={{ textAlign: 'center' }}>Action</th>
                                                                <th style={{ textAlign: 'center' }}>Publish to Sales</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {!albumListLoading ? albumList.length > 0 ? albumList.map(album => (
                                                                <tr>
                                                                    <td>{album.title}</td>
                                                                    <td style={{ textAlign: 'center' }}>{formatDateShowMonthBackend(album.createdOn, 4)}</td>
                                                                    <td style={{ textAlign: 'center' }}>
                                                                        <img onClick={() => openDeleteAlbumModal(album._id)} style={{ marginRight: "15px", cursor: "pointer" }} className="arrowView" src={deleteImg} />
                                                                        <img onClick={() => goToAlbumDetailPage(album._id)} style={{ marginRight: "15px", cursor: "pointer" }} className="arrowView" src={linkImg} />
                                                                            <img onClick={() => downloadAlbum(album._id)} style={{ marginRight: "15px", fontWeight: "normal", cursor: "pointer" }} src={uploadSvg} />
                                                                        {album.status === "pending" && <img onClick={() => openEditAlbumModal(album._id)} style={{ marginRight: "15px", cursor: "pointer" }} className="arrowView" src={proEdit} />}
                                                                    </td>
                                                                    <td style={{ textAlign: 'center' }}>
                                                                        {album.status === "pending" ? <button style={{ minWidth: 'auto', minHeight: "auto", margin: '0', padding: '8px', fontSize: "10px" }} className={`btn btn-orange ${creatingAlbum ? "btn-disabled button--loading" : ""}`} onClick={() => openPublishModal(album._id)} >Publish</button> : "Published"}
                                                                    </td>
                                                                </tr>
                                                            ))
                                                                :
                                                                <tr>
                                                                    <td style={{ textAlign: "center" }} colSpan={4}>NO ALBUM CREATED</td>
                                                                </tr>
                                                                :
                                                                ""
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        }
                                        {campaignDetails.isApproved && context.user.role === "backendSales" &&
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-20 pl">
                                                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', fontSize: "14px", marginBottom: "10px" }}>
                                                    <div>
                                                        Album for brands
                                                    </div>
                                                </div>
                                                <div className="table-container table-inventory tableArrowRight">
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th>Name</th>
                                                                <th style={{ textAlign: 'center' }}>Created on</th>
                                                                <th style={{ textAlign: 'center' }}>Action</th>
                                                                <th style={{ textAlign: 'center' }}>Publish to Brand</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {!albumListLoading ? albumList.length > 0 ? albumList.map((album, index) => (
                                                                <>
                                                                    <tr key={index} className="outerTable">
                                                                        <td>{album.title}</td>
                                                                        <td style={{ textAlign: 'center' }}>{formatDateShowMonthBackend(album.createdOn, 4)}</td>
                                                                        <td style={{ textAlign: 'center' }}>
                                                                            <img onClick={() => goToAlbumDetailPage(album._id)} style={{ marginRight: "15px", cursor: "pointer" }} className="arrowView" src={linkImg} />
                                                                            <img onClick={() => downloadAlbum(album._id)} style={{ marginRight: "15px", fontWeight: "normal", cursor: "pointer" }} src={uploadSvg} />
                                                                        </td>
                                                                        <td style={{ textAlign: 'center' }}>
                                                                            {album.publishedToBrand ? "Published" : <button style={{ minWidth: 'auto', minHeight: "auto", margin: '0', padding: '10px', fontSize: "8px" }} className={`btn btn-orange ${creatingAlbum ? "btn-disabled button--loading" : ""}`} onClick={() => openPublishToBrandModal(album._id)} >Publish</button>}
                                                                            <>
                                                                                <svg onClick={() => openTableInner(album._id)} id={`${album._id}-down-arrow`}  className="down-arrow" width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M1 6L6 0.999999L11 6" stroke="#8C8C8C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                                                                                <svg onClick={() => closeTableInner(album._id)} id={`${album._id}-up-arrow`} style={{display: "none"}} width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M1 6L6 0.999999L11 6" stroke="#8C8C8C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                                                                            </>
                                                                        </td>
                                                                    </tr>
                                                                    {album.subAlbums && album.subAlbums.length > 0 &&
                                                                        <tr className="" id={album._id} style={{display: "none"}}>
                                                                            <td colSpan={4} className="pad-border crInnerTablePadding">
                                                                                <div className="available-placements">
                                                                                    <table className="table">
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th style={{width: "80%"}} scope="col">inventory/event name</th>
                                                                                                <th style={{ textAlign: 'center' }} scope="col">Action</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody style={{background: "#F8F8F8"}}>
                                                                                            {album.subAlbums.map((item, subAlbumIndex) => (
                                                                                                <tr key={subAlbumIndex}>
                                                                                                    <td>
                                                                                                        {item.name}
                                                                                                    </td>
                                                                                                    <td style={{ textAlign: 'center' }}>
                                                                                                        <img onClick={() => downloadAlbum(album._id, item.name)} style={{ marginRight: "15px", fontWeight: "normal", cursor: "pointer" }} src={uploadSvg} />
                                                                                                    </td>
                                                                                                </tr>
                                                                                            ))}
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    }
                                                                </>
                                                            ))
                                                                :
                                                                <tr>
                                                                    <td style={{ textAlign: "center" }} colSpan={4}>NO ALBUM CREATED</td>
                                                                </tr>
                                                                :
                                                                ""
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className="col-xl-3 col-lg-3 col-md-12 col-xs-12 mb-10 pr">
                                        <div className="box-design border-zero">
                                            <h2 className="htx3 clearfix mb-20 greyText">Campaign Location</h2>
                                            {isScriptLoaded && isScriptLoadSucceed &&
                                                <MultiPointMap
                                                    pincodeList={pincodeList}
                                                />
                                            }
                                            <Link to={{
                                                pathname: "/backend/backend-details/view-campaign/set-up-campaign",
                                                state: { campaignId: history.location.state['campaignId'] }
                                            }}><a href="" className="viewwLink fN">View campaign creation <img className="arrowView" src={riarrownext}></img></a></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <InventoryMore
                showModalTwoMore={showInventoryModal}
                inventoriesList={inventoryList}
                eventList={eventList}
                closeWithoutSaving={closeInventoryModal}
            />
            <CreateAlbumPopUp
                showAlbumModal={showCreateAlbumPopUp}
                data={approvedSetData}
                closeWithoutSaving={closeAlbumModal}
                loading={approvedSetDataLoading}
                selectedData={selectedSets}
                setSelectedData={setSelectedSets}
                onCreateAlbum={createAlbum}
                albumName={albumName}
                setAlbumName={setAlbumName}
                creatingAlbum={creatingAlbum}
                setSearchApprovedSet={setSearchApprovedSet}
                searchApprovedSet={searchApprovedSet}
                getSetsData={getSetsData}
                reRouteToDetailPage={reRouteToDetailPage}
            />
            <CreateAlbumPopUp
                showAlbumModal={showEditAlbumPopUp}
                data={approvedSetData}
                closeWithoutSaving={closeEditAlbumModal}
                loading={approvedSetDataLoading}
                selectedData={selectedSets}
                setSelectedData={setSelectedSets}
                onCreateAlbum={editAlbum}
                albumName={albumName}
                setAlbumName={setAlbumName}
                creatingAlbum={creatingAlbum}
                setSearchApprovedSet={setSearchApprovedSet}
                searchApprovedSet={searchApprovedSet}
                getSetsData={getSetsData}
                reRouteToDetailPage={reRouteToDetailPage}
                editAlbum={true}
            />

            {history.location.state['campaignId'] !== '' && <SchoolList
                showSchoolModal={showSchoolModal}
                closeSchoolModal={closeSchoolModal}
                campaignId={history.location.state['campaignId']}
                showCampaignCreativeButton={false}
                fromBrand={false}
				campaignDetailsIsApproved = {campaignDetails.isApproved}
            // doNotShowCreativeStatus={campaignDetails.isTrial}
            />}
            {/* custom starts */}
            {history.location.state['campaignId'] !== '' && <CustomViewList
                showCustomModal={showCustomModal}
                closeCustomModal={closeCustomModal}
                campaignId={history.location.state['campaignId']}
                showCampaignCreativeButton={false}
                fromBrand={false}
                reason={campaignDetails?.campaignData?.reason || "Reason not available"}
				// campaignDetailsIsApproved = {campaignDetails.isApproved}
            />}
            {/* custom ends */}
            <CampaignRejetionModal
                openRejectedModal={openRejectedModal}
                hideModal={hideModal}
                rejectionMsg={rejectionMsg}
                setRejectionMsg={setRejectionMsg}
                formValidator={formValidator}
                rejectPayment={rejectPayment}
                loading={rejectingCampaign}
            />
            <ConfirmDialog
                togglePopup={showPaymentChangeAlert}
                onModalClose={onPaymentStatusChange}
                componentName={""}
                confirmTitle="Do you want to change payment status of this campaign?"
            />
            <ConfirmDialog
                togglePopup={showCampaignPauseStatusChangeAlert}
                onModalClose={onCampaignPauseStatusChange}
                componentName={""}
                confirmTitle={`Do you want to ${keepCampaignPauseStatus} this campaign?`}
                isLoading={isCampaignPauseLoading}
            />
            <ConfirmDialog
                togglePopup={showReleaseInventoriesAlert}
                onModalClose={onInventoriesRelease}
                componentName={""}
                confirmTitle={`Do you want to release inventories for this campaign?`}
                isLoading={isInventoryReleaseLoading}
            />
            <ConfirmDialog
                togglePopup={showDeleteAlbum}
                onModalClose={deleteAlbum}
                componentName={""}
                confirmTitle={`Do you want to delete this album?`}
            // isLoading={isInventoryReleaseLoading}
            />
            <ConfirmDialog
                togglePopup={showPublishPopUp}
                onModalClose={publishAlbum}
                componentName={""}
                confirmTitle={`Do you want to publish this album?`}
            // isLoading={isInventoryReleaseLoading}
            />
            <ConfirmDialog
                togglePopup={showPublishToBrandPopUp}
                onModalClose={publishAlbumToBrand}
                componentName={""}
                confirmTitle={`Do you want to publish this album to brand?`}
            // isLoading={isInventoryReleaseLoading}
            />
            <AlertDialog
                displayMsg={"Campaigns Inventories Released"}
                onModalClose={onAlertMessageClose}
                togglePopup={showAlertMessage}
                showBtn={true}
            />
        </>
    )
}
export default scriptLoader(
    [
        'https://maps.googleapis.com/maps/api/js?key=' + config.GOOGLE_MAPS_API_KEY
    ],
)(CreateTrialCampaignDetails)
