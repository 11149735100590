import { useEffect, useState } from "react";
import errorSVG from "./../../assets/images/error.svg";

// import check from "../../../assets/images/check.svg"
// import errorSVG from "../../../assets/images/error.svg"
// import helpImg from "../../../assets/images/help.svg"

const DateField = (props) => {
	const {
		formData,
		formKey,
		formErrors,
		onDataChange,
		label,
		placeholder,
		onVerifyClick,
		classes,
		wrapperClasses,
		isVerified,
		maxLength,
		smallDesc,
		formValidator,
		validation,
		isDisabled,
		minDateTime,
		pattern,
		inProgress,
		isPincodeValid,
		index,
		isVerifyClickDisabled,
		helpText,
		onKeyPress,
		autoFocus,
		onBlur,
		isBlur,
		urlErrors,
		showUrlErrors,
		value,
		isFutureDateError, // New prop to pass future date error status
		name,
	} = props;
	console.log("datevalue = ", value);
	console.log("formdata -", formData, formKey);
	const [classList] = useState(classes ? classes : "");
	const [wrapperClassList] = useState(wrapperClasses ? wrapperClasses : "");

	const [showFutureDateError, setShowFutureDateError] = useState(false);

	const handleBlur = (e) => {
		const selectedDate = new Date(e.target.value).getTime();
		const currentDate = new Date().getTime();

		if (selectedDate > currentDate) {
			setShowFutureDateError(true);
		} else {
			setShowFutureDateError(false);
		}

		onBlur && onBlur(e);
	};

	const onDateChange = (e) => {
		const selectedDate = new Date(e.target.value).getTime();
		const currentDate = new Date().getTime();

		if (selectedDate > currentDate) {
			// If the selected date is in the future, do not update the form data.
			return;
		}

		onDataChange(e);

		const re = /^[0-9\b]+$/;
		// if value is not blank, then test the regex
		if (e.target.value === "" || re.test(e.target.value)) {
			onDataChange(e);
		}
	};

	const getCurrentDateTime = () => {
		const now = new Date();
		const year = now.getFullYear();
		const month = String(now.getMonth() + 1).padStart(2, "0");
		const day = String(now.getDate()).padStart(2, "0");
		const hour = String(now.getHours()).padStart(2, "0");
		const minute = String(now.getMinutes()).padStart(2, "0");
		return `${year}-${month}-${day}T${hour}:${minute}`;
	};

	return (
		<>
			{label !== true ? (
				<div
					className={`${
						label ? "forminpt tooltip-relative" : ""
					} ${wrapperClassList} ${isDisabled === true ? "disabled" : ""} `}
				>
					{label && (
						<label>
							{label}
							{helpText ? (
								<a
									style={{ cursor: "pointer" }}
									title={helpText}
									className="tooltip"
								>
									<img src="" />
								</a>
							) : (
								""
							)}
						</label>
					)}
					{}
					<input
						id={formKey}
						name={name}
						value={
							value
								? value
								: index === undefined || index === null
								? formData[formKey]
								: formData[index]
						}
						// value=""
						onChange={formKey == "date" ? onDateChange : onDataChange}
						className={`${classList} ${
							(formValidator?.current?.messagesShown === true &&
								formValidator?.current?.fields?.[formKey] === false) ||
							(showUrlErrors &&
								urlErrors &&
								urlErrors.length > 0 &&
								urlErrors[index])
								? "onFocus"
								: ""
						}`}
						placeholder={placeholder}
						maxLength={maxLength}
						pattern={pattern}
						disabled={isDisabled === true ? true : false}
						onKeyPress={onKeyPress}
						autoFocus={autoFocus}
						type="datetime-local"
						// onBlur={isBlur ? onBlur : null}
						onBlur={handleBlur} // Call the handleBlur function
						// min="1970-01-01T00:00" // Set a minimum value for the input to disable future dates
						// min={formData.callingActivityDetails[index - 1].dateTime} // Set a minimum value for the input to disable future dates
						// min="2023-08-10T00:00" // Set a minimum value for the input to disable future dates
						min={minDateTime}
						max={getCurrentDateTime()} // Set the maximum value to the current date and time
					/>
					{showFutureDateError && isFutureDateError && (
						// <p className="error-message">Future date is not allowed.</p>
						<span className="valError">
							<img src={errorSVG} />
							{/* Future date is not allowed. */}
							Please select valid date and time
						</span>
					)}
					{formData[index] > getCurrentDateTime() && (
						<p>You cannot select a date in the future.</p>
					)}
					{smallDesc && <small>{smallDesc}</small>}
					{onVerifyClick && (
						<a
							onClick={onVerifyClick}
							href="javascript:void(0)"
							className={`${
								inProgress ? "verifyLoader active" : "verifyLoader"
							} ${isVerifyClickDisabled && "clickNone"}`}
						>
							{inProgress ? <span className="button--loading"></span> : ""}
							<b
								className={`vertext vrifiyotp ${
									isVerified === true ? "complete" : ""
								}`}
							>
								verify <img src="" />
							</b>
						</a>
					)}
					{formValidator?.current?.message(
						formKey,
						formData[formKey],
						validation
					)}
					{(formKey == "pincode" || formKey == "zip") && !isPincodeValid ? (
						<span className="valError">
							<img src="" />
							The {formKey} is invalid.
						</span>
					) : (
						""
					)}
					{showUrlErrors &&
						urlErrors &&
						urlErrors.length > 0 &&
						urlErrors[index] && (
							<p className="valError">
								<img src="" />
								{urlErrors[index] === "invalid"
									? "Please enter valid url."
									: urlErrors[index] === "required"
									? "The url is required."
									: ""}
							</p>
						)}
				</div>
			) : (
				<>
					<input
						id={formKey}
						value={formData[formKey]}
						onChange={onDataChange}
						className={`${classList} ${
							formValidator?.current?.messagesShown === true &&
							formValidator?.current?.fields?.[formKey] === false
								? "onFocus"
								: ""
						}`}
						placeholder={placeholder}
						maxLength={maxLength}
						disabled={isDisabled === true ? true : false}
						type="text"
					/>
					{smallDesc && <small>{smallDesc}</small>}
					{onVerifyClick && (
						<a onClick={onVerifyClick} href="javascript:void(0)">
							<b
								className={`vertext vrifiyotp ${
									isVerified === true ? "complete" : ""
								}`}
							>
								verify <img src="" />
							</b>
						</a>
					)}
					{formValidator?.current?.message(
						formKey,
						formData[formKey],
						validation
					)}
					{formKey == "pincode" && !isPincodeValid ? (
						<span className="valError">
							<img src="" />
							The pincode is invalid.
						</span>
					) : (
						""
					)}
				</>
			)}
		</>
	);
};

export default DateField;
