import React from "react";
import { useState, useContext, useEffect, useRef } from "react"

import { useHistory } from "react-router-dom"
// import OnGoingCampaigns from "./onGoingCampaigns"
// import Pagination from "../../../components/addon/pagination"
// import arrowView from "../../../assets/images/arrow-view.svg"
// import tableFilter from "../../../assets/images/table-filter.svg"
// import AppContextObject from "../../../common/context/common"
// import MultiPointMap from "../../../components/addon/multiPointMap"
// import scriptLoader from 'react-async-script-loader'
import { BackendAdminContextInitiator } from "../../../../backendAdminSrc/apis/backendAdminContextInitiator";
import AppContextObject from "../../../../common/context/common"
import config from "../../../../service/config"
// import DataContext from "../../../service/brandContext"
import filterSvg from "../../../../assets/images/filter-svg.svg"
import filterArrowSvg from "../../../../assets/images/filter-arrow-svg.svg"
// import TopBar from "../schools/components/topBar"
// import BrandTopBar from "./components/brandTopBar"
// import { returnReadableStatus } from "../../../service/helper"
import { DebounceInput } from 'react-debounce-input'
import SearchInput from "./SearchInput";
import Pagination from "../../../../components/addon/pagination";
import CustomSearchInput from "../../../../components/formFields/customSearchInput";
import { titleCase } from "../../../../service/helper";
import useClickOutside from "../../../../service/useClickOutside";
var campaignSchoolListGlobalVar = 1

const CustomViewList = (props) => {
    const { showCustomModal, closeCustomModal } = props

	

    const context = useContext(AppContextObject);
    const history = useHistory()

    // const [searchInpuOpen, setSearchInpuOpen] = useState(false);
    // const [clearSearchIcon, setClearSearchIcon] = useState(false);
    // const [openFilter, setOpenFilter] = useState(false);
    // const [openStateFilter, setOpenStateFilter] = useState(false)
    // const [openCityFilter, setOpenCityFilter] = useState(false)
    // const [openGenderFilter, setOpenGenderFilter] = useState(false);
    // const [openBoardFilter, setOpenBoardFilter] = useState(false);
    // const [openLanguageFilter, setOpenLanguageFilter] = useState(false);
    const [filterCity, setFilterCity] = useState([]);
    const [filterGender, setFilterGender] = useState([]);
    const [filterBoard, setFilterBoard] = useState([]);
    const [filterLanguage, setFilterLanguage] = useState([]);
    const [searchKeys, setSearchKeys] = useState("");
    // const [schools, setSchools] = useState<any>([]);
    // const [totalPage, setTotalPage] = useState([0])
    // const [totalPageCount, setTotalPageCount] = useState(0)
    const [currentPage, setCurrentpage] = useState(1)
    const [cityStateData, setCityStateData] = useState({})
    // const [stateList, setStateList] = useState([])
    const [selectedState, setSelectedState] = useState([])
    // const [cityList, setCityList] = useState([])
    // const [loading,setLoading]=useState(false);
    // const [schoolIds,setSchoolIds]=useState<any>();
	// const [buttonClicked, setButtonClicked] = useState({});
	// const [text, setText] = useState(schools?.schoolStatus);
    const filterRef = useRef(null)
    const filterOptionsRef = useRef(null)
    // useClickOutside(filterOptionsRef, (event) => {
    //     const el = filterRef?.current;

    //     if (!el || el.contains(event.target)) {
    //         return;
    //     }
    //     setOpenFilter(false)
    // })

    // useEffect(() => {
    //     getSchoolList();
    // }, [showCustomModal, searchKeys, currentPage, filterBoard, filterLanguage, filterGender, filterCity, selectedState])
    useEffect(() => {
        if(currentPage !== 1){
            setCurrentpage(1)
        }
    }, [searchKeys, filterBoard, filterLanguage, filterGender, filterCity, selectedState])
    // useEffect(() => {
    //     getAllSchool()
    // }, [])
    // useEffect(() => {
    //     fetchCityByState()
    // }, [selectedState])
    // const fetchCityByState = async() => {
    //     let city = []
    //     selectedState.forEach(state => {
    //         city = [...city, ...cityStateData[state]]
    //     })
    //     setCityList([...city])
    // }

    // const getAllSchool = async() => {
    //     setLoading(true);
    //     let resp
    //     if(fromBrand){
    //         resp = await context.apis[context.user.role].getAllCampaignSchools(
    //             context.validator, { campaignId: campaignId }, {}
    //         )
    //     }
    //     else{
    //         const backendContextInitiator = new BackendAdminContextInitiator(context)
    //         resp = await backendContextInitiator.getAllCampaignSchools(
    //             context.validator, { campaignId: campaignId }, {}
    //         )
    //     }
    //     if(resp && resp.done){
    //         console.log("getAllCampaignSchools", resp)
    //         setLoading(false);
    //         let states = [], cityStateObj = {}
    //         resp.response.rows.forEach(school => {
    //             if(!states.includes(school.state)){
    //                 states.push(school.state)
    //             }
    //             if(cityStateObj[school.state]){
    //                 if(!cityStateObj[school.state].includes(school.parentCity)){
    //                     cityStateObj[school.state].push(school.parentCity)
    //                 }
    //             }
    //             else{
    //                 cityStateObj[school.state] = [school.parentCity]
    //             }
    //         })
    //         console.log("first", states, cityStateObj)
    //         setStateList([...states])
    //         setCityStateData({...cityStateObj})
    //     }
    // }
    // const getSchoolList = async () => {
    //     setLoading(true);
    //     let resp
    //     var localVar = Math.random()
    //     campaignSchoolListGlobalVar = localVar
    //     if(fromBrand){
    //         resp = await context.apis[context.user.role].getAllCampaignSchools(
    //             context.validator, { campaignId: campaignId, searchValue: searchKeys, city: filterCity, language: filterLanguage, schoolType: filterGender, board: filterBoard, state: selectedState}, {page: currentPage, limit: 10, withCount: true, sort: {_id: -1}}
    //         )
    //     }
    //     else{
    //         const backendContextInitiator = new BackendAdminContextInitiator(context)
    //         resp = await backendContextInitiator.getAllCampaignSchools(
    //             context.validator, { campaignId: campaignId, searchValue: searchKeys, city: filterCity, language: filterLanguage, schoolType: filterGender, board: filterBoard, state: selectedState}, {page: currentPage, limit: 10, withCount: true, sort: {_id: -1}}
            
	// 			)
	// 			// setSchoolIds
	// 		setSchoolIds(resp.response.rows);
			
	// 		console.log("getAllCampaignSchools inside",resp)
    //     }
    //     console.log("getAllCampaignSchools",resp)
    //     if (resp && resp.done) {
    //         if(campaignSchoolListGlobalVar === localVar){
    //             setLoading(false);
    //             setSchools(resp.response.rows);
    //             setTotalPageCount(resp.response.count)
    //             let total = Math.ceil(resp.response.count / 10);
    //             let arr = []
    //             for (let i = 0; i < total; i++) {
    //                 arr.push(i)
    //             }
    //             setTotalPage([...arr])
    //         }
    //         else{
    //             setLoading(false);
    //         }
    //     }
    // }





    
    const closePopup = ()=>{
        closeCustomModal();
        // setOpenFilter(false)
        // setSearchInpuOpen(false)
        setFilterCity([]);
        setFilterGender([]);
        setFilterBoard([]);
        setFilterLanguage([]);
        setSearchKeys('');
    }
  
    
    return (
        <>
        <div className={`ovelap ${showCustomModal === true ? "active" : ""}`}></div>
            <div id="inventoryMore" className={`popup ${showCustomModal === false ? "hide" : ""}`}>
                <div className="popup-header">
                    <h3 className="htx2 pophead">Custom Details</h3>
                    
                    <span className="close toggle" onClick={closePopup} data-target="myPopup">close</span>
                </div>
                <div className="popup-body">
                    <div className="viewdetailsHead">
                        <div className="invetorypopblock-school">
                            <div className="rightPart noBack">
                                <div className="row schools-list-popup">
                                    <div className="col-xl-9 col-lg-9 col-md-12 col-xs-12">
                                        <div className="row">
                                            
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-20 pl pr">
                                               <p style={{ fontFamily: "'nexaregular', sans-serif" }}>
                                               {props.reason}
                                               </p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}
export default CustomViewList;