import { useState, useContext, useEffect } from "react"

import { useHistory } from "react-router-dom"

import search from "../../../../../assets/images/search.svg"
import riarrownext from "../../../../../assets/images/right-arrow-next.svg"
import tabImgInventory from "../../../../../assets/images/tab-img-inventory.png"
import tableFilter from "../../../../../assets/images/table-filter.svg"
import arrowUp from "../../../../../assets/images/arrow-up.svg"
import arrowDown from "../../../../../assets/images/arrow-down.svg"
import imgInventory from "../../../../../assets/images/blank-inventory-img.jpg"

import tabSlide1 from "../../../../../assets/images/tab-Slide1.png"
import TopBar from "../../components/topBar"
import AppContextObject from "../../../../../common/context/common"

import OnboardingBackBtn  from "../../components/onboardingBackBtn"
import InventoryCard from "./inventoryCard"
import Pagination from "../../../../../components/addon/pagination"
import CampaignApprovalModal from "../../../../../components/addon/campaignApprovalModal"
import ThankyouModal from "../../../../../components/addon/thankyouModal"
import RejectBrandRequestModal from "../../../../../components/addon/rejectBrandRequestModal"

import DataContext from "../../../../../service/contextProvider"

import { PublicContextInitiator } from "../../../../apis/publicContextInitiator"

import { Steps } from "intro.js-react"
import { formatDateToDDMMMYY, isObjectEmpty, revenueFormatter } from "../../../../../service/helper"

import toast from "react-hot-toast"
import AlertDialog from "../../../../../components/addon/alertDialog/alertDialog"

import moment from "moment"
import InventoryRevenueChart from "./inventoryRevenueChart"
import ImagePreview from "../../../../../components/addon/imagePreview"
import { DebounceInput } from 'react-debounce-input'
import DatePicker from "react-datepicker";
import CustomSearchInput from "../../../../../components/formFields/customSearchInput"
import BrandRequestDetailModal from "../../../../../components/addon/brandRequestDetailModal"
const { detect } = require('detect-browser');
var inventoryListGloBalVar = 1

const Inventory = ({toolTipLoaded}) => {
    const parentData = useContext<any>(DataContext)
    const history = useHistory()
    const context = useContext(AppContextObject)
    const publicContextInitiator = new PublicContextInitiator(context)

    const [searchInpuOpen, setSearchInpuOpen] = useState(false)
    const [clearSearchIcon, setClearSearchIcon] = useState(false)
    const [showInventoryUpdateMsg, setShowInventoryUpdateMsg] = useState(false)
    const [inventoryUpdateMsg, setInventoryUpdateMsg] = useState("")
    const [isChrome, setIsChrome] = useState(true)
    const [showModalApproveActive, setshowModalApproveActive] = useState(false)
    const [showThankyouModal, setShowThankyouModal] = useState(false)
    const [thankyouTitle, setThankyouTitle] = useState("")
    const [showConfirmRejectModal, setShowConfirmRejectModal] = useState(false)
    const [thankyouDescription, setThankyouDescription] = useState("")

    const [inventoryList, setInventoryList] = useState([])
    const [searchValue, setSearchValue] = useState("")
    const [sortingValue, setSortingValue] = useState(true)
    const [sortingObj, setSortingObj] = useState({sort: {}})
    const [refetch, setRefetch] = useState(false)
    const [paginationCount, setPaginationCount] = useState([])
    const [activeStatusName, setActiveStatusName] = useState("")
    const [pageLimit, setPageLimit] = useState(10)
    const [showModal, setShowModal] = useState(false)
    const [showModalapproveActive, setshowModalapproveActive] = useState(false)
    const [overlay, setOverlay] = useState(false)
    // const [openFisrtToBeAdded, setOpenFisrtToBeAdded] = useState(false)
    const [addButtonIndex, setAddButtonIndex] = useState(-1)
    // const [inventoryToolTipStatus, setInventoryToolTipStatus] = useState(parentData?.toolTipProgress?.inventory)
    const [countOfStatus, setCountOfStatus] = useState({
        all: 0,
        occupied: 0, 
        available: 0,
        toBeAdded: 0,
        event: 0
    })

    const [brandRequest, setBrandRequest] = useState <any>({})

    const [activeCard, setActiveCard] = useState<any>("")
    const [currentPage, setCurrentPage] = useState(1)
    const [fetchAllCounts, setFetchAllCounts] = useState(false)
    const [requestedInventoryName, setRequestedInventoryName] = useState("")
    const [isInventoryListLoading, setIsInventoryListLoading] = useState(false)
    const [toolTip1Completed, setToolTip1Completed] = useState(false)
    const [addButtonToolTip, setAddButtonToolTip] = useState(false)
    const [completeToolTip, setCompleteToolTip] = useState(false)
    const [onBoarding, setOnboarding] = useState({
        stepsEnabled: false,
        initialStep: 0,
        steps: context.user.role === "schoolPrincipal" ? [
            {element: ".tabBorderColor1", intro: "Click on this card to get detailed information about the top performing inventory.", position: "right"},
            {element: ".tabBorderColor2", intro: "Get trends about the earned revenue and where the income is coming from.", position: "right"},
            {element: ".tabBorderColor3", intro: "Get quick access to see the upcoming campaigns here.", position: "left"}
        ] : [
            {element: "#addInventoryBtn", intro: "If you have more inventory to offer, click here to add more inventory.", position: "left"}
        ],
        options: {
            exitOnOverlayClick: false,
            showStepNumbers: true,
            hidePrev: true,
            hideNext: false,
            nextLabel: "Next",
            prevLabel: "Prev",
            doneLabel: "Got it",
            autoPosition: false,
            exitOnEsc: false,
            scrollToElement: false,
            disableInteraction: true
        },
    })
    const [topPerformingInventoryData, setTopPerformingInventoryData] = useState<any>({
        // "inventory": {
        //     "_id": "61bacdc73b222a474494cc89",
        //     "type": "classroom",
        //     "_checked": true,
        //     "name": "Uniforms 1",
        //     "customName": null,
        //     "images": [],
        //     "school": "61bacdc73b222a474494cc86",
        //     "parentName": "Uniforms",
        //     "quantity": 1,
        //     "status": "In Review",
        //     "inventoryMaster": "615ae6c6954fe735a49a059e",
        //     "isApproved": false,
        //     "createdOn": "2021-12-16T05:25:27.971Z",
        //     "brand": "Nestle"
        // },
        // "percentage": 10,
        // "inventoryRevenue": 7016.2,
        // "inventoryRevenueGrowth": 0
    })
    const [revenueEarnedData, setRevenueEarnedData] = useState<any>({
        // "totalRevenue": 70162,
        // "brandRevenueContribution": [
        //     {
        //         "revenue": 66026.43042599999,
        //         "brand": "Nestle"
        //     }
        // ],
        // "inventoryRevenueGrowth": 0
    })
    const [upcomingBrandReqData, setUpcomingBrandReqData] = useState<any>(
        {
            // "_id": "61bae673e5e0614d6d890e6c",
            // "campaignName": "Inaugural Event 5",
            // "brandName": "Nestle",
            // "logo": "a.jpg",
            // "startDate": "2021-12-21",
            // "endDate": "2021-12-28",
            // "inventories": [
            //     {
            //         "_id": "61bacdc73b222a474494cc89",
            //         "attributes": [
            //             "61bacdc83b222a474494cc92",
            //             "61bacdc83b222a474494cc93"
            //         ],
            //         "inventory": {
            //             "_id": "61bacdc73b222a474494cc89",
            //             "type": "classroom",
            //             "_checked": true,
            //             "name": "Uniforms 1",
            //             "customName": null,
            //             "images": [],
            //             "school": "61bacdc73b222a474494cc86",
            //             "parentName": "Uniforms",
            //             "quantity": 1,
            //             "status": "In Review",
            //             "inventoryMaster": "615ae6c6954fe735a49a059e",
            //             "isApproved": false,
            //             "createdOn": "2021-12-16T05:25:27.971Z"
            //         }
            //     },
            //     {
            //         "_id": "61bacdc73b222a474494cc8a",
            //         "attributes": [
            //             "617698cd38c7e4059404e27e",
            //             "617698cd38c7e4059404e27f",
            //             "617698cd38c7e4059404e280"
            //         ],
            //         "inventory": {
            //             "_id": "61bacdc73b222a474494cc8a",
            //             "type": "commonArea",
            //             "_checked": true,
            //             "name": "Canteen 1",
            //             "customName": null,
            //             "images": [],
            //             "school": "61bacdc73b222a474494cc86",
            //             "parentName": "Canteen",
            //             "quantity": 1,
            //             "status": "In Review",
            //             "inventoryMaster": "615ae6c6954fe735a49a05a2",
            //             "isApproved": false,
            //             "createdOn": "2021-12-16T05:25:27.977Z"
            //         }
            //     }
            // ]
        }
    )
    const [topPerformingMonth, setTopPerformingMonth] = useState(moment(new Date).format("YYYY-MM"))
    const [revenueMonth, setRevenueMonth] = useState(moment(new Date).format("YYYY-MM"))
    const [topPerformingLoading, setTopPerformingLoading] = useState(false)
    const [earnedRevenueLoading, setEarnedRevenueLoading] = useState(false)
    const [brandRequestLoading, setBrandRequestLoading] = useState(false)
    const [refetchTabData, setRefetchTabData] = useState(false)
    const [showImagePreview, setShowImagePreview] = useState(false)
    const [selectedImageIndex, setSelectedImageIndex] = useState(0)
    const [selectedRequestForModal, setSelectedRequestForModal] = useState<any>(null)
    const [showBrandRequestDetailModal, setShowBrandRequestDetailModal] = useState(false)
    const [overlay2, setOverlay2] = useState(false)
    useEffect(() => {
        if ( !showBrandRequestDetailModal && !showModalApproveActive &&
            !showConfirmRejectModal && !showThankyouModal) {
            setOverlay(false)
        }
    }, [showModalApproveActive, showBrandRequestDetailModal, showConfirmRejectModal, showThankyouModal])
    
    // useEffect(() => {
    //     setInventoryToolTipStatus(parentData?.toolTipProgress?.inventory)
    // }, [])
    // useEffect(() => {
    //     let steps = []
    //     if(context.user.role === "schoolPrincipal"){
    //         steps = [
    //         ]
    //     }
    //     else{
    //         steps = []
    //     }
    //     setOnboarding({...onBoarding, steps: [...steps]})
    // }, [])
    useEffect(() => {
        if(history.location.state && history.location.state){
            let data:any = history.location.state
            if(data.updated){
                setInventoryUpdateMsg(data.updated)
                setShowInventoryUpdateMsg(true)
                setOverlay(true)
            }
        }
    }, [])
    useEffect(() => {
        const browser = detect();
        if (browser) {
            // console.log("browser...", browser.name)
            setIsChrome(browser.name === "chrome")
        }
    }, [])

    useEffect(() => {
        getCampaignData()
        setActiveStatusName("")
    }, [])
    useEffect(() => {
        if(currentPage !== 1){
            setCurrentPage(1)
        }
    }, [activeStatusName, searchValue])
    useEffect(() => {
        if(searchValue !== ""){
            setSearchValue("")
        }
    }, [activeStatusName])

    useEffect(() => {
        if(toolTipLoaded && inventoryList.length > 0 && !isInventoryListLoading){
            setTimeout(() => {
                setOnboarding({...onBoarding, stepsEnabled: parentData?.toolTipProgress?.inventory})
            }, 100)
        }
    }, [toolTipLoaded, inventoryList])

    useEffect(() => {
        if(activeStatusName === "Events"){
            getEventsList("")
        }
        else{
            getInventoryList(false)
        }
        // getEventsList("");

    }, [refetch])
    useEffect(() => {
        getInventoryListWithCount(true)
    }, [])

    useEffect(() => {
        // console.log("1month>>>1",topPerformingMonth);
        if(context.user.role === "schoolPrincipal"){
            fetchTabData(topPerformingMonth, "topPerformingInventory");
        }
    }, [topPerformingMonth, refetchTabData])

    useEffect(() => {
        // console.log("1month>>>2",revenueMonth);
        if(context.user.role === "schoolPrincipal"){
            fetchTabData(revenueMonth, "revenueEarned");
        }
    }, [revenueMonth, refetchTabData])

    useEffect(() => {
        // console.log("month>>>",);
        if(context.user.role === "schoolPrincipal"){
            fetchTabData(moment(new Date).format("YYYY-MM"), "brandRequest");
        }
    }, [refetchTabData])

    useEffect(() => {
        if(activeStatusName === "Not Added"){
            getToBeAddedInventoryList("")
        }else if(activeStatusName === "Events"){
            getEventsList("");
        }else{
            getInventoryList(false);
        }
    }, [currentPage, activeStatusName, searchValue])
    // useEffect(() => {
    //     // setFetchAllCounts(false)
    //     if(activeStatusName === "Not Added"){
    //         getToBeAddedInventoryList("")
    //     }else if(activeStatusName === "Events"){
    //         getEventsList("");
    //     }else{
    //         getInventoryList(false);
    //     }
    // }, [searchValue])

    const fetchTabData = async (month, type) =>{
        // console.log("fetchData month>>>",month);
        if(type === "topPerformingInventory") setTopPerformingLoading(true)
        if(type === "revenueEarned") setEarnedRevenueLoading(true)
        if(type === "brandRequest") setBrandRequestLoading(true)
        
        let resp = await context.apis[context.user.role].topPerformingInventory(
            context.validator, {month: moment(month, "YYYY-MM").format("MM-YYYY")}
        )
        console.log("topPerformingInventory",resp);
        if(resp && resp.done){
            // console.log("type--",type);
            if(type === "topPerformingInventory"){
                setTopPerformingInventoryData({
                    "inventory": {...resp.response.inventory},
                    "percentage":  resp.response.percentage,
                    "inventoryRevenue": resp.response.inventoryRevenue,
                    "inventoryRevenueGrowth": resp.response.inventoryRevenueGrowth || 0
                })
                
            }
            if(type === "revenueEarned"){
                setRevenueEarnedData({
                    "totalRevenue": resp.response.totalRevenue,
                    "brandRevenueContribution": resp.response.brandRevenueContribution,
                    "totalRevenueGrowth": resp.response.totalRevenueGrowth || 0
                })
            }
            if(type === "brandRequest"){
                setUpcomingBrandReqData({
                    ...resp.response.campaign,
                })
            }
        }
        setTopPerformingLoading(false)
        setEarnedRevenueLoading(false)
        setBrandRequestLoading(false)
    }

    const openModelApproveActive = async(inventoryData) =>{
        setRequestedInventoryName(inventoryData.name)
        let resp = await context.apis[context.user.role].createUserNotification(
            context.validator, { 
                message: 'New Inventory Add Request',
                meta: inventoryData,
                role: "schoolAdmin",
                seen: false,
                notificationType: 'newInventoryAddRequest', 
            }
        )

        if(resp && resp.done){
            setshowModalapproveActive(true)
            setOverlay(true)
        }
    }
    const closeWithoutSaving = () => {
        setshowModalapproveActive(false)
        setOverlay(false)
    }

    const updatePaginationCount = (count) => {
        // setPaginationCount([])
        let paginationsList = Math.ceil(count / pageLimit)
        let pagination = []
        for (let i = 0; i < paginationsList; i++) {
            // setPaginationCount(prev=>[
            //     ...prev,
            //     i
            // ])
            pagination.push(i)
        }
        setPaginationCount([...pagination])
    }

    const getEventsList = async(status, page?) =>{
        setIsInventoryListLoading(true)
        var localVar = Math.random()
        inventoryListGloBalVar = localVar
        let resp = await context.apis[context.user.role].getActivitiesForTable(
            context.validator, {searchValue: searchValue}, {page: page ? 1 : currentPage, limit: pageLimit, withCount: true, ...sortingObj}
            )
            // console.log(resp);
        // setActiveStatusName("Events")
        // console.log("inventoryPageEvent", resp)
        if(resp && resp.done){
            if(inventoryListGloBalVar === localVar){
                setIsInventoryListLoading(false)
                // setInventoryList([])
                let eventList = []
                resp.response.rows.map(evt=>{
                    // setInventoryList(prev=>[
                    //     ...prev,
                    //     evt
                    // ])
                    eventList.push(evt)
                })
                setInventoryList([...eventList])
                setCountOfStatus({
                    ...countOfStatus,
                    event: resp.response.count
                })
                updatePaginationCount(resp.response.count)
            }
            else{
                setIsInventoryListLoading(false)
            }
        }
    }
    const getCampaignData = async () => {
        let resp = await publicContextInitiator.getCampaignsBySchool(
            context.validator, { schoolId: context.user.school, schoolStatus: "pending", isUpcoming: true }, {}
        )
        if (resp && resp.done) {
            if (resp?.response?.allcampaigns && resp.response.allcampaigns.length > 0) {
                setBrandRequest(resp.response.allcampaigns[0])
            } else {
                setBrandRequest({})
                // setActiveCard(0)
            }
        }
    }    
    const getInventoryListWithCount = async(reCount) => {
        setIsInventoryListLoading(true)
        let resp = await context.apis[context.user.role].getDetailsForSchoolDetailsTable(
            context.validator, {school: context.user.school, status: activeStatusName, searchValue: searchValue}, {page: currentPage, limit: pageLimit, withCount: true, ...sortingObj}
        )
        
        if(resp && resp.done){
            setIsInventoryListLoading(false)
            console.log("respertyu", resp)
            let inventories = []
            resp.response.rows.map(am=>{
                // setInventoryList(prev=>[
                //     ...prev,
                //     am
                // ])
                inventories.push(am)
            })
            setInventoryList([...inventories])
            // let statusAvailable = resp.response.rows.filter(am=>am.status === "Available")
            // let statusOccupied = resp.response.rows.filter(am=>am.status === "Occupied")
            // let statusNotAdded = resp.response.rows.filter(am=>am.status === "Not Added")
            let eventsCount = 0;
            let toBeAddedCount = 0;
            if(fetchAllCounts === false){
                let toBeAddedResp = await context.apis[context.user.role].getSchoolInventoriesToBeAdded(
                    context.validator, {statusDiff: "fetch"}, {page: currentPage, limit: pageLimit, withCount: true}
                )

                let EventsCountResp = await context.apis[context.user.role].getActivitiesForTable(
                    context.validator, {}, {page: currentPage, limit: pageLimit, withCount: true}
                )
                // console.log(EventsCountResp);

                toBeAddedCount = toBeAddedResp.done && toBeAddedResp.response.count
                eventsCount = EventsCountResp.done && EventsCountResp.response.count
                if(toBeAddedCount > 0){
                    let index = inventories.findIndex(item => item.status === "Not Added")
                    if(index === -1){
                        setCompleteToolTip(true)
                    }
                    else{
                        setAddButtonIndex(index)
                        setAddButtonToolTip(true)
                    }
                }
                else{
                    setCompleteToolTip(true)
                }
                setFetchAllCounts(true)
            }
            console.log("fetchAllCount----", resp.response.count, reCount)
            if(reCount === false){
                console.log("allTabCount", countOfStatus)
                setCountOfStatus({
                    ...countOfStatus,
                    all: resp.response.count
                })
            }
            if(reCount){
                setCountOfStatus({
                    ...countOfStatus,
                    all: resp.response.count,
                    available: resp.response.countsData.available,
                    occupied: resp.response.countsData.ooccupied,
                    toBeAdded: toBeAddedCount,
                    event: eventsCount
                })
            }
            // console.log("Total record------------", resp.response.count)
            updatePaginationCount(resp.response.count)
        }
    }
    const getInventoryList = async(reCount) =>{
        setIsInventoryListLoading(true)
        let resp
        if(activeStatusName === "Not Added"){
            getToBeAddedInventoryList("")
            return
        }
        if(activeStatusName === "Event"){
            getEventsList("")
            return
        }
        var localVar = Math.random()
        inventoryListGloBalVar = localVar
        if(activeStatusName !== ""){
            console.log(sortingObj);
            resp = await context.apis[context.user.role].getDetailsForSchoolDetailsTable(
                context.validator, {school: context.user.school, status: activeStatusName, searchValue: searchValue}, {page: currentPage, limit: pageLimit, withCount: true, ...sortingObj}
            )
        }
        else{
            console.log(sortingObj)
            resp = await context.apis[context.user.role].getDetailsForSchoolDetailsTable(
                context.validator, {school: context.user.school, searchValue: searchValue}, {page: currentPage, limit: pageLimit, withCount: true, ...sortingObj}
            )
        }
        
        if(resp && resp.done){
            if(inventoryListGloBalVar === localVar){
                setIsInventoryListLoading(false)
                let inventories = []
                resp.response.rows.map(am=>{
                    // setInventoryList(prev=>[
                    //     ...prev,
                    //     am
                    // ])
                    inventories.push(am)
                })
                setInventoryList([...inventories])
                let statusNotAdded = resp.response.rows.filter(am=>am.status === "Not Added")
                // if(statusNotAdded.length > 0){
                //     setAddButtonToolTip(true)
                // }
                let index = inventories.findIndex(item => item.status === "Not Added")
                setAddButtonIndex(index)
                setCountOfStatus({
                    ...countOfStatus,
                    [activeStatusName === "" ? "all" : activeStatusName === "Occupied" ? "occupied" : activeStatusName === "Available" && "available"]: resp.response.count
                })
                updatePaginationCount(resp.response.count)
            }
            else{
                setIsInventoryListLoading(false)
            }
        }
        
    }

    console.log("resp----inven>>",inventoryList);
    const getToBeAddedInventoryList = async (status, page?) => {
        // setSearchValue("")
        setIsInventoryListLoading(true)
        // if(page){
        //     setCurrentPage(1)
        // }
        // setInventoryList([]);
        // setActiveStatusName(status)
        var localVar = Math.random()
        inventoryListGloBalVar = localVar
        let resp = await context.apis[context.user.role].getSchoolInventoriesToBeAdded(
            context.validator, {statusDiff: "fetch", searchValue: searchValue}, {page: page ? 1 : currentPage, limit: pageLimit, withCount: true, ...sortingObj}
        )
        if(resp && resp.done){
            if(inventoryListGloBalVar === localVar){
                setIsInventoryListLoading(false)
                let inventories = []
                resp.response.rows.map(am=>{
                    // setInventoryList(prev=>[
                    //     ...prev,
                    //     am
                    // ])
                    inventories.push(am)
                })
                setInventoryList([...inventories])
                setCountOfStatus({
                    ...countOfStatus,
                    toBeAdded: resp.response.count
                })
                updatePaginationCount(resp.response.count)
            }
            else{
                setIsInventoryListLoading(false)
            }
        }
    }
    const sortTable = async (columnName) => {
        let sortObject={
            sort: {}
        }
        sortObject.sort[columnName] = sortingValue ? 1 : -1;
        setSortingValue(!sortingValue)
        // setSortingObj({columnName: sortingValue ? 1 : -1})
        setSortingObj({sort: {[columnName]: sortingValue ? 1 : -1}})
        setIsInventoryListLoading(true)
        console.log(sortObject);
        if(activeStatusName === "Not Added"){
            let resp = await context.apis[context.user.role].getSchoolInventoriesToBeAdded(
                context.validator, {statusDiff: "sort"}, {page: currentPage, limit: pageLimit, withCount: true, ...sortObject}
            )
            if(resp && resp.done){
                setIsInventoryListLoading(false)
                // setInventoryList([]);
                let inventories = []
                resp.response.rows.map(am=>{
                    // setInventoryList(prev=>[
                    //     ...prev,
                    //     am
                    // ])
                    inventories.push(am)
                })
                setInventoryList([...inventories])
                updatePaginationCount(resp.response.count)
            }
        }else if(activeStatusName === "Events"){
            let resp = await context.apis[context.user.role].getActivitiesForTable(
                context.validator, {}, {page: currentPage, limit: pageLimit, withCount: true, ...sortObject})
            // setActiveStatusName("Events")
            // console.log(resp);
            if(resp && resp.done){
                setIsInventoryListLoading(false)
                // setInventoryList([])
                let eventList = []
                resp.response.rows.map(evt=>{
                    // setInventoryList(prev=>[
                    //     ...prev,
                    //     evt
                    // ])
                    eventList.push(evt)
                })
                setInventoryList([...eventList])
                updatePaginationCount(resp.response.count)
            }
        }else{
            let resp = await context.apis[context.user.role].getDetailsForSchoolDetailsTable(
                context.validator, {school: context.user.school, status: activeStatusName}, {page: currentPage, limit: pageLimit, withCount: true, ...sortObject}
                )
            // -1 to desc and 1 asc sorting order
            if(resp && resp.done){
                setIsInventoryListLoading(false)
                // setInventoryList([]);
                let inventories = []
                resp.response.rows.map(am=>{
                    // setInventoryList(prev=>[
                    //     ...prev,
                    //     am
                    // ])
                    inventories.push(am)
                })
                setInventoryList([...inventories])
                updatePaginationCount(resp.response.count)
            }
        }
       
    }

    const handleSearch = async (e) =>{
        // setIsInventoryListLoading(true)
        if(e === ""){
            setSearchValue("")
        }
        else{
            setSearchValue(e.target.value.trim())
        }
        // if(activeStatusName === "Events"){
        //     let resp = await context.apis[context.user.role].getActivitiesForTable(
        //         context.validator, {searchValue: e === "" ? "" : e.target.value}, {page: currentPage, limit: pageLimit, withCount: true, ...sortingObj}
        //         )
        //         // console.log(resp);
        //     setActiveStatusName("Events")
        //     // setInventoryList([])
        //     let inventories = []
        //     if(resp && resp.done){
        //         setIsInventoryListLoading(false)
        //         resp.response.rows.map(evt=>{
        //             // setInventoryList(prev=>[
        //             //     ...prev,
        //             //     evt
        //             // ])
        //             inventories.push(evt)
        //         })
        //         setInventoryList([...inventories])
        //         updatePaginationCount(resp.response.count)
        //         setCurrentPage(1)
        //     }
        // }else{
        //     let resp = await context.apis[context.user.role].getDetailsForSchoolDetailsTable(
        //         context.validator, {school: context.user.school, searchValue: e === "" ? "" : e.target.value, status: activeStatusName}, {page: currentPage, limit: pageLimit, withCount: true, ...sortingObj}
        //         )
        //     if(resp && resp.done){
        //         setIsInventoryListLoading(false)
        //         // setInventoryList([]);
        //         let inventories = []
        //         resp.response.rows.map(am=>{
        //             // setInventoryList(prev=>[
        //             //     ...prev,
        //             //     am
        //             // ])
        //             inventories.push(am)
        //         })
        //         setInventoryList([...inventories])
        //         setCurrentPage(1)
        //         updatePaginationCount(resp.response.count)
        //     }
        // }
           
    }

    const updateInventoryStatus = async (inventoryId) => {
        
        let resp = await context.apis[context.user.role].updateInventoriesById(
            context.validator, {_id: inventoryId}, {status: "Available"}
            )
        if(resp && resp.done){
            setRefetch(!refetch)
        }
    }

    const changeInventoryListByStatus = async (status) => {
        setSearchValue("")
        setIsInventoryListLoading(true)
        setInventoryList([]);
        setCurrentPage(1)
        setActiveStatusName(status)
        let resp = await context.apis[context.user.role].getDetailsForSchoolDetailsTable(
            context.validator, {school: context.user.school, status: status}, {page: currentPage, limit: pageLimit, withCount: true}
            )
        if(resp && resp.done && resp.response.rows){
            setIsInventoryListLoading(false)
            let inventories = []
            resp.response.rows.map(am=>{
                // setInventoryList(prev=>[
                //     ...prev,
                //     am
                // ])
                inventories.push(am)
            })
            setInventoryList([...inventories])
            updatePaginationCount(resp.response.count)
        }
    }

    const addNewInventory = () => {
        history.push("/schools/school-details/add-invetory/remaining-invetories")
    }

    const fetchPageInventory = (pageNumber) => {
       setCurrentPage(pageNumber)
    }

    const updateUsersToolTipProgress = async () => {
        let userToolTipResp = await context.apis[context.user.role].updateUsersToolTipProgress(
            context.validator, {}, {toolTipProgress: {...parentData.toolTipProgress, inventory: false}}
        )
    }

    const hanldeOnboardingComplete = () => {
        if(context.user.role === "schoolPrincipal"){
            if(completeToolTip){
                updateUsersToolTipProgress()
                parentData.setToolTipProgress({
                    ...parentData.toolTipProgress,
                    inventory: false
                })
            }
            setToolTip1Completed(true)
            // setInventoryToolTipStatus(false)
            setOnboarding({...onBoarding, stepsEnabled: false})
        }
        else{
            updateUsersToolTipProgress()
            parentData.setToolTipProgress({
                ...parentData.toolTipProgress,
                inventory: false
            })
        }
    }
    const exitOnBoarding = (e) => {
        console.log(e)
    }

    const onCardLinkClick = (index) => {
        if(activeCard === index){
            setActiveCard("")
        }
        else{
            setActiveCard(index)
        }
    }

    const rejectBrandRequest = () => {
        setOverlay(true)
        setShowConfirmRejectModal(true)
    }

    const approveBrandRequest = () => {
        setshowModalApproveActive(true)
        setOverlay(true)  
    }

    const viewBrandDetails = () => {
        setOverlay(false)
        setshowModalApproveActive(false)
    }

    const approveRequest = async () => {
        setshowModalApproveActive(false)
        const json = {
            data: {
                data: {
                    schoolStatus: "accepted",
                },
                args: {
                    campaignId: upcomingBrandReqData._id,
                }
            }
        }
        const result = await context.apis[context.user.role].acceptCampaignRequest(context.validator, json.data.args, json.data.data)
        // console.log("result>>>>",result);
        if (result && result?.done === true) {
            setOverlay(false)
            setThankyouTitle("Campaign Approval!")
            setThankyouDescription("Our brand champions have approved the content and with your final approval, we will go live with the campaign in 48 hours.")
            setShowThankyouModal(true)
            getCampaignData()
            setRefetchTabData(!refetchTabData)
        } else {
            setOverlay(false)
            toast.error("Something went wrong")
        }
    }

    const onRejectRequest = async (reason) => {
        const json = {
            data: {
                data: {
                    schoolStatus: "rejected",
                    rejectionMessage: reason
                },
                args: {
                    campaignId: upcomingBrandReqData._id,
                }
            }
        }
        const result = await context.apis[context.user.role].rejectCampaignRequest(context.validator, json.data.args, json.data.data)
        if (result && result?.done === true) {
            closeAllModals()
            setThankyouTitle("Thank You!")
            setThankyouDescription("We have registered your concern and a representative from our team will connect with you within 24 hours to discuss this further.")
            setShowThankyouModal(true)
            setOverlay(true)
            getCampaignData()
            setRefetchTabData(!refetchTabData)
        } else {
            setOverlay(false)
            toast.error("Something went wrong")
        }
        setShowConfirmRejectModal(false)
    }

    const closeThankyouModal = () => {
        setShowThankyouModal(false)
        setOverlay(false)
        setThankyouTitle("")
        setThankyouDescription("")
    }
    const closeAllModals = () => {
        setShowConfirmRejectModal(false)
        setshowModalApproveActive(false)
        setSelectedRequestForModal(false)
        // setShowConfirmedApprovalModal(false)
        setShowBrandRequestDetailModal(false)
        setShowConfirmRejectModal(false)
    }

    const closeApprovalModal = () => {
        setOverlay(false)
        setshowModalApproveActive(false)
    }

    const closeConfirmRejectModal = () => {
        setShowConfirmRejectModal(false)
        // setOverlay(false)
        setOverlay2(false)
    }

    const onNextClick = () => {
        setCurrentPage(currentPage + 1)
    }

    const onPrevClick = () => {
        setCurrentPage(currentPage - 1)
    }
    const resetSearchValue = () => {
        setSearchValue("")
    }

    const handleMonthChange = (e) => {
        // console.log("e.target.value", e.target.value, e.target.name);
        if(e.target.name === "month"){
            setTopPerformingMonth(e.target.value)
        }
        if(e.target.name === "revenueMonth"){
            setRevenueMonth(e.target.value)
        }
    }

    const openImagePreview = (index) => {
        setSelectedImageIndex(index)
        setShowImagePreview(true)
    }
    const openDetailModal = () => {
        setOverlay2(false)
        setOverlay(true)
        setShowBrandRequestDetailModal(true)
        setSelectedRequestForModal(upcomingBrandReqData)
    }
    const closedetailsModal = () => {
        setShowBrandRequestDetailModal(false)
        // setOverlay(false)
        // setOverlay2(false)

    }
    const openRejectModal = () => {
        setOverlay(true)
        setOverlay2(true)
        setShowConfirmRejectModal(true)
    }
    const openApprovalModal = () => {
        setShowBrandRequestDetailModal(false)
        setshowModalApproveActive(true)
        setOverlay(true)
    }

    const limitStringLength = (string: String, limit: number) => {
        return string.split("").slice(0, limit).join("")
    }
    return (
        <>
            <div className={`ovelap ${overlay === true ? "active" : ""}`}></div>
            <div className="col-xl-10 col-lg-12 col-md-12 col-xs-12">
                <div className="rightPart noBack">
                <div className="row">
                <div className="col-xs-12 col-lg-12 col-md-12 col-xs-12">
                        <OnboardingBackBtn
                            link="/"
                            text="Log Out"
                        />
                    </div>
                    <TopBar />
                    <div className={`col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-20 mb-10 ${context.user.role === "schoolAdmin" ? "dnone" : ""}`}>
                        <h2 className="htx1 clearfix">Inventory Overview</h2>  
                    </div>
                    <div className={`col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-20 ${context.user.role === "schoolAdmin" ? "dnone" : ""}`}>
                        <div className="inventory-over">
                            <ul className="inventory-overTabs">
                                <li>
                                    <a onClick={() => onCardLinkClick(0)} id="topPermormingInventory" href="javascript:void" className={`tabBorderColor1 ${activeCard === 0 ? "active" : ""}`}>
                                        <p>Top Performing Inventory ({moment(topPerformingMonth, "YYYY-MM").format("MMM'YY")})</p>
                                        {/* <h3>Assembly Hall 01</h3> */}
                                        {
                                            topPerformingLoading
                                            ?
                                            <div className="content-loader mt10"></div>
                                            :
                                            <h3>{topPerformingInventoryData.inventory && topPerformingInventoryData.inventory.name || "N/A"}</h3>
                                        }
                                        <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M1 6L6 0.999999L11 6" stroke="#8C8C8C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                                    </a>
                                </li>
                                <li>
                                    <a onClick={() => onCardLinkClick(1)} id="revenueEarned" href="javascript:void" className={`tabBorderColor2 ${activeCard === 1 ? "active" : ""} ${revenueEarnedData?.totalRevenueGrowth < 0 ? "redDownPercentage" : revenueEarnedData?.totalRevenueGrowth > 0 ? "greenUpPercentage" : "removeArrow"}`}>
                                        {
                                            // console.log("revenueMonth>>>",revenueMonth)
                                        }
                                        <p>Revenue Earned ({moment(revenueMonth, "YYYY-MM").format("MMM'YY")})</p>
                                        {
                                            earnedRevenueLoading
                                            ?
                                            <div className="content-loader mt10"></div>
                                            :
                                            <>
                                                {
                                                    revenueEarnedData.totalRevenue ? 
                                                    <h3>₹ {revenueFormatter(revenueEarnedData.totalRevenue) || 0} <span><b>{Math.abs(revenueEarnedData?.totalRevenueGrowth) || 0}% <img className="arrowUp" src={arrowUp} /><img className="arrowDown" src={arrowDown} /> </b> than last 30 days</span></h3>
                                                    :
                                                    <h3>N/A</h3>
                                                }
                                            </>
                                        }
                                        
                                        <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M1 6L6 0.999999L11 6" stroke="#8C8C8C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                                    </a>
                                </li>
                                {
                                    // !isObjectEmpty(upcomingBrandReqData) &&
                                    <li>
                                        <a onClick={() => onCardLinkClick(2)} id="upcomingBrandReq" href="javascript:void" className={`tabBorderColor3 ${activeCard === 2 ? "active" : ""}`}>
                                            <p>Upcoming Brand Request</p>
                                            {
                                                brandRequestLoading
                                                ?
                                                    <div className="content-loader mt10"></div>
                                                :
                                                    <h3>{upcomingBrandReqData?.campaignName ? activeCard !== 2 && upcomingBrandReqData?.campaignName.length > 25 ? `${limitStringLength(upcomingBrandReqData?.campaignName, 25)} ...` : upcomingBrandReqData?.campaignName : "N/A"}</h3>
                                            }
                                            <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M1 6L6 0.999999L11 6" stroke="#8C8C8C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                                        </a>
                                    </li>
                                }
                            </ul>
                            {activeCard !== "" &&
                                <div className={`inventory-overInner ${activeCard === 0 ? "overTabs01" : activeCard === 1 ? "overTabs02" : activeCard === 2 ? "overTabs03" : ""}`}>
                                    {
                                        activeCard === 0 &&
                                        <div className="inventory-overTabs01">
                                            <div className="row">
                                                {
                                                    topPerformingLoading
                                                    ?
                                                    <div className="content-loader"></div>
                                                    :
                                                    <>
                                                        <div className="col-lg-2 col-md-2 col-xs-12">
                                                            <div className="inventory-overTabs01L">
                                                                <img src={topPerformingInventoryData.inventory && topPerformingInventoryData.inventory.images && topPerformingInventoryData.inventory.images.length >= 1 ? topPerformingInventoryData.inventory.images[0].image : imgInventory}/>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-10 col-md-10 col-xs-12 flex-between">
                                                            <div className="row">
                                                                <div className="col-lg-3 col-md-4 col-xs-12">
                                                                    <div className="inventory-overTabs01R">
                                                                        {isChrome ? 
                                                                            <input type="month" name="month" value={topPerformingMonth} onChange={(e) => handleMonthChange(e)} />
                                                                            :
                                                                            <DatePicker
                                                                                dateFormat="MM/yyyy"
                                                                                showMonthYearPicker
                                                                                name="month"
                                                                                selected={new Date(topPerformingMonth)}
                                                                                onChange={(date) => setTopPerformingMonth(moment(date, "YYYY-MM").format("YYYY-MM"))}
                                                                            />
                                                                        }
                                                                        {/* <select name="month" value={topPerformingMonth} onChange={(e) => handleMonthChange(e)}>
                                                                            <option value="01-2021">Jan 21</option>
                                                                            <option value="02-2021">Feb 21</option>
                                                                            <option value="03-2021">Mar 21</option>
                                                                            <option value="04-2021">Apr 21</option>
                                                                            <option value="05-2021">May 21</option>
                                                                            <option value="06-2021">Jun 21</option>
                                                                            <option value="07-2021">Jul 21</option>
                                                                            <option value="08-2021">Aug 21</option>
                                                                            <option value="09-2021">Sep 21</option>
                                                                            <option value="10-2021">Oct 21</option>
                                                                            <option value="11-2021">Nov 21</option>
                                                                            <option value="12-2021">Dec 21</option>
                                                                        </select> */}
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-12 col-md-12 col-xs-12"></div>
                                                                {
                                                                    topPerformingInventoryData.inventory && topPerformingInventoryData.inventory.brand
                                                                    ?
                                                                    <>
                                                                    <div className="col-lg-4 col-md-4 col-xs-12">
                                                                        <div className={`inventory-overHead ${topPerformingInventoryData.inventoryRevenueGrowth < 0 ? "redDownPercentage" : topPerformingInventoryData.inventoryRevenueGrowth > 0 ? "greenUpPercentage" : "removeArrow"}`}>
                                                                            <p>Revenue Earned ({moment(topPerformingMonth, "YYYY-MM").format("MMM'YY")})</p>
                                                                            <h3>₹ {revenueFormatter(topPerformingInventoryData.inventoryRevenue) || 0} <span><b>{Math.abs(topPerformingInventoryData?.inventoryRevenueGrowth) || 0}% <img className="arrowUp" src={arrowUp} /><img className="arrowDown" src={arrowDown} /></b> than last 30 days</span></h3>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-4 col-md-4 col-xs-12">
                                                                    <div className="inventory-overHead">
                                                                        <p>Avg Occupancy Rate (Monthly)</p>
                                                                        {/* <h3>₹ 213,457</h3> */}
                                                                        <h3>{topPerformingInventoryData && topPerformingInventoryData.percentage || 0}%</h3>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-4 col-md-4 col-xs-12">
                                                                        <div className="inventory-overHead">
                                                                            <p>Booked By :</p>
                                                                            <h3>{topPerformingInventoryData.inventory && topPerformingInventoryData.inventory.brand || "-"}</h3>
                                                                        </div>
                                                                    </div>
                                                                    </>
                                                                    :
                                                                    <div className="col-lg-12 col-md-12 col-xs-12">
                                                                        <div className="inventory-overTabs01L">
                                                                            <p>No last campaign data available for analysis.</p>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                
                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                                
                                            </div>
                                            
                                            
                                        </div>
                                    }
                                    {
                                        activeCard === 1 &&
                                        <div className="inventory-overTabs02">
                                            <div className="row">
                                                {
                                                    earnedRevenueLoading
                                                    ?
                                                    <div className="content-loader"></div>
                                                    :
                                                    <>
                                                        <div className="col-lg-4 col-md-12 col-xs-12">
                                                        <div className="row">
                                                            <div className="col-lg-8 col-md-9 col-xs-12">
                                                                <div className="inventory-overTabs01R">
                                                                    {isChrome ?
                                                                        <input type="month" value={revenueMonth} name="revenueMonth" onChange={(e) => handleMonthChange(e)} />
                                                                        :
                                                                        <DatePicker
                                                                            dateFormat="MM/yyyy"
                                                                            showMonthYearPicker
                                                                            name="revenueMonth"
                                                                            selected={new Date(revenueMonth)}
                                                                            onChange={(date) => setRevenueMonth(moment(date, "YYYY-MM").format("YYYY-MM"))}
                                                                        />
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                        <div className="col-lg-12 col-md-12 col-xs-12 mt-30"></div>
                                                        {
                                                            revenueEarnedData.totalRevenue
                                                            ?
                                                            <div className="col-lg-12 col-md-12 col-xs-12">
                                                                <div className="inventory-overHead">
                                                                    <p>Earned Revenue till date</p>
                                                                    <h3>₹ {revenueFormatter(revenueEarnedData.totalRevenue) || 0}</h3>
                                                                </div>
                                                            </div>
                                                            :
                                                            ""
                                                        }
                                                        
                                                    </div>
                                                    {
                                                            revenueEarnedData.totalRevenue
                                                            ?
                                                            <div className="col-lg-8 col-md-12 col-xs-12">
                                                                <InventoryRevenueChart apiData={revenueEarnedData} />
                                                            </div>
                                                            :
                                                            <div className="col-lg-12 col-md-12 col-xs-12">
                                                                <div className="inventory-overTabs01L">
                                                                    <p>No last campaign data available for analysis.</p>
                                                                </div>
                                                            </div>
                                                    }
                                                    </>
                                                }
                                                </div>
                                                
                                        </div>
                                    }
                                    {
                                       activeCard === 2 ?
                                       !isObjectEmpty(upcomingBrandReqData) ?
                                        <div className="inventory-overTabs03" >
                                            <div className="row">
                                                {
                                                    brandRequestLoading
                                                    ?
                                                        <div className="content-loader"></div>
                                                    :
                                                    <>
                                                        <div className="col-lg-3 col-md-3 col-xs-12">
                                                        <div className="inventory-overTabs01L">
                                                            <div className="tabSlide1-box">
                                                                {/* {
                                                                    upcomingBrandReqData.campaignCreativesRevised &&
                                                                    upcomingBrandReqData.campaignCreativesRevised.length > 0 ?
                                                                    upcomingBrandReqData.campaignCreativesRevised.map((img, i)=>{
                                                                        return(
                                                                        
                                                                            i === 0
                                                                            ?
                                                                                <div className="campaignRequestSlideLarge">
                                                                                    <img onClick={() => openImagePreview(i)} src={img.image || imgInventory} />
                                                                                </div>
                                                                            :
                                                                            ""
                                                                        )
                                                                    })
                                                                    :
                                                                    upcomingBrandReqData.campaignCreatives && 
                                                                    upcomingBrandReqData.campaignCreatives.length > 0 &&
                                                                    upcomingBrandReqData.campaignCreatives.map((img, i)=>{
                                                                        return(
                                                                        
                                                                            i === 0
                                                                            ?
                                                                                <div className="campaignRequestSlideLarge">
                                                                                    <img onClick={() => openImagePreview(i)} src={img.image || imgInventory} />
                                                                                </div>
                                                                            :
                                                                            ""
                                                                        )
                                                                    })
                                                                } */}
                                                                {/* <ul>
                                                                <div className="campaignRequestSlideSmall">
                                                                    {
                                                                        upcomingBrandReqData.campaignCreativesRevised &&
                                                                        upcomingBrandReqData.campaignCreativesRevised.length > 0 ?
                                                                        upcomingBrandReqData.campaignCreativesRevised.map((img, i)=>{
                                                                            // console.log("upcomingBrandReqData.campaignCreatives.length-",upcomingBrandReqData.campaignCreatives.length);
                                                                            return(
                                                                                <>
                                                                                {
                                                                                    i === 0
                                                                                    ?
                                                                                    ""
                                                                                    :
                                                                                    // i  < 5
                                                                                    // ?
                                                                                    <li><a href="javascript:void(0);" onClick={() => openImagePreview(i)}><img src={img.image || imgInventory} /></a></li>
                                                                                    // :
                                                                                    // upcomingBrandReqData.campaignCreatives.length > 4
                                                                                    // ?
                                                                                    // <li><a href="javascript:void(0);" onClick={() => openImagePreview(5)}><img src={img.image || imgInventory} /><span>+ {upcomingBrandReqData.campaignCreatives.length - 5}</span></a></li>
                                                                                    // :
                                                                                    // ""
                                                                                }
                                                                                </>
                                                                            )
                                                                        })
                                                                        :
                                                                        upcomingBrandReqData.campaignCreatives && 
                                                                        upcomingBrandReqData.campaignCreatives.length > 0 ?
                                                                        upcomingBrandReqData.campaignCreatives.map((img, i)=>{
                                                                            // console.log("upcomingBrandReqData.campaignCreatives.length-",upcomingBrandReqData.campaignCreatives.length);
                                                                            return(
                                                                                <>
                                                                                {
                                                                                    i === 0
                                                                                    ?
                                                                                    ""
                                                                                    :
                                                                                    // i  < 5
                                                                                    // ?
                                                                                    <li><a href="javascript:void(0);" onClick={() => openImagePreview(i)}><img src={img.image || imgInventory} /></a></li>
                                                                                    // :
                                                                                    // upcomingBrandReqData.campaignCreatives.length > 4
                                                                                    // ?
                                                                                    // <li><a href="javascript:void(0);" onClick={() => openImagePreview(5)}><img src={img.image || imgInventory} /><span>+ {upcomingBrandReqData.campaignCreatives.length - 5}</span></a></li>
                                                                                    // :
                                                                                    // ""
                                                                                }
                                                                                </>
                                                                            )
                                                                        })
                                                                        :
                                                                        ""
                                                                    }
                                                                </div>
                                                                </ul> */}
                                                                {
                                                                // upcomingBrandReqData.campaignCreativesRevised && upcomingBrandReqData.campaignCreativesRevised.length > 0 ?
                                                                    // <div className="campaingCretivesTwo inventoryTable">
                                                                    //     <div className="campaignRequestSlide campaignDetailSlide">
                                                                    //         <div className="campaignRequestSlideLarge">
                                                                    //             <img style={{ cursor: "pointer", height: '100%' }} src={(upcomingBrandReqData && upcomingBrandReqData.campaignCreativesRevised && upcomingBrandReqData.campaignCreativesRevised.length>0 && upcomingBrandReqData.campaignCreativesRevised[0].image) ? upcomingBrandReqData.campaignCreativesRevised[0].image : imgInventory } onClick={() => (upcomingBrandReqData && upcomingBrandReqData.campaignCreativesRevised && upcomingBrandReqData.campaignCreativesRevised.length>0 && upcomingBrandReqData.campaignCreativesRevised[0].image) && openImagePreview(0)} />
                                                                    //         </div>
                                                                    //         <div className="campaignRequestSlideSmall">
                                                                    //             <img style={{ cursor: "pointer", height: '100%' }} src={(upcomingBrandReqData && upcomingBrandReqData.campaignCreativesRevised && upcomingBrandReqData.campaignCreativesRevised.length>1 && upcomingBrandReqData.campaignCreativesRevised[1].image) ? upcomingBrandReqData.campaignCreativesRevised[1].image : imgInventory } onClick={() => (upcomingBrandReqData && upcomingBrandReqData.campaignCreativesRevised && upcomingBrandReqData.campaignCreativesRevised.length>1 && upcomingBrandReqData.campaignCreativesRevised[1].image) && openImagePreview(1)} />
                                                                    //             <img style={{ cursor: "pointer", height: '100%' }} src={(upcomingBrandReqData && upcomingBrandReqData.campaignCreativesRevised && upcomingBrandReqData.campaignCreativesRevised.length>2 && upcomingBrandReqData.campaignCreativesRevised[2].image) ? upcomingBrandReqData.campaignCreativesRevised[2].image : imgInventory } onClick={() => (upcomingBrandReqData && upcomingBrandReqData.campaignCreativesRevised && upcomingBrandReqData.campaignCreativesRevised.length>2 && upcomingBrandReqData.campaignCreativesRevised[2].image) && openImagePreview(2)} />
                                                                    //         </div>
                                                                    //         {/* <p>Original Creatives</p> */}
                                                                    //     </div>
                                                                    // </div>
                                                                    // :
                                                                    <div className="campaingCretivesTwo inventoryTable">
                                                                        <div className="campaignRequestSlide campaignDetailSlide">
                                                                            <div className="campaignRequestSlideLarge">
                                                                                <img style={{ cursor: "pointer", height: '100%' }} src={(upcomingBrandReqData && upcomingBrandReqData.campaignCreatives && upcomingBrandReqData.campaignCreatives.length>0 && upcomingBrandReqData.campaignCreatives[0].image) ? upcomingBrandReqData.campaignCreatives[0].image : imgInventory } onClick={() => (upcomingBrandReqData && upcomingBrandReqData.campaignCreatives && upcomingBrandReqData.campaignCreatives.length>0 && upcomingBrandReqData.campaignCreatives[0].image) && openImagePreview(0)} />
                                                                            </div>
                                                                            <div className="campaignRequestSlideSmall">
                                                                                <img style={{ cursor: "pointer", height: '100%' }} src={(upcomingBrandReqData && upcomingBrandReqData.campaignCreatives && upcomingBrandReqData.campaignCreatives.length>1 && upcomingBrandReqData.campaignCreatives[1].image) ? upcomingBrandReqData.campaignCreatives[1].image : imgInventory } onClick={() => (upcomingBrandReqData && upcomingBrandReqData.campaignCreatives && upcomingBrandReqData.campaignCreatives.length>1 && upcomingBrandReqData.campaignCreatives[1].image) && openImagePreview(1)} />
                                                                                <img style={{ cursor: "pointer", height: '100%' }} src={(upcomingBrandReqData && upcomingBrandReqData.campaignCreatives && upcomingBrandReqData.campaignCreatives.length>2 && upcomingBrandReqData.campaignCreatives[2].image) ? upcomingBrandReqData.campaignCreatives[2].image : imgInventory } onClick={() => (upcomingBrandReqData && upcomingBrandReqData.campaignCreatives && upcomingBrandReqData.campaignCreatives.length>2 && upcomingBrandReqData.campaignCreatives[2].image) && openImagePreview(2)} />
                                                                            </div>
                                                                            {/* <p>Original Creatives</p> */}
                                                                        </div>
                                                                    </div>
                                                                }
                                                                
                                                            </div>
                                                            <ImagePreview
                                                                setShowImagePreview={setShowImagePreview}
                                                                showImagePreview={showImagePreview}
                                                                images={upcomingBrandReqData.campaignCreatives}
                                                                selectedImageIndex={selectedImageIndex}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-9 col-md-9 col-xs-12 flex-between">
                                                        <div className="row item-end">
                                                            <div className="col-lg-5 col-md-5 col-xs-12">
                                                                <div className="row align-items-end">
                                                                    <div className="col-lg-12 col-md-12 col-xs-12">
                                                                        <div className="inventory-overHead">
                                                                            <p>Upcoming Brand Request</p>
                                                                            <h3>{upcomingBrandReqData?.campaignName}</h3>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-12 col-md-12 col-xs-12 mt-30"></div>
                                                                    <div className="col-lg-12 col-md-12 col-xs-12">
                                                                        <div className="inventory-overHead">
                                                                            <p>Booked Inventory / Events</p>
                                                                            <label>{[...upcomingBrandReqData.inventories, ...upcomingBrandReqData.activities].map(inventory => inventory.inventory ? inventory.inventory.name : inventory.activity.name).join(", ")}</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-4 col-xs-12">
                                                                <div className="brand-creat-img">
                                                                    <h3>Duration</h3>
                                                                    <div className="starend">
                                                                        <p>Start <b>{formatDateToDDMMMYY(upcomingBrandReqData.startDate)}</b></p>
                                                                        <img className="next-arow" src={riarrownext}/>
                                                                        <p>End <b>{formatDateToDDMMMYY(upcomingBrandReqData.endDate)}</b></p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {
                                                                upcomingBrandReqData?.campaignEstimatedRevenue &&
                                                                <div className="col-lg-3 col-md-3 col-xs-12">
                                                                    <div className="inventory-overHead">
                                                                        <p>Estimated Revenue</p>
                                                                        <h3>{revenueFormatter(upcomingBrandReqData.campaignEstimatedRevenue)}</h3>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                        <div className="col-lg-12 col-md-12 col-xs-12">
                                                            <div className="brand-btn">
                                                                <a onClick={openDetailModal} href="javascript:void(0);" className="btn btn-outline">View Details</a>
                                                                <a onClick={approveBrandRequest} href="javascript:void(0)" className="btn btn-orange">Approve</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    </>
                                                }
                                                
                                            </div>
                                        </div>
                                        :
                                        brandRequestLoading
                                        ?
                                        <div className="content-loader"></div>
                                        :
                                        <div className="inventory-overTabs03" >
                                            <div className="row">
                                                <div className="col-lg-3 col-md-3 col-xs-12">
                                                    <div className="inventory-overTabs01L">
                                                        <p>No brand request found.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        ""
                                    }
                                </div>
                            }
                        </div>  
                    </div>
                    <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-30 mb-10">
                        <h2 className="htx1 clearfix">Inventory List</h2>  
                    </div>
                    <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                        <div className="inventory-tableTabs">
                            <ul className="tabsinventory">
                                <li><a href="javascript:void(0);" onClick={()=> setActiveStatusName("")} className={`${activeStatusName === "" && "active"}`}>All({countOfStatus.all})</a></li>
                                <li><a href="javascript:void(0);" onClick={()=> setActiveStatusName("Occupied")} className={`${activeStatusName === "Occupied" && "active"}`}>Occupied({countOfStatus.occupied})</a></li>
                                <li><a href="javascript:void(0);" onClick={()=> setActiveStatusName("Available")} className={`${activeStatusName === "Available" && "active"}`}>Available({countOfStatus.available})</a></li>
                                <li><a href="javascript:void(0);" onClick={()=> setActiveStatusName("Not Added")} className={`${activeStatusName === "Not Added" && "active"}`}>To Be Added({countOfStatus.toBeAdded})</a></li>
                                <li><a href="javascript:void(0);" onClick={()=> setActiveStatusName("Events")} className={`${activeStatusName === "Events" && "active"}`}>Events({countOfStatus.event})</a></li>
                            </ul> 
                            <div className="inventorySearch">
                                <CustomSearchInput
                                    searchInpuOpen={searchInpuOpen}
                                    setSearchInpuOpen={setSearchInpuOpen}
                                    clearSearchIcon={clearSearchIcon}
                                    setClearSearchIcon={setClearSearchIcon}
                                    searchKeys={searchValue}
                                    handleChange={handleSearch}
                                    palaceholder="Search"
                                />
                            <a id="addInventoryBtn" onClick={addNewInventory} href="javascript:void(0)" className="btn btn-orange">
                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M6 1V11" stroke="white" stroke-width="1.5" stroke-linecap="round"/> <path d="M1 6L11 6" stroke="white" stroke-width="1.5" stroke-linecap="round"/> </svg>
                            </a>
                            </div>
                            <div className="table-container table-inventory mt-10">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Inventory Name <a href="javascript:void(0);" onClick={()=>sortTable("name")}><img src={tableFilter} /></a></th>
                                            <th>Revenue Contribution <a href="javascript:void(0);" onClick={()=>sortTable("revenueContribution")}><img src={tableFilter} /></a></th>
                                            <th>Booking Schedule <a href="javascript:void(0);" onClick={()=>sortTable("bookingSchedule")}><img src={tableFilter} /></a></th>
                                            <th>Status <a href="javascript:void(0);" onClick={()=>sortTable("status")}><img src={tableFilter} /></a></th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            isInventoryListLoading ?
                                            [...Array(15)].map((el, index) => {
                                                return (
                                                    <tr>
                                                        {
                                                            [...Array(5)].map((el, index) => {
                                                                return (
                                                                    <td>
                                                                        <div className="content-loader-block"></div>
                                                                    </td>
                                                                )
                                                            })
                                                        }
                                                    </tr>
                                                )
                                            })
                                            :
                                            inventoryList.length > 0 ? inventoryList.map((inventory, index)=>{
                                                return(
                                                    <InventoryCard
                                                        key={index}
                                                        index={index}
                                                        inventory={inventory}
                                                        openModelApproveActive={openModelApproveActive}
                                                        toolTip1Completed={toolTip1Completed}
                                                        addButtonToolTip={addButtonToolTip}
                                                        setRefetch={setRefetch}
                                                        refetch={refetch}
                                                        addButonIndex={addButtonIndex}
                                                        resetSearchValue={resetSearchValue}
                                                    />
                                                )
                                            })
                                            :
                                            <tr><td colSpan={5}>No inventories found.</td></tr>
                                    }
                                    </tbody>
                                </table>
                            </div>
                            {
                                (paginationCount.length > 1) &&
                                <Pagination
                                    totalPage={paginationCount.map((el, index) => index)}
                                    currentPage={currentPage}
                                    onNextClick={onNextClick}
                                    onPrevClick={onPrevClick}
                                    onPageChange={fetchPageInventory}
                                    loading={isInventoryListLoading}
                                />
                            //     <div className="paginationInventory">
                            //     <ul>
                            //         <li>
                            //             <a
                            //                 href="javascript:void(0)"
                            //                 onClick={()=> fetchPageInventory(currentPage - 1)}
                            //             ><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z"/></svg>
                            //         </a></li>
                            //         {
                            //             paginationCount.map((page,i)=>{
                            //                 return(
                            //                     <li key={i}><a href="javascript:void(0)" className={`${currentPage === page + 1 && "active"}`} onClick={()=>{fetchPageInventory(page+1)}}>{page+1}</a></li>
                            //                 )
                            //             })
                            //         }
                            //         <li>
                            //             <a
                            //                 href="javascript:void(0)"
                            //                 onClick={()=> fetchPageInventory(currentPage + 1)}
                            //             ><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z"/></svg>
                            //             </a>
                            //         </li>
                            //     </ul>
                            // </div>
                            }
                        </div>  
                    </div>
                </div>
                </div>
            </div>      
            <div id="approveActive" className={`popup popup-center ${showModalapproveActive === false ? "hide" : ""}`}>
            <div className="popup-header">
                <h3 className="htx2 pophead">Request Sent Successfully!</h3>
                <span className="close toggle" onClick={closeWithoutSaving} data-target="myPopup">close</span> </div>
                <div className="popup-body">
                    <div className="viewdetailsHead">
                        <div className="approveActive">
                            <p>A request to add {requestedInventoryName} has been sent to your school's admin. </p>
                            <div className="brand-btn">
                                <a href="javascript:void(0)" onClick={closeWithoutSaving} className="btn btn-orange">DONE</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Steps
                enabled={onBoarding.stepsEnabled}
                steps={onBoarding.steps}
                initialStep={onBoarding.initialStep}
                onComplete={hanldeOnboardingComplete}
                onExit={exitOnBoarding}
                options={onBoarding.options}
            />

            <CampaignApprovalModal
                showModalApproveActive={showModalApproveActive}
                closeWithoutSaving={closeApprovalModal}
                viewDetails={viewBrandDetails}
                approveRequest={approveRequest}
            />

            {/* <ThankyouModal
                showThankyouModal={showThankyouModal}
                closeModal={closeThankyouModal}
                modalTitle={thankyouTitle}
                description={thankyouDescription}
            /> */}

            <RejectBrandRequestModal
                showConfirmRejectModal={showConfirmRejectModal}
                closeModal={closeConfirmRejectModal}
                onRejectRequest={onRejectRequest}
            />
            <AlertDialog
                onModalClose={() => {setShowInventoryUpdateMsg(false); setOverlay(false); history.push({state: undefined})}}
                togglePopup={showInventoryUpdateMsg}
                displayMsg={inventoryUpdateMsg === "inventoryUpdated" ? "Inventory/Inventories has been added to your inventory list successfully. Brands will be able to send booking requests once it is approved." : inventoryUpdateMsg === "eventUpdated" ? "Event/Events has been added to your event list successfully. Brands will be able to send booking requests once it is approved." : inventoryUpdateMsg === "bothUpdated" && "Inventory/Event has been added to your inventory/event list successfully. Brands will be able to send booking requests once it is approved."}
                showBtn={true}
            />
            {selectedRequestForModal && 
                <BrandRequestDetailModal
                    showBrandRequestDetailModal={showBrandRequestDetailModal}
                    campaignData={selectedRequestForModal}
                    openApprovalModal={openApprovalModal}
                    openRejectModal={openRejectModal}
                    closedetailsModal={closedetailsModal}
                    overlay={overlay2}
                    fromInventoryPage={true}
                />
            }
            <ThankyouModal
                showThankyouModal={showThankyouModal}
                closeModal={closeThankyouModal}
                modalTitle={thankyouTitle}
                description={thankyouDescription}
            />
        </>
    )
}


export default Inventory
