import React, { useEffect, useState, useContext, useRef } from 'react'
import AppContextObject from "../../../common/context/common"
import { formatDateShowMonth, titleCase } from "../../../service/helper"
import DataContext from "../../../service/brandContext"

const PrivacyAndPolicy = (props) => {
    const context = useContext(AppContextObject)
    const parentData = useContext<any>(DataContext)
    useEffect(() => {
      let element = document.getElementById("privacyPolicy")
      // console.log("element", element)
      element && element.scrollIntoView({block: "start"})
    }, [props.showMou])
    return (
        <>
      <div id="termsPopup" className={`onboarding-terms popup brand show `} style={{display: `${props.showMou ? "" : "none"}`, top: "55%"}} >
        <div className="popup-header">{/*<span className="toggle left-back" data-target="termsPopup">close</span>*/} <span onClick={props.onToggle} className="close toggle" data-target="termsPopup">close</span> </div>
        <div className="popup-body">
          <div className="clearfix">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="termsBox">
                  <div id='privacyPolicy'>
                  <h2>Privacy Policy</h2>
                  <div className='termsBoxContent'>
                    <h4>1. General</h4>
                    <p>Wondrlab Martech Platforms Pvt. Ltd. (“ Wondrlab Martech”)  is committed to the protection of personal information (“Personal Information”) provided by our users (“Users”) to Wondrlab Martech. Users agree that their use of our mobile or web application of our Platform (“WISR” implies their consent to the collection, retention and use of their Personal Information in accordance with the terms of this Privacy Policy (“Privacy Policy").</p>
                    <p>No User information is sold to a third party. We use the Personal Information collected solely to provide personalized and valuable services. However, the transmission of information via internet or telephone networks is not completely secure. While  Wondrlab Martech maintains a high standard of security for all data collected especially Personal Information of our Users, Wondrlab Martech does not ensure the security of Users’ data transmitted via internet, telephone or other networks.</p>
                    <p>This Privacy Policy is published pursuant to:</p>
                    <p>a. Section 43A of the Information Technology Act, 2000;</p>
                    <p>b. Regulation 4 of the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Information) Rules, 2011 (“SPI Rules”); and</p>
                    <p>c. Regulation 3(1) of the Information Technology (Intermediaries Guidelines) Rules, 2011.</p>
                  </div>
                  
                  <div className='termsBoxContent'>
                    <h4>2. Data Collected</h4>
                    <h4 className='mt-20'>2.1 Traffic Data Collected</h4>
                    <p>In order to provide our Platform-WISR, Wondrlab Martech automatically tracks and collects the following categories of information when Users use our Platform: IP Address; Domain Servers; and Other information with respect to interaction of Users' devices with our Platform (collectively "Traffic Data").</p>
                  </div>
                  <div className='termsBoxContent'>
                    <h4>2.2 Personal Information Collected</h4>
                    <p>In order to provide our Platform-WISR, Wondrlab Martech may require Users to provide us with certain data that verifies our Users' personal identities ("Personal Information"). Personal Information includes the following categories of information: Contact Data (such as email address, name, and contact number); Demographic Data (such as location, gender and date of birth); Social Media Data (such as statistics on social media reach, impressions & video views, engagement metrics and audience demographics contingent on necessary permissions being granted by Users); Payment Data (required to facilitate payments from a User's bank account(s), credit/debit card(s) and/or mobile wallet(s) to the Platform) Furthermore, if you communicate with Wondrlab Martech by, for example, email or letter, any information provided in such communication may be collected by  Wondrlab Martech.</p>
                  </div>
                  <div className='termsBoxContent'>
                    <h4>2.3 Links to Third Party Applications</h4>
                    <p>Our Platform-WISR may contain links to third party web and mobile applications such as Instagram. Users agree and acknowledge that privacy policies of such web and mobile applications are not under Wondrlab Martech's control.</p>
                  </div>
                  <div className='termsBoxContent'>
                    <h4>3. Disclosure of Personal Information</h4>
                    <p>3.1 Wondrlab Martech does not disclose Users' Personal Information to any third parties based on strict adherence to and in compliance with our Privacy Policy and any other appropriate confidentiality and security measures.</p>
                    <p>3.2 We use the information collected for the following purposes:</p>
                      <ul>
                        <li>•	To build up marketing profiles for the benefit of the brands we partner with (who are also "Users");</li>
                        <li>•	To facilitate strategic business decisions, Platform-WISR development, data collection and analytics;</li>
                        <li>•	To audit usage of Platform-WISR;</li>
                        <li>•	To facilitate payments</li>
                        <li>•	To enhance user experience in relation to our Platform-WISR (collectively, "Permitted Use");</li>
                      </ul>
                      <p>3.3 Wondrlab Martech reserves the right to disclose Personal Information if required to do so by law or if Wondrlab Martech believes that it is necessary to do so to protect and defend the rights, property or personal safety of Wondrlab Martech, the Platform-WISR or our Users.</p>
                  </div>
                  <div className='termsBoxContent'>
                    <h4>4. Confidentiality</h4>
                    <p>4.1 Except as otherwise stated in this Privacy Policy, Wondrlab Martech will keep Users' Personal Information private and will not share with third parties, unless Wondrlab Martech believes in good faith that disclosure of Personal Information or any other information collected about Users is necessary for Permitted Use or to:</p>
                    <ul>
                        <li>•	Comply with a court order or other legal process;</li>
                        <li>•	Protect the rights, property or safety of Wondrlab Martech or another party;</li>
                        <li>•	Respond to claims that any posting or content violates the rights of third-parties;</li>
                        </ul>
                  </div>
                  <div className='termsBoxContent'>
                    <h4>5. Security</h4>
                    <p>5.1 The security of Users' Personal Information is important to Wondrlab Martech. Wondrlab Martech follows generally accepted industry standards to protect the Personal Information submitted, both during transmission and once  Wondrlab Martech has received it.</p>
                    <p>5.2 Notwithstanding  Wondrlab  Martech's best efforts to store Personal Information in a secure operating environment that is not accessible by the public, Users acknowledge that there is no such thing as complete security, and  Wondrlab Martech does not guarantee that there will be no unintended disclosures of Users' Personal Information. If  Wondrlab  Martech becomes aware that Users' Personal Information has been disclosed in a manner not in accordance with this Privacy Policy,  Wondrlab  Martech will use reasonable efforts to notify Users of the nature and extent of such disclosure (to the extent  Wondrlab Martech knows that information) as soon as reasonably possible and as permitted by law.</p>
                  </div>
                  <div className='termsBoxContent'>
                    <h4>6. Updates and Changes to Privacy Policy</h4>
                    <p>6.1  Wondrlab  Martech reserves the right, at any time, to add to, change, update or modify this Privacy Policy. If  Wondrlab Martech does so,  Wondrlab Martech will post such changes on this page. In all cases, the use of information  Wondrlab Martech collects is subject to the Privacy Policy in effect at the time such information is collected.</p>
                  </div>
                  <div className='termsBoxContent'>
                    <h4>7. User Rights</h4>
                    <p>7.1 Users have a right to correct any errors in their Personal Information available with us. Users may request us in writing that  Wondrlab Martech ceases to use their respective Personal Information.</p>
                  </div>
                  <div className='termsBoxContent'>
                    <h4>8. Restriction of Liability</h4>
                    <p>8.1  Wondrlab Martech makes no claims, promises or guarantees about the accuracy, completeness, or adequacy of the contents available through the Platform and expressly disclaims liability for errors and omissions in the contents available through the Platform.</p>
                    <p>8.2 No warranty of any kind, implied, expressed or statutory, including but not limited to the warranties of non-infringement of third party rights, title, merchantability, fitness for a particular purpose, and freedom from computer virus, is given with respect to the contents available through the Platform or its links to other internet resources as may be available to Users through the Platform.</p>
                    <p>8.3 Reference in the Platform to any specific commercial products, processes, or services, or the use of any trade, firm or corporation name is for the information and convenience of the  public, and does not constitute endorsement, recommendation or favoring by  Wondrlab Martech.</p>
                    <p>Should there be any queries, please feel free to email us or at <a href='mailto:helpdesk@wisr.co.in'>helpdesk@wisr.co.in</a> and we will address any of your concerns.</p>
                    <p>Website/ App link: https://wisr.co.in/</p>
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className={`blurBackground`} style={{display: `${props.showMou ? "" : "none"}`}}></div> */}
    </>
    )
}

export default PrivacyAndPolicy
// 3) brand outlay prices needs to be round of on the basis of final brand outlay for a school and not on individual inventory attributes prices round off
// if final value for a school is greater than 0.50 it should consider next whole integer value and if lesser it should consider the lower interger value
