import React, { useState, useContext, useEffect, useRef } from "react";
import SimpleReactValidator from "simple-react-validator";
import AppContextObject from "../../../../common/context/common";
import { PublicContextInitiator } from "../../../apis/publicContextInitiator";
import TextField from "../../../../components/formFields/textField/textField";
import TextFieldWithPrefix from "../../../../components/formFields/textFieldWithPrefix/textFieldWithPrefix";
import SingleSelect from "../../../../components/formFields/singleSelect/singleSelect";
import config from "../../../../service/config";
import {
  createUniqueUsername,
  validateFullName,
} from "../../../../service/helper";
import errorSVG from "../../../../assets/images/error.svg";
import CircularProgress from '@mui/material/CircularProgress';

const NewUser = ({
  isCustomInventoryEdit,
  hideModal,
  setUserCreateSuccessfully,
  setOverlay,
  setUserCreatedEmail,
}) => {
  const context = useContext(AppContextObject);
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phone: "",
    state: "",
    city: "",
    role: "",
    username: "",
    salesAdmin: "",
    // salesAdmin: ""
  });
  const [statesCities, setStateCities] = useState([]);
  const [cities, setCities] = useState([]);
  const [buttonVisible, setButtonVisible] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  const [showErrorsFirsttime, setShowErrorsFirsttime] = useState(false);
  const [creatingNewUser, setCreatingNewUser] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formValidator] = useState(
    useRef(
      new SimpleReactValidator({
        element: (message, className) => (
          <h1 className="valError">
            <img src={errorSVG} />
            {message === "The email field is required."
              ? "The email id field is required."
              : message === "The phone field is required."
              ? "The phone no field is required."
              : message === "Please enter valid email id."
              ? "The email id must be a valid email id."
              : message}
          </h1>
        ),
      })
    )
  );

  useEffect(() => {
    fetchStateAndCity();
  }, []);

  const fetchStateAndCity = async () => {
    let publicContextInitiator = new PublicContextInitiator(context);
    let resp = await publicContextInitiator.getStatesAndCitiesFromPincode(
      context.validator,
      {},
      {}
    );
    // console.log('resp======>', resp)
    if (resp && resp.done) {
      setStateCities(resp.response.rows);
    }
  };


  useEffect(() => {
    if (formData.state !== "" && formData.state !== undefined) {
      fetchCities();
    }
  }, [formData.state]);

  const fetchCities = () => {
    setFormData({
      ...formData,
      city: "",
    });
    let stateArray = statesCities.find(
      (state) => state.stateName === formData.state
    );
    setCities(stateArray.districts);
  };

  useEffect(() => {
    if (formData.fullName.length > 0) {
      let trimLastName = formData.fullName.split(" ")[0];
      const userName = createUniqueUsername(trimLastName);
      setFormData({ ...formData, username: userName });
    }
  }, [formData.fullName]);
  useEffect(() => {
    if (
      formData.fullName.length > 0 &&
      formData.email.length > 0 &&
      formData.phone.length > 0 &&
      formData.state.length > 0 &&
      formData.city.length > 0 &&
      formData.role.length > 0 &&
      formData.salesAdmin.length > 0 
      // formData.salesAdmin.length > 0
    ) {
      setButtonVisible(true);
    } else {
      setButtonVisible(false);
    }
  }, [formData]);

  const onFormDataChange = (event) => {
    if (showErrorsFirsttime) {
      setShowErrors(true);
    }
    setFormData({
      ...formData,
      [event.target.id]: event.target.value,
    });
  };

  const onRoleChange = (data) => {
    console.log("ThisData ----", data._id);
    // const getuser = data.target.value;
    // console.log("getusergetuser ",getuser);
    const getuser = data._id;
    setShowhide(getuser);

    if (showErrorsFirsttime) {
      setShowErrors(true);
    }

    // const previousRole = formData.role; // Store the previous role
    // setFormData({
    //   ...formData,
    //   role: data._id,
    // });

    // setFormData({
    //   ...formData,
    //   role: data._id,
    // });

     // Reset fields here
    //  if(formData.role === "")
    //  {
    //   // setFormData((prevFormData) => ({
    //   //   ...prevFormData,
    //   //   role: data._id,
    //   // }));

    //    setFormData({
    //   ...formData,
    //   role: data._id,
    // });

    //  }

    if (data._id !== formData.role) {
      // Role has changed, reset the role and keep other fields intact
      setFormData((prevFormData) => ({
        ...prevFormData,
        role: data._id,
        salesAdmin: "", // Reset Sales Admin field
      }));
    }
    //  else if (data._id !== formData.role) {
    //   resetFields();
    // }
  };

  // const resetFields = () => {
  //   setFormData((prevFormData) => ({
  //     ...prevFormData,
  //     fullName: "",
  //     email: "",
  //     phone: "",
  //     state: "",
  //     city: "",
  //     username: "",
  //     salesAdmin: "",
  //   }));
  //   setCities([]);
  // };

  // const resetFields = () => {
  //   setFormData({
  //     fullName: "",
  //     email: "",
  //     phone: "",
  //     state: "",
  //     city: "",
  //     role: "",
  //     username: "",
  //     salesAdmin: "",
  //   });
  //   setCities([]);
  // };

  const onStateChange = (data) => {
    if (showErrorsFirsttime) {
      setShowErrors(true);
    }
    setFormData({
      ...formData,
      state: data.value,
    });
  };

  const onCityChange = (data) => {
    if (showErrorsFirsttime) {
      setShowErrors(true);
    }
    setFormData({
      ...formData,
      city: data.value,
    });
  };
  const refreshFormData = () => {
    setShowErrors(true);
    setFormData({
      fullName: "",
      email: "",
      phone: "",
      state: "",
      city: "",
      role: "",
      username: "",
      salesAdmin:"",
      // salesAdmin:""
    });
    setCities([]);
  };
  const submitNewUser = () => {
    //api call for create FOS
    // console.log('submitNewFOS');
    createFosUser();
    setShowErrorsFirsttime(true);
  };
  useEffect(() => {
    // formValidator.current.hideMessages()
    if (showErrorsFirsttime) {
      if (!showErrors) {
        formValidator.current.hideMessages();
      } else {
        formValidator.current.showMessages();
      }
    }
  }, [showErrors]);

  const createFosUser = async () => {
    setShowErrors(true);
    const result = formValidator.current.allValid();
    if (result === false) {
      formValidator.current.showMessages();
      return;
    } else {
      formValidator.current.hideMessages();
      if (
        formValidator.current.errorMessages.fullName === null &&
        formValidator.current.errorMessages.email === null &&
        formValidator.current.errorMessages.phone === null &&
        formValidator.current.errorMessages.state === null &&
        formValidator.current.errorMessages.city === null &&
        formValidator.current.errorMessages.role === null 
        // formValidator.current.errorMessages.salesAdmin === null
      ) {
        if (formData.username && formData.username.length > 0) {
          setCreatingNewUser(true);
          setLoading(true);
          console.log("ThisFormData", formData)
          let resp = await context.apis[context.user.role].addUserForBackend(
            context.validator,
            formData
          );
          setLoading(false);
          // console.log(resp)
          if (resp && resp.done) {
            formValidator.current.hideMessages();
            setCreatingNewUser(false);
            setUserCreatedEmail(formData.email);
            setShowErrorsFirsttime(false);
            hideModal();
            refreshFormData();
            setOverlay(true);
            setUserCreateSuccessfully(true);
          }
        }
      }
    }
  };
  formValidator.current.rules["email"] = {
    message: "Please enter valid email id.",
    rule: (val) => {
      const re =
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      // console.log(re.test(val))
      if (!re.test(val)) {
        return false;
      }
    },
  };
  formValidator.current.rules["phone"] = {
    message: "Please enter 10 digits phone no.",
    rule: (val) => {
      if (val.length < 10) return false;
    },
  };
  formValidator.current.rules["full_name"] = {
    message: "Please enter valid full name.",
    rule: (val) => {
      let returnValue = validateFullName(val);

      return returnValue;
    },
  };

  const [showhide, setShowhide] = useState("");
  const [showSalesPersonList, setShowSalesPersonList] = useState([]);

  const [salesPersonList, setSalesPersonList] = useState([]);



  // New Starts 

  useEffect(() => {
    newGetListOfSalesPerson();
  }, []);

  const newGetListOfSalesPerson = async () => {
    let publicContextInitiator = new PublicContextInitiator(context);
    // let resp = await context.apis.public.getUsersListByRole(
    let resp = await publicContextInitiator.getUsersListByRole(
      context.validator,
      { role: "backendSalesAdmin" },
      {}
    );
     console.log('NewNew', resp)
    if (resp && resp.done) {
    let salesPerson = resp.response.rows
        .sort((a, b) => a.fullName.localeCompare(b.fullName))
        .map((item) => {
          console.log("salesitem")
          return {
            _id: item._id,
            // value: item.fullName,
            value: item._id,
            label: item.fullName,
          };
        });

      setSalesPersonList(salesPerson);
    }

    // if (resp && resp.done && resp?.response?.rows.length > 0) {
    //   const fullNameList = resp?.response?.rows?.map((data) => data.fullName);
    //   setSalesPersonList(fullNameList);
    // }
  };

  // New Ends 

// Below is also working
  const getListOfSalesPerson = async () => {
    const resp = await context.apis.public.getUsersListByRole(
      context.validator,
      { role: "backendSales" },
      {}
    );
    if (resp && resp.done && resp?.response?.rows.length > 0) {
      const fullNameList = resp?.response?.rows?.map((data) => data.fullName);
      setShowSalesPersonList(fullNameList);
    }
  };

  useEffect(() => {
    getListOfSalesPerson();
  }, []);

  const [selectValue, setSelectValue] = useState("");

  const onChange = (event) => {
    const value1 = event.value
    const value2 = event._id
    console.log("ThisIsEvent", event)
    // const value = event.target.value;
     setSelectValue(value2);

     if (showErrorsFirsttime) {
      setShowErrors(true);
    }
    setFormData({
      ...formData,
      salesAdmin: event._id,
    });
  };

  return (
    <>
      <div
        id="fosModal"
        className={`popup fosModal ${
          isCustomInventoryEdit === true ? "" : "hide"
        }`}
      >
        <div className="popup-header">
          <h3 className="htx2 mb-10">Create Logins</h3>
          <span
            onClick={() => {
              hideModal();
              refreshFormData();
              setShowErrors(false);
              setShowErrorsFirsttime(false);
              formValidator.current.hideMessages();
            }}
            className="close toggle"
            data-target="closePopup"
          >
            close
          </span>
        </div>
        <div className="popup-body">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <TextField
                maxLength={50}
                formData={formData}
                formKey="fullName"
                onDataChange={onFormDataChange}
                label="Your Name"
                placeholder="Enter Full Name"
                isRequired={false}
                formValidator={formValidator}
                validation="required|full_name"
              />
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <SingleSelect
                formData={formData}
                formKey="role"
                onDataChange={(e) => onRoleChange(e)}
                label="Role"
                placeholder="Select Role"
                isRequired={false}
                options={config.backendAdminRole}
                formValidator={formValidator}
                validation="required"
                matchById={true}
              />
            </div>


            {/* {showhide === "backendSalesPerson" && (
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
             
              <SingleSelect
                formData={formData}
                formKey="salesperson"
                onDataChange={(e) => onRoleChange(e)}
                label="Sales Person"
                placeholder="Select Sales Person"
                isRequired={false}
                {...showSalesPersonList.map((fullName, index) => (
                  <>
                options={fullName}
                </>
                ))}
                formValidator={formValidator}
                validation="required"
                matchById={true}
              />
             
            </div>
)} */}

{showhide === "backendSalesPerson" && (
<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <SingleSelect
                formData={formData}
                formKey="salesAdmin"
                // onDataChange={onStateChange}
                onDataChange={(e) => onChange(e)}
                label="Sales Admin"
                placeholder="Select Sales Admin"
                isRequired={false}
                options={salesPersonList}
                  salesAdmin={selectValue}
                  // selected={selectValue}
                formValidator={formValidator}
                validation=""
                matchById={true}
              />
            </div>
 )}

            {/* Below is working code for backend sales person */}
            {/* {showhide === "backendSalesPerson" && (
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <select>
                  <option value="" disabled selected>
                    Select Sales Person
                  </option>
                  {showSalesPersonList.map((fullName, index) => (
                    <>
                      <option value="">{fullName}</option>
                    </>
                  ))}
                </select>

                <br />
                <br />
              </div>
            )} */}
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <TextField
                formData={formData}
                formKey="email"
                onDataChange={onFormDataChange}
                label="Email ID"
                placeholder="E.g. anju@abcschool.com"
                isRequired={false}
                formValidator={formValidator}
                validation="required|email"
              />
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <TextFieldWithPrefix
                formData={formData}
                formKey="phone"
                onDataChange={onFormDataChange}
                label="Phone No"
                placeholder="E.g. 99999 99999"
                isRequired={false}
                formValidator={formValidator}
                validation="required|phone"
              />
            </div>

            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <SingleSelect
                formData={formData}
                formKey="state"
                onDataChange={onStateChange}
                label="State"
                placeholder="Select State"
                isRequired={false}
                options={statesCities
                  .sort((a, b) => a.stateName.localeCompare(b.stateName))
                  .map((item) => {
                    return {
                      _id: item._id,
                      value: item.stateName,
                      label: item.stateName,
                    };
                  })}
                formValidator={formValidator}
                validation="required"
              />
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <SingleSelect
                formData={formData}
                formKey="city"
                onDataChange={onCityChange}
                label="City/District"
                placeholder="Select City"
                isRequired={false}
                options={cities
                  .sort((a, b) => a.localeCompare(b))
                  .map((item, id) => {
                    return { _id: item, value: item, label: item };
                  })}
                formValidator={formValidator}
                validation="required"
              />
            </div>

            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 end-xs">
           { loading ? <CircularProgress color="warning" size="1.5rem" style={{marginTop:"10px", marginLeft:"-15px"}}/> :
              <div className="forminpt mb-0">
                <input
                  value="create"
                  name="create"
                  // className={`btn btn-orange comp-seting ${
                  //   creatingNewUser && "btn btn-orange button--loading"
                  // }`}
                  className={`btn btn-orange ${
                    creatingNewUser && "btn btn-orange button--loading"
                  }`}
                  type="button"
                  // disabled={!buttonVisible}
                  onClick={submitNewUser}
                />
              </div>
}

              {/* <button onClick={getListOfSalesPerson}> Click Me </button> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default NewUser;
