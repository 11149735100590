const ProfileSVG = () => {

    return (
        <svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.11074 11.9043C3.74098 11.9043 0.863281 12.4115 0.863281 14.4428C0.863281 16.4742 3.72272 16.9996 7.11074 16.9996C10.4805 16.9996 13.3574 16.4915 13.3574 14.461C13.3574 12.4305 10.4988 11.9043 7.11074 11.9043Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.11004 9.00718C9.32142 9.00718 11.1138 7.22202 11.1138 5.02051C11.1138 2.819 9.32142 1.03467 7.11004 1.03467C4.89866 1.03467 3.10549 2.819 3.10549 5.02051C3.09802 7.21458 4.87874 8.99974 7.08183 9.00718H7.11004Z" stroke="white" stroke-width="1.42857" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
}

export default ProfileSVG
