import { useContext } from "react"

import {
    useLocation,
    useRouteMatch,
    Link
} from "react-router-dom"

import DataContext from "../../../../../../service/backendContext"
import logo from "../../../../../.././assets/images/logo.png"
import logoNew from "../../../../../../assets/images/logo-new.svg"
import waveBootNew from "../../../../../.././assets/images/wavebotnew.png"
import check from "../../../../../../assets/images/check.svg"

const NonWisrCampaignProgress = () => {

    const parentData = useContext<any>(DataContext)
    const location = useLocation()
    const router = useRouteMatch()

    return (
        <>
            <div className="col-xl-3 col-lg-3 col-md-12 col-xs-12 pl-0 pr-0">
                <div className={`sliderbar onboarding-bar newMvp-sidebar backendCampaign`}>
                    <div className="row logo between-xs bottom-xs logoNew">
                     <img src={logoNew} alt="logo" />
                     </div>
                    {
                        <ul>
                        
                            <li>
                                <Link
                                    to={{pathname: `${router.url}/set-up-nonWisr-campaign`, state: {from: "createCampaignFlow"}}}
                                    className={
                                        `${parentData.nonWisrCampaignActiveModule.module === "brandDetails" && "active"}
                                        ${parentData?.nonWisrCampaignProgress?.brandDetails === true && "complete"}`
                                    }
                                >
                                    <span>1</span> SET UP CAMPAIGN
                                </Link>
                            </li>
                        
                            <li>
                                <Link
                                    to={{pathname: parentData?.nonWisrCampaignData?.brandDetail?.inventory ? `${router.url}/nonWisr-campaign-inventory` : `${router.url}/nonWisr-campaign-event`, state: {from: "createCampaignFlow"}}}
                                    className={
                                        `${parentData.nonWisrCampaignActiveModule.module === "campaignPlacement" && "active"}
                                        ${(parentData?.nonWisrCampaignData?.brandDetail?.inventory ? parentData?.nonWisrCampaignProgress?.inventory === true : true)
                                            && parentData?.nonWisrCampaignProgress?.school === true
                                            && (parentData?.nonWisrCampaignData?.brandDetail?.eventAndOccations ? parentData?.nonWisrCampaignProgress?.event === true : true)
                                            && "complete"}
                                        ${parentData?.nonWisrCampaignProgress?.brandDetails === true ? "" : "disabledLink"}`
                                    }
                                >
                                    <span>2</span> CAMPAIGN PLACEMENT
                                </Link>
                                { parentData.nonWisrCampaignActiveModule.module === "campaignPlacement" &&
                                    <ul>
                                        {parentData?.nonWisrCampaignData?.brandDetail?.inventory &&
                                            <li>
                                                <Link
                                                    to={{pathname: `${router.url}/nonWisr-campaign-inventory`, state: {from: "createCampaignFlow"}}}
                                                    className={`${parentData.nonWisrCampaignActiveModule.subModule === "inventory" && "active"}
                                                        ${parentData?.nonWisrCampaignProgress?.inventory === true ? "" : "disabledLink"}
                                                    `}
                                                >
                                                    Inventory
                                                </Link>
                                            </li>
                                        }
                                        {parentData?.nonWisrCampaignData?.brandDetail?.eventAndOccations &&
                                            <li>
                                                <Link
                                                    to={{pathname: `${router.url}/nonWisr-campaign-event`, state: {from: "createCampaignFlow"}}}
                                                    className={`${parentData.nonWisrCampaignActiveModule.subModule === "events" && "active"}
                                                    ${parentData?.nonWisrCampaignProgress?.event === true ? "" : "disabledLink"}
                                                    `}
                                                >
                                                    Events
                                                </Link>
                                            </li>
                                        }
                                        <li>
                                            <Link
                                                to={{pathname: `${router.url}/nonWisr-campaign-school`, state: {from: "createCampaignFlow"}}}
                                                className={`${parentData.nonWisrCampaignActiveModule.subModule === "schools" && "active"}
                                                ${(parentData?.nonWisrCampaignProgress?.school === true
                                                    ) ? "" : "disabledLink"}
                                                `}
                                            >
                                                Schools
                                            </Link>
                                        </li>
                                    </ul>
                                }
                            </li>
                            <li>
                                <Link
                                    to={{pathname: `${router.url}/nonWisr-campaign-creative`, state: {from: "createCampaignFlow"}}}
                                    className={
                                        `${parentData.nonWisrCampaignActiveModule.module === "creative" && "active"}
                                        ${parentData?.nonWisrCampaignProgress?.campaignCreative === true
                                            && "complete"}
                                            ${parentData?.nonWisrCampaignProgress?.campaignCreative === true ? "" : "disabledLink"}
                                            `
                                    }
                                >
                                    <span>3</span> CAMPAIGN CREATIVE
                                </Link>
                            </li>
                        
                            <li>
                                <Link
                                    to={{pathname: `${router.url}/nonWisr-campaign-preview`, state: {from: "createCampaignFlow"}}}
                                    className={
                                        `${parentData.nonWisrCampaignActiveModule.module === "preview" && "active"}
                                        ${(parentData?.nonWisrCampaignProgress?.brandDetails === true
                                            && parentData?.nonWisrCampaignProgress?.inventory === true
                                            && parentData?.nonWisrCampaignProgress?.school === true
                                            && parentData?.nonWisrCampaignProgress?.event === true
                                            && parentData?.nonWisrCampaignProgress?.campaignCreative === true)
                                            ? "complete" : "disabledLink"}
                                        `
                                    }
                                >
                                    <span>{4}</span> CAMPAIGN PREVIEW
                                </Link>
                            </li>
                        </ul>
                    }
                    <img src={waveBootNew} className="wavebot" alt="wave" />
                </div>
            </div>
        </>
    )
}

export default NonWisrCampaignProgress
