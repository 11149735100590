import { useState, useContext, useEffect } from "react"

import {
    Route,
    Switch,
    useRouteMatch,
    useHistory
} from "react-router-dom"

import { Link } from "react-router-dom"

import ProfileGeneral from "./general"
import ProfileDescription from "./description"
import ProfileContact from "./contact"
import ProfileVerification from "./verification"
import ProfileAddUsers from "./addUsers"
import BrandProfileSidemenu from "./sidemenu"

import DataContext, { DataContextProvider }  from "../../../../service/brandContext"
import profileGeneralLogo from "../../../../assets/images/profile-general-logo.png"
import uploadDocument from "../../../../assets/images/img-upload.jpg"
import AppContextObject from "../../../../common/context/common"
import CreateBrand from "./createBrand"
import ProfileUsers from "./profileUsers"
import ProfileAbout from "./profileAbout"
import { Steps } from "intro.js-react"
import Loader from "../../../../components/addon/loader"
import BrandBackBtn from "../components/brandBackBtn"
import BrandTopBar from "../components/brandTopBar"

const Profile = (props) => {
    const {
        isEdit,
        methods
    } = props

    const history = useHistory()
    const router = useRouteMatch()
    const parentData = useContext <any> (DataContext)
    const context = useContext(AppContextObject)
    const [refetch, setRefetch] = useState(false)
    const [loading, setLoading] = useState(false)
    const [onBoarding, setOnboarding] = useState({
        stepsEnabled: false,
        initialStep: 0,
        steps: [
            {element: "#profileUserTab", intro: "Click here to get the list of users that have access to your brand's data on the platform.", position: "right"},
        ],
        options: {
            exitOnOverlayClick: false,
            showStepNumbers: true,
            hidePrev: true,
            hideNext: false,
            nextLabel: "Next",
            prevLabel: "Prev",
            doneLabel: "Got it",
            autoPosition: false,
            exitOnEsc: false,
            scrollToElement: false,
            disableInteraction: true
        },
    })

    const [brandProfile, setBrandProfile] = useState<any>({
        "general": {
            "companyName": "",
            "companyCategory": "",
            "companyWebsite": "",
            "socialMedia": {
                "twitter": "",
                "linkedIn": "",
                "other": []
            }
        },
        "companyDescription": {
            "description": ""
        },
        "contact": {
            "country": "",
            "state": "",
            "city": "",
            "address": "",
            "pincode": "",
            "mobileNo": ""
        },
        "verification": {
            "gst": {
                "image": "",
                "documentNo": "",
                "status": "required"
            },
            "pan": {
                "image": "",
                "documentNo": "",
                "status": "required"
            }
        },
        "users": [
            {
                "memberName": "",
                "memberEmail": "",
                "memberDesignation": ""
            },
        ],
        "removeUserIds": [],
        "subCategories": [],
        "subCategoryList": [],
        "uniqueSubCategoryList": [],
        "brandNameList": [],
        "myBrandDetails": {},
        "otherBrandCategory": false
    })

    const [otherBrandCategory, setOtherBrandCategory] = useState(false)
    const [showLetsGo, setShowLetsGo] = useState(true)
    const [initialInternetSpeedTest, setInitialInternetSpeedTest] = useState(false)

    const [formData, setFormData] = useState()

    const openOnBoardingWizard = () => {
        if (parentData && parentData.setShowSidemenu) {
            history.push(`${router.url}/general`)
            parentData.setShowSidemenu(false)
        }
    }

    const [progress, setProgress] = useState({
        general: false,
        description: false,
        verification: false,
        contact: false,
        addUsers: false
    })

    const [activeModule, setActiveModule] = useState({
        module: "",
        subModule: ""
    })

    const [activeTab, setActiveTab] = useState("about")
    useEffect(() => {
        if(props.toolTipLoaded){
            if(!showLetsGo && parentData && parentData.showSidemenu){
                setTimeout(() => {
                    setOnboarding({...onBoarding, stepsEnabled: parentData?.toolTipProgress?.myBrand})
                }, 100)
            }
        }
    }, [showLetsGo, props.toolTipLoaded])

    useEffect(() => {
        // if (parentData?.setShowSidemenu) {
        //     // parentData.setShowSidemenu(false)
        // }
        if(history.location.pathname == "/brands/brand-details/profile"){
            parentData.setShowSidemenu(true)
        }else{
            parentData.setShowSidemenu(false)
        }
    }, [history.location.pathname])

    useEffect(() => {
        // getBrandUsers()
        getBrandDetails()
    }, [refetch])


    const getBrandDetails = async () => {
        setLoading(true)
        try{
            let brandResp = await context.apis.public.getBrandById(context.validator,
                {_id: context.user.brand ? context.user.brand : ""}
                )
            let subCatResp = await context.apis[context.user.role].getAllSubCategoriesByParentBrandId(
                context.validator, {parentBrandId: context.user.parentBrand || context.user.brand}
            )
            console.log("brandRespsubCatResp",brandResp, subCatResp)
            let uniqueCategories = []
            let allSubCategories = []
            let allBrandNames = []
            if(subCatResp && subCatResp.response && subCatResp.response.rows.length > 0){
                // let uniq = []
                subCatResp.response.rows.forEach(item => {
                    if(!uniqueCategories.find(data => data.name === item.name)){
                        let found = subCatResp.response.rows.filter(subCat => subCat.name === item.name)
                        if(found && found.length === 1){
                            uniqueCategories.push(item)
                        }
                        else if(found && found.length > 1){
                            found.forEach(item1 => {
                                if(item1.brandId && item1.brandName && !uniqueCategories.find(data => data.name === item1.name)){
                                    uniqueCategories.push(item1)
                                }
                            })
                        }
                    }
                })
                console.log("subCatResp.response.rows", subCatResp.response.rows, uniqueCategories)
                // uniqueCategories = subCatResp.response.rows.filter((v,i,a)=>a.findIndex(t=>(t.name.toLowerCase().trim() === v.name.toLowerCase().trim()))===i)
                allSubCategories = subCatResp.response.rows
                allBrandNames = subCatResp.response.rows.filter((v,i,a)=>a.findIndex(t=>(t.brandName && v.brandName && t.brandName.toLowerCase().trim() === v.brandName.toLowerCase().trim()))===i)
            }

            let myBrandData = {
                subCategory: "",
                brandName: "",
                _id: ""
            }
            if(allSubCategories.length > 0){
                let fiteredArray = allSubCategories.filter(el => el.userId === context.user._id)
                if(fiteredArray.length > 0){
                    let found = fiteredArray.reduce((a, b) => {
                        return new Date(a.updatedOn) > new Date(b.updatedOn) ? a : b;
                      });
                    if(found){
                        myBrandData["subCategory"] = found.name
                        myBrandData["brandName"] = found.brandName
                        myBrandData["_id"] = found._id
                    }
                }
                
            }
            
            if(brandResp && brandResp.done){

                let brandProfileData = {
                    "general": {
                        "companyName": brandResp.response.general.brandName || "",
                        "companyCategory": brandResp.response.general.brandType || "",
                        "companyWebsite": brandResp.response.general.website || "",
                        "socialMedia": brandResp.response.general.socialMediaLinks || { "twitter": "", "linkedIn": "", "other": [] }
                    },
                    "companyDescription": {
                        "description": brandResp.response.description || ""
                    },
                    "contact": {
                        "country": brandResp.response.contact.country || "",
                        "state": brandResp.response.contact.state || "",
                        "city": brandResp.response.contact.city || "",
                        "address": brandResp.response.contact.address || "",
                        "pincode": brandResp.response.contact.zip || "",
                        "mobileNo": brandResp.response.contact.contactNumber || context?.user?.phone || ""
                    },
                    "verification": {
                        "gst": {
                            "image": typeof brandResp.response.documents.gst.file === "string" ? "" : brandResp.response.documents.gst.file[0].image,
                            "documentNo": brandResp.response.documents.gst.number || "",
                            "key": typeof brandResp.response.documents.gst.file === "string" ? "" : brandResp.response.documents.gst.file[0].key,
                            "status": brandResp.response.documents.gst.number.status || "required"
                        },
                        "pan": {
                            "image": typeof brandResp.response.documents.pan.file === "string" ? "" : brandResp.response.documents.pan.file[0].image,
                            "documentNo": brandResp.response.documents.pan.number || "",
                            "key": typeof brandResp.response.documents.pan.file === "string" ? "" : brandResp.response.documents.pan.file[0].key,
                            "status": brandResp.response.documents.pan.number.status || "required"
                        }
                    },
                    "users": [],
                    "removeUserIds": [],
                    "subCategories": [],
                    "uniqueSubCategoryList": [],
                    "myBrandDetails": {},
                    "brandNameList": [],
                    "otherBrandCategory": false
                };
                let subCatData = []
                let brandNameData = []
                if(brandResp.response.subCategories && brandResp.response.subCategories.length > 0){
                    for(let cat of brandResp.response.subCategories){
                        subCatData.push({
                            value: cat.name,
                            label: cat.name
                        })
                        
                    }
                    for(let brand of allBrandNames){
                        brandNameData.push({
                            value: brand.brandName,
                            label: brand.brandName
                        })
                    }
                    
                }
                brandProfileData["subCategories"] = subCatData
                brandProfileData["brandNameList"] = brandNameData
                let userArr = []
                for(let user of brandResp.response.userDetails){
                    userArr.push(
                        {
                            "memberName": user.fullName || "",
                            "memberEmail": user.email || "",
                            "memberDesignation": user.role === "brandCMO" ? "CMO" : user.role === "brandManager" ? "Brand Manager" : "",
                            "_id": user._id || null
                            // "subCategoryName": "",
                            // "brandName": ""
                        }
                    )
                }

                // if(brandResp.response.userDetails.length < 2)
                // {
                //     userArr.push({
                //         "memberName": "",
                //         "memberEmail": "",
                //         "memberDesignation": ""
                //     })
                // }
                // else if(brandResp.response.userDetails.length === 0){
                //     userArr.push({
                //             "memberName": "",
                //             "memberEmail": "",
                //             "memberDesignation": ""
                //         },
                //         {
                //             "memberName": "",
                //             "memberEmail": "",
                //             "memberDesignation": ""
                //         }
                //     )
                // }


                brandProfileData.users = userArr
                setBrandProfile(brandProfileData)
                let brndData = brandResp.response

                if(brandResp.response.general.brandType.length < 1)
                {
                    setShowLetsGo(true)
                }else{
                    let categoryResp = await context.apis.public.getBrandCategoriesDetails(
                        context.validator,
                        {_id: brndData["general"]["brandType"]}
                    )

                    if(categoryResp && categoryResp.done)
                    {
                        brndData["general"]["brandType"] = categoryResp.response.name || ""
                        context.user.companyCategoryName = categoryResp.response.name || ""
                    }
                    setShowLetsGo(false)
                }
                let uniqueCategory = [], subCategoryAndBrandData = {}
                allSubCategories && allSubCategories.forEach(subCategory => {
                    if(subCategoryAndBrandData[subCategory.name]){
                        let newBrandName = subCategory.brandName
                        if(newBrandName && subCategoryAndBrandData[subCategory.name][0].brandName && Array.isArray(subCategoryAndBrandData[subCategory.name][0].brandName)){
                            subCategoryAndBrandData[subCategory.name][0].brandName.push(newBrandName)
                        }
                        console.log("subCategoryAndBrandData[subCategory.name]", subCategoryAndBrandData[subCategory.name], newBrandName)
                    }
                    else{
                        let newSub = {...subCategory}
                        if(subCategory.brandName){
                            newSub.brandName = [subCategory.brandName]
                        }
                        else{
                            newSub.brandName = []
                        }
                        subCategoryAndBrandData[subCategory.name] = [newSub]
                    }
                })
                Object.keys(subCategoryAndBrandData).forEach((key) => {
                    if(subCategoryAndBrandData[key].length === 1){
                        uniqueCategory.push(subCategoryAndBrandData[key][0])
                    }
                })
                setFormData({...brndData, subCategoryWithBrand: uniqueCategory, uniqueSubCategoryList: uniqueCategories, subCategoryList: allSubCategories, myBrandDetails: myBrandData, brandNameList: brandNameData})

            }
            // else{
                setLoading(false)
            // }
        }catch(error){
            setLoading(false)
        }
        setLoading(false)
    }


    const onTabClick = (tabName) => {
        setActiveTab(tabName)
    }

    const myValue:any = {
        brandProfile,
        setBrandProfile,
        refetch,
        setRefetch,
        otherBrandCategory,
        setOtherBrandCategory,
        initialInternetSpeedTest,
        setInitialInternetSpeedTest
    }
    const updateUsersToolTipProgress = async () => {
        let userToolTipResp = await context.apis[context.user.role].updateUsersToolTipProgress(
            context.validator, {}, {toolTipProgress: {...parentData.toolTipProgress, myBrand: false}}
        )
    }
    const hanldeOnboardingComplete = () => {
        updateUsersToolTipProgress()
        parentData.setToolTipProgress({
            ...parentData.toolTipProgress,
            myBrand: false
        })
        // let progress = {
        //     ...parentData.tooltipProgress,
        //     inventory: false
        // }
        // localStorage.setItem("tooltipProgress", JSON.stringify(progress))
    }
    const exitOnBoarding = (e) => {
    }


    return (
        <>
            {
                loading
                ?
                <Loader />
                :
                parentData.showSidemenu === true ?
                showLetsGo === true
                ?
                <div className="col-xl-10 col-lg-10 col-md-12 col-xs-12 mt-20">
                    <div className="row">
                    <BrandTopBar isCreateBrandProfile={true} /> 
                    <div className="col-xl-12 col-lg-12 col-md-12 middle-xs letsGo row">
                    <div className="brand-profile-create">
                        <h2 className="htx2 clearfix">Create your brand profile to start using Wisr.</h2>
                        <a href="javascript:void(0)" onClick={() => history.push("/brands/brand-details/profile/general")} className="btn btn-orange brand-btn">Let's Go</a>
                    </div>
                    </div>
                    </div>
                    
                </div>
                :
                <div className="col-xl-10 col-lg-12 col-md-12 col-xs-12">
                    {/* <div className="rightPart noBack profilemiddle"></div> */}
                    <div className="rightPart noBack">
                        <div className="row">
                            {/* <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-20">
                                <div className="brand-profile-create">
                                    <h2 className="htx2 clearfix">Create your brand profile to start using Wisr.</h2>
                                    <a onClick={openOnBoardingWizard} href="javascript:void(0);" className="btn btn-orange brand-btn">Let's Go</a>
                                </div>
                            </div> */}
                            <div className="col-xs-12 col-lg-12 col-md-12 col-xs-12">
                               <BrandBackBtn
                                 link="/"
                                 text="Log Out"
                                />
                            </div>
                            <BrandTopBar isCreateBrandProfile={true}/>
                            <div className="col-xl-10 col-lg-10 col-md-10 col-xs-12">
                                <ul className="tabsinventory">
                                    <li><a onClick={() => onTabClick("about")} href="javascript:void(0);" className={`${activeTab === "about" ? "active" : ""}`}>About</a></li>
                                    <li id="profileUserTab"><a onClick={() => onTabClick("users")} href="javascript:void(0);" className={`${activeTab === "users" ? "active" : ""}`}>Users</a></li>
                                </ul>
                            </div>
                            {
                                activeTab === "about" &&
                                <ProfileAbout profileGeneralLogo={profileGeneralLogo} uploadDocument={uploadDocument} profileData={formData} refetch={refetch} setRefetch={setRefetch} loading={loading} setLoading={setLoading} />
                            }
                            {
                                activeTab === "users" &&
                                <ProfileUsers userData={formData} />
                            }
                        </div>
                    </div>
                </div>
                :
                <DataContextProvider value={myValue}>
                    <CreateBrand
                        progress={progress}
                        activeModule={activeModule}
                        router={router}
                        setProgress={setProgress}
                        setActiveModule={setActiveModule}
                        setBrandProfile={setBrandProfile}
                        brandProfile={brandProfile}
                        setInitialInternetSpeedTest={setInitialInternetSpeedTest}
                        initialInternetSpeedTest={initialInternetSpeedTest}
                    />
                 </DataContextProvider>
            }
            <Steps
                enabled={onBoarding.stepsEnabled}
                steps={onBoarding.steps}
                initialStep={onBoarding.initialStep}
                onComplete={hanldeOnboardingComplete}
                onExit={exitOnBoarding}
                options={onBoarding.options}
            />
        </>
    )
}

export default Profile
