import React, { useEffect, useState, useRef } from "react";
import filterArrowSvg from "../../../../../assets/images/filter-arrow-svg.svg";
import config from '../../../../../service/config';
import { titleCase } from "../../../../../service/helper";



const CampaignSchoolFilter = ({setOpenShoolFilter, openShoolFilter, setCategory, setRegion, setStates, setLocation, category, region, states, location, setCitiesByState, citiesByState, stateByRegion, setStateByRegion }) => {
    const [openCategory, setOpenCategory] = useState(false);
    const [openRegion, setOpenRegion] = useState(false);
    const [openStates, setOpenStates] = useState(false)
    const [openLocation, setOpenLocation] = useState(false);
    const [filterType, setFilterType] = useState("");
    
    
    

    


    const clearAllFilter = () => {
        setCategory([]);
        setRegion([]);
        setStates([])
        setLocation([]);
        setOpenCategory(false);
        setOpenRegion(false);
        setOpenLocation(false);
    }
    const filterBySchool = (type, val) => {
        if (type === 'category') {
            // setCategory(val);
            let array = [...category]
            if (array.includes(val)) {
                array.splice(array.indexOf(val), 1)
            }
            else {
                array.push(val)
            }
            setCategory([...array])
        }
        else if (type === 'region') {
            // setRegion(val);
            let array = [...region]
            if (array.includes(val)) {
                array.splice(array.indexOf(val), 1)
                // if(array.length===0){
                //     setStates([])
                // }
                setStates([])
            }
            else {
                array.push(val)
            }
            setRegion([...array])
        }
        else if(type === "state"){
            let array = [...states]
            if (array.includes(val)) {
                array.splice(array.indexOf(val), 1)
                // if(array.length===0){
                //     setStates([])
                // }
                setLocation([])
            }
            else {
                array.push(val)
            }
            setStates([...array])
        } else {
            // setLocation(val);
            let array = [...location]
            if (array.includes(val)) {
                array.splice(array.indexOf(val), 1)
            }
            else {
                array.push(val)
            }
            setLocation([...array])
        }
    }
    
    return (

        <div className={`filterDropDown textNone ${openShoolFilter ? 'open' : ''}`}>
            <div className="filterDropHead">
                <h2>Filter <a href="javascript:void(0);" className="viewwLink" onClick={clearAllFilter}>Clear All</a></h2>
            </div>
            <div className="filterDropBody">
                <div className={`filterDropClose ${openCategory ? 'open' : ''}`}>
                    <h3><a href="javascript:void(0);"
                        onClick={() => setOpenCategory(!openCategory)}
                    >{`Category`}  <img src={filterArrowSvg} /></a></h3>
                    <div className="filtercheckbox">
                        {config.categories.map((item, index) => (
                            <div className="checkbox fnone" key={index} >
                                <input type="checkbox" id={item.value}
                                    checked={category.includes(item.value)}
                                />
                                <label htmlFor={item.value}
                                    onClick={() => filterBySchool('category', item.value)}
                                >
                                    {item.label}
                                </label>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="filterDropBody">
                <div className={`filterDropClose ${openRegion ? 'open' : ''}`}>
                    <h3><a href="javascript:void(0);"
                        onClick={() => setOpenRegion(!openRegion)}
                    >{`Region`}  <img src={filterArrowSvg} /></a></h3>
                    <div className="filtercheckbox">
                        {config.regions.map((item, index) => (
                            <div className="checkbox fnone" key={index} >
                                <input type="checkbox" id={item.value}
                                    checked={region.includes(item.value)}
                                />
                                <label htmlFor={item.value}
                                    onClick={() => filterBySchool('region', item.value)}
                                >
                                    {item.label}
                                </label>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="filterDropBody">
                <div className={`filterDropClose ${(openStates && region.length>0) ? 'open' : ''}`}>
                    <h3><a href="javascript:void(0);"
                        onClick={() => setOpenStates(!openStates)}
                    >{`State`}  <img src={filterArrowSvg} /></a></h3>
                    <div className="filtercheckbox">
                        {stateByRegion.sort((a, b) => a.localeCompare(b)).map((item, index) => (
                            <div className="checkbox fnone" key={index} >
                                <input type="checkbox" id={item}
                                    checked={states.includes(item)}
                                />
                                <label htmlFor={item}
                                    onClick={() => filterBySchool('state', item)}
                                >
                                    {titleCase(item)}
                                </label>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="filterDropBody">
                <div className={`filterDropClose ${(openLocation && states.length>0) ? 'open' : ''}`}>
                    <h3><a href="javascript:void(0);"
                        onClick={() => setOpenLocation(!openLocation)}
                    >{`City`}  <img src={filterArrowSvg} /></a></h3>
                    <div className="filtercheckbox">
                        {citiesByState.sort((a, b) => a.localeCompare(b)).map((item, index) => (
                            <div className="checkbox fnone" key={index} >
                                <input type="checkbox" id={item}
                                    checked={location.includes(item)}
                                />
                                <label htmlFor={item}
                                    onClick={() => filterBySchool('location', item)}
                                >
                                    {titleCase(item)}
                                </label>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
        // <div className={`filterDropDown r-auto tableFilter ${openShoolFilter ? 'open' : ''}`}>
        //     <div className="filterDropBody">
        //         <div className="filterDropHead">
        //             <h3>Filter <a href="javascript:void(0);" className="viewwLink" onClick={clearAllFilter}>Clear All</a></h3>
        //         </div>
        //         <div className={`filterDropClose ${openCategory ? 'open' : ''} `}>
        //             <h3><a href="javascript:void(0);" onClick={() => setOpenCategory(!openCategory)}>Category<img src={filterArrowSvg} /></a></h3>
        //             <div className="filtercheckbox">
        //                 {/* {config.categories.map((category, key) => {
        //                     return (
        //                         <div className="radioBtn" key={key}>
        //                             <input type="radio" id="test1" name="radio-group"  value={category.value} checked={filterType===category.value} onChange={() => filterBySchool(category.value)}/>
        //                             <label htmlFor="test1">{category.label}</label>
        //                         </div>
        //                     )
        //                 })} */}

        //                 <div className="radioBtn">
        //                     <input type="radio" id="test1" name="radio-group1" checked={category === 'A'} onChange={() => filterBySchool('category', 'A')} />
        //                     <label htmlFor="test1">Category A</label>
        //                 </div>
        //                 <div className="radioBtn">
        //                     <input type="radio" id="test2" name="radio-group1" checked={category === 'B'} onChange={() => filterBySchool('category', 'B')} />
        //                     <label htmlFor="test2">Category B</label>
        //                 </div>
        //                 <div className="radioBtn">
        //                     <input type="radio" id="test3" name="radio-group1" checked={category === 'C'} onChange={() => filterBySchool('category', 'C')} />
        //                     <label htmlFor="test3">Category C</label>
        //                 </div>
        //             </div>
        //         </div>
        //         <div className={`filterDropClose ${openLocation ? 'open' : ''} `}>
        //             <h3><a href="javascript:void(0);" onClick={() => setOpenLocation(!openLocation)}>City  <img src={filterArrowSvg} /></a></h3>
        //             <div className="filtercheckbox">
        //                 <div className="radioBtn">
        //                     <input type="radio" id="test8" name="radio-group3" checked={location === 'hyderabad'} onChange={() => filterBySchool('location', 'hyderabad')} />
        //                     <label htmlFor="test8">Hyderabad</label>
        //                 </div>
        //                 <div className="radioBtn">
        //                     <input type="radio" id="test9" name="radio-group3" checked={location === 'chennai'} onChange={() => filterBySchool('location', 'chennai')} />
        //                     <label htmlFor="test9">Chennai</label>
        //                 </div>
        //                 <div className="radioBtn">
        //                     <input type="radio" id="test10" name="radio-group3" checked={location === 'kolkata'} onChange={() => filterBySchool('location', 'kolkata')} />
        //                     <label htmlFor="test10">Kolkata</label>
        //                 </div>
        //                 <div className="radioBtn">
        //                     <input type="radio" id="test11" name="radio-group3" checked={location === 'mumbai'} onChange={() => filterBySchool('location', 'mumbai')} />
        //                     <label htmlFor="test11">Mumbai</label>
        //                 </div>
        //                 <div className="radioBtn">
        //                     <input type="radio" id="test12" name="radio-group3" checked={location === 'bangalore'} onChange={() => filterBySchool('location', 'bangalore')} />
        //                     <label htmlFor="test12">Bangalore</label>
        //                 </div>
        //             </div>
        //         </div>
        //         <div className={`filterDropClose ${openRegion ? 'open' : ''} `}>
        //             <h3><a href="javascript:void(0);" onClick={() =>{if(location!=='') setOpenRegion(!openRegion)}}>Region  <img src={filterArrowSvg} /></a></h3>
        //             <div className="filtercheckbox">
        //                 <div className="radioBtn">
        //                     <input type="radio" id="test4" name="radio-group2" checked={region === 'east'} onChange={() => filterBySchool('region', 'east')} />
        //                     <label htmlFor="test4">East</label>
        //                 </div>
        //                 <div className="radioBtn">
        //                     <input type="radio" id="test5" name="radio-group2" checked={region === 'west'} onChange={() => filterBySchool('region', 'west')} />
        //                     <label htmlFor="test5">West</label>
        //                 </div>
        //                 <div className="radioBtn">
        //                     <input type="radio" id="test6" name="radio-group2" checked={region === 'north'} onChange={() => filterBySchool('region', 'north')} />
        //                     <label htmlFor="test6">North</label>
        //                 </div>
        //                 <div className="radioBtn">
        //                     <input type="radio" id="test7" name="radio-group2" checked={region === 'south'} onChange={() => filterBySchool('region', 'south')} />
        //                     <label htmlFor="test7">South</label>
        //                 </div>
        //             </div>
        //         </div>

        //     </div>
        // </div>
    )
}
export default CampaignSchoolFilter;