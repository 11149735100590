
const SettingsSVG = () => {

    return (
        <svg width="17" height="19" viewBox="0 0 17 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M15.7907 6.01071L15.2654 5.0993C14.8211 4.3281 13.8364 4.06206 13.0642 4.50456V4.50456C12.6966 4.72111 12.2579 4.78255 11.845 4.67533C11.432 4.56811 11.0787 4.30103 10.8629 3.933C10.724 3.69907 10.6494 3.43263 10.6466 3.16062V3.16062C10.6591 2.72451 10.4946 2.30191 10.1905 1.98908C9.8864 1.67624 9.46862 1.49982 9.03233 1.5H7.97417C7.54674 1.5 7.13694 1.67032 6.83543 1.97328C6.53392 2.27624 6.36556 2.68686 6.36762 3.11428V3.11428C6.35495 3.99675 5.63591 4.70547 4.75334 4.70538C4.48133 4.70256 4.21489 4.62795 3.98096 4.48911V4.48911C3.20871 4.04661 2.22401 4.31266 1.77967 5.08385L1.21584 6.01071C0.772037 6.78093 1.03446 7.76501 1.80285 8.21199V8.21199C2.30231 8.50035 2.60999 9.03327 2.60999 9.61C2.60999 10.1867 2.30231 10.7196 1.80285 11.008V11.008C1.03543 11.452 0.772726 12.4337 1.21584 13.2016V13.2016L1.74878 14.1207C1.95697 14.4964 2.30628 14.7736 2.7194 14.891C3.13253 15.0084 3.57542 14.9563 3.95006 14.7463V14.7463C4.31836 14.5314 4.75724 14.4726 5.16916 14.5828C5.58107 14.693 5.93189 14.9632 6.14363 15.3333C6.28246 15.5673 6.35707 15.8337 6.35989 16.1057V16.1057C6.35989 16.9973 7.08263 17.72 7.97417 17.72H9.03233C9.92086 17.72 10.6424 17.002 10.6466 16.1134V16.1134C10.6445 15.6847 10.814 15.2729 11.1171 14.9697C11.4203 14.6665 11.8321 14.4971 12.2609 14.4992C12.5322 14.5064 12.7976 14.5807 13.0333 14.7154V14.7154C13.8035 15.1592 14.7876 14.8968 15.2346 14.1284V14.1284L15.7907 13.2016C16.0059 12.8321 16.065 12.3921 15.9548 11.9789C15.8446 11.5657 15.5743 11.2135 15.2037 11.0003V11.0003C14.833 10.7871 14.5627 10.4349 14.4525 10.0217C14.3423 9.60852 14.4014 9.16847 14.6166 8.799C14.7566 8.55461 14.9593 8.35197 15.2037 8.21199V8.21199C15.9674 7.76525 16.2292 6.78692 15.7907 6.01843V6.01843V6.01071Z" stroke="#070707" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
            <circle cx="8.50694" cy="9.61002" r="2.22446" stroke="#070707" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export default SettingsSVG
