import React, { useEffect } from "react";
import search from "../../../../assets/images/search.svg";
import { DebounceInput } from 'react-debounce-input';
import closeCustome from "../../../../assets/images/close-1.svg";

// searchInpuOpen //true/false
// clearSearchIcon//true/false
// setSearchInpuOpen//function=>true/false
// setSearchKeys//function=>string
// searchKeys//string
// setClearSearchIcon//function=>true/false

const SearchInput = (props) => {
    const {searchInpuOpen, clearSearchIcon, setSearchInpuOpen, setSearchKeys, searchKeys, setClearSearchIcon,palaceholder,setCurrentpage}=props;
    useEffect(()=>{
        if(searchInpuOpen){
            setClearSearchIcon(true);
        }else{
            setClearSearchIcon(false);
        }
    },[searchInpuOpen])
    
    return (
            <div className={searchInpuOpen ? "searchinput searchinput-backendAdmin" : "searchinput searchinput-fos"}>
                <a href="javascript:void(0);" onClick={() => setSearchInpuOpen(true)}><img src={search} /></a>
                <>
                    {searchInpuOpen && <DebounceInput debounceTimeout={500} type="text" className="search-box" placeholder= {palaceholder ? palaceholder : "Search..."} value={searchKeys} onChange={(e) => { console.log("e.target.value.",e.target.value); setSearchKeys(e.target.value.trim()); setCurrentpage(1); }} />}
                    {clearSearchIcon && <button className="close-icon serachClose" type="reset" onClick={() => { setSearchKeys(""); setClearSearchIcon(false);setSearchInpuOpen(false) }}><img src={closeCustome} /></button>}
                </>

            </div>
    )
}
export default SearchInput;