import {
    useState,
    useContext,
    useEffect,
    forwardRef,
    useImperativeHandle,
    useRef
} from "react"

import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'

import Switch from "../../../../../components/addon/switch/switch"
import MultiSelect from "../../../../../components/formFields/multiSelect/multiSelect"
import ConfirmDialog from "../../../../../components/addon/confirmDialog/confirmDialog"
import EventDetails from "./eventDetails"

import AmenitySelection from "./amenitySelection"

import DataContext from "../../../../../service/contextProvider"

import SimpleReactValidator from "simple-react-validator"

import config from "../../../../../service/config"

import { validation } from "../../../../../service/validators"

import HelpIcon from "../../../../../assets/images/help.svg"
import errorSVG from "../../../../../assets/images/error.svg"

import { objectDeepClone } from "../../../../../service/helper"
import SingleSelect from "../../../../../components/formFields/singleSelect/singleSelect"
import { Steps } from "intro.js-react"

interface Props {
    data?: any,
    index?: any,
    onAvailForBrandingChange?: any,
    onVenueListUpdate?: any,
    onOtherVenueListUpdate?: any,
    onMonthsListUpdate?: any,
    onAmenitiesUpdate?: any,
    onSubmitClick?: any,
    isEdit?: boolean,
    // allData?: any,
    sortActivities?: any,
    onDateUpdate?: any,
    onAmenitiesChange?: Function,
    hideRevenue?: boolean,
    toggleNavigateFromTable?: Function
    isLastIndex?: boolean
}

const ActivityInfo = forwardRef(({
    data,
    index,
    onAvailForBrandingChange,
    onOtherVenueListUpdate,
    onVenueListUpdate,
    onMonthsListUpdate,
    onAmenitiesUpdate,
    isEdit,
    // allData,
    sortActivities,
    onDateUpdate,
    onAmenitiesChange,
    hideRevenue,
    toggleNavigateFromTable,
    isLastIndex
}: Props, ref) => {
    const parentData = useContext<any>(DataContext)

    const refEventDetails = useRef()

    const [formValidator] = useState(useRef(new SimpleReactValidator({
        element: (message, className) =>
            <span className="valError"><img src={errorSVG} />{message}</span>
        })))
    const [update, forceUpdate] = useState(false) // to re-render the dom after updating the validation

    const [updateCompleteState, setUpdateCompleteState] = useState(
        (parentData?.data?.student?.activites && parentData?.data?.student?.activites.length > 0) ? true : false
    )

    const [venueList, setVenueList] = useState([])
    const [otherVenueList, setOtherVenueList] = useState([])
    const [showTagsError, setShowTagsError] = useState(false)
    const [venueErrorMsg, setVenueErrorMsg] = useState("")
    const [showTagsInput, setShowTagsInput] = useState(false)
    const [overlay, setOverlay] = useState(false)
    const [formErrors, setFormErrors] = useState<any>({})
    const [amenityList, setAmenityList] = useState([])
    const [showNewCard, setShowNewCard] = useState(false)
    const [showInput, setShowInput] = useState(false)
    const [toBeEdited, setToBeEdited] = useState(0)
    const [errorOnEdit, setErrorOnEdit] = useState(false)
    const [newAmenityName, setNewAmenityName] = useState("")
    const [error, setError] = useState(false)
    const [sameNameError, setSameNameError] = useState(false)
    const [toggleAmenityDeleteConfirm, setToggleAmenityDeleteConfirm] = useState(false)
    const [selectedAmenityToDeleteIdx, setSelectedAmenityToDeleteIdx] = useState(null)
    const [selectedEntityName, setselectedEntityName] = useState("")
    const [amenityError, setAmenityError] = useState("")
    const [tag, setTag] = useState("")
    const [defaultObj, setDefaultObj] = useState({
        name: "Custom",
        availableForBranding: true,
        allowBrandsToProvide: false,
        isSelected: true,
        isCustom: true  
    })
    const [onBoarding, setOnboarding] = useState({
        stepsEnabled: false,
        initialStep: 0,
        steps: [
            {element: `#eventsDetailDate${index}`, intro: "Add one or multiple event dates here. If your event date is not finalised yet, simply select the month of the event.", position: "right"},
            {element: `#eventDetailLowerBox${index}`, intro: "Add all important inventory informations like you did in the previous step.", position: "right"}
        ],
        options: {
            exitOnOverlayClick: false,
            showStepNumbers: true,
            hidePrev: true,
            hideNext: false,
            nextLabel: "Next",
            prevLabel: "Prev",
            doneLabel: "Got it",
            autoPosition: false,
            exitOnEsc: false,
            scrollToElement: false,
            disableInteraction: true
        },
    })
    useEffect(() => {
        // setTimeout(() => {
        //     setOnboarding({...onBoarding, stepsEnabled: parentData?.tooltipProgress?.eventsAndOccasionsDetail2})
        // }, 2000)
        if(data?.availableForBranding){
            setOnboarding({...onBoarding, stepsEnabled: parentData?.tooltipProgress?.eventsAndOccasionsDetail2})
        }
    }, [data?.availableForBranding])

    useEffect(() => {
        let arr = []
        if (parentData?.data?.schoolAmenities?.amenities) {
            arr = parentData.data.schoolAmenities.amenities.map(el => {
                return {
                    label: el.name,
                    value: el.name
                }
            })
        }
        config.schoolAmenities.forEach(el => {
            const found = arr.find(e => e.label === el.name)
            if (!found) {
                arr.push({
                    label: el.name,
                    value: el.name
                })
            }
        })
        arr.push({
            label: "Other",
            value: "Other"
        })
        setVenueList(arr)
    }, [parentData?.data?.schoolAmenities?.amenities])

    useEffect(() => {
        fillActivityAttributesDetails();
    }, [])

    useEffect(() => {
        setUpdateCompleteState(true)
    }, [parentData?.data?.student?.activites])

    useEffect(() => {
        fillActivityAttributesDetails();
    }, [parentData?.data?.schoolAmenities?.amenities])

    useEffect(() => {
        if (data?.venue && data?.venue.length > 0 && data.venue.filter(v => v === "Other").length === 1) {
            setShowTagsInput(true)
        } else {
            setShowTagsInput(false)
        }
    }, [data.venue])

    const fillActivityAttributesDetails = () =>{
        process.nextTick(() => {
            if (data?.amenities.length > 0) {
                let amenities = [];                
                amenityList.forEach(e => {
                    const found = data.amenities.find(a => a.name === e.name)
                    if (found) {
                        amenities.push(found)
                    } else {
                        amenities.push(e)
                    }
                })
                if(isEdit === true){
                    if(sortActivities !== undefined){
                        // sortActivities && sortActivities.map((activity)=>{
                        //     let found = allData.filter(data => data.name === activity.name)
                        // })
                        if(sortActivities[index] !== undefined && sortActivities[index].amenities !== undefined){
                         sortActivities[index].amenities.map(am=>{
                                if(am.isCustom === true){
                                    amenities.push(am)
                                }
                            })
                        }
                    }
                    
                }else{
                    // parentData.data.student.activities.map((activity)=>{
                    //     let found = allData.filter(data => data.name === activity.name)
                    // })
                    if(parentData.data.student.activities[index] && parentData.data.student.activities[index].amenities){
                     parentData.data.student.activities[index].amenities.map(am=>{
                            if(am.isCustom === true){
                                amenities.push(am)
                            }
                        })
                    }
                }
                let amenitiesArr = amenities.filter((v,i,a)=>a.findIndex(t=>(t.name === v.name)) === i)
                setAmenityList(amenitiesArr)
            }
    })  
    }

    useImperativeHandle(ref,() => ({
        validateActivityInfo: async () => {
            if (data.availableForBranding === false) {
                return true
            } else {
                const result = formValidator.current.allValid()
                let otherTagsResult = true
                setShowTagsError(false)
                if (data.venue.find(el => el === "Other")) {
                    if (data.otherVenueList.length === 0) {
                        setVenueErrorMsg("Please provide venue name.")
                        setShowTagsError(true)
                        otherTagsResult = false
                    } 
                    else {
                        setShowTagsError(false)
                        otherTagsResult = true
                    }
                } else {
                    data.otherVenueList = []
                }
                if (result === false) {
                    formValidator.current.showMessages()
                    forceUpdate(true)
                } else {
                    formValidator.current.hideMessages()
                    forceUpdate(!update)
                }

                let facilities = []
                let validator = []
                let selectedAmenities = 0
                if (refEventDetails && refEventDetails.current) {
                    const ref: any = refEventDetails.current
                    facilities = ref.fetchAllAmenities()
                    validator = await ref.fetchValidation().catch(err => console.log(err))
                    selectedAmenities = await ref.selectedAmenities().catch(err => console.log(err))
                    setAmenityList(facilities)
                    if (selectedAmenities === 0) {
                        setAmenityError("Please select atleast one inventory.")
                    } else {
                        setAmenityError("")
                    }
                }
                // if(isEdit === true){
                //     if (parentData.data.schoolAmenities.amenities.length === 0) {
                //         setAmenityError("Please select atleat one amenity")
                //     } else {
                //         setAmenityError("")
                //     }
                // }else{
                //     if (data.amenities.length === 0) {
                //         setAmenityError("Please select atleat one amenity")
                //     } else {
                //         setAmenityError("")
                //     }
                // }
                
              return validator.length === 0 && result === true && otherTagsResult == true && selectedAmenities > 0
            }
        },
        fetchAllAmenities() {
            return amenityList
            // if (refEventDetails && refEventDetails.current) {
            //     return amenities
            // } else return []
          },
    }))

    const onBrandingChange = (branddingData) => {
        toggleNavigateFromTable()
        onAvailForBrandingChange(index, branddingData)
    }

    const onVenueChange = (venueData) => {
        toggleNavigateFromTable()
        const arr = venueData.map(el => el.value)
        onVenueListUpdate(index, arr)
    }

    const onOtherVenueListChange = (venueData) => {
        toggleNavigateFromTable()
        if(validateVenueName(venueData) === true) {
            console.log("......here 2",)
            onOtherVenueListUpdate(index, venueData)
        }
    }
    const onTagValueChange = (value) => {
        if(value.length <= 50){
            setTag(value)
        }
    }

    const validateVenueName = (venueData) => {
        const regex2 = /^(?:[A-Za-z]+)(?:[A-Za-z0-9 _]*)$/ ;

        let res = true;
        venueData.forEach(element => {
            if(!regex2.test(element)) {
                res = false;
            } 
        })
        console.log("......here", res)
        if(res === true) {
            setShowTagsError(false)
            return true
        }
        else {
            setVenueErrorMsg("Please provide valid venue name.")
            setShowTagsError(true)
            return false
        }
    }
    const onMonthsChange = (months) => {
        toggleNavigateFromTable()
        const arr = months.map(el => el.value)
        onMonthsListUpdate(index, arr)
    }

    const onDateChange = (date) => {
        onDateUpdate(index, date.value)
    }

    const onSelectionChange = (keyName, value, index, id) => {
        const arr = objectDeepClone(amenityList)
        arr[index][keyName] = value
        if (value === true) {
            arr[index]["isSelected"] = value
        }
        if (keyName === "isSelected") {
            if (value === false) {
                if(isEdit === true){
                    parentData.data?.removeActitiesAttributeArray.push(id)
                }
                arr[index]["allowBrandsToProvide"] = false
                arr[index]["availableForBranding"] = false
            } else {
                arr[index]["availableForBranding"] = true              
            }
        }
        setAmenityList(arr)
    }

    const onDependedSelectionChange = (keyName1, value1, keyName2, value2, index) => {
        const arr = objectDeepClone(amenityList)
        arr[index][keyName1] = value1
        if(value1 === true) {
            if (arr[index][keyName2] === true) {
                arr[index][keyName2] = false
            }
        }
        if (value1 === true || value2 === true) {
            arr[index]["isSelected"] = true
        }
        if (arr[index][keyName1] === false && arr[index][keyName2] === false) {
            arr[index]["isSelected"] = false
        }
        setAmenityList(arr)
    }

    const addNewCustomAmenity = () => {
        setShowNewCard(true)
    }

    const onNewAmenityChange = (event) => {
        setNewAmenityName(event.target.value)
    }
    const onNewAmenityEnter = () => {
        const arr = objectDeepClone(amenityList)
        let found = arr.find(e=> e.name.toUpperCase() === newAmenityName.toUpperCase())
        if(!found){
            setSameNameError(false)
            if (newAmenityName.trim() !== "") {
                let obj = objectDeepClone(defaultObj)
                obj["name"] = newAmenityName
                setAmenityList([...amenityList, obj])
                setShowNewCard(false)
                setNewAmenityName("")
            } else {
                setError(true)
                setSameNameError(false)
            }
        } else{
            setError(false)
            setSameNameError(true)
        }
    }

    const onEdit = (index) => {
        setShowInput(true)
        setToBeEdited(index)
    }

    const editAmenity = (oldAmenity, newAmenity) => {
        const arr = objectDeepClone(amenityList)
        let found = arr.find(e=> e.name.toUpperCase() === newAmenity.toUpperCase())
        if(!found || oldAmenity === newAmenity){
        setSameNameError(false)
            const newAmenities = amenityList.map((item) => {
                if (item.name === oldAmenity) {
                    item.name = newAmenity
                }
                return item
            })
            setAmenityList(newAmenities)
            setShowInput(false)
            setToBeEdited(0)     
        } else {
            setSameNameError(true)
       }
    }

    const removeAmenity = (index, name) => {
        setToggleAmenityDeleteConfirm(true)
        setselectedEntityName(name)
        setSelectedAmenityToDeleteIdx(index)
        setOverlay(true)
    }

    const amenityConfirmClose = (confirm) => {
        if (confirm === true) {
            if(isEdit === true){
                if(amenityList[selectedAmenityToDeleteIdx]["_id"] !== undefined){
                    parentData.data.removeActitiesAttributeArray.push(amenityList[selectedAmenityToDeleteIdx]["_id"])
                }
            }            
            const arr = objectDeepClone(amenityList)
            arr.splice(selectedAmenityToDeleteIdx, 1)
            setAmenityList(arr)
        }
        setToggleAmenityDeleteConfirm(false)
        setOverlay(false)
    }

    // useEffect(() => {
    //     const checkedList = amenityList.filter(el => el.isSelected === true)
    //     onAmenitiesUpdate(index, checkedList)
    // }, [amenityList])

    const hanldeOnboardingComplete = () => {
        parentData.setTooltipProgress({
            ...parentData.tooltipProgress,
            eventsAndOccasionsDetail2: false
        })
        let progress = {
            ...parentData.tooltipProgress,
            eventsAndOccasionsDetail2: false
        }
        localStorage.setItem("tooltipProgress", JSON.stringify(progress))
    }
    const exitOnBoarding = (e) => {
        console.log(e)
    }
    const handleBeforeChange = (index) => {
        if(index !== null && index !== undefined){
            let data = onBoarding.steps[index].element.split("")
            let array = data.slice(1).join("")
            console.log(array)
            document.getElementById(array).scrollIntoView({behavior: "auto", block: "center", inline: "nearest"})
        }
        // console.log(index)
    }

    return (
        <>
            <div className={`ovelap ${overlay === true ? "active" : ""}`}></div>
            <div className="activi-first" style={{borderBottom: isLastIndex ? '1px solid #ddd' : ''}}>
                <div className="activi-first-box">
                    <h3 className="htx2">{data.name} </h3>
                    <div className="ava-brad">
                        <b>Allow branding during events</b>
                        <Switch
                            form={data}
                            formKey="availableForBranding"
                            onValueChange={onBrandingChange}
                        />
                    </div>
                </div>
                <div
                    className="activi-box-open onclass1"
                    style={{
                        display: data.availableForBranding === true ? "block" : "none"
                    }}
                >
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-xs-12 row middle-xs">
                            <p className="ptx2">Venue</p>
                        </div>
                        <div
                            style={{zIndex: 5}}
                            className={`col-xl-6 col-lg-6 col-md-6 col-xs-12 poz ${showTagsInput === true ? "mb20" : ""}`}
                        >
                            <div className="sele-check">
                                <MultiSelect
                                    formData={data}
                                    formKey="venue"
                                    onDataChange={onVenueChange}
                                    placeholder="Select Venue"
                                    isRequired={false}
                                    options={venueList}
                                    formErrors={formErrors}
                                    formValidator={formValidator}
                                    validation="required|array|min:1"
                                />
                            </div>
                        </div>
                            {showTagsInput &&
                                <>
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-xs-12 row middle-xs">
                                        {/* <p className="ptx2">Please provide names</p> */}
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-xs-12 poz">
                                        <div>
                                            <TagsInput
                                                value={data.otherVenueList}
                                                onChange={onOtherVenueListChange}
                                                inputValue={tag}
                                                onChangeInput={onTagValueChange}
                                                inputProps={{
                                                    className: "tags-input",
                                                    placeholder: "Enter venue name"
                                                }}
                                                tagProps={{
                                                    className: 'react-tagsinput-tag-custom '
                                                }}
                                                addOnBlur={true}
                                                addOnPaste={true}
                                            />
                                            {
                                                showTagsError === true &&
                                                <span className="valError"><img src={errorSVG} />{venueErrorMsg}</span>
                                            }
                                        </div>
                                    </div>                                
                                </>
                            }
                        {/* <div className="col-xl-6 col-lg-6 col-md-6 col-xs-12 row middle-xs">
                            <p className="ptx2">Date</p>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-xs-12">
                            <div className="sele-check">
                                <SingleSelect
                                    formData={data}
                                    formKey="date"
                                    onDataChange={onDateChange}
                                    placeholder="Select Date"
                                    isRequired={false}
                                    options={Array.from({length: 31}, (_, i) => i + 1).map(e => { return {label: e, value: e}})} // gives array from 1 to 31
                                    formErrors={formErrors}
                                    formValidator={formValidator}
                                    validation="required"
                                />
                            </div>
                        </div> */}
                        <div id={`eventsDetailDate${index}`} style={{display: "flex", justifyContent: "space-between"}} className="row col-xl-12 col-lg-12 col-md-12 col-xs-12 mt20 pl pr">
                            <div className="col-xl-6 col-lg-6 col-md-6 col-xs-12 row middle-xs">
                                <p className="ptx2">Months</p>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-xs-12">
                                <div className="sele-check">
                                    <MultiSelect
                                        formData={data}
                                        formKey="months"
                                        onDataChange={onMonthsChange}
                                        placeholder="Select Months"
                                        isRequired={false}
                                        options={config.months}
                                        formErrors={formErrors}
                                        formValidator={formValidator}
                                        validation="required|array|min:1"
                                    />
                                </div>
                            </div>
                        </div>

                        <EventDetails
                            ref={refEventDetails}
                            amenityList={data.amenities}
                            amenityData={data}
                            eventIndex={index}
                            updateCompleteState={updateCompleteState}
                            onAmenitiesChange={onAmenitiesChange}
                            selectedActivityName={data.name}
                            hideRevenue={hideRevenue}
                            toggleNavigateFromTable={toggleNavigateFromTable}
                        />
                        {
                            amenityError &&
                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb20">
                                <span className="valError"><img src={errorSVG} />{amenityError}</span>
                            </div>
                        }
                    </div>
                </div>
            </div>

            <ConfirmDialog
                togglePopup={toggleAmenityDeleteConfirm}
                onModalClose={amenityConfirmClose}
                componentName={selectedEntityName}
            />
            <Steps
                enabled={onBoarding.stepsEnabled}
                steps={onBoarding.steps}
                initialStep={onBoarding.initialStep}
                onComplete={hanldeOnboardingComplete}
                onExit={exitOnBoarding}
                options={onBoarding.options}
                onBeforeChange={handleBeforeChange}
            />
        </>
    )
})

export default ActivityInfo
