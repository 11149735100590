import React from "react";
import { useState, useContext, useEffect, useRef } from "react"

import { useHistory } from "react-router-dom"
// import OnGoingCampaigns from "./onGoingCampaigns"
// import Pagination from "../../../components/addon/pagination"
// import arrowView from "../../../assets/images/arrow-view.svg"
// import tableFilter from "../../../assets/images/table-filter.svg"
// import AppContextObject from "../../../common/context/common"
// import MultiPointMap from "../../../components/addon/multiPointMap"
// import scriptLoader from 'react-async-script-loader'
import { BackendAdminContextInitiator } from "../../../../backendAdminSrc/apis/backendAdminContextInitiator";
import AppContextObject from "../../../../common/context/common"
import config from "../../../../service/config"
// import DataContext from "../../../service/brandContext"
import filterSvg from "../../../../assets/images/filter-svg.svg"
import filterArrowSvg from "../../../../assets/images/filter-arrow-svg.svg"
// import TopBar from "../schools/components/topBar"
// import BrandTopBar from "./components/brandTopBar"
// import { returnReadableStatus } from "../../../service/helper"
import { DebounceInput } from 'react-debounce-input'
import SearchInput from "./SearchInput";
import Pagination from "../../../../components/addon/pagination";
import CustomSearchInput from "../../../../components/formFields/customSearchInput";
import { titleCase } from "../../../../service/helper";
import useClickOutside from "../../../../service/useClickOutside";
var campaignSchoolListGlobalVar = 1

const NonWisrSchoolsList = (props) => {
    const { showSchoolModal, closeSchoolModal, campaignId } = props
    const context = useContext(AppContextObject);
    const history = useHistory()

    const [searchInpuOpen, setSearchInpuOpen] = useState(false);
    const [clearSearchIcon, setClearSearchIcon] = useState(false);
    const [openFilter, setOpenFilter] = useState(false);
    const [openStateFilter, setOpenStateFilter] = useState(false)
    const [openCityFilter, setOpenCityFilter] = useState(false)
    const [openGenderFilter, setOpenGenderFilter] = useState(false);
    const [openBoardFilter, setOpenBoardFilter] = useState(false);
    const [openLanguageFilter, setOpenLanguageFilter] = useState(false);
    const [filterCity, setFilterCity] = useState([]);
    const [filterGender, setFilterGender] = useState([]);
    const [filterBoard, setFilterBoard] = useState([]);
    const [filterLanguage, setFilterLanguage] = useState([]);
    const [searchKeys, setSearchKeys] = useState("");
    const [schools, setSchools] = useState<any>([]);
    const [totalPage, setTotalPage] = useState([0])
    const [totalPageCount, setTotalPageCount] = useState(0)
    const [currentPage, setCurrentpage] = useState(1)
    const [cityStateData, setCityStateData] = useState({})
    const [stateList, setStateList] = useState([])
    const [selectedState, setSelectedState] = useState([])
    const [cityList, setCityList] = useState([])
    const [loading,setLoading]=useState(false);
    const filterRef = useRef(null)
    const filterOptionsRef = useRef(null)
    useClickOutside(filterOptionsRef, (event) => {
        const el = filterRef?.current;

        if (!el || el.contains(event.target)) {
            return;
        }
        setOpenFilter(false)
    })

    useEffect(() => {
        getSchoolList();
    }, [showSchoolModal, searchKeys, currentPage, filterBoard, filterLanguage, filterGender, filterCity, selectedState])
    useEffect(() => {
        if(currentPage !== 1){
            setCurrentpage(1)
        }
    }, [searchKeys, filterBoard, filterLanguage, filterGender, filterCity, selectedState])
    useEffect(() => {
        getAllSchool()
    }, [])
    useEffect(() => {
        fetchCityByState()
    }, [selectedState])
    const fetchCityByState = async() => {
        let city = []
        selectedState.forEach(state => {
            city = [...city, ...cityStateData[state]]
        })
        setCityList([...city])
    }

    const getAllSchool = async() => {
        setLoading(true);
        let resp = await context.apis[context.user.role].getAllNonWisrCampaignSchools(
            context.validator, { _id: campaignId }, {}
        )
        if(resp && resp.done){
            console.log("getAllNonWisrCampaignSchools", resp)
            setLoading(false);
            let states = [], cityStateObj = {}
            resp.response.rows.forEach(school => {
                if( school.state && !states.includes(school.state)){
                    states.push(school.state)
                }
                if(school.state && school.city){
                    if(cityStateObj[school.state]){
                        if(school.city && !cityStateObj[school.state].includes(school.city)){
                            cityStateObj[school.state].push(school.city)
                        }
                    }
                    else{
                        cityStateObj[school.state] = [school.city]
                    }
                }
            })
            console.log("first", states, cityStateObj)
            setStateList([...states])
            setCityStateData({...cityStateObj})
        }
    }
    const getSchoolList = async () => {
        setLoading(true);
        var localVar = Math.random()
        campaignSchoolListGlobalVar = localVar
        let resp = await context.apis[context.user.role].getAllNonWisrCampaignSchools(
            context.validator, { _id: campaignId, searchValue: searchKeys, city: filterCity, language: filterLanguage, board: filterBoard, state: selectedState}, {page: currentPage, limit: 10, withCount: true, sort: {_id: -1}}
        )
        console.log("getAllNonWisrCampaignSchools",resp)
        if (resp && resp.done) {
            if(campaignSchoolListGlobalVar === localVar){
                setLoading(false);
                setSchools(resp.response.rows);
                setTotalPageCount(resp.response.count)
                let total = Math.ceil(resp.response.count / 10);
                let arr = []
                for (let i = 0; i < total; i++) {
                    arr.push(i)
                }
                setTotalPage([...arr])
            }
            else{
                setLoading(false);
            }
        }
    }
    const filteration = (category, value) => {
        console.log(category, value)
        if (category === 'state') {
            let array = [...selectedState]
            if (array.includes(value)) {
                array.splice(array.indexOf(value), 1)
                if(filterCity.length !== 0){
                    let citiesToRemove = [...cityStateData[value]], newCitiesData = [...filterCity]
                    for(let city of citiesToRemove){
                        if(newCitiesData.indexOf(city) !== -1){
                            newCitiesData.splice(newCitiesData.indexOf(city), 1)
                        }
                    }
                    setFilterCity([...newCitiesData])
                }
            }
            else {
                array.push(value)
                setFilterCity([...filterCity,...cityStateData[value]])
            }
            setSelectedState([...array])
        }
        else if (category === 'city') {
            let array = [...filterCity]
            if (array.includes(value)) {
                array.splice(array.indexOf(value), 1)
            }
            else {
                array.push(value)
            }
            setFilterCity([...array])
        }
        // else if (category === 'gender') {
        //     let array = [...filterGender]
        //     if (array.includes(value)) {
        //         array.splice(array.indexOf(value), 1)
        //     }
        //     else {
        //         array.push(value)
        //     }
        //     setFilterGender([...array])

        // }
        // else if (category === 'board') {
        //     let array = [...filterBoard]
        //     if (array.includes(value)) {
        //         array.splice(array.indexOf(value), 1)
        //     }
        //     else {
        //         array.push(value)
        //     }
        //     setFilterBoard([...array])

        // }
        // else if (category === 'language') {
        //     let array = [...filterLanguage]
        //     if (array.includes(value)) {
        //         array.splice(array.indexOf(value), 1)
        //     }
        //     else {
        //         array.push(value)
        //     }
        //     setFilterLanguage([...array])

        // }


    }
    const clearAllFilters = () => {
        setFilterCity([]);
        // setFilterGender([]);
        // setFilterBoard([]);
        // setFilterLanguage([]);
        setSelectedState([]);
        setOpenFilter(false)
        setOpenBoardFilter(false)
        setOpenStateFilter(false)
        setOpenCityFilter(false)
        setOpenGenderFilter(false)
        setOpenLanguageFilter(false)
    }
    const onPageChange = (page) => {
        setCurrentpage(page)
    }
    const onNextClick = () => {
        setCurrentpage(prev => prev + 1)
    }
    const onPrevClick = () => {
        setCurrentpage(prev => prev - 1)
    }
    const closePopup = ()=>{
        closeSchoolModal();
        setOpenFilter(false)
        setSearchInpuOpen(false)
        setSelectedState([])
        setFilterCity([]);
        // setFilterGender([]);
        // setFilterBoard([]);
        // setFilterLanguage([]);
        setSearchKeys('');
    }
    const handleSearch = (e) => {
        if(e === ""){
            setSearchKeys("")
        }
        else{
            setSearchKeys(e.target.value)
        }
    }
    console.log(filterLanguage, filterCity, filterGender, filterBoard)
    return (
        <>
        <div className={`ovelap ${showSchoolModal === true ? "active" : ""}`}></div>
            <div id="inventoryMore" className={`popup ${showSchoolModal === false ? "hide" : ""}`}>
                <div className="popup-header">
                    <h3 className="htx2 pophead">Schools Selected</h3>
                    <span className="close toggle" onClick={closePopup} data-target="myPopup">close</span>
                </div>
                <div className="popup-body">
                    <div className="viewdetailsHead">
                        <div className="invetorypopblock-school">
                            <div className="rightPart noBack">
                                <div className="row schools-list-popup">
                                    <div className="col-xl-9 col-lg-9 col-md-12 col-xs-12">
                                        <div className="row">
                                            
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-10 pr">
                                                {/* {showCampaignCreativeButton && <button onClick={() => history.push({pathname: "/brands/brand-details/create-campaign/creative", state: {campaignId: campaignId, creativeRework: true}})}>Upload creatives</button>} */}
                                                {/* {showCampaignCreativeButton && <a href="javascript:void(0)" className="btn btn-orange spaceL uploadCretiveLink" onClick={() => history.push({pathname: "/brands/brand-details/create-campaign/creative", state: {campaignId: campaignId, creativeRework: true}})}>Upload creatives</a>} */}
                                            
                                                <div className="fR filterbox">
                                                    <a href="javascript:void(0);" className="filterDrop" onClick={() => setOpenFilter(!openFilter)}>
                                                        <img src={filterSvg} />
                                                        <h3>Filter</h3>
                                                        <img src={filterArrowSvg} />
                                                    </a>
                                                    <div className={`filterDropDown ${openFilter ? 'open' : ''}`}>
                                                        <div className="filterDropHead" ref={filterRef}>
                                                            <h2>Filter <a href="javascript:void(0);" className="viewwLink" onClick={clearAllFilters}>Clear All</a></h2>
                                                        </div>
                                                        <div className="filterDropBody" ref={filterOptionsRef}>
                                                            {/* <div className={`filterDropClose ${openLocationFilter ? 'open' : ''}`}>
                                                                <h3><a href="javascript:void(0);" onClick={() => setOpenLocationFilter(!openLocationFilter)}>{`Location`}  <img src={filterArrowSvg} /></a></h3>
                                                                <div className="filtercheckbox">
                                                                    {config.location.map((item, index) => (
                                                                        <div className="checkbox fnone" key={index} >
                                                                            <input type="checkbox" id={item.value} checked={filterCity.includes(item.value)} />
                                                                            <label htmlFor={item.value} onClick={() => filteration('location', item.value)}>{item.label}</label>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div> */}

                                                            <div className={`filterDropClose ${openStateFilter ? 'open' : ''}`}>
                                                                <h3><a href="javascript:void(0);" onClick={() => setOpenStateFilter(!openStateFilter)}>{`State ${selectedState.length > 0 ? `(${selectedState.length})` : ""}`}  <img src={filterArrowSvg} /></a></h3>
                                                                <div className="filtercheckbox">
                                                                    {stateList.map((item, index) => (
                                                                        <div className="checkbox fnone" key={index} >
                                                                            <input type="checkbox" id={item} checked={selectedState.includes(item)} />
                                                                            <label htmlFor={item} onClick={() => filteration('state', item)}>{titleCase(item)}</label>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                            <div className={`filterDropClose ${openCityFilter && selectedState.length > 0 ? 'open' : ''}`}>
                                                                <h3><a href="javascript:void(0);" onClick={() => setOpenCityFilter(!openCityFilter)}>{`City ${filterCity.length > 0 ? `(${filterCity.length})` : ""}`}  <img src={filterArrowSvg} /></a></h3>
                                                                <div className="filtercheckbox">
                                                                    {cityList.map((item, index) => (
                                                                        <div className="checkbox fnone" key={index} >
                                                                            <input type="checkbox" id={item} checked={filterCity.includes(item)} />
                                                                            <label htmlFor={item} onClick={() => filteration('city', item)}>{titleCase(item)}</label>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                            {/* <div className={`filterDropClose ${openGenderFilter ? 'open' : ''}`}>
                                                                <h3><a href="javascript:void(0);" onClick={() => setOpenGenderFilter(!openGenderFilter)}>{`Gender ${filterGender.length > 0 ? `(${filterGender.length})` : ""}`}  <img src={filterArrowSvg} /></a></h3>
                                                                <div className="filtercheckbox">
                                                                    {config.gender.map((item, index) => (
                                                                        <div className="checkbox fnone" key={index}>
                                                                            <input type="checkbox" id={item.value} checked={filterGender.includes(item.value)} />
                                                                            <label htmlFor={item.value} onClick={() => filteration('gender', item.value)}>{item.label}</label>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                            <div className={`filterDropClose ${openBoardFilter ? 'open' : ''}`}>
                                                                <h3><a href="javascript:void(0);" onClick={() => setOpenBoardFilter(!openBoardFilter)} >{`Education Board ${filterBoard.length > 0 ? `(${filterBoard.length})` : ""}`}  <img src={filterArrowSvg} /></a></h3>
                                                                <div className="filtercheckbox">
                                                                    {config.boardOptions.filter((item) => item.value.toLowerCase() !== "custom" && item.value.toLowerCase() !== "other").map((item, index) => (
                                                                        <div className="checkbox fnone" key={index}>
                                                                            <input type="checkbox" id={item.value} checked={filterBoard.includes(item.value)} />
                                                                            <label htmlFor={item.value} onClick={() => filteration('board', item.value)}>{item.label}</label>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                            <div className={`filterDropClose ${openLanguageFilter ? 'open' : ''}`}>
                                                                <h3><a href="javascript:void(0);" onClick={() => setOpenLanguageFilter(!openLanguageFilter)}>{`Language of Study ${filterLanguage.length > 0 ? `(${filterLanguage.length})` : ""}`}  <img src={filterArrowSvg} /></a></h3>
                                                                <div className="filtercheckbox">
                                                                    {config.languages.filter((item) => item.value.toLowerCase() !== "custom").map((item, index) => (
                                                                        <div className="checkbox fnone" key={index}>
                                                                            <input type="checkbox" id={item.value} checked={filterLanguage.includes(item.value)} />
                                                                            <label htmlFor={item.value} onClick={() => filteration('language', item.value)}>{item.label}</label>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="inventorySearch schoolFilter inventoryBackAdmin">

                                                {/* <SearchInput
                                                    searchInpuOpen={searchInpuOpen} //true/false
                                                    clearSearchIcon={clearSearchIcon} //true/false
                                                    setSearchInpuOpen={setSearchInpuOpen} //function=>true/false
                                                    setSearchKeys={setSearchKeys} //function=>string
                                                    searchKeys={searchKeys} //string
                                                    setClearSearchIcon={setClearSearchIcon}//function=>true/false
                                                    palaceholder='School name'
                                                    setCurrentpage={setCurrentpage}
                                                /> */}
                                                <CustomSearchInput
                                                    searchInpuOpen={searchInpuOpen}
                                                    setSearchInpuOpen={setSearchInpuOpen}
                                                    clearSearchIcon={clearSearchIcon}
                                                    setClearSearchIcon={setClearSearchIcon}
                                                    searchKeys={searchKeys}
                                                    handleChange={handleSearch}
                                                    palaceholder="School name"
                                                />

                                                </div>
                                            </div>
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-20 pl pr">
                                                <div className="table-container mt-10 addAdmininput fillterTableHeight">
                                                    <table className="table going-campaign">
                                                        <thead>
                                                            <tr>
                                                                <th>SL No</th>
                                                                <th>School Name</th>
                                                                <th>State</th>
                                                                <th>City</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            {!loading ?<>
                                                                {schools && schools.length>0 ? schools.map((school, key) => {
                                                                    return (
                                                                        <tr key={key}>
                                                                            <td>{(key + 1) + (currentPage - 1) * 10}</td>
                                                                            <td>
                                                                                <div className="schoolNameTable">
                                                                                    <h4>{school.schoolName}</h4>
                                                                                    {/* <span>{school?.board}</span>
                                                                                    <span>{school.city}</span>
                                                                                    <span>{school.state}</span> */} 
                                                                                </div>
                                                                            </td>
                                                                            <td>{titleCase(school.state)}</td>
                                                                            <td>{titleCase(school.city)}</td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            :
                                                            <tr><td colSpan={4}>No School found</td></tr>
                                                            }
                                                            </>
                                                            :
                                                            [...Array(10)].map((el, index) => {
                                                                return (
                                                                    <tr>
                                                                        {
                                                                            [...Array(4)].map((el, index) => {
                                                                                return (
                                                                                    <td key={index}>
                                                                                        <div className="content-loader-block"></div>
                                                                                    </td>
                                                                                )
                                                                            })
                                                                        }
                                                                    </tr>
                                                                )
                                                            })
}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                
                                                {console.log('totalPage',totalPageCount)}
                                                {totalPageCount > 10 &&
                                                    <Pagination
                                                        currentPage={currentPage}
                                                        totalPage={totalPage}
                                                        onPageChange={onPageChange}
                                                        onNextClick={onNextClick}
                                                        onPrevClick={onPrevClick}
                                                        loading={loading}
                                                    />
                                                }
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}
export default NonWisrSchoolsList;