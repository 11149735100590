import React from "react";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { Toaster } from "react-hot-toast"

import AppRouter from "./publicSrc/appRouter"

import commonContext, { AppContextProvider, AppContext } from './common/context/common';
import { Validator } from "./common/utils/validator";
import { SocketManager } from "./common/utils/socketmanager";

import { PublicContextInitiator } from "./publicSrc/apis/publicContextInitiator";
import { SchoolAdminContextInitiator } from "./schoolAdminSrc/apis/schoolAdminContextInitiator";
import { SchoolPrincipalContextInitiator } from "./schoolPrincipalSrc/apis/schoolPrincipalContextInitiator";
import { BackendAdminContextInitiator } from "./backendAdminSrc/apis/backendAdminContextInitiator";
import { BrandManagerContextInitiator } from "./brandManagerSrc/apis/brandManagerContextInitiator";
import { eraseAllCookies, eraseCookie } from "./service/helper";
import ConfirmDialog from "./components/addon/confirmDialog/confirmDialog";
import NoControlDialog from "./components/addon/NoControlDialog/NoControlDialog";
import { BrandCMOContextInitiator } from "./brandCMOSrc/apis/brandCMOContextInitiator";
import { BackendFOSContextInitiator } from "./backendFOSSrc/apis/backendFOSContextInitiator";
import { BackendSalesContextInitiator } from "./backendSalesSrc/apis/backendSalesContextInitiator";
import { BackendOperationsContextInitiator } from "./backendOperationsSrc/apis/backendOperationsContextInitiator";
import { BackendTechContextInitiator } from "./backendTechSrc/apis/backendTechContextInitiator";
import { BackendFinanceContextInitiator } from "./backendFinanceSrc/apis/backendFinanceContextInitiator";

type props = {}
type state = {
  commonProgressbarVisible: boolean, updateApp: number, loading: boolean, offline: boolean, toggleInactiveDialog: boolean, isInactive: boolean,
  contextUser: any
}
export default class App extends React.Component<props, state> {
  static contextType = commonContext;
  events: Array<string>;
  warnTimer: any;
  logoutTimer: any;
  publicContextInitiator: PublicContextInitiator;
  schoolAdminContextInitiator: SchoolAdminContextInitiator;
  validator: Validator;
  loggedIn: boolean = false;
  constructor(props: props) {
    super(props);
    this.state = { commonProgressbarVisible: false, updateApp: Date.now(), loading: true, offline: false, toggleInactiveDialog: false, isInactive: this.context && this.context.inactive, contextUser: this.context && this.context.user };
    this.validator = new Validator(() => { console.log("") }, () => { console.log("") })
    window.addEventListener('online', () => {
      this.setState({ ...this.state, offline: false })
      this.setTimer()
    });
    window.addEventListener('offline', () => {
      this.setState({ ...this.state, offline: true, isInactive: false, toggleInactiveDialog: false })
      this.clearTimer()
    });
    this.events = ["load", "mousemove", "mousedown", "mouseover", "click", "scroll", "keypress"]
    for (var i in this.events) {
      window.addEventListener(this.events[i], () => this.resetTimer());
    }
    this.setTimer()
  }
  setTimer() {
    if (window.location.pathname !== "/login" && window.location.pathname !== "/") {
      this.warnTimer = setTimeout(() => this.warn(), 1000 * 60 * 45) // open confirm dialog at 45min
      this.logoutTimer = setTimeout(() => this.logoutUser(), 1000 * 60 * 60) // auto logout at 60min
      if (this.context) {
        (this.context as AppContext).inactive = false
      }
    }
  }
  resetTimer() {
    this.clearTimer()
    this.setTimer()
  }
  clearTimer() {
    if (this.warnTimer) clearTimeout(this.warnTimer)
    if (this.logoutTimer) clearTimeout(this.logoutTimer)
  }
  logoutUser = async () => {
    this.destroy()
    if (this.context && this.context.user) {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      };
      const response: any = await fetch("/api/logout", requestOptions).catch(err => { console.log("err>>>", err); })
      const res = await response.json()
      if (res && res.result) {
        eraseAllCookies();
        eraseCookie("session-wisr-core-microservice-backend")
        this.context.socketManager.socket.disconnect();
        window.location.href = `/login?mode=noActivity`
      }
    }
    if (this.context && this.context.user == undefined) {
      eraseAllCookies();
      eraseCookie("session-wisr-core-microservice-backend")
      this.context.socketManager.socket.disconnect();
      window.location.href = `/login?mode=noActivity`
    }

  }
  destroy() {
    this.clearTimer()
    for (var i in this.events) {
      window.removeEventListener(this.events[i], this.resetTimer);
    }
  }
  warn() {
    this.setState({ ...this.state, toggleInactiveDialog: true })
    this.setState({ ...this.state, isInactive: true })
    if (this.context) {
      (this.context as AppContext).inactive = true
    }
  }
  inactiveConfirmClose = async (confirm) => {
    // if (confirm === true) {
    // redirectToFirstPage()
    // }
    this.resetTimer()
    this.setState({ ...this.state, isInactive: false, toggleInactiveDialog: false })
    if (this.context) {
      (this.context as AppContext).inactive = false
    }
    // window.location.href = `/?mode=noActivity`
  }
  setLoggedIn(val: boolean = true) {
    if (!!val) {
      if (this.context.user) {
        sessionStorage.setItem('currentUser', this.context.user._id);
        this.loggedIn = true;
      }
    } else {
      sessionStorage.clear();
      this.loggedIn = false;
    }
  }
  componentDidMount() {
    this.initializingContextInitatorFile();
    this.attachUser();
    this.checkSession();
    if (window.location.pathname === "/login" || window.location.pathname === "/"){
      eraseCookie("session-wisr-core-microservice-backend")
    }
    // document.addEventListener("visibilitychange", this.onFocus)
  }

  checkSession() {
    // console.log('Check if need to Logout', this.context.socketManager.socket, this.context.socketManager.socket);
    // this.context.socketManager.socket.removeEventListener('logout');
    this.context.socketManager.socket.on('logout', (data) => {
      // console.log('Logout Event Received', data, this.context.user);
      // const currentUser = localStorage.getItem("currentUserId")
      // const prevUser = localStorage.getItem("prevUserId")
      // if(this.context.user == prevUser) {
      //   alert("Logging out because new session created")
      //   document.location.href = "/"
      // }
      const currentUser = sessionStorage.getItem('currentUser');
      // console.log("current user is ", currentUser);
      if (data.user == currentUser || this.loggedIn) {
        alert("Logging out because new session created!");
        this.context.socketManager.socket.disconnect();
        sessionStorage.removeItem('currentUser');
        document.location.href = "/"
      }
    });
  }

  componentWillUnmount() {
    window.removeEventListener('visibilitychange', this.onFocus, false);
  }

  onFocus() {
    // console.log(document.visibilityState)
    if (document.visibilityState === "visible") {
      process.nextTick(() => {
        // const currentUser = localStorage.getItem("currentUserId")
        // const prevUser = localStorage.getItem("prevUserId")
        // if(currentUser !== prevUser) {
        //   if (window.location.pathname !== "/") {
        //     localStorage.setItem("prevUserId", currentUser)
        //     alert("Logging out because new session created")
        //     document.location.href = "/"
        //   }
        // }
        // this.context.socketManager.socket.removeEventListener('logout');
        // this.context.socketManager.socket.on('logout', (data) => {
        //   console.log('Logout Event Received', data, this.context.user);
        //   if(currentUser === prevUser) {
        //     alert("Logging out because new session created")
        //     document.location.href = "/"
        //   }
        // });
      })
    }
  }

  async initializingContextInitatorFile() {
    if (!this.context.apis) {
      this.context.apis = {}
    }
    if (!this.context.apis.public) { this.context.apis.public = new PublicContextInitiator(this.context); }
    if (!this.context.apis.schoolAdmin) { this.context.apis.schoolAdmin = new SchoolAdminContextInitiator(this.context); }
    if (!this.context.apis.schoolPrincipal) { this.context.apis.schoolPrincipal = new SchoolPrincipalContextInitiator(this.context); }
    if (!this.context.apis.backendAdmin) { this.context.apis.backendAdmin = new BackendAdminContextInitiator(this.context); }
    if (!this.context.apis.brandManager) { this.context.apis.brandManager = new BrandManagerContextInitiator(this.context); }
    if (!this.context.apis.brandCMO) { this.context.apis.brandCMO = new BrandCMOContextInitiator(this.context); }
    if (!this.context.apis.backendFOS) { this.context.apis.backendFOS = new BackendFOSContextInitiator(this.context); }
    if (!this.context.apis.backendSales) { this.context.apis.backendSales = new BackendSalesContextInitiator(this.context); }
    if (!this.context.apis.backendOperations) { this.context.apis.backendOperations = new BackendOperationsContextInitiator(this.context); }
    if (!this.context.apis.backendTech) { this.context.apis.backendTech = new BackendTechContextInitiator(this.context); }
    if (!this.context.apis.backendFinance) { this.context.apis.backendFinance = new BackendFinanceContextInitiator(this.context); }
  }
  reRender() {
    this.setState({ ...this.state, updateApp: Date.now() })
  }
  async attachUser() {
    this.setState({ ...this.state, loading: true })
    if (typeof this.context.user === "undefined") {
      let resp = await this.context.apis.schoolPrincipal.getLoggedInUser(this.validator);
      // console.log("resp--->>", resp);
      if (resp && resp.done) {
        (this.context as AppContext).user = resp.response;
        this.setLoggedIn(true);
        this.setState({ ...this.state, updateApp: Date.now() })
      }
      this.setState({ ...this.state, loading: false })
    } else {
      this.setState({ ...this.state, loading: false })
    }
  }

  // Listen for Logout Event sent from server
  // async checkSession() {
  //   // this.context.socketManager.socket.removeEventListener('logout');
  //   this.context.socketManager.socket.on('logout', (data) => {
  //     console.log('Logout Event Received', data, sessionStorage.getItem('currentUser'));
  //     if(data.user == sessionStorage.getItem('currentUser')) {
  //       alert('You are already LoggedIn in another Tab, Logging Out');
  //       document.location.href = "/";
  //     }
  //   });
  // }

  render() {
    if (!this.context.socketManager) {
      this.context.socketManager = new SocketManager(this.context, {
        onSend: () => {
          if (!this.state.commonProgressbarVisible) {
            this.setState({ ...this.state, commonProgressbarVisible: true });
          }
        }, onReceive: () => {
          this.setState({ ...this.state, commonProgressbarVisible: false });
        }
      });
    }
    if (!this.context.validator) { this.context.validator = new Validator(() => { console.log("") }, () => { console.log("") }) }
    return (
      <>
        <ConfirmDialog
          confirmTitle="You were inactive for past 45 minutes. Would you like to continue?"
          confirmText="Continue"
          denyText="Cancel"
          togglePopup={this.state.toggleInactiveDialog}
          onModalClose={this.inactiveConfirmClose}
          onConfirmBtn={true}
        />
        <div className={`ovelap ${this.state.offline === true || this.state.toggleInactiveDialog === true ? "active" : ""}`}></div>
        <Router basename="/">
          {
            this.state.loading === false &&
            <Switch>
              <Route>
                <AppContextProvider value={this.context}>
                  <AppRouter
                    setLoggedIn={(val) => { this.setLoggedIn(val) }}
                    methods={{
                      reRender: () => this.reRender()
                    }}
                  />
                </AppContextProvider>
              </Route>
            </Switch>
          }
        </Router>
        <Toaster
          position="top-right"
        />
        <NoControlDialog
          togglePopup={this.state.offline}
          displayMsg="Your are offline! Please wait until your internet comes back."
        />
      </>
    )
  }
}
