import React, { useState } from "react"

const Loader = (props) => {

    const { loadingMessage } = props

    const [message] = useState(loadingMessage ? loadingMessage : "Loading...")
    return (
        <div className="loaderWrapper">
            <div className="fullScreenLoader"></div>
            <div className=""><p className="mt-15">{message}</p></div>
        </div>
    )
}

export default Loader
