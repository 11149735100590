import { useState, useContext, useEffect, useRef } from "react"

import { Link } from "react-router-dom"

import { useHistory } from "react-router-dom"

import SimpleReactValidator from "simple-react-validator"

import backBtn from "../../../../assets/images/back-arrow.svg"
import errorSVG from "../../../../assets/images/error.svg"

import TextField from "../../../../components/formFields/textField/textField"
import TextfieldWithPrefix from "../../../../components/formFields/textFieldWithPrefix/textFieldWithPrefix"
import OTPVerifyModal from "../../../../components/addon/otpVerifyModal"
import AlertDialog from "../../../../components/addon/alertDialog/alertDialog"
import ConfirmAlert from "../../../../components/addon/confirmAlert"

import { objectDeepClone, compareObjs, hasOnlyLettersAndSpaces, hasOnlyLettersNumberAndSpaces } from "../../../../service/helper"

import  { PublicContextInitiator } from "../../../apis/publicContextInitiator"

import AppContextObject from "../../../../common/context/common"
import config from "../../../../service/config"
import SingleSelect from "../../../../components/formFields/singleSelect/singleSelect"

const ProfileContact = (props) => {
    const {
        events,
        progress,
        brandProfile,
        isBackendUser
    } = props

    const history = useHistory()
    const context = useContext(AppContextObject)

    const [overlay, setOverlay] = useState(false)
    const [isPincodeValid, setIsPincodeValid] = useState(true)
    const [isPhoneVerified, setIsPhoneVerified] = useState(true)
    const [selectedContactForOTP, setSelectedContactForOTP] = useState("")

    const [msgDetails, setMsgDetails] = useState('')
    const [isMobileOTP, setIsMobileOTP] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [getVerifyPhoneStatus, setGetVerifyPhoneStatus] = useState(false)
    const [toggleAlertPopUp, setToggleAlertPopUp] = useState(false)
    const [detailsCaptured, setDetailsCaptured] = useState('')
    const [getFieldInfo, setGetFieldInfo] = useState('')
    const [inProgressEmail, setInProgressEmail] = useState(false)
    const [inProgressPhone, setInProgressPhone] = useState(false)

    const [showPrompt, setShowPrompt] = useState(false)
    const [oldFormData, setOldFormData] = useState<any>({})
    const [formData, setFormData] = useState<any>({})

    const [formValidator] = useState(useRef(new SimpleReactValidator({
        element: (message, className) =>
            <p className="valError">
                <img src={errorSVG} />
                {message==='The mobile no must be a valid phone number.'
                ?
                'The mobile no must be a valid mobile no.'
                :
                message
                }
            </p>
    })))
    const [update, forceUpdate] = useState(false)

    useEffect(() => {
        if (events && events.setActiveModule) {
            events.setActiveModule({
                module: "contact"
            })
        }
        if (brandProfile && brandProfile.contact) {
            setFormData(objectDeepClone(brandProfile.contact))
            setOldFormData(objectDeepClone(brandProfile.contact))
        }
    }, [])

    useEffect(() => {
        const isSame = compareObjs(formData, oldFormData)

        if (isSame === false) {
            setShowPrompt(true)
        } else {
            setShowPrompt(false)
        }
    }, [formData])

    useEffect(() => {
        fetchCityState()  
    }, [formData.pincode])

    useEffect(() => {
        if (showModal === false) setOverlay(false)
    }, [showModal])

    const onFormDataChange = (event) => {
        if(event.target.id === "state") {
            if(hasOnlyLettersAndSpaces(event.target.value)){
                setFormData({ ...formData, [event.target.id]: event.target.value })
            }
        }
        else if(event.target.id === "city") {
            // if(context.utils.cityNameRegex.test(event.target.value)){
            if(hasOnlyLettersNumberAndSpaces(event.target.value)){
                setFormData({ ...formData, [event.target.id]: event.target.value })
            }
        }
        else{
            setFormData({
                ...formData,
                [event.target.id]: event.target.value
            })
        }
    }

    const onCountryChange = (data) => {
        setFormData({
            ...formData,
            country: data.value
        })
    }

    const fetchCityState = async () => {
        if(formData.pincode && formData.pincode.length == 6) {
            const publicContextInitiator = new PublicContextInitiator(context)
            console.log("formData.pincode>>>",formData.pincode)
            let resp = await context.apis.public.getPincodeDetails(
                context.validator, {"pincode": parseInt(formData.pincode)}, {}
            )
                console.log("resp city",resp)
            if(resp && resp.done){
                onStateCityChange(resp.response.stateName, resp.response.district)
                setIsPincodeValid(true)
            }else{
                setIsPincodeValid(false)
            }
        } else{
            setIsPincodeValid(true)
            onStateCityChange("", "")
        }
    }

    const onVerifyMobileClick = async() => {
        const result = formValidator.current.fieldValid("phone")
        if(result === false) {
            formValidator.current.showMessageFor("phone")
            forceUpdate(!update)
            return
        } else {
            formValidator.current.hideMessageFor("phone")
            forceUpdate(!update)
        }

        if(isPhoneVerified) {
            return
        }
        setInProgressPhone(true)
        let publicContextInitiator = new PublicContextInitiator(context)
        let resp = await publicContextInitiator.sendOTPOnPhone(
            context.validator, {"phone":formData.phone}
        )

        setIsMobileOTP(true)
        setSelectedContactForOTP(formData.phone)
        setShowModal(true)
        setGetVerifyPhoneStatus(true)
        setOverlay(true)
        setToggleAlertPopUp(false)
    }

    const onStateCityChange = (dataState, dataCity) => {
        dataCity = dataCity.toLowerCase()
        if (dataCity || dataState) {
            setFormData({...formData, "state": dataState, "city": dataCity})
        }
    }

    const onModalSubmit = (otp) => {
        setIsPhoneVerified(true)
        setOverlay(false)
    }

    console.log("context>>>",context);
    const onSubmitClick = () => {
        const result = formValidator.current.allValid()

        if(isPhoneVerified==false) {
            setMsgDetails('You need to verify your mobile number to continue, Just click on verify to get OTP.')
            setOverlay(true)
            setToggleAlertPopUp(true)
            return
        }

        if (result === false || isPincodeValid === false) {
            formValidator.current.showMessages()
            forceUpdate(true)
            return
        } else {
            formValidator.current.hideMessages()
            forceUpdate(!update)
        }

        setShowPrompt(false)
    
        process.nextTick(() => {
            if (events && events.setBrandProfile) {
                progress.contact = true
                const json = objectDeepClone(brandProfile)
                json.contact = objectDeepClone(formData)
                console.log("json>>>>>",json);
                events.setBrandProfile(json)
                if(isBackendUser){
                    history.push("/backend/backend-details/brands/brand-details/verification")
                }else{
                    history.push("/brands/brand-details/profile/verification")
                }
            }
        })
    }

    const confirmClose = () => {
        setOverlay(false)
        setToggleAlertPopUp(false)
    }

    const onOTPModalClose = () => {
        setInProgressEmail(false);
        setInProgressPhone(false);
    }

    const confirmAlertCallback = (data) => {
        setOverlay(data.overlay)
    }

    formValidator.current.rules['city_name'] = {
        message: 'Please enter valid city name.',
        rule: (val) => {
            let returnValue = context.utils.cityNameRegex.test(val)
            return returnValue
        }
    }

    return (
        <>
            <div className={`ovelap ${overlay === true ? 'active' : ''}`}></div>
            <div className="col-xl-9 col-lg-9 col-md-12 col-xs-12">
                <div className="rightPart noBack">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-xs-6">
                            <Link to={isBackendUser ? "/backend/backend-details/brands/brand-details/description" :"/brands/brand-details/profile/description"} className="backbtn dblock">
                            <img src={backBtn} />BACK </Link>
                        </div>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-20">
                            <h2 className="htx5 clearfix">Tell us more about your company.</h2>
                        </div>
                        <div className="col-xl-7 col-lg-7 col-md-10 col-xs-12 mt-40 mb-20 forminpt-bottom pl pr">
                            <div className="row">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                    {/* <TextField
                                        formData={formData}
                                        formKey='country'
                                        onDataChange={onFormDataChange}
                                        label='Country'
                                        placeholder='Enter Country Name'
                                        isRequired={false}
                                        formValidator={formValidator}
                                        validation='required'
                                    /> */}
                                    <SingleSelect
                                        formData={formData}
                                        formKey='country'
                                        onDataChange={onCountryChange}
                                        label='Country'
                                        placeholder='Enter Country Name'
                                        isRequired={false}
                                        options={config.country}
                                        formValidator={formValidator}
                                        validation='required'
                                    />
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12">
                                    <TextField
                                        formData={formData}
                                        formKey="pincode"
                                        maxLength={6}
                                        onDataChange={onFormDataChange}
                                        label="Pin Code"
                                        placeholder="Enter Pincode"
                                        isRequired={false}
                                        formValidator={formValidator}
                                        validation="required|min:6|numeric"
                                        isPincodeValid={isPincodeValid}
                                    />
                                    {
                                        console.log("isPincodeValid ",isPincodeValid )
                                    }
                                </div>
                                
                                <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12"></div>                                
                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                    <TextField
                                        formData={formData}
                                        formKey='address'
                                        onDataChange={onFormDataChange}
                                        label='Address'
                                        placeholder='Street/Building/Locality name'
                                        isRequired={false}
                                        formValidator={formValidator}
                                        validation='required'
                                        maxLength={100}
                                    />
                                </div>
                                
                                <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12">
                                    <TextField
                                        formData={formData}
                                        formKey="state"
                                        onDataChange={onFormDataChange}
                                        label="State"
                                        placeholder="Enter State"
                                        isRequired={false}
                                        formValidator={formValidator}
                                        validation="required|alpha_space"
                                        maxLength={50}
                                    />
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12">
                                    <TextField
                                        formData={formData}
                                        formKey="city"
                                        onDataChange={onFormDataChange}
                                        label="City"
                                        placeholder="Enter City"
                                        isRequired={false}
                                        formValidator={formValidator}
                                        validation="required|city_name"
                                        maxLength={50}
                                    />
                                </div>
                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                    <TextfieldWithPrefix
                                        formData={formData}
                                        formKey='mobileNo'
                                        onDataChange={onFormDataChange}
                                        label='Mobile No'
                                        placeholder='Eg. 9999 999 999'
                                        isRequired={false}
                                        // onVerifyClick={onVerifyMobileClick}
                                        isVerified={isPhoneVerified}
                                        formValidator={formValidator}
                                        validation='required|phone'
                                    />
                                </div>
                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 pl-0 mt-30">
                                    <div className="forminpt fR">
                                        <a onClick={onSubmitClick} href="javascript:void(0);" className="btn btn-orange brand-btn">Next</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <OTPVerifyModal
                contact={selectedContactForOTP}
                isMobile={isMobileOTP}
                showModal={showModal}
                setShowModal={setShowModal}
                onModalSubmit={onModalSubmit} 
                isMobileAndEmail = {false}       
                onClose={onOTPModalClose}
            />

            <AlertDialog
                togglePopup={toggleAlertPopUp}
                fieldInfo={getFieldInfo}
                details={detailsCaptured}
                onModalClose={confirmClose}
                displayMsg={msgDetails}
            />

            <ConfirmAlert
                when={showPrompt}
                title="Leave page without saving..."
                cancelText="Cancel"
                okText="Confirm"
                onOK={() => true}
                onCancel={() => false}
                parentCallback={confirmAlertCallback}
            />
        </>
    )
}

export default ProfileContact
