import * as React from "react"
import * as ReactDOM from "react-dom"

import "./assets/css/flexboxgrid.css"
import "./assets/css/stylesheet.css"
import "./assets/css/fontAwesome/css/font-awesome.min.css"

import "./assets/css/login/style.css"
import "./assets/css/updateSchoolDetails/style.css"
import "./assets/css/style.css"
import "intro.js/introjs.css";
import "./assets/css/brands/brandsStyle.css"
import "./assets/css/backendAdmins/backendStyle.css"
// import "./assets/css/addEvent.css"
import "./assets/css/responsive.css"

import App from "./app"

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)
