import React, { useContext, useState, useEffect,useRef } from "react"

import { useHistory } from "react-router-dom"

import toast from "react-hot-toast"

import { validation } from "../../../service/validators"

import Textfield from "../../../components/formFields/textField/textField"
import PasswordField from "../../../components/formFields/passwordField"

import loginWave from "../../../assets/images/login-wave.svg"
import envelope from "../../../assets/images/envolpe.svg"
import mobile from "../../../assets/images/phone-icon.svg"
import username from "../../../assets/images/user-icon.svg"
import logo from "../../../assets/images/logo.png"
import TextFieldWithPrefix from "../../../components/formFields/textFieldWithPrefix/textFieldWithPrefix"
import AppContextObject from "../../../common/context/common"
import DataContext from "../../../service/contextProvider"
import OTPVerifyModal from "../../../components/addon/otpVerifyModal"
import SimpleReactValidator from "simple-react-validator"
import AlertDialog from "../../../components/addon/alertDialog/alertDialog"
import errorSVG from "../../../assets/images/error.svg"
import TextFieldWithIcon from "../../../components/formFields/textfieldWithIcon"
import OnboardingBackBtn from "../schools/components/onboardingBackBtn"
import { eraseAllCookies, eraseCookie, specialCharOnly } from "../../../service/helper"

interface Props {
    methods
}
const UserVerification = ({
    methods
}: Props) => {

    const history = useHistory()

    let [formErrors, setFormErrors] = useState<any>({})
    const context = useContext <any> (AppContextObject)
    let [loginError, setLoginError] = useState(null)
    const [formData, setFormData] = useState({
        username: '',
        email: '',
        phone: '',
    })
    const [userRole, setUserRole] = useState("")
    const [isPhoneVerified, setIsPhoneVerified] = useState(false);
    const [getVerifyPhoneStatus, setGetVerifyPhoneStatus] = useState(false);
    const [isMobileOTP, setIsMobileOTP] = useState(false);
    const [selectedContactForOTP, setSelectedContactForOTP] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [overlay, setOverlay] = useState(false)
    const [toggleAlertPopUp, setToggleAlertPopUp] = useState(false);
    const [getVerifyUsernameStatus, setGetVerifyUsernameStatus] = useState(false);
    const [isUsernameVerified, setIsUsernameVerified] = useState(false);
    const [getVerifyEmailStatus, setGetVerifyEmailStatus] = useState(false);
    const [isEmailVerified, setIsEmailVerified] = useState(false);
    const [msgDetails, setMsgDetails] = useState('');
    const [detailsCaptured, setDetailsCaptured] = useState('');
    const [getFieldInfo, setGetFieldInfo] = useState('');
    const [usernameError, setUsernameError] = useState(false)
    const [inProgressEmail, setInProgressEmail] = useState(false)
    const [inProgressPhone, setInProgressPhone] = useState(false)
    const [currentEmail, setCurrentEmail] = useState("")
    const [currentPhone, setCurrentPhone] = useState("")
    const [formValidator] = useState(useRef(new SimpleReactValidator({
        element: (message, className) =>
            <span className="valError">
                <img src={errorSVG} />{
                message === "The phone field is required." 
                ? "The mobile no field is required." 
                : 
                message === "The phone must be a valid phone number."
                ?
                "The mobile no must be a valid mobile no."
                :
                message
                }
                </span>
        })))
        const [update, forceUpdate] = useState(false) // to re-render the dom after updating the validation
    // let parentdata = useContext(DataContext)

    if (history.location.state) {
        // alert("wait")
        if(!context.user || !context?.user?.role){
            document.location.reload()
        }
    } 
    useEffect(() => {
        // redirecting user to user verification screen if isVerifed is false
        if (context && context.user && context.user.isVerified === true) {
            // document.location.reload()
            if(context.user.role.startsWith("school")){
                window.location.href = "/schools/school-details/dashboard"
            }else if(context.user.role.startsWith("brand")){
                window.location.href = "/brands/brand-details/dashboard"
            }else if(context.user.role.startsWith("backend")){
                window.location.href = "/backend/backend-details/dashboard"
            }
        }
    }, [])
    useEffect(() => {
        // if(context.user.phone != "") {
        //     setIsPhoneVerified(true)
        //     setGetVerifyPhoneStatus(true)
        // }
        // else {
        //     setIsPhoneVerified(false)
        //     setGetVerifyPhoneStatus(false)
        // }
        if(context.user){
            if(context.user.username && context.user.username !== ""){
                setIsUsernameVerified(true)
                setGetVerifyUsernameStatus(true)
            }
        }
        
    }, [])

    // useEffect(() => {
    //     if(!history.location.state){
    //         history.push("/login")
    //     }
    // }, [])  

    useEffect(() => {
        if(context.user){
            if(context.user.username && context.user.username !== "" && context.user.username === formData.username){
                setIsUsernameVerified(true)
                setGetVerifyUsernameStatus(true)
            }
        }
        else{
            setIsUsernameVerified(false)
            setGetVerifyUsernameStatus(false)
        }
        if(sessionStorage.getItem('updateUserDetailsData')){
            setIsUsernameVerified(true)
            setGetVerifyUsernameStatus(true)
            setGetVerifyEmailStatus(true)
            setIsEmailVerified(true)
            setGetVerifyPhoneStatus(true)
            setIsPhoneVerified(true)
        }
    }, [formData.username])

    useEffect(() => {
        if(context.user){
            setFormData({
                email: context.user.email,
                username : context.user.username,
                phone : context.user.phone
            })
            setUserRole(context.user.role)
        }
        
    }, [])

    useEffect(() => {
        window.addEventListener("beforeunload", handleBeforeUnload)
        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload)
        }
    }, [])

    const handleBeforeUnload = (e) => {
        e.preventDefault()
        window.addEventListener("unload", function(){
            // deleteUnsavedUser(unsavedUser)
            sessionStorage.removeItem("updateUserDetailsData")
        })
        setTimeout(function() {
            setTimeout(function() {
                // sessionStorage.removeItem("imageKeys")
                // sessionStorage.removeItem("userIds")
            }, 1000);
        },1);
        const message = "Are you sure you want to reload the page? All provided data will be lost."
        e.returnValue = message
        return message
    }

    const onFormDataChange = (event) => {

        if(event.target.id === "username"){
            setFormData({
                ...formData,
                username : event.target.value.trim().toLowerCase()
            }) 
            setGetVerifyUsernameStatus(false)
            setIsUsernameVerified(false)
            setUsernameError(false)

        }
        else if(event.target.id == "phone") {
            if(formData.phone != event.target.value) {
                if(event.target.parentNode.querySelectorAll(".vrifiyotp")[0].className.indexOf("complete") >= 0) {
                    event.target.parentNode.querySelectorAll(".vrifiyotp")[0].className = event.target.parentNode.querySelectorAll(".vrifiyotp")[0].className.replace(/ complete/gi, "");
                    setIsPhoneVerified(false)
                    setGetVerifyPhoneStatus(false)
                }
                if (currentPhone === event.target.value && currentPhone != ""){
                    setIsPhoneVerified(true)
                    setGetVerifyPhoneStatus(true)
                }
            }
        }
        if(event.target.id == "email") {
            if(formData.email != event.target.value) {
                if(event.target.parentNode.querySelectorAll(".vrifiyotp")[0].className.indexOf("complete") >= 0) {
                    event.target.parentNode.querySelectorAll(".vrifiyotp")[0].className = event.target.parentNode.querySelectorAll(".vrifiyotp")[0].className.replace(/ complete/gi, "");
                    setIsEmailVerified(false)                    
                    setGetVerifyEmailStatus(false)
                }
                if (currentEmail === event.target.value && currentEmail != ""){
                    setIsEmailVerified(true)
                    setGetVerifyEmailStatus(true)
                }
            }
        }
        if(event.target.id == "phone"){
            setFormData({
                ...formData,
                [event.target.id] : event.target.value
            })
        }
        if(event.target.id == "email") {
            setFormData({
                ...formData,
                [event.target.id] : event.target.value.trim()
            })
        }

    }

    const onSubmit = async (event) => {
        event.preventDefault()
        // const errors = validation(formData)
        // setFormErrors(errors)
        // if (Object.keys(errors).length > 0) {
        //   return
        // }
        const result = formValidator.current.allValid()
        if (result === false) {
            formValidator.current.showMessages()
            forceUpdate(true)
            return
        } else {
            formValidator.current.hideMessages()
            forceUpdate(!update)
        }
            if(isEmailVerified==false || getVerifyEmailStatus==false) {
                setMsgDetails('You need to verify your email to continue, Just click on verify to get OTP.')
                setOverlay(true)
                setToggleAlertPopUp(true)
                return
            }
            if(getVerifyUsernameStatus === false){
                setMsgDetails('You need to verify your username to continue, Just click on verify to get verified.')
                setOverlay(true)
                setToggleAlertPopUp(true)
                return
            }
            if(isPhoneVerified==false || getVerifyPhoneStatus==false) {
                setMsgDetails('You need to verify your mobile number to continue, Just click on verify to get OTP.')
                setOverlay(true)
                setToggleAlertPopUp(true)
                return
            }
        let updatingData = {}
        Object.assign(updatingData, {email: formData.email, phone: formData.phone, username: formData.username, isVerified: true})
        console.log("context.user.role--->>>>>",context.user.role);
        sessionStorage.setItem('updateUserDetailsData', JSON.stringify(updatingData))
        // let resp = await context.apis[context.user.role].updateUserDetails(
        //     context.validator, {}, updatingData
        // )
        // if(resp && resp.done){
            // context.user.isVerified = true
            // toast.success("User verification completed.")
            history.push({
                pathname: '/reset-password',
                state: {
                    username : context.user.username,
                    doNotLogout: true
                },
              })
        // }

    }

    const onModalSubmit = (otp) => {
        if(isMobileOTP) {
            setCurrentPhone(formData.phone)
            setIsPhoneVerified(true)
        }
        else {
            setCurrentEmail(formData.email) 
            setIsEmailVerified(true)
        }
    }

    const onVerifyMobileClick = async() => {
        const result = formValidator.current.fieldValid("phone")
        if(result === false) {
            formValidator.current.showMessageFor("phone")
            forceUpdate(!update)
            return
        } else {
            formValidator.current.hideMessageFor("phone")
            forceUpdate(!update)
        }

        if(isPhoneVerified) {
            return
        }

        setInProgressPhone(true)

        // let err = validation({"phone": formData.phone})
        // setFormErrors(err)
        // console.log("form errors..", formErrors, Object.keys(err).length)
        // if(Object.keys(err).length > 0) {
        //     setFormErrors({"phone": "phone number is invalid"})
        //     return
        // }

        // if(isPhoneVerified) {
        //     return
        // }

        // if(isPhoneVerified) {
        //     return
        // }

        let resp = await context.apis.public.sendOTPOnPhone(
            context.validator, {"phone":formData.phone}
        )

        if(!resp.response.userFound) {
            setIsMobileOTP(true)
            setSelectedContactForOTP(formData.phone)
            setShowModal(true)
            setGetVerifyPhoneStatus(true)
            setOverlay(false)
            setToggleAlertPopUp(false)
        }
        else {
            setGetFieldInfo("Phone No.");
            setDetailsCaptured(formData.phone);
            setGetVerifyPhoneStatus(false)
            setMsgDetails(`The Phone No.- ${formData.phone} - entered already exists. Kindly use a different Phone No. to register.`)
            setOverlay(true)
            setToggleAlertPopUp(true)
            setInProgressPhone(false)
        }
    }

    let error = ""
    formValidator.current.rules['user_name'] = {
        message: error === "required" ? 'The user name field is required.' : usernameError ? "Username is already exists." : usernameError === null ? `${formData.username} Username is been taken couple of seconds ago. Try another?` : usernameError === undefined ? 'Username must be minimum 4 characters long.' :'Username is invalid, Username can contain letters (a-z), numbers and spe. chars(_@.).',
        rule: (val) => {
            let returnValue = true
            if (!val || val.trim() === "") {
                error = "required"
                returnValue = false
            }
            
            const chars = "!#$%^&*()-{}[]+=<>?/,~'` "
            for (let i = 0; i < val.split("").length; i++) {
                const v = val.split("")[i]
                if (!v || v.trim() === " " || chars.split("").find(ch => ch === v)) {
                    error = ""
                    returnValue = false
                    break
                }
            }

            if(usernameError){
                error = ""
                returnValue = false
            }

            if(usernameError === null){
                error = ""
                returnValue = false
            }

            if(usernameError === undefined){
                error = ""
                returnValue = false
            }

            return returnValue
        },
    }
    formValidator.current.rules['valid_user_name'] = {
        message: 'Username can not contain only special character.',
        rule: (val) => {
            let returnValue = true
            if(!specialCharOnly(val)){
                returnValue = false
            }
            return returnValue
        }
    }
    const onVerifyUsernameClick = async() => {
        if(formData.username.length < 4){
            setUsernameError(undefined)
            formValidator.current.showMessageFor("username")
            return
        }
        const result = formValidator.current.fieldValid("username")
        if(result === false) {
            formValidator.current.showMessageFor("username")
            forceUpdate(!update)
            return
        } else {
            formValidator.current.hideMessageFor("username")
            forceUpdate(!update)
        }

        // let err = validation({"username": formData.username})
        // setFormErrors(err)
        // if(Object.keys(err).length > 0) {
        //     setFormErrors({"username": "username is invalid"})
        //     return
        // }

        let resp = await context.apis.public.findUserByUsername(
            context.validator, {"username": formData.username}
        )
        if((resp && resp.done === false) && context.user.username !== formData.username){
            let found = resp.response.filter(element => element._id !== context.user._id);
            if(found.length > 0){
                formValidator.current.showMessageFor("username")
                setGetVerifyUsernameStatus(false)
                setIsUsernameVerified(false)
                // setMsgDetails(`The Username- ${formData.username} - entered already exists. Kindly use a different username to register.`)
                // setOverlay(true)
                // setToggleAlertPopUp(true)
                setUsernameError(true)
                return
            }
        }
        else{
            setGetVerifyUsernameStatus(true)
            setIsUsernameVerified(true)
        }
    }

    const onVerifyEmailClick = async() => {
        const result = formValidator.current.fieldValid("email")
        if(result === false) {
            formValidator.current.showMessageFor("email")
            forceUpdate(!update)
            return
        } else {
            formValidator.current.hideMessageFor("email")
            forceUpdate(!update)
        }

        if(isEmailVerified) {
            return
        }
        // let err = validation({"email": formData.username})
        // setFormErrors(err)
        // if(Object.keys(err).length > 0) {
        //     setFormErrors({"email": "email is invalid"})
        //     return
        // } 
        setInProgressEmail(true)

        let resp = await context.apis.public.sendOTPOnEmail(
            context.validator, {"email":formData.email}
        )
        // if(!resp.response.userFound) {
            setIsMobileOTP(false)
            setSelectedContactForOTP(formData.email)
            setShowModal(true)
            setGetVerifyEmailStatus(true)
            setOverlay(false)
            setToggleAlertPopUp(false)
        // }
        // else {
        //     setGetFieldInfo("Email Id");
        //     setDetailsCaptured(formData.email);
        //     setGetVerifyEmailStatus(false)
        //     setMsgDetails(`The Email Id - ${formData.email} - entered already exists. Kindly use a different Email Id to register.`)
        //     setOverlay(true)
        //     setToggleAlertPopUp(true)
        // }
    }

    const confirmClose = () => {
        setOverlay(false)
        setToggleAlertPopUp(false)
    }

    const onOTPModalClose = () => {
        setInProgressEmail(false);
        setInProgressPhone(false);
    }

    const logoutUser = () =>{
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
        };

        fetch("/api/logout", requestOptions)
        .then(response => response.json())
        .then(data => {
            if(data.done){
                eraseAllCookies();
                eraseCookie("session-wisr-core-microservice-backend")
                context.socketManager.socket.disconnect();
                document.location.href = "/login"
                // history.push({
                //     pathname: '/login',
                //     // state: {
                //     //     fromResetPassword : true,
                //     // },
                //   })
                sessionStorage.clear()
            }
        }).catch(err=>{
            console.log(err);
        })
    }

    return (
        <>
            <div className={`ovelap ${(showModal === true || overlay === true) ? "active" : ""}`}></div>
            <div className="main-wapper">
                <div className="loginbox">
                    <div className="login-wave"> <img src={loginWave} alt="login-wave" /> </div>
                    <div className="login-form">
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 ">
                                {/* <img src={logo} alt="logo" /> */}
                                <img src={logo} alt="logo" className="mb-40" />
                                <div className="clearfix w100"></div>
                                <span onClick={logoutUser}><OnboardingBackBtn text='Back to login' /></span>
                            </div>
                            <h2 className="htx1">Verification</h2>
                        </div>
                        <form data-form-validation="login-form" id="login-form-parent">
                            <div className="row">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                    {/* <Textfield
                                        formData={formData}
                                        formKey='email'
                                        onDataChange={onFormDataChange}
                                        label='Your Email ID'
                                        placeholder='Your Email ID'
                                        isRequired={false}
                                        onVerifyClick={onVerifyEmailClick}
                                        isVerified={isEmailVerified}
                                        formValidator={formValidator}
                                        validation='required|email'
                                    /> */}
                                   <TextFieldWithIcon
                                        label='Your Email ID'
                                        placeholder='Your Email ID'
                                        isRequired={false}
                                        icon={envelope}
                                        formErrors={formValidator}
                                        formData={formData}
                                        formKey="email"
                                        validation="required|email"
                                        onFormDataChange={onFormDataChange}
                                        onVerifyClick={onVerifyEmailClick}
                                        inProgress={inProgressEmail}
                                        isVerified={isEmailVerified}
                                        isEditable={userRole === "backendFOS" ? false : true}
                                    />
                                </div>
                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                    <TextFieldWithPrefix
                                        formData={formData}
                                        formKey='phone'
                                        onDataChange={onFormDataChange}
                                        label='Mobile No'
                                        placeholder='Eg. 9999999999'
                                        isRequired={false}
                                        isIconAndPhcode={true}
                                        icon={mobile}
                                        onVerifyClick={onVerifyMobileClick}
                                        inProgress={inProgressPhone}
                                        isVerified={isPhoneVerified}
                                        formValidator={formValidator}
                                        validation='required|phone'
                                        isEditable={userRole === "backendFOS" ? false : true}
                                    />
                                </div>
                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                    {/* <Textfield
                                        formData={formData}
                                        formKey='username'
                                        onDataChange={onFormDataChange}
                                        label='Your Username'
                                        placeholder='Username'
                                        isRequired={false}
                                        formValidator={formValidator}
                                        validation='required'
                                        onVerifyClick={onVerifyUsernameClick}
                                        isVerified={isUsernameVerified}
                                    /> */}
                                    <TextFieldWithIcon
                                        label='Your Username'
                                        placeholder='Username'
                                        isRequired={false}
                                        icon={username}
                                        formErrors={formValidator}
                                        formData={formData}
                                        formKey="username"
                                        validation="required|user_name|valid_user_name"
                                        onFormDataChange={onFormDataChange}
                                        onVerifyClick={onVerifyUsernameClick}
                                        isVerified={isUsernameVerified}
                                        maxLength={16}
                                    />
                                </div>
                                <div className="row col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                    <div className="forminpt">
                                        <input onClick={onSubmit} value="Proceed" className="btn btn-orange datasubmit" type="button" />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <OTPVerifyModal
                contact={selectedContactForOTP}
                isMobile={isMobileOTP}
                showModal={showModal}
                setShowModal={setShowModal}
                onModalSubmit={onModalSubmit}
                isMobileAndEmail = {false}    
                onClose={onOTPModalClose}
            />
            <AlertDialog
            togglePopup={toggleAlertPopUp}
            fieldInfo={getFieldInfo}
            details={detailsCaptured}
            onModalClose={confirmClose}
            displayMsg={msgDetails}
        />
        </>
    )
}

export default UserVerification
