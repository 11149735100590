import { useState, useContext, useEffect } from "react"

import { useHistory } from "react-router-dom"
// import OnGoingCampaigns from "./onGoingCampaigns"
import tableFilter from "../../../assets/images/table-filter.svg"
import riarrownext from "../../../../assets/images/arrow-view.svg"
import mapsImg from "../../../assets/images/maps.jpg"

import TopBar from "../../schools/components/topBar"
import AppContextObject from "../../../../common/context/common"
import imgViewTwo from "../../../../assets/images/backendAdmin/img-view-two.jpg"
import { formatDateShowMonth, getPercentageComplition, returnReadableStatus } from "../../../../service/helper"
import HorizentalBarChart from "../../../../components/charts/horizentalBarChart"
import scriptLoader from 'react-async-script-loader'
import config from "../../../../service/config"
import MultiPointMap from "../../../../components/addon/multiPointMap"
import InventoryMore from "../../../../components/addon/inventoryMore"
import BackendTopBar from "../components/backendTopBar"
import SingleSelect from "../../../../components/formFields/singleSelect/singleSelect"
import BackendBackBtn from "../components/backendBackBtn"
const CampaignDetails = (props) => {
    const {
        isScriptLoaded,
        isScriptLoadSucceed
    } = props
    const context = useContext(AppContextObject)
    const history = useHistory()
    const [capmaignDetails, setCapmaignDetails] = useState<any>({})
    const [reachChartData, setReachChartData] = useState<any>()
    const [impressionChartData, setImpressionChartData] = useState<any>()
    const [tab, setTab] = useState("reach")
    const [pincodeList, setPincodeList] = useState([])
    const [campaignCompletion, setCampaignCompletion] = useState(0)
    const [showInventoryModal, setShowInventoryModal] = useState(false)
    const [inventoryList, setInventoryList] = useState([])
    const [paymentStatus, setPaymentStatus] = useState({value: ""})
    const paymentStatusOption = [{label: "Pending", value: "pending"}, {label: "Paid", value: "paid"}]
    const chartData = [
        {
            name: "ClassRoom",
            reach: "10000",
            impression: "20000"
        },
        {
            name: "School bus",
            reach: "30000",
            impression: "15000"
        },
        {
            name: "Auditorium",
            reach: "40000",
            impression: "24000"
        },
        {
            name: "Canteen",
            reach: "8000",
            impression: "5000"
        },
        {
            name: "Hall",
            reach: "12000",
            impression: "8000"
        }
    ]
    let campaignId = ""
    useEffect(() => {
        configureChartData()
    }, [])
    useEffect(() => {
        if(history.location.state){
            let data: any = history.location.state
            if(data.campaignId){
                campaignId = data.campaignId
            }
            getCampaignDetails()
        }
    }, [])
    useEffect(() => {
    }, [reachChartData, impressionChartData])
    const configureChartData = () => {
        let label = [], impression = [], reach = []
        chartData.forEach((data) => {
            label.push(data.name)
            reach.push(Number(data.reach)/1000)
            impression.push(Number(data.impression)/1000)
        })
        setReachChartData({
            labels: label,
            datasets: [
                {
                    label: "Reach (No of students * 1000)",
                    axis: "y",
                    data: reach,
                    backgroundColor: "#fe6e00",
                    borderRadius: "50",
                    boarderWidth: 1,
                    barThickness: 10
                }],
            options: {}
        })
        setImpressionChartData({
            labels: label,
            datasets: [
                {
                    label: "Impression (No of students * 1000)",
                    axis: "y",
                    data: impression,
                    backgroundColor: "#fe6e00",
                    borderRadius: "50",
                    boarderWidth: 1,
                    barThickness: 10
                }],
            options: {}
        })
    }
    const getCampaignDetails = async() => {
        const resp = await context.apis.public.getCampaignById(
            context.validator, {_id: campaignId}, {}
        )
        if(resp && resp.done){
            console.log("response-------", resp)
            setCapmaignDetails(resp.response)
            setPaymentStatus({value: resp.response.paymentStatus})
            let completion = getPercentageComplition(resp.response.startDate, resp.response.endDate)
            setCampaignCompletion(completion)
            let arr = resp.response.schoolCampaignDatas[0].inventories
            let inventory = arr.map((inventory) => {
                return {name: inventory.name, image: inventory.images[0] || "no image"}
            })
            setInventoryList([...inventory])
            let array = resp.response.schoolCampaignDatas.map((data) => data.schoolDetails.city || "")
            setPincodeList(array)
        }
    }
    const closeInventoryModal = () => {
        setShowInventoryModal(false)
    }
    const onPaymentStatusChange = async(data) => {
        console.log(data.value)
        let resp = await context.apis[context.user.role].updateCampaignStatus(
            context.validator, {_id: campaignId}, {paymentStatus: data.value}
        )
        console.log(resp)
        setPaymentStatus({value: data.value})
    }
    // getCampaignById
    return (
        <>
            <div className={`ovelap`}></div>
            {/* <TopBar isDashboard={true} /> */}
            <div className="col-xl-10 col-lg-9 col-md-12 col-xs-12">
            <div className="rightPart noBack">
                    <BackendTopBar redirectPage={true}/>
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-10">
                            <a className="backbtn dblock mb-0" href="javascript:void(0);"><img src="/static/media/back-arrow.7d7b4362.svg" /></a>
                        </div>
                        <div className="col-xl-8 col-lg-8 col-md-12 col-xs-12 mt-10">
                            <div className="inventory-overHead">
                                <p>Campaign name</p>
                                <h3>Nike Product Launch <span className="table-status orangebg">In-Review</span></h3>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-12 col-xs-12 mt-30">
                            <div className="schoolFilter campaignDetailsbtn mb-0 fR">
                                    <a href="javascript:void(0)" className="btn btn-outline">reject</a>
                                    <a href="javascript:void(0)" className="btn btn-orange">APPROVE</a>
                                
                            </div>
                        </div>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-20 mb-20"><div className="line-full"></div></div>
                        
                        <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 pl pr">
                            <div className="row">
                                <div className="col-xl-9 col-lg-9 col-md-12 col-xs-12 mb-10 pl">
                                    <div className="row">
                                        <div className="col-xl-4 col-lg-4 col-md-12 col-xs-12 mb-10">
                                            <div className="inventory-overHead">
                                                <p>Campaign Creatives</p>
                                            </div>
                                            <div className="campaignRequestSlide campaignDetailSlide">
                                                <div className="campaignRequestSlideLarge">
                                                    <img src={imgViewTwo} />
                                                </div>
                                                <div className="campaignRequestSlideSmall">
                                                    <img src={imgViewTwo} />
                                                    <img src={imgViewTwo} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-8 col-lg-8 col-md-12 col-xs-12 mb-10">
                                            <div className="row">
                                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-20">
                                                    <div className="inventory-overHead">
                                                        <p>Campaign Description</p>
                                                        <h3>The campaign is scheduled to launch the new Nike sport shoes for 2021. Activities during P.E. classes to be conducted by Nike Sports Professionals. Activities like Cricket, Football, Basketball to be conducted by Nike Professionals to train the school students.</h3>
                                                    </div>
                                                </div>

                                                <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12 mb-20">
                                                    <div className="inventory-overHead">
                                                        <p>Budget</p>
                                                        <h3>₹1,50,000</h3>
                                                    </div>
                                                </div>

                                                <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12 mb-20">
                                                    <div className="inventory-overHead">
                                                        <p>School</p>
                                                        <h3>24 <a href="" className="viewwLink fN">View LIST <img className="arrowView" src={riarrownext}></img></a></h3>
                                                    </div>
                                                </div>

                                                <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12 mb-20">
                                                    <div className="inventory-overHead">
                                                        <p>Schedule</p>
                                                        <h3>20 Jan’2021 - 10 Feb’2021</h3>
                                                    </div>
                                                </div>

                                                <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12 mb-20">
                                                    <div className="inventory-overHead">
                                                        <p>Inventories Occupied</p>
                                                        <h3>8 <a href="" className="viewwLink fN">View LIST <img className="arrowView" src={riarrownext}></img></a></h3>
                                                   
                                                    </div>
                                                </div>

                                                <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12 mb-10">
                                                    <div className="inventory-overHead">
                                                        <p>Payment Status</p>
                                                        {context.user.role === "backendSales" || context.user.role === "backendAdmin" ?
                                                            <SingleSelect
                                                                formData={paymentStatus}
                                                                formKey="value"
                                                                onDataChange={onPaymentStatusChange}
                                                                isRequired={false}
                                                                options={paymentStatusOption}
                                                            />
                                                            :
                                                            <h3>{paymentStatus.value === "paid" ? "Paid" : "Pending"}</h3>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12 mb-20">
                                                    <div className="inventory-overHead">
                                                        <p>Campaign Reporting</p>
                                                        <h3><a href="" className="viewwLink fN">+ Assign FOS </a></h3>
                                                   
                                                    </div>
                                                </div>
                                            </div>
                                             
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-3 col-md-12 col-xs-12 mb-10 pr">
                                    <div className="box-design">
                                    <h2 className="htx3 clearfix mb-20">Campaign Location</h2>
                                        {isScriptLoaded && isScriptLoadSucceed &&
                                            <MultiPointMap
                                                pincodeList={pincodeList}
                                            />
                                        }
                                    <a href="" className="viewwLink fN">View campaign creation <img className="arrowView" src={riarrownext}></img></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
            </div>
            <InventoryMore
                showModalTwoMore={showInventoryModal}
                inventoriesList={inventoryList}
                closeWithoutSaving={closeInventoryModal}
            />
        </>
    )
}

// export default CampaignDetails
export default scriptLoader(
    [
        'https://maps.googleapis.com/maps/api/js?key=' + config.GOOGLE_MAPS_API_KEY
    ],
)(CampaignDetails)
