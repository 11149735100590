import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import AlertDialog from '../../../components/addon/alertDialog/alertDialog'

import loginWave from "../../../assets/images/login-wave.svg"
import checkSuccess from "../../../assets/images/check.svg"

import envelope from "../../../assets/images/envolpe.svg"
import logo from "../../../assets/images/logo.png"
import AppContextObject from "../../../common/context/common"
import Textfield from "../../../components/formFields/textField/textField"
import SimpleReactValidator from "simple-react-validator"
import errorSVG from "../../../assets/images/error.svg";
import { objectDeepClone, validateFullName } from "../../../service/helper"
import SingleSelect from "../../../components/formFields/singleSelect/singleSelect"
import config from "../../../service/config"
import { PublicContextInitiator } from "../../apis/publicContextInitiator"
import DataContext from "../../../service/brandContext"
import loginBgLeft from "../../../assets/images/backendAdmin/login-bg.svg"

const PasswordSuccess = () => {
    let history = useHistory()
    const [overlay, setOverlay] = useState(false)
    const [toggleAlertPopUp, setToggleAlertPopUp] = useState(false)

    return (
        <>
            <div className={`ovelap ${(overlay === true) ? "active" : ""}`}></div>
            <div className="main-wapper">
                <div className="loginbox">
                <div className="login-wave backendlogin"> <img src={loginBgLeft} alt="login-wave" /> </div>
                    <div className="login-form">
                        {/* <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb10">
                                <img src={logo} alt="logo" />
                                <h2 className="htx1"><img className="checkSuccess" src={checkSuccess} alt="check Success" /> Success!</h2>
                                <p className="ptx mb-20">Your password for <strong>mohit@netbramha.com</strong> has been updated successfully.</p>
                            </div>
                        </div> */}
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb10">
                                <img src={logo} alt="logo" />
                                <h2 className="htx1"><img className="checkSuccess" src={checkSuccess} alt="check Success" /> Voila!</h2>
                                <p className="ptx mb-20">Your password has been changed successfully.</p>
                            </div>
                        </div>
                        <form data-form-validation="login-form" id="login-form-parent">
                            <div className="row">
                                <div className="row col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                    <div className="forminpt">
                                        <input onClick={() => history.replace("/login")} value="Log IN" className="btn btn-orange datasubmit" type="button" />
                                    </div>
                                </div>
                                
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {/* <AlertDialog
                togglePopup={toggleAlertPopUp}
                fieldInfo={getFieldInfo}
                details={detailsCaptured}
                onModalClose={confirmClose}
                displayMsg={msgDetails}
            /> */}
        </>
    )
}

export default PasswordSuccess
