import { AppContext } from "../../common/context/common";
import { Validator } from '../../common/utils/validator.js';
import { Utilities } from '../../common/utils/utilities';

export class BrandManagerContextInitiator {
    utils: Utilities;
    constructor(private appContext: AppContext){ this.utils = new Utilities(); }
    async getRecomendedEventsByEvent (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'eventName', args, ()=>{return true;}, false);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('recomendedEvents->getRecomendedEventsByEvent', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getApprovedImagesFromCampaignReportAlbum (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
        this.utils.addIfFound(validator, argsData, 'groupBy', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'searchValue', args, ()=>{return true;}, false);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('campaignReportAlbum->getApprovedImagesFromCampaignReportAlbum', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getCampaignReportingAlbumsByCampaign (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'campaignId', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('campaignReportAlbum->getCampaignReportingAlbumsByCampaign', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getLoggedInUser (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
    
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('user->getLoggedInUser', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async listFAQ (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'searchValue', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'category', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'type', args, ()=>{return true;}, false);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('faqs->listFAQ', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getWisrBlogs (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'type', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('wisrBlogs->getWisrBlogs', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getAllCampaignSchools (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'searchValue', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'campaignId', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'city', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'state', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'language', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'schoolType', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'board', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'schoolStatus', args, ()=>{return true;}, false);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('school->getAllCampaignSchools', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getAllUserNotification (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'role', args, ()=>{return true;}, false);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('userNotification->getAllUserNotification', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getUserNotifications (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'role', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'timestamp', args, ()=>{return true;}, false);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('userNotification->getUserNotifications', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getAllUserLocation (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'ipAddress', args, ()=>{return true;}, false);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('userLocation->getAllUserLocation', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async oldPasswordSendOtpEmailPhone (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'inputPassword', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'phone', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'email', args, ()=>{return true;}, false);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('user->oldPasswordSendOtpEmailPhone', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getUsersToolTipProgress (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
    
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('user->getUsersToolTipProgress', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getBrandSetupByUserId (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
    
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('user->getBrandSetupByUserId', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getCustomInventoriesFromAllSchools (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'searchValue', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'status', args, ()=>{return true;}, false);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('inventories->getCustomInventoriesFromAllSchools', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getBrandCategoryRecommendation (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'brandCategoryId', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'searchValue', args, ()=>{return true;}, false);
    
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('brandCategoryRecommendation->getBrandCategoryRecommendation', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getAllSubCategoriesByParentBrandId (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'parentBrandId', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('brandSubCategory->getAllSubCategoriesByParentBrandId', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getRecommendedDataByCampaignData (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'searchValue', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'city', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'brandCategoryId', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'schoolType', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'language', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'board', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'data', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'startDate', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'endDate', args, ()=>{return true;}, false);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('school->getRecommendedDataByCampaignData', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getSchoolsForCustomCampaign (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'searchValue', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'city', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'brandCategoryId', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'schoolType', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'language', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'board', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'data', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'startDate', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'endDate', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'selectedSchools', args, ()=>{return true;}, false);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('campaign->getSchoolsForCustomCampaign', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getRecommendedDataByCampaignDataV2 (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'searchValue', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'city', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'brandCategoryId', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'schoolType', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'language', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'board', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'data', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'startDate', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'endDate', args, ()=>{return true;}, false);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('schoolDataFlattened->getRecommendedDataByCampaignDataV2', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async createUserNotification (validator: Validator, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let newData = {};
    
        this.utils.addIfFound(validator, newData, 'message', data, ()=>{return true;}, true);
        this.utils.addIfFound(validator, newData, 'meta', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'role', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'seen', data, ()=>{return true;}, true);
        this.utils.addIfFound(validator, newData, 'notificationType', data, ()=>{return true;}, true);
        this.utils.addIfFound(validator, newData, 'user', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('userNotification->createUserNotification', {data: newData});
        } else {
            return null;
        }
    }
    async readAllUserNotification (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
    
        this.utils.addIfFound(validator, newData, 'message', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'meta', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'role', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'seen', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'notificationType', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'school', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'timestamp', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'user', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('userNotification->readAllUserNotification', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async unreadUserNotification (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, newData, 'message', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'meta', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'role', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'seen', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'notificationType', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'school', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'brand', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'timestamp', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'user', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('userNotification->unreadUserNotification', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async removeUserNotification (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, newData, 'message', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'meta', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'role', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'seen', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'notificationType', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'school', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'brand', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'timestamp', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'user', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('userNotification->removeUserNotification', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async muteUserNotification (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, newData, 'seen', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'user', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('userNotification->muteUserNotification', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async updateReadStateOfUserNotification (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, newData, 'seen', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'user', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('userNotification->updateReadStateOfUserNotification', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async saveUserQuery (validator: Validator, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let newData = {};
    
        this.utils.addIfFound(validator, newData, 'userType', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'query', data, ()=>{return true;}, true);
        this.utils.addIfFoundInArray(validator, newData, 'thread', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('userQueries->saveUserQuery', {data: newData});
        } else {
            return null;
        }
    }
    async addUserLocation (validator: Validator, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let newData = {};
    
        this.utils.addIfFound(validator, newData, 'city', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'country', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'timestamp', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'operatingSystem', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'ipAddress', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'userId', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'school', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'brand', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('userLocation->addUserLocation', {data: newData});
        } else {
            return null;
        }
    }
    async deleteUserLocation (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
    
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('userLocation->deleteUserLocation', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async updateUserDetails (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
    
        this.utils.addIfFound(validator, newData, 'fullName', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'email', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'phone', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'role', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'username', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'isVerified', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('user->updateUserDetails', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async changeUserPassword (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
    
        this.utils.addIfFound(validator, newData, 'password', data, ()=>{return true;}, false);
        this.utils.addIfFoundInArray(validator, newData, 'oldPasswords', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'isVerified', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('user->changeUserPassword', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async updateNotificationSettings (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
    
        this.utils.addIfFound(validator, newData, 'emailNotification', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'webPushNotification', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'smsNotification', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'allNotifications', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'webToken', data, ()=>{return true;}, false);
        this.utils.addIfFoundInArray(validator, newData, 'mutedNotificationCategories', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('user->updateNotificationSettings', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async updateUsersToolTipProgress (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
    
        this.utils.addIfFound(validator, newData, 'toolTipProgress', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('user->updateUsersToolTipProgress', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async addBrandUser (validator: Validator, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let newData = {};
    
        this.utils.addIfFound(validator, newData, 'fullName', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'email', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'city', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'country', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'state', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'pincode', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'role', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'isVerified', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'phone', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'username', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('user->addBrandUser', {data: newData});
        } else {
            return null;
        }
    }
    async updateBrandUser (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, newData, 'fullName', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'email', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'city', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'country', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'state', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'pincode', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'role', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'isVerified', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'phone', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'designation', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('user->updateBrandUser', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async archiveBrandUser (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
    
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('user->archiveBrandUser', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async updateBrand (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, newData, 'logo', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'brandName', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'brandType', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'website', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'documents', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'address', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'contactNumber', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'country', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'city', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'state', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'zip', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'description', data, ()=>{return true;}, false);
        this.utils.addIfFoundInArray(validator, newData, 'attributes', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'socialMediaLinks', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'wisrRepresentative', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('brand->updateBrand', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async updateBrandSubCategoryById (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, newData, 'name', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'brandName', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('brandSubCategory->updateBrandSubCategoryById', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async addOrUpdateBrandSubcategory (validator: Validator, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let newData = {};
    
        this.utils.addIfFound(validator, newData, 'name', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'brandId', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'brandName', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'parentBrandId', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'userId', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('brandSubCategory->addOrUpdateBrandSubcategory', {data: newData});
        } else {
            return null;
        }
    }
    async oldPasswordEmailPhoneOTPVerification (validator: Validator, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('user->oldPasswordEmailPhoneOTPVerification', {data: data});
        } else {
            return null;
        }
    }
    async checkSelectedInventories (validator: Validator, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('inventories->checkSelectedInventories', {data: data});
        } else {
            return null;
        }
    }
    async updateSpecificBrandDetails (validator: Validator, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('brand->updateSpecificBrandDetails', {data: data});
        } else {
            return null;
        }
    }
    async addCampaign (validator: Validator, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('campaign->addCampaign', {data: data});
        } else {
            return null;
        }
    }
    async editCampaign (validator: Validator, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('campaign->editCampaign', {data: data});
        } else {
            return null;
        }
    }
    async manageBrandSubCategory (validator: Validator, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('brandSubCategory->manageBrandSubCategory', {data: data});
        } else {
            return null;
        }
    }
}
