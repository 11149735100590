import {
    useEffect,
    useState,
    useContext
} from "react"

import { formatDateToDDMMMYY, objectDeepClone } from "../../service/helper"

import riarrownext from "../../assets/images/right-arrow-next.svg"

const BrandRequestDetailModal = (props) => {

    const {
        showBrandRequestDetailModal,
        campaignData,
        openApprovalModal,
        openRejectModal,
        closedetailsModal,
        overlay,
        fromInventoryPage
    } = props

    const [currentImage, setCurrentImage] = useState(0)
    const [showMoreDesc, setShowMoreDesc] = useState(false)
    const [descLength] = useState(60)
    const [inventoryLength] = useState(1)
    const [showMoreInventory, setShowMoreInventory] = useState(false)

    const onPrev = () => {
        if (currentImage > 0) {
            setCurrentImage(currentImage - 1)
        }
    }

    useEffect(() => {
        if (showBrandRequestDetailModal === false) {
            setCurrentImage(0)
            setShowMoreInventory(false)
            setShowMoreDesc(false)
        }
    }, [showBrandRequestDetailModal])
    
    const onNext = () => {
        // if(campaignData.campaignCreativesRevised && campaignData.campaignCreativesRevised.length > 0){
        //     if (currentImage < campaignData.campaignCreativesRevised.length - 1) {
        //         setCurrentImage(currentImage + 1)
        //     }
        // }
        // else{
            if (currentImage < campaignData.campaignCreatives.length - 1) {
                setCurrentImage(currentImage + 1)
            }
        // }
    }

    return (
        <>
            <div id="brandViewDetail" className={`popup ${showBrandRequestDetailModal === false ? "hide" : "" } ${fromInventoryPage && "fromInventory"}`}>
                <div className={`ovelap ${overlay === true ? "active" : ""}`}></div>
                <div className="popup-header">
                    <h3 className="htx2 pophead">Campaign Details</h3>
                    <span className="close toggle" onClick={closedetailsModal} data-target="myPopup">close</span>
                </div>
                <div className="popup-body">
                    <div className="viewdetailsHead">
                        <div className="viewdetailsMain">
                            <div className="viewdetailsLeft">
                                <div className="brand-creat-img">
                                    <h3>Name</h3>
                                    <h5>{campaignData?.brandName}</h5>
                                </div>
                                {
                                    (campaignData.startDate && campaignData.endDate) &&
                                    <div className="brand-creat-img">
                                        <h3>Schedule</h3>
                                        <div className="starend">
                                            <p>Start <b>{formatDateToDDMMMYY(campaignData.startDate)}</b></p>
                                            <img className="next-arow" src={riarrownext} />
                                            <p>End <b>{formatDateToDDMMMYY(campaignData.endDate)}</b></p>
                                        </div>
                                    </div>
                                }
                            </div>
                            {
                                // (campaignData.campaignCreativesRevised && campaignData.campaignCreativesRevised.length > 0) ?
                                // <div className="viewdetailsRight">
                                //     <div className="viewSlide"><img src={campaignData.campaignCreativesRevised[currentImage]?.image}/></div>
                                //     <div className="viewScroll">
                                //         <a onClick={onPrev} href="javascript:void(0);" className="brand-pagi-arrow"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z"></path></svg></a>
                                //         <span>({currentImage + 1}/{campaignData.campaignCreativesRevised.length})</span>
                                //         <a onClick={onNext} href="javascript:void(0);" className="brand-pagi-arrow"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z"></path></svg></a>
                                //     </div>
                                // </div>
                                // :
                                (campaignData.campaignCreatives && campaignData.campaignCreatives.length > 0) &&
                                <div className="viewdetailsRight">
                                    <div className="viewSlide"><img src={campaignData.campaignCreatives[currentImage]?.image}/></div>
                                    <div className="viewScroll">
                                        <a onClick={onPrev} href="javascript:void(0);" className="brand-pagi-arrow"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z"></path></svg></a>
                                        <span>({currentImage + 1}/{campaignData.campaignCreatives.length})</span>
                                        <a onClick={onNext} href="javascript:void(0);" className="brand-pagi-arrow"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z"></path></svg></a>
                                    </div>
                                </div>
                            }
                        </div>
                        {
                            campaignData.description &&
                            <div className="brand-creat-img">
                                <h3>Description</h3>
                                <p>
                                    {
                                        showMoreDesc == false ?
                                        <>{campaignData.description.substring(0, descLength)}</>
                                        :
                                        <>{campaignData.description}</>
                                    }
                                    {
                                        campaignData.description.length > descLength &&
                                        <a
                                            onClick={() => setShowMoreDesc(!showMoreDesc)}
                                            href="javascript:void(0);"
                                            className="viewwLink"
                                        >Read {`${!showMoreDesc ? 'More' : 'Less'}`}</a>
                                    }
                                </p>
                            </div>
                        }
                        {
                            (campaignData?.inventories && (campaignData.inventories.length + campaignData.activities.length) > 0) &&
                            <div className="brand-creat-img">
                                <h3>Booked Inventory / Events</h3>
                                <p>
                                    {
                                        showMoreInventory == false ?
                                        <>{objectDeepClone([...campaignData.inventories, ...campaignData.activities].slice(0, inventoryLength).map(inventory => inventory.inventory ? inventory.inventory.name : inventory.name ? inventory.name : inventory.activity.name).join(", "))}</>
                                        :
                                        <>{[...campaignData.inventories, ...campaignData.activities].map(inventory => inventory.inventory ? inventory.inventory.name : inventory.name ? inventory.name : inventory.activity.name).join(", ")}</>
                                    }
                                    {
                                        (campaignData.inventories.length + campaignData.activities.length) > inventoryLength &&
                                        <a onClick={() => setShowMoreInventory(!showMoreInventory)} href="javascript:void(0);" className="viewwLink">
                                            {!showMoreInventory ? ` & ${(campaignData.inventories.length + campaignData.activities.length) - inventoryLength} More` : 'Show Less'}
                                        </a>
                                    }
                                </p>
                            </div>
                        }
                        <div className="brand-btn">
                            <a onClick={openRejectModal} href="javascript:void(0);" className="btn btn-outline toggle" data-target="brandViewDetail">Reject</a>
                            <a onClick={openApprovalModal} href="javascript:void(0);" className="btn btn-orange">Approve</a>
                        </div>
                    </div>
                </div>
            </div>        
        </>
    )
}

export default BrandRequestDetailModal
