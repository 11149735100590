import React, { useState, useEffect, useRef, useContext} from 'react'
import uploadDocument from "../../../../assets/images/img-upload.jpg"
import errorSVG from "../../../../assets/images/error.svg"
import deleteImg from "../../../../assets/images/delete.svg"

import SimpleReactValidator from "simple-react-validator"
import ImageModal from '../../../../components/addon/imageModal/imageModal'
import TextField from '../../../../components/formFields/textField/textField'
import Compress from "compress.js"

import toast from "react-hot-toast"
import { PublicContextInitiator } from '../../../apis/publicContextInitiator'
import AppContextObject from '../../../../common/context/common'
import TextArea from '../../../../components/formFields/textArea'
import ConfirmDialog from '../../../../components/addon/confirmDialog/confirmDialog'
import saveInput from "../../../../assets/images/save.svg"
import proEdit from "../../../../assets/images/edit-profile.svg"
import trashCan from "../../../../assets/images/trash-can.svg"
import SingleSelect from '../../../../components/formFields/singleSelect/singleSelect'
import editSvg from "../../../../assets/images/edit-btn.svg"
import { compareObjs } from '../../../../service/helper'
import { eraseAllCookies, eraseCookie } from "../../../../service/helper"

const EditMyBrandDetails = (props) => {
    const {
        subCategoryList,
        setSubCategoryList,
        myBrandDetails,
        setMyBrandDetails,
        setOverlay,
        refetch,
        setRefetch,
        profileData,
        allSubCategoryList,
        brandNameList,
        setBrandNameList
    } = props
    const context = useContext(AppContextObject)
    const [formValidator] = useState(useRef(new SimpleReactValidator({
        element: (message, className) =>
            <p className="valError"><img src={errorSVG} />{message}</p>
    })))
    const [editingData, setEditingData] = useState(false)
    const [formData, setFormData] = useState(myBrandDetails)
    const [loading, setLoading] = useState(false)
    const [update, forceUpdate] = useState(false)
    const [editIndex, setEditIndex] = useState(null)
    const [isSave, setIsSave] = useState(false)
    const [oldsubCategoryName, setOldSubCategoryName] = useState(null)
    const [toggleDeleteModal, setToggleDeleteModal] = useState(false)
    const [isNewAdd, setIsNewAdd] = useState(false)
    const [oldFormData, setOldFormData] = useState(myBrandDetails)
    const [subCategoryOptions, setSubCategoryOptions] = useState([])
    const [brandNameOptions, setBrandNameOptions] = useState([])
    const [editingBrandDetails, setEditingBrandDetails] = useState(false)
    const [brandDetail, setBrandDetail] = useState({brandName: "", brandId: ""})
    const [toggleSave, setToggleSave] = useState(false)

    useEffect(() => {
        let subCat = subCategoryList.find(item => item.name === myBrandDetails.subCategory)
        if(subCat){
            let brand = subCat.brandName.find(item => item.brandName === myBrandDetails.brandName)
            setBrandDetail({...brand})
        }
    }, [])
    useEffect(() => {
        let options = []
        if(subCategoryList.length > 0 ){
            for(let cat of subCategoryList){
                options.push({
                    label: cat.name,
                    value: cat.name
                })
            }
        }
        setSubCategoryOptions(options)
    }, [])
    useEffect(() => {
        let options = []
        // if(brandNameList.length > 0 ){
        //     for(let brand of brandNameList){
        //         options.push({
        //             label: brand.value,
        //             value: brand.value
        //         })
        //     }
        // }
        let list = subCategoryList.find(item => item.name === formData.subCategory)
        if(list){
            list.brandName && Array.isArray(list.brandName) && list.brandName.forEach(item => {
                options.push({
                    _id: item.brandId,
                    label: item.brandName,
                    value: item.brandName
                })
            })
        }
        setBrandNameOptions(options)
    }, [formData.subCategory])
    console.log("subCategoryList>>>>>",subCategoryList);
    const onFormDataChange = (event) => {
        setFormData({
            ...formData,
            brandName: event.target.value
        })
    }

    console.log("allSubCategoryList>>>>",allSubCategoryList);

    console.log("isSame===", compareObjs(formData, oldFormData));

    const handleSave = async (confirm) => {
        if(confirm){
            const result = formValidator.current.allValid()

            console.log("result=====",result);
            if (result === false) {
                formValidator.current.showMessages()
                // forceUpdate(true)
                forceUpdate(true)
                return
            } else {
                formValidator.current.hideMessages()
                forceUpdate(!update)
            }

            let isSame = compareObjs(formData, oldFormData)
            console.log("brandDetail.brandName === formData.brandName", brandDetail.brandName === formData.brandName)

            if(brandDetail.brandName !== formData.brandName){
                setEditingBrandDetails(true)
                let data = {
                    brandName: brandDetail.brandName,
                    brandId: brandDetail.brandId,
                    name: formData.subCategory,
                    userId: context.user._id,
                    parentBrandId: context.user.parentBrand || context.user.brand
                }
                console.log("data-------------", data)
                let resp = await context.apis[context.user.role].addOrUpdateBrandSubcategory(
                    context.validator, data
                )
                console.log("addOrUpdateBrandSubcategory", resp)
                if(resp && resp.done){
                    logoutUser()
                    // context.user.brandName = brandDetail.brandName
                    // context.user.brandCategoryName = formData.subCategory
                    // formValidator.current.purgeFields()
                    // setEditingBrandDetails(false)
                }
                // let data = {
                //     name: formData.subCategory,
                //     brandName: formData.brandName
                // }
                // let resp = await context.apis[context.user.role].updateBrandSubCategoryById(
                //     context.validator, {_id: formData._id}, data
                // let userData = {users:[]}
                // userData.users.push({
                //     brandName: formData.brandName,
                //     subCategoryName: formData.subCategory,
                //     _id: context?.user?._id,
                //     role: context?.user?.role,
                //     brand: context?.user?.brand,
                //     fullName: context?.user?.fullName,
                //     email: context?.user?.email,
                //     username: context?.user?.username
                // })
                
                // let data = {
                //     ...userData, 
                //     subCategories: formData.subCategory, 
                //     brandName: formData.brandName, 
                //     brandId: context?.user?.parentBrand || context?.user?.brand,
                //     doNotUpdate: true,
                //     userBrandDetailsUpdate: true,
                //     archiveBrandSubCategoryId: context.user.brandCategoryId
                // }
                // let resp = await context.apis.public.updateBrandWithTeamMember(
                //     context.validator, data
                // )

                // console.log("updateBrandSubCategoryById resp>>>>",resp);
                // if(resp && resp.done){
                //     if(resp.response.brandSubCategoryId){
                //         context.user.brandCategoryId = resp.response.brandSubCategoryId
                //     }
                //     context.user.brandName = formData.brandName
                //     context.user.brandCategoryName = formData.subCategory
                //     formValidator.current.purgeFields()
                //     setEditingBrandDetails(false)
                // }
                // setRefetch(!refetch)
            }

            setEditingData(false)
        }
        setToggleSave(false)
        setOverlay(false)
    }

    // My Details Functions below

    const onSubcategoryChange = (data) => {
        let item = subCategoryList.find(item => item.name === data.value)
        console.log("itemSub", item)
        if(item && item.brandName.length > 0){
            setBrandDetail({brandName: item.brandName[0].brandName, brandId: item.brandName[0].brandId})
        }
        setFormData({
            ...formData,
            subCategory: data ? data.value : "",
        })
    }

    const onBrandNameChange = (data) => {
        // setFormData({
        //     ...formData,
        //     brandName: data ? data.value : ""
        // })
        setBrandDetail({brandName: data.value, brandId: data._id})
    }

    formValidator.current.rules['duplicate'] = {
        message: 'this sub category and brand is already assigned to other brand manager',
        rule: (val) => {
            console.log("working ",val);
            let returnValue
            let isSame = compareObjs(formData, oldFormData)
            if(isSame === false){
                let foundDuplicate = allSubCategoryList && allSubCategoryList.find(el => 
                    el.name.toLowerCase().trim() === formData.subCategory.toLowerCase().trim()
                    &&
                    el.brandName !== null
                    &&
                    el.brandName.toLowerCase().trim() === formData.brandName.toLowerCase().trim()
                )
                if(foundDuplicate){
                    returnValue = false
                }else{
                    returnValue = true
                }
            }else {
                returnValue = true
            }
            
            return returnValue
        },
    }

    const handleCancel = () => {
        setEditingData(false)
        // setFormData({...myBrandDetails})
        formValidator.current.purgeFields()
        setFormData({...oldFormData})
        let subCat = subCategoryList.find(item => item.name === myBrandDetails.subCategory)
        if(subCat){
            let brand = subCat.brandName.find(item => item.brandName === myBrandDetails.brandName)
            setBrandDetail({...brand})
        }
    }

    const openConfirmationModal = () => {
        setOverlay(true)
        setToggleSave(true)
    }

    const logoutUser = () =>{
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
        };
        fetch("/api/logout", requestOptions)
        .then(response => response.json())
        .then(data => {
            console.log("data>>>>>",data);
            if(data.done){
                // setToggleLogoutDialog(false)
                setOverlay(false)
                context.inactive = true
                deleteUserLocation()
                localStorage.setItem("prevUserId", context.user._id)
                eraseAllCookies();
                eraseCookie("session-wisr-core-microservice-backend")
                context.socketManager.socket.disconnect();
                setOverlay(false)
                document.location.href = "/login"
                sessionStorage.clear()
            }
        }).catch(err=>{
            console.log(err);
        }).catch(err=>{
            console.log(err);
        })
    }
    const deleteUserLocation = async () =>{
        let resp = await context.apis[context.user.role].deleteUserLocation(
            context.validator, {_id: sessionStorage.getItem('locationActivityId')}
        )
    }

    return (
        <>
            {/* <div className={`ovelap ${overlay === true ? "active" : ""}`}></div> */}
            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                <div className="row">
                    <div className="col-xl-4 col-lg-4 col-md-12 col-xs-12">
                        <div className="profile-general">
                            <h2 className="htx3 clearfix">My Brand Details 
                            {!editingData && <a href="javascript:void(0)" className="editbtn" onClick={() => setEditingData(true)}><img src={editSvg} /> Edit</a>}
                            </h2>
                            <p className="ptx clearfix">Tell us more about your brand.</p>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12 brand-general">
                        
                        
                        {editingData ?
                            <>
                            {/* Edit Mode  */}
                                <div className="inventory-overHead mt-0">
                                    <p>Sub Category</p>
                                        <SingleSelect
                                            formData={formData}
                                            formKey='subCategory'
                                            onDataChange={onSubcategoryChange}
                                            // label='Sub Category'
                                            placeholder='Select Sub Category'
                                            isRequired={false}
                                            options={subCategoryOptions}
                                            formValidator={formValidator}
                                            validation='required'
                                            // isDisable={designationDisable}
                                            helpText="Select subcategory for your brand from the dropdown."
                                        />
                                    <div className="clearfix mt-30"></div>
                                    <p>Brand Name</p>
                                        {/* <TextField
                                            formData={formData}
                                            formKey='brandName'
                                            onDataChange={onFormDataChange}
                                            // label='Brand Name'
                                            placeholder='Enter Brand Name'
                                            isRequired={false}
                                            formValidator={formValidator}
                                            validation='required|duplicate'
                                            helpText="Enter your brand name here."
                                        /> */}
                                        <SingleSelect
                                            formData={brandDetail}
                                            formKey='brandName'
                                            onDataChange={onBrandNameChange}
                                            // label='Brand Name'
                                            placeholder='Select Brand Name'
                                            isRequired={false}
                                            options={brandNameOptions}
                                            formValidator={formValidator}
                                            validation='required'
                                            // isDisable={designationDisable}
                                            helpText="Select brand from the dropdown."
                                        />
                                </div>
                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-20">
                                    <div className="btn-general">
                                        <a style={{cursor: "pointer"}} className="btn btn-outline" onClick={()=> handleCancel()}>Cancel</a>
                                        <a style={{cursor: "pointer"}} className={`btn btn-orange ${editingBrandDetails && "button--loading btn-disabled"}`} onClick={openConfirmationModal}>Save Changes</a>
                                    </div>
                                </div>
                            </>
                            :
                            <>
                            {/* Read Mode */}
                                <div className="inventory-overHead mt-0">
                                    <p>Sub Category</p>
                                    <h3>
                                        {myBrandDetails.subCategory || "-"}
                                    </h3>
                                </div>
                                <div className="clearfix mt-30"></div>
                                <div className="inventory-overHead mt-0">
                                    <p>Brand Name</p>
                                    <h3>
                                        {myBrandDetails.brandName || "-"}
                                    </h3>
                                </div>
                            </>
                        }
                    </div>
                </div>
                
            </div>
            {/* <ConfirmDialog
                togglePopup={toggleDeleteModal}
                onModalClose={deleteConfirmClose}
                // componentName={updatePasswordComponent}
                confirmTitle="Are you sure you want to delete sub category?"
                confirmText="Delete"
            /> */}
            <ConfirmDialog
                confirmTitle="Change brand detail"
                componentName="Are you sure you want to change your brand name. If yes, click on confirm and you will be logged out from the system to proceed with new brand details."
                confirmText="Confirm"
                denyText="Cancel"
                togglePopup={toggleSave}
                onModalClose={handleSave}
            />
        </>
    )
}

export default EditMyBrandDetails
