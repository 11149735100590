import { useState, useContext, useEffect, useRef } from "react"
import SimpleReactValidator from "simple-react-validator";

import { useHistory } from "react-router-dom"
// import OnGoingCampaigns from "./onGoingCampaigns"
import Pagination from "../../../components/addon/pagination"
import arrowView from "../../../assets/images/arrow-view.svg"
import arrowViewRound from "../../../assets/images/arrow-view-round.svg"
import tableFilter from "../../../assets/images/table-filter.svg"
import TopBar from "../schools/components/topBar"
import AppContextObject from "../../../common/context/common"
import { formatDateShowMonth, returnReadableStatus } from "../../../service/helper"
import DataContext from "../../../service/brandContext"
// import BrandTopBar from "./components/brandTopBar"
// import { relative } from "path"
import useClickOutside from "../../../service/useClickOutside"
// import BrandBackBtn from "./components/brandBackBtn"
import SchoolList from "../backendAdmins/components/SchoolsList"
import BackendTopBar from "./components/backendTopBar"
// import CampaignStatusFilter from "../brands/components/campaignStatusFilter"
import CustomSearchInput from "../../../components/formFields/customSearchInput"
import CampaignStatusFilter from "./components/campaign/CampaignStatusFilter"
import SingleSelect from "../../../components/formFields/singleSelect/singleSelect"
import config from "../../../service/config"
import CustomDatePicker from "../../../components/formFields/datePicker"
import errorSVG from "../../../assets/images/error.svg";
import closeCustome from "../../../assets/images/close-1.svg";
import moment from "moment";
import downloadSvg from "../../../assets/images/backendAdmin/download-svg.svg";

import { downloadFile, numDifferentiation, titleCase } from '../../../service/helper';
const { detect } = require('detect-browser');
var campaignListGlobalVar = 1

const NonWisrCampaignList = (props) => {
    const {
        isEdit,
        methods
    } = props

    const history = useHistory()
    const context = useContext(AppContextObject)
    const parentData = useContext<any>(DataContext)
    const [totalPage, setTotalPage] = useState([0])
    const [currentPage, setCurrentpage] = useState(1)
    const [creativeNeededList, setCreativeNeededList] = useState([])
    const [selectedIndex, setSelectedIndex] = useState(0)
    const [openStatusFilter, setOpenStatusFilter] = useState(false);
    const [filterType, setFilterType] = useState("");
    const [filterStatus, setFilterStatus] = useState([]);
    const [sortingValue, setSortingValue] = useState(true)
    const [sortingData, setSortingData] = useState<any>({ _id: -1 })
    const [showSchoolTable, setShowSchoolTable] = useState(false)
    const [campaignId, setCampaignId] = useState("")
    const [loading, setLoading] = useState(false)
    const [searchInpuOpen, setSearchInpuOpen] = useState(false);
    const [clearSearchIcon, setClearSearchIcon] = useState(false);
    const [searchKeys, setSearchKeys] = useState("");
    const [formData, setFormData] = useState({ dateFilter: "", dateCreatedFilter: "" });
    const [formSheduledDataEmpty, setFormSheduledDataEmpty] = useState(false)
    const [formCreatedDataEmpty, setFormCreatedDataEmpty] = useState(false)
    const [customSheduledFilter, setCustomSheduledFilter] = useState(false);
    const [customCreatedFilter, setCustomCreatedFilter] = useState(false);
    const [scheduleSearch,setScheduleSearch]=useState(false);
    const [createSearch,setCreateSearch]=useState(false);
    const [isChrome, setIsChrome] = useState(true)
    const [flag, setFlag] = useState(false);
    const [formSheduledData, setFormSheduledData] = useState({
        startSheduledDate: "",
        endSheduledDate: ""
    })
    const [formSheduledDataDiffFormat, setFormSheduledDataDiffFormat] = useState({
        startSheduledDate: "",
        endSheduledDate: ""
    })
    const [formCreatedData, setFormCreatedData] = useState({
        startCreatedDate: "",
        endCreatedDate: ""
    })
    const [formCreatedDataDiffFormat, setFormCreatedDataDiffFormat] = useState({
        startCreatedDate: "",
        endCreatedDate: ""
    })
    const [createedFilterErr, setCreatedFilterErr] = useState({
        startDate: false,
        endDate: false
    })
    const [createedFilterErrMsg, setCreatedFilterErrMsg] = useState({
        startDateMsg: "",
        endDateMsg: ""
    })
    const [sheduledFilterErr, setSheduleddFilterErr] = useState({
        startDate: false,
        endDate: false
    })
    const [sheduledFilterErrMsg, setSheduledFilterErrMsg] = useState({
        startDateMsg: "",
        endDateMsg: ""
    })
    const [formValidator] = useState(useRef(new SimpleReactValidator({
        element: (message) =>

            <span className="valError"><img src={errorSVG} />{message}</span>
    })))
    const [totalCampaignDetails, setTotalCampaignDetails] = useState({
        creativeReworkNeededList: [],
        onGoingCampaigns: 0,
        totalCampaigns: 0,
        totalReach: 0
    })
    const onPageChange = (page) => {
        setCurrentpage(page)
    }

    const [downloadSchoolLoading, setDownloadSchoolLoading] = useState(false);
    const ref = useRef(null);
    useClickOutside(ref, () => { setOpenStatusFilter(false) })
    useEffect(() => {
        getOnGoingCampaignList()
    }, [currentPage, filterStatus, sortingValue, searchKeys, scheduleSearch, formData, flag, formSheduledData, formCreatedData])
    useEffect(() => {
        if (currentPage !== 1) {
            setCurrentpage(1)
        }
    }, [filterStatus, sortingValue, searchKeys, scheduleSearch, formData, flag, formSheduledData, formCreatedData])
    useEffect(() => {
        if (parentData) {
            parentData.setShowSidemenu(true)
        }
        const browser = detect();
        if (browser) {
            // console.log("browser...", browser.name)
            setIsChrome(browser.name === "chrome")
        }
    }, [])
    const getOnGoingCampaignList = async () => {
        setLoading(true)
        var localVar = Math.random()
        campaignListGlobalVar = localVar
        const resp = await context.apis[context.user.role].getAllNonWisrCampaigns(
            context.validator, {dateFilter: formData.dateFilter, dateCreatedFilter: formData.dateCreatedFilter, startDate: formSheduledData, createdDate: formCreatedData, campaignStatus: [...filterStatus], searchValue: searchKeys }, { limit: 10, page: currentPage, withCount: true, "sort": sortingData }
        )
        console.log("getAllNonWisrCampaigns", resp)
        //"6176aa8e30c9ed3b109f759d"
        if (resp && resp.done) {
            if (localVar === campaignListGlobalVar) {
                setLoading(false)
                console.log(resp)
                setCampaignData(resp.response.rows)
                let total = Math.floor(Number(resp.response.count) / 10 + (Number(resp.response.count) % 10 > 0 ? 1 : 0))
                let arr = []
                for (let i = 0; i < total; i++) {
                    arr.push(i)
                }
                console.log(total)
                setTotalPage([...arr])
            }
            else {
                setLoading(false)
            }
        }
    }
    const [campaignData, setCampaignData] = useState([])
    const onPrev = () => {
        if (totalCampaignDetails.creativeReworkNeededList && selectedIndex > 0) {
            setSelectedIndex(selectedIndex - 1)
        }
    }
    const onCategoryChange = (data) => {        
        setCurrentpage(1);
        setCreateSearch(false);
        setCustomSheduledFilter(false);
        setFormData({ ...formData, dateFilter: data.value });
        if (data.value === 'custom') {
            setCustomSheduledFilter(true);
        }
    }
    const onCategoryChange2 = (data) => {
        setScheduleSearch(false)
        setCustomCreatedFilter(false);
        setFormData({ ...formData, dateCreatedFilter: data.value });
        if (data.value === 'custom') {
            setCustomCreatedFilter(true);
        }
    }
    const onScheduleDateChange = (event, id) => {
        if (isChrome) {
            setFormSheduledData({ ...formSheduledData, [event.target.id]: event.target.value });
            setFormSheduledDataDiffFormat({...formSheduledDataDiffFormat, [event.target.id] : event.target.value});
        }
        else {
            setFormSheduledData({ ...formSheduledData, [id]: moment(event).format("yyyy-MM-DD") });
            setFormSheduledDataDiffFormat({...formSheduledDataDiffFormat, [id] : event})
        }
    }
    const onCreatedDateChange = (event, id) => {
        if (isChrome) {
            setFormCreatedData({ ...formCreatedData, [event.target.id]: event.target.value });
            setFormCreatedDataDiffFormat({...formCreatedDataDiffFormat, [event.target.id]: event.target.value });
        }
        else {
            setFormCreatedData({ ...formCreatedData, [id]: moment(event).format("yyyy-MM-DD") });
            setFormCreatedDataDiffFormat({...formCreatedDataDiffFormat, [id] : event})
        }
    }
    const onNext = () => {
        if(totalCampaignDetails.creativeReworkNeededList && totalCampaignDetails.creativeReworkNeededList.length - 1 > selectedIndex){
            setSelectedIndex(selectedIndex + 1)
        }
    }
    const onNextClick = () => {
        setCurrentpage(currentPage + 1)
    }
    const onPrevClick = () => {
        setCurrentpage(currentPage - 1)
    }
    const sortByBudget = () => {
        setSortingData({"budget": sortingValue ? -1 : 1})
        setSortingValue(!sortingValue)
    }
    const openSchoolTable = (_id) => {
        setCampaignId(_id)
        setShowSchoolTable(true)
    }
    const closeSchoolTable = () => {
        setShowSchoolTable(false)
    }
    const calculateDraftPercentage = (data) => {
        return data.campaignCreationProgress && data.campaignCreationProgress.progress ? data.campaignCreationProgress.progress.campaignCreative === true ? "- 90%" : data.campaignCreationProgress.progress.recommendedSchool === true ? "- 80%" : data.campaignCreationProgress.progress.school === true ? "- 60%" : data.campaignCreationProgress.progress.event === true ? "- 60%" : data.campaignCreationProgress.progress.inventory === true ? "- 40%" : data.campaignCreationProgress.progress.brandDetails ? "- 20%" : "" : "";
    }
    const handleSearch = (e) => {
        if (e === "") {
            setSearchKeys("")
        }
        else {
            setSearchKeys(e.target.value)
        }
    }

    const getExportableData1 = async() => {
        setDownloadSchoolLoading(true)

        console.log("==getExportableData12=====1========>");
        
        let resp = await context.apis[context.user.role].getAllNonWisrCampaignDetailsForBackendUsers(
            context.validator, {
                //searchValue: searchKeys,
                //dateFilter: formData.dateFilter,
                //dateCreatedFilter: formData.dateCreatedFilter,
                //startDate: formSheduledData, 
                //createdDate: formCreatedData, 
                //status: ['in-review', 'rejected', 'accepted', 'executed', 'on-hold', 'ongoing', "scheduled"],
                // paymentStatus: paymentStatus,
                //tabStatus: tabStatus,
                //IsDownload: "true"
            },
                { "sort": { "_id": -1 } }
        )
        console.log("getExportableData12", resp);
    
        let data = resp;
        if (data) {
            let file = data.file;
            downloadFile(file, data.filename);
        }
        setDownloadSchoolLoading(false)
    }


    return (
        <>
            <div className={`ovelap`}></div>
            <div className="col-xl-10 col-lg-12 col-md-12 col-xs-12">
                <div className="rightPart noBack">
                    <div className="row">
                        <BackendTopBar />
                        <div style={{ display: 'flex', margin: '10px', justifyContent: 'space-between', width: '100%' }}>
                            <div>
                                <h2>Full List of Non-WISR Campaigns</h2>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            {(context.user.role === "backendAdmin" || context.user.role === "backendOperations" || context.user.role === "backendTech") && <a href='javascript:void(0)' className={`downloadSvg ${downloadSchoolLoading ? 'btn-disabled btn-opacity' : ''}`} style={{marginLeft: '10px',display:'none'}} onClick={getExportableData1} ><img src={downloadSvg} /></a>}
                                <CustomSearchInput
                                    searchInpuOpen={searchInpuOpen}
                                    setSearchInpuOpen={setSearchInpuOpen}
                                    clearSearchIcon={clearSearchIcon}
                                    setClearSearchIcon={setClearSearchIcon}
                                    searchKeys={searchKeys}
                                    handleChange={handleSearch}
                                    palaceholder="Campaign name"
                                />
                                <div className="forminpt-dateFilter customRelative" style={{marginLeft: "10px"}}>
                                    <SingleSelect
                                        formData={formData}
                                        formKey='dateFilter'
                                        onDataChange={onCategoryChange}
                                        placeholder='Schedule Date'
                                        isRequired={false}
                                        options={config.monthFilterSheduleDate}
                                        noScroll={true}
                                    />
                                    {formSheduledDataEmpty && <h1 className="valError">
                                        <img src={errorSVG} />
                                        {'Date required.'}
                                    </h1>
                                    }
                                    {customSheduledFilter && <div className={`filterDropDown tableFilter custom-w open`}>
                                        <div className="filterDropBody">
                                            <div className="filterDropHead">
                                                <a href="javascript:void(0);" className="viewwLink" onClick={() => setCustomSheduledFilter(false)} ><img src={closeCustome} /></a>
                                            </div>
                                            <div className="filterDropClose open">
                                                <div className="filtercheckbox">
                                                    <CustomDatePicker
                                                        isChrome={isChrome}
                                                        formData={isChrome ? formSheduledData : formSheduledDataDiffFormat}
                                                        formKey='startSheduledDate'
                                                        onDataChange={(e, v) => { onScheduleDateChange(e, v); setSheduleddFilterErr({ ...sheduledFilterErr, startDate: false }); setSheduledFilterErrMsg({ ...sheduledFilterErrMsg, startDateMsg: "" }) }}
                                                        isRequired={false}
                                                        formValidator={formValidator}
                                                        validation='required'
                                                        max={(formSheduledData.endSheduledDate && moment(formSheduledData.endSheduledDate).subtract(1, "days").clone().format("YYYY-MM-DD"))}
                                                    />
                                                    {sheduledFilterErr.startDate && <span className="valError"><img src={errorSVG} />{sheduledFilterErrMsg.startDateMsg}</span>}
                                                </div>
                                            </div>
                                            <div className="filterDropClose open">
                                                <div className="filtercheckbox">
                                                    <CustomDatePicker
                                                        isChrome={isChrome}
                                                        formData={isChrome ? formSheduledData : formSheduledDataDiffFormat}
                                                        formKey='endSheduledDate'
                                                        onDataChange={(e, v) => { onScheduleDateChange(e, v); setSheduleddFilterErr({ ...sheduledFilterErr, endDate: false }); setSheduledFilterErrMsg({ ...sheduledFilterErrMsg, endDateMsg: "" }) }}
                                                        isRequired={false}
                                                        formValidator={formValidator}
                                                        validation='required'
                                                        min={(formSheduledData.startSheduledDate && moment(formSheduledData.startSheduledDate).add(1, "days").clone().format("YYYY-MM-DD")) || moment().add(11, "days").clone().format("YYYY-MM-DD")}
                                                    />
                                                    {sheduledFilterErr.endDate && <span className="valError"><img src={errorSVG} />{sheduledFilterErrMsg.endDateMsg}</span>}
                                                </div>
                                            </div>
                                            <div className="filterDropHead-campaign">
                                                <a href="javascript:void(0);" className="viewwLink" onClick={() => { setFormSheduledData({ startSheduledDate: "", endSheduledDate: "" }); setFormSheduledDataDiffFormat({ startSheduledDate: "", endSheduledDate: "" }); setFlag(!flag); }} >Clear</a>
                                            </div>
                                            {formSheduledData.startSheduledDate !== '' && formSheduledData.endSheduledDate !== '' && <div className="filterDropHead-campaign">
                                                <a href="javascript:void(0);" className="viewwLink" onClick={() => setScheduleSearch(true)} >Search</a>
                                            </div>}


                                        </div>
                                    </div>}
                                </div>

                                <div className="forminpt-dateFilter" style={{marginLeft: "10px"}}>
                                    <SingleSelect
                                        formData={formData}
                                        formKey='dateCreatedFilter'
                                        onDataChange={onCategoryChange2}
                                        placeholder='Created Date'
                                        isRequired={false}
                                        options={config.monthFilter}
                                    />
                                    {formCreatedDataEmpty && <h1 className="valError">
                                        <img src={errorSVG} />
                                        {'Date required.'}
                                    </h1>
                                    }

                                    {customCreatedFilter && <div className={`filterDropDown r-auto tableFilter custom-w open`}>
                                        <div className="filterDropBody">
                                            <div className="filterDropHead">
                                                <a href="javascript:void(0);" className="viewwLink" onClick={() => setCustomCreatedFilter(false)} ><img src={closeCustome} /></a>
                                            </div>
                                            <div className="filterDropClose open">
                                                <div className="filtercheckbox">
                                                    <CustomDatePicker
                                                        isChrome={isChrome}
                                                        formData={isChrome ? formCreatedData : formCreatedDataDiffFormat}
                                                        formKey='startCreatedDate'
                                                        onDataChange={(e, v) => { onCreatedDateChange(e, v); setCreatedFilterErr({ ...createedFilterErr, startDate: false }); setCreatedFilterErrMsg({ ...createedFilterErrMsg, startDateMsg: "" }) }}
                                                        isRequired={false}
                                                        formValidator={formValidator}
                                                        validation='required'
                                                        max={formCreatedData.endCreatedDate ? moment(formCreatedData.endCreatedDate).subtract(1, "days").clone().format("YYYY-MM-DD") : moment().subtract(1, "days").clone().format("YYYY-MM-DD")}
                                                    />
                                                    {createedFilterErr.startDate && <span className="valError"><img src={errorSVG} />{createedFilterErrMsg.startDateMsg}</span>}
                                                </div>
                                            </div>
                                            <div className="filterDropClose open">
                                                <div className="filtercheckbox">
                                                    <CustomDatePicker
                                                        isChrome={isChrome}
                                                        formData={isChrome ? formCreatedData : formCreatedDataDiffFormat}
                                                        formKey='endCreatedDate'
                                                        onDataChange={(e, v) => { onCreatedDateChange(e, v); setCreatedFilterErr({ ...createedFilterErr, endDate: false }); setCreatedFilterErrMsg({ ...createedFilterErrMsg, endDateMsg: "" }) }}
                                                        isRequired={false}
                                                        formValidator={formValidator}
                                                        validation='required'
                                                        min={moment(formCreatedData.startCreatedDate).add(1, "days").clone().format("YYYY-MM-DD")}
                                                        max={moment().clone().format("YYYY-MM-DD")}
                                                    />
                                                    {createedFilterErr.endDate && <span className="valError"><img src={errorSVG} />{createedFilterErrMsg.endDateMsg}</span>}
                                                </div>
                                            </div>
                                            <div className="filterDropHead-campaign">
                                                <a href="javascript:void(0);" className="viewwLink" onClick={() => { setFormCreatedData({ startCreatedDate: "", endCreatedDate: "" }); setFormCreatedDataDiffFormat({ startCreatedDate: "", endCreatedDate: "" }); setFlag(!flag); }} >clear</a>
                                            </div>
                                            {formCreatedData.startCreatedDate !== '' && formCreatedData.endCreatedDate !== '' && <div className="filterDropHead-campaign">
                                                <a href="javascript:void(0);" className="viewwLink" onClick={() => setCreateSearch(true)} >Search</a>
                                            </div>}


                                        </div>
                                    </div>}
                                </div>
                                <button onClick={() => history.push('/backend/backend-details/nonWisr-create-campaign/set-up-nonWisr-campaign')} className='btn btn-orange' style={{height: "45px"}}>
                                    Create
                                </button>
                                <div className="filterDropHead-campaign">
                                    <a href="javascript:void(0);" className="viewwLink" onClick={() => { setCustomSheduledFilter(false); setCustomCreatedFilter(false); setFormData({ dateFilter: "", dateCreatedFilter: "" }); setFormSheduledData({startSheduledDate:"",endSheduledDate:""}); setFormSheduledDataDiffFormat({startSheduledDate: "", endSheduledDate: "" }); setFormCreatedData({ startCreatedDate: "", endCreatedDate: "" }); setFormCreatedDataDiffFormat({ startCreatedDate: "", endCreatedDate: "" }); setFlag(!flag); }} >Clear</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 tableScroll fillterTableHeight">
                            <table className="table going-campaign">
                                <thead>
                                    <tr ref={ref}>
                                        <th>SL No</th>
                                        <th>Campaign Name</th>
                                        <th className="p-relative">Status <a className="openFilter" href="javascript:void(0);" onClick={() => { console.log("filter clicked"); setOpenStatusFilter(!openStatusFilter) }}><img src={tableFilter} /></a>
                                            <CampaignStatusFilter
                                                openStatusFilter={openStatusFilter}
                                                setFilterType={setFilterType}
                                                filterType={filterType}
                                                setFilterStatus={setFilterStatus}
                                                filterStatus={filterStatus}
                                                nonWisrCampaign={true}
                                            />
                                        </th>
                                        <th>Budget <a href="javascript:void(0);" onClick={sortByBudget}><img src={tableFilter} /></a></th>
                                        <th>school</th>
                                        <th>schedule</th>
                                        {/* <th>reach</th>
                                        <th>impression</th> */}
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        !loading ?
                                            campaignData.length > 0 ?
                                                campaignData.map((data, index) => {
                                                    return (
                                                        <>
                                                            <tr>
                                                                <td>{((currentPage - 1) * 10) + (index + 1)}</td>
                                                                <td>{data.name}</td>
                                                                <td><span className={`table-status ${data.campaignStatus === 'rejected' ? 'redbg' : data.campaignStatus === "executed" ? "graybg" : data.campaignStatus === "scheduled" ? "orangebg" : data.campaignStatus === 'ongoing' ? 'yellowbg' : data.campaignStatus === 'in-review' ? "orangebg" : data.campaignStatus === 'approved' ? "greenbg" : data.campaignStatus === "on-hold" && "onholdbg"} `}> {data.campaignStatus === 'rejected' ? 'Rejected' : data.campaignStatus === 'ongoing' ? 'Ongoing' : data.campaignStatus === "executed" ? "Executed" : data.campaignStatus === "scheduled" ? "Scheduled" : data.campaignStatus === 'in-review' ? "In-Review" : data.campaignStatus === "approved" ? "Approved" : data.campaignStatus === "on-hold" && "On-Hold"}</span></td>
                                                                <td>₹ {data.budget !== null && data.budget !== null ? data.budget.toLocaleString('en-IN') : "-"}</td>
                                                                <td>{data && data.schools && data.schools.length}</td>
                                                                <td>{`${formatDateShowMonth(data.startDate, 2)} - ${formatDateShowMonth(data.endDate, 2)}`}</td>
                                                                {/* <td>{data.totalReach !== null && data.totalReach !== null ? data.totalReach.toLocaleString('en-IN') : "-"}</td>
                                                        <td>{data.totalImpression !== null && data.totalImpression !== null ? data.totalImpression.toLocaleString('en-IN') : "-"}</td> */}
                                                                <td><span onClick={() => history.push({ pathname: "/backend/backend-details/nonWisr-campaign-detail", state: { campaignId: data._id } })} style={{ cursor: "pointer" }}><img src={arrowViewRound} /></span></td>
                                                            </tr>
                                                        </>
                                                    )

                                                })
                                                :
                                                <tr><td colSpan={7}><div style={{ textAlign: "center" }}>No Campaign found</div></td></tr>
                                            :
                                            [...Array(10)].map((el, index) => {
                                                return (
                                                    <tr>
                                                        {
                                                            [...Array(6)].map((el, index) => {
                                                                return (
                                                                    <td>
                                                                        <div className="content-loader-block"></div>
                                                                    </td>
                                                                )
                                                            })
                                                        }
                                                    </tr>
                                                )
                                            })
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="col-lg-12 col-md-12 col-xs-12 p-r">
                            {totalPage.length > 1 &&
                                <Pagination
                                    currentPage={currentPage}
                                    totalPage={totalPage}
                                    onPageChange={onPageChange}
                                    onNextClick={onNextClick}
                                    onPrevClick={onPrevClick}
                                    loading={loading}
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
            {/* {campaignId !== '' && <SchoolList
                showSchoolModal={showSchoolTable}
                closeSchoolModal={closeSchoolTable}
                campaignId={campaignId}
                showCampaignCreativeButton={true}
                fromBrand={true}
                // doNotShowCreativeStatus={totalCampaignDetails.creativeReworkNeededList[selectedIndex].isTrial}
            />} */}
        </>
    )
}

export default NonWisrCampaignList
