import sucessImg from "../../assets/images/sucess.svg"
import { useHistory } from 'react-router-dom';
const SuccessUserCreateModal = (props) => {
    const history = useHistory();
    const {
        userCreateSuccessfully,
        setUserCreateSuccessfully,
        setNewUserCreated,
        newUserCreated,
        modalTitle,
        setOverlay,
        userCreateedEmail
    } = props
    return (
        <>
            <div id="thankyouModal" className={`popup success-popup sdf ${userCreateSuccessfully === false ? "hide" : ""}`}>
                <div className="popup-header">
                    <h3 className="htx2 pophead"><img src={sucessImg} /> {modalTitle}</h3>
                    <span className="close toggle" onClick={()=>{setUserCreateSuccessfully(false);setNewUserCreated(!newUserCreated);setOverlay(false);}} data-target="myPopup">finish</span>
                </div>
                <div className="popup-body">
                    <div className="viewdetailsHead">
                        <div className="approveActive">
                                <p>Login ID for <b>{userCreateedEmail}</b> has been successfully created.</p>
                        </div>
                    </div>
                </div>
                <div className="row col-xl-12 col-lg-12 col-md-12 col-xs-12 end-md">
                    <div className="forminpt">
                        <input name="Finish" className="btn btn-orange comp-seting" type="button" value="Finish" onClick={()=>{setUserCreateSuccessfully(false);setNewUserCreated(!newUserCreated);setOverlay(false);}} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default SuccessUserCreateModal
