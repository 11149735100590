import { useEffect, useState } from "react";

import teamLeftarrow from "../../../assets/images/landing/teamLeftarrow.svg"
import teamRightarrow from "../../../assets/images/landing/teamRightarrow.svg"
import ourTeam1 from "../../../assets/images/landing/our-team-1.jpg"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import exclamation from "../../../assets/images/exlcamation.png"

const HappySchools = (props) => {
    const {
        ourTeamData
    } = props


    const [lastIndex, setLastIndex] = useState(100)

    const [noOfSlides, setNoOfSlides] = useState([])
    const [currentIndex, setCurrentIndex] = useState(0);
    const [ourTeamPopUp, setOurTeamPopUp] = useState(false)
    const [selectedTeamMember, setSelectedTeamMember] = useState<any>({})
    const [overlay, setoverlay] = useState(false)
    const onNext = () => {
        // console.log("current Index..noofslides...", currentIndex, noOfSlides)
        if (currentIndex < noOfSlides.length - 1) {
            setCurrentIndex((prev) => prev + 1)
        }


    }
    const onPrev = () => {
        if (currentIndex !== 0) {
            setCurrentIndex((prev) => prev - 1)
        }
    }
    function slideCount() {
        let a = 0;
        if (ourTeamData.length % 3 == 0) {
            a = ourTeamData.length / 3
        }
        else {
            a = Math.floor(ourTeamData.length / 3) + 1
        }
        let temp = []
        for (var i = 0; i < a; i++) {
            temp.push(i)
        }
        setNoOfSlides(temp)
    }
    useEffect(() => {
        slideCount()
    }, [])

    const [startIndex, setStartIndex] = useState(0)
    const [endIndex, setEndIndex] = useState(0)

    useEffect(() => {
        setLastIndex(currentIndex === (noOfSlides.length - 1) ? (ourTeamData.length - ((currentIndex) * 4)) * 20 : 80)

        // console.log("while changing start and end... currentIndex", currentIndex)
        if (currentIndex === (noOfSlides.length - 1)) {
            setStartIndex((currentIndex * 4) - (4 - (ourTeamData.length - (currentIndex * 4))))
            setEndIndex(ourTeamData.length)
        }
        else {
            setStartIndex((currentIndex * 4))
            setEndIndex((currentIndex * 4) + 4)
        }

    }, [currentIndex])
    const closeWithoutSaving = () => {
        setOurTeamPopUp(false)
        setoverlay(false)
    }

    useEffect(() => {
        // console.log("start End current index value..", startIndex, endIndex, currentIndex)
    }, [endIndex, startIndex])

    var settings = {
        infinite: false,
        className: "",
        speed: 900,
        // slidesToScroll: 3,
        // slidesToShow: 3.5,
        nextArrow: <SampleNextArrow
            onNext={onNext}
        />,
        prevArrow: <SamplePrevArrow
            onPrev={onPrev}
        />,
        responsive: [
            {
                breakpoint: 2560,
                settings: {
                    slidesToScroll: 4,
                    slidesToShow: 4
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToScroll: 3,
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToScroll: 2,
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 400,
                settings: {
                    slidesToScroll: 1,
                    slidesToShow: 1
                }
            },
        ]
    };
    const openModal = (teamMember) => {
        // console.log("first", teamMember)
        setSelectedTeamMember({ ...teamMember })
        setoverlay(true)
        setOurTeamPopUp(true)
    }
    return (
        <>
            <div className={`ovelap ${overlay === true ? "active" : ""}`}></div>
            <div className='school-generate ourteam' style={{padding: '50px 0 120px 0'}}>
                <div className='containerLanding happySchoolContainer'>
                    <div className='col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-20'>
                        <h3 className='headhx2'>Happy Schools</h3>
                    </div>
                    {/* <div className='arrowteam'>
                        <a onClick={() => onPrev()}><img src={teamLeftarrow} /></a>
                        <a onClick={() => onNext()}><img src={teamRightarrow} /></a>
                    </div> */}
                        {

                            ourTeamData.length != 0 &&
                                <div className='row' style={{flexWrap: 'wrap'}}>
                                    {
                                        ourTeamData.map((data, index) => (
                                            index <= 2 &&
                                            <div className="happySchoolCard" style={{backgroundColor: index === 0 ? '#F7F5FF' : index === 1 ? ' #F4FDFF' : ' #FEF5FF', border: `1px solid ${index === 0 ? ' #EDEAFF' : index === 1 ? ' #E8FAFF' : '#FDECFF'}`}}>
                                                <div className="happySchoolCardUpperSection" style={{display: 'flex', padding: '15px 15px 15px 15px', width: '100%'}}>
                                                    <img height='75px' width='75px' style={{marginRight: '10px', borderRadius: '50%'}} src={data.hs_logo} />
                                                    <div style={{display: 'flex', flexDirection: 'column', textAlign: 'left', marginTop: '10px', flexWrap: 'wrap'}}>
                                                        <div style={{marginBottom: '7px', overflowWrap: 'break-word'}}><span style={{fontSize: '16px'}}>{data.hs_title}</span></div>
                                                        <div style={{fontSize: '12px',marginBottom: '3px', color: '#8a8a8a', overflowWrap: 'break-word'}}>{data.hs_designation}</div>
                                                        <div style={{fontSize: '12px', color: '#8a8a8a', overflowWrap: 'break-word'}}>{data.hs_school}</div>
                                                    </div>
                                                </div>
                                                <div style={{textAlign: 'left', padding: '15px', height: '215px', overflowY: 'scroll'}}>
                                                    <img width='auto' height='20px' src={exclamation} />
                                                    <span style={{fontFamily: 'nexaregular', marginLeft: '5px'}}>{data.hs_text}</span>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                        }
                </div>
            </div>
            {/* <div
                id='ourTeamMember'
                className={`popup ${ourTeamPopUp === false ? "hide" : ""}`}
            >
                <div className='popup-body'>

                    <div className='ourTeamMember clearfix'>
                        <div className="ourTeamMemberLeft">
                            <img src={selectedTeamMember.img} />
                        </div>
                        <div className="ourTeamMemberRight">
                            <span onClick={closeWithoutSaving} className='close toggle' data-target='amenitiesModal'> close</span>
                            <div className="ourTeamMemberScroll">

                                <h4>{selectedTeamMember.name}</h4>
                                <span>{selectedTeamMember.designation}</span>
                                <p>{selectedTeamMember.description}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    )

}

export default HappySchools

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            // style={{ ...style, display: "block", background: "red", top: 20, right: 20, position: "relative" }}
            onClick={onClick}
        >
            <a onClick={() => props.onNext()}><img src={teamRightarrow} /></a>
        </div>
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            // style={{ ...style, display: "block", background: "green", top: 20, right: 20, position: "relative" }}
            onClick={onClick}
        >
            <a onClick={() => props.onPrev()}><img src={teamLeftarrow} /></a>
        </div>
    );
}