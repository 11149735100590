import { useState, useEffect, useRef, useImperativeHandle, forwardRef } from "react"

import check from "../../../assets/images/check.svg"
import errorSVG from "../../../assets/images/error.svg"

import SimpleReactValidator from "simple-react-validator"
import { validateActName, validatePrincipalName } from "../../../service/helper"

interface Props {
    formData?: any
    formKey?: any
    onDataChange?: any
    label?: any
    placeholder?: any
    onVerifyClick?: any
    classes?: any
    wrapperClasses?: any
    isVerified?: any
    maxLength?: any
    smallDesc?: any
    validation? :any
    isDisabled?: any,
    isRequired?: any,
    role?: any
}

const TextFieldWithValidation = forwardRef(({
    formData,
    formKey,
    onDataChange,
    label,
    placeholder,
    onVerifyClick,
    classes,
    wrapperClasses,
    isVerified,
    maxLength,
    smallDesc,
    validation,
    isDisabled,
    role
}: Props, ref) => {

    const [formValidator] = useState(useRef(new SimpleReactValidator({
        element: (message, className) =>
            <span className="valError"><img src={errorSVG} />{label === "board" ? 'The other board field is required.' : label === "otherAct" || label === "School Principal's Email ID" || label === "School Principal's Name" ? message : `The other field for ${label} is required.`}</span>
        })))
    const [update, forceUpdate] = useState(false) // to re-render the dom after updating the validation

    const [classList] = useState(classes ? classes : "")
    const [wrapperClassList] = useState(wrapperClasses ? wrapperClasses : "")
    formValidator.current.rules['act_name'] = {
        message: 'Please enter valid act name.',
        rule: (val) => {
            let returnValue = validateActName(val)

            return returnValue
        },
    }
    formValidator.current.rules['Principal_name'] = {
        message: 'Please enter valid principal name.',
        rule: (val) => {
            let returnValue = validatePrincipalName(val)

            return returnValue
        },
    }
    formValidator.current.rules['same_name'] = {
        message: 'Admin and principal name can not be same.',
        rule: (val) => {
            let returnValue;
            if(formData.schoolPrincipalName.trim().replace(/  +/g, ' ').toLowerCase() === formData.fullName.trim().replace(/  +/g, ' ').toLowerCase()) {
                returnValue = false
            }else{
                returnValue = true
            }

            return returnValue
        },
    }
    formValidator.current.rules['max'] = {
        message: 'The act name must be less than 50 characters long.',
        rule: (val) => {
            let returnValue;
            if(val.length>50){
                returnValue = false
            }
            return returnValue
        },
    }

    useImperativeHandle(ref, () => ({
        validateField() {
            const result = formValidator.current.allValid()
            if (!result) {
                formValidator.current.showMessages()
            } else {
                formValidator.current.hideMessages()
            }
            forceUpdate(!update)

            return result
        }
    }))
    useEffect(() => {
        if(role && role === "schoolPrincipal"){
            formValidator.current.hideMessages()
            forceUpdate(!update)
        }
    }, [role])
    return (
        <>
        <div className={`${label ? "forminpt" : ""} ${wrapperClassList} ${isDisabled === true ? "disabled" : ""} `}>
            {
                (label === "School Principal's Name" || label === "School Principal's Email ID") &&
                <label>{label}</label>
            }
            <input
                id={formKey}
                value={formData[formKey]}
                onChange={onDataChange}
                className={`${classList} ${(formValidator?.current?.messagesShown === true && formValidator?.current?.fields?.[formKey] === false) ? "onFocus" : ""}`}
                placeholder={placeholder}
                maxLength={maxLength}
                disabled={isDisabled === true ? true : false}
                type="text" />
                {
                    smallDesc &&
                    <small>{smallDesc}</small>
                }
                {
                    onVerifyClick &&
                    <a onClick={onVerifyClick} href="javascript:void(0)"><b className={`vertext vrifiyotp ${isVerified === true ? "complete" : ""}`}>verify <img src={check} /></b></a>
                }
                {formValidator?.current?.message(formKey, formData[formKey], validation)}
        </div>
        </>
    )
})

export default TextFieldWithValidation
