import { useState, useEffect, useContext, useRef } from "react"

import { useHistory } from "react-router-dom"

import toast from "react-hot-toast"

import { validation } from "../../../../service/validators"

import TextFieldWithIcon from "../../../../components/formFields/textfieldWithIcon"
import PasswordField from "../../../../components/formFields/passwordField"

import loginWave from "../../../../assets/images/login-wave.svg"
import checkSuccess from "../../../../assets/images/check.svg"

import envelope from "../../../../assets/images/envolpe.svg"
import logo from "../../../../assets/images/logo.png"
import AppContextObject from "../../../../common/context/common"
import Textfield from "../../../../components/formFields/textField/textField"
import SimpleReactValidator from "simple-react-validator"
import errorSVG from "../../../../assets/images/error.svg";
import { objectDeepClone, validateFullName } from "../../../../service/helper"
import SingleSelect from "../../../../components/formFields/singleSelect/singleSelect"
import config from "../../../../service/config"
import AlertDialog from "../../../../components/addon/alertDialog/alertDialog"
import { PublicContextInitiator } from "../../../apis/publicContextInitiator"
import DataContext from "../../../../service/brandContext"

interface Props {
    methods
}

const SuccessLogin = ({
    methods
}: Props) => {
    const [update, forceUpdate] = useState(false)
    const history = useHistory()
    const context = useContext(AppContextObject)
    const [selectedContactForOTP, setSelectedContactForOTP] = useState("");
    const [msgDetails, setMsgDetails] = useState('');
    const [overlay, setOverlay] = useState(false)
    const [toggleAlertPopUp, setToggleAlertPopUp] = useState(false);
    const [getFieldInfo, setGetFieldInfo] = useState('');
    const [detailsCaptured, setDetailsCaptured] = useState('');
    let publicContextInitiator = new PublicContextInitiator(context)
    const parentData = useContext<any>(DataContext)
    const [formData, setFormData] = useState({
        fullName: "",
        companyName: "",
        designation: ""
    })
    const [formValidator] = useState(useRef(new SimpleReactValidator({
        element: (message, className) =>
            <span className="valError"><img src={errorSVG} />{message}</span>
    })))

    useEffect(() => {
    }, [])


    const onFormDataChange = (event) => {
        setFormData({
            ...formData,
            [event.target.id]: event.target.value
        })
        // if (event.target.id === "username") {
        //     setUsernameError(false)
        //     setFormData({
        //         ...formData,
        //         username: event.target.value.toLowerCase()
        //     })
        //     if (formData.username != event.target.value) {
        //         if (event.target.parentNode.querySelectorAll(".vrifiyotp")[0].className.indexOf("complete") >= 0) {
        //             event.target.parentNode.querySelectorAll(".vrifiyotp")[0].className = event.target.parentNode.querySelectorAll(".vrifiyotp")[0].className.replace(/ complete/gi, "");
        //             setIsUsernameVerified(false)
        //             setGetVerifyUsernameStatus(false)
        //         }
        //     }
        // }
    }

    formValidator.current.rules['full_name'] = {
        message: 'Please enter valid full name',
        rule: (val) => {
            let returnValue = validateFullName(val)

            return returnValue
        },
    }
    
    const onSubmit = async () => {

        const result = formValidator.current.allValid()
        if (result === false) {
            formValidator.current.showMessages()
            forceUpdate(!update)
            return
        } else {
            formValidator.current.hideMessages()
            forceUpdate(!update)
            // setEnableField("")
        }

         let role = ""
         if(formData.designation == 'Brand Manager') {
             role = 'brandManager'
         }
        else if(formData.designation == 'CMO') {
            role = 'brandCMO'
        }
        else if(formData.designation == 'Cheif Head') {
            role = 'brandCH'
        }


        // let data = {
        //     fullName: formData.fullName,
        //     designation : formData.designation,
        //     companyName : formData.companyName,
        //     // role : role
        // }
        // if (parentData) {
        //     const json = { ...formData }
        //     if (parentData.setData) {
        //         parentData.setData({
        //             ...parentData.data,
        //             personalDetails: 
        //             {
        //                 fullName: 'ss s',
        //                 email: 'da@ewew.ddd',
        //                 phone: '365616684786',
        //                 role: null,
        //                 username: 'dsd',
        //                 designation: 'ds',
        //                 companyName: 'ss'
        //             }
        //         })
        //     }
        // }
       
        // let addUserResp = await publicContextInitiator.addUser(
        //     context.validator, data
        // )
        // console.log("addUserResp ", addUserResp)
        // if (addUserResp && addUserResp.done) {
            // history.push('/brands/contact-details', {
            //     fullName : formData.fullName,
            //     companyName : formData.companyName,
            //     designation : formData.designation,
            //     role : role
            //   })
            // history.push("/brands/contact-details")
        // }

        let data = {
            fullName : formData.fullName,
            companyName : formData.companyName,
            designation : formData.designation,
            role : role
        }


        history.push({
            pathname: '/brands/contact-details',
            state: {
                fullName : formData.fullName,
                companyName : formData.companyName,
                designation : formData.designation,
                role : role
            },
          });

    }

    console.log("parentData ", parentData.data.personalDetails)

    const confirmClose = () => {
        setOverlay(false)
        setToggleAlertPopUp(false)
    }

    const onSignUpClick = () => {
        history.push("/brands/welcome-login")
    }

    const onDesignationChange = (data) => {
        setFormData({
            ...formData,
            designation: data.value
        })
    }
    return (
        <>
            <div className={`ovelap ${(overlay === true) ? "active" : ""}`}></div>
            <div className="main-wapper">
                <div className="loginbox">
                    <div className="login-wave"> <img src={loginWave} alt="login-wave" /> </div>
                    <div className="login-form">
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb10">
                                <img src={logo} alt="logo" />
                                <h2 className="htx1"><img className="checkSuccess" src={checkSuccess} alt="check Success" /> Success!</h2>
                                <p className="ptx mb-20">Your password for <strong>mohit@netbramha.com</strong> has been updated successfully.</p>
                            </div>
                        </div>
                        {/* <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb10">
                                <img src={logo} alt="logo" />
                                <h2 className="htx1"><img className="checkSuccess" src={checkSuccess} alt="check Success" /> Voila!!</h2>
                                <p className="ptx mb-20">Your password has been changed successfully.</p>
                            </div>
                        </div> */}
                        <form data-form-validation="login-form" id="login-form-parent">
                            <div className="row">
                                <div className="row col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                    <div className="forminpt">
                                        <input onClick={onSubmit} value="Log IN" className="btn btn-orange datasubmit" type="button" />
                                    </div>
                                </div>
                                
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <AlertDialog
                togglePopup={toggleAlertPopUp}
                fieldInfo={getFieldInfo}
                details={detailsCaptured}
                onModalClose={confirmClose}
                displayMsg={msgDetails}
            />
        </>
    )
}

export default SuccessLogin


