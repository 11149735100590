import { useState, useContext, useEffect, useRef } from "react"

import { useHistory } from "react-router-dom"
// import OnGoingCampaigns from "./onGoingCampaigns"
import Pagination from "../../../components/addon/pagination"
import arrowView from "../../../assets/images/arrow-view.svg"
import tableFilter from "../../../assets/images/table-filter.svg"
import AppContextObject from "../../../common/context/common"
import MultiPointMap from "../../../components/addon/multiPointMap"
import scriptLoader from 'react-async-script-loader'
import config from "../../../service/config"
import DataContext from "../../../service/brandContext"
import filterSvg from "../../../assets/images/filter-svg.svg"
import filterArrowSvg from "../../../assets/images/filter-arrow-svg.svg"
import TopBar from "../schools/components/topBar"
import BrandTopBar from "./components/brandTopBar"
import { returnReadableStatus, titleCase } from "../../../service/helper"
import { WisrOptionService } from "../../../service/wisrOptions"
import { DebounceInput } from 'react-debounce-input'
import searchSvg from "../../../assets/images/search.svg"
import CustomSearchInput from "../../../components/formFields/customSearchInput"
import BrandBackBtn from "./components/brandBackBtn"
import useClickOutside from "../../../service/useClickOutside"
import helpImg from "../../../assets/images/help.svg"
var globalVar = 1

const School = (props) => {
    const {
        isEdit,
        methods,
        isScriptLoaded,
        isScriptLoadSucceed
    } = props

    const filterRef = useRef(null)
    const filterOptionsRef = useRef(null)
    const history = useHistory()
    const mapRef = useRef()
    const [stateList, setStateList] = useState([])
    const [selectedState, setSelectedState] = useState([])
    const [cityList, setCityList] = useState([])
    const [searchInpuOpen, setSearchInpuOpen] = useState(false)
    const [clearSearchIcon, setClearSearchIcon] = useState(false)
    const parentData = useContext<any>(DataContext)
    const context = useContext(AppContextObject)
    const [totalPage, setTotalPage] = useState([0])
    const [currentPage, setCurrentpage] = useState(1)
    const [pincodeList, setPincodeList] = useState([])
    const [dataLoaded, setDataLoaded] = useState(false)
    const [searchValue, setSearchValue] = useState("")
    const [filterByCity, setFilterByCity] = useState([])
    const [filterByLanguage, setFilterByLanguage] = useState([])
    const [filterByBoard, setFilterByBoard] = useState([])
    const [filterBySchoolType, setFilterBySchoolType] = useState([])
    const [schoolList, setSchoolList] = useState([])
    const [showFilterModal, setShowFilterModal] = useState(false)
    const [showCityFilter, setShowCityFilter] = useState(false)
    const [showLanguageFilter, setShowLanguageFilter] = useState(false)
    const [showBoardFilter, setShowBoardFilter] = useState(false)
    const [showSchoolTypeFilter, setShowSchoolTypeFilter] = useState(false)
    const [showStateFilter, setShowStateFilter] = useState(false)
    const [dataPerPage] = useState(25)
    const [isPageLoading, setIsPageLoading] = useState(false)
    const [sortingData, setSortingData] = useState({_id: -1})
    const [fetchingSchoolForFirstTime, setFetchingSchoolForFirstTime] = useState(true)
    // const [countryArray] = useState([{label:"Hyderabad", value: "hyderabad"},{label:"Chennai", value: "chennai"}, {label:"Kolkata", value: "kolkata"}, {label:"Mumbai", value: "mumbai"}, {label:"Bengaluru", value: "bengaluru"}])
    useClickOutside(filterOptionsRef, (event) => {
        const el = filterRef?.current;

        if (!el || el.contains(event.target)) {
            return;
        }
        setShowFilterModal(false)
    })
    const onPageChange = (page) => {
        setCurrentpage(page)
    }
    const onNextClick = () => {
        setCurrentpage(prev => prev + 1)
    }
    const onPrevClick = () => {
        setCurrentpage(prev => prev - 1)
    }
    useEffect(() => {
        getAllSchoolList()
    }, [])
    useEffect(() => {
        if(parentData){
            parentData.setShowSidemenu(true)
        }
    }, [])
    useEffect(() => {
        fetchState()
    }, [])
    useEffect(() => {
        fetchCityByState()
    }, [selectedState])
    const fetchCityByState = async() => {
        const resp = await context.apis.public.getCitiesByState(
            context.validator, {states: selectedState}, {}
        )
        console.log("getCitiesByState", resp)
        let city = []
        if(resp && resp.done){
            resp?.response?.rows?.forEach(row => {
                // city = [...city, ...row.cities]
                 if(row.parentCities && row.parentCities.length > 0){
                    row.parentCities.forEach(item => {
                        if(!city.find(data => data.toLowerCase() === item.toLowerCase())){
                            city.push(item)
                        }
                    })
                }
            })
            city = city.sort((a, b) => {
                return a.toLowerCase() > b.toLowerCase() ? 1 : -1
            })
            console.log("cityFirst", city)
            setCityList([...city])
        }
    }
    const fetchState = async() => {
        const resp = await context.apis.public.getStatesByCountryRegion(
            context.validator, {countryRegion: ["East", "West", "Central", "North", "North East", "South"]}, {}
        )
        console.log("getStatesByCountryRegion", resp)
        let state = []
        if(resp && resp.done){
            resp?.response?.rows?.forEach(row => {
                state = [...state, ...row.states]
            })
            state = state.sort((a, b) => {
                return a.toLowerCase() > b.toLowerCase() ? 1 : -1
            })
            console.log("stateFirst", state)
            setStateList([...state])
        }
    }
    useEffect(() => {
        if(!fetchingSchoolForFirstTime){
            getFilteredSchoolList()
        }
    }, [filterByBoard, filterByCity, filterByLanguage, filterBySchoolType, currentPage, searchValue, sortingData, selectedState])
    useEffect(() => {
        if(currentPage !== 1){
            setCurrentpage(1)
        }
    }, [filterByBoard, filterByCity, filterByLanguage, filterBySchoolType, searchValue, selectedState])
    const getFilteredSchoolList = async() => {
        if(fetchingSchoolForFirstTime){
            return
        }
        var localVar = Math.random()
        globalVar = localVar
        console.log("variables", localVar, globalVar)
        setIsPageLoading(true)
        // let schoolTypeArray = filterBySchoolType.length === 0 ? [] : filterBySchoolType.length === 1 ? [...filterBySchoolType] : ["co-ed"]
        const resp = await context.apis.public.getAllSchools(
            context.validator, {city: filterByCity, schoolType: filterBySchoolType, language: filterByLanguage, board: filterByBoard, searchValue: searchValue, state: selectedState}, {limit: dataPerPage, page: currentPage, withCount: true, sort: sortingData}
        )
        console.log("getAllSchools", resp)
        if(resp && resp.done){
            console.log("variables12", localVar, globalVar)
            if(localVar === globalVar){
                let inventoryName = [], eventName = [], classRoomList = [], schoolsList = [], inventoryList = [], eventList = [], OptimizedSchool = []
                resp.response.rows.forEach(item => {
                    classRoomList = [...classRoomList, ...item.classrooms]
                    inventoryList = [...inventoryList, ...item.inventories.filter(inventory => inventory.status !=="Unavailable" && inventory.isApproved !== false)]
                    eventList = [...eventList, ...item.activities.filter(event => event.status !=="Unavailable" && event.isApproved !== false)]
                    schoolsList.push(item)
                })
                inventoryList.forEach(item => {
                    if(!inventoryName.includes(item.parentName)){
                        inventoryName.push(item.parentName)
                    }
                })
                eventList.forEach(item => {
                    if(!eventName.includes(item.name)){
                        eventName.push(item.name)
                    }
                })
                const WisrOption = new WisrOptionService({
                    TargetAudience: "co-ed",
                    EventsNames: [...eventName],
                    InventoriesNames: [...inventoryName],
                    budgetRatio: {CatA: 0, CatB: 0, CatC: 0},
                    campaignBudget: 0,
                    campaignDurationInDays: 365,
                    classroomData: classRoomList, //List of classrooms
                    eventData: eventList, //List of events
                    inventoryData: inventoryList, //List of inventories
                    schoolData: schoolsList, //List of schools
                    noOfDaysInYear: 365,
                    percentageDiscountInWISR: 0,
                    percentageIncreaseInReach: 0,
                    totalInternalCostPerSchool: 0,
                });
                WisrOption.$OptimizedSchool.subscribe((school) => {
                    OptimizedSchool = [...school]
                    console.log("OptimizedSchool",school)
                })
                schoolsList = resp.response.rows.map(item => {
                    let found = OptimizedSchool.find(data => data._id === item._id)
                    if(found){
                        item["totalImpressions"] = found.impressions
                    }
                    return item
                })
                setSchoolList([...schoolsList])
                let pinCodeData = []
                resp.response.rows.forEach((school) => {
                    if(school.city){
                        pinCodeData.push(school.city)
                    }
                })
                console.log(pinCodeData)
                setPincodeList(pinCodeData)
                let total = Math.ceil(Number(resp.response.count)/dataPerPage)
                let arr = []
                for(let i=0; i<total; i++){
                    arr.push(i)
                }
                setTotalPage([...arr])
                setIsPageLoading(false)
            }
        }
        setIsPageLoading(false)
    }
    const handleAddRemoveFilter = (type, data) => {
        if(type === "state"){
            let array = [...selectedState]
            if(array.includes(data)){
                array.splice(array.indexOf(data), 1)
                if(filterByCity.length !== 0){
                    setFilterByCity([])
                }
            }
            else{
                array.push(data)
            }
            setSelectedState([...array])
        }
        if(type === "city"){
            let array = [...filterByCity]
            if(array.includes(data)){
                array.splice(array.indexOf(data), 1)
            }
            else{
                array.push(data)
            }
            setFilterByCity([...array])
        }
        else if(type === "board"){
            let array = [...filterByBoard]
            if(array.includes(data)){
                array.splice(array.indexOf(data), 1)
            }
            else{
                array.push(data)
            }
            setFilterByBoard([...array])
        }
        else if(type === "language"){
            let array = [...filterByLanguage]
            if(array.includes(data)){
                array.splice(array.indexOf(data), 1)
            }
            else{
                array.push(data)
            }
            setFilterByLanguage([...array])
        }
        else if(type === "schoolType"){
            let array = [...filterBySchoolType]
            if(array.includes(data)){
                array.splice(array.indexOf(data), 1)
            }
            else{
                array.push(data)
            }
            setFilterBySchoolType([...array])
        }
    }
    const getAllSchoolList = async() => {
        setIsPageLoading(true)
        // let schoolTypeArray = filterBySchoolType.length === 0 ? [] : filterBySchoolType.length === 1 ? [...filterBySchoolType] : ["co-ed"]
        const resp = await context.apis.public.getAllSchools(
            context.validator, {}, {limit: dataPerPage, page: currentPage, withCount: true, sort: sortingData}
        )
        if(resp && resp.done){
            let inventoryName = [], eventName = [], classRoomList = [], schoolsList = [], inventoryList = [], eventList = [], OptimizedSchool = []
            resp.response.rows.forEach(item => {
                classRoomList = [...classRoomList, ...item.classrooms]
                inventoryList = [...inventoryList, ...item.inventories]
                eventList = [...eventList, ...item.activities]
                schoolsList.push(item)
            })
            inventoryList.forEach(item => {
                if(!inventoryName.includes(item.parentName)){
                    inventoryName.push(item.parentName)
                }
            })
            eventList.forEach(item => {
                if(!eventName.includes(item.name)){
                    eventName.push(item.name)
                }
            })
            const WisrOption = new WisrOptionService({
                TargetAudience: "co-ed",
                EventsNames: [...eventName],
                InventoriesNames: [...inventoryName],
                budgetRatio: {CatA: 0, CatB: 0, CatC: 0},
                campaignBudget: 0,
                campaignDurationInDays: 365,
                classroomData: classRoomList, //List of classrooms
                eventData: eventList, //List of events
                inventoryData: inventoryList, //List of inventories
                schoolData: schoolsList, //List of schools
                noOfDaysInYear: 365,
                percentageDiscountInWISR: 0,
                percentageIncreaseInReach: 0,
                totalInternalCostPerSchool: 0,
            });
            WisrOption.$OptimizedSchool.subscribe((school) => {
                OptimizedSchool = [...school]
                console.log("OptimizedSchool",school)
            })
            schoolsList = resp.response.rows.map(item => {
                let found = OptimizedSchool.find(data => data._id === item._id)
                if(found){
                    item["totalImpressions"] = found.impressions
                }
                return item
            })
            setSchoolList([...schoolsList])
            setFetchingSchoolForFirstTime(false)
            let pinCodeData = []
            resp.response.rows.forEach((school) => {
                if(school.city){
                    pinCodeData.push(school.city)
                }
            })
            console.log("getAllSchools", resp)
            setPincodeList(pinCodeData)
            let total = Math.ceil(Number(resp.response.count)/dataPerPage)
            let arr = []
            for(let i=0; i<total; i++){
                arr.push(i)
            }
            setTotalPage([...arr])
            setIsPageLoading(false)
        }
        setIsPageLoading(false)
    }
    const handleSearch = async (e) => {
        if(e === ""){
            setSearchValue("")
        }
        else{
            setSearchValue(e.target.value)
        }
    }
    const openFilter = () => {
        setShowBoardFilter(false)
        setShowCityFilter(false)
        setShowSchoolTypeFilter(false)
        setShowLanguageFilter(false)
        setShowFilterModal(!showFilterModal)
    }
    const showFilterDropDown = (type) => {
        if(type === "state"){
            setShowStateFilter(!showStateFilter)
            setShowBoardFilter(false)
            setShowLanguageFilter(false)
            setShowSchoolTypeFilter(false)
        }
        if(type === "city"){
            setShowBoardFilter(false)
            setShowLanguageFilter(false)
            setShowSchoolTypeFilter(false)
            setShowCityFilter(!showCityFilter)
        }
        else if(type === "board"){
            setShowStateFilter(false)
            setShowBoardFilter(!showBoardFilter)
            setShowLanguageFilter(false)
            setShowSchoolTypeFilter(false)
            setShowCityFilter(false)
        }
        else if(type === "language"){
            setShowStateFilter(false)
            setShowBoardFilter(false)
            setShowLanguageFilter(!showLanguageFilter)
            setShowSchoolTypeFilter(false)
            setShowCityFilter(false)
        }
        else if(type === "schoolType"){
            setShowStateFilter(false)
            setShowBoardFilter(false)
            setShowLanguageFilter(false)
            setShowSchoolTypeFilter(!showSchoolTypeFilter)
            setShowCityFilter(false)
        }
    }
    const clearAllFilter = () => {
        setFilterBySchoolType([])
        setFilterByBoard([])
        setFilterByCity([])
        setFilterByLanguage([])
        setSelectedState([])
        setShowStateFilter(false)
        setShowFilterModal(false)
        setShowBoardFilter(false)
        setShowLanguageFilter(false)
        setShowSchoolTypeFilter(false)
        setShowCityFilter(false)
    }
    return (
        <>
            <div className={`ovelap`}></div>
            {/* <TopBar isDashboard={true} /> */}
            <div className="col-xl-10 col-lg-12 col-md-12 col-xs-12">
                <div className="rightPart noBack">
                <div className="row">
                <div className="col-xs-12 col-lg-12 col-md-12 col-xs-12">
                           <BrandBackBtn
                                link="/"
                                text="Log Out"
                            />
                        </div>
                    <BrandTopBar schoolListTab={true} />
                    <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-30"></div>
                        <div className="col-xl-9 col-lg-12 col-md-12 col-xs-12">
                            <div className="row">
                                <div className="col-xl-6 col-lg-3 col-md-3 col-xs-12 mb-10 pl">
                                    <CustomSearchInput
                                        searchInpuOpen={searchInpuOpen}
                                        setSearchInpuOpen={setSearchInpuOpen}
                                        clearSearchIcon={clearSearchIcon}
                                        setClearSearchIcon={setClearSearchIcon}
                                        searchKeys={searchValue}
                                        handleChange={handleSearch}
                                        palaceholder="Search"
                                    />
                                </div>
                                <div className="col-xl-6 col-lg-9 col-md-9 col-xs-12 mb-10 pr">
                                    <div className="fR filterbox">
                                        <a href="javascript:void(0);" className="filterDrop" onClick={openFilter}>
                                            <img src={filterSvg} />
                                            <h3>Filter</h3>
                                            <img src={filterArrowSvg} />
                                        </a>
                                        <div className={`filterDropDown ${showFilterModal === true && "open"}`}>
                                            <div className="filterDropHead" ref={filterRef}>
                                                <h2>Filter <a href="javascript:void(0);" className="viewwLink" onClick={clearAllFilter}>Clear All</a></h2>
                                            </div>
                                            <div className="filterDropBody" ref={filterOptionsRef}>
                                                <div className={`filterDropClose ${showStateFilter === true && "open"}`}>
                                                    <h3><a href="javascript:void(0);" onClick={() => showFilterDropDown("state")}>{`State ${selectedState.length > 0 ? `(${selectedState.length})` : ""}`}  <img src={filterArrowSvg} /></a></h3>
                                                    <div className="filtercheckbox">
                                                        {stateList.map((item, index) => (
                                                            <div className="checkbox fnone" key={index} >
                                                                <input type="checkbox" id={item} checked={selectedState.includes(item)} onClick={(e) => handleAddRemoveFilter("state", item)}/>
                                                                <label htmlFor={item}>{titleCase(item)}</label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                                <div className={`filterDropClose ${showCityFilter === true && selectedState.length !== 0 && "open"}`}>
                                                    <h3><a href="javascript:void(0);" onClick={() => showFilterDropDown("city")}>{`City ${filterByCity.length > 0 ? `(${filterByCity.length})` : ""}`}  <img src={filterArrowSvg} /></a></h3>
                                                    <div className="filtercheckbox">
                                                        {cityList.map((item, index) => (
                                                            <div className="checkbox fnone" key={index} >
                                                                <input type="checkbox" id={item} checked={filterByCity.includes(item)} onClick={() => handleAddRemoveFilter("city", item)}/>
                                                                <label htmlFor={item}>{titleCase(item)}</label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                                <div className={`filterDropClose ${showSchoolTypeFilter === true && "open"}`}>
                                                    <h3><a href="javascript:void(0);" onClick={() => showFilterDropDown("schoolType")}>{`Gender ${filterBySchoolType.length > 0 ? `(${filterBySchoolType.length})` : ""}`}  <img src={filterArrowSvg} /></a></h3>
                                                    <div className="filtercheckbox">
                                                        <div className="checkbox fnone">
                                                            <input type="checkbox" id="male" checked={filterBySchoolType.includes("boys")} onClick={() => handleAddRemoveFilter("schoolType", "boys")}/>
                                                            <label htmlFor="male">Boys</label>
                                                        </div>
                                                        <div className="checkbox fnone">
                                                            <input type="checkbox" id="female" checked={filterBySchoolType.includes("girls")} onClick={() => handleAddRemoveFilter("schoolType", "girls")}/>
                                                            <label htmlFor="female">Girls</label>
                                                        </div>
                                                        <div className="checkbox fnone">
                                                            <input type="checkbox" id="co-ed" checked={filterBySchoolType.includes("co-ed")} onClick={() => handleAddRemoveFilter("schoolType", "co-ed")}/>
                                                            <label htmlFor="co-ed">Co-ed</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={`filterDropClose ${showBoardFilter === true && "open"}`}>
                                                    <h3><a href="javascript:void(0);" onClick={() => showFilterDropDown("board")}>{`Education Board ${filterByBoard.length > 0 ? `(${filterByBoard.length})` : ""}`}  <img src={filterArrowSvg} /></a></h3>
                                                    <div className="filtercheckbox">
                                                        {config.boardOptions.filter((item) => item.value.toLowerCase() !== "custom" && item.value.toLowerCase() !== "other").map((item, index) => (
                                                            <div className="checkbox fnone" key={index}>
                                                                <input type="checkbox" id={item.value} checked={filterByBoard.includes(item.value)} onClick={() => handleAddRemoveFilter("board", item.value)}/>
                                                                <label htmlFor={item.value}>{item.label}</label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                                <div className={`filterDropClose ${showLanguageFilter === true && "open"}`}>
                                                    <h3><a href="javascript:void(0);" onClick={() => showFilterDropDown("language")}>{`Language of Study ${filterByLanguage.length > 0 ? `(${filterByLanguage.length})` : ""}`}  <img src={filterArrowSvg} /></a></h3>
                                                    <div className="filtercheckbox">
                                                        {config.languages.filter((item) => item.value.toLowerCase() !== "custom").map((item, index) => (
                                                            <div className="checkbox fnone" key={index}>
                                                                <input type="checkbox" id={item.value} checked={filterByLanguage.includes(item.value)} onClick={() => handleAddRemoveFilter("language", item.value)}/>
                                                                <label htmlFor={item.value}>{item.label}</label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-20 pl pr">
                                    <div className="table-container mt-10 addAdmininput fillterTableHeight">
                                    <table className="table going-campaign tableSpacingtd">
                                        <thead>
                                            <tr>
                                                <th>SR No</th>
                                                <th>School Name</th>
                                                <th>reach</th>
                                                <th>impression </th>
                                                <th>Gender Split (B/G)</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {!isPageLoading ?
                                                schoolList.length !== 0 ? schoolList.map((data: any, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{((currentPage - 1) * dataPerPage) + (index + 1)}</td>
                                                            <td>
                                                                <div className="schoolNameTable">
                                                                    <h4>{data.schoolName && titleCase(data.schoolName)}</h4>
                                                                    <span>{typeof(data.board) === "string" ? data.board.toUpperCase() : data.board.board.toUpperCase()}</span>
                                                                    {/* <span>{titleCase(data.city)}</span> */}
                                                                    <span>{titleCase(data.parentCity ? data.parentCity : "")}</span>
                                                                    {/* {data.localArea && <span>{data.localArea}</span>} */}
                                                                </div>
                                                            </td>
                                                            <td>{data.totalReach || "TBC"}</td>
                                                            <td>{data.totalImpressions || "TBC"}</td>
                                                            <td>{data.boysRatio === 0 && data.girlsRatio === 0 ? "TBC" : data.boysRatio !== undefined && data.boysRatio !== null && data.girlsRatio !== undefined && data.girlsRatio !== null ? `${data.boysRatio}% / ${data.girlsRatio}%` : "TBC"}</td>
                                                        </tr>
                                                    )

                                                })
                                                :
                                                <tr><td colSpan={5}>No School found</td></tr>
                                                :
                                                [...Array(10)].map((el, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            {
                                                                [...Array(5)].map((el, index) => {
                                                                    return (
                                                                        <td key={index}>
                                                                            <div className="content-loader-block"></div>
                                                                        </td>
                                                                    )
                                                                })
                                                            }
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table> 
                                    </div>
                                    {totalPage.length > 1 &&
                                        <Pagination
                                            currentPage={currentPage}
                                            totalPage={totalPage}
                                            onPageChange={onPageChange}
                                            onNextClick={onNextClick}
                                            onPrevClick={onPrevClick}
                                            loading={isPageLoading}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-12 col-xs-12 mb-10 mt-50 pr">
                            <div className="box-design mt-20">
                            <h2 className="htx3 clearfix mb-10">Location</h2>
                                {isScriptLoaded && isScriptLoadSucceed &&
                                    <MultiPointMap
                                        pincodeList={pincodeList}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </>
    )
}

// export default School
export default scriptLoader(
    [
        'https://maps.googleapis.com/maps/api/js?key=' + config.GOOGLE_MAPS_API_KEY
    ],
)(School)
