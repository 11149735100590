import React from "react"

const DataContext = React.createContext({
    data: {
        brandDetail: {
            brandId: "",
            name: "",
            startDate: "",
            endDate: "",
            description: "",
            objective: "",
            inventory: false,
            eventAndOccations: false,
            csr: false,
            sampling: false,
            custom: false,
            targetGender: "both",
            targetAgeGroup: {min: 3, max: 4},
            studyMedium: [],
            brandCategory: {value: "", label: ""},
            brandSubcategory: {value: "", label: ""},
            brandName: "",
            isTrial: false
        },
        algoData :{
            classroomData: [],
            inventoryData: [],
            eventData: [],
            schoolData: [],
            noOfDaysInYear: 0,
            percentageDiscountInWISR: 0,
            percentageIncreaseInReach: 0,
            totalInternalCostPerSchool: 0,
            budgetRatio: { CatA: 0, CatB: 0, CatC: 0 }
        },
        campaignDetail: {
            wisrOption: false,
            totalImpression: 0,
            totalReach: 0,
            totalBudget: 0,
            boysRatio: 0,
            girlsRatio: 0
        },
        campaignCreative:{
            creatives: {image1: {}, image2: {}, image3: {}}
        },
        inventory: {
            inventories: [],
            notUsedInventories: [],
            usedInventories: []
        },
        school: {
            schools: [],
            locations: []
        },
        eventAndOccation: {
            eventAndOccations: []
        },
        schoolListDetail: [],
        general: {
            companyName: "",
            companyCategory: "",
            companyWebsite: "",
            socialMedia: {
                twitter: "",
                linkedIn: "",
                other: []
            }
        },
        companyDescription: {
            description: ""
        },
        contact: {
            country: "",
            state: "",
            city: "",
            address: "",
            pincode: "",
            mobileNo: ""
        },
        verification: {
            gst: {
                image: "",
                documentNo: ""
            },
            pan: {
                image: "",
                documentNo: ""
            }
        },
        users: [
            {
                memberName: "",
                memberEmail: "",
                memberDesignation: ""
            },
        ],
        removeUserIds: []
    },
    setData: () => {},
    setShowSidemenu: () => {}
})

export const DataContextProvider = DataContext.Provider

export default DataContext