import React, { useState, useEffect, useCallback } from 'react'
import Cropper from 'react-easy-crop'
import { dataURLtoFile, getCroppedImg } from '../../service/canvasUtils'

const ImageCroper = ({imageFile, setOverlay, showModal, setShowModal, setLogo}) => {
    const [imageSrc, setImageSrc] = useState(null)
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [rotation, setRotation] = useState(0)
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [croppedImage, setCroppedImage] = useState(null)
    const [imageName, setImageName] = useState("")

    useEffect(() => {
      if(imageFile){
          createImageUrl(imageFile)
          setImageName(imageFile.name)
      }
    }, [imageFile])

    const createImageUrl = async(imageFile) => {
        let imageDataUrl = await readFile(imageFile)
        setImageSrc(imageDataUrl)
    }
    const readFile = (file) => {
        return new Promise((resolve) => {
          const reader = new FileReader()
          reader.addEventListener('load', () => resolve(reader.result), false)
          reader.readAsDataURL(file)
        })
      }

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    const showCroppedImage = useCallback(async () => {
        try {
          const croppedImage = await getCroppedImg(
            imageSrc,
            croppedAreaPixels,
            rotation
          )
          // console.log('donee', { croppedImage })
          setCroppedImage(croppedImage)
          let file = dataURLtoFile(croppedImage, imageName)
          if(file){
            setLogo(file)
          }
          setOverlay(false)
          setShowModal(false)
          setImageSrc(null)
          setCroppedImage(null)
          setCrop({x:0, y:0})
          setZoom(1)
          // console.log(dataURLtoFile(croppedImage, imageName))
          // setLogo(croppedImage)
        } catch (e) {
          console.error(e)
        }
      }, [imageSrc, croppedAreaPixels, rotation])

      // const onClose = useCallback(() => {
      //   setCroppedImage(null)
      // }, [])
      useEffect(() => {
        if(imageSrc){
          setOverlay(true)
        }
      }, [imageSrc])
    const closeModal = () => {
      setOverlay(false)
      setShowModal(false)
      setImageSrc(null)
      setCroppedImage(null)
      setCrop({x:0, y:0})
      setZoom(1)
    }
    // useEffect(() => {
    //   console.log(croppedImage)
    // }, [croppedImage])
    return (
      <div
          id='cropImageModal'
          className={`popup ${showModal === false ? "hide" : ""}`}
      >
        <div className='popup-header'>
            <span
                onClick={closeModal}
                className='close toggle'
                data-target='amenitiesModal'
                >
            close
            </span>
        </div>
        <div className='popup-body'>
            {imageSrc && (
              <div className='cropImg'>
                  <Cropper
                      image={imageSrc && imageSrc}
                      crop={crop}
                      rotation={rotation}
                      zoom={zoom}
                      aspect={4 / 3}
                      onCropChange={setCrop}
                      onCropComplete={onCropComplete}
                      onZoomChange={setZoom}
                  />
              </div>
            )}
            
        </div>
        <div className='popup-footer'>
            <div className='cropBtn'>
                <button className='btn btn-orange' onClick={showCroppedImage}>Save</button>
                <button className='btn btn-outline' onClick={closeModal}>cancel</button>
            </div>
        </div>
      </div>
    )
}

export default ImageCroper
