import React, { useState, useEffect, useContext, createRef } from 'react'
import { Link, useHistory } from 'react-router-dom'
import MultiPointMap from "../../../../../components/addon/multiPointMap"
import scriptLoader from 'react-async-script-loader'
import uploadSvg from "../../../../../assets/images/upload-svg.svg"
import sucessCheck from "../../../../../assets/images/sucess-check.svg"
import closeSvg from "../../../../../assets/images/close-svg.svg"
import blockSvg from "../../../../../assets/images/block-svg.svg"
import reloadSvg from "../../../../../assets/images/reload-svg.svg"
import filterSvg from "../../../../../assets/images/filter-svg.svg"
import filterArrowSvg from "../../../../../assets/images/filter-arrow-svg.svg"
import mapsImg from "../../../../../assets/images/maps.jpg"
import AppContextObject from '../../../../../common/context/common'
import DataContext from '../../../../../service/backendContext'
import Pagination from '../../../../../components/addon/pagination'
import CampaignBudget from '../../../brands/components/campaignBudget'
import GenderSplit from '../../../brands/components/genderSplit'
import CampaignReach from '../../../brands/components/campaignReach'
import CampaignImpression from '../../../brands/components/campaignImpression'
import config from '../../../../../service/config'
import { titleCase } from '../../../../../service/helper'

const CampaignRecomendedSchool = ({setShowProgressSideMenu, isScriptLoaded, isScriptLoadSucceed}) => {
    const history = useHistory()
    const context = useContext(AppContextObject)
    const parentData = useContext<any>(DataContext)
    const [tableEditMode, setTableEditMode] = useState(false)
    const [recommendedSchoolList, setRecommendedSchoolList] = useState([])
    const [allSchoolList,setAllSchoolList] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPage, setTotalPage] = useState([0])
    const [schoolRef, setSchoolRef] = useState([])
    // const [reach, setReach] = useState(parentData?.data?.campaignDetail?.totalReach)
    // const [impression, setImpression] = useState(parentData?.data?.campaignDetail?.totalImpression)
    const [totalBudget, setTotalBudget] = useState(parentData?.data?.campaignDetail?.totalBudget)
    const [totalReach, setTotalReach] = useState(parentData?.data?.campaignDetail?.totalReach)
    const [boysRatio, setBoysRatio] = useState(parentData?.data?.campaignDetail?.boysRatio)
    const [girlsRatio, setGirlsRatio] = useState(parentData?.data?.campaignDetail?.girlsRatio)
    const [totalImpression, setTotalImpression] = useState(parentData?.data?.campaignDetail?.totalImpression)
    const [formData, setFormData] = useState(parentData?.data?.school)
    const [pinCodeList, setPinCodeList] = useState([])
    const [overlay, setOverlay] = useState(false)
    const [paginationSchool, setPaginationSchool] = useState([])
    const [dataPerPage] = useState(10)
    useEffect(() => {
        setShowProgressSideMenu(true)
        parentData.setActiveModule({
            module: "campaignPlacement",
            subModule:'school'
        })
    }, [])
    useEffect(() => {
        let cities = []
        formData.schools.forEach(item => {
            if(item.schoolDetails.city){
                cities.push(item.schoolDetails.city)
            }
        })
        // parentData.data.brandDetail.location.forEach((item) => {
        //     cities.push(item)
        // })
        setPinCodeList([...cities])
    }, [])
    const nextPage = () =>{
        history.push('/backend/backend-details/view-campaign/preview')
    }
    useEffect(() => {
        setPaginationSchool([...parentData.data.school.schools.slice(0, dataPerPage)])
        let page = Math.ceil(parentData.data.school.schools.length/dataPerPage)
        let arr = []
        for(let i=0; i<page; i++){
            arr.push(i)
        }
        setTotalPage([...arr])
    }, [])
    const onNextClick = () => {
        let schools = parentData.data.school.schools.slice(((currentPage + 1 - 1) * dataPerPage), ((currentPage + 1) * dataPerPage))
        console.log("nextSchool", schools)
        setCurrentPage(prev => prev + 1)
        setPaginationSchool([...schools])
    }
    const onPrevClick = () => {
        let schools = parentData.data.school.schools.slice(((currentPage - 1 - 1) * dataPerPage), ((currentPage - 1) * dataPerPage))
        console.log("prevSchool", schools)
        setCurrentPage(prev => prev - 1)
        setPaginationSchool([...schools])
    }
    const onPageChange = (page) => {
        let schools = parentData.data.school.schools.slice(((page - 1) * dataPerPage), ((page) * dataPerPage))
        setCurrentPage(page)
        console.log("pageschool", schools)
        setPaginationSchool([...schools])
    }
    // const onPageChange = (page) => {
    //     setCurrentpage(page)
    // }
    // const onNextClick = () => {
    //     setCurrentpage(prev => prev + 1)
    // }
    // const onPrevClick = () => {
    //     setCurrentpage(prev => prev - 1)
    // }
    return (
        <>
            <div className={`ovelap ${overlay === true && "active"}`}></div>
            {/* <TopBar isDashboard={true} /> */}
            <div className="col-xl-9 col-lg-9 col-md-12 col-xs-12">
            <div className="rightPart noBack">
                <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-20"><h2 className="htx1 clearfix mt-10 mb-10">Create a Campaign</h2></div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-30">
                    <h2 className="htx2 clearfix mb-10">{parentData?.data?.campaignDetail?.wisrOption ? "Recommended Schools" : "Partner Schools"} <b>{`(${formData.schools.length})`}</b></h2>
                </div>
                   <div className="col-xl-8 col-lg-8 col-md-12 col-xs-12 mb-20">
                        <div className="row">
                            {/* <div className="col-xl-6 col-lg-3 col-md-12 col-xs-12 mb-10 pl">
                                <div className="searchinput">
                                    <a href="javascript:void(0);">
                                        <img src="/static/media/search.a6944e87.svg" />
                                    </a>
                                    <input type="text" placeholder="Search" value="" />
                                </div>
                            </div> */}
                            {/* <div className="col-xl-6 col-lg-3 col-md-12 col-xs-12 mb-10 pr">
                                <div className="fR filterbox">
                                    <a href="javascript:void(0);" className="filterDrop">
                                        <img src={filterSvg} />
                                        <h3>Filter</h3>
                                        <img src={filterArrowSvg} />
                                    </a>
                                </div>
                            </div> */}
                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-20 pl pr">
                                <div className="table-container mt-10 addAdmininput">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            
                                                {/* <th scope="col">
                                                    <div className="checkbox fnone">
                                                        <input type="checkbox" id="schools1" disabled/>
                                                        <label htmlFor="schools1"></label>
                                                    </div>
                                                </th> */}
                                            
                                            <th scope="col">SR no</th>
                                            <th scope="col">School name</th>
                                            {/* {!tableEditMode && <th scope="col"><a href="javascript:void(0);" className="viewwLink proEdit" onClick={() => setTableEditMode(true)}><img src="/static/media/edit-profile.7459840c.svg" /> Edit</a></th>} */}
                                            {/* <th scope="col">Reach</th>
                                            <th scope="col">Impression</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {paginationSchool && paginationSchool.length > 0 && paginationSchool.map((school, index) => (
                                            
                                            <tr key={school._id}>
                                                {/* <td>
                                                    <div className="checkbox fnone">
                                                        <input type="checkbox" id={school.schoolDetails._id} checked 
                                                        // onClick={(e) => addRemoveSchool(e, school._id)}
                                                        />
                                                        <label htmlFor={school.schoolDetails._id}></label>
                                                    </div>
                                                </td> */}
                                                <td>{((currentPage - 1) * dataPerPage)+(index + 1)}</td>
                                                <td>
                                                    <div className="schoolNameTable">
                                                        <h4>{titleCase(school.schoolDetails.schoolName)}</h4>
                                                        <span>{typeof(school.schoolDetails.board) === "string" ? school.schoolDetails.board.toUpperCase() : school.schoolDetails.board.board.toUpperCase()}</span><span>{titleCase(school.schoolDetails.city)}</span>{school.schoolDetails.localArea && <span>{titleCase(school.schoolDetails.localArea)}</span>}
                                                    </div>
                                                </td>
                                                {/* {tableEditMode &&  */}
                                                    {/* <td>
                                                        {school.schoolDetails.totalReach || "-"}
                                                    </td> */}
                                                {/* } */}
                                                {/* {tableEditMode &&  */}
                                                    {/* <td>
                                                        {school.schoolDetails.impression || "-"}
                                                    </td> */}
                                                {/* } */}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                </div>
                                {totalPage.length > 1 &&
                                    <Pagination
                                        currentPage={currentPage}
                                        totalPage={totalPage}
                                        onPageChange={onPageChange}
                                        onNextClick={onNextClick}
                                        onPrevClick={onPrevClick}
                                        loading={false}
                                    />
                                }
                            </div>
                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 pl pr"></div>
                            <div className="col-xl-5 col-lg-5 col-md-12 col-xs-12 delete-user mt-30">
                            <Link className="btn btn-orange quit-btn" to={{
                                        pathname: "/backend/backend-details/create-trial-campaign-details",
                                        state: { campaignType: parentData.data.isTrial ? 'trial' : 'create', from: parentData.data.from, campaignId: parentData.data.campaignId }
                                    }}>QUIT</Link>
                                </div>
                            <div className="col-xl-7 col-lg-7 col-md-12 col-xs-12 end-md mt-30">
                                <div className="forminpt">
                                    <input name="next" className="btn btn-orange" type="button" value="next" 
                                    onClick={nextPage}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-12 col-xs-12 mb-20">
                        <div className="w90">
                            <div className="box-design mb-20 overheadThree">
                                <h2 className="htx3 clearfix mb-10">Campaign Location</h2>
                                {isScriptLoaded && isScriptLoadSucceed &&
                                    <MultiPointMap
                                        pincodeList={pinCodeList}
                                    />
                                }
                            </div>
                            <CampaignBudget
                                totalBudget={totalBudget}
                                setTotalBudget={setTotalBudget}
                                setOverlay={setOverlay}
                                hideEdit={true}
                            />
                            <GenderSplit
                                girlsRatio={girlsRatio}
                                boysRatio={boysRatio}
                                // disable={true}
                            />
                            <CampaignReach
                                totalReach={totalReach}
                                setTotalReach={setTotalReach}
                                disable={true}
                            />
                            <CampaignImpression
                                totalImpression={totalImpression}
                                setTotalImpression={setTotalImpression}
                                disable={true}
                            />
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </>
    )
}

export default scriptLoader(
    [
        'https://maps.googleapis.com/maps/api/js?key=' + config.GOOGLE_MAPS_API_KEY
    ],
)(CampaignRecomendedSchool)