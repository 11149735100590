import { useState, useContext, useRef, useEffect } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { validation } from "../../../../service/validators"

import TextFieldWithIcon from "../../../../components/formFields/textfieldWithIcon"
import OnboardingBackBtn from "../../schools/components/onboardingBackBtn"

import loginWave from "../../../../assets/images/login-wave.svg"
import envelope from "../../../../assets/images/envolpe.svg"
import logo from "../../../../assets/images/logo.png"

import AppContextObject from "../../../../common/context/common"
import TextFieldWithPrefix from "../../../../components/formFields/textFieldWithPrefix/textFieldWithPrefix"
import mobile from "../../../../assets/images/phone-icon.svg"
import toast from "react-hot-toast"
import SimpleReactValidator from "simple-react-validator"
import errorSVG from "../../../../assets/images/error.svg";
import DataContext from "../../../../service/brandContext"
import { PublicContextInitiator } from "../../../apis/publicContextInitiator"
import OTPVerifyModal from "../../../../components/addon/otpVerifyModal"
import AlertDialog from "../../../../components/addon/alertDialog/alertDialog"
import username from "../../../../assets/images/user-icon.svg"
import { objectDeepClone, specialCharOnly } from "../../../../service/helper"
import Loader from "../../../../components/addon/loader"

interface Props {
    // fullName? : any
    // companyName? : any
    // designation? : any
    // role? : any
}
const ContactDetails = (props: Props) => {

    // const {
    //     fullName,
    //     companyName,
    //     designation,
    //     role
    // } = props
    const location = useLocation()
    const history = useHistory()
    const context = useContext(AppContextObject)
    const parentData = useContext<any>(DataContext)
    const [formData, setFormData] = useState({
        email: '',
        phone: '',
        username: ""
    })
    const [userData, setUserData] = useState({
        fullName: "",
        companyName: "",
        designation: "",
        role : ""
    })
    const [isMobileOTP, setIsMobileOTP] = useState(false);
    const [selectedContactForOTP, setSelectedContactForOTP] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [isPhoneVerified, setIsPhoneVerified] = useState(false);
    const [getVerifyPhoneStatus, setGetVerifyPhoneStatus] = useState(false);
    const [getVerifyEmailStatus, setGetVerifyEmailStatus] = useState(false);
    const [isEmailVerified, setIsEmailVerified] = useState(false);
    const [update, forceUpdate] = useState(false)
    const [msgDetails, setMsgDetails] = useState('');
    const [overlay, setOverlay] = useState(false)
    const [toggleAlertPopUp, setToggleAlertPopUp] = useState(false);
    let publicContextInitiator = new PublicContextInitiator(context)
    const [detailsCaptured, setDetailsCaptured] = useState('');
    const [getFieldInfo, setGetFieldInfo] = useState('');
    const [usernameError, setUsernameError] = useState(false)
    const [isUsernameVerified, setIsUsernameVerified] = useState(false);
    const [getVerifyUsernameStatus, setGetVerifyUsernameStatus] = useState(false);
    const [inProgressEmail, setInProgressEmail] = useState(false)
    const [inProgressPhone, setInProgressPhone] = useState(false)
    const [currentEmail, setCurrentEmail] = useState("")
    const [currentPhone, setCurrentPhone] = useState("")
    const [formValidator] = useState(useRef(new SimpleReactValidator({
        element: (message, className) =>
            <span className="valError">
                <img src={errorSVG} />{
                    message === "The phone field is required."
                        ? "The mobile no field is required."
                        :
                        message === "The phone must be a valid phone number."
                        ?
                        "The mobile no must be a valid mobile no."
                        :
                        message
                }
            </span>
    })))
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        console.log("parent data...", parentData)
        let data = JSON.parse(sessionStorage.getItem("contactDetails"))
        if(!data){
            history.push("/brands/sign-up")
        }
    },[history.location.pathname])
    useEffect(() => {
      let oldFormData = sessionStorage.getItem("contactVerification")
      if(oldFormData){
          let data = JSON.parse(oldFormData)
          setFormData({
            email: data.email,
            phone: data.phone,
            username: data.username
          })
        setIsPhoneVerified(true)
        setGetVerifyPhoneStatus(true)
        setIsUsernameVerified(true)
        setGetVerifyUsernameStatus(true)
        setIsEmailVerified(true)
        setGetVerifyEmailStatus(true)
      }
    }, [])
    useEffect(() => {
        window.addEventListener("beforeunload", handleBeforeUnload)
        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload)
        }
    }, [])

    const handleBeforeUnload = (e) => {
        e.preventDefault()
        window.addEventListener("unload", function(){
            // deleteUnsavedUser(unsavedUser)
            sessionStorage.removeItem("userContactDetail")
            sessionStorage.removeItem("contactVerification")
            sessionStorage.removeItem("apiData")
            sessionStorage.removeItem("reVerify")
            sessionStorage.removeItem("contactDetails")
        })
        setTimeout(function() {
            setTimeout(function() {
                // sessionStorage.removeItem("imageKeys")
                // sessionStorage.removeItem("userIds")
            }, 1000);
        },1);
        const message = "Are you sure you want to reload the page? All provided data will be lost."
        e.returnValue = message
        return message
    }
    
    const onFormDataChange = (event) => {
        // setFormData({
        //     ...formData,
        //     [event.target.id]: event.target.value
        // })
        if (event.target.id === "username") {
            setUsernameError(false)
            setFormData({
                ...formData,
                username: event.target.value.toLowerCase()
            })
            if (formData.username != event.target.value) {
                if (event.target.parentNode.querySelectorAll(".vrifiyotp")[0].className.indexOf("complete") >= 0) {
                    event.target.parentNode.querySelectorAll(".vrifiyotp")[0].className = event.target.parentNode.querySelectorAll(".vrifiyotp")[0].className.replace(/ complete/gi, "");
                    setIsUsernameVerified(false)
                    setGetVerifyUsernameStatus(false)
                }
            }
        }
        if (event.target.id == "phone") {
            if (formData.phone != event.target.value) {
                if (event.target.parentNode.querySelectorAll(".vrifiyotp")[0].className.indexOf("complete") >= 0) {
                    event.target.parentNode.querySelectorAll(".vrifiyotp")[0].className = event.target.parentNode.querySelectorAll(".vrifiyotp")[0].className.replace(/ complete/gi, "");
                    setIsPhoneVerified(false)
                    setGetVerifyPhoneStatus(false)
                }
                if (currentPhone === event.target.value && currentPhone != ""){
                    setIsPhoneVerified(true)
                }
            }
        }
        if (event.target.id == "email") {
            if (formData.email != event.target.value) {
                if (event.target.parentNode.querySelectorAll(".vrifiyotp")[0].className.indexOf("complete") >= 0) {
                    event.target.parentNode.querySelectorAll(".vrifiyotp")[0].className = event.target.parentNode.querySelectorAll(".vrifiyotp")[0].className.replace(/ complete/gi, "");
                    setIsEmailVerified(false)
                    setGetVerifyEmailStatus(false)
                }
                if (currentEmail === event.target.value && currentEmail != ""){
                    setIsEmailVerified(true)
                }
            }
        }

        setFormData({ ...formData,
            [event.target.id]: event.target.value
        })
        if(event.target.id == "username"){
            setFormData({
                ...formData,
                username: event.target.value.toLowerCase().trim()
            })
        }
    }

    let error = ""
    formValidator.current.rules['user_name'] = {
        message: error === "required" ? 'The user name field is required.' : usernameError ? "Username is already exists." : usernameError === null ? `${formData.username} username is been taken couple of seconds ago. Try another?` : usernameError === undefined ? 'Username must be minimum 4 characters long.' : 'Username is invalid, Username must contain letters (a-z), numbers and spe. chars(_@.).',
        rule: (val) => {
            let returnValue = true
            if (!val || val.trim() === "") {
                error = "required"
                returnValue = false
            }
            if(val.trim().length < 4){
                error = ""
                setUsernameError(undefined)
                returnValue = false
            }

            const chars = "!#$%^&*()-{}[]+=<>?/,~'`"
            for (let i = 0; i < val.split("").length; i++) {
                const v = val.split("")[i]
                if (!v || v.trim() === " " || chars.split("").find(ch => ch === v)) {
                    error = ""
                    returnValue = false
                    break
                }
            }

            if (usernameError) {
                error = ""
                returnValue = false
            }

            if (usernameError === null) {
                error = ""
                returnValue = false
            }

            if (usernameError === undefined) {
                error = ""
                returnValue = false
            }

            return returnValue
        },
    }
    formValidator.current.rules['valid_user_name'] = {
        message: 'Username can not contain only special character.',
        rule: (val) => {
            let returnValue = true
            if(!specialCharOnly(val)){
                returnValue = false
            }
            return returnValue
        }
    }
    const onVerifyMobileClick = async () => {
        const result = formValidator.current.fieldValid("phone")
        if (result === false) {
            formValidator.current.showMessageFor("phone")
            forceUpdate(!update)
            return
        } else {
            formValidator.current.hideMessageFor("phone")
            forceUpdate(!update)
        }
        if (isPhoneVerified) {
            return
        }
        setInProgressPhone(true)
        let resp = await publicContextInitiator.sendOTPOnPhone(
            context.validator, { "phone": formData.phone }
        )

        if (!resp.response.userFound) {
            setIsMobileOTP(true)
            setSelectedContactForOTP(formData.phone)
            setShowModal(true)
            setGetVerifyPhoneStatus(true)
            setOverlay(false)
            setToggleAlertPopUp(false)
        }
        else {
            setGetFieldInfo("Phone No.");
            setDetailsCaptured(formData.phone);
            setGetVerifyPhoneStatus(false)
            setMsgDetails(`The Phone No.- ${formData.phone} - entered already exists. Kindly use a different Phone No. to register.`)
            setOverlay(true)
            setToggleAlertPopUp(true)
        }
    }
    const onModalSubmit = (otp) => {
        if(isMobileOTP) {
            setCurrentPhone(formData.phone)
            setIsPhoneVerified(true)
        }
        else {
            setCurrentEmail(formData.email) 
            setIsEmailVerified(true)
        }
    }
    const onVerifyUsernameClick = async () => {
        if (formData.username.length < 4) {
            setUsernameError(undefined)
            formValidator.current.showMessageFor("username")
            return
        }
        const result = formValidator.current.fieldValid("username")
        if (result === false) {
            formValidator.current.showMessageFor("username")
            forceUpdate(!update)
            return
        } else {
            formValidator.current.hideMessageFor("username")
            forceUpdate(!update)
        }

        // let err = validation({"username": userFormData.username})
        // setFormErrors(err)
        // if(Object.keys(err).length > 0) {
        //     setFormErrors({"username": "username is invalid"})
        //     return
        // }

        let resp = await context.apis.public.findUserByUsername(
            context.validator, { "username": formData.username }
        )
        if (resp && resp.done === false) {
            let found = resp.response.filter(element => element._id !== parentData.data.userId);
            if (found.length > 0) {
                formValidator.current.showMessageFor("username")
                setGetVerifyUsernameStatus(false)
                setIsUsernameVerified(false)
                setUsernameError(true)
                // setGetFieldInfo("Username");
                // setDetailsCaptured(formData.username)
                // setMsgDetails(`The Username- ${formData.username} - entered already exists. Kindly use a different username to register.`)
                // setOverlay(true)
                // setToggleAlertPopUp(true)
                return
            }
        }
        else {
            setGetVerifyUsernameStatus(true)
            setIsUsernameVerified(true)
            setToggleAlertPopUp(false)
        }
    }

    const onVerifyEmailClick = async() => {
        const result = formValidator.current.fieldValid("email")
        if(result === false) {
            formValidator.current.showMessageFor("email")
            forceUpdate(!update)
            return
        } else {
            formValidator.current.hideMessageFor("email")
            forceUpdate(!update)
        }

        if(isEmailVerified) {
            return
        }
        setInProgressEmail(true)
        let resp = await context.apis.public.sendOTPOnEmail(
            context.validator, {"email":formData.email}
        )
        console.log("sendOTPOnEmail ", resp)
            setIsMobileOTP(false)
            setSelectedContactForOTP(formData.email)
            setShowModal(true)
            setGetVerifyEmailStatus(true)
            setOverlay(false)
            setToggleAlertPopUp(false)

    }

    const onSubmit = async () => {
        const result = formValidator.current.allValid()
        if (result === false) {
            formValidator.current.showMessages()
            forceUpdate(true)
            return
        } else {
            formValidator.current.hideMessages()
            forceUpdate(!update)
        }
        if (isEmailVerified == false) {
            setMsgDetails('You need to verify your email to continue, Just click on verify to get OTP.')
            setOverlay(true)
            setToggleAlertPopUp(true)
            return
        }
        if (isPhoneVerified == false ) {
            setMsgDetails('You need to verify your mobile number to continue, Just click on verify to get OTP.')
            setOverlay(true)
            setToggleAlertPopUp(true)
            return
        }
        if (getVerifyUsernameStatus === false) {
            setMsgDetails('You need to verify your username to continue, Just click on verify to get verified.')
            setOverlay(true)
            setToggleAlertPopUp(true)
            return
        }
        // console.log("parent data...", parentData)
        // let data = parentData?.data?.personalDetails
        // Object.assign(data, {username: formData.username});
        // Object.assign(data, {email: formData.email});
        // Object.assign(data, {phone: formData.phone});

        // if (parentData) {
        //     const json = { ...formData }
        //     if (parentData.setData) {
        //         parentData.setData({
        //             ...parentData.data,
        //             personalDetails: objectDeepClone(data)
        //             // personalDetails: {
        //                 // email: formData.email,
        //                 // phone : formData.phone,
        //                 // username : formData.username,
        //             // }
        //         })
        //     }
        // }
        // console.log("parent data...", parentData)
        let role = ""
        if(userData.role === 'Brand Manager' || userData.role.toLocaleLowerCase() === 'other') {
            role = 'brandManager'
        }
        else if(userData.role == 'CMO') {
            role = 'brandCMO'
        }
        else if(userData.role == 'Cheif Head') {
            role = 'brandCH'
        }
        let users = [{
            memberName : userData.fullName,
            companyName : userData.companyName,
            designation : userData.designation,
            memberDesignation : role,
            memberEmail : formData.email,
            username : formData.username,
            phone : formData.phone
        }]

        let data = {
            logo: "",
            brandName: userData.companyName,
            brandType: "",
            website: "",
            documents: {
                gst: {
                    file: "",
                    number: "",
                    isVerified: false
                },
                pan: {
                    file: "",
                    number: "",
                    isVerified: false
                }
            },
            address: "",
            contactNumber: "",
            country: "",
            city: "",
            state: "",
            zip: "",
            description: "",
            attributes: [],
            socialMediaLinks: {
                twitter: "",
                linkedIn: "",
                other: []
            },
            users: users
        }
        console.log("addUserResp data",data)
        // setLoading(true)
        // let addUserResp = await publicContextInitiator.addBrandWithTeamMember(
        //     context.validator, data
        // ).catch(err => {setLoading(false)})

        // console.log("addUserResp>>>",addUserResp);
        // setLoading(false)
        // if (addUserResp && addUserResp.done) {
            // history.push("/brands/reset-password")
            sessionStorage.setItem("contactVerification", JSON.stringify(formData))
            history.push({
                pathname: '/brands/reset-password',
                state: {
                    username : formData.username,
                    apiData: data,
                    from: "contactDetails",
                    fromBrandSignup: true
                },
              });
            //   sessionStorage.removeItem("userContactDetail")
            //     sessionStorage.removeItem("contactVerification")
            //     sessionStorage.removeItem("apiData")
            //     sessionStorage.removeItem("reVerify")
            //     sessionStorage.removeItem("contactDetails")
        // }

    }
    const confirmClose = () => {
        setOverlay(false)
        setToggleAlertPopUp(false)
    }
    const onOTPModalClose = () => {
        setInProgressEmail(false);
        setInProgressPhone(false);
    }

    useEffect(() => {
        console.log(" Userdata history.location.state",history.location.state)
        if(history.location.state){
            const tempData: any = history.location.state
            console.log(" Userdata htempData",tempData)
            let data = {
                fullName : tempData.fullName,
                companyName : tempData.companyName,
                designation : tempData.designation,
                role : tempData.role
            }
            setUserData(data)
        }
        console.log("Userdata", userData)
    }, [])

    const handleOnKeyPress = (event) => {
        if (event.key.toLowerCase() === "enter") {
          console.log("event target..", event.target)
          if(event.target.id === "email" && isEmailVerified === false) {
            onVerifyEmailClick()
          }
          else if(event.target.id === "phone" && isPhoneVerified === false) {
            onVerifyMobileClick()
          }
         else if(event.target.id === "username" && isUsernameVerified === false) {
            onVerifyUsernameClick()
          }
          else {
            const form = event.target.form;
            const index = [...form].indexOf(event.target);
            form.elements[index + 1].focus();
            event.preventDefault();
            if(event.target.id === "username") {
                onSubmit()
            }
          }
        }
    }
    return (
        <>
            <div className={`ovelap ${(showModal === true || overlay === true) ? "active" : ""}`}></div>
            {
                loading
                    ?
                    <Loader />
                    :
                    <div className="main-wapper">
                        <div className="loginbox">
                            <div className="login-wave"> <img src={loginWave} alt="login-wave" /> </div>
                            <div className="login-form">
                                <div className="row">
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb30">
                                        <img src={logo} alt="logo" className="mb-40" />
                                        <div className="clearfix w100"></div>
                                        <OnboardingBackBtn link='/brands/sign-up' />
                                        <h2 className="htx1 mt-10">Contact Details</h2>
                                        <p className="ptx2">Enter the primary contact details for your company profile.</p>
                                    </div>
                                </div>
                                <form>
                                    <div className="row">
                                        <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                            <TextFieldWithIcon
                                                label="Email ID"
                                                placeholder="Eg. name@companyname.com"
                                                isRequired={false}
                                                icon={envelope}
                                                formErrors={formValidator}
                                                formData={formData}
                                                formKey="email"
                                                validation="required|email"
                                                onFormDataChange={onFormDataChange}
                                                onVerifyClick={onVerifyEmailClick}
                                                inProgress={inProgressEmail}
                                                isVerified={isEmailVerified}
                                                onKeyPress={(event) => handleOnKeyPress(event)}
                                                autoFocus={true}
                                            />
                                        </div>
                                        <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                            <TextFieldWithPrefix
                                                formData={formData}
                                                formKey='phone'
                                                onDataChange={onFormDataChange}
                                                label='Mobile No'
                                                placeholder='Eg. 9999999999'
                                                isRequired={false}
                                                isIconAndPhcode={true}
                                                icon={mobile}
                                                onVerifyClick={onVerifyMobileClick}
                                                isVerified={isPhoneVerified}
                                                formValidator={formValidator}
                                                inProgress={inProgressPhone}
                                                validation='required|phone'
                                                onKeyPress={(event) => handleOnKeyPress(event)}
                                            />
                                        </div>
                                        <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                            <TextFieldWithIcon
                                                label='Your Username'
                                                placeholder='Username'
                                                isRequired={false}
                                                icon={username}
                                                formErrors={formValidator}
                                                formData={formData}
                                                formKey="username"
                                                validation="required|user_name|valid_user_name"
                                                onFormDataChange={onFormDataChange}
                                                onVerifyClick={onVerifyUsernameClick}
                                                isVerified={isUsernameVerified}
                                                maxLength={16}
                                                onKeyPress={(event) => handleOnKeyPress(event)}
                                            />
                                        </div>
                                        <div className="row col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                            <div className="forminpt">
                                                <input
                                                    onClick={onSubmit}
                                                    name="Login"
                                                    value="Continue"
                                                    className="btn btn-orange datasubmit"
                                                    type="button"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
            }

            <OTPVerifyModal
                contact={selectedContactForOTP}
                isMobile={isMobileOTP}
                showModal={showModal}
                setShowModal={setShowModal}
                onModalSubmit={onModalSubmit}
                isMobileAndEmail={false}
                onClose={onOTPModalClose}
            />
            <AlertDialog
                togglePopup={toggleAlertPopUp}
                fieldInfo={getFieldInfo}
                details={detailsCaptured}
                onModalClose={confirmClose}
                displayMsg={msgDetails}
            />
        </>
    )
}

export default ContactDetails
