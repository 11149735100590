import { useContext } from "react"

import {
    useLocation,
    useRouteMatch,
    Link
} from "react-router-dom"

import DataContext from "../../../../service/backendContext"
import logo from "../../../.././assets/images/logo.png"
import logoNew from "../../../../assets/images/logo-new.svg"
import waveBootNew from "../../../.././assets/images/wavebotnew.png"
import check from "../../../../assets/images/check.svg"

const ViewCampaignProgress = () => {

    const parentData = useContext<any>(DataContext)
    const location = useLocation()
    const router = useRouteMatch()

    return (
        <>
            <div className="col-xl-3 col-lg-3 col-md-12 col-xs-12 pl-0 pr-0">
                <div className={`sliderbar onboarding-bar newMvp-sidebar backendCampaign`}>
                    <div className="row logo between-xs bottom-xs logoNew">
                     <img src={logoNew} alt="logo" />
                     </div>
                    {
                        <ul>
                            <li>
                                <Link
                                    to={`${router.url}/set-up-campaign`}
                                    className={
                                        `${parentData?.activeModule?.module === "setUpCampaign" && "active"}
                                        ${"complete"}`
                                    }
                                >
                                    <span>1</span> SET UP CAMPAIGN
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to={parentData.data.brandDetail.wisrOffering.inventory ? `${router.url}/inventory` : `${router.url}/events`}
                                    className={
                                        `${parentData.activeModule.module === "campaignPlacement" && "active"}
                                        ${"complete"}`
                                    }
                                >
                                    <span>2</span> CAMPAIGN PLACEMENT
                                </Link>
                                { parentData.activeModule.module === "campaignPlacement" &&
                                    <ul>
                                        {parentData.data.brandDetail.wisrOffering.inventory && 
                                            <li>
                                                <Link
                                                    to={`${router.url}/inventory`}
                                                    className={`${parentData.activeModule.subModule === "inventory" && "active"}
                                                    `}
                                                >
                                                    Inventory
                                                </Link>
                                            </li>
                                        }
                                        {parentData.data.brandDetail.wisrOffering.eventAndOccations && 
                                            <li>
                                            
                                                <Link
                                                    to={`${router.url}/events`}
                                                    className={`${parentData.activeModule.subModule === "events" && "active"}
                                                    `}
                                                >
                                                    Events
                                                </Link>
                                            
                                            </li>
                                        }
                                        <li>
                                            <Link
                                                to={`${router.url}/recommended-school`}
                                                className={`${parentData.activeModule.subModule === "school" && "active"}
                                                `}
                                            >
                                                Schools
                                            </Link>
                                        </li>
                                        {/* <li>
                                            <Link
                                                to={`${router.url}/recomended-school`}
                                                className={`${parentData.activeModule.subModule === "schools" && "active"}
                                                ${parentData?.progress?.school === true ? "" : "disabledLink"}
                                                `}
                                            >
                                                Schools
                                            </Link>
                                        </li> */}
                                    </ul>
                                }
                            </li>
                            {!parentData?.data?.brandDetail?.isTrial &&
                                <li>
                                    <Link
                                        to={`${router.url}/creative`}
                                        className={
                                            `${parentData.activeModule.module === "creative" && "active"}
                                            ${"complete disabledLink"}`
                                        }
                                    >
                                        <span>3</span> CAMPAIGN CREATIVE
                                    </Link>
                                </li>
                            }
                            <li>
                                <Link
                                    to={`${router.url}/preview`}
                                    className={
                                        `${parentData.activeModule.module === "preview" && "active"}
                                        ${"complete"}`
                                    }
                                >
                                    <span>{parentData?.data?.brandDetail?.isTrial ? 3 : 4}</span> CAMPAIGN PREVIEW
                                </Link>
                            </li>
                        </ul>
                    }
                    <img src={waveBootNew} className="wavebot" alt="wave" />
                </div>
            </div>
        </>
    )
}

export default ViewCampaignProgress
