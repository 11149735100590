import React, { useContext, useEffect } from 'react'
import { Route, Switch, useHistory } from 'react-router'
import AppContextObject from '../../../../common/context/common'
import ProfileAddUsers from './addUsers'
import ProfileContact from './contact'
import ProfileDescription from './description'
import ProfileGeneral from './general'
import BrandProfileSidemenu from './sidemenu'
import ProfileVerification from './verification'
import DataContextProvider  from "../../../../service/brandContext"

function CreateBrand(props) {
    const {
        progress,
        activeModule,
        router,
        setProgress,
        setActiveModule,
        setBrandProfile,
        brandProfile
    } = props;
    const context = useContext(AppContextObject)
    const parentData = useContext(DataContextProvider)
    const history = useHistory()

    useEffect(()=>{
        checkBrandSetup()
    },[history.location.pathname])

    useEffect(() => {
        window.addEventListener("beforeunload", handleBeforeUnload)
        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload)
        }
    }, [])

    const handleBeforeUnload = (e) => {
        e.preventDefault()
        window.addEventListener("unload", function(){
        })
        setTimeout(function() {
            setTimeout(function() {
            }, 1000);
        },1);
        const message = "Are you sure you want to reload the page? All provided data will be lost."
        e.returnValue = message
        return message
    }

    const checkBrandSetup = async () => {
        const resp = await context.apis[context.user.role].getBrandSetupByUserId(
            context.validator, {}, {}
        )   
        console.log("checkBrandSetup resp>>>>>>",resp);

        if(resp && resp.done){
            // if(context.user.role === "brandCMO"){
            //     if(resp.response.haveCategory){
            //     }else{
            //         history.push("/brands/brand-details/profile")
            //         // toast.error("Please complete your profile")
            //     }
            // }else if(context.user.role === "brandManager"){
                if(resp.response.haveCategory){
                    history.push("/brands/brand-details/profile")
                }
            // }
        }
    }
    console.log("createBrand parentData", parentData)
    return (
        <>
            <BrandProfileSidemenu
                progress={progress}
                activeModule={activeModule}
            />

            <Switch>
                <Route path={`${router.url}/general`}>
                    <ProfileGeneral
                        events={{
                            "setProgress": setProgress,
                            "setActiveModule": setActiveModule,
                            "setBrandProfile": setBrandProfile
                        }}
                        progress={progress}
                        activeModule={activeModule}
                        brandProfile={brandProfile}
                        parentData={parentData}
                        setBrandProfile={setBrandProfile}
                    />
                </Route>

                <Route path={`${router.url}/description`}>
                    <ProfileDescription
                        events={{
                            "setProgress": setProgress,
                            "setActiveModule": setActiveModule,
                            "setBrandProfile": setBrandProfile
                        }}
                        progress={progress}
                        activeModule={activeModule}
                        brandProfile={brandProfile}
                    />
                </Route>

                <Route path={`${router.url}/contact`}>
                    <ProfileContact
                        events={{
                            "setProgress": setProgress,
                            "setActiveModule": setActiveModule,
                            "setBrandProfile": setBrandProfile
                        }}
                        progress={progress}
                        activeModule={activeModule}
                        brandProfile={brandProfile}
                    />
                </Route>

                <Route path={`${router.url}/verification`}>
                    <ProfileVerification
                        events={{
                            "setProgress": setProgress,
                            "setActiveModule": setActiveModule,
                            "setBrandProfile": setBrandProfile
                        }}
                        progress={progress}
                        activeModule={activeModule}
                        brandProfile={brandProfile}
                        parentData={parentData}
                    />
                </Route>

                <Route path={`${router.url}/add-users`}>
                    <ProfileAddUsers
                        events={{
                            "setProgress": setProgress,
                            "setActiveModule": setActiveModule,
                            "setBrandProfile": setBrandProfile
                        }}
                        progress={progress}
                        activeModule={activeModule}
                        brandProfile={brandProfile}
                    />
                </Route>
            </Switch>
        </>
    )
}

export default CreateBrand
