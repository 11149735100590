const Faq = (props) => {
  return (
    <>
      <div id="termsPopup faq" className={`onboarding-terms popup show `} style={{display: `${props.showFaq ? "" : "none"}`}} >
        <div className="popup-header">{/*<span className="toggle left-back" data-target="termsPopup">close</span>*/} <span onClick={props.onToggle} className="close toggle" data-target="termsPopup">close</span> </div>
        <div className="popup-body">
          <div className="facili-avai-in clearfix">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="termsBox">
                  <div className="faq-qu-ans">
                    <div className="faq-qu-logo">
                      <span className="faq-logo"></span>
                      <span className="faq-logo-line"></span>
                    </div>
                    <div className="faq-qu-text">
                      <h4>1. How does a school sign up on WISR? </h4>
                      <p>Our representatives will help you with WISR's onboarding process. Get in touch with us at  helpdesk@wisr.co.in and a representative will arrive at your school to onboard you.</p>
                    </div>
                    <div className="faq-qu-text">
                      <h4>2. Will WISR charge us, the school, to sign up on the platform?</h4>
                      <p>There are no onboarding charges for schools while signing up on WISR. Simply reach out to us at helpdesk@wisr.co.in and our representatives will help onboard you. </p>
                    </div>

                    <div className="faq-qu-text">
                      <h4>3. Can several members from my school receive multiple WISR logins? </h4>
                      <p>We can provide your school with multiple WISR accounts as per your requirements.</p>
                      <p>We also empower you to decide the level of data access for each account. For example, the school's Principle or Trustee can receive the highest level of access over a member of the admin staff. You, as the school, are completely in control of the data access level.</p>
                    </div>
                    <div className="faq-qu-text">
                      <h4>4. Who has access to our school's details? </h4>
                      <p>At WISR, we believe in the complete confidentiality of your school's data. We don't share your information with any stakeholders without your express consent. </p>
                      <p>All individual WISR accounts provided to school authorities can access the details. However, we can help you customise the level of access based on the login profiles. In a nutshell, you decide which of the school's individuals can access your data, and how much. </p>
                      <p>Lastly, even brands you choose to partner with can view data that is solely relevant to their campaigns. </p>
                    </div>
                    <div className="faq-qu-text">
                      <h4>5. As a school, is there a list we can choose the inventory from? </h4>
                      <p>Yes, absolutely. We leave inventory selection completely to your discretion; you choose the inventory you want to open up on WISR and for what duration. You can also decide to add or remove your inventory at a later time. </p>
                    </div>
                    <div className="faq-qu-text">
                      <h4>6. What is the value of each school inventory we list on WISR?</h4>
                      <p>At WISR, we empower schools to help better teachers' pay scales, upgrade the infrastructure and facilities to enhance students' experience through quality education and facilities.</p>
                      <p>Write to us at helpdesk@wisr.co.in and our representatives will help you know more. </p>
                    </div>
                    
                    <div className="faq-qu-text">
                      <h4>7. What is the duration of each brand campaign?</h4>
                      <p>Each campaign's duration varies based on several factors. However, as the school, you have the complete liberty to decide how long you want to run a campaign. Reach out to our representatives at  helpdesk@wisr.co.in for any questions.</p>
                    </div>
                    <div className="faq-qu-text">
                      <h4>8. Do we, as the school, decide the time duration for a brand campaign?</h4>
                      <p>Yes, absolutely. WISR empowers the school's authorities to decide each brand campaign's time duration.</p>
                    </div>
                    <div className="faq-qu-text">
                      <h4>9. Does the school approve the creatives of a brand campaign? </h4>
                      <p>At WISR, we understand what students experience and takeaway from a brand campaign is of utmost importance. You have the power to decide what works best for your students, and that includes having the final say on all brand creatives that go up on your premises.</p>
                    </div>
                    <div className="faq-qu-text">
                      <h4>10. How do schools benefit from WISR?</h4>
                      <p>Schools get several advantages after signing up on WISR. Choose from a tailor-made list of rewards that suit you best. Reach out to our representatives at helpdesk@wisr.co.in for more details. </p>
                    </div>
                    
                    <div className="faq-qu-text">
                      <h4>11. Can we edit our school's profile after onboarding on WISR?</h4>
                      <p>Yes, you can. Simply log in via your school's account(s) and update your information in any section.</p>
                    </div>
                    <div className="faq-qu-text">
                      <h4>12. Can WISR offboard our school from the platform?</h4>
                      <p>WISR reserves the right to offboard a school without prior notice if it fails to comply with a brand campaign's minimum requirements.</p>
                    </div>
                    <div className="faq-qu-text">
                      <h4>13. Can we offboard our school from the WISR platform?</h4>
                      <p>WISR gives you the complete freedom to decide the duration of your association with us. Please reach to us at helpdesk@wisr.co.in and we will help you with the offboarding process.</p>
                    </div>
                    <div className="faq-qu-text">
                      <h4>14. As a school, can we decide which brands can run a campaign with us?</h4>
                      <p>At WISR, we empower you to make all the decisions about your brand campaign. Ergo, you decide which brands will most help in uplifting students' experiences within your school's premises.</p>
                      <p>We also take your written approvals before the start of each campaign.</p>
                    </div>
                    <div className="faq-qu-text">
                      <h4>15. Can we cancel a brand campaign after we have given WISR all approvals?</h4>
                      <p>Unfortunately, that isn't possible. Once we secure all approvals from you, the brand will go ahead and run the campaign in your school.</p>
                    </div>
                    <div className="faq-qu-text">
                      <h4>16. Once all approvals are in place, can the school opt for a different timeline for a brand campaign?</h4>
                      <p>Unfortunately, this isn't possible. After school authorities approve all creatives and timelines, WISR sets the ball rolling to seamlessly start the brand campaign in your school.</p>
                    </div>

                    <div className="faq-qu-text">
                      <h4>17. Can we double book our school's inventory for the same period with multiple brands by mistake?</h4>
                      <p>Fortunately, you cannot double book your inventory with multiple brands. Once a brand books your inventory, the platform will make the same inventory unavailable to all other brands for the campaign's duration.</p>
                    </div>
                    <div className="faq-qu-text">
                      <h4>18. Whom can we get in touch with for help at any stage of a brand campaign?</h4>
                      <p>We are always available to assist you. Simply reach out to us at helpdesk@wisr.co.in and our representatives will help you.</p>
                    </div>
                    <div className="faq-qu-text">
                      <h4>19. Can we de-list any of our school inventory after listing it on the WISR platform?</h4>
                      <p>WISR gives you the right to decide which inventory you want to list and for what duration. Just log in to your WISR account(s) and add or remove any inventory as you need to.</p>
                    </div>
                    <div className="faq-qu-text">
                      <h4>20. Can we decide how much to charge for renting our school's inventory?</h4>
                      <p>WISR believes in complete transparency. Our representatives will discuss all pre-determined inventory rentals with you during the onboarding process.</p>
                    </div>
                    <div className="faq-qu-text">
                      <h4>21. What can we do if a campaign or activity has damaged our school's property?</h4>
                      <p>In the unlikely event that your school's premises have been damaged due to a brand's activity, our representatives will assist you with the way forward. Please write to us at helpdesk@wisr.co.in for help.</p>
                    </div>
                    <div className="faq-qu-text">
                      <h4>22. How do we reach a WISR representative for questions about a brand campaign?</h4>
                      <p>A WISR representative will always help you out at any stage of the campaign. Just drop us an email at helpdesk@wisr.co.in and our executives will solve your queries.</p>
                    </div>
                    <div className="faq-qu-text">
                      <h4>23. Is there a minimum number of school inventory assets we need to select while onboarding?</h4>
                      <p>No, the WISR platform does not require you to have a minimum number of assets to sign up.</p>
                    </div>
                    <div className="faq-qu-text">
                      <h4>24. As a school, do we play a part in setting up the campaign's branding?</h4>
                      <p>At WISR, we want to empower you to take all the decisions about a brand campaign. The platform is designed for self-service with minimal intervention required from our representatives. Reach out to helpdesk@wisr.co.in for more details.</p>
                    </div>
                    <span className="faq-logo-line w100"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`blurBackground`} style={{display: `${props.showFaq ? "" : "none"}`}}></div>
    </>
  )
}

export default Faq
