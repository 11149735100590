import { useState, useContext, useEffect } from "react"

import { useHistory } from "react-router-dom"
// import OnGoingCampaigns from "./onGoingCampaigns"
import tableFilter from "../../../assets/images/table-filter.svg"
import mapsImg from "../../../assets/images/maps.jpg"

import TopBar from "../schools/components/topBar"
import AppContextObject from "../../../common/context/common"
import { allowDecimalUpto, calculateImpression, formatDateShowMonth, getPercentageComplition, objectDeepClone, returnReadableStatus } from "../../../service/helper"
import HorizentalBarChart from "../../../components/charts/horizentalBarChart"
import scriptLoader from 'react-async-script-loader'
import config from "../../../service/config"
import MultiPointMap from "../../../components/addon/multiPointMap"
import InventoryMore from "../../../components/addon/inventoryMore"
import BrandTopBar from "./components/brandTopBar"
import Loader from "../../../components/addon/loader"
import SchoolList from "../backendAdmins/components/SchoolsList"
import BrandBackBtn from "./components/brandBackBtn"
import arraow from "../../../assets/images/arrow-view.svg"
import moment from "moment"
import AlbumList from "../backendAdmins/components/albumList"
import CustomViewList from "../backendAdmins/components/customViewList"
const CampaignDetails = (props) => {
    const {
        isScriptLoaded,
        isScriptLoadSucceed
    } = props
    const context = useContext(AppContextObject)
    const history = useHistory()
    const [capmaignDetails, setCapmaignDetails] = useState<any>({})
    const [reachChartData, setReachChartData] = useState<any>()
    const [impressionChartData, setImpressionChartData] = useState<any>()
    const [eventReachChartData, setEventReachChartData] = useState<any>()
    const [eventImpressionChartData, setEventImpressionChartData] = useState<any>()
    const [tab, setTab] = useState("reach")
    const [parentTab, setParentTab] = useState("inventory")
    const [pincodeList, setPincodeList] = useState([])
    const [campaignCompletion, setCampaignCompletion] = useState(0)
    const [showInventoryModal, setShowInventoryModal] = useState(false)
    const [inventoryList, setInventoryList] = useState([])
    const [eventList, setEventList] = useState([])
    const [isPageLoading, setIsPageLoading] = useState(false)
    const [showSchoolModal, setShowSchoolModal] = useState(false)
    const [showCustomModal, setShowCustomModal] = useState(false)
    const [schoolList, setSchoolList] = useState([])
    const [totalSchool, setTotalSchool] = useState([])
    const [totalPage, setTotalPage] = useState([0])
    const [_id, set_id] = useState("")
    const [inventoryReachData, setInventoryReachData] = useState([])
    const [inventoryImpressionData, setInventoryImpressionData] = useState([])
    const [eventReachData, setEventReachData] = useState([])
    const [eventImpressionData, setEventImpressionData] = useState([])
    const [impression, setImpression] = useState(0)
    const [hideCampaignComplition, setHideCampaignComplition] = useState(false)
    const [inventoryMax, setInventoryMax] = useState(0)
    const [eventMax, setEventMax] = useState(0)
    const [albumList, setAlbumList] = useState([])
    const [showAlbumPopUp, setShowAlbumPopUp] = useState(false)
    console.log("campaignCompletion", campaignCompletion)
    let campaignId = ""
    useEffect(() => {
        if(capmaignDetails.startDate && capmaignDetails.endDate){
            configureChartData()
        }
    }, [inventoryReachData, inventoryImpressionData, eventReachData, eventImpressionData, capmaignDetails])
    useEffect(() => {
        if (history.location.state) {
            let data: any = history.location.state
            if (data.campaignId) {
                campaignId = data.campaignId
                getCampaignDetails(data.campaignId)
                getAlbumList(data.campaignId)
            }
        }
        // console.log("allowDecimalUpto", allowDecimalUpto(500.2034, 3))
    }, [])
    useEffect(() => {
    }, [reachChartData, impressionChartData])
    // useEffect(() => {
    //     if(tab === "impression"){
    //         if(capmaignDetails && capmaignDetails.totalImpression){
    //             setMax(Math.ceil(capmaignDetails.totalImpression/100000))
    //         }
    //     }
    //     else{
    //         setMax(undefined)
    //     }
    // }, [tab, parentTab, capmaignDetails])
    const configureChartData = () => {
        let inventoryReachLabel = [], inventoryImpressionLabel = [], inventoryImpression = [], eventImpression = [], inventoryReach = [], eventReach = [], eventReachLabel = [], eventImpressionLabel = []
        inventoryReachData.forEach((data) => {
            inventoryReachLabel.push(data.inventoryName)
            inventoryReach.push(allowDecimalUpto(Number(data.reach) / 1000, 4))
        })
        inventoryImpressionData.forEach((data, index) => {
            inventoryImpressionLabel.push(data.inventoryName)
            if(index === 0){
                setInventoryMax(allowDecimalUpto(data.impression / 100000, 4))
            }
            let currentImpression = calculateImpression(Number(data.impression), capmaignDetails.startDate, capmaignDetails.endDate, capmaignDetails?.campaignPausedDates || [], capmaignDetails?.campaignResumedDates || [])
            console.log("currentImpression", currentImpression, capmaignDetails, calculateImpression(Number(data.impression), capmaignDetails.startDate, capmaignDetails.endDate, capmaignDetails?.campaignPausedDates || [], capmaignDetails?.campaignResumedDates || []))
            inventoryImpression.push(allowDecimalUpto(Number(currentImpression) / 100000, 4))
        })
        eventReachData.forEach((data) => {
            eventReachLabel.push(data.inventoryName)
            eventReach.push(allowDecimalUpto(Number(data.reach) / 1000, 4))
        })
        eventImpressionData.forEach((data, index) => {
            eventImpressionLabel.push(data.inventoryName)
            if(index === 0){
                setEventMax(allowDecimalUpto(data.impression / 100000, 4))
            }
            let currentImpression = calculateImpression(Number(data.impression), capmaignDetails.startDate, capmaignDetails.endDate, capmaignDetails?.campaignPausedDates || [], capmaignDetails?.campaignResumedDates || [])
            eventImpression.push(allowDecimalUpto(Number(currentImpression) / 100000, 4))
        })
        console.log("checkValuesFor", inventoryReach, eventReachData, inventoryImpressionData, eventImpressionData)
        if(inventoryReach.length === 0 && inventoryImpressionData.length === 0){
            setParentTab("event")
        }
        setReachChartData({
            labels: inventoryReachLabel,
            datasets: [
                {
                    label: "Reach (No of students * 1000)",
                    axis: "y",
                    data: inventoryReach,
                    backgroundColor: "#fe6e00",
                    borderRadius: "50",
                    boarderWidth: 1,
                    barThickness: 10
                }],
            options: {}
        })
        setImpressionChartData({
            labels: inventoryImpressionLabel,
            datasets: [
                {
                    label: "Impression (Impression * 100000)",
                    axis: "y",
                    data: inventoryImpression,
                    backgroundColor: "#fe6e00",
                    borderRadius: "50",
                    boarderWidth: 1,
                    barThickness: 10
                }],
            options: {}
        })
        setEventReachChartData({
            labels: eventReachLabel,
            datasets: [
                {
                    label: "Reach (No of students * 1000)",
                    axis: "y",
                    data: eventReach,
                    backgroundColor: "#fe6e00",
                    borderRadius: "50",
                    boarderWidth: 1,
                    barThickness: 10
                }],
            options: {}
        })
        setEventImpressionChartData({
            labels: eventImpressionLabel,
            datasets: [
                {
                    label: "Impression (Impression * 100000)",
                    axis: "y",
                    data: eventImpression,
                    backgroundColor: "#fe6e00",
                    borderRadius: "50",
                    boarderWidth: 1,
                    barThickness: 10
                }],
            options: {}
        })
    }
    const getCampaignDetails = async (_id) => {
        setIsPageLoading(true)
        const resp = await context.apis.public.getCampaignById(
            context.validator, { _id: _id }, {}
        )
        if (resp && resp.done) {
            console.log("schoolListresp", resp)
            console.log("schoolListresp myconsole", resp.response.campaignData.reason)
            if(resp.response.useNewSchoolContributionBreakdown){
                if (resp.response.inventoriesByReach && Array.isArray(resp.response.inventoriesByReach)) {
                    setInventoryReachData([...resp.response.inventoriesByReach])
                }
                if (resp.response.inventoriesByImpression && Array.isArray(resp.response.inventoriesByImpression)) {
                    setInventoryImpressionData([...resp.response.inventoriesByImpression])
                }
                if (resp.response.eventsByReach && Array.isArray(resp.response.eventsByReach)) {
                    setEventReachData([...resp.response.eventsByReach])
                }
                if (resp.response.eventsByImpression && Array.isArray(resp.response.eventsByImpression)) {
                    setEventImpressionData([...resp.response.eventsByImpression])
                }
            }
            else{
                if (resp.response.schoolsByInventoryReachData && Array.isArray(resp.response.schoolsByInventoryReachData)) {
                    setInventoryReachData([...resp.response.schoolsByInventoryReachData])
                }
                if (resp.response.schoolsByInventoryImpressionData && Array.isArray(resp.response.schoolsByInventoryImpressionData)) {
                    setInventoryImpressionData([...resp.response.schoolsByInventoryImpressionData])
                }
                if (resp.response.schoolsByEventReachData && Array.isArray(resp.response.schoolsByEventReachData)) {
                    setEventReachData([...resp.response.schoolsByEventReachData])
                }
                if (resp.response.schoolsByEventImpressionData && Array.isArray(resp.response.schoolsByEventImpressionData)) {
                    setEventImpressionData([...resp.response.schoolsByEventImpressionData])
                }
            }
            let currentImpression = calculateImpression(Number(resp.response.totalImpression), resp.response.startDate, resp.response.endDate, resp.response?.campaignPausedDates || [], resp.response?.campaignResumedDates || [])
            setImpression(currentImpression)
            setCapmaignDetails(resp.response)
            let completion = getPercentageComplition(resp.response.startDate, resp.response.endDate)
            setCampaignCompletion(completion)
            if(!(moment().isBefore(moment(resp.response.startDate))) && resp.response.campaignStatus === "in-review"){  
                setHideCampaignComplition(true)
            }
            // let arr = resp.response.schoolCampaignDatas[0].inventories
            // let inventory = arr.map((inventory) => {
            //     return {name: inventory.parentName, image: inventory.images[0] || "no image"}
            // })
            let inventories = {}, events = [], inventoriesArray = []
            // let schools = []
            resp.response.schoolCampaignDatas && Array.isArray(resp.response.schoolCampaignDatas) && resp.response.schoolCampaignDatas.forEach(school => {
                // let schoolInventory = []
                // schools.push(school.schoolDetails)
                if (school.inventories && Array.isArray(school.inventories)) {
                    school.inventories.forEach(inventory => {
                        // if (inventory.parentName && inventory.images.length > 0) {
                        //     let data = {
                        //         name: inventory.parentName,
                        //         image: (inventory.images && inventory.images.length > 0 && inventory.images[0].image) || "no image"
                        //     }
                        //     if (!inventories.find(inventory => inventory.name === data.name)) {
                        //         inventories.push(data)
                        //     }
                        // }
                        if(inventories[inventory.parentName]){
                            inventories[inventory.parentName] = [...inventories[inventory.parentName], ...inventory.images.map(data => data.image)]
                        }
                        else{
                            inventories[inventory.parentName]=[...inventory.images.map(data => data.image)]
                        }
                    })
                }
            })
            Object.keys(inventories).forEach(key => {
                let data = {name: key, image: inventories[key].length > 0 ? inventories[key][0] : "no image"}
                inventoriesArray.push(data)
            })
            resp.response.schoolCampaignDatas && Array.isArray(resp.response.schoolCampaignDatas) && resp.response.schoolCampaignDatas.forEach(school => {
                // let schoolInventory = []
                // schools.push(school.schoolDetails)
                if (school.activities && Array.isArray(school.activities)) {
                    school.activities.forEach(inventory => {
                        if (inventory.name) {
                            let data = {
                                name: inventory.name,
                                // image: (inventory.images && inventory.images.length > 0 && inventory.images[0].image) || "no image"
                            }
                            if (!events.find(inventory => inventory.name === data.name)) {
                                events.push(data)
                            }
                        }
                    })
                }
            })
            // setTotalSchool([...schools])
            console.log("events", events)
            setEventList([...events])
            setInventoryList([...inventoriesArray])
            let array = resp.response.schoolCampaignDatas.map((data) => data?.schoolDetails?.city || "")
            setPincodeList(array)
        }
        setIsPageLoading(false)
    }

    console.log("capmaignDetails myconsole", capmaignDetails)

    const getAlbumList = async(_id) => {
        let resp = await context.apis[context.user.role].getCampaignReportingAlbumsByCampaign(
            context.validator, {campaignId: _id, searchValue: ""}
        )
        if(resp && resp.done){
            console.log("getCampaignReportingAlbumsByCampaign", resp)
            setAlbumList([...resp.response.rows.filter(item => item.publishedToBrand === true)])
        }
    }
    const closeInventoryModal = () => {
        setShowInventoryModal(false)
    }
    const openSchoolModal = (_id) => {
        set_id(_id)
        setShowSchoolModal(true)
    }

   

    const closeSchoolTable = () => {
        setShowSchoolModal(false)
    }

// come here
    const openCustomModal = (_id) => {
        // alert(_id)
        set_id(_id)
        setShowCustomModal(true)
    }
    const closeCustomTable = () => {
        setShowCustomModal(false)
    }

    // getCampaignById
    const routeToAlbumDetails = (albumId) => {
        // history.push({pathname: "/brands/brand-details/viewAlbum", state: {albumId: albumId}})
        window.open('/brands/brand-details/viewAlbum?' + albumId)
    }
    return (
        <>
            <div className={`ovelap`}></div>
            {/* <TopBar isDashboard={true} /> */}
            {isPageLoading ?
                <Loader />
                :
                <div className="col-xl-10 col-lg-12 col-md-12 col-xs-12">
                    <div className="rightPart noBack">
                        <div className="row">
                            <div className="col-xs-12 col-lg-12 col-md-12 col-xs-12">
                                <BrandBackBtn
                                    link="/"
                                    text="Log Out"
                                />
                            </div>
                            <BrandTopBar isCampaignDetail={true} />
                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-10">
                                <div className="inventory-overHead overheadTwo">
                                    <p>Campaign name</p>
                                    <h3>{capmaignDetails.name && capmaignDetails.name} {capmaignDetails.campaignStatus && <span className={`table-status ${capmaignDetails.campaignStatus.toLowerCase() === "ongoing" && "greenbg"} ${capmaignDetails.campaignStatus.toLowerCase() === "scheduled" && "orangebg"} ${capmaignDetails.campaignStatus.toLowerCase() === "rejected" && "redbg"} ${capmaignDetails.campaignStatus.toLowerCase() === "on-hold" && "orangebg"} ${capmaignDetails.campaignStatus.toLowerCase() === "in-review" && "yellowbg"} ${capmaignDetails.campaignStatus.toLowerCase() === "executed" && "redbg"} ${capmaignDetails.campaignStatus.toLowerCase() === "draft" && "graybg"} ${capmaignDetails.campaignStatus.toLowerCase() === "trial" && "yellowbg"}`}>{capmaignDetails.campaignStatus && returnReadableStatus(capmaignDetails.campaignStatus)}</span>}</h3>
                                </div>
                            </div>

                            <div className="col-xl-3 col-lg-4 col-md-6 col-xs-12 mt-20">
                                <div className="inventory-overHead overheadOne">
                                    <p>Budget</p>
                                    <h3>₹ {capmaignDetails.budget && capmaignDetails.budget.toLocaleString("en-IN") || "-"}</h3>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6 col-xs-12 mt-20">
                                <div className="inventory-overHead overheadOne">
                                    <p>Duration</p>
                                    <h3>{capmaignDetails.startDate && capmaignDetails.endDate && `${formatDateShowMonth(capmaignDetails.startDate, 4)} - ${formatDateShowMonth(capmaignDetails.endDate, 4)}`}</h3>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6 col-xs-12 mt-20">
                                <div className="inventory-overHead overheadOne">
                                    <p>No of Schools</p>
                                    <h3>{(capmaignDetails.schoolCampaignDatas && capmaignDetails.schoolCampaignDatas.length) || "-"}</h3>
                                    <a className="viewwLink camp-link bottom0" style={{ cursor: "pointer" }} onClick={() => openSchoolModal(capmaignDetails._id)}>VIEW list <img className="arrowView" src={arraow} /></a>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6 col-xs-12 mt-20">
                                <div className="inventory-overHead overheadOne">
                                    <p>{(inventoryList.length > 0 && eventList.length > 0) ? "Inventory and Event Selected" : inventoryList.length > 0 ? "Inventory Selected" : eventList.length > 0 && "Event Selected"}</p>
                                    <h3>{inventoryList && eventList && (inventoryList.length + eventList.length)}</h3>
                                    <a className="viewwLink camp-link bottom0" style={{ cursor: "pointer" }} onClick={() => setShowInventoryModal(true)}>VIEW list <img className="arrowView" src={arraow} /></a>
                                </div>
                            </div>

                            
                            {console.log("capmaignDetailssssaaaa", capmaignDetails)}
                            {console.log("capmaignDetailssssaaaa 2", capmaignDetails?.wisrOffering?.eventAndOccations)}
                            {/* {console.log("capmaignDetailssssaaaa 3", capmaignDetails.wisrOffering.eventAndOccations)} */}
                            {console.log("capmaignDetailssssaaaa 4", capmaignDetails?.wisrOffering?.inventory)}

                            <div className="col-xl-3 col-lg-4 col-md-6 col-xs-12 mt-20">
                                <div className="inventory-overHead overheadOne">
                                    <p>Total Reach</p>
                                    <h3>{capmaignDetails.totalReach && capmaignDetails.totalReach.toLocaleString("en-IN") || "-"}</h3>
                                </div>
                            </div>
                            {
                             capmaignDetails?.wisrOffering?.eventAndOccations && !capmaignDetails?.wisrOffering?.inventory ?
                             ""
                             :
                             capmaignDetails?.wisrOffering?.eventAndOccations && capmaignDetails?.wisrOffering?.inventory ?
                            <div className="col-xl-3 col-lg-4 col-md-6 col-xs-12 mt-20">
                                <div className="inventory-overHead overheadOne">
                                    <p>Total Impressions</p>
                                    <h3>{capmaignDetails.totalImpression !== null && capmaignDetails.totalImpression !== undefined && capmaignDetails.totalImpression.toLocaleString("en-IN") || "-"}</h3>
                                </div>
                            </div>
                            :
                            <div className="col-xl-3 col-lg-4 col-md-6 col-xs-12 mt-20">
                                <div className="inventory-overHead overheadOne">
                                    <p>Total Impressions</p>
                                    <h3>{capmaignDetails.totalImpression !== null && capmaignDetails.totalImpression !== undefined && capmaignDetails.totalImpression.toLocaleString("en-IN") || "-"}</h3>
                                </div>
                            </div>
                            }

{
    capmaignDetails?.wisrOffering?.custom && (
                            <div className="col-xl-3 col-lg-4 col-md-6 col-xs-12 mt-20">
                                <div className="inventory-overHead overheadOne">
                                    <div>
                                    <p>Custom Brief</p>
                                    
                                   <a className="camp-link bottom0" style={{ cursor: "pointer", color: "#FE6E00", textTransform: "uppercase",fontSize: "13px", marginTop: "4px", fontWeight: "500", transition: "0.5s" }} onClick={() => openCustomModal(capmaignDetails._id)}>Custom Details <img style={{height: "10px"}} className="arrowView" src={arraow} /></a> 
                                   {/* <a className="viewwLink" onClick={() => openCustomModal(capmaignDetails._id)}> VIEW list <img className="arrowView" src={arraow} /></a> */}
                                    </div>
                                </div>
                            </div>
    )
}
                            


 {/* <div className="col-xl-3 col-lg-4 col-md-6 col-xs-12 mt-20">
                                <div className="inventory-overHead overheadOne">
                                    <p>Custom Brief</p>
                                    
                                    <div className="col-xl-3 col-lg-4 col-md-6 col-xs-12 mt-20">
                                <div className="inventory-overHead overheadOne">
                                   
                                    
                                    <a className="viewwLink camp-link bottom0" style={{ cursor: "pointer" }} onClick={() => openCustomModal(capmaignDetails._id)}>VIEW list <img className="arrowView" src={arraow} /></a>
                                </div>
                            </div>
                                
                                </div>
                            </div> */}

                            {/* <div className="col-xl-3 col-lg-4 col-md-6 col-xs-12 mt-20">
                                <div className="inventory-overHead overheadOne">
                                    <p>Custom Brief</p>
                                    
                                    <div className="col-xl-3 col-lg-4 col-md-6 col-xs-12 mt-20">
                                <div className="inventory-overHead overheadOne">
                                   
                                    
                                    <a className="viewwLink camp-link bottom0" style={{ cursor: "pointer" }} onClick={() => openCustomModal(capmaignDetails._id)}>VIEW list <img className="arrowView" src={arraow} /></a>
                                </div>
                            </div>
                                
                                </div>
                            </div> */}
                            {albumList && albumList.length > 0 &&
                                <div className="col-xl-3 col-lg-4 col-md-6 col-xs-12 mt-20">
                                    <div className="inventory-overHead overheadOne">
                                        <p>Campaign Reporting</p>
                                        <a className="viewwLink" style={{ cursor: "pointer", float: "left" }} onClick={() => setShowAlbumPopUp(true)}>VIEW report <img className="arrowView" src={arraow} /></a>
                                    </div>
                                </div>
                            }
                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-40 mb-40">
                                <div className="line-full"></div>
                            </div>
                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 pl pr">
                                <div className="row">
                                    {!hideCampaignComplition ?
                                        <div className="col-xl-9 col-lg-9 col-md-12 col-xs-12 mb-10 pl">
                                            <div className="row">
                                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-10">
                                                    <h2 className="htx2 clearfix">Campaign performance</h2>
                                                </div>
                                                <div className="col-xl-7 col-lg-7 col-md-12 col-xs-12">
                                                    <div className="inventory-overHead camp-performance">
                                                        <h4>
                                                            Completion Status
                                                            <b>{`${campaignCompletion}%`}</b>
                                                        </h4>
                                                        <div className="camp-performance-bar">
                                                            <span style={{ width: `${campaignCompletion}%` }}></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-5 col-lg-5 col-md-12 col-xs-12 pr pl-50">
                                                    <div className="row">
                                                        <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12">
                                                            <div className="inventory-overHead overheadOne">
                                                                <p>Reach</p>
                                                                <h3>{capmaignDetails.totalReach && capmaignDetails.totalReach.toLocaleString("en-IN") || "-"}</h3>
                                                            </div>
                                                        </div>
{
    capmaignDetails?.wisrOffering?.eventAndOccations && !capmaignDetails?.wisrOffering?.inventory ?
    ""
    :
    capmaignDetails?.wisrOffering?.eventAndOccations && capmaignDetails?.wisrOffering?.inventory ?
                                                        <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12">
                                                            <div className="inventory-overHead overheadOne">
                                                                <p>Impressions</p>
                                                                <h3>{(Math.round(impression)).toLocaleString("en-IN") || "-"}</h3>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12">
                                                        <div className="inventory-overHead overheadOne">
                                                            <p>Impressions</p>
                                                            <h3>{(Math.round(impression)).toLocaleString("en-IN") || "-"}</h3>
                                                        </div>
                                                    </div>

                                                        }

                                                    </div>
                                                </div>
                                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-40"></div>
                                                {/* <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-10" onClick={() => setParentTab("inventory")}>
                                                    <h2 className="htx2 clearfix">Top Performing Inventories</h2>
                                                </div>
                                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-10" onClick={() => setParentTab("event")}>
                                                    <h2 className="htx2 clearfix">Top Performing Events</h2>
                                                </div> */}
                                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-10">
                                                    <ul className="tabsinventory parentTab">
                                                        {((reachChartData && reachChartData?.labels && reachChartData?.labels?.length !== 0) || (impressionChartData && impressionChartData?.labels && impressionChartData?.labels.length !== 0)) && <li onClick={() => setParentTab("inventory")}><a href="javascript:void(0);" className={parentTab === "inventory" && "active"}>Top Performing Inventories</a></li>}
                                                        {((eventReachChartData && eventReachChartData?.labels && eventReachChartData?.labels?.length !== 0) || (eventImpressionChartData && eventImpressionChartData?.labels && eventImpressionChartData?.labels.length !== 0)) && <li onClick={() => setParentTab("event")}><a href="javascript:void(0);" className={parentTab === "event" && "active"}>Top Performing Events </a></li>}
                                                    </ul>
                                                </div>
                                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-10">

                                                    <div className="top-performing">
                                                        <ul className="tabsinventory">
                                                            <li onClick={() => setTab("reach")}><a href="javascript:void(0);" className={tab === "reach" && "active"}>Reach</a></li>
{
    capmaignDetails?.wisrOffering?.eventAndOccations && !capmaignDetails?.wisrOffering?.inventory ?
    ""
    :
    capmaignDetails?.wisrOffering?.eventAndOccations && capmaignDetails?.wisrOffering?.inventory ?
                                                            <li onClick={() => setTab("impression")}><a href="javascript:void(0);" className={tab === "impression" && "active"}>ImprEssion</a></li>
                                                            :
                                                            <li onClick={() => setTab("impression")}><a href="javascript:void(0);" className={tab === "impression" && "active"}>ImprEssion</a></li>
}

                                                        </ul>
                                                        <div className="top-performing-one">
                                                            <div className="top-performing-graph graphCanvas">
                                                                <HorizentalBarChart
                                                                    data={reachChartData && impressionChartData && eventImpressionChartData && eventReachChartData && parentTab === "event" ? tab === "reach" ? eventReachChartData : eventImpressionChartData : tab === "reach" ? reachChartData : impressionChartData}
                                                                    xAxisText={tab === "reach" ? "Reach(No. of student * 1000)" : "Impression (Impression * 100000)"}
                                                                    yAxisText={parentTab === "inventory" ? "Inventory Name" : "Event Name"}
                                                                    max={tab === "impression" && (parentTab === "inventory" ? inventoryMax : eventMax)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className="col-xl-9 col-lg-9 col-md-12 col-xs-12 mb-10 pl">
                                            <div className="row">
                                              
                                            </div>
                                        </div>
                                    }
                                    <div className="col-xl-3 col-lg-3 col-md-12 col-xs-12 mb-10 pr">
                                        <div className="box-design">
                                            <h2 className="htx3 clearfix mb-10">Location</h2>
                                            {isScriptLoaded && isScriptLoadSucceed &&
                                                <MultiPointMap
                                                    pincodeList={pincodeList}
                                                />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <InventoryMore
                showModalTwoMore={showInventoryModal}
                inventoriesList={inventoryList}
                eventList={eventList}
                closeWithoutSaving={closeInventoryModal}
            />
            {_id !== '' && <SchoolList
                showSchoolModal={showSchoolModal}
                showCustomModal={showCustomModal}
                closeSchoolModal={closeSchoolTable}
                campaignId={_id}
                showCampaignCreativeButton={false}
                fromBrand={true}
                // doNotShowCreativeStatus={capmaignDetails.isTrial}
            />}
             {_id !== '' && <CustomViewList
                // showSchoolModal={showSchoolModal}
                showCustomModal={showCustomModal}
                closeCustomModal={closeCustomTable}
                campaignId={_id}
                showCampaignCreativeButton={false}
                fromBrand={true}
              reason={capmaignDetails?.campaignData?.reason || "Reason not available"}
                
            />}
            <AlbumList
                showAlbumPopUp={showAlbumPopUp}
                setShowAlbumPopUp={setShowAlbumPopUp}
                albumList={albumList}
                routeToAlbumDetails={routeToAlbumDetails}
                loading={false}
            />
        </>
    )
}

// export default CampaignDetails
export default scriptLoader(
    [
        'https://maps.googleapis.com/maps/api/js?key=' + config.GOOGLE_MAPS_API_KEY
    ],
)(CampaignDetails)
