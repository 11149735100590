import React, { useContext, useEffect, useRef, useState } from 'react'
import '../../../assets/css/landing.css'

import logoLanding from "../../../assets/images/landing/logo.png"
import slideBg from "../../../assets/images/landing/slide-bg.png"
import schoolGenerateImg from "../../../assets/images/landing/school-generateImg.png"
import schoolGenerateImg1 from "../../../assets/images/landing/school-generateImg1.jpg"
import schoolGif from "../../../assets/images/school-av-gif-final.gif"
import schoolCollage from "../../../assets/images/School_Collage.png"

import checkImg from "../../../assets/images/landing/checkImg.svg"
import ourPartnerLogo1 from "../../../assets/images/landing/our-partner-logo-1.svg"
import ourPartnerLogo2 from "../../../assets/images/landing/our-partner-logo-2.svg"
import ourPartnerLogo3 from "../../../assets/images/landing/our-partner-logo-3.svg"
import ourPartnerLogo4 from "../../../assets/images/landing/our-partner-logo-4.svg"
import ourPartnerLogo5 from "../../../assets/images/landing/our-partner-logo-5.svg"
import ourPartnerLogo6 from "../../../assets/images/landing/our-partner-logo-6.svg"
import ourPartnerLogo7 from "../../../assets/images/landing/our-partner-logo-7.svg"
import ourPartnerLogo8 from "../../../assets/images/our-partner-logo-8.jpeg"
import ourPartnerLogo9 from "../../../assets/images/our-partner-logo-9.jpeg"
import ourPartnerLogo10 from "../../../assets/images/our-partner-logo-10.jpeg"
import ourPartnerLogo11 from "../../../assets/images/our-partner-logo-11.jpeg"
import ourPartnerLogo12 from "../../../assets/images/our-partner-logo-12.jpeg"
import ourPartnerLogo13 from "../../../assets/images/our-partner-logo-13.jpeg"
import ourPartnerLogo14 from "../../../assets/images/our-partner-logo-14.jpeg"
import ourPartnerLogo15 from "../../../assets/images/our-partner-logo-15.jpeg"
import ourPartnerLogo16 from "../../../assets/images/our-partner-logo-16.jpeg"
import ourPartnerLogo17 from "../../../assets/images/our-partner-logo-17.jpeg"
import ourPartnerLogo1_1 from "../../../assets/images/landing/our-partner-new-1.jpg"
import ourPartnerLogo1_2 from "../../../assets/images/landing/our-partner-new-2.jpg"
import ourPartnerLogo1_3 from "../../../assets/images/landing/our-partner-new-3.jpg"
import ourPartnerLogo1_4 from "../../../assets/images/landing/our-partner-new-4.jpg"
import ourPartnerLogo1_5 from "../../../assets/images/landing/our-partner-new-5.jpg"


// import partnerBrandimg1 from "../../../assets/images/landing/partner-brand-img1.svg"
// import partnerBrandimg2 from "../../../assets/images/landing/partner-brand-img2.svg"
// import partnerBrandimg3 from "../../../assets/images/landing/partner-brand-img3.svg"
// import partnerBrandimg4 from "../../../assets/images/landing/partner-brand-img4.svg"
// import partnerBrandimg5 from "../../../assets/images/landing/partner-brand-img5.svg"
// import partnerBrandimg6 from "../../../assets/images/landing/partner-brand-img6.svg"

import partnerBrandimg1 from "../../../assets/images/landing/bmw.png"
import partnerBrandimg2 from "../../../assets/images/landing/fyp.png"
import partnerBrandimg3 from "../../../assets/images/landing/sonyyay.png"
import partnerBrandimg4 from "../../../assets/images/landing/axisbank.jpeg"
import partnerBrandimg5 from "../../../assets/images/landing/luxor.png"
import partnerBrandimg6 from "../../../assets/images/landing/dabur.png"
import partnerBrandimg7 from "../../../assets/images/landing/toothsi.png"
import partnerBrandimg8 from "../../../assets/images/landing/zee-mindwars.png"
import partnerBrandimg9 from "../../../assets/images/landing/hcl-jigsaw.png"
import partnerBrandimg10 from "../../../assets/images/landing/SFA.jpg"
import partnerBrandimg11 from "../../../assets/images/landing/Sunstone-logo.png"
import partnerBrandimg12 from "../../../assets/images/landing/cocobay.png"
import partnerBrandimg13 from "../../../assets/images/landing/HDFC-Life-Logo.jpg"

import happySchoolImg1 from "../../../assets/images/landing/happy-school-img1.png"
import happySchoolImg2 from "../../../assets/images/landing/happy-school-img2.png"
import happySchoolImg3 from "../../../assets/images/landing/happy-school-img3.png"
import teamLeftarrow from "../../../assets/images/landing/teamLeftarrow.svg"
import teamRightarrow from "../../../assets/images/landing/teamRightarrow.svg"

import quotes1 from "../../../assets/images/landing/quote-1.svg"
import quotes2 from "../../../assets/images/landing/quote-2.svg"
import quotes3 from "../../../assets/images/landing/quote-3.svg"

import advisoryBoardImg1 from "../../../assets/images/landing/advisory-board-img1.jpg"
import advisoryBoardImg2 from "../../../assets/images/landing/advisory-board-img2.jpg"
import advisoryBoardImg3 from "../../../assets/images/landing/advisory-board-img3.jpg"
import advisoryBoardImg4 from "../../../assets/images/landing/advisory-board-img4.jpg"
import aboutImg from "../../../assets/images/landing/about-img.svg"
import footerLogo from "../../../assets/images/landing/footer-logo.svg"
import fb1 from "../../../assets/images/landing/fb-1.svg"
import you1 from "../../../assets/images/landing/you-1.svg"

import insta1 from "../../../assets/images/landing/insta-1.svg"
import link1 from "../../../assets/images/landing/link-1.svg"
import logo from "../../../assets/images/landing/logo.png"
import loginBgLeft from "../../../assets/images/landing/login-bg.svg"
import closePopup from "../../../assets/images/landing/close-popup.svg"




import ourTeam1 from "../../../assets/images/landing/our-team-1.jpg"
import ourTeam2 from "../../../assets/images/landing/our-team-2.jpg"
import ourTeam3 from "../../../assets/images/landing/our-team-3.jpg"
import ourTeam4 from "../../../assets/images/landing/our-team-4.jpg"

import ourTeam5 from "../../../assets/images/landing/our-team-5.jpg"
import ourTeam6 from "../../../assets/images/landing/our-team-6.jpg"
import ourTeam7 from "../../../assets/images/landing/our-team-7.jpg"
import ourTeam8 from "../../../assets/images/landing/our-team-8.jpg"
import ourTeam9 from "../../../assets/images/landing/our-team-9.jpg"

import citySvg from "../../../assets/images/city.svg"
import impressionSvg from "../../../assets/images/impression.svg"
import inventriesSvg from "../../../assets/images/inventries.svg"
import studentSvg from "../../../assets/images/Student.svg"
import statsBackgroundImg from "../../../assets/images/Group55.png"
// import wisrVideo from "../../../service/wizer.mp4"

import { useHistory } from 'react-router-dom'
import TextField from '../../../components/formFields/textField/textField'
import SimpleReactValidator from 'simple-react-validator'
import errorSVG from "../../../assets/images/error.svg";
import TextArea from '../../../components/formFields/textArea'
import { PublicContextInitiator } from "../../apis/publicContextInitiator";
import AppContextObject from "../../../common/context/common"
import OurTeamScroll from './ourTeamScroll'
import toast from "react-hot-toast"
import OurTeamDetails from '../../../components/addon/ourTeamDetails'
import PrivacyAndPolicy from './privacyPolicy'
import TermsAndConditions from './termsAndCondition'
import Youtube from './youtube'
import News from './news'
import WhatsAppAndCall from '../../../components/addon/whatsAppAndCall'
import VideoImageCarousel from '../brands/components/videImageCarousel'
import HappySchools from './happySchools'
import HappyBrands from './happyBrands'
import { isInteger } from 'lodash'


import ScrollTrigger from 'react-scroll-trigger';
import DisableRightClick from '../brands/DisableRightClick'
import hotkeys from 'hotkeys-js';



const LandingPage = () => {
    const [overlay, setOverlay] = useState(false)
    const [togglePopup, setTogglePopup] = useState(false)
    const [statsData, setStatsData] = useState([])
    const [happySchoolData, setHappySchoolData] = useState([])
    const [happyBrandsData, setHappyBrandsData] = useState([])
    const [counterOn, setCounterOn] = useState(false)
    const [showSocialMediaIcons, setShowSocialMediaIcons] = useState({
        facebook: false,
        instagram: false,
        linkedin: false,
        youtube: false
    })
    const hideModal = () => {
        setOverlay(false)
        setTogglePopup(false)
    }
    const openPopup = () => {
        setOverlay(true)
        setTogglePopup(true)
    }
    const onModalClose = () => {
        setTeamdetailpopup(false)
    }
    const [teamdetailpopup, setTeamdetailpopup] = useState(false)
    const openMenu = () => {
        overlay ? setOverlay(false) : setOverlay(true)
    }
    const context = useContext(AppContextObject)
    const [contactUsData, setContactUsData] = useState({
        name: "",
        email: "",
        message: ""
    })
    const [submitMsg, setSubmitMsg] = useState("")
    const [formValidator] = useState(useRef(new SimpleReactValidator({
        element: (message) =>
            <span className="valError"><img src={errorSVG} />{message}</span>
    })))
    const [update, forceUpdate] = useState(false)
    const history = useHistory()
    const brandRef = useRef(null)
    const schoolRef = useRef(null)
    const aboutRef = useRef(null)
    const videoRef = useRef(null)
    const contactUsRef = useRef(null)
    const executeScroll = (ref) => {
        if (ref && ref.current) {
            ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
    }
    const [ourTeamData, setOurTeamData] = useState([
        {
            img: ourTeam1,
            name: "Saurabh Varma",
            designation: "CEO & Founder",
            description: "Driven by his ambition to establish India’s largest platform-first martech network, Saurabh Verma leads the team at WISR. His belief in ‘marketing for good’ combined with his 20 years' of experience in creativity and technology, powers the platform’s modus operandi. As the erstwhile CEO of Publicis Communications South Asia, Saurabh has been at the helm of building staunch brands. His divergent thinking and tech-first ideology has led him to an evolved mission of building India’s leading mar-tech startup to build brands that do good."
        },
        {
            img: ourTeam2,
            name: "Vandana Verma",
            designation: "Co-Founder & Managing Partner",
            description: "Vandana excels at strategising product-oriented solutions. She drives the business growth for WISR, a self-serve tech platform that offers innovative marketing solutions driven by intelligence automation and data-driven tools. Her business acumen and rich experience in brand solutions, lend an impetus to the platform’s vision of ‘marketing for good’.  At WISR, she leads the product’s vision to generate immense value for schools via brand partnerships. In her earlier stint, she was the COO at Arc Worldwide, Publicis Groupe's shopper marketing and experiential arm."
        },
        {
            img: ourTeam3,
            name: "Siddhyesh Narkar",
            designation: "Chief Technology Officer",
            description: "Siddhyesh (Sid, as we know him) is a techxpert whose futuristic thinking, digital proficiency and strategic approach to marketing technology have been the foundation of WISR as a tech platform. With his top-notch people skills, solution-oriented mindset and a sound business understanding, he has led a team of professionals across various locations, ensuring efficient deliveries. His technical understanding lends itself to creating turnkey solutions through the platform, for the purpose of marketing for good. In his prior stint, he was the CTO at Digitas, India & SapientRazorfish, India for 8+ years."
        },
        // {
        //     img: ourTeam4,
        //     name: "Shama Sayed",
        //     designation: "Head Sales",
        //     description: "A seasoned sales professional with a proven track record in the field of broadcast and digital platforms, Shama heads the Sales function at WISR. After garnering a rich experience of two decades in media sales, content and brand partnerships, she is now in the forefront of providing mar-tech solutions to brands, that will help make a difference to schools and students. Earlier, she worked for Sony, Star, Investing.com and other digital start-ups, making her mark in this field."
        // },
        {
            img: ourTeam5,
            name: "Ninad Nagwekar",
            designation: "Tech Lead",
            description: "Ninad, a passionate tech professional, specialises in coding and integration of complex backend functionalities. As a full-stack developer and programming expert, he has over 10 years’ experience in leading tech teams in startups, that has come in play in building a martech platform like WISR. His prior experience includes a stint in Business Standard and in a start-up organisation, where he handled clients globally."
        },
        {
            img: ourTeam6,
            name: "Rohan Phatarpekar",
            designation: "Project Manager",
            description: "Rohan specialises in end-to-end project management for WISR to ensure a flawless execution of the platform. He has over a decade’s experience managing complete project life-cycles across domains in IT, Hospitality, Automobile and Banking, as well as managing Scrum teams. He is a certified Scrum Master as well as a PRINCE 2 Practitioner."
        },
        {
            img: ourTeam7,
            name: "Bilal Ansari",
            designation: "Lead-Data",
            description: "In today’s martech era, data is the new economy. WISR is powered by robust data management and analytics that enables us to provide proactive, insightful and customized brand solutions. Bilal’s expertise in handling big data to improve efficiencies and enable smart decision making, ensures a strong product strategy and effective campaign results at WISR. Prior to this, he worked as Data Manager at ByteDance India (TikTok), and Senior Manager - Data Strategy for Digitas India."
        },
        {
            img: ourTeam8,
            name: "Hariprasad Kotian",
            designation: "Lead- School Partnerships",
            description: "With 15+ years’ in the field of experiential marketing, Hariprasad brings an in-depth understanding of school operations, which is a critical asset for effective school partnerships. At WISR, he specializes in on-boarding schools at a national level and leads school database management."
        },
        {
            img: ourTeam9,
            name: "Bharat Panchal",
            designation: "Lead-Operations",
            description: "Bharat specialises in managing the nation-wide execution and vendor network to scale extensions at schools. His expertise is to transform ideas into actual campaigns through flawless implementation of campaigns. At WISR, he is also responsible for materialising the partnerships between schools and brands. His earlier stints comprise brand solutions at Colors TV, Samsung, Star Plus, Asian Paints and Zee TV."
        }

    ])
    const [partnerSchools, setPartnerSchools] = useState([
        {
            img: ourPartnerLogo1_1,
        },
        {
            img: ourPartnerLogo1_2,
        },
        {
            img: ourPartnerLogo1_3,
        },
        {
            img: ourPartnerLogo1_4,
        },
        {
            img: ourPartnerLogo1_5,
        },
        {
            // img: ourPartnerLogo2,
            img: ourPartnerLogo2,
        },
        // {
        //     img: ourPartnerLogo3,
        // },
        {
            img: ourPartnerLogo4,
        },
        // {
        //     img: ourPartnerLogo5,
        // },
        {
            img: ourPartnerLogo6,
        },
        {
            img: ourPartnerLogo7,
        },
        {
            img: ourPartnerLogo8,
        },
        {
            img: ourPartnerLogo9,
        },
        {
            img: ourPartnerLogo10,
        },
        {
            img: ourPartnerLogo11,
        },
        {
            img: ourPartnerLogo12,
        },
        // {
        //     img: ourPartnerLogo13,
        // },
        {
            img: ourPartnerLogo14,
        },
        {
            img: ourPartnerLogo15,
        },
        {
            img: ourPartnerLogo16,
        },
        {
            img: ourPartnerLogo17,
        }

    ])

    // our brand logos starts 

    const [brandLogos, setBrandLogos] = useState([
        {
            // img: ourPartnerLogo2,
            img: partnerBrandimg1,
        },
        // {
        //     img: ourPartnerLogo3,
        // },
        {
            img: partnerBrandimg2,
        },
        // {
        //     img: ourPartnerLogo5,
        // },
        {
            img: partnerBrandimg3,
        },
        {
            img: partnerBrandimg4,
        },
        {
            img: partnerBrandimg5,
        },
        {
            img: partnerBrandimg6,
        },
        {
            img: partnerBrandimg7,
        },
        {
            img: partnerBrandimg8,
        },
        {
            img: partnerBrandimg9,
        },
        {
            img: partnerBrandimg10,
        },
        {
            img: partnerBrandimg11,
        },
        {
            img: partnerBrandimg12,
        },
        {
            img: partnerBrandimg13,
        },
        // {
        //     img: ourPartnerLogo10,
        // },
        // {
        //     img: ourPartnerLogo11,
        // },
        // {
        //     img: ourPartnerLogo12,
        // },
        // {
        //     img: ourPartnerLogo13,
        // },
        // {
        //     img: ourPartnerLogo14,
        // },
        // {
        //     img: ourPartnerLogo15,
        // },
        // {
        //     img: ourPartnerLogo16,
        // },
        // {
        //     img: ourPartnerLogo17,
        // }
    ])

    // our brand logos ends 

    const [getNewsDetails, setNewsDetails] = useState([]);

    const [showTermsPopUp, setShowTermsPopUp] = useState(false)
    const [showPrivacyPopUp, setShowPrivacyPopUp] = useState(false)

    const onContatcUsDataChange = (event) => {
        setContactUsData({
            ...contactUsData,
            [event.target.id]: event.target.value
        })
        setSubmitMsg("")
    }
    const onSubmit = async () => {
        const result = formValidator.current.allValid()
        if (result == false) {
            formValidator.current.showMessages()
            forceUpdate(true)
            return
        } else {
            formValidator.current.hideMessages()
            forceUpdate(!update)
        }
        let publicContextInitiator = new PublicContextInitiator(context);
        let resp = await publicContextInitiator.addContactUsUser(
            context.validator, contactUsData
        ).catch((err) => {
            // console.log("error occured...", err)
        })

        if (resp && resp.done) {
            //   toast.success("Submitted")
            setSubmitMsg("Message submited successfully. Thank You!")
            setContactUsData({
                ...contactUsData,
                name: "",
                email: "",
                message: ""
            })
        }
        else {
            toast.success("There was some issue")
        }
        // console.log(resp)
    }
    formValidator.current.rules['name'] = {
        message: 'Please enter valid name.',
        rule: (val) => {
            let returnValue = true
            if (!val || val.trim() === "") {
                returnValue = false
            }
            const regex2 = /^[a-zA-Z\s]*$/;
            if (!regex2.test(val)) {
                returnValue = false
            }
            return returnValue
        },
    }

    const scrollToTargetAdjusted = (ref, offsetValue) => {
        var elementPosition = ref.current.getBoundingClientRect().top;
        var offsetPosition = elementPosition + window.pageYOffset - offsetValue;

        window.scrollTo({
            top: offsetPosition,
            behavior: "smooth"
        });
        let menuButton: any = document.getElementById("menu-btn")
        if (menuButton) {
            menuButton.checked = false
            setOverlay(false)
        }
    }
    // useEffect(() => {
    //     if(navigator.geolocation){
    //         navigator.geolocation.getCurrentPosition((position) => {
    //             console.log("geoLocation", position.coords)
    //         })
    //     }
    // }, [])
    const openTermsPopUp = () => {
        setShowTermsPopUp(true)
        setOverlay(true)
    }
    const closeTermsPopUp = () => {
        setShowTermsPopUp(false)
        setOverlay(false)
    }
    const openPrivacyPopUp = () => {
        setShowPrivacyPopUp(true)
        setOverlay(true)
    }
    const closePrivacyPopUp = () => {
        setShowPrivacyPopUp(false)
        setOverlay(false)
    }
    const [showVideo, setVideoData] = useState(false);
    const [YoutubeEmbedUrl, setYoutubeEmbedUrl] = useState("");
    const [blockTitle, setBlockTitle] = useState("");
    const [menuTitle, setMenuTitle] = useState("");

    const [showNews, setShowNews] = useState(false);
    const [ShowNewsImage, setShowNewsImage] = useState(false);
    const [NewsActive, setNewsActive] = useState("");
    const [NewsTitle, setNewsTitle] = useState("");
    const [NewsUrl, setNewsUrl] = useState("");

    const getWisrLaunchVideo = async () => {

        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },

        };

        fetch("/api/getWisrVideoData", requestOptions)
            .then(response => response.json())
            .then(async data => {


                let videoDetails = data.result.response;
                //setVideoData(true)
                setVideoData(videoDetails.showVideo)
                setYoutubeEmbedUrl(videoDetails.videoUrl)
                setBlockTitle(videoDetails.blockTitle)
                setMenuTitle(videoDetails.menuTitle)
            }).catch(err => {
                // console.log(err);
            })

    }
    const getWisrNewApiData = () => {

        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },

        };

        fetch("/api/getWisrNewApiData", requestOptions)
            .then(response => response.json())
            .then(async data => {

                // console.log("-----------came to the New Api Data------------");
                let newsDetails = data.result.response;
                // console.log("newsDetails---------->",newsDetails);
                setNewsDetails(newsDetails)

            }).catch(err => {
                // console.log(err);
            })
    }
    const getDashboardStats = async () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: '{"bucket":"wisr-json","path":"numbers_stats.json"}'
        };
        let resp = await fetch("/api/readFileFromS3", requestOptions)
        let data = await resp.json()
        if (data && data.done) {
            setStatsData([...data.result])
        }
    }

    const getSocialMediaObject = async () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: '{"bucket":"wisr-json","path":"wisr-footer.json"}'
        };
        let resp = await fetch("/api/readFileFromS3", requestOptions)
        let data = await resp.json()
        // console.log("kjhgf", resp, data)
        if (data && data.done) {
            setShowSocialMediaIcons({ ...data.result[0] })
        }
    }

    const getHappySchoolsData = async () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: '{"bucket":"wisr-json","path":"happy_schools.json"}'
        };
        let resp = await fetch("/api/readFileFromS3", requestOptions)
        let data = await resp.json()
        if (data && data.done) {
            setHappySchoolData([...data.result])
            // console.log("happy_brandsSchool", resp, data)
        }
    }
    const getHappyBrandsData = async () => {
        // const requestOptions = {
        //     method: 'POST',
        //     headers: {'Content-Type': 'application/json'},
        //     body: '{"bucket":"wisr-json","path":"happy_brands.json"}'
        // };
        // let resp = await fetch("/api/readFileFromS3", requestOptions)
        // let data = await resp.json()
        // console.log("happy_brands", resp, data)
        // if(data && data.done){
        //     setHappyBrandsData([...data.result])
        // }
        setHappyBrandsData([...happyBrands])
    }

    // Disable View Source code and Copy code and control view source code starts
    hotkeys('shift+ctrl+c, shift+command+c', function (event) {
        event.preventDefault();
        return false;
    });

    hotkeys('ctrl+c, command+c', function (event) {
        event.preventDefault();
        return false;
    });

    hotkeys('ctrl+u, command+u', function (event) {
        event.preventDefault();
        return false;
    });

    // Disable View Source code and Copy code and control view source code ends

    useEffect(() => {
        if (history.location.pathname === "/") {
            window.scrollTo(0, 0)
        }
    }, [history.location.pathname])

    useEffect(() => {
        getWisrLaunchVideo()
        getWisrNewApiData()
        getDashboardStats()
        getHappySchoolsData()
        getHappyBrandsData()
        getSocialMediaObject()
    }, [])

    useEffect(() => {
        if (counterOn === true) {
            // setTimeout(() => {
            let valueDisplays = document.querySelectorAll(".num");

            valueDisplays.forEach(valueDisplay => {

                let endValue = parseFloat(valueDisplay.getAttribute("data-val"));
                if (Number.isInteger(endValue)) {
                    let startValue = 0;
                    // console.log("This is end value ", endValue);
                    let duration = Math.floor(3000 / endValue)
                    let counter = setInterval(() => {
                        if (endValue - startValue < 1 && endValue - startValue > 0) {
                            startValue += endValue - startValue
                        }
                        else {
                            startValue += 1
                        }
                        valueDisplay.textContent = startValue.toString()
                        if (startValue == endValue) {
                            clearInterval(counter)
                        }
                    }, duration)

                }

                else {
                    let startValue = 0.0;
                    // console.log("This is end value ", endValue);
                    let duration = Math.floor(500 / endValue)
                    let counter = setInterval(() => {
                        if (endValue - startValue < 0.1 && endValue - startValue > 0) {
                            startValue += endValue - startValue
                        }
                        else {
                            startValue += 0.1
                        }
                        // valueDisplay.textContent = startValue.toString()
                        valueDisplay.textContent = startValue.toFixed(1)
                        if (startValue == endValue) {
                            clearInterval(counter)
                        }
                    }, duration)


                }

            })
        }
    }, [counterOn])
    const schoolData = [
        {
            id: 1,
            title: "AV video",
            Image: schoolGenerateImg
        },
        {
            id: 2,
            title: 'Classroom Benches',
            label: 'Classroom Benches',
            Image: schoolCollage
        }
    ];
    const brandData = [
        {
            id: 1,
            title: 'Classroom Benches',
            label: 'Classroom Benches',
            Image: schoolGenerateImg1
        },
        {
            id: 2,
            title: "SFA video",
            video: 'kH5MdSvMEkA',
            credit: "Video by cottonbro from Pexels",
        },
        {
            id: 3,
            title: "SFA video",
            video: 'YwfvKLUMEVU',
            credit: "Video by cottonbro from Pexels",
        }
    ];

    let happyBrands = [
        {
            "hb_order": 1,
            "hb_title": "Mr. Antony John",
            "hb_logo": "https://wisr-json.s3.ap-south-1.amazonaws.com/principal1.png",
            "hb_designation": "CMO",
            "hb_school": "Nyke",
            "hb_text": "WISR has been a huge help to our school. I would recommend it to everyone. WISR is extremely child concious as well, and this is in line with our values. I would recommend it to everyone. WISR is extremely child concious as well, and this is in line with our values.",
            "hb_active": "Y"
        },
        {
            "hb_order": 2,
            "hb_title": "Mrs. Suhani Desai",
            "hb_logo": "https://wisr-json.s3.ap-south-1.amazonaws.com/principal2.png",
            "hb_designation": "Brand Manager",
            "hb_school": "Adibas",
            "hb_text": "WISR has been a huge help to our school. I would recommend it to everyone. WISR is extremely child concious as well, and this is in line with our values. I would recommend it to everyone. WISR is extremely child concious as well, and this is in line with our values.",
            "hb_active": "Y"
        },
        {
            "hb_order": 3,
            "hb_title": "Mrs. Samantha Jacobs",
            "hb_logo": "https://wisr-json.s3.ap-south-1.amazonaws.com/principal3.png",
            "hb_designation": "Marketing Head",
            "hb_school": "Dittol",
            "hb_text": "WISR has been a huge help to our school. I would recommend it to everyone. WISR is extremely child concious as well, and this is in line with our values. I would recommend it to everyone. WISR is extremely child concious as well, and this is in line with our values.",
            "hb_active": "Y"
        }
    ]
    // const getSchoolInfo = async () => {
    //     const requestOptions = {
    //         method: 'POST',
    //         headers: { 'Content-Type': 'application/json' },
    //         body: '{"bucket":"wisr-uat","path":"schoolStats/schoolInfo-20th Mar 2023.json"}'
    //     };


    //     let resp = await fetch("/api/readFileFromS3", requestOptions)        
    //     let data1 = await resp.json()
    //     if (data1 && data1.done) {
    //         console.log("data1.result=======>",data1.result);

    //         let schoolData = data1.result[0];
    //         let BrandOutlay = schoolData.totalBrandOutlay
    //         let TotalImpressions = schoolData.totalImpression
    //         let TotalInventoryCount = schoolData.totalInventory
    //         let TotalReach = schoolData.totalReach
    //         let TotalSchool =schoolData.totalSchools
    //     } 
    // }
    return (
        <>
            <DisableRightClick />
            <div className={`ovelap ${overlay === true ? "active" : ""}`}></div>
            <header className="headerLanding">
                <a href="" className="logo"><img src={logoLanding} /></a>
                <input className="menu-btn" type="checkbox" id="menu-btn" />
                <div className='loginBtn loginMobile'><a id='loginButton' href="javascript:void(0);" className='btn btn-orange toggle' data-target="landingPopup" onClick={() => history.push("/login")}>Login</a></div>
                <label className="menu-icon" htmlFor="menu-btn" onClick={openMenu}><span className="navicon"></span></label>
                <ul className="menu">
                    <li><a href="javascript:void(0);" onClick={() => scrollToTargetAdjusted(schoolRef, -50)}>Schools</a></li>
                    <li><a href="javascript:void(0);" onClick={() => scrollToTargetAdjusted(brandRef, 60)}>Brands</a></li>
                    <li><a href="javascript:void(0);" onClick={() => scrollToTargetAdjusted(aboutRef, 65)}>About</a></li>

                    <li>{showVideo === true && <a href="javascript:void(0);" onClick={() => scrollToTargetAdjusted(videoRef, 55)}>{menuTitle != '' ? menuTitle : 'Video'}</a>}</li>
                    <li><a href="javascript:void(0);" onClick={() => scrollToTargetAdjusted(contactUsRef, 65)}>Contact Us</a></li>
                    <li className='loginBtn loginDesk'><a id="landing-login" href="javascript:void(0);" className='btn btn-orange toggle' data-target="landingPopup" onClick={() => history.push("/login")}>Login</a></li>
                </ul>
            </header>
            <div className='topMargin'></div>
            <div className='landingSlide'>
                <div className='landingSlideImg'>
                    <img src={slideBg} />
                    <div className='landingSlideText'>
                        <h2>WISR believes in marketing for <br />good and leveraging the power of <br /> brands to create value for schools</h2>
                        <p>India's first technology-based platform empowering <br /> schools  through brand partnerships.</p>
                        <div className='landingSlideBtn'>
                            <a href="javascript:void(0)" onClick={() => history.push("/brands/sign-up")} className='btn btn-white'>SIgn up - Brands</a>
                            <a href="javascript:void(0)" onClick={() => history.push("/schools/onboarding/your-details")} className='btn btn-orange'>SIgn up - Schools</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mainContent'>
                <div ref={schoolRef} className='school-generate' style={{ padding: '130px 0 80px 0' }}>
                    <div className='containerLanding'>
                        <div className='generate-additional'>
                            <div className='row'>
                                <div className='col-xs-4 col-lg-5 col-xs-12'>
                                    <div className='school-generateImg'>
                                        <img style={{ height: '480px', marginTop: '40px' }} src={schoolGif} />
                                        {/* <VideoImageCarousel data={schoolData} supportPage={false} brand={false} /> */}
                                    </div>
                                </div>
                                <div className='col-xs-8 col-lg-7 col-xs-12'>
                                    <div className='school-generateTxt'>
                                        <h2 className='headhx1'>SCHOOLS</h2>
                                        <h3 className='headhx2'>Generate additional revenue with brand partnerships</h3>
                                        <p className='headhx3'>With WISR, schools can create an additional revenue stream through brand partnerships that enable them to:</p>
                                        <ul className='listAdditional'>
                                            <li><img src={checkImg} /> Pay higher salaries to teachers</li>
                                            <li><img src={checkImg} /> Enhance students’ experiences</li>
                                            <li><img src={checkImg} /> Develop better infrastructure</li>
                                        </ul>
                                        <a id='schoolSignUp' href="javascript:void(0)" onClick={() => history.push("/schools/onboarding/your-details")} className='btn btn-orange'>sign up - school</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className='our-partner-school our-partnerImg'>
                        <div className='row'>
                            <div className='col-xl-12 col-lg-12 col-xs-12'>
                                <h3>Our Partner Schools</h3>
                            </div>
                            <div className='col-xl-12 col-lg-12 col-xs-12'>
                            <div className='row'>
                            <div className='col-xl-2 col-lg-2 col-md-3 col-xs-6'>
                                <div className='our-partner-logo'>
                                    <img src={ourPartnerLogo1}></img>
                                </div>
                            </div>
                            <div className='col-xl-2 col-lg-2 col-md-3 col-xs-6'>
                                <div className='our-partner-logo'>
                                    <img src={ourPartnerLogo2}></img>
                                </div>
                            </div>
                            <div className='col-xl-2 col-lg-2 col-md-3 col-xs-6'>
                                <div className='our-partner-logo'>
                                    <img src={ourPartnerLogo3}></img>
                                </div>
                            </div>
                            <div className='col-xl-2 col-lg-2 col-md-3 col-xs-6'>
                                <div className='our-partner-logo'>
                                    <img src={ourPartnerLogo4}></img>
                                </div>
                            </div>
                            <div className='col-xl-2 col-lg-2 col-md-3 col-xs-6'>
                                <div className='our-partner-logo'>
                                    <img src={ourPartnerLogo5}></img>
                                </div>
                            </div>
                            <div className='col-xl-2 col-lg-2 col-md-3 col-xs-6'>
                                <div className='our-partner-logo'>
                                    <img src={ourPartnerLogo6}></img>
                                </div>
                            </div>
                            <div className='col-xl-2 col-lg-2 col-md-3 col-xs-6'>
                                <div className='our-partner-logo'>
                                    <img src={ourPartnerLogo7}></img>
                                </div>
                            </div>
                            </div>
                            </div>
                        </div>
                    </div> */}

                        <div className='our-partner-school'>
                            <div className='row'>
                                {/* <div className='col-xl-12 col-lg-12 col-xs-12'>
                                    <h3>Our Partner Schools</h3>
                                </div>
                                <div className='col-xl-12 col-lg-12 col-xs-12'>
                                    <div className='row our-partnerImg'> */}



                                {/* <div className='our-partner-logo'>
                                            <img src={ourPartnerLogo2}></img>
                                        </div>

                                        <div className='our-partner-logo'>
                                            <img src={ourPartnerLogo3}></img>
                                        </div>


                                        <div className='our-partner-logo'>
                                            <img src={ourPartnerLogo5}></img>
                                        </div>

                                        <div className='our-partner-logo'>
                                            <img src={ourPartnerLogo6}></img>
                                        </div>
                                        <div className='our-partner-logo'>
                                            <img src={ourPartnerLogo1}></img>
                                        </div>
                                        <div className='our-partner-logo'>
                                            <img src={ourPartnerLogo4}></img>
                                        </div>
                                        <div className='our-partner-logo'>
                                            <img src={ourPartnerLogo7}></img>
                                        </div> */}
                                {/* </div>
                                </div> */}
                            </div>
                        </div>
                        <div className='our-happy-school dnone'>
                            <div className='row'>
                                <div className='col-xl-12 col-lg-12 col-xs-12'>
                                    <h3 className='headhx2'>Our Happy Schools</h3>
                                </div>
                                <div className='col-xl-12 col-lg-12 col-xs-12'>
                                    <div className='row custom-bar'>
                                        <div className='col-xl-4 col-lg-4 col-md-6 col-xs-12'>
                                            <div className='happy-school-one oneBgcolor'>
                                                <span><img src={happySchoolImg1} /></span>
                                                <h3>Mr. Antony John</h3>
                                                <p>Principal, Mount Carmel High School</p>
                                                <div className='happy-school-text'>
                                                    <p><img src={quotes1} /> WISR has been a huge help to our school. I would recommend it to everyone. WISR is extremely child concious as well, and this is in line with our values</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-xl-4 col-lg-4 col-md-6 col-xs-12'>
                                            <div className='happy-school-one twoBgcolor'>
                                                <span><img src={happySchoolImg2} /></span>
                                                <h3>Mr. Akhil N</h3>
                                                <p>Principal, SNM High School</p>
                                                <div className='happy-school-text'>
                                                    <p><img src={quotes2} /> WISR has been a huge help to our school. I would recommend it to everyone. WISR is extremely child concious as well, and this is in line with our values</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-xl-4 col-lg-4 col-md-6 col-xs-12'>
                                            <div className='happy-school-one threeBgcolor'>
                                                <span><img src={happySchoolImg3} /></span>
                                                <h3>Mrs. Sunita Kapoor</h3>
                                                <p>Principal, Rajagiri High School</p>
                                                <div className='happy-school-text'>
                                                    <p><img src={quotes3} /> WISR has been a huge help to our school. I would recommend it to everyone. WISR is extremely child concious as well, and this is in line with our values</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <News
                    getNewsDetails={partnerSchools}
                    title={'Our Partner Schools'}
                />
                <div className='advisory-board dnone'>
                    <div className='containerLanding'>
                        <div className='row'>
                            <div className='col-xl-12 col-lg-12 col-xs-12'>
                                <h3 className='headhx2'>Advisory Board</h3>
                                <p className='headhx3'>Sports, Health, Hygiene, Edutainment are crucial to a child’s positive experience in school. The WISR advisory board ensures our brand partnerships only address these aspects and don’t heavily commercialize initiatives.</p>
                            </div>
                            <div className='col-xl-12 col-lg-12 col-xs-12'>
                                <div className='row custom-bar'>
                                    <div className='col-xl-3 col-lg-3 col-md-6 col-xs-12'>
                                        <div className='advisory-board-one'>
                                            <span><img src={advisoryBoardImg1} /></span>
                                            <h4>Mr. Nikhil K Soman</h4>
                                            <p>Educationist</p>
                                        </div>
                                    </div>
                                    <div className='col-xl-3 col-lg-3 col-md-6 col-xs-12'>
                                        <div className='advisory-board-one'>
                                            <span><img src={advisoryBoardImg2} /></span>
                                            <h4>Mrs. Sunita Kapoor</h4>
                                            <p>Child Psychologist</p>
                                        </div>
                                    </div>
                                    <div className='col-xl-3 col-lg-3 col-md-6 col-xs-12'>
                                        <div className='advisory-board-one'>
                                            <span><img src={advisoryBoardImg3} /></span>
                                            <h4>Mr. Nithin jose</h4>
                                            <p>Nutritionist</p>
                                        </div>
                                    </div>
                                    <div className='col-xl-3 col-lg-3 col-md-6 col-xs-12'>
                                        <div className='advisory-board-one'>
                                            <span><img src={advisoryBoardImg4} /></span>
                                            <h4>Mr. Akhil N</h4>
                                            <p>Legal Counsel</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <HappySchools
                    ourTeamData={happySchoolData}
                />


                <div className='school-generate statsBackGroundImg'>
                    <div className='containerLanding'>
                        <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>
                            <div className='generate-additional'>
                                <div className='row' style={{ justifyContent: 'space-around', flexWrap: 'wrap' }}>
                                    {statsData.length > 0 && statsData.map((data, index) => (
                                        <div className='statsContainer'>
                                            <div style={{ height: '55%' }}>
                                                <img style={{ height: '50px', width: 'auto' }} src={index === 0 ? citySvg : index === 1 ? inventriesSvg : index === 2 ? studentSvg : impressionSvg} />
                                            </div>
                                            <div style={{ height: '25%' }}>
                                                {/* {counterOn && 1+1 }  */}
                                                {counterOn && <span className='num' data-val={data.stats_numbers} style={{ fontSize: '22px', color: '#ff9406' }}>00</span>}
                                                <span style={{ fontSize: '22px', color: '#ff9406' }}>{data.stats_numbers_text}</span>
                                            </div>
                                            <span style={{ fontSize: '16px', color: '#8a8a8a', height: '20%' }}>{data.stats_title}</span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </ScrollTrigger>
                        {/* here  */}
                    </div>
                </div>

                <div ref={brandRef} className='school-generate pn-top'>
                    <div className='containerLanding'>
                        <div className='generate-additional'>
                            <div className='row'>
                                <div className='col-xs-8 col-lg-7 col-xs-12'>
                                    <div className='school-generateTxt pL'>
                                        <h2 className='headhx1'>BRANDS</h2>
                                        <h3 className='headhx2'>India’s first tech platform that helps brands connect with schools.</h3>
                                        <p className='headhx3'>Engage with over thousands of children across multiple cities. Measure the success of your school campaign with transparency and precision.</p>
                                        <ul className='listAdditional'>
                                            <li><img src={checkImg} /> Platform intelligence selects most relevant schools and inventory </li>
                                            <li><img src={checkImg} /> The most cost-effective platform for School campaigns</li>
                                            <li><img src={checkImg} /> Intuitive platform for a frictionless campaign, inception to execution</li>
                                            <li><img src={checkImg} /> Forge the deepest brand connections with your TG.</li>
                                        </ul>
                                        <a id='brandSignUp' href="javascript:void(0);" className='btn btn-orange' onClick={() => history.push("/brands/sign-up")}>sign up - brand</a>
                                    </div>
                                </div>
                                <div className='col-xs-4 col-lg-5 col-xs-12'>
                                    <div className='school-generateImg'>
                                        {/* <img src={schoolGenerateImg1} /> */}
                                        <VideoImageCarousel data={brandData} supportPage={false} brand={true} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className='our-partner-school'>
                        <div className='row'>
                            <div className='col-xl-12 col-lg-12 col-xs-12'>
                                <h3>Our Partner Brands</h3>
                            </div>
                            <div className='col-xl-12 col-lg-12 col-xs-12'>
                            <div className='row'>
                            <div className='col-xl-2 col-lg-2 col-md-3 col-xs-6'>
                                <div className='our-partner-logo'>
                                    <img src={partnerBrandimg1}></img>
                                </div>
                            </div>
                            <div className='col-xl-2 col-lg-2 col-md-3 col-xs-6'>
                                <div className='our-partner-logo'>
                                    <img src={partnerBrandimg2}></img>
                                </div>
                            </div>
                            <div className='col-xl-2 col-lg-2 col-md-3 col-xs-6'>
                                <div className='our-partner-logo'>
                                    <img src={partnerBrandimg3}></img>
                                </div>
                            </div>
                            <div className='col-xl-2 col-lg-2 col-md-3 col-xs-6'>
                                <div className='our-partner-logo'>
                                    <img src={partnerBrandimg4}></img>
                                </div>
                            </div>
                            <div className='col-xl-2 col-lg-2 col-md-3 col-xs-6'>
                                <div className='our-partner-logo'>
                                    <img src={partnerBrandimg5}></img>
                                </div>
                            </div>
                            <div className='col-xl-2 col-lg-2 col-md-3 col-xs-6'>
                                <div className='our-partner-logo'>
                                    <img src={partnerBrandimg6}></img>
                                </div>
                            </div>
                            </div>
                            </div>
                        </div>
                    </div> */}
                        <div className='our-happy-school dnone'>
                            <div className='row'>
                                <div className='col-xl-12 col-lg-12 col-xs-12'>
                                    <h3 className='headhx2'>Our Happy Brands</h3>
                                </div>
                                <div className='col-xl-12 col-lg-12 col-xs-12'>
                                    <div className='row custom-bar'>
                                        <div className='col-xl-4 col-lg-4 col-md-6 col-xs-12'>
                                            <div className='happy-school-one oneBgcolor'>
                                                <span><img src={happySchoolImg1} /></span>
                                                <h3>Mr. Antony John</h3>
                                                <p>Principal, Mount Carmel High School</p>
                                                <div className='happy-school-text'>
                                                    <p><img src={quotes1} /> WISR has been a huge help to our school. I would recommend it to everyone. WISR is extremely child concious as well, and this is in line with our values</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-xl-4 col-lg-4 col-md-6 col-xs-12'>
                                            <div className='happy-school-one twoBgcolor'>
                                                <span><img src={happySchoolImg2} /></span>
                                                <h3>Mr. Akhil N</h3>
                                                <p>Principal, SNM High School</p>
                                                <div className='happy-school-text'>
                                                    <p><img src={quotes2} /> WISR has been a huge help to our school. I would recommend it to everyone. WISR is extremely child concious as well, and this is in line with our values</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-xl-4 col-lg-4 col-md-6 col-xs-12'>
                                            <div className='happy-school-one threeBgcolor'>
                                                <span><img src={happySchoolImg3} /></span>
                                                <h3>Mrs. Sunita Kapoor</h3>
                                                <p>Principal, Rajagiri High School</p>
                                                <div className='happy-school-text'>
                                                    <p><img src={quotes3} /> WISR has been a huge help to our school. I would recommend it to everyone. WISR is extremely child concious as well, and this is in line with our values</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <News
                    getNewsDetails={brandLogos}
                    title={'Our Happy Brands'}
                />

                <div ref={aboutRef} className='advisory-board'>
                    <div className='containerLanding'>
                        <div className='row alignCenter'>
                            <div className='col-xl-6 col-lg-6 col-md-5 col-xs-12'>
                                <img src={aboutImg} className='imgAboutSchool' />
                            </div>
                            <div className='col-xl-6 col-lg-6 col-md-7 col-xs-12'>
                                <h3 className='headhx2'>About</h3>
                                <p className='headhx3'>WISR believes in marketing for good through which schools, teachers and children benefit. Schools can monetize brand partnerships through our key pillars of Sports, Health, Hygiene and Edutainment. This helps them better their infrastructure to enrich their students' learning experience, and projects a 20% increase in teachers' salaries over the years. WISR offers a fresh look at how brands engage with children.</p>
                            </div>
                        </div>
                    </div>
                </div>
                {showVideo === true &&
                    <div ref={videoRef} className='video-board'>
                        <div className='containerLanding'>
                            <div className='generate-additional'>
                                <div className='row'>
                                    <div className='col-xs-8 col-lg-12 col-xs-12'>
                                        <div className='school-generateTxt youtubeVideoTitle'>
                                            <h3 className='headhx2' >{blockTitle != '' ? blockTitle : 'WISR Launch video'}</h3>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                        <Youtube embedId={YoutubeEmbedUrl} noMargin={false} brand={false} />
                    </div>
                }
                <OurTeamScroll
                    ourTeamData={ourTeamData}
                />
                {/* <HappyBrands
                    ourTeamData={happyBrandsData}
                /> */}
                <News
                    getNewsDetails={getNewsDetails}
                    newsSection={true}
                />
            </div>
            <div className='footer'>
                <div ref={contactUsRef} className='footerTop'>
                    <div className='containerLanding'>
                        <div className='row'>
                            <div className='col-xl-6 col-lg-6 col-xs-12'>
                                <h3 className="headhx2">Contact Us</h3>
                                <p className="headhx3">Connect with us at <span style={{ color: '#ff9406' }}>+91 8454008369</span> today and unleash the <br /> power of WISR</p>
                            </div>
                            <div className='col-xl-6 col-lg-6 col-xs-12'>
                                <form>
                                    <div className='row'>
                                        <div className='col-xl-9 col-lg-9 col-xs-12'>
                                            <div className="forminpt">
                                                <label>Name</label>
                                                {/* <input placeholder="Enter name" type="text" value="" /> */}
                                                <TextField
                                                    formData={contactUsData}
                                                    formKey='name'
                                                    onDataChange={onContatcUsDataChange}
                                                    placeholder="Enter name"
                                                    isRequired={true}
                                                    formValidator={formValidator}
                                                    validation="required|name"
                                                    maxLength={50}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-xl-9 col-lg-9 col-xs-12'>
                                            <div className="forminpt">
                                                <label>Email</label>
                                                {/* <input placeholder="Enter email" type="text" value="" /> */}
                                                <TextField
                                                    formData={contactUsData}
                                                    formKey='email'
                                                    onDataChange={onContatcUsDataChange}
                                                    placeholder="Enter email"
                                                    isRequired={true}
                                                    formValidator={formValidator}
                                                    validation="required|email"
                                                />
                                            </div>
                                        </div>
                                        <div className='col-xl-9 col-lg-9 col-xs-12'>
                                            <div className="forminpt">
                                                <label>Message</label>
                                                {/* <textarea></textarea> */}
                                                <TextArea
                                                    formData={contactUsData}
                                                    formKey='message'
                                                    onDataChange={onContatcUsDataChange}
                                                    isRequired={true}
                                                    formValidator={formValidator}
                                                    validation="required"
                                                />
                                            </div>
                                        </div>
                                        <div className='col-xl-9 col-lg-9 col-xs-12'>
                                            <div className="forminpt">
                                                <input onClick={onSubmit} name="submit" className="btn btn-orange w100" type="button" value="submit" />
                                            </div>
                                        </div>
                                        <div className='col-xl-9 col-lg-9 col-xs-12'>
                                            <div className="forminpt">
                                                <label>{submitMsg}</label>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    {/* <div className='containerLanding'>
                        <div className='row'>
                            <div className='col-xl-6 col-lg-6 col-xs-12'>
                                <a id='brandSignUp' href="javascript:void(0);" className='btn btn-orange' 
                                style={{display:'none'}}
                                onClick={getSchoolInfo} 
                                >School Info</a>
                            </div>
                        </div>
                    </div> */}
                </div>
                <div className='footerBot'>
                    <div className='containerLanding'>
                        <div className='row'>
                            <div className='col-xl-3 col-lg-3 col-md-3 col-xs-12'>
                                <img src={footerLogo} />
                            </div>
                            <div className='col-xl-6 col-lg-6 col-md-6 col-xs-12'>
                                <div className='footerLink'>
                                    <ul>
                                        {/* <li>© WISR 2023</li> */}
                                        <li>© WISR {(new Date().getFullYear())}</li>
                                        <li>|</li>
                                        <li><a style={{ cursor: "pointer" }} onClick={openTermsPopUp}>Terms of Use</a></li>
                                        <li>|</li>
                                        <li><a style={{ cursor: "pointer" }} onClick={openPrivacyPopUp}>Privacy Policy</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className='col-xl-3 col-lg-3 col-md-3 col-xs-12'>
                                <div className='footerSocial'>
                                    {showSocialMediaIcons.youtube && <a href='https://www.youtube.com/channel/UCd3licHsV7jDP-AKPe3LtXw' target='_blank'><img src={you1} /></a>}
                                    {showSocialMediaIcons.instagram && <a href='https://www.instagram.com/wisr.official/' target='_blank'><img src={insta1} /></a>}
                                    {showSocialMediaIcons.facebook && <a href='https://www.facebook.com/Wisr-106401012077972' target='_blank'><img src={fb1} /></a>}
                                    {showSocialMediaIcons.linkedin && <a href='https://www.linkedin.com/company/wisr-india/' target='_blank'><img src={link1} /></a>}
                                    {/* <a href=''><img src={you1} /></a>
                            <a href=''><img src={insta1} /></a>
                            <a href=''><img src={fb1} /></a>
                            <a href=''><img src={link1} /></a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="landingPopup" className={`popup landingPopup ${togglePopup === true ? "" : "hide"}`}>
                <div className="popup-body">
                    <div className="loginbox">
                        <span onClick={hideModal} className="close toggle" data-target="closePopup"><img src={closePopup} /></span>
                        <div className="login-wave backendlogin"> <img src={loginBgLeft} alt="login-wave" /> </div>
                        <div className="login-form">
                            <div className="row">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb10">
                                    <img src={logo} alt="logo" />
                                    <h2 className="htx1">Welcome to WISR</h2>
                                </div>
                            </div>
                            <form data-form-validation="login-form" id="login-form-parent">
                                <div className="row">
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                        <div className="forminpt">
                                            <label>Email</label>
                                            <input placeholder="Enter Email ID" type="text" value="" />
                                        </div>
                                    </div>
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                        <div className="forminpt">
                                            <label>Password</label>
                                            <input placeholder="Enter Password" type="text" value="" />
                                        </div>
                                    </div>
                                    <div className="row col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                        <div className="forminpt">
                                            <input value="Login" className="btn btn-orange datasubmit" type="button" />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <OurTeamDetails
                setTeamdetailpopup={setTeamdetailpopup}
                teamdetailpopup={teamdetailpopup}
                onModalClose={onModalClose}
            />
            <PrivacyAndPolicy
                showMou={showPrivacyPopUp}
                onToggle={closePrivacyPopUp}
            />
            <TermsAndConditions
                showMou={showTermsPopUp}
                onToggle={closeTermsPopUp}
            />
            {
            /*<WhatsAppAndCall
                contactUsRef={contactUsRef}
                scrollToTargetAdjusted={scrollToTargetAdjusted}
            />*/

            }
            
        </>
    )
}

export default LandingPage
