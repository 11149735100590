import { useEffect, useState, useContext } from "react"
import { useHistory } from "react-router-dom"

import config from "../../../../../service/config"
import DataContext from "../../../../../service/contextProvider"

import ConfirmDialog from "../../../../../components/addon/confirmDialog/confirmDialog"
import ConfirmAlert from "../../../../../components/addon/confirmAlert"
import Loader from "../../../../../components/addon/loader"

import star from "../../../../../assets/images/star.svg"
// import close from "../../../../../assets/images/close-1.svg"
import close from "../../../../../assets/images/close-events.svg"
import { objectDeepClone, compareObjs, formatInventoriesForEvents, hasOnlyLettersAndSpaces } from "../../../../../service/helper"
import { Steps } from "intro.js-react"
import AppContextObject from "../../../../../common/context/common"
import errorSVG from "../../../../../assets/images/error.svg"
import menu from "../../../../../assets/images/menu.svg"

interface Props {
    isEdit ? : boolean
    onProceed?: Function
    setShowMenu?: Function
    redirectionURL?: string,
    hideRevenue?: boolean,
    showQuitButton?: any,
    toggleSidemenu ? : Function,
    showSidemenu ? : boolean,
    fromBackend?: boolean,
    schoolId?: any
}

const SchoolActivities = ({
        isEdit,
        onProceed,
        setShowMenu,
        redirectionURL,
        hideRevenue,
        showQuitButton,
        toggleSidemenu,
        showSidemenu,
        fromBackend,
        schoolId
    }: Props) => {
    // useContext
    const history = useHistory()
    const parentData = useContext < any > (DataContext)
    const context = useContext(AppContextObject)

    //amenities useState
    const [studentAmenities, setStudentAmenities] = useState(config.studentAmenities)

    const [defaultObj] = useState({
        id: "",
        name: "",
        custom: true,
        availableForBranding: false,
        venue: [],
        months: [],
        date: "31",
        amenities: [{
            name: "Stage Backdrop",
            availableForBranding: false,
            allowBrandsToProvide: false,
            _checked: false,
            images: [],
            count: 1,
            isSelected: false,
            isCustom: false,
            data: {
                quantity: 0,
                units: "feet",
                length: 0,
                height: 0,
                breadth: 0
            }
        },
        {
            name: "Stage Front",
            availableForBranding: false,
            allowBrandsToProvide: false,
            _checked: false,
            images: [],
            count: 1,
            isSelected: false,
            isCustom: false,
            data: {
                quantity: 0,
                units: "feet",
                length: 0,
                height: 0,
                breadth: 0,
            }
        },
        {
            name: "Stage Wings",
            availableForBranding: false,
            allowBrandsToProvide: false,
            _checked: false,
            images: [],
            count: 1,
            isSelected: false,
            isCustom: false,
            data: {
                quantity: 0,
                units: "feet",
                length: 0,
                height: 0,
                breadth: 0
            }
        }],
        otherVenueList: []
    })
    const [overlay, setOverlay] = useState(false)
    const [toggleActivityDeleteConfirm, setToggleActivityDeleteConfirm] = useState(false)
    const [toggleAmenityDeleteConfirm, setToggleAmenityDeleteConfirm] = useState(false)
    const [selectedAmenityToDeleteIdx, setSelectedAmenityToDeleteIdx] = useState(null)
    const [selectedEntityName, setselectedEntityName] = useState("")
    const [amenities, setAmenities] = useState([])
    const [customAmenity, setCustomAmenity] = useState(false)
    const [isCustomAmenityAdd, setIsCustomAmenityAdd] = useState(false)
    const [isCustomActivityAdd, setIsCustomActivityAdd] = useState(false)
    const [isEditCustomActivity,setIsEditCustomActivity] = useState(false)
    const [newAmenity, setNewAmenity] = useState("")
    const [amenityError, setAmenityError] = useState(false)
    const [amenityToEdit, setAmenityToEdit] = useState(null)
    const [amenityEditFlag, setAmenityEditFlag] = useState(false)
    const [editAmenityValue, setEditAmenityValue] = useState("")
    const [amenityCopy, setAmenityCopy] = useState({})
    const [amenityEditError, setAmenityEditError] = useState(false)

    //activities useState
    const [activities, setActivities] = useState([])
    const [selectedActivities, setSelectedActivities] = useState([])
    const [customEvent, setCustomEvent] = useState(false)
    const [newActivity, setNewActivity] = useState("")
    const [error, setError] = useState(false)
    const [selectAll, setSelectAll]: any = useState(false)
    const [allAmenitiesSelected, setAllAmenitiesSelected]: any = useState(false)
    const [editValue, setEditValue] = useState("")
    const [oldEditValue, setOldEditValue] = useState("")
    const [editFlag, setEditFlag] = useState(false)
    const [editCopy, setEditCopy] = useState<any>({})
    const [toBeEdited, setToBeEdited] = useState(0)
    const [activityError, setActivityError] = useState(false)
    const [sameNameError, setSameNameError] = useState(false)
    const [showPrompt, setShowPrompt] = useState(false)
    const [alphabeticError, setAlphabeticError] = useState(false)

    const [loader, setLoader] = useState(false)
    const [masterList, setMasterList] = useState([])
    const [recheck, setRecheck] = useState(false)
    const [showQuitModal, setShowQuitModal] = useState(false)
    const [existingEvents, setExistingEvents] = useState([])
    const [duplicateNameErrMsg, setDuplicateNameErrMsg] = useState("Activity name can't be same.")
    const [onBoarding, setOnboarding] = useState({
        stepsEnabled: false,
        initialStep: 0,
        steps: [
            {element: "#activity0", intro: "Add all events in your school to allow brands to use as inventory.", position: "right"},
            {element: "#addCustomEvent", intro: "If you don't find event that you can offer in this list, click here and add custom events.", position: "right"}
        ],
        options: {
            exitOnOverlayClick: false,
            showStepNumbers: true,
            hidePrev: true,
            hideNext: false,
            nextLabel: "Next",
            prevLabel: "Prev",
            doneLabel: "Got it",
            autoPosition: false,
            exitOnEsc: false,
            scrollToElement: false,
            disableInteraction: true
        },
    })
    useEffect(() => {
        setTimeout(() => {
            setOnboarding({...onBoarding, stepsEnabled: parentData?.tooltipProgress?.eventsAndOccasions})
        }, 100)
    }, [])

    useEffect(() => {
        const isSame = compareObjs(parentData?.data?.student?.activities, selectedActivities)

        if (isSame === false) {
            setShowPrompt(true)
        } else {
            setShowPrompt(false)
        }
    }, [selectedActivities])

    useEffect(() => {
        if (setShowMenu) {
            setShowMenu(true)
        }
        setLoader(true)
        let list = []
        if (parentData.listOfEvents && parentData.listOfEvents.length > 0) {
            list = formatInventoriesForEvents(parentData.listOfEvents)
        } else if (parentData.listOfInventories && parentData.listOfInventories.length > 0) {
            list = formatInventoriesForEvents(parentData.listOfInventories)
        }
        setMasterList(list)
        process.nextTick(() => {
            setLoader(false)
        })

        if (parentData.setActiveModule) {
            parentData.setActiveModule({
                module: "inventoryDetails",
                subModule: "events"
            })
        }
    }, [])

    useEffect(() => {
        fillActivityDetails()
    }, [parentData?.data?.student?.life, masterList])

    const confirmAlertCallback = (data) => {
        setOverlay(data.overlay)
    }

    const fillActivityDetails = () =>{
        if (parentData?.data?.student?.life && parentData?.data?.student?.life.length > 0) {
            const arr = objectDeepClone(studentAmenities)
            arr.forEach((el, i) => {
                const found = parentData?.data?.student?.life.find((e) => e.name === el.name)
                if (found) {
                    arr[i] = found
                }
            })
            const custom = parentData?.data?.student?.life.filter((el) => el.custom === true)
            arr.push(...custom)
            setStudentAmenities(arr)
            if (arr.filter(el => el._checked !== true).length === 0) {
                setSelectAll(true)
            }
        }
        if (parentData?.data?.student?.activities && parentData?.data?.student?.activities.length > 0) {
            const arr = objectDeepClone(parentData.data.student.activities)
            let selectedItems = objectDeepClone(selectedActivities)
            let items = []
            arr.forEach((el) => {
                let exist;
                if(isEdit === true){
                    exist = selectedActivities.find((act) => act.name === el.name)
                }else{
                    exist = selectedActivities.find((act) => act.name === el.name)
                }
                if (!exist) {
                    selectedItems.push(el)
                }
            })
            setSelectedActivities(selectedItems)
            masterList.forEach(element => {
                let found;
                if(isEdit === true){
                    found = selectedItems.find(e => e.name === element.name)
                }else{
                    found = selectedItems.find(e => e.name === element.name)
                }
                if (!found) {
                    items.push(element)
                }
            })
            const customItems = selectedItems.filter(el => el.custom === true)
            let array = items.concat(customItems)
            let sortedEventArray = array.sort((a, b) => {
                let nameA = a.name.toUpperCase();
                let nameB = b.name.toUpperCase();
                if (nameA < nameB) {
                  return -1; 
                }
                if (nameA > nameB) {
                  return 1; 
                }
                return 0;
            })
            setActivities(sortedEventArray)
            const custom = parentData?.data?.student?.activities.filter((el) => el.custom === true)
            arr.push(...custom)
            if (selectedItems.length === studentAmenities.length) {
                if(isEdit === true){
                    setAllAmenitiesSelected(false)
                }else{
                    setAllAmenitiesSelected(true)
                }
            }
        } else {
            setActivities(masterList)
        }
    }

    const onSubmit = async () => {
        setShowPrompt(false)
    console.log("selectedActivities>>>",selectedActivities);
        // process.nextTick(() => {
        //     if (redirectionURL) {
        //         const url = selectedActivities.length > 0 ? redirectionURL
        //         : "/schools/school-details/add-invetory/school-details-summary"
        //         onProceed(url)                
        //     } else if (isEdit === true) {
        //         onProceed("/schools/school-details/details/activities/amenity-details")
        //     } else {
        //         const url = selectedActivities.length > 0 ? "/schools/onboarding/events-and-occasions-details"
        //         : "/schools/onboarding/onboarding-summary"
        //         onProceed(url)
        //     }
        // })
        if (selectedActivities.length === 0) {
            if (parentData.setProgress) {
                parentData.setProgress({
                    ...parentData.progress,
                    eventsAndOccasions: true
                })
            }
        }
        if(!hideRevenue){
            console.log("arr>>>",selectedActivities, parentData.data.algorithmData.events);
            let algoAllData = []
            let amdata = []
              for(let amenity of selectedActivities){
                for(let amenityAttr of amenity.amenities){
                  console.log("amenityAttr>>>>",amenityAttr);
                    let algoData = {
                      name: amenity.name || "",
                      placement: [],
                      sizeOfPlacement: [],
                      noOfPlacement: [],
                    }
                    algoData.placement.push(amenityAttr.name)
                    console.log("amenityAttr-->",amenityAttr.data.length,amenityAttr.height, amenityAttr.breadth);
                    let length = Number(amenityAttr.data.length) === 0 ? 1 : Number(amenityAttr.data.length)
                    let breadth = Number(amenityAttr.data.breadth) === 0 ? 1 : Number(amenityAttr.data.breadth)
                    let height = Number(amenityAttr.height) === 0 ? 1 : Number(amenityAttr.data.height)
                    
                    let size = (length || 1) * (breadth || 1)
                    algoData.sizeOfPlacement.push(size)
                    algoData.noOfPlacement.push(Number(amenityAttr.data.quantity))
                    algoAllData.push(algoData)
                }
              }
              let allSelectedInventoryArr = [];
              let allAmenities = [...parentData.data.student.activities]
              console.log("allAmenities--->>",allAmenities, parentData.data.algorithmData.events);
              allSelectedInventoryArr = parentData.data.algorithmData.events.length > 0 
              ? parentData.data.algorithmData.events.filter(function (o1) {
                  return allAmenities.some(function (o2) {
                      // return o2.amenities.find(data => {
                          return o2.name === o1.name;
                      // })
                  });
              })
              : 
              []
              console.log("allSelectedInventoryArr",allSelectedInventoryArr);
    
              let allAlgoInventories = algoAllData.length > 0 ? [ ...algoAllData, ...allSelectedInventoryArr] : []
              // let allAlgoInventories = [ ...algoAllData ]
    
              console.log("allAlgoInventories",allAlgoInventories);
              
              amdata = allAlgoInventories.length > 0 ? allAlgoInventories.filter((am, index, self) =>
                  index === self.findIndex((t) => (
                      t.parentInventory === am.parentInventory && t.uniqueName === am.uniqueName
                  ))
              )
              :
              []
              console.log("algoData>>>--",algoAllData, amdata);
    
            console.log("amdata-->",amdata);
            
              if (parentData && parentData.setData) {
                parentData.setData({
                    ...parentData.data,
                    student: {
                        life: studentAmenities.filter(el => el._checked === true),
                        activities: selectedActivities,
                    },
                    algorithmData: {
                        ...parentData.data.algorithmData,
                        events: amdata
                    }
                })
              }
            setRecheck(true)
              let resp = await context.apis.public.getOnboardingRevenue(
                context.validator, {
                    ...parentData.data.algorithmData,
                    events: amdata
                }
            )
            if(resp && resp.done){
                if(parentData && parentData.setNetRevenue){
                    parentData.setNetRevenue(resp.netRevenue || 0)
                }
                if(parentData && parentData.setPrevNetRevenue){
                    parentData.setPrevNetRevenue(resp.netRevenue || 0)
                }
            }
    
            console.log("algo resp->>>>", resp);
        }else{
            parentData.setData({
                ...parentData.data,
                student: {
                    life: studentAmenities.filter(el => el._checked === true),
                    activities: selectedActivities,
                },
            })
        }
        setRecheck(true)
    }

    useEffect(() => {
        if(recheck===true && showPrompt===false) {
            process.nextTick(() => {
                if (redirectionURL) {
                    const url = selectedActivities.length > 0 ? redirectionURL
                    : "/schools/school-details/add-invetory/school-details-summary"
                    if(fromBackend && selectedActivities.length === 0){
                        history.push({pathname: "/backend/backend-details/school-details", state: { schoolId: schoolId }})
                    }
                    else{
                        onProceed(url)
                    }               
                } else if (isEdit === true) {
                    onProceed("/schools/school-details/details/activities/amenity-details")
                } else {
                    const url = selectedActivities.length > 0 ? "/schools/onboarding/events-and-occasions-details"
                    : "/schools/onboarding/onboarding-summary"
                    onProceed(url)
                }
            })
        }
      },[recheck, showPrompt])

    useEffect(() => {
        let foundcount = activities.filter(el => !el.custom && el._checked !== true)
        if (foundcount.length === 0) {
            setAllAmenitiesSelected(true)
        }else{
            setAllAmenitiesSelected(false)
        }
    }, [activities]);
    

    useEffect(() => {
    if(showQuitButton){
        getExistingEvent()
    }
    }, []);
      
    const getExistingEvent = async () =>{
        let resp
        if(fromBackend){
            resp = await context.apis[context.user.role].getActivitiesExternal(
                context.validator, {school: schoolId}
            )
        }
        else{
            resp = await context.apis[context.user.role].getActivities(
                context.validator, {school: context.user.school}
            )
        }
        if(resp && resp.done && resp.response && resp.response.rows){
            setExistingEvents(resp.response.rows)
        }
    }

    // to add activity in selected
    const addActivity = (newActivityData) => {
        let exist;
        if(isEdit === true){
            exist = selectedActivities.some((act) => act.name === newActivityData.name)
        }else{
            exist = selectedActivities.some((act) => act.id === newActivityData._id)
        }
        if (!exist) {
            setSelectedActivities([...selectedActivities, newActivityData])
            setActivities(activities.filter((act) => act !== newActivityData))
        }
    }

    //to remove activity from selected
    const removeActivity = (activityToRemove) => {     
        const exist = selectedActivities.find((act) => act === activityToRemove)
        if(isEdit === true){
            parentData.data.removeActivitiesArray.push(exist._id)
        }
        if (exist) {
            const remainingActivities = selectedActivities.filter(
                (element) => element !== activityToRemove
            )
            setSelectedActivities(remainingActivities)
            setActivities([...activities, activityToRemove])
        }
    }

    const handleEditActivity = (e) => {
        setIsEditCustomActivity(true)
        setEditValue(e.target.value)
        setActivityError(false)
        setSameNameError(false)
        setAlphabeticError(false)
    }
    const editActivity = (activityData, index) => {
        setIsCustomActivityAdd(false)
        setEditFlag(true)
        setEditCopy(activityData)
        setOldEditValue(activityData.name)
        setToBeEdited(index)
        setEditValue(activityData.name)
        setCustomEvent(true)
    }

    // to clear all non custom activity
    const clearAll = () => {
        setAllAmenitiesSelected(false)
        const removeActivities = selectedActivities.filter((item) => item.custom !== true)
        if(isEdit === true){
            let removeArr = []
            removeActivities.forEach((el) => {
                if (el._id) {
                    removeArr.push(el._id)
                }
            })
            parentData.setData({
                ...parentData.data,
                removeActivitiesArray: removeArr
            })
        }
        setActivities([...activities, ...removeActivities])
        const ext = selectedActivities.filter((item) => item.custom === true)
        setSelectedActivities(ext)
    }

    //to add all activities in slected at once
    const checkAllActivities = (flag) => {
        let arr = []
        const customActivities = activities.filter((item) => {
            return item.custom === true
        })

        if (flag) {
            activities.map((item) => {
                const exist = selectedActivities.find((el) => el === item)
                if (!exist) {
                    arr.push(item)
                }
                return arr
            })
            setSelectedActivities([...selectedActivities, ...arr])
            setActivities(customActivities)
        } else {
            const defaultActivities = selectedActivities.filter(
                (item) => item.custom !== true
            )
            setActivities([...defaultActivities, ...customActivities])
            setSelectedActivities(customActivities)
        }
    }

    // flag for custom event input tag
    const addCustomEvent = () => {
        setIsCustomActivityAdd(true)
        setCustomEvent(true)
    }
    // to change the value in input tag
    const handleChange = (e) => {
      setNewActivity(e.target.value)
      setError(false)
      setSameNameError(false)
      setAlphabeticError(false)
    }

    const saveCustomEvent = () => {
        let found = activities.find(e => e.name.toUpperCase() === newActivity.toUpperCase())
        let foundInSelected = selectedActivities.find(e => e.name.toUpperCase() === newActivity.toUpperCase())
        let foundInExisting:any = false
        if(existingEvents.length > 0){
            foundInExisting = existingEvents.find(e => e.name.toUpperCase() === newActivity.toUpperCase())
        }
            setSameNameError(false)
            if(!hasOnlyLettersAndSpaces(newActivity) || !hasOnlyLettersAndSpaces(editValue)){
                setAlphabeticError(true)
                return
            }else{
                setAlphabeticError(false)
            }
            if (isCustomActivityAdd === true) {
                if(!found && !foundInSelected && ( showQuitButton ? !foundInExisting : true)){
                    if (newActivity.trim() !== "") {
                        let obj = {
                            ...defaultObj,
                            name: newActivity,
                        }
                        setActivities([...activities, obj])
                        setSelectedActivities([...selectedActivities, obj])
                        setNewActivity("")
                        setCustomEvent(false)
                        setError(false)
                    } else {
                        setError(true)
                    }
                }else{
                    if(showQuitButton && foundInExisting){
                        setDuplicateNameErrMsg("Activity with same name already exists in school.")
                    }else{
                        setDuplicateNameErrMsg("Activity name can't be same.")
                    }
                    setError(false)
                    setSameNameError(true)
                    setActivityError(false)
                }

            } else {
                if (editValue.trim() !== "") {
                    let foundInSelected = selectedActivities.find(e => e.name.toUpperCase() === editValue.toUpperCase())
                    let foundInExisting:any = false
                    if(existingEvents.length > 0){
                        foundInExisting = existingEvents.find(e => e.name.toUpperCase() === editValue.toUpperCase())
                    }
                    let exist = activities.find(e => e.name.toUpperCase() === editValue.toUpperCase())
                    if(editValue.toUpperCase() === oldEditValue.toUpperCase()){
                        setEditFlag(false)  
                        setSameNameError(false)
                        setActivityError(false)
                        setCustomEvent(false)
                        return
                    }
                    if(!exist && !foundInSelected && ( showQuitButton ? !foundInExisting : true)){
                        setSameNameError(false)
                        const Act = activities.filter((item) => {
                            return item.name !== editCopy.name
                        })
                        setActivities(Act)
                        const SAct = selectedActivities.filter((item) => {
                            return item.name !== editCopy.name
                        })
                        setSelectedActivities(SAct)
                        let obj = {
                            ...defaultObj,
                            name: editValue
                        }
                        setEditFlag(false)
                        setEditCopy({})
                        setEditValue("")
                        setToBeEdited(0)
                        Act.splice(toBeEdited, 0, obj)
                        setActivities(Act)
                        SAct.splice(toBeEdited, 0, obj)
                        setSelectedActivities(SAct)
                        setCustomEvent(false)
                    } else {
                        if(showQuitButton && foundInExisting){
                            setDuplicateNameErrMsg("Activity with same name already exists in school.")
                        }else{
                            setDuplicateNameErrMsg("Activity name can't be same.")
                        }
                        setSameNameError(true)
                        setActivityError(false)
                    }
                    
                } else {
                    setActivityError(true)
                    setSameNameError(false)
                }            
            }
        
        
    }

    // to remove activity from activities as well as selected
    const removeCustomEvent = (activity) => {
        if (activity.custom === true) {
            setselectedEntityName(activity.name)
            if(isEdit === true){
                setSelectedAmenityToDeleteIdx(activity.name)
            }else{
                setSelectedAmenityToDeleteIdx(activity.id)
            }
            setOverlay(true)
            setToggleActivityDeleteConfirm(true)
        }
    }

    // adding studentAmenity
    const toggleAmenity = (item) => {
        if (item.custom !== true) {
            const exist = amenities.find((el) => el === item)
            if (!exist) {
                item._checked = true
                setAmenities([...amenities, item])
            } else {
                exist._checked = false
                const remaining = amenities.filter((el) => el !== item)
                setAmenities(remaining)
            }
        }
    }

    const addCustomAmenity = () => {
        setIsCustomAmenityAdd(true)
        setCustomAmenity(true)
    }

    const saveAmenity = () => {
        Object.keys(newAmenity).map(k => newAmenity[k] = typeof newAmenity[k] == 'string' ? newAmenity[k].trim().replace(/  +/g, ' ') : newAmenity[k])
        if (isCustomAmenityAdd === true) {
            if (newAmenity.trim() !== "") {
                let obj = {
                    id: newAmenity,
                    name: newAmenity,
                    custom: true,
                    _checked: true,
                }
                setStudentAmenities([...studentAmenities, obj])
                setNewAmenity("")
                setAmenities([...amenities, obj])
                setCustomAmenity(false)
            } else {
                setAmenityError(true)
            }
        } else {
            if (editAmenityValue.trim() !== "") {
                const Act = studentAmenities.filter((item) => {
                    return item !== amenityCopy
                })
                setStudentAmenities(Act)
                const SAct = amenities.filter((item) => {
                    return item !== amenityCopy
                })
                setAmenities(SAct)
                let obj = {
                    id: editAmenityValue,
                    name: editAmenityValue,
                    custom: true,
                    _checked: true,
                }
                setAmenityEditFlag(false)
                setAmenityCopy({})
                setEditAmenityValue("")
                setAmenityToEdit(null)
                Act.splice(amenityToEdit, 0, obj)
                setStudentAmenities(Act)
                SAct.splice(amenityToEdit, 0, obj)
                setAmenities(SAct)
                setCustomAmenity(false)
            } else {
                setAmenityEditError(true)
            }
        }
    }

    const handleAmenity = (e) => {
        setNewAmenity(e.target.value)
        setAmenityError(false)
    }

    const removeCustomAmenity = (val) => {
        setSelectedAmenityToDeleteIdx(val.id)
        setselectedEntityName(val.name)
        setOverlay(true)
        setToggleAmenityDeleteConfirm(true)
    }

    const selectAllAmenities = (flag) => {
        if (flag) {
            const allAmenities = studentAmenities.map((item) => {
                item._checked = true
                return item
            })
            setAmenities(allAmenities)
        } else {
            const unchecked = studentAmenities.map((item) => {
                if (item.custom !== true) {
                    if (item._checked === true) {
                        item._checked = false
                    }
                }
                return item
            })
            setStudentAmenities(unchecked)
            setAmenities(studentAmenities.filter((el) => el.custom === true))
        }
    }

    const EditAmenity = (item, index) => {
        if (customAmenity === false) {
            setIsCustomAmenityAdd(false)
            setAmenityEditFlag(true)
            setAmenityToEdit(index)
            setEditAmenityValue(item.name.trim())
            setAmenityCopy(item)
            setCustomAmenity(true)
        }
    }

    const handleAmenityValue = (e) => {
        setEditAmenityValue(e.target.value)
        setAmenityEditError(false)
    }

    const onSelectAllChange = (event) => {
        setSelectAll(event.target.checked)
        selectAllAmenities(event.target.checked)
    }

    const onSelectAllValueChange = (event) => {
        setAllAmenitiesSelected(event.target.checked)
        checkAllActivities(event.target.checked)
        const removeActivities = selectedActivities.filter((item) => item.custom !== true)
        if(event.target.checked === false && isEdit === true){
            let removeArr = []
            removeActivities.forEach((el) => {
                if (el._id) {
                    removeArr.push(el._id)
                }
            })
            parentData.setData({
                ...parentData.data,
                removeActivitiesArray: removeArr
            })
        }        
    }

    const amenityConfirmClose = (confirm) => {
        if (confirm === true) {
            setStudentAmenities(studentAmenities.filter((el) => el.id !== selectedAmenityToDeleteIdx))
            setAmenities(studentAmenities.filter((el) => el.id !== selectedAmenityToDeleteIdx))
        }
        setToggleAmenityDeleteConfirm(false)
        setOverlay(false)
    }

    const activityConfirmDelete = (confirm) => {
        if (confirm === true) {
            if(isEdit === true){
                let removedActivityData = selectedActivities.filter((el) => el.name === selectedAmenityToDeleteIdx)
                const removedFromSelected = selectedActivities.filter((el) => el.name !== selectedAmenityToDeleteIdx)
                if(removedActivityData[0]._id !== undefined){
                    parentData.data.removeActivitiesArray.push(removedActivityData[0]._id)
                }
                setSelectedActivities(removedFromSelected)
            }else{
                const removedFromSelected = selectedActivities.filter((el) => el.name !== selectedEntityName)
                setSelectedActivities(removedFromSelected)
            }
            const removedFromActivities = activities.filter((el) => el.name !== selectedEntityName)
            setActivities(removedFromActivities)
            
        }
        setToggleActivityDeleteConfirm(false)
        setOverlay(false)
    }
    const hanldeOnboardingComplete = () => {
        parentData.setTooltipProgress({
            ...parentData.tooltipProgress,
            eventsAndOccasions: false
        })
        let progress = {
            ...parentData.tooltipProgress,
            eventsAndOccasions: false
        }
        localStorage.setItem("tooltipProgress", JSON.stringify(progress))
    }
    const exitOnBoarding = (e) => {
        console.log(e)
    }
    useEffect(() => {
        if(activities.length > 0){
            console.log(activities)
            let array = objectDeepClone(activities)
            let sortedEventArray = array.sort((a, b) => {
                let nameA = a.name.toUpperCase();
                let nameB = b.name.toUpperCase();
                if (nameA < nameB) {
                  return -1; 
                }
                if (nameA > nameB) {
                  return 1; 
                }
                return 0;
            })
            // console.log(sortedEventArray)
            setActivities([...sortedEventArray])
        }
    }, [activities.length])
    const openQuitModal = () => {
        setOverlay(true)
        setShowQuitModal(true)
    }
    const onQuitModalClose = (confirm) => {
        if(confirm){
            if(fromBackend){
                history.push({pathname: "/backend/backend-details/school-details", state: { schoolId: schoolId }})
            }
            else{
                history.push("/schools/school-details/inventory")
            }
        }
        setShowQuitModal(false)
        setOverlay(false)
    }

    const handleBeforeChange = (index) => {
        if(index !== null && index !== undefined){
            let data = onBoarding.steps[index].element.split("")
            let array = data.slice(1).join("")
            console.log(array)
            document.getElementById(array).scrollIntoView({behavior: "auto", block: "center", inline: "nearest"})
        }
        // console.log(index)
    }

  return (
    <>
        <div className={`ovelap ${overlay === true ? "active" : ""}`}></div>
        {
            loader === true ?
            <Loader />
            :
            <div className={`${isEdit === false ? "col-xl-6 col-lg-6 col-md-12 col-xs-12" : ""}`}>
                <div className={`${isEdit === false ? "content mCustomScrollbar fixhight" : ""}`}>
                    <div className={`${isEdit === false ? "rightPart" : ""}`}>
                    {showQuitButton && <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-20"><a style={{cursor: "pointer"}} className="fR blackC" onClick={openQuitModal}>Quit</a></div>}
                        {isEdit === false && (
                        <>
                        <a onClick={() => toggleSidemenu()} href='javascript:void(0)' className={`hummob ${showSidemenu === true ? "no_highlight" : ""}`}>
                            <img src={menu} />
                        </a>
                        <h2 className='htx1 mb40'>
                            Events and Occasions
                        </h2>
                        <p className="ptx">Select one or more events from your upcoming annual school calendar</p>
                        </>
                        )}
                        <div className='row'>
                            <div className='col-xl-7 col-lg-7 col-md-7 col-xs-12 dnone'>
                                <div className='sudut-file dnone'>
                                    { isEdit === false &&
                                        <>
                                            <h3 className='htx2'>Student life</h3>
                                            <p className='ptx'>Amenities provided to students</p>
                                        </>
                                    }
                                    <div className='checkbox checkbox1'>
                                        <input
                                            type='checkbox'
                                            id='selectAllAmenitiesCheckbox'
                                            onChange={onSelectAllChange}
                                            checked={selectAll}
                                        />
                                        <label htmlFor='selectAllAmenitiesCheckbox'>Select All</label>
                                    </div>
                                    <div className='sele-calss'>
                                        {studentAmenities.map((item, index) => {
                                        return (
                                        <div
                                            className={`
                                                sele-calss1 ${item._checked ? "active" : ""}
                                                ${index === amenityToEdit ? "edit-custom-input" : ""}
                                            `}
                                            onClick={() => toggleAmenity(item)}
                                            key={item.id}
                                            onDoubleClick={item.custom ? () => EditAmenity(item, index) : null}
                                        >
                                        { (amenityEditFlag && item.custom && index === amenityToEdit)
                                            ?
                                            <>
                                                <input
                                                    type='text'
                                                    value={editAmenityValue}
                                                    onChange={handleAmenityValue}
                                                    autoFocus
                                                    className={amenityEditError ? "onFocus" : ""}
                                                />
                                                {
                                                amenityEditError &&
                                                    <p
                                                        style={{
                                                        fontSize: "0.8rem",
                                                        color: "tomato",
                                                        paddingTop: "0.5rem",
                                                        }}
                                                    ><span className="valError"><img src={errorSVG} />Event name field is required.</span>
                                                    </p>
                                                }
                                            </>
                                        :
                                            <h4>{item.name}</h4>
                                        }
                                        {
                                            item.custom ?
                                            <>
                                            <img className='star' src={star} alt='star' />
                                                {
                                                    (!amenityToEdit && !customAmenity) &&
                                                    <a
                                                        href='javascript:void(0)'
                                                        className='remove-icon'
                                                        onClick={() => removeCustomAmenity(item)}
                                                    >
                                                    <img src={close} />
                                                    </a>
                                                }
                                            </>
                                            : null
                                        }
                                    </div>
                                    )
                                    })}
                                    {(customAmenity && isCustomAmenityAdd === true) && (
                                    <div className='sele-calss1 add-activitie'>
                                        <input
                                            value={newAmenity}
                                            type='text'
                                            onChange={handleAmenity}
                                            autoFocus
                                            className={amenityError ? "onFocus" : ""}
                                        />
                                        <img className='star' src={star} alt='star' />
                                        {amenityError && (
                                        <p
                                            style={{
                                            fontSize: "0.8rem",
                                            color: "tomato",
                                            paddingTop: "0.5rem",
                                        }}
                                        >
                                            <span className="valError"><img src={errorSVG} />Event name field is required.</span>
                                        </p>
                                        )}
                                    </div>
                                    )}
                                    {
                                        customAmenity === true &&
                                        <div className='sele-calss1 active complete'>
                                            <a href='javascript:void(0)' onClick={saveAmenity}>Done</a>
                                        </div>
                                    }
                                    {
                                        customAmenity === false &&
                                        <div className='sele-calss1 active complete'>
                                            <a href='javascript:void(0)' onClick={addCustomAmenity}>+ Custom</a>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        {/* <div className='col-xl-5 col-lg-5 col-md-5 col-xs-12 end-xs'>
                                <div className='student-img'>
                                    <img src={student} />
                                </div>
                            </div> */}
                            <div className='col-xl-12 col-lg-12 col-md-12 col-xs-12 end-xs dnone'>
                                <div className="row align-items">
                                <div className='col-xl-6 col-lg-6 col-md-12 col-xs-12 end-xs'>
                                        <div className='student-imgbg'></div> 
                                    </div>
                                <div className='col-xl-6 col-lg-6 col-md-12 col-xs-12 end-xs'>
                                    <div className='student-righttext'>
                                        <div className="student-lineright">
                                                <p>Special school days such as Annual Day, Sports Day, Children's Day, and Teacher's Day, are special to a student. 
                                                    Children look forward to these days, and the whole host of activities enrich their school life. </p>
                                                <p>WISR turns these days from being special to memorable with spectacular experiences.Celebrate important school days with students.</p>
                                            </div>
                                        <b>Choose from below:</b>
                                    </div> 
                                </div>
                                </div>
                            </div>
                            <div className='col-xl-12 col-lg-12 col-md-12 col-xs-12 pl-0 pr-0'>
                                <div className='sudut-file'>
                                    {/* <h3 className='htx2'>Activities <p style={{display: "inline"}} className='ptx'>(If none selected then all of the activities will be added)</p> </h3>
                                    <p className='ptx'>Events and festivals</p> */}
                                    <div className='row mt20'>
                                        <div className='col-xl-6 col-lg-6 col-md-6 col-xs-12 pl-0'>
                                            <div className='activies-box'>
                                                <div className='activies-top'>
                                                    <h3 className='htx2'>
                                                    Events
                                                        <div className='checkbox'>
                                                            <input
                                                                type='checkbox'
                                                                id='selectAllActivities'
                                                                checked={allAmenitiesSelected}
                                                                value={allAmenitiesSelected}
                                                                onChange={onSelectAllValueChange}
                                                                autoFocus
                                                            />
                                                            <label htmlFor='selectAllActivities'>Select All</label>
                                                        </div>
                                                    </h3>
                                                </div>
                                                <div className='activies-mid activi-customeadd'>
                                                    <ul>
                                                        {activities.map((el, index) => {
                                                        return (
                                                            <li
                                                                id={`activity${index}`}
                                                                key={el.id}
                                                                onClick={() => 
                                                                    el.custom ? null : addActivity(el)
                                                                }
                                                                onDoubleClick={
                                                                el.custom
                                                                ? () => editActivity(el, index)
                                                                : null
                                                                }
                                                                className={`
                                                                    ${el.custom === true ? "active" : ""}
                                                                    ${(el.custom === true && index !== toBeEdited) ? "added-activates" : ""}
                                                                `}
                                                            >
                                                            {
                                                                editFlag &&
                                                                el.custom &&
                                                                index === toBeEdited ?
                                                                <>
                                                                    <input
                                                                        type='text'
                                                                        value={editValue}
                                                                        onChange={handleEditActivity}
                                                                        autoFocus
                                                                        maxLength={50}
                                                                        className={activityError ? "onFocus" : ""}
                                                                    />
                                                                    {
                                                                        activityError &&
                                                                        <p
                                                                            style={{
                                                                                fontSize: "0.8rem",
                                                                                color: "tomato",
                                                                                paddingTop: "0.5rem",
                                                                            }}                                                                
                                                                        >
                                                                        <span className="valError"><img src={errorSVG} />Event name field is required.</span>
                                                                        </p>
                                                                    }
                                                                    {
                                                                        sameNameError &&
                                                                        <p
                                                                            style={{
                                                                                fontSize: "0.8rem",
                                                                                color: "tomato",
                                                                                paddingTop: "0.5rem",
                                                                            }}                                                                
                                                                        >
                                                                            <span className="valError"><img src={errorSVG} />{duplicateNameErrMsg}</span>
                                                                        </p>
                                                                    }
                                                                    {
                                                                        alphabeticError && 
                                                                        <p
                                                                            style={{
                                                                                fontSize: "0.8rem",
                                                                                color: "tomato",
                                                                                paddingTop: "0.5rem",
                                                                            }}                                                                
                                                                        >
                                                                            <span className="valError"><img src={errorSVG} />The name may only contain letters.</span>
                                                                        </p>
                                                                    }
                                                                </>
                                                                : el.name
                                                            }
                                                            {
                                                                el.custom ?
                                                                <>
                                                                    {
                                                                        !customEvent &&
                                                                        <a href='javascript:void(0)' className='remove-icon'>
                                                                            <img src={close} onClick={() => removeCustomEvent(el)}/>
                                                                        </a>
                                                                    }
                                                                    <img className='star' src={star} />
                                                                </>
                                                                :
                                                                <a href='javascript:void(0)' className='addacbtn' onClick={() => addActivity(el)}>+ Add</a>
                                                            }
                                                            </li>
                                                        )
                                                        })}
                                                        {(customEvent && isCustomActivityAdd === true) && (
                                                        <li className='active addcustomEvent'>
                                                            <input
                                                                type='text'
                                                                value={newActivity}
                                                                onChange={handleChange}
                                                                autoFocus
                                                                maxLength={50}
                                                                className={error ? "onFocus" : ""}
                                                            />
                                                            {/* <a
                                                                href='javascript:void(0)'
                                                                className='remove-icon'
                                                                >
                                                            <img
                                                                src={close}
                                                                onClick={() => setCustomEvent(false)}
                                                            />
                                                            </a> */}
                                                            <img className='star' src={star} />
                                                            {error &&
                                                            <p
                                                                style={{
                                                                    fontSize: "0.8rem",
                                                                    color: "tomato",
                                                                    paddingTop: "0.5rem",
                                                                }}
                                                            ><span className="valError"><img src={errorSVG} />Event name field is required.</span></p>
                                                            }
                                                            {
                                                            sameNameError &&
                                                            <p
                                                                style={{
                                                                    fontSize: "0.8rem",
                                                                    color: "tomato",
                                                                    paddingTop: "0.5rem",
                                                                }}                                                                
                                                            ><span className="valError"><img src={errorSVG} />{duplicateNameErrMsg}</span>
                                                            </p>
                                                            }
                                                            {
                                                                alphabeticError && 
                                                                <p
                                                                    style={{
                                                                        fontSize: "0.8rem",
                                                                        color: "tomato",
                                                                        paddingTop: "0.5rem",
                                                                    }}                                                                
                                                                ><span className="valError"><img src={errorSVG} />The name may only contain letters.</span>
                                                                </p>
                                                            }
                                                        </li>
                                                        )}
                                                        {
                                                            customEvent === false &&
                                                            <li className='cust-btn'>
                                                                <a
                                                                    id="addCustomEvent"
                                                                    href='javascript:void(0)'
                                                                    className='btn btn-outline'
                                                                    onClick={addCustomEvent}
                                                                >
                                                                Add Custom Event
                                                                </a>
                                                            </li>
                                                        }
                                                        {
                                                            customEvent === true &&
                                                            <li className='cust-btn'>
                                                                <a
                                                                    href='javascript:void(0)'
                                                                    className='btn btn-outline'
                                                                    onClick={saveCustomEvent}
                                                                >
                                                                Done
                                                                </a>
                                                            </li>
                                                        }                                                
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-xl-6 col-lg-6 col-md-6 col-xs-12 pr-0'>
                                            <div className='activies-box'>
                                                <div className='activies-top'>
                                                    <h3 className='htx2'>
                                                        Selected{` (${selectedActivities.length})`}
                                                        <a
                                                            href='javascript:void(0)'
                                                            className={`add-inve ${selectedActivities.length < 1 && "anchor-disabled"}`}
                                                            onClick={clearAll}
                                                        >
                                                        Clear All
                                                        </a>
                                                    </h3>
                                                </div>
                                                <div className='activies-mid activies-mid1'>
                                                    
                                                    {selectedActivities.length > 0 ? selectedActivities.map((existingActivity) => {
                                                    return (
                                                        <label key={existingActivity.id}>
                                                        {existingActivity.name}
                                                        {
                                                            existingActivity.custom !== true &&
                                                            <a
                                                                href='javascript:void(0)'
                                                                onClick={() => removeActivity(existingActivity)}
                                                            >
                                                                {existingActivity.custom ? null : (
                                                                <img src={close} />
                                                                )}
                                                            </a>
                                                        }
                                                        </label>
                                                        )
                                                    })
                                                    :
                                                    <div className="empty-class">
                                                    <p>Add events organized in your school every year.</p>
                                                    </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt20 end-xs'>
                                <div className='forminpt'>
                                    <input
                                        onClick={onSubmit}
                                        name='Continue'
                                        value='Continue'
                                        className='btn btn-orange stu-life-acti'
                                        type='button'
                                    />
                                </div>
                            </div>
                        </div>
                </div>
                </div>
            </div>
        }

        <ConfirmDialog
            togglePopup={toggleAmenityDeleteConfirm}
            onModalClose={amenityConfirmClose}
            componentName={selectedEntityName}
        />

        <ConfirmDialog
            togglePopup={toggleActivityDeleteConfirm}
            onModalClose={activityConfirmDelete}
            componentName={selectedEntityName}
        />
        {!(fromBackend === true) &&
            <ConfirmAlert
                when={showPrompt}
                title="Leave page without saving..."
                cancelText="Cancel"
                okText="Confirm"
                onOK={() => true}
                onCancel={() => false}
                parentCallback={confirmAlertCallback}
            /> 
        }
        <ConfirmDialog
            confirmTitle="Do you want to Quit"
            togglePopup={showQuitModal}
            onModalClose={onQuitModalClose}
            componentName={""}
        />
        <Steps
            enabled={onBoarding.stepsEnabled}
            steps={onBoarding.steps}
            initialStep={onBoarding.initialStep}
            onComplete={hanldeOnboardingComplete}
            onExit={exitOnBoarding}
            options={onBoarding.options}
            onBeforeChange={handleBeforeChange}
        />       
    </>
  )
}

export default SchoolActivities
