import React, { useState, useEffect, useContext } from 'react'
import AppContextObject from '../../../../common/context/common';
import EditContact from './editContact';
import EditDiscription from './editDiscription';
import EditDocuments from './editDocuments';
import EditGeneral from './editGeneral';
import EditMyBrandDetails from './editMyBrandDetails';
import EditSubCategory from './editSubCategory';
import BrandWisrRepresentative from './brandWisrRepresentative';

function ProfileAbout(props) {
    const {
        profileGeneralLogo,
        uploadDocument,
        profileData,
        refetch,
        setRefetch,
        loading,
        setLoading
    } = props;
    const context = useContext(AppContextObject)
    const [generalDetail, setGeneralDetail] = useState()
    const [documents, setDocuments] = useState()
    const [contactDetail, setContactDetail] = useState()
    const [description, setDescription] = useState()
    const [uniqueSubCategoryList, setUniqueSubCategoryList] = useState()
    const [subcategoryWithBrand, setSubcategoryWithBrand] = useState([])
    const [subCategoryList, setSubCategoryList] = useState()
    const [brandNameList, setBrandNameList] = useState()
    const [myBrandDetails, setMyBrandDetails] = useState()
    const [overlay, setOverlay] = useState(false)
    useEffect(() => {
        let uniqueCategory = [], subCategoryAndBrandData = {}
        profileData && profileData.subCategoryList && profileData.subCategoryList.forEach(subCategory => {
            if(subCategoryAndBrandData[subCategory.name]){
                let newBrandName = {brandName: subCategory.brandName, brandId: subCategory.brandId}
                if(newBrandName.brandName && newBrandName.brandId && subCategoryAndBrandData[subCategory.name][0].brandName && Array.isArray(subCategoryAndBrandData[subCategory.name][0].brandName)){
                    if(!(subCategoryAndBrandData[subCategory.name][0].brandName.find(item => item.brandName === subCategory.brandName))){
                        subCategoryAndBrandData[subCategory.name][0].brandName.push(newBrandName)
                    }
                }
                console.log("subCategoryAndBrandData[subCategory.name]", subCategoryAndBrandData[subCategory.name], newBrandName)
            }
            else{
                let newSub = {...subCategory}
                if(subCategory.brandName){
                    newSub.brandName = [{brandName: subCategory.brandName, brandId: subCategory.brandId}]
                }
                else{
                    newSub.brandName = []
                }
                subCategoryAndBrandData[subCategory.name] = [newSub]
            }
        })
        Object.keys(subCategoryAndBrandData).forEach((key) => {
            if(subCategoryAndBrandData[key].length === 1){
                uniqueCategory.push(subCategoryAndBrandData[key][0])
            }
        })
        console.log("profileData....",profileData, uniqueCategory, subCategoryAndBrandData);
        setSubcategoryWithBrand([...uniqueCategory])
        let gData = (profileData && profileData.general && profileData.general) || {}
        let bData = (profileData && profileData.myBrandDetails && profileData.myBrandDetails) || {}
        setGeneralDetail({...gData, ...bData})
        const data:any = formateObject(profileData && profileData.documents && profileData.documents)
        if(data){
            setDocuments({...data})
        }
        
        // setDocuments(profileData && profileData.documents && profileData.documents)
        setContactDetail(profileData && profileData.contact && profileData.contact)
        setDescription(profileData && profileData.description && profileData.description)
        setUniqueSubCategoryList(profileData && profileData.uniqueSubCategoryList && profileData.uniqueSubCategoryList.reverse())
        setSubCategoryList(profileData && profileData.subCategoryList && profileData.subCategoryList.reverse())
        setBrandNameList(profileData && profileData.brandNameList && profileData.brandNameList.reverse())
        setMyBrandDetails(profileData && profileData.myBrandDetails && profileData.myBrandDetails)
    }, [profileData])
    const formateObject = (formateData) => {
        if(formateData){
            let reqData = {
                gst: {file: formateData.gst.file, gstNo: formateData.gst.number, status: formateData.gst.status},
                pan:{file: formateData.pan.file, panNo: formateData.pan.number, status: formateData.pan.status}
            }
            return reqData
        }
        return null
    }

    return (
        <>
            <div className={`ovelap ${overlay === true ? "active" : ""}`}></div>
            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-20 pl">
                <div className="row">
                    {!loading && generalDetail ?
                        <EditGeneral
                            generalDetail={generalDetail}
                            setGeneralDetail={setGeneralDetail}
                            setOverlay={setOverlay}
                            refetch={refetch}
                            setRefetch={setRefetch}
                            profileData={profileData}
                        />
                        :
                        <div className="content-loader-block"></div>
                    }
                    <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-40 mb-50">
                        <div className="line-full"></div>
                    </div>
                    {!loading && documents ?
                        <EditDocuments
                            documents={documents}
                            setDocuments={setDocuments}
                            setOverlay={setOverlay}
                            refetch={refetch}
                            setRefetch={setRefetch}
                            profileData={profileData}
                        />
                        :
                        <div className="content-loader-block"></div>
                    }

                    <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-40 mb-50">
                        <div className="line-full"></div>
                    </div>
                    {!loading && contactDetail ?
                        <EditContact
                            contactDetail={contactDetail}
                            setContactDetail={setContactDetail}
                            setOverlay={setOverlay}
                            refetch={refetch}
                            setRefetch={setRefetch}
                            profileData={profileData}
                        />
                        :
                        <div className="content-loader-block"></div>
                    }
                    <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-40 mb-50">
                        <div className="line-full"></div>
                    </div>
                    {!loading && uniqueSubCategoryList ?
                        <EditSubCategory
                            subCategoryList={uniqueSubCategoryList}
                            setSubCategoryList={setUniqueSubCategoryList}
                            allSubCategoryList={subCategoryList}
                            setOverlay={setOverlay}
                            refetch={refetch}
                            setRefetch={setRefetch}
                            profileData={profileData}
                        />
                        :
                        <div className="content-loader-block"></div>
                    }

                    {
                        context.user.role === "brandManager"
                        &&
                        <>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-40 mb-50">
                            <div className="line-full"></div>
                        </div>
                        {!loading && subCategoryList ?
                            <EditMyBrandDetails
                                subCategoryList={subcategoryWithBrand}
                                allSubCategoryList={subCategoryList}
                                myBrandDetails={myBrandDetails}
                                setMyBrandDetails={setMyBrandDetails}
                                setSubCategoryList={setUniqueSubCategoryList}
                                setOverlay={setOverlay}
                                refetch={refetch}
                                setRefetch={setRefetch}
                                brandNameList={brandNameList}
                                setBrandNameList={setBrandNameList}
                                profileData={profileData}
                            />
                            :
                            <div className="content-loader-block"></div>
                        }
                        </>
                    }

                     <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-40 mb-50">
                        <div className="line-full"></div>
                    </div>
<BrandWisrRepresentative  brandData={profileData} />
                    
                    <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-40 mb-50">
                        <div className="line-full"></div>
                    </div>
                    {!loading && description ?
                        <EditDiscription
                            description={description}
                            setDescription={setDescription}
                            setOverlay={setOverlay}
                            refetch={refetch}
                            setRefetch={setRefetch}
                            profileData={profileData}
                        />
                        :
                        <div className="content-loader-block"></div>
                    }
                    
                    {/* <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                        <div className="row">
                                <div className="col-xl-5 col-lg-5 col-md-12 col-xs-12">
                                    <div className="profile-general">
                                        <h2 className="htx3 clearfix">Description <a href="javascript:void(0)" className="editbtn"><img src="/static/media/edit-btn.c928a066.svg" /> Edit</a></h2>
                                        <p className="ptx clearfix">Write a few senetces about the brand.</p>
                                    </div>
                                </div>
                                <div className="col-xl-7 col-lg-7 col-md-12 col-xs-12 brand-general">
                                    <div className="inventory-overHead mt-0"><p>Description</p><h3>McDonald's is an international chain of hamburger fast food restaurants, which operates and franchises McDonald's restaurants worldwide. Its restaurants offer various food products, soft drinks, coffee, and other beverages, as well as the breakfast menu. Its menu also includes salads, fish, wraps, smoothies, and seasoned fries.</h3></div>
                                    <div className="clearfix mt-30"></div>
                                </div>
                        </div>
                    </div> */}

                    {/* <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-30 mb-20">
                        <div className="line-full"></div>
                    </div> */}
                    {/* <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-20">
                        <div className="btn-general">
                            <a href="" className="btn btn-outline">Cancel</a>
                            <a href="" className="btn btn-orange">Save Changes</a>
                        </div>
                    </div> */}
                </div>
            </div>
        </>
    )
}

export default ProfileAbout
