import { useEffect, useState, useContext } from "react"

import { useHistory } from "react-router-dom"
import { ReactInternetSpeedMeter } from 'react-internet-meter'
import edit from "../../../../../assets/images/edit-btn.svg"
import student from "../../../../../assets/images/student-img.jpg"
import schoolImgSmall from "../../../../../assets/images/school-small.png"
import schoolImgMedium from "../../../../../assets/images/school-medium.png"
import schoolImgLarge from "../../../../../assets/images/school-large.png"
import toast from "react-hot-toast"
import DataContext from "../../../../../service/contextProvider"
import PersonalDetails from "../../../../../components/addon/summary/personalDetails"
import SchoolDetails from "../../../../../components/addon/summary/schoolDetails"
import SchoolLife from "../../../../../components/addon/summary/schoolLife"
import SchoolActivities from "../../../../../components/addon/summary/schoolActivities"

import AppContextObject from "../../../../../common/context/common"
import { PublicContextInitiator } from "../../../../apis/publicContextInitiator"
import Mou from "../mou/mou"

import { titleCase } from "../../../../../service/helper"
import menu from "../../../../../assets/images/menu.svg"
import ConfirmDialog from "../../../../../components/addon/confirmDialog/confirmDialog"

interface Props {
  isEdit?: boolean
  onProceed?: any,
  toggleSidemenu?: Function,
  showSidemenu?: boolean,
  addSchoolFromBackend?: boolean,
  setShowBackendSidemenu?: any
}

const Summary = ({
  isEdit,
  onProceed,
  toggleSidemenu,
  showSidemenu,
  addSchoolFromBackend,
  setShowBackendSidemenu
}: Props) => {

  const history = useHistory()

  const parentData = useContext<any>(DataContext)
  const context = useContext(AppContextObject)
  const [terms, setTerms] = useState(isEdit ? true : false)
  const [showMou, setShowMou] = useState(false)
  const [loading, setLoading] = useState(false)
  const [wifiSpeed, setwifiSpeed] = useState<any>(null)
  const [pingIntervalTime, setPingIntervalTime] = useState(1000)
  const [checkSpeed, setCheckSpeed] = useState(false)
  const [canSaveData, setCanSaveData] = useState<any>(null)
  const [toggleNetworkDialog, setToggleNetworkDialog] = useState(false)
  const [overlay, setOverlay] = useState(false)
  const [isInternetOK, setIsInternetOK] = useState(false)
  const [apiHited, setApiHited] = useState(false)

  const personalDetails = parentData?.data?.personalDetails
  const generalDetails = parentData?.data?.schoolGeneral
  const schoolAmenities = parentData?.data?.schoolAmenities
  const schoolLife = parentData?.data?.student?.life
  const schoolActivities = parentData?.data?.student?.activities
    ? parentData?.data?.student?.activities : []

  const handleChange = (val) => {
    setTerms(val)
  }
  const toggleMou = () => {
    setShowMou(!showMou)
  }

  useEffect(() => {
    if (parentData.setActiveModule) {
      parentData.setActiveModule({
        module: "preview",
      })
    }
  }, [])
  const deleteSingleUploadedImage = (imageKey) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ key: imageKey && imageKey })
    }

    fetch('/api/deleteUploadedFiles', requestOptions)
      .then((res) => {
        res.json()
          .then(res2 => {
          })
      })
      .catch(err => console.log(err))
  }

  const deleteMultipleUploadedImages = (amenitydata) => {
    if (amenitydata && amenitydata.length > 0) {
      let imagesKeyArray = [];
      amenitydata && amenitydata[0].images.map(img => {
        imagesKeyArray.push({ Key: img.key })
      })

      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ keys: imagesKeyArray && imagesKeyArray })
      }

      fetch('/api/deleteMultipleUploadedFiles', requestOptions)
        .then((res) => { })
        .catch(err => console.log(err))
    }

  }
  useEffect(() => {
    setwifiSpeed(null)
    setCanSaveData(null)
  }, [])

  useEffect(() => {
    console.log('wifiSpeed=====>',wifiSpeed,history.location.pathname)
    if (history.location.pathname === "/schools/onboarding/onboarding-summary" || history.location.pathname ==='/backend/backend-details/schools/onboarding/onboarding-summary') {
      if (wifiSpeed !== null) {
        if (Number(wifiSpeed) < context.utils.internetSpeedLimit) {
          console.log("wifiSpeed and networkOK 1", wifiSpeed, isInternetOK);
          setIsInternetOK(false)
          setLoading(false)
          setToggleNetworkDialog(true)
          setOverlay(true)
          setCanSaveData(null)
        } else {
          if(!apiHited){
            console.log("wifiSpeed and networkOK 2", wifiSpeed, isInternetOK);
            setIsInternetOK(true)
            console.log("wifiSpeed>>>>", wifiSpeed);
            setApiHited(true)
            setCanSaveData(true)
            handleSubmit()
            // setwifiSpeed(0)
          }
        }
        // setCanSaveData(null)
      } else {
        // setLoading(false)
      }
    }
  }, [wifiSpeed])
  console.log("canSaveData", canSaveData);

  // useEffect(()=>{
  //   if(canSaveData && loading){
  //     // setLoading(true)
  //     console.log("API hited.");
  //     // setLoading(false)
  //     setCanSaveData(null)
  //     setwifiSpeed(null)
  //     handleSubmit()
  //   }
  // }, [canSaveData])

  const testSpeed = () => {
    setCheckSpeed(true)
    console.log("test speed hitted");
    // setTimeout(() => {
    //   setPingIntervalTime(1000)
    //   setCheckSpeed(false)
    //   // setwifiSpeed(0)
    //   console.log("wifiSpeed>>>>>", wifiSpeed);
    // }, 2000)
  }

  const networkConfirmClose = (confirm) => {
    setToggleNetworkDialog(false)
    setOverlay(false)
  }
  const handleSubmit = async() =>{
      let publicContextInitiator = new PublicContextInitiator(context)
      let resp: any = await publicContextInitiator.manageSchoolDetails(
        context.validator, parentData?.data
      ).catch(err => {
        console.log("Error while saving the data: ", err)
        setLoading(false)
      })
      setLoading(false)
      if (resp && resp.done) {
        sessionStorage.removeItem("userIds")
        sessionStorage.removeItem("imageKeys")
        if(parentData){
          console.log("parentData>>>>>>>>>",parentData);
          parentData.setData({
            ...parentData.data,
            unsavedUserIds: [],
            unsavedImages: [],
          })
        }
        if (addSchoolFromBackend) {
          toast.success("New School Added Successfully.")
          if (parentData.isFromFosTable || context.user.role === "backendFOS") {
            console.log("parentData.backendFosDetails", parentData.backendFosDetails)
            history.push({
              pathname: parentData.backendRedirectPath || "/backend/backend-details/fos-details",
              state: { userId: parentData.backendFosDetails.id || "", email: parentData.backendFosDetails.email || "" }
            })
          } else {
            history.push(parentData.backendRedirectPath || "/backend/backend-details/school-list")
          }
          setShowBackendSidemenu(true)
        } else {
          onProceed("/schools/thank-you")
        }
      }
      else {
        // alert box
        
      }
  }

  const onSubmit = async () => {
    if(terms === false || loading === true){
      return
    }
    if (isEdit === true) {
      setLoading(true)
      if (parentData.data && parentData.data.removeUploadedImageArray && parentData.data.removeUploadedImageArray.length > 0) {
        for (let i = 0; i < parentData.data.removeUploadedImageArray.length; i++) {
          deleteMultipleUploadedImages(parentData.data.removeUploadedImageArray[i])
        }
      }
      if (parentData.data && parentData.data.removeSingleUploadedImageArray && parentData.data.removeSingleUploadedImageArray.length > 0) {
        for (let i = 0; i < parentData.data.removeSingleUploadedImageArray.length; i++) {
          deleteSingleUploadedImage(parentData.data.removeSingleUploadedImageArray[i])
        }
      }
      let resp = await context.apis[context.user.role].updateSchoolDetails(
        context.validator, { data: parentData?.data, previousData: parentData?.previousData }
      )
      if (resp && resp.done) {
        if (parentData) {
          parentData.setData({
            ...parentData.data,
            isDataUpdated: true
          })
        }
        process.nextTick(() => {
          setLoading(false)

          if (parentData) {
            parentData.setData({
              ...parentData.data,
              unsavedImages: []
            })
          }
          toast.success("Details updated successfully")
          onProceed("/schools/school-details/myschool")
        })
      }
    } else {      
      setLoading(true)
      testSpeed();
    }
  }
  console.log("summary addSchoolFromBackend--->>>", addSchoolFromBackend)
  return (
    <>
    <div className={`ovelap ${overlay === true ? "active" : ""}`}></div>
      {
        checkSpeed
        &&
        <ReactInternetSpeedMeter  
            txtSubHeading="Internet is too slow"
            outputType="alert"
            customClassName={"slowInternetMsg"}
            txtMainHeading="Opps..."
            pingInterval={pingIntervalTime} // milliseconds
            thresholdUnit='megabyte' // "byte" , "kilobyte", "megabyte"
            threshold={100}
            imageUrl={context.utils.internetSpeedTestingImg}
            downloadSize="2550420"  //bytes
            callbackFunctionOnNetworkDown={(speed)=>console.log(`Internet speed is down ${speed}`)}
            callbackFunctionOnNetworkTest={(speed)=>{ console.log(`Internet Speed is ${speed} MBps`); setwifiSpeed(speed); setCheckSpeed(false)}}
        />
    }
      <div className='col-xl-9 col-lg-9 col-md-12 col-xs-12'>
        <div className='content mCustomScrollbar fixhight'>
          <div className='rightPart onboding'>
            <div className='row'>
              <div className='col-xl-12 col-lg-12 col-md-12 col-xs-12'>
                <div className='clearfix'></div>
                {
                  isEdit === false &&
                  <a onClick={() => toggleSidemenu()} href='javascript:void(0)' className={`hummob ${showSidemenu === true ? "no_highlight" : ""}`}>
                    <img src={menu} />
                  </a>
                }
                <h2 className='htx1 mb40'>Onboarding Overview</h2>
              </div>
              <PersonalDetails
                editImage={edit}
                personalDetails={personalDetails}
                isEdit={isEdit}
                addSchoolFromBackend={addSchoolFromBackend}
              />

              <SchoolDetails
                editImage={edit}
                schoolImage={generalDetails.builtUpArea < 350000 ? schoolImgSmall : generalDetails.builtUpArea >= 350000 && generalDetails.builtUpArea <= 750000 ? schoolImgMedium : generalDetails.builtUpArea > 750000 ? schoolImgLarge : schoolImgSmall}
                schoolAmenities={schoolAmenities}
                schoolGeneral={generalDetails}
                isEdit={isEdit}
                addSchoolFromBackend={addSchoolFromBackend}
              />
              <SchoolLife
                editImage={edit}
                studentImage={student}
                life={schoolLife}
                isEdit={isEdit}
                addSchoolFromBackend={addSchoolFromBackend}
              />
              <SchoolActivities
                editImage={edit}
                activities={schoolActivities}
                isEdit={isEdit}
                addSchoolFromBackend={addSchoolFromBackend}
              />
              <div className='row col-xl-8 col-lg-8 col-md-8 col-xs-12 middle-xs'>
                {
                  isEdit ?
                    ""
                    :
                    <div className='forminpt'>
                      <div className='checkbox onboardcheck'>
                        <input
                          type='checkbox'
                          id='html-term'
                          onChange={() => handleChange(!terms)}
                        />
                        <label style={{ display: "inline" }} htmlFor='html-term'></label> <b>I have read and agree to the{" "}</b>
                        <a style={{ cursor: "pointer", color: "#fe6e00" }} onClick={toggleMou}>Terms & Conditions</a>
                      </div>
                    </div>
                }

              </div>
              <div className='row col-xl-4 col-lg-4 col-md-4 col-xs-12 end-md'>
                <div className={`forminpt ${loading ? "button--loading" : ""}`}>
                  <input
                    name={isEdit ? 'Save Changes' : 'Submit'}
                    value={isEdit ? 'Save Changes' : 'Submit'}
                    className={`btn btn-orange comp-seting ${(terms === false || loading === true) ? "btn-disabled" : ""}`}
                    type='button'
                    disabled={(terms === false || loading === true) ? true : false}
                    onClick={onSubmit}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Mou
        showMou={showMou}
        onToggle={toggleMou}
        schoolName={personalDetails.schoolName}
        schoolAddress={`${generalDetails.address}, ${titleCase(generalDetails?.city)}${generalDetails?.city ? "," : ""} ${generalDetails.state} - ${generalDetails.pincode}`}
        // schoolAddress={`${generalDetails.address}, ${generalDetails.city.replace(/\w\S*/g, (t) => { return t.charAt(0).toUpperCase() + t.substr(1).toLowerCase() })}, ${context.utils.upperCaseFirst(generalDetails.state.toLowerCase())} - ${generalDetails.pincode}`}
        amenityDetails={schoolAmenities}
        activityDetails={schoolActivities}
        registeredUnder={personalDetails.registeredUnder}
      />
      <ConfirmDialog
        confirmTitle="Poor Internet Connection Detected"
        componentName="We've detected issues with your internet connection that could affect school onboarding & data loss. Please check your internet connection & try again."
        confirmText="Got it"
        denyText="Cancel"
        togglePopup={toggleNetworkDialog}
        onModalClose={networkConfirmClose}
        onConfirmBtn={true}
      />
    </>
  )
}

export default Summary
