import { useContext, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"

import loginBgLeft from "../../../../assets/images/backendAdmin/login-bg.svg"
import logo from "../../../../assets/images/logo.png"
import AppContextObject from "../../../../common/context/common"
import { PublicContextInitiator } from "../../../apis/publicContextInitiator"
import AlertDialog from "../../../../components/addon/alertDialog/alertDialog"
import errorSVG from "../../../../assets/images/error.svg"


const EnterOTP  = () => {

    const [emptyNos, setEmptyNos] = useState([])
    const [otpLength] = useState(4)
    const [otp, setOTP] = useState("")
    const context = useContext(AppContextObject)
    const shiftFocus = (event, nextEl) => {
        if (event.target.value.trim() !== "") {
            document.getElementById(nextEl).focus()
        }
    }
    const [username,setUsername] = useState()
    const history = useHistory()
    const [showRedBorder, setShowRedBorder] = useState(true)
    useEffect(() => {
        if(history.location.state){
            const tempData: any = history.location.state
            console.log(" Userdata htempData",tempData)
            setUsername(tempData.username)
        }
        // const display = document.querySelector('#time')
        // const time = countdownTime
        // initiateTimer(time, display)
    }, [])
    const [showModal,setShowModal] = useState(true)
    const [countdownInterval, setCountdownInterval] = useState(null)
    const [displayTime, setDisplaytime] = useState("00:60") // 60 seconds
    const [countdownTime, setCountdownTime] = useState(59) // 60 seconds
    const [showResend, setShowResend] = useState(false)
    let [resetTimer, setResetTimer] = useState(false);
    let [detailsCaptured, setDetailsCaptured] = useState('');
    let [toggleAlertPopUp, setToggleAlertPopUp] = useState(false);
    let [otpError, setOtpError] = useState(false);
    let [msgDetails, setMsgDetails] = useState('')
    let [overlay, setOverlay] = useState(false)
    let [getFieldInfo, setGetFieldInfo] = useState('');

    const onFormSubmit = async () => {
        const idPrefix = "num"
        let emptyArr = []
        let thisOTP = ""
        for (let i = 1; i <= otpLength; i++) {
            const id = idPrefix + i
            const num:any = document.getElementById(id)
            if (!num.value) {
                emptyArr.push(id)
            } else {
                thisOTP = thisOTP + num.value
            }
        }
        setEmptyNos(emptyArr)
        setOTP(thisOTP)

        console.log("otp sending", thisOTP)
        let publicContextInitiator = new PublicContextInitiator(context)
       let  resp = await publicContextInitiator.onboardingSchoolEmailOTPVerification(
            context.validator, { "otp": thisOTP, "username": username }
        )
        console.log("otp verifictaion response", resp)
        if (!resp.done) {
            // show alert dialog

            setDetailsCaptured('')
            setShowRedBorder(true)
            if (resp.msg == "OTP timer expired") {
                setToggleAlertPopUp(true)
                setOverlay(true)
                setMsgDetails('The OTP has expired. Click on RESEND to generate OTP again.')
            }
            else if (resp.msg == "incorrect OTP") {
                setOtpError(true)
            }
        }
        else {
            // onModalSubmit(formData.otp)
            clearData()
            // onClose && onClose()
            history.push({
                pathname: '/brands/reset-password',
                state: {
                    username : username,
                    from: "enterotp"
                },
              });
        }

    }
    const clearData = () => {
        clearInterval(countdownInterval)
        setShowModal(false)
        setShowResend(false)
        setOtpError(false)

        const idPrefix = "num"
        let emptyArr = []
        for (let i = 1; i <= otpLength; i++) {
            const id = idPrefix + i
            const num:any = document.getElementById(id)
            num.value = ""
                emptyArr.push(id)
             
        }
        // setEmptyNos(emptyArr)
    }
    const confirmClose = () => {
        setOverlay(false)
        setToggleAlertPopUp(false)
    }

    const resendOTP = async () => {
        if (showResend === true) {
            let publicContextInitiator = new PublicContextInitiator(context)

                    await publicContextInitiator.reSendOTPOnEmail(
                        context.validator, { "username": username }
                    )

            setShowRedBorder(false)
            // disable resend button once OTP is resent and restart the timer
            clearData()
            setShowResend(false)
            clearInterval(countdownInterval)
            setCountdownInterval(null)
            // setResetTimer(true)
            const display = document.querySelector('#time')
            const time = countdownTime
            initiateTimer(time, display)
            setOtpError(false)
            //initiateTimer(60, document.querySelector('#time'))
        }
    }

    const initiateTimer = (duration: any, display) => {
        setResetTimer(false);
        let timer = duration
        timer = timer.toString()
        let minutes = 0
        let seconds = 0
        const interval = setInterval(function () {
            const m = timer / 60
            const s = timer % 60
            minutes = parseInt(m.toString(), 10)
            seconds = parseInt(s.toString(), 10)

            const displayMinutes = minutes < 10 ? "0" + minutes : minutes
            const displaySeconds = seconds < 10 ? "0" + seconds : seconds

            display.textContent = displayMinutes + ":" + displaySeconds
            if (displayMinutes === "00" && displaySeconds === "00") {
                clearInterval(interval)
                setCountdownInterval(null)
                setShowResend(true)
            }
            if (--timer < 0) {
                timer = duration
            }
        }, 1000)
        setCountdownInterval(interval)
    }

    useEffect(() => {
        const display = document.querySelector('#time')
        if (showModal === true) {
            const time = countdownTime
            initiateTimer(time, display)
        } else {
            display.textContent = displayTime
            clearInterval(countdownInterval)
        }
    }, [showModal]) // resetTimer
    
    return (
        <>
             <div className={`ovelap ${overlay === true ? "active" : ""}`}></div>
            <div className="main-wapper">
                <div className="loginbox">
                <div className="login-wave backendlogin"> <img src={loginBgLeft} alt="login-wave" /> </div>
                    <div className="login-form">
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb20">
                                <img src={logo} alt="logo"/>
                                <h2 className="htx1">Recover Password</h2>
                                <p className="ptx2">Enter the OTP that has been sent to registered email <strong>{}</strong></p>
                            </div>
                        </div>
                        <form>
                            <div className="row">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                    <div className="forminpt">
                                        <div className="optbox">
                                            <input
                                                onChange={(e) => shiftFocus(e, "num2")} id="num1" name="" type="text" maxLength={1}
                                                className={`${emptyNos.find(e => e === "num1") && showRedBorder && "onFocus"}`}
                                            />
                                            <input
                                                onChange={(e) => shiftFocus(e, "num3")} id="num2" name="" type="text" maxLength={1}
                                                className={`${emptyNos.find(e => e === "num2")  && showRedBorder &&  "onFocus"}`}
                                            />
                                            <input
                                                onChange={(e) => shiftFocus(e, "num4")} id="num3" name="" type="text" maxLength={1}
                                                className={`${emptyNos.find(e => e === "num3") && showRedBorder && "onFocus"}`}
                                            />
                                            <input
                                                onChange={(e) => shiftFocus(e, "submitBtn")} id="num4" name="" type="text" maxLength={1}
                                                className={`${emptyNos.find(e => e === "num4") && showRedBorder && "onFocus"}`}
                                            />
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="row col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-10 email-veri">
                                    <label className="blightcolor">Not received the code, yet? {" "} 
                                        <a 
                                            href="javascript:void(0)" data-rel="resendOTP"
                                            className={showResend === false ? "disabled" : ""} 
                                            // style={{cursor: "pointer", color:"#fe6e00"}} 
                                            onClick={resendOTP}>RESEND CODE
                                        </a>
                                        <span id="time">{displayTime}</span>
                                    </label>
                                </div>   
                                <span className={`valError ${otpError === true ? "displayBlock" : "displayNone"}`}><img src={errorSVG} />Wrong code entered. Please try again.</span>                             
                                <div className="row col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                    <div className="forminpt">
                                        <input onClick={onFormSubmit} id="submitBtn" name="Continue" value="Continue" className="btn btn-orange datasubmit" type="button" />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <AlertDialog
                togglePopup={toggleAlertPopUp}
                fieldInfo={getFieldInfo}
                details={detailsCaptured}
                onModalClose={confirmClose}
                displayMsg={msgDetails} />
        </>
    )
}

export default EnterOTP
