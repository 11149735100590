import React from "react";
import imgViewTwo from "../../../../assets/images/backendAdmin/img-view-two.jpg";
import TableSpan from './TableSpanCanmpaign';
const BrandCampaignsTable = ({ campaigns, campaignType, loading }) => {
    return (
        <div className="table-container table-inventory mt-10">
            <table className="table">
                <thead>
                    <tr>
                        <th>SL No</th>
                        <th>Campaign Name</th>
                        <th>school</th>
                        <th>inventories</th>
                        <th>schedule</th>
                        <th>Budget</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {loading ?
                        [...Array(10)].map((el, index) => {
                            return (
                                <tr>
                                    {
                                        [...Array(7)].map((el, index) => {
                                            return (
                                                <td key={index}>
                                                    <div className="content-loader-block"></div>
                                                </td>
                                            )
                                        })
                                    }
                                </tr>
                            )
                        })
                        :
                        campaigns && campaigns.length > 0 ?
                        <>
                            {campaigns && campaigns.map((campaign, key) => {
                                return (
                                    <TableSpan
                                        campaign={campaign}
                                        keyNo={key}
                                        campaignType={campaignType}
                                    />
                                )
                            })
                            }
                        </>
                        :
                        <tr><td colSpan={7}>No Campaign found</td></tr>
                    }

                </tbody>
            </table>
        </div>
    )
}
export default BrandCampaignsTable;
