
import { stringify } from "querystring";
import { useContext, useEffect, useState } from "react"
import faqArrow from "../../assets/images/faq-arrow.svg"
import AppContextObject from "../../common/context/common";
import errorSVG from "../../assets/images/error.svg"
const EnterPriceModal = (props) => {

    const {
        showPriceModal,
        closeModal,
        inventoryDetails,
        setForceUpdate,
        forceUpdate
    } = props
    const context = useContext(AppContextObject);
    const [openCatA, setOpenCatA] = useState(false);
    const [openCatB, setOpenCatB] = useState(false);
    const [openCatC, setOpenCatC] = useState(false);
    const [priceErrA, setPriceErrA] = useState(false);
    const [priceErrB, setPriceErrB] = useState(false);
    const [priceErrC, setPriceErrC] = useState(false);
    const [catA, setCatA] = useState([])
    const [catB, setCatB] = useState([])
    const [catC, setCatC] = useState([])
    const [addingPrice, setAddingPrice] = useState(false)

    
    useEffect(() => {
        if (!inventoryDetails.isCustom) {
            if (inventoryDetails.type === "events&Occasions") {
                setCatA(inventoryDetails.eventForCatA);
                setCatB(inventoryDetails.eventForCatB);
                setCatC(inventoryDetails.eventForCatC);
            }
            else {
                setCatA(inventoryDetails.inventoryForCatA);
                setCatB(inventoryDetails.inventoryForCatB);
                setCatC(inventoryDetails.inventoryForCatC);
            }
        } else {

        }

    }, [inventoryDetails])

    const handleAddPrice = (e, name, type, categoryType) => {
        // console.log(e.target.value, name, type)
        if (categoryType === "events&Occasions") {
            if (type === 'catA') {
                var resultA = [...catA]
                resultA = resultA.map((x) => { //<- use map on result to find element to update using id
                    if (x.eventName === name) x.price = e.target.value;
                    return x;
                });
                // console.log(catA)
                setCatA(resultA)
            }
            if (type === 'catB') {
                var resultB = [...catB]
                resultB = resultB.map((x) => { //<- use map on result to find element to update using id
                    if (x.eventName === name) x.price = e.target.value;
                    return x;
                });
                // console.log(resultB)
                setCatB(resultB)

            }
            if (type === 'catC') {
                var resultC = [...catC]
                resultC = resultC.map((x) => { //<- use map on result to find element to update using id
                    if (x.eventName === name) x.price = e.target.value;
                    return x;
                });
                // console.log(resultC)
                setCatC(resultC)

            }
        } else {
            if (type === 'catA') {
                var resultA = catA.slice()
                resultA = resultA.map((x) => { //<- use map on result to find element to update using id
                    if (x.inventoryName === name) x.price = e.target.value;
                    return x;
                });
                // console.log(resultA)
                setCatA(resultA)
            }
            if (type === 'catB') {
                var resultB = [...catB]
                resultB = resultB.map((x) => { //<- use map on result to find element to update using id
                    if (x.inventoryName === name) x.price = e.target.value;
                    return x;
                });
                // console.log(resultB)
                setCatB(resultB)

            }
            if (type === 'catC') {
                var resultC = [...catC]
                resultC = resultC.map((x) => { //<- use map on result to find element to update using id
                    if (x.inventoryName === name) x.price = e.target.value;
                    return x;
                });
                // console.log(resultC)
                setCatC(resultC)

            }

        }
    }
    const resetData = () => {
        console.log(inventoryDetails.isCustom, inventoryDetails.type, inventoryDetails.inventoryForCatA)
        if (!inventoryDetails.isCustom) {
            if (inventoryDetails.type === "events&Occasions") {
                setCatA(inventoryDetails.eventForCatA);
                setCatB(inventoryDetails.eventForCatB);
                setCatC(inventoryDetails.eventForCatC);
            }
            else {
                setCatA(inventoryDetails.inventoryForCatA);
                setCatB(inventoryDetails.inventoryForCatB);
                setCatC(inventoryDetails.inventoryForCatC);
            }
        } else {
            setCatA([]);
            setCatB([]);
            setCatC([]);
        }
    }
    const savePrice = () => {
        if (catA.find(item => item?.price == 0) || catB.find(item => item?.price == 0) || catC.find(item => item?.price == 0)) {
            if (catA.find(item => item?.price == 0)) {
                setPriceErrA(true)
            }
            else {
                setPriceErrA(false)
            }
            if (catB.find(item => item?.price == 0)) {
                setPriceErrB(true)
            } else {
                setPriceErrB(false)
            }
            if (catC.find(item => item?.price == 0)) {
                setPriceErrC(true)
            } else {
                setPriceErrC(false)
            }
        } else {
            updateInventory();

        }
    }
    const updateInventory = async () => {
        const data = {
            id: inventoryDetails._id,
            catA,
            catB,
            catC,
            type: inventoryDetails.type === "events&Occasions" ? 'event' : 'inventory'
        }
        setAddingPrice(true)
        const resp = await context.apis[context.user.role].updateInventoryMasterBrandOutlay(
            context.validator, { data: data }
        )
        console.log(resp)
        if (resp && resp.done) {
            setForceUpdate(!forceUpdate)
            setAddingPrice(false)
            closeModal();
            setOpenCatA(false);
            setOpenCatB(false);
            setOpenCatC(false);
            setPriceErrA(false);
            setPriceErrB(false);
            setPriceErrC(false);
        }
    }
    const onlyNumberKey=(evt,item, cat, type)=> {
        if(evt.target.value.length<=12){
            handleAddPrice(evt, item, cat, type) 
        }
    }
    // console.log('category', catA, catB, catC)
    // console.log(inventoryDetails.eventForCatA)
    return (
        <>
            <div id="thankyouModal" className={`popup ${showPriceModal ? "" : "hide"}`}>
                <div className="popup-header">
                    <h3 className="htx2 pophead">{'Base Price'}</h3>
                    <span className="close toggle" onClick={() => { closeModal(); setOpenCatA(false); setOpenCatB(false); setOpenCatC(false); setPriceErrA(false); setPriceErrB(false); setPriceErrC(false);}} data-target="myPopup">close</span>
                </div>
                <div className="popup-body">
                    <div className="faq-tabs-list">

                        <>
                            {inventoryDetails.type !== 'events&Occasions' ?
                                <>
                                    <div className={"faq-tablist"}>
                                        <h4><a id="anchor" style={{ cursor: "pointer" }} onClick={() => { setOpenCatA(!openCatA); setOpenCatB(false); setOpenCatC(false); }}>{'School Cat A'} <img id="img" src={faqArrow} /></a></h4>
                                        <div className={openCatA ? 'faq-tablistView-open' : 'faq-tablistView'}>
                                            {inventoryDetails.inventoryForCatA && inventoryDetails.inventoryForCatA.map((item, key) => {
                                                return <div className="row" key={key}>
                                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 forminpt pl pr">
                                                        <div className="row">
                                                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                                                <label className="labelfaqprice">{item.inventoryName}</label>
                                                            </div>
                                                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                                                <input placeholder="Enter price"
                                                                    type="number" 
                                                                    disabled={context.user.role==='backendSales' ? true : false}
                                                                    value={item.price} 
                                                                    onChange={(e) => onlyNumberKey(e, item.inventoryName, 'catA', "inventory")} />
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            })
                                            }
                                        </div>
                                    </div>
                                    {priceErrA && <span className="valError">
                                        <img src={errorSVG} />
                                        Any field must not be 0 or empty.
                                    </span>}
                                    <div className={"faq-tablist"}>
                                        <h4><a id="anchor" style={{ cursor: "pointer" }} onClick={() => { setOpenCatB(!openCatB); setOpenCatA(false); setOpenCatC(false) }}>{'School Cat B'} <img id="img" src={faqArrow} /></a></h4>
                                        <div className={openCatB ? 'faq-tablistView-open' : 'faq-tablistView'}>
                                            {inventoryDetails.inventoryForCatB && inventoryDetails.inventoryForCatB.map((item, key) => {
                                                return <div className="row" key={key}>
                                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 forminpt pl pr">
                                                        <div className="row">
                                                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                                                <label className="labelfaqprice">{item.inventoryName}</label>
                                                            </div>
                                                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                                                <input placeholder="Enter price"
                                                                type="number"
                                                                disabled={context.user.role==='backendSales' ? true : false}
                                                                value={item.price} 
                                                                onChange={(e) => onlyNumberKey(e, item.inventoryName, 'catB', "inventory")} />
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            })
                                            }

                                        </div>
                                    </div>
                                    {priceErrB && <span className="valError">
                                        <img src={errorSVG} />
                                        Any field must not be 0 or empty.
                                    </span>}
                                    <div className={"faq-tablist"}>
                                        <h4><a id="anchor" style={{ cursor: "pointer" }} onClick={() => { setOpenCatC(!openCatC); setOpenCatA(false); setOpenCatB(false); }}>{'School Cat C'} <img id="img" src={faqArrow} /></a></h4>
                                        <div className={openCatC ? 'faq-tablistView-open' : 'faq-tablistView'}>
                                            {inventoryDetails.inventoryForCatC && inventoryDetails.inventoryForCatC.map((item, key) => {
                                                return <div className="row" key={key}>
                                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 forminpt pl pr">
                                                        <div className="row">
                                                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                                                <label className="labelfaqprice">{item.inventoryName}</label>
                                                            </div>
                                                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                                                <input placeholder="Enter price"
                                                                type="number"
                                                                disabled={context.user.role==='backendSales' ? true : false}
                                                                value={item.price} 
                                                                onChange={(e) => onlyNumberKey(e, item.inventoryName, 'catC', "inventory")} />
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            })
                                            }
                                        </div>
                                    </div>
                                    {priceErrC && <span className="valError">
                                        <img src={errorSVG} />
                                        Any field must not be 0 or empty.
                                    </span>}
                                </>
                                :
                                <>
                                    <div className={"faq-tablist"}>
                                        <h4><a id="anchor" style={{ cursor: "pointer" }} onClick={() => { setOpenCatA(!openCatA); setOpenCatB(false); setOpenCatC(false); }}>{'School Cat A'} <img id="img" src={faqArrow} /></a></h4>
                                        <div className={openCatA ? 'faq-tablistView-open' : 'faq-tablistView'}>
                                            {inventoryDetails.eventForCatA && inventoryDetails.eventForCatA.map((item, key) => {
                                                return <div className="row" key={key}>
                                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 forminpt pl pr">
                                                        <div className="row">
                                                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                                                <label className="labelfaqprice">{item.eventName}</label>
                                                            </div>
                                                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                                                <input placeholder="Enter price"
                                                                type="number" 
                                                                disabled={context.user.role==='backendSales' ? true : false}
                                                                value={item.price} 
                                                                onChange={(e) => onlyNumberKey(e, item.eventName, 'catA', "events&Occasions")} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            })
                                            }
                                        </div>
                                    </div>
                                    {priceErrA && <span className="valError">
                                        <img src={errorSVG} />
                                        Any field must not be 0 or empty.
                                    </span>}
                                    <div className={"faq-tablist"}>
                                        <h4><a id="anchor" style={{ cursor: "pointer" }} onClick={() => { setOpenCatB(!openCatB); setOpenCatA(false); setOpenCatC(false) }}>{'School Cat B'} <img id="img" src={faqArrow} /></a></h4>
                                        <div className={openCatB ? 'faq-tablistView-open' : 'faq-tablistView'}>
                                            {inventoryDetails.eventForCatB && inventoryDetails.eventForCatB.map((item, key) => {
                                                return <div className="row" key={key}>
                                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 forminpt pl pr">
                                                        <div className="row">
                                                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                                                <label className="labelfaqprice">{item.eventName}</label>
                                                            </div>
                                                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                                                <input placeholder="Enter price" 
                                                                type="number" 
                                                                disabled={context.user.role==='backendSales' ? true : false}
                                                                value={item.price} 
                                                                onChange={(e) => onlyNumberKey(e, item.eventName, 'catB', "events&Occasions")} />
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            })
                                            }

                                        </div>
                                    </div>
                                    {priceErrB && <span className="valError">
                                        <img src={errorSVG} />
                                        Any field must not be 0 or empty.
                                    </span>}
                                    <div className={"faq-tablist"}>
                                        <h4><a id="anchor" style={{ cursor: "pointer" }} onClick={() => { setOpenCatC(!openCatC); setOpenCatA(false); setOpenCatB(false); }}>{'School Cat C'} <img id="img" src={faqArrow} /></a></h4>
                                        <div className={openCatC ? 'faq-tablistView-open' : 'faq-tablistView'}>
                                            {inventoryDetails.eventForCatC && inventoryDetails.eventForCatC.map((item, key) => {
                                                return <div className="row" key={key}>
                                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 forminpt pl pr">
                                                        <div className="row">
                                                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                                                <label className="labelfaqprice">{item.eventName}</label>
                                                            </div>
                                                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                                                <input placeholder="Enter price" 
                                                                disabled={context.user.role==='backendSales' ? true : false}
                                                                type="number" 
                                                                value={item.price} 
                                                                onChange={(e) =>onlyNumberKey(e,item.eventName, 'catC', "events&Occasions") } />
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            })
                                            }
                                        </div>
                                    </div>
                                    {priceErrC && <span className="valError">
                                        <img src={errorSVG} />
                                        Any field must not be 0 or empty.
                                    </span>}
                                </>
                            }
                        </>


                    </div>
                    {context.user.role !== "backendSales" && <div className="row col-xl-12 col-lg-12 col-md-12 col-xs-12 end-md">
                        <div className="forminpt">
                            <input name="Finish" className={`btn btn-orange comp-seting ${addingPrice && "button--loading btn-disabled"}`} type="button" value="Save" onClick={savePrice} />
                        </div>
                    </div>}
                </div>
            </div>
        </>
    )
}

export default EnterPriceModal
