import { useState, useContext, useEffect, useRef } from "react"

import { useHistory } from "react-router-dom"
// import OnGoingCampaigns from "./onGoingCampaigns"
import Pagination from "../../../components/addon/pagination"
import arrowView from "../../../assets/images/arrow-view.svg"
import arrowViewRound from "../../../assets/images/arrow-view-round.svg"
import tableFilter from "../../../assets/images/table-filter.svg"
import TopBar from "../schools/components/topBar"
import AppContextObject from "../../../common/context/common"
import { formatDateShowMonth, returnReadableStatus } from "../../../service/helper"
import DataContext from "../../../service/brandContext"
import BrandTopBar from "./components/brandTopBar"
import { relative } from "path"
import CampaignStatusFilter from "./components/campaignStatusFilter";
import useClickOutside from "../../../service/useClickOutside"
import BrandBackBtn from "./components/brandBackBtn"
import SchoolList from "../backendAdmins/components/SchoolsList"
var campaignListGlobalVar = 1

const Campaigns = (props) => {
    const {
        isEdit,
        methods
    } = props

    const history = useHistory()
    const context = useContext(AppContextObject)
    const parentData = useContext<any>(DataContext)
    const [totalPage, setTotalPage] = useState([0])
    const [currentPage, setCurrentpage] = useState(1)
    const [creativeNeededList, setCreativeNeededList] = useState([])
    const [selectedIndex, setSelectedIndex] = useState(0)
    const [openStatusFilter, setOpenStatusFilter] = useState(false);
    const [filterType, setFilterType] = useState("");
    const [filterStatus, setFilterStatus] = useState([]);
    const [sortingValue, setSortingValue] = useState(true)
    const [sortingData, setSortingData] = useState<any>({_id: -1})
    const [showSchoolTable, setShowSchoolTable] = useState(false)
    const [campaignId, setCampaignId] = useState("")
    const [loading, setLoading] = useState(false)
    const [totalCampaignDetails, setTotalCampaignDetails] = useState({
        creativeReworkNeededList: [],
        onGoingCampaigns: 0,
        totalCampaigns: 0,
        totalReach: 0
    })
    const onPageChange = (page) => {
        setCurrentpage(page)
    }
    const ref = useRef(null);
    useClickOutside(ref, () => {setOpenStatusFilter(false)})
    // useEffect(() => {
        
    // }, [])
    useEffect(() => {
        getOnGoingCampaignList()
    }, [currentPage,filterType,filterStatus, sortingValue])
    useEffect(() => {
        if(currentPage !== 1){
            setCurrentpage(1)
        }
    }, [filterStatus])
    useEffect(() => {
        getBrandCampaignStats()
        if(parentData){
            parentData.setShowSidemenu(true)
        }
    }, [])
    const getBrandCampaignStats = async() => {
        const resp = await context.apis.public.getBrandCampaignStats(
            context.validator, {brandId: context.user.brand, userId: context.user._id,}, {}
        )
        //"6176aa8e30c9ed3b109f759d"
        console.log("totalBrandData", resp)
        if(resp && resp.done){
            let createiveReworkNeededArr = resp.data.creativeReworkNeeded.sort(function(a,b){
                let date1:any = new Date(a.updatedOn)
                let date2:any = new Date(b.updatedOn)
                return date2 - date1
            })
            console.log("createiveReworkNeededArr--->>",createiveReworkNeededArr, resp.data.creativeReworkNeeded);
            setTotalCampaignDetails({
                ...totalCampaignDetails,
                creativeReworkNeededList: createiveReworkNeededArr,
                onGoingCampaigns: resp.data.onGoingCampaigns,
                totalCampaigns: resp.data.totalCampaigns,
                totalReach: resp.data.totalReach
            })
        }
    }
    const getOnGoingCampaignList = async() => {
        setLoading(true)
        var localVar = Math.random()
        campaignListGlobalVar = localVar
        const resp = await context.apis.public.getCampaignsByBrand(
            context.validator, {brandId: context.user.brand, userId: context.user._id, status: filterStatus}, {limit: 10, page: currentPage, withCount: true,"sort": sortingData}
        )
        console.log("getCampaignsByBrand", resp)
        //"6176aa8e30c9ed3b109f759d"
        if(resp && resp.done){
            if(localVar === campaignListGlobalVar){
                setLoading(false)
                console.log(resp)
                setCampaignData(resp.response.rows)
                let total = Math.floor(Number(resp.response.count)/10 + (Number(resp.response.count)%10 > 0 ? 1 : 0))
                let arr = []
                for(let i=0; i<total; i++){
                    arr.push(i)
                }
                console.log(total)
                setTotalPage([...arr])
            }
            else{
                setLoading(false)
            }
        }
    }
    const [campaignData, setCampaignData] = useState([])
    console.log("campaignData for customCheck new", campaignData)
    const onPrev = () => {
        if(totalCampaignDetails.creativeReworkNeededList && selectedIndex > 0){
            setSelectedIndex(selectedIndex - 1)
        }
    }
    const onNext = () => {
        if(totalCampaignDetails.creativeReworkNeededList && totalCampaignDetails.creativeReworkNeededList.length - 1 > selectedIndex){
            setSelectedIndex(selectedIndex + 1)
        }
    }
    const onNextClick = () => {
        setCurrentpage(currentPage + 1)
    }
    const onPrevClick = () => {
        setCurrentpage(currentPage - 1)
    }
    const sortByBudget = () => {
        setSortingData({"budget": sortingValue ? -1 : 1})
        setSortingValue(!sortingValue)
    }
    const openSchoolTable = (_id) => {
        setCampaignId(_id)
        setShowSchoolTable(true)
    }
    const closeSchoolTable = () => {
        setShowSchoolTable(false)
    }
    const calculateDraftPercentage = (data) => {
        return data.campaignCreationProgress && data.campaignCreationProgress.progress ? data.campaignCreationProgress.progress.campaignCreative === true ? "- 90%" : data.campaignCreationProgress.progress.recommendedSchool === true ? "- 80%" : data.campaignCreationProgress.progress.school === true ? "- 60%" : data.campaignCreationProgress.progress.event === true ? "- 60%" : data.campaignCreationProgress.progress.inventory === true ? "- 40%" : data.campaignCreationProgress.progress.brandDetails ? "- 20%" : "" : "";
    }

    // console.log("campaignDataCampaignListBrand", campaignData)
    return (
        <>
            <div className={`ovelap`}></div>
            {/* <TopBar isDashboard={true} /> */}
            <div className="col-xl-10 col-lg-12 col-md-12 col-xs-12">
                <div className="rightPart noBack">
                    <div className="row">
                        <div className="col-xs-12 col-lg-12 col-md-12 col-xs-12">
                            <BrandBackBtn
                                    link="/"
                                    text="Log Out"
                                />
                        </div>
                        <BrandTopBar isDashboard={true} /> 
                        <div className="col-xl-7 col-lg-7 col-md-12 col-xs-12 mt-20 pl pr">
                            <div className="row">
                                <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12">
                                    <div className="impressions-slider box-design campaignsCreate">
                                        <h2 className="htx2 clearfix mb-10">New Campaign</h2>
                                        <p className="ptx2">Create a campaign and use in-school inventory + events/occasions for maximum impact.</p>
                                        <a href="javascript:void(0);" className={`btn btn-orange ml mt-10 `} onClick={() => history.push("/brands/brand-details/create-campaign/set-up-campaign")}>Create Campaign</a>
                                        {/* ${process.env && process.env.REACT_APP_production && process.env.REACT_APP_production == "true" ? "btn-disabled" : ""} */}
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12">
                                    <div className="impressions-slider box-design campaignsCreate">
                                        <h2 className="htx2 clearfix mb-10">Trial Campaign</h2>
                                        <p className="ptx2">Create a 'Trial campaign' to view and understand all the available features on the platform.</p>
                                        <a href="javascript:void(0);" className="btn btn-orange ml mt-10" onClick={() => history.push({pathname: "/brands/brand-details/create-trial-campaign/set-up-campaign", state: {campaignType: "trail"}})}>Create</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-5 col-lg-5 col-md-12 col-xs-12 mt-20 pl pr">
                            <div className="row">
                                <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12">
                                    <div className="inventory-overHead box-design campaign-total">
                                        <p>Total Campaign Run</p>
                                        <h3>{totalCampaignDetails.totalCampaigns.toLocaleString('en-IN')} <span>{totalCampaignDetails.onGoingCampaigns.toLocaleString('en-IN')} Ongoing</span></h3>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12">
                                    <div className="inventory-overHead box-design campaign-total">
                                        <p>Total Reach</p>
                                        <h3>{totalCampaignDetails.totalReach.toLocaleString('en-IN')} <span>Students Reached</span></h3>
                                    </div>
                                </div>
                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-10">
                                    <div className="inventory-overHead box-design campaign-total campaign-alerts">
                                        <div className={`blog-arrow ${totalCampaignDetails.creativeReworkNeededList.length < 2 ? "dnone" : ""}`}>
                                            <a style={{ cursor: "pointer" }} onClick={() => onPrev()} className="left-arrowB"><svg width="9" height="14" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M6 11L1 6L6 1" stroke="#8C8C8C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /> </svg></a>
                                            <a style={{ cursor: "pointer" }} onClick={() => onNext()} className="left-arrowR"><svg width="9" height="14" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M1 11L6 6L1 1" stroke="#8C8C8C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /> </svg></a>
                                        </div>
                                        <p>Campaign Alerts</p>
                                        <div className="slideshow" style={{width: "100%"}}>
                                        <div className=" slideshowSlider" style={{ transform: `translate3d(${-selectedIndex * 100}%, 0, 0)` }}>
                                            {
                                                totalCampaignDetails.creativeReworkNeededList.length > 0
                                                ?
                                                totalCampaignDetails.creativeReworkNeededList.map((data, index) => {
                                                    return (
                                                        <>
                                                        <div className="slide " key={selectedIndex}>
                                                            <h3>Creative Rework Needed {`(${totalCampaignDetails.creativeReworkNeededList.length})`} 
                                                                <span>{totalCampaignDetails.creativeReworkNeededList.length > 0 ? totalCampaignDetails.creativeReworkNeededList[selectedIndex].name : "No data"}
                                                                </span>
                                                            </h3>
                                                            {totalCampaignDetails.creativeReworkNeededList.length > 0 && <a className="viewwLink camp-link" style={{cursor: "pointer",  position: "relative" }} onClick={() => openSchoolTable(totalCampaignDetails.creativeReworkNeededList[selectedIndex]._id)}>View details <img className="arrowView" src="/static/media/arrow-view.17cce853.svg" /></a>}
                                                        </div>
                                                        </>
                                                    )
                                                })
                                                :
                                                <span>{"No alerts"}
                                                </span>
                                            }
                                        </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-40 tableScroll fillterTableHeight">
                            <table className="table going-campaign">
                                <thead>
                                    <tr ref={ref}>
                                        <th>SL No</th>
                                        <th>Campaign Name</th>
                                        <th className="p-relative">Status <a className="openFilter" href="javascript:void(0);" onClick={() => {console.log("filter clicked"); setOpenStatusFilter(!openStatusFilter)}}><img src={tableFilter} /></a>
                                        <CampaignStatusFilter
                                                openStatusFilter={openStatusFilter}
                                                setFilterStatus={setFilterStatus}
                                                filterStatus={filterStatus}
                                            /></th>
                                        <th>Budget <a href="javascript:void(0);" onClick={sortByBudget}><img src={tableFilter} /></a></th>
                                        <th>school</th>
                                        <th>schedule</th>
                                        <th>reach</th>
                                        <th>impression</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        !loading ?
                                        campaignData.length > 0 ?
                                        campaignData.map((data, index) => {
                                            return (
                                                <>
                                                    <tr>
                                                        <td>{((currentPage - 1) * 10 ) + (index + 1)}</td>
                                                        <td>{data.name}
                                                        
                                                      &nbsp; { data?.wisrOffering?.custom && <span className="table-status orangebg "> Custom </span> }
                                                        </td>
                                                        <td>
                                                            <span className={`table-status ${data.campaignStatus.toLowerCase() === "ongoing" && "greenbg"} ${data.campaignStatus.toLowerCase() === "scheduled" && "orangebg"} ${data.campaignStatus.toLowerCase() === "on-hold" && "orangebg"} ${data.campaignStatus.toLowerCase() === "in-review" && "yellowbg"} ${data.campaignStatus.toLowerCase() === "executed" && "redbg"} ${data.campaignStatus.toLowerCase() === "draft" && "graybg"} ${data.campaignStatus.toLowerCase() === "trial" && "yellowbg"} ${data.campaignStatus.toLowerCase() === "rejected" && "redbg"}`}>
                                                                {data.campaignStatus ? `${returnReadableStatus(data.campaignStatus)} ${data.campaignStatus.toLowerCase() === "draft" ? calculateDraftPercentage(data) : ""}` : "-" }
                                                            </span>
                                                        </td>
                                                        <td>₹ {data.brandOutlay !== null && data.brandOutlay !== null ? data.brandOutlay.toLocaleString('en-IN') : data.budget ? data.budget == 0 && data.budget == null : "-"}</td>
                                                        <td>{data && data.schools && data.schools.length}</td>
                                                        <td>{`${formatDateShowMonth(data.startDate, 2)} - ${formatDateShowMonth(data.endDate, 2)}`}</td>
                                                        <td>{data.totalReach !== null && data.totalReach !== null ? data.totalReach.toLocaleString('en-IN') : "-"}</td>
                                                       { data?.wisrOffering?.eventAndOccations && !data?.wisrOffering?.inventory ?

                                                        <td>-</td>
                                                        :
                                                        data?.wisrOffering?.eventAndOccations && data?.wisrOffering?.inventory ?
                                                        <td>{data.totalImpression !== null && data.totalImpression !== null ? data.totalImpression.toLocaleString('en-IN') : "-"}</td>
                                                       :
                                                        <td>{data.totalImpression !== null && data.totalImpression !== null ? data.totalImpression.toLocaleString('en-IN') : "-"}</td>
                                                    }
                                                        <td><span style={{cursor: "pointer"}}><img onClick={() => data.campaignStatus.toLowerCase() !== "rejected" && history.push({pathname: data.campaignStatus.toLowerCase() === "draft" ? data.isTrial ? "/brands/brand-details/create-trial-campaign/set-up-campaign" : "/brands/brand-details/create-campaign/set-up-campaign" : "/brands/brand-details/campaign-details", state: {campaignId: data._id}})} src={arrowViewRound} /></span></td>
                                                    </tr>

                                                    {
                                                        
    data.nonWisrCampaignLinked ? 
    
    
                                                    // <tr style={{backgroundColor:"#ddd"}}>
                                                    <>
                                                    {/* <hr /> */}
                                                    <tr >
                                                        
                                                        <td style={{borderTop:"none"}}></td>
                                                        
                                                        <td style={{borderTop:"1px solid #ddd"}}>{data.nonWisrCampaign.name}</td>
                                                        <td>
                                                            <span className={`table-status ${data.nonWisrCampaign.campaignStatus.toLowerCase() === "ongoing" && "greenbg"} ${data.nonWisrCampaign.campaignStatus.toLowerCase() === "scheduled" && "orangebg"} ${data.nonWisrCampaign.campaignStatus.toLowerCase() === "on-hold" && "orangebg"} ${data.nonWisrCampaign.campaignStatus.toLowerCase() === "in-review" && "yellowbg"} ${data.nonWisrCampaign.campaignStatus.toLowerCase() === "executed" && "redbg"} ${data.nonWisrCampaign.campaignStatus.toLowerCase() === "draft" && "graybg"} ${data.nonWisrCampaign.campaignStatus.toLowerCase() === "trial" && "yellowbg"} ${data.nonWisrCampaign.campaignStatus.toLowerCase() === "rejected" && "redbg"}`}>
                                                                {data.nonWisrCampaign.campaignStatus ? `${returnReadableStatus(data.nonWisrCampaign.campaignStatus)} ${data.nonWisrCampaign.campaignStatus.toLowerCase() === "draft" ? calculateDraftPercentage(data) : ""}` : "-" }
                                                            </span>
                                                        </td>
                                                        {/* <td>₹ {data.nonWisrCampaign.budget !== null && data.nonWisrCampaign.budget !== null ? data.nonWisrCampaign.budget.toLocaleString('en-IN') : "-"}</td> */}
                                                        {/* <td>₹ {data.brandOutlay !== null && data.brandOutlay != 0 ? data.nonWisrCampaign.budget.toLocaleString('en-IN') : "-"}</td> */}
                                                        <td>₹ {data.nonWisrCampaign.budget !== null && data.nonWisrCampaign.budget !== null ? data.nonWisrCampaign.budget.toLocaleString('en-IN') : "-"}</td>
                                                        {/* <td> {  data.nonWisrCampaign.budget } </td> */}
                                                        
                                                        <td>{data && data.nonWisrCampaign.schools && data.nonWisrCampaign.schools.length}</td>
                                                        <td>{`${formatDateShowMonth(data.nonWisrCampaign.startDate, 2)} - ${formatDateShowMonth(data.nonWisrCampaign.endDate, 2)}`}</td>
                                                        <td>{data.nonWisrCampaign.totalReach !== null && data.nonWisrCampaign.totalReach !== null  && data.nonWisrCampaign.totalReach != 0 ? data.nonWisrCampaign.totalReach.toLocaleString('en-IN') : "-"}</td>
                                                        <td>{data.nonWisrCampaign.totalImpression !== null && data.nonWisrCampaign.totalImpression !== null && data.nonWisrCampaign.totalImpression != 0 ? data.nonWisrCampaign.totalImpression.toLocaleString('en-IN') : "-"}</td>
                                                        <td ></td>
                                                    </tr>
                                                    </>
                                                    : ""
                }
                                                </>
                                            )

                                        })
                                        :
                                        <tr><td colSpan={9}><div style={{textAlign: "center"}}>No Campaign found</div></td></tr>
                                        :
                                        [...Array(10)].map((el, index) => {
                                            return (
                                                <tr>
                                                    {
                                                        [...Array(9)].map((el, index) => {
                                                            return (
                                                                <td>
                                                                    <div className="content-loader-block"></div>
                                                                </td>
                                                            )
                                                        })
                                                    }
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="col-lg-12 col-md-12 col-xs-12 p-r">
                            {totalPage.length > 1 &&
                                <Pagination
                                    currentPage={currentPage}
                                    totalPage={totalPage}
                                    onPageChange={onPageChange}
                                    onNextClick={onNextClick}
                                    onPrevClick={onPrevClick}
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
            {campaignId !== '' && <SchoolList
                showSchoolModal={showSchoolTable}
                closeSchoolModal={closeSchoolTable}
                campaignId={campaignId}
                showCampaignCreativeButton={true}
                fromBrand={true}
                // doNotShowCreativeStatus={totalCampaignDetails.creativeReworkNeededList[selectedIndex].isTrial}
            />}
        </>
    )
}

export default Campaigns
