import React from "react"

const DataContext = React.createContext({
    data: {
        brandDetail: {
            name: "",
            startDate: "",
            endDate: "",
            description: "",
            objective: "",
            inventory: false,
            eventAndOccations: false,
            csr: false,
            sampling: false,
            custom: false,
            // budget: 1,
            targetGender: "both",
            targetAgeGroup: {min: 3, max: 4},
            studyMedium: [],
            brandCategory: {value: "", label: ""},
            brandSubcategory: {value: "", label: ""},
            brandName: "",
            isTrial: false
        },
        campaignDetail: {
            wisrOption: true,
            totalImpression: 0,
            totalReach: 0,
            totalBudget: 5000000,
            boysRatio: 0,
            girlsRatio: 0
        },
        campaignCreative:{
            creatives: {image1: {}, image2: {}, image3: {}}
        },
        inventory: {
            inventories: [],
            notUsedInventories: [],
            usedInventories: []
        },
        school: {
            schools: [],
            locations: []
        },
        eventAndOccation: {
            eventAndOccations: [],
            usedEvents: []
        }
    },
    setShowSidemenu: () => {}
})

export const DataContextProvider = DataContext.Provider

export default DataContext