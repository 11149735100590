import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import arrowUpDown from "../../../../../assets/images/backendAdmin/arrow-up-down.svg";
import blankImg from '../../../../../assets/images/blank-inventory-img.jpg';
import { formatDateShowMonthBackend } from "../../../../../service/helper";
import AppContextObject from "../../../../../common/context/common";
import rupeeSymbol from '../../../../../assets/images/rupee-symbol.svg';



const CampaignTableSpan = ({flag,campaign,keyNo,currentPage,acceptRejectPayment, onlyActiveCampaign, schoolId}) => {
    const context = useContext(AppContextObject)
    const [openSpan, setOpenSpan] = useState(false);
    console.log("This is campaign--- new", campaign)
    return (
        <>
            <tr>
                <td>{(keyNo) + (currentPage - 1) * 10}</td>
                <td className="companyName">{campaign.name} &nbsp; { campaign?.wisrOffering?.custom && <span className="table-status orangebg "> Custom </span> }</td>
                <td className="companyName">{campaign.brand.brandName}</td>
                <td>{campaign.schools.length}</td>
                <td>{onlyActiveCampaign && context.user.role === "backendFOS" ? campaign.inventories : campaign.campaignData?.inventories?.length}</td>
                <td>{formatDateShowMonthBackend(campaign.startDate, 2)} - {formatDateShowMonthBackend(campaign.endDate, 2)}</td>
                <td>{formatDateShowMonthBackend(campaign.createdDate, 2)}</td>

                {/* <td>₹ {data.brandOutlay !== null && data.brandOutlay !== null ? data.brandOutlay.toLocaleString('en-IN') : data.budget ? data.budget == 0 && data.budget == null : "-"}</td> */}
                <td><img src={rupeeSymbol} className="rupeeSymbol" />{ campaign.brandOutlay !== null && campaign.brandOutlay !== null ? campaign.brandOutlay.toLocaleString('en-IN') : campaign.budget ? campaign.budget == 0 && campaign.budget == null : "-"}</td>
                {/* <td><img src={rupeeSymbol} className="rupeeSymbol" />{campaign.budget.toLocaleString('en-IN')}</td> */}

                <td className="companyName">{campaign.brand.wisrRepresentativeDetails ? campaign.brand?.wisrRepresentativeDetails?.fullName : "-"}</td>
                <td><span className= {`table-status ${campaign.campaignStatus==='rejected' ? 'redbg' : campaign.campaignStatus === "executed" ? "graybg" : campaign.campaignStatus === "scheduled" ? "orangebg" : campaign.campaignStatus==='ongoing' ? 'yellowbg' : campaign.campaignStatus==='in-review' && campaign.isApproved ? "greenbg" : campaign.campaignStatus ==="on-hold" ? "onholdbg" : "orangebg"} `}> {campaign.campaignStatus==='rejected' ? 'Rejected' : campaign.campaignStatus==='ongoing' ? 'Ongoing' : campaign.campaignStatus === "executed" ? "Executed" : campaign.campaignStatus === "scheduled" ? "Scheduled" : campaign.campaignStatus==='in-review' && campaign.isApproved ? "Approved" : campaign.campaignStatus === "on-hold" ? "On-Hold" : "In-Review"}</span></td>
                <td><span className={`table-status arrowsetOne ${campaign?.paymentStatus==='paid' ? 'greenbg' : 'orangebg'}`}>{campaign?.paymentStatus==='paid' ? 'Paid' : 'Pending'}</span>
                    <div className="arrowset arrowCampaign"><span className={openSpan ? `rowArrowUpDown`: ``} onClick={()=>setOpenSpan(!openSpan)}>
                        <svg className="down-arrow requestArrow" width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg"> 
                            <path d="M1 6L6 0.999999L11 6" stroke="#8C8C8C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /> 
                        </svg>
                    </span>
                    <Link to={{
                        pathname: onlyActiveCampaign === true ? context.user.role === "backendFOS" ? "/backend/backend-details/campaign-reporting" : "/backend/backend-details/active-schools" : "/backend/backend-details/create-trial-campaign-details",
                        state: (context.user.role === "backendSales" || context.user.role === "backendAdmin") ? { campaignType: campaign.isTrial ? 'trial' : 'create', from: onlyActiveCampaign === true ? "activeCampaign" : 'campaign', campaignId:campaign._id } : { campaignType: campaign.isTrial ? 'trial' : 'create', from: onlyActiveCampaign === true ? "activeCampaign" : 'campaign', campaignId:campaign._id, schoolId: schoolId }
                    }}><img className="updownarrow" src={arrowUpDown} /></Link></div>
                </td>
            </tr>
            {openSpan &&
                <tr>
                    <td className="campaignRequestOepn" colSpan={9}>
                        <table className="table">
                            <tbody>
                                <td style={{ width: "240px" }}>
                                    <div className="campaignRequestSlide">
                                        <div className="campaignRequestSlideLarge">
                                            <img src={campaign?.campaignCreativesRevised && campaign?.campaignCreativesRevised.length>0 ? campaign?.campaignCreativesRevised[0].image : (campaign?.campaignCreatives && campaign?.campaignCreatives.length>0 && campaign?.campaignCreatives[0].image) ? campaign?.campaignCreatives[0].image : blankImg } />
                                        </div>
                                        <div className="campaignRequestSlideSmall">
                                            <img src={campaign?.campaignCreativesRevised && campaign?.campaignCreativesRevised.length>1 ? campaign?.campaignCreativesRevised[1].image : (campaign?.campaignCreatives && campaign?.campaignCreatives.length>1 && campaign?.campaignCreatives[1].image) ? campaign?.campaignCreatives[1].image : blankImg } />
                                            <img src={campaign?.campaignCreativesRevised && campaign?.campaignCreativesRevised.length>2 ? campaign?.campaignCreativesRevised[2].image : (campaign?.campaignCreatives && campaign?.campaignCreatives.length>2 && campaign?.campaignCreatives[2].image) ? campaign?.campaignCreatives[2].image : blankImg } />
                                        </div>
                                    </div>
                                </td>
                                <td style={{ width: "auto" }}>
                                    <h4>Campaign Description</h4>
                                    <p>{campaign.description}</p>
                                </td>
                                {onlyActiveCampaign === false && campaign.campaignStatus==='in-review' && campaign.isApproved === false && !campaign.creativeReworkNeeded &&
                                    <td style={{ verticalAlign: "middle", width: "340px" }}>
                                        {context.user.role === "backendAdmin" &&
                                            <a href="javascript:void(0)" className={`btn btn-outline ${campaign.campaignCreativesRevised && campaign.campaignCreativesRevised.length>0 && "btnactivity "} ${(campaign.campaignStatus==='rejected' || campaign.isApproved) ? '' : ''}`} onClick={()=>{acceptRejectPayment('rejected',campaign);}}>{campaign.campaignStatus==='rejected' ? 'rejected' : campaign.campaignCreativesRevised && campaign.campaignCreativesRevised.length>0 ? 'reject creatives' : 'reject' }</a>
                                        }
                                        {context.user.role === "backendAdmin" &&
                                            <a href="javascript:void(0)" className={`btn btn-orange ${campaign.campaignCreativesRevised && campaign.campaignCreativesRevised.length>0 && "btnactivity "} ${(campaign.isApproved || campaign.campaignStatus==='rejected') ? '' : ''}`} onClick={()=>{acceptRejectPayment('accepted',campaign);}}>{campaign.isApproved ? 'approved' : campaign.campaignCreativesRevised && campaign.campaignCreativesRevised.length>0 ? 'approve creatives' : 'approve' }</a>
                                        }
                                    </td>
                                }
                            </tbody>
                        </table>
                    </td>
                </tr>
            }
        </>
    )
}
export default CampaignTableSpan;