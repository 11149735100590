import React from 'react'
import { useHistory } from 'react-router-dom'
import arrowView from "../../../../assets/images/arrow-view.svg"
import blogRight from "../../../../assets/images/blog-right.png"

const WisrProTip = ({wisrProTips, type}) => {
    const history = useHistory()
    return (
        <>
            <div className="row">
                <div className="blog-listing">
                    <div className="row">
                    <div className="col-xl-7 col-lg-7 col-md-7 col-xs-12 bloghead">
                        <h4>WISR Pro Tips:</h4>
                        <p>Use WISR smartly for maximum impact</p>
                    </div>
                    <div className={`col-xl-5 col-lg-5 col-md-5 col-xs-12 ${type === "brand" ? "hide" : ""}`}><a style={{cursor: "pointer"}} onClick={() => history.push("/schools/school-details/inventory")} className="viewwLink">View Inventory List <img className="arrowView" src={arrowView} /></a></div>
                    <div className="col-xl-5 col-lg-5 col-md-5 col-xs-12 flexcenter">
                        <img src={blogRight} />
                    </div>
                    <div className="col-xl-7 col-lg-7 col-md-7 col-xs-12">
                        <div className="blog-listingText">
                            <ul>
                                {wisrProTips.length !== 0 ? 
                                    wisrProTips.map((tip) => (
                                        <li>{tip.description}</li>
                                    ))
                                :   <div>
                                        <h3>Currently No WISR Tips</h3>
                                    </div>
                                }
                            </ul>
                        </div>
                    </div>
                    </div>
                </div>
                
            </div>
        </>
    )
}

export default WisrProTip
