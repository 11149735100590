import { useContext } from "react"

import {
    useLocation,
    useRouteMatch,
    Link
} from "react-router-dom"

import DataContext from "../../../../service/contextProvider"

import check from "../../../../assets/images/check.svg"

const InventoryProgress = ({addInventory}) => {

    const parentData = useContext<any>(DataContext)
    const location = useLocation()
    const router = useRouteMatch()

    return (
        <ul>
            {addInventory &&
                <li>
                    <Link
                        to={`${router.url}/remaining-invetories`}
                        className={`
                        ${parentData?.progress?.inventoryDetails === true && "complete"}
                        ${parentData.activeModule.subModule === "inventoryDetails" && "active"}`}
                    >
                        <span><img src={check} />1</span> Inventory Details
                    </Link>
                </li>
            }
            {!addInventory &&
                <li>
                    <Link
                        to={`${router.url}/events-and-occasions`}
                        className={`
                            ${parentData?.progress?.eventsAndOccasions === true ? "complete" : ""} 
                            ${parentData.activeModule.subModule === "events" && "active"}`}
                    >
                        <span><img src={check} />1</span> Events and Occasions
                    </Link>
                </li>
            }
            <li>
                <Link
                    to={`${router.url}/school-details-summary`}
                    className={`
                        ${parentData.activeModule.module === "preview" && "active"}
                        ${addInventory ? 
                            (parentData?.progress?.inventoryDetails === true ? "complete" : "disabledLink")
                            : (parentData?.progress?.eventsAndOccasions === true) ? 
                            "complete" : "disabledLink"
                        }    
                    `}
                >
                    <span><img src={check} />2</span> Preview
                </Link>
            </li>
        </ul>
    )
}

export default InventoryProgress
