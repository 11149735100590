import { useEffect, useState } from "react"

import { useHistory } from "react-router"

import config from "../../../service/config"

interface Props {
  isEdit ? : boolean
}
const PersonalDetails = ({ editImage, personalDetails, isEdit, addSchoolFromBackend }) => {
  const history = useHistory();

  const [userRole, setUserRole] = useState("-")
  useEffect(() => {
    if (personalDetails.role) {
      const found = config.schoolRoles.find(e => e.value === personalDetails.role)
      if (found) {
        setUserRole(found.label)
      }
    }
  }, [personalDetails])

  const goToPersonalDetails = () => {
    if(addSchoolFromBackend){
      history.push({
        pathname: "/backend/backend-details/schools/onboarding/your-details",
        search: `isSummary=${true}`,
      });
    }else if(isEdit === true){
      history.push({
        pathname: "/schools/school-details/details/your-details",
        search: `isSummary=${true}`,
      });
    }else{
      history.push({
        pathname: "/schools/onboarding/your-details",
        search: `isSummary=${true}`,
      });
    }
  };
  return (
    <div className='col-xl-12 col-lg-12 col-md-12 col-xs-12'>
      <div className='personal-box'>
        <div className='row personal-box-top'>
          <div className='row col-xl-8 col-lg-8 col-md-8 col-xs-8 start-md'>
            <h3 className='htx2 mb20'>Personal Details</h3>
          </div>
          <div className='row col-xl-4 col-lg-4 col-md-4 col-xs-4 end-xs'>
            <a
              href='javascript:void(0)'
              className='editbtn'
              onClick={goToPersonalDetails}
            >
              <img src={editImage} /> Edit
            </a>
          </div>
        </div>
        <div className='personal-bottom'>
          <div className='row personal-box-bot'>
            <div className='col-xl-6 col-lg-6 col-md-6 col-xs-12'>
              <p>Name</p>
            </div>
            <div className='col-xl-6 col-lg-6 col-md-6 col-xs-12'>
              <b>
                {personalDetails.fullName ? personalDetails.fullName : null}
              </b>
            </div>
            <div className='col-xl-6 col-lg-6 col-md-6 col-xs-12'>
              <p>Username</p>
            </div>
            <div className='col-xl-6 col-lg-6 col-md-6 col-xs-12'>
              <b>
                {personalDetails.username ? personalDetails.username : null}
              </b>
            </div>
            <div className='col-xl-6 col-lg-6 col-md-6 col-xs-12'>
              <p>Email ID</p>
            </div>
            <div className='col-xl-6 col-lg-6 col-md-6 col-xs-12'>
              <b>{personalDetails.email ? personalDetails.email : null}</b>
            </div>
            <div className='col-xl-6 col-lg-6 col-md-6 col-xs-12'>
              <p>Mobile No.</p>
            </div>
            <div className='col-xl-6 col-lg-6 col-md-6 col-xs-12'>
              <b>{personalDetails.phone ? `+91-${personalDetails.phone}` : null}</b>
            </div>
            <div className='col-xl-6 col-lg-6 col-md-6 col-xs-12'>
              <p>School Name</p>
            </div>
            <div className='col-xl-6 col-lg-6 col-md-6 col-xs-12'>
              <b>
                {personalDetails.schoolName ? personalDetails.schoolName : null}
              </b>
            </div>
            <div className='col-xl-6 col-lg-6 col-md-6 col-xs-12'>
              <p>Role in Organization</p>
            </div>
            <div className='col-xl-6 col-lg-6 col-md-6 col-xs-12'>
              <b>{ userRole }</b>
            </div>
            {
              personalDetails.schoolPrincipalName &&
              <>
                <div className='col-xl-6 col-lg-6 col-md-6 col-xs-12'>
                  <p>Prinicipal's Name</p>
                </div>
                <div className='col-xl-6 col-lg-6 col-md-6 col-xs-12'>
                  <b>{ personalDetails.schoolPrincipalName }</b>
                </div>
              </>
            }
            {
              personalDetails.schoolPrincipalEmail &&
              <>
                <div className='col-xl-6 col-lg-6 col-md-6 col-xs-12'>
                  <p>Prinicipal's Email ID</p>
                </div>
                <div className='col-xl-6 col-lg-6 col-md-6 col-xs-12'>
                  <b>{ personalDetails.schoolPrincipalEmail }</b>
                </div>
              </>
            }            
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalDetails;
