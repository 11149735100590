import React from "react";

const ClassBoard = ({ classData }) => {
  return (
    <div className='class-grade-mid'>
      <div className='row personal-box-bot'>
        <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12'>
          <h3 className='htx2'>Grade {classData.class}</h3>
        </div>
        <div className='col-xl-6 col-lg-6 col-md-6 col-xs-12'>
          <p>Number of Sections/Division</p>
        </div>
        <div className='col-xl-6 col-lg-6 col-md-6 col-xs-12'>
          <b>{classData.data.noOfSections}</b>
        </div>
        <div className='col-xl-6 col-lg-6 col-md-6 col-xs-12'>
          <p>Avg Age of Students</p>
        </div>
        <div className='col-xl-6 col-lg-6 col-md-6 col-xs-12'>
          <b>{classData.data.ageByStandard}</b>
        </div>
        {classData.data.hasOwnProperty("boys") ? (
          <>
            <div className='col-xl-6 col-lg-6 col-md-6 col-xs-12'>
              <p>Number of Boys</p>
            </div>
            <div className='col-xl-6 col-lg-6 col-md-6 col-xs-12'>
              <b>{classData.data.boys}</b>
            </div>
          </>
        ) : null}

        {classData.data.hasOwnProperty("girls") ? (
          <>
            <div className='col-xl-6 col-lg-6 col-md-6 col-xs-12'>
              <p>Number of Girls</p>
            </div>
            <div className='col-xl-6 col-lg-6 col-md-6 col-xs-12'>
              <b>{classData.data.girls}</b>
            </div>
          </>
        ) : null}
        <div className='col-xl-6 col-lg-6 col-md-6 col-xs-12'>
          <p>Average Annual Fee</p>
        </div>
        <div className='col-xl-6 col-lg-6 col-md-6 col-xs-12'>
          <b>₹ {classData.data.avgAnnualFee}</b>
        </div>
      </div>
      
    </div>
  );
};

export default ClassBoard;
