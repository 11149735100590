
const BrandsSVG = () => {

    return (
        <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.4453 2.03746C2.97575 2.49516 3.66374 1.76524 3.16906 1.26725L2.07433 0.24772C1.87067 0.0580517 1.55419 0.0580517 1.35057 0.24772L0.255839 1.26725C0.0422253 1.46624 0.0310308 1.79994 0.230872 2.01264C0.430749 2.2253 0.765949 2.23644 0.979598 2.03749L1.18272 1.84832V11.7573L0.979598 11.5681C0.450031 11.1099 -0.239227 11.8417 0.255839 12.3383L1.35057 13.3579C1.45238 13.4527 1.58241 13.5001 1.71247 13.5001C1.84253 13.5001 1.97252 13.4527 2.07437 13.3579L3.1691 12.3383C3.66367 11.8424 2.97589 11.1095 2.44534 11.5681L2.24221 11.7573V1.84825L2.4453 2.03746Z" fill="white"/>
            <path d="M15.4457 5.29559L12.9772 4.67905L11.6255 2.53241C11.5286 2.37857 11.3591 2.28516 11.1767 2.28516C10.9943 2.28516 10.8247 2.37857 10.7279 2.53241L9.37607 4.67905L6.90763 5.29559C6.73074 5.33974 6.5891 5.47144 6.53277 5.64412C6.47641 5.81681 6.51324 6.0062 6.63027 6.14545L8.26326 8.08868L8.08948 10.6163C8.07701 10.7975 8.15905 10.9723 8.30659 11.079C8.45413 11.1857 8.64645 11.2094 8.81568 11.1416L11.1767 10.196L13.5377 11.1416C13.7061 11.2091 13.8989 11.1861 14.0468 11.079C14.1943 10.9723 14.2764 10.7975 14.2639 10.6163L14.0901 8.08868L15.7231 6.14545C15.9741 5.85714 15.8191 5.38193 15.4457 5.29559ZM13.1408 7.57466C13.053 7.67907 13.0092 7.81323 13.0186 7.94904L13.1492 9.84916C13.0726 9.83134 11.1885 9.02714 11.1766 9.1002C11.1095 9.1002 11.0424 9.11292 10.9789 9.13831L9.20402 9.84916L9.33469 7.94904C9.34404 7.81323 9.30025 7.67907 9.2125 7.57466L7.98492 6.11385L9.84052 5.65038C9.97316 5.61727 10.0878 5.53437 10.1604 5.41898L11.1766 3.80531L12.1928 5.41898C12.2655 5.53433 12.3801 5.61727 12.5127 5.65038L14.3683 6.11385L13.1408 7.57466Z" fill="white"/>
            <path d="M4.71379 13.5H17.6387C17.9312 13.5 18.1684 13.2639 18.1684 12.9727V0.527345C18.1684 0.23611 17.9312 0 17.6387 0H4.71379C4.42125 0 4.18408 0.23611 4.18408 0.527345V12.9727C4.18408 13.2639 4.42125 13.5 4.71379 13.5ZM5.2435 1.05469H17.109V12.4453H5.2435V1.05469Z" fill="white"/>
            <path d="M18.0258 16.0225L17.0016 14.9327C16.5032 14.4401 15.7675 15.1254 16.228 15.6532L16.418 15.8554H5.77602L5.96604 15.6532C6.42639 15.1261 5.69123 14.4398 5.19238 14.9327L4.16828 16.0225C3.97776 16.2253 3.97776 16.5403 4.16828 16.7431L5.19238 17.8329C5.2967 17.9439 5.43782 18 5.57932 18C6.03434 18.0047 6.28355 17.4448 5.96604 17.1123L5.77602 16.9101H16.418L16.228 17.1123C16.0281 17.325 16.0393 17.6587 16.253 17.8577C16.4662 18.0564 16.8015 18.0459 17.0017 17.8329L18.0258 16.743C18.2163 16.5403 18.2163 16.2253 18.0258 16.0225Z" fill="white"/>
        </svg>
        
    )
}

export default BrandsSVG
