import {
    useState,
    useEffect,
    useContext,
} from "react"

import {
    Route,
    useHistory,
    useRouteMatch,
    Switch,
    useLocation,
} from "react-router-dom"
import { ReactInternetSpeedMeter } from 'react-internet-meter'

import PersonalDetails from "./personalDetails/personalDetails"

import SchoolGeneral from "./schoolGeneral/schoolGeneral"
import StudentSpecific from "./studentSpecific/studentSpecific"

import ClassroomInventories from "./classrooms/classroomInventories"
import ClassroomDetails from "./classrooms/classroomDetails"

import CommonAreaInventories from "./commonArea/commonAreaInventories"
import CommonAreaDetails from "./commonArea/commonAreaDetails"

import OtherAreaAndStaffInventories from "./otherRoomsAndStaff/otherRoomsAndStaffInventories"
import OtherAreaAndStaffDetails from "./otherRoomsAndStaff/otherRoomsAndStaffDetails"

import OutdoorInventories from "./outdoorInventories/outdoorInventories"
import OutdoorDetails from "./outdoorInventories/outdoorDetails"

import SchoolAmenities from "./schoolAmenities/schoolAmenities"
import SchoolSpecific from "./schoolSpecific/schoolSpecific"
import ClassroomSpecific from "./classroomSpecific/classroomSpecific"
import StudentActivities from "./activities/activities"
import StudentActivitiesDetails from "./activityDetails/activityDetails"

import CompareRevenue from "../components/compareRevenue"

import waveBootNew from "../../../../assets/images/wave-1.png"
import logoNew from "../../../../assets/images/logo-new.svg"

import SchoolOnboardProgress from "../components/schoolOnboardProgress"
import Loader from "../../../../components/addon/loader"

import { DataContextProvider } from "../../../../service/contextProvider"

import { PublicContextInitiator } from "../../../../publicSrc/apis/publicContextInitiator"

import AppContextObject from "../../../../common/context/common"

import config from "../../../../service/config"
import Summary from "./summary/summary"
import Faq from "./faq/faq"
import ConfirmDialog from "../../../../components/addon/confirmDialog/confirmDialog"

const Onboarding = (props) => {
    const {
        addSchoolFromBackend,
        setShowBackendSidemenu
    } = props
    const history = useHistory()
    const location = useLocation()
    const router = useRouteMatch()
    const [showFaq, setShowFaq] = useState(false)
    const [loading, setLoading] = useState(false)
    const [listOfInventories, setListOfInventories] = useState([])
    const [backendRedirectPath, setBackendRedirectPath] = useState("/backend/backend-details/school-list")
    const [backendFosDetails, setBackendFosDetails] = useState<any>({})
    const [isFromFosTable, setIsFromFosTable] = useState<any>(false)
    const context = useContext(AppContextObject)
    const [wifiSpeed, setwifiSpeed] = useState<any>(null)
    const [pingIntervalTime, setPingIntervalTime] = useState(1000)
    const [checkSpeed, setCheckSpeed] = useState(false)
    const [toggleNetworkDialog, setToggleNetworkDialog] = useState(false)
    const [overlay, setOverlay] = useState(false)
    const [slowInternetWarningGiven, setSlowInternetWarningGiven] = useState(false)
    const [networkOK,setNetworkOK] = useState(false)

    const [showCompareRevenuArray] = useState([
        "/schools/onboarding/classroom-inventories",
        "/schools/onboarding/classroom-inventories-details",
        "/schools/onboarding/common-area-inventories",
        "/schools/onboarding/common-area-details",
        "/schools/onboarding/other-area-and-staff-inventories",
        "/schools/onboarding/other-area-and-staff-details",
        "/schools/onboarding/outdoor-inventories",
        "/schools/onboarding/outdoor-details",
        "/schools/onboarding/events-and-occasions",
        "/schools/onboarding/events-and-occasions-details",

        "/backend/backend-details/schools/onboarding/classroom-inventories",
        "/backend/backend-details/schools/onboarding/classroom-inventories-details",
        "/backend/backend-details/schools/onboarding/common-area-inventories",
        "/backend/backend-details/schools/onboarding/common-area-details",
        "/backend/backend-details/schools/onboarding/other-area-and-staff-inventories",
        "/backend/backend-details/schools/onboarding/other-area-and-staff-details",
        "/backend/backend-details/schools/onboarding/outdoor-inventories",
        "/backend/backend-details/schools/onboarding/outdoor-details",
        "/backend/backend-details/schools/onboarding/events-and-occasions",
        "/backend/backend-details/schools/onboarding/events-and-occasions-details"
    ])
    const toggleFaq = () => {
        setShowFaq(!showFaq)
      }

    useEffect(() => {
        window.addEventListener("beforeunload", handleBeforeUnload)
        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload)
        }
    }, [])
    // useEffect(() => {
    //     switch (location.pathname){
    //         case addSchoolFromBackend ? "/backend/backend-details/schools/onboarding/general-details" : "/schools/onboarding/general-details":
    //             if(progress.personalDetails === false){
    //                 onProceed('/schools/onboarding/your-details')
    //             }
    //             break
    //         case addSchoolFromBackend ? "/backend/backend-details/schools/onboarding/student-specific" :"/schools/onboarding/student-specific":
    //             if(progress.schoolGeneral === false){
    //                 onProceed('/schools/onboarding/your-details')
    //             }
    //             break
    //         case addSchoolFromBackend ? "/backend/backend-details/schools/onboarding/classroom-inventories" : "/schools/onboarding/classroom-inventories":
    //             if(progress.studentSpecific === false){
    //                 onProceed('/schools/onboarding/your-details')
    //             }
    //             break
    //         case addSchoolFromBackend ? "/backend/backend-details/schools/onboarding/classroom-inventories-details" : "/schools/onboarding/classroom-inventories-details":
    //             if(progress.studentSpecific === false){
    //                 onProceed('/schools/onboarding/your-details')
    //             }
    //             break
    //         case addSchoolFromBackend ? "/backend/backend-details/schools/onboarding/common-area-inventories" : "/schools/onboarding/common-area-inventories":
    //             if(progress.classroom === false){
    //                 onProceed('/schools/onboarding/your-details')
    //             }
    //             break
    //         case addSchoolFromBackend ? "/backend/backend-details/schools/onboarding/common-area-details" : "/schools/onboarding/common-area-details":
    //             if(progress.classroom === false){
    //                 onProceed('/schools/onboarding/your-details')
    //             }
    //             break
    //         case addSchoolFromBackend ? "/backend/backend-details/schools/onboarding/other-area-and-staff-inventories" : "/schools/onboarding/other-area-and-staff-inventories":
    //             if(progress.commonArea === false){
    //                 onProceed('/schools/onboarding/your-details')
    //             }
    //             break
    //         case addSchoolFromBackend ? "/backend/backend-details/schools/onboarding/other-area-and-staff-details" : "/schools/onboarding/other-area-and-staff-details":
    //             if(progress.commonArea === false){
    //                 onProceed('/schools/onboarding/your-details')
    //             }
    //             break
    //         case addSchoolFromBackend ? "/backend/backend-details/schools/onboarding/outdoor-inventories" : "/schools/onboarding/outdoor-inventories":
    //             if(progress.otherRoomsAndStaff === false){
    //                 onProceed('/schools/onboarding/your-details')
    //             }
    //             break
    //         case addSchoolFromBackend ? "/backend/backend-details/schools/onboarding/outdoor-details" : "/schools/onboarding/outdoor-details":
    //             if(progress.otherRoomsAndStaff === false){
    //                 onProceed('/schools/onboarding/your-details')
    //             }
    //             break
    //         case addSchoolFromBackend ? "/backend/backend-details/schools/onboarding/events-and-occasions" : "/schools/onboarding/events-and-occasions":
    //             if(progress.outdoor === false){
    //                 onProceed('/schools/onboarding/your-details')
    //             }
    //             break
    //         case addSchoolFromBackend ? "/backend/backend-details/schools/onboarding/events-and-occasions-details" : "/schools/onboarding/events-and-occasions-details":
    //             if(progress.outdoor === false){
    //                 onProceed('/schools/onboarding/your-details')
    //             }
    //             break
    //         case addSchoolFromBackend ? "/backend/backend-details/schools/onboarding/onboarding-summary" : "/schools/onboarding/onboarding-summary":
    //             if(progress.eventsAndOccasions === false){
    //                 onProceed('/schools/onboarding/your-details')
    //             }
    //             break
    //     }
    // }, [location.pathname])

    useEffect(() => {
        if(addSchoolFromBackend){
            if(history.location.state){
                let data:any = history.location.state
                console.log("data---->>>",data)
                if(data && data.isFromFosTable){
                    setBackendRedirectPath("/backend/backend-details/fos-details")
                    setBackendFosDetails({id: data.fosUserId || "", email: data.fosUserEmail || "", phone: data.fosUserPhone })
                    setIsFromFosTable(data.isFromFosTable)
                }
            }
        }
    }, [])

    useEffect(() => {
      if(setShowBackendSidemenu){
        setShowBackendSidemenu(false)
      }
    }, [])
    

    useEffect(() => {
        if(sessionStorage.getItem("imageKeys")){
            let imagesArr = JSON.parse(sessionStorage.getItem("imageKeys"))
            if(imagesArr.length > 0){
                deleteMultipleUploadedImages(imagesArr)
            }else{
                sessionStorage.removeItem("imageKeys")
            }
        }

        if(sessionStorage.getItem("userIds")){
            let idArr = JSON.parse(sessionStorage.getItem("userIds"))
            if(idArr.length > 0){
                deleteUnsavedUser(idArr)
            }else{
                sessionStorage.removeItem("userIds")
            }
        }

        fetchInventories()
    }, [])
    useEffect(() => {
        if(localStorage.getItem("tooltipProgress")){
            let progress = JSON.parse(localStorage.getItem("tooltipProgress"))
            setTooltipProgress({...progress})
        }
    }, [])
    useEffect(()=>{
        if(location.pathname === "/schools/onboarding/your-details"){
            if(wifiSpeed !== null){
                if(Number(wifiSpeed) < context.utils.internetSpeedLimit && !slowInternetWarningGiven){
                    setToggleNetworkDialog(true)
                    setOverlay(true)
                }else{
                    setToggleNetworkDialog(false)
                    setOverlay(false)
                    setSlowInternetWarningGiven(true)
                }
            }
            
        }
    }, [wifiSpeed, location.pathname])
    console.log("networkOK>>>",networkOK);


    const testSpeed = () => {
        setCheckSpeed(true)
        console.log("test speed hitted");
        setTimeout(()=>{
            setPingIntervalTime(1000)
            setCheckSpeed(false)
            // setwifiSpeed(0)
            console.log("wifiSpeed>>>>>",wifiSpeed);
        }, 2000)
    }
    console.log("toggleNetworkDialog",toggleNetworkDialog);
    useEffect(()=>{
        if(checkSpeed == false && location.pathname === "/schools/onboarding/your-details" && !slowInternetWarningGiven){
            testSpeed()
        }
        
    },[])

    const fetchInventories = async () => {
        setLoading(true)
        const publicContextInitiator = new PublicContextInitiator(context)
        const data = await publicContextInitiator.getAllInventoriesFromMaster(context.validator, {})
        if (data?.response?.rows) {

            // map inventory placements default measurements to 0 if blank ''
            let inventoriesArr = data.response.rows.map(inventory => {
                inventory.placements = inventory.placements.map( placement => {
                    placement.data.length = placement.data.length != '' ? placement.data.length : 0;
                    placement.data.breadth = placement.data.breadth != '' ? placement.data.breadth : 0;
                    placement.data.height = placement.data.height != '' ? placement.data.height : 0;
                    placement.data.quantity = placement.data.quantity != '' ? placement.data.quantity : 0;

                    return placement;
                });
                return inventory;
            })

            setListOfInventories(inventoriesArr);
        }
        setLoading(false)
    }

    const deleteMultipleUploadedImages = (amenitydata) => {
        if(amenitydata && amenitydata.length > 0){
            let imagesKeyArray = [];
            amenitydata && amenitydata.map(img=>{
                imagesKeyArray.push({Key: img})
            })
    
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type' : 'application/json',
                },
                body: JSON.stringify({ keys: imagesKeyArray && imagesKeyArray })
            }
    
            fetch('/api/deleteMultipleUploadedFiles', requestOptions)
            .then((res)=>{
                sessionStorage.removeItem("imageKeys")
            })
            .catch(err=>console.log(err))    
        }
       
      }
    
    const deleteUnsavedUser = async(idArray) =>{
        for(let user of idArray){
            let deleteUserResp = context.apis.public.deleteUserById(context.validator,{_id: user}, {})
        }
        sessionStorage.removeItem("userIds")
    }
    const handleBeforeUnload = (e) => {
        if(context && context.inactive){
            window.onbeforeunload = null
            return true
        }
        e.preventDefault()
        let unsavedImages = data.unsavedImages
        let unsavedUser = data.unsavedUserIds
        sessionStorage.setItem("imageKeys",JSON.stringify(unsavedImages))
        sessionStorage.setItem("userIds",JSON.stringify(unsavedUser))
        window.addEventListener("unload", function(){
            // deleteUnsavedUser(unsavedUser)
            if(unsavedUser.length > 0){
                deleteUnsavedUser(unsavedUser)
            }else{
                sessionStorage.removeItem("userIds")
            }
            if(unsavedImages.length > 0){
                deleteMultipleUploadedImages(unsavedImages)
            }else{
                sessionStorage.removeItem("imageKeys")
            }
        })
        setTimeout(function() {
            setTimeout(function() {
                sessionStorage.removeItem("imageKeys")
                sessionStorage.removeItem("userIds")
            }, 1000);
        },1);
        const message = "Are you sure you want to reload the page? All provided data will be lost."
        e.returnValue = message
        return message
    }

    const [showSidemenu, setShowSidemenu] = useState(false)

    let [data, setData] = useState({
        personalDetails: {
            fullName: '',
            email: '',
            phone: '',
            countryCode: '+91',
            schoolName: '',
            registeredUnder: '',
            schoolPrincipalName: '',
            schoolPrincipalEmail: '',
            role: null,
            username: '',
            isUserCreated: false,
            fosEmail: "",
            fosPhone: "",
            fosUserId: ""
        },
        schoolGeneral: {
            educationLevel: [],
            schoolType: {
                schoolType: null,
                classWiseDetails: []
            },
            board: {
                board: null,
                classWiseDetails: []
            },
            language: {
                language: null,
                classWiseDetails: []
            },
            pincode: '',
            state: '',
            city: '',
            address: '',
            localArea: '',
            builtUpArea: config.limits.schoolArea.min,
            floors: 0,
            noOfTeachers: '',
        },
        schoolAmenities: {
            builtUpArea: config.limits.schoolArea.min,
            floors: 0,
            amenities: [],
            classrooms: [],
            classroomAmenities: [],
            commonAreaAmenities: [],
            otherRoomsAndStaffAmenities: [],
            outdoorAmenities: []
        },
        student: {
            life: [],
            activities: []
        },
        verifiedUsername: null,
        unsavedUserIds: [],
        unsavedImages: [],
        userId: null,
        algorithmData: {
            category: "",
            totalFee: 0,
            classrooms: 0,
            students: 0,
            teachers: 0,
            inventories: [],
            events: []
        },
        otherSchoolAlgorithmData: {
            category: "",
            totalFee: 0,
            classrooms: 0,
            students: 0,
            teachers: 0,
            inventories: [],
            events: []
        }
    })

    const [progress, setProgress] = useState({
        personalDetails: false,
        schoolGeneral: false,
        studentSpecific: false,
        classroom: false,
        commonArea: false,
        otherRoomsAndStaff: false,
        outdoor: false,
        eventsAndOccasions: false,
    })
    const [tooltipProgress, setTooltipProgress] = useState({
        studentSpecific: true,
        inventory: true,
        inventoryDetail1: true,
        inventoryDetail2: true,
        eventsAndOccasions: true,
        eventsAndOccasionsDetail1: true,
        eventsAndOccasionsDetail2: true
    })

    const [activeModule, setActiveModule] = useState({
        module: "",
        subModule: ""
    })

    const [netRevenue, setNetRevenue] = useState(0)
    const [otherSchoolsRevenue, setOtherSchoolsRevenue] = useState(0)
    const [prevNetRevenue, setPrevNetRevenue] = useState(0)

    const toggleSidemenu = () => {
        setShowSidemenu(!showSidemenu)
    }

    console.log("otherSchoolsRevenue--->>.",otherSchoolsRevenue)
    const myValue:any = {
        data,
        setData,
        progress,
        setProgress,
        toggleSidemenu,
        showSidemenu,
        activeModule,
        setActiveModule,
        listOfInventories,
        setTooltipProgress,
        tooltipProgress,
        netRevenue,
        setNetRevenue,
        prevNetRevenue,
        setPrevNetRevenue,
        otherSchoolsRevenue,
        setOtherSchoolsRevenue,
        addSchoolFromBackend,
        backendRedirectPath,
        backendFosDetails,
        isFromFosTable
    }

    console.log("addSchoolFromBackend---",addSchoolFromBackend)

    const onProceed = (route) => {
        if(addSchoolFromBackend){
            history.push(`/backend/backend-details${route}`)
        }else{
            history.push(route)
        }
    }
    const networkConfirmClose = (confirm) => {
        setToggleNetworkDialog(false)
        setOverlay(false)
        setSlowInternetWarningGiven(true)
        setwifiSpeed(null)
    }

    console.log("data.algorithmData", JSON.stringify(data.algorithmData));
    console.log("netRevenue, prevNetRevenue", netRevenue, prevNetRevenue);
    console.log("progress->>",progress);
    console.log("router.url--->>>",router.url)
    return (
        <>
        <div className={`ovelap ${overlay === true ? "active" : ""}`}></div>
            <DataContextProvider value={myValue}>
            {
                    checkSpeed
                    &&
                    <ReactInternetSpeedMeter  
                        txtSubHeading="Internet is too slow"
                        outputType="alert"
                        customClassName={"slowInternetMsg"}
                        txtMainHeading="Opps..."
                        pingInterval={pingIntervalTime} // milliseconds
                        thresholdUnit='megabyte' // "byte" , "kilobyte", "megabyte"
                        threshold={100}
                        imageUrl={context.utils.internetSpeedTestingImg}
                        downloadSize="2550420"  //bytes
                        callbackFunctionOnNetworkDown={(speed)=>console.log(`Internet speed is down ${speed}`)}
                        callbackFunctionOnNetworkTest={(speed)=>{ console.log(`Internet Speed is ${speed} MBps`); setwifiSpeed(speed)}}
                    />
                }
            <div className={`ovelap ${showSidemenu === true ? "active" : ""}`}></div>
            <div className="main-wapper">
                {
                    loading === true ?
                    <Loader />
                    :
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-3 col-lg-3 col-md-12 col-xs-12 pl-0">
                                <div className={`sliderbar onboarding-bar newMvp-sidebar ${showSidemenu === true ? "no_highlight" : ""}`}>
                                    <div className="row logo between-xs bottom-xs"><img src={logoNew} alt="logo" />
                                        <a href="javascript:void(0);" onClick={toggleFaq}>Help</a>
                                    </div>
                                    <SchoolOnboardProgress doNotShow={false} />
                                    <img src={waveBootNew} className="wavebot" alt="wave" />
                                </div>
                            </div>
                            <Switch>
                                <Route path={`${router.url}/your-details`}>
                                    <div className='col-xl-9 col-lg-9 col-md-12 col-xs-12'>
                                        <PersonalDetails
                                            isEdit={false}
                                            onProceed={onProceed}
                                            toggleSidemenu={toggleSidemenu}
                                            showSidemenu={showSidemenu}
                                        />
                                    </div>
                                </Route>

                                <Route path={`${router.url}/general-details`}>
                                    <SchoolGeneral
                                        isEdit={false}
                                        onProceed={onProceed}
                                        toggleSidemenu={toggleSidemenu}
                                        showSidemenu={showSidemenu}
                                        addSchoolFromBackend={addSchoolFromBackend}
                                    />
                                </Route>

                                <Route path={`${router.url}/student-specific`}>
                                    <StudentSpecific
                                        isEdit={false}
                                        onProceed={onProceed}
                                        toggleSidemenu={toggleSidemenu}
                                        showSidemenu={showSidemenu}
                                    />
                                </Route>

                                {/* Classroom */}
                                <Route path={`${router.url}/classroom-inventories`}>
                                    <ClassroomInventories
                                        isEdit={false}
                                        onProceed={onProceed}
                                        toggleSidemenu={toggleSidemenu}
                                        showSidemenu={showSidemenu}
                                    />
                                </Route>
                                <Route path={`${router.url}/classroom-inventories-details`}>
                                    <ClassroomDetails
                                        isEdit={false}
                                        onProceed={onProceed}
                                        toggleSidemenu={toggleSidemenu}
                                        showSidemenu={showSidemenu}
                                    />
                                </Route>

                                {/* Common Area */}
                                <Route path={`${router.url}/common-area-inventories`}>
                                    <CommonAreaInventories
                                        isEdit={false}
                                        onProceed={onProceed}
                                        toggleSidemenu={toggleSidemenu}
                                        showSidemenu={showSidemenu}
                                    />
                                </Route>
                                <Route path={`${router.url}/common-area-details`}>
                                    <CommonAreaDetails
                                        isEdit={false}
                                        onProceed={onProceed}
                                        toggleSidemenu={toggleSidemenu}
                                        showSidemenu={showSidemenu}
                                    />
                                </Route>

                                {/* Other Area and Staff */}
                                <Route path={`${router.url}/other-area-and-staff-inventories`}>
                                    <OtherAreaAndStaffInventories
                                        isEdit={false}
                                        onProceed={onProceed}
                                        toggleSidemenu={toggleSidemenu}
                                        showSidemenu={showSidemenu}
                                    />
                                </Route>
                                <Route path={`${router.url}/other-area-and-staff-details`}>
                                    <OtherAreaAndStaffDetails
                                        isEdit={false}
                                        onProceed={onProceed}
                                        toggleSidemenu={toggleSidemenu}
                                        showSidemenu={showSidemenu}
                                    />
                                </Route>

                                {/* Outdoor */}
                                <Route path={`${router.url}/outdoor-inventories`}>
                                    <OutdoorInventories
                                        isEdit={false}
                                        onProceed={onProceed}
                                        toggleSidemenu={toggleSidemenu}
                                        showSidemenu={showSidemenu}
                                    />
                                </Route>
                                <Route path={`${router.url}/outdoor-details`}>
                                    <OutdoorDetails
                                        isEdit={false}
                                        onProceed={onProceed}
                                        toggleSidemenu={toggleSidemenu}
                                        showSidemenu={showSidemenu}
                                    />
                                </Route>

                                <Route path={`${router.url}/events-and-occasions`}>
                                    <StudentActivities
                                        isEdit={false}
                                        onProceed={onProceed}
                                        toggleSidemenu={toggleSidemenu}
                                        showSidemenu={showSidemenu}
                                    />
                                </Route>

                                <Route path={`${router.url}/events-and-occasions-details`}>
                                    <StudentActivitiesDetails
                                        isEdit={false}
                                        onProceed={onProceed}
                                        toggleSidemenu={toggleSidemenu}
                                        showSidemenu={showSidemenu}
                                    />
                                </Route>

                                <Route path={`${router.url}/onboarding-summary`}>
                                    <Summary
                                        isEdit={false}
                                        toggleSidemenu={toggleSidemenu}
                                        showSidemenu={showSidemenu} 
                                        onProceed={onProceed}
                                        addSchoolFromBackend={addSchoolFromBackend}
                                        setShowBackendSidemenu={setShowBackendSidemenu}
                                     />
                                </Route>

                                <Route path={`/schools/onboarding/faq`}>
                                    <Faq />
                                </Route>
                            </Switch>

                            {
                                showCompareRevenuArray.find(el => el === location.pathname) &&
                                <CompareRevenue />
                            }
                        </div>
                    </div>
                }
            </div>
            <Faq showFaq={showFaq} onToggle={toggleFaq}></Faq>
            <ConfirmDialog
                confirmTitle="Poor Internet Connection Detected"
                componentName="We've detected issues with your internet connection that could affect school onboarding & data loss. Please check your internet connection"
                confirmText="Got it"
                denyText="Cancel" 
                togglePopup={toggleNetworkDialog}
                onModalClose={networkConfirmClose}
                onConfirmBtn={true}
            />
        </DataContextProvider>
        
    </>
  )
}

export default Onboarding
