import React,{useState} from "react";
import config from "../../../../../service/config";

const CampaignPaymentFilter = ({openPaymentFilter,setPaymentStatus,paymentStatus}) => {
    const filterPaymentStatus = (value) => {
        let array = [...paymentStatus]
        if (array.includes(value)) {
            array.splice(array.indexOf(value), 1)
        }
        else {
            array.push(value)
        }
        setPaymentStatus([...array])
    }



const clearAllFilter = () =>{
    setPaymentStatus([]);
}
    return (
        <div className={`filterDropDown textNone ${openPaymentFilter ? 'open' : ''}`}>
            <div className="filterDropHead">
                <h2>Filter <a href="javascript:void(0);" className="viewwLink" onClick={clearAllFilter}>Clear All</a></h2>
            </div>
            <div className="filterDropBody">
                <div className={`filterDropClose open`}>
                    {/* <h3><a href="javascript:void(0);" onClick={() => setSpanCategory(!spanCategory)} >{`Category`}  <img src={filterArrowSvg} /></a></h3> */}
                    <div className="filtercheckbox">
                        {config.paymentStatus.map((item, index) => (
                            <div className="checkbox fnone" key={index}>
                                <input type="checkbox" id={item.value} checked={paymentStatus.includes(item.value)} 
                                />
                                <label htmlFor={item.value} 
                                onClick={() => filterPaymentStatus(item.value)}
                                >{item.label}</label>
                            </div>
                        ))}
                    </div>
                </div>

            </div>
        </div>
        // <div className={`filterDropDown r-auto tableFilter ${openPaymentFilter ? 'open' : ''}`}>
        //     <div className="filterDropBody">
        //     <div className="filterDropHead">
        //             <h3>Filter <a href="javascript:void(0);" className="viewwLink" onClick={clearAllFilter}>Clear All</a></h3>
        //         </div>
        //         <div className="filterDropClose open">
        //             <div className="filtercheckbox">
        //                 <div className="radioBtn">
        //                     <input type="radio" id="test17" name="radio-group5"  checked={paymentStatus === 'paid'} onChange={() => filterByPaymentStatus('paid')} />
        //                     <label htmlFor="test17">Paid</label>
        //                 </div>
        //                 <div className="radioBtn">
        //                     <input type="radio" id="test18" name="radio-group5"  checked={paymentStatus === 'pending'} onChange={() => filterByPaymentStatus('pending')} />
        //                     <label htmlFor="test18">Pending</label>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </div>
    )
}
export default CampaignPaymentFilter;