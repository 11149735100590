import { Validator } from "./validator";

export class Utilities {
    goalSuccessThresholds = [2, 5, Infinity];
    categoryAmounts = {A: 1539000, B: 1054400, C: 630000};
    internetSpeedLimit = 0.5
    cityNameRegex = /^[a-zA-Z0-9\s\(\)\&\')\(-]+$/
    internetSpeedTestingImg = "https://i.postimg.cc/Fz2qbrw9/speed-testing-image-2-MB.webp"
    addIfFound(validator: Validator, newObject: any, key: string, oldObject: any, validate: RegExp | ((...args)=>boolean) = null, required: boolean = false) {
        try{
            if(oldObject && typeof oldObject[key] !== 'undefined') {
                if(typeof oldObject[key] === 'boolean' || typeof oldObject[key] !== 'undefined') {
                    let validationResult = true;
                    if(validate) {
                        if(validate instanceof RegExp) {
                            if(typeof oldObject[key] === 'boolean') {
                                validationResult = validate.test(oldObject[key].toString());
                            } else if(typeof oldObject[key] === 'number') {
                                validationResult = validate.test(new String(oldObject[key]).toString());
                            } else {
                                validationResult = validate.test(oldObject[key]);
                            }
                            // console.log('validate.test(oldObject[key])', oldObject[key], validate, validate.test(oldObject[key]));
                        } else if(typeof validate === 'function') {
                            validationResult = !!(validate(oldObject[key]));
                        } 
                    }
                    if(validationResult) {
                        newObject[key] = oldObject[key];
                        if(validator) {
                            validator.add(key, true);
                        }
                        return true;
                    } else {
                        if(validator) {
                            validator.add(key, false);
                        }
                        return false;
                    }
                } else {
                    validator.add(key, false);
                }
            }
            if(validator && required) {
                validator.add(key, false);
            } else {
                validator.add(key, true);
            }
            return false;
        } catch(e) {
            console.log("Some error occured in validation", e, oldObject, key);
            return false;
        }
    }
    addIfFoundInArray(validator: Validator, newObject: any, key: string, oldObject: any, validate: RegExp | ((...args)=>boolean) = null, required: boolean = false) {
        try {
            let validationResult = true;

            if(oldObject && oldObject[key] && oldObject[key] instanceof Array) {
                if(validate) {
                    for(let val of oldObject[key]){
                        if(validate instanceof RegExp) {
                            if(typeof oldObject[key] === 'boolean') {
                                validationResult = validate.test(oldObject[key].toString());
                            } else if(typeof oldObject[key] === 'number') {
                                validationResult = validate.test(new String(oldObject[key]).toString());
                            } else {
                                validationResult = validate.test(oldObject[key]);
                            }
                        } else if(typeof validate === 'function') {
                            validationResult = !!(validationResult && validate(val));
                        }
                    }
                }
                if(validationResult) {
                    if(!newObject[key]){
                        newObject[key]=[];
                    }
                    for(let val of oldObject[key]){
                        let newVal = val;
                        newObject[key].push(newVal);
                    }
                    if(validator) {
                        validator.add(key, true);
                    }
                    return true;
                } else {
                    if(validator) {
                        validator.add(key, false);
                    }
                    return false;
                }
            }
            if(validator && required) {
                validator.add(key, false);
            } else {
                validator.add(key, true);
            }
            return false;
        } catch(e) {
            console.log("Some error occured in array validation", e, oldObject, key);
            return false;
        }
    }
    upperCaseFirst(name) {
        return name ? name.replace(/^.{1}/, (firstChar)=>firstChar.toUpperCase()) : '';
    }
    lowerCaseFirst(name) {
        return name ? name.replace(/^.{1}/, (firstChar)=>firstChar.toLowerCase()) : '';
    }
    postFile(url,formData){
        return new Promise((resolve,reject)=>{
            let xhr = new XMLHttpRequest();
            xhr.open('POST', url);
            xhr.onreadystatechange = function () {
                if(xhr.readyState === XMLHttpRequest.DONE) {
                    if(xhr.status === 200)
                    {
                        resolve(JSON.parse(xhr.response));
                    }
                    else {
                        reject(xhr);
                    }
                }
            };
            xhr.onerror=reject;
            xhr.send(formData);
        });
    }
    camelCaseToNormal(str: string) {
        if(str) {
            str = this.upperCaseFirst(str);
            str.replace(/[A-Z]/g, (e)=>` ${e.toUpperCase()}`);
        }
        return str;
    }
}