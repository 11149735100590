
const SuccessDialog = (props) => {
    const {
        confirmTitle,
        componentName,
        confirmText,
        onModalClose,
        togglePopup
    } = props

    const title = confirmTitle ? confirmTitle : "Are you sure want to delete?"
    const name = componentName ? componentName : ""
    const confirmStr = confirmText ? confirmText : "Done"

    return (
        <div id="closePopup" className={`popup popup-center ${togglePopup === true ? "" : "hide"}`}>
            <div className="popup-header">
                <h3 className="htx2">{title}</h3>
                <span onClick={() => onModalClose()} className="close toggle" data-target="closePopup">close</span>
            </div>
            <div className="popup-body">
                <div className="facili-avai-in clearfix">
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <h3 className="htx2">{name}</h3>
                        </div>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-20 end-xs">
                            <div className="forminpt">
                                <input onClick={() => onModalClose()} name={confirmStr} value={confirmStr} className="btn btn-orange" type="button" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SuccessDialog