import React, { useState } from "react";
import { Link } from "react-router-dom";
import arrowUpDown from "../../../../assets/images/backendAdmin/arrow-up-down.svg";
import Pagination from "../../../../components/addon/pagination";
import moment from "moment";

const BrandTable = ({ allBrandsList, currentPage, totalPage, onPageChange, onNextClick, onPrevClick, isLoading, totalPageCount }) => {
    console.log("allBrandList ----", allBrandsList);
    return (
        <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
            <div className="inventory-tableTabs brandlistarrow">

                <div className="table-container table-inventory mt-10">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Sl no</th>
                                <th>Company Name</th>
                                <th>Brand Name</th>
                                <th>Category</th>
                                <th>Sales Representative</th>
                                <th>Document Status</th>
                                <th>Last visited</th>
                                <th>Last Activity</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!isLoading ?
                                <>
                                    {allBrandsList && allBrandsList.length > 0 ?
                                        <>
                                            {allBrandsList.map((brand, key) => {
                                               { console.log("ThisIsBrand --- ", brand)}
                                                return (
                                                    <tr key={key}>
                                                        {/* {brand.brandName}{`${allBrandsList.length===key && `,`}`} */}
                                                        <td>{(key + 1) + (currentPage - 1) * 10}</td>
                                                        <td className="companyName" style={{overflowWrap: "break-word"}}>{brand.brandName}</td>
                                                        <td className="brandName" style={{overflowWrap: "break-word"}}>
                                                            {console.log(brand.childBrands.length)}
                                                            {brand.childBrands.length <= 2 ?
                                                                <>
                                                                    {brand.childBrands && brand.childBrands.length > 0 ? brand.childBrands.map((item, key) => {

                                                                        return (
                                                                            <><span>{item.brandName}</span>{brand.childBrands.length > (key + 1) && ` , `}</>


                                                                        )
                                                                    })
                                                                        :
                                                                        '--'
                                                                    }
                                                                </>
                                                                :
                                                                <SpanBrandName
                                                                    brandNames={brand.childBrands}
                                                                />

                                                            }
                                                        </td>
                                                        <td>{brand.brandType}</td>
                                                        <td>{brand.wisrRepresentative ? brand?.wisrRepresentative?.fullName : "-"}</td>
                                                        <td><span className={`table-status ${(brand.documents.gst.status === 'accepted' && brand.documents.pan.status === 'accepted') ? 'greenbg' : (brand.documents.gst.status === 'rejected' || brand.documents.pan.status === 'rejected') ? 'redbg' : 'orangebg'}`}>{(brand.documents.gst.status === 'accepted' && brand.documents.pan.status === 'accepted') ? 'Completed' : (brand.documents.gst.status === 'rejected' || brand.documents.pan.status === 'rejected') ? 'Rejected' : 'Pending'}</span> </td>
                                                        <td>{brand.lastActiveAt ? moment(brand.lastActiveAt).fromNow() : 'NA'}</td>
                                                        <td className="arrowCenter">{brand.lastActivity ? brand.lastActivity : 'NA'}<Link className="arrowsetTwo"
                                                            to={{
                                                                pathname: "/backend/backend-details/brand-details",
                                                                state: { brandId: brand._id, brandCategoryId: brand.brandCategoryId }
                                                            }}><img className="updownarrow" src={arrowUpDown} /></Link></td>
                                                    </tr>
                                                )
                                            })
                                            }
                                        </>
                                        :
                                        <tr><td colSpan={8}>No Brand found</td></tr>
                                    }
                                </>
                                :
                                [...Array(10)].map((el, index) => {
                                    return (
                                        <tr>
                                            {
                                                [...Array(8)].map((el, index) => {
                                                    return (
                                                        <td key={index}>
                                                            <div className="content-loader-block"></div>
                                                        </td>
                                                    )
                                                })
                                            }
                                        </tr>
                                    )
                                })

                            }
                        </tbody>

                    </table>
                    {totalPageCount>10 && <Pagination
                        currentPage={currentPage}
                        totalPage={totalPage}
                        onPageChange={onPageChange}
                        onNextClick={onNextClick}
                        onPrevClick={onPrevClick}
                        loading={isLoading}
                    />}
                </div>
            </div>
        </div>
    )
}
export default BrandTable;

export const SpanBrandName = ({ brandNames }) => {
    const [showMore, setShowMore] = useState(false);
    return (
        <React.Fragment>
            {brandNames && brandNames.map((item, key) => {
                return (
                    showMore ?

                        <React.Fragment><span>{item.brandName}</span>{brandNames.length > (key + 1) && ` , `}</React.Fragment>
                        :
                        key <= 1 &&
                        <React.Fragment><span>{item.brandName}</span>{brandNames.length > (key + 2) ? ` , ` : key <= 1 && '...'}</React.Fragment>
                )
            })
            }
            {brandNames.length > 1 && <a href="javascript:void(0)" onClick={() => setShowMore(!showMore)}>{showMore ? "Less" : "More"}</a>}
        </React.Fragment>
    )
}