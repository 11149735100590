import React, { useState, useEffect, useRef, useContext } from "react";
import uploadDocument from "../../../../assets/images/img-upload.jpg";
import errorSVG from "../../../../assets/images/error.svg";
import deleteImg from "../../../../assets/images/delete.svg";

import SimpleReactValidator from "simple-react-validator";
import ImageModal from "../../../../components/addon/imageModal/imageModal";
import TextField from "../../../../components/formFields/textField/textField";
import Compress from "compress.js";

import toast from "react-hot-toast";
import { PublicContextInitiator } from "../../../apis/publicContextInitiator";
import AppContextObject from "../../../../common/context/common";
import TextArea from "../../../../components/formFields/textArea";
import editSvg from "../../../../assets/images/edit-btn.svg";
import SingleSelect from "../../../../components/formFields/singleSelect/singleSelect";

const WisrRepresentative = (props) => {

  console.log("thisWisrRepresentative", props)


  const {
    representativeList,
    representativeDetails,
    description,
    setDescription,
    setOverlay,
    refetch,
    setRefetch,
    profileData,
    isBackendUser,
    backendCompanyId,
    backendUserRole,
  } = props;
  const context = useContext(AppContextObject);
  const [formValidator] = useState(
    useRef(
      new SimpleReactValidator({
        element: (message, className) => (
          <p className="valError">
            <img src={errorSVG} />
            {message === "The representative field is required."
              ? "The sales team member field is required."
              : message}
          </p>
        ),
      })
    )
  );
  const [editingData, setEditingData] = useState(false);
  const [formData, setFormData] = useState({
    representative: representativeDetails?.wisrRepresentative?._id,
  });
  const [loading, setLoading] = useState(false);
  const [update, forceUpdate] = useState(false);
  const [editingWisrRepresentative, setEditingWisrRepresentative] =
    useState(false);
  const handleSave = async (confirm) => {
    if (confirm) {
      const result = formValidator.current.allValid();

      if (result === false) {
        formValidator.current.showMessages();
        forceUpdate(!update);
        return;
      } else {
        formValidator.current.hideMessages();
        forceUpdate(!update);
      }
      // let apiData = formData
      // console.log(apiData)
      setEditingWisrRepresentative(true);
      let updateResp = await context.apis[context.user.role].updateBrand(
        context.validator,
        {
          _id: isBackendUser
            ? backendCompanyId
            : context.user.parentBrand || context.user.brand,
        },
        { wisrRepresentative: formData.representative }
      );

      if (updateResp && updateResp.done) {
        toast.success("Brand edited successfully");
        setEditingWisrRepresentative(false);
        setRefetch(!refetch);
        setEditingData(false);
        setFormData({ representative: formData.representative });
      }
    } else {
      setEditingWisrRepresentative(false);
      setEditingData(false);
      setFormData({
        representative: representativeDetails?.wisrRepresentative?._id,
      });
      formValidator.current.hideMessages();
    }
  };
  const onFormDataChange = (data) => {
    console.log(data);
    setFormData({
      ...formData,
      representative: data.value,
    });
  };
  console.log(representativeList);
  return (
    <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
      <div className="row">
        <div className="col-xl-4 col-lg-4 col-md-12 col-xs-12">
          {/* {  (backendUserRole !== "backendSales" && backendUserRole !== "backendSalesPerson")  && */}

          <div className="profile-general">
            <h2 className="htx3 clearfix">
              WISR Representative
              {!editingData &&
                (backendUserRole === "backendAdmin" ||
                  backendUserRole === "backendSales" ||
                  backendUserRole === "backendTech") && (
                  <>
                    {/* {(backendUserRole === "backendSales" &&
                      backendUserRole === "backendSalesPerson") && ( */}

                    {(backendUserRole !== "backendSales" &&
                    backendUserRole !== "backendSalesPerson") && backendUserRole !== "backendFinance" ? (
                      <a
                        href="javascript:void(0)"
                        className="editbtn"
                        onClick={() => setEditingData(true)}
                      >
                        <img src={editSvg} /> Edit
                      </a>
                    ) : (
                      <a
                        href="javascript:void(0)"
                        className="editbtn"
                        onClick={() => setEditingData(true)}
                        style={{ pointerEvents: "none", color:"#ddd"}}
                      >
                        <img src={editSvg} style={{color:"#ddd !important"}}/> Edit
                      </a>
                    )}
                    {/* )}  */}
                  </>
                )}
            </h2>
          </div>

          {/* } */}
        </div>
        <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12 brand-general pl pr">
          {!editingData ? (
            <>
            <div className="row">
              <div className="col-md-6">
              <div className="inventory-overHead mt-0">
                <p>name</p>
                <div style={{display:"flex"}}>
                <h3>
                  <>
                  {console.log("representativeDetailsrepresentativeDetails", representativeDetails)}
                  </>
                  {representativeDetails?.wisrRepresentative &&
                  representativeDetails?.wisrRepresentative?._id
                    ? representativeDetails?.wisrRepresentative?.fullName
                    : "NA"}
                  {/* {representativeDetails?.wisrRepresentative &&
                  Object.keys(representativeDetails?.wisrRepresentative)
                    .length === 0
                    ? "NA"
                    : representativeDetails?.wisrRepresentative?.fullName} */}
                </h3>
                &nbsp;
                {/* <>
                {console.log("TESTTETSTSTSTS",representativeList[0].actualRole)}
                </> */}
                <p style={{marginTop:"7px"}}>
                {representativeDetails?.wisrRepresentative && representativeDetails?.wisrRepresentative.actualRole === "backendSalesAdmin" ? "Sales Admin" : representativeDetails?.wisrRepresentative && representativeDetails?.wisrRepresentative.actualRole === "backendSalesPerson" ? "Sales Person" : ""}  
                   {/* ({representativeDetails?.wisrRepresentative && representativeList[0].actualRole === "backendSalesAdmin" ? "Sales Admin" : representativeDetails?.wisrRepresentative && representativeList[0].actualRole === "backendSalesPerson" ? "Sales Person" : ""}) */}
                </p>
                </div>
              </div>
              </div>

             
              </div>
            </>
          ) : (
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12">
                <SingleSelect
                  formData={formData}
                  formKey="representative"
                  onDataChange={onFormDataChange}
                  placeholder="Select name"
                  label="Select sales team member"
                  options={
                    representativeList &&
                    representativeList.map((item) => {
                      // console.log("itemitem", item)
                    var actualRole  = item.actualRole === "backendSalesAdmin" ? "S.A" : item.actualRole === "backendSalesPerson" ? "S.P" : ""
                      return { value: item._id, label: item.fullName + " (" + actualRole + ") "};
                    })
                  }
                  formValidator={formValidator}
                  validation="required"
                />
              </div>


              {/* <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12">
                <SingleSelect
                  formData={formData}
                  formKey="representative"
                  onDataChange={onFormDataChange}
                  placeholder="Select name"
                  label="Select role"
                  options={
                    representativeList &&
                    representativeList.map((item) => {
                      return { value: item._id, label: item.role === "backendSales" ? "Backend Sales" :  ""};
                    })
                  }
                  formValidator={formValidator}
                  validation="required"
                />
              </div> */}

              <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 pr">
                {editingData && (
                  <div className="btn-general">
                    <a
                      style={{ cursor: "pointer" }}
                      className="btn btn-outline"
                      onClick={() => handleSave(false)}
                    >
                      Cancel
                    </a>
                    <a
                      style={{ cursor: "pointer" }}
                      className={`btn btn-orange ${
                        editingWisrRepresentative &&
                        "button--loading btn-disabled"
                      }`}
                      onClick={() => handleSave(true)}
                    >
                      Save Changes
                    </a>
                  </div>
                )}
              </div>
            </div>
          )}

          <div className="clearfix mt-30"></div>
        </div>
      </div>

      <div className="col-xl-10 col-lg-10 col-md-12 col-xs-12 mb-20"></div>
    </div>
  );
};

export default WisrRepresentative;
