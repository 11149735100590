import { useState, useContext, useEffect, useRef } from "react"

import { Link, useHistory } from "react-router-dom"
// import OnGoingCampaigns from "./onGoingCampaigns"
import Pagination from "../../../../../components/addon/pagination"
import arrowView from "../../../../../assets/images/arrow-view.svg"
import arrowViewRound from "../../../../../assets/images/arrow-view-round.svg"
import helpImg from "../../../../../assets/images/help.svg"
import tableFilter from "../../../../../assets/images/table-filter.svg"
import TopBar from "../../../schools/components/topBar"
import AppContextObject from "../../../../../common/context/common"
import { checkIfNumberOnly, compareObjs, formatDateShowMonth, objectDeepClone, titleCase, validateCampaignName } from "../../../../../service/helper"
import DataContext from "../../../../../service/backendContext"
import SimpleReactValidator from "simple-react-validator"
import errorSVG from "../../../../../assets/images/error.svg"
import TextField from "../../../../../components/formFields/textField/textField"
import SliderComponent from "../../../../../components/addon/rangeSlider"
import SingleSelect from "../../../../../components/formFields/singleSelect/singleSelect"
import config from "../../../../../service/config"
import TextArea from "../../../../../components/formFields/textArea"
import CustomDatePicker from "../../../../../components/formFields/datePicker"
import moment from 'moment'
import NumberFormat from "react-number-format"
import MultiSelect from "../../../../../components/formFields/multiSelect/multiSelect"
import ConfirmDialog from "../../../../../components/addon/confirmDialog/confirmDialog"
import Loader from '../../../../../components/addon/loader'
import { ReactInternetSpeedMeter } from 'react-internet-meter'
import CreatableSelect from "../../../../../components/formFields/creatableSelect/creatableSelect"
import BackendBackBtn from "../../components/backendBackBtn"
const { detect } = require('detect-browser');



const NonWisrCreateCampaign = ({ setShowProgressSideMenu }) => {
    const history = useHistory();
    const [formValidator] = useState(useRef(new SimpleReactValidator({
        element: (message, className) =>
            <span className="valError"><img src={errorSVG} />{message}</span>
    })
    ))
    const [update, forceUpdate] = useState(false)
    const context = useContext(AppContextObject)
    const parentData = useContext<any>(DataContext)
    const [formData, setFormData] = useState(parentData.nonWisrCampaignData.brandDetail)
    const [durationData, setDurationData] = useState<any>({ startDate: "", endDate: "" })
    const [brandCategoryList, setBrandCategoryList] = useState([])
    const targetAudienceOptions = [{ label: 3, value: 3 }, { label: 4, value: 4 }, { label: 5, value: 5 }, { label: 6, value: 6 }, { label: 7, value: 7 }, { label: 8, value: 8 }, { label: 9, value: 9 }, { label: 10, value: 10 }, { label: 11, value: 11 }, { label: 12, value: 12 }, { label: 13, value: 13 }, { label: 14, value: 14 }, { label: 15, value: 15 }, { label: 16, value: 16 }, { label: 17, value: 17 }, { label: 18, value: 18 }, { label: 19, value: 19 }, { label: 20, value: 20 },]
    const [showExitPopup, setShowExitPopup] = useState(false)
    const [budget, setBudget] = useState<any>(0)
    const [overlay, setOverlay] = useState(false)
    const [showBudgetError, setShowBudgetError] = useState(false)
    const [budgetError, setBudgetError] = useState(false)
    // const [showInventoryError, setShowInventoryError] = useState(false)
    // const [inventoryError, setInventoryError] = useState(false)
    const [showStartDateError, setShowStartDateError] = useState(false)
    const [startDateError, setStartDateError] = useState(false)
    const [showEndDateError, setShowEndDateError] = useState(false)
    const [endDateError, setEndDateError] = useState(false)
    const [showAlertForBrandCatChange, setShowAlertForBrandCatChange] = useState(false)
    const [tempBrandCategory, setTempBrandCategory] = useState({})
    const [subCategoryOption, setSubCategoryOption] = useState([])
    const [brandNameOption, setBrandNameOption] = useState([])
    const [isChrome, setIsChrome] = useState(true)
    const [allSubCategoryOption, setAllSubCategoryOption] = useState([])
    const [confirmDialogBtnLoading, setConfirmDialogBtnLoading] = useState(false)
    const [startDateRistrictionmMsg, setStartDateRistrictionmMsg] = useState(false)
    const [languageOptions] = useState(config.languages.filter(lang => lang.value.toLowerCase() !== "custom"))
    const [pingIntervalTime, setPingIntervalTime] = useState(1000)
    const [wifiSpeed, setwifiSpeed] = useState<any>(null)
    const [toggleNetworkDialog, setToggleNetworkDialog] = useState(false)
    const [checkSpeed, setCheckSpeed] = useState(false)
    const [stateCityData, setStateCityData] = useState([])
    const [stateList, setStateList] = useState([])
    const [cityList, setCityList] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [allBrands, setAllBrands] = useState(parentData?.nonWisrCampaignData?.brandDetail?.brandNameOptions ? [...parentData.nonWisrCampaignData.brandDetail.brandNameOptions] : [])
    const [allCampaigns, setAllCampaigns] = useState(parentData?.nonWisrCampaignData?.brandDetail?.campaignNameOptions ? [...parentData.nonWisrCampaignData.brandDetail.campaignNameOptions] : [])
    const [selectedCampaign, setSelectedCampaign] = useState({})
    const [showInventoryEventError, setShowInventoryEventError] = useState(false)
    const [inventoryEventError, setInventoryEventError] = useState(false)
    const [loadingCampaignFirstTime, setLoadingCampaignFirstTime] = useState(false)
    const [loadingBrandFirstTime, setLoadingBrandFirstTime] = useState(false)
    const [wait, setWait] = useState(false)

    useEffect(() => {
        if (brandCategoryList.length > 0) {
            getBrandDetail()
        }
    }, [brandCategoryList])
    useEffect(() => {
        getAllBrands()
    }, [])

    const getAllBrands = async () => {
        setWait(true);
        let resp = await context.apis.public.getAllBrands(
            context.validator, {}, { limit: 1000 }
        )
        console.log("getAllBrands", resp)
        if (resp && resp.done) {
            let brands = []
            resp.response.forEach(item => {
                item.childBrands.map(data => {
                    if(data._id && data.brandName && data.brandType){
                        brands.push({ _id: data._id, value: data._id, name: data.brandName, type: data.brandType, label: data.brandName })
                    }
                })
                if(item.childBrands.length == 0) {
                    brands.push({ _id: item._id, value: item._id, name: item.brandName, type: item.brandType, label: item.brandName })
                }
            })
            if(!loadingBrandFirstTime){
                if(allBrands.length === 0){
                    setAllBrands([...brands])
                    setFormData({...formData, brandNameOptions: [...brands]})
                }
                setLoadingBrandFirstTime(true)
            }
            else{
                setAllBrands([...brands])
                setFormData({...formData, brandNameOptions: [...brands]})
            }
        }
        setWait(false)
    }

    useEffect(() => {
        if (checkSpeed == false && (history.location.pathname === "/backend/backend-details/create-campaign/set-up-campaign" || history.location.pathname === "/backend/backend-details/create-trial-campaign/set-up-campaign") && !parentData.initialInternetSpeedTest) {
            testSpeed()
        }
    }, [])
    const testSpeed = () => {
        setCheckSpeed(true)
        setTimeout(() => {
            setPingIntervalTime(1000)
            setCheckSpeed(false)
            // setwifiSpeed(0)
        }, 2000)
    }
    const networkConfirmClose = (confirm) => {
        setToggleNetworkDialog(false)
        setOverlay(false)
        if (parentData.setInitialInternetSpeedTest) {
            parentData.setInitialInternetSpeedTest(true)
        }
        setwifiSpeed(null)
    }
    useEffect(() => {
        if (history.location.pathname === "/backend/backend-details/create-campaign/set-up-campaign") {
            if (wifiSpeed !== null) {
                if (Number(wifiSpeed) < context.utils.internetSpeedLimit && !parentData.initialInternetSpeedTest) {
                    setToggleNetworkDialog(true)
                    setOverlay(true)
                } else {
                    setToggleNetworkDialog(false)
                    setOverlay(false)
                    if (parentData.setInitialInternetSpeedTest) {
                        parentData.setInitialInternetSpeedTest(true)
                    }
                }
            }

        }
    }, [wifiSpeed, history.location.pathname])

    useEffect(() => {
        if (parentData?.nonWisrCampaignData?.brandDetail?.startDate !== "" && parentData?.nonWisrCampaignData?.brandDetail?.endDate !== "") {
            let startDate = new Date(parentData?.nonWisrCampaignData?.brandDetail?.startDate)
            let endDate = new Date(parentData?.nonWisrCampaignData?.brandDetail?.endDate)
            setDurationData({ startDate: startDate, endDate: endDate })
        }
        else if (parentData?.nonWisrCampaignData?.brandDetail?.startDate !== "") {
            let startDate = new Date(parentData?.nonWisrCampaignData?.brandDetail?.startDate)
            setDurationData({ ...durationData, startDate: startDate })
        }
        else if (parentData?.nonWisrCampaignData?.brandDetail?.endDate !== "") {
            let endDate = new Date(parentData?.nonWisrCampaignData?.brandDetail?.endDate)
            setDurationData({ ...durationData, endDate: endDate })
        }
    }, [])

    const getBrandDetail = async () => {
        // let obj = { _id: "", value: "", label: "" }, brandSubCategory = { value: "", label: "" }, brandName = ""
        // let resp = await context.apis.public.getBrandById(
        //     context.validator, { _id: context.user.parentBrand || context.user.brand }
        // )
        // console.log("abcd", resp)
        // if (resp && resp.done) {
        //     let brandType = resp.response.general.brandType
        //     let found = brandCategoryList.find(item => item._id === brandType)
        //     if (found) {
        //         obj = {
        //             _id: found._id,
        //             value: found.name,
        //             label: found.name
        //         }
        //     }
        //     if (context.user.role === "brandManager") {
        //         // let data = resp.response.subCategories.find(item => item.userId === context.user._id)
        //         let data = resp.response.subCategories.filter(el => el.userId === context.user._id)
        //         if (data.length > 0) {
        //             let found = data.reduce((a, b) => {
        //                 return new Date(a.updatedOn) > new Date(b.updatedOn) ? a : b;
        //             });
        //             if (found) {
        //                 console.log("found----12", found)
        //                 // myBrandData["subCategory"] = found.name
        //                 // myBrandData["brandName"] = found.brandName
        //                 // myBrandData["_id"] = found._id
        //                 brandSubCategory = { value: found._id, label: found.name }
        //                 brandName = found.brandId
        //                 setSubCategoryOption([{ value: found._id, label: found.name }])
        //                 setBrandNameOption([{ value: found.brandId, label: found.brandName }])
        //             }
        //         }
        //         console.log("abcd", data)
        //         if (data) {
        //             // setSubCategoryOption([{name: data.name, label: data.name}])
        //             // brandSubCategory = {value: data._id, label: data.name}
        //             // brandName = data.brandId
        //             // setSubCategoryOption([{value: data._id, label: data.name}])
        //             // setBrandNameOption([{value: data.brandId, label: data.brandName}])
        //         }
        //         console.log(brandSubCategory, brandName)
        //         setFormData({
        //             ...formData,
        //             brandCategory: obj,
        //             brandSubcategory: brandSubCategory,
        //             brandName: brandName,
        //             brandId: brandName
        //         })
        //     }
        //     else if (context.user.role === "brandCMO") {
        //         let categoryData = resp.response.subCategories.reduce((acc, item) => {
        //             if (item.brandName) {
        //                 let data = { ...item, value: item._id, label: item.name }
        //                 if (!acc.find(subCat => subCat._id === item._id || subCat.name === item.name))
        //                     acc.push(data)
        //             }
        //             return acc
        //         }, [])
        //         let allCategoryData = resp.response.subCategories.reduce((acc, item) => {
        //             if (item.brandName) {
        //                 let data = { ...item, value: item._id, label: item.name }
        //                 acc.push(data)
        //             }
        //             return acc
        //         }, [])
        //         console.log("categoryData", categoryData, allCategoryData, parentData.nonWisrCampaignData.brandDetail)
        //         setSubCategoryOption([...categoryData])
        //         setAllSubCategoryOption([...allCategoryData])
        //         if (parentData.nonWisrCampaignData.brandDetail.brandSubcategory.value !== "") {
        //             // setBrandNameOption([{value: data.brandId, label: data.brandName}])
        //             // let brandNameOption = resp.response.subCategories.find((item => item._id === parentData.nonWisrCampaignData.brandDetail.brandSubcategory.value))
        //             // setBrandNameOption([{value: brandNameOption.brandId, label: brandNameOption.brandName}])
        //             let brandNameOpt = allCategoryData.reduce((acc, current) => {
        //                 if ((parentData.nonWisrCampaignData.brandDetail.brandSubcategory && parentData.nonWisrCampaignData.brandDetail.brandSubcategory.label && current.name === parentData.nonWisrCampaignData.brandDetail.brandSubcategory.label)) {
        //                     let newOption = { value: current.brandId, label: current.brandName }
        //                     acc.push(newOption)
        //                 }
        //                 return acc
        //             }, [])
        //             console.log("brandNameOpt", brandNameOpt, allSubCategoryOption)
        //             setBrandNameOption([...brandNameOpt])

        //         }
        //         setFormData({
        //             ...formData,
        //             brandCategory: obj
        //         })
        //     }
        // }
        // let obj = {value: "", label: ""}, brandCategory = {value: "", label: ""}, brandName = ""
        // if(context.user.role === "brandCMO"){
        //     if(context.user.brandType){
        //         obj = brandCategoryList.find(item => item.name === context.user.brandType)
        //     }
        //     let subCatResp = await context.apis[context.user.role].getAllSubCategoriesByParentBrandId(
        //         context.validator, {parentBrandId: context.user.parentBrand || context.user.brand}
        //     )
        //     console.log("subCatResp", subCatResp)
        //     if(subCatResp && subCatResp.done){
        //         setSubCategoryOption([...subCatResp.response.rows])
        //     }
        // }
        // else if(context.user.role === "brandManager"){
        //     if(context.user.brandType){
        //         obj = brandCategoryList.find(item => item.name === context.user.brandType)
        //     }
        //     if(context.user.brandCategoryName){
        //         brandCategory = {value: context.user.brandCategoryName, label: context.user.brandCategoryName}
        //         setSubCategoryOption([brandCategory])
        //     }
        //     if(context.user.brandName){
        //         brandName = context.user.brandName
        //     }
        //     setFormData({
        //         ...formData,
        //         brandCategory: obj,
        //         brandSubcategory: brandCategory,
        //         brandName: brandName
        //     })
        // }
    }

    useEffect(() => {
        const isSummary = new URLSearchParams(history.location.search).get("isSummary")
        if (isSummary === "true") {
            console.log("object")
            parentData.setNonWisrCampaignProgress({
                brandDetails: false,
                inventory: false,
                school: false,
                event: false,
                campaignCreative: false,
                preview: false
            })
        }
        const browser = detect();
        if (browser) {
            // console.log("browser...", browser.name)
            setIsChrome(browser.name === "chrome")
        }
    }, [])
    useEffect(() => {
        setBudget(parentData.nonWisrCampaignData.campaignDetail.totalBudget)
    }, [])
    useEffect(() => {
        setShowProgressSideMenu(true)
        if (parentData.setNonWisrCampaignActiveModule) {
            parentData.setNonWisrCampaignActiveModule({
                ...parentData.nonWisrCampaignActiveModule,
                module: "brandDetails"
            })
        }
        getBrandCategoryList()
        getStateCityData()
        let cities = []
        formData.city.forEach(item => {
            let obj = { value: item, label: titleCase(item) }
            cities.push(obj)
        })
        setCityList([...cities])
        // const result = isValidId("615ae6c6954fe735a49a059f")
        // console.log("result", result)
        // if(parentData.nonWisrCampaignData.campaignDetail.totalBudget){
        //     setBudget(parentData.nonWisrCampaignData.campaignDetail.totalBudget)
        // }
    }, [])
    const getStateCityData = async () => {
        setIsLoading(true)
        const resp = await context.apis.public.getAllCitiesAndStatesByNonWisrSchool(
            context.validator, {}, {}
        )
        if (resp && resp.done) {
            console.log("getAllCitiesAndStatesBySchool", resp)
            let states = []
            resp.response.forEach(item => {
                states.push({ value: item._id, label: titleCase(item._id) })
            })
            setStateList([...states])
            setStateCityData([...resp.response])
        }
        setIsLoading(false)
    }
    const onStateChange = (data) => {
        let arr = []
        if (data.find(el => el.value === 'all')) {
            arr = stateList.map(el => el.value)
        }
        else {
            arr = data.map(el => el.value)
        }
        // setFormData({...formData, state: [...arr]})
        // if(arr.length === 0){
        //     setCityList([])
        // }
        let prevCity = [...formData.city], allCity = [], nextCity = []
        let cities = []
        arr.forEach(item => {
            let newCities = [...stateCityData.find(data => data._id === item).cities]
            allCity = [...allCity, ...newCities]
            // newCities.forEach(data => {
            //     let obj = {value: data, label: titleCase(data)}
            //     cities.push(obj)
            // })
        })
        //remove city of unselected state
        prevCity.forEach(item => {
            if (allCity.includes(item)) {
                nextCity.push(item)
            }
        })
        //populate cities of new selected state
        arr.forEach(item => {
            if (!formData.state.includes(item)) {
                nextCity = [...nextCity, ...stateCityData.find(data => data._id === item).cities]
            }
        })
        // setCityList([...cities])
        setFormData({ ...formData, state: [...arr], city: [...nextCity] })
    }
    const onCityChange = (data) => {
        let arr = []
        if (data.find(el => el.value === 'all')) {
            arr = cityList.map(el => el.value)
        }
        else {
            arr = data.map(el => el.value)
        }
        setFormData({ ...formData, city: [...arr] })
    }
    useEffect(() => {
        if (stateCityData && stateCityData.length && formData?.state && formData?.state.length) {
            let newState = []
            formData.state.forEach((item) => {
                let cities = stateCityData.find(data => data._id === item).cities
                let found = 0;
                cities.forEach(data => {
                    if (formData.city.includes(data)) {
                        found++
                    }
                })
                if (found) {
                    newState.push(item)
                }
            })
            setFormData({ ...formData, state: [...newState] })
        }
    }, [formData.city])
    useEffect(() => {
        if (stateCityData && stateCityData.length) {
            let cities = []
            formData.state.forEach(item => {
                let newCities = [...stateCityData.find(data => data._id === item).cities]
                newCities.forEach(data => {
                    let obj = { value: data, label: titleCase(data) }
                    cities.push(obj)
                })
            })
            setCityList([...cities])
        }
    }, [formData.state])
    const getBrandCategoryList = async () => {
        const resp = await context.apis.public.listBrandCategoriesMaster(
            context.validator, {}, {}
        )
        console.log(resp)
        if (resp && resp.done) {
            setBrandCategoryList([...resp.response.rows])
        }
    }
    const onFormDataChange = (event) => {
        setFormData({
            ...formData,
            [event.target.id]: event.target.value
        })
        if (event.target.id === "startDate") {
            setStartDateError(false)
        }
        if (event.target.id === "endDate") {
            setEndDateError(false)
        }
    }
    const onDurationChange = (event, id) => {
        console.log("data...", id, event, new Date(moment(event).format("yyyy-MM-DD")))
        if (isChrome) {
            setFormData({
                ...formData,
                [event.target.id]: event.target.value
            })
            setDurationData({ ...durationData, [event.target.id]: event.target.value })
            if (event.target.id === "startDate") {
                setStartDateError(false)
            }
            if (event.target.id === "endDate") {
                setEndDateError(false)
            }
        }
        else {
            setFormData({ ...formData, [id]: moment(event).format("yyyy-MM-DD") })
            setDurationData({ ...durationData, [id]: event })
            if (id === "startDate") {
                setStartDateError(false)
            }
            if (id === "endDate") {
                setEndDateError(false)
            }
        }


    }
    const onBudgetChange = (data) => {
        if (typeof data === "object") {
            if (checkIfNumberOnly(data.value) || data.value === "") {
                // setFormData({ ...formData,
                //     budget: Number(data.value)
                // })
                setBudget(data.value === "" ? data.value : Number(data.value))
                if (data.value === "" || Number(data.value) < 0) {
                    setBudgetError(true)
                }
                else {
                    setBudgetError(false)
                }
            }
        } else {
            // setFormData({ ...formData,
            //     budget: Number(data)
            // })
            setBudget(Number(data))
        }
    }
    const onMediumOfStudyChange = (data) => {
        let arr = []
        if (data.find(el => el.value === 'all')) {
            arr = languageOptions.map(el => el.value)
        }
        else {
            arr = data.map(el => el.value)
        }
        setFormData({ ...formData, studyMedium: [...arr] })
        // setFormData({...formData, studyMedium: data.value})
    }
    const onMinAgeChange = (data) => {
        setFormData({
            ...formData,
            targetAgeGroup: { ...formData.targetAgeGroup, min: data.value }
        })
    }
    const onMaxAgeChange = (data) => {
        setFormData({
            ...formData,
            targetAgeGroup: { ...formData.targetAgeGroup, max: data.value }
        })
    }
    const openExit = () => {
        // setOverlay(true)
        // setShowExitPopup(true)
    }
    const withValueCap = (inputObj) => {
        const { value } = inputObj;
        if (value <= 100000000 && value >= 0) return true;
        return false;
    };
    const handleInventoryClick = (e, type) => {
        if (e.target.checked) {
            setFormData({
                ...formData,
                [type === "inventory" ? "inventory" : "eventAndOccations"]: true
            })
            setInventoryEventError(false)
        }
        else {
            setFormData({
                ...formData,
                [type === "inventory" ? "inventory" : "eventAndOccations"]: false
            })
            if((type === "inventory" && formData.eventAndOccations === false) || (type === "event" && formData.inventory === false)){
                setInventoryEventError(false)
            }
        }
    }
    useEffect(() => {
        if (formData.startDate === "" && formData.endDate !== "" && isChrome === true) {
            setStartDateRistrictionmMsg(true)
        }
        else {
            setStartDateRistrictionmMsg(false)
        }
    }, [formData.startDate, formData.endDate])

    const onBrandCategoryChange = () => {

    }
    const onBrandSubCategoryChange = () => {

    }
    const onBrandNameChange = (data) => {
        let brandCategory = brandCategoryList.find(item => item._id === data.type)
        console.log("onbrandbnamevghj", data, brandCategoryList, brandCategory)
        setFormData({
            ...formData,
            brand: {...data},
            brandCategory: {value: brandCategory?._id, label: brandCategory?.name, _id: brandCategory?._id},
            campaign: {id: "", name: ""},
            startDate: "",
            endDate: "",
            objective: "",
            description: ""
        })
        setDurationData({startDate: "", endDate: ""})
    }
    useEffect(() => {
        if(formData.brand && formData.brand._id && formData.brand._id !== ""){
            getCampaignsByBrand()
        }
    }, [formData.brand])

    const getCampaignsByBrand = async() => {
        let resp = await context.apis.public.getCampaignsByBrandExternal(
            context.validator, {brandId: formData.brand._id, campaignType: formData.isTrial ? "trial" : "create"}, {}
        )
        console.log("getCampaignsByBrandExternal", resp)
        if(resp && resp.done){
            if(!loadingCampaignFirstTime){
                if(allCampaigns.length === 0){
                    setAllCampaigns([...resp.response.rows])
                }
                setLoadingCampaignFirstTime(true)
            }
            else{
                setAllCampaigns([...resp.response.rows])
            }
        }
    }

    const onSave = (e, proceed) => {
        e.preventDefault()
        const result = formValidator.current.allValid()
        if (result === false || budget < 0 || budget === "" || (formData.inventory === false && formData.eventAndOccations === false)) {
            formValidator.current.showMessages()
            setShowInventoryEventError(true)
            setShowBudgetError(true)
            if (budget < 0 || budget === "") {
                setBudgetError(true)
            }
            if(formData.inventory === false && formData.eventAndOccations === false){
                setInventoryEventError(true)
            }
            forceUpdate(true)
            return
        } else {
            formValidator.current.hideMessages()
            setShowBudgetError(false)
            setBudgetError(false)
            setShowInventoryEventError(false)
            setInventoryEventError(false)
            forceUpdate(!update)
        }
        let todaysDate = moment().clone().format("YYYY-MM-DD")
        // let isStartDateBeforeToday = moment(formData.startDate).isBefore(moment(todaysDate).add(11, "days"), "day")
        // let isEndDateBeforeToday = moment(formData.endDate).isBefore(moment(todaysDate).add(11, "days"), "day")
        let isEndDateBeforeStartDate = moment(formData.endDate).isBefore(formData.startDate, "day")
        // if (isEndDateBeforeToday || isEndDateBeforeStartDate) {
        //     setEndDateError(true)
        // }
        // else {
        //     setEndDateError(false)
        // }
        // if (isStartDateBeforeToday) {
        //     setStartDateError(true)
        // }
        // else {
        //     setStartDateError(false)
        // }
        if (isEndDateBeforeStartDate) {
            setShowStartDateError(true)
            setShowEndDateError(true)
            return
        }
        else {
            setShowStartDateError(false)
            setShowEndDateError(false)
        }
        // if(formData.startDate === "" || formData.endDate === ""){
        //     return
        // }
        console.log(formData)
        if (parentData?.nonWisrCampaignData?.school?.schools?.length > 0 && (!compareObjs(parentData.nonWisrCampaignData.brandDetail, formData) || parentData.nonWisrCampaignData.campaignDetail.totalBudget !== budget)) {
            if (parentData) {
                if (parentData.setNonWisrCampaignProgress) {
                    parentData.setNonWisrCampaignProgress({
                        ...parentData.nonWisrCampaignProgress,
                        brandDetails: true,
                    })
                }
                if (parentData.setNonWisrCampaignData) {
                    parentData.setNonWisrCampaignData({
                        ...parentData.nonWisrCampaignData,
                        brandDetail: { ...formData, name: formData.name.trim() },
                        campaignDetail: { ...parentData.nonWisrCampaignData.campaignDetail, totalBudget: budget },
                        school: { schools: [], locations: [] }
                    })
                }
            }
        }
        else {
            if (parentData) {
                if (parentData.setNonWisrCampaignProgress) {
                    parentData.setNonWisrCampaignProgress({
                        ...parentData.nonWisrCampaignProgress,
                        brandDetails: true,
                    })
                }
                if (parentData.setNonWisrCampaignData) {
                    parentData.setNonWisrCampaignData({
                        ...parentData.nonWisrCampaignData,
                        brandDetail: { ...formData },
                        campaignDetail: { ...parentData.nonWisrCampaignData.campaignDetail, totalBudget: budget }
                    })
                }
            }
        }
        if (proceed) {
            if (formData.inventory) {
                if (formData.isTrial) {
                    history.push('/backend/backend-details/nonWisr-create-trial-campaign/nonWisr-campaign-inventory')
                }
                else {
                    history.push('/backend/backend-details/nonWisr-create-campaign/nonWisr-campaign-inventory')
                }
            }
            else if(formData.eventAndOccations){
                if (formData.isTrial) {
                    history.push('/backend/backend-details/nonWisr-create-trial-campaign/nonWisr-campaign-event')
                }
                else {
                    history.push('/backend/backend-details/nonWisr-create-campaign/nonWisr-campaign-event')
                }
            }
            else {
                if (formData.isTrial) {
                    history.push('/backend/backend-details/nonWisr-create-trial-campaign/nonWisr-campaign-school')
                }
                else {
                    history.push('/backend/backend-details/nonWisr-create-campaign/nonWisr-campaign-school')
                }
            }
        }
    }
    formValidator.current.rules['campaign_name'] = {
        message: 'Please enter valid name.',
        rule: (val) => {
            let returnValue = validateCampaignName(val)

            return returnValue
        },
    }
    formValidator.current.rules['objective'] = {
        message: 'Please enter valid objective.',
        rule: (val) => {
            let returnValue = true
            if (!val || val.trim() === "") {
                returnValue = false
            }
            const regex2 = /[a-zA-Z]/;
            if (!regex2.test(val)) {
                returnValue = false
            }
            return returnValue
        },
    }
    const addNewOption = (value) => {
        let newCampaign = objectDeepClone(allCampaigns);
        newCampaign.push({
            name: value.trim(),
            value: value.trim()
        })
        setFormData({
            ...formData,
            campaign: {name: value.trim(), _id: ""},
            startDate: "",
            endDate: "",
            objective: "",
            description: "",
            campaignNameOptions: [...newCampaign],
        })
        setDurationData({startDate: "", endDate: ""})
        setAllCampaigns([...newCampaign])
    }
    const onCampaignChange = (data) => {
        console.log("datafist", data)
        if(data){
            setFormData({
                ...formData,
                campaign: {name: data.value.trim(), _id: data._id},
                startDate: data.startDate,
                endDate: data.endDate,
                objective: data.objective,
                description: data.description,
                campaignNameOptions: [...allCampaigns],
            })
            let startDate = new Date(data.startDate)
            let endDate = new Date(data.endDate)
            setDurationData({startDate, endDate})
        }
        else{
            setFormData({
                ...formData,
                campaign: {name: "", _id: ""},
                startDate: "",
                endDate: "",
                objective: "",
                description: ""
            })
            setDurationData({startDate: "", endDate: ""})
        }
    }
    return (
        <>
            <div className={`ovelap ${overlay === true && "active"}`}></div>
            {
                checkSpeed
                &&
                <ReactInternetSpeedMeter
                    txtSubHeading="Internet is too slow"
                    outputType="alert"
                    customClassName={"slowInternetMsg"}
                    txtMainHeading="Opps..."
                    pingInterval={pingIntervalTime} // milliseconds
                    thresholdUnit='megabyte' // "byte" , "kilobyte", "megabyte"
                    threshold={100}
                    imageUrl={context.utils.internetSpeedTestingImg}
                    downloadSize="2550420"  //bytes
                    callbackFunctionOnNetworkDown={(speed) => console.log(`Internet speed is down ${speed}`)}
                    callbackFunctionOnNetworkTest={(speed) => { console.log(`Internet Speed is ${speed} MBps`); setwifiSpeed(speed) }}
                />
            }
            {/* <TopBar isDashboard={true} /> */}
            {isLoading ?
                <Loader />
                :
                <div className="col-xl-9 col-lg-12 col-md-12 col-xs-12">
                    <div className="rightPart noBack">
                        <div className="row">
                            <div className="col-xs-12 col-lg-12 col-md-12 col-xs-12">
                                <BackendBackBtn
                                />
                            </div>
                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-20"><h2 className="htx5 clearfix mt-10 mb-20">Create a Campaign</h2></div>
                            <div className="col-xl-7 col-lg-7 col-md-12 col-xs-12 pl pr">
                                <div className="row">
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                     
                                       {
                                        wait ?
                                       
                                        
                                        <SingleSelect
                                            formData={formData.brand}
                                            formKey="_id"
                                            onDataChange={onBrandNameChange}
                                            label="Your Brand Name"
                                            placeholder="Please wait..."
                                            isRequired={false}
                                            options={allBrands && allBrands.length !== 0 && allBrands}
                                            formValidator={formValidator}
                                            validation="required"
                                            showInfoIcon={true}
                                            infoText="Select your brand name here."
                                            isDisable = {true}
                                        />

:
<SingleSelect
                                            formData={formData.brand}
                                            formKey="_id"
                                            onDataChange={onBrandNameChange}
                                            label="Your Brand Name"
                                            placeholder="Select Brand Name"
                                            isRequired={false}
                                            options={allBrands && allBrands.length !== 0 && allBrands}
                                            formValidator={formValidator}
                                            validation="required"
                                            showInfoIcon={true}
                                            infoText="Select your brand name here."
                                        />


                                       }
                                    </div>
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                        <SingleSelect
                                            formData={formData.brandCategory}
                                            formKey='_id'
                                            onDataChange={onBrandCategoryChange}
                                            label='Your Brand Category'
                                            placeholder='Select a Category'
                                            isRequired={false}
                                            options={brandCategoryList && brandCategoryList.length !== 0 && brandCategoryList.map((item) => { return { _id: item._id, value: item._id, label: item.name } })}
                                            formValidator={formValidator}
                                            validation='required'
                                            showInfoIcon={true}
                                            infoText="Select the category for your brand from the dropdown."
                                            isDisable={true}
                                        />
                                    </div>
                                    {/* <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                        <TextField
                                            formData={formData}
                                            formKey='name'
                                            onDataChange={onFormDataChange}
                                            label='Name'
                                            placeholder='Enter Campaign Name'
                                            isRequired={false}
                                            formValidator={formValidator}
                                            maxLength={50}
                                            validation='required|campaign_name'
                                        />
                                    </div> */}
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                    <CreatableSelect
                                        formData={formData.campaign}
                                        formKey='name'
                                        onDataChange={onCampaignChange}
                                        onCreate={addNewOption}
                                        label='Name'
                                        placeholder='Enter Campaign Name'
                                        isRequired={false}
                                        options={allCampaigns && allCampaigns.length > 0 ? allCampaigns.map(item => ({value: item.name, label: item.name, _id: item._id, startDate: item.startDate, endDate: item.endDate, description: item.description, objective: item.objective})) : []}
                                        formValidator={formValidator}
                                        validation="required|campaign_name"
                                        // validation=""
                                        // helpText="Insert or create a subcategory for your brand from the dropdown."
                                        // emptyOptionsMessage="Create a Sub Category"
                                        maxLength={50}
                                        validateOptionRule="campaign_name"
                                    />
                                    </div>

                                    <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                        <div className="forminpt mb-0 tooltip-relative taegetMobile">
                                            <label>Duration <a style={{ cursor: "pointer" }} title="Enter the start date and end date for your campaign." className="tooltip"><img src={helpImg} /></a></label>

                                            <div className="row">
                                                <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12 pl mm-b0">
                                                    {/* <div className="forminpt">
                                                <input id="startDate" className="" placeholder="select a date" type="date" value={formData.startDate} onChange={onFormDataChange} />
                                            </div> */}
                                                    <div className="forminpt">
                                                        {!isChrome ?
                                                            <CustomDatePicker
                                                                formData={durationData}
                                                                formKey='startDate'
                                                                onDataChange={onDurationChange}
                                                                isRequired={false}
                                                                formValidator={formValidator}
                                                                validation='required'
                                                                // min={moment().add(11, "days").clone().format("YYYY-MM-DD")}
                                                                max={durationData.endDate && moment(durationData.endDate).format("YYYY-MM-DD")}
                                                                isChrome={isChrome}
                                                            />
                                                            :
                                                            <CustomDatePicker
                                                                formData={formData}
                                                                formKey='startDate'
                                                                onDataChange={onFormDataChange}
                                                                isRequired={false}
                                                                formValidator={formValidator}
                                                                validation='required'
                                                                // min={moment().add(11, "days").clone().format("YYYY-MM-DD")}
                                                                max={formData.endDate && formData.endDate}
                                                            />
                                                            // <input
                                                            //     id="startDate"
                                                            //     type="date"
                                                            //     value={formData.startDate}
                                                            //     min={moment().add(11, "days").clone().format("YYYY-MM-DD")}
                                                            //     max={formData.endDate && formData.endDate}
                                                            //     onChange={onFormDataChange}
                                                            // />
                                                        }

                                                        {startDateError && showStartDateError && <span className="valError"><img src={errorSVG} />Please select valid start date.</span>}
                                                        {/* {startDateRistrictionmMsg && <span className="valError"><img src={errorSVG} />{`You cannot select date past ${moment(formData.endDate).clone().format("DD-MM-YYYY")}`}</span>} */}
                                                    </div>
                                                </div>
                                                <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12 pr">
                                                    {/* <div className="forminpt">
                                                <input id="endDate" className="" placeholder="select a date" type="date" value={formData.endDate} onChange={onFormDataChange} />
                                            </div> */}
                                                    <div className="forminpt">
                                                        {!isChrome ?
                                                            <CustomDatePicker
                                                                formData={durationData}
                                                                formKey='endDate'
                                                                onDataChange={onDurationChange}
                                                                isRequired={false}
                                                                formValidator={formValidator}
                                                                validation='required'
                                                                min={(durationData.startDate && moment(durationData.startDate).clone().format("YYYY-MM-DD"))}
                                                                isChrome={isChrome}
                                                            />
                                                            :
                                                            <CustomDatePicker
                                                                formData={formData}
                                                                formKey='endDate'
                                                                onDataChange={onFormDataChange}
                                                                isRequired={false}
                                                                formValidator={formValidator}
                                                                validation='required'
                                                                min={(formData.startDate && moment(formData.startDate).clone().format("YYYY-MM-DD"))}
                                                            />
                                                            // <input
                                                            //     id="endDate"
                                                            //     type="date"
                                                            //     value={formData.endDate}
                                                            //     min={(formData.startDate && moment(formData.startDate).clone().format("YYYY-MM-DD")) || moment().add(11, "days").clone().format("YYYY-MM-DD")}
                                                            //     onChange={onFormDataChange}
                                                            // />
                                                        }
                                                        {endDateError && showEndDateError && <span className="valError"><img src={errorSVG} />Please select valid end date.</span>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                        {/* <div className="forminpt">
                                        <label>Description</label>
                                        <textarea id="description" value={formData.description} onChange={onFormDataChange} placeholder="Write a few sentences about the campaign"></textarea>
                                    </div> */}
                                        <TextArea
                                            formData={formData}
                                            formKey='description'
                                            onDataChange={onFormDataChange}
                                            label='Description'
                                            placeholder='Write a few sentences about the campaign'
                                            isRequired={false}
                                            formValidator={formValidator}
                                            maxLength={500}
                                            validation='required'
                                        />
                                    </div>
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                        <div className="forminpt">
                                            {/* <label>Objective</label>
                                        <input id="objective" className="" placeholder="Reach, Recognition, Engagement etc" value={formData.endDate} onChange={onFormDataChange} /> */}
                                            <TextField
                                                formData={formData}
                                                formKey='objective'
                                                onDataChange={onFormDataChange}
                                                label='Objective'
                                                placeholder='Reach, Recognition, Engagement etc'
                                                isRequired={false}
                                                formValidator={formValidator}
                                                validation='required|objective'
                                            // maxLength={50}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                        <div className="forminpt checkboxinput tooltip-relative">
                                            <label>WISR Offering  <a style={{ cursor: "pointer" }} title="For Solution offerings like CSR, Sampling & Custom requirements, our WISR team will assist you set up campaigns offline." className="tooltip"><img src={helpImg} /></a> <small>Choose one or more</small></label>
                                            <div className="checkboxinputList">
                                                <div className="checkbox">
                                                    <input type="checkbox" id="inventory" checked={formData.inventory} onClick={(e) => handleInventoryClick(e, "inventory")} />
                                                    <label htmlFor="inventory">Inventory</label>
                                                </div>
                                                <div className="checkbox">
                                                    <input type="checkbox" id="eventAndOccations" checked={formData.eventAndOccations} onClick={(e) => handleInventoryClick(e, "event")} />
                                                    <label htmlFor="eventAndOccations">Event and Occasions</label>
                                                </div>
                                                <div className="checkbox">
                                                    <input type="checkbox" id="csr" checked={formData.csr} onClick={(e: any) => setFormData({ ...formData, csr: e.target.checked })} />
                                                    <label htmlFor="csr">CSR</label>
                                                </div>
                                                <div className="checkbox">
                                                    <input type="checkbox" id="sampling" checked={formData.sampling} onClick={(e: any) => setFormData({ ...formData, sampling: e.target.checked })} />
                                                    <label htmlFor="sampling">Sampling</label>
                                                </div>
                                                <div className="checkbox">
                                                    <input type="checkbox" id="custom" checked={formData.custom} onClick={(e: any) => setFormData({ ...formData, custom: e.target.checked })} />
                                                    <label htmlFor="custom">Custom</label>
                                                </div>
                                            </div>
                                            {showInventoryEventError && inventoryEventError &&
                                                <span className="valError"><img src={errorSVG} />Please select valid input .</span>
                                            }
                                        </div>
                                    </div>
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                        <div className="tol-built campaignBudget tooltip-relative mt-0 mb-30">
                                            {/* <p>Budget</p>
                                        <div className="tol-built-in"> <span><strong></strong></span> </div>
                                        <span>
                                            <b>₹</b>
                                            <input name="" value="200,000" type="text" />
                                        </span> */}
                                            <p>Budget <a style={{ cursor: "pointer" }} title="Select the budget of your campaign by dragging the slider or by entering the amount." className="tooltip"><img src={helpImg} /></a></p>
                                            <SliderComponent
                                                min={0}
                                                max={100000000}
                                                value={budget}
                                                onSliderValueChange={onBudgetChange}
                                                changeError={setBudgetError}
                                            />
                                            <span>
                                                <b>₹</b>
                                                <NumberFormat
                                                    type="text"
                                                    thousandSeparator={true}
                                                    thousandsGroupStyle="lakh"
                                                    value={budget}
                                                    onValueChange={onBudgetChange}
                                                    isAllowed={withValueCap}
                                                />
                                            </span>
                                            {budgetError && showBudgetError &&
                                                <span className="valError"><img src={errorSVG} />
                                                    {budget === "" ? "Budget field is required." : budget <= 0 && "Budget can not be less than 0."}
                                                </span>
                                            }
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12">
                                        <div className="forminpt">
                                            <MultiSelect
                                                formData={formData}
                                                formKey='state'
                                                onDataChange={onStateChange}
                                                label='State'
                                                placeholder='Select State'
                                                isRequired={false}
                                                options={stateList.length > 0 && stateList.length > formData.state.length ? stateList.concat([{ label: "All of the above", value: "all" }]) : stateList}
                                                formValidator={formValidator}
                                                validation="required|array|min:1"
                                                showInfoIcon={true}
                                                infoText="Select the preferred state for your campaign. This will give you a list of all the schools of that state."
                                            />
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12">
                                        <div className="forminpt">
                                            <MultiSelect
                                                formData={formData}
                                                formKey='city'
                                                onDataChange={onCityChange}
                                                label='City'
                                                placeholder='Select city'
                                                isRequired={false}
                                                options={cityList.length > 0 && cityList.length > formData.city.length ? cityList.concat([{ label: "All of the above", value: "all" }]) : cityList}
                                                formValidator={formValidator}
                                                validation="required|array|min:1"
                                                showInfoIcon={true}
                                                infoText="Select the preferred city for your campaign. This will give you a list of all the schools of that city."
                                            />
                                        </div>
                                    </div>
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                        <div className="forminpt checkboxinput">
                                            <label>Target  Audience</label>
                                            <div className="checkboxinputList">
                                                <div className="radioBtn">
                                                    <input type="radio" id="boys" name="radio-group" checked={formData.targetGender === "boys"} onChange={(e: any) => setFormData({ ...formData, targetGender: "boys" })} />
                                                    <label htmlFor="boys">Boys</label>
                                                </div>
                                                <div className="radioBtn">
                                                    <input type="radio" id="girls" name="radio-group" checked={formData.targetGender === "girls"} onChange={(e: any) => setFormData({ ...formData, targetGender: "girls" })} />
                                                    <label htmlFor="girls">Girls</label>
                                                </div>
                                                <div className="radioBtn">
                                                    <input type="radio" id="both" name="radio-group" checked={formData.targetGender === "both"} onChange={(e: any) => setFormData({ ...formData, targetGender: "both" })} />
                                                    <label htmlFor="both">Both</label>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12">
                                        <div className="forminpt taegetMobile"><label>Target Audience Age</label>
                                            <div className="row">
                                                <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12 pl">
                                                    {/* <div className="forminpt">
                                                <select onChange={(e) => setFormData({...formData, targetAgeGroup: {...formData.targetAgeGroup, min: Number(e.target.value)}})} value={formData.targetAgeGroup.min}>
                                                    {targetAudienceOptions.map((age, index) => (
                                                        <option key={index}>{age}</option>
                                                    ))}
                                                </select>
                                            </div> */}
                                                    <SingleSelect
                                                        formData={formData.targetAgeGroup}
                                                        formKey='min'
                                                        onDataChange={onMinAgeChange}
                                                        placeholder='Select age'
                                                        isRequired={false}
                                                        options={targetAudienceOptions.filter(age => age.value < formData?.targetAgeGroup.max)}
                                                        formValidator={formValidator}
                                                        validation='required'
                                                    />
                                                </div>
                                                <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12 pr">
                                                    {/* <div className="forminpt">
                                                <select onChange={(e) => setFormData({...formData, targetAgeGroup: {...formData.targetAgeGroup, max: Number(e.target.value)}})} value={formData.targetAgeGroup.max}>
                                                    {targetAudienceOptions.filter(age => formData?.targetAgeGroup.min < age).map((age, index) => (
                                                        <option key={index}>{age}</option>
                                                    ))}
                                                </select>
                                            </div> */}
                                                    <SingleSelect
                                                        formData={formData.targetAgeGroup}
                                                        formKey='max'
                                                        onDataChange={onMaxAgeChange}
                                                        placeholder='Select age'
                                                        isRequired={false}
                                                        options={targetAudienceOptions.filter(age => age.value > formData?.targetAgeGroup.min)}
                                                        formValidator={formValidator}
                                                        validation='required'
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12">
                                        <div className="forminpt">
                                            {/* <label>Medium Of Study</label>
                                        <select>
                                            <option>8</option>
                                        </select> */}
                                            <MultiSelect
                                                formData={formData}
                                                formKey='studyMedium'
                                                onDataChange={onMediumOfStudyChange}
                                                label='Medium Of Study'
                                                placeholder='Select Medium of study'
                                                isRequired={false}
                                                options={languageOptions.length > formData.studyMedium.length ? languageOptions.concat([{ label: "All of the above", value: "all" }]) : languageOptions}
                                                formValidator={formValidator}
                                                validation="required|array|min:1"
                                                showInfoIcon={true}
                                                infoText="Select the preferred medium of study for your campaign. This will give you a list of all the schools under a particular medium. For example, if you select Hindi as the medium then a list of all Hindi medium schools will appear."
                                            />
                                        </div>
                                    </div>
                                    {/* <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12">
                                    <div className="forminpt">
                                        <label>Your Brand Category</label>
                                        <select>
                                            <option>FMCG</option>
                                        </select>
                                            <SingleSelect
                                                formData={formData.brandCategory}
                                                formKey='_id'
                                                onDataChange={onBrandCategoryChange}
                                                label='Your Brand Category'
                                                placeholder='Select a Category'
                                                isRequired={false}
                                                options={brandCategoryList && brandCategoryList.length !== 0 && brandCategoryList.map((item) => {return {_id: item._id, value: item._id, label: item.name}})}
                                                formValidator={formValidator}
                                                validation='required'
                                                showInfoIcon={true}
                                                infoText="Select the category for your brand from the dropdown."
                                                isDisable={true}
                                            />
                                    </div>
                                </div> */}
                                    {/* <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12">
                                    <div className="forminpt">
                                        <label>Your Brand Sub-Category</label>
                                        <select>
                                            <option>Hygiene</option>
                                        </select>
                                        <SingleSelect
                                            formData={formData.brandSubcategory}
                                            formKey="value"
                                            onDataChange={onBrandSubCategoryChange}
                                            label="Your Brand Sub-Category"
                                            placeholder="Select a Sub-Category"
                                            isRequired={false}
                                            options={subCategoryOption}
                                            formValidator={formValidator}
                                            validation="required"
                                            showInfoIcon={true}
                                            infoText="Select the sub-category for your brand from the dropdown."
                                            isDisable={context.user.role === "brandManager"}
                                        />
                                    </div>
                                </div> */}
                                    {/* <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12">
                                    <div className="forminpt">
                                        <label>Your Brand Sub-Category</label>
                                        <select>
                                            <option>Hygiene</option>
                                        </select>
                                        <SingleSelect
                                            formData={formData}
                                            formKey="brandName"
                                            onDataChange={onBrandNameChange}
                                            label="Your Brand Name"
                                            placeholder="Select Brand Name"
                                            isRequired={false}
                                            options={brandNameOption}
                                            formValidator={formValidator}
                                            validation="required"
                                            showInfoIcon={true}
                                            infoText="Select your brand name here."
                                            isDisable={context.user.role === "brandManager"}
                                        />
                                    </div>
                                </div> */}
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12"></div>
                                    <div className="col-xl-5 col-lg-5 col-md-6 col-xs-12 delete-user">
                                        <a style={{ cursor: "pointer" }} className="viewwLink" onClick={openExit}>Save & Continue Later</a>
                                    </div>
                                    <div className="col-xl-7 col-lg-7 col-md-6 col-xs-12 end-md">
                                        <div className="forminpt"><input name="Save & Continue" className="btn btn-orange" type="button" value="Save & Continue " onClick={(e) => onSave(e, true)} /></div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            }
            {/* <ConfirmDialog
            togglePopup={showExitPopup}
            onModalClose={onExit}
            componentName={""}
            confirmTitle="Do you want to save and exit?"
            isLoading={confirmDialogBtnLoading}
        /> */}
            {/* <ConfirmDialog
            togglePopup={showAlertForBrandCatChange}
            onModalClose={onModalClose}
            componentName={""}
            confirmTitle="Do you want to save and exit?"
        /> */}
            <ConfirmDialog
                confirmTitle="Poor Internet Connection Detected"
                componentName="We've detected issues with your internet connection that could affect campaign creation & data loss. Please check your internet connection"
                confirmText="Got it"
                denyText="Cancel"
                togglePopup={toggleNetworkDialog}
                onModalClose={networkConfirmClose}
                onConfirmBtn={true}
            />
        </>
    )
}

export default NonWisrCreateCampaign