import React, { useEffect, useState, useContext, useRef } from 'react'
import AppContextObject from '../../../../common/context/common';
import TextField from '../../../../components/formFields/textField/textField';
import SimpleReactValidator from "simple-react-validator"
import errorSVG from "../../../../assets/images/error.svg"
import moment from 'moment';
import { compareObjs, objectDeepClone, validateFullName, hasOnlyLettersAndSpaces, titleCase } from '../../../../service/helper';
import SingleSelect from '../../../../components/formFields/singleSelect/singleSelect';
import config from "../../../../service/config"
import TextFieldWithPrefix from '../../../../components/formFields/textFieldWithPrefix/textFieldWithPrefix';
import ConfirmDialog from '../../../../components/addon/confirmDialog/confirmDialog';
import CreatableSelect from '../../../../components/formFields/creatableSelect/creatableSelect';
import editSvg from "../../../../assets/images/edit-btn.svg"


interface Props {
    formData?: any,
    setFormData?: any,
    selectedUserIndex?: any,
    onFormDataChange?: any,
    onDesignationChange?: any,
    allRoles?: any,
    formOldData?: any,
    isCMOVerfied?: any,
    setIsGeneralEdit?: any,
    setIsContactEdit?: any,
    isContactEdit?: any,
    setAllRoles?: any,
    isLoading?: any,
    setIsLoading?: any,
    haveCMO?: any,
    isGeneralEdit?: any,
    setRefetch?: any,
    refetch?: any,
    isPincodeValid?: any,
    onCountryChange?: any
}
const EditUserContact = ({
    formData,
    setFormData,
    selectedUserIndex,
    onFormDataChange,
    onDesignationChange,
    allRoles,
    formOldData,
    isCMOVerfied,
    setIsGeneralEdit,
    setIsContactEdit,
    isContactEdit,
    setAllRoles,
    isLoading,
    setIsLoading,
    haveCMO,
    isGeneralEdit,
    setRefetch,
    refetch,
    isPincodeValid,
    onCountryChange
}: Props) => {
    const context = useContext(AppContextObject)
    const [update, forceUpdate] = useState(false)
    const [formValidator] = useState(useRef(new SimpleReactValidator({
        element: (message) =>
            <span className="valError">
                <img src={errorSVG} />
                {
                    message === "The phone field is required."
                        ? "The mobile no field is required."
                        :
                        message === "The phone must be a valid phone number."
                        ?
                        "The mobile no must be a valid mobile no."
                        :
                        message === "The role field is required."
                        ?
                        "The designation field is required."
                        :
                        message
                }
            </span>
    })))
    const useForceUpdate = () => {
        const [value, setValue] = useState(0);
        return () => setValue(value => value + 1);
    }
    const forceUpdate1 = useForceUpdate();
    const onCancelContactEdit = () => {
        setIsContactEdit(false)
        setIsLoading(false)
        setFormData(formOldData)
        // formValidator.current.purgeFields()
        formValidator.current.hideMessages()
    }
    const onOpenContactEdit = () => {
        setIsContactEdit(true)
        setIsGeneralEdit(false)
        // formValidator.current.purgeFields()
        // formValidator.current.hideMessages()
    }
    const updateContactDetails = async () => {
        setIsLoading(true)
        const result = formValidator.current.allValid()
        if (result === false || isPincodeValid == false) {
            formValidator.current.showMessages()
            forceUpdate1()
            setIsLoading(false)
            return
        } else {
            formValidator.current.hideMessages()
            forceUpdate1()
        }
        if(!compareObjs(formData[selectedUserIndex], formOldData[selectedUserIndex])){
            let updatingData = {}
            if(formData[selectedUserIndex].phone !== formOldData[selectedUserIndex].phone){
                updatingData['phone'] = formData[selectedUserIndex].phone
            }
            if(formData[selectedUserIndex].country !== formOldData[selectedUserIndex].country){
                updatingData['country'] = formData[selectedUserIndex].country
            }
            if(formData[selectedUserIndex].state !== formOldData[selectedUserIndex].state){
                updatingData['state'] = formData[selectedUserIndex].state
            }
            if(formData[selectedUserIndex].city !== formOldData[selectedUserIndex].city){
                updatingData['city'] = formData[selectedUserIndex].city
            }
            if(formData[selectedUserIndex].pincode !== formOldData[selectedUserIndex].pincode){
                updatingData['pincode'] = formData[selectedUserIndex].pincode
            }
            if(formData[selectedUserIndex]["_id"]){
                let updateUserResp = await context.apis[context.user.role].updateBrandUser(
                    context.validator, {_id: formData[selectedUserIndex]["_id"]}, updatingData
                )
                if(updateUserResp && updateUserResp.done){
                    setIsContactEdit(false)
                    setRefetch(!refetch)
                    setIsLoading(false)
                }
            }
        } else {
            setIsLoading(false)
            setIsContactEdit(false)
        }
    }
    formValidator.current.rules['city_name'] = {
        message: 'Please enter valid city name.',
        rule: (val) => {
            let returnValue = context.utils.cityNameRegex.test(val)
            return returnValue
        }
    }
  return (
      <>
        {
            isContactEdit && formData && formData.length > 0
            ?
            <>
                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-20 mt-10">
                    <h2 className="htx3 clearfix">Contact</h2>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12 clearfix forminpt-bottom">
                    <TextFieldWithPrefix
                        formData={formData[selectedUserIndex]}
                        formKey='phone'
                        onDataChange={onFormDataChange}
                        label='Mobile No'
                        placeholder='Eg. 9999 999 999'
                        isRequired={false}
                        formValidator={formValidator}
                        validation='required|phone'
                    />
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 forminpt-bottom">
                    {/* <TextField
                        formData={formData[selectedUserIndex]}
                        formKey='country'
                        onDataChange={onFormDataChange}
                        label='Country'
                        placeholder='Enter Country Name'
                        isRequired={false}
                        formValidator={formValidator}
                        validation='required'
                    /> */}
                    <SingleSelect
                        formData={formData[selectedUserIndex]}
                        formKey='country'
                        onDataChange={onCountryChange}
                        label='Country'
                        placeholder='Enter Country Name'
                        isRequired={false}
                        options={config.country}
                        formValidator={formValidator}
                        validation='required'
                    />
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12 forminpt-bottom">
                    <TextField
                        formData={formData[selectedUserIndex]}
                        formKey="pincode"
                        maxLength={6}
                        onDataChange={onFormDataChange}
                        label="Pin Code"
                        placeholder="Enter Pincode"
                        isRequired={false}
                        formValidator={formValidator}
                        validation="required|min:6|numeric"
                        isPincodeValid={isPincodeValid}
                    />
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12 forminpt-bottom">
                    <TextField
                        formData={formData[selectedUserIndex]}
                        formKey="state"
                        onDataChange={onFormDataChange}
                        label="State"
                        placeholder="Enter State"
                        isRequired={false}
                        formValidator={formValidator}
                        validation="required|alpha_space"
                        maxLength={50}
                    />
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12 forminpt-bottom">
                    <TextField
                        formData={formData[selectedUserIndex]}
                        formKey="city"
                        onDataChange={onFormDataChange}
                        label="City"
                        placeholder="Enter City"
                        isRequired={false}
                        formValidator={formValidator}
                        validation="required|city_name"
                        maxLength={50}
                    />
                </div>
                
                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 end-md">
                    <a href="javascript:void(0);" onClick={()=> onCancelContactEdit()} className="btn btn-outline" >Cancel</a>
                    <a href="javascript:void(0);" onClick={()=> updateContactDetails()} className={isLoading ? "btn btn-orange button--loading btn-disabled" : "btn btn-orange"} >Save Changes</a>
                </div>
            </>
            :
            <>
                {
                    formOldData && formOldData.length > 0 && selectedUserIndex !== null
                    &&
                    <>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-20 mb-10"></div>
                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-10">
                                <h2 className="htx3 clearfix">Contact 
                                {
                                    context.user.role === "brandCMO" 
                                    ||
                                    formOldData[selectedUserIndex]._id === context.user._id
                                    ||
                                    (formOldData[selectedUserIndex].role === "CMO" && isCMOVerfied === false)
                                    ?
                                    <a href="javascript:void(0);" onClick={()=> onOpenContactEdit()} className="editbtn">
                                    <img src={editSvg} /> Edit</a>
                                    :
                                    ""
                                }
                                    
                                </h2>
                            </div>
                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 pl pr">
                                <div className="row generalview">
                                    <div className="col-xl-4 col-lg-4 col-md-12 col-xs-12 mt-20">
                                        <div className="inventory-overHead">
                                            <p>Mobile No</p>
                                            <h3>{formOldData[selectedUserIndex].phone || "-"}</h3>
                                        </div>
                                    </div>
                                    <div className="col-xl-8 col-lg-8 col-md-12 col-xs-12 mt-20">
                                        <div className="inventory-overHead">
                                            <p>Location</p>
                                            {/* <h3>202-206 Tolstoy H No-15 Tolstoy Marg, New Delhi 110001, India</h3> */}
                                            <h3>{formOldData[selectedUserIndex].city ? titleCase(formOldData[selectedUserIndex].city) : "-"}, {formOldData[selectedUserIndex].state || "-"} {formOldData[selectedUserIndex].pincode || "-"}, {formOldData[selectedUserIndex].country || "-"}</h3>
                                        </div>
                                    </div>

                                </div>
                        </div>
                    </>
                }
            </>
        }
    </>
  )
}

export default EditUserContact