import React, {
    useEffect,
    useState,
    useContext
} from "react"

import AppContextObject from "../../common/context/common"

import riarrownext from "../../assets/images/right-arrow-next.svg"

import BrandRequestPagination from "./brandRequestPagination"
import ImagePreview from "./imagePreview"
import ApprovalDeadLine from "./approvalDeadLine"
import CampaignApprovalModal from "./campaignApprovalModal"
import ConfirmedApprovalModal from "./confirmedApprovalModal"
import BrandRequestDetailModal from "./brandRequestDetailModal"
import ApproveAllBrandsRequestModal from "./approveAllBrandsRequestModal"
import RejectBrandRequestModal from "./rejectBrandRequestModal"
import ThankyouModal from "./thankyouModal"

import { isObjectEmpty, formatDateToDDMMMYY, objectDeepClone, revenueFormatter } from "../../service/helper"

// import { PublicContextInitiator } from "../../publicSrc/apis/publicContextInitiator"

import toast from "react-hot-toast"

const BrandRequests = (props) => {

    const {
        brandsRequestList,
        getAllBrandsRequests,
        refetch,
        setRefetch
    } = props

    useEffect(() => {
        if (brandsRequestList && brandsRequestList.length) {
            setSelectedRequest(brandsRequestList[0])
        }
    }, [brandsRequestList])

    const context = useContext(AppContextObject)

    const [showModalapproveAll, setShowModalapproveAll] = useState(false)
    const [overlay, setOverlay] = useState(false)
    const [overlay2, setOverlay2] = useState(false)
    const [showModalApproveActive, setshowModalApproveActive] = useState(false)
    const [selectedRequest, setSelectedRequest] = useState<any>({})
    const [selectedRequestForModal, setSelectedRequestForModal] = useState<any>({})
    const [showImagePreview, setShowImagePreview] = useState(false)
    const [selectedImageIndex, setSelectedImageIndex] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [showConfirmedApprovalModal, setShowConfirmedApprovalModal] = useState(false)
    const [showBrandRequestDetailModal, setShowBrandRequestDetailModal] = useState(false)
    const [showConfirmRejectModal, setShowConfirmRejectModal] = useState(false)
    const [showThankyouModal, setShowThankyouModal] = useState(false)
    const [thankyouTitle, setThankyouTitle] = useState("")
    const [thankyouDescription, setThankyouDescription] = useState("")

    useEffect(() => {
        if (!showModalapproveAll && !showModalApproveActive && !showConfirmedApprovalModal && !showBrandRequestDetailModal &&
            !showConfirmRejectModal && !showThankyouModal) {
            setOverlay(false)
        }
    }, [showModalapproveAll, showModalApproveActive, showConfirmedApprovalModal, showBrandRequestDetailModal, showConfirmRejectModal, showThankyouModal])

    const openModelApproveAll = () =>{
        setOverlay(true)
        setShowModalapproveAll(true)
    }

    const openDetailsModal = () => {
        setOverlay(true)
        setShowBrandRequestDetailModal(true)
        setSelectedRequestForModal(selectedRequest)
    }

    const openModelApproveActive = () =>{
        setshowModalApproveActive(true)
        setOverlay(true)
    }

    const closeWithoutSaving = () => {
        setshowModalApproveActive(false)
        setShowConfirmedApprovalModal(false)
    }

    const onNextClick = () => {
        if (currentPage < brandsRequestList.length) {
            setSelectedRequest(brandsRequestList[currentPage])
            setCurrentPage(currentPage + 1)
        }
    }

    const onPrevClick = () => {
        if (currentPage > 1) {
            setSelectedRequest(brandsRequestList[currentPage - 2])
            setCurrentPage(currentPage - 1)
        }
    }

    const onPageChange = (page) => {
        setSelectedRequest(brandsRequestList[page])
        setCurrentPage(page + 1)
    }

    const openImagePreview = (index) => {
        setSelectedImageIndex(index)
        setShowImagePreview(true)
    }

    const viewDetails = () => {
        setOverlay(true)
        setShowBrandRequestDetailModal(true)
    }

    const approveRequest = async () => {
        setshowModalApproveActive(false)
        const json = {
            data: {
                data: {
                    schoolStatus: "accepted",
                },
                args: {
                    campaignId: selectedRequest._id,
                }
            }
        }
        const result = await context.apis[context.user.role].acceptCampaignRequest(context.validator, json.data.args, json.data.data)
        if (result && result?.done === true) {
            setShowThankyouModal(true)
            setOverlay(true)
            setThankyouTitle("Campaign Approval!")
            setThankyouDescription("Our brand champions have approved the content and with your final approval, we will go live with the campaign in 48 hours.")
            closeAllModals()
            setRefetch(!refetch)
        } else {
            toast.error("Something went wrong")
        }
    }

    const openApprovalModal = () => {
        setShowBrandRequestDetailModal(false)
        setshowModalApproveActive(true)
        setOverlay(true)
    }

    const openRejectModal = () => {
        setOverlay(true)
        setOverlay2(true)
        setShowConfirmRejectModal(true)
    }

    const closedetailsModal = () => {
        setShowBrandRequestDetailModal(false)
    }

    const viewDetailsForSelectedRequest = (index) => {
        setSelectedRequestForModal(brandsRequestList[index])
        viewDetails()
    }

    const approveRequestForSelectedBrand = (index) => {
        openModelApproveActive()
    }

    const closeConfirmRejectModal = () => {
        setOverlay2(false)
        setShowConfirmRejectModal(false)
    }

    const onRejectRequest = async (reason) => {
        const json = {
            data: {
                data: {
                    schoolStatus: "rejected",
                    rejectionMessage: reason
                },
                args: {
                    campaignId: selectedRequest._id,
                }
            }
        }
        const result = await context.apis[context.user.role].rejectCampaignRequest(context.validator, json.data.args, json.data.data)
        if (result && result?.done === true) {
            closeAllModals()
            setThankyouTitle("Thank You!")
            setThankyouDescription("We have registered your concern and a representative from our team will connect with you within 24 hours to discuss this further.")
            setShowThankyouModal(true)
            setOverlay(true)
            setRefetch(!refetch)
        } else {
            toast.error("Something went wrong")
        }
        setOverlay2(false)
    }

    const closeAllModals = () => {
        setShowConfirmRejectModal(false)
        setShowModalapproveAll(false)
        setshowModalApproveActive(false)
        setSelectedRequestForModal(false)
        setShowConfirmedApprovalModal(false)
        setShowBrandRequestDetailModal(false)
        setShowConfirmRejectModal(false)
    }

    const closeThankyouModal = () => {
        setShowThankyouModal(false)
        setOverlay(false)
        setThankyouTitle("")
        setThankyouDescription("")
        getAllBrandsRequests()
    }
    console.log("selectedRequest",selectedRequest)

    return (
        <>
            <div className={`ovelap ${overlay === true ? "active" : ""}`}></div>
            <div id="brandReqCard" className="row">
                <div className="col-xl-7 col-lg-7 col-md-7 col-xs-12">
                    <h2 className="htx2 clearfix">Brand Request(s)</h2>
                </div>
                <div className="col-xl-5 col-lg-5 col-md-5 col-xs-12">
                    {
                        brandsRequestList.length > 0 &&
                        <a
                            href="javascript:void(0)"
                            onClick={openModelApproveAll}
                            className="viewwLink"
                            data-target="approvalAll"
                        >
                            Approve All({brandsRequestList.length})
                        </a>
                    }
                </div>
                {
                    !isObjectEmpty(selectedRequest) ?
                    <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                        <div className="brand-class">
                            <h2 className="htx2 clearfix">{selectedRequest?.brandName}</h2>
                            {
                                selectedRequest?.approvalDeadLine &&
                                <ApprovalDeadLine
                                    approvalDeadLine={selectedRequest.approvalDeadLine}
                                />
                            }
                            {(context.user && context.user.role === "schoolPrincipal" && selectedRequest.estimatedRevenue) &&
                                <div className="brand-creat-img">
                                    <h3>Estimated Revenue</h3>
                                    <h4>{revenueFormatter(selectedRequest.estimatedRevenue)}</h4>
                                </div>
                            }

                            <div className="brand-btn">
                                <a
                                    href="javascript:void(0);"
                                    className="btn btn-outline toggle"
                                    onClick={openDetailsModal}
                                >View Details</a>

                                <a
                                    href="javascript:void(0)"
                                    className="btn btn-orange"
                                    onClick={openModelApproveActive}
                                >Approve</a>
                            </div>
                            {
                                (selectedRequest?.campaignCreatives && selectedRequest.campaignCreatives.length > 0) &&
                                <div className="brand-creat-img">
                                    <h3>Campaign Creatives</h3>
                                    <ul className="brand-creativeCampaing">
                                        {
                                        // selectedRequest.campaignCreativesRevised && selectedRequest.campaignCreativesRevised.length > 0 ?
                                        //     objectDeepClone(selectedRequest.campaignCreativesRevised.slice(0, 3)).map((creative, index) => {
                                        //         return (
                                        //             <React.Fragment key={index}>
                                        //                 <li onClick={() => openImagePreview(index)}>
                                        //                     <img src={creative?.image} />
                                        //                 </li>
                                        //             </React.Fragment>
                                        //         )
                                        //     })
                                        //     :
                                            objectDeepClone(selectedRequest.campaignCreatives.slice(0, 3)).map((creative, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <li onClick={() => openImagePreview(index)}>
                                                            <img src={creative?.image} />
                                                        </li>
                                                    </React.Fragment>
                                                )
                                            })
                                        }
                                        {/* {
                                            selectedRequest.campaignCreatives.length > 3 &&
                                            <li onClick={() => openImagePreview(3)} className="imageOverlay">
                                                {
                                                    selectedRequest.campaignCreatives.length > 4 &&
                                                    <span>+ {selectedRequest.campaignCreatives.length - 4}</span>
                                                }
                                                <img src={selectedRequest.campaignCreatives[3]} />
                                            </li>
                                        } */}
                                    </ul>
                                </div>
                            }
                            {
                                (selectedRequest?.inventories && (selectedRequest.inventories.length + selectedRequest.activities.length) > 0) &&
                                <div className="brand-creat-img">
                                    <h3>Inventory / Events Booked</h3>
                                    <h4>
                                        {[...selectedRequest.inventories, ...selectedRequest.activities].map(inventory => inventory.name).join(", ")}
                                    </h4>
                                </div>
                            }
                            {
                                selectedRequest?.startDate && selectedRequest?.endDate &&
                                <div className="brand-creat-img">
                                    <h3>Schedule</h3>
                                    <div className="starend">
                                        <p>
                                            Start <b>{formatDateToDDMMMYY(selectedRequest.startDate)}</b>
                                        </p>
                                        <img className="next-arow" src={riarrownext} />
                                        <p>
                                            End <b>{formatDateToDDMMMYY(selectedRequest.endDate)}</b>
                                        </p>
                                    </div>
                                </div>
                            }
                        </div>

                        <BrandRequestPagination
                            totalPage={brandsRequestList ? brandsRequestList.map((el, index) => index) : 1}
                            currentPage={currentPage}
                            onNextClick={onNextClick}
                            onPrevClick={onPrevClick}
                            onPageChange={onPageChange}
                        />
                    </div>
                    :
                    <div id="brandReqEmptyCard" className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                    <div className="noData height-two mt-20"> 
                        <h3>Currently there is no data</h3>
                    </div>
                    </div>

                }
            </div>

            {
                (selectedRequest?.campaignCreatives && selectedRequest.campaignCreatives.length > 0) &&
                <ImagePreview
                    setShowImagePreview={setShowImagePreview}
                    showImagePreview={showImagePreview}
                    images={selectedRequest.campaignCreatives}
                    selectedImageIndex={selectedImageIndex}
                />
            }

            <ApproveAllBrandsRequestModal
                showModalapproveAll={showModalapproveAll}
                closeModal={() => setShowModalapproveAll(false)}
                approveRequest={approveRequestForSelectedBrand}
                viewDetails={viewDetailsForSelectedRequest}
                requestList={brandsRequestList}
            />

            <CampaignApprovalModal
                showModalApproveActive={showModalApproveActive}
                closeWithoutSaving={closeWithoutSaving}
                viewDetails={viewDetails}
                approveRequest={approveRequest}
            />

            <ConfirmedApprovalModal
                showConfirmedApprovalModal={showConfirmedApprovalModal}
                closeModal={closeWithoutSaving}
            />

            <BrandRequestDetailModal
                showBrandRequestDetailModal={showBrandRequestDetailModal}
                campaignData={selectedRequestForModal}
                openApprovalModal={openApprovalModal}
                openRejectModal={openRejectModal}
                closedetailsModal={closedetailsModal}
                overlay={overlay2}
            />

            <RejectBrandRequestModal
                showConfirmRejectModal={showConfirmRejectModal}
                closeModal={closeConfirmRejectModal}
                onRejectRequest={onRejectRequest}
            />

            <ThankyouModal
                showThankyouModal={showThankyouModal}
                closeModal={closeThankyouModal}
                modalTitle={thankyouTitle}
                description={thankyouDescription}
            />
        </>
    )
}

export default BrandRequests
