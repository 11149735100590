import campcreate from "../../assets/images/img2.png"
import Inventory from "../../publicSrc/pages/schools/updateDetails/inventory/inventoryList"
import imgInventory from "../../assets/images/blank-inventory-img.jpg"

interface Props {
    showModalTwoMore?: any,
    inventoriesList?: any,
    closeWithoutSaving?: any
    eventList?: any
}
const InventoryMore = ({
    showModalTwoMore,
    inventoriesList,
    closeWithoutSaving,
    eventList
}: Props) => {
    console.log(inventoriesList)
    return (
        <>
            <div className={`ovelap ${showModalTwoMore === true ? "active" : ""}`}></div>
            <div id="inventoryMore" className={`popup ${showModalTwoMore === false ? "hide" : ""}`}>
                <div className="popup-header">
                    <h3 className="htx2 pophead">Inventories / Events Selected</h3>
                    <span className="close toggle" onClick={closeWithoutSaving} data-target="myPopup">close</span>
                </div>
                <div className="popup-body">
                    <div className="viewdetailsHead">
                        <h3>Inventories</h3>
                        <div className="invetorypopblock">
                            {
                                inventoriesList.length > 0 ? inventoriesList.map(inventory => {
                                    return (
                                        <div className="invetorypopInner">
                                            <img src={inventory.image ? inventory.image === "no image" ? imgInventory : inventory.image : imgInventory} />
                                            <h3>{inventory.name}</h3>
                                        </div>
                                    )
                                })
                                :
                                <div style={{margin: "10px 0 10px 10px", fontSize: "14px", color: "#8a8a8a"}}>No inventories</div>
                            }
                        </div>
                        {eventList && eventList.length > 0 &&
                            <>
                                <hr className="hr2 mb20" />
                                <h3>Events</h3>
                                <div className="row invetorypopblock">
                                    {
                                        eventList.length > 0 ? eventList.map(inventory => {
                                            return (
                                                <div className="invetorypopInner" style={{gridTemplateColumns: "auto"}}>
                                                    {/* <img src={imgInventory} /> */}
                                                    <h3>{inventory.name}</h3>
                                                </div>
                                            )
                                        })
                                        :
                                        <div style={{margin: "10px 0 10px 10px", fontSize: "14px", color: "#8a8a8a"}}>No events</div>
                                    }
                                </div>
                            </>
                        }
                        {/* <a href="javascript:void(0)" className="viewwLink">View All Inventory</a> */}
                    </div>
                </div>
            </div>
        </>
    )
}
export default InventoryMore