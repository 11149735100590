import { useState, useContext, useEffect, useRef } from "react"

import { Link, useHistory } from "react-router-dom"
// import OnGoingCampaigns from "./onGoingCampaigns"
import Pagination from "../../../../../components/addon/pagination"
import arrowView from "../../../../../assets/images/arrow-view.svg"
import arrowViewRound from "../../../../../assets/images/arrow-view-round.svg"
import tableFilter from "../../../../../assets/images/table-filter.svg"
import TopBar from "../../../schools/components/topBar"
import AppContextObject from "../../../../../common/context/common"
import { checkIfNumberOnly, formatDateShowMonth } from "../../../../../service/helper"
import DataContext from "../../../../../service/backendContext"
import SimpleReactValidator from "simple-react-validator"
import errorSVG from "../../../../../assets/images/error.svg"
import TextField from "../../../../../components/formFields/textField/textField"
import SliderComponent from "../../../../../components/addon/rangeSlider"
import SingleSelect from "../../../../../components/formFields/singleSelect/singleSelect"
import config from "../../../../../service/config"
import TextArea from "../../../../../components/formFields/textArea"
import CustomDatePicker from "../../../../../components/formFields/datePicker"
import moment from 'moment'
import NumberFormat from "react-number-format"
import MultiSelect from "../../../../../components/formFields/multiSelect/multiSelect"
import ConfirmDialog from "../../../../../components/addon/confirmDialog/confirmDialog"
import Loader from '../../../../../components/addon/loader'


const SetUpCampaign = ({ setShowProgressSideMenu }) => {
    const history = useHistory();
    const [formValidator] = useState(useRef(new SimpleReactValidator({
        element: (message, className) =>
            <span className="valError"><img src={errorSVG} />{message}</span>
    })
    ))
    const [update, forceUpdate] = useState(false)
    const context = useContext(AppContextObject)
    const parentData = useContext<any>(DataContext)
    const [formData, setFormData] = useState<any>({
        name: '',
        startDate: '',
        endDate: '',
        description: '',
        objective: '',
        wisrOffering: {},
        budget: '',
        targetGender: '',
        tagetAgeAudiance: [],
        studyMedium: [],
        brandCatId: '',
        brandSubCatId: ''
    })
    const [isLoading, setIsLoading] = useState(false)
    const [brandDetailing, setBrandDetailing] = useState<any>([parentData.data.brandDetail]);
    const [budgetError, setBudgetError] = useState(false)
    const [brandCategoryList, setBrandCategoryList] = useState([])
    const [subCategoryOptions, setSubCategoryOptions] = useState([])
    const [showExitPopup, setShowExitPopup] = useState(false);
    const [campaignId, setCampaignId] = useState('')
    useEffect(() => {
        setShowProgressSideMenu(true)
            parentData.setActiveModule({
                ...parentData.activeModule,
                module: "setUpCampaign"
            })
        
        getBrandCategoryList()
        if (parentData.data.campaignId !== '') {
            getCampaignDetails()

        }
    }, [parentData.data.campaignId])
    // useEffect(() => {
    //     // parentData.setData({
    //     //     ...parentData.data,
    //     //     campaignId:history.location.state['campaignId']
    //     // })
    //     getCampaignDetails()
    // }, [])

    const getCampaignDetails = async () => {
        setIsLoading(true)
        const resp = await context.apis.public.getCampaignById(
            context.validator, { _id: parentData.data.campaignId }, {}
        )

        console.log('getCampaignById====>', resp)
        if (resp && resp.done) {
            setIsLoading(false)
            let ratioBoys = 0, ratioGirls = 0, noOfBoys = 0, noOfGirls = 0
            ratioBoys = Math.floor((resp.response.totalBoys * 100)/(resp.response.totalBoys + resp.response.totalGirls))
            ratioGirls = 100 - ratioBoys
            parentData.setData({
                ...parentData.data,
                brandDetail: {
                    name: resp.response.name,
                    brandName:resp.response.brandName,
                    brandSubCategoryName:resp.response.brandSubcategoryName,
                    startDate: resp.response.startDate,
                    endDate: resp.response.endDate,
                    description: resp.response.description,
                    objective: resp.response.objective,
                    wisrOffering: resp.response.wisrOffering,
                    budget: resp.response.budget,
                    targetGender: resp.response.targetGender,
                    tagetAgeAudiance: resp.response.targetAgeGroup,
                    studyMedium: resp.response.studyMedium,
                    brandCatId: resp.response.brandCategory,
                    brandSubCatId: resp.response.brandSubcategory,
                    brandcategoryId: resp.response.brandCategory,
                    location:resp.response.locations
                },
                campaignDetail: {
                    wisrOption: false,
                    totalImpression: resp.response.totalImpression,
                    totalReach: resp.response.totalReach,
                    totalBudget: resp.response.budget,
                    boysRatio: ratioBoys,
                    girlsRatio: ratioGirls
                },
                inventory: {
                    inventories: resp.response.campaignData.inventories
                },
                school:{
                    schools: resp.response.schoolCampaignDatas
                },
                activities: {
                    events:resp.response.campaignData.activities
                },
                campaignCreative:{
                    creatives: {image1: !resp.response.isTrial && resp.response.campaignCreatives.length > 0 ? resp.response.campaignCreatives[0].image : "", image2: !resp.response.isTrial && resp.response.campaignCreatives.length > 1 ?  resp.response.campaignCreatives[1].image : "", image3: !resp.response.isTrial && resp.response.campaignCreatives.length > 2 ? resp.response.campaignCreatives[2].image : ""}
                },
                isTrial: resp.response.isTrial
                

            })
            parentData.setActiveModule({
                module: 'setUpCampaign',
                subModule: ''
            })
        }
    }
    const getBrandCategoryList = async () => {
        const resp = await context.apis.public.listBrandCategoriesMaster(
            context.validator, {}, {}
        )
        console.log(resp)
        if (resp && resp.done) {
            setBrandCategoryList([...resp.response.rows])
        }
    }
    //     if(proceed){
    //         if(formData.inventory){
    //             history.push('/brands/brand-details/create-campaign/inventory')
    //         }
    //         else{
    //             history.push('/brands/brand-details/create-campaign/schools')
    //         }
    //     }
    // }
    // const onExit = (confirm) => {
    //     if(confirm){
    //         history.push('/brands/brand-details/campaign')
    //     }
    //     setShowExitPopup(false)
    // }
    const nextPage = () => {
        console.log("parentData.data", parentData.data)
        if(parentData.data.brandDetail.wisrOffering.inventory){
            history.push('/backend/backend-details/view-campaign/inventory');
        }
        else{
            history.push('/backend/backend-details/view-campaign/events');
        }
    }
    return (
        <>
            <div className={`ovelap`}></div>
            {/* <TopBar isDashboard={true} /> */}
            {isLoading ? 
            <Loader />
            :
            <div className="col-xl-9 col-lg-9 col-md-12 col-xs-12">
                <div className="rightPart noBack">
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-20"><h2 className="htx1 clearfix mt-10 mb-10">Create a Campaign</h2></div>
                        <div className="col-xl-8 col-lg-8 col-md-12 col-xs-12 pl pr">
                            <div className="row">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                    <div className="forminpt">
                                        <label>Name</label>
                                        {/* <input id="fullName" className="" placeholder="Enter Campaign Name" type="text" value="" /> */}
                                        <TextField
                                            formData={parentData.data.brandDetail}
                                            formKey='name'
                                            placeholder='Enter Campaign Name'
                                            isDisabled={true}
                                        />
                                    </div>
                                </div>

                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                    <div className="forminpt mb-10">
                                        <label>Duration</label>
                                    </div>
                                    <div className="row">
                                        <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12 pl">
                                            {/* <div className="forminpt">
                                            // <input id="startDate" className="" placeholder="select a date" type="date" value={formData.startDate} onChange={onFormDataChange} />
                                        </div> */}
                                            <div className="forminpt">
                                                <label>Start Date</label>
                                                <CustomDatePicker
                                                    formData={parentData.data.brandDetail}
                                                    formKey='startDate'
                                                    isDisabled={true}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12 pr">
                                            {/* <div className="forminpt">
                                            // <input id="endDate" className="" placeholder="select a date" type="date" value={formData.endDate} onChange={onFormDataChange} />
                                        </div> */}
                                            <div className="forminpt">
                                                <label>End Date</label>
                                                <CustomDatePicker
                                                    formData={parentData.data.brandDetail}
                                                    formKey='endDate'
                                                    isDisabled={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                    <div className="forminpt">
                                        <label>Description</label>
                                        {/* // <textarea id="description" value={formData.description} onChange={onFormDataChange} placeholder="Write a few sentences about the campaign"></textarea> */}
                                        <TextArea
                                            formData={parentData.data.brandDetail}
                                            formKey='description'
                                            isDisabled={true}
                                            maxLength={500}
                                        />
                                    </div>
                                   
                                </div>
                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                    <div className="forminpt">
                                        <label>Objective</label>
                                        {/* <input id="objective" className="" placeholder="Reach, Recognition, Engagement etc" value={formData.endDate} onChange={onFormDataChange} /> */}
                                        <TextField
                                            formData={parentData.data.brandDetail}
                                            formKey='objective'
                                            isDisabled={true}
                                        />
                                    </div>
                                </div>
                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                    <div className="forminpt checkboxinput">
                                        <label>Wisr Offering <small>Choose one or more</small></label>
                                        <div className="checkboxinputList">
                                            <div className="checkbox">
                                                <input type="checkbox" id="inventory" checked={parentData.data.brandDetail.wisrOffering.inventory} />
                                                <label htmlFor="inventory">Inventory</label>
                                            </div>
                                            <div className="checkbox">
                                                <input type="checkbox" id="eventAndOccations" checked={parentData.data.brandDetail.wisrOffering.eventAndOccations} />
                                                <label htmlFor="eventAndOccations">Event and Occasions</label>
                                            </div>
                                            <div className="checkbox">
                                                <input type="checkbox" id="csr" checked={parentData.data.brandDetail.wisrOffering.csr} />
                                                <label htmlFor="csr">CSR</label>
                                            </div>
                                            <div className="checkbox">
                                                <input type="checkbox" id="sampling" checked={parentData.data.brandDetail.wisrOffering.sampling} />
                                                <label htmlFor="sampling">Sampling</label>
                                            </div>
                                            <div className="checkbox">
                                                <input type="checkbox" id="custom" checked={parentData.data.brandDetail.wisrOffering.custom} />
                                                <label htmlFor="custom">Custom</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                    <div className="tol-built campaignBudget mt-0 mb-30">
                                        {/* <p>Budget</p>
                                    <div className="tol-built-in"> <span><strong></strong></span> </div>
                                    <span>
                                        <b>₹</b>
                                        <input name="" value="200,000" type="text" />
                                    </span> */}
                                        <p>Budget</p>
                                        <SliderComponent
                                            value={parentData.data.brandDetail.budget}
                                            min={0}
                                            max={500000000}
                                            onSliderValueChange={() => {}}
                                            changeError={setBudgetError}
                                            disable={true}
                                        />
                                        <span>
                                            <b>₹</b>
                                            <NumberFormat 
                                                type="text"
                                                thousandSeparator={true}
                                                thousandsGroupStyle="lakh"
                                                value={parentData.data.brandDetail.budget}
                                                disabled={true}
                                            />
                                        </span>
                                    </div>
                                </div>
                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                    <div className="forminpt checkboxinput">
                                        <label>Target  Audience</label>
                                        <div className="checkboxinputList">
                                            <div className="radioBtn">
                                                <input type="radio" id="boys" name="radio-group" checked={parentData.data.brandDetail.targetGender === "boys"} />
                                                <label htmlFor="boys">Boys</label>
                                            </div>
                                            <div className="radioBtn">
                                                <input type="radio" id="girls" name="radio-group" checked={parentData.data.brandDetail.targetGender === "girls"} />
                                                <label htmlFor="girls">Girls</label>
                                            </div>
                                            <div className="radioBtn">
                                                <input type="radio" id="both" name="radio-group" checked={parentData.data.brandDetail.targetGender === "co-ed"} />
                                                <label htmlFor="both">Both</label>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12">
                                    <div className="forminpt mb-0"><label>Target Audience Age</label></div>
                                    <div className="row">
                                        <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12 pl">
                                            <div className="forminpt">
                                                <select disabled>
                                                    <option>{parentData.data.brandDetail.tagetAgeAudiance[0]}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12 pr">
                                            <div className="forminpt">
                                                <select disabled>
                                                    <option>{parentData.data.brandDetail.tagetAgeAudiance[1]}</option>

                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12">
                                    <div className="forminpt">
                                        {/* <label>Medium Of Study</label>
                                    <select>
                                        <option>8</option>
                                    </select> */}
                                        <MultiSelect
                                            formData={parentData.data.brandDetail}
                                            formKey='studyMedium'
                                            label='Medium Of Study'
                                            placeholder='Select Medium of study'
                                            options={config.languages.filter((lang) => lang.value.toLowerCase() !== "custom")}
                                            isDisable={true}
                                        />
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12">
                                    <div className="forminpt">
                                        {/* <label>Your Brand Category</label>
                                    <select>
                                        <option>FMCG</option>
                                    </select> */}
                                        {brandCategoryList && brandCategoryList.length !== 0 &&
                                            <SingleSelect
                                                formData={{ '_id': parentData.data.brandDetail.brandCatId }}
                                                formKey='_id'
                                                label='Your Brand Category'
                                                options={brandCategoryList && brandCategoryList.length !== 0 && brandCategoryList.map((item) => { return { _id: item._id, value: item._id, label: item.name } })}
                                                isDisable={true}
                                            />
                                        }
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12">
                                    <div className="forminpt">
                                        <label>Your Brand Sub-Category</label>
                                    <select disabled>
                                        <option>{parentData.data.brandDetail.brandSubCategoryName}</option>
                                    </select>
                                        {/* <SingleSelect
                                            formData={{ '_id': formData.brandSubCatId }}
                                            formKey="_id"
                                            label="Your Brand Sub-Category"
                                            placeholder="Select a Sub-Category"

                                        /> */}
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12">
                                    <div className="forminpt">
                                        <label>Your Brand Name</label>
                                    <select disabled>
                                        <option>{parentData.data.brandDetail.brandName}</option>
                                    </select>
                                        {/* <SingleSelect
                                            formData={{ '_id': formData.brandSubCatId }}
                                            formKey="_id"
                                            label="Your Brand Sub-Category"
                                            placeholder="Select a Sub-Category"

                                        /> */}
                                    </div>
                                </div>
                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12"></div>
                                <div className="col-xl-5 col-lg-5 col-md-12 col-xs-12 delete-user mt-30">
                                <Link className="btn btn-orange quit-btn" to={{
                                        pathname: "/backend/backend-details/create-trial-campaign-details",
                                        state: { campaignType: parentData.data.isTrial ? 'trial' : 'create', from: parentData.data.from, campaignId: parentData.data.campaignId }
                                    }}>QUIT</Link>
                                </div>
                                <div className="col-xl-7 col-lg-7 col-md-12 col-xs-12 end-md mt-30">
                                    <div className="forminpt"><input name="Next" className="btn btn-orange" type="button" value="Next"
                                        onClick={nextPage}
                                    /></div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            }
            <ConfirmDialog
                togglePopup={showExitPopup}
                // onModalClose={onExit}
                componentName={""}
                confirmTitle="Do you want to save and exit"
            />
        </>
    )
}

export default SetUpCampaign
