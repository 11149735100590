import { useContext } from "react"

import DataContext from "../../../../../service/contextProvider"

import { Link } from "react-router-dom"

import backBtn from "../../../../../assets/images/back-arrow.svg"
import menu from "../../../../../assets/images/menu.svg"

const OnboardingBackBtn = (props) => {

    const {
        link,
        text
    } = props

    const url = link ? link : "/"
    const btnText = text ? text : "Back"

    const parentData = useContext<any>(DataContext)

    const toggleMenu = () => {
        if (parentData.toggleSidemenu) {
            parentData.toggleSidemenu()
        }
    }

    return (
        <>
            <a onClick={toggleMenu} href="javascript:vid(0)" className={`hummob ${parentData.showSidemenu === true ? "no_highlight" : ""}`}>
                <img src={menu} />
            </a>
            {
                link 
                ?
                <Link to={url} className="backbtn">
                    <img src={backBtn} />
                    {btnText}
                </Link>
                :
                <a href="javascript:void(0);" className="backbtn">
                    <img src={backBtn} />
                    {btnText}
                </a>
            }
            
            <div className="clearfix"></div>        
        </>
    )
}

export default OnboardingBackBtn
