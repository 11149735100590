import {
    useEffect,
    useState,
    forwardRef,
    useImperativeHandle
} from 'react'

declare var google

interface Props {
    isScriptLoaded? : boolean
    isScriptLoadSucceed? : boolean
    formData? : any,
    onMapLoaded?: Function,
    placeArray?: any,
    pincodeList?: any
}

const MultiPointMap = forwardRef(({
    isScriptLoaded,
    isScriptLoadSucceed,
    formData,
    pincodeList
}: Props, ref) => {
    const [mapComponent, setMapComponent] = useState(null)
    const [mapMarker, setMapMarker] = useState(null)
    // const [cityList, setCityList] = useState([])
    let cityList = []
    const INDIA_BOUNDS = {
        south: 6,
        north: 37.6,
        west: 65,
        east: 97.25,
    }

    let marker = null
    let delayFactor = 0;
    // let geocoder = null

    useEffect(() => {
        let obj = {}
        pincodeList.forEach(item => {
            if(obj[item]){
                obj[item] += 1
            }
            else{
                obj[item] = 1
            }
        })
        let cityArray = []
        Object.keys(obj).forEach(item => {
            let data = {city: item, count: obj[item]}
                cityArray.push(data)
        })
        // setCityList([...cityArray])
        cityList = [...cityArray]
        if (typeof document !== "undefined" && document.getElementById("map")) {
            if (!mapComponent) {
                initializeMap()
            }
        }
        // setMarker(pincodeList)
        // google.maps.event.addListener(marker, 'dragend', () => {
        //     geocodePosition(marker.getPosition())
        // })
    }, [])
    useEffect(() => {
        let obj = {}
        pincodeList.forEach(item => {
            if(obj[item]){
                obj[item] += 1
            }
            else{
                obj[item] = 1
            }
        })
        let cityArray = []
        Object.keys(obj).forEach(item => {
            let data = {city: item, count: obj[item]}
                cityArray.push(data)
        })
        // setCityList([...cityArray])
        cityList = [...cityArray]
        initializeMap()
    }, [pincodeList])

    const initializeMap = () => {
        return new Promise((resolve, reject) => {
            const map = new google.maps.Map(document.getElementById("map") as HTMLElement, {
                center: { lat: 20.593684, lng: 78.96288 },
                restriction: {
                    latLngBounds: INDIA_BOUNDS,
                    strictBounds: true,
                },
                zoom: 4,
                mapTypeControl: false,
                mapTypeControlOptions: {
                    style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                    position: google.maps.ControlPosition.TOP_CENTER,
                },
                zoomControl: false,
                zoomControlOptions: {
                    position: google.maps.ControlPosition.LEFT_CENTER,
                },
                scaleControl: false,
                streetViewControl: false,
                streetViewControlOptions: {
                    position: google.maps.ControlPosition.LEFT_TOP,
                },
                fullscreenControl: true,
            })
            // marker = new google.maps.Marker({
            //     position: { lat: 19.0760, lng: 72.8777 },
            //     map,
            //     draggable: false,
            //     })
            const pointMarker = (i) => {
                const geocoder = new google.maps.Geocoder()
                geocoder.geocode( { 'address': cityList[i].city, componentRestrictions: {country: 'IN'}}, async function(results, status) {
                    if (status == 'OK') {
                        marker = new google.maps.Marker({
                            map: map,
                            position: results[0].geometry.location,
                            draggable: false,
                            label: { color: '#fff', fontWeight: 'bold', fontSize: '14px', text: cityList[i].count.toString() }
                        })
                        google.maps.event.addListener(marker, 'dragend', () => {
                            geocodePosition(marker.getPosition())
                        })
                    }
                    else if(status == "OVER_QUERY_LIMIT"){
                        delayFactor++;
                        setTimeout(function () {
                            pointMarker(i)
                        }, delayFactor * 100);
                    } else {
                        console.log('Geocode was not successful for the following reason: ' + status);
                    }
                })
            }
            for(let i=0; i < cityList.length; i++){
                pointMarker(i)
            }
                // setMapMarker(marker)
            setMapComponent(map)
            resolve(map)
        })
    }
    const geocodePosition = (position) => {
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({ latLng: position }, (results, status) => {
            if (status == google.maps.GeocoderStatus.OK) {
                console.log(results[0])
            } else {
                console.log("Address not present")
            }
        })
    }

    const setMarker = (address) => {
        for(let i=0; i< address.length; i++){
            const geocoder = new google.maps.Geocoder()
            geocoder.geocode( { 'address': address[i]}, async function(results, status) {
                if (status == 'OK') {
                    console.log(results)
                    // if (mapMarker && mapMarker.setMap) {
                    //     mapMarker.setMap(null)
                    // }
                    // let thisMap = mapComponent
                    // if (!thisMap) {
                    //     thisMap = await initializeMap().catch(err => console.log(err))
                    // }
                    // thisMap.setCenter(results[0].geometry.location)
                    // marker = new google.maps.Marker({
                    //     map: thisMap,
                    //     position: results[0].geometry.location,
                    //     draggable: false,
                    // })
                    google.maps.event.addListener(marker, 'dragend', () => {
                        geocodePosition(marker.getPosition())
                    })
                    if (results && results.length > 0) {
                        // const area = getPlaceFromAddressComponents(results[0].address_components)
                        // console.log("area-----------------", area)
                    }
                    setMapMarker(marker)
                } else {
                    console.log('Geocode was not successful for the following reason: ' + status);
                }
            })
        }
    }

    // useImperativeHandle(ref, () => ({
    //     setMultipleMarker(places) {
    //         console.log(places)
    //         setMarker(places)
    //     }
    // }))
    return (
        <>
            <div id="map" style={{width: "100%", height: "380px", marginBottom: "40px"}}></div>
        </>
    )
})

export default MultiPointMap
