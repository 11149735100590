import React from 'react';
import logo from '../../assets/images/logo.svg';
import commonContext, { AppContext } from '../../common/context/common';
import { PublicContextInitiator } from "../apis/publicContextInitiator";
import { Validator } from "../../common/utils/validator";
 
type props={}
type state={}

export default class TestPage extends React.Component<props, state> {
  static contextType = commonContext;
  publicContextInitiator: PublicContextInitiator;
  validator: Validator;
  constructor(props: props) {
      super(props);
      this.state = {};
      this.validator = new Validator(()=>{console.log("")}, ()=>{console.log("")})
  }
  // async abc() {
  //   console.log("=========================789")
  //   let resp = await this.publicContextInitiator.getScheduledEvents(this.validator, {}, {})
  //   console.log("=========================", resp)
  //   return resp
  // }

  async componentDidMount() {
    // console.log("=========================123")
    this.publicContextInitiator = ((this.context as AppContext).apis as PublicContextInitiator)
    // console.log("=========================456", this.publicContextInitiator)
    // this.abc();
    
  }

  render() {
    return (
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <p>
            Edit <code>src/App.tsx</code> and save to reload. custom
          </p>
          <a
            className="App-link"
            href="https://reactjs.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn React
          </a>
        </header>
      </div>
    );
  }
}
