import {
    useContext,
    useState,
    useRef,
    useEffect
} from "react"

import { useHistory, useLocation } from "react-router-dom"

import ConfirmAlert from "../../../../../components/addon/confirmAlert"
import AmenityDetails from "../../components/amenityDetails/amenityDetails"
import AvailableFacilities from "../../components/availableFacilities/availableFacilities"
import Loader from "../../../../../components/addon/loader"

import { objectDeepClone, compareObjs, formatInventories } from "../../../../../service/helper"
import DataContext from "../../../../../service/contextProvider"

import config from "../../../../../service/config"
import { Steps } from "intro.js-react"
import AppContextObject from "../../../../../common/context/common"
import menu from "../../../../../assets/images/menu.svg"
import errorSVG from "../../../../../assets/images/error.svg"
interface Props {
    isEdit?: boolean,
    onProceed?: Function,
    toggleSidemenu ? : Function,
    showSidemenu ? : boolean
}

const OutdoorDetails = ({
    isEdit,
    onProceed,
    toggleSidemenu,
    showSidemenu
}: Props) => {

    const history = useHistory()
    const location = useLocation()
    const parentData = useContext<any>(DataContext)
    const context = useContext(AppContextObject)
    const refFacilities = useRef()
    const refAmenityDetail = useRef()

    const [updateCompleteState, setUpdateCompleteState] = useState(
        (parentData?.data?.schoolAmenities?.outdoorAmenities && parentData?.data?.schoolAmenities.outdoorAmenities.length > 0) ? true : false
    )

    const [showPrompt, setShowPrompt] = useState(false)
    const [overlay, setOverlay] = useState(false)
    const [formData, setFormData] = useState(parentData?.data?.schoolAmenities)
    const [oldFormData] = useState([...parentData?.data?.schoolAmenities?.outdoorAmenities])
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true)
    const [amenityList, setAmenityList] = useState([...parentData?.data?.schoolAmenities?.outdoorAmenities])

    const [showModal, setShowModal] = useState(false)
    const [remainingAmenities, setRemainingAmenities] = useState([])
    const [preSelectedClassroomAmenities, setPreSelectedClassroomAmenities] = useState(config.outdoorAmenities)
    const [showError, setShowError] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [inAddInventory, setInAddInventory] = useState(false)
    const [recheck, setRecheck] = useState(false)

    const [defaultAmenityObj] = useState({
        name: "",
        _checked: true,
        _custom: true,
        quantity: 1,
        data: [
          {
            name: "",
            images: [],
            customName: "",
            attr: {},
          },
        ]
    })

    const [loader, setLoader] = useState(false)
    const [masterList, setMasterList] = useState([])
    const [onBoarding1, setOnboarding1] = useState({
        stepsEnabled: false,
        initialStep: 0,
        steps: [
            {element: "#amenityDetail0", intro: "Click here to start adding inventory details on the Wisr platform", position: "right"},
        ],
        options: {
            exitOnOverlayClick: false,
            showStepNumbers: true,
            hidePrev: true,
            hideNext: false,
            nextLabel: "Next",
            prevLabel: "Prev",
            doneLabel: "Got it",
            autoPosition: false,
            exitOnEsc: false,
            scrollToElement: true,
            disableInteraction: true
        },
    })
    useEffect(() => {
        setTimeout(() => {
            setOnboarding1({...onBoarding1, stepsEnabled: parentData?.tooltipProgress?.inventoryDetail1})
        }, 100)
    }, [])

    useEffect(() => {
        if (parentData.listOfInventories && parentData.listOfInventories.length > 0) {
            setLoader(true)
            const finalList = formatInventories(parentData.listOfInventories, "outdoor")
            setMasterList(finalList)
            process.nextTick(() => {
                setLoader(false)
            })
        }        
        if (parentData.setActiveModule) {
            parentData.setActiveModule({
                module: "inventoryDetails",
                subModule: "outdoor"
            })
        }
        if(isEdit){
            parentData.data.schoolAmenities.outdoorAmenities.map(classroom=>{
                if(classroom._custom){
                    setPreSelectedClassroomAmenities(prev=>[
                        ...prev,
                        classroom
                    ])
                }
            })
        }
    }, [])

    useEffect(() => {
        let arr = []
        masterList.forEach((el) => {
            const found = amenityList.find((e) => e.name.toLowerCase().trim() === el.name.toLowerCase().trim())
            if (!found) {
                arr.push(el)
            }
        })
        setRemainingAmenities(arr)
    }, [amenityList])

    useEffect(() => {
        setFormData(parentData.data.schoolAmenities);
        setAmenityList([...parentData?.data?.schoolAmenities.outdoorAmenities])
        setUpdateCompleteState(true)
    }, [parentData.data.schoolAmenities.outdoorAmenities])

    const onAmenitiesChange = (newData, oldData) => {
        const obj1 = [...JSON.parse(newData)]
        const obj2 = [...JSON.parse(oldData)]
        const isSame = compareObjs(obj1, obj2)
        if (isSame === false) {
            setShowPrompt(true)
        } else {
            setShowPrompt(false)
        }
    }

    const onSubmit = async () => {
        setInAddInventory(true)
        let data = objectDeepClone(formData)
        let facilities = []
        let validatorObj = {}
        if (refAmenityDetail && refAmenityDetail.current) {
            const ref: any = refAmenityDetail.current
            facilities = ref.fetchAllAmenities()
            validatorObj = await ref.fetchValidation().catch(err => console.log(err))
            setAmenityList([...facilities])
        }
        if (Object.keys(validatorObj).length > 0) {
            setShowError(true)
            setErrorMessage("Please fill all fields.")
            return
        }
        data = {
            ...data,
            outdoorAmenities: facilities
        }
        console.log("data.outdoorAmenities",data.outdoorAmenities);
        let algoAllData = []
        let allInventoryData = [...data.classroomAmenities, ...data.commonAreaAmenities, ...data.otherRoomsAndStaffAmenities, ...data.outdoorAmenities]

        for(let amenity of allInventoryData){
            for(let amenityAttr of amenity.data){
                let algoData = {
                    placement: [],
                    cpi: [],
                    materialCost: [],
                    size: [],
                    unit: [],
                    quantity: [],
                    changes: []
                }
                let arr = Object.keys(amenityAttr.attr);
                console.log("amenity>>",amenity);
                // if(!amenity._custom){
                    algoData["parentInventory"] = amenityAttr.name.replace(/[0-9]/g, '').trim()
                    algoData["uniqueName"] = amenityAttr.name
                    for(let propertyName of arr){
                        let attribute = amenityAttr.attr[propertyName]
                        let length = Number(attribute.length) === 0
                        let breadth = Number(attribute.breadth) === 0 
                        let height = Number(attribute.height) === 0
                        let quantity = Number(attribute.quantity) === 0
                        
                        if(length && breadth && height && quantity){

                        }else{
                            // if(!attribute._isCustom){
                                let cat = parentData.data.algorithmData.category || "C"
                                let cpiCategory = !amenity._custom && !attribute._isCustom ? attribute.cpi.filter(element => element.category === cat) : [];
                                console.log("cpiCategory",cpiCategory);
                                !amenity._custom && !attribute._isCustom && algoData['cpi'].push(
                                    cpiCategory[0].value
                                )
                                // if(attribute.materialCost.length < 1){
                                //     algoData['materialCost'].push(1)
                                // }else{
                                //     algoData['materialCost'] = attribute.materialCost
                                // }
                                !amenity._custom && !attribute._isCustom && algoData['materialCost'].push(attribute.materialCost)
                                let length = Number(attribute.length) === 0 ? 1 : Number(attribute.length)
                                let breadth = Number(attribute.breadth) === 0 ? 1 : Number(attribute.breadth)
                                let height = Number(attribute.height) === 0 ? 1 : Number(attribute.height)
                                let size = length * breadth
                                algoData.size.push(size)
                                algoData.unit.push(attribute.units)
                                algoData.quantity.push(Number(attribute.quantity))
                                !amenity._custom && !attribute._isCustom && algoData.changes.push(Number(attribute.noOfChangesYearly))
                                algoData.placement.push(propertyName)
    
                            // }
                            // else{
                            //     algoData['cpi'].push(0)
                            //     algoData['materialCost'].push(1)
                            //     let length = Number(attribute.length) === 0 ? 1 : Number(attribute.length)
                            //     let breadth = Number(attribute.breadth) === 0 ? 1 : Number(attribute.breadth)
                            //     let height = Number(attribute.height) === 0 ? 1 : Number(attribute.height)
                            //     let size = height * length * breadth
                            //     algoData.size.push(size)
                            //     algoData.unit.push(attribute.units)
                            //     algoData.quantity.push(Number(attribute.quantity))
                            //     algoData.changes.push(1)
                            //     algoData["parentInventory"]= amenityAttr.name.replace(/[0-9]/g, '').trim()
                            //     algoData["uniqueName"] = amenityAttr.name
                            // }
                        }
                        
                    }
                    algoAllData.push(algoData)
                    let allSelectedInventoryArr = [];
                    let allAmenities = [...parentData.data.schoolAmenities.classroomAmenities, ...parentData.data.schoolAmenities.commonAreaAmenities, ...parentData.data.schoolAmenities.otherRoomsAndStaffAmenities, ...parentData.data.schoolAmenities.outdoorAmenities]
                    console.log("allAmenities--->",allAmenities);
                    allSelectedInventoryArr = parentData.data.algorithmData.inventories.filter(function (o1) {
                        return allAmenities.some(function (o2) {
                            return o2.data.find(data => {
                                return o2.name === o1.parentInventory && data.name === o1.uniqueName;
                            })
                        });
                    });
                    console.log("allSelectedInventoryArr",allSelectedInventoryArr);
            
                    let allAlgoInventories = [ ...algoAllData, ...allSelectedInventoryArr]
                    // let allAlgoInventories = [ ...algoAllData ]
            
                    console.log("allAlgoInventories",allAlgoInventories);
                    
                    let amdata = allAlgoInventories.filter((am, index, self) =>
                        index === self.findIndex((t) => (
                            t.parentInventory === am.parentInventory && t.uniqueName === am.uniqueName
                        ))
                    )
                    console.log("algoData>>>--",algoAllData, amdata);
                    if (parentData) {
                        if (parentData.setProgress) {
                            parentData.setProgress({
                                ...parentData.progress,
                                outdoor: true
                            })
                        }
                        if (parentData.setData) {
                            parentData.setData({
                                ...parentData.data,
                                schoolAmenities: data,
                                algorithmData: {
                                    ...parentData.data.algorithmData,
                                    inventories: amdata
                                }
                            })
                        }
                    }
            
                    let resp = await context.apis.public.getOnboardingRevenue(
                        context.validator, {
                            ...parentData.data.algorithmData,
                            inventories: amdata
                        }
                    )
                    if(resp && resp.done){
                        if(parentData && parentData.setNetRevenue){
                            parentData.setNetRevenue(resp.netRevenue || 0)
                        }
                        if(parentData.setPrevNetRevenue){
                            parentData.setPrevNetRevenue(resp.netRevenue || 0)
                        }
                    }
            
                    console.log("algo resp--",resp);
                // }
                // else{
                //     for(let propertyName of arr){
                //         let attribute = amenityAttr.attr[propertyName]
                //         algoData['cpi'].push(0)
                //         algoData['materialCost'].push(1)
                //         let length = Number(attribute.length) === 0 ? 1 : Number(attribute.length)
                //         let breadth = Number(attribute.breadth) === 0 ? 1 : Number(attribute.breadth)
                //         let height = Number(attribute.height) === 0 ? 1 : Number(attribute.height)
                //         let size = height * length * breadth
                //         algoData.size.push(size)
                //         algoData.unit.push(attribute.units)
                //         algoData.quantity.push(Number(attribute.quantity))
                //         algoData.changes.push(1)
                //         algoData["parentInventory"]= amenityAttr.name.replace(/[0-9]/g, '').trim()
                //         algoData["uniqueName"] = amenityAttr.name
                //     }
                // }
                
            }
            
        }
        

        setShowPrompt(false)
        setRecheck(true)
        // const isSummary = new URLSearchParams(location.search).get("isSummary")
        // process.nextTick(() => {
        //     if (isEdit === true) {
        //         if (isSummary === "true") {
        //             onProceed("/schools/school-details/details/update-details-summary")
        //         } else {
        //             onProceed("/schools/school-details/details/activities/student-life")
        //         }
        //     } else {
        //         if (isSummary === "true") {
        //             onProceed("/schools/onboarding/onboarding-summary")
        //         } else {
        //             onProceed("/schools/onboarding/events-and-occasions")
        //         }
        //     }
        // })
    }

    useEffect(() => {
        if(recheck===true && showPrompt===false) {
            const isSummary = new URLSearchParams(location.search).get("isSummary")
            process.nextTick(() => {
                if (isEdit === true) {
                    if (isSummary === "true") {
                        onProceed("/schools/school-details/details/update-details-summary")
                    } else {
                        onProceed("/schools/school-details/details/activities/student-life")
                    }
                } else {
                    if (isSummary === "true") {
                        onProceed("/schools/onboarding/onboarding-summary")
                    } else {
                        onProceed("/schools/onboarding/events-and-occasions")
                    }
                }
            })
        }
      },[recheck, showPrompt])
    const openModal = () => {
        setShowModal(true)
        setOverlay(true)
    }

    const confirmAlertCallback = (data) => {
        setOverlay(data.overlay)
    }

    const addNewSelectedAmenities = () => {
        if (refFacilities && refFacilities.current) {
            const ref: any = refFacilities.current
            const facilities = ref.fetchFacilities()

            const ref1: any = refAmenityDetail.current
            const existingFacilities = ref1.fetchAllAmenities()

            const arr = [...existingFacilities, ...facilities]
            arr.forEach((e) => {
                e.data.forEach((d, index) => {
                    d.name = e.name + " " + (index + 1)
                })
            })
            setAmenityList(arr)
            setOverlay(false)
            setShowModal(false)
            if (parentData) {
                if (parentData.setData) {
                    parentData.setData({
                        ...parentData.data,
                        schoolAmenities: {
                            ...parentData.data.schoolAmenities,
                            outdoorAmenities: arr
                        }
                    })
                }
            }
            // arr.forEach((e) => {
            //     e.data.forEach((d, index) => {
            //         d.name = e.name + " " + (index + 1)
            //     })
            // })
            // ref1.resetAmenityDetail()
        }
    }

    const closeWithoutSaving = () => {
        setShowModal(false)
        setOverlay(false)
    }

    const onCustomAmenityAdd = (amenityName, isEdit, index) => {
        if (isEdit === true) {
            const arr = objectDeepClone(amenityList)
            arr[index].name = amenityName
            setAmenityList(arr)
        } else {
            const json = objectDeepClone(defaultAmenityObj)
            json.name = amenityName
            json.data[0].name = amenityName + " 1"
            const arr = [...amenityList, json]
            setAmenityList(arr)
        }
    }

    const onCustomAmenityDelete = (index) => {
        const arr = objectDeepClone(amenityList)
        arr.splice(index, 1)
        setAmenityList(arr)
    }
    const hanldeOnboardingComplete1 = () => {
        parentData.setTooltipProgress({
            ...parentData.tooltipProgress,
            inventoryDetail1: false
        })
        let progress = {
            ...parentData.tooltipProgress,
            inventoryDetail1: false
        }
        localStorage.setItem("tooltipProgress", JSON.stringify(progress))
    }
    const exitOnBoarding1 = (e) => {
        console.log(e)
    }

    const updateParentErrorMsg = (msg, showErr) => {
        setErrorMessage(msg)
        setShowError(showErr)
    }

    return (
        <>
            <div className={`ovelap ${overlay === true ? "active" : ""}`}></div>
            {
                loader === true ?
                <Loader />
                :                
                <div className={isEdit === false ? "col-xl-6 col-lg-6 col-md-12 col-xs-12" : ""}>
                    <div className={isEdit === false ? "content mCustomScrollbar fixhight" : ""}>
                        <div className={isEdit === false ? "rightPart" : ""}>
                            {
                                isEdit === false &&
                                <>
                                    <a onClick={() => toggleSidemenu()} href='javascript:void(0)' className={`hummob ${showSidemenu === true ? "no_highlight" : ""}`}>
                                        <img src={menu} />
                                    </a>
                                    <h2 className="htx1 mb40">Outdoor Inventory Details</h2>
                                </>
                            }
                            <div className="row">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    {/* {
                                        <div className="inf-stru">
                                            <h3 className="htx2">Classroom specific
                                            <a
                                                href='javascript:void(0)'
                                                onClick={openModal}
                                                className='add-inve toggle'
                                                data-target='amenitiesModal'
                                            >
                                                + Add inventory
                                            </a>
                                            </h3>
                                        </div>
                                    } */}
                                    <AmenityDetails
                                        amenityList={amenityList}
                                        ref={refAmenityDetail}
                                        setIsSubmitDisabled={setIsSubmitDisabled}
                                        onCustomAmenityAdd={onCustomAmenityAdd}
                                        onCustomAmenityDelete={onCustomAmenityDelete}
                                        updateCompleteState={updateCompleteState}
                                        isEdit={isEdit}
                                        configData={masterList}
                                        openRemainingInventories={openModal}
                                        onAmenitiesChange={onAmenitiesChange}
                                        onBoarding={true}
                                        inAddInventory={inAddInventory}
                                        updateParentErrorMsg={updateParentErrorMsg}
                                    />
                                </div>
                                <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-20 end-xs'>
                                    <div className="bottom-error-btn">
                                        <p className="valError">
                                            {
                                                showError === true &&
                                                <>
                                                    <img src={errorSVG} />
                                                    {errorMessage}
                                                </>
                                            }
                                        </p>
                                        <div className='forminpt'>
                                            <input
                                                onClick={onSubmit}
                                                name='Proceed'
                                                value='Proceed'
                                                className='btn btn-orange sc-sch-spe'
                                                type='button'
                                                />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <div
                id='amenitiesModal'
                className={`popup ${showModal === false ? "hide" : ""}`}
            >
                <div className='popup-header'>
                    <h3 className='htx2'>Add New Inventory</h3>
                    <span
                        onClick={closeWithoutSaving}
                        className='close toggle'
                        data-target='amenitiesModal'
                        >
                    close
                    </span>
                </div>
                <div className='popup-body'>
                    <div className='facili-avai-in clearfix'>
                        <div className='row'>
                            {/* <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                                <h3 className='htx2'>Classroom Inventory</h3>
                            </div> */}
                            {
                                remainingAmenities.length > 0
                                &&
                                <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                                    <p className="ptx">Remaining inventory</p>
                                </div>
                            }  
                            <AvailableFacilities
                                ref={refFacilities}
                                hideSelectAll={true}
                                showModal={showModal}
                                availableFacilities={remainingAmenities}
                                selectedInventories={amenityList}
                                isModal={true}
                            />
                            <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-20 end-xs'>
                                <div className='forminpt'>
                                    <input
                                        onClick={addNewSelectedAmenities}
                                        name='Finish'
                                        value='Finish'
                                        className='btn btn-orange'
                                        type='button'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ConfirmAlert
                when={showPrompt}
                title="Leave page without saving..."
                cancelText="Cancel"
                okText="Confirm"
                onOK={() => true}
                onCancel={() => false}
                parentCallback={confirmAlertCallback}
            />
            <Steps
                enabled={onBoarding1.stepsEnabled}
                steps={onBoarding1.steps}
                initialStep={onBoarding1.initialStep}
                onComplete={hanldeOnboardingComplete1}
                onExit={exitOnBoarding1}
                options={onBoarding1.options}
            />
        </>
    )
}

export default OutdoorDetails
