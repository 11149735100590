import { useContext, useState } from "react";

import { useLocation, useRouteMatch, Link } from "react-router-dom";

import DataContext from "../../../../service/brandContext";
import logo from "../../../.././assets/images/logo.png";
import waveBootNew from "../../../.././assets/images/wavebotnew.png";
import check from "../../../../assets/images/check.svg";
import ConfirmDialog from "../../../../components/addon/confirmDialog/confirmDialog";
import AppContextObject from "../../../../common/context/common";
import { eraseAllCookies, eraseCookie } from "../../../../service/helper";

const CreateCampaignProgress = (props) => {
	const parentData = useContext<any>(DataContext);
	const context = useContext(AppContextObject);
	const location = useLocation();
	const router = useRouteMatch();
	const [overlay, setOverlay] = useState(false);
	const [toggleLogoutDialog, setToggleLogoutDialog] = useState(false);
	const [isLoggedOut, setIsLoggedOut] = useState(false);
	const [loading, setLoading] = useState(false);

	const toggleLogoutDialogBox = () => {
		setOverlay(true);
		setToggleLogoutDialog(true);
	};
	const confirmLogout = async (confirm) => {
		if (confirm) {
			saveCampaign();
			logoutUser();
		} else {
			setToggleLogoutDialog(false);
			setOverlay(false);
		}
	};
	const saveCampaign = async () => {
		let apiData = formateData();
		setLoading(true);
		if (parentData.progress.brandDetails) {
			if (apiData["_id"]) {
				console.log(apiData);
				let resp = await context.apis[context.user.role].editCampaign(
					context.validator,
					apiData
				);
				console.log("resp---", resp);
				if (resp && resp.done) {
					setLoading(false);
				}
			} else {
				let resp = await context.apis[context.user.role].addCampaign(
					context.validator,
					apiData
				);
				console.log("resp---", resp);
				if (resp && resp.done) {
					setLoading(false);
				}
			}
		}
	};
	const formateData = () => {
		let inventoriesIdArray = [];
		parentData.data.inventory.inventories.forEach((inventory) => {
			let attributesIdArray = [];
			inventory.placements &&
				Array.isArray(inventory.placements) &&
				inventory.placements.length > 0 &&
				inventory.placements.map((item) => {
					attributesIdArray.push(item.name);
				});
			let obj = {
				_id: inventory._id,
				name: inventory.name,
				attributes: [...attributesIdArray],
			};
			inventoriesIdArray.push(obj);
		});
		let eventIdArray = [];
		parentData.data.eventAndOccation.eventAndOccations.forEach((event) => {
			let attributesIdArray = [];
			event.placements &&
				Array.isArray(event.placements) &&
				event.placements.map((item) => {
					attributesIdArray.push(item._id || item.name);
				});
			let obj = {
				_id: event._id,
				name: event.name,
				attributes: [...attributesIdArray],
			};
			eventIdArray.push(obj);
		});
		let imageArray = [];
		if (parentData.data.campaignCreative.creatives.image1.key) {
			imageArray.push(parentData.data.campaignCreative.creatives.image1.key);
		}
		if (parentData.data.campaignCreative.creatives.image2.key) {
			imageArray.push(parentData.data.campaignCreative.creatives.image2.key);
		}
		if (parentData.data.campaignCreative.creatives.image3.key) {
			imageArray.push(parentData.data.campaignCreative.creatives.image3.key);
		}
		let obj = {
			brandId: parentData.data.brandDetail?.brandId,
			name: parentData.data.brandDetail?.name,
			description: parentData.data.brandDetail?.description,
			objective: parentData.data.brandDetail?.objective,
			startDate: parentData.data.brandDetail?.startDate,
			endDate: parentData.data.brandDetail?.endDate,
			locations: [],
			targetGender:
				parentData.data.brandDetail.targetGender === "both"
					? "co-ed"
					: parentData.data.brandDetail.targetGender,
			targetAgeGroup: [
				parentData.data.brandDetail.targetAgeGroup?.min,
				parentData.data.brandDetail.targetAgeGroup?.max,
			],
			studyMedium: [...parentData.data.brandDetail?.studyMedium],
			brandCategory: parentData.data.brandDetail.brandCategory?._id,
			brandSubcategory: parentData.data.brandDetail.brandSubcategory?.value,
			brandName: parentData.data.brandDetail?.brandName,
			recommendEvents: false,
			campaignStatus: "draft",
			isApproved: false,
			campaignCreatives: [...imageArray],
			budget: parentData.data.campaignDetail?.totalBudget,
			isTrial: parentData.data.brandDetail?.isTrial,
			wisrOffering: {
				inventory: parentData.data.brandDetail.inventory,
				eventAndOccations: parentData.data.brandDetail.eventAndOccations,
				csr: parentData.data.brandDetail.csr,
				sampling: parentData.data.brandDetail.sampling,
				custom: parentData.data.brandDetail.custom,
			},
			campaignCreationProgress: {
				progress: {
					...parentData.progress,
				},
				currentPage:
					parentData.progress.event === true
						? "event"
						: parentData.progress.recommendedSchool === true
						? "recommendedSchool"
						: parentData.progress.school === true
						? "school"
						: parentData.progress.inventory === true
						? "inventory"
						: parentData.progress.brandDetails === true && "brandDetails",
			},
			campaignData: {
				inventories: [...inventoriesIdArray],
				schools: [],
				activities: [...eventIdArray],
			},
		};
		if (parentData.data.brandDetail._id) {
			obj["_id"] = parentData.data.brandDetail?._id;
		}
		return obj;
	};
	const logoutUser = () => {
		const requestOptions = {
			method: "POST",
			headers: { "Content-Type": "application/json" },
		};
		setIsLoggedOut(true);
		fetch("/api/logout", requestOptions)
			.then((response) => response.json())
			.then((data) => {
				console.log("data>>>>>", data);
				if (data.done) {
					setToggleLogoutDialog(false);
					setOverlay(false);
					context.inactive = true;
					deleteUserLocation();
					setIsLoggedOut(false);
					localStorage.setItem("prevUserId", context.user._id);
					eraseAllCookies();
					eraseCookie("session-wisr-core-microservice-backend");
					context.socketManager.socket.disconnect();
					setOverlay(false);
					setToggleLogoutDialog(false);
					document.location.href = "/login";
					sessionStorage.clear();
				}
			})
			.catch((err) => {
				setIsLoggedOut(false);
				console.log(err);
			})
			.catch((err) => {
				setIsLoggedOut(false);
				console.log(err);
			});
	};
	const deleteUserLocation = async () => {
		let resp = await context.apis[context.user.role].deleteUserLocation(
			context.validator,
			{
				_id: sessionStorage.getItem("locationActivityId"),
			}
		);
	};
	return (
		<>
			<div className={`ovelap ${overlay === true && "active"}`}></div>
			<div className="col-xl-3 col-lg-3 col-md-12 col-xs-12 pl-0 pr-0">
				<div
					className={`sliderbar onboarding-bar newMvp-sidebar  campaignProgress ${
						parentData.responsiveSidemenu === true ? "no_highlight" : ""
					}`}
				>
					<div className="row logo between-xs bottom-xs logoNew">
						<img src={logo} alt="logo" />
					</div>
					{
						<ul>
							{!props.isCreativeRework && (
								<li>
									<Link
										to={{
											pathname: `${router.url}/set-up-campaign`,
											state: { from: "createCampaignFlow" },
										}}
										className={`${
											parentData.activeModule.module === "brandDetails" &&
											"active"
										}
                                            ${
																							parentData?.progress
																								?.brandDetails === true &&
																							"complete"
																						}`}
									>
										<span>1</span> SET UP CAMPAIGN
									</Link>
								</li>
							)}
							{!props.isCreativeRework && (
								<li>
									<Link
										to={{
											pathname: parentData?.data?.brandDetail?.inventory
												? `${router.url}/inventory`
												: `${router.url}/events`,
											state: { from: "createCampaignFlow" },
										}}
										className={`${
											parentData.activeModule.module === "campaignPlacement" &&
											"active"
										}
                                            ${
																							(parentData?.data?.brandDetail
																								?.inventory
																								? parentData?.progress
																										?.inventory === true
																								: true) &&
																							parentData?.progress?.school ===
																								true &&
																							parentData?.progress
																								?.recommendedSchool === true &&
																							(parentData?.data?.brandDetail
																								?.eventAndOccations
																								? parentData?.progress
																										?.event === true
																								: true) &&
																							"complete"
																						}
                                            ${
																							parentData?.progress
																								?.brandDetails === true
																								? ""
																								: "disabledLink"
																						}`}
									>
										<span>2</span> CAMPAIGN PLACEMENT
									</Link>
									{parentData.activeModule.module === "campaignPlacement" && (
										<ul>
											{parentData?.data?.brandDetail?.inventory && (
												<li>
													<Link
														to={{
															pathname: `${router.url}/inventory`,
															state: { from: "createCampaignFlow" },
														}}
														className={`${
															parentData.activeModule.subModule ===
																"inventory" && "active"
														}
                                                            ${
																															parentData
																																?.progress
																																?.inventory ===
																															true
																																? ""
																																: "disabledLink"
																														}
                                                        `}
													>
														Inventory
													</Link>
												</li>
											)}
											{parentData?.data?.brandDetail?.eventAndOccations && (
												<li>
													<Link
														to={{
															pathname: `${router.url}/events`,
															state: { from: "createCampaignFlow" },
														}}
														className={`${
															parentData.activeModule.subModule === "events" &&
															"active"
														}
                                                        ${
																													parentData?.progress
																														?.event === true
																														? ""
																														: "disabledLink"
																												}
                                                        `}
													>
														Events
													</Link>
												</li>
											)}
											<li>
												<Link
													to={{
														pathname: `${router.url}/schools`,
														state: { from: "createCampaignFlow" },
													}}
													className={`${
														parentData.activeModule.subModule === "schools" &&
														"active"
													}
                                                    ${
																											parentData?.progress
																												?.school === true &&
																											parentData?.progress
																												?.recommendedSchool ===
																												true
																												? ""
																												: "disabledLink"
																										}
                                                    `}
												>
													Schools
												</Link>
											</li>
											{/* custom link starts */}
											{/* <li>
												<Link
													to={{
														pathname: `${router.url}/custom-comments`,
														state: { from: "createCampaignFlow" },
													}}
													className={`${
														parentData.activeModule.subModule === "custom" &&
														"active"
													}
                                                    ${
																											parentData?.progress
																												?.school === true &&
																											parentData?.progress
																												?.recommendedSchool ===
																												true
																												? ""
																												: "disabledLink"
																										}
                                                    `}
												>
													Custom
												</Link>
											</li> */}
										</ul>
									)}
								</li>
							)}
							{/* {!props.isTrail && */}
							<li>
								<Link
									to={{
										pathname: `${router.url}/creative`,
										state: { from: "createCampaignFlow" },
									}}
									className={`${
										parentData.activeModule.module === "creative" && "active"
									}
                                            ${
																							parentData?.progress
																								?.campaignCreative === true &&
																							"complete"
																						}
                                                ${
																									parentData?.progress
																										?.campaignCreative === true
																										? ""
																										: "disabledLink"
																								}
                                                `}
								>
									<span>{props.isCreativeRework ? 1 : 3}</span> CAMPAIGN
									CREATIVE
								</Link>
							</li>
							{/* custom links starts */}

							{console.log("customLog", parentData)}

{/* for custom starts */}
							{ parentData.data.brandDetail.custom &&
							<>
							<li>
								<Link
									to={{
										pathname: `${router.url}/custom-comments`,
										state: { from: "createCampaignFlow" },
									}}
									className={`${
										parentData.activeModule.module === "custom" && "active"
									}
								
                                                    ${
																											parentData?.progress
																												?.customComments ===
																											true
																												? "complete"
																												: "disabledLink"
																										}
                                                    `}
								>
									<span>{4}</span>Custom
								</Link>
							</li>  <li>
									<Link
										to={{
											pathname: `${router.url}/preview`,
											state: { from: "createCampaignFlow" },
										}}
										className={`${
											parentData.activeModule.module === "preview" && "active"
										}
                                            ${
																							parentData?.progress
																								?.brandDetails === true &&
																							parentData?.progress
																								?.inventory === true &&
																							parentData?.progress?.school ===
																								true &&
																							parentData?.progress?.event ===
																								true &&
																							parentData?.progress
																								?.campaignCreative === true
																								? "complete"
																								: "disabledLink"
																						}
                                            `}
									>
										<span>{5}</span> CAMPAIGN PREVIEW
									</Link>
								</li>
								</>
}

{/* for custom ends */}



							{/* custom links ends */}
							{!props.isCreativeRework && !parentData.data.brandDetail.custom && (
								<li>
									<Link
										to={{
											pathname: `${router.url}/preview`,
											state: { from: "createCampaignFlow" },
										}}
										className={`${
											parentData.activeModule.module === "preview" && "active"
										}
                                            ${
																							parentData?.progress
																								?.brandDetails === true &&
																							parentData?.progress
																								?.inventory === true &&
																							parentData?.progress?.school ===
																								true &&
																							parentData?.progress?.event ===
																								true &&
																							parentData?.progress
																								?.campaignCreative === true
																								? "complete"
																								: "disabledLink"
																						}
                                            `}
									>
										<span>{4}</span> CAMPAIGN PREVIEW
									</Link>
								</li>
							)}
							<li>
								<a href="javascript:void(0);" onClick={toggleLogoutDialogBox}>
									<span>
										<svg
											width="16"
											height="18"
											viewBox="0 0 16 18"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											{" "}
											<path
												d="M13.0289 4.55556C14.0232 5.57522 14.7002 6.87422 14.9744 8.28833C15.2486 9.70245 15.1077 11.1682 14.5694 12.5001C14.0311 13.8321 13.1196 14.9706 11.9503 15.7715C10.7809 16.5725 9.40613 17 7.99978 17C6.59344 17 5.21867 16.5725 4.0493 15.7715C2.87994 14.9706 1.96849 13.8321 1.4302 12.5001C0.891907 11.1682 0.750948 9.70245 1.02514 8.28833C1.29934 6.87422 1.97638 5.57522 2.97065 4.55556"
												stroke="white"
												stroke-width="1.5"
												stroke-linecap="round"
												stroke-linejoin="round"
											/>{" "}
											<path
												d="M8 1V9"
												stroke="white"
												stroke-width="1.5"
												stroke-linecap="round"
												stroke-linejoin="round"
											/>{" "}
										</svg>
									</span>{" "}
									logout
								</a>{" "}
							</li>
						</ul>
					}
					<img src={waveBootNew} className="wavebot" alt="wave" />
				</div>
			</div>
			<ConfirmDialog
				togglePopup={toggleLogoutDialog}
				onModalClose={confirmLogout}
				componentName={""}
				confirmTitle="Your current page data would be lost. Are you sure you want to logout ?"
				confirmText="Logout"
				isLoading={loading}
			/>
		</>
	);
};

export default CreateCampaignProgress;
