import React, { useState, useEffect } from 'react'
import Pagination from '../../../../components/addon/pagination'
import { objectDeepClone, returnReadableStatus, titleCase } from '../../../../service/helper'
import errorSVG from "../../../../assets/images/error.svg"
import searchSvg from "../../../../assets/images/search.svg"
import editSvg from "../../../../assets/images/edit-btn.svg"
import toast from "react-hot-toast"
import { all } from 'axios'

interface Props {
    tableEditMode?: any,
    setTableEditMode?: any,
    allSchools?: any,
    formData?: any,
    setFormData?: any,
    schoolList?: any,
    wisrOption?: any,
    currentPage?: any,
    totalPage?: any,
    onPageChange?: any,
    onNextClick?: any,
    onPrevClick?: any,
    setSchoolList?: any,
    pageLoading?: any,
    dataPerPage?: any,
    showErrorMessage?: any,
    totalReach?: any,
    setTotalReach?: any,
    totalImpression?: any,
    setTotalImpression?: any,
    boysRatio?: any,
    setBoysRatio?: any,
    girlsRatio?: any,
    setGirlsRatio?: any,
    boysCount?: any,
    setBoysCount?: any,
    setGirlsCount?: any,
    girlsCount?: any,
    totalBudget?: any,
    setTotalBudget?: any,
    setOverlay?: any,
    schoolType?: any,
    setEventAndInventory? :any
}
const SchoolTable = ({
    tableEditMode,
    setTableEditMode,
    allSchools,
    formData,
    setFormData,
    schoolList,
    setSchoolList,
    wisrOption,
    currentPage,
    totalPage,
    onPageChange,
    onNextClick,
    onPrevClick,
    pageLoading,
    dataPerPage,
    showErrorMessage,
    totalReach,
    setTotalReach,
    totalImpression,
    setTotalImpression,
    boysRatio,
    setBoysRatio,
    girlsRatio,
    setGirlsRatio,
    girlsCount,
    boysCount,
    setBoysCount,
    setGirlsCount,
    totalBudget,
    setTotalBudget,
    setOverlay,
    schoolType,
    setEventAndInventory
}: Props) => {

    const[finalSortedSchoolList, setFinalSortedSchoolList] = useState([]);
    const [isInventoryAndEvent, setIsInventoryAndEvent] = useState(setEventAndInventory)

    useEffect(() => {
        let location = [], countBoys = 0, countGirls = 0, reach = 0;
            formData.schools.forEach(item => {
                if(item?.parentCity && !location.find(data => data.toLowerCase() === item.parentCity.toLowerCase())){
                    location.push(item?.parentCity)
                }
                countBoys += item.totalNoOfBoys
                countGirls += item.totalNoOfGirls
                reach += (schoolType === "both" ? (Number(item.totalNoOfBoys) + Number(item.totalNoOfGirls)) : schoolType === "boys" ? Number(item.totalNoOfBoys) : Number(item.totalNoOfGirls))
            })
            if(schoolType === "boys"){
                countGirls = 0
            }
            if(schoolType === "girls"){
                countBoys = 0
            }
            // setTotalReach(reach)
            setFormData({
                ...formData,
                locations: [...location]
            })
            setBoysCount(countBoys)
            setGirlsCount(countGirls)
            if((countBoys + countGirls) > 0){
                let ratioBoys = Math.ceil((countBoys * 100)/(countBoys + countGirls))
                let ratioGirls = Math.floor((countGirls * 100)/(countBoys + countGirls))
                setBoysRatio(ratioBoys)
                setGirlsRatio(ratioGirls)
            }
            else{
                
                setBoysRatio(0)
                setGirlsRatio(0)
            }
    }, [formData.schools])
    const removeOverLay = async() => {
        setTimeout(() => {
            setOverlay(false)
        }, 3000)
    }
    const addRemoveSchool = (e, item) => {
        let arr = formData.schools
        if(e.target.checked){
            let budget = totalBudget + item.totalBrandOutlay
            setTotalBudget(budget)
            arr.push(item)
            let reach = totalReach + (schoolType === "both" ? (Number(item.totalNoOfBoys) + Number(item.totalNoOfGirls)) : schoolType === "boys" ? Number(item.totalNoOfBoys) : Number(item.totalNoOfGirls))
            setTotalReach(reach)
            let impression = totalImpression + item.impressions
            setTotalImpression(impression)
           
            setFormData({
                ...formData,
                schools: [...arr],
            })
        }
        else{
            // calculateReachAndGenderSplit(item.totalReach, item.boysRatio, "remove")
            arr = arr.filter((school) => school._id !== item._id)
            let budget = totalBudget - item.totalBrandOutlay
            if(budget < 0){
                setOverlay(true)
                toast.error("Can not select school worth less than 0")
                removeOverLay()
                return
            }
            setTotalBudget(budget)
            let reach = totalReach - (schoolType === "both" ? (Number(item.totalNoOfBoys) + Number(item.totalNoOfGirls)) : schoolType === "boys" ? Number(item.totalNoOfBoys) : Number(item.totalNoOfGirls))
            setTotalReach(reach)
            let impression = totalImpression - item.impressions
            setTotalImpression(impression)
            setFormData({
                ...formData,
                schools: [...arr],
            })
        }
    }
    const shoolSelected = (_id) => {
       
        if(formData.schools && formData.schools.length > 0){
            let found = formData.schools.find(item => item._id === _id)
            if(found){
                return true
            }
            else return false
        }
        return false
    }


    const selectAllSchool = (e) => {
        if(schoolList.length === 0){
            return
        }
        if(e.target.checked){
            
            let location = objectDeepClone(formData.locations), school = objectDeepClone(formData.schools), reach = totalReach, impression = totalImpression, budget = totalBudget, countBoys = boysCount, countgirls = girlsCount;
            schoolList.forEach(item => {
               
                if(!school.find(data => data._id === item._id)){
                    school.push(item)
                    impression += item.impressions
                    budget += item.totalBrandOutlay
                    reach += (schoolType === "both" ? (Number(item.totalNoOfBoys) + Number(item.totalNoOfGirls)) : schoolType === "boys" ? Number(item.totalNoOfBoys) : Number(item.totalNoOfGirls))
                    
                }
            })
            setTotalBudget(budget)
            setTotalImpression(impression)
            setTotalReach(reach)
           
            setFormData({
                ...formData,
                schools: [...school],
               
            })
        }
        else{
            let school = objectDeepClone(formData.schools), reach = totalReach, impression = totalImpression, budget = totalBudget, countBoys = boysCount, countgirls = girlsCount;
            schoolList.forEach(item => {
                
                school = [...school.filter(data => data._id !== item._id)]
                let found = school.find(data => data._id === item._id)
               
                impression -= item.impressions
                budget -= item.totalBrandOutlay
                reach -= (schoolType === "both" ? (Number(item.totalNoOfBoys) + Number(item.totalNoOfGirls)) : schoolType === "boys" ? Number(item.totalNoOfBoys) : Number(item.totalNoOfGirls))
               
            })
            if(budget < 0){
                setOverlay(true)
                toast.error("Can not select school worth less than 0")
                removeOverLay()
                return
            }
            setTotalBudget(budget)
            setTotalImpression(impression)
            setTotalReach(reach)
            
            setFormData({
                ...formData,
                schools: [...school]
            })
        }
    }
    const checkAllSchoolSelected = () => {
        if(formData.schools.length === 0 || schoolList.length === 0){
            return false
        }
        let notFound = false
        schoolList.forEach(school => {
            let found = formData.schools.find(item => item._id === school._id)
            if(!found){
                notFound = true
            }
        })
        return !notFound
    }
    const handlePageChange = (page) => {
        console.log("in page click")

        onPageChange(page)
    }
    const handleNextClick = () => {
        console.log("in next click")
        onNextClick(currentPage + 1)
    }
    const handlePrevClick = () => {
        console.log("in prev click")

        onPrevClick(currentPage - 1)
    }
    const getUniqInventories = (inventories) => {
        let uniqInventories = []
        inventories.forEach(item => {
            if(!uniqInventories.find(data => data.name === item.name)){
                uniqInventories.push(item)
            }
        })
        return (
            uniqInventories.map((item, index) => (
                <span key={index}>{item.name}</span>
            ))
        )
    }

useEffect(()=>{
    sortingAllSchoolsBasedonSelection();
},[])

useEffect(()=>{
    sortingAllSchoolsBasedonSelection();
},[schoolList])

const sortingAllSchoolsBasedonSelection = ()=>{
    const uniqueInventoriesArray = [...schoolList].map((entry) => {
   
    // Create a set of unique names for inventories
    const uniqueInventoryNames = new Set(entry.inventories.map((item) => item.name));

    // Filter out duplicates based on 'name' property for inventories
    const uniqueInventoryArray = entry.inventories.filter((item, index, self) => 
    self.findIndex((i) => i.name === item.name) === index
    );

    // Create a set of unique names for events
    const uniqueEventNames = new Set(entry.events.map((event) => event.name));

    // Filter out duplicates based on 'name' property for events
    const uniqueEventArray = entry.events.filter(
      (event, index, self) => self.findIndex((e) => e.name === event.name) === index
    );

    return { ...entry, inventories: uniqueInventoryArray, events: uniqueEventArray };
});

  const sortedSchoolList = [...uniqueInventoriesArray].sort((a, b) => {
    // Sort by category (ascending)
    const categoryComparison = a.category.localeCompare(b.category, undefined, { numeric: true, sensitivity: 'base' });
    if (categoryComparison !== 0) {
      return categoryComparison;
    }
    return b.inventories.length - a.inventories.length;
    
  });


const selectedSchools = [];
const unselectedSchools = [];

// Iterate through schoolList and classify schools
sortedSchoolList.forEach(school => {
    if (shoolSelected(school._id)) {
      selectedSchools.push(school);
    } else {
      unselectedSchools.push(school);
    }
  });
  
  // Combine selected and unselected schools to get the final sorted list
   setFinalSortedSchoolList([...selectedSchools, ...unselectedSchools]);
}

  return (
    <>
        <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-20 pl pr">
            <div className="table-container mt-10 addAdmininput">
            <table className="table">
                <thead>
                    <tr>
                        {tableEditMode && 
                            <th scope="col" style={{width:"70px"}}>
                                <div className="checkbox fnone">
                                    <input type="checkbox" id="schoolsAll" checked={checkAllSchoolSelected()} onClick={(e) => selectAllSchool(e)}/>
                                    <label htmlFor="schoolsAll"></label>
                                </div>
                            </th>
                        }
                        <th scope="col" style={{width:"70px"}}>SR no</th>
                        <th scope="col">School name</th>
                        {
                            <>
                                {!tableEditMode && <th scope="col"><a href="javascript:void(0);" className="viewwLink proEdit" onClick={() => schoolList.length > 0 && setTableEditMode(true)}><img src={editSvg} /> Edit</a></th>}
                                {tableEditMode && <th scope="col">Reach</th>}
                                {
                                
                                // tableEditMode && <th scope="col">Impression</th>
                                !isInventoryAndEvent?.inventory && isInventoryAndEvent?.eventAndOccations &&
                                tableEditMode ?
                                ""
                                : tableEditMode &&

                                <th scope="col">Impression</th>
                                
                                
                                }
                                {tableEditMode && <th scope="col"><a href="javascript:void(0);" className="viewwLink proEdit" onClick={() => setTableEditMode(false)}> Cancel</a></th>}
                            </>
                        }
                        
                    </tr>
                </thead>
                <tbody>
                    
                   
                        {!pageLoading ? schoolList.length > 0 ? schoolList.map((school, index) => (
                        <tr key={school._id}>
                            {
                                <>
                                    {tableEditMode && <td>
                                        <div className="checkbox fnone">
                                            <input type="checkbox" id={school._id} checked={shoolSelected(school._id)} onClick={(e) => addRemoveSchool(e, school)}/> 
                                            <label htmlFor={school._id}></label>
                                        </div>
                                    </td>}
                                </>
                            }
                            
                            <td>
                                {
                            ((currentPage - 1) * dataPerPage) + (index + 1)
                            }
                            </td>
                            {
                                <td colSpan={!tableEditMode ? 2 : 1}>
                                    <div className="schoolNameTable">
                                        <h4>{`${titleCase(school.schoolName)} `} 
                                        
                                            <span>{` - Cat ${school.category}`}</span>
                                            
                                            <span>{titleCase(school.parentCity)}</span>
                                            {school.localArea && <span>{school.localArea}</span>}
                                        </h4>
                                        
                                        {school.inventories && school.inventories.length > 0 && getUniqInventories(objectDeepClone(school.inventories))}
                                        {school.events && school.events.length > 0 && school.events.map(item => (
                                            <span>{item.name}</span>
                                        ))}
                                        
                                    </div>
                                </td>
                            }
                            {
                                <>
                                {tableEditMode && 
                                        <td>
                                            {school.totalNoOfBoys !== undefined && school.totalNoOfBoys !== null 
                                            && school.totalNoOfGirls !== undefined && school.totalNoOfGirls !== null 
                                            ? schoolType === "both" ? (Number(school.totalNoOfBoys) + Number(school.totalNoOfGirls)) : schoolType === "girls" ? Number(school.totalNoOfGirls) : Number(school.totalNoOfBoys) : "-"}
                                        </td>
                                    }
                                    {
                                    !isInventoryAndEvent?.inventory && isInventoryAndEvent?.eventAndOccations &&
                                    tableEditMode ?
                                    ""
                                    : tableEditMode &&

                                        <td>
                                            {school.impressions || "-"}
                                        </td>


                                    }
                                    {tableEditMode && 
                                        <td>
                                        </td>
                                    }
                                </>
                            }
                            
                        </tr>
                    ))
                    :
                    <tr><td colSpan={tableEditMode ? 6 : 3}>No School found</td></tr>
                    :
                    [...Array(10)].map((el, index) => {
                        return (
                            <tr>
                                {
                                    [...Array(tableEditMode ? 6 : 3)].map((el, index) => {
                                        return (
                                            <td>
                                                <div className="content-loader-block"></div>
                                            </td>
                                        )
                                    })
                                }
                            </tr>
                        )
                    })
                }
                </tbody>
            </table>
            {showErrorMessage && <span className="valError"><img src={errorSVG} />Please select atleast one school.</span>}
            </div>
            {totalPage.length > 1 &&
                <Pagination
                    currentPage={currentPage}
                    totalPage={totalPage}
                    onPageChange={handlePageChange}
                    onNextClick={handleNextClick}
                    onPrevClick={handlePrevClick}
                    loading={pageLoading}
                />
            }
        </div>
    </>
  )
}

export default SchoolTable