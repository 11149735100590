import { useState, useEffect, useContext } from "react"
import { useHistory } from "react-router-dom"

import loginWave from "../../../assets/images/login-wave.svg"
import logo from "../../../assets/images/logo.png"
import AppContextObject from "../../../common/context/common"

const FosOnboardPage = () => {
    const history = useHistory()
    const context = useContext(AppContextObject)
    console.log("fosContext", context);
    // let mode = window.location.search?.substring(1).split("=")[1]
    // const [noActivity, setNoActivity] = useState(false)
    const [schoolLoggedIn, setSchoolLoggedIn] = useState(false)
    const [brandLoggedIn, setBrandLoggedIn] = useState(false)

    // useEffect(() => {
    //     if(mode && mode === "noActivity"){
    //         setNoActivity(true)
    //     }else{
    //         setNoActivity(false)
    //     }
    // }, [mode])

    const moveToOnboarding = () =>{
        history.push("/schools/onboarding/your-details")
    }

    const moveToSchoolLogin = () =>{
        // history.push("/login")
        if(context && context.user && context.user.role.startsWith("brand")){
            setBrandLoggedIn(true)
        }else{
            setBrandLoggedIn(false)
            window.location.href = '/login';
        }
    }

    const moveToBrandLogin = () =>{
        if(context && context.user && context.user.role.startsWith("school")){
            setSchoolLoggedIn(true)
        }else{
            window.location.href = '/brands/login';
            setSchoolLoggedIn(false)
        }
    }
    
    return (
        <>
            <div className="ovelap"></div>
            <div className="main-wapper">
                <div className="loginbox fospage">
                    <div className="login-wave"> <img src={loginWave} alt="login-wave" /> </div>
                    <div className="login-form">
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb10">
                                <img src={logo} alt="logo" />
                                <h2 className="htx1">Choose an Option</h2>
                                <div className="fosbtnmain">
                                    <div className="fosbtndiv">
                                        <a href="javascript:void(0);" onClick={moveToOnboarding} className="fosbtn">School Onboarding</a>
                                        <p>Click on School Onboarding, if you wish to onboard new school to WISR platform.</p>
                                    </div>
                                    <span className="fos-line"></span>
                                    <div className="fosbtndiv">
                                        <a href="javascript:void(0);" onClick={moveToSchoolLogin} className="fosbtn">Login/Update School</a>
                                        {
                                            brandLoggedIn
                                            &&
                                            <p className="valError">Your already logged in into brand, please log out from brand to proceed.</p>
                                        }
                                        <p>Click on Login/Update Details to update school details or validate/approve onboarded school details</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row fos-line-horizontal mt-20">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb10">
                                <div className="fosbtnmain">
                                    <div className="fosbtndiv">
                                        <a href="javascript:void(0);" onClick={moveToBrandLogin} className="fosbtn">Signup/Login Brand</a>
                                        {
                                            schoolLoggedIn
                                            &&
                                            <p className="valError">Your already logged in into school, please log out from school to proceed.</p>
                                        }
                                        <p>Click on Singup/Login to onboard a new brand or validate/approve onboarded brand details</p>
                                    </div>
                                    {/* <span className="fos-line"></span> */}
                                    {/* <div className="fosbtndiv">
                                        <a href="javascript:void(0);" onClick={moveToBrandLogin} className="fosbtn">Login/Update Brand</a>
                                        <p>Click on Login/Update Details to update brand details or validate/approve onboarded brand details</p>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        {/* {
                            noActivity && 
                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mt20">
                                <p className="fosNoActivityText">Whoops!, Looks like you're not there for long time.</p>
                            </div>
                        } */}
                    </div>
                </div>
            </div>
        </>
    )

}
export default FosOnboardPage
