import React, { useEffect, useState, useContext } from 'react';
import AppContextObject from "../../../../common/context/common";
import filterArrowSvg from "../../../../assets/images/filter-arrow-svg.svg";

const CategoryFilterUser = ({ setFilterCity, filterCity, setCurrentpage, tabRole }) => {
    const context = useContext(AppContextObject)
    const [countryArray] = useState([{ label: "Hyderabad", value: "hyderabad" }, { label: "Chennai", value: "chennai" }, { label: "Kolkata", value: "kolkata" }, { label: "Mumbai", value: "mumbai" }, { label: "Bengaluru", value: "bengaluru" }])
    const [spanCat, setSpanCat] = useState(false);
    const [cities, setCities] = useState([])

    useEffect(() => {
        backendUsersCities();
    }, [])
    const backendUsersCities = async () => {
        let resp = await context.apis.public.getAllCitiesByUser(
            context.validator, {
                role: tabRole === 'all' ? ['backendSales', 'backendOperations', 'backendFinance', 'backendTech', 'backendAdmin'] : tabRole === 'backendSales' ?
                    ['backendSales'] : tabRole === 'backendOperations' ? ['backendOperations'] : tabRole === 'backendFinance' ? ['backendFinance'] : tabRole === 'backendAdmin' ? ['backendAdmin'] : ['backendTech']
        }, {}
        )
        console.log('backendUsersCities===>', resp)
        if (resp && resp.done) {
            setCities(resp.response)
        }

    }


    const filterByCatCity = (city) => {
        setCurrentpage(1);
        let array = [...filterCity]
        if (array.includes(city)) {
            array.splice(array.indexOf(city), 1)
        }
        else {
            array.push(city)
        }
        setFilterCity([...array])
    }
    const clearAllFilter = () => {
        setFilterCity([]);
        setSpanCat(false);
    }
    console.log('sdg')

    return (
        <div className={`filterDropDown leftAlign open`}>
            <div className="filterDropHead">
                <h2>Filter <a href="javascript:void(0);" className="viewwLink" onClick={clearAllFilter}>Clear All</a></h2>
            </div>
            <div className="filterDropBody">
                <div className={`filterDropClose ${spanCat ? 'open' : ''}`}>
                    <h3><a href="javascript:void(0);" onClick={() => setSpanCat(!spanCat)} >{`Location`}  <img src={filterArrowSvg} /></a></h3>
                    <div className="filtercheckbox">
                        {cities && cities.map((item, index) => (
                            <div className="checkbox fnone" key={index} >
                                <input type="checkbox" id={item} checked={filterCity.includes(item)} />
                                <label htmlFor={item} onClick={() => filterByCatCity(item)}>{item}</label>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CategoryFilterUser;