import {
    useEffect,
    useState,
    useContext,
    useRef
} from "react"

import { useLocation } from "react-router-dom"
import { useHistory } from "react-router-dom"

import SimpleReactValidator from "simple-react-validator"

import AppContextObject from "../../../../../common/context/common"
import { PublicContextInitiator } from "../../../../apis/publicContextInitiator"

import menu from "../../../../../assets/images/menu.svg"

import Textfield from "../../../../../components/formFields/textField/textField"
import TextfieldWithPrefix from "../../../../../components/formFields/textFieldWithPrefix/textFieldWithPrefix"
import SingleSelect from "../../../../../components/formFields/singleSelect/singleSelect"
import OTPVerifyModal from "../../../../../components/addon/otpVerifyModal"
import ConfirmAlert from "../../../../../components/addon/confirmAlert"
import AlertDialog from "../../../../../components/addon/alertDialog/alertDialog"

import DataContext from "../../../../../service/contextProvider"

import { compareObjs, specialCharOnly, validateActName, validateFullName, validatePrincipalName, validateSchoolName} from "../../../../../service/helper"

import errorSVG from "../../../../../assets/images/error.svg"

import config from "../../../../../service/config"
import TextFieldWithValidation from "../../../../../components/formFields/textField/textFieldWithValidation"
import { formatDiagnosticsWithColorAndContext } from "typescript"
import TextFieldWithPrefix from "../../../../../components/formFields/textFieldWithPrefix/textFieldWithPrefix"

interface Props {
    isEdit ? : boolean,
    onProceed ? : any,
    toggleSidemenu ? : Function,
    showSidemenu ? : boolean,
    isBackendUser ? : boolean,
    backendData ? : any,
    showEditModal ? : boolean,
    schoolId ? : any,
    schoolUserId ? : any,
    setRefetch ? : any,
    refetch ? : any
}

const PersonalDetails = ({
    isEdit,
    onProceed,
    toggleSidemenu,
    showSidemenu,
    isBackendUser,
    backendData,
    showEditModal,
    schoolId,
    schoolUserId,
    setRefetch,
    refetch
}: Props) => {

    const location = useLocation()
    const history = useHistory()
    const [formValidator] = useState(useRef(new SimpleReactValidator({
        element: (message, className) =>
            <span className="valError">
                {console.log(message)}
                <img src={errorSVG} />
                {message==='The phone field is required.'
                ?
                'The contact number field is required.'
                :
                message==='The role field is required.'
                ?
                'The designation field is required.'
                :
                message
                }
            </span>
        })))
    // const [update, forceUpdate] = useState(false) // to re-render the dom after updating the validation

    const parentData = useContext <any> (DataContext)
    console.log("parentData--->>>",parentData)
    const context = useContext(AppContextObject)
    const [formData, setFormData] = useState(parentData.data.personalDetails)
    const [oldFormData, setOldFormData] = useState(parentData.data.personalDetails)
    const [showPrompt, setShowPrompt] = useState(false)
    const [update, forceUpdate] = useState(false)
    const otherActName = useRef()
    const principleNameRef = useRef()
    const principleEmailRef = useRef()
    const [fromFosPage, setFromFosPage] = useState(false)
    const [updateFormData, setUpdateFormData] = useState({
        fullName: "",
        username: "",
        email:"",
        phone:"",
        schoolName:"",
        registeredUnder: "",
        schoolPrincipalName: "",
        schoolPrincipalEmail: "",
        role:"",
    })
    const [registerData, setRegisterData] = useState({
        registeredUnder: ""
    })
    const [fillDetails, setFillDetails] = useState(false);
    const [isMobileOTP, setIsMobileOTP] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [selectedContactForOTP, setSelectedContactForOTP] = useState("");
    const [overlay, setOverlay] = useState(false)
    const [toggleAlertPopUp, setToggleAlertPopUp] = useState(false);
    const [detailsCaptured, setDetailsCaptured] = useState('');
    const [getFieldInfo, setGetFieldInfo] = useState('');
    const [getVerifyEmailStatus, setGetVerifyEmailStatus] = useState(false);
    const [getVerifyPhoneStatus, setGetVerifyPhoneStatus] = useState(false);
    const [getVerifyUsernameStatus, setGetVerifyUsernameStatus] = useState(false);
    const [msgDetails, setMsgDetails] = useState('');
    const [isEmailVerified, setIsEmailVerified] = useState(false);
    const [isPhoneVerified, setIsPhoneVerified] = useState(false);
    const [inProgressEmail, setInProgressEmail] = useState(null);
    const [inProgressPhone, setInProgressPhone] = useState(null);
    const [isUsernameVerified, setIsUsernameVerified] = useState(false);
    const [isUserCreated, setIsUserCreated] = useState(parentData.data.personalDetails.isUserCreated)
    const [usernameError, setUsernameError] = useState(false)
    const [recheck, setRecheck] = useState(false)
    const [currentEmail, setCurrentEmail] = useState("")
    const [currentPhone, setCurrentPhone] = useState("")
    const [fosEmailList, setFosEmailList] = useState([])
    const [fosPhoneList, setFosPhoneList] = useState([])
    const [fosOption, setFosOption] = useState("")
    const [fosUserList, setFosUserList] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    console.log("backendData--->>",backendData)
    const useForceUpdate = () => {
        const [value, setValue] = useState(0);
        return () => setValue(value => value + 1);
    }

    const forceUpdate1 = useForceUpdate();
    useEffect(() => {
        getFosData()
    }, [])

    const getFosData = async() => {
        const resp = await context.apis.public.getUsersListByRole(
            context.validator, {role: "backendFOS"}, {}
        )
        let phoneList = [], emailList = [];
        if(resp && resp.done && resp?.response?.rows.length > 0){
            resp?.response?.rows?.forEach(data => {
                phoneList.push(data.phone)
                emailList.push(data.email)
            })
            setFosEmailList([...emailList])
            setFosPhoneList([...phoneList])
            setFosUserList([...resp?.response?.rows])
        }
    }

    useEffect(() => {
        if (parentData.setActiveModule) {
            parentData.setActiveModule({
                ...parentData.activeModule,
                module: "yourDetails"
            })
        }
        if(parentData.data.personalDetails.email != "") {
            setIsEmailVerified(true)
            setGetVerifyEmailStatus(true)
        }
        else {
            setIsEmailVerified(false)
            setGetVerifyEmailStatus(false)
        }

        if(parentData.data.personalDetails.phone != "") {
            setIsPhoneVerified(true)
            setGetVerifyPhoneStatus(true)
        }
        else {
            setIsPhoneVerified(false)
            setGetVerifyPhoneStatus(false)
        }

        if(parentData.data.personalDetails.username != "") {
            setIsUsernameVerified(true)
            setGetVerifyUsernameStatus(true)
        } else {
            setIsUsernameVerified(false)
            setGetVerifyUsernameStatus(false)
        }

        if(isEdit === true){
            parentData?.setIsUpdated(false)
        }

        if(isEdit === true) {
            if(parentData.data.personalDetails.email !== "") {
                // setIsPhoneVerified(true);
                setIsEmailVerified(true);
            }
            if(parentData.data.personalDetails.phone !== "") {
                // setIsEmailVerified(true);
                setIsPhoneVerified(true);
            }
        }
    }, [])

    useEffect(()=>{
        // if(isBackendUser && showEditModal){
        //     setFormData({
        //         email: backendData.email || "",
        //         fullName: backendData.fullName || "",
        //         phone: backendData.phone || "",
        //         schoolPrincipalName: backendData.principalName || "",
        //         schoolPrincipalEmail: backendData.principalEmail || "",
        //         registeredUnder: backendData.registeredUnder || "",
        //         role: backendData.role || "",
        //         schoolName: backendData.schoolName || "",
        //         username: backendData.username || "",
        //         fosPhone: context.user.phone || "",
        //         fosEmail: context.user.email || "",
        //     })
        //     setOldFormData({
        //         email: backendData.email || "",
        //         fullName: backendData.fullName || "",
        //         phone: backendData.phone || "",
        //         schoolPrincipalName: backendData.principalName || "",
        //         schoolPrincipalEmail: backendData.principalEmail || "",
        //         registeredUnder: backendData.registeredUnder || "",
        //         role: backendData.role || "",
        //         schoolName: backendData.schoolName || "",
        //         username: backendData.username || "",
        //         fosPhone: context.user.phone || "",
        //         fosEmail: context.user.email || "",
        //     })
        //     setFosOption("email")
        // }else{
            setFormData(parentData.data.personalDetails)
        // }
    },[fillDetails, showEditModal, backendData])

    // console.log("personalDetails formData--->>>",formData);

    useEffect(()=>{
        if(parentData.data.personalDetails.email != "") {
            setIsEmailVerified(true)
            setGetVerifyEmailStatus(true)
        }
        else {
            setIsEmailVerified(false)
            setGetVerifyEmailStatus(false)
        }

        if(parentData.data.personalDetails.phone != "") {
            setIsPhoneVerified(true)
            setGetVerifyPhoneStatus(true)
        }
        else {
            setIsPhoneVerified(false)
            setGetVerifyPhoneStatus(false)
        }

        if(parentData.data.personalDetails.username != "") {
            setIsUsernameVerified(true)
            setGetVerifyUsernameStatus(true)
        } else {
            setIsUsernameVerified(false)
            setGetVerifyUsernameStatus(false)
        }

        if(isEdit === true){
            parentData?.setIsUpdated(false)
        }
        let json = {...parentData.data.personalDetails}
        if (!config.registerActs.find(e => e.value === json.registeredUnder) && json.registeredUnder !== "") {
            setRegisterData({registeredUnder: parentData.data.personalDetails.registeredUnder})
            json.registeredUnder = "other"
        }
        // if(isBackendUser && showEditModal){
        //     console.log("useEffect working",backendData, isBackendUser, showEditModal)
        //     setFormData({
        //         email: backendData.email || "",
        //         fullName: backendData.fullName || "",
        //         phone: backendData.phone || "",
        //         schoolPrincipalName: backendData.principalName || "",
        //         schoolPrincipalEmail: backendData.principalEmail || "",
        //         registeredUnder: backendData.registeredUnder || "",
        //         role: backendData.role || "",
        //         schoolName: backendData.schoolName || "",
        //         username: backendData.username || "",
        //         fosPhone: context.user.phone || "",
        //         fosEmail: context.user.email || "",
        //     })
        //     setOldFormData({
        //         email: backendData.email || "",
        //         fullName: backendData.fullName || "",
        //         phone: backendData.phone || "",
        //         schoolPrincipalName: backendData.principalName || "",
        //         schoolPrincipalEmail: backendData.principalEmail || "",
        //         registeredUnder: backendData.registeredUnder || "",
        //         role: backendData.role || "",
        //         schoolName: backendData.schoolName || "",
        //         username: backendData.username || "",
        //         fosPhone: context.user.phone || "",
        //         fosEmail: context.user.email || "",
        //     })
        //     setFosOption("email")
        // }else{
            setFormData(json)
        // }
    },[parentData.data.personalDetails, showEditModal, backendData])

    useEffect(() => {
        // if(isBackendUser){
        //     setIsEmailVerified(true)
        //     setGetVerifyEmailStatus(true)
        //     setIsPhoneVerified(true)
        //     setGetVerifyPhoneStatus(true)
        //     setGetVerifyUsernameStatus(true)
        //     setIsUsernameVerified(true)
        // }
      }, [showEditModal, backendData])

    useEffect(() => {
        const isSame = compareObjs(formData, oldFormData)

        if (isSame === false) {
            setShowPrompt(true)
        } else {
            setShowPrompt(false)
        }
    }, [formData])

    useEffect(() => {
      if(context && context.user && context.user.role){
          if(context.user.role == "backendFOS"){
              setFormData({
                  ...formData,
                  fosEmail : context.user.email,
                  fosPhone : context.user.phone,
              })
              setOldFormData({
                ...oldFormData,
                fosEmail : context.user.email,
                fosPhone : context.user.phone,
            })
          }else if(context.user.role == "backendAdmin"){
            setFormData({
                ...formData,
                fosEmail : parentData.backendFosDetails.email || "",
                fosPhone : parentData.backendFosDetails.phone || "",
            })
            setOldFormData({
              ...oldFormData,
              fosEmail : parentData.backendFosDetails.email || "",
              fosPhone : parentData.backendFosDetails.phone || "",
          })
        }
      }
      if(history.location.state){
        let data:any = history.location.state
        if(data.isFromFosTable){
        setFromFosPage(true)
        }
      }
    }, [])
    

    const onFormDataChange = (event) => {
        let foundFosUser;
        if(event.target.id === "fosPhone"){
            foundFosUser = fosUserList.find(data => data.phone === event.target.value)
        }
        if(event.target.id === "fosEmail"){
            foundFosUser = fosUserList.find(data => data.email === event.target.value)
        }
        if(event.target.id === "username"){
            setUsernameError(false)
            setIsUserCreated(false)
        }
        // if(event.target.id == "email") {
        //     if(formData.email != event.target.value) {
        //         if(event.target.parentNode.querySelectorAll(".vrifiyotp")[0].className.indexOf("complete") >= 0) {
        //             event.target.parentNode.querySelectorAll(".vrifiyotp")[0].className = event.target.parentNode.querySelectorAll(".vrifiyotp")[0].className.replace(/ complete/gi, "");
        //             setIsEmailVerified(false)                    
        //             setGetVerifyEmailStatus(false)
        //         }
        //         if (currentEmail === event.target.value && currentEmail != ""){
        //             setIsEmailVerified(true)
        //         }
        //     }
        //     if(isEdit) {
        //         if(parentData.data.personalDetails[event.target.id] === event.target.value){
        //             setIsEmailVerified(true)
        //         }
        //     }
        //     // else if(isBackendUser){
        //     //     if(oldFormData[event.target.id] === event.target.value){
        //     //         setIsEmailVerified(true)
        //     //         setGetVerifyEmailStatus(true)
        //     //     }
        //     // }
        // }
        // else if(event.target.id == "phone") {
        //     if(formData.phone != event.target.value) {
        //         if(event.target.parentNode.querySelectorAll(".vrifiyotp")[0].className.indexOf("complete") >= 0) {
        //             event.target.parentNode.querySelectorAll(".vrifiyotp")[0].className = event.target.parentNode.querySelectorAll(".vrifiyotp")[0].className.replace(/ complete/gi, "");
        //             setIsPhoneVerified(false)
        //             setGetVerifyPhoneStatus(false)
        //         }
        //         if (currentPhone === event.target.value && currentPhone != ""){
        //             setIsPhoneVerified(true)
        //         }
        //     }
        //     if(isEdit) {
        //         if(parentData.data.personalDetails[event.target.id] === event.target.value) {
        //             setIsPhoneVerified(true)
        //         }
        //     }
        //     // else if(isBackendUser){
        //     //     if(oldFormData[event.target.id] === event.target.value){
        //     //         setIsPhoneVerified(true)
        //     //         setGetVerifyPhoneStatus(true)
        //     //     }
        //     // }
        // } 
        else if(event.target.id == "username") {
            if(formData.username != event.target.value) {
                if(event.target.parentNode.querySelectorAll(".vrifiyotp")[0].className.indexOf("complete") >= 0) {
                    event.target.parentNode.querySelectorAll(".vrifiyotp")[0].className = event.target.parentNode.querySelectorAll(".vrifiyotp")[0].className.replace(/ complete/gi, "");
                    setIsUsernameVerified(false)
                    setGetVerifyUsernameStatus(false)
                }
            }
            if(isEdit){
                if(parentData.data.personalDetails[event.target.id] === event.target.value){
                    setIsUsernameVerified(true)
                    setGetVerifyUsernameStatus(true)
                }
            }
            // else if(isBackendUser){
            //     if(oldFormData[event.target.id] === event.target.value){
            //         setIsUsernameVerified(true)
            //         setGetVerifyUsernameStatus(true)
            //     }
            // }
        }
        if(foundFosUser){
            setFormData({ ...formData,
                [event.target.id]: event.target.value,
                fosUserId: foundFosUser._id
            })
        }
        else{
            setFormData({ ...formData,
                [event.target.id]: event.target.value
            })
        }
        if(event.target.id == "username"){
            setFormData({
                ...formData,
                username: event.target.value.toLowerCase().trim()
            })
        }

        // remove all leading and trailing spaces from textfields
        if(event.target.id == "email"){
            setFormData({
                ...formData,
                email: event.target.value.trim()
            })
        }
        if(event.target.id == "schoolPrincipalEmail"){
            setFormData({
                ...formData,
                schoolPrincipalEmail: event.target.value.trim()
            })
        }
        if(isEdit){
            if(parentData.data.personalDetails[event.target.id] === event.target.value){
                setUpdateFormData({...updateFormData, [event.target.id]: ""})
            }else{
                setUpdateFormData({...updateFormData, [event.target.id]: event.target.value})
            }
        }       
    }

    const confirmAlertCallback = (data) => {
        setOverlay(data.overlay)
    }

    // useEffect(() => {
    //     if (update === true) {
    //         formValidator.current.showMessages()
    //     }
    // }, [update])

    const submitForm = async(event) => {
        event.preventDefault()
        if(isEdit){
            setFormData({ ...formData,
                role: "Admin"
            })
        }

        const result = formValidator.current.allValid()
        let otherActFieldValid = true, validPrincipleName = true, validPrincipleEmail = true
        const otherActField:any = otherActName
        otherActFieldValid = otherActField?.current?.validateField()
        const principleName:any = principleNameRef
        validPrincipleName = principleName?.current?.validateField()
        const principleEmail:any = principleEmailRef
        validPrincipleEmail = principleEmail?.current?.validateField()

        if (result === false || otherActFieldValid === false || validPrincipleName === false || validPrincipleEmail === false) {
            formValidator.current.showMessages()
            // forceUpdate(true)
            forceUpdate1();
            return
        } else {
            formValidator.current.hideMessages()
            forceUpdate1();
            // forceUpdate(!update)
        }
        Object.keys(formData).map(k => formData[k] = typeof formData[k] == 'string' ? formData[k].trim().replace(/  +/g, ' ') : formData[k])
        if(isEdit){
            // if(isEmailVerified==false || isPhoneVerified==false) {
            //     setMsgDetails('You need to verify your email & mobile number to continue, Just click on verify to get OTP.')
            //     setOverlay(true)
            //     setToggleAlertPopUp(true)
            //     return
            // }
            if(getVerifyUsernameStatus === false){
                setMsgDetails('You need to verify your username to continue, Just click on verify to get verified.')
                setOverlay(true)
                setToggleAlertPopUp(true)
                return
            }
        }else{
            if(getVerifyUsernameStatus === false){
                setMsgDetails('You need to verify your username to continue, Just click on verify to get verified.')
                setOverlay(true)
                setToggleAlertPopUp(true)
                return
            }
            // if(getVerifyEmailStatus==false || getVerifyPhoneStatus==false) {
            // if(isEmailVerified==false || isPhoneVerified==false) {
            //     setMsgDetails('You need to verify your email & mobile number to continue, Just click on verify to get OTP.')
            //     setOverlay(true)
            //     setToggleAlertPopUp(true)
            //     return
            // }
        }

        if (parentData) {
            if (parentData.setProgress) {
                parentData.setProgress({
                    ...parentData.progress,
                    personalDetails: true,
                })
            }
            const json = {...formData, schoolName: formData.schoolName.trim()}

            if (json.registeredUnder === "other") {
                json.registeredUnder = registerData.registeredUnder.trim().replace(/  +/g, ' ')
                // setRegisterData({registeredUnder: ""})
            }
            if (parentData.setData) {
                parentData.setData({
                    ...parentData.data,
                    personalDetails: json,
                })
            }
        }


        // if(isBackendUser){
        //     if(formData.username !== oldFormData.username){
        //         let resp = await context.apis.public.findUserByUsername(
        //             context.validator, {"username": formData.username}
        //         )
        //         if(resp && resp.done === false){
        //             let found = resp.response.filter(element => element._id !== parentData.data.userId);
        //             if(found.length > 0){
        //                 formValidator.current.showMessages()
        //                 setUsernameError(null)
        //                 setGetVerifyUsernameStatus(false)
        //                 setIsUsernameVerified(false)
        //                 return
        //             }else{
        //                 setGetVerifyUsernameStatus(true)
        //                 setIsUsernameVerified(true)
        //                 setUsernameError(false)
        //             }
        //         }else{
        //             setGetVerifyUsernameStatus(true)
        //             setIsUsernameVerified(true)
        //             setUsernameError(false)
        //         }
        //     }
            
        // }else{
            let resp = await context.apis.public.findUserByUsername(
                context.validator, {"username": formData.username}
            )
            if(resp && resp.done === false){
                let found = resp.response.filter(element => element._id !== parentData.data.userId);
                if(found.length > 0){
                    formValidator.current.showMessages()
                    setUsernameError(null)
                    setGetVerifyUsernameStatus(false)
                    setIsUsernameVerified(false)
                    return
                }else{
                    setGetVerifyUsernameStatus(true)
                    setIsUsernameVerified(true)
                    setUsernameError(false)
                }
            }else{
                setGetVerifyUsernameStatus(true)
                setIsUsernameVerified(true)
                setUsernameError(false)
            }
        // }
        console.log("reached");
        // if(!isBackendUser){

            let data = {
                fullName: formData.fullName,
                username: formData.username,
                email: formData.email,
                phone: formData.phone,
                role: formData.role
            }
            
            // if(parentData.data.verifiedUsername !== formData.username){
                if(isEdit === false){
                    let addUserResp;
                    let updateUserResp;
                    if(parentData.data.userId === null){
                        if(formData.role == "Admin" || formData.role == "schoolAdmin"){
                            formData.role="schoolAdmin"
                            addUserResp = await context.apis.public.addUser(
                                context.validator, data
                            )
                        }
                        if(formData.role == "Principle/Trustee" || formData.role == "schoolPrincipal"){
                            formData.role="schoolPrincipal"
                            addUserResp = await context.apis.public.addUser(
                                context.validator, data
                            )
                        }
                        if(addUserResp && addUserResp.done){
                            parentData.setData({
                                ...parentData.data,
                                personalDetails: {
                                    ...formData,
                                    registeredUnder: formData.registeredUnder === "other" ? registerData.registeredUnder.trim().replace(/  +/g, ' ') : formData.registeredUnder,
                                    isUserCreated: true
                                },
                                verifiedUsername: formData.username,
                                userId: addUserResp.response._id
                            })
                            if(isEdit === false){
                                parentData.data.unsavedUserIds.push(addUserResp.response._id)
                            }
                            setIsUserCreated(true)
                            setOverlay(false)
                            setToggleAlertPopUp(false)
                        }
                    }else{
                        if(formData.role == "Admin" || formData.role == "schoolAdmin"){
                            formData.role="schoolAdmin"
                            updateUserResp = await context.apis.public.updateUserById(
                                context.validator, {_id: parentData.data.userId}, data
                            )
                        }
                        if(formData.role == "Principle/Trustee" || formData.role == "schoolPrincipal"){
                            formData.role="schoolPrincipal"
                            updateUserResp = await context.apis.public.updateUserById(
                                context.validator, {_id: parentData.data.userId}, data
                            )
                        }
                    }
            
                // }
            }

            if (isEdit === true) {
                let data = {}
                if (formData.schoolName !== parentData.data.personalDetails.schoolName) {
                    await context.apis[context.user.role].updateSchoolFields(
                        context.validator, {},{"schoolName": updateFormData.schoolName}
                    )               
                }
                // update school Admin/Principal separately
                if(formData.fullName !== parentData.data.personalDetails.fullName) {
                    Object.assign(data, {fullName: updateFormData.fullName});
                }

                if(formData.username !== parentData.data.personalDetails.username) {
                    Object.assign(data, {username: updateFormData.username});
                }

                if (formData.email !== parentData.data.personalDetails.email) {
                    Object.assign(data, {email: updateFormData.email});
                }

                if (formData.phone !== parentData.data.personalDetails.phone) {
                    Object.assign(data, {phone: updateFormData.phone});
                }

                await context.apis[context.user.role].updateUserDetails(context.validator, {}, data)
                setShowPrompt(false)
                setRecheck(true)
                // process.nextTick(() => {
                    // if (isSummary === "true") {
                    //     onProceed("/schools/school-details/details/update-details-summary")
                    // } else {
                    //     onProceed("/schools/school-details/details/school-details/general")
                    // }
                // })

            } else {
                setShowPrompt(false)
                setRecheck(true)
                // process.nextTick(() => {
                    // if (isSummary === "true") {
                        // onProceed("/schools/onboarding/onboarding-summary")
                    // } else {
                        // onProceed("/schools/onboarding/general-details")
                    // }
                // })
            }
        // }else{
        //     let apiData = {
        //         data: {
        //             ...parentData?.data,
        //             personalDetails:{
        //                 fullName: formData.fullName,
        //                 email: formData.email,
        //                 phone: formData.phone,
        //                 username: formData.username,
        //                 schoolPrincipalEmail: formData.schoolPrincipalEmail,
        //                 schoolPrincipalName: formData.schoolPrincipalName,
        //                 registeredUnder: formData.registeredUnder,
        //                 schoolId: schoolId,
        //                 schoolUserId: schoolUserId,
        //                 fosEmail: formData.fosEmail,
        //                 fosPhone: formData.fosPhone,
        //                 schoolName: formData.schoolName
        //             },
        //             schoolId: schoolId
        //         }
        //     }
        //     console.log("apiData>>",apiData)
        //     setIsLoading(true)
        //     let resp = await context.apis[context.user.role].updateSchoolDetails(
        //         context.validator, apiData
        //     )
        //     console.log("updateSchoolDetails resp",resp)
        //     if(resp && resp.done){
        //         setIsLoading(false)
        //         setRefetch(!refetch)
        //     }
        // }

    }

    useEffect(() => {
      if(recheck===true && showPrompt===false) {
        const isSummary = new URLSearchParams(location.search).get("isSummary")
          if(isEdit){
            process.nextTick(() => {
                if (isSummary === "true") {
                    onProceed("/schools/school-details/details/update-details-summary")
                } else {
                    onProceed("/schools/school-details/details/school-details/general")
                }
            })
          }
          else {
            process.nextTick(() => {
                if (isSummary === "true") {
                    onProceed("/schools/onboarding/onboarding-summary")
                } else {
                    onProceed("/schools/onboarding/general-details")
                }
            })
          }
      }
    },[recheck, showPrompt])

    const onVerifyEmailClick = async() => {
        const result = formValidator.current.fieldValid("email")
        if(result === false) {
            formValidator.current.showMessageFor("email")
            forceUpdate1();
            // forceUpdate(true)
            return
        } else {
            formValidator.current.hideMessageFor("email")
            forceUpdate1();
            // forceUpdate(!update)
        }

        if(isEmailVerified) {
            return
        }
        setInProgressEmail(true)
        let publicContextInitiator = new PublicContextInitiator(context)
        let resp = await publicContextInitiator.sendOTPOnEmail(
            context.validator, {"email":formData.email}
        )
            if(resp.done === false){
                setInProgressEmail(false)
                return
            }
        // if(!resp.response.userFound) {
            setIsMobileOTP(false)
            setSelectedContactForOTP(formData.email)
            setShowModal(true)
            setGetVerifyEmailStatus(true)
            setOverlay(false)
            setToggleAlertPopUp(false)
        // }
        // else {
        //     setGetFieldInfo("Email Id");
        //     setDetailsCaptured(formData.email);
        //     setGetVerifyEmailStatus(false)
        //     setMsgDetails(`The Email Id - ${formData.email} - entered already exists. Kindly use a different Email Id to register.`)
        //     setOverlay(true)
        //     setToggleAlertPopUp(true)
        // }
    }

    const onVerifyMobileClick = async() => {
        
        const result = formValidator.current.fieldValid("phone")
        if(result === false) {
            formValidator.current.showMessageFor("phone")
            forceUpdate1();
            // forceUpdate(true)
            return
        } else {
            formValidator.current.hideMessageFor("phone")
            forceUpdate1();
            // forceUpdate(!update)
        }

        if(isPhoneVerified) {
            return
        }
        setInProgressPhone(true)

        let publicContextInitiator = new PublicContextInitiator(context)
        let resp = await publicContextInitiator.sendOTPOnPhone(
            context.validator, {"phone":formData.phone}
        )
        if(resp.done === false){
            setInProgressPhone(false)
            return
        }

        // if(!resp.response.userFound) {
            setIsMobileOTP(true)
            setSelectedContactForOTP(formData.phone)
            setShowModal(true)
            setGetVerifyPhoneStatus(true)
            setOverlay(false)
            setToggleAlertPopUp(false)
        // }
        // else {
        //     setGetFieldInfo("Phone No.");
        //     setDetailsCaptured(formData.phone);
        //     setGetVerifyPhoneStatus(false)
        //     setMsgDetails(`The Phone No.- ${formData.phone} - entered already exists. Kindly use a different Phone No. to register.`)
        //     setOverlay(true)
        //     setToggleAlertPopUp(true)
        // }
    }

    const onVerifyUsernameClick = async() => {
        if(formData.username.length < 4){
            setUsernameError(undefined)
            formValidator.current.showMessageFor("username")
            return
        }
        const result = formValidator.current.fieldValid("username")
        if(result === false) {
            formValidator.current.showMessageFor("username")
            forceUpdate1();
            // forceUpdate(true)
            return
        } else {
            formValidator.current.hideMessageFor("username")
            forceUpdate1();
            // forceUpdate(!update)
        }

        // if(isBackendUser){
        //     if(formData.username !== oldFormData.username){
        //         let resp = await context.apis.public.findUserByUsername(
        //             context.validator, {"username": formData.username}
        //         )
        //         if(resp && resp.done === false){
        //             let found = resp.response.filter(element => element._id !== parentData.data.userId);
        //             if(found.length > 0){
        //                 formValidator.current.showMessageFor("username")
        //                 setUsernameError(true)
        //                 setGetVerifyUsernameStatus(false)
        //                 setIsUsernameVerified(false)
        //                 return
        //             }else{
        //                 setGetVerifyUsernameStatus(true)
        //                 setIsUsernameVerified(true)
        //                 setUsernameError(false)
        //             }
        //         }else{
        //             setGetVerifyUsernameStatus(true)
        //             setIsUsernameVerified(true)
        //             setUsernameError(false)
        //         }
        //     }
            
        // }else{
            let resp = await context.apis.public.findUserByUsername(
                context.validator, {"username": formData.username}
            )
            if(resp && resp.done === false){
                let found = resp.response.filter(element => element._id !== parentData.data.userId);
                if(found.length > 0){
                    formValidator.current.showMessageFor("username")
                    setUsernameError(true)
                    setGetVerifyUsernameStatus(false)
                    setIsUsernameVerified(false)
                    return
                }else{
                    setGetVerifyUsernameStatus(true)
                    setIsUsernameVerified(true)
                    setUsernameError(false)
                }
            }else{
                    setGetVerifyUsernameStatus(true)
                    setIsUsernameVerified(true)
                    setUsernameError(false)
            }
        // }
    }


    const onRoleChange = (data) => {
        const d = {...formData}
        if (data.value === "schoolPrincipal") {
            d.schoolPrincipalName = ""
            d.schoolPrincipalEmail = ""
        }
        d.role = data.value
        setFormData({...d})
    }

    const onModalSubmit = (otp) => {
        if(isMobileOTP) {
            setCurrentPhone(formData.phone)
            setIsPhoneVerified(true)
        }
        else {
            setCurrentEmail(formData.email) 
            setIsEmailVerified(true)
        }
    }

    const onOTPModalClose = () => {
        setInProgressEmail(false);
        setInProgressPhone(false);
    }

    const confirmClose = () => {
        setOverlay(false)
        setToggleAlertPopUp(false)
    }

    const onRegisterDataChange = (event) => {
        setRegisterData({ registeredUnder: event.target.value})
    }

    const onActChange = (data) => {
        setFormData({
            ...formData,
            registeredUnder: data.value
        })
    }

    let error = ""
    formValidator.current.rules['user_name'] = {
        message: error === "required" ? 'The user name field is required.' : usernameError ? "Username is already exists." : usernameError === null ? `${formData.username} username is been taken couple of seconds ago. Try another?` : usernameError === undefined ? 'Username must be minimum 4 characters long.' :'Username is invalid, Username can contain letters (a-z), numbers and spe. chars(_@.).',
        rule: (val) => {
            let returnValue = true
            if (!val || val.trim() === "") {
                error = "required"
                returnValue = false
            }
            if(val.trim().length < 4){
                error = ""
                setUsernameError(undefined)
                returnValue = false
            }
            
            const chars = "!#$%^&*()-{}[]+=<>?/,~'` "
            for (let i = 0; i < val.split("").length; i++) {
                const v = val.split("")[i]
                if (!v || v.trim() === " " || chars.split("").find(ch => ch === v)) {
                    error = ""
                    returnValue = false
                    break
                }
            }

            if(usernameError){
                error = ""
                returnValue = false
            }

            if(usernameError === null){
                error = ""
                returnValue = false
            }

            if(usernameError === undefined){
                error = ""
                returnValue = false
            }

            return returnValue
        },
    }
    formValidator.current.rules['valid_user_name'] = {
        message: 'Username can not contain only special character.',
        rule: (val) => {
            let returnValue = true
            if(!specialCharOnly(val)){
                returnValue = false
            }
            return returnValue
        }
    }
    formValidator.current.rules['full_name'] = {
        message: 'Please enter valid full name.',
        rule: (val) => {
            let returnValue = validateFullName(val)

            return returnValue
        },
    }
    let errormsg = '';
    formValidator.current.rules['phone'] = {
        message: 'The contact number must be a valid contact number.',
        rule: (val) => {
            let returnValue = true;
            if(val.length<10){
                returnValue = false
            }
            

            return returnValue
        },
    }
    formValidator.current.rules['fos_phone'] = {
        message: 'The fos phone number must be a valid phone number.',
        rule: (val) => {
            let returnValue = true;
            if(val.length<10){
                returnValue = false
            }
            

            return returnValue
        },
    }
    formValidator.current.rules['school_name'] = {
        message: 'Please enter valid school name.',
        rule: (val) => {
            let returnValue = validateSchoolName(val)

            return returnValue
        },
    }
    formValidator.current.rules['Principal_name'] = {
        message: 'Please enter valid principal name.',
        rule: (val) => {
            let returnValue = validatePrincipalName(val)

            return returnValue
        },
    }
    formValidator.current.rules['act_name'] = {
        message: 'Please enter valid act name.',
        rule: (val) => {
            let returnValue = validateActName(val)

            return returnValue
        },
    }
    formValidator.current.rules['max'] = {
        message: 'The act name must be less than 50 characters long.',
        rule: (val) => {
            let returnValue;
            if(val.length>50){
                returnValue = false
            }
            return returnValue
        },
    }
    formValidator.current.rules['same_name'] = {
        message: 'Admin and principal name can not be same.',
        rule: (val) => {
            let returnValue;
            if(formData.schoolPrincipalName.trim().replace(/  +/g, ' ').toLowerCase() === formData.fullName.trim().replace(/  +/g, ' ').toLowerCase()) {
                returnValue = false
            }else{
                returnValue = true
            }

            return returnValue
        },
    }
    formValidator.current.rules['validFosEmail'] = {
        message: "Invalid Fos email.",
        rule: (val) => {
            let returnValue;
            if(!fosEmailList.includes(val)){
                returnValue = false
            }
            else{
                returnValue = true
            }
            return returnValue
        }
    }
    formValidator.current.rules['validFosPhone'] = {
        message: "Invalid Fos phone number.",
        rule: (val) => {
            let returnValue;
            if(!fosPhoneList.includes(val)){
                
                returnValue = false
            }
            else{
                returnValue = true
            }
            console.log("fosPhoneList----", val, fosPhoneList);
            return returnValue
        }
    }


    return (
      <>
        <div className={`ovelap ${(showModal === true || overlay === true || showSidemenu === true) ? "active" : ""}`}></div>

        <AlertDialog
            togglePopup={toggleAlertPopUp}
            fieldInfo={getFieldInfo}
            details={detailsCaptured}
            onModalClose={confirmClose}
            displayMsg={msgDetails}
        />

        <div className={`${isEdit === false ? "content mCustomScrollbar fixhight" : ""}`}>
            <div className={`${isEdit === false ? "rightPart w80" : ""}`}>
                <form action='' id='formsubmit'>
                    <div className='row'>
                        {
                            isEdit === false &&
                            <div className={`col-xl-12 col-lg-12 col-md-12 col-xs-12`}>
                                <a onClick={() => toggleSidemenu()} href='javascript:void(0)' className={`hummob ${showSidemenu === true ? "no_highlight" : ""}`}>
                                    <img src={menu} />
                                </a>
                                <h2 className='htx1 mb40'>Tell us about yourself!</h2>
                            </div>
                        }
                        <div className={`col-xl-12 col-lg-12 col-md-12 col-xs-12 ${isEdit === true ? "pl" : ""}`}>
                            <Textfield
                                maxLength={50}
                                formData={formData}
                                formKey='fullName'
                                onDataChange={onFormDataChange}
                                label='Your Name'
                                placeholder='Enter Full Name'
                                isRequired={false}
                                formValidator={formValidator}
                                validation='required|full_name|same_name'
                            />
                        </div>
                        <div className={`col-xl-12 col-lg-12 col-md-12 col-xs-12 ${isEdit === true ? "pl" : ""}`}>
                        <Textfield
                                formData={formData}
                                formKey='username'
                                onDataChange={onFormDataChange}
                                label='Your Username'
                                placeholder='Username'
                                isRequired={false}
                                formValidator={formValidator}
                                // validation={isBackendUser ? 'required' : 'required|user_name'}
                                validation='required|user_name|valid_user_name'
                                onVerifyClick={onVerifyUsernameClick}
                                isVerified={isUsernameVerified}
                                maxLength={16}
                                isVerifyClickDisabled={formData.username.length < 1 ? true : false}
                            />
                        </div>
                        <div className={`col-xl-6 col-lg-6 col-md-6 col-xs-12 ${isEdit === true ? "pl" : ""}`}>
                            <Textfield
                                formData={formData}
                                formKey='email'
                                onDataChange={onFormDataChange}
                                label='Your Email ID'
                                placeholder='E.g. anju@abcschool.com'
                                isRequired={false}
                                // onVerifyClick={onVerifyEmailClick}
                                // isVerified={isEmailVerified}
                                inProgress={inProgressEmail}
                                formValidator={formValidator}
                                validation='required|email'
                                // isVerifyClickDisabled={formData.email.length < 1 ? true : false}
                            />
                        </div>
                        <div className={`col-xl-6 col-lg-6 col-md-6 col-xs-12`}>
                            <TextfieldWithPrefix
                                formData={formData}
                                formKey='phone'
                                onDataChange={onFormDataChange}
                                label='Your Contact Number'
                                placeholder='E.g. 99999 99999'
                                isRequired={false}
                                // onVerifyClick={onVerifyMobileClick}
                                // isVerified={isPhoneVerified}
                                formValidator={formValidator}
                                inProgress={inProgressPhone}
                                validation='required|phone'
                                // isVerifyClickDisabled={formData.phone.length < 1 ? true : false}
                            />
                        </div>
                        <div className={`col-xl-12 col-lg-12 col-md-12 col-xs-12 ${isEdit === true ? "pl" : ""}`}>
                            <Textfield
                                maxLength={50}
                                formData={formData}
                                formKey='schoolName'
                                onDataChange={onFormDataChange}
                                label="Your School’s Name"
                                placeholder='E.g. Delhi Public School'
                                isRequired={false}
                                formValidator={formValidator}
                                validation='required|school_name'
                            />
                        </div>
                        <div className={`col-xl-12 col-lg-12 col-md-12 col-xs-12 ${isEdit === true ? "pl" : ""}
                        ${formData.registeredUnder === "other" ? "" : ""}`}>
                            <SingleSelect
                                formData={formData}
                                formKey='registeredUnder'
                                onDataChange={onActChange}
                                label='Register Under'
                                placeholder='Select'
                                isRequired={false}
                                options={config.registerActs}
                                formValidator={formValidator}
                                validation='required'
                            />
                            {
                                formData.registeredUnder === "other" &&
                                // <Textfield
                                //     formData={registerData}
                                //     formKey='registeredUnder'
                                //     onDataChange={onRegisterDataChange}
                                //     placeholder='Enter Act Name'
                                //     isRequired={false}
                                //     formValidator={formValidator}
                                //     validation='required|act_name|max'
                                //     maxLength={50}
                                // />
                                <TextFieldWithValidation
                                    ref={otherActName}
                                    formData={registerData}
                                    formKey='registeredUnder'
                                    onDataChange={onRegisterDataChange}
                                    placeholder='Enter Act Name'
                                    isRequired={false}
                                    validation='required|act_name|max'
                                    maxLength={50}
                                    label="otherAct"
                                />
                            }
                        </div>
                        <div className={`col-xl-12 col-lg-12 col-md-12 col-xs-12 ${isEdit === true ? "pl" : ""}`} style={{display: isEdit ? 'none' : 'block'}}>
                            <SingleSelect
                                formData={formData}
                                formKey='role'
                                onDataChange={onRoleChange}
                                label='Your Designation'
                                placeholder='Select Your Designation'
                                isRequired={false}
                                options={config.schoolRoles}
                                formValidator={formValidator}
                                validation='required'
                            />
                        </div>
                        <div className={`col-xl-12 col-lg-12 col-md-12 col-xs-12 ${isEdit === true ? "pl" : ""}`}>
                            {/* <Textfield
                                maxLength={50}
                                formData={formData}
                                formKey='schoolPrincipalName'
                                onDataChange={onFormDataChange}
                                label="School Principal's Name"
                                placeholder="Enter Principal’s Full Name"
                                isRequired={false}
                                formValidator={formValidator}
                                validation={`${formData.role === "schoolPrincipal" ? "" : "required|Principal_name|same_name"}`}
                                isDisabled={formData.role === "schoolPrincipal" ? true : false}
                            /> */}
                            <TextFieldWithValidation
                                ref={principleNameRef}
                                maxLength={50}
                                formData={formData}
                                formKey='schoolPrincipalName'
                                onDataChange={onFormDataChange}
                                label="School Principal's Name"
                                placeholder="Enter Principal’s Full Name"
                                isRequired={false}
                                validation={`${formData.role === "schoolPrincipal" ? "" : "required|Principal_name|same_name"}`}
                                isDisabled={formData.role === "schoolPrincipal" ? true : false}
                                role={formData.role}
                                // label="principleName"
                            />
                        </div>
                        <div className={`col-xl-12 col-lg-12 col-md-12 col-xs-12 ${isEdit === true ? "pl" : ""}`}>
                            {/* <Textfield
                                formData={formData}
                                formKey='schoolPrincipalEmail'
                                onDataChange={onFormDataChange}
                                label="School Principal's Email ID"
                                placeholder="E.g. anju@abcschool.com"
                                isRequired={false}
                                smallDesc="*An invite will be shared with the school principal on this Email ID"
                                formValidator={formValidator}
                                validation={`${formData.role === "schoolPrincipal" ? "" : 'required|email'}`}
                                isDisabled={formData.role === "schoolPrincipal" ? true : false}
                            /> */}
                            <TextFieldWithValidation
                                ref={principleEmailRef}
                                formData={formData}
                                formKey='schoolPrincipalEmail'
                                onDataChange={onFormDataChange}
                                label="School Principal's Email ID"
                                placeholder="E.g. anju@abcschool.com"
                                isRequired={false}
                                smallDesc="*An invite will be shared with the school principal on this Email ID"
                                validation={`${formData.role === "schoolPrincipal" ? "" : 'required|email'}`}
                                isDisabled={formData.role === "schoolPrincipal" ? true : false}
                                role={formData.role}
                            />
                        </div>
                        {
                            (context && context.user && context.user.role && (context.user.role === "backendFOS")) || fromFosPage
                            ?
                            ""
                            :
                            <div className="forminpt checkboxinput">
                                {/* <label>Target  Audience</label> */}
                                <div className="checkboxinputList">
                                    <div className="radioBtn">
                                        <input type="radio" id="emailOption" name="radio-group" checked={fosOption === "email"} onClick={(e: any) => setFosOption("email")}/>
                                        <label htmlFor="emailOption">FOS Email ID</label>
                                    </div> 
                                    <div className="radioBtn">
                                        <input type="radio" id="phoneOption" name="radio-group" checked={fosOption === "phone"} onClick={(e: any) => setFosOption("phone")}/>
                                        <label htmlFor="phoneOption">FOS Phone Number</label>
                                    </div>
                                        
                                </div>    
                            </div>
                        }
                        
                        <div className={`col-xl-12 col-lg-12 col-md-12 col-xs-12 ${isEdit === true ? "pl" : ""}`}>
                            <Textfield
                                formData={formData}
                                formKey='fosEmail'
                                onDataChange={onFormDataChange}
                                label="Fos Email ID"
                                placeholder="E.g. anju@abcschool.com"
                                isRequired={false}
                                formValidator={formValidator}
                                validation={fosOption === "email" ? 'email|validFosEmail' : ""}
                                isDisabled={fosOption === "email" ? false : true}
                            />
                        </div>
                        <div className={`col-xl-12 col-lg-12 col-md-12 col-xs-12 ${isEdit === true ? "pl" : ""}`}>
                            <TextFieldWithPrefix
                                formData={formData}
                                formKey='fosPhone'
                                onDataChange={onFormDataChange}
                                label="Fos Phone Number"
                                placeholder='E.g. 99999 99999'
                                isRequired={false}
                                formValidator={formValidator}
                                validation={fosOption === "phone" ? 'fos_phone|validFosPhone' : ""}
                                isDisabled={fosOption === "phone" ? false : true}
                            />
                        </div>
                        <div className='row col-xl-12 col-lg-12 col-md-12 col-xs-12 end-md'>
                            {/* {
                                isBackendUser 
                                ?
                                <div className="forminpt fR row">
                                    <a href="javascript:void(0);" onClick={submitForm} className={isLoading ? "btn btn-orange button--loading btn-disabled" : "btn btn-orange" } >
                                    Save
                                    </a>
                                </div>
                                : */}
                            <div className='forminpt'>
                                <input
                                    onClick={submitForm}
                                    name='Proceed'
                                    value='Proceed'
                                    className='btn btn-orange datasubmit'
                                    type='button'
                                />
                            </div>
                            {/* } */}
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <OTPVerifyModal
            contact={selectedContactForOTP}
            isMobile={isMobileOTP}
            showModal={showModal}
            setShowModal={setShowModal}
            onModalSubmit={onModalSubmit} 
            onClose={onOTPModalClose}
            isMobileAndEmail = {false}         
        />

        <ConfirmAlert
            when={showPrompt}
            title="Leave page without saving..."
            cancelText="Cancel"
            okText="Confirm"
            onOK={() => true}
            onCancel={() => false}
            parentCallback={confirmAlertCallback}
        />
    </>
  )
}

export default PersonalDetails
