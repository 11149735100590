import React, { useState, useEffect, useContext, useRef } from 'react'
import { Link, useHistory } from "react-router-dom"
import AppContextObject from "../../../common/context/common";
import tableFilter from "../../../assets/images/table-filter.svg"
import arrowUpDown from "../../../assets/images/backendAdmin/arrow-up-down.svg";
import BackendTopBar from './components/backendTopBar';
import CustomSearchInput from '../../../components/formFields/customSearchInput';
import { objectDeepClone, titleCase } from '../../../service/helper';
import CampaignStatusFilter from './components/campaign/CampaignStatusFilter';
import toast from 'react-hot-toast';
import Pagination from "../../../components/addon/pagination"
import ConfirmDialog from '../../../components/addon/confirmDialog/confirmDialog';
import FosListComponent from './components/fosListComponent';
import useClickOutside from '../../../service/useClickOutside';
import AssignToFilter from './components/assignToFilter';
import CityFilter from './components/cityFilter';

const AssignSchoolsToFos = () => {
    let history = useHistory()
    const context = useContext(AppContextObject)
    // const fosListRef = useRef()
    // const editFosListRef = useRef()

    const [dataPerPage] = useState(25)
    const [campaignId, setCampaignId] = useState()
    const [totalPage, setTotalPage] = useState([0]);
    const [currentPage, setCurrentpage] = useState(1);
    const [cityList, setCityList] = useState([])
    const [openCityFilter, setOpenCityFilter] = useState(false)
    const [openAssignToFilter, setOpenAssignToFilter] = useState(false)
    const [openLocalAreaFilter, setOpenLocalAreaFilter] = useState(false)
    const [cityFilter, setCityFilter] = useState([])
    const [assignToFilter, setAssignToFilter] = useState([])
    const [searchValue, setSearchValue] = useState('')
    const [allSchools, setAllSchools] = useState([])
    const [schoolsToAssign, setSchoolsToAssign] = useState([])
    const [allFosList, setAllFosList] = useState([])
    const [overlay, setOverlay] = useState(false)
    const [allSchoolsCount, setAllSchoolsCount] = useState(0)
    const [assignSchoolsCount, setAssignSchoolsCount] = useState(0)
    const [notAssignSchoolsCount, setNotAssignSchoolsCount] = useState(0)
    const [activeTab, setActiveTab] = useState('all')
    const [searchInputOpen, setSearchInputOpen] = useState(false)
    const [clearSearchIcon, setClearSearchIcon] = useState(false)
    const [selectedSchools, setSelectedSchools] = useState([])
    const [fosList, setFosList] = useState([])
    const [showFosList, setShowFosList] = useState(false)
    const [showSingleSchoolFosList, setShowSingleSchoolFosList] = useState(false)
    const [refetch, setRefetch] = useState(false)
    const [assignFosPopup, setAssignFosPopup] = useState(false)
    const [assignSingleSchoolFosPopup, setAssignSingleSchoolFosPopup] = useState(false)
    const [loading, setLoading] = useState(false)
    const [selectedFos, setSelectedFos] = useState({_id: "", name: ""})
    const [showEditFosList, setShowEditFosList] = useState(false)
    const [editFosActiveIndex, setEditFosActiveIndex] = useState(-1)
    const [activeFos, setActiveFos] = useState({_id: '', name: ''})
    const [pageView, setPageView] = useState('')
    const [fosName, setFosName] = useState("")
    const [editSchool, setEditSchool] = useState<any>({})
    const [editAssignFosPopup, setEditAssignFosPopup] = useState(false)
    // const [fosDetail, setFosDetail] = useState({name: "", _id: ""})

    useEffect(() => {
        let state: any = history.location.state
        console.log("history.location.state", history.location.state)
        if(state && state.pageView && state.fosName && state.fosId){
            console.log("history.location.state.values", state.pageView, state.fosName, state.fosId)
            setPageView(state.pageView)
            setFosName(state.fosName)
            setAssignToFilter([state.fosId])
        }
        else{
            setPageView('assign')
        }
        if(state && state.campaignId){
            setCampaignId(state.campaignId)
            getCampaignSchoolList(state.campaignId)
        }
    }, [])
    useEffect(() => {
        if(campaignId){
            getCampaignSchoolList()
        }
    }, [cityFilter, searchValue, activeTab, refetch, assignToFilter, currentPage])
    useEffect(() => {
        setCurrentpage(1)
    }, [cityFilter, searchValue, activeTab, refetch, assignToFilter])
    useEffect(() => {
        getFosData()
    }, [])

    const getFosData = async() => {
        const resp = await context.apis.public.getUsersListByRole(
            context.validator, {role: "backendFOS"}
        )
        console.log("getUsersListByRole", resp)
        if(resp && resp.done && resp?.response?.rows.length > 0){
            console.log("getUsersListByRole", resp)
            setFosList([...resp.response.rows])
        }
    }

    const getCampaignSchoolList = async(_id?) => {
        setLoading(true)
        let state: any = history.location.state
        let resp
        if(state.fosId){
            resp = await context.apis[context.user.role].getCampaignSchoolAssignmentsByCampaign(
                context.validator, {campaignId: _id || campaignId, city: [...cityFilter], fosIds: [state.fosId, ...assignToFilter], searchValue: searchValue}, {page: currentPage, limit: 25, withCount: true}
            )
        }
        else{
            resp = await context.apis[context.user.role].getCampaignSchoolAssignmentsByCampaign(
                context.validator, {campaignId: _id || campaignId, city: [...cityFilter], fosIds: [...assignToFilter], searchValue: searchValue}, {page: currentPage, limit: 25, withCount: true}
            )
        }
        console.log("getCampaignSchoolAssignmentsByCampaign", resp)
        if(resp && resp.done){
            let totalCount = 0, assignCount = 0, notAssignCount = 0, schools = [], assignSchools = [], notAssignSchools = [];
            resp.campaignSchoolAssignments.forEach((item, index) => {
                if(item.schools && item.schools.length){
                    totalCount += item.schools.length
                    assignCount += item.schools.length
                    item.schools.forEach((school, index) => {
                        schools.push({...school, assignedTo: {fosId: item.fosId, name: item.name, _id: item._id}})
                        assignSchools.push({...school, assignedTo: {fosId: item.fosId, name: item.name, _id: item._id}})
                    })
                }
            })
            schools = schools.sort((a, b) => a.schoolName.toLowerCase() > b.schoolName.toLowerCase() ? 1 : -1)
            assignSchools = assignSchools.sort((a, b) => a.schoolName.toLowerCase() > b.schoolName.toLowerCase() ? 1 : -1)
            if(resp.campaignSchools && resp.campaignSchools.length > 0 && resp.campaignSchools[0].schools){
                totalCount += resp.campaignSchools[0].schools.length
                notAssignCount += resp.campaignSchools[0].schools.length
                // schools = [...schools, ...resp.campaignSchools[0].schools]
                notAssignSchools = [...resp.campaignSchools[0].schools]
            }
            notAssignSchools = notAssignSchools.sort((a, b) => a.schoolName.toLowerCase() > b.schoolName.toLowerCase() ? 1 : -1)
            schools = [...schools, ...notAssignSchools]
            let cities = []
            resp.getAllCampaignAssignmentsBySchool && resp.getAllCampaignAssignmentsBySchool.length && resp.getAllCampaignAssignmentsBySchool.forEach(item => {
                item.schools && item.schools.length && item.schools.forEach(data => {
                    if(data.city && !cities.find(el => el.value === data.city)){
                        // console.log("cities.includes(data.city)", cities.includes(data.city), cities, data.city)
                        cities.push({value: data.city, label: data.city})
                    }
                })
            })
            setCityList([...cities])
            if(activeTab === "all"){
                setAllSchools([...schools])
                let total = Math.ceil(Number(totalCount)/dataPerPage)
                let arr = []
                for(let i=0; i<total; i++){
                    arr.push(i)
                }
                setTotalPage([...arr])
            }
            else if(activeTab === "assign"){
                setAllSchools([...assignSchools])
                let total = Math.ceil(Number(assignCount)/dataPerPage)
                let arr = []
                for(let i=0; i<total; i++){
                    arr.push(i)
                }
                setTotalPage([...arr])
            }
            else if(activeTab === "notAssign"){
                setAllSchools([...notAssignSchools])
                let total = Math.ceil(Number(notAssignCount)/dataPerPage)
                let arr = []
                for(let i=0; i<total; i++){
                    arr.push(i)
                }
                setTotalPage([...arr])
            }
            setAssignSchoolsCount(assignCount)
            setNotAssignSchoolsCount(notAssignCount)
            setAllSchoolsCount(totalCount)
            setLoading(false)
        }
        setLoading(false)
    }

    const selectAllSchool = (e) => {
        if(allSchools.length === 0){
            return
        }
        if(e.target.checked){
            let school = objectDeepClone(selectedSchools)
            allSchools.forEach(item => {
                // if(item?.city && !location.find(data => data.toLowerCase() === item.city.toLowerCase())){
                //     location.push(item?.city)
                // }
                if(!school.find(data => data._id === item._id)){
                    if(!item.hasOwnProperty('assignedTo')){
                        school.push(item)
                    }
                }
            })
            setSelectedSchools([...school])
        }
        else{
            let school = objectDeepClone(selectedSchools)
            allSchools.forEach(item => {
                school = [...school.filter(data => data._id !== item._id)]
            })
            setSelectedSchools([...school])
        }
    }
    const checkAllSchoolSelected = () => {
        if(selectedSchools.length === 0 || allSchools.length === 0){
            return false
        }
        let notFound = false
        allSchools.forEach(school => {
            let found = selectedSchools.find(item => item._id === school._id)
            if(!found && !school.assignedTo){
                notFound = true
            }
        })
        return !notFound
    }
    const addRemoveSchool = (e, item) => {
        let arr = objectDeepClone(selectedSchools)
        if(e.target.checked){
            arr.push(item)
            setSelectedSchools([...arr])
        }
        else{
            arr = arr.filter((school) => school._id !== item._id)
            setSelectedSchools([...arr])
        }
    }
    const shoolSelected = (_id) => {
        if(selectedSchools && selectedSchools.length > 0){
            let found = selectedSchools.find(item => item._id === _id)
            if(found){
                return true
            }
            else return false
        }
        return false
    }
    const handleSearch = (e) => {
        if(e === ""){
            setSearchValue("")
        }
        else{
            setSearchValue(e.target.value)
        }
    }

    const assignSchoolsToFos = async(confirm) => {
        if(confirm){
            let resp 
            if(selectedSchools && selectedSchools.length && selectedSchools[0].assignedTo){
                console.log('selectedSchoools', selectedSchools)
                resp = await context.apis[context.user.role].updateCampaignSchoolAssignment(
                    context.validator, {fosId: selectedFos._id, campaignId: campaignId}, {schools: selectedSchools.map(item => item._id)}
                )
            }
            else{
                resp = await context.apis[context.user.role].addCampaignSchoolAssignment(
                    context.validator, {fosId: selectedFos._id, campaignId: campaignId , schools: selectedSchools.map(item => item._id)}
                )
            }
            if(resp && resp.done){
                toast.success("Schools assigned to fos successfully")
                setSelectedSchools([])
                setSelectedFos({_id: "", name: ""})
                setShowFosList(false)
                setAssignFosPopup(false)
                setOverlay(false)
                setRefetch(!refetch)
            }
            console.log("addCampaignSchoolAssignment", resp, {fosId: "62a2fa0cb948c200088cb890", campaignId: campaignId , schools: selectedSchools.map(item => item._id)})
        }
        setAssignFosPopup(false)
        setOverlay(false)
        setSelectedFos({_id: "", name: ""})
        setShowEditFosList(false)
        setEditFosActiveIndex(-1)
    }

    const editAssignSchoolsToFos = async(confirm) => {
        if(confirm){
            let resp 
            if(editSchool && editSchool.assignedTo){
                console.log('selectedSchoools', selectedSchools)
                resp = await context.apis[context.user.role].updateCampaignSchoolAssignment(
                    context.validator, {fosId: selectedFos._id, campaignId: campaignId}, {schools: [editSchool._id]}
                )
            }
            else{
                resp = await context.apis[context.user.role].addCampaignSchoolAssignment(
                    context.validator, {fosId: selectedFos._id, campaignId: campaignId , schools: [editSchool._id]}
                )
            }
            if(resp && resp.done){
                toast.success("Schools assigned to fos successfully")
                setSelectedSchools([])
                setSelectedFos({_id: "", name: ""})
                setShowFosList(false)
                setAssignFosPopup(false)
                setEditAssignFosPopup(false)
                setOverlay(false)
                setRefetch(!refetch)
            }
            console.log("addCampaignSchoolAssignment", resp, {fosId: "62a2fa0cb948c200088cb890", campaignId: campaignId , schools: selectedSchools.map(item => item._id)})
        }
        setAssignFosPopup(false)
        setEditAssignFosPopup(false)
        setOverlay(false)
        setSelectedFos({_id: "", name: ""})
        setEditSchool({})
        setShowEditFosList(false)
        setEditFosActiveIndex(-1)
    }

    const onPageChange = (page) => {
        setCurrentpage(page)
    }
    const onNextClick = () => {
        setCurrentpage(currentPage + 1)
    }
    const onPrevClick = () => {
        setCurrentpage(currentPage - 1)
    }

    const handleClick = () => {
        setAssignFosPopup(true)
        setOverlay(true)
    }
    const handleEditSchoolClick = () => {
        setEditAssignFosPopup(true)
        setOverlay(true)
    }
    const handleDownArrowClick = (item, index) => {
        setShowEditFosList(true);
        setEditFosActiveIndex(index);
        setActiveFos({_id: item.assignedTo && item.assignedTo.fosId ? item.assignedTo.fosId : "", name: item.assignedTo && item.assignedTo.name ? item.assignedTo.name : ""})
        setEditSchool({...item})
        // let arr = objectDeepClone(selectedSchools)
        // arr.push(item)
        // setSelectedSchools([...arr])
    }
    const handleUpArrowClick = (item?, index?) => {
        setShowEditFosList(false)
        setEditFosActiveIndex(-1)
        setActiveFos({_id: '', name: ''})
        setEditSchool({})
        // let arr = objectDeepClone(selectedSchools)
        // arr = arr.filter((school) => school._id !== item._id)
        // setSelectedSchools([...arr])
    }
    // useEffect(() => {
    //     if(!showEditFosList && )
    // }, [showEditFosList])
    return (
        <>
            <div className={`ovelap ${overlay === true ? "active" : ""}`}></div>
            <div className="col-xl-10 col-lg-12 col-md-12 col-xs-12">
                <div className="rightPart noBack">
                    <div className="row">
                        <BackendTopBar redirectPage={true} from="assignFOS" />
                        <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-10 mt-20">
                            <h2 className="htx2 clearfix">{pageView === "assign" ? "Assign FOS" : fosName}</h2>
                        </div>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12" style={{display: 'flex', justifyContent: pageView !== "assign" ?  "flex-end" : 'space-between', flexWrap: 'wrap'}}>
                            {pageView === "assign" &&
                                <ul className="tabsinventory" style={{width: 'auto'}}>
                                    <li><a href="javascript:void(0);" className={activeTab === "all" ? "active" : ""} onClick={() => setActiveTab('all')}>All({allSchoolsCount})</a></li>
                                    <li><a href="javascript:void(0);" className={activeTab === "assign" ? "active" : ""} onClick={() => setActiveTab('assign')}>ASSIGN({assignSchoolsCount})</a></li>
                                    <li><a href="javascript:void(0);" className={activeTab === "notAssign" ? "active" : ""} onClick={() => setActiveTab('notAssign')}>NOT ASSIGN({notAssignSchoolsCount})</a></li>
                                </ul>
                            }
                            <div style={{display: 'flex'}}>
                                {selectedSchools.length > 0 && <a href="javascript:void(0);" className="btn btn-orange" style={{marginRight: '15px', height: '45px', textTransform: 'none'}} onClick={() => setShowFosList(!showFosList)}>Assign({selectedSchools.length})</a>}
                                <CustomSearchInput
                                    searchInpuOpen={searchInputOpen}
                                    setSearchInpuOpen={setSearchInputOpen}
                                    clearSearchIcon={clearSearchIcon}
                                    setClearSearchIcon={setClearSearchIcon}
                                    searchKeys={searchValue}
                                    handleChange={handleSearch}
                                    palaceholder="School name"
                                />
                            </div>
                        </div>
                            <FosListComponent
                                showFosList={showFosList}
                                setShowFosList={setShowFosList}
                                fosList={fosList}
                                setFosList={setFosList}
                                handleClick={handleClick}
                                selectedFos={selectedFos}
                                setSelectedFos={setSelectedFos}
                                editFosActiveIndex={editFosActiveIndex}
                                setEditFosActiveIndex={setEditFosActiveIndex}
                                setSelectedSchools={setSelectedSchools}
                                setEditSchool={setEditSchool}
                            />
                            <FosListComponent
                                showFosList={showEditFosList}
                                setShowFosList={setShowEditFosList}
                                activeFos={activeFos}
                                fosList={fosList}
                                setFosList={setFosList}
                                handleClick={handleEditSchoolClick}
                                selectedFos={selectedFos}
                                setSelectedFos={setSelectedFos}
                                editFosActiveIndex={editFosActiveIndex}
                                setEditFosActiveIndex={setEditFosActiveIndex}
                                setSelectedSchools={setSelectedSchools}
                                setEditSchool={setEditSchool}
                            />
                        <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-20 pl pr">
                            <div className="table-container mt-10 addAdmininput">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            {pageView === "assign" &&
                                                <th scope="col" style={{width:"70px"}}>
                                                    <div className="checkbox fnone">
                                                        <input type="checkbox" id="schoolsAll" checked={activeTab === "assign" ? false : checkAllSchoolSelected()} onClick={(e) => selectAllSchool(e)}/>
                                                        <label htmlFor="schoolsAll"></label>
                                                    </div>
                                                </th>
                                            }
                                            <th scope="col" style={{width:"70px"}}>SR no</th>
                                            <th scope="col">School name</th>
                                            {pageView === "assign" &&
                                                <th className="p-relative">City <a className="openFilter" href="javascript:void(0);" onClick={() => setOpenCityFilter(!openCityFilter)}><img src={tableFilter} /></a>
                                                    <CityFilter
                                                        cityList={cityList}
                                                        openStatusFilter={openCityFilter}
                                                        setOpenStatusFilter={setOpenCityFilter}
                                                        setFilterStatus={setCityFilter}
                                                        filterStatus={cityFilter}
                                                    />
                                                </th>
                                            }
                                            {pageView === "assign" && <th scope="col">Local area</th>}
                                            <th>
                                                Approval status
                                            </th>
                                            <th className="p-relative">Assign to <a className="openFilter" href="javascript:void(0);" onClick={() => setOpenAssignToFilter(!openAssignToFilter)}><img src={tableFilter} /></a>
                                                <AssignToFilter
                                                    fosList={fosList}
                                                    openStatusFilter={openAssignToFilter}
                                                    setOpenStatusFilter={setOpenAssignToFilter}
                                                    setFilterStatus={setAssignToFilter}
                                                    filterStatus={assignToFilter}
                                                />
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!loading ? allSchools.length > 0 ? allSchools.map((item, index) => (
                                            <tr>
                                                {pageView === "assign" &&
                                                    <td>
                                                        <div className={`checkbox fnone ${item.assignedTo ? 'disabled-grey' : ""}`}>
                                                            <input type="checkbox" id={item._id} disabled={item.assignedTo} checked={shoolSelected(item._id)} onClick={(e) => addRemoveSchool(e, item)}/>
                                                            <label htmlFor={item._id}></label>
                                                        </div>
                                                    </td>
                                                }
                                                <td>{((currentPage - 1) * dataPerPage) + (index + 1)}</td>
                                                <td>
                                                    {pageView === "assign" ? 
                                                    item.schoolName
                                                    :
                                                    <div className="schoolNameTable">
                                                        <h4>{titleCase(item.schoolName)}</h4>
                                                        {item.city && <span>{item.city}</span>}
                                                        {item.localArea && <span>{item.localArea}</span>}
                                                    </div>
                                                    }
                                                </td>
                                                {pageView === "assign" && <td>{item.city}</td>}
                                                {pageView === "assign" && <td>{item.localArea}</td>}
                                                <td>
                                                    {item.setApprovalStatus ? <span className={`table-status ${item.setApprovalStatus.toLowerCase() === "not-started" ? 'graybg' : item.setApprovalStatus.toLowerCase() === "started" ? "orangebg" : item.setApprovalStatus.toLowerCase() === "completed" ? 'greenbg' : item.setApprovalStatus.toLowerCase() === "actionable" ? "yellowbg" : item.setApprovalStatus.toLowerCase() === "rejected" && "redbg"}`}>{titleCase(item.setApprovalStatus.toLowerCase())}</span> : "-"}
                                                </td>
                                                <td>
                                                    {item.assignedTo ? item.assignedTo.name : '-'}
                                                    {pageView === "assign" ?
                                                        <>
                                                            <svg id={`down-arrow`} onClick={() => selectedSchools.length === 0 && handleDownArrowClick(item, index)} className="down-arrow" style={{display: editFosActiveIndex === index && "none", float: 'right', marginRight: '10px'}} width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M1 6L6 0.999999L11 6" stroke="#8C8C8C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                                                            <svg id={`up-arrow`} onClick={() => handleUpArrowClick(item, index)} style={{display: editFosActiveIndex !== index && "none", float: 'right', marginRight: '10px'}} width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M1 6L6 0.999999L11 6" stroke="#8C8C8C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                                                        </>
                                                        :
                                                        item.setApprovalStatus === "not-started" ?
                                                        <img className="updownarrow" style={{opacity: "0.3", cursor: "default"}} src={arrowUpDown} />
                                                        :
                                                        <Link to={{
                                                            pathname: "/backend/backend-details/campaign-reporting",
                                                            state: {campaignId: campaignId, schoolId: item._id, schoolName: item.schoolName }
                                                        }}>
                                                            <img className="updownarrow" src={arrowUpDown} />
                                                        </Link>
                                                    }
                                                </td>
                                            </tr>
                                        ))
                                        :
                                        <tr><td colSpan={6}>No School found</td></tr>
                                        :
                                        [...Array(10)].map((el, index) => {
                                            return (
                                                <tr key={index}>
                                                    {
                                                        [...Array(6)].map((el, index) => {
                                                            return (
                                                                <td key={index}>
                                                                    <div className="content-loader-block"></div>
                                                                </td>
                                                            )
                                                        })
                                                    }
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-xs-12 p-r">
                            {totalPage.length > 1 &&
                                <Pagination
                                    currentPage={currentPage}
                                    totalPage={totalPage}
                                    onPageChange={onPageChange}
                                    onNextClick={onNextClick}
                                    onPrevClick={onPrevClick}
                                    loading={loading}
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
            <ConfirmDialog
                togglePopup={assignFosPopup}
                onModalClose={assignSchoolsToFos}
                componentName={""}
                confirmTitle={`Do you want to assign ${selectedSchools.length} school to ${selectedFos?.name}?`}
            />
            <ConfirmDialog
                togglePopup={editAssignFosPopup}
                onModalClose={editAssignSchoolsToFos}
                componentName={""}
                confirmTitle={`Do you want to assign 1 school to ${selectedFos?.name}?`}
            />
        </>
    )
}

export default AssignSchoolsToFos