import { useState, useEffect, useContext, useRef } from "react"

import { useHistory } from "react-router-dom"

import toast from "react-hot-toast"

import { validation } from "../../../../service/validators"

import TextFieldWithIcon from "../../../../components/formFields/textfieldWithIcon"
import PasswordField from "../../../../components/formFields/passwordField"

import loginBgLeft from "../../../../assets/images/backendAdmin/login-bg.svg"
import envelope from "../../../../assets/images/envolpe.svg"
import logo from "../../../../assets/images/logo.png"
import AppContextObject from "../../../../common/context/common"
import Textfield from "../../../../components/formFields/textField/textField"
import SimpleReactValidator from "simple-react-validator"
import errorSVG from "../../../../assets/images/error.svg";
import { objectDeepClone, validateFullName } from "../../../../service/helper"
import SingleSelect from "../../../../components/formFields/singleSelect/singleSelect"
import config from "../../../../service/config"
import AlertDialog from "../../../../components/addon/alertDialog/alertDialog"
import { PublicContextInitiator } from "../../../apis/publicContextInitiator"
import DataContext from "../../../../service/brandContext"
import TextField from "../../../../components/formFields/textField/textField"

interface Props {
    methods
}

const SignUp = ({
    methods
}: Props) => {
    const [update, forceUpdate] = useState(false)
    const history = useHistory()
    const context = useContext(AppContextObject)
    const [selectedContactForOTP, setSelectedContactForOTP] = useState("");
    const [msgDetails, setMsgDetails] = useState('');
    const [overlay, setOverlay] = useState(false)
    const [toggleAlertPopUp, setToggleAlertPopUp] = useState(false);
    const [getFieldInfo, setGetFieldInfo] = useState('');
    const [detailsCaptured, setDetailsCaptured] = useState('');
    let publicContextInitiator = new PublicContextInitiator(context)
    const parentData = useContext<any>(DataContext)
    const [formData, setFormData] = useState({
        fullName: "",
        companyName: "",
        role: "",
        designation: "",
    })
    const [formValidator] = useState(useRef(new SimpleReactValidator({
        element: (message, className) =>
            <span className="valError"><img src={errorSVG} />
            {message === "The role field is required." ? "The designation field is required." : message }
            </span>
    })))

    useEffect(() => {
        if(sessionStorage.getItem("contactDetails")){
            let data = JSON.parse(sessionStorage.getItem("contactDetails"))
            setFormData({
                fullName : data.fullName,
                companyName : data.companyName,
                designation : data.designation,
                role : data.role,
            })
        }
    }, [history.location.pathname])

    useEffect(() => {
        window.addEventListener("beforeunload", handleBeforeUnload)
        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload)
        }
    }, [])

    const handleBeforeUnload = (e) => {
        e.preventDefault()
        window.addEventListener("unload", function(){
            // deleteUnsavedUser(unsavedUser)
            sessionStorage.removeItem("userContactDetail")
            sessionStorage.removeItem("contactVerification")
            sessionStorage.removeItem("apiData")
            sessionStorage.removeItem("reVerify")
            sessionStorage.removeItem("contactDetails")
        })
        setTimeout(function() {
            setTimeout(function() {
                // sessionStorage.removeItem("imageKeys")
                // sessionStorage.removeItem("userIds")
            }, 1000);
        },1);
        const message = "Are you sure you want to reload the page? All provided data will be lost."
        e.returnValue = message
        return message
    }


    const onFormDataChange = (event) => {
        setFormData({
            ...formData,
            [event.target.id]: event.target.value
        })
        // if (event.target.id === "username") {
        //     setUsernameError(false)
        //     setFormData({
        //         ...formData,
        //         username: event.target.value.toLowerCase()
        //     })
        //     if (formData.username != event.target.value) {
        //         if (event.target.parentNode.querySelectorAll(".vrifiyotp")[0].className.indexOf("complete") >= 0) {
        //             event.target.parentNode.querySelectorAll(".vrifiyotp")[0].className = event.target.parentNode.querySelectorAll(".vrifiyotp")[0].className.replace(/ complete/gi, "");
        //             setIsUsernameVerified(false)
        //             setGetVerifyUsernameStatus(false)
        //         }
        //     }
        // }
    }

    formValidator.current.rules['full_name'] = {
        message: 'Please enter valid full name.',
        rule: (val) => {
            let returnValue = validateFullName(val)

            return returnValue
        },
    }
    console.log("formData===>",formData)
    
    const onSubmit = async () => {
        
        const result = formValidator.current.allValid()
        const asArray = Object.entries(formValidator.current.fields);
        console.log("asArray>>",asArray);
        const filtered = asArray.filter(([key, value]) => value === false);
        const revertObject = Object.fromEntries(filtered);
        var size = Object.keys(revertObject).length;
        console.log("revertObject>>>",revertObject, Object.keys(revertObject)[0]);
        if(formData.role !== 'other' && result === false && size === 1 && Object.keys(revertObject)[0] === 'designation'){

        }else if (result === false) {
            formValidator.current.showMessages()
            forceUpdate(!update)
            return
        } else {
            formValidator.current.hideMessages()
            forceUpdate(!update)
            // setEnableField("")
        }


        // let data = {
        //     fullName: formData.fullName,
        //     designation : formData.designation,
        //     companyName : formData.companyName,
        //     // role : role
        // }
        // if (parentData) {
        //     const json = { ...formData }
        //     if (parentData.setData) {
        //         parentData.setData({
        //             ...parentData.data,
        //             personalDetails: 
        //             {
        //                 fullName: 'ss s',
        //                 email: 'da@ewew.ddd',
        //                 phone: '365616684786',
        //                 role: null,
        //                 username: 'dsd',
        //                 designation: 'ds',
        //                 companyName: 'ss'
        //             }
        //         })
        //     }
        // }
       
        // let addUserResp = await publicContextInitiator.addUser(
        //     context.validator, data
        // )
        // console.log("addUserResp ", addUserResp)
        // if (addUserResp && addUserResp.done) {
            // history.push('/brands/contact-details', {
            //     fullName : formData.fullName,
            //     companyName : formData.companyName,
            //     designation : formData.designation,
            //     role : role
            //   })
            // history.push("/brands/contact-details")
        // }

        let data = {
            fullName : formData.fullName,
            companyName : formData.companyName,
            designation : formData.designation,
            role : formData.role
        }

        sessionStorage.setItem("contactDetails", JSON.stringify(data))

        history.push({
            pathname: '/brands/contact-details',
            state: {
                fullName : formData.fullName,
                companyName : formData.companyName,
                designation : formData.designation,
                role : formData.role
            },
          });

    }

    console.log("parentData ", parentData.data.personalDetails)

    const confirmClose = () => {
        setOverlay(false)
        setToggleAlertPopUp(false)
    }

    const onSignUpClick = () => {
        history.push("/login")
    }

    const onDesignationChange = (data) => {
        // setFormData({
        //     ...formData,
        //     role: data.value
        // })
        if(data.value === "other"){
            setFormData({
                ...formData,
                role: data.value,
                designation: ""
            })
            if(document.getElementById("designation") != null){
                document.getElementById("designation").focus()
            }
           
        }else{
            setFormData({
                ...formData,
                role: data.value,
                designation: data.value
            })
            document.getElementById("submitBtn").focus()
            // onSubmit();
        }
    }
    const onOtherDesignationChange = (e) => {
        // console.log("data>>>",e)
        setFormData({
            ...formData,
            designation: e.target.value
        })
    }
    formValidator.current.rules['company_name'] = {
        message: 'Please enter valid company name.',
        rule: (val) => {
            let returnValue = true
            if (!val || val.trim() === "") {
                returnValue = false
            }
            const regex2 = /[a-zA-Z]/;
            if (!regex2.test(val)) {
                returnValue = false
            }
            return returnValue
        },
    }
    formValidator.current.rules['valid_other_designation'] = {
        message: 'Please enter valid designation.',
        rule: (val) => {
            let returnValue = !(val.toLowerCase() === "brand manager" || val.toLowerCase() === "cmo")
            return returnValue
        }
    }
    const handleOnKeyPress = (event) => {
        if (event.key.toLowerCase() === "enter") {
          const form = event.target.form;
          const index = [...form].indexOf(event.target);
          form.elements[index + 1].focus();
          event.preventDefault();
          if(event.target.id === "designation")
          {
            onSubmit();
          }
        }
    }
    return (
        <>
            <div className={`ovelap ${(overlay === true) ? "active" : ""}`}></div>
            <div className="main-wapper">
                <div className="loginbox">
                <div className="login-wave backendlogin"> <img src={loginBgLeft} alt="login-wave" /> </div>
                    <div className="signup-form">
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb10">
                                <img src={logo} alt="logo" />
                                <h2 className="htx1 mt-20">Welcome to WISR</h2>
                                <p className="ptx mb-20">Let's get you started!</p>
                            </div>
                        </div>
                        <form data-form-validation="login-form" id="login-form-parent">
                            <div className="row">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                    <Textfield
                                        formData={formData}
                                        formKey='fullName'
                                        onDataChange={onFormDataChange}
                                        label='Your Full Name'
                                        placeholder='Enter Full Name'
                                        isRequired={false}
                                        formValidator={formValidator}
                                        validation='required|full_name'
                                        maxLength={50}
                                        onKeyPress={(event) => handleOnKeyPress(event)}
                                    />
                                </div>
                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                    <Textfield
                                        label="Your Company Name"
                                        placeholder="Enter Company Name"
                                        isRequired={false}
                                        formValidator={formValidator}
                                        validation='required|company_name'
                                        formData={formData}
                                        formKey='companyName'
                                        onDataChange={onFormDataChange}
                                        maxLength={100}
                                        onKeyPress={(event) => handleOnKeyPress(event)}
                                    />
                                </div>
                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">

                                    {/* <Textfield
                                        label="Your Designation"
                                        placeholder="Your Designation"
                                        isRequired={false}
                                        formValidator={formValidator}
                                        validation='required'
                                        formData={formData}
                                        formKey='designation'
                                        onDataChange={onFormDataChange}
                                    /> */}
                                    <SingleSelect
                                        formData={formData}
                                        formKey="role"
                                        onDataChange={onDesignationChange}
                                        label="Your Designation"
                                        placeholder="Select Designation"
                                        isRequired={false}
                                        options={config.designationOptions}
                                        formValidator={formValidator}
                                        validation="required"
                                        onKeyPress={(event) => handleOnKeyPress(event)}
                                    />
                                </div>
                                {formData && formData.role && formData.role === "other" &&
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                        <TextField
                                            id={"designation"}
                                            formData={formData}
                                            formKey='designation'
                                            onDataChange={onOtherDesignationChange}
                                            label='Other'
                                            placeholder='Designation'
                                            isRequired={false}
                                            formValidator={formValidator}
                                            validation={formData.role && formData.role === "other" ? 'required|alpha_space|valid_other_designation' : ''}
                                            maxLength={50}
                                            onKeyPress={(event) => handleOnKeyPress(event)}
                                            autoFocus={true}
                                        />
                                    </div>
                                }

                                <div className="row col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                    <div className="forminpt">
                                        <input id={"submitBtn"} onClick={onSubmit} value="Continue" className="btn btn-orange datasubmit w100" type="button" />
                                    </div>
                                </div>
                                <div className="row col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                    <p className="centerText">Have a WISR account, already? <a style={{ cursor: "pointer", color: "#fe6e00" }} onClick={onSignUpClick}>LOG IN</a></p>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <AlertDialog
                togglePopup={toggleAlertPopUp}
                fieldInfo={getFieldInfo}
                details={detailsCaptured}
                onModalClose={confirmClose}
                displayMsg={msgDetails}
            />
        </>
    )
}

export default SignUp


