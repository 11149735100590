import { useState, useContext, useEffect, useRef } from "react"

import { Link, useHistory } from "react-router-dom"
import moment from 'moment';
import search from "../../../assets/images/search.svg"
import SimpleReactValidator from "simple-react-validator";
import riarrownext from "../../../assets/images/right-arrow-next.svg"
import tabImgInventory from "../../../assets/images/tab-img-inventory.png"
import tableFilter from "../../../assets/images/table-filter.svg"
import arrowUp from "../../../../assets/images/arrow-up.svg"
import filterSvg from "../../../assets/images/filter-svg.svg"
import filterArrowSvg from "../../../assets/images/filter-arrow-svg.svg"
import DatePicker from "../../../../components/formFields/datePicker";
import tabSlide1 from "../../../assets/images/tab-Slide1.png"
import TopBar from "../../schools/components/topBar"
import AppContextObject from "../../../../common/context/common"
import errorSVG from "../../../../assets/images/error.svg";
import OnboardingBackBtn from "../../schools/components/onboardingBackBtn"
import InventoryCard from "../../schools/updateDetails/inventory/inventoryCard"
import Pagination from "../../../../components/addon/pagination"
import CampaignApprovalModal from "../../../../components/addon/campaignApprovalModal"
import ThankyouModal from "../../../../components/addon/thankyouModal"
import RejectBrandRequestModal from "../../../../components/addon/rejectBrandRequestModal"
import closeCustome from "../../../../assets/images/close-1.svg";
import DataContext from "../../../../service/contextProvider"

import { PublicContextInitiator } from "../../../apis/publicContextInitiator"

import { Steps } from "intro.js-react"
import { formatDateToDDMMMYY, isObjectEmpty } from "../../../../service/helper"

import toast from "react-hot-toast"
import CampaignTable from "./../components/campaign/CampaignTable"
import SingleSelect from "../../../../components/formFields/singleSelect/singleSelect"
import SearchInput from "./../components/SearchInput";
import config from "../../../../service/config";
import backBtn from "../../../../assets/images/back-arrow.svg";
import SpecificCampaignTableSpan from "../components/SpecificCampaignTableSpan"
import CampaignRejetionModal from "../components/CampaignRejectionModal";
import CustomDatePicker from "../../../../components/formFields/datePicker";
const { detect } = require('detect-browser');

var backendListGlobalVar = 1

const SpecificCampaignList = () => {
    const context = useContext(AppContextObject)

    const [rejectCampaign, setrejectCampaign] = useState(false)

    const [showModalApproveActive, setshowModalApproveActive] = useState(false)
    const [showThankyouModal, setShowThankyouModal] = useState(false)
    const [thankyouTitle, setThankyouTitle] = useState("")
    const [showConfirmRejectModal, setShowConfirmRejectModal] = useState(false)
    const [thankyouDescription, setThankyouDescription] = useState("")
    const [overlay, setOverlay] = useState(false);
    const [clearSearchIcon, setClearSearchIcon] = useState(false);
    const [searchInpuOpen, setSearchInpuOpen] = useState(false);
    const [searchKeys, setSearchKeys] = useState("");
    const [formData, setFormData] = useState({ dateFilter: "" });
    const [campaigns, setCamnpaigns] = useState<any>([]);
    const [category, setCategory] = useState("");
    const [region, setRegion] = useState("");
    const [location, setLocation] = useState("");
    const [filterType, setFilterType] = useState("");
    const [totalPage, setTotalPage] = useState([0]);
    const [currentPage, setCurrentpage] = useState(1);
    const [allCount, setAllCount] = useState(0)
    const [isApproved, setIsApproved] = useState(null);
    const [flag, setFlag] = useState(false);
    const [openRejectedModal, setOpenRejectedModal] = useState(false);
    const [rejectionMsg, setRejectionMsg] = useState({ reason: "" });
    const [campaignId, setCampaignId] = useState("");
    const [update, forceUpdate] = useState(false);
    const [customSheduledFilter, setCustomSheduledFilter] = useState(false);
    const [isLoading,setIsLoading]=useState(false);
    const [acceptingCampaign, setAcceptingCampaign] = useState(false)
    const [rejectingCampaign, setRejectingCampaign] = useState(false)
    const [formSheduledDataEmpty, setFormSheduledDataEmpty] = useState(false)
    const [formSheduledData, setFormSheduledData] = useState({
        startSheduledDate: "",
        endSheduledDate: ""
    })
    const [formSheduledDataDiffFormat, setFormSheduledDataDiffFormat] = useState({
        startSheduledDate: "",
        endSheduledDate: ""
    })
    const [formValidator] = useState(useRef(new SimpleReactValidator({
        element: (message) =>
            <span className="valError"><img src={errorSVG} />{message}</span>
    })))
    const [isChrome, setIsChrome] = useState(true)
    const history = useHistory();

    useEffect(() => {
        const browser = detect();
        if (browser) {
            // console.log("browser...", browser.name)
            setIsChrome(browser.name === "chrome")
        }
    },[])
    const addNewInventory = () => {
        setrejectCampaign(true)
        setOverlay(true)
    }

    const hideModal = () => {
        setOpenRejectedModal(false);
        setOverlay(false);
        setRejectionMsg({ reason: "" });
    }

    useEffect(() => {
        if(customSheduledFilter && (formSheduledData.startSheduledDate === "" || formSheduledData.endSheduledDate === "")){
            setFormSheduledDataEmpty(true)
        }
        else{
            setFormSheduledDataEmpty(false)
        }
    }, [formSheduledData, formData.dateFilter])
    useEffect(() => {
        getCampaignList();
    }, [currentPage, searchKeys, formData.dateFilter, flag, formSheduledData])
    useEffect(() => {
        if(currentPage !==  1){
            setCurrentpage(1)
        }
    }, [formData.dateFilter, flag, formSheduledData])
    const acceptRejectPayment = async (val, campaignId) => {
        setCampaignId(campaignId);
        if (val === 'rejected') {
            setOpenRejectedModal(true);
            setOverlay(true);
            // setFlag(!flag);
        }
        else if (val === 'accepted') {
            setAcceptingCampaign(true)
            let resp = await context.apis[context.user.role].updateCampaignStatus(
                context.validator, { _id: campaignId }, { isApproved: true, campaignStatus: 'In-review' }
            )
            console.log('approved resp======>', resp);
            if (resp && resp.done) {
                setFlag(!flag);
                setAcceptingCampaign(false)
            }
        }
    }
    const rejectPayment = async () => {
        const result = formValidator.current.allValid();
        if (!result) {
            formValidator.current.showMessages()
            forceUpdate(true)
            return
        } else {
            formValidator.current.hideMessages();
            setRejectingCampaign(true)
            
            let resp = await context.apis[context.user.role].updateCampaignStatus(
                context.validator, { _id: campaignId }, { isApproved: false, campaignStatus: 'rejected', rejectionMessage: rejectionMsg.reason }
            )
            console.log('approved resp======>', resp);
            if (resp && resp.done) {
                setFlag(!flag);
                setRejectingCampaign(false)
                setOpenRejectedModal(false);
                setRejectionMsg({ reason: "" });
                setOverlay(false);
            }
        }
    }

    const getCampaignList = async () => {
        console.log("dateFilters---", formData.dateFilter,formSheduledData.startSheduledDate,formSheduledData.endSheduledDate)
        var localVar = Math.random()
        backendListGlobalVar = localVar
        if (formData.dateFilter === 'custom' && formSheduledData.startSheduledDate !== '' && formSheduledData.endSheduledDate !== '') {
            setIsLoading(true);
            console.log('1111111')
            let resp = await context.apis.public.getCampaignsByBrandExternal(
                context.validator, {
                searchValue: searchKeys, status: history.location.state['campaignType'] === 'request' ? ['in-review'] : history.location.state['campaignType'] === 'rejected' ? ['rejected'] : '',
                // dateFilter: history.location.state['campaignType'] === 'onGoing' ? 'this_month' : '', 
                dateFilter: formData.dateFilter, startDate: formSheduledData,
                campaignStatus: history.location.state['campaignType'] === 'onGoing' ? 'ongoing' : ''
            },
                { limit: 10, page: currentPage, withCount: true, "sort": { "_id": -1 } }
            )
            console.log('brands resp======>', resp);
            if (resp && resp.done) {
                if(localVar === backendListGlobalVar){
                    setAllCount(resp.allCounts.all)
                    setCamnpaigns(resp.response.rows)
                    let total = Math.ceil(resp.response.count / 10);
                    // setTotalPageCount(resp.response.count);
                    let arr = []
                    for (let i = 0; i < total; i++) {
                        arr.push(i)
                    }
                    setTotalPage([...arr])
                }
            }
        } else if (formData.dateFilter !== 'custom') {
            setIsLoading(true);
            console.log('22222')
            let resp = await context.apis.public.getCampaignsByBrandExternal(
                context.validator, {
                searchValue: searchKeys, status: history.location.state['campaignType'] === 'request' ? ['in-review'] : history.location.state['campaignType'] === 'rejected' ? ['rejected'] : '',
                // dateFilter: history.location.state['campaignType'] === 'onGoing' ? 'this_month' : '', 
                dateFilter: formData.dateFilter,
                campaignStatus: history.location.state['campaignType'] === 'onGoing' ? 'ongoing' : ''
            },
                { limit: 10, page: currentPage, withCount: true, "sort": { "_id": -1 } }
            )


            console.log('brands resp======>', resp)

            if (resp && resp.done) {
                if(localVar === backendListGlobalVar){
                    setAllCount(resp.allCounts.all)
                    setCamnpaigns(resp.response.rows)
                    let total = Math.ceil(resp.response.count / 10);
                    // setTotalPageCount(resp.response.count);
                    let arr = []
                    for (let i = 0; i < total; i++) {
                        arr.push(i)
                    }
                    setTotalPage([...arr])
                }
            }
        }
        setIsLoading(false);
    }
    const onCategoryChange = (data) => {
        if (data.value === 'custom') {
            setCustomSheduledFilter(true);
        }else{
            setCustomSheduledFilter(false);
            setFormData({ dateFilter: data.value });
        }
        setFormData({ dateFilter: data.value });
    }
    const onPageChange = (page) => {
        setCurrentpage(page)
    }
    const onNextClick = () => {
        setCurrentpage(prev => prev + 1)
    }
    const onPrevClick = () => {
        setCurrentpage(prev => prev - 1)
    }

    const onScheduleDateChange = (event, id) => {
        if (isChrome) {
            setFormSheduledData({ ...formSheduledData, [event.target.id]: event.target.value });
            setFormSheduledDataDiffFormat({...formSheduledDataDiffFormat, [event.target.id] : event.target.value});
        }
        else {
            setFormSheduledData({ ...formSheduledData, [id]: moment(event).format("yyyy-MM-DD") });
            setFormSheduledDataDiffFormat({...formSheduledDataDiffFormat, [id] : event})
        }
    }
    return (
        <>
            <div className={`ovelap ${overlay === true ? "active" : ""}`}></div>
            <div className="col-xl-10 col-lg-12 col-md-12 col-xs-12">
                <div className="rightPart noBack">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-xs-6">
                            <Link to="/backend/backend-details/campaign-list" className="backbtn dblock">
                                <img src={backBtn} />
                                {history.location.state['campaignType'] === 'onGoing' ? 'On-Going Campaigns' : history.location.state['campaignType'] === 'rejected' ? 'Rejected Campaigns' : 'Campaign Requests'}
                            </Link>
                        </div>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                            <div className="row">
                            <div className="col-xl-5 col-lg-5 col-md-12 col-xs-12 mt-20">
                                <h3>Total {history.location.state['campaignType'] === 'onGoing' ? 'On-Going Campaigns' : history.location.state['campaignType'] === 'rejected' ? 'Rejected Campaigns' : 'Campaign Requests'} - {allCount}</h3>
                            </div>
                            <div className="col-xl-7 col-lg-7 col-md-12 col-xs-12">
                            
                                <div className="inventorySearch schoolFilter campainFilter inventoryRelative mt-30m">
                                    <SearchInput
                                        searchInpuOpen={searchInpuOpen} //true/false
                                        clearSearchIcon={clearSearchIcon} //true/false
                                        setSearchInpuOpen={setSearchInpuOpen} //function=>true/false
                                        setSearchKeys={setSearchKeys} //function=>string
                                        searchKeys={searchKeys} //string
                                        setClearSearchIcon={setClearSearchIcon}//function=>true/false
                                        palaceholder='Campaign name'
                                        setCurrentpage={setCurrentpage}
                                    />
                                    <div className="forminpt-dateFilter">
                                        <SingleSelect
                                            formData={formData}
                                            formKey='dateFilter'
                                            onDataChange={onCategoryChange}
                                            placeholder='Select'
                                            isRequired={false}
                                            options={config.monthFilterSheduleDate}
                                            noScroll={true}
                                        />
                                        {formSheduledDataEmpty && <h1 className="valError">
                                            <img src={errorSVG} />
                                            {'Date required.'}
                                        </h1>
                                        }
                                        {customSheduledFilter && <div className={`filterDropDown r-auto tableFilter custom-w open`}>
                                            <div className="filterDropBody">
                                                <div className="filterDropHead">
                                                    <a href="javascript:void(0);" className="viewwLink" onClick={() => setCustomSheduledFilter(false)} ><img src={closeCustome} /></a>
                                                </div>
                                                <div className="filterDropClose open">
                                                    <div className="filtercheckbox">
                                                        <CustomDatePicker
                                                            isChrome={isChrome} 
                                                            formData={isChrome ? formSheduledData : formSheduledDataDiffFormat}
                                                            formKey='startSheduledDate'
                                                            onDataChange={(e,v) => { onScheduleDateChange(e,v)}}
                                                            isRequired={false}
                                                            formValidator={formValidator}
                                                            validation='required'
                                                        // min={moment().add(11, "days").clone().format("YYYY-MM-DD")}
                                                            max={(formSheduledData.endSheduledDate && moment(formSheduledData.endSheduledDate).subtract(1, "days").clone().format("YYYY-MM-DD"))}
                                                        />
                                                        {/* {sheduledFilterErr.startDate && <span className="valError"><img src={errorSVG} />{sheduledFilterErrMsg.startDateMsg}</span>} */}
                                                        {/* </div> */}
                                                    </div>
                                                </div>
                                                <div className="filterDropClose open">
                                                    <div className="filtercheckbox">
                                                        <CustomDatePicker
                                                            isChrome={isChrome}
                                                            formData={isChrome ? formSheduledData : formSheduledDataDiffFormat}
                                                            formKey='endSheduledDate'
                                                            onDataChange={(e,v) => { onScheduleDateChange(e,v)}}
                                                            isRequired={false}
                                                            formValidator={formValidator}
                                                            validation='required'
                                                            min={(formSheduledData.startSheduledDate && moment(formSheduledData.startSheduledDate).add(1, "days").clone().format("YYYY-MM-DD")) || moment().add(11, "days").clone().format("YYYY-MM-DD")}
                                                        />
                                                        {/* {sheduledFilterErr.endDate && <span className="valError"><img src={errorSVG} />{sheduledFilterErrMsg.endDateMsg}</span>} */}
                                                    </div>
                                                </div>
                                                        <div className="filterDropHead-campaign">
                                                    <a href="javascript:void(0);" className="viewwLink" onClick={() => { setFormSheduledData({ startSheduledDate: "", endSheduledDate: "" }); setFormSheduledDataDiffFormat({ startSheduledDate: "", endSheduledDate: "" }); setFlag(!flag);}} >Clear</a>

                                                    
                                                </div>
                                            </div>
                                        </div>}
                                    </div>
                                    <div className="filterDropHead">
                                        <a href="javascript:void(0);" className="viewwLink" onClick={() => { setFormData({ dateFilter: "" }); setFormSheduledData({ startSheduledDate: "", endSheduledDate: "" }); setFormSheduledDataDiffFormat({ startSheduledDate: "", endSheduledDate: "" }); }} >Clear</a>
                                    </div>
                                
                            </div>
                            </div>
                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                            <div className="inventory-tableTabs">
                            <div className="table-container table-inventory mt-10">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>SL No</th>
                                                <th>Campaign Name</th>
                                                <th className="p-relative">school
                                                </th>
                                                <th>inventories</th>
                                                <th>schedule</th>
                                                <th>Budget</th>
                                                <th className="p-relative">Status
                                                </th>
                                                {history.location.state['campaignType'] !== 'rejected' && <th className="p-relative">PAYMENT Status
                                                </th>}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {!isLoading ? <>
                                                {campaigns && campaigns.length > 0 ?
                                                    <>
                                                        {campaigns.map((campaign, key) => {
                                                            return (
                                                                <SpecificCampaignTableSpan
                                                                    campaign={campaign}
                                                                    keyNo={key + 1}
                                                                    currentPage={currentPage}
                                                                    campaignType={history.location.state['campaignType']}
                                                                    acceptRejectPayment={acceptRejectPayment}
                                                                    acceptingCampaign={acceptingCampaign}
                                                                />
                                                            )
                                                        })
                                                        }
                                                    </>
                                                    :
                                                    <tr><td colSpan={9}>No Campaign found</td></tr>


                                                }
                                            </>
                                                :

                                                [...Array(10)].map((el, index) => {
                                                    return (
                                                        <tr>
                                                            {
                                                                [...Array(9)].map((el, index) => {
                                                                    return (
                                                                        <td key={index}>
                                                                            <div className="content-loader-block"></div>
                                                                        </td>
                                                                    )
                                                                })
                                                            }
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                    {totalPage.length > 1 && 
                                        <Pagination
                                            currentPage={currentPage}
                                            totalPage={totalPage}
                                            onPageChange={onPageChange}
                                            onNextClick={onNextClick}
                                            onPrevClick={onPrevClick}
                                            loading={isLoading}
                                        // cityFilterModal={cityFilterModal}
                                        />
                                    }
                                </div>
                                </div>
                                </div>
                                </div>
                        </div>
                    </div>
                </div>
            </div>

            <CampaignRejetionModal
                openRejectedModal={openRejectedModal}
                hideModal={hideModal}
                rejectionMsg={rejectionMsg}
                setRejectionMsg={setRejectionMsg}
                formValidator={formValidator}
                rejectPayment={rejectPayment}
                loading={rejectingCampaign}
            />
            {/* <div id="rejectCampaignPopup" className={`popup rejectCampaignPopup ${rejectCampaign === true ? "" : "hide"}`}>
                <div className="popup-header">
                    <h3 className="htx2 mb-10">Rejecting a Campaign?</h3>
                    <span onClick={hideModal} className="close toggle" data-target="closePopup">close</span>
                </div>
                <div className="popup-body">
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="forminpt">
                                <label>Please specify a reason on why this campaign was rejected</label>
                                <textarea placeholder="Enter text here"></textarea>
                            </div>
                        </div>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 end-xs">
                            <div className="forminpt mb-0">
                                <input value="SUBMIT" name="SUBMIT" className="btn btn-orange" type="button" />
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}


            {/* <CampaignApprovalModal
                showModalApproveActive={showModalApproveActive}
            // closeWithoutSaving={closeApprovalModal}
            // viewDetails={viewBrandDetails}
            // approveRequest={approveRequest}
            /> */}

            {/* <ThankyouModal
                showThankyouModal={showThankyouModal}
                // closeModal={closeThankyouModal}
                modalTitle={thankyouTitle}
                description={thankyouDescription}
            />

            <RejectBrandRequestModal
                showConfirmRejectModal={showConfirmRejectModal}
            // closeModal={closeConfirmRejectModal}
            // onRejectRequest={onRejectRequest}
            /> */}

        </>
    )
}


export default SpecificCampaignList;
