import { useState, useContext, createRef, useEffect } from "react"

import { Link } from "react-router-dom"

import { useHistory } from "react-router-dom"

import backBtn from "../../../../assets/images/back-arrow.svg"

import UserCard from "./userCard"

import {isBase64, objectDeepClone, compareObjs } from "../../../../service/helper"

import  { PublicContextInitiator } from "../../../apis/publicContextInitiator"

import AppContextObject from "../../../../common/context/common"

import toast from "react-hot-toast"

import config from "../../../../service/config"
import DataContext from "../../../../service/brandContext"
import errorSVG from "../../../../assets/images/error.svg"
import { ReactInternetSpeedMeter } from 'react-internet-meter'
import ConfirmDialog from "../../../../components/addon/confirmDialog/confirmDialog"

const ProfileAddUsers = (props) => {
    const {
        events,
        progress,
        brandProfile,
        isBackendUser
    } = props

    const history = useHistory()
    const context = useContext(AppContextObject)
    const parentData = useContext<any>(DataContext)

    const [formData, setFormData] = useState<any>([])
    const [userCardRefs, setUserCardRefs] = useState([])
    const [errorMessage, setErrorMessage] = useState("")
    const [defaultUser] = useState({
        memberName: "",
        memberEmail: "",
        memberDesignation: "",
        subCategoryName: "",
        brandName: "",
        isCompanyNameChecked: false,
        brandNameList: [],
        isTextfield: false
    })
    const [hidePrompt, setHidePrompt] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [indexOfCMO, setIndexOfCMO] = useState(null)
    const [allRoles, setAllRoles] = useState(config.designationOptions)
    const [refetch, setRefetch] = useState(false)
    const [subCategories, setSubCategories] = useState<any>([])
    const [brandNameOptions, setBrandNameOptions] = useState<any>([])
    const [indexWithCompanyName, setIndexWithCompanyName] = useState(-1)
    const [wifiSpeed, setwifiSpeed] = useState<any>(null)
    const [pingIntervalTime, setPingIntervalTime] = useState(1000)
    const [apiHited, setApiHited] = useState(false)
    const [checkSpeed, setCheckSpeed] = useState(false)
    const [canSaveData, setCanSaveData] = useState<any>(null)
    const [toggleNetworkDialog, setToggleNetworkDialog] = useState(false)
    const [isInternetOK, setIsInternetOK] = useState(false)
    const [loading, setLoading] = useState(false)
    const [overlay, setOverlay] = useState(false)

    useEffect(() => {
        let cats = parentData.brandProfile.subCategories || []
        if(brandProfile.otherBrandCategory){
            let found = cats.find(el=> el.value == "Others")
            if(!found){
                cats.push({value: 'Others', label: 'Others'})
            }
        }else{
            cats = cats.filter(el=> el.value !== "Others")
        }
        setSubCategories(cats)

    }, [parentData.brandProfile.subCategories])

    useEffect(() => {
        let brands = objectDeepClone(parentData.brandProfile.brandNameList) || []
        setBrandNameOptions([...brands])
    //    setFormData({...formData, brandNameList: brands })
    }, [])

    useEffect(() => {
        if (events && events.setActiveModule) {
            events.setActiveModule({
                module: "addUsers"
            })
        }
        if(formData.length === 0){
            if (brandProfile && brandProfile.users) {
                setFormData(objectDeepClone(brandProfile))
            }
        }
        
    }, [])

    useEffect(() => {
        if (formData.users) {
            setUserCardRefs(refs => (
                formData.users.map((_, i) => {
                    return refs[i] || createRef()
                })
            ))
        }
    }, [formData.users])

    useEffect(() => {
        if(formData.users)
        {
            let foundCMO =  formData.users.find(el => el.memberDesignation == "CMO")
            if(foundCMO){
                let indx = formData.users.indexOf(foundCMO)
                setIndexOfCMO(indx)
            } else {
                setIndexOfCMO(null)
            }
        }
        
    }, [formData.users])

    useEffect(() => {
        setwifiSpeed(null)
        setCanSaveData(null)
    }, [])

    useEffect(() => {
    console.log('wifiSpeed=====>',wifiSpeed,history.location.pathname)
    if (history.location.pathname === "/brands/brand-details/profile/add-users" || history.location.pathname ==='/backend/backend-details/brands/brand-details/profile/add-users' || history.location.pathname ==='/backend/backend-details/brands/brand-details/add-users') {
        console.log("wifiSpeed--->>>>>",wifiSpeed);
        if (wifiSpeed !== null) {
            if (Number(wifiSpeed) < context.utils.internetSpeedLimit) {
                console.log("wifiSpeed and networkOK 1", wifiSpeed, isInternetOK);
                setIsInternetOK(false)
                setCanSaveData(false)
                setIsLoading(false)
                setToggleNetworkDialog(true)
                setOverlay(true)
                setCanSaveData(null)
            } else {
                if(!apiHited){
                    console.log("wifiSpeed and networkOK 2", wifiSpeed, context.utils.internetSpeedLimit);
                    setIsInternetOK(true)
                    setCanSaveData(true)
                    setApiHited(true)
                    handleSubmit()
                    // setwifiSpeed(0)
                }
            }
            // setCanSaveData(null)
        } else {
            // setIsLoading(false)
        }
    }
    }, [wifiSpeed])

    // useEffect(()=>{
    //     if(canSaveData && isLoading){
    //         setIsLoading(true)
    //         console.log("create brand API hited.");
    //         setIsLoading(false)
    //         setCanSaveData(null)
    //         setwifiSpeed(null)
    //         handleSubmit()
    //     }
    // }, [canSaveData])

    const testSpeed = () => {
        setCheckSpeed(true)
        console.log("test speed hitted");
        // setTimeout(() => {
        //     setPingIntervalTime(1000)
        //     setCheckSpeed(false)
        //     // setwifiSpeed(0)
        //     console.log("wifiSpeed>>>>>", wifiSpeed);
        // }, 2000)
    }

    const networkConfirmClose = (confirm) => {
    setToggleNetworkDialog(false)
    setwifiSpeed(null)
    setCanSaveData(null)
    setCheckSpeed(false)
    setOverlay(false)
    }
    
    const handleSubmit = () =>{
        // setIsLoading(true)
        setTimeout(() => {
        let allResults = []
        userCardRefs.forEach(userCard => {
            if (userCard?.current?.validateUser) {
                allResults.push(userCard.current.validateUser())
            }
        })

        // let found = formData.users.filter(element=> element.memberDesignation === "CMO");
        // if(found && found.length > 1){
        //     setErrorMessage("Team members must include one CMO only")
        //     setIsLoading(false)
        //     return
        // }

        // let onlyBMArr = formData.users.filter(el=> el.memberDesignation !== "CMO")
        // let valueArr = onlyBMArr.map(function(item){ return item.brandName });
        // let isDuplicate = valueArr.some(function(item, idx){ 
        //     return item && valueArr.indexOf(item) != idx 
        // });
        // if(isDuplicate){
        //     setErrorMessage("Brand Name can't be same for two different Brand Managers")
        //     setIsLoading(false)
        //     return
        // }
        let allUsersData = formData.users
        if(allUsersData){
            allUsersData = allUsersData.filter(el => el.memberName !== "")
        }
        allUsersData.map(el=>{
            if(el.memberDesignation === 'other'){
                el.memberDesignation = "Brand Manager"
            }
            if(el.designation === undefined || el.designation.length === 0){
                el.designation = el.memberDesignation || "Brand Manager"
            }
        })
        setHidePrompt(true)
        if (allResults.indexOf(false) === -1) {
            setErrorMessage("")
            if (events && events.setBrandProfile) {
                const json = objectDeepClone(parentData.brandProfile)
                json.users = objectDeepClone(allUsersData)
                events.setBrandProfile(json)
                const publicContextInitiator = new PublicContextInitiator(context)
                const payload = objectDeepClone(transformObj(json))
                payload.users = objectDeepClone(allUsersData)
                payload.brandId = context.user.brand || ""
                payload.removeUserIds = parentData.brandProfile.removeUserIds
                if(isBackendUser){
                    payload.isBackendUser = true
                }
                console.log("payload--->>",payload)
                publicContextInitiator.updateBrandWithTeamMember(context.validator, payload)
                .then(res=> {
                    if (res && res.done) {
                        setIsLoading(false)
                        progress.addUsers = true
                        setIsLoading(false)
                        toast.success("Brand created successfully")
                        if(parentData && parentData.setRefetch)
                        {
                            parentData.setRefetch(!parentData.refetch)
                        }
                        process.nextTick(()=>{
                            if(isBackendUser){
                                history.push("/backend/backend-details/brand-list")
                            }else{
                                history.push("/brands/brand-details/profile")
                            }
                        })
                    }
                })
                .catch(err => {
                    setIsLoading(false)
                    toast.error("Something went wrong.")
                })
            }
        } else {
            setErrorMessage("Please fill all fields.")
            setIsLoading(false)
        }
        }, 100);
        
    }

    const onFormSubmit = () => {
        if(isLoading){
            return
        }
        setIsLoading(true)
        let allResults = []
        userCardRefs.forEach(userCard => {
            if (userCard?.current?.validateUser) {
                allResults.push(userCard.current.validateUser())
            }
        })
        if (allResults.indexOf(false) === -1) {
            testSpeed();
        }else{
            setIsLoading(false)
        }
    }

    const transformObj = (json) => {
        const obj = {
            logo: "",
            brandName: json.general.companyName.trim(),
            brandType: json.general.companyCategory,
            website: json.general.companyWebsite,
            documents: {
                gst: {
                    file: json.verification.gst.image || "",
                    number: json.verification.gst.documentNo,
                    isVerified: false,
                    status: json.verification.gst.status
                },
                pan: {
                    file: json.verification.pan.image  || "",
                    number: json.verification.pan.documentNo,
                    isVerified: false,
                    status: json.verification.pan.status
                }
            },
            address: json.contact.address,
            contactNumber: json.contact.mobileNo,
            country: json.contact.country.trim(),
            city: json.contact.city.trim(),
            state: json.contact.state.trim(),
            zip: json.contact.pincode,
            description: json.companyDescription.description,
            attributes: [],
            socialMediaLinks: json.general.socialMedia,
        }
        return obj
    }

    const addUser = () => {
        const data = objectDeepClone(formData)
        // if(data.users.length < 2){
            data.users.push(objectDeepClone(defaultUser))
        // }
        setFormData({
            ...data,
        })
    }

    const removeUser = (index) => {
        const data = objectDeepClone(formData)
        let id = data.users[index]._id || null

        data.users.splice(index, 1)
        setFormData({
            ...data,
            removeUserIds: [...formData.removeUserIds, id]
        })

        if(parentData && parentData.setBrandProfile){
            parentData.setBrandProfile({
                ...data,
                removeUserIds: [...parentData.brandProfile.removeUserIds, id]
            })
        }

        setRefetch(!refetch)
    }



    const handleUserData = (userdata, index) => {
        const data = objectDeepClone(formData)
        data.users[index] = userdata
        setFormData({
            ...data,
        })
    }

    const updateSubCategory = (data) => {
        setSubCategories(data)
        if (events && events.setBrandProfile) {
            const json = objectDeepClone({...parentData.brandProfile, subCategories: data})
            // json.users = objectDeepClone(allUsersData)
            events.setBrandProfile(json)
        }
        setRefetch(!refetch)
    }

    const updateBrandNameOptions = (data) => {
        let companyName = parentData?.brandProfile?.general?.companyName || (context.user?.companyName ? context.user?.companyName : context.user?.brandName)
        setBrandNameOptions([...data.filter(item => item.value?.toLowerCase() !== companyName?.toLowerCase())])
        if (events && events.setBrandProfile) {
            const json = objectDeepClone({...parentData.brandProfile, brandNameList: [...data]})
            // json.users = objectDeepClone(allUsersData)
            events.setBrandProfile(json)
        }
        setRefetch(!refetch)
    }

    const updateAllFormData = (data) => {
        const alldata = objectDeepClone(formData)
        alldata.users = data
        setFormData({...alldata})
    }
    
    const handleIndexWithBrandName = (i) => {
        setIndexWithCompanyName(i)
    }

    console.log("wifiSpeed---==>",wifiSpeed);

    return (
        <>
            <div className={`ovelap ${overlay === true ? "active" : ""}`}></div>
            {
                checkSpeed
                &&
                <ReactInternetSpeedMeter  
                    txtSubHeading="Internet is too slow"
                    outputType="alert"
                    customClassName={"slowInternetMsg"}
                    txtMainHeading="Opps..."
                    pingInterval={pingIntervalTime} // milliseconds
                    thresholdUnit='megabyte' // "byte" , "kilobyte", "megabyte"
                    threshold={100}
                    imageUrl={context.utils.internetSpeedTestingImg}
                    downloadSize="2550420"  //bytes
                    callbackFunctionOnNetworkDown={(speed)=>console.log(`Internet speed is down ${speed}`)}
                    callbackFunctionOnNetworkTest={(speed)=>{ console.log(`Internet Speed is ${speed} MBps`); setwifiSpeed(speed); setCheckSpeed(false)}}
                />
            }
            <div className="col-xl-9 col-lg-9 col-md-12 col-xs-12">
                <div className="rightPart noBack">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-xs-6">
                            <Link to={isBackendUser ? "/backend/backend-details/brands/brand-details/verification" : "/brands/brand-details/profile/verification"} className="backbtn dblock">
                            <img src={backBtn} />BACK </Link>
                        </div>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-20">
                            <h2 className="htx5 clearfix">Your Details</h2>
                        </div>
                        <div className="col-xl-7 col-lg-7 col-md-10 col-xs-12 mt-40 mb-20 pl pr forminpt-bottom">
                            <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 pl">
                                    {
                                        (formData?.users && formData?.users.length > 0) &&
                                        formData.users.map((user, index) => {
                                            return (
                                                <>
                                                {
                                                    index === 0
                                                    &&
                                                    <UserCard
                                                        data={user}
                                                        key={index}
                                                        index={index}
                                                        ref={userCardRefs[index]}
                                                        removeUser={()=>removeUser(index)}
                                                        handleUserData={handleUserData}
                                                        hidePrompt={hidePrompt}
                                                        allData={formData.users}
                                                        indexOfCMO={indexOfCMO}
                                                        allRoles={ indexOfCMO === null ? allRoles : index === indexOfCMO ? allRoles : allRoles.filter(el => el.value !== "CMO")}
                                                        refetch={refetch}
                                                        subCategories={subCategories}
                                                        brandNameOptions={brandNameOptions}
                                                        updateSubCategory={updateSubCategory}
                                                        updateBrandNameOptions={updateBrandNameOptions}
                                                        isBackendUser={isBackendUser}
                                                        designationDisable={isBackendUser ? false : true}
                                                        setAllData={updateAllFormData}
                                                        handleIndexWithBrandName={handleIndexWithBrandName}
                                                        indexWithCompanyName={indexWithCompanyName}
                                                    />

                                                }
                                                </>
                                                
                                            )
                                        })
                                    }
                                </div>
                                {/* <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 pl">
                                    {
                                        (formData?.users && formData?.users.length > 0) &&
                                        formData.users.map((user, index) => {
                                            return (
                                                <>
                                                {
                                                    index === 1
                                                    &&
                                                    <UserCard
                                                        data={user}
                                                        key={index}
                                                        index={index}
                                                        ref={userCardRefs[index]}
                                                        removeUser={()=>removeUser(index)}
                                                        handleUserData={handleUserData}
                                                        hidePrompt={hidePrompt}
                                                        allData={formData.users}
                                                        indexOfCMO={indexOfCMO}
                                                        allRoles={ indexOfCMO === null ? allRoles : index === indexOfCMO ? allRoles : allRoles.filter(el => el.value !== "CMO")}
                                                        refetch={refetch}
                                                        subCategories={subCategories}
                                                        brandNameOptions={brandNameOptions}
                                                        updateSubCategory={updateSubCategory}
                                                        updateBrandNameOptions={updateBrandNameOptions}
                                                        // designationDisable={true}
                                                        isBackendUser={isBackendUser}
                                                        setAllData={updateAllFormData}
                                                        handleIndexWithBrandName={handleIndexWithBrandName}
                                                        indexWithCompanyName={indexWithCompanyName}
                                                    />

                                                }
                                                </>
                                                
                                            )
                                        })
                                    }
                                </div> */}
                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 pl">
                                    {
                                        (formData?.users && formData?.users.length > 0) &&
                                        formData.users.map((user, index) => {
                                            return (
                                                <>
                                                {
                                                    index > 0
                                                    &&
                                                    <UserCard
                                                        data={user}
                                                        key={index}
                                                        index={index}
                                                        ref={userCardRefs[index]}
                                                        removeUser={()=>removeUser(index)}
                                                        handleUserData={handleUserData}
                                                        hidePrompt={hidePrompt}
                                                        allData={formData.users}
                                                        indexOfCMO={indexOfCMO}
                                                        allRoles={ indexOfCMO === null ? allRoles : index === indexOfCMO ? allRoles : allRoles.filter(el => el.value !== "CMO")}
                                                        refetch={refetch}
                                                        subCategories={subCategories}
                                                        brandNameOptions={brandNameOptions}
                                                        updateSubCategory={updateSubCategory}
                                                        updateBrandNameOptions={updateBrandNameOptions}
                                                        isBackendUser={isBackendUser}
                                                        setAllData={updateAllFormData}
                                                        handleIndexWithBrandName={handleIndexWithBrandName}
                                                        indexWithCompanyName={indexWithCompanyName}
                                                    />

                                                }
                                                </>
                                                
                                            )
                                        })
                                    }
                                </div>
                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                    <div className="load-more"><a onClick={addUser} href="javascript:void(0);" className="viewwLink">+ Add</a></div>
                                </div>
                                <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-20 end-xs'>
                                    <div className="bottom-error-btn">
                                        <p className="valError">
                                            {
                                                errorMessage &&
                                                <>
                                                    <img src={errorSVG} />
                                                    {errorMessage}
                                                </>
                                            }
                                        </p>
                                        {/* <div className="forminpt fR row">
                                            <a onClick={onFormSubmit} href="javascript:void(0);" className={isLoading ? "btn btn-orange brand-btn" : "btn btn-orange brand-btn button--loading"}>Complete setup</a>
                                        </div> */}
                                        <div className="forminpt fR row">
                                            <a href="javascript:void(0);" onClick={()=> onFormSubmit()} className={isLoading ? "btn btn-orange button--loading btn-disabled loaderFocus" : "btn btn-orange" } >
                                            Create Profile
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ConfirmDialog
                confirmTitle="Poor Internet Connection Detected"
                componentName="We've detected issues with your internet connection that could affect brand onboarding & data loss. Please check your internet connection & try again."
                confirmText="Got it"
                denyText="Cancel"
                togglePopup={toggleNetworkDialog}
                onModalClose={networkConfirmClose}
                onConfirmBtn={true}
            />
        </>
    )
}

export default ProfileAddUsers
