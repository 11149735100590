import React, { useEffect, useState, forwardRef } from 'react'
import { Bar } from 'react-chartjs-2';

interface Props {
    data?: any,
    categories?: any,
    totalCampaigns?:any
}

const VerticalBarChartBackend = forwardRef(({
    data, categories,totalCampaigns
}: Props, ref) => {
    const [chartData, setChartData] = useState()
    useEffect(() => {
        setChartData(data)
    }, [data])
    const labelsData=totalCampaigns && totalCampaigns.map(totalCampaign=>totalCampaign.category);
    const dataSet=totalCampaigns && totalCampaigns.map(totalCampaign=>totalCampaign.count);
    // console.log(labelsData,dataSet)
    let data1 = {
        // labels: labelsData,
        labels: data && data.map(item=>item.name),
        datasets: [
            {
                // data: dataSet,
                data:data && data.map(item=>item.numOfCampaigns),
                // boarderWidth: 1
                backgroundColor: "#96E5FA",
                borderColor: "#96E5FA",
                tension: 0,
                barThickness: 5
            }],
    }

    return (
        <>
            {data1 &&
                <Bar
                    data={data1}
                    // options={{
                    //     indexAxis: 'y',

                    // }}
                    options={{
                        indexAxis: 'x',
                        scales: {
                            y: {
                                beginAtZero: true,
                                title: {
                                    padding: 20,
                                    display: true,
                                    text: "No. of Campaigns",
                                    font: {
                                        family: 'Arial, Helvetica, sans-serif',
                                        size: 13,
                                      }
                                },
                                "ticks": {
                                    "autoSkip": false
                                }
                            },
                            x: {
                                beginAtZero: true,
                                title: {
                                    padding: 20,
                                    display: true,
                                    text: "Inventories",
                                    font: {
                                        family: 'Arial, Helvetica, sans-serif',
                                        size: 13,
                                      }
                                }
                            }
                        },
                        plugins: {
                            legend: {
                                display: false
                            }
                        }

                    }}
                    height="100"
                />
            }
        </>
    )
})

export default VerticalBarChartBackend;
