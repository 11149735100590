import {
	useEffect,
	useState,
	useContext,
	useRef,
	createRef,
	forwardRef,
	useImperativeHandle,
} from "react";

import { DebounceInput } from "react-debounce-input";

import { useLocation } from "react-router-dom";

import { validation } from "../../../../service/validators";

import SimpleReactValidator from "simple-react-validator";

import AlertDialog from "../../../../components/addon/alertDialog/alertDialog";
import ConfirmAlert from "../../../../components/addon/confirmAlert";
import Numberfield from "../../../../components/formFields/numberField";
import NumWithPlusMinus from "../../../../components/formFields/numWithPlusMinus/numWithPlusMinus";
import Textfield from "../../../../components/formFields/textField/textField";
import SingleSelect from "../../../../components/formFields/singleSelect/singleSelect";
import MultiSelect from "../../../../components/formFields/multiSelect/multiSelect";
import SliderComponent from "../../../../components/addon/rangeSlider";
import TextFieldWithValidation from "../../../../components/formFields/textField/textFieldWithValidation";
import GMaps from "../../../../components/addon/gMaps";

import config from "../../../../service/config";
import NumberFormat from "react-number-format";

import AppContextObject from "../../../../common/context/common";
import { PublicContextInitiator } from "../../../apis/publicContextInitiator";

import DataContext from "../../../../service/contextProvider";

import {
	checkIfNumberOnly,
	compareObjs,
	hasOnlyLettersAndSpaces,
	hasOnlyLettersNumberAndSpaces,
	objectDeepClone,
	sortEduCationLevel,
	validateSchoolName,
} from "../../../../service/helper";

import schoolImgSmall from "../../../../assets/images/school-small.png";
import schoolImgMedium from "../../../../assets/images/school-medium.png";
import schoolImgLarge from "../../../../assets/images/school-large.png";
import errorSVG from "../../../../assets/images/error.svg";
import menu from "../../../../assets/images/menu.svg";
import poorSvg from "../../../../assets/images/poor.png";
import averageSvg from "../../../../assets/images/average.png";
import excellentSvg from "../../../../assets/images/excellent.png";

interface Props {
	isEdit?: boolean;
	onProceed?: any;
	formData?: any;
	setFormData?: any;
	showPrompt?: any;
	setShowPrompt?: any;
	showModal?: boolean;
	otherBoardRef?: any;
	setOtherBoardRef?: any;
	isPinCodeValid?: boolean;
	setIsPincodeValid?: any;
	toggleAlertPopUp?: boolean;
	setToggleAlertPopUp?: any;
	boardOfStudyData?: any;
	setBoardOfStudy?: any;
	recheck?: any;
	localArea?: any;
	setLocalArea?: any;
	setBuiltError?: any;
	builtError?: any;
	showBuildUpError?: any;
	otherBoardReference?: any;
	toggleSidemenu?: Function;
	showSidemenu?: boolean;
	schoolId?: any;
	isBackendUser?: boolean;
	hideHeading?: boolean;
	isBackendEdit?: boolean;
	showPrincipleDetails?: boolean;
	principleDetails?: any;
	schoolName?: any;
	setSchoolName?: any;
}

const SchoolGeneralDetail = forwardRef(
	(
		{
			isEdit,
			onProceed,
			formData,
			setFormData,
			showPrompt,
			setShowPrompt,
			showModal,
			otherBoardRef,
			setOtherBoardRef,
			isPinCodeValid,
			setIsPincodeValid,
			toggleAlertPopUp,
			setToggleAlertPopUp,
			boardOfStudyData,
			setBoardOfStudy,
			recheck,
			localArea,
			setLocalArea,
			setBuiltError,
			builtError,
			showBuildUpError,
			otherBoardReference,
			toggleSidemenu,
			showSidemenu,
			schoolId,
			isBackendUser,
			hideHeading,
			isBackendEdit,
			showPrincipleDetails,
			principleDetails,
			schoolName,
			setSchoolName,
		}: Props,
		ref
	) => {
		const parentData = useContext<any>(DataContext);

		const context = useContext(AppContextObject);

		const location = useLocation();

		let mapRef = useRef();
		const [update, forceUpdate] = useState(false); // to re-render the dom after updating the validation

		const maxBuiltupArea = 1000000;
		const minbuiltupArea = 100;

		const [oldFormData] = useState(parentData.data.schoolGeneral);
		// const [showPrompt, setShowPrompt] = useState(false)
		// const [toggleAlertPopUp, setToggleAlertPopUp] = useState(false)

		// const [formData, setFormData] = useState(parentData.data.schoolGeneral)
		const [errorMessage, setErrorMessage] = useState("");
		const [currentClassTypeIndex, setCurrentClassTypeIndex] = useState(0);
		// const [builtError, setBuiltError] = useState(false)
		const [floorError, setFloorError] = useState(false);
		const [overlay, setOverlay] = useState(false);
		const [stateName, setStateName] = useState("State");
		const [districtName, setDistrictName] = useState("City");
		const [prevData, setPrevData] = useState();
		const [isMapLoading, setIsMapLoading] = useState(false);
		const [localAreaOption, setLocalAreaOption] = useState([]);
		const [resetData, setResetData] = useState(false);
		const [infraDetails, setInfraDetails] = useState(
			formData.infraDetails && formData.infraDetails.length > 0
				? [...formData.infraDetails]
				: []
		);
		// const [isPincodeValid, setIsPincodeValid] = useState(false)

		// const [boardOfStudyData, setBoardOfStudy] = useState({
		//     boardOfStudy: ""
		// })
		const [formValidator] = useState(
			useRef(
				new SimpleReactValidator({
					element: (message, className) => (
						<span className="valError">
							<img src={errorSVG} />
							{message === "The no of teachers field is required."
								? "The number of teachers field is required."
								: message === "The language field is required." ||
								  message === "The lang field is required."
								? "The medium of study field is required."
								: message === "The floors field is required."
								? "The number of floors field is required."
								: message === "The board field is required."
								? "The board of study field is required."
								: message}
						</span>
					),
				})
			)
		);

		const [otherBoardLength, setOtherBoardLength] = useState(0);

		useEffect(() => {
			if (showModal === undefined || showModal) {
				if (isBackendUser) {
					if (formData.infraDetails && formData.infraDetails.length > 0) {
						console.log("formData.infraDetails", formData.infraDetails);
						setInfraDetails([...formData.infraDetails]);
					} else {
						getInfraList();
					}
				}
			}
		}, [showModal !== undefined && showModal]);

		const getInfraList = async () => {
			let resp = await context.apis.public.getSchoolSettings(
				context.validator,
				{},
				{}
			);
			console.log("getSchoolSettings", resp);
			if (resp && resp.done) {
				setInfraDetails([...resp.response.rows[0].infraDetails]);
			}
		};

		useEffect(() => {
			if (showModal) {
				getSchoolGeneralDetails();
			}
		}, [showModal !== undefined && showModal]);

		useEffect(() => {
			if (showModal === undefined || showModal) {
				setOtherBoardRef((otherBoardRef) =>
					Array(otherBoardLength)
						.fill(0)
						.map((_, i) => {
							return otherBoardRef[i] || createRef();
						})
				);
			}
		}, [otherBoardLength, showModal !== undefined && showModal]);

		useEffect(() => {
			if (showModal === undefined || showModal) {
				if (parentData.setActiveModule) {
					parentData.setActiveModule({
						module: "schoolDetails",
						subModule: "general",
					});
				}
				let json = { ...parentData.data.schoolGeneral };
				if (
					!config.boardOptions.find((e) => e.value === json.board.board) &&
					json.board.board !== null
				) {
					setBoardOfStudy({
						boardOfStudy: parentData.data.schoolGeneral.board.board,
					});
					json.board.board = "Other";
				}
				if (json.board.board === "Custom") {
					for (let i = 0; i < json.board.classWiseDetails.length; i++) {
						if (
							!config.boardOptions.find(
								(e) => e.value === json.board.classWiseDetails[i].board
							)
						) {
							json.board.classWiseDetails[i]["otherBoard"] =
								json.board.classWiseDetails[i].board;
							json.board.classWiseDetails[i].board = "Other";
						}
					}
				}
				setFormData(json);
			}
		}, [showModal !== undefined && showModal]);

		console.log("formData--->>>", formData);

		useEffect(() => {
			console.log(
				"parentData.data.schoolGeneral--->.>",
				parentData.data.schoolGeneral
			);
			if (showModal === undefined || showModal) {
				if (isBackendEdit) {
					// setFormData({ ...parentData.data.schoolGeneral })
				} else {
					setFormData({ ...parentData.data.schoolGeneral });
				}
			}
		}, [parentData.data.schoolGeneral, showModal !== undefined && showModal]);

		useEffect(() => {
			if (showModal === undefined) {
				fetchCityState();
			}
		}, [formData.pincode]);

		useEffect(() => {
			console.log("showModal>>>>", showModal);
			if (showModal === undefined || showModal) {
				if (recheck === false) {
					const isSame = compareObjs(formData, oldFormData);
					if (isSame === false) {
						setShowPrompt(true);
					} else {
						setShowPrompt(false);
					}
				}
				setOtherBoardLength(formData.board.classWiseDetails.length);
			}
		}, [formData, showModal !== undefined && showModal, recheck]);

		useEffect(() => {
			if (formData.address) {
				if (mapRef && mapRef.current) {
					const ref: any = mapRef.current;
					ref.setMarkerFromAddress(formData.address);
				}
			}
		}, []);

		const getSchoolGeneralDetails = async () => {
			if (schoolId || context.user.school) {
				let schoolGeneralDetailsReps = await context.apis[
					context.user.role
				].getSchoolGeneralDetailsBySchoolId(context.validator, {
					_id: schoolId || context.user.school,
				});
				console.log("schoolGeneralDetailsReps-", schoolGeneralDetailsReps);
				if (schoolGeneralDetailsReps && schoolGeneralDetailsReps.done) {
					setPrevData(schoolGeneralDetailsReps.response);
				}
			}
		};

		const fetchCityState = async () => {
			if (showModal === undefined) {
				if (formData.pincode.length == 6) {
					try {
						let publicContextInitiator = new PublicContextInitiator(context);
						let resp = await publicContextInitiator.getPincodeDetails(
							context.validator,
							{ pincode: parseInt(formData.pincode) },
							{}
						);
						console.log("resp======>", resp);
						let multiResp =
							await publicContextInitiator.getMultiplePincodeDetails(
								context.validator,
								{ pincode: parseInt(formData.pincode) },
								{}
							);

						console.log("resp-----", multiResp, resp);

						if (resp && resp.done && multiResp && multiResp.done) {
							onStateCityChange(
								resp.response.stateName,
								resp.response.district
							);
							setIsPincodeValid(true);
							let option = [];
							multiResp.response.rows.forEach((item) => {
								let data = {
									label: item.officeName,
									value: item.officeName,
								};
								option.push(data);
							});
							let options = objectDeepClone(option);
							setLocalAreaOption(options);
							// if(options.length === 1){
							//     setFormData({
							//         ...formData,
							//         localArea: options.value
							//     })
							// }
							// if (multiResp.response.rows.length > 1) {
							//     setLocalArea(multiResp.response.rows[0].divisionName)
							// }
							// else {
							//     setLocalArea(multiResp.response.rows[0].officeName)
							// }
						} else {
							const error = validation({ pincode: "invalid" });
							if (error) {
								setIsPincodeValid(false);
								// setToggleAlertPopUp(true)
							}
						}
					} catch (error) {
						console.log("error", error);
					}
				} else {
					setIsPincodeValid(true);
					onStateCityChange("", "");
					setLocalAreaOption([]);
					RemoveLocalAreaValue();
				}
			}
		};
		const RemoveLocalAreaValue = () => {
			setFormData({
				...formData,
				localArea: "",
			});
		};

		const [langOptions] = useState(config.languages);
		const [schoolTypeOptions] = useState(config.schoolTypes);
		const [boardOptions] = useState(config.boardOptions);

		const onFormDataChange = async (event) => {
			if (event.target.id === "state") {
				if (hasOnlyLettersAndSpaces(event.target.value)) {
					setFormData({ ...formData, [event.target.id]: event.target.value });
				}
			} else if (event.target.id === "city") {
				if (hasOnlyLettersNumberAndSpaces(event.target.value)) {
					setFormData({ ...formData, [event.target.id]: event.target.value });
				}
			} else {
				setFormData({ ...formData, [event.target.id]: event.target.value });
			}
			if (event.target.id === "pincode") {
				if (event.target.value && event.target.value.length === 6) {
					if (mapRef && mapRef.current) {
						const ref: any = mapRef.current;
						ref.setMarkerFromPincode(event.target.value);
					}
				}
			}
		};

		const onAddressChange = (event) => {
			setFormData({ ...formData, [event.target.id]: event.target.value });
			if (mapRef && mapRef.current) {
				process.nextTick(() => {
					const ref: any = mapRef.current;
					ref.setMarkerFromAddress(event.target.value, formData.pincode);
				});
			}
		};

		// const submitForm = async (event) => {
		//     let otherBoardValidation = []
		//     otherBoardRef.forEach(e => {
		//         otherBoardValidation.push(e.current.validateField())
		//     })
		//     const isSummary = new URLSearchParams(location.search).get("isSummary")
		//     event.preventDefault()
		//     const updateProgress = {
		//         page: "schoolGeneral",
		//         value: false
		//     }

		//     const result = formValidator.current.allValid()

		//     if(isPincodeValid){
		//     }else{
		//         setOverlay(true)
		//         setToggleAlertPopUp(true)
		//         return
		//     }

		//     if (result === false || !(otherBoardValidation.length === 0 || otherBoardValidation.indexOf(false) === -1)) {
		//         updateProgress.value = false
		//         formValidator.current.showMessages()
		//         forceUpdate(!update)
		//         return
		//     } else {
		//         formValidator.current.hideMessages()
		//         forceUpdate(!update)
		//     }
		//     updateProgress.value = true
		//     setShowPrompt(false)

		//     const json = {...formData}

		//     if (json.board.board === "Other") {
		//         json.board.board = boardOfStudyData.boardOfStudy
		//     }

		//     if (json.board.board === "Custom") {
		//         for (let i = 0; i < json.board.classWiseDetails.length; i++) {
		//             if (json.board.classWiseDetails[i].board === "Other") {
		//                 json.board.classWiseDetails[i].board = json.board.classWiseDetails[i].otherBoard
		//                 delete json.board.classWiseDetails[i].otherBoard
		//             }
		//         }
		//     }

		//     if (parentData) {
		//         if (parentData.setProgress) {
		//             parentData.setProgress({
		//                 ...parentData.progress,
		//                 schoolGeneral: true
		//             })
		//         }
		//         if (parentData.setData) {
		//             parentData.setData({
		//                 ...parentData.data,
		//                 schoolGeneral: json
		//             })
		//         }
		//     }
		//     setBoardOfStudy({boardOfStudy: ""})
		//     process.nextTick(() => {
		//     if (isEdit === true) {
		//         if (isSummary === "true") {
		//             onProceed("/schools/update-details/details/school-details/amenities")
		//         } else {
		//             onProceed("/schools/update-details/details/school-details/amenities")
		//         }
		//     } else {
		//             if (isSummary === "true") {
		//                 onProceed("/schools/onboarding/student-specific?isSummary=true")
		//             } else {
		//                 onProceed("/schools/onboarding/student-specific")
		//             }
		//         }
		//     })
		// }
		const onSchoolNameChange = (e) => {
			setSchoolName({ schoolName: e.target.value });
		};

		const onSchoolTypeChange = (data) => {
			console.log("data----", data);
			let classList = objectDeepClone(formData.schoolType.classWiseDetails);
			if (showModal) {
				if (data.value.toLowerCase() === "custom") {
					classList.forEach((el) => {
						// console.log(el)
						const prev: any = prevData;
						const prevSchoolTypeData: any =
							prev?.schoolType?.classWiseDetails?.find(
								(schoolType) => schoolType.eduLevel === el.eduLevel
							);
						if (prevSchoolTypeData) {
							// console.log(prevBoardData)
							el.schoolType = prevSchoolTypeData.schoolType;
						} else {
							el.schoolType = schoolTypeOptions[0].value;
						}
					});
				} else {
					classList.forEach((el) => {
						el.schoolType = data.value;
					});
				}
			} else {
				if (data.value.toLowerCase() === "custom") {
					classList.forEach((el) => {
						// console.log(el)
						// let prev:any = prevData
						let schoolType: any = formData?.board?.classWiseDetails?.find(
							(schoolType) => schoolType.eduLevel === el.eduLevel
						);
						if (schoolType && schoolType.schoolType) {
							// el.board = prevBoardData.board
							// el.otherBoard = prev?.board?.board
							// else if(!config.boardOptions.find(e => e.value === prevBoardData.board) && prevBoardData.board !== null){
							//     el.board = prevBoardData.board
							//     el.otherBoard = prev?.board?.board
							// }
							el.schoolType = schoolType.schoolType;
						} else {
							el.schoolType = null;
						}
					});
				} else {
					classList.forEach((el) => {
						el.schoolType = data.value;
						// const found = formData.schoolType.classWiseDetails?.find((schoolType) => schoolType.eduLevel === el.eduLevel)
						// if(found && found.schoolType){
						//     // console.log(prevBoardData)
						//     console.log(found, data)
						// }
						// else{
						//     el.schoolType = schoolTypeOptions[0].value
						// }
					});
				}
			}
			setFormData({
				...formData,
				schoolType: {
					classWiseDetails: classList,
					schoolType: data.value,
				},
			});
		};

		const onEduLevelsChange = (data) => {
			let arr = [];
			const obj = { ...formData };
			if (data.find((el) => el.value === "all")) {
				arr = config.eduLevels
					.filter((f) => f.value !== "all")
					.map((el) => el.value);
			} else {
				arr = data.map((el) => el.value);
			}
			arr = sortEduCationLevel([...arr]);
			let langsArr = [];
			let schoolTypesArr = [];
			let boardArr = [];
			setCurrentClassTypeIndex(0);
			if (arr.length === 0) {
				obj.language.language = null;
				obj.schoolType.schoolType = null;
				obj.board.board = null;
			}
			if (showModal) {
				for (let i = 0; i < arr.length; i++) {
					const el = arr[i];
					//language
					if (arr.length === 1) {
						if (obj.language.language && obj.language.language === "Custom") {
							const langFound = obj.language.classWiseDetails.find(
								(ln) => ln.eduLevel === el
							);
							if (!langFound) {
								langsArr.push({ eduLevel: el, language: null });
								obj.language.language = null;
							} else {
								langsArr.push(langFound);
								obj.language.language = langFound.lang || langFound.language;
							}
						} else if (obj.language.language) {
							langsArr.push({ eduLevel: el, language: obj.language.language });
						} else {
							langsArr.push({ eduLevel: el, language: null });
						}
					} else {
						if (obj.language.language && obj.language.language === "Custom") {
							const langFound = obj.language.classWiseDetails.find(
								(ln) => ln.eduLevel === el
							);
							if (!langFound) {
								langsArr.push({ eduLevel: el, language: langOptions[0].value });
							} else {
								langsArr.push(langFound);
							}
						} else if (obj.language.language) {
							langsArr.push({ eduLevel: el, language: obj.language.language });
						} else {
							langsArr.push({ eduLevel: el, language: null });
						}
					}
					//schoolType
					if (arr.length === 1) {
						if (
							obj.schoolType.schoolType &&
							obj.schoolType.schoolType === "Custom"
						) {
							const schoolTypeFound = obj.schoolType.classWiseDetails.find(
								(ln) => ln.eduLevel === el
							);
							if (!schoolTypeFound) {
								schoolTypesArr.push({ eduLevel: el, schoolType: null });
								obj.schoolType.schoolType = null;
							} else {
								schoolTypesArr.push(schoolTypeFound);
								obj.schoolType.schoolType = schoolTypeFound.schoolType;
							}
						} else if (obj.schoolType.schoolType) {
							schoolTypesArr.push({
								eduLevel: el,
								schoolType: obj.schoolType.schoolType,
							});
						} else {
							schoolTypesArr.push({ eduLevel: el, schoolType: null });
						}
					} else {
						if (
							obj.schoolType.schoolType &&
							obj.schoolType.schoolType === "Custom"
						) {
							const schoolTypeFound = obj.schoolType.classWiseDetails.find(
								(ln) => ln.eduLevel === el
							);
							if (!schoolTypeFound) {
								schoolTypesArr.push({
									eduLevel: el,
									schoolType: schoolTypeOptions[0].value,
								});
							} else {
								schoolTypesArr.push(schoolTypeFound);
							}
						} else if (obj.schoolType.schoolType) {
							schoolTypesArr.push({
								eduLevel: el,
								schoolType: obj.schoolType.schoolType,
							});
						} else {
							schoolTypesArr.push({ eduLevel: el, schoolType: null });
						}
					}
					//board
					if (arr.length === 1) {
						if (obj.board.board && obj.board.board === "Custom") {
							const boardFound = obj.board.classWiseDetails.find(
								(ln) => ln.eduLevel === el
							);
							if (!boardFound) {
								boardArr.push({ eduLevel: el, board: null, otherBoard: "" });
								obj.board.board = null;
							} else {
								boardArr.push(boardFound);
								obj.board.board = boardFound.board;
							}
						} else if (obj.board.board && obj.board.board === "Other") {
							const otherBoard =
								obj.board.classWiseDetails.length !== 0
									? obj.board.classWiseDetails.find(
											(ln) => ln.otherBoard !== null
									  )
									: null;
							if (otherBoard) {
								boardArr.push({
									eduLevel: el,
									board: "Other",
									otherBoard: otherBoard.otherBoard,
								});
							} else {
								boardArr.push({ eduLevel: el, board: "Other", otherBoard: "" });
							}
						} else if (obj.board.board) {
							boardArr.push({
								eduLevel: el,
								board: obj.board.board,
								otherBoard: "",
							});
						} else {
							boardArr.push({ eduLevel: el, board: null, otherBoard: "" });
						}
					} else {
						if (obj.board.board && obj.board.board === "Custom") {
							const boardFound = obj.board.classWiseDetails.find(
								(ln) => ln.eduLevel === el
							);
							if (!boardFound) {
								boardArr.push({
									eduLevel: el,
									board: boardOptions[0].value,
									otherBoard: "",
								});
							} else {
								boardArr.push(boardFound);
							}
						} else if (obj.board.board && obj.board.board === "Other") {
							const otherBoard =
								obj.board.classWiseDetails.length !== 0
									? obj.board.classWiseDetails.find(
											(ln) => ln.otherBoard !== null
									  )
									: null;
							if (otherBoard) {
								boardArr.push({
									eduLevel: el,
									board: "Other",
									otherBoard: otherBoard.otherBoard,
								});
							} else {
								boardArr.push({ eduLevel: el, board: "Other", otherBoard: "" });
							}
						} else if (obj.board.board) {
							boardArr.push({
								eduLevel: el,
								board: obj.board.board,
								otherBoard: "",
							});
						} else {
							boardArr.push({ eduLevel: el, board: null, otherBoard: "" });
						}
					}
				}
			} else {
				for (let i = 0; i < arr.length; i++) {
					const el = arr[i];
					//language
					if (arr.length === 1) {
						if (obj.language.language && obj.language.language === "Custom") {
							const langFound = obj.language.classWiseDetails.find(
								(ln) => ln.eduLevel === el
							);
							if (!langFound) {
								langsArr.push({ eduLevel: el, language: null });
								obj.language.language = null;
							} else {
								langsArr.push(langFound);
								obj.language.language = langFound.lang || langFound.language;
							}
						} else if (obj.language.language) {
							langsArr.push({ eduLevel: el, language: obj.language.language });
						} else {
							langsArr.push({ eduLevel: el, language: null });
						}
					} else {
						if (obj.language.language && obj.language.language === "Custom") {
							const langFound = obj.language.classWiseDetails.find(
								(ln) => ln.eduLevel === el
							);
							if (!langFound) {
								langsArr.push({ eduLevel: el, language: null });
							} else {
								langsArr.push(langFound);
							}
						} else if (obj.language.language) {
							langsArr.push({ eduLevel: el, language: obj.language.language });
						} else {
							langsArr.push({ eduLevel: el, language: null });
						}
					}
					//schoolType
					if (arr.length === 1) {
						if (
							obj.schoolType.schoolType &&
							obj.schoolType.schoolType === "Custom"
						) {
							const schoolTypeFound = obj.schoolType.classWiseDetails.find(
								(ln) => ln.eduLevel === el
							);
							if (!schoolTypeFound) {
								schoolTypesArr.push({ eduLevel: el, schoolType: null });
								obj.schoolType.schoolType = null;
							} else {
								schoolTypesArr.push(schoolTypeFound);
								obj.schoolType.schoolType = schoolTypeFound.schoolType;
							}
						} else if (obj.schoolType.schoolType) {
							schoolTypesArr.push({
								eduLevel: el,
								schoolType: obj.schoolType.schoolType,
							});
						} else {
							schoolTypesArr.push({ eduLevel: el, schoolType: null });
						}
					} else {
						if (
							obj.schoolType.schoolType &&
							obj.schoolType.schoolType === "Custom"
						) {
							const schoolTypeFound = obj.schoolType.classWiseDetails.find(
								(ln) => ln.eduLevel === el
							);
							if (!schoolTypeFound) {
								schoolTypesArr.push({ eduLevel: el, schoolType: null });
							} else {
								schoolTypesArr.push(schoolTypeFound);
							}
						} else if (obj.schoolType.schoolType) {
							schoolTypesArr.push({
								eduLevel: el,
								schoolType: obj.schoolType.schoolType,
							});
						} else {
							schoolTypesArr.push({ eduLevel: el, schoolType: null });
						}
					}
					//board
					if (arr.length === 1) {
						if (obj.board.board && obj.board.board === "Custom") {
							const boardFound = obj.board.classWiseDetails.find(
								(ln) => ln.eduLevel === el
							);
							if (!boardFound) {
								boardArr.push({ eduLevel: el, board: null, otherBoard: "" });
								obj.board.board = null;
							} else {
								boardArr.push(boardFound);
								obj.board.board = boardFound.board;
							}
						} else if (obj.board.board && obj.board.board === "Other") {
							const otherBoard =
								obj.board.classWiseDetails.length !== 0
									? obj.board.classWiseDetails.find(
											(ln) => ln.otherBoard !== null
									  )
									: null;
							if (otherBoard) {
								boardArr.push({
									eduLevel: el,
									board: "Other",
									otherBoard: otherBoard.otherBoard,
								});
							} else {
								boardArr.push({ eduLevel: el, board: "Other", otherBoard: "" });
							}
						} else if (obj.board.board) {
							boardArr.push({
								eduLevel: el,
								board: obj.board.board,
								otherBoard: "",
							});
						} else {
							boardArr.push({ eduLevel: el, board: null, otherBoard: "" });
						}
					} else {
						if (obj.board.board && obj.board.board === "Custom") {
							const boardFound = obj.board.classWiseDetails.find(
								(ln) => ln.eduLevel === el
							);
							if (!boardFound) {
								boardArr.push({ eduLevel: el, board: null, otherBoard: "" });
							} else {
								boardArr.push(boardFound);
							}
						} else if (obj.board.board && obj.board.board === "Other") {
							const otherBoard =
								obj.board.classWiseDetails.length !== 0
									? obj.board.classWiseDetails.find(
											(ln) => ln.otherBoard !== null
									  )
									: null;
							if (otherBoard) {
								boardArr.push({
									eduLevel: el,
									board: "Other",
									otherBoard: otherBoard.otherBoard,
								});
							} else {
								boardArr.push({ eduLevel: el, board: "Other", otherBoard: "" });
							}
						} else if (obj.board.board) {
							boardArr.push({
								eduLevel: el,
								board: obj.board.board,
								otherBoard: "",
							});
						} else {
							boardArr.push({ eduLevel: el, board: null, otherBoard: "" });
						}
					}
				}
			}
			setFormData({
				...formData,
				educationLevel: arr,
				language: {
					...formData.language,
					classWiseDetails: langsArr,
				},
				schoolType: {
					...formData.schoolType,
					classWiseDetails: schoolTypesArr,
				},
				board: {
					...formData.board,
					classWiseDetails: boardArr,
				},
			});
		};

		const onOtherBoardChange = (event) => {
			if (hasOnlyLettersAndSpaces(event.target.value)) {
				setBoardOfStudy({ boardOfStudy: event.target.value });
			}
		};

		const onBoardTypeChange = (data) => {
			let classList = [...formData.board.classWiseDetails];
			if (showModal) {
				if (data.value.toLowerCase() === "custom") {
					let prev: any = prevData;
					if (prev.board.board.toLowerCase() !== "custom") {
						classList.forEach((el) => {
							// console.log(el)
							// let prev:any = prevData
							let prevBoardData: any = prev?.board?.classWiseDetails?.find(
								(board) => board.eduLevel === el.eduLevel
							);
							if (prevBoardData) {
								// console.log(prevBoardData)
								if (prevBoardData.board.toLowerCase() === "other") {
									el.board = prevBoardData.board;
									el.otherBoard = prev?.board?.board;
								}
								// else if(!config.boardOptions.find(e => e.value === prevBoardData.board) && prevBoardData.board !== null){
								//     el.board = prevBoardData.board
								//     el.otherBoard = prev?.board?.board
								// }
								else {
									el.board = prevBoardData.board;
								}
							} else {
								el.board = boardOptions[0].value;
							}
						});
					} else if (prev.board.board.toLowerCase() === "custom") {
						let prev: any = prevData;
						// console.log(classList)
						classList.forEach((el) => {
							let prevBoardData: any = prev?.board?.classWiseDetails?.find(
								(board) => board.eduLevel === el.eduLevel
							);
							if (prevBoardData) {
								if (
									!config.boardOptions.find(
										(e) => e.value === prevBoardData.board
									) &&
									prevBoardData.board !== null
								) {
									el.board = "Other";
									el.otherBoard = prevBoardData.board;
								} else {
									el.board = prevBoardData.board;
								}
							} else {
								el.board = boardOptions[0].value;
							}
						});
					}
				} else {
					classList.forEach((el) => {
						console.log(el);
						el.board = data.value;
					});
				}
			} else {
				if (data.value.toLowerCase() === "custom") {
					classList.forEach((el) => {
						// console.log(el)
						// let prev:any = prevData
						// let boardData: any = formData?.board?.classWiseDetails?.find((board) => board.eduLevel === el.eduLevel)
						// if (boardData && boardData.board) {
						//     // el.board = prevBoardData.board
						//     // el.otherBoard = prev?.board?.board
						//     // else if(!config.boardOptions.find(e => e.value === prevBoardData.board) && prevBoardData.board !== null){
						//     //     el.board = prevBoardData.board
						//     //     el.otherBoard = prev?.board?.board
						//     // }
						//     el.board = boardData.board
						// }
						// else {
						el.board = null;
						// }
					});
				} else {
					classList.forEach((el) => {
						console.log(el);
						el.board = data.value;
					});
				}
			}
			for (let i = 0; i < classList.length; i++) {
				if (!classList[i]["otherBoard"]) classList[i]["otherBoard"] = "";
			}
			setFormData({
				...formData,
				board: {
					classWiseDetails: classList,
					board: data.value,
				},
			});
		};

		const onLangChange = (data) => {
			let classList = [...formData.language.classWiseDetails];
			if (showModal) {
				if (data.value.toLowerCase() === "custom") {
					classList.forEach((el) => {
						// console.log(el)
						let prev: any = prevData;
						let prevLangData: any = prev?.language?.classWiseDetails?.find(
							(language) => language.eduLevel === el.eduLevel
						);
						if (prevLangData) {
							// console.log(prevBoardData)
							el.language = prevLangData.lang || prevLangData.language;
						} else {
							el.language = langOptions[0].value;
						}
					});
				} else {
					classList.forEach((el) => {
						el.language = data.value;
					});
				}
			} else {
				if (data.value.toLowerCase() === "custom") {
					classList.forEach((el) => {
						// console.log(el)
						// let langData: any = formData?.language?.classWiseDetails?.find((language) => language.eduLevel === el.eduLevel)
						// if (langData && langData.language) {
						//     // console.log(prevBoardData)
						//     el.language = langData.language
						// }
						// else {
						el.language = null;
						// }
					});
				} else {
					classList.forEach((el) => {
						el.language = data.value;
					});
				}
			}
			setFormData({
				...formData,
				language: {
					classWiseDetails: classList,
					language: data.value,
				},
			});
		};

		const onEduLangChange = (data, index) => {
			const obj = { ...formData.language };
			obj.classWiseDetails[index].language = data.value;
			setFormData({
				...formData,
				language: { ...obj },
			});
		};

		const onEduBoardChange = (data, index) => {
			const obj = { ...formData.board };
			obj.classWiseDetails[index].board = data.value;
			obj.classWiseDetails[index].otherBoard = "";
			setFormData({
				...formData,
				board: { ...obj },
			});
		};

		const onCustomOtherChange = (event, index) => {
			if (hasOnlyLettersAndSpaces(event.target.value)) {
				const obj = { ...formData.board };
				obj.classWiseDetails[index].otherBoard = event.target.value;
				setFormData({
					...formData,
					board: { ...obj },
				});
			}
		};

		const onEduSchoolTypeChange = (data, index) => {
			const obj = { ...formData.schoolType };
			obj.classWiseDetails[index].schoolType = data.value;
			setFormData({
				...formData,
				schoolType: { ...obj },
			});
		};

		const onStateCityChange = (dataState, dataCity) => {
			if (showModal === undefined) {
				dataCity = dataCity.toLowerCase();
				setFormData({ ...formData, state: dataState, city: dataCity });
				setStateName(dataState);
				setDistrictName(dataCity);
			}
		};

		const onSchoolAreaChange = (data) => {
			console.log(data);
			if (typeof data === "object") {
				if (checkIfNumberOnly(data.value) || data.value === "") {
					setFormData({
						...formData,
						builtUpArea: data.value === "" ? data.value : Number(data.value),
					});
					if (data.value !== "" && Number(data.value) >= 100) {
						setBuiltError(false);
					} else {
						setBuiltError(true);
					}
				}
			} else {
				setFormData({
					...formData,
					builtUpArea: data,
				});
				if (data >= 100) {
					setBuiltError(false);
				}
				setBuiltError(true);
			}
		};

		const updateNumber = (newNum, formKey) => {
			setFormData({
				...formData,
				[formKey]: newNum,
			});
		};

		const confirmAlertCallback = (data) => {
			setOverlay(data.overlay);
		};

		const confirmClose = () => {
			setOverlay(false);
			setToggleAlertPopUp(false);
		};

		const onMapLoaded = () => {
			// mapRef = useRef()
		};
		useImperativeHandle(ref, () => ({
			formValidator,
		}));

		const [errorMsgTeacher, setErrorMsgTeacher] = useState("");
		formValidator.current.rules["teacherLimit"] = {
			message: errorMsgTeacher,
			rule: (val) => {
				let returnValue = true;
				if (parseInt(val) === 0) {
					setErrorMsgTeacher("The number of teachers can not be 0.");
					returnValue = false;
				}
				// else if (parseInt(val) > 1000) {
				//     setErrorMsgTeacher("Number Of Teachers can be maximum 1000")
				//     returnValue = false
				// }

				return returnValue;
			},
		};
		const withValueCap = (inputObj) => {
			const { value } = inputObj;
			if (value <= 5000000) return true;
			return false;
		};
		const onLocalAreaChange = (data) => {
			console.log(data, formData.localArea);

			setFormData({
				...formData,
				localArea: data.value,
			});
		};
		useEffect(() => {
			if (localAreaOption.length === 1) {
				setFormData({
					...formData,
					localArea: localAreaOption[0].value,
				});
			}
		}, [localAreaOption]);

		useEffect(() => {
			if (isBackendEdit) {
				if (showModal) {
					process.nextTick(() => {
						let data = { ...formData };
						if (
							!config.boardOptions.find(
								(e) => e.value === formData.board.board
							) &&
							formData.board.board !== null
						) {
							console.log("wrking-->>", formData.board.board);
							setBoardOfStudy({ boardOfStudy: formData.board.board });
							data.board.board = "Other";
						}
						// setTimeout(() => {
						setFormData(data);
						// }, 1000);
					});
				}
			}
		}, [showModal, resetData]);

		useEffect(() => {
			if (isBackendEdit) {
				if (
					!config.boardOptions.find((e) => e.value === formData.board.board) &&
					formData.board.board !== null
				) {
					setResetData(!resetData);
				}
			}
		}, [showModal, formData]);

		formValidator.current.rules["city_name"] = {
			message: "Please enter valid city name.",
			rule: (val) => {
				let returnValue = context.utils.cityNameRegex.test(val);
				return returnValue;
			},
		};
		formValidator.current.rules["school_name"] = {
			message: "Please enter valid school name.",
			rule: (val) => {
				let returnValue = validateSchoolName(val);

				return returnValue;
			},
		};

		console.log("boardOfStudyData--->>", boardOfStudyData);
		const handleInfraDataChange = (e, index, value) => {
			if (formData.infraDetails) {
				let infraAllItems = objectDeepClone(formData.infraDetails);
				let infraItem = objectDeepClone(formData.infraDetails[index]);
				infraItem["value"] = value;
				infraAllItems.splice(index, 1, infraItem);
				setInfraDetails([...infraAllItems]);
				setFormData({
					...formData,
					infraDetails: [...infraAllItems],
				});
			} else {
				let infraAllItems = objectDeepClone(infraDetails);
				let infraItem = objectDeepClone(infraDetails[index]);
				infraItem["value"] = value;
				infraAllItems.splice(index, 1, infraItem);
				setInfraDetails([...infraAllItems]);
				setFormData({
					...formData,
					infraDetails: [...infraAllItems],
				});
			}
		};
		return (
			<>
				<div
					className={`ovelap ${toggleAlertPopUp === true ? "active" : ""}`}
				></div>
				<div className="row mb40">
					<div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
						<>
							{isEdit === false && (
								<a
									onClick={() => toggleSidemenu()}
									href="javascript:void(0)"
									className={`hummob ${
										showSidemenu === true ? "no_highlight" : ""
									}`}
								>
									<img src={menu} />
								</a>
							)}
							{!hideHeading && (
								<>
									<h2 className="htx1 mb40">
										{showModal && isEdit
											? "Edit Details"
											: "Tell us about your school"}
									</h2>
									{showPrincipleDetails && (
										<div className="mb40">
											<h3 className="htx2 mb20">Principal Details</h3>
											{/* <div className="row mb20" style={{justifyContent: "space-between", padding: "0.5rem"}}> */}
											<div className="mb10 ptx2">
												Name:{" "}
												<span className="ptx2">
													{principleDetails?.fullName}
												</span>
											</div>
											<div className="mb10 ptx2">
												Email:{" "}
												<span className="ptx2">{principleDetails?.email}</span>
											</div>
											<div className="mb10 ptx2">
												Phone:{" "}
												<span className="ptx2">{principleDetails?.phone}</span>
											</div>
											{/* </div> */}
										</div>
									)}
									<h3 className="htx2 mb20">
										{showModal && isEdit
											? "School Details"
											: "Infrastructure Details"}
									</h3>
								</>
							)}
						</>
					</div>
					{isBackendEdit && (
						<div
							className={`col-xl-12 col-lg-12 col-md-12 col-xs-12 ${
								isEdit === true ? "pl" : ""
							}`}
						>
							<Textfield
								maxLength={50}
								formData={schoolName}
								formKey="schoolName"
								onDataChange={onSchoolNameChange}
								label="School Name"
								placeholder="E.g. Delhi Public School"
								isRequired={false}
								formValidator={formValidator}
								validation="required|school_name"
							/>
						</div>
					)}
					<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
						<div className="tol-built">
							<p>
								Total Built-up Area:{" "}
								{/* <div className="helpText">(Max. 10 L)</div>*/}
							</p>
							<SliderComponent
								min={minbuiltupArea}
								max={maxBuiltupArea}
								value={formData.builtUpArea}
								onSliderValueChange={onSchoolAreaChange}
								changeError={setBuiltError}
							/>
							<span
								className={
									builtError && showBuildUpError && formData.builtUpArea === ""
										? "onFocus"
										: ""
								}
							>
								{/* <input
                                onChange={onSchoolAreaChange}
                                value={formData.builtUpArea}
                                type='text'
                            /> */}
								<NumberFormat
									type="text"
									thousandSeparator={true}
									thousandsGroupStyle="lakh"
									value={formData.builtUpArea}
									onValueChange={onSchoolAreaChange}
									isAllowed={withValueCap}
								/>
								<b>sq ft.</b>
							</span>

							<div className="imgSchool sdf">
								<img
									src={
										formData.builtUpArea < 350000
											? schoolImgSmall
											: formData.builtUpArea >= 350000 &&
											  formData.builtUpArea <= 750000
											? schoolImgMedium
											: formData.builtUpArea > 750000
											? schoolImgLarge
											: schoolImgSmall
									}
								/>
							</div>
							{builtError && showBuildUpError && (
								<span className="valError">
									<img src={errorSVG} />
									{formData.builtUpArea === ""
										? "Total built up area is required."
										: formData.builtUpArea < 100 &&
										  "The minimum total build up area value is 100 sq ft."}
								</span>
							)}
						</div>
					</div>

					<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt20"></div>
					<div className="row col-xl-7 col-lg-7 col-md-7 col-sm-12 col-xs-12 middle-xs mb40">
						<p className="ptx2">Number of Floors: </p>
						<div className="helpText w100">
							If your school does not have floors, please add "0"
						</div>
					</div>
					<div className="row col-xl-5 col-lg-5 col-md-5 col-sm-12 col-xs-12 end-md mb40">
						<NumWithPlusMinus
							maxLimit={20}
							minLimit={0}
							formData={formData}
							onDataChange={onFormDataChange}
							updateNumber={updateNumber}
							formKey="floors"
							formValidator={formValidator}
							validation="required"
							errorClass={formData.floors === "" ? true : false}
						/>
						{floorError && (
							<p
								style={{
									color: "tomato",
									fontSize: "0.8rem",
									marginTop: "0.5rem",
									marginLeft: "0.5rem",
								}}
							>
								Please provide valid floor number
							</p>
						)}
					</div>
					{isBackendUser && (
						<div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
							<div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
								<h3 className="htx2 mb20">Amenities</h3>
							</div>
							{infraDetails.length > 0 &&
								infraDetails.map((item, index) => (
									<div
										className="forminpt checkboxinput"
										style={{ marginBottom: "10px" }}
									>
										<div
											className="row"
											style={{ justifyContent: "space-between" }}
										>
											<span
												className="col-xl-3 col-lg-3 col-md-3 col-xs-12"
												style={{ alignSelf: "center", marginTop: "8px" }}
											>
												{item.infraName}
											</span>
											<div className="checkboxinputList col-xl-9 col-lg-9 col-md-9 col-xs-12">
												<div
													className={`radioBtn radioBtnInfra ${
														item.value === 0 ? "radioBtnActive" : ""
													}`}
													style={{ margin: "0 10px 10px 0" }}
												>
													<input
														type="radio"
														id={`notAvailable${item.infraName}`}
														name={`radio-group${item.infraName}`}
														checked={item.value == "0"}
														onClick={(e: any) =>
															handleInfraDataChange(e, index, 0)
														}
													/>
													<label htmlFor={`notAvailable${item.infraName}`}>
														<span style={{ float: "left", marginTop: "5px" }}>
															Unavailable
														</span>
													</label>
												</div>
												<div
													className={`radioBtn radioBtnInfra ${
														item.value === 1 ? "radioBtnActive" : ""
													}`}
													style={{ margin: "0 10px 10px 0" }}
												>
													<input
														type="radio"
														id={`poor${item.infraName}`}
														name={`radio-group${item.infraName}`}
														checked={item.value == "1"}
														onClick={(e: any) =>
															handleInfraDataChange(e, index, 1)
														}
													/>
													<label htmlFor={`poor${item.infraName}`}>
														<span style={{ float: "left", marginTop: "5px" }}>
															Poor
														</span>
														<span style={{ marginTop: "2px" }}>
															<img src={poorSvg} />
														</span>
													</label>
												</div>
												<div
													className={`radioBtn radioBtnInfra ${
														item.value === 2 ? "radioBtnActive" : ""
													}`}
													style={{ margin: "0 10px 10px 0" }}
												>
													<input
														type="radio"
														id={`average${item.infraName}`}
														name={`radio-group${item.infraName}`}
														checked={item.value == "2"}
														onClick={(e: any) =>
															handleInfraDataChange(e, index, 2)
														}
													/>
													<label htmlFor={`average${item.infraName}`}>
														<span style={{ float: "left", marginTop: "5px" }}>
															Average
														</span>
														<span style={{ marginTop: "2px" }}>
															<img src={averageSvg} />
														</span>
													</label>
												</div>
												<div
													className={`radioBtn radioBtnInfra ${
														item.value === 3 ? "radioBtnActive" : ""
													}`}
													style={{ margin: "0 0 10px 0" }}
												>
													<input
														type="radio"
														id={`excellent${item.infraName}`}
														name={`radio-group${item.infraName}`}
														checked={item.value == "3"}
														onClick={(e: any) =>
															handleInfraDataChange(e, index, 3)
														}
													/>
													<label htmlFor={`excellent${item.infraName}`}>
														<span style={{ float: "left", marginTop: "5px" }}>
															Excellent
														</span>
														<span style={{ marginTop: "2px" }}>
															<img src={excellentSvg} />
														</span>
													</label>
												</div>
											</div>
										</div>
									</div>
								))}
						</div>
					)}
				</div>
				<div className="row">
					<div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
						<h3 className="htx2 mb20">General Details</h3>
					</div>
					<div className="col-xl-6 col-lg-6 col-md-7 col-xs-12">
						<MultiSelect
							formData={formData}
							formKey="educationLevel"
							onDataChange={onEduLevelsChange}
							label="Education Level"
							placeholder="Select Level of Education"
							isRequired={false}
							options={
								formData && formData.educationLevel.length < 5
									? config.eduLevels
									: config.eduLevels.filter(
											(labels) => labels.label !== "All of the Above"
									  )
							}
							formValidator={formValidator}
							validation="required|array|min:1"
						/>
					</div>
					<div className="col-xd-12 col-lg-12 col-md-12 col-xs-12"></div>
					<div
						className={`col-xl-6 col-lg-6 col-md-7 col-xs-12
                ${formData.board.board === "Other" ? "" : ""}`}
					>
						<SingleSelect
							formData={formData.board}
							formKey="board"
							onDataChange={onBoardTypeChange}
							label="Board Of Study"
							placeholder="Select Your Board"
							isRequired={false}
							options={
								formData && formData.educationLevel.length > 1
									? boardOptions
									: boardOptions.filter((lebels) => lebels.label !== "Custom")
							}
							formValidator={formValidator}
							validation="required"
						/>
						{formData.board.board === "Other" && (
							<TextFieldWithValidation
								ref={otherBoardReference}
								formData={boardOfStudyData}
								formKey="boardOfStudy"
								onDataChange={onOtherBoardChange}
								placeholder="Enter Board Name"
								isRequired={false}
								// formValidator={formValidator}
								validation="required"
								maxLength={50}
								label="board"
							/>
						)}
					</div>
					<div className="col-xd-12 col-lg-12 col-md-12 col-xs-12"></div>
					{formData?.board?.board === "Custom" &&
						formData.board.classWiseDetails.length > 0 && (
							<div className="col-xl-7 col-lg-7 col-md-7 col-xs-12">
								<div className="customeShowdiv">
									<div className="row">
										<div className="forminpt">
											<label>
												Select the board of education for the education levels
											</label>
										</div>
										{formData.board.classWiseDetails.map((el, index) => {
											return (
												<div
													key={index}
													className={`col-xl-12 col-lg-12 col-md-12 col-xs-12
                                                ${
																									formData.board
																										.classWiseDetails[index]
																										.board === "Other"
																										? "mb-10"
																										: ""
																								}
                                            `}
												>
													<SingleSelect
														formData={formData.board.classWiseDetails[index]}
														formKey="board"
														onDataChange={(data) =>
															onEduBoardChange(data, index)
														}
														label={el.eduLevel}
														placeholder="Select Your Board"
														isRequired={false}
														options={boardOptions.filter(
															(el) => el.value !== "Custom"
														)}
														formValidator={formValidator}
														validation="required"
													/>
													{formData.board.classWiseDetails[index].board ===
														"Other" && (
														<TextFieldWithValidation
															ref={otherBoardRef[index]}
															formData={formData.board.classWiseDetails[index]}
															formKey="otherBoard"
															onDataChange={(event) =>
																onCustomOtherChange(event, index)
															}
															placeholder="Enter Board Name"
															isRequired={false}
															validation="required"
															label={el.eduLevel}
															maxLength={50}
														/>
													)}
												</div>
											);
										})}
									</div>
								</div>
							</div>
						)}
					<div className="col-xl-6 col-lg-6 col-md-7 col-xs-12">
						<SingleSelect
							formData={formData.language}
							formKey="language"
							onDataChange={onLangChange}
							label="Medium of Study"
							placeholder="Select Medium of study"
							isRequired={false}
							options={
								formData && formData.educationLevel.length > 1
									? langOptions
									: langOptions.filter((lebels) => lebels.label !== "Custom")
							}
							formValidator={formValidator}
							validation="required"
						/>
					</div>
					<div className="col-xd-12 col-lg-12 col-md-12 col-xs-12"></div>
					{formData?.language?.language === "Custom" &&
						formData.language.classWiseDetails.length > 0 && (
							<div className="col-xl-7 col-lg-7 col-md-7 col-xs-12">
								<div className="customeShowdiv">
									<div className="row">
										<div className="forminpt">
											<label>
												Select the medium of education for the education levels
											</label>
										</div>
										{formData.language.classWiseDetails.map((el, index) => {
											return (
												<div
													key={index}
													className="col-xl-12 col-lg-12 col-md-12 col-xs-12"
												>
													<SingleSelect
														formData={formData.language.classWiseDetails[index]}
														formKey="language"
														onDataChange={(data) =>
															onEduLangChange(data, index)
														}
														label={el.eduLevel}
														placeholder="Select Medium of study"
														isRequired={false}
														options={langOptions.filter(
															(el) => el.value !== "Custom"
														)}
														formValidator={formValidator}
														validation="required"
													/>
												</div>
											);
										})}
									</div>
								</div>
							</div>
						)}
					<div className="col-xd-12 col-lg-12 col-md-12 col-xs-12"></div>
					<div className="col-xl-6 col-lg-6 col-md-7 col-xs-12">
						<SingleSelect
							formData={formData.schoolType}
							formKey="schoolType"
							onDataChange={onSchoolTypeChange}
							label="Type of School"
							placeholder="Select Type of School"
							isRequired={false}
							options={
								formData && formData.educationLevel.length > 1
									? schoolTypeOptions
									: schoolTypeOptions.filter(
											(lebels) => lebels.label !== "Custom"
									  )
							}
							formValidator={formValidator}
							validation="required"
						/>
					</div>
					{formData?.schoolType?.schoolType === "Custom" &&
						formData.schoolType.classWiseDetails.length > 0 && (
							<div className="col-xl-7 col-lg-7 col-md-7 col-xs-12">
								<div className="customeShowdiv">
									<div className="row">
										<div className="forminpt">
											<label>
												Select the type of school for the education levels
											</label>
										</div>
										{formData.schoolType.classWiseDetails.map((el, index) => {
											return (
												<div
													key={index}
													className="col-xl-12 col-lg-12 col-md-12 col-xs-12"
												>
													<SingleSelect
														formData={
															formData.schoolType.classWiseDetails[index]
														}
														formKey="schoolType"
														onDataChange={(data) =>
															onEduSchoolTypeChange(data, index)
														}
														label={el.eduLevel}
														placeholder="Select Type of School"
														isRequired={false}
														options={schoolTypeOptions.filter(
															(el) => el.value !== "Custom"
														)}
														formValidator={formValidator}
														validation="required"
													/>
												</div>
											);
										})}
									</div>
								</div>
							</div>
						)}
					<div className="col-xd-12 col-lg-12 col-md-12 col-xs-12"></div>
					{showModal === undefined && (
						<div className="col-xl-6 col-lg-6 col-md-7 col-xs-12">
							<div className="forminpt">
								<label>Number Of Teachers</label>
								<Numberfield
									formData={formData}
									formKey="noOfTeachers"
									onDataChange={onFormDataChange}
									placeholder="Enter the Number of Teachers"
									isRequired={false}
									formValidator={formValidator}
									validation="required|teacherLimit"
								/>
							</div>
						</div>
					)}
					{isBackendEdit && (
						<div className="col-xl-6 col-lg-6 col-md-7 col-xs-12">
							<div className="forminpt">
								<label>Number Of Teachers</label>
								<Numberfield
									formData={formData}
									formKey="noOfTeachers"
									onDataChange={onFormDataChange}
									placeholder="Enter the Number of Teachers"
									isRequired={false}
									formValidator={formValidator}
									validation="required|teacherLimit"
								/>
							</div>
						</div>
					)}
					{isBackendEdit && (
						<div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
							<div className="forminpt">
								<label>Address</label>
								<DebounceInput
									debounceTimeout={1000}
									id="address"
									placeholder="Enter your School Address"
									value={formData.address}
									onChange={onFormDataChange}
									className={
										formValidator?.current?.messagesShown === true &&
										formValidator?.current?.fields?.["address"] === false
											? "onFocus"
											: ""
									}
								/>
								{formValidator?.current?.message(
									"address",
									formData.address,
									"required"
								)}
							</div>
						</div>
					)}
					{showModal === undefined && (
						<div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-20">
							<h3 className="htx2 mb20">Location Details</h3>
						</div>
					)}

					{showModal === undefined && (
						<>
							<div className="col-xl-6 col-lg-6 col-md-6 col-xs-12">
								<Textfield
									formData={formData}
									formKey="pincode"
									maxLength={6}
									onDataChange={onFormDataChange}
									label="Pin Code"
									placeholder="Enter the pin code"
									isRequired={false}
									formValidator={formValidator}
									validation="required|min:6"
									isPincodeValid={isPinCodeValid}
								/>
							</div>
							<div className="col-xl-6 col-lg-6 col-md-6 col-xs-12">
								<SingleSelect
									formData={formData}
									formKey="localArea"
									onDataChange={onLocalAreaChange}
									label="Area/Region"
									placeholder="Select Area"
									isRequired={false}
									options={
										localAreaOption && localAreaOption.length > 0
											? localAreaOption
											: []
									}
									formValidator={formValidator}
									validation="required"
								/>
							</div>
						</>
					)}

					{showModal === undefined && (
						<div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
							<div className="forminpt">
								<label>Address</label>
								<DebounceInput
									debounceTimeout={1000}
									id="address"
									placeholder="Enter your School Address"
									value={formData.address}
									onChange={onAddressChange}
									maxLength={100}
									className={
										formValidator?.current?.messagesShown === true &&
										formValidator?.current?.fields?.["address"] === false
											? "onFocus"
											: ""
									}
								/>
								{formValidator?.current?.message(
									"address",
									formData.address,
									"required"
								)}
							</div>
						</div>
					)}

					<div className="clearfix col-xl-12 col-lg-12 col-md-12 col-xs-12"></div>
					{showModal === undefined && (
						<div className="col-xl-6 col-lg-6 col-md-6 col-xs-12">
							<Textfield
								formData={formData}
								formKey="state"
								onDataChange={onFormDataChange}
								label="State"
								placeholder="Select your State"
								isRequired={false}
								formValidator={formValidator}
								validation="required|alpha_space"
								maxLength={50}
							/>
						</div>
					)}
					{showModal === undefined && (
						<div className="col-xl-6 col-lg-6 col-md-6 col-xs-12">
							<Textfield
								formData={formData}
								formKey="city"
								onDataChange={onFormDataChange}
								label="City"
								placeholder="Select your City"
								isRequired={false}
								formValidator={formValidator}
								validation="required|city_name"
								maxLength={50}
							/>
						</div>
					)}
					<div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
						<div className="map-loader">
							{showModal === undefined && (
								<GMaps
									formData={formData}
									setFormData={setFormData}
									ref={mapRef}
									onMapLoaded={onMapLoaded}
									setIsMapLoading={setIsMapLoading}
								/>
							)}
							{showModal === undefined && (
								<div
									className={`upload-image-loading maploader ${
										isMapLoading === false ? "dnone" : "active"
									}`}
								>
									<div className="button--loading">Loading....</div>
								</div>
							)}
						</div>
					</div>
					<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-20 end-xs">
						<div className="bottom-error-btn">
							<p className="valError">
								{errorMessage && (
									<>
										<img src={errorSVG} />
										{errorMessage}
									</>
								)}
							</p>
							{/* <div className='forminpt'>
                            <input
                                onClick={submitForm}
                                name='Proceed'
                                value='Proceed'
                                className='btn btn-orange sc-ameties'
                                type='button'
                            />
                        </div> */}
						</div>
					</div>
				</div>

				<AlertDialog
					togglePopup={toggleAlertPopUp}
					fieldInfo={"Pin Code"}
					details={"Entered pin code is not valid"}
					onModalClose={confirmClose}
					displayMsg={"Entered pin code is not valid"}
					showBtn={true}
				/>
			</>
		);
	}
);

export default SchoolGeneralDetail;
