import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import arrowUpDown from "../../../../assets/images/backendAdmin/arrow-up-down.svg";
import blankImg from '../../../../assets/images/blank-inventory-img.jpg';
import AppContextObject from "../../../../common/context/common";

const SpecificCampaignTableSpan = ({campaign,keyNo,currentPage,campaignType,acceptRejectPayment, acceptingCampaign}) => {
    const [openRow, setOpenRow] = useState(false);
    const context = useContext(AppContextObject)
    return (
        <>
            <tr>
                <td>{(keyNo) + (currentPage - 1) * 10}</td>
                <td>{campaign.name}</td>
                <td>{campaign.schools.length}</td>
                <td>{campaign.campaignData && campaign.campaignData.inventories && campaign.campaignData.inventories.length}</td>
                <td>{campaign.startDate} - {campaign.endDate}</td>
                <td>₹ {campaign.budget}</td>
                <td><span className={`table-status ${campaign.campaignStatus==='rejected' ? 'redbg' : campaign.campaignStatus === "executed" ? "graybg" : campaign.campaignStatus === "scheduled" ? "orangebg" : campaign.campaignStatus==='ongoing' ? 'yellowbg' : campaign.campaignStatus==='in-review' && campaign.isApproved ? "greenbg" : campaign.campaignStatus ==="on-hold" ? "onholdbg" : "orangebg"} `}> {campaign.campaignStatus==='rejected' ? 'Rejected' : campaign.campaignStatus==='ongoing' ? 'Ongoing' : campaign.campaignStatus === "executed" ? "Executed" : campaign.campaignStatus === "scheduled" ? "Scheduled" : campaign.campaignStatus==='in-review' && campaign.isApproved ? "Approved" : campaign.campaignStatus === "on-hold" ? "On-Hold" : "In-Review"}</span></td>
                <td>{campaignType !== 'rejected' &&<span className= {`table-status ${campaign?.paymentStatus==='paid' ? 'greenbg' : 'orangebg'}`}>{campaign?.paymentStatus==='paid' ? 'Paid' : 'Pending'}</span>}
                    <div className="arrowset arrowCampaign"> <span onClick={() => setOpenRow(!openRow)}>
                        <svg className="down-arrow requestArrow" width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 6L6 0.999999L11 6" stroke="#8C8C8C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </span>
                    {/* <Link to={{
                        pathname: "/backend/backend-details/create-trial-campaign-details",
                        state: { campaignType: campaign.isTrial ? 'trial' : 'create', from:'campaign', campaignId:campaign._id }
                    }}> */}
                    <Link to={{
                        pathname: "/backend/backend-details/create-trial-campaign-details",
                        state: { campaignType: campaign.isTrial ? 'trial' : 'create', from:'campaign', campaignId:campaign._id }
                    }}><img className="updownarrow" src={arrowUpDown} /></Link>
                    </div>
                    {/* </Link> */}
                </td>
            </tr>
            {openRow && <tr>
                <td className="campaignRequestOepn" colSpan={8}>
                    <table className="table">
                        <tbody>
                            <td>
                                <div className="campaignRequestSlide">
                                    <div className="campaignRequestSlideLarge">
                                            <img src={(campaign.campaignCreatives.length>0 && campaign.campaignCreatives[0].image) ? campaign.campaignCreatives[0].image : blankImg } />
                                        </div>
                                        <div className="campaignRequestSlideSmall">
                                            <img src={(campaign.campaignCreatives.length>1 && campaign.campaignCreatives[1].image) ? campaign.campaignCreatives[1].image : blankImg } />
                                            <img src={(campaign.campaignCreatives.length>2 && campaign.campaignCreatives[2].image) ? campaign.campaignCreatives[2].image : blankImg } />
                                        </div>
                                </div>
                            </td>
                            <td>
                                <h4>Campaign Description</h4>
                                <p>{campaign.description}</p>
                            </td>
                            {context.user.role === "backendAdmin" && campaign.campaignStatus==='in-review' && campaign.isApproved === false && !campaign.creativeReworkNeeded && <td style={{ verticalAlign: "middle", width: "340px" }}>
                                <a href="javascript:void(0)" className={`btn btn-outline ${acceptingCampaign && "button--loading btn-disabled"}`} onClick={()=>{acceptRejectPayment('rejected',campaign._id);}}>{campaign.campaignCreativesRevised && campaign.campaignCreativesRevised.length>0 ? 'reject creatives' : 'reject'}</a>
                                <a href="javascript:void(0)" className="btn btn-orange" onClick={()=>{acceptRejectPayment('accepted',campaign._id);}}>{campaign.campaignCreativesRevised && campaign.campaignCreativesRevised.length>0 ? 'approve creatives' : 'approve'}</a>
                            </td>}
                        </tbody>
                    </table>
                </td>
            </tr>}
        </>

    )
}
export default SpecificCampaignTableSpan;