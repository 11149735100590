import { useState, useEffect, useContext } from "react";
import AppContextObject from "../../common/context/common";

import checkSign from "../../assets/images/smallSignedCheck.svg";
import approved from "../../assets/images/Approved.svg";

interface CrImagePreviewProps {
	activeImageIndexLocation: string;
}

// const CrImagePreview = (props) => {
const CrImagePreview = (props) => {
	const {
		setShowImagePreview,
		showImagePreview,
		images,
		selectedImageIndex,
		location,
		activeImageIndexLocation,
	} = props;

	console.log("location - ", location);

	console.log("activeImageIndexNewNew", activeImageIndexLocation);

	const defaultImage = "https://via.placeholder.com/450x330";

	let [imageList, setImageList] = useState(images);
	let [currentIndex, setCurrentIndex] = useState(selectedImageIndex);
	let [currentImage, setCurrentImage] = useState(
		images && images.length > 0 ? images[selectedImageIndex] : defaultImage
	);

	useEffect(() => {
		setImageList(images);
		setCurrentImage(images && images[selectedImageIndex]);
	}, [images, showImagePreview, selectedImageIndex]);

	useEffect(() => {
		setCurrentImage(images && images[selectedImageIndex]);
		setCurrentIndex(selectedImageIndex);
	}, [selectedImageIndex]);

	const prevButtonClick = () => {
		if (currentIndex > 0) {
			setCurrentImage(images[currentIndex - 1]);
			setCurrentIndex(currentIndex - 1);
		}
	};

	const nextButtonClick = () => {
		if (currentIndex < images.length - 1) {
			setCurrentImage(images[currentIndex + 1]);
			setCurrentIndex(currentIndex + 1);
		}
	};
	const context = useContext(AppContextObject);
	const myFunction = async () => {
		let resp = await context.apis[
			context.user.role
		].getCommentsForCampaignReport(
			context.validator,
			{ imageId: currentImage._id },
			{}
		);
		console.log("getCommentsForCampaignReportNewwww", resp);
	};

	// const uploadTime = currentImage?.uploadTime;
	// const parsedTime = uploadTime ? new Date(`1970-01-01T${uploadTime}`) : null;

	// let formattedTime = "";
	// if (parsedTime) {
	// 	// const options = { hour: "numeric", minute: "numeric", hour12: true };
	// 	formattedTime = parsedTime.toLocaleTimeString(undefined);
	// }

	const uploadTime = currentImage?.uploadTime;
	const parsedTime = uploadTime ? new Date(`1970-01-01T${uploadTime}`) : null;

	let formattedTime = "";
	if (parsedTime) {
		const hours = parsedTime.getHours();
		const minutes = parsedTime.getMinutes();
		const amPM = hours >= 12 ? "PM" : "AM";
		const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
		formattedTime = `${formattedHours}:${minutes
			.toString()
			.padStart(2, "0")} ${amPM}`;
	}

	return (
		<>
			{/* <button onClick={myFunction}>Click Me</button> */}
			<div
				id="overlay"
				className="imageView crImageView"
				style={{
					display: showImagePreview === true ? "flex" : "none",
					border: "2px solid #2ec193",
				}}
			>
				{imageList && imageList.length > 1 && (
					<div id="prevButton" onClick={prevButtonClick}>
						<i className="fa fa-chevron-left"></i>
					</div>
				)}
				<img src={currentImage && currentImage.image} alt="Wisr" />

				{imageList && imageList.length > 1 && (
					<div id="nextButton" onClick={nextButtonClick}>
						<i className="fa fa-chevron-right"></i>
					</div>
				)}
				<div id="exitButton">
					<i
						onClick={() => setShowImagePreview(false)}
						className="fa fa-times"
					></i>
				</div>

				<div
					style={{
						height: "auto",
						fontSize: "12px",
						width: "auto",
						background: "rgb(0,0,0,0.6)",
						color: "rgb(255, 255, 255)",
						position: "absolute",
						left: "0%",
						top: "0%",
						padding: "3px 5px",
						borderRadius: "3px",
					}}
				>{`${currentImage?.placementName}`}</div>

				<div
					style={{
						height: "auto",
						fontSize: "12px",
						width: "auto",
						background: "rgb(0,0,0,0.6)",
						color: "rgb(255, 255, 255)",
						position: "absolute",
						left: "0%",
						bottom: "0%",
						padding: "3px 5px",
						borderRadius: "3px",
					}}
				>
					{/* {`${currentImage?.uploadDate}\u00A0/\u00A0${currentImage?.uploadTime}`}</div> */}
					{`${currentImage?.uploadDate}\u00A0\u00A0${formattedTime}`}
				</div>

				<div
					style={{
						display: "flex",
						background: "rgb(0,0,0,0.6)",
						color: "rgb(255, 255, 255)",
						position: "absolute",
						right: "0%",
						top: "0%",
						padding: "3px 5px",
						borderRadius: "3px",
					}}
				>
					<img
						style={{
							height: "20px",
							filter: "brightness(100%)",
							marginLeft: "auto",
							paddingRight: "5px",
							background: "black",
						}}
						src={checkSign}
						alt="Close"
					/>
					<img
						style={{
							height: "20px",
							filter: "brightness(100%)",
							background: "black",
						}}
						src={approved}
						alt="Approved"
					/>
				</div>

				<div
					style={{
						height: "auto",
						fontSize: "12px",
						width: "auto",
						maxWidth: "170px",
						background: "rgb(0,0,0,0.6)",
						color: "rgb(255, 255, 255)",
						position: "absolute",
						right: "0%",
						bottom: "0%",
						wordBreak: "break-word",
						// textAlign: "right",
						padding: "3px 5px",
						borderRadius: "3px",
						overflowWrap: "break-word",
					}}
				>
					{location && location}
				</div>
			</div>
		</>
	);
};

export default CrImagePreview;
