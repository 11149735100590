import React, { useEffect, useState, useContext, useRef } from 'react';
import filterArrowSvg from "../../../../assets/images/filter-arrow-svg.svg";
import config from "../../../../service/config";
import AppContextObject from "../../../../common/context/common";
import { PublicContextInitiator } from "../../../apis/publicContextInitiator";
import { titleCase } from '../../../../service/helper';
import useClickOutside from '../../../../service/useClickOutside';

var citiesSelected = [];
const CategoryFilterBrand = ({ selectedCategory, selectedDocStatus, selectedLocation, setSelectedCategory, setSelectedDocStatus, setOpenCategoryFilter,
    setSelectedLocation, setSelectedState, setSelectedCity, selectedState, selectedCity, stateList, cityList, setCurrentpage}) => {
    const context = useContext(AppContextObject);

    const [brandCategory, setBrandCategory] = useState([]);
    const [spanCategory, setSpanCategory] = useState(false);
    const [spanDocStatus, setSpanDocStatus] = useState(false);
    const [spanLocation, setSpanLocation] = useState(false);
    const [spanState, setSpanState] = useState(false)
    const [spanCity, setSpanCity] = useState(false)
    const filterRef = useRef(null)
    const filterOptionsRef = useRef(null)
    useClickOutside(filterOptionsRef, (event) => {
        const el = filterRef?.current;

        if (!el || el.contains(event.target)) {
            return;
        }
        setOpenCategoryFilter(false)
    })

    useEffect(() => {
        fetchBrandCategory();
    }, [])

    const fetchBrandCategory = async () => {

        let publicContextInitiator = new PublicContextInitiator(context)
        let resp = await publicContextInitiator.listBrandCategoriesMaster(
            context.validator, {}, {}
        )
        console.log('resp======>', resp)

        if (resp && resp.done) {
            setBrandCategory(resp.response.rows);
        }
    }

    const filterBrand = (type, value) => {
        setCurrentpage(1);
        if (type === 'category') {
            let array = [...selectedCategory]
            if (array.includes(value)) {
                array.splice(array.indexOf(value), 1)
            }
            else {
                array.push(value)
            }
            setSelectedCategory([...array])
        }
        else if (type === 'docStatus') {
            let array = [...selectedDocStatus]
            if (array.includes(value)) {
                array.splice(array.indexOf(value), 1)
            }
            else {
                array.push(value)
            }
            setSelectedDocStatus([...array])
        }
        else if(type === "state"){
            let array = selectedState
            if (array.includes(value)) {
                array.splice(array.indexOf(value), 1)
            }
            else {
                array.push(value)
            }
            setSelectedState([...array])
        }
        else if(type === "city"){
            let array = selectedCity
            if (array.includes(value)) {
                array.splice(array.indexOf(value), 1)
            }
            else {
                array.push(value)
            }
            setSelectedCity([...array])
        }
        // else {
        //     let array = [...selectedLocation]
        //     if (array.includes(value)) {
        //         array.splice(array.indexOf(value), 1)
        //     }
        //     else {
        //         array.push(value)
        //     }
        //     setSelectedLocation([...array])
        // }

    }

    const clearAllFilter = () => {
        setSelectedCategory([]);
        setSelectedDocStatus([]);
        setSelectedLocation([]);
        setSelectedState([]);
        setSelectedCity([]);
        setSpanState(false);
        setSpanCity(false)
        setSpanCategory(false);
        setSpanDocStatus(false);
        setSpanLocation(false);
        setOpenCategoryFilter(false)
    }
    console.log('brands cities======>', cityList)
    return (
        <div className={`filterDropDown open`}>
            <div className="filterDropHead" ref={filterRef}>
                <h2>Filter <a href="javascript:void(0);" className="viewwLink" onClick={clearAllFilter}>Clear All</a></h2>
            </div>
            <div className="filterDropBody" ref={filterOptionsRef}> 
                <div className={`filterDropClose ${spanCategory ? 'open' : ''}`}>
                    <h3><a href="javascript:void(0);" onClick={() => setSpanCategory(!spanCategory)} >{`Category`}  <img src={filterArrowSvg} /></a></h3>
                    <div className="filtercheckbox">
                        {brandCategory.map((item, index) => (
                            <div className="checkbox fnone" key={index}>
                                <input type="checkbox" id={item.name} checked={selectedCategory.includes(item.name)} />
                                <label htmlFor={item.name} onClick={() => filterBrand('category', item.name)}>{item.name}</label>
                            </div>
                        ))}
                    </div>
                </div>
                <div className={`filterDropClose ${spanDocStatus ? 'open' : ''}`}>
                    <h3><a href="javascript:void(0);" onClick={() => setSpanDocStatus(!spanDocStatus)} >{`Document Status`}  <img src={filterArrowSvg} /></a></h3>
                    <div className="filtercheckbox">
                        {config.brandDocumentStatus.map((item, index) => (
                            <div className="checkbox fnone" key={index} >
                                <input type="checkbox" id={item.value} checked={selectedDocStatus.includes(item.value)} />
                                <label htmlFor={item.value} onClick={() => filterBrand('docStatus', item.value)}>{item.label}</label>
                            </div>
                        ))}
                    </div>
                </div>
                {/* <div className={`filterDropClose ${spanLocation ? 'open' : ''}`}>
                    <h3><a href="javascript:void(0);" onClick={() => setSpanLocation(!spanLocation)} >{`Location`}  <img src={filterArrowSvg} /></a></h3>
                    <div className="filtercheckbox">
                        {cityList && cityList.sort((a, b) => a.localeCompare(b)).map((item, index) => (
                            <div className="checkbox fnone" key={index} >
                                <input type="checkbox" id={item} checked={selectedLocation.includes(item)} />
                                <label htmlFor={item} onClick={() => filterBrand('location', item)}>{item}</label>
                            </div>
                        ))}
                    </div>
                </div> */}

                <div className={`filterDropClose ${spanState ? 'open' : ''}`}>
                    <h3><a href="javascript:void(0);" onClick={() => setSpanState(!spanState)} >{`State`}  <img src={filterArrowSvg} /></a></h3>
                    <div className="filtercheckbox">
                        {stateList.map((item, index) => (
                            <div className="checkbox fnone" key={index} >
                                <input type="checkbox" id={item} checked={selectedState.includes(item)} />
                                <label htmlFor={item} onClick={() => filterBrand('state', item)}>{item}</label>
                            </div>
                        ))}
                    </div>
                </div>

                <div className={`filterDropClose ${spanCity && selectedState.length > 0 ? 'open' : ''}`}>
                    <h3><a href="javascript:void(0);" onClick={() => setSpanCity(!spanCity)} >{`City`}  <img src={filterArrowSvg} /></a></h3>
                    <div className="filtercheckbox">
                        {cityList.map((item, index) => (
                            <div className="checkbox fnone" key={index} >
                                <input type="checkbox" id={item} checked={selectedCity.includes(item)} />
                                <label htmlFor={item} onClick={() => filterBrand('city', item)}>{titleCase(item)}</label>
                            </div>
                        ))}
                    </div>
                </div>

            </div>
        </div>
    )
}
export default CategoryFilterBrand;