import { useState, useContext, useEffect, useRef } from "react"

import { useHistory } from "react-router-dom"

import OnboardingBackBtn  from "../components/onboardingBackBtn"
import backBtn from "../../../../assets/images/back-arrow.svg"
import search from "../../../../assets/images/search.svg"
import schOver1 from "../../../../assets/images/sch-over1.svg"
import schOver2 from "../../../../assets/images/sch-over2.svg"
import schOver3 from "../../../../assets/images/sch-over3.svg"
import schoolImg from "../../../../assets/images/school-img.jpg"
import backArrow1 from "../../../../assets/images/down-arrow1.svg"
import DataContext from "../../../../service/contextProvider"
import AppContextObject from "../../../../common/context/common"
import ImageModal from "../../../../components/addon/imageModal/imageModal"
import notification from "../../../../assets/images/noti-bell.svg"
import proEdit from "../../../../assets/images/edit-profile.svg"
import trashCan from "../../../../assets/images/trash-can.svg"
import addAdmin from "../../../../assets/images/add-admin.svg"
import OTPVerifyModal from "../../../../components/addon/otpVerifyModal"
import errorSVG from "../../../../assets/images/error.svg";
import SimpleReactValidator from "simple-react-validator"
import AlertDialog from "../../../../components/addon/alertDialog/alertDialog"
import check from "../../../../assets/images/check.svg"
import saveInput from "../../../../assets/images/save.svg"
import Textfield from "../../../../components/formFields/textField/textField"
import ConfirmDialog from "../../../../components/addon/confirmDialog/confirmDialog"

import { specialCharOnly, validateFullName } from "../../../../service/helper"
import TopBar from "../components/topBar"
import { toast } from "react-hot-toast"
import TextfieldWithPrefix from "../../../../components/formFields/textFieldWithPrefix/textFieldWithPrefix"

const MyProfile = (props) => {
    const {
        isEdit,
        methods
    } = props

    const history = useHistory()
    const parentData = useContext < any > (DataContext)
    const [schoolInfo, setSchoolInfo] = useState({
        name:"",
        state:"",
    })
    const [formValidator] = useState(useRef(new SimpleReactValidator({
            element: (message, className) =>
                <span className="valError">
                    {console.log(message)}
                    <img src={errorSVG} />
                    {message==='The user email field is required.'
                    ?
                    'The email id field is required.'
                    :
                    message === 'The phone field is required.'
                    ?
                    'The mobile no field is required.'
                    :
                    message === 'The phone must be a valid phone number.'
                    ?
                    'The mobile no must be a valid mobile no.'
                    :
                    message
                    }
                </span>
            })))
    const [adminFormValidator] = useState(useRef(new SimpleReactValidator({
        element: (message, className) =>
            <span className="valError">
                {console.log(message)}
                <img src={errorSVG} />
                {message==='The email field is required.'
                ?
                'The email id field is required.'
                :
                message === 'The phone field is required.'
                ?
                'The mobile no field is required.'
                :
                message === 'The phone must be a valid phone number.'
                ?
                'The mobile no must be a valid mobile no.'
                :
                message
                }
            </span>
        })))
    const [update, forceUpdate] = useState(false)
    let [overviewList, setOverviewList] = useState([])

    let [schoolDetails, setSchoolDetails] = useState([])
    let [activities, setActivities] = useState([])
    let [schoolTableLoading, setSchoolTableLoading] = useState(false)
    let [activityLoading, setActivityLoading] = useState(false)
    let [mainInfoLoading, setMainInfoLoading] = useState(false)
    let [overviewListCount] = useState([1, 2, 3, 4, 5, 6])
    let [toggleImage, setToggleImage] = useState(false)
    let [imageData, setImageData] = useState("");
    let [userFormData, setUserFormData] = useState({
        fullName: "",
        userEmail: "",
        phone: "",
        username: ""
    })
    let [userFetchedData, setUserFetchedData] = useState({
        fullName: "",
        userEmail: "",
        phone: "",
        username: ""
    })
    let [adminList, setAdminList] = useState([]);
    let [refetch, setRefetch] = useState(false);
    let [refetchAdmins, setRefetchAdmins] = useState(false)
    let [adminListloading, setAdminListLoading] = useState(false)
    let [selectedContactForOTP, setSelectedContactForOTP] = useState("");
    let [isMobileOTP, setIsMobileOTP] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [getVerifyEmailStatus, setGetVerifyEmailStatus] = useState(false);
    const [getVerifyPhoneStatus, setGetVerifyPhoneStatus] = useState(false);
    const [overlay, setOverlay] = useState(false)
    const [getVerifyUsernameStatus, setGetVerifyUsernameStatus] = useState(false);
    const [isUsernameVerified, setIsUsernameVerified] = useState(false);
    const [toggleAlertPopUp, setToggleAlertPopUp] = useState(false);
    const [getFieldInfo, setGetFieldInfo] = useState('');
    const [detailsCaptured, setDetailsCaptured] = useState('');
    const [msgDetails, setMsgDetails] = useState('');
    const [isPhoneVerified, setIsPhoneVerified] = useState(false);
    const [isEmailVerified, setIsEmailVerified] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState("Loading...")
    const [deleteAdminId, setDeleteAdminId] = useState("")
    const [editAdminData, setEditAdminData] = useState({
        _id: "",
        fullName: "",
        email: "",
        phone: ""
    })
    const [toggleConfirmDialogModal, setToggleConfirmDialogModal] = useState(false)
    const [confirmDialogArgs, setConfirmDialogArgs] = useState({
        confirmTitle: "",
        componentName: "",
        confirmText: "Yes",
        denyText: "No",
    })
    const [newAdmin, setNewAdmin] = useState({
        fullName: "",
        email: "",
        phone: ""
    })
    const [showNewAdmin, setShowNewAdmin] = useState(false)
    const [enableField, setEnableField] = useState("")
    const [usernameError, setUsernameError] = useState(false)
    const [inProgressEmail, setInProgressEmail] = useState(false)
    const [inProgressPhone, setInProgressPhone] = useState(false)
    const [isEditAdminActive, setIsEditAdminActive] = useState(false)
    const [loading, setLoading] = useState(false)
    const context = useContext(AppContextObject)

    useEffect(() => {
        setSchoolTableLoading(true)
        setActivityLoading(true)
        setMainInfoLoading(true)
        if(isEdit === true){
            parentData?.setIsUpdated(true)
        }
        // getSchoolOverview();
        // getSchoolDetailsTable();
        // getActivitesTable();
        if(parentData){
            parentData.setData({
              ...parentData.data,
              unsavedImages: []
            })
          }
        // if(userFormData.userEmail != "") {
        //     setIsEmailVerified(true)
        //     setGetVerifyEmailStatus(true)
        // }
        // else {
        //     setIsEmailVerified(false)
        //     setGetVerifyEmailStatus(false)
        // }

        // if(userFormData.phone != "") {
        //     setIsPhoneVerified(true)
        //     setGetVerifyPhoneStatus(true)
        // }
        // else {
        //     setIsPhoneVerified(false)
        //     setGetVerifyPhoneStatus(false)
        // }
    }, [])

    useEffect(() => {
        fetchUser();
    }, [refetch])

    useEffect(()=>{
        setAdminList([])
        if(context.user.role === "schoolPrincipal"){
            fetchSchoolAdmins();
        }
    }, [refetchAdmins])

    const fetchUser = async () =>{
        let resp = await context.apis.public.getUserById(
            context.validator, {_id: context.user._id}
            )
        if(resp && resp.done){
            resp.response.rows.forEach(d =>{
                setUserFormData({fullName: d.fullName, userEmail: d.email, phone: d.phone, username: d.username})
                setUserFetchedData({fullName: d.fullName, userEmail: d.email, phone: d.phone, username: d.username})
                setIsPhoneVerified(true)
                setGetVerifyPhoneStatus(true)
                setIsEmailVerified(true)
                setGetVerifyEmailStatus(true)
                setIsUsernameVerified(true)
                setGetVerifyUsernameStatus(true)
            })
        }
    }

    const fetchSchoolAdmins = async () =>{
        setAdminListLoading(true)
        let resp = await context.apis[context.user.role].getSchoolAdmins(
            context.validator, {}
            )

        if(resp && resp.done){
            setAdminListLoading(false)
            resp.response.rows.forEach(d =>{
                setAdminList(prev=>[
                    ...prev,
                    {
                        _id: d._id,
                        fullName: d.fullName,
                        email: d.email,
                        phone: d.phone
                    }
                ])
            })
            if(resp.response.rows.length < 1){
                setLoadingMessage("No Admin(s) found.")
            }
        }else{
            setAdminListLoading(false)
        }
    }

    // const getSchoolOverview = async () =>{
    //     setMainInfoLoading(true)
    //     let resp = await context.apis[context.user.role].getSchoolOverview(
    //         context.validator, {}
    //         )
    //     if(resp && resp.done){
    //         setMainInfoLoading(false)
    //         setSchoolInfo({
    //             name: resp.response.schoolName,
    //             state: resp.response.state
    //         })
    //         setOverviewList([
    //             {
    //                 title: `${resp.response.builtUpArea} Sq Ft.`,
    //                 subTitle: "Total built-up area",
    //                 icon: schOver1
    //             },
    //             {
    //                 title: resp.response.inventoriesCount,
    //                 subTitle: "Total Inventories",
    //                 icon: schOver2
    //             },
    //             {
    //                 title: resp.response.onGoingCampaigns,
    //                 subTitle: "Ongoing Campaigns",
    //                 icon: schOver3
    //             },
    //             {
    //                 title: resp.response.CampaignsComplete,
    //                 subTitle: "Campaigns Completed",
    //                 icon: schOver3
    //             },
    //             {
    //                 title: resp.response.user,
    //                 subTitle: resp.response.role.toUpperCase() == "SCHOOLPRINCIPAL" 
    //                 ? "School Principal / Trustee" 
    //                 : resp.response.role.toUpperCase() == "SCHOOLADMIN" ? "School Admin"
    //                 : "School Principal / Trustee",
    //                 icon: schOver3
    //             },
    //         ])
    //     } else setMainInfoLoading(false)
    // }

    // const getSchoolDetailsTable = async() =>{
    //     setSchoolTableLoading(true)
    //     let resp = await context.apis[context.user.role].getDetailsForSchoolDetailsTable(
    //         context.validator, {school: context.user.school}, {page: 1, limit: 2, withCount: true}
    //         )
    //     if(resp && resp.done){
    //         setSchoolTableLoading(false)
    //         setSchoolDetails([]);
    //         resp.response.rows.map(am=>{
    //             setSchoolDetails(prev=>[
    //                 ...prev,
    //                 {
    //                     amenity: am.amenities,
    //                     assets: am.assets,
    //                     status: am.status,
    //                     images: am.images
    //                 }
    //             ])
    //         })
    //     }
    // }

    // const getActivitesTable = async() =>{
    //     setActivityLoading(true)
    //     let resp = await context.apis[context.user.role].getActivitiesForTable(
    //         context.validator, {}
    //         )
    //     if(resp && resp.done){
    //         setActivityLoading(false)
    //         setActivities([])
    //         resp.response.rows.map(act=>{
    //             setActivities(prev=>[
    //                 ...prev,
    //                 {
    //                     name: act.name,
    //                     daysLeft: "14 days remaining",
    //                     date: act.months,
    //                     assets: act.assets,
    //                     assetsProvidedBy: act.assetsProvidedBy,
    //                     venue: act.venue,
    //                 }
    //             ])
    //         })
    //     }
    // }

    // const getStatusClass = (status) => {
    //     const statusName = status.toLowerCase()
    //     switch (statusName) {
    //         case "campaigning":
    //             return "btn-red-light"

    //         case "available":
    //             return "btn-green-light"

    //         case "booked":
    //             return "btn-orange-light"

    //         default:
    //             break
    //     }
    // }

    // const fetchAllData = () => {
    //     methods.getPersonalDetails()
    //     methods.getSchoolGeneralDetails()
    //     methods.getSchoolAmenitiesDetails()
    //     methods.getSchoolSpecificDetails()
    //     methods.getClassroomSpecificDetails()
    //     methods.getActivitiesAndStudentLifeDetails()
    //     process.nextTick(() =>{
    //         history.push("/schools/school-details/details/your-details")
    //     })
    // }

    // const openImageModal = (data) =>{
    //     setImageData(data)
    //     setToggleImage(true)
    // }

    const confirmImageModalClose = () => {
        setToggleImage(false)
    }

    const handleUserDetails= (event) => {
        if(event.target.id === "userEmail") {
            if(userFetchedData.userEmail.trim() != event.target.value.trim()) {
                if(event.target.parentNode.querySelectorAll(".vrifiyotp")[0].className.indexOf("complete") >= 0) {
                    event.target.parentNode.querySelectorAll(".vrifiyotp")[0].className = event.target.parentNode.querySelectorAll(".vrifiyotp")[0].className.replace(/ complete/gi, "");
                    setIsEmailVerified(false)                    
                    setGetVerifyEmailStatus(false)
                }
            }
            if(isEdit) {
                if(userFetchedData[event.target.id] === event.target.value.trim()){
                    setIsEmailVerified(true)
                    setGetVerifyEmailStatus(true)
                }
            }
        }
        else if(event.target.id === "phone") {
            if(userFormData.phone != event.target.value) {
                if(event.target.parentNode.querySelectorAll(".vrifiyotp")[0].className.indexOf("complete") >= 0) {
                    event.target.parentNode.querySelectorAll(".vrifiyotp")[0].className = event.target.parentNode.querySelectorAll(".vrifiyotp")[0].className.replace(/ complete/gi, "");
                    setIsPhoneVerified(false)
                    setGetVerifyPhoneStatus(false)
                }
            }
            if(isEdit) {
                if(userFetchedData[event.target.id] === event.target.value) {
                    setIsPhoneVerified(true)
                    setGetVerifyPhoneStatus(true)
                }
            }
        }
        if(event.target.id === "username"){
            // setUserFormData({
            //     ...userFormData,
            //     username : event.target.value.toLowerCase()
            // }) 
            if(userFetchedData.username != event.target.value.trim()) {
                if(event.target.parentNode.querySelectorAll(".vrifiyotp")[0].className.indexOf("complete") >= 0) {
                    event.target.parentNode.querySelectorAll(".vrifiyotp")[0].className = event.target.parentNode.querySelectorAll(".vrifiyotp")[0].className.replace(/ complete/gi, "");
                    setIsUsernameVerified(false)
                    setGetVerifyUsernameStatus(false)
                }
            }
            if(isEdit) {
                if(userFetchedData[event.target.id] === event.target.value.trim()){
                    setIsUsernameVerified(true)
                    setGetVerifyUsernameStatus(true)
                }
            }
            setUsernameError(false)
        }
        setUserFormData({ ...userFormData,
            [event.target.id]: event.target.id === "username" ? event.target.value.toLowerCase().trim() : event.target.id === "userEmail" ? event.target.value.trim() : event.target.value
        })
    }

    const onVerifyEmailClick = async() => {
        const result = formValidator.current.fieldValid("userEmail")
        if(result === false) {
            formValidator.current.showMessageFor("userEmail")
            forceUpdate(!update)
            // setRefetch(!refetch)
            return
        } else {
            formValidator.current.hideMessageFor("userEmail")
            forceUpdate(!update)
            // setRefetch(!refetch)
        }

        if(isEmailVerified) {
            return
        }
        setInProgressEmail(true)
        let resp = await context.apis.public.sendOTPOnEmail(
            context.validator, {"email": userFormData.userEmail}
        )

        if(!resp.response.userFound) {
            setIsMobileOTP(false)
            setSelectedContactForOTP(userFormData.userEmail)
            setShowModal(true)
            // setGetVerifyEmailStatus(true)
            setOverlay(false)
            setToggleAlertPopUp(false)
        }
        else {
            setGetFieldInfo("Email ID");
            setDetailsCaptured(userFormData.userEmail);
            setGetVerifyEmailStatus(false)
            setMsgDetails(`This email id is already being used by someone else. Please use different one.`)
            setOverlay(true)
            setToggleAlertPopUp(true)
        }
    }

    const onVerifyMobileClick = async() => {
        const result = formValidator.current.fieldValid("phone")
        if(result === false) {
            formValidator.current.showMessageFor("phone")
            forceUpdate(!update)
            // setRefetch(!refetch)
            return
        } else {
            formValidator.current.hideMessageFor("phone")
            forceUpdate(!update)
            // setRefetch(!refetch)
        }

        if(isPhoneVerified) {
            return
        }
        setInProgressPhone(true)
        let resp = await context.apis.public.sendOTPOnPhone(
            context.validator, {"phone":userFormData.phone}
        )

        if(!resp.response.userFound) {
            setIsMobileOTP(true)
            setSelectedContactForOTP(userFormData.phone)
            setShowModal(true)
            // setGetVerifyPhoneStatus(true)
            setOverlay(false)
            setToggleAlertPopUp(false)
        }
        else {
            setGetFieldInfo("Phone No.");
            setDetailsCaptured(userFormData.phone);
            setGetVerifyPhoneStatus(false)
            setMsgDetails(`This mobile no is being used by someone else. Please use different one.`)
            setOverlay(true)
            setToggleAlertPopUp(true)
        }
    }

    const onVerifyUsernameClick = async() => {
        if(userFormData.username.length < 4){
            setUsernameError(undefined)
            formValidator.current.showMessageFor("username")
            return
        }
        const result = formValidator.current.fieldValid("username")
        if(result === false) {
            formValidator.current.showMessageFor("username")
            forceUpdate(!update)
            return
        } else {
            formValidator.current.hideMessageFor("username")
            forceUpdate(!update)
        }
        if(isUsernameVerified) {
            return
        }
        // let err = validation({"username": userFormData.username})
        // setFormErrors(err)
        // if(Object.keys(err).length > 0) {
        //     setFormErrors({"username": "username is invalid"})
        //     return
        // }

        let resp = await context.apis.public.findUserByUsername(
            context.validator, {"username": userFormData.username}
        )
        if((resp && resp.done === false) && context.user.username !== userFormData.username){
            let found = resp.response.filter(element => element._id !== context.user._id);
            if(found.length > 0){
                formValidator.current.showMessageFor("username")
                setGetVerifyUsernameStatus(false)
                setIsUsernameVerified(false)
                setUsernameError(true)
                // setMsgDetails(`The Username- ${userFormData.username} - entered already exists. Kindly use a different username to register.`)
                // setOverlay(true)
                // setToggleAlertPopUp(true)
                return
            }
        }
        else{
            setGetVerifyUsernameStatus(true)
            setIsUsernameVerified(true)
        }
    }

    const onModalSubmit = (otp) => {
        if(isMobileOTP) {
            setIsPhoneVerified(true)
            setGetVerifyPhoneStatus(true)
        }
        else {
            setIsEmailVerified(true)
            setGetVerifyEmailStatus(true)
        }
    }

    const confirmClose = () => {
        setOverlay(false)
        setToggleAlertPopUp(false)
    }
    const updateUserDetails = async (field) => {
        Object.keys(userFormData).map(k => userFormData[k] = typeof userFormData[k] == 'string' ? userFormData[k].trim().replace(/  +/g, ' ') : userFormData[k])
        // if(field === "fullName"){
            const result = formValidator.current.fieldValid("fullName")
            if(result === false) {
                formValidator.current.showMessages()
                forceUpdate(!update)
                return
            } else {
                formValidator.current.hideMessages()
                forceUpdate(!update)
                // setEnableField("")
            }            
        // }
        
        if(getVerifyEmailStatus==false) {
            setMsgDetails('You need to verify your email address to continue, Just click on verify to get OTP.')
            setOverlay(true)
            setToggleAlertPopUp(true)
            return
        }
        if( getVerifyPhoneStatus==false) {
            setMsgDetails('You need to verify your mobile no to continue, Just click on verify to get OTP.')
            setOverlay(true)
            setToggleAlertPopUp(true)
            return
        }
        if(getVerifyUsernameStatus === false){
            setMsgDetails('You need to verify your username to continue, Just click on verify to get verified.')
            setOverlay(true)
            setToggleAlertPopUp(true)
            return
        }

        if(field === "userEmail" && getVerifyEmailStatus){
            setEnableField("")
        }
        if(field === "phone" && getVerifyPhoneStatus){
            setEnableField("")
        }
        if(field === "username" && getVerifyUsernameStatus){
            setEnableField("")
        }
        if(field === "fullName") {
            setEnableField("")
        }
        let updatingData;
        if(field === "fullName"){
            updatingData = {
                fullName: userFormData.fullName,
            }
        }
        if(field === "userEmail"){
            updatingData = {
                email: userFormData.userEmail,
            }
        }
        if(field === "phone"){
            updatingData = {
                phone: userFormData.phone
            }
        }
        if(field === "username"){
            updatingData = {
                username: userFormData.username
            }
        }
        if(userFetchedData[field] != userFormData[field]) {
        let resp = await context.apis[context.user.role].updateUserDetails(
            context.validator, {}, updatingData
            )
        if(resp && resp.done){
            setRefetch(!refetch)
                toast.success("Updated successfully")
            }
        }
    }

    const enableEditField = (field) => {
        if (enableField == "" && showNewAdmin === false && isEditAdminActive === false) {
            setEnableField(field)
        }
    }

    const editAdmin = (admin) => {
        if(showNewAdmin === false) {
            setEditAdminData({...editAdminData, _id: admin._id, fullName: admin.fullName, email: admin.email, phone: admin.phone})
            setIsEditAdminActive(true)
        }
    }

    const editSchoolAdmin = async () => {
        if(context.user.role === "schoolPrincipal"){
            const result = adminFormValidator.current.allValid()

            if (result === false) {
                adminFormValidator.current.showMessages()
                forceUpdate(true)
                return
            } else {
                adminFormValidator.current.hideMessages()
                forceUpdate(!update)
                let updatingData = {
                    fullName: editAdminData.fullName,
                    email: editAdminData.email,
                    phone: editAdminData.phone
                }
                let respEditAdmin = await context.apis[context.user.role].updateSchoolAdmin(
                    context.validator, {_id: editAdminData._id}, updatingData
                    )
                if(respEditAdmin && respEditAdmin.done){
                    setAdminList([])
                    setEditAdminData({_id: "", fullName: "", email: "", phone: ""})
                    // setRefetch(!refetch)
                    setRefetchAdmins(!refetchAdmins)
                    setIsEditAdminActive(false)
                }
            }
        }
    }

    const addSchoolAdmin = async () => {
        if(context.user.role === "schoolPrincipal"){
            setLoading(true)
            let result = adminFormValidator.current.allValid()
            if (result === false) {
                setNewAdmin({...newAdmin})
                adminFormValidator.current.showMessages()
                forceUpdate(true)
                setLoading(false)
                return
            } else {
                adminFormValidator.current.hideMessages()
                forceUpdate(!update)
                let addData = {
                    fullName: newAdmin.fullName,
                    email: newAdmin.email,
                    phone: newAdmin.phone
                }
                let respAddAdmin = await context.apis[context.user.role].addSchoolAdmin(
                    context.validator, addData
                ).catch(err => {
                    setLoading(false)
                })
                setLoading(false)
                if(respAddAdmin && respAddAdmin.done){
                    setAdminList([])
                    setNewAdmin({fullName: "", email: "", phone: ""})
                    // setRefetch(!refetch)
                    setRefetchAdmins(!refetchAdmins)
                    setShowNewAdmin(false)
                }                
            }
        }
    }

    const deleteSchoolAdmin = async (adminId) => {
        if(context.user.role === "schoolPrincipal"){
            if(context.user.role === "schoolPrincipal"){
                let respDeleteAdmin = await context.apis[context.user.role].deleteSchoolAdmin(
                    context.validator, {_id: adminId}
                )
                if(respDeleteAdmin && respDeleteAdmin.done){
                    setAdminList([])
                    // setRefetch(!refetch)
                    setRefetchAdmins(!refetchAdmins)
                }
            }
            setDeleteAdminId("")
        }
    }
    const discardAddNewAdmin = () => {
        setNewAdmin({fullName: "", email: "", phone: ""})
        setShowNewAdmin(false)
        // setConfirmDialogArgs({...confirmDialogArgs, confirmTitle: ""})
    }
    const discardEditAdmin = () => {
        setEditAdminData({_id: "", fullName: "", email: "", phone: ""})
        setIsEditAdminActive(false)
    }
    const confirmDialogClose = (next: boolean) => {
        if(next){
            if(confirmDialogArgs.confirmTitle === "Discard Admin Addition"){
                if(editAdminData._id !== ""){
                    discardEditAdmin()
                }
                else{
                    discardAddNewAdmin()
                }
                setConfirmDialogArgs({...confirmDialogArgs, confirmTitle: "", componentName: "",confirmText: "",denyText: ""})
            }
            else if(confirmDialogArgs.confirmTitle === "Delete admin" && deleteAdminId !== ""){
                deleteSchoolAdmin(deleteAdminId)
            }
        }
        setToggleConfirmDialogModal(false)
    }
    const handleDiscard = () => {
        setConfirmDialogArgs({...confirmDialogArgs, confirmTitle: "Discard Admin Addition", componentName: "Are you sure you want to discard the changes that you made?", confirmText: "Discard", denyText: "cancel"})
        setToggleConfirmDialogModal(true)
    }
    const handleDeleteAdmin = (_id) => {
        if(showNewAdmin === false) {
        setConfirmDialogArgs({...confirmDialogArgs, confirmTitle: "Delete admin", componentName: "Are you sure you want to delete the admin?", confirmText: "Delete", denyText: "cancel"})
        setDeleteAdminId(_id)
        setToggleConfirmDialogModal(true)
        }
    }

    const onMobileNoChange = (e) => {
        const re = /^[0-9\b]+$/
        if (e.target.value === '' || (re.test(e.target.value) && e.target.value.trim().length <= 10)) {
            setNewAdmin({...newAdmin, phone: e.target.value})
        }        
    }

    const onAdminDataChange = (e) => {
        setNewAdmin({...newAdmin, [e.target.id]: e.target.value})
    }

    const onEditAdminDataChange = (e) => {
        setEditAdminData({...editAdminData, [e.target.id]: e.target.value})
    }

    adminFormValidator.current.rules['full_name'] = {
        message: 'Please enter valid full name.',
        rule: (val) => {
            let returnValue = validateFullName(val)

            return returnValue
        },
    }

    formValidator.current.rules['full_name'] = {
        message: 'Please enter valid full name.',
        rule: (val) => {
            let returnValue = validateFullName(val)

            return returnValue
        },
    }
    let error = ""
    formValidator.current.rules['user_name'] = {
        message: error === "required" ? 'The user name field is required.' : usernameError ? "Username is already exists." : usernameError === null ? `${userFormData.username} Username is been taken couple of seconds ago. Try another?` : usernameError === undefined ? 'Username must be minimum 4 characters long.' :'Username is invalid, Username can contain letters (a-z), numbers and spe. chars(_@.).',
        rule: (val) => {
            let returnValue = true
            if (!val || val.trim() === "") {
                error = "required"
                returnValue = false
            }
            
            const chars = "!#$%^&*()-{}[]+=<>?/,~'` "
            for (let i = 0; i < val.split("").length; i++) {
                const v = val.split("")[i]
                if (!v || v.trim() === " " || chars.split("").find(ch => ch === v)) {
                    error = ""
                    returnValue = false
                    break
                }
            }

            if(usernameError){
                error = ""
                returnValue = false
            }

            if(usernameError === null){
                error = ""
                returnValue = false
            }

            if(usernameError === undefined){
                error = ""
                returnValue = false
            }

            return returnValue
        },
    }
    formValidator.current.rules['valid_user_name'] = {
        message: 'Username can not contain only special character.',
        rule: (val) => {
            let returnValue = true
            if(!specialCharOnly(val)){
                returnValue = false
            }
            return returnValue
        }
    }
    adminFormValidator.current.rules['same_name'] = {
        message: 'Admin and principal name can not be same.',
        rule: (val) => {
            let returnValue;
            console.log("testerdkhona", userFormData, val)
            if(userFormData.fullName.trim().replace(/  +/g, ' ').toLowerCase() === val.trim().replace(/  +/g, ' ').toLowerCase()) {
                returnValue = false
            }else{
                returnValue = true
            }

            return returnValue
        },
    }
    formValidator.current.rules['same_name'] = {
        message: 'Admin and principal name can not be same.',
        rule: (val) => {
            let returnValue;
            console.log("testerdkhona", userFormData, val)
            for(let i = 0; i<adminList.length; i++){
                if(adminList[i].fullName.trim().replace(/  +/g, ' ').toLowerCase() === val.trim().replace(/  +/g, ' ').toLowerCase()) {
                    returnValue = false
                    return returnValue
                }
            }
            return true
        },
    }
    const onOTPModalClose = () => {
        setInProgressEmail(false);
        setInProgressPhone(false);
    }
    const addAmdinRow = () =>{
        adminFormValidator.current.hideMessages()
        setShowNewAdmin(true);
    }
    return (
        <>
        <AlertDialog
            togglePopup={toggleAlertPopUp}
            fieldInfo={getFieldInfo}
            details={detailsCaptured}
            onModalClose={confirmClose}
            displayMsg={msgDetails}
        />
        <ConfirmDialog
            confirmTitle={confirmDialogArgs.confirmTitle}
            componentName={confirmDialogArgs.componentName}
            confirmText={confirmDialogArgs.confirmText}
            denyText={confirmDialogArgs.denyText}
            onModalClose={confirmDialogClose}
            togglePopup={toggleConfirmDialogModal}
        />
        <div className={`ovelap ${(showModal === true || toggleAlertPopUp === true || toggleConfirmDialogModal === true) ? "active" : ""}`}></div>
            <div className="col-xl-10 col-lg-12 col-md-12 col-xs-12">
                <div className="rightPart noBack">
                <div className="row">
                    <div className="col-xs-12 col-lg-12 col-md-12 col-xs-12">
                        <OnboardingBackBtn
                            link="/"
                            text="Log Out"
                        />
                    </div>
                    <TopBar />

                    <div className="col-xs-5 col-lg-4 col-md-7 col-xs-12 pl">
                        <div className="row mypro-disable">
                            <div className="col-xs-8 col-lg-8 col-md-8 col-xs-12 mt-30">
                                <h2 className="htx2 clearfix">{context.user && context.user.role === "schoolPrincipal" ? "Principal's" : "Admin"} Details</h2>
                            </div>
                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-30"></div>
                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-20">
                            {
                                    enableField === "fullName"
                                    ?
                                    <a href="javascript:void(0);" className="viewwLink proEdit" onClick={()=>updateUserDetails("fullName")}><img src={saveInput}/> Save</a>
                                    :
                                    <a href="javascript:void(0);" className="viewwLink proEdit" onClick={()=>enableEditField("fullName")}><img src={proEdit}/> Edit</a>
                                }
                                <Textfield
                                    formData={userFormData}
                                    formKey='fullName'
                                    onDataChange={handleUserDetails}
                                    label='Full Name'
                                    placeholder='Name'
                                    isRequired={false}
                                    formValidator={formValidator}
                                    validation='required|full_name|same_name'
                                    isDisabled={enableField === "fullName" ? false : true}
                                    maxLength={50}
                                />
                            </div>
                            
                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-20">
                            {
                                    enableField === "username"
                                    ?
                                    <a href="javascript:void(0);" className="viewwLink proEdit" onClick={()=>updateUserDetails("username")}><img src={saveInput}/> Save</a>
                                    :
                                    <a href="javascript:void(0);" className="viewwLink proEdit" onClick={()=>enableEditField("username")}><img src={proEdit}/> Edit</a>
                                }
                            <Textfield
                                formData={userFormData}
                                formKey='username'
                                onDataChange={handleUserDetails}
                                label='Your Username'
                                placeholder='Username'
                                isRequired={false}
                                formValidator={formValidator}
                                validation='required|user_name|valid_user_name'
                                onVerifyClick={onVerifyUsernameClick}
                                isVerified={isUsernameVerified}
                                maxLength={16}
                                isDisabled={enableField === "username" ? false : true}
                            />
                            </div>
                            
                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-20">
                            {
                                    enableField === "userEmail" 
                                    ?
                                    <a href="javascript:void(0);" className="viewwLink proEdit" onClick={()=>updateUserDetails("userEmail")}><img src={saveInput}/> Save</a>
                                    :
                                    <a href="javascript:void(0);" className="viewwLink proEdit" onClick={()=>enableEditField("userEmail")}><img src={proEdit}/> Edit</a>
                                }
                                <Textfield
                                    formData={userFormData}
                                    formKey='userEmail'
                                    onDataChange={handleUserDetails}
                                    label='Email ID'
                                    placeholder='Email ID'
                                    isRequired={false}
                                    onVerifyClick={onVerifyEmailClick}
                                    isVerified={isEmailVerified}
                                    inProgress={inProgressEmail}
                                    formValidator={formValidator}
                                    validation='required|email'
                                    isDisabled={enableField === "userEmail" ? false : true}
                                />
                            </div>
                            
                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-20">
                            {
                                    enableField === "phone" 
                                    ?
                                    <a href="javascript:void(0);" className="viewwLink proEdit" onClick={()=>updateUserDetails("phone")}><img src={saveInput}/> Save</a>
                                    :
                                    <a href="javascript:void(0);" className="viewwLink proEdit" onClick={()=>enableEditField("phone")}><img src={proEdit}/> Edit</a>
                                }
                                {/* <Textfield
                                    formData={userFormData}
                                    formKey='phone'
                                    onDataChange={handleUserDetails}
                                    label='Mobile No'
                                    placeholder='1212112112'
                                    isRequired={false}
                                    onVerifyClick={onVerifyMobileClick}
                                    isVerified={isPhoneVerified}
                                    inProgress={inProgressPhone}
                                    formValidator={formValidator}
                                    validation='required|phone'
                                    maxLength={10}
                                    isDisabled={enableField === "phone" ? false : true}
                                /> */}
                                <TextfieldWithPrefix
                                    formData={userFormData}
                                    formKey='phone'
                                    onDataChange={handleUserDetails}
                                    label='Mobile No'
                                    placeholder='1212112112'
                                    isRequired={false}
                                    onVerifyClick={onVerifyMobileClick}
                                    isVerified={isPhoneVerified}
                                    inProgress={inProgressPhone}
                                    formValidator={formValidator}
                                    validation='required|phone'
                                    maxLength={10}
                                    isDisabled={enableField === "phone" ? false : true}
                            />
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-xl-12 col-lg-12 col-md-9 col-xs-12 mt-20 mb-30"><div className="line-full"></div></div> */}
                    {
                        context.user && context.user.role === "schoolPrincipal"
                        &&
                        <div className="col-xs-12 col-lg-12 col-md-12 col-xs-12 pl mt-20">
                        <div className="row">
                                <div className="col-xs-8 col-lg-8 col-md-8 col-xs-12">
                                    <h2 className="htx2 clearfix">Admin</h2>
                                </div>
                                <div className={`col-xs-4 col-lg-4 col-md-4 col-xs-12 ${(showNewAdmin || isEditAdminActive) ? "dnone" : ""}`}>
                                    <a style={{cursor: "pointer"}} className="viewwLink" onClick={addAmdinRow}><img src={addAdmin}/> Add Admin</a>
                                </div>
                                <div className="col-xs-12 col-lg-12 col-md-12 col-xs-12">
                                    <div className="table-container mt-10 addAdmininput fillterTableHeight">
                                        <table className="table">
                                        <thead>
                                            <tr>
                                            <th scope="col">Name</th>
                                            <th scope="col">Email ID</th>
                                            <th scope="col">Mobile No</th>
                                            <th className="align-R" scope="col">Update Access</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                adminListloading && <tr><td colSpan={4}>{loadingMessage}</td></tr>
                                            }
                                            {
                                                !showNewAdmin && adminList.length < 1 && <tr><td colSpan={4}>{loadingMessage}</td></tr>
                                            }
                                            {
                                                adminList && adminList.map((admin, index)=>{
                                                    return(
                                                        editAdminData._id === admin._id ?
                                                            <tr key={index}>
                                                                <td>
                                                                    <Textfield
                                                                        formData={editAdminData}
                                                                        formKey='fullName'
                                                                        onDataChange={onEditAdminDataChange}
                                                                        placeholder='Enter Name'
                                                                        isRequired={false}
                                                                        formValidator={adminFormValidator}
                                                                        validation='required|full_name|same_name'
                                                                        maxLength={50}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <Textfield
                                                                        formData={editAdminData}
                                                                        formKey='email'
                                                                        onDataChange={onEditAdminDataChange}
                                                                        placeholder='Enter Email ID'
                                                                        isRequired={false}
                                                                        formValidator={adminFormValidator}
                                                                        validation='required|email'
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <Textfield
                                                                        formData={editAdminData}
                                                                        formKey='phone'
                                                                        onDataChange={onEditAdminDataChange}
                                                                        placeholder='Enter Mobile Number'
                                                                        isRequired={false}
                                                                        formValidator={adminFormValidator}
                                                                        validation='required|phone'
                                                                        maxLength={10}
                                                                    />
                                                                </td>
                                                                <td className="align-R"><a href="javascript:void(0);" onClick={()=> editSchoolAdmin()} className="btn btn-orange">Save</a><a href="javascript:void(0)" className="btn btn-outline" data-target="approvalAll" onClick={() => handleDiscard()}>Discard</a></td>
                                                            </tr> 
                                                            :
                                                            <tr>
                                                                <td>{admin.fullName}</td>
                                                                <td>{admin.email}</td>
                                                                <td>{admin.phone}</td>
                                                                <td className="iconAddEdit"><a href="javascript:void(0)" onClick={()=> editAdmin(admin)}><img src={proEdit}/></a><a href="javascript:void(0)" onClick={()=> handleDeleteAdmin(admin._id)}><img src={trashCan}/></a></td>
                                                            </tr>
                                                    )
                                                })
                                            }
                                            {showNewAdmin && <tr>
                                                <td>
                                                    <Textfield
                                                        formData={newAdmin}
                                                        formKey='fullName'
                                                        onDataChange={onAdminDataChange}
                                                        placeholder='Enter Name'
                                                        isRequired={false}
                                                        formValidator={adminFormValidator}
                                                        validation='required|full_name|same_name'
                                                        maxLength={50}
                                                    />
                                                </td>
                                                <td>
                                                <Textfield
                                                    formData={newAdmin}
                                                    formKey='email'
                                                    onDataChange={onAdminDataChange}
                                                    placeholder='Enter Email ID'
                                                    isRequired={false}
                                                    formValidator={adminFormValidator}
                                                    validation='required|email'
                                                />
                                                </td>
                                                <td>
                                                    <Textfield
                                                        formData={newAdmin}
                                                        formKey='phone'
                                                        onDataChange={onAdminDataChange}
                                                        placeholder='Enter Mobile Number'
                                                        isRequired={false}
                                                        formValidator={adminFormValidator}
                                                        validation='required|phone'
                                                        pattern="[1-9]{1}[0-9]{9}"
                                                        maxLength={10}
                                                    />
                                                </td>
                                                    <td className="align-R">
                                                        <div className={` forminpt ${loading ? "button--loading" : ""}`} style={{display: "inline-block", width: "auto"}}>
                                                            <input
                                                                name={'Save'}
                                                                value={'Save'}
                                                                className={`btn btn-orange comp-seting ${(loading === true) ? "btn-disabled button--loading" : ""}`}
                                                                type='button'
                                                                onClick={addSchoolAdmin}
                                                            />
                                                        </div>
                                                        {/* <a href="javascript:void(0);" onClick={()=> addSchoolAdmin()} className="btn btn-orange">Save</a> */}
                                                         <a href="javascript:void(0)" className="btn btn-outline" data-target="approvalAll" onClick={() => handleDiscard()}>Discard</a>
                                                    </td>
                                            </tr>}
                                        </tbody>
                                        </table>
                                    </div>
                                </div>
                        </div>
                    </div>
                    }
                </div>
                </div>
            </div>
            <ImageModal
                togglePopup={toggleImage}
                displayImage={imageData} 
                onModalClose={confirmImageModalClose}
            />
            <OTPVerifyModal
            contact={selectedContactForOTP}
            isMobile={isMobileOTP}
            showModal={showModal}
            setShowModal={setShowModal}
            onModalSubmit={onModalSubmit}   
            isMobileAndEmail = {false}    
            onClose={onOTPModalClose}
            pageType={'myprofilePage'}
        />
        </>
    )
}

export default MyProfile
