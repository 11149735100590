import { useState, useContext, useEffect, useRef, createRef } from "react"
import { useHistory } from "react-router-dom"
import search from "../../../../assets/images/search.svg"
import notification from "../../../../assets/images/noti-bell.svg"
import OnboardingBackBtn  from "../components/onboardingBackBtn"
import backBtn from "../../../../assets/images/back-arrow.svg"
import proEdit from "../../../../assets/images/edit-profile.svg"
import addAdmin from "../../../../assets/images/add-admin1.svg"
import studentImgbg from "../../../../assets/images/student-imgbg.png"
import riarrownext from "../../../../assets/images/right-arrow-next.svg"
import calendararrowL from "../../../../assets/images/calendar-arrowL.svg"
import calendararrowR from "../../../../assets/images/calendar-arrowR.svg"
import daybrand from "../../../../assets/images/logo512.png"
import AppContextObject from "../../../../common/context/common"
import { useLocation } from "react-router-dom"

import { validation } from "../../../../service/validators"

import SimpleReactValidator from "simple-react-validator"

import AlertDialog from "../../../../components/addon/alertDialog/alertDialog"
import ConfirmAlert from "../../../../components/addon/confirmAlert"
import NumberField from "../../../../components/formFields/numberField"
import NumWithPlusMinus from "../../../../components/formFields/numWithPlusMinus/numWithPlusMinus"
import Textfield from "../../../../components/formFields/textField/textField"
import SingleSelect from "../../../../components/formFields/singleSelect/singleSelect"
import MultiSelect from "../../../../components/formFields/multiSelect/multiSelect"
import RadioButton from "../../../../components/formFields/radioButton/radioButton"
import SliderComponent from "../../../../components/addon/rangeSlider"
import ClassroomAttributes from "../components/classrooms/attributes"

import config from "../../../../service/config";
import { PublicContextInitiator } from "../../../apis/publicContextInitiator"

import DataContext from "../../../../service/contextProvider"

import { checkIfNumberOnly, compareObjs, objectDeepClone, readAbleBoard } from "../../../../service/helper"

import schoolImg from "../../../../assets/images/school-img.jpg"
import errorSVG from "../../../../assets/images/error.svg"
import toast from "react-hot-toast"
import TextFieldWithValidation from "../../../../components/formFields/textField/textFieldWithValidation"
import SchoolGeneralDetail from "../components/schoolGeneralDetail"
import moment from 'moment'
import Calander from "../components/calander"
import TopBar from "../components/topBar"
import { formatDateShowMonth } from "../../../../service/helper"
import { Steps } from "intro.js-react"
import Loader from "../../../../components/addon/loader"

interface Props {
    isEdit?: boolean,
    onProceed?: any,
    toolTipLoaded?: boolean,
    setRefetch?: any,
    refetch?: boolean
}

const MySchool = ({
    isEdit,
    onProceed,
    toolTipLoaded,
    setRefetch,
    refetch
}: Props) => {
    const history:any = useHistory()
    const stepRef = useRef()
    // const [tempData, setTempData] = useState({_id: ""})
    const [value, setValue] = useState<any>()
    const refClassrooms = useRef()
    const generalDetailRef = useRef({formValidator : {current: {allValid: () => false, showMessages: () => {return}, hideMessages: () => {return}}}})
    const [initialData, setInitialData] = useState([])
    const [errorMessage, setErrorMessage] = useState("")
    const [overlay, setOverlay] = useState(false)
    const context = useContext(AppContextObject)
    const [inventoryList, setSupportList] = useState([])
    const [searchValue, setSearchValue] = useState("")
    const [sortingValue, setSortingValue] = useState(true)
    const [showModal, setShowModal] = useState(false)
    const [updatingData, setUpdatingData] = useState(false)
    
    //
    const parentData = useContext<any>(DataContext)
    const [oldParentData, setOldParentData] = useState({})

    const location = useLocation()

    const [formValidator] = useState(useRef(new SimpleReactValidator({
        element: (message, className) =>
            <span className="valError"><img src={errorSVG} />{message}</span>
        })))
    const [update, forceUpdate] = useState(false) // to re-render the dom after updating the validation

    const maxBuiltupArea = 1000000
    const minbuiltupArea = 1

    const [oldFormData] = useState({...parentData.data.schoolGeneral})
    const [showPrompt, setShowPrompt] = useState(false)
    const [toggleAlertPopUp, setToggleAlertPopUp] = useState(false)
    const otherBoardReference = useRef()
    const [formData, setFormData] = useState({...parentData.data.schoolGeneral})
    const [currentClassTypeIndex, setCurrentClassTypeIndex] = useState(0)
    const [builtError, setBuiltError] = useState(false)
    const [floorError, setFloorError] = useState(false)
    const [showBuildUpError, setShowBuildUpError] = useState(false)
    const [schooldetailLoading, setSchooldetailLoading] = useState(false)
    const [calenderLoading, setCalenderLoading] = useState(false)
    const [boardOfStudyData, setBoardOfStudy] = useState({
        boardOfStudy: ""
    })

    const [otherBoardRef, setOtherBoardRef] = useState([])
    // const [otherBoardLength, setOtherBoardLength] = useState(0)

    const [langOptions] = useState(config.languages)
    const [schoolTypeOptions] = useState(config.schoolTypes)
    const [boardOptions] = useState(config.boardOptions)
    const [schoolBoard, setSchoolBoard] = useState([])
    const [schoolLang, setSchoolLang] = useState([])
    const [schoolEduLevel, setSchoolEduLevel] = useState([])
    const [schoolBuildUpArea, setSchoolBuildUpArea] = useState()
    const [schoolTotalStudent, setSchoolTotalStudent] = useState()
    const [schoolTotalInventory, setSchoolTotalInventory] = useState()
    const [schoolTotalActivities, setSchoolTotalActivities] = useState()
    const [holidayList, setHolidayList] = useState([])
    const [brandCampaignList, setBrandCampaignList] = useState([])
    const [schoolEventList, setSchoolEventList] = useState([])
    const [schoolName, setSchoolName] = useState("")
    const [fosEmail, setFosEmail] = useState("")
    const [fosPhone, setFosPhone] = useState("")
    const [selectedCampaignId, setSelectedCampaignId] = useState("")
    const [schoolId, setSchoolId] = useState("")
    const [selectedCampaignDetail, setSelectedCampaignDetail] = useState({
        show: false,
        name: "",
        startDate: "",
        endDate: "",
        discription: "",
        inventories: []
    })
    const [modalCampaignList, setModalCampaignList] = useState([])
    const [showFullDiscription, setShowFullDiscription] = useState(false)
    const [showFullInventoryList, setShowFullInventoryList] = useState(false)
    const [onBoarding, setOnboarding] = useState({
        stepsEnabled: false,
        initialStep: 0,
        steps: [
            {element: "#toolTip", intro: "View upcoming campaign in your school here",position: "left", tooltipClass: "myToolTipClass"},
        ],
        options: {
            exitOnOverlayClick: false,
            showStepNumbers: true,
            hidePrev: true,
            hideNext: false,
            nextLabel: "Next",
            prevLabel: "Prev",
            doneLabel: "Got it",
            position: "top",
            autoPosition: false,
            exitOnEsc: false,
            scrollToElement: true,
            disableInteraction: true
        },
    })
    useEffect(() => {
        if(toolTipLoaded){
            setTimeout(() => {
                setOnboarding({...onBoarding, stepsEnabled: parentData?.toolTipProgress?.mySchool})
            }, 100)
        }
    }, [toolTipLoaded])
    // useEffect(() => {
    //     if(value){
    //         let today = moment()
    //         if(today.format("DD-MM-YY") !== value.format("DD-MM-YY")){
    //             // setValue(today.clone())
    //         }
    //     }
    // })
    useEffect(() => {
        setShowFullDiscription(false)
        setShowFullInventoryList(false)
    }, [selectedCampaignId])

    useEffect(() => {
        if(history.location.state && history.location.state.campaignData){
            const tempData: any = history.location.state.campaignData
            // setTempData(tempData)
            setValue(moment(tempData.startDate, "YYYY-MM-DD"))
        }
        else{
            setValue(moment())
        }
    }, [])
    // useEffect(() => {
    //     if(parentData?.data?.schoolGeneral?._id && parentData?.data?.schoolGeneral?._id !== "")
    //         setSchoolId(parentData?.data?.schoolGeneral?._id)
    // }, [parentData.data.schoolGeneral])
    useEffect(() => {
        if(showModal){
            // console.log(parentData.data.schoolGeneral)
            setFormData({...parentData.data.schoolGeneral})
        }
    }, [showModal])
    useEffect(() => {
        if(!showModal){
            getSchoolGeneralDetails()
        }
    }, [showModal])
    const getSchoolGeneralDetails = async () =>{
        setSchooldetailLoading(true)
        let schoolGeneralDetailsReps = await context.apis[context.user.role].getSchoolGeneralDetailsBySchoolId(
            context.validator, {_id: context.user.school}
        )
        let schoolAmenitiesDetailsReps = await context.apis[context.user.role].getSchoolAmenitiesDetailsBySchoolId(
            context.validator, {_id: context.user.school}
        )
        console.log("schoolAmenitiesDetailsReps", schoolAmenitiesDetailsReps, parentData.data)
        if(schoolGeneralDetailsReps && schoolGeneralDetailsReps.done && schoolAmenitiesDetailsReps && schoolAmenitiesDetailsReps.done){
            setSchoolName(schoolGeneralDetailsReps.response.schoolName)
            setSchoolBuildUpArea(schoolGeneralDetailsReps.response.builtUpArea)
            setSchoolTotalStudent(schoolGeneralDetailsReps.response.totalStudents)
            setSchoolTotalInventory(schoolGeneralDetailsReps.response.totalInventories)
            setSchoolTotalActivities(schoolGeneralDetailsReps.response.totalActivities)
            setSchoolEduLevel([...schoolGeneralDetailsReps.response.educationLevel])
            if(schoolGeneralDetailsReps.response.fosEmail){
                setFosEmail(schoolGeneralDetailsReps.response.fosEmail)
            }
            if(schoolGeneralDetailsReps.response.fosPhone){
                setFosPhone(schoolGeneralDetailsReps.response.fosPhone)
            }
            if(schoolGeneralDetailsReps.response.language.language !== "Custom"){
                let language = [schoolGeneralDetailsReps.response?.language?.language]
                setSchoolLang([...language])
            }
            else{
                let language = []
                for (let el of schoolGeneralDetailsReps.response?.language?.classWiseDetails) {
                    if(!Object.keys(el).find(e=> e==="language")) el["language"] = el.lang; delete el.lang;
                    language.push(el.language)
                }
                // resp.response?.language?.classWiseDetails.map(el=> {  })
                // console.log("language--->>",language, resp.response?.language?.classWiseDetails);
                // resp.response?.language?.classWiseDetails.forEach((e) => (
                //     language.push(e.language)
                // ))
                setSchoolLang([...language])
            }
            if(schoolGeneralDetailsReps.response.board.board !== "Custom"){
                let board = [schoolGeneralDetailsReps.response?.board?.board]
                setSchoolBoard([...board])
            }
            else{
                let board = []
                schoolGeneralDetailsReps.response?.board?.classWiseDetails.forEach((e) => (
                    board.push(e.board)
                ))
                setSchoolBoard([...board])
            }
            // setFormData(schoolGeneralDetailsReps.response)
            // parentData.setData({
            //     ...parentData.data,
            //     schoolGeneral: {...schoolGeneralDetailsReps.response}
            // })
            // if(schoolGeneralDetailsReps.response.language.language !== "Custom"){
            //     let language = [schoolGeneralDetailsReps.response?.language?.language]
            //     setSchoolLang([...language])
            // }
            // else{
            //     let language = []
            //     for (let el of schoolGeneralDetailsReps.response?.language?.classWiseDetails) {
            //         if(!Object.keys(el).find(e=> e==="language")) el["language"] = el.lang; delete el.lang;
            //         language.push(el.language)
            //     }
            // }
            setFormData(schoolGeneralDetailsReps.response)
            parentData.setData({
                ...parentData.data,
                schoolGeneral: {...schoolGeneralDetailsReps.response},
                schoolAmenities: {
                    ...parentData.data.schoolAmenities,
                    builtUpArea: schoolAmenitiesDetailsReps.response.builtUpArea,
                    floors: schoolAmenitiesDetailsReps.response.floors,
                    classrooms: schoolAmenitiesDetailsReps.response.classrooms
                }
            })
        }
        setSchooldetailLoading(false)
    }

    useEffect(() => {
        // setFormData({...parentData.data.schoolGeneral})
        setOldParentData({...parentData, data: {...parentData.data, schoolGeneral: formData}})
    }, [formData])

    // useEffect(() => {
    //     if(!showModal){
    //         getSchoolGenDetailById()
    //     }
    // }, [showModal])
    useEffect(() => {
        if(value){
            getCapmHolidayList()
        }
    }, [value])
    useEffect(() => {
        showSelectedBrandCampaign()
    }, [selectedCampaignId])

    const showSelectedBrandCampaign = () => {
        if(selectedCampaignId === ""){
            setSelectedCampaignDetail({
                show: false,
                name: "",
                startDate: "",
                endDate: "",
                discription: "",
                inventories: []
            })
        }
        else if(selectedCampaignId && selectedCampaignId !== ""){
            let selectedCampaign = brandCampaignList.length !== 0 && brandCampaignList.filter((campaign) => campaign._id === selectedCampaignId)
            console.log("brandCampaignList----", selectedCampaignId, brandCampaignList)
            if(selectedCampaign && selectedCampaign.length > 0){
                setSelectedCampaignDetail({
                    show: true,
                    discription: selectedCampaign[0]?.description,
                    startDate: selectedCampaign[0]?.startDate,
                    endDate: selectedCampaign[0]?.endDate,
                    name: selectedCampaign[0]?.name,
                    inventories: selectedCampaign[0]?.inventories
                })
            }
        }
    }
    useEffect(() => {
        if(brandCampaignList.length > 0 && !calenderLoading){
            if(history.location.state && history.location.state.campaignData){
                setSelectedCampaignId(history.location.state.campaignData._id)
                history.push({state: undefined})
            }
        }
    }, [brandCampaignList, calenderLoading])
    const getCapmHolidayList = async() => {
        setCalenderLoading(true)
        let startDay = value.clone().startOf("month").startOf("week").format("YYYY-MM-DD").toString()
        let endDay = value.clone().endOf("month").endOf("week").format("YYYY-MM-DD").toString()
        let resp = await context.apis.public.mySchoolCalendarData(
            context.validator, {startDate: startDay, endDate: endDay, _id: context.user.school}, {}
        )
        if(resp && resp.done){
            console.log("mySchoolCalendarData",resp)
            setBrandCampaignList(resp.campaigns)
            setHolidayList(resp.holidays)
            setSchoolEventList(resp.schoolEvents)
        }
        setCalenderLoading(false)
    }

    const confirmAlertCallback = (data) => {
        setOverlay(data.overlay)
    }

    const compareOldAndNewData = (newData) => {
        const isSame = compareObjs(newData, initialData)
        if (isSame === false) {
            setShowPrompt(true)
        } else {
            setShowPrompt(false)
        }
    }

    const submitForm = async(event) => {
        let otherBoardValidation = []
        otherBoardRef.length !== 0 && otherBoardRef.forEach(e => {
            if (e?.current?.validateField) {
                otherBoardValidation.push(e.current.validateField())
            }
        })
        let otherResult
        let otherValidation:any = otherBoardReference
        if(otherValidation?.current?.validateField){
            otherResult = otherValidation?.current?.validateField()
        }
        event.preventDefault()
        const updateProgress = {
            value: false
        }
        const result = generalDetailRef?.current?.formValidator.current?.allValid()
        console.log(result, otherBoardValidation)
        if (otherResult === false || result === false || !(otherBoardValidation.length === 0 || otherBoardValidation.indexOf(false) === -1) || (formData.builtUpArea === "" || formData.builtUpArea < 100)) {
            updateProgress.value = false
            generalDetailRef?.current?.formValidator.current?.showMessages()
            if(formData.builtUpArea === "" || formData.builtUpArea < 100){
                setBuiltError(true)
                setShowBuildUpError(true)
            }
            forceUpdate(!update)
            return
        } else {
            generalDetailRef?.current?.formValidator.current?.hideMessages()
            setShowBuildUpError(false)
            forceUpdate(!update)
        }
        //2nd validation
        let pendingClassList = []
        let classrooms = []
        let data = objectDeepClone(formData)
        if (refClassrooms && refClassrooms.current) {
            const ref: any = refClassrooms.current
            pendingClassList = ref.renderErrorState()
            classrooms = ref.fecthClassrooms()
            data = {
                ...data,
                classrooms: classrooms,
            }
        }
        if (pendingClassList.length > 0) {
            setErrorMessage("Please fill all fields.")
            return
        } else {
            setErrorMessage("")
        }
        const json = {...formData}

        if (json.board.board === "Other") {
            json.board.board = boardOfStudyData.boardOfStudy
        }

        if (json.board.board === "Custom") {
            for (let i = 0; i < json.board.classWiseDetails.length; i++) {
                if (json.board.classWiseDetails[i].board === "Other") {
                    json.board.classWiseDetails[i].board = json.board.classWiseDetails[i].otherBoard
                    delete json.board.classWiseDetails[i].otherBoard
                }
            }
        }
        updateProgress.value = true
        setShowPrompt(false)
        //set edited data
        let apiData = {
            data: {
                ...parentData?.data,
                schoolGeneral: {...json},
                schoolAmenities: {
                    ...parentData.data.schoolAmenities,
                    builtUpArea: formData.builtUpArea,
                    classrooms: [...classrooms]
                },
            },
            previousData: {
                ...parentData?.previousData,
                schoolGeneral: {...json},
                schoolAmenities: {
                    ...parentData.data.schoolAmenities,
                    builtUpArea: formData.builtUpArea,
                    classrooms: [...classrooms]
                },
            }
        }
        console.log("apiData------",apiData)
        setUpdatingData(true)
        let resp = await context.apis[context.user.role].updateSchoolDetails(
            context.validator, apiData
        )
        console.log("updateSchoolDetails",resp)
        if(resp && resp.done){
            toast.success("Details updated successfully")
            setRefetch(!refetch)
            setShowModal(false)
            setOverlay(false)
        }
        setBoardOfStudy({boardOfStudy: ""})
        setUpdatingData(false)
    }
    const closeModal = () => {
        // getSchoolGeneralDetails()
        setShowModal(false)
        setOverlay(false)
    }
    const openModal = () => {
        let element = document.getElementById("editSchool")
        console.log("element", element)
        element.scrollIntoView({block: "start"})
        setShowModal(true)
        setOverlay(true)
        // parentData.setData({
        //     ...parentData.data,
        //     schoolGeneral: {...formData}
        // })
    }
    const updateUsersToolTipProgress = async () => {
        let userToolTipResp = await context.apis[context.user.role].updateUsersToolTipProgress(
            context.validator, {}, {toolTipProgress: {...parentData.toolTipProgress, mySchool: false}}
        )
        console.log(userToolTipResp)
    }

    const hanldeOnboardingComplete = () => {
        updateUsersToolTipProgress()
        parentData.setToolTipProgress({
            ...parentData.toolTipProgress,
            mySchool: false
        })
    }
    const exitOnBoarding = (e) => {
        console.log(e)
    }
    const limitStringLength = (string: String, limit: number) => {
        return string.split("").slice(0, limit).join("")
    }

    useEffect(() => {
        setErrorMessage("")
    }, [showModal])
    return (
        <>
        {schooldetailLoading && calenderLoading ?
            <Loader />
            :
            <>
                <div className={`ovelap ${overlay === true ? "active" : ""}`}></div>
                <div className="col-xl-10 col-lg-12 col-md-12 col-xs-12">
                    <div className="rightPart noBack">
                    <div className="row">
                    <div className="col-xs-12 col-lg-12 col-md-12 col-xs-12">
                            <OnboardingBackBtn
                                link="/"
                                text="Log Out"
                            />
                        </div>
                        <TopBar />
                        <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-20">
                            <h2 className="htx1 htx-school clearfix">{schoolName !== "" && schoolName}</h2>  
                        </div>
                        <div className="col-xl-9 col-lg-9 col-md-10 col-xs-12 mt-30">
                            <div className="row">
                            <div className="col-xl-4 col-lg-4 col-md-5 col-xs-12 pl-0">
                                <div className="my-school-reptative">
                                    <h3>WISR Representative</h3>
                                        {
                                            <p>helpdesk@wisr.co.in</p>
                                        }
                                    </div>
                                </div>
                                <div className="col-xl-8 col-lg-8 col-md-7 col-xs-12">
                                    <div className="my-school-features">
                                    <h4>School Features <a style={{cursor: "pointer"}} onClick={openModal}><img src={proEdit} /> Edit</a></h4>
                                    <ul>
                                            <li>{schoolEduLevel.length !== 0 && schoolEduLevel.map((eduLevel, index) => (
                                                index == schoolEduLevel.length-2 ? `${eduLevel} & ` : index == schoolEduLevel.length-1 ? eduLevel : `${eduLevel}, `
                                            ))}</li> 
                                            <li>{schoolTotalStudent && schoolTotalStudent} Students</li>
                                            <li>{schoolLang.length !== 0 && schoolLang.map((language, index) => (
                                                index == schoolLang.length-2 ? `${language} & ` : index == schoolLang.length-1 ? language : `${language}, `
                                            ))} Medium</li>
                                            <li>{schoolBoard.length !== 0 && schoolBoard.map((board, index) => (
                                                index == schoolBoard.length-2 ? `${readAbleBoard(board)} & ` : index == schoolBoard.length-1 ? readAbleBoard(board) : `${readAbleBoard(board)}, `
                                            ))} Board</li>
                                            <li>{schoolBuildUpArea && schoolBuildUpArea} Sq Ft. School area</li>
                                            <li>{schoolTotalInventory && schoolTotalInventory} Inventories</li>   
                                            <li>{schoolTotalActivities && schoolTotalActivities} Events</li>   
                                    </ul> 
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-40 mb-50">
                            <div className="line-full"></div>
                        </div>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-30 mb-30">
                            <div id="toolTip" className="row">
                                <div className="col-xl-4 col-lg-4 col-md-12 col-xs-12">
                                    {selectedCampaignId !== "" ? 
                                        <div className="my-campaign">
                                            <h2 className="htx2 clearfix">My School Calendar</h2>
                                            <div className="brand-creat-img">
                                                <h3>Name</h3>
                                                <h4>{selectedCampaignDetail.name && selectedCampaignDetail.name}</h4>
                                            </div>
                                            <div className="brand-creat-img">
                                                <h3>Duration</h3>
                                                <div className="starend">
                                                    <p>Start <b>{selectedCampaignDetail.startDate && formatDateShowMonth(selectedCampaignDetail.startDate, 4)}</b></p>
                                                    <img className="next-arow" src={riarrownext}/>
                                                    <p>End <b>{selectedCampaignDetail.endDate && formatDateShowMonth(selectedCampaignDetail.endDate, 4)}</b></p>
                                                </div>
                                            </div>
                                            <div className="brand-creat-img">
                                                <h3>Description</h3>
                                                <p className="ptx2">{selectedCampaignDetail.discription && !showFullDiscription ? selectedCampaignDetail.discription?.length > 20 ? `${limitStringLength(selectedCampaignDetail.discription, 20)}...` : `${selectedCampaignDetail.discription}.` : `${selectedCampaignDetail.discription}.`} {!showFullDiscription && selectedCampaignDetail.discription.length > 20 ? <a style={{cursor: "pointer"}} onClick={() => setShowFullDiscription(true)}>See More</a> : showFullDiscription && selectedCampaignDetail.discription.length > 20 && <a style={{cursor: "pointer"}} onClick={() => setShowFullDiscription(false)}>See Less</a>}</p>
                                            </div>
                                            <div className="brand-creat-img">
                                                <h3>Booked Inventory</h3>
                                                <p className="ptx2">{selectedCampaignDetail.inventories.length !== 0 && showFullInventoryList ? selectedCampaignDetail.inventories.map((e, index) => index == selectedCampaignDetail.inventories.length - 2 ? <span>{e.inventoryDetails?.name} and </span> : index == selectedCampaignDetail.inventories.length - 1 ? <span>{e.inventoryDetails?.name}.</span> : <span>{e.inventoryDetails?.name}, </span>) : selectedCampaignDetail.inventories.map((e, index) => index > 3 ? "" : index == selectedCampaignDetail.inventories.length - 2 ? <span>{e.inventoryDetails?.name} and </span> : index == selectedCampaignDetail.inventories.length - 1 ? <span>{e.inventoryDetails?.name}. </span> : <span>{e.inventoryDetails?.name}, </span>)} {selectedCampaignDetail.inventories.length >= 5 && !showFullInventoryList ? <a style={{cursor: "pointer"}} onClick={() => setShowFullInventoryList(true)}>{selectedCampaignDetail.inventories.length - 4} more</a> : selectedCampaignDetail.inventories.length >= 5 && showFullInventoryList && <a style={{cursor: "pointer"}} onClick={() => setShowFullInventoryList(false)}>See Less</a>}</p>
                                            </div>
                                        </div>
                                        :
                                        <div className="my-campaign">
                                            <h2 className="htx2 clearfix">My School Calendar</h2>
                                            <div className="brand-creat-img">
                                                <h4>No Campaign Schedule for this date</h4>
                                            </div>
                                        </div>
                                    }

                                </div>
                                <div className="col-xl-8 col-lg-8 col-md-12 col-xs-12">
                                    {value && <Calander value={value} setValue={setValue} brandCampaignList={brandCampaignList} holidayList={holidayList} schoolEventList={schoolEventList} selectedCampaignId={selectedCampaignId} setSelectedCampaignId={setSelectedCampaignId} modalCampaignList={modalCampaignList} setModalCampaignList={setModalCampaignList} />}
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>    
                <div
                    id='amenitiesModal'
                    className={`popup ${showModal === false ? "hide" : ""}`}
                >
                    <div className='popup-header'>
                        {!updatingData && 
                            <span
                                onClick={closeModal}
                                className='close toggle'
                                data-target='amenitiesModal'
                                >
                            close
                            </span>
                        }
                    </div>
                    <div className='popup-body'>
                        <div className={`${isEdit === false ? "col-xl-9 col-lg-9 col-md-12 col-xs-12" : ""}`}>
                            <div className={`${isEdit === false ? "content mCustomScrollbar fixhight newMvp-pages-css" : ""}`} id="editSchool">
                            {updatingData ?
                                <div style={{height: "300px"}}>
                                    <Loader />
                                </div>
                                :
                                <div className={`${isEdit === false ? "rightPart w80" : ""}`}>
                                    <form onSubmit={(e) => e.preventDefault()}>
                                        <SchoolGeneralDetail 
                                            ref={generalDetailRef}
                                            isEdit={isEdit}
                                            onProceed={onProceed}
                                            formData={formData}
                                            setFormData={setFormData}
                                            showPrompt={showPrompt}
                                            setShowPrompt={setShowPrompt}
                                            showModal={showModal}
                                            otherBoardRef={otherBoardRef}
                                            setOtherBoardRef={setOtherBoardRef}
                                            boardOfStudyData={boardOfStudyData}
                                            setBoardOfStudy={setBoardOfStudy}
                                            builtError={builtError}
                                            setBuiltError={setBuiltError}
                                            showBuildUpError={showBuildUpError}
                                            otherBoardReference={otherBoardReference}
                                        />
                                        <div className="row">
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                                <h3 className="htx2 mb10">Student Details</h3>
                                            </div>
                                            <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb40'>
                                                <ClassroomAttributes
                                                    ref={refClassrooms}
                                                    compareOldAndNewData={compareOldAndNewData}
                                                    setInitialData={setInitialData}
                                                    newParentData={oldParentData}
                                                    showModal={showModal}
                                                />
                                            </div>
                                            <p className="valError">
                                                {
                                                    errorMessage && errorMessage !== "" &&
                                                    <>
                                                        <img src={errorSVG}/>
                                                        {errorMessage}
                                                    </>
                                                }
                                            </p>
                                            <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-20 end-xs'>
                                                <div className="bottom-error-btn">
                                                    <p className="valError">
                                                        {
                                                            errorMessage && errorMessage !== "" &&
                                                            <>
                                                                <img src={errorSVG}/>
                                                                {errorMessage}
                                                            </>
                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="row col-xl-12 col-lg-12 col-md-12 col-xs-12 end-md">
                                                <div className="forminpt">
                                                    <input onClick={submitForm} name="Proceed" value="Save" className="btn btn-orange sc-ganral" type="button" />
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            }
                            </div>
                        </div>
                        
                        {/* <ConfirmAlert
                            when={showPrompt}
                            title="Leave page without saving..."
                            cancelText="Cancel"
                            okText="Confirm"
                            onOK={() => true}
                            onCancel={() => false}
                            parentCallback={confirmAlertCallback}
                        /> */}
                    </div>
                </div>
                <Steps
                    enabled={onBoarding.stepsEnabled}
                    steps={onBoarding.steps}
                    initialStep={onBoarding.initialStep}
                    onComplete={hanldeOnboardingComplete}
                    onExit={exitOnBoarding}
                    options={onBoarding.options}
                    ref={stepRef}
                />
            </>
        }
        </>
    )
}

export default MySchool
