import React, { useEffect, useState, useContext, useRef } from 'react'
import AppContextObject from "../../../common/context/common"
import { formatDateShowMonth, titleCase } from "../../../service/helper"
import DataContext from "../../../service/brandContext"

const TermsAndConditions = (props) => {
    const context = useContext(AppContextObject)
    const parentData = useContext<any>(DataContext)
    useEffect(() => {
      let element = document.getElementById("termsAndCondition")
      // console.log("element", element)
      element && element.scrollIntoView({block: "start"})
    }, [props.showMou])
    return (
        <>
      <div id="termsPopup" className={`onboarding-terms popup brand show `} style={{display: `${props.showMou ? "" : "none"}`}} >
        <div className="popup-header">{/*<span className="toggle left-back" data-target="termsPopup">close</span>*/} <span onClick={props.onToggle} className="close toggle" data-target="termsPopup">close</span> </div>
        <div className="popup-body">
          <div className="facili-avai-in clearfix">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="termsBox" id='termsAndCondition'>
                  <h2>Terms of Use</h2>
                  <div className='termsBoxContent'>
                    <p>This page describes the terms (“Terms”) on which Wondrlab Martech Platforms Pvt. Ltd.  (“Wondrlab Maretch”) Offers its users (“Users”) access to its services. They apply to the use of  Wondrlab Martech's mobile or web application (“Platform – WISR”). By using the Platform-WISR, Users agree to the Terms. It is therefore important that Users read these Terms carefully.</p>
                    <p>These Terms govern the use of this Platform-WISR, and any other related policies/agreement/documents or legal relationship with the Wondrlab Martech Platforms Pvt. Ltd. in a legally binding way (collectively hereinafter referred to as “Agreement”). Capitalized words are defined in the relevant dedicated section of this document.</p>
                    <p>This Platform-WISR is provided by: Wondrlab Martech Platforms Private Limited, a Wondrlab Group Company.</p>
                    <p>Wondrlab Martech may from time to time update or change these Terms. Changes take effect immediately after  Wondrlab Martech posts them on the WISR Platform. By continuing to use the Platform-WISR, Users will be subject to these changes and the new Terms.  Wondrlab Martech therefore recommends Users to revisit this page regularly to stay informed of the current Terms that apply to their use of the Platform-WISR. These terms supersede all previous oral and written terms and conditions (if any) communicated to any of our Users.</p>
                  </div>
                  <div className='termsBoxContent'>
                    <h4>PLATFORM-WISR</h4>
                    <p>The Platform-WISR and services available from it is owned and operated by  Wondrlab Martech.  Wondrlab Martech is a company registered in Mumbai, Maharshtra, India under registration CIN: U72900MH2021PTC358138. Its registered mailing address is B-801, 8th Floor, Pinnacle Corporate Park BKC, Near Trade Center, Bandra (East), Mumbai, Maharashtra 400051. The terms “ Wondrlab Martech”, “we” and “us” on this page refer to  Wondrlab Martech. The terms “Users” and “you” refer to Users of the Platform-WISR.</p>
                    <p>This Platform WISR refers to: this Platform WISR, including its subdomains and any other website through which the Owner makes its Service available; the Application Program Interfaces (API); the Service; any applications, sample and content files, source code, scripts, instruction sets or software included as part of the Service, as well as any related documentation;</p>
                  </div>
                  <div className='termsBoxContent'>
                    <h4>1. SUBSCRIPTIONS and ONBOARDING</h4>
                    <p>1.1 Your WISR subscription and onboarding is free.</p>
                    <p>1.2 We may offer a number of Subscription and Onboarding login options in relation to the provision of our own Platform-WISR and Services. Some Subscription and Onboarding options may have differing conditions and limitations, which will be disclosed at your sign-up or in other communications made available to you. </p>
                  </div>

                  <div className='termsBoxContent'>
                    <h4>2. WISR SERVICE</h4>
                    <p>2.1 The Platform-WISR and the Services available on it are for your use only in relation with availability and usage of school inventory like fixed spaces, school events/special occasions and related marketing activities. The Platform-WISR and the Services available on it may not be shared with individuals beyond your organization. During your WISR Subscription, we grant you a limited, non-exclusive, non-transferrable right to access the Platform-WISR and use the Services available on it. Except for the foregoing, no right, title or interest shall be transferred to you.</p>
                    <p>2.2 You agree to use the WISR service, including all features and functionalities associated therewith, in accordance with all applicable laws, rules and regulations, or other restrictions on use of the service or content therein. You agree not to archive, reproduce, distribute, modify, display, perform, publish, license, create derivative works from, offer for sale, or use (except as explicitly authorized in these Terms of Use) content and information contained on or obtained from or through the WISR service. You also agree not to: circumvent, remove, alter, deactivate, degrade or thwart any of the content protections in the WISR service; use any robot, spider, scraper or other automated means to access the WISR service; decompile, reverse engineer or disassemble any software or other products or processes accessible through the  WISR service; insert any code or product or manipulate the content of the WISR service in any way; or use any data mining, data gathering or extraction method. In addition, you agree not to upload, post, e-mail or otherwise send or transmit any material designed to interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications equipment associated with the WISR service, including any software viruses or any other computer code, files or programs. We may terminate or restrict your use of our service if you violate these Terms of Use or are engaged in illegal or fraudulent use of the service.</p>
                  </div>

                  <div className='termsBoxContent'>
                    <h4>3. PASSWORDS AND ACCOUNT ACCESS</h4>
                    <p>The User who created the WISR account (the "Account Owner") is responsible for any activity that occurs through the  WISR account. To maintain control over the account and to prevent anyone from accessing the account, the Account Owner should maintain control over the devices that are used to access the service and not reveal the password associated with the account to anyone. You are responsible for updating and maintaining the accuracy of the information you provide to us relating to your account. We can terminate your account or place your account on hold in order to protect you,  Wondrlab Martech or our partners/affiliate company from identity theft or other fraudulent activity. </p>
                  </div>
                  <div className='termsBoxContent'>
                    <h4>4. INTELLECTUAL PROPERTY RIGHTS </h4>
                    <p>Wondrlab Martech owns all rights on its name and logo that appears on the Platform (the "Logo"). You are not permitted to, and you agree not to, use these marks in any way (including as part of any other trademark, company name or domain name), in connection with any product or service.  Wondrlab Martech owns or is the authorized licensee of all intellectual property rights (including copyright) in and to the Platform including intellectual property rights in the content hosted, published, displayed, uploaded on the Platform-WISR by us ( subject to the license granted herein). Copyright laws and treaties throughout the world protect these works and all rights in and to them are reserved by us. No information, content or material from  Wondrlab Martech may be copied, reproduced, republished, uploaded, posted, transmitted or distributed in any way without  Wondrlab Martech's express written permission. </p>
                    <p>Wondrlab Martech and/or its licensors assert all proprietary rights in and to all names and trademarks contained on the Platform WISR. Notwithstanding the generality of the foregoing, the name, "WISR" is the trademark and copyright (or any other intellectual property right) of Wondrlab Martech and/or its licensors. Any use of the WISR's trademarks or copyright, unless otherwise authorized in a written agreement, will constitute an infringement upon the trademark and copyright (or any other such intellectual property right) of Wondrlab Martech and may be actionable under the applicable laws.</p>
                  </div>

                  <div className='termsBoxContent'>
                    <h4>5. WARRANTIES AND LIMITATIONS ON LIABILITY </h4>
                    <p>The  Wondrlab Martech service is provided "as is" and without warranty or condition. In particular, our service may not be uninterrupted or error-free. You waive all special, indirect and consequential damages against us. </p>
                    <p>To the fullest extent permitted by applicable law, in no event will Wondrlab Martech Platforms Pvt. Ltd. or its affiliates be liable for any direct, indirect, special, incidental, punitive, exemplary or consequential damages (including, without limitation, loss of business, revenue, profits, goodwill), whether or not Wondrlab Martech has been warned of the possibility of such damages or could have reasonably foreseen such damages. Notwithstanding anything  to the contrary in this Agreement, Wondrlab Martech's liability under this Agreement to YOU shall in no event exceed the fee amounts collected from You in the preceding Six Months.</p>
                  </div>

                  <div className='termsBoxContent'>
                    <h4>6. DISPUTE RESOLUTION  </h4>
                    <p>If you consider there to be a dispute between you and Wondrlab Martech, please contact us at <a href='mailto:helpdesk@wisr.co.in'>helpdesk@wisr.co.in</a>. These Terms and the Privacy Policy available separately on the Platform shall be governed by and construed in accordance with the laws of Mumbai, India. You agree to submit to the exclusive jurisdiction of the courts of Mumbai, India to resolve any dispute arising out of the Terms or your use of the Platform. Your Use of the Platform is not permitted in any jurisdiction that does not give effect to all provisions of the Terms, including without limitation, this section. </p>
                  </div>

                  <div className='termsBoxContent'>
                    <h4>7. BREACH OF THESE TERMS </h4>
                    <p>Wondrlab Martech shall be entitled to and reserves the right to restrict, suspend or terminate your WISR account or deny you access to the WISR Platform or take such other action as  Wondrlab Martech deems appropriate, with or without notice to you, if it determines, in its sole discretion, that you are in breach of any of the Terms or that your use of the Platform is inappropriate or otherwise unacceptable, whether or not on the basis of a User or a third party report of violation of its right as a result of your use of the Platform. Upon suspension or termination of your account with us, we reserve the right to remove or delete your information that is available with us, including but not limited to your login, account information and information posted by you. The restriction, suspension or termination of your WISR account or your access to the WISR Platform pursuant to this section will be without prejudice to any rights which  Wondrlab Martech may have against you in respect of your breach of these Terms.  Wondrlab Martech shall be entitled to disclose your user identity and details if required or requested by the courts or other law enforcement authorities and/or agencies or in such other circumstances as  Wondrlab Martech in its sole discretion considers reasonably necessary or appropriate.</p>
                  </div>

                  <div className='termsBoxContent'>
                    <h4>8. INDEMNIFICATION </h4>
                    <p>You agree to indemnify and hold each of Wondrlab Martech Platforms Pvt. Ltd., its directors, representatives, employees and contractors, affiliate companies, harmless from any claim or demand, including reasonable solicitors fees, made by any third party due to or arising out of your use of the WISR Platform, the breach of these Terms by you, or the infringement by you of any intellectual property or other right of any other person or entity.</p>
                  </div>

                  <div className='termsBoxContent'>
                    <h4>9. GENERAL </h4>
                    <p>If any provision of these Terms is unenforceable as determined by a court of competent jurisdiction under your national law, then such provision shall be struck out. All remaining  provisions shall remain in full force and effect. If we delay or fail to act in respect of any breach by you of these Terms, this will not operate as a waiver of our right to act in relation to subsequent or similar breaches by you.</p>
                    <p>NOTICES: If you need to give us notice of anything, you must write to us in English at <a href='mailto:helpdesk@wisr.co.in'>helpdesk@wisr.co.in</a>. If we need to give you notice of anything, we shall write to you at the email address you provide to us, either during the registration process or when your email address changes. Notice shall be deemed given within 2 banking working days after an email is sent, unless the sender is notified that the email address is invalid or receives any ‘delivery failure' notification. If you have any questions about this, please contact <a href='mailto:helpdesk@wisr.co.in'>helpdesk@wisr.co.in</a>.</p> 
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className={`blurBackground`} style={{display: `${props.showMou ? "" : "none"}`}}></div> */}
    </>
    )
}

export default TermsAndConditions