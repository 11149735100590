import React, { useEffect, useState, useContext, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import uploadSvg from "../../../../assets/images/upload-svg.svg";
import uploadGreenSvg from "../../../../assets/images/uploadGreen-svg.svg";
import sucessCheck from "../../../../assets/images/sucess-check.svg";
import closeSvg from "../../../../assets/images/close-svg.svg";
import blockSvg from "../../../../assets/images/block-svg.svg";
import reloadSvg from "../../../../assets/images/reload-svg.svg";
import AppContextObject from "../../../../common/context/common";
import DataContext from "../../../../service/brandContext";
import Compress from "compress.js";
import { PublicContextInitiator } from "../../../apis/publicContextInitiator";
import ImageModal from "../../../../components/addon/imageModal/imageModal";
import ConfirmDialog from "../../../../components/addon/confirmDialog/confirmDialog";
import errorSVG from "../../../../assets/images/error.svg";
import { compareObjs } from "../../../../service/helper";
import BrandBackBtn from "../components/brandBackBtn";
import axios from "axios";
import Loader from "../../../../components/addon/loader";
import CircularProgress from "@mui/material/CircularProgress";
// import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
// import LinearProgress from '@mui/material/LinearProgress';
import { Carousel } from "react-bootstrap";
import CreativeCampaignCarousel from "./creativeCampaignCarousel";
// import CreativeCampaignCarousel from './creativeCampaignCarousel'
import LinearProgress from "@mui/material/LinearProgress";
import Grid from "@mui/material/Grid";
import toast from "react-hot-toast";
import exp from "constants";

// import Modal from 'react-bootstrap/Modal';
// import 'bootstrap/dist/css/bootstrap.min.css';

const CampaignCreative = ({ setShowProgressSideMenu }) => {
	const location = useLocation();
	const compress = new Compress();
	// const creative1Ref = useRef()
	// const creative2Ref = useRef()
	// const creative3Ref = useRef()
	const history = useHistory();
	const parentData = useContext<any>(DataContext);
	const context = useContext(AppContextObject);
	const [formData, setFormData] = useState(
		parentData?.data?.campaignCreative?.creatives
	);
	const [image1Rejected, setImage1Rejected] = useState(false);
	const [image2Rejected, setImage2Rejected] = useState(false);
	const [image3Rejected, setImage3Rejected] = useState(false);

	const [activeImageIndex, setActiveImageIndex] = useState(0);

	const [modalShow, setModalShow] = React.useState(false);
	const [muiprogress, setMuiprogress] = React.useState(0);
	const [inventoryList, setInventoryList] = React.useState([]);
	const [expand, setExpand] = useState(
		parentData?.data?.campaignCreative?.inventoryCreativeData.length > 0
	);

	const [showPreview, setShowPreview] = useState(false);

	const [size, setSize] = useState({});
	const imageRef = useRef(null);

	const [apiSet, setApiSet] = useState([
		...parentData?.data?.campaignCreative?.inventoryCreativeData,
	]);
	const [loading, setLoading] = useState(false);
	// useEffect(() => {
	//     setLoading(true);
	//     setTimeout(() => {
	//         setLoading(false);
	//     }, 2000);
	// }, []);
	useEffect(() => {
		let arr = [];
		parentData.data.inventory.usedInventories.forEach((item) => {
			if (myArray.includes(item.name)) {
				arr.push(item.name);
			}
		});
		console.log("inventoryList", arr);
		setInventoryList([...arr]);
	}, []);

	// const myArray = [
	//     "Classroom",
	//     "Notice Board",
	//     "School Bags",
	//     "School Bus",
	//     "School Gate",
	//     "Washrooms",
	//     "Music Class"

	//     // "Hallway",
	// ];

	const myArray = [
		"Classroom",
		"Notice Board",
		"School Bags",
		"School Bus",
		"School Gate",
		"Washrooms",
		"Water Coolers",
		"Music Class",
	];

	useEffect(() => {
		// console.log("value =" ,parentData?.data?.inventory?.usedInventories?.some(item => {
		//     myArray.includes(item.name)
		// }))
		setShowPreview(
			parentData?.data?.inventory?.usedInventories?.some((item) => {
				console.log("This is item dot name----- ", item.name);
				return myArray.includes(item.name);
			})
		);
	}, [parentData?.data?.inventory?.usedInventories]);

	const sendAPI = async (e?) => {
		if (e) {
			e.stopPropagation();
		}

		setLoading(true); // Set loading before sending API request
		let apiData = {
			camp_name: parentData.data.brandDetail.name,
			inventory_id: [],
			creative_path: {
				"1:1": formData.image1.key,
				"1:2": formData.image2.key,
				"2:1": formData.image3.key,
			},
		};
		console.log("testtest", apiData);
		parentData?.data?.inventory?.usedInventories?.forEach((item) => {
			if (myArray.indexOf(item.name) != -1) {
				apiData.inventory_id.push(myArray.indexOf(item.name) + 1);
			}
		});

		console.log("imageeee-------imageeee", formData.image1.image);

		let imgWidth = formData?.image1?.image?.naturalWidth;

		let imgHeight = formData?.image1?.image?.naturalHeight;

		console.log("imageeee-------imgWidth", imgWidth);
		console.log("imageeee-------imgHeight", imgHeight);
		console.log("This is apiData ------ New", apiData);
		console.log("This is apiData ----- ", apiData);
		// const requestOptions = {
		//     method: 'POST',
		//     headers: { 'Content-Type': 'application/json' },
		//     body: JSON.stringify(
		//         {
		//             "camp_name": "test",
		//             "inventory_id": [3, 5],
		//             "creative_path": { "2:1": "1676971868901-bisleri1.jpg" }
		//         }
		//     )
		// };

		let response = await axios.post(
			"https://api.wisr.co.in/overlay",
			apiData
		);
		// let response = await axios.post('https://api.wisr.co.in/overlay', apiData);
		setLoading(false); // Stop loading

		console.log("inside of sendAPI response requestOptions--- ", response);

		// if(response.data.Description == "Creative path Missing or Creative size Improper"){
		if (
			response.data.Description ==
			"Please upload valid creative size for processing preview"
		) {
			toast.error("Please upload valid creative size for processing preview");
			setExpand(true);
			return;
		}

		let respnew = await context.apis.public.getUploadedImagesSignedUrl(
			context.validator,
			{ keys: response.data.s3location }
		);

		let arr = [];

		let Obj = { inventoryName: "", image: "" };
		console.log("This is res---- 1", respnew);
		response?.data?.inventories?.forEach((item, index) => {
			console.log("Hellllllllooo", response?.data?.inventories, item);
			let Obj = { inventoryName: item, image: respnew.response[index].image };
			arr.push(Obj);
		});
		console.log("This is res---- 2", respnew);
		setApiSet(arr);
		setExpand(true);

		console.log("This is respnew outside ---", respnew);
		console.log("This is arr --- arr ", arr);
	};
	const [loading1, setLoading1] = useState(false);
	const [loading2, setLoading2] = useState(false);
	const [loading3, setLoading3] = useState(false);
	const [toggleShowImage1, setToggleShowImage1] = useState(false);
	const [toggleShowImage2, setToggleShowImage2] = useState(false);
	const [toggleShowImage3, setToggleShowImage3] = useState(false);
	const [showExitPopup, setShowExitPopup] = useState(false);
	const [overlay, setOverlay] = useState(false);
	const [errorMessage, setErrorMessage] = useState(false);
	const [creativeNeeded, setCreativeNeeded] = useState(false);
	const [showCreativeNeededPopup, setShowCreativeNeededPopup] = useState(false);
	const [showImageError, setShowImageError] = useState(false);
	const [confirmDialogBtnLoading, setConfirmDialogBtnLoading] = useState(false);

	const [selectedImage, setSelectedImage] = useState("");
	const [showPopup, setShowPopup] = useState(false);
	const [imageUploaded, setImageUploaded] = useState(false);

	// const [activeSelectedID, setActiveSelectedID] = useState("")

	useEffect(() => {
		const isSummary = new URLSearchParams(location.search).get("isSummary");
		if (isSummary === "true") {
			console.log("object");
			parentData.setProgress({
				brandDetails: true,
				inventory: true,
				school: true,
				recommendedSchool: true,
				event: true,
				campaignCreative: false,
				preview: false,
			});
		}
		// console.log(history)
		if (history.location.state) {
			let data: any = history.location.state;
			if (data.creativeRework) {
				setCreativeNeeded(true);
			}
		}
	}, []);

	console.log("parentData?.data?-->>", parentData?.data);
	useEffect(() => {
		setShowProgressSideMenu(true);
		if (parentData.setActiveModule) {
			parentData.setActiveModule({
				...parentData.activeModule,
				module: "creative",
				subModule: "",
			});
		}
	}, []);
	const handleCreativeUpload = async (e, type) => {
		console.log("Target Files -----", e.target.files);
		console.log("Target Files ----- output", e.target.files.name);

		// let file;
		// let img;
		// if ((file = e.target.files[0])) {
		//     img = new Image();
		//     var objectUrl = URL.createObjectURL(file);
		//     img.onload = function () {
		//         alert(this.width + "  " + this.height);
		//         URL.revokeObjectURL(objectUrl);
		//     };
		//     console.log("img.src = objectUrl----- ",img.src = objectUrl);
		// }

		if (e.target.files && e.target.files.length > 0) {
			if (!e.target.files[0].type.startsWith("image/")) {
				setShowImageError(true);
				return;
			} else {
				setShowImageError(false);
			}
			setErrorMessage(false);
			if (type === 1) {
				setLoading1(true);
			} else if (type === 2) {
				setLoading2(true);
			} else if (type === 3) {
				setLoading3(true);
			}
			const formData1 = new FormData();
			const resizedImage = await compress
				.compress([e.target.files[0]], {
					size: 2, // the max size in MB, defaults to 2MB
					quality: 0.4, // the quality of the image, max is 1,
					resize: false, // defaults to true, set false if you do not want to resize the image width and height
				})
				.catch((err) => {
					console.log("Error while compressing....", err);
				});
			const img = resizedImage[0];
			const base64str = img.data;
			const imgExt = img.ext;
			let compressedFile = Compress.convertBase64ToFile(base64str, imgExt);
			formData1.append(`image`, compressedFile, img.alt);
			let reqBody = formData1;
			if (parentData?.data?.brandDetail?.brandNameLabel) {
				// let destinationFolder = `brands/${context?.user?.parentBrand ? context?.user?.parentBrand : context?.user?.brand}-${context?.user?.companyName ? context?.user?.companyName : context?.user?.brandName}/${parentData?.data?.brandDetail?.brandNameLabel}/campaign-creatives`
				let destinationFolder = `brands/${
					context?.user?.parentBrand
						? context?.user?.parentBrand
						: context?.user?.brand
				}/${parentData?.data?.brandDetail?.brandName}/campaign-creatives`;
				reqBody.append("destinationFolder", destinationFolder);
			}
			// formData1.append(`image`, e.target.files[0]);
			const requestOptions = {
				method: "POST",
				body: reqBody,
			};

			let publicContextInitiator = new PublicContextInitiator(context);
			fetch("/api/uploadFiles", requestOptions)
				.then((response) => {
					response
						.json()
						.then((res) => {
							let data = [];
							res["response"].map(async (item) => {
								data.push(item.Key);
							});
							publicContextInitiator
								.getUploadedImagesSignedUrl(context.validator, { keys: data })
								.then((res) => {
									let updateImageObj = [];
									let requests = res.response.map((item) => {
										updateImageObj.push({ image: item.image, key: item.key });
										console.log(updateImageObj);
										if (type === 1) {
											console.log("setImage1", updateImageObj);
											setFormData({ ...formData, image1: updateImageObj[0] });
											setImage1Rejected(false);
										} else if (type === 2) {
											setFormData({ ...formData, image2: updateImageObj[0] });
											setImage2Rejected(false);
										} else if (type === 3) {
											setFormData({ ...formData, image3: updateImageObj[0] });
											setImage3Rejected(false);
										}
										setImageUploaded(true);
									});
									Promise.all(requests).then(() => {
										if (type === 1) {
											setLoading1(false);
										} else if (type === 2) {
											setLoading2(false);
										} else if (type === 3) {
											setLoading3(false);
										}
									});
								});
						})
						.catch((error) => {
							if (type === 1) {
								setImage1Rejected(true);
								setLoading1(false);
								// const dt = new DataTransfer()
								e.target.value = null;
							} else if (type === 2) {
								setImage2Rejected(true);
								setLoading2(false);
								// const dt = new DataTransfer()
								e.target.value = null;
							} else if (type === 3) {
								setImage3Rejected(true);
								setLoading3(false);
								// const dt = new DataTransfer()
								e.target.value = null;
							}
						});
				})
				.catch((error) => {
					if (type === 1) {
						setImage1Rejected(true);
						setLoading1(false);
						// const dt = new DataTransfer()
						e.target.value = null;
					} else if (type === 2) {
						setImage2Rejected(true);
						setLoading2(false);
						// const dt = new DataTransfer()
						e.target.value = null;
					} else if (type === 3) {
						setImage3Rejected(true);
						setLoading3(false);
						// const dt = new DataTransfer()
						e.target.value = null;
					}
				});
			// setLoading(false)
			// if(type === 1){
			//     setLoading1(false)
			// }
			// else if(type === 2){
			//     setLoading2(false)
			// }
			// else if(type === 3){
			//     setLoading3(false)
			// }
		}
	};
	// const handleClick = async(e, type) => {
	//     if(type === 1){
	//         let ref:any = creative1Ref
	//         ref.current.click()
	//     }
	//     else if(type === 2){
	//         let ref:any = creative2Ref
	//         ref.current.click()
	//     }
	//     else if(type === 3){
	//         let ref:any = creative3Ref
	//         ref.current.click()
	//     }
	// }
	const confirmClose = (type) => {
		if (type === 1) {
			setToggleShowImage1(false);
		} else if (type === 2) {
			setToggleShowImage2(false);
		} else if (type === 3) {
			setToggleShowImage3(false);
		}
		setOverlay(false);
	};
	const openImageModal = (type) => {
		setOverlay(true);
		if (type === 1) {
			setToggleShowImage1(true);
		} else if (type === 2) {
			setToggleShowImage2(true);
		} else if (type === 3) {
			setToggleShowImage3(true);
		}
		// setOverlay(true)
	};
	const onSave = async (confirm) => {
		if (confirm) {
			if (parentData) {
				if (
					!(formData.image1.key || formData.image2.key || formData.image3.key)
				) {
					setErrorMessage(true);
					return;
				}
				if (parentData.setProgress) {
					parentData.setProgress({
						...parentData.progress,
						campaignCreative: true,
					});
				}
				// let allInventory = [...parentData.data.inventory.usedInventories]
				// let inventory = parentData.data.inventory.usedInventories.length > 0 ? parentData.data.inventory.usedInventories.map(item => item.parentName || item.name) : []
				// let schools = parentData.data.school.schools.length > 0 ? parentData.data.school.schools.map(item => item._id) : []
				// let resp = await context.apis[context.user.role].checkSelectedusedInventories(
				//     context.validator, {usedInventories: inventory, schools: schools}
				// )
				// console.log("checkSelectedusedInventories", resp)
				// if(resp && resp.length > 0){
				// let usedInventory = allInventory && allInventory.length > 0 && allInventory.filter(inventory => {
				//     let found = resp.find(item => item === inventory.name)
				//     console.log(found)
				//     if(found){
				//         return false
				//     }
				//     else{
				//         return true
				//     }
				// })
				//     console.log(usedInventory)
				//     allInventory = [...usedInventory]
				// }
				if (parentData.setData) {
					parentData.setData({
						...parentData.data,
						campaignCreative: {
							...parentData.data.campaignCreative,
							creatives: { ...formData },
							inventoryCreativeData: [...apiSet],
							inventoryCreativeDataGenerated: apiSet.length > 0 && expand,
						},
					});
				}
			}

		
			
			 if (parentData.data.brandDetail.custom && parentData.data.brandDetail.isTrial) {
				history.push(
					"/brands/brand-details/create-trial-campaign/custom-comments"
				);
			} 
			else if (parentData.data.brandDetail.custom) {
				history.push(
					"/brands/brand-details/create-campaign/custom-comments"
				);
			} 
			else if ((parentData.data.brandDetail.inventory || parentData.data.brandDetail.eventAndOccations) && parentData.data.brandDetail.isTrial) {
				history.push(
					"/brands/brand-details/create-trial-campaign/preview"
				);
			} 
			else if (parentData.data.brandDetail.inventory || parentData.data.brandDetail.eventAndOccations) {
				history.push(
					"/brands/brand-details/create-campaign/preview"
				);
			} 
			else {
				history.push("/brands/brand-details/create-trial-campaign/preview");
			}
		} else {
		}
	};
	const onExit = async (confirm, doNotSave?) => {
		if (confirm) {
			if (doNotSave && creativeNeeded) {
				console.log("ho gaya", doNotSave, creativeNeeded);
				setConfirmDialogBtnLoading(false);
				setShowCreativeNeededPopup(false);
				setShowExitPopup(false);
				setOverlay(false);
				history.push({ pathname: "/brands/brand-details/campaigns" });
				return;
			}
			if (
				!(formData.image1.key || formData.image2.key || formData.image3.key)
			) {
				setErrorMessage(true);
				setOverlay(false);
				setShowExitPopup(false);
				return;
			}
			let apiData = formateData();
			console.log(apiData);
			setConfirmDialogBtnLoading(true);
			if (apiData["_id"]) {
				console.log(apiData);
				let resp = await context.apis[context.user.role].editCampaign(
					context.validator,
					apiData
				);
				console.log("resp---", resp);
				if (resp && resp.done) {
					setConfirmDialogBtnLoading(false);
					history.push({
						pathname: "/brands/brand-details/campaigns",
						state: { campaignCreated: true },
					});
				}
			} else {
				let resp = await context.apis[context.user.role].addCampaign(
					context.validator,
					apiData
				);
				console.log("resp---", resp);
				if (resp && resp.done) {
					setConfirmDialogBtnLoading(false);
					history.push({
						pathname: "/brands/brand-details/campaigns",
						state: { campaignCreated: true },
					});
				}
			}
		}
		setShowCreativeNeededPopup(false);
		setShowExitPopup(false);
		setOverlay(false);
	};
	const openExit = () => {
		setShowExitPopup(true);
		setOverlay(true);
	};
	const formateData = () => {
		let inventoriesIdArray = [];
		parentData.data.inventory.inventories.forEach((inventory) => {
			let attributesIdArray = [];
			inventory.placements &&
				Array.isArray(inventory.placements) &&
				inventory.placements.length > 0 &&
				inventory.placements.map((item) => {
					attributesIdArray.push(item.name);
				});
			let obj = {
				_id: inventory._id,
				name: inventory.parentName || inventory.name,
				attributes: [...attributesIdArray],
				_custom: inventory._custom || false,
			};
			inventoriesIdArray.push(obj);
		});
		let eventIdArray = [];
		parentData.data.eventAndOccation.eventAndOccations.forEach((event) => {
			let attributesIdArray = [];
			event.placements &&
				Array.isArray(event.placements) &&
				event.placements.map((item) => {
					attributesIdArray.push(item._id || item.name);
				});
			let obj = {
				_id: event._id,
				name: event.name,
				attributes: [...attributesIdArray],
			};
			eventIdArray.push(obj);
		});
		let imageArray = [];
		if (formData.image1.key) {
			imageArray.push(formData.image1.key);
		}
		if (formData.image2.key) {
			imageArray.push(formData.image2.key);
		}
		if (formData.image3.key) {
			imageArray.push(formData.image3.key);
		}
		let schoolIdArray = parentData.data.school.schools.map((school) => school);
		let obj = {
			brandId: parentData.data.brandDetail.brandId,
			name: parentData.data.brandDetail.name.trim(),
			description: parentData.data.brandDetail.description.trim(),
			objective: parentData.data.brandDetail.objective.trim(),
			startDate: parentData.data.brandDetail.startDate,
			endDate: parentData.data.brandDetail.endDate,
			locations: creativeNeeded ? [...parentData?.data?.school?.locations] : [],
			targetGender:
				parentData.data.brandDetail.targetGender === "both"
					? "co-ed"
					: parentData.data.brandDetail.targetGender,
			targetAgeGroup: [
				parentData.data.brandDetail.targetAgeGroup.min,
				parentData.data.brandDetail.targetAgeGroup.max,
			],
			studyMedium: [...parentData.data.brandDetail.studyMedium],
			states: [...parentData.data.brandDetail.state],
			cities: [...parentData.data.brandDetail.city],
			brandCategory: parentData.data.brandDetail.brandCategory._id,
			brandSubcategory: parentData.data.brandDetail.brandSubcategory.value,
			brandName: parentData.data.brandDetail.brandName,
			recommendEvents: false,
			campaignStatus: creativeNeeded ? "in-review" : "draft",
			isApproved: false,
			campaignCreatives: [...imageArray],
			budget: parentData.data.campaignDetail.totalBudget,
			isTrial: parentData.data.brandDetail.isTrial,
			wisrOffering: {
				inventory: parentData.data.brandDetail.inventory,
				eventAndOccations: parentData.data.brandDetail.eventAndOccations,
				csr: parentData.data.brandDetail.csr,
				sampling: parentData.data.brandDetail.sampling,
				custom: parentData.data.brandDetail.custom,
			},
			campaignCreationProgress: {
				progress: {
					...parentData.progress,
					campaignCreative: true,
				},
				currentPage: "creative",
			},
			campaignData: {
				inventories: [...inventoriesIdArray],
				schools: creativeNeeded ? [...schoolIdArray] : [],
				schoolListDetail: [],
				activities: [...eventIdArray],
			},
		};
		if (parentData.data.brandDetail._id) {
			obj["_id"] = parentData.data.brandDetail._id;
		}
		if (creativeNeeded) {
			delete obj.campaignData;
			if (compareObjs(formData, parentData.data.campaignCreative.creatives)) {
				obj["creativeReworkNeeded"] = true;
			} else {
				obj["creativeReworkNeeded"] = false;
			}
		}
		return obj;
	};
	const openCreativeNeededPopop = () => {
		setShowCreativeNeededPopup(true);
		setOverlay(true);
	};

	// const handleSelect = (selectedIndex, e) => {
	//     setActiveImageIndex(selectedIndex)
	// }

	const handleImageClick = (item, index) => {
		console.log("This is item ----- handleImageClick ", item);
		setModalShow(true);
		setSelectedImage(item);
		setActiveImageIndex(index);
		// setShowPopup(true);
	};

	useEffect(() => {
		if (loading == true) {
			let element = document.getElementById("dynamicInventoryName");
			const timer = setInterval(() => {
				setMuiprogress((oldProgress) => {
					let i = 0;
					oldProgress <= 10
						? inventoryList.length >= 1
							? (i = 0)
							: (i = -1)
						: oldProgress <= 20
						? inventoryList.length >= 2
							? (i = 1)
							: (i = inventoryList.length - 1)
						: oldProgress <= 30
						? inventoryList.length >= 3
							? (i = 2)
							: (i = inventoryList.length - 1)
						: oldProgress <= 40
						? inventoryList.length >= 4
							? (i = 3)
							: (i = inventoryList.length - 1)
						: oldProgress <= 50
						? inventoryList.length >= 4
							? (i = 3)
							: (i = inventoryList.length - 1)
						: oldProgress <= 60
						? inventoryList.length >= 5
							? (i = 4)
							: (i = inventoryList.length - 1)
						: oldProgress <= 70
						? inventoryList.length >= 6
							? (i = 5)
							: (i = inventoryList.length - 1)
						: oldProgress <= 80
						? inventoryList.length >= 7
							? (i = 6)
							: (i = inventoryList.length - 1)
						: oldProgress <= 90
						? inventoryList.length >= 8
							? (i = 7)
							: (i = inventoryList.length - 1)
						: oldProgress <= 100 && inventoryList.length >= 9
						? (i = 8)
						: (i = inventoryList.length - 1);
					const diff = oldProgress + 10;
					if (element && diff <= 100) {
						// element.innerText = `on ${inventoryList[Math.ceil(Math.random() * (inventoryList.length - 1))]}`
						element.innerText = `on ${inventoryList[i]}`;
					}
					return diff >= 100 ? 100 : diff;
				});
			}, 400);

			return () => {
				clearInterval(timer);
			};
		}
	}, [loading]);

	useEffect(() => {
		if (
			parentData.data.campaignCreative.inventoryCreativeDataGenerated &&
			imageUploaded
		) {
			sendAPI();
		} else if (expand && imageUploaded) {
			// if(imageUploaded){
			sendAPI();
			// }
		}
	}, [formData.image1, formData.image2, formData.image3, imageUploaded]);

	return (
		<>
			<div className={`ovelap ${overlay === true && "active"}`}></div>
			{/* <TopBar isDashboard={true} /> */}
			<div className="col-xl-9 col-lg-12 col-md-12 col-xs-12">
				<div className="rightPart noBack">
					<div className="row">
						<div className="col-xs-12 col-lg-12 col-md-12 col-xs-12">
							<BrandBackBtn />
						</div>
						<div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-20">
							<h2 className="htx5 clearfix mt-10 mb-20">
								{creativeNeeded
									? "Upload campaign creatives"
									: "Create a Campaign"}
							</h2>
						</div>
						<div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-30 creativepage">
							<h2 className="htx2 clearfix mb-10">Upload Campaign Creatives</h2>
							<p className="ptx1">
								Upload your key campaign visuals in 3 different sizes.
							</p>{" "}
							<p className="ptx1">
								These will then be sent to the schools for a review.
							</p>
						</div>
						<div className="col-xl-8 col-lg-8 col-md-12 col-xs-12 mb-20 creativepage">
							<div className="row">
								<div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 pl pr">
									{/* <h2 className="htx3 clearfix">Total No of Creatives: 3</h2> */}
								</div>
								<div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-20 pl pr">
									<div className="table-container mt-10 addAdmininput">
										<table className="table">
											<thead>
												<tr>
													<th scope="col">sr no</th>
													<th scope="col">Type of Creative</th>
													<th scope="col">Dimensions</th>
													<th scope="col">REFERENCE CREATIVE</th>
													<th scope="col">ACTIONS</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td>1</td>
													<td>1:1</td>
													<td>500px X 500px</td>
													<td>
														<a
															style={{
																cursor:
																	formData.image1 && formData.image1.image
																		? "pointer"
																		: "default",
															}}
															className="view-anchor text-decoration"
															onClick={() =>
																formData.image1 &&
																formData.image1.image &&
																openImageModal(1)
															}
														>
															View
														</a>
													</td>
													<td>
														{image1Rejected ? (
															<>
																<a className="uploadSvg">
																	<img src={blockSvg} />
																</a>
																<a
																	style={{ cursor: "pointer" }}
																	className="uploadSvg uploadBrose"
																>
																	<img src={reloadSvg} />
																	<input
																		type="file"
																		accept="image/*"
																		onChange={(e) => handleCreativeUpload(e, 1)}
																	/>
																</a>
															</>
														) : loading1 ? (
															<div
																className="image imageLoader"
																style={{ height: "20px" }}
															>
																<div className="loader-imgupload"></div>
															</div>
														) : (
															<>
																<a
																	style={{ cursor: "pointer" }}
																	className="uploadSvg uploadBrose"
																>
																	<img src={uploadSvg} />
																	{formData.image1 && formData.image1.image && (
																		<img
																			className="sucessCheck"
																			src={sucessCheck}
																		/>
																	)}{" "}
																	<input
																		type="file"
																		accept="image/*"
																		onChange={(e) => handleCreativeUpload(e, 1)}
																	/>
																</a>
															</>
														)}
													</td>
												</tr>
												<tr>
													<td>2</td>
													<td>1:2</td>
													<td>1200px X 1000 px</td>
													<td>
														<a
															style={{
																cursor:
																	formData.image2 && formData.image2.image
																		? "pointer"
																		: "default",
															}}
															className="view-anchor text-decoration"
															onClick={() =>
																formData.image2 &&
																formData.image2.image &&
																openImageModal(2)
															}
														>
															View
														</a>
													</td>
													<td>
														{image2Rejected ? (
															<>
																<a className="uploadSvg uploadBrose">
																	<img src={blockSvg} />
																</a>
																<a
																	style={{ cursor: "pointer" }}
																	className="uploadSvg"
																>
																	<img src={reloadSvg} />
																	<input
																		type="file"
																		accept="image/*"
																		onChange={(e) => handleCreativeUpload(e, 2)}
																	/>
																</a>
															</>
														) : loading2 ? (
															<div
																className="image imageLoader"
																style={{ height: "20px" }}
															>
																<div className="loader-imgupload"></div>
															</div>
														) : (
															<>
																<a
																	style={{ cursor: "pointer" }}
																	className="uploadSvg uploadBrose"
																>
																	<img src={uploadSvg} />
																	{formData.image2 && formData.image2.image && (
																		<img
																			className="sucessCheck"
																			src={sucessCheck}
																		/>
																	)}{" "}
																	<input
																		type="file"
																		accept="image/*"
																		onChange={(e) => handleCreativeUpload(e, 2)}
																	/>
																</a>
															</>
														)}
													</td>
												</tr>
												<tr>
													<td>3</td>
													<td>2:1</td>
													<td>1200px X 1200px</td>
													<td>
														<a
															style={{
																cursor:
																	formData.image3 && formData.image3.image
																		? "pointer"
																		: "default",
															}}
															className="view-anchor text-decoration"
															onClick={() =>
																formData.image3 &&
																formData.image3.image &&
																openImageModal(3)
															}
														>
															View
														</a>
													</td>
													<td>
														{image3Rejected ? (
															<>
																<a className="uploadSvg">
																	<img src={blockSvg} />
																</a>
																<a
																	style={{ cursor: "pointer" }}
																	className="uploadSvg uploadBrose"
																>
																	<img src={reloadSvg} />
																	<input
																		type="file"
																		accept="image/*"
																		onChange={(e) => handleCreativeUpload(e, 3)}
																	/>
																</a>
															</>
														) : loading3 ? (
															<div
																className="image imageLoader"
																style={{ height: "20px" }}
															>
																<div className="loader-imgupload"></div>
															</div>
														) : (
															<>
																<a
																	style={{ cursor: "pointer" }}
																	className="uploadSvg uploadBrose"
																>
																	<img src={uploadSvg} />
																	{formData.image3 && formData.image3.image && (
																		<img
																			className="sucessCheck"
																			src={sucessCheck}
																		/>
																	)}{" "}
																	<input
																		type="file"
																		accept="image/*"
																		onChange={(e) => handleCreativeUpload(e, 3)}
																	/>
																</a>
															</>
														)}
													</td>
												</tr>
											</tbody>
										</table>
										{errorMessage && (
											<span className="valError">
												<img src={errorSVG} />
												Please upload atleast one image.
											</span>
										)}
										{showImageError && (
											<span className="valError">
												<img src={errorSVG} />
												The uploaded file is not supported. Please upload image
												file.
											</span>
										)}

										{/* Creative Preview Starts  */}
										{/* <br />
                                        <br />
                                        <br />
                                        <br /> */}
										{(formData.image1.image ||
											formData.image2.image ||
											formData.image3.image) &&
											showPreview && (
												<div
													className="panel-group"
													id=""
													style={{ marginTop: "30px" }}
												>
													<div className="panel panel-default">
														<div className="panel-heading">
															{showPreview && (
																<div className="title-flex-container">
																	<div
																		className="cl-row"
																		style={{
																			display: "flex",
																			justifyContent: "space-between",
																		}}
																	>
																		<div style={{}}>
																			<h4
																				className="panel-title title-text-head"
																				data-toggle="collapse"
																				data-target="#collapseOne"
																			>
																				<strong> Creative Preview </strong>{" "}
																			</h4>
																		</div>

																		{!expand && (
																			<div
																				style={{}}
																				onClick={(e) => {
																					apiSet.length > 0
																						? setExpand(true)
																						: sendAPI(e);
																				}}
																			>
																				<i
																					style={{ cursor: "pointer" }}
																					className="fa fa-eye"
																					aria-hidden="true"
																				></i>{" "}
																				&nbsp;
																				<span style={{ cursor: "pointer" }}>
																					Generate Preview
																				</span>
																			</div>
																		)}
																		{expand && (
																			<div
																				style={{}}
																				onClick={(e) => {
																					setExpand(false);
																					e.stopPropagation();
																				}}
																			>
																				<i
																					style={{ cursor: "pointer" }}
																					className="fa fa-eye-slash"
																					aria-hidden="true"
																				></i>{" "}
																				&nbsp;
																				{/* <span style={{ cursor: "pointer" }}>GENERATE PREVIEW</span> */}
																				<span style={{ cursor: "pointer" }}>
																					{" "}
																					Hide Preview
																				</span>
																			</div>
																		)}
																	</div>
																	{/* <p style={{ color: "#8A8A8A", fontSize: "10px" }}>To generate preview you need to first upload creative</p> */}
																</div>
															)}

															<div className="cl-row ">
																<div className="fl col-sm-6 col-md-6 col-xs-4"></div>

																<div className="fl col-sm-6 col-md-6 col-xs-8"></div>
															</div>
															<br />
															<br />
															<div className="cl-row ">
																<div className="col-md-6 ">
																	<strong className=""> </strong>
																</div>
															</div>
														</div>
														{loading ? (
															<>
																<span style={{ textAlign: "center" }}>
																	<div className="row">
																		<div className="col-md-4 col-lg-4"></div>
																		<div className="col-md-4 col-lg-4">
																			<i
																				className="fa fa-file-image-o fa-3x"
																				aria-hidden="true"
																			></i>
																			<p
																				style={{
																					fontSize: "10px",
																					marginTop: "5px",
																				}}
																			>
																				{" "}
																				Placing your brand creative{" "}
																				<span id="dynamicInventoryName">
																					{" "}
																				</span>{" "}
																			</p>
																			<Box sx={{ width: "100%" }}>
																				<LinearProgress
																					color="warning"
																					variant="determinate"
																					value={muiprogress}
																					style={{ marginTop: "5px" }}
																				/>
																			</Box>
																		</div>
																		<div className="col-md-4 col-lg-4"></div>
																	</div>
																</span>
															</>
														) : (
															expand && (
																<div
																	id="collapseOne"
																	className="panel-collapse collapse"
																>
																	<div
																		className="panel-body"
																		style={{
																			border: "none",
																			backgroundColor: "",
																			marginTop: "-30px",
																		}}
																	>
																		<hr className="camp-list-hr" />
																		<div className="cl-row mb-3">
																			<div className="row">
																				{apiSet.length > 0 &&
																					apiSet.map((item, index) => (
																						<>
																							<div
																								className="col-md-3 col-lg-3 col-xs-3"
																								style={{ padding: "10px" }}
																							>
																								<div
																									style={{
																										backgroundColor: "#f5f5f5",
																										width: "120px",
																										height: "140px",
																										position: "relative",
																									}}
																								>
																									<h4
																										style={{
																											position: "absolute",
																											top: "1%",
																											left: "1%",
																											color: "white",
																											fontSize: "12px",
																											backgroundColor:
																												"rgba(0, 0, 0, 0.6)",
																											padding: "3px",
																											zIndex: "999",
																										}}
																									>
																										{" "}
																										{item.inventoryName.replace(
																											/_/g,
																											" "
																										)}{" "}
																									</h4>
																									<img
																										onClick={() =>
																											handleImageClick(
																												item,
																												index
																											)
																										}
																										src={item.image}
																										onLoad={(event) => {
																											setSize({
																												height:
																													event.currentTarget
																														.naturalHeight,
																												width:
																													event.currentTarget
																														.naturalWidth,
																											});
																										}}
																										alt=""
																										style={{
																											maxHeight: "100%",
																											maxWidth: "100%",
																											width: "auto",
																											height: "auto",
																											position: "absolute",
																											top: "0",
																											bottom: "0",
																											left: "0",
																											right: "0",
																											margin: "auto",
																										}}
																									/>
																								</div>
																							</div>
																						</>
																					))}
																			</div>
																		</div>
																	</div>
																</div>
															)
														)}
													</div>
												</div>
											)}

										{/* Creative Preview Ends  */}

										<ImageModal
											togglePopup={toggleShowImage1}
											displayImage={formData.image1 && formData.image1.image}
											onModalClose={() => confirmClose(1)}
										/>
										<ImageModal
											togglePopup={toggleShowImage2}
											displayImage={formData.image2 && formData.image2.image}
											onModalClose={() => confirmClose(2)}
										/>
										<ImageModal
											togglePopup={toggleShowImage3}
											displayImage={formData.image3 && formData.image3.image}
											onModalClose={() => confirmClose(3)}
										/>
									</div>
								</div>
								<div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 pl pr mt-20"></div>
								<div className="col-xl-5 col-lg-5 col-md-6 col-xs-12 delete-user mt-30">
									{!creativeNeeded && (
										<a
											className="viewwLink"
											style={{ cursor: "pointer" }}
											onClick={openExit}
										>
											Save & Continue Later
										</a>
									)}
								</div>
								<div className="col-xl-7 col-lg-7 col-md-6 col-xs-12 end-md mt-30">
									<div className="forminpt">
										{creativeNeeded ? (
											<input
												name="Save"
												className="btn btn-orange"
												type="button"
												value="Save"
												onClick={() =>
													!compareObjs(
														formData,
														parentData.data.campaignCreative.creatives
													)
														? openCreativeNeededPopop()
														: onExit(true, true)
												}
											/>
										) : (
											<input
												name="Save &amp; next"
												className="btn btn-orange"
												type="button"
												value="Save &amp; next"
												onClick={() => onSave(true)}
											/>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{modalShow && (
				<div id="" className="popup" style={{ backgroundColor: "#000000" }}>
					<div className="popup-header">
						<h3
							className="htx2 pophead"
							style={{ textAlign: "center", color: "#ffffff" }}
						>
							Creative Preview
						</h3>
						<span
							className="close toggle"
							onClick={() => setModalShow(false)}
							data-target="myPopup"
						>
							close
						</span>
					</div>
					<div className="popup-body" id="ct-arrow">
						<CreativeCampaignCarousel
							image={selectedImage}
							id={activeImageIndex}
							data={apiSet}
						/>
					</div>
				</div>
			)}

			<ConfirmDialog
				togglePopup={showExitPopup}
				onModalClose={onExit}
				componentName={""}
				confirmTitle="Do you want to save and exit?"
				isLoading={confirmDialogBtnLoading}
			/>
			<ConfirmDialog
				togglePopup={showCreativeNeededPopup}
				onModalClose={onExit}
				componentName={""}
				confirmTitle="Do you want to save changes?"
				isLoading={confirmDialogBtnLoading}
			/>
		</>
	);
};

export default CampaignCreative;
