import { useState, useContext, useEffect, useRef } from "react"
import { Link, useHistory, BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom"


import AppContextObject from "../../../../common/context/common"
import './style.css';
import CampaignReportingSpan from "./components/campaignReportingSpan";
import CustomSearchInput from "../../../../components/formFields/customSearchInput";
import Loader from "../../../../components/addon/loader";
import Pagination from "../../../../components/addon/pagination";
const { detect } = require('detect-browser');

var backendCampaignListGlobalVar = 1

const CampaignReportingList = ({ onlyActiveCampaign }) => {
    const context = useContext(AppContextObject)
    const history = useHistory()
    const [overlay, setOverlay] = useState(false);
    const [clearSearchIcon, setClearSearchIcon] = useState(false);
    const [searchInpuOpen, setSearchInpuOpen] = useState(false);
    const [searchKeys, setSearchKeys] = useState("");
    const [campaigns, setCamnpaigns] = useState([])
    const [totalPageCount, setTotalPageCount] = useState<any>([0]);
    const [totalPage, setTotalPage] = useState([0]);
    const [currentPage, setCurrentpage] = useState(1)
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        getCampaignList()
    }, [currentPage, searchKeys])
    useEffect(() => {
        if (currentPage !== 1) {
            setCurrentpage(1)
        }
    }, [searchKeys])


    const getCampaignList = async (_id?) => {
        setIsLoading(true);
        var localVar = Math.random()
        backendCampaignListGlobalVar = localVar
        // let resp = await context.apis.public.getCampaignsByBrandExternal(
        //     context.validator, {searchValue: searchKeys}, { limit: 10, page: currentPage, withCount: true, "sort": { "_id": -1 } }
        // )
        let resp = await context.apis[context.user.role].getAssignedCampaignsByFOS(
            context.validator, {fosId: context.user._id,searchValue: searchKeys}, { limit: 10, page: currentPage, withCount: true, "sort": { "_id": -1 } }
        )

        console.log('getAssignedCampaignsByFOS', resp)

        if (resp && resp.done) {
            if (localVar === backendCampaignListGlobalVar) {
                setCamnpaigns(resp.response.rows)
                let total = Math.ceil(resp.response.count / 10);
                setTotalPageCount(resp.response.count)
                let arr = []
                for (let i = 0; i < total; i++) {
                    arr.push(i)
                }
                setTotalPage([...arr])
            }
        }
        setIsLoading(false)
    }
    const onPageChange = (page) => {
        setCurrentpage(page)
    }
    const onNextClick = () => {
        setCurrentpage(prev => prev + 1)
    }
    const onPrevClick = () => {
        setCurrentpage(prev => prev - 1)
    }

    const handleChange = (e) => {
        if(e === ""){
            setSearchKeys("")
        }
        else{
            setSearchKeys(e.target.value)
        }
    }
    return (
        <>
            <div className="" style={{ width: "100%" }}>
                <div className="logo-header header-flex-container">
                    {/* <div className="logos"> */}
                    <img onClick={() => history.goBack()} className="back-arrow" src="/assets/FOSimg/back-arrow.png" />
                    <img className="wisrLogo" src="/assets/FOSimg/wisr-logo.png" />
                    {/* </div> */}
                    {/* <div className="logos"> */}
                    <img className="maskgrouplogo" src="/assets/FOSimg/maskgrouplogo.png" />
                    {/* </div> */}
                </div>


                <div className="ws-container">
                    {/* <Container> */}

                    {/* <div className="heading headTitleContainer" style={{paddingBottom:"10px"}}> */}
                    <div className="heading headTitleContainer">
                        <div className="headTitle headerTitleText">
                            <h2>Campaign List</h2>
                        </div>
                        <div className="headTitle searchBoxContainer">
                            <CustomSearchInput
                                searchInpuOpen={searchInpuOpen}
                                setSearchInpuOpen={setSearchInpuOpen}
                                clearSearchIcon={clearSearchIcon}
                                setClearSearchIcon={setClearSearchIcon}
                                handleChange={handleChange}
                                searchKeys={searchKeys}
                            />
                        </div>

                        {/* <div className="headTitle searchBoxContainer">
                            <div className={searchInpuOpen ? "searchBox searchinput searchinput-backendAdmin" : "searchBox searchinput searchinput-fos"}>
                                <a href="javascript:void(0);" onClick={() => setSearchInpuOpen(true)}><img src="/static/media/search.a6944e87.svg" /></a>
                                <form onSubmit={(e) => e.preventDefault()}>
                                    {searchInpuOpen}
                                    {clearSearchIcon && <button className="close-icon serachClose" type="reset" ><img src="" /></button>}
                                </form>
                            </div>
                        </div> */}
                    </div>

                    {/* CardBox starts  */}
                    {isLoading ? 
                        <Loader />
                        :
                        campaigns.length > 0 ?
                        campaigns.map((item, index) => (
                            <CampaignReportingSpan
                                item={item}
                                index={index}
                            />
                        ))
                        :
                        "No campaigns found"
                    }
                </div>
                {totalPage.length > 1 &&
                    <Pagination
                        currentPage={currentPage}
                        totalPage={totalPage}
                        onPageChange={onPageChange}
                        onNextClick={onNextClick}
                        onPrevClick={onPrevClick}
                        loading={isLoading}
                    />
                }
                {/* </Container> */}
            </div>
        </>
    )
}


export default CampaignReportingList
