// import { useState, useContext } from "react"

// import { validation } from "../../../../service/validators"

// import TextFieldWithIcon from "../../../../components/formFields/textfieldWithIcon"
// import OnboardingBackBtn from "../../schools/components/onboardingBackBtn"

// import loginWave from "../../../../assets/images/login-wave.svg"
// import envelope from "../../../../assets/images/envolpe.svg"
// import logo from "../../../../assets/images/logo.png"

// import AppContextObject from "../../../../common/context/common"
// import toast from "react-hot-toast"
// import { useHistory } from "react-router-dom"
// import username from "../../../../assets/images/user-icon.svg"

// interface Props {
//     methods?: any
// }
// const ForgotPassword = (props: Props) => {

//     const {
//         methods
//     } = props
//     const history = useHistory()
//     const context = useContext(AppContextObject)
//     let [formErrors, setFormErrors] = useState<any>({})
//     const [emailError, setEmailError] = useState(false)
//     const [data, setData] = useState({
//         username: "",
//     })

//     const onFormDataChange = (event) => {
//         setData({
//             ...data,
//             [event.target.id] : event.target.value
//         })
//     }

//     const onSubmit = async () => {
//         const errors = validation(data)
//         setFormErrors(errors)
//         if (Object.keys(errors).length > 0) {
//           return
//         }else{
//             // let resetPassResp = await context.apis.public.sendResetPasswordLinkOnEmail(
//             //         context.validator, {"email": data.email}
//             //     )
//             // if(resetPassResp && resetPassResp.done){
//             //     setEmailError(false)
//             //     toast.success("Reset password link is sent to your email address")
//             // }else{
//             //     setEmailError(true)
//             // }
//             let resp = await context.apis.public.reSendOTPOnEmail(
//                 context.validator, {"username":data.username}
//             )
//             console.log("email otp resp", resp)
//             if(resp && resp.done) {
//                 console.log("email otp resp", resp.generatedOtp)
//             history.push({
//                 pathname: '/brands/enter-otp',
//                 state: {
//                     username :data.username
//                 },
//               });
//             }

//         }
//     }

import { useState, useEffect, useContext, useRef } from "react"

import { useHistory } from "react-router-dom"

import toast from "react-hot-toast"

import { validation } from "../../../../service/validators"

import TextFieldWithIcon from "../../../../components/formFields/textfieldWithIcon"
import PasswordField from "../../../../components/formFields/passwordField"

import loginBgLeft from "../../../../assets/images/backendAdmin/login-bg.svg"
import envelope from "../../../../assets/images/envolpe.svg"
import logo from "../../../../assets/images/logo.png"
import AppContextObject from "../../../../common/context/common"
import SimpleReactValidator from "simple-react-validator"
import errorSVG from "../../../../assets/images/error.svg";
import DataContext from "../../../../service/brandContext"
import username from "../../../../assets/images/user-icon.svg"
import AlertDialog from "../../../../components/addon/alertDialog/alertDialog"
import OnboardingBackBtn from "../../schools/components/onboardingBackBtn"


interface Props {
    methods
}

const ForgotPassword = ({
    methods
}: Props) => {

    const history = useHistory()
    const context = useContext(AppContextObject)
    const parentData = useContext<any>(DataContext)
    let [formErrors, setFormErrors] = useState<any>({})
    let [loginError, setLoginError] = useState(null)
    const [update, forceUpdate] = useState(false)
    const [data, setData] = useState({
        username: ""
    })
    const [ip, setIP] = useState("192.168.56.1")
    const [formValidator] = useState(useRef(new SimpleReactValidator({
        element: (message, className) =>
            <span className="valError"><img src={errorSVG} />{message}</span>
    })))

    // const [toggleAlertPopUp, setToggleAlertPopUp] = useState(false);
    // const [detailsCaptured, setDetailsCaptured] = useState('');
    // const [getFieldInfo, setGetFieldInfo] = useState('');
    // const [overlay, setOverlay] = useState(false)
    // const [msgDetails, setMsgDetails] = useState('');
    const [usernameError, setUsernameError] = useState(false)
    // useEffect(() => {
    //     // fetchUserIp()
    // }, [])


    const fetchUserIp = () =>{
        try{
            fetch('https://geolocation-db.com/json/')
            .then(response => response.json())
            .then(async data =>{
                console.log("data>>>>>>>",data);
                setIP(data.IPv4)
            })
        }catch(e){
            console.log(e);
        }
        
    }

    const onFormDataChange = (event) => {
        setData({
            ...data,
            [event.target.id] : event.target.value
        })
        if (event.target.id === "username") {
            setUsernameError(false)
            setData({
                ...data,
                username: event.target.value.toLowerCase()
            })
        }
    }
    let error = ""
    formValidator.current.rules['user_name'] = {
        message: error === "required" ? 'The user name field is required.' : usernameError ? "The username entered does not exist. Please enter correct username." : "" ,
        rule: (val) => {
            let returnValue = true
            if (!val || val.trim() === "") {
                error = "required"
                returnValue = false
            }

            // const chars = "!#$%^&*()-{}[]+=<>?/,~'`"
            // for (let i = 0; i < val.split("").length; i++) {
            //     const v = val.split("")[i]
            //     if (!v || v.trim() === " " || chars.split("").find(ch => ch === v)) {
            //         error = ""
            //         returnValue = false
            //         break
            //     }
            // }

            if (usernameError) {
                error = ""
                returnValue = false
            }

            if (usernameError === null) {
                error = ""
                returnValue = false
            }

            if (usernameError === undefined) {
                error = ""
                returnValue = false
            }

            return returnValue
        },
    }
    const onSubmit = async () => {
        const result = formValidator.current.allValid()
        if (result === false) {
            formValidator.current.showMessages()
            forceUpdate(true)
            return
        } else {
            formValidator.current.hideMessages()
            forceUpdate(!update)
        }

        let resp = await context.apis.public.findUserByUsername(
            context.validator, { "username": data.username }
        )
        if (resp && resp.done === false) {
            let found = resp.response.filter(element => element._id !== parentData.data.userId);
            if (found.length > 0) {

            // let resetPassResp = await context.apis.public.sendResetPasswordLinkOnEmail(
            //         context.validator, {"email": data.email}
            //     )
            // if(resetPassResp && resetPassResp.done){
            //     setEmailError(false)
            //     toast.success("Reset password link is sent to your email address")
            // }else{
            //     setEmailError(true)
            // }
            let respOtp = await context.apis.public.reSendOTPOnEmail(
                context.validator, {"username":data.username}
            )
            console.log("email otp resp", respOtp)
            if(respOtp && respOtp.done) {
                console.log("email otp respOtp", respOtp.generatedOtp)
            history.push({
                pathname: '/brands/enter-otp',
                state: {
                    username :data.username
                },
              });
            }


                return
            }
            else {
                formValidator.current.showMessageFor("username")
                // setGetVerifyUsernameStatus(false)
                // setIsUsernameVerified(false)
                setUsernameError(true)
                // setGetFieldInfo("Username");
                // setDetailsCaptured(data.username)
                // setMsgDetails(`The Username- ${data.username} - entered does not exists. Click on Sign Up.`)
                // setOverlay(true)
                // setToggleAlertPopUp(true)
            }
        }
        else {
            formValidator.current.showMessageFor("username")
            // setGetVerifyUsernameStatus(false)
            // setIsUsernameVerified(false)
            setUsernameError(true)
            // setGetFieldInfo("Username");
            // setDetailsCaptured(data.username)
            // setMsgDetails(`The Username- ${data.username} - entered does not exists. Click on Sign Up.`)
            // setOverlay(true)
            // setToggleAlertPopUp(true)
            // history.push("/brands/sign-up")
        }

        // fetch("/api/login", requestOptions)
        // .then(response => response.json())
        // .then(async data => {
        //     methods.reRender()
        //     if(data.result && data.result.done){
        //         toast.success("Logged in successfully")
        //         if(data.result.role === "backendAdmin"){
        //             history.push("/schools-listing")
        //         }else if(data.result.role !== "backendAdmin" && (data.result.changePassword === true || data.result.isVerified == false)){
        //             if(data.result.isVerified == false) {
        //                 history.push("/user-verification")
        //             }
        //             else {
        //                 history.push("/reset-password")
        //             }
        //         }else{
        //             history.push("/schools/school-details/dashboard")
        //         }
        //     }else{
        //         setLoginError("Username or Password is incorrect.")
        //     }
        // }).catch(err=>{
        //     setLoginError("Username or Password is incorrect.")
        // })
    }

    return (
        <>
            <div className="ovelap"></div>
            <div className="main-wapper">
                <div className="loginbox">
                    <div className="login-wave backendlogin"> <img src={loginBgLeft} alt="login-wave" /> </div>
                    <div className="login-form backendForgot">
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb10">
                                <img src={logo} alt="logo" />
                                <OnboardingBackBtn link='/brands/login' />  
                                <h2 className="htx1">Password Recovery</h2>
                                <p className="ptx2">Enter the Email ID associated with your account.</p>
                            </div>
                        </div>
                        <form>
                            <div className="row">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                    <TextFieldWithIcon
                                        label="Email ID"
                                        placeholder="abhi@wisr.com"
                                        isRequired={true}
                                        type="text"
                                        icon={envelope}
                                        formData={data}
                                        formKey="email"
                                        onFormDataChange={onFormDataChange}
                                        formErrors={formErrors}
                                        // forgotPasswordEmailError={emailError}
                                    />
                                {/* <TextFieldWithIcon
                                        // label='Your Username'
                                        placeholder='Username'
                                        isRequired={false}
                                        icon={username}
                                        formErrors={formValidator}
                                        formData={data}
                                        formKey="username"
                                        validation="required|user_name"
                                        onFormDataChange={onFormDataChange}
                                        maxLength={16}
                                    /> */}
                                </div>
                                <div className="row col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                    <div className="forminpt">
                                        <input
                                            onClick={onSubmit}
                                            name="Login"
                                            value="Continue"
                                            className="btn btn-orange datasubmit"
                                            type="button"
                                        />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ForgotPassword
