import React, { useState, useEffect} from 'react'
import { sortMonths } from '../../../../../../service/helper'

const EventsTable = ({event, index, formData, setFormData, setOverlay, tableName}) => {
    const [placements, setPlacements] = useState([])
    const [selectedPlacements, setSelectedPlacements] = useState([])
    const [showPlacements, setShowPlacements] = useState(false)
    const [showEventVenue, setShowEventVenue] = useState(false)
    const [showEventMonths, setShowEventMonths] = useState(false)
    // useEffect(() => {
    //     setSelectedPlacements([...event.placements])
    // }, [])
    useEffect(() => {
        let found = formData.eventAndOccations.find(item => item._id === event._id)
        if(found){
            setSelectedPlacements(found.placements && found.placements.length > 0 && [...found.placements])
        }
        else{
            setSelectedPlacements([])
        }
    }, [formData.eventAndOccations])
    useEffect(() => {
        let found = formData.eventAndOccations.find(item => item._id === event._id)
        console.log("code runing", found)
        if(found){
            setSelectedPlacements(found.placements && found.placements.length > 0 && [...found.placements])
        }
        else{
            setSelectedPlacements([])
        }
    }, [])
    useEffect(() => {
        console.log("code runing", event)
        if(event.placements && Array.isArray(event.placements) && event.placements.length > 0){
            setPlacements([...event.placements])
        }
    }, [event?.placements])
    const addRemoveEvent = (e) => {
        if(e.target.checked){
            let arr = [...formData.eventAndOccations]
            arr.push(event)
            setFormData({
                ...formData,
                eventAndOccations: [...arr]
            })
            setSelectedPlacements([...event.placements])
        }
        else{
            let arr = [...formData.eventAndOccations.filter(item => item._id !== event._id)]
            setFormData({
                ...formData,
                eventAndOccations: [...arr]
            })
            setSelectedPlacements([])
        }
    }
    const addRemovePlacement = (e, placement) => {
        console.log(placement)
        let eventSelected = formData.eventAndOccations.find(item => item._id === event._id)
        if(eventSelected){
            let arr = [...selectedPlacements]
            console.log(arr)
            if(e.target.checked){
                arr.push(placement)
                setSelectedPlacements([...arr])
            }
            else{
                // if(selectedPlacements.length !== 1){
                    arr = [...selectedPlacements.filter(item => placement._id ? item._id !== placement._id : placement.name !== item.name)]
                    console.log("consolearr",arr)
                    setSelectedPlacements([...arr])
                // }
            }
            if(arr.length > 0){
                let newEvent = {...event, placements: arr}
                console.log(newEvent)
                let newArray = [...formData.eventAndOccations.filter(item => item._id !== event._id), newEvent]
                console.log(newArray)
                setFormData({
                    ...formData,
                    eventAndOccations: newArray
                })
            }
            else{
                let newArray = [...formData.eventAndOccations.filter(item => item._id !== event._id)]
                setFormData({
                    ...formData,
                    eventAndOccations: newArray
                })
            }
        }
        else{
            console.log("object")
        }
    }
    const placementSlected = (_id, name) => {
        if(selectedPlacements.length > 0 && selectedPlacements.find(item => _id ? item._id === _id : item.name === name)){
            return true
        }
        else{
            return false
        }
    }
    const eventSelected = () => {
        if(formData.eventAndOccations.find(item => item._id === event._id)){
            return true
        }
        else{
            return false
        }
    }
    const openMoreEventModal = () => {
        setShowEventVenue(true)
        setOverlay(true)
    }
    const closeMoreEventModal = () => {
        setShowEventVenue(false)
        setOverlay(false)
    }
    const closeMoreEventMonth = () => {
        setShowEventMonths(false)
        setOverlay(false)
    }
    const openEventMonthsModal = () => {
        setShowEventMonths(true)
        setOverlay(true)
    }
    return (
        <>
            <tr>
                <td>
                    <div className="checkbox fnone">
                        <input type="checkbox" id={event._id} checked={eventSelected()} onClick={addRemoveEvent}/>
                        <label htmlFor={event._id}></label>
                    </div>
                </td>
                <td>{index+1}</td>
                <td>{event.name}</td>
                <td>{event.placements && event.placements.length}</td>
                <td><span className={`other-arrow align-R fR ${showPlacements && "other-downarrow"}`} onClick={() => setShowPlacements(!showPlacements)}><svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M11 1L6 6L1 1" stroke="#8C8C8C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg></span></td>
            </tr>
            {showPlacements && 
                <tr>
                    <td colSpan={5} className="pad-border">
                        <div className="available-placements">
                            <h4 className="htx3">Available Inventory Placements</h4>
                            {/* {event.venue && Array.isArray(event.venue) ?
                                <div className='w50'>
                                    {event.venue && event.venue.length > 0 && event.venue.map((item, index) => (
                                        index > 1 ? "" : <span key={index}>{event.venue.length - 1 === index ? item : `${item}, `}</span>
                                    ))}
                                    {event.venue && event.venue.length > 2 && <a className='achorCss' style={{cursor: "pointer"}} onClick={openMoreEventModal}> See more</a>}
                                </div>
                                :
                                event.venue &&
                                <div className='w50'>
                                    <span>{event.venue}</span>
                                </div>
                            } */}
                            {event.months && Array.isArray(event.months) ?
                                <div className='w50 mb-10'>
                                    {event.months && event.months.length > 0 && sortMonths(event.months).map((item, index) => (
                                        index > 3 ? "" : <span key={index}>{event.months.length - 1 === index ? item : `${item}, `}</span>
                                    ))}
                                    {event.months && event.months.length > 4 && <a style={{cursor: "pointer"}} onClick={openEventMonthsModal}> See more</a>}
                                </div>
                                :
                                event.months &&
                                <div className='w50 mb-10'>
                                    <span>{event.months}</span>
                                </div>
                            }
                            {/* <div id="school-events-popup" className={`popup ${showEventVenue === false ? "hide" : ""}`}>
                                <div className="popup-header">
                                    <h3 className="htx2 pophead">Event's Venue</h3>
                                    <span className="close toggle" onClick={closeMoreEventModal} data-target="myPopup">close</span> 
                                </div>
                                <div className="popup-body">
                                    <div className="searchlist cale-popup-tue">
                                        <h3>Events</h3>
                                        <ul>
                                            {event.venue && Array.isArray(event.venue) && event.venue.length !== 0 && event.venue.map((item, index) => (
                                                <li><a href="javascript:void(0);"><span></span>{item}</a></li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div> */}
                            <div id="school-events-popup" className={`popup ${showEventMonths === false ? "hide" : ""}`}>
                                <div className="popup-header">
                                    <h3 className="htx2 pophead">Event's Months</h3>
                                    <span className="close toggle" onClick={closeMoreEventMonth} data-target="myPopup">close</span> 
                                </div>
                                <div className="popup-body">
                                    <div className="searchlist cale-popup-tue">
                                        <h3>Events</h3>
                                        <ul>
                                            {event.months && Array.isArray(event.months) && event.months.length !== 0 && sortMonths(event.months).map((item, index) => (
                                                <li><a href="javascript:void(0);"><span></span>{item}</a></li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col"></th>
                                        <th scope="col">inventory name</th>
                                        {/* <th scope="col">inventory impressions</th>
                                        <th scope="col">inventory Quantity</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {placements.length > 0 && placements.map((placement, index1) => (
                                        <tr key={index1}>
                                            <td>
                                                <div className={`checkbox fnone ${!(formData.eventAndOccations.length > 0 && formData.eventAndOccations.find(item => item._id === event._id)) && "disabled-grey"}`}>
                                                    <input type="checkbox" id={placement._id || `${tableName}${index1}${index}${placement.name}`} checked={placementSlected(placement._id, placement.name)} onClick={(e) => addRemovePlacement(e, placement)} disabled={!(formData.eventAndOccations.length > 0 && formData.eventAndOccations.find(item => item._id === event._id))} />
                                                    <label htmlFor={placement._id || `${tableName}${index1}${index}${placement.name}`}></label>
                                                </div>
                                            </td>
                                            <td>{placement.name}</td>
                                            {/* <td>-</td>
                                            <td>-</td> */}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </td>
                </tr>
            }
        </>
    )
}

export default EventsTable
