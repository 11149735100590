import React, {useState} from 'react';
// import ImagePreview from '../../../../components/addon/imagePreview';
import CampaignExamplePreviewImg from './campaignExamplePreviewImg';


const CampaignExample = () => {

    const [showImage, setShowImage] =  useState(true);
    const [images, setImages] = useState([{ image: 'https://picsum.photos/200/300' }, { image: 'https://wisr-json.s3.ap-south-1.amazonaws.com/wisr-inventoryImages/Classroom+Boards.jpg' }]);
    const [activeIndex, setActiveIndex] = useState(0);

  return (
    <div>
        
        <CampaignExamplePreviewImg
         setShowImagePreview = {setShowImage}
         showImagePreview = {showImage}
         images = {images}
         selectedImageIndex = {activeIndex}
         />
    </div>
  )
}

export default CampaignExample