import { useState, useContext, useEffect } from "react"

import { useHistory } from "react-router-dom"

import search from "../../../assets/images/search.svg"
import arrowUpDown from "../../../assets/images/backendAdmin/arrow-up-down.svg"
import riarrownext from "../../../assets/images/right-arrow-next.svg"
import imgViewTwo from "../../../../assets/images/backendAdmin/img-view-two.jpg"
import tabImgInventory from "../../../assets/images/tab-img-inventory.png"
import tableFilter from "../../../../assets/images/table-filter.svg"
import arrowUp from "../../../assets/images/arrow-up.svg"
import filterSvg from "../../../assets/images/filter-svg.svg"
import filterArrowSvg from "../../../assets/images/filter-arrow-svg.svg"

import tabSlide1 from "../../../assets/images/tab-Slide1.png"
import TopBar from "../../schools/components/topBar"
import AppContextObject from "../../../../common/context/common"

import OnboardingBackBtn  from "../../schools/components/onboardingBackBtn"
import InventoryCard from "../../schools/updateDetails/inventory/inventoryCard"
import Pagination from "../../../../components/addon/pagination"
import CampaignApprovalModal from "../../../../components/addon/campaignApprovalModal"
import ThankyouModal from "../../../../components/addon/thankyouModal"
import RejectBrandRequestModal from "../../../../components/addon/rejectBrandRequestModal"

import DataContext from "../../../../service/contextProvider"

import { PublicContextInitiator } from "../../../apis/publicContextInitiator"

import { Steps } from "intro.js-react"
import { formatDateToDDMMMYY, isObjectEmpty } from "../../../../service/helper"

import toast from "react-hot-toast"
import BackendBackBtn from "../components/backendBackBtn"

const CampaignsRequest = () => {
    const parentData = useContext<any>(DataContext)
    const history = useHistory()
    const context = useContext(AppContextObject)
    const publicContextInitiator = new PublicContextInitiator(context)

    const [showModalApproveActive, setshowModalApproveActive] = useState(false)
    const [showThankyouModal, setShowThankyouModal] = useState(false)
    const [thankyouTitle, setThankyouTitle] = useState("")
    const [showConfirmRejectModal, setShowConfirmRejectModal] = useState(false)
    const [thankyouDescription, setThankyouDescription] = useState("")

    const [inventoryList, setInventoryList] = useState([])
    const [searchValue, setSearchValue] = useState("")
    const [sortingValue, setSortingValue] = useState(true)
    const [refetch, setRefetch] = useState(false)
    const [paginationCount, setPaginationCount] = useState([])
    const [activeStatusName, setActiveStatusName] = useState("")
    const [pageLimit, setPageLimit] = useState(10)
    const [showModal, setShowModal] = useState(false)
    const [showModalapproveActive, setshowModalapproveActive] = useState(false)
    const [overlay, setOverlay] = useState(false)
    const [openFisrtToBeAdded, setOpenFisrtToBeAdded] = useState(false)
    const [countOfStatus, setCountOfStatus] = useState({
        all: 0,
        occupied: 0, 
        available: 0,
        toBeAdded: 0,
        event: 0
    })

    const [brandRequest, setBrandRequest] = useState <any>({})

    const [activeCard, setActiveCard] = useState<any>("")
    const [currentPage, setCurrentPage] = useState(1)
    const [fetchAllCounts, setFetchAllCounts] = useState(false)
    const [requestedInventoryName, setRequestedInventoryName] = useState("")
    const [isInventoryListLoading, setIsInventoryListLoading] = useState(false)
    const [toolTip1Completed, setToolTip1Completed] = useState(false)
    const [addButtonToolTip, setAddButtonToolTip] = useState(false)
    const [onBoarding, setOnboarding] = useState({
        stepsEnabled: false,
        initialStep: 0,
        steps: context.user.role === "schoolPrincipal" ? [
            {element: ".tabBorderColor1", intro: "Click on this card to get detailed information about the top performing inventory.", position: "right"},
            {element: ".tabBorderColor2", intro: "Get trends about the earned revenue and where the income is coming from.", position: "right"},
            {element: ".tabBorderColor3", intro: "Get quick access to see the upcoming campaigns here.", position: "left"}
        ] : [
            {element: "#addInventoryBtn", intro: "If you have more inventory to offer, click here to add more inventory.", position: "left"}
        ],
        options: {
            exitOnOverlayClick: false,
            showStepNumbers: true,
            hidePrev: true,
            hideNext: false,
            nextLabel: "Next",
            prevLabel: "Prev",
            doneLabel: "Got it",
            autoPosition: false,
            exitOnEsc: false,
            scrollToElement: false,
            disableInteraction: true
        },
    })
    // useEffect(() => {
    //     let steps = []
    //     if(context.user.role === "schoolPrincipal"){
    //         steps = [
    //         ]
    //     }
    //     else{
    //         steps = []
    //     }
    //     setOnboarding({...onBoarding, steps: [...steps]})
    // }, [])

    // useEffect(() => {
    //     getCampaignData()
    //     setActiveStatusName("")
    // }, [])

    // useEffect(() => {
    //     if(toolTipLoaded && inventoryList.length > 0){
    //         setTimeout(() => {
    //             setOnboarding({...onBoarding, stepsEnabled: parentData?.toolTipProgress?.inventory})
    //         }, 100)
    //     }
    // }, [toolTipLoaded, inventoryList])

    // useEffect(() => {
    //     getInventoryList(true);
    //     // getEventsList("");

    // }, [refetch])

    // useEffect(() => {
    //     if(activeStatusName === "Not Added"){
    //         getToBeAddedInventoryList("Not Added")
    //     }else if(activeStatusName === "Events"){
    //         getEventsList("");
    //     }else{
    //         getInventoryList(false);
    //     }
    // }, [currentPage])

    // const openModelApproveActive = async(inventoryData) =>{
    //     setRequestedInventoryName(inventoryData.name)
    //     let resp = await context.apis[context.user.role].createUserNotification(
    //         context.validator, { 
    //             message: 'New Inventory Add Request',
    //             meta: inventoryData,
    //             role: "schoolAdmin",
    //             seen: false,
    //             notificationType: 'newInventoryAddRequest', 
    //         }
    //     )

    //     if(resp && resp.done){
    //         setshowModalapproveActive(true)
    //         setOverlay(true)
    //     }
    // }
    // const closeWithoutSaving = () => {
    //     setshowModalapproveActive(false)
    //     setOverlay(false)
    // }

    // const updatePaginationCount = (count) => {
    //     setPaginationCount([])
    //     let paginationsList = count / pageLimit 
    //     for (let i = 0; i < paginationsList; i++) {
    //         setPaginationCount(prev=>[
    //             ...prev,
    //             i
    //         ])
    //     }
    // }

    // const getEventsList = async(status) =>{
    //     setIsInventoryListLoading(true)
    //     let resp = await context.apis[context.user.role].getActivitiesForTable(
    //         context.validator, {}, {page: currentPage, limit: pageLimit, withCount: true}
    //         )
    //     setActiveStatusName("Events")
    //     if(resp && resp.done){
    //         setIsInventoryListLoading(false)
    //         setInventoryList([])
    //         resp.response.rows.map(evt=>{
    //             setInventoryList(prev=>[
    //                 ...prev,
    //                 evt
    //             ])
    //         })
    //         setCountOfStatus({
    //             ...countOfStatus,
    //             event: resp.response.count
    //         })
    //         updatePaginationCount(resp.response.count)
    //     }
    // }
    // const getCampaignData = async () => {
    //     let resp = await publicContextInitiator.getCampaignsBySchool(
    //         context.validator, { schoolId: context.user.school, schoolStatus: "pending", isUpcoming: true }, {}
    //     )
    //     if (resp && resp.done) {
    //         if (resp?.response?.allcampaigns && resp.response.allcampaigns.length > 0) {
    //             setBrandRequest(resp.response.allcampaigns[0])
    //         } else {
    //             setBrandRequest({})
    //             // setActiveCard(0)
    //         }
    //     }
    // }    
    // const getInventoryList = async(reCount) =>{
    //     setIsInventoryListLoading(true)
    //     let resp = await context.apis[context.user.role].getDetailsForSchoolDetailsTable(
    //         context.validator, {school: context.user.school, status:""}, {page: currentPage, limit: pageLimit, withCount: true}
    //     )
    //     if(resp && resp.done){
    //         setIsInventoryListLoading(false)
    //         // if(parentData?.toolTipProgress?.inventory){
    //         //     setInventoryList([])
    //         // }
    //         setInventoryList([]);
    //         resp.response.rows.map(am=>{
    //             setInventoryList(prev=>[
    //                 ...prev,
    //                 am
    //             ])
    //         })
    //         let statusAvailable = resp.response.rows.filter(am=>am.status === "Available")
    //         let statusOccupied = resp.response.rows.filter(am=>am.status === "Occupied")
    //         let statusNotAdded = resp.response.rows.filter(am=>am.status === "Not Added")
    //         if(statusNotAdded.length > 0){
    //             setAddButtonToolTip(true)
    //         }
    //         let eventsCount = 0;
    //         let toBeAddedCount = 0;
    //         if(fetchAllCounts === false){
    //             let toBeAddedResp = await context.apis[context.user.role].getSchoolInventoriesToBeAdded(
    //                 context.validator, {statusDiff: "fetch"}, {page: currentPage, limit: pageLimit, withCount: true}
    //             )

    //             let EventsCountResp = await context.apis[context.user.role].getActivitiesForTable(
    //                 context.validator, {}, {page: currentPage, limit: pageLimit, withCount: true}
    //             )

    //             toBeAddedCount = toBeAddedResp.done && toBeAddedResp.response.count
    //             eventsCount = EventsCountResp.done && EventsCountResp.response.count
    //             setFetchAllCounts(true)
    //         }
    //         if(reCount){
    //             setCountOfStatus({
    //                 ...countOfStatus,
    //                 all: resp.response.count,
    //                 available: statusAvailable.length,
    //                 occupied: statusOccupied.length,
    //                 toBeAdded: toBeAddedCount,
    //                 event: eventsCount
    //             })
    //         }
    //         console.log("Total record------------", resp.response.count)
    //         updatePaginationCount(resp.response.count)
    //     }
        
    // }
    // const getToBeAddedInventoryList = async (status) => {
    //     setSearchValue("")
    //     setIsInventoryListLoading(true)
    //     setInventoryList([]);
    //     setActiveStatusName(status)
    //     let resp = await context.apis[context.user.role].getSchoolInventoriesToBeAdded(
    //         context.validator, {statusDiff: "fetch"}, {page: currentPage, limit: pageLimit, withCount: true}
    //     )
    //     if(resp && resp.done){
    //         setIsInventoryListLoading(false)
    //         resp.response.rows.map(am=>{
    //             setInventoryList(prev=>[
    //                 ...prev,
    //                 am
    //             ])
    //             setCountOfStatus({
    //                 ...countOfStatus,
    //                 toBeAdded: resp.response.count
    //             })
    //             updatePaginationCount(resp.response.count)
    //         })
    //     }
    // }
    // const sortTable = async (columnName) => {
    //     let sortObject={
    //         sort: {}
    //     }
    //     sortObject.sort[columnName] = sortingValue ? 1 : -1;
    //     setSortingValue(!sortingValue)
    //     setIsInventoryListLoading(true)
    //     if(activeStatusName === "Not Added"){
    //         let resp = await context.apis[context.user.role].getSchoolInventoriesToBeAdded(
    //             context.validator, {statusDiff: "sort"}, {page: currentPage, limit: pageLimit, withCount: true, ...sortObject}
    //         )
    //         if(resp && resp.done){
    //             setIsInventoryListLoading(false)
    //             setInventoryList([]);
    //             resp.response.rows.map(am=>{
    //                 setInventoryList(prev=>[
    //                     ...prev,
    //                     am
    //                 ])
    //                 updatePaginationCount(resp.response.count)
    //             })
    //         }
    //     }else if(activeStatusName === "Events"){
    //         let resp = await context.apis[context.user.role].getActivitiesForTable(
    //             context.validator, {}, {page: currentPage, limit: pageLimit, withCount: true, ...sortObject})
    //         setActiveStatusName("Events")
    //         if(resp && resp.done){
    //             setIsInventoryListLoading(false)
    //             setInventoryList([])
    //             resp.response.rows.map(evt=>{
    //                 setInventoryList(prev=>[
    //                     ...prev,
    //                     evt
    //                 ])
    //             })
    //             updatePaginationCount(resp.response.count)
    //         }
    //     }else{
    //         let resp = await context.apis[context.user.role].getDetailsForSchoolDetailsTable(
    //             context.validator, {school: context.user.school, status: activeStatusName}, {page: currentPage, limit: pageLimit, withCount: true, ...sortObject}
    //             )
    //         // -1 to desc and 1 asc sorting order
    //         if(resp && resp.done){
    //             setIsInventoryListLoading(false)
    //             setInventoryList([]);
    //             resp.response.rows.map(am=>{
    //                 setInventoryList(prev=>[
    //                     ...prev,
    //                     am
    //                 ])
    //             })
    //             updatePaginationCount(resp.response.count)
    //         }
    //     }
       
    // }

    // const handleSearch = async (e) =>{
    //     setIsInventoryListLoading(true)
    //     setSearchValue(e.target.value)
    //     if(activeStatusName === "Events"){
    //         let resp = await context.apis[context.user.role].getActivitiesForTable(
    //             context.validator, {searchValue: e.target.value}, {page: currentPage, limit: pageLimit, withCount: true}
    //             )
    //         setActiveStatusName("Events")
    //         setInventoryList([])
    //         if(resp && resp.done){
    //             setIsInventoryListLoading(false)
    //             resp.response.rows.map(evt=>{
    //                 setInventoryList(prev=>[
    //                     ...prev,
    //                     evt
    //                 ])
    //             })
    //             updatePaginationCount(resp.response.count)
    //             setCurrentPage(1)
    //         }
    //     }else{
    //         let resp = await context.apis[context.user.role].getDetailsForSchoolDetailsTable(
    //             context.validator, {school: context.user.school, searchValue: e.target.value, status: activeStatusName}, {page: currentPage, limit: pageLimit, withCount: true}
    //             )
    //         if(resp && resp.done){
    //             setIsInventoryListLoading(false)
    //             setInventoryList([]);
    //             resp.response.rows.map(am=>{
    //                 setInventoryList(prev=>[
    //                     ...prev,
    //                     am
    //                 ])
    //             })
    //             setCurrentPage(1)
    //             updatePaginationCount(resp.response.count)
    //         }
    //     }
           
    // }

    // const updateInventoryStatus = async (inventoryId) => {
        
    //     let resp = await context.apis[context.user.role].updateInventoriesById(
    //         context.validator, {_id: inventoryId}, {status: "Available"}
    //         )
    //     if(resp && resp.done){
    //         setRefetch(!refetch)
    //     }
    // }

    // const changeInventoryListByStatus = async (status) => {
    //     setSearchValue("")
    //     setIsInventoryListLoading(true)
    //     setInventoryList([]);
    //     setCurrentPage(1)
    //     setActiveStatusName(status)
    //     let resp = await context.apis[context.user.role].getDetailsForSchoolDetailsTable(
    //         context.validator, {school: context.user.school, status: status}, {page: currentPage, limit: pageLimit, withCount: true}
    //         )
    //     if(resp && resp.done && resp.response.rows){
    //         setIsInventoryListLoading(false)
    //         resp.response.rows.map(am=>{
    //             setInventoryList(prev=>[
    //                 ...prev,
    //                 am
    //             ])
    //         })
    //         updatePaginationCount(resp.response.count)
    //     }
    // }

    // const addNewInventory = () => {
    //     history.push("/schools/school-details/add-invetory/remaining-invetories")
    // }

    // const fetchPageInventory = (pageNumber) => {
    //    setCurrentPage(pageNumber)
    // }

    // const updateUsersToolTipProgress = async () => {
    //     let userToolTipResp = await context.apis[context.user.role].updateUsersToolTipProgress(
    //         context.validator, {}, {toolTipProgress: {...parentData.toolTipProgress, inventory: false}}
    //     )
    // }

    // const hanldeOnboardingComplete = () => {
    //     if(context.user.role === "schoolPrincipal"){
    //         setToolTip1Completed(true)
    //         setOnboarding({...onBoarding, stepsEnabled: false})
    //     }
    //     else{
    //         updateUsersToolTipProgress()
    //         parentData.setToolTipProgress({
    //             ...parentData.toolTipProgress,
    //             inventory: false
    //         })
    //     }
    // }
    // const exitOnBoarding = (e) => {
    //     console.log(e)
    // }

    // const onCardLinkClick = (index) => {
    //     setActiveCard(index)
    // }

    // const rejectBrandRequest = () => {
    //     setOverlay(true)
    //     setShowConfirmRejectModal(true)
    // }

    // const approveBrandRequest = () => {
    //     setshowModalApproveActive(true)
    //     setOverlay(true)        
    // }

    // const viewBrandDetails = () => {
    //     setOverlay(false)
    //     setshowModalApproveActive(false)
    // }

    // const approveRequest = async () => {
    //     setshowModalApproveActive(false)
    //     const json = {
    //         data: {
    //             data: {
    //                 schoolStatus: "accepted",
    //             },
    //             args: {
    //                 campaignId: brandRequest._id,
    //             }
    //         }
    //     }
    //     const result = await context.apis[context.user.role].acceptCampaignRequest(context.validator, json.data.args, json.data.data)
    //     if (result && result?.done === true) {
    //         setOverlay(true)
    //         setThankyouTitle("Campaign Approval!")
    //         setThankyouDescription("Our brand champions have approved the content and with your final approval, we will go live with the compaign in 48 hours.")
    //         setShowThankyouModal(true)
    //         getCampaignData()
    //     } else {
    //         toast.error("Something went wrong")
    //     }
    // }

    // const onRejectRequest = async (reason) => {
    //     const json = {
    //         data: {
    //             data: {
    //                 schoolStatus: "rejected",
    //                 rejectionMessage: reason
    //             },
    //             args: {
    //                 campaignId: brandRequest._id,
    //             }
    //         }
    //     }
    //     const result = await context.apis[context.user.role].rejectCampaignRequest(context.validator, json.data.args, json.data.data)
    //     if (result && result?.done === true) {
    //         setThankyouTitle("Thank You!")
    //         setThankyouDescription("We have registered your concern and a representative from our team will connect with you within 24 hours to discuss this further.")
    //         setShowThankyouModal(true)
    //         setOverlay(true)
    //         getCampaignData()
    //     } else {
    //         toast.error("Something went wrong")
    //     }
    //     setShowConfirmRejectModal(false)
    // }

    // const closeThankyouModal = () => {
    //     setShowThankyouModal(false)
    //     setOverlay(false)
    //     setThankyouTitle("")
    //     setThankyouDescription("")
    // }

    // const closeApprovalModal = () => {
    //     setOverlay(false)
    //     setshowModalApproveActive(false)
    // }

    // const closeConfirmRejectModal = () => {
    //     setShowConfirmRejectModal(false)
    // }

    // const onNextClick = () => {
    //     setCurrentPage(currentPage + 1)
    // }

    // const onPrevClick = () => {
    //     setCurrentPage(currentPage - 1)
    // }
    // const resetSearchValue = () => {
    //     setSearchValue("")
    // }

    return (
        <>
            <div className={`ovelap ${overlay === true ? "active" : ""}`}></div>
            <div className="col-xl-10 col-lg-12 col-md-12 col-xs-12">
                <div className="rightPart noBack">
                <div className="row">
                <div className="col-xs-12 col-lg-12 col-md-12 col-xs-12">
                        <BackendBackBtn
                            link="/"
                            text="Log Out"
                        />
                    </div>
                    <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-10">
                        <a className="backbtn dblock" href="javascript:void(0);"><img src="/static/media/back-arrow.7d7b4362.svg" /> Campaign Requests</a>
                    </div>
                    
                    <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                        <div className="inventory-tableTabs">
                            <div className="inventorySearch schoolFilter inventoryBackAdmin mb-20">
                                <a href="javascript:void(0)" className="btn">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"> <circle cx="8.58478" cy="8.58478" r="7.58478" stroke="#070707" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M13.8599 14.254L16.8335 17.22" stroke="#070707" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                                </a>
                                <select><option>This Month</option></select>
                               
                            </div>
                            <div className="table-container table-inventory mt-10">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>SL No</th>
                                            <th>Campaign Name</th>
                                            <th>school</th>
                                            <th>inventories</th>
                                            <th>schedule</th>
                                            <th>Budget</th>
                                            <th>Status</th>
                                            <th>PAYMENT Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>Nike Product Launch</td>
                                                <td>24</td>
                                                <td>12</td>
                                                <td>01 Feb, 21 - 21 Mar, 21</td>
                                                <td>₹ 7,50,000</td>
                                                <td><span className="table-status orangebg"> In-Review</span></td>
                                                <td><span className="table-status orangebg">Pending</span> 
                                                    <svg className="down-arrow requestArrow" width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M1 6L6 0.999999L11 6" stroke="#8C8C8C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                                                    <img className="updownarrow" src="/static/media/arrow-up-down.3516c17e.svg" />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="campaignRequestOepn" colSpan={9}>
                                                    <table className="table">
                                                    <tbody>
                                                    <td>
                                                        <div className="campaignRequestSlide">
                                                            <div className="campaignRequestSlideLarge">
                                                                <img src={imgViewTwo} />
                                                            </div>
                                                            <div className="campaignRequestSlideSmall">
                                                                <img src={imgViewTwo} />
                                                                <img src={imgViewTwo} />
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <h4>Campaign Description</h4>
                                                        <p>The campaign is scheduled to launch the new Nike sport shoes for 2021. Activities during P.E. classes to be conducted by Nike Sports Professionals. Activities like Cricket, Football, Basketball to be conducted by Nike Professionals to train the school students.</p>
                                                    </td>
                                                    <td style={{verticalAlign: "bottom", width: "340px"}}>
                                                        <a href="javascript:void(0)" className="btn btn-outline">reject</a>
                                                        <a href="javascript:void(0)" className="btn btn-orange">APPROVE</a>
                                                    </td>
                                                    </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>Addidas Product Launch</td>
                                                <td>4</td>
                                                <td>2</td>
                                                <td>01 Feb, 21 - 21 Mar, 21</td>
                                                <td>₹ 6,50,000</td>
                                                <td><span className="table-status orangebg"> In-Review</span></td>
                                                <td><span className="table-status orangebg">Pending</span> 
                                                    <svg className="down-arrow requestArrow" width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M1 6L6 0.999999L11 6" stroke="#8C8C8C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                                                    <img className="updownarrow" src="/static/media/arrow-up-down.3516c17e.svg" />
                                                     </td>
                                            </tr>
                                            <tr>
                                                <td>3</td>
                                                <td>Nike Product Launch</td>
                                                <td>24</td>
                                                <td>12</td>
                                                <td>01 Feb, 21 - 21 Mar, 21</td>
                                                <td>₹ 7,50,000</td>
                                                <td><span className="table-status orangebg"> In-Review</span></td>
                                                <td><span className="table-status orangebg">Pending</span> 
                                                    <svg className="down-arrow requestArrow" width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M1 6L6 0.999999L11 6" stroke="#8C8C8C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                                                    <img className="updownarrow" src="/static/media/arrow-up-down.3516c17e.svg" />
                                                     </td>
                                            </tr>
                                            <tr>
                                                <td>4</td>
                                                <td>Nike Product Launch</td>
                                                <td>24</td>
                                                <td>12</td>
                                                <td>01 Feb, 21 - 21 Mar, 21</td>
                                                <td>₹ 7,50,000</td>
                                                <td><span className="table-status orangebg"> In-Review</span></td>
                                                <td><span className="table-status orangebg">Pending</span> 
                                                    <svg className="down-arrow requestArrow" width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M1 6L6 0.999999L11 6" stroke="#8C8C8C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                                                    <img className="updownarrow" src="/static/media/arrow-up-down.3516c17e.svg" />
                                                     </td>
                                            </tr>
                                            <tr>
                                                <td>5</td>
                                                <td>Nike Product Launch</td>
                                                <td>24</td>
                                                <td>12</td>
                                                <td>01 Feb, 21 - 21 Mar, 21</td>
                                                <td>₹ 7,50,000</td>
                                                <td><span className="table-status orangebg"> In-Review</span></td>
                                                <td><span className="table-status orangebg">Pending</span> 
                                                    <svg className="down-arrow requestArrow" width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M1 6L6 0.999999L11 6" stroke="#8C8C8C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                                                    <img className="updownarrow" src="/static/media/arrow-up-down.3516c17e.svg" />
                                                     </td>
                                            </tr>
                                            <tr>
                                                <td>6</td>
                                                <td>Nike Product Launch</td>
                                                <td>24</td>
                                                <td>12</td>
                                                <td>01 Feb, 21 - 21 Mar, 21</td>
                                                <td>₹ 7,50,000</td>
                                                <td><span className="table-status orangebg"> In-Review</span></td>
                                                <td><span className="table-status orangebg">Pending</span> 
                                                    <svg className="down-arrow requestArrow" width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M1 6L6 0.999999L11 6" stroke="#8C8C8C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                                                    <img className="updownarrow" src="/static/media/arrow-up-down.3516c17e.svg" />
                                                     </td>
                                            </tr>
                                            <tr>
                                                <td>7</td>
                                                <td>Addidas Product Launch</td>
                                                <td>4</td>
                                                <td>2</td>
                                                <td>01 Feb, 21 - 21 Mar, 21</td>
                                                <td>₹ 6,50,000</td>
                                                <td><span className="table-status orangebg"> In-Review</span></td>
                                                <td><span className="table-status greenbg">Paid</span>
                                                    <svg className="down-arrow requestArrow" width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M1 6L6 0.999999L11 6" stroke="#8C8C8C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                                                    <img className="updownarrow" src="/static/media/arrow-up-down.3516c17e.svg" />
                                                     </td>
                                            </tr>
                                            <tr>
                                                <td>8</td>
                                                <td>Nike Product Launch</td>
                                                <td>24</td>
                                                <td>12</td>
                                                <td>01 Feb, 21 - 21 Mar, 21</td>
                                                <td>₹ 7,50,000</td>
                                                <td><span className="table-status orangebg"> In-Review</span></td>
                                                <td><span className="table-status greenbg">Paid</span> 
                                                    <svg className="down-arrow requestArrow" width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M1 6L6 0.999999L11 6" stroke="#8C8C8C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                                                    <img className="updownarrow" src="/static/media/arrow-up-down.3516c17e.svg" />
                                                     </td>
                                            </tr>
                                            <tr>
                                                <td>9</td>
                                                <td>Nike Product Launch</td>
                                                <td>24</td>
                                                <td>12</td>
                                                <td>01 Feb, 21 - 21 Mar, 21</td>
                                                <td>₹ 7,50,000</td>
                                                <td><span className="table-status orangebg"> In-Review</span></td>
                                                <td><span className="table-status greenbg">Paid</span>
                                                    <svg className="down-arrow requestArrow" width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M1 6L6 0.999999L11 6" stroke="#8C8C8C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                                                    <img className="updownarrow" src="/static/media/arrow-up-down.3516c17e.svg" />
                                                     </td>
                                            </tr>
                                            <tr>
                                                <td>10</td>
                                                <td>Nike Product Launch</td>
                                                <td>24</td>
                                                <td>12</td>
                                                <td>01 Feb, 21 - 21 Mar, 21</td>
                                                <td>₹ 7,50,000</td>
                                                <td><span className="table-status orangebg"> In-Review</span></td>
                                                <td><span className="table-status greenbg">Paid</span> 
                                                    <svg className="down-arrow requestArrow" width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M1 6L6 0.999999L11 6" stroke="#8C8C8C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                                                    <img className="updownarrow" src="/static/media/arrow-up-down.3516c17e.svg" />
                                                     </td>
                                            </tr>
                                            
                                    </tbody>
                                </table>
                            </div>
                        </div>  
                    </div>
                </div>
                </div>
            </div>      
            <div id="approveActive" className={`popup popup-center ${showModalapproveActive === false ? "hide" : ""}`}>
            <div className="popup-header">
                <h3 className="htx2 pophead">Request Sent Successfully!</h3>
                <span className="close toggle" data-target="myPopup">close</span> </div>
                <div className="popup-body">
                    <div className="viewdetailsHead">
                        <div className="approveActive">
                            <p>A request to add {requestedInventoryName} has been sent to your school's admin. </p>
                            <div className="brand-btn">
                                <a href="javascript:void(0)" className="btn btn-orange">DONE</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <Steps
                enabled={onBoarding.stepsEnabled}
                steps={onBoarding.steps}
                initialStep={onBoarding.initialStep}
                onComplete={hanldeOnboardingComplete}
                onExit={exitOnBoarding}
                options={onBoarding.options}
            /> */}

            <CampaignApprovalModal
                showModalApproveActive={showModalApproveActive}
                // closeWithoutSaving={closeApprovalModal}
                // viewDetails={viewBrandDetails}
                // approveRequest={approveRequest}
            />

            <ThankyouModal
                showThankyouModal={showThankyouModal}
                // closeModal={closeThankyouModal}
                modalTitle={thankyouTitle}
                description={thankyouDescription}
            />

            <RejectBrandRequestModal
                showConfirmRejectModal={showConfirmRejectModal}
                // closeModal={closeConfirmRejectModal}
                // onRejectRequest={onRejectRequest}
            />

        </>
    )
}


export default CampaignsRequest
