
const CampaignApprovalModal = (props) => {

    const {
        showModalApproveActive,
        closeWithoutSaving,
        viewDetails,
        approveRequest
    } = props

    return (
        <div id="approveActive" className={`popup ${showModalApproveActive === false ? "hide" : ""}`}>
            <div className="popup-header">
                <h3 className="htx2 pophead">Campaign Approval</h3>
                <span className="close toggle"  onClick={closeWithoutSaving} data-target="myPopup">close</span>
            </div>
            <div className="popup-body">
                <div className="viewdetailsHead">
                    <div className="approveActive">
                        <p>Our brand champions have approved the content and with your final approval,
                            we will go live with the compaign in 48 hours.
                        </p>
                        <div className="brand-btn">
                            <a onClick={viewDetails} href="javascript:void(0);" className="btn btn-outline">View Details</a>
                            <a onClick={approveRequest} href="javascript:void(0)" className="btn btn-orange">Approve</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CampaignApprovalModal
