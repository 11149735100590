import React, { useEffect, useState, useContext, useRef } from 'react'
import { Link, useHistory } from 'react-router-dom'
import AppContextObject from "../../../../../common/context/common"
import DataContext from "../../../../../service/backendContext"
import CampaignBudget from '../../../brands/components/campaignBudget'
import GenderSplit from '../../../brands/components/genderSplit'
import CampaignReach from '../../../brands/components/campaignReach'
import CampaignImpression from '../../../brands/components/campaignImpression'
import EventTable from './components/EventTableRow'
import EventTableRow from './components/EventTableRow'

const CampaignEvents = ({ setShowProgressSideMenu }) => {
    const history = useHistory()
    const context = useContext(AppContextObject)
    const parentData = useContext<any>(DataContext)
    const [formData, setFormData] = useState(parentData?.data?.eventAndOccation)
    const [totalBudget, setTotalBudget] = useState(parentData?.campaignDetail?.totalBudget)
    const [totalReach, setTotalReach] = useState(parentData?.campaignDetail?.totalReach)
    const [boysRatio, setBoysRatio] = useState(parentData?.campaignDetail?.boysRaio)
    const [girlsRatio, setGirlsRatio] = useState(parentData?.campaignDetail?.girlsRatio)
    const [totalImpression, setTotalImpression] = useState(parentData?.campaignDetail?.totalImpression)
    const [overlay, setOverlay] = useState(false)
    useEffect(() => {
        setShowProgressSideMenu(true)
        if (parentData.setActiveModule) {
            parentData.setActiveModule({
                ...parentData.activeModule,
                module: "campaignPlacement",
                subModule: "events"
            })
        }
    }, [])
    // const onSave = (confirm) => {
    //     if(confirm){
    //         if (parentData) {
    //             if (parentData.setProgress) {
    //                 parentData.setProgress({
    //                     ...parentData.progress,
    //                     event: true,
    //                 })
    //             }
    //             if(parentData.setData){
    //                 parentData.setData({
    //                     ...parentData.data,
    //                     eventAndOccation: {...formData}
    //                 })
    //             }
    //         }
    //         history.push('/brands/brand-details/create-campaign/creative')
    //     }
    //     else{

    //     }
    // }
    const nextPage = () => {
        history.push('/backend/backend-details/view-campaign/recommended-school')
    }
    return (
        <>
            <div className={`ovelap`}></div>
            {/* <TopBar isDashboard={true} /> */}
            <div className="col-xl-9 col-lg-9 col-md-12 col-xs-12">
                <div className="rightPart noBack">
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-20"><h2 className="htx1 clearfix mt-10 mb-10">Create a Campaign</h2></div>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-20"><h2 className="htx2 clearfix mb-10">Events & Occasions</h2></div>
                        <div className="col-xl-8 col-lg-8 col-md-12 col-xs-12 mb-20">
                            <div className="row">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 pl pr">
                                    <h2 className="htx3 clearfix">Recommended Events</h2>
                                </div>
                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-20 pl pr">
                                    <div className="table-container mt-10 addAdmininput fillterTableHeight">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">
                                                        <div className="checkbox fnone">
                                                            <input type="checkbox" id="html-1" disabled/>
                                                            <label htmlFor="html-1"></label>
                                                        </div>
                                                    </th>
                                                    <th scope="col">sr no</th>
                                                    <th scope="col">Event name</th>
                                                    <th scope="col">placements</th>
                                                    <th scope="col"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {parentData.data.activities.events.map((event,key)=>{
                                                return(
                                                    <EventTableRow
                                                        eventData={event}
                                                        keyNo={key+1}
                                            />
                                                )
                                            }) }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {/* <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 pl pr mt-20"></div>
                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 pl pr">
                                <h2 className="htx3 clearfix">Other Events & Occasions</h2>
                            </div>
                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 pl pr">
                                <div className="table-container mt-10 addAdmininput">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">
                                                <div className="checkbox fnone">
                                                    <input type="checkbox" id="html-3" />
                                                    <label htmlFor="html-3"></label>
                                                </div>
                                            </th>
                                            <th scope="col">Slno</th>
                                            <th scope="col">Event name</th>
                                            <th scope="col">placements </th>
                                            <th scope="col">Impressions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div className="checkbox fnone">
                                                    <input type="checkbox" id="html-4" />
                                                    <label htmlFor="html-4"></label>
                                                </div>
                                            </td>
                                            <td>2</td>
                                            <td>Annual Day</td>
                                            <td>3</td>
                                            <td>25,000 <span className="other-arrow align-R"><svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M11 1L6 6L1 1" stroke="#8C8C8C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg></span></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="checkbox fnone">
                                                    <input type="checkbox" id="html-5" />
                                                    <label htmlFor="html-5"></label>
                                                </div>
                                            </td>
                                            <td>3</td>
                                            <td>Childrens Day</td>
                                            <td>12</td>
                                            <td>25,000 <span className="other-arrow align-R"><svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M11 1L6 6L1 1" stroke="#8C8C8C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg></span></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="checkbox fnone">
                                                    <input type="checkbox" id="html-6" />
                                                    <label htmlFor="html-6"></label>
                                                </div>
                                            </td>
                                            <td>4</td>
                                            <td>Independence Day</td>
                                            <td>4</td>
                                            <td>25,000 <span className="other-arrow align-R"><svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M11 1L6 6L1 1" stroke="#8C8C8C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg></span></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="checkbox fnone">
                                                    <input type="checkbox" id="html-7" />
                                                    <label htmlFor="html-7"></label>
                                                </div>
                                            </td>
                                            <td>5</td>
                                            <td>Republic Day</td>
                                            <td>3</td>
                                            <td>25,000 <span className="other-arrow align-R"><svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M11 1L6 6L1 1" stroke="#8C8C8C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg></span></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="checkbox fnone">
                                                    <input type="checkbox" id="html-8" />
                                                    <label htmlFor="html-8"></label>
                                                </div>
                                            </td>
                                            <td>6</td>
                                            <td>Republic Day</td>
                                            <td>3</td>
                                            <td>25,000 <span className="other-arrow align-R"><svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M11 1L6 6L1 1" stroke="#8C8C8C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg></span></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="checkbox fnone">
                                                    <input type="checkbox" id="html-8" />
                                                    <label htmlFor="html-8"></label>
                                                </div>
                                            </td>
                                            <td>7</td>
                                            <td>Republic Day</td>
                                            <td>3</td>
                                            <td>25,000 <span className="other-arrow align-R"><svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M11 1L6 6L1 1" stroke="#8C8C8C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg></span></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="checkbox fnone">
                                                    <input type="checkbox" id="html-9" />
                                                    <label htmlFor="html-9"></label>
                                                </div>
                                            </td>
                                            <td>8</td>
                                            <td>Republic Day</td>
                                            <td>3</td>
                                            <td>25,000 <span className="other-arrow align-R"><svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M11 1L6 6L1 1" stroke="#8C8C8C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg></span></td>
                                        </tr>
                                    </tbody>
                                </table>
                                </div>
                                <div className="paginationInventory">
                                    <ul>
                                        <li>
                                            <a href="javascript:void(0)">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z"></path></svg></a></li><li><a href="javascript:void(0)" className="active">1
                                            </a>
                                        </li>
                                        <li><a href="javascript:void(0)">2</a></li>
                                        <li><a href="javascript:void(0)">3</a></li>
                                        <li><a href="javascript:void(0)">4</a></li>
                                        <li>. . . . . .</li>
                                        <li><a href="javascript:void(0)"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z"></path></svg></a></li>
                                    </ul>
                                </div>
                            </div> */}
                            <div className="col-xl-5 col-lg-5 col-md-12 col-xs-12 delete-user mt-30">
                            <Link className="btn btn-orange quit-btn" to={{
                                        pathname: "/backend/backend-details/create-trial-campaign-details",
                                        state: { campaignType: parentData.data.isTrial ? 'trial' : 'create', from: parentData.data.from, campaignId: parentData.data.campaignId }
                                    }}>QUIT</Link>
                                </div>
                                {/* <div className="col-xl-5 col-lg-5 col-md-12 col-xs-12 delete-user"><a className="viewwLink" style={{cursor: "pointer"}} onClick={() => onSave(false)}>Save &amp; continue later</a></div> */}
                                <div className="col-xl-7 col-lg-7 col-md-12 col-xs-12 end-md mt-30">
                                    <div className="forminpt">
                                        <input name="next" className="btn btn-orange" type="button" value="next" onClick={nextPage} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-xl-4 col-lg-4 col-md-12 col-xs-12 mb-20 mt-30">
                        <CampaignBudget
                            totalBudget={totalBudget}
                            setTotalBudget={setTotalBudget}
                            setOverlay={setOverlay}
                        />
                        <GenderSplit
                            girlsRatio={girlsRatio}
                            boysRatio={boysRatio}
                        />
                        <CampaignReach
                            totalReach={totalReach}
                            setTotalReach={setTotalReach}
                        />
                        <CampaignImpression
                            totalImpression={totalImpression}
                            setTotalImpression={setTotalImpression}
                        />
                    </div> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default CampaignEvents