import {
    useEffect,
    useState,
    useContext,
    useRef
} from "react"

import { useLocation } from "react-router-dom"

import ClassroomAttributes from "../../components/classrooms/attributes"
import ConfirmAlert from "../../../../../components/addon/confirmAlert"

import AppContextObject from "../../../../../common/context/common"

import DataContext from "../../../../../service/contextProvider"

import { compareObjs, objectDeepClone } from "../../../../../service/helper"
import { Steps } from "intro.js-react"
import errorSVG from "../../../../../assets/images/error.svg"
import menu from "../../../../../assets/images/menu.svg"

interface Props {
    isEdit?: boolean,
    onProceed?: any,
    toggleSidemenu ? : Function,
    showSidemenu ? : boolean
}

const StudentSpecific = ({
    isEdit,
    onProceed,
    toggleSidemenu,
    showSidemenu
}: Props) => {

    const parentData = useContext<any>(DataContext)

    const context = useContext(AppContextObject)

    const location = useLocation()

    const refClassrooms = useRef()

    const [initialData, setInitialData] = useState([])
    const [showPrompt, setShowPrompt] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")

    const [formData, setFormData] = useState(parentData.data.schoolGeneral)
    let [overlay, setOverlay] = useState(false)
    const [recheck, setRecheck] = useState(false)
    const [onBoarding, setOnboarding] = useState({
        stepsEnabled: false,
        initialStep: 0,
        steps: [
            {element: "#classroomsWrapper", intro: "Add information about all classes available in your school", position: "right"},
        ],
        options: {
            exitOnOverlayClick: false,
            showStepNumbers: true,
            hidePrev: true,
            hideNext: false,
            nextLabel: "Next",
            prevLabel: "Prev",
            doneLabel: "Got it",
            autoPosition: false,
            exitOnEsc: false,
            scrollToElement: true,
            disableInteraction: true
        },
    })
    useEffect(() => {
        setOnboarding({...onBoarding, stepsEnabled: parentData?.tooltipProgress?.studentSpecific})
    }, [])

    useEffect(() => {
        if (parentData.setActiveModule) {
            parentData.setActiveModule({
                module: "schoolDetails",
                subModule: "students"
            })
        }
    }, [])

    useEffect(() => {
        setFormData(parentData.data.schoolGeneral)
    }, [parentData.data.schoolGeneral])

    useEffect(() => {
    }, [formData])

    const compareOldAndNewData = (newData) => {
        const isSame = compareObjs(newData, initialData)
        if (isSame === false) {
            setShowPrompt(true)
        } else {
            setShowPrompt(false)
        }
    }

    const submitForm = async (event) => {
        

        event.preventDefault()
        let pendingClassList = []
        let classrooms = []
        let data = objectDeepClone(formData)
        if (refClassrooms && refClassrooms.current) {
            const ref: any = refClassrooms.current
            pendingClassList = ref.renderErrorState()
            classrooms = ref.fecthClassrooms()
            data = {
                ...data,
                classrooms: classrooms,
            }
        }        
        const updateProgress = {
            page: "schoolGeneral",
            value: false
        }
        updateProgress.value = true

        if (pendingClassList.length > 0) {
            setErrorMessage("Please fill all fields.")
            return
        } else {
            setErrorMessage("")
        }

        // setShowPrompt(false)
        let totalFee: number = 0;
        let category: string;
        let totalStudents = 0;
        let classroomLength = 0;

        for(let classroom of classrooms){
            totalFee = totalFee + ( + Number(classroom.data.avgAnnualFee)); 
            totalStudents += ((Number(classroom.data.girls) || 0) + (Number(classroom.data.boys) || 0))
            // classroomLength += classroom.data.noOfSections
            classroomLength += 1
        }
        let avgFee: number = totalFee / (classrooms.length);
        console.log("avgFee>>>",avgFee,totalFee,classrooms.length);
        if (avgFee > 100000) {
            category = "A"
        }
        else if (avgFee > 40000 && avgFee < 100000){
            category = "B"
        }
        else if (avgFee < 40000){ 
            category = "C"
        }
        
        console.log("totalFee, avgFee, category, totalStudents, classroomLength-",totalFee, avgFee, category, totalStudents, classroomLength);
        if (parentData) {
            if (parentData.setProgress) {
                parentData.setProgress({
                    ...parentData.progress,
                    studentSpecific: true
                })
            }
            
            
            let resp = await context.apis.public.getOnboardingRevenue(
                context.validator, {
                    ...parentData.data.algorithmData,
                    totalFee: totalFee,
                    category: category,
                    classrooms: classroomLength,
                    students: totalStudents
                }
            )
            console.log("getOnboardingRevenue resp----", resp)
            if(resp && resp.done){
                if(parentData && parentData.setNetRevenue){
                    parentData.setNetRevenue(resp.netRevenue || 0)
                }
            }
             // for total schools revenue calculation
             console.log("listofinventories",parentData.listOfInventories);

            let allInvData = []

            if(parentData.listOfInventories){
                for(let inventory of parentData.listOfInventories){
                    if(inventory.type !== "events&Occasions"){
                        let data = {
                            "placement": [],
                            "cpi": [],
                            "materialCost": [],
                            "size": [],
                            "unit": [],
                            "quantity": [],
                            "changes": [],
                            "parentInventory": "",
                            "uniqueName": ""
                          }
                        for(let placement of inventory.placements){
                            data['placement'].push(placement.name)
                            console.log("placement---",placement);
                            if(placement.data.cpi){
                                for(let cpi of placement.data.cpi){
                                    if(cpi.category === category){
                                        data['cpi'].push(cpi.value)
                                    }
                                }
                            } else if(placement.data.costPerImpression){
                                for(let costPerImpression of placement.data.costPerImpression){
                                    if(costPerImpression.category === category){
                                        data['cpi'].push(costPerImpression.value)
                                    }
                                }
                            } else {
                                data['costPerImpression'].push(0)
                            }
                            data['materialCost'].push(placement.data.materialCost)
                            data['size'].push(placement.defaultSize)
                            data['unit'].push(placement.defaultUnits)
                            data['quantity'].push(placement.defaultQuantity)
                            data['changes'].push(placement.data.noOfChangesYearly)
                            data['parentInventory'] = inventory.name
                            data['uniqueName'] = inventory.name
                        }
                        console.log("data---xxxx",data);
                        allInvData.push(data)
                    }
                    
                }
            }

            let otherSchoolRevenueData = {
                category: category,
                totalFee: totalFee,
                classrooms: classroomLength,
                students: totalStudents,
                teachers: parentData.data.algorithmData.teachers,
                inventories: allInvData,
                events: []
            }

            console.log("otherSchoolRevenueData----",otherSchoolRevenueData);

            // let otherSchoolResp = await context.apis.public.getOnboardingRevenue(
            //     context.validator, otherSchoolRevenueData
            // )

            // if(otherSchoolResp && otherSchoolResp.done){
            //     if(parentData && parentData.setOtherSchoolsRevenue){
            //         console.log("working",parentData.setOtherSchoolsRevenue);
                    // let revenueAmount = category === "A" ? context.utils.categoryAmounts.A : category === "B" ? context.utils.categoryAmounts.B : category === "C" ? context.utils.categoryAmounts.C : context.utils.categoryAmounts.C
                    let revenueAmount = context.utils.categoryAmounts.C
                    if(resp.schoolCategory){
                        revenueAmount = resp.schoolCategory === "A" ? context.utils.categoryAmounts.A : resp.schoolCategory === "B" ? context.utils.categoryAmounts.B : resp.schoolCategory === "C" ? context.utils.categoryAmounts.C : context.utils.categoryAmounts.C
                    }
                    parentData.setOtherSchoolsRevenue((resp.maxPotentialRevenue && resp.maxPotentialRevenue.total) || 0)
            //     }
            // }
            // context?.utils?.categoryAmounts

            if (parentData.setData) {
                parentData.setData({
                    ...parentData.data,
                    schoolAmenities: {
                        ...parentData.data.schoolAmenities,
                        classrooms: classrooms
                    },
                    algorithmData: {
                        ...parentData.data.algorithmData,
                        totalFee: totalFee,
                        category: category,
                        classrooms: classroomLength,
                        students: totalStudents
                    },
                    otherSchoolAlgorithmData: {
                        ...parentData.data.otherSchoolAlgorithmData,
                        totalFee: totalFee,
                        category: category,
                        classrooms: classroomLength,
                        students: totalStudents
                    }
                })
            }
            

            // console.log("otherSchoolResp======",otherSchoolResp);

             console.log("allInvData->>>>", allInvData);

            console.log("algo resp->>>>", resp);
        }
        setShowPrompt(false)
        setRecheck(true)
        // process.nextTick(() => {
        //     if (isSummary === "true") {
        //         if (isEdit === true) {
        //             onProceed("/schools/school-details/details/update-details-summary")
        //         } else {
        //             onProceed("/schools/onboarding/onboarding-summary")
        //         }
        //     } else {
        //         if (isEdit === true) {
        //             onProceed("/schools/school-details/details/school-details/amenities")
        //         } else {
        //             onProceed("/schools/onboarding/classroom-inventories")
        //         }                
        //     }
        // })
    }
    useEffect(() => {
        if(recheck===true && showPrompt===false) {
            const isSummary = new URLSearchParams(location.search).get("isSummary")
            process.nextTick(() => {
                if (isSummary === "true") {
                    if (isEdit === true) {
                        onProceed("/schools/school-details/details/update-details-summary")
                    } else {
                        onProceed("/schools/onboarding/onboarding-summary")
                    }
                } else {
                    if (isEdit === true) {
                        onProceed("/schools/school-details/details/school-details/amenities")
                    } else {
                        onProceed("/schools/onboarding/classroom-inventories")
                    }                
                }
            })
        }
      },[recheck, showPrompt])

    const confirmAlertCallback = (data) => {
        setOverlay(data.overlay)
    }
    const hanldeOnboardingComplete = () => {
        parentData.setTooltipProgress({
            ...parentData.tooltipProgress,
            studentSpecific: false
        })
        let progress = {
            ...parentData.tooltipProgress,
            studentSpecific: false
        }
        localStorage.setItem("tooltipProgress", JSON.stringify(progress))
    }
    const exitOnBoarding = (e) => {
        console.log(e)
    }
    console.log(onBoarding)

    return (
        <>
            <div className={`ovelap ${overlay === true ? "active" : ""}`}></div>
            <div className={`${isEdit === false ? "col-xl-9 col-lg-9 col-md-12 col-xs-12" : ""}`}>
                <div className={`${isEdit === false ? "content mCustomScrollbar fixhight newMvp-pages-css" : ""}`}>
                    <div className={`${isEdit === false ? "rightPart w80" : ""}`}>
                        {isEdit === false &&
                            <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                                <a onClick={() => toggleSidemenu()} href='javascript:void(0)' className={`hummob ${showSidemenu === true ? "no_highlight" : ""}`}>
                                    <img src={menu} />
                                </a>
                                <h2 className='htx1 mb40'>
                                Tell us about your students
                            </h2></div>
                        }
                        <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb40'>
                            <ClassroomAttributes
                                ref={refClassrooms}
                                compareOldAndNewData={compareOldAndNewData}
                                setInitialData={setInitialData}
                            />
                        </div>
                        <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-20 end-xs'>
                            <div className="bottom-error-btn">
                                <p className="valError">
                                    {
                                        errorMessage &&
                                        <>
                                            <img src={errorSVG} />
                                            {errorMessage}
                                        </>
                                    }
                                </p>
                                <div className='forminpt'>
                                    <input
                                        onClick={submitForm}
                                        name='Proceed'
                                        value='Proceed'
                                        className='btn btn-orange sc-ameties'
                                        type='button'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ConfirmAlert
                when={showPrompt}
                title="Leave page without saving..."
                cancelText="Cancel"
                okText="Confirm"
                onOK={() => true}
                onCancel={() => false}
                parentCallback={confirmAlertCallback}
            />  
            <Steps
                enabled={onBoarding.stepsEnabled}
                steps={onBoarding.steps}
                initialStep={onBoarding.initialStep}
                onComplete={hanldeOnboardingComplete}
                onExit={exitOnBoarding}
                options={onBoarding.options}
            />
        </>
    )
}

export default StudentSpecific
