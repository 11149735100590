import React, { useState, useEffect, useRef, useContext } from 'react'
import uploadDocument from "../../../../assets/images/img-upload.jpg"
import errorSVG from "../../../../assets/images/error.svg"
import deleteImg from "../../../../assets/images/delete.svg"

import SimpleReactValidator from "simple-react-validator"
import ImageModal from '../../../../components/addon/imageModal/imageModal'
import TextField from '../../../../components/formFields/textField/textField'
import Compress from "compress.js"

import toast from "react-hot-toast"
import { PublicContextInitiator } from '../../../apis/publicContextInitiator'
import AppContextObject from '../../../../common/context/common'
import TextArea from '../../../../components/formFields/textArea'
import ConfirmDialog from '../../../../components/addon/confirmDialog/confirmDialog'
import saveInput from "../../../../assets/images/save.svg"
import proEdit from "../../../../assets/images/edit-profile.svg"
import trashCan from "../../../../assets/images/trash-can.svg"
import close from "../../../../assets/images/close-1.svg"
import editSvg from "../../../../assets/images/edit-btn.svg"
import AlertDialog from '../../../../components/addon/alertDialog/alertDialog'

const EditSubCategory = (props) => {
    const {
        subCategoryList,
        setSubCategoryList,
        allSubCategoryList,
        setOverlay,
        refetch,
        setRefetch,
        profileData,
        isBackendUser,
        backendCompanyId,
        backendUserRole,
        brandCategoryId,
        editingSubCategoryData,
        setEditingSubCategoryData
    } = props
    const context = useContext(AppContextObject)
    const [formValidator] = useState(useRef(new SimpleReactValidator({
        element: (message, className) =>
            <span className="valError"><img src={errorSVG} />{message}</span>
    })))
    const [editingData, setEditingData] = useState(false)
    const [formData, setFormData] = useState({ name: "" })
    const [loading, setLoading] = useState(false)
    const [update, forceUpdate] = useState(false)
    const [editIndex, setEditIndex] = useState(null)
    const [isSave, setIsSave] = useState(false)
    const [oldsubCategoryName, setOldSubCategoryName] = useState(null)
    const [toggleDeleteModal, setToggleDeleteModal] = useState(false)
    const [toggleAlertModal, setToggleAlertModal] = useState(false)
    const [isNewAdd, setIsNewAdd] = useState(false)
    const [newSubCategory, setNewSubCategory] = useState({ name: "" })
    const onFormDataChange = (event) => {
        console.log("event-----", event);
        setFormData({ name: event.target.value })
    }
    const [showAllCategory, setShowAllCategory] = useState(false)
    const [categoryShowCount, setCategoryShowCount] = useState(3)

    const handleEdit = (index, id, categoryData) => {
        setIsNewAdd(false)
        formValidator.current.purgeFields()
        console.log("button clicked = edit", index, editIndex, isSave, editIndex !== null && editIndex === index && isSave);
        setEditIndex(index);
        setFormData({ name: categoryData.name })
        setOldSubCategoryName(categoryData.name)
        setIsSave(true)
        console.log("index, id, categoryData", index, id, categoryData);
    }

    const handleSave = async (index, id, categoryData) => {
        const result = formValidator.current.allValid()

        if (result === false) {
            formValidator.current.showMessages()
            forceUpdate(true)
            return
        } else {
            formValidator.current.hideMessages()
            forceUpdate(!update)
        }
        console.log("formData name, oldSubCategoryName", formData.name, oldsubCategoryName, formData.name.toLowerCase().trim() === oldsubCategoryName.toLowerCase().trim());

        if (formData.name.toLowerCase().trim() !== oldsubCategoryName.toLowerCase().trim()) {
            let data = {
                name: oldsubCategoryName,
                newName: formData.name.trim(),
                parentBrandId: isBackendUser ? backendCompanyId : (context.user.parentBrand || context.user.brand)
            }
            let resp = await context.apis[context.user.role].manageBrandSubCategory(
                context.validator, data
            )

            if (resp && resp.done) {
                if (!isBackendUser) {
                    if (context?.user?.brandCategoryName && context?.user?.brandCategoryName.toLowerCase().trim() === oldsubCategoryName.toLowerCase().trim()) {
                        context.user.brandCategoryName = formData.name
                    }
                }
            }

            console.log("manageBrandSubCategory resp>>>>", resp);
            setRefetch(!refetch)
        }
        setEditIndex(null);
        setIsSave(false)
    }

    const handleCancel = () => {
        setIsSave(false)
        setEditIndex(null)
    }

    const toggleModal = async (index, categoryData) => {
        if (categoryData.userId) {
            setToggleAlertModal(true)
            setOverlay(true)
        } else {
            setToggleDeleteModal(true)
            setOverlay(true)
            setIsSave(false)
            setEditIndex(null)
            setFormData({ name: categoryData.name })
        }

    }

    const alertDialogConfirmClose = (confirm) => {
        setToggleAlertModal(false)
        setOverlay(false)
    }

    const deleteConfirmClose = async (confirm) => {
        if (confirm === true) {
            console.log("yes confirm");
            let data = {
                name: formData.name,
                parentBrandId: isBackendUser ? backendCompanyId : (context.user.parentBrand || context.user.brand),
                isDelete: true
            }
            let resp = await context.apis[context.user.role].manageBrandSubCategory(
                context.validator, data
            )

            console.log("delete BrandSubCategory", resp);
            setRefetch(!refetch)
        }
        setToggleDeleteModal(false)
        setOverlay(false)
    }

    const onNewSubCategoryDataChange = (e) => {
        setNewSubCategory({ name: e.target.value })
    }

    const handleAdd = () => {
        setIsNewAdd(true)
        formValidator.current.purgeFields()
        setIsSave(false)
        setEditIndex(null)
    }

    const handleNewSubCategorySave = async () => {
        const result = formValidator.current.allValid()

        if (result === false) {
            formValidator.current.showMessages()
            forceUpdate(!update)
            return
        } else {
            formValidator.current.hideMessages()
            forceUpdate(!update)
        }

        let data = {
            name: newSubCategory.name.trim(),
            parentBrandId: isBackendUser ? backendCompanyId : (context.user.parentBrand || context.user.brand),
            isCreate: true,
            isParent: context.user.isParent === false ? context.user.isParent : null,
            brandType: isBackendUser ? brandCategoryId : context.user.brandType ? context.user.brandType : null,
        }
        let resp = await context.apis[context.user.role].manageBrandSubCategory(
            context.validator, data
        )

        if (resp && resp.done) {
            setNewSubCategory({ name: "" })
            setRefetch(!refetch)
        }

        setIsNewAdd(false)
    }

    const handleNewSubCategoryCancel = () => {
        setIsNewAdd(false)
        setNewSubCategory({ name: "" })
        formValidator.current.hideMessages()
    }

    formValidator.current.rules['duplicate'] = {
        message: 'Sub category with same name already exists.',
        rule: (val) => {
            let returnValue
            let foundDuplicate = subCategoryList.find(el => el.name.trim().toLowerCase() === newSubCategory.name.trim().toLowerCase())
            if (foundDuplicate) {
                returnValue = false
            } else {
                returnValue = true
            }
            return returnValue
        },
    }

    formValidator.current.rules['edit_duplicate'] = {
        message: 'Sub category with same name already exists.',
        rule: (val) => {
            let returnValue
            let foundDuplicate = subCategoryList.find(el => el.name.trim().toLowerCase() === formData.name.trim().toLowerCase())
            if (foundDuplicate && (formData.name.toLowerCase().trim() !== oldsubCategoryName.toLowerCase().trim())) {
                returnValue = false
            } else {
                returnValue = true
            }
            return returnValue
        },
    }

    const handleLoadMore = (value) => {
        // setCategoryShowCount(categoryShowCount + 3)
        setShowAllCategory(value)
    }

    return (
        <>
            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                <div className="row">
                    <div className="col-xl-4 col-lg-4 col-md-12 col-xs-12">
                        <div className="profile-general">
                            <h2 className="htx3 clearfix">Sub Category
                                {
                                    isBackendUser &&
                                    editingSubCategoryData && (backendUserRole === "backendAdmin" || backendUserRole === "backendSales" || backendUserRole === "backendTech") && <a href="javascript:void(0)" className="editbtn" onClick={() => setEditingSubCategoryData(false)}><img src={close} /> Cancel</a>
                                }
                            </h2>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12 brand-general">
                        <div className="inventory-overHead mt-0">
                            {
                                isNewAdd === false &&
                                <a href="javascript:void(0);" onClick={() => handleAdd()} className="viewwLink fR mt-0" >+ Add</a>
                            }
                            {
                                isNewAdd &&
                                <>
                                    <TextField
                                        formData={newSubCategory}
                                        formKey='name'
                                        onDataChange={onNewSubCategoryDataChange}
                                        label='Sub Category Name'
                                        placeholder='Enter Sub Category Name'
                                        isRequired={false}
                                        formValidator={formValidator}
                                        validation='required|alpha_space|duplicate'
                                        maxLength={50}
                                    />
                                    <div className='subEditBtn'>
                                        <a href="javascript:void(0);" onClick={() => handleNewSubCategoryCancel()} className='btn btn-outline'> Cancel </a>
                                        <a href="javascript:void(0);" onClick={() => handleNewSubCategorySave()} className='btn btn-orange'> Save </a>
                                    </div>
                                </>
                            }
                            <div className="table-container table-inventory mt-10">
                                <table className="table">
                                    {/* <thead>
                                        <tr>
                                            <th>Name <a href="javascript:void(0);"></a></th>
                                            <th className='align-R'>Action</th>
                                        </tr>
                                    </thead> */}
                                    <tbody>
                                        {
                                            subCategoryList && subCategoryList.length > 0 &&
                                            subCategoryList.map((category, index) => {
                                                return (
                                                    <>
                                                        {
                                                            showAllCategory
                                                                ?
                                                                <tr>
                                                                    <td>
                                                                        {
                                                                            editIndex !== null &&
                                                                                editIndex === index ?
                                                                                <TextField
                                                                                    formData={formData}
                                                                                    formKey='name'
                                                                                    onDataChange={onFormDataChange}
                                                                                    placeholder='Enter Sub Category Name'
                                                                                    isRequired={false}
                                                                                    formValidator={formValidator}
                                                                                    validation='required|alpha_space|edit_duplicate'
                                                                                    maxLength={50}
                                                                                />
                                                                                :
                                                                                category.name
                                                                        }
                                                                    </td>
                                                                    <td style={{ verticalAlign: "middle" }}>
                                                                        <div className="fR editSub">

                                                                            <a href="javascript:void(0)" className="editbtn" onClick={() => { editIndex === index && isSave === true ? handleSave(index, category._id, category) : handleEdit(index, category._id, category) }}>
                                                                                {
                                                                                    editIndex !== null && editIndex === index && isSave
                                                                                        ?
                                                                                        <img src={saveInput} />
                                                                                        :
                                                                                        <>
                                                                                            <img src={proEdit} />
                                                                                        </>
                                                                                }

                                                                            </a>
                                                                            {
                                                                                editIndex !== null && editIndex === index && isSave
                                                                                    ?
                                                                                    <a href="javascript:void(0)" className="editbtn" onClick={() => { handleCancel() }}>
                                                                                        <img src={close} />
                                                                                    </a>
                                                                                    :
                                                                                    <a href="javascript:void(0)" className="editbtn" onClick={() => { toggleModal(index, category) }}>
                                                                                        <img src={trashCan} />
                                                                                    </a>
                                                                            }
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                :
                                                                <>
                                                                    {
                                                                        index < 3
                                                                        &&
                                                                        <tr>
                                                                            <td>
                                                                                {
                                                                                    editIndex !== null &&
                                                                                        editIndex === index ?
                                                                                        <TextField
                                                                                            formData={formData}
                                                                                            formKey='name'
                                                                                            onDataChange={onFormDataChange}
                                                                                            placeholder='Enter Sub Category Name'
                                                                                            isRequired={false}
                                                                                            formValidator={formValidator}
                                                                                            validation='required|alpha_space|edit_duplicate'
                                                                                            maxLength={50}
                                                                                        />
                                                                                        :
                                                                                        category.name
                                                                                }
                                                                            </td>
                                                                            <td style={{ verticalAlign: "middle" }}>
                                                                                <div className="fR editSub">

                                                                                    <a href="javascript:void(0)" className="editbtn" onClick={() => { editIndex === index && isSave === true ? handleSave(index, category._id, category) : handleEdit(index, category._id, category) }}>
                                                                                        {
                                                                                            editIndex !== null && editIndex === index && isSave
                                                                                                ?
                                                                                                <img src={saveInput} />
                                                                                                :
                                                                                                <>
                                                                                                    <img src={proEdit} />
                                                                                                </>
                                                                                        }

                                                                                    </a>
                                                                                    {
                                                                                        editIndex !== null && editIndex === index && isSave
                                                                                            ?
                                                                                            <a href="javascript:void(0)" className="editbtn" onClick={() => { handleCancel() }}>
                                                                                                <img src={close} />
                                                                                            </a>
                                                                                            :
                                                                                            <a href="javascript:void(0)" className="editbtn" onClick={() => { toggleModal(index, category) }}>
                                                                                                <img src={trashCan} />
                                                                                            </a>
                                                                                    }
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    }
                                                                </>
                                                        }
                                                    </>
                                                )
                                            })
                                        }

                                    </tbody>
                                </table>
                                {
                                    // categoryShowCount >= (subCategoryList && subCategoryList.length)
                                    showAllCategory
                                        ?
                                        <a href="javascript:void(0);" onClick={() => handleLoadMore(false)} className="viewwLink fR" >Show Less</a>
                                        :
                                        !showAllCategory && subCategoryList.length > 3
                                            ?
                                            <a href="javascript:void(0);" onClick={() => handleLoadMore(true)} className="viewwLink fR" >Load More</a>
                                            :
                                            ""
                                }

                            </div>
                        </div>
                        <div className="clearfix mt-30"></div>
                    </div>
                </div>
            </div>
            <ConfirmDialog
                togglePopup={toggleDeleteModal}
                onModalClose={deleteConfirmClose}
                confirmTitle="Are you sure you want to delete sub category?"
                confirmText="Delete"
            />
            <AlertDialog
                togglePopup={toggleAlertModal}
                onModalClose={alertDialogConfirmClose}
                displayMsg={'This sub category is already assigned to a brand manager you can not delete it.'}
                showBtn={true}
            />
        </>
    )
}

export default EditSubCategory
