import { Route, Redirect, useHistory } from "react-router-dom"

import authService from "../service/auth"
import AppContextObject from "../common/context/common"
import { useContext, useEffect, useState } from "react"

export const PublicRoute = (props) => {

    const {
        component: Component,
        ...rest
    } = props
    const context = useContext(AppContextObject)
    const history = useHistory()
    const [redirectPath, setRedirectPath] = useState("")

    if (!context.apis || !context?.user?.role || !context?.socketManager.socket.connected) {
        // document.location.reload()
    }

    useEffect(() => {
      if(context && context.user && context.user.role){
            if(context.user.role.startsWith("school")){
                setRedirectPath("/schools/school-details/dashboard")
            }else if(context.user.role.startsWith("brand")){
                setRedirectPath("/brands/brand-details/dashboard")
            }else if(context.user.role.startsWith("backend")){
                if(context.user.role==="backendFOS"){
                    setRedirectPath("/backend/backend-details/fos-details")
                }else{
                    setRedirectPath("/backend/backend-details/dashboard")
                }
            }else{
                setRedirectPath("")
            }          
      }
    }, [history.location.pathname]);

    
    const isAuthenticated = authService.checkAuth() ? true : false
    return (
        <Route
        {...rest}
        render={routeProps => {
                if (!authService.checkAuth()) {
                    // console.log("welcoming");
                    return <Component {...routeProps} {...rest} />
                } else {
                    // console.log("Returning", redirectPath);
                    return (
                        redirectPath !== "" &&
                        <Redirect
                            to={{
                                pathname: redirectPath,
                                state: {
                                    from: routeProps.location
                                }
                            }}
                        />
                    )
                }
            }}
        />
    )
}
