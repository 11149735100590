import React, { useEffect, useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import wisrOptionImg from "../../../../../assets/images/wisr-option-img.png"
import DataContext from "../../../../../service/backendContext"

const CampaignSchool = ({setShowProgressSideMenu}) => {
    const history = useHistory()
    const parentData = useContext<any>(DataContext)
    const [formData, setFormData] = useState(parentData?.data?.campaignDetail)
    useEffect(() => {
        setShowProgressSideMenu(true)
        if (parentData.setActiveModule) {
            parentData.setActiveModule({
                ...parentData.activeModule,
                module: "campaignPlacement",
                subModule: "school"
            })
        }
    }, [])
    const onSave = (confirm) => {
        if(confirm){
            if(parentData) {
                if(parentData.setProgress) {
                    parentData.setProgress({
                        ...parentData.progress,
                        school: true,
                    })
                }
                if(parentData.setData){
                    parentData.setData({
                        ...parentData.data,
                        campaignDetail: {...formData}
                    })
                }
            }
            history.push('/brands/brand-details/create-campaign/recommended-school')
        }
    }
    const nextPage = () =>{
            history.push('/backend/backend-details/view-campaign/recommended-school');
        
    }
    console.log(parentData)
    return (
        <>
            <div className={`ovelap`}></div>
            {/* <TopBar isDashboard={true} /> */}
            <div className="col-xl-9 col-lg-9 col-md-12 col-xs-12">
            <div className="rightPart noBack">
                <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-20"><h2 className="htx1 clearfix mt-10 mb-10">Create a Campaign</h2></div>
                    
                    <div className="col-xl-8 col-lg-8 col-md-12 col-xs-12 mb-20">
                        <div className="wisr-option">
                            <div className="radioBtn">
                                <input type="radio" id="test1" name="radio-group" />
                                <label htmlFor="test1">The Wisr Option</label>
                            </div>
                            <div className="line-full mt-20 mb-20"></div>
                            <div className="wisr-option-img">
                                <img src={wisrOptionImg} />
                            </div>
                            <p className="ptx1">Run your campaign the Wisr way! Pick from top performing schools with shortlisted inventory and key locations with the help of our custom algorithm designed to ensure efficiency and accurate returns.</p>
                            <div className="addi-reach mt-20 mb-10">
                                <p>Additional reach of <b>1,000 student</b></p>
                            </div>
                            <h2 className="htx2 clearfix mt-20">Recommended Campaign</h2>
                            <div className="row generalview">
                                <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12 mt-20 pl">
                                    <div className="inventory-overHead">
                                        <p>Budget</p><h3>₹ 1.2 Lac <em>1 Lac</em></h3>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12 mt-20 pl">
                                    <div className="inventory-overHead">
                                        <p>Estimated Reach</p><h3>2,000 Student <em>1000</em></h3>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12 mt-20 pl">
                                    <div className="inventory-overHead">
                                        <p>No of Shortlisted Schools</p><h3>54</h3>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12 mt-20 pl">
                                    <div className="inventory-overHead">
                                        <p>Location</p><h3>Mumbai, Thane <a href="" className="viewwLink">14 more</a></h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="wisr-option">
                            <div className="radioBtn">
                                <input type="radio" id="test2" name="radio-group" checked/>
                                <label htmlFor="test2">Choose Placements</label>
                            </div>
                            <div className="line-full mt-20 mb-20"></div>
                            
                            <p className="ptx1">Explore our partner schools and preferred locations and choose where you want to run your campaign. </p><p className="ptx1">And don't worry, we will notify you if you exceed your budget!</p>
                            <div className="row generalview">
                                <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12 mt-20 pl">
                                    <div className="inventory-overHead">
                                        <p>Campaign Budget:</p><h3>₹ {parentData.data.brandDetail.budget}</h3>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-8 col-lg-8 col-md-12 col-xs-12">
                    <div className="row">
                    {/* <div className="col-xl-5 col-lg-5 col-md-12 col-xs-12 delete-user"><a className="viewwLink" style={{cursor: "pointer"}} onClick={() => onSave(false)}>Save &amp; continue later</a></div> */}
                    <div className="col-xl-7 col-lg-7 col-md-12 col-xs-12 end-md mt-30">
                        <div className="forminpt">
                            <input name="next" className="btn btn-orange" type="button" value="next" onClick={nextPage}/>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            </div>
        </>
    )
}

export default CampaignSchool
