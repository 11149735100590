import { useState, useContext, useEffect } from "react"
import {
    Route,
    Switch,
    useRouteMatch,
    useHistory
} from "react-router-dom"

import UpdateDetailsSideMenu from "../components/updateDetailsSideMenu"
import Dashboard from "./dashboard"
import MySchool from "./mySchool"
import Support from "./support"
import MyProfile from "./myProfile"
import Inventory from "./inventory/inventoryList"
import Settings from "./settings"
import Details from "./details/details"

import AppContextObject from "../../../../common/context/common"
import config from "../../../../service/config"
import { DataContextProvider } from "../../../../service/contextProvider"
import { eraseAllCookies, eraseCookie } from "../../../../service/helper"
import toast from "react-hot-toast"

const UpdateDetails = (props) => {
    
    const router = useRouteMatch()
    const history = useHistory()
    const [toolTipLoaded, setToolTipLoaded] = useState(false)
    const context = useContext(AppContextObject)
    const [fillPersonalDetails, setFillPersonalDetails] = useState(false)
    const [fillGeneralDetails, setFillGeneralDetails] = useState(false)
    const [fillAmenitiesDetails, setFillAmenitiesDetails] = useState(false)
    const [fillSchoolSpecificDetails, setFillSchoolSpecificDetails] = useState(false)
    const [fillClassroomSpecificDetails, setFillClassroomSpecificDetails] = useState(false)
    const [fillActivitiesAndStudentLifeDetails, setFillActivitiesAndStudentLifeDetails] = useState(false)
    const [isEdit, setIsEdit] = useState(true);
    const [refetch, setRefetch] = useState(false)

    const [data, setData] = useState({
        personalDetails: {
            fullName: '',
            username: '',
            email: '',
            phone: '',
            countryCode: '+91',
            schoolName: '',
            registeredUnder: "",
            schoolPrincipalName: "",
            schoolPrincipalEmail: "",
            role: null
        },
        schoolGeneral: {
            educationLevel: [],
            schoolType: {
                schoolType: null,
                classWiseDetails: []
            },
            board: {
                board: null,
                classWiseDetails: []
            },
            language: {
                language: null,
                classWiseDetails: []
            },
            pincode: '',
            state: '',
            city: '',
            address: '',
            builtUpArea: config.limits.schoolArea.min,
            floors: 1,
            noOfTeachers: '',
        },
        schoolAmenities: {
            builtUpArea: config.limits.schoolArea.min,
            floors: 1,
            amenities: [],
            classrooms: [],
            classroomAmenities: [],
            commonAreaAmenities: [],
            otherRoomsAndStaffAmenities: [],
            outdoorAmenities: []
        },
        student: {
            life: [],
            activities: []
        },
        isDataUpdated : false,
        removeInventoriesArray: [],
        removeInventoriesAttributeArray: [],
        removeUploadedImageArray: [],
        removeSingleUploadedImageArray: [],
        removeActivitiesArray:[],
        removeActitiesAttributeArray: [],
        unsavedImages: [],
        userId: null
    })

    const [showSidemenu, setShowSidemenu] = useState(false)

    const [previousData, setPreviousData] = useState({
        personalDetails: {
            fullName: '',
            username: '',
            email: '',
            phone: '',
            countryCode: '+91',
            schoolName: '',
            registeredUnder: '',
            schoolPrincipalName: '',
            schoolPrincipalEmail: '',
            role: null
        },
        schoolGeneral: {
            educationLevel: [],
            schoolType: {
                schoolType: null,
                classWiseDetails: []
            },
            board: {
                board: null,
                classWiseDetails: []
            },
            language: {
                language: null,
                classWiseDetails: []
            },
            pincode: '',
            state: '',
            city: '',
            address: '',
            builtUpArea: config.limits.schoolArea.min,
            floors: 1,
            noOfTeachers: '',
        },
        schoolAmenities: {
            builtUpArea: config.limits.schoolArea.min,
            floors: 1,
            amenities: [],
            classrooms: [],
            classroomAmenities: [],
            commonAreaAmenities: [],
            otherRoomsAndStaffAmenities: [],
            outdoorAmenities: []
        },
        student: {
            life: [],
            activities: []
        },
        userId: null
    })

    const [progress, setProgress] = useState({
        personalDetails: true,
        schoolGeneral: true,
        schoolAmenities: true,
        schoolDetails: true,
        classroomDetails: true,
        studentActivities: true,
        activityDetails: true,
    })
    const [toolTipProgress, setToolTipProgress] = useState({
        dashBoard: true,
        mySchool: true,
        inventory: true
    })

    const [selectedInventories, setSelectedInventories] = useState([])

    const [isUpdated, setIsUpdated]= useState(false)

    context.socketManager.socket.on("logoutEvent", (data) => {
        console.log("checking ip..",data.data.ip, sessionStorage.getItem('ip'), data.data.ip === sessionStorage.getItem('ip'));
        if(data.data.ip === sessionStorage.getItem('ip'))
        {
            deleteUserLocation()
            eraseAllCookies();
            eraseCookie("session-wisr-core-microservice-backend")
            context.socketManager.socket.disconnect();
            toast.success('logged out by another device')
            history.push('/')
            sessionStorage.clear()
        }
        
    });

    const deleteUserLocation = async () =>{
        if(sessionStorage.getItem('locationActivityId')){
            let resp = await context.apis[context.user.role].deleteUserLocation(
                context.validator, {_id: sessionStorage.getItem('locationActivityId')}
            )
            console.log("deleteUserLocation resp", resp);
        }
        
    }

    const toggleSidemenu = () => {
        setShowSidemenu(!showSidemenu)
    }

    const myValue:any = {
        data,
        setData,
        previousData,
        setPreviousData,
        progress,
        setProgress,
        isUpdated,
        setIsUpdated,
        toggleSidemenu,
        showSidemenu,
        selectedInventories,
        setSelectedInventories,
        setToolTipProgress,
        toolTipProgress
    }
    if (!context.apis || !context?.user?.role || !data.personalDetails) {
        // alert("wait")
        document.location.reload()
    }

    // useEffect(() => {
    //     updateUsersToolTipProgress()
    // }, [])

    // const updateUsersToolTipProgress = async () => {
    //     let userToolTipResp = await context.apis[context.user.role].updateUsersToolTipProgress(
    //         context.validator, {}, {toolTipProgress}
    //     )
    //     console.log(userToolTipResp)
    // }

    useEffect(() => {
        // redirecting user to user verification screen if isVerifed is false
        if (context && context.user && context.user.isVerified === false) {
            // document.location.reload()
            history.push({
                pathname: '/user-verification',
                state: {
                    formLogin : true,
                },
              })
        }
    }, [])


    useEffect(() => {
        if(data.isDataUpdated === true && history.location.pathname === "/schools/school-details/myschool"){
        }
    }, [])

    useEffect(() => {
        getUsersToolTipProgress()
    }, [])

    useEffect(() => {
        getPersonalDetails();
        getSchoolGeneralDetails();
        getSchoolAmenitiesDetails();
        getSchoolSpecificDetails();
        getClassroomSpecificDetails();
        getActivitiesAndStudentLifeDetails()
    }, [refetch])

    useEffect(() => {
        if(isUpdated === true){
            getPersonalDetails();
            getSchoolGeneralDetails();
            getSchoolAmenitiesDetails();
            getSchoolSpecificDetails();
            getClassroomSpecificDetails();
            getActivitiesAndStudentLifeDetails()
        }
    }, [isUpdated])

    useEffect(() => {
        if(fillPersonalDetails === false || data.personalDetails.fullName === ''){
            getPersonalDetails();
        }        
    }, [fillPersonalDetails])

    useEffect(() => {
        if(fillGeneralDetails === false || data.schoolGeneral.board === null){
            getSchoolGeneralDetails();
        }
    }, [fillGeneralDetails])

    useEffect(() => {
        if(fillAmenitiesDetails === false){
            getSchoolAmenitiesDetails();
        }
    }, [fillAmenitiesDetails])

    useEffect(() => {
        if(fillSchoolSpecificDetails === false){
            getSchoolSpecificDetails()
        }
    }, [fillSchoolSpecificDetails])

    useEffect(() => {
        if(fillActivitiesAndStudentLifeDetails === false){
            getClassroomSpecificDetails()
        }
    }, [fillActivitiesAndStudentLifeDetails])

    useEffect(() => {
        if(fillClassroomSpecificDetails === false){
            getActivitiesAndStudentLifeDetails()
        }
    }, [fillClassroomSpecificDetails])

    useEffect(() => {
        if(sessionStorage.getItem("reloadPage")){
            history.push(`${sessionStorage.getItem("reloadPage")}`)
            sessionStorage.removeItem("reloadPage")
        }
    }, [])

    const getUsersToolTipProgress = async () => {
        let userToolTipResp = await context.apis[context.user.role].getUsersToolTipProgress(
            context.validator, {}
        )
        console.log(userToolTipResp)
        if(userToolTipResp && userToolTipResp.done){
            setToolTipProgress({...userToolTipResp.response.toolTipProgress})
            setToolTipLoaded(true)
        }
    }
    // }


    const getPersonalDetails = async () =>{
        let personalDetailsResp = await context.apis[context.user.role].getSchoolNameWithUserDetails(
            context.validator, {_id: context.user.school}
        )
        if(personalDetailsResp && personalDetailsResp.done){

            setData((prevState) => {
                return {
                  ...prevState,
                  personalDetails: personalDetailsResp.response,
                  userId: context.user && context.user._id
                };
              });
            setPreviousData((prevState) => {
                return {
                  ...prevState,
                  personalDetails: personalDetailsResp.response,
                  userId: context.user && context.user._id
                };
              });
        }
        setFillPersonalDetails(true)
    }

    const getSchoolGeneralDetails = async () =>{
        let schoolGeneralDetailsReps = await context.apis[context.user.role].getSchoolGeneralDetailsBySchoolId(
            context.validator, {_id: context.user.school}
        )
        if(schoolGeneralDetailsReps && schoolGeneralDetailsReps.done){
            setData((prevState) => {
                return {
                  ...prevState,
                  schoolGeneral: schoolGeneralDetailsReps.response,
                };
              });
            setPreviousData((prevState) => {
                return {
                  ...prevState,
                  schoolGeneral: schoolGeneralDetailsReps.response,
                };
              });
        }
        setFillGeneralDetails(true)
    }

    const getSchoolAmenitiesDetails = async () =>{
        let schoolAmenitiesDetailsReps = await context.apis[context.user.role].getSchoolAmenitiesDetailsBySchoolId(
            context.validator, {_id: context.user.school}
        )
        if(schoolAmenitiesDetailsReps && schoolAmenitiesDetailsReps.done){
            console.log(schoolAmenitiesDetailsReps)
            setData((prevState) => {
                return {
                  ...prevState,
                  schoolAmenities: {...prevState.schoolAmenities, builtUpArea: schoolAmenitiesDetailsReps.response.builtUpArea,
                    floors: schoolAmenitiesDetailsReps.response.floors,
                   classrooms: schoolAmenitiesDetailsReps.response.classrooms
                   },
                };
              });
              setPreviousData((prevState) => {
                return {
                  ...prevState,
                  schoolAmenities: {...prevState.schoolAmenities, builtUpArea: schoolAmenitiesDetailsReps.response.builtUpArea,
                    floors: schoolAmenitiesDetailsReps.response.floors,
                   classrooms: schoolAmenitiesDetailsReps.response.classrooms
                   },
                };
              });
            setFillAmenitiesDetails(true)
        }
    }

    const getSchoolSpecificDetails = () => {
        context.apis[context.user.role].getInventoriesWithAttributesBySchoolId(
            context.validator, {type:"school"}
        ).then(res=>{
            if(res !== null){
                setData((prevState) => {
                    return {
                      ...prevState,
                      schoolAmenities: {...prevState.schoolAmenities, amenities: res.response}
                    };
                  });
                setPreviousData((prevState) => {
                    return {
                      ...prevState,
                      schoolAmenities: {...prevState.schoolAmenities, amenities: res.response}
                    };
                  });
                setFillSchoolSpecificDetails(true)
            }
           
        })
    }

    const getClassroomSpecificDetails = () => {
         context.apis[context.user.role].getInventoriesWithAttributesBySchoolId(
            context.validator, {type:"classroom"}
        ).then(res=>{
            if(res !== null){
                setData((prevState) => {
                    return {
                      ...prevState,
                      schoolAmenities: {...prevState.schoolAmenities, classroomAmenities: res.response}
                    };
                  });
                setPreviousData((prevState) => {
                    return {
                      ...prevState,
                      schoolAmenities: {...prevState.schoolAmenities, classroomAmenities: res.response}
                    };
                  });
                setFillClassroomSpecificDetails(true)
            }
            
        })
    }

    const getActivitiesAndStudentLifeDetails = () => {
        context.apis[context.user.role].getStudentLifeAndActivitiesBySchoolId(
            context.validator, {}
        ).then(res=>{
            if(res !== null){
                setData((prevState) => {
                    return {
                      ...prevState,
                      student: res
                    };
                  });
                setPreviousData((prevState) => {
                    return {
                        ...prevState,
                        student: res
                    };
                });
                setFillActivitiesAndStudentLifeDetails(true)
            }
            
        })
    }

    return (
        <>
            <DataContextProvider value={myValue}>
                <div className={`ovelap ${showSidemenu === true ? "active" : ""}`}></div>
                <div className="main-wapper">
                    <div className="container">
                        <div className="row">
                            <UpdateDetailsSideMenu
                            />

                            <Switch>
                                <Route path={`${router.url}/dashboard`}>
                                    <Dashboard
                                        isUpdated={isUpdated}
                                        isEdit={true}
                                        toolTipLoaded={toolTipLoaded}
                                        methods={{
                                            getPersonalDetails: getPersonalDetails,
                                            getSchoolGeneralDetails: getSchoolGeneralDetails,
                                            getSchoolAmenitiesDetails: getSchoolAmenitiesDetails,
                                            getSchoolSpecificDetails: getSchoolSpecificDetails,
                                            getClassroomSpecificDetails: getClassroomSpecificDetails,
                                            getActivitiesAndStudentLifeDetails: getActivitiesAndStudentLifeDetails
                                        }}
                                    />
                                </Route>
                            {/* 
                                <Route
                                    path={`${router.url}/details`}
                                >
                                    <Details/>
                                </Route> */}

                                <Route path={`${router.url}/my-profile`}>
                                    <MyProfile isEdit={isEdit} />
                                </Route>

                                <Route path={`${router.url}/inventory`}>
                                    <Inventory toolTipLoaded={toolTipLoaded} />
                                </Route>

                                <Route path={`${router.url}/settings`}>
                                    <Settings />
                                </Route>

                                <Route path={`${router.url}/support`}>
                                    <Support />
                                </Route>

                                <Route path={`${router.url}/my-school`}>
                                    <MySchool toolTipLoaded={toolTipLoaded} isEdit={isEdit} setRefetch={setRefetch} refetch={refetch}/>
                                </Route>
                            </Switch>
                        </div>
                    </div>
                </div>   
            </DataContextProvider>     
        </>
    )
}

export default UpdateDetails
