import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import SchoolAmenities from "../../../components/addon/summary/schoolAmenities";
import ClassBoard from "./classBoard";
interface Props {
  isEdit ? : boolean
}
const SchoolDetails = ({ editImage, schoolImage, schoolAmenities, schoolGeneral, isEdit, addSchoolFromBackend }) => {
  const [dataByClass, setDataByClass] = useState({});
  const [activeClass, setActiveClass] = useState(0);
  const history = useHistory();

  const goToSchoolAmenities = () => {
    if(addSchoolFromBackend){
      history.push({
        pathname: "/backend/backend-details/schools/onboarding/general-details",
        search: `isSummary=${true}`,
      });
    }else if(isEdit === true){
      history.push({
        pathname: "/schools/school-details/details/school-details/amenities",
        search: `isSummary=${true}`,
      });
    }else{
    history.push({
      pathname: "/schools/onboarding/general-details",
      search: `isSummary=${true}`,
    });
  }
  };

  const { classrooms, amenities } = schoolAmenities;

  const classes = classrooms.map((item) => item.class);

  const getDataByClass = (ind) => {
    const data = classrooms.find((item, index) => {
      if (index === ind) {
        return item;
      }
    });
    setActiveClass(ind);
    setDataByClass(data);
  };

  useEffect(() => {
    if (classrooms.length > 0 && classrooms[0] !== "undefined") {
      setDataByClass(classrooms[0]);
    }
  }, []);

  return (
    <>
      <div className='col-xl-12 col-lg-12 col-md-12 col-xs-12'>
        <div className='personal-box'>
          <div className='row personal-box-top'>
            <div className='row col-xl-8 col-lg-8 col-md-8 col-xs-8 start-md'>
              <h3 className='htx2 mb20'>School Details</h3>
            </div>
            <div className='row col-xl-4 col-lg-4 col-md-4 col-xs-4 end-xs'>
              <a
                href='javascript:void(0)'
                className='editbtn'
                onClick={goToSchoolAmenities}
              >
                <img src={editImage} /> Edit
              </a>
            </div>
          </div>
          <div className='personal-bottom'>
            <div className='row personal-box-bot'>
              <div className='inf-stru-img'>
                <img src={schoolImage} />
              </div>
              <div className='col-xl-12 col-lg-12 col-md-12 col-xs-12 mb20'>
                <h3 className='htx2'>Infrastructure Details</h3>
              </div>
              <div className='col-xl-6 col-lg-6 col-md-6 col-xs-12'>
                <p>Total Built-up Area</p>
              </div>
              <div className='col-xl-6 col-lg-6 col-md-6 col-xs-12'>
                <b>
                  {schoolGeneral.builtUpArea
                    ? schoolGeneral.builtUpArea + "  sq ft."
                    : null}
                </b>
              </div>
              <div className='col-xl-6 col-lg-6 col-md-6 col-xs-12'>
                <p>Number of Floors</p>
              </div>
              <div className='col-xl-6 col-lg-6 col-md-6 col-xs-12'>
                <b>{schoolGeneral.floors ? schoolGeneral.floors : "Ground Floor"}</b>
              </div>
            </div>
            <div className='class-grade'>
              <div className='class-grade-top'>
                <ul>
                  {classes.map((item, index) => {
                    return (
                      <li key={index} onClick={() => getDataByClass(index)}>
                        <a
                          href='javascript:void(0)'
                          className={
                            activeClass === index ? "active" : "complete"
                          }
                        >
                          {item}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
              {Object.keys(dataByClass).length > 0 ? (
                <ClassBoard classData={dataByClass} />
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <SchoolAmenities
        editImage={editImage}
        isEdit={isEdit}
        amenities={amenities}
        schoolAmenities={schoolAmenities}
        addSchoolFromBackend={addSchoolFromBackend}
      />
    </>
  );
};

export default SchoolDetails;
