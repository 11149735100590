import logo from "../../../../../assets/images/logo.png"
import waveBot from "../../../../../assets/images/wavebot.png"

const Thankyou = (props) => {

  return (
    <>
      <div className='ovelap'></div>
      <div className='main-wapper'>
        <div className='container'>
          <div className='row'>
            <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12'>
              <div className='thank-sign'>
                {" "}
                <img src={logo} />
                <h4 className='htx1 mb40'>Thank you for signing up with Wisr!</h4>
                <p>
                Thank you for registering with us!
                <br/>
                All your assets and information are under review to help us serve you better. 
                <br/>
                Once the review is completed, you will receive an email with your credentials on the registered email ID.
                </p>
                <a href='/' className='btn btn-orange'>
                  Explore Wisr
                </a>{" "}
              </div>
            </div>
          </div>
        </div>
        <img src={waveBot} className='poximg' />{" "}
      </div>
    </>
  )
}

export default Thankyou
