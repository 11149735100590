import React, { useEffect, useState, useContext, useRef } from 'react';
import filterArrowSvg from "../../../../assets/images/filter-arrow-svg.svg";
import config from "../../../../service/config";
import AppContextObject from "../../../../common/context/common";
import { PublicContextInitiator } from "../../../apis/publicContextInitiator";
import useClickOutside from '../../../../service/useClickOutside';

const CategoryFilterSchool = ({filterByLanguage,  setFilterByLanguage, filterByBoard, setFilterByBoard, filterBySchoolType, setFilterBySchoolType, setOpenFilter}) => {
    const context = useContext(AppContextObject);

    const [brandCategory, setBrandCategory] = useState([]);
    const [spanCategory, setSpanCategory] = useState(false);
    const [spanDocStatus, setSpanDocStatus] = useState(false);
    const [spanLocation, setSpanLocation] = useState(false);
    const [showBoardFilter, setShowBoardFilter] = useState(false)
    const [showLanguageFilter, setShowLanguageFilter] = useState(false)
    const [showSchoolTypeFilter, setShowSchoolTypeFilter] = useState(false)
    const filterRef = useRef(null)
    const filterOptionsRef = useRef(null)
    useClickOutside(filterOptionsRef, (event) => {
        const el = filterRef?.current;

        if (!el || el.contains(event.target)) {
            return;
        }
        setOpenFilter(false)
    })
    const handleAddRemoveFilter = (type, data) => {
        if(type === "board"){
            let array = [...filterByBoard]
            if(array.includes(data)){
                array.splice(array.indexOf(data), 1)
            }
            else{
                array.push(data)
            }
            setFilterByBoard([...array])
        }
        else if(type === "language"){
            let array = [...filterByLanguage]
            if(array.includes(data)){
                array.splice(array.indexOf(data), 1)
            }
            else{
                array.push(data)
            }
            setFilterByLanguage([...array])
        }
        else if(type === "schoolType"){
            let array = [...filterBySchoolType]
            if(array.includes(data)){
                array.splice(array.indexOf(data), 1)
            }
            else{
                array.push(data)
            }
            setFilterBySchoolType([...array])
        }
    }
    const showFilterDropDown = (type) => {
        if(type === "board"){
            setShowBoardFilter(!showBoardFilter)
            setShowLanguageFilter(false)
            setShowSchoolTypeFilter(false)
        }
        else if(type === "language"){
            setShowBoardFilter(false)
            setShowLanguageFilter(!showLanguageFilter)
            setShowSchoolTypeFilter(false)
        }
        else if(type === "schoolType"){
            setShowBoardFilter(false)
            setShowLanguageFilter(false)
            setShowSchoolTypeFilter(!showSchoolTypeFilter)
        }
    }
    const clearAllFilter = () => {
        setFilterBySchoolType([])
        setFilterByBoard([])
        setFilterByLanguage([])
        setShowBoardFilter(false)
        setShowLanguageFilter(false)
        setShowSchoolTypeFilter(false)
    }
    return (
        <div className="filterDropDown open">
            <div className="filterDropHead" ref={filterRef}>
                <h2>Filter <a href="javascript:void(0);" className="viewwLink"onClick={clearAllFilter} >Clear All</a></h2>
            </div>
            <div className="filterDropBody" ref={filterOptionsRef}>
                <div className={`filterDropClose ${showSchoolTypeFilter === true && "open"}`}>
                    <h3><a href="javascript:void(0);" onClick={() => showFilterDropDown("schoolType")}>{`Gender ${filterBySchoolType.length > 0 ? `(${filterBySchoolType.length})` : ""}`}  <img src={filterArrowSvg} /></a></h3>
                    <div className="filtercheckbox">
                        <div className="checkbox fnone">
                            <input type="checkbox" id="male" checked={filterBySchoolType.includes("boys")} onClick={() => handleAddRemoveFilter("schoolType", "boys")}/>
                            <label htmlFor="male">Boys</label>
                        </div>
                        <div className="checkbox fnone">
                            <input type="checkbox" id="female" checked={filterBySchoolType.includes("girls")} onClick={() => handleAddRemoveFilter("schoolType", "girls")}/>
                            <label htmlFor="female">Girls</label>
                        </div>
                        <div className="checkbox fnone">
                            <input type="checkbox" id="all" checked={filterBySchoolType.includes("co-ed")} onClick={() => handleAddRemoveFilter("schoolType", "co-ed")}/>
                            <label htmlFor="all">Co-ed</label>
                        </div>
                    </div>
                </div>
                <div className={`filterDropClose ${showBoardFilter === true && "open"}`}>
                    <h3><a href="javascript:void(0);" onClick={() => showFilterDropDown("board")}>{`Education Board ${filterByBoard.length > 0 ? `(${filterByBoard.length})` : ""}`}  <img src={filterArrowSvg} /></a></h3>
                    <div className="filtercheckbox">
                        {config.boardOptions.filter((item) => item.value.toLowerCase() !== "custom" && item.value.toLowerCase() !== "other").map((item, index) => (
                            <div className="checkbox fnone" key={index}>
                                <input type="checkbox" id={item.value} checked={filterByBoard.includes(item.value)} onClick={() => handleAddRemoveFilter("board", item.value)}/>
                                <label htmlFor={item.value}>{item.label}</label>
                            </div>
                        ))}
                    </div>
                </div>
                <div className={`filterDropClose ${showLanguageFilter === true && "open"}`}>
                    <h3><a href="javascript:void(0);" onClick={() => showFilterDropDown("language")}>{`Language of Study ${filterByLanguage.length > 0 ? `(${filterByLanguage.length})` : ""}`}  <img src={filterArrowSvg} /></a></h3>
                    <div className="filtercheckbox">
                        {config.languages.filter((item) => item.value.toLowerCase() !== "custom").map((item, index) => (
                            <div className="checkbox fnone" key={index}>
                                <input type="checkbox" id={item.value} checked={filterByLanguage.includes(item.value)} onClick={() => handleAddRemoveFilter("language", item.value)}/>
                                <label htmlFor={item.value}>{item.label}</label>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CategoryFilterSchool;