import Select from "react-select"

import errorSVG from "../../../assets/images/error.svg"
import helpImg from "../../../assets/images/help.svg"

const MultiSelect = (props) => {

    const {
        formData,
        formKey,
        formErrors,
        onDataChange,
        label,
        placeholder,
        options,
        formValidator,
        validation,
        showInfoIcon,
        infoText  ,
        isDisable    
    } = props

    const customStyles = {
        option: (provided, state) => ({
          ...provided,
          fontSize:'14px',
          padding: '13px 12px',
          backgroundColor: state.isSelected ? "#ECECEC" : state.isFocused ? '#F8F8F8' : '#fff',
          color: "#000",
        }),
    }

    const getSelectedValues = () => {
        const arr = formData[formKey].map(option => {
            return {
                _id: option._id,
                value: option.value ? option.value : option,
                label: options.length > 0 ? options.find(o => option.value ? o.value === option.value : o.value === option) && options.find(o => option.value ? o.value === option.value : o.value === option).label : ""
            }
        })
        return arr && arr.length > 0 ? arr : []
    }

    return (
        <>
        <div className={label ? showInfoIcon ? "forminpt tooltip-relative" : "forminpt" : ""}>
            {
                label &&   
                <label>
                    {label} &nbsp;
                    {showInfoIcon &&
                        <a style={{cursor: "pointer"}} title={infoText} className="tooltip"><img src={helpImg} /></a>
                    }
                </label>
            }
            <Select
                id={formKey}
                classNamePrefix="wisr-react-multi-select"
                className={formValidator?.current?.messagesShown === true && formValidator?.current?.fields?.[formKey] === false ? "onFocus" : ""}
                styles={customStyles}
                isMulti={true}
                options={options}
                onChange={onDataChange}
                closeMenuOnSelect={false}
                value={getSelectedValues()}
                placeholder={placeholder}
                noOptionsMessage={() => null}
                isDisabled={isDisable}
            />
            {formValidator?.current?.message(formKey, formData[formKey], validation)}
        </div>
        </>
    )
}

export default MultiSelect
