import React, { useState, useContext, useRef } from "react";
import useClickOutside from '../../../../../service/useClickOutside';
import { Link } from "react-router-dom";
import arrowUpDown from "../../../../../assets/images/backendAdmin/arrow-up-down.svg";
import tableFilter from "../../../../../assets/images/table-filter.svg";
import Pagination from "../../../../../components/addon/pagination";
import SchoolInventoryStatusFilter from "../SchoolInventoryStatusFilter";
import SchoolsNameCategoryFilter from "../SchoolsNameCategoryFilter";
import imgViewTwo from "../../../../../assets/images/backendAdmin/img-view-two.jpg";
import CampaignPaymentFilter from "./CampaignPaymentFilter";
import CampaignStatusFilter from "./CampaignStatusFilter";
import CampaignSchoolFilter from "./CampaignSchoolFilter";
import CampaignTableSpan from "./CampaignTableSpan";

import AppContextObject from "../../../../../common/context/common"


const CampaignTable = ({flag, campaigns, setCategory, setRegion, setStates, setLocation, category, region, states,
    location, setFilterType, filterType, setPaymentStatus, paymentStatus,
    onPageChange, onNextClick, onPrevClick, currentPage, totalPage, selectedTab,
    acceptRejectPayment, setFilterStatus, filterStatus, citiesByState, setCitiesByState, stateByRegion, setStateByRegion, setOpenShoolFilter, openShoolFilter, setOpenStatusFilter,
    openStatusFilter, setOpenPaymentFilter, openPaymentFilter, isLoading, allCount, onlyActiveCampaign, schoolId }) => {

    const ref = useRef(null);
    const context = useContext(AppContextObject)

    useClickOutside(ref, () => { setOpenShoolFilter(false); setOpenStatusFilter(false); setOpenPaymentFilter(false); })

    return (
        <div className="table-container table-inventory mt-10 fillterTableHeight">
            <table className="table">
                <thead>
                    <tr ref={ref}>
                        <th>SL No</th>
                        <th>Campaign Name</th>
                        <th>Brand Name</th>
                        <th className="p-relative">schools <a className="openFilter" href="javascript:void(0);" onClick={() => setOpenShoolFilter(!openShoolFilter)}><img src={tableFilter} /></a>
                            {!(onlyActiveCampaign === true && context.user.role === "backendFOS") &&
                                <CampaignSchoolFilter
                                    setOpenShoolFilter={setOpenShoolFilter}
                                    openShoolFilter={openShoolFilter}
                                    setCategory={setCategory}
                                    setRegion={setRegion}
                                    setStates={setStates}
                                    setLocation={setLocation}
                                    category={category}
                                    region={region}
                                    states={states}
                                    location={location}
                                    citiesByState={citiesByState}
                                    setCitiesByState={setCitiesByState}
                                    stateByRegion={stateByRegion}
                                    setStateByRegion={setStateByRegion}
                                />
                            }
                        </th>
                        <th>inventories</th>
                        <th>schedule</th>
                        <th>created</th>
                        <th>Budget</th>
                        <th>Sales Representative</th>
                        <th className="p-relative">Status {(selectedTab === 'all' || selectedTab === 'pending') && <a className="openFilter" href="javascript:void(0);" onClick={() => setOpenStatusFilter(!openStatusFilter)}><img src={tableFilter} /></a>}
                            {(selectedTab === 'all' || selectedTab === 'pending') && !(onlyActiveCampaign === true && context.user.role === "backendFOS") &&
                                <CampaignStatusFilter
                                    openStatusFilter={openStatusFilter}
                                    setFilterType={setFilterType}
                                    filterType={filterType}
                                    setFilterStatus={setFilterStatus}
                                    filterStatus={filterStatus}
                                    nonWisrCampaign={false}
                                />}
                        </th>
                        <th className="p-relative">PAYMENT Status {selectedTab !== 'pending' && <a href="javascript:void(0);" onClick={() => setOpenPaymentFilter(!openPaymentFilter)}><img src={tableFilter} /></a>}
                            {selectedTab !== 'pending' && !(onlyActiveCampaign === true && context.user.role === "backendFOS") && <CampaignPaymentFilter
                                openPaymentFilter={openPaymentFilter}
                                setPaymentStatus={setPaymentStatus}
                                paymentStatus={paymentStatus}
                            />}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {!isLoading ?
                        <>
                            {campaigns && campaigns.length > 0 ?
                                <>
                                    {campaigns.map((campaign, key) => {
                                        return <CampaignTableSpan
                                            campaign={campaign}
                                            key={key}
                                            keyNo={key + 1}
                                            currentPage={currentPage}
                                            acceptRejectPayment={acceptRejectPayment}
                                            flag={flag}
                                            onlyActiveCampaign={onlyActiveCampaign}
                                            schoolId={schoolId}
                                        />
                                    })
                                    }
                                </>
                                :
                                <tr><td colSpan={11}>No Campaign found</td></tr>
                            }
                        </>
                        :
                        [...Array(10)].map((el, index) => {
                            return (
                                <tr>
                                    {
                                        [...Array(11)].map((el, index) => {
                                            return (
                                                <td key={index}>
                                                    <div className="content-loader-block"></div>
                                                </td>
                                            )
                                        })
                                    }
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
            {totalPage.length > 1 && <Pagination
                currentPage={currentPage}
                totalPage={totalPage}
                onPageChange={onPageChange}
                onNextClick={onNextClick}
                onPrevClick={onPrevClick}
                loading={isLoading}
            />}
        </div>

    )
}
export default CampaignTable;

