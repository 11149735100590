import React, { useContext, useState } from "react";
import { PublicContextInitiator } from '../../../apis/publicContextInitiator';
import AppContextObject from '../../../../common/context/common';

const BrandDocsApprovalModal = (props) => {
    const context = useContext(AppContextObject);
    const [approvingDocument, setApprovingDocument] = useState(false)
    const {
        showConfirmedApprovalModal,
        closeModal,
        docType,
        setOverlay,
        brandDocumentDetails,
        brandId,
        updateBrandDetails,
        setUpdateBrandDetails
    } = props
    const approvedDocs = async (docType) => {
        console.log(docType);
        let gstStatus;
        let panStatus;
        if (docType === 'gst') {
            gstStatus = 'accepted';
            panStatus = brandDocumentDetails.pan.status;
        } else {
            gstStatus = brandDocumentDetails.gst.status;
            panStatus = 'accepted';
        }
        console.log("brandDocumentDetails", brandDocumentDetails, docType)
        let approvalDocs = {
            gst: {
                file: brandDocumentDetails.gst.file && brandDocumentDetails.gst.file.length !== 0 ? brandDocumentDetails.gst.file[0].key : "",
                number: brandDocumentDetails.gst.number,
                status: gstStatus
            },
            pan: {
                file: brandDocumentDetails.pan.file && brandDocumentDetails.pan.file.length !== 0 ? brandDocumentDetails.pan.file[0].key : "",
                number: brandDocumentDetails.pan.number,
                status: panStatus
            }

        }
        console.log(brandId)
        setApprovingDocument(true)
        let resp = await context.apis[context.user.role].updateBrandDocumentStatus(
            context.validator, { _id: brandId, statusAction: 'approve' }, { documents: approvalDocs }
        )
        console.log('resp======>', resp)

        if (resp && resp.done) {
            setApprovingDocument(false)
        }
        console.log(approvalDocs)
        closeModal();
        setOverlay(false);
        setUpdateBrandDetails(!updateBrandDetails);
    }
    return (
        <div id="confirmedApprovalModal" className={`popup popup-center ${showConfirmedApprovalModal === false ? "hide" : ""}`}>
            <div className="popup-header">
                <h3 className="htx2 pophead">Brand Document Approval</h3>
                <span className="close toggle" onClick={() => { closeModal(); setOverlay(false); }} data-target="myPopup">close</span>
            </div>
            <div className="popup-body">
                <div className="viewdetailsHead">
                    <div className="approveActive">
                        <p>{docType === 'pan' ? 'PAN card details approved.' : 'GST details approved.'}
                        </p>
                        <div className="brand-btn">
                            <a onClick={() => approvedDocs(docType)} href="javascript:void(0)" className={`btn btn-orange ${approvingDocument && "button--loading btn-disabled"}`}>Done</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BrandDocsApprovalModal
