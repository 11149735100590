import {
    useLocation,
    useRouteMatch,
    Link
} from "react-router-dom"

import logo from "../../../.././assets/images/logo.png"
import waveBootNew from "../../../.././assets/images/wavebotnew.png"
import check from "../../../.././assets/images/check.svg"

const BrandProfileSidemenu = (props) => {

    const {
        progress,
        activeModule
    } = props

    const location = useLocation()
    const router = useRouteMatch()

    return (
        <>
            <div className="col-xl-3 col-lg-3 col-md-12 col-xs-12 pl-0 pr-0">
                <div className={`sliderbar onboarding-bar newMvp-sidebar  campaignProgress`}>
                    <div className="row logo between-xs bottom-xs logoNew">
                        <img src={logo} alt="logo" />
                        </div>
                        <ul>
                            <li>
                                <Link
                                    to={`${router.url}/general`}
                                    className={
                                        `${activeModule.module === "general" && "active"}
                                        ${progress?.general === true && "complete"}`
                                    }
                                >
                                    <span>1</span> General
                                </Link>
                            </li>

                            <li>
                                <Link
                                    to={`${router.url}/description`}
                                    className={
                                        `${activeModule.module === "description" && "active"}
                                        ${progress?.description === true && "complete"} 
                                        ${progress?.general === true ? "" : "disabledLink"}`
                                    }
                                >
                                    <span>2</span> Description
                                </Link>
                            </li>

                            <li>
                                <Link
                                    to={`${router.url}/contact`}
                                    className={
                                        `${activeModule.module === "contact" && "active"}
                                        ${progress?.contact === true && "complete"}
                                        ${progress?.description === true ? "" : "disabledLink"}`
                                    }
                                >
                                    <span>3</span> Contact
                                </Link>
                            </li>

                            <li>
                                <Link
                                    to={`${router.url}/verification`}
                                    className={
                                        `${activeModule.module === "verification" && "active"}
                                        ${progress?.verification === true && "complete"}
                                        ${progress?.contact === true ? "" : "disabledLink"}`
                                    }
                                >
                                    <span>4</span> Verification
                                </Link>
                            </li>

                            <li>
                                <Link
                                    to={`${router.url}/add-users`}
                                    className={
                                        `${activeModule.module === "addUsers" && "active"}
                                        ${progress?.addUsers === true && "complete"}
                                        ${progress?.verification === true ? "" : "disabledLink"}`
                                    }
                                >
                                    <span>5</span> Add Users
                                </Link>
                            </li>
                        </ul>
                        <img src={waveBootNew} className="wavebot" alt="wave" />
                    
            </div>
            </div>
        </>
    )
}

export default BrandProfileSidemenu
