import React, { useEffect, useState, useContext, useRef } from "react";
import { useHistory } from "react-router-dom";
import AppContextObject from "../../../../common/context/common";
import { PublicContextInitiator } from "../../../apis/publicContextInitiator";
import { BackendAdminContextInitiator } from "../../../../backendAdminSrc/apis/backendAdminContextInitiator";
import arrowUpDown from "../../../../assets/images/backendAdmin/arrow-up-down.svg";
import { formatDateShowMonthBackendFOS, returnReadableStatus, titleCase } from "../../../../service/helper";
import Pagination from "../../../../components/addon/pagination";
import moment from "moment";
import BackendTopBar from "../components/backendTopBar";
import BackendBackBtn from "../components/backendBackBtn";
import SingleSelect from "../../../../components/formFields/singleSelect/singleSelect";
import config from "../../../../service/config";
import CustomSearchInput from "../../../../components/formFields/customSearchInput";
import filterSvg from "../../../../assets/images/filter-svg.svg"
import filterArrowSvg from "../../../../assets/images/filter-arrow-svg.svg"
import tableFilter from "../../../../assets/images/table-filter.svg";
import SchoolStatusFilter from "../components/schoolStatusFilter";
import SchoolInventoryStatusFilter from "../components/SchoolInventoryStatusFilter";
import useClickOutside from "../../../../service/useClickOutside";
import { downloadFile} from '../../../../service/helper';
import downloadSvg from "../../../../assets/images/backendAdmin/download-svg.svg";
import { ExportToCsv } from 'export-to-csv';
import Loader from "../../../../components/addon/loader";
// import DisableRightClick from "../../brands/DisableRightClick";
// import hotkeys from 'hotkeys-js';
const DetailsFOS = ({onlyActiveSchools}) => {

    // hotkeys('shift+ctrl+c, shift+command+c', function(event) {
    //     event.preventDefault();
    //     return false;
    //   });
    
    //   hotkeys('ctrl+c, command+c', function(event) {
    //     event.preventDefault();
    //     return false;
    //   });
    
    //   hotkeys('ctrl+u, command+u', function(event) {
    //     event.preventDefault();
    //     return false;
    //   });

    const history = useHistory();
    const context = useContext(AppContextObject);
    const inventoryFilterRef = useRef()
    const schoolFilterRef = useRef()
    useClickOutside(inventoryFilterRef, () => { setOpenShoolInventoryFilter(false) })
    useClickOutside(schoolFilterRef, () => { setOpenShoolStatusFilter(false) })

    const [searchInpuOpen, setSearchInpuOpen] = useState(false);
    const [clearSearchIcon, setClearSearchIcon] = useState(false);
    const [openFilter, setOpenFilter] = useState(false);
    const [openStateFilter, setOpenStateFilter] = useState(false)
    const [openCityFilter, setOpenCityFilter] = useState(false)
    const [openGenderFilter, setOpenGenderFilter] = useState(false);
    const [openBoardFilter, setOpenBoardFilter] = useState(false);
    const [openLanguageFilter, setOpenLanguageFilter] = useState(false);
    const [filterCity, setFilterCity] = useState([]);
    const [filterGender, setFilterGender] = useState([]);
    const [filterBoard, setFilterBoard] = useState([]);
    const [filterLanguage, setFilterLanguage] = useState([]);
    const [cityStateData, setCityStateData] = useState({})
    const [stateList, setStateList] = useState([])
    const [selectedState, setSelectedState] = useState([])
    const [cityList, setCityList] = useState([])

    const [accountChange, setAccountChange] = useState(false);
    const [schoolList, setSchoolList] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPage, setTotalPage] = useState([0])
    const [searchValue, setSearchValue] = useState("")
    const [dataPerPage, setDataPerPage] = useState(10)
    const [loading, setLoading] = useState(false)
    const [openShoolInventoryFilter, setOpenShoolInventoryFilter] = useState(false);
    const [openShoolStatusFilter, setOpenShoolStatusFilter] = useState(false);
    const [inventoryStatus, setInventoryStatus] = useState([]);
    const [schoolStatus, setSchoolStatus] = useState([]);
    const [campaignId, setCampaignId] = useState<any>()
    const [loader, setLoader] = useState(false)
    const [formData, setFormData] = useState({
        fullName: "",
        email: "",
        region: "",
        city: "",
        phone: "",
        activeccountStatus: 'active'
    })
    const [downloadSchoolLoading, setDownloadSchoolLoading] = useState(false);
    // console.log(history.location.state['userId'])
    useEffect(() => {
        if (history.location.state) {
            let data: any = history.location.state
            if (data.email) {
                fetchAllSchoolList(data.email)
            }
            else{
                fetchAllSchoolList()
            }
            if(data.from && data.from === "activeCampaign"){
                setCampaignId(data.campaignId)
            }
        }
        else {
            fetchAllSchoolList()
        }
    }, [])
    useEffect(() => {
        if (history.location.state) {
            let data: any = history.location.state
            if (data.email) {
                fetchSchoolListByEmail(data.email)
            }
            else{
                fetchSchoolListByEmail()
            }
        }
        else {
            fetchSchoolListByEmail()
        }
    }, [currentPage, searchValue, filterBoard, filterLanguage, filterGender, filterCity, selectedState, inventoryStatus, schoolStatus])
    useEffect(() => {
        if (currentPage !== 1) {
            setCurrentPage(1)
        }
    }, [searchValue, filterBoard, filterLanguage, filterGender, filterCity, selectedState, inventoryStatus, schoolStatus])
    useEffect(() => {
        if(onlyActiveSchools === false){
            fetchUser();
        }
    }, [])
    useEffect(() => {
        if (accountChange) {
            updateUserAccountStatus();
        }
    }, [formData.activeccountStatus])
    useEffect(() => {
        fetchCityByState()
    }, [selectedState])
    const fetchCityByState = async() => {
        let city = []
        selectedState.forEach(state => {
            city = [...city, ...cityStateData[state]]
        })
        setCityList([...city])
    }
    const onPageChange = (page) => {
        setCurrentPage(page)
    }
    const onNextClick = () => {
        setCurrentPage(prev => prev + 1)
    }
    const onPrevClick = () => {
        setCurrentPage(prev => prev - 1)
    }
    const fetchSchoolListByEmail = async (email?) => {
        setLoading(true)
        let resp
        let state:any = history.location.state
        if(state && state?.from === "activeCampaign" && state?.campaignId){
            const backendContextInitiator = new BackendAdminContextInitiator(context)
            resp = await backendContextInitiator.getAllCampaignSchools(
                context.validator, { campaignId: state.campaignId, searchValue: searchValue, city: filterCity, language: filterLanguage, schoolType: filterGender, board: filterBoard, state: selectedState}, {page: currentPage, limit: 10, withCount: true, sort: {_id: -1}}
            )
        }
        else{
            resp = await context.apis[context.user.role].getSchoolsByFOS(
                context.validator, { fosEmail: email || context.user.email, searchValue: searchValue, city: filterCity, language: filterLanguage, schoolType: filterGender, board: filterBoard, state: selectedState, inventoryStatus: inventoryStatus, schoolStatus: [...schoolStatus.map(item => item === "approved")]}, { page: currentPage, limit: dataPerPage, withCount: true, sort: { _id: -1 } }
            )
        }
        console.log("getSchoolsByFOS", resp)
        if (resp && resp.done) {
            setSchoolList([...resp.response.rows])
            let count = Math.ceil(resp.response.count / dataPerPage)
            let page = []
            for (let i = 0; i < count; i++) {
                page.push(i)
            }
            setTotalPage([...page])
        }
        setLoading(false)
    }
    const fetchAllSchoolList = async (email?) => {
        let resp
        let state:any = history.location.state
        if(state && state?.from === "activeCampaign" && state?.campaignId){
            const backendContextInitiator = new BackendAdminContextInitiator(context)
            resp = await backendContextInitiator.getAllCampaignSchools(
                context.validator, { campaignId: state.campaignId }, {}
            )
        }
        else{
            resp = await context.apis[context.user.role].getSchoolsByFOS(
                context.validator, { fosEmail: email || context.user.email }, {}
            )
        }
        console.log("getSchoolsByFOS", resp)
        if (resp && resp.done) {
            let states = [], cityStateObj = {}
            resp.response.rows.forEach(school => {
                if(!states.includes(school.state)){
                    states.push(school.state)
                }
                if(cityStateObj[school.state]){
                    if(!cityStateObj[school.state].includes(school.city)){
                        cityStateObj[school.state].push(school.city)
                    }
                }
                else{
                    cityStateObj[school.state] = [school.city]
                }
            })
            console.log("first", states, cityStateObj)
            setStateList([...states])
            setCityStateData({...cityStateObj})
        }
    }
    const fetchUser = async () => {
        let publicContextInitiator = new PublicContextInitiator(context)
        let resp = await publicContextInitiator.getUserById(
            context.validator, { _id: context.user.role === "backendFOS" ? context.user._id : history.location.state['userId'] }, {}
        )
        console.log('getUserById', resp)

        if (resp && resp.done) {
            setFormData({
                fullName: resp.response.rows[0].fullName,
                email: resp.response.rows[0].email,
                region: resp.response.rows[0].region,
                city: resp.response.rows[0].city,
                phone: resp.response.rows[0].phone,
                activeccountStatus: resp.response.rows[0].accountStatus
            })
        }
    }
    const updateUserAccountStatus = async () => {
        let publicContextInitiator = new PublicContextInitiator(context)
        let resp = await publicContextInitiator.updateAccountStatusOfBackendFOS(
            context.validator, { _id: context.user.role === "backendFOS" ? context.user._id : history.location.state['userId'] }, { accountStatus: formData.activeccountStatus }
        )
        // console.log(resp)
    }
    const redirectToSchoolOnboarding = () => {
        // console.log(history.location,context.user._id)
        history.push({ pathname: "/backend/backend-details/schools/onboarding/your-details", state: { isFromFosTable: true, fosUserId: context.user.role === "backendFOS" ? context.user._id : history.location.state['userId'], fosUserEmail: formData.email, fosUserPhone: formData.phone } })
    }
    const filteration = (category, value) => {
        console.log(category, value)
        if (category === 'state') {
            let array = [...selectedState]
            if (array.includes(value)) {
                array.splice(array.indexOf(value), 1)
                if(filterCity.length !== 0){
                    let citiesToRemove = [...cityStateData[value]], newCitiesData = [...filterCity]
                    for(let city of citiesToRemove){
                        if(newCitiesData.indexOf(city) !== -1){
                            newCitiesData.splice(newCitiesData.indexOf(city), 1)
                        }
                    }
                    setFilterCity([...newCitiesData])
                }
            }
            else {
                array.push(value)
                setFilterCity([...filterCity,...cityStateData[value]])
            }
            setSelectedState([...array])
        }
        else if (category === 'city') {
            let array = [...filterCity]
            if (array.includes(value)) {
                array.splice(array.indexOf(value), 1)
            }
            else {
                array.push(value)
            }
            setFilterCity([...array])
        }
        else if (category === 'gender') {
            let array = [...filterGender]
            if (array.includes(value)) {
                array.splice(array.indexOf(value), 1)
            }
            else {
                array.push(value)
            }
            setFilterGender([...array])

        }
        else if (category === 'board') {
            let array = [...filterBoard]
            if (array.includes(value)) {
                array.splice(array.indexOf(value), 1)
            }
            else {
                array.push(value)
            }
            setFilterBoard([...array])

        }
        else if (category === 'language') {
            let array = [...filterLanguage]
            if (array.includes(value)) {
                array.splice(array.indexOf(value), 1)
            }
            else {
                array.push(value)
            }
            setFilterLanguage([...array])

        }


    }
    const clearAllFilters = () => {
        setFilterCity([]);
        setFilterGender([]);
        setFilterBoard([]);
        setFilterLanguage([]);
        setSelectedState([]);
        setOpenFilter(false)
        setOpenBoardFilter(false)
        setOpenStateFilter(false)
        setOpenCityFilter(false)
        setOpenLanguageFilter(false)
    }
    const handleSearch = (e) => {
        if(e === ""){
            setSearchValue("")
        }
        else{
            setSearchValue(e.target.value)
        }
    }

    const getExportableDataFosDetail = async () => {

        console.log("--getExportableDataFosDetail---check here==new==>")
        setDownloadSchoolLoading(true);
        setLoader(true)
        
        let resp = await context.apis[context.user.role].getSchoolsByFOS(
            context.validator, 
            { 
                //fosEmail: context.user.email, 
                fosEmail: formData.email, 
                searchValue: searchValue, city: filterCity, language: filterLanguage, schoolType: filterGender, board: filterBoard, state: selectedState, inventoryStatus: inventoryStatus, schoolStatus: [...schoolStatus.map(item => item === "approved")],
            }, 
                { 
                  //page: currentPage, 
                  //limit: dataPerPage, 
                  withCount: true, sort: { _id: -1 } 
                }
        )
        console.log("getSchoolsByFOS   getExportableDataFosDetail", resp)
        let data=[]
        if (resp && resp.done) {
        await resp.response.rows.forEach((schoolData,index) => {
            let schoolDetails={}
            schoolDetails['SL NO'] = ++index;
            schoolDetails['SCHOOL NAME']=schoolData.schoolName;
            schoolDetails['BOARD']=schoolData.board.board;
            schoolDetails['CATEGORY']=schoolData.category;
            schoolDetails['STATE']=schoolData.state;
            schoolDetails['CITY']=schoolData.city;
            schoolDetails['Address']=schoolData.address;
            schoolDetails['STUDENTS']=schoolData.totalStudents;
            schoolDetails['INVENTORIES']=schoolData.schoolInventories.length;
            schoolDetails['EVENTS & OCCASIONS']=schoolData.schoolActivities.length;
            schoolDetails['SCHOOL STATUS']=schoolData.initialApproval ? "Approved" : "New Added";
            schoolDetails['INVENTORY STATUS']=schoolData.collectiveInventoryAndEventStatus;
            schoolDetails['ADDED ON']=schoolData.addedOn && formatDateShowMonthBackendFOS(moment(schoolData.addedOn).format("DD MMM YYYY"), 4, true);
            data.push(schoolDetails);
        });

        const options = { 
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showTitle: false,
            filename:'FOS_SchoolsList',
            useTextFile: false,
            useKeysAsHeaders: true,
            //headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
        };
        setLoader(false)
        setDownloadSchoolLoading(false);
        const csvExporter = new ExportToCsv(options);
        csvExporter.generateCsv(data);

        }
    }

    return (
        <>
        
        <div className={`ovelap`}></div>
            {
                loader === true ?
                <Loader /> 
                :
                    <div className="col-xl-10 col-lg-12 col-md-12 col-xs-12 mvFosDetailContainer">
                        <div className="rightPart noBack mobilePadding">
                            <div className="row">

                                <BackendTopBar from={onlyActiveSchools ? null : formData.fullName} redirectPage={onlyActiveSchools ? campaignId ? true : false : true} />
                                {/* <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-10"><h2 className="htx1 clearfix"> {formData.fullName} </h2></div> */}
                                {onlyActiveSchools === false &&
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-10">
                                        <div className="row item-center mobileViewFosDetail">
                                            <div className="col-xl-3 col-lg-3 col-md-6 col-xs-12 mb-20 pl mt-10">
                                                <div className="schoolNameTable detailsFosList">
                                                    <span>{formData.region}</span>
                                                    <span>{formData.city}</span>
                                                </div>
                                            </div>
                                            <div className="col-xl-3 col-lg-3 col-md-6 col-xs-12 mb-20 pl mt-10">
                                                <div className="schoolNameTable detailsFosList">
                                                    <h2>Contact Number : <b>+91 {formData.phone}</b></h2>
                                                </div>
                                            </div>
                                            <div className="col-xl-3 col-lg-3 col-md-6 col-xs-12 mb-20 pl mt-10">
                                                <div className="schoolNameTable detailsFosList" style={{wordBreak: "break-word"}}>
                                                    <h2>Email ID : <b>{formData.email}</b></h2>
                                                </div>
                                            </div>
                                            <div className="col-xl-3 col-lg-3 col-md-6 col-xs-12 mb-20 pl mt-10 end-xs">
                                                <div className='flexInlineLeft foscustomeselect'>
                                                    <label>Account Status</label>
                                                    <SingleSelect
                                                        formData={formData}
                                                        formKey='activeccountStatus'
                                                        onDataChange={(data) => { setFormData({ ...formData, activeccountStatus: data.value }); setAccountChange(true) }}
                                                        // label='Account Status'
                                                        // placeholder='Select Inventory Category'
                                                        options={config.fosAccountStatus}
                                                        isDisable={!(context.user.role === "backendAdmin" || context.user.role === "backendOperations")}
                                                    />
                                                </div>
                                                {/* <div className="schoolNameTable detailsFosList">
                                                <SingleSelect
                                                    formData={formData}
                                                    formKey='activeccountStatus'
                                                    onDataChange={(data) => { setFormData({ ...formData, activeccountStatus: data.value }); setAccountChange(true) }}
                                                    label='Account Status'
                                                    // placeholder='Select Inventory Category'
                                                    options={config.fosAccountStatus}
                                                    isDisable={!(context.user.role === "backendAdmin" || context.user.role === "backendOperations")}
                                                />
                                                </div> */}
                                                {/* <div className="schoolNameTable detailsFosList">
                                                    <h2>Account Status
                                                        <select disabled={!(context.user.role === "backendAdmin" || context.user.role === "backendOperations")} onChange={(e) => { setFormData({ ...formData, activeccountStatus: e.target.value }); setAccountChange(true) }}>
                                                            <option value={'active'} selected={formData.activeccountStatus === 'active' ? true : false}>Active</option>
                                                            <option value={'inactive'} selected={formData.activeccountStatus === 'inactive' ? true : false}>Inactive</option>
                                                        </select>
                                                    </h2>
                                                </div> */}
                                            </div>
                                        </div>
                                        <div className="clearfix line-full mb-20"></div>
                                    </div>
                                }


                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                    <h2 className="htx2 clearfix">{onlyActiveSchools && campaignId ? "List of Schools" : 'List of School Onboarded'} </h2> 
                                </div>
                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                    <div className="inventory-tableTabs">
                                        <div className="schoolFilter crFosDetailTab" style={{flexDirection: "row-reverse"}}>
                                            {(context.user.role === "backendAdmin" || context.user.role === "backendTech" || context.user.role === "backendOperations" || context.user.role === "backendFOS") && onlyActiveSchools === false && <a href="javascript:void(0);" className="btn btn-orange spaceL mobileViewAddSchool" onClick={redirectToSchoolOnboarding}>+ Add School</a>}
                                            {(context.user.role === "backendAdmin" || context.user.role === "backendOperations" || context.user.role === "backendTech" ||context.user.role === "backendFOS") && onlyActiveSchools === false && <a href='javascript:void(0)' className={`downloadSvg mobileViewDownloadSchool ${downloadSchoolLoading ? 'btn-disabled btn-opacity' : ''}`} style={{marginLeft: '10px'}} onClick={getExportableDataFosDetail} ><img src={downloadSvg} /></a>}
                                            <CustomSearchInput
                                                searchInpuOpen={searchInpuOpen}
                                                setSearchInpuOpen={setSearchInpuOpen}
                                                clearSearchIcon={clearSearchIcon}
                                                setClearSearchIcon={setClearSearchIcon}
                                                searchKeys={searchValue}
                                                handleChange={handleSearch}
                                                palaceholder="School name"
                                            />
                                            <div className="filterbox">
                                                <a href="javascript:void(0);" className="filterDrop" onClick={() => setOpenFilter(!openFilter)}>
                                                    <img src={filterSvg} />
                                                    <h3>Filter</h3>
                                                    <img src={filterArrowSvg} />
                                                </a>

                                                <div className={`filterDropDown ${openFilter ? 'open' : ''}`}>
                                                    <div className="filterDropHead">
                                                        <h2>Filter <a href="javascript:void(0);" className="viewwLink" onClick={clearAllFilters}>Clear All</a></h2>
                                                    </div>
                                                    <div className="filterDropBody">
                                                        <div className={`filterDropClose ${openStateFilter ? 'open' : ''}`}>
                                                            <h3><a href="javascript:void(0);" onClick={() => setOpenStateFilter(!openStateFilter)}>{`State ${selectedState.length > 0 ? `(${selectedState.length})` : ""}`}  <img src={filterArrowSvg} /></a></h3>
                                                            <div className="filtercheckbox">
                                                                {stateList.map((item, index) => (
                                                                    <div className="checkbox fnone" key={index} >
                                                                        <input type="checkbox" id={item} checked={selectedState.includes(item)} />
                                                                        <label htmlFor={item} onClick={() => filteration('state', item)}>{titleCase(item)}</label>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                        <div className={`filterDropClose ${openCityFilter && selectedState.length > 0 ? 'open' : ''}`}>
                                                            <h3><a href="javascript:void(0);" onClick={() => setOpenCityFilter(!openCityFilter)}>{`City ${filterCity.length > 0 ? `(${filterCity.length})` : ""}`}  <img src={filterArrowSvg} /></a></h3>
                                                            <div className="filtercheckbox">
                                                                {cityList.map((item, index) => (
                                                                    <div className="checkbox fnone" key={index} >
                                                                        <input type="checkbox" id={item} checked={filterCity.includes(item)} />
                                                                        <label htmlFor={item} onClick={() => filteration('city', item)}>{titleCase(item)}</label>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                        <div className={`filterDropClose ${openBoardFilter ? 'open' : ''}`}>
                                                            <h3><a href="javascript:void(0);" onClick={() => setOpenBoardFilter(!openBoardFilter)} >{`Education Board ${filterBoard.length > 0 ? `(${filterBoard.length})` : ""}`}  <img src={filterArrowSvg} /></a></h3>
                                                            <div className="filtercheckbox">
                                                                {config.boardOptions.filter((item) => item.value.toLowerCase() !== "custom" && item.value.toLowerCase() !== "other").map((item, index) => (
                                                                    <div className="checkbox fnone" key={index}>
                                                                        <input type="checkbox" id={item.value} checked={filterBoard.includes(item.value)} />
                                                                        <label htmlFor={item.value} onClick={() => filteration('board', item.value)}>{item.label}</label>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                        <div className={`filterDropClose ${openLanguageFilter ? 'open' : ''}`}>
                                                            <h3><a href="javascript:void(0);" onClick={() => setOpenLanguageFilter(!openLanguageFilter)}>{`Language of Study ${filterLanguage.length > 0 ? `(${filterLanguage.length})` : ""}`}  <img src={filterArrowSvg} /></a></h3>
                                                            <div className="filtercheckbox">
                                                                {config.languages.filter((item) => item.value.toLowerCase() !== "custom").map((item, index) => (
                                                                    <div className="checkbox fnone" key={index}>
                                                                        <input type="checkbox" id={item.value} checked={filterLanguage.includes(item.value)} />
                                                                        <label htmlFor={item.value} onClick={() => filteration('language', item.value)}>{item.label}</label>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="schoolFilter crFosDetailTabMobileView" style={{flexDirection: "column", flexWrap: "wrap"}}>
                                            <div style={{display: "flex", justifyContent: "space-between", marginBottom: "20px"}}>
                                                <CustomSearchInput
                                                    searchInpuOpen={searchInpuOpen}
                                                    setSearchInpuOpen={setSearchInpuOpen}
                                                    clearSearchIcon={clearSearchIcon}
                                                    setClearSearchIcon={setClearSearchIcon}
                                                    searchKeys={searchValue}
                                                    handleChange={handleSearch}
                                                    palaceholder="School name"
                                                />
                                                <div className="filterbox" style={{margin: "0"}}>
                                                    <a href="javascript:void(0);" className="filterDrop" onClick={() => setOpenFilter(!openFilter)}>
                                                        <img src={filterSvg} />
                                                        <h3>Filter</h3>
                                                        <img src={filterArrowSvg} />
                                                    </a>

                                                    <div className={`filterDropDown ${openFilter ? 'open' : ''}`}>
                                                        <div className="filterDropHead">
                                                            <h2>Filter <a href="javascript:void(0);" className="viewwLink" onClick={clearAllFilters}>Clear All</a></h2>
                                                        </div>
                                                        <div className="filterDropBody">
                                                            <div className={`filterDropClose ${openStateFilter ? 'open' : ''}`}>
                                                                <h3><a href="javascript:void(0);" onClick={() => setOpenStateFilter(!openStateFilter)}>{`State ${selectedState.length > 0 ? `(${selectedState.length})` : ""}`}  <img src={filterArrowSvg} /></a></h3>
                                                                <div className="filtercheckbox">
                                                                    {stateList.map((item, index) => (
                                                                        <div className="checkbox fnone" key={index} >
                                                                            <input type="checkbox" id={item} checked={selectedState.includes(item)} />
                                                                            <label htmlFor={item} onClick={() => filteration('state', item)}>{titleCase(item)}</label>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                            <div className={`filterDropClose ${openCityFilter && selectedState.length > 0 ? 'open' : ''}`}>
                                                                <h3><a href="javascript:void(0);" onClick={() => setOpenCityFilter(!openCityFilter)}>{`City ${filterCity.length > 0 ? `(${filterCity.length})` : ""}`}  <img src={filterArrowSvg} /></a></h3>
                                                                <div className="filtercheckbox">
                                                                    {cityList.map((item, index) => (
                                                                        <div className="checkbox fnone" key={index} >
                                                                            <input type="checkbox" id={item} checked={filterCity.includes(item)} />
                                                                            <label htmlFor={item} onClick={() => filteration('city', item)}>{titleCase(item)}</label>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                            <div className={`filterDropClose ${openBoardFilter ? 'open' : ''}`}>
                                                                <h3><a href="javascript:void(0);" onClick={() => setOpenBoardFilter(!openBoardFilter)} >{`Education Board ${filterBoard.length > 0 ? `(${filterBoard.length})` : ""}`}  <img src={filterArrowSvg} /></a></h3>
                                                                <div className="filtercheckbox">
                                                                    {config.boardOptions.filter((item) => item.value.toLowerCase() !== "custom" && item.value.toLowerCase() !== "other").map((item, index) => (
                                                                        <div className="checkbox fnone" key={index}>
                                                                            <input type="checkbox" id={item.value} checked={filterBoard.includes(item.value)} />
                                                                            <label htmlFor={item.value} onClick={() => filteration('board', item.value)}>{item.label}</label>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                            <div className={`filterDropClose ${openLanguageFilter ? 'open' : ''}`}>
                                                                <h3><a href="javascript:void(0);" onClick={() => setOpenLanguageFilter(!openLanguageFilter)}>{`Language of Study ${filterLanguage.length > 0 ? `(${filterLanguage.length})` : ""}`}  <img src={filterArrowSvg} /></a></h3>
                                                                <div className="filtercheckbox">
                                                                    {config.languages.filter((item) => item.value.toLowerCase() !== "custom").map((item, index) => (
                                                                        <div className="checkbox fnone" key={index}>
                                                                            <input type="checkbox" id={item.value} checked={filterLanguage.includes(item.value)} />
                                                                            <label htmlFor={item.value} onClick={() => filteration('language', item.value)}>{item.label}</label>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{display: "flex", justifyContent: "space-between"}}>
                                                {(context.user.role === "backendAdmin" || context.user.role === "backendOperations" || context.user.role === "backendTech" ||context.user.role === "backendFOS") && onlyActiveSchools === false && <a href='javascript:void(0)' className={`downloadSvg mobileViewDownloadSchool ${downloadSchoolLoading ? 'btn-disabled btn-opacity' : ''}`} style={{marginRight: '10px'}} onClick={getExportableDataFosDetail} ><img src={downloadSvg} /></a>}
                                                {(context.user.role === "backendAdmin" || context.user.role === "backendTech" || context.user.role === "backendOperations" || context.user.role === "backendFOS") && onlyActiveSchools === false && <a href="javascript:void(0);" className="btn btn-orange spaceL mobileViewAddSchool" onClick={redirectToSchoolOnboarding}>+ Add School</a>}   
                                            </div>
                                        </div>
                                
                                        <div className="table-container table-inventory mt-10 fillterTableHeight">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th>Sl no</th>
                                                        <th>school name</th>
                                                        <th>students</th>
                                                        <th>inventories</th>
                                                        <th>events & occasions</th>
                                                        <th ref={schoolFilterRef} className="p-relative">School Status <a href="javascript:void(0);"><img src={tableFilter} onClick={() => setOpenShoolStatusFilter(!openShoolStatusFilter)} /></a>
                                                            <SchoolStatusFilter
                                                                openStatusFilter={openShoolStatusFilter}
                                                                setFilterStatus={setSchoolStatus}
                                                                filterStatus={schoolStatus}
                                                            />
                                                        </th>
                                                        <th ref={inventoryFilterRef} className="p-relative">inventory status <a href="javascript:void(0);"><img src={tableFilter} onClick={() => setOpenShoolInventoryFilter(!openShoolInventoryFilter)} /></a>
                                                            <SchoolInventoryStatusFilter
                                                                openShoolInventoryFilter={openShoolInventoryFilter}
                                                                setInventoryStatus={setInventoryStatus}
                                                                inventoryStatus={inventoryStatus}
                                                            />
                                                        </th>
                                                        <th>added On</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {!loading ?
                                                        <>
                                                            {schoolList.length > 0 ?
                                                                <>
                                                                    {schoolList.map((school, index) => (
                                                                        <tr key={school._id}>
                                                                            <td>{((currentPage - 1) * dataPerPage) + (index + 1)}</td>
                                                                            <td>
                                                                                <div className="schoolNameTable">
                                                                                    <h4>{school.schoolName && school.schoolName}</h4>
                                                                                    <span>{typeof (school.board) === "string" ? school.board.toUpperCase() : school.board.board.toUpperCase()}</span>
                                                                                    {school.category && <span>Cat {school.category}</span>}
                                                                                    <span>{returnReadableStatus(school.city)}</span>
                                                                                    {school.localArea && <span>{school.localArea}</span>}
                                                                                </div>
                                                                            </td>
                                                                            <td>{school.totalStudents}</td>
                                                                            <td>{school.schoolInventories && school.schoolInventories.length}</td>
                                                                            <td>{school.schoolActivities && school.schoolActivities.length}</td>
                                                                            <td>
                                                                                <span className={`table-status ${school.initialApproval ? 'greenbg' : "orangebg"}`}> {school.initialApproval ? "Approved" : "New Added"}</span>
                                                                            </td>
                                                                            <td>
                                                                                <span className={`table-status ${school.collectiveInventoryAndEventStatus === "In-review" ? 'yellowbg' : school.collectiveInventoryAndEventStatus === "Approved" ? 'greenbg' : school.collectiveInventoryAndEventStatus === "Rejected" ? "redbg" : school.collectiveInventoryAndEventStatus === "New Added" ? "orangebg" : ""}`}>
                                                                                    {school.collectiveInventoryAndEventStatus}
                                                                                </span>
                                                                            </td>
                                                                            <td>
                                                                                <span style={{cursor: "pointer"}}>
                                                                                    {school.addedOn && formatDateShowMonthBackendFOS(moment(school.addedOn).format("DD MMM YYYY"), 4, true)}
                                                                                </span>
                                                                                {/* <img className="updownarrow mobileViewArrow" src={arrowUpDown} onClick={() => history.push({ pathname: onlyActiveSchools === true ? campaignId ? "/backend/backend-details/campaign-reporting" : "/backend/backend-details/active-campaigns" : "/backend/backend-details/fos-school/school-details", state: { schoolId: school._id, fromPage: onlyActiveSchools === true ? "fos-active-schools" : 'fos-details', campaignId: campaignId } })} /> */}
                                                                                <img className="updownarrow mobileViewArrow" src={arrowUpDown} onClick={() => window.open("/backend/backend-details/fos-school/school-details?"+ school._id)} />
                                                                            </td>
                                                                        </tr>
                                                                    ))
                                                                    }
                                                                </>
                                                                :
                                                                <tr><td colSpan={8}>No School found</td></tr>
                                                            }
                                                        </>
                                                        :
                                                        [...Array(10)].map((el, index) => {
                                                            return (
                                                                <tr>
                                                                    {
                                                                        [...Array(8)].map((el, index) => {
                                                                            return (
                                                                                <td>
                                                                                    <div className="content-loader-block"></div>
                                                                                </td>
                                                                            )
                                                                        })
                                                                    }
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                            {totalPage.length > 1 &&
                                                <Pagination
                                                    currentPage={currentPage}
                                                    totalPage={totalPage}
                                                    onPageChange={onPageChange}
                                                    onNextClick={onNextClick}
                                                    onPrevClick={onPrevClick}
                                                    loading={loading}
                                                />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
        </>                           
    )
}
export default DetailsFOS;