import React from "react";
import TextArea from "../../../../components/formFields/textArea";

const CampaignRejetionModal = ({openRejectedModal, hideModal, rejectionMsg,setRejectionMsg,formValidator,rejectPayment, loading}) =>{
    return(
        <div id="rejectCampaignPopup" className={`popup rejectCampaignPopup ${openRejectedModal === true ? "open" : "hide"}`}>
                <div className="popup-header">
                    <h3 className="htx2 mb-10">Rejecting a Campaign?</h3>
                    <span onClick={hideModal} className="close toggle" data-target="closePopup">close</span>
                </div>
                <div className="popup-body">
                    <div className="row">
                        <form onSubmit={(e) => e.preventDefault()}>
                            <div className="row">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                    <TextArea
                                        formData={rejectionMsg}
                                        formKey='reason'
                                        onDataChange={(e) => setRejectionMsg({reason:e.target.value})}
                                        label='Please specify a reason on why this campaign was rejected'
                                        placeholder='Enter text here'
                                        isRequired={false}
                                        formValidator={formValidator}
                                        validation='required'
                                        maxLength={500}
                                    />
                                </div>
                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 fR">
                                    <a onClick={rejectPayment} href="javascript:void(0)" className={`btn btn-orange fR ${loading && "button--loading btn-disabled"}`}>SUBMIT</a>
                                </div>
                            </div>
                        </form>
                        {/* <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="forminpt">
                                <label>Please specify a reason on why this campaign was rejected</label>
                                <textarea placeholder="Enter text here" value={rejectionMsg} onChange={(e) => setRejectionMsg(e.target.value)} ></textarea>
                            </div>
                        </div>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 end-xs">
                            <div className="forminpt mb-0">
                                <input value="SUBMIT" name="SUBMIT" className="btn btn-orange" type="button" onClick={rejectPayment} />
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
    )
}
export default CampaignRejetionModal;