import React from 'react'

const GenderSplit = ({girlsRatio, boysRatio}) => {
    return (
        <div className="box-design mb-20 overheadThree">
            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-10">
                <h2 className="htx3 h-achor clearfix">Average Gender Split</h2>
                <p className='ptx2 mt-10'>This indicates the % split of the original TA selected while creating the campaign.</p>
                <div className="inventory-overHead camp-performance genderSplit">
                    <p style={{textTransform: "unset"}}>Boys <b>{`${boysRatio}%`}</b></p><p className="fR" style={{textTransform: "unset"}}>Girls <b>{`${girlsRatio}%`}</b></p>
                    <div className="camp-performance-bar"><span style={{width: `${boysRatio}%`}}></span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GenderSplit
