import React, {
  useState,
  useContext,
  useRef,
  useEffect,
  createRef,
} from "react";
import { useHistory } from "react-router-dom";

import AppContextObject from "../../../../common/context/common";
import { PublicContextInitiator } from "../../../apis/publicContextInitiator";
import proEdit from "../../../../assets/images/edit-profile.svg";
import imgInventory from "../../../../assets/images/blank-inventory-img.jpg";
import errorSVG from "../../../../assets/images/error.svg";
import closeCard from "../../../../assets/images/close-2.svg";
import faqArrow from "../../../../assets/images/faq-arrow.svg";
import Textfield from "../../../../components/formFields/textField/textField";
import Numberfield from "../../../../components/formFields/numberField";
import ConfirmDialog from "../../../../components/addon/confirmDialog/confirmDialog";
import SimpleReactValidator from "simple-react-validator";
import ImagePreview from "../../../../components/addon/imagePreview";
import BackendTopBar from "../components/backendTopBar";
import InventoryReport from "./inventoryReport";
import Loader from "../../../../components/addon/loader";
import school from "../../brands/school";
import { objectDeepClone } from "../../../../service/helper";
import CustomSearchInput from "../../../../components/formFields/customSearchInput";
import CrImagePreview from "../../../../components/addon/crImagePreview";

const CampaignReportView = (props) => {
  const context = useContext(AppContextObject);
  const history = useHistory();

  const [overlay, setOverlay] = useState(false);
  const [savingData, setSavingData] = useState(false);
  const [showSaveDataModal, setShowDataModal] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [ref, setRef] = useState();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [userLocation, setUserLocation] = useState<any>();
  // const [latLong, setLatLong] = useState([])
  const [showLocationAccessPopup, setShowLocationAccessPopup] = useState(false);
  const [inventoryList, setInventoryList] = useState([]);
  const [formData, setFormData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [schoolId, setSchoolId] = useState<any>();
  const [campaignId, setCampaignId] = useState<any>();
  const [schoolName, setSchoolName] = useState("");

  const [activeSetIndex, setActiveSetIndex] = useState(-1);
  const [activeInventoryIndex, setActiveInventoryIndex] = useState(-1);
  const [activeImageIndex, setActiveImageIndex] = useState(-1);
  const [showAllApproveModal, setShowAllApproveModal] = useState(false);
  const [showAllRejectModal, setShowAllRejectModal] = useState(false);
  const [showAllDeleteModal, setShowAllDeleteModal] = useState(false);
  const [showButtons, setShowButtons] = useState(false);

  const [multiSelectInventories, setMultiSelectInventories] = useState([]);

  const [salesViewData, setSalesViewData] = useState([]);

  const [clearSearchIcon, setClearSearchIcon] = useState(false);
  const [searchInpuOpen, setSearchInpuOpen] = useState(false);
  const [searchKeys, setSearchKeys] = useState("");
  const [sortAlbumDataBy, setSortAlbumDataBy] = useState("school");
  const [location, setLocation ] = useState("");

  // const [showAllDeleteModal, setShowAllDeleteModal] = useState(false)
  let prevInventory = "";

  useEffect(() => {
    if (props.setShowSidemenu) {
      props.setShowSidemenu(true);
    }
    let state: any = history.location.state;
    if (
      context.user.role === "backendSales" ||
      context.user.role === "backendOperations" ||
      context.user.role === "backendAdmin" ||
      context.user.role.startsWith("brand")
    ) {
      if (
        history.location.search &&
        history.location.search.split("?").length > 1
      ) {
        let array = history.location.search.split("?");
        if (array && array.length > 1 && array[1] && array[1] !== "") {
          let data: any = array[1];
          if (data) {
            getAlbumViewForSales(data);
          }
        }
      } else if (state.albumId) {
        getAlbumViewForSales(state.albumId);
      }
    }
  }, [refetch, searchKeys, sortAlbumDataBy]);

  const getInventoryReportData = async (school_id, campaign_id) => {
    setIsLoading(true);
    let resp = await context.apis[
      context.user.role
    ].getAllCampaignReportingSetByCampaign(
      context.validator,
      { campaignId: campaign_id, schoolId: school_id },
      {}
    );
    console.log("getAllCampaignReportingSetByCampaign", resp);
    if (resp && resp.done) {
      // setInventoryList([...resp.response.inventories])
      setFormData([...resp.response.rows]);
      setIsLoading(false);
    }
  };

  const handleChange = (e) => {
    if (e === "") {
      setSearchKeys("");
    } else {
      setSearchKeys(e.target.value);
    }
  };

  useEffect(() => {
    if (context.user.role === "backendOperations" && salesViewData.length > 0) {
      getInventoryReportData(salesViewData[0]?.schoolId, "");
    }
  }, [salesViewData]);

  const getAlbumViewForSales = async (_id) => {
    setIsLoading(true);
    let resp = await context.apis[
      context.user.role
    ].getApprovedImagesFromCampaignReportAlbum(
      context.validator,
      { _id: _id, searchValue: searchKeys, groupBy: sortAlbumDataBy },
      {}
    );
    console.log("getApprovedImagesFromCampaignReportAlbum", resp.response[0].location);
    setLocation(resp.response[0].location);
    if (resp && resp.done) {
      setSalesViewData([...resp.response]);
      //   let data = [];
      // resp.response.forEach(item => {
      //     let obj = {
      //         ...item,
      //         images: [...item.inventoryImages, ...item.eventImages]
      //     }
      //     data.push(obj)
      // })
      // setSalesViewData([...data])
    }
    setIsLoading(false);
  };

  return (
    <>
      <div className={`ovelap ${overlay ? "active" : ""}`}></div>
      <div className="col-xl-10 col-lg-12 col-md-12 col-xs-12">
        <div className="rightPart noBack">
          <div className="row">
            <BackendTopBar
              from={context.user.role === "backendFOS" && "campaignReporting"}
              redirectPage={false}
            />
            {isLoading ? (
              <Loader />
            ) : (
              <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-10 mt-20">
                <h2 className="htx2 clearfix mb-20">
                  {salesViewData.length > 0 && salesViewData[0].albumTitle}
                </h2>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <ul className={`tabsinventory`}>
                    <li onClick={() => setSortAlbumDataBy("school")}>
                      <a
                        href="javascript:void(0);"
                        className={sortAlbumDataBy === "school" ? "active" : ""}
                      >
                        Schools
                      </a>
                    </li>
                    <li onClick={() => setSortAlbumDataBy("inventory")}>
                      <a
                        href="javascript:void(0);"
                        className={
                          sortAlbumDataBy === "inventory" ? "active" : ""
                        }
                      >
                        Inventories/events
                      </a>
                    </li>
                  </ul>
                  <CustomSearchInput
                    searchInpuOpen={searchInpuOpen}
                    setSearchInpuOpen={setSearchInpuOpen}
                    clearSearchIcon={clearSearchIcon}
                    setClearSearchIcon={setClearSearchIcon}
                    handleChange={handleChange}
                    searchKeys={searchKeys}
                    align="right"
                  />
                </div>
                <div className="faq-view">
                  <div className="faq-tabs-list">
                    {salesViewData.length > 0 &&
                      salesViewData.map((item, inventoryIndex) => {
                        if (sortAlbumDataBy !== "school") {
                          if (prevInventory === item._id) {
                            item._id = "";
                          } else {
                            prevInventory = item._id;
                          }
                        }
                        return (
                          <>
                            <InventoryReport
                              ref={ref}
                              inventoryIndex={inventoryIndex}
                              inventory={item}
                              formData={formData}
                              setFormData={setFormData}
                              schoolId={schoolId}
                              campaignId={campaignId}
                              setRefetch={setRefetch}
                              refetch={refetch}
                              setShowButtons={setShowButtons}
                              showButtons={showButtons}
                              setActiveSetindex={setActiveSetIndex}
                              setActiveImageIndex={setActiveImageIndex}
                              setActiveInventoryIndex={setActiveInventoryIndex}
                              activeSetIndex={activeSetIndex}
                              activeInventoryIndex={activeInventoryIndex}
                              activeImageIndex={activeImageIndex}
                              multiSelectInventories={multiSelectInventories}
                              setMultiSelectInventories={
                                setMultiSelectInventories
                              }
                              groupBy={sortAlbumDataBy}
                              view={"campaignReportView"}
                              campaignLocation={location}
                            />
                            {salesViewData.length - 1 !== inventoryIndex && (
                              <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-20 mb-20">
                                <div className="line-full"></div>
                              </div>
                            )}
                          </>
                        );
                      })}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CampaignReportView;
