import { useState } from "react"

import check from "../../../assets/images/check.svg"
import DatePicker from "react-datepicker";
import moment from "moment";
import { parseISO, format } from 'date-fns';
const CustomDatePicker = (props) => {

    const {
        formData,
        formKey,
        onDataChange,
        label,
        placeholder,
        classes,
        wrapperClasses,
        maxLength,
        formValidator,
        validation,
        isDisabled,
        min,
        max,
        isChrome
    } = props

    const [classList] = useState(classes ? classes : "")
    const [wrapperClassList] = useState(wrapperClasses ? wrapperClasses : "")
    return (
        <>
        <div className={`${label ? "forminpt" : ""} ${wrapperClassList} ${isDisabled === true ? "disabled" : ""} `}>
            {
                label &&
                <label>{label}</label>
            }
            {
                isChrome === false 
                ?
                <DatePicker
                dateFormat="dd-MM-yyyy"
                id={formKey}
                selected={formData[formKey]}
                onChange={(date) => onDataChange(date, formKey)}
                className={`${classList} ${(formValidator?.current?.messagesShown === true && formValidator?.current?.fields?.[formKey] === false) ? "onFocus" : ""}`}
                minDate={parseISO(min)}
                maxDate={parseISO(max)}
                disabled={isDisabled === true ? true : false}
                placeholderText={placeholder ? placeholder : "dd/mm/yyyy"}
                />
                :
                <input
                min={min}
                max={max}
                type="date"
                id={formKey}
                value={formData[formKey]}
                onChange={onDataChange}
                className={`${classList} ${(formValidator?.current?.messagesShown === true && formValidator?.current?.fields?.[formKey] === false) ? "onFocus" : ""}`}
                placeholder={placeholder ? placeholder : "dd/mm/yyyy"}
                maxLength={maxLength}
                disabled={isDisabled === true ? true : false}
               />

            }

            {formValidator?.current?.message(formKey, formData[formKey], validation)}
        </div>
        </>
    )
}

export default CustomDatePicker