import React, { useState, useEffect, useRef, useContext } from "react";
import uploadDocument from "../../../../assets/images/img-upload.jpg";
import errorSVG from "../../../../assets/images/error.svg";
import deleteImg from "../../../../assets/images/delete.svg";

import SimpleReactValidator from "simple-react-validator";
import ImageModal from "../../../../components/addon/imageModal/imageModal";
import TextField from "../../../../components/formFields/textField/textField";
import Compress from "compress.js";

import toast from "react-hot-toast";
import { PublicContextInitiator } from "../../../apis/publicContextInitiator";
import AppContextObject from "../../../../common/context/common";

import editSvg from "../../../../assets/images/edit-btn.svg";
import { compareObjs } from "../../../../service/helper";
import { eraseAllCookies, eraseCookie } from "../../../../service/helper";
// import { getBrandDetails } from "./service";

interface BrandWisrRepresentativeProps {
  brandData: any;
}

const BrandWisrRepresentative = (props: BrandWisrRepresentativeProps) => {
  const { brandData } = props;
  const context = useContext(AppContextObject);

  const [brandDetailsById, setBrandDetailsById] = useState(brandData?.contact?.wisrRepresentative);



  // console.log("myBrandDetails", myBrandDetails)

  // const getBrandDetails = async () => {

  //         let brandResp = await context.apis.public.getBrandById(context.validator,
  //             {_id: context.user.brand ? context.user.brand : ""}
  //             )
  //             setBrandDetailsById(brandResp.response.contact.wisrRepresentative)
  //         }

  // useEffect(() => {
  //     getBrandDetails(context).then(resp => setBrandDetailsById(resp))
  // }, []);

  return (
    <>
      <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
        <div className="row">
          <div className="col-xl-4 col-lg-4 col-md-12 col-xs-12">
            <div className="profile-general">
              <h2 className="htx3 clearfix">
                Brand Wisr Representative
                {/* {!editingData && <a href="javascript:void(0)" className="editbtn" onClick={() => setEditingData(true)}><img src={editSvg} /> Edit</a>} */}
              </h2>
              {/* <p className="ptx clearfix">Tell us more about your brand.</p> */}
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12 brand-general">
            {/* Read Mode */}
            {brandDetailsById && brandDetailsById?._id && (
              <>
                <div className="inventory-overHead mt-0">
                  <p>Name</p>
                  <h3>{brandDetailsById.fullName}</h3>
                </div>
                <div className="clearfix mt-30"></div>
                <div className="inventory-overHead mt-0">
                  <p>Email</p>
                  <h3>{brandDetailsById.email}</h3>
                </div>
                <div className="clearfix mt-30"></div>
                <div className="inventory-overHead mt-0">
                  <p>Contact Number</p>
                  <h3>{brandDetailsById.phone}</h3>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default BrandWisrRepresentative;
