import { useState } from "react"

import errorSVG from "../../assets/images/error.svg"
import NumberFormat from 'react-number-format';

const ComaSeperatedNumField = (props) => {

    const {
        formData,
        extraChar,
        formKey,
        formErrors,
        onDataChange,
        label,
        placeholder,
        onVerifyClick,
        classes,
        wrapperClasses,
        isVerified,
        maxLength,
        formValidator,
        validation,
        noLimit,
        errorClass
    } = props

    const [classList, setClassList] = useState(classes ? classes : "")
    const [wrapperClassList, setWrapperClassList] = useState(wrapperClasses ? wrapperClasses : "")
    
    const onValueChange = (data) => {
        // console.log(e)
        const re = /^[0-9\b]+$/
        // if value is not blank, then test the regex
        if (data.value === '' || re.test(data.value)) {
            if(noLimit){
                onDataChange(data)
            }
            else if(data.value.length<6){
                onDataChange(data)
            }
            else{
                // console.log(data.value.length)
            }
        }
    }
    const withValueCap = (inputObj) => {
        if(noLimit){
            return true
        }
        const { value } = inputObj;
        if (value.length < 6) return true;
        return false;
    };

    return (
        <>
        {label !== true ?
            <div className={`${label ? "forminpt" : extraChar ? "form-text avgAnnualFeeMobile" : ""} ${wrapperClassList} `}>
                {
                    label &&
                    <label>{label}</label>
                }
                {extraChar &&<label htmlFor={formKey} className="static-value">&#8377;</label>}
                <NumberFormat
                    thousandSeparator={true}
                    thousandsGroupStyle="lakh"
                    id={formKey}
                    value={formData && formData[formKey]}
                    onValueChange={onValueChange}
                    className={` ${classList} ${formErrors && formErrors[formKey] ? "onFocus" : ""} ${errorClass ? "errorClass  onFocus" : ""}`}
                    placeholder={placeholder}
                    maxLength={maxLength}
                    pattern="[0-9]*"
                    type="text"
                    isAllowed={withValueCap}
                />
                    {formValidator?.current?.message(formKey, formData[formKey], validation)}
            </div>
            :
            <>
                <NumberFormat
                    thousandSeparator={true}
                    thousandsGroupStyle="lakh"
                    id={formKey}
                    value={formData && formData[formKey]}
                    onValueChange={onValueChange}
                    className={` ${classList} ${formErrors && formErrors[formKey] ? "onFocus" : ""} ${errorClass ? "errorClass  onFocus" : ""}`}
                    placeholder={placeholder}
                    maxLength={maxLength}
                    pattern="[0-9]*"
                    type="text"
                    isAllowed={withValueCap}
                />
                    {formValidator?.current?.message(formKey, formData[formKey], validation)}
            </>
        }
        </>
    )
}

export default ComaSeperatedNumField