import React, { useEffect, useState,useContext } from 'react';
import filterArrowSvg from "../../../../assets/images/filter-arrow-svg.svg";
import AppContextObject from '../../../../common/context/common';
import { titleCase } from '../../../../service/helper';

const CategoryFilter = ({ setFilterCity, filterCity,setCurrentpage, region }) => {
    let context = useContext(AppContextObject)
    const [cityArray,setCityArray] = useState([])
    const [spanCat, setSpanCat] = useState(false);
    useEffect(()=>{
        backendUsersCities();
    },[])
    const backendUsersCities = async () => {
        let resp = await context.apis.public.getUserCitiesByCountryRegion(
            context.validator, { countryRegion:region==='all' ? ["East","West","North","South","Central"] :[region], userRole:"backendFOS" }, {}
        )
        console.log('backendUsersCities===>', resp)
        if (resp && resp.done) {
            let city = []
            // setCityArray(resp.response.rows)
            resp.response.rows.forEach(item => {
                // city = [...city, ...item.cities]
                item.cities.forEach(data => {
                    if(!city.includes(data)){
                        city.push(data)
                    }
                })
            })
            setCityArray([...city])
        }

    }

    const filterByCatCity = (city) => {
        setCurrentpage(1);
        let array = [...filterCity]
        if (array.includes(city)) {
            array.splice(array.indexOf(city), 1)
        }
        else {
            array.push(city)
        }
        setFilterCity([...array])
    }
    const clearAllFilter = () => {
        setFilterCity([]);
        setSpanCat(false);
    }

    return (
        <div className={`filterDropDown open leftFos`}>
            <div className="filterDropHead">
                <h2>Filter <a href="javascript:void(0);" className="viewwLink" onClick={clearAllFilter}>Clear All</a></h2>
            </div>
            <div className="filterDropBody">
                <div className={`filterDropClose ${spanCat ? 'open' : ''}`}>
                    <h3><a href="javascript:void(0);" onClick={() => setSpanCat(!spanCat)} >{`Location`}  <img src={filterArrowSvg} /></a></h3>
                    <div className="filtercheckbox">
                        {cityArray.map((item, index) => (
                            <div className="checkbox fnone" key={index} >
                                <input type="checkbox" id={item} checked={filterCity.includes(item)} />
                                <label htmlFor={item} onClick={() => filterByCatCity(item)}>{titleCase(item)}</label>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CategoryFilter;