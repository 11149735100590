import { useEffect, useState } from "react"

import check from "../../../assets/images/check.svg"
import errorSVG from "../../../assets/images/error.svg"
import helpImg from "../../../assets/images/help.svg"


const TextField = (props) => {

    const {
        formData,
        formKey,
        formErrors,
        onDataChange,
        label,
        placeholder,
        onVerifyClick,
        classes,
        wrapperClasses,
        isVerified,
        maxLength,
        smallDesc,
        formValidator,
        validation,
        isDisabled,
        pattern,
        inProgress,
        isPincodeValid,
        index,
        isVerifyClickDisabled,
        helpText,
        onKeyPress,
        autoFocus,
        onBlur,
        isBlur,
        urlErrors,
        showUrlErrors
    } = props

    const [classList] = useState(classes ? classes : "")
    const [wrapperClassList] = useState(wrapperClasses ? wrapperClasses : "")

    const onPhoneNoChange = (e) => {
        const re = /^[0-9\b]+$/
        // if value is not blank, then test the regex
        if (e.target.value === '' || re.test(e.target.value)) {
          onDataChange(e)
        }
    }

    // console.log(formData)
    // console.log(formValidator,formKey, formData[formKey], validation)
    return (
        <>
        {label !== true ?
            <div className={`${label ? "forminpt tooltip-relative" : ""} ${wrapperClassList} ${isDisabled === true ? "disabled" : ""} `}>
                {
                    label &&
                    <label>{label}{helpText ? <a style={{cursor: "pointer"}} title={helpText} className="tooltip"><img src={helpImg} /></a> : ""}</label>
                }
                {
                // console.log("inside textfield formdata",formData)
            }
                <input
                    id={formKey}
                    value={index === undefined || index === null ? formData[formKey] : formData[index]}
                    onChange={formKey == "phone" ? onPhoneNoChange : onDataChange}
                    className={`${classList} ${((formValidator?.current?.messagesShown === true && formValidator?.current?.fields?.[formKey] === false) || showUrlErrors && urlErrors && urlErrors.length > 0 && urlErrors[index]) ? "onFocus" : ""}`}
                    placeholder={placeholder}
                    maxLength={maxLength}
                    pattern={pattern}
                    disabled={isDisabled === true ? true : false}
                    onKeyPress={onKeyPress}
                    autoFocus={autoFocus}
                    type="text" 
                    onBlur={isBlur ? onBlur : null}
                    />
                    {
                        smallDesc &&
                        <small>{smallDesc}</small>
                    }
                    {
                        onVerifyClick &&
                        <a onClick={onVerifyClick} href="javascript:void(0)" className={`${inProgress ? "verifyLoader active" : "verifyLoader"} ${isVerifyClickDisabled && "clickNone"}`}>{inProgress ? <span className="button--loading"></span> : ""}<b className={`vertext vrifiyotp ${isVerified === true ? "complete" : ""}`}>verify <img src={check} /></b></a>
                    }
                    {formValidator?.current?.message(formKey, formData[formKey], validation)}
                    {(formKey == "pincode" || formKey =="zip") && !isPincodeValid ? <span className="valError"><img src={errorSVG} />The {formKey} is invalid.</span> : ""}
                    {showUrlErrors && urlErrors && urlErrors.length > 0 && urlErrors[index] && <p className="valError"><img src={errorSVG} />{ urlErrors[index] === "invalid" ? "Please enter valid url." : urlErrors[index] === "required" ?  "The url is required." : ""}</p>}
            </div>
            :
            <>
            
                <input
                    id={formKey}
                    value={formData[formKey]}
                    onChange={onDataChange}
                    className={`${classList} ${(formValidator?.current?.messagesShown === true && formValidator?.current?.fields?.[formKey] === false) ? "onFocus" : ""}`}
                    placeholder={placeholder}
                    maxLength={maxLength}
                    disabled={isDisabled === true ? true : false}
                    type="text" />
                    {
                        smallDesc &&
                        <small>{smallDesc}</small>
                    }
                    {
                        onVerifyClick &&
                        <a onClick={onVerifyClick} href="javascript:void(0)"><b className={`vertext vrifiyotp ${isVerified === true ? "complete" : ""}`}>verify <img src={check} /></b></a>
                    }
                    {formValidator?.current?.message(formKey, formData[formKey], validation)}
                    {formKey == "pincode" && !isPincodeValid ? <span className="valError"><img src={errorSVG} />The pincode is invalid.</span> : ""}
            </>
            }
        </>
    )
}

export default TextField
