import { useState, useEffect, useContext, useRef } from "react"

import { useHistory } from "react-router-dom"

import toast from "react-hot-toast"

import { validation } from "../../../../service/validators"

import TextFieldWithIcon from "../../../../components/formFields/textfieldWithIcon"
import PasswordField from "../../../../components/formFields/passwordField"

import loginWave from "../../../../assets/images/login-wave.svg"
import envelope from "../../../../assets/images/envolpe.svg"
import logo from "../../../../assets/images/logo.png"
import AppContextObject from "../../../../common/context/common"
import SimpleReactValidator from "simple-react-validator"
import errorSVG from "../../../../assets/images/error.svg";
import DataContext from "../../../../service/brandContext"
import username from "../../../../assets/images/user-icon.svg"
import AlertDialog from "../../../../components/addon/alertDialog/alertDialog"


interface Props {
    methods
}

const WelcomeLogin = ({
    methods
}: Props) => {

    const history = useHistory()
    const context = useContext(AppContextObject)
    const parentData = useContext<any>(DataContext)
    let [formErrors, setFormErrors] = useState<any>({})
    let [loginError, setLoginError] = useState(null)
    const [update, forceUpdate] = useState(false)
    const [data, setData] = useState({
        username: ""
    })
    const [ip, setIP] = useState("192.168.56.1")
    const [formValidator] = useState(useRef(new SimpleReactValidator({
        element: (message, className) =>
            <span className="valError"><img src={errorSVG} />{message}</span>
    })))

    const [toggleAlertPopUp, setToggleAlertPopUp] = useState(false);
    const [detailsCaptured, setDetailsCaptured] = useState('');
    const [getFieldInfo, setGetFieldInfo] = useState('');
    const [overlay, setOverlay] = useState(false)
    const [msgDetails, setMsgDetails] = useState('');
    const [usernameError, setUsernameError] = useState(false)
    // useEffect(() => {
    //     // fetchUserIp()
    // }, [])


    const fetchUserIp = () =>{
        try{
            fetch('https://geolocation-db.com/json/')
            .then(response => response.json())
            .then(async data =>{
                console.log("data>>>>>>>",data);
                setIP(data.IPv4)
            })
        }catch(e){
            console.log(e);
        }
        
    }

    const onFormDataChange = (event) => {
        setData({
            ...data,
            [event.target.id] : event.target.value
        })
        if (event.target.id === "username") {
            setUsernameError(false)
            setData({
                ...data,
                username: event.target.value.toLowerCase()
            })
        }
    }
    let error = ""
    formValidator.current.rules['user_name'] = {
        message: error === "required" ? 'The user name field is required.' : usernameError ? "The username entered does not exist. Click on sign up" : "" ,
        rule: (val) => {
            let returnValue = true
            if (!val || val.trim() === "") {
                error = "required"
                returnValue = false
            }

            // const chars = "!#$%^&*()-{}[]+=<>?/,~'`"
            // for (let i = 0; i < val.split("").length; i++) {
            //     const v = val.split("")[i]
            //     if (!v || v.trim() === " " || chars.split("").find(ch => ch === v)) {
            //         error = ""
            //         returnValue = false
            //         break
            //     }
            // }

            if (usernameError) {
                error = ""
                returnValue = false
            }

            if (usernameError === null) {
                error = ""
                returnValue = false
            }

            if (usernameError === undefined) {
                error = ""
                returnValue = false
            }

            return returnValue
        },
    }
    const onSubmit = async () => {
        const result = formValidator.current.allValid()
        if (result === false) {
            formValidator.current.showMessages()
            forceUpdate(true)
            return
        } else {
            formValidator.current.hideMessages()
            forceUpdate(!update)
        }

        let resp = await context.apis.public.findUserByUsername(
            context.validator, { "username": data.username }
        )
        if (resp && resp.done === false) {
            let found = resp.response.filter(element => element._id !== parentData.data.userId);
            if (found.length > 0) {
                // formValidator.current.showMessageFor("username")
                // setGetVerifyUsernameStatus(false)
                // setIsUsernameVerified(false)
                // setUsernameError(true)
                // setGetFieldInfo("Username");
                // setDetailsCaptured(formData.username)
                // setMsgDetails(`The Username- ${formData.username} - entered already exists. Kindly use a different username to register.`)
                // setOverlay(true)
                // setToggleAlertPopUp(true)
                history.push({
                    pathname: '/brands/login',
                    state: {
                        username :data.username
                    },
                  });

                return
            }
            else {
                formValidator.current.showMessageFor("username")
                // setGetVerifyUsernameStatus(false)
                // setIsUsernameVerified(false)
                setUsernameError(true)
                // setGetFieldInfo("Username");
                // setDetailsCaptured(data.username)
                // setMsgDetails(`The Username- ${data.username} - entered does not exists. Click on Sign Up.`)
                // setOverlay(true)
                // setToggleAlertPopUp(true)
            }
        }
        else {
            formValidator.current.showMessageFor("username")
            // setGetVerifyUsernameStatus(false)
            // setIsUsernameVerified(false)
            setUsernameError(true)
            // setGetFieldInfo("Username");
            // setDetailsCaptured(data.username)
            // setMsgDetails(`The Username- ${data.username} - entered does not exists. Click on Sign Up.`)
            // setOverlay(true)
            // setToggleAlertPopUp(true)
            // history.push("/brands/sign-up")
        }

        // fetch("/api/login", requestOptions)
        // .then(response => response.json())
        // .then(async data => {
        //     methods.reRender()
        //     if(data.result && data.result.done){
        //         toast.success("Logged in successfully")
        //         if(data.result.role === "backendAdmin"){
        //             history.push("/schools-listing")
        //         }else if(data.result.role !== "backendAdmin" && (data.result.changePassword === true || data.result.isVerified == false)){
        //             if(data.result.isVerified == false) {
        //                 history.push("/user-verification")
        //             }
        //             else {
        //                 history.push("/reset-password")
        //             }
        //         }else{
        //             history.push("/schools/school-details/dashboard")
        //         }
        //     }else{
        //         setLoginError("Username or Password is incorrect.")
        //     }
        // }).catch(err=>{
        //     setLoginError("Username or Password is incorrect.")
        // })
    }

    const onSignUpClick = () => {
        history.push("/brands/sign-up")
    }

    const confirmClose = () => {
        setOverlay(false)
        setToggleAlertPopUp(false)
    }
    return (
        <>
            <div className={`ovelap ${(overlay === true) ? "active" : ""}`}></div>
            <div className="main-wapper">
                <div className="loginbox">
                    <div className="login-wave"> <img src={loginWave} alt="login-wave" /> </div>
                    <div className="login-form">
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb10">
                                <img src={logo} alt="logo" />
                                <h2 className="htx1">Login to WISR</h2>
                                <p className="ptx mb-20">Enter your log in details to continue your journey!</p>
                            </div>
                        </div>
                        <form data-form-validation="login-form" id="login-form-parent">
                            <div className="row">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                <TextFieldWithIcon
                                        label='Your Username'
                                        placeholder='Username'
                                        isRequired={false}
                                        icon={username}
                                        formErrors={formValidator}
                                        formData={data}
                                        formKey="username"
                                        validation="required|user_name"
                                        onFormDataChange={onFormDataChange}
                                        maxLength={16}
                                    />
                                </div>                            
                                <div className="row col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                    <div className="forminpt">
                                        <input onClick={onSubmit} value="Login" className="btn btn-orange datasubmit" type="button" />
                                    </div>
                                </div>
                                <div className="row col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                    <p className="centerText">New to WISR? <a style={{cursor: "pointer", color:"#fe6e00"}} onClick={onSignUpClick}>SIGN UP</a></p>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <AlertDialog
            togglePopup={toggleAlertPopUp}
            fieldInfo={getFieldInfo}
            details={detailsCaptured}
            onModalClose={confirmClose}
            displayMsg={msgDetails}
        />
        </>
        
    )
}

export default WelcomeLogin
