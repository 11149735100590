import { useContext } from "react"

import DataContext from "../../../../service/brandContext"

import { Link } from "react-router-dom"

import backBtn from "../../../../assets/images/back-arrow.svg"
import menu from "../../../../assets/images/menu.svg"

const BrandBackBtn = (props) => {

    const {
        link,
        text
    } = props

    const url = link ? link : "/"
    const btnText = text ? text : "Back"

    const parentData = useContext<any>(DataContext)

    const toggleMenu = () => {
        if (parentData.toggleResponsiveSidemenu) {
            parentData.toggleResponsiveSidemenu()
        }
    }

    return (
        <>
            <a onClick={toggleMenu} href="javascript:vid(0)" className={`hummob ${parentData.responsiveSidemenu === true ? "no_highlight" : ""}`}>
                <img src={menu} />
            </a>
            <Link to={url} className="backbtn">
                <img src={backBtn} />
                {btnText}
            </Link>
            <div className="clearfix"></div>        
        </>
    )
}

export default BrandBackBtn
