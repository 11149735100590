import { AppContext } from "../../common/context/common";
import { Validator } from '../../common/utils/validator.js';
import { Utilities } from '../../common/utils/utilities';

export class SchoolAdminContextInitiator {
    utils: Utilities;
    constructor(private appContext: AppContext){ this.utils = new Utilities(); }
    async getLoggedInUser (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
    
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('user->getLoggedInUser', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async mySchoolBookingStats (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'month', args, ()=>{return true;}, false);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('school->mySchoolBookingStats', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async listFAQ (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'searchValue', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'category', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'type', args, ()=>{return true;}, false);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('faqs->listFAQ', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getWisrBlogs (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'type', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('wisrBlogs->getWisrBlogs', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getWisrProTip (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
    
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('wisrProTips->getWisrProTip', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getSchoolGeneralDetailsBySchoolId (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('school->getSchoolGeneralDetailsBySchoolId', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getSchoolTypeBySchoolId (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
    
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('school->getSchoolTypeBySchoolId', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getSchoolEducationLevelBySchoolId (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
    
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('school->getSchoolEducationLevelBySchoolId', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getSchoolOverview (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
    
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('school->getSchoolOverview', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getSchoolName (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
    
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('school->getSchoolName', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getSchoolNameWithUserDetails (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('school->getSchoolNameWithUserDetails', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getSchoolAmenitiesDetailsBySchoolId (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('school->getSchoolAmenitiesDetailsBySchoolId', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getAllUserNotification (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'role', args, ()=>{return true;}, false);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('userNotification->getAllUserNotification', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getUserNotifications (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'role', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'timestamp', args, ()=>{return true;}, false);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('userNotification->getUserNotifications', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getAllUserLocation (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'ipAddress', args, ()=>{return true;}, false);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('userLocation->getAllUserLocation', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async oldPasswordSendOtpEmailPhone (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'inputPassword', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'phone', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'email', args, ()=>{return true;}, false);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('user->oldPasswordSendOtpEmailPhone', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getUsersToolTipProgress (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
    
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('user->getUsersToolTipProgress', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getDetailsForSchoolDetailsTable (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'searchValue', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'school', args, ()=>{return true;}, true);
        this.utils.addIfFound(validator, argsData, 'status', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'type', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'revenueContribution', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'bookingSchedule', args, ()=>{return true;}, false);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('inventories->getDetailsForSchoolDetailsTable', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getInventoriesWithAttributesBySchoolId (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'type', args, ()=>{return true;}, false);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('inventories->getInventoriesWithAttributesBySchoolId', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async findInventory (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('inventories->findInventory', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getSchoolInventoriesToBeAdded (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'searchValue', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'statusDiff', args, ()=>{return true;}, false);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('inventories->getSchoolInventoriesToBeAdded', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getInventoriesByParentName (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'parentName', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('inventories->getInventoriesByParentName', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getCustomInventories (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, '_custom', args, ()=>{return true;}, true);
        this.utils.addIfFound(validator, argsData, 'status', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('inventories->getCustomInventories', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async findInventoryAttribute (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('inventoryAttributes->findInventoryAttribute', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getActivities (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('activities->getActivities', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getActivitiesExternal (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'school', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('activities->getActivitiesExternal', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getActivitiesForTable (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'searchValue', args, ()=>{return true;}, false);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('activities->getActivitiesForTable', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getStudentLifeAndActivitiesBySchoolId (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('activities->getStudentLifeAndActivitiesBySchoolId', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async findActivity (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('activities->findActivity', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getSchoolActivitiesToBeAdded (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'searchValue', args, ()=>{return true;}, false);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('activities->getSchoolActivitiesToBeAdded', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async findActivitiesAttribute (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('activitiesAttribute->findActivitiesAttribute', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async updateSchoolFields (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
    
        this.utils.addIfFound(validator, newData, 'schoolName', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'principalName', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'principalEmail', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'registeredUnder', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'noOfTeachers', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'termsAgreed', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'builtUpArea', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'floors', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('school->updateSchoolFields', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async updateSchoolGeneralDetailsBySchoolId (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
    
        this.utils.addIfFoundInArray(validator, newData, 'educationLevel', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'schoolType', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'board', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'language', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'pincode', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'state', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'city', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'address', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'builtUpArea', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'localArea', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('school->updateSchoolGeneralDetailsBySchoolId', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async updateSchoolDetailsAmenities (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, newData, 'builtUpArea', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'floors', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('school->updateSchoolDetailsAmenities', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async createUserNotification (validator: Validator, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let newData = {};
    
        this.utils.addIfFound(validator, newData, 'message', data, ()=>{return true;}, true);
        this.utils.addIfFound(validator, newData, 'meta', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'role', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'seen', data, ()=>{return true;}, true);
        this.utils.addIfFound(validator, newData, 'notificationType', data, ()=>{return true;}, true);
        this.utils.addIfFound(validator, newData, 'user', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('userNotification->createUserNotification', {data: newData});
        } else {
            return null;
        }
    }
    async readAllUserNotification (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
    
        this.utils.addIfFound(validator, newData, 'message', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'meta', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'role', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'seen', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'notificationType', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'school', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'timestamp', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'user', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('userNotification->readAllUserNotification', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async unreadUserNotification (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, newData, 'message', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'meta', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'role', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'seen', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'notificationType', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'school', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'brand', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'timestamp', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'user', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('userNotification->unreadUserNotification', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async removeUserNotification (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, newData, 'message', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'meta', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'role', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'seen', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'notificationType', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'school', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'brand', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'timestamp', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'user', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('userNotification->removeUserNotification', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async muteUserNotification (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, newData, 'seen', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'user', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('userNotification->muteUserNotification', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async updateReadStateOfUserNotification (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, newData, 'seen', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'user', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('userNotification->updateReadStateOfUserNotification', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async saveUserQuery (validator: Validator, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let newData = {};
    
        this.utils.addIfFound(validator, newData, 'userType', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'query', data, ()=>{return true;}, true);
        this.utils.addIfFoundInArray(validator, newData, 'thread', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('userQueries->saveUserQuery', {data: newData});
        } else {
            return null;
        }
    }
    async addUserLocation (validator: Validator, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let newData = {};
    
        this.utils.addIfFound(validator, newData, 'city', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'country', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'timestamp', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'operatingSystem', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'ipAddress', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'userId', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'school', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'brand', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('userLocation->addUserLocation', {data: newData});
        } else {
            return null;
        }
    }
    async deleteUserLocation (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
    
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('userLocation->deleteUserLocation', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async updateUserDetails (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
    
        this.utils.addIfFound(validator, newData, 'fullName', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'email', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'phone', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'role', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'username', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'isVerified', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('user->updateUserDetails', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async changeUserPassword (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
    
        this.utils.addIfFound(validator, newData, 'password', data, ()=>{return true;}, false);
        this.utils.addIfFoundInArray(validator, newData, 'oldPasswords', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'isVerified', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('user->changeUserPassword', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async updateNotificationSettings (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
    
        this.utils.addIfFound(validator, newData, 'emailNotification', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'webPushNotification', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'smsNotification', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'allNotifications', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'webToken', data, ()=>{return true;}, false);
        this.utils.addIfFoundInArray(validator, newData, 'mutedNotificationCategories', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('user->updateNotificationSettings', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async updateUsersToolTipProgress (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
    
        this.utils.addIfFound(validator, newData, 'toolTipProgress', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('user->updateUsersToolTipProgress', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async addClassroomsIfNotExists (validator: Validator, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let newData = {};
    
        this.utils.addIfFound(validator, newData, 'class', data, ()=>{return true;}, true);
        this.utils.addIfFound(validator, newData, 'noOfSections', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'ageByStandard', data, ()=>{return true;}, true);
        this.utils.addIfFound(validator, newData, 'boys', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'girls', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'avgAnnualFee', data, ()=>{return true;}, true);
        this.utils.addIfFound(validator, newData, 'school', data, ()=>{return true;}, true);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('classrooms->addClassroomsIfNotExists', {data: newData});
        } else {
            return null;
        }
    }
    async addClassroomsBySchoolId (validator: Validator, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let newData = {};
    
        this.utils.addIfFound(validator, newData, 'class', data, ()=>{return true;}, true);
        this.utils.addIfFound(validator, newData, 'noOfSections', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'ageByStandard', data, ()=>{return true;}, true);
        this.utils.addIfFound(validator, newData, 'boys', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'girls', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'avgAnnualFee', data, ()=>{return true;}, true);
        this.utils.addIfFound(validator, newData, 'school', data, ()=>{return true;}, true);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('classrooms->addClassroomsBySchoolId', {data: newData});
        } else {
            return null;
        }
    }
    async updateClassroomById (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, newData, 'class', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'noOfSections', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'ageByStandard', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'boys', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'girls', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'avgAnnualFee', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'school', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('classrooms->updateClassroomById', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async updateClassroomBySchoolId (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, newData, 'class', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'noOfSections', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'ageByStandard', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'boys', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'girls', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'avgAnnualFee', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'school', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('classrooms->updateClassroomBySchoolId', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async archiveClassrooms (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
        this.utils.addIfFound(validator, argsData, 'class', args, ()=>{return true;}, true);
    
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('classrooms->archiveClassrooms', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async updateInventoriesById (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, newData, 'type', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, '_custom', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, '_checked', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'name', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'customName', data, ()=>{return true;}, false);
        this.utils.addIfFoundInArray(validator, newData, 'images', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'school', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'parentName', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'quantity', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'status', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('inventories->updateInventoriesById', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async updateInventoryStatusById (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, newData, 'status', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('inventories->updateInventoryStatusById', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async archiveInventory (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
    
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('inventories->archiveInventory', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async addInventoryAttributesIfNotExists (validator: Validator, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let newData = {};
    
        this.utils.addIfFound(validator, newData, 'name', data, ()=>{return true;}, true);
        this.utils.addIfFound(validator, newData, '_isCustom', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'units', data, ()=>{return true;}, true);
        this.utils.addIfFound(validator, newData, 'length', data, ()=>{return true;}, true);
        this.utils.addIfFound(validator, newData, 'breadth', data, ()=>{return true;}, true);
        this.utils.addIfFound(validator, newData, 'height', data, ()=>{return true;}, true);
        this.utils.addIfFound(validator, newData, 'inventory', data, ()=>{return true;}, true);
        this.utils.addIfFound(validator, newData, 'quantity', data, ()=>{return true;}, true);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('inventoryAttributes->addInventoryAttributesIfNotExists', {data: newData});
        } else {
            return null;
        }
    }
    async updateInventoryAttributesById (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, newData, 'name', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, '_isCustom', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'units', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'length', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'breadth', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'height', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'inventory', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'quantity', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('inventoryAttributes->updateInventoryAttributesById', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async archiveInventoryAttributesById (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
    
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('inventoryAttributes->archiveInventoryAttributesById', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async updateActivities (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, newData, 'name', data, ()=>{return true;}, false);
        this.utils.addIfFoundInArray(validator, newData, 'venue', data, ()=>{return true;}, false);
        this.utils.addIfFoundInArray(validator, newData, 'otherVenueList', data, ()=>{return true;}, false);
        this.utils.addIfFoundInArray(validator, newData, 'months', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'custom', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'availableForBranding', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'school', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'status', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'isApproved', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('activities->updateActivities', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async archiveActivity (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
        this.utils.addIfFound(validator, argsData, 'school', args, ()=>{return true;}, true);
    
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('activities->archiveActivity', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async updateActivityStatusById (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, newData, 'status', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('activities->updateActivityStatusById', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async updateActivitiesAttribute (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, newData, 'name', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'isCustom', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'availableForBranding', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'allowBrandsToProvide', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'activity', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'isSelected', data, ()=>{return true;}, false);
        this.utils.addIfFoundInArray(validator, newData, 'images', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'quantity', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'units', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'length', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'height', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'breadth', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('activitiesAttribute->updateActivitiesAttribute', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async archiveActivitiesAttribute (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
    
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('activitiesAttribute->archiveActivitiesAttribute', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async acceptCampaignRequest (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
        this.utils.addIfFound(validator, argsData, 'campaignId', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, newData, 'schoolStatus', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'creativeVersion', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'actionTakenBy', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'actionTakenOn', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'isApprovedBySchool', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('schoolCampaignData->acceptCampaignRequest', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async rejectCampaignRequest (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
        this.utils.addIfFound(validator, argsData, 'campaignId', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, newData, 'schoolStatus', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'rejectionMessage', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'actionTakenBy', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'actionTakenOn', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('schoolCampaignData->rejectCampaignRequest', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async updateSchoolDetails (validator: Validator, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('school->updateSchoolDetails', {data: data});
        } else {
            return null;
        }
    }
    async oldPasswordEmailPhoneOTPVerification (validator: Validator, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('user->oldPasswordEmailPhoneOTPVerification', {data: data});
        } else {
            return null;
        }
    }
    async addInventoryWithAttributes (validator: Validator, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('inventories->addInventoryWithAttributes', {data: data});
        } else {
            return null;
        }
    }
}
