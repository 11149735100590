import { useState, useContext, useEffect, useRef } from "react"

import { Link, useHistory } from "react-router-dom"
import search from "../../../assets/images/search.svg"
import arrowUpDown from "../../../assets/images/backendAdmin/arrow-up-down.svg"
import uploadSvg from "../../../assets/images/upload-svg.svg"
import riarrownext from "../../../assets/images/right-arrow-next.svg"
import tabImgInventory from "../../../assets/images/tab-img-inventory.png"
import tableFilter from "../../../assets/images/table-filter.svg"
import arrowUp from "../../../assets/images/arrow-up.svg"
import arrowDown from "../../../assets/images/arrow-down.svg"
import filterSvg from "../../../assets/images/filter-svg.svg"
import filterArrowSvg from "../../../assets/images/filter-arrow-svg.svg"
import errorSVG from "../../../assets/images/error.svg";
import tabSlide1 from "../../../assets/images/tab-Slide1.png"
import TopBar from "../schools/components/topBar"
import ArrowView from "../../../assets/images/arrow-view.svg"
import AppContextObject from "../../../common/context/common"

import OnboardingBackBtn from "../schools/components/onboardingBackBtn"
import InventoryCard from "../schools/updateDetails/inventory/inventoryCard"
import Pagination from "../../../components/addon/pagination"
import CampaignApprovalModal from "../../../components/addon/campaignApprovalModal"
import ThankyouModal from "../../../components/addon/thankyouModal"
import RejectBrandRequestModal from "../../../components/addon/rejectBrandRequestModal"
import SimpleReactValidator from "simple-react-validator";
import DataContext from "../../../service/contextProvider"

import { PublicContextInitiator } from "../../apis/publicContextInitiator"

import { Steps } from "intro.js-react"
import { formatDateToDDMMMYY, isObjectEmpty, readAbleBoard, returnReadableStatus, titleCase } from "../../../service/helper"

import toast from "react-hot-toast"
import SchoolTable from "./components/SchoolTable"
import CategoryFilterSchool from "./components/CategoryFilterSchool"
import SearchInput from "./components/SearchInput"
import config from "../../../service/config"
import SingleSelect from "../../../components/formFields/singleSelect/singleSelect"
import Loader from "../../../components/addon/loader"
import backBtn from "../../../assets/images/back-arrow.svg";
import BackendTopBar from "./components/backendTopBar"
import BackendBackBtn from "./components/backendBackBtn"

import downloadSvg from "../../../assets/images/backendAdmin/download-svg.svg";

import Compress from "compress.js"
import { downloadFile} from '../../../service/helper';
import CustomSearchInput from "../../../components/formFields/customSearchInput"
import { ExportToCsv } from 'export-to-csv';

import useClickOutside from "../../../service/useClickOutside";
var backendSchoolListGlobalVar = 1

const NonWisrSchoolList = () => {
    const context = useContext(AppContextObject)
    const history = useHistory()
    const compress = new Compress()
    const [schoolList, setSchoolList] = useState([])
    const [overlay, setOverlay] = useState(false)
    const [totalPage, setTotalPage] = useState([0]);
    const [currentPage, setCurrentpage] = useState(1);
    const [searchInpuOpen, setSearchInpuOpen] = useState(false);
    const [clearSearchIcon, setClearSearchIcon] = useState(false);
    const [openFilter, setOpenFilter] = useState(false);
    const [openStateFilter, setOpenStateFilter] = useState(false)
    const [openCityFilter, setOpenCityFilter] = useState(false)
    const [openBoardFilter, setOpenBoardFilter] = useState(false);
    const [openLanguageFilter, setOpenLanguageFilter] = useState(false);
    const [filterCity, setFilterCity] = useState([]);
    const [filterGender, setFilterGender] = useState([]);
    const [filterBoard, setFilterBoard] = useState([]);
    const [filterLanguage, setFilterLanguage] = useState([]);
    const [searchKeys, setSearchKeys] = useState("");
    const [cityStateData, setCityStateData] = useState({})
    const [stateList, setStateList] = useState([])
    const [selectedState, setSelectedState] = useState([])
    const [cityList, setCityList] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [uploadingData, setUploadingData] = useState(false)
    const [refetch, setRefetch] = useState(false)

    const filterRef = useRef(null);
    const filterOptionsRef = useRef(null);

    useClickOutside(filterOptionsRef, (event) => {
        const el = filterRef?.current;

        if (!el || el.contains(event.target)) {
            return;
        }
        setOpenFilter(false);
    });

    const [downloadSchoolLoading, setDownloadSchoolLoading] = useState(false);
    
    
    
    useEffect(() => {
        getAllSchools()
    }, [refetch])

    useEffect(() => {
        getFilteredSchool()
    }, [searchKeys, currentPage, filterBoard, filterLanguage, filterCity, selectedState, refetch])

    useEffect(() => {
        if(currentPage !== 1){
            setCurrentpage(1)
        }
    }, [searchKeys, filterBoard, filterLanguage, selectedState, filterCity, refetch])

    useEffect(() => {
        fetchCityByState()
    }, [selectedState])
    const fetchCityByState = async() => {
        let city = []
        selectedState.forEach(state => {
            city = [...city, ...cityStateData[state]]
        })
        setCityList([...city])
    }

    const getAllSchools = async() => {
        // setIsLoading(true)
        let resp = await context.apis[context.user.role].getAllNonWisrSchools(
            context.validator, {}, {}
        )
        console.log("getAllNonWisrSchools", resp)
        if(resp && resp.done){
            let states = [], cityStateObj = {}
            resp.response.rows.forEach(school => {
                if(!states.includes(school.state)){
                    states.push(school.state)
                }
                if(cityStateObj[school.state]){
                    if(!cityStateObj[school.state].includes(school.city)){
                        cityStateObj[school.state].push(school.city)
                    }
                }
                else{
                    cityStateObj[school.state] = [school.city]
                }
            })
            console.log("first", states, cityStateObj)
            setStateList([...states])
            setCityStateData({...cityStateObj})
        }
        // setIsLoading(false)
    }

    const getFilteredSchool = async() => {
        setIsLoading(true)
        let resp = await context.apis[context.user.role].getAllNonWisrSchools(
            context.validator, {board: filterBoard, language: filterLanguage, state: selectedState, city: filterCity, searchValue: searchKeys}, {page: currentPage, limit: 10, withCount: true}
        )
        console.log("getAllNonWisrSchools", resp)
        if(resp && resp.done){
            setSchoolList([...resp.response.rows])
            let total = Math.ceil(resp.response.count / 10);
            let arr = []
            for (let i = 0; i < total; i++) {
                arr.push(i)
            }
            setTotalPage([...arr])
        }
        setIsLoading(false)
    }
    const filteration = (category, value) => {
        console.log(category, value)
        if (category === 'state') {
            let array = [...selectedState]
            if (array.includes(value)) {
                array.splice(array.indexOf(value), 1)
                if(filterCity.length !== 0){
                    let citiesToRemove = [...cityStateData[value]], newCitiesData = [...filterCity]
                    for(let city of citiesToRemove){
                        if(newCitiesData.indexOf(city) !== -1){
                            newCitiesData.splice(newCitiesData.indexOf(city), 1)
                        }
                    }
                    setFilterCity([...newCitiesData])
                }
            }
            else {
                array.push(value)
                setFilterCity([...filterCity,...cityStateData[value]])
            }
            setSelectedState([...array])
        }
        else if (category === 'city') {
            let array = [...filterCity]
            if (array.includes(value)) {
                array.splice(array.indexOf(value), 1)
            }
            else {
                array.push(value)
            }
            setFilterCity([...array])
        }
        else if (category === 'gender') {
            let array = [...filterGender]
            if (array.includes(value)) {
                array.splice(array.indexOf(value), 1)
            }
            else {
                array.push(value)
            }
            setFilterGender([...array])

        }
        else if (category === 'board') {
            let array = [...filterBoard]
            if (array.includes(value)) {
                array.splice(array.indexOf(value), 1)
            }
            else {
                array.push(value)
            }
            setFilterBoard([...array])

        }
        else if (category === 'language') {
            let array = [...filterLanguage]
            if (array.includes(value)) {
                array.splice(array.indexOf(value), 1)
            }
            else {
                array.push(value)
            }
            setFilterLanguage([...array])

        }


    }
    const clearAllFilters = () => {
        setFilterCity([]);
        setFilterGender([]);
        setFilterBoard([]);
        setFilterLanguage([]);
        setSelectedState([]);
        setOpenFilter(false)
        setOpenBoardFilter(false)
        setOpenStateFilter(false)
        setOpenCityFilter(false)
        setOpenLanguageFilter(false)
    }

    const handleSearch = (e) => {
        if(e === ""){
            setSearchKeys("")
        }
        else{
            setSearchKeys(e.target.value)
        }
    }

    const onPageChange = (page) => {
        setCurrentpage(page)
    }
    const onNextClick = () => {
        setCurrentpage(prev => prev + 1)
    }
    const onPrevClick = () => {
        setCurrentpage(prev => prev - 1)
    }

    const redirectToSchoolOnboarding = () => {
        history.push("/backend/backend-details/add-nonWisr-school")
    }

    const uploadCSV = async(e) => {
        const file = e.target.files[0];
        if(file.type !== "text/csv"){
            toast.error("Please upload csv file only")
            e.target.value = null
            return
        }
        const formData = new FormData();
        formData.append(`image`, file);
        formData.append("destinationFolder", "nonWiseSchoolsData")
        const controller = new AbortController()
        let timeoutId
        timeoutId = setTimeout(() => {
            controller.abort();
        }, 60000)
        let reqBody = formData
        const requestOptions = {
            signal: controller.signal,
            method: 'POST',
            body: reqBody
        };
        setUploadingData(true)
        try{
            const response: any = await fetch('/api/uploadFiles', requestOptions).catch(err => console.log(err))
            const res = await response.json()
            console.log("response", response, res)
            if(res && res.done){
                try{
                    let apiData = new FormData()
                    apiData.append('filename', res.response[0].Key)
                    let requestData = {
                        method: 'POST',
                        body: apiData
                    }
                    const uploadToBackend: any = await fetch('/api/nonWisrSchoolsBulkUpload', requestData).catch(err => console.log(err))
                    const backendRes = await uploadToBackend.json()
                    console.log("backendRes", backendRes)
                    if(backendRes && backendRes.done){
                        toast.success("File uploaded successfully.")
                        setRefetch(!refetch)
                    }
                }catch(err) {
                    console.log("error", err)
                }
            }
        }catch(err){
            console.log("error", err)
        }
        e.target.value = null;
        setUploadingData(false)
    }

    const getNonWisrSchool = async () => {

        setDownloadSchoolLoading(true);

        let resp = await context.apis[context.user.role].getAllNonWisrSchools(
            context.validator, {board: filterBoard, language: filterLanguage, state: selectedState, city: filterCity, searchValue: searchKeys}, {withCount: true}
        )
        let data =resp.response.rows
        const options = { 
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showTitle: false,
            filename:'NonWISRSchools',
            useTextFile: false,
            useKeysAsHeaders: true,
            // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
        };
        setDownloadSchoolLoading(false);
        const csvExporter = new ExportToCsv(options);
        csvExporter.generateCsv(data);

        }
        
    return (
        <>
            <div className={`ovelap ${overlay === true ? "active" : ""}`}></div>
            <div className="col-xl-10 col-lg-12 col-md-12 col-xs-12">
                <div className="rightPart noBack">
                    <div className="row">
                        <BackendTopBar redirectPage={false} />
                        <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                            <h2 className="htx2 clearfix">Full List of Non-WISR Schools</h2>
                        </div>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                            <div className="inventory-tableTabs">
                                <div className="schoolFilter" style={{flexDirection: "row-reverse"}}>
                                    <a style={{margin: "10px", width: "25px", height: "27px"}} className="uploadSvg uploadBrose"><img src={uploadSvg} />
                                        <input 
                                            style={{cursor: "unset", width:'25px'}}
                                            type='file'
                                            accept='.csv'
                                            id='csvFile'
                                            onChange={(e) => uploadCSV(e)}
                                            disabled={uploadingData}
                                        />
                                    </a>
                                    {(context.user.role === "backendAdmin" || context.user.role === "backendOperations" || context.user.role === "backendTech") && <a href='javascript:void(0)' className={`downloadSvg ${downloadSchoolLoading ? 'btn-disabled btn-opacity' : ''}`} style={{marginLeft: '10px'}} onClick={getNonWisrSchool} ><img src={downloadSvg} /></a>}
                                    {(context.user.role === "backendAdmin" || context.user.role === "backendTech" || context.user.role === "backendOperations") && <a href="javascript:void(0);" className="btn btn-orange spaceL" onClick={redirectToSchoolOnboarding}>+ Add School</a>}
                                        <CustomSearchInput
                                            searchInpuOpen={searchInpuOpen}
                                            setSearchInpuOpen={setSearchInpuOpen}
                                            clearSearchIcon={clearSearchIcon}
                                            setClearSearchIcon={setClearSearchIcon}
                                            searchKeys={searchKeys}
                                            handleChange={handleSearch}
                                            palaceholder="School name"
                                        />
                                    <div className="filterbox">
                                        <a href="javascript:void(0);" className="filterDrop" onClick={() => setOpenFilter(!openFilter)}>
                                            <img src={filterSvg} />
                                            <h3>Filter</h3>
                                            <img src={filterArrowSvg} />
                                        </a>

                                        <div className={`filterDropDown ${openFilter ? 'open' : ''}`}>
                                            <div className="filterDropHead" ref={filterRef}>
                                                <h2>Filter{" "} <a href="javascript:void(0);" className="viewwLink" onClick={clearAllFilters}>Clear All</a></h2>
                                            </div>
                                            <div className="filterDropBody" ref={filterOptionsRef}>
                                                <div className={`filterDropClose ${openStateFilter ? 'open' : ''}`}>
                                                    <h3><a href="javascript:void(0);" onClick={() => setOpenStateFilter(!openStateFilter)}>{`State ${selectedState.length > 0 ? `(${selectedState.length})` : ""}`}  <img src={filterArrowSvg} /></a></h3>
                                                    <div className="filtercheckbox">
                                                        {stateList.map((item, index) => (
                                                            <div className="checkbox fnone" key={index} >
                                                                <input type="checkbox" id={item} checked={selectedState.includes(item)} />
                                                                <label htmlFor={item} onClick={() => filteration('state', item)}>{titleCase(item)}</label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                                <div className={`filterDropClose ${openCityFilter && selectedState.length > 0 ? 'open' : ''}`}>
                                                    <h3><a href="javascript:void(0);" onClick={() => setOpenCityFilter(!openCityFilter)}>{`City ${filterCity.length > 0 ? `(${filterCity.length})` : ""}`}  <img src={filterArrowSvg} /></a></h3>
                                                    <div className="filtercheckbox">
                                                        {cityList.map((item, index) => (
                                                            <div className="checkbox fnone" key={index} >
                                                                <input type="checkbox" id={item} checked={filterCity.includes(item)} />
                                                                <label htmlFor={item} onClick={() => filteration('city', item)}>{titleCase(item)}</label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                                <div className={`filterDropClose ${openBoardFilter ? 'open' : ''}`}>
                                                    <h3><a href="javascript:void(0);" onClick={() => setOpenBoardFilter(!openBoardFilter)} >{`Education Board ${filterBoard.length > 0 ? `(${filterBoard.length})` : ""}`}  <img src={filterArrowSvg} /></a></h3>
                                                    <div className="filtercheckbox">
                                                        {config.boardOptions.filter((item) => item.value.toLowerCase() !== "custom" && item.value.toLowerCase() !== "other").map((item, index) => (
                                                            <div className="checkbox fnone" key={index}>
                                                                <input type="checkbox" id={item.value} checked={filterBoard.includes(item.value)} />
                                                                <label htmlFor={item.value} onClick={() => filteration('board', item.value)}>{item.label}</label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                                <div className={`filterDropClose ${openLanguageFilter ? 'open' : ''}`}>
                                                    <h3><a href="javascript:void(0);" onClick={() => setOpenLanguageFilter(!openLanguageFilter)}>{`Language of Study ${filterLanguage.length > 0 ? `(${filterLanguage.length})` : ""}`}  <img src={filterArrowSvg} /></a></h3>
                                                    <div className="filtercheckbox">
                                                        {config.languages.filter((item) => item.value.toLowerCase() !== "custom").map((item, index) => (
                                                            <div className="checkbox fnone" key={index}>
                                                                <input type="checkbox" id={item.value} checked={filterLanguage.includes(item.value)} />
                                                                <label htmlFor={item.value} onClick={() => filteration('language', item.value)}>{item.label}</label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="table-container table-inventory mt-10">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Sl no</th>
                                                <th>school name</th>
                                                <th>Contact email</th>
                                                <th>Contact No</th>
                                                <th>Onboarded Status</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {!isLoading ? schoolList.length > 0 ? schoolList.map((school, key) => (
                                                    <tr key={key}>
                                                        <td>{(key + 1) + (currentPage - 1) * 10}</td>
                                                        <td>
                                                            <div className="schoolNameTable companyName">
                                                                <h4>{school.schoolName}</h4>
                                                                {school.board && <span>{readAbleBoard(school.board)}</span>}{school.city && <span>{returnReadableStatus(school.city)}</span>}{school.pincode && <span>{school.pincode}</span>}
                                                            </div>
                                                        </td>
                                                        <td>{school.contactEmail}</td>
                                                        <td>{school.contactNumber}</td>
                                                        <td> <span style={{color: school.hasSchoolOnboarded ? '#15bc88' : '#171717',margin: "10px"}}>{school.hasSchoolOnboarded == true ? "Yes" :"No"}</span></td>
                                                        <td>
                                                            <img style={{maxWidth:"15px"}} onClick={() => history.push({ pathname: "/backend/backend-details/nonWisr-school-details", state: { schoolId: school._id } })} className="updownarrow" src={arrowUpDown} />
                                                        </td>
                                                    </tr>
                                                ))
                                                :
                                                <tr><td colSpan={5}>No School found</td></tr>
                                                :
                                                [...Array(10)].map((el, index) => {
                                                    return (
                                                        <tr>
                                                            {
                                                                [...Array(5)].map((el, index) => {
                                                                    return (
                                                                        <td key={index}>
                                                                            <div className="content-loader-block"></div>
                                                                        </td>
                                                                    )
                                                                })
                                                            }
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                    {totalPage.length > 1 &&
                                        <Pagination
                                            currentPage={currentPage}
                                            totalPage={totalPage}
                                            onPageChange={onPageChange}
                                            onNextClick={onNextClick}
                                            onPrevClick={onPrevClick}
                                            loading={isLoading}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NonWisrSchoolList