const NoControlDialog = (props) => {
    const {
        togglePopup,
        displayMsg
    } = props



    return (
        <div id="closePopup" className={`popup ${togglePopup === true ? "" : "hide"}`} style={{width: "auto", height: "auto", padding: "25px"}}>
            <div className="popup-body" style={{padding: "0", margin: "0"}}>
                <div className="facili-avai-in clearfix" style={{padding: "0", margin: "0"}}>
                    <div className="row" style={{padding: "0", margin: "0"}}>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{padding: "0", margin: "0"}}>
                            <h3 className="htx2">{displayMsg}</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NoControlDialog