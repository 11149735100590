import close from "../../../assets/images/close-1.svg"
import star from "../../..//assets/images/star.svg"
import check from "../../../assets/images/check.svg"
import {
    useState,
    forwardRef,
    useImperativeHandle,
} from "react"

interface Props {
    data?: any,
    onCardClick?: Function,
    index?: any,
    removeAmenity?: Function,
    editAmenity?: any,
    onEdit?: any,
    showInput?: any,
    error?: any,
    setError?: any,
    toBeEdited?: any,
    sameNameError?: boolean
}

const AmenityCard = forwardRef(({
    data,
    onCardClick,
    index,
    removeAmenity,
    editAmenity,
    onEdit,
    showInput,
    error,
    setError,
    toBeEdited,
    sameNameError
}: Props, ref) => {

    const [newAmenity, setNewAmenity] = useState(data.name)

    const oldAmenity = data.name
    
    const onAmenityClick = () => {
        onCardClick(data, index)
    }
    
    const onDelete = () => {
        removeAmenity(index, data.name)
    }
    
    const handleChange = (e) => {
        setNewAmenity(e.target.value)
        setError(false)
    }

    useImperativeHandle(ref, () => ({
        saveData() {
            if (newAmenity.trim() !== "") {
                editAmenity(oldAmenity, newAmenity)
            } else {
                setError(true)
            }            
        },
    }))

    return (
        <div
            id={`amenity${index}`}
            className={`
                facili-avai1
                ${data._checked === true ? "complete" : ""}
                ${data._custom === true ? "remove" : ""}
            `}
            onClick={onAmenityClick}
            onDoubleClick={() => (data._custom === true ? onEdit(data, index) : null)}
        >
        <img className='checkcom' src={check} />
        {
            (!toBeEdited) &&
            <a onClick={onDelete} href='javascript:void(0)' className='remove-icon'>
                <img src={close} />
            </a>
        }
        <img className='checkcom star' src={star} />
        {(showInput && data._custom && index === toBeEdited) ? (
        <>
            <input
                value={newAmenity}
                className={`'onFocus'`}
                type='text'
                onChange={handleChange}
                autoFocus
            />
            {
                error && index === toBeEdited &&
                <p
                    style={{
                        fontSize: "0.8rem",
                        color: "tomato",
                        paddingTop: "0.5rem",
                    }}
                >
                    Field name can't be empty
                </p>
            }
            {
                !error && index === toBeEdited && sameNameError &&
                <p
                    style={{
                        fontSize: "0.8rem",
                        color: "tomato",
                        paddingTop: "0.5rem",
                    }}
                >
                    Inventory name can't be same
                </p>
            }
        </>
        ) : (
            <h3>{data.name}</h3>
        )}
        </div>
    )
})

export default AmenityCard
