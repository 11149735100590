import { useState, useEffect } from "react"
const ImagePreview = (props) => {

    const {
        setShowImagePreview,
        showImagePreview,
        images,
        selectedImageIndex,
    } = props

    const defaultImage = "https://via.placeholder.com/450x330"

    let [imageList, setImageList] = useState(images)
    let [currentIndex, setCurrentIndex] = useState(selectedImageIndex)
    let [currentImage, setCurrentImage] = useState(images && images.length > 0 ? images[selectedImageIndex] : defaultImage)

    useEffect(() => {
        setImageList(images)
        setCurrentImage(images && images[selectedImageIndex])
    }, [images, showImagePreview, selectedImageIndex])

    useEffect(() => {
        setCurrentImage(images && images[selectedImageIndex])
        setCurrentIndex(selectedImageIndex)
    }, [selectedImageIndex])

    const prevButtonClick = () => {
        if (currentIndex > 0) {
            setCurrentImage(images[currentIndex - 1])
            setCurrentIndex(currentIndex - 1)
        }
    }

    const nextButtonClick = () => {
        if (currentIndex < (images.length - 1)) {
            setCurrentImage(images[currentIndex + 1])
            setCurrentIndex(currentIndex + 1)
        }
    }
    return (
        <>
            <div id="overlay" className="imageView" style={{display: showImagePreview === true ? "flex" : "none"}}>
                {imageList && imageList.length > 1 && <div id="prevButton" onClick={prevButtonClick}><i className="fa fa-chevron-left"></i></div>}
                <img src={currentImage && currentImage.image} alt="Wisr" />
                {/* {showInventoryName && <div style={{position: "absolute", top: "1%", left: "1%"}}>{currentImage.inventoryName}</div>} */}
                {imageList && imageList.length > 1 && <div id="nextButton" onClick={nextButtonClick}><i className="fa fa-chevron-right"></i></div>}
                <div id="exitButton"><i onClick={() => setShowImagePreview(false)} className="fa fa-times"></i></div>
            </div>        
        </>
    )
}

export default ImagePreview
