import { useState, useContext, useEffect, useRef } from "react"

import { Link, useHistory } from "react-router-dom"

import search from "../../../../assets/images/search.svg"
import arrowUpDown from "../../../../assets/images/backendAdmin/arrow-up-down.svg"
import riarrownext from "../../../../assets/images/right-arrow-next.svg"
import tabImgInventory from "../../../../assets/images/tab-img-inventory.png"
import tableFilter from "../../../../assets/images/table-filter.svg"
import arrowUp from "../../../../assets/images/arrow-up.svg"
import arrowDown from "../../../../assets/images/arrow-down.svg"
import filterSvg from "../../../../assets/images/filter-svg.svg"
import filterArrowSvg from "../../../../assets/images/filter-arrow-svg.svg"
import errorSVG from "../../../../assets/images/error.svg";
import tabSlide1 from "../../../../assets/images/tab-Slide1.png"
import TopBar from "../../schools/components/topBar"
import ArrowView from "../../../../assets/images/arrow-view.svg"
import AppContextObject from "../../../../common/context/common"

import OnboardingBackBtn from "../../schools/components/onboardingBackBtn"
import InventoryCard from "../../schools/updateDetails/inventory/inventoryCard"
import Pagination from "../../../../components/addon/pagination"
import CampaignApprovalModal from "../../../../components/addon/campaignApprovalModal"
import ThankyouModal from "../../../../components/addon/thankyouModal"
import RejectBrandRequestModal from "../../../../components/addon/rejectBrandRequestModal"
import SimpleReactValidator from "simple-react-validator";
import DataContext from "../../../../service/contextProvider"

import { PublicContextInitiator } from "../../../apis/publicContextInitiator"

import { Steps } from "intro.js-react"
import { formatDateToDDMMMYY, isObjectEmpty, readAbleBoard, titleCase } from "../../../../service/helper"

import toast from "react-hot-toast"
// import SchoolTable from "./components/SchoolTable"
// import CategoryFilterSchool from "./components/CategoryFilterSchool"
import SearchInput from "../components/SearchInput"
import config from "../../../../service/config"
import SingleSelect from "../../../../components/formFields/singleSelect/singleSelect"
import Loader from "../../../../components/addon/loader"
import backBtn from "../../../../assets/images/back-arrow.svg";
import BackendTopBar from "../components/backendTopBar"
import BackendBackBtn from "../components/backendBackBtn"

import downloadSvg from "../../../assets/images/backendAdmin/download-svg.svg";

import { downloadFile} from '../../../../service/helper';
import { ExportToCsv } from 'export-to-csv';
import CustomSearchInput from "../../../../components/formFields/customSearchInput"
var backendSchoolListGlobalVar = 1

const CampaignSchoolList = ({ onlyActiveCampaign }) => {
    const parentData = useContext<any>(DataContext)
    const history = useHistory()
    const context = useContext(AppContextObject)
    const publicContextInitiator = new PublicContextInitiator(context)

    const [completed] = useState("completed")
    const [campaignId, setCampaignId] = useState<any>('')
    const [campaignName, setCampaignName] = useState<any>('')

    const [showModalApproveActive, setshowModalApproveActive] = useState(false)
    const [showThankyouModal, setShowThankyouModal] = useState(false)
    const [thankyouTitle, setThankyouTitle] = useState("")
    const [showConfirmRejectModal, setShowConfirmRejectModal] = useState(false)
    const [thankyouDescription, setThankyouDescription] = useState("")
    const [pageLimit, setPageLimit] = useState(10)
    const [showModalapproveActive, setshowModalapproveActive] = useState(false)
    const [overlay, setOverlay] = useState(false)
    const [countOfStatus, setCountOfStatus] = useState({
        all: 0,
        active: 0,
        pending: 0,
        rejected: 0,
    })
    const [requestedInventoryName, setRequestedInventoryName] = useState("")
    const [schoolList, setSchoolList] = useState([]);
    const [totalPageCount, setTotalPageCount] = useState<any>([0]);
    const [totalPage, setTotalPage] = useState([0]);
    const [currentPage, setCurrentpage] = useState(1);
    const [openFilter, setOpenFilter] = useState(false);
    const [clearSearchIcon, setClearSearchIcon] = useState(false);
    const [searchInpuOpen, setSearchInpuOpen] = useState(false);
    const [searchKeys, setSearchKeys] = useState("");
    const [category, setCategory] = useState([]);
    const [inventoryStatus, setInventoryStatus] = useState([]);
    const [schoolStatus, setSchoolStatus] = useState([]);
    const [city, setCity] = useState({ name: '' });
    const [allCities, setAllCities] = useState([]);
    const [location, setLocation] = useState([]);
    const [filterByLanguage, setFilterByLanguage] = useState([])
    const [filterByBoard, setFilterByBoard] = useState([])
    const [filterBySchoolType, setFilterBySchoolType] = useState([])
    const [selectedTab, setSelectedTab] = useState("all")
    const [alphabaticOrder, setAlphabaticOrder] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [activeSchool, setActiveSchool] = useState(0)
    const [activeSchoolLastMonth, setactiveSchoolLastMonth] = useState(0)
    const [topLocation, setTopLocation] = useState("")
    const [topPerformingInventory, setTopPerformingInventory] = useState("")
    const [campaignRejectedSchoolCount, setCampaignRejectedSchoolCount] = useState(0)
    const [locationByCities, setLocationByCities] = useState([])
    const [update, forceUpdate] = useState(false);
    const [sortingData, setSortingData] = useState({ _id: -1 })
    const [totalSchools, setTotalSchools] = useState(0)
    const [formValidator] = useState(useRef(new SimpleReactValidator({
        element: (message) =>

            <span className="valError"><img src={errorSVG} />{message}</span>
    })))
    const [downloadSchoolLoading, setDownloadSchoolLoading] = useState(false);
    useEffect(() => {
        if (update === true) {
            formValidator.current.showMessages()
        } else {
            formValidator.current.hideMessages()
        }
    }, [update])
    useEffect(() => {
        getAllSchoolsList();
    }, [currentPage, searchKeys])
    useEffect(() => {
        if (currentPage !== 1) {
            setCurrentpage(1)
        }
    }, [searchKeys])

    useEffect(() => {
        // getSchoolStats();
        // getSchoolCities();
    }, [])
    useEffect(() => {
        // getSchoolLocation();
    }, [city])

    // const getSchoolStats = async () => {
    //     let resp = await context.apis.public.getSchoolsStatsForBackendAdmin(
    //         context.validator, {}, {}
    //     )
    //     console.log(resp)
    //     if (resp && resp.done) {
    //         setActiveSchool(resp.response.activeSchools)
    //         setactiveSchoolLastMonth(resp.response.activeSchoolsLastMonth)
    //         setTopLocation(resp.response.topLocations)
    //         setTopPerformingInventory(resp.response.topPerformingInventory)
    //         if (resp.response.multipleCampaignsRejected && Array.isArray(resp.response.multipleCampaignsRejected)) {
    //             setCampaignRejectedSchoolCount(resp.response.multipleCampaignsRejected.length)
    //         }
    //         else {
    //             setCampaignRejectedSchoolCount(0)
    //         }
    //     }
    // }

    // const getSchoolCities = async () => {
    //     //cities filter api
    //     let resp = await context.apis.public.getAllCitiesBySchool(
    //         context.validator, {}, {}
    //     )
    //     console.log('SchoolsCities======>', resp)
    //     if (resp && resp.done) {
    //         setAllCities(resp.response)
    //     }
    // }
    // const getSchoolLocation = async () => {
    //     //locations filter api
    //     if (city.name) {
    //         let resp = await context.apis.public.geLocationsByCity(
    //             context.validator, { cities: city.name }, {}
    //         )
    //         console.log('SchoolsLocations======>', resp)
    //         if (resp && resp.done) {
    //             let locationCity = [];
    //             resp.response.rows.map(locations => locationCity.push(...locations.locations))
    //             setLocationByCities(locationCity)
    //         }
    //     }
    // }

    useEffect(() => {
        let state:any = history.location.state
        if(state && state.campaignId && state.campaignName){
            setCampaignId(state.campaignId)
            setCampaignName(state.campaignName)
        }
    }, [])

    const getAllSchoolsList = async () => {
        setIsLoading(true)
        let state:any = history.location.state
        var localVar = Math.random()
        backendSchoolListGlobalVar = localVar
        let resp = await context.apis[context.user.role].getAssignedSchoolsForFOS(
            context.validator, {fosId: context.user._id, campaignId: state.campaignId, board: [], language: [], state: [], city: [], searchValue: searchKeys}, { limit: pageLimit, page: currentPage, withCount: true, }
        )
        console.log('getAssignedSchoolsForFOS', resp)

        if (resp && resp.done) {
            if (localVar === backendSchoolListGlobalVar) {
                setSchoolList(resp.response.rows);
                // setTotalSchools(resp.count)
                // let data: any = resp
                // setCountOfStatus({
                //     all: selectedTab === "all" ? data.count : data.allCount.all,
                //     active: selectedTab === "active" ? data.count : data.allCount.active,
                //     rejected: selectedTab === "inactive" ? data.count : data.allCount.inactive,
                //     pending: selectedTab === "pendingApproval" ? data.count : data.allCount.pendingApproval,
                // })
                // let total = Math.ceil(resp.response.rows.length / pageLimit);
                let total = Math.ceil(resp.response.count / pageLimit);
                setTotalPageCount(resp.response.count);
                let arr = []
                for (let i = 0; i < total; i++) {
                    arr.push(i)
                }
                setTotalPage([...arr])
            }
        }
        setIsLoading(false)
    }

    const onPageChange = (page) => {
        setCurrentpage(page)
    }
    const onNextClick = () => {
        setCurrentpage(prev => prev + 1)
    }
    const onPrevClick = () => {
        setCurrentpage(prev => prev - 1)
    }

    const changeActiveTab = (tab) => {
        if (tab === selectedTab) {
            return
        }
        setSelectedTab(tab)
        setSearchKeys("")
        setInventoryStatus([])
        setSchoolStatus([])
        setCurrentpage(1)
    }
    const redirectToSchoolOnboarding = () => {
        history.push("/backend/backend-details/schools/onboarding/your-details")
    }

    // const getExportableData1 = async () => {

    //     console.log("--getExportableData---check here==new==>")
    //     setDownloadSchoolLoading(true);

    //     let currentPage = 1;
    //     let resp = await context.apis.public.getAllSchoolsForBackendUsers(
    //         context.validator, {
    //         tabStatus: selectedTab, searchValue: searchKeys === "" ? null : searchKeys, language: filterByLanguage, board: filterByBoard, schoolType: filterBySchoolType,
    //         category: category, inventoryStatus: inventoryStatus, schoolStatus: [...schoolStatus.map(item => item === "approved")], locations: location, city: [city.name],
    //         IsDownload: "true"
    //     }, { page: currentPage, withCount: true, sort: sortingData }
    //     )
    //     //console.log('getAllSchoolsForBackendUsers ###########', resp)
    //     let data = []
    //     let test = await resp.response.forEach((schoolData, index) => {
    //         let schoolDetails = {}
    //         schoolDetails['SL NO'] = ++index;
    //         schoolDetails['SCHOOL NAME'] = schoolData.schoolName;
    //         schoolDetails['BOARD'] = schoolData.board;
    //         schoolDetails['CATEGORY'] = schoolData.category;
    //         schoolDetails['STATE'] = schoolData.state;
    //         schoolDetails['CITY'] = schoolData.city;
    //         schoolDetails['Address'] = schoolData.address;
    //         schoolDetails['STUDENTS'] = schoolData.totalStudents;
    //         schoolDetails['INVENTORIES'] = schoolData.inventories;
    //         schoolDetails['EVENTS & OCCASIONS'] = schoolData.activities;
    //         schoolDetails['CAMPAIGNS RUN'] = schoolData.campaigns;
    //         schoolDetails['SCHOOL STATUS'] = schoolData.initialApproval ? "Approved" : "New Added";
    //         schoolDetails['INVENTORY STATUS'] = schoolData.collectiveInventoryStatus;
    //         data.push(schoolDetails);
    //     });

    //     const options = {
    //         fieldSeparator: ',',
    //         quoteStrings: '"',
    //         decimalSeparator: '.',
    //         showTitle: false,
    //         filename: 'SchoolsList',
    //         useTextFile: false,
    //         useKeysAsHeaders: true,
    //         //headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
    //     };
    //     setDownloadSchoolLoading(false);
    //     const csvExporter = new ExportToCsv(options);
    //     csvExporter.generateCsv(data);
    // }

    const handleChange = (e) => {
        if(e === ""){
            setSearchKeys("")
        }
        else{
            setSearchKeys(e.target.value)
        }
    }
    
    return (
        <>

            <div className="" style={{ width: "100%" }}>
                <div className="logo-header header-flex-container">
                    {/* <div className="logos"> */}
                        <img onClick={() => history.goBack()} className="back-arrow" src="/assets/FOSimg/back-arrow.png" />
                        <img className="wisrLogo" src="/assets/FOSimg/wisr-logo.png" />
                    {/* </div> */}
                    {/* <div className="logos"> */}
                        <img className="maskgrouplogo" src="/assets/FOSimg/maskgrouplogo.png" />
                    {/* </div> */}
                </div>


                <div className="ws-container">

                    <div className="heading headTitleContainer">
                        <div className="headTitle headerTitleText">
                            <h2>School List</h2>
                        </div>

                        <div className="headTitle searchBoxContainer">
                            {/* <div className="searchBox">
                                <img className="searchIcon" src="/static/media/search.a6944e87.svg" alt="search" />
                            </div> */}
                            <CustomSearchInput
                                searchInpuOpen={searchInpuOpen}
                                setSearchInpuOpen={setSearchInpuOpen}
                                clearSearchIcon={clearSearchIcon}
                                setClearSearchIcon={setClearSearchIcon}
                                handleChange={handleChange}
                                searchKeys={searchKeys}
                            />
                        </div>
                    </div>

                    {/* CardBox starts  */}
                    {isLoading ? 
                        <Loader />
                        :
                        schoolList.length > 0 ?
                        schoolList.map((item, index) => (
                            <div className="panel-group" id={`accordion${index}`} onClick={() => history.push({pathname: '/backend/backend-details/create-new-camp-set', state: {campaignId: campaignId, schoolId: item._id, schoolName: item.schoolName, campaignName: campaignName}})}>

                                {/* <!-- First Panel --> */}
                                <div className="panel panel-default">
                                    <div className="panel-heading">
                                        <h4 className="panel-title panel-title-school"><strong>{item.schoolName} </strong> </h4>
                                        {/* <p className="panel-title-pg" style={{ color: "#8A8A8A", fontSize: "12px" }}> {titleCase(item.city)} <span style={{
                                            content: "",
                                            position: "absolute",
                                            
                                            top: "155px",
                                            width: "5px",
                                            height: "5px",
                                            backgroundColor: "#8A8A8a",
                                            borderRadius: "50%",
                                            

                                        }}> </span> &nbsp; &nbsp;Jogeshwari East </p> */}

                                        <div className="schoolNameTable detailsFosList" ><span style={{color: "#8a8a8a", fontSize: "12px"}}>{titleCase(item.city)}</span>{item.localArea && <span style={{color: "#8a8a8a", fontSize: "12px"}}>{titleCase(item.localArea)}</span>}</div>

                                        {/* <div className="row">
                                            <div className="col-xs-3">
                                            <span> Mumbai Suburban </span>
                                            </div>

                                            <div className="col-xs-3">

                                            </div>

                                            <div className="col-xs-3">
                                            <span> Jogeshwari East </span>
                                            </div>
                                        </div> */}


                                        {/* <div className="row mt10">

                                            <div className="col-sm-2 col-md-6">
                                                <span style={{ color: "#8A8A8A", fontSize: "12px" }}>INVENTORIES </span><br />
                                                <strong> 2 </strong>
                                            </div>

                                            <div className="col-sm-8 col-md-6">
                                                <span style={{ color: "#8A8A8A", fontSize: "12px" }}> SCHEDULE </span><br />
                                                <strong>02 OCT, 22-11 NOV, 22 </strong>
                                            </div>
                                        </div> */}

                                        <div className="cl-row mt10">
                                            <div className="col-sm-6 col-md-6">
                                                <span className="text-head">STATUS </span><br />
                                                {/* <strong style={{color:"#2EC193"}}>Completed</strong> */}
                                                <strong className={item.setApprovalStatus.toLowerCase() === "not-started" ? 'status-gray' : item.setApprovalStatus.toLowerCase() === "in-progress" ? 'status-orange' : item.setApprovalStatus.toLowerCase() === "completed" ? "greenbg" : item.setApprovalStatus.toLowerCase() === "rejected" && "redbg"}>{item.setApprovalStatus.toLowerCase() === "rejected" ? "Actionable" : titleCase(item.setApprovalStatus.toLowerCase())}</strong>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                        :
                        "No schools found"
                    }
                </div>
                {totalPage.length > 1 &&
                    <Pagination
                        currentPage={currentPage}
                        totalPage={totalPage}
                        onPageChange={onPageChange}
                        onNextClick={onNextClick}
                        onPrevClick={onPrevClick}
                        loading={isLoading}
                    />
                }
            </div>
        </>
    )
}


export default CampaignSchoolList
