import { useState, useContext, useEffect } from "react"

import { useHistory } from "react-router-dom"
import search from "../../../assets/images/search.svg"
import arrowUpDown from "../../../assets/images/backendAdmin/arrow-up-down.svg"
import riarrownext from "../../../assets/images/right-arrow-next.svg"
import tabImgInventory from "../../../assets/images/tab-img-inventory.png"
import tableFilter from "../../../assets/images/table-filter.svg"
import arrowUp from "../../../assets/images/arrow-up.svg"
import filterSvg from "../../../assets/images/filter-svg.svg"
import filterArrowSvg from "../../../assets/images/filter-arrow-svg.svg"

import tabSlide1 from "../../../assets/images/tab-Slide1.png"
import TopBar from "../schools/components/topBar"
import AppContextObject from "../../../common/context/common"

import OnboardingBackBtn from "../schools/components/onboardingBackBtn"
import InventoryCard from "../schools/updateDetails/inventory/inventoryCard"
import Pagination from "../../../components/addon/pagination"
import CampaignApprovalModal from "../../../components/addon/campaignApprovalModal"
import RejectBrandRequestModal from "../../../components/addon/rejectBrandRequestModal"

import DataContext from "../../../service/contextProvider"

import { PublicContextInitiator } from "../../apis/publicContextInitiator"

import { Steps } from "intro.js-react"
import { formatDateToDDMMMYY, isObjectEmpty } from "../../../service/helper"

import toast from "react-hot-toast";
import InventoryTable from "./components/InventoryTable"
import SearchInput from "./components/SearchInput"
import EnterPriceModal from "../../../components/addon/enterPriceModal"
import BackendTopBar from "./components/backendTopBar"
import BackendBackBtn from "./components/backendBackBtn"

var backendInventoryListGlobalVar = 1

const InventoryList = () => {
    const context = useContext(AppContextObject);
    const publicContextInitiator = new PublicContextInitiator(context);
    const [inventories, setInventories] = useState([]);
    const [overlay, setOverlay] = useState(false);
    const [tabStatus, setTabStatus] = useState("all");
    const [isLoading, setIsLoading] = useState(false);
    const [sortByName, setSortByName] = useState(false);
    const [sortByOccupency, setSortByOccupency] = useState(false);
    const [inventoryCategory, setInventorySortByCategory] = useState([]);
    const [totalPage, setTotalPage] = useState([0]);
    const [currentPage, setCurrentPage] = useState(1);
    const [allCounts, setAllCounts] = useState<any>({});
    const [clearSearchIcon, setClearSearchIcon] = useState(false);
    const [searchInpuOpen, setSearchInpuOpen] = useState(false);
    const [searchKeys, setSearchKeys] = useState("");
    const [sortFilter, setSortFilter] = useState('');
    const [showPriceModal, setShowPriceModal] = useState(false);
    const [inventoryDetails, setInventoryDetails] = useState([])
    const [forceUpdate, setForceUpdate] = useState(null);
    const [countInitialize, setCountInitialize] = useState(false)
    

    useEffect(() => {
        getAllInventoriesList()
    }, [])
    useEffect(() => {
        allInventoriesList();
    }, [currentPage, tabStatus, searchKeys, sortByName, sortByOccupency, inventoryCategory,forceUpdate])

    const getAllInventoriesList = async() => {
        setIsLoading(true)
        let resp = await context.apis.public.getAllInventoriesForBackend(
                context.validator, { type: tabStatus }, { limit: 10, page: currentPage, withCount: true, sort: {_id: -1} }
            )
        console.log("getAllInventoriesForBackend", resp)
        if (resp && resp.done) {
            setIsLoading(false);
            setInventories(resp.response.rows);
            setAllCounts(resp.allCounts);
            setCountInitialize(true)
            let total = Math.ceil(resp.response.count / 10);
            let arr = []
            for (let i = 0; i < total; i++) {
                arr.push(i)
            }
            setTotalPage([...arr])

        }
    }
    
    const allInventoriesList = async () => {
        let sortValue;
        let resp
        // console.log(sortFilter, sortByName, sortByOccupency)
        if (sortFilter === 'name' && sortByName) {
            sortValue = {
                "name": 1
            }
        } else if (sortFilter === 'name' && !sortByName) {
            sortValue = {
                "name": -1
            }
        } else if (sortFilter === 'occupancy' && sortByOccupency) {
            sortValue = {
                "occupancyRate": 1
            }
        }
        else if (sortFilter === 'occupancy' && !sortByOccupency) {
            sortValue = {
                "occupancyRate": -1
            }
        }
        else if (sortFilter === '') {
            sortValue = {
                "_id": -1
            }
        }
        if(countInitialize){
            var localVar = Math.random()
            backendInventoryListGlobalVar = localVar
            if (sortValue === undefined) {
                setIsLoading(true);
                resp = await context.apis.public.getAllInventoriesForBackend(
                    context.validator, { type: tabStatus, search: searchKeys, category: inventoryCategory }, { limit: 10, page: currentPage, withCount: true, sort: { _id: -1 } }
                )
            } else {
                if(forceUpdate===null){
                    setIsLoading(true);
                }
                
                resp = await context.apis.public.getAllInventoriesForBackend(
                    context.validator, { type: tabStatus, search: searchKeys, category: inventoryCategory }, { limit: 10, page: currentPage, withCount: true, "sort": sortValue }
                )
            }

            console.log("getAllInventoriesForBackend", resp)
            if (resp && resp.done) {
                if(localVar === backendInventoryListGlobalVar){
                    setIsLoading(false);
                    setInventories(resp.response.rows);
                    if(countInitialize){
                        setAllCounts({...allCounts, [tabStatus]: resp.response.count});
                    }
                    let total = Math.ceil(resp.response.count / 10);
                    let arr = []
                    for (let i = 0; i < total; i++) {
                        arr.push(i)
                    }
                    setTotalPage([...arr])
                }
            }
            setIsLoading(false)
        }
    }
    const onPageChange = (page) => {
        setCurrentPage(page)
    }
    const onNextClick = () => {
        setCurrentPage(prev => prev + 1)
    }
    const onPrevClick = () => {
        setCurrentPage(prev => prev - 1)
    }
    const closeModal = () => {
        setShowPriceModal(false);
        setOverlay(false);
        setForceUpdate(null);
    }
    return (
        <>
            <div className={`ovelap ${overlay ? "active" : ""}`}></div>
            <div className="col-xl-10 col-lg-12 col-md-12 col-xs-12">
                <div className="rightPart noBack">
                    <div className="row">
                        <BackendTopBar />
                        <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-10 mt-20">
                            <h2 className="htx2 clearfix">List of Inventories</h2>
                        </div>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                            <div className="inventory-tableTabs">
                                <ul className="tabsinventory">
                                    <li><a href="javascript:void(0);" className={tabStatus === "all" ? "active" : ""} onClick={() => { setTabStatus('all'); setCurrentPage(1); setInventorySortByCategory([]); }}>All({allCounts.all})</a></li>
                                    <li><a href="javascript:void(0);" className={tabStatus === "unique" ? "active" : ""} onClick={() => { setTabStatus('unique'); setCurrentPage(1); setInventorySortByCategory([]); }}>unique({allCounts.unique})</a></li>
                                    <li><a href="javascript:void(0);" className={tabStatus === "events" ? "active" : ""} onClick={() => { setTabStatus('events'); setCurrentPage(1); setInventorySortByCategory([]); }}>events({allCounts.events})</a></li>
                                    <li><a href="javascript:void(0);" className={tabStatus === "custom" ? "active" : ""} onClick={() => { setTabStatus('custom'); setCurrentPage(1); setInventorySortByCategory([]); }}>custom({allCounts.custom})</a></li>
                                </ul>
                                <div className="inventorySearch schoolFilter">
                                    <SearchInput
                                        searchInpuOpen={searchInpuOpen} //true/false
                                        clearSearchIcon={clearSearchIcon} //true/false
                                        setSearchInpuOpen={setSearchInpuOpen} //function=>true/false
                                        setSearchKeys={setSearchKeys} //function=>string
                                        searchKeys={searchKeys} //string
                                        setClearSearchIcon={setClearSearchIcon}//function=>true/false
                                        palaceholder='Inventory name'
                                        setCurrentpage={setCurrentPage}
                                    />
                                </div>
                                <div className="table-container table-inventory mt-10 tableArrowRight">
                                    <InventoryTable
                                        inventories={inventories}
                                        isLoading={isLoading}
                                        setSortByName={setSortByName}
                                        sortByName={sortByName}
                                        onPageChange={onPageChange}
                                        onNextClick={onNextClick}
                                        onPrevClick={onPrevClick}
                                        currentPage={currentPage}
                                        totalPage={totalPage}
                                        setSortByOccupency={setSortByOccupency}
                                        sortByOccupency={sortByOccupency}
                                        setInventorySortByCategory={setInventorySortByCategory}
                                        inventoryCategory={inventoryCategory}
                                        tabStatus={tabStatus}
                                        setSortFilter={setSortFilter}
                                        setCurrentPage={setCurrentPage}
                                        setShowPriceModal={setShowPriceModal}
                                        setOverlay={setOverlay}
                                        setInventoryDetails={setInventoryDetails}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="approveActive" className={`popup popup-center hide`}>
                <div className="popup-header">
                    <h3 className="htx2 pophead">Request Sent Successfully!</h3>
                    <span className="close toggle" data-target="myPopup">close</span> </div>
                <div className="popup-body">
                    <div className="viewdetailsHead">
                        <div className="approveActive">
                            <p>A request to add has been sent to your school's admin. </p>
                            <div className="brand-btn">
                                <a href="javascript:void(0)" className="btn btn-orange">DONE</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <EnterPriceModal
                showPriceModal={showPriceModal}
                closeModal={closeModal}
                inventoryDetails={inventoryDetails}
                setForceUpdate={setForceUpdate}
                forceUpdate={forceUpdate}
            />
        </>
    )
}


export default InventoryList
