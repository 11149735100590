import search from "../../../../assets/images/search.svg"
import notification from "../../../../assets/images/noti-bell.svg"
import OnboardingBackBtn from "../components/onboardingBackBtn"
import backBtn from "../../../../assets/images/back-arrow.svg"
import proEdit from "../../../../assets/images/edit-profile.svg"
import addAdmin from "../../../../assets/images/add-admin1.svg"
import studentImgbg from "../../../../assets/images/student-imgbg.png"
import passShow from "../../../../assets/images/passShow.svg"
import React, { useState, useContext, useEffect } from "react"
import AppContextObject from "../../../../common/context/common"
import moment from "moment"
import toast from "react-hot-toast"
import Textfield from "../../../../components/formFields/textField/textField"
import PasswordField from "../../../../components/formFields/passwordField"
import { validation } from "../../../../service/validators"
import ConfirmDialog from "../../../../components/addon/confirmDialog/confirmDialog"
import { useHistory } from "react-router"
import { eraseAllCookies, eraseCookie } from "../../../../service/helper"
import DotDrop from "../../../../components/addon/dotDrop"
import OTPVerifyModal from "../../../../components/addon/otpVerifyModal"
import AlertDialog from "../../../../components/addon/alertDialog/alertDialog"
import TopBar from "../components/topBar"
import errorSVG from "../../../../assets/images/error.svg";

const Settings = () => {
    const context = useContext(AppContextObject)
    const history = useHistory();

    const [formData, setFormData] = useState({
        oldPassword: "",
        newPassword: "",
        confirmNewPassword: "",
    });
    const [userNotificationFlags, setUserNotificationFlags] = useState({
        emailNotification: false,
        webPushNotification: false,
        smsNotification: false,
        allNotifications: false,
        allMutedNotifications: false
    })
    let [toggleNotificationDeselectModal, setToggleNotificationDeselectModal] = useState(false)
    let [toggleMutedNotificationDeselectModal, setToggleMutedNotificationDeselectModal] = useState(false)
    let [selectedNotificationName, setSelectedNotificationName] = useState("")
    const [overlay, setOverlay] = useState(false)
    const [locationActivity, setLocationActivity] = useState([])
    const [inputError, setInputError] = useState(null)
    const [formErrors, setFormErrors] = useState<any>({})
    const [loginError, setLoginError] = useState(null)
    const [toggleUpdateConfirm, setToggleUpdateConfirm] = useState(false)
    const [updatePasswordComponent, setSelectedMainAmenityToDelete] = useState("")
    const [refetch, setRefetch] = useState(false)
    const [toggleAlertPopUp, setToggleAlertPopUp] = useState(false);
    const [notificationsUpdated,setNotificationsUpdated] = useState(false)
    const [loadCount, setLoadCount] = useState(3)
    const [totalLocationCount, setTotalLocationCount] = useState(3)

    const [showModal, setShowModal] = useState(false);
    const [isOldPasswordVerified, setIsOldPasswordVerified] = useState(false);
    const [getVerifyOldPasswordStatus, setGetVerifyOldPasswordStatus] = useState(false);
    const [selectedContactForOTP, setSelectedContactForOTP] = useState("");
    const [selectedEmailForOTP, setSelectedEmailForOTP] = useState("");
    const [msgDetails, setMsgDetails] = useState('');
    const [inProgressEmailAndMobile, setInProgressEmailAndMobile] = useState(false)
    const [isUpdatePasswordLoading, setIsUpdatePasswordLoading] = useState(false)
    // const [userNotificationFlags, setUserNotificationFlags] = useState([
    //     {id: "emailNotification", value: "false"},
    //     {id: "webPushNotification", value : "false"},
    //     {id: "smsNotification", value: "false"}, 
    //     {id: "allNotifications", value : "false"}]
    // )

    useEffect(() => {
        getNotificationSettings();
        getUserLocationActivities();
    }, [loadCount, refetch])


    const getNotificationSettings = async () => {
        let resp = await context.apis[context.user.role].getLoggedInUser(
            context.validator, {}
        )
        if (resp && resp.done) {
            // console.log("notification settings",resp.response);
            // const arr = objectDeepClone(resp.response)
            // const newArr = []
            // userNotificationFlags.forEach(element => {
            //     newArr.push({id: element.id, value : arr[element.id]})
            // });
            // setUserNotificationFlags(newArr);
            setUserNotificationFlags({
                emailNotification: resp.response.emailNotification,
                webPushNotification: resp.response.webPushNotification,
                smsNotification: resp.response.smsNotification,
                allNotifications: resp.response.allNotifications,
                allMutedNotifications: resp.response.mutedNotificationCategories && resp.response.mutedNotificationCategories.length > 0 ? false : true
            })
        }
    }

    const getUserLocationActivities = async () => {
        let resp = await context.apis[context.user.role].getAllUserLocation(
            context.validator, {ipAddress: sessionStorage.getItem('ip')}, {limit: loadCount, withCount: true, sort:{createdOn: -1}}
        )
        if (resp && resp.done) {
            setTotalLocationCount(resp.response.count)
            setLocationActivity(resp.response.rows)
        }
    }

    const hanldeNotificationFlags = (e) => {
        if (e.target.value !== "true") {
            
            if(e.target.id === "allNotifications"){
                setUserNotificationFlags({
                    ...userNotificationFlags,
                    smsNotification: true,
                    emailNotification: true,
                    webPushNotification: true,
                    allNotifications: true
                })
            }else{
                if(e.target.id === "allMutedNotifications"){
                    setOverlay(true)
                    setToggleMutedNotificationDeselectModal(true)
                }else{
                    setUserNotificationFlags({
                        ...userNotificationFlags,
                        [e.target.id]: true
                    })
                }
                
            }
            if(e.target.id === "allMutedNotifications"){

            }else{
                modifyNotificationSettings(e.target.id)
            }
        }
        else {
            if(e.target.id === "allMutedNotifications"){
                setOverlay(true)
                setMsgDetails("No notifications are muted currently.")
                setToggleAlertPopUp(true)
            }else{
                setOverlay(true)
                setToggleNotificationDeselectModal(true)
            }
        }

        setSelectedNotificationName(e.target.id)
    }

    const onFormDataChange = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value
        })

        if (e.target.id == "oldPassword") {
            if(e.target.value.length > 0){
                setFormErrors({...formErrors, oldPassword: ""})
            }
            if (formData.oldPassword != e.target.value) {
                if (e.target.parentNode.querySelectorAll(".vrifiyotp")[0].className.indexOf("complete") >= 0) {
                    e.target.parentNode.querySelectorAll(".vrifiyotp")[0].className = e.target.parentNode.querySelectorAll(".vrifiyotp")[0].className.replace(/ complete/gi, "");
                    setIsOldPasswordVerified(false)
                    setGetVerifyOldPasswordStatus(false)
                }
            }
        }
        if(e.target.id == "newPassword") {
            if(e.target.value.length < 1){
                setFormErrors({ ...formErrors, newPassword: "New password field is required." })
            }else if(!(/^.{8,40}$/).test(e.target.value)) {
                setFormErrors({ ...formErrors, newPassword: "Password must have at least 8 characters." })
            }
            else {
                setFormErrors(null)
            }
            
        }
    }
    const changePassword = async () => {

        if (getVerifyOldPasswordStatus == false) {
            setMsgDetails('You need to verify your old password to continue, Just click on verify to get OTP.')
            setOverlay(true)
            setToggleAlertPopUp(true)
            return
        }
        const errors = validation(formData)
        setFormErrors(errors)
        if (Object.keys(errors).length > 0) {
            return
        }
        setInputError("")
        // if(!formData.oldPassword || !formData.newPassword || !formData.confirmNewPassword ){
        //     setInputError("please fill the form")
        // }

        if (formData.newPassword !== formData.confirmNewPassword) {
            // setInputError("confirm password does not matched")
            setFormErrors({ confirmNewPassword: "The password and confirm password do not match." })
        }
        else {
            setToggleUpdateConfirm(true)
        }

    }
    const onVerifyOldPasswordClick = async () => {
        let formDataValidate = {}
        Object.assign(formDataValidate, { oldPassword: formData.oldPassword })
        const errors = validation({ formDataValidate })
        setFormErrors(errors)
        if (Object.keys(errors).length > 0) {
            return
        }

        if (formData.oldPassword == ''){
            setFormErrors({ oldPassword: "input password then click Verify" })
            return
        }

        setInProgressEmailAndMobile(true)
        let resp = await context.apis[context.user.role].oldPasswordSendOtpEmailPhone(
            context.validator, { "inputPassword": formData.oldPassword, "email" : context.user.email, "phone" : context.user.phone }
        )
        if (resp?.done) {

            setGetVerifyOldPasswordStatus(true)
            setSelectedContactForOTP(context.user.phone)
            setSelectedEmailForOTP(context.user.email)
            setShowModal(true)
            setOverlay(false)
            setToggleAlertPopUp(false)
        }
        else {
            setFormErrors({ oldPassword: "The password you entered is incorrect."})
            setInProgressEmailAndMobile(false)
        }
    }

    const onModalSubmit = (otp) => {
        setIsOldPasswordVerified(true);
    }

    const modifyNotificationSettings = async (notificationType, value?: Boolean) => {
        let data = {};

        if(notificationType === "emailNotification"){
            data = {emailNotification: value !== undefined ? value : true}
        }

        if(notificationType === "webPushNotification"){
            data = {webPushNotification: value !== undefined ? value : true}
        }

        if(notificationType === "smsNotification"){
            data = {smsNotification: value !== undefined ? value : true}
        }

        if(notificationType === "allMutedNotifications"){
            data = {mutedNotificationCategories: []}
        }

        if(notificationType === "allNotifications"){
            data = {
                allNotifications: value !== undefined ? value : true,
                emailNotification: value !== undefined ? value : true,
                webPushNotification: value !== undefined ? value : true,
                smsNotification: value !== undefined ? value : true,
                mutedNotificationCategories: []
            }
        }

        let resp = await context.apis[context.user.role].updateNotificationSettings(
            context.validator, {}, data
        )
        if(resp && resp.done){
            if(notificationType === "allMutedNotifications"){
                if(context && context.user && context.user.mutedNotificationCategories !== undefined){
                    context.user.mutedNotificationCategories = []
                }
            }
            setNotificationsUpdated(!notificationsUpdated)
        }
    }  

    const updateConfirmClose = async (confirm) => {
        if (confirm === true) {
            setIsUpdatePasswordLoading(true)
            let resp = await context.apis[context.user.role].changeUserPassword(
                context.validator, {}, { password: formData.newPassword, oldPasswords: [formData.oldPassword] }
            ).catch(err => 
                setIsUpdatePasswordLoading(false)
            )
            if (resp && !resp.done) {
                setInputError(resp.msg)
                setIsUpdatePasswordLoading(false)
            } else {
                setIsUpdatePasswordLoading(false)
                setInputError("")
                setFormData({
                    oldPassword: "",
                    newPassword: "",
                    confirmNewPassword: "",
                })
                // toast.success("Password changed successfully")
                // eraseAllCookies();
                // eraseCookie("session-wisr-core-microservice-backend")
                // context.socketManager.socket.disconnect();
                // history.push("/login")
                logoutUser()
            }
        }
        setToggleUpdateConfirm(false)
    }

    const logoutUser = () =>{
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
        };

        fetch("/api/logout", requestOptions)
        .then(response => response.json())
        .then(data => {
            if(data.done){
                eraseAllCookies();
                eraseCookie("session-wisr-core-microservice-backend")
                context.socketManager.socket.disconnect();
                document.location.href = "/login"
                sessionStorage.clear()
            }
        }).catch(err=>{
            console.log(err);
        })
    }

    const notificationConfirmDeselect = (confirm) => {
        if (confirm) {
            if(selectedNotificationName === "allNotifications"){
                setUserNotificationFlags({
                    ...userNotificationFlags,
                    smsNotification: false,
                    emailNotification: false,
                    webPushNotification: false,
                    allNotifications: false
                })
            }else{
                setUserNotificationFlags({
                    ...userNotificationFlags,
                    [selectedNotificationName]: false
                })
            }
            modifyNotificationSettings(selectedNotificationName, false)
        }
        setOverlay(false)
        setToggleNotificationDeselectModal(false)
    }

    const mutedNotificationDeselect = (confirm) => {
        if (confirm) {
            if(selectedNotificationName === "allMutedNotifications"){
                setUserNotificationFlags({
                    ...userNotificationFlags,
                    allMutedNotifications: true
                })
            }else{
                setUserNotificationFlags({
                    ...userNotificationFlags,
                    [selectedNotificationName]: true
                })
            }
            modifyNotificationSettings(selectedNotificationName, false)
        }
        setOverlay(false)
        setToggleMutedNotificationDeselectModal(false)
    }

    console.log("notificationflags",userNotificationFlags);

    const confirmClose = () => {
        setOverlay(false)
        setToggleAlertPopUp(false)
    }
    const onOTPModalClose = () => {
        setInProgressEmailAndMobile(false);
    }
    const refetchLocations = () =>{
        setRefetch(!refetch)
    }
    return (
        <>
            <div className={`ovelap ${(showModal === true || overlay === true || toggleUpdateConfirm === true) ? "active" : ""}`}></div>
            <AlertDialog
                togglePopup={toggleAlertPopUp}
                onModalClose={confirmClose}
                displayMsg={msgDetails}
            />
            <div className="col-xl-10 col-lg-12 col-md-12 col-xs-12">
                <div className="rightPart noBack">
                    <div className="row">
                        <div className="col-xs-12 col-lg-12 col-md-12 col-xs-12">
                            <OnboardingBackBtn
                                link="/"
                                text="Log Out"
                            />
                        </div>
                        <TopBar isNotificationsUpdate={notificationsUpdated} />
                        <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-20">
                            <h2 className="htx2 clearfix">Change Password</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-7 col-lg-9 col-md-10 col-xs-12 mt-30 settingInput">
                            <div className="row">
                                <div className="col-xl-8 col-lg-8 col-md-9 col-xs-12 pl-0">
                                    {/* <div className="forminpt"> */}
                                    {/* <label>Old Password</label> */}
                                    {/* <input placeholder="Password" type="text" id="oldPassword" value={formData.oldPassword} onChange={(e)=>onFormDataChange(e)} /> */}
                                    <PasswordField
                                        label="Old Password"
                                        isRequired={false}
                                        placeholder="Password"
                                        formData={formData}
                                        formErrors={formErrors}
                                        formKey="oldPassword"
                                        onFormDataChange={onFormDataChange}
                                        showForgotPasswordLink={false}
                                        loginError={loginError}
                                        onVerifyClick={onVerifyOldPasswordClick}
                                        isVerified={isOldPasswordVerified}
                                        isDisabled={false}
                                        inProgress={inProgressEmailAndMobile}
                                        isOldPassword={true}
                                        isVerifyClickDisabled={formData.oldPassword.length < 1 ? true : false}
                                        hideIcon={true}
                                    />
                                    {/* <img src={passShow} className="passShow" /> */}
                                    {/* </div> */}
                                </div>
                                </div>
                            <div className="col-xl-8 col-lg-8 col-md-9 col-xs-12 mb-20 pl-0">
                                {/* <div className="forminpt">
                                        <label>New Password</label>
                                        <input placeholder="Password" type="password" id="newPassword" value={formData.newPassword} onChange={(e)=>onFormDataChange(e)} />
                                        <img src={passShow} className="passShow" /> */}
                                <PasswordField
                                    label="New Password"
                                    isRequired={false}
                                    placeholder="Password"
                                    formData={formData}
                                    formErrors={isOldPasswordVerified ? formErrors : ""}
                                    formKey="newPassword"
                                    onFormDataChange={onFormDataChange}
                                    showForgotPasswordLink={false}
                                    loginError={loginError}
                                    isDisabled={!isOldPasswordVerified}
                                    maxLength={40}
                                    helpText="Must have minimun 8 characters"
                                    hideIcon={true}
                                />
                            </div>
                            <div className="col-xl-8 col-lg-8 col-md-9 col-xs-12 mb-20 pl-0">
                                {/* <div className="forminpt">
                                        <label>Confirm Password</label>
                                        <input placeholder="Password" type="password" id="confirmNewPassword" value={formData.confirmNewPassword} onChange={(e)=>onFormDataChange(e)} />
                                        <img src={passShow} className="passShow" /> */}
                                <PasswordField
                                    label="Confirm Password"
                                    isRequired={false}
                                    placeholder="Password"
                                    formData={formData}
                                    formErrors={isOldPasswordVerified ? formErrors : ""}
                                    formKey="confirmNewPassword"
                                    onFormDataChange={onFormDataChange}
                                    showForgotPasswordLink={false}
                                    loginError={loginError}
                                    isDisabled={!isOldPasswordVerified}
                                    maxLength={40}
                                    hideIcon={true}
                                />
                                {/* </div> */}
                            </div>
                            {
                                inputError && <div className="col-xl-8 col-lg-8 col-md-9 col-xs-12 pl-0">
                                    <span id="for_fullname" className="valError"><img src={errorSVG} />
                                        {inputError}
                                    </span>
                                </div>
                            }

                            <div className="col-xl-8 col-lg-8 col-md-9 col-xs-12 pl-0">
                                <div className="forminpt fR">
                                    <a href="javascript:void(0);" onClick={changePassword} className="btn btn-orange ml">Update Password</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-12 col-lg-12 col-md-9 col-xs-12 mt-20 mb-30">
                            <div className="line-full"></div>
                        </div>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-30 mb-30">
                            <h2 className="htx2 clearfix">Notifications</h2>
                        </div>

                        <div className="col-xl-6 col-lg-8 col-md-9 col-xs-12 pl">
                            <div className="row">
                                <div className="col-xl-9 col-lg-9 col-md-9 col-xs-12">
                                    <div className="notification-showhide">
                                        <h4>Email Notifications</h4>
                                        <p>Receive dashboard updates on your registered email ID.</p>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-3 col-md-3 col-xs-12">
                                    <div className="ava-brad fR">
                                        <div className="content">
                                            <label className="switch">
                                                <input type="checkbox" id="emailNotification" value={userNotificationFlags.emailNotification ? "true" : "false"} checked={userNotificationFlags.emailNotification} onChange={(e) => hanldeNotificationFlags(e)} className="shohide" data-cls="onclass1" />
                                                <span className="slider round"></span> </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-8 col-md-9 col-xs-12 mt-20 mb-20 pl">
                            <div className="line-full"></div>
                        </div>
                        <div className="col-xl-6 col-lg-8 col-md-9 col-xs-12 pl">
                            <div className="row">
                                <div className="col-xl-9 col-lg-9 col-md-9 col-xs-12">
                                    <div className="notification-showhide">
                                        <h4>Web Push notifications</h4>
                                        <p>Receive dashboard updates on your web browser.</p>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-3 col-md-3 col-xs-12">
                                    <div className="ava-brad fR">
                                        <div className="content">
                                            <label className="switch">
                                                <input type="checkbox" id="webPushNotification" value={userNotificationFlags.webPushNotification ? "true" : "false"} checked={userNotificationFlags.webPushNotification} onChange={(e) => hanldeNotificationFlags(e)} className="shohide" data-cls="onclass1" />
                                                <span className="slider round"></span> </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-8 col-md-9 col-xs-12 mt-20 mb-20 pl">
                            <div className="line-full"></div>
                        </div>
                        <div className="col-xl-6 col-lg-8 col-md-9 col-xs-12 pl">
                            <div className="row">
                                <div className="col-xl-9 col-lg-9 col-md-9 col-xs-12">
                                    <div className="notification-showhide">
                                        <h4>SMS notification</h4>
                                        <p>Receive dashboard updates via SMS on your registerd mobile number.</p>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-3 col-md-3 col-xs-12">
                                    <div className="ava-brad fR">
                                        <div className="content">
                                            <label className="switch">
                                                <input type="checkbox" id="smsNotification" value={userNotificationFlags.smsNotification ? "true" : "false"} checked={userNotificationFlags.smsNotification} onChange={(e) => hanldeNotificationFlags(e)} className="shohide" data-cls="onclass1" />
                                                <span className="slider round"></span> </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-8 col-md-9 col-xs-12 mt-20 mb-20 pl">
                            <div className="line-full"></div>
                        </div>
                        <div className="col-xl-6 col-lg-8 col-md-9 col-xs-12 pl">
                            <div className="row">
                                <div className="col-xl-9 col-lg-9 col-md-9 col-xs-12">
                                    <div className="notification-showhide">
                                        <h4>Muted notifications</h4>
                                        <p>Restart all muted notifications.</p>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-3 col-md-3 col-xs-12">
                                    <div className="ava-brad fR">
                                        <div className="content">
                                            <label className="switch">
                                                <input type="checkbox" id="allMutedNotifications" value={userNotificationFlags.allMutedNotifications ? "true" : "false"} checked={userNotificationFlags.allMutedNotifications} onChange={(e) => hanldeNotificationFlags(e)} className="shohide" data-cls="onclass1" />
                                                <span className="slider round"></span> </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-8 col-md-9 col-xs-12 mt-20 mb-20 pl">
                            <div className="line-full"></div>
                        </div>
                        <div className="col-xl-6 col-lg-8 col-md-9 col-xs-12 pl mb-30">
                            <div className="row">
                                <div className="col-xl-9 col-lg-9 col-md-9 col-xs-12">
                                    <div className="notification-showhide">
                                        <h4>All notifications</h4>
                                        <p>Enable or disable all notification from us.</p>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-3 col-md-3 col-xs-12">
                                    <div className="ava-brad fR">
                                        <div className="content">
                                            <label className="switch">
                                                <input type="checkbox" id="allNotifications" value={userNotificationFlags.allNotifications ? "true" : "false"} checked={userNotificationFlags.allNotifications} onChange={(e) => hanldeNotificationFlags(e)} className="shohide" data-cls="onclass1" />
                                                <span className="slider round"></span> </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-8 col-md-9 col-xs-12 mt-40 mb-50 pl">
                            <div className="line-full"></div>
                        </div>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-20 mb-30 pl">
                            <h2 className="htx2 clearfix">Location Activity</h2>
                        </div>
                        {
                            locationActivity.length > 0 ? locationActivity.map(location => {
                                return (
                                    <>
                                        <div className="col-xl-7 col-lg-9 col-md-10 col-xs-12 pl last-login">
                                            <div className="row">
                                                <div className="col-xl-11 col-lg-10 col-md-9">
                                                <svg className="location" width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fill-rule="evenodd" clip-rule="evenodd" d="M11.7077 9.37563C11.7077 7.87925 10.4952 6.66675 8.9999 6.66675C7.50352 6.66675 6.29102 7.87925 6.29102 9.37563C6.29102 10.8709 7.50352 12.0834 8.9999 12.0834C10.4952 12.0834 11.7077 10.8709 11.7077 9.37563Z" stroke="#070707" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path fill-rule="evenodd" clip-rule="evenodd" d="M8.99947 20.75C7.70112 20.75 0.875 15.2232 0.875 9.44356C0.875 4.91886 4.51186 1.25 8.99947 1.25C13.4871 1.25 17.125 4.91886 17.125 9.44356C17.125 15.2232 10.2978 20.75 8.99947 20.75Z" stroke="#070707" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                                                    <div className="notification-showhide">
                                                        <h4>{location.city || "Unknown"}, {location.conutry|| "India"}</h4>
                                                        <p>{moment(location.timestamp).fromNow()} | {location.operatingSystem || "Unknown"}</p>
                                                    </div>
                                                </div>
                                                <div className="col-xl-1 col-lg-2 col-md-3">
                                                    <DotDrop locationId={location._id || null} refetchLocations={refetchLocations} ip={location.ipAddress || null} />
                                                    {/* <svg className="dotdrop" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 18c1.657 0 3 1.343 3 3s-1.343 3-3 3-3-1.343-3-3 1.343-3 3-3zm0-9c1.657 0 3 1.343 3 3s-1.343 3-3 3-3-1.343-3-3 1.343-3 3-3zm0-9c1.657 0 3 1.343 3 3s-1.343 3-3 3-3-1.343-3-3 1.343-3 3-3z"/></svg> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-7 col-lg-9 col-md-10 col-xs-7 mt-20 mb-20 pl">
                                            <div className="line-full"></div>
                                        </div>
                                    </>
                                )
                            })
                            :
                            <p>No activity found.</p>
                        }
                        {
                            loadCount < totalLocationCount
                            &&
                            <div className="col-xl-7 col-lg-9 col-md-10 col-xs-12 pl last-login mb-30">
                            <div className="row">
                                <div className="col-xl-12 col-lg-12 col-md-12">
                                <div className="notification-showhide load-more">  
                                    <a href="javascript:void(0);" onClick={()=> setLoadCount(loadCount + 3)} className="viewwLink">LOAD MORE</a>
                                </div>
                                </div>
                                </div>
                            </div>
                        } 
                        

                    </div>
                </div>    
            <ConfirmDialog
                togglePopup={toggleNotificationDeselectModal}
                onModalClose={notificationConfirmDeselect}
                denyText = "No"
                componentName={
                    selectedNotificationName === "allNotifications" 
                    ? 
                    "Would you surely like to disable All notifications?"
                    :
                    selectedNotificationName === "emailNotification" 
                    ?
                    "Would you surely like to disable Email notifications? "
                    :
                    selectedNotificationName === "webPushNotification" 
                    ?
                    "Would you surely like to disable Push notifications?"
                    :
                    selectedNotificationName === "smsNotification" 
                    ?
                    "Would you surely like to disable SMS alerts?"
                    :
                    selectedNotificationName
                }
                confirmTitle={
                    selectedNotificationName === "allNotifications" 
                    ? 
                    "Turn Off All Notifications"
                    :
                    selectedNotificationName === "emailNotification" 
                    ?
                    "Turn Off Email Notification"
                    :
                    selectedNotificationName === "webPushNotification" 
                    ?
                    "Turn Off Web Push Notification"
                    :
                    selectedNotificationName === "smsNotification" 
                    ?
                    "Turn Off SMS Notification"
                    :
                    selectedNotificationName
                }
            />
            <ConfirmDialog
                togglePopup={toggleMutedNotificationDeselectModal}
                onModalClose={mutedNotificationDeselect}
                componentName={""}
                confirmTitle="Would you surely like to unmute and receive all notifications?"
                confirmText="Yes"
            />
            <ConfirmDialog
                togglePopup={toggleUpdateConfirm}
                onModalClose={updateConfirmClose}
                componentName={updatePasswordComponent}
                confirmTitle="Are you sure you want to update the password? You will be logged out and redirect to login screen"
                confirmText="Update & Log out"
                isLoading={isUpdatePasswordLoading}
            />
            <AlertDialog 
                togglePopup={toggleAlertPopUp}
                onModalClose={confirmClose}
                displayMsg={msgDetails}
                showBtn={false}
            />
            <OTPVerifyModal
            contact={selectedContactForOTP}
            email={selectedEmailForOTP}
            isMobile={false}
            showModal={showModal}
            setShowModal={setShowModal}
            onModalSubmit={onModalSubmit}
            isMobileAndEmail={true}
            onClose={onOTPModalClose}
            />
        </>
    )
}

export default Settings
