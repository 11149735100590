import { useState, useContext, useEffect, useRef } from "react";

import { useHistory, useLocation } from "react-router-dom";

import Pagination from "../../../../components/addon/pagination";
import arrowView from "../../../../assets/images/arrow-view.svg";
import arrowViewRound from "../../../../assets/images/arrow-view-round.svg";
import tableFilter from "../../../../assets/images/table-filter.svg";
import TopBar from "../../schools/components/topBar";
import AppContextObject from "../../../../common/context/common";
import {
	checkIfNumberOnly,
	compareObjs,
	formatDateShowMonth,
	isValidId,
	titleCase,
	validateCampaignName,
} from "../../../../service/helper";
import DataContext from "../../../../service/brandContext";
import SimpleReactValidator from "simple-react-validator";
import errorSVG from "../../../../assets/images/error.svg";
import TextField from "../../../../components/formFields/textField/textField";
import SliderComponent from "../../../../components/addon/rangeSlider";
import SingleSelect from "../../../../components/formFields/singleSelect/singleSelect";
import config from "../../../../service/config";
import TextArea from "../../../../components/formFields/textArea";
import CustomDatePicker from "../../../../components/formFields/datePicker";
import moment from "moment";
import MultiSelect from "../../../../components/formFields/multiSelect/multiSelect";
import ConfirmDialog from "../../../../components/addon/confirmDialog/confirmDialog";
import ComaSeperatedNumField from "../../../../components/formFields/comaSeperatedInput";
import NumberFormat from "react-number-format";
import helpImg from "../../../../assets/images/help.svg";
import { Steps } from "intro.js-react";
import BrandBackBtn from "../components/brandBackBtn";
import { ReactInternetSpeedMeter } from "react-internet-meter";
import Loader from "../../../../components/addon/loader";
import { type } from "os";
import { ClassNames } from "@emotion/react";
const { detect } = require("detect-browser");

const SetUpCampaign = ({
	setShowProgressSideMenu,
	toolTipLoaded,
	isTrailCampaign,
}) => {
	const history = useHistory();
	const location = useLocation();
	const [formValidator] = useState(
		useRef(
			new SimpleReactValidator({
				element: (message, className) => (
					<span className="valError">
						<img src={errorSVG} />
						{message === "The study medium field is required."
							? "The medium of study field is required."
							: message === "The value field is required."
							? "The brand sub-category field is required."
							: message}
					</span>
				),
			})
		)
	);
	const [update, forceUpdate] = useState(false);
	const context = useContext(AppContextObject);
	const parentData = useContext<any>(DataContext);
	const [formData, setFormData] = useState({
		...parentData.data.brandDetail,

		countryRegion: parentData?.data?.brandDetail?.countryRegion ?? [],
		state: parentData?.data?.brandDetail?.state ?? [],
		city: parentData?.data?.brandDetail?.city ?? [],
		targetAgeGroup: parentData?.data?.brandDetail?.targetAgeGroup ?? [],
	});

	console.log("parentDataUseState",parentData?.data?.brandDetail?.targetAgeGroup)

	const [showConditionalBlock, setShowConditionalBlock] = useState(true);

	console.log("ParentData ----- ppp", parentData);

	const [durationData, setDurationData] = useState<any>({
		startDate: "",
		endDate: "",
	});
	const [brandCategoryList, setBrandCategoryList] = useState([]);
	const targetAudienceOptions = [
		{ label: 3, value: 3 },
		{ label: 4, value: 4 },
		{ label: 5, value: 5 },
		{ label: 6, value: 6 },
		{ label: 7, value: 7 },
		{ label: 8, value: 8 },
		{ label: 9, value: 9 },
		{ label: 10, value: 10 },
		{ label: 11, value: 11 },
		{ label: 12, value: 12 },
		{ label: 13, value: 13 },
		{ label: 14, value: 14 },
		{ label: 15, value: 15 },
		{ label: 16, value: 16 },
		{ label: 17, value: 17 },
		{ label: 18, value: 18 },
		{ label: 19, value: 19 },
		{ label: 20, value: 20 },
	];
	const [showExitPopup, setShowExitPopup] = useState(false);
	const [budget, setBudget] = useState<any>(0);
	const [overlay, setOverlay] = useState(false);
	const [showBudgetError, setShowBudgetError] = useState(false);
	const [budgetError, setBudgetError] = useState(false);
	const [showWisrOfferingError, setShowWisrOfferingError] = useState(false);
	const [wisrOfferingError, setWisrOfferingError] = useState(false);
	const [showStartDateError, setShowStartDateError] = useState(false);
	const [startDateError, setStartDateError] = useState(false);
	const [showEndDateError, setShowEndDateError] = useState(false);
	const [endDateError, setEndDateError] = useState(false);
	const [showAlertForBrandCatChange, setShowAlertForBrandCatChange] = useState(
		false
	);
	const [tempBrandCategory, setTempBrandCategory] = useState({});
	const [subCategoryOption, setSubCategoryOption] = useState([]);
	const [brandNameOption, setBrandNameOption] = useState([]);
	const [isChrome, setIsChrome] = useState(true);
	const [allSubCategoryOption, setAllSubCategoryOption] = useState([]);
	const [confirmDialogBtnLoading, setConfirmDialogBtnLoading] = useState(false);
	const [startDateRistrictionmMsg, setStartDateRistrictionmMsg] = useState(
		false
	);
	const [languageOptions] = useState(
		config.languages.filter((lang) => lang.value.toLowerCase() !== "custom")
	);
	const [onBoarding, setOnboarding] = useState({
		stepsEnabled: false,
		initialStep: 0,
		steps: [
			{
				intro:
					"Let's get started! Get customised recommendations: Our recommendations engine will suggest relevant placements based on your input. You can apply these suggestions or customise them as required. Let's Go!",
			},
		],
		options: {
			exitOnOverlayClick: false,
			showStepNumbers: true,
			hidePrev: true,
			hideNext: false,
			nextLabel: "Next",
			prevLabel: "Prev",
			doneLabel: "START",
			autoPosition: true,
			exitOnEsc: false,
			scrollToElement: false,
			disableInteraction: true,
		},
	});
	const [pingIntervalTime, setPingIntervalTime] = useState(1000);
	const [wifiSpeed, setwifiSpeed] = useState<any>(null);
	const [toggleNetworkDialog, setToggleNetworkDialog] = useState(false);
	const [checkSpeed, setCheckSpeed] = useState(false);
	const [stateCityData, setStateCityData] = useState([]);
	const [stateList, setStateList] = useState<any>([]);
	const [cityList, setCityList] = useState<any>([]);
	const [isLoading, setIsLoading] = useState(false);

	const [countryRegionOptions, setCountryRegionOptions] = useState([]);
	const [countryRegionData, setCountryRegionData] = useState([]);

	const [accessState, setAccessState] = useState(parentData?.data?.state ?? []);
	const [accessCities, setAccessCities] = useState(
		parentData?.data?.city ?? []
	);

	console.log("formDataNewOne", formData);

	const [validator, setValidator] = useState<any>("");
	const [simpleFormValidator, setSimplFormValidator] = useState<any>(
		formValidator
	);

	useEffect(() => {
		if (toolTipLoaded) {
			setTimeout(() => {
				setOnboarding({
					...onBoarding,
					stepsEnabled: parentData?.toolTipProgress?.createCampaign,
				});
			}, 100);
		}
	}, [toolTipLoaded]);
	useEffect(() => {
		if (brandCategoryList.length > 0) {
			getBrandDetail();
		}
	}, [brandCategoryList]);
	useEffect(() => {
		if (
			history.location.pathname ===
			"/brands/brand-details/create-trial-campaign/set-up-campaign"
		) {
			setFormData({
				...formData,
				isTrial: true,
			});
		} else {
			setFormData({
				...formData,
				isTrial: isTrailCampaign,
			});
		}
	}, [isTrailCampaign]);
	useEffect(() => {
		if (
			parentData?.data?.brandDetail?.startDate !== "" &&
			parentData?.data?.brandDetail?.endDate !== ""
		) {
			let startDate = new Date(parentData?.data?.brandDetail?.startDate);
			let endDate = new Date(parentData?.data?.brandDetail?.endDate);
			setDurationData({ startDate: startDate, endDate: endDate });
		} else if (parentData?.data?.brandDetail?.startDate !== "") {
			let startDate = new Date(parentData?.data?.brandDetail?.startDate);
			setDurationData({ ...durationData, startDate: startDate });
		} else if (parentData?.data?.brandDetail?.endDate !== "") {
			let endDate = new Date(parentData?.data?.brandDetail?.endDate);
			setDurationData({ ...durationData, endDate: endDate });
		}
	}, []);
	useEffect(() => {
		if (
			checkSpeed == false &&
			(location.pathname ===
				"/brands/brand-details/create-campaign/set-up-campaign" ||
				location.pathname ===
					"/brands/brand-details/create-trial-campaign/set-up-campaign") &&
			!parentData.initialInternetSpeedTest
		) {
			testSpeed();
		}
	}, []);
	const testSpeed = () => {
		setCheckSpeed(true);
		setTimeout(() => {
			setPingIntervalTime(1000);
			setCheckSpeed(false);
			// setwifiSpeed(0)
		}, 2000);
	};
	const networkConfirmClose = (confirm) => {
		setToggleNetworkDialog(false);
		setOverlay(false);
		if (parentData.setInitialInternetSpeedTest) {
			parentData.setInitialInternetSpeedTest(true);
		}
		setwifiSpeed(null);
	};
	useEffect(() => {
		if (
			location.pathname ===
			"/brands/brand-details/create-campaign/set-up-campaign"
		) {
			if (wifiSpeed !== null) {
				if (
					Number(wifiSpeed) < context.utils.internetSpeedLimit &&
					!parentData.initialInternetSpeedTest
				) {
					setToggleNetworkDialog(true);
					setOverlay(true);
				} else {
					setToggleNetworkDialog(false);
					setOverlay(false);
					if (parentData.setInitialInternetSpeedTest) {
						parentData.setInitialInternetSpeedTest(true);
					}
				}
			}
		}
	}, [wifiSpeed, location.pathname]);
	const getBrandDetail = async () => {
		let obj = { _id: "", value: "", label: "" },
			brandSubCategory = { value: "", label: "" },
			brandName = "";
		let resp = await context.apis.public.getBrandById(context.validator, {
			_id: context.user.parentBrand || context.user.brand,
		});
		console.log("abcd", resp);
		if (resp && resp.done) {
			let brandType = resp.response.general.brandType;
			let found = brandCategoryList.find((item) => item._id === brandType);
			if (found) {
				obj = {
					_id: found._id,
					value: found.name,
					label: found.name,
				};
			}
			if (context.user.role === "brandManager") {
				let data = resp.response.subCategories.filter(
					(el) => el.userId === context.user._id
				);
				if (data.length > 0) {
					let found = data.reduce((a, b) => {
						return new Date(a.updatedOn) > new Date(b.updatedOn) ? a : b;
					});
					if (found) {
						console.log("found----12", found);

						brandSubCategory = { value: found._id, label: found.name };
						brandName = found.brandId;
						setSubCategoryOption([{ value: found._id, label: found.name }]);
						setBrandNameOption([
							{ value: found.brandId, label: found.brandName },
						]);
					}
				}
				console.log("abcd", data);
				if (data) {
				}
				console.log(brandSubCategory, brandName);
				setFormData({
					...formData,
					brandCategory: obj,
					brandSubcategory: brandSubCategory,
					brandName: brandName,
					brandId: brandName,
				});
			} else if (context.user.role === "brandCMO") {
				let categoryData = resp.response.subCategories.reduce((acc, item) => {
					if (item.brandName) {
						let data = { ...item, value: item._id, label: item.name };
						if (
							!acc.find(
								(subCat) => subCat._id === item._id || subCat.name === item.name
							)
						)
							acc.push(data);
					}
					return acc;
				}, []);
				let allCategoryData = resp.response.subCategories.reduce(
					(acc, item) => {
						if (item.brandName) {
							let data = { ...item, value: item._id, label: item.name };
							acc.push(data);
						}
						return acc;
					},
					[]
				);
				console.log(
					"categoryData",
					categoryData,
					allCategoryData,
					parentData.data.brandDetail
				);
				setSubCategoryOption([...categoryData]);
				setAllSubCategoryOption([...allCategoryData]);
				if (parentData.data.brandDetail.brandSubcategory.value !== "") {
					let brandNameOpt = allCategoryData.reduce((acc, current) => {
						if (
							parentData.data.brandDetail.brandSubcategory &&
							parentData.data.brandDetail.brandSubcategory.label &&
							current.name ===
								parentData.data.brandDetail.brandSubcategory.label
						) {
							let newOption = {
								value: current.brandId,
								label: current.brandName,
							};
							acc.push(newOption);
						}
						return acc;
					}, []);
					console.log("brandNameOpt", brandNameOpt, allSubCategoryOption);
					setBrandNameOption([...brandNameOpt]);
				}
				setFormData({
					...formData,
					brandCategory: obj,
				});
			}
		}
	};
	useEffect(() => {
		const isSummary = new URLSearchParams(location.search).get("isSummary");
		if (isSummary === "true") {
			console.log("object");
			parentData.setProgress({
				brandDetails: false,
				inventory: false,
				school: false,
				recommendedSchool: false,
				event: false,
				campaignCreative: false,
				preview: false,
			});
		}
		const browser = detect();
		if (browser) {
			setIsChrome(browser.name === "chrome");
		}
	}, []);
	useEffect(() => {
		setBudget(parentData.data.campaignDetail.totalBudget);
	}, []);
	console.log("parentData.data.campaignDetail", parentData.data.campaignDetail);

	useEffect(() => {
		setShowProgressSideMenu(true);
		if (parentData.setActiveModule) {
			parentData.setActiveModule({
				...parentData.activeModule,
				module: "brandDetails",
			});
		}
		getBrandCategoryList();
		getStateCityData();
		let cities = [];
		formData.city.forEach((item) => {
			let obj = { value: item, label: titleCase(item) };
			cities.push(obj);
		});
		setCityList([...cities]);
	}, []);
	const getStateCityData = async () => {
		setIsLoading(true);
		const resp = await context.apis.public.getAllCitiesAndStatesBySchool(
			context.validator,
			{},
			{}
		);
		if (resp && resp.done) {
			console.log("getAllCitiesAndStatesBySchool", resp);
			let states = [];
			resp.response.forEach((item) => {
				states.push({ value: item._id, label: titleCase(item._id) });
			});
			setStateList([...states]);
			setStateCityData([...resp.response]);
		}
		setIsLoading(false);
	};

	console.log("handleAddRemoveFilter setStateCityData", stateCityData)

	// get data for events and occassion starts

	const getAllCitiesAndStatesByRegionFun = async () => {
		setIsLoading(true);
		const resp = await context.apis.public.getAllCitiesAndStatesByRegion(
			context.validator,
			{},
			{}
		);
		if (resp && resp.done) {
			console.log("getAllCitiesAndStatesByRegion", resp);
			let countryRegion = [];
			let countryRegionData = [];
			resp.response.forEach((item) => {
				countryRegion.push({ value: item._id, label: titleCase(item._id) });
				countryRegionData.push(item);
			});
			setCountryRegionOptions([...countryRegion]);
			setCountryRegionData([...countryRegionData]);
		}
	};

	useEffect(() => {
		getAllCitiesAndStatesByRegionFun();
	}, []);

	console.log("countryRegionList ====> ", countryRegionOptions);

	console.log("formDataformData", formData);
	console.log("formDataformData 2", formData.targetAgeGroup);
	console.log("formDataCountryRegion", formData, countryRegionOptions);

	// get data for events and occassion ends

	const onStateChange = (data) => {
		console.log("handleAddRemoveFilter onStateChangeData", data);
		let arr = [];

		if (data.find((el) => el.value === "all")) {

			arr = stateList.map((el) => el.value);

			console.log("handleAddRemoveFilter arr 1", arr)

		} else {

			arr = data.map((el) => el.value);
			console.log("handleAddRemoveFilter arr 2", arr)

		}

		let prevCity = [...formData.city],
			allCity = [],
			nextCity = [];

		let cities = [];

		arr.forEach((item) => {

			let newCities = [
				...stateCityData.find((data) => data._id === item).cities,
			];
			allCity = [...allCity, ...newCities];
			console.log("handleAddRemoveFilter newCities", newCities);
			console.log("handleAddRemoveFilter allCity", allCity);

		});
		//remove city of unselected state
		prevCity.forEach((item) => {
			if (allCity.includes(item)) {
				nextCity.push(item);
			}
		});
		//populate cities of new selected state
		arr.forEach((item) => {
			if (!formData.state.includes(item)) {
				nextCity = [
					...nextCity,
					...stateCityData.find((data) => data._id === item).cities,
				];
			}
		});

		setFormData({ ...formData, state: [...arr], city: [...nextCity] });
	};

	const onCityChange = (data) => {
		console.log("handleAddRemoveFilter allCity data", data);
		let arr = [];
		if (data.find((el) => el.value === "all")) {
			arr = cityList.map((el) => el.value);
		} else {
			arr = data.map((el) => el.value);
		}
		setFormData({ ...formData, city: [...arr] });
	};
	useEffect(() => {
		if (
			stateCityData &&
			stateCityData.length &&
			formData?.state &&
			formData?.state.length &&
			!checkIsEventAndOcassion(formData)
		) {
			let newState = [];
			formData.state.forEach((item) => {
				let cities = stateCityData.find((data) => {
					console.log("data id & item =", data._id, item, data._id === item);
					return data._id === item;
				}).cities;
				let found = 0;
				cities.forEach((data) => {
					if (formData.city.includes(data)) {
						found++;
					}
				});
				if (found) {
					newState.push(item);
				}
			});
			setFormData({ ...formData, state: [...newState] });
		}
	}, [formData.city]);
	useEffect(() => {
		if (
			stateCityData &&
			stateCityData.length &&
			!checkIsEventAndOcassion(formData)
		) {
			let cities = [];
			formData.state.forEach((item) => {
				let newCities = [
					...stateCityData.find((data) => data._id === item).cities,
				];
				newCities.forEach((data) => {
					let obj = { value: data, label: titleCase(data) };
					cities.push(obj);
				});
			});
			setCityList([...cities]);
		}
	}, [formData.state]);
	const getBrandCategoryList = async () => {
		const resp = await context.apis.public.listBrandCategoriesMaster(
			context.validator,
			{},
			{}
		);
		console.log(resp);
		if (resp && resp.done) {
			setBrandCategoryList([...resp.response.rows]);
		}
	};
	const onFormDataChange = (event) => {
		setFormData({ ...formData, [event.target.id]: event.target.value });
		if (event.target.id === "startDate") {
			setStartDateError(false);
		}
		if (event.target.id === "endDate") {
			setEndDateError(false);
		}
	};

	const onDurationChange = (event, id) => {
		console.log(
			"data...",
			id,
			event,
			new Date(moment(event).format("yyyy-MM-DD"))
		);
		if (isChrome) {
			setFormData({ ...formData, [event.target.id]: event.target.value });
			setDurationData({
				...durationData,
				[event.target.id]: event.target.value,
			});
			if (event.target.id === "startDate") {
				setStartDateError(false);
			}
			if (event.target.id === "endDate") {
				setEndDateError(false);
			}
		} else {
			setFormData({ ...formData, [id]: moment(event).format("yyyy-MM-DD") });
			setDurationData({ ...durationData, [id]: event });
			if (id === "startDate") {
				setStartDateError(false);
			}
			if (id === "endDate") {
				setEndDateError(false);
			}
		}
	};
	const onBudgetChange = (data) => {
		if (typeof data === "object") {
			if (checkIfNumberOnly(data.value) || data.value === "") {
				setBudget(data.value === "" ? data.value : Number(data.value));
				if (data.value === "" || Number(data.value) < 0) {
					setBudgetError(true);
				} else {
					setBudgetError(false);
				}
			}
		} else {
			setBudget(Number(data));
		}
	};
	const onMediumOfStudyChange = (data) => {
		console.log("MediumdOfStudy", data);

		let arr = [];
		if (data.find((el) => el.value === "all")) {
			arr = languageOptions.map((el) => el.value);
		} else {
			arr = data.map((el) => el.value);
		}
		setFormData({ ...formData, studyMedium: [...arr] });
	};
	const onBrandCategoryChange = (data) => {
		console.log(data);
		if (
			parentData.data.brandDetail.brandCategory &&
			parentData.data.brandDetail.brandCategory.value &&
			data.value !== parentData.data.brandDetail.brandCategory.value
		) {
			setShowAlertForBrandCatChange(true);
			setOverlay(true);
			setTempBrandCategory({ ...data });
			return;
		}
		setFormData({
			...formData,
			brandCategory: { ...data },
		});
	};
	const onBrandSubCategoryChange = (data) => {
		console.log(data);
		let subCategory = {
			value: data._id,
			label: data.name,
		};

		let brandNameOpt = allSubCategoryOption.reduce((acc, current) => {
			if (current._id === data._id || current.name === data.name) {
				let newOption = { value: current.brandId, label: current.brandName };
				acc.push(newOption);
			}
			return acc;
		}, []);
		console.log("brandNameopt", brandNameOpt);
		setBrandNameOption([...brandNameOpt]);
		setFormData({
			...formData,
			brandSubcategory: { ...subCategory },
			brandName: data.brandId,
			brandId: data.brandId,
			brandNameLabel: data.brandName,
		});
	};
	console.log("formData-->>>", formData);
	const onBrandNameChange = (data) => {
		setFormData({
			...formData,
			brandName: data.value,
			brandId: data.value,
			brandNameLabel: data.label,
		});
	};

	const onSave = (e, proceed) => {
		e.preventDefault();
		const result = formValidator.current.allValid();
		if (
			result === false ||
			budget < 0 ||
			budget === "" ||
			(formData.inventory === false && formData.eventAndOccations === false)
		) {
			formValidator.current.showMessages();
			setShowWisrOfferingError(true);
			setShowBudgetError(true);
			if (budget < 0 || budget === "") {
				setBudgetError(true);
			}
			if (
				formData.inventory === false &&
				formData.eventAndOccations === false
			) {
				setWisrOfferingError(true);
			}
			forceUpdate(true);
			return;
		} else {
			formValidator.current.hideMessages();
			setShowBudgetError(false);
			setBudgetError(false);
			setShowWisrOfferingError(false);
			setWisrOfferingError(false);
			forceUpdate(!update);
		}
		let todaysDate = moment().clone().format("YYYY-MM-DD");
		let isStartDateBeforeToday = moment(formData.startDate).isBefore(
			moment(todaysDate).add(11, "days"),
			"day"
		);
		let isEndDateBeforeToday = moment(formData.endDate).isBefore(
			moment(todaysDate).add(11, "days"),
			"day"
		);
		let isEndDateBeforeStartDate = moment(formData.endDate).isBefore(
			formData.startDate,
			"day"
		);
		if (isEndDateBeforeToday || isEndDateBeforeStartDate) {
			setEndDateError(true);
		} else {
			setEndDateError(false);
		}
		if (isStartDateBeforeToday) {
			setStartDateError(true);
		} else {
			setStartDateError(false);
		}
		if (
			isStartDateBeforeToday ||
			isEndDateBeforeStartDate ||
			isEndDateBeforeToday
		) {
			setShowStartDateError(true);
			setShowEndDateError(true);
			return;
		} else {
			setShowStartDateError(false);
			setShowEndDateError(false);
		}

		console.log(formData);
		if (
			parentData?.data?.school?.schools?.length > 0 &&
			(!compareObjs(parentData.data.brandDetail, formData) ||
				parentData.data.campaignDetail.totalBudget !== budget)
		) {
			if (parentData) {
				if (parentData.setProgress) {
					parentData.setProgress({
						...parentData.progress,
						brandDetails: true,
					});
				}
				if (parentData.setData) {
					parentData.setData({
						...parentData.data,
						brandDetail: { ...formData, name: formData.name.trim() },
						campaignDetail: {
							...parentData.data.campaignDetail,
							totalBudget: budget,
						},
						school: { schools: [], locations: [] },
					});
				}
			}
		} else {
			if (parentData) {
				if (parentData.setProgress) {
					parentData.setProgress({
						...parentData.progress,
						brandDetails: true,
					});
				}
				if (parentData.setData) {
					parentData.setData({
						...parentData.data,
						brandDetail: { ...formData },
						campaignDetail: {
							...parentData.data.campaignDetail,
							totalBudget: budget,
						},
					});
				}
			}
		}

		if (formData.targetAgeGroup.min >= formData.targetAgeGroup.max) {
			console.log("maxValueCheck True");
			setTargetAgeValidationError(true);
			return;
		} else {
			
			console.log("maxValueCheck false");
			setTargetAgeValidationError(false);
		}

		console.log("formDataInventory --1", formData.inventory);
		if (proceed) {
			if (formData.inventory) {
				console.log("formDataInventory --2", formData.inventory);
				if (formData.isTrial) {
					history.push("/brands/brand-details/create-trial-campaign/inventory");
				} else {
					history.push("/brands/brand-details/create-campaign/inventory");
				}
			} else if (formData.eventAndOccations) {
				if (formData.isTrial) {
					history.push("/brands/brand-details/create-trial-campaign/events");
				} else {
					history.push("/brands/brand-details/create-campaign/events");
				}
			}
		}
	};
	const onExit = async (confirm) => {
		if (confirm) {
			const result = formValidator.current.allValid();
			if (
				result === false ||
				budget < 0 ||
				budget === "" ||
				(formData.inventory === false && formData.eventAndOccations === false)
			) {
				formValidator.current.showMessages();
				setShowWisrOfferingError(true);
				setShowBudgetError(true);
				if (budget < 0 || budget === "") {
					setBudgetError(true);
				}
				if (
					formData.inventory === false &&
					formData.eventAndOccations === false
				) {
					setWisrOfferingError(true);
				}
				forceUpdate(true);
				setShowExitPopup(false);
				setOverlay(false);
				return;
			} else {
				formValidator.current.hideMessages();
				setShowBudgetError(false);
				setBudgetError(false);
				setShowWisrOfferingError(false);
				setWisrOfferingError(true);
				forceUpdate(!update);
			}
			let todaysDate = moment().clone().format("YYYY-MM-DD");
			let isStartDateBeforeToday = moment(formData.startDate).isBefore(
				moment(todaysDate).add(11, "days"),
				"day"
			);
			let isEndDateBeforeToday = moment(formData.endDate).isBefore(
				moment(todaysDate).add(11, "days"),
				"day"
			);
			let isEndDateBeforeStartDate = moment(formData.endDate).isBefore(
				formData.startDate,
				"day"
			);
			if (isEndDateBeforeToday || isEndDateBeforeStartDate) {
				setEndDateError(true);
			} else {
				setEndDateError(false);
			}
			if (isStartDateBeforeToday) {
				setStartDateError(true);
			} else {
				setStartDateError(false);
			}
			if (
				isStartDateBeforeToday ||
				isEndDateBeforeStartDate ||
				isEndDateBeforeToday
			) {
				setShowStartDateError(true);
				setShowEndDateError(true);
				setShowExitPopup(false);
				setOverlay(false);
				return;
			} else {
				setShowStartDateError(false);
				setShowEndDateError(false);
			}

			let apiData = formateData();
			setConfirmDialogBtnLoading(true);

			if (apiData["_id"]) {
				console.log(apiData);
				let resp = await context.apis[context.user.role].editCampaign(
					context.validator,
					apiData
				);
				console.log("resp---", resp);
				if (resp && resp.done) {
					setConfirmDialogBtnLoading(false);
					history.push({
						pathname: "/brands/brand-details/campaigns",
						state: { campaignCreated: true },
					});
				}
			} else {
				let resp = await context.apis[context.user.role].addCampaign(
					context.validator,
					apiData
				);
				console.log("resp---", resp, apiData);
				if (resp && resp.done) {
					setConfirmDialogBtnLoading(false);
					history.push({
						pathname: "/brands/brand-details/campaigns",
						state: { campaignCreated: true },
					});
				}
			}
		}
		setOverlay(false);
		setShowExitPopup(false);
	};
	const onMinAgeChange = (data) => {
		setFormData({
			...formData,
			targetAgeGroup: { ...formData.targetAgeGroup, min: data.value },
		});
		setTargetAgeValidationError(data.value >= formData.targetAgeGroup.max);
	};
	const onMaxAgeChange = (data) => {
		setFormData({
			...formData,
			targetAgeGroup: { ...formData.targetAgeGroup, max: data.value },
		});
		setTargetAgeValidationError(formData.targetAgeGroup.min >= data.value);		
	};




	const onCountryRegionChange = (data) => {
		let arr = [];
		if (data.find((el) => el.value === "all")) {
			arr = countryRegionData.map((el) => el._id);
		} else {
			arr = data.map((el) => el.value);
		}

		// Update locations and states based on user's selection
		let locations = [];
		let states = [];

		//  below was working code
		data.map((region) => {
			const statesAndCities = countryRegionData.filter((regionData) => {
				if (region.value !== "all") {
					if (regionData._id === region.value) {
						return regionData;
					}
				} else {
					return regionData;
				}
			});
			console.log("statesAndCitiessss", statesAndCities);
			statesAndCities.map((state) => {
				state.states.forEach((stateData) => {
					if (stateData.cities.length) {
						states.push(stateData.name.toLowerCase());
						locations.push(...stateData.cities);
					}
				});
			});
		});

		// Update the state with the new values
		setAccessState(states);
		setAccessCities(locations);
		// setStateList(...stateList, states.map((state)=> {return { value: state, label: state}} ));


		// setStateList([...stateList, states.map((state) => ({ value: state, label: state })) ].flat());

			const newStateObjects = states.map((state:string) => ({ value: state, label: state }));

			const uniqueStateSet = new Set([...stateList, ...newStateObjects].map((state) => state.value));
			const uniqueStateObjects = Array.from(uniqueStateSet).map((value) => ({
			value: value,
			label: value.charAt(0).toUpperCase() + value.slice(1),
			}));

			setStateList(uniqueStateObjects);

		

		// setCityList(locations.map((city)=> {return { value: city, label: city}} ));

			const newCityObjects = locations.map((city:string) => ({ value: city, label: city }));

			const uniqueCitySet = new Set([...cityList, ...newCityObjects].map((city) => city.value));
			const uniqueCityObjects = Array.from(uniqueCitySet).map((value) => ({
			value: value,
			label: value.charAt(0).toUpperCase() + value.slice(1),
			}));

			setCityList(uniqueCityObjects);


		setFormData({
			...formData,
			countryRegion: arr,
			state: states,
			city: locations,
		});

		

		parentData.setData({
			...parentData.data,
			countryRegion: arr,
			state: states,
			city: locations,
		});
	};

	console.log("stateListNew", stateList)

	const formateData = () => {
		let inventoriesIdArray = [];
		parentData.data.inventory.inventories.forEach((inventory) => {
			let attributesIdArray = [];
			inventory.placements &&
				Array.isArray(inventory.placements) &&
				inventory.placements.length > 0 &&
				inventory.placements.map((item) => {
					attributesIdArray.push(item.name);
				});
			let obj = {
				_id: inventory._id,
				name: inventory.parentName || inventory.name,
				attributes: [...attributesIdArray],
				_custom: inventory._custom || false,
			};
			inventoriesIdArray.push(obj);
		});
		let eventIdArray = [];
		parentData.data.eventAndOccation.eventAndOccations.forEach((event) => {
			let attributesIdArray = [];
			event.placements &&
				Array.isArray(event.placements) &&
				event.placements.map((item) => {
					attributesIdArray.push(item._id || item.name);
				});
			let obj = {
				_id: event._id,
				name: event.name,
				attributes: [...attributesIdArray],
			};
			eventIdArray.push(obj);
		});

		let imageArray = [];
		if (parentData.data.campaignCreative.creatives.image1.key) {
			imageArray.push(parentData.data.campaignCreative.creatives.image1.key);
		}
		if (parentData.data.campaignCreative.creatives.image2.key) {
			imageArray.push(parentData.data.campaignCreative.creatives.image2.key);
		}
		if (parentData.data.campaignCreative.creatives.image3.key) {
			imageArray.push(parentData.data.campaignCreative.creatives.image3.key);
		}
		let obj = {
			brandId: formData.brandId,
			name: formData.name.trim(),
			description: formData.description.trim(),
			objective: formData.objective.trim(),
			startDate: formData.startDate,
			endDate: formData.endDate,
			locations: [],
			targetGender:
				formData.targetGender === "both" ? "co-ed" : formData.targetGender,
			targetAgeGroup: [
				formData.targetAgeGroup.min,
				formData.targetAgeGroup.max,
			],

			countryRegion: [...formData.countryRegion],
			studyMedium: [...formData.studyMedium],
			states: [...formData.state],
			cities: [...formData.city],

			brandCategory: formData.brandCategory._id,
			brandSubcategory: formData.brandSubcategory.value,
			brandName: formData.brandName,
			recommendEvents: false,
			campaignStatus: "draft",
			isApproved: false,
			campaignCreatives: [...imageArray],
			budget: budget,
			isTrial: formData.isTrial,
			wisrOffering: {
				inventory: inventory,
				eventAndOccations: event,
				csr: formData.csr,
				sampling: formData.sampling,
				custom: formData.custom,
			},
			campaignCreationProgress: {
				progress: {
					...parentData.progress,
					brandDetails: true,
				},
				currentPage: "setUpCampaign",
			},
			campaignData: {
				inventories: [...inventoriesIdArray],
				schools: [],
				activities: [...eventIdArray],
				schoolListDetail: [],
			},
		};
		if (parentData.data.brandDetail._id) {
			obj["_id"] = parentData.data.brandDetail._id;
		}
		return obj;
	};
	const openExit = () => {
		setOverlay(true);
		setShowExitPopup(true);
	};
	const withValueCap = (inputObj) => {
		const { value } = inputObj;
		if (value <= 100000000 && value >= 0) return true;
		return false;
	};

	const [inventory, setInventory] = useState(formData.inventory);
	const [event, setEvent] = useState(formData.eventAndOccations);
	const [targetAgeValidationError, setTargetAgeValidationError] = useState(
		false
	);


	console.log("inventory thisIsInventory", inventory)

	const handleEVentCheck = () => {

		if(event){
			setEvent(false);
			setFormData({...formData, targetAgeGroup: formData.targetAgeGroup,  countryRegion:[] })
		}
		else {
			setEvent(true);
			if(inventory){
				setFormData({...formData, targetAgeGroup: formData.targetAgeGroup, countryRegion:[] })
			}
			else{
			setFormData({...formData, targetAgeGroup: { min: 3, max: 20 } , city:[], state:[]})
			} 
		}
	}


	const handleInventoryCheck = () => {
		if(inventory){
			setInventory(false);
			if(event)
				setFormData({...formData, targetAgeGroup: { min: 3, max: 20 } ,city:[], state:[],countryRegion:[]  })
		}
		else {
			setInventory(true);
			if(event)
				setFormData({...formData, targetAgeGroup: formData.targetAgeGroup ,city:[], state:[],countryRegion:[]  })
			else
				setFormData({...formData, targetAgeGroup: formData.targetAgeGroup ,countryRegion:[] })			
		}
	}



	useEffect(() => {
		if (event && inventory) {
			setValidator("required|array|min:1");
			setFormData({
				...formData,
				eventAndOccations: event,
				inventory: inventory,
				// state: [], city:[],
				countryRegion:[]
			});
			
		} else if (inventory) {
			
			setValidator("required|array|min:1");
			setFormData({
				...formData,
				eventAndOccations: event,
				inventory: inventory,
				// state: [], city:[],countryRegion:[]
			});
			setShowWisrOfferingError(false); 
		} else if (event) {
			setValidator("");
			setFormData({
				...formData,
				eventAndOccations: event,
				inventory: inventory,
			
			});
			setShowWisrOfferingError(false); 
		} else {
			setValidator("required|array|min:1");
			setFormData({
				...formData,
				eventAndOccations: event,
				inventory: inventory,
				// state: [], city:[],countryRegion:[]
			});
		}
	}, [inventory, event]);

	console.log("formDataTest event wala", formData);

	const handleInventoryClick = (e, type) => {
		const isChecked = e.target.checked;
		console.log("checked", type);
		if (type == "event" && isChecked && formData?.inventory) {
			setFormData({
				...formData,
				["inventory" && "eventAndOccations"]: true,
				city: [],
				state: [],
				targetAgeGroup: {
					min: 3,
					max: 4,
				},
			});
			setSimplFormValidator(formValidator);
			setValidator("required|array|min:1");
		} else if (type == "event" && !isChecked && formData?.inventory) {
			setSimplFormValidator(formValidator);
			setValidator("required|array|min:1");
		} else if (type == "event" && isChecked && !formData?.inventory) {
			setValidator("");
		} else if (
			type == "inventory" &&
			isChecked &&
			formData?.eventAndOccations
		) {
			console.log(
				`4. inventory -> ${isChecked}, occasion -> ${formData?.eventAndOccations}`
			);
			setSimplFormValidator(formValidator);
			setValidator("required|array|min:1");
		} else if (
			type == "inventory" &&
			!isChecked &&
			formData?.eventAndOccations
		) {
			console.log(
				`5. inventory -> ${isChecked}, occasion -> ${formData?.eventAndOccations}`
			);
			setSimplFormValidator("");
			setValidator("");
		}

		if (type === "inventory" && isChecked) {
			setValidator("required|array|min:1");
			setFormData({
				...formData,
				eventAndOccations: false, // Uncheck "Event and Occasions"
				city: [],
				state: [],
			});
		} else if (type === "inventory" && !isChecked) {
			setValidator("");
			setFormData({
				...formData,
				eventAndOccations: false, // Uncheck "Event and Occasions",
				inventory: false,
				city: [],
				state: [],
			});
		}

		if (type === "event" && e.target.checked && formData.inventory) {
			setFormData({
				...formData,
				["inventory" && "eventAndOccations"]: true,
				city: [],
				state: [],
			});
			setWisrOfferingError(false);
		} else if (type === "inventory" && e.target.checked) {
			setFormData({
				...formData,
				["inventory"]: true,
				city: [],
				state: [],
			});
			setWisrOfferingError(false);
		} else if (type === "event" && e.target.checked) {
			setFormData({
				...formData,
				["eventAndOccations"]: true,
				city: [],
				state: [],
			});

			setWisrOfferingError(false);
		} else {
			setFormData({
				...formData,
				[type === "inventory" ? "inventory" : "eventAndOccations"]: false,
				city: [],
				state: [],
			});

			if (
				(type === "inventory" && formData.eventAndOccations === false) ||
				(type === "event" && formData.inventory === false)
			) {
				setWisrOfferingError(false);
			}
		}
	};

	const onModalClose = (confirm) => {
		if (confirm) {
			setFormData({
				...formData,
				brandCategory: { ...tempBrandCategory },
			});
		}
		setShowAlertForBrandCatChange(false);
		setTempBrandCategory({});
		setOverlay(false);
	};
	const updateUsersToolTipProgress = async () => {
		let userToolTipResp = await context.apis[
			context.user.role
		].updateUsersToolTipProgress(
			context.validator,
			{},
			{
				toolTipProgress: {
					...parentData.toolTipProgress,
					createCampaign: false,
				},
			}
		);
		console.log(userToolTipResp);
	};
	const hanldeOnboardingComplete = () => {
		updateUsersToolTipProgress();
		parentData.setToolTipProgress({
			...parentData.tooltipProgress,
			createCampaign: false,
		});
	};
	const exitOnBoarding = (e) => {
		console.log(e);
	};
	useEffect(() => {
		if (
			formData.startDate === "" &&
			formData.endDate !== "" &&
			isChrome === true
		) {
			setStartDateRistrictionmMsg(true);
		} else {
			setStartDateRistrictionmMsg(false);
		}
	}, [formData.startDate, formData.endDate]);
	formValidator.current.rules["campaign_name"] = {
		message: "Please enter valid name.",
		rule: (val) => {
			let returnValue = validateCampaignName(val);

			return returnValue;
		},
	};
	formValidator.current.rules["objective"] = {
		message: "Please enter valid objective.",
		rule: (val) => {
			let returnValue = true;
			if (!val || val.trim() === "") {
				returnValue = false;
			}
			const regex2 = /[a-zA-Z]/;
			if (!regex2.test(val)) {
				returnValue = false;
			}
			return returnValue;
		},
	};

	const checkIsEventAndOcassion = ({
		inventory,
		eventAndOccations,
		csr,
		sampling,
		custom,
	}) => {
		return (
			eventAndOccations === true && !inventory && !csr && !sampling && !custom
		);
	};

	console.log("setUpCampaginPage ", formData);

	const hideInventory = {
		display: `${!event || (inventory && event) ? "block" : "none"} `,
	};

	console.log("setEvent", event)

	return (
		<>
			<div className={`ovelap ${overlay === true && "active"}`}></div>
			{checkSpeed && (
				<ReactInternetSpeedMeter
					txtSubHeading="Internet is too slow"
					outputType="alert"
					customClassName={"slowInternetMsg"}
					txtMainHeading="Opps..."
					pingInterval={pingIntervalTime} // milliseconds
					thresholdUnit="megabyte" // "byte" , "kilobyte", "megabyte"
					threshold={100}
					imageUrl={context.utils.internetSpeedTestingImg}
					downloadSize="2550420" //bytes
					callbackFunctionOnNetworkDown={(speed) =>
						console.log(`Internet speed is down ${speed}`)
					}
					callbackFunctionOnNetworkTest={(speed) => {
						console.log(`Internet Speed is ${speed} MBps`);
						setwifiSpeed(speed);
					}}
				/>
			)}
			{/* <TopBar isDashboard={true} /> */}
			{isLoading ? (
				<Loader />
			) : (
				<div className="col-xl-9 col-lg-12 col-md-12 col-xs-12">
					<div className="rightPart noBack">
						<div className="row">
							<div className="col-xs-12 col-lg-12 col-md-12 col-xs-12">
								<BrandBackBtn />
							</div>
							<div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-20">
								<h2 className="htx5 clearfix mt-10 mb-20">Create a Campaign</h2>
							</div>
							<div className="col-xl-7 col-lg-7 col-md-12 col-xs-12 pl pr">
								<div className="row">
									<div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
										<TextField
											formData={formData}
											formKey="name"
											onDataChange={onFormDataChange}
											label="Name"
											placeholder="Enter Campaign Name"
											isRequired={false}
											formValidator={formValidator}
											maxLength={50}
											validation="required|campaign_name"
										/>
									</div>

									<div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
										<div className="forminpt mb-0 tooltip-relative taegetMobile">
											<label>
												Duration{" "}
												<a
													style={{ cursor: "pointer" }}
													title="Enter the start date and end date for your campaign."
													className="tooltip"
												>
													<img src={helpImg} />
												</a>
											</label>

											<div className="row">
												<div className="col-xl-6 col-lg-6 col-md-12 col-xs-12 pl mm-b0">
													<div className="forminpt">
														{!isChrome ? (
															<CustomDatePicker
																formData={durationData}
																formKey="startDate"
																onDataChange={onDurationChange}
																isRequired={false}
																formValidator={formValidator}
																validation="required"
																min={moment()
																	.add(11, "days")
																	.clone()
																	.format("YYYY-MM-DD")}
																max={
																	durationData.endDate &&
																	moment(durationData.endDate).format(
																		"YYYY-MM-DD"
																	)
																}
																isChrome={isChrome}
															/>
														) : (
															<CustomDatePicker
																formData={formData}
																formKey="startDate"
																onDataChange={onFormDataChange}
																isRequired={false}
																formValidator={formValidator}
																validation="required"
																min={moment()
																	.add(11, "days")
																	.clone()
																	.format("YYYY-MM-DD")}
																max={formData.endDate && formData.endDate}
															/>
														)}

														{startDateError && showStartDateError && (
															<span className="valError">
																<img src={errorSVG} />
																Please select valid start date.
															</span>
														)}
														{startDateRistrictionmMsg && (
															<span className="valError">
																<img src={errorSVG} />
																{`You cannot select date past ${moment(
																	formData.endDate
																)
																	.clone()
																	.format("DD-MM-YYYY")}`}
															</span>
														)}
													</div>
												</div>
												<div className="col-xl-6 col-lg-6 col-md-12 col-xs-12 pr">
													<div className="forminpt">
														{!isChrome ? (
															<CustomDatePicker
																formData={durationData}
																formKey="endDate"
																onDataChange={onDurationChange}
																isRequired={false}
																formValidator={formValidator}
																validation="required"
																min={
																	(durationData.startDate &&
																		moment(durationData.startDate)
																			.clone()
																			.format("YYYY-MM-DD")) ||
																	moment()
																		.add(11, "days")
																		.clone()
																		.format("YYYY-MM-DD")
																}
																isChrome={isChrome}
															/>
														) : (
															<CustomDatePicker
																formData={formData}
																formKey="endDate"
																onDataChange={onFormDataChange}
																isRequired={false}
																formValidator={formValidator}
																validation="required"
																min={
																	(formData.startDate &&
																		moment(formData.startDate)
																			.clone()
																			.format("YYYY-MM-DD")) ||
																	moment()
																		.add(11, "days")
																		.clone()
																		.format("YYYY-MM-DD")
																}
															/>
														)}
														{endDateError && showEndDateError && (
															<span className="valError">
																<img src={errorSVG} />
																Please select valid end date.
															</span>
														)}
													</div>
												</div>
											</div>
										</div>
									</div>

									<div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
										<TextArea
											formData={formData}
											formKey="description"
											onDataChange={onFormDataChange}
											label="Description"
											placeholder="Write a few sentences about the campaign"
											isRequired={false}
											formValidator={formValidator}
											maxLength={500}
											validation="required"
										/>
									</div>
									<div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
										<div className="forminpt">
											<TextField
												formData={formData}
												formKey="objective"
												onDataChange={onFormDataChange}
												label="Objective"
												placeholder="Reach, Recognition, Engagement etc"
												isRequired={false}
												formValidator={formValidator}
												validation="required|objective"
											/>
										</div>
									</div>
									<div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
										<div className="forminpt checkboxinput tooltip-relative">
											<label>
												WISR Offering{" "}
												<a
													style={{ cursor: "pointer" }}
													title="For Solution offerings like CSR, Sampling & Custom requirements, our WISR team will assist you set up campaigns offline."
													className="tooltip"
												>
													<img src={helpImg} />
												</a>{" "}
												<small>Choose one or more</small>
											</label>
											<div className="checkboxinputList">
												<div className="checkbox">
													<input
														type="checkbox"
														id="inventory"
														checked={inventory}
														onClick={handleInventoryCheck}
													/>
													<label htmlFor="inventory">Inventory</label>
												</div>
												<div className="checkbox">
													<input
														type="checkbox"
														id="eventAndOccations"
														checked={event}
														onClick={handleEVentCheck}
													/>
													<label htmlFor="eventAndOccations">
														Event and Occasions
													</label>
												</div>
												<div className="checkbox">
													<input
														type="checkbox"
														id="csr"
														checked={formData.csr}
														onClick={(e: any) =>
															setFormData({
																...formData,
																csr: e.target.checked,
															})
														}
													/>
													<label htmlFor="csr">CSR</label>
												</div>
												<div className="checkbox">
													<input
														type="checkbox"
														id="sampling"
														checked={formData.sampling}
														onClick={(e: any) =>
															setFormData({
																...formData,
																sampling: e.target.checked,
															})
														}
													/>
													<label htmlFor="sampling">Sampling</label>
												</div>
												<div className="checkbox">
													<input
														type="checkbox"
														id="custom"
														checked={formData.custom}
														onClick={(e: any) =>
															setFormData({
																...formData,
																custom: e.target.checked,
															})
														}
													/>
													<label htmlFor="custom">Custom</label>
												</div>
											</div>

											{showWisrOfferingError && wisrOfferingError && (
												<span className="valError">
													<img src={errorSVG} />
													Please select valid input.
												</span>
											)}

										</div>
									</div>
									<div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
										<div className="tol-built campaignBudget tooltip-relative mt-0 mb-30">
											<p>
												Budget{" "}
												<a
													style={{ cursor: "pointer" }}
													title="Select the budget of your campaign by dragging the slider or by entering the amount."
													className="tooltip"
												>
													<img src={helpImg} />
												</a>
											</p>
											<SliderComponent
												min={0}
												max={100000000}
												value={budget}
												onSliderValueChange={onBudgetChange}
												changeError={setBudgetError}
											/>
											<span>
												<b>₹</b>
												<NumberFormat
													type="text"
													thousandSeparator={true}
													thousandsGroupStyle="lakh"
													value={budget}
													onValueChange={onBudgetChange}
													isAllowed={withValueCap}
												/>
											</span>
											{budgetError && showBudgetError && (
												<span className="valError">
													<img src={errorSVG} />
													{budget === ""
														? "Budget field is required."
														: budget <= 0 && "Budget can not be less than 0."}
												</span>
											)}
										</div>
									</div>

									{formData.eventAndOccations && !formData.inventory && (
										<>
											<div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
												<div className="forminpt">
													<MultiSelect
														formData={formData}
														formKey="countryRegion"
														onDataChange={onCountryRegionChange}
														label="Select region"
														placeholder="Select region"
														isRequired={false}
														options={
															countryRegionOptions.length >
															formData.countryRegion.length
																? countryRegionOptions.concat([
																		{ label: "All of the above", value: "all" },
																  ])
																: countryRegionOptions
														}
														formValidator={simpleFormValidator}
														// validation={validator}
														validation={!inventory && event ? "required" : ""}
														showInfoIcon={true}
														infoText="Select the preferred medium of study for your campaign. This will give you a list of all the schools under a particular medium. For example, if you select Hindi as the medium then a list of all Hindi medium schools will appear."
													/>
												</div>
											</div>

											<div className="col-xl-6 col-lg-6 col-md-6 col-xs-12 dnone">
											{/* <div className="col-xl-6 col-lg-6 col-md-6 col-xs-12"> */}
												<label> States </label>
												<div className="forminpt">
													<textarea
														name=""
														value={accessState.join(", ")}
														readOnly
														className="wisr-react-multi-select"
													></textarea>
												</div>
											</div>

											<div className="col-xl-6 col-lg-6 col-md-6 col-xs-12 dnone">
											{/* <div className="col-xl-6 col-lg-6 col-md-6 col-xs-12"> */}
												<label> Cities </label>
												<div className="forminpt">
													<textarea
														name=""
														value={accessCities.join(", ")}
														readOnly
														className="wisr-react-multi-select"
													></textarea>
												</div>
											</div>
										</>
									)}

									{
										<>
											<div
												className="col-xl-6 col-lg-6 col-md-12 col-xs-12"
												style={hideInventory}
											>
												<div className="forminpt">
													<MultiSelect
														formData={formData}
														formKey="state"
														onDataChange={onStateChange}
														label="State"
														placeholder="Select State"
														options={
															stateList.length > 0 &&
															stateList.length > formData.state.length
																? stateList.concat([
																		{
																			label: "All of the above",
																			value: "all",
																		},
																  ])
																: stateList
														}
														formValidator={simpleFormValidator}
														validation={validator}
														showInfoIcon={true}
														infoText="Select the preferred state for your campaign. This will give you a list of all the schools of that state."
													/>
												</div>
											</div>
											<div
												className="col-xl-6 col-lg-6 col-md-12 col-xs-12"
												style={hideInventory}
											>
												<div className="forminpt">
													<MultiSelect
														formData={formData}
														formKey="city"
														onDataChange={onCityChange}
														label="City"
														placeholder="Select city"
														options={
															cityList.length > 0 &&
															cityList.length > formData.city.length
																? cityList.concat([
																		{
																			label: "All of the above",
																			value: "all",
																		},
																  ])
																: cityList
														}
														formValidator={simpleFormValidator}
														validation={validator}
														showInfoIcon={true}
														infoText="Select the preferred city for your campaign. This will give you a list of all the schools of that city."
													/>
												</div>
											</div>

											<div
												className="col-xl-12 col-lg-12 col-md-12 col-xs-12"
												style={hideInventory}
											>
												<div className="forminpt checkboxinput">
													<label>Target Audience</label>
													<div className="checkboxinputList">
														<div className="radioBtn">
															<input
																type="radio"
																id="boys"
																name="radio-group"
																checked={formData.targetGender === "boys"}
																onChange={(e: any) =>
																	setFormData({
																		...formData,
																		targetGender: "boys",
																	})
																}
															/>
															<label htmlFor="boys">Boys</label>
														</div>
														<div className="radioBtn">
															<input
																type="radio"
																id="girls"
																name="radio-group"
																checked={formData.targetGender === "girls"}
																onChange={(e: any) =>
																	setFormData({
																		...formData,
																		targetGender: "girls",
																	})
																}
															/>
															<label htmlFor="girls">Girls</label>
														</div>
														<div className="radioBtn">
															<input
																type="radio"
																id="both"
																name="radio-group"
																checked={formData.targetGender === "both"}
																onChange={(e: any) =>
																	setFormData({
																		...formData,
																		targetGender: "both",
																	})
																}
															/>
															<label htmlFor="both">Both</label>
														</div>
													</div>
												</div>
											</div>

											<div
												className="col-xl-6 col-lg-6 col-md-12 col-xs-12"
												style={hideInventory}
											>
												<div className="forminpt taegetMobile">
													<label>Target Audience Age</label>
													<div className="row">
														<div className="col-xl-6 col-lg-6 col-md-12 col-xs-12 pl">
															<SingleSelect
																formData={formData.targetAgeGroup}
																formKey="min"
																onDataChange={onMinAgeChange}
																placeholder="Select age"
																isRequired={false}
																options={targetAudienceOptions}
																formValidator={formValidator}
																validation="required"
															/>
														</div>

														<div className="col-xl-6 col-lg-6 col-md-12 col-xs-12 pr">
															<SingleSelect
																formData={formData.targetAgeGroup}
																formKey="max"
																onDataChange={onMaxAgeChange}
																placeholder="Select age"
																isRequired={false}
																options={targetAudienceOptions.filter(
																	(age) =>
																		age.value > formData?.targetAgeGroup.min
																)}
																formValidator={formValidator}
																validation={
																	formData.targetAgeGroup.min >
																	formData.targetAgeGroup.max
																		? "required"
																		: ""
																}
															/>
															{targetAgeValidationError && (
																<span
																	className="valError"
																	style={{
																		fontSize: "14px!important",
																		fontWeight: "normal!important",
																		display: "block!important",
																		marginTop: "7px!important",
																		color: "#000 !important",
																	}}
																>
																	<img src={errorSVG} />
																	{/* Max age required. */}
																	The max age field is required.
																</span>
															)}
														</div>
													</div>
												</div>
											</div>
										</>
									}

									<div className="col-xl-6 col-lg-6 col-md-12 col-xs-12">
										<div className="forminpt">
											<MultiSelect
												formData={formData}
												formKey="studyMedium"
												onDataChange={onMediumOfStudyChange}
												label="Medium Of Study"
												placeholder="Select Medium of study"
												isRequired={false}
												options={
													languageOptions.length > formData.studyMedium.length
														? languageOptions.concat([
																{ label: "All of the above", value: "all" },
														  ])
														: languageOptions
												}
												formValidator={formValidator}
												validation="required|array|min:1"
												showInfoIcon={true}
												infoText="Select the preferred medium of study for your campaign. This will give you a list of all the schools under a particular medium. For example, if you select Hindi as the medium then a list of all Hindi medium schools will appear."
											/>
										</div>
									</div>
									<div className="col-xl-6 col-lg-6 col-md-12 col-xs-12">
										<div className="forminpt">
											<SingleSelect
												formData={formData.brandCategory}
												formKey="_id"
												onDataChange={onBrandCategoryChange}
												label="Your Brand Category"
												placeholder="Select a Category"
												isRequired={false}
												options={
													brandCategoryList &&
													brandCategoryList.length !== 0 &&
													brandCategoryList.map((item) => {
														return {
															_id: item._id,
															value: item._id,
															label: item.name,
														};
													})
												}
												formValidator={formValidator}
												validation="required"
												showInfoIcon={true}
												infoText="Select the category for your brand from the dropdown."
												isDisable={true}
											/>
										</div>
									</div>
									<div className="col-xl-6 col-lg-6 col-md-12 col-xs-12">
										<div className="forminpt">
											<SingleSelect
												formData={formData.brandSubcategory}
												formKey="value"
												onDataChange={onBrandSubCategoryChange}
												label="Your Brand Sub-Category"
												placeholder="Select a Sub-Category"
												isRequired={false}
												options={subCategoryOption}
												formValidator={formValidator}
												validation="required"
												showInfoIcon={true}
												infoText="Select the sub-category for your brand from the dropdown."
												isDisable={context.user.role === "brandManager"}
											/>
										</div>
									</div>
									<div className="col-xl-6 col-lg-6 col-md-12 col-xs-12">
										<div className="forminpt">
											<SingleSelect
												formData={formData}
												formKey="brandName"
												onDataChange={onBrandNameChange}
												label="Your Brand Name"
												placeholder="Select Brand Name"
												isRequired={false}
												options={brandNameOption}
												formValidator={formValidator}
												validation="required"
												showInfoIcon={true}
												infoText="Select your brand name here."
												isDisable={context.user.role === "brandManager"}
											/>
										</div>
									</div>
									<div className="col-xl-12 col-lg-12 col-md-12 col-xs-12"></div>
									<div className="col-xl-5 col-lg-5 col-md-6 col-xs-12 delete-user">
										<a
											style={{ cursor: "pointer" }}
											className="viewwLink"
											onClick={openExit}
										>
											Save & Continue Later
										</a>
									</div>
									<div className="col-xl-7 col-lg-7 col-md-6 col-xs-12 end-md">
										<div className="forminpt">
											<input
												name="Save & Continue"
												className="btn btn-orange"
												type="button"
												value="Save & Continue "
												onClick={(e) => onSave(e, true)}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
			<ConfirmDialog
				togglePopup={showExitPopup}
				onModalClose={onExit}
				componentName={""}
				confirmTitle="Do you want to save and exit?"
				isLoading={confirmDialogBtnLoading}
			/>
			<ConfirmDialog
				togglePopup={showAlertForBrandCatChange}
				onModalClose={onModalClose}
				componentName={""}
				confirmTitle="Do you want to save and exit?"
			/>
			<Steps
				enabled={onBoarding.stepsEnabled}
				steps={onBoarding.steps}
				initialStep={onBoarding.initialStep}
				onComplete={hanldeOnboardingComplete}
				onExit={exitOnBoarding}
				options={onBoarding.options}
			/>
			<ConfirmDialog
				confirmTitle="Poor Internet Connection Detected"
				componentName="We've detected issues with your internet connection that could affect campaign creation & data loss. Please check your internet connection"
				confirmText="Got it"
				denyText="Cancel"
				togglePopup={toggleNetworkDialog}
				onModalClose={networkConfirmClose}
				onConfirmBtn={true}
			/>
		</>
	);
};

export default SetUpCampaign;
