import { useContext, useEffect, useState } from "react"
import { Link, useHistory } from "react-router-dom"
import logo from "../../../assets/images/logo.png"
import logoNew from "../../../assets/images/logo-new.svg"
import waveBootNew from "../../../assets/images/wavebotnew.png"
import AppContextObject from "../../../common/context/common"
import { eraseAllCookies, eraseCookie } from "../../../service/helper"

import DataContext from "../../../service/backendContext"

import InventorySVG from "../../../components/addon/svg/inventory"
import DashboardSVG from "../../../components/addon/svg/dashboard"
import BookingSVG from "../../../components/addon/svg/bookings"
import MessageSVG from "../../../components/addon/svg/message"
import SchoolSVG from "../../../components/addon/svg/school"
import CampaignSvg from "../../../components/addon/svg/campaign"
import BrandSvg from "../../../components/addon/svg/brands"
import SettingsSVG from "../../../components/addon/svg/settings"
import ProfileSVG from "../../../components/addon/svg/profile"
import LogoutSVG from "../../../components/addon/svg/logout"
import ConfirmDialog from "../../../components/addon/confirmDialog/confirmDialog"
import { downloadFile } from '../../../service/helper';
import downloadSvg from "../../../assets/images/backendAdmin/download-svg.svg";
import { ExportToCsv } from "export-to-csv"

const BackendDetailsSideMenu = (props) => {
    const context = useContext(AppContextObject)
    const history = useHistory()
    const parentData = useContext<any>(DataContext)
    const [toggleLogoutDialog, setToggleLogoutDialog] = useState(false)
    const [overlay, setOverlay] = useState(false)
    const [isLoggedOut, setIsLoggedOut] = useState(false)
    const [currentTab, setCurrentTab] = useState("")
    const [downloadSchoolLoading, setDownloadSchoolLoading] = useState(false);
    const [isMobileDevice, setIsMobileDevice] = useState(false)

    useEffect(() => {
        if (history.location.pathname === "/backend/backend-details/dashboard") {
            setCurrentTab("dashBoard")
        }
        else if (history.location.pathname === "/backend/backend-details/school-list" || history.location.pathname === "/backend/backend-details/school-details" || history.location.pathname === "/backend/backend-details/campaign-rejected") {
            setCurrentTab("school")
        }
        else if (history.location.pathname === "/backend/backend-details/inventory-list") {
            setCurrentTab("inventory")
        }
        else if (history.location.pathname === "/backend/backend-details/fos-list" || history.location.pathname === "/backend/backend-details/fos-details" || history.location.pathname === "/backend/backend-details/fos-school/school-details") {
            setCurrentTab("fosAgency")
        }
        else if (history.location.pathname === "/backend/backend-details/user-list") {
            setCurrentTab("addUser")
        }
        else if (history.location.pathname === "/backend/backend-details/activity-status-list") {
            setCurrentTab("activityStatus")
        }
        else if (history.location.pathname === "/backend/backend-details/campaign-list" || history.location.pathname === "/backend/backend-details/create-trial-campaign-details" || history.location.pathname === "/backend/backend-details/campaign/specific-campaign" || history.location.pathname === '/backend/backend-details/assignSchoolToFos' || history.location.pathname === "/backend/backend-details/campaign-reporting" || history.location.pathname === "/backend/backend-details/campaign-reporting-view") {
            setCurrentTab("campaign")
        }
        else if (history.location.pathname === "/backend/backend-details/brand-list" || history.location.pathname === "/backend/backend-details/brand-details" || history.location.pathname === "/backend/backend-details/campaign/create-trial-campaign" || history.location.pathname === "/backend/backend-details/create-trial-campaign" || history.location.pathname === "/backend/backend-details/campaign/brand/campaign-details" || history.location.pathname === "/backend/backend-details/brand/campaign-details") {
            setCurrentTab("brand")
        }
        else if (history.location.pathname === "/backend/backend-details/exportSchoolData") {
            setCurrentTab("exportSchoolData")
        }
        else if (history.location.pathname === "/backend/backend-details/nonWisr-school-list" || history.location.pathname === "/backend/backend-details/nonWisr-school-details") {
            setCurrentTab("nonWisrSchoolList")
        }
        else if (history.location.pathname === "/backend/backend-details/scrapped-school-data" || history.location.pathname === "/backend/backend-details/scrapped-school-data-details") {
            setCurrentTab("ScrappedSchoolData")
        }
        else if (history.location.pathname === "/backend/backend-details/nonWisr-campaign-list" || history.location.pathname === "/backend/backend-details/nonWisr-campaign-detail") {
            setCurrentTab("nonWisrCampaign")
        }
        else if (history.location.pathname === "/backend/backend-details/schoolInfraDetails") {
            setCurrentTab("schoolInfraDetails")
        }
        else if (history.location.pathname === "/backend/backend-details/campaign-reporting-list" || history.location.pathname === "/backend/backend-details/campaign-school-list" || history.location.pathname === "/backend/backend-details/create-new-camp-set") {
            setCurrentTab("campaignReport")
        }
    }, [history.location.pathname])

    useEffect(() => {
        console.log("navigator.userAgent", navigator.userAgent)
        let regExp = /android|iphone|kindle|ipad/i;
        setIsMobileDevice(regExp.test(navigator.userAgent))
    }, [navigator.userAgent])

    useEffect(() => {
        toggleMenu()
    }, [currentTab])

    const logoutUser = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
        };
        console.log(context)
        setIsLoggedOut(true)
        fetch("/api/logout", requestOptions)
            .then(response => response.json())
            .then(data => {
                console.log("data>>>>>", data);
                if (data.done) {
                    setToggleLogoutDialog(false)
                    setOverlay(false)
                    console.log(data)
                    setIsLoggedOut(false)
                    // localStorage.setItem("prevUserId", context.user._id)
                    eraseAllCookies();
                    eraseCookie("session-wisr-core-microservice-backend")
                    context.socketManager.socket.disconnect();
                    setOverlay(false)
                    setToggleLogoutDialog(false)
                    document.location.href = "/login"
                    sessionStorage.clear()
                }
            }).catch(err => {
                setIsLoggedOut(false)
                console.log(err);
            }).catch(err => {
                setIsLoggedOut(false)
                console.log(err);
            })
    }

    const [isLoading, setIsLoading] = useState(true)

    const toggleLogoutDialogBox = () => {
        setOverlay(true)
        setToggleLogoutDialog(true)
    }

    const confirmLogout = (confirm) => {
        if (confirm) {
            logoutUser();
        }
        else {
            setToggleLogoutDialog(false)
            setOverlay(false)
        }
    }
    const toggleMenu = () => {
        if (parentData.responsiveSidemenu === true) {
            parentData.toggleResponsiveSidemenu()
        }
    }

    const getExportableData = async () => {

        console.log("--getExportableData---check here==new==>")
        props.setIsSchoolListDownload(true)
        setDownloadSchoolLoading(true);

        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        };

        fetch("/api/exportSchoolData/all", requestOptions)
            .then(response => {
                return response.json();
            })
            .then(async data => {
                if (data) {
                    setDownloadSchoolLoading(false);
                    let file = data.result.file.data;
                    downloadFile(file, data.result.filename)
                }
                props.setIsSchoolListDownload(false)
                setDownloadSchoolLoading(false);
            })
    }

    // const getExportableData1 = async () => {

    //     console.log("--getExportableData---check here==new==>")
    //     setDownloadSchoolLoading(true);

    //     const requestOptions = {
    //         method: 'GET',
    //         headers: { 'Content-Type': 'application/json' },
    //     };

    //     fetch("/api/exportSchoolData", requestOptions)
    //         .then(response => {
    //         return response.json();
    //     })
    //         .then(async data => {
    //             if (data) {
    //                 setDownloadSchoolLoading(false);
    //                 let file = data.result.file.data;
    //                 downloadFile(file, data.result.filename)
    //             }
    //             props.setIsSchoolListDownload(false)
    //         })
    //     }

    const getAllSchoolInfraDetails = async () => {

        props.setIsSchoolListDownload(true)
        setDownloadSchoolLoading(true);

        let resp = await context.apis[context.user.role].getAllSchoolInfraDetails(
            context.validator, {}, {}
        )
        //console.log("getAllSchoolInfraDetails", resp)

        let data = resp.response

        if (resp && resp.done) {

            const options = {
                fieldSeparator: ',',
                quoteStrings: '"',
                decimalSeparator: '.',
                showTitle: false,
                filename: 'SchoolInfraDetails',
                useTextFile: false,
                useKeysAsHeaders: true,
                // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
            };

            const csvExporter = new ExportToCsv(options);
            csvExporter.generateCsv(data);

            props.setIsSchoolListDownload(false)
            setDownloadSchoolLoading(false);
        }
    }

    return (
        <>
            <div className={`ovelap ${(overlay === true) ? "active" : ""}`}></div>
            <div className="col-xl-2 col-lg-3 col-md-12 col-xs-12 pl-0 pr-0">
                <div className={`sliderbar updatebar ${parentData.responsiveSidemenu === true ? "no_highlight" : ""}`}>
                    <div className="row logo between-xs bottom-xs logoNew"><img src={logoNew} alt="logo" /></div>
                    <ul className="dnone">
                        <li><a href="javascript:void(0);" className=""><span><DashboardSVG /> </span> Dashboard</a></li>

                        <li><a href="javascript:void(0);" className=""><span><BookingSVG /> </span> Bookings</a></li>

                        <li><a href="javascript:void(0);" className="active"><span><InventorySVG /></span> Inventory</a></li>

                        <li><a href="javascript:void(0);"><span><MessageSVG /> </span> Message</a></li>

                        <li><a href="javascript:void(0);"><span><SchoolSVG /></span> My School</a></li>

                        <li><a href="javascript:void(0);"><span><SettingsSVG /></span> Settings</a></li>
                    </ul>

                    <ul id="navBar">
                        {context.user.role !== "backendFOS" &&
                            <li id="navBarDashBoard" onClick={() => setCurrentTab("dashBoard")}>
                                <Link to="/backend/backend-details/dashboard"
                                    className={currentTab === "dashBoard" ? "active" : ""}><span><DashboardSVG /> </span> Dashboard</Link>
                            </li>
                        }
                        {context.user.role !== "backendFOS" &&
                            <li id="navBarSchool" onClick={() => setCurrentTab("school")}>
                                <Link to="/backend/backend-details/school-list"
                                    className={currentTab === "school" ? "active" : ""}><span><SchoolSVG /></span> SCHOOL</Link>
                            </li>
                        }
                        {context.user.role !== "backendFOS" &&
                            <li id="navBarInventory" onClick={() => setCurrentTab("inventory")}>
                                <Link to="/backend/backend-details/inventory-list"
                                    className={currentTab === "inventory" ? "active" : ""}><span><InventorySVG /> </span> Inventory</Link>
                            </li>
                        }
                        {context.user.role !== "backendFOS" &&
                            <li id="navBarInventory" onClick={() => setCurrentTab("campaign")}>
                                <Link to="/backend/backend-details/campaign-list"
                                    className={currentTab === "campaign" ? "active" : ""}><span><CampaignSvg /> </span> Campaign</Link>
                            </li>
                        }
                        {context.user.role !== "backendFOS" &&
                            <li id="navBarInventory" onClick={() => setCurrentTab("nonWisrCampaign")}>
                                <Link to="/backend/backend-details/nonWisr-campaign-list"
                                    className={currentTab === "nonWisrCampaign" ? "active" : ""}><span><CampaignSvg /> </span> Non-Wisr-Campaign</Link>
                            </li>
                        }
                        {context.user.role !== "backendOperations" && context.user.role !== "backendFOS" &&
                            <li onClick={() => setCurrentTab("brand")}>
                                <Link to="/backend/backend-details/brand-list"
                                    className={currentTab === "brand" ? "active" : ""}><span><BrandSvg /> </span> Brand</Link>
                            </li>
                        }
                        {context.user.role !== "backendFOS" && <li className="line-sidebar"><a href="javascript:void(0);"></a>
                        </li>}
                        {context.user.role !== "backendSales" && context.user.role !== "backendFinance" &&
                            <li onClick={() => setCurrentTab("fosAgency")}>
                                <Link to={{
                                    pathname: context.user.role !== "backendFOS" ? "/backend/backend-details/fos-list" : "/backend/backend-details/fos-details",
                                    state: context.user.role !== "backendFOS" ? undefined : { userId: context.user._id }
                                }}
                                    className={currentTab === "fosAgency" ? "active" : ""}><span><BrandSvg /> </span> FOS Agencies
                                </Link>
                            </li>
                        }
                        {context.user.role === "backendFOS" && isMobileDevice &&
                            <li id="campaignReport" onClick={() => setCurrentTab("campaignReport")}>
                                <Link to={context.user.role === "backendFOS" ? "/backend/backend-details/campaign-reporting-list" : "/backend/backend-details/active-campaigns"}
                                    className={currentTab === "campaignReport" ? "active" : ""}><span><CampaignSvg /> </span> Campaign Reporting</Link>
                            </li>
                        }
                        {context.user.role === "backendAdmin" &&
                            <li onClick={() => setCurrentTab("addUser")}>
                                <Link to="/backend/backend-details/user-list" className={currentTab === "addUser" ? "active" : ""}>
                                    <span><ProfileSVG /> </span> Add Users
                                </Link>
                            </li>
                        }
                        {context.user.role === "backendAdmin" &&
                            <li onClick={() => setCurrentTab("activityStatus")}>
                                <Link to="/backend/backend-details/activity-status-list" className={currentTab === "activityStatus" ? "active" : ""}>
                                    <span><InventorySVG /> </span> Activity Status
                                </Link>
                            </li>
                        }
                        {/* {context.user.role === "backendAdmin" &&
                            <li onClick={() => setCurrentTab("exportSchoolData")}>
                                <a href="javascript:void(0);" className={`downloadSvg ${downloadSchoolLoading ? 'btn-disabled btn-opacity' : ''}`} onClick={getExportableData}><span><InventorySVG /> </span> Export School List</a>
                            </li>
                        } */}
                        {context.user.role !== "backendFOS" &&
                            <li id="navBarNonWisrSchoolList" onClick={() => setCurrentTab("nonWisrSchoolList")}>
                                <Link to="/backend/backend-details/nonWisr-school-list"
                                    className={currentTab === "nonWisrSchoolList" ? "active" : ""}><span><SchoolSVG /> </span> Non-wisr-schools
                                </Link>
                            </li>
                        }
                        {/* {(context.user.role === "backendAdmin" || context.user.role === "backendOperations" || context.user.role === "backendTech") &&
                            <li id="navBarNSchoolListWithInfraDetail" onClick={() => setCurrentTab("schoolInfraDetails")}>
                                <Link to="/backend/backend-details/schoolInfraDetails" 
                                    className={currentTab === "schoolInfraDetails" ? "active" : ""}><span><SchoolSVG /> </span> SCHOOL INFRA-DETAILS
                                </Link>
                            </li>
                        } */}

                        {(context.user.role === "backendAdmin" || context.user.role === "backendOperations" || context.user.role === "backendTech") &&
                            <li id="navBarNSchoolListWithInfraDetail" onClick={() => setCurrentTab("schoolInfraDetails")}>
                                <a href="javascript:void(0);" className={`downloadSvg ${downloadSchoolLoading ? 'btn-disabled btn-opacity' : ''}`} onClick={getAllSchoolInfraDetails} ><span><SchoolSVG /> </span> SCHOOL INFRA-DETAILS</a>
                            </li>
                        }
                        {/* Scrapped Schools Data Link */}
                        {context.user.role !== "backendFOS" && context.user.role !== "backendSales" &&
                            <li id="navBarScrappedSchoolData" onClick={() => setCurrentTab("ScrappedSchoolData")}>
                                <Link to="/backend/backend-details/scrapped-school-data"
                                    className={currentTab === "ScrappedSchoolData" ? "active" : ""}><span><SchoolSVG /> </span> Scraped School
                                </Link>
                            </li>
                        }

                         {/* {(context.user.role === "backendAdmin" || context.user.role === "backendOperations" || context.user.role === "backendTech") &&
                            <li id="navBarNSchoolListWithInfraDetail" onClick={() => setCurrentTab("schoolInfraDetails")}>
                                <a href="javascript:void(0);" className={`downloadSvg ${downloadSchoolLoading ? 'btn-disabled btn-opacity' : ''}`} onClick={getAllSchoolInfraDetails} ><span><SchoolSVG /> </span> SCHOOL INFRA-DETAILS</a>
                            </li>
                        } */}


                        {/* {context.user.role === "backendAdmin" &&
                            <li onClick={() => setCurrentTab("exportSchoolData")}>
                                <a href="javascript:void(0);" className={`downloadSvg ${downloadSchoolLoading ? 'btn-disabled btn-opacity' : ''}`} onClick={getExportableData1}><span><InventorySVG /> </span> Export School List 1</a>
                            </li>
                        } */}
                        {/* <li> <Link to="/backend/backend-details/settings"
            className={history.location.pathname === "/backend/backend-details/settings" ? "active" : ""}><span><SettingsSVG /></span> settings</Link> </li> */}
                        <li><a href="javascript:void(0);" onClick={toggleLogoutDialogBox}><span><LogoutSVG /></span> logout</a> </li>

                    </ul>
                    <img src={waveBootNew} className="wavebot" alt="wave" /> </div>
            </div>
            <ConfirmDialog
                togglePopup={toggleLogoutDialog}
                onModalClose={confirmLogout}
                componentName={""}
                confirmTitle="Are you sure you want to logout?"
                confirmText="Logout"
                isLoading={isLoggedOut}
            />
        </>
    )
}

export default BackendDetailsSideMenu
