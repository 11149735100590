import React, { useEffect, useState, useContext, useRef } from 'react'
import uploadSvg from "../../../assets/images/upload-svg.svg"
import sucessCheck from "../../../assets/images/sucess-check.svg"
import closeSvg from "../../../assets/images/close-svg.svg"
import blockSvg from "../../../assets/images/block-svg.svg"
import reloadSvg from "../../../assets/images/reload-svg.svg"
import filterSvg from "../../../assets/images/filter-svg.svg"
import filterArrowSvg from "../../../assets/images/filter-arrow-svg.svg"
import mapsImg from "../../../assets/images/maps.jpg"
import copySvg from "../../../../assets/images/copySvg.svg"
import LogSvg from "../../../../assets/images/wisrSvg.svg"
import { useHistory } from 'react-router-dom'
import AppContextObject from "../../../../common/context/common"
import { formatDateShowMonth } from "../../../../service/helper"
import DataContext from "../../../../service/brandContext"
import CampaignBudget from '../components/campaignBudget'
import GenderSplit from '../components/genderSplit'
import CampaignReach from '../components/campaignReach'
import CampaignImpression from '../components/campaignImpression'
import { returnReadableStatus } from '../../../../service/helper'
import TextField from '../../../../components/formFields/textField/textField'
import SimpleReactValidator from "simple-react-validator"
import errorSVG from "../../../../assets/images/error.svg"
import TextArea from '../../../../components/formFields/textArea'
import editSvg from "../../../../assets/images/edit-btn.svg"
import wavebotConfirm from "../../../../assets/images/wavebot.png"
import { ToWords } from 'to-words';

const Confirmation = ({setShowProgressSideMenu}) => {
    const context = useContext(AppContextObject)
    const parentData = useContext<any>(DataContext)
    const history = useHistory()
    const toWords = new ToWords();
    useEffect(() => {
        setShowProgressSideMenu(false)
    }, [])
    const handleClose = () => {
        history.push({pathname: "/brands/brand-details/campaigns", state: {campaignCreated: true}})
        // window.location.href = window.location.href;
    }
    const handleRedirect = () => {
        if(parentData.setEditFromConfirmationPage){
            parentData.setEditFromConfirmationPage(true)
        }
        if(parentData.data.brandDetail.isTrial){
            history.push("/brands/brand-details/create-trial-campaign/preview")
        }
        else{
            history.push("/brands/brand-details/create-campaign/preview")
        }
    }
    return (
        <>
        <div className={`ovelap`}></div>
        {/* <TopBar isDashboard={true} /> */}
        <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
        <div className="rightPart noBack">
            <img src={wavebotConfirm} className="wavebotConfirm" />
            <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-30">
                <div className="confirmation-box">
                    <img className="con-img" src={LogSvg} />
                    <h2 className="htx1 clearfix mb-10">{parentData.data.brandDetail.isTrial ? `Trial Campaign was Successfully Set Up!` : 'Congratulations!'}</h2>
                    <p className="ptx1 mb-0 mt-0">{parentData.data.brandDetail.isTrial ? `The Trial Campaign has successfully been created. You will be able to access this campaign in the campaign tab on your WISR account.` : `Your campaign has been created successfully! `}</p>
                    <p className="ptx1 mb-0 mt-0">{parentData.data.brandDetail.isTrial ? `` : `It will be reviewed by our team thoroughly, before it can go live. `}</p>
                    <p className="ptx1 mt-0">{parentData.data.brandDetail.isTrial ? `` : `You can access it anytime from the ‘Campaigns’ tab through your WISR account.`}</p>
                    {!parentData.data.brandDetail.isTrial && <p className="ptx1">A WISR representative will be assigned to your brand shortly. The details will be sent to you on your registered email ID.{/*<b>mohit@wisr.com</b> <img className="copy-img" src={copySvg} >*/}</p>}
                    <div className="personal-box">
                        <div className="row personal-box-top">
                            <div className="row col-xl-8 col-lg-8 col-md-8 col-xs-8 start-md">
                            </div>
                            <div className="row col-xl-4 col-lg-4 col-md-4 col-xs-4 end-xs">
                                <a href="javascript:void(0)" className="editbtn" onClick={handleRedirect}><img src={editSvg}/> Edit</a>
                            </div>
                        </div>
                        <div className="personal-bottom">
                            <div className="row personal-box-bot">
                                <div className="col-xl-5 col-lg-5 col-md-6 col-xs-12">
                                    <p>Name</p>
                                </div>
                                <div className="col-xl-7 col-lg-7 col-md-6 col-xs-12">
                                    <b>{parentData?.data?.brandDetail?.name}</b>
                                </div>
                                <div className="col-xl-5 col-lg-5 col-md-6 col-xs-12">
                                    <p>Start Date</p>
                                </div>
                                <div className="col-xl-7 col-lg-7 col-md-6 col-xs-12">
                                    <b>{parentData?.data?.brandDetail?.startDate !== "" ? formatDateShowMonth(parentData?.data?.brandDetail?.startDate, 2, true) : ""}</b>
                                </div>
                                <div className="col-xl-5 col-lg-5 col-md-6 col-xs-12">
                                    <p>End Date</p>
                                </div>
                                <div className="col-xl-7 col-lg-7 col-md-6 col-xs-12">
                                    <b>{parentData?.data?.brandDetail?.endDate !== "" ? formatDateShowMonth(parentData?.data?.brandDetail?.endDate, 2, true) : ""}</b>
                                </div>
                                <div className="col-xl-5 col-lg-5 col-md-6 col-xs-12">
                                    <p>Budget</p>
                                </div>
                                <div className="col-xl-7 col-lg-7 col-md-6 col-xs-12">
                                    <b>{`₹ ${parentData?.data?.campaignDetail?.totalBudget.toLocaleString("en-IN")}`} &nbsp;
                                        <em>{`Rupees ${toWords.convert(parentData?.data?.campaignDetail?.totalBudget)}`}</em>
                                    </b>
                                </div>
                                <div className="col-xl-5 col-lg-5 col-md-6 col-xs-12">
                                    <p>No of Schools Occupied</p>
                                </div>
                                <div className="col-xl-7 col-lg-7 col-md-6 col-xs-12">
                                    <b>{parentData?.data?.school?.schools.length}</b>
                                </div>
                                <div className="col-xl-5 col-lg-5 col-md-6 col-xs-12">
                                    <p>Total Estimated Reach</p>
                                </div>
                                <div className="col-xl-7 col-lg-7 col-md-6 col-xs-12">
                                    <b>{`${parentData?.data?.campaignDetail?.totalReach.toLocaleString("en-IN")} Student(s)`}</b>
                                </div>
                            </div>
                        </div>
                    </div>
                    {parentData.data.brandDetail.isTrial ?  
                        <input name="CLOSE" className="btn btn-orange" type="button" value="FINISH" onClick={handleClose}/>
                        : 
                        <input name="CLOSE" className="btn btn-orange" type="button" value="CLOSE" onClick={handleClose}/>
                    }
                </div>
            </div>
            
            </div>
        </div>
        </div>
    </>
    )
}

export default Confirmation
