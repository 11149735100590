import React, {
    useContext,
    useState,
    useRef,
    useEffect
} from "react"

import { useHistory, useLocation } from "react-router-dom"
import DataContext from "../../../../../service/contextProvider"
import edit from "../../../../../assets/images/edit-btn.svg"

import InventorySpecific from "../../../../../components/addon/summary/inventorySpecific"

import AppContextObject from "../../../../../common/context/common"
import { objectDeepClone } from "../../../../../service/helper"

import toast from "react-hot-toast"
import ConfirmDialog from "../../../../../components/addon/confirmDialog/confirmDialog"

interface Props {
    isEdit?: boolean
    onProceed?: Function
    setShowMenu?: Function
    redirectionURL?: string
    inventoryEditURL?: string,
    byPassEventFlow?: any
    byPassInventoryFlow?: any,
    fromBackend?: boolean,
    addInventory?: boolean,
    schoolId?: any
}

const InventorySummary = ({
    isEdit,
    onProceed,
    setShowMenu,
    redirectionURL,
    inventoryEditURL,
    byPassEventFlow,
    byPassInventoryFlow,
    fromBackend,
    addInventory,
    schoolId
}: Props) => {

    const context = useContext(AppContextObject)
    const history = useHistory()
    const location = useLocation()
    const parentData = useContext<any>(DataContext)

    const [loading, setLoading] = useState(false)
    const [showQuitModal, setShowQuitModal] = useState(false)
    const [overlay, setOverlay] = useState(false)

    useEffect(() => {
        if (parentData.setActiveModule) {
            parentData.setActiveModule({
                module: "preview",
                subModule: ""
            })
        }          
        setShowMenu(true)
    }, [])

    const onSubmit = async () => {
        if (parentData) {
            let eventsPromise = null
            let inventoriesPromise = null
            if (parentData?.data?.student?.activities && parentData.data.student.activities.length > 0) {
                let events = objectDeepClone(parentData.data.student.activities)
                eventsPromise = context.apis.public.upsertStudentLifeAndActivitiesWithAttributes(
                    context.validator, {
                        student: {
                            "school": fromBackend ? schoolId : context.user.school,
                            "user": fromBackend ? null : context.user._id,
                            "life": [],
                            "activities": objectDeepClone(events),
                            "removeActivitiesArray": []
                        }
                    }, {}
                )
            }
            if (parentData?.newlyAddedInventories && parentData.newlyAddedInventories.length > 0) {
                let facilities = objectDeepClone(parentData.newlyAddedInventories)
                facilities.forEach(facility => {
                    facility.data.forEach(d => {
                        d["type"] = facility.type
                    })
                    facility["status"] = "In Review"
                })
                inventoriesPromise = context.apis.public.upsertSchoolInventoriesDetailsBySchoolId(
                context.validator, {
                    "school": fromBackend ? schoolId : context.user.school,
                    "user": fromBackend ? null : context.user._id,
                    "amenities": objectDeepClone(facilities),
                    "removeInventoriesArray": [],
                    "addInventories": true
                    }, {}
                )
            }
            let results = []
            if (eventsPromise !== null) results.push(eventsPromise)
            if (inventoriesPromise !== null) results.push(inventoriesPromise)

            const response = await Promise.all(results).catch(err => console.log(err))
            if (response && response.filter(res => res === false).length === 0) {
                // toast.success("Inventory/Inventories has been added to your inventory list successfully. Brands will be able to send booking requests once it is approved.")
                if(fromBackend){
                    if(context.user.role === "backendFOS"){
                        history.push({pathname: "/backend/backend-details/fos-school/school-details", state: { schoolId: schoolId }})
                    }
                    else{
                        history.push({pathname: "/backend/backend-details/school-details", state: { schoolId: schoolId }})
                    }
                }else{
                    history.push({pathname: "/schools/school-details/inventory", state: {updated: byPassEventFlow ? "inventoryUpdated" : byPassInventoryFlow ? "eventUpdated" : "bothUpdated"}})
                }
            } else {
                toast.error("Something went wrong")
            }              
        }
    }

    const goToInventoryPage = () => {
        history.push({
            pathname: fromBackend ? "/backend/backend-details/add-invetory/remaining-invetories" : byPassEventFlow ? "/schools/school-details/add-invetory/invetories-details" : "/schools/school-details/add-invetory/remaining-invetories",
            search: "isSummary=true",
        })
    }

    const goToActivitiesDetails = () => {
        history.push({
            pathname: fromBackend ? "/backend/backend-details/add-invetory/events-and-occasions" : byPassInventoryFlow ? "/schools/school-details/add-invetory/events-and-occasions-details" : "/schools/school-details/add-invetory/events-and-occasions",
            search: "isSummary=true",
        })
    }

    const renderVenueList = (el) => {
        let venue = el.venue
        let otherVenue = el.otherVenueList
        venue = venue.filter(e => e !== "Other")
        const clubbedArr = [...venue, ...otherVenue]
        return clubbedArr.length > 0 ? clubbedArr.join(", ") : "-"
    }
    const openQuitModal = () => {
        setOverlay(true)
        setShowQuitModal(true)
    }
    const onQuitModalClose = (confirm) => {
        if(confirm){
            if(fromBackend){
                history.push({pathname: "/backend/backend-details/school-details", state: { schoolId: schoolId }})
            }else{
                history.push("/schools/school-details/inventory")
            }
        }
        setShowQuitModal(false)
        setOverlay(false)
    }

    return (
        <>
            <div className={`ovelap ${overlay === true ? "active" : ""}`}></div>
            <div className='col-xl-9 col-lg-9 col-md-12 col-xs-12'>
                <div className='content mCustomScrollbar fixhight'>
                    <div className='rightPart onboding'>
                        <div className='row'>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-20"><a style={{cursor: "pointer"}} className="fR blackC" onClick={openQuitModal}>Quit</a></div>
                            <div className='col-xl-12 col-lg-12 col-md-12 col-xs-12'>
                                <div className='clearfix'></div>
                                <h2 className='htx1 mb40'>Onboarding Overview</h2>
                            </div>

                            {(fromBackend ? addInventory : (byPassInventoryFlow === undefined || byPassInventoryFlow === false)) && 
                                <div className='col-xl-12 col-lg-12 col-md-12 col-xs-12'>
                                    <div className='personal-box'>
                                        <div className='row personal-box-top'>
                                            <div className='row col-xl-8 col-lg-8 col-md-8 col-xs-8 start-md'>
                                                <h3 className='htx2 mb20'>Inventory</h3>
                                            </div>
                                            <div className='row col-xl-4 col-lg-4 col-md-4 col-xs-4 end-xs'>
                                                {" "}
                                                <a
                                                    href='javascript:void(0)'
                                                    className='editbtn'
                                                    onClick={goToInventoryPage}
                                                    >
                                                <img src={edit} /> Edit
                                                </a>{" "}
                                            </div>
                                        </div>
                                        <div className='personal-bottom'>
                                            <div className='row personal-box-bot'>
                                                <InventorySpecific data={parentData.selectedInventories} heading="Inventories" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                            {(fromBackend ? !addInventory : (byPassEventFlow === undefined || byPassEventFlow === false)) &&
                                <div className='col-xl-12 col-lg-12 col-md-12 col-xs-12'>
                                    <div className='personal-box'>
                                        <div className='row personal-box-top'>
                                            <div className='row col-xl-8 col-lg-8 col-md-8 col-xs-8 start-md'>
                                                <h3 className='htx2 mb20'>Events and Occasions</h3>
                                            </div>
                                            <div className='row col-xl-4 col-lg-4 col-md-4 col-xs-4 end-xs'>
                                                <a
                                                    href='javascript:void(0)'
                                                    className='editbtn'
                                                    onClick={goToActivitiesDetails}
                                                    >
                                                <img src={edit} /> Edit
                                                </a>
                                            </div>
                                        </div>
                                        <div className='personal-bottom'>
                                            <div className='row personal-box-bot'>
                                                <div className='col-xl-6 col-lg-6 col-md-6 col-xs-12'>
                                                    <p>Selected Events</p>
                                                </div>
                                                <div className='col-xl-6 col-lg-6 col-md-6 col-xs-12'>
                                                    <p>Venue</p>
                                                </div>
                                                {(parentData.data.student.activities && parentData.data.student.activities.length > 0) ?
                                                    parentData.data.student.activities.map((item, index) => {
                                                        return (
                                                            <React.Fragment key={index}>
                                                                <div className='col-xl-6 col-lg-6 col-md-6 col-xs-12'>
                                                                    <b>{item.name}</b>
                                                                </div>
                                                                <div className='col-xl-6 col-lg-6 col-md-6 col-xs-12'>
                                                                    <b>
                                                                    {renderVenueList(item)}
                                                                    </b>
                                                                </div>
                                                            </React.Fragment>
                                                        )
                                                    })
                                                    :
                                                    <React.Fragment>
                                                        <div className='col-xl-6 col-lg-6 col-md-6 col-xs-12'>
                                                            <b>-</b>
                                                        </div>
                                                        <div className='col-xl-6 col-lg-6 col-md-6 col-xs-12'>
                                                            <b>-</b>
                                                        </div>
                                                    </React.Fragment>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                            <div className='row col-xl-8 col-lg-8 col-md-8 col-xs-12 middle-xs'></div>
                            <div className='row col-xl-4 col-lg-4 col-md-4 col-xs-12 end-md'>
                                <div className={`forminpt ${loading ? "button--loading" : ""}`}>
                                    <input
                                        name="Save Changes"
                                        value="Save Changes"
                                        className={`btn btn-orange comp-seting ${(loading === true) ? "btn-disabled" : ""}`}
                                        type='button'
                                        onClick={onSubmit}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ConfirmDialog
                confirmTitle="Do you want to Quit"
                togglePopup={showQuitModal}
                onModalClose={onQuitModalClose}
                componentName={""}
            />
        </>
    )
}

export default InventorySummary
