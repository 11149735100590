import { useEffect, useState } from "react";

import teamLeftarrow from "../../../assets/images/landing/teamLeftarrow.svg"
import teamRightarrow from "../../../assets/images/landing/teamRightarrow.svg"
import ourTeam1 from "../../../assets/images/landing/our-team-1.jpg"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import wisrNews from "../../../assets/images/landing/wisr-news.png"

const News = (props) => {
    const {
        getNewsDetails,
        newsSection,
        title
    } = props


    const [lastIndex, setLastIndex] = useState(100)

    const [noOfSlides, setNoOfSlides] = useState([])
    const [currentIndex, setCurrentIndex] = useState(0);
    const [ourTeamPopUp, setOurTeamPopUp] = useState(false)
    const [selectedTeamMember, setSelectedTeamMember] = useState<any>({})
    const [overlay, setoverlay] = useState(false)
    const onNext = () => {
        // console.log("current Index..noofslides...", currentIndex, noOfSlides)
        if (currentIndex < noOfSlides.length - 1) {
            setCurrentIndex((prev) => prev + 1)
        }


    }
    const onPrev = () => {
        if (currentIndex !== 0) {
            setCurrentIndex((prev) => prev - 1)
        }
    }
    function slideCount() {
        let a = 0;
        if (getNewsDetails.length % 3 == 0) {
            a = getNewsDetails.length / 3
        }
        else {
            a = Math.floor(getNewsDetails.length / 3) + 1
        }
        let temp = []
        for (var i = 0; i < a; i++) {
            temp.push(i)
        }
        setNoOfSlides(temp)
    }
    useEffect(() => {
        slideCount()
    }, [])

    const [startIndex, setStartIndex] = useState(0)
    const [endIndex, setEndIndex] = useState(0)

    useEffect(() => {
        setLastIndex(currentIndex === (noOfSlides.length - 1) ? (getNewsDetails.length - ((currentIndex) * 4)) * 20 : 80)

        // console.log("while changing start and end... currentIndex", currentIndex)
        if (currentIndex === (noOfSlides.length - 1)) {
            setStartIndex((currentIndex * 4) - (4 - (getNewsDetails.length - (currentIndex * 4))))
            setEndIndex(getNewsDetails.length)
        }
        else {
            setStartIndex((currentIndex * 4))
            setEndIndex((currentIndex * 4) + 4)
        }

    }, [currentIndex])
    const closeWithoutSaving = () => {
        setOurTeamPopUp(false)
        setoverlay(false)
    }

    //   useEffect(() => {
    //       console.log("Last Index value..", lastIndex, currentIndex, noOfSlides.length)
    //   }, [lastIndex])

    useEffect(() => {
        // console.log("start End current index value..", startIndex, endIndex, currentIndex)
    }, [endIndex, startIndex])

    var settings = {
        infinite: false,
        className: "",
        speed: 900,
        // slidesToScroll: 3,
        // slidesToShow: 3.5,
        nextArrow: <SampleNextArrow
                    onNext={onNext}
                    />,
        prevArrow: <SamplePrevArrow 
                    onPrev={onPrev}
                    />,
        responsive: [
            {
                breakpoint: 2560,
                settings: {
                    slidesToScroll: (newsSection ? 4 : 5),
                    slidesToShow: (newsSection ? 4 : 5)
                }
            },
            {
              breakpoint: 1024,
              settings: {
                slidesToScroll: (newsSection ? 3 : 4),
                slidesToShow: (newsSection ? 3 : 4)
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToScroll: (newsSection ? 2 : 3),
                slidesToShow: (newsSection ? 2 : 3)
              }
            },
            {
              breakpoint: 400,
              settings: {
                slidesToScroll: (newsSection ? 1 : 2),
                slidesToShow: (newsSection ? 1 : 2)
              }
            },
        ]
    };
    var settings1 = {
        infinite: true,
        autoplay: true,
        // autoplaySpeed: 2000,
        autoplaySpeed: 3000,
        className: "",
        speed: 900,
        nextArrow: <SampleNextArrow
                    onNext={onNext}
                    />,
        prevArrow: <SamplePrevArrow 
                    onPrev={onPrev}
                    />,
        responsive: [
            {
                breakpoint: 2560,
                settings: {
                    slidesToScroll: (newsSection ? 5 : 6),
                    slidesToShow: (newsSection ? 5 : 6)
                }
            },
            {
              breakpoint: 1024,
              settings: {
                slidesToScroll: (newsSection ? 3 : 4),
                slidesToShow: (newsSection ? 3 : 4)
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToScroll: (newsSection ? 2 : 3),
                slidesToShow: (newsSection ? 2 : 3)
              }
            },
            {
              breakpoint: 400,
              settings: {
                slidesToScroll: (newsSection ? 1 : 2),
                slidesToShow: (newsSection ? 1 : 2)
              }
            },
        ]
    };
    const openModal = (teamMember) => {
        // console.log("first", teamMember)
        setSelectedTeamMember({...teamMember})
        setoverlay(true)
        setOurTeamPopUp(true)
    }
    return (
        <>
            <div className={`ovelap ${overlay === true ? "active" : ""}`}></div>
           

           
            {getNewsDetails.length != 0 &&
            
            // <div className='school-generate ourteamNews' id="WisrNews">
            <div className='school-generate ourteam' id="WisrNews">
                <div className='containerLanding'>
                    
                        <div className='row'>
                            <div className='col-xl-8 col-lg-8 col-md-8 col-xs-8'>
                                <h3 className='headhx2' style={{margin: "0"}}>{newsSection ? 'WISR in the news' : title}</h3>
                            </div>
                                <div className="teamSlider">
                                    <div className='col-xl-12 col-lg-12 col-xs-12 pl pr'>
                                        {/* <div style={{display:'flex',justifyContent:'center'}}>
                                            

                                            
                                            <img src={wisrNews} style={{height:'350px'}}/>
                                        </div> */}

                                        
                                        <Slider {...settings1}>
                                            {
                                                
                                                getNewsDetails.map((el, index) => {
                                                    return (
                                                        <>
                                                           
                                                            <div className='advisory-board-oneNew'>
                                                            
                                                                    <span>
                                                                    <a href={el.news_url} target="_blank">
                                                                        <img src={el.news_image || el.img} style={{height: newsSection ? '150px' : '60px', width: newsSection ? '150px' : "auto"}}/>
                                                                        {/* <img src={el.news_image} width={10} height={10} /> */}
                                                                    </a>
                                                                    </span>
                                                            
                                                                    {/* <h4>{el.news_title}</h4> */}
                                                                    {/* <a href={el.news_url} target="_blank">Read more</a> */}
                                                                    {/* <h3>{el.news_title}</h3> */}
                                                                    
                                                                    {/* <a href={el.news_url} style={{wordWrap:'break-word'}} target="_blank">{el.news_url}</a> */}
                                                                    {/* <a href={el.news_url} target="_blank">{el.news_title}</a> */}
                                                            </div>
                                                            
                                                            
                                                        </>
                                                    )
                                                })
                                            }
                                        </Slider>
                                        </div>
                                    </div>
                        </div>
                </div>
            </div>
            }
        </>
    )

}

export default News

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            // style={{ ...style, background: "red", top: 200, position: "absolute",right: '-30px',cursor:'pointer' }}
            //style={{ ...style, top: 90, position: "absolute",right: '-30px',cursor:'pointer' }}
            onClick={onClick}
        >
            <a onClick={() => props.onNext()}><img src={teamRightarrow} /></a>
        </div>
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            //style={{ ...style,top: 90, left: '-30px',position: "absolute",cursor:'pointer' }}
            onClick={onClick}
        >
            <a onClick={() => props.onPrev()}><img src={teamLeftarrow} /></a>
        </div>
    );
}