import io from 'socket.io-client'
import { AppContext } from '../context/common';
export class SocketManager{
    socket: SocketIOClient.Socket;
    constructor(public context:AppContext, private events:{onSend:()=>void, onReceive:()=>void}){
        //process.env.NODE_ENV === "development" ? "localhost:4602" : ""
        this.socket = io();
        console.log("Socket manager initialized...")
    }
    private __send(socketObj: SocketIOClient.Socket, endpoint: string, data: any, onProgress: (arg0: any) => void):Promise<any>{
        return new Promise((resolve, reject)=>{
            // if (typeof mainProgress !== 'undefined') mainProgress.state.animating = true;
            var reqId = 'r' + Math.random();
            socketObj.emit(endpoint, { reqId: reqId, data: data });
            this.events.onSend();
            let onReceiveTimeout = setTimeout(()=>{
                this.events.onReceive();
            }, 10000);
            socketObj.once(endpoint + ':' + reqId + ':reply', (data)=>{
                resolve(data);
                clearTimeout(onReceiveTimeout);
                this.events.onReceive();
                // if (typeof mainProgress !== 'undefined') mainProgress.state.animating = false;
                // console.log(`${endpoint}:reply`,data)
                socketObj.off(endpoint + ':' + reqId + ':progress');
            });
            socketObj.on(endpoint + ':' + reqId + ':progress', (data)=>{
                console.log('received progress',data,onProgress)
                if (onProgress) {
                    onProgress(data);
                }
            });
            socketObj.once(endpoint + ':' + reqId + ':error', (data)=>{
                reject(data);
                // if (typeof mainProgress !== 'undefined') mainProgress.state.animating = false;
                socketObj.off(endpoint + ':' + reqId + ':progress');
            });
        });
    }
    send(endpoint: string, data: any, onProgress?: any){
        console.log('sendData->' + endpoint, data, this.socket);
        return this.__send(this.socket, endpoint, data, onProgress);
    }
    post(url: string,data: any,noJSON: any){
        return new Promise((resolve,reject)=>{
            var xmlHttp = new XMLHttpRequest();
            xmlHttp.onreadystatechange = function() {
                if (xmlHttp.readyState == 4 && (xmlHttp.status == 200 || xmlHttp.status===301))
                {
                    resolve(noJSON?xmlHttp.responseText:JSON.parse(xmlHttp.responseText));
                }
            }
            xmlHttp.open("POST", url, true); // true for asynchronous
            xmlHttp.setRequestHeader("Content-type", "application/json");
            xmlHttp.send(JSON.stringify(data));
        });
    }    
    get(url: string){
        return new Promise((resolve,reject)=>{
            var xmlHttp = new XMLHttpRequest();
            xmlHttp.onreadystatechange = function() {
                if (xmlHttp.readyState == 4 && (xmlHttp.status == 200 || xmlHttp.status===301))
                {
                    resolve(xmlHttp.responseText);
                }
            }
            xmlHttp.open("GET", url, true); // true for asynchronous
            xmlHttp.send(null);
        });
    }
}