import React, { useEffect, useState, useContext, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import ImageCroper from "../../../../components/addon/imageCroper";
import TextField from "../../../../components/formFields/textField/textField";
import blankImg from '../../../../assets/images/blank-inventory-img.jpg'
import ImageModal from "../../../../components/addon/imageModal/imageModal";
import { PublicContextInitiator } from '../../../apis/publicContextInitiator'
import AppContextObject from '../../../../common/context/common'
import ConfirmedApprovalModal from "../../../../components/addon/confirmedApprovalModal";
import BrandDocsApprovalModal from "../components/BrandDocsApprovalModal";
import BrandDocsRejectModal from "../components/BrandDocsRejectModal";
import backBtn from "../../../../assets/images/back-arrow.svg";
import SimpleReactValidator from "simple-react-validator";
import errorSVG from "../../../../assets/images/error.svg"
import editSvg from "../../../../assets/images/edit-btn.svg"
import noImg from "../../../../assets/images/blank-inventory-img.jpg"
import toast from "react-hot-toast";
import EditGeneral from "../../brands/profile/editGeneral";
import EditContact from "../../brands/profile/editContact";
import EditDiscription from "../../brands/profile/editDiscription";
import EditSubCategory from "../../brands/profile/editSubCategory";
import BackendTopBar from "../components/backendTopBar";
import BackendBackBtn from "../components/backendBackBtn";
import EditWisrRepresentative from "../../brands/profile/editWisrRepresentative";
import ArrowView from "../../../../assets/images/arrow-view.svg"
import EditAllBrands from "../../brands/profile/editAllBrands";
import ConfirmDialog from "../../../../components/addon/confirmDialog/confirmDialog"
import WisrRepresentative from "../../brands/profile/WisrRepresentative";

const DetailsBrand = () => {
    const history = useHistory();
    const context = useContext(AppContextObject);
    const [formValidator] = useState(useRef(new SimpleReactValidator({
        element: (message, className) =>
            <p className="valError"><img src={errorSVG} />{message}</p>
    })))
    const [update, forceUpdate] = useState(false)
    const [toggleGstImage, setToggleGstImage] = useState(false);
    const [togglePanImage, setTogglePanImage] = useState(false);
    const [brandGeneralDetails, setBrandGeneralDetails] = useState<any>({});
    const [brandDocumentDetails, setBrandDocumentDetails] = useState<any>([]);
    const [brandContactDetails, setBrandContactDetails] = useState<any>([]);
    const [brandActivityDetails, setBrandActivityDetails] = useState<any>([]);
    const [brandDescriptionDetails, setBrandDescriptionDetails] = useState('');
    const [brandSubCategory, setBrandSubCategory] = useState<any>([]);
    const [openApprovalModal, setOpenApprovalModal] = useState(false);
    const [openRejectModal, setOpenRejectModal] = useState(false);
    const [overlay, setOverlay] = useState(false);
    const [docType, setDocType] = useState('');
    const [updateBrandDetails, setUpdateBrandDetails] = useState(false);
    const [showMoreSubCategory, setShowMoreSubCategory] = useState(false)
    const [showMoreBrand, setShowMoreBrand] = useState(false)
    const [refetch, setRefetch] = useState(false)
    const [companyId, setCompanyId] = useState<any>("")
    const [brandCategoryId, setbrandCategoryId] = useState<any>("")
    const [loading, setLoading] = useState(false)
    const [editingSubCategoryData, setEditingSubCategoryData] = useState(false)
    const [editingAllBrandsData, setEditingAllBrandsData] = useState(false)
    const [wisrRepresentative,setWisrRepresentative]=useState([]);
    const [allBrandList, setAllBrandList] = useState([])
    const [showBrandDeleteModal, setShowBrandDeleteModal] = useState(false)

    useEffect(()=>{
        wisrRepresentativeList();
    },[])
    useEffect(() => {
        if(history.location.state['brandId']){
            getBrandDetailsById()
            setCompanyId(history.location.state['brandId'])
        }
        if(history.location.state['brandCategoryId']){
            setbrandCategoryId(history.location.state['brandCategoryId'])
        }
    }, [updateBrandDetails, refetch])
    const wisrRepresentativeList = async() =>{
        let resp = await context.apis.public.getUsersListByRole(
            context.validator, { role: 'backendSales' }, {}
        )
        console.log('wisr representative resp======>', resp)

        if (resp && resp.done) {
            setWisrRepresentative(resp.response.rows)
        }
    }
    const getBrandDetailsById = async () => {
        setLoading(true)
        let resp = await context.apis.public.getBrandByIdExternal(
            context.validator, { _id: history.location.state['brandId'] }, {}
        )
        let resp2 = await context.apis.public.getBrandByIdExternal(
            context.validator, { _id: history.location.state['brandId'] }, {}
        )
        console.log('resp======>', resp)
        console.log('resp2======>', resp2)

        if (resp && resp.done && resp2 && resp2.done) {
            setBrandGeneralDetails(resp.response.general);
            setBrandDocumentDetails(resp.response.documents);
            setBrandContactDetails(resp.response.contact);
            setBrandDescriptionDetails(resp.response.description);
            setBrandActivityDetails(resp.response.activity);
            let brandList = []
            resp2.response.subCategories.forEach(category => {
                if(category.brandId && category.brandName){
                    if(!brandList.find(item => item.brandName === category.brandName)){
                        brandList.push(category)
                    }
                }
            })
            setAllBrandList([...brandList])
            // setAllBrandList([...resp2.response.subCategories.filter(category => category.brandId && category.brandName)])
            let uniqueSubCategoryList = [], subCategoryAndBrandData = {}
            if(resp.response.subCategories){
                resp.response.subCategories.forEach(subCategory => {
                    if(subCategoryAndBrandData[subCategory.name]){
                        subCategoryAndBrandData[subCategory.name].push(subCategory)
                    }
                    else{
                        subCategoryAndBrandData[subCategory.name] = [subCategory]
                    }
                })
                Object.keys(subCategoryAndBrandData).forEach((key) => {
                    if(subCategoryAndBrandData[key].length === 1){
                        uniqueSubCategoryList.push(subCategoryAndBrandData[key][0])
                    }
                    else{
                        let allUndefined = true, isArray = false, brandNameIndex = 0
                        // console.log("subCategoryAndBrandData[key]",subCategoryAndBrandData[key][0], subCategoryAndBrandData[key][1])
                        subCategoryAndBrandData[key].forEach((data, index) => {
                            console.log("data.brandName", data)
                            if(data.brandName && Array.isArray(data.brandName)){
                                allUndefined = false
                                brandNameIndex = index
                            }
                        })
                        if(allUndefined){
                            uniqueSubCategoryList.push(subCategoryAndBrandData[key][0])
                        }
                        else{
                            uniqueSubCategoryList.push(subCategoryAndBrandData[key][brandNameIndex])
                        }
                    }
                })
                // uniqueSubCategoryList = resp.response.subCategories.filter((v,i,a)=>a.findIndex(t=>(t.name.toLowerCase().trim() === v.name.toLowerCase().trim()))===i)
                var output = [];
                console.log("resp.response.subCategories", uniqueSubCategoryList, resp.response.subCategories, subCategoryAndBrandData)
                resp.response.subCategories.forEach(function(item) {
                var existing = output.filter(function(v, i) {
                    return v.name == item.name;
                });
                if (existing.length) {
                    var existingIndex = output.indexOf(existing[0]);
                    if(output[existingIndex].brandName){
                        let found = output[existingIndex].brandName.find(el => el === item.brandName)
                        if(!found){
                            output[existingIndex].brandName = output[existingIndex].brandName.concat(item.brandName);
                        }
                    }
                } else {
                    if (typeof item.brandName == 'string')
                    item.brandName = [item.brandName];
                    output.push(item);
                }
                });
            }
            setBrandSubCategory(uniqueSubCategoryList);
            setLoading(false)
        }else{
            setLoading(false)
        }
    }

    const openImageModal = (type) => {
        console.log(type)
        if (type === "gst") {
            setToggleGstImage(true)
        }
        else if (type === "pan") {
            setTogglePanImage(true)
        }
        setOverlay(true)
    }
    const confirmClose = (type) => {
        if (type === "gst") {
            setToggleGstImage(false)
        }
        else if (type === "pan") {
            setTogglePanImage(false)
        }
        setOverlay(false)
    }
    const openPDF = (dataString) => {
        var string = dataString
        var iframe = "<a href='" + string + "' download style='position: absolute; background: #FE6E00; color: #fff; border: 1px solid #FE6E00; right: 20%; font-family: sans-serif; padding: 5px; text-decoration: none;'>Download PDF</a><iframe width='100%' height='100%' src='" + string + "'></iframe>"
        var x = window.open();
        x.document.open();
        x.document.write(iframe);
        x.document.close();
    }

    const handleSave = async(confirm) => {
        if(confirm){
            const result = formValidator.current.allValid()

            if (result === false) {
                formValidator.current.showMessages()
                forceUpdate(true)
                return
            } else {
                formValidator.current.hideMessages()
                forceUpdate(!update)
            }
            const apiData = {...brandGeneralDetails}
            console.log("apiData------",apiData)
            let payload = {
                brandName: apiData.brandName,
                logo: apiData.logo,
                socialMediaLinks: apiData.socialMediaLinks,
                website: apiData.website
            }
            let updateResp = await context.apis[context.user.role].updateBrand(
                context.validator, {_id: context.user.parentBrand || context.user.brand}, payload
            )

            console.log("updateResp----",updateResp);

            if(updateResp && updateResp.done){
                toast.success("Brand updated successfully")
                setRefetch(!refetch)
            }
        }
        else{
        }
    }

    const handleEditingSubCategoryData = () =>{
        setEditingSubCategoryData(false)
    }
    const handleEditingAllBrandsData = () =>{
        setEditingAllBrandsData(false)
    }

    const openDeleteModal = () => {
        setShowBrandDeleteModal(true)
        setOverlay(true)
    }

    const deleteBrand = async(confirm) => {
        if(confirm){
            let resp = await context.apis[context.user.role].deleteBrand(
                context.validator, {_id: history.location.state['brandId']}
            )
            console.log("deleteBrand", resp)
            if(resp && resp.done){
                toast.success("Brand deleted successfully.")
                history.push('/backend/backend-details/brand-list')
                
            }
        }
        setOverlay(false)
        setShowBrandDeleteModal(false)
    }

    return (
        <>
            <div className={`ovelap ${overlay === true ? "active" : ""}`}></div>
            <div className="col-xl-10 col-lg-12 col-md-12 col-xs-12">
                <div className="rightPart noBack">
                    <div className="row">
                        <BackendTopBar redirectPage={true}  from={brandGeneralDetails['brandName']} showDeleteButton={true} onBrandDelete={openDeleteModal} />           
                        {/* <div className="col-xl-6 col-lg-6 col-md-6 col-xs-6">
                            <a className="backbtn dblock" href="/backend/backend-details/brand-list">
                                <Link to="/backend/backend-details/brand-list" className="backbtn dblock">
                                    <img src={backBtn} />
                                </Link>{brandGeneralDetails['brandName'] && brandGeneralDetails['brandName']}
                            </a>
                        </div> */}

                        {/* General start */}

                        <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-20 pl">
                            <div className="row">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 pl pr">
                                    <div className="row">
                                        {/* <div className="col-xl-5 col-lg-5 col-md-12 col-xs-12">
                                            <div className="profile-general">
                                                <h2 className="htx3 clearfix">General</h2>
                                                <p className="ptx clearfix">Your company logo, name, URL.</p>
                                                {!canEditGeneralDetails && <a href="javascript:void(0)" className="editbtn" onClick={() => enableGeneralDetailsForEdit}><img src={editSvg} /> Edit</a>}
                                            </div>
                                        </div> */}
                                        {/* {
                                            canEditGeneralDetails 
                                            ?
                                            <div className="col-xl-7 col-lg-7 col-md-12 col-xs-12 brand-general ">
                                                <div className="row">
                                                    <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-20">
                                                        <div className="btn-general">
                                                            <a style={{cursor: "pointer"}} className="btn btn-outline" onClick={() => handleSave(false)}>Cancel</a>
                                                            <a style={{cursor: "pointer"}} className="btn btn-orange" onClick={() => handleSave(true)}>Save Changes</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div className="col-xl-7 col-lg-7 col-md-12 col-xs-12 brand-general ">
                                                <div className="row">
                                                    <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                                        <div className="profile-general-upload-img">
                                                            <div className="profile-general-logo">
                                                                <div className="profile-general-logo-img">
                                                                    <img src={(brandGeneralDetails['logo'] && brandGeneralDetails['logo'][0]) ? brandGeneralDetails['logo'][0].image : blankImg} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                                        <div className="inventory-overHead"><p>Company Name</p><h3>{brandGeneralDetails['brandName'] ? brandGeneralDetails['brandName'] : 'NA'}</h3></div>
                                                    </div>
                                                    <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12"><div className="clearfix mt-10"></div></div>
                                                    <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                                        <div className="inventory-overHead"><p>Company URL</p><h3>{brandGeneralDetails['website'] ? brandGeneralDetails['website'] : 'NA'}</h3></div>
                                                    </div>


                                                </div>
                                            </div>

                                        } */}
                                        {
                                            !loading && brandGeneralDetails && Object.keys(brandGeneralDetails).length > 0 
                                            ? 
                                            <EditGeneral
                                                generalDetail={brandGeneralDetails}
                                                setGeneralDetail={setBrandGeneralDetails}
                                                setOverlay={setOverlay}
                                                refetch={refetch}
                                                setRefetch={setRefetch}
                                                isBackendUser={true}
                                                backendCompanyId={companyId}
                                                backendUserRole={context.user.role}
                                            />
                                            :
                                            <div className="content-loader-block"></div>

                                        }
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* General end */}

                        {/* <div className="className">
                        <h3 className="htx4 clearfix">
                            {(context.user.role === "backendAdmin" || context.user.role === "backendOperations" || context.user.role === "backendTech" || context.user.role === "backendFOS") && <><a className="" style={{ cursor: "pointer" }} onClick={openDeleteModal}><i className="fa fa-trash" style={{cursor: "pointer", marginLeft: "10px"}}></i> Delete</a></>}
                        </h3>
                        </div> */}
                        {/* line */}
                        <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-40 mb-50">
                            <div className="line-full"></div>
                        </div>
                        {/* Documents start */}
                        <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                            <div className="row">
                                <div className="col-xl-4 col-lg-4 col-md-12 col-xs-12">
                                    <div className="profile-general">
                                        <h2 className="htx3 clearfix">Documents</h2>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12 brand-general">
                                    <div className="row">

                                        <>
                                            <div className="col-xl-6 col-lg-6 col-md-6 col-xs-12 pl">
                                                <div className="documentDetails">
                                                    <div className="upload-display mt-10 mb-20">
                                                        <div className="upload-displayL">
                                                            <span><img src={brandDocumentDetails['gst'] && brandDocumentDetails['gst'].file && brandDocumentDetails['gst'].file.length !== 0 ? brandDocumentDetails['gst'].file[0].key.endsWith(".pdf") ? blankImg : brandDocumentDetails['gst'].file[0].image : blankImg} /></span>
                                                            {/* <a style={{ cursor: "pointer" }}
                                                                onClick={() => openImageModal("gst")}
                                                            >View</a> */}
                                                            {
                                                                brandDocumentDetails['gst'] && brandDocumentDetails['gst'].file && brandDocumentDetails['gst'].file.length !== 0
                                                                    ?
                                                                    brandDocumentDetails['gst'] && brandDocumentDetails['gst'].file && brandDocumentDetails['gst'] && brandDocumentDetails['gst'].file[0].key.endsWith(".pdf")
                                                                        ?
                                                                        <a href="javascript:void(0);" onClick={() => openPDF(brandDocumentDetails['pan'].file[0].image)} style={{ cursor: "pointer" }}>View</a>
                                                                        :
                                                                        <a style={{ cursor: "pointer" }} onClick={() => openImageModal("gst")}>View</a>
                                                                    :
                                                                    ""
                                                            }
                                                        </div>
                                                        <ImageModal
                                                            togglePopup={toggleGstImage}
                                                            displayImage={brandDocumentDetails['gst'] && brandDocumentDetails['gst'].file && brandDocumentDetails['gst'].file.length !== 0 ? brandDocumentDetails['gst'].file[0].image : blankImg}
                                                            onModalClose={() => confirmClose("gst")}
                                                        />
                                                        <div className="upload-displayR">
                                                            {
                                                                brandDocumentDetails['gst'] && brandDocumentDetails['gst']?.status
                                                                    ?
                                                                    <span className={`uploaded-doc ${brandDocumentDetails['gst'] && brandDocumentDetails['gst']?.status.toLowerCase() === "required" ? "yellowbg" : brandDocumentDetails['gst']?.status.toLowerCase() === "pending" ? "orangebg" : brandDocumentDetails['gst']?.status.toLowerCase() === "rejected" ? "redbg" : brandDocumentDetails['gst']?.status.toLowerCase() === "accepted" ? "greenbg" : ""}`} >
                                                                        {brandDocumentDetails['gst']?.status && brandDocumentDetails['gst']?.status.toLowerCase() === "required" ? "Document Required" : brandDocumentDetails['gst']?.status.toLowerCase() === "pending" ? "Verification Pending" : brandDocumentDetails['gst']?.status.toLowerCase() === "rejected" ? "Rejected" : brandDocumentDetails['gst']?.status.toLowerCase() === "accepted" ? "Verified" : "Verified"}
                                                                    </span>
                                                                    :
                                                                    ""
                                                            }
                                                            <div className="upload-text">
                                                                <h4>Document Name</h4>
                                                                <p>GST</p>
                                                            </div>
                                                            <div className="upload-text">
                                                                <h4>Document Number</h4>
                                                                <p className="fixText">{(brandDocumentDetails['gst'] && brandDocumentDetails['gst'].number.length > 0) ? brandDocumentDetails['gst'].number : 'NA'}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-20 pl pr">
                                                        <div className="btn-general">
                                                            {context.user.role !== "backendTech" && context.user.role !== "backendSales" && !(brandDocumentDetails['gst']?.status === 'required') &&
                                                                <a style={{ cursor: "pointer" }} className={`btn btn-outline ${brandDocumentDetails['gst']?.status === 'pending' ? '' : 'btn-disabled'}`} onClick={() => { setDocType('gst'); setOpenRejectModal(true); setOverlay(true); }}>{brandDocumentDetails['gst']?.status === 'rejected' ? 'Rejected' : 'Reject'}</a>
                                                            }{context.user.role !== "backendTech" && context.user.role !== "backendSales" && !(brandDocumentDetails['gst']?.status === 'required') &&
                                                                <a style={{ cursor: "pointer" }} className={`btn btn-orange ${brandDocumentDetails['gst']?.status === 'pending' ? '' : 'btn-disabled'}`} onClick={() => { setDocType('gst'); setOpenApprovalModal(true); setOverlay(true); }}>{brandDocumentDetails['gst']?.status === 'accepted' ? 'Approved' : 'Approve'}</a>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6 col-md-6 col-xs-12 pr">
                                                <div className="documentDetails1">
                                                    <div className="upload-display mt-10 mb-20">
                                                        <div className="upload-displayL">
                                                            <span><img src={brandDocumentDetails['pan'] && brandDocumentDetails['pan'].file && brandDocumentDetails['pan'].file.length !== 0 ? brandDocumentDetails['pan'].file[0].key.endsWith(".pdf") ? blankImg : brandDocumentDetails['pan'].file[0].image : blankImg} /></span>
                                                            {/* <a style={{ cursor: "pointer" }}
                                                                onClick={() => openImageModal("pan")}
                                                            >View</a> */}
                                                            {
                                                                brandDocumentDetails['pan'] && brandDocumentDetails['pan'].file && brandDocumentDetails['pan'].file.length !== 0
                                                                    ?
                                                                    brandDocumentDetails['pan'] && brandDocumentDetails['pan'].file && brandDocumentDetails['pan'] && brandDocumentDetails['pan'].file[0].key.endsWith(".pdf")
                                                                        ?
                                                                        <a href="javascript:void(0);" onClick={() => openPDF(brandDocumentDetails['pan'].file[0].image)} style={{ cursor: "pointer" }}>View</a>
                                                                        :
                                                                        <a style={{ cursor: "pointer" }} onClick={() => openImageModal("pan")}>View</a>
                                                                    :
                                                                    ""
                                                            }
                                                        </div>
                                                        <ImageModal
                                                            togglePopup={togglePanImage}
                                                            displayImage={brandDocumentDetails['pan'] && brandDocumentDetails['pan'].file && brandDocumentDetails['pan'].file.length !== 0 ? brandDocumentDetails['pan'].file[0].image : blankImg}
                                                            onModalClose={() => confirmClose("pan")}
                                                        />
                                                        <div className="upload-displayR">
                                                            {
                                                                brandDocumentDetails['pan'] && brandDocumentDetails['pan']?.status
                                                                    ?
                                                                    <span className={`uploaded-doc ${brandDocumentDetails['pan'] && brandDocumentDetails['pan']?.status.toLowerCase() === "required" ? "yellowbg" : brandDocumentDetails['pan']?.status.toLowerCase() === "pending" ? "orangebg" : brandDocumentDetails['pan']?.status.toLowerCase() === "rejected" ? "redbg" : brandDocumentDetails['pan']?.status.toLowerCase() === "accepted" ? "greenbg" : ""}`} >
                                                                        {brandDocumentDetails['pan']?.status && brandDocumentDetails['pan']?.status.toLowerCase() === "required" ? "Document Required" : brandDocumentDetails['pan']?.status.toLowerCase() === "pending" ? "Verification Pending" : brandDocumentDetails['pan']?.status.toLowerCase() === "rejected" ? "Rejected" : brandDocumentDetails['pan']?.status.toLowerCase() === "accepted" ? "Verified" : "Verified"}
                                                                    </span>
                                                                    :
                                                                    ""
                                                            }
                                                            <div className="upload-text">
                                                                <h4>Document Name</h4>
                                                                <p>PAN Card</p>
                                                            </div>
                                                            <div className="upload-text">
                                                                <h4>Document Number</h4>
                                                                <p className="fixText">{(brandDocumentDetails['pan'] && brandDocumentDetails['pan'].number.length > 0) ? brandDocumentDetails['pan'].number : 'NA'}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-20 pl pr">
                                                        <div className="btn-general">
                                                            {context.user.role !== "backendTech" && context.user.role !== "backendSales" && !(brandDocumentDetails['pan']?.status === 'required') &&
                                                                <a style={{ cursor: "pointer" }} className={`btn btn-outline ${brandDocumentDetails['pan']?.status === 'pending' ? '' : 'btn-disabled'}`} onClick={() => { setDocType('pan'); setOpenRejectModal(true); setOverlay(true); }}>{brandDocumentDetails['pan']?.status === 'rejected' ? 'Rejected' : 'Reject'}</a>
                                                            }{context.user.role !== "backendTech" && context.user.role !== "backendSales" && !(brandDocumentDetails['pan']?.status === 'required') &&
                                                                <a style={{ cursor: "pointer" }} className={`btn btn-orange ${brandDocumentDetails['pan']?.status === 'pending' ? '' : 'btn-disabled'}`} onClick={() => { setDocType('pan'); setOpenApprovalModal(true); setOverlay(true); }}>{brandDocumentDetails['pan']?.status === 'accepted' ? 'Approved' : 'Approve'}</a>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>


                                    </div>
                                </div>
                            </div>

                        </div>
                        {/* Documents end */}

                        {/* line */}
                        <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-40 mb-50">
                            <div className="line-full"></div>
                        </div>

                        {/* Contact start */}
                        {/* <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                            <div className="row">
                                <div className="col-xl-5 col-lg-5 col-md-12 col-xs-12">
                                    <div className="profile-general">
                                        <h2 className="htx3 clearfix">Contact</h2>
                                    </div>
                                </div>
                                <div className="col-xl-7 col-lg-7 col-md-12 col-xs-12 brand-general">
                                    <div className="row">
                                        <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                            <div className="inventory-overHead mt-0"><p>Location</p><h3>{brandContactDetails['address'] + `, ` + brandContactDetails['state'] + ` ` + brandContactDetails['zip'] + `, ` + brandContactDetails['country']}</h3>
                                            </div>
                                        </div>
                                        <div className="clearfix mt-30"></div>
                                        <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                            <div className="inventory-overHead"><p>Phone Number</p><h3>{brandContactDetails['contactNumber'] ? brandContactDetails['contactNumber'] : 'NA'}</h3></div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div> */}
                        {
                            !loading && brandContactDetails && Object.keys(brandContactDetails).length > 0 ?
                            <EditContact
                                contactDetail={brandContactDetails}
                                setContactDetail={setBrandContactDetails}
                                setOverlay={setOverlay}
                                refetch={refetch}
                                setRefetch={setRefetch}
                                isBackendUser={true}
                                backendCompanyId={companyId}
                                backendUserRole={context.user.role}
                            />
                            :
                            <div className="content-loader-block"></div>
                        }
                        {/* Contact end */}

                        {/* line */}
                        <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-40 mb-50">
                            <div className="line-full"></div>
                        </div>

                        {/* Description start */}
                        {/* <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                            <div className="row">
                                <div className="col-xl-5 col-lg-5 col-md-12 col-xs-12">
                                    <div className="profile-general">
                                        <h2 className="htx3 clearfix">Description </h2>
                                    </div>
                                </div>
                                <div className="col-xl-7 col-lg-7 col-md-12 col-xs-12 brand-general">

                                    <div className="inventory-overHead mt-0"><p>Description</p>

                                        <h3>{brandDescriptionDetails ? brandDescriptionDetails : 'NA'}</h3>
                                    </div>


                                    <div className="clearfix mt-30"></div>
                                </div>
                            </div>

                        </div> */}
                        {!loading && brandDescriptionDetails && Object.keys(brandDescriptionDetails).length > 0 ?
                                <EditDiscription
                                    description={brandDescriptionDetails}
                                    setDescription={setBrandDescriptionDetails}
                                    setOverlay={setOverlay}
                                    refetch={refetch}
                                    setRefetch={setRefetch}
                                    isBackendUser={true}
                                    backendCompanyId={companyId}
                                    backendUserRole={context.user.role}
                                />
                                :
                                <div className="content-loader-block"></div>
                            }
                        {/* Description end */}
                        {/* line */}
                        <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-40 mb-30">
                            <div className="line-full"></div>
                        </div>
                        {/* Sub-category start */}
                        {editingSubCategoryData && 
                            <>
                                {
                                    !loading && brandSubCategory && brandSubCategory.length > 0 ?
                                    <EditSubCategory
                                        subCategoryList={brandSubCategory}
                                        setSubCategoryList={setBrandSubCategory}
                                        setOverlay={setOverlay}
                                        refetch={refetch}
                                        setRefetch={setRefetch}
                                        isBackendUser={true}
                                        backendCompanyId={companyId}
                                        backendUserRole={context.user.role}
                                        brandCategoryId={brandCategoryId}
                                        editingSubCategoryData={editingSubCategoryData}
                                        setEditingSubCategoryData={handleEditingSubCategoryData}
                                    />
                                    :
                                    <div className="content-loader-block"></div>
                                }
                            </>
                        }
                        {
                            !editingSubCategoryData
                            &&
                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                <div className="row">
                                    <div className="col-xl-4 col-lg-4 col-md-12 col-xs-12">
                                        <div className="profile-general">
                                            <h2 className="htx3 clearfix">Sub Category {!editingSubCategoryData && (context.user.role === "backendAdmin" || context.user.role === "backendSales" || context.user.role === "backendTech") && <a href="javascript:void(0)" className="editbtn" onClick={() => setEditingSubCategoryData(true)}><img src={editSvg} /> Edit</a>}</h2>
                                            {/* <p className="ptx clearfix">Write a few senetces about the brand.</p> */}
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12 brand-general">

                                        {brandSubCategory && brandSubCategory.length > 0 && brandSubCategory.map((item, key) => {
                                            return (
                                                showMoreSubCategory ?
                                                    <React.Fragment>
                                                        <div className="inventory-overHead mt-0" key={key}>
                                                            <h3>Brand Name- {item.brandName ? item.brandName.join(', ') : 'NA'}</h3>

                                                            <h3>Category- {item.name}</h3>
                                                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-20 mb-30"><div className="line-full"></div></div>
                                                        </div>
                                                    </React.Fragment>
                                                    :
                                                    key <= 1 &&
                                                    <React.Fragment>
                                                        <div className="inventory-overHead mt-0 borderlastchild" key={key}>
                                                            <h3>Brand Name- {item.brandName ? item.brandName.join(', ') : 'NA'}</h3>

                                                            <h3>Category- {item.name}</h3>
                                                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-20 mb-30 pr pl"><div className="line-full"></div></div>
                                                        </div>
                                                    </React.Fragment>
                                            )
                                        })
                                        }
                                        {brandSubCategory.length > 2 && <a style={{ cursor: "pointer" }} className="viewwLink" onClick={() => setShowMoreSubCategory(!showMoreSubCategory)}>{showMoreSubCategory ? "Show less" : "Show more"}</a>}
                                        <div className="clearfix mt-30"></div>
                                    </div>
                                </div>

                            </div>
                        }
                        
                        {/* Sub-category end */}

                        {/* line */}
                        <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-40 mb-50">
                            <div className="line-full"></div>
                        </div>
                        {editingAllBrandsData && 
                            <>
                                {
                                    !loading && brandSubCategory && brandSubCategory.length > 0 ?
                                    <EditAllBrands
                                        subCategoryList={allBrandList}
                                        setSubCategoryList={setAllBrandList}
                                        setOverlay={setOverlay}
                                        refetch={refetch}
                                        setRefetch={setRefetch}
                                        isBackendUser={true}
                                        backendCompanyId={companyId}
                                        backendUserRole={context.user.role}
                                        brandCategoryId={brandCategoryId}
                                        editingAllBrandsData={editingAllBrandsData}
                                        setEditingAllBrandsData={handleEditingAllBrandsData}
                                    />
                                    :
                                    <div className="content-loader-block"></div>
                                }
                            </>
                        }
                        {
                            !editingAllBrandsData
                            &&
                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                <div className="row">
                                    <div className="col-xl-4 col-lg-4 col-md-12 col-xs-12">
                                        <div className="profile-general">
                                            <h2 className="htx3 clearfix">All Brands {!editingAllBrandsData && (context.user.role === "backendAdmin" || context.user.role === "backendSales" || context.user.role === "backendTech") && <a href="javascript:void(0)" className="editbtn" onClick={() => setEditingAllBrandsData(true)}><img src={editSvg} /> Edit</a>}</h2>
                                            {/* <p className="ptx clearfix">Write a few senetces about the brand.</p> */}
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12 brand-general">

                                        {allBrandList && allBrandList.length > 0 && allBrandList.map((item, key) => {
                                            return (
                                                showMoreBrand ?
                                                    <React.Fragment>
                                                        <div className="inventory-overHead mt-0" key={key}>
                                                            <h3>Brand Name- {item.brandName ? item.brandName : 'NA'}</h3>

                                                            <h3>Category- {item.name}</h3>
                                                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-20 mb-30"><div className="line-full"></div></div>
                                                        </div>
                                                    </React.Fragment>
                                                    :
                                                    key <= 1 &&
                                                    <React.Fragment>
                                                        <div className="inventory-overHead mt-0 borderlastchild" key={key}>
                                                            <h3>Brand Name- {item.brandName ? item.brandName : 'NA'}</h3>

                                                            <h3>Category- {item.name}</h3>
                                                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-20 mb-30 pr pl"><div className="line-full"></div></div>
                                                        </div>
                                                    </React.Fragment>
                                            )
                                        })
                                        }
                                        {allBrandList.length > 2 && <a style={{ cursor: "pointer" }} className="viewwLink" onClick={() => setShowMoreBrand(!showMoreBrand)}>{showMoreBrand ? "Show less" : "Show more"}</a>}
                                        <div className="clearfix mt-30"></div>
                                    </div>
                                </div>

                            </div>
                        }
                        {/* line */}
                    <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-40 mb-50">
                            <div className="line-full"></div>
                        </div>

                    { !loading &&
                    <WisrRepresentative 
                    representativeList={wisrRepresentative}
                    representativeDetails={brandContactDetails}
                    isBackendUser={true}
                    backendCompanyId={companyId}
                    backendUserRole={context.user.role}
                    refetch={refetch}
                    setRefetch={setRefetch}
                    setOverlay={setOverlay}
                    />
                    }

               
                    <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-40 mb-30">
                        <div className="line-full"></div>
                    </div>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                            <div className="row">
                                <div className="col-xl-4 col-lg-4 col-md-12 col-xs-12">
                                    <div className="profile-general">
                                        <h2 className="htx3 clearfix">Activity </h2>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6 col-xs-12 brand-general">

                                    <div className="inventory-overHead mt-0 activityBrand">
                                        <h3>Campaign Created <Link to={{
                                            pathname: "/backend/backend-details/create-trial-campaign",
                                            state: { brandId: history.location.state['brandId'], type: "create" }
                                        }} className="viewwLink" >{brandActivityDetails.lastCampaignAgo !== "NA" && <a href="javascript:void(0);" className="viewwLink">View <img className="arrowView" src={ArrowView}></img></a>}</Link> </h3>
                                        <p>{brandActivityDetails.lastCampaignAgo}</p>
                                    </div>
                                    <div className="inventory-overHead mt-0 activityBrand border-zero">
                                        <h3>Trial Campaign Created <Link to={{
                                            pathname: "/backend/backend-details/create-trial-campaign",
                                            state: { brandId: history.location.state['brandId'], type: "trial" }
                                        }}>{brandActivityDetails.lastTrialCampaignAgo !== "NA" && <a href="javascript:void(0);" className="viewwLink">View <img className="arrowView" src={ArrowView}></img></a>}</Link></h3>
                                        <p>{brandActivityDetails.lastTrialCampaignAgo}</p>
                                    </div>
                                    {/* <div className="inventory-overHead mt-0 activityBrand">
                                        <h3>Documents Added <a href="javascript:void(0);" className="viewwLink">View <img className="arrowView" src="/static/media/arrow-view.17cce853.svg"></img></a></h3>
                                        <p>1 day ago</p>
                                    </div> */}


                                    <div className="clearfix mt-30"></div>
                                </div>
                                {/* <div className="col-xl-2 col-lg-2 col-md-5 col-xs-12">
                                    <div className="selectbrand">
                                        <select>
                                            <option>Today</option>
                                        </select>
                                    </div>
                                </div> */}
                            </div>

                        </div>

                    </div>
                </div>
            </div>
            <BrandDocsApprovalModal
                showConfirmedApprovalModal={openApprovalModal}
                closeModal={() => setOpenApprovalModal(false)}
                docType={docType}
                setOverlay={setOverlay}
                brandDocumentDetails={brandDocumentDetails}
                brandId={history.location.state['brandId']}
                setUpdateBrandDetails={setUpdateBrandDetails}
                updateBrandDetails={updateBrandDetails}
            />
            <BrandDocsRejectModal
                openRejectModal={openRejectModal}
                closeModal={() => setOpenRejectModal(false)}
                docType={docType}
                setOverlay={setOverlay}
                brandDocumentDetails={brandDocumentDetails}
                brandId={history.location.state['brandId']}
                setUpdateBrandDetails={setUpdateBrandDetails}
                updateBrandDetails={updateBrandDetails}
            // onRejectRequest
            />

            <ConfirmDialog
            confirmTitle="Do you want to delete this brand ?"
            confirmText="Yes"
            denyText="No"
            onModalClose={deleteBrand}
            togglePopup={showBrandDeleteModal}
        />
        </>
    )
}
export default DetailsBrand;