import { useState, useEffect, useContext } from "react"

import AppContextObject from "../../common/context/common"
import { PublicContextInitiator } from "../../publicSrc/apis/publicContextInitiator"
import AlertDialog from "../../components/addon/alertDialog/alertDialog"

import close from "../../assets/images/close.svg"
import { isCallSignatureDeclaration } from "typescript"
import errorSVG from "../../assets/images/error.svg"

const OTPVerifyModal = (props) => {

    const {
        contact,
        isMobile,
        showModal,
        setShowModal,
        onModalSubmit,
        isMobileAndEmail,
        email,
        onClose,
        pageType

    } = props

    const context = useContext(AppContextObject)

    const [countdownInterval, setCountdownInterval] = useState(null)
    const [displayTime, setDisplaytime] = useState("00:60") // 60 seconds
    const [countdownTime, setCountdownTime] = useState(59) // 60 seconds
    const [showResend, setShowResend] = useState(false)
    let [overlay, setOverlay] = useState(false)
    let [toggleAlertPopUp, setToggleAlertPopUp] = useState(false);
    let [detailsCaptured, setDetailsCaptured] = useState('');
    let [getFieldInfo, setGetFieldInfo] = useState('');
    let [msgDetails, setMsgDetails] = useState('');
    let [otpError, setOtpError] = useState(false);
    let [resetTimer, setResetTimer] = useState(false);

    useEffect(() => {
        const display = document.querySelector('#time')
        if (showModal === true) {
            const time = countdownTime
            initiateTimer(time, display)
        } else {
            display.textContent = displayTime
            clearInterval(countdownInterval)
        }
    }, [showModal]) // resetTimer

    let [formData, setFormData] = useState({
        otp: ""
    })

    useEffect(() => {
        document.getElementById("otp").focus()
    },[showModal])
    const initiateTimer = (duration: any, display) => {
        setResetTimer(false);
        let timer = duration
        timer = timer.toString()
        let minutes = 0
        let seconds = 0
        const interval = setInterval(function () {
            const m = timer / 60
            const s = timer % 60
            minutes = parseInt(m.toString(), 10)
            seconds = parseInt(s.toString(), 10)

            const displayMinutes = minutes < 10 ? "0" + minutes : minutes
            const displaySeconds = seconds < 10 ? "0" + seconds : seconds

            display.textContent = displayMinutes + ":" + displaySeconds
            if (displayMinutes === "00" && displaySeconds === "00") {
                clearInterval(interval)
                setCountdownInterval(null)
                setShowResend(true)
            }
            if (--timer < 0) {
                timer = duration
            }
        }, 1000)
        setCountdownInterval(interval)
    }

    const onFormDataChange = (event) => {
        const re = /^[0-9\b]+$/
        if (event.target.value === '' || re.test(event.target.value)) {
            setFormData({ otp: event.target.value })
        }
    }

    const closeModal = () => {
        clearData()
        onClose && onClose()
    }

    const onSubmit = async () => {
        if (formData.otp == "") {
            return
        }

        let publicContextInitiator = new PublicContextInitiator(context)
        let resp;
        let resp1;

        if (isMobileAndEmail) {
            resp = await context.apis[context.user.role].oldPasswordEmailPhoneOTPVerification(
                context.validator, { "otp": formData.otp, "phone": contact, "email": email }
            )
            if (!resp.done) {
                // show alert dialog
                setDetailsCaptured('')
                if (resp.msg == "OTP timer expired") {
                    setToggleAlertPopUp(true)
                    setMsgDetails('The OTP has expired. Click on RESEND to generate OTP again.')
                }
                else if (resp.msg == "incorrect OTP") {
                    setOtpError(true)
                }
            }
            else {
                onModalSubmit(formData.otp)
                clearData()
                onClose && onClose()
            }
        }
        else {
            if (isMobile) {
                resp = await publicContextInitiator.onboardingSchoolPhoneOTPVerification(
                    context.validator, { "otp": formData.otp, "phone": contact }
                )
            }
            else {
                resp = await publicContextInitiator.onboardingSchoolEmailOTPVerification(
                    context.validator, { "otp": formData.otp, "email": contact }
                )
            }
            if (!resp.done) {
                // show alert dialog

                setDetailsCaptured('')

                if (resp.msg == "OTP timer expired") {
                    setToggleAlertPopUp(true)
                    setMsgDetails('The OTP has expired. Click on RESEND to generate OTP again.')
                }
                else if (resp.msg == "incorrect OTP") {
                    setOtpError(true)
                }
            }
            else {
                onModalSubmit(formData.otp)
                clearData()
                onClose && onClose()
            }

        }


    }

    const clearData = () => {
        clearInterval(countdownInterval)
        setFormData({ otp: "" })
        setShowModal(false)
        setShowResend(false)
        setOtpError(false)
    }

    const resendOTP = async () => {
        if (showResend === true) {
            let publicContextInitiator = new PublicContextInitiator(context)

            if (isMobileAndEmail) {
                await context.apis[context.user.role].oldPasswordSendOtpEmailPhone(
                    context.validator, { "email": email, "phone": contact }
                )
            }
            else {
                if (isMobile) {
                    await publicContextInitiator.reSendOTPOnPhone(
                        context.validator, { "phone": contact }
                    )
                }
                else {
                    await publicContextInitiator.reSendOTPOnEmail(
                        context.validator, { "email": contact }
                    )
                }
            }
            // disable resend button once OTP is resent and restart the timer
            setShowResend(false)
            clearInterval(countdownInterval)
            setCountdownInterval(null)
            // setResetTimer(true)
            const display = document.querySelector('#time')
            const time = countdownTime
            initiateTimer(time, display)
            //initiateTimer(60, document.querySelector('#time'))
        }
    }

    const confirmClose = () => {
        setOverlay(false)
        setToggleAlertPopUp(false)
        setShowModal(true)
    }
console.log(window.location)
    const handleOnKeyPress = (event) => {
        if (event.key.toLowerCase() === "enter") {
            document.getElementById("submitBtn").focus()
            onSubmit()
        }
    }
    return (
        <>
            <div className={`ovelap ${overlay === true ? "active" : ""}`}></div>
            <AlertDialog
                togglePopup={toggleAlertPopUp}
                fieldInfo={getFieldInfo}
                details={detailsCaptured}
                onModalClose={confirmClose}
                displayMsg={msgDetails} />

            <div className={`
                ${isMobile === true ? "mobileVery" : "email-veri"} email-pop
                ${(showModal === true && toggleAlertPopUp === false ) ? "active" : ""}
            `}>
                <a href="javascript:void(0);" onClick={closeModal} className="close-pop">
                    <img src={close} alt="close" />
                </a>
                <h2 className="htx2">
                    {
                        isMobileAndEmail ? "Old Password Verification" :
                        isMobile === true ?
                            "Verify Your Mobile No"
                            : "Verify Your Email ID"
                    }
                </h2>
                <p className="ptx2"><b className="textColor">Enter the verification code received on</b> {" "}
                    {isMobileAndEmail === true ? email + " and " + contact : (isMobile === true ? <strong>+91-{contact}</strong> : contact)}
                </p>
                <div className="mobile-verifi">
                    <input
                        id="otp"
                        name="otp"
                        type="text"
                        value={formData.otp}
                        onChange={onFormDataChange}
                        placeholder="Enter verification code"
                        pattern="[1-9]{1}[0-9]{9}"
                        maxLength={4}
                        onKeyPress={handleOnKeyPress}
                    />
                    <span id="time">{displayTime}</span>
                    <span className={`valError ${otpError === true ? "displayBlock" : "displayNone"}`}><img src={errorSVG} />Wrong code entered. Please try again.</span>
                </div>
                <label>Not Received the code yet?{" "}
                    <a
                        href="javascript:void(0)" data-rel="resendOTP"
                        className={showResend === false ? "disabled" : ""}
                        onClick={resendOTP}
                    >Resend</a></label>
                <input id="submitBtn" onClick={onSubmit} disabled={formData.otp === ""} name="Confirm" value={pageType==='myprofilePage' ? "Save" : "Confirm"} className={formData.otp !== "" ? "btn btn-orange remove" : "btn btn-orange remove btn-disabled"} type="button" />
            </div>
        </>
    )
}

export default OTPVerifyModal
