import {
    useEffect,
    useState,
    useContext,
    createRef
} from "react"
import { useHistory } from "react-router-dom"

import DataContext from "../../../../../service/contextProvider"

import ActivityInfo from "../../components/activityDetails/activityInfo"
import ConfirmAlert from "../../../../../components/addon/confirmAlert"
import Loader from "../../../../../components/addon/loader"

import config from "../../../../../service/config"

import { objectDeepClone, compareObjs, formatInventoriesForEvents } from "../../../../../service/helper"

import { nextTick } from "process"
import { Steps} from "intro.js-react"
import AppContextObject from "../../../../../common/context/common"
import ConfirmDialog from "../../../../../components/addon/confirmDialog/confirmDialog"
import menu from "../../../../../assets/images/menu.svg"
import errorSVG from "../../../../../assets/images/error.svg"

interface Props {
  isEdit?: boolean
  onProceed?: Function
  setShowMenu?: Function
  redirectionURL?: string,
  hideRevenue?: boolean,
  byPassInventoryFlow?: any
  setByPassInventoryFlow?: any,
  showQuitButton?: any,
  toggleSidemenu ? : Function,
  showSidemenu ? : boolean,
  fromBackend?: boolean,
  schoolId?: any
}

const ActivityDetails = ({ isEdit, onProceed, setShowMenu, redirectionURL, hideRevenue, byPassInventoryFlow, setByPassInventoryFlow, showQuitButton, toggleSidemenu, showSidemenu, fromBackend, schoolId }: Props) => {
console.log("hideRevenue---",hideRevenue);
  const parentData = useContext<any>(DataContext)
  const context = useContext(AppContextObject)
  const history = useHistory()
  const [showPrompt, setShowPrompt] = useState(false)
  const [oldFormData] = useState(parentData?.data?.student?.activities)
  const [overlay, setOverlay] = useState(false)
  const [data, setData] = useState([])
  const [dataLength, setDataLength] = useState(0)
  const [showData, setShowData] = useState(false)
  const [activityInfoRefs, setActivityInfoRefs] = useState([])
  const [errorMessage, setErrorMessage] = useState("")
  const [sortActivities, setSortActivities ] = useState([])

  const [loader, setLoader] = useState(false)
  const [masterList, setMasterList] = useState([])
  const [isNavigateFromTable, setIsNavigateFromTable] = useState(false)
  const [isEventSelected, setIsEventSelected] = useState(false)
  const [showQuitModal, setShowQuitModal] = useState(false)
  const [onBoarding, setOnboarding] = useState({
    stepsEnabled: false,
    initialStep: 0,
    steps: [
        {element: "#activiDetail0", intro: "Click here to add more details about all the inventories brand can be use during this event.", position: "right"}
    ],
    options: {
      exitOnOverlayClick: false,
      showStepNumbers: true,
      hidePrev: true,
      hideNext: false,
      nextLabel: "Next",
      prevLabel: "Prev",
      doneLabel: "Got it",
      autoPosition: false,
      exitOnEsc: false,
      scrollToElement: false,
      disableInteraction: true
    },
})
const [recheck, setRecheck] = useState(true)
  useEffect(() => {
    setTimeout(() => {
      setOnboarding({...onBoarding, stepsEnabled: parentData?.tooltipProgress?.eventsAndOccasionsDetail1})
    }, 100)
  }, [])

  useEffect(() => {
    if (setShowMenu) {
      setShowMenu(true)
    }
    setLoader(true)
    let list = []
    if (parentData.listOfEvents && parentData.listOfEvents.length > 0) {
        list = formatInventoriesForEvents(parentData.listOfEvents)
    } else if (parentData.listOfInventories && parentData.listOfInventories.length > 0) {
        list = formatInventoriesForEvents(parentData.listOfInventories)
    }    
    if(history.location.state){
      setIsNavigateFromTable(true)
      let data: any = history.location.state
      if(data.selectedEvent){
        list = data.selectedEvent
      }
    }
    setMasterList(list)
    setData(list)
    process.nextTick(() => {
        setLoader(false)
    })
    if (parentData.setActiveModule) {
        parentData.setActiveModule({
            module: "inventoryDetails",
            subModule: "events"
        })
    }
  }, [])
  useEffect(() => {
    if(history.location.state){
      let data:any = history.location.state
      if(data.selectedEvent){
        setByPassInventoryFlow(true)
      }
    }
  }, [])

  useEffect(() => {
    fillActivityDetails()
  }, [masterList])

  useEffect(() => {
    fillActivityDetails()
  }, [parentData?.data?.student?.activities])

  const fillActivityDetails = () =>{
    if (parentData?.data?.student?.activities && parentData?.data?.student?.activities.length > 0) {
      const newArr = []
      masterList.forEach(el => {
        const found = parentData?.data?.student?.activities.find((e) => e.name === el.name)
        if (found) {
          newArr.push({...found})
        }
      })
      const custom = parentData?.data?.student?.activities.filter((el) => el.custom === true)
      newArr.push(...custom)
      setData(newArr)
      nextTick(() => {
        setShowData(true)
      })
    } else {
      setShowData(true)
    }
  }

    useEffect(() => {
      setDataLength(data.length)
      onAmenitiesChange()
    }, [data])

    useEffect(() => {
      setActivityInfoRefs(activityInfoRefs => (
        Array(dataLength).fill(0).map((_, i) => activityInfoRefs[i] || createRef())
      ))
    }, [dataLength])
    useEffect(() => {
      if(data && data.length > 0){
        let eventSelected = false
        data.forEach(el => {
          if(el.availableForBranding){
            eventSelected = true
          }
        })
        setIsEventSelected(eventSelected)
      }
    }, [data])

    const onAvailForBrandingChange = async (index, value) => {
        const arr = objectDeepClone(data)
        arr[index].availableForBranding = value
        setData(arr)

      // if(!hideRevenue){
      //   console.log("arr>>>",arr, parentData.data.algorithmData.events, value);
      //   let algoAllData = []
      //   let amdata = []
      //   if(value){
      //     for(let amenity of arr){
      //       for(let amenityAttr of amenity.amenities){
      //         console.log("amenityAttr>>>>",amenityAttr);
      //           let algoData = {
      //             name: amenity.name || "",
      //             placement: [],
      //             sizeOfPlacement: [],
      //             noOfPlacement: [],
      //           }
      //           algoData.placement.push(amenityAttr.name)
      //           console.log("amenityAttr-->",amenityAttr.data.length,amenityAttr.height, amenityAttr.breadth);
      //           let length = Number(amenityAttr.data.length) === 0 ? 1 : Number(amenityAttr.data.length)
      //           let breadth = Number(amenityAttr.data.breadth) === 0 ? 1 : Number(amenityAttr.data.breadth)
      //           let height = Number(amenityAttr.height) === 0 ? 1 : Number(amenityAttr.data.height)
      //           let size = (length || 1) * (breadth || 1)
      //           algoData.sizeOfPlacement.push(size)
      //           algoData.noOfPlacement.push(Number(amenityAttr.data.quantity))
      //           algoAllData.push(algoData)
      //       }
      //     }
      //     let allSelectedInventoryArr = [];
      //     let allAmenities = [...parentData.data.student.activities]
      //     console.log("allAmenities--->>",allAmenities, parentData.data.algorithmData.events);
      //     allSelectedInventoryArr = parentData.data.algorithmData.events.filter(function (o1) {
      //         return allAmenities.some(function (o2) {
      //             // return o2.amenities.find(data => {
      //                 return o2.name === o1.name;
      //             // })
      //         });
      //     });
      //     console.log("allSelectedInventoryArr",allSelectedInventoryArr);

      //     let allAlgoInventories = [ ...algoAllData, ...allSelectedInventoryArr]
      //     // let allAlgoInventories = [ ...algoAllData ]

      //     console.log("allAlgoInventories",allAlgoInventories);
          
      //     amdata = allAlgoInventories.filter((am, index, self) =>
      //         index === self.findIndex((t) => (
      //             t.parentInventory === am.parentInventory && t.uniqueName === am.uniqueName
      //         ))
      //     )
      //     console.log("algoData>>>--",algoAllData, amdata);
      //   }else{
      //     let selectedInventoriesForAlgo = parentData.data.algorithmData.events;
      //     let filterArr = selectedInventoriesForAlgo.filter(element=> arr[index].name !== element.name)
      //     amdata = filterArr
      //     console.log("arr[index]>",arr[index]);
      //     console.log("selectedInventoriesForAlgo>",selectedInventoriesForAlgo, filterArr);
      //   }

      //   console.log("amdata-->",amdata);
        
      //     if (parentData && parentData.setData) {
      //       parentData.setData({
      //           ...parentData.data,
      //           algorithmData: {
      //               ...parentData.data.algorithmData,
      //               events: amdata
      //           }
      //       })
      //     }
      //     let resp = await context.apis.public.getOnboardingRevenue(
      //       context.validator, {
      //           ...parentData.data.algorithmData,
      //           events: amdata
      //       }
      //   )
      //   if(resp && resp.done){
      //       if(parentData && parentData.setNetRevenue){
      //           parentData.setNetRevenue(resp.netRevenue || 0)
      //       }
      //   }

      //   console.log("algo resp->>>>", resp);
      // }
    }

    const onVenueListUpdate = (index, value) => {
        if(history.location.state){
          setIsNavigateFromTable(false)
        }
        const arr = objectDeepClone(data)
        arr[index].venue = value
        setData(arr)
    }

    const onOtherVenueListUpdate = (index, value) => {
        const arr = objectDeepClone(data)
        arr[index].otherVenueList = value
        setData(arr)      
    }
  
    const onMonthsListUpdate = (index, value) => {
        if(history.location.state){
          setIsNavigateFromTable(false)
        }
        const arr = objectDeepClone(data)
        arr[index].months = value
        setData(arr)
    }

    const onAmenitiesUpdate = (index, value) => {
        if(history.location.state){
          setIsNavigateFromTable(false)
        }
        const arr = objectDeepClone(data)
        arr[index].amenities = value
        setData(arr)        
    }

    const confirmAlertCallback = (data) => {
        setOverlay(data.overlay)
    }

    const onDateUpdate = (index, value) => {
        if(history.location.state){
          setIsNavigateFromTable(false)
        }
        const arr = objectDeepClone(data)
        arr[index].date = value
        setData(arr)
    }

    const onAmenitiesChange = () => {
        let newData = data ? objectDeepClone(data) : []
        newData.forEach((el, index) => {
            const d = activityInfoRefs[index]
            if (d && d.current) {
                el.amenities = el.availableForBranding === true ? d.current.fetchAllAmenities() : el.amenities
            }
        })        
        const obj1 = [...newData]
        const obj2 = [...oldFormData]
        const isSame = compareObjs(obj1, obj2)
        if(recheck && !isNavigateFromTable){
          if (isSame === false) {
            setShowPrompt(true)
          } else {
              setShowPrompt(false)
          }
        }
    }

    console.log("isNavigateFromTable>>>",isNavigateFromTable);

    const onSubmit = async () => {
      setRecheck(false)
        const validation = []
        data.forEach((el, index) => {
            const d = activityInfoRefs[index]
            validation.push(d.current.validateActivityInfo()) 
        })
        const results = await Promise.all(validation)
        if (results.filter(e => e === false).length === 0) {
            setShowPrompt(false)
            data.forEach((el, index) => {
                const d = activityInfoRefs[index]
                el.amenities = el.availableForBranding === true ? d.current.fetchAllAmenities() : el.amenities
            })
        if(!hideRevenue){
          let algoAllData = []
          for(let amenity of data){
            console.log("amenity>>>123231",amenity);
            if(!amenity.custom){
              for(let amenityAttr of amenity.amenities){
                console.log("amenityAttr-->11",amenityAttr);
                  let algoData = {
                    name: amenity.name || "",
                    placement: [],
                    sizeOfPlacement: [],
                    noOfPlacement: [],
                  }
                  algoData.placement.push(amenityAttr.name)
                  let length = Number(amenityAttr.data.length) === 0 ? 1 : Number(amenityAttr.data.length)
                  let breadth = Number(amenityAttr.data.breadth) === 0 ? 1 : Number(amenityAttr.data.breadth)
                  let height = Number(amenityAttr.data.height) === 0 ? 1 : Number(amenityAttr.data.height)
                  let size = length * breadth
                  algoData.sizeOfPlacement.push(size)
                  algoData.noOfPlacement.push(Number(amenityAttr.data.quantity))
                  algoAllData.push(algoData)
                  
              }
            }
              
          }
          console.log("algoAllData>>>>>",algoAllData);
          let allSelectedInventoryArr = [];
          let allAmenities = [...parentData.data.student.activities]
          console.log("allAmenities--->>",allAmenities, parentData.data.algorithmData.events);
          allSelectedInventoryArr = parentData.data.algorithmData.events.filter(function (o1) {
              return allAmenities.some(function (o2) {
                  // return o2.amenities.find(data => {
                      return o2.name === o1.name;
                  // })
              });
          });
          console.log("allSelectedInventoryArr",allSelectedInventoryArr);

          let allAlgoInventories = [ ...algoAllData, ...allSelectedInventoryArr]
          // let allAlgoInventories = [ ...algoAllData ]

          console.log("allAlgoInventories",allAlgoInventories);
          
          let amdata = allAlgoInventories.filter((am, index, self) =>
              index === self.findIndex((t) => (
                  t.name === am.name
              ))
          )
          console.log("algoData>>>--",algoAllData, amdata);
          

          if (parentData.setProgress) {
            parentData.setProgress({
              ...parentData.progress,
              eventsAndOccasions: true
            })
          }
          setErrorMessage("")
          if (parentData) {
            if (parentData.setData) {
              parentData.setData({
                ...parentData.data,
                student: {
                  life: parentData?.data?.student?.life,
                  activities: objectDeepClone(data),
                },
                algorithmData: {
                  ...parentData.data.algorithmData,
                  events: amdata
                }
              })
            }
          }   

          let resp = await context.apis.public.getOnboardingRevenue(
            context.validator, {
              ...parentData.data.algorithmData,
              events: amdata
            }
          )
          if(resp && resp.done){
              if(parentData && parentData.setNetRevenue){
                  parentData.setNetRevenue(resp.netRevenue || 0)
              }
              if(parentData && parentData.setPrevNetRevenue){
                parentData.setPrevNetRevenue(resp.netRevenue || 0)
            }
          }

          console.log("algo resp->>>>", resp);      
          process.nextTick(() => {
            if (redirectionURL) {
              onProceed(redirectionURL)
            } else {
              if (isEdit === true) {
                onProceed("/schools/school-details/details/update-details-summary")
              } else {
                onProceed("/schools/onboarding/onboarding-summary")
              }
            }
          })
        }else{


          if (parentData.setProgress) {
            parentData.setProgress({
              ...parentData.progress,
              eventsAndOccasions: true
            })
          }
          setErrorMessage("")
          if (parentData) {
            if (parentData.setData) {
              parentData.setData({
                ...parentData.data,
                student: {
                  life: parentData?.data?.student?.life,
                  activities: objectDeepClone(data),
                },
              })
            }
          }          
          process.nextTick(() => {
            if (redirectionURL) {
              onProceed(redirectionURL)
            } else {
              if (isEdit === true) {
                onProceed("/schools/school-details/details/update-details-summary")
              } else {
                onProceed("/schools/onboarding/onboarding-summary")
              }
            }
          })
        }
      } else {
          setErrorMessage("Please fill all fields.")
      }
    }

    useEffect(() => {
      if(isEdit === true) {
        let sortedArr = []
        let isFound = false;
        data.map(item=>{
          let result = parentData.data.student.activities.filter((e) =>{ 
            if(e.name === item.name) {
              isFound = true
            }else{
              isFound = false
            }
            return e.name === item.name
          })
          if(isFound){
            sortedArr.push(...result)
          }else{
            sortedArr.push(item)
          }
        })
        setSortActivities(sortedArr)
      }

    }, [dataLength])

  const hanldeOnboardingComplete = () => {
    parentData.setTooltipProgress({
        ...parentData.tooltipProgress,
        eventsAndOccasionsDetail1: false
    })
    let progress = {
        ...parentData.tooltipProgress,
        eventsAndOccasionsDetail1: false
    }
    localStorage.setItem("tooltipProgress", JSON.stringify(progress))
  }
  const exitOnBoarding = (e) => {
      console.log(e)
  }

  const toggleNavigateFromTable = () => {
    if(history.location.state){
      setIsNavigateFromTable(false)
    }
  }
  const openQuitModal = () => {
      setOverlay(true)
      setShowQuitModal(true)
  }
  const onQuitModalClose = (confirm) => {
      if(confirm){
        if(fromBackend){
          history.push({pathname: "/backend/backend-details/school-details", state: { schoolId: schoolId }})
        }
        else{
            history.push("/schools/school-details/inventory")
        }
      }
      setShowQuitModal(false)
      setOverlay(false)
  }

    return (
      <>
        <div className={`ovelap ${overlay === true ? "active" : ""}`}></div>
        <div className={isEdit === false ? "col-xl-6 col-lg-6 col-md-12 col-xs-12" : ""}>
            <div className={isEdit === false ? "content mCustomScrollbar fixhight" : ""}>
                <div className={isEdit === false ? "rightPart" : ""}>
                {showQuitButton && <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-20"><a style={{cursor: "pointer"}} className="fR blackC" onClick={openQuitModal}>Quit</a></div>}
                    {
                        isEdit === false && (
                          <>
                            <a onClick={() => toggleSidemenu()} href='javascript:void(0)' className={`hummob ${showSidemenu === true ? "no_highlight" : ""}`}>
                                <img src={menu} />
                            </a>
                            <h2 className='htx1'>Events and Occasions Details</h2>
                            <p className="ptx mb40">{isEventSelected ? "Select the inventory available for the shortlisted event" : "Select the activities that brands can book for their campaigns and activations"}</p>
                            </>
                        )
                    }
                    {
                        loader === true ?
                        <Loader />
                        :
                        <>
                            <div className="row">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                    {   showData === true &&
                                        data.map((el, index) => {
                                            return (
                                                <div id={`activiDetail${index}`} className="activi-detail" key={index}>
                                                    <ActivityInfo
                                                        ref={activityInfoRefs[index]}
                                                        data={el}
                                                        index={index}
                                                        onAvailForBrandingChange={onAvailForBrandingChange}
                                                        onOtherVenueListUpdate={onOtherVenueListUpdate}
                                                        onVenueListUpdate={onVenueListUpdate}
                                                        onMonthsListUpdate={onMonthsListUpdate}
                                                        onAmenitiesUpdate={onAmenitiesUpdate}
                                                        isEdit={isEdit}
                                                        hideRevenue={hideRevenue}
                                                        // allData={data}
                                                        sortActivities={sortActivities}
                                                        onDateUpdate={onDateUpdate}
                                                        onAmenitiesChange={onAmenitiesChange}
                                                        toggleNavigateFromTable={toggleNavigateFromTable}
                                                        isLastIndex={index===(data.length-1) ? true : false}
                                                    />
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-20 end-xs'>
                                <div className="bottom-error-btn">
                                    <p className="valError">
                                        {
                                            errorMessage &&
                                            <>
                                                <img src={errorSVG} />
                                                {errorMessage}
                                            </>
                                        }
                                    </p>
                                    <div className='forminpt'>
                                        <input
                                            onClick={onSubmit}
                                            name='Proceed'
                                            value='Proceed'
                                            className='btn btn-orange onboarding'
                                            type='button'
                                        />
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </div>
            </div>
        </div>
        {!(fromBackend === true) &&
          <ConfirmAlert
              when={showPrompt}
              title="Leave page without saving..."
              cancelText="Cancel"
              okText="Confirm"
              onOK={() => true}
              onCancel={() => false}
              parentCallback={confirmAlertCallback}
          />
        }
        <ConfirmDialog
              confirmTitle="Do you want to Quit"
              togglePopup={showQuitModal}
              onModalClose={onQuitModalClose}
              componentName={""}
          />
        <Steps
            enabled={onBoarding.stepsEnabled}
            steps={onBoarding.steps}
            initialStep={onBoarding.initialStep}
            onComplete={hanldeOnboardingComplete}
            onExit={exitOnBoarding}
            options={onBoarding.options}
        />
      </>
  )
}

export default ActivityDetails
