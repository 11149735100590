import { Route, Redirect } from "react-router-dom"

import authService from "../service/auth"
import { useContext } from "react"
import AppContextObject from "../common/context/common"

export const BackendProtectedRoute = (props) => {

    const {
        component: Component,
        auth,
        ...rest
    } = props

    const context = useContext(AppContextObject)

    // console.log("Component----->>>>", props);

    return (
        <Route
            {...rest}
            render={routeProps => {
                if (authService.checkAuth() && context && context.user && context.user.role && context.user.role.startsWith("backend")) {
                    console.log("backend protected welcoming");
                    return <Component {...routeProps} />
                } else {
                    console.log("backend protected returning");
                    return (
                        <Redirect
                            to={{
                                pathname: "/login",
                                state: {
                                    from: routeProps.location
                                }
                            }}
                        />
                    )
                }
            }}
        />
    )
}
