import { useState, useContext, useEffect, useRef } from "react";

import { useHistory, useParams } from "react-router-dom";
import {
	readAbleBoard,
	returnReadableStatus,
	titleCase,
} from "../../../../service/helper";
import BackendTopBar from "../components/backendTopBar";
import AppContextObject from "../../../../common/context/common";
import proEdit from "../../../../assets/images/edit-profile.svg";
import Loader from "../../../../components/addon/loader";
import ConfirmDialog from "../../../../components/addon/confirmDialog/confirmDialog";
import toast from "react-hot-toast";
import EditScrapedSchool from "../editScrapedSchool";
import moment from "moment";

const ScrappedSchoolDetails = () => {
	const params = useParams();
	const context = useContext(AppContextObject);
	const history = useHistory();
	const [overlay, setOverlay] = useState(false);
	const [schoolDetail, setSchoolDetail] = useState<any>();
	const [localArea, setLocalArea] = useState();
	const [city, setCity] = useState();
	const [state, setState] = useState();
	const [pincode, setPincode] = useState();
	const [address, setAddress] = useState();
	const [schoolEduLevel, setSchoolEduLevel] = useState([]);
	const [schoolLang, setSchoolLang] = useState([]);
	const [schoolBoard, setSchoolBoard] = useState([]);
	const [schoolId, setSchoolId] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [showSchoolDeleteModal, setShowSchoolDeleteModal] = useState(false);
	const [openVerifiedModal, setOpenVerifiedModal] = useState(false);

	const [isVerified, setIsVerified] = useState(false);
	const [refetch, setRefetch] = useState(false);

	// const [showScrappedSchoolContact, setShowScrappedSchoolContact] = useState("")
	const [schoolName, setSchoolName] = useState();
	const [showEditScrapedSchoolData, setShowEditScrapedSchoolData] = useState(
		false
	);
	const [hideEditDelete, setHideEditDelete] = useState(true);
	// const [displayBlock, setDisplayBlock] = useState("block")
	const [isToggle, setIsToggle] = useState({});
	// const [board, setBoard] = useState()

	useEffect(() => {
		console.log("This is params ---", params);
		if (
			history.location.search &&
			history.location.search.split("?").length > 1
		) {
			let array = history.location.search.split("?");
			if (array && array.length > 1 && array[1] && array[1] !== "") {
				let data: any = array[1];
				if (data) {
					setSchoolId(data);
					getScrappedSchoolDetails(data);
				}
			}
		} else if (history.location.state) {
			let data: any = history.location.state;
			console.log("This is just data ", data);
			if (data.schoolId) {
				setSchoolId(data.schoolId);
				getScrappedSchoolDetails(data.schoolId);
				//  updateScrappedSchool(data)
				console.log("data.schoolId ", data.schoolId);
			}
		}
	}, [refetch]);

	// var tempEditDeleteHid = true;
	// useEffect(() => {
	//     console.log("This is true -- 1", hideEditDelete, displayBlock)
	//     if(hideEditDelete == true)
	//     {
	//         console.log("This is true -- 2", displayBlock)
	//         //  setHideEditDelete(false)
	//         setDisplayBlock('none');
	//     }
	//   });

	// Toggle isVerified Starts

	//     useEffect(() => {
	//         let data: any = history.location.state
	//     updateScrappedSchool(data.schoolId);
	// }, [])

	// Toggle isVerified Ends

	const getScrappedSchoolDetails = async (_id) => {
		setIsLoading(true);
		// const resp = await context.apis[context.user.role].getScrappedSchoolDetails(
		//     context.validator, {_id}
		// )
		// const resp = await context.apis[context.user.role].getScrappedSchoolDetails(
		const resp = await context.apis[
			context.user.role
		].getScrappedSchoolDetails(context.validator, { _id });
		console.log("getScrappedSchoolDetails--- New One", resp);
		if (resp && resp.done) {
			setLocalArea(resp.response.localArea);
			setCity(resp.response.city);
			setState(resp.response.state);
			setPincode(resp.response.pincode);
			setAddress(resp.response.address);
			setSchoolLang([resp.response.language]);
			setSchoolBoard([resp.response.Board]);

			setIsVerified(resp.response.is_verified);

			setSchoolDetail(resp.response);
			setSchoolName(resp.response.schoolName);
			console.log("this is schoolName hidden ", schoolName);
			// const text = resp.response.Contact;
			// console.log("This is text for spliting ", text);

			// const matches = text.match(/'(.*?)'/g);

			// if (matches) {
			// for (let i = 0; i < matches.length; ++i) {
			// const match = matches[i];
			// var substring = match.substring(1, match.length - 1);
			// console.log("This is substring -- ", substring);
			// }
			// }

			// setShowScrappedSchoolContact(substring);

			// console.log("Test School name ---", resp.response)
			// setsetBoard(resp.response)
		}
		setIsLoading(false);
	};

	// Update Toggle Scrapped School Starts

	// openVerifiedModal

	// const showVerifiedModal = () => {
	//     // setOpenVerifiedModal(true)
	//     // setOverlay(true)
	//     updateScrappedSchool(true);
	// };

	const showVerifiedModal = () => {
		setOpenVerifiedModal(true);
		setOverlay(true);
	};

	const updateScrappedSchool = async (confirm) => {
		setIsLoading(true);
		// setOpenVerifiedModal(true)
		// setOverlay(true)
		setHideEditDelete(false);

		// setOverlay(true)
		setIsToggle({
			disabled: true,
		});
		// setDisplayBlock('none');
		if (confirm) {
			const resp = await context.apis[context.user.role].updateScrappedSchool(
				context.validator,
				{ _id: schoolId },
				{ is_verified: !isVerified }
			);
			console.log("updateScrappedSchool--- New One", resp);
			if (resp && resp.done) {
				setRefetch(!refetch);
				setLocalArea(resp.response.localArea);
				setCity(resp.response.city);
				setState(resp.response.state);
				setPincode(resp.response.pincode);
				setAddress(resp.response.address);
				setSchoolLang([resp.response.language]);
				setSchoolBoard([resp.response.Board]);

				setSchoolDetail(resp.response);
				setSchoolName(resp.response.schoolName);
				console.log("this is schoolName hidden ", schoolName);

				toast.success("Verified Successfully.");
				// history.push('/backend/backend-details/scrapped-school-data-details')
			}
		}
		setIsLoading(false);
		setOverlay(false);
		// setShowSchoolDeleteModal(false)
		setOpenVerifiedModal(false);
		setHideEditDelete(true); // here
		// event.preventDefault();

		// setOverlay(false)
		// setShowSchoolDeleteModal(false)
	};

	// Update Toggle Scrapped School Ends

	const openModal = () => {
		history.push({
			pathname: "/backend/backend-details/edit-scraped-school",
			state: { isEdit: true, _id: schoolDetail._id },
		});
	};
	const openDeleteModal = () => {
		setShowSchoolDeleteModal(true);
		setOverlay(true);
	};

	// const showVerifiedModal = () => {
	//     setOpenVerifiedModal(true)
	//     setOverlay(true)
	// }

	const deleteScrappedSchool = async (confirm) => {
		if (confirm) {
			let resp = await context.apis[
				context.user.role
			].deleteScrappedSchool(context.validator, { _id: schoolId });
			console.log("deleteScrappedSchool", resp);
			if (resp && resp.done) {
				toast.success("school deleted successfully.");
				history.push("/backend/backend-details/scrapped-school-data");
			}
		}
		setOverlay(false);
		setShowSchoolDeleteModal(false);
	};

	console.log("schoolDetailschoolDetail", schoolDetail);

	// const arrayOfCallingActivities = schoolDetail.callingActivityDetails.map((activity) => {activity});

	return (
		<>
			<div className={`ovelap ${overlay === true ? "active" : ""}`}></div>
			{isLoading ? (
				<Loader />
			) : (
				<div className="col-xl-10 col-lg-12 col-md-12 col-xs-12">
					<div className="rightPart noBack">
						<div className="row">
							<BackendTopBar
								redirectPage={true}
								from="hideBackButtonScrapedDetail"
							/>

							<div className="col-xl-8 col-lg-8 col-md-8 col-xs-12 mt-10">
								<div className="inventory-overHead my-school-features">
									<p>school name</p>

									{/* <h3 className="htx4 clearfix" >{schoolDetail && schoolDetail.schoolName}<a className="editSchool" style={{ cursor: "pointer" }} onClick={openDeleteModal}><i className="fa fa-trash" style={{cursor: "pointer", marginLeft: "10px"}}></i> Delete</a>
                                    {schoolDetail && schoolDetail.schoolName && schoolDetail.schoolName}{(context.user.role === "backendAdmin" || context.user.role === "backendOperations" || context.user.role === "backendTech" || context.user.role === "backendFOS") && <><a className="editSchool" style={{ cursor: "pointer" }} onClick={openModal}><img src={proEdit} /> Edit</a></>}
                                    </h3> */}

									<div className="row">
										<div className="col-xl-8 col-lg-8 col-md-8 col-xs-12 mt-10 textHover">
											<h3
												className="htx4 clearfix "
												style={{
													textOverflow: "ellipsis",
													whiteSpace: "nowrap",
													overflow: "hidden",
												}}
											>
												{schoolDetail && schoolDetail.schoolName}
											</h3>
											<h3 className="textHidden">
												{schoolDetail && schoolDetail.schoolName}
											</h3>
										</div>

										<div className="col-xl-4 col-lg-4 col-md-4 col-xs-12 mt-10">
											{/* {schoolDetail && schoolDetail.schoolName && schoolDetail.schoolName}{(context.user.role === "backendAdmin" || context.user.role === "backendOperations" || context.user.role === "backendTech" || context.user.role === "backendFOS") && <><a className="editSchool" style={{ cursor: "pointer" }} onClick={openModal}><img src={proEdit} /> Edit</a></>} */}
											{/* { hideEditDelete && */}
											{!isVerified && hideEditDelete && (
												// <h3 className="htx4 clearfix" style={{display:"block"}}>
												// <h3 className="htx4 clearfix" style={{display:`${ displayBlock }`}}>
												<h3 className="htx4 clearfix">
													{(context.user.role === "backendAdmin" ||
														context.user.role === "backendOperations" ||
														context.user.role === "backendTech" ||
														context.user.role === "backendFOS") && (
														<>
															<a
																className="editSchool"
																style={{ cursor: "pointer" }}
																onClick={openModal}
															>
																<img src={proEdit} /> Edit
															</a>
														</>
													)}
													{(context.user.role === "backendAdmin" ||
														context.user.role === "backendOperations") && (
														<a
															className="editSchool"
															style={{ cursor: "pointer" }}
															onClick={openDeleteModal}
														>
															<i
																className="fa fa-trash"
																style={{ cursor: "pointer" }}
															></i>{" "}
															Delete
														</a>
													)}
												</h3>
											)}
										</div>
									</div>

									{/* <h3 className="htx4 clearfix">{schoolDetail && schoolDetail.schoolName && schoolDetail.schoolName}{(context.user.role === "backendAdmin" || context.user.role === "backendOperations" || context.user.role === "backendTech" || context.user.role === "backendFOS") && <><a className="editSchool" style={{ cursor: "pointer" }} onClick={openModal}><img src={proEdit} /> Edit</a><a className="editSchool" style={{ cursor: "pointer" }} onClick={openDeleteModal}><i className="fa fa-trash" style={{cursor: "pointer", marginLeft: "10px"}}></i> Delete</a></>}</h3> */}
									{/* <ul>
                                        <li>{schoolDetail && schoolDetail._id}</li>
                                        <li>{schoolLang.length !== 0 && schoolLang.map((language, index) => (
                                            index == schoolLang.length - 2 ? `${language} & ` : index == schoolLang.length - 1 ? language : `${language}, `
                                        ))} Medium
                                        </li>
                                        <li>{schoolBoard && Array.isArray(schoolBoard) && schoolBoard.length !== 0 && schoolBoard.map((board, index) => (
                                            index == schoolBoard.length - 2 ? `${readAbleBoard(board)} & ` : index == schoolBoard.length - 1 ? readAbleBoard(board) : `${readAbleBoard(board)}, `
                                        ))} Board
                                        </li>
                                        <li>Cat {schoolDetail && schoolDetail.category && schoolDetail.category}</li>
                                        <li>{schoolDetail && schoolDetail.totalStudents && schoolDetail.totalStudents} Students</li>
                                        <li> {schoolDetail && schoolDetail.builtUpArea && schoolDetail.builtUpArea} Sq Ft. School area</li>
                                        <li> {`${address && address}, ${localArea && localArea}, ${city && city}, ${pincode && pincode}, ${state && state}`}</li>
                                    </ul> */}
								</div>
							</div>

							<div
								className="col-xl-4 col-lg-4 col-md-4 col-xs-12 pl"
								style={{ paddingTop: "50px" }}
							>
								<div className="row">
									{!isVerified ? (
										<div className="col-xl-8 col-lg-8 col-md-8 col-xs-12">
											<div
												className="notification-showhide"
												style={{ textAlign: "right", marginTop: "3px" }}
											>
												<h4
													style={{
														fontSize: "15px",
														margin: "0 0 0 13px",
														color: "#070707",
													}}
												>
													Is Verified?
												</h4>
											</div>
										</div>
									) : (
										<div className="col-xl-8 col-lg-8 col-md-8 col-xs-12">
											<div
												className="notification-showhide"
												style={{ float: "right" }}
											>
												<h4
													style={{
														fontSize: "12px",
														margin: "0 0 0 13px",
														background: "#e6f8f2",
														color: "#31c295",
														padding: "4px 9px",
														borderRadius: "4px",
														fontFamily: "nexaregular",
														textTransform: "capitalize",
														width: "100px",
														textAlign: "center",
													}}
												>
													<i
														className="fa fa-check-circle"
														aria-hidden="true"
													></i>{" "}
													Verified
												</h4>
											</div>
										</div>
									)}

									<div className="col-xl-4 col-lg-4 col-md-4 col-xs-12">
										<div className="ava-brad ">
											<div className="content">
												<label className="switch">
													{/* { (isVerified && isToggle) && */}
													{/* <input {...isToggle} onClick={updateScrappedSchool} type="checkbox" id="emailNotification" className="shohide" data-cls="onclass1" value={isVerified ? "true" : "false"} checked={isVerified} /> */}
													{!isVerified && (
														<>
															{/* <input { ...isToggle} onClick={showVerifiedModal} type="checkbox" id="emailNotification" className="shohide" data-cls="onclass1" value={isVerified ? "true" : "false"} checked={isVerified} /> */}
															<input
																onClick={showVerifiedModal}
																type="checkbox"
																id="emailNotification"
																className="shohide"
																data-cls="onclass1"
																value={isVerified ? "true" : "false"}
																checked={isVerified}
															/>
															<span className="slider round"></span>
														</>
													)}
												</label>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-10 mb-10">
								<div className="line-full"></div>
							</div>
							<div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-10">
								<div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
									<div className="forminpt">
										<div className="row">
											<div className="col-xl-3 col-lg-3 col-md-3 col-xs-12">
												School Name:
											</div>
											<div
												className="col-xl-9 col-lg-9 col-md-9 col-xs-12"
												style={{ color: "#444" }}
											>
												{(schoolDetail &&
													schoolDetail.schoolName &&
													titleCase(readAbleBoard(schoolDetail.schoolName))) ||
													"-"}
											</div>
										</div>
									</div>
									<div className="forminpt">
										<div className="row">
											<div className="col-xl-3 col-lg-3 col-md-3 col-xs-12">
												Board:
											</div>
											<div
												className="col-xl-9 col-lg-9 col-md-9 col-xs-12"
												style={{ color: "#444" }}
											>
												{(schoolDetail &&
													schoolDetail.Board &&
													titleCase(readAbleBoard(schoolDetail.Board))) ||
													"-"}
											</div>
										</div>
									</div>
									<div className="forminpt">
										<div className="row">
											<div className="col-xl-3 col-lg-3 col-md-3 col-xs-12">
												Language of Study:
											</div>
											<div
												className="col-xl-9 col-lg-9 col-md-9 col-xs-12"
												style={{ color: "#444" }}
											>
												{(schoolDetail &&
													schoolDetail.LanguageOfInstruction &&
													titleCase(schoolDetail.LanguageOfInstruction)) ||
													"-"}
											</div>
										</div>
									</div>

									<div className="forminpt">
										<div className="row">
											<div className="col-xl-3 col-lg-3 col-md-3 col-xs-12">
												State:
											</div>
											<div
												className="col-xl-9 col-lg-9 col-md-9 col-xs-12"
												style={{ color: "#444" }}
											>
												{(schoolDetail &&
													schoolDetail.state &&
													titleCase(schoolDetail.state)) ||
													"-"}
											</div>
										</div>
									</div>
									<div className="forminpt">
										<div className="row">
											<div className="col-xl-3 col-lg-3 col-md-3 col-xs-12">
												City:
											</div>
											<div
												className="col-xl-9 col-lg-9 col-md-9 col-xs-12"
												style={{ color: "#444" }}
											>
												{(schoolDetail &&
													schoolDetail.city &&
													titleCase(schoolDetail.city)) ||
													"-"}
											</div>
										</div>
									</div>
									<div className="forminpt">
										<div className="row">
											<div className="col-xl-3 col-lg-3 col-md-3 col-xs-12">
												Address:
											</div>
											<div
												className="col-xl-9 col-lg-9 col-md-9 col-xs-12"
												style={{ color: "#444" }}
											>
												{(schoolDetail && titleCase(schoolDetail.address)) ||
													"-"}
											</div>
										</div>
									</div>
									<div className="forminpt">
										<div className="row">
											<div className="col-xl-3 col-lg-3 col-md-3 col-xs-12">
												Pincode:
											</div>
											<div
												className="col-xl-9 col-lg-9 col-md-9 col-xs-12"
												style={{ color: "#444" }}
											>
												{(schoolDetail && parseInt(schoolDetail.pincode)) ||
													"-"}
											</div>
										</div>
									</div>
									<div className="forminpt">
										<div className="row">
											<div className="col-xl-3 col-lg-3 col-md-3 col-xs-12">
												Contact Email:
											</div>
											<div
												className="col-xl-9 col-lg-9 col-md-9 col-xs-12"
												style={{ color: "#444" }}
											>
												{(schoolDetail && schoolDetail.contactEmail) || "-"}
											</div>
										</div>
									</div>
									<div className="forminpt">
										<div className="row">
											<div className="col-xl-3 col-lg-3 col-md-3 col-xs-12">
												Contact Number:
											</div>
											<div
												className="col-xl-9 col-lg-9 col-md-9 col-xs-12"
												style={{ color: "#444" }}
											>
												{(schoolDetail && schoolDetail.Valid_Contact) || "-"}
											</div>
										</div>
									</div>
									<div className="forminpt">
										<div className="row">
											<div className="col-xl-3 col-lg-3 col-md-3 col-xs-12">
												Other Contact Numbers:
											</div>
											<div
												className="col-xl-9 col-lg-9 col-md-9 col-xs-12"
												style={{ color: "#444" }}
											>
												{(schoolDetail && schoolDetail.Contact) || "-"}
											</div>
										</div>
									</div>
									{/* Source Added */}

									<div className="forminpt">
										<div className="row">
											<div className="col-xl-3 col-lg-3 col-md-3 col-xs-12">
												Source:
											</div>
											<div
												className="col-xl-9 col-lg-9 col-md-9 col-xs-12"
												style={{ color: "#444" }}
											>
												{(schoolDetail &&
													schoolDetail?.Source?.charAt(0)?.toUpperCase() +
														schoolDetail?.Source?.slice(1)) ||
													"-"}
											</div>
										</div>
									</div>
								</div>

								{/* calling activity starts  */}
								{schoolDetail?.callingActivityDetails ? (
									<>
										<div className="line-full"></div>
										<div
											className="col-xl-12 col-lg-12 col-md-12 col-xs-12"
											style={{ marginTop: "20px" }}
										>
											<h2 className="htx1 mb40">Calling Activity Details </h2>

											{schoolDetail?.callingActivityDetails?.map(
												(activity, index) => (
													<>
														{/* <div className="forminpt"> */}
														<div className="" style={{ marginBottom: "8px" }}>
															<div className="row">
																<div className="col-xl-3 col-lg-3 col-md-3 col-xs-12">
																	{/* <h4 style={{ marginBottom: "30px" }}> */}
																	<h4
																		style={{
																			marginBottom: "5px",
																			color: "#FE6E00",
																		}}
																	>
																		Call {index + 1}{" "}
																	</h4>
																</div>
															</div>
														</div>

														<div className="forminpt">
															<div className="row">
																<div className="col-xl-3 col-lg-3 col-md-3 col-xs-12">
																	Date Time:
																</div>
																<div
																	className="col-xl-9 col-lg-9 col-md-9 col-xs-12"
																	// style={{ color: "#444" }}
																	style={{ color: "#666" }}
																>
																	{moment(activity.dateTime).format(
																		"DD-MM-YYYY HH:mm"
																	)}
																</div>
															</div>
														</div>

														<div className="forminpt">
															<div className="row">
																<div className="col-xl-3 col-lg-3 col-md-3 col-xs-12">
																	Outcome:
																</div>
																<div
																	className="col-xl-9 col-lg-9 col-md-9 col-xs-12"
																	style={{ color: "#666" }}
																>
																	{(activity?.callOutcomeDetails?.name &&
																		activity?.callOutcomeDetails?.name) ||
																		"-"}
																</div>
															</div>
														</div>

														<div className="forminpt">
															<div className="row">
																<div className="col-xl-3 col-lg-3 col-md-3 col-xs-12">
																	Contacted Number:
																</div>
																<div
																	className="col-xl-9 col-lg-9 col-md-9 col-xs-12"
																	style={{ color: "#666" }}
																>
																	{(activity?.contactNumber &&
																		activity?.contactNumber) ||
																		"-"}
																</div>
															</div>
														</div>

														<div
															className="forminpt"
															style={{ marginBottom: "55px" }}
														>
															<div className="row">
																<div className="col-xl-3 col-lg-3 col-md-3 col-xs-12">
																	Remark:
																</div>
																<div
																	className="col-xl-9 col-lg-9 col-md-9 col-xs-12"
																	style={{
																		color: "#666",
																		overflowWrap: "break-word",
																	}}
																>
																	{(activity?.remarks && activity?.remarks) ||
																		"-"}
																</div>
															</div>
														</div>
													</>
												)
											)}
										</div>
									</>
								) : (
									""
								)}
								{/* calling activity ends  */}
							</div>
						</div>
					</div>
				</div>
			)}
			{showSchoolDeleteModal && (
				<ConfirmDialog
					confirmTitle="Do you want to delete this school ?"
					confirmText="Yes"
					denyText="No"
					onModalClose={deleteScrappedSchool}
					togglePopup={showSchoolDeleteModal}
				/>
			)}
			{showEditScrapedSchoolData && (
				<ConfirmDialog
					confirmTitle="Do you want to edit this inventory?"
					togglePopup={showEditScrapedSchoolData}
					onModalClose=""
					componentName={EditScrapedSchool}
					isLoading=""
				/>
			)}
			{openVerifiedModal && (
				<ConfirmDialog
					confirmTitle="Do you want to make this school verified ?"
					confirmText="Yes"
					denyText="No"
					onModalClose={updateScrappedSchool}
					togglePopup={openVerifiedModal}
				/>
			)}
		</>
	);
};

export default ScrappedSchoolDetails;
