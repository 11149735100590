const ImageModal = (props) => {
    const {
        onModalClose,
        togglePopup,
        displayImage
    } = props



    return (
        <div id="closePopup" className={`popup campaignViewimg ${togglePopup === true ? "" : "hide"}`}>
            <div className="popup-header">
                <span onClick={() => onModalClose()} className="close toggle" data-target="closePopup">close</span>
            </div>
            <div className="popup-body">
                <div className="clearfix">
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            {/* <h3 className="htx2">{displayImage}</h3> */}
                            <img src={displayImage} className="upload-campaignImg"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ImageModal