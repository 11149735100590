import {
    useState,
    useEffect,
    forwardRef,
    useImperativeHandle,
    useContext,
  } from "react"
  
  import NumWithPlusMinus from "../../../../../components/formFields/numWithPlusMinus/numWithPlusMinus"
  import NumberField from "../../../../../components/formFields/numberField"
  
  import config from "../../../../../service/config"
  
  import { objectDeepClone } from "../../../../../service/helper"
  
  import DataContext from "../../../../../service/contextProvider"
  import AppContextObject from "../../../../../common/context/common"
  import ComaSeperatedNumField from "../../../../../components/formFields/comaSeperatedInput"

  interface Props {
    compareOldAndNewData?: Function,
    setInitialData?: Function,
    newParentData?: any,
    showModal?: boolean,
    schoolId?: any,
    isBackendUser ? : boolean
  }

const Attributes = forwardRef(({
    compareOldAndNewData,
    setInitialData,
    newParentData,
    showModal,
    schoolId,
    isBackendUser
}: Props, ref) => {
    console.log("newParentData--->>>",newParentData,showModal)
    const context = useContext(AppContextObject)
    let parentData = useContext < any > (DataContext)
    parentData = newParentData ? {...newParentData} : parentData
    const [oldParentData, setOldParentData] = useState(parentData)
    const [classrooms, setClassrooms] = useState([])
    const [maxStudentsLimit, setMaxStudentsLimit] = useState(999)
    const [defaultStudentNo] = useState(999)
    const [schoolType, setSchoolType] = useState(newParentData ? newParentData?.data?.schoolGeneral.schoolType : parentData?.data?.schoolGeneral.schoolType)
    const [activeClassroom, setActiveClassroom] = useState(0)
    const [pendingClassList, setPendingClassList] = useState([])
    const [completedClassList, setCompletedClassList] = useState([])
    const [oldClassRoomDetail, setOldClassRoomDetail] = useState()
    const [noOfSectionError, setNoOfSectionError] = useState([])
    const [ageByStandardError, setAgeByStandardError] = useState([])
    const [annualFeeError, setAnnualFeeError] = useState([])
    const [noOfBoysError, setNoOfBoysError] = useState([])
    const [noOfGirlsError, setNoOfGirlsError] = useState([])
  
    const [attributes]: any = useState({
        noOfSections: 1,
        ageByStandard: 3,
        avgAnnualFee: 0,
    })
  
    useEffect(() => {
        if(newParentData && showModal){
            getSchoolGeneralDetails()
            getSchoolAmenitiesDetails()
        }
    }, [showModal !== undefined && showModal])
      useEffect(() => {
          if(showModal === undefined || showModal){
            if(newParentData){
                parentData = {...newParentData}
                setSchoolType(newParentData?.data?.schoolGeneral.schoolType)
            }
            fillClassroomDetails();
        }
      }, [newParentData, showModal !== undefined && showModal])

      useEffect(() => {
        if(showModal === undefined || showModal){
        compareOldAndNewData(classrooms)
        }
      }, [classrooms, showModal !== undefined && showModal])

      useEffect(() => {
        if(showModal === undefined || showModal){
            fillClassroomDetails();
        }
      }, [parentData?.data?.schoolAmenities.classrooms, newParentData?.data?.schoolGeneral.schoolType, showModal !== undefined && showModal])

      useEffect(() => {
        if(showModal === undefined || showModal){
            setSchoolType(newParentData ? newParentData?.data?.schoolGeneral.schoolType : parentData?.data?.schoolGeneral.schoolType)
        }
    }, [parentData?.data?.schoolGeneral.schoolType, newParentData?.data?.schoolGeneral.schoolType, showModal !== undefined && showModal])

    const limits = {
        noOfSections: {
            min: 0,
            max: 20
        },
        ageByStandard: {
            min: 0,
            max: 20
        },
        boys: {
            min: 0,
            max: maxStudentsLimit
        },
        girls: {
            min: 0,
            max: maxStudentsLimit
        }
    }

    useEffect(() => {
        if(showModal === undefined || showModal){
            fillClassroomDetails();
        }
    }, [schoolType, showModal !== undefined && showModal])
  
      useEffect(() => {
          if(showModal === undefined || showModal){
            if (classrooms[activeClassroom]?.data.noOfSections &&
                classrooms[activeClassroom]?.data.noOfSections >= 1) {
                    setMaxStudentsLimit((Number(classrooms[activeClassroom]?.data.noOfSections) * defaultStudentNo) + (classrooms[activeClassroom]?.data.noOfSections - 1))
            }
        }
      }, [classrooms[activeClassroom]?.data?.noOfSections, showModal !== undefined && showModal])

      const getSchoolGeneralDetails = async () =>{
          if(schoolId || context.user.school){

            let schoolGeneralDetailsReps = await context.apis[context.user.role].getSchoolGeneralDetailsBySchoolId(
                context.validator, {_id: schoolId || context.user.school}
            )
            if(schoolGeneralDetailsReps && schoolGeneralDetailsReps.done){
                setOldParentData(schoolGeneralDetailsReps.response)
            }
          }
        }
        const getSchoolAmenitiesDetails = async () => {
          if(schoolId || context.user.school){
            let schoolAmenitiesDetailsReps = await context.apis[context.user.role].getSchoolAmenitiesDetailsBySchoolId(
                context.validator, {_id: schoolId || context.user.school}
            )
            if(schoolAmenitiesDetailsReps && schoolAmenitiesDetailsReps.done){
                console.log(schoolAmenitiesDetailsReps.response.classrooms)
                setOldClassRoomDetail(schoolAmenitiesDetailsReps.response.classrooms)
            }
         }
        }
      const fillClassroomDetails = () => {
        let classes = ["N/Pg"]
        let arr = []
        console.log("parentData?.data?.schoolGeneral?.educationLevel",parentData?.data?.schoolGeneral?.educationLevel);
        if (parentData?.data?.schoolGeneral?.educationLevel.length > 0) {
            classes = []
            const isAllSelected = parentData?.data?.schoolGeneral?.educationLevel.find((e) => e === "all")
            if (isAllSelected) {
                const allSelectedArr = config.eduLevels.find((e) => e.value === isAllSelected).classRange
                classes.push(...allSelectedArr)
            } else {
                for (let i = 0; i < parentData?.data?.schoolGeneral.educationLevel.length; i++) {
                    let el = parentData?.data?.schoolGeneral.educationLevel[i]
                    const found = config.eduLevels.find((e) => e.value === el)
                    const foundClass = schoolType?.classWiseDetails.find(c => c.eduLevel === el)
                    if (found && foundClass) {
                        for (let j = 0; j < found.classRange.length; j++) {
                            const cr = found.classRange[j]
                            const data: any = getClassroomData(el, foundClass.schoolType, cr)
                            classes.push({...data})
                            arr.push({...data})
                        }
                    }
                }
            }
        }

        let sortedArr = arr.filter(el => Number(el.class))
        sortedArr.sort((a,b) => (Number(a.class) > Number(b.class)) ? 1 : ((Number(b.class) > Number(a.class)) ? -1 : 0))

        const srKg = arr.find(e => e.class === "Sr.Kg")
        if (srKg) sortedArr.splice(0, 0, srKg)
        
        const jrKg = arr.find(e => e.class === "Jr.Kg")
        if (jrKg) sortedArr.splice(0, 0, jrKg)

        const nPg = arr.find(e => e.class === "N/Pg")
        if (nPg) sortedArr.splice(0, 0, nPg)
        console.log("sortedArr--->>>",sortedArr);
        setClassrooms(sortedArr)
        setInitialData(sortedArr)
    }
    console.log("parentData?.data?.schoolAmenities.",parentData?.data?.schoolAmenities);
    const getClassroomData = (el, type, cr) => {
        let attr = objectDeepClone(attributes)
        console.log("attr-->>>",attr)
        const found = parentData?.data?.schoolAmenities.classrooms.find((e) => e.class === cr)
        console.log("found-->>",found, type, cr, parentData?.data?.schoolAmenities.classrooms)
        const prevType = oldParentData?.schoolType?.classWiseDetails.find(c => c.eduLevel === el)
        const prevClassList:any = oldClassRoomDetail
        const currentClass = prevClassList && prevClassList.find(e => e.class === cr)
        if(showModal){
            if (!found || (prevType && prevType.schoolType !== type)) {
                switch (type) {
                    case "boys":
                        attr = {
                            ...attributes,
                            boys: currentClass && currentClass.data.boys ? currentClass.data.boys : 0,
                            avgAnnualFee: currentClass && currentClass.data.avgAnnualFee ? currentClass.data.avgAnnualFee : 0,
                        }
                        delete attr.girls
                        break
        
                    case "girls":
                        attr = {
                            ...attributes,
                            girls: currentClass && currentClass.data.girls ? currentClass.data.girls : 0,
                            avgAnnualFee: currentClass && currentClass.data.avgAnnualFee ? currentClass.data.avgAnnualFee : 0,
                        }
                        delete attr.boys
                        break
        
                    case "co-ed":
                        attr = {
                            ...attributes,
                            girls: currentClass && currentClass.data.girls ? currentClass.data.girls : 0,
                            boys: currentClass && currentClass.data.boys ? currentClass.data.boys : 0,
                            avgAnnualFee: currentClass && currentClass.data.avgAnnualFee ? currentClass.data.avgAnnualFee : 0,
                        }
                        break
        
                    default:
                        break
                }
            }
            if(found){
                attr.school = found.data.school
                attr._id = found.data._id
                if(found.data.girls == 0){
                    delete found.data.girls
                }
                if(found.data.boys == 0){
                    delete found.data.boys
                }
            }
        }
        else{
            // if (!found) {
                switch (type) {
                    case "boys":
                        attr = {
                            ...attributes,
                            boys: currentClass && currentClass.data.boys ? currentClass.data.boys : 0,
                            avgAnnualFee: currentClass && currentClass.data.avgAnnualFee ? currentClass.data.avgAnnualFee : 0,
                        }
                        delete attr.girls
                        break

                    case "girls":
                        attr = {
                            ...attributes,
                            girls: currentClass && currentClass.data.girls ? currentClass.data.girls : 0,
                            avgAnnualFee: currentClass && currentClass.data.avgAnnualFee ? currentClass.data.avgAnnualFee : 0,
                        }
                        delete attr.boys
                        break

                    case "co-ed":
                        attr = {
                            ...attributes,
                            girls: currentClass && currentClass.data.girls ? currentClass.data.girls : 0,
                            boys: currentClass && currentClass.data.boys ? currentClass.data.boys : 0,
                            avgAnnualFee: currentClass && currentClass.data.avgAnnualFee ? currentClass.data.avgAnnualFee : 0,
                        }
                        break
        
                    default:
                        break
                }
        }
        if(prevType){
            let json = {
                class: cr,
                data: found && prevType.schoolType === type ? objectDeepClone({...attr, ...found.data}) : objectDeepClone(attr)
            }
            return json
        }
        else{
            let json = {
                class: cr,
                data: found ? objectDeepClone({...attr, ...found.data}) : objectDeepClone(attr)
            }
            if (type === "boys") delete json.data.girls
            if (type === "girls") delete json.data.boys
            return json
        }
        
        // Object.keys(json.data).forEach(a => {
        //     if (!attr.hasOwnProperty(a) && a !== "_id") {
        //         delete json.data[a]
        //     }
        // })
    }

    useEffect(() => {
        if(showModal === undefined || showModal){
            if (classrooms[activeClassroom]?.data.noOfSections &&
                classrooms[activeClassroom]?.data.noOfSections >= 1) {
                    setMaxStudentsLimit((Number(classrooms[activeClassroom]?.data.noOfSections) * defaultStudentNo) + (classrooms[activeClassroom]?.data.noOfSections - 1))
            }
        }
    }, [classrooms[activeClassroom]?.data?.noOfSections, showModal !== undefined && showModal])
    console.log("classrooms->>",classrooms);
    useEffect(() => {
        if(showModal === undefined || showModal){
            let pendingList=[]
            let completedList=[]
            parentData?.data?.schoolAmenities?.classrooms.forEach(e => {
                Object.keys(e.data).forEach(el => {
                    if (!e.data[el] || e.data[el] === "0") {
                        pendingList.push(e.class)
                    }
                })
            })
            const uniq = pendingList.filter(function(item, pos) {
                return pendingList.indexOf(item) == pos
            })
            setPendingClassList(uniq)
            parentData?.data?.schoolAmenities?.classrooms.forEach(el => {
                const found = uniq.find(e => e === el.class)
                if (!found) {
                    completedList.push(el.class)
                }
            })
            setCompletedClassList(completedList)
        }
    }, [parentData?.data?.schoolAmenities?.classrooms, showModal !== undefined && showModal])

    const onFormDataChange = (event) => {
        const arr = objectDeepClone(classrooms)
        if(event.target.id === "avgAnnualFee" && event.target.value.length<7){
            arr[activeClassroom].data[event.target.id] = event.target.value
        }
        // const arr = objectDeepClone(classrooms)
        // arr[activeClassroom].data[event.target.id] = event.target.value
        if (event.target.id === "noOfSections") {
            if (arr[activeClassroom].data.hasOwnProperty("boys")) {
                arr[activeClassroom].data["boys"] = 0
            }
            if (arr[activeClassroom].data.hasOwnProperty("girls")) {
                arr[activeClassroom].data["girls"] = 0
            }     
            arr[activeClassroom].data[event.target.id] = event.target.value       
        }
        if(event.target.id === "boys" && Number(event.target.value) <= maxStudentsLimit){
            arr[activeClassroom].data[event.target.id] = event.target.value
        }
        if(event.target.id === "girls" && Number(event.target.value) <= maxStudentsLimit){
            arr[activeClassroom].data[event.target.id] = event.target.value
        }
        if(event.target.id === "ageByStandard"){
            arr[activeClassroom].data[event.target.id] = event.target.value
        }
        setClassrooms(arr)
    }
    const onAnnualFeeChange = (data) => {
        const arr = objectDeepClone(classrooms)
        if(data.value.length<7){
            arr[activeClassroom].data["avgAnnualFee"] = data.value
        }
        setClassrooms(arr)
    }

    const updateNumber = (newNum, keyName, isSectionPlus) => {
        const arr = objectDeepClone(classrooms)
        arr[activeClassroom].data[keyName] = newNum
        if (keyName === "noOfSections" && !isSectionPlus) {
            if (arr[activeClassroom].data.hasOwnProperty("boys")) {
                arr[activeClassroom].data["boys"] = 0
            }
            if (arr[activeClassroom].data.hasOwnProperty("girls")) {
                arr[activeClassroom].data["girls"] = 0
            }            
        }
        setClassrooms(arr)
    }

    useImperativeHandle(ref, () => ({
        renderErrorState() {
            let pendingList = []
            let completedList = []
            let ageError = [], sectionError = [], feeError = [], boysError = [], girlsError = [];
            console.log("classrooms----", classrooms)
            classrooms.forEach((e, index) => {
                Object.keys(e.data).forEach(el => {
                    if(el !== "ageByStandard"){
                        if (!e.data[el] || e.data[el] === "0" || e.data[el] === "") {
                            if(el === "noOfSections"){
                                // let error = [...noOfSectionError]
                                // error.push(e.class)
                                // setNoOfSectionError([...error])
                                sectionError.push(e.class)
                            }
                            else if(el === "avgAnnualFee"){
                                // let error = [...annualFeeError]
                                // error.push(e.class)
                                // console.log("every error class", e.class, annualFeeError, error)
                                // setAnnualFeeError([...error])
                                feeError.push(e.class)
                            }
                            else if(el === "boys"){
                                // let error = [...noOfBoysError]
                                // error.push(e.class)
                                // setNoOfBoysError([...error])
                                boysError.push(e.class)
                            }
                            else if(el === "girls"){
                                // let error = [...noOfGirlsError]
                                // error.push(e.class)
                                // setNoOfGirlsError([...error])
                                girlsError.push(e.class)
                            }
                            pendingList.push(e.class)
                        }
                    }
                    else if(el === "ageByStandard"){
                        if(!e.data[el] || e.data[el] === "0" || e.data[el] === "" || e.data[el] < 3){
                            // let error = [...ageByStandardError]
                            // error.push(e.class)
                            // setAgeByStandardError([...error])
                            ageError.push(e.class)
                            pendingList.push(e.class)
                        }
                    }
                })
            })
            setNoOfSectionError([...sectionError])
            setAnnualFeeError([...feeError])
            setNoOfBoysError([...boysError])
            setNoOfGirlsError([...girlsError])
            setAgeByStandardError([...ageError])
            const uniq = pendingList.filter(function(item, pos) {
                return pendingList.indexOf(item) == pos
            })
            setPendingClassList(uniq)
            classrooms.forEach(el => {
                const found = uniq.find(e => e === el.class)
                if (!found) {
                    completedList.push(el.class)
                }
            })
            if (uniq && uniq.length > 0) {
                const item = document.getElementById(`className-${uniq[0]}`)
                const parent = document.getElementById('classroomsWrapper')
                if (item != null && parent != null) {
                    parent.scrollLeft = item.offsetLeft
                }
            }
            setCompletedClassList(completedList)
            return uniq
        },
        fecthClassrooms() {
            return classrooms
        },
        fetchPending() {
            return pendingClassList
        },
    }))

    useEffect(() => {
        if(showModal === undefined || showModal){
            setActiveClassroom(0)
        }
    }, [showModal])
    useEffect(() => {
        // if(newParentData?.data?.schoolGeneral?.educationLeve.includes())
        if(newParentData && showModal && classrooms && classrooms[activeClassroom] && classrooms[activeClassroom].class){
            checkActiveClass()
        }
    }, [newParentData?.data?.schoolGeneral?.educationLevel, classrooms])
    const checkActiveClass = () => {
        let classList = []
        if (newParentData?.data?.schoolGeneral?.educationLevel.length > 0){
            for (let i = 0; i < newParentData?.data?.schoolGeneral.educationLevel.length; i++) {
                let el = newParentData?.data?.schoolGeneral.educationLevel[i]
                const found = config.eduLevels.find((e) => e.value === el)
                classList = [...classList, ...found.classRange]
            }
        }
        if(!classList.includes(classrooms[activeClassroom].class)){
            setActiveClassroom(0)
        }
    }

    return (
        <div className='facili-avai'>
            <div className='row'>
                <div className='col-xl-12 col-lg-12 col-md-12 col-xs-12 pl-0'>
                    {/* <h3 className='htx2'>Classrooms</h3> */}
                    <p className='ptx'>
                        We calculate impressions based on the strength of your school.
                    </p>
                </div>
            </div>
            <div className='row'>
                <div className='col-xl-12 col-lg-12 col-md-12 col-xs-12 pl-0'>
                {(classrooms && classrooms.length > 0) &&
                    <div className='class-grade'>
                        <div className='class-grade-top'>
                            <ul id="classroomsWrapper">
                                {
                                    classrooms.map((el, index) => {
                                        const showErrorState = pendingClassList.find((item) => item === el.class)
                                        const showCompleteState = completedClassList.find((item) => item === el.class)
                                        return (
                                            <li key={index}>
                                                <a
                                                    onClick={() => setActiveClassroom(index)}
                                                    href='javascript:void(0)'
                                                    id={`className-${el.class}`}
                                                    className={`
                                                        ${activeClassroom === index ? "active" : showCompleteState ? "complete" : ""}
                                                        ${showErrorState ? "pending" : ""}
                                                    `}
                                                >{el.class}
                                                </a>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                        <div className='class-grade-mid'>
                            <div className='row'>
                                <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                                    <h3 className='htx2'>
                                        {
                                            classrooms[activeClassroom] && 
                                            (classrooms[activeClassroom].class === "Sr.Kg" ?  "Senior Kg " :
                                             classrooms[activeClassroom].class === 'Jr.Kg' ? "Junior Kg" :
                                             classrooms[activeClassroom].class === "N/Pg" ? "Nursery/Playgroup" :
                                             `Class ${classrooms[activeClassroom].class}`)
                                        }
                                    </h3>
                                </div>
                                <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt20'></div>
                                <div className='row col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 middle-xs'>
                                    <p className='ptx2'>No of Sections/Divisions</p>
                                </div>
                                <div className='row col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 end-md'>
                                    <NumWithPlusMinus
                                        maxLimit={limits.noOfSections.max}
                                        minLimit={limits.noOfSections.min}
                                        formData={classrooms[activeClassroom] && classrooms[activeClassroom]?.data}
                                        onDataChange={onFormDataChange}
                                        updateNumber={updateNumber}
                                        formKey='noOfSections'
                                        errorClass={noOfSectionError.find(item => item === classrooms[activeClassroom].class) ? true : false}
                                        // isDisabled={true}
                                    />
                                </div>
                                <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt20'></div>
                                <div className='row col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 middle-xs'>
                                    <p className='ptx2'>Avg Age of Students</p>
                                </div>
                                <div className='row col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 end-md'>
                                    <NumWithPlusMinus
                                        maxLimit={limits.ageByStandard.max}
                                        minLimit={limits.ageByStandard.min}
                                        formData={classrooms[activeClassroom] && classrooms[activeClassroom].data}
                                        onDataChange={onFormDataChange}
                                        updateNumber={updateNumber}
                                        formKey='ageByStandard'
                                        errorClass={ageByStandardError.find(item => item === classrooms[activeClassroom].class) ? true : false}
                                        // isDisabled={true}
                                    />
                                </div>
                                {classrooms[activeClassroom]?.data.hasOwnProperty("boys") &&
                                    <>
                                        <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt20'></div>
                                        <div className='row col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 middle-xs'>
                                            <p className='ptx2'>Number of Boys</p>
                                        </div>
                                        <div className='row col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 end-md'>
                                            <NumWithPlusMinus
                                                maxLimit={maxStudentsLimit}
                                                minLimit={limits.boys.min}
                                                formData={classrooms[activeClassroom] && classrooms[activeClassroom].data}
                                                onDataChange={onFormDataChange}
                                                updateNumber={updateNumber}
                                                errorClass={noOfBoysError.find(item => item === classrooms[activeClassroom].class) ? true : false}
                                                formKey='boys'
                                            />
                                        </div>
                                    </>
                                }
                                {classrooms[activeClassroom]?.data.hasOwnProperty("girls") && 
                                    <>
                                        <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt20'></div>
                                        <div className='row col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 middle-xs'>
                                            <p className='ptx2'>Number of Girls</p>
                                        </div>
                                        <div className='row col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 end-md'>
                                            <NumWithPlusMinus
                                                maxLimit={maxStudentsLimit}
                                                minLimit={limits.girls.min}
                                                formData={classrooms[activeClassroom] && classrooms[activeClassroom].data}
                                                onDataChange={onFormDataChange}
                                                updateNumber={updateNumber}
                                                errorClass={noOfGirlsError.find(item => item === classrooms[activeClassroom].class) ? true : false}
                                                formKey='girls'
                                            />
                                        </div>
                                    </>
                                }
                                <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt20'></div>
                                <div className='row col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 middle-xs'>
                                    <p className='ptx2'>Avg Annual Fees </p>
                                </div>
                                <div className='row col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 end-md'>
                                    <ComaSeperatedNumField
                                        formData={classrooms[activeClassroom] && classrooms[activeClassroom].data}
                                        extraChar={true}
                                        formKey='avgAnnualFee'
                                        onDataChange={onAnnualFeeChange}
                                        placeholder='25,000'
                                        isRequired={false}
                                        classes='avefee rtlText'
                                        errorClass={annualFeeError.find(item => item === classrooms[activeClassroom].class) ? true : false}
                                        noLimit={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                }
                </div>
            </div>
        </div>
      )
})

export default Attributes
