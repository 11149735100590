import moment, { months } from "moment"
import React, { useState, useContext, useEffect } from "react"
import AppContextObject from "../../../common/context/common"
import config from "../../../service/config"
import { PublicContextInitiator } from "../../apis/publicContextInitiator"
import Select from "react-select"
import SingleSelect from "../../../components/formFields/singleSelect/singleSelect"

import DatePicker, { ReactDatePicker } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { titleCase } from "../../../service/helper"
const { detect } = require('detect-browser');
interface Props {
}
const CampaignReachDashboard = ({

}: Props) => {

    const [campaignReachData, setCampaignReachData] = useState(
        {
            ongoingCampaigns: "",
            schools: 0,
            locations: [],
            totalReach: 0,
            reachByCampaign: [{
                campaignName: "",
                reach: 0,
                percent: 0,
                locations: [],
                budget: 0,
            }]
            // reach: "10,000",
            // impression: "15,000"
        })
    const [selectedDate, setSelectedDate] = useState(new Date())
    const [isChrome, setIsChrome] = useState(true)
    const context = useContext(AppContextObject)
    const [showMoreLocations, setShowMoreLocations] = useState(false)
    const [overlay, setOverlay] = useState(false)
    const [brandCreationDate, setBrandCreationDate] = useState("")
    useEffect(() => {
        console.log("selectedDate......", selectedDate)
        getData()
    }, [selectedDate])

    useEffect(() => {
        setSelectedDate(new Date())
    }, [])

    const getData = async () => {
        const resp = await context.apis.public.getBrandCampaignReachStats(
            context.validator, { brandId: context.user.brand, month: moment(selectedDate).format("MM-YYYY") },
        )
        if (resp && resp.done) {
            let sum = resp.response.reachByCampaign.reduce((prev, current) => prev = prev + current.reach, 0)
            let percentagesReach = []
            resp.response.reachByCampaign.map((data, idx) => {
                let temp = {
                    campaignName: data.campaignName,
                    reach: data.reach,
                    budget: data.budget,
                    locations: data.locations,
                    percent: data.reach == 0 ? 0 : (data.reach / sum) * 100
                }
                percentagesReach.push(temp)
            })


            let allData = {
                ongoingCampaigns: resp.response.ongoingCampaigns,
                schools: resp.response.schools,
                locations: resp.response.locations.filter((val, id, array) => array.indexOf(val) == id), //resp.response.locations,
                totalReach: resp.response.totalReach,
                reachByCampaign: percentagesReach
            }
            setBrandCreationDate(moment(resp.response.brandCreationDate).format("YYYY-MM"))
            setCampaignReachData(allData)
        }
        console.log("getBrandCampaignReachStats response...", resp)
    }
    let colors = ["#59BBD6", "#D6DA28", "#D32F27", "#362F87"]
    const openModalTwoMore = () => {
        setShowMoreLocations(true)
        setOverlay(true)


    }
    const closeWithoutSaving = () => {
        setShowMoreLocations(false)
        setOverlay(false)
    }

    const calPercentageBar = (position) => {

        let sum = 0;
        campaignReachData.reachByCampaign.map((data, idx) => {
            if (idx >= position) {
                sum = sum + data.percent
            }
        })
        return sum
    }

    const handleMonthChange = (e) => {
        if (e && e.target && e.target.name === "month") {
            setSelectedDate(e.target.value)
        }
        else {
            setSelectedDate(e)
        }
    }

    useEffect(() => {
        const browser = detect();
        if (browser) {
            // console.log("browser...", browser.name)
            setIsChrome(browser.name === "chrome")
        }
    }, [])
    return (
        <>
            <div className={`ovelap ${overlay === true ? "active" : ""}`}></div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-xs-12"><h2 className="htx2 clearfix">Campaign Reach</h2></div>
            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-20">
                <div className="box-design">
                    
                    <div className="col-lg-3 col-md-4 col-xs-12">
                        <div className="inventory-overTabs01R">
                           {
                               isChrome
                               ?
                               <input  
                               type="month" 
                               name="month" 
                               value={moment(selectedDate).format("YYYY-MM").toString()} 
                               min={brandCreationDate} 
                               onChange={(e) => handleMonthChange(e)} />
                               :
                               <DatePicker
                               dateFormat="MMMM yyyy"
                               showMonthYearPicker
                               selected={selectedDate}
                               onChange={handleMonthChange}
                               minDate={new Date(brandCreationDate)}
                             />
                           }
                            
                        </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-xs-12 mt-10"></div>
                    {
                    campaignReachData.ongoingCampaigns && campaignReachData.ongoingCampaigns != "0" ?
                    <div className="row">
                        <div className="col-lg-4 col-md-4 col-xs-12">
                            <div className="inventory-overHead overheadFour">
                                {/* <p>Campaigns Running</p> */}
                                <p>Ongoing Campaigns</p>
                                <h3>{campaignReachData.ongoingCampaigns}
                                    {/* <span className="w-auto"><b>12% <img src="/static/media/arrow-up.02c44f55.svg" /></b> than last 30 days</span> */}
                                </h3>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-xs-12">
                            <div className="inventory-overHead overheadFour">
                                {/* <p>Schools Occupied</p> */}
                                <p>No. of Schools Engaged</p>
                                <h3>{campaignReachData.schools}</h3>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-xs-12">
                            <div className="inventory-overHead overheadFour">
                                <p>Locations</p>
                                <h3> {campaignReachData.locations.length == 0 ? "-" :
                                    campaignReachData.locations.map((item, index) => index > 2 ? "" : index === 2 || index === campaignReachData.locations.length - 1 ? titleCase(item) : `${titleCase(item)}, `)}
                                    {(campaignReachData.locations.length > 3) ?
                                        <a id="openModal" href='javascript:void(0)' onClick={openModalTwoMore} className=''
                                            data-target='inventoryMore'> {campaignReachData.locations.length - 3} more</a> : ""
                                    }
                                </h3>
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-xs-12 mt-30">
                            <h2 className="htx3 clearfix">Campaign Performance</h2>
                        </div>
                        <div className="col-lg-4 col-md-4 col-xs-12">
                            <div className="inventory-overHead overheadFour">
                                <p>Total Estimated Reach</p>
                                <h3>{campaignReachData.totalReach.toLocaleString('en-IN')}  </h3>
                            </div>
                        </div>

                        <div className="col-lg-12 col-md-12 col-xs-12 mt-20">

                            <div className="campaign-reach">
                                {
                                    campaignReachData.reachByCampaign.map((data, idx) => {
                                        return (
                                            // <div className="campaign-color active" style={{background: colors[idx], width: `${calPercentageBar(idx)}%`, zIndex: campaignReachData.reachByCampaign.length - 1 }}>
                                            <div className="campaign-color active" style={{ background: colors[idx % (colors.length)], width: `${data.percent}%`, zIndex: idx }}>
                                                {/* <span className="campaign-reach-40"></span> */}
                                                <div className={` tooltipdash ${idx === (campaignReachData.reachByCampaign.length - 1) ? "tooltipdashright" : ""}`} style={{ display: "none" }}>
                                                    <h4>{data.campaignName}</h4>
                                                    <p>Reach: {data.reach}</p>
                                                    <p>Budget: {data.budget}</p>
                                                    <p>Location:
                                                    {data.locations.length == 0 ? "NA" :
                                                            titleCase(data.locations[0])}
                                                        {(data.locations.length > 1) ?
                                                            <a id="openModal" href='javascript:void(0)' onClick={openModalTwoMore} className=''
                                                                data-target='inventoryMore'> +{data.locations.length - 1} more</a> : ""
                                                        }
                                                    </p>
                                                </div>
                                            </div>

                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    :
                    <div id="campaignReachEmptyCard" className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                    <div className="noData height-one mt-20"> 
                        <h3>There are no ongoing campaigns this month.</h3>
                    </div>
                    </div>
                    }
                </div>
            </div>
            <div id="see-location" className={`popup ${showMoreLocations === false ? "hide" : ""}`}>
                <div className="popup-body">
                    <span className="close toggle" onClick={closeWithoutSaving} data-target="myPopup">close</span>
                    <div className="invetorypopblock">
                        <div className="schoolNameTable">
                            <h4>Locations</h4>
                            {campaignReachData.locations.map((item, index) => (
                                <span>{titleCase(item)}</span>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default CampaignReachDashboard