
import { useState, useContext, useEffect, useRef } from "react"
// import search from "../../../../assets/images/search.svg"
import search from "../../../assets/images/search.svg"
import notification from "../../../assets/images/noti-bell.svg"
import OnboardingBackBtn from '../schools/components/onboardingBackBtn'
import backBtn from "../../../assets/images/back-arrow.svg"
import proEdit from "../../../assets/images/edit-profile.svg"
import addAdmin from "../../../assets/images/add-admin1.svg"
import studentImgbg from "../../../assets/images/student-imgbg.png"
import watchVideo from "../../../assets/images/watch-video.png"
import faqArrow from "../../../assets/images/faq-arrow.svg"
import AppContextObject from "../../../common/context/common"
import SuccessDialog from "../../../components/addon/successDialog"
import DataContext from "../../../service/brandContext"

import ReactPlayer from 'react-player'
import BrandTopBar from "./components/brandTopBar"
import { DebounceInput } from 'react-debounce-input'
import BrandBackBtn from "./components/brandBackBtn"
import CustomSearchInput from "../../../components/formFields/customSearchInput"
import VideoImageCarousel from "./components/videImageCarousel"

const BrandsSupport = () => {
    const context = useContext(AppContextObject)
    const parentData = useContext<any>(DataContext)
    const [inventoryList, setSupportList] = useState([])
    const [searchValue, setSearchValue] = useState("")
    const [sortingValue, setSortingValue] = useState(true)
    const [refetch, setRefetch] = useState(false)
    const [allQuestions, setAllQuestions] = useState([])
    const [campaignSetupQuestions, setCampaignSetupQuestions] = useState([])
    const [campaignExecution, setCampaignExecutionQuestions] = useState([])
    const [reportingQuestions, setReportingQuestions] = useState([])
    const [financeQuestions, setFinanceQuestions] = useState([])
    const [singUpAndOnboardingQuestions, setSingUpAndOnboardingQuestions] = useState([])
    const [questionTab, setQuestionTab] = useState(1)
    const [userQuestion, setUserQuestion] = useState("")
    const [showSuccessModal, setShowSuccessModal] = useState(false)
    const [videoLoading, setVideoLoading] = useState(true)
    const [videoUrl, setVideoUrl] = useState("")
    const [searchInpuOpen, setSearchInpuOpen] = useState(false)
    const [clearSearchIcon, setClearSearchIcon] = useState(false)
    const [category, setCategory] = useState("all")
    const [submitingQuestion, setSubmitingQuestion] = useState(false)

    useEffect(() => {
        if(parentData){
            parentData.setShowSidemenu(true)
        }
        getQuestions()
        getWisrVideo()
    }, [])

    useEffect(() => {
        getQuestions()
    }, [category])
    
    const getWisrVideo = async () =>{
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({type: 'brand'})
        };

        fetch("/api/getUploadedVideo", requestOptions)
        .then(response => response.json())
        .then(async data => {
            setVideoUrl(data.result.response.image)
        }).catch(err=>{
            console.log(err);
        })
    }
    const getQuestions = async() => {
        let allQuestionResp = await context.apis[context.user.role].listFAQ(
            context.validator, {searchValue: searchValue, type: "brand"}, {}
            )
        let campaignSetUpQuestionResp = await context.apis[context.user.role].listFAQ(
            context.validator, {searchValue: searchValue, category: "campaignSetup", type: "brand"}, {}
            )
        let campaignExecutionQuestionResp = await context.apis[context.user.role].listFAQ(
            context.validator, {searchValue: searchValue, category: "campaignExecution", type: "brand"}, {}
            )
        let reportingQuestionResp = await context.apis[context.user.role].listFAQ(
            context.validator, {searchValue: searchValue, category: "reporting", type: "brand"}, {}
            )
        let financeQuestionResp = await context.apis[context.user.role].listFAQ(
            context.validator, {searchValue: searchValue, category: "finance", type: "brand"}, {}
            )
        let signUpQuestionResp = await context.apis[context.user.role].listFAQ(
            context.validator, {searchValue: searchValue, category: "signUp&Onboarding", type: "brand"}, {}
            )
        if(allQuestionResp && allQuestionResp.done && allQuestionResp.response.rows){
            setAllQuestions(allQuestionResp.response.rows)
        }
        if(campaignSetUpQuestionResp && campaignSetUpQuestionResp.done && campaignSetUpQuestionResp.response.rows){
            setCampaignSetupQuestions(campaignSetUpQuestionResp.response.rows)
        }
        if(campaignExecutionQuestionResp && campaignExecutionQuestionResp.done && campaignExecutionQuestionResp.response.rows){
            setCampaignExecutionQuestions(campaignExecutionQuestionResp.response.rows)
        }
        if(reportingQuestionResp && reportingQuestionResp.done && reportingQuestionResp.response.rows){
            setReportingQuestions(reportingQuestionResp.response.rows)
        }
        if(financeQuestionResp && financeQuestionResp.done && financeQuestionResp.response.rows){
            setFinanceQuestions(financeQuestionResp.response.rows)
        } 
        if(signUpQuestionResp && signUpQuestionResp.done && signUpQuestionResp.response.rows){
            setSingUpAndOnboardingQuestions(signUpQuestionResp.response.rows)
        }
    }

    const handleSearch = async (e) =>{
        if(e === ""){
            setSearchValue("")
        }
        else{
            setSearchValue(e.target.value.trim())
        }
        let resp = await context.apis[context.user.role].listFAQ(
            context.validator, {searchValue:  e === "" ? "" : e.target.value, category, type: "brand"}, {}
            )
        if(resp && resp.done && resp.response.rows){
            if(questionTab === 1)
                setAllQuestions(resp.response.rows)
            else if(questionTab === 2)
                setCampaignSetupQuestions(resp.response.rows)
            else if(questionTab === 3)
                setCampaignExecutionQuestions(resp.response.rows)
            else if(questionTab === 4)
                setReportingQuestions(resp.response.rows)
            else if(questionTab === 5)
                setFinanceQuestions(resp.response.rows)
            else if(questionTab === 6)
                setSingUpAndOnboardingQuestions(resp.response.rows)
        }
    }

    const handleClick = (e, index) => {
        const faqs = document.getElementsByClassName("faq-tablist")
        for(let i=0; i<faqs.length; i++){
            if(i !== index){
                faqs[i].classList.remove("active")
            }
        }
        let parentElement = e.target.parentNode
        if(e.target.id === "img"){
            for(let j=0; j<2; j++){
                parentElement = parentElement.parentNode
            }
            if(parentElement.classList.contains("active")){
                parentElement.classList.remove("active")
            }
            else{
                parentElement.classList.add("active")
            }
        }
        else if(e.target.id === "anchor"){
            parentElement = parentElement.parentNode
            if(parentElement.classList.contains("active")){
                parentElement.classList.remove("active")
            }
            else{
                parentElement.classList.add("active")
            }
        }
    }
    const submitUserQuestion = async() => {
        if(userQuestion === "" || submitingQuestion){
            return
        }
        setSubmitingQuestion(true)
        let resp = await context.apis[context.user.role].saveUserQuery(
            context.validator, {query: userQuestion, userType: "brand"}, {}
            )
        if(resp && resp.done){
            setUserQuestion("")
            setShowSuccessModal(true)
            setSubmitingQuestion(false)
        }
    }
    const closeSuccessModal = () => {
        setShowSuccessModal(false)
    }

    const onVideoReady = () => {
        setVideoLoading(false)
    }

    const handleTabChange = (tabNo, type) => {
        setQuestionTab(tabNo)
        setCategory(type)
    }

    const data = [
        {
          id: 1,
          title: "School video",
          video: 'hq7os19Y_dg',
          credit: "Video by cottonbro from Pexels",
        },
        {
          id: 2,
          title: "SFA video",
          video: 'YwfvKLUMEVU',
          credit: "Video by cottonbro from Pexels",
        }
    ];

    return (
        <>
        <div className={`ovelap ${showSuccessModal === true ? "active" : ""}`}></div>
            <div className="col-xl-10 col-lg-12 col-md-12 col-xs-12">
                <div className="rightPart noBack">
                <div className="row">
                <div className="col-xs-12 col-lg-12 col-md-12 col-xs-12">
                           <BrandBackBtn
                                link="/"
                                text="Log Out"
                            />
                </div>
                    <BrandTopBar />
                    <div className="col-xl-9 col-lg-12 col-md-12 col-xs-12 mt-20">
                        <h2 className="htx2 clearfix mb30">Watch how to make the most of  WISR!</h2>  
                        <div>
                            {/* {
                                videoLoading === true &&
                                <p>Loading Video....</p>
                            }
                            <ReactPlayer
                                url={videoUrl}
                                controls={true}
                                width={1123}
                                height={462}
                                onReady={() => onVideoReady()}
                            /> */}
                            {/* <img src={watchVideo} /> */}
                            <VideoImageCarousel data={data} supportPage={true} brand={false} />
                        </div>
                    </div>
                    <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-50"></div>
                    <div className="col-xl-9 col-lg-12 col-md-12 col-xs-12 mb-50 mt-10">
                        <div className="have-question">
                            <h2 className="htx2 clearfix">Have Questions?</h2>
                            <p className="ptx2">Send in your queries below and someone from our team will resolve them within 24 hours.</p>
                            <div className="forminpt">
                                <textarea placeholder="Enter text here" maxLength={500} value={userQuestion} onChange={(e) => setUserQuestion(e.target.value)}></textarea>
                                <small>{`${userQuestion.length}/500`}</small>
                            </div>
                            <div className="forminpt"><a href="javascript:void(0);" className={`btn btn-orange ${submitingQuestion ? "button--loading" : ""}`} onClick={submitUserQuestion}>Submit</a></div>
                        </div>
                    </div>
                    <div className="col-xl-9 col-lg-12 col-md-12 col-xs-12 mt-30">
                        <h2 className="htx2 clearfix">FAQs</h2>
                        <div className="faq-view">
                            <ul className="tabsinventory" style={{width: "calc(100% - 50px)"}}>
                                <li onClick={() => handleTabChange(1, "all")}><a href="javascript:void(0);" className={questionTab === 1 ? "active" : ""}>All</a></li>
                                <li onClick={() => handleTabChange(2,"campaignSetup")}><a href="javascript:void(0);" className={questionTab === 2 ? "active" : ""}>{`Campaign Setup(${campaignSetupQuestions.length})`}</a></li>
                                <li onClick={() => handleTabChange(3, "campaignExecution")}><a href="javascript:void(0);" className={questionTab === 3 ? "active" : ""}>{`Campaign Execution(${campaignExecution.length})`}</a></li>
                                <li onClick={() => handleTabChange(4, "reporting")}><a href="javascript:void(0);" className={questionTab === 4 ? "active" : ""}>{`Reporting(${reportingQuestions.length})`}</a></li>
                                <li onClick={() => handleTabChange(5, "finance")}><a href="javascript:void(0);" className={questionTab === 5 ? "active" : ""}>{`Finance(${financeQuestions.length})`}</a></li>
                                <li onClick={() => handleTabChange(6, "signUp&Onboarding")}><a href="javascript:void(0);" className={questionTab === 6 ? "active" : ""}>{`Sign-up and onboarding(${singUpAndOnboardingQuestions.length})`}</a></li>
                            </ul>
                            <div className="inventorySearch">
                                {/* <div className="searchinput">
                                    <a href="javascript:void(0);"><img src={search}/></a> 
                                    <DebounceInput debounceTimeout={500} type="text" placeholder="Search" value={searchValue} onChange={handleSearch} />
                                </div> */}
                                <CustomSearchInput
                                    searchInpuOpen={searchInpuOpen}
                                    setSearchInpuOpen={setSearchInpuOpen}
                                    clearSearchIcon={clearSearchIcon}
                                    setClearSearchIcon={setClearSearchIcon}
                                    searchKeys={searchValue}
                                    handleChange={handleSearch}
                                    palaceholder="Search"
                                />
                            </div>
                            <div className="faq-tabs-list">
                                {questionTab === 1 && 
                                    (allQuestions.length !== 0 ? allQuestions.map((question, index) => (
                                            <div key={index} className={index === 0 ? "faq-tablist active" : "faq-tablist"}>
                                                <h4><a id="anchor" style={{cursor: "pointer"}} onClick={(e) => handleClick(e, index)}>{`Q${index+1} ${question.question}`} <img id="img" src={faqArrow} /></a></h4>
                                                <div className="faq-tablistView">
                                                    <p>{question.answer}</p>
                                                </div>
                                            </div>
                                        ))
                                        :
                                        <div>No data found</div>
                                    )
                                }
                                {questionTab === 2 && 
                                    (campaignSetupQuestions.length !== 0 ? campaignSetupQuestions.map((question, index) => (
                                            <div key={index} className={index === 0 ? "faq-tablist active" : "faq-tablist"}>
                                                <h4><a id="anchor" style={{cursor: "pointer"}} onClick={(e) => handleClick(e, index)}>{`Q${index+1} ${question.question}`} <img id="img" src={faqArrow} /></a></h4>
                                                <div className="faq-tablistView">
                                                    <p>{question.answer}</p>
                                                </div>
                                            </div>
                                        ))
                                        :
                                        <div>No data found</div>
                                    )
                                }
                                {questionTab === 3 && 
                                    (campaignExecution.length !== 0 ? campaignExecution.map((question, index) => (
                                            <div key={index} className={index === 0 ? "faq-tablist active" : "faq-tablist"}>
                                                <h4><a id="anchor" style={{cursor: "pointer"}} onClick={(e) => handleClick(e, index)}>{`Q${index+1} ${question.question}`} <img id="img" src={faqArrow} /></a></h4>
                                                <div className="faq-tablistView">
                                                    <p>{question.answer}</p>
                                                </div>
                                            </div>
                                        ))
                                        :
                                        <div>No data found</div>
                                    )
                                }
                                {questionTab === 4 && 
                                    (reportingQuestions.length !== 0 ? reportingQuestions.map((question, index) => (
                                            <div key={index} className={index === 0 ? "faq-tablist active" : "faq-tablist"}>
                                                <h4><a id="anchor" style={{cursor: "pointer"}} onClick={(e) => handleClick(e, index)}>{`Q${index+1} ${question.question}`} <img id="img" src={faqArrow} /></a></h4>
                                                <div className="faq-tablistView">
                                                    <p>{question.answer}</p>
                                                </div>
                                            </div>
                                        ))
                                        :
                                        <div>No data found</div>
                                    )
                                }
                                {questionTab === 5 && 
                                    (financeQuestions.length !== 0 ? financeQuestions.map((question, index) => (
                                            <div key={index} className={index === 0 ? "faq-tablist active" : "faq-tablist"}>
                                                <h4><a id="anchor" style={{cursor: "pointer"}} onClick={(e) => handleClick(e, index)}>{`Q${index+1} ${question.question}`} <img id="img" src={faqArrow} /></a></h4>
                                                <div className="faq-tablistView">
                                                    <p>{question.answer}</p>
                                                </div>
                                            </div>
                                        ))
                                        :
                                        <div>No data found</div>
                                    )
                                }
                                {questionTab === 6 && 
                                    (singUpAndOnboardingQuestions.length !== 0 ? singUpAndOnboardingQuestions.map((question, index) => (
                                            <div key={index} className={index === 0 ? "faq-tablist active" : "faq-tablist"}>
                                                <h4><a id="anchor" style={{cursor: "pointer"}} onClick={(e) => handleClick(e, index)}>{`Q${index+1} ${question.question}`} <img id="img" src={faqArrow} /></a></h4>
                                                <div className="faq-tablistView">
                                                    <p>{question.answer}</p>
                                                </div>
                                            </div>
                                        ))
                                        :
                                        <div>No data found</div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    
                </div>
                </div>
            </div>  
            <SuccessDialog 
                confirmTitle={"Message submitted successfully"}
                componentName={"Thank You! Our team member will get in touch with you shortly. "}
                confirmText={"Done"}
                togglePopup={showSuccessModal}
                onModalClose={closeSuccessModal}
            />
        </>
    )
}

export default BrandsSupport
