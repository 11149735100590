import React, { useContext, useState, useEffect } from 'react'
import {
    Route,
    useHistory,
    useRouteMatch,
    Switch,
    useLocation,
} from "react-router-dom"
import { DataContextProvider } from "../../../../../service/contextProvider"
import AppContextObject from "../../../../../common/context/common"
import SetUpNonWisrCampaign from './setUpNonWisrCampaign'
import DataContext from '../../../../../service/backendContext'
import Loader from '../../../../../components/addon/loader'
import CampaignInventory from './nonWisrCampaignInventory'
import CampaignEvents from './nonWisrCampaignEvent'
import CampaignRecomendedSchool from './nonWisrRecommendedSchool'
// import CampaignSchool from './campaignSchool'
import CampaignCreative from './nonWisrCampaignCreative'
// import Confirmation from './confirmation'
import CampaignPreview from './nonWisrCampaignPreview'
// import ViewCampaignProgress from '../../components/viewCampaignProgress'
import NonWisrCampaignProgress from './components/nonWisrCampaignProgress'

const NonWisrCreateCampaign = () => {
    const context = useContext(AppContextObject)
    const history = useHistory()
    const location = useLocation()
    const router = useRouteMatch()
    const parentData = useContext<any>(DataContext)
    const [showProgressSideMenu, setShowProgressSideMenu] = useState(false)
    const [loading, setLoading] = useState(false)
    const [isTrailCampaign, setIsTrailCampaign] = useState(false)
    const [isCreativeRework, setIsCreativeRework] = useState(false)
    const [progress, setProgress] = useState({
        brandDetails: false,
        inventory: false,
        event: false,
        school: false,
        recommendedSchool: false,
        campaignCreative: false,
        preview: false
    })
    const [activeModule, setActiveModule] = useState({
        module: "",
        subModule: ""
    })

    useEffect(() => {
        if(parentData && parentData.setShowSidemenu){
            parentData.setShowSidemenu(false)
        }
    }, [])
    useEffect(() => {
        window.addEventListener("beforeunload", handleBeforeUnload)
        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload)
        }
    }, [])
    useEffect(() => {
        if(history.location.state){
            let data:any = history.location.state
            if(data.campaignId){
                console.log(data.campaignId)
                // getCampaignData(data.campaignId)
            }
        }
        else{
            setLoading(true)
            parentData.setNonWisrCampaignData({
                brandDetail: {
                    campaign: {name: "", _id: ""},
                    startDate: "",
                    endDate: "",
                    description: "",
                    objective: "",
                    inventory: false,
                    eventAndOccations: false,
                    csr: false,
                    sampling: false,
                    custom: false,
                    // budget: 1,
                    targetGender: "both",
                    targetAgeGroup: {min: 3, max: 4},
                    studyMedium: [],
                    brandCategory: {value: "", label: ""},
                    brandSubcategory: {value: "", label: ""},
                    brand: {_id: "", name: "", label: "", type: "", value: ""},
                    state: [],
                    city: [],
                    campaignNameOptions: [],
                    brandNameOptions: [],
                    isTrial: false
                },
                algoData :{
                    classroomData: [],
                    inventoryData: [],
                    eventData: [],
                    schoolData: [],
                    noOfDaysInYear: 0,
                    percentageDiscountInWISR: 0,
                    percentageIncreaseInReach: 0,
                    totalInternalCostPerSchool: 0,
                    budgetRatio: { CatA: 0, CatB: 0, CatC: 0 }
                },
                campaignDetail: {
                    wisrOption: true,
                    totalImpression: 0,
                    totalReach: 0,
                    totalBudget: 0,
                    boysRatio: 0,
                    girlsRatio: 0
                },
                campaignCreative:{
                    creatives: {image1: {}, image2: {}, image3: {}}
                },
                inventory: {
                    inventories: [],
                    notUsedInventories: [],
                    usedInventories: []
                },
                school: {
                    schools: [],
                    locations: []
                },
                eventAndOccation: {
                    eventAndOccations: [],
                    usedEvents: []
                },
                schoolListDetail: []
            })
            setLoading(false)
        }
        // history.push("/backend/backend-details/nonWisr-create-campaign/set-up-nonWisr-campaign")
    }, [])
    useEffect(() => {
        if(history.location.state){
            let data:any = history.location.state
            // if(data.campaignId){
            //     console.log(data.campaignId)
            //     getCampaignData(data.campaignId)
            // }
            // if(data.campaignType){
            //     setLoading(true)
            //     setIsTrailCampaign(true)
            //     parentData.setData({
            //         brandDetail: {
            //             name: "",
            //             startDate: "",
            //             endDate: "",
            //             description: "",
            //             objective: "",
            //             inventory: false,
            //             eventAndOccations: false,
            //             csr: false,
            //             sampling: false,
            //             custom: false,
            //             // budget: 1,
            //             targetGender: "both",
            //             targetAgeGroup: {min: 3, max: 4},
            //             studyMedium: [],
            //             brandCategory: {value: "", label: ""},
            //             brandSubcategory: {value: "", label: ""},
            //             brandName: "",
            //             state: [],
            //             city: [],
            //             brandNameLabel: "",
            //             isTrial: true
            //         },
            //         algoData :{
            //             classroomData: [],
            //             inventoryData: [],
            //             eventData: [],
            //             schoolData: [],
            //             noOfDaysInYear: 0,
            //             percentageDiscountInWISR: 0,
            //             percentageIncreaseInReach: 0,
            //             totalInternalCostPerSchool: 0,
            //             budgetRatio: { CatA: 0, CatB: 0, CatC: 0 }
            //         },
            //         campaignDetail: {
            //             wisrOption: true,
            //             totalImpression: 0,
            //             totalReach: 0,
            //             totalBudget: 0,
            //             boysRatio: 0,
            //             girlsRatio: 0
            //         },
            //         campaignCreative:{
            //             creatives: {image1: {}, image2: {}, image3: {}}
            //         },
            //         inventory: {
            //             inventories: [],
            //             notUsedInventories: [],
            //             usedInventories: []
            //         },
            //         school: {
            //             schools: [],
            //             locations: []
            //         },
            //         eventAndOccation: {
            //             eventAndOccations: [],
            //             usedEvents: []
            //         },
            //         schoolListDetail: []
            //     })
            //     setLoading(false)
            // }
        }
        else{
            setLoading(true)
            parentData.setNonWisrCampaignData({
                brandDetail: {
                    campaign: {name: "", _id: ""},
                    startDate: "",
                    endDate: "",
                    description: "",
                    objective: "",
                    inventory: false,
                    eventAndOccations: false,
                    csr: false,
                    sampling: false,
                    custom: false,
                    // budget: 1,
                    targetGender: "both",
                    targetAgeGroup: {min: 3, max: 4},
                    studyMedium: [],
                    brandCategory: {value: "", label: ""},
                    brandSubcategory: {value: "", label: ""},
                    brand: {_id: "", name: "", label: "", type: "", value: ""},
                    state: [],
                    city: [],
                    campaignNameOptions: [],
                    brandNameOptions: [],
                    isTrial: false
                },
                algoData :{
                    classroomData: [],
                    inventoryData: [],
                    eventData: [],
                    schoolData: [],
                    noOfDaysInYear: 0,
                    percentageDiscountInWISR: 0,
                    percentageIncreaseInReach: 0,
                    totalInternalCostPerSchool: 0,
                    budgetRatio: { CatA: 0, CatB: 0, CatC: 0 }
                },
                campaignDetail: {
                    wisrOption: true,
                    totalImpression: 0,
                    totalReach: 0,
                    totalBudget: 0,
                    boysRatio: 0,
                    girlsRatio: 0
                },
                campaignCreative:{
                    creatives: {image1: {}, image2: {}, image3: {}}
                },
                inventory: {
                    inventories: [],
                    notUsedInventories: [],
                    usedInventories: []
                },
                school: {
                    schools: [],
                    locations: []
                },
                eventAndOccation: {
                    eventAndOccations: [],
                    usedEvents: []
                },
                schoolListDetail: []
            })
            setLoading(false)
        }
        // history.push("/backend/backend-details/nonWisr-create-campaign/set-up-nonWisr-campaign")
    }, [])
    const handleBeforeUnload = (e) => {
        if(context && context.inactive){
            window.onbeforeunload = null
            return true
        }
        const message = "Are you sure you want to reload the page? All provided data will be lost."
        e.returnValue = message
        return message
    }

    useEffect(() => {
        if(history.location.state){
            let data: any = history.location.state
            if(data.creativeRework){
                return
            }
        }
        switch (location.pathname){
            case "/backend/backend-details/nonWisr-create-campaign/nonWisr-campaign-inventory":
                if(parentData.nonWisrCampaignProgress.brandDetails === false){
                    history.push('/backend/backend-details/nonWisr-create-campaign/set-up-nonWisr-campaign')
                }
                break
            case "/backend/backend-details/nonWisr-create-campaign/nonWisr-campaign-event":
                if(parentData?.nonWisrCampaignData?.brandDetail?.inventory === true){
                    if(parentData.nonWisrCampaignProgress.inventory === false){
                        history.push('/backend/backend-details/nonWisr-create-campaign/set-up-nonWisr-campaign')
                    }
                }
                else{
                    if(parentData.nonWisrCampaignProgress.brandDetails === false){
                        history.push('/backend/backend-details/nonWisr-create-campaign/set-up-nonWisr-campaign')
                    }
                }
                break
            case "/backend/backend-details/nonWisr-create-campaign/nonWisr-campaign-school":
                if(parentData?.nonWisrCampaignData?.brandDetail?.eventAndOccations === true){
                    if(parentData.nonWisrCampaignProgress.event === false){
                        history.push('/backend/backend-details/nonWisr-create-campaign/set-up-nonWisr-campaign')
                    }
                }
                else{
                    if(parentData?.nonWisrCampaignData?.brandDetail?.inventory === true){
                        if(parentData.nonWisrCampaignProgress.inventory === false){
                            history.push('/backend/backend-details/nonWisr-create-campaign/set-up-nonWisr-campaign')
                        }
                    }
                    else{
                        if(parentData.nonWisrCampaignProgress.brandDetails === false){
                            history.push('/backend/backend-details/nonWisr-create-campaign/set-up-nonWisr-campaign')
                        }
                    }
                }
                break
            case "/backend/backend-details/nonWisr-create-campaign/nonWisr-campaign-creative":
                if(parentData.nonWisrCampaignProgress.recommendedSchool === false){
                    history.push('/backend/backend-details/nonWisr-create-campaign/set-up-nonWisr-campaign')
                }
                break
            case "/backend/backend-details/nonWisr-create-campaign/nonWisr-campaign-preview":
                if(parentData.nonWisrCampaignProgress.campaignCreative === false){
                    history.push('/backend/backend-details/nonWisr-create-campaign/set-up-nonWisr-campaign')
                }
                break
            // case "/backend/backend-details/nonWisr-create-campaign/confirm":
            //     if(parentData.nonWisrCampaignProgress.preview === false){
            //         history.push('/brands/brand-details/campaigns')
            //     }
            //     break
            // case "/brands/brand-details/create-trial-campaign/inventory":
            //     if(parentData.nonWisrCampaignProgress.brandDetails === false){
            //         history.push('/brands/brand-details/create-trial-campaign/set-up-nonWisr-campaign')
            //     }
            //     break
            // case "/brands/brand-details/create-trial-campaign/events":
            //     if(parentData.nonWisrCampaignProgress.inventory === false){
            //         history.push('/brands/brand-details/create-trial-campaign/set-up-nonWisr-campaign')
            //     }
            //     break
            // case "/brands/brand-details/create-trial-campaign/schools":
            //     if(parentData?.nonWisrCampaignData?.brandDetail?.eventAndOccations === true){
            //         if(parentData.nonWisrCampaignProgress.event === false){
            //             history.push('/brands/brand-details/create-trial-campaign/set-up-nonWisr-campaign')
            //         }
            //     }
            //     else{
            //         if(parentData.nonWisrCampaignProgress.inventory === false){
            //             history.push('/brands/brand-details/create-trial-campaign/set-up-nonWisr-campaign')
            //         }
            //     }
            //     break
            // case "/brands/brand-details/create-trial-campaign/recommended-school":
            //     if(parentData.nonWisrCampaignProgress.school === false){
            //         history.push('/brands/brand-details/create-trial-campaign/set-up-nonWisr-campaign')
            //     }
            //     break
            // case "/brands/brand-details/create-trial-campaign/creative":
            //     if(parentData.nonWisrCampaignProgress.recommendedSchool === false){
            //         history.push('/brands/brand-details/create-trial-campaign/set-up-nonWisr-campaign')
            //     }
            //     break
            // case "/brands/brand-details/create-trial-campaign/preview":
            //     if(parentData.nonWisrCampaignProgress.campaignCreative === false){
            //         history.push('/brands/brand-details/create-trial-campaign/set-up-nonWisr-campaign')
            //     }
            //     break
            // case "/brands/brand-details/create-trial-campaign/confirm":
            //     if(parentData.nonWisrCampaignProgress.preview === false){
            //         history.push('/brands/brand-details/campaigns')
            //     }
            //     break
        }
    }, [location.pathname])
    return (
        <>
            <div className="main-wapper">
                <div className="container">
                {loading ? 
                    <Loader />
                    :
                    <div className="row">
                        {showProgressSideMenu &&
                            <NonWisrCampaignProgress />
                        }
                        <Switch>
                            <Route path={`${router.url}/set-up-nonWisr-campaign`}>
                                <SetUpNonWisrCampaign
                                    setShowProgressSideMenu={setShowProgressSideMenu}
                                />
                            </Route>
                            <Route path={`${router.url}/nonWisr-campaign-inventory`}>
                                <CampaignInventory
                                    setShowProgressSideMenu={setShowProgressSideMenu}
                                />
                            </Route>
                            <Route path={`${router.url}/nonWisr-campaign-event`}>
                                <CampaignEvents
                                    setShowProgressSideMenu={setShowProgressSideMenu}
                                />
                            </Route>
                            <Route path={`${router.url}/nonWisr-campaign-school`}>
                                <CampaignRecomendedSchool
                                    setShowProgressSideMenu={setShowProgressSideMenu}
                                />
                            </Route>
                            <Route path={`${router.url}/nonWisr-campaign-creative`}>
                                <CampaignCreative
                                    setShowProgressSideMenu={setShowProgressSideMenu}
                                />
                            </Route>
                            <Route path={`${router.url}/nonWisr-campaign-preview`}>
                                <CampaignPreview
                                    setShowProgressSideMenu={setShowProgressSideMenu}
                                />
                            </Route>
                        </Switch>
                    </div>
                }
                </div>
            </div>
        </>
    )
}

export default NonWisrCreateCampaign