import { useState, useContext, useEffect, useRef } from "react"

import { Link, useHistory } from "react-router-dom"
// import OnGoingCampaigns from "./onGoingCampaigns"
import Pagination from "../../../../../components/addon/pagination"
import arrowView from "../../../../../assets/images/arrow-view.svg"
import arrowViewRound from "../../../../../assets/images/arrow-view-round.svg"
import tableFilter from "../../../../../assets/images/table-filter.svg"
import TopBar from "../../../schools/components/topBar"
import AppContextObject from "../../../../../common/context/common"
import { formatDateShowMonth } from "../../../../../service/helper"
import DataContext from "../../../../../service/backendContext"
import SimpleReactValidator from "simple-react-validator"
import errorSVG from "../../../../../assets/images/error.svg"

const CampaignInventory = ({ setShowProgressSideMenu }) => {
    const history = useHistory()
    const context = useContext(AppContextObject)
    const [formValidator] = useState(useRef(new SimpleReactValidator({
        element: (message, className) =>
            <span className="valError"><img src={errorSVG} />{message}</span>
        })
    ))
    const [update, forceUpdate] = useState(false)
    const parentData = useContext<any>(DataContext)
    const [formData, setFormData] = useState(parentData.data.inventory)
    const [recomendedInventoryList, setRecomendedInventoryList] = useState([])
    const [allInventoryList, setAllInventoryList] = useState([])
    const [customInventoryList, setCustomInventoryList] = useState([])
    const [customClassRoomInventory, setCustomClassRoomInventory] = useState([])
    const [customOtherRoomInventory, setCustomOtherRoomInventory] = useState([])
    const [customCommonAreaInventory, setCustomCommonAreaInventory] = useState([])
    const [customOutdoorInventory, setCustomOutdoorInventory] = useState([])
    // const [inventoryCategoryList, setInventoryCategoryList] = useState([])
    const [classRoomInventoryList, setClassRoomInventoryList] = useState([])
    const [otherRoomsAndStaffInventoryList, setOtherRoomsAndStaffInventoryList] = useState([])
    const [outdoorInventoryList, setOutdoorInventoryList] = useState([])
    const [commonAreaInventoryList, setCommonAreaInventoryList] = useState([])
    const [showOtherInventories, setShowOtherInventories] = useState(false)
    const [brandCategory, setBrandCategory] = useState([]);
    const [showCustomInventory, setShowCustomInventory] = useState(false)
    // const [eventsAndOccasionsInventoryList, setEventsAndOccasionsInventoryList] = useState([])
    useEffect(() => {
        setShowProgressSideMenu(true)
        if (parentData.setActiveModule) {
            parentData.setActiveModule({
                ...parentData.activeModule,
                module: "campaignPlacement",
                subModule: "inventory"
            })
        }
        getAllInventoryList()
        getBrandCategoryList();
        getCustomInventory()
    }, [])
    const getBrandCategoryList = async () => {
        const resp = await context.apis.public.listBrandCategoriesMaster(
            context.validator, {}, {}
        )
        console.log(resp)
        if (resp && resp.done) {
            setBrandCategory(resp.response.rows.filter((item => item._id === parentData.data.brandDetail.brandcategoryId)))
            // setBrandCategoryList([...setBrandCategoryList])
        }
    }
    useEffect(() => {
        getSuggestedInventoryList()
    }, [parentData.data.brandDetail.brandcategoryId])
    useEffect(() => {
        if(recomendedInventoryList.length > 0 && allInventoryList.length > 0){
            let allInventory = allInventoryList.filter(inventory => {
                let found = recomendedInventoryList.find(item => item._id === inventory._id)
                if(found){
                    return false
                }
                else{
                    return true
                }
            })
            setAllInventoryList([...allInventory])
            let classRoomInventory = classRoomInventoryList.filter(inventory => {
                let found = recomendedInventoryList.find(item => item._id === inventory._id)
                if(found){
                    return false
                }
                else{
                    return true
                }
            })
            setClassRoomInventoryList([...classRoomInventory])
            let otherRommInventory = otherRoomsAndStaffInventoryList.filter(inventory => {
                let found = recomendedInventoryList.find(item => item._id === inventory._id)
                if(found){
                    return false
                }
                else{
                    return true
                }
            })
            setOtherRoomsAndStaffInventoryList([...otherRommInventory])
            let outdoorInventory = outdoorInventoryList.filter(inventory => {
                let found = recomendedInventoryList.find(item => item._id === inventory._id)
                if(found){
                    return false
                }
                else{
                    return true
                }
            })
            setOutdoorInventoryList([...outdoorInventory])
            let commonAreaInventory = commonAreaInventoryList.filter(inventory => {
                let found = recomendedInventoryList.find(item => item._id === inventory._id)
                if(found){
                    return false
                }
                else{
                    return true
                }
            })
            setCommonAreaInventoryList([...commonAreaInventory])
        }
    }, [recomendedInventoryList])
    const getSuggestedInventoryList = async () => {
        const resp = await context.apis.public.getSuggestedInventoriesForBrandCampaign(
            context.validator, { brandCategory: parentData.data.brandDetail.brandcategoryId }, {}
        )
        if (resp && resp.done) {
            let allInventory = [...resp.response.rows]
            let sortInventory = allInventory.sort((a, b) => {
                return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
            })
            setRecomendedInventoryList([...sortInventory])
        }
        console.log(resp)
    }
    const getCustomInventory = async() => {
        const resp = await context.apis[context.user.role].getCustomInventoriesFromAllSchools(
            context.validator, {}, {}
        )
        console.log("getCustomInventoriesFromAllSchools", resp)
        if(resp && resp.done){
            let customInventory = [...resp.response]
            let sortInventory = customInventory.sort((a, b) => {
                return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
            })
            console.log("sortInventory", sortInventory,"filter", sortInventory.filter(inventory => inventory.type === "classroom"))
            setCustomInventoryList([...sortInventory])
            setCustomClassRoomInventory([...sortInventory.filter(inventory => inventory.type === "classroom")])
            setCustomCommonAreaInventory([...sortInventory.filter(inventory => inventory.type === "commonArea")])
            setCustomOutdoorInventory([...sortInventory.filter(inventory => inventory.type === "outdoor")])
            setCustomOtherRoomInventory([...sortInventory.filter(inventory => inventory.type === "otherRooms&Staff")])
        }
    }
    const getAllInventoryList = async () => {
        const resp = await context.apis.public.getAllInventoriesFromMaster(
            context.validator, {}, {}
        )
        console.log(resp)
        if (resp && resp.done) {
            let allInventory = [...resp.response.rows.filter(inventory => inventory.type !== "events&Occasions")]
            let sortInventory = allInventory.sort((a, b) => {
                return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
            })
            setAllInventoryList([...sortInventory.filter(inventory => inventory.type !== "events&Occasions"  && !inventory._custom)])
            // setCustomInventoryList([...sortInventory.filter(inventory => inventory.type !== "events&Occasions" )])
            // setCustomClassRoomInventory([...sortInventory.filter(inventory => inventory.type === "classroom" && inventory._custom)])
            // setCustomCommonAreaInventory([...sortInventory.filter(inventory => inventory.type === "commonArea" && inventory._custom)])
            // setCustomOutdoorInventory([...sortInventory.filter(inventory => inventory.type === "outdoor" && inventory._custom)])
            // setCustomOtherRoomInventory([...sortInventory.filter(inventory => inventory.type === "otherRooms&Staff" && inventory._custom)])
            // let category = []
            // resp.response.rows.forEach((inventory) => {
            //     if(!category.includes(inventory.type)){
            //         category.push(inventory.type)
            //     }
            // })
            // setInventoryCategoryList([...category])
            setClassRoomInventoryList([...sortInventory.filter(inventory => inventory.type === "classroom" && !inventory._custom)])
            setCommonAreaInventoryList([...sortInventory.filter(inventory => inventory.type === "commonArea" && !inventory._custom)])
            setOutdoorInventoryList([...sortInventory.filter(inventory => inventory.type === "outdoor" && !inventory._custom)])
            setOtherRoomsAndStaffInventoryList([...sortInventory.filter(inventory => inventory.type === "otherRooms&Staff" && !inventory._custom)])
            // setEventsAndOccasionsInventoryList([...resp.response.rows.filter(inventory => inventory.type === "events&Occasions")])
        }
    }
    const handleInventoryClick = (e: any, item) => {
        console.log(e.target.checked)
        let arr = formData.inventories
        if (e.target.checked) {
            arr.push(item._id)
            setFormData({
                ...formData,
                inventories: [...arr]
            })
        }
        else {
            arr = [...arr.filter(inventory => inventory !== item._id)]
            setFormData({
                ...formData,
                inventories: [...arr]
            })
        }
    }
    const onSave = (e, proceed) => {
        if (proceed) {
            e.preventDefault()
            if (parentData) {
                if (parentData.setProgress) {
                    parentData.setProgress({
                        ...parentData.progress,
                        inventory: true,
                    })
                }
                if (parentData.setData) {
                    parentData.setData({
                        ...parentData.data,
                        inventory: { ...formData }
                    })
                }
            }
            // history.push('/brands/brand-details/create-campaign/schools')
        }
        else {

        }
    }
    const nextPage = () => {
        if(parentData.data.brandDetail.wisrOffering?.eventAndOccations){
            history.push('/backend/backend-details/view-campaign/events');
        }
        else{
            history.push('/backend/backend-details/view-campaign/recommended-school');
        }
    }

    return (
        <>
            <div className={`ovelap`}></div>
            {/* <TopBar isDashboard={true} /> */}
            <div className="col-xl-9 col-lg-9 col-md-12 col-xs-12">
                <div className="rightPart noBack">
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-20"><h2 className="htx1 clearfix mt-10 mb-10">Create a Campaign</h2></div>
                        <div className="col-xl-8 col-lg-8 col-md-12 col-xs-12 pl pr">
                            <div className="row">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                    <div className="brandTeamMemberTable w100">
                                        <div className="row">
                                            {/* <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-10">
                                                <h2 className="htx2 clearfix">Campaign Details <a href="javascript:void(0)" className="editbtn" onClick={() => history.push('/brands/brand-details/create-campaign/set-up-campaign')}><img src="/static/media/edit-btn.c928a066.svg" /> Edit</a></h2>
                                            </div> */}
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 pl pr">
                                                <div className="row generalview">
                                                    <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12 mt-20">
                                                        <div className="inventory-overHead">
                                                            <p>Name</p>
                                                            <h3>{parentData?.data?.brandDetail?.name}</h3>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12 mt-20">
                                                        <div className="inventory-overHead">
                                                            <p>Description</p>
                                                            <h3>{parentData.data.brandDetail?.description}</h3>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12 mt-20">
                                                        <div className="inventory-overHead">
                                                            <p>Medium of Study</p>
                                                            <span><h3>
                                                                {parentData.data.brandDetail.studyMedium.length !== 0 && parentData.data.brandDetail.studyMedium.map((item, index) => (
                                                                    <b style={{"wordBreak": "initial"}} key={index}>{index < parentData.data.brandDetail.studyMedium.length -1 ? `${item}, ` : item}</b>
                                                                ))}
                                                                </h3>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12 mt-20">
                                                        <div className="inventory-overHead">
                                                            <p>Brand Category</p>
                                                            <span><h3>{brandCategory[0]?.name}</h3></span>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12 mt-20">
                                                        <div className="inventory-overHead">
                                                            <p>Events</p>
                                                            <h3>Sports Day</h3>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12 mt-20">
                                                        <div className="inventory-overHead">
                                                            <p>Duration</p>
                                                            <h3>{parentData.data.brandDetail.startDate && parentData.data.brandDetail.endDate && `${formatDateShowMonth(parentData.data.brandDetail.startDate, 4)} - ${formatDateShowMonth(parentData.data.brandDetail.endDate, 4)}`}</h3>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12 mt-20">
                                                        <div className="inventory-overHead">
                                                            <p>Target Audience Gender</p>
                                                            <h3>{parentData.data.brandDetail.targetGender && parentData.data.brandDetail.targetGender === "both" ? "All" : parentData.data.brandDetail.targetGender}</h3>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12 mt-20">
                                                        <div className="inventory-overHead">
                                                            <p>Target Audience Age</p>
                                                            <h3>{parentData.data.brandDetail.tagetAgeAudiance[0] && parentData.data.brandDetail.tagetAgeAudiance[1] && `${parentData.data.brandDetail.tagetAgeAudiance[0]} y.o to ${parentData.data.brandDetail.tagetAgeAudiance[1]} y.o`}</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="reco-inventory">
                                        <div className="row">
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-20 mt-40">
                                                <h2 className="htx2 clearfix">{`Recommended for your Brand (${recomendedInventoryList.length > 0 ? recomendedInventoryList.length : 0})`}</h2>
                                            </div>
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                                <div className="forminpt checkboxinput">
                                                    <div className="checkboxinputList">
                                                        {recomendedInventoryList.length > 0 && recomendedInventoryList.map((item, index) => (
                                                            <div key={index} className="checkbox">
                                                                <input type="checkbox" id={item._id} checked={parentData?.data?.inventory?.inventories?.some(inventory => inventory._id === item._id)} />
                                                                <label htmlFor={item._id}>{item.name}</label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-20">
                                                <h2 className="htx2 clearfix">{`Other Available Inventory (${allInventoryList.length})`} <span className="other-arrow" onClick={() => setShowOtherInventories(!showOtherInventories)}><svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M11 6L6 1L1 6" stroke="#8C8C8C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /> </svg></span></h2>
                                            </div>
                                            {allInventoryList.length > 0 ? showOtherInventories &&
                                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 otherDisplay">
                                                    <div className="row">
                                                        {classRoomInventoryList.length !== 0 &&
                                                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 pl pr mt-30">
                                                                <div className="forminpt checkboxinput">
                                                                    <h2 className="htx3 clearfix">Classroom Inventory</h2>
                                                                    <div className="checkboxinputList">
                                                                        {classRoomInventoryList.length !== 0 && classRoomInventoryList.map(item => (
                                                                            <div key={item._id} className="checkbox">
                                                                                <input type="checkbox" id={item._id} checked={parentData?.data?.inventory?.inventories?.some(inventory => inventory._id === item._id)} />
                                                                                <label htmlFor={item._id}>{item.name}</label>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                        {commonAreaInventoryList.length !== 0 &&
                                                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 pl pr mt-30">
                                                                <div className="forminpt checkboxinput">
                                                                    <h2 className="htx3 clearfix">Common Area Inventory</h2>
                                                                    <div className="checkboxinputList">
                                                                        {commonAreaInventoryList.length !== 0 && commonAreaInventoryList.map(item => (
                                                                            <div key={item._id} className="checkbox">
                                                                                <input type="checkbox" id={item._id} checked={parentData?.data?.inventory?.inventories?.some(inventory => inventory._id === item._id)} />
                                                                                <label htmlFor={item._id}>{item.name}</label>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                        {otherRoomsAndStaffInventoryList.length !== 0 && 
                                                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 pl pr mt-30">
                                                                <div className="forminpt checkboxinput">
                                                                    <h2 className="htx3 clearfix">Other Rooms and Staff</h2>
                                                                    <div className="checkboxinputList">
                                                                        {otherRoomsAndStaffInventoryList.length !== 0 && otherRoomsAndStaffInventoryList.map(item => (
                                                                            <div key={item._id} className="checkbox">
                                                                                <input type="checkbox" id={item._id} checked={parentData?.data?.inventory?.inventories?.some(inventory => inventory._id === item._id)} />
                                                                                <label htmlFor={item._id}>{item.name}</label>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                        {outdoorInventoryList.length !== 0 &&
                                                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 pl pr mt-30">
                                                                <div className="forminpt checkboxinput">
                                                                    <h2 className="htx3 clearfix">Outdoor Inventory</h2>
                                                                    <div className="checkboxinputList">
                                                                        {outdoorInventoryList.length !== 0 && outdoorInventoryList.map(item => (
                                                                            <div key={item._id} className="checkbox">
                                                                                <input type="checkbox" id={item._id} checked={parentData?.data?.inventory?.inventories?.some(inventory => inventory._id === item._id)} />
                                                                                <label htmlFor={item._id}>{item.name}</label>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                                :
                                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 otherDisplay">
                                                    <div className="row">
                                                        No data found
                                                    </div>
                                                </div>
                                            }
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-20">
                                                <h2 className="htx2 clearfix">{`Custom Inventories (${customInventoryList.length})`} <span className={`other-arrow ${showCustomInventory && "other-downarrow"}`} onClick={() => setShowCustomInventory(!showCustomInventory)}><svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M11 6L6 1L1 6" stroke="#8C8C8C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg></span></h2>
                                            </div>
                                            {showCustomInventory && (customInventoryList.length > 0 ?
                                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 otherDisplay">
                                                    <div className="row">
                                                    {customClassRoomInventory.length > 0 &&
                                                        <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 pl pr mt-30">
                                                            <div className="forminpt checkboxinput">
                                                                <h2 className="htx3 clearfix">Classroom Inventory</h2>
                                                                <div className="checkboxinputList">
                                                                    {customClassRoomInventory.length !==0 && customClassRoomInventory.map(item => (
                                                                        <div key={item._id} className="checkbox">
                                                                            <input type="checkbox" id={item._id} checked={parentData?.data?.inventory?.inventories?.some(inventory => inventory._id === item._id)} />
                                                                            <label htmlFor={item._id}>{item.parentName}</label>
                                                                        </div>
                                                                    ))}
                                                                </div>    
                                                            </div>
                                                        </div>
                                                    }
                                                    {customCommonAreaInventory.length > 0 &&
                                                        <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 pl pr mt-30">
                                                            <div className="forminpt checkboxinput">
                                                                <h2 className="htx3 clearfix">Common Area Inventory</h2>
                                                                <div className="checkboxinputList">
                                                                    {customCommonAreaInventory.length !==0 && customCommonAreaInventory.map(item => (
                                                                        <div key={item._id} className="checkbox">
                                                                            <input type="checkbox" id={item._id} checked={parentData?.data?.inventory?.inventories?.some(inventory => inventory._id === item._id)}/>
                                                                            <label htmlFor={item._id}>{item.parentName}</label>
                                                                        </div>
                                                                    ))}
                                                                </div>    
                                                            </div>
                                                        </div>
                                                    }
                                                    {customOtherRoomInventory.length > 0 &&
                                                        <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 pl pr mt-30">
                                                            <div className="forminpt checkboxinput">
                                                                <h2 className="htx3 clearfix">Other Rooms and Staff</h2>
                                                                <div className="checkboxinputList">
                                                                    {customOtherRoomInventory.length !==0 && customOtherRoomInventory.map(item => (
                                                                        <div key={item._id} className="checkbox">
                                                                            <input type="checkbox" id={item._id} checked={parentData?.data?.inventory?.inventories?.some(inventory => inventory._id === item._id)}/>
                                                                            <label htmlFor={item._id}>{item.parentName}</label>
                                                                        </div>
                                                                    ))}
                                                                </div>    
                                                            </div>
                                                        </div>
                                                    }
                                                    {customOutdoorInventory.length > 0 &&
                                                        <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 pl pr mt-30">
                                                            <div className="forminpt checkboxinput">
                                                                <h2 className="htx3 clearfix">Outdoor Inventory</h2>
                                                                <div className="checkboxinputList">
                                                                    {customOutdoorInventory.length !==0 && customOutdoorInventory.map(item => (
                                                                        <div key={item._id} className="checkbox">
                                                                            <input type="checkbox" id={item._id} checked={parentData?.data?.inventory?.inventories?.some(inventory => inventory._id === item._id)}/>
                                                                            <label htmlFor={item._id}>{item.parentName}</label>
                                                                        </div>
                                                                    ))}
                                                                </div>    
                                                            </div>
                                                        </div>
                                                    }
                                                    </div>
                                                </div>
                                                :
                                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 otherDisplay">
                                                    <div className="row">
                                                        No data found
                                                    </div>
                                                </div>
                                            )}
                                            <div className="col-xl-5 col-lg-5 col-md-12 col-xs-12 delete-user mt-30">
                                                <Link className="btn btn-orange quit-btn" to={{
                                                    pathname: "/backend/backend-details/create-trial-campaign-details",
                                                    state: { campaignType: parentData.data.isTrial ? 'trial' : 'create', from: parentData.data.from, campaignId: parentData.data.campaignId }
                                                }}>QUIT</Link>
                                            </div>
                                            <div className="col-xl-7 col-lg-7 col-md-12 col-xs-12 end-md mt-30">
                                                <div className="forminpt">
                                                    <input name="next" className="btn btn-orange" type="button" value="next" onClick={nextPage} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CampaignInventory
