import React from "react";

const InventorySpecific = ({ data, heading }) => {
  return (
    <>
      <div className='col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-20'></div>
      <div className='col-xl-6 col-lg-6 col-md-6 col-xs-12'>
        <p className="text-uppercase">{heading}</p>
      </div>
      <div className='col-xl-6 col-lg-6 col-md-6 col-xs-12'>
        <p className="text-uppercase">Quantity</p>
      </div>
      {
      data.length > 0 ?
      data.map((item, index) => {
        return (
          <React.Fragment key={index}>
            <div className='col-xl-6 col-lg-6 col-md-6 col-xs-12'>
              <b>{item.name}</b>
            </div>
            <div className='col-xl-6 col-lg-6 col-md-6 col-xs-12'>
              <b>{`${item.data.length > 0 ? item.data.length: "-"}`}</b>
            </div>
          </React.Fragment>
        )
      })
      :
      <React.Fragment>
        <div className='col-xl-6 col-lg-6 col-md-6 col-xs-12'>
          <b>-</b>
        </div>
        <div className='col-xl-6 col-lg-6 col-md-6 col-xs-12'>
          <b>-</b>
        </div>          
      </React.Fragment>
    }
    </>
  );
};

export default InventorySpecific;
