import { useState, useContext, useEffect, useRef } from "react"

import { Link } from "react-router-dom"

import { useHistory } from "react-router-dom"

import backBtn from "../../../../assets/images/back-arrow.svg"
import errorSVG from "../../../../assets/images/error.svg"

import SimpleReactValidator from "simple-react-validator"

import TextArea from "../../../../components/formFields/textArea"
import ConfirmAlert from "../../../../components/addon/confirmAlert"

import { objectDeepClone, compareObjs } from "../../../../service/helper"

const ProfileDescription = (props) => {
    const {
        events,
        progress,
        brandProfile,
        isBackendUser
    } = props

    const history = useHistory()

    const [formValidator] = useState(useRef(new SimpleReactValidator({
        element: (message, className) =>
            <span className="valError"><img src={errorSVG} />{message}</span>
    })))

    const [update, forceUpdate] = useState(false)
    const [formData, setFormData] = useState<any>({})
    const [showPrompt, setShowPrompt] = useState(false)
    const [oldFormData, setOldFormData] = useState<any>({})
    const [overlay, setOverlay] = useState(false)

    useEffect(() => {
        if (events && events.setActiveModule) {
            events.setActiveModule({
                module: "description"
            })
        }
        if (brandProfile && brandProfile.companyDescription) {
            setFormData(objectDeepClone(brandProfile.companyDescription))
            setOldFormData(objectDeepClone(brandProfile.companyDescription))
        }
    }, [])

    console.log("brandProfile====",brandProfile);

    useEffect(() => {
        const isSame = compareObjs(formData, oldFormData)

        if (isSame === false) {
            setShowPrompt(true)
        } else {
            setShowPrompt(false)
        }
    }, [formData])

    const onFormDataChange = (event) => {
        setFormData({
            ...formData,
            [event.target.id]: event.target.value
        })
    }

    const onSubmitClick = () => {
        const result = formValidator.current.allValid()

        console.log(result)
        if (result === false) {
            formValidator.current.showMessages()
            forceUpdate(true)
            return
        } else {
            formValidator.current.hideMessages()
            forceUpdate(!update)
        }

        setShowPrompt(false)

        process.nextTick(() => {
            if (events && events.setBrandProfile) {
                progress.description = true
                const json = objectDeepClone(brandProfile)
                json.companyDescription = objectDeepClone(formData)
                events.setBrandProfile(json)
                if(isBackendUser){
                    history.push("/backend/backend-details/brands/brand-details/contact")
                }else{
                    history.push("/brands/brand-details/profile/contact")
                }
            }
        })
    }

    const confirmAlertCallback = (data) => {
        setOverlay(data.overlay)
        // setShowPrompt(false)
    }

    return (
        <>
            <div className={`ovelap ${overlay === true ? "active" : ""}`}></div>
            <div className="col-xl-9 col-lg-9 col-md-12 col-xs-12">
                <div className="rightPart noBack">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-xs-6">
                            <Link to={isBackendUser ? "/backend/backend-details/brands/brand-details/general" :"/brands/brand-details/profile/general"} className="backbtn dblock">
                            <img src={backBtn} />BACK </Link>
                        </div>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-20">
                            <h2 className="htx5 clearfix">What does your company do?</h2>
                        </div>
                        <div className="col-xl-7 col-lg-7 col-md-10 col-xs-12 mt-40 mb-20">
                            <div className="row">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                    <TextArea
                                        formData={formData}
                                        formKey='description'
                                        onDataChange={onFormDataChange}
                                        label='Description'
                                        placeholder='Write a few sentences about your company'
                                        isRequired={false}
                                        formValidator={formValidator}
                                        validation='required'
                                        maxLength={500}
                                    />                                    
                                    {/* <div className="forminpt">
                                        <label>Description</label>
                                        <textarea placeholder="Write a few sentences about your company"></textarea>
                                    </div> */}
                                </div>
                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 pl-0 mt-30">
                                    <div className="forminpt fR">
                                        <a onClick={onSubmitClick} href="javascript:void(0);" className="btn btn-orange brand-btn">Next</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ConfirmAlert
                when={showPrompt}
                title="Leave page without saving..."
                cancelText="Cancel"
                okText="Confirm"
                onOK={() => true}
                onCancel={() => false}
                parentCallback={confirmAlertCallback}
            />
        </>
    )
}

export default ProfileDescription
