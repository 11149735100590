import React from "react";
import { useState, useContext, useEffect, useRef } from "react"

import { useHistory } from "react-router-dom"

import { BackendAdminContextInitiator } from "../../../../backendAdminSrc/apis/backendAdminContextInitiator";
import AppContextObject from "../../../../common/context/common"
import config from "../../../../service/config"

import filterSvg from "../../../../assets/images/filter-svg.svg"
import filterArrowSvg from "../../../../assets/images/filter-arrow-svg.svg"

import { DebounceInput } from 'react-debounce-input'
import SearchInput from "./SearchInput";
import Pagination from "../../../../components/addon/pagination";
import CustomSearchInput from "../../../../components/formFields/customSearchInput";
import { titleCase } from "../../../../service/helper";
import useClickOutside from "../../../../service/useClickOutside";
var campaignSchoolListGlobalVar = 1

const SchoolsList = (props) => {
    const { showSchoolModal, closeSchoolModal, campaignId, schoolId, showCampaignCreativeButton, fromBrand, doNotShowCreativeStatus, campaignDetailsIsApproved } = props

	console.log("schoolIdNew", schoolId)

    const context = useContext(AppContextObject);
    const history = useHistory()

	console.log("campaignIdcampaignId", campaignId, closeSchoolModal)

    const [searchInpuOpen, setSearchInpuOpen] = useState(false);
    const [clearSearchIcon, setClearSearchIcon] = useState(false);
    const [openFilter, setOpenFilter] = useState(false);
    const [openStateFilter, setOpenStateFilter] = useState(false)
    const [openCityFilter, setOpenCityFilter] = useState(false)
    const [openGenderFilter, setOpenGenderFilter] = useState(false);
    const [openBoardFilter, setOpenBoardFilter] = useState(false);
    const [openLanguageFilter, setOpenLanguageFilter] = useState(false);
    const [filterCity, setFilterCity] = useState([]);
    const [filterGender, setFilterGender] = useState([]);
    const [filterBoard, setFilterBoard] = useState([]);
    const [filterLanguage, setFilterLanguage] = useState([]);
    const [searchKeys, setSearchKeys] = useState("");
    const [schools, setSchools] = useState<any>([]);
    const [totalPage, setTotalPage] = useState([0])
    const [totalPageCount, setTotalPageCount] = useState(0)
    const [currentPage, setCurrentpage] = useState(1)
    const [cityStateData, setCityStateData] = useState({})
    const [stateList, setStateList] = useState([])
    const [selectedState, setSelectedState] = useState([])
    const [cityList, setCityList] = useState([])
    const [loading,setLoading]=useState(false);
    const [schoolIds,setSchoolIds]=useState<any>();
	const [buttonClicked, setButtonClicked] = useState({});

    const [allSchoolList, setAllSchoolList] = useState<any>([]);
	// const [text, setText] = useState(schools?.schoolStatus);
    const filterRef = useRef(null)
    const filterOptionsRef = useRef(null)
    useClickOutside(filterOptionsRef, (event) => {
        const el = filterRef?.current;

        if (!el || el.contains(event.target)) {
            return;
        }
        setOpenFilter(false)
    })

    useEffect(() => {
        getSchoolList();
    }, [showSchoolModal, searchKeys, currentPage, filterBoard, filterLanguage, filterGender, filterCity, selectedState, buttonClicked])
    useEffect(() => {
        if(currentPage !== 1){
            setCurrentpage(1)
        }
    }, [searchKeys, filterBoard, filterLanguage, filterGender, filterCity, selectedState])
    useEffect(() => {
        getAllSchool()
    }, [])
    useEffect(() => {
        fetchCityByState()
    }, [selectedState])
    const fetchCityByState = async() => {
        let city = []
        selectedState.forEach(state => {
            city = [...city, ...cityStateData[state]]
        })
        setCityList([...city])
    }

    const getAllSchool = async() => {
        setLoading(true);
        let resp
        if(fromBrand){
            resp = await context.apis[context.user.role].getAllCampaignSchools(
                context.validator, { campaignId: campaignId }, {}
            )
        }
        else{
            const backendContextInitiator = new BackendAdminContextInitiator(context)
            resp = await backendContextInitiator.getAllCampaignSchools(
                context.validator, { campaignId: campaignId }, {}
            )
        }
        if(resp && resp.done){
            console.log("getAllCampaignSchools", resp)
            setAllSchoolList(resp.response.rows)
            setLoading(false);
            let states = [], cityStateObj = {}
            resp.response.rows.forEach(school => {
                if(!states.includes(school.state)){
                    states.push(school.state)
                }
                if(cityStateObj[school.state]){
                    if(!cityStateObj[school.state].includes(school.parentCity)){
                        cityStateObj[school.state].push(school.parentCity)
                    }
                }
                else{
                    cityStateObj[school.state] = [school.parentCity]
                }
            })
            console.log("first", states, cityStateObj)
            setStateList([...states])
            setCityStateData({...cityStateObj})
        }
    }

    console.log("setAllSchoolList", allSchoolList)
    const getSchoolList = async () => {
        setLoading(true);
        let resp
        var localVar = Math.random()
        campaignSchoolListGlobalVar = localVar
        if(fromBrand){
            resp = await context.apis[context.user.role].getAllCampaignSchools(
                context.validator, { campaignId: campaignId, searchValue: searchKeys, city: filterCity, language: filterLanguage, schoolType: filterGender, board: filterBoard, state: selectedState}, {page: currentPage, limit: 10, withCount: true, sort: {_id: -1}}
            )
        }
        else{
            const backendContextInitiator = new BackendAdminContextInitiator(context)
            resp = await backendContextInitiator.getAllCampaignSchools(
                context.validator, { campaignId: campaignId, searchValue: searchKeys, city: filterCity, language: filterLanguage, schoolType: filterGender, board: filterBoard, state: selectedState}, {page: currentPage, limit: 10, withCount: true, sort: {_id: -1}}
            
				)
				// setSchoolIds
			setSchoolIds(resp.response.rows);
			
			console.log("getAllCampaignSchools inside",resp)
        }
        console.log("getAllCampaignSchools",resp)
        if (resp && resp.done) {
            if(campaignSchoolListGlobalVar === localVar){
                setLoading(false);
                setSchools(resp.response.rows);
                setTotalPageCount(resp.response.count)
                let total = Math.ceil(resp.response.count / 10);
                let arr = []
                for (let i = 0; i < total; i++) {
                    arr.push(i)
                }
                setTotalPage([...arr])
            }
            else{
                setLoading(false);
            }
        }
    }
	


const arrayOfSchoolIds = schools.map(obj => obj._id);
const allSchoolsApproved = schools.every(obj => obj.schoolStatus ==='accepted');

const arrayOfAllSchoolList = allSchoolList.map(obj => obj._id);



console.log("arrayOfSchoolIdsNew", arrayOfSchoolIds)
console.log("allSchoolsApproved", allSchoolsApproved)

const acceptOrRejectCampaignRequest = async (_id) => {
	console.log("acceptOrRejectCampaignRequest",_id)

  // Update the school's status in the local state
  const updatedSchools = schools.map(school => {
	if (school._id === _id) {
	  school.schoolStatus = "accepted";
	  school.creativeVersion = 1; // Assuming creative version 1
	}
	return school;
  });

	let resp = await context.apis[context.user.role].acceptOrRejectCampaignRequest(
		context.validator,
		{campaignId:campaignId, schoolId : _id},
		{schoolStatus: "accepted"}	
	);


	if(resp !== null && resp.done === true){
  		   setSchools(updatedSchools);
			 setButtonClicked({ ...buttonClicked, [_id]: true }); // Set the specific button's state to true
             getSchoolList();
	}

	console.log("acceptOrRejectCampaignRequest resp", resp)

	
};


console.log("campaignDetailsIsApproved schoolList", campaignDetailsIsApproved)


    const filteration = (category, value) => {
        console.log(category, value)
        
        if (category === 'state') {
            let array = [...selectedState]
            if (array.includes(value)) {
                array.splice(array.indexOf(value), 1)
                if(filterCity.length !== 0){
                    let citiesToRemove = [...cityStateData[value]], newCitiesData = [...filterCity]
                    for(let city of citiesToRemove){
                        if(newCitiesData.indexOf(city) !== -1){
                            newCitiesData.splice(newCitiesData.indexOf(city), 1)
                        }
                    }
                    setFilterCity([...newCitiesData])
                }
            }
            else {
                array.push(value)
                setFilterCity([...filterCity,...cityStateData[value]])
            }
            setSelectedState([...array])
        }
        else if (category === 'city') {
            let array = [...filterCity]
            if (array.includes(value)) {
                array.splice(array.indexOf(value), 1)
            }
            else {
                array.push(value)
            }
            setFilterCity([...array])
        }
        else if (category === 'gender') {
            let array = [...filterGender]
            if (array.includes(value)) {
                array.splice(array.indexOf(value), 1)
            }
            else {
                array.push(value)
            }
            setFilterGender([...array])

        }
        else if (category === 'board') {
            let array = [...filterBoard]
            if (array.includes(value)) {
                array.splice(array.indexOf(value), 1)
            }
            else {
                array.push(value)
            }
            setFilterBoard([...array])

        }
        else if (category === 'language') {
            let array = [...filterLanguage]
            if (array.includes(value)) {
                array.splice(array.indexOf(value), 1)
            }
            else {
                array.push(value)
            }
            setFilterLanguage([...array])

        }


    }
    const clearAllFilters = () => {
        setFilterCity([]);
        setFilterGender([]);
        setFilterBoard([]);
        setFilterLanguage([]);
        setSelectedState([]);
        setOpenFilter(false)
        setOpenBoardFilter(false)
        setOpenStateFilter(false)
        setOpenCityFilter(false)
        setOpenGenderFilter(false)
        setOpenLanguageFilter(false)
    }
    const onPageChange = (page) => {
        setCurrentpage(page)
    }
    const onNextClick = () => {
        setCurrentpage(prev => prev + 1)
    }
    const onPrevClick = () => {
        setCurrentpage(prev => prev - 1)
    }
    const closePopup = ()=>{
        closeSchoolModal();
        setOpenFilter(false)
        setSearchInpuOpen(false)
        setFilterCity([]);
        setFilterGender([]);
        setFilterBoard([]);
        setFilterLanguage([]);
        setSearchKeys('');
    }
    const handleSearch = (e) => {
        if(e === ""){
            setSearchKeys("")
        }
        else{
            setSearchKeys(e.target.value)
        }
    }
    console.log(filterLanguage, filterCity, filterGender, filterBoard)

    console.log("schoolsschoolsschools", schools)

    return (
        <>
        <div className={`ovelap ${showSchoolModal === true ? "active" : ""}`}></div>
            <div id="inventoryMore" className={`popup ${showSchoolModal === false ? "hide" : ""}`}>
                <div className="popup-header">
                    <h3 className="htx2 pophead">Schools Selected</h3>
                    <span className="close toggle" onClick={closePopup} data-target="myPopup">close</span>
                </div>
                <div className="popup-body">
                    <div className="viewdetailsHead">
                        <div className="invetorypopblock-school">
                            <div className="rightPart noBack">
                                <div className="row schools-list-popup">
                                    <div className="col-xl-9 col-lg-9 col-md-12 col-xs-12">
                                        <div className="row">
                                            
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-10 pr">
                                                
                                                {showCampaignCreativeButton && <a href="javascript:void(0)" className="btn btn-orange spaceL uploadCretiveLink" onClick={() => history.push({pathname: "/brands/brand-details/create-campaign/creative", state: {campaignId: campaignId, creativeRework: true}})}>Upload creatives</a>}
                                            
                                                <div className="fR filterbox">
                                                    <a href="javascript:void(0);" className="filterDrop" onClick={() => setOpenFilter(!openFilter)}>
                                                        <img src={filterSvg} />
                                                        <h3>Filter</h3>
                                                        <img src={filterArrowSvg} />
                                                    </a>
                                                    <div className={`filterDropDown ${openFilter ? 'open' : ''}`}>
                                                        <div className="filterDropHead" ref={filterRef}>
                                                            <h2>Filter <a href="javascript:void(0);" className="viewwLink" onClick={clearAllFilters}>Clear All</a></h2>
                                                        </div>
                                                        <div className="filterDropBody" ref={filterOptionsRef}>
                                                           

                                                            <div className={`filterDropClose ${openStateFilter ? 'open' : ''}`}>
                                                                <h3><a href="javascript:void(0);" onClick={() => setOpenStateFilter(!openStateFilter)}>{`State ${selectedState.length > 0 ? `(${selectedState.length})` : ""}`}  <img src={filterArrowSvg} /></a></h3>
                                                                <div className="filtercheckbox">
                                                                    {stateList.map((item, index) => (
                                                                        <div className="checkbox fnone" key={index} >
                                                                            <input type="checkbox" id={item} checked={selectedState.includes(item)} />
                                                                            <label htmlFor={item} onClick={() => filteration('state', item)}>{titleCase(item)}</label>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                            <div className={`filterDropClose ${openCityFilter && selectedState.length > 0 ? 'open' : ''}`}>
                                                                <h3><a href="javascript:void(0);" onClick={() => setOpenCityFilter(!openCityFilter)}>{`City ${filterCity.length > 0 ? `(${filterCity.length})` : ""}`}  <img src={filterArrowSvg} /></a></h3>
                                                                <div className="filtercheckbox">
                                                                    {cityList.map((item, index) => (
                                                                        <div className="checkbox fnone" key={index} >
                                                                            <input type="checkbox" id={item} checked={filterCity.includes(item)} />
                                                                            <label htmlFor={item} onClick={() => filteration('city', item)}>{titleCase(item)}</label>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                            <div className={`filterDropClose ${openGenderFilter ? 'open' : ''}`}>
                                                                <h3><a href="javascript:void(0);" onClick={() => setOpenGenderFilter(!openGenderFilter)}>{`Gender ${filterGender.length > 0 ? `(${filterGender.length})` : ""}`}  <img src={filterArrowSvg} /></a></h3>
                                                                <div className="filtercheckbox">
                                                                    {config.gender.map((item, index) => (
                                                                        <div className="checkbox fnone" key={index}>
                                                                            <input type="checkbox" id={item.value} checked={filterGender.includes(item.value)} />
                                                                            <label htmlFor={item.value} onClick={() => filteration('gender', item.value)}>{item.label}</label>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                            <div className={`filterDropClose ${openBoardFilter ? 'open' : ''}`}>
                                                                <h3><a href="javascript:void(0);" onClick={() => setOpenBoardFilter(!openBoardFilter)} >{`Education Board ${filterBoard.length > 0 ? `(${filterBoard.length})` : ""}`}  <img src={filterArrowSvg} /></a></h3>
                                                                <div className="filtercheckbox">
                                                                    {config.boardOptions.filter((item) => item.value.toLowerCase() !== "custom" && item.value.toLowerCase() !== "other").map((item, index) => (
                                                                        <div className="checkbox fnone" key={index}>
                                                                            <input type="checkbox" id={item.value} checked={filterBoard.includes(item.value)} />
                                                                            <label htmlFor={item.value} onClick={() => filteration('board', item.value)}>{item.label}</label>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                            <div className={`filterDropClose ${openLanguageFilter ? 'open' : ''}`}>
                                                                <h3><a href="javascript:void(0);" onClick={() => setOpenLanguageFilter(!openLanguageFilter)}>{`Language of Study ${filterLanguage.length > 0 ? `(${filterLanguage.length})` : ""}`}  <img src={filterArrowSvg} /></a></h3>
                                                                <div className="filtercheckbox">
                                                                    {config.languages.filter((item) => item.value.toLowerCase() !== "custom").map((item, index) => (
                                                                        <div className="checkbox fnone" key={index}>
                                                                            <input type="checkbox" id={item.value} checked={filterLanguage.includes(item.value)} />
                                                                            <label htmlFor={item.value} onClick={() => filteration('language', item.value)}>{item.label}</label>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="inventorySearch schoolFilter inventoryBackAdmin">

                                               
                                                <CustomSearchInput
                                                    searchInpuOpen={searchInpuOpen}
                                                    setSearchInpuOpen={setSearchInpuOpen}
                                                    clearSearchIcon={clearSearchIcon}
                                                    setClearSearchIcon={setClearSearchIcon}
                                                    searchKeys={searchKeys}
                                                    handleChange={handleSearch}
                                                    palaceholder="School name"
                                                />

                                                </div>
                                            </div>



                                            {/* all approval button starts */}
                                            {/* <div>
                                                { campaignDetailsIsApproved &&
                                                    <button 
                                                    style={{
                                                        background: allSchoolsApproved ? "#ccc" : "#FE6E00", 
                                                        color: allSchoolsApproved ? "#000" : "#fff",
                                                        border: allSchoolsApproved ? "1px solid #ccc" : "1px solid #FE6E00",
                                                        padding: "10px",
                                                        borderRadius: "8px",
                                                    }}  
                                                    onClick={() => acceptOrRejectCampaignRequest(arrayOfAllSchoolList)}
                                                    disabled={allSchoolsApproved}
                                                    >
                                                        {allSchoolsApproved ? "Approved All" : " Approve All"} 
                                                    </button>
                                                }
                                            </div> */}
                                            {/* all approval button ends */}



                                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-20 pl pr">
                                                <div className="table-container mt-10 addAdmininput fillterTableHeight">
                                                    <table className="table going-campaign">
                                                        <thead>
                                                            <tr>
                                                                <th>SL No</th>
                                                                <th>School Name</th>
                                                                {!doNotShowCreativeStatus ? <th>Status</th> : ""}
                                                                {!doNotShowCreativeStatus ? <th>Creative Approval Status</th> : ""}
                                                                {
                                                                    campaignDetailsIsApproved &&
																<th>Action</th>
}
                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            

                                                            {!loading ?<>
                                                                {schools && schools.length>0 ? schools.map((school, key) => {
																	console.log("schoolId new", school._id)
                                                                    return (
                                                                        <tr key={key}>
                                                                            <td>{(key + 1) + (currentPage - 1) * 10}</td>
                                                                            <td>
                                                                                <div className="schoolNameTable">
                                                                                    <h4>{school?.schoolName}</h4>
                                                                                   
                                                                                </div>
                                                                            </td>
																			
                                                                            {!doNotShowCreativeStatus ? 
                                                                                <td>
                                                                                    <span className={`table-status ${school?.schoolStatus?.toLowerCase() === "accepted" && "greenbg"} ${school?.schoolStatus?.toLowerCase() === "pending" && "orangebg"} ${school?.schoolStatus?.toLowerCase() === "rejected" && "redbg"}`}>
                                                                                        {school?.schoolStatus || "-"}
																						
                                                                                    </span>
                                                                                </td>
                                                                                :
                                                                                ""
                                                                            }
                                                                            {!doNotShowCreativeStatus ? <td>{school?.creativeVersion ? school?.creativeVersion === 1 ? 'Creative' : "Re-worked Creative" : "-"}</td> : ""}
																			
																			{
																				campaignDetailsIsApproved === true ? (
																				<td>
																				<button  style={{
																					background: (buttonClicked[school._id] || school?.schoolStatus === 'accepted')  ? "#dfdfdf" : "#FE6E00", // Change the background color when the specific button is clicked
																					color: (buttonClicked[school._id] || school?.schoolStatus === 'accepted' ) ? "#ffffff" : "#fff",
																					border: (buttonClicked[school._id] || school?.schoolStatus === 'accepted' ) ? "1px solid #dfdfdf" : "1px solid #FE6E00",
																					padding: "10px",
																					borderRadius: "8px",
																					
																				}} 
																				onClick={() => acceptOrRejectCampaignRequest([school._id])}
																				disabled={buttonClicked[school._id] || school?.schoolStatus === 'accepted'} // Disable the specific button when it is clicked
																				> 
																				{(buttonClicked[school._id] || school?.schoolStatus === 'accepted' )? "Approved" : "Approve"} {/* Change the button text based on buttonClicked state */}
																				</button>
																				</td>
																				) : 
																				<td> </td>
																			}
                                                                        </tr>
                                                                    )
                                                                })
                                                            :
                                                            <tr><td colSpan={7}>No School found</td></tr>
                                                            }
                                                            </>
                                                            :
                                                            [...Array(10)].map((el, index) => {
                                                                return (
                                                                    <tr>
                                                                        {
                                                                            [...Array(doNotShowCreativeStatus ? 2 : 4)].map((el, index) => {
                                                                                return (
                                                                                    <td key={index}>
                                                                                        <div className="content-loader-block"></div>
                                                                                    </td>
                                                                                )
                                                                            })
                                                                        }
                                                                    </tr>
                                                                )
                                                            })
}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                
                                                {console.log('totalPage',totalPageCount)}
                                                {totalPageCount > 10 &&
                                                    <Pagination
                                                        currentPage={currentPage}
                                                        totalPage={totalPage}
                                                        onPageChange={onPageChange}
                                                        onNextClick={onNextClick}
                                                        onPrevClick={onPrevClick}
                                                        loading={loading}
                                                    />
                                                }
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}
export default SchoolsList;