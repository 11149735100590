import { useState, useContext, useEffect } from "react"
import hotkeys from 'hotkeys-js';
import {
    Route,
    Switch,
    useRouteMatch,
    useHistory
} from "react-router-dom"

import AppContextObject from "../../../common/context/common"
import config from "../../../service/config"
import { DataContextProvider } from "../../../service/backendContext"
import BackendDetailsSideMenu from "./backendDetailsSideMenu"
import SchoolList from "./schoolList"
import Dashboard from "./dashboard"
import InventoryList from "./inventoryList"
import BrandList from "./brandList"
import FosList from "./fosList"
import UserList from "./userList"
import CampaignList from "./campaignList"
import CampaignReportingList from './campaignReporting/campaignReportingList'
import CampaignSchoolList from './campaignReporting/CampaignSchoolList'
import CampaignsReject from "./campaign/campaignsReject"
import CampaignsRequest from "./campaign/campaignsRequest"
import CampaignsOngoing from "./campaign/campaignsOngoing"
import CampaignDetails from "./campaign/campaignDetails"
import SchoolDetails from "./school/schoolDetails"
import CampaignCreation from "./campaign/campaignCreation"
import DetailsFOS from "./fosDetails/detailsFOS"
import DetailsBrand from "./brandDetails/detailsBrand";
import CreateTrialCampaign from "./brandDetails/create-trialCampaigns";
import CreateTrialCampaignDetails from "./brandDetails/createTrialCampaignDetails"
import CampaignTest from "./campaignTest"
import SpecificCampaignList from "../backendAdmins/campaign/specificCampaignList";
import CampaignRejectedBySchool from "./campaignRejectedBySchool"
import Onboarding from "../schools/onboarding/onboarding"
import BrandDetails from "../brands/brandDetails"
import CreateBrand from "../brands/profile/createBrand"
import CreateBrandBackend from "../brands/profile/createBrandBackend"
import ActivityStatusList from "./activityStatusList"
import Loader from "../../../components/addon/loader"
import AddInventory from "../schools/updateDetails/inventory/inventoyWizard"
import NonWisrSchoolList from "./nonWisrSchoolList"
import SchoolInfraDetail from "./schoolInfraDetail"
import AddNonWisrSchool from "./addNonWisrSchool"
import NonWisrSchoolDetails from "./school/nonWisrSchoolDetails"
import SchoolImpressionDetail from "./schoolImpressionDetail"
import CampaignReport from "./campaignReporting/campaignReport"
import AssignSchoolsToFos from "./assignSchoolsToFos"
import NonWisrCreateCampaign from "./campaign/nonWisrCreateCampaign"
import NonWisrCampaignList from "./nonWisrCampaignList"
import CreateNewCampaignSet from "./campaignReporting/CreateNewCampaignSet"
import CreateNewCampSetDialogBox from './campaignReporting/CreateNewCampSetDialogBox'
import CampaignReportingSetList from './campaignReporting/campaignReportingSetList'
import ExampleImage from "./exampleImage"
import NonWisrCampaignDetail from "./nonWisrCampaignDetail"
import CampaignReportingRetakeImg from './campaignReporting/campaignReportingRetakeImg';
import CampaignExample from "./campaignReporting/campaignExample"
import CampaignReportView from "./campaignReporting/campaignReportingView"
import DisableRightClick from "../brands/DisableRightClick";
import ScrappedSchoolData from "./scrappedSchoolData";
import ScrappedSchoolDetails from "./school/scrapped-school-data-details";
import EditScrapedSchool from "./editScrapedSchool";

const BackendDetails = (props) => {
    const router = useRouteMatch()
    const history = useHistory()
    const context = useContext(AppContextObject)
    const [showSidemenu, setShowSidemenu] = useState(true)
    const [responsiveSidemenu, setResponsiveSidemenu] = useState(false)
    const [data, setData] = useState<any>({
        campaignId: '',
        from: '',
        brandDetail: {
            name: '',
            brandName: '',
            brandSubCategoryName: '',
            startDate: '',
            endDate: '',
            description: '',
            objective: '',
            wisrOffering: {},
            budget: '',
            targetGender: '',
            tagetAgeAudiance: [],
            studyMedium: [],
            brandCatId: '',
            brandSubCatId: '',
            brandcategoryId: '',
            location: []
        },
        campaignDetail: {
            wisrOption: true,
            totalImpression: 0,
            totalReach: 0,
            totalBudget: 0,
            boysRatio: 0,
            girlsRatio: 0
        },
        campaignCreative: {
            creatives: { image1: '', image2: '', image3: '' }
        },
        inventory: {
            inventories: []
        },
        school: {
            schools: []
        },
        eventAndOccation: {
            eventAndOccations: []
        },
        activities: {
            events: []
        },
        isTrial: false
    })
    const [nonWisrCampaignData, setNonWisrCampaignData] = useState({
        brandDetail: {
            brandId: "",
            campaign: {name: "", _id: ""},
            name: "",
            startDate: "",
            endDate: "",
            description: "",
            objective: "",
            inventory: false,
            eventAndOccations: false,
            csr: false,
            sampling: false,
            custom: false,
            // budget: 1,
            targetGender: "both",
            targetAgeGroup: {min: 3, max: 4},
            studyMedium: [],
            brandCategory: {value: "", label: ""},
            brandSubcategory: {value: "", label: ""},
            brand: {_id: "", name: "", label: "", type: "", value: ""},
            state: [],
            city: [],
            campaignNameOptions: [],
            brandNameOptions: [],
            brandName: "",
            isTrial: false
        },
        campaignDetail: {
            wisrOption: true,
            totalImpression: 0,
            totalReach: 0,
            totalBudget: 0,
            boysRatio: 0,
            girlsRatio: 0
        },
        campaignCreative: {
            creatives: { image1: '', image2: '', image3: '' }
        },
        inventory: {
            inventories: []
        },
        school: {
            schools: []
        },
        eventAndOccation: {
            eventAndOccations: []
        },
        activities: {
            events: []
        },
        isTrial: false
    })
    const [progress, setProgress] = useState({
        brandDetails: false,
        inventory: false,
        school: false,
        recommendedSchool: false,
        event: false,
        campaignCreative: false,
    })
    const [nonWisrCampaignProgress, setNonWisrCampaignProgress] = useState({
        brandDetails: false,
        inventory: false,
        event: false,
        school: false,
        campaignCreative: false,
        preview: false
    })
    const [activeModule, setActiveModule] = useState({
        module: "",
        subModule: ""
    })
    const [nonWisrCampaignActiveModule, setNonWisrCampaignActiveModule] = useState({
        module: "",
        subModule: ""
    })

    const toggleResponsiveSidemenu = () => {
        setResponsiveSidemenu(!responsiveSidemenu)
    }
    const myValue: any = {
        setData,
        data,
        nonWisrCampaignData,
        setNonWisrCampaignData,
        setProgress,
        progress,
        nonWisrCampaignProgress,
        setNonWisrCampaignProgress,
        setActiveModule,
        activeModule,
        nonWisrCampaignActiveModule,
        setNonWisrCampaignActiveModule,
        setShowSidemenu,
        showSidemenu,
        responsiveSidemenu,
        toggleResponsiveSidemenu,
    }
    const [isSchoolListDownload, setIsSchoolListDownload] = useState(false)

    const [isSchoolListDownload1, setIsSchoolListDownload1] = useState(false)

    if (!context.apis || !context?.user?.role || !context?.socketManager.socket.connected) {
        document.location.reload()
    }
    if(context.user.role === "backendFOS"){
        hotkeys('shift+ctrl+c, shift+command+c', function(event) {
            event.preventDefault();
            return false;
        });

        hotkeys('ctrl+c, command+c', function(event) {
            event.preventDefault();
            return false;
        });

        hotkeys('ctrl+u, command+u', function(event) {
            event.preventDefault();
            return false;
        });
    }
    useEffect(() => {
        // redirecting user to user verification screen if isVerifed is false
        if (context && context.user && context.user.isVerified === false) {
              window.location.href = '/user-verification'
        }
    }, [])

    useEffect(() => {
        if (!history.location.pathname.startsWith("/backend/backend-details/schools/onboarding") && !history.location.pathname.startsWith("/backend/backend-details/add-invetory") && !history.location.pathname.startsWith('/backend/backend-details/add-nonWisr-school') && !history.location.pathname.startsWith('/backend/backend-details/edit-nonWisr-school') && !history.location.pathname.startsWith('/backend/backend-details/edit-scraped-school') && !history.location.pathname.startsWith('/backend/backend-details/school-impression-details')
        ) {
            setShowSidemenu(true)
        }
        if (history.location.pathname.startsWith("/backend/backend-details/view-campaign") || history.location.pathname.startsWith("/backend/backend-details/nonWisr-create-campaign")) {
            setShowSidemenu(false)
            setResponsiveSidemenu(false)
        }
        if (!history.location.pathname.startsWith("/backend/backend-details/nonWisr-create-campaign")) {
            setNonWisrCampaignData({
                brandDetail: {
                    brandId: "",
                    campaign: {name: "", _id: ""},
                    name: "",
                    startDate: "",
                    endDate: "",
                    description: "",
                    objective: "",
                    inventory: false,
                    eventAndOccations: false,
                    csr: false,
                    sampling: false,
                    custom: false,
                    // budget: 1,
                    targetGender: "both",
                    targetAgeGroup: {min: 3, max: 4},
                    studyMedium: [],
                    brandCategory: {value: "", label: ""},
                    brandSubcategory: {value: "", label: ""},
                    brand: {_id: "", name: "", label: "", type: "", value: ""},
                    state: [],
                    city: [],
                    campaignNameOptions: [],
                    brandNameOptions: [],
                    brandName: "",
                    isTrial: false
                },
                campaignDetail: {
                    wisrOption: true,
                    totalImpression: 0,
                    totalReach: 0,
                    totalBudget: 0,
                    boysRatio: 0,
                    girlsRatio: 0
                },
                campaignCreative: {
                    creatives: { image1: '', image2: '', image3: '' }
                },
                inventory: {
                    inventories: []
                },
                school: {
                    schools: []
                },
                eventAndOccation: {
                    eventAndOccations: []
                },
                activities: {
                    events: []
                },
                isTrial: false
            })
            setNonWisrCampaignProgress({
                brandDetails: false,
                inventory: false,
                event: false,
                school: false,
                campaignCreative: false,
                preview: false
            })
            setNonWisrCampaignActiveModule({
                module: "",
                subModule: ""
            })
        }
        if (history.location.pathname.startsWith("/backend/backend-details/brands/brand-details")) {
            setShowSidemenu(false)
            setResponsiveSidemenu(false)
        }
        if(context.user.role==='backendFOS' && history){
            console.log('history',history,history.action)
            if(history && history.action==='POP'){
                // history.replace("/backend/backend-details/fos-details") // commented because when clicking on back button it is redirecting to backend/backend-details/fos-details page
            }
        }
        //user-wise url access permission
        if(context.user.role === "backendFOS"){
            if(history.location.pathname === "/backend/backend-details/fos-details" || history.location.pathname === "/backend/backend-details/fos-school-details" || history.location.pathname === "/backend/backend-details/fos-school/school-details" || history.location.pathname === "/backend/backend-details/campaign-reporting-list" || history.location.pathname === "/backend/backend-details/campaign-school-list" || history.location.pathname === "/backend/backend-details/create-new-camp-set" || history.location.pathname.startsWith("/backend/backend-details/schools/onboarding") || history.location.pathname === "/backend/backend-details/add-invetory/remaining-invetories" || history.location.pathname === "/backend/backend-details/add-invetory/invetories-details" || history.location.pathname === "/backend/backend-details/add-invetory/school-details-summary" || history.location.pathname === "/backend/backend-details/add-invetory/events-and-occasions" || history.location.pathname === "/backend/backend-details/add-invetory/events-and-occasions-details"){

            }
            else{
                window.location.href = "/backend/backend-details/fos-details"
            }
        }

        //user-wise url access permission for sales
        if(context.user.role === "backendSales"){
            if(history.location.pathname === "/backend/backend-details/scrapped-school-data"){
                window.location.href = "/backend/backend-details/dashboard"
            }
            
        }

    }, [history.action,history.location.pathname])
    useEffect(() => {
        // if(history.location.pathname.startsWith("/backend/backend-details/add-invetory")){
           context.validator.add("school", true)
        // }
    }, [history.location.pathname])


    console.log("showSidemenu", showSidemenu);
    const toggleSidemenu = () => {
        setShowSidemenu(!showSidemenu)
    }
    return (
        <>
            <DataContextProvider value={myValue}>
                {context.user.role === "backendFOS" && <DisableRightClick />}
                <Switch>
                    <Route path={`${router.url}/schools/onboarding`}>
                        <Onboarding addSchoolFromBackend={true} setShowBackendSidemenu={setShowSidemenu} />
                    </Route>
                    <Route path={`${router.url}/add-invetory`}>
                        <AddInventory fromBackend={true} setShowBackendSidemenu={setShowSidemenu} />
                    </Route>
                </Switch>
                <div className={`ovelap ${responsiveSidemenu === true ? "active" : ""}`}></div>
                <div className="main-wapper">
                    <div className="container">
                        <div className="row">
                            {
                                showSidemenu === true &&
                                <BackendDetailsSideMenu
                                setIsSchoolListDownload={setIsSchoolListDownload}
                                isSchoolListDownload={isSchoolListDownload}
                                setIsSchoolListDownload1={setIsSchoolListDownload1}
                                isSchoolListDownload1={isSchoolListDownload1}
                                />
                            }
                            {isSchoolListDownload ? <Loader /> :
                            <Switch>
                                <Route path={`${router.url}/dashboard`}>
                                    <Dashboard />
                                </Route>

                                <Route path={`${router.url}/school-list`}>
                                    <SchoolList />
                                </Route>

                                <Route path={`${router.url}/school-campaign-reject`}>
                                    <CampaignsReject />
                                </Route>

                                <Route path={`${router.url}/inventory-list`}>
                                    <InventoryList />
                                </Route>

                                <Route path={`${router.url}/campaign-list`}>
                                    <CampaignList onlyActiveCampaign={false}/>
                                </Route>
                                <Route path={`${router.url}/campaign/specific-campaign`}>
                                    <SpecificCampaignList />
                                </Route>
                                {/* <Route path={`${router.url}/campaign/campaign`}>
                                    <CampaignTest />
                                </Route> */}

                                <Route path={`${router.url}/brand-list`}>
                                    <BrandList />
                                </Route>
                                <Route path={`${router.url}/brand-details`}>
                                    <DetailsBrand />
                                </Route>
                                <Route path={`${router.url}/brand/campaign-details`}>
                                    <CreateTrialCampaignDetails />
                                </Route>
                                <Route path={`${router.url}/fos-list`}>
                                    <FosList />
                                </Route>
                                <Route path={`${router.url}/activity-status-list`}>
                                    <ActivityStatusList />
                                </Route>
                                <Route path={`${router.url}/user-list`}>
                                    <UserList />
                                </Route>
                                <Route path={`${router.url}/fos-details`}>
                                    <DetailsFOS onlyActiveSchools={false} />
                                </Route>
                                <Route path={`${router.url}/fos-school-details`}>
                                    <SchoolDetails />
                                </Route>

                                <Route path={`${router.url}/campaign-reject`}>
                                    <CampaignsReject />
                                </Route>
                                <Route path={`${router.url}/create-trial-campaign`}>
                                    <CreateTrialCampaign />
                                </Route>

                                <Route path={`${router.url}/campaign-request`}>
                                    <CampaignsRequest />
                                </Route>

                                <Route path={`${router.url}/campaign-ongoing`}>
                                    <CampaignsOngoing />
                                </Route>

                                <Route path={`${router.url}/campaign/campaign-details`}>
                                    <CampaignDetails />
                                </Route>
                                <Route path={`${router.url}/create-trial-campaign-details`}>
                                    <CreateTrialCampaignDetails />
                                </Route>
                                {/* <Route path={`${router.url}/create-trial-campaign-schools`}>
                                    <SchoolsList />
                                </Route> */}
                                <Route path={`${router.url}/school-details`}>
                                    <SchoolDetails />
                                </Route>
                                <Route path={`${router.url}/fos-school/school-details`}>
                                    <SchoolDetails />
                                </Route>

                                <Route path={`${router.url}/view-campaign`}>
                                    <CampaignCreation />
                                </Route>
                                <Route path={`${router.url}/nonWisr-create-campaign`}>
                                    <NonWisrCreateCampaign />
                                </Route>
                                <Route path={`${router.url}/brands/brand-details`}>
                                    <CreateBrandBackend
                                    router={`${router.url}/brands/brand-details`}
                                    activeModule={activeModule}
                                    setShowSidemenu={setShowSidemenu}
                                    showSidemenu={showSidemenu}
                                    // progress={progress}
                                    />
                                </Route>
                                <Route path={`${router.url}/campaign-rejected`}>
                                    <CampaignRejectedBySchool />
                                </Route>
                                <Route path={`${router.url}/nonWisr-school-list`}>
                                    <NonWisrSchoolList />
                                </Route>
                                <Route path={`${router.url}/scrapped-school-data`}>
                                    <ScrappedSchoolData />
                                </Route>
                                <Route path={`${router.url}/schoolInfraDetails`}>
                                    <SchoolInfraDetail />
                                </Route>
                                <Route path={`${router.url}/add-nonWisr-school`}>
                                    <AddNonWisrSchool setShowSidemenu={setShowSidemenu} showSidemenu={showSidemenu} toggleSidemenu={toggleSidemenu} />
                                </Route>
                                <Route path={`${router.url}/edit-nonWisr-school`}>
                                    <AddNonWisrSchool setShowSidemenu={setShowSidemenu} showSidemenu={showSidemenu} toggleSidemenu={toggleSidemenu} />
                                </Route>
                                <Route path={`${router.url}/edit-scraped-school`}>
                                    <EditScrapedSchool setShowSidemenu={setShowSidemenu} showSidemenu={showSidemenu} toggleSidemenu={toggleSidemenu} />
                                </Route>
                                <Route path={`${router.url}/nonWisr-school-details`}>
                                    <NonWisrSchoolDetails />
                                </Route>
                                <Route path={`${router.url}/scrapped-school-data-details`}>
                                    <ScrappedSchoolDetails />
                                </Route>
                                <Route path={`${router.url}/school-impression-details`}>
                                    <SchoolImpressionDetail setShowSidemenu={setShowSidemenu}/>
                                </Route>
                                <Route path={`${router.url}/campaign-reporting-view`}>
                                    <CampaignReportView setShowSidemenu={setShowSidemenu}/>
                                </Route>
                                <Route path={`${router.url}/campaign-reporting`}>
                                    <CampaignReport setShowSidemenu={setShowSidemenu}/>
                                </Route>
                                <Route path={`${router.url}/active-schools`}>
                                    <DetailsFOS onlyActiveSchools={true} />
                                </Route>
                                <Route path={`${router.url}/active-campaigns`}>
                                    <CampaignList onlyActiveCampaign={true}/>
                                </Route>
                                {/* custom route start */}
                                <Route path={`${router.url}/campaign-reporting-list`}>
                                    <CampaignReportingList onlyActiveCampaign={true}/>
                                </Route>
                                <Route path={`${router.url}/campaign-school-list`}>
                                    <CampaignSchoolList onlyActiveCampaign={true}/>
                                </Route>
                                <Route path={`${router.url}/create-new-camp-set`}>
                                    <CreateNewCampaignSet onlyActiveCampaign={true}/>
                                </Route>
                                <Route path={`${router.url}/create-new-camp-set-dialog-box`}>
                                    <CreateNewCampSetDialogBox onlyActiveCampaign={true}/>
                                </Route>
                                <Route path={`${router.url}/campaign-report-set-list`}>
                                    <CampaignReportingSetList onlyActiveCampaign={true}/>
                                </Route>
                                {/* custom route ends */}
                                <Route path={`${router.url}/assignSchoolToFos`}>
                                    <AssignSchoolsToFos />
                                </Route>
                                <Route path={`${router.url}/nonWisr-campaign-list`}>
                                    <NonWisrCampaignList />
                                </Route>
                                <Route path={`${router.url}/nonWisr-campaign-detail`}>
                                    <NonWisrCampaignDetail />
                                </Route>
                                {/* <Route path={`${router.url}/example-image`}>
                                    <ExampleImage />
                                </Route> */}
                                <Route path={`${router.url}/campaign-reporting-retake-image`}>
                                    <CampaignReportingRetakeImg />
                                </Route>
                                <Route path={`${router.url}/campaign-example`}>
                                    <CampaignExample />
                                </Route>
                                {/* <Route path={`${router.url}/schools/onboarding`}>
                                    <Onboarding addSchoolFromBackend={true} setShowBackendSidemenu={setShowSidemenu} />
                                </Route> */}
                            </Switch>
                            }
                        </div>
                    </div>
                </div>
            </DataContextProvider>
        </>
    )
}

export default BackendDetails
