import { AppContext } from "../../common/context/common";
import { Validator } from '../../common/utils/validator.js';
import { Utilities } from '../../common/utils/utilities';

export class PublicContextInitiator {
    utils: Utilities;
    constructor(private appContext: AppContext){ this.utils = new Utilities(); }
    async getAllCitiesAndStatesByRegion (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'countryRegion', args, ()=>{return true;}, false);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('stateDetailsWithRegion->getAllCitiesAndStatesByRegion', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getSchoolSettings (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('schoolSettingsMaster->getSchoolSettings', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getAllCitiesAndStatesByNonWisrSchool (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('nonWisrSchool->getAllCitiesAndStatesByNonWisrSchool', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getAllFOSUsers (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'region', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'city', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'searchValue', args, ()=>{return true;}, false);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('user->getAllFOSUsers', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async mySchoolCalendarData (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'startDate', args, ()=>{return true;}, true);
        this.utils.addIfFound(validator, argsData, 'endDate', args, ()=>{return true;}, true);
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('school->mySchoolCalendarData', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getAllCitiesBySchool (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('school->getAllCitiesBySchool', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getAllCitiesAndStatesBySchool (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('school->getAllCitiesAndStatesBySchool', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getAllCitiesByUser (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'role', args, ()=>{return true;}, false);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('user->getAllCitiesByUser', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getUsersListByRole (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'role', args, ()=>{return true;}, true);
        this.utils.addIfFound(validator, argsData, 'searchValue', args, ()=>{return true;}, false);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('user->getUsersListByRole', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getSiteSettings (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
    
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('siteSettings->getSiteSettings', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async listBrandCategoriesMaster (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'status', args, ()=>{return true;}, false);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('brandCategoryMaster->listBrandCategoriesMaster', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getBrandCategoriesDetails (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('brandCategoryMaster->getBrandCategoriesDetails', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getAllBrands (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'searchValue', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'city', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'documents', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'brandType', args, ()=>{return true;}, false);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('brand->getAllBrands', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getAllBrandsForExport (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'searchValue', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'city', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'documents', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'brandType', args, ()=>{return true;}, false);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('brand->getAllBrandsForExport', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getBrandStats (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('brand->getBrandStats', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getAllInventoriesFromMaster (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'type', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'searchValue', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'inventoryType', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'numOfDays', args, ()=>{return true;}, false);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('inventoriesMaster->getAllInventoriesFromMaster', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getBrandById (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('brand->getBrandById', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getBrandByIdExternal (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('brand->getBrandByIdExternal', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getAllBrandsExternal (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('brand->getAllBrandsExternal', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getCampaignById (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('campaign->getCampaignById', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getAllSchools (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'searchValue', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'city', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'state', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'schoolType', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'language', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'board', args, ()=>{return true;}, false);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('school->getAllSchools', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getAllSchoolsNew (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'searchValue', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'city', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'state', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'schoolType', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'language', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'board', args, ()=>{return true;}, false);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('schoolDataFlattened->getAllSchoolsNew', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getAllSchoolEventsExternal (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'school', args, ()=>{return true;}, false);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('activitiesBeta->getAllSchoolEventsExternal', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getAllSchoolsForBackendUsers (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'searchValue', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'city', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'schoolType', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'language', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'board', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'category', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'inventoryStatus', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'schoolStatus', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'tabStatus', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'campaignStatus', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'locations', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'IsDownload', args, ()=>{return true;}, false);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('school->getAllSchoolsForBackendUsers', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getSchoolDetailsById (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('school->getSchoolDetailsById', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getSchoolsStatsForBackendAdmin (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('school->getSchoolsStatsForBackendAdmin', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getAllActivitiesWithRecommendation (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'months', args, ()=>{return true;}, true);
        this.utils.addIfFound(validator, argsData, 'brandCategory', args, ()=>{return true;}, false);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('activities->getAllActivitiesWithRecommendation', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getAllRecemmendedSchools (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'searchValue', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'city', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'schoolType', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'language', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'board', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'inventories', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'targetGender', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'targetAgeGroup', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'studyMedium', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'startDate', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'endDate', args, ()=>{return true;}, false);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('school->getAllRecemmendedSchools', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getCampaignsBySchool (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'schoolId', args, ()=>{return true;}, true);
        this.utils.addIfFound(validator, argsData, 'schoolStatus', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'isUpcoming', args, ()=>{return true;}, false);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('campaign->getCampaignsBySchool', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getCampaignsByBrand (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'brandId', args, ()=>{return true;}, true);
        this.utils.addIfFound(validator, argsData, 'isUpcoming', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'isOngoing', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'status', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'userId', args, ()=>{return true;}, false);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('campaign->getCampaignsByBrand', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getCampaignsByBrandExternal (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'brandId', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'searchValue', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'campaignType', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'dateFilter', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'school', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'status', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'paymentStatus', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'campaignStatus', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'dateCreatedFilter', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'createdDate', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'startDate', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'tabStatus', args, ()=>{return true;}, false);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('campaign->getCampaignsByBrandExternal', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getBrandCampaignStats (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'brandId', args, ()=>{return true;}, true);
        this.utils.addIfFound(validator, argsData, 'userId', args, ()=>{return true;}, false);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('campaign->getBrandCampaignStats', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getCampaignStatsForBackendAdmin (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('campaign->getCampaignStatsForBackendAdmin', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getSchoolStatsForBackendAdminDashboard (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'city', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'category', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'dateFilter', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'startDate', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'endDate', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'scale', args, ()=>{return true;}, false);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('school->getSchoolStatsForBackendAdminDashboard', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getInventoryStatsForBackendAdminDashboard (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'location', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'dateFilter', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'startDate', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'endDate', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'scale', args, ()=>{return true;}, false);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('inventories->getInventoryStatsForBackendAdminDashboard', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getBrandStatsForBackendAdminDashboard (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'location', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'dateFilter', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'startDate', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'endDate', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'scale', args, ()=>{return true;}, false);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('brand->getBrandStatsForBackendAdminDashboard', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getCampaignStatsForBackendAdminDashboard (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'location', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'dateFilter', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'brandCategory', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'startDate', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'endDate', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'scale', args, ()=>{return true;}, false);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('campaign->getCampaignStatsForBackendAdminDashboard', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getBrandCampaignReachStats (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'brandId', args, ()=>{return true;}, true);
        this.utils.addIfFound(validator, argsData, 'month', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'userId', args, ()=>{return true;}, false);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('campaign->getBrandCampaignReachStats', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getCities (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('city->getCities', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getStates (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('state->getStates', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async findUserByUsername (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'username', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('user->findUserByUsername', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getUserById (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'school', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'brand', args, ()=>{return true;}, false);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('user->getUserById', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getInventories (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('inventories->getInventories', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getInventoriesByType (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'type', args, ()=>{return true;}, true);
        this.utils.addIfFound(validator, argsData, 'school', args, ()=>{return true;}, false);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('inventories->getInventoriesByType', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getInventoriesBySchoolId (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'school', args, ()=>{return true;}, true);
        this.utils.addIfFound(validator, argsData, 'type', args, ()=>{return true;}, false);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('inventories->getInventoriesBySchoolId', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getSuggestedInventoriesForBrandCampaign (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'brandCategory', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'numOfDays', args, ()=>{return true;}, false);
    
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('brandCategoryRecommendation->getSuggestedInventoriesForBrandCampaign', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getInventoriesImages (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'inventoryIds', args, ()=>{return true;}, false);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('inventories->getInventoriesImages', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getInventoryAttributesById (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('inventoryAttributes->getInventoryAttributesById', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getAttributesById (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('activitiesAttribute->getAttributesById', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async renderMOUContract (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'school', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('mouTemplateData->renderMOUContract', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async renderCampaignMOUContract (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'campaign', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('campaignMouTemplateData->renderCampaignMOUContract', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getPincodeDetails (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'pincode', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('pincode->getPincodeDetails', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getStatesAndCitiesFromPincode (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('pincode->getStatesAndCitiesFromPincode', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getMultiplePincodeDetails (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'pincode', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('pincode->getMultiplePincodeDetails', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getCitiesByCountryRegion (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'countryRegion', args, ()=>{return true;}, false);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('pincode->getCitiesByCountryRegion', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getUserCitiesByCountryRegion (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'countryRegion', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'userRole', args, ()=>{return true;}, false);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('pincode->getUserCitiesByCountryRegion', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getStatesForBrand (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('pincode->getStatesForBrand', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getCitiesByStateForBrand (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'states', args, ()=>{return true;}, false);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('pincode->getCitiesByStateForBrand', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getStatesByCountryRegion (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'countryRegion', args, ()=>{return true;}, false);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('pincode->getStatesByCountryRegion', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getCitiesByState (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'states', args, ()=>{return true;}, false);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('pincode->getCitiesByState', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async geLocationsByCity (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'cities', args, ()=>{return true;}, false);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('pincode->geLocationsByCity', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getBrandUpdates (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFoundInArray(validator, argsData, 'brandUsersSeen', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('brandUpdates->getBrandUpdates', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getAllUsersForBackend (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'city', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'role', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'search', args, ()=>{return true;}, false);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('user->getAllUsersForBackend', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getUserBySchoolIdByRole (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'school', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'role', args, ()=>{return true;}, false);
    
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('user->getUserBySchoolIdByRole', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getAllInventoriesForBackend (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'type', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'search', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'category', args, ()=>{return true;}, false);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('inventoriesMaster->getAllInventoriesForBackend', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async addUser (validator: Validator, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let newData = {};
    
        this.utils.addIfFound(validator, newData, 'fullName', data, ()=>{return true;}, true);
        this.utils.addIfFound(validator, newData, 'email', data, ()=>{return true;}, true);
        this.utils.addIfFound(validator, newData, 'phone', data, ()=>{return true;}, true);
        this.utils.addIfFound(validator, newData, 'school', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'brand', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'role', data, ()=>{return true;}, true);
        this.utils.addIfFound(validator, newData, 'countryCode', data, ()=>{return true;}, false);
        this.utils.addIfFoundInArray(validator, newData, 'mutedNotificationCategories', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'username', data, ()=>{return true;}, true);
        this.utils.addIfFound(validator, newData, 'companyName', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'designation', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'companyEmail', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'accountStatus', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'region', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'city', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'pincode', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'state', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'country', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('user->addUser', {data: newData});
        } else {
            return null;
        }
    }
    async updateUserById (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, newData, 'fullName', data, ()=>{return true;}, true);
        this.utils.addIfFound(validator, newData, 'email', data, ()=>{return true;}, true);
        this.utils.addIfFound(validator, newData, 'username', data, ()=>{return true;}, true);
        this.utils.addIfFound(validator, newData, 'phone', data, ()=>{return true;}, true);
        this.utils.addIfFound(validator, newData, 'school', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'role', data, ()=>{return true;}, true);
        this.utils.addIfFound(validator, newData, 'countryCode', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'isVerified', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'city', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'state', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'region', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('user->updateUserById', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async deleteUserById (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
    
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('user->deleteUserById', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async changeUserPasswordExternal (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, newData, 'password', data, ()=>{return true;}, false);
        this.utils.addIfFoundInArray(validator, newData, 'oldPasswords', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'isVerified', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('user->changeUserPasswordExternal', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async updateAccountStatusOfBackendFOS (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, newData, 'accountStatus', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('user->updateAccountStatusOfBackendFOS', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async addClassrooms (validator: Validator, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let newData = {};
    
        this.utils.addIfFound(validator, newData, 'class', data, ()=>{return true;}, true);
        this.utils.addIfFound(validator, newData, 'noOfSections', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'ageByStandard', data, ()=>{return true;}, true);
        this.utils.addIfFound(validator, newData, 'boys', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'girls', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'avgAnnualFee', data, ()=>{return true;}, true);
        this.utils.addIfFound(validator, newData, 'school', data, ()=>{return true;}, true);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('classrooms->addClassrooms', {data: newData});
        } else {
            return null;
        }
    }
    async addInventories (validator: Validator, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let newData = {};
    
        this.utils.addIfFound(validator, newData, 'type', data, ()=>{return true;}, true);
        this.utils.addIfFound(validator, newData, '_custom', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, '_checked', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'name', data, ()=>{return true;}, true);
        this.utils.addIfFound(validator, newData, 'customName', data, ()=>{return true;}, false);
        this.utils.addIfFoundInArray(validator, newData, 'images', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'school', data, ()=>{return true;}, true);
        this.utils.addIfFound(validator, newData, 'parentName', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'quantity', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'status', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'inventoryMaster', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'isApproved', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('inventories->addInventories', {data: newData});
        } else {
            return null;
        }
    }
    async updateInventories (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, newData, 'type', data, ()=>{return true;}, true);
        this.utils.addIfFound(validator, newData, '_custom', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, '_checked', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'name', data, ()=>{return true;}, true);
        this.utils.addIfFound(validator, newData, 'customName', data, ()=>{return true;}, false);
        this.utils.addIfFoundInArray(validator, newData, 'images', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'school', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'parentName', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'quantity', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'status', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'isApproved', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('inventories->updateInventories', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async addInventoryAttributes (validator: Validator, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let newData = {};
    
        this.utils.addIfFound(validator, newData, 'name', data, ()=>{return true;}, true);
        this.utils.addIfFound(validator, newData, '_isCustom', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'units', data, ()=>{return true;}, true);
        this.utils.addIfFound(validator, newData, 'length', data, ()=>{return true;}, true);
        this.utils.addIfFound(validator, newData, 'breadth', data, ()=>{return true;}, true);
        this.utils.addIfFound(validator, newData, 'height', data, ()=>{return true;}, true);
        this.utils.addIfFound(validator, newData, 'inventory', data, ()=>{return true;}, true);
        this.utils.addIfFound(validator, newData, 'quantity', data, ()=>{return true;}, true);
        this.utils.addIfFound(validator, newData, 'inventoryMaster', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'inventoryMasterAttrSlug', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('inventoryAttributes->addInventoryAttributes', {data: newData});
        } else {
            return null;
        }
    }
    async updateInventoryAttributes (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, newData, 'name', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, '_isCustom', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'units', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'length', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'breadth', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'height', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'inventory', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'quantity', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('inventoryAttributes->updateInventoryAttributes', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async addActivities (validator: Validator, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let newData = {};
    
        this.utils.addIfFound(validator, newData, 'name', data, ()=>{return true;}, true);
        this.utils.addIfFoundInArray(validator, newData, 'venue', data, ()=>{return true;}, true);
        this.utils.addIfFoundInArray(validator, newData, 'otherVenueList', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'custom', data, ()=>{return true;}, true);
        this.utils.addIfFoundInArray(validator, newData, 'months', data, ()=>{return true;}, true);
        this.utils.addIfFound(validator, newData, 'availableForBranding', data, ()=>{return true;}, true);
        this.utils.addIfFound(validator, newData, 'school', data, ()=>{return true;}, true);
        this.utils.addIfFound(validator, newData, 'status', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'isApproved', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('activities->addActivities', {data: newData});
        } else {
            return null;
        }
    }
    async addActivitiesAttribute (validator: Validator, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let newData = {};
    
        this.utils.addIfFound(validator, newData, 'name', data, ()=>{return true;}, true);
        this.utils.addIfFound(validator, newData, 'isCustom', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'availableForBranding', data, ()=>{return true;}, true);
        this.utils.addIfFound(validator, newData, 'allowBrandsToProvide', data, ()=>{return true;}, true);
        this.utils.addIfFound(validator, newData, 'activity', data, ()=>{return true;}, true);
        this.utils.addIfFound(validator, newData, 'isSelected', data, ()=>{return true;}, true);
        this.utils.addIfFoundInArray(validator, newData, 'images', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'length', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'breadth', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'height', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'units', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'quantity', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('activitiesAttribute->addActivitiesAttribute', {data: newData});
        } else {
            return null;
        }
    }
    async addBrand (validator: Validator, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let newData = {};
    
        this.utils.addIfFound(validator, newData, 'logo', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'brandName', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'brandType', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'website', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'documents', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'address', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'contactNumber', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'country', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'city', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'state', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'zip', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'description', data, ()=>{return true;}, false);
        this.utils.addIfFoundInArray(validator, newData, 'attributes', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'socialMediaLinks', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('brand->addBrand', {data: newData});
        } else {
            return null;
        }
    }
    async updateBrandUpdates (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
        this.utils.addIfFound(validator, argsData, 'userId', args, ()=>{return true;}, true);
    
        this.utils.addIfFoundInArray(validator, newData, 'brandUsersSeen', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('brandUpdates->updateBrandUpdates', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async addContactUsUser (validator: Validator, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let newData = {};
    
        this.utils.addIfFound(validator, newData, 'name', data, ()=>{return true;}, true);
        this.utils.addIfFound(validator, newData, 'email', data, ()=>{return true;}, true);
        this.utils.addIfFound(validator, newData, 'message', data, ()=>{return true;}, true);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('contactUs->addContactUsUser', {data: newData});
        } else {
            return null;
        }
    }
    async saveBrandCategoryMaster (validator: Validator, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let newData = {};
    
        this.utils.addIfFound(validator, newData, 'name', data, ()=>{return true;}, true);
        this.utils.addIfFound(validator, newData, 'description', data, ()=>{return true;}, true);
        this.utils.addIfFound(validator, newData, 'status', data, ()=>{return true;}, true);
        this.utils.addIfFound(validator, newData, 'parentCategory', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'isParent', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('brandCategoryMaster->saveBrandCategoryMaster', {data: newData});
        } else {
            return null;
        }
    }
    async updateCampaign (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, newData, 'name', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'description', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'isTrial', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'startDate', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'endDate', data, ()=>{return true;}, false);
        this.utils.addIfFoundInArray(validator, newData, 'locations', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'targetGender', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'targetAgeGroup', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'studyMedium', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'brandCategory', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'brandSubcategory', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'recommendEvents', data, ()=>{return true;}, false);
        this.utils.addIfFoundInArray(validator, newData, 'inventories', data, ()=>{return true;}, false);
        this.utils.addIfFoundInArray(validator, newData, 'activities', data, ()=>{return true;}, false);
        this.utils.addIfFoundInArray(validator, newData, 'schools', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'schoolStatus', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'brandStatus', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'rejectionMessage', data, ()=>{return true;}, false);
        this.utils.addIfFoundInArray(validator, newData, 'campaignCreatives', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'campaignStatus', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'objective', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('campaign->updateCampaign', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async createActivityStatus (validator: Validator, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let newData = {};
    
        this.utils.addIfFound(validator, newData, 'type', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'message', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'portal', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'activityData', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'activityDoneBy', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'performerName', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('activityStatus->createActivityStatus', {data: newData});
        } else {
            return null;
        }
    }
    async deleteSchoolEventsExternal (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
        this.utils.addIfFound(validator, argsData, 'school', args, ()=>{return true;}, true);
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
    
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('activities->deleteSchoolEventsExternal', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async deleteSchoolInventoriesExternal (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
        this.utils.addIfFound(validator, argsData, 'school', args, ()=>{return true;}, true);
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
    
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('inventories->deleteSchoolInventoriesExternal', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async updateSchoolEventsExternal (validator: Validator, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('activitiesBeta->updateSchoolEventsExternal', {data: data});
        } else {
            return null;
        }
    }
    async manageSchoolDetails (validator: Validator, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('school->manageSchoolDetails', {data: data});
        } else {
            return null;
        }
    }
    async sendOTPOnEmail (validator: Validator, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('school->sendOTPOnEmail', {data: data});
        } else {
            return null;
        }
    }
    async sendResetPasswordLinkOnEmail (validator: Validator, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('school->sendResetPasswordLinkOnEmail', {data: data});
        } else {
            return null;
        }
    }
    async sendOTPOnPhone (validator: Validator, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('school->sendOTPOnPhone', {data: data});
        } else {
            return null;
        }
    }
    async reSendOTPOnEmail (validator: Validator, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('school->reSendOTPOnEmail', {data: data});
        } else {
            return null;
        }
    }
    async reSendOTPOnPhone (validator: Validator, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('school->reSendOTPOnPhone', {data: data});
        } else {
            return null;
        }
    }
    async onboardingSchoolPhoneOTPVerification (validator: Validator, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('school->onboardingSchoolPhoneOTPVerification', {data: data});
        } else {
            return null;
        }
    }
    async onboardingSchoolEmailOTPVerification (validator: Validator, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('school->onboardingSchoolEmailOTPVerification', {data: data});
        } else {
            return null;
        }
    }
    async getSchoolYourDetailsByUserId (validator: Validator, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('school->getSchoolYourDetailsByUserId', {data: data});
        } else {
            return null;
        }
    }
    async updateSchoolYourDetailsByUserId (validator: Validator, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('school->updateSchoolYourDetailsByUserId', {data: data});
        } else {
            return null;
        }
    }
    async loginUser (validator: Validator, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('user->loginUser', {data: data});
        } else {
            return null;
        }
    }
    async logoutUser (validator: Validator, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('user->logoutUser', {data: data});
        } else {
            return null;
        }
    }
    async forgetPasswordForUser (validator: Validator, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('user->forgetPasswordForUser', {data: data});
        } else {
            return null;
        }
    }
    async resendOTPForUser (validator: Validator, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('user->resendOTPForUser', {data: data});
        } else {
            return null;
        }
    }
    async UserEmailOTPVerification (validator: Validator, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('user->userEmailOTPVerification', {data: data});
        } else {
            return null;
        }
    }
    async updateSchoolAmenityDetailsBySchoolId (validator: Validator, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('classrooms->updateSchoolAmenityDetailsBySchoolId', {data: data});
        } else {
            return null;
        }
    }
    async upsertSchoolInventoriesDetailsBySchoolId (validator: Validator, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('inventories->upsertSchoolInventoriesDetailsBySchoolId', {data: data});
        } else {
            return null;
        }
    }
    async getUploadedImages (validator: Validator, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('inventories->getUploadedImages', {data: data});
        } else {
            return null;
        }
    }
    async getUploadedImagesSignedUrl (validator: Validator, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('inventories->getUploadedImagesSignedUrl', {data: data});
        } else {
            return null;
        }
    }
    async upsertStudentLifeAndActivitiesWithAttributes (validator: Validator, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('activities->upsertStudentLifeAndActivitiesWithAttributes', {data: data});
        } else {
            return null;
        }
    }
    async addBrandWithTeamMember (validator: Validator, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('brand->addBrandWithTeamMember', {data: data});
        } else {
            return null;
        }
    }
    async updateBrandWithTeamMember (validator: Validator, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('brand->updateBrandWithTeamMember', {data: data});
        } else {
            return null;
        }
    }
    async getOnboardingRevenue (validator: Validator, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('inventories->getOnboardingRevenue', {data: data});
        } else {
            return null;
        }
    }
}
