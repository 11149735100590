import { useState, useContext, useEffect, useRef } from "react"
import errorSVG from "../../../assets/images/error.svg";
import { Link, useHistory } from "react-router-dom"
import { PublicContextInitiator } from "../../apis/publicContextInitiator";
import riarrownext from "../../../assets/images/arrow-view.svg";
import SimpleReactValidator from "simple-react-validator";
import AppContextObject from "../../../common/context/common";
import { getPercentageComplition } from "../../../service/helper";
import scriptLoader from 'react-async-script-loader';
import config from "../../../service/config";
import MultiPointMap from "../../../components/addon/multiPointMap";
import InventoryMore from "../../../components/addon/inventoryMore";
import backBtn from "../../../assets/images/back-arrow.svg";
// import SchoolList from "./components/SchoolsList";
import DataContext from "../../../service/backendContext";
// import CampaignRejetionModal from "../components/CampaignRejectionModal";
import blankImg from '../../../assets/images/blank-inventory-img.jpg';
import { formatDateShowMonthBackend } from "../../../service/helper";
import ImageModal from "../../../components/addon/imageModal/imageModal";
import SingleSelect from "../../../components/formFields/singleSelect/singleSelect";
import ConfirmDialog from "../../../components/addon/confirmDialog/confirmDialog";
import BackendBackBtn from "./components/backendBackBtn";
import ImagePreview from "../../../components/addon/imagePreview";
import AlertDialog from "../../../components/addon/alertDialog/alertDialog"
import ArrowView from "../../../assets/images/arrow-view.svg"
import moment from "moment";
import Loader from "../../../components/addon/loader";
import NonWisrSchoolsList from "./components/nonWisrSchoolsList";

const NonWisrCampaignDetail = (props) => {

    const parentData = useContext<any>(DataContext)
    const {
        isScriptLoaded,
        isScriptLoadSucceed
    } = props
    const context = useContext(AppContextObject)
    const history = useHistory()
    const [showPaymentChangeAlert, setShowPaymentChangeAlert] = useState(false)
    const [campaignDetails, setCampaignDetails] = useState<any>({})
    const [reachChartData, setReachChartData] = useState<any>()
    const [impressionChartData, setImpressionChartData] = useState<any>()
    const [tab, setTab] = useState("reach")
    const [pincodeList, setPincodeList] = useState([])
    const [campaignCompletion, setCampaignCompletion] = useState(0)
    const [showInventoryModal, setShowInventoryModal] = useState(false)
    const [inventoryList, setInventoryList] = useState([]);
    const [eventList, setEventList] = useState([])
    const [showSchoolModal, setShowSchoolModal] = useState<any>(false);
    const [isApproved, setIsApproved] = useState(null);
    const [flag, setFlag] = useState(false);
    const [openRejectedModal, setOpenRejectedModal] = useState(false);
    const [rejectionMsg, setRejectionMsg] = useState({ reason: "" });
    const [overlay, setOverlay] = useState(false);
    const [campaignID, setCampaignID] = useState("");
    const [update, forceUpdate] = useState(false);
    const [toggleImage, setToggleImage] = useState(false);
    const [imageIndex, setImageIndex] = useState(0);
    const [toggleReworkCreativeImage, setToggleReworkCreativeImage] = useState(false);
    const [imageReworkCreativeIndex, setImageReworkCreativeIndex] = useState(0);
    const [paymentStatus, setPaymentStatus] = useState({ value: "" })
    const [keepPaymentStatus, setKeepPaymentStatus] = useState("")
    const [acceptingCampaign, setAcceptingCampaign] = useState(false)
    const [rejectingCampaign, setRejectingCampaign] = useState(false)
    const paymentStatusOption = [{ label: "Pending", value: "pending" }, { label: "Paid", value: "paid" }]
    const [campaignPauseStatus, setCampaignPauseStatus] = useState({ value: "" })
    // const campaignPauseStatusOptions = [{label: "Release Inventories", value : "Release Inventories"}, {label: "Resume Campaign", value: "Resume Campaign"}]
    const [keepCampaignPauseStatus, setKeepCampaignPauseStatus] = useState("")
    const [showCampaignPauseStatusChangeAlert, setShowCampaignPauseStatusChangeAlert] = useState(false)
    const [showReleaseInventoriesAlert, setShowReleaseInventoriesAlert] = useState(false)
    const [isCampaignPauseLoading, setIsCampaignPauseLoading] = useState(false)
    const [isInventoryReleaseLoading, setIsInventoryReleaseLoading] = useState(false)
    const [showAlertMessage, setShowAlertMessage] = useState(false)
    const [alertMessage, setAlertMessage] = useState("Campaigns Inventories Released")
    const [isLoading, setIsloading] = useState(false)
    const [assignedFosList, setAssignedFosList] = useState([])

    const [formValidator] = useState(useRef(new SimpleReactValidator({
        element: (message) =>
            <span className="valError"><img src={errorSVG} />{message}</span>
    })))
    useEffect(() => {
        if (history.location.state) {
            let state: any = history.location.state
            getCampaignDetails(state.campaignId)
        }
    }, [flag])
    const getCampaignDetails = async (campaignId) => {
        setIsloading(true)
        const resp = await context.apis[context.user.role].getNonWisrCampaign(
            context.validator, { _id: campaignId }, {}
        )
        console.log('getNonWisrCampaign', resp)
        if (resp && resp.done) {
            console.log(resp)
            setPaymentStatus({ value: resp.response.paymentStatus })
            setCampaignPauseStatus({ value: resp.response.campaignStatus.toLowerCase() === "on-hold" ? "resume" : "pause" })
            setCampaignDetails(resp.response)
            let completion = getPercentageComplition(resp.response.startDate, resp.response.endDate)
            setCampaignCompletion(completion)
            let inventories = [], events = []
            // let schools = []
            resp.response.schoolCampaignDatas && Array.isArray(resp.response.schoolCampaignDatas) && resp.response.schoolCampaignDatas.forEach(school => {
                if (school.inventories && Array.isArray(school.inventories)) {
                    school.inventories.forEach(inventory => {
                        if (inventory.parentName) {
                            let data = {
                                name: inventory.parentName,
                                image: (inventory.images && inventory.images.length > 0 && inventory.images[0].image) || "no image"
                            }
                            if (!inventories.find(inventory => inventory.name === data.name)) {
                                inventories.push(data)
                            }
                        }
                    })
                }
            })
            resp.response.schoolCampaignDatas && Array.isArray(resp.response.schoolCampaignDatas) && resp.response.schoolCampaignDatas.forEach(school => {
                // let schoolInventory = []
                // schools.push(school.schoolDetails)
                if (school.activities && Array.isArray(school.activities)) {
                    school.activities.forEach(inventory => {
                        if (inventory.name) {
                            let data = {
                                name: inventory.name,
                                // image: (inventory.images && inventory.images.length > 0 && inventory.images[0].image) || "no image"
                            }
                            if (!events.find(inventory => inventory.name === data.name)) {
                                events.push(data)
                            }
                        }
                    })
                }
            })
            setEventList([...events])
            setInventoryList([...inventories])
            let array = resp.response.schools ? resp.response.schools.map((data) => data.schoolDetails.city || "") : []
            setPincodeList([...array])
            setIsloading(false)
        }
    }
    const closeInventoryModal = () => {
        setShowInventoryModal(false)
    }
    const closeSchoolModal = () => {
        setShowSchoolModal(false);
    }
    const openImageModal = (index) => {
        setToggleImage(true)
        setImageIndex(index)
        setOverlay(true)
    }
    const confirmClose = (index) => {
        setToggleImage(false)
        setImageIndex(index)

        setOverlay(false)
    }
    const onPaymentStatusChange = async (confirm) => {
        if (confirm) {
            console.log(keepPaymentStatus)
            let resp = await context.apis[context.user.role].updateNonWisrCampaignStatus(
                context.validator, { _id: history.location.state["campaignId"] }, { paymentStatus: keepPaymentStatus }
            )
            console.log(resp)
            setPaymentStatus({ value: keepPaymentStatus })
        }
        setKeepPaymentStatus("")
        setShowPaymentChangeAlert(false)
        setOverlay(false)
    }
    const onCampaignPauseStatusChange = async (confirm) => {
        if (confirm) {
            console.log(keepCampaignPauseStatus)
            setIsCampaignPauseLoading(true)
            let resp = await context.apis[context.user.role].updateCampaignStatus(
                context.validator, { _id: history.location.state["campaignId"] }, { campaignStatus: keepCampaignPauseStatus }
            ).catch(err => {
                setIsCampaignPauseLoading(false)
            })
            console.log("updateCampaignStatus..onpause..", resp)
            if (resp && resp?.done) {
                setIsCampaignPauseLoading(false)
                // setCampaignPauseStatus({ value: keepCampaignPauseStatus === 'pause' ? "resume" : 'pause' })
                setFlag(!flag);
            }
        }
        setKeepCampaignPauseStatus("")
        setShowCampaignPauseStatusChangeAlert(false)
        setOverlay(false)
    }
    const onInventoriesRelease = async (confirm) => {
        if (confirm) {
            setIsInventoryReleaseLoading(true)
            let resp = await context.apis[context.user.role].releaseCampaignOccupiedInventory(
                context.validator, { _id: history.location.state["campaignId"] }, { campaignStatus: campaignDetails.campaignStatus }
            ).catch(err => {
                setOverlay(false)
                setIsInventoryReleaseLoading(false)
            })
            console.log("releaseCampaignOccupiedInventory...", resp)
            if (resp && resp?.done) {
                if (resp.response.isInventoryReleased === true) {
                    setIsInventoryReleaseLoading(false)
                    setAlertMessage(resp.response?.message)
                    setShowAlertMessage(true)
                    setFlag(!flag);
                }
            }
            else {
                setOverlay(false)
            }
        }
        else {
            setOverlay(false)
        }
        setShowReleaseInventoriesAlert(false)
    }
    const goToAssignSchoolProcess = () => {
        history.push({ pathname: '/backend/backend-details/assignSchoolToFos', state: { campaignId: history.location.state['campaignId'] } })
    }

    return (
        <>
            <div className={`ovelap ${overlay === true ? "active" : ""}`}></div>
            {isLoading ?
                <Loader />
                :
                <div className="col-xl-10 col-lg-12 col-md-12 col-xs-12">
                    <div className="rightPart noBack">
                        <div className="row">
                            {/* <div className="col-xs-12 col-lg-12 col-md-12 col-xs-12">
                                <BackendBackBtn />
                            </div> */}
                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-10">
                                <Link to="/backend/backend-details/nonWisr-campaign-list" className="backbtn dblock">
                                    <img src={backBtn} />
                                    {/* Back */}
                                </Link>
                            </div>
                            <div className="col-xl-8 col-lg-8 col-md-12 col-xs-12 mt-10">
                                <div className="inventory-overHead overheadTwo">
                                    <p>CAMPAIGN NAME</p>
                                    <h3>{campaignDetails.name} <span className={`table-status ${campaignDetails.campaignStatus === 'rejected' ? 'redbg' : campaignDetails.campaignStatus === "executed" ? "graybg" : campaignDetails.campaignStatus === "scheduled" ? "orangebg" : campaignDetails.campaignStatus === 'ongoing' ? 'yellowbg' : campaignDetails.campaignStatus === 'in-review' ? "orangebg" : campaignDetails.campaignStatus === "approved" ? "greenbg" : campaignDetails.campaignStatus === "on-hold" && "onholdbg"} `}>{campaignDetails.campaignStatus}</span></h3>
                                </div>
                            </div>
                            {/* {(history.location.state['from'] === 'campaign' && campaignDetails.campaignStatus === 'in-review' && campaignDetails.isApproved === false && !campaignDetails.creativeReworkNeeded) && <div className="col-xl-4 col-lg-4 col-md-12 col-xs-12 mt-30">
                                <div className="schoolFilter campaignDetailsbtn mb-0 fR">
                                    {context.user.role === "backendAdmin" && <a href="javascript:void(0)" className={`btn btn-outline ${(campaignDetails.campaignStatus === 'rejected' || campaignDetails.isApproved) ? '' : ''}`}
                                        onClick={() => { acceptRejectPayment('rejected', campaignDetails._id); }}
                                    >{campaignDetails.campaignStatus === 'rejected' ? 'rejected' : campaignDetails.campaignCreativesRevised && campaignDetails.campaignCreativesRevised.length>0 ? 'reject creatives' : 'reject' }</a>}
                                    {context.user.role === "backendAdmin" && <a href="javascript:void(0)" className={`btn btn-orange ${(campaignDetails.isApproved || campaignDetails.campaignStatus === 'rejected') ? '' : ''} ${acceptingCampaign && "button--loading btn-disabled"}`}
                                        onClick={() => { acceptRejectPayment('accepted', campaignDetails._id); }}
                                    >{campaignDetails.isApproved ? 'approved' : campaignDetails.campaignCreativesRevised && campaignDetails.campaignCreativesRevised.length>0 ? 'approve creatives' : 'approve' }</a>}
                                </div>
                            </div>} */}
                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-20 mb-20"><div className="line-full"></div></div>
                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 pl pr">
                                <div className="row">
                                    <div className="col-xl-9 col-lg-9 col-md-12 col-xs-12 mb-10 pl">
                                        <div className="row">
                                            <div className="col-xl-4 col-lg-4 col-md-12 col-xs-12 mb-10">
                                                <div className="campaingCretivesTwo">
                                                    <div className="inventory-overHead trial-left">
                                                        <p>Campaign Creatives</p>
                                                    </div>
                                                    <div className="campaignRequestSlide campaignDetailSlide">
                                                        <div className="campaignRequestSlideLarge">
                                                            <img style={{ cursor: "pointer", height: '100%' }} src={(campaignDetails && campaignDetails.campaignCreatives && campaignDetails.campaignCreatives.length > 0 && campaignDetails.campaignCreatives[0].image) ? campaignDetails.campaignCreatives[0].image : blankImg} onClick={() => (campaignDetails && campaignDetails.campaignCreatives && campaignDetails.campaignCreatives.length > 0 && campaignDetails.campaignCreatives[0].image) && openImageModal(0)} />
                                                        </div>
                                                        <div className="campaignRequestSlideSmall">
                                                            <img style={{ cursor: "pointer", height: '100%' }} src={(campaignDetails && campaignDetails.campaignCreatives && campaignDetails.campaignCreatives.length > 1 && campaignDetails.campaignCreatives[1].image) ? campaignDetails.campaignCreatives[1].image : blankImg} onClick={() => (campaignDetails && campaignDetails.campaignCreatives && campaignDetails.campaignCreatives.length > 1 && campaignDetails.campaignCreatives[1].image) && openImageModal(1)} />
                                                            <img style={{ cursor: "pointer", height: '100%' }} src={(campaignDetails && campaignDetails.campaignCreatives && campaignDetails.campaignCreatives.length > 2 && campaignDetails.campaignCreatives[2].image) ? campaignDetails.campaignCreatives[2].image : blankImg} onClick={() => (campaignDetails && campaignDetails.campaignCreatives && campaignDetails.campaignCreatives.length > 2 && campaignDetails.campaignCreatives[2].image) && openImageModal(2)} />
                                                        </div>
                                                        <p>Original Creatives</p>
                                                    </div>
                                                </div>
                                                {/* {history.location.state['campaignType'] === "create" && campaignDetails && campaignDetails.campaignCreativesRevised && campaignDetails.campaignCreativesRevised.length > 0 &&
                                                        <div className="campaingCretivesTwo">
                                                            <div className="campaignRequestSlide campaignDetailSlide">
                                                                <div className="campaignRequestSlideLarge">
                                                                    <img style={{ cursor: "pointer", height: '100%' }} src={(campaignDetails && campaignDetails.campaignCreativesRevised && campaignDetails.campaignCreativesRevised.length > 0) ? campaignDetails.campaignCreativesRevised[0].image : blankImg } onClick={() => (campaignDetails && campaignDetails.campaignCreativesRevised && campaignDetails.campaignCreativesRevised.length>0 && campaignDetails.campaignCreativesRevised[0].image) && openReworkCreativeImageModal(0)} />
                                                                </div>
                                                                <div className="campaignRequestSlideSmall">
                                                                    <img style={{ cursor: "pointer", height: '100%' }} src={(campaignDetails && campaignDetails.campaignCreativesRevised && campaignDetails.campaignCreativesRevised.length > 1) ? campaignDetails.campaignCreativesRevised[1].image : blankImg } onClick={() => (campaignDetails && campaignDetails.campaignCreativesRevised && campaignDetails.campaignCreativesRevised.length>1 && campaignDetails.campaignCreativesRevised[1].image) && openReworkCreativeImageModal(1)} />
                                                                    <img style={{ cursor: "pointer", height: '100%' }} src={(campaignDetails && campaignDetails.campaignCreativesRevised && campaignDetails.campaignCreativesRevised.length > 2) ? campaignDetails.campaignCreativesRevised[2].image : blankImg } onClick={() => (campaignDetails && campaignDetails.campaignCreativesRevised && campaignDetails.campaignCreativesRevised.length>2 && campaignDetails.campaignCreativesRevised[2].image) && openReworkCreativeImageModal(2)} />
                                                                </div>
                                                                <p>Rework Creatives</p>
                                                            </div>
                                                        </div>
                                                    } */}

                                            </div>
                                            <ImagePreview
                                                setShowImagePreview={() => confirmClose(imageIndex)}
                                                showImagePreview={toggleImage}
                                                images={campaignDetails.campaignCreatives && campaignDetails.campaignCreatives}
                                                selectedImageIndex={imageIndex}
                                            />
                                            {/* <ImagePreview
                                                setShowImagePreview={() => confirmReworkCreativeClose(imageReworkCreativeIndex)}
                                                showImagePreview={toggleReworkCreativeImage}
                                                images={campaignDetails.campaignCreativesRevised && campaignDetails.campaignCreativesRevised}
                                                selectedImageIndex={imageReworkCreativeIndex}
                                            /> */}
                                            <div className="col-xl-8 col-lg-8 col-md-12 col-xs-12 mb-10  trial-right">
                                                <div className="row">
                                                    <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-20 ">
                                                        <div className="inventory-overHead overheadOne">
                                                            <p>Campaign Description</p>
                                                            <h3 className="descrText">{campaignDetails.description}</h3>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12 mb-20">
                                                        <div className="inventory-overHead overheadOne">
                                                            <p>Budget</p>
                                                            <h3>₹ {campaignDetails.budget && campaignDetails.budget.toLocaleString('en-IN')}</h3>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12 mb-20">
                                                        <div className="inventory-overHead overheadOne">
                                                            <p>Schools</p>
                                                            <h3>{(campaignDetails.schools && campaignDetails.schools.length)}</h3>
                                                            {campaignDetails.schools && campaignDetails.schools.length > 0 &&
                                                                <a className="viewwLink camp-link bottom0" style={{ cursor: "pointer" }}
                                                                    onClick={() => setShowSchoolModal(true)}
                                                                >VIEW list <img className="arrowView" src={ArrowView} />
                                                                </a>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12 mb-20">
                                                        <div className="inventory-overHead overheadOne">
                                                            <p>Schedule</p>
                                                            <h3>{formatDateShowMonthBackend(campaignDetails.startDate, 4)} - {formatDateShowMonthBackend(campaignDetails.endDate, 4)}</h3>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12 mb-20">
                                                        <div className="inventory-overHead overheadOne">
                                                            <p>Inventories occupied</p>
                                                            <h3>{campaignDetails.inventories && campaignDetails.activities && campaignDetails.inventories.length + campaignDetails.activities.length}</h3>
                                                            {campaignDetails.inventories && campaignDetails.activities && campaignDetails.inventories.length + campaignDetails.activities.length > 0 &&
                                                                <a className="viewwLink camp-link bottom0" style={{ cursor: "pointer" }} onClick={() => setShowInventoryModal(true)}>VIEW list
                                                                    <img className="arrowView" src={ArrowView} />
                                                                </a>
                                                            }
                                                        </div>
                                                    </div>
                                                    {campaignDetails.campaignStatus !== 'rejected' &&
                                                        <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12 mb-10">
                                                            <div className="inventory-overHead overheadOne">
                                                                <p>Payment Status</p>
                                                                <div className="mt-10">
                                                                    {context.user.role === "backendFinance" || context.user.role === "backendAdmin" ?
                                                                        <SingleSelect
                                                                            formData={paymentStatus}
                                                                            formKey="value"
                                                                            onDataChange={(data) => { setKeepPaymentStatus(data.value); setShowPaymentChangeAlert(true); setOverlay(true) }}
                                                                            isRequired={false}
                                                                            options={paymentStatusOption}
                                                                        />
                                                                        :
                                                                        <h3>{paymentStatus.value === "paid" ? "Paid" : "Pending"}</h3>
                                                                    }
                                                                </div>

                                                            </div>
                                                        </div>
                                                    }
                                                    {/* {campaignDetails.isApproved &&
                                                        <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12 mb-20">
                                                            <div className="inventory-overHead">
                                                                <p>Campaign Reporting</p>
                                                                <a href="" className="viewwLink fN" onClick={goToAssignSchoolProcess}>
                                                                    <svg style={{ marginRight: '5px' }} width="10" height="10" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M6 1V11" stroke="#FE6E00" stroke-width="2" stroke-linecap="round" /> <path d="M1 6L11 6" stroke="#FE6E00" stroke-width="2" stroke-linecap="round" /> </svg>
                                                                    Assign FOS
                                                                </a>
                                                                <div className="row">
                                                                    {assignedFosList.length > 0 && assignedFosList.map((item, index) => (
                                                                        <div onClick={goToAssignSchoolProcess} style={{ color: '#FE6E00', cursor: 'pointer', backgroundColor: "white", width: 'auto', height: 'auto', border: '1px solid #FE6E00', borderRadius: '5px', padding: '8px 8px 5px 8px', margin: "5px 10px 5px 0", fontSize: '14px' }}>
                                                                            <span>{`${item.fosName} (${item.schools.length})`}</span>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    } */}
                                                    {/* {(campaignDetails.campaignStatus === "ongoing" || campaignDetails.campaignStatus === "on-hold") && campaignDetails.isInventoryReleased !== true && (!moment().isAfter(moment(campaignDetails.endDate), 'day')) && <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-20 ">
                                                        <div className="inventory-overHead overheadOne pause-campaign">
                                                            {(context.user.role === "backendAdmin") &&
                                                                <a href="javascript:void(0)" className={`btn btn-orange`}
                                                                    onClick={() => { setKeepCampaignPauseStatus(campaignPauseStatus.value); setShowCampaignPauseStatusChangeAlert(true); setOverlay(true) }}
                                                                >
                                                                    {campaignDetails.campaignStatus === "on-hold" ? "Resume Campaign" : "Pause Campaign"}
                                                                </a>
                                                            }

                                                            {context.user.role === "backendAdmin" &&
                                                                <a href="javascript:void(0)" className={`btn btn-orange ${(campaignPauseStatus.value === "pause" || (campaignDetails.isInventoryReleased === true)) ? "btn-disabled" : ""}`}
                                                                    onClick={() => { setShowReleaseInventoriesAlert(true); setOverlay(true) }}
                                                                >
                                                                    Release Inventories
                                                                </a>
                                                            }
                                                        </div>
                                                    </div>} */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-3 col-md-12 col-xs-12 mb-10 pr">
                                        <div className="box-design border-zero">
                                            <h2 className="htx3 clearfix mb-20 greyText">Campaign Location</h2>
                                            {isScriptLoaded && isScriptLoadSucceed &&
                                                <MultiPointMap
                                                    pincodeList={pincodeList}
                                                />
                                            }
                                            {/* <Link to={{
                                                pathname: "/backend/backend-details/view-campaign/set-up-campaign",
                                                state: { campaignId: history.location.state['campaignId'] }
                                            }}><a href="" className="viewwLink fN">View campaign creation <img className="arrowView" src={riarrownext}></img></a></Link> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {campaignDetails.inventories && campaignDetails.activities && campaignDetails.inventories.length + campaignDetails.activities.length > 0 &&
                <InventoryMore
                    showModalTwoMore={showInventoryModal}
                    inventoriesList={campaignDetails.inventories}
                    eventList={campaignDetails.activities}
                    closeWithoutSaving={closeInventoryModal}
                />
            }
            {history.location.state['campaignId'] !== '' && <NonWisrSchoolsList
                showSchoolModal={showSchoolModal}
                closeSchoolModal={closeSchoolModal}
                campaignId={history.location.state['campaignId']}
            />}
            <ConfirmDialog
                togglePopup={showPaymentChangeAlert}
                onModalClose={onPaymentStatusChange}
                componentName={""}
                confirmTitle="Do you want to change payment status of this campaign?"
            />
            <ConfirmDialog
                togglePopup={showCampaignPauseStatusChangeAlert}
                onModalClose={onCampaignPauseStatusChange}
                componentName={""}
                confirmTitle={`Do you want to ${keepCampaignPauseStatus} this campaign?`}
                isLoading={isCampaignPauseLoading}
            />
            <ConfirmDialog
                togglePopup={showReleaseInventoriesAlert}
                onModalClose={onInventoriesRelease}
                componentName={""}
                confirmTitle={`Do you want to release inventories for this campaign?`}
                isLoading={isInventoryReleaseLoading}
            />
        </>
    )
}

export default scriptLoader(
    [
        'https://maps.googleapis.com/maps/api/js?key=' + config.GOOGLE_MAPS_API_KEY
    ],
)(NonWisrCampaignDetail)