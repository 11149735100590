import {
    useState,
    useEffect,
    useRef
} from "react"

import SimpleReactValidator from "simple-react-validator"

import errorSVG from "../../assets/images/error.svg"

import TextArea from "../formFields/textArea"

const RejectBrandRequestModal = (props) => {

    const {
        showConfirmRejectModal,
        closeModal,
        onRejectRequest
    } = props

    const [formData, setFormData] = useState({reason: ""})

    const useForceUpdate = () => {
        const [value, setValue] = useState(0);
        return () => setValue(value => value + 1);
    }
    const forceUpdate1 = useForceUpdate();

    const [formValidator] = useState(useRef(new SimpleReactValidator({
        element: (message) =>
            <span className="valError"><img src={errorSVG} />{message}</span>
        })))
    const [update, forceUpdate] = useState(false)

    const rejectRequest = () => {
        if (onRejectRequest) {
            const result = formValidator.current.allValid()
            if (result === false) {
                formValidator.current.showMessages()
                forceUpdate1()
                return
            } else {
                formValidator.current.hideMessages()
                forceUpdate1()
                onRejectRequest(formData.reason)
            }
        }
    }

    useEffect(() => {
        if (showConfirmRejectModal === false) {
            setFormData({reason: ""})
        } 
        forceUpdate1()
    }, [showConfirmRejectModal])

    const handleClose = () => {
        formValidator?.current?.purgeFields()
        formValidator?.current?.hideMessages()
        closeModal()
    }

    return (
        <div id="confirmRejectModal" className={`popup rejectPopup ${showConfirmRejectModal === false ? "hide" : ""}`}>
            {/* <div className="popup-header">
                <h2 className="htx2 pophead">Campaign Rejection</h2>
                <span className="close toggle" onClick={closeModal} data-target="myPopup">close</span>
            </div> */}
            <div className="popup-body">
                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                    <span className="close toggle" onClick={handleClose} data-target="myPopup">close</span>
                    <h2 className="htx2 pophead">Rejection message</h2>
                    <label className="lableEdit">Tell us why you want to reject the campaign</label>
                </div>
                <div className="row">
                    
                    <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                        <div className="forminpt mb-0 mt-10">
                            <TextArea
                                        formData={formData}
                                        formKey='reason'
                                        onDataChange={(event) => setFormData({reason: event.target.value})}
                                        label='Reason'
                                        placeholder='Enter Reason'
                                        isRequired={false}
                                        formValidator={formValidator}
                                        validation='required'
                                        maxLength={500}
                                    />
                            <a onClick={rejectRequest} href="javascript:void(0)" className="btn btn-orange">Done</a>
                        </div>
                    </div>
                </div>
                {/* <div className="viewdetailsHead">
                    <div className="approveActive">
                        <p></p>
                        <form>
                            <div className="row">
                                <div className="col-xl-6 col-lg-6 col-md-6 col-xs-6">
                                    
                                </div>
                            </div>
                        </form>
                        <div className="brand-btn">
                            <a onClick={rejectRequest} href="javascript:void(0)" className="btn btn-orange">Done</a>
                        </div>
                    </div>
                </div> */}
            </div>
        </div> 
    )
}

export default RejectBrandRequestModal
