import {
    useState,
    useEffect,
    forwardRef,
    useImperativeHandle,
    useContext
} from "react"

import config from "../../../../../service/config"

import AppContextObject from "../../../../../common/context/common"
import { PublicContextInitiator } from "../../../../apis/publicContextInitiator"

import Numberfield from "../../../../../components/formFields/numberField"
import ConfirmDialog from "../../../../../components/addon/confirmDialog/confirmDialog"
import ImagePreview from "../../../../../components/addon/imagePreview"
import InventoryModal from "../../../../../components/addon/inventoryModal"

import { objectDeepClone, titleCase } from "../../../../../service/helper"

import check from "../../../../../assets/images/check.svg"
import star from "../../../../../assets/images/star.svg"
import tabBlank from "../../../../../assets/images/tab-blank-img.png"
import deleteImg from "../../../../../assets/images/close-1.svg"
import addBox from "../../../../../assets/images/add-bx.svg"
import helpImg from "../../../../../assets/images/help.svg"

import Compress from "compress.js"
import DataContext from "../../../../../service/contextProvider"
import errorSVG from "../../../../../assets/images/error.svg"
import SingleSelect from "../../../../../components/formFields/singleSelect/singleSelect"

interface Props {
    amenityList?: any
    setIsSubmitDisabled?: Function
    onCustomAmenityAdd?: Function
    onCustomAmenityDelete?: Function
    updateCompleteState? : boolean
    isEdit? : boolean
    configData? : any
    openRemainingInventories? : Function
    onAmenitiesChange?: Function,
    eventIndex?: Number,
    selectedActivityName?: any,
    hideRevenue? : boolean,
    amenityData? : any,
    toggleNavigateFromTable? : Function
}

const AmenityDetails = forwardRef(({
    amenityList,
    setIsSubmitDisabled,
    onCustomAmenityAdd,
    onCustomAmenityDelete,
    updateCompleteState,
    isEdit,
    configData,
    openRemainingInventories,
    onAmenitiesChange,
    eventIndex,
    selectedActivityName,
    hideRevenue,
    amenityData,
    toggleNavigateFromTable
}: Props, ref) => {

    const context = useContext(AppContextObject)
    const parentData = useContext <any> (DataContext)

    const [overlay, setOverlay] = useState(false)
    const [selectedAmenity, setSelectedAmenity] = useState(null)
    const [selectedAmenityDetail, setSelectedAmenityDetail] = useState(null)
    const [amenities, setAmenities] = useState(amenityList)
    const [customAttr, setCustomAttr] = useState("")
    const [showNewAttrTextfield, setShowNewAttrTextfield] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [quantityList, setQuantityList] = useState([])
    const [selectedAttrForEdit, setSelectedAttrForEdit] = useState("")
    const [showImagePreview, setShowImagePreview] = useState(false)
    const [amenityDetailList, setAmenityDetailList] = useState([])
    const [completedAmenities, setCompletedAmenities] = useState([])
    const [imagesArray, setImagesArray] = useState([])
    const [selectedImageIndex, setSelectedImageIndex] = useState(0)
    const [quantityErrorMessage, setQuantityErrorMessage] = useState("Please fill all data for");
    const [imageBase64String, setImageBase64String] = useState("")
    const [selectedDeleteAmenityIndex, setSelectedDeleteAmenityIndex] = useState(null)
    const [selectedDeleteAmenityName, setSelectedDeleteAmenityName] = useState(null)
    const [removeImage, setRemoveImage] = useState(null);
    const [backupCount, setBackupCount] = useState(0)
    const [amenityCount, setAmenityCount] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [imagesCount, setImagesCount] = useState([]);
    const [attributeError, setAttributeError]= useState(false)
    const [sameAttributeNameError,setSameAttributeNameError] = useState(false)

    const [validatorObj, setValidatorObj] = useState([])
    const [currentErrorObj, setCurrentErrorObj] = useState([])

    const [toggleAmenityDeleteConfirm, setToggleAmenityDeleteConfirm] = useState(false)

    const [customInventoryData, setCustomInventoryData] = useState({name: ""})
    const [isCustomInventoryEdit, setIsCustomInventoryEdit] = useState(false)
    const [toggleInventoryModal, setToggleInventoryModal] = useState(false)
    const [amenityIdxToEdit, setAmenityIdxToEdit] = useState(0)
    const [notUploadedImagesList, setNotUploadedImagesList] = useState([])
    const [toggleImageLoadingPopUp, setToggleImageLoadingPopUp] = useState(false)

    const [tempData, setTempData] = useState({
        quantity: 1,
        selectedItem: ""
    })

    const [defaultObj] = useState({
        name: "",
        availableForBranding: true,
        allowBrandsToProvide: false,
        _checked: false,
        images: [],
        count: 1,
        isSelected: false,
        isCustom: false,
        data: {
            quantity: 0,
            units: "feet",
            length: 0,
            height: 0,
            breadth: 0,
        }
    })
    const [notSupportedImageErr, setNotSupportedImageErr] = useState("")

    const compress = new Compress()

    useImperativeHandle(ref, () => ({
        fetchAllAmenities() {
          return amenities
        },

        fetchValidation() {
            return new Promise((resolve) => {
                validateData().then(response => {
                    resolve(response)
                })
            })
        },

        resetAmenityDetail() {
            setSelectedAmenity(null)
        },

        selectedAmenities() {
            return new Promise((resolve) => {
                checkIfAtleastOneAmenitySelectd().then(response => {
                    resolve(response)
                })
            })
        }
    }))

    useEffect(() => {
        if (updateCompleteState === true) {
        }
        validateData()
        if(amenityList.length > 0){
            setActiveAmenity(0)
        }
    }, [amenityList])

    useEffect(() => {
        validateSelectedAmenity()
    }, [selectedAmenity, validatorObj])

    useEffect(() => {
        const arr = [...amenities]
        if (arr[selectedAmenity]) {
            arr[selectedAmenity] = {...selectedAmenityDetail}
            setAmenities(arr)
        }
        setImagesArray(selectedAmenityDetail && selectedAmenityDetail.images && selectedAmenityDetail.images)
        setShowNewAttrTextfield(false)
        setAttributeError(false)
        setSameAttributeNameError(false)
        if(isEdit === true){
            setAmenityCount(selectedAmenityDetail && selectedAmenityDetail.count)
        }
        onAmenitiesChange()
    }, [selectedAmenityDetail])

    const openImagePreview = (index) => {
        setSelectedImageIndex(index)
        setShowImagePreview(true)
    }

    const validateSelectedAmenity = () => {
        if (selectedAmenity !== null && Object.keys(validatorObj).length > 0) {
            let pendingItem = ""

            validatorObj.forEach(e => {
                if (amenities[selectedAmenity].name === e) {
                    pendingItem = e
                }
            })
            if (pendingItem !== "") {
                const errorStr = "Please fill all fields for " + pendingItem + "."
                setQuantityErrorMessage(errorStr)
            } else {
                setQuantityErrorMessage("")
                setCurrentErrorObj([])
            }
        }
        if (Object.keys(validatorObj).length === 0) {
            setQuantityErrorMessage("")
            setCurrentErrorObj([])
        }
    }

    const deleteSingleUploadedImage = (imageKey) => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type' : 'application/json',
            },
            body: JSON.stringify({ key: imageKey && imageKey })
        }

        fetch('/api/deleteUploadedFiles', requestOptions)
        .then((res)=>{
            res.json()
        })
        .catch(err=>console.log(err))
    }

    const deleteImage = (imageKey) =>{
        if(isEdit === true && selectedAmenityDetail._id === undefined){
            deleteSingleUploadedImage(imageKey);
        }else if(isEdit === true){
            parentData.data.removeSingleUploadedImageArray.push(imageKey)
        }else{
            deleteSingleUploadedImage(imageKey);
        }
        const key = imageKey;
        selectedAmenityDetail.images.splice(selectedAmenityDetail.images.findIndex(e => e.key === key),1);
        if(removeImage !== null){
            setRemoveImage(!removeImage)
        }else{
            setRemoveImage(false)
        }
    }

    const handleUpload = async (event, files) =>{
        setToggleImageLoadingPopUp(true)
        toggleNavigateFromTable()
        let timeoutId
        setNotUploadedImagesList([])
        // const dt = new DataTransfer()
        // event.target.files = dt.files
        setImagesCount([])
        let imagesList = []
        let imagesUploadedList = []
        let updateImageObj = objectDeepClone(imagesArray)
        for (let file of files) {
            imagesList.push(file.name)
            if(!file.type.startsWith("image/"))
            {
                setToggleImageLoadingPopUp(false)
                setNotSupportedImageErr("only image file formats could be uploaded")
                return
            }else{
                setNotSupportedImageErr("")
            }
        }
        const controller = new AbortController()
        timeoutId = setTimeout(() => {
            controller.abort(); 
            let notUploadedImages = imagesList.filter(function(obj) { return imagesUploadedList.indexOf(obj) == -1; });
            setIsLoading(false)
            setNotUploadedImagesList(notUploadedImages)
        }, 60000)
        
        for (let i = 0; i < files.length; i++) {
            setIsLoading(true);
            const formData = new FormData();
            setImagesCount(prev=>[
                ...prev,
                files[i]
            ])
            const resizedImage = await compress.compress([files[i]], {
                size: 2, // the max size in MB, defaults to 2MB
                quality: 0.4, // the quality of the image, max is 1,
                resize: false // defaults to true, set false if you do not want to resize the image width and height
            })
            const img = resizedImage[0];
            const base64str = img.data
            const imgExt = img.ext
            let compressedFile = Compress.convertBase64ToFile(base64str, imgExt);
            formData.append(`image`,compressedFile, img.alt);
            let reqBody = formData
            if(hideRevenue){
                let schoolName = await context.apis[context.user.role].getSchoolName(context.validator, {})
                console.log("schoolName--->>>",schoolName);
                if(schoolName.done){
                    let destinationFolder = `schools/${context?.user?.school}-${schoolName.response.schoolName}/events`
                    reqBody.append('destinationFolder',destinationFolder)
                }
            }
            const requestOptions = {
                signal: controller.signal,
                method: 'POST',
                body: reqBody
            };
            let publicContextInitiator = new PublicContextInitiator(context)
            
            try {
                const response: any = await fetch('/api/uploadFiles', requestOptions).catch(err => console.log(err))
                console.log("response", response)
                const res = await response.json()
                let data = [];
                imagesUploadedList.push(img.alt)

                for(let item of res["response"]){
                   data.push(item.Key)
                }

                const myRes = await publicContextInitiator.getUploadedImagesSignedUrl(context.validator, {keys: data})
                for(let item of myRes.response){
                    updateImageObj.push({image: item.image, key: item.key});
                    parentData.data.unsavedImages.push(item.key)
                }                          
                if(files.length - 1 === i ){
                    // setIsImagesUploaded(!isImagesUploaded)
                }
            }catch (error) {
                console.log("err",error);
            }
        } 
        setImagesArray(objectDeepClone(updateImageObj))
        setSelectedAmenityDetail({
            ...selectedAmenityDetail,
            images: objectDeepClone(updateImageObj)
        })
        clearTimeout(timeoutId)
        setIsLoading(false)
        setToggleImageLoadingPopUp(false)
        event.target.value = null;
    }

    const onAttrDataChange = (event) => {
        toggleNavigateFromTable()
        setSelectedAmenityDetail({
            ...selectedAmenityDetail,
            data: {
                ...selectedAmenityDetail.data,
                [event.target.id]: event.target.value
            }
        })
    }
    const onAttributeDataChange = (data) => {
        toggleNavigateFromTable()
        setSelectedAmenityDetail({
            ...selectedAmenityDetail,
            data: {
                ...selectedAmenityDetail.data,
                ["units"]: data.value
            }
        })
    }

    console.log("hideRevenue>>>>",hideRevenue);

    const onAttrSubmit = async () => {
        toggleNavigateFromTable()
        let validator: any = []
        validator = await validateData().catch(err => console.log(err))
        let validated = true
        for (let i = 0; i < validator.length; i++) {
            if (validated === true && selectedAmenityDetail.name === validator[i]) {
                validated = false
            }
        }
        if (validated) {
            if (selectedAmenity < (amenities.length - 1)) {
                setActiveAmenity(selectedAmenity + 1)
            }
            const completedAmenityName = amenities[selectedAmenity].name
            // setCompletedAmenities([
            //     ...completedAmenities,
            //     completedAmenityName
            // ])
            if(!hideRevenue){
                if(!amenityData.custom){

                let eventData = {
                name: selectedActivityName || "",
                placement: [],
                sizeOfPlacement: [],
                noOfPlacement: [],
                }
                console.log("amenityData>>>",amenityData);

                for(let amenity of amenities){
                    if(!amenity.isCustom){
                        console.log("amenityData.custom>>>",amenityData.custom);
                        eventData.placement.push(amenity.name)
                        let length = Number(amenity.data.length) === 0 ? 1 : Number(amenity.data.length)
                        let breadth = Number(amenity.data.breadth) === 0 ? 1 : Number(amenity.data.breadth)
                        let height = Number(amenity.data.height) === 0 ? 1 : Number(amenity.data.height)
                        let size = Number(length) * Number(breadth)
                        console.log("size>>",size);
                        eventData.sizeOfPlacement.push(size)
                        eventData.noOfPlacement.push(Number(amenity.data.quantity))
                    }
                }
                let allSelectedInventoryArr = [];
                let allAmenities = [...parentData.data.student.activities]
                allSelectedInventoryArr = parentData.data.algorithmData.events.filter(el => {
                    return allAmenities.find(element => {
                        return element.name === el.name && !element.custom;
                    });
                });

                console.log("allSelectedInventoryArr--1>",allSelectedInventoryArr);
                console.log("eventData>>>",eventData);

                let allSelectedEvent = [ eventData, ...allSelectedInventoryArr]
                let evdata = allSelectedEvent.filter((am, index, self) =>
                   { 
                       console.log("am>>>",am);
                       return index === self.findIndex((t) => (
                        t.name === am.name
                        ))
                    }
                )
                console.log("evdata>>>",evdata);
                if (parentData && parentData.setData) {
                    parentData.setData({
                        ...parentData.data,
                        algorithmData: {
                            ...parentData.data.algorithmData,
                            events: evdata
                        }
                    })
                }

                let resp = await context.apis.public.getOnboardingRevenue(
                    context.validator, {
                        ...parentData.data.algorithmData,
                        events: evdata
                    }
                )
                if(resp && resp.done){
                    if(parentData && parentData.setNetRevenue){
                        parentData.setNetRevenue(resp.netRevenue || 0)
                    }
                }

                console.log("algo resp->>>>", resp);
            }
        }
            
        }
            
    }

    const validateData = () => {
        return new Promise((resolve) => {
            let validator = []
            let completedList = [...completedAmenities]
            for (let i = 0; i < amenities.length; i++) {
                const el = amenities[i]
                let isEmpty = false
                if ((el.availableForBranding === true || el.allowBrandsToProvide === true) &&
                (el.hasOwnProperty("data") && Object.keys(el.data).length > 0)) {
                    for (let j = 0; j < Object.keys(el.data).length; j++) {
                        const keyName = Object.keys(el.data)[j] // getting the key name
                        const attr = el.data[keyName] // getting actual value from the keyname
                        if (!attr) {
                            if((attr === "") && isEdit === true){
                                isEmpty = true
                            }
                        }
                        if( keyName === "quantity" || keyName === "length" || keyName === "height" || keyName === "breadth"){
                            if(attr === ""){
                                isEmpty = true
                            }
                        }
                        // if((keyName === "quantity" && (attr === "0" || attr === 0))){
                        // console.log("keyname, value", keyName, attr);
                        //     isEmpty = true
                        // }
                    }
                }
                if((Number(el.data["quantity"]) > 0) && (Number(el.data["length"]) === 0) && (Number(el.data["breadth"]) === 0) && (Number(el.data["height"]) === 0)){
                    isEmpty = true
                }
                if((Number(el.data["quantity"]) === 0) && ((Number(el.data["length"]) > 0) || (Number(el.data["breadth"]) > 0) || (Number(el.data["height"]) > 0))){
                    isEmpty = true
                }
                if (isEmpty === true) {
                    validator.push(el.name)
                    const foundCompletedIndex = completedList.findIndex(e => e === el.name)
                    if (foundCompletedIndex >= 0) {
                        completedList.splice(foundCompletedIndex, 1)
                    }
                    setCompletedAmenities(completedList)
                } else {
                    if (el.availableForBranding === true || el.allowBrandsToProvide === true) {
                        const found = completedList.find(c => c === el.name)
                        if (!found) {
                            completedList.push(el.name)
                            setCompletedAmenities(completedList)
                        }
                    } else {
                        const foundCompletedIndex = completedList.findIndex(e => e === el.name)
                        if (foundCompletedIndex >= 0) {
                            completedList.splice(foundCompletedIndex, 1)
                        }
                        setCompletedAmenities(completedList)
                    }
                }
            }
            setValidatorObj(validator)
            resolve(validator)
        })
    }

    const checkIfAtleastOneAmenitySelectd = () => {
        return new Promise((resolve) => {
            let counter = 0
            for (let i = 0; i < amenities.length; i++) {
                const el = amenities[i]
                if ((el.availableForBranding === true || el.allowBrandsToProvide === true)) {
                    counter = counter + 1
                }
            }
            resolve(counter)
        })
    }

    const onSelectAvailForBranding = (event) => {
        toggleNavigateFromTable()
        let json = {...selectedAmenityDetail}
        if (event.target.checked === true && json.allowBrandsToProvide === true) {
            json.allowBrandsToProvide = false
        }
        json.availableForBranding = event.target.checked
        setSelectedAmenityDetail(json)
    }

    const onSelectAllowBrandsToProvide = (event) => {
        toggleNavigateFromTable()
        let json = {...selectedAmenityDetail}
        if (event.target.checked === true && json.availableForBranding === true) {
            json.availableForBranding = false
        }
        json.allowBrandsToProvide = event.target.checked
        setSelectedAmenityDetail(json)      
    }

    const setActiveAmenity = (index) => {
        setSelectedAmenity(index)
        setSelectedAmenityDetail(amenities[index])

        setTempData({...tempData, selectedItem: ""})
        // empty attribute level error object
        setCurrentErrorObj([])

        // setTempData({
        //     ...tempData,
        //     selectedItem: amenities[index].data[0].name
        // })
        // setAmenityCount(amenities[index].data.length)
    }

    const addNewInventory = () => {
        toggleNavigateFromTable()
        setIsCustomInventoryEdit(false)
        setOverlay(true)
        setToggleInventoryModal(true)
        setCustomInventoryData({name: ""})
    }

    const onCustomInventorySave = (data) => {
        toggleNavigateFromTable()
        setOverlay(false)
        setCustomInventoryData(data)
        setToggleInventoryModal(false)
        if (isCustomInventoryEdit === false) {
            const json = {...defaultObj}
            json.name = data.name
            json.isCustom = true
            setAmenities([...amenities, json])
        } else {
            const arr = [...amenities]
            arr[amenityIdxToEdit].name = data.name
            setAmenities(arr)
            setSelectedAmenityDetail({
                ...selectedAmenityDetail,
                name: data.name
            })
        }
    }

    const onInventoryModalClose = () => {
        setOverlay(false)
        setToggleInventoryModal(false)
    }

    const updateCustomAmenity = (data, index) => {
        toggleNavigateFromTable()
        if (data.isCustom === true) {
            setAmenityIdxToEdit(index)
            setIsCustomInventoryEdit(true)
            setOverlay(true)
            setToggleInventoryModal(true)
            setCustomInventoryData({name: data.name})
        }
    }

    const deleteCustomAmenity = (data, index) => {
        toggleNavigateFromTable()
        setOverlay(true)
        setToggleAmenityDeleteConfirm(true)
        setSelectedDeleteAmenityName(data.name)
        setSelectedDeleteAmenityIndex(index)
    }

    const amenityConfirmClose = (confirm) => {
        if (confirm === true) {
            if(isEdit === true){
                // parentData.data.removeInventoriesArray.push(selectedDeleteAmenityDetails[0]._id)
            }
            setSelectedAmenity(null)
            const arr = [...amenities]
            arr.splice(selectedDeleteAmenityIndex, 1)
            setAmenities(arr)
        }
        setToggleAmenityDeleteConfirm(false)
        setOverlay(false)
    }
    return (
        <>
            <div className={`ovelap ${(overlay === true || toggleImageLoadingPopUp === true) ? "active" : ""}`}></div>
            <div id={`eventDetailLowerBox${eventIndex}`} className="school-specify relative">
                {/* below ovelaper class added for showing upload in progress until images uploading completes. */}
                <article className={`tabbed-content tabs-side ${toggleImageLoadingPopUp && "ovelaper active"}`}>
                    <nav className="tabs">
                        <ul>
                            {
                                (amenities && amenities.length > 0) &&
                                amenities.map((el, index) => {
                                    const isCompleted = completedAmenities.find(c => c === el.name)
                                    const isPending = validatorObj.find(v => v === el.name)
                                    return (
                                        <li key={index}>
                                                <a
                                                    onClick={() => setActiveAmenity(index)}
                                                    onDoubleClick={() => updateCustomAmenity(el, index)}
                                                    href="javascript:void(0)"
                                                    className={
                                                        `star-icon ${index === selectedAmenity ? "active" : ""}
                                                        ${isCompleted ? "complete" : ""}
                                                        ${isCompleted && el.isCustom === true ? "custom-complete" : ""}
                                                        ${isPending ? "pending-amenity" : ""}
                                                    `}
                                                >
                                                    {el.isCustom === true &&
                                                        <img className="checkcom star" src={star} />
                                                    }
                                                    <h4>{el.name}</h4>
                                                    <p>Total - {el.data.quantity}</p>
                                                    <img src={check} />
                                                </a>
                                                {
                                                    el.isCustom === true &&
                                                    <a
                                                        className="delete-custom-inventory"
                                                        href="javascript:void(0)"
                                                        onClick={() => deleteCustomAmenity(el, index)}>
                                                    <img src={deleteImg} /></a>
                                                }
                                        </li>
                                    )
                                })
                            }
                            <li>
                                <div className='facili-avai1 add' onClick={addNewInventory}>
                                    {" "}
                                    <img src={addBox} />{" "}
                                </div>
                            </li>
                        </ul>
                    </nav>
                    {
                        selectedAmenity === null ?
                        <div id="side_img" className="item active" data-title="">
                            <div className="item-content">
                                <div className="tabblank"><img src={tabBlank} /> <p>Select an option to provide <br/> more details.</p></div>
                            </div>
                        </div>
                        :
                        <div id="side_tab1" className="item active" data-title="Auditorium">
                            <div className="item-content">
                                <div className="scho-spec">
                                    <div className="row">
                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <div className="ava-brad ava-school"> <strong>Branding to be provided by brand <a href="#" title="Click on this if brands can provide inventory during the events." className="tooltip"><img src={helpImg} /></a></strong>
                                                <div className="content">
                                                <label className="switch">
                                                    <input
                                                        type="checkbox"
                                                        className="shohide"
                                                        data-cls="onclass1"
                                                        id={eventIndex + "-availableForBranding"}
                                                        onChange={onSelectAvailForBranding}
                                                        checked={selectedAmenityDetail.availableForBranding}
                                                    />
                                                    <span className="slider round"></span> </label>
                                                </div>
                                            </div>
                                        </div>                                      
                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <div className="ava-brad ava-school"> <strong>Structure to be provided by brand <a href="#" title="Select if this inventory is open for branding" className="tooltip"><img src={helpImg} /></a></strong>
                                                <div className="content">
                                                <label className="switch">
                                                    <input
                                                        type="checkbox"
                                                        className="shohide"
                                                        data-cls="onclass1"
                                                        id={eventIndex + "-allowForBrandsToProvide"}
                                                        onChange={onSelectAllowBrandsToProvide}
                                                        checked={selectedAmenityDetail.allowBrandsToProvide}
                                                    />
                                                    <span className="slider round"></span> </label>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            quantityErrorMessage &&
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12"><p className="valError">
                                                <img src={errorSVG}/>
                                                {quantityErrorMessage}
                                            </p></div>
                                        }
                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt20">
                                            <div className="sch-add-img">
                                                <h3 className="htx2">Images
                                                    <a href="javascript:void(0);" className="add-inve adbrose">+ Add
                                                        <input name="amenityImages" type="file" accept="image/*" title="" onChange={(event)=> handleUpload(event, event.target.files)} multiple={true} />
                                                    </a>
                                                </h3>
                                                <img src={imageBase64String} className="img-responsiv" />
                                                <section id="gallery">
                                                    <div className="container">
                                                        <div id="image-gallery">
                                                            {
                                                                imagesArray && imagesArray.map((el, index) => {
                                                                    return (
                                                                        <div className="image" key={index} style={{height:'55px'}}>
                                                                            
                                                                            <div onClick={() => openImagePreview(index)} id="imageId1" className="img-wrapper">
                                                                                <a href="javascript:void(0)"><img src={el.image} className="img-responsive" /></a>
                                                                                <div className="img-overlay"> <i className="fa fa-eye" aria-hidden="true"></i> </div>
                                                                            </div>
                                                                            <a onClick={()=>deleteImage(el.key)} href="javascript:void(0)" className="removeImg"><i className="fa fa-plus-circle" aria-hidden="true"></i></a>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                            {
                                                                isLoading ?
                                                                imagesCount && imagesCount.map((item,i)=>{
                                                                       return( 
                                                                       <div className="image" title={item.name} key={i}  style={{height:'55px'}}>
                                                                            <div className="loader-imgupload"></div>
                                                                        </div>
                                                                       )
                                                                    })
                                                                :
                                                                ""
                                                            }
                                                        </div>
                                                        {
                                                            notUploadedImagesList && notUploadedImagesList.length > 0 &&
                                                            <p
                                                            style={{
                                                            fontSize: "0.8rem",
                                                            color: "tomato",
                                                            paddingTop: "0.5rem",
                                                            }}
                                                            >
                                                           Failed to upload : {
                                                                notUploadedImagesList && notUploadedImagesList.length > 0 && notUploadedImagesList.map((image, index)=>{
                                                                    return(
                                                                            <span> {image}{notUploadedImagesList.length === index + 1 ? "." : ", "} </span>
                                                                    )
                                                                })
                                                            }
                                                            </p>
                                                        }
                                                        {
                                                            notSupportedImageErr && notSupportedImageErr.length > 0 &&
                                                            <p
                                                            style={{
                                                            fontSize: "0.8rem",
                                                            color: "tomato",
                                                            paddingTop: "0.5rem",
                                                            }}
                                                            >
                                                                <span className="valError">
                                                                    <img src={errorSVG} />
                                                                    <p>The uploaded file is not supported. Please upload image file.</p>
                                                                </span>
                                                            </p>
                                                        }
                                                        <ImagePreview
                                                            setShowImagePreview={setShowImagePreview}
                                                            showImagePreview={showImagePreview}
                                                            images={imagesArray && imagesArray}
                                                            selectedImageIndex={selectedImageIndex}
                                                        />
                                                    </div>
                                                </section>
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt20">
                                            <div className="sch-add-img ifnotline">
                                                <div className="d-flex justify-content-space-between">
                                                    <h3 className="htx2">Size Info
                                                        {/* <p className="ptx" >(Please enter "0" if not applicable)</p> */}
                                                        <p className="ptx">Add sizes for branding only</p>
                                                    </h3>
                                                </div>
                                                <div className="attri-add">
                                                    <div className="">
                                                        <div className="tab-panels">
                                                            <div className={`tab-panel`}>
                                                            {
                                                                (selectedAmenityDetail && selectedAmenityDetail.data) &&
                                                                Object.keys(selectedAmenityDetail.data).map((el, index) => {
                                                                    return (
                                                                        <>
                                                                        {
                                                                            el.toLowerCase() === "units" ?
                                                                            <div className="attbox">
                                                                                <label>Units</label>
                                                                                {/* <select
                                                                                    onChange={onAttrDataChange}
                                                                                    id="units"
                                                                                >
                                                                                    {
                                                                                        config.attrMeasurementUnits.map((unit, index) =>{
                                                                                            return (
                                                                                                <option
                                                                                                    key={index}
                                                                                                    value={unit.value}
                                                                                                    selected={selectedAmenityDetail.data.units == unit.value} >
                                                                                                {unit.label}</option>    
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </select> */}
                                                                                        <SingleSelect
                                                                                            formData={selectedAmenityDetail.data}
                                                                                            formKey={"units"}
                                                                                            onDataChange={onAttributeDataChange}
                                                                                            // label="units"
                                                                                            placeholder="units"
                                                                                            isRequired={false}
                                                                                            options={config.attrMeasurementUnits}
                                                                                        />
                                                                            </div>
                                                                            :
                                                                            el.toLowerCase() !== "height" && el.toLowerCase() !== "breadth" && el.toLowerCase() !== "length" && el.toLowerCase() !== "quantity"
                                                                            ?
                                                                            ""
                                                                            :
                                                                            <div className="attbox" key={index}>
                                                                                <label>{el ? titleCase(el) : ""}</label>
                                                                                <Numberfield
                                                                                    formData={selectedAmenityDetail.data}
                                                                                    formKey={el}
                                                                                    onDataChange={onAttrDataChange}
                                                                                    placeholder='100'
                                                                                    isRequired={false}
                                                                                    allowDecimal={el.toLowerCase() === "quantity" ? false : true}
                                                                                />
                                                                            </div> 
                                                                        }
                                                                        </>
                                                                    )
                                                                })
                                                            }
                                                            </div>
                                                            <input onClick={onAttrSubmit} className="btn btn-outline" name="Submit" value="Submit" type="button" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </article>
            </div>

            <InventoryModal
                data={customInventoryData}
                parentCallback={onCustomInventorySave}
                isEdit={isCustomInventoryEdit}
                onModalClose={onInventoryModalClose}
                togglePopup={toggleInventoryModal}
                existingAmenities={(amenities && amenities.length > 0) ? amenities : []}
                selectedInventories={[]}
            />

            <ConfirmDialog
                togglePopup={toggleAmenityDeleteConfirm}
                onModalClose={amenityConfirmClose}
                componentName={selectedDeleteAmenityName}
            />
        </>
    )
})

export default AmenityDetails
