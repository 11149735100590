import { useState, useEffect, useContext } from "react"

import { useHistory, useLocation } from 'react-router-dom'

import { validation } from "../../../../service/validators"

import PasswordField from "../../../../components/formFields/passwordField"

import logo from "../../../../assets/images/logo.png"
import loginBgLeft from "../../../../assets/images/backendAdmin/login-bg.svg"
import { PublicContextInitiator } from "../../../apis/publicContextInitiator"
import AppContextObject from "../../../../common/context/common"
import { BrandManagerContextInitiator } from "../../../../brandManagerSrc/apis/brandManagerContextInitiator"
import OnboardingBackBtn from "../../schools/components/onboardingBackBtn"
const ResetPassword = () => {

    const useQuery = () => {
        return new URLSearchParams(useLocation().search)
    }
    const context = useContext(AppContextObject)
    const queryParams = useQuery()

    let [formErrors, setFormErrors] = useState<any>({})

    let [data, setData] = useState({
        newPassword: "",
        confirmNewPassword: ""
    })

    const [userData, setUserData] = useState<any>({
        userId : "",
        username : "",
    })
    const [username,setUsername] = useState<any>("")
    const [emailId, setEmailId] = useState("")

    useEffect(() => {
        const email = queryParams.get("email")
        const token = queryParams.get("token")
        if (email) {
            setEmailId(email)
        }
    }, [])

    useEffect(() => {
        console.log(" Userdata history.location.state",history.location.state)
        if(history.location.state){
            const tempData: any = history.location.state
            console.log(" Userdata htempData",tempData)
            let data = {
                username : tempData.username,
                userId : ""
            }
            setUserData(data)

            // let data = tempData.username
            console.log("User data", userData)
            // setUsername(data)
            // console.log("User name", username)
            // findUserId()
        }
        
       
    },[])
    const onFormDataChange = (event) => {
        setData({
            ...data,
            [event.target.id]: event.target.value
        })
    }

    // if (!context.apis || !context?.user?.role) {
    //     document.location.reload()
    // }

    const history = useHistory()

    const onSubmit = async () => {
        const errors = validation(data)
        setFormErrors(errors)
        if (Object.keys(errors).length > 0) {
            return
        }
        console.log("USER NAME ", userData.username )
        let resp1 = await context.apis.public.findUserByUsername(
            context.validator, { "username": userData.username }
        )
        console.log("finduser resp", resp1)
        let userId = ""
        if (resp1 && resp1.done === false) {
            userId = resp1.response[0]._id
        }

        console.log("USER ID ", userId)
        
        let publicContextInitiator = new PublicContextInitiator(context)
        let resp = await publicContextInitiator.changeUserPasswordExternal(
            context.validator, {"_id" : userId}, { password: data.newPassword }
        )

        console.log("change password response", resp)
        if (resp && resp.done) {
            history.push("/brands/login")
        }
        else {
            let errr = {};
            errr["confirmNewPassword"] = resp.msg;
            setFormErrors(errr)
        }
    }

    return (
        <>
            <div className="ovelap"></div>
            <div className="main-wapper">
                <div className="loginbox">
                <div className="login-wave backendlogin"> <img src={loginBgLeft} alt="login-wave" /> </div>
                    <div className="login-form backendForgot">
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb20">
                                <img src={logo} alt="logo" />
                                <OnboardingBackBtn link='/brands/login' />
                                <h2 className="htx1">Update Password</h2>
                                <p className="ptx2">Setup a new password for <strong>mohit@netbramha.com</strong></p>
                            </div>
                        </div>
                        <form>
                            <div className="row">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-20">
                                    <PasswordField
                                        label="Enter New Password"
                                        isRequired={true}
                                        placeholder="********"
                                        formData={data}
                                        formKey="newPassword"
                                        onFormDataChange={onFormDataChange}
                                        formErrors={formErrors}
                                    />
                                </div>
                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                    <PasswordField
                                        label="Confirm New Password"
                                        isRequired={true}
                                        placeholder="********"
                                        formData={data}
                                        formKey="confirmNewPassword"
                                        onFormDataChange={onFormDataChange}
                                        formErrors={formErrors}
                                    />
                                </div>
                                
                                <div className="row col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                    <div className="forminpt">
                                        <input onClick={onSubmit} name="Update Password" value="Update Password" className="btn btn-orange datasubmit" type="button" />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ResetPassword
