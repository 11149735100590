import { 
    useState,
    useEffect,
    useContext,
 } from "react"

import {
    Route,
    useHistory,
    useRouteMatch,
    Switch,
    useLocation,
} from "react-router-dom"

import waveBootNew from "../../../../../assets/images/wavebotnew.png"
import logoNew from "../../../../../assets/images/logo-new.svg"

import { DataContextProvider } from "../../../../../service/contextProvider"

import RemainingInventories from "./remainingInventories"
import InventoryWizardProgress from "../../components/inventoryWizardProgress"
import InventoryDetails from "./inventoryDetails"
import StudentActivities from "../../onboarding/activities/activities"
import StudentActivitiesDetails from "../../onboarding/activityDetails/activityDetails"
import Summary from "./inventorySummary"

import Loader from "../../../../../components/addon/loader"

import AppContextObject from "../../../../../common/context/common"
import { PublicContextInitiator } from "../../../../../publicSrc/apis/publicContextInitiator"

import { objectDeepClone, formatInventories } from "../../../../../service/helper"
import InventoryProgress from "../../../backendAdmins/addInventory/inventoryProgress"

interface Props{
    fromBackend?: boolean,
    setShowBackendSidemenu?: any
}

const InventoryWizard = ({
    fromBackend,
    setShowBackendSidemenu
}: Props) => {

    const history = useHistory()
    const location = useLocation()
    const router = useRouteMatch()
    const context = useContext(AppContextObject)

    const [loading, setLoading] = useState(false)
    const [listOfInventories, setListOfInventories] = useState([])
    const [listOfEvents, setListOfEvents] = useState([])
    const [showSidemenu, setShowSidemenu] = useState(false)
    const [selectedInventories, setSelectedInventories] = useState([])
    const [newlyAddedInventories, setNewlyAddedInventories] = useState([])
    const [showMenu, setShowMenu] = useState(false)
    const [byPassEventFlow, setBypassEventFlow] = useState(false)
    const [byPassInventoryFlow, setByPassInventoryFlow] = useState(false)
    const [addInventory, setAddInventory] = useState(false)
    const [schoolId, setSchoolId] = useState<any>()
    const [fetchSchoolId, setFetchSchoolId] = useState(false)

    const [activeModule, setActiveModule] = useState({
        module: "",
        subModule: ""
    })

    const [progress, setProgress] = useState({
        personalDetails: false,
        schoolGeneral: false,
        studentSpecific: false,
        classroom: false,
        commonArea: false,
        otherRoomsAndStaff: false,
        outdoor: false,
        eventsAndOccasions: false,

        inventoryDetails: false
    })

    const [data, setData] = useState({
        student: {
            life: [],
            activities: []
        },        
    })

    const myValue: any = {
        selectedInventories,
        setSelectedInventories,
        setNewlyAddedInventories,
        newlyAddedInventories,
        listOfInventories,
        progress,
        setProgress,
        activeModule,
        setActiveModule,
        listOfEvents,
        data,
        setData
    }
    useEffect(() => {
        if(history.location.state){
            let stateObj:any = history.location.state
            setSchoolId(stateObj.schoolId)
            setAddInventory(stateObj.addInventory)
        }
        setFetchSchoolId(true)
        if(fromBackend){
            setShowBackendSidemenu(false)
        }
    }, [])

    useEffect(() => {
        if(fetchSchoolId){
            fetchRemainingInventories()
        }
    }, [fetchSchoolId])

    const fetchRemainingInventories = async () => {
        setLoading(true)
        let events
        if(fromBackend){
            events = await context.apis[context.user.role].getSchoolActivitiesToBeAddedExternal(
                context.validator, {school: schoolId}, {page: 1, limit: 50, withCount: true}
            )
        }
        else{
            events = await context.apis[context.user.role].getSchoolActivitiesToBeAdded(
                context.validator, {}, {page: 1, limit: 50, withCount: true}
            )
        }
        console.log("getSchoolActivitiesToBeAdded", events)
        if (events && events.done) {
            let inventoriesArr = events.response.rows.map(inventory => {
                inventory.placements = inventory.placements.map( placement => {
                    placement.data.length = placement.data.length != '' ? placement.data.length : 0;
                    placement.data.breadth = placement.data.breadth != '' ? placement.data.breadth : 0;
                    placement.data.height = placement.data.height != '' ? placement.data.height : 0;
                    placement.data.quantity = placement.data.quantity != '' ? placement.data.quantity : 0;

                    return placement;
                });
                return inventory;
            })
            let list = objectDeepClone(inventoriesArr)
            setListOfEvents(list)
        }
        let resp
        if(fromBackend){
            resp = await context.apis[context.user.role].getSchoolInventoriesToBeAddedExternal(
                context.validator, {school: schoolId}, {page: 1, limit: 50, withCount: true}
            )
        }
        else{
            resp = await context.apis[context.user.role].getSchoolInventoriesToBeAdded(
                context.validator, {}, {page: 1, limit: 50, withCount: true}
            )
        }
        console.log("getSchoolInventoriesToBeAdded", resp)
        if(resp && resp.done) {
            let list = objectDeepClone(resp?.response?.rows)
            const finalList  = formatInventories(list, "")
            finalList.forEach(((row, index) => {
                const found = resp?.response?.rows.findIndex(f => f.name.toLowerCase() === row.name.toLowerCase())
                if (found >= 0) {
                    finalList[index] = {
                        ...finalList[index],
                        ...objectDeepClone(resp?.response?.rows[found])
                    }
                }
            }))
            setListOfInventories(objectDeepClone(finalList))
        } else {
            setListOfInventories([])
        }        
        process.nextTick(() => {
            setLoading(false)
        })
    }

    const onProceed = (route) => {
        history.push(route)
    }
    // useEffect(() => {
    //     if(history.location.pathname.startsWith("/backend/backend-details/add-invetory")){
    //        console.log("removekey", context.validator.dropKey("school"))
    //     }
    // }, [history.location.pathname])

    return (
        <>
            <DataContextProvider value={myValue}>
                <div className={`ovelap ${showSidemenu === true ? "active" : ""}`}></div>
                <div className="main-wapper">
                    {
                        loading === true ?
                        <Loader />
                        :
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-3 col-lg-3 col-md-12 col-xs-12 pl-0">
                                    {
                                        showMenu === true &&
                                        <div className={`sliderbar onboarding-bar newMvp-sidebar ${showSidemenu === true ? "no_highlight" : ""}`}>
                                            <div className="row logo between-xs bottom-xs"><img src={logoNew} alt="logo" />
                                            </div>
                                            {fromBackend ?
                                                <InventoryProgress
                                                    addInventory={addInventory}
                                                />
                                                :
                                                <InventoryWizardProgress
                                                    byPassEventFlow={byPassEventFlow}
                                                    byPassInventoryFlow={byPassInventoryFlow}
                                                />
                                            }
                                            <img src={waveBootNew} className="wavebot" alt="wave" />
                                        </div>
                                    }
                                </div> 
                                {fetchSchoolId &&               
                                    <Switch>
                                        <Route path={`${router.url}/remaining-invetories`}>
                                            {fromBackend ?
                                                <RemainingInventories
                                                    schoolId={schoolId}
                                                    setShowMenu={setShowMenu}
                                                    fromBackend={true}
                                                />
                                                :
                                                <RemainingInventories
                                                    setShowMenu={setShowMenu}
                                                />
                                            }
                                        </Route>

                                        <Route path={`${router.url}/invetories-details`}>
                                            {fromBackend ?
                                                <InventoryDetails
                                                    schoolId={schoolId}
                                                    setShowMenu={setShowMenu}
                                                    isEdit={false}
                                                    hideRevenue={true}
                                                    byPassEventFlow={byPassEventFlow}
                                                    setBypassEventFlow={setBypassEventFlow}
                                                    fromBackend={true}
                                                    inDashboard={true}
                                                />
                                                :
                                                <InventoryDetails
                                                    setShowMenu={setShowMenu}
                                                    isEdit={false}
                                                    hideRevenue={true}
                                                    byPassEventFlow={byPassEventFlow}
                                                    setBypassEventFlow={setBypassEventFlow}
                                                    inDashboard={true}
                                                />
                                            }
                                        </Route>

                                        <Route path={`${router.url}/events-and-occasions`}>
                                            {fromBackend ? 
                                                <StudentActivities
                                                    isEdit={false}
                                                    hideRevenue={true}
                                                    onProceed={onProceed}
                                                    setShowMenu={setShowMenu}
                                                    showQuitButton={true}
                                                    fromBackend={true}
                                                    schoolId={schoolId}
                                                    redirectionURL={`${router.url}/events-and-occasions-details`}
                                                />
                                                :
                                                <StudentActivities
                                                    isEdit={false}
                                                    hideRevenue={true}
                                                    onProceed={onProceed}
                                                    setShowMenu={setShowMenu}
                                                    showQuitButton={true}
                                                    redirectionURL={`${router.url}/events-and-occasions-details`}
                                                />
                                            }
                                        </Route>

                                        <Route path={`${router.url}/events-and-occasions-details`}>
                                            {fromBackend ?
                                                <StudentActivitiesDetails
                                                    isEdit={false}
                                                    hideRevenue={true}
                                                    onProceed={onProceed}
                                                    setShowMenu={setShowMenu}
                                                    showQuitButton={true}
                                                    fromBackend={true}
                                                    schoolId={schoolId}
                                                    redirectionURL={`${router.url}/school-details-summary`}
                                                    byPassInventoryFlow={byPassInventoryFlow}
                                                    setByPassInventoryFlow={setByPassInventoryFlow}
                                                />
                                                :
                                                <StudentActivitiesDetails
                                                    isEdit={false}
                                                    hideRevenue={true}
                                                    onProceed={onProceed}
                                                    setShowMenu={setShowMenu}
                                                    showQuitButton={true}
                                                    redirectionURL={`${router.url}/school-details-summary`}
                                                    byPassInventoryFlow={byPassInventoryFlow}
                                                    setByPassInventoryFlow={setByPassInventoryFlow}
                                                />
                                            }
                                        </Route>

                                        <Route path={`${router.url}/school-details-summary`}>
                                            {fromBackend ?
                                                <Summary
                                                    byPassEventFlow={byPassEventFlow}
                                                    byPassInventoryFlow={byPassInventoryFlow}
                                                    fromBackend={true}
                                                    addInventory={addInventory}
                                                    schoolId={schoolId}
                                                    onProceed={onProceed}
                                                    setShowMenu={setShowMenu}
                                                    redirectionURL={"/"}
                                                    inventoryEditURL="/remaining-invetories"
                                                />
                                                :
                                                <Summary
                                                    byPassEventFlow={byPassEventFlow}
                                                    byPassInventoryFlow={byPassInventoryFlow}
                                                    onProceed={onProceed}
                                                    setShowMenu={setShowMenu}
                                                    redirectionURL={"/"}
                                                    inventoryEditURL="/remaining-invetories"
                                                />
                                            }
                                        </Route>
                                    </Switch>
                                }
                            </div>
                        </div>
                    }
                </div>
            </DataContextProvider>
        </>
    )
}

export default InventoryWizard
