import React, { useState } from 'react'
import { formatDateShowMonthBackend, returnReadableStatus, titleCase } from '../../../../../service/helper'
import blankImg from "../../../../../assets/images/blank-inventory-img.jpg"
import { useHistory } from 'react-router-dom'

const CampaignReportingSpan = ({ item, index }) => {
    let history = useHistory()
    const [notStarted, setNotStarted] = useState('not started')
    const [expand, setExpand] = useState(false)
    return (
        <div className="panel-group" id={`accordion${index}`} onClick={() => history.push({ pathname: '/backend/backend-details/campaign-school-list', state: { campaignId: item._id, campaignName: item.name } })}>
            <div className="panel panel-default">
                <div className="panel-heading">
                    {/* <h4 onClick={(e) => {setExpand(!expand); e.stopPropagation()}} className="panel-title title-text-head" data-toggle="collapse" data-target="#collapseOne"><strong>{item.name} </strong><span className="tag"> {returnReadableStatus(item.campaignStatus)}</span> </h4> */}

                    <div className='title-flex-container'>
                        {/* <div className='cl-row'> */}
                            <div style={{width:"75%"}} >
                                <h4 className="panel-title title-text-head" data-toggle="collapse" data-target="#collapseOne"><strong>{item.name} </strong> </h4>
                            </div>
                            <div  style={{width:"20%", marginRight:"3px"}}>
                                <span className="tag" style={{float:"right" }}> {returnReadableStatus(item.campaignStatus)}</ span>
                            </div>
                            {!expand &&
                                <div style={{width:"4%"}} onClick={(e) => {setExpand(true); e.stopPropagation()}}>
                                    
                                    <svg id={`down-arrow`} className="down-arrow" style={{display: expand && "none"}}  width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M1 6L6 0.999999L11 6" stroke="#8C8C8C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /> </svg>
                                </div>
                            }
                            {expand &&
                                <div style={{width:"4%"}} onClick={(e) => {setExpand(false); e.stopPropagation()}}>
                                    <svg id={`up-arrow`} style={{display: !expand && "none"}} width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M1 6L6 0.999999L11 6" stroke="#8C8C8C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                                </div>
                            }
                        {/* </div> */}
                        
                    </div>

                    <div className="cl-row mt10">

                        <div className="fl col-sm-6 col-md-6 col-xs-4" >
                            {/* <span style={{ color: "#8A8A8A", fontSize: "12px" }}>INVENTORIES </span><br /> */}
                            <span className="text-head">INVENTORIES </span><br />
                            <strong className="text-head-bold"> {item.campaignData?.inventories?.length} </strong>
                        </div>

                        <div className="fl col-sm-6 col-md-6 col-xs-8">
                            {/* <span style={{ color: "#8A8A8A", fontSize: "12px" }}> SCHEDULE </span><br /> */}
                            <span className="text-head"> SCHEDULE </span><br />
                            <strong className="text-head-bold">{formatDateShowMonthBackend(item.startDate, 2)} - {formatDateShowMonthBackend(item.endDate, 2)}</strong>
                        </div>
                    </div>
                    <br /><br />
                    <div className="cl-row mt10">
                        <div className="col-md-6 ">
                            <span className="text-head">STATUS </span><br />
                            {/* <strong className="status-gray status-orange status-green status-red text-head-bold">NOT STARTED</strong> */}
                            {/* <strong className={"myClass " + (notStarted === "not started" ? 'status-green' : 'status-orange')}>NOT STARTED</strong> */}
                            <strong className={item.campaignReportingStatus.toLowerCase() === "not-started" ? 'status-gray' : item.campaignReportingStatus.toLowerCase() === "in-progress" ? 'status-orange' : item.campaignReportingStatus.toLowerCase() === "completed" ? "status-green" : item.campaignReportingStatus.toLowerCase() === "rejected" && "status-red"}>{titleCase(item.campaignReportingStatus.toLowerCase()) || "Not started"}</strong>
                        </div>
                    </div>

                </div>
                {expand &&
                    <div id="collapseOne" className="panel-collapse collapse">
                        <div className="panel-body" style={{ border: 'none', backgroundColor: "#f5f5f5", marginTop: "-30px" }}>
                            <hr className="camp-list-hr" />

                            <div className="row cl-row mb-3"  >
                                <div className="col-xs-4 col-sm-4 col-md-4 themed-grid-col">
                                    <div className="pb-3"><img style={{ width: "100%" }} src={item?.campaignCreativesRevised && item?.campaignCreativesRevised.length > 0 ? item?.campaignCreativesRevised[0].image : (item?.campaignCreatives && item?.campaignCreatives.length > 0 && item?.campaignCreatives[0].image) ? item?.campaignCreatives[0].image : blankImg} /></div>
                                    <div className="row">
                                        <div className="column" style={{}}><img src={item?.campaignCreativesRevised && item?.campaignCreativesRevised.length > 1 ? item?.campaignCreativesRevised[1].image : (item?.campaignCreatives && item?.campaignCreatives.length > 1 && item?.campaignCreatives[1].image) ? item?.campaignCreatives[1].image : blankImg} /></div>
                                        <div className="column" style={{}}><img src={item?.campaignCreativesRevised && item?.campaignCreativesRevised.length > 2 ? item?.campaignCreativesRevised[2].image : (item?.campaignCreatives && item?.campaignCreatives.length > 2 && item?.campaignCreatives[2].image) ? item?.campaignCreatives[2].image : blankImg} /></div>
                                    </div>
                                    {/* <div className="row"> </div> */}
                                </div>
                                <div className="cl-row col-xs-8 col-sm-8 col-md-8 themed-grid-col" style={{}}>
                                    <h3 style={{ textAlign: "center", color: "#8A8A8A", fontSize: "12px", float: "left", marginTop: "0" }}>CAMPAIGN DESCRIPTION</h3>
                                    <p className="fl campaignDescription" style={{marginTop: '5px'}} > {item.description} </p>

                                </div>
                            </div>


                            {/* <Link to="/SchoolList">SchoolList</Link> */}


                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default CampaignReportingSpan