import React, { useEffect, useState, forwardRef } from 'react'
import { Line } from 'react-chartjs-2';
import { formatDateDDMonth } from '../../../../service/helper';

interface Props {
    total?: any,
    newly?: any,
    active?: any,
}
var data;
var dataSet1;
let tempData;
const LineChartBackend = forwardRef(({
    total, newly, active
}: Props, ref) => {
    const [schoolData, setSchoolData] = useState([]);
    const [isNewly, setIsNewly] = useState(true);
    const [isTotal, setIsTotal] = useState(true);
    const [isActive, setIsActive] = useState(true);
    const [flag, setFlag] = useState(false);


    useEffect(() => {
        // console.log('schoolData 111111111======>', schoolData)
        if (dataSet1 && dataSet1.length === 3) {

            tempData = [...dataSet1]
            document.getElementById('first').style.backgroundColor = dataSet1[0].backgroundColor
            document.getElementById('second').style.backgroundColor = dataSet1[1].backgroundColor
            document.getElementById('third').style.backgroundColor = dataSet1[2].backgroundColor
            document.getElementById('newly').innerText = dataSet1[0].label
            document.getElementById('total').innerText = dataSet1[1].label
            document.getElementById('active').innerText = dataSet1[2].label
        } else {
        }
    }, [dataSet1])
    useEffect(() => {
        if ((newly && total && active)) {
            dataSet1 = [
                {
                    label: "Newly added schools",
                    data: newly.map(item => item.count),
                    backgroundColor: "#F19DFF",
                    borderColor: "#F19DFF",
                    tension: 0,
                    borderWidth: 1,
                    pointRadius: 1,
                    pointHoverRadius: 1
                },
                {
                    label: "Total schools",
                    data: total.map(item => item.count),
                    backgroundColor: "#96E5FA",
                    borderColor: "#96E5FA",
                    tension: 0,
                    borderWidth: 1,
                    pointRadius: 1,
                    pointHoverRadius: 1
                },
                {
                    label: "Active schools",
                    data: active.map(item => item.count),
                    backgroundColor: "#ABA1FC",
                    borderColor: "#ABA1FC",
                    tension: 0,
                    borderWidth: 1,
                    pointRadius: 1,
                    pointHoverRadius: 1
                }
            ]
            setSchoolData(dataSet1);

        }
    }, [newly, total, active])
    useEffect(() => {
        // console.log('schoolData 3333333333======>', schoolData)
        if (total && schoolData) {
            // console.log('schoolData 777777777======>', schoolData)
            data = {
                labels: total.map((item, index) => formatDateDDMonth(item.date)),
                datasets: schoolData,
            };
            setFlag(!flag)
        }
    }, [schoolData])

    const toggleData = (value, fieldValue) => {
        // console.log('schoolData 4444444444444======>', schoolData)
        let indexArray;
        let selectedArray;
        // console.log('dddddd', indexArray, fieldValue)
        if (value === 'total' && fieldValue) {
            tempData = [...tempData]
            indexArray = tempData.findIndex(item => item.label === "Total schools")
            selectedArray = dataSet1.find(item => item.label === "Total schools")
            // console.log('true', selectedArray, indexArray)
            // console.log('prev', tempData)
            tempData.splice(indexArray, 0, selectedArray);
            setSchoolData(tempData)
            // console.log('after', tempData)
        }
        if (value === 'total' && !fieldValue) {
            tempData = [...tempData]
            indexArray = tempData.findIndex(item => item.label === "Total schools")
            selectedArray = tempData.find(item => item.label === "Total schools")
            tempData.splice(indexArray, 1);
            setSchoolData(tempData)
            // dataSet1.splice(indexArray,selectedArray);  
            // console.log('after', tempData)
            // setSchoolData(schoolData) 
            // console.log('inserted',indexArray,schoolData)
        }
        if (value === 'newly' && fieldValue) {
            tempData = [...tempData]
            indexArray = dataSet1.findIndex(item => item.label === "Newly added schools")
            selectedArray = dataSet1.find(item => item.label === "Newly added schools")
            // console.log('true', selectedArray, indexArray)
            // console.log('prev', tempData)
            tempData.splice(indexArray, 0, selectedArray);
            setSchoolData(tempData)
            // console.log('after', tempData)
            // console.log(indexArray)
        }
        if (value === 'newly' && !fieldValue) {
            tempData = [...tempData]
            indexArray = tempData.findIndex(item => item.label === "Newly added schools")
            selectedArray = dataSet1.find(item => item.label === "Newly added schools")
            tempData.splice(indexArray, 1);
            setSchoolData(tempData)
            // dataSet1.splice(indexArray,selectedArray);  
            // console.log('after', tempData)
        }
        if (value === 'active' && fieldValue) {
            tempData = [...tempData]
            indexArray = dataSet1.findIndex(item => item.label === "Active schools")
            selectedArray = dataSet1.find(item => item.label === "Active schools")
            // console.log(indexArray)
            tempData.splice(indexArray, 0, selectedArray);
            setSchoolData(tempData)
            // console.log('after', tempData)
            // console.log(indexArray)
        }
        if (value === 'active' && !fieldValue) {
            tempData = [...tempData]
            indexArray = tempData.findIndex(item => item.label === "Active schools")
            selectedArray = dataSet1.find(item => item.label === "Active schools")
            // console.log('false', selectedArray, indexArray)
            // console.log('prev', tempData)
            tempData.splice(indexArray, 1);
            setSchoolData(tempData)
            // dataSet1.splice(indexArray,selectedArray);  
            // console.log('after', tempData)
        }
    }
    // console.log('schoolData 555555555555======================>', schoolData, tempData, data)
    return (
        <>
            <div className='schoolLegend'>
                <a href="javascript:void(0)" className="row" onClick={() => { toggleData('newly', !isNewly); setIsNewly(!isNewly) }}>
                    <div id='first' className='box'></div>
                    <span id='newly' className={`schoolType ${isNewly ? '' : 'lingThrough'}`}></span>
                </a>

                <a href="javascript:void(0)" className="row" onClick={() => { toggleData('total', !isTotal); setIsTotal(!isTotal) }}>
                    <div id='second' className='box'></div>
                    <span id='total' className={`schoolType ${isTotal ? '' : 'lingThrough'}`}></span>
                </a>

                <a href="javascript:void(0)" className="row" onClick={() => { toggleData('active', !isActive); setIsActive(!isActive) }}>
                    <div id='third' className='box'></div>
                    <span id='active' className={`schoolType ${isActive ? '' : 'lingThrough'}`}></span>
                </a>
            </div>
            {data &&
                <Line
                    data={data}
                    options={{
                        indexAxis: 'x',
                        scales: {
                            y: {
                                beginAtZero: true,
                                title: {
                                    padding: 20,
                                    display: true,
                                    text: "No. of Schools",
                                    font: {
                                        family: 'Arial, Helvetica, sans-serif',
                                        size: 13,
                                      }
                                },
                                ticks:{
                                    stepSize: 1
                                }
                            },
                            x: {
                                beginAtZero: true,
                                title: {
                                    padding: 20,
                                    display: true,
                                    text: "Days",
                                    font: {
                                        family: 'Arial, Helvetica, sans-serif',
                                        size: 13,
                                      }
                                }
                            }
                        },
                        plugins: {
                            legend: {
                                display: false
                            }
                        }
                    }}
                    height="100"
                />
            }
        </>
    )
})
export default LineChartBackend;