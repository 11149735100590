import React, { useEffect } from 'react';

const DisableRightClick = () => {
    useEffect(() => {
        const handleContextmenu = e => {
            e.preventDefault()
        }
        document.addEventListener('contextmenu', handleContextmenu)
        return function cleanup() {
            document.removeEventListener('contextmenu', handleContextmenu)
        }
}, [ ]);

  return (
    <>
    
    </>
   )
}

export default DisableRightClick