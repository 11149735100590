import {
    useEffect,
    useRef,
    useState,
    useImperativeHandle,
    forwardRef,
    useContext
} from "react"

import SimpleReactValidator from "simple-react-validator"

import Textfield from "../../../../components/formFields/textField/textField"
import SingleSelect from "../../../../components/formFields/singleSelect/singleSelect"

import errorSVG from "../../../../assets/images/error.svg"

import config from "../../../../service/config"

import { objectDeepClone, compareObjs, validateFullName } from "../../../../service/helper"
import ConfirmAlert from "../../../../components/addon/confirmAlert"
import AppContextObject from "../../../../common/context/common"
import CreatableSelect from "../../../../components/formFields/creatableSelect/creatableSelect"
import DataContext from "../../../../service/brandContext"
import TextField from "../../../../components/formFields/textField/textField"

interface Props {
    data?: any,
    index: number,
    removeUser: Function,
    handleUserData: Function,
    hidePrompt: boolean,
    allData?: any,
    indexOfCMO?: number,
    allRoles?: any,
    refetch?: boolean,
    subCategories?: any,
    updateSubCategory?: Function,
    updateBrandNameOptions?: Function,
    brandNameOptions?: any,
    designationDisable?: boolean;
    isBackendUser?: any,
    setAllData?: any,
    handleIndexWithBrandName?: Function,
    indexWithCompanyName?: any
}
const UserCard = forwardRef((props: Props, ref) => {

    const {
        data,
        index,
        removeUser,
        handleUserData,
        hidePrompt,
        allData,
        indexOfCMO,
        allRoles,
        refetch,
        subCategories,
        updateSubCategory,
        designationDisable,
        updateBrandNameOptions,
        brandNameOptions,
        isBackendUser,
        setAllData,
        handleIndexWithBrandName,
        indexWithCompanyName
    } = props

    const [formValidator] = useState(useRef(new SimpleReactValidator({
        element: (message) =>
            <span className="valError"><img src={errorSVG} />{message}</span>
        })))
    const context = useContext(AppContextObject)
    const parentData = useContext<any>(DataContext)

    const [update, forceUpdate] = useState(false)
    const [formData, setFormData] = useState<any>({})
    const [oldFormData, setOldFormData] = useState<any>({})
    const [showPrompt, setShowPrompt] = useState(false)
    const [overlay, setOverlay] = useState(false)
    const [isChecked, setIsChecked] = useState(false)
    const [refetchAllData, setRefetchAllData] = useState(false)
    const [isCheckboxChange, setIsCheckboxChange] = useState(false)
    const checkboxRef = useRef()
    // const [allRoles, setAllRoles] = useState(config.designationOptions)

    useEffect(() => {
        setFormData(objectDeepClone(data))
        setOldFormData(objectDeepClone(data))
    }, [refetch])

    useEffect(() => {
        const isSame = compareObjs(formData, oldFormData)
        if (isSame === false) {
            setShowPrompt(true)
        } else {
            setShowPrompt(false)
        }
    }, [formData])

    useEffect(() => {
        if(hidePrompt){
            setShowPrompt(false)
        }
    }, [hidePrompt])

    useEffect(() => {
        // getBrandUsersDetails()
    }, [])

    const getBrandUsersDetails = async () => {
        let brandResp = await context.apis.public.getBrandById(context.validator,
            {_id: context.user.brand}
            )
        if(brandResp && brandResp.done){
            let data = [];
            for(let user of brandResp.response.userDetails){
                data.push({
                    role: user.role && user.role === "brandCMO" ? "CMO" : user.role === "brandManager" ? "Brand Manager" : "",
                })
            }
            const found = data.find(element => element.role === "CMO");
            if(found){
                // setAllRoles([
                //     {
                //         label: 'Brand Manager',
                //         value: 'Brand Manager'
                //     }
                // ])
            }else{
                // setAllRoles(config.designationOptions)
            }
        }
    }

    const onFormDataChange = (event) => {
        setFormData({
            ...formData,
            [event.target.id]: event.target.value
        })
        handleUserData({
            ...formData,
            [event.target.id]: event.target.value
        }, index)
    }

    const validateData = () => {
        const result = formValidator.current.allValid()
        if (result === false) {
            formValidator.current.showMessages()
        } else {
            formValidator.current.hideMessages()
        }
        forceUpdate(!update)

        return result
    }

    useImperativeHandle(ref, () => ({
        validateUser() {
            return validateData()
        },

        fetchData() {
            return formData
        }
    }))

    useEffect(() => {
    //     if(context && context.user.role === "brandCMO")
    //     {
    //         if(index === 0)
    //         {
    //             setFormData({
    //                 ...parentData.brandProfile.users[0],
    //                 memberDesignation: "CMO"
    //             })
    //             setOldFormData({
    //                 ...parentData.brandProfile.users[0],
    //                 memberDesignation: "CMO"
    //             })
    //             handleUserData({
    //                 ...parentData.brandProfile.users[0],
    //                 memberDesignation: "CMO"
    //             }, index)
    //         }

    //         // if(index === 1)
    //         // {
    //         //     setFormData({
    //         //         ...parentData.brandProfile.users[1],
    //         //         memberDesignation: "Brand Manager"
    //         //     })
    //         //     setOldFormData({
    //         //         ...parentData.brandProfile.users[1],
    //         //         memberDesignation: "Brand Manager"
    //         //     })
    //         //     handleUserData({
    //         //         ...parentData.brandProfile.users[1],
    //         //         memberDesignation: "Brand Manager"
    //         //     }, index)
    //         // }
    //     }

        if(context && context.user.role === "brandManager")
        {
            if(index === 0)
            {
                setFormData({
                    ...parentData.brandProfile.users[0],
                    memberDesignation: context.user.designation !== "Brand Manager" ? "other" : "Brand Manager",
                    designation: context.user.designation !== "Brand Manager" ? context.user.designation : "Brand Manager"
                })
                setOldFormData({
                    ...parentData.brandProfile.users[0],
                    memberDesignation: context.user.designation !== "Brand Manager" ? "other" : "Brand Manager",
                    designation: context.user.designation !== "Brand Manager" ? context.user.designation : "Brand Manager"
                })
                handleUserData({
                    ...parentData.brandProfile.users[0],
                    memberDesignation: context.user.designation !== "Brand Manager" ? "other" : "Brand Manager",
                    designation: context.user.designation !== "Brand Manager" ? context.user.designation : "Brand Manager"
                }, index)
            }
        }

    //         // if(index === 1)
    //         // {
    //         //     setFormData({
    //         //         ...parentData.brandProfile.users[1],
    //         //         memberDesignation: "CMO"
    //         //     })
    //         //     setOldFormData({
    //         //         ...parentData.brandProfile.users[1],
    //         //         memberDesignation: "CMO"
    //         //     })
    //         //     handleUserData({
    //         //         ...parentData.brandProfile.users[1],
    //         //         memberDesignation: "CMO"
    //         //     }, index)
    //         // }
    //     }
    //     if(isBackendUser) 
    //     {
    //         setFormData({
    //             ...parentData.brandProfile.users[0],
    //             memberDesignation: "",
    //             designation: ""
    //         })
    //         setOldFormData({
    //             ...parentData.brandProfile.users[0],
    //             memberDesignation: "",
    //             designation: ""
    //         })
    //         handleUserData({
    //             ...parentData.brandProfile.users[0],
    //             memberDesignation: "",
    //             designation: ""
    //         }, index)
    //     }
        
    }, [])

    useEffect(() => {
        let companyName = parentData.brandProfile.general.companyName || (context.user.companyName ? context.user.companyName : context.user.brandName)
        if(formData.isCompanyNameChecked){
            // if(formData.brandNameList){
                // let newBrandName = formData.brandNameList || [];
                // newBrandName.push({
                //     label: companyName,
                //     value: companyName
                // })
                // setFormData({
                //     ...formData,
                //     brandName: companyName || "",
                //     brandNameList: newBrandName
                // })
                // handleUserData({
                //     ...formData,
                //     brandName: companyName || "",
                //     brandNameList: newBrandName
                // }, index)
                // updateBrandNameOptions(newBrandName)
            // }
            
        }else{
            // if(formData.brandNameList){
                // let removeBrandName = formData.brandNameList;
                // if(removeBrandName){
                //     removeBrandName = removeBrandName.filter(el => el.value.toLowerCase() !== companyName.toLowerCase())
                //     updateBrandNameOptions(removeBrandName)
                //     setFormData({
                //         ...formData,
                //         brandNameList: removeBrandName
                //     })
                //     handleUserData({
                //         ...formData,
                //         brandNameList: removeBrandName
                //     }, index)
                // }
                
            // }
            
            // setFormData({
            //     ...formData,
            //     isCompanyNameChecked: false
            // })
            // handleUserData({
            //     ...formData,
            //     isCompanyNameChecked: false
            // }, index)
        }
    }, [formData.isCompanyNameChecked])

    // useEffect(() => {
    //     let companyName = parentData.brandProfile.general.companyName || (context.user.companyName ? context.user.companyName : context.user.brandName)
    //     let parentFormDataData = allData
    //     // let requests  = parentFormDataData.map((data,i)=>{
            
    //     // })
    //     // Promise.all(requests).then(() => {
    //     //     setAllData(parentFormDataData)
            
    //     // })
    //     for(let [i, data] of parentFormDataData.entries()){
    //         if(i !== index){
    //             if(formData.brandName !== companyName && data.memberDesignation !== "CMO"){
    //                 if(!data["brandNameList"]){
    //                     data["brandNameList"] = [];
    //                     data["brandNameList"].push({
    //                         label: formData.brandName,
    //                         value: formData.brandName
    //                     })
    //                 }else{
    //                     data["brandNameList"].push({
    //                         label: formData.brandName,
    //                         value: formData.brandName
    //                     })
    //                 }
                    
    //             }
    //         }
    //     }
    //     setAllData(parentFormDataData)
    // }, [formData, index])

    useEffect(() => {
        let companyName = parentData.brandProfile.general.companyName || (context.user?.companyName ? context.user?.companyName : context.user?.brandName)

        if(indexWithCompanyName === -1){
            let removeBrandName = brandNameOptions;
            if(removeBrandName){
                removeBrandName = removeBrandName.filter(el => el.value?.toLowerCase() !== companyName?.toLowerCase())
                updateBrandNameOptions(removeBrandName)
            }
        }
    }, [indexWithCompanyName, formData.brandName])
    
    


    const onDesignationChange = (data) => {
        setFormData({
            ...formData,
            memberDesignation: data ? data.value : ""
        })
        handleUserData({
            ...formData,
            memberDesignation: data ? data.value : ""
        }, index)
        if(data.value === "CMO"){
            formValidator.current.purgeFields()
        }
        if(data.value === "other"){
            // setNewUserData({
            //     ...newUserData,
            //     role: data.value,
            //     designation: ""
            // })
            setFormData({
                ...formData,
                memberDesignation: data ? data.value : "",
                designation: ""
            })
            handleUserData({
                ...formData,
                memberDesignation: data ? data.value : "",
                designation: ""
            }, index)
        }else if(data.value === "CMO"){
            let userData = {...formData}
            delete userData.brandName
            delete userData.subCategoryName
            // setNewUserData({
            //     ...userData,
            //     role: data.value,
            //     designation: data.value
            // })
            setFormData({
                ...userData,
                memberDesignation: data ? data.value : "",
                designation: "",
                isCompanyNameChecked: false
            })
            handleUserData({
                ...userData,
                memberDesignation: data ? data.value : "",
                designation: "",
                isCompanyNameChecked: false
            }, index)
        }else{
            // setNewUserData({
            //     ...newUserData,
            //     role: data.value,
            //     designation: data.value
            // })
            setFormData({
                ...formData,
                memberDesignation: data ? data.value : "",
                designation: "",
            })
            handleUserData({
                ...formData,
                memberDesignation: data ? data.value : "",
                designation: "",
            }, index)
        }
    }

    const onSubCategoryChange = (data) => {
        if(data){
            setFormData({
                ...formData,
                subCategoryName: data ? data.value.trim() : ""
            })
            handleUserData({
                ...formData,
                subCategoryName: data ? data.value.trim() : ""
            }, index)
        }else{
            setFormData({
                ...formData,
                subCategoryName: ""
            })
            handleUserData({
                ...formData,
                subCategoryName: ""
            }, index)
        }
        
    }

    const onBrandNameChange = (data) => {
        let companyName = parentData.brandProfile.general.companyName || (context.user.companyName ? context.user.companyName : context.user.brandName)
        if(data){
            if(data.value !== companyName){
                setIsChecked(false)
                setFormData({
                    ...formData,
                    brandName: data ? data.value : "",
                    isCompanyNameChecked: false
                })
                handleUserData({
                    ...formData,
                    brandName: data ? data.value : "",
                    isCompanyNameChecked: false
                }, index)
            }else{
                setFormData({
                    ...formData,
                    brandName: data ? data.value : ""
                })
                handleUserData({
                    ...formData,
                    brandName: data ? data.value : ""
                }, index)
            }
        }else{
            setFormData({
                ...formData,
                brandName: "",
                isCompanyNameChecked: false
            })
            handleUserData({
                ...formData,
                brandName: "",
                isCompanyNameChecked: false
            }, index)
        }
        
    }

    const onNewBrandNameChange = (e) => {
        let companyName = parentData.brandProfile.general.companyName || (context.user.companyName ? context.user.companyName : context.user.brandName)

        if(e.target.value.toLowerCase() !== companyName.toLowerCase()){
            setFormData({
                ...formData,
                brandName: e.target.value,
                isCompanyNameChecked: false
            })
            handleUserData({
                ...formData,
                brandName: e.target.value,
                isCompanyNameChecked: false
            }, index)
            // if(e.target.checked == false){
                handleIndexWithBrandName(-1)
            // }else{
                // handleIndexWithBrandName(index)
            // }
        }else{
            setFormData({
                ...formData,
                brandName: e.target.value,
                isCompanyNameChecked: true
            })
            handleUserData({
                ...formData,
                brandName: e.target.value,
                isCompanyNameChecked: true
            }, index)
            handleIndexWithBrandName(index)
        }
    }

    const addCustomBrandOption = (e) => {
        if(!formValidator.current.fields.brandName){
            formValidator.current.showMessageFor("brandName")
            forceUpdate(!update)
            return
        }
        let companyName = parentData.brandProfile.general.companyName || (context.user.companyName ? context.user.companyName : context.user.brandName)

        let newBrandName = objectDeepClone(brandNameOptions) || [];
        let found = newBrandName.find(el => el.value === e.target.value)
        if(!found){
            newBrandName.push({
                label: e.target.value,
                value: e.target.value
            })
        }
        updateBrandNameOptions(newBrandName)
        if(e.target.value.toLowerCase() !== companyName.toLowerCase()){
            setFormData({
                ...formData,
                brandName: e.target.value,
                isTextfield: false
            })
            handleUserData({
                ...formData,
                brandName: e.target.value,
                isTextfield: false
            }, index)
        }
       
        // setFormData({
        //     ...formData,
        //     isTextfield: false
        // })
        // handleUserData({
        //     ...formData,
        //     isTextfield: false
        // }, index)
    }

    const addNewOption = (value) => {
        let newSubCategories = objectDeepClone(subCategories);
        newSubCategories.push({
            label: value.trim(),
            value: value.trim()
        })
        updateSubCategory(newSubCategories)
        setFormData({
            ...formData,
            subCategoryName: value.trim()
        })
        handleUserData({
            ...formData,
            subCategoryName: value.trim()
        }, index)
    }

    const addNewBrandOption = (value) => {
        let companyName = parentData.brandProfile.general.companyName || (context.user?.companyName ? context.user?.companyName : context.user?.brandName)

        let newBrandName = objectDeepClone(brandNameOptions) || [];
        newBrandName.push({
            label: value,
            value: value
        })
        updateBrandNameOptions(newBrandName)
        setFormData({
            ...formData,
            brandName: value,
            brandNameList: newBrandName,
            isCompanyNameChecked: companyName?.toLowerCase() === value?.toLowerCase() ? true : false
        })
        handleUserData({
            ...formData,
            brandName: value,
            brandNameList: newBrandName,
            isCompanyNameChecked: companyName?.toLowerCase() === value?.toLowerCase() ? true : false
        }, index)
    }

    const onNewUserOtherDesignationChange = (e) => {
        setFormData({
            ...formData,
            designation: e.target.value
        })
        handleUserData({
            ...formData,
            designation: e.target.value
        }, index)
    }


    const confirmAlertCallback = (data) => {
        setOverlay(data.overlay)
        // setShowPrompt(false)
    }

    formValidator.current.rules['full_name'] = {
        message: 'Please enter valid full name.',
        rule: (val) => {
            let returnValue = validateFullName(val)

            return returnValue
        },
    }

    formValidator.current.rules['alpha_space_limit_special'] = {
        message: 'Please enter valid brand name.',
        rule: (val) => {
            let reg = /^[0-9a-zA-Z\s\(\)\&\,\')\(+=._-]+$/;
            let returnValue = reg.test(val)
            return returnValue
        }
    }

    const handleCheckbox = (e, i) => {
        let companyName = parentData.brandProfile.general.companyName || (context.user.companyName ? context.user.companyName : context.user.brandName)

        setFormData({
            ...formData, 
            isCompanyNameChecked: e.target.checked, 
            isTextfield: e.target.checked,
            brandName: e.target.checked ? companyName : ""
        })
        handleUserData({
            ...formData,
            isCompanyNameChecked: e.target.checked,
            isTextfield: e.target.checked,
            brandName: e.target.checked ? companyName : ""
        }, index)

        if(e.target.checked == false){
            handleIndexWithBrandName(-1)
        }else{
            handleIndexWithBrandName(i)
        }

    }

    formValidator.current.rules['valid_other_designation'] = {
        message: 'Please enter valid designation.',
        rule: (val) => {
            let returnValue = !(val.toLowerCase() === "brand manager" || val.toLowerCase() === "cmo")
            return returnValue
        }
    }
    
    return (
        <>
        <div className={`ovelap ${overlay === true ? "active" : ""}`}></div>
        <div className="row mb-20">
                
            {
                // index == 0
                // ?
                // <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-20">
                //     <h2 className="htx2 clearfix">{
                //         context?.user?.role === "brandCMO"
                //         ?
                //         "Brand Manager Details"
                //         :
                //         context?.user?.role === "brandManager"
                //         ?
                //         "Brand CMO Details"
                //         :
                //         ""
                //     } </h2>
                // </div>
                // :
                index > 0
                ?
                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-20">
                    <h2 className="htx2 clearfix">Team member {(index - 1) + 1} {
                        index > 0
                        &&
                        <a href="javascript:void(0);" className="viewwLink" onClick={()=>removeUser(index)} >Delete</a>
                    }</h2>
                </div>
                :
                ""
            }
                
            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                <Textfield
                    formData={formData}
                    formKey='memberName'
                    onDataChange={onFormDataChange}
                    label={index === 0 ? 'Your Name' : 'Team Member’s Name'}
                    placeholder='Member Name'
                    isRequired={false}
                    formValidator={formValidator}
                    maxLength={50}
                    validation={`${
                        index === 0 && (formData.memberDesignation !== "CMO")  ?
                        'required|full_name'
                        :
                        index > 0 && (formData.memberDesignation !== "CMO")  && ((formData.brandName && formData.brandName.length > 0) || (formData.memberDesignation && formData.memberDesignation.length > 0) || (formData.subCategoryName && formData.subCategoryName.length > 0)|| (formData.memberEmail && formData.memberEmail.length > 0)) 
                        ? 'required|full_name' 
                        : formData.memberDesignation === "CMO" && (formData.memberEmail && formData.memberEmail.length > 0 || formData.memberDesignation && formData.memberDesignation.length > 0 ) 
                        ? 'required|full_name' 
                        : 'full_name'
                    }`}
                    // style={{overflowWrap: "break-word"}}
                    // validation='full_name'
                />
            </div>
            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                <Textfield
                    formData={formData}
                    formKey='memberEmail'
                    onDataChange={onFormDataChange}
                    label={index === 0 ? 'Your Email ID' : 'Team Member’s Email ID'}
                    placeholder='Email ID'
                    isRequired={false}
                    formValidator={formValidator}
                    validation={`${
                        index === 0 && (formData.memberDesignation !== "CMO") 
                        ? 'required|email'
                        : index > 0 && (formData.memberDesignation !== "CMO")  && ((formData.brandName && formData.brandName.length > 0) || (formData.memberDesignation && formData.memberDesignation.length > 0) || (formData.subCategoryName && formData.subCategoryName.length  > 0) || (formData.memberName && formData.memberName.length > 0)) 
                        ? 'required|email' 
                        : formData.memberDesignation === "CMO" && (formData.memberName && formData.memberName.length > 0 || formData.memberDesignation && formData.memberDesignation.length > 0 ) 
                        ? 'required|email' 
                        : 'email'
                    }`}
                    // validation='email'
                />
            </div>
            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                <SingleSelect
                    formData={formData}
                    formKey='memberDesignation'
                    onDataChange={onDesignationChange}
                    label={index === 0 ? 'Your Designation' : 'Team Member’s Designation'}
                    placeholder='Designation'
                    isRequired={false}
                    options={allRoles}
                    formValidator={formValidator}
                    isDisable={designationDisable || false}
                    validation={`${
                        index === 0 && (formData.memberDesignation !== "CMO") 
                        ? 'required'
                        : (formData.memberDesignation !== "CMO")  && ((formData.brandName && formData.brandName.length > 0) || (formData.designation && formData.designation.length > 0) || (formData.subCategoryName && formData.subCategoryName.length  > 0) || (formData.memberName && formData.memberName.length > 0)) 
                        ? 'required' 
                        : formData.memberDesignation === "CMO" && (formData.memberName && formData.memberName.length > 0 || formData.memberEmail && formData.memberEmail.length > 0)
                        ? 'required' 
                        : ''
                    }`}
                    // validation=''
                    // isDisable={isBackendUser ? false : index === 0 && formData.memberDesignation !== "CMO" ? false : index === 0 && formData.memberDesignation === "CMO" ? true : index === 1 && formData.memberDesignation !== "CMO" ? false : index === 1 && formData.memberDesignation ? true : false}
                />
                {formData && formData.memberDesignation && formData.memberDesignation === "other" &&
                    <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 pl pr">
                        <TextField
                            formData={formData}
                            formKey='designation'
                            onDataChange={onNewUserOtherDesignationChange}
                            label='Other'
                            placeholder='Designation'
                            isRequired={false}
                            formValidator={formValidator}
                            validation={`${
                                index === 0 && (formData.memberDesignation !== "CMO") 
                                ? 'required|alpha_space|valid_other_designation'
                                : index > 0 && (formData.memberDesignation !== "CMO")  && ((formData.brandName && formData.brandName.length > 0) || (formData.memberDesignation && formData.memberDesignation.length > 0) || (formData.subCategoryName && formData.subCategoryName.length  > 0) || (formData.memberName && formData.memberName.length > 0)) 
                                ? 'required|alpha_space|valid_other_designation' 
                                : formData.memberDesignation === "CMO" && (formData.memberName && formData.memberName.length > 0 || formData.memberEmail && formData.memberEmail.length > 0)
                                ? 'required|alpha_space|valid_other_designation' 
                                : ''
                            }`}
                            maxLength={50}
                            // isDisabled={index === 0 ? true : index === 1 && formData.memberDesignation ? true : false}
                            // isDisabled={index === 0 ? true : false}
                        />
                    </div>
                }
            </div>
            {
                // formData.memberDesignation !== "CMO"
                // ?
                <>
                    <div className={formData.memberDesignation === "CMO" ? "col-xl-12 col-lg-12 col-md-12 col-xs-12 displayNone" : "col-xl-12 col-lg-12 col-md-12 col-xs-12"}>
                        <CreatableSelect
                            formData={formData}
                            formKey='subCategoryName'
                            onDataChange={onSubCategoryChange}
                            onCreate={addNewOption}
                            label='Sub Category'
                            placeholder='Sub Category'
                            isRequired={false}
                            options={subCategories}
                            formValidator={formValidator}
                            validation={`${
                                formData.memberDesignation === "CMO"
                                ? ""
                                :
                                index === 0 && (formData.memberDesignation !== "CMO") ?
                                'required|alpha_space'
                                :
                                index > 0 && (formData.memberDesignation !== "CMO") && ((formData.brandName && formData.brandName.length > 0)) 
                                ? "required|alpha_space" 
                                : ""
                            }`}
                            // validation=""
                            helpText="Insert or create a subcategory for your brand from the dropdown."
                            emptyOptionsMessage="Create a Sub Category"
                            maxLength={50}
                            validateOptionRule="alpha_space"
                        />
                    </div>
                    <div className={formData.memberDesignation === "CMO" ? "col-xl-12 col-lg-12 col-md-12 col-xs-12 displayNone" : "col-xl-12 col-lg-12 col-md-12 col-xs-12"}>
                        {/* <Textfield
                            formData={formData}
                            formKey='brandName'
                            onDataChange={onFormDataChange}
                            label='Brand Name'
                            placeholder='Brand Name'
                            isRequired={false}
                            formValidator={formValidator}
                            // validation={`${index < 2 && (formData.memberDesignation === "Brand Manager" || formData.memberDesignation === "other") ? "required" : ""}`}
                            validation=""
                            helpText="Enter your brand name here."
                        /> */}
                        {
                            formData.isTextfield ?
                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                <TextField
                                    formData={formData}
                                    formKey='brandName'
                                    onDataChange={onNewBrandNameChange}
                                    label='Brand Name'
                                    placeholder='Brand Name'
                                    isRequired={false}
                                    formValidator={formValidator}
                                    validation={`${
                                        formData.memberDesignation === "CMO"
                                        ? ""
                                        :
                                        index === 0 && (formData.memberDesignation !== "CMO") 
                                        ?
                                        'required|alpha_space_limit_special'
                                        : 
                                        index > 0 && (formData.memberDesignation !== "CMO") && ((formData.brandName && formData.brandName.length > 0)) 
                                        ? "required|alpha_space_limit_special" 
                                         
                                        : ""
                                    }`}
                                    onBlur={addCustomBrandOption}
                                    isBlur={true}
                                    maxLength={100}
                                    autoFocus={formData.isCompanyNameChecked}
                                    // isDisabled={index === 0 ? true : index === 1 && formData.memberDesignation ? true : false}
                                    // isDisabled={index === 0 ? true : false}
                                />
                            </div>
                            :
                            <CreatableSelect
                            formData={formData}
                            formKey='brandName'
                            onDataChange={onBrandNameChange}
                            onCreate={addNewBrandOption}
                            label='Brand Name'
                            placeholder='Brand Name'
                            isRequired={false}
                            options={brandNameOptions}
                            formValidator={formValidator}
                            validation={`${
                                formData.memberDesignation === "CMO"
                                ? ""
                                :
                                index === 0 && (formData.memberDesignation !== "CMO") 
                                ?
                                'required|alpha_space_limit_special'
                                : 
                                index > 0 && (formData.memberDesignation !== "CMO") && ((formData.brandName && formData.brandName.length > 0)) 
                                ? "required|alpha_space_limit_special"
                                : ""
                            }`}
                            // validation=""
                            helpText="Enter your brand name here."
                            emptyOptionsMessage="Create a Brand Name"
                            maxLength={100}
                            validateOptionRule="alpha_space_limit_special"
                            />
                        }
                            {
                                (indexWithCompanyName === -1 || index === indexWithCompanyName)
                                &&
                                <div className='forminpt mb-0'>
                                    <div className='checkbox onboardcheck'>
                                        <input
                                            type='checkbox'
                                            id={`keepCompanyName${index}`}
                                            onChange={(e)=>handleCheckbox(e, index)}
                                            ref={checkboxRef}
                                            checked={formData.isCompanyNameChecked ? true : false}
                                        />
                                        <label style={{display: "inline"}} htmlFor={`keepCompanyName${index}`}> <b>Keep company name as a brand name</b></label>
                                    </div>
                                </div>
                            }
                            
                    </div>
                </>
                // :
                // ""
            }
            
            <ConfirmAlert
                when={showPrompt}
                title="Leave page without saving..."
                cancelText="Cancel"
                okText="Confirm"
                onOK={() => true}
                onCancel={() => false}
                parentCallback={confirmAlertCallback}
            />
        </div>  
        </>      
    )
})

export default UserCard
