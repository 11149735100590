import React from "react";
import { useHistory } from "react-router";
interface Props {
  isEdit ? : boolean
}
const SchoolLife = ({ editImage, studentImage, life, isEdit, addSchoolFromBackend }) => {
  const history = useHistory();

  const goToStudentActivities = () => {
    if(addSchoolFromBackend){
      history.push({
        pathname: "/backend/backend-details/schools/onboarding/school-activities",
        search: `isSummary=${true}`,
      });
    }else if(isEdit === true){
      history.push({
        pathname: "/schools/school-details/details/activities/student-life",
        search: `isSummary=${true}`,
      });
    }else{
      history.push({
        pathname: "/schools/onboarding/school-activities",
        search: `isSummary=${true}`,
      });
    }    
  };

  return (
    <div className='col-xl-12 col-lg-12 col-md-12 col-xs-12 dnone'>
      <div className='personal-box'>
        <div className='row personal-box-top'>
          <div className='row col-xl-8 col-lg-8 col-md-8 col-xs-8 start-md'>
            <h3 className='htx2 mb20'>Student Life</h3>
          </div>
          <div className='row col-xl-4 col-lg-4 col-md-4 col-xs-4 end-xs'>
            <a
              href='javascript:void(0)'
              onClick={goToStudentActivities}
              className='editbtn'
            >
              <img src={editImage} /> Edit
            </a>
          </div>
        </div>
        <div className='personal-bottom'>
          <div className='row personal-box-bot'>
            <div className='col-xl-9 col-lg-9 col-md-8 col-xs-12'>
              <div className='col-xl-12 col-lg-12 col-md-12 col-xs-12'>
                <p>Amenities</p>
              </div>
              {life.map((item, index) => {
                return (
                  <div
                    className='col-xl-12 col-lg-12 col-md-12 col-xs-12'
                    key={index}
                  >
                    <b>{item.name}</b>
                  </div>
                );
              })}
            </div>
            <div className='col-xl-3 col-lg-3 col-md-4 col-xs-12'>
              <div className='student-img'>
                <img src={studentImage} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SchoolLife;
