import {
    useState,
    useContext,
    useEffect,
    useRef,
    useImperativeHandle,
    forwardRef
} from "react"

import SimpleReactValidator from "simple-react-validator"

import AppContextObject from "../../common/context/common"

import Textfield from "../formFields/textField/textField"

import errorSVG from "../../assets/images/error.svg"

import { PublicContextInitiator } from "../../../src/publicSrc/apis/publicContextInitiator"

import Compress from "compress.js"

import toast from "react-hot-toast"
import ImageModal from "./imageModal/imageModal"

import noImg from "../../assets/images/blank-inventory-img.jpg"

interface Props {
    documentName: string,
    fileUploadCallback: Function,
    formKey: string,
    data: any,
    onDataChange: Function
    validationRule: any,
    parentData?: any,
    isBackendUser?: any
}

const FileUploader = forwardRef((props: Props, ref) => {

    const {
        documentName,
        fileUploadCallback,
        formKey,
        data,
        onDataChange,
        validationRule,
        parentData,
        isBackendUser
    } = props

    const compress = new Compress()
    const context = useContext(AppContextObject)

    const [formValidator] = useState(useRef(new SimpleReactValidator({
        element: (message) =>
            <span className="valError">
                <img src={errorSVG} />
                {
                    message === "The document no field is required."
                    ?
                    "The document number field is required."
                    :
                    message
                }</span>
        })))

    const [update, forceUpdate] = useState(false)
    const [imageSrc, setImageSrc] = useState("")
    const [loading, setLoading] = useState(false)
    const [overlay, setOverlay] = useState(false)
    const [toggleImage, setToggleImage] = useState(false)
    const [isPDF, setIsPDF] = useState(false)
    const [fileType, setFileType] = useState("")
    const [errorMsg, showErrorMsg] = useState(false)
    console.log("parentData123->",parentData)

    const validateForm = () => {
        const result = formValidator.current.allValid()
        if (result === false) {
            formValidator.current.showMessages()
        } else {
            formValidator.current.hideMessages()
        }
        forceUpdate(!update)
        return result
    }
    useImperativeHandle(ref, () => ({
        validatData() {
            return validateForm()
        }
    }))
    console.log("data>>>>",data);
    useEffect(() => {
        if (data?.image) {
            setLoading(true)
            const publicContextInitiator = new PublicContextInitiator(context)
            const payload = [data.image]
            if((data.key && data.key.endsWith(".pdf")) || (data.image && data.image.endsWith(".pdf"))){
                console.log("coming inside",data);
                setIsPDF(true)
                setFileType("application/pdf")
            }else{
                setIsPDF(false)
            }
            if(data?.image.startsWith("https://") || data?.image.endsWith("SignedHeaders=host"))
            {
                setLoading(false)
                setImageSrc(data?.image)
                setFileType("image")
            }else{
                publicContextInitiator.getUploadedImagesSignedUrl(
                    context.validator, {keys: payload}
                ).then(res=>{
                    console.log("getUploadedImagesSignedUrl res-->>>>",res);
                    if (res?.response && res?.response?.length > 0) {
                        setImageSrc(res.response[0].image)
                    }
                    if(res.response[0].key.endsWith(".pdf")){
                        setFileType("application/pdf")
                    }else{
                        setFileType("image")
                    }
                    setLoading(false)
                })
                .catch(err => {
                    console.log("Error while fetching image:", err)
                    setLoading(false)
                })
            }
        }
    }, [data?.image])

    console.log("data====",data);
    console.log("isPdf", isPDF);
    console.log("ImageSrc---->>>",imageSrc);

    const handleUpload = async (file) => {
        const formData = new FormData();
        showErrorMsg(false)
        for (let i = 0; i < file.length; i++) {
            console.log("handleupload type",file[i]['type']);
            if (file[i]['type'].includes("image")) {
                setFileType(file[i]['type'])
                const resizedImage = await compress.compress([file[i]], {
                    size: 2, // the max size in MB, defaults to 2MB
                    quality: 0.4, // the quality of the image, max is 1,
                    resize: false // defaults to true, set false if you do not want to resize the image width and height
                }).catch(err => {
                    console.log("Error while compressing....", err)
                })
                const img = resizedImage[0];
                const base64str = img.data
                const imgExt = img.ext
                let compressedFile = Compress.convertBase64ToFile(base64str, imgExt)
                formData.append(`image`,compressedFile, img.alt)
                let reqBody = formData
                // let destinationFolder = `brands/${context?.user?.parentBrand ? context?.user?.parentBrand : context?.user?.brand}-${parentData?.brandProfile?.general?.companyName ? parentData?.brandProfile?.general?.companyName.trim() : context?.user?.brandName.trim()}/documents`
                let destinationFolder = ""
                if(isBackendUser){
                    destinationFolder = ""
                }else{
                    destinationFolder = `brands/${context?.user?.parentBrand ? context?.user?.parentBrand : context?.user?.brand}/documents`
                }
                reqBody.append('destinationFolder',destinationFolder)
                fileUploader(reqBody)
                setIsPDF(false)
            } else if (file[i]['type'].includes("pdf")) {
                setFileType(file[i]['type'])
                console.log("inside pdf upload");
                formData.append("image", file[i])
                let reqBody = formData
                // let destinationFolder = `brands/${context?.user?.parentBrand ? context?.user?.parentBrand : context?.user?.brand}-${parentData?.brandProfile?.general?.companyName ? parentData?.brandProfile?.general?.companyName.trim() : context?.user?.brandName.trim()}/documents`
                let destinationFolder = ""
                if(isBackendUser){
                    destinationFolder = ""
                }else{
                    destinationFolder = `brands/${context?.user?.parentBrand ? context?.user?.parentBrand : context?.user?.brand}/documents`
                }
                reqBody.append('destinationFolder',destinationFolder)
                fileUploader(reqBody)
                setIsPDF(true)
            } else {
                showErrorMsg(true)
                toast.error("Please upload pdf or image file")
                return
            }
        }
    }

    const fileUploader = (formData) => {
        const requestOptions = {
            method: 'POST',
            body: formData
        }
        setLoading(true)
        const publicContextInitiator = new PublicContextInitiator(context)
        fetch('/api/uploadFiles', requestOptions)
        .then(response => {
            response.json()
            .then(res=>{
                if (res?.response && res?.response.length > 0) {
                    if (fileUploadCallback) {
                        fileUploadCallback(formKey, res.response[0].Key)
                    }
                }
                let data = []
                res["response"].map(async item=>{
                    data.push(item.Key)
                })
                publicContextInitiator.getUploadedImagesSignedUrl(
                    context.validator, {keys: data}
                ).then(res=> {
                    console.log("res--->>>>>>>",res);
                    if (res?.response && res?.response?.length > 0) {
                        setImageSrc(res.response[0].image)
                    }
                    setLoading(false)
                })
                .catch(err => {
                    console.log("Error while fetching image:", err)
                    setLoading(false)
                })
            })
        })
        .catch(err => {
            console.log("Error while fetching image:", err)
            setLoading(false)
        })        
    }

    const onFormDataChange = (event) => {
        onDataChange(formKey, event.target.value)
    }

    formValidator.current.rules['pan_card'] = {
        message: 'Please enter valid PAN Number.',
        rule: (val) => {
            const pattern = /[A-Z]{5}[0-9]{4}[A-Z]{1}$/
            return pattern.test(val)
        },
    }

    formValidator.current.rules['gst_no'] = {
        message: 'Please enter valid GST Number.',
        rule: (val) => {
            const pattern = /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/
            return pattern.test(val)
        },
    }

    const confirmClose = () => {
        setToggleImage(false)
        setOverlay(false)
    }

    const openImageModal = (data) =>{
        setToggleImage(true)
        setOverlay(true)
    }

    const openPDF = (dataString) => {
        // context.inactive = true
        // window.location.href = dataString
        // console.log("dataString",dataString);
        // var string = dataString
        // var iframe = "<a href='" + string + "' download style='position: absolute; background: #FE6E00; color: #fff; border: 1px solid #FE6E00; right: 20%; font-family: sans-serif; padding: 5px; text-decoration: none;'>Download PDF</a><iframe width='100%' height='100%' src='" + string + "'></iframe>"
        var x = window.open(dataString);
        // x.document.open();
        // x.document.write(iframe);
        // x.document.close();
    }

    console.log("fileType, isPDF>>>>", fileType, isPDF);

    return (
        <>
        <div className={`ovelap ${overlay === true ? 'active' : ''}`}></div>
        
            {
                
                loading === true ?
                <div className="upload-image-loading" style={{display: `grid`}}>
                    <p className="button--loading">Loading....</p>
                </div>
                :
                <div className="forminpt">
                    <label className="colorBlack">{documentName || ""}</label>
                    {imageSrc ?
                        <div className="upload-display mt-10" style={{display: `${imageSrc !== "" ? 'grid' : 'none'}`}}>
                            <div className="upload-displayL">
                                <span>
                                    {
                                        console.log("why con",isPDF, (fileType && !fileType.includes("image")), isPDF || (fileType && !fileType.includes("image")))
                                    }
                                    {
                                        (!fileType.includes("image"))
                                        ?
                                        <img src={noImg} />
                                        :
                                        <img src={imageSrc} />
                                    }
                                    
                                </span>
                                <div className="file-controller">
                                    {
                                        console.log("fileType",fileType)
                                    }
                                    {
                                        (fileType.includes("image"))
                                        &&
                                        <a href="javascript:void(0)" onClick={openImageModal}>View</a>
                                        
                                    }
                                    {
                                        (fileType.includes("pdf"))
                                        &&
                                        <a href="javascript:void(0)" onClick={()=>openPDF(imageSrc)}>Download</a>
                                    }
                                    <div className="upload-img editupload end-md">
                                    <input className="btn btn-outline edit"
                                        // multiple={true}
                                        type="file"
                                        onChange={(event)=> handleUpload(event.target.files)}
                                        accept="image/*, application/pdf"
                                    />
                                    Edit
                                    </div>
                                </div>
                               
                               
                                <ImageModal 
                                    togglePopup={toggleImage}
                                    displayImage={imageSrc} 
                                    onModalClose={confirmClose}
                                />
                            </div>
                            <div className="upload-displayR">
                                <span className="uploaded-doc">Uploaded</span>
                                <div className="upload-text">
                                    <h4>Document Name</h4>
                                    <p>{documentName}</p>
                                </div>
                                <div className="upload-text">
                                    <h4>Document Number</h4>
                                            <Textfield
                                                formData={data}
                                                formKey='documentNo'
                                                onDataChange={onFormDataChange}
                                                placeholder='Document No.'
                                                isRequired={false}
                                                formValidator={formValidator}
                                                validation={`required|${validationRule ? validationRule : ''}`}
                                            />
                                </div>
                               
                            </div>
                        </div>
                    :
                    <div>
                    <div className="upload-images mt-10">
                        <span>Drag & Drop the file  from <br/> your computer</span>
                        <b>OR</b>
                        <div className="upload-img">
                            <input
                                multiple={true}
                                type="file"
                                onChange={(event)=> handleUpload(event.target.files)}
                                accept="image/*, application/pdf"
                            />
                        </div>
                        <div className="upload-img button">
                            <label>Upload</label>
                        </div>
                    </div>
                     {errorMsg && <span className="valError"><img src={errorSVG} />{"Please upload pdf or image file."}</span>}
                    </div>
                    }
                </div>
            }
            
        </>
    )
})

export default FileUploader
