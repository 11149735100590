import React, { useContext, useEffect, useState } from 'react'
import AppContextObject from '../../../../common/context/common'
import blogImg from "../../../../assets/images/blog-img.png"
import blogImg1 from "../../../../assets/images/485x250-media.jpg"
import blogImg2 from "../../../../assets/images/485x200.jpg"

interface Props {
    type?: String
}
const NewAtWisr = ({
type
}: Props) => {
    const [wisrBlogs, setWisrBlogs] = useState([])
    const [currentIndex, setCurrentIndex] = useState(0)
    const context = useContext(AppContextObject)
    const onNext = () => {
        if (currentIndex < wisrBlogs.length - 1) {
            setCurrentIndex((prev) => prev + 1)
        }
    }
    const onPrev = () => {
        if (currentIndex !== 0) {
            setCurrentIndex((prev) => prev - 1)
        }
    }
    const getWhatNewAtWisr = async () => {
        let resp = await context.apis[context.user.role].getWisrBlogs(
            context.validator, {type : type}, {}
        )
        if (resp && resp.done) {
            setWisrBlogs(resp.response.rows)
        }
    }
    useEffect(() => {
        getWhatNewAtWisr()
    }, [])
    return (
        <>
            <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12 pl">
                {/* <div className="row"> */}

                <div className="blog-listing">
                    <div className="slideshow slide-relative">
                        <div className="row">
                            <div className="col-xl-7 col-lg-7 col-md-7 col-xs-12 bloghead">
                                <h4 className='mb-0'>What's new at WISR:</h4>
                            </div>
                            {
                                wisrBlogs.length > 1 &&
                                    <div className="blog-arrow whatarrow">
                                        <a style={{ cursor: "pointer" }} onClick={() => onPrev()} className="left-arrowB"><svg width="9" height="14" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M6 11L1 6L6 1" stroke="#8C8C8C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /> </svg></a>
                                        <a style={{ cursor: "pointer" }} onClick={() => onNext()} className="left-arrowR"><svg width="9" height="14" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M1 11L6 6L1 1" stroke="#8C8C8C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /> </svg></a>
                                         {/* <a style={{ cursor: "pointer" }} className="left-arrowB"><svg width="9" height="14" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M6 11L1 6L6 1" stroke="#8C8C8C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /> </svg></a>
                                        <a style={{ cursor: "pointer" }} className="left-arrowR"><svg width="9" height="14" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M1 11L6 6L1 1" stroke="#8C8C8C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /> </svg></a> */}
                                    </div>
                            }

                        </div>
                        {
                            wisrBlogs.length != 0 ?
                                <div className=" slideshowSlider"
                                    style={{ transform: `translate3d(${-currentIndex * 100}%, 0, 0)` }}>
                                    {
                                        wisrBlogs.map((data, index) => {
                                            return (
                                                <>
                                                    <div className="slide " key={currentIndex}>
                                                        <div className="row">
                                                            <div className="col-xl-6 col-lg-6 col-md-6 col-xs-12">
                                                                <img src={blogImg} />
                                                            </div>
                                                            <div className="col-xl-6 col-lg-6 col-md-6 col-xs-12">
                                                                <div className="blog-listingText">
                                                                    <h3>{data.title}</h3>
                                                                    <p>{data.description}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        })
                                    }
                                </div>
                                :
                                
                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                    <div className="noData height-one colored">
                                    <img src={blogImg1} />
                                        {/* <h3>You're up-to-date with all WISR news.</h3> */}
                                        </div>
                                </div>
                        }
                        {/* <div className=" slideshowSlider"
                                    style={{ transform: `translate3d(${-currentIndex * 100}%, 0, 0)` }}>
            
                                    <div className="slide " key={currentIndex}>
                                        <div className={`row`}>
                                            <div className="col-xl-6 col-lg-6 col-md-6 col-xs-12">
                                                <img src={blogImg} />
                                            </div>
                                            <div className="col-xl-6 col-lg-6 col-md-6 col-xs-12">
                                                <div className="blog-listingText">
                                                    <h3>Nike partners with 700 schools across India.</h3>
                                                    <p>Nike's latest product campaigns partnered with 700 schools across India reaching up to 4 Lakh students. The brand has booked sports & games rooms, canteens menues and water coolers.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                
                        </div> */}
                    </div>


                </div>
            </div>


        </>
    )
}

export default NewAtWisr
