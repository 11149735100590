import {
    useEffect,
    useState,
    useContext
} from "react"

import { Link, useHistory } from "react-router-dom"

import AppContextObject from "../../common/context/common"

import arrowView from "../../assets/images/arrow-view.svg"
import campcreate from "../../assets/images/img2.png"
import TwoMore from "./twoMore"
import { revenueFormatter } from "../../service/helper"

const InventoryBookingGraph = (props) => {
    const {
        refetch
        // inventoryBookings
    } = props

    const history = useHistory()
    const context = useContext(AppContextObject)

    const [selectedMonthIndex, setSelectedMonthIndex] = useState(0)
    const [showModalTwoMore, setShowModalTwoMore] = useState(false)
    const [overlay, setOverlay] = useState(false)

    useEffect(() => {
        getInventoryBookings()
    }, [refetch])
    useEffect(() => {
        let currentMonth = new Date().getMonth();
        setSelectedMonthIndex(currentMonth);

    },[])
    const openModalTwoMore = () => {
        setShowModalTwoMore(true)
        setOverlay(true)
    }

    const closeWithoutSaving = () => {
        setShowModalTwoMore(false)
        setOverlay(false)
    }

    window.addEventListener('click', (event) => {
        // const target = event.target as Element
        const target:any = event.target
        const moreInventoryPopUp = target.closest('#inventoryMore');
        if(!moreInventoryPopUp && target.id !== "openModal"){
            setShowModalTwoMore(false)
            setOverlay(false)
        }
    })
    const [inventoryBookings, setInventoryBookings] = useState([{
        month: "",
        estimatedRevenue: "",
        bookedCount: "-",
        idleCount: "-",
        brandPartners: [{name: '-', logo: ''}],
        status: 0,
    }])

    let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const getInventoryBookings = async () => {
        setInventoryBookings(null)
        let resp = await context.apis[context.user.role].mySchoolBookingStats(
            context.validator,
        )
        if (resp && resp.done) {
            let bookings = []
            resp.data.map((data) => {

                let brandPartnersAll = []
                data.brands.map(a => {
                    brandPartnersAll.push({name: a.brandName, logo: a.logo})
                })
                let totalInventories = data.inventories.length + data.inventoriesNotBooked.length
                let a = {
                    month: months[data.month.slice(0, 2) - 1],
                    estimatedRevenue: data.netRevenue,
                    bookedCount: totalInventories == 0 ? "-" : data.inventories.length,
                    idleCount: totalInventories == 0 ? "-" : data.inventoriesNotBooked.length,
                    brandPartners: brandPartnersAll.length == 0 ? ["-"] : brandPartnersAll,
                    status: totalInventories == 0 ? 0 : Math.round((data.inventories.length / totalInventories ) * 100)
                }
                bookings.push(a)
            })
            setInventoryBookings(bookings);
        }
        else {
            let bookings = []
            months.forEach(element => {
                let a = {
                    month: element,
                    estimatedRevenue: "-",
                    bookedCount: "-",
                    idleCount: "-",
                    brandPartners: ["-"] ,
                    status:"0"
                }
                bookings.push(a)
            });
            setInventoryBookings(bookings);
        }
    }

    return (
        <>
            <div className={`ovelap ${overlay === true ? "active" : ""}`}></div>
            <div id="inventoryBookingStatus" className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-40"></div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-xs-12">
                    <h2 className="htx2 clearfix">Inventory: Booking Status</h2>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-xs-12">
                    <Link to="/schools/school-details/inventory" className="viewwLink">View Full Inventory List <img className="arrowView" src={arrowView} /></Link>
                </div>
                {
                    inventoryBookings && inventoryBookings.length > 0 &&
                    <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                    <div className={"bookingStatus"}>
                        {
                            inventoryBookings && inventoryBookings.length > 0 &&
                            inventoryBookings.map((el, index) => {
                                return (
                                    <div className={`booking1 ${index === selectedMonthIndex ? "active" : ""}`} onClick={() => setSelectedMonthIndex(index)}>
                                        <div className="bookingTop">{el.status + "%"}</div>
                                        <div className="bookingMid">
                                            <div className="bookingOver" style={{ height: el.status }}></div>
                                        </div>
                                        <div className="bookingBot">{el.month}</div>
                                    </div>
                                )
                            })
                        }
                        <div className={`booking-full ${inventoryBookings[selectedMonthIndex].month} ${context.user.role}`}>
                            {
                                context.user && context.user.role === "schoolPrincipal" &&
                                <>
                                    <div className="booking-full1">
                                        <b>Estimated Revenue</b>
                                        <h2>{revenueFormatter(inventoryBookings[selectedMonthIndex].estimatedRevenue)}</h2>
                                    </div>
                                    <div className="booking-border"></div>
                                </>
                            }
                            <div className="booking-full1">
                                <b>Brand Partners for the month</b>
                                <h2>{
                                    inventoryBookings[selectedMonthIndex].brandPartners.length == 0 ? "-" :
                                    inventoryBookings[selectedMonthIndex].brandPartners.slice(0, 3).map(function(elem){
                                        return elem.name;
                                    }).join(" , ")}
                                    {(inventoryBookings[selectedMonthIndex].brandPartners.length > 3) ?
                                        <a id="openModal" href='javascript:void(0)' onClick={openModalTwoMore} className=''
                                            data-target='inventoryMore'> {inventoryBookings[selectedMonthIndex].brandPartners.length - 3} more</a> : ""
                                    }
                                </h2>
                            </div>
                            <div className="booking-border"></div>
                            <div className="booking-full1">
                                <b>Booked Inventory Blocks</b>
                                <h2>{inventoryBookings[selectedMonthIndex].bookedCount}</h2>
                            </div>
                            <div className="booking-border"></div>
                            <div className="booking-full1">
                                <b>Remaining Inventory Blocks</b>
                                <h2>{inventoryBookings[selectedMonthIndex].idleCount}</h2>
                            </div>
                        </div>

                    </div>
                </div>
                }

            </div>
            {
                inventoryBookings && inventoryBookings.length > 0 &&  
                <TwoMore
                showModalTwoMore={showModalTwoMore}
                brandPartners={inventoryBookings[selectedMonthIndex].brandPartners}
                closeWithoutSaving={closeWithoutSaving}
            />
            }

        </>
    )
}

export default InventoryBookingGraph
