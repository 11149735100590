import React, { useEffect } from 'react'
import copySvg from "../../../../../assets/images/copySvg.svg"
import LogSvg from "../../../../../assets/images/wisrSvg.svg"

const Confirmation = ({setShowProgressSideMenu}) => {
    useEffect(() => {
        setShowProgressSideMenu(true)
    }, [])
    return (
        <>
        <div className={`ovelap`}></div>
        {/* <TopBar isDashboard={true} /> */}
        <div className="col-xl-9 col-lg-9 col-md-12 col-xs-12">
        <div className="rightPart noBack">
            <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-30">
                <div className="confirmation-box">
                    <img className="con-img" src={LogSvg} />
                    <h2 className="htx1 clearfix mb-10">Congratulations!</h2>
                    <p className="ptx1">Your campaign has been successfully created.</p><p className="ptx1"> Our team will review the campaign details and creatives and send you an approval email once completed.</p>
                    <p className="ptx1">You can access the campaign details under the Campaigns tab on our platform.</p>
                    <p className="ptx1">Your wisr representative: <b>mohit@wisr.com</b> <img className="copy-img" src={copySvg} /></p>
                    <div className="personal-box">
                        <div className="row personal-box-top">
                            <div className="row col-xl-8 col-lg-8 col-md-8 col-xs-8 start-md">
                            </div>
                            <div className="row col-xl-4 col-lg-4 col-md-4 col-xs-4 end-xs">
                                <a href="javascript:void(0)" className="editbtn"><img src="/static/media/edit-btn.c928a066.svg" /> Edit</a>
                            </div>
                        </div>
                        <div className="personal-bottom">
                            <div className="row personal-box-bot">
                                <div className="col-xl-5 col-lg-5 col-md-6 col-xs-12">
                                    <p>Name</p>
                                </div>
                                <div className="col-xl-7 col-lg-7 col-md-6 col-xs-12">
                                    <b>Nike Product Launch</b>
                                </div>
                                <div className="col-xl-5 col-lg-5 col-md-6 col-xs-12">
                                    <p>Start Date</p>
                                </div>
                                <div className="col-xl-7 col-lg-7 col-md-6 col-xs-12">
                                    <b>21 Feb’21</b>
                                </div>
                                <div className="col-xl-5 col-lg-5 col-md-6 col-xs-12">
                                    <p>End Date</p>
                                </div>
                                <div className="col-xl-7 col-lg-7 col-md-6 col-xs-12">
                                    <b>28 Feb’21</b>
                                </div>
                                <div className="col-xl-5 col-lg-5 col-md-6 col-xs-12">
                                    <p>Budget</p>
                                </div>
                                <div className="col-xl-7 col-lg-7 col-md-6 col-xs-12">
                                    <b>₹ 120,000 <em>Rupees One Lakh Twenty Thousand</em></b>
                                </div>
                                <div className="col-xl-5 col-lg-5 col-md-6 col-xs-12">
                                    <p>No of Schools Occupied</p>
                                </div>
                                <div className="col-xl-7 col-lg-7 col-md-6 col-xs-12">
                                    <b>48</b>
                                </div>
                                <div className="col-xl-5 col-lg-5 col-md-6 col-xs-12">
                                    <p>Total Estimated Reach</p>
                                </div>
                                <div className="col-xl-7 col-lg-7 col-md-6 col-xs-12">
                                    <b>1,000,000 Students</b>
                                </div>
                            </div>
                        </div>
                    </div>
                    <input name="CLOSE" className="btn btn-orange" type="button" value="CLOSE" />
                </div>
            </div>
            
            </div>
        </div>
        </div>
    </>
    )
}

export default Confirmation
