import React, { useEffect, useState, useContext, useRef } from 'react'
import AppContextObject from '../../../../common/context/common';
import TextField from '../../../../components/formFields/textField/textField';
import SimpleReactValidator from "simple-react-validator"
import errorSVG from "../../../../assets/images/error.svg"
import moment from 'moment';
import { compareObjs, objectDeepClone, validateFullName, hasOnlyLettersAndSpaces, titleCase } from '../../../../service/helper';
import SingleSelect from '../../../../components/formFields/singleSelect/singleSelect';
import config from "../../../../service/config"
import TextFieldWithPrefix from '../../../../components/formFields/textFieldWithPrefix/textFieldWithPrefix';
import ConfirmDialog from '../../../../components/addon/confirmDialog/confirmDialog';
import CreatableSelect from '../../../../components/formFields/creatableSelect/creatableSelect';
import editSvg from "../../../../assets/images/edit-btn.svg"
import Select from "react-select"

interface Props {
    formData?: any,
    setFormData?: any,
    selectedUserIndex?: any,
    onFormDataChange?: any,
    onDesignationChange?: any,
    allRoles?: any,
    formOldData?: any,
    isCMOVerfied?: any,
    setIsGeneralEdit?: any,
    setIsContactEdit?: any,
    setAllRoles?: any,
    isLoading?: any,
    setIsLoading?: any,
    haveCMO?: any,
    isGeneralEdit?: any,
    setRefetch?: any,
    refetch?: any
}

const EditUserGeneral = ({
    formData,
    setFormData,
    selectedUserIndex,
    onFormDataChange,
    onDesignationChange,
    allRoles,
    formOldData,
    isCMOVerfied,
    setIsGeneralEdit,
    setIsContactEdit,
    setAllRoles,
    isLoading,
    setIsLoading,
    haveCMO,
    isGeneralEdit,
    setRefetch,
    refetch
}: Props) => {
    const context = useContext(AppContextObject)
    const [update, forceUpdate] = useState(false)
    const [otherDesignation, setOtherDesignation] = useState({name: ""})
    const [formValidator] = useState(useRef(new SimpleReactValidator({
        element: (message) =>
            <span className="valError">
                <img src={errorSVG} />
                {
                    message === "The phone field is required."
                        ? "The mobile no field is required."
                        :
                        message === "The phone must be a valid phone number."
                        ?
                        "The mobile no must be a valid mobile no."
                        :
                        message === "The role field is required."
                        ?
                        "The designation field is required."
                        :
                        message
                }
            </span>
    })))
    useEffect(() => {
        if(formData && formData[selectedUserIndex] && formData[selectedUserIndex]["designation"] !== "CMO" && formData[selectedUserIndex]["designation"] !== "Brand Manager"){
            setOtherDesignation({name: formData[selectedUserIndex]["designation"]})
        }
    }, [selectedUserIndex, isGeneralEdit])
    const useForceUpdate = () => {
        const [value, setValue] = useState(0);
        return () => setValue(value => value + 1);
    }
    const forceUpdate1 = useForceUpdate();
    // useEffect(() => {
    //     if(formValidator.current){
    //         console.log("formValidator.current", formValidator.current)
    //     }
    // }, [formValidator])
    const onCancelGeneralEdit = () => {
        // formValidator.current.messagesShown = false
        formValidator.current.hideMessages()
        setIsGeneralEdit(false)
        setIsLoading(false)
        setFormData(formOldData)
    }
    const onOpenGeneralEdit = () => {
        console.log("formValidator.current", formValidator.current)
        // formValidator.current.purgeFields()
        // formValidator.current.hideMessages()
        setIsGeneralEdit(true)
        setIsContactEdit(false)
        if(formData[selectedUserIndex].role === "CMO" || haveCMO === false){
            let roles = config.designationOptions;
            if(formData[selectedUserIndex].designation !== "CMO" && formData[selectedUserIndex].designation !== "Brand Manager"){
                roles.push({label: formData[selectedUserIndex].designation, value: formData[selectedUserIndex].designation})
            }
            setAllRoles([...roles])
        }else{
            if(formData[selectedUserIndex].designation !== "CMO" && formData[selectedUserIndex].designation !== "Brand Manager"){
                // roles.push({label: formData[selectedUserIndex].designation, value: formData[selectedUserIndex].designation})
                setAllRoles([
                    {
                        label: 'Brand Manager',
                        value: 'Brand Manager'
                    },
                    {
                        label: 'Other',
                        value: 'other'
                    },
                    {
                        label: formData[selectedUserIndex].designation,
                        value: formData[selectedUserIndex].designation
                    }
                ])
            }
            else{
                setAllRoles([
                    {
                        label: 'Brand Manager',
                        value: 'Brand Manager'
                    },
                    {
                        label: 'Other',
                        value: 'other'
                    }
                ])
            }
        }
    }
    const updateGeneralDetails = async () => {
        setIsLoading(true)
        const result = formValidator.current.allValid()
        console.log("result", result)
        if (result === false) {
            formValidator.current.showMessages()
            forceUpdate1()
            setIsLoading(false)
            return
        } else {
            formValidator.current.hideMessages()
            forceUpdate1()
        }
        // console.log("updatingData", formData[selectedUserIndex].designation !== otherDesignation.name)
        if(!compareObjs(formData[selectedUserIndex], formOldData[selectedUserIndex]) || formData[selectedUserIndex].designation !== otherDesignation.name){
            let updatingData = {}
            if(formData[selectedUserIndex].fullName !== formOldData[selectedUserIndex].fullName){
                updatingData['fullName'] = formData[selectedUserIndex].fullName
            }
            if(formData[selectedUserIndex].email !== formOldData[selectedUserIndex].email){
                updatingData['email'] = formData[selectedUserIndex].email
            }
            if(formData[selectedUserIndex].role !== formOldData[selectedUserIndex].role){
                updatingData['role'] = formData[selectedUserIndex].role === "CMO" ? "brandCMO" : formData[selectedUserIndex].role === "Brand Manager" ? "brandManager" : formData[selectedUserIndex].role
            }
            if(formData[selectedUserIndex].designation !== otherDesignation.name){
                updatingData['designation'] = otherDesignation.name.trim()
            }
            console.log("updatingData", updatingData, formData[selectedUserIndex].designation !== otherDesignation.name)
            if(formData[selectedUserIndex]["_id"]){
                let updateUserResp = await context.apis[context.user.role].updateBrandUser(
                    context.validator, {_id: formData[selectedUserIndex]["_id"]}, updatingData
                )
                if(updateUserResp && updateUserResp.done){
                    setIsGeneralEdit(false)
                    setRefetch(!refetch)
                    setIsLoading(false)
                }else{
                    setIsLoading(false)
                }
            }
        } else {
            setIsGeneralEdit(false)
            setIsLoading(false)
        }
    }
    formValidator.current.rules['full_name'] = {
        message: 'Please enter valid full name.',
        rule: (val) => {
            let returnValue = validateFullName(val)

            return returnValue
        },
    }
    formValidator.current.rules['valid_other_designation'] = {
        message: 'Please enter valid designation.',
        rule: (val) => {
            let returnValue = !(val.toLowerCase() === "brand manager" || val.toLowerCase() === "cmo")
            return returnValue
        }
    }
    const customStyles = {
        option: (provided, state) => ({
          ...provided,
          fontSize:'14px',
          padding: '13px 12px',
          backgroundColor: state.isSelected ? "#ECECEC" : state.isFocused ? '#F8F8F8' : '#fff',
          color: "#000",
          wordWrap: 'break-word' , 
          whiteSpace: 'normal',
          textOverflow: 'ellipsis',

        }),
    }
    const onOtherDesignationChange = (e) => {
        console.log("first", e.target.value)
        setOtherDesignation({name: e.target.value})
        // let selectedData = objectDeepClone(formData)
        // selectedData[selectedUserIndex]["designation"] = e.target.value
        // setFormData([...selectedData])
    }
  return (
    <>
        {isGeneralEdit && formData && formData.length > 0 ?
            <>
                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-20 mt-10">
                    <h2 className="htx3 clearfix">General</h2>
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 forminpt-bottom">
                    <TextField
                        formData={formData[selectedUserIndex]}
                        formKey='fullName'
                        onDataChange={onFormDataChange}
                        label='Name'
                        placeholder='Member Name'
                        isRequired={false}
                        formValidator={formValidator}
                        validation='required|full_name'
                        maxLength={50}
                    />
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 forminpt-bottom">
                    <TextField
                        formData={formData[selectedUserIndex]}
                        formKey='email'
                        onDataChange={onFormDataChange}
                        label='Email'
                        placeholder='Member Email'
                        isRequired={false}
                        formValidator={formValidator}
                        validation='required|email'
                    />
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 forminpt-bottom">
                    {formData[selectedUserIndex].designation !== "CMO" && formData[selectedUserIndex].designation !== "Brand Manager" ?
                    <>
                        <div className="forminpt">
                            <label>
                                Designation &nbsp;
                            </label>
                            <Select
                                classNamePrefix="wisr-react-select"
                                styles={customStyles}
                                options={[{label: "Other", value: "other"}]}
                                onChange={() => {}}
                                placeholder="Designation"
                                value={[{label: "Other", value: "other"}] && [{label: "Other", value: "other"}].length > 0 && [{label: "Other", value: "other"}].filter((option) => {
                                    return  option.value === "other"
                                })}
                                isDisabled={true}
                            />
                        </div>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                            <TextField
                                formData={otherDesignation}
                                formKey='name'
                                onDataChange={onOtherDesignationChange}
                                label='Other'
                                placeholder='Designation'
                                isRequired={false}
                                formValidator={formValidator}
                                validation='required|alpha_space|valid_other_designation'
                                maxLength={50}
                            />
                        </div>
                    </>
                    :
                    <SingleSelect
                        formData={formData[selectedUserIndex]}
                        formKey='designation'
                        onDataChange={onDesignationChange}
                        label='Designation'
                        placeholder='Designation'
                        isRequired={false}
                        options={allRoles}
                        formValidator={formValidator}
                        validation='required'
                        isDisable={true}
                    />
                    }
                </div>
                {/* {
                    formData[selectedUserIndex].role !== "CMO"
                    &&
                    <>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                            <CreatableSelect
                                formData={newUserData}
                                formKey='subCategoryName'
                                onDataChange={onSubCategoryChange}
                                onCreate={addNewOption}
                                label='Sub Category'
                                placeholder='Sub Category'
                                isRequired={false}
                                options={subCategories}
                                formValidator={formValidator}
                                validation={"required"}
                                emptyOptionsMessage="Create a Sub Category"
                            />
                        </div>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                            <TextField
                                formData={newUserData}
                                formKey='brandName'
                                onDataChange={onNewUserDataChange}
                                label='Brand Name'
                                placeholder='Brand Name'
                                isRequired={false}
                                formValidator={formValidator}
                                validation='required'
                            />
                        </div>
                    </>
                } */}
                
                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 end-md">
                    <a href="javascript:void(0);" onClick={()=> onCancelGeneralEdit()} className="btn btn-outline" >Cancel</a>
                    <a href="javascript:void(0);" onClick={()=> updateGeneralDetails()} className={isLoading ? "btn btn-orange button--loading btn-disabled" : "btn btn-orange"} >Save Changes</a>
                </div>
            </>
            :
            <>
                {
                    formOldData && formOldData.length > 0 && selectedUserIndex !== null
                    &&
                    <>
                    <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-10">
                        <h2 className="htx3 clearfix">General 
                        {
                            context.user.role === "brandCMO" 
                            ||
                            formOldData[selectedUserIndex]._id === context.user._id
                            ||
                            (formOldData[selectedUserIndex].role === "CMO" && isCMOVerfied === false)
                            ?
                            <a href="javascript:void(0);" onClick={()=> onOpenGeneralEdit()} className="editbtn">
                            <img src={editSvg} /> Edit</a>
                            :
                            ""
                        }
                        </h2>
                    </div>
                    <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 pl pr">
                        <div className="row generalview">
                            <div className="col-xl-4 col-lg-4 col-md-12 col-xs-12 mt-20">
                                <div className="inventory-overHead">
                                    <p>Name</p>
                                    <h3>{formOldData[selectedUserIndex].fullName}</h3>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-12 col-xs-12 mt-20">
                                <div className="inventory-overHead">
                                    <p>Member Joining Date</p>
                                    <h3>{moment(formOldData[selectedUserIndex].createdOn).format("Do MMMM YYYY")}</h3>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-12 col-xs-12 mt-20">
                                <div className="inventory-overHead">
                                    <p>Email ID</p>
                                    <h3>{formOldData[selectedUserIndex].email}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    </>
                }
                
            </>
        }
    </>
  )
}

export default EditUserGeneral