import {
    useEffect,
    useState,
    forwardRef,
    useImperativeHandle
} from 'react'

import { getPlaceFromAddressComponents } from "../../service/helper"

declare var google

interface Props {
    isScriptLoaded? : boolean
    isScriptLoadSucceed? : boolean
    formData? : any,
    setFormData? : any,
    onMapLoaded?: Function
    setIsMapLoading? : any
}

const GMaps = forwardRef(({
    isScriptLoaded,
    isScriptLoadSucceed,
    formData,
    setFormData,
    onMapLoaded,
    setIsMapLoading
}: Props, ref) => {

    const [mapComponent, setMapComponent] = useState(null)
    const [mapMarker, setMapMarker] = useState(null)
    // const [isMapLoading, setIsMapLoading] = useState(false)
    const INDIA_BOUNDS = {
        south: 6.8,
        north: 37.6,
        west: 68.7,
        east: 97.25,
    }

    let marker = null
    // let geocoder = null

    useEffect(() => {
        if (typeof document !== "undefined" && document.getElementById("map")) {
            if (!mapComponent) {
                initializeMap()
            }
        }
        google.maps.event.addListener(marker, 'dragend', () => {
            geocodePosition(marker.getPosition())
        })
        onMapLoaded()
    }, [])

    const initializeMap = () => {
        return new Promise((resolve, reject) => {
            const map = new google.maps.Map(document.getElementById("map") as HTMLElement, {
                center: { lat: 19.0760, lng: 72.8777 },
                restriction: {
                    latLngBounds: INDIA_BOUNDS,
                    strictBounds: true,
                },
                zoom: 15,
                mapTypeControl: true,
                mapTypeControlOptions: {
                    style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                    position: google.maps.ControlPosition.TOP_CENTER,
                },
                zoomControl: true,
                zoomControlOptions: {
                    position: google.maps.ControlPosition.LEFT_CENTER,
                },
                scaleControl: true,
                streetViewControl: true,
                streetViewControlOptions: {
                    position: google.maps.ControlPosition.LEFT_TOP,
                },
                fullscreenControl: true,
            })
            marker = new google.maps.Marker({
                position: { lat: 19.0760, lng: 72.8777 },
                map,
                draggable: true,
                })
                setMapMarker(marker)
            setMapComponent(map)
            resolve(map)
        })
    }
    const geocodePosition = (position) => {
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({ latLng: position }, (results, status) => {
            if (status == google.maps.GeocoderStatus.OK) {
                console.log(results[0])
            } else {
                console.log("Address not present")
            }
        })
    }

    const setMarker = (address) => {
        console.log("address", address)
        const geocoder = new google.maps.Geocoder()
        setIsMapLoading(true)
        geocoder.geocode( { 'address': address, componentRestrictions: {country: 'IN'}}, async function(results, status) {
            if (status == 'OK') {
                if (mapMarker && mapMarker.setMap) {
                    mapMarker.setMap(null)
                }
                let thisMap = mapComponent
                if (!thisMap) {
                    thisMap = await initializeMap().catch(err => console.log(err))
                }
                thisMap.setCenter(results[0].geometry.location)
                marker = new google.maps.Marker({
                    map: thisMap,
                    position: results[0].geometry.location,
                    draggable: true,
                })
                google.maps.event.addListener(marker, 'dragend', () => {
                    geocodePosition(marker.getPosition())
                })
                if (results && results.length > 0) {
                    console.log("results", results)
                    const area = getPlaceFromAddressComponents(results[0].address_components)
                    // if(formData && setFormData){
                    //     setFormData({
                    //         ...formData,
                    //         localArea: area
                    //     })
                    // }
                }
                setMapMarker(marker)
                setIsMapLoading(false)
            } else {
                console.log('Geocode was not successful for the following reason: ' + status);
                setIsMapLoading(false)
            }
        })
    }

    useImperativeHandle(ref, () => ({
        setMarkerFromPincode(pincode) {
            setMarker(pincode)
        },

        setMarkerFromAddress(adress, pincode) {
            const addr = adress + (pincode ? ` ${pincode}, India`: ', India') 
            setMarker(addr)
        },
    }))

    // useEffect(() => {
    //     if (formData.pincode && formData.pincode.length === 6) {
    //         setMarker(formData.pincode)
    //     }
    //     if (formData.address && formData.address.trim() !== "") {
    //         setMarker(formData.address)
    //     }
    // }, [formData.pincode, formData.address])

    return (
        <>
            {
                // isMapLoading ?
                //     <div className="upload-image-loading" style={{ background: "#e20808", width: "100%", height: "300px", marginBottom: "40px", display: 'grid' }}>
                //         <div style={{ background: "#e20808", width: "100%", height: "300px", marginBottom: "40px" }}>
                //             <div className="button--loading">Loading....</div>
                //         </div>
                //     </div>
                //     :
                //     <div id="map" style={{ width: "100%", height: "300px", marginBottom: "40px" }}>
                //     </div>
                    
                    <div id="map" style={{ width: "100%", height: "300px", marginBottom: "40px" }}></div>
            }

        </>
    )
})

export default GMaps
