import React from "react";
import { useState, useContext, useEffect, useRef } from "react"
import { DebounceInput } from 'react-debounce-input'
import Pagination from "../addon/pagination"

interface Props {
    showSchoolModal?: any,
    schoolList?: any,
    closeSchoolModal?: any,
    totalPage?: any,
    onNextPage?: any,
    onPrevPage?: any,
    onPageChange?: any,
    isActivityStatus?: any,
    schoolListTitle?: any
}


const SchoolList = ({ showSchoolModal, closeSchoolModal, onNextPage, onPrevPage, onPageChange, schoolList, totalPage, isActivityStatus, schoolListTitle }: Props) => {
    const [currentPage, setCurrentpage] = useState(1)

    const onPageClick = (page) => {
        setCurrentpage(page)
        onPageChange(page)
    }
    const onNextClick = () => {
        setCurrentpage(prev => prev + 1)
        onNextPage(currentPage + 1)
    }
    const onPrevClick = () => {
        setCurrentpage(prev => prev - 1)
        onPrevPage(currentPage - 1)
    }
    const closePopup = () => {
        closeSchoolModal()
    }
    console.log("schoolList-----", schoolList)
    return (
        <>
        <div className={`ovelap ${showSchoolModal === true ? "active" : ""}`}></div>
            <div id="inventoryMore" className={`popup ${showSchoolModal === false ? "hide" : ""}`}>
                <div className="popup-header">
                    <h3 className="htx2 pophead">{isActivityStatus ? schoolListTitle : 'Schools Selected'}</h3>
                    <span className="close toggle" onClick={closePopup} data-target="myPopup">close</span>
                </div>
                <div className="popup-body">
                    <div className="viewdetailsHead">
                        <div className="invetorypopblock-school">
                            <div className="rightPart noBack">
                                <div className="row schools-list-popup">
                                    <div className="col-xl-9 col-lg-9 col-md-12 col-xs-12">
                                        <div className="row">
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-20 pl pr">
                                                <div className="table-container mt-10 addAdmininput fillterTableHeight">
                                                    <table className="table going-campaign">
                                                        <thead>
                                                            <tr>
                                                                <th>SL No</th>
                                                                <th>School Name</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            <>
                                                                {schoolList && schoolList.length > 0 ? schoolList.map((school, key) => {
                                                                    return (
                                                                        <tr key={key}>
                                                                            <td>{(key + 1) + (currentPage - 1) * 10}</td>
                                                                            <td>
                                                                                <div className="schoolNameTable">
                                                                                    <h4>{school.schoolName}</h4>
                                                                                    {/* <span>{school?.board}</span>
                                                                                    <span>{school.city}</span>
                                                                                    <span>{school.state}</span> */}
                                                                                </div>
                                                                            </td>

                                                                        </tr>
                                                                    )
                                                                }): <tr><td colSpan={2}>No school found.</td></tr>}
                                                            </>

                                                        </tbody>
                                                    </table>
                                                </div>
                                                {totalPage.length > 1 &&
                                                    <Pagination
                                                        currentPage={currentPage}
                                                        totalPage={totalPage}
                                                        onPageChange={onPageClick}
                                                        onNextClick={onNextClick}
                                                        onPrevClick={onPrevClick}
                                                        loading={false}
                                                    />
                                                }
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}
export default SchoolList;