import React, { useState, useEffect } from 'react'

// import ImageModal from '../../../components/addon/imageModal/imageModal'
// import ImagePreview from '../../../components/addon/imagePreview'
// import exmapleImage from "../../../assets/images/"

const CampaignReportingRetakeImg = () => {
    const [showImage, setShowImage] = useState(true)
    const [showImagePreview, setShowImagePreview] = useState(false)
    const [selectedImageIndex, setSelectedImageIndex] = useState(0)
    const [images] = useState([{ image: 'https://wisr-json.s3.ap-south-1.amazonaws.com/wisr-inventoryImages/Classroom+Boards.jpg' }, { image: 'https://wisr-json.s3.ap-south-1.amazonaws.com/wisr-inventoryImages/Classroom+Boards.jpg' }])

    const onModalClose = () => {
        setShowImage(false)
        setShowImagePreview(true)
    }
    return (
        <>
            <div className="" style={{ width: "100%" }}>
                <div className="logo-header header-flex-container">

                    <img className="back-arrow" src="/assets/FOSimg/back-arrow.png" />
                    <img className="wisrLogo" src="/assets/FOSimg/wisr-logo.png" />


                    <img className="maskgrouplogo" src="/assets/FOSimg/maskgrouplogo.png" />

                </div>

                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12" style={{ padding: '0' }}>
                    <div id="closePopup" className="" style={{ padding: "15px", background: "#000000", width: "100% !important", borderRadius: "0" }}>


                        <div className="flex-container1" style={{ display: "flex", justifyContent: "space-between" }}>
                            <h4 style={{ color: "#ffffff", fontFamily: "nexaxbold", fontSize: "20px" }}> Hallway 1 </h4>
                            <img style={{ height: "20px", filter: "brightness(100%)", marginLeft: "auto", paddingRight: "5px" }} src="/assets/FOSimg/close.png" alt="Close" />
                            <img style={{ height: "20px", filter: "brightness(100%)" }} src="/assets/FOSimg/rejected.png" alt="Rejected" />
                        </div>



                        <div className="popup-header">


                        </div>
                        <div className="popup-body">

                            <div className="clearfix">

                                <div className="">
                                    <div className="">

                                        <img src="https://wisr-json.s3.ap-south-1.amazonaws.com/wisr-inventoryImages/Classroom+Boards.jpg" className="upload-campaignImg" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12" >
                        {/* <textarea placeholder="Describe" id="message" style={{ width: "100%", height: "100px" }}> Text Area </textarea> */}

                        <div className="cl-row">
                            {/* <div className="col-xs-6 col-sm-6 col-md-12 col-lg-12" style={{ paddingRight: "4px" }}>
                                <div className="forminpt">
                                    <input name="Finish" style={{ fontSize: "16px", fontFamily: "nexaxbold" }} className="btn btn-orange comp-seting" type="button" value="Save" />
                                </div>
                            </div> */}

                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">

                                <div className="ws-container">
                                    <div className="commentRight" style={{ backgroundColor: "#F8F8F8", marginLeft: "auto", paddingLeft: "10px", paddingTop: "10px", width: "300px", height: "80px", borderRadius: "5px" }}>
                                        <div style={{ display: "flex" }}>
                                            <div style={{ width: "95%" }}>
                                                <div style={{ width: "80%", display: "flex" }}>
                                                    <h1 style={{ fontSize: "18px", fontFamily: "nexaxbold", color: "#070707", paddingRight: "5px" }}> FOS-Name  </h1>
                                                    <span style={{ fontSize: "12px", paddingTop: "5px", color: "#8C8C8C" }}>2 hrs ago</span>
                                                </div>

                                                
                                            </div>
                                            <div style={{ width: "5%", marginRight:"10px" }}>
                                                <img style={{ height: "18px", filter: "brightness(100%)"  }} src="/assets/FOSimg/delete.png" alt="Delete" />
                                            </div>
                                        </div>
                                        <p style={{ paddingRight: "25px"}}>
                                            Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                                        </p>
                                    </div>

                                    <div className="commentLeft" style={{ backgroundColor: "#F8F8F8", width: "300px", height: "80px", marginTop: "19px", marginBottom: "19px", paddingLeft: "10px", paddingTop: "10px", borderRadius: "5px" }}>
                                    <div style={{ display: "flex" }}>
                                            <div style={{ width: "95%" }}>
                                                <div style={{ width: "80%", display: "flex" }}>
                                                    <h1 style={{ fontSize: "18px", fontFamily: "nexaxbold", color: "#070707", paddingRight: "5px" }}> Ops-Admin  </h1>
                                                    <span style={{ fontSize: "12px", paddingTop: "5px", color: "#8C8C8C" }}>2 hrs ago</span>
                                                </div>

                                                
                                            </div>
                                            {/* <div style={{ width: "5%", marginRight:"10px" }}>
                                                <img style={{ height: "18px", filter: "brightness(100%)"  }} src="/assets/FOSimg/delete.png" alt="Delete" />
                                            </div> */}
                                        </div>

                                        <p style={{ paddingRight: "25px"}}>
                                            Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                                        </p>
                                        
                                        {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor */}
                                    </div>
                                    <input name="retakeImage" placeholder="Add Comment" className="" type="text" />


                                    <div className="forminpt">

                                        <div className="image uploadImgDesign" style={{ width: '100%', marginTop: "6px" }}>
                                            <label className="uploadImgPlus retake" style={{ backgroundColor: "#FE6E02", color: "#FFFFFF" }}>RETAKE IMAGE</label>
                                            <input name="retakeImageButton" capture="environment" type="file" accept="image/*" title="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                </div>

            </div>

        </>
    )
}

export default CampaignReportingRetakeImg