
const ThankyouModal = (props) => {

    const {
        showThankyouModal,
        closeModal,
        modalTitle,
        description
    } = props

    return (
        <>
            <div id="thankyouModal" className={`popup ${showThankyouModal === false ? "hide" : ""}`}>
                <div className="popup-header">
                    <h3 className="htx2 pophead">{modalTitle}</h3>
                    <span className="close toggle" onClick={closeModal} data-target="myPopup">close</span>
                </div>
                <div className="popup-body">
                    <div className="viewdetailsHead">
                        <div className="approveActive">
                            <p>{description}</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ThankyouModal
