import { useEffect, useState } from "react";

import teamLeftarrow from "../../../assets/images/landing/teamLeftarrow.svg"
import teamRightarrow from "../../../assets/images/landing/teamRightarrow.svg"
import ourTeam1 from "../../../assets/images/landing/our-team-1.jpg"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const OurTeamScroll = (props) => {
    const {
        ourTeamData
    } = props


    const [lastIndex, setLastIndex] = useState(100)

    const [noOfSlides, setNoOfSlides] = useState([])
    const [currentIndex, setCurrentIndex] = useState(0);
    const [ourTeamPopUp, setOurTeamPopUp] = useState(false)
    const [selectedTeamMember, setSelectedTeamMember] = useState<any>({})
    const [overlay, setoverlay] = useState(false)
    const onNext = () => {
        // console.log("current Index..noofslides...", currentIndex, noOfSlides)
        if (currentIndex < noOfSlides.length - 1) {
            setCurrentIndex((prev) => prev + 1)
        }


    }
    const onPrev = () => {
        if (currentIndex !== 0) {
            setCurrentIndex((prev) => prev - 1)
        }
    }
    function slideCount() {
        let a = 0;
        if (ourTeamData.length % 3 == 0) {
            a = ourTeamData.length / 3
        }
        else {
            a = Math.floor(ourTeamData.length / 3) + 1
        }
        let temp = []
        for (var i = 0; i < a; i++) {
            temp.push(i)
        }
        setNoOfSlides(temp)
    }
    useEffect(() => {
        slideCount()
    }, [])

    const [startIndex, setStartIndex] = useState(0)
    const [endIndex, setEndIndex] = useState(0)

    useEffect(() => {
        setLastIndex(currentIndex === (noOfSlides.length - 1) ? (ourTeamData.length - ((currentIndex) * 4)) * 20 : 80)

        // console.log("while changing start and end... currentIndex", currentIndex)
        if (currentIndex === (noOfSlides.length - 1)) {
            setStartIndex((currentIndex * 4) - (4 - (ourTeamData.length - (currentIndex * 4))))
            setEndIndex(ourTeamData.length)
        }
        else {
            setStartIndex((currentIndex * 4))
            setEndIndex((currentIndex * 4) + 4)
        }

    }, [currentIndex])
    const closeWithoutSaving = () => {
        setOurTeamPopUp(false)
        setoverlay(false)
    }

    //   useEffect(() => {
    //       console.log("Last Index value..", lastIndex, currentIndex, noOfSlides.length)
    //   }, [lastIndex])

    useEffect(() => {
        // console.log("start End current index value..", startIndex, endIndex, currentIndex)
    }, [endIndex, startIndex])

    var settings = {
        infinite: false,
        className: "",
        speed: 900,
        // slidesToScroll: 3,
        // slidesToShow: 3.5,
        nextArrow: <SampleNextArrow
                    onNext={onNext}
                    />,
        prevArrow: <SamplePrevArrow 
                    onPrev={onPrev}
                    />,
        responsive: [
            {
                breakpoint: 2560,
                settings: {
                    slidesToScroll: 4,
                    slidesToShow: 4
                }
            },
            {
              breakpoint: 1024,
              settings: {
                slidesToScroll: 3,
                slidesToShow: 3
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToScroll: 2,
                slidesToShow: 2
              }
            },
            {
              breakpoint: 400,
              settings: {
                slidesToScroll: 1,
                slidesToShow: 1
              }
            },
        ]
    };
    const openModal = (teamMember) => {
        // console.log("first", teamMember)
        setSelectedTeamMember({...teamMember})
        setoverlay(true)
        setOurTeamPopUp(true)
    }
    return (
        <>
            <div className={`ovelap ${overlay === true ? "active" : ""}`}></div>
            <div className='school-generate ourteam'>
            {/* <div className='containerLanding containerOurTeam'> */}
                <div className='containerLanding'>
                    {/* <div className="slideshow"> */}
                        <div className='row'>
                            <div className='col-xl-8 col-lg-8 col-md-8 col-xs-8'>
                                <h3 className='headhx2'>{ourTeamData[0].name ? 'Meet team WISR' : 'Our Partner Schools'}</h3>
                                {/* <p className='headhx3'>Our Team</p> */}
                            </div>
                            <div className='arrowteam'>
                                <a onClick={() => onPrev()}><img src={teamLeftarrow} /></a>
                                <a onClick={() => onNext()}><img src={teamRightarrow} /></a>
                            </div>
                            {

                                ourTeamData.length != 0 &&
                                <div className="teamSlider">
                                    
                                    <div className='col-xl-12 col-lg-12 col-xs-12 pl pr'>
                                        {/* <div className='row'> */}
                                        <Slider {...settings}>
                                            {
                                                ourTeamData.map((el, index) => {
                                                    return (
                                                        <>
                                                            {/* <div className='col-xl-3 col-lg-3 col-md-6 col-xs-12'> */}
                                                            <div className='advisory-board-one' onClick={() => openModal(el)}>
                                                                <span><img src={el.img} /></span>
                                                                {el.name && <h4>{el.name}</h4>}
                                                                {el.designation && <p>{el.designation}</p>}
                                                            </div>
                                                            {/* </div> */}
                                                        </>
                                                    )
                                                })
                                            }
                                        </Slider>
                                        </div>
                                    </div>
                                // </div>

                                // ourTeamData.length != 0 ?
                                //    <div className=" slideshowSlider teamSlider"
                                //    style={{ transform: `translate3d(${-currentIndex * (lastIndex)}%, 0, 0)` }}>
                                //     {
                                //     //    [...Array(ourTeamData.length/4)].map((el) => {
                                //         noOfSlides.map((e, i) => {
                                //            return (
                                //                <>
                                //                <div className="slide" key={currentIndex} >
                                //                  <div className='col-xl-12 col-lg-12 col-xs-12 pl pr'>
                                //                   <div className='row ' style={{display: "flex"}}>
                                //                       {
                                //                         //   [...Array(5)].map(( el, index) => {
                                //                             ourTeamData.slice(startIndex,endIndex).map(( el, index) => {
                                //                               return (
                                //                                   <>
                                //                                      {
                                //                                         el ?
                                //                                         <div className='col-xl-3 col-lg-3 col-md-6 col-xs-12'>
                                //                                             <div className='advisory-board-one'>
                                //                                               <span><img src={el.img} /></span>
                                //                                               <h4>{el.name}</h4>
                                //                                               <p>{el.designation}</p>
                                //                                             </div>
                                //                                         </div>
                                //                                         :
                                //                                         <div className='col-xl-3 col-lg-3 col-md-6 col-xs-12'>
                                //                                             <div className='advisory-board-one'>
                                //                                               <span></span>
                                //                                               <h4></h4>
                                //                                               <p></p>
                                //                                             </div>
                                //                                         </div>
                                //                                      }
                                //                                   </>
                                //                               )
                                //                           })
                                //                       }
                                //                   </div>
                                //                 </div>

                                //                </div>
                                //                </>
                                //            )
                                //        })
                                //     }
                                //     </div>
                                //     :
                                //     <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                //     <div className="noData height-one"><h3> Our Team is setting up</h3></div>
                                //     </div>

                            }
                        </div>
                    {/* </div> */}
                </div>
            </div>
            <div
                id='ourTeamMember'
                className={`popup ${ourTeamPopUp === false ? "hide" : ""}`}
            >
                <div className='popup-body'>
                    
                    <div className='ourTeamMember clearfix'>
                       <div className="ourTeamMemberLeft">
                            <img src={selectedTeamMember.img} />
                       </div>
                        <div className="ourTeamMemberRight">
                            <span onClick={closeWithoutSaving} className='close toggle' data-target='amenitiesModal'> close</span>
                            <div className="ourTeamMemberScroll">
                                
                            <h4>{selectedTeamMember.name}</h4>
                            <span>{selectedTeamMember.designation}</span>
                            <p>{selectedTeamMember.description}</p>
                            </div>
                       </div>
                    </div>
                </div>
            </div>
        </>
    )

}

export default OurTeamScroll

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            // style={{ ...style, display: "block", background: "red", top: 20, right: 20, position: "relative" }}
            onClick={onClick}
        >
            <a onClick={() => props.onNext()}><img src={teamRightarrow} /></a>
        </div>
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            // style={{ ...style, display: "block", background: "green", top: 20, right: 20, position: "relative" }}
            onClick={onClick}
        >
            <a onClick={() => props.onPrev()}><img src={teamLeftarrow} /></a>
        </div>
    );
}