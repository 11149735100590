import { useState, useContext, useEffect } from "react"
import { useHistory } from "react-router-dom"
import AppContextObject from "../../../common/context/common"
import OnboardingBackBtn from "../schools/components/onboardingBackBtn"
import CampaignApprovalModal from "../../../components/addon/campaignApprovalModal"
import ThankyouModal from "../../../components/addon/thankyouModal"
import RejectBrandRequestModal from "../../../components/addon/rejectBrandRequestModal"
import DataContext from "../../../service/contextProvider"
import { PublicContextInitiator } from "../../apis/publicContextInitiator"
import FOSTable from "./components/FOSTable";
import NewFOS from "./components/NewFOS";
import EditFOS from "./components/EditFOS";
import SearchInput from "./components/SearchInput"
import BackendTopBar from "./components/backendTopBar"
import BackendBackBtn from "./components/backendBackBtn"
import SuccessUserCreateModal from "../../../components/addon/SuccessUserCreateModal"

var fosListGlobalVar = 1

const FosList = () => {
    const context = useContext(AppContextObject)
    const [isCustomInventoryEdit, setIsCustomInventoryEdit] = useState(false)
    const [overlay, setOverlay] = useState(false)
    const [searchKeys, setSearchKeys] = useState("");
    const [searchInpuOpen, setSearchInpuOpen] = useState(false);
    const [filterCity, setFilterCity] = useState([])
    const [allFosUsers, setAllFosUsers] = useState([]);
    const [newUserCreated, setNewUserCreated] = useState(true);
    const [totalPage, setTotalPage] = useState([0])
    const [currentPage, setCurrentpage] = useState(1)
    const [totalPageCount, setTotalPageCount] = useState<any>([0])
    const [region, setRegion] = useState('all');
    const [isFOSEdit, setIsFOSEdit] = useState(false);
    const [userId, setUserId] = useState("");
    const [cityFilterModal, setCityFilterModal] = useState(false);
    const [clearSearchIcon, setClearSearchIcon] = useState(false);
    const [regionCount, setRegionCount] = useState<any>({});
    const [loading, setLoading] = useState(false);
    const [userCreateSuccessfully, setUserCreateSuccessfully] = useState(false);
    const [userCreateedEmail, setUserCreatedEmail] = useState("");
    const [loadingFirstTime, setLoadingFirstTime] = useState(false)

    const addNewInventory = () => {
        setIsCustomInventoryEdit(true)
        setOverlay(true)
    }

    const hideModal = () => {
        setIsCustomInventoryEdit(false);
        setOverlay(false);
    }
    const hideEditModal = () => {
        setIsFOSEdit(false);
        setOverlay(false);
    }
    useEffect(() => {
        getAllFosUsers();
    }, [newUserCreated, currentPage, region, searchKeys, filterCity])

    const getAllFosUsers = async () => {
        setLoading(true);
        let resp;
        var localVar = Math.random()
        fosListGlobalVar = localVar
        if (region === 'all') {
            if(filterCity.length>0){
                resp = await context.apis.public.getAllFOSUsers(
                    context.validator, { searchValue: searchKeys, city: filterCity }, { limit: 10, page: currentPage, withCount: true, "sort": { "_id": -1 } }
                )
            }else{
                resp = await context.apis.public.getAllFOSUsers(
                    context.validator, { searchValue: searchKeys }, { limit: 10, page: currentPage, withCount: true, "sort": { "_id": -1 } }
                )
            }
            
        }
        else {
            if(filterCity.length>0){
                resp = await context.apis.public.getAllFOSUsers(
                    context.validator, { region: region, searchValue: searchKeys, city: filterCity }, { limit: 10, page: currentPage, withCount: true, "sort": { "_id": -1 } }
                )
            }else{
                resp = await context.apis.public.getAllFOSUsers(
                    context.validator, { region: region, searchValue: searchKeys }, { limit: 10, page: currentPage, withCount: true, "sort": { "_id": -1 } }
                )
            }
            
        }
        console.log('resp=========>', resp)

        if (resp && resp.done) {
            if(fosListGlobalVar === localVar){
                if(!loadingFirstTime){
                    setLoadingFirstTime(true)
                    setRegionCount(resp.countData);
                }
                else{
                    setRegionCount({...regionCount, [region === "all" ? "All" : region]: resp.response.count});
                }
                setAllFosUsers(resp.response.rows)
                let total = Math.ceil(resp.response.count / 10);
                setTotalPageCount(resp.response.count);
                let arr = []
                for (let i = 0; i < total; i++) {
                    arr.push(i)
                }
                setTotalPage([...arr])
            }          
        }
        setLoading(false);
    }
    const onPageChange = (page) => {
        setCurrentpage(page)
    }
    const onNextClick = () => {
        setCurrentpage(prev => prev + 1)
    }
    const onPrevClick = () => {
        setCurrentpage(prev => prev - 1)
    }
    
    const sortByRegion = (region) => {
        if (region !== 'all') {
            setRegion(region);
            setCurrentpage(1);
        }
        else {
            setRegion('all');
            setCurrentpage(1);
        }
    }
    const cityFilter = () => {
        setCityFilterModal(!cityFilterModal)
    }
    console.log(allFosUsers)
    return (
        <>
            <div className={`ovelap ${overlay === true ? "active" : ""}`}></div>
            <div className="col-xl-10 col-lg-12 col-md-12 col-xs-12">
                <div className="rightPart noBack">
                    <div className="row">
                        <BackendTopBar />
                        <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-10 mt-20">
                            <h2 className="htx2 clearfix">Full List of FOS Agencies</h2>
                        </div>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                            <div className="inventory-tableTabs">
                                <ul className="tabsinventory">
                                    <li onClick={() => sortByRegion('all')}><a href="javascript:void(0);" className={region === 'all' ? "active" : ""}>All ({regionCount.All})</a></li>
                                    <li onClick={() => sortByRegion('East')}><a href="javascript:void(0);" className={region === 'East' ? "active" : ""}>east ({regionCount.East})</a></li>
                                    <li onClick={() => sortByRegion('West')}><a href="javascript:void(0);" className={region === 'West' ? "active" : ""}>west ({regionCount.West})</a></li>
                                    <li onClick={() => sortByRegion('North')}><a href="javascript:void(0);" className={region === 'North' ? "active" : ""}>north ({regionCount.North})</a></li>
                                    <li onClick={() => sortByRegion('South')}><a href="javascript:void(0);" className={region === 'South' ? "active" : ""}>south ({regionCount.South})</a></li>
                                </ul>
                                <div className="inventorySearch schoolFilter">
                                    <SearchInput
                                        searchInpuOpen={searchInpuOpen} //true/false
                                        clearSearchIcon={clearSearchIcon} //true/false
                                        setSearchInpuOpen={setSearchInpuOpen} //function=>true/false
                                        setSearchKeys={setSearchKeys} //function=>string
                                        searchKeys={searchKeys} //string
                                        setClearSearchIcon={setClearSearchIcon}//function=>true/false
                                        palaceholder='FOS name'
                                        setCurrentpage={setCurrentpage}
                                    />
                                {(context.user.role === "backendAdmin" || context.user.role === "backendOperations" || context.user.role === "backendTech") && <a href="javascript:void(0)" className="btn btn-orange spaceL" onClick={addNewInventory}>new fos agency</a>}
                                </div>
                                <FOSTable
                                    dataList={allFosUsers}
                                    currentPage={currentPage}
                                    totalPage={totalPage}
                                    onPageChange={onPageChange}
                                    onNextClick={onNextClick}
                                    onPrevClick={onPrevClick}
                                    cityFilter={cityFilter}
                                    setIsFOSEdit={setIsFOSEdit}
                                    setUserId={setUserId}
                                    cityFilterModal={cityFilterModal}
                                    setOverlay={setOverlay}
                                    setFilterCity={setFilterCity}
                                    filterCity={filterCity}
                                    setCurrentpage={setCurrentpage}
                                    setCityFilterModal={setCityFilterModal}
                                    loading={loading}
                                    region={region}
                                    totalPageCount={totalPageCount}   
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {userCreateedEmail!=="" && <SuccessUserCreateModal
                modalTitle={'Success!'}
                userCreateSuccessfully={userCreateSuccessfully}
                setOverlay={setOverlay}
                setUserCreateSuccessfully={setUserCreateSuccessfully}
                setNewUserCreated={setNewUserCreated}
                newUserCreated={newUserCreated}
                userCreateedEmail={userCreateedEmail}
            />}
            <NewFOS
                isCustomInventoryEdit={isCustomInventoryEdit}
                hideModal={hideModal}
                setUserCreateSuccessfully={setUserCreateSuccessfully}
                setOverlay={setOverlay}  
                setUserCreatedEmail={setUserCreatedEmail}
            />
            <EditFOS
                isFOSEdit={isFOSEdit}
                hideEditModal={hideEditModal}
                setNewUserCreated={setNewUserCreated}
                newUserCreated={newUserCreated}
                userId={userId}
                setOverlay={setOverlay}
            />      
        </>
    )
}


export default FosList
