import React, { useEffect, useState, forwardRef } from 'react'
import { Line } from 'react-chartjs-2';
import { formatDateDDMonth } from '../../../../service/helper';

interface Props {
    totalBudgets?: any
}
var data;
var dataSet1;
let tempData;
var extraField;
const LineChartBackendBrand = forwardRef(({
    totalBudgets
}: Props, ref) => {
    const [brandData, setBrandData] = useState([]);
    const [flag, setFlag] = useState(false);
    const [budget, setBudget] = useState(true);

    useEffect(() => {
        // console.log('schoolData 111111111======>', brandData)
        if (dataSet1 && dataSet1.length === 1) {

            tempData = [...dataSet1]
            document.getElementById('budgetBox').style.backgroundColor = dataSet1[0].backgroundColor
            document.getElementById('budget').innerText = dataSet1[0].label
        } else {
        }
    }, [dataSet1])

    useEffect(() => {
        // console.log('schoolData 3333333333======>', totalBudgets)
        if (totalBudgets) {
            // console.log('schoolData 777777777======>', brandData)
            data = {
                labels:
                    totalBudgets.map((item, index) => formatDateDDMonth(item.startDate)),
                datasets: brandData,
            };
            setFlag(!flag)
        }
    }, [brandData])
    useEffect(() => {
        if (totalBudgets) {
            extraField = totalBudgets.map((item, index) => item.brandName)
            dataSet1 = [
                {
                    label: "Budget",
                    data: totalBudgets.map(item => item.budget),
                    backgroundColor: "#ABA1FC",
                    borderColor: "#ABA1FC",
                    tension: 0,
                    borderWidth: 1,
                    pointRadius: 1,
                    pointHoverRadius: 1,

                }
            ]

        }
        setBrandData(dataSet1);
    }, [totalBudgets])

    const toggleData = (value, fieldValue) => {
        // console.log('schoolData 4444444444444======>', brandData)
        let indexArray;
        let selectedArray;
        if (value === 'budget' && fieldValue) {
            tempData = [...tempData]
            indexArray = tempData.findIndex(item => item.label === "Budget")
            selectedArray = dataSet1.find(item => item.label === "Budget")
            // console.log('true', selectedArray, indexArray)
            // console.log('prev', tempData)
            tempData.splice(indexArray, 0, selectedArray);
            setBrandData(tempData);
            // console.log('after', tempData);
        }
        if (value === 'budget' && !fieldValue) {
            tempData = [...tempData]
            indexArray = tempData.findIndex(item => item.label === "Budget")
            selectedArray = tempData.find(item => item.label === "Budget")
            // console.log('false', selectedArray, indexArray)
            // console.log('prev', tempData)
            tempData.splice(indexArray, 1);
            setBrandData(tempData)
            // console.log('after', tempData)
        }

    }
    // console.log('schoolData 555555555555======================>', brandData, tempData, data)
    return (
        <>
            <div className='schoolLegend'>
                <a href="javascript:void(0)" className="row" onClick={() => { toggleData('budget', !budget); setBudget(!budget) }}>
                    <div id='budgetBox' className='box'></div>
                    <span id='budget' className={`schoolType ${budget ? '' : 'lingThrough'}`}></span>
                </a>
            </div>
            {data && <Line
                data={data}
                options={{
                    indexAxis: 'x',
                    scales: {
                        y: {
                            beginAtZero: true,
                            title: {
                                padding: 20,
                                display: true,
                                text: "Money Spent",
                                font: {
                                    family: 'Arial, Helvetica, sans-serif',
                                    size: 13,
                                  }
                            },
                        },
                        x: {
                            beginAtZero: true,
                            title: {
                                padding: 20,
                                display: true,
                                text: "Days",
                                font: {
                                    family: 'Arial, Helvetica, sans-serif',
                                    size: 13,
                                  }
                            },
                        }
                    },
                    plugins: {
                        tooltip: {
                            usePointStyle: true,
                            callbacks: {
                                labelPointStyle: function (context) {
                                    return {
                                        pointStyle: 'triangle',
                                        rotation: 0
                                    };
                                },
                                afterBody: function (data) {
                                    var multistringText = `Brand: ${extraField[data[0].dataIndex].map(item => item).length > 0 ? extraField[data[0].dataIndex].map(item => item) : 'NA'}`;

                                    return multistringText;
                                }
                            },


                        },
                        legend: {
                            display: false
                        }
                    },
                }}
                height="100"
            />
            }
        </>
    )
})

export default LineChartBackendBrand;