import campcreate from "../../assets/images/img2.png"
import noImg from "../../assets/images/blank-inventory-img.jpg"

interface Props {
    showModalTwoMore?: any,
    brandPartners?: any,
    closeWithoutSaving?: any

}
const TwoMore = ({
    showModalTwoMore,
    brandPartners,
    closeWithoutSaving
}: Props) => {
    return (
        <>
            <div id="brands-popup" className={`popup ${showModalTwoMore === false ? "hide" : ""}`}>
                <div className="popup-header">
                    <h3 className="htx2 pophead">Brand Partners for the Month</h3>
                    <span className="close toggle" onClick={closeWithoutSaving} data-target="myPopup">close</span> </div>
                <div className="popup-body">
                    <div className="invetorypopblock">
                        <div className="searchlist cale-popup-tue">
                            <h3>Campaigns</h3>
                            <ul>
                                {brandPartners.map((item, index) => (
                                    <li><a href="javascript:void(0);"><span><img src={item.logo || noImg} /></span>{item.name}</a></li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default TwoMore