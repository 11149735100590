
const SchoolSVG = () => {

    return (
        <svg width="21" height="16" viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.16812 7.05759V12.4559C4.16812 12.7914 4.33324 13.1073 4.62625 13.2708C6.75938 14.4607 11.3216 15.7567 16.0095 13.2723C16.3228 13.1063 16.5093 12.7755 16.5093 12.4209V7.05759" stroke="#070707" strokeWidth="1.2"/>
            <path d="M9.95253 1.62885L2.43441 4.43323C1.57663 4.75319 1.5641 5.96197 2.41506 6.29966L9.9334 9.28313C10.1677 9.37612 10.4286 9.37716 10.6637 9.28605L18.3608 6.30266C19.2216 5.96902 19.2091 4.74671 18.3417 4.43067L10.6444 1.62621C10.4208 1.54475 10.1755 1.54569 9.95253 1.62885Z" stroke="#070707" strokeWidth="1.2"/>
            <path d="M18.8 6.27609V8.94256" stroke="#070707" strokeWidth="1.2" strokeLinecap="round"/>
        </svg>        
    )
}

export default SchoolSVG
