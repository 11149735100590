import { useState } from "react"
import { Link } from "react-router-dom"

import lock from "../../assets/images/lock.svg"
import eye from "../../assets/images/eye.svg"
import openEye from "../../assets/images/open-eye.svg"
import check from "../../assets/images/check.svg"
import errorSVG from "../../assets/images/error.svg";

const PasswordField = (props) => {

    const {
        formData,
        formKey,
        placeholder,
        isRequired,
        label,
        onFormDataChange,
        showForgotPasswordLink,
        formErrors,
        loginError,
        onVerifyClick,
        isVerified,
        isDisabled,
        forgotPasswordLink,
        inProgress,
        isOldPassword,
        isVerifyClickDisabled,
        maxLength,
        helpText,
        hideIcon,
        onKeyPress,
        autoFocus
    } = props

    let [isPasswordVisible, setIsPasswordVisible] = useState(false)

    return (
        <div className={`forminpt ${(isDisabled === true) ? "disabled" : ""}`}>
            <label>{label}
            {
                isRequired === true &&
                <span className="mandate">*</span>
            }
            </label>
            {
                !hideIcon
                &&
                <em><img src={lock} className="inputicon" alt="envolpe" /></em>
            }
            <input
                value={formData[formKey]}
                name={formKey}
                placeholder={placeholder}
                type={isPasswordVisible === true ? "text" : "password"}
                disabled={isDisabled === true ? true : false}
                id={formKey}
                onChange={onFormDataChange}
                className={`${formErrors && formErrors[formKey] ? "onFocus" : ""}  pl1` }
                maxLength={maxLength}
                onKeyPress={onKeyPress}
                autoFocus={autoFocus}
            />
            {
                formErrors && formErrors[formKey] &&
                <span id="for_fullname" className="valError"><img src={errorSVG} />{formErrors[formKey]}</span> 
            }
            {
                loginError &&
                <span id="for_fullname" className="valError"><img src={errorSVG} />{loginError}</span>
            }
            {
                onVerifyClick &&
                <a onClick={onVerifyClick} href="javascript:void(0)" className={`${inProgress ? "verifyLoader active" : "verifyLoader"} ${isOldPassword && "shiftLeft"} ${isVerifyClickDisabled && "clickNone"} `}>{inProgress ? <span className="button--loading"></span> : ""}<b className={`vertext vrifiyotp ${isVerified === true ? "complete" : ""}`}>verify  <img src={check} /></b></a>
            }
            <a
                href="javascript:void(0);"
                onClick={() => setIsPasswordVisible(!isPasswordVisible)}
            >
                <img src={isPasswordVisible === true ? openEye : eye} className="eyeshow" alt="eye" />
            </a>
            {
                (showForgotPasswordLink === true && forgotPasswordLink) &&
                <Link to={forgotPasswordLink} className="forgot">Forgot Password?</Link>
            }
            {
                helpText && helpText.length > 0 &&
                <small>{helpText}</small>
            }
        </div>
    )
}

export default PasswordField
