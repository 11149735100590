import React,{useState} from "react";
import config from "../../../../../service/config";

const CampaignStatusFilter = ({openStatusFilter,setFilterType,filterType,setFilterStatus,filterStatus, nonWisrCampaign}) => {
    
    // const filterByStatus = (val)=>{
    //     setFilterType(val)
    // }
    const filterCampaignStatus = (value) => {
            let array = [...filterStatus]
            if (array.includes(value)) {
                array.splice(array.indexOf(value), 1)
            }
            else {
                array.push(value)
            }
            setFilterStatus([...array])
        }

    

    const clearAllFilter = () =>{
        setFilterStatus([]);
    }
    return (
        <div className={`filterDropDown textNone ${openStatusFilter ? 'open' : ''}`}>
            <div className="filterDropHead">
                <h2>Filter <a href="javascript:void(0);" className="viewwLink" onClick={clearAllFilter}>Clear All</a></h2>
            </div>
            <div className="filterDropBody">
                <div className={`filterDropClose open`}>
                    {/* <h3><a href="javascript:void(0);" onClick={() => setSpanCategory(!spanCategory)} >{`Category`}  <img src={filterArrowSvg} /></a></h3> */}
                    <div className="filtercheckbox">
                        {nonWisrCampaign ?
                            config.nonWisrCampaignStatus.map((item, index) => (
                                <div className="checkbox fnone" key={index}>
                                    <input type="checkbox" id={item.value} checked={filterStatus.includes(item.value)} 
                                    />
                                    <label htmlFor={item.value} 
                                    onClick={() => filterCampaignStatus(item.value)}
                                    >{item.label}</label>
                                </div>
                            ))
                            :
                            config.campaignStatus.map((item, index) => (
                                <div className="checkbox fnone" key={index}>
                                    <input type="checkbox" id={item.value} checked={filterStatus.includes(item.value)} 
                                    />
                                    <label htmlFor={item.value} 
                                    onClick={() => filterCampaignStatus(item.value)}
                                    >{item.label}</label>
                                </div>
                            ))
                        }
                    </div>
                </div>

            </div>
        </div>
    )
}
export default CampaignStatusFilter;