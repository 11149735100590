import React,{useState} from "react";
import config from "../../../../service/config";

interface Props{
    openStatusFilter?: any,
    setFilterStatus?: any,
    filterStatus?: any,
    campaignType?: any
}

const CampaignStatusFilter = ({openStatusFilter,setFilterStatus,filterStatus, campaignType}: Props) => {
    
    // const filterByStatus = (val)=>{
    //     setFilterType(val)
    // }
    const filterCampaignStatus = (value) => {
            let array = [...filterStatus]
            if (array.includes(value)) {
                array.splice(array.indexOf(value), 1)
            }
            else {
                array.push(value)
            }
            setFilterStatus([...array])
        }

    

    const clearAllFilter = () =>{
        setFilterStatus([]);
    }
    return (
        <div className={`filterDropDown ${openStatusFilter ? 'open' : ''}`}>
            <div className="filterDropHead">
                <h2>Filter <a href="javascript:void(0);" className="viewwLink" onClick={clearAllFilter}>Clear All</a></h2>
            </div>
            <div className="filterDropBody">
                <div className={`filterDropClose open`}>
                    {/* <h3><a href="javascript:void(0);" onClick={() => setSpanCategory(!spanCategory)} >{`Category`}  <img src={filterArrowSvg} /></a></h3> */}
                    <div className="filtercheckbox">
                        {campaignType ?
                            config.campaignStatusBrand.map((item, index) => (
                                <div className="checkbox fnone" key={index}>
                                    <input type="checkbox" id={item.value} checked={filterStatus.includes(item.value)} 
                                    />
                                    <label htmlFor={item.value} 
                                    onClick={() => filterCampaignStatus(item.value)}
                                    >{item.label}</label>
                                </div>
                            ))
                            :
                            config.campaignStatusBrand.filter(item => item.value !== "approved").map((item, index) => (
                                <div className="checkbox fnone" key={index}>
                                    <input type="checkbox" id={item.value} checked={filterStatus.includes(item.value)} 
                                    />
                                    <label htmlFor={item.value} 
                                    onClick={() => filterCampaignStatus(item.value)}
                                    >{item.label}</label>
                                </div>
                            ))
                        }
                    </div>
                </div>

            </div>
        </div>
        // <div className={`filterDropDown r-auto tableFilter ${openStatusFilter ? 'open' : ''}`}>
        //     <div className="filterDropBody">
        //     <div className="filterDropHead">
        //             <h3>Filter <a href="javascript:void(0);" className="viewwLink" onClick={clearAllFilter}>Clear All</a></h3>
        //         </div>
        //         <div className="filterDropClose open">
        //             <div className="filtercheckbox mt-10">
        //                 <div className="radioBtn">
        //                     <input type="radio" id="test14" name="radio-group4"  checked={filterType === 'in-review'} onChange={() => filterByStatus('in-review')} />
        //                     <label htmlFor="test14">In-Review</label>
        //                 </div>
        //                 <div className="radioBtn">
        //                     <input type="radio" id="test15" name="radio-group4" checked={filterType === 'accepted'} onChange={() => filterByStatus('accepted')} />
        //                     <label htmlFor="test15">Approved</label>
        //                 </div>
        //                 <div className="radioBtn">
        //                     <input type="radio" id="test16" name="radio-group4" checked={filterType === 'rejected'} onChange={() => filterByStatus('rejected')} />
        //                     <label htmlFor="test16">Rejected</label>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </div>
    )
}
export default CampaignStatusFilter;