import { useState, useContext, useEffect } from "react"

import { useHistory } from "react-router-dom"

import search from "../../../assets/images/search.svg"
import arrowUpDown from "../../../assets/images/backendAdmin/arrow-up-down.svg"
import riarrownext from "../../../assets/images/right-arrow-next.svg"
import imgViewTwo from "../../../../assets/images/backendAdmin/img-view-two.jpg"
import tabImgInventory from "../../../assets/images/tab-img-inventory.png"
import tableFilter from "../../../../assets/images/table-filter.svg"
import arrowUp from "../../../assets/images/arrow-up.svg"
import filterSvg from "../../../assets/images/filter-svg.svg"
import filterArrowSvg from "../../../assets/images/filter-arrow-svg.svg"

import tabSlide1 from "../../../assets/images/tab-Slide1.png"
import TopBar from "../../schools/components/topBar"
import AppContextObject from "../../../../common/context/common"

import OnboardingBackBtn  from "../../schools/components/onboardingBackBtn"
import InventoryCard from "../../schools/updateDetails/inventory/inventoryCard"
import Pagination from "../../../../components/addon/pagination"
import CampaignApprovalModal from "../../../../components/addon/campaignApprovalModal"
import ThankyouModal from "../../../../components/addon/thankyouModal"
import RejectBrandRequestModal from "../../../../components/addon/rejectBrandRequestModal"

import DataContext from "../../../../service/contextProvider"

import { PublicContextInitiator } from "../../../apis/publicContextInitiator"

import { Steps } from "intro.js-react"
import { formatDateToDDMMMYY, isObjectEmpty } from "../../../../service/helper"

import toast from "react-hot-toast"
import BackendBackBtn from "../components/backendBackBtn"

const CampaignsReject = () => {
    const parentData = useContext<any>(DataContext)
    const history = useHistory()
    const context = useContext(AppContextObject)
    const publicContextInitiator = new PublicContextInitiator(context)

    const [showModalApproveActive, setshowModalApproveActive] = useState(false)
    const [showThankyouModal, setShowThankyouModal] = useState(false)
    const [thankyouTitle, setThankyouTitle] = useState("")
    const [showConfirmRejectModal, setShowConfirmRejectModal] = useState(false)
    const [thankyouDescription, setThankyouDescription] = useState("")
    const [showModalapproveActive, setshowModalapproveActive] = useState(false)
    const [overlay, setOverlay] = useState(false)
    const [requestedInventoryName, setRequestedInventoryName] = useState("")

    console.log("working");

    return (
        <>
            <div className={`ovelap ${overlay === true ? "active" : ""}`}></div>
            <div className="col-xl-10 col-lg-12 col-md-12 col-xs-12">
                <div className="rightPart noBack">
                <div className="row">
                <div className="col-xs-12 col-lg-12 col-md-12 col-xs-12">
                            <BackendBackBtn/>
                        </div>
                    <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-10">
                        <a className="backbtn dblock" href="javascript:void(0);"><img src="/static/media/back-arrow.7d7b4362.svg" /> Rejected Campaigns</a>
                    </div>
                    
                    <div className="col-xl-5 col-lg-5 col-md-12 col-xs-12 mt-30"><h2 className="htx3 clearfix mt-10">Total Campaigns Rejected: 14</h2></div>
                    <div className="col-xl-7 col-lg-7 col-md-12 col-xs-12 mt-30 mb-10">
                                <div className="inventorySearch schoolFilter inventoryBackAdmin">
                                <a href="javascript:void(0)" className="btn">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"> <circle cx="8.58478" cy="8.58478" r="7.58478" stroke="#070707" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M13.8599 14.254L16.8335 17.22" stroke="#070707" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                                </a>
                                <select><option>This Month</option></select>
                               </div>
                               </div>
                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                            <div className="inventory-tableTabs">
                            <div className="table-container table-inventory mt-10">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>SL No</th>
                                            <th>Campaign Name</th>
                                            <th>school</th>
                                            <th>inventories</th>
                                            <th>schedule</th>
                                            <th>Budget</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>Nike Product Launch</td>
                                                <td>24</td>
                                                <td>12</td>
                                                <td>01 Feb, 21 - 21 Mar, 21</td>
                                                <td>₹ 7,50,000</td>
                                                <td><span className="table-status redbg">Rejected</span> 
                                                    <svg className="down-arrow requestArrow" width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M1 6L6 0.999999L11 6" stroke="#8C8C8C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                                                    <img className="updownarrow" src="/static/media/arrow-up-down.3516c17e.svg" />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="campaignRequestOepn" colSpan={9}>
                                                    <table className="table">
                                                    <tbody>
                                                    <td>
                                                        <div className="campaignRequestSlide">
                                                            <div className="campaignRequestSlideLarge">
                                                                <img src={imgViewTwo} />
                                                            </div>
                                                            <div className="campaignRequestSlideSmall">
                                                                <img src={imgViewTwo} />
                                                                <img src={imgViewTwo} />
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <h4>Campaign Description</h4>
                                                        <p>The campaign is scheduled to launch the new Nike sport shoes for 2021. Activities during P.E. classes to be conducted by Nike Sports Professionals. Activities like Cricket, Football, Basketball to be conducted by Nike Professionals to train the school students.</p>
                                                        <div className="mt-20 clearfix"></div>
                                                        <h4>Rejection Reason</h4>
                                                        <p>The campaign creatives do not meet the Wisr guidelines. The brand was informed to make appropriate changes.</p>
                                                   
                                                    </td>
                                                    <td style={{verticalAlign: "bottom", width: "340px"}}></td>
                                                    </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>Addidas Product Launch</td>
                                                <td>4</td>
                                                <td>2</td>
                                                <td>01 Feb, 21 - 21 Mar, 21</td>
                                                <td>₹ 6,50,000</td>
                                                <td><span className="table-status redbg">Rejected</span>
                                                    <svg className="down-arrow requestArrow" width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M1 6L6 0.999999L11 6" stroke="#8C8C8C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                                                    <img className="updownarrow" src="/static/media/arrow-up-down.3516c17e.svg" />
                                                     </td>
                                            </tr>
                                            <tr>
                                                <td>3</td>
                                                <td>Nike Product Launch</td>
                                                <td>24</td>
                                                <td>12</td>
                                                <td>01 Feb, 21 - 21 Mar, 21</td>
                                                <td>₹ 7,50,000</td>
                                                <td><span className="table-status redbg">Rejected</span> 
                                                    <svg className="down-arrow requestArrow" width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M1 6L6 0.999999L11 6" stroke="#8C8C8C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                                                    <img className="updownarrow" src="/static/media/arrow-up-down.3516c17e.svg" />
                                                     </td>
                                            </tr>
                                            <tr>
                                                <td>4</td>
                                                <td>Nike Product Launch</td>
                                                <td>24</td>
                                                <td>12</td>
                                                <td>01 Feb, 21 - 21 Mar, 21</td>
                                                <td>₹ 7,50,000</td>
                                                <td><span className="table-status redbg">Rejected</span> 
                                                    <svg className="down-arrow requestArrow" width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M1 6L6 0.999999L11 6" stroke="#8C8C8C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                                                    <img className="updownarrow" src="/static/media/arrow-up-down.3516c17e.svg" />
                                                     </td>
                                            </tr>
                                            <tr>
                                                <td>5</td>
                                                <td>Nike Product Launch</td>
                                                <td>24</td>
                                                <td>12</td>
                                                <td>01 Feb, 21 - 21 Mar, 21</td>
                                                <td>₹ 7,50,000</td>
                                                <td><span className="table-status redbg">Rejected</span>
                                                    <svg className="down-arrow requestArrow" width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M1 6L6 0.999999L11 6" stroke="#8C8C8C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                                                    <img className="updownarrow" src="/static/media/arrow-up-down.3516c17e.svg" />
                                                     </td>
                                            </tr>
                                            <tr>
                                                <td>6</td>
                                                <td>Nike Product Launch</td>
                                                <td>24</td>
                                                <td>12</td>
                                                <td>01 Feb, 21 - 21 Mar, 21</td>
                                                <td>₹ 7,50,000</td>
                                                <td><span className="table-status redbg">Rejected</span>
                                                    <svg className="down-arrow requestArrow" width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M1 6L6 0.999999L11 6" stroke="#8C8C8C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                                                    <img className="updownarrow" src="/static/media/arrow-up-down.3516c17e.svg" />
                                                     </td>
                                            </tr>
                                            <tr>
                                                <td>7</td>
                                                <td>Addidas Product Launch</td>
                                                <td>4</td>
                                                <td>2</td>
                                                <td>01 Feb, 21 - 21 Mar, 21</td>
                                                <td>₹ 6,50,000</td>
                                                <td><span className="table-status redbg">Rejected</span>
                                                    <svg className="down-arrow requestArrow" width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M1 6L6 0.999999L11 6" stroke="#8C8C8C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                                                    <img className="updownarrow" src="/static/media/arrow-up-down.3516c17e.svg" />
                                                     </td>
                                            </tr>
                                            <tr>
                                                <td>8</td>
                                                <td>Nike Product Launch</td>
                                                <td>24</td>
                                                <td>12</td>
                                                <td>01 Feb, 21 - 21 Mar, 21</td>
                                                <td>₹ 7,50,000</td>
                                                <td><span className="table-status redbg">Rejected</span>
                                                    <svg className="down-arrow requestArrow" width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M1 6L6 0.999999L11 6" stroke="#8C8C8C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                                                    <img className="updownarrow" src="/static/media/arrow-up-down.3516c17e.svg" />
                                                     </td>
                                            </tr>
                                            <tr>
                                                <td>9</td>
                                                <td>Nike Product Launch</td>
                                                <td>24</td>
                                                <td>12</td>
                                                <td>01 Feb, 21 - 21 Mar, 21</td>
                                                <td>₹ 7,50,000</td>
                                                <td><span className="table-status redbg">Rejected</span>
                                                    <svg className="down-arrow requestArrow" width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M1 6L6 0.999999L11 6" stroke="#8C8C8C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                                                    <img className="updownarrow" src="/static/media/arrow-up-down.3516c17e.svg" />
                                                     </td>
                                            </tr>
                                            <tr>
                                                <td>10</td>
                                                <td>Nike Product Launch</td>
                                                <td>24</td>
                                                <td>12</td>
                                                <td>01 Feb, 21 - 21 Mar, 21</td>
                                                <td>₹ 7,50,000</td>
                                                <td><span className="table-status redbg">Rejected</span> 
                                                    <svg className="down-arrow requestArrow" width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M1 6L6 0.999999L11 6" stroke="#8C8C8C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                                                    <img className="updownarrow" src="/static/media/arrow-up-down.3516c17e.svg" />
                                                     </td>
                                            </tr>
                                            
                                    </tbody>
                                </table>
                            </div>
                            </div>
                        </div>  
                    
                </div>
                </div>
            </div>      
            <div id="approveActive" className={`popup popup-center ${showModalapproveActive === false ? "hide" : ""}`}>
            <div className="popup-header">
                <h3 className="htx2 pophead">Request Sent Successfully!</h3>
                <span className="close toggle" data-target="myPopup">close</span> </div>
                <div className="popup-body">
                    <div className="viewdetailsHead">
                        <div className="approveActive">
                            <p>A request to add {requestedInventoryName} has been sent to your school's admin. </p>
                            <div className="brand-btn">
                                <a href="javascript:void(0)" className="btn btn-orange">DONE</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <CampaignApprovalModal
                showModalApproveActive={showModalApproveActive}
                // closeWithoutSaving={closeApprovalModal}
                // viewDetails={viewBrandDetails}
                // approveRequest={approveRequest}
            />

            <ThankyouModal
                showThankyouModal={showThankyouModal}
                // closeModal={closeThankyouModal}
                modalTitle={thankyouTitle}
                description={thankyouDescription}
            />

            <RejectBrandRequestModal
                showConfirmRejectModal={showConfirmRejectModal}
                // closeModal={closeConfirmRejectModal}
                // onRejectRequest={onRejectRequest}
            />

        </>
    )
}


export default CampaignsReject
