import { useEffect, useState } from "react";
const Mou = (props) => {

  const [amenityTableData, setAmenityTableData] = useState([]);
  const [activityTableData, setActivityTableData] = useState([]);

  useEffect(()=>{
    var keyNames = Object.keys(props.amenityDetails);
    let inventoryTypes = ["classroomAmenities","commonAreaAmenities","otherRoomsAndStaffAmenities","outdoorAmenities"]
    for (let i = 0; i < inventoryTypes.length; i++) {
      props.amenityDetails[inventoryTypes[i]].map(item=>{
        let typeArr = [];
        let placementArr = [];
        item.data.map(amenity=>{
          typeArr.push({name:amenity.name,customName: amenity.customName})
          let propertyNames = Object.getOwnPropertyNames(amenity.attr);
          let data = []
          propertyNames.map(name=>{
            if(amenity.attr[name].length > 0 || amenity.attr[name].breadth > 0 || amenity.attr[name].height > 0)
            {
              data.push({
                name: name,
                custom: amenity.attr[name]._isCustom !== undefined ? amenity.attr[name]._isCustom : false,
                quantity: amenity.attr[name].quantity
              })
            }
            
          })        
          placementArr.push(data)
        })      
        let data = {
          parentName: item.name,
          quantity: item.quantity,
          types: typeArr,
          placements: placementArr,
          custom: item._custom !== undefined ? item._custom : false
        }
        setAmenityTableData(prev=>[
          ...prev,
          data
        ])
      })
    }
  },[])

  useEffect(()=>{
    props.activityDetails.map(item=>{
      if(item.availableForBranding === true){
        let placementArr = [];
        item.amenities.map(amenity=>{
          console.log("amenity>>>>>.",amenity);
          if(amenity.allowBrandsToProvide == true || amenity.availableForBranding == true){
            placementArr.push({name: amenity.name, custom: amenity.isCustom})
          }
        })
        let allVenue = item.venue.filter(el => el.toLowerCase() !== "other")
        let data = {
          eventName: item.name,
          placements: placementArr,
          venue: [...allVenue, ...item.otherVenueList],
          month: item.months,
          custom: item.custom
        }
        setActivityTableData(prev=>[
          ...prev,
          data
        ])
      }
      
    })
  },[])
  useEffect(() => {
    let element = document.getElementById("termsBox")
    console.log("element", element)
    element && element.scrollIntoView({block: "start"})
  }, [props.showMou])

  return (
    <>
      <div id="termsPopup" className={`onboarding-terms popup show `} style={{display: `${props.showMou ? "" : "none"}`}} >
        <div className="popup-header">{/*<span className="toggle left-back" data-target="termsPopup">close</span>*/} <span onClick={props.onToggle} className="close toggle" data-target="termsPopup">close</span> </div>
        <div className="popup-body">
          <div className="facili-avai-in clearfix">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="termsBox" id="termsBox">
                  <h2>Terms and Conditions</h2>
                  <p>This Terms and Conditions (hereinafter “Agreement”) is made and entered this {new Date().getDate()} day of {new Date().toLocaleString("default", { month: "long"})}, {new Date().getFullYear()} into by and between:</p>
                  <p><b>Wondrlab Martech Platforms Private Limited</b>, a company registered in India under the Companies Act, 2013 and having its registered office address at B802, Oberoi Woods, CHS Ltd., Mohan Gokhale Marg, Near Oberoi Mall, Goregaon East, Mumbai 400063 (hereinafter referred to as the <b>“Company”</b>, which expression shall, unless repugnant to the context or meaning thereof, be deemed to mean and include its successors and assigns) of the FIRST PART</p>
                  <p>And</p>
                  <p><b>{props.schoolName ? props.schoolName : "School Name"}</b>, a company/ trust registered under {props.registeredUnder ? props.registeredUnder : "''"} and having its registered office at <b>{props.schoolAddress ? props.schoolAddress : "school address"}</b> entity engaged in the business of education and runs a school or similar facility (hereinafter referred to as the <b>“School”</b>, which expression shall, unless repugnant to the context or meaning thereof, be deemed to mean and include it successors and permitted assigns) of the SECOND PART</p>
                  <p>The Company and the School are wherever the context so requires, hereinafter collectively referred to as the <b>“Parties”</b></p>
                  <h3>WHEREAS:</h3>
                  <div className="bullet"><p>1.</p><p>The Company through its IP platform “WISR” is engaged in the business of providing better infrastructure for schools, better child experiences & better salaries for School staff.</p></div>
                  <div className="bullet"><p>2.</p><p>“WISR” is India’s first platform that enables brands across categories to activate thousands of schools by creating templatized school contact programs at the click of a button. The platform enables brands to bring scale and efficiency at every step through automation, data and measurability.</p></div>
                  <div className="bullet"><p>3.</p><p>The School is a company/ trust/ legal entity engaged in the business of education and runs a school or similar facility.</p></div>
                  <div className="bullet"><p>4.</p><p>4The Company is now desirous of entering into a Terms and Conditions with the School to onboard the school for marketing school assets for its end Clients/ Brands through WISR.</p></div>
                  <p>NOW THEREFORE, in consideration of the mutual promises and covenants herein contained, the Parties mutually agree to the following terms:</p>
                  <div className="bullet-heading"><p><h3>1.</h3></p><p><h3>DEFINITIONS </h3></p></div>
                  <div className="bullet"><p>(a)</p><p><b>“Terms and Conditions”</b> means this agreement and all schedules, annexures and exhibits attached to it or incorporated in it by reference.</p></div>
                  <div className="bullet"><p>(b)</p><p><b>“Client/ Brands”</b> shall mean the end clients referred to or introduced by the Company for marketing of school assets through its platform “WISR”;</p></div>
                  <div className="bullet"><p>(c)</p><p><b>“Intellectual Property”</b> means and includes any and all intellectual property created specifically by the Company including its school platform “WISR”;</p></div>
                  <div className="bullet"><p>(d)</p><p><b>“School platform assets”</b> means and includes the assets provided by School and covered under this Agreement; or as amended by the Company or the school from time to time.</p></div>
                  <div className="bullet"><p>(e)</p><p><b>“Term”</b> shall mean a period of three years academic years; from the date of execution of this Agreement.</p></div>
                  <div className="bullet-heading"><p><h3>2.</h3></p><p><h3>Engagement of Services:</h3></p></div>
                  <div className="bullet"><p>2.1</p><p> The School hereby agrees to permit the Company to use the school assets and to market such school assets to its end Clients/ Brands through its IP platform WISR on terms and conditions as agreed with such end client.</p></div>
                  <div className="bullet"><p>2.2</p><p>	The School agrees that the Company will have exclusive rights to market such school assets during the tenure of this Agreement. </p></div>
                  <div className="bullet"><p>2.3</p><p>	Post onboarding process of school is completed, and any Client/ Brand of the Company is interested in availing the school assets, then the Company will share details of the same vide email for pre-approval. The School will pre-approve the School Assets and also provide its written approval for the corresponding creatives for the campaign shared subsequently. The school can choose to accept or reject the proposed campaign within a period of 5 business days. If  the school fails to approve the same in writing within 5 business days, the brand campaign will stand cancelled and the school agrees that it will lose the revenue associated with that campaign.</p></div>
                  <div className="bullet"><p>2.4</p><p>	Post receiving the approval from school for the campaign, the creatives will also be shared for such approved campaign for approval from the school; which will have to be approved or changes suggested within 3 business days.</p></div>
                  <div className="bullet"><p>2.5</p><p>	The School will provide pictures and dimensions of the school assets to be marketed. Once campaign is initiated; the school will be obligated to provide pictures of the school assets that are branded.</p></div>
                  <div className="bullet"><p>2.6</p><p>	If for any reason the School decides to not market any of the School asset previously agreed, then it can be removed from the list of empanelled/ onboarded schools subject to no arrangement being in place with end Client/ Brand for marketing the school assets on the date of such communication from the School. In the event that any such arrangement/ activity is not completed; the School can be removed from the list of onboarded schools after the School assets and associated services have been provided to such end Client/ Brand to the satisfaction of such end Client/ Brand. In case any pending arrangements then the Agreement will continue to be valid till completion of any such previouslyagreed arrangements.</p></div>
                  <div className="bullet"><p><h3>3.</h3></p><p><h3>Redemption process</h3></p></div>
                  <div className="bullet-heading"><p>3.1</p><p>	The Company will provide a redemption option on a case-to-case basis. The school will have the option to select from the list of available redemption options which will be an exhaustive list. Such options will be equivalent to a xx% of the revenue basis the redemption option selected.</p></div>
                  <div className="bullet"><p>3.2</p><p>	In consideration of marketing of the School assets through WISR, the Company shall offer to the School on a redemption model basis an amount equivalent to xx% of the revenue (net of all costs) earned from the Client by the Company from such school assets.</p></div>
                  <div className="bullet"><p>3.3</p><p>	The School may decide upfront the manner in which it would like to avail of redemption options once the school assets have been billed to the Client. However the Company shall be obligated to honour and complete the redemption process only after receipt of monies from the Client and subject to costs incurred. All redemptions made by the school pursuant to this agreement shall be subject to Indian laws, as applicable. The School will be  responsible for income tax compliances on the receipt of such redemption proceeds; if any applicable.</p></div>
                  <div className="bullet-heading"><p><h3>4.</h3></p><p><h3>General Terms & Conditions</h3></p></div>
                  <div className="bullet"><p>4.1</p><p>The School acknowledges and agrees that the Company shall retain all right, title, and interest in WISR and the right to marketing of School assets and to any material/proprietary information in the School assets which may be provided by the Company to the School or its clients.</p></div>
                  <div className="bullet"><p>4.2</p><p>Either party may terminate this agreement without cause by giving 90 days written notice to the other party.</p></div>
                  <div className="bullet"><p>4.3</p><p>The Company shall not be liable for any consequential, indirect, exemplary, incidental, special or punitive damages based on any claim arising out of this Agreement. The liability of Company is limited to value under this Agreement. The School further hereby agrees to indemnify the Company and the end Client/ Brand for any losses or damages caused due to any action of the School or breach of this Agreement or breach by School for any agreed engagement with the end Client. This clause shall survive termination or expiry of this Agreement.</p></div>
                  <div className="bullet"><p>4.4</p><p>Any Confidential Information received or acquired by the School from in pursuance of this Agreement shall be treated as confidential and shall not be used or disclosed to any third party by the School without the prior written consent of the Company.</p></div>
                  <div className="bullet"><p>4.5</p><p>This Agreement shall be interpreted and governed in all respects by the laws of India and the competent courts of Mumbai alone and no other courts shall be entitled to entertain and try any dispute or matter relating to or arising out of this Agreement.</p></div>
                  <div className="bullet"><p>4.6</p><p>The Company shall have the right to assign all or any part of its rights and obligations hereunder to any of its Affiliates without the prior consent of or notice to the School. All assignments, warranties, indemnities and confidentiality obligations provided herein shall survive termination or expiration of this Agreement.</p></div>
                  <div className="bullet"><p>4.7</p><p>In case of any Force Majeure event; each Party shall be excused from such performance to the extent that it is prevented, hindered or delayed by such cause.</p></div>
                  
                  <div className="moutableview">
                  <table className="table signature">
                    <thead>
                      <tr>
                        <td scope="col">Wondrlab Martech Platforms Private Limited</td>
                        <td scope="col"></td>
                        <td scope="col">{props.schoolName ? props.schoolName : "School Name"}</td>
                      </tr>
                    </thead>
                    <tbody><tr>
                        <td scope="col">
                          <div>By :</div>
                          <div>Title :</div>
                        </td>
                        <td scope="col"></td>
                        <td scope="col">
                          <div>By :</div>
                          <div>Title :</div>
                          </td>
                      </tr>
                      <tr>
                        <td scope="col">Date :</td>
                        <td scope="col"></td>
                        <td scope="col">Date :</td>
                      </tr>
                      </tbody>
                      </table>
                      </div>

                  <h3>SCHEDULE:</h3>
                  <div className="yellow-text"><span>Detailed description of School assets and Services</span></div>
                  <div className="moutableview">
                    <h3>1. School/Classroom Inventory Details</h3>
                    <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">Inventories</th>
                        <th scope="col">Quanitity</th>
                        <th scope="col">Inventory Types</th>
                        <th scope="col">Placements</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        amenityTableData && amenityTableData.map(amenity=>{
                          return(
                          <tr>
                            <td><span className="caps">{amenity.parentName}</span><span className="customeStar">{amenity.custom ? "*" : ""}</span></td>
                            <td>{amenity.quantity}</td>
                            <td className="p0">
                              <table className="table">
                                <thead>
                                  {amenity && amenity.types.map(type=>{
                                    return(
                                    <tr>
                                      <td><span className="caps">{type.name} {type.customName ? `(${type.customName})` : ""}</span></td>
                                    </tr>
                                    )
                                  })}
                                </thead>
                              </table>
                            </td>
                            <td className="p0">
                            <table className="table">
                                <thead>
                                  {amenity && amenity.placements.map((placement,index)=>{
                                  return(
                                    <tr>
                                      <td>
                                        {
                                        placement.length > 0 ? placement.map((p,i)=>{
                                            return(
                                              <span className="caps">{p.name}<span className="customeStar">{p.custom ? "*" : ""}</span>{placement.length === i + 1 ? "" : ", "}</span>
                                            )
                                          })  
                                          :
                                          "-"
                                        }
                                      </td>
                                    </tr>
                                    )
                                  })}
                                </thead>
                              </table>
                            </td>
                          </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                    <span className="notetable"><span className="customeStar">*</span> Represents custom inventories/placements added for branding by school.</span>
                  </div>
                  <div className="moutableview  ">
                    <h3>2. Occasions/Events Details</h3>
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">Occasions/Events</th>
                          <th scope="col">Placements</th>
                          <th scope="col">Venue</th>
                          <th scope="col">Month</th>
                        </tr>
                      </thead>
                      <tbody>
                      {
                          activityTableData && activityTableData.map(activity=>{
                            return(
                              <tr>
                              <td><span className="caps">{activity.eventName}</span><span className="customeStar">{activity.custom ? "*" : ""}</span></td>
                              <td>
                                {
                                  activity.placements.map((p,i)=>{
                                    return(
                                      <span className="caps">{p.name}<span className="customeStar">{p.custom ? "*" : ""}</span>{activity.placements.length === i + 1 ? "" : ", "}</span>
                                    )
                                  })
                                }
                              </td>
                              <td>
                                {
                                  activity.venue.join(", ")
                                }
                              </td>
                              <td>{activity.month.join(", ")}</td>
                            </tr>
                            )
                          })
                        }
                      </tbody>
                    </table>
                    <span className="notetable"><span className="customeStar">*</span> Represents custom occassions/placements added for branding by school.</span>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`blurBackground`} style={{display: `${props.showMou ? "" : "none"}`}}></div>
    </>
  )
}

export default Mou
