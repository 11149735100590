import React, {useState} from 'react'
import campcreate from "../../assets/images/img2.png"
import Inventory from "../../publicSrc/pages/schools/updateDetails/inventory/inventoryList"
import imgInventory from "../../assets/images/blank-inventory-img.jpg"
import linkImg from "../../assets/images/linkImage.svg"
import { objectDeepClone } from "../../service/helper"
import CustomSearchInput from "../formFields/customSearchInput"

interface Props {
    showAlbumModal?: any,
    data?: any,
    closeWithoutSaving?: any,
    loading?: any,
    selectedData?: any,
    setSelectedData?: any,
    onCreateAlbum?: any,
    albumName?: any,
    setAlbumName?: any,
    creatingAlbum?: any,
    searchApprovedSet?: any,
    setSearchApprovedSet?: any,
    getSetsData?: any,
    reRouteToDetailPage?: any,
    editAlbum?: any
}
const CreateAlbumPopUp = ({
    showAlbumModal,
    data,
    closeWithoutSaving,
    loading,
    selectedData,
    setSelectedData,
    onCreateAlbum,
    albumName,
    setAlbumName,
    creatingAlbum,
    searchApprovedSet,
    setSearchApprovedSet,
    getSetsData,
    reRouteToDetailPage,
    editAlbum
}: Props) => {
    const [clearSearchIcon, setClearSearchIcon] = useState(false);
    const [searchInpuOpen, setSearchInpuOpen] = useState(false);
    // const [searchKeys, setSearchKeys] = useState("");

    const allSetsOfFosSelected = (index) => {
        if(selectedData.length === 0){
            return false
        }
        let notFound = false
        let fosData = data[index]
        fosData.setData.forEach(set => {
            let found = selectedData.find(item => item._id === set._id)
            if(!found){
                notFound = true
            }
        })
        return !notFound
    }
    const selectAllSetOfFos = (e, index) => {
        let array = [...selectedData]
        let fosData = data[index]
        if(e.target.checked){
            fosData.setData.forEach(set => {
                let found = selectedData.find(item => item._id === set._id)
                if(!found){
                    array.push(set)
                }
            })
        }
        else{
            fosData.setData.forEach(set => {
                array = [...array.filter(item => item._id !== set._id)]
            })
        }
        setSelectedData([...array])

    }
    const limitStringLength = (string: String, limit: number) => {
        return string.split("").slice(0, limit).join("")
    }
    const isSetSelected = (_id) => {
        if(selectedData.length === 0 ){
            return false
        }
        let itemFound = false
        let found = selectedData.find(set => set._id === _id)
        if(found){
            itemFound = true
        }
        return itemFound
    }
    const addRemoveSet = (e, set) => {
        let arr = [...selectedData]
        if(e.target.checked){
            arr.push(set)
        }
        else{
            arr = [...arr.filter(item => item._id !== set._id)]
        }
        setSelectedData([...arr])
    }
    const handleChange = (e) => {
        if(e === ""){
            setSearchApprovedSet("")
            getSetsData("")
        }
        else{
            setSearchApprovedSet(e.target.value)
            getSetsData(e.target.value)
        }
    }
    const onCloseModal = () => {
        setSearchApprovedSet("")
        setAlbumName("")
        setSelectedData([])
        setSearchInpuOpen(false)
        setClearSearchIcon(false)
        closeWithoutSaving()
    }
    return (
        <>
            <div className={`ovelap ${showAlbumModal === true ? "active" : ""}`}></div>
            <div id="inventoryMore" className={`popup ${showAlbumModal === false ? "hide" : ""}`}>
                <div className="popup-header">
                    <h3 className="htx2 pophead" style={{textAlign: "center"}}>{editAlbum ? "Edit Album" : "Create Album"}</h3>
                    <span className="close toggle" onClick={onCloseModal} data-target="myPopup">close</span>
                </div>
                <div className="popup-body">
                    <div className="viewdetailsHead">
                        <div className="invetorypopblock">
                            {!loading &&
                                <div className="row">
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-10">
                                        <h5 style={{ color: "gray" }}> Album name</h5>
                                    </div>
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-20">
                                        <input className='crCustomInput' type="text" placeholder="First Album" style={{ width: "600px" }} value={albumName} onChange={(e) => setAlbumName(e.target.value)} />
                                    </div>

                                    <div className="col-xl-10 col-lg-10 col-md-10 col-xs-12 mb-10">
                                        <h5 style={{ color: "gray" }}>{`Select Set(${selectedData.length})`}</h5>
                                    </div>

                                    <div className="col-xl-2 col-lg-2 col-md-2 col-xs-12 mb-10 pull-4">
                                        <CustomSearchInput
                                            searchInpuOpen={searchInpuOpen}
                                            setSearchInpuOpen={setSearchInpuOpen}
                                            clearSearchIcon={clearSearchIcon}
                                            setClearSearchIcon={setClearSearchIcon}
                                            handleChange={handleChange}
                                            searchKeys={searchApprovedSet}
                                            align="right"
                                        />
                                        {/* <h5 style={{ color: "gray", float: "right" }}> <i className="fa fa-search fa-lg" aria-hidden="true"></i> </h5> */}
                                    </div>
                                    {data.length > 0 && data.map((item, index) => (
                                        <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-10">
                                            <div className="mb-10">
                                                <input type="checkbox" id={`selectAll ${item.fosDetails._id}`} checked={allSetsOfFosSelected(index)} onClick={(e) => selectAllSetOfFos(e, index)} style={{ width: "12px", height: "12px", float: "left", marginRight: "3px" }} />
                                                <h5 style={{}}> {item.fosDetails.fullName} </h5>
                                            </div>
                                            <div style={{display: 'flex', flexWrap: 'wrap', marginLeft: "8px"}}>
                                                {item.setData.length > 0 && item.setData.map((set, setIndex) => (
                                                    <div className="" style={{ width: "136px", height: "60px", backgroundColor: set.isInAlbum ? "rgb(225, 225, 225)" : "white", borderRadius:"5px", margin: '0 12px 12px 0', padding: "8px", border: set.isInAlbum ? "none" : "1px solid #564c4c", opacity: set.isInAlbum && "0.5"}}>
                                                        <input type="checkbox" id={set._id} checked={isSetSelected(set._id)} onClick={(e) => addRemoveSet(e, set)} style={{ width: "12px", height: "12px", float: "right", marginTop:"5px"}} />
                                                        <h6 style={{margin:"8px 0"}}>{set.schoolDetails.schoolName.length > 15 ? `${limitStringLength(set.schoolDetails.schoolName, 15)}...` : set.schoolDetails.schoolName}</h6>
                                                        <div style={{display: 'flex', flexWrap: "wrap"}}>
                                                            <div style={{color:"#8A8A8A", fontSize: "10px", marginRight: "5px"}}>{set.setTitle}</div>
                                                            <img style={{cursor: "pointer"}} onClick={() => reRouteToDetailPage(set.schoolDetails._id, set.schoolDetails.schoolName)} height="10px" src={linkImg} />
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                                        {/* <div className="mb-10">
                                            <input type="checkbox" id='selectAll' style={{ width: "12px", height: "12px", float: "left", marginRight: "3px" }} />
                                            <h5 style={{}}> FOSmum </h5>
                                        </div>
                                        <div style={{display: 'flex', flexWrap: 'wrap', marginLeft: "8px"}}>
                                            <div className="" style={{ width: "125px", height: "60px", backgroundColor: "#F8F8F8", borderRadius:"5px", margin: '0 10px 10px 0', padding: "5px"}}>
                                                <input type="checkbox" style={{ width: "12px", height: "12px", float: "right", marginTop:"5px"}} />
                                                <h5 style={{marginTop:"8px"}}> Nandchhaya Vi... </h5>
                                                <h6 style={{color:"#8A8A8A"}}> First 22112022 </h6>
                                            </div>

                                            <div className="" style={{ width: "125px", height: "60px", backgroundColor: "#F8F8F8", borderRadius:"5px", margin: '0 10px 10px 0', padding: "5px"}}>
                                                <input type="checkbox" style={{ width: "12px", height: "12px", float: "right", marginTop:"5px"}} />
                                                <h5 style={{marginTop:"8px"}}> SSS Technical </h5>
                                                <h6 style={{color:"#8A8A8A"}}> First School </h6>
                                            </div>

                                            <div className="" style={{ width: "125px", height: "60px", backgroundColor: "#F8F8F8", borderRadius:"5px", margin: '0 10px 10px 0', padding: "5px"}}>
                                                <input type="checkbox" style={{ width: "12px", height: "12px", float: "right", marginTop:"5px"}} />
                                                <h5 style={{marginTop:"8px"}}> Don Bosco CBSE </h5>
                                                <h6 style={{color:"#8A8A8A"}}> MySET2 </h6>
                                            </div>

                                            <div className="" style={{ width: "125px", height: "60px", backgroundColor: "#F8F8F8", borderRadius:"5px", margin: '0 10px 10px 0', padding: "5px"}}>
                                                <input type="checkbox" style={{ width: "12px", height: "12px", float: "right", marginTop:"5px"}} />
                                                <h5 style={{marginTop:"8px"}}> Garodia High S... </h5>
                                                <h6 style={{color:"#8A8A8A"}}> Garodia High S... </h6>
                                            </div>

                                            <div className="" style={{ width: "125px", height: "60px", backgroundColor: "#F8F8F8", borderRadius:"5px", margin: '0 10px 10px 0', padding: "5px"}}>
                                                <input type="checkbox" style={{ width: "12px", height: "12px", float: "right", marginTop:"5px"}} />
                                                <h5 style={{marginTop:"8px"}}> Garodia High S... </h5>
                                                <h6 style={{color:"#8A8A8A"}}> 20112022 </h6>
                                            </div>
                                        </div> */}
                                    {/* Set box 1 starts */}

                                    

                                    {/* Set box 1 ends */}


                                    {/* <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-10">
                                        <div>
                                            <input type="checkbox" id='selectAll' style={{ width: "12px", height: "12px", float: "left", marginRight: "3px" }} />
                                            <h5 style={{}}> FOS21 </h5>
                                        </div>

                                    </div> */}

                                    {/* Set box 2 starts */}

                                    {/* <div className="col-xl-3 col-lg-3 col-md-3 col-xs-3 mb-10" style={{ width: "125px", height: "58px", backgroundColor: "#F8F8F8", marginLeft: "8px", borderRadius:"5px" }}>
                                        <input type="checkbox" style={{ width: "12px", height: "12px", float: "right", marginRight: "-3px",  marginTop:"5px"}} />
                                        <h5 style={{marginTop:"8px"}}> Nandchhaya Vi... </h5>
                                        <h6 style={{color:"#8A8A8A"}}> First 22112022 </h6>
                                    </div>

                                    <div className="col-xl-3 col-lg-3 col-md-3 col-xs-3 mb-10" style={{ width: "125px", height: "58px", backgroundColor: "#F8F8F8", marginLeft: "8px", borderRadius:"5px"}}>
                                        <input type="checkbox" style={{ width: "12px", height: "12px", float: "right", marginRight: "-3px",  marginTop:"5px"}} />
                                        <h5 style={{marginTop:"8px"}}> SSS Technical </h5>
                                        <h6 style={{color:"#8A8A8A"}}> First School </h6>
                                    </div>

                                    <div className="col-xl-3 col-lg-3 col-md-3 col-xs-3 mb-10" style={{ width: "125px", height: "58px", backgroundColor: "#F8F8F8", marginLeft: "8px", borderRadius:"5px"}}>
                                        <input type="checkbox" style={{ width: "12px", height: "12px", float: "right", marginRight: "-3px",  marginTop:"5px"}} />
                                        <h5 style={{marginTop:"8px"}}> Don Bosco CBSE </h5>
                                        <h6 style={{color:"#8A8A8A"}}> MySET2 </h6>
                                    </div>

                                    <div className="col-xl-3 col-lg-3 col-md-3 col-xs-3 mb-10" style={{ width: "125px", height: "58px", backgroundColor: "#F8F8F8", marginLeft: "8px", borderRadius:"5px"}}>
                                        <input type="checkbox" style={{ width: "12px", height: "12px", float: "right", marginRight: "-3px",  marginTop:"5px"}} />
                                        <h5 style={{marginTop:"8px"}}> Garodia High S... </h5>
                                        <h6 style={{color:"#8A8A8A"}}> Garodia High S... </h6>
                                    </div>

                                    <div className="col-xl-3 col-lg-3 col-md-3 col-xs-3 mb-10" style={{ width: "125px", height: "58px", backgroundColor: "#F8F8F8", marginLeft: "8px", borderRadius:"5px" }}>
                                        <input type="checkbox" style={{ width: "12px", height: "12px", float: "right", marginRight: "-3px",  marginTop:"5px"}} />
                                        <h5 style={{marginTop:"8px"}}> Garodia High S... </h5>
                                        <h6 style={{color:"#8A8A8A"}}> 20112022 </h6>
                                    </div> */}

                                    {/* Set box 2 ends */}
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-10" style={{textAlign:"center"}}>
                                        <button className={`btn btn-orange ${creatingAlbum ? "btn-disabled button--loading" : ""} ${albumName === "" || selectedData.length === 0 ? "btn-disabled" : ""}`} onClick={onCreateAlbum} >{editAlbum ? "Edit Album" : "Create Album"}</button>
                                    </div>
                                    
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default CreateAlbumPopUp