import { useState, useContext, useEffect } from "react"
import {useHistory} from 'react-router-dom';
import arrowUp from "../../../assets/images/arrow-up.svg"
import arrowDown from "../../../assets/images/arrow-down.svg"
import filterSvg from "../../../assets/images/filter-svg.svg"
import filterArrowSvg from "../../../assets/images/filter-arrow-svg.svg"
import AppContextObject from "../../../common/context/common"

import OnboardingBackBtn from "../schools/components/onboardingBackBtn"
import { PublicContextInitiator } from "../../apis/publicContextInitiator"
import BrandTable from "./components/BrandTable"
import SearchInput from "./components/SearchInput"
import CategoryFilterBrand from "./components/CategoryFilterBrand"
import BackendTopBar from "./components/backendTopBar";
import BackendBackBtn from "./components/backendBackBtn";

var backendBrandListGlobalVar = 1

const BrandList = () => {
    const context = useContext(AppContextObject)
    const history = useHistory()
    const [showModalapproveActive, setshowModalapproveActive] = useState(false)
    const [overlay, setOverlay] = useState(false)
    const [clearSearchIcon, setClearSearchIcon] = useState(false);
    const [searchInpuOpen, setSearchInpuOpen] = useState(false);
    const [searchKeys, setSearchKeys] = useState("");
    const [requestedInventoryName, setRequestedInventoryName] = useState("")
    const [openCategoryFilter, setOpenCategoryFilter] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState([]);
    const [selectedDocStatus, setSelectedDocStatus] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState([]);
    const [allBrandsList, setAllBrandsList] = useState([]);
    const [totalPageCount, setTotalPageCount] = useState<any>([0]);
    const [totalPage, setTotalPage] = useState([0]);
    const [currentPage, setCurrentpage] = useState(1);
    const [brandsStats, setBrandsStats] = useState<any>([]);
    const [isLoading,setIsLoading]=useState(false);
    const [stateList, setStateList] = useState([])
    const [cityList, setCityList] = useState([])
    const [selectedState, setSelectedState] = useState([])
    const [selectedCity, setSelectedCity] = useState([])

    useEffect(() => {
        getAllBrandList();
    }, [currentPage, searchKeys, selectedCategory, selectedDocStatus, selectedCity])

    useEffect(() => {
        getStatsOfBrands();
    }, [])

    useEffect(() => {
        getStateList()
    }, [])
    useEffect(() => {
        getCityList()
    }, [selectedState])
    const getCityList = async() => {
        let resp;
        if(selectedState.length > 0){
            resp = await context.apis.public.getCitiesByStateForBrand(
                context.validator, {states: selectedState}, {}
            )
            console.log("cityResp", resp)
            if(resp && resp.done){
                let city = []
                resp.response.rows.forEach(data => {
                    city = [...city, ...data.cities]
                })
                setCityList([...city])
            }
        }
        else{
            setSelectedCity([])
        }
    }
    const getStateList = async() => {
        let resp = await context.apis.public.getStatesForBrand(
            context.validator, {}, {}
        )
        console.log("state response",resp)
        if(resp && resp.done){
            let states = []
            resp.response.rows.forEach(data => {
                if(!states.includes(data.state) && data.state !== ""){
                    states.push(data.state)
                }
            })
            setStateList([...states])
        }
    }

    const getAllBrandList = async () => {
        let publicContextInitiator = new PublicContextInitiator(context);
        let resp;
        var localVar = Math.random()
        backendBrandListGlobalVar = localVar
        if (selectedCategory.length > 0) {
            setIsLoading(true);
            resp = await publicContextInitiator.getAllBrands(
                context.validator, { searchValue: searchKeys, city: selectedCity, documents: selectedDocStatus, brandType: selectedCategory }, { limit: 10, page: currentPage, withCount: true, sort: { "_id": -1 } }
            )

        } else {
            setIsLoading(true);
            resp = await publicContextInitiator.getAllBrands(
                context.validator, { searchValue: searchKeys, city: selectedCity, documents: selectedDocStatus }, { limit: 10, page: currentPage, withCount: true, sort: { "_id": -1 } }
            )

        }
        console.log('brands resp======>', resp)

        if (resp && resp.done) {
            if(backendBrandListGlobalVar === localVar){
                setAllBrandsList(resp.response);
                let total = Math.ceil(resp.count / 10);
                setTotalPageCount(resp.count);
                let arr = []
                for (let i = 0; i < total; i++) {
                    arr.push(i)
                }
                setTotalPage([...arr])
            }
        }
        setIsLoading(false);
    }

    const getStatsOfBrands = async () => {
        let publicContextInitiator = new PublicContextInitiator(context);
        let resp = await publicContextInitiator.getBrandStats(
            context.validator, {}, {}
        )
        console.log('brands stat resp=====>', resp)
        if (resp && resp.done) {
            setBrandsStats(resp.response)
        }
    }

    const onPageChange = (page) => {
        setCurrentpage(page)
    }
    const onNextClick = () => {
        setCurrentpage(prev => prev + 1)
    }
    const onPrevClick = () => {
        setCurrentpage(prev => prev - 1)
    }
    const redirectToBrandOnboarding = () =>{
        history.push("/backend/backend-details/brands/brand-details/general")
    }


    return (
        <>
            <div className={`ovelap ${overlay === true ? "active" : ""}`}></div>
            <div className="col-xl-10 col-lg-12 col-md-12 col-xs-12">
                <div className="rightPart noBack">
                    <div className="row">
                        <BackendTopBar />
                        <div className={`col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-20"`}>
                            <div className="inventory-over">
                                <ul className="inventory-overTabs">
                                    <li>
                                        <a href="javascript:void" className={(brandsStats.activeBrands - brandsStats.activeBrandsLastMonth) > 0 ? "greenUpPercentage" : (brandsStats.activeBrands - brandsStats.activeBrandsLastMonth) < 0 ? "redDownPercentage" : "removeArrow"}>
                                            <p>Active Brands (Last 30 Days)</p>
                                            <h3>{brandsStats.activeBrands} <span><b className={`${(brandsStats.activeBrands - brandsStats.activeBrandsLastMonth) >= 0 ? '' : 'redDownPercentage'}`}>{(brandsStats.activeBrands - brandsStats.activeBrandsLastMonth) >= 0 ? brandsStats.activeBrands - brandsStats.activeBrandsLastMonth : Math.abs(brandsStats.activeBrands - brandsStats.activeBrandsLastMonth)}
                                                <img className={(brandsStats.activeBrands - brandsStats.activeBrandsLastMonth) >= 0 ? "arrowUp" : "arrowDown"} src={(brandsStats.activeBrands - brandsStats.activeBrandsLastMonth) >= 0 ? arrowUp : arrowDown} />
                                            </b> than last 30 days</span>
                                            </h3>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="javascript:void">
                                            <p>Top Locations (Last 30 Days)</p>
                                            <h3>{brandsStats.topLocations && brandsStats.topLocations.charAt(0).toUpperCase() + brandsStats.topLocations.slice(1)}</h3>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="javascript:void">
                                            <p>Total Spends (Last 30 Days)</p>
                                            <h3>₹ {brandsStats.totalSpends && brandsStats.totalSpends.toLocaleString('en-IN')}</h3>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-5 col-lg-5 col-md-12 col-xs-12 mt-50 mb-10 mmt-0">
                            <h2 className="htx2 clearfix">List of Brands</h2>
                        </div>
                        <div className="col-xl-7 col-lg-7 col-md-12 col-xs-12 mt-40 mb-10 mmt-0">
                            <div className="inventorySearch schoolFilter inventoryBackAdmin">
                                <SearchInput
                                    searchInpuOpen={searchInpuOpen} //true/false
                                    clearSearchIcon={clearSearchIcon} //true/false
                                    setSearchInpuOpen={setSearchInpuOpen} //function=>true/false
                                    setSearchKeys={setSearchKeys} //function=>string
                                    searchKeys={searchKeys} //string
                                    setClearSearchIcon={setClearSearchIcon}//function=>true/false
                                    palaceholder='Company or brand name'
                                    setCurrentpage={setCurrentpage}
                                />
                                <div className="filterbox">
                                    <a href="javascript:void(0);" className="filterDrop" onClick={() => setOpenCategoryFilter(!openCategoryFilter)}>
                                        <img src={filterSvg} />
                                        <h3>Filter</h3>
                                        <img src={filterArrowSvg} />
                                    </a>
                                    {openCategoryFilter && <CategoryFilterBrand
                                        setSelectedCategory={setSelectedCategory}
                                        setSelectedDocStatus={setSelectedDocStatus}
                                        setSelectedLocation={setSelectedLocation}
                                        setSelectedState={setSelectedState}
                                        setSelectedCity={setSelectedCity}
                                        setOpenCategoryFilter={setOpenCategoryFilter}
                                        selectedState={selectedState}
                                        selectedCity={selectedCity}
                                        stateList={stateList}
                                        cityList={cityList}
                                        selectedCategory={selectedCategory}
                                        selectedDocStatus={selectedDocStatus}
                                        selectedLocation={selectedLocation}
                                        setCurrentpage={setCurrentpage}
                                    />
                                    }

                                </div>
                               {(context.user.role === "backendAdmin" || context.user.role === "backendTech" || context.user.role === "backendSales") && <a href="javascript:void(0);" className="btn btn-orange spaceL" onClick={redirectToBrandOnboarding} >+ Add Brand</a>}

                            </div>
                        </div>
                        <BrandTable
                            allBrandsList={allBrandsList}
                            currentPage={currentPage}
                            totalPage={totalPage}
                            onPageChange={onPageChange}
                            onNextClick={onNextClick}
                            onPrevClick={onPrevClick}
                            isLoading={isLoading}
                            totalPageCount={totalPageCount}
                        />

                    </div>
                </div>
            </div>
            <div id="approveActive" className={`popup popup-center ${showModalapproveActive === false ? "hide" : ""}`}>
                <div className="popup-header">
                    <h3 className="htx2 pophead">Request Sent Successfully!</h3>
                    <span className="close toggle" data-target="myPopup">close</span> </div>
                <div className="popup-body">
                    <div className="viewdetailsHead">
                        <div className="approveActive">
                            <p>A request to add {requestedInventoryName} has been sent to your school's admin. </p>
                            <div className="brand-btn">
                                <a href="javascript:void(0)" className="btn btn-orange">DONE</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}


export default BrandList
