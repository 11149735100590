import React, { useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import arrowUpDown from "../../../../assets/images/backendAdmin/arrow-up-down.svg";
import tableFilter from "../../../../assets/images/table-filter.svg";
import Pagination from "../../../../components/addon/pagination";
import { returnReadableStatus } from "../../../../service/helper";
import useClickOutside from "../../../../service/useClickOutside";
import SchoolInventoryStatusFilter from "./SchoolInventoryStatusFilter";
import SchoolsNameCategoryFilter from "./SchoolsNameCategoryFilter";
import SchoolStatusFilter from "./schoolStatusFilter";
// import "./font-awesome.min.css";


const SchoolTable = ({ isLoading, allSchoolList, currentPage, totalPage, onPageChange, onNextClick, 
    onPrevClick, category, setCategory, setInventoryStatus, setAlphabaticOrder, alphabaticOrder, 
    inventoryStatus,city,setLocation,location,locationByCities, sortingData, setSortingData,count, setSchoolStatus, schoolStatus }) => {

    const history = useHistory()
    const [openShoolNameFilter, setOpenShoolNameFilter] = useState(false);
    const [openShoolInventoryFilter, setOpenShoolInventoryFilter] = useState(false);
    const [openShoolStatusFilter, setOpenShoolStatusFilter] = useState(false);
    const ref = useRef(null);
    // const schoolStatusRef = useRef(null);

    useClickOutside(ref, () => { setOpenShoolInventoryFilter(false); setOpenShoolNameFilter(false); setOpenShoolStatusFilter(false) })
    // useClickOutside(schoolStatusRef, () => { setOpenShoolInventoryFilter(false); setOpenShoolNameFilter(false); })
    console.log(totalPage)
    return (
        <div className="table-container table-inventory mt-10 fillterTableHeight">
            <table className="table">
                <thead>
                    <tr ref={ref}>
                        <th>Sl no</th>
                        <th className="p-relative">school name <a href="javascript:void(0);"><img src={tableFilter} onClick={() => setOpenShoolNameFilter(!openShoolNameFilter)} /></a>

                            <SchoolsNameCategoryFilter
                                openShoolNameFilter={openShoolNameFilter}
                                setCategory={setCategory}
                                alphabaticOrder={alphabaticOrder}
                                setAlphabaticOrder={setAlphabaticOrder}
                                sortingData={sortingData}
                                setSortingData={setSortingData}
                                category={category}                                
                                city={city}
                                setLocation={setLocation}
                                location={location}
                                locationByCities={locationByCities}
                            />
                        </th>
                        <th>students</th>
                        <th>inventories</th>
                        <th>events & occasions</th>
                        <th>CAMPAIGNS RUN</th>
                        <th className="p-relative">School Status <a href="javascript:void(0);"><img src={tableFilter} onClick={() => setOpenShoolStatusFilter(!openShoolStatusFilter)} /></a>
                            <SchoolStatusFilter
                                openStatusFilter={openShoolStatusFilter}
                                setFilterStatus={setSchoolStatus}
                                filterStatus={schoolStatus}
                            />
                        </th>
                        <th className="p-relative">inventory status <a href="javascript:void(0);"><img src={tableFilter} onClick={() => setOpenShoolInventoryFilter(!openShoolInventoryFilter)} /></a>
                            <SchoolInventoryStatusFilter
                                openShoolInventoryFilter={openShoolInventoryFilter}
                                setInventoryStatus={setInventoryStatus}
                                inventoryStatus={inventoryStatus}
                            />
                        </th>

                    </tr>
                </thead>
                <tbody>
                    {!isLoading ? allSchoolList && allSchoolList.length > 0 ? allSchoolList.map((school, key) => {
                        // console.log("Schoooool ", school);
                        return (
                            
                            <tr key={key}>
                                <td>{(key + 1) + (currentPage - 1) * 10}</td>
                                <td>
                                    <div className="schoolNameTable companyName TEST">
                                        {/* <h1 style={{color:"red"}}> {school.duplicateEntrySeverity} </h1> */}
                                        
                                        {/* <h4>{school.schoolName} {school.duplicateEntrySeverity=="none" ? "RED" : school.duplicateEntrySeverity=="high" ? "GREEN" : school.duplicateEntrySeverity=="low" ? "YELLOW" : "NOTHING"}   */}
                                        <h4>{school.schoolName} {school.duplicateEntrySeverity=="high" ? <i className='fa fa-solid fa-flag' style={{color:'red', fontSize:"10px", paddingLeft:"5px"}}></i> : school.duplicateEntrySeverity=="medium" ? <i className='fa fa-solid fa-flag' style={{color:'orange', fontSize:"10px", paddingLeft:"5px"}}></i> : school.duplicateEntrySeverity=="low" ? <i className='fa fa-solid fa-flag' style={{color:'yellow', fontSize:"10px", paddingLeft:"5px"}}></i> : " "}  </h4>
                                        {school.board && <span>{school.board.toUpperCase()}</span>}{school.category && <span>{`Cat ${school.category}`}</span>}<span>{returnReadableStatus(school.city)}</span>{school.localArea && <span>{school.localArea}</span>}
                                    </div>
                                </td>
                                <td>{school.totalStudents}</td>
                                <td>{school.inventories}</td>
                                <td>{school.activities}</td>
                                <td>{school.campaigns}</td>
                                <td>
                                    <span className={`table-status ${school.initialApproval ? 'greenbg' : "orangebg"}`}> {school.initialApproval ? "Approved" : "New Added"}</span>
                                </td>
                                <td>
                                    <span className={`table-status ${school.collectiveInventoryStatus === "In-review" ? 'yellowbg' : school.collectiveInventoryStatus === "Approved" ? 'greenbg' : school.collectiveInventoryStatus === "Rejected" ? "redbg" : school.collectiveInventoryStatus === "New Added" ? "orangebg" : ""}`}>
                                        {school.collectiveInventoryStatus}
                                    </span>
                                    {/* <img onClick={() => history.push({ pathname: "/backend/backend-details/school-details", state: { schoolId: school._id } })} className="updownarrow" src={arrowUpDown} /> */}
                                    <img onClick={() => window.open("/backend/backend-details/school-details?"+ school._id) } className="updownarrow" src={arrowUpDown} />
                                </td>
                            </tr>
                        )
                    })
                        :
                        <tr><td colSpan={8}>No School found</td></tr>
                        :
                        [...Array(10)].map((el, index) => {
                            return (
                                <tr>
                                    {
                                        [...Array(8)].map((el, index) => {
                                            return (
                                                <td key={index}>
                                                    <div className="content-loader-block"></div>
                                                </td>
                                            )
                                        })
                                    }
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
            {totalPage.length > 1 &&
                <Pagination
                    currentPage={currentPage}
                    totalPage={totalPage}
                    onPageChange={onPageChange}
                    onNextClick={onNextClick}
                    onPrevClick={onPrevClick}
                    loading={isLoading}
                />
            }
        </div>

    )
}
export default SchoolTable;

