import { useState, useContext, useEffect, useRef } from "react"
import SimpleReactValidator from "simple-react-validator";
import { Link, useHistory, BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom"
import moment from 'moment';
// import arrowUp from "../../../assets/images/arrow-up.svg"
// import arrowDown from "../../../assets/images/arrow-down.svg"
// import errorSVG from "../../../assets/images/error.svg";
// import closeCustome from "../../../assets/images/close-1.svg";


// import AppContextObject from "../../../common/context/common"

// import OnboardingBackBtn from "../schools/components/onboardingBackBtn"
// import { PublicContextInitiator } from "../../apis/publicContextInitiator"
import CampaignReportingTable from "./campaignReportingTable"
// import SingleSelect from "../../../components/formFields/singleSelect/singleSelect"
// import SearchInput from "./campaignReporting/backendAdmin/pages/publicSrc/components/SearchInput";
import CampaignSearchInput from './CampaignSearchInput';
// import config from "../../../service/config";
// import CampaignRejetionModal from "./components/CampaignRejectionModal";
// import CustomDatePicker from "../../../components/formFields/datePicker";
// import BackendTopBar from "./components/backendTopBar";
// import BackendBackBtn from "./components/backendBackBtn";
// import ArrowView from "../../../assets/images/arrow-view.svg"
import CampaignSchoolList from './CampaignSchoolList';
import './style.css';
import { on } from "events";
const { detect } = require('detect-browser');

var backendCampaignListGlobalVar = 1

const CampaignReportingSetList = ({ onlyActiveCampaign }) => {
    // const context = useContext(AppContextObject)
    const history = useHistory()
    const [overlay, setOverlay] = useState(false);
    const [clearSearchIcon, setClearSearchIcon] = useState(false);
    const [searchInpuOpen, setSearchInpuOpen] = useState(false);
    const [searchKeys, setSearchKeys] = useState("");
    const [formData, setFormData] = useState({ dateFilter: "", dateCreatedFilter: "" });
    const [campaigns, setCamnpaigns] = useState<any>([]);
    const [category, setCategory] = useState([]);
    const [region, setRegion] = useState([]);
    const [states, setStates] = useState([])
    const [location, setLocation] = useState([]);
    const [campaignStat, setCampaignStat] = useState<any>({});
    const [filterType, setFilterType] = useState("");
    const [filterStatus, setFilterStatus] = useState([]);
    const [selectedTab, setSelectedTab] = useState("all");
    const [allCount, setAllCount] = useState<any>({});
    const [paymentStatus, setPaymentStatus] = useState([]);
    const [totalPageCount, setTotalPageCount] = useState<any>([0]);
    const [totalPage, setTotalPage] = useState([0]);
    const [currentPage, setCurrentpage] = useState(1);
    const [isApproved, setIsApproved] = useState(null);
    const [flag, setFlag] = useState(false);
    const [openRejectedModal, setOpenRejectedModal] = useState(false);
    const [rejectionMsg, setRejectionMsg] = useState({ reason: "" });
    const [campaignId, setCampaignId] = useState<any>({});
    const [update, forceUpdate] = useState(false);
    const [customSheduledFilter, setCustomSheduledFilter] = useState(false);
    const [customCreatedFilter, setCustomCreatedFilter] = useState(false);
    const [stateByRegion, setStateByRegion] = useState([])
    const [citiesByState, setCitiesByState] = useState([]);
    const [tabStatus, setTabStatus] = useState('');
    const [openShoolFilter, setOpenShoolFilter] = useState(false);
    const [openStatusFilter, setOpenStatusFilter] = useState(false);
    const [openPaymentFilter, setOpenPaymentFilter] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [scheduleSearch, setScheduleSearch] = useState(false);
    const [createSearch, setCreateSearch] = useState(false);
    const [acceptingCampaign, setAcceptingCampaign] = useState(false)
    const [rejectingCampaign, setRejectingCampaign] = useState(false)
    const [formSheduledDataEmpty, setFormSheduledDataEmpty] = useState(false)
    const [formCreatedDataEmpty, setFormCreatedDataEmpty] = useState(false)
    const [initialiseCount, setInitialiseCount] = useState(false)
    const [formSheduledData, setFormSheduledData] = useState({
        startSheduledDate: "",
        endSheduledDate: ""
    })
    const [formSheduledDataDiffFormat, setFormSheduledDataDiffFormat] = useState({
        startSheduledDate: "",
        endSheduledDate: ""
    })
    const [formCreatedData, setFormCreatedData] = useState({
        startCreatedDate: "",
        endCreatedDate: ""
    })
    const [formCreatedDataDiffFormat, setFormCreatedDataDiffFormat] = useState({
        startCreatedDate: "",
        endCreatedDate: ""
    })
    const [createedFilterErr, setCreatedFilterErr] = useState({
        startDate: false,
        endDate: false
    })
    const [createedFilterErrMsg, setCreatedFilterErrMsg] = useState({
        startDateMsg: "",
        endDateMsg: ""
    })
    const [sheduledFilterErr, setSheduleddFilterErr] = useState({
        startDate: false,
        endDate: false
    })
    const [sheduledFilterErrMsg, setSheduledFilterErrMsg] = useState({
        startDateMsg: "",
        endDateMsg: ""
    })
    const [formValidator] = useState(useRef(new SimpleReactValidator({
        element: (message) =>

            <span className="valError"><img src="" />{message}</span>
    })))
    const [isChrome, setIsChrome] = useState(true)
    const [schoolId, setSchoolId] = useState<any>()

    const hideModal = () => {
        setOpenRejectedModal(false);
        setOverlay(false);
        setRejectionMsg({ reason: "" });
    }
    useEffect(() => {
        if (customSheduledFilter && (formSheduledData.startSheduledDate === "" || formSheduledData.endSheduledDate === "")) {
            setFormSheduledDataEmpty(true)
        }
        else {
            setFormSheduledDataEmpty(false)
        }
    }, [formSheduledData, formData.dateFilter])
    useEffect(() => {
        if (customCreatedFilter && (formCreatedData.startCreatedDate === "" || formCreatedData.endCreatedDate === "")) {
            setFormCreatedDataEmpty(true)
        }
        else {
            setFormCreatedDataEmpty(false)
        }
    }, [formCreatedData, formData.dateCreatedFilter])
    useEffect(() => {
        if (update === true) {
            formValidator.current.showMessages()
        } else {
            formValidator.current.hideMessages()
        }
    }, [update])

    // useEffect(() => {
    //     if(onlyActiveCampaign === false){
    //         getCampaignStat();
    //     }
    //     let state:any = history.location.state
    //     if(state && state?.fromPage === "fos-active-schools" && state?.schoolId && onlyActiveCampaign){
    //         console.log("reached")
    //         setSchoolId(state.schoolId)
    //     }
    //     const browser = detect();
    //     if (browser) {
    //         // console.log("browser...", browser.name)
    //         setIsChrome(browser.name === "chrome")
    //     }
    // }, [])

    useEffect(() => {
        console.log(formData.dateFilter, scheduleSearch, formData.dateCreatedFilter, createSearch)
        let state: any = history.location.state
        if (state && state?.fromPage === "fos-active-schools" && state?.schoolId && onlyActiveCampaign) {
            console.log("reached")
            getCampaignList(state.schoolId);
        }
        else {
            if ((formData.dateFilter !== 'custom' && formData.dateCreatedFilter !== 'custom')) {
                console.log('111111')
                getCampaignList();
            } else if ((formData.dateFilter === 'custom' && scheduleSearch === true)) {
                console.log('22222')
                getCampaignList();
            } else if ((formData.dateCreatedFilter === 'custom' && createSearch === true)) {
                console.log('3333333')
                getCampaignList();
            }
            else if ((formData.dateFilter === 'last_thirty_day' || formData.dateFilter === 'this_month' ||
                formData.dateFilter === 'last_month' || formData.dateFilter === 'last_three_month' ||
                formData.dateFilter === 'last_six_month') && formData.dateCreatedFilter === 'custom') {
                getCampaignList();
            }
            else if ((formData.dateCreatedFilter === 'last_thirty_day' || formData.dateCreatedFilter === 'this_month' ||
                formData.dateCreatedFilter === 'last_month' || formData.dateCreatedFilter === 'last_three_month' ||
                formData.dateCreatedFilter === 'last_six_month') && formData.dateFilter === 'custom') {
                getCampaignList();
            }
        }
        // else if((formData.dateFilter==='custom' && formData.dateCreatedFilter!=='custom')){
        //     // console.log('44444444')
        //     // if(formData.dateFilter==='custom' && scheduleSearch===true){
        //         console.log('555555555')
        //         getCampaignList();

        //     // }
        // }
        // else if((formData.dateFilter!=='custom' && formData.dateCreatedFilter==='custom')){
        //     // console.log('666666666')
        //     // if(formData.dateCreatedFilter==='custom' && createSearch===true){
        //         console.log('77777777')
        //         getCampaignList();

        //     // }
        // }
    }, [currentPage, scheduleSearch, createSearch, searchKeys, formData, filterType, paymentStatus, category, location, isApproved, flag, filterStatus, tabStatus, formSheduledData, formCreatedData])
    useEffect(() => {
        if (currentPage !== 1) {
            setCurrentpage(1)
        }
    }, [scheduleSearch, createSearch, searchKeys, formData, filterType, paymentStatus, category, location, isApproved, flag, filterStatus, tabStatus, formSheduledData, formCreatedData])
    // useEffect(() => {
    //     getCampaignList()
    // }, [])

    // useEffect(() => {
    //     if (region.length > 0) {
    //         fetchStateByRegion();
    //     }
    // }, [region])
    // useEffect(() => {
    //     if (states.length > 0) {
    //         fetchCitiesByState();
    //     }
    // }, [states])

    // const fetchCitiesByState = async () => {
    //     let resp = await context.apis.public.getCitiesByState(
    //         context.validator, { states: states }, {}
    //     )
    //     console.log(resp);
    //     if (resp && resp.done) {
    //         let cities = []
    //         Array.isArray(resp.response.rows) && resp.response.rows.forEach(row => {
    //             cities = [...cities, ...row.cities]
    //         })
    //         setCitiesByState([...cities])
    //         console.log(cities);
    //     }
    // }

    // const fetchStateByRegion = async () => {
    //     let publicContextInitiator = new PublicContextInitiator(context);
    //     let resp = await publicContextInitiator.getStatesByCountryRegion(
    //         context.validator, { countryRegion: region }, {}
    //     )
    //     console.log('cities resp======>', resp)
    //     if (resp && resp.done) { 
    //         let states = []
    //         Array.isArray(resp.response.rows) && resp.response.rows.forEach(row => {
    //             states = [...states, ...row.states]
    //         })
    //         setStateByRegion([...states])
    //     }
    // }
    const acceptRejectPayment = async (val, campaignId) => {
        setCampaignId(campaignId);
        if (val === 'rejected') {
            setOpenRejectedModal(true);
            setOverlay(true);
            // setFlag(!flag);
        }
        // else if (val === 'accepted') {
        //     // setFlag(!flag);
        //     setAcceptingCampaign(true)
        //     let resp = await context.apis[context.user.role].updateCampaignStatus(
        //         context.validator, { _id: campaignId._id }, { isApproved: true, campaignStatus: 'in-review' }
        //     )
        //     console.log('approved resp======>', resp)
        //     if (resp && resp.done) {
        //         setAcceptingCampaign(false)
        //         setFlag(!flag);
        //     }
        // }
    }
    const rejectPayment = async () => {
        const result = formValidator.current.allValid();
        if (!result) {
            formValidator.current.showMessages()
            forceUpdate(true)
            return
        } else {
            formValidator.current.hideMessages();
            setRejectingCampaign(true)
            let resp
            // if(campaignId.campaignCreativesRevised && campaignId.campaignCreativesRevised.length > 0){
            //     resp = await context.apis[context.user.role].updateCampaignStatus(
            //         context.validator, { _id: campaignId._id }, { isApproved: false, campaignStatus: 'in-review', creativeReworkNeeded: true, rejectionMessage: rejectionMsg.reason }
            //     )
            // }
            // else{
            //     resp = await context.apis[context.user.role].updateCampaignStatus(
            //         context.validator, { _id: campaignId._id }, { isApproved: false, campaignStatus: "rejected", rejectionMessage: rejectionMsg.reason }
            //     )
            // }
            console.log('reject resp======>', resp)

            if (resp && resp.done) {
                setOpenRejectedModal(false);
                setAcceptingCampaign(false)
                setRejectingCampaign(false)
                setRejectionMsg({ reason: "" });
                setRejectingCampaign(false);
                setOverlay(false);
                setFlag(!flag);
            }
        }
    }

    // const getCampaignStat = async () => {
    //     let publicContextInitiator = new PublicContextInitiator(context);
    //     let resp = await publicContextInitiator.getCampaignStatsForBackendAdmin(
    //         context.validator, {}, {}
    //     )
    //     console.log('stat resp======>', resp)
    //     if (resp && resp.done) {
    //         setCampaignStat(resp.response)
    //     }
    // }
    // useEffect(() => {
    //     getCount();
    // }, [])
    // const getCount = async () => {
    //     let resp = await context.apis.public.getCampaignsByBrandExternal(
    //         context.validator, {},
    //         { limit: 10, page: currentPage, withCount: true, "sort": { "_id": -1 } }
    //     )

    //     console.log('campaigns resp======>', resp)

    //     if (resp && resp.done) {
    //         setAllCount(resp['allCounts'])
    //     }
    // }

    const getCampaignList = async (_id?) => {
        setIsLoading(true);
        var localVar = Math.random()
        backendCampaignListGlobalVar = localVar
        let resp;
        // if(filterStatus.length > 0){
        //     if(onlyActiveCampaign === true && context.user.role === "backendFOS") {
        //         resp = await context.apis[context.user.role].getCampaignsBySchoolExternal(
        //             context.validator, {schoolId: _id}, { limit: 10, page: currentPage, withCount: true, "sort": { "_id": -1 } }
        //         )
        //     }
        //     else{
        //         resp = await context.apis.public.getCampaignsByBrandExternal(
        //             context.validator, {
        //             searchValue: searchKeys, dateFilter: formData.dateFilter, dateCreatedFilter: formData.dateCreatedFilter,
        //             startDate: formSheduledData, createdDate: formCreatedData, status: filterStatus, paymentStatus: paymentStatus,
        //             school: { category: category, city: location }, tabStatus: tabStatus
        //         },
        //             { limit: 10, page: currentPage, withCount: true, "sort": { "_id": -1 } }
        //         )
        //     }
        // }
        // else{
        //     if(onlyActiveCampaign === true && context.user.role === "backendFOS") {
        //         resp = await context.apis[context.user.role].getCampaignsBySchoolExternal(
        //             context.validator, {schoolId: _id}, { limit: 10, page: currentPage, withCount: true, "sort": { "_id": -1 } }
        //         )
        //     }
        //     else{
        //         resp = await context.apis.public.getCampaignsByBrandExternal(
        //             context.validator, {
        //             searchValue: searchKeys, dateFilter: formData.dateFilter, dateCreatedFilter: formData.dateCreatedFilter,
        //             startDate: formSheduledData, createdDate: formCreatedData, status: ['in-review', 'rejected', 'accepted', 'executed', 'on-hold', 'ongoing', "scheduled"], paymentStatus: paymentStatus,
        //             school: { category: category, city: location }, tabStatus: tabStatus
        //         },
        //             { limit: 10, page: currentPage, withCount: true, "sort": { "_id": -1 } }
        //         )
        //     }
        // }

        console.log('campaigns resp======>', resp)

        if (resp && resp.done) {
            if (localVar === backendCampaignListGlobalVar) {
                // setAllCount(resp['allCounts'])
                setCamnpaigns(resp.response.rows)
                let total = Math.ceil(resp.response.count / 10);
                setTotalPageCount(resp.response.count)
                if (initialiseCount === false) {
                    setAllCount(resp['allCounts'])
                    setInitialiseCount(true)
                }
                else {
                    setAllCount({ ...allCount, [selectedTab === "in-review" ? "inReview" : selectedTab === "pending" ? "pendingPayment" : selectedTab]: resp.response.count })
                }
                let arr = []
                for (let i = 0; i < total; i++) {
                    arr.push(i)
                }
                setTotalPage([...arr])
            }
        }
        setIsLoading(false)
    }
    const onCategoryChange = (data) => {
        setCurrentpage(1);
        setCreateSearch(false);
        setCustomSheduledFilter(false);
        setFormData({ ...formData, dateFilter: data.value });
        if (data.value === 'custom') {
            setCustomSheduledFilter(true);
        }
    }
    const onCategoryChange2 = (data) => {
        setScheduleSearch(false)
        setCustomCreatedFilter(false);
        setFormData({ ...formData, dateCreatedFilter: data.value });
        if (data.value === 'custom') {
            setCustomCreatedFilter(true);
        }
    }
    const onPageChange = (page) => {
        setCurrentpage(page)
    }
    const onNextClick = () => {
        setCurrentpage(prev => prev + 1)
    }
    const onPrevClick = () => {
        setCurrentpage(prev => prev - 1)
    }
    const searchCreateDate = () => {
        var d1 = new Date();
        var d1Date = d1.getFullYear() + '-' + (d1.getMonth() + 1) + '-' + d1.getDate();
        var d2 = new Date(formCreatedData.startCreatedDate);
        var d2Ddate = d2.getFullYear() + '-' + (d2.getMonth() + 1) + '-' + d2.getDate();
        var d3 = new Date(formCreatedData.endCreatedDate);
        var d3Ddate = d3.getFullYear() + '-' + (d3.getMonth() + 1) + '-' + d3.getDate();
        if (formCreatedData.startCreatedDate === '') {
            setCreatedFilterErr({ ...createedFilterErr, startDate: true });
            setCreatedFilterErrMsg({ ...createedFilterErrMsg, startDateMsg: 'Please add a date' })
        }
        else if (formCreatedData.endCreatedDate === '') {
            setCreatedFilterErr({ ...createedFilterErr, endDate: true });
            setCreatedFilterErrMsg({ ...createedFilterErrMsg, endDateMsg: 'Please add a date' })
        }
        else if (d1Date < d2Ddate) {
            setCreatedFilterErr({ ...createedFilterErr, startDate: true });
            setCreatedFilterErrMsg({ ...createedFilterErrMsg, startDateMsg: 'should less than today date1' })
        }
        else if (d1Date < d3Ddate) {
            setCreatedFilterErr({ ...createedFilterErr, endDate: true });
            setCreatedFilterErrMsg({ ...createedFilterErrMsg, endDateMsg: 'should less than today date' })
        }
        else {
            setCustomCreatedFilter(false);
            setFlag(!flag);
        }
    }
    const searchSheduleDate = () => {
        if (formSheduledData.startSheduledDate === '') {
            setSheduleddFilterErr({ ...sheduledFilterErr, startDate: true });
            setSheduledFilterErrMsg({ ...sheduledFilterErrMsg, startDateMsg: 'Please add a date' })
        }
        else if (formSheduledData.endSheduledDate === '') {
            setSheduleddFilterErr({ ...sheduledFilterErr, endDate: true });
            setSheduledFilterErrMsg({ ...sheduledFilterErrMsg, endDateMsg: 'Please add a date' })
        }
        else {
            setCustomSheduledFilter(false);
            setFlag(!flag);
        }
    }
    const onScheduleDateChange = (event, id) => {
        if (isChrome) {
            setFormSheduledData({ ...formSheduledData, [event.target.id]: event.target.value });
            setFormSheduledDataDiffFormat({ ...formSheduledDataDiffFormat, [event.target.id]: event.target.value });
        }
        else {
            setFormSheduledData({ ...formSheduledData, [id]: moment(event).format("yyyy-MM-DD") });
            setFormSheduledDataDiffFormat({ ...formSheduledDataDiffFormat, [id]: event })
        }
    }
    const onCreatedDateChange = (event, id) => {
        if (isChrome) {
            setFormCreatedData({ ...formCreatedData, [event.target.id]: event.target.value });
            setFormCreatedDataDiffFormat({ ...formCreatedDataDiffFormat, [event.target.id]: event.target.value });
        }
        else {
            setFormCreatedData({ ...formCreatedData, [id]: moment(event).format("yyyy-MM-DD") });
            setFormCreatedDataDiffFormat({ ...formCreatedDataDiffFormat, [id]: event })
        }
    }



    console.log(scheduleSearch)
    return (
        <>

            <Router>
                <Switch>
                    <Route exact path="/SchoolList" component={CampaignSchoolList} />
                </Switch>
            </Router>

            <div className="" style={{ width: "100%" }}>
                <div className="logo-header">
                    {/* <div className="logos back-arrow-logo">
                    <img className="back-arrow" src="/assets/FOSimg/back-arrow.png" />
                    </div> */}
                    <div className="logos">
                        <img className="back-arrow" src="/assets/FOSimg/back-arrow.png" />
                        <img className="wisrLogo" src="/assets/FOSimg/wisr-logo.png" />
                    </div>
                    <div className="logos">
                        <img className="maskgrouplogo" src="/assets/FOSimg/maskgrouplogo.png" />
                    </div>
                </div>


                <div className="ws-container">

                    <div className="heading headTitleContainer">
                        <div className="headTitle headerTitleText">
                            <h2>Campaign Reporting</h2>
                            <p style={{ position: "absolute", left: "13px", color: "#4A4A4A", marginTop: "2px" }}>Camp SA-SA School</p>
                            <br />
                        </div>


                    </div>

                    {/* CardBox starts  */}

                    <div className="panel-group" id="accordion">

                        {/* <!-- First Panel --> */}
                        <div className="panel panel-default">
                            <div className="panel-heading">
                                <h4 className="panel-title title-text-head" data-toggle="collapse" data-target="#collapseOne"><span>SET 1 </span> </h4>




                            </div>

                            <div id="collapseOne" className="panel-collapse collapse">
                                <div className="panel-body" style={{ border: 'none', backgroundColor: "#f5f5f5", marginTop: "-30px" }}>
                                    {/* <hr className="camp-list-hr" /> */}



                                    {/* <div className="cl-row"> */}
                                        <div className="col-xs-12 s-bg">
                                            <div className="col-xs-6" style={{marginTop:"15px"}}>
                                                HallWay 1
                                            </div>

                                            <div className="col-xs-6">
                                                <div className="cl-row">
                                                    <div className="col-xs-4 no-margin">
                                                        <img  src="/assets/FOSimg/schoolSetList.jpg" />
                                                    </div>

                                                    <div className="col-xs-4 no-margin">
                                                        <img src="/assets/FOSimg/schoolSetList.jpg" />
                                                    </div>
                                                    <div className="col-xs-4 no-margin">
                                                        <img src="/assets/FOSimg/add-button.png" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    {/* </div> */}

                                    
                                    <div className="col-xs-12 s-bg">
                                            <div className="col-xs-6" style={{marginTop:"15px"}}>
                                                School Gate 1
                                            </div>

                                            <div className="col-xs-6">
                                                <div className="cl-row">
                                                    <div className="col-xs-4 no-margin">
                                                        <img  src="/assets/FOSimg/schoolSetList.jpg" />
                                                    </div>

                                                    <div className="col-xs-4 no-margin">
                                                        <img src="/assets/FOSimg/schoolSetList.jpg" />
                                                    </div>
                                                    <div className="col-xs-4 no-margin">
                                                        <img src="/assets/FOSimg/add-button.png" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>





                                </div>
                            </div>
                        </div>
                    </div>

                    {/*  working row starts */}

                    {/* <div className="cl-row">
                            <div className="col-xs-12">
                                <div className="col-xs-6">
                                    HallWay 1
                                </div>

                                <div className="col-xs-6">
                                   <div className="cl-row">
                                     <div className="col-xs-4">
                                     <img className="maskgrouplogo" src="/assets/FOSimg/schoolSetList.jpg" />
                                     </div>

                                     <div className="col-xs-4">
                                     <img className="maskgrouplogo" src="/assets/FOSimg/schoolSetList.jpg" />
                                     </div>
                                     <div className="col-xs-4">
                                     <img className="maskgrouplogo" src="/assets/FOSimg/add-button.png" />
                                     </div>
                                   </div>
                                </div>
                            </div>
                        </div> */}
                    {/* Working row ends  */}
                </div>
            </div>
        </>
    )
}


export default CampaignReportingSetList
