import React, { useState, useEffect, useContext } from "react"
import hotkeys from 'hotkeys-js';
import {
    Route,
    Switch,
    useRouteMatch,
    useHistory
} from "react-router-dom"

import BrandsSideMenu from "./components/sidemenu"
import Profile from "./profile/profile"
import OnGoingCampaigns from "./onGoingCampaigns"

import { DataContextProvider } from "../../../service/brandContext"
import BrandsSupport from "./support"
import BrandSettings from "./setting"
import Campaigns from "./campaign"
import CampaignDetails from "./campaignDetails"
import Schools from "./school"
import OptimisedSchools from "./optimisedSchool"
import Dashboard from "./dashboard"
import CreateCampaign from "./createCampaign"
import AppContextObject from "../../../common/context/common"
import { eraseAllCookies, eraseCookie } from "../../../service/helper"
import toast from "react-hot-toast"
import BrandManagerDetail from "./login/brandManagerDetail"
import Loader from "../../../components/addon/loader"
import DisableRightClick from "./DisableRightClick"
import CampaignReportView from "../backendAdmins/campaignReporting/campaignReportingView";

const BrandDetails = (props) => {

    hotkeys('shift+ctrl+c, shift+command+c', function(event) {
        event.preventDefault();
        return false;
    });

    hotkeys('ctrl+c, command+c', function(event) {
        event.preventDefault();
        return false;
    });

    hotkeys('ctrl+u, command+u', function(event) {
        event.preventDefault();
        return false;
    });


    const router = useRouteMatch()
    const history = useHistory()
    const context = useContext(AppContextObject)

    const [showSidemenu, setShowSidemenu] = useState(true)
    const [responsiveSidemenu, setResponsiveSidemenu] = useState(false)
    const [toolTipLoaded, setToolTipLoaded] = useState(false)
    const [toolTipFocus, setToolTipFocus] = useState("")
    const [brandSetupLoading, setBrandSetupLoading] = useState(false)
    const [data, setData] = useState({
        brandDetail: {
            brandId: "",
            name: "",
            startDate: "",
            endDate: "",
            description: "",
            objective: "",
            inventory: false,
            eventAndOccations: false,
            csr: false,
            sampling: false,
            custom: false,
            // budget: 1,
            targetGender: "both",
            targetAgeGroup: {min: 3, max: 4},
            studyMedium: [],
            city: [],
            state: [],
            brandCategory: {value: "", label: ""},
            brandSubcategory: {value: "", label: ""},
            brandName: "",
            isTrial: false
        },
        algoData :{
            classroomData: [],
            inventoryData: [],
            eventData: [],
            schoolData: [],
            noOfDaysInYear: 0,
            percentageDiscountInWISR: 0,
            percentageIncreaseInReach: 0,
            totalInternalCostPerSchool: 0,
            budgetRatio: { CatA: 0, CatB: 0, CatC: 0 }
        },
        campaignDetail: {
            wisrOption: true,
            totalImpression: 0,
            totalReach: 0,
            totalBudget: 0,
            boysRatio: 0,
            girlsRatio: 0
        },
        campaignCreative:{
            creatives: {image1: {}, image2: {}, image3: {}},
            inventoryCreativeData: [],
            inventoryCreativeDataGenerated: false,
        },
        inventory: {
            inventories: [],
            notUsedInventories: [],
            usedInventories: []
        },
        school: {
            schools: [],
            locations: []
        },
        eventAndOccation: {
            eventAndOccations: [],
            usedEvents: []
        },
        schoolListDetail: []
    })
    const [progress, setProgress] = useState({
        brandDetails: false,
        inventory: false,
        event: false,
        school: false,
        recommendedSchool: false,
        campaignCreative: false,
        preview: false
    })
    const [activeModule, setActiveModule] = useState({
        module: "",
        subModule: ""
    })

    const [editFromConfirmationPage, setEditFromConfirmationPage] = useState(false)

    const [toolTipProgress, setToolTipProgress] = useState({
        dashboard: true,
        createCampaign: true,
        myBrand: true
    })
    const [initialInternetSpeedTest, setInitialInternetSpeedTest] = useState(false)

    context.socketManager.socket.on("logoutEvent", (data) => {
        console.log("checking ip..",data.data.ip, sessionStorage.getItem('ip'), data.data.ip === sessionStorage.getItem('ip'));
        if(data.data.ip === sessionStorage.getItem('ip'))
        {
            deleteUserLocation()
            eraseAllCookies();
            eraseCookie("session-wisr-core-microservice-backend")
            context.socketManager.socket.disconnect();
            toast.success('logged out by another device')
            history.push('/login')
            sessionStorage.clear()
        }
        
    });

    const deleteUserLocation = async () =>{
        let resp = await context.apis[context.user.role].deleteUserLocation(
            context.validator, {_id: sessionStorage.getItem('locationActivityId')}
        )
        console.log("deleteUserLocation resp", resp);
    }

    const toggleResponsiveSidemenu = () => {
        setResponsiveSidemenu(!responsiveSidemenu)
    }
    const value: any = {
        setData,
        data,
        setProgress,
        progress,
        setActiveModule,
        responsiveSidemenu,
        toggleResponsiveSidemenu,
        activeModule,
        setShowSidemenu,
        showSidemenu,
        toolTipProgress,
        setToolTipProgress,
        initialInternetSpeedTest,
        setInitialInternetSpeedTest,
        editFromConfirmationPage,
        setEditFromConfirmationPage
    }
    useEffect(() => {
        getUsersToolTipProgress()
    }, [])
    if (!context.apis || !context?.user?.role || !context?.socketManager.socket.connected) {
        document.location.reload()
    }

    useEffect(() => {
        // redirecting user to user verification screen if isVerifed is false
        if (context && context.user && context.user.isVerified === false) {
              window.location.href = '/user-verification'
        }
    }, [])

    useEffect(() => {
        console.log("history.location.pathname>>>>",history.location.pathname);
        if(!history.location.pathname.startsWith("/brands/brand-details/create-campaign") && !history.location.pathname.startsWith("/brands/brand-details/create-trial-campaign")){
            setInitialInternetSpeedTest(false)
            setData({
                brandDetail: {
                    brandId: "",
                    name: "",
                    startDate: "",
                    endDate: "",
                    description: "",
                    objective: "",
                    inventory: false,
                    eventAndOccations: false,
                    csr: false,
                    sampling: false,
                    custom: false,
                    // budget: 1,
                    targetGender: "both",
                    targetAgeGroup: {min: 3, max: 4},
                    studyMedium: [],
                    city: [],
                    state: [],
                    brandCategory: {value: "", label: ""},
                    brandSubcategory: {value: "", label: ""},
                    brandName: "",
                    isTrial: false
                },
                algoData :{
                    classroomData: [],
                    inventoryData: [],
                    eventData: [],
                    schoolData: [],
                    noOfDaysInYear: 0,
                    percentageDiscountInWISR: 0,
                    percentageIncreaseInReach: 0,
                    totalInternalCostPerSchool: 0,
                    budgetRatio: { CatA: 0, CatB: 0, CatC: 0 }
                },
                campaignDetail: {
                    wisrOption: true,
                    totalImpression: 0,
                    totalReach: 0,
                    totalBudget: 0,
                    boysRatio: 0,
                    girlsRatio: 0
                },
                campaignCreative:{
                    creatives: {image1: {}, image2: {}, image3: {}},
                    inventoryCreativeData: [],
                    inventoryCreativeDataGenerated: false,
                },
                inventory: {
                    inventories: [],
                    notUsedInventories: [],
                    usedInventories: []
                },
                school: {
                    schools: [],
                    locations: []
                },
                eventAndOccation: {
                    eventAndOccations: [],
                    usedEvents: []
                },
                schoolListDetail: []
            })
            setProgress({
                brandDetails: false,
                inventory: false,
                event: false,
                school: false,
                recommendedSchool: false,
                campaignCreative: false,
                preview: false
            })
        }
        if(
            history.location.pathname == "/brands/brand-details/setting"  
            || history.location.pathname.startsWith("/brands/brand-details/profile")
            || history.location.pathname == "/brands/brand-details/support"
        ){
        }else if(
            history.location.pathname == "/brands/brand-details/create-campaign/set-up-campaign"
            || history.location.pathname == "/brands/brand-details/create-trial-campaign/set-up-campaign"
            || history.location.pathname == "/brands/brand-details/dashboard"
            || history.location.pathname == "/brands/brand-details/campaigns" 
            || history.location.pathname == "/brands/brand-details/school"
            || history.location.pathname == "/brands/brand-details/optimisedSchool"
        ){
            let data:any = history.location.state
            if((history.location.pathname === "/brands/brand-details/create-campaign/set-up-campaign" || history.location.pathname === "/brands/brand-details/create-trial-campaign/set-up-campaign") && data && data["from"] === "createCampaignFlow"){

            }else{
                checkBrandSetup()
            }
        }
    }, [history.location.pathname])
    // useEffect(() => {
    //     if(process.env && process.env.REACT_APP_production && process.env.REACT_APP_production == "true"){
    //         if(history.location.pathname === "/brands/brand-details/create-campaign/set-up-campaign"){
    //             history.push({pathname: "/brands/brand-details/create-trial-campaign/set-up-campaign", state: {campaignType: "trail"}})
    //         }
    //     }  
    // }, [history.location.pathname])

    const checkBrandSetup = async () => {
        setBrandSetupLoading(true)
        const resp = await context.apis[context.user.role].getBrandSetupByUserId(
            context.validator, {}, {}
        )   
        console.log("checkBrandSetup resp>>>>>>",resp);

        if(resp && resp.done){
            if(context.user.role === "brandCMO"){
                if(resp.response.haveCategory){
                    setBrandSetupLoading(false)
                }else{
                    setBrandSetupLoading(false)
                    history.push("/brands/brand-details/profile")
                    toast.error("Please complete your profile")
                }
            }else if(context.user.role === "brandManager"){
                if(resp.response.haveCategory && resp.response.haveSubCategory && resp.response.haveBrand){
                    setBrandSetupLoading(false)
                }else{
                    setBrandSetupLoading(false)
                    history.push("/brands/brand-details/profile")
                    toast.error("Please complete your profile")
                }
            }else{
                setBrandSetupLoading(false)
            }
        
        }else{
            setBrandSetupLoading(false)
        }
    }
    const getUsersToolTipProgress = async () => {
        let userToolTipResp = await context.apis[context.user.role].getUsersToolTipProgress(
            context.validator, {}
        )
        console.log(userToolTipResp)
        if(userToolTipResp && userToolTipResp.done){
            setToolTipProgress({...userToolTipResp.response.toolTipProgress})
            setToolTipLoaded(true)
        }
    }
    return (
        <>
            <DisableRightClick />
            {
                brandSetupLoading
                ?
                <Loader />
                    :
                <DataContextProvider value={value}>
                    <div className={`ovelap ${responsiveSidemenu === true ? "active" : ""}`}></div>
                    <div className="main-wapper">
                    <div className="container">
                        <div className="row">
                            {
                                showSidemenu === true &&
                                <BrandsSideMenu toolTipFocus={toolTipFocus} />
                            }

                            <Switch>
                                <Route path={`${router.url}/dashboard`}>
                                    <Dashboard toolTipLoaded={toolTipLoaded} setToolTipFocus={setToolTipFocus} />
                                </Route>
                                <Route path={`${router.url}/profile`}>
                                    <Profile toolTipLoaded={toolTipLoaded} />
                                </Route>
                                <Route path = {`${router.url}/support`}>
                                    <BrandsSupport />
                                </Route>
                                <Route path = {`${router.url}/setting`}>
                                    <BrandSettings />
                                </Route>
                                <Route path = {`${router.url}/campaigns`}>
                                    <Campaigns />
                                </Route>
                                <Route path = {`${router.url}/campaign-details`}>
                                    <CampaignDetails />
                                </Route>
                                <Route path = {`${router.url}/school`}>
                                    <Schools />
                                </Route>
                                <Route path = {`${router.url}/optimisedSchool`}>
                                    <OptimisedSchools />
                                </Route>
                                <Route path = {`${router.url}/create-campaign`}>
                                    <CreateCampaign toolTipLoaded={toolTipLoaded} />
                                </Route>
                                <Route path = {`${router.url}/create-trial-campaign`}>
                                    <CreateCampaign toolTipLoaded={toolTipLoaded} />
                                </Route>
                                <Route path = {`${router.url}/viewAlbum`}>
                                    <CampaignReportView />
                                </Route>
                                {/* <Route path = {`${router.url}/add-brand-details`}>
                                    <BrandManagerDetail />
                                </Route> */}
                            </Switch>
                        </div>
                    </div>
                    </div>
                </DataContextProvider>
            }
        </>
    )
}

export default BrandDetails
