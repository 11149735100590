import { useState } from "react"
import minus from "../../../assets/images/minus.svg"
import plus from "../../../assets/images/plus1.svg"
import ConfirmDialog from "../../addon/confirmDialog/confirmDialog"

const NumWithPlusMinus = (props) => {
  const {
    maxLimit,
    minLimit,
    formData,
    formKey,
    onDataChange,
    updateNumber,
    isDisabled,
    id,
    formValidator,
    validation,
    errorClass
  } = props

  const [toggleSectionChangeDialog, setToggleSectionChangeDialog] = useState(false)
  const [overlay, setOverlay] = useState(false)

  const onMinus = () => {
    if (Number(formData[formKey]) > minLimit) {
      if(formKey === "noOfSections"){
        console.log("formData>>>",formData);
        if(formData["girls"] > 0 || formData["boys"] > 0)
        {
          setToggleSectionChangeDialog(true)
          setOverlay(true)
        } else {
          const newNum = Number(formData[formKey]) - 1
          updateNumber(newNum, formKey)
        }
        
      }else{
        const newNum = Number(formData[formKey]) - 1
        updateNumber(newNum, formKey)
      }
    }
  }

  const onPlus = () => {
    if (Number(formData[formKey]) < maxLimit) {
      const newNum = Number(formData[formKey]) + 1
      if(formKey === "noOfSections"){
        updateNumber(newNum, formKey, true)
      }else{
        updateNumber(newNum, formKey)
      }
    }
  }

  const onValueChange = (e) => {
    const re = /^[0-9\b]+$/
    // if value is not blank, then test the regex
    if (e.target.value === '' || re.test(e.target.value)) {
      if (Number(e.target.value) >= minLimit && Number(e.target.value) <= maxLimit) {
        onDataChange(e)
      }
    }
  }

  const confirmDecrement = (confirm) => {
    if(confirm){
      if (Number(formData[formKey]) > minLimit) {
          const newNum = Number(formData[formKey]) - 1
          updateNumber(newNum, formKey)
      }
    }
    setToggleSectionChangeDialog(false)
    setOverlay(false)
  }

  return (
    <>
      <div className={`ovelap ${ overlay === true ? "active" : ""}`}></div>
      <div id={id ? id : ""} className={`nu-in-dic avgAnnualFeeMobile ${errorClass ? "errorClass  onFocus" : ""}`}>
        <a onClick={onMinus} href='javascript:void(0)' className='dele-anvetry'>
          <img src={minus} />
        </a>
        <input
          id={formKey}
          value={formData && formData[formKey]}
          type='text'
          min={minLimit}
          max={maxLimit}
          onChange={onValueChange}
          pattern="[0-9]*"
          disabled={isDisabled === true ? true : false}
        />
        <a onClick={onPlus} href='javascript:void(0)'>
          <img src={plus} />
        </a>
      </div>
      {formValidator?.current?.message(formKey, formData[formKey], validation)}
      <ConfirmDialog
        togglePopup={toggleSectionChangeDialog}
        onModalClose={confirmDecrement}
        componentName={""}
        confirmTitle="Are you sure you want to decrement section? Number of boys and girls count will reset."
        confirmText="Yes"
      />
    </>
  )
}

export default NumWithPlusMinus
