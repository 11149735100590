import React, {useState, useContext, useRef, useEffect} from 'react'
import CustomSearchInput from '../../../../components/formFields/customSearchInput'
import AppContextObject from "../../../../common/context/common";
import useClickOutside from '../../../../service/useClickOutside';

interface Props{
    fosList?: any,
    setFosList?: any,
    handleClick?: any,
    selectedFos?: any,
    setSelectedFos?: any,
    showFosList?: any,
    activeFos?: any,
    setShowFosList?: any,
    editFosActiveIndex?: any,
    setEditFosActiveIndex?: any,
    setSelectedSchools?: any,
    setEditSchool?: any
}

const FosListComponent = ({ fosList, setFosList, handleClick, selectedFos, setSelectedFos, showFosList, setShowFosList, activeFos, editFosActiveIndex, setEditFosActiveIndex, setSelectedSchools, setEditSchool }: Props) => {
    const context = useContext(AppContextObject)
    const ref = useRef()
    const [overlay, setOverlay] = useState(false)
    const [searchInputOpen, setSearchInputOpen] = useState(true)
    const [clearSearchIcon, setClearSearchIcon] = useState(true)
    const [searchValue, setSearchValue] = useState('')

    useClickOutside(ref, () => {
        if(showFosList){
            setShowFosList(false);
            if(selectedFos && selectedFos._id && selectedFos._id !== ""){
                
            }
            else{
                setEditSchool({})
                setEditFosActiveIndex(-1);
                setSelectedSchools([]);
            }
        }
    })
    const handleSelectFos = (item) => {
        setSelectedFos({_id: item._id, name: item.fullName})
        handleClick()
    }
    useEffect(() => {
        if(!showFosList){
            setSearchValue("")
        }
    }, [showFosList])
    const handleSearch = (e) => {
        if(e === ""){
            setSearchValue("")
            getFosData("")
        }
        else{
            setSearchValue(e.target.value)
            getFosData(e.target.value)
        }
    }

    const getFosData = async(value) => {
        const resp = await context.apis.public.getUsersListByRole(
            context.validator, {role: "backendFOS", searchValue: value}
        )
        console.log("getUsersListByRole", resp)
        if(resp && resp.done){
            console.log("getUsersListByRole", resp)
            setFosList([...resp.response.rows])
        }
    }
    return (
        <>
            <div className={`ovelap ${overlay === true ? "active" : ""}`}></div>
            <div className={`filterDropDown ${showFosList ? "open" : ""}`} style={{backgroundColor: 'white', right: '40%', top: '30%', width: '350px'}}>
                {/* <div className="filterDropHead">
                    <h2>Filter <a href="javascript:void(0);" className="viewwLink" onClick={clearAllFilter}>Clear All</a></h2>
                </div> */}
                {/* <div className="filterDropHead"> */}
                    
                {/* </div> */}
                <div ref={ref} className="filterDropBody" style={{backgroundColor: 'white'}}>
                    <div className={`filterDropClose open`} style={{backgroundColor: 'white'}}>
                        <CustomSearchInput
                            searchInpuOpen={true}
                            setSearchInpuOpen={setSearchInputOpen}
                            clearSearchIcon={clearSearchIcon}
                            setClearSearchIcon={setClearSearchIcon}
                            searchKeys={searchValue}
                            handleChange={handleSearch}
                            doNotShowClearIcon={true}
                            palaceholder="Fos name"
                            width="100%"
                        />
                        <ul className="filtercheckbox fosListContainer" style={{maxHeight: "380px"}}>
                            {fosList.map((item, index) => (
                                <li onClick={() => handleSelectFos(item)} className={activeFos && activeFos._id && activeFos._id == item._id && 'active'}>
                                    {item.fullName}
                                </li>
                            ))}
                        </ul>
                    </div>

                </div>
            </div>
        </>
    )
}

export default FosListComponent