import { useContext, useEffect, useState } from "react"
import { Link, useHistory } from "react-router-dom"
import logo from "../../../../assets/images/logo.png"
import logoNew from "../../../../assets/images/logo-new.svg"
import waveBootNew from "../../../../assets/images/wavebotnew.png"
import AppContextObject from "../../../../common/context/common"
import { eraseAllCookies, eraseCookie } from "../../../../service/helper"

import DataContext from "../../../../service/contextProvider"

import InventorySVG from "../../../../components/addon/svg/inventory"
import DashboardSVG from "../../../../components/addon/svg/dashboard"
import BookingSVG from "../../../../components/addon/svg/bookings"
import MessageSVG from "../../../../components/addon/svg/message"
import SchoolSVG from "../../../../components/addon/svg/school"
import SettingsSVG from "../../../../components/addon/svg/settings"
import ProfileSVG from "../../../../components/addon/svg/profile"
import LogoutSVG from "../../../../components/addon/svg/logout"
import ConfirmDialog from "../../../../components/addon/confirmDialog/confirmDialog"

const UpdateDetailsSideMenu = (props) => {
    const context = useContext(AppContextObject)
    const history = useHistory()
    const parentData = useContext<any>(DataContext)
    const [toggleLogoutDialog, setToggleLogoutDialog] = useState(false)
    const [overlay, setOverlay] = useState(false)
    const [isLoggedOut, setIsLoggedOut] = useState(false)
    const logoutUser = () =>{
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
        };
        setIsLoggedOut(true)
        fetch("/api/logout", requestOptions)
        .then(response => response.json())
        .then(data => {
            console.log("data>>>>>",data);
            if(data.done){
                setToggleLogoutDialog(false)
                setOverlay(false)
                deleteUserLocation()
                setIsLoggedOut(false)
                localStorage.setItem("prevUserId", context.user._id)
                eraseAllCookies();
                eraseCookie("session-wisr-core-microservice-backend")
                context.socketManager.socket.disconnect();
                setOverlay(false)
                setToggleLogoutDialog(false)
                document.location.href = "/login"
                sessionStorage.clear()
            }
        }).catch(err=>{
            console.log(err);
            setIsLoggedOut(false)
            setToggleLogoutDialog(true)
        }).catch(err=>{
            console.log(err);
            setIsLoggedOut(false)
            setToggleLogoutDialog(true)
        })
    }

    const deleteUserLocation = async () =>{
        if(sessionStorage.getItem('locationActivityId')){
            let resp = await context.apis[context.user.role].deleteUserLocation(
                context.validator, {_id: sessionStorage.getItem('locationActivityId')}
            )
            console.log("deleteUserLocation resp>>>",resp);
        } 
    }
    const toggleLogoutDialogBox = () => {
        setOverlay(true)
        setToggleLogoutDialog(true)
    }

    const confirmLogout = (confirm) => {
        if (confirm) {
            logoutUser();
        }
        else{
            setToggleLogoutDialog(false)
            setOverlay(false)
        }
    }
    
    const toggleMenu = () => {
        if (parentData.showSidemenu === true) {
            parentData.toggleSidemenu()
        }
    }
    return (
        <>
        <div className={`ovelap ${( overlay === true ) ? "active" : ""}`}></div>
        <div className="col-xl-2 col-lg-3 col-md-12 col-xs-12 pl-0 pr-0">
        <div className={`sliderbar updatebar ${parentData.showSidemenu === true ? "no_highlight" : ""}`}>
        <div className="row logo between-xs bottom-xs logoNew"><img src={logoNew} alt="logo" /></div>
        <ul className="dnone">
            <li><a href="javascript:void(0);" className=""><span><DashboardSVG /> </span> Dashboard</a></li>

            <li><a href="javascript:void(0);" className=""><span><BookingSVG /> </span> Bookings</a></li>

            <li><a href="javascript:void(0);" className="active"><span><InventorySVG /></span> Inventory</a></li>

            <li><a href="javascript:void(0);"><span><MessageSVG/> </span> Message</a></li>

            <li><a href="javascript:void(0);"><span><SchoolSVG /></span> My School</a></li>

            <li><a href="javascript:void(0);"><span><SettingsSVG /></span> Settings</a></li>
        </ul>

        <ul id="navBar">
            <li id="navBarDashBoard" onClick={() => toggleMenu()}>
                <Link to="/schools/school-details/dashboard"
                className={history.location.pathname === "/schools/school-details/dashboard" ? "active" : ""}><span><DashboardSVG /> </span> Dashboard</Link>
            </li>
            <li id="navBarInventory" onClick={() => toggleMenu()}>
                <Link to="/schools/school-details/inventory"
            className={history.location.pathname === "/schools/school-details/inventory" ? "active" : ""}><span><InventorySVG /> </span> Inventory</Link>
            </li>
            <li id="navBarSchool" onClick={() => toggleMenu()}>
                <Link to="/schools/school-details/my-school"
            className={history.location.pathname === "/schools/school-details/my-school" ? "active" : ""}><span><SchoolSVG /></span> MY SCHOOL</Link>
            </li>
            <li className="line-sidebar"><a href="javascript:void(0);"></a>
            </li>
            <li onClick={() => toggleMenu()}>
                <Link to="/schools/school-details/my-profile"
            className={history.location.pathname === "/schools/school-details/my-profile" ? "active" : ""}><span><ProfileSVG /> </span> MY Profile</Link> </li>
            <li onClick={() => toggleMenu()}>
                <Link to="/schools/school-details/settings"
            className={history.location.pathname === "/schools/school-details/settings" ? "active" : ""}><span><SettingsSVG /></span> settings</Link> </li>
            <li><a href="javascript:void(0);" onClick={toggleLogoutDialogBox}><span><LogoutSVG /></span> logout</a> </li>
        
        </ul>
        <img src={waveBootNew} className="wavebot" alt="wave" /> </div>
    </div>   
    <ConfirmDialog
        togglePopup={toggleLogoutDialog}
        onModalClose={confirmLogout}
        componentName={""}
        confirmTitle="Are you sure you want to logout?"
        confirmText="Logout"
        isLoading={isLoggedOut}
    />
    </>     
    )
}

export default UpdateDetailsSideMenu
