import { useState, useEffect, useContext } from "react"

import { useHistory, useLocation } from 'react-router-dom'

import { validation } from "../../../../service/validators"

import PasswordField from "../../../../components/formFields/passwordField"

import logo from "../../../../assets/images/logo.png"
import loginBgLeft from "../../../../assets/images/backendAdmin/login-bg.svg"
import { PublicContextInitiator } from "../../../apis/publicContextInitiator"
import AppContextObject from "../../../../common/context/common"
import { BrandManagerContextInitiator } from "../../../../brandManagerSrc/apis/brandManagerContextInitiator"
import BrandBackBtn from "../components/brandBackBtn"
import Loader from "../../../../components/addon/loader"

const ResetPassword = () => {

    const useQuery = () => {
        return new URLSearchParams(useLocation().search)
    }
    const context = useContext(AppContextObject)
    const queryParams = useQuery()

    let [formErrors, setFormErrors] = useState<any>({})

    let [data, setData] = useState({
        newPassword: "",
        confirmNewPassword: ""
    })

    const [userData, setUserData] = useState<any>({
        userId : "",
        username : "",
    })
    const [parentData, setParentData] = useState("")
    const [username,setUsername] = useState<any>("")
    const [emailId, setEmailId] = useState("")
    const [fromBrandSignup, setFromBrandSignup] = useState(false)
    const [apiData, setApiData] = useState<any>()
    const [dataLoading, setDataLoading] = useState(false)

    useEffect(() => {
        const email = queryParams.get("email")
        const token = queryParams.get("token")
        if (email) {
            setEmailId(email)
        }
    }, [])

    useEffect(() => {
        console.log(" Userdata history.location.state",history.location.state)
        if(history.location.state){
            const tempData: any = history.location.state
            console.log(" Userdata htempData",tempData)
            let data = {
                username : tempData.username,
                userId : ""
            }
            setUserData(data)
            setParentData(tempData.from)
            if(tempData.apiData && tempData.fromBrandSignup){
                setFromBrandSignup(true)
                setApiData(tempData.apiData)
                window.addEventListener("beforeunload", handleBeforeUnload)
                return () => {
                    window.removeEventListener("beforeunload", handleBeforeUnload)
                }
            }
            // let data = tempData.username
            console.log("User data", userData, data)
            // setUsername(data)
            // console.log("User name", username)
            // findUserId()
        }
        
       
    },[])

    // useEffect(() => {
    //     window.addEventListener("beforeunload", handleBeforeUnload)
    //     return () => {
    //         window.removeEventListener("beforeunload", handleBeforeUnload)
    //     }
    // }, [])

    const handleBeforeUnload = (e) => {
        e.preventDefault()
        window.addEventListener("unload", function(){
            // deleteUnsavedUser(unsavedUser)
            sessionStorage.removeItem("userContactDetail")
            sessionStorage.removeItem("contactVerification")
            sessionStorage.removeItem("apiData")
            sessionStorage.removeItem("reVerify")
            sessionStorage.removeItem("contactDetails")
            history.push("/brands/sign-up")
        })
        setTimeout(function() {
            setTimeout(function() {
                // sessionStorage.removeItem("imageKeys")
                // sessionStorage.removeItem("userIds")
            }, 1000);
        },1);
        const message = "Are you sure you want to reload the page? All provided data will be lost."
        e.returnValue = message
        return message
    }
    const onFormDataChange = (event) => {
        setData({
            ...data,
            [event.target.id]: event.target.value.trim()
        })
        if(event.target.value){
            setFormErrors({...formErrors, [event.target.id]: "" })
        }
    }

    // if (!context.apis || !context?.user?.role) {
    //     document.location.reload()
    // }

    const history = useHistory()
    console.log("fromBrandSignup-->",fromBrandSignup);
    const onSubmit = async () => {
        const errors = validation(data)
        setFormErrors(errors)
        if (Object.keys(errors).length > 0) {
            return
        }
        console.log("USER NAME ", userData.username )
        setDataLoading(true)
        if(fromBrandSignup){
            console.log("apiData>>>",apiData);
            let addUserResp = await context.apis.public.addBrandWithTeamMember(
                context.validator, apiData
            )
            console.log("reset password addUserResp",addUserResp);
            if(addUserResp && addUserResp.done){
                sessionStorage.removeItem("userContactDetail")
                sessionStorage.removeItem("contactVerification")
                sessionStorage.removeItem("apiData")
                sessionStorage.removeItem("reVerify")
                sessionStorage.removeItem("contactDetails")
            }
            
        }
        
        let resp1 = await context.apis.public.findUserByUsername(
            context.validator, { "username": userData.username }
        )
        console.log("finduser resp", resp1)
        let userId = ""
        if (resp1 && resp1.done === false) {
            userId = resp1.response[0]._id
        }

        console.log("USER ID ", userId)
        
        let publicContextInitiator = new PublicContextInitiator(context)
        let resp = await publicContextInitiator.changeUserPasswordExternal(
            context.validator, {"_id" : userId}, { password: data.newPassword }
        )

        console.log("change password response", resp)
        console.log(resp)
        if (resp && resp.done) {
            setDataLoading(false)
            if(parentData === "contactDetails") {
                history.replace("/login")
            }
            else {
                history.replace("/brands/password-reset-success")
            }
           
        }
        else {
            setDataLoading(false)
            let errr = {};
            errr["confirmNewPassword"] = resp.msg;
            setFormErrors(errr)
        }
    }

    const handleOnKeyPress = (event) => {
        if (event.key.toLowerCase() === "enter") {
          const form = event.target.form;
          const index = [...form].indexOf(event.target);
          form.elements[index + 1].focus();
          event.preventDefault();
          if(event.target.id === "confirmNewPassword")
          {
            onSubmit();
          }
        }
    }

    return (
        <>
            {
            dataLoading
            ?
            <Loader />
            :
            <>
            <div className="ovelap"></div>
            <div className="main-wapper">
                <div className="loginbox">
                <div className="login-wave backendlogin"> <img src={loginBgLeft} alt="login-wave" /> </div>
                    <div className="login-form">
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb10">
                                <img src={logo} alt="logo" className="mb-40" />
                                <div className="clearfix w100"></div>
                                <BrandBackBtn link={parentData === "contactDetails" ? '/brands/contact-details' : '/brands/forgot-password'} />
                                <h2 className="htx1 mt-10">{parentData === "contactDetails" ? "Password" : "Change Password"}</h2>
                            </div>
                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb20">
                                    {/* <div className="forminpt"> */}
                                        <p className="ptx2">Enter a secure password for your company account.</p>
                                    {/* </div> */}
                                </div>
                        </div>
                        <form>
                            <div className="row">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                    <PasswordField
                                        label={parentData === "contactDetails" ? "Password" : "New Password"}
                                        isRequired={false}
                                        placeholder="Enter Password"
                                        formData={data}
                                        formKey="newPassword"
                                        onFormDataChange={onFormDataChange}
                                        formErrors={formErrors}
                                        maxLength={40}
                                        onKeyPress={(event) => handleOnKeyPress(event)}
                                        autoFocus={true}
                                        
                                    />
                                </div>
                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                    <PasswordField
                                        label="Confirm Password"
                                        isRequired={false}
                                        placeholder="Enter Password"
                                        formData={data}
                                        formKey="confirmNewPassword"
                                        onFormDataChange={onFormDataChange}
                                        formErrors={formErrors}
                                        maxLength={40}
                                        onKeyPress={(event) => handleOnKeyPress(event)}
                                    />
                                </div>
                                {/* <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                    <div className="forminpt mb-10">
                                        <p className="ptx2">By clicking on done, the password to <strong>{emailId}</strong> will be updated.</p>
                                    </div>
                                </div> */}
                                <div className="row col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                    <div className="forminpt">
                                        <input onClick={onSubmit} name="Done" value="Continue" className="btn btn-orange datasubmit" type="button" />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            </>
            }
        </>
    )
}

export default ResetPassword
