
import infoIcon from "../../assets/images/info-icon.svg"

import { formatDateToDDMMMYY } from "../../service/helper"

const ApprovalDeadLine = (props) => {

    const {
        approvalDeadLine
    } = props

    return (
        <>
            <span className="approve-deadline">
                {
                    approvalDeadLine &&
                    <>
                        <img src={infoIcon} /> Approval Deadline - {formatDateToDDMMMYY(approvalDeadLine)}
                    </>
                }
            </span>        
        </>
    )
}

export default ApprovalDeadLine
