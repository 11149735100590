import React, { useContext, useState, useEffect} from 'react'
import {
    Route,
    useHistory,
    useRouteMatch,
    Switch,
    useLocation,
} from "react-router-dom"
import { DataContextProvider } from "../../../../../service/contextProvider"
import AppContextObject from "../../../../../common/context/common"
import SetUpCampaign from './setUpCampaign'
import DataContext from '../../../../../service/backendContext'
import CampaignInventory from './campaignInventory'
import CampaignEvents from './campaignEvents'
import CampaignRecomendedSchool from './campaignRecomendedSchool'
import CampaignSchool from './campaignSchool'
import CampaignCreative from './campaignCreative'
import Confirmation from './confirmation'
import CampaignPreview from './campaignPreview'
import ViewCampaignProgress from '../../components/viewCampaignProgress'

const ViewCampaign = () => {
    const context = useContext(AppContextObject)
    const history = useHistory()
    const location = useLocation()
    const router = useRouteMatch()
    const parentData = useContext<any>(DataContext)
    const [showProgressSideMenu, setShowProgressSideMenu] = useState(false)
    console.log("parentData>>>",parentData);
    useEffect(() => {
        if(parentData && parentData.setShowSidemenu){
            console.log('11111111')
            console.log("parentData.showSideMenu>>>",parentData.showSideMenu);
            parentData.setShowSidemenu(false)
        }
    }, [parentData.showSideMenu])
    useEffect(()=>{

    })
    console.log('creation campaign route',parentData)
    console.log(parentData)
    return (
        <>
        <div className="main-wapper">
            <div className="container">
                <div className="row">
                        {showProgressSideMenu &&
                            <ViewCampaignProgress />
                        }
                        <Switch>
                            <Route path={`${router.url}/set-up-campaign`}>
                                <SetUpCampaign
                                    setShowProgressSideMenu={setShowProgressSideMenu}
                                />
                            </Route>
                            <Route path = {`${router.url}/inventory`}>
                                <CampaignInventory 
                                    setShowProgressSideMenu={setShowProgressSideMenu}
                                />
                            </Route>
                            {parentData.data.brandDetail.wisrOffering.eventAndOccations && <Route path = {`${router.url}/events`}>
                                <CampaignEvents 
                                    setShowProgressSideMenu={setShowProgressSideMenu}
                                />
                            </Route>}
                            <Route path = {`${router.url}/recommended-school`}>
                                <CampaignRecomendedSchool 
                                    setShowProgressSideMenu={setShowProgressSideMenu}
                                />
                            </Route>
                            <Route path = {`${router.url}/schools`}>
                                <CampaignSchool 
                                    setShowProgressSideMenu={setShowProgressSideMenu}
                                />
                            </Route>
                            <Route path = {`${router.url}/creative`}>
                                <CampaignCreative 
                                    setShowProgressSideMenu={setShowProgressSideMenu}
                                />
                            </Route>
                            <Route path = {`${router.url}/preview`}>
                                <CampaignPreview 
                                    setShowProgressSideMenu={setShowProgressSideMenu}
                                />
                            </Route>
                            <Route path = {`${router.url}/confirm`}>
                                <Confirmation 
                                    setShowProgressSideMenu={setShowProgressSideMenu}
                                />
                            </Route>
                        </Switch>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ViewCampaign
