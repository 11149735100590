import React, { useState, useContext, useRef, useEffect, createRef } from 'react'
import { useHistory } from "react-router-dom"

import AppContextObject from "../../../../common/context/common"
import { PublicContextInitiator } from "../../../apis/publicContextInitiator"
import proEdit from "../../../../assets/images/edit-profile.svg"
import imgInventory from "../../../../assets/images/blank-inventory-img.jpg"
import errorSVG from "../../../../assets/images/error.svg"
import closeCard from "../../../../assets/images/close-2.svg"
import faqArrow from "../../../../assets/images/faq-arrow.svg"
import Textfield from "../../../../components/formFields/textField/textField"
import Numberfield from "../../../../components/formFields/numberField"
import ConfirmDialog from "../../../../components/addon/confirmDialog/confirmDialog"
import SimpleReactValidator from "simple-react-validator"
import ImagePreview from "../../../../components/addon/imagePreview"
import BackendTopBar from '../components/backendTopBar'
import InventoryReport from './inventoryReport'
import Loader from '../../../../components/addon/loader'
import school from '../../brands/school'
import { objectDeepClone } from '../../../../service/helper'

const CampaignReport = ({ setShowSidemenu }) => {
    const context = useContext(AppContextObject)
    const history = useHistory()

    const [overlay, setOverlay] = useState(false)
    const [savingData, setSavingData] = useState(false)
    const [showSaveDataModal, setShowDataModal] = useState(false)
    const [refetch, setRefetch] = useState(false)
    const [ref, setRef] = useState()
    const [selectedIndex, setSelectedIndex] = useState(0)
    const [userLocation, setUserLocation] = useState<any>()
    // const [latLong, setLatLong] = useState([])
    const [showLocationAccessPopup, setShowLocationAccessPopup] = useState(false)
    const [inventoryList, setInventoryList] = useState([])
    const [formData, setFormData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [schoolId, setSchoolId] = useState<any>()
    const [campaignId, setCampaignId] = useState<any>()
    const [schoolName, setSchoolName] = useState('')

    const [activeSetIndex, setActiveSetIndex] = useState(-1)
    const [activeInventoryIndex, setActiveInventoryIndex] = useState(-1)
    const [activeImageIndex, setActiveImageIndex] = useState(-1)
    const [showAllApproveModal, setShowAllApproveModal] = useState(false)
    const [showAllRejectModal, setShowAllRejectModal] = useState(false)
    const [showAllDeleteModal, setShowAllDeleteModal] = useState(false)
    const [showButtons, setShowButtons] = useState(false)

    const [multiSelectInventories, setMultiSelectInventories] = useState([])

    const [salesViewData, setSalesViewData] = useState({type: "school", data: []})

    // const [showAllDeleteModal, setShowAllDeleteModal] = useState(false)

    useEffect(() => {
        setShowSidemenu(true)
        let state: any = history.location.state
        console.log("thisIsStateLcoaiton", state);
        if(context.user.role === "backendSales"){
            if(state.campaignId){
                setCampaignId(state.campaignId)
                getSalesViewData(state.campaignId)
            }
        }
        else{
            if (state.schoolId && state.campaignId) {
                setSchoolId(state.schoolId)
                setCampaignId(state.campaignId)
                getInventoryReportData(state.schoolId, state.campaignId)
            }
            if(state.schoolName){
                setSchoolName(state.schoolName)
            }
        }
    }, [refetch])

    const getSalesViewData =async(_id) => {
        let resp = await context.apis[context.user.role].getApprovedImagesFromCampaignReports(
            context.validator, {groupBy: "school", campaignId: _id}, {}
        )
        console.log("getApprovedImagesFromCampaignReports", resp)
        if(resp && resp.done){
            setSalesViewData({type: "school", data: [...resp.response]})
        }
    }

    // useEffect(() => {
    //     setRef(ref => (
    //         Array(formData.length).fill(0).map((_, i) => {
    //             return Array(formData[i].inventories.length).fill(0).map((_, j) => {
    //                 return ref[i] && ref[i][j] ? ref[i][j] : createRef()
    //             })
    //         })
    //     ))
    // }, [formData.length])
    // useEffect(() => {
    //     askLocationAccessPermission(true)
    // }, [])

    // const askLocationAccessPermission = (confirm) => {
    //     if(confirm){
    //         if (navigator.geolocation) {
    //             const options = {
    //                 enableHighAccuracy: true,
    //                 timeout: 5000,
    //                 maximumAge: 0
    //             };
    //             navigator.permissions.query({ name: "geolocation" })
    //                 .then(function (result) {
    //                     if (result.state === "granted") {
    //                         console.log(result.state);
    //                         //If granted then you can directly call your function here
    //                         navigator.geolocation.getCurrentPosition((location => {
    //                             let arr = [location.coords.latitude, location.coords.longitude]
    //                             setLatLong([...arr])
    //                         }), (error) => {console.log("error", error)}, options);
    //                     } else if (result.state === "prompt") {
    //                         navigator.geolocation.getCurrentPosition((location => {
    //                             let arr = [location.coords.latitude, location.coords.longitude]
    //                             setLatLong([...arr])
    //                         }), (error) => {console.log("error", error)}, options);
    //                     } else if (result.state === "denied") {
    //                         //If denied then you have to show instructions to enable location
    //                         console.log("denied")
    //                         setOverlay(true)
    //                         setShowLocationAccessPopup(true)
    //                     }
    //                     result.onchange = function () {
    //                         console.log(result.state);
    //                     };
    //                 });
    //         } else {
    //             alert("Sorry Not available!");
    //         }
    //     }
    // }

    const getInventoryReportData = async (school_id, campaign_id) => {
        setIsLoading(true)
        let resp = await context.apis[context.user.role].getAllCampaignReportingSetByCampaign(
            context.validator, { campaignId: campaign_id, schoolId: school_id }, {}
        )
        console.log("getAllCampaignReportingSetByCampaign", resp)
        if (resp && resp.done) {
            setInventoryList([...resp.response.inventories])
            setFormData([...resp.response.rows])
            setIsLoading(false)
        }
    }

    const handleClick = (e, index) => {
        const faqs = document.getElementsByClassName("faq-tablist")
        console.log("getElementsByClassName", e.target)
        for (let i = 0; i < faqs.length; i++) {
            if (i !== index) {
                faqs[i].classList.remove("active")
            }
        }
        let parentElement = e.target.parentNode
        if (e.target.id === "img") {
            for (let j = 0; j < 3; j++) {
                parentElement = parentElement.parentNode
            }
            if (parentElement.classList.contains("active")) {
                parentElement.classList.remove("active")
            }
            else {
                parentElement.classList.add("active")
            }
        }
        else if (e.target.id === "anchor") {
            parentElement = parentElement.parentNode
            for (let j = 0; j < 1; j++) {
                parentElement = parentElement.parentNode
            }
            if (parentElement.classList.contains("active")) {
                parentElement.classList.remove("active")
            }
            else {
                parentElement.classList.add("active")
            }
        }
        else if (e.target.id === "faqOpenDiv") {
            if (parentElement.classList.contains("active")) {
                parentElement.classList.remove("active")
            }
            else {
                parentElement.classList.add("active")
            }
        }
        else if (e.target.id === "faqOpenHeader") {
            parentElement = parentElement.parentNode
            for (let j = 0; j < 1; j++) {
                parentElement = parentElement.parentNode
            }
            if (parentElement.classList.contains("active")) {
                parentElement.classList.remove("active")
            }
            else {
                parentElement.classList.add("active")
            }
        }
    }

    const saveSetData = async (index) => {
        setSelectedIndex(index)
        setOverlay(true)
        setShowDataModal(true)
    }

    const formateData = (event?) => {
        let data = objectDeepClone(formData[activeSetIndex])
        // data.inventories.forEach(inventory => {
        //     inventory.approvalStatus = "pending"
        // })
        event ? 
        data.events.forEach((item, index) => {
            item.images.forEach(el => {
                if(el.imageSt && el.imageMt){
                    if(el.imageStKey){
                        el.imageSt = el.imageStKey
                        delete el.imageStKey;
                    }
                    if(el.imageMtKey){
                        el.imageMt = el.imageMtKey
                        delete el.imageMtKey;
                    }
                }
            })
        })
        :
        data.inventories.forEach((item, index) => {
            item.images.forEach(el => {
                if(el.imageSt && el.imageMt){
                    if(el.imageStKey){
                        el.imageSt = el.imageStKey
                        delete el.imageStKey;
                    }
                    if(el.imageMtKey){
                        el.imageMt = el.imageMtKey
                        delete el.imageMtKey;
                    }
                }
            })
        })
        return {
            _id: data._id || "",
            [event ? "events" : "inventories"]: event ? data.events : data.inventories,
            schoolId: schoolId,
            campaignId: campaignId,
        }
    }

    const saveCampaignReportData = async (confirm) => {
        if (confirm) {
            // let inventoryValiadation = []
            // ref[selectedIndex].length !== 0 && ref[selectedIndex].forEach(data => {
            //     inventoryValiadation.push(data?.current?.validateAll())
            // })
            // if (!(inventoryValiadation.indexOf(false) === -1)) {
            //     setOverlay(false)
            //     setShowDataModal(false)
            //     return
            // }
                // console.log("camehere", inventoryValiadation, ref[selectedIndex])
                let apiData = formateData()
                let addResp
                if(formData[activeSetIndex]._id && formData[activeSetIndex]._id !== ""){
                    addResp = await context.apis[context.user.role].updateCampaignReportingSet(
                        context.validator, {_id: formData[activeSetIndex]._id}, apiData
                    )
                }
                else{
                    addResp = await context.apis[context.user.role].addCampaignReportingSet(
                        context.validator, apiData, {}
                    )
                }
                console.log("updateCampaignReportingSet", addResp, apiData)
                if(addResp && addResp.done){
                    setRefetch(!refetch)
                }
            // }
        }
        setOverlay(false)
        setShowDataModal(false)
    }
    // const askPermission = () => {
    //     setShowLocationAccessPopup(false)
    //     setOverlay(false)
    //     askLocationAccessPermission(true)
    // }
    const formateApproveRejectAllData = (status) => {
        let data = objectDeepClone(formData[activeSetIndex])
        multiSelectInventories.forEach(item => {
            data.inventories ? 
            data.inventories[item.inventoryIndex].images.map(item => {
                item.approvalStatus = status
            })
            :
            data.events[item.inventoryIndex].images.map(item => {
                item.approvalStatus = status
            })
        })
        data.inventories ?
            data.inventories.forEach(item => {
                item.images.forEach(el => {
                    if(el.imageSt && el.imageMt){
                        if(el.imageStKey){
                            el.imageSt = el.imageStKey
                            delete el.imageStKey;
                        }
                        if(el.imageMtKey){
                            el.imageMt = el.imageMtKey
                            delete el.imageMtKey;
                        }
                    }
                })
            })
            :
            data.events.forEach(item => {
                item.images.forEach(el => {
                    if(el.imageSt && el.imageMt){
                        if(el.imageStKey){
                            el.imageSt = el.imageStKey
                            delete el.imageStKey;
                        }
                        if(el.imageMtKey){
                            el.imageMt = el.imageMtKey
                            delete el.imageMtKey;
                        }
                    }
                })
            })
        // data.inventories[activeInventoryIndex].images[activeImageIndex].approvalStatus = status
        // data.inventories.forEach(inventory => {
        //     inventory.approvalStatus = "pending"
        // })
        return {
            _id: data._id || "",
            [data.events ? "events" : "inventories"]: data.events ? data.events : data.inventories,
            schoolId: schoolId,
            campaignId: campaignId
        }
    }
    const formateApproveRejectData = (status) => {
        let data = objectDeepClone(formData[activeSetIndex])
        data.inventories ?
        data.inventories[activeInventoryIndex].images[activeImageIndex].approvalStatus = status
        :
        data.events[activeInventoryIndex].images[activeImageIndex].approvalStatus = status
        // data.inventories.forEach(inventory => {
        //     inventory.approvalStatus = "pending"
        // })
        data.events ?
        data.events.forEach((item, index) => {
            item.images.forEach(el => {
                if(el.imageSt && el.imageMt){
                    if(el.imageStKey){
                        el.imageSt = el.imageStKey
                        delete el.imageStKey;
                    }
                    if(el.imageMtKey){
                        el.imageMt = el.imageMtKey
                        delete el.imageMtKey;
                    }
                }
            })
        })
        :
        data.inventories.forEach((item, index) => {
            item.images.forEach(el => {
                if(el.imageSt && el.imageMt){
                    if(el.imageStKey){
                        el.imageSt = el.imageStKey
                        delete el.imageStKey;
                    }
                    if(el.imageMtKey){
                        el.imageMt = el.imageMtKey
                        delete el.imageMtKey;
                    }
                }
            })
        })
        return {
            _id: data._id || "",
            [data.events ? "events" : "inventories"]: data.events ? data.events : data.inventories,
            schoolId: schoolId,
            campaignId: campaignId
        }
    }
    const formateDeleteAllData = () => {
        let data = objectDeepClone(formData[activeSetIndex])
        multiSelectInventories.forEach(item => {
            data.inventories ?
            data.inventories[item.inventoryIndex].images=[]
            :
            data.events[item.inventoryIndex].images=[]
        })
        data.events ?
        data.events.forEach((item, index) => {
            item.images.forEach(el => {
                if(el.imageSt && el.imageMt){
                    if(el.imageStKey){
                        el.imageSt = el.imageStKey
                        delete el.imageStKey;
                    }
                    if(el.imageMtKey){
                        el.imageMt = el.imageMtKey
                        delete el.imageMtKey;
                    }
                }
            })
        })
        :
        data.inventories.forEach((item, index) => {
            item.images.forEach(el => {
                if(el.imageSt && el.imageMt){
                    if(el.imageStKey){
                        el.imageSt = el.imageStKey
                        delete el.imageStKey;
                    }
                    if(el.imageMtKey){
                        el.imageMt = el.imageMtKey
                        delete el.imageMtKey;
                    }
                }
            })
        })
        // data.inventories[activeInventoryIndex].images[activeImageIndex].approvalStatus = status
        // data.inventories.forEach(inventory => {
        //     inventory.approvalStatus = "pending"
        // })
        return {
            _id: data._id || "",
            [data.events ? "events" : "inventories"]: data.events ? data.events : data.inventories,
            schoolId: schoolId,
            campaignId: campaignId
        }
    }
    const formateDeleteData = () => {
        let data = objectDeepClone(formData[activeSetIndex])
        // data.inventories[activeInventoryIndex].images[activeImageIndex] = {}
        data.inventories ?
        data.inventories[activeInventoryIndex].images.splice(activeImageIndex, 1)
        :
        data.events[activeInventoryIndex].images.splice(activeImageIndex, 1)
        // data.inventories[activeInventoryIndex].images[activeImageIndex].approvalStatus = status
        // data.inventories.forEach(inventory => {
        //     inventory.approvalStatus = "pending"
        // })
        data.events ?
        data.events.forEach((item, index) => {
            item.images.forEach(el => {
                if(el.imageSt && el.imageMt){
                    if(el.imageStKey){
                        el.imageSt = el.imageStKey
                        delete el.imageStKey;
                    }
                    if(el.imageMtKey){
                        el.imageMt = el.imageMtKey
                        delete el.imageMtKey;
                    }
                }
            })
        })
        :
        data.inventories.forEach((item, index) => {
            item.images.forEach(el => {
                if(el.imageSt && el.imageMt){
                    if(el.imageStKey){
                        el.imageSt = el.imageStKey
                        delete el.imageStKey;
                    }
                    if(el.imageMtKey){
                        el.imageMt = el.imageMtKey
                        delete el.imageMtKey;
                    }
                }
            })
        })
        return {
            _id: data._id || "",
            [data.events ? "events" : "inventories"]: data.events ? data.events : data.inventories,
            schoolId: schoolId,
            campaignId: campaignId
        }
    }
    const approveImageOfAnInventory = async() => {
        let apiData = formateApproveRejectData("approved")
        console.log("approveImageOfAnInventory", apiData)
        let addResp = await context.apis[context.user.role].updateCampaignReportingSet(
            context.validator, { _id: formData[activeSetIndex]._id }, apiData
        )
        if (addResp && addResp.done) {
            setRefetch(!refetch)
            // setShowRetakeModal(false)
        }
    }
    const rejectImageOfAnInventory = async() => {
        let apiData = formateApproveRejectData("rejected")
        console.log("rejectImageOfAnInventory", apiData)
        let addResp = await context.apis[context.user.role].updateCampaignReportingSet(
            context.validator, { _id: formData[activeSetIndex]._id }, apiData
        )
        if (addResp && addResp.done) {
            setRefetch(!refetch)
            // setShowRetakeModal(false)
        }
    }
    const deleteImageOfAnInventory = async() => {
        let apiData = formateDeleteData()
        console.log("deleteImageOfAnInventory", apiData)
        let addResp = await context.apis[context.user.role].updateCampaignReportingSet(
            context.validator, { _id: formData[activeSetIndex]._id }, apiData
        )
        if (addResp && addResp.done) {
            setActiveImageIndex(-1)
            setRefetch(!refetch)
            // setShowRetakeModal(false)
        }
    }

    const approveAllImageOfAnInventory = async() => {
        let apiData = formateApproveRejectAllData("approved")
        console.log("approveAllImageOfAnInventory", apiData)
        let addResp = await context.apis[context.user.role].updateCampaignReportingSet(
            context.validator, { _id: formData[activeSetIndex]._id }, apiData
        )
        if (addResp && addResp.done) {
            setRefetch(!refetch)
            // setShowRetakeModal(false)
        }
    }
    const rejectAllImageOfAnInventory = async() => {
        let apiData = formateApproveRejectAllData("rejected")
        console.log("rejectAllImageOfAnInventory", apiData)
        let addResp = await context.apis[context.user.role].updateCampaignReportingSet(
            context.validator, { _id: formData[activeSetIndex]._id }, apiData
        )
        if (addResp && addResp.done) {
            setRefetch(!refetch)
            // setShowRetakeModal(false)
        }
    }
    const deleteAllImageOfAnInventory = async() => {
        let apiData = formateDeleteAllData()
        console.log("deleteAllImageOfAnInventory", apiData)
        let addResp = await context.apis[context.user.role].updateCampaignReportingSet(
            context.validator, { _id: formData[activeSetIndex]._id }, apiData
        )
        if (addResp && addResp.done) {
            setRefetch(!refetch)
            // setShowRetakeModal(false)
        }
    }

    const handleAllApprove = () => {
        setShowAllApproveModal(true)
        setOverlay(true)
    }
    const handleAllReject = () => {
        setShowAllRejectModal(true)
        setOverlay(true)
    }
    const handleAllDelete = () => {
        setShowAllDeleteModal(true)
        setOverlay(true)
    }

    const onApproveAll = (confirm) => {
        if(confirm){
            approveAllImageOfAnInventory()
        }
        setShowAllApproveModal(false)
        setOverlay(false)
        setShowButtons(false)
        setMultiSelectInventories([])
    }
    const onRejectAll = (confirm) => {
        if(confirm){
            rejectAllImageOfAnInventory()
        }
        setShowAllRejectModal(false)
        setOverlay(false)
        setShowButtons(false)
        setMultiSelectInventories([])
    }
    const onDeleteAll = (confirm) => {
        if(confirm){
            deleteAllImageOfAnInventory()
        }
        setShowAllDeleteModal(false)
        setOverlay(false)
        setShowButtons(false)
        setMultiSelectInventories([])
    }
    const showAllButtons = (index) => {
        let selectedInventories = objectDeepClone(multiSelectInventories)
        if(showButtons && selectedInventories.find(item => item.setIndex === index)){
            return true
        }
        else{
            return false
        }
    }
    // useEffect(() => {
    //     context.socketManager.socket.on("campaignReportDataUpdated", (data) => {
    //         console.log("campaignReportDataUpdated", data)
    //     })
    // })
    return (
        <>
            <div className={`ovelap ${overlay ? "active" : ""}`}></div>
            <div className="col-xl-10 col-lg-12 col-md-12 col-xs-12">
                <div className="rightPart noBack">
                    <div className="row">
                        <BackendTopBar from={context.user.role === "backendFOS" && "campaignReporting"} redirectPage={true} />
                        {isLoading ?
                            <Loader />
                            :
                            context.user.role !== "backendSales" ?
                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-10 mt-20">
                                <h2 className="htx2 clearfix mb-10">Campaign Reporting</h2>
                                <p className="mb-20" style={{color: "#8A8A8A"}}>{schoolName}</p>
                                <div className="faq-view">
                                    <div className="faq-tabs-list">
                                        {formData.length !== 0 && formData.map((item, index) => (
                                            <div key={index} className={activeSetIndex !== -1 ? index === activeSetIndex ? "faq-tablist active" : "faq-tablist" : index === 0 ? "faq-tablist active" : "faq-tablist"} style={{backgroundColor: "#F8F8F8", padding: "10px 20px 10px 0"}}>
                                                <div id="faqOpenDiv" style={{display: 'flex', justifyContent: showAllButtons(index) && activeSetIndex === index  ? "flex-start" : "space-between"}} onClick={(e) => handleClick(e, index)}>
                                                    <div style={{width: '40%', marginTop: '3px', marginLeft: "12px"}}>
                                                        <h4 id="faqOpenHeader">
                                                            {`Set ${index + 1}`}
                                                        </h4>
                                                    </div>
                                                    {showAllButtons(index) &&
                                                        <div style={{width: '57%', display: 'flex', justifyContent: 'flex-end', marginRight: '15px'}}>
                                                            <button onClick={handleAllApprove} className='btn campaign-reporting-btn-approve crButtons' style={{width: "165px"}}> 
                                                                Approve
                                                            </button>
                                                            <button onClick={handleAllReject} className='btn campaign-reporting-btn-reject crButtons' style={{width: "165px"}}>
                                                                Reject
                                                            </button>
                                                            <button onClick={handleAllDelete} className='btn campaign-reporting-btn-delete crButtons' style={{width: "165px"}}>
                                                                Delete
                                                            </button>
                                                        </div>
                                                    }
                                                    <div style={{width: '1.5%', marginTop: '3px'}}>
                                                        <a id="anchor" style={{ cursor: "pointer" }}><img id="img" src={faqArrow} /></a>
                                                    </div>
                                                </div>
                                                <div className="faq-tablistView">
                                                    <div key={index} className="table-container table-inventory">
                                                        <table className="table" style={{border: "none"}}>
                                                            <tbody style={{background: "rgb(248, 248, 248)"}}>
                                                                {item.inventories ? 
                                                                item.inventories.length > 0 && item.inventories.map((inventory, inventoryIndex) => (
                                                                    <>
                                                                        <InventoryReport
                                                                            ref={ref}
                                                                            index={index}
                                                                            inventoryIndex={inventoryIndex}
                                                                            inventory={inventory}
                                                                            formData={formData}
                                                                            setFormData={setFormData}
                                                                            schoolId={schoolId}
                                                                            campaignId={campaignId}
                                                                            setRefetch={setRefetch}
                                                                            refetch={refetch}
                                                                            setShowButtons={setShowButtons}
                                                                            showButtons={showButtons}
                                                                            setActiveSetindex={setActiveSetIndex}
                                                                            setActiveImageIndex={setActiveImageIndex}
                                                                            setActiveInventoryIndex={setActiveInventoryIndex}
                                                                            activeSetIndex={activeSetIndex}
                                                                            activeInventoryIndex={activeInventoryIndex}
                                                                            activeImageIndex={activeImageIndex}
                                                                            approveImageOfAnInventory={approveImageOfAnInventory}
                                                                            rejectImageOfAnInventory={rejectImageOfAnInventory}
                                                                            deleteImageOfAnInventory={deleteImageOfAnInventory}
                                                                            approveAllImageOfAnInventory={approveAllImageOfAnInventory}
                                                                            rejectAllImageOfAnInventory={rejectAllImageOfAnInventory}
                                                                            deleteAllImageOfAnInventory={deleteAllImageOfAnInventory}
                                                                            multiSelectInventories={multiSelectInventories}
                                                                            setMultiSelectInventories={setMultiSelectInventories}
                                                                            view={"campaignReport"}
                                                                        />
                                                                        {item.inventories.length - 1 !== inventoryIndex &&
                                                                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                                                                <div className="line-full"></div>
                                                                            </div>
                                                                        }
                                                                    </>
                                                                    ))
                                                                    :
                                                                    item.events.length > 0 && item.events.map((inventory, inventoryIndex) => (
                                                                        <>
                                                                            <InventoryReport
                                                                                ref={ref}
                                                                                index={index}
                                                                                inventoryIndex={inventoryIndex}
                                                                                inventory={inventory}
                                                                                formData={formData}
                                                                                setFormData={setFormData}
                                                                                schoolId={schoolId}
                                                                                campaignId={campaignId}
                                                                                setRefetch={setRefetch}
                                                                                refetch={refetch}
                                                                                setShowButtons={setShowButtons}
                                                                                showButtons={showButtons}
                                                                                setActiveSetindex={setActiveSetIndex}
                                                                                setActiveImageIndex={setActiveImageIndex}
                                                                                setActiveInventoryIndex={setActiveInventoryIndex}
                                                                                activeSetIndex={activeSetIndex}
                                                                                activeInventoryIndex={activeInventoryIndex}
                                                                                activeImageIndex={activeImageIndex}
                                                                                approveImageOfAnInventory={approveImageOfAnInventory}
                                                                                rejectImageOfAnInventory={rejectImageOfAnInventory}
                                                                                deleteImageOfAnInventory={deleteImageOfAnInventory}
                                                                                approveAllImageOfAnInventory={approveAllImageOfAnInventory}
                                                                                rejectAllImageOfAnInventory={rejectAllImageOfAnInventory}
                                                                                deleteAllImageOfAnInventory={deleteAllImageOfAnInventory}
                                                                                multiSelectInventories={multiSelectInventories}
                                                                                setMultiSelectInventories={setMultiSelectInventories}
                                                                                view={"campaignReport"}
                                                                            />
                                                                            <br />
                                                                        </>
                                                                    ))
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    {/* <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                                                        <a href="javascript:void(0)" className='btn btn-outline mt-10 fR' onClick={() => saveSetData(index)}>Save</a>
                                                    </div> */}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            :
                            context.user.role === "backendSales" &&
                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-10 mt-20">
                                <h2 className="htx2 clearfix mb-10">Campaign Reporting</h2>
                                {/* <p className="mb-20" style={{color: "#8A8A8A"}}>{schoolName}</p> */}
                                <div className="faq-view" style={{backgroundColor: "#F8F8F8"}}>
                                    <div className="faq-tabs-list">
                                        {salesViewData.data.length !== 0 && salesViewData.data.map((item, index) => (
                                            <div key={index} className={index === 0 ? "faq-tablist active" : "faq-tablist"}>
                                                <div id="faqOpenDiv" style={{display: 'flex', justifyContent:  "space-between"}} onClick={(e) => handleClick(e, index)}>
                                                    <div style={{width: '40%', marginTop: '3px'}}>
                                                        <h4 id="faqOpenHeader">
                                                            {`Set ${index + 1}`}
                                                        </h4>
                                                    </div>
                                                    <div style={{width: '1.5%', marginTop: '3px'}}>
                                                        <a id="anchor" style={{ cursor: "pointer" }}><img id="img" src={faqArrow} /></a>
                                                    </div>
                                                </div>
                                                <div className="faq-tablistView">
                                                    <div key={index} className="table-container table-inventory">
                                                        <table className="table">
                                                            <tbody>
                                                                    <>
                                                                        <InventoryReport
                                                                            ref={ref}
                                                                            index={index}
                                                                            inventory={item}
                                                                            schoolId={schoolId}
                                                                            campaignId={campaignId}
                                                                            setRefetch={setRefetch}
                                                                            refetch={refetch}
                                                                            setActiveSetindex={setActiveSetIndex}
                                                                            setActiveImageIndex={setActiveImageIndex}
                                                                            setActiveInventoryIndex={setActiveInventoryIndex}
                                                                            activeSetIndex={activeSetIndex}
                                                                            activeInventoryIndex={activeInventoryIndex}
                                                                            activeImageIndex={activeImageIndex}
                                                                            view={"campaignReport"}
                                                                        />
                                                                    </>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <ConfirmDialog
                togglePopup={showSaveDataModal}
                onModalClose={saveCampaignReportData}
                componentName={""}
                confirmTitle="Do you want to save the data?"
            />
            {showButtons &&
                <ConfirmDialog
                    togglePopup={showAllApproveModal}
                    onModalClose={onApproveAll}
                    componentName={""}
                    confirmTitle={`Do you want to approve all images of ${formData[activeSetIndex].inventories ? formData[activeSetIndex]?.inventories[activeInventoryIndex]?.name : formData[activeSetIndex]?.events[activeInventoryIndex]?.name} of set ${activeSetIndex + 1}?`}
                />
            }
            {showButtons &&
                <ConfirmDialog
                    togglePopup={showAllRejectModal}
                    onModalClose={onRejectAll}
                    componentName={""}
                    confirmTitle={`Do you want to reject all images of ${formData[activeSetIndex].inventories ? formData[activeSetIndex]?.inventories[activeInventoryIndex]?.name : formData[activeSetIndex]?.events[activeInventoryIndex]?.name} of set ${activeSetIndex + 1}?`}
                />
            }
            {showButtons &&
                <ConfirmDialog
                    togglePopup={showAllDeleteModal}
                    onModalClose={onDeleteAll}
                    componentName={""}
                    confirmTitle={`Do you want to delete all images of ${formData[activeSetIndex].inventories ? formData[activeSetIndex]?.inventories[activeInventoryIndex]?.name : formData[activeSetIndex]?.events[activeInventoryIndex]?.name} of set ${activeSetIndex + 1}?`}
                />
            }
            {/* <ConfirmDialog
                togglePopup={showLocationAccessPopup}
                onModalClose={askPermission}
                componentName={""}
                confirmTitle="Please turn on your location?"
            /> */}
        </>
    )
}

export default CampaignReport