import { AppContext } from "../../common/context/common";
import { Validator } from '../../common/utils/validator.js';
import { Utilities } from '../../common/utils/utilities';

export class BackendTechContextInitiator {
    utils: Utilities;
    constructor(private appContext: AppContext){ this.utils = new Utilities(); }
    async getRecomendedEventsByEvent (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'eventName', args, ()=>{return true;}, false);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('recomendedEvents->getRecomendedEventsByEvent', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getScrappedSchool (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'Board', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'state', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'city', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'searchValue', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'isCSVExport', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'Source', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'is_verified', args, ()=>{return true;}, false);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('scrappedSchool->getScrappedSchool', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getScrappedSchoolDetails (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('scrappedSchool->getScrappedSchoolDetails', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getCallingOutcomesMaster (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'status', args, ()=>{return true;}, false);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('callingActivityOutcome->getCallingOutcomesMaster', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getNonWisrSchool (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('nonWisrSchool->getNonWisrSchool', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getAssignedFOSByCampaign (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'campaignId', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('campaignSchoolAssignments->getAssignedFOSByCampaign', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getNonWisrCampaign (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('nonWisrCampaign->getNonWisrCampaign', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getAllNonWisrCampaigns (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'campaignStatus', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'searchValue', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'dateFilter', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'dateCreatedFilter', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'createdDate', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'startDate', args, ()=>{return true;}, false);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('nonWisrCampaign->getAllNonWisrCampaigns', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getAllNonWisrCampaignSchools (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
        this.utils.addIfFound(validator, argsData, 'searchValue', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'city', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'state', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'language', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'board', args, ()=>{return true;}, false);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('nonWisrCampaign->getAllNonWisrCampaignSchools', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getCampaignSchoolAssignmentsByCampaign (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'campaignId', args, ()=>{return true;}, true);
        this.utils.addIfFound(validator, argsData, 'fosIds', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'city', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'searchValue', args, ()=>{return true;}, false);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('campaignSchoolAssignments->getCampaignSchoolAssignmentsByCampaign', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getAllNonWisrSchools (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'board', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'language', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'state', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'city', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'searchValue', args, ()=>{return true;}, false);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('nonWisrSchool->getAllNonWisrSchools', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getAssignedSchoolsForFOS (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'board', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'language', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'state', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'city', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'searchValue', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'fosId', args, ()=>{return true;}, true);
        this.utils.addIfFound(validator, argsData, 'campaignId', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('school->getAssignedSchoolsForFOS', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getLoggedInUser (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
    
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('user->getLoggedInUser', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getWisrBlogs (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'type', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('wisrBlogs->getWisrBlogs', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getSchoolGeneralDetailsBySchoolId (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('school->getSchoolGeneralDetailsBySchoolId', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getSchoolNameListById (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'schoolIds', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('school->getSchoolNameListById', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getSchoolAmenitiesDetailsBySchoolId (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('school->getSchoolAmenitiesDetailsBySchoolId', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getAllCampaignSchools (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'searchValue', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'campaignId', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'city', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'state', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'language', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'schoolType', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'board', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'schoolStatus', args, ()=>{return true;}, false);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('school->getAllCampaignSchools', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getSchoolsByFOS (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'searchValue', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'city', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'schoolType', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'language', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'board', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'category', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'inventoryStatus', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'schoolStatus', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'locations', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'fosEmail', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'fosPhone', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'fosUserId', args, ()=>{return true;}, false);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('school->getSchoolsByFOS', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getSchoolsRejectingMultipleCampaigns (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'searchValue', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'fosEmail', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'fosPhone', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'fosUserId', args, ()=>{return true;}, false);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('school->getSchoolsRejectingMultipleCampaigns', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getAllBrandsForExport (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'searchValue', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'city', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'documents', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'brandType', args, ()=>{return true;}, false);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('brand->getAllBrandsForExport', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getAllUserNotification (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'role', args, ()=>{return true;}, false);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('userNotification->getAllUserNotification', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getUserNotifications (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'role', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'timestamp', args, ()=>{return true;}, false);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('userNotification->getUserNotifications', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getAllUserLocation (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'ipAddress', args, ()=>{return true;}, false);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('userLocation->getAllUserLocation', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async oldPasswordSendOtpEmailPhone (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'inputPassword', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'phone', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'email', args, ()=>{return true;}, false);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('user->oldPasswordSendOtpEmailPhone', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getUsersToolTipProgress (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
    
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('user->getUsersToolTipProgress', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getSchoolInventoriesToBeAddedExternal (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'searchValue', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'school', args, ()=>{return true;}, true);
        this.utils.addIfFound(validator, argsData, 'statusDiff', args, ()=>{return true;}, false);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('inventories->getSchoolInventoriesToBeAddedExternal', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getCustomInventoriesFromAllSchools (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'searchValue', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'status', args, ()=>{return true;}, false);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('inventories->getCustomInventoriesFromAllSchools', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getActivitiesExternal (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'school', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('activities->getActivitiesExternal', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async findActivity (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('activities->findActivity', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getSchoolActivitiesToBeAddedExternal (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'searchValue', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'school', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('activities->getSchoolActivitiesToBeAddedExternal', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async findActivitiesAttribute (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('activitiesAttribute->findActivitiesAttribute', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getBrandCategoryRecommendation (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'brandCategoryId', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'searchValue', args, ()=>{return true;}, false);
    
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('brandCategoryRecommendation->getBrandCategoryRecommendation', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async getActivityStatus (validator: Validator, args: any = {}, options: any={}):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, optionsData = {};
    
        this.utils.addIfFound(validator, argsData, 'message', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'type', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'portal', args, ()=>{return true;}, false);
        this.utils.addIfFound(validator, argsData, 'searchValue', args, ()=>{return true;}, false);
    
        this.utils.addIfFound(validator, optionsData, 'page', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'limit', options, /[0-9]+/, false);
        this.utils.addIfFound(validator, optionsData, 'sort', options, ()=>{return true;}, false);
        this.utils.addIfFound(validator, optionsData, 'withCount', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'withArchive', options, /^((:?true)|(:?false))$/);
        this.utils.addIfFound(validator, optionsData, 'archivesOnly', options, /^((:?true)|(:?false))$/);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('activityStatus->getActivityStatus', {args: argsData, options: optionsData});
        } else {
            return null;
        }
    }
    async updateScrappedSchool (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, newData, 'is_verified', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'schoolName', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'Board', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'LanguageOfInstruction', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'state', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'city', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'address', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'pincode', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'contactName', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'contactEmail', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'contactNumber', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'Source', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'Valid_Contact', data, ()=>{return true;}, false);
        this.utils.addIfFoundInArray(validator, newData, 'callingActivityDetails', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'Contact', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('scrappedSchool->updateScrappedSchool', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async deleteScrappedSchool (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
    
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('scrappedSchool->deleteScrappedSchool', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async addNonWisrSchool (validator: Validator, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let newData = {};
    
        this.utils.addIfFound(validator, newData, 'schoolName', data, ()=>{return true;}, true);
        this.utils.addIfFound(validator, newData, 'board', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'language', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'pincode', data, ()=>{return true;}, true);
        this.utils.addIfFound(validator, newData, 'state', data, ()=>{return true;}, true);
        this.utils.addIfFound(validator, newData, 'city', data, ()=>{return true;}, true);
        this.utils.addIfFound(validator, newData, 'address', data, ()=>{return true;}, true);
        this.utils.addIfFound(validator, newData, 'contactName', data, ()=>{return true;}, true);
        this.utils.addIfFound(validator, newData, 'contactEmail', data, ()=>{return true;}, true);
        this.utils.addIfFound(validator, newData, 'contactNumber', data, ()=>{return true;}, true);
        this.utils.addIfFound(validator, newData, 'designation', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'commentsOrInventoryDetails', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'hasSchoolOnboarded', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'totalBoys', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'totalGirls', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'totalReach', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('nonWisrSchool->addNonWisrSchool', {data: newData});
        } else {
            return null;
        }
    }
    async updateNonWisrSchool (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, newData, 'schoolName', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'board', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'language', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'pincode', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'state', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'city', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'address', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'contactName', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'contactEmail', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'contactNumber', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'designation', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'commentsOrInventoryDetails', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'hasSchoolOnboarded', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'totalBoys', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'totalGirls', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'totalReach', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('nonWisrSchool->updateNonWisrSchool', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async addNonWisrCampaign (validator: Validator, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let newData = {};
    
        this.utils.addIfFound(validator, newData, 'brandId', data, ()=>{return true;}, true);
        this.utils.addIfFound(validator, newData, 'campaignId', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'name', data, ()=>{return true;}, true);
        this.utils.addIfFound(validator, newData, 'description', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'budget', data, ()=>{return true;}, true);
        this.utils.addIfFound(validator, newData, 'isNonWisrCampaign', data, ()=>{return true;}, true);
        this.utils.addIfFound(validator, newData, 'startDate', data, ()=>{return true;}, true);
        this.utils.addIfFound(validator, newData, 'endDate', data, ()=>{return true;}, true);
        this.utils.addIfFoundInArray(validator, newData, 'locations', data, ()=>{return true;}, true);
        this.utils.addIfFoundInArray(validator, newData, 'schools', data, ()=>{return true;}, true);
        this.utils.addIfFoundInArray(validator, newData, 'cities', data, ()=>{return true;}, false);
        this.utils.addIfFoundInArray(validator, newData, 'states', data, ()=>{return true;}, false);
        this.utils.addIfFoundInArray(validator, newData, 'campaignCreatives', data, ()=>{return true;}, false);
        this.utils.addIfFoundInArray(validator, newData, 'inventories', data, ()=>{return true;}, true);
        this.utils.addIfFoundInArray(validator, newData, 'activities', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'campaignStatus', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'paymentStatus', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'campaignCreationProgress', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'totalImpression', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'totalReach', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('nonWisrCampaign->addNonWisrCampaign', {data: newData});
        } else {
            return null;
        }
    }
    async updateNonWisrCampaignStatus (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, newData, 'paymentStatus', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'campaignStatus', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('nonWisrCampaign->updateNonWisrCampaignStatus', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async addCampaignSchoolAssignment (validator: Validator, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let newData = {};
    
        this.utils.addIfFound(validator, newData, 'campaignId', data, ()=>{return true;}, true);
        this.utils.addIfFound(validator, newData, 'fosId', data, ()=>{return true;}, true);
        this.utils.addIfFoundInArray(validator, newData, 'schools', data, ()=>{return true;}, true);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('campaignSchoolAssignments->addCampaignSchoolAssignment', {data: newData});
        } else {
            return null;
        }
    }
    async updateCampaignSchoolAssignment (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
        this.utils.addIfFound(validator, argsData, 'campaignId', args, ()=>{return true;}, true);
        this.utils.addIfFound(validator, argsData, 'fosId', args, ()=>{return true;}, true);
    
        this.utils.addIfFoundInArray(validator, newData, 'schools', data, ()=>{return true;}, true);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('campaignSchoolAssignments->updateCampaignSchoolAssignment', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async deleteNonWisrSchool (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, newData, 'schoolName', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'board', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'language', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'pincode', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'state', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'city', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'address', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'contactName', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'contactEmail', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'contactNumber', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'designation', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'commentsOrInventoryDetails', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('nonWisrSchool->deleteNonWisrSchool', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async deleteSchool (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, newData, 'schoolName', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'board', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'language', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'pincode', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'state', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'city', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'address', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'principalName', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'parentCity', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'principalEmail', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'fosEmail', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'fosPhone', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'fosUserId', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('school->deleteSchool', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async deleteBrand (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, newData, 'logo', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'brandName', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'brandType', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'website', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'documents', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'address', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'contactNumber', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'country', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'city', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'state', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'zip', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'description', data, ()=>{return true;}, false);
        this.utils.addIfFoundInArray(validator, newData, 'attributes', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'socialMediaLinks', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('brand->deleteBrand', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async updateSchoolGeneralDetailsBySchoolId (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
    
        this.utils.addIfFoundInArray(validator, newData, 'educationLevel', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'schoolType', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'board', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'language', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'pincode', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'state', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'city', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'address', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'builtUpArea', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'localArea', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('school->updateSchoolGeneralDetailsBySchoolId', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async updateSchoolDetailsAmenities (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, newData, 'builtUpArea', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'floors', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('school->updateSchoolDetailsAmenities', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async createUserNotification (validator: Validator, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let newData = {};
    
        this.utils.addIfFound(validator, newData, 'message', data, ()=>{return true;}, true);
        this.utils.addIfFound(validator, newData, 'meta', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'role', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'seen', data, ()=>{return true;}, true);
        this.utils.addIfFound(validator, newData, 'notificationType', data, ()=>{return true;}, true);
        this.utils.addIfFound(validator, newData, 'user', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('userNotification->createUserNotification', {data: newData});
        } else {
            return null;
        }
    }
    async readAllUserNotification (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
    
        this.utils.addIfFound(validator, newData, 'message', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'meta', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'role', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'seen', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'notificationType', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'school', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'timestamp', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'user', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('userNotification->readAllUserNotification', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async unreadUserNotification (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, newData, 'message', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'meta', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'role', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'seen', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'notificationType', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'school', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'brand', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'timestamp', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'user', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('userNotification->unreadUserNotification', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async removeUserNotification (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, newData, 'message', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'meta', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'role', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'seen', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'notificationType', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'school', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'brand', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'timestamp', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'user', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('userNotification->removeUserNotification', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async muteUserNotification (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, newData, 'seen', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'user', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('userNotification->muteUserNotification', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async updateReadStateOfUserNotification (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, newData, 'seen', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'user', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('userNotification->updateReadStateOfUserNotification', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async addUserLocation (validator: Validator, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let newData = {};
    
        this.utils.addIfFound(validator, newData, 'city', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'country', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'timestamp', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'operatingSystem', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'ipAddress', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'userId', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'school', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'brand', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('userLocation->addUserLocation', {data: newData});
        } else {
            return null;
        }
    }
    async deleteUserLocation (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
    
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('userLocation->deleteUserLocation', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async addFosUser (validator: Validator, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let newData = {};
    
        this.utils.addIfFound(validator, newData, 'fullName', data, ()=>{return true;}, true);
        this.utils.addIfFound(validator, newData, 'email', data, ()=>{return true;}, true);
        this.utils.addIfFound(validator, newData, 'phone', data, ()=>{return true;}, true);
        this.utils.addIfFound(validator, newData, 'school', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'brand', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'role', data, ()=>{return true;}, true);
        this.utils.addIfFound(validator, newData, 'countryCode', data, ()=>{return true;}, false);
        this.utils.addIfFoundInArray(validator, newData, 'mutedNotificationCategories', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'username', data, ()=>{return true;}, true);
        this.utils.addIfFound(validator, newData, 'companyName', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'designation', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'companyEmail', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'accountStatus', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'region', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'city', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'pincode', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'state', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'country', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('user->addFosUser', {data: newData});
        } else {
            return null;
        }
    }
    async updateBackendUserById (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, newData, 'fullName', data, ()=>{return true;}, true);
        this.utils.addIfFound(validator, newData, 'email', data, ()=>{return true;}, true);
        this.utils.addIfFound(validator, newData, 'username', data, ()=>{return true;}, true);
        this.utils.addIfFound(validator, newData, 'phone', data, ()=>{return true;}, true);
        this.utils.addIfFound(validator, newData, 'school', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'role', data, ()=>{return true;}, true);
        this.utils.addIfFound(validator, newData, 'countryCode', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'isVerified', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'city', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'state', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'region', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'salesAdmin', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('user->updateBackendUserById', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async updateUserDetails (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
    
        this.utils.addIfFound(validator, newData, 'fullName', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'email', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'phone', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'role', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'username', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'isVerified', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('user->updateUserDetails', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async changeUserPassword (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
    
        this.utils.addIfFound(validator, newData, 'password', data, ()=>{return true;}, false);
        this.utils.addIfFoundInArray(validator, newData, 'oldPasswords', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'isVerified', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('user->changeUserPassword', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async updateNotificationSettings (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
    
        this.utils.addIfFound(validator, newData, 'emailNotification', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'webPushNotification', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'smsNotification', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'allNotifications', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'webToken', data, ()=>{return true;}, false);
        this.utils.addIfFoundInArray(validator, newData, 'mutedNotificationCategories', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('user->updateNotificationSettings', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async updateUsersToolTipProgress (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
    
        this.utils.addIfFound(validator, newData, 'toolTipProgress', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('user->updateUsersToolTipProgress', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async addClassroomsIfNotExists (validator: Validator, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let newData = {};
    
        this.utils.addIfFound(validator, newData, 'class', data, ()=>{return true;}, true);
        this.utils.addIfFound(validator, newData, 'noOfSections', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'ageByStandard', data, ()=>{return true;}, true);
        this.utils.addIfFound(validator, newData, 'boys', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'girls', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'avgAnnualFee', data, ()=>{return true;}, true);
        this.utils.addIfFound(validator, newData, 'school', data, ()=>{return true;}, true);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('classrooms->addClassroomsIfNotExists', {data: newData});
        } else {
            return null;
        }
    }
    async updateClassroomById (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, newData, 'class', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'noOfSections', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'ageByStandard', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'boys', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'girls', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'avgAnnualFee', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'school', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('classrooms->updateClassroomById', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async archiveClassrooms (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
        this.utils.addIfFound(validator, argsData, 'class', args, ()=>{return true;}, true);
    
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('classrooms->archiveClassrooms', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async updateActivities (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, newData, 'name', data, ()=>{return true;}, false);
        this.utils.addIfFoundInArray(validator, newData, 'venue', data, ()=>{return true;}, false);
        this.utils.addIfFoundInArray(validator, newData, 'otherVenueList', data, ()=>{return true;}, false);
        this.utils.addIfFoundInArray(validator, newData, 'months', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'custom', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'availableForBranding', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'school', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'status', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'isApproved', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('activities->updateActivities', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async archiveActivity (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
        this.utils.addIfFound(validator, argsData, 'school', args, ()=>{return true;}, true);
    
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('activities->archiveActivity', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async updateActivitiesAttribute (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, newData, 'name', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'isCustom', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'availableForBranding', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'allowBrandsToProvide', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'activity', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'isSelected', data, ()=>{return true;}, false);
        this.utils.addIfFoundInArray(validator, newData, 'images', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'quantity', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'units', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'length', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'height', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'breadth', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('activitiesAttribute->updateActivitiesAttribute', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async archiveActivitiesAttribute (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
    
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('activitiesAttribute->archiveActivitiesAttribute', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async updateBrandDocumentStatus (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
        this.utils.addIfFound(validator, argsData, 'statusAction', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, newData, 'documents', data, ()=>{return true;}, true);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('brand->updateBrandDocumentStatus', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async approveSchool (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, newData, 'initialApproval', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'rejectionReason', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('school->approveSchool', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async updateCampaignStatus (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, newData, 'paymentStatus', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'isApproved', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'creativeReworkNeeded', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'rejectionMessage', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'campaignStatus', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'campaignStatusPriorToPaused', data, ()=>{return true;}, false);
        this.utils.addIfFoundInArray(validator, newData, 'campaignPausedDates', data, ()=>{return true;}, false);
        this.utils.addIfFoundInArray(validator, newData, 'campaignResumedDates', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('campaign->updateCampaignStatus', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async releaseCampaignOccupiedInventory (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, newData, 'campaignStatus', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('campaign->releaseCampaignOccupiedInventory', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async updateInventoryStatusByIdForBackendAdmin (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, newData, 'isApproved', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'rejectionReason', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'approvedOn', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('inventories->updateInventoryStatusByIdForBackendAdmin', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async updateActivityStatusByIdForBackendAdmin (validator: Validator, args: any, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
        let argsData = {}, newData = {};
    
        this.utils.addIfFound(validator, argsData, '_id', args, ()=>{return true;}, true);
    
        this.utils.addIfFound(validator, newData, 'isApproved', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'rejectionReason', data, ()=>{return true;}, false);
        this.utils.addIfFound(validator, newData, 'approvedOn', data, ()=>{return true;}, false);
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('activities->updateActivityStatusByIdForBackendAdmin', {args: argsData, data: newData});
        } else {
            return null;
        }
    }
    async updateSchoolDetails (validator: Validator, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('school->updateSchoolDetails', {data: data});
        } else {
            return null;
        }
    }
    async oldPasswordEmailPhoneOTPVerification (validator: Validator, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('user->oldPasswordEmailPhoneOTPVerification', {data: data});
        } else {
            return null;
        }
    }
    async addUserForBackend (validator: Validator, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('user->addUserForBackend', {data: data});
        } else {
            return null;
        }
    }
    async updateInventoryMasterBrandOutlay (validator: Validator, data: any):Promise<{done?: boolean, response?: any, msg?: string, error?: boolean}> {
    
        if(validator.check()) {
            return await this.appContext.socketManager.send('inventoriesMaster->updateInventoryMasterBrandOutlay', {data: data});
        } else {
            return null;
        }
    }
}
