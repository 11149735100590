import moment from "moment"
import { useState, useContext, useEffect } from "react"
import AppContextObject from "../../../common/context/common"
import { PublicContextInitiator } from "../../apis/publicContextInitiator"
import arrowView from "../../../assets/images/arrow-view.svg"
interface Props {
}
const OnGoingCampaigns = ({

}: Props) => {

    const [campaignData, setCampaignData] = useState([
        // {
        //     campaignName: " ",
        //     budget: "₹ 500,000",
        //     school: 12,
        //     schedule: "",
        //     reach: "10,000",
        //     impression: "15,000"
        // },
        // {
        //     campaignName: "Nike Product Launch",
        //     budget: "₹ 500,000",
        //     school: 12,
        //     schedule: "01 Feb, 21 - 21 Mar, 21",
        //     reach: "10,000",
        //     impression: "15,000"
        // },
        // {
        //     campaignName: "Nike Product Launch",
        //     budget: "₹ 500,000",
        //     school: 12,
        //     schedule: "01 Feb, 21 - 21 Mar, 21",
        //     reach: "10,000",
        //     impression: "15,000"
        // },
        // {
        //     campaignName: "Nike Product Launch",
        //     budget: "₹ 500,000",
        //     school: 12,
        //     schedule: "01 Feb, 21 - 21 Mar, 21",
        //     reach: "10,000",
        //     impression: "15,000"
        // },
        // {
        //     campaignName: "Nike Product Launch",
        //     budget: "₹ 500,000",
        //     school: 12,
        //     schedule: "01 Feb, 21 - 21 Mar, 21",
        //     reach: "10,000",
        //     impression: "15,000"
        // },
    ])
    const context = useContext(AppContextObject)
    let publicContextInitiator = new PublicContextInitiator(context)
    // const [campaignData, setCampaignData] = useState([]);
    const [lastIndex, setLastIndex] = useState(4)
    const [isCampaignListLoading, setIsCampaignListLoading] = useState(false)
    useEffect(() => {
        getCampaignData()
    }, [])
    const getCampaignData = async () => {
        setIsCampaignListLoading(true)
        console.log("context.......",context)
        let resp = await publicContextInitiator.getCampaignsByBrand(
            context.validator, { brandId: context.user.brand, isOngoing: true},  {limit: 200, page: 1}
        ).catch(err => {
            console.log("Error while getting data: ", err)
            setIsCampaignListLoading(false)
          })
        console.log("getCampaignsByBrand.......",resp)
        if (resp && resp.done) {
            setIsCampaignListLoading(false)
            console.log("campagign Data for brand module...",resp)
            let tempData = []
            if(resp.response.rows.length > 0) {
                resp.response.rows.filter(r => r.campaignStatus.toLowerCase() === "ongoing").map(data => {
                    let a = {
                        campaignName: data.name,
                        budget: data.budget,
                        brandOutlay: data.brandOutlay,
                        school: data.schools.length,
                        schedule: moment(data.startDate).format("DD MMM, YY") + " - " + moment(data.endDate).format("DD MMM, YY"),
                        reach: data.totalReach,
                        impression: data.totalImpression || "-",
                        wisrOffering: data.wisrOffering
                    }
                    tempData.push(a)
                })
                setCampaignData(tempData)
            }

           
        }
    }
    const onLoadMoreClick = () => {
        lastIndex === campaignData.length ? setLastIndex(4) : setLastIndex(campaignData.length)
    }

    
    return (
        <>
            <div className="col-xl-6 col-lg-6 col-md-6 col-xs-12"><h2 className="htx2 clearfix">Ongoing Campaigns</h2></div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-xs-12"><a className="viewwLink" href="/brands/brand-details/campaigns">View all<img className="arrowView" src={arrowView} /></a></div>
            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-10">
                <table className="table going-campaign">
                    <thead>
                        <tr>
                            <th>SR No</th>
                            <th>Campaign Name</th>
                            <th>Budget</th>
                            <th>No Of schools</th>
                            <th>Duration</th>
                            <th>Total reach</th>
                            <th>Total impressions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            isCampaignListLoading ?
                                [...Array(4)].map((el, index) => {
                                    return (
                                        <tr>
                                            {
                                                [...Array(7)].map((el, index) => {
                                                    return (
                                                        <td>
                                                            <div className="content-loader-block"></div>
                                                        </td>
                                                    )
                                                })
                                            }
                                        </tr>
                                    )
                                })
                                :
                                campaignData && campaignData.length > 0 ?
                                campaignData.slice(0, lastIndex).map((data, index) => {
                                    // console.log("brandoutLay set", data)
                                return (
                                    <>
                                        <tr>
                                            <td>{index + 1}</td>
                                            <td>{data.campaignName}</td>
                                            {/* <td>{`₹ ${data.budget.toLocaleString('en-IN')}`}</td> */}
                                            <td>{`₹ ${data.brandOutlay.toLocaleString('en-IN')}`}</td>
                                            <td>{data.school}</td>
                                            <td>{data.schedule}</td>
                                            <td>{data.reach.toLocaleString('en-IN')}</td>
                                            {/* {console.log("daaaaa", data.wisrOffering)} */}
                                            {
                                                data.wisrOffering.eventAndOccations && !data.wisrOffering.inventory ?

                                            <td>-</td>
                                            :
                                            data.wisrOffering.eventAndOccations && data.wisrOffering.inventory ?
                                            <td>{data.impression.toLocaleString('en-IN')}</td>
                                            :
                                            <td>{data.impression.toLocaleString('en-IN')}</td>
                                        }
                                        </tr>
                                    </>
                                )
                                })
                                :
                                <>
                                    <tr>
                                        <td align={"center"} colSpan={7} style={{height: "200px", verticalAlign: "middle"}}><p className="txGrey">No On-Going Campaign</p></td>
                                    </tr>
                                </>
                        }
                    </tbody>
                </table>
                {
                    campaignData && campaignData.length > 4 &&
                    <div className={`notification-showhide load-more mt-10`}><a href="javascript:void(0);" className="viewwLink" onClick={onLoadMoreClick}>
                    {
                        lastIndex == campaignData.length ?
                        "SHOW LESS" 
                        :
                        "VIEW MORE"
                    }
                    
                    </a></div>
                }

            </div>
        </>
    )
}

export default OnGoingCampaigns