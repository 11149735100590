import errorSVG from "../../../assets/images/error.svg"
import check from "../../../assets/images/check.svg"
import 
Select from "react-select"
import SingleSelect from "../singleSelect/singleSelect"
import { useState } from "react"

const TextFieldWithPrefix = (props) => {

    const {
        formData,
        formKey,
        formErrors,
        onDataChange,
        label,
        placeholder,
        onVerifyClick,
        isVerified,
        formValidator,
        validation,
        isIcon,
        icon,
        inProgress,
        isDisabled,
        isVerifyClickDisabled,
        onKeyPress,
        isIconAndPhcode,
        isEditable
    } = props

    const onPhoneNoChange = (e) => {
        const re = /^[0-9\b]+$/
        // if value is not blank, then test the regex
        if (e.target.value === '' || re.test(e.target.value)) {
          onDataChange(e)
        }
    }
    const [code, setCode] = useState("+91")
    const codeOptions = [
        {label: "+91", value: "+91"} //,{label: "011", value: "011"}
    ]
    return (
        <>
        <div className={`forminpt ${isDisabled === true ? "disabled" : ""}`}>
            <label>{label}</label>
            <div className={`${(isIcon) ? " " : "formset"} ${isIconAndPhcode ? "mobileNoIcon" : ""} ${isDisabled === true ? "disabled" : ""}`}>
                {
                    isIcon ?  <em><img src={icon} className="inputicon" alt="Wisr" /></em>
                    :
                    isIconAndPhcode 
                    ?
                    <div className="">
                    <em><img src={icon} className="inputicon" alt="Wisr" /></em>
                    <div className="custom-select">
                    <Select
                        className="phcode"
                        options={codeOptions}
                        onChange={(data) => setCode(data.value)}
                        value={codeOptions.filter((option) => {
                            return option.value === code
                        })}
                    />
                     </div>
                    </div>
                    :
                    // <select className="phcode">
                    // <option value="+91">+91</option>
                    // </select>
                    <div className="custom-select">
                    <Select
                    className="phcode"
                    options={codeOptions}
                    onChange={(data) => setCode(data.value)}
                    value={codeOptions.filter((option) => {
                        return option.value === code
                    })}
                    />
                    </div>
                }

                <input
                    disabled={isDisabled || isEditable === false}
                    id={formKey}
                    value={formData[formKey]}
                    onChange={onPhoneNoChange}
                    className={`${(formValidator?.current?.messagesShown === true && formValidator?.current?.fields?.[formKey] === false) ? "onFocus" : ""}`}
                    placeholder={placeholder}
                    pattern="[1-9]{1}[0-9]{9}"
                    maxLength={10}
                    onKeyPress={onKeyPress}
                    type="text" />
                    {
                        onVerifyClick &&
                        <a onClick={onVerifyClick} href="javascript:void(0)" className={`${inProgress ? "verifyLoader active" : "verifyLoader"} ${isVerifyClickDisabled && "clickNone"}`}>{ inProgress ? <span className="button--loading"></span> : "" }<b className={`vertext vrifiyotp ${isVerified === true ? "complete" : ""}`}>verify <img src={check} /></b></a>
                    }
            </div>
            {formValidator?.current?.message(formKey, formData[formKey], validation)}
        </div>
        </>
    )
}

export default TextFieldWithPrefix
