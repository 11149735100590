import { specialCharOnly } from "./helper";

export function validation(form) {
    let errors = {};

    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const passwordRe = /^.{8,40}$/ ;

    for (const field of Object.keys(form)) {
        if ((form[field] === "" || form[field] === null) &&
            field !== "emailId" &&
            field !== "email" &&
            field !== "confirmNewPassword" &&
            field !== "fullName" &&
            field !== "username" &&
            field !== "datetime" && 
            field !== "oldPassword"
        ) {
            errors[field] = `The ${
            field === "newPassword" ? "Password" :
            field === "name" ? "Name" :
            field === "description" ? "Description" :
            field === "name" ? "Name" :
            field === "title" ? "Title" :
            field === "imageUrl" ? "Image" :
            field === "image" ? "Image" :
            field === "date" ? "Date" :
            field === "location" ? "Location" :
            field === "summary" ? "Summary" :
            field
        } field is required.`;
        }

        if (field === "oldPassword") {
            if (form[field].trim() === "") {
            errors[field] = "The old password field is required.";
            }
            else if(!specialCharOnly(form[field].trim())){
                errors[field] = "The old password field can not contain only special character.";
            }
        }
        if (field === "pincode") {
            if (form[field].trim() === "") {
                errors[field] = "Pincode is required";
            } else if(form[field] === "invalid"){
                errors[field] = "Pincode is not valid";
            }else {
                if (!/^(\d{4}|\d{6})$/.test(form[field])) {
                    errors[field] = "Pincode is not valid";
                }
            }
        }
        if (field === "schoolName") {
            if (form[field] === "") {
                errors[field] = "School name is required";
            }
        }
        if (field === "educationLevel" || field === "board"
        || field  === "language" || field == "state"
        || field === "city"
        || field === "months"
        || field === "role") {
            if (form[field] === "default" || form[field] === null || form[field] === "") {
                errors[field] = "This field is required"
            }
        }
        if (field === "venue") { //  || field === "months"
            if (Array.isArray(form[field]) && form[field].length === 0) {
                errors[field] = "This field is required"
            } else if (!form[field]) {
                errors[field] = "This field is required"
            }
        } 
        if (field === "role") {
            if (form[field] === null) {
                errors[field] = "School role is required";
            }
        }
        if (field === "phone") {
            if (form[field] === "") {
                errors[field] = "Phone No. is required";
            } else if (form[field]?.indexOf(" ") !== -1) {
                errors[field] = "Space is not allowed";
            } else if (form[field].trim().length !== 10) {
                errors[field] = "Phone no. is not valid";
            }
        }

        if (field === "emailId" || field === "email") {
            if (form[field] === "") {
                errors[field] = "Email Id is required";
            } else if (!regex.test(form[field])) {
                errors[field] = "Email Id is not valid";
            }
        }

        if (field === "loginMethod") {
            if (form[field].length === 0) {
                errors[field] = "Login Method is required"
            }
        }

        if (field === "fullName") {
            if (form[field] === "") {
                errors[field] = "Full name is required";
            } else if (form.fullName.split(" ").length < 2) {
                errors[field] = "Full name is mandatory";
            }
        }

        if (field === "confirmNewPassword") {
            if (form[field] === "") {
                // errors[field] = "Confirm password is required";
                errors[field] = "Please confirm your password.";
            } else if(!specialCharOnly(form[field].trim())){
                errors[field] = "The confirm password field can not contain only special character.";
            } else if (form.newPassword !== form.confirmNewPassword) {
                // errors[field] = "Confirm password does not match";
                errors[field] = "The password and confirm password do not match.";
            }
        }

        if (field === "newPassword") {
            console.log("form.newPassword",form[field])
            if(form[field] === ""){
                errors[field] = "New password field is required.";
            } else if(!specialCharOnly(form[field].trim())){
                errors[field] = "The new password field can not contain only special character.";
            } else if(!passwordRe.test(form.newPassword)) {
                // errors[field] = "Must have minimum 8 characters"
                errors[field] = "Password must have at least 8 characters."
            }
        }

        if (field === "defaultUserGroups" || field === "userGroups") {
            if (form[field].length === 0) {
                errors[field] = "Default User Groups is required"
            }
        }

        if (field === "userGroups") {
            if (form[field].length === 0) {
                errors[field] = "User Groups is required"
            }
        }

        if (field === "apiProducts") {
            if (form[field].length === 0) {
                errors[field] = "API Products is required"
            }
        }

        if (field === "tags") {
            if (form[field].length === 0) {
                errors[field] = "Tags is required"
            }
        }
    }

    return errors;
}
