import React from "react";
import { useState } from "react"

import { useHistory } from "react-router-dom"
import AppContextObject from "../../../../common/context/common"
import linkImg from "../../../../assets/images/linkImage.svg"
import uploadSvg from "../../../../assets/images/download.svg"
import { formatDateShowMonthBackend } from "../../../../service/helper";

const AlbumList = (props) => {
    const { showAlbumPopUp, setShowAlbumPopUp, albumList, loading, routeToAlbumDetails } = props
    // const [schools, setSchools] = useState<any>([]);
    // const [totalPage, setTotalPage] = useState([0])
    // const [totalPageCount, setTotalPageCount] = useState(0)
    // const [currentPage, setCurrentpage] = useState(1)
    // const onPageChange = (page) => {
    //     setCurrentpage(page)
    // }
    // const onNextClick = () => {
    //     setCurrentpage(prev => prev + 1)
    // }
    // const onPrevClick = () => {
    //     setCurrentpage(prev => prev - 1)
    // }
    const closePopup = () => {
        setShowAlbumPopUp(false)
    }

    const routeToDetailPage = (albumId) => {
        setShowAlbumPopUp(false)
        routeToAlbumDetails(albumId)
    }
    const downloadAlbum = async(albumId, subAlbumName?) => {
        const options = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                albumId: albumId,
                groupBy: "school",
                subAlbumName: subAlbumName
            })
        }
        fetch('/api/downloadCampaignReportingDocByAlbum', options)
        .then(response => response.json())
        .then(data => {
            console.log("downloadCampaignReportingDocByAlbum", data)
            if (data) {
                // setDownloadSchoolLoading(false);
                // let file = data.result.file.data;
                // downloadFile(file, data.result.filename)
                window.open(data.result.pdfData.image)
            }
        })
        .catch(err => console.error("downloadCampaignReportingDocByAlbum", err))
        .catch(err => console.error("downloadCampaignReportingDocByAlbum", err))
    }
    const openTableInner = (_id) => {
        // console.log(inventory)
        const id = _id
        const rowContainer = document.getElementById(id);
        const rowBtn = document.getElementById(`${id}-btn`);
        const rowUpArrow = document.getElementById(`${id}-up-arrow`);
        const rowDownArrow = document.getElementById(`${id}-down-arrow`);
        if (rowContainer) rowContainer.style.display = "revert";
        if (rowBtn) rowBtn.style.display = "revert";
        if (rowUpArrow) rowUpArrow.style.display = "revert";
        if (rowDownArrow) rowDownArrow.style.display = "none";
        // let fourChildNode = rowContainer.querySelector('.four');

        // if (isEdit === true) {
        //     let obj = objectDeepClone(inventory)
        //     if(!Array.isArray(obj.months)){
        //         obj.months = [obj.months]
        //     }
        //     // obj.placements.forEach((placement) => { // remove this loop once the api starts giving units attribute
        //     //     placement["units"] = "feet"
        //     // })
        //     setFormData(objectDeepClone(obj))
        //     setReadMode(false)
        // }
    }
    const closeTableInner = (_id) => {
        const id = _id
        const rowContainer = document.getElementById(id);
        const rowBtn = document.getElementById(`${id}-btn`);
        const rowUpArrow = document.getElementById(`${id}-up-arrow`);
        const rowDownArrow = document.getElementById(`${id}-down-arrow`);
        if (rowContainer) rowContainer.style.display = "none";
        if (rowBtn) rowBtn.style.display = "none";
        if (rowUpArrow) rowUpArrow.style.display = "none";
        if (rowDownArrow) rowDownArrow.style.display = "revert";
        // setShowForm(false)
    }
    return (
        <>
            <div className={`ovelap ${showAlbumPopUp === true ? "active" : ""}`}></div>
            <div id="inventoryMore" className={`popup ${showAlbumPopUp === false ? "hide" : ""}`}>
                <div className="popup-header">
                    <h3 className="htx2 pophead">Campaign Reporting</h3>
                    <span className="close toggle" onClick={closePopup} data-target="myPopup">close</span>
                </div>
                <div className="popup-body">
                    <div className="viewdetailsHead">
                        <div className="invetorypopblock-school">
                            <div className="rightPart noBack">
                                <div className="row schools-list-popup">
                                    <div className="col-xl-9 col-lg-9 col-md-12 col-xs-12">
                                        <div className="row">
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-20 pl pr">
                                                <div className="table-container mt-10 addAdmininput fillterTableHeight">
                                                    <table className="table going-campaign">
                                                        <thead>
                                                            <tr>
                                                                <th>Name</th>
                                                                <th style={{ textAlign: 'center' }}>Submited On</th>
                                                                <th style={{ textAlign: 'center' }}>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            {!loading ? 
                                                            <>
                                                                {albumList && albumList.length > 0 ? albumList.map((album, key) => {
                                                                    return (
                                                                        <>
                                                                            <tr key={key} className="outerTable">
                                                                                <td>
                                                                                    <h4>{album.title}</h4>
                                                                                </td>
                                                                                <td style={{ textAlign: 'center' }}>{formatDateShowMonthBackend(album.updatedOn, 4)}</td>
                                                                                <td style={{ textAlign: 'center' }}>
                                                                                    <img onClick={() => routeToDetailPage(album._id)} style={{cursor: "pointer", marginRight: "20px"}} src={linkImg}/>
                                                                                    <img onClick={() => downloadAlbum(album._id)} style={{marginRight: "15px", fontWeight: "normal", cursor: "pointer"}} src={uploadSvg}/>
                                                                                    <>
                                                                                        <svg style={{cursor: "pointer"}} onClick={() => openTableInner(album._id)} id={`${album._id}-down-arrow`}  className="down-arrow" width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M1 6L6 0.999999L11 6" stroke="#8C8C8C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                                                                                        <svg onClick={() => closeTableInner(album._id)} id={`${album._id}-up-arrow`} style={{display: "none", cursor: "pointer"}} width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M1 6L6 0.999999L11 6" stroke="#8C8C8C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                                                                                    </>
                                                                                </td>
                                                                            </tr>
                                                                            {album.subAlbums && album.subAlbums.length > 0 &&
                                                                                <tr className="" id={album._id} style={{display: "none"}}>
                                                                                    <td colSpan={4} className="pad-border crInnerTablePadding">
                                                                                        <div className="available-placements">
                                                                                            <table className="table">
                                                                                                <thead>
                                                                                                    <tr>
                                                                                                        <th style={{width: "80%"}} scope="col">inventory/event name</th>
                                                                                                        <th style={{ textAlign: 'center' }} scope="col">Action</th>
                                                                                                    </tr>
                                                                                                </thead>
                                                                                                <tbody style={{background: "#F8F8F8"}}>
                                                                                                    {album.subAlbums.map((item, subAlbumIndex) => (
                                                                                                        <tr key={subAlbumIndex}>
                                                                                                            <td>
                                                                                                                {item.name}
                                                                                                            </td>
                                                                                                            <td style={{ textAlign: 'center' }}>
                                                                                                                <img onClick={() => downloadAlbum(album._id, item.name)} style={{ marginRight: "15px", fontWeight: "normal", cursor: "pointer" }} src={uploadSvg} />
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    ))}
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            }
                                                                        </>
                                                                    )
                                                                })
                                                                    :
                                                                    <tr><td colSpan={3}>No School found</td></tr>
                                                                }
                                                            </>
                                                                :
                                                                [...Array(5)].map((el, index) => {
                                                                    return (
                                                                        <tr>
                                                                            {
                                                                                [...Array(3)].map((el, index) => {
                                                                                    return (
                                                                                        <td key={index}>
                                                                                            <div className="content-loader-block"></div>
                                                                                        </td>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                                {/* {totalPageCount > 10 &&
                                                    <Pagination
                                                        currentPage={currentPage}
                                                        totalPage={totalPage}
                                                        onPageChange={onPageChange}
                                                        onNextClick={onNextClick}
                                                        onPrevClick={onPrevClick}
                                                        loading={loading}
                                                    />
                                                } */}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}
export default AlbumList;