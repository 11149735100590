import React, { useState, useContext, useRef, useEffect, createRef } from 'react'
import { useHistory } from "react-router-dom"
import Geocode from "react-geocode";

import AppContextObject from "../../../../common/context/common"
import { PublicContextInitiator } from "../../../apis/publicContextInitiator"
import proEdit from "../../../../assets/images/edit-profile.svg"
import imgInventory from "../../../../assets/images/blank-inventory-img.jpg"
import errorSVG from "../../../../assets/images/error.svg"
import closeCard from "../../../../assets/images/close-2.svg"
import faqArrow from "../../../../assets/images/faq-arrow.svg"
import Textfield from "../../../../components/formFields/textField/textField"
import Numberfield from "../../../../components/formFields/numberField"
import ConfirmDialog from "../../../../components/addon/confirmDialog/confirmDialog"
import SimpleReactValidator from "simple-react-validator"
import ImagePreview from "../../../../components/addon/imagePreview"
import BackendTopBar from '../components/backendTopBar'
import InventoryReport from './inventoryReport'
import Loader from '../../../../components/addon/loader'
import { getPlaceFromAddressComponents, getSublocalityFromAddressComponents, objectDeepClone, titleCase } from '../../../../service/helper'
import checkSign from "../../../../assets/images/smallSignedCheck.svg"
import pendingImg from "../../../../assets/images/pending.svg"
import verySmallClose from "../../../../assets/images/verySmallClose.png"
import toast from "react-hot-toast"
import Compress from "compress.js"
import './style.css';
// import ImageRetakeModal from '../../../../components/addon/imageModal/imageRetakeModal'
import ExampleImage from '../exampleImage'
import NoControlDialog from '../../../../components/addon/NoControlDialog/NoControlDialog'
import moment from 'moment';
import CampaignReportingUploadImage from '../campaignReportingUploadImg';
const { detect } = require('detect-browser');

var backendCampaignListGlobalVar = 1

const CreateNewCampaignSet = ({ onlyActiveCampaign }) => {
    const context = useContext(AppContextObject)
    const history = useHistory()
    const compress = new Compress()

    const [schoolName, setSchoolName] = useState('')
    const [campaignName, setCampaignName] = useState('')
    const [overlay, setOverlay] = useState(false)
    const [savingData, setSavingData] = useState(false)
    const [showSaveDataModal, setShowDataModal] = useState(false)
    const [refetch, setRefetch] = useState(false)
    const [ref, setRef] = useState([[]])
    const [selectedIndex, setSelectedIndex] = useState(0)
    const [userLocation, setUserLocation] = useState("")
    const [latLong, setLatLong] = useState([])
    const [showLocationAccessPopup, setShowLocationAccessPopup] = useState(false)
    const [inventoryList, setInventoryList] = useState([])
    const [eventList, setEventList] = useState([])
    const [formData, setFormData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [schoolId, setSchoolId] = useState<any>()
    const [campaignId, setCampaignId] = useState<any>()
    const [creatingSet, setCreatingSet] = useState(false)
    const [activeSetIndex, setActiveSetIndex] = useState(-1)
    const [activeInventoryIndex, setActiveInventoryIndex] = useState(-1)
    const [showRetakeModal, setShowRetakeModal] = useState(false)
    const [activeImageIndex, setActiveImageIndex] = useState(-1)
    const [displayImage, setDisplayImage] = useState('')

    const [selectedImageIndex, setSelectedImageIndex] = useState(0)
    const [showImagePreview, setShowImagePreview] = useState(false)
    const [deleteImageKey, setDeleteImageKey] = useState("")
    const [showImageDeleteModal, setShowImageDeleteModal] = useState(false)
    const [isImageLoading, setIsImageLoading] = useState(false)
    const [showImageError, setShowImageError] = useState(false)
    const [notSupportedImageError, setNotSupportedImageError] = useState("")
    const [imagesCount, setImagesCount] = useState([])
    const [loadingImgIndex, setLoadingImgIndex] = useState(-1)
    const [removeImage, setRemoveImage] = useState(null);
    const [showUploadView, setShowUploadView] = useState(true)
    const [imageUploading, setImageUploading] = useState(false)
    const [isMobileDevice, setIsMobileDevice] = useState(false)

    useEffect(() => {
        let state: any = history.location.state
        if (state.schoolId && state.campaignId && state.campaignName) {
            setSchoolId(state.schoolId)
            setCampaignId(state.campaignId)
            setSchoolName(state.schoolName)
            setCampaignName(state.campaignName)
            getInventoryReportData(state.schoolId, state.campaignId)
        }
    }, [refetch])
    useEffect(() => {
        askLocationAccessPermission(true)
    }, [])
    useEffect(() => {
        Geocode.setApiKey("AIzaSyCBB_79wh_bRPXZfHx7Pnt-r05Lyy0vg_8");
        Geocode.setLocationType("ROOFTOP");
        Geocode.setRegion("in");
        Geocode.enableDebug();
    }, [])
    useEffect(() => {
        console.log("navigator.userAgent", navigator.userAgent)
        let regExp = /android|iphone|kindle|ipad/i;
        setIsMobileDevice(regExp.test(navigator.userAgent))
    }, [navigator.userAgent])
    const askLocationAccessPermission = (confirm) => {
        if (confirm) {
            if (navigator.geolocation) {
                const options = {
                    enableHighAccuracy: true,
                    timeout: 5000,
                    maximumAge: 0
                };
                navigator.permissions.query({ name: "geolocation" })
                    .then(function (result) {
                        if (result.state === "granted") {
                            console.log(result.state);
                            //If granted then you can directly call your function here
                            navigator.geolocation.getCurrentPosition((location => {
                                let arr = [location.coords.latitude, location.coords.longitude]
                                setLatLong([...arr])
                                Geocode.fromLatLng(location.coords.latitude, location.coords.longitude).then(
                                    (response) => {
                                        let address = getSublocalityFromAddressComponents(response.results[0].address_components)
                                        if(address === ""){
                                            address = getPlaceFromAddressComponents(response.results[0].address_components)
                                        }
                                        setUserLocation(address)
                                        console.log("formatted_address", address, response.results);
                                    },
                                    (error) => {
                                        console.error(error);
                                    }
                                );
                            }), (error) => { console.log("error", error) }, options);
                        } else if (result.state === "prompt") {
                            navigator.geolocation.getCurrentPosition((location => {
                                let arr = [location.coords.latitude, location.coords.longitude]
                                setLatLong([...arr])
                                Geocode.fromLatLng(location.coords.latitude, location.coords.longitude).then(
                                    (response) => {
                                        let address = getSublocalityFromAddressComponents(response.results[0].address_components)
                                        if(address === ""){
                                            address = getPlaceFromAddressComponents(response.results[0].address_components)
                                        }
                                        setUserLocation(address)
                                        console.log("formatted_address", address, response.results);
                                    },
                                    (error) => {
                                        console.error(error);
                                    }
                                );
                            }), (error) => { console.log("error", error) }, options);
                        } else if (result.state === "denied") {
                            //If denied then you have to show instructions to enable location
                            console.log("denied")
                            setOverlay(true)
                            setShowLocationAccessPopup(true)
                        }
                        result.onchange = function () {
                            console.log(result.state);
                        };
                    });
            } else {
                alert("Sorry Not available!");
            }
        }
    }
    const getInventoryReportData = async (school_id, campaign_id) => {
        setIsLoading(true)
        let resp = await context.apis[context.user.role].getAllCampaignReportingSetByCampaign(
            context.validator, { campaignId: campaign_id, schoolId: school_id }, {}
        )
        console.log("getAllCampaignReportingSetByCampaign", resp)
        if (resp && resp.done) {
            setInventoryList([...resp.response.inventories])
            setEventList([...resp.response.events])
            setFormData([...resp.response.rows])
        }
        setIsLoading(false)
    }
    const handleClick = (e, index) => {
        const faqs = document.getElementsByClassName("faq-tablist")
        for (let i = 0; i < faqs.length; i++) {
            if (i !== index) {
                faqs[i].classList.remove("active")
            }
        }
        let parentElement = e.target.parentNode
        if (e.target.id === "img") {
            for (let j = 0; j < 2; j++) {
                parentElement = parentElement.parentNode
            }
            if (parentElement.classList.contains("active")) {
                parentElement.classList.remove("active")
            }
            else {
                parentElement.classList.add("active")
            }
        }
        else if (e.target.id === "anchor") {
            parentElement = parentElement.parentNode
            if (parentElement.classList.contains("active")) {
                parentElement.classList.remove("active")
            }
            else {
                parentElement.classList.add("active")
            }
        }
    }
    const formateData = (newFormData?, index?, event?) => {
        // console.log("formData[selectedIndex]", formData, selectedIndex, newFormData, index)
        // let data = objectDeepClone(newFormData && index !== null && index !== undefined ? newFormData[index] : formData[selectedIndex])
        let data = newFormData && index !== null && index !== undefined ? objectDeepClone(newFormData[index]) : objectDeepClone(formData[activeSetIndex])
        event ?
            data.events.forEach((item, index) => {
                item.images.forEach(el => {
                    if (el.imageSt && el.imageMt) {
                        if (el.imageStKey) {
                            el.imageSt = el.imageStKey
                            delete el.imageStKey;
                        }
                        if (el.imageMtKey) {
                            el.imageMt = el.imageMtKey
                            delete el.imageMtKey;
                        }
                    }
                })
            })
            :
            data.inventories.forEach((item, index) => {
                item.images.forEach(el => {
                    if (el.imageSt && el.imageMt) {
                        if (el.imageStKey) {
                            el.imageSt = el.imageStKey
                            delete el.imageStKey;
                        }
                        if (el.imageMtKey) {
                            el.imageMt = el.imageMtKey
                            delete el.imageMtKey;
                        }
                    }
                })
            })
        // data.inventories.forEach(inventory => {
        //     inventory.approvalStatus = "pending"
        // })
        return {
            _id: data._id || "",
            [event ? "events" : "inventories"]: event ? data.events : data.inventories,
            schoolId: schoolId,
            campaignId: campaignId,
            // setApprovalStatus: "pending",
            location: userLocation,
            latLng: [...latLong],
        }
    }
    const saveCampaignReportData = async (confirm, data) => {
        if (confirm) {
            if (data === "inventory") {
                let newSet = { inventories: [] }
                let inventories = []
                inventoryList.forEach(inventory => {
                    let obj = { name: inventory.name, images: [], inventoryId: inventory._id }
                    inventories.push(obj)
                })
                inventories.push({ name: "other", images: [], inventoryId: "" })
                newSet.inventories = [...inventories]
                console.log("arrdata", newSet, [...formData, newSet])
                let newFormData = [...formData, newSet]
                let apiData = formateData(newFormData, newFormData.length - 1)
                let addResp
                if (newFormData[formData.length]._id && newFormData[formData.length]._id !== "") {
                    addResp = await context.apis[context.user.role].updateCampaignReportingSet(
                        context.validator, { _id: newFormData[formData.length]._id }, apiData
                    )
                }
                else {
                    apiData.setNo = newFormData.length
                    addResp = await context.apis[context.user.role].addCampaignReportingSet(
                        context.validator, apiData, {}
                    )
                }
                console.log("updateCampaignReportingSet", addResp, apiData)
                if (addResp && addResp.done) {
                    setRefetch(!refetch)
                    setCreatingSet(false)
                }
            }
            else {
                let newSet = { events: [] }
                let events = []
                eventList.forEach(event => {
                    let obj = { name: event.name, images: [], inventoryId: event._id }
                    events.push(obj)
                })
                events.push({ name: "other", images: [], inventoryId: "" })
                newSet.events = [...events]
                console.log("arrdata", newSet, [...formData, newSet])
                let newFormData = [...formData, newSet]
                let apiData = formateData(newFormData, newFormData.length - 1, "event")
                let addResp
                if (newFormData[formData.length]._id && newFormData[formData.length]._id !== "") {
                    addResp = await context.apis[context.user.role].updateCampaignReportingSet(
                        context.validator, { _id: newFormData[formData.length]._id }, apiData
                    )
                }
                else {
                    apiData.setNo = newFormData.length
                    addResp = await context.apis[context.user.role].addCampaignReportingSet(
                        context.validator, apiData, {}
                    )
                }
                console.log("updateCampaignReportingSet", addResp, apiData)
                if (addResp && addResp.done) {
                    setRefetch(!refetch)
                    setCreatingSet(false)
                }
            }
            // }
        }
        setOverlay(false)
        setShowDataModal(false)
    }
    const askPermission = () => {
        setShowLocationAccessPopup(false)
        setOverlay(false)
        askLocationAccessPermission(true)
    }

    const createInventorySet = () => {
        saveCampaignReportData(true, "inventory")
    }
    const createEventsSet = () => {
        saveCampaignReportData(true, "event")
    }
    const formateFileName = (name, postFix) => {
        let array = name.split(".")
        let newStr = ''
        array.forEach((item, index) => {
            if (index < array.length - 2) {
                newStr += item
            }
            else if (index === array.length - 2) {
                newStr += item + postFix
            }
            else if (index === array.length - 1) {
                newStr += '.' + item
            }
        })
        return newStr
    }

    const handleUpload = async (event, files, setIndex, inventoryIndex) => {
        const formData1 = new FormData();
        setShowImageError(false)
        setNotSupportedImageError("")
        setImagesCount([])
        setIsImageLoading(true);
        setActiveSetIndex(setIndex)
        setActiveInventoryIndex(inventoryIndex)
        // for (let i = 0; i < 3; i++) {
        if (!files[0].type.startsWith("image/")) {
            setShowImageError(true)
            setNotSupportedImageError(files[0].name)
            event.target.value = null;
            setIsImageLoading(false)
            setLoadingImgIndex(-1)
            setActiveSetIndex(-1)
            setActiveInventoryIndex(-1)
            return
        }
        setImagesCount(prev => [
            ...prev,
            files[0]
        ])
        setImageUploading(true)
        for (let i = 0; i < 3; i++) {
            const resizedImage = await compress.compress([files[0]], {
                size: 20, // the max size in MB, defaults to 2MB
                quality: i === 0 ? 0.4 : i === 1 ? 0.2 : 0.1, // the quality of the image, max is 1,
                resize: false // defaults to true, set false if you do not want to resize the image width and height
            })
            const img = resizedImage[0];
            const base64str = img.data
            const imgExt = img.ext
            let fileName = (i === 0 ? img.alt : i === 1 ? formateFileName(img.alt, "_mt") : formateFileName(img.alt, "_st"))
            console.log("fileName", fileName)
            let compressedFile = Compress.convertBase64ToFile(base64str, imgExt);
            console.log("resizedImage", resizedImage, imgExt, compressedFile, img.alt, fileName)
            formData1.append(`image`, compressedFile, fileName);
        }
        // }
        event.target.value = null
        let reqBody = formData1
        const requestOptions = {
            method: 'POST',
            body: reqBody
        };

        let publicContextInitiator = new PublicContextInitiator(context)
        fetch('/api/uploadFiles', requestOptions)
            .then(response => {
                response.json()
                    .then(res => {
                        let data = [];
                        res["response"].map(async item => {
                            data.push(item.Key)
                        })
                        publicContextInitiator.getUploadedImagesSignedUrl(
                            context.validator, { keys: data }
                        ).then(res => {
                            let updateImageObj = []
                            if (formData[setIndex].inventories) {
                                updateImageObj = [...formData[setIndex].inventories[inventoryIndex].images]
                            }
                            else {
                                updateImageObj = [...formData[setIndex].events[inventoryIndex].images]
                            }
                            console.log("res.response", res.response)
                            updateImageObj.push({uploadDate: moment().format("DD-MM-YYYY"), uploadTime: moment().format("HH:mm:ss"), image: !(res.response[0].key.includes('_mt') || res.response[0].key.includes('_st')) ? res.response[0].image : !(res.response[1].key.includes('_mt') || res.response[1].key.includes('_st')) ? res.response[1].image : res.response[2].image, key: !(res.response[0].key.includes('_mt') || res.response[0].key.includes('_st')) ? res.response[0].key : !(res.response[1].key.includes('_mt') || res.response[1].key.includes('_st')) ? res.response[1].key : res.response[2].key, imageMt: res.response[0].key.includes("_mt") ? res.response[0].key : res.response[1].key.includes("_mt") ? res.response[1].key : res.response[2].key, imageSt: res.response[0].key.includes("_st") ? res.response[0].key : res.response[1].key.includes("_st") ? res.response[1].key : res.response[2].key, fosRemarks: "", salesRemarks: "", approvalStatus: "pending" });
                            setDisplayImage(res.response[0].image)
                            let newFormData = objectDeepClone(formData)
                            let selectedSet = objectDeepClone(formData[setIndex])
                            let selectedInventory = formData[setIndex].inventories ? objectDeepClone(formData[setIndex].inventories[inventoryIndex]) : objectDeepClone(formData[setIndex].events[inventoryIndex])
                            selectedInventory.images = [...updateImageObj]
                            formData[setIndex].inventories ? selectedSet.inventories.splice(inventoryIndex, 1, selectedInventory) : selectedSet.events.splice(inventoryIndex, 1, selectedInventory)
                            newFormData.splice(setIndex, 1, selectedSet)
                            setActiveImageIndex(newFormData[setIndex].inventories ? newFormData[setIndex].inventories[inventoryIndex].images.length - 1 : newFormData[setIndex].events[inventoryIndex].images.length - 1)
                            setFormData([...newFormData])
                            setImageUploading(false)
                            setShowUploadView(true)
                            setShowRetakeModal(true)
                        })
                    })
            }
            );
    }
    const handleSave = async (addComment?, upload?) => {
        let apiData = formateData(null, null, formData[activeSetIndex].events && "event")
        console.log("appiData", apiData)
        let addResp = await context.apis[context.user.role].updateCampaignReportingSet(
            context.validator, { _id: formData[activeSetIndex]._id }, apiData
        )
        console.log("updateCampaignReportingSet", addResp)
        if (addResp && addResp.done) {
            toast.success(upload ? "Image uploaded successfully" : "Data saved successfully")
            if (addComment && addComment !== "") {
                let resp = await context.apis[context.user.role].addCommentForCampaignReport(
                    context.validator, { setId: addResp.response._id, imageId: addResp.response.inventories ? addResp.response.inventories[activeInventoryIndex].images[activeImageIndex]._id : addResp.response.events[activeInventoryIndex].images[activeImageIndex]._id, comment: addComment, userRole: "backendFOS", commentBy: context.user.fullName }
                )
            }
            setRefetch(!refetch)
            setShowRetakeModal(false)
        }
    }
    const handleDelete = async (data, actionIndex) => {
        let apiData = formateData(data, actionIndex, formData[activeSetIndex].events && "event")
        let addResp = await context.apis[context.user.role].updateCampaignReportingSet(
            context.validator, { _id: formData[activeSetIndex]._id }, apiData
        )
        if (addResp && addResp.done) {
            setRefetch(!refetch)
            setShowRetakeModal(false)
        }
    }

    const showRetakeImage = (setIndex, inventoryIndex, imageIndex) => {
        setShowUploadView(false)
        setActiveSetIndex(setIndex)
        setActiveInventoryIndex(inventoryIndex)
        setActiveImageIndex(imageIndex)
        setShowRetakeModal(true)
    }
    return (
        <>
            <div className={`ovelap ${overlay ? "active" : ""}`}></div>
            {(isLoading || imageUploading || userLocation === "" ) ?
                <Loader />
                :
                <div className="" style={{ width: "100%" }}>
                    <div className="logo-header header-flex-container">
                        {/* <div className="logos"> */}
                        <img onClick={() => history.goBack()} className="back-arrow" src="/assets/FOSimg/back-arrow.png" />
                        <img className="wisrLogo" src="/assets/FOSimg/wisr-logo.png" />
                        {/* </div> */}
                        {/* <div className="logos"> */}
                        <img className="maskgrouplogo" src="/assets/FOSimg/maskgrouplogo.png" />
                        {/* </div> */}
                    </div>

                    {showRetakeModal ? showUploadView ?
                        <CampaignReportingUploadImage
                            activeSetIndex={activeSetIndex}
                            activeInventoryIndex={activeInventoryIndex}
                            activeImageIndex={activeImageIndex}
                            setShowRetakeModal={setShowRetakeModal}
                            formData={formData}
                            currentImage={formData[activeSetIndex].inventories ? formData[activeSetIndex].inventories[activeInventoryIndex].images[activeImageIndex] : formData[activeSetIndex].events[activeInventoryIndex].images[activeImageIndex]}
                            setUploadView={setShowUploadView}
                            setFormData={setFormData}
                            onSave={handleSave}
                        />
                        :
                        <ExampleImage
                            activeSetIndex={activeSetIndex}
                            activeInventoryIndex={activeInventoryIndex}
                            activeImageIndex={activeImageIndex}
                            setActiveImageIndex={setActiveImageIndex}
                            setShowRetakeModal={setShowRetakeModal}
                            showImagePreview={showRetakeModal}
                            formData={formData}
                            uploadView={showUploadView}
                            setUploadView={setShowUploadView}
                            setFormData={setFormData}
                            onSave={handleSave}
                            onDelete={handleDelete}
                        />
                        :
                        <div className="ws-container">

                            <div className="heading headTitleContainer" style={{ margin: '0' }}>
                                <div className="headTitle headerTitleText">
                                    <h2>Campaign Reporting</h2>
                                    <p style={{ color: "#8A8A8A", fontFamily: "nexaregular", fontSize: "16px", marginBottom: '20px', marginTop: "10px" }}>{`${campaignName} - ${schoolName}`}</p>
                                </div>

                                {/* <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}> */}
                                {/* <div style={{ display:"flex", justifyContent:"center", alignItems:"center", height:"50vh" }}> */}
                                {formData.length > 0 ?
                                    <div className="faq-view mb-50">
                                        <div className="faq-tabs-list mb-50">
                                            {formData.map((item, index) => (
                                                <div key={index} className={activeSetIndex !== -1 ? index === activeSetIndex ? "faq-tablist active" : "faq-tablist" : index === 0 ? "faq-tablist active" : "faq-tablist"} style={{ float: 'left', width: '100%', padding: '10px', backgroundColor: "#F8F8F8" }}>

                                                    {/* <!-- First Panel --> */}

                                                    <h4><a id="anchor" style={{ cursor: "pointer" }} onClick={(e) => handleClick(e, index)}>{`Set ${index + 1}`} <img id="img" src={faqArrow} /></a></h4>
                                                    <div className="faq-tablistView">
                                                        <div className="">
                                                            {item.inventories ?
                                                                item.inventories.length > 0 && item.inventories.map((inventory, inventoryIndex) => (
                                                                    <div className={`col-xs-12 s-bg ${inventory.overallStatus === "rejected" ? "rejectedBorder" : inventory.overallStatus === "approved" && "approvedBorder"}`}>
                                                                        <div className="col-xs-6" style={{ margin: "auto 0", padding: '0' }}>
                                                                            <div style={{ fontWeight: "bold", fontSize: "16px" }}>{inventory.name}</div>
                                                                            <div style={{ color: inventory.overallStatus === "rejected" ? "#FF4A4A" : inventory.overallStatus === "approved" ? "#2EC193" : "#B4A51F", fontSize: "12px", position: "relative" }}><img style={{ position: "absolute", left: 0, top: '-2px', height: "14px", width: "14px" }} src={inventory.overallStatus === "approved" ? checkSign : inventory.overallStatus === "rejected" ? verySmallClose : pendingImg} /><span style={{ marginLeft: "17px" }}>{titleCase(inventory.overallStatus)}</span></div>
                                                                        </div>

                                                                        <div className="col-xs-6" style={{padding: "0"}}>
                                                                                <div className="col-xs-9 crImg flexBoxImg" style={{ padding: '0' }}>
                                                                                    {inventory.images && inventory.images.length > 0 && inventory.images.map((el, imageIndex) => (
                                                                                        imageIndex < 3
                                                                                            ?
                                                                                            <span style={{ position: "relative", order: 2 - imageIndex }}>
                                                                                                <img style={{ margin: '0 5px 0 0', borderRadius: "5px", border: el.approvalStatus === "rejected" ? "2px solid #FF4A4A" : el.approvalStatus === "approved" && "2px solid #2EC193" }} src={el.imageSt ? el.imageSt : el.imageMt ? el.imageMt : el.image} width='34px' height='34px' onClick={() => showRetakeImage(index, inventoryIndex, imageIndex)} />
                                                                                                {imageIndex === 2 && inventory.images.length >= 4 && <a style={{ cursor: "pointer", position: 'absolute', top: '50%', right: "55%", margin: "auto", transform: "translate(50%, -50%)", color: "white", textShadow: "2px 2px #000" }} data-target="brands-popup" className="brands-popup-count" onClick={() => showRetakeImage(index, inventoryIndex, imageIndex + 1)} >{`+${inventory.images.length - 3}`}</a>}
                                                                                            </span>
                                                                                            :
                                                                                            ""
                                                                                    ))}
                                                                                </div>
                                                                                {context.user.role === "backendFOS" && isMobileDevice &&
                                                                                    <div className="image uploadImgDesign col-xs-3" style={{ padding: '0' }} >
                                                                                        <label className="uploadImgPlus" style={{ width: '34px', height: '34px', lineHeight: "38px" }}>+</label>
                                                                                        <input height='34px' width='34px' name="amenityImages" capture="environment" type="file" accept="image/*" title="" onChange={(event) => handleUpload(event, event.target.files, index, inventoryIndex)} />
                                                                                    </div>
                                                                                }
                                                                        </div>
                                                                    </div>
                                                                ))
                                                                :
                                                                item.events && item.events.length > 0 && item.events.map((event, inventoryIndex) => (
                                                                    <div className={`col-xs-12 s-bg ${event.overallStatus === "rejected" ? "rejectedBorder" : event.overallStatus === "approved" && "approvedBorder"}`}>
                                                                        <div className="col-xs-6" style={{ marginTop: "auto 0", padding: '0' }}>
                                                                            <div style={{ fontWeight: "bold", fontSize: "16px" }}>{event.name}</div>
                                                                            <div style={{ color: event.overallStatus === "rejected" ? "#FF4A4A" : event.overallStatus === "approved" ? "#2EC193" : "#B4A51F", fontSize: "12px", position: "relative" }}><img style={{ position: "absolute", left: 0, top: '-2px', height: "14px", width: "14px" }} src={event.overallStatus === "approved" ? checkSign : event.overallStatus === "rejected" ? verySmallClose : pendingImg} /><span style={{ marginLeft: "17px" }}>{event.overallStatus}</span></div>
                                                                        </div>

                                                                        <div className="col-xs-6" style={{padding: "0"}}>
                                                                                <div className="col-xs-9 crImg flexBoxImg" style={{ padding: '0' }}>
                                                                                    {event.images && event.images.length > 0 && event.images.map((el, imageIndex) => (
                                                                                        imageIndex < 3 ?
                                                                                            <span style={{ position: "relative", order: 2 - imageIndex }}>
                                                                                                <img style={{ margin: '0 5px 0 0', borderRadius: "5px", border: el.approvalStatus === "rejected" ? "2px solid #FF4A4A" : "none" }} src={el.imageSt ? el.imageSt : el.imageMt ? el.imageMt : el.image} width='34px' height='34px' onClick={() => showRetakeImage(index, inventoryIndex, imageIndex)} />
                                                                                                {imageIndex === 2 && event.images.length >= 4 && <a style={{ cursor: "pointer", position: 'absolute', top: '50%', right: "55%", margin: "auto", transform: "translate(50%, -50%)", color: "white", textShadow: "2px 2px #000" }} data-target="brands-popup" className="brands-popup-count" onClick={(e) => showRetakeImage(index, inventoryIndex, imageIndex + 1)} >+{event.images.length - 3}</a>}
                                                                                            </span>
                                                                                            :
                                                                                            ""
                                                                                    ))}
                                                                                </div>
                                                                                {context.user.role === "backendFOS" && isMobileDevice &&
                                                                                    <div className="image uploadImgDesign col-xs-3" style={{ padding: '0' }} >
                                                                                        <label className="uploadImgPlus" style={{ width: '34px', height: '34px', lineHeight: "38px" }}>+</label>
                                                                                        <input height='34px' width='34px' name="amenityImages" capture="environment" type="file" accept="image/*" title="" onChange={(event) => handleUpload(event, event.target.files, index, inventoryIndex)} />
                                                                                    </div>
                                                                                }
                                                                        </div>
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    :
                                    <div style={{ verticalAlign: "middle", textAlign: "center", marginTop: "50%" }}>

                                        <img src='/assets/FOSimg/create-set.png' alt="" />
                                        <h3 className="h3"> CREATE NEW SET </h3>
                                        <p style={{ fontSize: "20px", color: "#8A8A8a", fontFamily: "nexaregular" }}> To upload inventory images create new set or event </p>
                                    </div>
                                }
                                {context.user.role === "backendFOS" &&
                                    <>
                                        {creatingSet ?
                                            <>
                                                {inventoryList && inventoryList.length > 0 &&
                                                    <div className="">
                                                        <a href="javascript:void(0);" className={`btn btn-orange bg-transparent ${eventList.length > 0 ? "invButton" : "invButton invButtonBottom"}`} onClick={createInventorySet}>Inventory </a>
                                                    </div>
                                                }
                                                {eventList && eventList.length > 0 &&
                                                    <div className="">
                                                        <a href="javascript:void(0);" className="btn btn-orange bg-transparent eventButton" onClick={createEventsSet}>Event </a>
                                                    </div>
                                                }
                                                <div className="">
                                                    <a href="javascript:void(0);" className="btn btn-orange bg-transparent closeButton" onClick={() => setCreatingSet(false)}> x </a>
                                                </div>
                                            </>
                                            :
                                            <div className="">
                                                <a href="javascript:void(0);" className="btn btn-orange createSetButton" onClick={() => setCreatingSet(true)}>CREATE SET + </a>
                                            </div>
                                        }
                                    </>
                                }
                                {/* <div className="headTitle searchBoxContainer">
                                    
                                    
                                </div> */}
                            </div>

                            {/* CardBox starts  */}

                        </div>
                    }
                </div>
            }
            {/* <ImageRetakeModal
                onModalClose={onRetakeModalClose}
                togglePopup={showRetakeModal}
                displayImage={displayImage}
            /> */}
            {/* <ConfirmDialog
                togglePopup={showLocationAccessPopup}
                onModalClose={askPermission}
                componentName={""}
                confirmTitle="Please enable your device location and refresh this page to continue"
            /> */}
            <NoControlDialog
                togglePopup={showLocationAccessPopup}
                displayMsg="Please enable your device location and refresh this page to continue"
            />
        </>
    )
}


export default CreateNewCampaignSet
