import { useState, useEffect, useContext } from "react"

import { useHistory, useLocation } from 'react-router-dom'

import { validation } from "../../../service/validators"

import PasswordField from "../../../components/formFields/passwordField"

import logo from "../../../assets/images/logo.png"
import loginWave from "../../../assets/images/login-wave.svg"
import { PublicContextInitiator } from "../../apis/publicContextInitiator"
import AppContextObject from "../../../common/context/common"
import { eraseAllCookies, eraseCookie } from "../../../service/helper"
import OnboardingBackBtn from "../schools/components/onboardingBackBtn"
import Loader from "../../../components/addon/loader"

const ResetPassword = () => {

    const useQuery = () => {
        return new URLSearchParams(useLocation().search)
    }
    const context = useContext(AppContextObject)
    const queryParams = useQuery()

    let [formErrors, setFormErrors] = useState<any>({})

    let [data, setData] = useState({
        newPassword: "",
        confirmNewPassword: ""
    })

    const [emailId, setEmailId] = useState("")
    const [userData, setUserData] = useState<any>({
        username : "noData",
    })
    const [doNotLogout,setDoNotLogout] = useState(false)
    const [dataLoading, setDataLoading] = useState(false)
    useEffect(() => {
        const email = queryParams.get("email")
        const token = queryParams.get("token")
        if (email) {
            setEmailId(email)
        }
    }, [])
    useEffect(() => {
        console.log("Userdata history.location.state",history.location.state)
        if(history.location.state){
            const tempData: any = history.location.state
            console.log(" Userdata htempData",tempData)
            let data = {
                username : tempData.username,
            }
            setUserData(data)
            if(tempData.doNotLogout){
                setDoNotLogout(true)
            }
            console.log("User data", userData)
        }else {
            let data = {
                username : "",
            }
            setUserData(data)
            setDoNotLogout(false)
            history.push("/forgot-password")
        }
        
       
    },[])
    const onFormDataChange = (event) => {
        setData({
            ...data,
            [event.target.id]: event.target.value
        })
        
        if(event.target.value){
            setFormErrors({...formErrors, [event.target.id]: "" })
        }
    }

    if (userData.username == "") {
        if (!context.apis || !context?.user?.role) {
            document.location.reload()
        } 
    }


    const history = useHistory()

    const onSubmit = async () => {
        const errors = validation(data)
        setFormErrors(errors)
        if (Object.keys(errors).length > 0) {
            return
        }
    console.log('userData===>',userData)
        if(userData.username !== "") {
            let resp1 = await context.apis.public.findUserByUsername(
                context.validator, { "username": userData.username }
            )
            console.log("finduser resp", resp1)
            let userId = ""
            if (resp1 && resp1.done === false) {
                userId = resp1.response[0]._id
            }
            console.log("USER ID ", userId)
            let updatingData = { password: data.newPassword }
            if(doNotLogout){
                updatingData["isVerified"] = true
                let data = sessionStorage.getItem('updateUserDetailsData')
                let respUpdateDetails = await context.apis[context.user.role].updateUserDetails(
                    context.validator, {}, JSON.parse(data)
                )
                if(respUpdateDetails && respUpdateDetails.done){
                }
            }
            console.log("updatingData---->",updatingData);
            let publicContextInitiator = new PublicContextInitiator(context)
            let resp = await publicContextInitiator.changeUserPasswordExternal(
                context.validator, {"_id" : userId}, updatingData
            )
            console.log("api call changeUserPasswordExternal ");
            console.log("change password response", resp)
            if (resp && resp.done) {
                if(context.user){
                    context.user.isVerified = true
                }
                sessionStorage.removeItem('updateUserDetailsData')

                logoutUser()
                //commented becuase user was not getting logout and was redirecting directly to dashboard
                // if(doNotLogout){
                //     history.replace({
                //         pathname: '/login',
                //     })
                //     logoutUser()
                // }
                // else {
                //     history.replace({
                //         pathname: '/password-reset-success',
                //     })
                // }
                
            }
            else {
                let errr = {};
                errr["confirmNewPassword"] = resp.msg;
                setFormErrors(errr)
            }
        }
        else {
            console.log('context role=====>',context.user.role)
            let resp = await context.apis[context.user.role].changeUserPassword(
                context.validator, {}, { password: data.newPassword, isVerified: true }
            )
            console.log("api call changeUserPassword ");
            if (resp && resp.done) {
                // history.push("/login")
                logoutUser()
            }
            else {
                let errr = {};
                errr["confirmNewPassword"] = resp.msg;
                setFormErrors(errr)
            }
        }


    }
    const logoutUser = () =>{
        setDataLoading(true)
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
        };

        fetch("/api/logout", requestOptions)
        .then(response => response.json())
        .then(data => {
            if(data.done){
                eraseAllCookies();
                eraseCookie("session-wisr-core-microservice-backend")
                context.socketManager.socket.disconnect();
                // document.location.href = "/login"
                
                if(sessionStorage.getItem("otpVerified")){
                    setDataLoading(false)
                    history.replace({
                        pathname: '/password-reset-success',
                    })
                }
                else {
                    setDataLoading(false)
                    history.replace({
                        pathname: '/login',
                    })
                }
                sessionStorage.removeItem("otpVerified")
                sessionStorage.removeItem("forgotUsername")
                sessionStorage.clear()
            }
        }).catch(err=>{
            console.log(err);
        })
    }

    const handleOnKeyPress = (event) => {
        if (event.key.toLowerCase() === "enter") {
          const form = event.target.form;
          const index = [...form].indexOf(event.target);
          form.elements[index + 1].focus();
          event.preventDefault();
          if(event.target.id === "confirmNewPassword")
          {
            onSubmit();
          }
        }
    }
    
    return (
        <>
            {
            dataLoading
            ?
            <Loader />
            :
            <>
            <div className="ovelap"></div>
            <div className="main-wapper">
                <div className="loginbox">
                    <div className="login-wave"> <img src={loginWave} alt="login-wave" /> </div>
                    <div className="login-form">
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb20">
                                <img src={logo} alt="logo" className="mb-40" />
                                <div className="clearfix w100"></div>
                                <OnboardingBackBtn link='/forgot-password' text="BACK"/>
                                <h2 className="htx1 mt-10">Change Password</h2>
                            </div>
                        </div>
                        <form>
                            <div className="row">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                    <PasswordField
                                        label="New Password"
                                        isRequired={false}
                                        placeholder="Enter Password"
                                        formData={data}
                                        formKey="newPassword"
                                        onFormDataChange={onFormDataChange}
                                        formErrors={formErrors}
                                        maxLength={40}
                                        onKeyPress={(event) => handleOnKeyPress(event)}
                                        autoFocus={true}
                                    />
                                </div>
                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                    <PasswordField
                                        label="Confirm Password"
                                        isRequired={false}
                                        placeholder="Enter Password"
                                        formData={data}
                                        formKey="confirmNewPassword"
                                        onFormDataChange={onFormDataChange}
                                        formErrors={formErrors}
                                        maxLength={40}
                                        onKeyPress={(event) => handleOnKeyPress(event)}
                                    />
                                </div>
                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                    <div className="forminpt mb-0">
                                        <p className="ptx2">By clicking on done, the password <strong>{emailId}</strong> will be updated.</p>
                                    </div>
                                </div>
                                <div className="row col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                    <div className="forminpt">
                                        <input onClick={onSubmit} name="Done" value="Done" className="btn btn-orange datasubmit" type="button" />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            </>
            }
        </>
    )
}

export default ResetPassword
