import { useState, useEffect, useContext, useRef } from "react"

import { useHistory } from "react-router-dom"

import toast from "react-hot-toast"

import { validation } from "../../../service/validators"

import TextFieldWithIcon from "../../../components/formFields/textfieldWithIcon"
import PasswordField from "../../../components/formFields/passwordField"

import loginWave from "../../../assets/images/login-wave.svg"
import loginBgLeft from "../../../assets/images/landing/login-bg.svg"
import envelope from "../../../assets/images/user-icon.svg"
import logo from "../../../assets/images/logo.png"
import AppContextObject from "../../../common/context/common"
import SimpleReactValidator from "simple-react-validator"
import errorSVG from "../../../assets/images/error.svg"
import { getCookie, eraseAllCookies, specialCharOnly } from "../../../service/helper"
import Loader from "../../../components/addon/loader"
import OnboardingBackBtn from "../schools/components/onboardingBackBtn"

interface Props {
    setLoggedIn,
    methods
}
const Login = ({
    setLoggedIn,
    methods
}: Props) => {

    const history = useHistory()
    const context = useContext(AppContextObject)
    const [noActivity, setNoActivity] = useState(false)
    const [isAccountInactive, setIsAccountInactive] = useState(false)
    let mode = window.location.search?.substring(1).split("=")[1]
    let [formErrors, setFormErrors] = useState<any>({})
    let [loginError, setLoginError] = useState(null)
    let [isVerified, setIsVerified] = useState(false)
    const [usernameError, setUsernameError] = useState(false)
    const [data, setData] = useState({
        username: "",
        password: ""
    })
    const [locDetails, setLocDetails] = useState({
        ip: "",
        city: "",
        country: ""
    })
    const [loading, setLoading] = useState(false)
    const [formValidator] = useState(useRef(new SimpleReactValidator({
        element: (message, className) =>
            <span className="valError">
                <img src={errorSVG} />{message}
                </span>
        })))
        const [update, forceUpdate] = useState(false) // to re-render the dom after updating the validation
    useEffect(() => {
        // fetchUserIp()
        // checkIfUserLoggedIn();
        if(context && context.user){
            if(!history.location.state && (context.user.role === "backendFOS" || context.user.role === "backendSales" || context.user.role === "backendOperations" || context.user.role === "backendFinance" || context.user.role === "backendTech" || context.user.role === "backendAdmin")){
               if(context.user.role === "backendFOS"){
                history.push("/backend/backend-details/fos-details")
               }else{
                history.push("/backend/backend-details/dashboard")
               }
                
            }
            if(!history.location.state && !(context.user.role === "schoolPrincipal" || context.user.role === "schoolAdmin") ){
                history.push("/schools/school-details/dashboard")
            }
            if(!history.location.state && !(context.user.role === "brandCMO" || context.user.role === "brandManager") ){
                history.push("/brands/brand-details/dashboard")
            }
        }
    }, [history])

    useEffect(() => {
        sessionStorage.removeItem("otpVerified")
        sessionStorage.removeItem("userContactDetail")
        sessionStorage.removeItem("contactVerification")
        sessionStorage.removeItem("apiData")
        sessionStorage.removeItem("reVerify")
        sessionStorage.removeItem("contactDetails")
        sessionStorage.removeItem("forgotUsername")
    }, [])
    useEffect(() => {
        if(mode && mode === "noActivity"){
            setNoActivity(true)
        }else{
            setNoActivity(false)
        }
    }, [mode])
    const checkIfUserLoggedIn = () => {
        console.log(50, context.user, getCookie('session-wisr-core-microservice-backend'));
        if(context.user && getCookie('session-wisr-core-microservice-backend')){
            if(context.user.role == 'schoolAdmin' || context.user.role == 'schoolPrincipal') {
                window.alert('Already logged in another tab by some other/same user. Kindly logout from previous tab and try again, if press "OK" then will open previous logged-in user pages');
                //"You are already LoggedIn in another Tab" 
                history.push("/schools/school-details/dashboard");
                // window.alert('You are already LoggedIn in another Tab, Logging Out');
                // eraseAllCookies();
                // context.socketManager.socket.disconnect();
                // document.location.href = "/"
                // sessionStorage.clear()
            }
        }
    }

    const fetchUserIp = () =>{
        console.log("ip--",sessionStorage.getItem('ip'));
        if(sessionStorage.getItem('ip') === null){
        setLoading(true)
            try{
                fetch('https://geolocation-db.com/json/')
                .then(response => response.json())
                .then(async data =>{
                    console.log("data>>>>>>>",data);
                    setLocDetails({
                        ip: data.IPv4 || "",
                        city: data.city || "",
                        country: data.country_name || ""
                    })
                    sessionStorage.setItem('ip', data.IPv4)
                    setLoading(false)
                }).catch(err=>{
                    setLoading(false)
                    setLocDetails({
                        ip: "127.0.0.1",
                        city: "",
                        country: ""
                    })
                    console.log(err);
                    sessionStorage.setItem('ip', "127.0.0.1")
                    // document.location.reload()
                })
            }catch(e){
                console.log(e);
            }
        } else {
            setLoading(false)
        }
        
    }

    const onFormDataChange = (event) => {
        setData({
            ...data,
            [event.target.id] : event.target.value
        })
        if(event.target.id === "username"){
            setUsernameError(false)
            setData({
                ...data,
                username : event.target.value.trim().toLowerCase()
            }) 
        }
        if(event.target.id == "password") {
            setData({
                ...data,
                password : event.target.value.trim()
            }) 
            if(event.target.value.length > 0) {
                setFormErrors({ newPassword: " " })
                setLoginError("")
            }

        }
    }

    let error = ""
    formValidator.current.rules['user_name'] = {
        message: error === "required" ? 'The user name field is required.' : "",//  : usernameError === undefined ? 'The user name field is required.' :'',
        rule: (val) => {
            let returnValue = true
            if (!val || val.trim() === "") {
                error = "required"
                returnValue = false
            }
            return returnValue
        },
    }
    formValidator.current.rules['valid_user_name'] = {
        message: 'Username can not contain only special character.',
        rule: (val) => {
            let returnValue = true
            if(!specialCharOnly(val)){
                returnValue = false
            }
            return returnValue
        }
    }


    const onSubmit = async () => {
        // checkIfUserLoggedIn();
        // if(data.username.length < 1){
        //     setUsernameError(undefined)
        //     formValidator.current.showMessageFor("username")
        //     return
        // }
        const errors = validation(data)
        setFormErrors(errors)
        const result = formValidator.current.allValid()

        if (result === false || Object.keys(errors).length > 0) {
            setLoginError("")
            formValidator.current.showMessages()
            forceUpdate(true)
            return
        } else {
            formValidator.current.hideMessages()
            forceUpdate(!update)
        }
        // const errors = validation(data)
        // setFormErrors(errors)
        if (Object.keys(errors).length > 0) {
          return
        }
        // Setting current tab loggedIn app variable to false as it has landed on login page
        if(typeof setLoggedIn === "function") {
            setLoggedIn(false);
        }
        
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json'},
            body: JSON.stringify({username: data.username, password: data.password, ip: locDetails.ip, city: locDetails.city, country: locDetails.country})
        };

        fetch("/api/login", requestOptions)
        .then(response => response.json())
        .then(async data => {
            methods.reRender()
            // console.log("data", JSON.stringify(data))
            // if (localStorage.getItem("prevUserId") === null) {
            //     localStorage.setItem("prevUserId", data.result._id)
            // }
            // localStorage.setItem("currentUserId", data.result._id)
            console.log("data login",data);
            if(data.result && data.result.done){
                // console.log(120, data.result, data.result._id);
                if(data.result.role === "backendFOS" && data.result.isVerified == true && data.result.loggedIn === false && data.result.accountStatus === "inactive") {
                    setIsAccountInactive(true)
                    return
                }
                console.log("data is", data)
                sessionStorage.setItem('currentUser', data.result._id);
                console.log("locDetails--",locDetails);
                sessionStorage.setItem('ip', data.result.ip || null)
                sessionStorage.setItem('locationActivityId',data.result.locationId)
                toast.success("Logged in successfully")
                // if(data.result.role === "backendAdmin"){
                //     history.push("/schools-listing")
                // }
                if((data.result.changePassword === true || data.result.isVerified == false)){
                    if(data.result.isVerified == false) {
                        // history.push({
                        //     pathname: '/user-verification',
                        //     state: {
                        //         formLogin : true,
                        //     },
                        //   })
                        window.location.href = "/user-verification"
                    }else if((data.result.role === "backendFOS" || data.result.role === "backendSales" || data.result.role === "backendOperations" || data.result.role === "backendFinance" || data.result.role === "backendTech" || data.result.role === "backendAdmin") && data.result.changePassword === true && data.result.isVerified == true){
                        // history.push("/backend/backend-details/dashboard")
                        if(data.result.role === "backendFOS"){
                            window.location.href = "/backend/backend-details/fos-details"
                        }
                        else{
                            window.location.href = "/backend/backend-details/dashboard"
                        }
                    }
                    else {
                        // history.push("/reset-password")
                        window.location.href = "/reset-password"
                    }
                }
                else if(data.result.role === "brandCMO" || data.result.role === "brandManager"){
                    // history.push("/brands/brand-details/dashboard")
                    window.location.href = "/brands/brand-details/dashboard"
                } else if((data.result.role === "backendFOS" || data.result.role === "backendSales" || data.result.role === "backendOperations" || data.result.role === "backendFinance" || data.result.role === "backendTech" || data.result.role === "backendAdmin" ) && data.result.isVerified == true){
                    // history.push("/backend/backend-details/dashboard")
                    if(data.result.role === "backendFOS"){
                        window.location.href = "/backend/backend-details/fos-details"
                    }
                    else{
                        window.location.href = "/backend/backend-details/dashboard"
                    }
                }else {
                    console.log("working--->>>");
                    // history.push("/schools/school-details/dashboard")
                    window.location.href = "/schools/school-details/dashboard"
                }
                // document.location.reload()
            }else{
                setUsernameError(null)
                setLoginError("Username or Password is incorrect.")
            }
        }).catch(err=>{
            setUsernameError(null)
            console.log("err-->>>",err);
            setLoginError("Something went wrong, please try again later.")
        })
    }
    const handleOnKeyPress = (event) => {
        if (event.key.toLowerCase() === "enter") {
          const form = event.target.form;
          const index = [...form].indexOf(event.target);
          form.elements[index + 1].focus();
          event.preventDefault();
          if(event.target.id === "password")
          {
            onSubmit();
          }
        }
    }
    
    return (
        <>
            <div className={`ovelap`}></div>
            {loading
                ?
                <Loader />
                :
                <div className="main-wapper">
                <div className="loginbox">
                    <div className="login-wave backendlogin"> <img src={loginBgLeft} alt="login-wave" /> </div>
                    <div className="login-form">
                        <div className="row">
                        <OnboardingBackBtn link='/' text="Back to home"/>
                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb10 mt-20">
                                <img src={logo} alt="logo" />
                                <h2 className="htx1">Log In</h2>
                            </div>
                        </div>
                        <form data-form-validation="login-form" id="login-form-parent">
                            <div className="row">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                    <TextFieldWithIcon
                                        label="Username"
                                        placeholder="Enter Username"
                                        isRequired={false}
                                        type="text"
                                        icon={envelope}
                                        formErrors={formValidator}
                                        formData={data}
                                        formKey="username"
                                        validation='required|user_name|valid_user_name'
                                        onFormDataChange={onFormDataChange}
                                        onKeyPress={(event) => handleOnKeyPress(event)}
                                        autoFocus={true}
                                    />
                                </div>
                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                    <PasswordField
                                        label="Password"
                                        isRequired={false}
                                        placeholder="Enter Password"
                                        formData={data}
                                        formErrors={formErrors}
                                        formKey="password"
                                        onFormDataChange={onFormDataChange}
                                        showForgotPasswordLink={true}
                                        forgotPasswordLink="/forgot-password"
                                        loginError={loginError}
                                        onKeyPress={(event) => handleOnKeyPress(event)}
                                    />
                                </div>
                                <div className="row col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                    <div className="forminpt">
                                        <input id="login" onClick={onSubmit} value="Login" className="btn btn-orange datasubmit" type="button" />
                                    </div>
                                </div>
                            </div>
                        </form>
                        {
                            noActivity && 
                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mt20">
                                <p className="fosNoActivityText">Whoops!, Looks like you're not there for long time.</p>
                            </div>
                            
                        }
                        {
                            isAccountInactive && 
                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                <div className="fosInactiveUser">
                                    <h4>Your account has been disabled.</h4>
                                    <p>To unlock, please check with your company admin or contact Wisr representative at <a href="mailto:helpdesk@wisr.co.in">helpdesk@wisr.co.in</a>
</p>
                                </div>
                            </div>
                            
                        }
                    </div>
                </div>
            </div>
            }
            
        </>
    )
}

export default Login
