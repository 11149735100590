
const Switch = (props) => {

    const {
        form,
        formKey,
        onValueChange
    } = props

    const onCheck = (event) => {
        onValueChange(event.target.checked)
    }

    return (
        <div className="content">
            <label className="switch">
            <input onChange={onCheck} type="checkbox"  value={form[formKey]} checked={form[formKey]} className="shohide" data-cls="onclass1" />
            <span className="slider round"></span> </label>
        </div>        
    )
}

export default Switch
