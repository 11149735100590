import { useState, useEffect, useContext } from "react"

import { useHistory } from "react-router-dom"

import toast from "react-hot-toast"

import { validation } from "../../../../service/validators"

import TextFieldWithIcon from "../../../../components/formFields/textfieldWithIcon"
import PasswordField from "../../../../components/formFields/passwordField"

import loginBgLeft from "../../../../assets/images/backendAdmin/login-bg.svg"
import envelope from "../../../../assets/images/envolpe.svg"
import logo from "../../../../assets/images/logo.png"
import AppContextObject from "../../../../common/context/common"

interface Props {
    methods,
    setLoggedIn
}

const BackendAdminLogin = ({
    methods,
    setLoggedIn
}: Props) => {

    const history = useHistory()
    const context = useContext(AppContextObject)

    let [formErrors, setFormErrors] = useState<any>({})
    let [loginError, setLoginError] = useState(null)
    const [data, setData] = useState({
        username: "",
        password: ""
    })
    const [ip, setIP] = useState("192.168.56.1")

    useEffect(() => {
        // fetchUserIp()
    }, [])

    useEffect(() => {
        if(history.location.state){
            const tempData: any = history.location.state
            console.log(" Userdata htempData",tempData)
            setData({
                ...data,
                username : tempData.username
            }
            )
        }
    }, [])

    const fetchUserIp = () =>{
        try{
            fetch('https://geolocation-db.com/json/')
            .then(response => response.json())
            .then(async data =>{
                console.log("data>>>>>>>",data);
                setIP(data.IPv4)
            })
        }catch(e){
            console.log(e);
        }
        
    }

    const onFormDataChange = (event) => {
        setData({
            ...data,
            [event.target.id] : event.target.value
        })
        if(event.target.id === "username"){
            setData({
                ...data,
                username : event.target.value.toLowerCase()
            }) 
        }
    }

    const onSubmit = async () => {
        const errors = validation(data)
        setFormErrors(errors)
        if (Object.keys(errors).length > 0) {
          return
        }
        if(typeof setLoggedIn === "function") {
            setLoggedIn(false);
        }
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json'},
            body: JSON.stringify({username: data.username, password: data.password, ip: ip})
        };

        fetch("/api/login", requestOptions)
        .then(response => response.json())
        .then(async data => {
            methods.reRender()
            if(data.result && data.result.done){
                toast.success("Logged in successfully")
                history.push("/brands/brand-details/dashboard")
            }else{
                setLoginError("Username or Password is incorrect.")
            }
        }).catch(err=>{
            setLoginError("Username or Password is incorrect.")
        })
    }

    const onSignUpClick = () => {
        history.push("/brands/sign-up")
    }

    return (
        <>
            <div className="ovelap"></div>
            <div className="main-wapper">
                <div className="loginbox">
                    <div className="login-wave backendlogin"> <img src={loginBgLeft} alt="login-wave" /> </div>
                    <div className="login-form">
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb10">
                                <img src={logo} alt="logo" />
                                <h2 className="htx1">Login in to Wisr</h2>
                                <p className="ptx mb-20">Start building your movement today!</p>
                            </div>
                        </div>
                        <form data-form-validation="login-form" id="login-form-parent">
                            <div className="row">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                    <TextFieldWithIcon
                                        label="Email ID"
                                        placeholder="Enter Email ID"
                                        isRequired={true}
                                        type="text"
                                        icon={envelope}
                                        formErrors={formErrors}
                                        formData={data}
                                        formKey="Enter Email ID"
                                        validation="Enter Email ID"
                                        onFormDataChange={onFormDataChange}
                                    />
                                </div>
                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                    <PasswordField
                                        label="Password"
                                        isRequired={true}
                                        placeholder="********"
                                        formData={data}
                                        formErrors={formErrors}
                                        formKey="password"
                                        onFormDataChange={onFormDataChange}
                                        showForgotPasswordLink={true}
                                        forgotPasswordLink="/brands/forgot-password"
                                        loginError={loginError}
                                    />
                                </div>
                                <div className='row col-xl-12 col-lg-12 col-md-12 col-xs-12 end-md'>
                                    <div className='forminpt'>
                                        <div className='checkbox onboardcheck'>
                                            <input
                                                type='checkbox'
                                                id='html-term'
                                            />
                                            <label style={{display: "inline"}} htmlFor='html-term'></label> <b>Keep me signed in</b>
                                        </div>
                                    </div>
                                </div>                                
                                <div className="row col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                    <div className="forminpt">
                                        <input onClick={onSubmit} value="Login" className="btn btn-orange datasubmit" type="button" />
                                    </div>
                                </div>
                                <div className="row col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                    <p className="centerTx">Don’t have a Wisr account? <a style={{cursor: "pointer", color:"#fe6e00"}} onClick={onSignUpClick}>SIGN UP</a></p>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BackendAdminLogin
