import {
    BrowserRouter as Router,
    Switch,
    Route,
  } from "react-router-dom"
  
import { ProtectedRoute } from "./protectedRoute"
import { PublicRoute } from "./publicRoute"

import Onboarding from "./pages/schools/onboarding/onboarding"
import UpdateSchoolDetails from "./pages/schools/updateDetails/updateDetails"
import InventoryWizard from "./pages/schools/updateDetails/inventory/inventoyWizard"
//   import RemainingInventories from "./pages/schools/updateDetails/inventory/remainingInventories"
import SchoolListing from "./pages/schools/schoolListing"
import Thankyou from "./pages/schools/onboarding/thankYou/thankYou"
import TestPage from "./pages/testPage"

import Login from "./pages/login/login"
import UserVerification from "./pages/login/userVerification"
import FosOnboardPage from "./pages/login/fosOnboardPage"
import ResetPassword from "./pages/login/resetPassword"
import EnterOTP from "./pages/login/enterOTP"
import AddEventDataForm from "./pages/addEventDataForm"

//Brands Routes
import BrandDetails from "./pages/brands/brandDetails"

import BrandsDashboard from "./pages/brands/dashboard"
import BrandsCampaign from "./pages/brands/campaign"
import BrandsProfile from "./pages/brands/profile/profile"

import BrandsProfileGeneral from "./pages/brands/profile/general"
import BrandsProfileDescription from "./pages/brands/profile/description"
import BrandsProfileAddUsers from "./pages/brands/profile/addUsers"
import BrandsProfileContact from "./pages/brands/profile/contact"
import BrandsProfileVerification from "./pages/brands/profile/verification"

import BrandsSupport from "./pages/brands/support"

import BrandsLogin from "./pages/brands/login/login"
import BrandsForgotPassword from "./pages/brands/login/forgotPassword"
import BrandsEnterOTP from "./pages/brands/login/enterOTP"
import BrandsResetPassword from "./pages/brands/login/resetPassword"
import BrandSettings from "./pages/brands/setting"
import SignUp from "./pages/brands/login/signUp"
import ContactDetails from "./pages/brands/login/contactDetails"
import WelcomeLogin from "./pages/brands/login/welcomeLogin"
import BackendForgotPassword from "./pages/backendAdmins/login/forgotPassword"
import BackendEnterOTP from "./pages/backendAdmins/login/enterOTP"
import BackendResetPassword from "./pages/backendAdmins/login/resetPassword"
import BackendSuccessLogin from "./pages/backendAdmins/login/successLogin"
import BackendWelcomeLogin from "./pages/backendAdmins/login/welcomeLogin"
import BackendAdminLogin from "./pages/backendAdmins/login/backendAdminLogin"
import CampaignList from "./pages/backendAdmins/campaignList"
import Dashboard from "./pages/backendAdmins/dashboard"
import SchoolList from "./pages/backendAdmins/schoolList"
import InventoryList from "./pages/backendAdmins/inventoryList"
import BrandList from "./pages/backendAdmins/brandList"
import FosList from "./pages/backendAdmins/fosList"
import BackendDetails from "./pages/backendAdmins/backendDetails"
import CampaignsReject from "./pages/backendAdmins/campaign/campaignsReject"
import CampaignsRequest from "./pages/backendAdmins/campaign/campaignsRequest"
import ForgotPassword from "./pages/login/forgotPassword"
import CampaignsOngoing from "./pages/backendAdmins/campaign/campaignsOngoing"
import CampaignDetails from "./pages/backendAdmins/campaign/campaignDetails"
import SchoolDetails from "./pages/backendAdmins/school/schoolDetails"
import SetUpCampaign from "./pages/backendAdmins/campaign/campaignCreation/setUpCampaign"
import LandingPage from "./pages/landingPage/landingPage"
import BrandManagerDetail from "./pages/brands/login/brandManagerDetail"
import NewUserContactDeatil from "./pages/brands/login/newUserContactDetail"
import PasswordChangeSuccess from "./pages/brands/login/successLogIn"
import { BackendProtectedRoute } from "./backendProtectedRoute"
import { SchoolProtectedRoute } from "./schoolProtectedRoute"
import { BrandProtectedRoute } from "./brandProtectedRoute"
import PasswordSuccess from "./pages/login/successLogin"

  interface Props {
    setLoggedIn, 
    methods
  }
  const AppRouter = ({
    setLoggedIn,
      methods
  }: Props) => {

        return (
            <Router getUserConfirmation={() => {}}>
                <Switch>
                    {/* Normal Routes start */}
                    <Route exact path='/fos-onboard-page' component={FosOnboardPage} />
                    <Route exact path='/'>
                        <LandingPage />
                    </Route>
                    <Route exact path='/addEvent/:id'>
                        <AddEventDataForm />
                    </Route>

                    <Route path='/schools/thank-you'>
                        <Thankyou />
                    </Route>

                    <Route path='/reset-password'>
                        <ResetPassword />
                    </Route>

                    <Route path="/brands/reset-password" component={BrandsResetPassword} />
                    <Route path="/brands/password-reset-success" component={PasswordChangeSuccess} />
                    <Route path="/backend/reset-password" component={BackendResetPassword} />
                    <Route path='/app' component={TestPage} />
                    
                    {/* Normal Routes end */}

                    {/* Please Don't change the ProtectedRoute and PublicRoute Below */}

                    {/* Protected routes start*/}
                    <SchoolProtectedRoute path='/schools/school-details/add-invetory' component={InventoryWizard} />
                    <SchoolProtectedRoute path='/schools/school-details' component={UpdateSchoolDetails} />

                    <BrandProtectedRoute path='/brands/brand-details' component={BrandDetails} />
                    <ProtectedRoute path = '/brands/verify-user' component={NewUserContactDeatil} />
                    {/* <ProtectedRoute path='/brands/dashboard' component={BrandsDashboard} /> */}
                    {/* <ProtectedRoute path='/brands/profile' component={BrandsProfile} /> */}
                    {/* <ProtectedRoute path='/brands/campaigns' component={BrandsCampaign} /> */}

                    {/* <ProtectedRoute path='/brands/profile/verification' component={BrandsProfileVerification} /> */}
                    {/* <ProtectedRoute path='/brands/profile/addUsers' component={BrandsProfileAddUsers} /> */}
                    <BackendProtectedRoute path='/schools-listing' component={SchoolListing} />
                    <BackendProtectedRoute path='/backend/backend-details' component={BackendDetails} />
                    <BackendProtectedRoute path='/backend/backend-details/campaign-reject' component={CampaignsReject} />
                    <BackendProtectedRoute path='/backend/backend-details/campaign-request' component={CampaignsRequest} />
                    <BackendProtectedRoute path='/backend/backend-details/campaign-ongoing' component={CampaignsOngoing} />
                    <BackendProtectedRoute path='/backend/backend-details/campaign-details' component={CampaignDetails} />
                    <BackendProtectedRoute path='/backend/backend-details/school-details' component={SchoolDetails} />
                    <BackendProtectedRoute path='/backend/backend-details/view/set-up-campaign' component={SetUpCampaign} />
                    <BackendProtectedRoute path='/backend/backend-details/schools/onboarding' component={Onboarding} />
                    {/* <ProtectedRoute path='/brands/support' component={BrandsSupport} />
                    <ProtectedRoute path='/brands/settings' component={BrandSettings} /> */}

                    {/* Protected routes end*/}
                            
                    {/* Public routes start */}
                    {/* <PublicRoute exact path='/' component={FosOnboardPage} /> */}
                        {/* <FosOnboardPage />
                    </PublicRoute> */}
                    <PublicRoute path="/login" component={Login} methods={methods} setLoggedIn={(val)=>{setLoggedIn(val)}} />
                    {/* <PublicRoute path="/login">
                        <Login methods={methods} setLoggedIn={(val)=>{console.log("data is", val); setLoggedIn(val)}} />
                    </PublicRoute> */}
                    <PublicRoute path="/forgot-password" component={ForgotPassword} />
                        {/* <ForgotPassword />
                    </PublicRoute> */}
                    <PublicRoute path="/password-reset-success" component={PasswordSuccess} />
                    <ProtectedRoute path="/user-verification" component={UserVerification}/>
                        {/* <UserVerification methods={methods} />
                    </PublicRoute> */}
                    {/* <PublicRoute path='/schools/onboarding' component={Onboarding} /> */}
                    <Route path='/schools/onboarding' component={Onboarding} />
                        {/* <Onboarding />
                    </PublicRoute> */}
                    {/* <PublicRoute path='/schools/thank-you' component={Thankyou} /> */}
                    
                    <PublicRoute path="/enter-otp" component={EnterOTP} />

                    <PublicRoute path="/brands/login" component={BrandsLogin} methods={methods} />
                        {/* <BrandsLogin methods={methods} />
                    </PublicRoute> */}
                    <PublicRoute path="/brands/forgot-password" component={BrandsForgotPassword} methods={methods} />
                        {/* <BrandsForgotPassword methods={methods} />
                    </PublicRoute> */}
                    {/* <ProtectedRoute path = '/brands/add-brand-details' component={BrandManagerDetail} /> */}
                        {/* <BrandManagerDetail /> */}
                    {/* </ProtectedRoute> */}
                        {/* <NewUserContactDeatil />
                    </ProtectedRoute> */}
                    <PublicRoute path="/brands/enter-otp" component={BrandsEnterOTP} />
                    
                    <PublicRoute path="/brands/sign-up" component={SignUp} />
                    <PublicRoute path="/brands/contact-details" component={ContactDetails} />
                    <PublicRoute path="/brands/welcome-login" component={WelcomeLogin} />

                    <PublicRoute path="/backend/login" component={BackendAdminLogin} methods={methods} setLoggedIn={(val)=>{setLoggedIn(val)}} />
                        {/* <BackendAdminLogin methods={methods} setLoggedIn={(val)=>{console.log("data is", val); setLoggedIn(val)}} />
                    </PublicRoute> */}
                    <PublicRoute path="/backend/forgot-password" component={BackendForgotPassword} />
                    <PublicRoute path="/backend/enter-otp" component={BackendEnterOTP} />
                    <PublicRoute path="/backend/success" component={BackendSuccessLogin} />
                    <PublicRoute path="/backend/welcome" component={BackendWelcomeLogin} />
                    {/* Public routes end */}

                    <Route exact>
                        <h1>404 not Found</h1>
                    </Route>
                   
                </Switch>
            </Router>
        )
  }
  
  export default AppRouter
  