import React, { useState, useRef, useContext } from "react";
import useClickOutside from '../../../../service/useClickOutside';
import tableFilter from "../../../../assets/images/table-filter.svg"
import Pagination from "../../../../components/addon/pagination";
import config from "../../../../service/config";
import AppContextObject from "../../../../common/context/common";

const InventoryTable = ({ inventories, isLoading, setInventorySortByCategory, inventoryCategory, setSortByName, setCurrentPage,
    sortByName, setSortByOccupency, sortByOccupency, onPageChange, onNextClick, onPrevClick, currentPage, totalPage, tabStatus,
    setSortFilter, setShowPriceModal, setOverlay, setInventoryDetails }) => {
    const context = useContext(AppContextObject)
    const [openInventoryNameFilter, setOpenInventoryNameFilter] = useState(false);
    const [openInventoryOccupencyFilter, setOpenInventoryOccupencyFilter] = useState(false);
    const [openInventoryCategoryFilter, setOpenInventoryCategoryFilter] = useState(false);

    const ref = useRef(null);

    useClickOutside(ref, () => { setOpenInventoryNameFilter(false); setOpenInventoryOccupencyFilter(false); setOpenInventoryCategoryFilter(false) })
    return (
        <>
            <table className="table">
                <thead>
                    <tr ref={ref}>
                        <th>Sl no</th>
                        <th>inventory name <a href="javascript:void(0);" onClick={() => { setSortByName(!sortByName); setSortByOccupency(false); setSortFilter('name') }}><img src={tableFilter} /></a>
                        </th>
                        <th>occupancy Rate <a href="javascript:void(0);"><img src={tableFilter} onClick={() => { setSortByOccupency(!sortByOccupency); setSortByName(false); setSortFilter('occupancy') }} /></a>
                        </th>
                        <th className="p-relative" style={{width: "350px"}}>category {tabStatus !== 'events' && <a href="javascript:void(0);"><img src={tableFilter} onClick={() => setOpenInventoryCategoryFilter(!openInventoryCategoryFilter)} /></a>}
                            <InventoryCategoryFilter
                                openInventoryCategoryFilter={openInventoryCategoryFilter}
                                setInventorySortByCategory={setInventorySortByCategory}
                                inventoryCategory={inventoryCategory}
                                tabStatus={tabStatus}
                                setCurrentPage={setCurrentPage}
                            />
                        </th>
                    </tr>
                </thead>
                <tbody>

                    {!isLoading ?
                        <>
                            {inventories && inventories.length > 0 ? inventories.map((inventory, key) => {

                                return (
                                    <InventorySpanRow
                                        inventory={inventory}
                                        keyNo={key + 1}
                                        currentPage={currentPage}
                                        setOverlay={setOverlay}
                                        setShowPriceModal={setShowPriceModal}
                                        setInventoryDetails={setInventoryDetails}
                                    />
                                )
                            })
                                :
                                <tr><td colSpan={7}>No Inventory found</td></tr>
                            }
                        </>
                        :
                        [...Array(10)].map((el, index) => {
                            return (
                                <tr>
                                    {
                                        [...Array(4)].map((el, index) => {
                                            return (
                                                <td key={index}>
                                                    <div className="content-loader-block"></div>
                                                </td>
                                            )
                                        })
                                    }
                                </tr>
                            )
                        })
                    }                    
                </tbody>
            </table>
            {totalPage.length > 1 &&
                <Pagination
                    currentPage={currentPage}
                    totalPage={totalPage}
                    onPageChange={onPageChange}
                    onNextClick={onNextClick}
                    onPrevClick={onPrevClick}
                    loading={isLoading}
                />
            }
        </>
    )
}
export default InventoryTable;

export const InventorySpanRow = ({ inventory, currentPage, keyNo, setOverlay, setShowPriceModal, setInventoryDetails }) => {
    const [openRow, setOpenRow] = useState(false);
    const context = useContext(AppContextObject)
    return (
        <>
            <tr>
                <td>{keyNo + (currentPage - 1) * 10}</td>
                <td>{inventory.name ? inventory.name : '--'}</td>
                <td>{inventory.occupancyRate}%</td>
                <td>{inventory.type === 'classroom' ? 'Classroom' : inventory.type === 'commonArea' ? 'Common Area'
                    : inventory.type === 'otherRooms&Staff' ? 'Staff and Miscellaneous rooms' : inventory.type === 'outdoor' ? 'Outdoor' 
                    :  inventory.type === 'events&Occasions' ? 'Events & Occasions' : inventory.type === 'student' ? 'Student'              
                    : inventory.type === 'school' ? 'School' : '--'
                    } 
                    <div className="arrowset">
                    <a href="javascript:void(0)" onClick={() => setOpenRow(!openRow)}>
                        <svg className="down-arrow requestArrow" width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M1 6L6 0.999999L11 6" stroke="#8C8C8C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /> </svg>
                        </a>
                        </div>
                </td>
            </tr>

            {openRow && <tr className="inventorieTableDetail">
                <td></td>
                <td></td>                
                {!inventory.isCustom ? <td colSpan={2}>
                    {(context.user.role === "backendAdmin" || context.user.role === "backendTech" || context.user.role === "backendSales") &&
                        <div className="inventorySearch schoolFilter inventoryBackAdmin">
                            {/* <input placeholder="Enter price" type="text" /> */}
                            <a href="javascript:void(0)" className="btn btn-orange" onClick={() => { setOverlay(true); setShowPriceModal(true);setInventoryDetails(inventory); }}>Add price</a>
                        </div>
                    }
                </td>
                :
                <td colSpan={2}></td>
                }
                {/* <td></td> */}
            </tr>}
        </>
    )
}
export const Categories = ({ brandCategories }) => {
    const [showMore, setShowMore] = useState(false)
    return (
        <>{!showMore ?
            brandCategories.slice(0, 3).map((item, key) => {
                return <>{item}{','} </>
            })
            :
            brandCategories.map((item, key) => {
                return <>{item}{brandCategories.length > key + 1 && ','} </>
            })
        }


            <a href="javascript:void(0)" className="viewwLink" onClick={() => setShowMore(!showMore)}>{brandCategories.length - 3} {!showMore ? 'more' : 'less'}</a>
        </>

    )
}
export const InventoryNameFilter = ({ openInventoryNameFilter, setSortByName, sortByName }) => {
    return (
        <div className={`textNone filterDropDown filterDropDown-inventory ${openInventoryNameFilter ? 'open' : ''}`}>
            <div className="filterDropHead">
                <h2>Filter
                    {/* <a href="javascript:void(0);" className="viewwLink" onClick={clearAllFilter}>Clear All</a> */}
                </h2>
            </div>
            <div className="filterDropClose">
                <h3><a href="javascript:void(0);" onClick={() => setSortByName(!sortByName)} >A &#8594; Z</a></h3>
            </div>
        </div>
    )
}
export const InventoryOccupencyFilter = ({ openInventoryOccupencyFilter, setSortByOccupency, sortByOccupency }) => {
    return (
        <div className={`textNone filterDropDown filterDropDown-inventory ${openInventoryOccupencyFilter ? 'open' : ''}`}>
            <div className="filterDropHead">
                <h2>Filter
                    {/* <a href="javascript:void(0);" className="viewwLink" onClick={clearAllFilter}>Clear All</a> */}
                </h2>
            </div>
            <div className="filterDropClose">
                <h3><a href="javascript:void(0);" onClick={() => setSortByOccupency(!sortByOccupency)} >high &#8594; low</a></h3>
            </div>
        </div>
    )
}
export const InventoryCategoryFilter = ({ openInventoryCategoryFilter, setInventorySortByCategory, inventoryCategory, tabStatus, setCurrentPage }) => {
    const filterCategory = (value) => {
        setCurrentPage(1);
        let array = [...inventoryCategory]
        if (array.includes(value)) {
            array.splice(array.indexOf(value), 1)
        }
        else {
            array.push(value)
        }
        setInventorySortByCategory([...array])
    }
    return (
        <div className={`filterDropDown filterDropDown-inventory textNone ${openInventoryCategoryFilter ? 'open' : ''}`}>
            <div className="filterDropHead">
                <h2>Filter <a href="javascript:void(0);" className="viewwLink" onClick={() => setInventorySortByCategory([])}>Clear All</a></h2>
            </div>
            <div className="filterDropBody">
                <div className={`filterDropClose open`}>
                    {/* <h3><a href="javascript:void(0);" onClick={() => setSpanCategory(!spanCategory)} >{`Category`}  <img src={filterArrowSvg} /></a></h3> */}
                    <div className="filtercheckbox">
                        {tabStatus === 'all' || tabStatus === 'custom' ?
                            config.inventoryCategories.map((item, index) => (
                                <div className="checkbox fnone" key={index}>
                                    <input type="checkbox" id={item.value} checked={inventoryCategory.includes(item.value)}
                                    />
                                    <label htmlFor={item.value}
                                        onClick={() => filterCategory(item.value)}
                                    >{item.label}</label>
                                </div>
                            ))
                            :

                            config.inventoryCategories.filter(item => item.value !== 'events&Occasions').map((item, index) => (
                                <div className="checkbox fnone" key={index}>
                                    <input type="checkbox" id={item.value} checked={inventoryCategory.includes(item.value)}
                                    />
                                    <label htmlFor={item.value}
                                        onClick={() => filterCategory(item.value)}
                                    >{item.label}</label>
                                </div>
                            ))
                        }
                    </div>
                </div>

            </div>
        </div>
    )
}
