import { useState, useContext, useEffect, useRef } from "react"

import { useHistory } from "react-router-dom"
// import OnGoingCampaigns from "./onGoingCampaigns"
import tableFilter from "../../../assets/images/table-filter.svg"
import mapsImg from "../../../assets/images/maps.jpg"
import imgViewTwo from "../../../../assets/images/backendAdmin/img-view-two.jpg"

import TopBar from "../../schools/components/topBar"
import AppContextObject from "../../../../common/context/common"
import { compareObjs, formatDateShowMonth, formatInventories, formatInventoriesForEvents, getPercentageComplition, objectDeepClone, readAbleBoard, returnReadableStatus } from "../../../../service/helper"
import HorizentalBarChart from "../../../../components/charts/horizentalBarChart"
import scriptLoader from 'react-async-script-loader'
import config from "../../../../service/config"
import MultiPointMap from "../../../../components/addon/multiPointMap"
import InventoryMore from "../../../../components/addon/inventoryMore"
import { PublicContextInitiator } from "../../../apis/publicContextInitiator"
import ConfirmDialog from "../../../../components/addon/confirmDialog/confirmDialog"
import toast from "react-hot-toast"
import TextArea from "../../../../components/formFields/textArea"
import blankImg from '../../../../assets/images/blank-inventory-img.jpg';
import errorSVG from "../../../../assets/images/error.svg";
import proEdit from "../../../../assets/images/edit-profile.svg"
import SimpleReactValidator from "simple-react-validator";
import ImagePreview from "../../../../components/addon/imagePreview"
import Loader from "../../../../components/addon/loader"
import SchoolGeneralDetail from "../../schools/components/schoolGeneralDetail"
import ClassroomAttributes from "../../schools/components/classrooms/attributes"
import ConfirmAlert from "../../../../components/addon/confirmAlert"
import DataContext from "../../../../service/contextProvider"
import PersonalDetails from "../../schools/onboarding/personalDetails/personalDetails"
import BackendTopBar from "../components/backendTopBar"
import BackendBackBtn from "../components/backendBackBtn"
import star from "../../../../assets/images/star.svg"
import closeCard from "../../../../assets/images/close-2.svg"
import TextField from "../../../../components/formFields/textField/textField"
import NumberField from "../../../../components/formFields/numberField"
import SingleSelect from "../../../../components/formFields/singleSelect/singleSelect"
import Compress from "compress.js"
import MultiSelect from "../../../../components/formFields/multiSelect/multiSelect"
import Select from "react-select"

const SchoolDetails = (props) => {
    const {
    } = props
    const context = useContext(AppContextObject)
    const parentData = useContext<any>(DataContext)
    const history = useHistory()
    const compress = new Compress()
    const [overlay, setOverlay] = useState(false)
    const [selectedImageIndex, setSelectedImageIndex] = useState(0)
    const [selectedEventIndex, setSelectedEventIndex] = useState(0)
    const [showImagePreview, setShowImagePreview] = useState(false)
    const [inventoryList, setInventoryList] = useState([])
    const [classRoomInventoryList, setClassRoomInventoryList] = useState([])
    const [otherRoomsAndStaffInventoryList, setOtherRoomsAndStaffInventoryList] = useState([])
    const [outdoorInventoryList, setOutdoorInventoryList] = useState([])
    const [commonAreaInventoryList, setCommonAreaInventoryList] = useState([])
    const [inreviewInventoryList, setInreviewInventoryList] = useState([])
    const [activeIndex, setActiveIndex] = useState(0)
    const [schoolDetail, setSchoolDetail] = useState<any>()
    const [localArea, setLocalArea] = useState()
    const [city, setCity] = useState()
    const [state, setState] = useState()
    const [pincode, setPincode] = useState()
    const [address, setAddress] = useState()
    const [schoolEduLevel, setSchoolEduLevel] = useState([])
    const [schoolLang, setSchoolLang] = useState([])
    const [schoolBoard, setSchoolBoard] = useState([])
    const [activityList, setActivityList] = useState([])
    const [showSchoolApproveModal, setShowSchoolApproveModal] = useState(false)
    const [showSchoolRejectModal, setShowSchoolRejectModal] = useState(false)
    const [schoolId, setSchoolId] = useState("")
    const [showRejectionMessageModal, setShowRejectionMessageModal] = useState(false)
    const [rejectionMessage, setRejectionMessage] = useState({ message: "" })
    const [rejectingSchool, setRejectingSchool] = useState(false)
    const [openRejectModal, setOpenRejectModal] = useState(false);
    const [showRejectionInventoryMessageModal, setShowRejectionInventoryMessageModal] = useState(false);
    const [rejectionInventoryMessage, setRejectionInventoryMessage] = useState({ message: "" });
    const [showInventoryApproveModal, setShowInventoryApproveModal] = useState(false)
    const [inventoryID, setInventoryID] = useState("");
    const [flag, setFlag] = useState(false)
    const [update, forceUpdate] = useState(false);
    const [updatingData, setUpdatingData] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [formData, setFormData] = useState<any>({ ...parentData.data.schoolGeneral })
    const generalDetailRef = useRef({ formValidator: { current: { allValid: () => false, showMessages: () => { return }, hideMessages: () => { return } } } })
    const otherBoardReference = useRef()
    const refClassrooms = useRef()
    const [activeTab, setActiveTab] = useState("personal")
    const [otherBoardRef, setOtherBoardRef] = useState([])
    const [boardOfStudyData, setBoardOfStudy] = useState({
        boardOfStudy: ""
    })
    const [builtError, setBuiltError] = useState(false)
    const [showBuildUpError, setShowBuildUpError] = useState(false)
    const [showPrompt, setShowPrompt] = useState(false)
    const [initialData, setInitialData] = useState([])
    const [oldParentData, setOldParentData] = useState({})
    const [errorMessage, setErrorMessage] = useState("")
    const [personalDetailsData, setPersonalDetailsData] = useState<any>({})
    const [schoolUserId, setSchoolUserId] = useState("")
    const [refetch, setRefetch] = useState(null)
    const [isEvent, setIsEvent] = useState(false)
    const [approvingSchool, setApprovingSchool] = useState(false)
    const [approvingInventory, setApprovingInventory] = useState(false)
    const [rejectingInventory, setRejectingInventory] = useState(false)
    const [principleDetails, setPrincipleDetails] = useState<any>()
    const [editingInventory, setEditingInventory] = useState(false)
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false)
    const [selectedPlacementIdx, setSelectedPlacementIdx] = useState(null)
    const [deleteCustomPlacementId, setDeleteCustomPlacementId] = useState("")
    const [deleteCustomPlacementArray, setDeleteCustomPlacementArray] = useState([])
    const [inventory, setInventory] = useState<any>()
    const [showInventoryPreviewImg, setShowInventoryPreviewImg] = useState(false)
    const [selectedInventoryImgIndex, setSelectedInventoryImgIndex] = useState(0)
    const [inventoryImageLoading, setInventoryImageLoading] = useState(false)
    const [placementIndex, setPlacementIndex] = useState(0)
    const [deleteImageKey, setDeleteImageKey] = useState("")
    const [showImageDeleteModal, setShowImageDeleteModal] = useState(false)
    const [showImageError, setShowImageError] = useState(false)
    const [notSupportedImageError, setNotSupportedImageError] = useState("")
    const [imagesCount, setImagesCount] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [loadingImgIndex, setLoadingImgIndex] = useState(-1)
    const [imageErrorIndex, setImageErrorIndex] = useState(null)
    const [removeImage, setRemoveImage] = useState(null);
    const [showEditInventoryModal, setShowEditInventoryModal] = useState(false)
    const [inventoryErorMessage, setInventoryErorMessage] = useState("")
    const [isPageLoading, setIsPageLoading] = useState(false)
    const [status, setStatus] = useState({value: ""})
    const [formErrors, setFormErrors] = useState<any>({})
    const [venueList, setVenueList] = useState([])
    const [showTagsError, setShowTagsError] = useState(false)
    const [showDeleteInventoryPopop, setShowDeleteInventoryPopop] = useState(false)
    const [showDeleteEventPopop, setShowDeleteEventPopop] = useState(false)
    const [schoolName, setSchoolName] = useState({schoolName: ""})
    const [showSchoolDeleteModal, setShowSchoolDeleteModal] = useState(false)

    const [formValidator] = useState(useRef(new SimpleReactValidator({
        element: (message) =>

            <span className="valError"><img src={errorSVG} />{message}</span>
    })))
    const [inventoryFormValidator] = useState(useRef(new SimpleReactValidator({
        element: (message, className) =>
            <span className="valError"><img src={errorSVG} />{message}</span>
        })))
    useEffect(() => {
        if (update === true) {
            formValidator.current.showMessages()
        } else {
            formValidator.current.hideMessages()
        }
    }, [])
    useEffect(() => {
        if(!schoolDetail && !schoolDetail?.schoolName){
            setRefetch(!refetch)
        }
    }, [schoolDetail])
    useEffect(() => {
        let arr = []
        config.schoolAmenities.forEach(el => {
            const found = arr.find(e => e.label === el.name)
            if (!found) {
                arr.push({
                    label: el.name,
                    value: el.name
                })
            }
        })
        arr.push({
            label: "Other",
            value: "Other"
        })
        setVenueList(arr)
    }, [])
    useEffect(() => {
        if(inreviewInventoryList && inreviewInventoryList.length > 0 && activeIndex >= 0){
            console.log("inreviewInventoryList", inreviewInventoryList[activeIndex])
            let selectedInventory = objectDeepClone(inreviewInventoryList[activeIndex])
            let assetsProvidedByArray = []
            selectedInventory["placements"] = objectDeepClone(selectedInventory.attributes)
            if((inreviewInventoryList[activeIndex].months || inreviewInventoryList[activeIndex].venue)){
                inreviewInventoryList[activeIndex].attributes.forEach((item => {
                    if(item.allowBrandsToProvide){
                        assetsProvidedByArray.push("Brand")
                    }
                    else{
                        assetsProvidedByArray.push("Self")
                    }
                }))
            }
            selectedInventory["assetsProvidedBy"] = [...assetsProvidedByArray]
            delete selectedInventory.attributes
            setInventory({...selectedInventory})
            setStatus({value: selectedInventory.status})
        }
    }, [activeIndex, editingInventory])

    const getPrincipleDetails = async(id) => {
        let resp = await context.apis.public.getUserBySchoolIdByRole(
            context.validator, {school: id, role: "schoolPrincipal"}, {}
        )
        console.log("getUserBySchoolIdByRole", resp)
        if(resp && resp.done){
            setPrincipleDetails({...resp.response})
        }
    }

    useEffect(() => {
        if(history.location.search && history.location.search.split("?").length > 1){
            let array = history.location.search.split("?")
            if(array && array.length > 1 && array[1] && array[1] !== ""){
                let data: any = array[1]
                if (data) {
                    setSchoolId(data)
                    getSchoolData(data)
                    getPrincipleDetails(data)
                }
            }
        }
        else if (history.location.state) {
            let data: any = history.location.state
            if (data.schoolId) {
                setSchoolId(data.schoolId)
                getSchoolData(data.schoolId)
                getPrincipleDetails(data.schoolId)
            }
        }
    }, [flag, refetch])


    // useEffect(() => {
    //     if(parentData?.data?.schoolGeneral?._id && parentData?.data?.schoolGeneral?._id !== "")
    //         setSchoolId(parentData?.data?.schoolGeneral?._id)
    // }, [parentData.data.schoolGeneral])

    // useEffect(() => {
    //     if(!showModal){
    //         // console.log(parentData.data.schoolGeneral)
    //         // setFormData({...parentData.data.schoolGeneral})
    //     }
    // }, [showModal])

    useEffect(() => {
        // setFormData({...parentData.data.schoolGeneral})
        setOldParentData({ ...parentData, data: { ...parentData.data, schoolGeneral: formData } })
    }, [formData])

    // useEffect(() => {
    //     if(!showModal){
    //         getSchoolGenDetailById()
    //     }
    // }, [showModal])
    useEffect(() => {
        if (!showModal) {
            if(history.location.search && history.location.search.split("?").length > 1){
                let array = history.location.search.split("?")
                if(array && array.length > 1 && array[1] && array[1] !== ""){
                    let data: any = array[1]
                if (data) {
                    setSchoolId(data)
                    getSchoolData(data)
                    getPrincipleDetails(data)
                }
                }
            }
            else if (history.location.state) {
                let data: any = history.location.state
                if (data.schoolId) {
                    getSchoolGeneralDetails(data.schoolId)
                }
            }
        }
    }, [showModal])

    useEffect(() => {
        if ((!showModal && schoolId) || refetch !== null) {
            getSchoolPersonalDetails()
        }
    }, [showModal, refetch])
    console.log("showModal--->>>", showModal);
    console.log("schoolId--->>>", schoolId);

    const getSchoolData = async (_id) => {
        // let publicContextInitiator = new PublicContextInitiator(context)
        setIsPageLoading(true)
        let resp = await context.apis.public.getSchoolDetailsById(
            context.validator, { _id: _id }, {}
        )
        console.log("getSchoolDetailsById", resp)
        console.log(resp)
        if (resp && resp.done) {
            setLocalArea(resp.response.localArea)
            setCity(resp.response.city)
            setState(resp.response.state)
            setPincode(resp.response.pincode)
            setAddress(resp.response.address)
            setSchoolEduLevel([...resp.response.educationLevel])
            if (resp.response.language.language !== "Custom") {
                let language = [resp.response?.language?.language]
                setSchoolLang([...language])
            }
            else {
                let language = []
                resp.response?.language?.classWiseDetails.forEach((e) => (
                    language.push(e.lang || e.language)
                ))
                setSchoolLang([...language])
            }
            if (resp.response.board.board !== "Custom") {
                let board = [resp.response?.board?.board]
                setSchoolBoard([...board])
            }
            else {
                let board = []
                resp.response?.board?.classWiseDetails.forEach((e) => (
                    board.push(e.board)
                ))
                setSchoolBoard([...board])
            }
            setSchoolDetail(resp.response)
            setClassRoomInventoryList([...resp.response.allInventories.filter(inventory => inventory.type === "classroom" && (inventory.status !== "Rejected" && inventory.status !== "In Review" && inventory.isApproved !== false))])
            setCommonAreaInventoryList([...resp.response.allInventories.filter(inventory => inventory.type === "commonArea" && (inventory.status !== "Rejected" && inventory.status !== "In Review" && inventory.isApproved !== false))])
            setOutdoorInventoryList([...resp.response.allInventories.filter(inventory => inventory.type === "outdoor" && (inventory.status !== "Rejected" && inventory.status !== "In Review" && inventory.isApproved !== false))])
            setOtherRoomsAndStaffInventoryList([...resp.response.allInventories.filter(inventory => inventory.type === "otherRooms&Staff" && (inventory.status !== "Rejected" && inventory.status !== "In Review" && inventory.isApproved !== false))])
            setInreviewInventoryList([...resp.response.inventories])
            setActivityList([...resp.response.allActivities.filter(event => event.status !== "In Review" && event.status !== "Rejected" && event.isApproved !== false && event.availableForBranding !== false)])
        }
        setIsPageLoading(false)
    }

    const prevInventory = () => {
        if (activeIndex !== 0) {
            setActiveIndex(activeIndex - 1)
        }
    }
    const nextInventory = () => {
        if (inreviewInventoryList.length - 1 > activeIndex) {
            setActiveIndex(activeIndex + 1)
        }
    }

    const handleApproveSchool = async (confirm) => {
        if (confirm) {
            setApprovingSchool(true)
            let resp = await context.apis[context.user.role].approveSchool(
                context.validator, { _id: schoolId }, { initialApproval: true }
            )
            if (resp && resp.done) {
                setShowSchoolApproveModal(false)
                setApprovingSchool(false)
                setOverlay(false)
                setFlag(!flag);
                // history.push("/backend/backend-details/school-list")
                toast.success("School approved successfully")
            }
        }
        else {
            setShowSchoolApproveModal(false)
            setOverlay(false)
        }
    }
    const handleRejectSchool = async (confirm) => {
        if (confirm) {
            setShowRejectionMessageModal(true)
            setShowSchoolRejectModal(false)
        }
        else {
            setShowSchoolRejectModal(false)
            setOverlay(false)
        }
    }
    const openShoolRejectModal = () => {
        setShowSchoolRejectModal(true)
        setOverlay(true)
    }
    const openSchoolApproveModal = () => {
        setShowSchoolApproveModal(true)
        setOverlay(true)
    }

    const onRejectionMessageSchange = (e) => {
        setRejectionMessage({ message: e.target.value })
    }
    const onConfirmRejection = async () => {
        const result = formValidator.current.allValid();
        console.log(result)
        if (rejectionMessage.message === '') {
            formValidator.current.showMessages()
            forceUpdate(true)
            return
        } else {
            console.log('bhjbf')
            formValidator.current.hideMessages();
            setFlag(!flag);
            setRejectingSchool(true)
            let resp = await context.apis[context.user.role].approveSchool(
                context.validator, { _id: schoolId }, { initialApproval: false, rejectionReason: rejectionMessage.message }
            )
            if (resp && resp.done) {
                setShowRejectionMessageModal(false)
                setOverlay(false)
                history.push("/backend/backend-details/school-list")
                toast.success("School rejected successfully")
                setRejectingSchool(false)
            }
            setRejectingSchool(false)
        }
    }
    const closeRejectionMessageModal = () => {
        setRejectionMessage({ message: "" })
        formValidator.current.hideMessages();
        forceUpdate(false)
        setShowRejectionMessageModal(false)
        setOverlay(false)
    }
    const openInventoryRejectModal = (inventoryId, data) => {
        setIsEvent((data.months || data.venue) ? true : false)
        setInventoryID(inventoryId);
        setOpenRejectModal(true);
        setOverlay(true);

    }
    const handleRejectInventory = async (confirm) => {
        if (confirm) {
            setShowRejectionInventoryMessageModal(true)
            // setShowSchoolRejectModal(false)
            setOpenRejectModal(false);
            setOverlay(true);
        }
        else {
            setOpenRejectModal(false);
            setOverlay(false);
            setIsEvent(false)
        }
    }
    const closeRejectionInventoryMessageModal = () => {
        formValidator.current.hideMessages();
        forceUpdate(false)
        setRejectionInventoryMessage({ message: "" })
        setShowRejectionInventoryMessageModal(false)
        setOverlay(false)
    }
    const onRejectionMessageChange = (e) => {
        setRejectionInventoryMessage({ message: e.target.value })
    }
    const openInventoryApproveModal = (inventoryId, data) => {
        setIsEvent((data.months || data.venue) ? true : false)
        setInventoryID(inventoryId)
        setShowInventoryApproveModal(true)
        setOverlay(true)
    }
    const handleApproveInventory = async (confirm) => {
        if (confirm) {
            let resp
            setApprovingInventory(true)
            if(isEvent){
                resp = await context.apis[context.user.role].updateActivityStatusByIdForBackendAdmin(
                    context.validator, { _id: inventoryID }, { isApproved: true, rejectionReason: '' }
                )
            }
            else{
                resp = await context.apis[context.user.role].updateInventoryStatusByIdForBackendAdmin(
                    context.validator, { _id: inventoryID }, { isApproved: true, rejectionReason: '' }
                )
            }
            if (resp && resp.done) {
                setApprovingInventory(false)
                setShowInventoryApproveModal(false)
                setOverlay(false)
                setFlag(!flag)
                if(activeIndex > 0  && activeIndex === inreviewInventoryList.length-1) {
                    setActiveIndex(activeIndex-1)
                }
                // history.push("/backend/backend-details/school-list")
                toast.success("Inventory approved successfully")
            }
        }
        else {
            setShowInventoryApproveModal(false)
            setOverlay(false)
            setIsEvent(false)
        }
    }
    const onConfirmRejectionInventory = async () => {
        const result = formValidator.current.allValid();
        if (rejectionInventoryMessage.message === '') {
            formValidator.current.showMessages()
            forceUpdate(true)
            return
        } else {
            formValidator.current.hideMessages();
            // setRejectingSchool(true)
            let resp
            setRejectingInventory(true)
            if(isEvent){
                resp = await context.apis[context.user.role].updateActivityStatusByIdForBackendAdmin(
                    context.validator, { _id: inventoryID }, { isApproved: false, rejectionReason: rejectionInventoryMessage.message }
                )
            }
            else{
                resp = await context.apis[context.user.role].updateInventoryStatusByIdForBackendAdmin(
                    context.validator, { _id: inventoryID }, { isApproved: false, rejectionReason: rejectionInventoryMessage.message }
                )
            }
            
            if (resp && resp.done) {
                setShowRejectionInventoryMessageModal(false)
                setRejectingInventory(false)
                setOverlay(false)
                // history.push("/backend/backend-details/school-list")
                toast.success("Inventory rejected successfully")
                setFlag(!flag)
                if(activeIndex > 0  && activeIndex === inreviewInventoryList.length-1) {
                    setActiveIndex(activeIndex-1)
                }
                // setRejectingSchool(false)
            }
        }
        setShowRejectionInventoryMessageModal(false)
        setOverlay(false)
    }

    const openImagePreview = (index, eventIndex?) => {
        if(eventIndex){
            setSelectedEventIndex(eventIndex)
        }
        setSelectedImageIndex(index)
        setShowImagePreview(true)
    }

    // const getSchoolGenDetailById = async() => {
    //     let resp = await context.apis[context.user.role].getSchoolGeneralDetailsBySchoolId(
    //         context.validator, {_id: context.user.school}
    //     )
    //     if(resp && resp.done){
    //         setSchoolEduLevel([...resp.response.educationLevel])
    //         if(resp.response.language.language !== "Custom"){
    //             let language = [resp.response?.language?.language]
    //             setSchoolLang([...language])
    //         }
    //         else{
    //             let language = []
    //             for (let el of resp.response?.language?.classWiseDetails) {
    //                 if(!Object.keys(el).find(e=> e==="language")) el["language"] = el.lang; delete el.lang;
    //                 language.push(el.language)
    //             }
    //             // resp.response?.language?.classWiseDetails.map(el=> {  })
    //             // console.log("language--->>",language, resp.response?.language?.classWiseDetails);
    //             // resp.response?.language?.classWiseDetails.forEach((e) => (
    //             //     language.push(e.language)
    //             // ))
    //             setSchoolLang([...language])
    //         }
    //         if(resp.response.board.board !== "Custom"){
    //             let board = [resp.response?.board?.board]
    //             setSchoolBoard([...board])
    //         }
    //         else{
    //             let board = []
    //             resp.response?.board?.classWiseDetails.forEach((e) => (
    //                 board.push(e.board)
    //             ))
    //             setSchoolBoard([...board])
    //         }
    //         setFormData(resp.response)
    //         parentData.setData({
    //             ...parentData.data,
    //             schoolGeneral: {...resp.response}
    //         })
    //     }
    // }

    // const getSchoolGeneralDetails = async () =>{
    //     let schoolGeneralDetailsReps = await context.apis[context.user.role].getSchoolGeneralDetailsBySchoolId(
    //         context.validator, {_id: schoolId}
    //     )
    //     // let schoolAmenitiesDetailsReps = await context.apis[context.user.role].getSchoolAmenitiesDetailsBySchoolId(
    //     //     context.validator, {}
    //     // )
    //     console.log("schoolGeneralDetailsReps--->",schoolGeneralDetailsReps)
    //     if(schoolGeneralDetailsReps && schoolGeneralDetailsReps.done){
    //         if(schoolGeneralDetailsReps.response.language.language !== "Custom"){
    //             let language = [schoolGeneralDetailsReps.response?.language?.language]
    //             setSchoolLang([...language])
    //         }
    //         else{
    //             let language = []
    //             for (let el of schoolGeneralDetailsReps.response?.language?.classWiseDetails) {
    //                 if(!Object.keys(el).find(e=> e==="language")) el["language"] = el.lang; delete el.lang;
    //                 language.push(el.language)
    //             }
    //         }
    //         setFormData(schoolGeneralDetailsReps.response)
    //         parentData.setData({
    //             ...parentData.data,
    //             schoolGeneral: {...schoolGeneralDetailsReps.response}
    //         })
    //     }
    // }
    const getSchoolGeneralDetails = async (_id?) => {
        try {


            let schoolGeneralDetailsReps = await context.apis[context.user.role].getSchoolGeneralDetailsBySchoolId(
                context.validator, { _id: _id || schoolId }
            )
            let schoolAmenitiesDetailsReps = await context.apis[context.user.role].getSchoolAmenitiesDetailsBySchoolId(
                context.validator, { _id: _id || schoolId }
            )
            console.log("schoolGeneralDetailsReps--->", schoolGeneralDetailsReps);
            console.log("schoolAmenitiesDetailsReps", schoolAmenitiesDetailsReps, parentData.data)
            if (schoolGeneralDetailsReps && schoolGeneralDetailsReps.done && schoolAmenitiesDetailsReps && schoolAmenitiesDetailsReps.done) {
                // setSchoolName(schoolGeneralDetailsReps.response.schoolName)
                // setSchoolBuildUpArea(schoolGeneralDetailsReps.response.builtUpArea)
                // setSchoolTotalStudent(schoolGeneralDetailsReps.response.totalStudents)
                // setSchoolTotalInventory(schoolGeneralDetailsReps.response.totalInventories)
                // setSchoolTotalActivities(schoolGeneralDetailsReps.response.totalActivities)
                setSchoolEduLevel([...schoolGeneralDetailsReps.response.educationLevel])
                if (schoolGeneralDetailsReps.response.language.language !== "Custom") {
                    let language = [schoolGeneralDetailsReps.response?.language?.language]
                    setSchoolLang([...language])
                }
                else {
                    let language = []
                    for (let el of schoolGeneralDetailsReps.response?.language?.classWiseDetails) {
                        if (!Object.keys(el).find(e => e === "language")) el["language"] = el.lang; delete el.lang;
                        language.push(el.language)
                    }
                    // resp.response?.language?.classWiseDetails.map(el=> {  })
                    // console.log("language--->>",language, resp.response?.language?.classWiseDetails);
                    // resp.response?.language?.classWiseDetails.forEach((e) => (
                    //     language.push(e.language)
                    // ))
                    setSchoolLang([...language])
                }
                if (schoolGeneralDetailsReps.response.board.board !== "Custom") {
                    let board = [schoolGeneralDetailsReps.response?.board?.board]
                    setSchoolBoard([...board])
                }
                else {
                    let board = []
                    schoolGeneralDetailsReps.response?.board?.classWiseDetails.forEach((e) => (
                        board.push(e.board)
                    ))
                    setSchoolBoard([...board])
                }
                // setFormData(schoolGeneralDetailsReps.response)
                // parentData.setData({
                //     ...parentData.data,
                //     schoolGeneral: {...schoolGeneralDetailsReps.response}
                // })
                if (schoolGeneralDetailsReps.response.language.language !== "Custom") {
                    let language = [schoolGeneralDetailsReps.response?.language?.language]
                    setSchoolLang([...language])
                }
                else {
                    let language = []
                    for (let el of schoolGeneralDetailsReps.response?.language?.classWiseDetails) {
                        if (!Object.keys(el).find(e => e === "language")) el["language"] = el.lang; delete el.lang;
                        language.push(el.language)
                    }
                }
                setFormData(schoolGeneralDetailsReps.response)
                console.log("schoolAmenitiesDetailsReps.response.classrooms->", schoolAmenitiesDetailsReps.response.classrooms)
                // parentData.setData({
                //     ...parentData.data,
                //     schoolGeneral: {...schoolGeneralDetailsReps.response},
                //     schoolAmenities: {
                //         ...parentData.data.schoolAmenities,
                //         builtUpArea: schoolAmenitiesDetailsReps.response.builtUpArea,
                //         floors: schoolAmenitiesDetailsReps.response.floors,
                //         classrooms: schoolAmenitiesDetailsReps.response.classrooms
                //     }
                // })
                parentData.data.schoolAmenities.classrooms = schoolAmenitiesDetailsReps.response.classrooms
                console.log("parentData--->>>>", parentData);
            }
        } catch (e) {
            console.log("getSchoolGeneralDetails Error:", e);
        }
    }
    const getSchoolPersonalDetails = async () => {
        console.log("schoolId--->>>", schoolId)
        let personalDetailsResp = await context.apis[context.user.role].getSchoolNameWithUserDetails(
            context.validator, { _id: schoolId }
        )
        console.log("personalDetailsResp--->", personalDetailsResp);
        if (personalDetailsResp && personalDetailsResp.done) {
            setPersonalDetailsData(personalDetailsResp.response)
            setSchoolUserId(personalDetailsResp.response._id)
            setSchoolName({schoolName: personalDetailsResp.response.schoolName})
            // parentData.setData({
            //     ...parentData.data,
            //     personalDetails:{
            //         fullName: personalDetailsResp.response.fullName,
            //         username: personalDetailsResp.response.username,
            //         email: personalDetailsResp.response.email,
            //         phone: personalDetailsResp.response.phone,
            //         countryCode: personalDetailsResp.response.countryCode || '+91',
            //         schoolName: personalDetailsResp.response.schoolName,
            //         registeredUnder: personalDetailsResp.response.registeredUnder,
            //         schoolPrincipalName: personalDetailsResp.response.principalName,
            //         schoolPrincipalEmail: personalDetailsResp.response.principalEmail,
            //         role: personalDetailsResp.response.role
            //     }
            // })
        }
    }

    const closeModal = () => {
        getSchoolGeneralDetails()
        setShowModal(false)
        setOverlay(false)
    }
    const openModal = () => {
        let element = document.getElementById("editSchool")
        console.log("element", element)
        element.scrollIntoView({block: "start"})
        getSchoolGeneralDetails().then(() => {
            setShowModal(true)
            setOverlay(true)
        })
        // parentData.setData({
        //     ...parentData.data,
        //     schoolGeneral: {...formData}
        // })
    }

    const compareOldAndNewData = (newData) => {
        const isSame = compareObjs(newData, initialData)
        if (isSame === false) {
            setShowPrompt(true)
        } else {
            setShowPrompt(false)
        }
    }

    const confirmAlertCallback = (data) => {
        setOverlay(data.overlay)
    }

    const submitForm = async (event) => {
        // const result = generalDetailRef?.current?.formValidator.current?.allValid()
        let otherBoardValidation = []
        otherBoardRef.length !== 0 && otherBoardRef.forEach(e => {
            if (e?.current?.validateField) {
                otherBoardValidation.push(e.current.validateField())
            }
        })
        let otherResult
        let otherValidation: any = otherBoardReference
        if (otherValidation?.current?.validateField) {
            otherResult = otherValidation?.current?.validateField()
        }
        event.preventDefault()
        const updateProgress = {
            value: false
        }
        const result = generalDetailRef?.current?.formValidator.current?.allValid()
        console.log(result, otherBoardValidation)
        if (otherResult === false || result === false || !(otherBoardValidation.length === 0 || otherBoardValidation.indexOf(false) === -1) || (formData.builtUpArea === "" || formData.builtUpArea < 100)) {
            updateProgress.value = false
            generalDetailRef?.current?.formValidator.current?.showMessages()
            if (formData.builtUpArea === "" || formData.builtUpArea < 100) {
                setBuiltError(true)
                setShowBuildUpError(true)
            }
            forceUpdate(!update)
            return
        } else {
            generalDetailRef?.current?.formValidator.current?.hideMessages()
            setShowBuildUpError(false)
            forceUpdate(!update)
        }
        //2nd validation
        let pendingClassList = []
        let classrooms = []
        let data = objectDeepClone(formData)
        if (refClassrooms && refClassrooms.current) {
            const ref: any = refClassrooms.current
            pendingClassList = ref.renderErrorState()
            classrooms = ref.fecthClassrooms()
            console.log("classrooms--->>>", classrooms)
            data = {
                ...data,
                classrooms: classrooms,
            }
        }
        if (pendingClassList.length > 0) {
            setErrorMessage("Please fill all fields.")
            return
        } else {
            setErrorMessage("")
        }
        setUpdatingData(true)
        const json = { ...formData }

        if (json.board.board === "Other") {
            json.board.board = boardOfStudyData.boardOfStudy
        }

        if (json.board.board === "Custom") {
            for (let i = 0; i < json.board.classWiseDetails.length; i++) {
                if (json.board.classWiseDetails[i].board === "Other") {
                    json.board.classWiseDetails[i].board = json.board.classWiseDetails[i].otherBoard
                    delete json.board.classWiseDetails[i].otherBoard
                }
            }
        }
        updateProgress.value = true
        setShowPrompt(false)
        //set edited data
        let apiData = {
            data: {
                ...parentData?.data,
                personalDetails: {
                    fullName: personalDetailsData.fullName,
                    email: personalDetailsData.email,
                    phone: personalDetailsData.phone,
                    username: personalDetailsData.username,
                    schoolPrincipalEmail: personalDetailsData.principalEmail,
                    schoolPrincipalName: personalDetailsData.schoolPrincipalName,
                    registeredUnder: personalDetailsData.registeredUnder,
                    schoolName: schoolName.schoolName,
                    schoolId: schoolId
                },
                schoolGeneral: { ...json, schoolName: schoolName.schoolName },
                schoolAmenities: {
                    ...parentData.data.schoolAmenities,
                    builtUpArea: formData.builtUpArea,
                    classrooms: [...classrooms]
                },
                schoolId: schoolId
            },
            previousData: {
                ...parentData?.previousData,
                schoolGeneral: { ...json },
                schoolAmenities: {
                    ...parentData.data.schoolAmenities,
                    builtUpArea: formData.builtUpArea,
                    classrooms: [...classrooms]
                },
            }
        }
        console.log("apiData--------->>>", apiData)
        let resp = await context.apis[context.user.role].updateSchoolDetails(
            context.validator, apiData
        )
        if (parentData) {
            if (parentData.setProgress) {
                parentData.setProgress({
                    ...parentData.progress,
                    schoolGeneral: true,
                    studentSpecific: true,
                })
            }
            if (parentData.setData) {
                parentData.setData({
                    ...parentData.data,
                    schoolGeneral: { ...json },
                    schoolAmenities: {
                        ...parentData.data.schoolAmenities,
                        builtUpArea: formData.builtUpArea,
                        classrooms: [...classrooms]
                    },
                })
            }
        }
        console.log(resp)
        if (resp && resp.done) {
            toast.success("Details updated successfully")
            // parentData.setIsUpdated(true)
            setRefetch(!refetch)
            setShowModal(false)
            setOverlay(false)
        }
        setBoardOfStudy({ boardOfStudy: "" })
        setUpdatingData(false)
    }

    const onTabClick = (tabName) => {
        setActiveTab(tabName)
    }
    const onPlacementDataChange = (index, event) => {
        let info = objectDeepClone(inventory)
        info["placements"][index][event.target.id] = event.target.value
        console.log("info", info)
        setInventory({
            ...objectDeepClone(info)
        })
        // let reviewInventoryList = objectDeepClone(inreviewInventoryList)
        // reviewInventoryList.splice(activeIndex, 1, info)
        // setInreviewInventoryList([...reviewInventoryList])
    }
    const onFormDataChange = (event) => {
        let info = objectDeepClone(inventory)
        info[event.target.id] = event.target.value
        setInventory({ 
            ...inventory,
            [event.target.id]: event.target.value
        })
        // let reviewInventoryList = objectDeepClone(inreviewInventoryList)
        // reviewInventoryList.splice(activeIndex, 1, info)
        // setInreviewInventoryList([...reviewInventoryList])       
    }
    const onAttrTypeChange = (index, data) => {
        let info = objectDeepClone(inventory)
        info["placements"][index]["units"] = data.value
        setInventory({
            ...objectDeepClone(info)
        })
        // let reviewInventoryList = objectDeepClone(inreviewInventoryList)
        // reviewInventoryList.splice(activeIndex, 1, info)
        // setInreviewInventoryList([...reviewInventoryList]) 
    }
    const removeNewAddedPlacement = (index) => {
        setOverlay(true)
        setSelectedPlacementIdx(index)
        setShowConfirmDeleteModal(true)
    }
    const removeCustomPlacement = (index, id) => {
        setOverlay(true)
        setSelectedPlacementIdx(index)
        setShowConfirmDeleteModal(true)
        setDeleteCustomPlacementId(id)
    }
    const onDeleteModalClose = (confirm) => {
        if (confirm === true) {
            if(deleteCustomPlacementId !== ""){
                setDeleteCustomPlacementArray([...deleteCustomPlacementArray, deleteCustomPlacementId])
            }
            let list = objectDeepClone(inventory.placements)
            list.splice(selectedPlacementIdx, 1)
            if(inventory.months || inventory.venue){
                let arr = [...inventory.assetsProvidedBy]
                arr.splice(arr.length - 1, 1)
                setInventory({
                    ...inventory,
                    assetsProvidedBy: [...arr],
                    placements: objectDeepClone(list)
                })
            }
            else{
                setInventory({
                    ...inventory,
                    placements: objectDeepClone(list)
                })
                // let reviewInventoryList = objectDeepClone(inreviewInventoryList)
                // reviewInventoryList.splice(activeIndex, 1, list)
                // setInreviewInventoryList([...reviewInventoryList])
            }
            
        }
        setShowConfirmDeleteModal(false)
        setOverlay(false)
    }
    const onAddNewSizeClick = () => {
        if(inventory.months || inventory.venue){
            let obj = objectDeepClone(inventory)
            let json = {}
            if (obj.placements && obj.placements.length > 0) {
                json = {...objectDeepClone(obj.placements[0]), isCustom: true}
                Object.keys(json).forEach(key => {
                    if (key.toLowerCase() === "units") json[key] = "feet"
                    else if(key.toLowerCase() !== "iscustom") json[key] = ""
                })
                delete json["activity"]
                delete json["allowBrandsToProvide"]
                delete json["isSelected"]
                delete json["availableForBranding"]
                delete json["createdOn"]
                delete json["updatedOn"]
                delete json["inventoryMasterAttrSlug"]
                delete json["inventoryMaster"]
                json["_newAdded"] = true
                obj.placements.push(objectDeepClone(json))
                let arr = [...inventory.assetsProvidedBy]
                arr.push("Self")
                obj.assetsProvidedBy = [...arr]
                setInventory(objectDeepClone(obj)) 
                // let reviewInventoryList = objectDeepClone(inreviewInventoryList)
                // reviewInventoryList.splice(activeIndex, 1, obj)
                // setInreviewInventoryList([...reviewInventoryList])
            } 
        }
        else{
            let obj = objectDeepClone(inventory)
            let json = {}
            if (obj.placements && obj.placements.length > 0) {
                json = {...objectDeepClone(obj.placements[0]), _isCustom: true}
                Object.keys(json).forEach(key => {
                    if (key.toLowerCase() === "units") json[key] = "feet"
                    else if(key.toLowerCase() !== "_iscustom") json[key] = ""
                })
                delete json["inventory"]
                delete json["createdOn"]
                delete json["updatedOn"]
                delete json["inventoryMasterAttrSlug"]
                delete json["inventoryMaster"]
                json["_newAdded"] = true
                obj.placements.push(objectDeepClone(json))
                console.log("newAttributes", json, inventory)
                setInventory(objectDeepClone(obj))            
                // let reviewInventoryList = objectDeepClone(inreviewInventoryList)
                // reviewInventoryList.splice(activeIndex, 1, obj)
                // setInreviewInventoryList([...reviewInventoryList])
            }
        }
    }
    const openInventoryImagePreview = (index, eventIndex?) => {
        if(eventIndex){
            setSelectedEventIndex(eventIndex)
        }
        setSelectedInventoryImgIndex(index)
        setShowInventoryPreviewImg(true)
    }
    const handleDleteInventoryImage = (imageKey, index?) => {
        setOverlay(true)
        setPlacementIndex(index)
        setDeleteImageKey(imageKey)
        setShowImageDeleteModal(true)
    }
    const handleUpload = async (event, files, index?) =>{
        const formData1 = new FormData();
        setShowImageError(false)
        setNotSupportedImageError("")
        setImagesCount([])
        setInventoryImageLoading(true);
        if(index !== undefined || index !== null){
            setLoadingImgIndex(index)
        }
        for (let i = 0; i < files.length; i++) {
            if(!files[i].type.startsWith("image/")){
                setShowImageError(true)
                setNotSupportedImageError(files[i].name)
                if(index !== undefined || index !== null){
                    setImageErrorIndex(index)
                }
                event.target.value = null;
                setInventoryImageLoading(false)
                setLoadingImgIndex(-1)
                return
            }
            setImagesCount(prev=>[
                ...prev,
                files[i]
            ])
            const resizedImage = await compress.compress([files[i]], {
                size: 2, // the max size in MB, defaults to 2MB
                quality: 0.4, // the quality of the image, max is 1,
                resize: false // defaults to true, set false if you do not want to resize the image width and height
              })
              const img = resizedImage[0];
              const base64str = img.data
              const imgExt = img.ext
              let compressedFile = Compress.convertBase64ToFile(base64str, imgExt);
              formData1.append(`image`,compressedFile, img.alt);
        }
        let reqBody = formData1
        // if(inDashboard){
            let schoolName = schoolDetail.schoolName
            console.log("schoolName--->>>",schoolName);
            let destinationFolder = ""
            if(schoolName.done){
                if(inventory.months || inventory.venue){
                    destinationFolder = `schools/${context?.user?.school}-${schoolName.response.schoolName}/events`
                }else{
                    destinationFolder = `schools/${context?.user?.school}-${schoolName.response.schoolName}/inventories`
                }
                reqBody.append('destinationFolder',destinationFolder)
            }
            
        // }
        const requestOptions = {
            method: 'POST',
            body: reqBody
        };
      
        let publicContextInitiator = new PublicContextInitiator(context)
        fetch('/api/uploadFiles', requestOptions)
            .then(response => {
                response.json()
                .then(res=>{
                    let data = [];
                    res["response"].map(async item=>{
                        data.push(item.Key)
                    })
                    publicContextInitiator.getUploadedImagesSignedUrl(
                        context.validator, {keys: data}
                    ).then(res=>{
                        let updateImageObj = []
                            if((inventory.months || inventory.venue)){
                                updateImageObj = [...inventory.placements[index].images]
                            }
                            else{
                                updateImageObj = [...inventory.images]
                            }
                            let requests = res.response.map(item=>{
                            updateImageObj.push({image: item.image, key: item.key});
                            if((inventory.months || inventory.venue)){
                                let placementObj = [...inventory.placements]
                                placementObj[index]["images"] = [...updateImageObj]
                                setFormData({...formData, placements: [...placementObj]})
                            }
                            else{
                                setInventory({...inventory, images: [...updateImageObj]})
                                // parentData.data.unsavedImages.push(item.key)
                            }
                            // let placementObj = [...formData.placements]
                            // placementObj[index]["images"] = [...updateImageObj]
                            // setFormData({...formData, images: [...updateImageObj]})
                        })
                        Promise.all(requests).then(() => {
                            setInventoryImageLoading(false)
                            setLoadingImgIndex(-1)
                        });                            
                    })
                })   
            });
            // setIsLoading(false)
    }
    const deleteSingleUploadedImage = (imageKey) => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type' : 'application/json',
            },
            body: JSON.stringify({ key: imageKey && imageKey })
        }

        fetch('/api/deleteUploadedFiles', requestOptions)
        .then((res)=>{
            res.json()
        })
        .catch(err=>console.log(err))
    }
    const deleteImage = (confirm) =>{
        if(confirm){
            // if(isEdit === true && selectedAmenityDetail._id === undefined){
            //     deleteSingleUploadedImage(imageKey);
            // }else if(isEdit === true){
            //     parentData.data.removeSingleUploadedImageArray.push(imageKey)
            // }else{
                deleteSingleUploadedImage(deleteImageKey);
            // }
            const key = deleteImageKey;
            if((inventory.months || inventory.venue)){
                inventory.placements[placementIndex].images.splice(inventory.placements[placementIndex].images.findIndex(e => e.key === key), 1)
            }
            else{
                inventory.images.splice(inventory.images.findIndex(e => e.key === key),1);
            }
            if(removeImage !== null){
                setRemoveImage(!removeImage)
            }else{
                setRemoveImage(false)
            }
        }
        setDeleteImageKey("")
        setShowImageDeleteModal(false)
        setOverlay(false)
    }
    const onCancel = () => {
        setEditingInventory(false)
        // setInventory(null)
    }
    const onSave = async(confirm) => {
        if(confirm){
            if(inventory.months || inventory.venue){
                let isMonthsEdited, isVenueEdited, isOtherVenueListEdited, isAssetsProvidedByEdited, IsStatusEdited, isDataEdited;
                isDataEdited = !compareObjs(inreviewInventoryList[activeIndex].placements, inventory.placements)
                isMonthsEdited = !compareObjs(inreviewInventoryList[activeIndex].months, inventory.months)
                isVenueEdited = !compareObjs(inreviewInventoryList[activeIndex].venue, inventory.venue)
                isOtherVenueListEdited = !compareObjs(inreviewInventoryList[activeIndex].otherVenueList, inventory.otherVenueList)
                isAssetsProvidedByEdited = !compareObjs(inreviewInventoryList[activeIndex].assetsProvidedBy, inventory.assetsProvidedBy)
                IsStatusEdited = status.value !== inventory.status
                if(!(isMonthsEdited || isDataEdited || isMonthsEdited || isVenueEdited || isOtherVenueListEdited || isAssetsProvidedByEdited || IsStatusEdited)){
                    setOverlay(false)
                    setShowEditInventoryModal(false)
                    setEditingInventory(false)
                    return
                }
                else{
                    console.log("object")
                    let isValid = true
                    const result = inventoryFormValidator.current.allValid()
                    let otherVenueResult
                    if(inventory.venue.find(el => el === "Other")){
                        if(inventory.otherVenueList && inventory.otherVenueList.length === 0){
                            otherVenueResult = false
                        }
                    }
                    console.log(result)
                    if (result === false || otherVenueResult === false) {
                        formValidator.current.showMessages()
                        setShowTagsError(true)
                    } else {
                        formValidator.current.hideMessages()
                        setShowTagsError(false)
                    }
                    forceUpdate(!update)
                    for (let i = 0; i < inventory.placements.length; i++) {
                        const placement = inventory.placements[i]
                        if((Number(placement["quantity"]) > 0) && (Number(placement["length"]) === 0) && (Number(placement["breadth"]) === 0) && (Number(placement["height"]) === 0)){
                            isValid = false
                        }
                        if((Number(placement["quantity"]) === 0) && ((Number(placement["length"]) > 0) || (Number(placement["breadth"]) > 0) || (Number(placement["height"]) > 0))){
                            isValid = false
                        }
                        const emptyValues = Object.keys(placement).filter(val => {
                            if(val !== "_id" && val !== "images" && val !== "isCustom"){
                                return placement[val] !== null && placement[val] !== undefined && placement[val].toString().trim() === ""
                            }
                        })
                        if (isValid ===  true && emptyValues.length > 0) {
                            console.log("bugin12", emptyValues, isValid)
                            isValid = false
                        }
                    }
                    if (result === false || isValid === false || otherVenueResult === false) {
                        console.log("bugin1", result, isValid, otherVenueResult)
                        setInventoryErorMessage("Please fill all data.")
                        setShowTagsError(true)
                    } else {
                        setInventoryErorMessage("")
                        setShowTagsError(false)
                        inventory.placements.forEach((placement, index) => {
                            placement["data"] = objectDeepClone(placement)
                            delete placement.data.name
                            Object.keys(placement).forEach(pKey => {
                                if (pKey !== "name" && pKey !== "data") delete placement[pKey]
                            })
                        })
                        setIsPageLoading(true)
                        console.log(deleteCustomPlacementArray)
                        const finalList = formatInventoriesForEvents([inventory], "events&Occasions")
                        finalList[0]["status"] = inventory.status
                        // console.log(finalList)
                        console.log("finalList----",finalList)
                        let response = await context.apis.public.upsertStudentLifeAndActivitiesWithAttributes(
                            context.validator, {
                                student: {
                                    "school": schoolId,
                                    "user": null,
                                    "life": [],
                                    "activities": finalList,
                                    "removeActivitiesArray": [],
                                    "removeActivitiesAttributeArray": deleteCustomPlacementArray
                                }
                            }, {}
                        )
                        if (response && response?.done === true) {
                            setRefetch(!refetch)
                            toast.success("Event updated successfully")
                            setOverlay(false)
                            setEditingInventory(false)
                            setIsPageLoading(false)
                            setDeleteCustomPlacementArray([])
                        } else {
                            toast.error("Something went wrong")
                        }
                    }
                }
            }
            else{
                let isDataEdited, isImagesEdited, IsStatusEdited;
                isDataEdited = !compareObjs(inreviewInventoryList[activeIndex].attributes, inventory.placements)
                isImagesEdited = !compareObjs(inreviewInventoryList[activeIndex].images, inventory.images)
                IsStatusEdited = status.value !== inventory.status
                console.log(isDataEdited, isImagesEdited, IsStatusEdited)
                console.log(inreviewInventoryList[activeIndex].attributes, inventory.placements)
                if(!(isDataEdited || isImagesEdited || IsStatusEdited || inreviewInventoryList[activeIndex].customName !== inventory.customName)){
                    if(inreviewInventoryList[activeIndex].customName === inventory.customName){
                        setOverlay(false)
                        setShowEditInventoryModal(false)
                        setEditingInventory(false)
                        return
                    }
                }
                else{
                    let isValid = true
                    const result = inventoryFormValidator.current.allValid()
                    if (result === false) {
                        inventoryFormValidator.current.showMessages()
                    } else {
                        inventoryFormValidator.current.hideMessages()
                    }
                    forceUpdate(!update)
                    for (let i = 0; i < inventory.placements.length; i++) {
                        const placement = inventory.placements[i]
                        if((Number(placement["quantity"]) > 0) && (Number(placement["length"]) === 0) && (Number(placement["breadth"]) === 0) && (Number(placement["height"]) === 0)){
                            isValid = false
                        }
                        if((Number(placement["quantity"]) === 0) && ((Number(placement["length"]) > 0) || (Number(placement["breadth"]) > 0) || (Number(placement["height"]) > 0))){
                            isValid = false
                        }
                        const emptyValues = Object.keys(placement).filter(val => {
                            if(val !== "_id"){
                                return placement[val] !== null && placement[val] !== undefined && placement[val].toString().trim() === ""
                            }
                        })
                        if (isValid ===  true && emptyValues.length > 0) {
                            console.log("bugin2", emptyValues, isValid, placement)
                            isValid = false
                        }
                    }
                    if (result === false || isValid === false) {
                        console.log("bugin", isValid, result)
                        setInventoryErorMessage("Please fill all data.")
                    } else {
                        setInventoryErorMessage("")
                        inventory.placements.forEach((placement, index) => {
                            placement["data"] = objectDeepClone(placement)
                            delete placement.data.name
                            Object.keys(placement).forEach(pKey => {
                                if (pKey !== "name" && pKey !== "data") delete placement[pKey]
                            })
                        })
                        console.log(deleteCustomPlacementArray)
                        setIsPageLoading(true)
                        const finalList = formatInventories([inventory], inventory.type, false, false)
                        console.log(finalList)
                        finalList[0].data[0].customName = inventory.customName
                        finalList[0].data[0].type = inventory.type
                        finalList[0].type = inventory.type
                        finalList[0].data[0].status = inventory.status
                        console.log("finalList", finalList, inventory)
                        let response = await context.apis.public.upsertSchoolInventoriesDetailsBySchoolId(
                            context.validator, {
                            "school": schoolId,
                            "user": null,
                            "amenities": objectDeepClone(finalList),
                            "removeInventoriesArray": [],
                            "removeInventoryAttributesArray": deleteCustomPlacementArray
                            }, {}
                        )
                        if (response && response?.done === true) {
                            setRefetch(!refetch)
                            toast.success("Inventory updated successfully")
                            setOverlay(false)
                            setEditingInventory(false)
                            setIsPageLoading(false)
                            setDeleteCustomPlacementArray([])
                        } else {
                            toast.error("Something went wrong")
                        }
                    }
                }
            }
        }
        setOverlay(false)
        setShowEditInventoryModal(false)
    }
    const handleAssetProvidedByChange = (data, index) => {
        console.log(formData)
        let arr = [...inventory.assetsProvidedBy]
        arr.splice(index, 1, data.value)
        // console.log(arr)
        setInventory({...inventory, assetsProvidedBy: [...arr]})
    }
    const onMonthsChange = (months) => {
        const arr = months.map(el => el.value)
        // console.log(months)
        setInventory({...inventory, months: [...arr]})
        // onMonthsListUpdate(index, arr)
    }
    const onVenueChange = (venue) => {
        const arr = venue.map(el => el.value)
        setInventory({...inventory, venue: [...arr]})
    }
    const deleteInventory = async(confirm) => {
        if(confirm){
            let resp = await context.apis.public.deleteSchoolInventoriesExternal(
                context.validator, {_id: inreviewInventoryList[activeIndex]._id, school: schoolId}, {}
            )
            console.log("deleteResp", resp)
            if(resp && resp.done){
                toast.success("Inventory deleted successfully")
                if(inreviewInventoryList.length - 1 === activeIndex){
                    setActiveIndex(activeIndex - 1)
                }
                setRefetch(!refetch)
            }
        }
        setOverlay(false)
        setShowDeleteInventoryPopop(false)
    }
    const deleteEvent = async (confirm) => {
        if(confirm){
            let resp = await context.apis.public.deleteSchoolEventsExternal(
                context.validator, {_id: inreviewInventoryList[activeIndex]._id, school: schoolId}, {}
            )
            console.log("deleteResp", resp)
            if(resp && resp.done){
                toast.success("Event deleted successfully")
                if(inreviewInventoryList.length - 1 === activeIndex){
                    setActiveIndex(activeIndex - 1)
                }
                setRefetch(!refetch)
            }
        }
        setOverlay(false)
        setShowDeleteEventPopop(false)
    }
    const handleDeleteInventory = () => {
        setOverlay(true)
        if(inreviewInventoryList[activeIndex].months || inreviewInventoryList[activeIndex].venue){
            setShowDeleteEventPopop(true)
        }
        else{
            setShowDeleteInventoryPopop(true)
        }
    }

    const openDeleteModal = () => {
        setShowSchoolDeleteModal(true)
        setOverlay(true)
    }

    const deleteSchool = async(confirm) => {
        if(confirm){
            let resp = await context.apis[context.user.role].deleteSchool(
                context.validator, {_id: schoolId}
            )
            console.log("deleteSchool", resp)
            if(resp && resp.done){
                toast.success("school deleted successfully.")
                history.push('/backend/backend-details/school-list')
                
            }
        }
        setOverlay(false)
        setShowSchoolDeleteModal(false)
    }


    // console.log("backendcontext",context)
    // console.log("personalDetailsData", personalDetailsData)
    // console.log("formData--->>",formData);
    return (
        <>
            <div className={`ovelap ${overlay === true ? "active" : ""}`}></div>
            {/* <TopBar isDashboard={true} /> */}
            {isPageLoading ? 
            <Loader />
            :
            <div className="col-xl-10 col-lg-12 col-md-12 col-xs-12">
                <div className="rightPart noBack">
                    <div className="row">
                        <BackendTopBar redirectPage={true} from="onboarded-school" />
                        {/* <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-10">
                            <a className="backbtn dblock mb-0" href="javascript:void(0);"><img src="/static/media/back-arrow.7d7b4362.svg" /></a>
                        </div> */}
                        <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-10">
                            <div className="inventory-overHead my-school-features">
                                <p>school name</p>
                                <h3 className="htx4 clearfix">
                                    {schoolDetail && schoolDetail.schoolName && schoolDetail.schoolName}{(context.user.role === "backendAdmin" || context.user.role === "backendOperations" || context.user.role === "backendTech" || context.user.role === "backendFOS") && <><a className="editSchool" style={{ cursor: "pointer" }} onClick={openModal}><img src={proEdit} /> Edit</a></>}
                                    {(context.user.role === "backendAdmin" || context.user.role === "backendOperations") && <a className="editSchool" style={{ cursor: "pointer"}} onClick={openDeleteModal}><i className="fa fa-trash" style={{cursor: "pointer"}}></i> Delete</a>}
                                </h3>
                                <ul>
                                    <li>{schoolDetail && schoolDetail._id}</li>
                                    <li>{schoolLang.length !== 0 && schoolLang.map((language, index) => (
                                        index == schoolLang.length - 2 ? `${language} & ` : index == schoolLang.length - 1 ? language : `${language}, `
                                    ))} Medium
                                    </li>
                                    <li>{schoolBoard && Array.isArray(schoolBoard) && schoolBoard.length !== 0 && schoolBoard.map((board, index) => (
                                        index == schoolBoard.length - 2 ? `${readAbleBoard(board)} & ` : index == schoolBoard.length - 1 ? readAbleBoard(board) : `${readAbleBoard(board)}, `
                                    ))} Board
                                    </li>
                                    <li>Cat {schoolDetail && schoolDetail.category && schoolDetail.category}</li>
                                    <li>{schoolDetail && schoolDetail.totalStudents && schoolDetail.totalStudents} Students</li>
                                    <li> {schoolDetail && schoolDetail.builtUpArea && schoolDetail.builtUpArea} Sq Ft. School area</li>
                                    <li> {`${address && address}, ${localArea && localArea}, ${city && city}, ${pincode && pincode}, ${state && state}`}</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-10 mb-10">
                            <div className="line-full"></div>
                        </div>
                        {/* {history?.location?.state?.['fromPage'] !== 'fos-details' && */}
                            <>
                                {activeIndex >= 0 && context.user.role !== "backendSales" && context.user.role !== "backendFinance" && inreviewInventoryList && inreviewInventoryList.length > 0 &&
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-10 mb-10">
                                        {editingInventory ? 
                                            <>
                                                {inventory && inventory.placements && inventory.placements.length > 0 &&
                                                    <div className="box-design">
                                                        {!(inreviewInventoryList[activeIndex].months || inreviewInventoryList[activeIndex].venue) ?
                                                            <div className="row mb-20">
                                                                <tr className="tableInner">
                                                                    {/* <td></td> */}
                                                                    <td className="inventoryCustomName pr-0">
                                                                        {/* <div style={{marginBottom: "20px"}}>

                                                                        </div> */}
                                                                        <TextField
                                                                            formData={inventory}
                                                                            // isDisabled={(inventory.months || inventory.venue) && !inventory.custom ? true : false}
                                                                            formKey="customName"
                                                                            onDataChange={onFormDataChange}
                                                                            label='Inventory Name'
                                                                            placeholder='Inventory Name'
                                                                            isRequired={false}
                                                                            formValidator={inventoryFormValidator}
                                                                            validation="string"
                                                                            maxLength={50}
                                                                        />
                                                                    </td>
                                                                    <td className="inventoryCustomName">
                                                                        <table className="table placementTable inventoryCustomMargin">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>Placement</th>
                                                                                    <th>Quantity</th>
                                                                                    <th>L</th>
                                                                                    <th>B</th>
                                                                                    <th>H</th>
                                                                                    <th>Units</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {
                                                                                    inventory.placements && inventory.placements.map((placement, index) => (
                                                                                        <tr>
                                                                                            <td>
                                                                                                <>
                                                                                                    {
                                                                                                        placement._newAdded === true || placement._isCustom ?
                                                                                                        <>
                                                                                                            <div className="forminpt customPlacement customInventory">
                                                                                                                <TextField
                                                                                                                    formData={placement}
                                                                                                                    formKey="name"
                                                                                                                    label={true}
                                                                                                                    onDataChange={(event) => onPlacementDataChange(index, event)}
                                                                                                                    placeholder='Name'
                                                                                                                    isRequired={false}
                                                                                                                    maxLength={50}
                                                                                                                />
                                                                                                                {placement._isCustom &&
                                                                                                                    <img src={star} className="starCard" alt="star" />
                                                                                                                }
                                                                                                            </div>
                                                                                                            
                                                                                                        </>
                                                                                                        :
                                                                                                        <div className="forminpt">
                                                                                                            <label htmlFor={placement.name} className="placementName">
                                                                                                                {placement.name}
                                                                                                            </label>
                                                                                                        </div>                                                       
                                                                                                    }
                                                                                                </>
                                                                                            </td>
                                                                                            <td>
                                                                                                <>
                                                                                                    <div className="forminpt">
                                                                                                        {
                                                                                                            <NumberField
                                                                                                                formData={placement}
                                                                                                                label={true}
                                                                                                                formKey="quantity"
                                                                                                                onDataChange={(event) => onPlacementDataChange(index, event)}
                                                                                                                placeholder='100'
                                                                                                                isRequired={false}
                                                                                                            />
                                                                                                        }
                                                                                                    </div>
                                                                                                </>
                                                                                            </td>
                                                                                            <td>
                                                                                                <>
                                                                                                    <div className="forminpt">
                                                                                                        {
                                                                                                            <NumberField
                                                                                                                formData={placement}
                                                                                                                label={true}
                                                                                                                formKey="length"
                                                                                                                onDataChange={(event) => onPlacementDataChange(index, event)}
                                                                                                                placeholder='100'
                                                                                                                isRequired={false}
                                                                                                                allowDecimal={true}
                                                                                                            />
                                                                                                        }
                                                                                                    </div>
                                                                                                </>
                                                                                            </td>
                                                                                            <td>
                                                                                                <>
                                                                                                    <div className="forminpt">
                                                                                                        {
                                                                                                            <NumberField
                                                                                                                formData={placement}
                                                                                                                label={true}
                                                                                                                formKey="breadth"
                                                                                                                onDataChange={(event) => onPlacementDataChange(index, event)}
                                                                                                                placeholder='100'
                                                                                                                isRequired={false}
                                                                                                                allowDecimal={true}
                                                                                                            />
                                                                                                        }
                                                                                                    </div>
                                                                                                </>
                                                                                            </td>
                                                                                            <td>
                                                                                                <>
                                                                                                    <div className="forminpt">
                                                                                                        {
                                                                                                            <NumberField
                                                                                                                formData={placement}
                                                                                                                label={true}
                                                                                                                formKey="height"
                                                                                                                onDataChange={(event) => onPlacementDataChange(index, event)}
                                                                                                                placeholder='100'
                                                                                                                isRequired={false}
                                                                                                                allowDecimal={true}
                                                                                                            />
                                                                                                        }
                                                                                                    </div>
                                                                                                </>
                                                                                            </td>
                                                                                            <td>
                                                                                                <div className="forminpt">
                                                                                                    <SingleSelect
                                                                                                        formData={placement}
                                                                                                        formKey="units"
                                                                                                        onDataChange={(data: any) => onAttrTypeChange(index,data)}
                                                                                                        isRequired={false}
                                                                                                        options={config.attrMeasurementUnits}
                                                                                                    />
                                                                                                    <span className="closeEdit">
                                                                                                        {
                                                                                                            placement._newAdded === true ?
                                                                                                                <a onClick={() => removeNewAddedPlacement(index)} href="javascript:void(0)"><img src={closeCard} /></a>
                                                                                                                :
                                                                                                                placement._isCustom === true && 
                                                                                                                <a onClick={() => removeCustomPlacement(index, placement._id)} href="javascript:void(0)"><img src={closeCard} /></a>
                                                                                                        }
                                                                                                    </span>
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                    ))
                                                                                }
                                                                                <tr className="pd0-td">
                                                                                    <td colSpan={6}>
                                                                                        <a href='javascript:void(0)' onClick={onAddNewSizeClick}
                                                                                            className='addsize'
                                                                                            data-target='approvalAll' >+ Add Size</a>&nbsp;<small>(Add sizes for branding only)</small>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr className="pd0-td">
                                                                                    <td>
                                                                                        <b>Images</b>
                                                                                    </td>
                                                                                    <td colSpan={5}>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td className="imgUpImg" colSpan={5}>
                                                                                        <section id="gallery">
                                                                                            <div className="container">
                                                                                                <div id="image-gallery" className="manageImage manageImageList">
                                                                                                    {inventoryImageLoading ? 
                                                                                                        <div className="image" style={{height:'40px'}}>
                                                                                                            <div className="loader-imgupload"></div>
                                                                                                        </div> 
                                                                                                        :
                                                                                                        inventory.images && inventory.images.length !== 0 && inventory.images.map((el, index) => (
                                                                                                        <div className="image" key={index} style={{height:'40px'}}>
                                                                                                                                        
                                                                                                            <div onClick={() => openInventoryImagePreview(index)} id="imageId1" className="img-wrapper">
                                                                                                                <a href="javascript:void(0)"><img src={el.image} className="img-responsive" /></a>
                                                                                                                <div className="img-overlay"> <i className="fa fa-eye" aria-hidden="true"></i> </div>
                                                                                                            </div>
                                                                                                            <a onClick={()=>handleDleteInventoryImage(el.key)} href="javascript:void(0)" className="removeImg"><i className="fa fa-plus-circle" aria-hidden="true"></i></a>
                                                                                                        </div>
                                                                                                    ))}
                                                                                                    <div className="image uploadImgDesign" style={{height:'40px'}}>
                                                                                                        <label className="uploadImgPlus">+</label>
                                                                                                        <input name="amenityImages" type="file" accept="image/*" title="" onChange={(event)=> handleUpload(event, event.target.files)} multiple={true} />
                                                                                                    </div>
                                                                                                </div>
                                                                                                {showImageError && notSupportedImageError !== "" &&
                                                                                                    <p
                                                                                                        style={{
                                                                                                        fontSize: "0.8rem",
                                                                                                        color: "tomato",
                                                                                                        paddingTop: "0.5rem",
                                                                                                        }}
                                                                                                    >
                                                                                                        <span className="valError">
                                                                                                            <img src={errorSVG} />
                                                                                                            <p>The uploaded file is not supported. Please upload image file.</p>
                                                                                                        </span>
                                                                                                    </p>
                                                                                                }
                                                                                                <ImagePreview
                                                                                                    setShowImagePreview={setShowInventoryPreviewImg}
                                                                                                    showImagePreview={showInventoryPreviewImg}
                                                                                                    images={inventory && inventory.images && inventory.images}
                                                                                                    selectedImageIndex={selectedInventoryImgIndex}
                                                                                                />
                                                                                            </div>
                                                                                        </section>
                                                                                    </td>
                                                                                    <td>
                                                                                    </td>
                                                                                </tr>
                                                                                {
                                                                                    inventoryErorMessage &&
                                                                                    <tr className="tableInner">
                                                                                        <td colSpan={5}>
                                                                                            <p className="valError"><img src={errorSVG} /> {inventoryErorMessage}</p>
                                                                                        </td>
                                                                                    </tr>
                                                                                }
                                                                            </tbody>
                                                                        </table>
                                                                    </td>
                                                                </tr>
                                                            </div>
                                                            :
                                                            <div className="row mb-20">
                                                                <tr className="tableInner">
                                                                    <td colSpan={5}>
                                                                        <table className="table placementTable">
                                                                            <tr>
                                                                                <td colSpan={1}>
                                                                                    <TextField
                                                                                        formData={inventory}
                                                                                        isDisabled={(inventory.months || inventory.venue) && !inventory.custom ? true : false}
                                                                                        formKey='name'
                                                                                        onDataChange={onFormDataChange}
                                                                                        label='Event Name'
                                                                                        placeholder='Event Name'
                                                                                        isRequired={false}
                                                                                        formValidator={inventoryFormValidator}
                                                                                        validation='required'
                                                                                        maxLength={50}
                                                                                    />
                                                                                </td>
                                                                                <td colSpan={3}>
                                                                                    <b>Venue</b>
                                                                                    <div>
                                                                                        <MultiSelect
                                                                                            formData={inventory}
                                                                                            formKey="venue"
                                                                                            onDataChange={onVenueChange}
                                                                                            placeholder="Select Venue"
                                                                                            isRequired={false}
                                                                                            options={venueList}
                                                                                            formErrors={formErrors}
                                                                                            formValidator={inventoryFormValidator}
                                                                                            validation="required|array|min:1"
                                                                                        />
                                                                                    </div>
                                                                                    {/* {showTagsInput &&
                                                                                        <>
                                                                                            
                                                                                                <div className="venueInputother">
                                                                                                    <TagsInput
                                                                                                        value={formData.otherVenueList || []}
                                                                                                        onChange={onOtherVenueListChange}
                                                                                                        inputValue={tag}
                                                                                                        onChangeInput={onTagValueChange}
                                                                                                        inputProps={{
                                                                                                            className: "tags-input",
                                                                                                            placeholder: "Enter venue name"
                                                                                                        }}
                                                                                                        tagProps={{
                                                                                                            className: 'react-tagsinput-tag-custom '
                                                                                                        }}
                                                                                                        addOnBlur={true}
                                                                                                        addOnPaste={true}
                                                                                                    />
                                                                                                    {
                                                                                                        showTagsError === true &&
                                                                                                        <span className="valError"><img src={errorSVG} />Please provide venue name.</span>
                                                                                                    }
                                                                                                </div>                              
                                                                                        </>
                                                                                    } */}
                                                                                </td>
                                                                                <td colSpan={3}>
                                                                                    <b>Month</b>
                                                                                    <div>
                                                                                        <MultiSelect
                                                                                            formData={inventory}
                                                                                            formKey="months"
                                                                                            onDataChange={onMonthsChange}
                                                                                            placeholder="Select Months"
                                                                                            isRequired={false}
                                                                                            options={config.months}
                                                                                            formErrors={formErrors}
                                                                                            formValidator={inventoryFormValidator}
                                                                                            validation="required|array|min:1"
                                                                                        />
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            {
                                                                                inventory.placements.map((placement, index) => (
                                                                                    <tr>
                                                                                        <td>
                                                                                            {index === 0 && <b>Placement</b>}
                                                                                            {
                                                                                                placement._newAdded === true || placement.isCustom ?
                                                                                                <>
                                                                                                    <div className="forminpt customPlacement">
                                                                                                        <TextField
                                                                                                            formData={placement}
                                                                                                            formKey="name"
                                                                                                            label={true}
                                                                                                            onDataChange={(event) => onPlacementDataChange(index, event)}
                                                                                                            placeholder='Name'
                                                                                                            isRequired={false}
                                                                                                            maxLength={50}
                                                                                                        />
                                                                                                        {placement.isCustom &&
                                                                                                            <img src={star} className="starCard" alt="star" />
                                                                                                        }
                                                                                                    </div>
                                                                                                </>
                                                                                                :
                                                                                                <>
                                                                                                    <div className="forminpt">
                                                                                                        <label htmlFor={placement.name} className="placementName">
                                                                                                            {placement.name}
                                                                                                        </label>
                                                                                                    </div>
                                                                                                </>
                                                                                            }
                                                                                            {index === inventory.placements.length - 1 &&
                                                                                                <>
                                                                                                    <a href='javascript:void(0)' onClick={onAddNewSizeClick}
                                                                                                        className='addsize'
                                                                                                        data-target='approvalAll' >+ Add Size
                                                                                                    </a>&nbsp;<small>(Add sizes for branding only)</small>
                                                                                                </>
                                                                                            }
                                                                                        </td>
                                                                                        <td>
                                                                                            {index === 0 && <b>Images</b>}
                                                                                            <section id="gallery">
                                                                                                <div className="container">
                                                                                                    <div id="image-gallery" className="manageImage" style={{display: isLoading && loadingImgIndex === index ? "grid" : placement?.images?.length === 0 ? 'none' : "grid"}}>
                                                                                                        {isLoading && loadingImgIndex === index ? 
                                                                                                            <div className="image" style={{height:'40px'}}>
                                                                                                                <div className="loader-imgupload"></div>
                                                                                                            </div> 
                                                                                                            :
                                                                                                            placement.images && placement.images.length !== 0 && placement.images.map((el, index1) => (
                                                                                                            <div className="image" key={index} style={{height:'40px'}}>
                                                                                                                                            
                                                                                                                <div onClick={() => openInventoryImagePreview(index1, index)} id="imageId1" className="img-wrapper">
                                                                                                                    <a href="javascript:void(0)"><img src={el.image} className="img-responsive" /></a>
                                                                                                                    <div className="img-overlay"> <i className="fa fa-eye" aria-hidden="true"></i> </div>
                                                                                                                </div>
                                                                                                                <a onClick={()=>handleDleteInventoryImage(el.key, index)} href="javascript:void(0)" className="removeImg"><i className="fa fa-plus-circle" aria-hidden="true"></i></a>
                                                                                                            </div>
                                                                                                        ))}
                                                                                                    </div>
                                                                                                    <div className="image uploadImgDesign eventMargin" style={{height:'40px'}}>
                                                                                                        <label className="uploadImgPlus">+</label>
                                                                                                        <input name="amenityImages" type="file" accept="image/*" title="" onChange={(event)=> handleUpload(event, event.target.files, index)} multiple={true} />
                                                                                                    </div>
                                                                                                </div>
                                                                                                {showImageError && notSupportedImageError !== "" && index === imageErrorIndex &&
                                                                                                    <p
                                                                                                        style={{
                                                                                                        fontSize: "0.8rem",
                                                                                                        color: "tomato",
                                                                                                        paddingTop: "0.5rem",
                                                                                                        }}
                                                                                                    >
                                                                                                        <span className="valError">
                                                                                                            <img src={errorSVG} />
                                                                                                            <p>The uploaded file is not supported. Please upload image file.</p>
                                                                                                        </span>
                                                                                                    </p>
                                                                                                }
                                                                                            </section>
                                                                                        </td>
                                                                                        <td>
                                                                                            {index === 0 && <b>Asset provided by</b>}
                                                                                            <div className="forminpt">
                                                                                                <Select
                                                                                                    classNamePrefix="wisr-react-select"
                                                                                                    options={[{label: "Brand", value: "Brand"}, {label: "Self", value: "Self"}]}
                                                                                                    onChange={(data) => handleAssetProvidedByChange(data, index)}
                                                                                                    value={[{label: "Brand", value: "Brand"}, {label: "Self", value: "Self"}].filter((option) => {
                                                                                                        return option.value === inventory.assetsProvidedBy[index]
                                                                                                    })}
                                                                                                />
                                                                                                {inventoryFormValidator?.current?.message("Asset provided by", inventory.assetsProvidedBy[index], "required")}
                                                                                            </div>
                                                                                        </td>
                                                                                        <td>
                                                                                            {index === 0 && <b>Quantity</b>}
                                                                                            <div className="forminpt">
                                                                                                {
                                                                                                    <NumberField
                                                                                                        formData={placement}
                                                                                                        label={true}
                                                                                                        formKey="quantity"
                                                                                                        onDataChange={(event) => onPlacementDataChange(index, event)}
                                                                                                        placeholder='100'
                                                                                                        isRequired={false}
                                                                                                    />
                                                                                                }
                                                                                            </div>
                                                                                        </td>
                                                                                        <td>
                                                                                            {index === 0 && <b>L</b>}
                                                                                            <div className="forminpt">
                                                                                                {
                                                                                                    <NumberField
                                                                                                        formData={placement}
                                                                                                        label={true}
                                                                                                        formKey="length"
                                                                                                        onDataChange={(event) => onPlacementDataChange(index, event)}
                                                                                                        placeholder='100'
                                                                                                        isRequired={false}
                                                                                                        allowDecimal={true}
                                                                                                    />
                                                                                                }
                                                                                            </div>
                                                                                        </td>
                                                                                        <td>
                                                                                            {index === 0 && <b>B</b>}
                                                                                            <div className="forminpt">
                                                                                                {
                                                                                                    <NumberField
                                                                                                        formData={placement}
                                                                                                        label={true}
                                                                                                        formKey="breadth"
                                                                                                        onDataChange={(event) => onPlacementDataChange(index, event)}
                                                                                                        placeholder='100'
                                                                                                        isRequired={false}
                                                                                                        allowDecimal={true}
                                                                                                    />
                                                                                                }
                                                                                            </div>
                                                                                        </td>
                                                                                        <td>
                                                                                            {index === 0 && <b>H</b>}
                                                                                            <div className="forminpt">
                                                                                                {
                                                                                                    <NumberField
                                                                                                        formData={placement}
                                                                                                        label={true}
                                                                                                        formKey="height"
                                                                                                        onDataChange={(event) => onPlacementDataChange(index, event)}
                                                                                                        placeholder='100'
                                                                                                        isRequired={false}
                                                                                                        allowDecimal={true}
                                                                                                    />
                                                                                                }
                                                                                            </div>
                                                                                        </td>
                                                                                        <td>
                                                                                            {index === 0 && <b>Units</b>}
                                                                                            <div className="forminpt eventunits">
                                                                                                    <SingleSelect
                                                                                                        formData={placement}
                                                                                                        formKey="units"
                                                                                                        onDataChange={(data: any) => onAttrTypeChange(index,data)}
                                                                                                        isRequired={false}
                                                                                                        options={config.attrMeasurementUnits}
                                                                                                    />
                                                                                                <span className="closeEdit">
                                                                                                    {
                                                                                                        placement._newAdded === true ?
                                                                                                            <a onClick={() => removeNewAddedPlacement(index)} href="javascript:void(0)"><img src={closeCard} /></a>
                                                                                                            :
                                                                                                            placement.isCustom === true && 
                                                                                                            <a onClick={() => removeCustomPlacement(index, placement._id)} href="javascript:void(0)"><img src={closeCard} /></a>
                                                                                                    }
                                                                                                </span>
                                                                                            </div>
                                                                                        </td>
                                                                                        <ImagePreview
                                                                                            setShowImagePreview={setShowInventoryPreviewImg}
                                                                                            showImagePreview={showInventoryPreviewImg}
                                                                                            images={inventory && inventory.placements && inventory.placements[selectedEventIndex].images && inventory.placements[selectedEventIndex].images}
                                                                                            selectedImageIndex={selectedInventoryImgIndex}
                                                                                        />
                                                                                    </tr>
                                                                                ))
                                                                            }
                                                                            {
                                                                                inventoryErorMessage &&
                                                                                <tr className="tableInner">
                                                                                    <td colSpan={5}>
                                                                                        <p className="valError"><img src={errorSVG} /> {inventoryErorMessage}</p>
                                                                                    </td>
                                                                                </tr>
                                                                            }
                                                                        </table>
                                                                    </td>
                                                                </tr>
                                                            </div>
                                                        }
                                                        <>
                                                            <div style={{display: "flex", justifyContent: 'flex-end'}}>
                                                                <input onClick={onCancel} value="Cancel" className="btn btn-outline" type="button" />
                                                                <input onClick={() => {setShowEditInventoryModal(true); setOverlay(true)}} value="Save" className={`btn btn-orange comp-seting`} type="button" />
                                                            </div>
                                                        </>
                                                    </div>
                                                }
                                            </>
                                            :
                                            <div className="box-design">
                                                <div className="row">
                                                    <div className="col-xl-4 col-lg-4 col-md-12 col-xs-12 pl">
                                                        <div className="campaignRequestSlide schoolDetailsSlide">
                                                            <div className="campaignRequestSlideLarge">
                                                                <img style={{ cursor: "pointer" }} src={inreviewInventoryList[activeIndex]?.images[0]?.image ? inreviewInventoryList[activeIndex]?.images[0]?.image : blankImg} onClick={() => inreviewInventoryList[activeIndex]?.images[0]?.image && openImagePreview(0)} />
                                                            </div>
                                                            <div className="campaignRequestSlideSmall">
                                                                <span>
                                                                    <img style={{ cursor: "pointer" }} src={inreviewInventoryList[activeIndex]?.images[1]?.image ? inreviewInventoryList[activeIndex]?.images[1]?.image : blankImg} onClick={() => inreviewInventoryList[activeIndex]?.images[1]?.image && openImagePreview(1)} />
                                                                </span>
                                                                <span>
                                                                    <img style={{ cursor: "pointer" }} src={inreviewInventoryList[activeIndex]?.images[2]?.image ? inreviewInventoryList[activeIndex]?.images[2]?.image : blankImg} onClick={() => inreviewInventoryList[activeIndex]?.images[2]?.image && openImagePreview(2)} />
                                                                    {inreviewInventoryList[activeIndex]?.images && inreviewInventoryList[activeIndex]?.images.length > 3 && <a href="javascript:void(0);" onClick={() => inreviewInventoryList[activeIndex]?.images[3]?.image && openImagePreview(3)}>{`+ ${inreviewInventoryList[activeIndex]?.images.length - 3}`}</a>}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <ImagePreview
                                                        setShowImagePreview={setShowImagePreview}
                                                        showImagePreview={showImagePreview}
                                                        images={inreviewInventoryList[activeIndex] && inreviewInventoryList[activeIndex]?.images && inreviewInventoryList[activeIndex].images}
                                                        selectedImageIndex={selectedImageIndex}
                                                    />
                                                    <div className="col-xl-8 col-lg-8 col-md-12 col-xs-12 pr">
                                                        <div className="schoolDetailsRight">
                                                            <div className="inventory-overHead fL mt-0"><p>{inreviewInventoryList[activeIndex] && (inreviewInventoryList[activeIndex].months || inreviewInventoryList[activeIndex].venue) ? inreviewInventoryList[activeIndex]?.isApproved ? "Updated event" : "New event" : inreviewInventoryList[activeIndex]?.isApproved ? "Updated inventory" : "new inventory"}</p><h3>{inreviewInventoryList[activeIndex] && inreviewInventoryList[activeIndex].name && inreviewInventoryList[activeIndex]?.name} <img style={{cursor: "pointer", marginLeft: "40px"}} onClick={() => setEditingInventory(true)} src={proEdit} /><i className="fa fa-trash" style={{cursor: "pointer", marginLeft: "10px"}} onClick={handleDeleteInventory}></i></h3></div>
                                                            {inreviewInventoryList.length > 1 && 
                                                                <div className="slidearrow fR mt-20">
                                                                    <a href="javascript:void(0)" onClick={prevInventory}><svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M6 11L1 6L6 1" stroke="#8C8C8C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /> </svg></a>
                                                                    <a href="javascript:void(0)" onClick={nextInventory}><svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M1 11L6 6L1 1" stroke="#8C8C8C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /> </svg></a>
                                                                </div>
                                                            }
                                                        </div>
                                                        <div className="schoolDetailsTable tableInner schoolDetailsTable">
                                                            <table className="table">
                                                                <thead>
                                                                    <th>Placement</th>
                                                                    <th>Quantity</th>
                                                                    <th>Size</th>
                                                                </thead>
                                                                <tbody>
                                                                    {inreviewInventoryList[activeIndex] && inreviewInventoryList[activeIndex].attributes && inreviewInventoryList[activeIndex].attributes.length > 0 && inreviewInventoryList[activeIndex].attributes.map((item, index) => (
                                                                        <tr key={item._id}>
                                                                            <td>
                                                                                {item.name}
                                                                            </td>
                                                                            <td>
                                                                                {item.quantity}
                                                                            </td>
                                                                            <td>
                                                                                <span><em>L:</em> {`${item.length} ${item.units === "feet" ? "ft" : "in"}`}  <em>B:</em> {`${item.breadth} ${item.units === "feet" ? "ft" : "in"}`}  <em>H:</em> {`${item.height} ${item.units === "feet" ? "ft" : "in"}`}</span>
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                    {/* <tr>
                                                                        <td><b>Placement</b><span>Walls</span><span>Assembly Hall Stage</span></td>
                                                                        <td><b>Quantity</b><span>0</span><span>0</span></td>
                                                                        <td><b>Size</b><span><em>L:</em> 8ft  <em>B:</em> 9ft  <em>H:</em> 88ft</span><span><em>L:</em> 7ft  <em>B:</em> 9ft  <em>H:</em> 8ft</span></td>
                                                                    </tr> */}
                                                                </tbody>
                                                            </table>
                                                            <div className="schoolFilter campaignDetailsbtn mt-20 fR">
                                                                <a href="javascript:void(0)" className={`btn btn-outline ${schoolDetail && schoolDetail.initialApproval === false && "btn-disabled"} ${context.user.role === "backendFOS" && "btn-disabled"} ${inreviewInventoryList[activeIndex] && inreviewInventoryList[activeIndex].status !== "In Review" && "btn-disabled"}`} onClick={() => openInventoryRejectModal(inreviewInventoryList[activeIndex]._id, inreviewInventoryList[activeIndex])}>{editingInventory ? "" : "REJECT"}</a>
                                                                <a href="javascript:void(0)" className={`btn btn-outline ${schoolDetail && schoolDetail.initialApproval === false && "btn-disabled"} ${context.user.role === "backendFOS" && "btn-disabled"} ${inreviewInventoryList[activeIndex] && inreviewInventoryList[activeIndex].status !== "In Review" && "btn-disabled"}`} onClick={() => openInventoryApproveModal(inreviewInventoryList[activeIndex]._id, inreviewInventoryList[activeIndex])}>{editingInventory ? "" : "APPROVE"}</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        }
                                    </div>
                                }
                            </>
                        {/* } */}
                        <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-10 mb-10">
                            <div className="inventory-overHead" style={{display: "flex", justifyContent: "space-between"}}>
                                <p>INVENTORIES</p>
                                {context.user.role !== "backendSales" && context.user.role !== "backendFinance" && <a href="javascript:void(0);" className="btn btn-orange spaceL" onClick={() => history.push({pathname: '/backend/backend-details/add-invetory/remaining-invetories', state: {addInventory: true, schoolId: schoolId}})}>+ Add Inventory</a>}
                            </div>
                            <div className="row">
                                <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12 pl">
                                    <div className="inventory-overHead my-school-features inventorydetailsbox">
                                        <h3>Classroom Inventories</h3>
                                        {classRoomInventoryList.length > 0 &&
                                            <ul>
                                                {classRoomInventoryList.map((item, index) => (
                                                    <li key={item._id}>{item.name}</li>
                                                ))}
                                            </ul>
                                        }
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12 pl">
                                    <div className="inventory-overHead my-school-features inventorydetailsbox">
                                        <h3>Staff & Miscellaneous</h3>
                                        {outdoorInventoryList.length > 0 &&
                                            <ul>
                                                {outdoorInventoryList.map((item, index) => (
                                                    <li key={item._id}>{item.name}</li>
                                                ))}
                                            </ul>
                                        }
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12 pl">
                                    <div className="inventory-overHead my-school-features inventorydetailsbox">
                                        <h3>Common Area Inventory</h3>
                                        {commonAreaInventoryList.length > 0 &&
                                            <ul>
                                                {commonAreaInventoryList.map((item, index) => (
                                                    <li key={item._id}>{item.name}</li>
                                                ))}
                                            </ul>
                                        }
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12 pl">
                                    <div className="inventory-overHead my-school-features inventorydetailsbox">
                                        <h3>Other Room & Staff Inventory</h3>
                                        {otherRoomsAndStaffInventoryList.length > 0 &&
                                            <ul>
                                                {otherRoomsAndStaffInventoryList.map((item, index) => (
                                                    <li key={item._id}>{item.name}</li>
                                                ))}
                                            </ul>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-10 mb-10">
                            <div className="inventory-overHead" style={{display: "flex", justifyContent: "space-between"}}>
                                <p>EVENTS & OCCASIONS</p>
                                {context.user.role !== "backendSales" && context.user.role !== "backendFinance" && <a href="javascript:void(0);" className="btn btn-orange spaceL" onClick={() => history.push({pathname: '/backend/backend-details/add-invetory/events-and-occasions', state: {addInventory: false, schoolId: schoolId}})}>+ Add Event</a>}
                            </div>
                            <div className="row">
                                <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12 pl">
                                    <div className="inventory-overHead my-school-features inventorydetailsbox">
                                        <h3>Events & Occasions</h3>
                                        {activityList.length > 0 &&
                                            <ul>
                                                {activityList.map((item, index) => (
                                                    <li key={item._id}>{item.name}</li>
                                                ))}
                                            </ul>
                                        }
                                    </div>
                                </div>
                            </div>
                            {history?.location?.state?.['fromPage'] !== 'fos-details' &&
                                <>
                                    {context.user.role !== "backendSales" && context.user.role !== "backendFinance" && context.user.role !== "backendFOS" && ((schoolDetail && schoolDetail.initialApproval === false && schoolDetail.isRejected === undefined) || (schoolDetail && schoolDetail.initialApproval === false && schoolDetail.isRejected === false)) &&
                                        <div className="schoolFilter campaignDetailsbtn fR">
                                            {/* <a href="javascript:void(0)" className="btn btn-outline" onClick={openShoolRejectModal}>reject</a> */}
                                            <a href="javascript:void(0)" className="btn btn-orange" onClick={openSchoolApproveModal}>APPROVE SCHOOL</a>
                                        </div>
                                    }
                                </>
                            }
                        </div>

                    </div>
                </div>
            </div>
            }
            <div
                id='editCampaignDetail'
                className={`popup rejectPopup ${showRejectionMessageModal === false ? "hide" : ""}`}
            >
                <div className='popup-body'>
                    <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                        <h2>Reason for rejection </h2>
                    </div>
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                            <label className='lableEdit'>Rejection message</label>
                        </div>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                            <div className='forminpt'>
                                <TextArea
                                    formData={rejectionMessage}
                                    formKey='message'
                                    onDataChange={onRejectionMessageSchange}
                                    placeholder='Write reason for rejection'
                                    isRequired={false}
                                    formValidator={formValidator}
                                    validation='required'
                                    maxLength={500}
                                />
                            </div>
                        </div>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-20 end-xs">
                            <div className="forminpt">
                                <>
                                    <input onClick={() => closeRejectionMessageModal()} name="Cancel" value="Cancel" className="btn btn-outline" type="button" />
                                    <input onClick={() => onConfirmRejection()} name="Done" value="Done" className={rejectingSchool ? "btn-disabled button--loading" : "btn btn-orange"} type="button" />
                                </>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                id='editCampaignDetail'
                className={`popup rejectPopup ${showRejectionInventoryMessageModal === false ? "hide" : ""}`}
            >
                <div className='popup-body'>
                    <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                        <h2>Reason for rejection </h2>
                    </div>
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                            <label className='lableEdit'>Rejection message</label>
                        </div>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                            <div className='forminpt mb-0'>
                                <TextArea
                                    formData={rejectionInventoryMessage}
                                    formKey='message'
                                    onDataChange={onRejectionMessageChange}
                                    placeholder='Write reason for rejection'
                                    isRequired={false}
                                    formValidator={formValidator}
                                    validation='required'
                                    maxLength={500}
                                />
                            </div>
                        </div>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-20 end-xs">
                            <div className="forminpt mb-0">
                                <>
                                    <input onClick={() => closeRejectionInventoryMessageModal()} name="Cancel" value="Cancel" className="btn btn-outline" type="button" />
                                    <input onClick={() => onConfirmRejectionInventory()} name="Done" value="Done" className={`btn btn-orange ${rejectingInventory && "button--loading btn-disabled"}`} type="button" />
                                </>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                id='amenitiesModal'
                className={`popup ${showModal === false ? "hide" : ""}`}
            >
                <div className='popup-header'>
                    <span
                        onClick={closeModal}
                        className='close toggle'
                        data-target='amenitiesModal'
                    >
                        close
                    </span>
                </div>
                <div className='popup-body'>
                    <div className="row">
                        {/* <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-20">
                                <div className="brand-profile-create">
                                    <h2 className="htx2 clearfix">Create your brand profile to start using Wisr.</h2>
                                    <a onClick={openOnBoardingWizard} href="javascript:void(0);" className="btn btn-orange brand-btn">Let's Go</a>
                                </div>
                            </div> */}
                        {/* <div className="col-xl-6 col-lg-6 col-md-6 col-xs-6">
                                <ul className="tabsinventory" style={{width: "unset"}}>
                                    <li><a onClick={() => onTabClick("personal")} href="javascript:void(0);" className={`${activeTab === "personal" ? "active" : ""}`}>Personal Details</a></li>
                                    <li id="profileUserTab"><a onClick={() => onTabClick("general")} href="javascript:void(0);" className={`${activeTab === "general" ? "active" : ""}`}>General Details</a></li>
                                </ul>
                            </div>
                            {
                                activeTab === "personal" && schoolId && personalDetailsData &&
                                <div className="row">
                                    <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb40'>
                                        <PersonalDetails isBackendUser={true} backendData={personalDetailsData} showEditModal={showModal} schoolId={schoolId} schoolUserId={schoolUserId} setRefetch={setRefetch} refetch={refetch} />
                                    </div>
                                </div>
                            } */}
                        <div className={""}>
                            <div className={""} id="editSchool">
                                {updatingData ?
                                    <div style={{ height: "300px" }}>
                                        <Loader />
                                    </div>
                                    :
                                    <div className={""}>
                                        <form action="">

                                            <SchoolGeneralDetail
                                                showPrincipleDetails={true}
                                                principleDetails={principleDetails}
                                                ref={generalDetailRef}
                                                isEdit={true}
                                                // onProceed={onProceed}
                                                formData={formData}
                                                setFormData={setFormData}
                                                showPrompt={showPrompt}
                                                setShowPrompt={setShowPrompt}
                                                showModal={showModal}
                                                otherBoardRef={otherBoardRef}
                                                setOtherBoardRef={setOtherBoardRef}
                                                boardOfStudyData={boardOfStudyData}
                                                setBoardOfStudy={setBoardOfStudy}
                                                builtError={builtError}
                                                setBuiltError={setBuiltError}
                                                showBuildUpError={showBuildUpError}
                                                otherBoardReference={otherBoardReference}
                                                schoolName={schoolName}
                                                setSchoolName={setSchoolName}
                                                schoolId={schoolId}
                                                isBackendUser={true}
                                                isBackendEdit={true}
                                            // hideHeading={true}
                                            />
                                            <div className="row">
                                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                                    <h3 className="htx2 mb10">Student Details</h3>
                                                </div>
                                                <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb40'>
                                                    <ClassroomAttributes
                                                        ref={refClassrooms}
                                                        compareOldAndNewData={compareOldAndNewData}
                                                        setInitialData={setInitialData}
                                                        newParentData={oldParentData}
                                                        showModal={showModal}
                                                        schoolId={schoolId}
                                                        isBackendUser={true}
                                                    />
                                                </div>
                                                <p className="valError">
                                                    {
                                                        errorMessage && errorMessage !== "" &&
                                                        <>
                                                            <img src={errorSVG} />
                                                            {errorMessage}
                                                        </>
                                                    }
                                                </p>
                                                <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-20 end-xs'>
                                                    <div className="bottom-error-btn">
                                                        <p className="valError">
                                                            {
                                                                errorMessage && errorMessage !== "" &&
                                                                <>
                                                                    <img src={errorSVG} />
                                                                    {errorMessage}
                                                                </>
                                                            }
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="row col-xl-12 col-lg-12 col-md-12 col-xs-12 end-md">
                                                    <div className="forminpt">
                                                        <input onClick={submitForm} name="Proceed" value="Save" className={`btn btn-orange sc-ganral ${approvingSchool && "button--loading btn-disabled"}`} type="button" />
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>

                    {/* <ConfirmAlert
                        when={showPrompt}
                        title="Leave page without saving..."
                        cancelText="Cancel"
                        okText="Confirm"
                        onOK={() => true}
                        onCancel={() => false}
                        parentCallback={confirmAlertCallback}
                    /> */}
                </div>
            </div>
            <ConfirmDialog
                confirmTitle="Do you want approve this inventory"
                togglePopup={showInventoryApproveModal}
                onModalClose={handleApproveInventory}
                componentName={""}
                isLoading={approvingInventory}
            />
            <ConfirmDialog
                confirmTitle="Do you want reject this inventory"
                togglePopup={openRejectModal}
                onModalClose={handleRejectInventory}
                componentName={""}
            />
            <ConfirmDialog
                confirmTitle="Do you want approve this school"
                togglePopup={showSchoolApproveModal}
                onModalClose={handleApproveSchool}
                componentName={""}
                isLoading={approvingSchool}
            />
            <ConfirmDialog
                confirmTitle="Do you want reject this school"
                togglePopup={showSchoolRejectModal}
                onModalClose={handleRejectSchool}
                componentName={""}
            />
            <ConfirmDialog
                togglePopup={showConfirmDeleteModal}
                onModalClose={onDeleteModalClose}
                componentName={""}
            />
            <ConfirmDialog
                togglePopup={showImageDeleteModal}
                onModalClose={deleteImage}
                componentName={""}
            />
            <ConfirmDialog
                confirmTitle="Do you want to edit this inventory"
                togglePopup={showEditInventoryModal}
                onModalClose={onSave}
                componentName={""}
                isLoading={isPageLoading}
            />
            <ConfirmDialog
                confirmTitle="Do you want to delete this inventory ?"
                confirmText="Yes"
                denyText="No"
                onModalClose={deleteInventory}
                togglePopup={showDeleteInventoryPopop}
            />
            <ConfirmDialog
                confirmTitle="Do you want to delete this event ?"
                confirmText="Yes"
                denyText="No"
                onModalClose={deleteEvent}
                togglePopup={showDeleteEventPopop}
            />
             <ConfirmDialog
                confirmTitle="Do you want to delete this school ?"
                confirmText="Yes"
                denyText="No"
                onModalClose={deleteSchool}
                togglePopup={showSchoolDeleteModal}
            />
        </>
    )
}

// export default CampaignDetails
export default SchoolDetails
