import Slider from "rc-slider";
import "rc-slider/assets/index.css";

const RangeSlider = (props) => {
  const { min, max, value, onSliderValueChange, changeError, disable } = props;

  const onChange = (data) => {
    if (onSliderValueChange) {
      onSliderValueChange(data);
      changeError(false);
    }
  };

  return (
    <>
      <Slider
        min={min}
        max={max}
        value={value}
        onChange={onChange}
        handleStyle={{
          width: 27,
          height: 27,
          position: "absolute",
          top: -2,
          left: "39%",
          background: "#fff",
          borderRadius: "100%",
          cursor: disable ? "default" : "pointer",
          border: "4px solid #070707",
        }}
        trackStyle={{
          content: "",
          position: "absolute",
          left: 0,
          top: 3,
          background: "#070707",
          width: "40%",
          height: 6,
          borderRadius: 50,
        }}
      />
    </>
  );
};

export default RangeSlider;
