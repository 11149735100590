import React, {
  useState,
  useContext,
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useHistory } from "react-router-dom";

import AppContextObject from "../../../../common/context/common";
import { PublicContextInitiator } from "../../../apis/publicContextInitiator";
import proEdit from "../../../../assets/images/edit-profile.svg";
import imgInventory from "../../../../assets/images/blank-inventory-img.jpg";
import errorSVG from "../../../../assets/images/error.svg";
import closeCard from "../../../../assets/images/close-2.svg";
import Textfield from "../../../../components/formFields/textField/textField";
import Numberfield from "../../../../components/formFields/numberField";
import ConfirmDialog from "../../../../components/addon/confirmDialog/confirmDialog";
import SimpleReactValidator from "simple-react-validator";
import CrImagePreview from "../../../../components/addon/crImagePreview";
import TextArea from "../../../../components/formFields/textArea";
import { objectDeepClone } from "../../../../service/helper";
import Compress from "compress.js";
import CampaignExamplePreviewImg from "./campaignExamplePreviewImg";

interface Props {
  index?: any;
  inventoryIndex?: any;
  inventory?: any;
  formData?: any;
  setFormData?: any;
  schoolId?: any;
  campaignId?: any;
  setRefetch?: any;
  refetch?: any;
  setShowButtons?: any;
  showButtons?: any;
  setActiveSetindex?: any;
  setActiveImageIndex?: any;
  setActiveInventoryIndex?: any;
  activeSetIndex?: any;
  activeInventoryIndex?: any;
  activeImageIndex?: any;
  approveImageOfAnInventory?: any;
  rejectImageOfAnInventory?: any;
  deleteImageOfAnInventory?: any;
  approveAllImageOfAnInventory?: any;
  rejectAllImageOfAnInventory?: any;
  deleteAllImageOfAnInventory?: any;
  multiSelectInventories?: any;
  setMultiSelectInventories?: any;
  groupBy?: any;
  view: string;
  campaignLocation?:string;
}

const InventoryReport = forwardRef(
  (
    {
      inventory,
      index,
      inventoryIndex,
      formData,
      setFormData,
      schoolId,
      campaignId,
      setRefetch,
      refetch,
      setShowButtons,
      showButtons,
      setActiveSetindex,
      setActiveImageIndex,
      setActiveInventoryIndex,
      activeSetIndex,
      activeInventoryIndex,
      activeImageIndex,
      approveImageOfAnInventory,
      rejectImageOfAnInventory,
      deleteImageOfAnInventory,
      approveAllImageOfAnInventory,
      rejectAllImageOfAnInventory,
      deleteAllImageOfAnInventory,
      multiSelectInventories,
      setMultiSelectInventories,
      groupBy,
      view,
      campaignLocation
    }: Props,
    ref
  ) => {
    const context = useContext(AppContextObject);
    const history = useHistory();
    const compress = new Compress();
    const [formValidator] = useState(
      useRef(
        new SimpleReactValidator({
          element: (message, className) => (
            <span className="valError">
              <img src={errorSVG} />
              {message}
            </span>
          ),
        })
      )
    );

    const [overlay, setOverlay] = useState(false);
    const [isImageLoading, setIsImageLoading] = useState(false);
    const [showImageError, setShowImageError] = useState(false);
    const [notSupportedImageError, setNotSupportedImageError] = useState("");
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);
    const [showImagePreview, setShowImagePreview] = useState(false);
    const [deleteImageKey, setDeleteImageKey] = useState("");
    const [showImageDeleteModal, setShowImageDeleteModal] = useState(false);
    const [imagesCount, setImagesCount] = useState([]);
    const [loadingImgIndex, setLoadingImgIndex] = useState(-1);
    const [removeImage, setRemoveImage] = useState(null);
    const [showApproveModal, setShowApproveModal] = useState(false);
    const [showRejectModal, setShowRejectModal] = useState(false);

    const [showApproveRejectPopup, setShowApproveRejectPopup] = useState(false);

    const openApproveRejectPopup = (imgIndex, eventIndex?) => {
      // if(eventIndex){
      //     setSelectedEventIndex(eventIndex)
      // }
      // setSelectedImageIndex(index)
      // setShowImagePreview(true)

      if (
        context.user.role === "backendSales" ||
        view === "campaignReportView" ||
        context.user.role.startsWith("brand")
      ) {
        setSelectedImageIndex(imgIndex);
        setShowImagePreview(true);
        // setOverlay(true)
      } else {
        setActiveImageIndex(imgIndex);
        setActiveSetindex(index);
        setActiveInventoryIndex(inventoryIndex);
        setShowApproveRejectPopup(true);
      }
    };

    useImperativeHandle(ref, () => ({
      validateAll: () => {
        let result = formValidator.current.allValid();
        if (result) {
          formValidator.current.hideMessages();
        } else {
          formValidator.current.showMessages();
        }
        console.log("resultinventory", result);
        return result;
      },
    }));
    const showAllButtons = (e) => {
      if (e.target.checked) {
        let data = { setIndex: index, inventoryIndex: inventoryIndex };
        setMultiSelectInventories([...multiSelectInventories, data]);
        setShowButtons(true);
        setActiveInventoryIndex(inventoryIndex);
        setActiveSetindex(index);
      } else {
        let selectedInventorySet = objectDeepClone(multiSelectInventories);
        setMultiSelectInventories([
          ...selectedInventorySet.filter((item) => {
            return !(
              item.setIndex === index && item.inventoryIndex === inventoryIndex
            );
          }),
        ]);
        if (selectedInventorySet.length === 0) {
          setShowButtons(false);
        }
        // setActiveInventoryIndex(-1)
        // setActiveSetindex(-1)
      }
    };
    const checkInventorySelected = () => {
      let selectedInventorySet = objectDeepClone(multiSelectInventories);
      let found = selectedInventorySet.find(
        (item) =>
          item.setIndex === index && item.inventoryIndex === inventoryIndex
      );
      if (found) {
        return true;
      } else {
        return false;
      }
    };
    const onApprove = () => {
      approveImageOfAnInventory();
      setShowApproveRejectPopup(false);
    };
    const onReject = () => {
      rejectImageOfAnInventory();
      setShowApproveRejectPopup(false);
    };
    const onDelete = () => {
      setShowApproveRejectPopup(false);
      deleteImageOfAnInventory();
    };
    return (
      <>
        <div className={`ovelap ${overlay ? "active" : ""}`}></div>
        {!(
          context.user.role === "backendSales" ||
          view === "campaignReportView" ||
          context.user.role.startsWith("brand")
        ) ? (
          <tr className="cr">
            <div className="row crCheckBox" style={{ height: "30px" }}>
              <td className="">
                <div
                  className={`checkbox fnone ${
                    inventory.images.length === 0 ? "disabled-grey" : ""
                  }`}
                >
                  <input
                    type="checkbox"
                    id={`schoolsAll${index + 1}${inventory.inventoryId}`}
                    checked={checkInventorySelected()}
                    disabled={inventory.images.length === 0}
                    onClick={(e) => showAllButtons(e)}
                  />
                  <label
                    htmlFor={`schoolsAll${index + 1}${inventory.inventoryId}`}
                  >
                    {inventory.name}
                  </label>
                </div>
              </td>
            </div>
          </tr>
        ) : (
          <tr className="cr">
            <div className="row mb-20">
              <td className="">
                {groupBy === "school" ? inventory.schoolName : inventory._id}
              </td>
            </div>
          </tr>
        )}
        {inventory.images.length !== 0 ? (
          <tr className="cr">
            <div className="row">
              {/* <td className='col-xl-2 col-lg-2 col-md-2 col-xs-9'>{inventory.name}</td> */}
              <td className="">
                <section id="gallery">
                  <div className="container">
                    {(context.user.role === "backendSales" ||
                      view === "campaignReportView" ||
                      context.user.role.startsWith("brand")) &&
                      groupBy === "inventory" && (
                        <div style={{ fontSize: "14px" }}>
                          {inventory.schoolName}
                        </div>
                      )}
                    <div
                      id="image-gallery"
                      className="manageImage manageImageList"
                    >
                      {isImageLoading ? (
                        <div className="image" style={{ height: "100px" }}>
                          <div className="loader-imgupload"></div>
                        </div>
                      ) : (
                        inventory.images &&
                        inventory.images.length !== 0 &&
                        inventory.images.map((el, index) => (
                          <div
                            className="image"
                            key={index}
                            style={{
                              height: "160px",
                              width: "auto",
                              marginRight: "10px",
                              marginBottom: "10px",
                            }}
                          >
                            <div
                              onClick={() => openApproveRejectPopup(index)}
                              id="imageId1"
                              className="img-wrapper"
                              style={{ height: "100%", width: "100%" }}
                            >
                              <a
                                href="javascript:void(0)"
                                style={{ height: "100%", width: "100%" }}
                              >
                                <img
                                  style={{
                                    height: "100%",
                                    width: "100%",
                                    borderRadius: "5px",
                                    border:
                                      context.user.role === "backendSales" ||
                                      // view === "campaignReportView" ||
                                      context.user.role.startsWith("brand")
                                        ? "none"
                                        : el.approvalStatus === "rejected"
                                        ? "3px solid #FF4A4A"
                                        : el.approvalStatus === "approved" &&
                                          "3px solid #2EC193",
                                  }}
                                  src={
                                    context.user.role === "backendSales" ||
                                    view === "campaignReportView" ||
                                    context.user.role.startsWith("brand")
                                      ? el.image
                                      : el.imageMt
                                      ? el.imageMt
                                      : el.image
                                  }
                                  className="img-responsive"
                                />
                                {(context.user.role === "backendSales" ||
                                  // view === "campaignReportView" ||
                                  context.user.role.startsWith("brand")) &&
                                  groupBy === "school" && (
                                    <div
                                      style={{
                                        position: "absolute",
                                        top: "1%",
                                        left: "1%",
                                        color: "white",
                                        fontSize: "12px",
                                        backgroundColor: "rgba(0, 0, 0, 0.6)",
                                        padding: "3px",
                                      }}
                                    >
                                      {el.placementName}
                                    </div>
                                  )}
                              </a>
                              <div
                                className="img-overlay"
                                style={{ height: "100%", width: "100%" }}
                              >
                                {" "}
                                <i
                                  className="fa fa-eye"
                                  aria-hidden="true"
                                ></i>{" "}
                              </div>
                            </div>
                            {!(
                              context.user.role === "backendSales" ||
                              // view === "campaignReportView" ||
                              context.user.role.startsWith("brand")
                            ) && (
                              <span
                                style={{
                                  position: "absolute",
                                  right: "1%",
                                  top: "1%",
                                  fontSize: "11px",
                                  padding: "2px 4px",
                                  background: "black",
                                  color:
                                    el.approvalStatus === "approved"
                                      ? "#2EC193"
                                      : el.approvalStatus === "rejected"
                                      ? "#FF4A4A"
                                      : "#B4A51F",
                                }}
                              >
                                {el.approvalStatus === "approved"
                                  ? "Approved"
                                  : el.approvalStatus === "rejected"
                                  ? "Rejected"
                                  : "Pending"}
                              </span>
                            )}
                            {/* <a onClick={()=>handleDleteImage(el.key)} href="javascript:void(0)" className="removeImg"><i className="fa fa-plus-circle" aria-hidden="true"></i></a> */}
                          </div>
                        ))
                      )}
                    </div>
                    <CrImagePreview
                      setShowImagePreview={setShowImagePreview}
                      showImagePreview={showImagePreview}
                      images={inventory.images && inventory.images}
                      selectedImageIndex={selectedImageIndex}
                      location={campaignLocation}
                    />
                    {activeSetIndex !== -1 &&
                      activeInventoryIndex !== -1 &&
                      activeImageIndex !== -1 && (
                        <CampaignExamplePreviewImg
                          setShowImagePreview={setShowApproveRejectPopup}
                          showImagePreview={showApproveRejectPopup}
                          activeImageIndex={activeImageIndex}
                          setActiveImageIndex={setActiveImageIndex}
                          activeSetIndex={activeSetIndex}
                          activeInventoryIndex={activeInventoryIndex}
                          formData={formData}
                          setFormData={setFormData}
                          onApprove={onApprove}
                          onReject={onReject}
                          onDelete={onDelete}
                          inventory={inventory}
                          view={view}
                        />
                      )}
                  </div>
                </section>
              </td>
            </div>
          </tr>
        ) : (
          <tr className="cr">
            <td>No Images uploaded</td>
          </tr>
        )}
      </>
    );
  }
);
export default InventoryReport;
