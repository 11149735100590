import React, { useEffect, useState, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import wisrOptionImg from "../../../../assets/images/wisr-option-img.png";
import ConfirmDialog from "../../../../components/addon/confirmDialog/confirmDialog";
import DataContext from "../../../../service/brandContext";
import AppContextObject from "../../../../common/context/common";
import {
	numberWithCommas,
	revenueFormatter,
	titleCase,
} from "../../../../service/helper";
import BrandBackBtn from "../components/brandBackBtn";
import { WisrOptionService } from "../../../../service/wisrOptions";
import moment from "moment";
import Loader from "../../../../components/addon/loader";

const CampaignSchool = ({ setShowProgressSideMenu }) => {
	const location = useLocation();
	const history = useHistory();
	const parentData = useContext<any>(DataContext);
	const context = useContext(AppContextObject);
	const [formData, setFormData] = useState(parentData?.data?.campaignDetail);
	const [showExitPopup, setShowExitPopup] = useState(false);
	const [overlay, setOverlay] = useState(false);
	const [confirmDialogBtnLoading, setConfirmDialogBtnLoading] = useState(false);
	const [data, setData] = useState<any>({});
	const [schoolForWisrOption, setSchoolForWisrOption] = useState([]);
	const [filteredSchool, setFilteredSchool] = useState([]);
	const [reach, setReach] = useState(0);
	const [impression, setImpression] = useState(0);
	const [totalBudget, setTotalBudget] = useState(0);
	const [increasedReach, setIncreasedReach] = useState(0);
	const [increasedBudget, setIncreasedBudget] = useState(0);
	const [increasedImpression, setIncreasedImpression] = useState(0);
	// const [per]
	const [pageLoading, setPageLoading] = useState(false);
	const [locations, setLocations] = useState([]);
	const [showLocationModal, setShowLocationModal] = useState(false);
	const [eventData, setEventData] = useState([]);
	const [eventDataCount, setEventDataCount] = useState<any>(0);

	const [resultDate, setResultDate] = useState(null);

	console.log("formDataformData", formData)

	useEffect(() => {
		getCampaignAlgorithmData();
	}, []);
	useEffect(() => {
		setShowProgressSideMenu(true);
		if (parentData.setActiveModule) {
			parentData.setActiveModule({
				...parentData.activeModule,
				module: "campaignPlacement",
				subModule: "schools",
			});
		}
	}, []);
	// console.log("parentData?.data?.campaignDetail", parentData?.data.brandDetail.eventAndOccations);
	console.log("brandDetail.eventAndOccations", parentData?.data.brandDetail.eventAndOccations);
	useEffect(() => {
		const isSummary = new URLSearchParams(location.search).get("isSummary");
		if (isSummary === "true") {
			console.log("object");
			parentData.setProgress({
				brandDetails: true,
				inventory: true,
				event: true,
				school: false,
				recommendedSchool: false,
				campaignCreative: false,
				preview: false,
			});
		}
	}, []);

	let EventType = parentData?.data?.eventAndOccation?.eventAndOccations;
	let EventInventory = parentData?.data?.inventory?.inventories;
	let EventBudget = parentData.data.campaignDetail.totalBudget;
	let currentBudget = totalBudget;

	const getCampaignAlgorithmData = async () => {
		console.log("events in algo = ", [
			...parentData.data.eventAndOccation.eventAndOccations,
			...eventData,
		]);
		setPageLoading(true);
		let startDate = moment(new Date(parentData.data.brandDetail.startDate));
		let endDate = moment(new Date(parentData.data.brandDetail.endDate));
		let duration = moment.duration(endDate.diff(startDate));
		let days = duration.asDays();
		let dataset = {
			startDate: parentData.data.brandDetail.startDate,
			endDate: parentData.data.brandDetail.endDate,
			_id: null,
			searchValue: "",
			brandCategoryId: parentData.data.brandDetail.brandCategory._id,
			budget: parentData.data.campaignDetail.totalBudget,
			ageGroup: [
				parentData.data.brandDetail.targetAgeGroup.min,
				parentData.data.brandDetail.targetAgeGroup.max,
			],
			locations: [...parentData.data.brandDetail.city],
			states: [...parentData.data.brandDetail.state],
			inventories: !parentData?.data?.brandDetail?.inventory
				? []
				: [...parentData.data.inventory.inventories],
			events: !parentData?.data?.brandDetail?.eventAndOccations
				? []
				: [...parentData.data.eventAndOccation.eventAndOccations, ...eventData],
			//   [...parentData.data.eventAndOccation.eventAndOccations],
			gender:
				parentData.data.brandDetail.targetGender === "both"
					? ["boys", "girls", "co-ed"]
					: parentData.data.brandDetail.targetGender === "girls"
					? ["girls", "co-ed"]
					: ["boys", "co-ed"],
			language: parentData.data.brandDetail.studyMedium,
			board: [],
			campaignDuration: days + 1,
		};
		let resp;
		let state: any = history.location.state;
		if (state && state.showNewData) {
			resp = await context.apis[
				context.user.role
			].getRecommendedDataByCampaignDataV2(context.validator, {
				data: dataset,
			});
		} else {
			resp = await context.apis[
				context.user.role
			].getRecommendedDataByCampaignData(context.validator, { data: dataset });
		}
		console.log("getRecommendedDataByCampaignData", resp, days, dataset);
		console.log("datasetdataset", dataset);
		if (resp && resp.done) {
			setData({ ...resp.response.rows });
			setPageLoading(false);
			parentData.setData({
				...parentData.data,
				algoData: {
					classroomData: [...resp.response.rows.classrooms],
					inventoryData: !parentData?.data?.brandDetail?.inventory
						? []
						: [...resp.response.rows.inventories],
					eventData: !parentData?.data?.brandDetail?.eventAndOccations
						? []
						: [...resp.response.rows.events],
					schoolData: [...resp.response.rows.schools],
					noOfDaysInYear: resp.response.rows.noOfDaysInYear,
					percentageDiscountInWISR:
						resp.response.rows.percentageIncreaseInBudget,
					percentageIncreaseInReach:
						resp.response.rows.percentageIncreaseInReach,
					totalInternalCostPerSchool:
						resp.response.rows.totalInternalCostPerSchool,
					budgetRatio: resp.response.rows.budgetRatio,
				},
			});
		}
	};
	useEffect(() => {
		if (data) {
			console.log("consoleeee data", data);
			getRecommendedData();
		}
	}, [data]);

	const getRecommendedData = async () => {
		if (data && data.schools && data.schools.length) {
			let startDate = moment(new Date(parentData.data.brandDetail.startDate));
			let endDate = moment(new Date(parentData.data.brandDetail.endDate));
			let duration = moment.duration(endDate.diff(startDate));
			let days = duration.asDays();

			let inventoryName = !parentData?.data?.brandDetail?.inventory
				? []
				: parentData.data.inventory.inventories.reduce((acc, current) => {
						if (!acc.includes(current.name)) {
							acc.push(current.name);
						}
						return acc;
				  }, []);

			console.log("consoleeee let inventoryName", inventoryName);

			let eventName = !parentData?.data?.brandDetail?.eventAndOccations
				? []
				: parentData.data.eventAndOccation.eventAndOccations.reduce(
						(acc, current) => {
							if (current.parentName) {
								if (!acc.includes(current.parentName)) {
									acc.push(current.parentName);
								}
							} else {
								if (!acc.includes(current.name)) {
									acc.push(current.name);
								}
							}
							return acc;
						},
						[]
				  );

			console.log("consoleeee let eventName", eventName);

			const WisrOption = new WisrOptionService({
				TargetAudience:
					parentData.data.brandDetail.targetGender === "both"
						? "co-ed"
						: parentData.data.brandDetail.targetGender,
				EventsNames: [...eventName],
				InventoriesNames: [...inventoryName],
				budgetRatio: data.budgetRatio,
				campaignBudget: parentData.data.campaignDetail.totalBudget,
				campaignDurationInDays: days + 1,
				classroomData: data.classrooms, //List of classrooms
				eventData: data.events, //List of events
				inventoryData: data.inventories, //List of inventories
				schoolData: data.schools, //List of schools
				noOfDaysInYear: data.noOfDaysInYear,
				percentageDiscountInWISR: data.percentageIncreaseInBudget,
				percentageIncreaseInReach: data.percentageIncreaseInReach,
				totalInternalCostPerSchool: data.totalInternalCostPerSchool,
			});

			console.log("WiserOption", WisrOption);
			// WisrOption.$SetBudget.next(parentData.data.campaignDetail.totalBudget);
			let maxBudget = 0,
				normalBudget = 0,
				wisrBudget = 0,
				normalReach = 0,
				wisrReach = 0,
				normalImpression = 0,
				wisrImpression = 0,
				normalSchoolCount = 0,
				wisrSchoolCount = 0;
			WisrOption.$MaxBudget.subscribe((budget) => {
				maxBudget = budget;
				if (maxBudget < parentData.data.campaignDetail.totalBudget) {
					// setTotalBudget(maxBudget)
					WisrOption.$SetBudget.next(maxBudget);
					console.log("consoleeee maxBudget - if condition", maxBudget);
				}
				console.log("maxBudget", budget);
				console.log("consoleeee maxBudget", maxBudget);
			});
			WisrOption.$FilteredSchool.subscribe((schools) => {
				console.log("Filtered", schools);
				normalSchoolCount = schools.length;
				setFilteredSchool([...schools]);
				console.log("consoleeee schools", schools);
			});
			WisrOption.$CatASchool.subscribe((schools) => {
				console.log("ACatASchool", schools);
				console.log("consoleeee schools 2", schools);
			});
			WisrOption.$CatBSchool.subscribe((schools) => {
				console.log("CatBSchool", schools);
				console.log("consoleeee schools 3", schools);
			});
			WisrOption.$CatCSchool.subscribe((schools) => {
				console.log("CatCSchool", schools);
				console.log("consoleeee schools 3", schools);
			});
			WisrOption.$SchoolsForWisrOptions.subscribe((school) => {
				let location = [];
				school.forEach((item) => {
					if (
						item.city &&
						!location.find(
							(data) => data.toLowerCase() === item.city.toLowerCase()
						)
					) {
						location.push(item.city);
					}
				});
				setLocations([...location]);
				setSchoolForWisrOption([...school]);
				wisrSchoolCount = school.length;
				console.log("SchoolsForWisrOption", school);
				console.log("consoleeee schools 4", school);
			});
			WisrOption.$GetReach.subscribe((reach) => {
				setReach(reach);
				normalReach = reach;
				console.log("GetReach", reach);
				console.log("consoleeee reach", reach);
			});
			WisrOption.$GetBudget.subscribe((budget) => {
				if (parentData.data.campaignDetail.totalBudget > maxBudget) {
					setTotalBudget(maxBudget);
					normalBudget = budget;
				} else {
					setTotalBudget(budget);
					normalBudget = budget;
				}
				console.log("GetBudget", budget);
				console.log("consoleeee GetBudget", budget);
			});
			WisrOption.$OptimizedSchool.subscribe((school) => {
				console.log("OptimizedSchool", school);
				console.log("consoleeee OptimizedSchool", school);
			});
			WisrOption.$SchoolList.subscribe((school) => {
				console.log("SchoolList", school);
				console.log("consoleeee SchoolList", school);
			});
			WisrOption.$GetImpression.subscribe((impression) => {
				setImpression(impression);
				normalImpression = impression;
				console.log("GetImpression", impression);
				console.log("consoleeee GetImpression", impression);
			});
			WisrOption.$IncreasedReach.subscribe((IncreasedReach) => {
				setIncreasedReach(IncreasedReach);
				wisrReach = IncreasedReach;
				console.log("IncreasedReach", IncreasedReach);
				console.log("consoleeee IncreasedReach", IncreasedReach);
			});
			WisrOption.$IncreasedBudget.subscribe((increasedBudget) => {
				setIncreasedBudget(increasedBudget);

				wisrBudget = increasedBudget;

				console.log("increasedBudget", increasedBudget);
				console.log("consoleeee increasedBudget", increasedBudget);
			});

			WisrOption.$IncreasedImpressions.subscribe((increasedImpression) => {
				setIncreasedImpression(increasedImpression);
				wisrImpression = increasedImpression;
				console.log("increasedImpression", increasedImpression);
				console.log("consoleeee increasedImpression", increasedImpression);
			});

			// IncreasedReach
			console.log("consoleeee EventType --", EventType);
			console.log("consoleeee EventInventory --", EventInventory);
			console.log("consoleeee EventBudget --", EventBudget);
			console.log("consoleeee currentBudget --", currentBudget);
			console.log("consoleeee increasedBudget --", increasedBudget);
		}
	};

	const onSave = (confirm) => {
		if (confirm) {
			if (
				parentData?.data?.school?.schools?.length > 0 &&
				parentData.data.campaignDetail.wisrOption !== formData.wisrOption
			) {
				if (parentData) {
					if (parentData.setProgress) {
						parentData.setProgress({
							...parentData.progress,
							school: true,
						});
					}
					if (parentData.setData) {
						parentData.setData({
							...parentData.data,
							school: {
								schools: [],
								locations: [],
							},
							campaignDetail: {
								...parentData.data.campaignDetail,
								wisrOption: formData.wisrOption,
							},
						});
					}
				}
			} else {
				if (parentData) {
					if (parentData.setProgress) {
						parentData.setProgress({
							...parentData.progress,
							school: true,
						});
					}
					if (parentData.setData) {
						parentData.setData({
							...parentData.data,
							campaignDetail: {
								...parentData.data.campaignDetail,
								wisrOption: formData.wisrOption,
							},
						});
					}
				}
			}
			let state: any = history.location.state;
			if (parentData.data.brandDetail.isTrial) {
				if (state && state.showNewData) {
					history.push({
						pathname:
							"/brands/brand-details/create-trial-campaign/recommended-school",
						state: { showNewData: true },
					});
				} else {
					history.push(
						"/brands/brand-details/create-trial-campaign/recommended-school"
					);
				}
			} else {
				if (state && state.showNewData) {
					history.push({
						pathname:
							"/brands/brand-details/create-campaign/recommended-school",
						state: { showNewData: true },
					});
				} else {
					history.push(
						"/brands/brand-details/create-campaign/recommended-school"
					);
				}
			}
		}
	};
	const onExit = async (confirm) => {
		if (confirm) {
			setConfirmDialogBtnLoading(true);
			let apiData = formateData();
			console.log("apiData", apiData);
			if (apiData["_id"]) {
				console.log(apiData);
				let resp = await context.apis[context.user.role].editCampaign(
					context.validator,
					apiData
				);
				console.log("resp---", resp);
				if (resp && resp.done) {
					setConfirmDialogBtnLoading(false);
					history.push({
						pathname: "/brands/brand-details/campaigns",
						state: { campaignCreated: true },
					});
				}
			} else {
				let resp = await context.apis[context.user.role].addCampaign(
					context.validator,
					apiData
				);
				console.log("resp---", resp);
				if (resp && resp.done) {
					setConfirmDialogBtnLoading(false);
					history.push({
						pathname: "/brands/brand-details/campaigns",
						state: { campaignCreated: true },
					});
				}
			}
		}
		setShowExitPopup(false);
		setOverlay(false);
	};
	const openExit = () => {
		setShowExitPopup(true);
		setOverlay(true);
	};
	const formateData = () => {
		let inventoriesIdArray = [];
		parentData.data.inventory.inventories.forEach((inventory) => {
			let attributesIdArray = [];
			inventory.placements &&
				Array.isArray(inventory.placements) &&
				inventory.placements.length > 0 &&
				inventory.placements.map((item) => {
					attributesIdArray.push(item.name);
				});
			let obj = {
				_id: inventory._id,
				name: inventory.parentName || inventory.name,
				attributes: [...attributesIdArray],
				_custom: inventory._custom || false,
			};
			inventoriesIdArray.push(obj);
		});
		let eventIdArray = [];
		parentData.data.eventAndOccation.eventAndOccations.forEach((event) => {
			let attributesIdArray = [];
			event.placements &&
				Array.isArray(event.placements) &&
				event.placements.map((item) => {
					attributesIdArray.push(item._id || item.name);
				});
			let obj = {
				_id: event._id,
				name: event.name,
				attributes: [...attributesIdArray],
			};
			eventIdArray.push(obj);
		});
		// let imageArray = [parentData.data.campaignCreative.creatives.image1.key, parentData.data.campaignCreative.creatives.image2.key, parentData.data.campaignCreative.creatives.image3.key]
		let imageArray = [];
		if (parentData.data.campaignCreative.creatives.image1.key) {
			imageArray.push(parentData.data.campaignCreative.creatives.image1.key);
		}
		if (parentData.data.campaignCreative.creatives.image2.key) {
			imageArray.push(parentData.data.campaignCreative.creatives.image2.key);
		}
		if (parentData.data.campaignCreative.creatives.image3.key) {
			imageArray.push(parentData.data.campaignCreative.creatives.image3.key);
		}
		let obj = {
			brandId: parentData.data.brandDetail.brandId,
			name: parentData.data.brandDetail.name.trim(),
			description: parentData.data.brandDetail.description.trim(),
			objective: parentData.data.brandDetail.objective.trim(),
			startDate: parentData.data.brandDetail.startDate,
			endDate: parentData.data.brandDetail.endDate,
			locations: [],
			targetGender:
				parentData.data.brandDetail.targetGender === "both"
					? "co-ed"
					: parentData.data.brandDetail.targetGender,
			targetAgeGroup: [
				parentData.data.brandDetail.targetAgeGroup.min,
				parentData.data.brandDetail.targetAgeGroup.max,
			],
			studyMedium: [...parentData.data.brandDetail.studyMedium],
			states: [...parentData.data.brandDetail.state],
			cities: [...parentData.data.brandDetail.city],
			brandCategory: parentData.data.brandDetail.brandCategory._id,
			brandSubcategory: parentData.data.brandDetail.brandSubcategory.value,
			brandName: parentData.data.brandDetail.brandName,
			recommendEvents: false,
			campaignStatus: "draft",
			isApproved: false,
			campaignCreatives: [...imageArray],
			budget: parentData.data.campaignDetail.totalBudget,
			isTrial: parentData.data.brandDetail.isTrial,
			wisrOffering: {
				inventory: parentData.data.brandDetail.inventory,
				eventAndOccations: parentData.data.brandDetail.eventAndOccations,
				csr: parentData.data.brandDetail.csr,
				sampling: parentData.data.brandDetail.sampling,
				custom: parentData.data.brandDetail.custom,
			},
			campaignCreationProgress: {
				progress: {
					...parentData.progress,
					school: true,
				},
				currentPage: "school",
			},
			campaignData: {
				inventories: [...inventoriesIdArray],
				activities: [...eventIdArray],
				schools: [],
				schoolListDetail: [],
			},
		};
		if (parentData.data.brandDetail._id) {
			obj["_id"] = parentData.data.brandDetail._id;
		}
		return obj;
	};
	const closeMoreLocationModal = () => {
		setShowLocationModal(false);
		setOverlay(false);
	};

	return (
		<>
			<div className={`ovelap ${overlay === true && "active"}`}></div>
			{/* <TopBar isDashboard={true} /> */}
			<div className="col-xl-9 col-lg-12 col-md-12 col-xs-12">
				{pageLoading ? (
					<Loader />
				) : (
					<div className="rightPart noBack">
						<div className="row">
							<div className="col-xs-12 col-lg-12 col-md-12 col-xs-12">
								<BrandBackBtn />
							</div>
							<div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-20">
								<h2 className="htx5 clearfix mt-10 mb-20">Create a Campaign</h2>
							</div>

							<div className="col-xl-10 col-lg-10 col-md-12 col-xs-12 mb-20">
								<div className="createCampaingTable">
									<div className="table-container mt-10">
										<table className="table">
											<thead>
												<tr>
													<th style={{ width: "25%" }}>Elements</th>
													<th style={{ width: "35%" }}>
														<div className="radioBtn">
															<input
																type="radio"
																id="boys"
																name="radio-group"
																// checked={!formData.wisrOption}
																checked={
																	(parentData?.data.brandDetail.eventAndOccations && totalBudget === increasedBudget) || (parentData?.data.brandDetail.inventory && totalBudget === increasedBudget) ? formData.wisrOption : !formData.wisrOption
																}
																onClick={() =>
																	setFormData({
																		...formData,
																		wisrOption: false,
																	})
																}
																
																disabled={
																	(parentData?.data.brandDetail.eventAndOccations && totalBudget === increasedBudget) ||
																	(parentData?.data.brandDetail.inventory && totalBudget === increasedBudget)
																}
																
															/>
															<label htmlFor="boys">CURRENT OPTION</label>
														</div>
													</th>

{
													(parentData?.data.brandDetail.inventory && totalBudget === increasedBudget) || (parentData?.data.brandDetail.eventAndOccations && totalBudget === increasedBudget) ?
													
													"" 
													:
													<th className="activeTd" style={{ width: "40%" }}>
														<div className="radioBtn">
															<input
																type="radio"
																id="boys2"
																name="radio-group"
																// checked={formData.wisrOption}
																checked={
																	// (parentData?.data.brandDetail.eventAndOccations && totalBudget === increasedBudget) || (parentData?.data.brandDetail.inventory && totalBudget === increasedBudget) ? formData.wisrOption : !formData.wisrOption
																	(parentData?.data.brandDetail.eventAndOccations && totalBudget === increasedBudget) || (parentData?.data.brandDetail.inventory && totalBudget === increasedBudget) ? formData.wisrOption : (parentData?.data.brandDetail.eventAndOccations && totalBudget !== increasedBudget) || (parentData?.data.brandDetail.inventory && totalBudget !== increasedBudget) ? formData.wisrOption : !formData.wisrOption
																}
																
																onClick={() =>
																	setFormData({ ...formData, wisrOption: true })
																}
															/>
															<label htmlFor="boys2">WISR OPTION</label>
														</div>
													</th>

}

												</tr>


											</thead>
											<tbody>
												<tr>
													<td></td>

{

parentData?.data.brandDetail.eventAndOccations && !parentData?.data.brandDetail.inventory ?
													<td>
													The current reach count as per your selected partner schools, locations, TA and budget. 
													</td>
													:
													parentData?.data.brandDetail.eventAndOccations && parentData?.data.brandDetail.inventory ?		
													<td>
														The current reach and impression count as per your
														selected partner schools, locations, TA and budget.
													</td>
													:
													<td>
														The current reach and impression count as per your
														selected partner schools, locations, TA and budget.
													</td>

}

													


{




													// (parentData?.data.brandDetail.inventory && totalBudget === increasedBudget) || (parentData?.data.brandDetail.eventAndOccations && totalBudget === increasedBudget) ?
													// "" 
													// :
													// <td>
													// 	Maximise campaign efficiency with our platform
													// 	intelligence. Get a significant increase in reach
													// 	and impressions with a minor budget addition.
													// </td>


													(parentData?.data.brandDetail.inventory && totalBudget === increasedBudget) || (parentData?.data.brandDetail.eventAndOccations && totalBudget === increasedBudget) ?
													"" 
													:
													parentData?.data.brandDetail.eventAndOccations && !parentData?.data.brandDetail.inventory ?
													<td>
														Maximise campaign efficiency with our platform intelligence. Get a significant increase in reach with a minor budget addition.
													</td>
:
parentData?.data.brandDetail.eventAndOccations && parentData?.data.brandDetail.inventory ?	
<td>
														Maximise campaign efficiency with our platform
														intelligence. Get a significant increase in reach
														and impressions with a minor budget addition.
													</td>
													:
													<td>
														Maximise campaign efficiency with our platform
														intelligence. Get a significant increase in reach
														and impressions with a minor budget addition.
													</td>

													
													
													}


												</tr>
												<tr>
													<td>
														<span className="tdTx">Budget</span>
													</td>
													<td>
														<span className="tdTx">
															{totalBudget.toLocaleString("en-IN")}
														</span>
													</td>

													

												{
													(parentData?.data.brandDetail.inventory && totalBudget === increasedBudget) || (parentData?.data.brandDetail.eventAndOccations && totalBudget === increasedBudget) ?
													"" 
													:
													<td>
													<span className="tdTx w60">
													{increasedBudget.toLocaleString("en-IN")}
													</span>
													<b className="tdTg w40">
													{totalBudget && totalBudget > 0
													? `${Math.round(
													((increasedBudget - totalBudget) * 100) /
													totalBudget
													)}%`
													: "- %"}
													</b>
													</td>
												}
													
												</tr>
												<tr>
													<td>
														<span className="tdTx">No of Schools</span>
													</td>
													<td>
														<span className="tdTx">
															{filteredSchool.length.toLocaleString("en-IN")}
														</span>
													</td>

													

{
													(parentData?.data.brandDetail.inventory && totalBudget === increasedBudget) || (parentData?.data.brandDetail.eventAndOccations && totalBudget === increasedBudget) ?
													"" 
													:
													<td>
														<span className="tdTx w60">
															{schoolForWisrOption.length.toLocaleString(
																"en-IN"
															)}
														</span>
														<b className="tdTg w40">
															{filteredSchool && filteredSchool.length > 0
																? `${Math.round(
																		((schoolForWisrOption.length -
																			filteredSchool.length) *
																			100) /
																			filteredSchool.length
																  )}%`
																: "- %"}
														</b>
													</td>

}
												</tr>
												<tr>
													<td>
														<span className="tdTx">Estimated Reach</span>
													</td>
													<td>
														<span className="tdTx">
															{reach.toLocaleString("en-IN")}
														</span>
													</td>

												

{
													(parentData?.data.brandDetail.inventory && totalBudget === increasedBudget) || (parentData?.data.brandDetail.eventAndOccations && totalBudget === increasedBudget) ?
													"" 
													:
													<td>
														<span className="tdTx w60">
															{increasedReach.toLocaleString("en-IN")}
														</span>
														<b className="tdTg w40">
															{reach && reach > 0
																? `${Math.round(
																		((increasedReach - reach) * 100) / reach
																  )}%`
																: "- %"}
														</b>
													</td>
}

												</tr>

{
!parentData?.data.brandDetail.inventory && parentData?.data.brandDetail.eventAndOccations ?
"" : 
parentData?.data.brandDetail.inventory && parentData?.data.brandDetail.eventAndOccations ?

												<tr>
													<td>
														<span className="tdTx">Estimated Impressions </span>
													</td>


													<td>
														<span className="tdTx">
															{impression.toLocaleString("en-IN")} 
														</span>
													</td>
{
													(parentData?.data.brandDetail.inventory && totalBudget === increasedBudget) || (parentData?.data.brandDetail.eventAndOccations && totalBudget === increasedBudget) ?
													"" 
													:
<td>
														<span className="tdTx w60">
															{increasedImpression.toLocaleString("en-IN")} 
														</span>
														<b className="tdTg w40">
															{impression && impression > 0
																? `${Math.round(
																		((increasedImpression - impression) * 100) /
																			impression
																  )}%`
																: "- %"}
														</b> 
													</td>
}
												</tr>

												:
												<tr>
													<td>
														<span className="tdTx">Estimated Impressions </span>
													</td>


													<td>
														<span className="tdTx">
															{impression.toLocaleString("en-IN")}
														</span>
													</td>
{
													(parentData?.data.brandDetail.inventory && totalBudget === increasedBudget) || (parentData?.data.brandDetail.eventAndOccations && totalBudget === increasedBudget) ?
													"" 
													:
<td>
														<span className="tdTx w60">
															{increasedImpression.toLocaleString("en-IN")} 
														</span>
														<b className="tdTg w40">
															{impression && impression > 0
																? `${Math.round(
																		((increasedImpression - impression) * 100) /
																			impression
																  )}%`
																: "- %"}
														</b> 
													</td>
}
												</tr>

}


											</tbody>
										</table>
									</div>
								</div>
							</div>
							<div className="col-xl-10 col-lg-10 col-md-12 col-xs-12">
								<div className="row">
									<div className="col-xl-5 col-lg-5 col-md-6 col-xs-12 delete-user mt-30">
										<a
											className="viewwLink"
											style={{ cursor: "pointer" }}
											onClick={openExit}
										>
											Save & Continue Later
										</a>
									</div>
									<div className="col-xl-7 col-lg-7 col-md-6 col-xs-12 end-md mt-30">
										<div className="forminpt">
											<input
												name="Save &amp; next"
												className="btn btn-orange"
												type="button"
												value="Save &amp; next"
												onClick={() => onSave(true)}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
			<div
				id="school-events-popup"
				className={`popup ${showLocationModal === false ? "hide" : ""}`}
			>
				<div className="popup-header">
					<h3 className="htx2 pophead">Location</h3>
					<span
						className="close toggle"
						onClick={closeMoreLocationModal}
						data-target="myPopup"
					>
						close
					</span>
				</div>
				<div className="popup-body">
					<div className="searchlist cale-popup-tue">
						<h3>Location</h3>
						<ul>
							{locations &&
								Array.isArray(locations) &&
								locations.length !== 0 &&
								locations.map((item, index) => (
									<li>
										<a href="javascript:void(0);">
											<span></span>
											{titleCase(item)}
										</a>
									</li>
								))}
						</ul>
					</div>
				</div>
			</div>
			<ConfirmDialog
				togglePopup={showExitPopup}
				onModalClose={onExit}
				componentName={""}
				confirmTitle="Do you want to save and exit?"
				isLoading={confirmDialogBtnLoading}
			/>
		</>
	);
};

export default CampaignSchool;
