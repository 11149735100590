import React, { useEffect, useState } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
import { Bar } from "react-chartjs-2"
import { isObjectEmpty } from '../../../../../service/helper';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

function InventoryRevenueChart(props) {
  const {
    apiData
  } = props
  console.log("apiData>>>",apiData);
    // const labels = ["Nike", "Addidas", "Lifebouy", "Hamley"];
    // let data:any = {
    // labels: labels,
    // datasets: [{
    //     axis: 'y',
    //     label: 'Campaign',
    //     data: [95, 50, 28, 10],
    //     fill: false,
    //     backgroundColor: [
    //     '#744fc2',
    //     '#744fc2',
    //     '#744fc2',
    //     '#744fc2'
    //     ],
    //     borderColor: [
    //     '#744fc2',
    //     '#744fc2',
    //     '#744fc2',
    //     '#744fc2'
    //     ],
    //     borderWidth: 1
    // }],
    // indexAxis: 'y',
    // plugins: {
    //     legend: {
    //       position: 'right' as const,
    //     },
    //     title: {
    //       display: true,
    //       text: 'Chart.js Horizontal Bar Chart',
    //     },
    //   },
    //   responsive: true,
    // };
    const options = {
        indexAxis: 'y' as const,
        barThickness: 6,
        elements: {
          bar: {
            borderWidth: 0,
          },
        },
        defaultFontFamily: "'nexabold', sans-serif",
        font: {size: 12, family: "'nexabold', sans-serif"},
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom' as const,
                labels:{
                  boxWidth: 0,
                  font: {
                      size: 12,
                      family: "'nexabold', sans-serif",
                      style: 'normal' as const
                  }
                }
            },
          title: {
            display: true,
            text: 'Campaign',
            position: 'left' as const,
            font: {
              size: 12,
              family: "'nexabold', sans-serif",
              style: 'normal' as const
          }
          },
        },
        scales: {
          y: {
            grid: {
              display: false,
            },
            font: {
              size: 12,
              family: "'nexabold', sans-serif",
              style: 'normal' as const
            },
            ticks: {
                font: {
                  size: 12,
                  family: "'nexabold', sans-serif",
                  style: 'normal' as const
                },
                function(label) {
                  if (/\s/.test(label)) {
                    return label.split(" ");
                  }else{
                    return label;
                  }  
                }
            }
          },
        },
      };

      const [labels, setLabels] = useState([])
      const [chartData, setChartData] = useState([])

      useEffect(() => {
        let arr = [];
        let dArr = []
        if(!isObjectEmpty(apiData)){
          for(let data of apiData.brandRevenueContribution){
            arr.push(data.campaign)
            dArr.push(data.revenue)
          }
          setLabels(arr)
          setChartData(dArr)
        }else{
          setLabels([])
          setChartData([])
        }
        
      }, [apiData])
      
      // const labels = ['Nike', 'Adiddas', 'Lifebouy', 'Hamley'];
      
      const data = {
        labels,
        datasets: [
          {
            label: 'INR(1000)',
            data: chartData,
            borderColor: '#744fc2',
            backgroundColor: '#744fc2',
            borderRadius: 60,
            
          }
        ],
      };
    return (
        <div>
            <Bar data={data} options={options} />
        </div>
    )
}

export default InventoryRevenueChart
