import { useState, useContext, useEffect, useRef } from "react"

import { Link, useHistory } from "react-router-dom"

import AppContextObject from "../../../common/context/common"

import { ExportToCsv } from 'export-to-csv';


const SchoolInfraDetail = () => {
    const context = useContext(AppContextObject)
    const history = useHistory()
    const [overlay, setOverlay] = useState(false)

   
    useEffect(() => {
        getAllSchoolInfraDetails()
    }, [])

    const getAllSchoolInfraDetails = async() => {
        // setIsLoading(true)
        let resp = await context.apis[context.user.role].getAllSchoolInfraDetails(
            context.validator, {}, {}
        )
        //console.log("getAllSchoolInfraDetails", resp)

        let data = resp.response

        if (resp && resp.done) {
            
        const options = { 
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showTitle: false,
            filename:'SchoolInfraDetails',
            useTextFile: false,
            useKeysAsHeaders: true,
            // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
        };
       
        const csvExporter = new ExportToCsv(options);
        csvExporter.generateCsv(data);
       
        }
    }

   

    

    
        
    return (
        <>
            <div className={`ovelap ${overlay === true ? "active" : ""}`}></div>
            <div className="col-xl-10 col-lg-12 col-md-12 col-xs-12">
                <div className="rightPart noBack">
                    <div className="row">
                       
                        <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                            <h2 className="htx2 clearfix">Schools InfraDetails</h2>
                        </div>
                        
                    </div>
                </div>
            </div>
        </>
    )
}

export default SchoolInfraDetail