import { useState } from "react"

import check from "../../../assets/images/check.svg"

const TextArea = (props) => {

    const {
        formData,
        formKey,
        onDataChange,
        label,
        placeholder,
        classes,
        wrapperClasses,
        maxLength,
        formValidator,
        validation,
        isDisabled
    } = props

    const [classList] = useState(classes ? classes : "")
    const [wrapperClassList] = useState(wrapperClasses ? wrapperClasses : "")

    return (
        <>
        <div className={`${label ? "forminpt" : ""} ${wrapperClassList} ${isDisabled === true ? "disabled" : ""} `}>
            {
                label &&
                <label>{label}</label>
            }
            <textarea
                id={formKey}
                value={formData[formKey]}
                onChange={onDataChange}
                className={`${classList} ${(formValidator?.current?.messagesShown === true && formValidator?.current?.fields?.[formKey] === false) ? "onFocus" : ""}`}
                placeholder={placeholder}
                maxLength={maxLength}
                disabled={isDisabled === true ? true : false}
            ></textarea>
            {formValidator?.current?.message(formKey, formData[formKey], validation)}
        </div>
        </>
    )
}

export default TextArea
