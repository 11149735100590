import React, { useState, useEffect, useRef, useContext} from 'react'
import uploadDocument from "../../../../assets/images/img-upload.jpg"
import errorSVG from "../../../../assets/images/error.svg"
import deleteImg from "../../../../assets/images/delete.svg"

import SimpleReactValidator from "simple-react-validator"
import ImageModal from '../../../../components/addon/imageModal/imageModal'
import TextField from '../../../../components/formFields/textField/textField'
import Compress from "compress.js"

import toast from "react-hot-toast"
import { PublicContextInitiator } from '../../../apis/publicContextInitiator'
import AppContextObject from '../../../../common/context/common'
import { profile } from 'console'
import noImg from "../../../../assets/images/blank-inventory-img.jpg"
import editSvg from "../../../../assets/images/edit-btn.svg"
import { compareObjs } from '../../../../service/helper'

const EditDocuments = (props) => {
    const {
        documents,
        setDocuments,
        setOverlay,
        refetch,
        setRefetch,
        profileData
    } = props
    const [formValidator] = useState(useRef(new SimpleReactValidator({
        element: (message) =>
            <span className="valError"><img src={errorSVG} />
                {message === "The gst no field is required." || message === "The pan no field is required."
                    ?
                    "The document number field is required."
                    :
                    message
                }
            </span>
        })))
    const context = useContext(AppContextObject)
    const compress = new Compress()
    const [editingData, setEditingData] = useState(false)
    const [toggleGstImage, setToggleGstImage] = useState(false)
    const [togglePanImage, setTogglePanImage] = useState(false)
    const [toggleEditGstImage, setToggleEditGstImage] = useState(false)
    const [toggleEditPanImage, setToggleEditPanImage] = useState(false)
    const [gstImage, setGstImage] = useState({image: "", isPdf: false})
    const [panImage, setPanImage] = useState({image: "", isPdf: false})
    const [formData, setFormData] = useState(documents)
    const [oldFormData, setOldFormData] = useState(documents)
    const [update, forceUpdate] = useState(false)
    const [loading, setLoading] = useState(false)
    const [gstErrorMsg, showGstErrorMsg] = useState(false)
    const [panErrorMsg, showPanErrorMsg] = useState(false)
    const [editingDocument, setEditingDocument] = useState(false)
    const [gstDocumentUploaded, setGstDocumentNotUploaded] = useState(false)
    const [panDocumentUploaded, setPanDocumentNotUploaded] = useState(false)

    useEffect(() => {
        if(update === true){
            formValidator.current.showMessages()
        }
    }, [update])
    useEffect(() => {

    }, [])

    const openEditMode = () => {
        if(documents.gst && documents.gst.file && documents.gst.file.length !== 0){
            setGstImage({image: documents.gst.file[0].image, isPdf: documents.gst.file[0].key.endsWith(".pdf")})
        }
        if(documents.pan && documents.pan.file && documents.pan.file.length !== 0){
            setPanImage({image: documents.pan.file[0].image, isPdf: documents.pan.file[0].key.endsWith(".pdf")})
        }
        setEditingData(true)
    }

    const handleSave = async(confirm) => {
        if(confirm){
            const result = formValidator.current.allValid()
            console.log("result------",result)

            if (result === false) {
                formValidator.current.showMessages()
                forceUpdate(true)
                return
            } else {
                formValidator.current.hideMessages()
                // forceUpdate(!update)
            }
            if(formData.gst.gstNo !== "" && formData.gst.file === ""){
                setGstDocumentNotUploaded(true)
                toast.error("Please upload gst file")
                return
            }
            if(formData.pan.panNo !== "" && formData.pan.file === ""){
                setPanDocumentNotUploaded(true)
                toast.error("Please upload pan file")
                return
            }
            setEditingDocument(true)
            console.log(formData)
            let apiData = transformObj()

            let isGstDataSame = compareObjs(apiData.documents.gst, oldFormData.gst)
            let isPanDataSame = compareObjs(apiData.documents.pan, oldFormData.pan)
            let payload = {
                documents:
                { 
                    gst:{
                        file: oldFormData.gst.file && oldFormData.gst.file.length !== 0 ? oldFormData.gst.file[0].key : "",
                        number: oldFormData.gst.gstNo,
                        status: oldFormData.gst.status
                    }, 
                    pan:{
                        file: oldFormData.pan.file && oldFormData.pan.file.length !== 0 ? oldFormData.pan.file[0].key : "",
                        number: oldFormData.pan.panNo,
                        status: oldFormData.pan.status
                    }
                }
            }
            if(isGstDataSame === false){
                payload.documents.gst = {
                    file: formData.gst.file && formData.gst.file.length !== 0 ? formData.gst.file[0].key : "",
                    number: formData.gst.gstNo,
                    status: formData.gst.file === "" && formData.gst.gstNo === "" ? "required" : "pending"
                }
            }
            if(isPanDataSame === false){
                payload.documents.pan = {
                    file: formData.pan.file && formData.pan.file.length !== 0 ? formData.pan.file[0].key : "",
                    number: formData.pan.panNo,
                    status: formData.gst.file === "" && formData.gst.gstNo === "" ? "required" : "pending"
                }
            }
            console.log("payload",payload);

            if(isGstDataSame === false || isPanDataSame === false){
                let updateResp = await context.apis[context.user.role].updateBrand(
                    context.validator, {_id: context.user.parentBrand || context.user.brand, sectionName: 'document details'}, payload
                )

                console.log("dadupdateResp",updateResp);
    
                if(updateResp && updateResp.done){
                    toast.success("Brand edited successfully")
                    setEditingDocument(false)
                    setRefetch(!refetch)
                    setEditingData(false)
                    setFormData({})
                    setDocuments({})
                }
            }else{
                setEditingDocument(false)
                setEditingData(false)
                setFormData({...documents})
            }
        }
        else{
            setEditingDocument(false)
            setEditingData(false)
            setFormData({...documents})
            setGstImage({image: "", isPdf: false})
            setPanImage({image: "", isPdf: false})
        }
    }
    formValidator.current.rules['pan_card'] = {
        message: 'Please enter valid PAN Number.',
        rule: (val) => {
            const pattern = /[A-Z]{5}[0-9]{4}[A-Z]{1}$/
            return pattern.test(val)
        },
    }

    formValidator.current.rules['gst_no'] = {
        message: 'Please enter valid GST Number.',
        rule: (val) => {
            const pattern = /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/
            return pattern.test(val)
        },
    }
    const confirmClose = (type) => {
        if(!editingData){
            if(type === "gst"){
                setToggleGstImage(false)
            }
            else if(type === "pan"){
                setTogglePanImage(false)
            }
        }
        else{
            if(type === "gst"){
                setToggleEditGstImage(false)
            }
            else if(type === "pan"){
                setToggleEditPanImage(false)
            }
        }
        setOverlay(false)
    }

    const openImageModal = (type) =>{
        if(!editingData){
            if(type === "gst"){
                setToggleGstImage(true)
            }
            else if(type === "pan"){
                setTogglePanImage(true)
            }
        }
        else{
            if(type === "gst"){
                setToggleEditGstImage(true)
            }
            else if(type === "pan"){
                setToggleEditPanImage(true)
            }
        }
        setOverlay(true)
    }
    const handleUpload = async(file, type) => {
        const formData1 = new FormData();
        showGstErrorMsg(false)
        showPanErrorMsg(false)
        for (let i = 0; i < file.length; i++) {
            if (file[i]['type'].includes("image")) {
                const resizedImage = await compress.compress([file[i]], {
                    size: 2, // the max size in MB, defaults to 2MB
                    quality: 0.4, // the quality of the image, max is 1,
                    resize: false // defaults to true, set false if you do not want to resize the image width and height
                }).catch(err => {
                    console.log("Error while compressing....", err)
                })
                const img = resizedImage[0];
                const base64str = img.data
                const imgExt = img.ext
                let compressedFile = Compress.convertBase64ToFile(base64str, imgExt)
                formData1.append(`image`,compressedFile, img.alt)
                let reqBody = formData1
                // let destinationFolder = `brands/${context?.user?.parentBrand ? context?.user?.parentBrand : context?.user?.brand}-${context?.user?.companyName ? context?.user?.companyName.trim() : context?.user?.brandName.trim()}/documents`
                let destinationFolder = `brands/${context?.user?.parentBrand ? context?.user?.parentBrand : context?.user?.brand}/documents`
                reqBody.append('destinationFolder',destinationFolder)
                fileUploader(reqBody, type)
            } else if (file[i]['type'].includes("pdf")) {
                formData1.append("image", file[i])
                let reqBody = formData1
                // let destinationFolder = `brands/${context?.user?.parentBrand ? context?.user?.parentBrand : context?.user?.brand}-${context?.user?.companyName ? context?.user?.companyName.trim() : context?.user?.brandName.trim()}/documents`
                let destinationFolder = `brands/${context?.user?.parentBrand ? context?.user?.parentBrand : context?.user?.brand}/documents`
                reqBody.append('destinationFolder',destinationFolder)
                fileUploader(reqBody, type)
            } else {
                if(type === "gst") {
                    showGstErrorMsg(true)
                }
                else {
                    showPanErrorMsg(true)
                }
                // toast.error("Please upload pdf or image file")
                return
            }
        }
    }
    const fileUploader = (formData1, type) => {
        const requestOptions = {
            method: 'POST',
            body: formData1
        }
        setLoading(true)
        const publicContextInitiator = new PublicContextInitiator(context)
        fetch('/api/uploadFiles', requestOptions)
        .then(response => {
            response.json()
            .then(res=>{
                if (res?.response && res?.response.length > 0) {
                    // if (fileUploadCallback) {
                    //     fileUploadCallback(formKey, res.response[0].Key)
                    // }
                    if(type === "gst"){
                        setFormData({
                            ...formData,
                            gst: {...formData.gst, file: [res.response[0]]}
                        })
                    }
                    else if(type === "pan"){
                        setFormData({
                            ...formData,
                            pan: {...formData.pan, file: [res.response[0]]}
                        })
                    }
                }
                let data = []
                res["response"].map(async item=>{
                    data.push(item.Key)
                })
                publicContextInitiator.getUploadedImagesSignedUrl(
                    context.validator, {keys: data}
                ).then(res=> {
                    if (res?.response && res?.response?.length > 0) {
                        if(type === "gst"){
                            setGstImage({image: res.response[0].image, isPdf: res.response[0].key.endsWith(".pdf")})
                        }
                        else if(type === "pan"){
                            setPanImage({image: res.response[0].image, isPdf: res.response[0].key.endsWith(".pdf")})
                        }
                    }
                    setLoading(false)
                })
                .catch(err => {
                    console.log("Error while fetching image:", err)
                    setLoading(false)
                })
            })
        })
        .catch(err => {
            console.log("Error while fetching image:", err)
            setLoading(false)
        })        
    }
    const onGstNoChange = (e) => {
        setFormData({
            ...formData,
            gst: {...formData.gst, [e.target.id]: e.target.value}
        })
    }
    const onPanNoChange = (e) => {
        setFormData({
            ...formData,
            pan: {...formData.pan, [e.target.id]: e.target.value}
        })
    }
    const removeImage = (type) => {
        if(type === "gst"){
            setFormData({
                ...formData,
                gst: {
                    ...formData.gst,
                    gstNo: "",
                    file: ""
                }
            })
            setGstImage({image: "", isPdf: false})
        }
        else if(type === "pan"){
            setFormData({
                ...formData,
                pan: {
                    ...formData.pan,
                    panNo: "",
                    file: ""
                }
            })
            setPanImage({image: "", isPdf: false})
        }
    }
    const transformObj = () => {
        const obj = {
            documents: {
                gst: {
                    file: formData.gst.file && formData.gst.file.length !== 0 && formData.gst.file,
                    gstNo: formData.gst.gstNo,
                    status: formData.gst.status
                },
                pan: {
                    file: formData.pan.file && formData.pan.file.length !== 0 && formData.pan.file,
                    panNo: formData.pan.panNo,
                    status: formData.pan.status
                }
            },
        }
        return obj
    }

    const openPDF = (dataString) => {
        // var string = dataString
        // var iframe = "<a href='" + string + "' download style='position: absolute; background: #FE6E00; color: #fff; border: 1px solid #FE6E00; right: 20%; font-family: sans-serif; padding: 5px; text-decoration: none;'>Download PDF</a><iframe width='100%' height='100%' src='" + string + "'></iframe>"
        // var x = window.open();
        // x.document.open();
        // x.document.write(iframe);
        // x.document.close();
        var x = window.open(dataString);
    }

    console.log("documents----",documents);
    return (
        <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
            <div className="row">
                <div className="col-xl-4 col-lg-4 col-md-12 col-xs-12">
                    <div className="profile-general">
                        <h2 className="htx3 clearfix">Documents {!editingData && <a href="javascript:void(0)" className="editbtn" onClick={openEditMode}><img src={editSvg} /> Edit</a>}</h2>
                        <p className="ptx clearfix">Your GST and PAN documents are required for the verification of your brand.</p>
                    </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12 brand-general">
                    <div className="row">
                        {!editingData ?
                            <>
                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                    <div className="upload-display mt-10 mb-20">
                                        <div className="upload-displayL">
                                            <span><img src={documents.gst && documents.gst.file && documents.gst.file !== "" && documents.gst.file.length !== 0 ? documents.gst.file[0].key.endsWith(".pdf") ? noImg : documents.gst.file[0].image : noImg} /></span>
                                            {
                                                documents.gst && documents.gst.file && documents.gst.file.length !== 0 
                                                ? 
                                                documents.gst && documents.gst.file && documents.gst.file[0].key.endsWith(".pdf")
                                                ?
                                                <a href="javascript:void(0);" onClick={()=> openPDF(documents.gst.file[0].image) } style={{cursor: "pointer"}}>View</a>
                                                :
                                                <a style={{cursor: "pointer"}} onClick={() => openImageModal("gst")}>View</a>
                                                : 
                                                ""
                                            }
                                        </div>
                                        <ImageModal 
                                            togglePopup={toggleGstImage}
                                            displayImage={documents.gst && documents.gst.file && documents.gst.file.length !== 0 && documents.gst.file[0].image}
                                            onModalClose={() => confirmClose("gst")}
                                        />
                                        <div className="upload-displayR">
                                            {
                                                documents.gst && documents.gst.status
                                                 ? 
                                                <span className={`uploaded-doc ${documents.gst.status && documents.gst.status.toLowerCase() === "required" ? "yellowbg" :  documents.gst.status.toLowerCase() === "pending" ? "orangebg" : documents.gst.status.toLowerCase() === "rejected" ? "redbg" : documents.gst.status.toLowerCase() === "accepted" ? "greenbg" : ""}`} >
                                                    {documents.gst.status && documents.gst.status.toLowerCase() === "required" ? "Document Required" : documents.gst.status.toLowerCase() === "pending" ? "Verification Pending" : documents.gst.status.toLowerCase() === "rejected" ? "Rejected" : documents.gst.status.toLowerCase() === "accepted" ? "Verified" : "Verified"}
                                                </span>
                                                 : 
                                                 ""
                                            }
                                            
                                            <div className="upload-text">
                                                <h4>Document Name</h4>
                                                <p>GST</p>
                                            </div>
                                            <div className="upload-text">
                                                <h4>Document Number</h4>
                                                <p>{documents.gst && documents.gst.gstNo || "-"}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                    <div className="upload-display mt-10 mb-20">
                                        <div className="upload-displayL">
                                            <span><img src={documents.pan && documents.pan.file && documents.pan.file !== "" && documents.pan.file.length !== 0 ? documents.pan.file[0].key.endsWith(".pdf") ? noImg : documents.pan.file[0].image : noImg} /></span>
                                            {
                                                documents.pan && documents.pan.file && documents.pan.file.length !== 0 
                                                ? 
                                                documents.pan && documents.pan.file && documents.pan.file[0].key.endsWith(".pdf")
                                                ?
                                                <a href="javascript:void(0);" onClick={()=> openPDF(documents.pan.file[0].image) } style={{cursor: "pointer"}}>View</a>
                                                :
                                                <a style={{cursor: "pointer"}} onClick={() => openImageModal("pan")}>View</a>
                                                : 
                                                ""
                                            }
                                        </div>
                                        <ImageModal 
                                            togglePopup={togglePanImage}
                                            displayImage={documents.pan && documents.pan.file && documents.pan.file.length !== 0 && documents.pan.file[0].image}
                                            onModalClose={() => confirmClose("pan")}
                                        />
                                        <div className="upload-displayR">
                                            {
                                                documents.pan && documents.pan.status
                                                ? 
                                               <span className={`uploaded-doc ${documents.pan.status && documents.pan.status.toLowerCase() === "required" ? "yellowbg" :  documents.pan.status.toLowerCase() === "pending" ? "orangebg" : documents.pan.status.toLowerCase() === "rejected" ? "redbg" : documents.pan.status.toLowerCase() === "accepted" ? "greenbg" : ""}`} >
                                                   {documents.pan.status && documents.pan.status.toLowerCase() === "required" ? "Document Required" : documents.pan.status.toLowerCase() === "pending" ? "Verification Pending" : documents.pan.status.toLowerCase() === "rejected" ? "Rejected" : documents.pan.status.toLowerCase() === "accepted" ? "Verified" : "Verified"}
                                               </span>
                                                : 
                                                ""
                                            }
                                        {/* <span className={`uploaded-doc ${documents.pan.status ? documents.pan.status.toLowerCase() === "pending" ? "orangebg" : documents.pan.status.toLowerCase() === "rejected" ? "redbg" : documents.pan.status.toLowerCase() === "accepted" ? "greenbg" : "greenbg" : ""}`}>{documents.pan.status ? documents.pan.status.toLowerCase() === "pending" ? "Verification Pending" : documents.pan.status.toLowerCase() === "rejected" ? "Rejected" : documents.pan.status.toLowerCase() === "accepted" ? "Verified" : "Verified" : ""}</span> */}
                                            <div className="upload-text">
                                                <h4>Document Name</h4>
                                                <p>PAN Card</p>
                                            </div>
                                            <div className="upload-text">
                                                <h4>Document Number</h4>
                                                <p>{documents.pan && documents.pan.panNo || "-"}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                    <div className="document-uploaded">
                                        <div className="forminpt">
                                            <label className='colorBlack'>GST Document</label>
                                            <div className="upload-images mt-10">
                                                <span>Drag & Drop the file  from <br/> your computer</span>
                                                <div className='upload-images-Btn'>
                                                    <label>Upload Image</label>
                                                <input
                                                        multiple={true}
                                                        type="file"
                                                        onChange={(event)=> handleUpload(event.target.files, "gst")}
                                                        accept="image/*, application/pdf"
                                                    />
                                                </div>
                                            </div>
                                            {gstErrorMsg && <span className="valError"><img src={errorSVG} />{"Please upload pdf or image file."}</span>}
                                        </div>
                                        <div className="forminpt">
                                            {/* <label>Document Name</label> */}
                                            <div className="upload-images-view-remove mt-10">
                                                <TextField
                                                    formData={formData.gst}
                                                    formKey='gstNo'
                                                    label="Document No"
                                                    onDataChange={onGstNoChange}
                                                    placeholder='Document No.'
                                                    isRequired={false}
                                                    formValidator={formValidator}
                                                    validation={`${gstImage.image.length > 0 ? "required|gst_no" : "gst_no"}`}
                                                    isDisabled={gstImage.image.length > 0 ? false : true}
                                                />
                                                {
                                                    console.log("gstImage===",gstImage)
                                                }
                                                <div className="profile-general-logo-img">
                                                    <img src={gstImage.image.length > 0 ? gstImage.isPdf ? noImg : gstImage.image : noImg} />
                                                </div>
                                                {
                                                    gstImage.image
                                                    ? 
                                                    gstImage.isPdf
                                                    ?
                                                    <a href="javascript:void(0);" onClick={()=> openPDF(gstImage.image) } style={{cursor: "pointer"}}><svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fill-rule="evenodd" clip-rule="evenodd" d="M11.6344 7.04418C11.6344 8.49918 10.4544 9.67835 8.9994 9.67835C7.5444 9.67835 6.36523 8.49918 6.36523 7.04418C6.36523 5.58835 7.5444 4.40918 8.9994 4.40918C10.4544 4.40918 11.6344 5.58835 11.6344 7.04418Z" stroke="#070707" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path fill-rule="evenodd" clip-rule="evenodd" d="M8.99768 13.129C12.171 13.129 15.0735 10.8473 16.7077 7.04398C15.0735 3.24065 12.171 0.958984 8.99768 0.958984H9.00102C5.82768 0.958984 2.92518 3.24065 1.29102 7.04398C2.92518 10.8473 5.82768 13.129 9.00102 13.129H8.99768Z" stroke="#070707" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg></a>
                                                    :
                                                    <a href="javascript:void(0);" onClick={() => openImageModal("gst")}><svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fill-rule="evenodd" clip-rule="evenodd" d="M11.6344 7.04418C11.6344 8.49918 10.4544 9.67835 8.9994 9.67835C7.5444 9.67835 6.36523 8.49918 6.36523 7.04418C6.36523 5.58835 7.5444 4.40918 8.9994 4.40918C10.4544 4.40918 11.6344 5.58835 11.6344 7.04418Z" stroke="#070707" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path fill-rule="evenodd" clip-rule="evenodd" d="M8.99768 13.129C12.171 13.129 15.0735 10.8473 16.7077 7.04398C15.0735 3.24065 12.171 0.958984 8.99768 0.958984H9.00102C5.82768 0.958984 2.92518 3.24065 1.29102 7.04398C2.92518 10.8473 5.82768 13.129 9.00102 13.129H8.99768Z" stroke="#070707" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg></a>
                                                    : 
                                                    ""
                                                }
                                                {
                                                    gstImage.image && <a href="javascript:void(0);" onClick={() => removeImage("gst")}><svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M13.6128 6.79565C13.6128 6.79565 13.1848 12.105 12.9365 14.3415C12.8182 15.4096 12.1584 16.0356 11.0776 16.0553C9.02087 16.0923 6.96177 16.0947 4.90584 16.0513C3.86604 16.03 3.21725 15.3962 3.10137 14.347C2.85147 12.0908 2.42578 6.79565 2.42578 6.79565" stroke="#070707" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M7.83789 7.21484V11.9448" stroke="#070707" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M14.7023 4.25036H1.33398" stroke="#070707" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M12.1268 4.25045C11.508 4.25045 10.9751 3.81293 10.8537 3.20671L10.6621 2.24812C10.5439 1.80587 10.1434 1.5 9.68697 1.5H6.35001C5.89357 1.5 5.4931 1.80587 5.37486 2.24812L5.18329 3.20671C5.06189 3.81293 4.52899 4.25045 3.91016 4.25045" stroke="#070707" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg></a>
                                                }
                                                <ImageModal 
                                                    togglePopup={toggleEditGstImage}
                                                    displayImage={gstImage.image}
                                                    onModalClose={() => confirmClose("gst")}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                    <div className="document-uploaded">
                                        <div className="forminpt">
                                            <label className='colorBlack'>PAN Document</label>
                                            <div className="upload-images mt-10">
                                                <span>Drag & Drop the file  from <br/> your computer</span>
                                                <div className='upload-images-Btn'>
                                                    <label>Upload Image</label>
                                                    <input
                                                    multiple={true}
                                                    type="file"
                                                    onChange={(event)=> handleUpload(event.target.files, "pan")}
                                                    accept="image/*, application/pdf"
                                                />
                                                </div>
                                                
                                            </div>
                                            {panErrorMsg && <span className="valError"><img src={errorSVG} />{"Please upload pdf or image file."}</span>}
                                        </div>
                                        <div className="forminpt">
                                            {/* <label>Document Name</label> */}
                                            <div className="upload-images-view-remove mt-10">
                                                <TextField
                                                    formData={formData.pan}
                                                    formKey='panNo'
                                                    label="Document No"
                                                    onDataChange={onPanNoChange}
                                                    placeholder='Document No.'
                                                    isRequired={false}
                                                    formValidator={formValidator}
                                                    validation={`${panImage.image.length > 0 ? "required|pan_card" : "pan_card"}`}
                                                    isDisabled={panImage.image.length > 0 ? false : true}
                                                />
                                                <div className="profile-general-logo-img">
                                                    <img src={panImage.image.length > 0 ? panImage.isPdf ? noImg : panImage.image : noImg} />
                                                </div>
                                                {
                                                    panImage.image
                                                    ? 
                                                    panImage.isPdf
                                                    ?
                                                    <a href="javascript:void(0);" onClick={()=> openPDF(panImage.image) } style={{cursor: "pointer"}}><svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fill-rule="evenodd" clip-rule="evenodd" d="M11.6344 7.04418C11.6344 8.49918 10.4544 9.67835 8.9994 9.67835C7.5444 9.67835 6.36523 8.49918 6.36523 7.04418C6.36523 5.58835 7.5444 4.40918 8.9994 4.40918C10.4544 4.40918 11.6344 5.58835 11.6344 7.04418Z" stroke="#070707" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path fill-rule="evenodd" clip-rule="evenodd" d="M8.99768 13.129C12.171 13.129 15.0735 10.8473 16.7077 7.04398C15.0735 3.24065 12.171 0.958984 8.99768 0.958984H9.00102C5.82768 0.958984 2.92518 3.24065 1.29102 7.04398C2.92518 10.8473 5.82768 13.129 9.00102 13.129H8.99768Z" stroke="#070707" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg></a>
                                                    :
                                                    <a href="javascript:void(0);" onClick={() => openImageModal("pan")}><svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fill-rule="evenodd" clip-rule="evenodd" d="M11.6344 7.04418C11.6344 8.49918 10.4544 9.67835 8.9994 9.67835C7.5444 9.67835 6.36523 8.49918 6.36523 7.04418C6.36523 5.58835 7.5444 4.40918 8.9994 4.40918C10.4544 4.40918 11.6344 5.58835 11.6344 7.04418Z" stroke="#070707" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path fill-rule="evenodd" clip-rule="evenodd" d="M8.99768 13.129C12.171 13.129 15.0735 10.8473 16.7077 7.04398C15.0735 3.24065 12.171 0.958984 8.99768 0.958984H9.00102C5.82768 0.958984 2.92518 3.24065 1.29102 7.04398C2.92518 10.8473 5.82768 13.129 9.00102 13.129H8.99768Z" stroke="#070707" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg></a>
                                                    : 
                                                    ""
                                                }
                                                {
                                                    panImage.image && <a href="javascript:void(0);" onClick={() => removeImage("pan")}><svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M13.6128 6.79565C13.6128 6.79565 13.1848 12.105 12.9365 14.3415C12.8182 15.4096 12.1584 16.0356 11.0776 16.0553C9.02087 16.0923 6.96177 16.0947 4.90584 16.0513C3.86604 16.03 3.21725 15.3962 3.10137 14.347C2.85147 12.0908 2.42578 6.79565 2.42578 6.79565" stroke="#070707" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M7.83789 7.21484V11.9448" stroke="#070707" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M14.7023 4.25036H1.33398" stroke="#070707" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M12.1268 4.25045C11.508 4.25045 10.9751 3.81293 10.8537 3.20671L10.6621 2.24812C10.5439 1.80587 10.1434 1.5 9.68697 1.5H6.35001C5.89357 1.5 5.4931 1.80587 5.37486 2.24812L5.18329 3.20671C5.06189 3.81293 4.52899 4.25045 3.91016 4.25045" stroke="#070707" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg></a>
                                                }
                                                
                                                <ImageModal 
                                                    togglePopup={toggleEditPanImage}
                                                    displayImage={panImage.image}
                                                    onModalClose={() => confirmClose("pan")}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
            {editingData &&
                <div className="col-xl-10 col-lg-10 col-md-12 col-xs-12 mb-20">
                    <div className="btn-general">
                        <a style={{cursor: "pointer"}} className="btn btn-outline" onClick={() => handleSave(false)}>Cancel</a>
                        <a style={{cursor: "pointer"}} className={`btn btn-orange ${editingDocument && "button--loading btn-disabled"}`} onClick={() => handleSave(true)}>Save Changes</a>
                    </div>
                </div>
            }
        </div>
    )
}

export default EditDocuments
