const LogoutSVG = () => {

    return (
        <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.1771 4.55566C14.1758 5.57532 14.8559 6.87433 15.1313 8.28844C15.4068 9.70255 15.2652 11.1683 14.7245 12.5002C14.1838 13.8322 13.2682 14.9707 12.0936 15.7716C10.919 16.5726 9.53807 17.0001 8.12542 17.0001C6.71277 17.0001 5.33184 16.5726 4.15723 15.7716C2.98262 14.9707 2.06708 13.8322 1.52638 12.5002C0.985671 11.1683 0.84408 9.70255 1.11951 8.28844C1.39493 6.87433 2.075 5.57532 3.07374 4.55566" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M8.125 1V9" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
}

export default LogoutSVG
