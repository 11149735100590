import { useState, useContext, useEffect, useRef } from "react"

import { Link, useHistory } from "react-router-dom"

import search from "../../../assets/images/search.svg"
import arrowUpDown from "../../../assets/images/backendAdmin/arrow-up-down.svg"
import riarrownext from "../../../assets/images/right-arrow-next.svg"
import tabImgInventory from "../../../assets/images/tab-img-inventory.png"
import tableFilter from "../../../assets/images/table-filter.svg"
import arrowUp from "../../../assets/images/arrow-up.svg"
import arrowDown from "../../../assets/images/arrow-down.svg"
import filterSvg from "../../../assets/images/filter-svg.svg"
import filterArrowSvg from "../../../assets/images/filter-arrow-svg.svg"
import errorSVG from "../../../assets/images/error.svg";
import tabSlide1 from "../../../assets/images/tab-Slide1.png"
import TopBar from "../schools/components/topBar"
import ArrowView from "../../../assets/images/arrow-view.svg"
import AppContextObject from "../../../common/context/common"

import OnboardingBackBtn from "../schools/components/onboardingBackBtn"
import InventoryCard from "../schools/updateDetails/inventory/inventoryCard"
import Pagination from "../../../components/addon/pagination"
import CampaignApprovalModal from "../../../components/addon/campaignApprovalModal"
import ThankyouModal from "../../../components/addon/thankyouModal"
import RejectBrandRequestModal from "../../../components/addon/rejectBrandRequestModal"
import SimpleReactValidator from "simple-react-validator";
import DataContext from "../../../service/contextProvider"

import { PublicContextInitiator } from "../../apis/publicContextInitiator"

import { Steps } from "intro.js-react"
import { formatDateToDDMMMYY, isObjectEmpty, titleCase } from "../../../service/helper"

import toast from "react-hot-toast"
import SchoolTable from "./components/SchoolTable"
import CategoryFilterSchool from "./components/CategoryFilterSchool"
import SearchInput from "./components/SearchInput"
import config from "../../../service/config"
import SingleSelect from "../../../components/formFields/singleSelect/singleSelect"
import Loader from "../../../components/addon/loader"
import backBtn from "../../../assets/images/back-arrow.svg";
import BackendTopBar from "./components/backendTopBar"
import BackendBackBtn from "./components/backendBackBtn"

import downloadSvg from "../../../assets/images/backendAdmin/download-svg.svg";

import { downloadFile} from '../../../service/helper';
import { ExportToCsv } from 'export-to-csv';
var backendSchoolListGlobalVar = 1

const SchoolList = () => {
    const parentData = useContext<any>(DataContext)
    const history = useHistory()
    const context = useContext(AppContextObject)
    const publicContextInitiator = new PublicContextInitiator(context)

    const [showModalApproveActive, setshowModalApproveActive] = useState(false)
    const [showThankyouModal, setShowThankyouModal] = useState(false)
    const [thankyouTitle, setThankyouTitle] = useState("")
    const [showConfirmRejectModal, setShowConfirmRejectModal] = useState(false)
    const [thankyouDescription, setThankyouDescription] = useState("")
    const [pageLimit, setPageLimit] = useState(10)
    const [showModalapproveActive, setshowModalapproveActive] = useState(false)
    const [overlay, setOverlay] = useState(false)
    const [countOfStatus, setCountOfStatus] = useState({
        all: 0,
        active: 0,
        pending: 0,
        rejected: 0,
    })
    const [requestedInventoryName, setRequestedInventoryName] = useState("")
    const [allSchoolList, setSchoolList] = useState([]);
    const [totalPageCount, setTotalPageCount] = useState<any>([0]);
    const [totalPage, setTotalPage] = useState([0]);
    const [currentPage, setCurrentpage] = useState(1);
    const [openFilter, setOpenFilter] = useState(false);
    const [clearSearchIcon, setClearSearchIcon] = useState(false);
    const [searchInpuOpen, setSearchInpuOpen] = useState(false);
    const [searchKeys, setSearchKeys] = useState("");
    const [category, setCategory] = useState([]);
    const [inventoryStatus, setInventoryStatus] = useState([]);
    const [schoolStatus, setSchoolStatus] = useState([]);
    const [city, setCity] = useState({ name: '' });
    const [allCities, setAllCities] = useState([]);
    const [location, setLocation] = useState([]);
    const [filterByLanguage, setFilterByLanguage] = useState([])
    const [filterByBoard, setFilterByBoard] = useState([])
    const [filterBySchoolType, setFilterBySchoolType] = useState([])
    const [selectedTab, setSelectedTab] = useState("all")
    const [alphabaticOrder, setAlphabaticOrder] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [activeSchool, setActiveSchool] = useState(0)
    const [activeSchoolLastMonth, setactiveSchoolLastMonth] = useState(0)
    const [topLocation, setTopLocation] = useState("")
    const [topPerformingInventory, setTopPerformingInventory] = useState("")
    const [campaignRejectedSchoolCount, setCampaignRejectedSchoolCount] = useState(0)
    const [locationByCities, setLocationByCities] = useState([])
    const [update, forceUpdate] = useState(false);
    const [sortingData, setSortingData] = useState({ _id: -1 })
    const [totalSchools, setTotalSchools] = useState(0)
    const [formValidator] = useState(useRef(new SimpleReactValidator({
        element: (message) =>

            <span className="valError"><img src={errorSVG} />{message}</span>
    })))
    const [downloadSchoolLoading, setDownloadSchoolLoading] = useState(false);
    useEffect(() => {
        if (update === true) {
            formValidator.current.showMessages()
        } else {
            formValidator.current.hideMessages()
        }
    }, [update])
    useEffect(() => {
        getAllSchoolsList();
    }, [currentPage, searchKeys, category, inventoryStatus, schoolStatus, filterBySchoolType, filterByBoard, filterByLanguage, selectedTab, alphabaticOrder, location, city.name])
    useEffect(() => {
        if (currentPage !== 1) {
            setCurrentpage(1)
        }
    }, [searchKeys, category, inventoryStatus, schoolStatus, filterBySchoolType, filterByBoard, filterByLanguage, selectedTab, location])

    useEffect(() => {
        getSchoolStats();
        getSchoolCities();
    }, [])
    useEffect(() => {
        getSchoolLocation();
    }, [city])

    const getSchoolStats = async () => {
        let resp = await context.apis.public.getSchoolsStatsForBackendAdmin(
            context.validator, {}, {}
        )
        console.log(resp)
        if (resp && resp.done) {
            setActiveSchool(resp.response.activeSchools)
            setactiveSchoolLastMonth(resp.response.activeSchoolsLastMonth)
            setTopLocation(resp.response.topLocations)
            setTopPerformingInventory(resp.response.topPerformingInventory)
            if (resp.response.multipleCampaignsRejected && Array.isArray(resp.response.multipleCampaignsRejected)) {
                setCampaignRejectedSchoolCount(resp.response.multipleCampaignsRejected.length)
            }
            else {
                setCampaignRejectedSchoolCount(0)
            }
        }
    }

    const getSchoolCities = async () => {
        //cities filter api
        let resp = await context.apis.public.getAllCitiesBySchool(
            context.validator, {}, {}
        )
        console.log('SchoolsCities======>', resp)
        if (resp && resp.done) {
            setAllCities(resp.response)
        }
    }
    const getSchoolLocation = async () => {
        //locations filter api
        if (city.name) {
            let resp = await context.apis.public.geLocationsByCity(
                context.validator, { cities: city.name }, {}
            )
            console.log('SchoolsLocations======>', resp)
            if (resp && resp.done) {
                let locationCity = [];
                resp.response.rows.map(locations => locationCity.push(...locations.locations))
                setLocationByCities(locationCity)
            }
        }
    }

    const getAllSchoolsList = async () => {
        setIsLoading(true)
        var localVar = Math.random()
        backendSchoolListGlobalVar = localVar
        let resp = await context.apis.public.getAllSchoolsForBackendUsers(
            context.validator, {
                tabStatus: selectedTab, searchValue: searchKeys === "" ? null : searchKeys, language: filterByLanguage, board: filterByBoard, schoolType: filterBySchoolType,
            category: category, inventoryStatus: inventoryStatus, schoolStatus: [...schoolStatus.map(item => item === "approved")], locations: location, city: [city.name]
        }, { limit: pageLimit, page: currentPage, withCount: true, sort: sortingData }
        )
        console.log('getAllSchoolsForBackendUsers', resp)

        if (resp && resp.done) {
            if(localVar === backendSchoolListGlobalVar){
                setSchoolList(resp.response);
                setTotalSchools(resp.count)
                let data: any = resp
                setCountOfStatus({
                    all: selectedTab === "all" ? data.count : data.allCount.all,
                    active: selectedTab === "active" ? data.count : data.allCount.active,
                    rejected: selectedTab === "inactive" ? data.count : data.allCount.inactive,
                    pending: selectedTab === "pendingApproval" ? data.count : data.allCount.pendingApproval,
                })
                let total = Math.ceil(resp['count'] / pageLimit);
                setTotalPageCount(resp.response.count);
                let arr = []
                for (let i = 0; i < total; i++) {
                    arr.push(i)
                }
                setTotalPage([...arr])
            }
        }
        setIsLoading(false)
    }

    const onPageChange = (page) => {
        setCurrentpage(page)
    }
    const onNextClick = () => {
        setCurrentpage(prev => prev + 1)
    }
    const onPrevClick = () => {
        setCurrentpage(prev => prev - 1)
    }

    const changeActiveTab = (tab) => {
        if (tab === selectedTab) {
            return
        }
        setSelectedTab(tab)
        setSearchKeys("")
        setInventoryStatus([])
        setSchoolStatus([])
        setCurrentpage(1)
    }
    const redirectToSchoolOnboarding = () => {
        history.push("/backend/backend-details/schools/onboarding/your-details")
    }

    const getExportableData1 = async () => {

        console.log("--getExportableData---check here==new==>")
        setDownloadSchoolLoading(true);
        
        let currentPage =1;
        let resp = await context.apis.public.getAllSchoolsForBackendUsers(
            context.validator, {
                tabStatus: selectedTab, searchValue: searchKeys === "" ? null : searchKeys, language: filterByLanguage, board: filterByBoard, schoolType: filterBySchoolType,
            category: category, inventoryStatus: inventoryStatus, schoolStatus: [...schoolStatus.map(item => item === "approved")], locations: location, city: [city.name],
            IsDownload:"true"
        },{  page: currentPage, withCount: true, sort: sortingData}
        )
        //console.log('getAllSchoolsForBackendUsers ###########', resp)
        let data=[]
        let test =await resp.response.forEach(async (schoolData,index) => {
            let schoolDetails={}
            schoolDetails['SL NO'] = ++index;
            schoolDetails['SCHOOL NAME']=schoolData.schoolName;

            // new code starts 
            schoolDetails['Average Fee']=schoolData.averageFee;
            // schoolDetails['Principal Contact']=schoolData.Principal;
            
            // schoolDetails['INVENTORY STATUS']=schoolData.collectiveInventoryStatus;
            // schoolDetails['INVENTORY STATUS']=schoolData.collectiveInventoryStatus;
            // schoolDetails['INVENTORY STATUS']=schoolData.collectiveInventoryStatus;
            // new code ends 

            schoolDetails['BOARD']=schoolData.board.toUpperCase();
            schoolDetails['CATEGORY']=schoolData.category;
            schoolDetails['STATE']=schoolData.state;
            schoolDetails['CITY']=schoolData.city.toUpperCase();
            schoolDetails['ADDRESS']=schoolData.address;
            schoolDetails['Principal Name']=schoolData['Principal Name'];
            schoolDetails['Principal Contact']=schoolData['Principal Contact'];
            schoolDetails['Principal Email']=schoolData['Principal Email'];
            schoolDetails['STUDENTS']=schoolData.totalStudents;
            schoolDetails['INVENTORIES']=schoolData.inventories;
            schoolDetails['EVENTS & OCCASIONS']=schoolData.activities;
            schoolDetails['CAMPAIGNS RUN']=schoolData.campaigns;
            schoolDetails['SCHOOL STATUS']=schoolData.initialApproval ? "Approved" : "New Added";
            schoolDetails['INVENTORY STATUS']=schoolData.collectiveInventoryStatus;

           

            //schoolDetails['N/Pg'] = schoolData["N/Pg_class"] 
            schoolDetails['N/Pg Boys'] = schoolData["N/Pg_boys"] 
            schoolDetails['N/Pg Girls'] = schoolData["N/Pg_girls"] 
            schoolDetails['N/Pg total'] = schoolData['N/Pg_total'] 


            //schoolDetails['Jr.Kg'] = schoolData["Jr.Kg_class"] 
            schoolDetails['Jr.Kg Boys'] = schoolData["Jr.Kg_boys"] 
            schoolDetails['Jr.Kg Girls'] = schoolData["Jr.Kg_girls"] 
            schoolDetails['Jr.Kg total'] = schoolData['Jr.Kg_total'] 

           // schoolDetails['Sr.Kg'] = schoolData["Sr.Kg_class"]
            schoolDetails['Sr.Kg Boys'] = schoolData["Sr.Kg_boys"] 
            schoolDetails['Sr.Kg Girls'] =schoolData["Sr.Kg_girls"]
            schoolDetails['Sr.Kg total'] = schoolData['Sr.Kg_total']

            //schoolDetails['1th Class'] =schoolData["1_class"] 
            schoolDetails['1st Class Boys'] =schoolData["1_boys"] 
            schoolDetails['1st Class Girls'] =schoolData["1_girls"] 
            schoolDetails['1st total'] = schoolData['1_total'] 

            //schoolDetails['2th Class'] =schoolData["2_class"] 
            schoolDetails['2nd Class Boys'] =schoolData["2_boys"] 
            schoolDetails['2nd Class Girls'] =schoolData["2_girls"] 
            schoolDetails['2nd total'] = schoolData['2_total']


            //schoolDetails['3th Class'] =schoolData["3_class"] 
            schoolDetails['3nd Class Boys'] =schoolData["3_boys"] 
            schoolDetails['3nd Class Girls'] =schoolData["3_girls"] 
            schoolDetails['3nd total'] = schoolData['3_total']


            //schoolDetails['4th Class'] =schoolData["4_class"] 
            schoolDetails['4th Class Boys'] =schoolData["4_boys"] 
            schoolDetails['4th Class Girls'] =schoolData["4_girls"]
            schoolDetails['4th total'] = schoolData['4_total']

            //schoolDetails['5th Class'] =schoolData["5_class"] 
            schoolDetails['5th Class Boys'] =schoolData["5_boys"] 
            schoolDetails['5th Class Girls'] =schoolData["5_girls"] 
            schoolDetails['5th total'] = schoolData['5_total']

            //schoolDetails['6th Class'] =schoolData["6_class"]  
            schoolDetails['6th Class Boys'] =schoolData["6_boys"] 
            schoolDetails['6th Class Girls'] =schoolData["6_girls"] 
            schoolDetails['6th total'] = schoolData['6_total'] 

            //schoolDetails['7th Class'] =schoolData["7_class"] 
            schoolDetails['7th Class Boys'] =schoolData["7_boys"] 
            schoolDetails['7th Class Girls'] =schoolData["7_girls"] 
            schoolDetails['7th total'] = schoolData['7_total']

            //schoolDetails['8th Class'] =schoolData["8_class"] 
            schoolDetails['8th Class Boys'] =schoolData["8_boys"] 
            schoolDetails['8th Class Girls'] =schoolData["8_girls"] 
            schoolDetails['8th total'] = schoolData['8_total']

            //schoolDetails['9th Class'] =schoolData["9_class"] 
            schoolDetails['9th Class Boys'] =schoolData["9_boys"] 
            schoolDetails['9th Class Girls'] =schoolData["9_girls"] 
            schoolDetails['9th total'] = schoolData['9_total'] 

            //schoolDetails['10th Class'] =schoolData["10_class"] 
            schoolDetails['10th Class Boys'] =schoolData["10_boys"] 
            schoolDetails['10th Class Girls'] =schoolData["10_girls"] 
            schoolDetails['10th total'] = schoolData['10_total']

            //schoolDetails['11th Class'] =schoolData["11_class"] 
            schoolDetails['11th Class Boys'] =schoolData["11_boys"] 
            schoolDetails['11th Class Girls'] =schoolData["11_girls"] 
            schoolDetails['11th total'] = schoolData['11_total']

            //schoolDetails['12th Class'] =schoolData["12_class"] 
            schoolDetails['12th Class Boys'] =schoolData["12_boys"] 
            schoolDetails['12th Class Girls'] =schoolData["12_girls"] 
            schoolDetails['12th total'] = schoolData['12_total']
            schoolDetails['List of inventories'] = schoolData.inventory 
                
            data.push(schoolDetails);
        });

        const options = { 
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showTitle: false,
            filename:'SchoolsList',
            useTextFile: false,
            useKeysAsHeaders: true,
            //headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
        };
        setDownloadSchoolLoading(false);
        const csvExporter = new ExportToCsv(options);
        csvExporter.generateCsv(data);
    }
    return (
        <>
            <div className={`ovelap ${overlay === true ? "active" : ""}`}></div>
            <div className="col-xl-10 col-lg-12 col-md-12 col-xs-12">
                <div className="rightPart noBack">
                    <div className="row">
                        <BackendTopBar redirectPage={false} />
                        {/* <div className="col-xl-6 col-lg-6 col-md-6 col-xs-6"> */}
                        {/* <a className="backbtn dblock" href="/backend/backend-details/school-list"> */}
                        {/* <Link to="/backend/backend-details/school-list" className="backbtn dblock">
                                    <img src={backBtn} />
                                </Link> */}
                        {/* </a> */}
                        {/* </div> */}


                        <div className={`col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-20`}>
                            <div className="inventory-over">
                                <ul className="inventory-overTabs tabs4">
                                    <li>
                                        <a href="javascript:void" className={(activeSchool - activeSchoolLastMonth) > 0 ? "greenUpPercentage" : (activeSchool - activeSchoolLastMonth) < 0 ? "redDownPercentage" : "removeArrow"}>
                                            <p>Active Schools (This Month)</p>
                                            <h3>{activeSchool} {(activeSchool - activeSchoolLastMonth) !== 0 && <span><b>{(activeSchool - activeSchoolLastMonth) >= 0 ? activeSchool - activeSchoolLastMonth : activeSchoolLastMonth - activeSchool} <img className={(activeSchool - activeSchoolLastMonth) >= 0 ? "arrowUp" : "arrowDown"} src={(activeSchool - activeSchoolLastMonth) >= 0 ? arrowUp : arrowDown} /></b> than last month</span>}</h3>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="javascript:void">
                                            <p>Top Locations (This Month)</p>
                                            <h3>{topLocation === "n/a" ? "N/A" : titleCase(topLocation)}</h3>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="javascript:void">
                                            <p>Top Performing Inventory</p>
                                            <h3>{topPerformingInventory === "n/a" ? "N/A" : topPerformingInventory}</h3>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="javascript:void">
                                            <p>Multiple Campaigns Rejected</p>
                                            <h3>{campaignRejectedSchoolCount} Schools <Link to="/backend/backend-details/campaign-rejected" className="viewwLink fR">View List <img className="arrowView" src={ArrowView} /></Link></h3>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-50 mb-10">
                            <h2 className="htx2 clearfix">Full List of Schools</h2>
                        </div>
                        {/* <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-50 mb-10">
                            <input
                                type="file"
                                accept="image/*"
                                capture="environment"
                            />
                        </div> */}
                        <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                            <div className="inventory-tableTabs">
                                <ul className={`tabsinventory ${searchInpuOpen ? "tabWidth" : ""}`}>
                                    <li onClick={() => changeActiveTab("all")}><a href="javascript:void(0);" className={selectedTab === "all" ? "active" : ""}>{`All (${countOfStatus.all})`}</a></li>
                                    <li onClick={() => changeActiveTab("active")}><a href="javascript:void(0);" className={selectedTab === "active" ? "active" : ""}>{`Active (${countOfStatus.active})`}</a></li>
                                    <li onClick={() => changeActiveTab("inactive")}><a href="javascript:void(0);" className={selectedTab === "inactive" ? "active" : ""}>{`In-active (${countOfStatus.rejected})`}</a></li>
                                    <li onClick={() => changeActiveTab("pendingApproval")}><a href="javascript:void(0);" className={selectedTab === "pendingApproval" ? "active" : ""}>{`pending Approvals (${countOfStatus.pending})`}</a></li>
                                </ul>
                                <div className="inventorySearch schoolFilter">
                                    <div className="filterbox">
                                        <a href="javascript:void(0);" className="filterDrop" onClick={() => setOpenFilter(!openFilter)}>
                                            <img src={filterSvg} />
                                            <h3>Filter</h3>
                                            <img src={filterArrowSvg} />
                                        </a>

                                        {openFilter &&
                                            <CategoryFilterSchool
                                                setOpenFilter={setOpenFilter}
                                                filterByLanguage={filterByLanguage}
                                                setFilterByLanguage={setFilterByLanguage}
                                                filterByBoard={filterByBoard}
                                                setFilterByBoard={setFilterByBoard}
                                                filterBySchoolType={filterBySchoolType}
                                                setFilterBySchoolType={setFilterBySchoolType}
                                            />
                                        }
                                    </div>
                                    <SingleSelect
                                        formData={city}
                                        formKey='name'
                                        onDataChange={(data) => {if(data===null){setCity({ name: "" })}else{ setCity({ name: data.value })}; setLocation([]);}}
                                        placeholder='City'
                                        isRequired={false}
                                        options={allCities && allCities.map((item) => { return { value: item, label: titleCase(item) } })}
                                        isClearable={true}
                                    />
                                    {/* <a href="javascript:void(0)" className="btn">
                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"> <circle cx="8.58478" cy="8.58478" r="7.58478" stroke="#070707" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /> <path d="M13.8599 14.254L16.8335 17.22" stroke="#070707" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /> </svg>
                                    </a> */}
                                         
                                    <SearchInput
                                        searchInpuOpen={searchInpuOpen} //true/false
                                        clearSearchIcon={clearSearchIcon} //true/false
                                        setSearchInpuOpen={setSearchInpuOpen} //function=>true/false
                                        setSearchKeys={setSearchKeys} //function=>string
                                        searchKeys={searchKeys} //string
                                        setClearSearchIcon={setClearSearchIcon}//function=>true/false
                                        palaceholder='School name'
                                        setCurrentpage={setCurrentpage}
                                    />
                                    {(context.user.role === "backendAdmin" || context.user.role === "backendOperations" || context.user.role === "backendTech") && <a href='javascript:void(0)' className={`downloadSvg ${downloadSchoolLoading ? 'btn-disabled btn-opacity' : ''}`} style={{marginLeft: '10px'}} onClick={getExportableData1} ><img src={downloadSvg} /></a>}
                                    {(context.user.role === "backendAdmin" || context.user.role === "backendTech" || context.user.role === "backendOperations") && <a href="javascript:void(0);" className="btn btn-orange spaceL" onClick={redirectToSchoolOnboarding}>+ Add School</a>}
                                </div>
                                <SchoolTable
                                    allSchoolList={allSchoolList}
                                    currentPage={currentPage}
                                    totalPage={totalPage}
                                    onPageChange={onPageChange}
                                    onNextClick={onNextClick}
                                    onPrevClick={onPrevClick}
                                    category={category}
                                    setCategory={setCategory}
                                    setInventoryStatus={setInventoryStatus}
                                    setSchoolStatus={setSchoolStatus}
                                    schoolStatus={schoolStatus}
                                    alphabaticOrder={alphabaticOrder}
                                    setAlphabaticOrder={setAlphabaticOrder}
                                    sortingData={sortingData}
                                    setSortingData={setSortingData}
                                    isLoading={isLoading}
                                    inventoryStatus={inventoryStatus}
                                    city={city}
                                    setLocation={setLocation}
                                    location={location}
                                    locationByCities={locationByCities}
                                    count={totalSchools}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="approveActive" className={`popup popup-center ${showModalapproveActive === false ? "hide" : ""}`}>
                <div className="popup-header">
                    <h3 className="htx2 pophead">Request Sent Successfully!</h3>
                    <span className="close toggle" data-target="myPopup">close</span> </div>
                <div className="popup-body">
                    <div className="viewdetailsHead">
                        <div className="approveActive">
                            <p>A request to add {requestedInventoryName} has been sent to your school's admin. </p>
                            <div className="brand-btn">
                                <a href="javascript:void(0)" className="btn btn-orange">DONE</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <Steps
                enabled={onBoarding.stepsEnabled}
                steps={onBoarding.steps}
                initialStep={onBoarding.initialStep}
                onComplete={hanldeOnboardingComplete}
                onExit={exitOnBoarding}
                options={onBoarding.options}
            /> */}

            <CampaignApprovalModal
                showModalApproveActive={showModalApproveActive}
            // closeWithoutSaving={closeApprovalModal}
            // viewDetails={viewBrandDetails}
            // approveRequest={approveRequest}
            />

            <ThankyouModal
                showThankyouModal={showThankyouModal}
                // closeModal={closeThankyouModal}
                modalTitle={thankyouTitle}
                description={thankyouDescription}
            />

            <RejectBrandRequestModal
                showConfirmRejectModal={showConfirmRejectModal}
            // closeModal={closeConfirmRejectModal}
            // onRejectRequest={onRejectRequest}
            />

        </>
    )
}


export default SchoolList
