import { useEffect, useState } from "react"

import { objectDeepClone } from "../../service/helper"


interface Props {
    totalPage?: any
    currentPage?: number
    onNextClick?: any
    onPrevClick?: any
    onPageChange?: any
}

const BrandRequestPagination = (props: Props) => {

    const {
        totalPage,
        currentPage,
        onNextClick,
        onPrevClick,
        onPageChange
    } = props
 
    const [showDots, setShowDots] = useState(false)
    const [pages, setPages] = useState([])
    const [minPageLength] = useState(6)

    useEffect(() => {
        renderPagination()
    }, [])

    const renderPagination = () => {
        let pages = []
        if (totalPage.length <= minPageLength) {
            setShowDots(false)
            totalPage.forEach(page => {
                pages.push(page)
            })
            setPages(pages)
        } else {
            if ((pages.length > 0 && currentPage === pages[0]) || pages.length === 0) {
                const pageList = objectDeepClone(totalPage.slice((currentPage - 1), currentPage + 3))
                if (currentPage < totalPage.length - 4) {
                    setShowDots(true)
                } else {
                    setShowDots(false)
                }
                setPages(pageList)
            }
        }
    }

    const onPageNoClick = (pageNo, index) => {
        if (totalPage.length > minPageLength) {
            renderPageList(pageNo, index)
        }
        onPageChange(pageNo)
    }

    const renderPageList = (pageNo, index) => {
        if (index === pages.length - 1 && pageNo !== totalPage[totalPage.length - 1]) {
            const list = objectDeepClone(totalPage.slice(pageNo, (pageNo + 4))) // we want next set of 4 pages.
            setPages(list)
            if (list[list.length - 1] === totalPage[totalPage.length - 1]) {
                setShowDots(false)
            } else {
                setShowDots(true)
            }
        }
    } 
    const onPrevBtnClick = (pageNo) => {
        if (pageNo > 0) {
            const foundIndex = pages.findIndex(page => page === pageNo)
            if (foundIndex > 0) {
                renderPageList(pageNo, foundIndex)
            } else {
                const list = objectDeepClone(totalPage.slice(pageNo - 1, (pageNo + 3)))
                setPages(list)
                if (list[list.length - 1] === totalPage[totalPage.length - 1]) {
                    setShowDots(false)
                } else {
                    setShowDots(true)
                }
            }
            onPrevClick()
        }
    }

    const onNextBtnClick = (pageNo) => {
        if (pageNo < totalPage.length) {
            const foundIndex = pages.findIndex(page => page === pageNo)
            if (foundIndex === -1) {
                const list = objectDeepClone(totalPage.slice(pageNo, (pageNo + 4)))
                setPages(list)
                if (list[list.length - 1] === totalPage[totalPage.length - 1]) {
                    setShowDots(false)
                } else {
                    setShowDots(true)
                }
            }
            onNextClick()
        }
    }

    return (
        <>
            <div className="brand-pagi">
                <a href="javascript:void(0);" className="brand-pagi-arrow" onClick={() => onPrevBtnClick(currentPage - 1)}>
                    <svg
                        width="7"
                        height="12"
                        viewBox="0 0 7 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        {" "}
                        <path
                            d="M6 11L1 6L6 1"
                            stroke="#8A8A8A"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />{" "}
                    </svg>
                </a>
                <div className="brand-pagi1">
                    <ul>
                        {
                            pages.map((page, index) => {
                                return (
                                    <li key={index}>
                                        <a onClick={() => onPageNoClick(page, index)} href="javascript:void(0)" className={`${currentPage === page + 1 ? "active" : ""}`}>{page + 1}</a>
                                    </li>
                                )
                            })
                        }
                        {showDots === true &&<li>. . . . . .</li>}
                    </ul>
                </div>
                <a href="javascript:void(0)" className="brand-pagi-arrow" onClick={() => onNextBtnClick(currentPage)}>
                    <svg
                        width="7"
                        height="12"
                        viewBox="0 0 7 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        {" "}
                        <path
                            d="M1 1L6 6L1 11"
                            stroke="#8A8A8A"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />{" "}
                    </svg>
                </a>
            </div>
        </>
    )
}

export default BrandRequestPagination
