import { useState, useEffect, useContext, useRef } from "react"

import { useHistory } from "react-router-dom"

import toast from "react-hot-toast"

import { validation } from "../../../../service/validators"

import TextFieldWithIcon from "../../../../components/formFields/textfieldWithIcon"
import PasswordField from "../../../../components/formFields/passwordField"

import loginBgLeft from "../../../../assets/images/backendAdmin/login-bg.svg"
import envelope from "../../../../assets/images/envolpe.svg"
import logo from "../../../../assets/images/logo.png"
import AppContextObject from "../../../../common/context/common"
import SimpleReactValidator from "simple-react-validator"
import errorSVG from "../../../../assets/images/error.svg"

interface Props {
    methods
}

const Login = ({
    methods
}: Props) => {

    const history = useHistory()
    const context = useContext(AppContextObject)

    let [formErrors, setFormErrors] = useState<any>({})
    let [loginError, setLoginError] = useState(null)
    const [loading, setLoading] = useState(false)
    const [locDetails, setLocDetails] = useState({
        ip: "",
        city: "",
        country: ""
    })
    const [data, setData] = useState({
        username: "",
        password: ""
    })
    const [formValidator] = useState(useRef(new SimpleReactValidator({
        element: (message, className) =>
            <span className="valError">
                <img src={errorSVG} />{message}
                </span>
        })))
        const [update, forceUpdate] = useState(false)
    // const [ip, setIP] = useState("192.168.56.1")

    useEffect(() => {
        // fetchUserIp()
        sessionStorage.removeItem("contactDetails")
    }, [])

    useEffect(() => {
        if(history.location.state){
            const tempData: any = history.location.state
            console.log(" Userdata htempData",tempData)
            setData({
                ...data,
                username : tempData.username
            }
            )
        }
    }, [])

    useEffect(() => {
        if(context && context.user){
            if(context.user.role === "backendFOS" || context.user.role === "backendSales" || context.user.role === "backendOperations" || context.user.role === "backendFinance" || context.user.role === "backendTech"){
                history.push("/backend/backend-details/dashboard")
            }
            if(context.user.role === "schoolPrincipal" || context.user.role === "schoolAdmin"){
                history.push("/schools/school-details/dashboard")
            }
            if(context.user.role === "brandCMO" || context.user.role === "brandManager"){
                history.push("/brands/brand-details/dashboard")
            }
        }
    }, []);
    
    const fetchUserIp = () =>{
        setLoading(true)
        try{
            fetch('https://geolocation-db.com/json/')
            .then(response => response.json())
            .then(async data =>{
                console.log("data>>>>>>>",data);
                setLocDetails({
                    ip: data.IPv4 || "",
                    city: data.city || "",
                    country: data.country_name || ""
                })
                setLoading(false)
            }).catch(err=>{
                setLoading(false)
                setLocDetails({
                    ip: "127.0.0.1",
                    city: "",
                    country: ""
                })
                console.log(err);
                // document.location.reload()
            })
        }catch(e){
            console.log(e);
        }
        
    }

    const onFormDataChange = (event) => {
        setData({
            ...data,
            [event.target.id] : event.target.value
        })
        if(event.target.id === "username"){
            setData({
                ...data,
                username : event.target.value.toLowerCase()
            }) 
        }
        if(event.target.id == "password") {
            setData({
                ...data,
                password : event.target.value.trim()
            }) 
            if(event.target.value.length > 0) {
                setFormErrors({ newPassword: " " })
                setLoginError("")
            }

        }
    }

    let error = ""
    formValidator.current.rules['user_name'] = {
        message: error === "required" ? 'The user name field is required.' : "",//  : usernameError === undefined ? 'The user name field is required.' :'',
        rule: (val) => {
            let returnValue = true
            if (!val || val.trim() === "") {
                error = "required"
                returnValue = false
            }
            return returnValue
        },
    }


    const onSubmit = async () => {
        const errors = validation(data)
        setFormErrors(errors)
        const result = formValidator.current.allValid()
        if (result === false || Object.keys(errors).length > 0) {
            setLoginError("")
            formValidator.current.showMessages()
            forceUpdate(true)
            return
        } else {
            formValidator.current.hideMessages()
            forceUpdate(!update)
        }

        if (Object.keys(errors).length > 0) {
            return
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json'},
            body: JSON.stringify({username: data.username, password: data.password, ip: locDetails.ip, city: locDetails.city, country: locDetails.country})
        };

        fetch("/api/login", requestOptions)
        .then(response => response.json())
        .then(async data => {
            methods.reRender()
            console.log("data login",data, data.result);
            if(data.result && data.result.done){
                sessionStorage.setItem('currentUser', data.result._id);
                sessionStorage.setItem('ip', data.result.ip || null)
                sessionStorage.setItem('locationActivityId',data.result.locationId)
                toast.success("Logged in successfully")
                if(data.result.isVerified){
                    // history.push("/brands/brand-details/dashboard")
                    // window.location.reload()
                    window.location.href = "/brands/brand-details/dashboard"
                }
                else{
                    let userContactDetail = {
                        username: data.username,
                        phone: data.phone,
                        email: data.email
                    }
                    sessionStorage.setItem('userContactDetail', JSON.stringify(data.result))
                    // history.push("/brands/verify-user")
                    window.location.href = "/user-verification"
                }
            }else{
                setLoginError("Username or Password is incorrect.")
            }
        }).catch(err=>{
            setLoginError("Username or Password is incorrect.")
        })
    }

    const onSignUpClick = () => {
        history.push("/brands/sign-up")
    }

    return (
        <>
            <div className="ovelap"></div>
            <div className="main-wapper">
                <div className="loginbox">
                <div className="login-wave backendlogin"> <img src={loginBgLeft} alt="login-wave" /> </div>
                    <div className="login-form">
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb10">
                                <img src={logo} alt="logo" />
                                <h2 className="htx1">Login to WISR</h2>
                                <p className="ptx mb-20">Enter your log in details to continue your journey!</p>
                            </div>
                        </div>
                        <form data-form-validation="login-form" id="login-form-parent">
                            <div className="row">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                    <TextFieldWithIcon
                                        label="User Name"
                                        placeholder="abhishek"
                                        isRequired={true}
                                        type="text"
                                        icon={envelope}
                                        formErrors={formValidator}
                                        formData={data}
                                        formKey="username"
                                        validation="required|user_name"
                                        onFormDataChange={onFormDataChange}
                                    />
                                </div>
                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                    <PasswordField
                                        label="Password"
                                        isRequired={true}
                                        placeholder="********"
                                        formData={data}
                                        formErrors={formErrors}
                                        formKey="password"
                                        onFormDataChange={onFormDataChange}
                                        showForgotPasswordLink={true}
                                        forgotPasswordLink="/brands/forgot-password"
                                        loginError={loginError}
                                    />
                                </div>
                                <div className='row col-xl-12 col-lg-12 col-md-12 col-xs-12 end-md'>
                                    <div className='forminpt'>
                                        <div className='checkbox onboardcheck'>
                                            <input
                                                type='checkbox'
                                                id='html-term'
                                            />
                                            <label style={{display: "inline"}} htmlFor='html-term'></label> <b>Keep me signed in</b>
                                        </div>
                                    </div>
                                </div>                                
                                <div className="row col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                    <div className="forminpt">
                                        <input onClick={onSubmit} value="Login" className="btn btn-orange datasubmit" type="button" />
                                    </div>
                                </div>
                                <div className="row col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                    <p className="centerText">New to WISR? <a style={{cursor: "pointer", color:"#fe6e00"}} onClick={onSignUpClick}>SIGN UP</a></p>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Login
