import React, { useState, useEffect, useRef, useContext} from 'react'
import uploadDocument from "../../../../assets/images/img-upload.jpg"
import errorSVG from "../../../../assets/images/error.svg"
import deleteImg from "../../../../assets/images/delete.svg"

import SimpleReactValidator from "simple-react-validator"
import ImageModal from '../../../../components/addon/imageModal/imageModal'
import TextField from '../../../../components/formFields/textField/textField'
import Compress from "compress.js"

import toast from "react-hot-toast"
import { PublicContextInitiator } from '../../../apis/publicContextInitiator'
import AppContextObject from '../../../../common/context/common'
import TextArea from '../../../../components/formFields/textArea'
import editSvg from "../../../../assets/images/edit-btn.svg"

const EditDiscription = (props) => {
    const {
        description,
        setDescription,
        setOverlay,
        refetch,
        setRefetch,
        profileData,
        isBackendUser,
        backendCompanyId,
        backendUserRole
    } = props
    const context = useContext(AppContextObject)
    const [formValidator] = useState(useRef(new SimpleReactValidator({
        element: (message, className) =>
            <p className="valError"><img src={errorSVG} />{message}</p>
    })))
    const [editingData, setEditingData] = useState(false)
    const [formData, setFormData] = useState({description: description})
    const [loading, setLoading] = useState(false)
    const [update, forceUpdate] = useState(false)
    const [editingDescription, setEditingDescription] = useState(false)
    const handleSave = async(confirm) => {
        if(confirm){
            const result = formValidator.current.allValid()

            if (result === false) {
                formValidator.current.showMessages()
                forceUpdate(true)
                return
            } else {
                formValidator.current.hideMessages()
                forceUpdate(!update)
            }
            let apiData = formData
            console.log(apiData)
            setEditingDescription(true)
            let updateResp = await context.apis[context.user.role].updateBrand(
                context.validator, {_id: ( isBackendUser ? backendCompanyId  : (context.user.parentBrand || context.user.brand)), sectionName: 'description details'}, apiData
            )

            if(updateResp && updateResp.done){
                toast.success("Brand edited successfully")
                setEditingDescription(false)
                setRefetch(!refetch)
                setEditingData(false)
                setFormData({description: ""})
                setDescription({})
            }
        }
        else{
            setEditingDescription(false)
            setEditingData(false)
            setFormData({description})
        }
    }
    const onFormDataChange = (event) => {
        setFormData({
            ...formData,
            description: event.target.value
        })
    }
    return (
        <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 pl pr">
            <div className="row">
                <div className="col-xl-4 col-lg-4 col-md-12 col-xs-12">
                    <div className="profile-general">
                        {isBackendUser ? 
                            <h2 className="htx3 clearfix">Description {!editingData && (backendUserRole === "backendAdmin" || backendUserRole === "backendSales" || backendUserRole === "backendTech") && <a href="javascript:void(0)" className="editbtn" onClick={() => setEditingData(true)}><img src={editSvg} /> Edit</a>}</h2>
                            :
                            <h2 className="htx3 clearfix">Description {!editingData && <a href="javascript:void(0)" className="editbtn" onClick={() => setEditingData(true)}><img src={editSvg} /> Edit</a>}</h2>
                        }
                        <p className="ptx clearfix">Write a few sentences about the brand.</p>
                    </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12 brand-general">
                    {!editingData ?
                        <>
                            <div className="inventory-overHead mt-0"><p>Description</p>
                        
                                <h3>{description}</h3>
                            </div>
                        </>
                        :
                        <TextArea
                            formData={formData}
                            formKey='description'
                            onDataChange={onFormDataChange}
                            label='Description'
                            placeholder='Write a few sentences about your company'
                            isRequired={false}
                            formValidator={formValidator}
                            validation='required'
                            maxLength={500}
                        />
                    }
                    
                    <div className="clearfix mt-30"></div>
                </div>
            </div>
            {editingData &&
                <div className="col-xl-10 col-lg-10 col-md-12 col-xs-12 mb-20">
                    <div className="btn-general">
                        <a style={{cursor: "pointer"}} className="btn btn-outline" onClick={() => handleSave(false)}>Cancel</a>
                        <a style={{cursor: "pointer"}} className={`btn btn-orange ${editingDescription && "button--loading btn-disabled"}`} onClick={() => handleSave(true)}>Save Changes</a>
                    </div>
                </div>
            }
        </div>
    )
}

export default EditDiscription
