import React, { useEffect, useState, useContext, useRef } from 'react'
import AppContextObject from '../../../../common/context/common';
import TextField from '../../../../components/formFields/textField/textField';
import SimpleReactValidator from "simple-react-validator"
import errorSVG from "../../../../assets/images/error.svg"
import moment from 'moment';
import { compareObjs, objectDeepClone, validateFullName, hasOnlyLettersAndSpaces, titleCase, hasOnlyLettersNumberAndSpaces } from '../../../../service/helper';
import SingleSelect from '../../../../components/formFields/singleSelect/singleSelect';
import config from "../../../../service/config"
import TextFieldWithPrefix from '../../../../components/formFields/textFieldWithPrefix/textFieldWithPrefix';
import ConfirmDialog from '../../../../components/addon/confirmDialog/confirmDialog';
import CreatableSelect from '../../../../components/formFields/creatableSelect/creatableSelect';
import editSvg from "../../../../assets/images/edit-btn.svg"
import EditUserGeneral from './editUserGeneral';
import EditUserContact from './editUserContact';

function ProfileUsers(props) {
    const {
        userData
    } = props;
    
    const context = useContext(AppContextObject)
    const [formValidator] = useState(useRef(new SimpleReactValidator({
        element: (message) =>
        <span className="valError">
        <img src={errorSVG} />{
            message === "The phone field is required."
                ? "The mobile no field is required."
                :
                message === "The phone must be a valid phone number."
                ?
                "The mobile no must be a valid mobile no."
                :
                message === "The role field is required."
                ?
                "The designation field is required."
                :
                message
        }
    </span>
        })))
    const [formData, setFormData] = useState<any>([{
        fullName: "",
        email: "",
        role: "",
        phone: "",
        country: "",
        city: "",
        state: "",
        pincode: "",
        createdOn: ""
    }])
    const [formOldData, setFormOldData] = useState<any>([{
        fullName: "",
        email: "",
        role: "",
        phone: "",
        country: "",
        city: "",
        state: "",
        pincode: "",
        createdOn: "",
        _id: "",
        designation: ""
    }])
    const [selectedUserIndex, setSelectedUserIndex] = useState(0)
    const [isGeneralEdit, setIsGeneralEdit] = useState(false)
    const [isContactEdit, setIsContactEdit] = useState(false)
    const [allRoles, setAllRoles] = useState<any>()
    const [update, forceUpdate] = useState(false)
    const [refetch, setRefetch] = useState(false)
    const [toggleLogoutDialog, setToggleLogoutDialog] = useState(false)
    const [overlay, setOverlay] = useState(false)
    const [newUserData, setNewUserData] = useState<any>({})
    const [isNewAddUser, setIsNewAddUser] = useState(false)
    const [isPincodeValid, setIsPincodeValid] = useState(true)
    const [haveCMO, setHaveCMO] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [componentLoader, setComponentLoader] = useState(false)
    const [isCMOVerfied, setIsCMOVerfied] = useState(true)
    const [subCategories, setSubCategories] = useState([])
    const [brandNameOptions, setBrandNameOptions] = useState([])
    const [uniqueSubCategories, setUniqueSubCategories] = useState([])
    const [hasCompanyNameAlready, setHasCompanyNameAlready] = useState(false)

    useEffect(() => {
        getBrandUsersDetails()
    }, [refetch])


    useEffect(() => {
        fetchCityState()  
    }, [formData[selectedUserIndex] && formData[selectedUserIndex].pincode, newUserData.pincode])

    useEffect(() => {
        if(update === true){
            formValidator.current.showMessages()
        }else{
            formValidator.current.hideMessages()
        }
    }, [update])

    const fetchCityState = async () => {
        if((formData[selectedUserIndex] && formData[selectedUserIndex].pincode && formData[selectedUserIndex].pincode.length == 6) || newUserData && newUserData.pincode && newUserData.pincode.length == 6) {
            let resp
            if(isNewAddUser){
                resp = await context.apis.public.getPincodeDetails(
                    context.validator, {"pincode": parseInt(newUserData && newUserData.pincode)}, {}
                )
            }else{
                resp = await context.apis.public.getPincodeDetails(
                    context.validator, {"pincode": parseInt(formData[selectedUserIndex] && formData[selectedUserIndex].pincode)}, {}
                )
            }
            if(resp && resp.done){
                onStateCityChange(resp.response.stateName, resp.response.district)
                setIsPincodeValid(true)
            }else{
                // if(formData[selectedUserIndex] && formData[selectedUserIndex].pincode && formData[selectedUserIndex].pincode.length > 5)
                // {
                    setIsPincodeValid(false)
                // }
            }
        } else{
            setIsPincodeValid(true)
            onStateCityChange("", "")
        }
    }

    const onStateCityChange = (dataState, dataCity) => {
        dataCity = dataCity.toLowerCase()
        if (dataCity || dataState) {
            if(isNewAddUser){
                setNewUserData({
                    ...newUserData,
                    state: dataState,
                    city: dataCity
                })
            }else{
                setFormData([...formData].map(object => {
                    if(object["_id"] === formData[selectedUserIndex]["_id"]) {
                      return {
                        ...object,
                        state: dataState,
                        city: dataCity
                      }
                    }
                    else return object;
                  }))
            }
        }
    }
    useEffect(() => {
        let companyName = context.user.companyName ? context.user.companyName : context.user.brandName
        let options = []
        let list = userData.subCategoryWithBrand.find(item => item.name === newUserData.subCategoryName)
        if(list){
            list.brandName && Array.isArray(list.brandName) && list.brandName.forEach(item => {
                options.push({
                    label: item,
                    value: item
                })
                if(item === companyName){
                    setHasCompanyNameAlready(true)
                }
            })
        }
        setBrandNameOptions([...options])
    }, [newUserData.subCategoryName])

    const getBrandUsersDetails = async () => {
        setComponentLoader(true)
        setFormData([])
        setFormOldData([])
        let brandResp = await context.apis.public.getBrandById(context.validator,
            {_id: context.user.brand}
            )
        let subCatResp = await context.apis[context.user.role].getAllSubCategoriesByParentBrandId(
            context.validator, {parentBrandId: context.user.parentBrand || context.user.brand}
        )

        let uniqueCategories = []
        let allSubCategories = []
        let allBrandNames = []
        if(subCatResp && subCatResp.response && subCatResp.response.rows.length > 0){
            uniqueCategories = subCatResp.response.rows.filter((v,i,a)=>a.findIndex(t=>(t.name.toLowerCase().trim() === v.name.toLowerCase().trim()))===i)
            allSubCategories = subCatResp.response.rows
            allBrandNames = subCatResp.response.rows.filter((v,i,a)=>a.findIndex(t=>(t.brandName && v.brandName && t.brandName.toLowerCase().trim() === v.brandName.toLowerCase().trim()))===i)
        }

            let myBrandData = {
                subCategory: "",
                brandName: "",
                _id: ""
            }
            if(allSubCategories.length > 0){
                let found = allSubCategories.find(el => el.userId === context.user._id)
                if(found){
                    myBrandData["subCategory"] = found.name
                    myBrandData["brandName"] = found.brandName
                    myBrandData["_id"] = found._id
                }
            }
        let companyName = context.user.companyName ? context.user.companyName : context.user.brandName

        if(uniqueCategories.length > 0){
            let subCat = []
            let brandNames = []
            for(let category of uniqueCategories){
    
                subCat.push({
                    value: category.name,
                    label: category.name
                })
            }
            for(let brand of allBrandNames){
                brandNames.push({
                    value: brand.brandName,
                    label: brand.brandName
                })
                if(brand.brandName === companyName){
                    setHasCompanyNameAlready(true)
                }
            }
            if(context.user && context.user.companyCategoryName && context.user.companyCategoryName.toLowerCase() === "others"){
                let found = subCat.find(el=> el.value == "Others")
                if(!found){
                    subCat.push({value: 'Others', label: 'Others'})
                }
            }else{
                subCat = subCat.filter(el=> el.value !== "Others")
            }
            setSubCategories(subCat)
            setBrandNameOptions(brandNames)
            setUniqueSubCategories(allSubCategories)
        }
       
        // setUniqueSubCategories(uniqueCategories)
        if(brandResp && brandResp.done){
            setComponentLoader(false)
            let data = [];
            for(let user of brandResp.response.userDetails){
                if(user.role === "brandCMO" && user.isVerified === false){
                    setIsCMOVerfied(false)
                }
                data.push({
                    fullName: user.fullName || "",
                    email: user.email || "",
                    role: user.role && user.role === "brandCMO" ? "CMO" : user.role === "brandManager" ? "Brand Manager" : "",
                    phone: user.phone || "",
                    country: user.country || "",
                    city: user.city || "",
                    state: user.state || "",
                    pincode: user.pincode || "",
                    createdOn: user.createdOn || "",
                    _id: user._id || "",
                    designation: user.designation || ""
                })
            }

            // if(brandResp.response.subCategories && brandResp.response.subCategories.length > 0){
            //     let subCat = []
            //     for(let category of brandResp.response.subCategories){

            //         subCat.push({
            //             value: category.name,
            //             label: category.name
            //         })
            //     }
            // }
            const found = data.find(element => element.role === "CMO");
            if(found){
                setHaveCMO(true)
                setAllRoles([
                    {
                        label: 'Brand Manager',
                        value: 'Brand Manager'
                    },
                    {
                        label: 'Other',
                        value: 'other'
                    }
                ])
            }else{
                setHaveCMO(false)
                setAllRoles(config.designationOptions)
            }
            setFormOldData(data)
            setFormData(data)
        }else{
            setComponentLoader(false)
        }
    }

    const nameToInitials = (fullName) => {
        const namesArray = fullName.trim().split(' ');
        if (namesArray.length === 1) return `${namesArray[0].charAt(0)}`;
        else return `${namesArray[0].charAt(0)}${namesArray[namesArray.length - 1].charAt(0)}`.toUpperCase();
      }
    
    const lastNameToInitials = (fullName) => {
        const namesArray = fullName.trim().split(' ');        
        if (namesArray.length === 1) return '';
        if (namesArray.length === 2) return `${namesArray[1].charAt(0)}`;
        if (namesArray.length === 3) return `${namesArray[2].charAt(0)}.${namesArray[namesArray.length - 2].charAt(0)}`;
        namesArray.shift()
        return namesArray.join(' ').toUpperCase()
    }

    const getFirstName = (fullName) => {
        const namesArray = fullName.trim().split(' ');
        return namesArray[0]
    }

    const changeUserTab = (index) => {
        setSelectedUserIndex(index)
        setIsGeneralEdit(false)
        setIsContactEdit(false)
        if(formData[index].role === "CMO" || haveCMO === false){
            setAllRoles(config.designationOptions)
        }else{
            setAllRoles([
                {
                    label: 'Brand Manager',
                    value: 'Brand Manager'
                },
                {
                    label: 'Other',
                    value: 'other'
                }
            ])
        }
        setIsNewAddUser(false)
        forceUpdate(false)
    }

    const onFormDataChange = (event) => {
        if(event.target.id === "state") {
            if(hasOnlyLettersAndSpaces(event.target.value)){
                setFormData([...formData].map(object => {
                    if(object["_id"] === formData[selectedUserIndex]["_id"]) {
                      return {
                        ...object,[event.target.id]: event.target.value
                      }
                    }
                    else return object;
                  }))
            }
        }
        else if(event.target.id === "city") {
            // if(context.utils.cityNameRegex.test(event.target.value))
            if(hasOnlyLettersNumberAndSpaces(event.target.value)){
                setFormData([...formData].map(object => {
                    if(object["_id"] === formData[selectedUserIndex]["_id"]) {
                      return {
                        ...object,[event.target.id]: event.target.value
                      }
                    }
                    else return object;
                  }))
            }
        }
        else {
            setFormData([...formData].map(object => {
                if(object["_id"] === formData[selectedUserIndex]["_id"]) {
                  return {
                    ...object,[event.target.id]: event.target.value
                  }
                }
                else return object;
              }))
        }
    }

    const onCountryChange = (data) => {
        setFormData([...formData].map(object => {
            if(object["_id"] === formData[selectedUserIndex]["_id"]) {
              return {
                ...object,
                country: data.value
              }
            }
            else return object;
          }))
    }

    const onNewUserCountryChange = (data) => {
        setNewUserData({
            ...newUserData,
            country: data.value
        })
    }

    const onNewUserDataChange = (event) => {
        if(event.target.id === "state") {
            if(hasOnlyLettersAndSpaces(event.target.value)){
                setNewUserData({
                    ...newUserData,
                    [event.target.id]: event.target.value
                })
            }
        }
        else if(event.target.id === "city") {
            // if(context.utils.cityNameRegex.test(event.target.value))
            if(hasOnlyLettersNumberAndSpaces(event.target.value)){
                setNewUserData({
                    ...newUserData,
                    [event.target.id]: event.target.value
                })
            }
        }
        else{
            setNewUserData({
                ...newUserData,
                [event.target.id]: event.target.value
            })
        }
    }

    const onDesignationChange = (data) => {
        setFormData([...formData].map(object => {
            if(object["_id"] === formData[selectedUserIndex]["_id"]) {
                return {
                ...object,
                role: data.value
                }
            }
            else return object;
        }))
    }

    const onNewUserDesignationChange = (data) => {
        if(data.value === "other"){
            setNewUserData({
                ...newUserData,
                role: data.value,
                designation: ""
            })
        }else if(data.value === "CMO"){
            let userData = {...newUserData}
            delete userData.brandName
            delete userData.subCategoryName
            console.log("userDAta--->>>",userData);
            setNewUserData({
                ...userData,
                role: data.value,
                designation: data.value
            })
            formValidator.current.fieldValid("brandName")
        }else{
            setNewUserData({
                ...newUserData,
                role: data.value,
                designation: data.value
            })
        }
    }
    console.log("newUserData----->>",newUserData);

    const onSubCategoryChange = (data) => {
        // if(data && data){}
        if(data && data.value){
            let item = userData.subCategoryWithBrand.find(item => item.name === data.value)
            setNewUserData({
                ...newUserData,
                subCategoryName: data ? data.value : "",
                brandName: item && item.brandName ? Array.isArray(item.brandName) && item.brandName[0] : ""
            })
        }
        else{
            setNewUserData({
                ...newUserData,
                subCategoryName: data ? data.value : ""
            })
        }
    }

    const onBrandNameChange = (data) => {
        setNewUserData({
            ...newUserData,
            brandName: data ? data.value : ""
        })
        console.log("Data--->>>",data, brandNameOptions);
    }
    useEffect(() => {
        let companyName = context.user.companyName ? context.user.companyName : context.user.brandName

        let removeBrandName = brandNameOptions;
        if(removeBrandName && newUserData.isCompanyNameChecked){
            removeBrandName = removeBrandName.filter(el => el.value.toLowerCase() !== companyName.toLowerCase())
            console.log("removeBrandName-->>",removeBrandName);
            setBrandNameOptions(removeBrandName)
        }
    }, [newUserData.brandName])
    
    

    const handleCheckbox = (e) => {
        console.log("calling itself");
        let companyName = context.user.companyName ? context.user.companyName : context.user.brandName

        setNewUserData({
            ...newUserData, 
            isCompanyNameChecked: e.target.checked, 
            isTextfield: e.target.checked,
            brandName: e.target.checked ? companyName : ""
        })
    }

    const addNewOption = (value) => {
        let newSubCategories = objectDeepClone(subCategories);
        newSubCategories.push({
            label: value,
            value: value
        })
        setSubCategories(newSubCategories)
        setNewUserData({
            ...newUserData,
            subCategoryName: value
        })
    }

    const addNewBrandOption = (value) => {
        let newBrandName = objectDeepClone(brandNameOptions);
        newBrandName.push({
            label: value,
            value: value
        })
        setBrandNameOptions(newBrandName)
        setNewUserData({
            ...newUserData,
            brandName: value
        })
    }


    const updateGeneralDetails = async () => {
        setIsLoading(true)
        const result = formValidator.current.allValid()
        if (result === false) {
            forceUpdate(true)
            formValidator.current.showMessages()
            setIsLoading(false)
            return
        } else {
            formValidator.current.hideMessages()
        }
        if(!compareObjs(formData[selectedUserIndex], formOldData[selectedUserIndex])){
            let updatingData = {}
            if(formData[selectedUserIndex].fullName !== formOldData[selectedUserIndex].fullName){
                updatingData['fullName'] = formData[selectedUserIndex].fullName
            }
            if(formData[selectedUserIndex].email !== formOldData[selectedUserIndex].email){
                updatingData['email'] = formData[selectedUserIndex].email
            }
            if(formData[selectedUserIndex].role !== formOldData[selectedUserIndex].role){
                updatingData['role'] = formData[selectedUserIndex].role === "CMO" ? "brandCMO" : formData[selectedUserIndex].role === "Brand Manager" ? "brandManager" : formData[selectedUserIndex].role
            }
            if(formData[selectedUserIndex]["_id"]){
                let updateUserResp = await context.apis[context.user.role].updateBrandUser(
                    context.validator, {_id: formData[selectedUserIndex]["_id"]}, updatingData
                )
                if(updateUserResp && updateUserResp.done){
                    setIsGeneralEdit(false)
                    setRefetch(!refetch)
                    setIsLoading(false)
                }else{
                    setIsLoading(false)
                }
            }
        } else {
            setIsGeneralEdit(false)
            setIsLoading(false)
        }
    }

    const updateContactDetails = async () => {
        setIsLoading(true)
        const result = formValidator.current.allValid()
        if (result === false || isPincodeValid == false) {
            formValidator.current.showMessages()
            forceUpdate(true)
            setIsLoading(false)
            return
        } else {
            formValidator.current.hideMessages()
        }
        if(!compareObjs(formData[selectedUserIndex], formOldData[selectedUserIndex])){
            let updatingData = {}
            if(formData[selectedUserIndex].phone !== formOldData[selectedUserIndex].phone){
                updatingData['phone'] = formData[selectedUserIndex].phone
            }
            if(formData[selectedUserIndex].country !== formOldData[selectedUserIndex].country){
                updatingData['country'] = formData[selectedUserIndex].country
            }
            if(formData[selectedUserIndex].state !== formOldData[selectedUserIndex].state){
                updatingData['state'] = formData[selectedUserIndex].state
            }
            if(formData[selectedUserIndex].city !== formOldData[selectedUserIndex].city){
                updatingData['city'] = formData[selectedUserIndex].city
            }
            if(formData[selectedUserIndex].pincode !== formOldData[selectedUserIndex].pincode){
                updatingData['pincode'] = formData[selectedUserIndex].pincode
            }
            if(formData[selectedUserIndex]["_id"]){
                let updateUserResp = await context.apis[context.user.role].updateBrandUser(
                    context.validator, {_id: formData[selectedUserIndex]["_id"]}, updatingData
                )
                if(updateUserResp && updateUserResp.done){
                    setIsContactEdit(false)
                    setRefetch(!refetch)
                    setIsLoading(false)
                }
            }
        } else {
            setIsLoading(false)
            setIsContactEdit(false)
        }
    }

    const onOpenGeneralEdit = () => {
        setIsGeneralEdit(true)
        setIsContactEdit(false)
        // formValidator.current.purgeFields()
        // formValidator.current.hideMessages()
        if(formData[selectedUserIndex].role === "CMO" || haveCMO === false){
            setAllRoles(config.designationOptions)
        }else{
            setAllRoles([
                {
                    label: 'Brand Manager',
                    value: 'Brand Manager'
                },
                {
                    label: 'Other',
                    value: 'other'
                }
            ])
        }
    }

    const onOpenContactEdit = () => {
        setIsContactEdit(true)
        setIsGeneralEdit(false)
        // formValidator.current.purgeFields()
        // formValidator.current.hideMessages()
    }

    const onCancelGeneralEdit = () => {
        setIsGeneralEdit(false)
        setIsLoading(false)
        setFormData(formOldData)
        formValidator.current.purgeFields()
        formValidator.current.hideMessages()
    }

    const onCancelContactEdit = () => {
        setIsContactEdit(false)
        setIsLoading(false)
        forceUpdate(false)
        setFormData(formOldData)
        formValidator.current.purgeFields()
        formValidator.current.hideMessages()
    }

    const onCancelNewUser = () => {
        setIsNewAddUser(false)
        setIsLoading(false)
        setNewUserData({
            fullName: "",
            email: "",
            role: "",
            phone: "",
            country: "",
            city: "",
            state: "",
            pincode: "",
            _id: ""
        })
        formValidator.current.purgeFields()
        formValidator.current.hideMessages()
        setSelectedUserIndex(formData.length - 1)
    }

    const createNewUser = async () => {
        setIsLoading(true)
        const result = formValidator.current.allValid()
        let cmoBrandNameErr = false;
        // if(allRoles.)
        let errorFieldArr = []
        for (var key in formValidator.current.fields) {
            if (formValidator.current.fields.hasOwnProperty(key)) {
                console.log(key + " -> " + formValidator.current.fields[key]);
                if(!formValidator.current.fields[key]){
                    errorFieldArr.push(key)
                }
            }
        }
        console.log("errorFieldArr--->>",errorFieldArr, errorFieldArr.find(el => el === 'brandName'));
        if(errorFieldArr.length === 1 && errorFieldArr.find(el => el === 'brandName') && newUserData.role === "CMO"){
            console.log("yes only brand NAme");
        }
        console.log("result--->>>",result, formValidator.current)
        if (result === false || isPincodeValid == false) {
            formValidator.current.showMessages()
            forceUpdate(true)
            setIsLoading(false)
            return
        } else {
            formValidator.current.hideMessages()
        }
        let payload = {
            users:[{
                    fullName: newUserData["fullName"],
                    email: newUserData["email"],
                    role: newUserData["role"] === "other" ? "brandManager" : newUserData["role"],
                    phone: newUserData["phone"],
                    country: newUserData["country"],
                    city: newUserData["city"],
                    state: newUserData["state"],
                    pincode: newUserData["pincode"],
                    brandName: newUserData["brandName"],
                    subCategoryName: newUserData["subCategoryName"],
                    designation: newUserData["designation"]
                }],
            brandName: newUserData["brandName"],
            brandId: context.user.brand || "",
            parentBrand: context.user.parentBrand || "",
            doNotUpdate: true
        }

        console.log('payload--->>>',payload)
        let addUserResp = await context.apis.public.updateBrandWithTeamMember(
            context.validator, payload
        )
        if(addUserResp && addUserResp.done){
            setIsLoading(false)
            setRefetch(!refetch)
            setSelectedUserIndex(0)
            setIsNewAddUser(false)
        }else{
            setIsLoading(false)
        }
    }

    const deleteUser = async () => {
        if(formData[selectedUserIndex]["_id"]){
            let deleteUserResp = await context.apis[context.user.role].archiveBrandUser(
                context.validator, {_id: formData[selectedUserIndex]["_id"]}, {}
            )
            if(deleteUserResp && deleteUserResp.done){
                setRefetch(!refetch)
                setSelectedUserIndex(formData.length - 2)
            }
        }
    }

    const toggleLogoutDialogBox = () => {
        setOverlay(true)
        setToggleLogoutDialog(true)
    }

    const confirmLogout = (confirm) => {
        if (confirm) {
            deleteUser();
        }
        setToggleLogoutDialog(false)
        setOverlay(false)
    }

    const addUser = () => {
        forceUpdate(false)
        formValidator?.current?.hideMessages()
        setIsNewAddUser(true)
        setSelectedUserIndex(null)
        setNewUserData({
            fullName: "",
            email: "",
            role: "",
            phone: "",
            country: "",
            city: "",
            state: "",
            pincode: "",
            _id: ""
        })
        if(haveCMO === false){
            setAllRoles(config.designationOptions)
        }else{
            setAllRoles([
                {
                    label: 'Brand Manager',
                    value: 'Brand Manager'
                },
                {
                    label: 'Other',
                    value: 'other'
                }
            ])
        }
    }

    formValidator.current.rules['full_name'] = {
        message: 'Please enter valid full name.',
        rule: (val) => {
            let returnValue = validateFullName(val)

            return returnValue
        },
    }

    formValidator.current.rules['duplicate'] = {
        message: 'This sub category and brand is already assigned to other brand manager',
        rule: (val) => {
            let returnValue
            // let isSame = compareObjs(formData, oldFormData)
            // if(isSame === false){
                let foundDuplicate = uniqueSubCategories && uniqueSubCategories.find(el => 
                    el?.name?.toLowerCase().trim() === newUserData?.subCategoryName?.toLowerCase().trim()
                    &&
                    el.brandName !== null
                    &&
                    el?.brandName?.toLowerCase().trim() === newUserData?.brandName?.toLowerCase().trim()
                )
                if(foundDuplicate){
                    returnValue = false
                }else{
                    returnValue = true
                }
            // }else {
            //     returnValue = true
            // }
            
            return returnValue
        },
    }

    formValidator.current.rules['alpha_space_limit_special'] = {
        message: 'Please enter valid brand name.',
        rule: (val) => {
            let reg = /^[a-zA-Z\s\(\)\&\,\')\(+=._-]+$/;
            let returnValue = reg.test(val)
            return returnValue
        }
    }
    formValidator.current.rules['city_name'] = {
        message: 'Please enter valid city name.',
        rule: (val) => {
            let returnValue = context.utils.cityNameRegex.test(val)
            return returnValue
        }
    }
    formValidator.current.rules['valid_other_designation'] = {
        message: 'Please enter valid designation.',
        rule: (val) => {
            let returnValue = !(val.toLowerCase() === "brand manager" || val.toLowerCase() === "cmo")
            return returnValue
        }
    }
    const onNewUserOtherDesignationChange = (e) => {
        setNewUserData({
            ...newUserData,
            designation: e.target.value
        })
    }

    const onCustomBrandNameChange = (e) => {
        let companyName = context.user.companyName ? context.user.companyName : context.user.brandName

        if(e.target.value.toLowerCase() !== companyName.toLowerCase()){
            setNewUserData({
                ...newUserData,
                brandName: e.target.value,
                isCompanyNameChecked: false
            })
        }else{
            setNewUserData({
                ...newUserData,
                brandName: e.target.value,
                isCompanyNameChecked: true
            })
        }
    }

    const addCustomBrandOption = (e) => {
        let companyName = context.user.companyName ? context.user.companyName : context.user.brandName

        let newBrandName = brandNameOptions || [];
        let found = newBrandName.find(el => el.value === e.target.value)
        if(!found){
            newBrandName.push({
                label: e.target.value,
                value: e.target.value
            })
        }
        // updateBrandNameOptions(newBrandName)
        if(e.target.value.toLowerCase() !== companyName.toLowerCase()){
            setNewUserData({
                ...newUserData,
                brandName: e.target.value,
                isTextfield: false
            })
        }
        // else{
        //     setNewUserData({
        //         ...newUserData,
        //         brandName: e.target.value,
        //         isCompanyNameChecked: true,
        //         isTextfield: true
        //     })
        // }
    }

    return (
        <>
        <div className={`ovelap ${( overlay === true ) ? "active" : ""}`}></div>
            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-20 pl">
            {
                componentLoader 
                ?
                    <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-10">
                        <div className="content-loader-block"></div>
                    </div>
                :
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-10">
                        <div className="profile-general">
                            <h2 className="htx2 clearfix">Team Members</h2>
                        </div>
                    </div>
                    <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                        <div className="brandTeamMember">
                            <div className="brandTeamMemberL">
                                <ul>
                                    {
                                        formOldData && formOldData.length > 0 && formOldData.map((data, i)=>{
                                            return(
                                            <li>
                                                <a href="javascript:void(0);" onClick={()=>changeUserTab(i)} className={selectedUserIndex === i && "active"}>
                                                    <span>{nameToInitials(data.fullName)}</span>
                                                    <h2>{data.fullName}</h2>
                                                    <p>{data.designation || data.role || "-"}</p>
                                                </a>
                                            </li>
                                            )
                                        })
                                    }
                                    <li className="addTab">
                                        <a href="javascript:void(0);" onClick={()=> addUser()} className={isNewAddUser && "active"}>
                                            <span><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M9 1.5V16.5" stroke="white" stroke-width="2" stroke-linecap="round" /> <path d="M1.5 9L16.5 9" stroke="white" stroke-width="2" stroke-linecap="round" /> </svg></span>
                                            <h2>Add</h2>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="brandTeamMemberR">
                                {
                                    !isNewAddUser 
                                    &&
                                    <>
                                        <div className="brandTeamMemberTable">
                                            <div className="row">
                                                <EditUserGeneral
                                                    formData={formData}
                                                    setFormData={setFormData}
                                                    selectedUserIndex={selectedUserIndex}
                                                    onFormDataChange={onFormDataChange}
                                                    onDesignationChange={onDesignationChange}
                                                    allRoles={allRoles}
                                                    formOldData={formOldData}
                                                    isCMOVerfied={isCMOVerfied}
                                                    setIsGeneralEdit={setIsGeneralEdit}
                                                    isGeneralEdit={isGeneralEdit}
                                                    setIsContactEdit={setIsContactEdit}
                                                    setAllRoles={setAllRoles}
                                                    isLoading={isLoading}
                                                    setIsLoading={setIsLoading}
                                                    haveCMO={haveCMO}
                                                    setRefetch={setRefetch}
                                                    refetch={refetch}
                                                />
                                                <EditUserContact
                                                    formData={formData}
                                                    setFormData={setFormData}
                                                    selectedUserIndex={selectedUserIndex}
                                                    onFormDataChange={onFormDataChange}
                                                    onDesignationChange={onDesignationChange}
                                                    allRoles={allRoles}
                                                    formOldData={formOldData}
                                                    isCMOVerfied={isCMOVerfied}
                                                    setIsGeneralEdit={setIsGeneralEdit}
                                                    isGeneralEdit={isGeneralEdit}
                                                    setIsContactEdit={setIsContactEdit}
                                                    isContactEdit={isContactEdit}
                                                    setAllRoles={setAllRoles}
                                                    isLoading={isLoading}
                                                    setIsLoading={setIsLoading}
                                                    haveCMO={haveCMO}
                                                    setRefetch={setRefetch}
                                                    refetch={refetch}
                                                    onCountryChange={onCountryChange}
                                                    isPincodeValid={isPincodeValid}
                                                />
                                                
                                            </div>
                                        </div>
                                        <div className="brandTeamMemberTable">
                                            <div className="row">
                                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12"></div>
                                                {/* <div className="col-xl-5 col-lg-5 col-md-12 col-xs-12 delete-user mt-30">
                                                    {
                                                        context.user._id && formData[selectedUserIndex] && formData[selectedUserIndex]["_id"] &&
                                                        context.user._id !== formData[selectedUserIndex]["_id"]
                                                        &&
                                                        formData[selectedUserIndex] && formData[selectedUserIndex].role === "Brand Manager"
                                                        &&
                                                        <a className="viewwLink" href="javascript:void(0);" onClick={toggleLogoutDialogBox}>Delete user <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M11.4887 5.23645C11.4887 5.23645 11.1463 9.48392 10.9476 11.2731C10.853 12.1276 10.3251 12.6284 9.46051 12.6441C7.81513 12.6738 6.16786 12.6757 4.52311 12.641C3.69127 12.624 3.17224 12.1169 3.07953 11.2775C2.87962 9.47257 2.53906 5.23645 2.53906 5.23645" stroke="#FF4A4A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /> <path d="M6.87109 5.57184V9.35578" stroke="#FF4A4A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /> <path d="M12.3626 3.20034H1.66797" stroke="#FF4A4A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /> <path d="M10.3038 3.20036C9.80873 3.20036 9.38241 2.85035 9.28529 2.36537L9.13204 1.59849C9.03744 1.24469 8.71707 1 8.35192 1H5.68235C5.3172 1 4.99683 1.24469 4.90223 1.59849L4.74898 2.36537C4.65186 2.85035 4.22553 3.20036 3.73047 3.20036" stroke="#FF4A4A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /> </svg></a>
                                                    }
                                                </div> */}
                                            </div>
                                        </div>
                                    </> 
                                }
                                {
                                    isNewAddUser && newUserData ?
                                    <>
                                        <div className="brandTeamMemberTable">
                                            <div className="row">
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-20 mt-10">
                                                        <h2 className="htx3 clearfix">General</h2>
                                                    </div>
                                                    <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                                        <TextField
                                                            formData={newUserData}
                                                            formKey='fullName'
                                                            onDataChange={onNewUserDataChange}
                                                            label='Name'
                                                            placeholder='Member Name'
                                                            isRequired={false}
                                                            formValidator={formValidator}
                                                            validation='required|full_name'
                                                            maxLength={50}
                                                        />
                                                    </div>
                                                    <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                                        <TextField
                                                            formData={newUserData}
                                                            formKey='email'
                                                            onDataChange={onNewUserDataChange}
                                                            label='Email'
                                                            placeholder='Member Email'
                                                            isRequired={false}
                                                            formValidator={formValidator}
                                                            validation='required|email'
                                                        />
                                                    </div>
                                                    <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                                        <SingleSelect
                                                            formData={newUserData}
                                                            formKey='role'
                                                            onDataChange={onNewUserDesignationChange}
                                                            label='Designation'
                                                            placeholder='Designation'
                                                            isRequired={false}
                                                            options={allRoles}
                                                            formValidator={formValidator}
                                                            validation='required'
                                                        />
                                                    </div>
                                                    {newUserData && newUserData.role === "other" &&
                                                        <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                                            <TextField
                                                                formData={newUserData}
                                                                formKey='designation'
                                                                onDataChange={onNewUserOtherDesignationChange}
                                                                label='Other'
                                                                placeholder='Designation'
                                                                isRequired={false}
                                                                formValidator={formValidator}
                                                                validation='required|alpha_space|valid_other_designation'
                                                                maxLength={50}
                                                            />
                                                        </div>
                                                    }
                                                    {
                                                        newUserData && newUserData.role !== "CMO"
                                                        &&
                                                        <>
                                                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                                            <CreatableSelect
                                                                formData={newUserData}
                                                                formKey='subCategoryName'
                                                                onDataChange={onSubCategoryChange}
                                                                onCreate={addNewOption}
                                                                label='Sub Category'
                                                                placeholder='Sub Category'
                                                                isRequired={false}
                                                                options={subCategories}
                                                                formValidator={formValidator}
                                                                validation={newUserData.brandName && newUserData.role !== "CMO" ? "required|alpha_space" : ""}
                                                                helpText="Insert or create a subcategory for your brand from the dropdown."
                                                                emptyOptionsMessage="Create a Sub Category"
                                                                maxLength={50}
                                                                validateOptionRule="alpha_space"
                                                                />
                                                            </div>
                                                            <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                                                {/* <TextField
                                                                    formData={newUserData}
                                                                    formKey='brandName'
                                                                    onDataChange={onNewUserDataChange}
                                                                    label='Brand Name'
                                                                    placeholder='Brand Name'
                                                                    isRequired={false}
                                                                    formValidator={newUserData.brandName && newUserData.designation !== "CMO" ? formValidator : null}
                                                                    validation={newUserData.brandName && newUserData.designation !== "CMO" ? "duplicate" : ""}
                                                                    helpText="Enter your brand name here."
                                                                /> */}
                                                                {/* <CreatableSelect
                                                                    formData={newUserData}
                                                                    formKey='brandName'
                                                                    onDataChange={onBrandNameChange}
                                                                    onCreate={addNewBrandOption}
                                                                    label='Brand Name'
                                                                    placeholder='Brand Name'
                                                                    isRequired={false}
                                                                    options={brandNameOptions}
                                                                    formValidator={newUserData.brandName && newUserData.designation !== "CMO" ? formValidator : null}
                                                                    // validation={newUserData.brandName && newUserData.designation !== "CMO" ? "duplicate" : ""}
                                                                    validation={newUserData.brandName && newUserData.designation !== "CMO" ? "" : ""}
                                                                    helpText="Enter your brand name here."
                                                                    emptyOptionsMessage="Create a Brand Name"
                                                                    maxLength={100}
                                                                    /> */}
                                                                    {
                                                                        newUserData.isTextfield ?
                                                                        <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                                                            <TextField
                                                                            formData={newUserData}
                                                                            formKey='brandName'
                                                                            onDataChange={onCustomBrandNameChange}
                                                                            label='Brand Name'
                                                                            placeholder='Brand Name'
                                                                            isRequired={false}
                                                                            formValidator={formValidator}
                                                                            validation={`${
                                                                                newUserData.subCategoryName && newUserData.role !== "CMO"
                                                                                ? "required|alpha_space_limit_special"
                                                                                :
                                                                            ""
                                                                            }`}
                                                                            onBlur={addCustomBrandOption}
                                                                            isBlur={true}
                                                                            maxLength={100}
                                                                            autoFocus={newUserData.isCompanyNameChecked}
                                                                            // isDisabled={index === 0 ? true : index === 1 && formData.role ? true : false}
                                                                            // isDisabled={index === 0 ? true : false}
                                                                        />
                                                                            </div>
                                                                            :
                                                                            <CreatableSelect
                                                                            formData={newUserData}
                                                                            formKey='brandName'
                                                                            onDataChange={onBrandNameChange}
                                                                            onCreate={addNewBrandOption}
                                                                            label='Brand Name'
                                                                            placeholder='Brand Name'
                                                                            isRequired={false}
                                                                            options={brandNameOptions}
                                                                            formValidator={formValidator}
                                                                            validation={`${
                                                                                newUserData.subCategoryName && newUserData.role !== "CMO"
                                                                                ? "required|alpha_space_limit_special"
                                                                                :
                                                                                ""
                                                                            }`}
                                                                            // validation=""
                                                                            helpText="Enter your brand name here."
                                                                            emptyOptionsMessage="Create a Brand Name"
                                                                            maxLength={100}
                                                                            validateOptionRule="alpha_space_limit_special"
                                                                            />
                                                                    }
                                                                    {
                                                                        !hasCompanyNameAlready && 
                                                                    <div className='forminpt'>
                                                                            <div className='checkbox onboardcheck'>
                                                                                <input
                                                                                    type='checkbox'
                                                                                    id='html-term'
                                                                                    onChange={(e)=>handleCheckbox(e)}
                                                                                    checked={newUserData.isCompanyNameChecked}
                                                                                />
                                                                                <label style={{display: "inline"}} htmlFor='html-term'><b>Keep company name as a brand name</b></label> 
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    
                                                            </div>
                                                        </>
                                                    }
                                                    
                                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-20 mt-10">
                                                        <h2 className="htx3 clearfix">Contact</h2>
                                                    </div>
                                                    <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12 clearfix ">
                                                        <TextFieldWithPrefix
                                                            formData={newUserData}
                                                            formKey='phone'
                                                            onDataChange={onNewUserDataChange}
                                                            label='Mobile No'
                                                            placeholder='Eg. 9999 999 999'
                                                            isRequired={false}
                                                            formValidator={formValidator}
                                                            validation='required|phone'
                                                        />
                                                    </div>
                                                    <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                                        {/* <TextField
                                                            formData={newUserData}
                                                            formKey='country'
                                                            onDataChange={onNewUserDataChange}
                                                            label='Country'
                                                            placeholder='Enter Country Name'
                                                            isRequired={false}
                                                            formValidator={formValidator}
                                                            validation='required'
                                                        /> */}
                                                        <SingleSelect
                                                            formData={newUserData}
                                                            formKey='country'
                                                            onDataChange={onNewUserCountryChange}
                                                            label='Country'
                                                            placeholder='Enter Country Name'
                                                            isRequired={false}
                                                            options={config.country}
                                                            formValidator={formValidator}
                                                            validation='required'
                                                        />
                                                    </div>
                                                    <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12">
                                                        <TextField
                                                            formData={newUserData}
                                                            formKey="pincode"
                                                            maxLength={6}
                                                            onDataChange={onNewUserDataChange}
                                                            label="Pin Code"
                                                            placeholder="Enter Pincode"
                                                            isRequired={false}
                                                            formValidator={formValidator}
                                                            isPincodeValid={isPincodeValid}
                                                            validation="required|min:6|numeric"
                                                        />
                                                    </div>
                                                    <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12">
                                                        <TextField
                                                            formData={newUserData}
                                                            formKey="state"
                                                            onDataChange={onNewUserDataChange}
                                                            label="State"
                                                            placeholder="Enter State"
                                                            isRequired={false}
                                                            formValidator={formValidator}
                                                            validation="required|alpha_space"
                                                            maxLength={50}
                                                        />
                                                    </div>
                                                    <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12">
                                                        <TextField
                                                            formData={newUserData}
                                                            formKey="city"
                                                            onDataChange={onNewUserDataChange}
                                                            label="City"
                                                            placeholder="Enter City"
                                                            isRequired={false}
                                                            formValidator={formValidator}
                                                            validation="required|city_name"
                                                            maxLength={50}
                                                        />
                                                    </div>
                                                
                                                    <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 end-md row">
                                                        <a href="javascript:void(0);" onClick={()=> onCancelNewUser()} className="btn btn-outline" >Cancel</a>
                                                        <a href="javascript:void(0);" onClick={()=> createNewUser()} className={isLoading ? "btn btn-orange button--loading btn-disabled" : "btn btn-orange"} >
                                                        Add Member
                                                        </a>
                                                    </div>
                                                </div>
                                        </div>
                                    </>
                                    :
                                    ""
                                }
                            </div>
                        </div>
                    </div>
                </div>
                }

        </div>
        <ConfirmDialog
            togglePopup={toggleLogoutDialog}
            onModalClose={confirmLogout}
            componentName={""}
            confirmTitle="Are you sure you want to delete user?"
            confirmText="Yes"
        />
        </>
    )
}

export default ProfileUsers
