import React, { useState, useContext, useEffect, useRef } from 'react';
import SimpleReactValidator from "simple-react-validator";
import AppContextObject from "../../../../common/context/common";
import { PublicContextInitiator } from "../../../apis/publicContextInitiator";
import TextField from '../../../../components/formFields/textField/textField';
import TextFieldWithPrefix from '../../../../components/formFields/textFieldWithPrefix/textFieldWithPrefix';
import SingleSelect from '../../../../components/formFields/singleSelect/singleSelect';
import config from '../../../../service/config';
import { createUniqueUsername, validateFullName } from '../../../../service/helper';
import errorSVG from "../../../../assets/images/error.svg";

const NewFOS = ({ isCustomInventoryEdit, hideModal, setUserCreateSuccessfully, setOverlay, setUserCreatedEmail }) => {
    const context = useContext(AppContextObject)
    const [formData, setFormData] = useState({
        fullName: "",
        email: "",
        phone: "",
        state: "",
        city: "",
        region: "",
        role: "backendFOS",
        username: ""
    })
    const [statesCities, setStateCities] = useState([]);
    const [cities, setCities] = useState([]);
    const [buttonVisible, setButtonVisible] = useState(false);
    const [showErrors, setShowErrors] = useState(false);
    const [fosEmailList, setFosEmailList] = useState([])
    const [fosPhoneList, setFosPhoneList] = useState([])
    const [showErrorsFirsttime, setShowErrorsFirsttime] = useState(false);
    const [creatingNewFos, setCreatingNewFos] = useState(false)
    const [formValidator] = useState(useRef(new SimpleReactValidator({
        element: (message, className) =>
            <h1 className="valError">
                <img src={errorSVG} />
                {message==="The full name field is required."
                ?
                "The FOS agency name field is required."
                :
                message==="The phone field is required."
                ?
                "The phone no field is required."
                :
                message==="The email field is required."
                ?
                "The email id field is required."
                :
                message==="Please enter valid full name."
                ?
                "Please enter valid full agency name."
                :
                message
                }
            </h1>
    })))

    useEffect(() => {
        getFosData()
    }, [])

    const getFosData = async() => {
        const resp = await context.apis.public.getUsersListByRole(
            context.validator, {role: "backendFOS"}, {}
        )
        let phoneList = [], emailList = [];
        if(resp && resp.done && resp?.response?.rows.length > 0){
            resp?.response?.rows?.forEach(data => {
                phoneList.push(data.phone)
                emailList.push(data.email)
            })
            setFosEmailList([...emailList])
            setFosPhoneList([...phoneList])
        }
    }

    useEffect(() => {
        fetchStateAndCity()
    }, [])
    const fetchStateAndCity = async () => {
        let publicContextInitiator = new PublicContextInitiator(context)
        let resp = await publicContextInitiator.getStatesAndCitiesFromPincode(
            context.validator, {}, {}
        )
        // console.log('resp======>', resp)
        if (resp && resp.done) {
            setStateCities(resp.response.rows)
        }
    }
    useEffect(() => {
        if (formData.state !== "" && formData.state !== undefined) {
            fetchCities()
        }
    }, [formData.state])

    const fetchCities = () => {
        setFormData({
            ...formData,
            city: ""
        })
        let stateArray = statesCities.find(state => state.stateName === formData.state);
        setCities(stateArray.districts)
    }
    useEffect(() => {

        if (formData.fullName.length > 0) {
            let trimLastName = formData.fullName.split(" ")[0]
            const userName = createUniqueUsername(trimLastName);
            setFormData({ ...formData, username: userName })
        }
    }, [formData.fullName])
    useEffect(() => {

        if (formData.fullName.length > 0 && formData.email.length > 0 && formData.phone.length > 0 && formData.state.length > 0 && formData.city.length > 0 && formData.region.length > 0) {
            setButtonVisible(true)
        }
        else {
            setButtonVisible(false)
        }
    }, [formData])

    const onFormDataChange = (event) => {
        if(showErrorsFirsttime){
            setShowErrors(true);
        }
        setFormData({
            ...formData,
            [event.target.id]: event.target.value
        })
    }

    const onRegionChange = (data) => {
        setShowErrors(true);
        setFormData({
            ...formData,
            region: data.value
        })
    }

    const onStateChange = (data) => {
        if(showErrorsFirsttime){
            setShowErrors(true);
        }
        setFormData({
            ...formData,
            state: data.value
        })
    }

    const onCityChange = (data) => {
        if(showErrorsFirsttime){
            setShowErrors(true);
        }
        
        setFormData({
            ...formData,
            city: data.value
        })
    }
    const refreshFormData = () => {
        setShowErrors(false);
        setFormData({
            fullName: "",
            email: "",
            phone: "",
            state: "",
            city: "",
            region: "",
            role: "backendFOS",
            username: ""
        })
        setCities([])
    }
    const submitNewFOS = () => {
        //api call for create FOS
        // console.log('submitNewFOS');
        createFosUser();
        setShowErrorsFirsttime(true);
    }
    useEffect(() => {
        if(showErrorsFirsttime){
            if (!showErrors) {
                formValidator.current.hideMessages()
            } else {
                formValidator.current.showMessages()
            }
        }        
    }, [showErrors])
    const createFosUser = async () => {
        setShowErrors(true)
        const result = formValidator.current.allValid()
        if (result === false) {
            formValidator.current.showMessages()
            return
        } else {
            formValidator.current.hideMessages()
            if (formValidator.current.errorMessages.fullName === null &&
                formValidator.current.errorMessages.email === null &&
                formValidator.current.errorMessages.phone === null &&
                formValidator.current.errorMessages.state === null &&
                formValidator.current.errorMessages.city === null
            ) {
                if (formData.username && formData.username.length > 0) {
                    setCreatingNewFos(true)
                    let resp = await context.apis[context.user.role].addFosUser(
                        context.validator, formData
                    )
                    // console.log(resp)
                    if (resp && resp.done) {
                        formValidator.current.hideMessages()
                        setCreatingNewFos(false)
                        setShowErrorsFirsttime(false);
                        setUserCreatedEmail(formData.email)
                        hideModal();
                        refreshFormData();
                        setOverlay(true);
                        setUserCreateSuccessfully(true);
                    }
                }
            }
        }
    }
    formValidator.current.rules['email'] = {
        message: 'The email id must be a valid email id.',
        rule: (val) => {
            let returnValue = true;
            const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
            // console.log(re.test(val))
            if (!re.test(val)) {
                returnValue = false
            }
            return returnValue 
        },
    }
    formValidator.current.rules['phone'] = {
        message: 'Please enter 10 digits phone no.',
        rule: (val) => {
            let returnValue = true;
            if (val.length < 10){
                returnValue = false
            }
            return returnValue     
        },
        
    }
    
    formValidator.current.rules['full_name'] = {
        message: 'Please enter valid full name.',
        rule: (val) => {
            let returnValue = validateFullName(val)
            return returnValue
        },
    }
    formValidator.current.rules['uniqueFosEmail'] = {
        message: "This email id is already taken.",
        rule: (val) => {
            let returnValue;
            if(fosEmailList.includes(val)){
                returnValue = false
            }
            else{
                returnValue = true
            }
            return returnValue
        }
    }
    formValidator.current.rules['uniqueFosPhone'] = {
        message: "This phone number is already taken.",
        rule: (val) => {
            let returnValue;
            if(fosPhoneList.includes(val)){
                
                returnValue = false
            }
            else{
                returnValue = true
            }
            console.log("fosPhoneList----", val, fosPhoneList);
            return returnValue
        }
    }

    return (
        <div id="fosModal" className={`popup fosModal ${isCustomInventoryEdit === true ? "" : "hide"}`}>
            <div className="popup-header">
                <h3 className="htx2 mb-10">Create Logins</h3>
                <span onClick={() => { hideModal(); refreshFormData(); setShowErrors(false);setShowErrorsFirsttime(false);formValidator.current.hideMessages() }} className="close toggle" data-target="closePopup">close</span>
            </div>
            <div className="popup-body">
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <TextField
                            maxLength={50}
                            formData={formData}
                            formKey='fullName'
                            onDataChange={onFormDataChange}
                            label='Your Agency Name'
                            placeholder='Enter Your Agency Name'
                            isRequired={false}
                            formValidator={formValidator}
                            validation='required|full_name'
                        />
                    </div>

                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                        <TextField
                            formData={formData}
                            formKey='email'
                            onDataChange={onFormDataChange}
                            label='Email ID'
                            placeholder='E.g. anju@abcschool.com'
                            isRequired={false}
                            formValidator={formValidator}
                            validation='required|email|uniqueFosEmail'
                        />

                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                        <TextFieldWithPrefix
                            formData={formData}
                            formKey='phone'
                            onDataChange={onFormDataChange}
                            label='Phone No'
                            placeholder='E.g. 99999 99999'
                            isRequired={false}
                            formValidator={formValidator}
                            validation='required|phone|uniqueFosPhone'
                        />

                    </div>

                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                        <SingleSelect
                            formData={formData}
                            formKey="state"
                            onDataChange={onStateChange}
                            label='State'
                            placeholder='Select State'
                            isRequired={false}
                            options={statesCities.sort((a, b) => a.stateName.localeCompare(b.stateName)).map((item) => { return { _id: item._id, value: item.stateName, label: item.stateName } })}
                            formValidator={formValidator}
                            validation='required'
                        />
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                        <SingleSelect
                            formData={formData}
                            formKey='city'
                            onDataChange={onCityChange}
                            label='City/District'
                            placeholder='Select City'
                            isRequired={false}
                            options={cities.sort((a, b) => a.localeCompare(b)).map((item, id) => { return { _id: item, value: item, label: item } })}
                            formValidator={formValidator}
                            validation='required'
                        />
                    </div>
                    {/* <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                        <SingleSelect
                            formData={formData}
                            formKey='region'
                            onDataChange={onRegionChange}
                            label='Region'
                            placeholder='Select Region'
                            isRequired={false}
                            options={config.regions}
                            formValidator={formValidator}
                            validation=''
                        />
                    </div> */}
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 end-xs">
                        <div className="forminpt mb-0">
                            <input value="create" name="create" className={`btn btn-orange ${creatingNewFos && "button--loading"}`} type="button"
                                // disabled={!buttonVisible}
                                onClick={submitNewFOS}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default NewFOS;