import { useContext, useEffect, useState } from "react"
import DataContext from "../../../../service/contextProvider"
import { revenueFormatter, numberWithCommas } from "../../../../service/helper";

const CompareRevenue = (props) => {
    const parentData = useContext(DataContext)
    console.log("parentData>>>>",parentData);
    const [revenue, setRevenue] = useState(parentData["netRevenue"])
    const [otherSchoolsRevenue, setOtherSchoolsRevenue] = useState(parentData["otherSchoolsRevenue"])

    useEffect(() => {
        setRevenue(revenueFormatter(parentData["netRevenue"]))
    }, [parentData["netRevenue"]])

    useEffect(() => {
        setOtherSchoolsRevenue(revenueFormatter(parentData["otherSchoolsRevenue"]))
    }, [parentData["otherSchoolsRevenue"]])

    return (
        <div className="col-xl-3 col-lg-3 col-md-12 col-xs-12">
            <div className="avera-anual">
                {/* <div className="avera-textlogo">
                    <p>WISR's vision is for schools to have great infrastructure, build remarkable experiences for students, and 
                        to facilitate better teacher salaries. WISR is also determined to infuse schools with positive forces of health, 
                        hygiene, nutrition, edutainment and wellness, so that there are no more compromises on the quality of learning.</p>
                    <span className="faq-logo"></span>
                </div> */}
                <div className="avera-anualbox">
                    <h3>Average annual revenue based on builtup area & inventories</h3>
                    <div className="avera-school row bottom-xs">
                        <div className="avera-youschol"> <span>Your School</span> <b>₹ {revenue || 0}</b> </div>
                        <div className="avera-youschol"> <span>Other Schools</span> <b>₹ {otherSchoolsRevenue || 0}</b> </div>
                    </div>
                    <p><b>Tip :</b> Add more inventory to increase your revenue</p>
                </div>
            </div>
        </div>
    )
}

export default CompareRevenue
