import { useState, useEffect, useContext } from "react";
import { objectDeepClone } from "../../../../service/helper";

import checkSign from "../../../../assets/images/smallSignedCheck.svg";
import sendButton from "../../../../assets/images/commentSendButton.svg";
import approved from "../../../../assets/images/Approved.svg";
import AppContextObject from "../../../../common/context/common";
import ConfirmDialog from "../../../../components/addon/confirmDialog/confirmDialog";
import { Carousel } from "react-bootstrap";
import "../../../../assets/css/videoCarousel.css";
import moment from "moment";
import Loader from "../../../../components/addon/loader";
import CrImagePreview from "../../../../components/addon/crImagePreview";

const CampaignPreviewCarousel = (props) => {
  const {
    setShowImagePreview,
    showImagePreview,
    activeImageIndex,
    setActiveImageIndex,
    activeSetIndex,
    activeInventoryIndex,
    formData,
    setFormData,
    onApprove,
    onReject,
    onDelete,
    inventory,
    view,
  } = props;
  // const [index, setIndex] = useState(0)
  const [overlay, setOverlay] = useState(false);
  const context = useContext(AppContextObject);

  const [activeImageIndexLocation, setActiveImageIndexLocation] = useState("");

  useEffect(() => {
    setActiveImageIndexLocation(formData[activeSetIndex]?.location);
  }, []);

  const handleSelect = (selectedIndex, e) => {
    setActiveImageIndex(selectedIndex);
  };

  // const currentLocation = formData[0].location; // Replace with the actual location
  // const activeImageIndexLocation = formData[0].location;
  // const activeImageIndexLocation = formData[activeSetIndex]?.location;
  // const activeImageIndexLocation = "I am in London";

  // console.log("formDataformData", formData, "propspropspropspropsprops", props);
  // console.log("currentLocation", currentLocation);
  console.log("activeImageIndexLocation ", activeImageIndexLocation);
  return (
    <>
      <CrImagePreview activeImageIndexLocation={activeImageIndexLocation} />
      <div className={`ovelap ${overlay ? "active" : ""}`}></div>
      <div
        id="overlay"
        className="imageView"
        style={{
          display: showImagePreview === true ? "flex" : "none",
          width: "1100px",
          maxWidth: "1100px",
          float: "left",
          height: "600Px",
          maxHeight: "600px",
          overflowY: "scroll",
        }}
      >
        <div
          className="slider-wrapper crPreviewImg"
          style={{ width: "auto", height: "auto" }}
        >
          <Carousel
            activeIndex={activeImageIndex}
            onSelect={handleSelect}
            interval={null}
          >
            {context.user.role === "backendSales" ||
            view === "campaignReportView" ||
            context.user.role.startsWith("brand")
              ? ""
              : formData[activeSetIndex] && formData[activeSetIndex].inventories
              ? formData[activeSetIndex].inventories[
                  activeInventoryIndex
                ].images.map((videoObj, currentIndex) => {
                  return (
                    <Carousel.Item key={currentIndex}>
                      <CampaignExamplePreviewImg
                        setShowImagePreview={setShowImagePreview}
                        showImagePreview={showImagePreview}
                        activeImageIndex={activeImageIndex}
                        activeSetIndex={activeSetIndex}
                        activeInventoryIndex={activeInventoryIndex}
                        formData={formData}
                        setFormData={setFormData}
                        onApprove={onApprove}
                        onReject={onReject}
                        onDelete={onDelete}
                        inventory={inventory}
                        overlay={overlay}
                        setOverlay={setOverlay}
                        currentImage={
                          context.user.role === "backendSales" ||
                          view === "campaignReportView" ||
                          context.user.role.startsWith("brand")
                            ? inventory.images[currentIndex]
                            : formData[activeSetIndex].inventories
                            ? formData[activeSetIndex].inventories[
                                activeInventoryIndex
                              ].images[currentIndex]
                            : formData[activeSetIndex].events[
                                activeInventoryIndex
                              ].images[currentIndex]
                        }
                        // currentInventory={formData[activeSetIndex].inventories[activeInventoryIndex]}
                        view={view}
                      />
                    </Carousel.Item>
                  );
                })
              : formData[activeSetIndex] &&
                formData[activeSetIndex].events[
                  activeInventoryIndex
                ].images.map((videoObj, currentIndex) => {
                  return (
                    <Carousel.Item key={currentIndex}>
                      <CampaignExamplePreviewImg
                        setShowImagePreview={setShowImagePreview}
                        showImagePreview={showImagePreview}
                        activeImageIndex={activeImageIndex}
                        activeSetIndex={activeSetIndex}
                        activeInventoryIndex={activeInventoryIndex}
                        formData={formData}
                        setFormData={setFormData}
                        onApprove={onApprove}
                        onReject={onReject}
                        onDelete={onDelete}
                        inventory={inventory}
                        overlay={overlay}
                        setOverlay={setOverlay}
                        currentImage={
                          context.user.role === "backendSales" ||
                          view === "campaignReportView" ||
                          context.user.role.startsWith("brand")
                            ? inventory.images[currentIndex]
                            : formData[activeSetIndex].events[
                                activeInventoryIndex
                              ].images[currentIndex]
                        }
                        // currentInventory={formData[activeSetIndex].events[activeInventoryIndex]}
                        view={view}
                      />
                    </Carousel.Item>
                  );
                })}
          </Carousel>
        </div>
      </div>
    </>
  );
};
export default CampaignPreviewCarousel;

const CampaignExamplePreviewImg = (props) => {
  const {
    setShowImagePreview,
    showImagePreview,
    activeImageIndex,
    activeSetIndex,
    activeInventoryIndex,
    formData,
    setFormData,
    onApprove,
    onReject,
    onDelete,
    inventory,
    overlay,
    setOverlay,
    currentImage,
    view,
  } = props;

  const defaultImage = "https://via.placeholder.com/450x330";

  const context = useContext(AppContextObject);

  // let [imageList, setImageList] = useState([...formData[activeSetIndex].inventories[activeInventoryIndex].images])
  // let [currentIndex, setCurrentIndex] = useState(activeImageIndex)
  // let [currentImage, setCurrentImage] = useState(formData[activeSetIndex].inventories[activeInventoryIndex].images && formData[activeSetIndex].inventories[activeInventoryIndex].images.length > 0 ? formData[activeSetIndex].inventories[activeInventoryIndex].images[activeImageIndex] : defaultImage)
  const [refetch, setRefetch] = useState(false);
  const [allComments, setAllComments] = useState([]);
  const [newComment, setNewComment] = useState("");
  const [savingComment, setSavingComment] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [commentLoading, setCommentLoading] = useState(false);
  // useEffect(() => {
  //     setImageList(formData[activeSetIndex].inventories[activeInventoryIndex].images)
  //     setCurrentImage(formData[activeSetIndex].inventories[activeInventoryIndex].images && formData[activeSetIndex].inventories[activeInventoryIndex].images[activeImageIndex])
  // }, [formData[activeSetIndex].inventories[activeInventoryIndex].images, showImagePreview, activeImageIndex])

  // useEffect(() => {
  //     setCurrentImage(formData[activeSetIndex].inventories[activeInventoryIndex].images && formData[activeSetIndex].inventories[activeInventoryIndex].images[activeImageIndex])
  //     setCurrentIndex(activeImageIndex)
  // }, [activeImageIndex])

  useEffect(() => {
    if (
      !(
        context.user.role === "backendSales" ||
        view === "campaignReportView" ||
        context.user.role.startsWith("brand")
      )
    ) {
      getAllComments();
    }
  }, [refetch, showImagePreview, activeImageIndex]);

  const getAllComments = async () => {
    // console.log("jhgfdnbv", formData[activeSetIndex].inventories)
    setCommentLoading(true);
    if (formData[activeSetIndex].inventories) {
      let resp = await context.apis[
        context.user.role
      ].getCommentsForCampaignReport(
        context.validator,
        {
          imageId:
            formData[activeSetIndex].inventories[activeInventoryIndex].images[
              activeImageIndex
            ]._id,
        },
        {}
      );
      console.log("getCommentsForCampaignReport", resp);
      if (resp && resp.done) {
        setAllComments([...resp.response.rows]);
      }
    } else {
      let resp = await context.apis[
        context.user.role
      ].getCommentsForCampaignReport(
        context.validator,
        {
          imageId:
            formData[activeSetIndex].events[activeInventoryIndex].images[
              activeImageIndex
            ]._id,
        },
        {}
      );
      console.log("getCommentsForCampaignReport", resp);
      if (resp && resp.done) {
        setAllComments([...resp.response.rows]);
      }
    }
    setCommentLoading(false);
  };

  // const prevButtonClick = () => {
  //     if (currentIndex > 0) {
  //         setCurrentImage(images[currentIndex - 1])
  //         setCurrentIndex(currentIndex - 1)
  //     }
  // }

  // const nextButtonClick = () => {
  //     if (currentIndex < (images.length - 1)) {
  //         setCurrentImage(images[currentIndex + 1])
  //         setCurrentIndex(currentIndex + 1)
  //     }
  // }
  const deleteImage = (data?) => {
    // let newFormData = objectDeepClone(formData)
    // let selectedSet = objectDeepClone(formData[activeSetIndex])
    // let selectedInventory = objectDeepClone(formData[activeSetIndex].inventories[activeInventoryIndex])
    // selectedInventory.images.splice(activeImageIndex, 1)
    // selectedSet.inventories.splice(activeInventoryIndex, 1, selectedInventory)
    // newFormData.splice(activeSetIndex, 1, selectedSet)
    // setFormData([...newFormData])
    // onDelete()
  };
  const addComment = (e) => {
    let newFormData = objectDeepClone(formData);
    let selectedSet = objectDeepClone(formData[activeSetIndex]);
    let selectedInventory = formData[activeSetIndex].inventories
      ? objectDeepClone(
          formData[activeSetIndex].inventories[activeInventoryIndex]
        )
      : objectDeepClone(formData[activeSetIndex].events[activeInventoryIndex]);
    let selectedImage = formData[activeSetIndex].inventories
      ? objectDeepClone(
          formData[activeSetIndex].inventories[activeInventoryIndex].images[
            activeImageIndex
          ]
        )
      : objectDeepClone(
          formData[activeSetIndex].events[activeInventoryIndex].images[
            activeImageIndex
          ]
        );
    selectedImage.salesRemarks = e.target.value;
    selectedInventory.images.splice(activeImageIndex, 1, selectedImage);
    formData[activeSetIndex].inventories
      ? selectedSet.inventories.splice(
          activeInventoryIndex,
          1,
          selectedInventory
        )
      : selectedSet.events.splice(activeInventoryIndex, 1, selectedInventory);
    newFormData.splice(activeSetIndex, 1, selectedSet);
    setFormData([...newFormData]);
  };
  const addNewComment = async (data?) => {
    if (newComment && newComment !== "") {
      setSavingComment(true);
      let imageId = data
        ? formData[activeSetIndex].events[activeInventoryIndex].images[
            activeImageIndex
          ]._id
          ? formData[activeSetIndex].events[activeInventoryIndex].images[
              activeImageIndex
            ]._id
          : formData[activeSetIndex].events[activeInventoryIndex].images[
              activeImageIndex
            ].key
        : formData[activeSetIndex].inventories[activeInventoryIndex].images[
            activeImageIndex
          ]._id
        ? formData[activeSetIndex].inventories[activeInventoryIndex].images[
            activeImageIndex
          ]._id
        : formData[activeSetIndex].inventories[activeInventoryIndex].images[
            activeImageIndex
          ].key;
      let resp = await context.apis[
        context.user.role
      ].addCommentForCampaignReport(context.validator, {
        setId: formData[activeSetIndex]._id,
        imageId: imageId,
        comment: newComment,
        userRole: context.user.role,
        commentBy: context.user.fullName,
      });
      console.log("addCommentForCampaignReport", resp);
      if (resp && resp.done) {
        setNewComment("");
        setRefetch(!refetch);
      }
      setSavingComment(false);
    }
  };
  const handleDelete = (confirm) => {
    if (confirm) {
      onDelete();
    }
    setOverlay(false);
    setShowDeletePopup(false);
  };
  const handleApprove = (confirm) => {
    if (confirm) {
      onApprove();
    }
    setOverlay(false);
    setShowApproveModal(false);
  };
  const handleReject = (confirm) => {
    if (confirm) {
      onReject();
    }
    setOverlay(false);
    setShowRejectModal(false);
  };
  return (
    <>
      <div
        className="col-xl-12 col-lg-12 col-md-12 col-xs-12"
        style={{
          padding: "0",
          float: "left",
          width: "1100px",
          height: "600px",
        }}
      >
        <div
          id="closePopup"
          className="col-xl-7 col-lg-7 col-md-7 col-xs-7"
          style={{
            padding: "15px",
            width: "100%",
            height: "600px",
            float: "left",
            background: "#000000",
            borderRadius: "0",
          }}
        >
          <div className="popup-header">
            <span
              onClick={() => setShowImagePreview(false)}
              className="close toggle"
              data-target="closePopup"
            >
              close
            </span>
          </div>
          <div className="popup-body">
            <div className="clearfix" style={{ position: "relative" }}>
              {!(
                context.user.role === "backendSales" ||
                view === "campaignReportView" ||
                context.user.role.startsWith("brand")
              ) && (
                <div
                  className="flex-container1"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h4
                    style={{
                      color: "#ffffff",
                      fontFamily: "nexaxbold",
                      fontSize: "20px",
                    }}
                  >
                    {formData[activeSetIndex].inventories
                      ? formData[activeSetIndex].inventories[
                          activeInventoryIndex
                        ].name
                      : formData[activeSetIndex].events[activeInventoryIndex]
                          .name}
                  </h4>
                  {currentImage?.approvalStatus === "pending" ? (
                    // <img onClick={() => deleteImage()} style={{ height: "20px", filter: "brightness(100%)" }} src="/assets/FOSimg/delete.png" alt="Delete" />
                    <span
                      style={{
                        color: "#B4A51F",
                        float: "right",
                        filter: "brightness(100%)",
                        marginLeft: "auto",
                        paddingRight: "5px",
                      }}
                    >
                      Pending
                    </span>
                  ) : currentImage?.approvalStatus === "rejected" ? (
                    <>
                      <img
                        style={{
                          height: "20px",
                          filter: "brightness(100%)",
                          marginLeft: "auto",
                          paddingRight: "5px",
                          background: "black",
                        }}
                        src="/assets/FOSimg/close.png"
                        alt="Close"
                      />
                      <img
                        style={{
                          height: "20px",
                          filter: "brightness(100%)",
                          background: "black",
                        }}
                        src="/assets/FOSimg/rejected.png"
                        alt="Rejected"
                      />
                    </>
                  ) : (
                    currentImage?.approvalStatus === "approved" && (
                      <>
                        <img
                          style={{
                            height: "20px",
                            filter: "brightness(100%)",
                            marginLeft: "auto",
                            paddingRight: "5px",
                            background: "black",
                          }}
                          src={checkSign}
                          alt="Close"
                        />
                        <img
                          style={{
                            height: "20px",
                            filter: "brightness(100%)",
                            background: "black",
                          }}
                          src={approved}
                          alt="Approved"
                        />
                      </>
                    )
                  )}
                  {/* <h4 style={{ color: "#ffffff", fontFamily: "nexaxbold", fontSize: "20px" }}>{formData[activeSetIndex].inventories[activeInventoryIndex].name}</h4> */}
                  {/* <div id="exitButton"> <img onClick={() => setShowImagePreview(false)} style={{ height: "20px", filter: "brightness(100%)", marginLeft: "auto", marginTop: "40px", backgroundColor: "transparent" }} src="/assets/FOSimg/close.png" alt="Close" /> </div> */}
                  {/* <img onClick={() => setShowImagePreview(false)} style={{ height: "20px", filter: "brightness(100%)", marginLeft: "auto", backgroundColor: "transparent" }} src="/assets/FOSimg/close.png" alt="Close" /> */}
                  {/* <i onClick={() => setShowImagePreview(false)} className="fa fa-times"></i></div> */}
                  {/* <img style={{ height: "20px", filter: "brightness(100%)", marginLeft: "auto", paddingRight: "5px" }} src="/assets/FOSimg/close.png" alt="Close" /> */}
                  {/* <img style={{ height: "20px", filter: "brightness(100%)" }} src="/assets/FOSimg/rejected.png" alt="Rejected" /> */}
                </div>
              )}

              <div
                className=""
                style={{
                  height: "405px",
                  width: "100%",
                  float: "left",
                  position: "relative",
                }}
              >
                <div className="">
                  {/* <img src={(context.user.role === "backendSales" || context.user.role.startsWith("brand")) ? inventory.images[activeImageIndex]?.image : formData[activeSetIndex].inventories ? formData[activeSetIndex].inventories[activeInventoryIndex].images[activeImageIndex].image : formData[activeSetIndex].events[activeInventoryIndex].images[activeImageIndex].image} className="upload-campaignImg" style={{ position: "relative", objectFit: "contain", backgroundColor: "black", width: "100%" }} /> */}
                  {/* <img src={currentImage.image} className="upload-campaignImg" style={{ objectFit: "contain", backgroundColor: "black", width: "100%" }} /> */}
                  <img
                    src={currentImage.image}
                    className="upload-campaignImg"
                    style={{
                      maxHeight: "100%",
                      maxWidth: "100%",
                      width: "auto",
                      height: "auto",
                      position: "absolute",
                      top: "0",
                      bottom: "0",
                      left: "0",
                      right: "0",
                      margin: "auto",
                    }}
                  />
                  {/* <div style={{ height: "auto", fontSize: "12px", width: "auto", background: "transparent", color: "#FFFFFF", position: "absolute", left: "3%", top: "13%" }}>{(context.user.role === "backendSales" || context.user.role.startsWith("brand")) ? `${inventory.images[activeImageIndex]?.uploadDate}/${inventory.images[activeImageIndex]?.uploadTime}` : formData[activeSetIndex].inventories ? `${formData[activeSetIndex].inventories[activeInventoryIndex].images[activeImageIndex]?.uploadDate}/${formData[activeSetIndex].inventories[activeInventoryIndex].images[activeImageIndex]?.uploadTime}` : `${formData[activeSetIndex].events[activeInventoryIndex].images[activeImageIndex]?.uploadDate}/${formData[activeSetIndex].events[activeInventoryIndex].images[activeImageIndex]?.UploadTime}`}</div> */}
                  <div
                    style={{
                      height: "auto",
                      fontSize: "12px",
                      width: "auto",
                      background: "rgb(0,0,0,0.6)",
                      color: "rgb(255, 255, 255)",
                      position: "absolute",
                      left: "1%",
                      top: "7%",
                      padding: "3px 5px",
                      borderRadius: "3px",
                    }}
                  >{`${currentImage.uploadDate}/${currentImage.uploadTime}`}</div>
                  <div
                    style={{
                      height: "auto",
                      fontSize: "12px",
                      width: "auto",
                      background: "rgb(0,0,0,0.6)",
                      color: "rgb(255, 255, 255)",
                      position: "absolute",
                      right: "1%",
                      top: "7%",
                      wordBreak: "break-word",
                      textAlign: "right",
                      padding: "3px 5px",
                      borderRadius: "3px",
                    }}
                  >
                    {formData[activeSetIndex]?.location}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {!(
          context.user.role === "backendSales" ||
          view === "campaignReportView" ||
          context.user.role.startsWith("brand")
        ) && (
          <div
            className="col-xs-5 col-sm-5 col-md-5 col-lg-5"
            style={{
              backgroundColor: "#ffffff",
              float: "left",
              height: "600px",
              width: "100%",
            }}
          >
            <div
              className="row"
              style={{
                flexDirection: "column",
                justifyContent: "flex-end",
                height: "100%",
              }}
            >
              <div className="">
                <div className="container">
                  <div style={{ maxHeight: "380px", overflowY: "scroll" }}>
                    {commentLoading ? (
                      ""
                    ) : allComments && allComments.length > 0 ? (
                      allComments.map((item) => (
                        <div
                          className={
                            item.userRole !== "backendFOS"
                              ? "commentRight"
                              : "commentLeft"
                          }
                          style={
                            item.userRole !== "backendFOS"
                              ? {
                                  backgroundColor: "#F8F8F8",
                                  marginTop: "19px",
                                  marginBottom: "19px",
                                  marginLeft: "auto",
                                  paddingLeft: "10px",
                                  paddingTop: "10px",
                                  width: "300px",
                                  height: "80px",
                                  borderRadius: "5px",
                                }
                              : {
                                  backgroundColor: "#F8F8F8",
                                  width: "300px",
                                  height: "80px",
                                  marginTop: "19px",
                                  marginBottom: "19px",
                                  paddingLeft: "10px",
                                  paddingTop: "10px",
                                  borderRadius: "5px",
                                }
                          }
                        >
                          <div style={{ display: "flex" }}>
                            <div>
                              <div style={{ width: "80%", display: "flex" }}>
                                <h1
                                  style={{
                                    fontSize: "18px",
                                    fontFamily: "nexaxbold",
                                    color: "#070707",
                                    paddingRight: "5px",
                                  }}
                                >
                                  {" "}
                                  {item.commentBy}{" "}
                                </h1>
                                <span
                                  style={{
                                    fontSize: "12px",
                                    paddingTop: "5px",
                                    color: "#8C8C8C",
                                  }}
                                >
                                  {moment(item.createdOn).fromNow()}
                                </span>
                              </div>
                            </div>
                            {/* <div style={{ width: "5%", marginRight: "10px" }}>
                                                            <img style={{ height: "18px", filter: "brightness(100%)" }} src="/assets/FOSimg/delete.png" alt="Delete" />
                                                        </div> */}
                          </div>
                          <p style={{ paddingRight: "25px" }}>{item.comment}</p>
                        </div>
                      ))
                    ) : (
                      <div
                        style={{
                          alignSelf: "center",
                          textAlign: "center",
                          fontSize: "16px",
                        }}
                      >
                        No comments
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div>
                <span style={{ position: "relative" }}>
                  <div className="mb-20">
                    {formData[activeSetIndex]?.inventories ? (
                      <textarea
                        style={{ height: "100px", position: "relative" }}
                        name="retakeImage"
                        placeholder="Add Comment"
                        className="mt-20"
                        value={newComment}
                        onChange={(e) => setNewComment(e.target.value)}
                      />
                    ) : (
                      <textarea
                        style={{ height: "100px", position: "relative" }}
                        name="retakeImage"
                        placeholder="Add Comment"
                        className="mt-20"
                        value={newComment}
                        onChange={(e) => setNewComment(e.target.value)}
                      />
                    )}
                  </div>
                  {formData[activeSetIndex]?.inventories ? (
                    <img
                      onClick={() => addNewComment()}
                      style={{
                        width: "30px",
                        height: "auto",
                        position: "absolute",
                        right: "2.5%",
                        top: "65%",
                        float: "right",
                      }}
                      src={sendButton}
                    />
                  ) : (
                    <img
                      onClick={() => addNewComment()}
                      style={{
                        width: "30px",
                        height: "auto",
                        position: "absolute",
                        right: "2.5%",
                        top: "65%",
                        float: "right",
                      }}
                      src={sendButton}
                    />
                  )}
                </span>
                <div className="row mb-20">
                  <div>
                    <button
                      disabled={
                        currentImage?.approvalStatus === "approved" ||
                        currentImage?.approvalStatus === "rejected"
                      }
                      onClick={() => {
                        setOverlay(true);
                        setShowApproveModal(true);
                      }}
                      className={`btn crButtons ${
                        currentImage?.approvalStatus === "approved" ||
                        currentImage?.approvalStatus === "rejected"
                          ? "btn-disabled"
                          : "campaign-reporting-btn-approve"
                      }`}
                      style={{ width: "165px" }}
                    >
                      Approve
                    </button>
                  </div>
                  <div>
                    <button
                      disabled={
                        currentImage?.approvalStatus === "approved" ||
                        currentImage?.approvalStatus === "rejected"
                      }
                      onClick={() => {
                        setOverlay(true);
                        setShowRejectModal(true);
                      }}
                      className={`btn crButtons ${
                        currentImage?.approvalStatus === "approved" ||
                        currentImage?.approvalStatus === "rejected"
                          ? "btn-disabled"
                          : "campaign-reporting-btn-reject"
                      }`}
                      style={{ width: "165px" }}
                    >
                      Reject
                    </button>
                  </div>
                  <div>
                    <button
                      onClick={() => {
                        setOverlay(true);
                        setShowDeletePopup(true);
                      }}
                      className="btn campaign-reporting-btn-delete crButtons"
                      style={{ width: "165px" }}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <ConfirmDialog
        togglePopup={showDeletePopup}
        onModalClose={handleDelete}
        componentName={""}
        confirmTitle="Do you want to delete the image?"
      />
      <ConfirmDialog
        togglePopup={showApproveModal}
        onModalClose={handleApprove}
        componentName={""}
        confirmTitle="Do you want to approve the image?"
      />
      <ConfirmDialog
        togglePopup={showRejectModal}
        onModalClose={handleReject}
        componentName={""}
        confirmTitle="Do you want to reject the image?"
      />
    </>
  );
};
