import { useState, useContext, useEffect, useRef } from "react"
import { useHistory } from "react-router-dom"
import AppContextObject from "../../../common/context/common"
import OnboardingBackBtn from "../schools/components/onboardingBackBtn"
import CampaignApprovalModal from "../../../components/addon/campaignApprovalModal"
import ThankyouModal from "../../../components/addon/thankyouModal"
import RejectBrandRequestModal from "../../../components/addon/rejectBrandRequestModal"
import DataContext from "../../../service/contextProvider"
import { PublicContextInitiator } from "../../apis/publicContextInitiator"
import FOSTable from "./components/FOSTable";
import NewFOS from "./components/NewFOS";
import EditFOS from "./components/EditFOS";
import SearchInput from "./components/SearchInput"
import BackendTopBar from "./components/backendTopBar"
import BackendBackBtn from "./components/backendBackBtn"
import Pagination from "../../../components/addon/pagination"
import filterSvg from "../../../assets//images/filter-svg.svg"
import filterArrowSvg from "../../../assets/images/filter-arrow-svg.svg"
import CustomSearchInput from "../../../components/formFields/customSearchInput"
import useClickOutside from "../../../service/useClickOutside"
import { objectDeepClone, titleCase } from "../../../service/helper"
import moment from "moment"
import SchoolList from "../../../components/addon/schoolList"

const ActivityStatusList = () => {
    const context = useContext(AppContextObject)
    const [isCustomInventoryEdit, setIsCustomInventoryEdit] = useState(false)
    const [overlay, setOverlay] = useState(false)
    const [searchKeys, setSearchKeys] = useState("");
    const [searchInpuOpen, setSearchInpuOpen] = useState(false);
    const [filterCity, setFilterCity] = useState([])
    const [activityStatusList, setActivityStatusList] = useState([]);
    const [newUserCreated, setNewUserCreated] = useState(true);
    const [totalPage, setTotalPage] = useState([0])
    const [schoolTotalPage, setSchoolTotalPage] = useState([0])
    const [currentPage, setCurrentpage] = useState(1)
    const [schoolCurrentPage, setSchoolCurrentPage] = useState(1)
    const [totalPageCount, setTotalPageCount] = useState<any>([0])
    const [schoolTotalPageCount, setSchoolTotalPageCount] = useState<any>([0])
    const [region, setRegion] = useState('all');
    const [isFOSEdit, setIsFOSEdit] = useState(false);
    const [userId, setUserId] = useState("");
    const [cityFilterModal, setCityFilterModal] = useState(false);
    const [clearSearchIcon, setClearSearchIcon] = useState(false);
    const [regionCount, setRegionCount] = useState<any>({});
    const [loading, setLoading] = useState(false);
    const [showTypeFilter, setShowTypeFilter] = useState(false)
    const [showFilterModal, setShowFilterModal] = useState(false)
    const filterRef = useRef(null)
    const filterOptionsRef = useRef(null)
    const [filterByPortal, setFilterByPortal] = useState([])
    const [activityStatusTypeList, setActivityStatusTypeList] = useState(["school", "brand", "backend"])
    const [selectedPortal, setSelectedPortal] = useState([])
    const [selectedAllPortal, setSelectedAllPortal] = useState(true)
    const [campaignSchoolList, setCampaignSchoolList] = useState([])
    const [showSchoolModal, setShowSchoolModal] = useState(false)
    const [schoolListTitle, setSchoolListTitle] = useState('Schools List')
    const [pageLimit, setPageLimit] = useState(10)

    useClickOutside(filterOptionsRef, (event) => {
        const el = filterRef?.current;

        if (!el || el.contains(event.target)) {
            return;
        }
        setShowFilterModal(false)
    })
    const addNewInventory = () => {
        setIsCustomInventoryEdit(true)
        setOverlay(true)
    }

    const hideModal = () => {
        setIsCustomInventoryEdit(false);
        setOverlay(false);
    }
    const hideEditModal = () => {
        setIsFOSEdit(false);
        setOverlay(false);
    }
    
    useEffect(() => {
        setSelectedPortal(["school", "brand", "backend"])
        setFilterByPortal(["school", "brand", "backend"])
    }, [])

    useEffect(() => {
        getAllActivityStatus();
    }, [currentPage, searchKeys, filterByPortal])

    useEffect(() => {
        setCurrentpage(1)
    }, [searchKeys])

    const getAllActivityStatus = async () => {
        setLoading(true);
        console.log("filterByPortal-->>", filterByPortal);
        if(filterByPortal.length === 0){
            setLoading(false);
            setActivityStatusList([])
            setTotalPageCount([0])
            setTotalPage([0])
        }else{
            let resp = await context.apis[context.user.role].getActivityStatus(
                context.validator, { searchValue: searchKeys, portal: filterByPortal.length > 0 ? filterByPortal : [] }, { limit: pageLimit, page: currentPage, withCount: true, "sort": { "createdOn": -1 } }
            )
            console.log('resp=========>', resp)
    
            if (resp && resp.done) {
                setLoading(false);
                setRegionCount(resp.countData);
                setActivityStatusList(resp.response.rows)
                let total = Math.ceil(resp.response.count / 10);
                setTotalPageCount(resp.response.count);
                let arr = []
                for (let i = 0; i < total; i++) {
                    arr.push(i)
                }
                setTotalPage([...arr])
            }
        }
        
    }
    const onPageChange = (page) => {
        setCurrentpage(page)
    }
    const onNextClick = () => {
        setCurrentpage(prev => prev + 1)
    }
    const onPrevClick = () => {
        setCurrentpage(prev => prev - 1)
    }

    const handleSearch = async (e) => {
        if (e === "") {
            setSearchKeys("")
        }
        else {
            setSearchKeys(e.target.value.trim())
        }
    }
    const openFilter = () => {
        setShowTypeFilter(false)
        setShowFilterModal(!showFilterModal)
    }

    const clearAllFilter = () => {
        setFilterByPortal([])
        setSelectedPortal([])
        setShowFilterModal(false)
        setShowTypeFilter(false)
        setSelectedAllPortal(false)
    }

    const showFilterDropDown = (type) => {
        if (type === "type") {
            setShowTypeFilter(!showTypeFilter)
        }
    }

    const handleAddRemoveFilter = (type, data) => {
        console.log('type, data',type, data)
        if (type === "type") {
            let array = [...selectedPortal]
            if (array.includes(data)) {
                array.splice(array.indexOf(data), 1)
            }
            else {
                array.push(data)
            }
            console.log('array--->>',array)
            if (array.length === 3) {
                setSelectedAllPortal(true)
            } else {
                setSelectedAllPortal(false)
            }
            setSelectedPortal([...array])
            setFilterByPortal([...array])
        }
    }

    const handleAllPortalSelect = (e) => {
        if (e.target.checked) {
            setSelectedAllPortal(e.target.checked)
            setSelectedPortal(["school", "brand", "backend"])
            setFilterByPortal(["school", "brand", "backend"])
        } else {
            setSelectedAllPortal(e.target.checked)
            setSelectedPortal([])
            setFilterByPortal([])
        }
    }

    const getSchoolList = async (schoolIds, type) => {
        if (type === 'accepted') {
            setSchoolListTitle('Accepted Schools List')
        } else if (type === 'rejected') {
            setSchoolListTitle('Rejected Schools List')
        }
        if (schoolIds) {
            let ids = []
            for (const school of schoolIds) {
                ids.push(school.schoolId)
            }
            console.log("ids-->>>", ids);
            let schoolListResp = await context.apis[context.user.role].getSchoolNameListById(
                context.validator, { schoolIds: ids }
            )
            console.log("schoolListResp-->>", schoolListResp);
            if (schoolListResp && schoolListResp.done) {
                let data = []
                for (const school of schoolListResp.response.rows) {
                    data.push({
                        schoolName: school.schoolName
                    })
                }
                let total = Math.ceil(schoolListResp.response.count / 10);
                setSchoolTotalPageCount(schoolListResp.response.count);
                let arr = []
                for (let i = 0; i < total; i++) {
                    arr.push(i)
                }
                setSchoolTotalPage([...arr])
                setShowSchoolModal(true)
                setCampaignSchoolList(data)
            }

        }

    }
    const onNextPage = (currentPage) => {
        let school = objectDeepClone(campaignSchoolList)
        // school.splice((currentPage - 1) * 1, 1)
        setCampaignSchoolList([...school.slice((currentPage - 1) * 10, (currentPage) * 10)])
    }
    const onPrevPage = (currentPage) => {
        let school = objectDeepClone(campaignSchoolList)
        // school.splice((currentPage - 1) * 1, 1)
        setCampaignSchoolList([...school.slice((currentPage - 1) * 10, (currentPage) * 10)])
    }
    const onSchoolPageChange = (currentPage) => {
        let school = objectDeepClone(campaignSchoolList)
        setCampaignSchoolList([...school.slice((currentPage - 1) * 10, (currentPage) * 10)])
    }
    console.log("activityStatusList---->>>", activityStatusList)
    return (
        <>
            <div className={`ovelap ${overlay === true ? "active" : ""}`}></div>
            <div className="col-xl-10 col-lg-12 col-md-12 col-xs-12">
                <div className="rightPart noBack">
                    <div className="row">
                        <BackendTopBar />
                        <div className="col-xl-5 col-lg-5 col-md-12 col-xs-12 mt-30 mb-10 mmt-0">
                            <h2 className="htx2 clearfix">Activity Status</h2>
                        </div>
                        <div className="col-xl-7 col-lg-7 col-md-12 col-xs-12 mt-30 mb-20 mmt-0">
                        
                               <div className="inventorySearch schoolFilter inventoryBackAdmin">
                               
                                <CustomSearchInput
                                    searchInpuOpen={searchInpuOpen}
                                    setSearchInpuOpen={setSearchInpuOpen}
                                    clearSearchIcon={clearSearchIcon}
                                    setClearSearchIcon={setClearSearchIcon}
                                    searchKeys={searchKeys}
                                    handleChange={handleSearch}
                                    palaceholder="Search by activity, user"
                                />
                                <div className="fR filterbox">
                                <a
                                    href="javascript:void(0);"
                                    className="filterDrop"
                                    onClick={openFilter}
                                >
                                    <img src={filterSvg} />
                                    <h3>Filter</h3>
                                    <img src={filterArrowSvg} />
                                </a>
                                <div
                                    className={`filterDropDown ${showFilterModal === true && "open"
                                        }`}
                                >
                                    <div className="filterDropHead" ref={filterRef}>
                                        <h2>
                                            Filter{" "}
                                            <a
                                                href="javascript:void(0);"
                                                className="viewwLink"
                                                onClick={clearAllFilter}
                                            >
                                                Clear All
                                            </a>
                                        </h2>
                                    </div>
                                    <div className="filterDropBody" ref={filterOptionsRef}>
                                        <div
                                            className={`filterDropClose ${showTypeFilter === true && "open"
                                                }`}
                                        >
                                            <h3>
                                                <a
                                                    href="javascript:void(0);"
                                                    onClick={() => showFilterDropDown("type")}
                                                >
                                                    {`Portal ${selectedPortal.length > 0
                                                        ? `(${selectedPortal.length})`
                                                        : ""
                                                        }`}{" "}
                                                    <img src={filterArrowSvg} />
                                                </a>
                                            </h3>
                                            <div className="filtercheckbox">
                                                <div className="checkbox fnone">
                                                    <input
                                                        type="checkbox"
                                                        id='selectAll'
                                                        checked={selectedAllPortal}
                                                        onClick={(e) =>
                                                            handleAllPortalSelect(e)
                                                        }
                                                    />
                                                    <label htmlFor='selectAll'>Select All</label>
                                                </div>
                                                {activityStatusTypeList.map((item, index) => (
                                                    <div className="checkbox fnone" key={index}>
                                                        <input
                                                            type="checkbox"
                                                            id={item}
                                                            checked={selectedPortal.includes(item)}
                                                            onClick={(e) =>
                                                                handleAddRemoveFilter("type", item)
                                                            }
                                                        />
                                                        <label htmlFor={item}>{titleCase(item)}</label>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                            <div className="inventory-tableTabs">
                                <div className="table-container table-inventory">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                {/* <th>Sr no</th> */}
                                                <th>Notification</th>
                                                <th>User</th>
                                                <th>Date</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {!loading ? (
                                                <>
                                                    {activityStatusList &&
                                                        activityStatusList.length > 0 ? (
                                                        <>
                                                            {activityStatusList.map((activity, key) => {
                                                                return (
                                                                    <tr key={key}>
                                                                        {/* <td>
                                                                            {key + 1 + (currentPage - 1) * 10}
                                                                        </td> */}
                                                                        <td>
                                                                            {activity.message
                                                                                ?
                                                                                activity.type === "brandUpcomingCampaign" ?
                                                                                    <>
                                                                                        {activity.activityData.campaignName}'s Upcoming Campaign was accepted by
                                                                                        <a href="javascript:void(0);" className="greenTX" onClick={() => getSchoolList(activity.activityData.acceptedSchools, 'accepted')}> {activity.activityData.numOfAcceptedSchools} Schools </a>
                                                                                        & Rejected by
                                                                                        <a href="javascript:void(0);" className="orangeTx" onClick={() => getSchoolList(activity.activityData.rejectedSchools, 'rejected')}> {activity.activityData.numOfRejectedSchools} Schools.</a>
                                                                                    </>
                                                                                    :
                                                                                    activity.message
                                                                                :
                                                                                "--"
                                                                            }
                                                                        </td>
                                                                        <td>{ activity.performerName || "-" }</td>
                                                                        <td>
                                                                            {
                                                                                moment(activity.createdOn).format('D MMM, YYYY HH:mm A')
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })}
                                                        </>
                                                    ) : (
                                                        <tr>
                                                            <td colSpan={7}>No Activity found</td>
                                                        </tr>
                                                    )}
                                                </>
                                            ) : (
                                                [...Array(10)].map((el, index) => {
                                                    return (
                                                        <tr>
                                                            {[...Array(3)].map((el, index) => {
                                                                return (
                                                                    <td key={index}>
                                                                        <div className="content-loader-block"></div>
                                                                    </td>
                                                                );
                                                            })}
                                                        </tr>
                                                    );
                                                })
                                            )}
                                        </tbody>
                                    </table>
                                    {totalPage &&
                                        totalPage.length > 1 ?
                                        <Pagination
                                            currentPage={currentPage}
                                            totalPage={totalPage}
                                            onPageChange={onPageChange}
                                            onNextClick={onNextClick}
                                            onPrevClick={onPrevClick}
                                            loading={loading}
                                        // cityFilterModal={cityFilterModal}
                                        />
                                        :
                                        ""
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <SchoolList
                showSchoolModal={showSchoolModal}
                closeSchoolModal={() => setShowSchoolModal(false)}
                onNextPage={onNextPage}
                onPrevPage={onPrevPage}
                onPageChange={onSchoolPageChange}
                schoolList={campaignSchoolList}
                totalPage={schoolTotalPage}
                isActivityStatus={true}
                schoolListTitle={schoolListTitle}
            />
        </>
    );
}
export default ActivityStatusList
