import React, { useState, useContext, useEffect, useRef } from "react"

import { Link, useHistory } from "react-router-dom"

import AppContextObject from "../../../../common/context/common"

import search from "../../../../assets/images/search.svg"
import supportBrand from "../../../../assets/images/supportBrand.svg"
import notification from "../../../../assets/images/noti-bell.svg"
import gearsvg from "../../../../assets/images/gear-svg.svg"
import nikeimg from "../../../../assets/images/nike-logo.svg"
import dotmenu from "../../../../assets/images/dot-menu.svg"
import backBtn from "../../../../assets/images/back-arrow.svg"
import logoPng from "../../../../assets/images/logo.png"
import SearchTopBar from "../../schools/components/searchTopBar"
import blankImg from "../../../../assets/images/blank-inventory-img.jpg"

import moment from "moment"
import toast from "react-hot-toast"
import useClickOutside from "../../../../service/useClickOutside"
import BackendBackBtn from "./backendBackBtn"

function BackendTopBar(props) {
    let {
        redirectPage,
        isNotificationsUpdate,
        from,
        onBrandDelete,
        showDeleteButton
    } = props;
    const context = useContext(AppContextObject)
    const history = useHistory()
    const ref = useRef(null)
    const notificationRef = useRef(null)

    const [userFullName, setUserFullName] = useState()
    const [mainInfoLoading, setMainInfoLoading] = useState(false)
    const [addSearchShow, setAddSearchShow] = useState(false)
    const [showNotification, setNotificationShow] = useState(false)
    const [notificationList, setNotificationList] = useState([])
    const [hasNewNotifications, setHasNewNotifications] = useState(false)
    const [refetchNotification, setRefetchNotification] = useState(false);
    const [notificationOptionsModal, setNotificationOptionsModal] = useState(true);
    const [loggedInUserData, setLoggedInUserData] = useState({})
    useClickOutside(ref, (event) => {
        const el = notificationRef?.current;

        if (!el || el.contains(event.target)) {
            return;
        }
        setNotificationShow(false)
    })
    // For handling inventoryAdded Notifications
    if (typeof Notification !== 'undefined') {
        try {
            Notification.requestPermission().then(permission => {
                if (permission === "granted") {

                }
            })
        } catch (error) {
            // Safari doesn't return a promise for requestPermissions and it                                                                                                                                       
            // throws a TypeError. It takes a callback as the first argument                                                                                                                                       
            // instead.
            if (error instanceof TypeError) {
                Notification.requestPermission(() => {
                });
            } else {
                throw error;
            }
        }
    }
    // if(Notification.permission !== "denied"){
    //     Notification.requestPermission().then(permission => {
    //         if(permission === "granted"){

    //         }
    //     })
    // }
    let [count, setCount] = useState(0);
    let notificationTypes = ["inventoryAdded", "inventoryUpdated", "campaignAdded", "campaignApproaching", "completeCampaign", "campaignRequest", "paymentStatusUpdated", "campaignPaymentPending", "draftCampaignAdded", "campaignStatusReview", "creativeReworkNeeded", "campaignAlbumPublished", "campaignReportAdded", "campaignReportUpdated"]
    // console.log("Notification.permission",Notification.permission, loggedInUserData["webPushNotification"]);
    // if(loggedInUserData["webPushNotification"] === true){
    notificationTypes.map(type => {
        // console.log("notificationTypes>>>",type);
        context.socketManager.socket.removeEventListener(type);
        context.socketManager.socket.on(type, (data) => {
            // if(count === 0 ){
            console.log("data>>>>--", data);
            setCount(1)
            if (loggedInUserData["webPushNotification"] === true) {
                if (Notification.permission === "granted") {
                    showPushNotification(data);
                }
                toast.success(type);
            }
            setRefetchNotification(!refetchNotification)
            // }
            // toast.success(type);
        });
    })
    // }

    // console.log("backendcontext", context) 
    const showPushNotification = (data) => {
        // console.log("data>>>>showPushNotification>>>",data);
        const notification = new Notification("New notification from WISR", {
            body: data.message,
            timestamp: data.timestamp,
            image: logoPng,
            icon: logoPng,
            badge: logoPng,
            data: data.meta,
        })

        notification.onclick = (e) => {
            window.location.href = "http://localhost:4000/brands/brand-details/campaigns"
            setCount(0)
        }
    }
    // For handling inventoryAdded Notifications

    useEffect(() => {
        getUserName()
    }, [])

    useEffect(() => {
        getUserNotificationDetails()
    }, [isNotificationsUpdate])

    useEffect(() => {
        fetchUserNotifications()
    }, [refetchNotification])

    const getUserNotificationDetails = async () => {
        let resp = await context.apis[context.user.role].getLoggedInUser(
            context.validator, {}
        )
        if (resp && resp.done) {
            setLoggedInUserData(resp.response)
        }
    }


    const getUserName = async () => {
        // setMainInfoLoading(true)
        // let resp = await context.apis[context.user.role].getSchoolOverview(
        //     context.validator, {}
        //     )
        // if(resp && resp.done){
        //     setMainInfoLoading(false)
        //     setSchoolInfo({
        //         name: resp?.response?.schoolName,
        //         state: resp?.response?.state
        //     })
        // } else setMainInfoLoading(false)
        setUserFullName(context.user.fullName)
    }

    const fetchUserNotifications = async () => {
        let resp = await context.apis[context.user.role].getUserNotifications(
            context.validator, { user: context.user._id, role: context.user.role }
        )
        console.log("notificaiton resp--->>>>", resp);
        if (resp && resp.done) {
            setNotificationList(resp.data)
            let found = resp.data && resp.data.find(nots => nots.seen === false)
            if (found) {
                setHasNewNotifications(true)
            } else {
                setHasNewNotifications(false)
            }
        }
    }

    const showNotificationlist = async () => {
        if (showNotification) {
            setNotificationShow(false)
        } else {
            setNotificationShow(true)
            // setTimeout(async() => {
            for (let notification of notificationList) {
                let resp = await context.apis[context.user.role].updateReadStateOfUserNotification(
                    context.validator, { _id: notification._id }, { seen: true }
                )
                if (resp && resp.done) {

                }
            }
            setRefetchNotification(!refetchNotification)
            // }, 500);

        }
    }

    const handleNotificationClick = async (notification) => {
        let resp = await context.apis[context.user.role].updateReadStateOfUserNotification(
            context.validator, { _id: notification._id }, { seen: true }
        )
        if (resp && resp.done) {
            setRefetchNotification(!refetchNotification)
            setNotificationOptionsModal(false)
            toggleNotificationOptionsModal(notification._id)
        }
    }

    const toggleNotificationOptionsModal = (id, fromOption?: Boolean) => {
        setNotificationOptionsModal(!notificationOptionsModal);
        let optionModal = document.getElementById(id);
        if (notificationOptionsModal === true && fromOption === true) {
            optionModal.style.display = "revert";
        } else {
            optionModal.style.display = "none";
        }
    };

    const reviewRedirect = () => {
        history.push("/brands/brand-details/campaigns")
    }

    const showsearchlist = () => {
        setAddSearchShow(true)
    }

    const markAsUnread = async (notification) => {
        let resp = await context.apis[context.user.role].updateReadStateOfUserNotification(
            context.validator, { _id: notification._id }, { seen: false }
        )
        if (resp && resp.done) {
            setRefetchNotification(!refetchNotification)
            setNotificationOptionsModal(false)
            toggleNotificationOptionsModal(notification._id)
        }
    }

    const removeNotification = async (notification) => {
        let resp = await context.apis[context.user.role].removeUserNotification(
            context.validator, { _id: notification._id }, {}
        )
        if (resp && resp.done) {
            setRefetchNotification(!refetchNotification)
            setNotificationOptionsModal(false)
            toggleNotificationOptionsModal(notification._id)
        }
    }

    const muteNotificationsByType = async (notification) => {
        let resp = await context.apis[context.user.role].muteUserNotification(
            context.validator, { _id: notification._id, user: context.user._id }, {}
        )
        if (resp && resp.done) {
            setRefetchNotification(!refetchNotification)
            setNotificationOptionsModal(false)
            toggleNotificationOptionsModal(notification._id)
        }
    }

    return (
        <>
            <div className="col-xl-6 col-lg-6 col-md-6 col-xs-6">
                {from !=="non-wisr-school" && <BackendBackBtn />}
                {
                    !redirectPage ?
                        <>
                            {from === 'dashboard' && <h2 className="htx1 clearfix">Welcome, {userFullName ? `${userFullName}` : <div className="content-loader"></div>}</h2>}
                        </>
                        :
                        <>
                            
                            {
                            from === "hideBackButtonScrapedDetail" ?
                            <></>
                            :
                            from === "non-wisr-school-detail" ?
                                <a className="backbtn dblock backheading" href="javascript:void(0);" onClick={() => history.push("/backend/backend-details/nonWisr-school-list")}>
                                    <img src={backBtn} />
                                    {/* {from} */}
                                </a>
                                :
                                from === "assignFOS" ? 
                                    <a className="backbtn dblock backheading" href="javascript:void(0);" onClick={() => history.goBack()}>
                                        <img src={backBtn} />
                                        {/* {from} */}
                                    </a>
                                :
                                from === "onboarded-school" ?
                                    <a className="backbtn dblock backheading" href="javascript:void(0);" onClick={() => context.user.role === "backendFOS" ? history.goBack() : history.push("/backend/backend-details/school-list")}>
                                        <img src={backBtn} />
                                        {/* {from} */}
                                    </a>
                                :
                                context.user.role === "backendFOS" && from !=="campaignReporting" && (from !== 'onboarded-school' || from !=="non-wisr-school") ?
                                <a className="backbtn dblock backheading">
                                    {/* <img src={backBtn} /> */}
                                    {from}
                                </a>
                                :
                                <>
                                    {context.user.role === "backendFOS" && (from === 'onboarded-school' || from ==="non-wisr-school" || from ==="campaignReporting") ?
                                        <a className="backbtn dblock backheading" href="javascript:void(0);" onClick={() => history.goBack()}>
                                            <img src={backBtn} />
                                            {/* {from} */}
                                        </a>
                                        :
                                        <a className="backbtn dblock backheading textInherit" href="javascript:void(0);">
                                            <img src={backBtn}  onClick={() => history.goBack()}/>
                                            {(from==="onboarded-school" || from ==="non-wisr-school") ? "" : from}
                                            {showDeleteButton && <a className="" style={{ cursor: "pointer", color: "black", fontSize: '16px', fontWeight: "normal" }} onClick={onBrandDelete}><i className="fa fa-trash" style={{cursor: "pointer", marginLeft: "30px"}}></i> Delete</a>}
                                        </a>
                                    }
                                </>

                            }

                        </>
                    // schoolListTab ?
                    // <h2 className="htx1 clearfix"> Explore Schools on Wisr </h2>
                    // :
                    // isCampaignDetail ?
                    // <Link to="/backend/backend-details/campaigns" className="backbtn dblock">
                    //     <img src={backBtn} />
                    //     Back
                    // </Link>
                    //     :
                    // <Link to="/backend/backend-details/dashboard" className="backbtn dblock">
                    //     <img src={backBtn} />
                    //     Back
                    // </Link>
                }
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-xs-6">
                <div className="sc-ad-rightmenu">
                    <ul>
                        <li><a ref={notificationRef} href="javascript:void(0);" onClick={showNotificationlist}><img src={notification} />{hasNewNotifications && <b></b>}</a>
                            <div ref={ref} className={`dropdownShow showSearchbox ${showNotification === true ? "active" : ""}`}>
                                <div className="notificationtop">
                                    <h4>Notification</h4>
                                    {/* <a href="javascript:void(0);" onClick={()=> history.push("/brands/brand-details/setting")}><img src={gearsvg}/></a> */}
                                </div>
                                <div className="notificationList">
                                    {
                                        notificationList && notificationList.length > 0 ? notificationList.map((notification, index) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    <div className="notificationListview">
                                                        <div className="notification-img">
                                                            <img src={notification.meta && notification.meta.images && notification.meta.images[0] && notification.meta.images[0]?.image || blankImg} />
                                                        </div>
                                                        <div className="notification-title">
                                                            <h4 onClick={() => handleNotificationClick(notification)}>{notification.message}</h4>
                                                            <span>{!notification.seen && <b></b>}{moment(notification.timestamp).fromNow()}</span>
                                                            {
                                                                notification.notificationType === "campaignAdded" && <p>Added {notification.meta.name} Campaign.</p>
                                                            }
                                                            {
                                                                notification.notificationType === "schoolAdded" && <p>{notification.meta.schoolName} Onboarded.</p>
                                                            }
                                                            {
                                                                notification.notificationType === "brandAdded" && <p>{notification.meta.brandName} Brand Onboarded.</p>
                                                            }
                                                            {
                                                                notification.notificationType === "campaignStatusReview" && <p>{notification.meta.brandName}</p>
                                                            }
                                                            {
                                                                notification.notificationType === "creativeReworkNeeded" && <p>{notification.meta.brandName}</p>
                                                            }
                                                        </div>
                                                        <div className="notification-dot">
                                                            <a href="javascript:void(0)" onClick={() => toggleNotificationOptionsModal(notification._id, true)}>
                                                                <img src={dotmenu} />
                                                            </a>
                                                        </div>
                                                        {
                                                            <div className="modal" id={notification._id} style={{ display: "none" }}>
                                                                <div onClick={() => toggleNotificationOptionsModal(notification._id)} className="overlay"></div>
                                                                <div className="modal-content">
                                                                    {
                                                                        notification.seen === false
                                                                            ?
                                                                            <a href="javascript:void(0);" className="notificationListview" onClick={() => handleNotificationClick(notification)}>Mark as Read</a>
                                                                            :
                                                                            <a href="javascript:void(0);" className="notificationListview" onClick={() => markAsUnread(notification)}>Mark as Unread</a>
                                                                    }
                                                                    <a href="javascript:void(0);" className="notificationListview" onClick={() => removeNotification(notification)}>Remove Notification</a>
                                                                    {/* <a href="javascript:void(0);" className="notificationListview" onClick={() => muteNotificationsByType(notification)}>Mute Notifications Like This</a> */}
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </React.Fragment>
                                            )
                                        })
                                            :
                                            <h5>You have 0 Notifications.</h5>
                                    }

                                    {/* <div className="notificationListview">
                            <div className="notification-img"><img src={nikeimg}></img></div>
                            <div className="notification-title">
                                <h4>New Brand Request </h4>
                                <span><b></b>38m ago</span>
                                <p>Nike Product launch campaign</p>
                            </div>
                            <div className="notification-dot"><a href="javascript:void(0)"><img src={dotmenu}/></a></div>
                        </div>
                        <div className="notificationListview">
                            <div className="notification-img"><img src={nikeimg}></img></div>
                            <div className="notification-title">
                                <h4>New Brand Request </h4>
                                <span>1hr ago</span>
                                <p className="tx-orange">Review</p>
                            </div>
                            <div className="notification-dot"><a href="javascript:void(0)"><img src={dotmenu}/></a></div>
                        </div> */}
                                </div>
                            </div>
                        </li>
                        {/* <li><Link to="/brands/brand-details/support"><img src={supportBrand}/></Link></li> */}
                        {/* <li><a href="javascript:void(0);" onClick={addInventory}><img src={addbx}/></a></li> */}
                    </ul>
                </div>
            </div>

            {/* <div className={`dropdownShow showNotificatonbox ${addSearchShow === true ? "active" : ""}`}>
                    <div className="searchboxtop">
                    <div className="searchinput">
                        <a href="javascript:void(0);"><img src={search}/></a> 
                        <input type="text" placeholder="Nike" /> 
                    </div> 
                    <div className="searchlist">
                        <h3>Campaigns</h3>
                        <ul>
                            <li><a href="javascript:void(0);"><span><img src={nikeimg}></img></span> Nike - Product Launch</a></li>
                            <li><a href="javascript:void(0);"><span><img src={nikeimg}></img></span> Nike - Summer</a></li>
                            <li><a href="javascript:void(0);"><span><img src={nikeimg}></img></span> Nike - Diwali 2021</a></li>
                        
                        </ul>
                    </div> 
                    <div className="searchlist searchlistimg">
                        <h3>Inventory Occupied</h3>
                        <ul>
                            <li><a href="javascript:void(0);">Auditorium - Nike Product Launch</a></li>
                            <li><a href="javascript:void(0);">Libary - Nike Summer</a></li>
                        </ul>
                    </div>
                    </div>
                </div> */}
            <SearchTopBar
                addSearchShow={addSearchShow}
            />
        </>
    )
}

export default BackendTopBar
