import React, { useState } from "react";

const EventTableRow = ({ eventData, keyNo }) => {
    const [openSpan, setOpenSpan] = useState(false);
    console.log('eventData===>', eventData)
    return (
        <>
            <tr>
                <td>
                    <div className="checkbox fnone">
                        <input type="checkbox" id="html-2" checked disabled />
                        <label htmlFor="html-2"></label>
                    </div>
                </td>
                <td>{keyNo}</td>
                <td>{eventData.name}</td>
                <td>{eventData.attributes.length}</td>
                <td><span className="other-arrow align-R fR" onClick={() => setOpenSpan(!openSpan)}><svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M11 1L6 6L1 1" stroke="#8C8C8C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /> </svg></span></td>
            </tr>
            {openSpan && <tr>
                <td colSpan={5} className="pad-border">
                    <div className="available-placements">
                        <h4 className="htx3">Available Inventory Placements</h4>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col"></th>
                                    <th scope="col">inventory name</th>
                                    {/* <th scope="col">inventory impressions</th>
                                    <th scope="col">inventory Quantity</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {eventData.attributes.map((attribute, key) => {
                                    return (
                                        <tr key={key}>
                                            <td>
                                                <div className="checkbox fnone">
                                                    <input type="checkbox" id="place-1" checked disabled/>
                                                    <label htmlFor="place-1"></label>
                                                </div>
                                            </td>
                                            <td>{attribute}</td>
                                            {/* <td>-</td>
                                            <td>-</td> */}
                                        </tr>
                                    )
                                })}
                                
                            </tbody>
                        </table>
                    </div>
                </td>
            </tr>}
        </>
    )
}
export default EventTableRow;