import { useEffect, useState, useContext, useRef } from "react";

import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";

import SimpleReactValidator from "simple-react-validator";

import AppContextObject from "../../../common/context/common";
import { PublicContextInitiator } from "../../apis/publicContextInitiator";

import menu from "../../../assets/images/menu.svg";
import waveBootNew from "../../../assets/images/wave-1.png";
import helpImg from "../../../assets/images/help.svg";

import { validation } from "../../../service/validators";
import Textfield from "../../../components/formFields/textField/textField";
import TextArea from "../../../components/formFields/textArea";
import TextfieldWithPrefix from "../../../components/formFields/textFieldWithPrefix/textFieldWithPrefix";
import SingleSelect from "../../../components/formFields/singleSelect/singleSelect";
import OTPVerifyModal from "../../../components/addon/otpVerifyModal";
import ConfirmAlert from "../../../components/addon/confirmAlert";
import AlertDialog from "../../../components/addon/alertDialog/alertDialog";
import Select from "react-select";
// import DataContext from "../../../service/contextProvider"

import {
	compareObjs,
	hasOnlyLettersAndSpaces,
	hasOnlyLettersNumberAndSpaces,
	specialCharOnly,
	titleCase,
	validateActName,
	validateFullName,
	validatePrincipalName,
	validateSchoolName,
} from "../../../service/helper";

import errorSVG from "../../../assets/images/error.svg";
import logoNew from "../../../assets/images/logo-new.svg";

import config from "../../../service/config";
import TextFieldWithValidation from "../../../components/formFields/textField/textFieldWithValidation";
import { formatDiagnosticsWithColorAndContext } from "typescript";
import TextFieldWithPrefix from "../../../components/formFields/textFieldWithPrefix/textFieldWithPrefix";
import { DebounceInput } from "react-debounce-input";
import Loader from "../../../components/addon/loader";
import SchoolOnboardProgress from "../schools/components/schoolOnboardProgress";
import toast from "react-hot-toast";
import BackendTopBar from "./components/backendTopBar";
import NumberField from "../../../components/formFields/numberField";
import DateField from "../../../components/formFields/dateField";
import CustomDatePicker from "../../../components/formFields/datePicker";

const EditScrapedSchool = (props) => {
	const { setShowSidemenu, showSidemenu, toggleSidemenu } = props;
	const context = useContext(AppContextObject);
	// const location = useLocation();
	const history = useHistory();
	const otherBoardReference = useRef({
		validateField: () => {
			return;
		},
		showMessages: () => {
			return;
		},
		hideMessages: () => {
			return;
		},
	});
	// const otherBoardReference = useRef()
	const [formValidator] = useState(
		useRef(
			new SimpleReactValidator({
				element: (message, className) => (
					<span className="valError">
						<img src={errorSVG} />
						{message === "The phone field is required."
							? "The contact number field is required."
							: message === "The role field is required."
							? "The designation field is required."
							: message}
					</span>
				),
			})
		)
	);
	const [overlay, setOverlay] = useState(false);
	const [showMakeWisr, setShowMakeWisr] = useState(false);
	const [formData, setFormData] = useState({
		fullName: "",
		Board: "",
		language: "",
		pincode: "",
		state: "",
		city: "",
		address: "",
		schoolName: "",
		otherContact: "",
		email: "",
		phone: "",
		role: "",
		source: "",
		comment: "",
		noOfBoys: 0,
		noOfGirls: 0,
		totalStudents: 0,
		hasSchoolOnboarded: false,
		callingActivityDetails: [
			{
				dateTime: "",
				callOutcome: "",
				contactNumber: "",
				attemptNumber: "",
				remarks: "",
			},
		],
	});

	const [currentAttemptNumber, setCurrentAttemptNumber] = useState(1);

	const [boardOptions] = useState(
		config.boardOptions.filter((item) => item.value.toLowerCase() !== "custom")
	);
	const [langOptions] = useState(
		config.languages.filter((item) => item.value.toLowerCase() !== "custom")
	);
	const [otherBoardOfStudy, setOtherBoardOfStudy] = useState({
		boardOfStudy: "",
	});
	const [isPinCodeValid, setIsPinCodeValid] = useState(true);
	const [loading, setLoading] = useState(true);
	const [isEdit, setIsEdit] = useState(false);
	const [submittingData, setSubmittingData] = useState(false);
	const [schoolId, setSchoolId] = useState("");
	const [showFaq, setShowFaq] = useState(false);

	const [isContactEmailMandatory, setIsContactEmailMandatory] = useState(false);
	const [outComeList, setOutComeList] = useState([]);

	const [addMorFormData, setAddMorFormData] = useState([
		{
			dateTime: "",
			attemptNumber: "1", // The initial attempt number is set to 1
			callOutcome: "",
			contactNumber: "",
			remarks: "",
		},
	]);
	const [hideAddMoreButton, setHideAddMoreButton] = useState(true);
	const [hideRemoveButton, setHideRemoveButton] = useState(true);

	const [attempts, setAttempts] = useState(0);

	const [showCallingDetais, setShowCallingDetais] = useState(false);
	const [contactNumber, setContactNumber] = useState("");

	const [outcomeError, setOutcomeError] = useState(false);
	const [dateTimeError, setDateTimeError] = useState(false);
	const [contactNumberError, setContactNumberError] = useState(false);
	const [dateTimeErrorIndex, setDateTimeErrorIndex] = useState(-1);

	const [addMorFormData2, setAddMorFormData2] = useState();
	const [isFutureDateError, setIsFutureDateError] = useState(false);

	const codeOptions = [{ label: "+91", value: "+91" }];
	const outComeOptions = [
		{ label: "Select", value: "" },
		...outComeList.map((ocl) => {
			return { label: ocl.name, value: ocl._id };
		}),
	];
	console.log("outcomeoptions = ", outComeOptions);
	console.log("outComeList = ", outComeList);
	const [code, setCode] = useState("+91");

	const useForceUpdate = () => {
		const [value, setValue] = useState(0);
		return () => setValue((value) => value + 1);
	};
	const forceUpdate1 = useForceUpdate();
	useEffect(() => {
		setShowSidemenu(false);
		if (history.location.state) {
			let data: any = history.location.state;
			if (data.isEdit && data._id) {
				setIsEdit(true);
				fetchSchoolDetails(data._id);
				setSchoolId(data._id);
				setShowMakeWisr(true);
			} else {
				setLoading(false);
			}
		} else {
			setLoading(false);
		}
	}, []);
	useEffect(() => {
		if (!loading) {
			fetchCityState();
		}
	}, [formData.pincode]);

	const fetchSchoolDetails = async (_id) => {
		setLoading(true);
		let resp = await context.apis[
			context.user.role
		].getScrappedSchoolDetails(context.validator, { _id });

		if (resp && resp.done) {
			let regex = new RegExp(/principal/, "i");
			setFormData({
				fullName: resp.response.contactName,
				Board: resp.response.Board,
				language: resp.response.LanguageOfInstruction,
				pincode:
					(resp.response.pincode && resp.response.pincode.toString()) || "",
				state: titleCase(resp.response.state),
				city: titleCase(resp.response.city),
				address: resp.response.address,
				schoolName: resp.response.schoolName,
				email: resp.response.contactEmail,
				otherContact: resp.response.Contact,
				phone: resp.response.Valid_Contact,
				source: titleCase(resp.response.Source),
				role: regex.test(resp.response.designation)
					? "schoolPrincipal"
					: "schoolAdmin",
				comment: resp.response.commentsOrInventoryDetails,
				noOfBoys: resp.response.totalBoys || 0,
				noOfGirls: resp.response.totalGirls || 0,
				totalStudents: resp.response.totalReach || 0,
				hasSchoolOnboarded: resp.response.hasSchoolOnboarded || false,
				callingActivityDetails:
					resp.response.callingActivityDetails &&
					resp.response.callingActivityDetails.length
						? [...resp.response.callingActivityDetails]
						: [
								{
									dateTime: "",
									callOutcome: "",
									contactNumber: "",
									attemptNumber: "1",
									remarks: "",
								},
						  ],
			});
		}

		if (
			resp.response.callingActivityDetails &&
			resp.response.callingActivityDetails.length
		) {
			setShowCallingDetais(true);
			setAddMorFormData(
				resp.response.callingActivityDetails &&
					resp.response.callingActivityDetails.length
					? [...resp.response.callingActivityDetails]
					: [
							{
								dateTime: "",
								callOutcome: "",
								contactNumber: resp.response.Valid_Contact
									? resp.response.Valid_Contact
									: "",
								attemptNumber: "1",
								remarks: "",
							},
					  ]
			);
			setHideAddMoreButton(false);
		} else {
			setAddMorFormData([
				{
					dateTime: "",
					callOutcome: "",
					contactNumber: resp.response.Valid_Contact
						? resp.response.Valid_Contact
						: "",
					attemptNumber: "1",
					remarks: "",
				},
			]);
		}
		setLoading(false);
	};

	const onStateCityChange = (dataState, dataCity) => {
		dataCity = dataCity.toLowerCase();
		setFormData({ ...formData, state: dataState, city: dataCity });
	};

	const fetchCityState = async () => {
		if (formData.pincode.length == 6) {
			try {
				let publicContextInitiator = new PublicContextInitiator(context);
				let resp = await publicContextInitiator.getPincodeDetails(
					context.validator,
					{ pincode: parseInt(formData.pincode) },
					{}
				);
				if (resp && resp.done) {
					onStateCityChange(resp.response.stateName, resp.response.district);
					setIsPinCodeValid(true);
				} else {
					const error = validation({ pincode: "invalid" });
					if (error) {
						setIsPinCodeValid(false);
					}
				}
			} catch (error) {
				console.log("error", error);
			}
		} else {
			setIsPinCodeValid(true);
			onStateCityChange("", "");
		}
	};

	const onFormDataChange = async (event) => {
		if (event.target.id === "state") {
			if (hasOnlyLettersAndSpaces(event.target.value)) {
				setFormData({
					...formData,
					[event.target.id]: event.target.value,
				});
			}
		} else if (event.target.id === "city") {
			if (hasOnlyLettersNumberAndSpaces(event.target.value)) {
				setFormData({
					...formData,
					[event.target.id]: event.target.value,
				});
			}
		} else {
			setFormData({ ...formData, [event.target.id]: event.target.value });
		}
		if (event.target.id === "pincode") {
		}
	};

	const onLangChange = (data) => {
		setFormData({
			...formData,
			language: data.value,
		});
	};

	const onBoardChange = (data) => {
		setFormData({
			...formData,
			Board: data.value,
		});
	};

	const onRoleChange = (data) => {
		setFormData({
			...formData,
			role: data.value,
		});
	};
	const onOtherBoardChange = (e) => {
		setOtherBoardOfStudy({ boardOfStudy: e.target.value });
	};

	const submitForm = async (event) => {
		event.preventDefault();

		const isEmptyDate = !addMorFormData.every((item) => item.dateTime);

		const isEmptyCallOutcome = !addMorFormData.every(
			(item) => item.callOutcome
		);

		const isEmptyContactNumber = !addMorFormData.every(
			(item) => item.contactNumber
		);

		console.log("isEmptyDate =", isEmptyDate);
		console.log("isEmptyCallOutcome =", isEmptyCallOutcome);
		console.log("isEmptyContactNumber =", isEmptyContactNumber);

		if (showCallingDetais) {
			const hasInvalidDateTime = addMorFormData.some((item, index) => {
				if (index > 0) {
					const newDateTime = new Date(item.dateTime).getTime();
					const prevDateTime = new Date(
						addMorFormData[index - 1].dateTime
					).getTime();
					return newDateTime < prevDateTime;
				}
				return false;
			});

			if (hasInvalidDateTime) {
				setDateTimeErrorIndex(
					addMorFormData.findIndex((item, index) => {
						if (index > 0) {
							const newDateTime = new Date(item.dateTime).getTime();
							const prevDateTime = new Date(
								addMorFormData[index - 1].dateTime
							).getTime();
							return newDateTime < prevDateTime;
						}
						// setSubmittingData(true);
						return false;
					})
				);
				return; // Don't submit the form if there are invalid date-time values
			} else {
				setDateTimeErrorIndex(-1);
			}
			if (!dateTimeError && !outcomeError && !contactNumberError) {
				setSubmittingData(true);

				// ... existing code ...

				setSubmittingData(false);
			}

			if (isEmptyDate || isEmptyCallOutcome || isEmptyContactNumber) {
				setDateTimeError(isEmptyDate && true);
				setOutcomeError(isEmptyCallOutcome && true);
				setContactNumberError(isEmptyContactNumber && true);
				return;
			}
			// if (isEmptyDate) {
			// 	setDateTimeError(true);
			// 	return;
			// }
			// if (isEmptyCallOutcome) {
			// 	setOutcomeError(true);
			// 	return;
			// }
			// if (isEmptyContactNumber) {
			// 	setContactNumberError(true);
			// 	return;
			// }
		}

		const hasFutureDate = addMorFormData.some(
			(item) => new Date(item.dateTime).getTime() > new Date().getTime()
		);

		if (hasFutureDate) {
			setIsFutureDateError(true);

			return;
		} else {
			setIsFutureDateError(false);
		}
		// New Ends

		setSubmittingData(true);

		// Calculate the attemptNumber based on the number of attempts
		const attemptNumber = attempts + 1;
		setAttempts(attemptNumber);

		let otherBoardValid: any = true;
		const result = formValidator.current.allValid();
		if (formData.Board === "Other") {
			otherBoardValid = otherBoardReference?.current?.validateField();
		}
		if (result === false || otherBoardValid === false) {
			formValidator.current.showMessages();
			setSubmittingData(false);
			forceUpdate1();
			return;
		} else {
			formValidator.current.hideMessages();
			forceUpdate1();
		}
		if (formData.email === "" && formData.phone === "") {
			setIsContactEmailMandatory(true);
			setSubmittingData(false);
			forceUpdate1();
			return;
		} else {
			setIsContactEmailMandatory(false);
		}
		let apiData = {
			schoolName: formData.schoolName,
			Board: formData.Board,
			LanguageOfInstruction: formData.language,
			pincode: formData.pincode,
			state: formData.state,
			city: formData.city,
			address: formData.address,
			contactName: formData.fullName,
			contactEmail: formData.email,
			contactNumber: formData.otherContact,
			Valid_Contact: formData.phone,
			Source: formData.source,
			designation: formData.role,
			totalBoys: formData.noOfBoys,
			totalGirls: formData.noOfGirls,
			totalReach: formData.totalStudents,
			commentsOrInventoryDetails: formData.comment,
			hasSchoolOnboarded: formData.hasSchoolOnboarded,
			callingActivityDetails: addMorFormData,
		};

		let resp;
		if (isEdit) {
			resp = await context.apis[context.user.role].updateScrappedSchool(
				context.validator,
				{ _id: schoolId },
				apiData
			);
		} else {
			resp = await context.apis[context.user.role].updateScrappedSchool(
				context.validator,
				apiData
			);
		}

		if (resp && resp.done) {
			toast.success(
				isEdit ? "School updated successfully. " : "School added successfully. "
			);
			if (isEdit) {
				history.push({
					pathname: "/backend/backend-details/scrapped-school-data-details",
					state: { schoolId: schoolId },
				});
			} else {
				history.push("/backend/backend-details/scrapped-school-data");
			}
		}
		setSubmittingData(false);
	};
	const toggleFaq = () => {
		setShowFaq(!showFaq);
	};
	const handleSchoolOnboardingStatus = (e) => {
		setFormData({
			...formData,
			hasSchoolOnboarded: e.target.checked,
		});
	};

	formValidator.current.rules["schoolName"] = {
		message: "Please enter valid school name.",
		rule: (val) => {
			let returnValue = validateSchoolName(val);

			return returnValue;
		},
	};
	formValidator.current.rules["city_name"] = {
		message: "Please enter valid city name.",
		rule: (val) => {
			let returnValue = context.utils.cityNameRegex.test(val);
			return returnValue;
		},
	};

	const handleKeyPress = (e, index) => {
		// Allow only numeric digits and a few special characters like space, hyphen, and parentheses
		const allowedCharacters = /^[0-9\b]+$/;
		if (!allowedCharacters.test(e.key)) {
			e.preventDefault();
		}

		// Limit to a maximum of 10 characters
		if (contactNumber.length >= 10 && e.key !== "Backspace") {
			e.preventDefault();
		}

		// Check if the current contact number + the new character exceeds 10 digits
		if (formData[index]?.contactNumber.length >= 10 && e.key !== "Backspace") {
			e.preventDefault();
		}
	};

	const handleCallActivityDetails = (id, value, index) => {
		console.log("id = ", id, " value = ", value, "index = ", index);
		const newData = addMorFormData.map((obj, i) => {
			if (i === index) {
				return {
					...obj,
					[id]: value,
				};
			}
			return obj;
		});

		setAddMorFormData(newData);

		// if (id === "dateTime") {
		// 	const newDateTime = new Date(value).getTime();
		// 	const prevDateTime =
		// 		index > 0 ? new Date(addMorFormData[index - 1].dateTime).getTime() : 0;

		// 	if (newDateTime < prevDateTime) {
		// 		setDateTimeErrorIndex(index);
		// 	} else {
		// 		setDateTimeErrorIndex(-1);
		// 	}

		// 	// setDateTimeError(isDateTimeSelected && false);
		// }

		// Working code
		// Check and update error states
		const isDateTimeSelected = newData[index].dateTime !== "";
		const isOutcomeSelected = newData[index].callOutcome !== "";
		const isContactNumberFilled = newData[index].contactNumber !== "";

		if (id === "callOutcome") {
			setOutcomeError(isOutcomeSelected && false);
		} else if (id === "dateTime") {
			setDateTimeError(isDateTimeSelected && false);
		} else if (id === "contactNumber") {
			setContactNumberError(isContactNumberFilled && false);
		}
	};

	const handleAddMore = () => {
		if (addMorFormData.length < 3) {
			// Get the last attemptNumber from the existing addMorFormData array
			const lastAttemptNumber = parseInt(
				addMorFormData[addMorFormData.length - 1].attemptNumber,
				10
			);
			// Increment the lastAttemptNumber to get the next attempt number
			const nextAttemptNumber = lastAttemptNumber + 1;

			setAddMorFormData((addMorFormData) => [
				...addMorFormData,
				{
					dateTime: "",
					attemptNumber: nextAttemptNumber.toString(), // Set the attempt number dynamically
					callOutcome: "",
					contactNumber: formData.phone ? formData.phone : "",
					remarks: "",
				},
			]);
			setHideAddMoreButton(true);
			setHideRemoveButton(false);
		}
	};

	const handleRemoveLast = () => {
		if (addMorFormData.length > 1) {
			const newData = [...addMorFormData];
			newData.pop();
			setAddMorFormData(newData);
			setHideAddMoreButton(false);
			setHideRemoveButton(true);

			setOutcomeError(false);
			setDateTimeError(false);
			setContactNumberError(false);
		}
	};

	const handleSubmit = (event) => {
		event.preventDefault();
	};

	const getOutcomeList = async () => {
		let resp = await context.apis[context.user.role].getCallingOutcomesMaster(
			context.validator
		);
		setOutComeList(resp.response.rows);
	};

	useEffect(() => {
		getOutcomeList();
	}, []);

	const selectOptionSpaceBetween = {
		color: "red",
		backgroundColor: "DodgerBlue",
		paddingBottom: "10px solid transparent",
	};

	return (
		<>
			<div className={`ovelap ${overlay === true ? "active" : ""}`}></div>
			<div className="main-wapper">
				{loading === true ? (
					<Loader />
				) : (
					<div className="container">
						<div className="row">
							<div className="col-xl-3 col-lg-3 col-md-12 col-xs-12 pl-0">
								<div
									className={`sliderbar onboarding-bar newMvp-sidebar ${
										showSidemenu === true ? "no_highlight" : ""
									}`}
								>
									<div className="row logo between-xs bottom-xs">
										<img src={logoNew} alt="logo" />
										<a href="javascript:void(0);" onClick={toggleFaq}>
											Help
										</a>
									</div>
									<SchoolOnboardProgress doNotShow={true} />
									<img src={waveBootNew} className="wavebot" alt="wave" />
								</div>
							</div>
							<div className="col-xl-9 col-lg-9 col-md-12 col-xs-12">
								<div className="content mCustomScrollbar fixhight">
									<div className="rightPart w80 noBack">
										<div className="row">
											<BackendTopBar
												redirectPage={true}
												from="non-wisr-school"
											/>
											<div
												className={`col-xl-12 col-lg-12 col-md-12 col-xs-12`}
											>
												<a
													onClick={() => toggleSidemenu()}
													href="javascript:void(0)"
													className={`hummob ${
														showSidemenu === true ? "no_highlight" : ""
													}`}
												>
													<img src={menu} />
												</a>
												<h2 className="htx1 mb40">Edit Details</h2>
											</div>
											<div
												className={`col-xl-12 col-lg-12 col-md-12 col-xs-12`}
											>
												<Textfield
													maxLength={200}
													formData={formData}
													formKey="schoolName"
													onDataChange={onFormDataChange}
													label="Your School’s Name"
													placeholder="E.g. Delhi Public School"
													isRequired={false}
													formValidator={formValidator}
													validation="required|schoolName"
													isDisabled={true}
												/>
											</div>
											<div className="col-xl-6 col-lg-6 col-md-6 col-xs-12">
												<Textfield
													formData={formData}
													formKey="Board"
													maxLength={6}
													onDataChange={onFormDataChange}
													label="Board Of Study"
													placeholder="Board Of Study"
													isRequired={false}
													formValidator={formValidator}
													validation=""
													isDisabled={true}
													isPincodeValid={isPinCodeValid}
												/>
											</div>
											<div className="col-xl-6 col-lg-6 col-md-6 col-xs-12">
												<Textfield
													formData={formData}
													formKey="language"
													maxLength={50}
													onDataChange={onFormDataChange}
													label="Language of Study"
													placeholder="Language of Study"
													isRequired={false}
													formValidator={formValidator}
													validation=""
													isDisabled={true}
												/>
											</div>

											<div className="col-xl-6 col-lg-6 col-md-6 col-xs-12">
												<Textfield
													formData={formData}
													formKey="state"
													onDataChange={onFormDataChange}
													label="State"
													placeholder="Select your State"
													isRequired={false}
													formValidator={formValidator}
													validation=""
													maxLength={50}
													isDisabled={true}
												/>
											</div>
											<div className="col-xl-6 col-lg-6 col-md-6 col-xs-12">
												<Textfield
													formData={formData}
													formKey="city"
													onDataChange={onFormDataChange}
													label="City"
													placeholder="Select your City"
													isRequired={false}
													formValidator={formValidator}
													validation=""
													maxLength={50}
													isDisabled={true}
												/>
											</div>
											<div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
												<div className="forminpt">
													<label>Address</label>
													<DebounceInput
														debounceTimeout={1000}
														id="address"
														placeholder="Enter your School Address"
														value={formData.address}
														onChange={onFormDataChange}
														className={
															formValidator?.current?.messagesShown === true &&
															formValidator?.current?.fields?.["address"] ===
																false
																? "onFocus"
																: ""
														}
													/>
													{formValidator?.current?.message(
														"address",
														formData.address,
														"required"
													)}
												</div>
											</div>
											<div className="col-xl-6 col-lg-6 col-md-6 col-xs-12">
												<Textfield
													formData={formData}
													formKey="pincode"
													maxLength={6}
													onDataChange={onFormDataChange}
													label="Pin Code"
													placeholder="Enter the pin code"
													isRequired={false}
													formValidator={formValidator}
													validation=""
													isPincodeValid={isPinCodeValid}
													isDisabled={true}
												/>
											</div>

											<div className={`col-xl-6 col-lg-6 col-md-6 col-xs-12`}>
												<Textfield
													maxLength={200}
													formData={formData}
													formKey="source"
													onDataChange={onFormDataChange}
													label="Source"
													placeholder="Source"
													isRequired={false}
													formValidator={formValidator}
													validation="required|source"
													isDisabled={true}
												/>
											</div>

											<div className={`col-xl-6 col-lg-6 col-md-6 col-xs-12`}>
												<Textfield
													formData={formData}
													formKey="email"
													onDataChange={onFormDataChange}
													label="Contact Email"
													placeholder="E.g. anju@abcschool.com"
													isRequired={false}
													formValidator={formValidator}
													validation="email"
												/>
											</div>

											<div className={`col-xl-6 col-lg-6 col-md-6 col-xs-12`}>
												<TextfieldWithPrefix
													formData={formData}
													formKey="phone"
													onDataChange={onFormDataChange}
													label="Contact Number"
													placeholder="E.g. 99999 99999"
													isRequired={false}
													formValidator={formValidator}
													validation="phone"
												/>
											</div>

											<div
												className={`col-xl-12 col-lg-12 col-md-12 col-xs-12`}
												style={{
													marginTop: "-30px",
													marginBottom: "20px",
													height: "25px",
												}}
											>
												{isContactEmailMandatory && (
													<span className="valError">
														<img src={errorSVG} />
														Contact email or Contact number field is required.
													</span>
												)}
											</div>

											<div
												className={`col-xl-12 col-lg-12 col-md-12 col-xs-12`}
											>
												<Textfield
													maxLength={200}
													formData={formData}
													formKey="otherContact"
													onDataChange={onFormDataChange}
													label="Other Contact Numbers"
													placeholder="Contact Number With Comma Separated"
													isRequired={false}
													formValidator={formValidator}
													validation="otherContact"
												/>
											</div>
											<div
												className={`col-xl-6 col-lg-6 col-md-6 col-xs-12`}
												style={{
													padding: 0,
													lineHeight: "50px",
												}}
											>
												<h2 className="htx1 mb40">Calling Activity Details </h2>
											</div>

											{!showCallingDetais && (
												<div
													className={`col-xl-6 col-lg-6 col-md-6 col-xs-12`}
													style={{
														padding: 0,
														textAlign: "left",
													}}
												>
													<button
														type="button"
														className="btn btn-orange"
														onClick={() => setShowCallingDetais(true)}
														style={{
															minWidth: "50px",
															padding: "12px 10px!important",
															margin: "0 0 0 10px",
														}}
													>
														Add Details
													</button>
												</div>
											)}

											{/* Text fields start */}
											{console.log("showcalling details = ", showCallingDetais)}
											{showCallingDetais && (
												<div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
													{/* Add details wrapped div */}
													{/* <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 "> */}
													{addMorFormData.map((item, index) => (
														<div key={index}>
															<div
																className="col-xl-12 col-lg-12 col-md-12 col-xs-12"
																style={{
																	marginBottom: "10px",
																}}
															>
																<h4
																	style={{
																		color: "#FE6E00",
																	}}
																>
																	Call {index + 1}
																</h4>
																{/* <div style={{ borderBottom: "1px solid #ccc" }}>
																	{" "}
																</div> */}
															</div>
															<div
																className="row"
																style={{ marginBottom: "0px !important" }}
															>
																<div
																	className={`col-xl-6 col-lg-6 col-md-6 col-xs-12`}
																	style={{ marginBottom: "0px !important" }}
																>
																	{
																		<div className=" tooltip-relative">
																			<DateField
																				formData={formData}
																				name="dateTime"
																				id="dateTime"
																				formKey="dateTime"
																				readOnly={true}
																				minDateTime={
																					index > 0 &&
																					formData.callingActivityDetails[
																						index - 1
																					].dateTime
																				}
																				onDataChange={(e) =>
																					handleCallActivityDetails(
																						"dateTime",
																						e.target.value,
																						index
																					)
																				}
																				// min={formData.callingActivityDetails[index-1].dateTime}
																				onBlur={() =>
																					setIsFutureDateError(false)
																				} // Reset error on blur
																				label="Date"
																				placeholder="Select Date"
																				// isFutureDateError={isFutureDateError} // Pass error status as prop
																				isFutureDateError={
																					isFutureDateError ||
																					dateTimeErrorIndex === index
																				} // Combine with dateTimeErrorIndex
																				isRequired={false}
																				value={item.dateTime}
																				isDisabled={
																					formData.callingActivityDetails[index]
																						?.dateTime
																						? true
																						: false
																				}
																				// Disable typing by preventing any key press events
																				// onKeyPress={(e) => e.preventDefault()}
																			/>
																		</div>
																	}
																	<div style={{ marginTop: "-30px" }}>
																		{dateTimeError &&
																			!formData.callingActivityDetails[index]
																				?.dateTime && (
																				<span className="valError">
																					<img src={errorSVG} />
																					Please select valid date and time.
																				</span>
																			)}

																		{dateTimeErrorIndex === index && (
																			<span className="valError">
																				<img src={errorSVG} />
																				{/* Date-time cannot be earlier than the
																				previous entry. */}
																				Please select valid date and time.
																			</span>
																		)}
																	</div>
																</div>

																{/* above is the contact number take this from here */}

																{/* <div
																	className={`col-xl-6 col-lg-6 col-md-6 col-xs-12`}
																> */}
																<div
																	className={`col-xl-6 col-lg-6 col-md-6 col-xs-12 forminpt ${
																		formData.callingActivityDetails[index]
																			?.callOutcome
																			? "disabled"
																			: ""
																	}`}
																	style={
																		formData.callingActivityDetails[index]
																			?.callOutcome
																			? {
																					pointerEvents: "none",
																					opacity: 0.6,
																			  }
																			: {}
																	}
																>
																	<div
																		className={` tooltip-relative ${
																			outcomeError ? "error" : ""
																		}`}
																		style={{ marginBottom: "0px !important" }}
																	>
																		<label>Outcome</label>
																		<div className="formset">
																			<Select
																				id={`callOutcome`}
																				className="phcode"
																				options={outComeOptions}
																				onChange={(e) =>
																					handleCallActivityDetails(
																						"callOutcome",
																						e.value,
																						index
																					)
																				}
																				// value={item.callOutcome}
																				value={outComeOptions.filter(
																					(option) => {
																						return (
																							option.value === item.callOutcome
																						);
																					}
																				)}
																				disabled={
																					formData.callingActivityDetails[index]
																						?.callOutcome
																						? true
																						: false
																				}
																			>
																				{/* <option value="">Select</option> */}
																				{/* {outComeList.map((finalList) => (
																				<option
																					key={finalList._id}
																					value={finalList._id}
																				>
																					{finalList.name}
																				</option>
																			))} */}
																			</Select>
																		</div>
																		{outcomeError &&
																			!formData.callingActivityDetails[index]
																				?.callOutcome && (
																				<span className="valError">
																					<img src={errorSVG} />
																					Please select valid outcome.
																				</span>
																			)}
																	</div>
																</div>
															</div>
															{/* first Row Ends*/}
															{/* Second Row */}
															<div className="row">
																<div
																	className={`col-xl-6 col-lg-6 col-md-6 col-xs-12 forminpt ${
																		formData.callingActivityDetails[index]
																			?.contactNumber
																			? "disabled"
																			: ""
																	}`}
																	style={
																		formData.callingActivityDetails[index]
																			?.contactNumber
																			? {
																					pointerEvents: "none",
																					opacity: 0.6,
																			  }
																			: {}
																	}
																>
																	<div
																		className=" tooltip-relative"
																		style={{ marginBottom: "0px !important" }}
																	>
																		<label>Contact Number</label>
																		<div className="formset">
																			<div className="custom-select">
																				<Select
																					className="phcode"
																					options={codeOptions}
																					onChange={(data) =>
																						setCode(data.value)
																					}
																					value={codeOptions.filter(
																						(option) => {
																							return option.value === code;
																						}
																					)}
																				/>
																			</div>

																			<input
																				id="contactNumber"
																				name="contactNumber"
																				pattern="[1-9]{1}[0-9]{9}"
																				value={item.contactNumber}
																				maxLength={10}
																				onChange={(e) =>
																					handleCallActivityDetails(
																						"contactNumber",
																						e.target.value,
																						index
																					)
																				}
																				onKeyPress={(e) =>
																					handleKeyPress(e, index)
																				}
																				placeholder="Contacted Number"
																				disabled={
																					formData.callingActivityDetails[index]
																						?.contactNumber
																						? true
																						: false
																				}
																			/>
																		</div>
																		{contactNumberError &&
																			!formData.callingActivityDetails[index]
																				?.contactNumber && (
																				<span className="valError">
																					<img src={errorSVG} />
																					Please enter valid contact number.
																				</span>
																			)}
																	</div>
																</div>
																{/* put here contact number */}

																{/* do not remove calloutcome from remarks */}

																{/* <div
																	className={`col-xl-6 col-lg-6 col-md-6 col-xs-12 ${
																		formData.callingActivityDetails[index]
																			?.callOutcome
																			? "disabled"
																			: ""
																	}`}
																	style={
																		formData.callingActivityDetails[index]
																			?.callOutcome
																			? {
																					pointerEvents: "none",
																					opacity: 0.6,
																			  }
																			: {}
																	}
																> */}

																<div
																	className={`col-xl-6 col-lg-6 col-md-6 col-xs-12 forminpt ${
																		formData.callingActivityDetails[index]
																			?.callOutcome
																			? "disabled"
																			: ""
																	}`}
																	style={
																		formData.callingActivityDetails[index]
																			?.callOutcome
																			? {
																					pointerEvents: "none",
																					opacity: 0.6,
																			  }
																			: {}
																	}
																>
																	<div
																		className="forminpt tooltip-relative"
																		style={{ marginBottom: "0px !important" }}
																	>
																		<label>Remarks</label>
																		<textarea
																			name="remarks"
																			id="remarks"
																			placeholder="Enter remarks"
																			value={item.remarks}
																			onChange={(e) =>
																				handleCallActivityDetails(
																					"remarks",
																					e.target.value,
																					index
																				)
																			}
																			disabled={
																				formData.callingActivityDetails[index]
																					?.callOutcome
																					? true
																					: false
																			}
																			maxLength={500}
																		></textarea>
																	</div>
																</div>
																{/* </div> */}
															</div>
															{/* till here */}
															{/* <div style={{ borderBottom: "1px solid #ccc" }}>
																{" "}
															</div> */}
														</div>
													))}
													{/* </div> */}
													{formData.callingActivityDetails.length >= 1 &&
														formData.callingActivityDetails.length < 3 &&
														!hideAddMoreButton && (
															<button
																type="button"
																className="btn btn-orange"
																onClick={handleAddMore}
																style={{
																	minWidth: "40px",
																	width: "40px",
																	height: "40px",
																	padding: "10px 0px 5px 0px",
																	// padding: "14px 10px 10px 10px",
																	// margin: "0 0 0 10px",
																}}
															>
																<svg
																	width="12"
																	height="12"
																	viewBox="0 0 12 12"
																	fill="none"
																	xmlns="http://www.w3.org/2000/svg"
																>
																	{" "}
																	<path
																		d="M6 1V11"
																		stroke="white"
																		stroke-width="1.5"
																		stroke-linecap="round"
																	/>{" "}
																	<path
																		d="M1 6L11 6"
																		stroke="white"
																		stroke-width="1.5"
																		stroke-linecap="round"
																	/>{" "}
																</svg>
															</button>
														)}
													{!hideRemoveButton &&
														formData.callingActivityDetails.length < 3 && (
															<button
																type="button"
																className="btn btn-orange"
																onClick={handleRemoveLast}
																// style={{
																// 	minWidth: "50px",
																// 	padding: "14px 10px 10px 10px",
																// 	margin: "0 0 0 10px",
																// }}
																style={{
																	minWidth: "40px",
																	width: "40px",
																	height: "40px",
																	padding: "10px 0px 5px 0px",
																}}
															>
																<svg
																	xmlns="http://www.w3.org/2000/svg"
																	version="1.1"
																	width="12"
																	height="12"
																	fill="none"
																	viewBox="0 0 12 12"
																>
																	<path
																		d="M1 6L11 6"
																		stroke-linecap="round"
																		stroke="white"
																		stroke-width="1.5"
																	/>
																</svg>
															</button>
														)}
												</div>
											)}

											{/* Text fields end */}

											<div
												className="row col-xl-12 col-lg-12 col-md-12 col-xs-12 end-md"
												style={{ marginTop: "40px" }}
											>
												<div className="forminpt">
													<input
														onClick={submitForm}
														name={isEdit ? "Save" : "Submit"}
														value={isEdit ? "Save" : "Submit"}
														className={`btn btn-orange datasubmit`}
														type="button"
														disabled={submittingData}
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
		</>
	);
};

export default EditScrapedSchool;
