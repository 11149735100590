
const MessageSVG = () => {

    return (
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.9333 11.4556C15.9333 11.8956 15.7585 12.3177 15.4473 12.6288C15.1362 12.94 14.7141 13.1148 14.2741 13.1148H4.31852L1 15.5V3.15926C1 2.7192 1.17481 2.29716 1.48599 1.98599C1.79716 1.67481 2.2192 1.5 2.65926 1.5H14.2741C14.7141 1.5 15.1362 1.67481 15.4473 1.98599C15.7585 2.29716 15.9333 2.7192 15.9333 3.15926V11.4556Z" stroke="#070707" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M4.73333 6.16666H8" stroke="#070707" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M4.73333 8.96667H11.7333" stroke="#070707" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>        
    )
}

export default MessageSVG