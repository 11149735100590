import React , {useEffect, useRef } from "react";
import PropTypes from "prop-types";
import '../../../assets/css/landing.css'

const Youtube = ({ embedId, noMargin, brand }) => (
  <div className="videoContainer" style={{margin: noMargin && '0'}}>
    <div className="embed-container" style={{margin: noMargin && '0'}}>
      <iframe
        width="100%"
        height={noMargin ? "520px" : "380px"}
        src={`https://www.youtube.com/embed/${embedId}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
    </div>
  </div>

);

Youtube.propTypes = {
  embedId: PropTypes.string.isRequired
};

export default Youtube;