import React, { useState, useEffect } from 'react'
import Pagination from '../../../../../../components/addon/pagination'
import { objectDeepClone, returnReadableStatus, titleCase } from '../../../../../../service/helper'
import errorSVG from "../../../../../../assets/images/error.svg"
import searchSvg from "../../../../../../assets/images/search.svg"
import editSvg from "../../../../../../assets/images/edit-btn.svg"
import toast from "react-hot-toast"

interface Props {
    tableEditMode?: any,
    setTableEditMode?: any,
    allSchools?: any,
    formData?: any,
    setFormData?: any,
    schoolList?: any,
    wisrOption?: any,
    currentPage?: any,
    totalPage?: any,
    onPageChange?: any,
    onNextClick?: any,
    onPrevClick?: any,
    pageLoading?: any,
    dataPerPage?: any,
    showErrorMessage?: any,
    totalReach?: any,
    setTotalReach?: any,
    totalImpression?: any,
    setTotalImpression?: any,
    boysRatio?: any,
    setBoysRatio?: any,
    girlsRatio?: any,
    setGirlsRatio?: any,
    boysCount?: any,
    setBoysCount?: any,
    setGirlsCount?: any,
    girlsCount?: any,
    totalBudget?: any,
    setTotalBudget?: any,
    setOverlay?: any,
    schoolType?: any
}
const SchoolTable = ({
    tableEditMode,
    setTableEditMode,
    formData,
    setFormData,
    schoolList,
    currentPage,
    totalPage,
    onPageChange,
    onNextClick,
    onPrevClick,
    pageLoading,
    dataPerPage,
    showErrorMessage,
    totalReach,
    totalImpression,
    girlsCount,
    boysCount,
    totalBudget,
    schoolType
}: Props) => {

    const addRemoveSchool = (e, item) => {
        let arr = formData.schools
        if(e.target.checked){
            arr.push(item)
            setFormData({
                ...formData,
                schools: [...arr]
            })
        }
        else{
            arr = arr.filter((school) => school._id !== item._id)
            setFormData({
                ...formData,
                schools: [...arr]
            })
        }
    }
    const shoolSelected = (_id) => {
        if(formData.schools && formData.schools.length > 0){
            let found = formData.schools.find(item => item._id === _id)
            if(found){
                return true
            }
            else return false
        }
        return false
    }
    const selectAllSchool = (e) => {
        if(schoolList.length === 0){
            return
        }
        if(e.target.checked){
            let location = objectDeepClone(formData.locations), school = objectDeepClone(formData.schools), reach = totalReach, impression = totalImpression, budget = totalBudget, countBoys = boysCount, countgirls = girlsCount;
            schoolList.forEach(item => {
                if(!school.find(data => data._id === item._id)){
                    school.push(item)
                }
            })
            setFormData({
                ...formData,
                schools: [...school]
            })
        }
        else{
            let school = objectDeepClone(formData.schools), reach = totalReach, impression = totalImpression, budget = totalBudget, countBoys = boysCount, countgirls = girlsCount;
            schoolList.forEach(item => {
                school = [...school.filter(data => data._id !== item._id)]
            })
            setFormData({
                ...formData,
                schools: [...school]
            })
        }
    }
    const checkAllSchoolSelected = () => {
        if(formData.schools.length === 0 || schoolList.length === 0){
            return false
        }
        let notFound = false
        schoolList.forEach(school => {
            let found = formData.schools.find(item => item._id === school._id)
            if(!found){
                notFound = true
            }
        })
        return !notFound
    }
    const handlePageChange = (page) => {
        onPageChange(page)
    }
    const handleNextClick = () => {
        onNextClick(currentPage + 1)
    }
    const handlePrevClick = () => {
        onPrevClick(currentPage - 1)
    }
    return (
        <>
        <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-20 pl pr">
            <div className="table-container mt-10 addAdmininput">
            <table className="table">
                <thead>
                    <tr>
                        {tableEditMode && 
                            <th scope="col" style={{width:"70px"}}>
                                <div className="checkbox fnone">
                                    <input type="checkbox" id="schoolsAll" checked={checkAllSchoolSelected()} onClick={(e) => selectAllSchool(e)}/>
                                    <label htmlFor="schoolsAll"></label>
                                </div>
                            </th>
                        }
                        <th scope="col" style={{width:"70px"}}>SR no</th>
                        <th scope="col">School name</th>
                        {
                            <>
                                {/* {!tableEditMode && <th scope="col"><a href="javascript:void(0);" className="viewwLink proEdit" onClick={() => schoolList.length > 0 && setTableEditMode(true)}><img src={editSvg} /> Edit</a></th>} */}
                                {tableEditMode && <th scope="col">City</th>}
                                {tableEditMode && <th scope="col">State</th>}
                                {/* {tableEditMode && <th scope="col"><a href="javascript:void(0);" className="viewwLink proEdit" onClick={() => setTableEditMode(false)}> Cancel</a></th>} */}
                            </>
                        }
                        
                    </tr>
                </thead>
                <tbody>
                    {!pageLoading ? schoolList.length > 0 ? schoolList.map((school, index) => (
                        <tr key={school._id}>
                            {
                                <>
                                    {tableEditMode && <td>
                                        <div className="checkbox fnone">
                                            <input type="checkbox" id={school._id} checked={shoolSelected(school._id)} onClick={(e) => addRemoveSchool(e, school)}/>
                                            <label htmlFor={school._id}></label>
                                        </div>
                                    </td>}
                                </>
                            }
                            <td>{((currentPage - 1) * dataPerPage) + (index + 1)}</td>
                            {
                                <td colSpan={1}>
                                    <div className="schoolNameTable">
                                        <h4>{`${titleCase(school.schoolName)} `} 
                                            {/* {school.category && <span>{` - Cat ${school.category}`}</span>} */}
                                            {/* <span>{typeof(school.board) === "string" ? school.board.toUpperCase() : school.board.board.toUpperCase()}</span> */}
                                            
                                            {/* {school.parentCity && <span>{titleCase(school.parentCity)}</span>}
                                            {school.localArea && <span>{school.localArea}</span>} */}
                                        </h4>
                                        <span>{school.address}</span>
                                        
                                        {/* {school.inventories && school.inventories.length > 0 && getUniqInventories(objectDeepClone(school.inventories))}
                                        {school.events && school.events.length > 0 && school.events.map(item => (
                                            <span>{item.name}</span>
                                        ))} */}
                                        {/* <span>{typeof(school.board) === "string" ? titleCase(school.board) : titleCase(school.board.board)}</span><span>{returnReadableStatus(school.city)}</span>{school.localArea && <span>{school.localArea}</span>} */}
                                    </div>
                                </td>
                            }
                            {
                                <>
                                {tableEditMode && 
                                        // <td>
                                        //     {school.totalNoOfBoys !== undefined && school.totalNoOfBoys !== null 
                                        //     && school.totalNoOfGirls !== undefined && school.totalNoOfGirls !== null 
                                        //     ? schoolType === "both" ? (Number(school.totalNoOfBoys) + Number(school.totalNoOfGirls)) : schoolType === "girls" ? Number(school.totalNoOfGirls) : Number(school.totalNoOfBoys) : "-"}
                                        // </td>
                                        <td>
                                            {school.city && titleCase(school.city) || "-"}
                                        </td>
                                    }
                                    {tableEditMode && 
                                        <td>
                                            {school.state && titleCase(school.state) || "-"}
                                        </td>
                                    }
                                    <td>
                                    </td>
                                </>
                            }
                            
                        </tr>
                    ))
                    :
                    <tr><td colSpan={tableEditMode ? 6 : 3}>No School found</td></tr>
                    :
                    [...Array(10)].map((el, index) => {
                        return (
                            <tr>
                                {
                                    [...Array(tableEditMode ? 6 : 3)].map((el, index) => {
                                        return (
                                            <td>
                                                <div className="content-loader-block"></div>
                                            </td>
                                        )
                                    })
                                }
                            </tr>
                        )
                    })
                }
                </tbody>
            </table>
            {showErrorMessage && <span className="valError"><img src={errorSVG} />Please select atleast one school.</span>}
            </div>
            {totalPage.length > 1 &&
                <Pagination
                    currentPage={currentPage}
                    totalPage={totalPage}
                    onPageChange={handlePageChange}
                    onNextClick={handleNextClick}
                    onPrevClick={handlePrevClick}
                    loading={pageLoading}
                />
            }
        </div>
    </>
    )
}

export default SchoolTable