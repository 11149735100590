import React, { useCallback, useContext, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import DataContext from "../../service/contextProvider"

const ConfirmAlert = (props) => {

    const {
        when,
        onOK,
        onCancel,
        title,
        okText,
        cancelText,
        parentCallback
    } = props
    
    const history = useHistory()
    
    const [showPrompt, setShowPrompt] = useState(false)
    const [currentPath, setCurrentPath] = useState("")
    const parentData = useContext<any>(DataContext)

    useEffect(() => {
        if (when) {
                history.block((prompt) => {
                    if(prompt.pathname !== history.location.pathname){
                        setCurrentPath(prompt.pathname)
                        setShowPrompt(true)
                        const data = {
                            overlay: true
                        }
                        parentCallback(data)
                        return "true"
                    }
                })
        } else {
            history.block(() => {})
        }
    
        return () => {
            history.block(() => {})
        }
    }, [history, when])
    
    const handleOK = useCallback(async() => {
        if (onOK) {
            console.log("what is net revenue",parentData.netRevenue, parentData.prevNetRevenue);
            if(parentData && parentData.setNetRevenue){
                parentData.setNetRevenue(parentData.prevNetRevenue || 0)
            }
            const canRoute = await Promise.resolve(onOK())
            if (canRoute) {
                history.block(() => {})
                history.push(currentPath)
                setShowPrompt(false)
                const data = {
                    overlay: false
                }
                parentCallback(data)
            }
        }
    }, [currentPath, history, onOK])
    
    const handleCancel = useCallback(async() => {
        if (onCancel) {
            const canRoute = await Promise.resolve(onCancel())
            if (canRoute) {
                history.block(() => {})
                history.push(currentPath)
            }
        }
        setShowPrompt(false)
        const data = {
            overlay: false
        }
        parentCallback(data)
    }, [currentPath, history, onCancel])

    return showPrompt ? (
        <>
            <div id="closePopup" className={`popup ${showPrompt === true ? "" : "hide"}`}>
                <div className="popup-header">
                    <h3 className="htx2">{title}</h3>
                    <span onClick={() => handleCancel()} className="close toggle" data-target="closePopup">close</span>
                </div>
                <div className="popup-body">
                    <div className="facili-avai-in clearfix">
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <h3 className="htx2">{`Are you sure you want to leave without saving. Please click on proceed to save changes.`}</h3>
                            </div>
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-20 end-xs">
                                <div className="forminpt">
                                    <input onClick={() => handleCancel()} name={cancelText} value={cancelText} className="btn btn-grey" type="button" />
                                    <input onClick={() => handleOK()} name={okText} value={okText} className="btn btn-orange" type="button" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>    
        </>
    ) : null
}

export default ConfirmAlert