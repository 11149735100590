import React, { useState, useEffect, useContext, createRef, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
// import MultiPointMap from "../../../../components/addon/multiPointMap"
import scriptLoader from 'react-async-script-loader'
import uploadSvg from "../../../../../assets/images/upload-svg.svg"
import sucessCheck from "....//../../../assets/images/sucess-check.svg"
import closeSvg from "../../../../../assets/images/close-svg.svg"
import blockSvg from "../../../../../assets/images/block-svg.svg"
import reloadSvg from "../../../../../assets/images/reload-svg.svg"
import filterSvg from "../../../../../assets/images/filter-svg.svg"
import filterArrowSvg from "../../../../../assets/images/filter-arrow-svg.svg"
import mapsImg from "../../../../../assets/images/maps.jpg"
import AppContextObject from '../../../../../common/context/common'
import DataContext from '../../../../../service/backendContext'
import Pagination from '../../../../../components/addon/pagination'
// import CampaignBudget from '../components/campaignBudget'
// import GenderSplit from '../components/genderSplit'
// import CampaignReach from '../components/campaignReach'
// import CampaignImpression from '../components/campaignImpression'
import { compareObjs, objectDeepClone, returnReadableStatus, titleCase } from '../../../../../service/helper'
import config from '../../../../../service/config'
import AlertDialog from '../../../../../components/addon/alertDialog/alertDialog'
import ConfirmDialog from '../../../../../components/addon/confirmDialog/confirmDialog'
import errorSVG from "../../../../../assets/images/error.svg"
import searchSvg from "../../../../../assets/images/search.svg"
import editSvg from "../../../../../assets/images/edit-btn.svg"
import { DebounceInput } from 'react-debounce-input'
import CustomSearchInput from '../../../../../components/formFields/customSearchInput'
import useClickOutside from '../../../../../service/useClickOutside'
// import BrandBackBtn from "../components/brandBackBtn"
import moment from 'moment'
// import { WisrOptionService } from "../../../../service/wisrOptions"
import SchoolTable from './components/schoolTable'
// import school from '../school'
import toast from 'react-hot-toast'
import BackendBackBtn from '../../components/backendBackBtn'
var algoSchoolListGlobalVar = 1

const NonWisrRecommendedSchool = ({ setShowProgressSideMenu }) => {
    const filterRef = useRef(null)
    const filterOptionsRef = useRef(null)
    const location = useLocation()
    const history = useHistory()
    const context = useContext(AppContextObject)
    const parentData = useContext<any>(DataContext)
    const [loadingFirstTime, setLoadingFirstTime] = useState(true)
    //location data
    const [stateList, setStateList] = useState([...parentData.nonWisrCampaignData.brandDetail.state])
    const [selectedState, setSelectedState] = useState([...parentData.nonWisrCampaignData.brandDetail.state])
    const [cityList, setCityList] = useState([...parentData.nonWisrCampaignData.brandDetail.city.sort((a, b) => a.toLowerCase() > b.toLowerCase() ? 1 : -1)])
    const [filterByCity, setFilterByCity] = useState([...parentData.nonWisrCampaignData.brandDetail.city])
    const [cityStateData, setCityStateData] = useState({})

    //search related data
    const [searchInpuOpen, setSearchInpuOpen] = useState(false)
    const [clearSearchIcon, setClearSearchIcon] = useState(false)
    const [searchValue, setSearchValue] = useState("")

    const [tableEditMode, setTableEditMode] = useState(true)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPage, setTotalPage] = useState([0])
    const [totalBudget, setTotalBudget] = useState(parentData.nonWisrCampaignData.campaignDetail.totalBudget)
    const [totalReach, setTotalReach] = useState(parentData.nonWisrCampaignData.campaignDetail.totalReach)
    const [totalImpression, setTotalImpression] = useState(parentData.nonWisrCampaignData.campaignDetail.totalImpression)
    const [boysRatio, setBoysRatio] = useState(0)
    const [girlsRatio, setGirlsRatio] = useState(0)
    const [formData, setFormData] = useState({ schools: [...parentData.nonWisrCampaignData.school.schools], locations: [] })
    const [schoolList, setSchoolList] = useState([])
    const [totalSchoolCount, setTotalSchoolCount] = useState(0)
    const [overlay, setOverlay] = useState(false)
    const [showFilterModal, setShowFilterModal] = useState(false)
    const [showCityFilter, setShowCityFilter] = useState(false)
    const [showLanguageFilter, setShowLanguageFilter] = useState(false)
    const [showBoardFilter, setShowBoardFilter] = useState(false)
    const [showSchoolTypeFilter, setShowSchoolTypeFilter] = useState(false)
    const [showStateFilter, setShowStateFilter] = useState(false)
    const [dataPerPage] = useState(25)
    const [pageLoading, setPageLoading] = useState(true)
    const [showErrorMessage, setShowErrorMessage] = useState(false)
    const [filteredSchoolCount, setFilteredSchoolCount] = useState(0)

    useClickOutside(filterOptionsRef, (event) => {
        const el = filterRef?.current;

        if (!el || el.contains(event.target)) {
            return;
        }
        setShowFilterModal(false)
    })
    useEffect(() => {
        const isSummary = new URLSearchParams(location.search).get("isSummary")
        if (isSummary === "true") {
            console.log("object")
            parentData.setNonWisrCampaignProgress({
                brandDetails: true,
                inventory: true,
                event: true,
                school: false,
                campaignCreative: false,
                preview: false
            })
        }
    }, [])
    useEffect(() => {
        if (cityStateData && Object.keys(cityStateData).length > 0) {
            fetchCityByState()
        }
    }, [selectedState])
    const fetchCityByState = async () => {
        // const resp = await context.apis.public.getCitiesByState(
        //     context.validator, {states: selectedState}, {}
        // )
        // console.log("Andhra Pradesh", resp)
        let city = []
        selectedState.forEach(state => {
            // city = [...city, ...cityStateData[state]]
            if (cityStateData[state] && cityStateData[state].length > 0) {
                cityStateData[state].forEach(item => {
                    if (!city.find(data => data.toLowerCase() === item.toLowerCase())) {
                        city.push(item)
                    }
                })
            }
        })
        city = city.sort((a, b) => {
            return a.toLowerCase() > b.toLowerCase() ? 1 : -1
        })
        console.log("cityFirst", city)
        setCityList([...city])
        // if(resp && resp.done){
        //     resp?.response?.rows?.forEach(row => {
        //         city = [...city, ...row.cities]
        //     })
        //     setCityList([...city])
        // }
    }
    useEffect(() => {
        if (cityStateData && Object.keys(cityStateData).length > 0 && selectedState && selectedState.length) {
            let newState = []
            selectedState.forEach((item) => {
                let cities = [...cityStateData[item]]
                console.log("mnbvcfghjk", item, cityStateData)
                let found = 0;
                cities.forEach(data => {
                    if (filterByCity.includes(data)) {
                        found++
                    }
                })
                if (found) {
                    newState.push(item)
                }
            })
            setSelectedState([...newState])
        }
    }, [filterByCity])
    useEffect(() => {
        setShowProgressSideMenu(true)
        if (parentData.setNonWisrCampaignActiveModule) {
            parentData.setNonWisrCampaignActiveModule({
                ...parentData.nonWisrCampaignActiveModule,
                module: "campaignPlacement",
                subModule: "schools"
            })
        }
    }, [])
    useEffect(() => {
        // if(parentData.nonWisrCampaignData.school.schools.length === 0){
        getAllSchools()
        // }
    }, [])
    useEffect(() => {
        // if (!loadingFirstTime) {
            getFilteredSchoolList()
        // }
    }, [searchValue, selectedState, currentPage])
    useEffect(() => {
        if (currentPage !== 1) {
            setCurrentPage(1)
        }
    }, [searchValue, selectedState])

    const getAllSchools = async() => {
        // setIsLoading(true)
        let resp = await context.apis[context.user.role].getAllNonWisrSchools(
            context.validator, {state: selectedState, city: filterByCity, searchValue: searchValue}, {withCount: true}
        )
        console.log("getAllNonWisrSchools", resp)
        if(resp && resp.done){
            let states = [], cityStateObj = {}
            resp.response.rows.forEach(school => {
                if(!states.includes(school.state.toLowerCase())){
                    states.push(school.state.toLowerCase())
                }
                if(cityStateObj[school.state.toLowerCase()]){
                    if(!cityStateObj[school.state.toLowerCase()].includes(school.city.toLowerCase())){
                        cityStateObj[school.state.toLowerCase()].push(school.city.toLowerCase())
                    }
                }
                else{
                    cityStateObj[school.state.toLowerCase()] = [school.city.toLowerCase()]
                }
            })
            console.log("first", states, cityStateObj)
            setStateList([...states])
            setCityStateData({...cityStateObj})
            if(loadingFirstTime){
                setLoadingFirstTime(false)
                setTotalSchoolCount(resp.response.count)
            }
        }
        // setIsLoading(false)
    }
    const getFilteredSchoolList = async () => {
        setPageLoading(true)
        let resp = await context.apis[context.user.role].getAllNonWisrSchools(
            context.validator, {state: selectedState, city: filterByCity, searchValue: searchValue}, {page: currentPage, limit: dataPerPage, withCount: true}
        )
        console.log("getAllNonWisrSchools", resp)
        if(resp && resp.done){
            let sortedSchool = resp.response.rows.sort((a) => {
                let found = formData.schools.find(item => item._id === a._id)
                return found ? -1 : 1
            })
            setSchoolList([...sortedSchool])
            setFilteredSchoolCount(resp.response.count)
            let total = Math.ceil(resp.response.count / dataPerPage);
            let arr = []
            for (let i = 0; i < total; i++) {
                arr.push(i)
            }
            setTotalPage([...arr])
        }
        setPageLoading(false)
    }
    const handleAddRemoveFilter = (type, data) => {
        if (type === "state") {
            let array = [...selectedState]
            if (array.includes(data)) {
                if (array.length < 2) {
                    toast.error("You need to select atlest one state")
                    return
                }
                array.splice(array.indexOf(data), 1)
                if (filterByCity.length !== 0) {
                    let citiesToRemove = [...cityStateData[data]], newCitiesData = [...filterByCity]
                    console.log("citiesToRemove", citiesToRemove, "newCitiesData", newCitiesData)
                    for (let city of citiesToRemove) {
                        console.log("newCitiesData.indexOf(city)", newCitiesData.indexOf(city));

                        if (newCitiesData.indexOf(city) !== -1) {
                            newCitiesData.splice(newCitiesData.indexOf(city), 1)
                        }
                    }
                    // console.log("citiesToRemove", citiesToRemove, "newCitiesData", newCitiesData)
                    setFilterByCity([...newCitiesData])
                    // setFilterByCity([...filterByCity,...cityStateData[data]])
                    // setFilterByCity([])
                }
            }
            else {
                array.push(data)
                setFilterByCity([...filterByCity, ...cityStateData[data]])
            }
            setSelectedState([...array])
        }
        else if (type === "city") {
            let array = [...filterByCity]
            if (array.includes(data)) {
                if (array.length < 2) {
                    toast.error("You need to select atlest one city")
                    return
                }
                array.splice(array.indexOf(data), 1)
            }
            else {
                array.push(data)
            }
            setFilterByCity([...array])
        }
        // else if (type === "board") {
        //     let array = [...filterByBoard]
        //     if (array.includes(data)) {
        //         array.splice(array.indexOf(data), 1)
        //     }
        //     else {
        //         array.push(data)
        //     }
        //     setFilterByBoard([...array])
        // }
        // else if (type === "language") {
        //     let array = [...filterByLanguage]
        //     if (array.includes(data)) {
        //         array.splice(array.indexOf(data), 1)
        //     }
        //     else {
        //         array.push(data)
        //     }
        //     setFilterByLanguage([...array])
        // }
        // else if (type === "schoolType") {
        //     let array = [...filterBySchoolType]
        //     if (array.includes(data)) {
        //         array.splice(array.indexOf(data), 1)
        //     }
        //     else {
        //         array.push(data)
        //     }
        //     setFilterBySchoolType([...array])
        // }
    }
    const handleSearch = async (e) => {
        if (e === "") {
            setSearchValue("")
        } else {
            setSearchValue(e.target.value.trim())
        }
        // let schoolTypeArray = filterBySchoolType.length === 0 ? [] : filterBySchoolType.length === 1 ? [...filterBySchoolType] : ["co-ed"]
        // let inventoriesIdArray = parentData?.data?.inventory?.inventories.map((item) => item._id)
        // const resp = await context.apis.public.getAllRecemmendedSchools(
        //     context.validator, {searchValue: e.target.value === "" ? null : e.target.value, inventories: inventoriesIdArray, city: filterByCity, schoolType: filterBySchoolType, language: filterByLanguage, board: filterByBoard,}, {limit: dataPerPage, page: currentPage, withCount: true}
        // )
        // // console.log(resp)
        // if(resp && resp.done){
        //     console.log(resp)
        //     setSchoolList([...resp.response.rows])
        //     setTotalSchoolCount(resp.response.count)
        //     let pinCodeData = []
        //     resp.response.rows.forEach((school) => {
        //         if(school.pincode){
        //             pinCodeData.push(school.pincode)
        //         }
        //     })
        //     setPincodeList(pinCodeData)
        //     let total = Math.floor(Number(resp.response.count)/dataPerPage) + (Number(resp.response.count)%dataPerPage > 0 ? 1 : 0)
        //     let arr = []
        //     for(let i=0; i<total; i++){
        //         arr.push(i)
        //     }
        //     setTotalPage([...arr])
        // }
    }
    const openFilter = () => {
        setShowBoardFilter(false)
        setShowCityFilter(false)
        setShowSchoolTypeFilter(false)
        setShowLanguageFilter(false)
        setShowFilterModal(!showFilterModal)
    }
    const showFilterDropDown = (type) => {
        if (type === "state") {
            setShowStateFilter(!showStateFilter)
            setShowBoardFilter(false)
            setShowLanguageFilter(false)
            setShowSchoolTypeFilter(false)
        }
        if (type === "city") {
            setShowBoardFilter(false)
            setShowLanguageFilter(false)
            setShowSchoolTypeFilter(false)
            setShowCityFilter(!showCityFilter)
        }
        else if (type === "board") {
            setShowStateFilter(false)
            setShowBoardFilter(!showBoardFilter)
            setShowLanguageFilter(false)
            setShowSchoolTypeFilter(false)
            setShowCityFilter(false)
        }
        else if (type === "language") {
            setShowStateFilter(false)
            setShowBoardFilter(false)
            setShowLanguageFilter(!showLanguageFilter)
            setShowSchoolTypeFilter(false)
            setShowCityFilter(false)
        }
        else if (type === "schoolType") {
            setShowStateFilter(false)
            setShowBoardFilter(false)
            setShowLanguageFilter(false)
            setShowSchoolTypeFilter(!showSchoolTypeFilter)
            setShowCityFilter(false)
        }
    }
    const clearAllFilter = () => {
        // setFilterBySchoolType([])
        // setFilterByBoard([])
        setFilterByCity([...parentData.nonWisrCampaignData.brandDetail.city])
        // setFilterByLanguage([])
        setSelectedState([...parentData.nonWisrCampaignData.brandDetail.state])
        setShowStateFilter(false)
        setShowFilterModal(false)
        setShowBoardFilter(false)
        setShowLanguageFilter(false)
        setShowSchoolTypeFilter(false)
        setShowCityFilter(false)
    }
    const openExit = () => {
        // setShowExitPopup(true)
        // setOverlay(true)
    }
    useEffect(() => {
        if (formData.schools && formData.schools.length > 0) {
            setShowErrorMessage(false)
        }
    }, [formData?.schools])
    const onSave = async (confirm, budgetChecked) => {
        if (confirm) {
            if (formData.schools && formData.schools.length === 0) {
                setShowErrorMessage(true)
                return
            }
            else if (formData.schools.length > 0) {
                setShowErrorMessage(false)
            }
            // let usedEvent = []
            // formData.schools.forEach(item => {
            //     item.events.forEach(data => {
            //         if (!data._custom) {
            //             if (!usedEvent.includes(data.name)) {
            //                 usedEvent.push(data.name)
            //             }
            //         }
            //         else {
            //             if (!usedEvent.includes(data.parentName)) {
            //                 usedEvent.push(data.parentName)
            //             }
            //         }
            //     })
            // })
            // usedEvent = parentData.nonWisrCampaignData.eventAndOccation.eventAndOccations.filter(event => {
            //     let found = usedEvent.find(item => item === event.parentName || item === event.name)
            //     // console.log(found)
            //     if (found) {
            //         return true
            //     }
            //     else {
            //         return false
            //     }
            // })
            // console.log("usedEvent", usedEvent)
            // usedEvent = usedEvent.filter((item, index) => index === usedEvent.findIndex(data => data.name === item.name))
            // console.log("usedEvent1", usedEvent)


            // let usedInventories = []
            // formData.schools.forEach(item => {
            //     item.inventories.forEach(data => {
            //         if (!data._custom) {
            //             if (!usedInventories.includes(data.name)) {
            //                 usedInventories.push(data.name)
            //             }
            //         }
            //         else {
            //             if (!usedInventories.includes(data.parentName)) {
            //                 usedInventories.push(data.parentName)
            //             }
            //         }
            //     })
            // })
            // usedInventories = parentData.nonWisrCampaignData.inventory.inventories.filter(inventory => {
            //     let found = usedInventories.find(item => item === inventory.parentName || item === inventory.name)
            //     // console.log(found)
            //     if (found) {
            //         return true
            //     }
            //     else {
            //         return false
            //     }
            // })
            // usedInventories = usedInventories.filter((item, index) => index === usedInventories.findIndex(data => data.parentName === item.parentName || data.name === item.name))
            // console.log("usedinventories", usedInventories)
            if (parentData) {
                if (parentData.setNonWisrCampaignProgress) {
                    parentData.setNonWisrCampaignProgress({
                        ...parentData.nonWisrCampaignProgress,
                        school: true,
                    })
                }
                if (parentData.setNonWisrCampaignData) {
                    parentData.setNonWisrCampaignData({
                        ...parentData.nonWisrCampaignData,
                        school: { ...formData },
                        campaignDetail: {
                            ...parentData.nonWisrCampaignData.campaignDetail,
                            totalBudget: totalBudget,
                            totalReach: totalReach,
                            totalImpression: totalImpression,
                            girlsRatio: girlsRatio,
                            boysRatio: boysRatio
                        },
                        inventory: {
                            ...parentData.nonWisrCampaignData.inventory
                        },
                        eventAndOccation: {
                            ...parentData.nonWisrCampaignData.eventAndOccation,
                            eventAndOccations: [...parentData.nonWisrCampaignData.eventAndOccation.eventAndOccations]
                        }
                    })
                }
            }
            if (parentData.nonWisrCampaignData.brandDetail.isTrial) {
                history.push('/backend/backend-details/nonWisr-create-trial-campaign/nonWisr-campaign-creative')
            }
            else {
                history.push('/backend/backend-details/nonWisr-create-campaign/nonWisr-campaign-creative')
            }
        }
    }
    const onPageChange = (page) => {
        setCurrentPage(page)
    }
    const onNextClick = (page) => {
        setCurrentPage(page)
    }
    const onPrevClick = (page) => {
        setCurrentPage(page)
    }
    return (
        <>
            <div className={`ovelap ${overlay === true && "active"}`}></div>
            {/* <TopBar isDashboard={true} /> */}
            <div className="col-xl-9 col-lg-12 col-md-12 col-xs-12">
                <div className="rightPart noBack">
                    <div className="row">
                        <div className="col-xs-12 col-lg-12 col-md-12 col-xs-12">
                            <BackendBackBtn />
                        </div>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-20">
                            <div className='row'>
                                <div className="col-xl-6 col-lg-6 col-md-6 col-xs-12" style={{ padding: '0px' }}>
                                    <h2 className="htx5 clearfix mt-10 mb-20">Create a Campaign</h2>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6 col-xs-12" style={{ marginTop: "20px" }}>
                                    {/* <a href="javascript:void(0);" onClick={() => history.push({ pathname: parentData.nonWisrCampaignData.brandDetail.isTrial ? '/brands/brand-details/create-trial-campaign/set-up-campaign' : '/brands/brand-details/create-campaign/set-up-campaign', state: { from: "createCampaignFlow" } })} style={{ color: 'black', position: "relative" }}><img style={{ position: "absolute", right: '118px' }} src={editSvg} /> Edit Campaign</a> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-10">
                            <h2 className="htx2 clearfix mb-10">{parentData?.nonWisrCampaignData?.campaignDetail?.wisrOption ? "List of Non-WISR Schools" : "List of Non-WISR Schools"} <b>{`(${formData.schools.length}/${totalSchoolCount})`}</b></h2>
                        </div>
                        <div className="col-xl-8 col-lg-8 col-md-12 col-xs-12 mb-20">
                            <div className="row">
                                <div className="col-xl-6 col-lg-3 col-md-3 col-xs-12 mb-10 pl">
                                    <CustomSearchInput
                                        searchInpuOpen={searchInpuOpen}
                                        setSearchInpuOpen={setSearchInpuOpen}
                                        clearSearchIcon={clearSearchIcon}
                                        setClearSearchIcon={setClearSearchIcon}
                                        searchKeys={searchValue}
                                        handleChange={handleSearch}
                                        palaceholder="Search"
                                    />
                                </div>
                                <div className="col-xl-6 col-lg-9 col-md-9 col-xs-12 mb-10 pr">
                                    <div className="fR filterbox">
                                        <a href="javascript:void(0);" className="filterDrop" onClick={openFilter}>
                                            <img src={filterSvg} />
                                            <h3>{`Filter(${pageLoading ? "..." : selectedState.length === 0 && filterByCity.length === 0 && searchValue === "" ? 0 : filteredSchoolCount})`}</h3>
                                            <img src={filterArrowSvg} />
                                        </a>
                                        <div className={`filterDropDown ${showFilterModal === true && "open"}`}>
                                            <div className="filterDropHead" ref={filterRef}>
                                                <h2>Filter <a href="javascript:void(0);" className="viewwLink" onClick={clearAllFilter}>Clear All</a></h2>
                                            </div>
                                            <div className="filterDropBody" ref={filterOptionsRef}>
                                                <div className={`filterDropClose ${showStateFilter === true && "open"}`}>
                                                    <h3><a href="javascript:void(0);" onClick={() => showFilterDropDown("state")}>{`State ${selectedState.length > 0 ? `(${selectedState.length})` : ""}`}  <img src={filterArrowSvg} /></a></h3>
                                                    <div className="filtercheckbox">
                                                        {stateList.map((item, index) => (
                                                            <div className="checkbox fnone" key={index} >
                                                                <input type="checkbox" id={item} checked={selectedState.includes(item)} onClick={(e) => handleAddRemoveFilter("state", item)} />
                                                                <label htmlFor={item}>{titleCase(item)}</label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                                <div className={`filterDropClose ${showCityFilter === true && selectedState.length !== 0 && "open"}`}>
                                                    <h3><a href="javascript:void(0);" onClick={() => showFilterDropDown("city")}>{`City ${filterByCity.length > 0 ? `(${filterByCity.length})` : ""}`}  <img src={filterArrowSvg} /></a></h3>
                                                    <div className="filtercheckbox">
                                                        {cityList.map((item, index) => (
                                                            <div className="checkbox fnone" key={index} >
                                                                <input type="checkbox" id={item} checked={filterByCity.includes(item)} onClick={() => handleAddRemoveFilter("city", item)} />
                                                                <label htmlFor={item}>{titleCase(item)}</label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                                {/* <div className={`filterDropClose ${showSchoolTypeFilter === true && "open"}`}>
                                                    <h3><a href="javascript:void(0);" onClick={() => showFilterDropDown("schoolType")}>{`Gender ${filterBySchoolType.length > 0 ? `(${filterBySchoolType.length})` : ""}`}  <img src={filterArrowSvg} /></a></h3>
                                                    <div className="filtercheckbox">
                                                        <div className={`checkbox fnone ${parentData.nonWisrCampaignData.brandDetail.targetGender === "girls" ? "disabled-grey" : ""}`}>
                                                            <input type="checkbox" id="male" disabled={parentData.nonWisrCampaignData.brandDetail.targetGender === "girls"} checked={filterBySchoolType.includes("boys")} onClick={() => handleAddRemoveFilter("schoolType", "boys")} />
                                                            <label htmlFor="male">Boys</label>
                                                        </div>
                                                        <div className={`checkbox fnone ${parentData.nonWisrCampaignData.brandDetail.targetGender === "boys" ? "disabled-grey" : ""}`}>
                                                            <input type="checkbox" id="female" disabled={parentData.nonWisrCampaignData.brandDetail.targetGender === "boys"} checked={filterBySchoolType.includes("girls")} onClick={() => handleAddRemoveFilter("schoolType", "girls")} />
                                                            <label htmlFor="female">Girls</label>
                                                        </div>
                                                        <div className="checkbox fnone">
                                                            <input type="checkbox" id="co-ed" checked={filterBySchoolType.includes("co-ed")} onClick={() => handleAddRemoveFilter("schoolType", "co-ed")} />
                                                            <label htmlFor="co-ed">Co-ed</label>
                                                        </div>
                                                    </div>
                                                </div> */}
                                                {/* <div className={`filterDropClose ${showBoardFilter === true && "open"}`}>
                                                    <h3><a href="javascript:void(0);" onClick={() => showFilterDropDown("board")}>{`Education Board ${filterByBoard.length > 0 ? `(${filterByBoard.length})` : ""}`}  <img src={filterArrowSvg} /></a></h3>
                                                    <div className="filtercheckbox">
                                                        {config.boardOptions.filter((item) => item.value.toLowerCase() !== "custom" && item.value.toLowerCase() !== "other").map((item, index) => (
                                                            <div className="checkbox fnone" key={index}>
                                                                <input type="checkbox" id={item.value} checked={filterByBoard.includes(item.value)} onClick={() => handleAddRemoveFilter("board", item.value)} />
                                                                <label htmlFor={item.value}>{item.label}</label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div> */}
                                                {/* <div className={`filterDropClose ${showLanguageFilter === true && "open"}`}>
                                                    <h3><a href="javascript:void(0);" onClick={() => showFilterDropDown("language")}>{`Language of Study ${filterByLanguage.length > 0 ? `(${filterByLanguage.length})` : ""}`}  <img src={filterArrowSvg} /></a></h3>
                                                    <div className="filtercheckbox">
                                                        {parentData.nonWisrCampaignData.brandDetail.studyMedium.map((item, index) => (
                                                            <div className="checkbox fnone" key={index}>
                                                                <input type="checkbox" id={item} checked={filterByLanguage.includes(item)} onClick={() => handleAddRemoveFilter("language", item)} />
                                                                <label htmlFor={item}>{item}</label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <SchoolTable
                                    tableEditMode={tableEditMode}
                                    setTableEditMode={setTableEditMode}
                                    formData={formData}
                                    setFormData={setFormData}
                                    schoolList={schoolList}
                                    totalBudget={totalBudget}
                                    setTotalBudget={setTotalBudget}
                                    totalReach={totalReach}
                                    setTotalReach={setTotalReach}
                                    totalImpression={totalImpression}
                                    setTotalImpression={setTotalImpression}
                                    boysRatio={boysRatio}
                                    setBoysRatio={setBoysRatio}
                                    girlsRatio={girlsRatio}
                                    setGirlsRatio={setGirlsRatio}
                                    wisrOption={parentData.nonWisrCampaignData.campaignDetail.wisrOption}
                                    currentPage={currentPage}
                                    totalPage={totalPage}
                                    pageLoading={pageLoading}
                                    dataPerPage={dataPerPage}
                                    showErrorMessage={showErrorMessage}
                                    setOverlay={setOverlay}
                                    schoolType={parentData.nonWisrCampaignData.brandDetail.targetGender}
                                    onPageChange={onPageChange}
                                    onNextClick={onNextClick}
                                    onPrevClick={onPrevClick}
                                />
                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 pl pr"></div>
                                <div className="col-xl-5 col-lg-5 col-md-6 col-xs-12 delete-user mt-30"><a className="viewwLink" style={{ cursor: "pointer" }} onClick={openExit}>Save & Continue Later</a></div>
                                <div className="col-xl-7 col-lg-7 col-md-6 col-xs-12 end-md mt-30">
                                    <div className="forminpt">
                                        <input name="Save &amp; next" className="btn btn-orange" type="button" value="Save &amp; next" onClick={() => onSave(true, false)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-12 col-xs-12 mb-20">
                            <div className="w90">
                                {/* <div className="box-design mb-20 overheadThree">
                                <h2 className="htx3 clearfix mb-10">Campaign Location</h2>
                                {isScriptLoaded && isScriptLoadSucceed &&
                                    <MultiPointMap
                                        pincodeList={pincodeList}
                                    />
                                }
                            </div> */}
                                {/* {!loadingFirstTime && 
                                <>
                                    <CampaignBudget
                                        totalBudget={totalBudget}
                                        setTotalBudget={setTotalBudget}
                                        setOverlay={setOverlay}
                                        manuallyChangeBudget={manuallyChangeBudget}
                                        budgetExceeded={budgetExceeded}
                                        setPageLoading={setPageLoading}
                                        // min={minBudget}
                                        // max={maxBudget}
                                    />
                                    <GenderSplit
                                        girlsRatio={girlsRatio}
                                        boysRatio={boysRatio}
                                    />
                                    <CampaignReach
                                        totalReach={totalReach}
                                        setTotalReach={setTotalReach}
                                        manuallyChangeReach={manuallyChangeReach}
                                        disable={false}
                                        min={minReach}
                                        max={maxReach}
                                        setPageLoading={setPageLoading}
                                    />
                                    <CampaignImpression
                                        totalImpression={totalImpression}
                                        setTotalImpression={setTotalImpression}
                                        manuallyChangeImpression={manuallyChangeImpression}
                                        disable={false}
                                        min={minImpression}
                                        max={maxImpression}
                                        setPageLoading={setPageLoading}
                                    />
                                </>
                            } */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <AlertDialog
                onModalClose={onModalClose}
                togglePopup={showLimitExceeded}
                displayMsg="Maximum limit reached! selecting more school will increase your budget"
                showBtn={true}
            /> */}
            {/* <ConfirmDialog
                togglePopup={saveAndContinue}
                onModalClose={onSaveAndContinueExit}
                componentName={""}
                confirmTitle="Your campaign budget has increased do you want to continue?"
            /> */}
            {/* <ConfirmDialog
                togglePopup={showExitPopup}
                onModalClose={onExit}
                componentName={""}
                confirmTitle="Do you want to save and exit?"
                isLoading={confirmDialogBtnLoading}
            /> */}
        </>
    )
}

export default NonWisrRecommendedSchool