import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import RangeSlider from '../../../../components/addon/rangeSlider'
import { ToWords } from 'to-words';
import { returnReadableStatus, titleCase } from '../../../../service/helper';
import AlertDialog from '../../../../components/addon/alertDialog/alertDialog';
import editSvg from "../../../../assets/images/edit-btn.svg"
import errorSVG from "../../../../assets/images/error.svg"

interface Props {
    totalBudget?: any,
    setTotalBudget?: any,
    setOverlay?: any,
    disable?: any,
    budgetExceeded?: any,
    manuallyChangeBudget?: any,
    hideEdit?: any,
    setPageLoading? :any
    setEventAndInventory? :any
    // min?: any,
    // max?: any
}

const CampaignBudget = ({
    totalBudget, setOverlay, setTotalBudget, disable, budgetExceeded, manuallyChangeBudget, hideEdit, setPageLoading, setEventAndInventory
}: Props) => {
    const history = useHistory()
    const min = 0, max = 100000000
    const toWords = new ToWords();
    const [showEditBudgetModal, setShowEditBudgetModal] = useState(false)
    const [budgetError, setBudgetError] = useState(false)
    const [showAlertMessage, setShowAlertMessage] = useState(false)
    const [budget, setBudget] = useState(totalBudget)
    const [isInventoryAndEvent, setIsInventoryAndEvent] = useState(setEventAndInventory)

    console.log("budget campaignBudget", budget)

    // useEffect(() => {
    //     setBudget(totalBudget)
    // }, [totalBudget])
    const closeModal = () => {
        setBudget(totalBudget)
        setOverlay(false)
        setShowEditBudgetModal(false)
    }
    const onRangeChange = (data) => {
        if(data >= 0){
            setBudgetError(false)
        }
        // console.log(data)
        setBudget(data)
    }
    const changeError = () => {
        // setBudgetError(false)
    }
    const openBudgetModal = () => {
        setBudget(totalBudget)
        setOverlay(true)
        setShowEditBudgetModal(true)
    }
    const onModalClose = () => {
        if(budget < 0){
            setBudgetError(true)
            return
        }
        manuallyChangeBudget(budget)
        setOverlay(false)
        setShowEditBudgetModal(false)
    }
    const openPopup = () => {
        setShowAlertMessage(true)
        setOverlay(true)
    }
    const onAlertMessageClose = (confirm) => {
        if(confirm){
            history.push({pathname: "/brands/brand-details/create-campaign/recommended-school", search: `isSummary=${true}`})
        }
        setShowAlertMessage(false)
        setOverlay(false)
    }
    return (
        <>
            <div className="box-design mb-20 overheadThree">
                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-10">
                    <h2 className="htx3 h-achor clearfix">Campaign Budget {!hideEdit && <a href="javascript:void(0)" className="editbtn" onClick={disable ? openPopup : openBudgetModal}>
                        <img src={editSvg} /> Edit</a>}
                    </h2>
                    <div className={`inventory-overHead ${budgetExceeded ? "budgetExceeded" : ""}`}>
                        <h3>{`₹ ${totalBudget.toLocaleString('en-IN')}`}</h3>
                        <p className="mt-10" style={{textTransform: "unset"}}>{`Rupees ${toWords.convert(Math.ceil(Number(totalBudget)))}`}</p>
                        {/* <p style={{marginTop:"30px"}}><span style={{fontSize:"15px", color:"#000"}}>Note: </span> <span className='overheadThree ptx2'>Costs are inclusive of school associations, production, placement of branding, and monitoring. Customisations are not included.</span></p> */}
                        <p style={{marginTop:"30px"}}><span style={{fontSize:"15px", color:"#000"}}>Note: </span> <span className='overheadThree ptx2'>
                            
                            {
                            // isInventoryAndEvent?.inventory && isInventoryAndEvent?.eventAndOccations ? "Only event" : isInventoryAndEvent?.eventAndOccations ? "Only event 2" : "Costs are inclusive of school associations, production, placement of branding, and monitoring. Customisations are not included."
                            isInventoryAndEvent?.inventory && isInventoryAndEvent?.eventAndOccations ? "Costs are inclusive of school associations, production, placement of branding, and monitoring. Customisations are not included." : isInventoryAndEvent?.eventAndOccations ? "Costs are inclusive of school associations, production, and monitoring. Customisations are not included." : "Costs are inclusive of school associations, production, placement of branding, and monitoring. Customisations are not included."
                            // isInventoryAndEvent?.eventAndOccations ? "Costs are inclusive of school associations, production, and monitoring. Customisations are not included." : "Costs are inclusive of school associations, production, placement of branding, and monitoring. Customisations are not included."
                            
                            }

                            
                            
                            </span></p>
                    </div>
                    
                    
                </div>
            </div>
            <div
                id='campaignBudgetModal'
                className={`popup budget ${showEditBudgetModal === false ? "hide" : ""}`}
            >
                <div className='popup-header'>
                    <span
                        onClick={closeModal}
                        className='close toggle'
                        data-target='campaignBudgetModal'
                        >
                    close
                    </span>
                </div>
                <div className='popup-body'>
                    <h2>Your Campaign Budget <a style={{cursor: "pointer"}} onClick={onModalClose}><svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M1 3.5191L3.37713 5.9L8.27462 1" stroke="#070707" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg> Save</a></h2>                    
                    <div className='rc-slider-body'>
                    <h3>{`₹ ${budget.toLocaleString('en-IN')}`}</h3>
                    <RangeSlider
                        min={min}
                        max={max}
                        value={budget}
                        onSliderValueChange={onRangeChange}
                        changeError={changeError}
                    />
                    </div>
                    {budgetError &&
                        <span className="valError">
                            <img src={errorSVG} />
                            Budget can not be less than 0.
                        </span>
                    }
                </div>
            </div>
            <AlertDialog
                onModalClose={onAlertMessageClose}
                togglePopup={showAlertMessage}
                displayMsg="The schools are recommended based on your budget. If you proceed to edit the budget, a revised school list will appear."
                showBtn={true}
            />
        </>
    )
}

export default CampaignBudget
