import React from "react";
import { useHistory } from "react-router";
import InventorySpecific from "./inventorySpecific";
interface Props {
    isEdit ? : boolean,
    addSchoolFromBackend ? : any
}
const SchoolAmenities = ({
        editImage,
        amenities,
        schoolAmenities,
        isEdit,
        addSchoolFromBackend
    }) => {
    const history = useHistory();

    const goToSchoolAmenities = () => {
        if(addSchoolFromBackend){
            history.push({
                pathname: "/backend/backend-details/schools/onboarding/school-specific",
                search: `isSummary=${true}`,
            });
        }else if (isEdit === true) {
            history.push({
                pathname: "/schools/school-details/details/school-classroom-specific/school",
                search: `isSummary=${true}`,
            });
        } else {
            history.push({
                pathname: "/schools/onboarding/school-specific",
                search: `isSummary=${true}`,
            });
        }
    };

    const goClassroomAmenities = () => {
        if(addSchoolFromBackend){
            history.push({
                pathname: "/backend/backend-details/schools/onboarding/classroom-inventories",
                search: `isSummary=${true}`,
            });
        }else if (isEdit === true) {
            history.push({
                pathname: "/schools/school-details/details/school-classroom-specific/classroom",
                search: `isSummary=${true}`,
            });
        } else {
            history.push({
                pathname: "/schools/onboarding/classroom-inventories",
                search: `isSummary=${true}`,
            })
        }
    }

    const gotoCommonArea = () => {
        if(addSchoolFromBackend){
            history.push({
                pathname: "/backend/backend-details/schools/onboarding/common-area-inventories",
                search: `isSummary=${true}`,
            });
        }else if (isEdit === true) {
            history.push({
                pathname: "/schools/school-details/details/school-classroom-specific/classroom",
                search: `isSummary=${true}`,
            });
        } else {
            history.push({
                pathname: "/schools/onboarding/common-area-inventories",
                search: `isSummary=${true}`,
            })
        }
    }

    const gotoOtherAreasAndStaff = () => {
        if(addSchoolFromBackend){
            history.push({
                pathname: "/backend/backend-details/schools/onboarding/other-area-and-staff-inventories",
                search: `isSummary=${true}`,
            });
        }else if (isEdit === true) {
            history.push({
                pathname: "/schools/school-details/details/school-classroom-specific/classroom",
                search: `isSummary=${true}`,
            });
        } else {
            history.push({
                pathname: "/schools/onboarding/other-area-and-staff-inventories",
                search: `isSummary=${true}`,
            })
        }
    }

    const gotoOutdoor = () => {
        if(addSchoolFromBackend){
            history.push({
                pathname: "/backend/backend-details/schools/onboarding/outdoor-inventories",
                search: `isSummary=${true}`,
            });
        }else if (isEdit === true) {
            history.push({
                pathname: "/schools/school-details/details/school-classroom-specific/classroom",
                search: `isSummary=${true}`,
            });
        } else {
            history.push({
                pathname: "/schools/onboarding/outdoor-inventories",
                search: `isSummary=${true}`,
            })
        }
    }

    return (
        <>
            {/* <div className='col-xl-12 col-lg-12 col-md-12 col-xs-12'>
                <div className='personal-box'>
                    <div className='row personal-box-top'>
                        <div className='row col-xl-8 col-lg-8 col-md-8 col-xs-8 start-md'>
                            <h3 className='htx2 mb20'>School Inventories</h3>
                        </div>
                        <div className='row col-xl-4 col-lg-4 col-md-4 col-xs-4 end-xs'>
                            {" "}
                            <a
                                href='javascript:void(0)'
                                className='editbtn'
                                onClick={goToSchoolAmenities}
                                >
                            <img src={editImage} /> Edit
                            </a>{" "}
                        </div>
                    </div>
                    <div className='personal-bottom'>
                        <div className='row personal-box-bot'>
                            <div className='col-xl-6 col-lg-6 col-md-6 col-xs-12'>
                                <p>School Specific</p>
                            </div>
                            <div className='col-xl-6 col-lg-6 col-md-6 col-xs-12'>
                                <p>Quantity</p>
                            </div>
                            {amenities.map((item, index) => {
                            return (
                            <>
                            <div
                                className='col-xl-6 col-lg-6 col-md-6 col-xs-12'
                                key={index}
                                >
                                <b>{item.name}</b>
                            </div>
                            <div className='col-xl-6 col-lg-6 col-md-6 col-xs-12'>
                                <b>{`${item.data.length}`}</b>
                            </div>
                            </>
                            );
                            })}
                        </div>
                    </div>
                </div>
            </div> */}
            <div className='col-xl-12 col-lg-12 col-md-12 col-xs-12'>
                <h3 className='htx2 mb20'>Inventories</h3>
            </div>
            <div className='col-xl-12 col-lg-12 col-md-12 col-xs-12'>
                <div className='personal-box'>
                    <div className='row personal-box-top'>
                        <div className='row col-xl-8 col-lg-8 col-md-8 col-xs-8 start-md'>
                            <h3 className='htx2 mb20'>Classroom Inventories</h3>
                        </div>
                        <div className='row col-xl-4 col-lg-4 col-md-4 col-xs-4 end-xs'>
                            {" "}
                            <a
                                href='javascript:void(0)'
                                className='editbtn'
                                onClick={goClassroomAmenities}
                                >
                            <img src={editImage} /> Edit
                            </a>{" "}
                        </div>
                    </div>
                    <div className='personal-bottom'>
                        <div className='row personal-box-bot'>
                            <InventorySpecific data={schoolAmenities.classroomAmenities} heading="Inventory" />
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-xl-12 col-lg-12 col-md-12 col-xs-12'>
                <div className='personal-box'>
                    <div className='row personal-box-top'>
                        <div className='row col-xl-8 col-lg-8 col-md-8 col-xs-8 start-md'>
                            <h3 className='htx2 mb20'>Common Area Inventory</h3>
                        </div>
                        <div className='row col-xl-4 col-lg-4 col-md-4 col-xs-4 end-xs'>
                            {" "}
                            <a
                                href='javascript:void(0)'
                                className='editbtn'
                                onClick={gotoCommonArea}
                                >
                            <img src={editImage} /> Edit
                            </a>{" "}
                        </div>
                    </div>
                    <div className='personal-bottom'>
                        <div className='row personal-box-bot'>
                            <InventorySpecific data={schoolAmenities.commonAreaAmenities}  heading="Common Area Inventory"/>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-xl-12 col-lg-12 col-md-12 col-xs-12'>
                <div className='personal-box'>
                    <div className='row personal-box-top'>
                        <div className='row col-xl-8 col-lg-8 col-md-8 col-xs-8 start-md'>
                            <h3 className='htx2 mb20'>Other Room & Staff Inventory</h3>
                        </div>
                        <div className='row col-xl-4 col-lg-4 col-md-4 col-xs-4 end-xs'>
                            {" "}
                            <a
                                href='javascript:void(0)'
                                className='editbtn'
                                onClick={gotoOtherAreasAndStaff}
                                >
                            <img src={editImage} /> Edit
                            </a>{" "}
                        </div>
                    </div>
                    <div className='personal-bottom'>
                        <div className='row personal-box-bot'>
                            <InventorySpecific data={schoolAmenities.otherRoomsAndStaffAmenities} heading="Other Room & Staff Inventory"/>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-xl-12 col-lg-12 col-md-12 col-xs-12'>
                <div className='personal-box'>
                    <div className='row personal-box-top'>
                        <div className='row col-xl-8 col-lg-8 col-md-8 col-xs-8 start-md'>
                            <h3 className='htx2 mb20'>Outdoor Inventory</h3>
                        </div>
                        <div className='row col-xl-4 col-lg-4 col-md-4 col-xs-4 end-xs'>
                            {" "}
                            <a
                                href='javascript:void(0)'
                                className='editbtn'
                                onClick={gotoOutdoor}
                                >
                            <img src={editImage} /> Edit
                            </a>{" "}
                        </div>
                    </div>
                    <div className='personal-bottom'>
                        <div className='row personal-box-bot'>
                            <InventorySpecific data={schoolAmenities.outdoorAmenities} heading="Outdoor Inventory"/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SchoolAmenities;
