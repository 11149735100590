import { useState, useContext, useEffect, useRef } from "react"

import { Link } from "react-router-dom"

import { useHistory } from "react-router-dom"

import FileUploader from "../../../../components/addon/fileUploader"

import backBtn from "../../../../assets/images/back-arrow.svg"

import { objectDeepClone, compareObjs } from "../../../../service/helper"

import Compress from "compress.js"
import ConfirmAlert from "../../../../components/addon/confirmAlert"

const ProfileVerification = (props) => {
    const {
        events,
        progress,
        brandProfile,
        parentData,
        isBackendUser
    } = props

    const history = useHistory()
    const compress = new Compress()

    const refGST = useRef()
    const refPAN = useRef()
    console.log("parentData={parentData}--->>>",parentData)
    useEffect(() => {
        if (events && events.setActiveModule) {
            events.setActiveModule({
                module: "verification"
            })
        }
        if (brandProfile && brandProfile.verification) {
            setFormData(objectDeepClone(brandProfile.verification))
            setOldFormData(objectDeepClone(brandProfile.verification))
        }
    }, [])

    const [formData, setFormData] = useState<any>({})
    const [showPrompt, setShowPrompt] = useState(false)
    const [oldFormData, setOldFormData] = useState<any>({})
    const [overlay, setOverlay] = useState(false)

    const fileUploadCallback = (type, url) => {
        setFormData({
            ...formData,
            [type]: {
                ...formData[type],
                image: url
            }
        })
    }

    useEffect(() => {
        const isSame = compareObjs(formData, oldFormData)
        console.log("isSame>>>>",isSame, formData, oldFormData);
        if (isSame === false) {
            setShowPrompt(true)
        } else {
            setShowPrompt(false)
        }
    }, [formData])

    const onFormSubmit = () => {
        setShowPrompt(false)
        setTimeout(() => {
        let gst = true
        let pan = true
        if (refGST && refGST.current) {
            const ref: any = refGST.current
            gst = ref.validatData()
        }
        if (refPAN && refPAN.current) {
            const ref: any = refPAN.current
            pan = ref.validatData()
        }
        if (gst && pan) {
            if (events && events.setBrandProfile) {
                let apiData = objectDeepClone(formData)
                progress.verification = true
                const json = objectDeepClone(brandProfile)
                if(apiData.gst.documentNo !== ""){
                    apiData.gst.status = "pending"
                }
                if(apiData.pan.documentNo !== ""){
                    apiData.pan.status = "pending"
                }
                json.verification = objectDeepClone(apiData)
                events.setBrandProfile(json)
                console.log("checkData----", json, apiData)
                if(isBackendUser){
                    history.push("/backend/backend-details/brands/brand-details/add-users")
                }else{
                    history.push("/brands/brand-details/profile/add-users")
                }
            }
        }
        }, 100);
        
    }

    const onFormDataChange = (type, docNo) => {
        setFormData({
            ...formData,
            [type]: {
                ...formData[type],
                documentNo: docNo.toUpperCase()
            }
        })        
    }

    console.log("showPrompt>>>",showPrompt);

    const confirmAlertCallback = (data) => {
        setOverlay(data.overlay)
        // setShowPrompt(false)
    }

    return (
        <>
            <div className={`ovelap ${overlay === true ? "active" : ""}`}></div>
            <div className="col-xl-9 col-lg-9 col-md-12 col-xs-12">
                <div className="rightPart noBack">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-xs-6">
                            <Link to={isBackendUser ? "/backend/backend-details/brands/brand-details/contact" :"/brands/brand-details/profile/contact"} className="backbtn dblock">
                            <img src={backBtn} />BACK </Link>
                        </div>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-20">
                            <h2 className="htx1 clearfix">Upload your company documents for verification</h2>
                        </div>
                        <div className="col-xl-7 col-lg-7 col-md-10 col-xs-12 mt-40 mb-20">
                            <div className="row">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-10">
                                    <FileUploader
                                        formKey="gst"
                                        documentName="GST Document"
                                        fileUploadCallback={fileUploadCallback}
                                        data={formData.gst}
                                        onDataChange={onFormDataChange}
                                        validationRule="gst_no" // custom form validator created inside FileUploader component
                                        ref={refGST}
                                        parentData={parentData}
                                        isBackendUser={isBackendUser}
                                    />
                                </div>
                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                    <FileUploader
                                        formKey="pan"
                                        documentName="PAN Card"
                                        fileUploadCallback={fileUploadCallback}
                                        data={formData.pan}
                                        onDataChange={onFormDataChange}
                                        validationRule="pan_card" // custom form validator created inside FileUploader component
                                        ref={refPAN}
                                        parentData={parentData}
                                        isBackendUser={isBackendUser}
                                    />
                                </div>
                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 pl-0 mt-30">
                                    <div className="forminpt fR">
                                        <a onClick={onFormSubmit} href="javascript:void(0);" className="btn btn-orange brand-btn">Next</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ConfirmAlert
                when={showPrompt}
                title="Leave page without saving..."
                cancelText="Cancel"
                okText="Confirm"
                onOK={() => true}
                onCancel={() => false}
                parentCallback={confirmAlertCallback}
            />
        </>
    )
}

export default ProfileVerification
