import { useState } from "react"

import Creatable from "react-select/creatable"

import errorSVG from "../../../assets/images/error.svg"
import helpImg from "../../../assets/images/help.svg"
import { validateCampaignName } from "../../../service/helper"

const CreatableSelect = (props) => {

    const {
        formData,
        formKey,
        formErrors,
        onDataChange,
        label,
        placeholder,
        options,
        wrapperClasses,
        formValidator,
        validation,
        showInfoIcon,
        onCreate,
        isDisable,
        helpText,
        emptyOptionsMessage,
        maxLength,
        validateOptionRule
    } = props

    const [wrapperClassList, setWrapperClassList] = useState(wrapperClasses ? wrapperClasses : "")
    const [errorMessage, setErrorMessage] = useState("")
    const [errorDisplay, setErrorDisplay] = useState(false)

    const customStyles = {
        option: (provided, state) => ({
          ...provided,
          fontSize:'14px',
          padding: '13px 12px',
          backgroundColor: state.isSelected ? "#ECECEC" : state.isFocused ? '#F8F8F8' : '#fff',
          color: "#000",
        }),
        noOptionsMessage: (provided, state) =>({
            ...provided,
          fontSize:'14px',
          padding: '13px 12px',
          color: !errorDisplay ? "#696969" : "#ff4a4a",
        })
    }

    const validateOption = (value) =>{
        let reg
        if(validateOptionRule){
            switch (validateOptionRule) {
                case "alpha_space":
                    reg = /^[a-zA-Z\s]*$/;
                    break;
                case "alpha_space_limit_special":
                    reg = /^[0-9a-zA-Z\s\(\)\&\,\')\(+=._-]+$/;
                    break;
                default:
                    break;
            }
            if(validateOptionRule === "campaign_name"){
                if(validateCampaignName(value)){
                    setErrorMessage("")
                    return true
                }
            }
            else if(reg.test(value)){
                setErrorMessage("")
                return true
            }else{
                let formattedFormKeyFormat = formKey.replace(/[A-Z]/g, letter => ` ${letter.toLowerCase()}`)
                switch (validateOptionRule) {
                    case "alpha_space":
                        reg = /^[a-zA-Z\s]*$/;
                        setErrorMessage(`The ${formattedFormKeyFormat} may only contain letters, and spaces.`)
                        break;
                    case "alpha_space_limit_special":
                        reg = /^[a-zA-Z\s\(\)\&\,\')\(+=._-]+$/;
                        setErrorMessage(`The ${formattedFormKeyFormat} may only contain letters, spaces and ( .,&()' ) this special characters.`)
                        break;
                    default:
                        break;
                }
                return false
            }
        }
        
    }
    const handleKeyDown = (e) => {
        // console.log(e.key)
        if(maxLength && e.target.value.length > maxLength - 1 && e.key !== "Backspace" && e.key !== "Delete" && e.key !== "ArrowRight" && e.key !== "ArrowLeft" && e.key !== "ArrowUp" && e.key !== "ArrowDown"){
            e.preventDefault()
        }
    }

    return (
        <>
        <div className={`${label ? "forminpt tooltip-relative" : ""} ${wrapperClassList}`}>
            {
                label &&  
                <>
                    <label>
                        {label}
                        {showInfoIcon &&
                            <a href="#" title="Wisr offering" className="tooltip"><img src={helpImg} /></a>
                        }
                    {helpText ? <a style={{cursor: "pointer"}} title={helpText} className="tooltip"><img src={helpImg} /></a> : "" }
                    </label>
                </> 
            }
            <Creatable
                classNamePrefix="wisr-react-select"
                className={formValidator?.current?.messagesShown === true && formValidator?.current?.fields?.[formKey] === false ? "onFocus" : ""}
                styles={customStyles}
                onKeyDown={handleKeyDown}
                options={options}
                onChange={onDataChange}
                placeholder={placeholder}
                isClearable={true}
                value={options.filter((option) => {
                    return option.value === formData[formKey]
                })}
                onCreateOption={(e)=>{onCreate(e)}}
                noOptionsMessage={(e)=>{
                    if(e.inputValue.length > 0 && errorMessage.length > 0){
                        // if(e.inputValue.length > maxLength){
                        setErrorDisplay(true)
                        return errorMessage
                        // }
                    }else{
                        setErrorDisplay(false)
                        return emptyOptionsMessage
                    }
                }}
                isDisabled={isDisable}
                isValidNewOption={(inputValue, selectValue) =>{
                    let allValidate = {maxLength: maxLength ? false : true, alpha_space: validateOptionRule ? false : true}
                    
                    if(validateOptionRule){
                        if(inputValue.length > 0){
                            allValidate.alpha_space = validateOption(inputValue)
                        }
                    }
                    if(maxLength){
                        if(inputValue.length > 0){
                            if(inputValue.length < maxLength){
                                // setErrorMessage("")
                                allValidate.maxLength =  true
                            }else{
                                let formattedFormKeyFormat = formKey.replace(/[A-Z]/g, letter => ` ${letter.toLowerCase()}`)
                                setErrorMessage(`The ${formattedFormKeyFormat} may not be greater than ${maxLength} characters.`)
                                allValidate.maxLength = false
                            }
                        }
                    }
                    if(maxLength === undefined && validateOptionRule === undefined){
                        return
                    }
                    if(allValidate.maxLength === true &&  allValidate.alpha_space === true){
                        return true
                    }else{
                        return false
                    }
                    
                }}
            />
            {formValidator?.current?.message(formKey, formData[formKey], validation)}
        </div>
        </>
    )
}

export default CreatableSelect
