import { useState, useContext, useEffect, useRef } from "react"

import { useHistory } from "react-router-dom"

import config from "../../../../service/config"
import master from "../../../../service/master"

import OnboardingBackBtn  from "../components/onboardingBackBtn"
import NewAtWisr from "../components/newAtWisr"
import WisrProTip from "../components/wisrProTip"

import schOver1 from "../../../../assets/images/sch-over1.svg"
import schOver2 from "../../../../assets/images/sch-over2.svg"
import schOver3 from "../../../../assets/images/sch-over3.svg"
import DataContext from "../../../../service/contextProvider"
import AppContextObject from "../../../../common/context/common"
import ImageModal from "../../../../components/addon/imageModal/imageModal"
import CampaignScheduleCard from "../components/campaignScheduleCard";
import blogImg from "../../../../assets/images/blog-img.png"

import InventoryBookingGraph from "../../../../components/addon/inventoryBookingGraph"
import BrandRequests from "../../../../components/addon/brandRequests"
import TopBar from "../components/topBar"

import { PublicContextInitiator } from "../../../apis/publicContextInitiator"
import { Steps } from "intro.js-react"

const Dashboard = (props) => {
    const {
        isEdit,
        toolTipLoaded,
        methods
    } = props
    const toolTipRef = useRef()

    const history = useHistory()
    const parentData = useContext < any > (DataContext)

    const [brandsRequestList, setBrandsRequestList] = useState([])
    const [schoolInfo, setSchoolInfo] = useState({
        name:"",
        state:"",
    })

    const [overviewList, setOverviewList] = useState([])

    const [schoolDetails, setSchoolDetails] = useState([])
    const [activities, setActivities] = useState([])
    const [schoolTableLoading, setSchoolTableLoading] = useState(false)
    const [activityLoading, setActivityLoading] = useState(false)
    const [mainInfoLoading, setMainInfoLoading] = useState(false)
    const [overviewListCount] = useState([1, 2, 3, 4, 5, 6])
    const [toggleImage, setToggleImage] = useState(false)
    const [imageData, setImageData] = useState("")
    const [showModal, setShowModal] = useState(false)
    const [showModalapproveAll, setShowModalapproveAll] = useState(false)
    const [showModalTwoMore, setShowModalTwoMore] = useState(false)
    const [wisrBlogs, setWisrBlogs] = useState([])
    
    const [overlay, setOverlay] = useState(false)
    const [newAtWisrIndex, setNewAtWisrIndex] = useState(0)
    const [notificationList, setNotificationList] = useState([])
    const [refetchNotification, setRefetchNotification] = useState(false);
    const [hasNewNotifications, setHasNewNotifications] = useState(false)

    const [requestLoader, setRequestLoader] = useState(false)

    const [inventoryBookings, setInventoryBookings] = useState(config.inventoryBookings);
    const context = useContext(AppContextObject)
    const [refetch, setRefetch] = useState(false)
    const [onBoarding, setOnboarding] = useState({
        stepsEnabled: false,
        initialStep: 0,
        steps: context.user.role === "schoolPrincipal" ? [
            {element: "#navBar", intro: "This is your navigation. Open various pages in your Wisr platform from here.", position: "right"},
            {element: "#navBarDashBoard", intro: "This is your Dashboard. Get all important information about brand activities in this page.", position: "right"},
            {element: "#navBarInventory", intro: "Access all the inventories you added in this page. Get more controls to add/remove inventories in this page.", position: "right"},
            {element: "#navBarSchool", intro: "Get all information about your school you added and view the campaign calender here", position: "right"},
            {element: "#brandReqCard", intro: "Get more details about campaigns and approve campaigns here.", position: "left"},
            {element: "#campaignSchedule", intro: "Get access to the next in-line campaigns in your school here.", position: "right"},
            {element: "#inventoryBookingStatus", intro: "View how inventory helps your school gain more income in this month flow. Click on different months to see the potential money your school can make with campaigns in those months.", position: "right"}
        ] : [
            {element: "#navBar", intro: "This is your navigation. Open various pages in your Wisr platform from here.", position: "right"},
            {element: "#navBarDashBoard", intro: "This is your Dashboard. Get all important information about brand activities in this page.", position: "right"},
            {element: "#navBarInventory", intro: "Access all the inventories you added in this page. Get more controls to add/remove inventories in this page.", position: "right"},
            {element: "#navBarSchool", intro: "Get all information about your school you added and view the campaign calender here", position: "right"},
            {element: "#brandReqCard", intro: "Get more details about campaigns and approve campaigns here.", position: "left"},
        ],
        options: {
            exitOnOverlayClick: false,
            showStepNumbers: true,
            hidePrev: true,
            hideNext: false,
            nextLabel: "Next",
            prevLabel: "Prev",
            doneLabel: "Got it",
            position: "top",
            autoPosition: false,
            exitOnEsc: false,
            scrollToElement: false,
            disableInteraction: true
        },
    })
    if (!context.apis || !context?.user?.role || !context?.socketManager.socket.connected) {
        document.location.reload()
    }
    // useEffect(() => {
    //     let steps = []
    //     if(context.user.role === "schoolPrincipal"){
    //         steps = [
    //             {element: "#navBar", intro: "This is your navigation. Open various pages in your Wisr platform from here.", position: "right"},
    //             {element: "#navBarDashBoard", intro: "This is your Dashboard. Get all important information about brand activities in this page.", position: "right"},
    //             {element: "#navBarInventory", intro: "Access all the inventories you added in this page. Get more controls to add/remove inventories in this page.", position: "right"},
    //             {element: "#navBarSchool", intro: "Get all information about your school you added and view the campaign calender here", position: "right"}
    //         ]
    //         if(document.getElementById("brandReqCard")){
    //             steps.push({element: "#brandReqCard", intro: "Get more details about campaigns and approve campaigns here.", position: "left"})
    //         }
    //         if(document.getElementById("campaignSchedule")){
    //             steps.push({element: "#campaignSchedule", intro: "Get access to the next in-line campaigns in your school here.", position: "right"})
    //         }
    //         if(document.getElementById("inventoryBookingStatus")){
    //             steps.push({element: "#inventoryBookingStatus", intro: "View how inventory helps your school gain more income in this month flow. Click on different months to see the potential money your school can make with campaigns in those months.", position: "right"})
    //         }
    //     }
    //     else{
    //         steps = [
    //             {element: "#navBar", intro: "View upcoming here", position: "right"},
    //             {element: "#navBarDashBoard", intro: "View upcoming here", position: "right"},
    //             {element: "#navBarInventory", intro: "View upcoming here", position: "right"},
    //             {element: "#navBarSchool", intro: "View upcoming here", position: "right"}
    //         ]
    //         if(document.getElementById("brandReqCard")){
    //             steps.push({element: "#brandReqCard", intro: "View upcoming here", position: "left"})
    //         }
    //     }
    //     setOnboarding({...onBoarding, steps: [...steps]})
    // }, [document.getElementById("brandReqCard")])
    useEffect(() => {
        if(toolTipLoaded){
            setTimeout(() => {
                setOnboarding({...onBoarding, stepsEnabled: parentData?.toolTipProgress?.dashBoard})
            }, 2000)
        }
    }, [toolTipLoaded])

    useEffect(() => {
        const currentUser = localStorage.getItem("currentUserId")
        if (currentUser !== null && context.user._id !== currentUser) {
            // document.location.reload()
        }
    }, [])

    const calculateStatus = () => {

        setInventoryBookings(items =>
            items.map(item => ({
              ...item,
              status: Math.round((item.bookedCount/(item.bookedCount + item.idleCount)) * 100)
            }))
          );
    }

    const onNext = () => {
        if(newAtWisrIndex < wisrBlogs.length - 1){
            setNewAtWisrIndex((prev) => prev+1)
        }
    }
    const onPrev = () => {
        if(newAtWisrIndex !== 0){
            setNewAtWisrIndex((prev) => prev-1)
        }
    }

    const getInventoryBookings =async () => {
        let resp = await context.apis[context.user.role].mySchoolBookingStats(
            context.validator, 
        )
    }

    useEffect(() => {
        // getWhatNewAtWisr()
        getWisrTip()
        getSchoolOverview()
        calculateStatus()
        // getInventoryBookings()

        // getAllBrandsRequests()
    }, [])
    useEffect(() => {
        getAllBrandsRequests()
    }, [refetch])

    useEffect(() => {
        fetchUserNotifications()
    }, [refetchNotification])

    const getAllBrandsRequests = async () => {
        setRequestLoader(true)
        let response = await context.apis.public.getCampaignsBySchool(
            context.validator, { schoolId: context.user.school, schoolStatus: "pending", isUpcoming: true }, {}
        )
        console.log("response----",response);
        if (response?.response?.allcampaigns) {
            setBrandsRequestList([...response.response.allcampaigns])
        }
        setRequestLoader(false)
    }
    // console.log("parentData",parentData)


    const getWhatNewAtWisr = async() => {
        let resp = await context.apis[context.user.role].getWisrBlogs(
            context.validator, {}, {}
            )
        let resp2 = await context.apis[context.user.role].getSchoolGeneralDetailsBySchoolId(
            context.validator, {_id: context.user.school}, {}
            )
        if(resp && resp.done){
            setWisrBlogs(resp.response.rows)
        }
    }
    const getWisrTip = async() => {
        let resp = await context.apis[context.user.role].getWisrProTip(
            context.validator, {}, {}
            )
    }

    const getSchoolOverview = async () =>{
        setMainInfoLoading(true)
        let resp = await context.apis[context.user.role].getSchoolOverview(
            context.validator, {}
            )
        if(resp && resp.done){
            setMainInfoLoading(false)
            setSchoolInfo({
                name: resp.response.schoolName,
                state: resp.response.state
            })
            setOverviewList([
                {
                    title: `${resp.response.builtUpArea} Sq Ft.`,
                    subTitle: "Total built-up area",
                    icon: schOver1
                },
                {
                    title: resp.response.inventoriesCount,
                    subTitle: "Total Inventories",
                    icon: schOver2
                },
                {
                    title: resp.response.onGoingCampaigns,
                    subTitle: "Ongoing Campaigns",
                    icon: schOver3
                },
                {
                    title: resp.response.CampaignsComplete,
                    subTitle: "Campaigns Completed",
                    icon: schOver3
                },
                {
                    title: resp.response.user,
                    subTitle: resp.response.role.toUpperCase() == "SCHOOLPRINCIPAL" 
                    ? "School Principal / Trustee" 
                    : resp.response.role.toUpperCase() == "SCHOOLADMIN" ? "School Admin"
                    : "School Principal / Trustee",
                    icon: schOver3
                },
            ])
        } else setMainInfoLoading(false)
    }

    const getSchoolDetailsTable = async() =>{
        setSchoolTableLoading(true)
        let resp = await context.apis[context.user.role].getDetailsForSchoolDetailsTable(
            context.validator, {}
            )
        if(resp && resp.done && !resp.response.rows){
            setSchoolTableLoading(false)
            setSchoolDetails([]);
            resp.response.map(am=>{
                setSchoolDetails(prev=>[
                    ...prev,
                    {
                        amenity: am.name,
                        assets: am.placements,
                        status: am.status,
                        images: am.images
                    }
                ])
            })
        }
    }

    const getActivitesTable = async() =>{
        setActivityLoading(true)
        let resp = await context.apis[context.user.role].getActivitiesForTable(
            context.validator, {}
            )
        if(resp && resp.done){
            setActivityLoading(false)
            setActivities([])
            resp.response.map(act=>{
                setActivities(prev=>[
                    ...prev,
                    {
                        name: act.name,
                        daysLeft: "14 days remaining",
                        date: act.months,
                        assets: act.assets,
                        assetsProvidedBy: act.assetsProvidedBy,
                        venue: act.venue,
                    }
                ])
            })
        }
    }

    const getStatusClass = (status) => {
        const statusName = status.toLowerCase()
        switch (statusName) {
            case "campaigning":
                return "btn-red-light"

            case "available":
                return "btn-green-light"

            case "booked":
                return "btn-orange-light"

            default:
                break
        }
    }

    const fetchAllData = () => {
        methods.getPersonalDetails()
        methods.getSchoolGeneralDetails()
        methods.getSchoolAmenitiesDetails()
        methods.getSchoolSpecificDetails()
        methods.getClassroomSpecificDetails()
        methods.getActivitiesAndStudentLifeDetails()
        process.nextTick(() =>{
            history.push("/schools/school-details/details/your-details")
        })
    }

    const openImageModal = (data) =>{
        setImageData(data)
        setToggleImage(true)
    }

    const confirmClose = () => {
        setToggleImage(false)
    }

    const openModal = () => {
        setShowModal(true)
        setOverlay(true)
    }
    const openModelApproveAll = () =>{
        setShowModalapproveAll(true)
        setOverlay(true)
    }
    const openModalTwoMore = () => {
        setShowModalTwoMore(true)
        setOverlay(true)
    }

    const fetchUserNotifications = async () =>{
        let resp = await context.apis[context.user.role].getUserNotifications(
            context.validator, {user: context.user._id, role: context.user.role}
        )
        if(resp && resp.done){
            setNotificationList(resp.data)
            let found = resp.data.find(nots=>nots.seen === false)
            if(found){
                setHasNewNotifications(true)
            }else{
                setHasNewNotifications(false)
            }
        }
    }

    const updateUsersToolTipProgress = async () => {
        let userToolTipResp = await context.apis[context.user.role].updateUsersToolTipProgress(
            context.validator, {}, {toolTipProgress: {...parentData.toolTipProgress, dashBoard: false}}
        )
        console.log(userToolTipResp)
    }

    const hanldeOnboardingComplete = () => {
        updateUsersToolTipProgress()
        parentData.setToolTipProgress({
            ...parentData.toolTipProgress,
            dashBoard: false
        })
        // let progress = {
        //     ...parentData.tooltipProgress,
        //     mySchool: false
        // }
        // localStorage.setItem("tooltipProgress", JSON.stringify(progress))
    }
    const exitOnBoarding = (e) => {
        console.log(e)
    }

    return (
        <>
            <div className={`ovelap ${overlay === true ? "active" : ""}`}></div>
            <div className="col-xl-10 col-lg-12 col-md-12 col-xs-12">
                <div className="rightPart noBack">
                <div className="row">
                    <div className="col-xs-12 col-lg-12 col-md-12 col-xs-12">
                        <OnboardingBackBtn
                            link="/"
                            text="Log Out"
                        />
                    </div>
                    <TopBar isDashboard={true} />
                    <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-40"></div>
                    <div className="col-xl-8 col-lg-8 col-md-12 col-xs-12 pl">
                        <CampaignScheduleCard refetch={refetch} />
                        <InventoryBookingGraph
                            refetch={refetch}
                            // inventoryBookings={inventoryBookings}
                        />
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-12 col-xs-12 pr">
                        {
                            (requestLoader === false) && // && brandsRequestList.length > 0
                            <BrandRequests
                                refetch={refetch}
                                setRefetch={setRefetch}
                                brandsRequestList={brandsRequestList}
                                getAllBrandsRequests={getAllBrandsRequests}
                            />
                        }
                    </div>
                    <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-20"></div>
                    {/* <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12 pl"> */}

                        {/* {wisrBlogs.length !== 0 &&  */}
 
                            <NewAtWisr
                            type={"school"}
                            />
                        {/* } */} 
                    {/* </div> */}
                    <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12 pl">
                        <WisrProTip 
                         wisrProTips={config.wisrProTipsSchool}
                         type={"school"}
                        />
                    </div>
                </div>
                </div>
            </div>

            <ImageModal
                togglePopup={toggleImage}
                displayImage={imageData} 
                onModalClose={confirmClose}
            />
            <Steps
                enabled={onBoarding.stepsEnabled}
                steps={onBoarding.steps}
                initialStep={onBoarding.initialStep}
                onComplete={hanldeOnboardingComplete}
                onExit={exitOnBoarding}
                options={onBoarding.options}
                ref={toolTipRef}
            />
        </>
    )
}

export default Dashboard
