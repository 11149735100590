import { Route, Redirect } from "react-router-dom"

import authService from "../service/auth"
import { useContext } from "react"
import AppContextObject from "../common/context/common"

export const ProtectedRoute = (props) => {

    const {
        component: Component,
        auth,
        ...rest
    } = props

    const context = useContext(AppContextObject)
    return (
        <Route
            {...rest}
            render={routeProps => {
                if (authService.checkAuth()) {
                    console.log("protected welcoming");
                    return <Component {...routeProps} />
                } else {
                    console.log("protected returning");
                    return (
                        <Redirect
                            to={{
                                pathname: "/login",
                                state: {
                                    from: routeProps.location
                                }
                            }}
                        />
                    )
                }
            }}
        />
    )
}
