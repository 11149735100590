
const BookingSVG = () => {
    return (
        <svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.07422 7.42345H15.3334" stroke="#070707" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M11.4349 1.5V4.13262" stroke="#070707" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M6.33341 12.2363L7.65727 13.5622L10.3848 10.8333" stroke="#070707" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M4.97258 1.5V4.13262" stroke="#070707" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
            <rect x="1" y="3.10007" width="14.4" height="14.4" rx="3" stroke="#070707" strokeWidth="1.2"/>
        </svg>
    )
}

export default BookingSVG
