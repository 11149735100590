import React, { useRef, useContext } from "react";
import useClickOutside from '../../../../service/useClickOutside';
import { Link } from "react-router-dom";
import arrowUpDown from "../../../../assets/images/backendAdmin/arrow-up-down.svg";
import editBtn from "../../../../assets/images/backendAdmin/edit-btn.svg";
import tableFilter from "../../../../assets/images/table-filter.svg";
import Pagination from "../../../../components/addon/pagination";
import CategoryFilter from "./CategoryFilter";
import AppContextObject from '../../../../common/context/common'

const FOSTable = ({ dataList, setCurrentpage, currentPage, totalPage, onPageChange, onNextClick, onPrevClick,
    cityFilter, setIsFOSEdit, setUserId, cityFilterModal, setOverlay,
    setFilterCity, filterCity, setCityFilterModal, loading, region, totalPageCount
}) => {
    let context = useContext(AppContextObject)
    const ref = useRef(null);
    useClickOutside(ref, () => { setCityFilterModal(false) })
    return (
        <div className="table-container table-inventory">
            <table className="table">
                <thead>
                    <tr ref={ref}>
                        <th>Sl no</th>
                        <th>FOS Agency name</th>
                        <th>region</th>
                        <th className="p-relative tableDropdown">city <a href="javascript:void(0);"><img src={tableFilter} onClick={cityFilter} /></a>
                            {cityFilterModal && <CategoryFilter
                                setFilterCity={setFilterCity}
                                filterCity={filterCity}
                                setCurrentpage={setCurrentpage}
                                region={region}
                            />}
                        </th>
                        <th>Schools onboarded</th>
                        <th>Action</th>
                    </tr>
                </thead>


                <tbody>
                    {!loading ?
                        <>
                            {dataList && dataList.length > 0 ?
                                <>
                                    {dataList.map((user, key) => {
                                        return (
                                            <tr key={key}>
                                                <td>{(key + 1) + (currentPage - 1) * 10}</td>
                                                <td>{user.fullName ? user.fullName : '--'}</td>
                                                <td>{user.region ? user.region : '--'}</td>
                                                <td>{user.city ? user.city : '--'}</td>
                                                <td>{user.schoolsOnboarded}</td>
                                                <td>
                                                    {(context.user.role === "backendAdmin" || context.user.role === "backendOperations" || context.user.role === "backendTech") &&
                                                        <a href="javascript:void(0);"><img src={editBtn} onClick={() => { setIsFOSEdit(true); setUserId(user._id); setOverlay(true); }} /></a>
                                                    }
                                                    <Link
                                                        to={{
                                                            pathname: "/backend/backend-details/fos-details",
                                                            state: { userId: user._id, email: user.email }
                                                        }}> <img className="updownarrow" src={arrowUpDown} onClick={() => setUserId(user._id)} /> 
                                                    </Link>
                                                </td>
                                            </tr>
                                        )
                                    })
                                    }
                                </>
                                :
                                <tr><td colSpan={7}>No FOS found</td></tr>
                            }
                        </>
                        :
                        [...Array(10)].map((el, index) => {
                            return (
                                <tr>
                                    {
                                        [...Array(6)].map((el, index) => {
                                            return (
                                                <td key={index}>
                                                    <div className="content-loader-block"></div>
                                                </td>
                                            )
                                        })
                                    }
                                </tr>
                            )
                        })

                    }

                </tbody>

            </table>
            {totalPageCount>10 &&<Pagination
                currentPage={currentPage}
                totalPage={totalPage}
                onPageChange={onPageChange}
                onNextClick={onNextClick}
                onPrevClick={onPrevClick}
                cityFilterModal={cityFilterModal}
                loading={loading}
            />}
        </div>
    )
}
export default FOSTable;

