import {
    useContext
} from "react"

import AppContextObject from "../../common/context/common"

import ApprovalDeadLine from "./approvalDeadLine"

import infoIcon from "../../assets/images/info-icon.svg"

import { formatDateToDDMMMYY } from "../../service/helper"

const ApproveAllBrandsRequestModal = (props) => {

    const {
        showModalapproveAll,
        closeModal,
        requestList,
        approveRequest,
        viewDetails
    } = props

    const context = useContext(AppContextObject)

    return (
        <>
            <div id="approvalAll" className={`popup ${showModalapproveAll === false ? "hide" : ""}`}>
                <div className="popup-header">
                    <h3 className="htx2 pophead">Brand Requests</h3>
                    <span className="close toggle" onClick={closeModal} data-target="myPopup">close</span>
                </div>
                <div className="popup-body">
                    <div className="viewdetailsHead">
                        {
                            requestList.map((request, index) => {
                                return (
                                    <div className="approveall-div" key={index}>
                                        <h2 className="htx2">{request?.brandName}
                                        {
                                            request?.approvalDeadLine &&
                                            <ApprovalDeadLine
                                                approvalDeadLine={request.approvalDeadLine}
                                            />
                                        }
                                        </h2>
                                        <div className="approveall-text">
                                        {(context.user && context.user.role === "schoolPrincipal" && request.estimatedRevenue) &&
                                            <div className="brand-creat-img">
                                                <h3>Estimated Revenue</h3>
                                                <h4>{request.estimatedRevenue}</h4>
                                            </div>
                                        }
                                        {
                                            (request?.startDate && request?.endDate) &&
                                            <div className="brand-creat-img">
                                                <h3>Duration</h3>
                                                <h4>{formatDateToDDMMMYY(request.startDate)} - {formatDateToDDMMMYY(request.endDate)}</h4>
                                            </div>
                                        }
                                        </div>
                                        <div className="brand-btn">
                                            <a onClick={() => viewDetails(index)} href="javascript:void(0);" className="btn btn-outline">More Details</a>
                                            <a onClick={() => approveRequest(index)} href="javascript:void(0);" className="btn btn-orange">Approve</a>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>        
        </>
    )
}

export default ApproveAllBrandsRequestModal
