import { useState } from "react"

import errorSVG from "../../assets/images/error.svg"

const NumberField = (props) => {

    const {
        formData,
        extraChar,
        formKey,
        formErrors,
        onDataChange,
        label,
        placeholder,
        onVerifyClick,
        classes,
        wrapperClasses,
        isVerified,
        maxLength,
        formValidator,
        validation,
        allowDecimal
    } = props

    const [classList, setClassList] = useState(classes ? classes : "")
    const [wrapperClassList, setWrapperClassList] = useState(wrapperClasses ? wrapperClasses : "")
    
    const onValueChange = (e) => {
        // const re = /^[0-9\b]+$/
        const re = allowDecimal ? /^\d*\.?\d*$/ : /^[0-9\b]+$/
        // if value is not blank, then test the regex
        if (e.target.value === '' || re.test(e.target.value)) {
            if(e.target.value.length<6){
                onDataChange(e)
            }
        }
    }

    return (
        <>
        {label !== true ?
            <div className={`${label ? "forminpt" : extraChar ? "form-text" : ""} ${wrapperClassList} `}>
                {
                    label &&
                    <label>{label}</label>
                }
                {extraChar &&<label htmlFor={formKey} className="static-value">&#8377;</label>}
                <input
                    id={formKey}
                    value={formData && formData[formKey]}
                    onChange={onValueChange}
                    className={` ${classList} ${(formValidator?.current?.messagesShown === true && formValidator?.current?.fields?.[formKey] === false) ? "onFocus" : ""}`}
                    placeholder={placeholder}
                    maxLength={maxLength}
                    pattern="[0-9]*"
                    type="text" />
                    {formValidator?.current?.message(formKey, formData[formKey], validation)}
            </div>
            :
            <>
                <input
                    id={formKey}
                    value={formData && formData[formKey]}
                    onChange={onValueChange}
                    className={` ${classList} ${(formValidator?.current?.messagesShown === true && formValidator?.current?.fields?.[formKey] === false) ? "onFocus" : ""}`}
                    placeholder={placeholder}
                    maxLength={maxLength}
                    pattern="[0-9]*"
                    type="text" />
                    {formValidator?.current?.message(formKey, formData[formKey], validation)}
            </>
        }
        </>
    )
}

export default NumberField
