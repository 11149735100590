import React, { useState } from "react";
import { formatDateShowMonthBackend } from "../../../../service/helper";
import arrowUpDown from "../../../../assets/images/backendAdmin/arrow-up-down.svg";
import blankImg from '../../../../assets/images/blank-inventory-img.jpg'
import { Link } from "react-router-dom";

const TableSpan = ({ campaign, keyNo, campaignType }) => {
    const [openSpan, setOpenSpan] = useState(false);
    // console.log('campaigns', campaign,keyNo)
    return (
        <>
            <tr>
                <td>{keyNo + 1}</td>
                <td>{campaign.name}</td>
                <td>{campaign.schools.length}</td>
                <td>{campaign?.campaignData?.inventories?.length || "-"}</td>
                <td>{formatDateShowMonthBackend(campaign.startDate, 2)} - {formatDateShowMonthBackend(campaign.endDate, 2)}</td>
                <td>₹ {campaign.budget.toLocaleString('en-IN')}</td>
                <td><span className={`table-status redbg`}>{campaign.campaignStatus}</span>
                    <span onClick={() => setOpenSpan(!openSpan)}>
                        <svg className="down-arrow requestArrow" width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M1 6L6 0.999999L11 6" stroke="#8C8C8C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </span>
                    <Link to={{
                        pathname: "/backend/backend-details/brand/campaign-details",
                        state: { campaignType: campaignType, from:"brand", campaignId:campaign._id }
                    }}>
                        <span>
                            <img className="updownarrow" src={arrowUpDown} />
                        </span>
                    </Link>

                </td>
            </tr>
            {openSpan && <tr>
                <td className="campaignRequestOepn" colSpan={8}>
                    <table className="table">
                        <tbody>
                            <td>
                                <div className="campaignRequestSlide">
                                    <div className="campaignRequestSlideLarge">
                                        <img src={campaign.campaignCreatives[0] && campaign.campaignCreatives[0].image ? campaign.campaignCreatives[0].image : blankImg} />
                                    </div>
                                    <div className="campaignRequestSlideSmall">
                                        <img src={campaign.campaignCreatives[1] && campaign.campaignCreatives[1].image ? campaign.campaignCreatives[1].image : blankImg} />
                                        <img src={campaign.campaignCreatives[2] && campaign.campaignCreatives[2].image ? campaign.campaignCreatives[2].image : blankImg} />
                                    </div>
                                </div>
                            </td>
                            <td>
                                <h4>Campaign Description</h4>
                                <p>{campaign.description}</p>
                                <div className="mt-20 clearfix"></div>
                            </td>
                            <td style={{ verticalAlign: "bottom", width: "340px" }}></td>
                        </tbody>
                    </table>
                </td>
            </tr>}
        </>

    )
}
export default TableSpan;
