import { Link, useHistory } from "react-router-dom"

import campLogo1 from "../../../../assets/images/camp-logo-1.png"
import campLogo2 from "../../../../assets/images/camp-logo-2.png"
import campLogo3 from "../../../../assets/images/camp-logo-3.png"
import riarrownext from "../../../../assets/images/right-arrow-next.svg"
import arrowView from "../../../../assets/images/arrow-view.svg"
import { useState, useContext, useEffect } from "react"
import AppContextObject from "../../../../common/context/common"
import { PublicContextInitiator } from "../../../apis/publicContextInitiator"

const CampaignScheduleCard = ({refetch}) => {

    const context = useContext(AppContextObject)
    let publicContextInitiator = new PublicContextInitiator(context)
    const [campaignCardData, setCampaignCardData] = useState([]);
    const [noOfBlankBoxes, setNoOfBlankBoxes] = useState([])
    const history = useHistory()
    useEffect(() => {
        getCampaignData()
    }, [refetch])
    const getCampaignData = async () => {
        let resp = await publicContextInitiator.getCampaignsBySchool(
            context.validator, { schoolId: context.user.school, schoolStatus: "accepted", isUpcoming: true }, {}
        )
        if (resp && resp.done) {
            console.log('campaign schedule data====>',resp.response.allcampaigns)
            setCampaignCardData(resp.response.allcampaigns)
            // resp.response.allcampaigns.map(evt => {
            //     console.log('evt====>',evt)
            //     setCampaignCardData(prev => [
            //         ...prev,
            //         evt
            //     ])
            // })
        }
    }
    const goToMySchool = (data) => {
        history.push({
            pathname: "/schools/school-details/my-school",
            state: {campaignData: data },
        })
    }
    return (
        <div id="campaignSchedule" className="row">
            <div className="col-xl-6 col-lg-6 col-md-6 col-xs-12">
                <h2 className="htx2 clearfix">Campaign Schedule</h2>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-xs-12">
                <Link to="/schools/school-details/my-school" className="viewwLink">View Calendar <img className="arrowView" src={arrowView} /></Link>
            </div>
            {
                campaignCardData.length !== 0 ?
                [...Array(3)].map(( el, index) => {
                    return (
                        <div key={index} className="col-xl-4 col-lg-4 col-md-6 col-xs-12">
                            {/* <div className={`campaign-box ${data.bgColor}`}> */}
                            {
                                    campaignCardData[index] ? 
                                    <div key={''} className={`campaign-box campaign-bg${index + 1}`}>

                                    <span className="camgtoplogo"><img src={campaignCardData[index].brandLogo} /></span>
                                    <h3>{campaignCardData[index].brandName} - {campaignCardData[index].campaignName}</h3>
                                    <p>Start Date : <b>{campaignCardData[index].startDate}</b></p>
                                    <p>End Date : <b>{campaignCardData[index].endDate}</b></p>
                                    <a onClick={() => goToMySchool(campaignCardData[index])} href="javascript:void(0);"><img className="next-arow" src={riarrownext} /></a> 
                                </div>
                                   :
                                   <div className="campaign-box campaign-bg4"></div>
                            }

                        </div>
                      )
                    })
                :
                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mt-20">
                    <div className="noData height-one"> 
                    <h3>No Campaign schedule</h3>
                    </div>
                </div>

            }
        </div>
    )
}
export default CampaignScheduleCard

