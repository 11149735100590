
const ConfirmedApprovalModal = (props) => {

    const {
        showConfirmedApprovalModal,
        closeModal
    } = props

    return (
        <div id="confirmedApprovalModal" className={`popup ${showConfirmedApprovalModal === false ? "hide" : ""}`}>
            <div className="popup-header">
                <h3 className="htx2 pophead">Campaign Approval</h3>
                <span className="close toggle"  onClick={closeModal} data-target="myPopup">close</span>
            </div>
            <div className="popup-body">
                <div className="viewdetailsHead">
                    <div className="approveActive">
                        <p>Campaign approved. We will go live with campaign in 48 hours.
                        </p>
                        <div className="brand-btn">
                            <a onClick={closeModal} href="javascript:void(0)" className="btn btn-orange">Done</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>        
    )
}

export default ConfirmedApprovalModal
