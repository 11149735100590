

import React, { useState, useContext } from "react";
import toast from "react-hot-toast";
import AppContextObject from "../../common/context/common";
// import "./Modal.css";

export default function DotDrop(props) {
  const {
    locationId,
    refetchLocations,
    ip
  } = props
  const [modal, setModal] = useState(false);

  const toggleModal = () => {
    setModal(!modal);
  };

  const context = useContext(AppContextObject)

  console.log("locationId>>>",locationId);
  const deleteUserLocation = async () => {
    if(locationId){
      let resp = await context.apis[context.user.role].deleteUserLocation(
        context.validator, {_id: locationId}
      )
      console.log("resp>.>>",resp);
    }
  }
  const logoutUser = () =>{
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({role: context.user.role, ip: ip})
    };

    fetch("/api/logoutFromDevice", requestOptions)
    .then(response => response.json())
    .then(data => {
      console.log("data>>>",data);
    }).catch(err=>{
        console.log("err>>",err);
        console.log(err);
    })
}

  const logOutClicked = () => {
    deleteUserLocation();
    logoutUser();
    toast.success("User Logged out!")
    toggleModal()
    refetchLocations()
  }

  return (
    <>
      {/* <button onClick={toggleModal} className="btn-modal">
        Open
      </button> */}
      <svg className="dotdrop" onClick = {toggleModal} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 18c1.657 0 3 1.343 3 3s-1.343 3-3 3-3-1.343-3-3 1.343-3 3-3zm0-9c1.657 0 3 1.343 3 3s-1.343 3-3 3-3-1.343-3-3 1.343-3 3-3zm0-9c1.657 0 3 1.343 3 3s-1.343 3-3 3-3-1.343-3-3 1.343-3 3-3z"/></svg> 
      {modal && (
        <div className="modal">
          <div onClick={toggleModal} className="overlay"></div>
          <div className="modal-content" onClick = {logOutClicked}>
            <h4>Logout</h4>
            {/* <button className="close-modal" onClick={toggleModal}>
              CLOSE
            </button> */}
          </div>
        </div>
      )}
    </>
  );
}