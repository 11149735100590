export default {
    GOOGLE_MAPS_API_KEY: "AIzaSyCBB_79wh_bRPXZfHx7Pnt-r05Lyy0vg_8",
    brandsCategories: [
        {
            label: "IT",
            value: "it"
        },
        {
            label: "Food",
            value: "food"
        },
        {
            label: "Drinks",
            value: "Drinks"
        }
    ],
    availability: [
        { label: "Available", value: "Available" },
        { label: "Unavailable", value: "Unavailable" }
    ],
    // wisrProTips: [
    //     {
    //         description: "Update and add inventory blocks on a monthly basis to increase your revenue."
    //     },
    //     {
    //         description: "Always share images that follow Wisr guidelines and clearly dispaly the inventory."
    //     },
    //     {
    //         description: "Assist brands and organize brand activites around thier activations to ensure maximum student interaction."
    //     }
    // ],
    wisrProTipsBrand: [
        {
            description: "Use a combination of events, occasions, and inventory for a customised and effective campaign."
        },
        {
            description: "Choose multiple inventories at strategic touchpoints across schools for optimum impressions."
        },
        {
            description: "Run your campaign for a longer duration and avail of the WISR option for unprecedented reach."
        }
    ],
    wisrProTipsSchool: [
        {
            description: "Update and add inventory blocks on a monthly basis to increase your revenue."
        },
        {
            description: "Always share images that follow WISR guidelines and clearly dispaly the inventory.."
        },
        {
            description: "Assist brands and organize brand activites around thier activations to ensure maximum student interaction."
        }
    ],

    inventoryBookings: [
        { month: "Jan", estimatedRevenue: "₹ 14,957", bookedCount: 50, idleCount: 50, brandPartners: ["Lifeboy", "Nike", "Apsara", "Milton", "Maggi", "Britania", "Haldiram", "Paper Boat"], status: 0 },
        { month: "Feb", estimatedRevenue: "₹ 21,955", bookedCount: 20, idleCount: 80, brandPartners: ["Navneet", "Sundaram", "Parle G", "Puma", "Mother Dairy", "Amul"], status: 0 },
        { month: "Mar", estimatedRevenue: "₹ 66,957", bookedCount: 10, idleCount: 25, brandPartners: ["Britania", "Nestle", "Cadbury", "Navneet", "Maggi", "Sundaram", "Hershey", "Nike", "Apsara", "Balaji",], status: 0 },
        { month: "Apr", estimatedRevenue: "₹ 25,957", bookedCount: 68, idleCount: 25, brandPartners: ["Kissan", "Nestle", "Apsara", "Maggi", "Britania"], status: 0 },
        { month: "May", estimatedRevenue: "₹ 20,957", bookedCount: 22, idleCount: 25, brandPartners: ["Vadilal", "Nike", "Apsara", "Godrej", "Fortune", "Britania"], status: 0 },
        { month: "Jun", estimatedRevenue: "₹ 90,957", bookedCount: 60, idleCount: 40, brandPartners: ["Godrej", "Balaji", "Apsara", "Fortune", "Maggi", "Paper Boat"], status: 0 },
        { month: "Jul", estimatedRevenue: "₹ 20,957", bookedCount: 82, idleCount: 25, brandPartners: ["Balaji", "Nestle", "Apsara", "Britania"], status: 0 },
        { month: "Aug", estimatedRevenue: "₹ 94,957", bookedCount: 30, idleCount: 30, brandPartners: ["Haldiram", "Paper Boat", "Hershey", "Fortune", "Maggi", "Godrej", "Nestle"], status: 0 },
        { month: "Sep", estimatedRevenue: "₹ 4,957", bookedCount: 74, idleCount: 25, brandPartners: ["Fortune", "Hershey", "Apsara", "Balaji", "Maggi", "Britania"], status: 0 },
        { month: "Oct", estimatedRevenue: "₹ 30,957", bookedCount: 40, idleCount: 60, brandPartners: ["Paper Boat", "Fortune", "Hershey", "Godrej", "Balaji", "Paper Boat", "Apsara", "Nestle"], status: 0 },
        { month: "Nov", estimatedRevenue: "₹ 57,957", bookedCount: 84, idleCount: 25, brandPartners: ["Hershey", "Nike", "Apsara", "Milton", "Maggi", "Balaji"], status: 0 },
        { month: "Dec", estimatedRevenue: "₹ 36,957", bookedCount: 5, idleCount: 20, brandPartners: ["Nestle", "Hershey", "Fortune", "Milton", "Maggi", "Godrej"], status: 0 },
    ],
    inventoryCategories: [{
        label: "Classroom",
        value: "classroom"
    },
    {
        label: "Common Area",
        value: "commonArea"
    },
    {
        label: "Staff and Miscellaneous rooms",
        value: "otherRooms&Staff"
    },
    {
        label: "Outdoor",
        value: "outdoor"
    },
    {
        label: "Events&Occasions",
        value: "events&Occasions"
    }
    ],
    registerActs: [{
        label: "Indian Trusts Act 1982",
        value: "Indian Trusts Act 1982"
    },
    {
        label: "Section 8 of Companies Act 2013",
        value: "Section 8 of Companies Act 2013"
    },
    {
        label: "Section 25 of Companies Act 1956",
        value: "Section 25 of Companies Act 1956"
    },
    {
        label: "Societies Registration Act 1860",
        value: "Societies Registration Act 1860"
    },
    {
        label: "Other",
        value: "other"
    }
    ],
    languages: [{
        label: "Assamese",
        value: "Assamese",
    },
    {
        label: "Bengali (Bangla)",
        value: "Bengali (Bangla)",
    },
    {
        label: "Bodo",
        value: "Bodo",
    },
    {
        label: "Dogri",
        value: "Dogri",
    },
    {
        label: "English",
        value: "English",
    },
    {
        label: "Gujarati",
        value: "Gujarati",
    },
    {
        label: "Hindi",
        value: "Hindi",
    },
    {
        label: "Kannada",
        value: "Kannada",
    },
    {
        label: "Kashmiri",
        value: "Kashmiri",
    },
    {
        label: "Konkani",
        value: "Konkani",
    },
    {
        label: "Maithili",
        value: "Maithili",
    },
    {
        label: "Malayalam",
        value: "Malayalam",
    },
    {
        label: "Manipuri (Meitei)",
        value: "Manipuri (Meitei)",
    },
    {
        label: "Marathi",
        value: "Marathi",
    },
    {
        label: "Nepali",
        value: "Nepali",
    },
    {
        label: "Oriya (Odia)",
        value: "Oriya (Odia)",
    },
    {
        label: "Punjabi",
        value: "Punjabi",
    },
    {
        label: "Sanskrit",
        value: "Sanskrit",
    },
    {
        label: "Santali",
        value: "Santali",
    },
    {
        label: "Sindhi",
        value: "Sindhi",
    },
    {
        label: "Tamil",
        value: "Tamil",
    },
    {
        label: "Telugu",
        value: "Telugu",
    },
    {
        label: "Urdu",
        value: "Urdu",
    },
    {
        label: "Custom",
        value: "Custom",
    },
    ],
    schoolTypes: [{
        label: "Boys",
        value: "boys"
    },
    {
        label: "Girls",
        value: "girls"
    },
    {
        label: "Co-ed",
        value: "co-ed"
    },
    {
        label: "Custom",
        value: "Custom"
    }
    ],
    schoolAmenities: [{
        name: "Auditorium",
        _checked: false,
        quantity: 1,
        data: [{
            name: "Auditorium 1",
            images: [],
            count: 1,
            customName: "",
            attr: {
                stage: {
                    quantity: "",
                    units: "feet",
                    length: "",
                    height: "",
                    breadth: "",
                },
                screens: {
                    quantity: "",
                    units: "feet",
                    length: "",
                    height: "",
                    breadth: "",
                },
            },
        }],
    },
    {
        name: "Canteen",
        customName: "",
        _checked: false,
        quantity: 1,
        data: [{
            name: "Canteen 1",
            images: [],
            count: 1,
            customName: "",
            attr: {
                walls: {
                    quantity: "",
                    units: "feet",
                    length: "",
                    height: "",
                    breadth: "",
                },
                menu: {
                    quantity: "",
                    units: "feet",
                    length: "",
                    height: "",
                    breadth: "",
                },
                trays: {
                    quantity: "",
                    units: "feet",
                    length: "",
                    height: "",
                    breadth: "",
                },
                tables: {
                    quantity: "",
                    units: "feet",
                    length: "",
                    height: "",
                    breadth: "",
                },
                chairs: {
                    quantity: "",
                    units: "feet",
                    length: "",
                    height: "",
                    breadth: "",
                },
            },
        },],
    },
    {
        name: "Assembly Hall",
        _checked: false,
        quantity: 1,
        data: [{
            name: "Assembly Hall 1",
            customName: "",
            images: [],
            count: 1,
            attr: {
                stage: {
                    quantity: "",
                    units: "feet",
                    length: "",
                    height: "",
                    breadth: "",
                },
                wall: {
                    quantity: "",
                    units: "feet",
                    length: "",
                    height: "",
                    breadth: "",
                },
            },
        },],
    },
    {
        name: "Staff Room",
        _checked: false,
        quantity: 1,
        data: [{
            name: "Staff Room 1",
            customName: "",
            images: [],
            count: 1,
            attr: {
                doors: {
                    quantity: "",
                    units: "feet",
                    length: "",
                    height: "",
                    breadth: "",
                },
                walls: {
                    quantity: "",
                    units: "feet",
                    length: "",
                    height: "",
                    breadth: "",
                },
                lockers: {
                    quantity: "",
                    units: "feet",
                    length: "",
                    height: "",
                    breadth: "",
                },
            },
        },],
    },
    {
        name: "Notice Board",
        _checked: false,
        quantity: 1,
        data: [{
            name: "Notice Board 1",
            customName: "",
            images: [],
            count: 1,
            attr: {
                board: {
                    quantity: "",
                    units: "feet",
                    length: "",
                    height: "",
                    breadth: "",
                },
            },
        },],
    },
    {
        name: "Sports Ground",
        _checked: false,
        quantity: 1,
        data: [{
            name: "Sports Ground 1",
            customName: "",
            images: [],
            count: 1,
            attr: {
                walls: {
                    quantity: "",
                    units: "feet",
                    length: "",
                    height: "",
                    breadth: "",
                },
            },
        },],
    },
    {
        name: "School Bus",
        quantity: 1,
        _checked: false,
        data: [{
            name: "School Bus 1",
            customName: "",
            images: [],
            count: 1,
            attr: {
                seatCover: {
                    quantity: "",
                    units: "feet",
                    length: "",
                    height: "",
                    breadth: "",
                },
            },
        },],
    },
    {
        name: "School Gate",
        _checked: false,
        quantity: 1,
        data: [{
            name: "School Gate 1",
            customName: "",
            images: [],
            count: 1,
            attr: {
                gate: {
                    quantity: "",
                    units: "feet",
                    length: "",
                    height: "",
                    breadth: "",
                },
            },
        },],
    },
    {
        name: "Water Coolers",
        _checked: false,
        quantity: 1,
        data: [{
            name: "Water Coolers 1",
            customName: "",
            images: [],
            count: 1,
            attr: {
                covers: {
                    quantity: "",
                    units: "feet",
                    length: "",
                    height: "",
                    breadth: "",
                },
            },
        },],
    },
    {
        name: "Washrooms",
        _checked: false,
        quantity: 1,
        data: [{
            name: "Washrooms 1",
            customName: "",
            images: [],
            count: 1,
            attr: {
                doors: {
                    quantity: "",
                    units: "feet",
                    length: "",
                    height: "",
                    breadth: "",
                },
                mirrors: {
                    quantity: "",
                    units: "feet",
                    length: "",
                    height: "",
                    breadth: "",
                }
            },
        },],
    },
    {
        name: "Science Lab",
        _checked: false,
        quantity: 1,
        data: [{
            name: "Science Lab 1",
            customName: "",
            images: [],
            count: 1,
            attr: {
                standees: {
                    quantity: "",
                    units: "feet",
                    length: "",
                    height: "",
                    breadth: "",
                },
                doors: {
                    quantity: "",
                    units: "feet",
                    length: "",
                    height: "",
                    breadth: "",
                },
            },
        },],
    },
    {
        name: "Music Class",
        _checked: false,
        quantity: 1,
        data: [{
            name: "Music Class 1",
            customName: "",
            images: [],
            count: 1,
            attr: {
                doors: {
                    quantity: "",
                    units: "feet",
                    length: "",
                    height: "",
                    breadth: ""
                },
                walls: {
                    quantity: "",
                    units: "feet",
                    length: "",
                    height: "",
                    breadth: ""
                },
            }
        }]
    },
    {
        name: "Hallway",
        _checked: false,
        quantity: 1,
        data: [{
            name: "Hallway 1",
            customName: "",
            images: [],
            count: 1,
            attr: {
                lockers: {
                    quantity: "",
                    units: "feet",
                    length: "",
                    height: "",
                    breadth: ""
                },
            }
        }]
    },
    {
        name: "Computer Lab",
        _checked: false,
        quantity: 1,
        data: [{
            name: "Computer Lab 1",
            customName: "",
            images: [],
            count: 1,
            attr: {
                walls: {
                    quantity: "",
                    units: "feet",
                    length: "",
                    height: "",
                    breadth: "",
                },
                doors: {
                    quantity: "",
                    units: "feet",
                    length: "",
                    height: "",
                    breadth: "",
                },
                screens: {
                    quantity: "",
                    units: "feet",
                    length: "",
                    height: "",
                    breadth: "",
                },
            },
        },],
    },
    ],
    eduLevels: [{
        label: "Nursery/Playgroup",
        value: "Nursery/Playgroup",
        classRange: ["N/Pg"],
    },
    {
        label: "Junior Kg/Senior Kg",
        value: "Junior Kg/Senior Kg",
        classRange: ["Jr.Kg", "Sr.Kg"],
    },
    {
        label: "Primary (1 - 4)",
        value: "Primary (1 - 4)",
        classRange: ["1", "2", "3", "4"],
    },
    {
        label: "Secondary (5 - 10)",
        value: "Secondary (5 - 10)",
        classRange: ["5", "6", "7", "8", "9", "10"],
    },
    {
        label: "Higher/Senior Secondary(10+2)",
        value: "Higher/Senior Secondary(10+2)",
        classRange: ["11", "12"],
    },
    {
        label: "All of the Above",
        value: "all",
        classRange: [
            "N/Pg",
            "Jr.Kg",
            "Sr.Kg",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
        ],
    },
    ],
    limits: {
        schoolArea: {
            min: 100,
            max: 50000,
        },
    },
    attrMeasurementUnits: [{
        label: "Feet",
        value: "feet",
    },
    {
        label: "Inches",
        value: "inches",
    },
    ],
    events: [{
        id: "School Fest",
        name: "School Fest",
        custom: false,
        availableForBranding: false,
        venue: [],
        otherVenueList: [],
        months: [],
        date: "31",
        amenities: [{
            name: "Stage Backdrop",
            availableForBranding: false,
            allowBrandsToProvide: false,
            _checked: false,
            images: [],
            count: 1,
            isSelected: false,
            isCustom: false,
            data: {
                quantity: "",
                units: "feet",
                length: "",
                height: "",
                breadth: "",
            }
        },
        {
            name: "Stage Front",
            availableForBranding: false,
            allowBrandsToProvide: false,
            _checked: false,
            images: [],
            count: 1,
            isSelected: false,
            isCustom: false,
            data: {
                quantity: "",
                units: "feet",
                length: "",
                height: "",
                breadth: "",
            }
        },
        {
            name: "Stage Wings",
            availableForBranding: false,
            allowBrandsToProvide: false,
            _checked: false,
            images: [],
            count: 1,
            isSelected: false,
            isCustom: false,
            data: {
                quantity: "",
                units: "feet",
                length: "",
                height: "",
                breadth: "",
            }
        }
        ]
    },
    {
        id: "Independence Day Celebration",
        name: "Independence Day Celebration",
        custom: false,
        availableForBranding: false,
        venue: [],
        otherVenueList: [],
        months: [],
        date: "31",
        amenities: [{
            name: "Stage Backdrop",
            availableForBranding: false,
            allowBrandsToProvide: false,
            _checked: false,
            images: [],
            count: 1,
            isSelected: false,
            isCustom: false,
            data: {
                quantity: "",
                units: "feet",
                length: "",
                height: "",
                breadth: "",
            }
        },
        {
            name: "Stage Front",
            availableForBranding: false,
            allowBrandsToProvide: false,
            _checked: false,
            images: [],
            count: 1,
            isSelected: false,
            isCustom: false,
            data: {
                quantity: "",
                units: "feet",
                length: "",
                height: "",
                breadth: "",
            }
        },
        {
            name: "Stage Wings",
            availableForBranding: false,
            allowBrandsToProvide: false,
            _checked: false,
            images: [],
            count: 1,
            isSelected: false,
            isCustom: false,
            data: {
                quantity: "",
                units: "feet",
                length: "",
                height: "",
                breadth: "",
            }
        }]
    },
    {
        id: "Summer Camp",
        name: "Summer Camp",
        custom: false,
        availableForBranding: false,
        venue: [],
        otherVenueList: [],
        months: [],
        date: "31",
        amenities: [{
            name: "Stage Backdrop",
            availableForBranding: false,
            allowBrandsToProvide: false,
            _checked: false,
            images: [],
            count: 1,
            isSelected: false,
            isCustom: false,
            data: {
                quantity: "",
                units: "feet",
                length: "",
                height: "",
                breadth: "",
            }
        },
        {
            name: "Stage Front",
            availableForBranding: false,
            allowBrandsToProvide: false,
            _checked: false,
            images: [],
            count: 1,
            isSelected: false,
            isCustom: false,
            data: {
                quantity: "",
                units: "feet",
                length: "",
                height: "",
                breadth: "",
            }
        },
        {
            name: "Stage Wings",
            availableForBranding: false,
            allowBrandsToProvide: false,
            _checked: false,
            images: [],
            count: 1,
            isSelected: false,
            isCustom: false,
            data: {
                quantity: "",
                units: "feet",
                length: "",
                height: "",
                breadth: "",
            }
        }]
    },
    {
        id: "Sports Day",
        name: "Sports Day",
        custom: false,
        availableForBranding: false,
        venue: [],
        otherVenueList: [],
        months: [],
        date: "31",
        amenities: [{
            name: "Stage Backdrop",
            availableForBranding: false,
            allowBrandsToProvide: false,
            _checked: false,
            images: [],
            count: 1,
            isSelected: false,
            isCustom: false,
            data: {
                quantity: "",
                units: "feet",
                length: "",
                height: "",
                breadth: "",
            }
        },
        {
            name: "Stage Front",
            availableForBranding: false,
            allowBrandsToProvide: false,
            _checked: false,
            images: [],
            count: 1,
            isSelected: false,
            isCustom: false,
            data: {
                quantity: "",
                units: "feet",
                length: "",
                height: "",
                breadth: "",
            }
        },
        {
            name: "Stage Wings",
            availableForBranding: false,
            allowBrandsToProvide: false,
            _checked: false,
            images: [],
            count: 1,
            isSelected: false,
            isCustom: false,
            data: {
                quantity: "",
                units: "feet",
                length: "",
                height: "",
                breadth: "",
            }
        }
        ]
    },
    {
        id: "Annual Day",
        name: "Annual Day",
        custom: false,
        availableForBranding: false,
        venue: [],
        otherVenueList: [],
        months: [],
        date: "31",
        amenities: [{
            name: "Stage Backdrop",
            availableForBranding: false,
            allowBrandsToProvide: false,
            _checked: false,
            images: [],
            count: 1,
            isSelected: false,
            isCustom: false,
            data: {
                quantity: "",
                units: "feet",
                length: "",
                height: "",
                breadth: "",
            }
        },
        {
            name: "Stage Front",
            availableForBranding: false,
            allowBrandsToProvide: false,
            _checked: false,
            images: [],
            count: 1,
            isSelected: false,
            isCustom: false,
            data: {
                quantity: "",
                units: "feet",
                length: "",
                height: "",
                breadth: "",
            }
        },
        {
            name: "Stage Wings",
            availableForBranding: false,
            allowBrandsToProvide: false,
            _checked: false,
            images: [],
            count: 1,
            isSelected: false,
            isCustom: false,
            data: {
                quantity: "",
                units: "feet",
                length: "",
                height: "",
                breadth: "",
            }
        }
        ]
    },
    {
        id: "Gender Education Session",
        name: "Gender Education Session",
        custom: false,
        availableForBranding: false,
        venue: [],
        otherVenueList: [],
        months: [],
        date: "31",
        amenities: [{
            name: "Stage Backdrop",
            availableForBranding: false,
            allowBrandsToProvide: false,
            _checked: false,
            images: [],
            count: 1,
            isSelected: false,
            isCustom: false,
            data: {
                quantity: "",
                units: "feet",
                length: "",
                height: "",
                breadth: "",
            }
        },
        {
            name: "Stage Front",
            availableForBranding: false,
            allowBrandsToProvide: false,
            _checked: false,
            images: [],
            count: 1,
            isSelected: false,
            isCustom: false,
            data: {
                quantity: "",
                units: "feet",
                length: "",
                height: "",
                breadth: "",
            }
        },
        {
            name: "Stage Wings",
            availableForBranding: false,
            allowBrandsToProvide: false,
            _checked: false,
            images: [],
            count: 1,
            isSelected: false,
            isCustom: false,
            data: {
                quantity: "",
                units: "feet",
                length: "",
                height: "",
                breadth: "",
            }
        }
        ]
    },
    {
        id: "Parent Teacher Meetings",
        name: "Parent Teacher Meetings",
        custom: false,
        availableForBranding: false,
        venue: [],
        otherVenueList: [],
        months: [],
        date: "31",
        amenities: [{
            name: "Stage Backdrop",
            availableForBranding: false,
            allowBrandsToProvide: false,
            _checked: false,
            images: [],
            count: 1,
            isSelected: false,
            isCustom: false,
            data: {
                quantity: "",
                units: "feet",
                length: "",
                height: "",
                breadth: "",
            }
        },
        {
            name: "Stage Front",
            availableForBranding: false,
            allowBrandsToProvide: false,
            _checked: false,
            images: [],
            count: 1,
            isSelected: false,
            isCustom: false,
            data: {
                quantity: "",
                units: "feet",
                length: "",
                height: "",
                breadth: "",
            }
        },
        {
            name: "Stage Wings",
            availableForBranding: false,
            allowBrandsToProvide: false,
            _checked: false,
            images: [],
            count: 1,
            isSelected: false,
            isCustom: false,
            data: {
                quantity: "",
                units: "feet",
                length: "",
                height: "",
                breadth: "",
            }
        }
        ]
    },
    {
        id: "School Reunion",
        name: "School Reunion",
        custom: false,
        availableForBranding: false,
        venue: [],
        otherVenueList: [],
        months: [],
        date: "31",
        amenities: [{
            name: "Stage Backdrop",
            availableForBranding: false,
            allowBrandsToProvide: false,
            _checked: false,
            images: [],
            count: 1,
            isSelected: false,
            isCustom: false,
            data: {
                quantity: "",
                units: "feet",
                length: "",
                height: "",
                breadth: "",
            }
        },
        {
            name: "Stage Front",
            availableForBranding: false,
            allowBrandsToProvide: false,
            _checked: false,
            images: [],
            count: 1,
            isSelected: false,
            isCustom: false,
            data: {
                quantity: "",
                units: "feet",
                length: "",
                height: "",
                breadth: "",
            }
        },
        {
            name: "Stage Wings",
            availableForBranding: false,
            allowBrandsToProvide: false,
            _checked: false,
            images: [],
            count: 1,
            isSelected: false,
            isCustom: false,
            data: {
                quantity: "",
                units: "feet",
                length: "",
                height: "",
                breadth: "",
            }
        }
        ]
    },
    {
        id: "Dental Health Week",
        name: "Dental Health Week",
        custom: false,
        availableForBranding: false,
        venue: [],
        otherVenueList: [],
        months: [],
        date: "31",
        amenities: [{
            name: "Stage Backdrop",
            availableForBranding: false,
            allowBrandsToProvide: false,
            _checked: false,
            images: [],
            count: 1,
            isSelected: false,
            isCustom: false,
            data: {
                quantity: "",
                units: "feet",
                length: "",
                height: "",
                breadth: "",
            }
        },
        {
            name: "Stage Front",
            availableForBranding: false,
            allowBrandsToProvide: false,
            _checked: false,
            images: [],
            count: 1,
            isSelected: false,
            isCustom: false,
            data: {
                quantity: "",
                units: "feet",
                length: "",
                height: "",
                breadth: "",
            }
        },
        {
            name: "Stage Wings",
            availableForBranding: false,
            allowBrandsToProvide: false,
            _checked: false,
            images: [],
            count: 1,
            isSelected: false,
            isCustom: false,
            data: {
                quantity: "",
                units: "feet",
                length: "",
                height: "",
                breadth: "",
            }
        }
        ]
    },
    {
        id: "Diwali Party",
        name: "Diwali Party",
        custom: false,
        availableForBranding: false,
        venue: [],
        otherVenueList: [],
        months: [],
        date: "31",
        amenities: [{
            name: "Stage Backdrop",
            availableForBranding: false,
            allowBrandsToProvide: false,
            _checked: false,
            images: [],
            count: 1,
            isSelected: false,
            isCustom: false,
            data: {
                quantity: "",
                units: "feet",
                length: "",
                height: "",
                breadth: "",
            }
        },
        {
            name: "Stage Front",
            availableForBranding: false,
            allowBrandsToProvide: false,
            _checked: false,
            images: [],
            count: 1,
            isSelected: false,
            isCustom: false,
            data: {
                quantity: "",
                units: "feet",
                length: "",
                height: "",
                breadth: "",
            }
        },
        {
            name: "Stage Wings",
            availableForBranding: false,
            allowBrandsToProvide: false,
            _checked: false,
            images: [],
            count: 1,
            isSelected: false,
            isCustom: false,
            data: {
                quantity: "",
                units: "feet",
                length: "",
                height: "",
                breadth: "",
            }
        }
        ]
    },
    {
        id: "Christmas Party",
        name: "Christmas Party",
        custom: false,
        availableForBranding: false,
        venue: [],
        otherVenueList: [],
        months: [],
        date: "31",
        amenities: [{
            name: "Stage Backdrop",
            availableForBranding: false,
            allowBrandsToProvide: false,
            _checked: false,
            images: [],
            count: 1,
            isSelected: false,
            isCustom: false,
            data: {
                quantity: "",
                units: "feet",
                length: "",
                height: "",
                breadth: "",
            }
        },
        {
            name: "Stage Front",
            availableForBranding: false,
            allowBrandsToProvide: false,
            _checked: false,
            images: [],
            count: 1,
            isSelected: false,
            isCustom: false,
            data: {
                quantity: "",
                units: "feet",
                length: "",
                height: "",
                breadth: "",
            }
        },
        {
            name: "Stage Wings",
            availableForBranding: false,
            allowBrandsToProvide: false,
            _checked: false,
            images: [],
            count: 1,
            isSelected: false,
            isCustom: false,
            data: {
                quantity: "",
                units: "feet",
                length: "",
                height: "",
                breadth: "",
            }
        }
        ]
    },
    {
        id: "Republic Day Celebration",
        name: "Republic Day Celebration",
        custom: false,
        availableForBranding: false,
        venue: [],
        otherVenueList: [],
        months: [],
        date: "31",
        amenities: [{
            name: "Stage Backdrop",
            availableForBranding: false,
            allowBrandsToProvide: false,
            _checked: false,
            images: [],
            count: 1,
            isSelected: false,
            isCustom: false,
            data: {
                quantity: "",
                units: "feet",
                length: "",
                height: "",
                breadth: "",
            }
        },
        {
            name: "Stage Front",
            availableForBranding: false,
            allowBrandsToProvide: false,
            _checked: false,
            images: [],
            count: 1,
            isSelected: false,
            isCustom: false,
            data: {
                quantity: "",
                units: "feet",
                length: "",
                height: "",
                breadth: "",
            }
        },
        {
            name: "Stage Wings",
            availableForBranding: false,
            allowBrandsToProvide: false,
            _checked: false,
            images: [],
            count: 1,
            isSelected: false,
            isCustom: false,
            data: {
                quantity: "",
                units: "feet",
                length: "",
                height: "",
                breadth: "",
            }
        }
        ]
    },
    {
        id: "Children's day",
        name: "Children's day",
        custom: false,
        availableForBranding: false,
        venue: [],
        otherVenueList: [],
        months: [],
        date: "31",
        amenities: [{
            name: "Stage Backdrop",
            availableForBranding: false,
            allowBrandsToProvide: false,
            _checked: false,
            images: [],
            count: 1,
            isSelected: false,
            isCustom: false,
            data: {
                quantity: "",
                units: "feet",
                length: "",
                height: "",
                breadth: "",
            }
        },
        {
            name: "Stage Front",
            availableForBranding: false,
            allowBrandsToProvide: false,
            _checked: false,
            images: [],
            count: 1,
            isSelected: false,
            isCustom: false,
            data: {
                quantity: "",
                units: "feet",
                length: "",
                height: "",
                breadth: "",
            }
        },
        {
            name: "Stage Wings",
            availableForBranding: false,
            allowBrandsToProvide: false,
            _checked: false,
            images: [],
            count: 1,
            isSelected: false,
            isCustom: false,
            data: {
                quantity: "",
                units: "feet",
                length: "",
                height: "",
                breadth: "",
            }
        }
        ]
    },
    ],
    studentAmenities: [{
        id: "Uniform",
        name: "Uniform",
        custom: false,
        _checked: false,
    },
    {
        id: "Stationary",
        name: "Stationary",
        custom: false,
        _checked: false,
    },
    {
        id: "Bags",
        name: "Bags",
        custom: false,
        _checked: false,
    },
    ],
    commonAreaAmenities: [{
        name: "Assembly Hall",
        _checked: false,
        quantity: 1,
        data: [{
            name: "Assembly Hall 1",
            images: [],
            count: 1,
            customName: "",
            attr: {
                boards: {
                    quantity: "",
                    units: "feet",
                    length: "",
                    height: "",
                    breadth: ""
                },
            }
        }]
    },
    {
        name: "Wash Room",
        _checked: false,
        quantity: 1,
        data: [{
            name: "Wash Room 1",
            images: [],
            count: 1,
            customName: "",
            attr: {
                stage: {
                    quantity: "",
                    units: "feet",
                    length: "",
                    height: "",
                    breadth: ""
                },
                door: {
                    quantity: "",
                    units: "feet",
                    length: "",
                    height: "",
                    breadth: ""
                },
            }
        }]
    },
    {
        name: "Water Coolers",
        _checked: false,
        quantity: 1,
        data: [{
            name: "Water Coolers 1",
            images: [],
            count: 1,
            customName: "",
            attr: {
                boards: {
                    quantity: "",
                    units: "feet",
                    length: "",
                    height: "",
                    breadth: ""
                },
            }
        }]
    },
    {
        name: "Hallway",
        _checked: false,
        quantity: 1,
        data: [{
            name: "Hallway 1",
            images: [],
            count: 1,
            customName: "",
            attr: {
                boards: {
                    quantity: "",
                    units: "feet",
                    length: "",
                    height: "",
                    breadth: ""
                },
            }
        }]
    },
    ],
    otherRoomsAndStaffAmenities: [{
        name: "Staff Rooms",
        _checked: false,
        quantity: 1,
        data: [{
            name: "Staff Room 1",
            images: [],
            count: 1,
            customName: "",
            attr: {
                boards: {
                    quantity: "",
                    units: "feet",
                    length: "",
                    height: "",
                    breadth: ""
                },
            }
        }]
    },
    {
        name: "Tables",
        _checked: false,
        quantity: 1,
        data: [{
            name: "Table 1",
            images: [],
            count: 1,
            customName: "",
            attr: {
                boards: {
                    quantity: "",
                    units: "feet",
                    length: "",
                    height: "",
                    breadth: ""
                },
            }
        }]
    },
    {
        name: "Chairs",
        _checked: false,
        quantity: 1,
        data: [{
            name: "Chair 1",
            images: [],
            count: 1,
            customName: "",
            attr: {
                boards: {
                    quantity: "",
                    units: "feet",
                    length: "",
                    height: "",
                    breadth: ""
                },
            }
        }]
    },
    ],
    outdoorAmenities: [{
        name: "Playgrounds",
        _checked: false,
        quantity: 1,
        data: [{
            name: "Playground 1",
            images: [],
            count: 1,
            customName: "",
            attr: {
                size: {
                    quantity: "",
                    units: "feet",
                    length: "",
                    height: "",
                    breadth: ""
                },
            }
        }]
    },
    {
        name: "Parking Grounds",
        _checked: false,
        quantity: 1,
        data: [{
            name: "Parking Ground 1",
            images: [],
            count: 1,
            customName: "",
            attr: {
                ground1: {
                    quantity: "",
                    units: "feet",
                    length: "",
                    height: "",
                    breadth: ""
                },
                ground2: {
                    quantity: "",
                    units: "feet",
                    length: "",
                    height: "",
                    breadth: ""
                },
            }
        }]
    },
    {
        name: "Toilets",
        _checked: false,
        quantity: 1,
        data: [{
            name: "Toilet 1",
            images: [],
            count: 1,
            customName: "",
            attr: {
                doors: {
                    quantity: "",
                    units: "feet",
                    length: "",
                    height: "",
                    breadth: ""
                },
                mirror: {
                    quantity: "",
                    units: "feet",
                    length: "",
                    height: "",
                    breadth: ""
                },
            }
        }]
    },
    ],
    classroomAmenities: [{
        name: "Boards",
        _checked: false,
        quantity: 1,
        data: [{
            name: "Board 1",
            images: [],
            count: 1,
            customName: "",
            attr: {
                boards: {
                    quantity: "",
                    units: "feet",
                    length: "",
                    height: "",
                    breadth: ""
                },
            }
        }]
    },
    {
        name: "Cabinets",
        _checked: false,
        quantity: 1,
        data: [{
            name: "Cabinets 1",
            customName: "",
            images: [],
            count: 1,
            attr: {
                cabinets: {
                    quantity: "",
                    units: "feet",
                    length: "",
                    height: "",
                    breadth: ""
                },
            }
        }]
    },
    {
        name: "Benches",
        _checked: false,
        quantity: 1,
        data: [{
            name: "Benches 1",
            images: [],
            count: 1,
            customName: "",
            attr: {
                benches: {
                    quantity: "",
                    units: "feet",
                    length: "",
                    height: "",
                    breadth: ""
                },
            }
        }]
    },
    {
        name: "Uniforms",
        _checked: false,
        quantity: 1,
        data: [{
            name: "Uniforms 1",
            images: [],
            count: 1,
            customName: "",
            attr: {
                sleeves: {
                    quantity: "",
                    units: "feet",
                    length: "",
                    height: "",
                    breadth: ""
                },
            }
        }]
    },
    {
        name: "Staff Uniforms",
        _checked: false,
        quantity: 1,
        data: [{
            name: "Staff Uniforms 1",
            images: [],
            count: 1,
            customName: "",
            attr: {
                sleeves: {
                    quantity: "",
                    units: "feet",
                    length: "",
                    height: "",
                    breadth: ""
                },
            }
        }]
    },
    {
        name: "Sport Uniforms",
        _checked: false,
        quantity: 1,
        data: [{
            name: "Sport Uniforms 1",
            images: [],
            count: 1,
            customName: "",
            attr: {
                sleeve: {
                    quantity: "",
                    units: "feet",
                    length: "",
                    height: "",
                    breadth: ""
                },
            }
        }]
    },
    {
        name: "School Bags",
        _checked: false,
        quantity: 1,
        data: [{
            name: "School Bags 1",
            images: [],
            count: 1,
            customName: "",
            attr: {
                bagface: {
                    quantity: "",
                    units: "feet",
                    length: "",
                    height: "",
                    breadth: ""
                },
            }
        }]
    }
    ],
    months: [{
        label: "Jan",
        value: "Jan"
    },
    {
        label: "Feb",
        value: "Feb"
    },
    {
        label: "Mar",
        value: "Mar"
    },
    {
        label: "Apr",
        value: "Apr"
    },
    {
        label: "May",
        value: "May"
    },
    {
        label: "Jun",
        value: "Jun"
    },
    {
        label: "Jul",
        value: "Jul"
    },
    {
        label: "Aug",
        value: "Aug"
    },
    {
        label: "Sep",
        value: "Sep"
    },
    {
        label: "Oct",
        value: "Oct"
    },
    {
        label: "Nov",
        value: "Nov"
    },
    {
        label: "Dec",
        value: "Dec"
    }
    ],

    schoolRoles: [{
        label: "Principal/Trustee",
        value: "schoolPrincipal",
    },
    {
        label: "Admin",
        value: "schoolAdmin",
    },
    ],

    country: [{
        label: "India",
        value: "India",
    }],

    isVerified: [
        {
            label: "Yes",
            value: "true",
        },
        {
            label: "No",
            value: "false",
        },
    ],

    source: [
        {
            label: "Cbseschools",
            value: "cbseschools",
        },
        {
            label: "Saras",
            value: "saras",
        },
        {
            label: "Uniapply",
            value: "uniapply",
        },
    ],

    boardOptions: [
        {
            label: 'State Board',
            value: 'state'
        },
        {
            label: 'CBSE',
            value: 'cbse'
        },
        {
            label: 'IGSCE',
            value: 'igsce'
        },
        {
            label: 'ICSE',
            value: 'icse'
        },
        {
            label: 'Other',
            value: 'Other'
        },
        {
            label: 'Custom',
            value: 'Custom'
        }
    ],

    designationOptions: [
        {
            label: 'CMO',
            value: 'CMO'
        },
        // {
        //     label: 'Chief Head',
        //     value: 'Chief Head'
        // },
        {
            label: 'Brand Manager',
            value: 'Brand Manager'
        },
        {
            label: 'Other',
            value: 'other'
        }
    ],
    regions: [
        {
            label: "East",
            value: "East"
        },
        {
            label: "West",
            value: "West"
        },
        {
            label: "Central",
            value: "Central"
        },
        {
            label: "North",
            value: "North"
        },
        {
            label: "North East",
            value: "North East"
        },
        {
            label: "South",
            value: "South"
        }
    ],
    dashboardRegions: [
        {
            label: "All Regions",
            value: "All Regions"
        },
        {
            label: "East",
            value: "East"
        },
        {
            label: "West",
            value: "West"
        },
        {
            label: "Central",
            value: "Central"
        },
        {
            label: "North",
            value: "North"
        },
        {
            label: "North East",
            value: "North East"
        },
        {
            label: "South",
            value: "South"
        }
    ],
    role: [
        {
            label: "Ops",
            value: "Ops",
            _id: "backendOperations"
        },
        {
            label: "Sales",
            value: "Sales",
            _id: "backendSales"
        },
        {
            label: "Finance",
            value: "Finance",
            _id: "backendFinance"
        },
        {
            label: "Tech",
            value: "Tech",
            _id: "backendTech"
        },
        {
            label: "Super Admin",
            value: "Super Admin",
            _id: "backendAdmin"
        }
    ],
    backendAdminRole: [
        {
            label: "Ops",
            value: "Ops",
            _id: "backendOperations"
        },
        {
            label: "Sales Admin",
            value: "Sales Admin",
            _id: "backendSalesAdmin"
        },
        {
            label: "Sales Person",
            value: "Sales Person",
            _id: "backendSalesPerson"
        },
        {
            label: "Finance",
            value: "Finance",
            _id: "backendFinance"
        },
        {
            label: "Tech",
            value: "Tech",
            _id: "backendTech"
        },
        {
            label: "Super Admin",
            value: "Super Admin",
            _id: "backendAdmin"
        }
    ],
    allRole: [
        {
            label: "Ops",
            value: "Ops",
            _id: "backendOperations"
        },
        {
            label: "Sales Admin",
            value: "SalesAdmin",
            _id: "backendSalesAdmin"
        },
        {
            label: "Sales Person",
            value: "salesPerson",
            _id: "backendSalesPerson"
        },
        {
            label: "Finance",
            value: "Finance",
            _id: "backendFinance"
        },
        {
            label: "Tech",
            value: "Tech",
            _id: "backendTech"
        },
        {
            label: "Super Admin",
            value: "Super Admin",
            _id: "backendAdmin"
        }
    ],
    location: [
        {
            label: "Hyderabad",
            value: "hyderabad"
        },
        {
            label: "Chennai",
            value: "chennai"
        },
        {
            label: "Kolkata",
            value: "kolkata"
        },
        {
            label: "Mumbai",
            value: "mumbai"
        },
        {
            label: "Bengaluru",
            value: "bengaluru"
        }
    ],
    brandDocumentStatus: [
        {
            label: "Completed",
            value: "accepted"
        },
        {
            label: "Pending",
            value: "pending"
        },
        {
            label: "Rejected",
            value: "rejected"
        }
    ],
    schoolCategory: [
        {
            label: "Category A",
            value: "A"
        },
        {
            label: "Category B",
            value: "B"
        },
        {
            label: "Category C",
            value: "C"
        }
    ],
    campaignStatus: [
        {
            label: "In-Review",
            value: "in-review"
        },
        {
            label: "Approved",
            value: "accepted"
        },
        {
            label: "Rejected",
            value: "rejected"
        },
        {
            label: "Executed",
            value: "executed"
        },
        {
            label: "Scheduled",
            value: "scheduled"
        },
        {
            label: "On-Hold",
            value: "on-hold"
        },
        {
            label: "Ongoing",
            value: "ongoing"
        },
    ],
    nonWisrCampaignStatus: [
        {
            label: "In-Review",
            value: "in-review"
        },
        {
            label: "Approved",
            value: "approved"
        },
        {
            label: "Rejected",
            value: "rejected"
        },
        {
            label: "Executed",
            value: "executed"
        },
        {
            label: "Scheduled",
            value: "scheduled"
        },
        {
            label: "On-Hold",
            value: "on-hold"
        },
        {
            label: "Ongoing",
            value: "ongoing"
        },
    ],
    campaignStatusBrand: [
        {
            label: "ongoing",
            value: "ongoing"
        },
        {
            label: "on-hold",
            value: "on-hold"
        },
        {
            label: "review",
            value: "in-review"
        },
        {
            label: "executed",
            value: "executed"
        },
        {
            label: "draft",
            value: "draft"
        },
        {
            label: "trial",
            value: "trial"
        },
        {
            label: "approved",
            value: "approved"
        },        
    ],

    paymentStatus: [
        {
            label: "Paid",
            value: "paid"
        },
        {
            label: "Pending",
            value: "pending"
        },
    ],
    monthFilter: [{
        label: "Last 30 days",
        value: "last_thirty_day"
    },
    {
        label: "This Month",
        value: "this_month"
    },
    {
        label: "Last Month",
        value: "last_month"
    },
    {
        label: "Last 3 Months",
        value: "last_three_month"
    },
    {
        label: "Last 6 Months",
        value: "last_six_month"
    },
    {
        label: "Custom",
        value: "custom"
    }
    ],
    monthFilterSheduleDate: [{
        label: "Last 30 days",
        value: "last_thirty_day"
    },
    {
        label: "This Month",
        value: "this_month"
    },
    {
        label: "Next Month",
        value: "next_month"
    },
    {
        label: "Last Month",
        value: "last_month"
    },
    {
        label: "Last 3 Months",
        value: "last_three_month"
    },
    {
        label: "Last 6 Months",
        value: "last_six_month"
    },
    {
        label: "Custom",
        value: "custom"
    }
    ],
    categories: [{
        label: "Category A",
        value: "A"
    },
    {
        label: "Category B",
        value: "B"
    },
    {
        label: "Category C",
        value: "C"
    }
    ],
    dashboardCategories: [
        {
            label: "All Categories",
            value: "All Categories"
        },
        {
            label: "Category A",
            value: "A"
        },
        {
            label: "Category B",
            value: "B"
        },
        {
            label: "Category C",
            value: "C"
        }
    ],
    inventoriesStatus: [{
        label: "New Added",
        value: "new-added"
    },
    {
        label: "Approved",
        value: "approved"
    },
    {
        label: "Rejected",
        value: "rejected"
    },
    {
        label: "In-Review",
        value: "updated"
    }
    ],
    gender: [{
        label: "Boys",
        value: "boys"
    },
    {
        label: "Girls",
        value: "girls"
    },
    {
        label: "Co-ed",
        value: "co-ed"
    }
    ],
    fosAccountStatus: [
        {
            label:'Active',
            value:'active'
        },
        {
            label:'Inactive',
            value:'inactive'
        }
    ],
    inventory: [
        {
            label: "Benches",
            value: "benches"
        },
        {
            label: "Boards",
            value: "boards"
        },
        {
            label: "Sports Uniform",
            value: "sports_uniform"
        },
        {
            label: "School Bags",
            value: "school_bags"
        },
        {
            label: "Cabinets",
            value: "cabinets"
        },
        {
            label: "Uniform",
            value: "uniform"
        },
        {
            label: "School Ground",
            value: "school_ground"
        },
        {
            label: "Science Lab",
            value: "science_ab"
        },
        {
            label: "Hallway",
            value: "hallway"
        },
        {
            label: "Washroom",
            value: "washroom"
        },

    ]
}


