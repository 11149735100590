import React from "react"
import config from "./config"

const DataContext = React.createContext({
    data: {
        personalDetails: {
            fullName: '',
            email: '',
            phone: '',
            countryCode: '+91',
            schoolName: '',
            registeredUnder: '',
            schoolPrincipalName: '',
            schoolPrincipalEmail: '',
            role: null,
            username: '',
            isUserCreated: false,
            fosEmail: "",
            fosPhone: "",
            fosUserId: ""
        },
        schoolGeneral: {
            educationLevel: [],
            schoolType: {
                schoolType: null,
                classWiseDetails: []
            },
            board: {
                board: null,
                classWiseDetails: []
            },
            language: {
                language: null,
                classWiseDetails: []
            },
            pincode: '',
            state: '',
            city: '',
            address: '',
            localArea: '',
            builtUpArea: config.limits.schoolArea.min,
            floors: 0,
            noOfTeachers: '',          
        },
        schoolAmenities: {
            builtUpArea: config.limits.schoolArea.min,
            floors: 0,
            amenities: [],
            classrooms: [],
            classroomAmenities: config.classroomAmenities,
            commonAreaAmenities: [],
            otherRoomsAndStaffAmenities: [],
            outdoorAmenities: []            
        },
        student: {
            life: [],
            activities: []
        },
        verifiedUsername: null,
        unsavedUserIds: [],
        unsavedImages: [],
        algorithmData: {
            category: "",
            totalFee: 0,
            classrooms: 0,
            students: 0,
            teachers: 0,
            inventories: [],
            events: []
        }
    },
    setData: () => {}
})

export const DataContextProvider = DataContext.Provider

export default DataContext
