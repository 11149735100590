// import "./VideoCarousel.css";
import React, {useState, useEffect, useRef} from "react";
import { Carousel } from "react-bootstrap";
import ReactPlayer from "react-player";
import YouTube, { YouTubeProps } from 'react-youtube';
import '../../../../assets/css/videoCarousel.css'
import Youtube from "../../landingPage/youtube";

const VideoImageCarousel = ({data, supportPage, brand}) => {
  const [index, setIndex] = useState(0)

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex)
  }

  return (
    <div className="slider-wrapper" style={{ maxWidth: supportPage ? 'auto' : '650px', width: 'auto', height: 'auto' }}>
        <Carousel
            activeIndex={index}
            onSelect={handleSelect}
            interval={null}
        >
            {data.map((videoObj, currentIndex) => {
            return (
                <Carousel.Item key={videoObj.id}>
                {
                   
                   
                    videoObj.video ?
                    // <NewYouTube embedId={videoObj.video}  stop={true}/>
                    <NewYouTube 
                        embedId={videoObj.video}  
                        stop={currentIndex !== index}
                        noMargin={true} 
                        brand={brand}
                    />
                    :
                    <img className="slick-slide-image" src={videoObj.Image} style={{boxShadow:'none', height: "470px", marginTop: '40px'}} />
                }
                {/* <iframe
                    ref={youTubeRef}
                    className="youtube-video"
                    id={embedId}
                    width="100%"
                    height="380px"
                    src={`https://www.youtube.com/embed/${embedId}??enablejsapi=1&version=3&playerapiid=ytplayer`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Embedded youtube"
                /> */}
                {/* <Carousel.Caption>
                    <h3>{videoObj.title}</h3>
                    <p>{videoObj.credit}</p>
                </Carousel.Caption> */}
                </Carousel.Item>
            );
            })}
        </Carousel>
    </div>
  );
};

export default VideoImageCarousel;

const NewYouTube = ({embedId, stop, noMargin, brand}) => {
    const ref = useRef()
    let opts  = {
        height: noMargin ? "470px" :  "380px",
        width: '100%',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 1,
        },
        allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
        allowFullScreen: true,
        title: "Embedded youtube"
    };
    // console.log("embedId", embedId)
    const onPlayerReady = (event) => {
        event.target.stopVideo();
    }
    useEffect(() => {
        if(stop){
            if(ref && ref.current){
                let yt: any = ref.current
                yt.internalPlayer.pauseVideo()
                // console.log("current", ref, ref.current)
            }
        }
    }, [stop])
    return (
        <div className="videoContainer" style={{margin: noMargin && '40px 0 0 0'}}>
            <div className="embed-container" style={{margin: noMargin && '0'}}>
                <YouTube ref={ref} videoId={embedId} opts={opts} onReady={onPlayerReady} />
            </div>
        </div>
    )
}