import { useEffect, useState, useRef } from "react"

import Textfield from "../formFields/textField/textField"
import SingleSelect from "../formFields/singleSelect/singleSelect"

import SimpleReactValidator from "simple-react-validator"

import errorSVG from "../../assets/images/error.svg"

import { useRouteMatch } from "react-router-dom"

import config from "../../service/config"

const InventoryModal = (props) => {
    const {
        isEdit,
        onModalClose,
        togglePopup,
        data,
        parentCallback,
        existingAmenities,
        selectedInventories,
        inDashboard,
        schoolExistingInventories
    } = props

    const router = useRouteMatch()

    const [formValidator] = useState(useRef(new SimpleReactValidator({
        element: (message, className) =>
            <span className="valError">
                <img src={errorSVG} />
                {message==='The name field is required.'
                ?
                'Inventory name is required.'
                :
                message
                }
                </span>
        })))
    const [update, forceUpdate] = useState(false) // to re-render the dom after updating the validation

    const title = isEdit ? "Edit Inventory" : "Add New Inventory"
    const confirmStr = isEdit ? "Save" : "Create"

    const [formData, setFormData] = useState<any>({name: ""})
    const [oldName, setOldName] = useState()
    const [showForm, setShowForm] = useState(false)
    const [inventoryURLs] = useState([
        "/schools/school-details/add-invetory/remaining-invetories",
        "/schools/school-details/add-invetory/invetories-details"
    ])
    const customValidator = new SimpleReactValidator({
        validators: {
            unique_name: {
                message: 'The inventory name must be unique',
                rule: (val, params) => {
                    let isValidated = true
                    existingAmenities.forEach(element => {
                        if (isValidated === true && element.name.trim().toLowerCase() === val.trim().toLowerCase()) {
                            isValidated = false
                        }
                    })
                    return isValidated
                },
            }
        }
    })

    console.log("check twice",existingAmenities,selectedInventories);

    formValidator.current.rules['unique_name'] = {
        message: 'Inventory name must be unique.',
        rule: (val, params) => {
            let isValidated = true
            // const arr = params.filter(e => e.trim().toLowerCase() === val.trim().toLowerCase())
            params.forEach(element => {
                // console.log("element--->>>",element);
                if (isValidated === true && element.trim().toLowerCase() === val.trim().toLowerCase()) {
                    isValidated = false
                }
            })
            if (isEdit === true && oldName === val.trim().toLowerCase()) isValidated = true
            return isValidated
        },
    }
    formValidator.current.rules['existing_name'] = {
        message: 'Inventory with same category and name is already exists in your school.',
        rule: (val, params) => {
            let isValidated = true
            // const arr = params.filter(e => e.trim().toLowerCase() === val.trim().toLowerCase())
            if(schoolExistingInventories && schoolExistingInventories.length === 0){
                return true
            }
            params.forEach(element => {
                if (isValidated === true && element.trim().toLowerCase() === val.trim().toLowerCase()) {
                    for (const inventory of schoolExistingInventories) {
                        if(element.trim().toLowerCase() === inventory.parentName.trim().toLowerCase()){
                            if(formData.inventoryCategory == inventory.type){
                                isValidated = false
                            }else{
                                isValidated = true
                            }
                        }
                    }
                }
            })
            if (isEdit === true && oldName === val) isValidated = true
            return isValidated
        },
    }
    formValidator.current.rules['length_name'] = {
        message: 'The name must be less than 15 characters long',
        rule: (val) => {
            let isValidated = true
            
                if (isValidated === true && val.length>15) {
                    isValidated = false
                }
            
            if (isEdit === true && oldName === val) isValidated = true
            return isValidated
        },
    }
    
    formValidator.current.rules['alpha_num_dash_space'] = {
        message: 'Only Alphabets, numbers, space is allowed. only numbers and special characters not allowed',
        rule: (val) => {
            let isValidated = true
            var testNumberRegex = /^\d*\.?\d*$/;
            var testSpecialCharsRegex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
            if(testNumberRegex.test(val)||testSpecialCharsRegex.test(val)){
                isValidated = false
            }
            if (isEdit === true && oldName === val) isValidated = true
            return isValidated
        },
    }
   
    useEffect(() => {
        setFormData(data)
        if (Object.keys(data).length > 0) {
            setShowForm(true)
        } else {
            setShowForm(false)
        }
        forceUpdate(!update)
    }, [data])

    useEffect(() => {
        if (togglePopup === false) {
            formValidator.current.hideMessages()
            forceUpdate(!update)
        } else {
            if (inventoryURLs.find(url => url === router.url)) {
                // setFormData({
                //     ...formData,
                //     inventoryCategory: null
                // })
            }
        }
        setOldName(data.name.replace(/^\s+|\s+$/gm,''))
    }, [togglePopup])

    const onSubmit = () => {
        Object.keys(formData).map(k => formData[k] = typeof formData[k] == 'string' ? formData[k].trim().replace(/  +/g, ' ') : formData[k])
        const result = formValidator.current.allValid()
        if (result === false) {
            formValidator.current.showMessages()
            forceUpdate(!update)
            return
        } else {
            formValidator.current.hideMessages()
            forceUpdate(!update)
        }
        parentCallback(formData, oldName)
    }

    const hideModal = () => {
        onModalClose(false)
    }
    const onFormDataChange = (event) => {
        setFormData({
            ...formData,
            [event.target.id]: event.target.value
        })
    }

    const onCategoryChange = (data) => {
        setFormData({
            ...formData,
            "inventoryCategory": data.value
        })
    }

    return (
        <div id="inventoryModal" className={`popup inventoryModal ${togglePopup === true ? "" : "hide"} ${inDashboard ? "inventoryDashboardModal" : ""}`}>
            <div className="popup-header">
                <h3 className="htx2">{title}</h3>
                <span onClick={hideModal} className="close toggle" data-target="closePopup">close</span>
            </div>
            <div className="popup-body">
                <div className="row">
                    {
                        inDashboard &&
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <SingleSelect
                                formData={formData}
                                formKey='inventoryCategory'
                                onDataChange={onCategoryChange}
                                label='Inventory Category'
                                placeholder='Select Inventory Category'
                                isRequired={false}
                                formValidator={formValidator}
                                validation={`required`}
                                options={config.inventoryCategories}
                            />
                        </div>
                    }             
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6">
                        <Textfield
                            formData={formData}
                            formKey='name'
                            onDataChange={onFormDataChange}
                            label='Inventory Name'
                            placeholder='Enter Inventory Name'
                            isRequired={false}
                            formValidator={formValidator}
                            validation={`required|length_name|alpha_space|unique_name:${[...selectedInventories, ...existingAmenities].map(e => e.name)}|existing_name:${schoolExistingInventories && schoolExistingInventories.length > 0 && [...schoolExistingInventories].map(e => e.parentName )}`}
                            maxLength={50}
                        />
                    </div>
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 end-xs">
                        <div className="forminpt mb-0">
                            <input onClick={onSubmit} name={confirmStr} value={confirmStr} className="btn btn-orange" type="button" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InventoryModal
