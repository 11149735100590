import React, { useState } from "react";
import filterArrowSvg from "../../../../assets/images/filter-arrow-svg.svg";
import config from "../../../../service/config";

const SchoolInventoryStatusFilter = ({ openShoolInventoryFilter, setInventoryStatus,inventoryStatus }) => {
    const [openInventory, setOpenInventory] = useState(false);
    const [inventoryType, setInventoryType] = useState('');

    const filterByNames = (val) => {
        // console.log(type)
        // setInventoryStatus(type);
        // setInventoryType(type);
        // if (val === 'A' || val === 'B' || val === 'C') {
            let array = [...inventoryStatus]
        if (array.includes(val)) {
            array.splice(array.indexOf(val), 1)
        }
        else {
            array.push(val)
        }
        setInventoryStatus([...array])
    }
    const clearAllFilter = () => {
        setInventoryStatus([]);
        // setInventoryType('');
    }
    // console.log(categoryType === 'A')
    // console.log(categoryType === 'B')
    // console.log(categoryType === 'C')
    return (
        <div className={`filterDropDown textNone ${openShoolInventoryFilter ? 'open' : ''}`}>
            <div className="filterDropHead">
                <h2>Filter <a href="javascript:void(0);" className="viewwLink" onClick={clearAllFilter}>Clear All</a></h2>
            </div>
            <div className="filterDropBody">
                <div className={`filterDropClose ${openInventory ? 'open' : ''}`}>
                    <h3><a href="javascript:void(0);"
                        onClick={() => setOpenInventory(!openInventory)}
                    >{`Inventory Status`}  <img src={filterArrowSvg} /></a></h3>
                    <div className="filtercheckbox">
                        {config.inventoriesStatus.map((item, index) => (
                            <div className="checkbox fnone" key={index} >
                                <input type="checkbox" id={item.value}
                                checked={inventoryStatus.includes(item.value)}
                                />
                                <label htmlFor={item.value}
                                onClick={() => filterByNames(item.value)}
                                >{item.label}</label>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
        // <div className={`filterDropDown tableFilte ${openShoolInventoryFilter ? 'open' : ''}`}>
        //     <div className="filterDropBody">
        //     <div className="filterDropHead">
        //         <h2>Filter <a href="javascript:void(0);" className="viewwLink" onClick={clearAllFilter}>Clear All</a></h2>
        //     </div>
        //         <div className={`filterDropClose ${openInventory ? 'open' : ''}`}>
        //         <h3><a href="javascript:void(0);" onClick={() => setOpenInventory(!openInventory)}>{`Inventory Status`}  <img src={filterArrowSvg} /></a></h3>
        //             <div className="filtercheckbox mt-10">
        //                 <div className="radioBtn">
        //                     <input type="radio" id="new1" name="radio-group" checked={inventoryType==='newAdded'} onChange={() => filterByNames('newAdded')} />
        //                     <label htmlFor="new1">New Added</label>
        //                 </div>
        //                 <div className="radioBtn">
        //                     <input type="radio" id="new2" name="radio-group1" checked={inventoryType==='approved'} onChange={() => filterByNames('approved')} />
        //                     <label htmlFor="new2">Approved</label>
        //                 </div>
        //                 <div className="radioBtn">
        //                     <input type="radio" id="new3" name="radio-group1" checked={inventoryType==='rejected'} onChange={() => filterByNames('rejected')} />
        //                     <label htmlFor="new3">Rejected</label>
        //                 </div>
        //                 <div className="radioBtn">
        //                     <input type="radio" id="new4" name="radio-group1" checked={inventoryType==='updated'} onChange={() => filterByNames('updated')} />
        //                     <label htmlFor="new4">Updated</label>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </div>
    )
}
export default SchoolInventoryStatusFilter;