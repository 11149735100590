import React, {
    useState,
    useContext,
    useRef,
    useEffect
} from "react"

import { useHistory } from "react-router-dom"

import AppContextObject from "../../../../../common/context/common"

import proEdit from "../../../../../assets/images/edit-profile.svg"
import imgInventory from "../../../../../assets/images/blank-inventory-img.jpg"
import errorSVG from "../../../../../assets/images/error.svg"
import closeCard from "../../../../../assets/images/close-2.svg"
import Textfield from "../../../../../components/formFields/textField/textField"
import Numberfield from "../../../../../components/formFields/numberField"
import ConfirmDialog from "../../../../../components/addon/confirmDialog/confirmDialog"

import SimpleReactValidator from "simple-react-validator"

import { compareObjs, duplicateInventoryName, formatInventories, formatInventoriesForEvents, objectDeepClone } from "../../../../../service/helper"

import config from "../../../../../service/config"

import toast from "react-hot-toast"
import { Steps } from "intro.js-react"
import DataContext from "../../../../../service/contextProvider"
import ImagePreview from "../../../../../components/addon/imagePreview"
import Compress from "compress.js"
import { PublicContextInitiator } from "../../../../apis/publicContextInitiator"
import star from "../../../../../assets/images/star.svg"
import MultiSelect from "../../../../../components/formFields/multiSelect/multiSelect"
import Select from "react-select"
import SingleSelect from "../../../../../components/formFields/singleSelect/singleSelect"
import moment from "moment"
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'

const InventoryCard = (props) => {

    const {
        inventory,
        index,
        openModelApproveActive,
        toolTip1Completed,
        addButtonToolTip,
        setRefetch,
        refetch,
        resetSearchValue,
        addButonIndex
    } = props

    const history = useHistory()
    const compress = new Compress()
    const context = useContext(AppContextObject)
    const parentData = useContext<any>(DataContext)

    const [otherVenueList, setOtherVenueList] = useState([])
    const [tag, setTag] = useState("")
    const [showTagsError, setShowTagsError] = useState(false)
    const [showTagsInput, setShowTagsInput] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [readMode, setReadMode] = useState(true)
    const [showForm, setShowForm] = useState(false)
    const [formData, setFormData] = useState<any>({})
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false)
    const [selectedPlacementIdx, setSelectedPlacementIdx] = useState(null)
    const [errorMessage, setErrorMessage] = useState("")
    const [selectedImageIndex, setSelectedImageIndex] = useState(0)
    const [showImagePreview, setShowImagePreview] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [imagesCount, setImagesCount] = useState([])
    const [removeImage, setRemoveImage] = useState(null);
    const [status, setStatus] = useState({value: inventory.status})
    const [changeStatus, setChangeStatus] = useState({value: inventory.status})
    const [deleteCustomPlacementId, setDeleteCustomPlacementId] = useState("")
    const [deleteCustomPlacementArray, setDeleteCustomPlacementArray] = useState([])
    const [showImageDeleteModal, setShowImageDeleteModal] = useState(false)
    const [deleteImageKey, setDeleteImageKey] = useState("")
    const [showDuplicateModal, setShowDuplicateModal] = useState(false)
    const [showEditInventoryModal, setShowEditInventoryModal] = useState(false)
    const [formErrors, setFormErrors] = useState<any>({})
    const [venueList, setVenueList] = useState([])
    const [placementIndex, setPlacementIndex] = useState(0)
    const [selectedEventIndex, setSelectedEventIndex] = useState(0)
    const [notSupportedImageError, setNotSupportedImageError] = useState("")
    const [showImageError, setShowImageError] = useState(false)
    const [imageErrorIndex, setImageErrorIndex] = useState(null)
    const [loadingImgIndex, setLoadingImgIndex] = useState(-1)
    const [isPageLoading, setIsPageLoading] = useState(false)
    const [formValidator] = useState(useRef(new SimpleReactValidator({
        element: (message, className) =>
            <span className="valError"><img src={errorSVG} />{message}</span>
        })))
    const [defaultAttrs] = useState({
        quantity: "",
        units: "feet",
        length: "",
        height: "",
        breadth: ""
    })
    const [update, forceUpdate] = useState(false)
    const [onBoarding, setOnboarding] = useState({
        stepsEnabled: false,
        initialStep: 0,
        steps: [],
        options: {
            exitOnOverlayClick: false,
            showStepNumbers: true,
            hidePrev: true,
            hideNext: false,
            nextLabel: "Next",
            prevLabel: "Prev",
            doneLabel: "Got it",
            autoPosition: false,
            exitOnEsc: false,
            scrollToElement: false,
            disableInteraction: true
        },
    })
    useEffect(() => {
        // console.log("inventory change")
        setChangeStatus({...changeStatus, value: inventory.status})
        setStatus({...status, value: inventory.status})
    }, [inventory])
    useEffect(() => {
        if(index === addButonIndex && inventory.status === "Not Added" && parentData?.toolTipProgress?.inventory && context.user.role === "schoolPrincipal"){
            // setOpenFisrtToBeAdded(true)
            setTimeout(() => {
                openTableInner()
            }, 100)
        }
    }, [toolTip1Completed])
    useEffect(() => {
        setShowImageError(false)
        setNotSupportedImageError("")
        setImageErrorIndex(null)
    }, [readMode])
    useEffect(() => {
        let arr = []
        config.schoolAmenities.forEach(el => {
            const found = arr.find(e => e.label === el.name)
            if (!found) {
                arr.push({
                    label: el.name,
                    value: el.name
                })
            }
        })
        arr.push({
            label: "Other",
            value: "Other"
        })
        setVenueList(arr)
    }, [])
    useEffect(() => {
        if(addButtonToolTip && context.user.role === "schoolPrincipal"){
            setOnboarding({
                ...onBoarding,
                steps: [
                    {element: ".addInventory", intro: "For inventories that are not added on the Wisr platform. You can request addition to your school admin by clicking here.", position: "left"},
                    {element: "#sendRequest", intro: "Click here to notify your school admin to add the particular inventory on the Wisr platform.", position: "left"}
                ]
            })
        }
    }, [addButtonToolTip])
    useEffect(() => {
        if(index === addButonIndex && toolTip1Completed && onBoarding.steps.length > 0 && parentData?.toolTipProgress?.inventory){
            if(document.getElementsByClassName("addInventory")){
                document
                .getElementsByClassName("addInventory")[0]
                .scrollIntoView({behavior: "smooth"});
                setTimeout(() => {
                    setOnboarding({...onBoarding, stepsEnabled: parentData?.toolTipProgress?.inventory})
                }, 2000)
            }
        }
    }, [toolTip1Completed, onBoarding.steps])
    useEffect(()=>{
        if(removeImage !== null){
            setFormData(formData)
        }
    },[removeImage])

    // useEffect(() => {
    //     if (readMode === true) {
    //         openTableInner()
    //     }
    // }, [readMode])

    const onFormDataChange = (event) => {
        setFormData({ ...formData,
            [event.target.id]: event.target.value
        })        
    }

    const openInventoryDetailPage = () => {
        console.log("inventory", inventory)
        if(inventory.type === "events&Occasions"){
            let inventoryToAdd = objectDeepClone(inventory)
            let placement = inventory.placements.map( placement => {
                placement.data.length = placement.data.length != '' ? placement.data.length : 0;
                placement.data.breadth = placement.data.breadth != '' ? placement.data.breadth : 0;
                placement.data.height = placement.data.height != '' ? placement.data.height : 0;
                placement.data.quantity = placement.data.quantity != '' ? placement.data.quantity : 0;

                return placement;
            });
            inventoryToAdd.placements = placement
            const finalList = formatInventoriesForEvents([inventoryToAdd], "events&Occasions")
            console.log("finalList", finalList)
            history.push({
                pathname: "/schools/school-details/add-invetory/events-and-occasions-details",
                state: {selectedEvent: finalList}
            })
        }
        else{
            let inventoryToAdd = objectDeepClone(inventory)
            let placement = inventory.placements.map( placement => {
                placement.data.length = placement.data.length != '' ? placement.data.length : 0;
                placement.data.breadth = placement.data.breadth != '' ? placement.data.breadth : 0;
                placement.data.height = placement.data.height != '' ? placement.data.height : 0;
                placement.data.quantity = placement.data.quantity != '' ? placement.data.quantity : 0;

                return placement;
            });
            inventoryToAdd.placements = placement
            const finalList = formatInventories([inventoryToAdd], inventory.type, false, false)
            finalList[0].data[0].customName = inventory.customName
            finalList[0].data[0].type = inventory.type
            finalList[0].type = inventory.type
            console.log("finalList", finalList)
            history.push({
                pathname: "/schools/school-details/add-invetory/invetories-details",
                state: {selectedInventory: finalList},
            })
        }
    }

    const addNewInventory = () => {
        if(inventory.type === "events&Occasions"){
            const finalList = formatInventoriesForEvents([inventory], "events&Occasions")
            history.push({
                pathname: "/schools/school-details/add-invetory/events-and-occasions-details",
                state: {selectedEvent: finalList}
            })
        }
        else{
            const finalList = formatInventories([inventory], inventory.type, false, false)
            finalList[0].data[0].customName = inventory.customName
            finalList[0].data[0].type = inventory.type
            finalList[0].type = inventory.type
            history.push({
                pathname: "/schools/school-details/add-invetory/invetories-details",
                state: {selectedInventory: finalList},
            })
        }
        // history.push("/schools/school-details/add-invetory/remaining-invetories")
    }

    const openTableInner = (isEdit?: boolean) => {
        console.log(inventory)
        const id = inventory._id
        const rowContainer = document.getElementById(id);
        const rowBtn = document.getElementById(`${id}-btn`);
        const rowUpArrow = document.getElementById(`${id}-up-arrow`);
        const rowDownArrow = document.getElementById(`${id}-down-arrow`);
        if (rowContainer) rowContainer.style.display = "revert";
        if (rowBtn) rowBtn.style.display = "revert";
        if (rowUpArrow) rowUpArrow.style.display = "revert";
        if (rowDownArrow) rowDownArrow.style.display = "none";
        // let fourChildNode = rowContainer.querySelector('.four');

        if (isEdit === true) {
            let obj = objectDeepClone(inventory)
            if(!Array.isArray(obj.months)){
                obj.months = [obj.months]
            }
            // obj.placements.forEach((placement) => { // remove this loop once the api starts giving units attribute
            //     placement["units"] = "feet"
            // })
            setFormData(objectDeepClone(obj))
            setReadMode(false)
        }
    }

    const closeTableInner = () => {
        const id = inventory._id
        const rowContainer = document.getElementById(id);
        const rowBtn = document.getElementById(`${id}-btn`);
        const rowUpArrow = document.getElementById(`${id}-up-arrow`);
        const rowDownArrow = document.getElementById(`${id}-down-arrow`);
        if (rowContainer) rowContainer.style.display = "none";
        if (rowBtn) rowBtn.style.display = "none";
        if (rowUpArrow) rowUpArrow.style.display = "none";
        if (rowDownArrow) rowDownArrow.style.display = "revert";
        // setShowForm(false)
    }

    const onPlacementDataChange = (index, event) => {
        let info = objectDeepClone(formData.placements)
        info[index][event.target.id] = event.target.value
        setFormData({
            ...formData,
            placements: objectDeepClone(info)
        })
    }
    const onAttrTypeChange = (index, data) => {
        console.log("changes here...", index, data)
        let info = objectDeepClone(formData.placements)
        info[index]["units"] = data.value
        setFormData({
            ...formData,
            placements: objectDeepClone(info)
        })
    }
    useEffect(() => {
        console.log(formData)
    }, [formData])

    const onAddNewSizeClick = () => {
        if(inventory.months || inventory.venue){
            let obj = objectDeepClone(formData)
            let json = {}
            if (obj.placements && obj.placements.length > 0) {
                json = {...objectDeepClone(obj.placements[0]), isCustom: true}
                Object.keys(json).forEach(key => {
                    if (key.toLowerCase() === "units") json[key] = "feet"
                    else if(key.toLowerCase() !== "iscustom") json[key] = ""
                })
                json["_newAdded"] = true
                obj.placements.push(objectDeepClone(json))
                let arr = [...formData.assetsProvidedBy]
                arr.push("Self")
                obj.assetsProvidedBy = [...arr]
                setFormData(objectDeepClone(obj)) 
            } 
        }
        else{
            let obj = objectDeepClone(formData)
            let json = {}
            if (obj.placements && obj.placements.length > 0) {
                json = {...objectDeepClone(obj.placements[0]), _isCustom: true}
                Object.keys(json).forEach(key => {
                    if (key.toLowerCase() === "units") json[key] = "feet"
                    else if(key.toLowerCase() !== "_iscustom") json[key] = ""
                })
                json["_newAdded"] = true
                obj.placements.push(objectDeepClone(json))
                setFormData(objectDeepClone(obj))            
            }
        }
    }

    const removeNewAddedPlacement = (index) => {
        setSelectedPlacementIdx(index)
        setShowConfirmDeleteModal(true)
    }
    const removeCustomPlacement = (index, id) => {
        setSelectedPlacementIdx(index)
        setShowConfirmDeleteModal(true)
        setDeleteCustomPlacementId(id)
    }
    
    const onDeleteModalClose = (confirm) => {
        if (confirm === true) {
            if(deleteCustomPlacementId !== ""){
                setDeleteCustomPlacementArray([...deleteCustomPlacementArray, deleteCustomPlacementId])
            }
            let list = objectDeepClone(formData.placements)
            list.splice(selectedPlacementIdx, 1)
            if(inventory.months || inventory.venue){
                let arr = [...formData.assetsProvidedBy]
                arr.splice(arr.length - 1, 1)
                setFormData({
                    ...formData,
                    assetsProvidedBy: [...arr],
                    placements: objectDeepClone(list)
                })
            }
            else{
                setFormData({
                    ...formData,
                    placements: objectDeepClone(list)
                })
            }
            
        }
        setShowConfirmDeleteModal(false)
    }

    const onCancel = () => {
        setReadMode(true)
        setShowTagsError(false)
        setFormData({})
        process.nextTick(() => {
            openTableInner()
        })
    }

    const onSave =  async (confirm) => {
        if(confirm){
            if(inventory.months || inventory.venue){
                let isMonthsEdited, isVenueEdited, isOtherVenueListEdited, isAssetsProvidedByEdited, IsStatusEdited, isDataEdited;
                isDataEdited = !compareObjs(inventory.placements, formData.placements)
                isMonthsEdited = !compareObjs(inventory.months, formData.months)
                isVenueEdited = !compareObjs(inventory.venue, formData.venue)
                isOtherVenueListEdited = !compareObjs(inventory.otherVenueList, formData.otherVenueList)
                isAssetsProvidedByEdited = !compareObjs(inventory.assetsProvidedBy, formData.assetsProvidedBy)
                IsStatusEdited = status.value !== formData.status
                if(!(isMonthsEdited || isDataEdited || isMonthsEdited || isVenueEdited || isOtherVenueListEdited || isAssetsProvidedByEdited || IsStatusEdited)){
                    setReadMode(true)
                    setShowEditInventoryModal(false)
                    setFormData({})
                    process.nextTick(() => {
                        openTableInner()
                    })
                    return
                }
                else{
                    console.log("object")
                    let isValid = true
                    const result = formValidator.current.allValid()
                    let otherVenueResult
                    if(formData.venue.find(el => el === "Other")){
                        if(formData.otherVenueList && formData.otherVenueList.length === 0){
                            otherVenueResult = false
                        }
                    }
                    console.log(result)
                    if (result === false || otherVenueResult === false) {
                        formValidator.current.showMessages()
                        setShowTagsError(true)
                    } else {
                        formValidator.current.hideMessages()
                        setShowTagsError(false)
                    }
                    forceUpdate(!update)
                    for (let i = 0; i < formData.placements.length; i++) {
                        const placement = formData.placements[i]
                        if((Number(placement["quantity"]) > 0) && (Number(placement["length"]) === 0) && (Number(placement["breadth"]) === 0) && (Number(placement["height"]) === 0)){
                            isValid = false
                        }
                        if((Number(placement["quantity"]) === 0) && ((Number(placement["length"]) > 0) || (Number(placement["breadth"]) > 0) || (Number(placement["height"]) > 0))){
                            isValid = false
                        }
                        const emptyValues = Object.keys(placement).filter(val => {
                            if(val !== "_id" && val !== "images" && val !== "isCustom"){
                                return placement[val] !== null && placement[val] !== undefined && placement[val].toString().trim() === ""
                            }
                        })
                        if (isValid ===  true && emptyValues.length > 0) {
                            console.log(emptyValues)
                            isValid = false
                        }
                    }
                    if (result === false || isValid === false || otherVenueResult === false) {
                        setErrorMessage("Please fill all data.")
                        setShowTagsError(true)
                    } else {
                        setErrorMessage("")
                        setShowTagsError(false)
                        formData.placements.forEach((placement, index) => {
                            placement["data"] = objectDeepClone(placement)
                            delete placement.data.name
                            Object.keys(placement).forEach(pKey => {
                                if (pKey !== "name" && pKey !== "data") delete placement[pKey]
                            })
                        })
                        setIsPageLoading(true)
                        console.log(deleteCustomPlacementArray)
                        const finalList = formatInventoriesForEvents([formData], "events&Occasions")
                        finalList[0]["status"] = formData.status === "Occupied" ? 'Occupied' : (isMonthsEdited || isDataEdited || isVenueEdited || isOtherVenueListEdited || isAssetsProvidedByEdited) ? "In Review" : IsStatusEdited ? status.value : formData.status
                        // console.log(finalList)
                        console.log("finalList----",finalList)
                        let response = await context.apis.public.upsertStudentLifeAndActivitiesWithAttributes(
                            // context.validator, {
                            // "school": context.user.school,
                            // "user": context.user._id,
                            // "amenities": objectDeepClone(finalList),
                            // "removeActivitiesArray": [],
                            // "removeActivitiesAttributeArray": deleteCustomPlacementArray
                            // }, {}
                            context.validator, {
                                student: {
                                    "school": context.user.school,
                                    "user": context.user._id,
                                    "life": [],
                                    "activities": finalList,
                                    "removeActivitiesArray": [],
                                    "removeActivitiesAttributeArray": deleteCustomPlacementArray
                                }
                            }, {}
                        )
                        if (response && response?.done === true) {
                            console.log(response)
                            console.log(response)
                            setRefetch(!refetch)
                            toast.success("Event updated successfully")
                            setReadMode(true)
                            setFormData({})
                            setIsPageLoading(false)
                            setDeleteCustomPlacementArray([])
                            process.nextTick(() => {
                                openTableInner()
                            })
                        } else {
                            toast.error("Something went wrong")
                        }
                    }
                }
            }
            else{
                let isDataEdited, isImagesEdited, IsStatusEdited;
                isDataEdited = !compareObjs(inventory.placements, formData.placements)
                isImagesEdited = !compareObjs(inventory.images, formData.images)
                IsStatusEdited = status.value !== formData.status
                console.log(isDataEdited, isImagesEdited, IsStatusEdited)
                console.log(inventory.placements, formData.placements)
                if(!(isDataEdited || isImagesEdited || IsStatusEdited || inventory.customName !== formData.customName)){
                    if(inventory.customName === formData.customName){
                        setReadMode(true)
                        setShowEditInventoryModal(false)
                        setFormData({})
                        process.nextTick(() => {
                            openTableInner()
                        })
                        return
                    }
                }
                else{
                    let isValid = true
                    const result = formValidator.current.allValid()
                    if (result === false) {
                        formValidator.current.showMessages()
                    } else {
                        formValidator.current.hideMessages()
                    }
                    forceUpdate(!update)
                    for (let i = 0; i < formData.placements.length; i++) {
                        const placement = formData.placements[i]
                        if((Number(placement["quantity"]) > 0) && (Number(placement["length"]) === 0) && (Number(placement["breadth"]) === 0) && (Number(placement["height"]) === 0)){
                            isValid = false
                        }
                        if((Number(placement["quantity"]) === 0) && ((Number(placement["length"]) > 0) || (Number(placement["breadth"]) > 0) || (Number(placement["height"]) > 0))){
                            isValid = false
                        }
                        const emptyValues = Object.keys(placement).filter(val => {
                            if(val !== "_id"){
                                return placement[val] !== null && placement[val] !== undefined && placement[val].toString().trim() === ""
                            }
                        })
                        if (isValid ===  true && emptyValues.length > 0) {
                            isValid = false
                        }
                    }
                    if (result === false || isValid === false) {
                        setErrorMessage("Please fill all data.")
                    } else {
                        setErrorMessage("")
                        formData.placements.forEach((placement, index) => {
                            placement["data"] = objectDeepClone(placement)
                            delete placement.data.name
                            Object.keys(placement).forEach(pKey => {
                                if (pKey !== "name" && pKey !== "data") delete placement[pKey]
                            })
                        })
                        console.log(deleteCustomPlacementArray)
                        setIsPageLoading(true)
                        const finalList = formatInventories([formData], formData.type, false, false)
                        console.log(finalList)
                        finalList[0].data[0].customName = formData.customName
                        finalList[0].data[0].type = formData.type
                        finalList[0].type = formData.type
                        finalList[0].data[0].status = formData.status === "Occupied" ? 'Occupied' : (isDataEdited || isImagesEdited) ? "In Review" : IsStatusEdited ? status.value : formData.status
                        console.log("finalList", finalList, inventory, formData)
                        let response = await context.apis.public.upsertSchoolInventoriesDetailsBySchoolId(
                            context.validator, {
                            "school": context.user.school,
                            "user": context.user._id,
                            "amenities": objectDeepClone(finalList),
                            "removeInventoriesArray": [],
                            "removeInventoryAttributesArray": deleteCustomPlacementArray
                            }, {}
                        )
                        if (response && response?.done === true) {
                            setRefetch(!refetch)
                            toast.success("Inventory updated successfully")
                            setReadMode(true)
                            setFormData({})
                            setIsPageLoading(false)
                            setDeleteCustomPlacementArray([])
                            process.nextTick(() => {
                                openTableInner()
                            })
                        } else {
                            toast.error("Something went wrong")
                        }
                    }
                }
            }
        }
        setShowEditInventoryModal(false)
    }
    const updateUsersToolTipProgress = async () => {
        let userToolTipResp = await context.apis[context.user.role].updateUsersToolTipProgress(
            context.validator, {}, {toolTipProgress: {...parentData.toolTipProgress, inventory: false}}
        )
    }
    const hanldeOnboardingComplete = () => {
        updateUsersToolTipProgress()
        parentData.setToolTipProgress({
            ...parentData.toolTipProgress,
            inventory: false
        })
    }
    const exitOnBoarding = (e) => {
        console.log(e)
    }
    const openImagePreview = (index, eventIndex?) => {
        if(eventIndex){
            setSelectedEventIndex(eventIndex)
        }
        setSelectedImageIndex(index)
        setShowImagePreview(true)
    }
    const deleteSingleUploadedImage = (imageKey) => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type' : 'application/json',
            },
            body: JSON.stringify({ key: imageKey && imageKey })
        }

        fetch('/api/deleteUploadedFiles', requestOptions)
        .then((res)=>{
            res.json()
        })
        .catch(err=>console.log(err))
    }
    const handleDleteImage = (imageKey, index?) => {
        setPlacementIndex(index)
        setDeleteImageKey(imageKey)
        setShowImageDeleteModal(true)
    }
    const deleteImage = (confirm) =>{
        if(confirm){
            // if(isEdit === true && selectedAmenityDetail._id === undefined){
            //     deleteSingleUploadedImage(imageKey);
            // }else if(isEdit === true){
            //     parentData.data.removeSingleUploadedImageArray.push(imageKey)
            // }else{
                deleteSingleUploadedImage(deleteImageKey);
            // }
            const key = deleteImageKey;
            if((inventory.months || inventory.venue)){
                formData.placements[placementIndex].images.splice(formData.placements[placementIndex].images.findIndex(e => e.key === key), 1)
            }
            else{
                formData.images.splice(formData.images.findIndex(e => e.key === key),1);
            }
            if(removeImage !== null){
                setRemoveImage(!removeImage)
            }else{
                setRemoveImage(false)
            }
        }
        setDeleteImageKey("")
        setShowImageDeleteModal(false)
    }
    const handleUpload = async (event, files, index?) =>{
        const formData1 = new FormData();
        setShowImageError(false)
        setNotSupportedImageError("")
        setImagesCount([])
        setIsLoading(true);
        if(index !== undefined || index !== null){
            setLoadingImgIndex(index)
        }
        for (let i = 0; i < files.length; i++) {
            if(!files[i].type.startsWith("image/")){
                setShowImageError(true)
                setNotSupportedImageError(files[i].name)
                if(index !== undefined || index !== null){
                    setImageErrorIndex(index)
                }
                event.target.value = null;
                setIsLoading(false)
                setLoadingImgIndex(-1)
                return
            }
            setImagesCount(prev=>[
                ...prev,
                files[i]
            ])
            const resizedImage = await compress.compress([files[i]], {
                size: 2, // the max size in MB, defaults to 2MB
                quality: 0.4, // the quality of the image, max is 1,
                resize: false // defaults to true, set false if you do not want to resize the image width and height
              })
              const img = resizedImage[0];
              const base64str = img.data
              const imgExt = img.ext
              let compressedFile = Compress.convertBase64ToFile(base64str, imgExt);
              formData1.append(`image`,compressedFile, img.alt);
        }
        let reqBody = formData1
        // if(inDashboard){
            let schoolName = await context.apis[context.user.role].getSchoolName(context.validator, {})
            console.log("schoolName--->>>",schoolName);
            let destinationFolder = ""
            if(schoolName.done){
                if(inventory.months || inventory.venue){
                    destinationFolder = `schools/${context?.user?.school}-${schoolName.response.schoolName}/events`
                }else{
                    destinationFolder = `schools/${context?.user?.school}-${schoolName.response.schoolName}/inventories`
                }
                reqBody.append('destinationFolder',destinationFolder)
            }
            
        // }
        const requestOptions = {
            method: 'POST',
            body: reqBody
        };
      
        let publicContextInitiator = new PublicContextInitiator(context)
        fetch('/api/uploadFiles', requestOptions)
            .then(response => {
                response.json()
                .then(res=>{
                    let data = [];
                    res["response"].map(async item=>{
                        data.push(item.Key)
                    })
                    publicContextInitiator.getUploadedImagesSignedUrl(
                        context.validator, {keys: data}
                    ).then(res=>{
                        let updateImageObj = []
                            if((inventory.months || inventory.venue)){
                                updateImageObj = [...formData.placements[index].images]
                            }
                            else{
                                updateImageObj = [...formData.images]
                            }
                            let requests = res.response.map(item=>{
                            updateImageObj.push({image: item.image, key: item.key});
                            if((inventory.months || inventory.venue)){
                                let placementObj = [...formData.placements]
                                placementObj[index]["images"] = [...updateImageObj]
                                setFormData({...formData, placements: [...placementObj]})
                            }
                            else{
                                setFormData({...formData, images: [...updateImageObj]})
                                parentData.data.unsavedImages.push(item.key)
                            }
                            // let placementObj = [...formData.placements]
                            // placementObj[index]["images"] = [...updateImageObj]
                            // setFormData({...formData, images: [...updateImageObj]})
                        })
                        Promise.all(requests).then(() => {
                            setIsLoading(false)
                            setLoadingImgIndex(-1)
                            });                            
                    })
                })   
            });
            // setIsLoading(false)
    }
    const handleDuplicate = async(confirm) => {
        if(confirm){
            setIsPageLoading(true)
            let duplicateName = ""
            const resp = await context.apis[context.user.role].getInventoriesByParentName(
                context.validator, {parentName: inventory.name.replace(/[0-9]/g, '').trim()}, {}
            )
            console.log(resp)
            if(resp && resp.done){
                duplicateName = duplicateInventoryName(resp.response.rows, inventory.name.replace(/[0-9]/g, '').trim())
            }
            const dupliCateData = objectDeepClone(inventory)
            dupliCateData.placements.forEach((placement, index) => {
                placement["data"] = objectDeepClone(placement)
                delete placement.data.name
                Object.keys(placement).forEach(pKey => {
                    if (pKey !== "name" && pKey !== "data") delete placement[pKey]
                })
            })
            const finalList = formatInventories([dupliCateData], dupliCateData.type, false, true, duplicateName)
            finalList[0].data[0].customName = dupliCateData.customName
            finalList[0].data[0].type = dupliCateData.type
            finalList[0].type = dupliCateData.type
            finalList[0].status = "In Review"
            let name = dupliCateData.name.split("")
            name.splice(name.length - 2, 2)
            // finalList[0].data[0].parentName = name.join("")
            finalList[0].data[0].parentName = inventory.name.replace(/[0-9]/g, '').trim()
            finalList[0].data[0].status = "In Review"   
            let response = await context.apis.public.upsertSchoolInventoriesDetailsBySchoolId(
                context.validator, {
                "school": context.user.school,
                "user": context.user._id,
                "amenities": objectDeepClone(finalList),
                "removeInventoriesArray": [],
                }, {}
            )
            if (response && response?.done === true) {
                setRefetch(!refetch)
                resetSearchValue()
                setIsPageLoading(false)
                toast.success("Inventory duplicated successfully")
                // setReadMode(true)
                // setFormData({})
                // process.nextTick(() => {
                //     openTableInner()
                // })
            } else {
                toast.error("Something went wrong")
            }
        }
        setShowDuplicateModal(false)
    }
    const handleInventoryStatusChange = async(e) => {
        if(inventory.isApproved){
            setChangeStatus({...changeStatus, value:e.value})
            const resp = await context.apis[context.user.role].updateInventoryStatusById(
                context.validator, {_id: inventory._id}, {status: e.value}
            )
            console.log(resp)
            if(resp && resp.done){
                setRefetch(!refetch)
                resetSearchValue()
                toast.success("Inventory status changed successfully")
            }
        }
        else{
            setChangeStatus({...changeStatus, value: "In Review"})
            const resp = await context.apis[context.user.role].updateInventoryStatusById(
                context.validator, {_id: inventory._id}, {status: "In Review"}
            )
            console.log(resp)
            if(resp && resp.done){
                setRefetch(!refetch)
                resetSearchValue()
                toast.success("Inventory status changed successfully")
            }
        }
            
    }
    const handleEventStatusChange = async(e) => {
        if(inventory.isApproved){
            setChangeStatus({...changeStatus, value:e.value})
            const resp = await context.apis[context.user.role].updateActivityStatusById(
                context.validator, {_id: inventory._id}, {status: e.value}
            )
            console.log(resp)
            if(resp && resp.done){
                setRefetch(!refetch)
                resetSearchValue()
                toast.success("Event status changed successfully")
            }
        }
        else{
            setChangeStatus({...changeStatus, value: "In Review"})
            const resp = await context.apis[context.user.role].updateActivityStatusById(
                context.validator, {_id: inventory._id}, {status: "In Review"}
            )
            console.log(resp)
            if(resp && resp.done){
                setRefetch(!refetch)
                resetSearchValue()
                toast.success("Event status changed successfully")
            }
        }
    }
    const onMonthsChange = (months) => {
        const arr = months.map(el => el.value)
        // console.log(months)
        setFormData({...formData, months: [...arr]})
        // onMonthsListUpdate(index, arr)
    }
    const onVenueChange = (venue) => {
        const arr = venue.map(el => el.value)
        setFormData({...formData, venue: [...arr]})
    }
    const handleAssetProvidedByChange = (data, index) => {
        console.log(formData)
        let arr = [...formData.assetsProvidedBy]
        arr.splice(index, 1, data.value)
        // console.log(arr)
        setFormData({...formData, assetsProvidedBy: [...arr]})
    }

    formValidator.current.rules['inventory_name'] = {
        message: "Please enter valid name of Inventory.",
        rule: (val) => {
            let returnValue = true
            const regex2 = /[a-zA-Z]/;
            if (!regex2.test(val)) {
                returnValue = false
            }
            return returnValue
        },
    }

    useEffect(() => {
        if (formData?.venue && formData?.venue.length > 0 && formData.venue.filter(v => v === "Other").length === 1) {
            setShowTagsInput(true)
        } else {
            setShowTagsInput(false)
        }
    }, [formData?.venue])

    const onTagValueChange = (value) => {
        if(value.length <= 50){
            setTag(value)
        }
    }
    const onOtherVenueListChange = (data) => {
        console.log(data)
        if(data.length > 0){
            setShowTagsError(false)
        }
        setFormData({
            ...formData,
            otherVenueList: [...data]
        })
    }
    useEffect(() => {
        console.log("formDataPlacement", inventory, formData)
    }, [readMode])

    return (
        <>
            <div className={`ovelap ${(showConfirmDeleteModal || showImageDeleteModal || showEditInventoryModal || showDuplicateModal) ? "active" : ""}`}></div>
            <tr className="outerTable">
                <td>{inventory.name}</td>
                <td>{inventory.revenueContribution && inventory.revenueContribution || 0}%</td>
                <td>
                    {inventory.bookingSchedule && inventory.bookingSchedule.length > 0 ? inventory.months || inventory.venue ?
                    inventory.bookingSchedule.map((bookedPeriod, index) => (
                        <>{index === inventory.bookingSchedule.length - 1 ? `${bookedPeriod}.` : `${bookedPeriod}, `}</>
                    )) 
                    :
                    inventory.bookingSchedule.map((bookedPeriod, index) => (
                        <div key={index}>{`${moment(bookedPeriod.startDate).format("DD MMM, YY")} - ${moment(bookedPeriod.endDate).format("DD MMM, YY")}`}</div>
                    )) 
                    :
                    "-"
                    }
                 </td>
                <td>
                    <span className={`table-status ${inventory.status === "In Review" && "yellowbg"} ${inventory.status === "Available" && "greenbg"} ${inventory.status === "Occupied" && "orangebg"} ${inventory.status === "Not Added" && "redbg"} ${inventory.status === "Unavailable" && "graybg"} ${inventory.status === "Rejected" && "yellowbg"}`}>
                    {inventory.status}
                    </span>
                </td>
                <td id={inventory.status === "Not Added" ? "addInventory" : ""} className={inventory.status === "Not Added" ? "iconAddEdit addInventory" : "iconAddEdit"}>
                    {
                        inventory.status === "Not Added" ?
                            <a onClick={openInventoryDetailPage} href="javascript:void(0)">
                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6 1V11" stroke="black" stroke-width="1.5" stroke-linecap="round"/>
                                    <path d="M1 6L11 6" stroke="black" stroke-width="1.5" stroke-linecap="round"/>
                                </svg>
                                Add
                            </a>
                            :
                            <>
                                {readMode === true ?
                                <>
                                    <a onClick={() => openTableInner(true)} href="javascript:void(0)"><img src={proEdit}/> Edit</a>
                                    {!(inventory.months || inventory.venue) &&
                                        <a onClick={() => setShowDuplicateModal(true)} href="javascript:void(0)"> Duplicate</a>
                                    }
                                </>
                                :
                                <>
                                    <a onClick={() => setShowEditInventoryModal(true)} href="javascript:void(0)">Save</a>
                                    <a onClick={onCancel} href="javascript:void(0)">Cancel</a>
                                </>
                            }
                            </>
                    }
                    {console.log("readMode--->",readMode)}
                    {
                        readMode === true
                        &&
                        <>
                            <svg onClick={() => openTableInner()} id={`${inventory._id}-down-arrow`}  className="down-arrow" width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M1 6L6 0.999999L11 6" stroke="#8C8C8C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                            <svg onClick={closeTableInner} id={`${inventory._id}-up-arrow`} style={{display: "none"}} width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M1 6L6 0.999999L11 6" stroke="#8C8C8C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                        </>
                    }
                    
                </td>
            </tr>
            {
                readMode === true ?
                <tr className="tableInner" id={inventory._id} style={{display: "none"}}>
                    {!(inventory.months || inventory.venue || inventory.type === "events&Occasions") &&
                        <td className="imgInventory" colSpan={1}>
                            <img src={inventory.images && inventory.images.length >= 1 ? inventory.images[0].image : imgInventory} />
                        </td>
                    }
                    <td colSpan={(inventory.months || inventory.venue || inventory.type === "events&Occasions") ? 5 : 4}>
                        <table className="table">
                            <tbody>
                                <tr>
                                    <td>
                                        <b>{inventory.months || inventory.venue ? "Event Name" : "Inventory name"}</b>
                                        {
                                            <span key={index}>{inventory.months || inventory.venue ? inventory.name : inventory.customName}</span>
                                        }
                                        {(inventory.months || inventory.venue) &&
                                            <>
                                                <b>Venue</b>
                                                <>
                                                    {
                                                        inventory.venue.length !== 0 && inventory.venue.map((venue, index) => (
                                                            <span key={index}>{index < inventory.venue.length - 1 ? `${venue}, ` : venue}</span>
                                                        ))
                                                    }
                                                </>
                                            </>
                                        }
                                        {(inventory.months || inventory.venue) &&
                                            <>
                                                <b>Month</b>
                                                <>
                                                    {
                                                        Array.isArray(inventory.months) ? inventory.months.length !== 0 && inventory.months.map((months, index) => (
                                                            <span key={index}>{index < inventory.months.length - 1 ? `${months}, ` : months}</span>
                                                        ))
                                                        :
                                                        <span>{inventory.months}</span>
                                                    }
                                                </>
                                            </>
                                        }
                                    </td>
                                    {(inventory.months || inventory.venue) &&
                                        <td>
                                            <b>Asset provided by</b>
                                            {
                                                inventory.placements && inventory.placements.map((placement, index) => {
                                                    return(
                                                        <span key={index}>{inventory.assetsProvidedBy[index].toLowerCase() === "self" ? "School" : "Brand"}</span>
                                                    )
                                                })
                                            }
                                        </td>
                                    }
                                    <td>
                                        <b>Placement</b>
                                        {
                                            inventory.placements && inventory.placements.map((placement, index) => {
                                                return(
                                                    <span key={index}>{placement.name}</span>
                                                )
                                            })
                                        }
                                    </td>
                                    <td>
                                        <b>Quantity</b>
                                        {
                                            inventory.placements && inventory.placements.map((placement, index) => {
                                                return(
                                                    <span key={index}>{placement.quantity && placement.quantity || 0}</span>
                                                )
                                            })
                                        }
                                    </td>
                                    <td>
                                        <b>Size</b>
                                        {
                                            inventory.placements && inventory.placements.map((placement, index) => {
                                                return(
                                                    <span key={index}><em>L:</em> {placement.length && placement.length || 0}{placement.units && placement.units === "feet" ? "ft" : placement.units === "inches" && "in"} <em>B:</em> {placement.breadth && placement.breadth || 0}{placement.units && placement.units === "feet" ? "ft" : placement.units === "inches" && "in"}  <em>H:</em> {placement.height && placement.height || 0}{placement.units && placement.units === "feet" ? "ft" : placement.units === "inches" && "in"}</span>
                                                )
                                            })
                                        }
                                    </td>
                                    {(inventory.status === "Available" || inventory.status === "Unavailable") ?
                                        <td>
                                            <b>inventory status</b>
                                            {/* <select disabled={changeStatus.value == "Not Added" || changeStatus.value == "Occupied"} defaultValue={changeStatus.value} onChange={handleInventoryStatusChange}>
                                                <option value="Available">Available</option>
                                                <option value="Unavailable">Unavailable</option>
                                            </select> */}
                                            <SingleSelect
                                                formData={changeStatus}
                                                formKey="value"
                                                onDataChange={(e) => (inventory.months || inventory.venue) ? handleEventStatusChange(e) : handleInventoryStatusChange(e)}
                                                isRequired={false}
                                                options={config.availability}
                                                isDisable={changeStatus.value == "Not Added" || changeStatus.value == "Occupied"}
                                            />
                                        </td>
                                        :
                                        <td></td>
                                    }
                                </tr>
                                    {inventory.images &&
                                        <tr>
                                            <td>
                                                <b>{inventory.images.length !== 0 ? "Images" : inventory.images.length === 0 && "No images"}</b>
                                            </td>
                                            <td>
                                            </td>
                                            <td>
                                            </td>
                                            <td>
                                            </td>
                                            <td>
                                            </td>
                                        </tr>
                                    }
                                {inventory.images && inventory.images.length !== 0 &&
                                    <tr>
                                        <td className="manageImgSlider" colSpan={5}>
                                            <section id="gallery">
                                                <div className="container">
                                                    <div id="image-gallery">
                                                        {inventory.images && inventory.images.length !== 0 && inventory.images.map((el, index) => (
                                                            <div className="image" key={index} style={{height:'40px'}}>
                                                                                        
                                                                <div onClick={() => openImagePreview(index)} id="imageId1" className="img-wrapper">
                                                                    <a href="javascript:void(0)"><img src={el.image} className="img-responsive" /></a>
                                                                    <div className="img-overlay"> <i className="fa fa-eye" aria-hidden="true"></i> </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    <ImagePreview
                                                        setShowImagePreview={setShowImagePreview}
                                                        showImagePreview={showImagePreview}
                                                        images={inventory.images && inventory.images}
                                                        selectedImageIndex={selectedImageIndex}
                                                    />
                                                </div>
                                            </section>
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </td>
                </tr> :
                <>
                {!(inventory.months || inventory.venue) ?
                    <tr className="tableInner">
                        <td className="imgInventory" colSpan={1}>
                            <img src={inventory.images && inventory.images.length >= 1 ? inventory.images[0].image : imgInventory} />
                        </td>
                        <td className="inventoryCustomName pr-0">
                            <Textfield
                                formData={formData}
                                isDisabled={(inventory.months || inventory.venue) && !inventory.custom ? true : false}
                                formKey={inventory.months || inventory.venue ? 'name' : "customName"}
                                onDataChange={onFormDataChange}
                                label={inventory.months || inventory.venue ? 'Event Name' : 'Inventory Name'}
                                placeholder={inventory.months || inventory.venue ? 'Event Name' : 'Inventory Name'}
                                isRequired={false}
                                formValidator={formValidator}
                                validation={inventory.months || inventory.venue ? 'required' : "string"}
                                maxLength={50}
                            />
                        </td>
                        <td className="inventoryCustomName" colSpan={(inventory.status === "Available" || inventory.status === "Unavailable") ? 2 : 3}>
                            <table className="table placementTable inventoryCustomMargin">
                                <thead>
                                    <tr>
                                        <th>Placement</th>
                                        <th>Quantity</th>
                                        <th>L</th>
                                        <th>B</th>
                                        <th>H</th>
                                        <th>Units</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        formData.placements && formData.placements.map((placement, index) => (
                                            <tr key={index}>
                                                <td>
                                                    <>
                                                        {
                                                            placement._newAdded === true || placement._isCustom ?
                                                            <>
                                                            <div className="forminpt customPlacement customInventory">
                                                                <Textfield
                                                                    formData={placement}
                                                                    formKey="name"
                                                                    label={true}
                                                                    onDataChange={(event) => onPlacementDataChange(index, event)}
                                                                    placeholder='Name'
                                                                    isRequired={false}
                                                                    maxLength={50}
                                                                />
                                                                {placement._isCustom &&
                                                                    <img src={star} className="starCard" alt="star" />
                                                                }
                                                            </div>
                                                                
                                                            </>
                                                            :
                                                            <div className="forminpt">
                                                                <label htmlFor={placement.name} className="placementName">
                                                                    {placement.name}
                                                                </label>
                                                            </div>                                                       
                                                        }
                                                    </>
                                                </td>
                                                <td>
                                                    <>
                                                        <div className="forminpt">
                                                            {
                                                                <Numberfield
                                                                    formData={placement}
                                                                    label={true}
                                                                    formKey="quantity"
                                                                    onDataChange={(event) => onPlacementDataChange(index, event)}
                                                                    placeholder='100'
                                                                    isRequired={false}
                                                                />
                                                            }
                                                        </div>
                                                    </>
                                                </td>
                                                <td>
                                                    <>
                                                        <div className="forminpt">
                                                            {
                                                                <Numberfield
                                                                    formData={placement}
                                                                    label={true}
                                                                    formKey="length"
                                                                    onDataChange={(event) => onPlacementDataChange(index, event)}
                                                                    placeholder='100'
                                                                    isRequired={false}
                                                                    allowDecimal={true}
                                                                />
                                                            }
                                                        </div>
                                                    </>
                                                </td>
                                                <td>
                                                    <>
                                                        <div className="forminpt">
                                                            {
                                                                <Numberfield
                                                                    formData={placement}
                                                                    label={true}
                                                                    formKey="breadth"
                                                                    onDataChange={(event) => onPlacementDataChange(index, event)}
                                                                    placeholder='100'
                                                                    isRequired={false}
                                                                    allowDecimal={true}
                                                                />
                                                            }
                                                        </div>
                                                    </>
                                                </td>
                                                <td>
                                                    <>
                                                        <div className="forminpt">
                                                            {
                                                                <Numberfield
                                                                    formData={placement}
                                                                    label={true}
                                                                    formKey="height"
                                                                    onDataChange={(event) => onPlacementDataChange(index, event)}
                                                                    placeholder='100'
                                                                    isRequired={false}
                                                                    allowDecimal={true}
                                                                />
                                                            }
                                                        </div>
                                                    </>
                                                </td>
                                                <td>
                                                    <div className="forminpt">
                                                        {/* <select id="units"
                                                            onChange={(event) => onPlacementDataChange(index, event)}
                                                            value={placement.units}
                                                        >
                                                            {config.attrMeasurementUnits.map((unit, index) =>{
                                                                return (
                                                                    <option
                                                                    key={index}
                                                                    value={unit.value}
                                                                    selected={placement.units == unit.value} >
                                                                    {unit.label}</option>    
                                                                )
                                                            })}
                                                        </select> */}
                                                        <SingleSelect
                                                            formData={placement}
                                                            formKey="units"
                                                            onDataChange={(data: any) => onAttrTypeChange(index,data)}
                                                            isRequired={false}
                                                            options={config.attrMeasurementUnits}
                                                        />
                                                        <span className="closeEdit">
                                                            {
                                                                placement._newAdded === true ?
                                                                    <a onClick={() => removeNewAddedPlacement(index)} href="javascript:void(0)"><img src={closeCard} /></a>
                                                                    :
                                                                    placement._isCustom === true && 
                                                                    <a onClick={() => removeCustomPlacement(index, placement._id)} href="javascript:void(0)"><img src={closeCard} /></a>
                                                            }
                                                        </span>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                    <tr className="pd0-td">
                                        <td colSpan={6}>
                                            <a href='javascript:void(0)' onClick={onAddNewSizeClick}
                                                className='addsize'
                                                data-target='approvalAll' >+ Add Size</a>&nbsp;<small>(Add sizes for branding only)</small>
                                        </td>
                                    </tr>
                                    {!(inventory.months || inventory.venue) &&
                                        <tr className="pd0-td">
                                            <td>
                                                <b>Images</b>
                                            </td>
                                            <td colSpan={5}>
                                            </td>
                                        </tr>
                                    }
                                    {!(inventory.months || inventory.venue) &&
                                        <tr>
                                            <td className="imgUpImg" colSpan={5}>
                                            <section id="gallery">
                                                    <div className="container">
                                                        <div id="image-gallery" className="manageImage manageImageList">
                                                            {isLoading ? 
                                                                <div className="image" style={{height:'40px'}}>
                                                                    <div className="loader-imgupload"></div>
                                                                </div> 
                                                                :
                                                                formData.images && formData.images.length !== 0 && formData.images.map((el, index) => (
                                                                <div className="image" key={index} style={{height:'40px'}}>
                                                                                                
                                                                    <div onClick={() => openImagePreview(index)} id="imageId1" className="img-wrapper">
                                                                        <a href="javascript:void(0)"><img src={el.image} className="img-responsive" /></a>
                                                                        <div className="img-overlay"> <i className="fa fa-eye" aria-hidden="true"></i> </div>
                                                                    </div>
                                                                    <a onClick={()=>handleDleteImage(el.key)} href="javascript:void(0)" className="removeImg"><i className="fa fa-plus-circle" aria-hidden="true"></i></a>
                                                                </div>
                                                            ))}
                                                            <div className="image uploadImgDesign" style={{height:'40px'}}>
                                                                <label className="uploadImgPlus">+</label>
                                                                <input name="amenityImages" type="file" accept="image/*" title="" onChange={(event)=> handleUpload(event, event.target.files)} multiple={true} />
                                                            </div>
                                                        </div>
                                                        {showImageError && notSupportedImageError !== "" &&
                                                            <p
                                                                style={{
                                                                fontSize: "0.8rem",
                                                                color: "tomato",
                                                                paddingTop: "0.5rem",
                                                                }}
                                                            >
                                                                <span className="valError">
                                                                    <img src={errorSVG} />
                                                                    <p>The uploaded file is not supported. Please upload image file.</p>
                                                                </span>
                                                            </p>
                                                        }
                                                        <ImagePreview
                                                            setShowImagePreview={setShowImagePreview}
                                                            showImagePreview={showImagePreview}
                                                            images={formData.images && formData.images}
                                                            selectedImageIndex={selectedImageIndex}
                                                        />
                                                    </div>
                                                </section>
                                            </td>
                                            <td>
                                            </td>
                                        </tr>
                                    }
                                    {
                                        errorMessage &&
                                        <tr className="tableInner">
                                            <td colSpan={5}>
                                                <p className="valError"><img src={errorSVG} /> {errorMessage}</p>
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </td>
                        {(inventory.status === "Available" || inventory.status === "Unavailable") ? 
                            <td className="inventoryCustomName pl-0">
                                <div className="forminpt">
                                    <label>INVENTORY STATUS</label>
                                    {/* <select defaultValue={status.value} onChange={(e) => setStatus({...status,value: e.target.value})}>
                                    <option value="Available">Available</option>
                                        <option value="Unavailable">Unavailable</option>
                                    </select> */}
                                    <div className="single-select">
                                    <SingleSelect
                                        formData={status}
                                        formKey="value"
                                        onDataChange={(e) => setStatus({...status,value: e.value})}
                                        isRequired={false}
                                        options={config.availability}
                                    />
                                    </div>
                                </div>
                            </td>
                            :
                            <td></td>
                        }
                    </tr>
                    :
                    <tr className="tableInner">
                        {/* <td className="imgInventory" colSpan={1}>
                            <img src={inventory.images && inventory.images.length >= 1 ? inventory.images[0].image : imgInventory} />
                        </td> */}
                        <td colSpan={5}>
                            <table className="table placementTable">
                                <tr>
                                    <td colSpan={1}>
                                        <Textfield
                                            formData={formData}
                                            isDisabled={(inventory.months || inventory.venue) && !inventory.custom ? true : false}
                                            formKey={inventory.months || inventory.venue ? 'name' : "customName"}
                                            onDataChange={onFormDataChange}
                                            label={inventory.months || inventory.venue ? 'Event Name' : 'Inventory Name'}
                                            placeholder={inventory.months || inventory.venue ? 'Event Name' : 'Inventory Name'}
                                            isRequired={false}
                                            formValidator={formValidator}
                                            validation={inventory.months || inventory.venue ? 'required' : "string"}
                                            maxLength={50}
                                        />
                                    </td>
                                    <td colSpan={3}>
                                        <b>Venue</b>
                                        <div>
                                            <MultiSelect
                                                formData={formData}
                                                formKey="venue"
                                                onDataChange={onVenueChange}
                                                placeholder="Select Venue"
                                                isRequired={false}
                                                options={venueList}
                                                formErrors={formErrors}
                                                formValidator={formValidator}
                                                validation="required|array|min:1"
                                            />
                                        </div>
                                        {showTagsInput &&
                                            <>
                                                
                                                    <div className="venueInputother">
                                                        <TagsInput
                                                            value={formData.otherVenueList || []}
                                                            onChange={onOtherVenueListChange}
                                                            inputValue={tag}
                                                            onChangeInput={onTagValueChange}
                                                            inputProps={{
                                                                className: "tags-input",
                                                                placeholder: "Enter venue name"
                                                            }}
                                                            tagProps={{
                                                                className: 'react-tagsinput-tag-custom '
                                                            }}
                                                            addOnBlur={true}
                                                            addOnPaste={true}
                                                        />
                                                        {
                                                            showTagsError === true &&
                                                            <span className="valError"><img src={errorSVG} />Please provide venue name.</span>
                                                        }
                                                    </div>                              
                                            </>
                                        }
                                    </td>
                                    <td colSpan={3}>
                                    <b>Month</b>
                                        <div>
                                            <MultiSelect
                                                formData={formData}
                                                formKey="months"
                                                onDataChange={onMonthsChange}
                                                placeholder="Select Months"
                                                isRequired={false}
                                                options={config.months}
                                                formErrors={formErrors}
                                                formValidator={formValidator}
                                                validation="required|array|min:1"
                                            />
                                        </div>
                                    </td>
                                    {(inventory.status === "Available" || inventory.status === "Unavailable") ?
                                        <td>
                                            <b>inventory status</b>
                                            <div className="forminpt">
                                                {/* <select defaultValue={status.value} onChange={(e) => setStatus({...status,value: e.target.value})}>
                                                    <option value="Available">Available</option>
                                                    <option value="Unavailable">Unavailable</option>
                                                </select> */}
                                                <SingleSelect
                                                    formData={status}
                                                    formKey="value"
                                                    onDataChange={(e) => setStatus({ ...status, value: e.value })}
                                                    isRequired={false}
                                                    options={config.availability}
                                                />
                                            </div>
                                        </td>
                                        :
                                        <td></td>
                                    }
                                </tr>
                                
                                {
                                    formData.placements && formData.placements.map((placement, index) => (
                                        <tr>
                                            <td>
                                                {index === 0 && <b>Placement</b>}
                                                {
                                                    placement._newAdded === true || placement.isCustom ?
                                                    <>
                                                        <div className="forminpt customPlacement">
                                                            <Textfield
                                                                formData={placement}
                                                                formKey="name"
                                                                label={true}
                                                                onDataChange={(event) => onPlacementDataChange(index, event)}
                                                                placeholder='Name'
                                                                isRequired={false}
                                                                maxLength={50}
                                                            />
                                                            {placement.isCustom &&
                                                                <img src={star} className="starCard" alt="star" />
                                                            }
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        <div className="forminpt">
                                                            <label htmlFor={placement.name} className="placementName">
                                                                {placement.name}
                                                            </label>
                                                        </div>
                                                    </>
                                                }
                                                {index === formData.placements.length - 1 &&
                                                    <>
                                                        <a href='javascript:void(0)' onClick={onAddNewSizeClick}
                                                            className='addsize'
                                                            data-target='approvalAll' >+ Add Size
                                                        </a>&nbsp;<small>(Add sizes for branding only)</small>
                                                    </>
                                                }
                                            </td>
                                            <td>
                                                {index === 0 && <b>Images</b>}
                                                <section id="gallery">
                                                    <div className="container">
                                                        <div id="image-gallery" className="manageImage" style={{display: isLoading && loadingImgIndex === index ? "grid" : placement?.images?.length === 0 ? 'none' : "grid"}}>
                                                            {isLoading && loadingImgIndex === index ? 
                                                                <div className="image" style={{height:'40px'}}>
                                                                    <div className="loader-imgupload"></div>
                                                                </div> 
                                                                :
                                                                placement.images && placement.images.length !== 0 && placement.images.map((el, index1) => (
                                                                <div className="image" key={index} style={{height:'40px'}}>
                                                                                                
                                                                    <div onClick={() => openImagePreview(index1, index)} id="imageId1" className="img-wrapper">
                                                                        <a href="javascript:void(0)"><img src={el.image} className="img-responsive" /></a>
                                                                        <div className="img-overlay"> <i className="fa fa-eye" aria-hidden="true"></i> </div>
                                                                    </div>
                                                                    <a onClick={()=>handleDleteImage(el.key, index)} href="javascript:void(0)" className="removeImg"><i className="fa fa-plus-circle" aria-hidden="true"></i></a>
                                                                </div>
                                                            ))}
                                                        </div>
                                                        <div className="image uploadImgDesign eventMargin" style={{height:'40px'}}>
                                                            <label className="uploadImgPlus">+</label>
                                                            <input name="amenityImages" type="file" accept="image/*" title="" onChange={(event)=> handleUpload(event, event.target.files, index)} multiple={true} />
                                                        </div>
                                                    </div>
                                                    {showImageError && notSupportedImageError !== "" && index === imageErrorIndex &&
                                                        <p
                                                            style={{
                                                            fontSize: "0.8rem",
                                                            color: "tomato",
                                                            paddingTop: "0.5rem",
                                                            }}
                                                        >
                                                            <span className="valError">
                                                                <img src={errorSVG} />
                                                                <p>The uploaded file is not supported. Please upload image file.</p>
                                                            </span>
                                                        </p>
                                                    }
                                                </section>
                                            </td>
                                            <td>
                                                {index === 0 && <b>Asset provided by</b>}
                                                <div className="forminpt">
                                                    <Select
                                                        classNamePrefix="wisr-react-select"
                                                        options={[{label: "Brand", value: "Brand"}, {label: "Self", value: "Self"}]}
                                                        onChange={(data) => handleAssetProvidedByChange(data, index)}
                                                        value={[{label: "Brand", value: "Brand"}, {label: "Self", value: "Self"}].filter((option) => {
                                                            return option.value === formData.assetsProvidedBy[index]
                                                        })}
                                                    />
                                                    {formValidator?.current?.message("Asset provided by", formData.assetsProvidedBy[index], "required")}
                                                </div>
                                            </td>
                                            <td>
                                                {index === 0 && <b>Quantity</b>}
                                                <div className="forminpt">
                                                    {
                                                        <Numberfield
                                                            formData={placement}
                                                            label={true}
                                                            formKey="quantity"
                                                            onDataChange={(event) => onPlacementDataChange(index, event)}
                                                            placeholder='100'
                                                            isRequired={false}
                                                        />
                                                    }
                                                </div>
                                            </td>
                                            <td>
                                                {index === 0 && <b>L</b>}
                                                <div className="forminpt">
                                                    {
                                                        <Numberfield
                                                            formData={placement}
                                                            label={true}
                                                            formKey="length"
                                                            onDataChange={(event) => onPlacementDataChange(index, event)}
                                                            placeholder='100'
                                                            isRequired={false}
                                                            allowDecimal={true}
                                                        />
                                                    }
                                                </div>
                                            </td>
                                            <td>
                                                {index === 0 && <b>B</b>}
                                                <div className="forminpt">
                                                    {
                                                        <Numberfield
                                                            formData={placement}
                                                            label={true}
                                                            formKey="breadth"
                                                            onDataChange={(event) => onPlacementDataChange(index, event)}
                                                            placeholder='100'
                                                            isRequired={false}
                                                            allowDecimal={true}
                                                        />
                                                    }
                                                </div>
                                            </td>
                                            <td>
                                                {index === 0 && <b>H</b>}
                                                <div className="forminpt">
                                                    {
                                                        <Numberfield
                                                            formData={placement}
                                                            label={true}
                                                            formKey="height"
                                                            onDataChange={(event) => onPlacementDataChange(index, event)}
                                                            placeholder='100'
                                                            isRequired={false}
                                                            allowDecimal={true}
                                                        />
                                                    }
                                                </div>
                                            </td>
                                            <td>
                                                {index === 0 && <b>Units</b>}
                                                <div className="forminpt eventunits">
                                                        <SingleSelect
                                                            formData={placement}
                                                            formKey="units"
                                                            onDataChange={(data: any) => onAttrTypeChange(index,data)}
                                                            isRequired={false}
                                                            options={config.attrMeasurementUnits}
                                                        />
                                                    <span className="closeEdit">
                                                        {
                                                            placement._newAdded === true ?
                                                                <a onClick={() => removeNewAddedPlacement(index)} href="javascript:void(0)"><img src={closeCard} /></a>
                                                                :
                                                                placement.isCustom === true && 
                                                                <a onClick={() => removeCustomPlacement(index, placement._id)} href="javascript:void(0)"><img src={closeCard} /></a>
                                                        }
                                                    </span>
                                                </div>
                                            </td>
                                            <ImagePreview
                                                setShowImagePreview={setShowImagePreview}
                                                showImagePreview={showImagePreview}
                                                images={formData.placements[selectedEventIndex].images && formData.placements[selectedEventIndex].images}
                                                selectedImageIndex={selectedImageIndex}
                                            />
                                        </tr>
                                    ))

                                }
                                {
                                    errorMessage &&
                                    <tr className="tableInner">
                                        <td colSpan={5}>
                                            <p className="valError"><img src={errorSVG} /> {errorMessage}</p>
                                        </td>
                                    </tr>
                                }
                            </table>
                        </td>
                    </tr>
                }
                </>
            }
            <tr id={`${inventory._id}-btn`} style={{display:"none"}}>
                <td className="sendRequest" colSpan={5}>
                    {
                        inventory.status === "Not Added" 
                        ? 
                            context.user.role === "schoolPrincipal"
                            ? 
                                <a id="sendRequest" href='javascript:void(0)' onClick={()=> openModelApproveActive(inventory)}
                                className='btn btn-orange sendRequest'
                                data-target='approvalAll'>Send Request</a>
                            :
                                <a href='javascript:void(0)' onClick={addNewInventory}
                                className={`btn btn-orange ${inventory.type === "events&Occasions" ? "addEvent" : "addInventory"}`}
                                data-target='approvalAll'>{inventory.type === "events&Occasions" ? "Add Event" : "Add Inventory"}</a>
                        :
                        ""
                    }
                </td>
            </tr>

            <ConfirmDialog
                togglePopup={showConfirmDeleteModal}
                onModalClose={onDeleteModalClose}
                componentName={""}
            />
            <ConfirmDialog
                togglePopup={showImageDeleteModal}
                onModalClose={deleteImage}
                componentName={""}
            />
            <ConfirmDialog
                confirmTitle="Do you want to duplicate this inventory"
                togglePopup={showDuplicateModal}
                onModalClose={handleDuplicate}
                componentName={""}
                isLoading={isPageLoading}
            />
            <ConfirmDialog
                confirmTitle="Do you want to edit this inventory"
                togglePopup={showEditInventoryModal}
                onModalClose={onSave}
                componentName={""}
                isLoading={isPageLoading}
            />
            <Steps
                enabled={onBoarding.stepsEnabled}
                steps={onBoarding.steps}
                initialStep={onBoarding.initialStep}
                onComplete={hanldeOnboardingComplete}
                onExit={exitOnBoarding}
                options={onBoarding.options}
            />
            {/* confirmTitle */}
        </>
    )
}

export default InventoryCard
