import React, { useEffect, useState, useContext, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import uploadSvg from "../../../../../assets/images/upload-svg.svg"
import sucessCheck from "../../../../../assets/images/sucess-check.svg"
import closeSvg from "../../../../../assets/images/close-svg.svg"
import blockSvg from "../../../../../assets/images/block-svg.svg"
import reloadSvg from "../../../../../assets/images/reload-svg.svg"
import filterSvg from "../../../../../assets/images/filter-svg.svg"
import filterArrowSvg from "../../../../../assets/images/filter-arrow-svg.svg"
import mapsImg from "../../../../../assets/images/maps.jpg"
import creativeImgViewOne from "../../../../../assets/images/img-view-one.jpg"
import creativeImgViewTwo from "../../../../../assets/images/img-view-two.jpg"
import creativeImgViewThree from "../../../../../assets/images/img-view-three.jpg"
import imgInventory from "../../../../../assets/images/blank-inventory-img.jpg"
import AppContextObject from "../../../../../common/context/common"
import { formatDateShowMonth, titleCase } from "../../../../../service/helper"
import DataContext from "../../../../../service/backendContext"
// import CampaignBudget from '../components/campaignBudget'
// import GenderSplit from '../components/genderSplit'
// import CampaignReach from '../components/campaignReach'
// import CampaignImpression from '../components/campaignImpression'
import { returnReadableStatus } from '../../../../../service/helper'
import TextField from '../../../../../components/formFields/textField/textField'
import SimpleReactValidator from "simple-react-validator"
import errorSVG from "../../../../../assets/images/error.svg"
import editSvg from "../../../../../assets/images/edit-btn.svg"
import TextArea from '../../../../../components/formFields/textArea'
// import BrandMou from '../components/brandMou'
import AlertDialog from '../../../../../components/addon/alertDialog/alertDialog'
// import BrandBackBtn from "../components/brandBackBtn"
import ConfirmDialog from '../../../../../components/addon/confirmDialog/confirmDialog'
import { ReactInternetSpeedMeter } from 'react-internet-meter'
import BackendBackBtn from '../../components/backendBackBtn'

const NonWisrCampaignPreview = ({ setShowProgressSideMenu }) => {
    const history = useHistory()
    const [formValidator] = useState(useRef(new SimpleReactValidator({
        element: (message, className) =>
            <span className="valError"><img src={errorSVG} />{message}</span>
    })
    ))
    const context = useContext(AppContextObject)
    const parentData = useContext<any>(DataContext)
    // const [reach, setReach] = useState(parentData?.nonWisrCampaignData?.campaignDetail?.totalReach)
    // const [impression, setImpression] = useState(parentData?.nonWisrCampaignData?.campaignDetail?.totalImpression)
    const [update, forceUpdate] = useState(false)
    const [showAlertMessage, setShowAlertMessage] = useState(false)
    const [pageToRedirect, setPageToRedirect] = useState("")
    const [totalBudget, setTotalBudget] = useState(parentData?.nonWisrCampaignData?.campaignDetail?.totalBudget)
    const [totalReach, setTotalReach] = useState(parentData?.nonWisrCampaignData?.campaignDetail?.totalReach)
    const [boysRatio, setBoysRatio] = useState(parentData?.nonWisrCampaignData?.campaignDetail?.boysRatio)
    const [girlsRatio, setGirlsRatio] = useState(parentData?.nonWisrCampaignData?.campaignDetail?.girlsRatio)
    const [totalImpression, setTotalImpression] = useState(parentData?.nonWisrCampaignData?.campaignDetail?.totalImpression)
    const [overlay, setOverlay] = useState(false)
    const [isTermAndConditionCheck, setIsTermAndConditionCheck] = useState(false)
    const [showEditBrandDetail, setShowEditBrandDetail] = useState(false)
    const [campaignName, setCampaignName] = useState({ name: "" })
    const [campaignDescription, setCampaignDescription] = useState({ description: "" })
    const [showMou, setShowMou] = useState(false)
    const [location, setLocation] = useState([])
    const [alertMessage, setAlertMessage] = useState("")
    const [title, setTitle] = useState("")
    const [buttonText, setButtonText] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [wifiSpeed, setwifiSpeed] = useState<any>(null)
    const [pingIntervalTime, setPingIntervalTime] = useState(1000)
    const [checkSpeed, setCheckSpeed] = useState(false)
    const [canSaveData, setCanSaveData] = useState<any>(null)
    const [toggleNetworkDialog, setToggleNetworkDialog] = useState(false)
    const [isInternetOK, setIsInternetOK] = useState(false)
    const [loading, setLoading] = useState(false)
    const [apiHited, setApiHited] = useState(false)

    useEffect(() => {
        if (parentData.nonWisrCampaignData.school.schools && parentData.nonWisrCampaignData.school.schools.length > 0) {
            let data = []
            parentData.nonWisrCampaignData.school.schools.forEach(school => {
                if(!data.includes(school.city)){
                    data.push(school.city)
                }
            })
            console.log(data)
            setLocation([...data])
        }
        if (parentData.nonWisrCampaignData.brandDetail.isTrial) {
            setIsTermAndConditionCheck(true)
        }
    }, [])
    useEffect(() => {
        setShowProgressSideMenu(true)
        if (parentData.setNonWisrCampaignActiveModule) {
            parentData.setNonWisrCampaignActiveModule({
                ...parentData.nonWisrCampaignActiveModule,
                module: "preview",
            })
        }
        if (parentData && parentData.nonWisrCampaignData) {
            setCampaignName({ name: parentData.nonWisrCampaignData.brandDetail.campaign.name })
            setCampaignDescription({ description: parentData.nonWisrCampaignData.brandDetail.description })
        }
        setTotalBudget(parentData.nonWisrCampaignData.campaignDetail.totalBudget)
        setTotalReach(parentData.nonWisrCampaignData.campaignDetail.totalReach)
        setTotalImpression(parentData.nonWisrCampaignData.campaignDetail.totalImpression)
        setGirlsRatio(parentData.nonWisrCampaignData.campaignDetail.girlsRatio)
        setBoysRatio(parentData.nonWisrCampaignData.campaignDetail.boysRatio)
    }, [])
    useEffect(() => {
        setwifiSpeed(null)
        setCanSaveData(null)
    }, [])
    useEffect(() => {
        console.log('wifiSpeed=====>', wifiSpeed, history.location.pathname)
        if (history.location.pathname === "/backend/backend-details/nonWisr-create-campaign/nonWisr-campaign-preview" || history.location.pathname === "//backend/backend-details/nonWisr-create-trial-campaign/nonWisr-campaign-preview") {
            console.log("wifiSpeed--->>>>>", wifiSpeed);
            if (wifiSpeed !== null) {
                if (Number(wifiSpeed) < context.utils.internetSpeedLimit) {
                    console.log("wifiSpeed and networkOK 1", wifiSpeed, isInternetOK);
                    setIsInternetOK(false)
                    setCanSaveData(false)
                    setIsLoading(false)
                    setToggleNetworkDialog(true)
                    setOverlay(true)
                } else {
                    if (!apiHited) {
                        console.log("wifiSpeed and networkOK 2", wifiSpeed, isInternetOK);
                        setIsInternetOK(true)
                        console.log("wifiSpeed>>>>", wifiSpeed);
                        setCanSaveData(true)
                        setApiHited(true)
                        handleSubmit()
                        // setwifiSpeed(0)
                    }
                }
                // setCanSaveData(null)
            } else {
                // setIsLoading(false)
            }
        }
    }, [wifiSpeed])
    const testSpeed = () => {
        setCheckSpeed(true)
        console.log("test speed hitted");
        // setTimeout(() => {
        //     setPingIntervalTime(1000)
        //     setCheckSpeed(false)
        //     // setwifiSpeed(0)
        //     console.log("wifiSpeed>>>>>", wifiSpeed);
        // }, 2000)
    }
    const networkConfirmClose = (confirm) => {
        setToggleNetworkDialog(false)
        setwifiSpeed(null)
        setCanSaveData(null)
        setCheckSpeed(false)
        setOverlay(false)
    }
    const onCampaignNameChange = (e) => {
        setCampaignName({ name: e.target.value })
    }
    const onDescriprionChange = (e) => {
        setCampaignDescription({ description: e.target.value })
    }
    const openEditModal = () => {
        setCampaignName({ name: parentData.nonWisrCampaignData.brandDetail.campaign.name })
        setCampaignDescription({ description: parentData.nonWisrCampaignData.brandDetail.description })
        setOverlay(true)
        setShowEditBrandDetail(true)
    }
    const saveCampaignDetail = () => {
        const result = formValidator.current.allValid()
        if (result === false) {
            formValidator.current.showMessages()
            forceUpdate(true)
            return
        } else {
            formValidator.current.hideMessages()
            forceUpdate(!update)
        }
        if (parentData && parentData.setNonWisrCampaignData) {
            parentData.setNonWisrCampaignData({
                ...parentData.nonWisrCampaignData,
                brandDetail: {
                    ...parentData.nonWisrCampaignData.brandDetail,
                    campaign: {name: campaignName.name, _id: campaignName.name === parentData.nonWisrCampaignData.brandDetail.campaign.name ? parentData.nonWisrCampaignData.brandDetail.campaign._id : ""},
                    description: campaignDescription.description
                }
            })
        }
        setOverlay(false)
        setShowEditBrandDetail(false)
    }
    const handleSubmit = async () => {
        if (parentData) {
            if (parentData.setNonWisrCampaignProgress) {
                parentData.setNonWisrCampaignProgress({
                    ...parentData.nonWisrCampaignProgress,
                    preview: true,
                })
            }
            try {
                let apiData = formateData()
                console.log("apiData-----", apiData)
                if (apiData["_id"]) {
                    console.log(apiData)
                    if (!parentData.editFromConfirmationPage) {
                        apiData["triggerBackendAdminNotification"] = true
                    }
                    let resp = await context.apis[context.user.role].editCampaign(
                        context.validator, apiData
                    )
                    console.log("resp---", resp)
                    if (resp && resp.done) {
                        if (parentData.nonWisrCampaignData.brandDetail.isTrial) {
                            history.push('/brands/brand-details/create-trial-campaign/confirm')
                        }
                        else {
                            history.push('/brands/brand-details/create-campaign/confirm')
                        }
                        setIsLoading(false)
                    }
                }
                else {
                    let resp = await context.apis[context.user.role].addNonWisrCampaign(
                        context.validator, apiData
                    )
                    console.log("resp---", resp)
                    if (resp && resp.done) {
                        // let _id = resp.campaign._id
                        // parentData.setNonWisrCampaignData({
                        //     ...parentData.nonWisrCampaignData,
                        //     brandDetail: { ...parentData.nonWisrCampaignData.brandDetail, _id: _id }
                        // })
                        if (parentData.nonWisrCampaignData.brandDetail.isTrial) {
                            history.push('/backend/backend-details/nonWisr-campaign-list')
                        }
                        else {
                            history.push('/backend/backend-details/nonWisr-campaign-list')
                        }
                        setIsLoading(false)
                    }
                }
            }
            catch (err) {
                console.log("error", err)
                setIsLoading(false)
            }
        }
    }
    const onSave = () => {
        if (isLoading) {
            return
        }
        setIsLoading(true)
        testSpeed();
    }
    const formateData = () => {
        let inventoriesIdArray = []
        parentData.nonWisrCampaignData.inventory.inventories.forEach(inventory => {
            let attributesIdArray = []
            inventory.placements && Array.isArray(inventory.placements) && inventory.placements.length > 0 && inventory.placements.map(item => {
                attributesIdArray.push(item.name)
            })
            let obj = {
                _id: inventory._id,
                name: inventory.parentName || inventory.name,
                attributes: [...attributesIdArray],
                _custom: inventory._custom || false
            }
            inventoriesIdArray.push(obj)
        })
        let eventIdArray = []
        parentData.nonWisrCampaignData.eventAndOccation.eventAndOccations.forEach(event => {
            let attributesIdArray = []
            event.placements && Array.isArray(event.placements) && event.placements.map(item => {
                attributesIdArray.push(item._id || item.name)
            })
            let obj = {
                _id: event._id,
                name: event.name,
                attributes: [...attributesIdArray]
            }
            eventIdArray.push(obj)
        })
        // let imageArray = [parentData.nonWisrCampaignData.campaignCreative.creatives.image1.key, parentData.nonWisrCampaignData.campaignCreative.creatives.image2.key, parentData.nonWisrCampaignData.campaignCreative.creatives.image3.key]
        let imageArray = []
        if (parentData.nonWisrCampaignData.campaignCreative.creatives.image1.key) {
            imageArray.push(parentData.nonWisrCampaignData.campaignCreative.creatives.image1.key)
        }
        if (parentData.nonWisrCampaignData.campaignCreative.creatives.image2.key) {
            imageArray.push(parentData.nonWisrCampaignData.campaignCreative.creatives.image2.key)
        }
        if (parentData.nonWisrCampaignData.campaignCreative.creatives.image3.key) {
            imageArray.push(parentData.nonWisrCampaignData.campaignCreative.creatives.image3.key)
        }
        let schoolIdArray = parentData.nonWisrCampaignData.school.schools.map(school => ({_id: school._id, budget: 0}))
        let obj = {
            brandId: parentData.nonWisrCampaignData.brandDetail.brand._id,
            campaignId: parentData.nonWisrCampaignData.brandDetail.campaign._id,
            isNonWisrCampaign: true,
            name: parentData.nonWisrCampaignData.brandDetail.campaign.name.trim(),
            description: parentData.nonWisrCampaignData.brandDetail.description.trim(),
            objective: parentData.nonWisrCampaignData.brandDetail.objective.trim(),
            startDate: parentData.nonWisrCampaignData.brandDetail.startDate,
            endDate: parentData.nonWisrCampaignData.brandDetail.endDate,
            locations: [...parentData?.nonWisrCampaignData?.school?.locations],
            targetGender: parentData.nonWisrCampaignData.brandDetail.targetGender === "both" ? "co-ed" : parentData.nonWisrCampaignData.brandDetail.targetGender,
            targetAgeGroup: [parentData.nonWisrCampaignData.brandDetail.targetAgeGroup.min, parentData.nonWisrCampaignData.brandDetail.targetAgeGroup.max],
            studyMedium: [...parentData.nonWisrCampaignData.brandDetail.studyMedium],
            states: [...parentData.nonWisrCampaignData.brandDetail.state],
            cities: [...parentData.nonWisrCampaignData.brandDetail.city],
            brandCategory: parentData.nonWisrCampaignData.brandDetail.brandCategory._id,
            brandSubcategory: parentData.nonWisrCampaignData.brandDetail.brandSubcategory.value,
            brandName: parentData.nonWisrCampaignData.brandDetail.brand.name,
            // recommendEvents: false,
            campaignStatus: parentData.nonWisrCampaignData.brandDetail.isTrial ? "trial" : "in-review",
            isApproved: true,
            campaignCreatives: [...imageArray],
            budget: totalBudget,
            totalImpression: totalImpression,
            totalReach: totalReach,
            isTrial: parentData.nonWisrCampaignData.brandDetail.isTrial,
            inventories: [...inventoriesIdArray],
            schools: [...schoolIdArray],
            activities: [...eventIdArray],
            wisrOffering: {
                inventory: parentData.nonWisrCampaignData.brandDetail.inventory,
                eventAndOccations: parentData.nonWisrCampaignData.brandDetail.eventAndOccations,
                csr: parentData.nonWisrCampaignData.brandDetail.csr,
                sampling: parentData.nonWisrCampaignData.brandDetail.sampling,
                custom: parentData.nonWisrCampaignData.brandDetail.custom
            },
            campaignData: {
                inventories: [...inventoriesIdArray],
                schools: [...schoolIdArray],
                activities: [...eventIdArray],
                schoolListDetail: [...parentData.nonWisrCampaignData.school.schools]
            }
        }
        if (parentData.nonWisrCampaignData.brandDetail._id) {
            obj["_id"] = parentData.nonWisrCampaignData.brandDetail._id
        }
        return obj
    }
    const onAlertMessageClose = (confirm) => {
        if (confirm) {
            history.push({ pathname: parentData.nonWisrCampaignData.brandDetail.isTrial ? `/backend/backend-details/nonWisr-create-trial-campaign/${pageToRedirect}` : `/backend/backend-details/nonWisr-create-campaign/${pageToRedirect}`, search: `isSummary=${true}`, state: { from: "createCampaignFlow" } })
        }
        setShowAlertMessage(false)
        setOverlay(false)
        setAlertMessage("")
    }
    const openAlert = (page) => {
        if (page === "set-up-nonWisr-campaign") {
            setAlertMessage("The inventory list is recommended based on the campaign requirements. If you proceed to edit, an updated inventory list will appear.")
            setTitle("")
            setButtonText("")
        }
        else if (page === "nonWisr-campaign-inventory") {
            setAlertMessage("The schools are recommended based on the availability of inventory. If you proceed to edit the inventory, a revised school list will appear.")
            setTitle("Heads Up!")
            setButtonText("EDIT INVENTORIES")
        }
        else if (page === "nonWisr-campaign-event") {
            setAlertMessage("The events are selected on the basis of availability & school selected. By proceeding to edit the events  you will have to reselect your schools for this campaign.")
            setTitle("")
            setButtonText("")
        }
        setPageToRedirect(page)
        setShowAlertMessage(true)
        setOverlay(true)
    }
    return (
        <>
            <div className={`ovelap ${overlay === true && "active"}`}></div>
            {
                checkSpeed
                &&
                <ReactInternetSpeedMeter
                    txtSubHeading="Internet is too slow"
                    outputType="alert"
                    customClassName={"slowInternetMsg"}
                    txtMainHeading="Opps..."
                    pingInterval={pingIntervalTime} // milliseconds
                    thresholdUnit='megabyte' // "byte" , "kilobyte", "megabyte"
                    threshold={100}
                    imageUrl={context.utils.internetSpeedTestingImg}
                    downloadSize="2550420"  //bytes
                    callbackFunctionOnNetworkDown={(speed) => console.log(`Internet speed is down ${speed}`)}
                    callbackFunctionOnNetworkTest={(speed) => { console.log(`Internet Speed is ${speed} MBps`); setwifiSpeed(speed); setCheckSpeed(false) }}
                />
            }
            {/* <TopBar isDashboard={true} /> */}
            <div className="col-xl-9 col-lg-12 col-md-12 col-xs-12">
                <div className="rightPart noBack">
                    <div className="row">
                        <div className="col-xs-12 col-lg-12 col-md-12 col-xs-12">
                            <BackendBackBtn />
                        </div>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-20"><h2 className="htx5 clearfix mt-10 mb-20">Create a Campaign</h2></div>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-30">
                            <h2 className="htx2 clearfix mb-10">Campaign Preview</h2>
                        </div>
                        <div className="col-xl-8 col-lg-8 col-md-12 col-xs-12 mb-20 pl">
                            <div className="row">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                    <div className="personal-box">
                                        <div className="row personal-box-top">
                                            <div className="row col-xl-8 col-lg-8 col-md-8 col-xs-8 start-md">
                                                <h3 className="htx2 mb20">Campaign Details</h3>
                                            </div>
                                            <div className="row col-xl-4 col-lg-4 col-md-4 col-xs-4 end-xs">
                                                <a href="javascript:void(0)" className="editbtn" onClick={openEditModal}><img src={editSvg} /> Edit</a>
                                            </div>
                                        </div>
                                        <div className="personal-bottom">
                                            <div className="row personal-box-bot">
                                                <div className="col-xl-5 col-lg-5 col-md-6 col-xs-12">
                                                    <p>Name</p>
                                                </div>
                                                <div className="col-xl-7 col-lg-7 col-md-6 col-xs-12">
                                                    <b>{parentData?.nonWisrCampaignData?.brandDetail?.campaign?.name}</b>
                                                </div>
                                                <div className="col-xl-5 col-lg-5 col-md-6 col-xs-12">
                                                    <p>Description</p>
                                                </div>
                                                <div className="col-xl-7 col-lg-7 col-md-6 col-xs-12">
                                                    <b>{parentData?.nonWisrCampaignData?.brandDetail?.description}</b>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                    <div className="personal-box">
                                        <div className="row personal-box-top">
                                            <div className="row col-xl-8 col-lg-8 col-md-8 col-xs-8 start-md">
                                                <h3 className="htx2 mb20">Campaign Specifications</h3>
                                            </div>
                                            <div className="row col-xl-4 col-lg-4 col-md-4 col-xs-4 end-xs">
                                                <a href="javascript:void(0)" className="editbtn" onClick={() => openAlert("set-up-nonWisr-campaign")}><img src={editSvg} /> Edit</a>
                                            </div>
                                        </div>
                                        <div className="personal-bottom">
                                            <div className="row personal-box-bot">
                                                <div className="col-xl-5 col-lg-5 col-md-6 col-xs-12">
                                                    <p>Start Date</p>
                                                </div>
                                                <div className="col-xl-7 col-lg-7 col-md-6 col-xs-12">
                                                    <b>{parentData?.nonWisrCampaignData?.brandDetail?.startDate !== "" ? formatDateShowMonth(parentData?.nonWisrCampaignData?.brandDetail?.startDate, 2) : ""}</b>
                                                </div>
                                                <div className="col-xl-5 col-lg-5 col-md-6 col-xs-12">
                                                    <p>End Date</p>
                                                </div>
                                                <div className="col-xl-7 col-lg-7 col-md-6 col-xs-12">
                                                    <b>{parentData?.nonWisrCampaignData?.brandDetail?.endDate !== "" ? formatDateShowMonth(parentData?.nonWisrCampaignData?.brandDetail?.endDate, 2) : ""}</b>
                                                </div>
                                                <div className="col-xl-5 col-lg-5 col-md-6 col-xs-12">
                                                    <p>Location</p>
                                                </div>
                                                <div className="col-xl-7 col-lg-7 col-md-6 col-xs-12">
                                                    <b>
                                                        {location && location.length > 0 && location.map((item, index) => (
                                                            <React.Fragment key={index}>
                                                                {index === location.length - 1 ? `${titleCase(item)}` : `${titleCase(item)}, `}
                                                            </React.Fragment>
                                                        ))}
                                                    </b>
                                                </div>
                                                <div className="col-xl-5 col-lg-5 col-md-6 col-xs-12">
                                                    <p>Target Gender</p>
                                                </div>
                                                <div className="col-xl-7 col-lg-7 col-md-6 col-xs-12">
                                                    <b>{parentData?.nonWisrCampaignData?.brandDetail?.targetGender === "both" ? "Boys & Girls" : returnReadableStatus(parentData?.nonWisrCampaignData?.brandDetail?.targetGender)}</b>
                                                </div>

                                                <div className="col-xl-5 col-lg-5 col-md-6 col-xs-12">
                                                    <p>Target Age Group</p>
                                                </div>
                                                <div className="col-xl-7 col-lg-7 col-md-6 col-xs-12">
                                                    <b>{`${parentData?.nonWisrCampaignData?.brandDetail?.targetAgeGroup?.min} yo - ${parentData?.nonWisrCampaignData?.brandDetail?.targetAgeGroup?.max} yo`}</b>
                                                </div>
                                                <div className="col-xl-5 col-lg-5 col-md-6 col-xs-12">
                                                    <p>Medium of Study</p>
                                                </div>
                                                <div className="col-xl-7 col-lg-7 col-md-6 col-xs-12">
                                                    <span className="medium-break">
                                                        {parentData.nonWisrCampaignData.brandDetail.studyMedium.length !== 0 && parentData.nonWisrCampaignData.brandDetail.studyMedium.map((item, index) => (
                                                            <b style={{ "wordBreak": "initial" }} key={index}>{index < parentData.nonWisrCampaignData.brandDetail.studyMedium.length - 1 ? `${item}, ` : item}</b>
                                                        ))}
                                                    </span>
                                                </div>
                                                <div className="col-xl-5 col-lg-5 col-md-6 col-xs-12">
                                                    <p>Brand Category</p>
                                                </div>
                                                <div className="col-xl-7 col-lg-7 col-md-6 col-xs-12">
                                                    <b>{parentData?.nonWisrCampaignData?.brandDetail?.brandCategory.label && parentData?.nonWisrCampaignData?.brandDetail?.brandCategory.label}</b>
                                                </div>
                                                <div className="col-xl-5 col-lg-5 col-md-6 col-xs-12">
                                                    <p>No of Schools Occupied</p>
                                                </div>
                                                <div className="col-xl-7 col-lg-7 col-md-6 col-xs-12">
                                                    <b>{parentData?.nonWisrCampaignData?.school?.schools.length}</b>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                    <div className="personal-box">
                                        <div className="row personal-box-top">
                                            <div className="row col-xl-8 col-lg-8 col-md-8 col-xs-8 start-md">
                                                <h3 className="htx2 mb20">Inventory Details</h3>
                                            </div>
                                            <div className="row col-xl-4 col-lg-4 col-md-4 col-xs-4 end-xs">
                                                <a href="javascript:void(0)" className="editbtn" onClick={() => openAlert("nonWisr-campaign-inventory")}><img src={editSvg} /> Edit</a>
                                            </div>
                                        </div>
                                        <div className="personal-bottom">
                                            <div className="row personal-box-bot">
                                                <div className="col-xl-12 col-lg-12 col-md-6 col-xs-12">
                                                    <h4 className="htx3 mb-10">Inventory Added</h4>
                                                </div>
                                                <div className="col-xl-12 col-lg-12 col-md-6 col-xs-12">
                                                    <div className="schoolNameTable">
                                                        {parentData?.nonWisrCampaignData?.inventory.inventories.length > 0 && parentData?.nonWisrCampaignData?.inventory.inventories.map((inventory, index) => (
                                                            <span key={inventory._id}>{inventory.parentName || inventory.name}</span>
                                                        ))}
                                                    </div>
                                                </div>
                                                {/* {parentData?.nonWisrCampaignData?.inventory.notUsedInventories.length > 0 &&
                                                <>
                                                    <div className="col-xl-12 col-lg-12 col-md-6 col-xs-12">
                                                        <h4 className="htx3 mb-10 mt-20">Inventory Selected/Recommended But Not Added</h4>
                                                    </div>
                                                    <div className="col-xl-12 col-lg-12 col-md-6 col-xs-12">
                                                        <div className="schoolNameTable">
                                                            {parentData?.nonWisrCampaignData?.inventory.notUsedInventories.length > 0 && parentData?.nonWisrCampaignData?.inventory.notUsedInventories.map((inventory, index) => (
                                                                <span key={index}>{inventory}</span>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </>
                                            } */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {parentData.nonWisrCampaignData.brandDetail.eventAndOccations &&
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                        <div className="personal-box">
                                            <div className="row personal-box-top">
                                                <div className="row col-xl-8 col-lg-8 col-md-8 col-xs-8 start-md">
                                                    <h3 className="htx2 mb20">Event Details</h3>
                                                </div>
                                                <div className="row col-xl-4 col-lg-4 col-md-4 col-xs-4 end-xs">
                                                    <a href="javascript:void(0)" className="editbtn" onClick={() => openAlert("nonWisr-campaign-event")}><img src={editSvg} /> Edit</a>
                                                </div>
                                            </div>
                                            <div className="personal-bottom">
                                                <div className="row personal-box-bot">
                                                    <div className="col-xl-12 col-lg-12 col-md-6 col-xs-12">
                                                        <h4 className="htx3 mb-10">Events Selected</h4>
                                                    </div>
                                                    <div className="col-xl-12 col-lg-12 col-md-6 col-xs-12">
                                                        <div className="schoolNameTable">
                                                            {parentData?.nonWisrCampaignData?.eventAndOccation.eventAndOccations.length > 0 && parentData?.nonWisrCampaignData?.eventAndOccation.eventAndOccations.map((event, index) => (
                                                                <span key={event._id}>{event.name}</span>
                                                            ))}
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {/* {!parentData.nonWisrCampaignData.brandDetail.isTrial && */}
                                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                                    <div className="personal-box">
                                        <div className="row personal-box-top">
                                            <div className="row col-xl-8 col-lg-8 col-md-8 col-xs-8 start-md">
                                                <h3 className="htx2 mb20">Campaign Creatives</h3>
                                            </div>
                                            <div className="row col-xl-4 col-lg-4 col-md-4 col-xs-4 end-xs">
                                                <a href="javascript:void(0)" className="editbtn" onClick={() => history.push({ pathname: parentData.nonWisrCampaignData.brandDetail.isTrial ? '/backend/backend-details/nonWisr-create-trial-campaign/nonWisr-campaign-creative' : '/backend/backend-details/nonWisr-create-campaign/nonWisr-campaign-creative', search: `isSummary=${true}` })}><img src={editSvg} /> Edit</a>
                                            </div>
                                        </div>
                                        <div className="personal-bottom">
                                            <div className="row personal-box-bot">
                                                <div className="col-xl-12 col-lg-12 col-md-6 col-xs-12">
                                                    <h4 className="htx3 mb-10">Creatives Uploaded</h4>
                                                </div>
                                                <div className="col-xl-12 col-lg-12 col-md-6 col-xs-12">
                                                    <div className="schoolNameTable">
                                                        <div className="creativeImgView">
                                                            <div className="creativeImg">
                                                                <img src={parentData?.nonWisrCampaignData?.campaignCreative?.creatives?.image1?.image || imgInventory} />
                                                            </div>
                                                            <div className="creativeImg">
                                                                <img src={parentData?.nonWisrCampaignData?.campaignCreative?.creatives?.image2?.image || imgInventory} />
                                                            </div>
                                                            <div className="creativeImg">
                                                                <img src={parentData?.nonWisrCampaignData?.campaignCreative?.creatives?.image3?.image || imgInventory} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* } */}
                                <div className="row col-xl-8 col-lg-8 col-md-8 col-xs-12 middle-xs">
                                    <div className='forminpt'>
                                        {!parentData.nonWisrCampaignData.brandDetail.isTrial &&
                                            <div className='checkbox onboardcheck'>
                                                <input type="checkbox" id="html-term" checked={isTermAndConditionCheck} onClick={(e: any) => setIsTermAndConditionCheck(e.target.checked)} />
                                                <label style={{ display: "inline" }} htmlFor='html-term'></label> <b>I have read and agree to the{" "}</b>
                                                <a style={{ cursor: "pointer", color: "#fe6e00" }} onClick={() => setShowMou(true)}>Terms &amp; Conditions</a>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="row col-xl-4 col-lg-4 col-md-4 col-xs-12 end-md">
                                    <div className="forminpt ">
                                        {/* <input name="Complete Setting up" className={`btn btn-orange comp-seting ${!isTermAndConditionCheck && "btn-disabled"}`} type="button" value="Complete Setting up" onClick={onSave}/> */}
                                        <a href="javascript:void(0);" onClick={onSave} className={`btn btn-orange comp-seting ${!isTermAndConditionCheck && "btn-disabled"} ${isLoading && "btn btn-orange button--loading btn-disabled"}`} >
                                            SET UP CAMPAIGN
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-xl-4 col-lg-4 col-md-12 col-xs-12 mb-20">
                            <div className="w90">
                                <CampaignBudget
                                    totalBudget={totalBudget}
                                    setTotalBudget={setTotalBudget}
                                    setOverlay={setOverlay}
                                    disable={true}
                                />
                                <GenderSplit
                                    girlsRatio={girlsRatio}
                                    boysRatio={boysRatio}
                                />
                                <CampaignReach
                                    totalReach={totalReach}
                                    setTotalReach={setTotalReach}
                                    disable={true}
                                />
                                <CampaignImpression
                                    totalImpression={totalImpression}
                                    setTotalImpression={setTotalImpression}
                                    disable={true}
                                />
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
            {/* <BrandMou
                showMou={showMou}
                onToggle={onToggle}
            /> */}
            <div
                id='editCampaignDetail'
                className={`popup ${showEditBrandDetail === false ? "hide" : ""}`}
            >
                <div className='popup-body'>
                    <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                        <h2>Campaign Details <a style={{ cursor: "pointer" }} onClick={() => { setShowEditBrandDetail(false); setOverlay(false) }}>Cancel</a><a style={{ cursor: "pointer", marginRight: "15px" }} onClick={saveCampaignDetail}><svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M1 3.5191L3.37713 5.9L8.27462 1" stroke="#070707" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /> </svg> Save</a></h2>
                    </div>
                    <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-12 col-xs-12">
                            <label className='lableEdit'>Name</label>
                        </div>
                        <div className="col-xl-8 col-lg-8 col-md-12 col-xs-12">
                            <div className='forminpt'>
                                <TextField
                                    formData={campaignName}
                                    formKey='name'
                                    onDataChange={onCampaignNameChange}
                                    placeholder='Enter Campaign Name'
                                    isRequired={false}
                                    formValidator={formValidator}
                                    validation='required'
                                />
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-12 col-xs-12">
                            <label className='lableEdit'>Description</label>
                        </div>
                        <div className="col-xl-8 col-lg-8 col-md-12 col-xs-12">
                            <div className='forminpt'>
                                <TextArea
                                    formData={campaignDescription}
                                    formKey='description'
                                    onDataChange={onDescriprionChange}
                                    placeholder='Write a few sentences about the campaign'
                                    isRequired={false}
                                    formValidator={formValidator}
                                    validation='required'
                                    maxLength={500}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <AlertDialog
                onModalClose={onAlertMessageClose}
                title={title}
                togglePopup={showAlertMessage}
                displayMsg={alertMessage}
                showBtn={true}
            />
            <ConfirmDialog
                confirmTitle="Poor Internet Connection Detected"
                componentName="We've detected issues with your internet connection that could affect campaign creation & data loss. Please check your internet connection & try again."
                confirmText="Got it"
                denyText="Cancel"
                togglePopup={toggleNetworkDialog}
                onModalClose={networkConfirmClose}
                onConfirmBtn={true}
            />
        </>
    )
}

export default NonWisrCampaignPreview