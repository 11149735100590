import React, { useEffect, useState, useContext, useRef, forwardRef, useImperativeHandle } from 'react'
import { useHistory } from 'react-router-dom'
import AppContextObject from '../../common/context/common'
import MultiSelect from '../../components/formFields/multiSelect/multiSelect'
import config from '../../service/config'
import { findMonthsBetweenDate, objectDeepClone, validateCampaignName } from '../../service/helper'
import SimpleReactValidator from "simple-react-validator"
import errorSVG from "../../assets/images/error.svg"
import CustomDatePicker from '../../components/formFields/datePicker'
import moment from 'moment'
import TextField from '../../components/formFields/textField/textField'
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'
import ConfirmDialog from '../../components/addon/confirmDialog/confirmDialog'

interface Props {
    event: any,
    index: any
    customEventList: any
    setCustomEventList: any,
    setOverlay?: any,
    overlay?: any
}

const AddEventComponent = forwardRef(({ event, index, customEventList, setCustomEventList, setOverlay, overlay }: Props, ref) => {
    const [formValidator] = useState(useRef(new SimpleReactValidator({
        element: (message, className) =>
            <span className="valError"><img src={errorSVG} />{message}</span>
    })))
    const [venueList, setVenueList] = useState([])
    const [formErrors, setFormErrors] = useState<any>({})
    const [update, forceUpdate] = useState(false)
    const [tag, setTag] = useState("")
    const [showTagsError, setShowTagsError] = useState(false)
    const [venueErrorMsg, setVenueErrorMsg] = useState("")
    const [showRemoveEventPopup, setShowRemoveEventPopup] = useState(false)


    useEffect(() => {
        let arr = []
        config.schoolAmenities.forEach(el => {
            // const found = arr.find(e => e.label === el.name)
            // if (!found) {
            arr.push({
                label: el.name,
                value: el.name
            })
            // }
        })
        arr.push({
            label: "Other",
            value: "Other"
        })
        setVenueList(arr)
    }, [])
    const removeCustomEvent = (confirm) => {
        if(confirm){
            let customList = objectDeepClone(customEventList)
            customList.splice(index, 1)
            setCustomEventList([...customList])
        }
        setShowRemoveEventPopup(false)
        setOverlay(false)
    }
    const onCustumEventVenueChange = (data, index) => {
        let eventList = objectDeepClone(customEventList)
        let event = objectDeepClone(customEventList[index])
        event["venue"] = [...data.map(item => item.value)]
        if (!data.find(item => item.value.toLowerCase() === "other")) {
            event["otherVenueList"] = []
        }
        eventList.splice(index, 1, event)
        setCustomEventList([...eventList])
        console.log("multiselect", data, index)
    }
    const onCustomFormDataChange = (e, index) => {
        let eventList = objectDeepClone(customEventList)
        let event = objectDeepClone(customEventList[index])
        event[e.target.id] = e.target.value
        eventList.splice(index, 1, event)
        setCustomEventList([...eventList])
    }
    formValidator.current.rules['campaign_name'] = {
        message: 'Please enter valid name.',
        rule: (val) => {
            let returnValue = validateCampaignName(val)

            return returnValue
        },
    }

    const onTagValueChange = (value) => {
        if (value.length <= 50) {
            setTag(value)
        }
    }
    const onOtherVenueListChange = (venueData, index) => {
        // toggleNavigateFromTable()
        if (validateVenueName(venueData) === true) {
            console.log("......here 2", venueData)
            let eventList = objectDeepClone(customEventList)
            let event = objectDeepClone(customEventList[index])
            event["otherVenueList"] = [...venueData]
            eventList.splice(index, 1, event)
            setCustomEventList([...eventList])
            // onOtherVenueListUpdate(index, venueData)
        }
    }
    const validateVenueName = (venueData) => {
        const regex2 = /^(?:[A-Za-z]+)(?:[A-Za-z0-9 _]*)$/;

        let res = true;
        venueData.forEach(element => {
            if (!regex2.test(element)) {
                res = false;
            }
        })
        console.log("......here", res)
        if (res === true) {
            setShowTagsError(false)
            return true
        }
        else {
            setVenueErrorMsg("Please provide valid venue name.")
            setShowTagsError(true)
            return false
        }
    }
    useImperativeHandle(ref, () => ({
        validate: () => {
            const result = formValidator.current.allValid()
            let otherTagsResult = true
            if (event.venue.find(el => el === "Other")) {
                if (event.otherVenueList.length === 0) {
                    setVenueErrorMsg("Please provide venue name.")
                    setShowTagsError(true)
                    otherTagsResult = false
                }
                else {
                    setShowTagsError(false)
                    otherTagsResult = true
                }
            }
            if (result === false) {
                formValidator.current.showMessages()
                forceUpdate(!update)
            }
            else {
                formValidator.current.hideMessages()
                forceUpdate(!update)
            }
            console.log("see result", index, result, otherTagsResult)
            return (result && otherTagsResult)
        }
    }))
    const startDateMin = () => {
        // if(event.name === "Republic Day Celebration"){
        //     return `${moment().clone().format("YYYY")}-01-01`
        // }
        // if(event.name === "Independence Day Celebration"){
        //     return `${moment().clone().format("YYYY")}-08-01`
        // }
        // if(event.name === "Children's Day"){
        //     return `${moment().clone().format("YYYY")}-11-01`
        // }
        return moment().clone().format("YYYY-MM-DD")
    }
    const startDateMax = () => {
        if(event.endDate && event.endDate !== ""){
            return event.endDate
        }
        // if(event.name === "Republic Day Celebration"){
        //     return `${moment().clone().format("YYYY")}-01-31`
        // }
        // if(event.name === "Independence Day Celebration"){
        //     return `${moment().clone().format("YYYY")}-08-31`
        // }
        // if(event.name === "Children's Day"){
        //     return `${moment().clone().format("YYYY")}-11-30`
        // }
        return false
    }
    const EndDateMin = () => {
        if(event.startDate && event.startDate !== ""){
            return event.startDate
        }
        // if(event.name === "Republic Day Celebration"){
        //     return `${moment().clone().format("YYYY")}-01-01`
        // }
        // if(event.name === "Independence Day Celebration"){
        //     return `${moment().clone().format("YYYY")}-08-01`
        // }
        // if(event.name === "Children's Day"){
        //     return `${moment().clone().format("YYYY")}-11-01`
        // }
        return moment().clone().format("YYYY-MM-DD")
    }
    const EndDateMax = () => {
        // if(event.name === "Republic Day Celebration"){
        //     return `${moment().clone().format("YYYY")}-01-31`
        // }
        // if(event.name === "Independence Day Celebration"){
        //     return `${moment().clone().format("YYYY")}-08-31`
        // }
        // if(event.name === "Children's Day"){
        //     return `${moment().clone().format("YYYY")}-11-30`
        // }
        return false
    }
    return (
        <div key={index} className="col-xs-12 col-lg-12 col-md-12 col-xs-12">
            <div className="col-xs-12 col-lg-12 col-md-12 col-xs-12 mb20">
                <div className='row'>
                    <div className="col-xl-3 col-lg-3 col-md-3 col-xs-12 addEventMargin">
                        {event.isNew && event.custom ?
                            <TextField
                                formData={event}
                                formKey='name'
                                onDataChange={(e) => onCustomFormDataChange(e, index)}
                                placeholder='Enter Event Name'
                                isRequired={false}
                                formValidator={formValidator}
                                maxLength={50}
                                validation={(event.startDate !== "" || event.endDate !== "" || event.venue.length !== 0) ? "required|campaign_name" : ""}
                            />
                            :
                            <label style={{ fontSize: "16px", fontWeight: "bold" }}>{event.name}</label>
                        }
                    </div>
                    <div
                        // style={{zIndex: 5}}
                        className={`col-xl-3 col-lg-3 col-md-3 col-xs-12`}
                    >
                        {/* <div className="sele-check"> */}
                        <MultiSelect
                            formData={event}
                            formKey="venue"
                            onDataChange={(data) => onCustumEventVenueChange(data, index)}
                            placeholder="Select Venue"
                            isRequired={false}
                            options={venueList}
                            formErrors={formErrors}
                            formValidator={formValidator}
                            validation={event.custom ? (event.startDate !== "" || event.endDate !== "" || event.name !== "") ? "required|array|min:1" : "" : (event.startDate !== "" || event.endDate !== "") ? "required|array|min:1" : ""}
                        />
                        {/* </div> */}

                        {event.venue.find(item => item.toLowerCase() === "other") &&
                            <>
                                {/* <div className="col-xl-3 col-lg-3 col-md-3 col-xs-12"> */}
                                <div className='mt20'>
                                    <TagsInput
                                        value={event.otherVenueList}
                                        onChange={(data) => onOtherVenueListChange(data, index)}
                                        inputValue={tag}
                                        onChangeInput={onTagValueChange}
                                        inputProps={{
                                            className: "tags-input",
                                            placeholder: "Enter venue name"
                                        }}
                                        tagProps={{
                                            className: 'react-tagsinput-tag-custom '
                                        }}
                                        addOnBlur={true}
                                        addOnPaste={true}
                                    />
                                    {
                                        showTagsError === true &&
                                        <span className="valError"><img src={errorSVG} />{venueErrorMsg}</span>
                                    }
                                </div>
                                {/* </div>                                 */}
                            </>
                        }
                    </div>
                    <div className="col-xl-1 col-lg-1 col-md-1 col-xs-12">&nbsp;</div>
                    <div className="col-xl-2 col-lg-2 col-md-2 col-xs-12 addEventMargin">
                    <span className="showMob">Start date:</span>
                        <CustomDatePicker
                            formData={event}
                            formKey='startDate'
                            onDataChange={(e) => onCustomFormDataChange(e, index)}
                            isRequired={false}
                            formValidator={formValidator}
                            validation={event.custom ? (event.name !== "" || event.endDate !== "" || event.venue.length !== 0) ? "required" : "" : (event.endDate !== "" || event.venue.length !== 0) ? "required" : ""}
                            // min={moment().add(11, "days").clone().format("YYYY-MM-DD")}
                            // max={event.endDate && event.endDate}
                            min={startDateMin()}
                            max={startDateMax()}
                        />
                    </div>
                    <div className="col-xl-2 col-lg-2 col-md-2 col-xs-12 addEventMargin">
                        <span className="showMob">End date:</span>
                        <CustomDatePicker
                            formData={event}
                            formKey='endDate'
                            onDataChange={(e) => onCustomFormDataChange(e, index)}
                            isRequired={false}
                            formValidator={formValidator}
                            validation={event.custom ? (event.startDate !== "" || event.name !== "" || event.venue.length !== 0) ? "required" : "" : (event.startDate !== "" || event.venue.length !== 0) ? "required" : ""}
                            // min={(event.startDate && moment(event.startDate).clone().format("YYYY-MM-DD"))}
                            // max={formData.endDate && formData.endDate}
                            min={EndDateMin()}
                            max={EndDateMax()}
                        />
                    </div>
                    {event.isNew && event.custom &&
                        <div className="col-xl-1 col-lg-1 col-md-1 col-xs-12 mobileView">
                            <i className="fa fa-trash mt10" style={{cursor: "pointer"}} onClick={() => {setOverlay(true); setShowRemoveEventPopup(true)}}></i>
                        </div>
                    }
                </div>
            </div>
            {/* <div className="form-row  col-md-12" style={{ display: "none" }}>
                <div className="form-group col-md-3">
                    <label style={{ fontSize: "18px" }}></label>
                </div>
                <div className="form-group col-md-9">
                    <label className="col-md-3 checkbox-inline" htmlFor="checkboxes-0">
                        <input type="checkbox" name="checkboxes" id="checkboxes-0" checked value="Stage Front" />
                        Stage Front
                    </label>
                    <label className="col-md-3 checkbox-inline" htmlFor="checkboxes-1">
                        <input type="checkbox" name="checkboxes" id="checkboxes-1" checked value="Stage Backdrop" />
                        Stage Backdrop
                    </label>
                    <label className="col-md-3 checkbox-inline" htmlFor="checkboxes-2">
                        <input type="checkbox" name="checkboxes" id="checkboxes-2" checked value="Stage Wings" />
                        Stage Wings
                    </label>
                    <label className="col-md-2 checkbox-inline" htmlFor="checkboxes-3">
                        <input type="checkbox" name="checkboxes" id="checkboxes-3" checked value="Stalls" />
                        Stalls
                    </label>
                </div>
            </div> */}
            <ConfirmDialog
                confirmTitle="Do you want to remove this event ?"
                confirmText="Yes"
                denyText="No"
                onModalClose={removeCustomEvent}
                togglePopup={showRemoveEventPopup}
            />
        </div>
    )
})

export default AddEventComponent