import {
    useContext,
    useState,
    useRef,
    useEffect
} from "react"

import { useHistory, useLocation } from "react-router-dom"

import ConfirmAlert from "../../../../../components/addon/confirmAlert"
import AmenityDetails from "../../components/amenityDetails/amenityDetails"
import AvailableFacilities from "../../components/availableFacilities/availableFacilities"
import Loader from "../../../../../components/addon/loader"

import { objectDeepClone, compareObjs, formatInventories } from "../../../../../service/helper"
import DataContext from "../../../../../service/contextProvider"

import AppContextObject from "../../../../../common/context/common"

import toast from "react-hot-toast"
import ConfirmDialog from "../../../../../components/addon/confirmDialog/confirmDialog"
import errorSVG from "../../../../../assets/images/error.svg"

interface Props {
    isEdit?: boolean,
    onProceed?: Function,
    setShowMenu?: Function,
    hideRevenue?: boolean,
    byPassEventFlow?: any,
    setBypassEventFlow?: any,
    inDashboard?: boolean,
    fromBackend?: boolean,
    schoolId?: any
}

const InventoryDetails = ({
    isEdit,
    setShowMenu,
    onProceed,
    hideRevenue,
    byPassEventFlow,
    setBypassEventFlow,
    inDashboard,
    fromBackend,
    schoolId
}: Props) => {

    const context = useContext(AppContextObject)
    const history = useHistory()
    const location = useLocation()
    const parentData = useContext<any>(DataContext)
    const refFacilities = useRef()
    const refAmenityDetail = useRef()

    const [updateCompleteState, setUpdateCompleteState] = useState(
        (parentData?.selectedInventories && parentData?.selectedInventories.length > 0) ? true : false
    )

    const [showPrompt, setShowPrompt] = useState(false)
    const [overlay, setOverlay] = useState(false)
    // const [formData, setFormData] = useState(parentData?.data?.schoolAmenities)
    const [oldFormData] = useState(objectDeepClone(parentData?.selectedInventories))
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true)
    const [amenityList, setAmenityList] = useState(objectDeepClone(parentData?.selectedInventories))

    const [showModal, setShowModal] = useState(false)
    const [remainingAmenities, setRemainingAmenities] = useState([])
    const [showError, setShowError] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [inAddInventory, setInAddInventory] = useState(false)
    const [showQuitModal, setShowQuitModal] = useState(false)
    // const [byPassEventFlow, setBypassEventFlow] = useState(false)

    const [defaultAmenityObj] = useState({
        name: "",
        _checked: true,
        _custom: true,
        quantity: 1,
        data: [
          {
            name: "",
            images: [],
            customName: "",
            attr: {},
          },
        ]
    })

    const [loader, setLoader] = useState(false)
    const [masterList, setMasterList] = useState([])

    useEffect(() => {
        if(history.location.state){
            console.log(history.location.state)
            let data:any = history.location.state
            if(data.selectedInventory){
                setAmenityList([...data.selectedInventory])
                parentData.setSelectedInventories([...data.selectedInventory])
                setBypassEventFlow(true)
            }
        }
    }, [])
    useEffect(() => {
        if (parentData.setActiveModule) {
            parentData.setActiveModule({
                module: "inventoryDetails",
                subModule: "inventoryDetails"
            })
        }        
        setShowMenu(true)
        if (parentData.listOfInventories && parentData.listOfInventories.length > 0) {
            setLoader(true)
            ;let finalList = formatInventories(parentData.listOfInventories, "")
            finalList.forEach(((row, index) => {
                const found = parentData.listOfInventories.findIndex(f => f.name.toLowerCase() === row.name.toLowerCase())
                if (found >= 0) {
                    finalList[index] = {
                        ...finalList[index],
                        ...objectDeepClone(parentData.listOfInventories[found])
                    }
                }
            }))            
            setMasterList(finalList)
            process.nextTick(() => {
                setLoader(false)
            })
        }
    }, [])

    useEffect(() => {
        let arr = []
        masterList.forEach((el) => {
            const found = amenityList.find((e) => e.name.toLowerCase().trim() === el.name.toLowerCase().trim())
            if (!found) {
                arr.push(el)
            }
        })
        setRemainingAmenities(arr)
    }, [amenityList])

    useEffect(() => {
        // setFormData(parentData.data.schoolAmenities);
        setAmenityList([...parentData?.selectedInventories])
        setUpdateCompleteState(true)
    }, [parentData?.selectedInventories])

    const onAmenitiesChange = (newData, oldData) => {
        const obj1 = [...JSON.parse(newData)]
        const obj2 = [...JSON.parse(oldData)]
        const isSame = compareObjs(obj1, obj2)
        if (isSame === false) {
            setShowPrompt(true)
        } else {
            setShowPrompt(false)
        }
    }

    const onSubmit = async (proceed) => {
        setInAddInventory(true)
        let data = {...parentData?.data?.schoolAmenities}
        let facilities = []
        let validatorObj = {}
        if (refAmenityDetail && refAmenityDetail.current) {
            const ref: any = refAmenityDetail.current
            facilities = ref.fetchAllAmenities()
            validatorObj = await ref.fetchValidation().catch(err => console.log(err))
            setAmenityList([...facilities])
        }
        if (Object.keys(validatorObj).length > 0) {
            setShowError(true)
            setErrorMessage("Please fill all fields.")
            return
        }

        if (parentData) {
            if (parentData.setProgress) {
                parentData.setProgress({
                    ...parentData.progress,
                    inventoryDetails: true
                })
            }
            if (parentData.setSelectedInventories) {
                parentData.setSelectedInventories(objectDeepClone(facilities))
            }
        }
        setShowPrompt(false)
        if (proceed == false) {
            if (facilities.length == 0) {
                setErrorMessage("Please select atleast one inventory")
                return
            } else {
                facilities.forEach(facility => {
                    facility.data.forEach(d => {
                        d["type"] = facility.type
                    })
                })
                console.log("objectDeepClone(facilities)", objectDeepClone(facilities));
                let response = await context.apis.public.upsertSchoolInventoriesDetailsBySchoolId(
                    context.validator, {
                    "school": context.user.school,
                    "user": context.user._id,
                    "amenities": objectDeepClone(facilities),
                    "removeInventoriesArray": []
                    }, {}
                )
                console.log("response>>",response);
                if (response) {
                    if (response.done === true) {
                        toast.success(response.msg)
                        process.nextTick(() => {
                            // history.push("/schools/school-details/inventory")
                        })
                    }
                    else toast.error(response.msg)
                } else {
                    toast.error("Something went wrong")
                }
            }
        } else {
            const isSummary = new URLSearchParams(location.search).get("isSummary")
            process.nextTick(() => {
                if (isEdit === true) {
                    if (isSummary === "true") {
                        history.push("/schools/school-details/details/update-details-summary")
                    } else {
                        history.push("/schools/school-details/details/activities/student-life")
                    }
                } else {
                    parentData.setNewlyAddedInventories(facilities)
                    if (isSummary === "true") {
                        if(fromBackend){
                            history.push("/backend/backend-details/add-invetory/school-details-summary")
                        } else{
                            history.push("/schools/school-details/add-invetory/school-details-summary")
                        }
                    } else {
                        if(fromBackend){
                            history.push("/backend/backend-details/add-invetory/school-details-summary")
                        } else{
                            if(byPassEventFlow){
                                history.push("/schools/school-details/add-invetory/school-details-summary")
                            }
                            else{
                                history.push("/schools/school-details/add-invetory/events-and-occasions")
                            }
                        }
                    }
                }
            })
        }
    }

    const openModal = () => {
        setShowModal(true)
        setOverlay(true)
    }

    const confirmAlertCallback = (data) => {
        setOverlay(data.overlay)
    }

    const addNewSelectedAmenities = () => {
        if (refFacilities && refFacilities.current) {
            const ref: any = refFacilities.current
            const facilities = ref.fetchFacilities()

            const ref1: any = refAmenityDetail.current
            const existingFacilities = ref1.fetchAllAmenities()

            const arr = [...existingFacilities, ...facilities]
            arr.forEach((e) => {
                e.data.forEach((d, index) => {
                    d.name = e.name + " " + (index + 1)
                })
            })
            setAmenityList(arr)
            setOverlay(false)
            setShowModal(false)
            if (parentData) {
                // if (parentData.setProgress) {
                //     parentData.setProgress({
                //         ...parentData.progress,
                //         classroom: true
                //     })
                // }
                if (parentData.setData) {
                    parentData.setData({
                        ...parentData.data,
                        schoolAmenities: {
                            ...parentData.data.schoolAmenities,
                            classroomAmenities: [...arr]
                        }
                    })
                }
            }
            // arr.forEach((e) => {
            //     e.data.forEach((d, index) => {
            //         d.name = e.name + " " + (index + 1)
            //     })
            // })
            // ref1.resetAmenityDetail()
        }
    }

    const closeWithoutSaving = () => {
        setShowModal(false)
        setOverlay(false)
    }

    const onCustomAmenityAdd = (amenityName, isEdit, index) => {
        if (isEdit === true) {
            const arr = objectDeepClone(amenityList)
            arr[index].name = amenityName
            setAmenityList(arr)
        } else {
            const json = objectDeepClone(defaultAmenityObj)
            json.name = amenityName
            json.data[0].name = amenityName + " 1"
            const arr = [...amenityList, json]
            setAmenityList(arr)
        }
    }

    const onCustomAmenityDelete = (index) => {
        const arr = objectDeepClone(amenityList)
        arr.splice(index, 1)
        setAmenityList(arr)
    }

    const updateParentErrorMsg = (msg, showErr) => {
        setErrorMessage(msg)
        setShowError(showErr)
    }

    const openQuitModal = () => {
        setOverlay(true)
        setShowQuitModal(true)
    }
    const onQuitModalClose = (confirm) => {
        if(confirm){
            if(fromBackend){
                history.push({pathname: "/backend/backend-details/school-details", state: {schoolId: schoolId }})
            }
            else{
                history.push("/schools/school-details/inventory")
            }
        }
        setShowQuitModal(false)
        setOverlay(false)
    }

    return (
        <>
            <div className={`ovelap ${overlay === true ? "active" : ""}`}></div>
            {
                loader === true ?
                <Loader />
                :
                <div className={isEdit === false ? "col-xl-6 col-lg-6 col-md-12 col-xs-12" : ""}>
                    <div className={isEdit === false ? "content mCustomScrollbar fixhight" : ""}>
                        <div className={isEdit === false ? "rightPart" : ""}>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-20"><a style={{cursor: "pointer"}} className="fR blackC" onClick={openQuitModal}>Quit</a></div>
                            {
                                isEdit === false &&
                                <>
                                    <h2 className="htx1 mb40">Inventories Details</h2>
                                </>
                            }
                            <div className="row">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    {/* {
                                        <div className="inf-stru">
                                            <h3 className="htx2">Classroom specific
                                            <a
                                                href='javascript:void(0)'
                                                onClick={openModal}
                                                className='add-inve toggle'
                                                data-target='amenitiesModal'
                                            >
                                                + Add inventory
                                            </a>
                                            </h3>
                                        </div>
                                    } */}
                                    <AmenityDetails
                                        amenityList={amenityList}
                                        ref={refAmenityDetail}
                                        setIsSubmitDisabled={setIsSubmitDisabled}
                                        onCustomAmenityAdd={onCustomAmenityAdd}
                                        onCustomAmenityDelete={onCustomAmenityDelete}
                                        updateCompleteState={updateCompleteState}
                                        isEdit={isEdit}
                                        hideRevenue={hideRevenue}
                                        configData={masterList}
                                        openRemainingInventories={openModal}
                                        inAddInventory={inAddInventory}
                                        onAmenitiesChange={onAmenitiesChange}
                                        updateParentErrorMsg={updateParentErrorMsg}
                                        inDashboard={fromBackend ? false : inDashboard}
                                    />
                                </div>
                                <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-20 end-xs'>
                                    <div className="bottom-error-btn">
                                        <p className="valError">
                                            {
                                                showError === true &&
                                                <>
                                                    <img src={errorSVG} />
                                                    {errorMessage}
                                                </>
                                            }
                                        </p>
                                        <div className='forminpt'>
                                            {/* <input
                                                onClick={() => onSubmit(false)}
                                                name='Save'
                                                value='Save'
                                                className='btn sc-ameties'
                                                type='button'
                                            /> */}
                                            <input
                                                onClick={() => onSubmit(true)}
                                                name='Save and Continue'
                                                value='Save and Continue'
                                                className='btn btn-orange sc-sch-spe'
                                                type='button'
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <div
                id='amenitiesModal'
                className={`popup ${showModal === false ? "hide" : ""}`}
            >
                <div className='popup-header'>
                    <h3 className='htx2'>Add New Inventory</h3>
                    <span
                        onClick={closeWithoutSaving}
                        className='close toggle'
                        data-target='amenitiesModal'
                        >
                    close
                    </span>
                </div>
                <div className='popup-body'>
                    <div className='facili-avai-in clearfix'>
                        <div className='row'>
                            {/* <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                                <h3 className='htx2'>Classroom Inventory</h3>
                            </div> */}
                            {
                                remainingAmenities.length > 0
                                &&
                                <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                                    <p className="ptx">Remaining inventory</p>
                                </div>
                            }
                            <AvailableFacilities
                                ref={refFacilities}
                                hideSelectAll={true}
                                showModal={showModal}
                                availableFacilities={remainingAmenities}
                                selectedInventories={amenityList}
                                isModal={true}
                                inDashboard={inDashboard}
                            />
                            <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-20 end-xs'>
                                <div className='forminpt mb-0'>
                                    <input
                                        onClick={addNewSelectedAmenities}
                                        name='Finish'
                                        value='Finish'
                                        className='btn btn-orange'
                                        type='button'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {!(fromBackend === true) &&
                <ConfirmAlert
                    when={showPrompt}
                    title="Leave page without saving..."
                    cancelText="Cancel"
                    okText="Confirm"
                    onOK={() => true}
                    onCancel={() => false}
                    parentCallback={confirmAlertCallback}
                />
            }
            <ConfirmDialog
                confirmTitle="Do you want to Quit"
                togglePopup={showQuitModal}
                onModalClose={onQuitModalClose}
                componentName={""}
            />
        </>
    )
}

export default InventoryDetails
