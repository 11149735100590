import React from "react";
import { useHistory } from "react-router";
interface Props {
  isEdit ? : boolean
}
const SchoolActivities = ({ editImage, activities, isEdit, addSchoolFromBackend }) => {
  const history = useHistory();

  const goToActivitiesDetails = () => {
    if(addSchoolFromBackend){
      history.push({
        pathname: "/backend/backend-details/schools/onboarding/events-and-occasions",
        search: `isSummary=${true}`,
      });
    }else if(isEdit === true){
      history.push({
        pathname: "/schools/school-details/details/activities/student-life",
        search: `isSummary=${true}`,
      });
    }else{
      history.push({
        pathname: "/schools/onboarding/events-and-occasions",
        search: `isSummary=${true}`,
      });
    }    
  };
  const renderVenueList = (el) => {
    let venue = el.venue
    let otherVenue = el.otherVenueList
    venue = venue.filter(e => e !== "Other")
    const clubbedArr = [...venue, ...otherVenue]
    return clubbedArr.length > 0 ? clubbedArr.join(", ") : "-"
  }
  return (
    <div className='col-xl-12 col-lg-12 col-md-12 col-xs-12'>
      <div className='personal-box'>
        <div className='row personal-box-top'>
          <div className='row col-xl-8 col-lg-8 col-md-8 col-xs-8 start-md'>
            <h3 className='htx2 mb20'>Events & Occasions</h3>
          </div>
          <div className='row col-xl-4 col-lg-4 col-md-4 col-xs-4 end-xs'>
            <a
              href='javascript:void(0)'
              className='editbtn'
              onClick={goToActivitiesDetails}
            >
              <img src={editImage} /> Edit
            </a>
          </div>
        </div>
        <div className='personal-bottom'>
          <div className='row personal-box-bot'>
            <div className='col-xl-6 col-lg-6 col-md-6 col-xs-12'>
              <p className="text-uppercase">Selected Events</p>
            </div>
            <div className='col-xl-6 col-lg-6 col-md-6 col-xs-12'>
              <p className="text-uppercase">Venue</p>
            </div>
            {(activities && activities.length > 0) ?
            activities.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <div className='col-xl-6 col-lg-6 col-md-6 col-xs-12'>
                    <b>{item.name}</b>
                  </div>
                  <div className='col-xl-6 col-lg-6 col-md-6 col-xs-12'>
                    <b>
                      {renderVenueList(item)}
                    </b>
                  </div>
                </React.Fragment>
              )
            })
          :
          <React.Fragment>
            <div className='col-xl-6 col-lg-6 col-md-6 col-xs-12'>
              <b>-</b>
            </div>
            <div className='col-xl-6 col-lg-6 col-md-6 col-xs-12'>
              <b>-</b>
            </div>
          </React.Fragment>
          }
          </div>
        </div>
      </div>
    </div>
  );
};

export default SchoolActivities;
