import React, {
	useState,
	useEffect,
	useContext,
	createRef,
	useRef,
	useMemo,
	useCallback,
} from "react";
import { useHistory, useLocation } from "react-router-dom";
import MultiPointMap from "../../../../components/addon/multiPointMap";
import scriptLoader from "react-async-script-loader";
import uploadSvg from "../../../../assets/images/upload-svg.svg";
import sucessCheck from "../../../../assets/images/sucess-check.svg";
import closeSvg from "../../../../assets/images/close-svg.svg";
import blockSvg from "../../../../assets/images/block-svg.svg";
import reloadSvg from "../../../../assets/images/reload-svg.svg";
import filterSvg from "../../../../assets/images/filter-svg.svg";
import filterArrowSvg from "../../../../assets/images/filter-arrow-svg.svg";
import mapsImg from "../../../../assets/images/maps.jpg";
import AppContextObject from "../../../../common/context/common";
import DataContext from "../../../../service/brandContext";
import Pagination from "../../../../components/addon/pagination";
import CampaignBudget from "../components/campaignBudget";
import GenderSplit from "../components/genderSplit";
import CampaignReach from "../components/campaignReach";
import CampaignImpression from "../components/campaignImpression";
import {
	compareObjs,
	objectDeepClone,
	returnReadableStatus,
	titleCase,
} from "../../../../service/helper";
import config from "../../../../service/config";
import AlertDialog from "../../../../components/addon/alertDialog/alertDialog";
import ConfirmDialog from "../../../../components/addon/confirmDialog/confirmDialog";
import errorSVG from "../../../../assets/images/error.svg";
import searchSvg from "../../../../assets/images/search.svg";
import editSvg from "../../../../assets/images/edit-btn.svg";
import { DebounceInput } from "react-debounce-input";
import CustomSearchInput from "../../../../components/formFields/customSearchInput";
import useClickOutside from "../../../../service/useClickOutside";
import BrandBackBtn from "../components/brandBackBtn";
import moment from "moment";
import { WisrOptionService } from "../../../../service/wisrOptions";
import SchoolTable from "../components/schoolTable";
import school from "../school";
import toast from "react-hot-toast";
var algoSchoolListGlobalVar = 1;

const CampaignRecomendedSchool = ({
	setShowProgressSideMenu,
	isScriptLoaded,
	isScriptLoadSucceed,
}) => {
	const [WisrOption, setWisrOption] = useState<any>();
	const filterRef = useRef(null);
	const filterOptionsRef = useRef(null);
	const location = useLocation();
	const history = useHistory();
	const context = useContext(AppContextObject);
	const parentData = useContext<any>(DataContext);
	const [loadingFirstTime, setLoadingFirstTime] = useState(true);
	//location data
	const [stateList, setStateList] = useState([]);
	console.log("stateListtttt", stateList);
	const [selectedState, setSelectedState] = useState([
		...parentData.data.brandDetail.state,
	]);
	const [cityList, setCityList] = useState([
		...parentData.data.brandDetail.city.sort((a, b) =>
			a.toLowerCase() > b.toLowerCase() ? 1 : -1
		),
	]);

	const [cityStateData, setCityStateData] = useState({});
	//search related data
	const [searchInpuOpen, setSearchInpuOpen] = useState(false);
	const [clearSearchIcon, setClearSearchIcon] = useState(false);
	const [searchValue, setSearchValue] = useState("");

	console.log("searchValue ----", searchValue);

	const [tableEditMode, setTableEditMode] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPage, setTotalPage] = useState([0]);
	const [totalBudget, setTotalBudget] = useState(
		parentData.data.campaignDetail.totalBudget
	);
	const [totalReach, setTotalReach] = useState(
		parentData.data.campaignDetail.totalReach
	);
	const [boysRatio, setBoysRatio] = useState(0);
	const [girlsRatio, setGirlsRatio] = useState(0);
	const [boysCount, setBoysCount] = useState(0);
	const [girlsCount, setGirlsCount] = useState(0);
	const [totalImpression, setTotalImpression] = useState(
		parentData.data.campaignDetail.totalImpression
	);
	const [pincodeList, setPincodeList] = useState([]);
	const [saveAndContinue, setSaveAndContinue] = useState(false);
	const [formData, setFormData] = useState({
		schools: [...parentData.data.school.schools],
		locations: [...parentData.data.school.locations],
	});
	const [schoolList, setSchoolList] = useState([]);
	const [schoolList2, setSchoolList2] = useState([]);
	const [paginationSchool, setPaginationSchool] = useState([]);
	const [initialSchools, setInitialSchools] = useState([]);
	const [overlay, setOverlay] = useState(false);
	const [filterByCity, setFilterByCity] = useState([
		...parentData.data.brandDetail.city,
	]);
	// const [filterByLanguage, setFilterByLanguage] = useState([]);
	const [filterByLanguage, setFilterByLanguage] = useState(
		parentData.data.brandDetail.studyMedium
	);

	let dd = new Set(
		parentData.data.algoData.schoolData.map((data) => data.board.board)
	);
	console.log("boardddddd", Array.from(dd));
	console.log(
		"boardddddd ib",
		parentData.data.algoData.schoolData.filter((data) => {
			return data.board.board == "IB";
		})
	);
	const [filterByBoard, setFilterByBoard] = useState(Array.from(dd));
	const [filterByBoardOptions, setFilterByBoardOptions] = useState(
		Array.from(dd)
	);

	// const [filterByBoard, setFilterByBoard] = useState([
	// 	"state",
	// 	"cbse",
	// 	"igsce",
	// 	"icse",
	// ]);
	// const [filterBySchoolType, setFilterBySchoolType] = useState([]);
	const [filterBySchoolType, setFilterBySchoolType] = useState([
		"boys",
		"girls",
		"co-ed",
		"Custom",
	]);
	const [showFilterModal, setShowFilterModal] = useState(false);
	const [showCityFilter, setShowCityFilter] = useState(false);
	const [showLanguageFilter, setShowLanguageFilter] = useState(false);
	const [showBoardFilter, setShowBoardFilter] = useState(false);
	const [showSchoolTypeFilter, setShowSchoolTypeFilter] = useState(false);
	const [showStateFilter, setShowStateFilter] = useState(false);
	const [dataPerPage] = useState(25);
	const [pageLoading, setPageLoading] = useState(true);
	const [showLimitExceeded, setShowLimitExceeded] = useState(false);
	const [showExitPopup, setShowExitPopup] = useState(false);
	const [showErrorMessage, setShowErrorMessage] = useState(false);
	const [budgetExceeded, setBudgetExceeded] = useState(false);
	const [totalSchoolCount, setTotalSchoolCount] = useState(0);
	const [schoolCount, setSchoolCount] = useState(0);
	const [confirmDialogBtnLoading, setConfirmDialogBtnLoading] = useState(false);
	const [allSchoolList, setAllSchoolList] = useState([]);
	const [minReach, setMinReach] = useState(0);
	const [maxReach, setMaxReach] = useState(0);
	const [minImpression, setMinImpression] = useState(0);
	const [maxImpression, setMaxImpression] = useState(0);
	const [increasedBudget, setIncreasedBudget] = useState(0);
	const [algoDataset, setAlgoDataset] = useState({
		classroomData: [...parentData.data.algoData.classroomData],
		inventoryData: [...parentData.data.algoData.inventoryData],
		eventData: [...parentData.data.algoData.eventData],
		schoolData: [...parentData.data.algoData.schoolData],
		noOfDaysInYear: parentData.data.algoData.noOfDaysInYear,
		percentageDiscountInWISR: parentData.data.algoData.percentageDiscountInWISR,
		percentageIncreaseInReach:
			parentData.data.algoData.percentageIncreaseInReach,
		totalInternalCostPerSchool:
			parentData.data.algoData.totalInternalCostPerSchool,
		budgetRatio: { ...parentData.data.algoData.budgetRatio },
	});
	useClickOutside(filterOptionsRef, (event) => {
		const el = filterRef?.current;

		if (!el || el.contains(event.target)) {
			return;
		}
		setShowFilterModal(false);
	});
	useEffect(() => {
		const isSummary = new URLSearchParams(location.search).get("isSummary");
		if (isSummary === "true") {
			parentData.setProgress({
				brandDetails: true,
				inventory: true,
				event: true,
				school: true,
				recommendedSchool: false,
				campaignCreative: false,
				preview: false,
			});
		}
	}, []);
	useEffect(() => {
		if (cityStateData && Object.keys(cityStateData).length > 0) {
			fetchCityByState();
		}
	}, [selectedState]);
	useEffect(() => {
		if (
			cityStateData &&
			Object.keys(cityStateData).length > 0 &&
			selectedState &&
			selectedState.length
		) {
			let newState = [];
			selectedState.forEach((item) => {
				if (cityStateData[item] && cityStateData[item].length > 0) {
					let cities = [...cityStateData[item]];
					let found = 0;
					cities.forEach((data) => {
						if (filterByCity.includes(data)) {
							found++;
						}
					});
					if (found) {
						newState.push(item);
					}
				}
			});
			setSelectedState([...newState]);
		}
	}, [filterByCity]);

	useEffect(() => {
		let arr =
			parentData.data.brandDetail.targetGender === "boys"
				? ["boys", "co-ed", "Custom"]
				: parentData.data.brandDetail.targetGender === "girls"
				? ["girls", "co-ed", "Custom"]
				: ["boys", "girls", "co-ed", "Custom"];

		setFilterBySchoolType(arr);
	}, [parentData.data.brandDetail.targetGender]);

	useEffect(() => {
		console.log("filterBySchoolType = ", filterBySchoolType);
	}, [filterBySchoolType]);

	const fetchCityByState = async () => {
		let city = [];
		selectedState.forEach((state) => {
			if (cityStateData[state] && cityStateData[state].length > 0) {
				cityStateData[state].forEach((item) => {
					if (!city.find((data) => data.toLowerCase() === item.toLowerCase())) {
						if (item != "raigarh(mh)") city.push(item);
					}
				});
			}
		});
		city = city.sort((a, b) => {
			return a.toLowerCase() > b.toLowerCase() ? 1 : -1;
		});
		setCityList([...city]);
	};

	useEffect(() => {
		setShowProgressSideMenu(true);
		if (parentData.setActiveModule) {
			parentData.setActiveModule({
				...parentData.activeModule,
				module: "campaignPlacement",
				subModule: "schools",
			});
		}
	}, []);
	useEffect(() => {
		getAllSchools();
	}, []);

	const onNextClick = (page) => {
		setCurrentPage(page);
		let schools = schoolList2.slice(
			(page - 1) * dataPerPage,
			page * dataPerPage
		);
		let city = [];
		schools.forEach((item) => {
			city.push(item.city);
		});
		setPaginationSchool([...schools]);
	};
	const onPrevClick = (page) => {
		setCurrentPage(page);
		let schools = schoolList2.slice(
			(page - 1) * dataPerPage,
			page * dataPerPage
		);
		let city = [];
		schools.forEach((item) => {
			city.push(item.city);
		});
		setPaginationSchool([...schools]);
	};
	const onPageChange = (page) => {
		setCurrentPage(page);
		let schools = schoolList2.slice(
			(page - 1) * dataPerPage,
			page * dataPerPage
		);
		let city = [];
		schools.forEach((item) => {
			city.push(item.city);
		});
		setPaginationSchool([...schools]);
	};

	const paginationSchoolData = (schoolsList, sortedList) => {
		let schoolData = [];
		for (let i = 0; i < dataPerPage && i < sortedList.length; i++) {
			schoolData.push(sortedList[i]);
		}
		let page = Math.ceil(schoolsList.length / dataPerPage);
		let arr = [];
		for (let i = 0; i < page; i++) {
			arr.push(i);
		}
		setTotalPage([...arr]);
		setPaginationSchool([...schoolData]);
	};

	useEffect(() => {
		if (!loadingFirstTime) {
			// getFilteredSchoolList();
			getFilteredSchoolListNew();
		}
		if (currentPage !== 1) {
			setCurrentPage(1);
		}
	}, [
		filterByBoard,
		filterByCity,
		filterByLanguage,
		filterBySchoolType,
		searchValue,
	]);

	console.log("filterByBoardddd", filterByBoard);

	const shoolSelected = (_id) => {
		if (formData.schools && formData.schools.length > 0) {
			let found = formData.schools.find((item) => item._id === _id);
			if (found) {
				return true;
			} else return false;
		}
		return false;
	};

	const getFilteredSchoolListNew = () => {
		let school = schoolList.filter((data) => {
			if (selectedState.includes(data.state.toLowerCase())) {
				if (
					!filterByCity.length ||
					filterByCity.includes(data.parentCity.toLowerCase())
				) {
					if (
						!searchValue.length ||
						data.schoolName.toLowerCase().includes(searchValue.toLowerCase())
					) {
						if (
							!filterByLanguage.length ||
							filterByLanguage.includes(data.language.language)
						) {
							if (
								!filterBySchoolType.length ||
								filterBySchoolType.includes(data.schoolType.schoolType)
							) {
								if (
									!filterByBoard.length ||
									filterByBoard.includes(data.board.board)
								) {
									return true;
								}

								// return true;
							}
							// return true;
						}

						// return true;
					}
				}
			}
			//

			return false;
		});

		let sortedSchool = school.sort((a) => {
			let found = formData.schools.find((item) => item._id === a._id);
			return found ? -1 : 1;
		});

		let sortedSchoolsByCategory = sortAllSchoolsByCategory(sortedSchool);

		const selectedSchools = [];
		const unselectedSchools = [];

		// Iterate through schoolList and classify schools
		sortedSchoolsByCategory.forEach((school) => {
			if (shoolSelected(school._id)) {
				selectedSchools.push(school);
			} else {
				unselectedSchools.push(school);
			}
		});

		// Combine selected and unselected schools to get the final sorted list
		const finalSortedSchoolList = [...selectedSchools, ...unselectedSchools];
		setSchoolList2([...finalSortedSchoolList]);
		paginationSchoolData(school, finalSortedSchoolList);
		// let schoolData = [];
		// for (let i = 0; i < dataPerPage && i < finalSortedSchoolList.length; i++) {
		// 	schoolData.push(finalSortedSchoolList[i]);
		// }
		// let page = Math.ceil(school.length / dataPerPage);
		// let arr = [];e
		// for (let i = 0; i < page; i++) {
		// 	arr.push(i);
		// }
		// setTotalPage([...arr]);
		// setPaginationSchool([...schoolData]);

		if (loadingFirstTime) {
			setTotalSchoolCount(school.length);
		}
	};

	const getFilteredSchoolList = async () => {
		let startDate = moment(new Date(parentData.data.brandDetail.startDate));
		let endDate = moment(new Date(parentData.data.brandDetail.endDate));
		let duration = moment.duration(endDate.diff(startDate));
		let days = duration.asDays();
		let dataset = {
			startDate: parentData.data.brandDetail.startDate,
			endDate: parentData.data.brandDetail.endDate,
			_id: null,
			brandCategoryId: parentData.data.brandDetail.brandCategory._id,
			searchValue: searchValue,
			budget: parentData.data.campaignDetail.totalBudget,
			ageGroup: [
				parentData.data.brandDetail.targetAgeGroup.min,
				parentData.data.brandDetail.targetAgeGroup.max,
			],
			locations: filterByCity,
			states: selectedState,
			inventories: !parentData?.data?.brandDetail?.inventory
				? []
				: [...parentData.data.inventory.inventories],
			events: !parentData?.data?.brandDetail?.eventAndOccations
				? []
				: [...parentData.data.eventAndOccation.eventAndOccations],
			gender:
				filterBySchoolType.length > 0
					? filterBySchoolType
					: parentData.data.brandDetail.targetGender === "both"
					? ["boys", "girls", "co-ed"]
					: parentData.data.brandDetail.targetGender === "girls"
					? ["girls", "co-ed"]
					: ["boys", "co-ed"],
			language:
				filterByLanguage.length > 0
					? filterByLanguage
					: parentData.data.brandDetail.studyMedium,
			board: filterByBoard,
			campaignDuration: days,
		};
		setPageLoading(true);
		var localVar = Math.random();
		algoSchoolListGlobalVar = localVar;
		console.log("algoSchoolListGlobalVar", algoSchoolListGlobalVar);
		let state: any = history.location.state;
		let resp;
		if (state && state.showNewData) {
			resp = await context.apis[
				context.user.role
			].getRecommendedDataByCampaignDataV2(context.validator, {
				data: dataset,
			});
		} else {
			resp = await context.apis[
				context.user.role
			].getRecommendedDataByCampaignData(context.validator, { data: dataset });
		}
		if (resp && resp.done) {
			setPageLoading(false);
			if (localVar === algoSchoolListGlobalVar) {
				let inventoryName = !parentData?.data?.brandDetail?.inventory
					? []
					: parentData.data.inventory.inventories.reduce((acc, current) => {
							if (!acc.includes(current.name)) {
								acc.push(current.name);
							}
							return acc;
					  }, []);
				let eventName = !parentData?.data?.brandDetail?.eventAndOccations
					? []
					: parentData.data.eventAndOccation.eventAndOccations.reduce(
							(acc, current) => {
								if (current.parentName) {
									if (!acc.includes(current.parentName)) {
										acc.push(current.parentName);
									}
								} else {
									if (!acc.includes(current.name)) {
										acc.push(current.name);
									}
								}
								return acc;
							},
							[]
					  );
				const WisrOption = new WisrOptionService({
					TargetAudience:
						parentData.data.brandDetail.targetGender === "both"
							? "co-ed"
							: parentData.data.brandDetail.targetGender,
					EventsNames: [...eventName],
					InventoriesNames: [...inventoryName],
					budgetRatio: { ...resp.response.rows.budgetRatio },
					campaignBudget: totalBudget,
					campaignDurationInDays: days + 1,
					classroomData: [...resp.response.rows.classrooms], //List of classrooms
					eventData: [...resp.response.rows.events], //List of events
					inventoryData: [...resp.response.rows.inventories], //List of inventories
					schoolData: [...resp.response.rows.schools], //List of schools
					noOfDaysInYear: resp.response.rows.noOfDaysInYear,
					percentageDiscountInWISR:
						resp.response.rows.percentageIncreaseInBudget,
					percentageIncreaseInReach:
						resp.response.rows.percentageIncreaseInReach,
					totalInternalCostPerSchool:
						resp.response.rows.totalInternalCostPerSchool,
				});

				// const WisrOption = new WisrOptionService({
				// 	TargetAudience:
				// 		parentData.data.brandDetail.targetGender === "both"
				// 			? "co-ed"
				// 			: parentData.data.brandDetail.targetGender,
				// 	EventsNames: [...eventName],
				// 	InventoriesNames: [...inventoryName],
				// 	budgetRatio: { ...algoDataset.budgetRatio },
				// 	campaignBudget: parentData.data.campaignDetail.totalBudget,
				// 	campaignDurationInDays: days + 1,
				// 	classroomData: algoDataset.classroomData, //List of classrooms
				// 	eventData: algoDataset.eventData, //List of events
				// 	inventoryData: algoDataset.inventoryData, //List of inventories
				// 	schoolData: algoDataset.schoolData, //List of schools
				// 	noOfDaysInYear: algoDataset.noOfDaysInYear,
				// 	percentageDiscountInWISR: algoDataset.percentageDiscountInWISR,
				// 	percentageIncreaseInReach: algoDataset.percentageIncreaseInReach,
				// 	totalInternalCostPerSchool: algoDataset.totalInternalCostPerSchool,
				// });

				WisrOption.$OptimizedSchool.subscribe((school) => {
					console.log("optimittttt", school);
					let sortedSchool = school.sort((a) => {
						let found = formData.schools.find((item) => item._id === a._id);
						return found ? -1 : 1;
					});

					let sortedSchoolsByCategory = sortAllSchoolsByCategory(sortedSchool);

					const selectedSchools = [];
					const unselectedSchools = [];

					// Iterate through schoolList and classify schools
					sortedSchoolsByCategory.forEach((school) => {
						if (shoolSelected(school._id)) {
							selectedSchools.push(school);
						} else {
							unselectedSchools.push(school);
						}
					});

					// Combine selected and unselected schools to get the final sorted list
					const finalSortedSchoolList = [
						...selectedSchools,
						...unselectedSchools,
					];
					setSchoolList2([...finalSortedSchoolList]);
					paginationSchoolData(school, finalSortedSchoolList);
					// let schoolData = [];
					// for (
					// 	let i = 0;
					// 	i < dataPerPage && i < finalSortedSchoolList.length;
					// 	i++
					// ) {
					// 	schoolData.push(finalSortedSchoolList[i]);
					// }
					// let page = Math.ceil(school.length / dataPerPage);
					// let arr = [];
					// for (let i = 0; i < page; i++) {
					// 	arr.push(i);
					// }
					// setTotalPage([...arr]);
					// setPaginationSchool([...schoolData]);

					if (loadingFirstTime) {
						setTotalSchoolCount(school.length);
					}
					console.log("OptimizedSchool", school);
				});
				// setPageLoading(false);
			} else {
				setPageLoading(false);
			}
		}
	};

	useEffect(() => {
		if (parentData.data.campaignDetail.wisrOption) {
			if (increasedBudget > parentData.data.campaignDetail.totalBudget) {
				if (totalBudget > increasedBudget) {
					setBudgetExceeded(true);
				} else {
					setBudgetExceeded(false);
				}
			} else {
				if (totalBudget > parentData.data.campaignDetail.totalBudget) {
					setBudgetExceeded(true);
				} else {
					setBudgetExceeded(false);
				}
			}
		} else {
			if (totalBudget > parentData.data.campaignDetail.totalBudget) {
				setBudgetExceeded(true);
			} else {
				setBudgetExceeded(false);
			}
		}
	}, [totalBudget]);

	const manuallyChangeBudget = (budgetData) => {
		// setSearchValue("");
		setClearSearchIcon(false);
		setSearchInpuOpen(false);
		setPageLoading(true);

		let maxBudget = 0;
		let selectedSchool = [];
		WisrOption.$MaxBudget.subscribe((budget) => {
			maxBudget = budget;
			if (maxBudget < budgetData) {
				WisrOption.$SetBudget.next(maxBudget);
			}
		});

		if (budgetData <= maxBudget) {
			WisrOption.$SetBudget.next(budgetData);
		}

		WisrOption.$GetReach.subscribe((reach) => {
			setTotalReach(reach);
		});
		WisrOption.$GetBudget.subscribe((budget) => {
			if (budgetData > maxBudget) {
				setTotalBudget(maxBudget);
			} else {
				setTotalBudget(budget);
			}
		});
		WisrOption.$GetImpression.subscribe((impression) => {
			setTotalImpression(impression);
		});
		WisrOption.$FilteredSchool.subscribe((school) => {
			selectedSchool = [...school];
			let location = [];
			school.forEach((data) => {
				if (!location.includes(data.parentCity)) {
					location.push(data?.parentCity);
				}
			});
			setFormData({
				...parentData.data.school,
				schools: [...school],
				locations: [...location],
			});
			setInitialSchools([...school]);

			let sortedSchool = schoolList2.sort((a) => {
				let found = school.find((item) => item._id === a._id);
				return found ? -1 : 1;
			});

			let sortedSchoolsByCategory = sortAllSchoolsByCategory(sortedSchool);

			const budgetSelectedSchools = (_id) => {
				if (school && school.length > 0) {
					let found = school.find((item) => item._id === _id);
					if (found) {
						return true;
					} else return false;
				}
				return false;
			};

			const selectedSchools = [];
			const unselectedSchools = [];

			sortedSchoolsByCategory.forEach((school) => {
				if (budgetSelectedSchools(school._id)) {
					selectedSchools.push(school);
				} else {
					unselectedSchools.push(school);
				}
			});

			const finalSortedSchoolList = [...selectedSchools, ...unselectedSchools];
			setSchoolList2([...finalSortedSchoolList]);
			// Change 1
			paginationSchoolData(schoolList2, finalSortedSchoolList);
			// let schoolData = [];
			// for (
			// 	let i = 0;
			// 	i < dataPerPage && i < finalSortedSchoolList.length;
			// 	i++
			// ) {
			// 	schoolData.push(finalSortedSchoolList[i]);
			// }
			// let page = Math.ceil(schoolList.length / dataPerPage);
			// let arr = [];
			// for (let i = 0; i < page; i++) {
			// 	arr.push(i);
			// }
			// setTotalPage([...arr]);
			// setPaginationSchool([...schoolData]);

			setPageLoading(false);
			setCurrentPage(1);
		});
	};

	const manuallyChangeImpression = (impressionData) => {
		// setSearchValue("")
		setClearSearchIcon(false);
		setSearchInpuOpen(false);
		setPageLoading(true);
		setTotalImpression(impressionData);
		WisrOption.$SetImpressions.next(impressionData);
		let maxBudget = 0;
		WisrOption.$MaxBudget.subscribe((budget) => {
			maxBudget = budget;

			if (maxBudget < totalBudget) {
				WisrOption.$SetBudget.next(maxBudget);
			}
		});

		WisrOption.$GetReach.subscribe((reach) => {
			setTotalReach(reach);
		});
		WisrOption.$GetBudget.subscribe((budget) => {
			if (budget > maxBudget) {
				setTotalBudget(maxBudget);
			} else {
				setTotalBudget(budget);
			}
		});
		WisrOption.$GetImpression.subscribe((impression) => {
			setTotalImpression(impression);
		});
		WisrOption.$FilteredSchool.subscribe((school) => {
			let location = [];
			let selectedSchool = [];
			school.forEach((data) => {
				if (!location.includes(data.parentCity)) {
					location.push(data?.parentCity);
				}
			});
			selectedSchool = [...school];
			setFormData({
				...parentData.data.school,
				schools: [...school],
				locations: [...location],
			});
			setInitialSchools([...school]);

			let sortedSchool = schoolList2.sort((a) => {
				let found = school.find((item) => item._id === a._id);
				return found ? -1 : 1;
			});

			let sortedSchoolsByCategory = sortAllSchoolsByCategory(sortedSchool);

			const impressionSelectedSchools = (_id) => {
				if (school && school.length > 0) {
					let found = school.find((item) => item._id === _id);
					if (found) {
						return true;
					} else return false;
				}
				return false;
			};

			const selectedSchools = [];
			const unselectedSchools = [];
			sortedSchoolsByCategory.forEach((school) => {
				if (impressionSelectedSchools(school._id)) {
					selectedSchools.push(school);
				} else {
					unselectedSchools.push(school);
				}
			});

			// Combine selected and unselected schools to get the final sorted list
			const finalSortedSchoolList = [...selectedSchools, ...unselectedSchools];
			setSchoolList2([...finalSortedSchoolList]);
			// Changed 2
			paginationSchoolData(schoolList2, finalSortedSchoolList);

			// let schoolData = [];
			// for (
			// 	let i = 0;
			// 	i < dataPerPage && i < finalSortedSchoolList.length;
			// 	i++
			// ) {
			// 	schoolData.push(finalSortedSchoolList[i]);
			// }
			// let page = Math.ceil(schoolList.length / dataPerPage);
			// let arr = [];
			// for (let i = 0; i < page; i++) {
			// 	arr.push(i);
			// }
			// setTotalPage([...arr]);
			// setPaginationSchool([...schoolData]);

			setPageLoading(false);
			setCurrentPage(1);
		});
	};
	const manuallyChangeReach = (reach) => {
		// setSearchValue("")
		setClearSearchIcon(false);
		setSearchInpuOpen(false);
		setPageLoading(true);
		setTotalReach(reach);
		WisrOption.$SetReach.next(reach);
		let maxBudget = 0;
		WisrOption.$MaxBudget.subscribe((budget) => {
			maxBudget = budget;

			if (maxBudget < totalBudget) {
				WisrOption.$SetBudget.next(maxBudget);
			}
		});
		WisrOption.$GetReach.subscribe((reachData) => {
			setTotalReach(reachData);
		});

		WisrOption.$GetBudget.subscribe((budget) => {
			if (budget > maxBudget) {
				setTotalBudget(maxBudget);
			} else {
				setTotalBudget(budget);
			}
		});
		WisrOption.$GetImpression.subscribe((impression) => {
			setTotalImpression(impression);
		});
		WisrOption.$FilteredSchool.subscribe((school) => {
			let selectedSchool = [];
			selectedSchool = [...school];
			let location = [];
			school.forEach((data) => {
				if (!location.includes(data.parentCity)) {
					location.push(data?.parentCity);
				}
			});

			setFormData({
				...parentData.data.school,
				schools: [...school],
				locations: [...location],
			});
			setInitialSchools([...school]);

			let sortedSchool = schoolList2.sort((a) => {
				let found = school.find((item) => item._id === a._id);
				return found ? -1 : 1;
			});

			let sortedSchoolsByCategory = sortAllSchoolsByCategory(sortedSchool);

			const reachSelectedSchools = (_id) => {
				if (school && school.length > 0) {
					let found = school.find((item) => item._id === _id);
					if (found) {
						return true;
					} else return false;
				}
				return false;
			};

			const selectedSchools = [];
			const unselectedSchools = [];
			sortedSchoolsByCategory.forEach((school) => {
				if (reachSelectedSchools(school._id)) {
					selectedSchools.push(school);
				} else {
					unselectedSchools.push(school);
				}
			});

			const finalSortedSchoolList = [...selectedSchools, ...unselectedSchools];

			setSchoolList2([...finalSortedSchoolList]);
			paginationSchoolData(schoolList2, finalSortedSchoolList);

			// let schoolData = [];
			// for (
			// 	let i = 0;
			// 	i < dataPerPage && i < finalSortedSchoolList.length;
			// 	i++
			// ) {
			// 	schoolData.push(finalSortedSchoolList[i]);
			// }
			// let page = Math.ceil(schoolList2.length / dataPerPage);
			// let arr = [];
			// for (let i = 0; i < page; i++) {
			// 	arr.push(i);
			// }
			// setTotalPage([...arr]);
			// setPaginationSchool([...schoolData]);

			setPageLoading(false);
			setCurrentPage(1);
		});
	};
	const handleAddRemoveFilter = (type, data) => {
		if (type === "state") {
			let array = [...selectedState];

			if (array.includes(data)) {
				if (array.length < 2) {
					console.log("arrayLength 1", array.length);
					toast.error("You need to select atlest one state ----");
					return;
				}
				array.splice(array.indexOf(data), 1);
				if (filterByCity.length !== 0) {
					let citiesToRemove = [...cityStateData[data]],
						newCitiesData = [...filterByCity];

					for (let city of citiesToRemove) {
						console.log("new city = ", city, newCitiesData);
						if (newCitiesData.indexOf(city) !== -1) {
							newCitiesData.splice(newCitiesData.indexOf(city), 1);
						}
					}
					newCitiesData = newCitiesData.filter((data) => data != "raigarh(mh)");

					setFilterByCity([...newCitiesData]);
				}
			} else {
				array.push(data);
				let newCityList = filterByCity.filter((data) => data != "raigarh(mh)");

				setFilterByCity([...newCityList, ...cityStateData[data]]);
			}
			setSelectedState([...array]);
		}
		if (type === "city") {
			let array = [...filterByCity];
			array = array.filter((data) => data != "raigarh(mh)");
			if (array.includes(data)) {
				if (array.length < 2) {
					toast.error("You need to select atlest one city");
					return;
				}
				array.splice(array.indexOf(data), 1);
			} else {
				array.push(data);
			}

			setFilterByCity([...array]);
		} else if (type === "board") {
			let array = [...filterByBoard];
			if (array.includes(data)) {
				if (array.length < 2) {
					console.log("arrayLength 2", array.length);
					toast.error("You need to select atlest one board");
					return;
				}
				array.splice(array.indexOf(data), 1);
			} else {
				array.push(data);
			}
			setFilterByBoard([...array]);
		} else if (type === "language") {
			let array = [...filterByLanguage];
			if (array.includes(data)) {
				if (array.length < 2) {
					console.log("arrayLength 2", array.length);
					toast.error("You need to select atlest one language");
					return;
				}
				array.splice(array.indexOf(data), 1);
			} else {
				array.push(data);
			}
			setFilterByLanguage([...array]);
		} else if (type === "schoolType") {
			// let array = [...filterBySchoolType];
			// console.log("school type array = ", array);
			// console.log("school type data = ", data);
			// if (!array.includes("co-ed")) {
			// 	array.splice(array.indexOf("Custom"), 1);
			// }
			// console.log("school type includes girls =", array.includes(data.trim()));
			// console.log("school type index of girls =", array.indexOf(data));

			// if (array.includes(data.trim())) {
			// 	let removedValue = array.splice(array.indexOf(data), 1);
			// 	if (array.length < 2) {
			// 		console.log("school type arrayLength 2", array.length);
			// 		toast.error("You need to select atlest one gender");
			// 		array.push(...removedValue);
			// 		return;
			// 	}
			// 	console.log("school type array in if= ", array);
			// 	// array.splice(array.indexOf(data), 1);
			// 	if (array.includes("co-ed") && !array.includes("Custom")) {
			// 		array.push("Custom");
			// 	} else {
			// 		array.splice(array.indexOf("Custom"), 1);
			// 	}
			// } else {
			// 	console.log(
			// 		"school type else includes girls =",
			// 		array.includes(data.trim())
			// 	);
			// 	console.log("school type else =", data);
			// 	console.log("school type else array =", array);

			// 	array.push(data);
			// 	console.log("school type else push array=", array);
			// }

			const schoolTypeSet = new Set(filterBySchoolType);
			console.log(
				"school type =",
				schoolTypeSet,
				" array size =",
				schoolTypeSet.size
			);
			if (schoolTypeSet.size === 1 && schoolTypeSet.has(data.trim())) {
				toast.error("You need to select at least one gender");
				return;
			} else if (
				schoolTypeSet.size === 2 &&
				schoolTypeSet.has(data) &&
				data === "co-ed"
			) {
				toast.error("You need to select at least one gender");
				return;
			}

			if (data.trim() === "co-ed") {
				schoolTypeSet.delete("Custom");
			}

			if (schoolTypeSet.has(data.trim())) {
				schoolTypeSet.delete(data);
				if (schoolTypeSet.has("co-ed") && !schoolTypeSet.has("Custom")) {
					schoolTypeSet.add("Custom");
				}
			} else {
				schoolTypeSet.add(data);
				schoolTypeSet.add("Custom");
			}
			console.log("school type end =", schoolTypeSet);
			setFilterBySchoolType(Array.from(schoolTypeSet));

			// setFilterBySchoolType([...array]);
		}
	};

	const clearCityStateFilter = () => {
		let stateCityData = {},
			state = [],
			cities = [];
		schoolList.forEach((item) => {
			if (
				item.parentCity &&
				item.parentCity !== "" &&
				item.parentCity !== null
			) {
				if (stateCityData[item.state.toLowerCase()]) {
					if (
						!stateCityData[item.state.toLowerCase()].find(
							(data) => data.toLowerCase() === item?.parentCity?.toLowerCase()
						)
					) {
						stateCityData[item.state.toLowerCase()].push(
							item.parentCity.toLowerCase()
						);
					}
				} else {
					stateCityData[item.state.toLowerCase()] = [
						item.parentCity.toLowerCase(),
					];
				}

				if (!state.includes(item.state.toLowerCase())) {
					state.push(item.state.toLowerCase());
				}
				if (!cities.includes(item.parentCity.toLowerCase())) {
					cities.push(item.parentCity.toLowerCase());
				}
			}
		});
		state = state.sort((a, b) => {
			return a.toLowerCase() > b.toLowerCase() ? 1 : -1;
		});
		cities = cities.sort((a, b) => {
			return a.toLowerCase() > b.toLowerCase() ? 1 : -1;
		});

		setSelectedState([...state]);
		setFilterByCity([...cities]);
	};

	const getAllSchools = async () => {
		// alert("Hello I'm called inside getAllSchools function");
		setPageLoading(true);

		let startDate = moment(new Date(parentData.data.brandDetail.startDate));
		let endDate = moment(new Date(parentData.data.brandDetail.endDate));
		let duration = moment.duration(endDate.diff(startDate));
		let days = duration.asDays();
		let inventoryName = !parentData?.data?.brandDetail?.inventory
			? []
			: parentData.data.inventory.inventories.reduce((acc, current) => {
					if (!acc.includes(current.name)) {
						acc.push(current.name);
					}
					return acc;
			  }, []);
		let eventName = !parentData?.data?.brandDetail?.eventAndOccations
			? []
			: parentData.data.eventAndOccation.eventAndOccations.reduce(
					(acc, current) => {
						if (current.parentName) {
							if (!acc.includes(current.parentName)) {
								acc.push(current.parentName);
							}
						} else {
							if (!acc.includes(current.name)) {
								acc.push(current.name);
							}
						}
						return acc;
					},
					[]
			  );

		const wisrOption = new WisrOptionService({
			TargetAudience:
				parentData.data.brandDetail.targetGender === "both"
					? "co-ed"
					: parentData.data.brandDetail.targetGender,
			EventsNames: [...eventName],
			InventoriesNames: [...inventoryName],
			budgetRatio: { ...algoDataset.budgetRatio },
			campaignBudget: parentData.data.campaignDetail.totalBudget,
			campaignDurationInDays: days + 1,
			classroomData: algoDataset.classroomData, //List of classrooms
			eventData: algoDataset.eventData, //List of events
			inventoryData: algoDataset.inventoryData, //List of inventories
			schoolData: algoDataset.schoolData, //List of schools
			noOfDaysInYear: algoDataset.noOfDaysInYear,
			percentageDiscountInWISR: algoDataset.percentageDiscountInWISR,
			percentageIncreaseInReach: algoDataset.percentageIncreaseInReach,
			totalInternalCostPerSchool: algoDataset.totalInternalCostPerSchool,
		});
		setWisrOption(wisrOption);
		let maxBudget = 0;
		let selectedSchool = [];
		wisrOption.$MaxBudget.subscribe((budget) => {
			maxBudget = budget;
			if (maxBudget < parentData.data.campaignDetail.totalBudget) {
				wisrOption.$SetBudget.next(maxBudget);
			}
		});

		wisrOption.$MinReach.subscribe((minReach) => {
			setMinReach(minReach);
		});
		wisrOption.$MaxReach.subscribe((maxReach) => {
			setMaxReach(maxReach);
		});
		wisrOption.$MinImpressions.subscribe((minImpression) => {
			setMinImpression(minImpression);
		});
		wisrOption.$MaxImpressions.subscribe((maxImpression) => {
			setMaxImpression(maxImpression);
		});
		wisrOption.$MinBudget.subscribe((minBudget) => {});
		wisrOption.$MaxBudget.subscribe((maxBudget) => {});
		if (parentData.data.campaignDetail.wisrOption) {
			wisrOption.$IncreasedBudget.subscribe((increasedBudget) => {
				if (parentData.data.campaignDetail.totalBudget > maxBudget) {
					if (parentData.data.school.schools.length === 0) {
						setTotalBudget(maxBudget);
					}
					setIncreasedBudget(maxBudget);
				} else {
					if (parentData.data.school.schools.length === 0) {
						setTotalBudget(increasedBudget);
					}
					setIncreasedBudget(increasedBudget);
				}
			});
			wisrOption.$IncreasedImpressions.subscribe((increasedImpression) => {
				if (parentData.data.school.schools.length === 0) {
					setTotalImpression(increasedImpression);
				}
			});
			wisrOption.$IncreasedReach.subscribe((IncreasedReach) => {
				if (parentData.data.school.schools.length === 0) {
					setTotalReach(IncreasedReach);
				}
			});
			wisrOption.$SchoolsForWisrOptions.subscribe((school) => {
				selectedSchool =
					parentData.data.school.schools.length === 0
						? [...school]
						: [...parentData.data.school.schools];
				let location = [];
				school.forEach((data) => {
					if (!location.includes(data.parentCity)) {
						location.push(data?.parentCity);
					}
				});
				if (parentData.data.school.schools.length === 0) {
					selectedSchool = [...school];
					setFormData({
						...parentData.data.school,
						schools: [...school],
						locations: [...location],
					});
					setInitialSchools([...school]);
				}
			});
		} else {
			wisrOption.$GetReach.subscribe((reach) => {
				if (parentData.data.school.schools.length === 0) {
					setTotalReach(reach);
				}
			});
			wisrOption.$GetBudget.subscribe((budget) => {
				if (parentData.data.school.schools.length === 0) {
					if (parentData.data.campaignDetail.totalBudget > maxBudget) {
						setTotalBudget(maxBudget);
					} else {
						setTotalBudget(budget);
					}
				}
			});
			wisrOption.$GetImpression.subscribe((impression) => {
				if (parentData.data.school.schools.length === 0) {
					setTotalImpression(impression);
				}
			});
			wisrOption.$FilteredSchool.subscribe((school) => {
				selectedSchool =
					parentData.data.school.schools.length === 0
						? [...school]
						: [...parentData.data.school.schools];
				if (parentData.data.school.schools.length === 0) {
					let location = [];
					school.forEach((data) => {
						if (!location.includes(data.parentCity)) {
							location.push(data?.parentCity);
						}
					});
					selectedSchool = [...school];
					setFormData({
						...parentData.data.school,
						schools: [...school],
						locations: [...location],
					});
					setInitialSchools([...school]);
				}
			});
		}
		wisrOption.$OptimizedSchool.subscribe((school) => {
			console.log("findingBoard", school);
			let stateCityData = {},
				state = [],
				cities = [];
			// board = []; // new created
			school.forEach((item) => {
				console.log("findingBoard item", item.board);
				// console.log("findingBoard only item", item.classrooms[0].avgAnnualFee);
				console.log(
					"findingBoard only item new",
					// item.classrooms.map((singleValue) => singleValue.avgAnnualFee)
					// item.board.map((singleValue) => singleValue.board)
					item.board
				);
				console.log("findingBoard state", item.state);
				if (
					item.parentCity &&
					item.parentCity !== "" &&
					item.parentCity !== null
				) {
					if (stateCityData[item.state.toLowerCase()]) {
						if (
							!stateCityData[item.state.toLowerCase()].find(
								(data) => data.toLowerCase() === item?.parentCity?.toLowerCase()
							)
						) {
							stateCityData[item.state.toLowerCase()].push(
								item.parentCity.toLowerCase()
							);
						}
					} else {
						stateCityData[item.state.toLowerCase()] = [
							item.parentCity.toLowerCase(),
						];
					}

					if (!state.includes(item.state.toLowerCase())) {
						state.push(item.state.toLowerCase());
					}
					if (!cities.includes(item.parentCity.toLowerCase())) {
						cities.push(item.parentCity.toLowerCase());
					}
					// if (!board.includes(item.parentCity.toLowerCase())) {
					// 	cities.push(item.parentCity.toLowerCase());
					// }
				}
			});
			state = state.sort((a, b) => {
				return a.toLowerCase() > b.toLowerCase() ? 1 : -1;
			});
			cities = cities.sort((a, b) => {
				return a != "raigarh(mh)" && a.toLowerCase() > b.toLowerCase() ? 1 : -1;
			});

			setStateList([...state]);
			setCityStateData({ ...stateCityData });
			setCityList([...cities]);
			setFilterByCity([...cities]);

			let sortedSchool = school.sort((a) => {
				let found = selectedSchool.find((item) => item._id === a._id);
				return found ? -1 : 1;
			});

			let sortedSchoolsByCategory = sortAllSchoolsByCategory(sortedSchool);

			const checkSelectedSchools = (_id) => {
				let found = selectedSchool.find((item) => item._id === _id);
				if (found) {
					return true;
				}
				return false;
			};

			const selectedSchools = [];
			const unselectedSchools = [];
			sortedSchoolsByCategory.forEach((school) => {
				if (checkSelectedSchools(school._id)) {
					selectedSchools.push(school);
				} else {
					unselectedSchools.push(school);
				}
			});

			const finalSortedSchoolList = [...selectedSchools, ...unselectedSchools];

			setSchoolList([...finalSortedSchoolList]);
			setSchoolList2([...finalSortedSchoolList]);
			paginationSchoolData(school, finalSortedSchoolList);
			// let schoolData = [];
			// for (
			// 	let i = 0;
			// 	i < dataPerPage && i < finalSortedSchoolList.length;
			// 	i++
			// ) {
			// 	schoolData.push(finalSortedSchoolList[i]);
			// }
			// let page = Math.ceil(school.length / dataPerPage);
			// let arr = [];
			// for (let i = 0; i < page; i++) {
			// 	arr.push(i);
			// }
			// setTotalPage([...arr]);
			// setPaginationSchool([...schoolData]);

			if (loadingFirstTime) {
				setTotalSchoolCount(school.length);
			}
			console.log("OptimizedSchool 1309", school);
		});
		setPageLoading(false);
		setLoadingFirstTime(false);
	};

	useEffect(() => {
		let city = [];
		formData.schools.forEach((school) => {
			if (
				school.parentCity != "undefined" &&
				school.parentCity != "" &&
				school.parentCity != null
			) {
				city.push(school.parentCity);
			}
		});
		setPincodeList([...city]);
	}, [formData.schools]);

	const onSave = async (confirm, budgetChecked) => {
		if (confirm) {
			if (formData.schools && formData.schools.length === 0) {
				setShowErrorMessage(true);
				return;
			} else if (formData.schools.length > 0) {
				setShowErrorMessage(false);
			}

			let usedEvent = [];
			formData.schools.forEach((item) => {
				item.events.forEach((data) => {
					if (!data._custom) {
						if (!usedEvent.includes(data.name)) {
							usedEvent.push(data.name);
						}
					} else {
						if (!usedEvent.includes(data.parentName)) {
							usedEvent.push(data.parentName);
						}
					}
				});
			});
			usedEvent = parentData.data.eventAndOccation.eventAndOccations.filter(
				(event) => {
					let found = usedEvent.find(
						(item) => item === event.parentName || item === event.name
					);
					if (found) {
						return true;
					} else {
						return false;
					}
				}
			);

			usedEvent = usedEvent.filter(
				(item, index) =>
					index === usedEvent.findIndex((data) => data.name === item.name)
			);

			let usedInventories = [];
			formData.schools.forEach((item) => {
				item.inventories.forEach((data) => {
					if (!data._custom) {
						if (!usedInventories.includes(data.name)) {
							usedInventories.push(data.name);
						}
					} else {
						if (!usedInventories.includes(data.parentName)) {
							usedInventories.push(data.parentName);
						}
					}
				});
			});
			usedInventories = parentData.data.inventory.inventories.filter(
				(inventory) => {
					let found = usedInventories.find(
						(item) => item === inventory.parentName || item === inventory.name
					);
					if (found) {
						return true;
					} else {
						return false;
					}
				}
			);

			if (parentData) {
				if (parentData.setProgress) {
					parentData.setProgress({
						...parentData.progress,
						recommendedSchool: true,
					});
				}
				if (parentData.setData) {
					parentData.setData({
						...parentData.data,
						school: { ...formData },
						campaignDetail: {
							...parentData.data.campaignDetail,
							totalBudget: totalBudget,
							totalReach: totalReach,
							totalImpression: totalImpression,
							girlsRatio: girlsRatio,
							boysRatio: boysRatio,
						},
						inventory: {
							...parentData.data.inventory,
							usedInventories: [...usedInventories],
						},
						eventAndOccation: {
							...parentData.data.eventAndOccation,
							eventAndOccations: [
								...parentData.data.eventAndOccation.eventAndOccations,
							],
							usedEvents: [...usedEvent],
						},
					});
				}
			}
			if (parentData.data.brandDetail.isTrial) {
				history.push("/brands/brand-details/create-trial-campaign/creative");
			} else {
				history.push("/brands/brand-details/create-campaign/creative");
			}
		}
	};
	const handleSearch = async (e) => {
		if (e === "") {
			setSearchValue("");
		} else {
			setSearchValue(e.target.value.trim());
		}
	};
	const openFilter = () => {
		setShowBoardFilter(false);
		setShowCityFilter(false);
		setShowSchoolTypeFilter(false);
		setShowLanguageFilter(false);
		setShowFilterModal(!showFilterModal);
	};
	const showFilterDropDown = (type) => {
		if (type === "state") {
			setShowStateFilter(!showStateFilter);
			setShowBoardFilter(false);
			setShowLanguageFilter(false);
			setShowSchoolTypeFilter(false);
		}
		if (type === "city") {
			setShowBoardFilter(false);
			setShowLanguageFilter(false);
			setShowSchoolTypeFilter(false);
			setShowCityFilter(!showCityFilter);
		} else if (type === "board") {
			setShowStateFilter(false);
			setShowBoardFilter(!showBoardFilter);
			setShowLanguageFilter(false);
			setShowSchoolTypeFilter(false);
			setShowCityFilter(false);
		} else if (type === "language") {
			setShowStateFilter(false);
			setShowBoardFilter(false);
			setShowLanguageFilter(!showLanguageFilter);
			setShowSchoolTypeFilter(false);
			setShowCityFilter(false);
		} else if (type === "schoolType") {
			setShowStateFilter(false);
			setShowBoardFilter(false);
			setShowLanguageFilter(false);
			setShowSchoolTypeFilter(!showSchoolTypeFilter);
			setShowCityFilter(false);
		}
	};
	const clearAllFilter = () => {
		setFilterBySchoolType(["boys", "girls", "co-ed", "Custom"]);
		setFilterByBoard(Array.from(dd));
		// setFilterByCity([...parentData.data.brandDetail.city]);
		setFilterByLanguage([...parentData.data.brandDetail.studyMedium]);
		// setSelectedState([...parentData.data.brandDetail.state]);
		setShowStateFilter(false);
		setShowFilterModal(false);
		setShowBoardFilter(false);
		setShowLanguageFilter(false);
		setShowSchoolTypeFilter(false);
		setShowCityFilter(false);
		clearCityStateFilter();
	};
	const onExit = async (confirm) => {
		if (confirm) {
			setConfirmDialogBtnLoading(true);
			let apiData = formateData();

			if (apiData["_id"]) {
				let resp = await context.apis[context.user.role].editCampaign(
					context.validator,
					apiData
				);

				if (resp && resp.done) {
					setConfirmDialogBtnLoading(false);
					history.push({
						pathname: "/brands/brand-details/campaigns",
						state: { campaignCreated: true },
					});
				}
			} else {
				let resp = await context.apis[context.user.role].addCampaign(
					context.validator,
					apiData
				);

				if (resp && resp.done) {
					setConfirmDialogBtnLoading(false);
					history.push({
						pathname: "/brands/brand-details/campaigns",
						state: { campaignCreated: true },
					});
				}
			}
		}
		setShowExitPopup(false);
		setOverlay(false);
	};
	const openExit = () => {
		setShowExitPopup(true);
		setOverlay(true);
	};
	const formateData = () => {
		let inventoriesIdArray = [];
		parentData.data.inventory.inventories.forEach((inventory) => {
			let attributesIdArray = [];
			inventory.placements &&
				Array.isArray(inventory.placements) &&
				inventory.placements.length > 0 &&
				inventory.placements.map((item) => {
					attributesIdArray.push(item.name);
				});
			let obj = {
				_id: inventory._id,
				name: inventory.parentName || inventory.name,
				attributes: [...attributesIdArray],
				_custom: inventory._custom || false,
			};
			inventoriesIdArray.push(obj);
		});
		let eventIdArray = [];
		parentData.data.eventAndOccation.eventAndOccations.forEach((event) => {
			let attributesIdArray = [];
			event.placements &&
				Array.isArray(event.placements) &&
				event.placements.map((item) => {
					attributesIdArray.push(item._id || item.name);
				});
			let obj = {
				_id: event._id,
				name: event.name,
				attributes: [...attributesIdArray],
			};
			eventIdArray.push(obj);
		});
		let imageArray = [];
		if (parentData.data.campaignCreative.creatives.image1.key) {
			imageArray.push(parentData.data.campaignCreative.creatives.image1.key);
		}
		if (parentData.data.campaignCreative.creatives.image2.key) {
			imageArray.push(parentData.data.campaignCreative.creatives.image2.key);
		}
		if (parentData.data.campaignCreative.creatives.image3.key) {
			imageArray.push(parentData.data.campaignCreative.creatives.image3.key);
		}
		let obj = {
			brandId: parentData.data.brandDetail.brandId,
			name: parentData.data.brandDetail.name.trim(),
			description: parentData.data.brandDetail.description.trim(),
			objective: parentData.data.brandDetail.objective.trim(),
			startDate: parentData.data.brandDetail.startDate,
			endDate: parentData.data.brandDetail.endDate,
			locations: [],
			targetGender:
				parentData.data.brandDetail.targetGender === "both"
					? "co-ed"
					: parentData.data.brandDetail.targetGender,
			targetAgeGroup: [
				parentData.data.brandDetail.targetAgeGroup.min,
				parentData.data.brandDetail.targetAgeGroup.max,
			],
			studyMedium: [...parentData.data.brandDetail.studyMedium],
			states: [...parentData.data.brandDetail.state],
			cities: [...parentData.data.brandDetail.city],
			brandCategory: parentData.data.brandDetail.brandCategory._id,
			brandSubcategory: parentData.data.brandDetail.brandSubcategory.value,
			brandName: parentData.data.brandDetail.brandName,
			recommendEvents: false,
			campaignStatus: "draft",
			isApproved: false,
			campaignCreatives: [...imageArray],
			budget: totalBudget,
			isTrial: parentData.data.brandDetail.isTrial,
			wisrOffering: {
				inventory: parentData.data.brandDetail.inventory,
				eventAndOccations: parentData.data.brandDetail.eventAndOccations,
				csr: parentData.data.brandDetail.csr,
				sampling: parentData.data.brandDetail.sampling,
				custom: parentData.data.brandDetail.custom,
			},
			campaignCreationProgress: {
				progress: {
					...parentData.progress,
					recommendedSchool: true,
				},
				currentPage: "recommendedSchool",
			},
			campaignData: {
				inventories: [...inventoriesIdArray],
				schools: [],
				activities: [...eventIdArray],
				schoolListDetail: [],
			},
		};
		if (parentData.data.brandDetail._id) {
			obj["_id"] = parentData.data.brandDetail._id;
		}
		return obj;
	};
	useEffect(() => {
		if (formData.schools && formData.schools.length > 0) {
			setShowErrorMessage(false);
		}
	}, [formData?.schools]);

	const removeDuplicates = (array, key) => {
		const seen = new Set();
		return array.filter((item) => {
			const value = item[key];
			if (!seen.has(value)) {
				seen.add(value);
				return true;
			}
			return false;
		});
	};

	const sortAllSchoolsByCategory = (sortedSchoolParam) => {
		const uniqueInventoriesArray = sortedSchoolParam.map((entry) => {
			const uniqueInventoryArray = removeDuplicates(entry.inventories, "name");
			const uniqueEventArray = removeDuplicates(entry.events, "name");

			return {
				...entry,
				inventories: uniqueInventoryArray,
				events: uniqueEventArray,
			};
		});

		const sortedSchoolsByCategory = uniqueInventoriesArray.sort((a, b) => {
			const categoryComparison = a.category.localeCompare(
				b.category,
				undefined,
				{ numeric: true, sensitivity: "base" }
			);
			if (categoryComparison !== 0) {
				return categoryComparison;
			}

			const itemCountB = b.inventories.length + b.events.length;
			const itemCountA = a.inventories.length + a.events.length;

			return itemCountB - itemCountA;
		});

		return sortedSchoolsByCategory;
	};

	const [finalCount, setFinalCount] = useState<any>();
	const [finalCount2, setFinalCount2] = useState<any>();
	useEffect(() => {
		// setFinalCount("192");
		setFinalCount(schoolList2.length);
	}, [finalCount]);

	useEffect(() => {
		setFinalCount2(finalCount);
	});

	// other

	// const [finalCount, setFinalCount] = useState<any>();
	// const finalCount2Ref = useRef<any>();

	// useEffect(() => {
	// 	setFinalCount(schoolList2.length);
	// }, [schoolList2]); // Empty dependency array ensures this effect runs only once

	// useEffect(() => {
	// 	if (finalCount !== undefined && finalCount2Ref.current === undefined) {
	// 		finalCount2Ref.current = finalCount;
	// 	}
	// }, [finalCount]);

	// // Now you can use finalCount2Ref.current as the initial value of finalCount2
	// const finalCount2 = finalCount2Ref.current;

	// other 2

	// const [finalCount, setFinalCount] = useState<any>();
	// const [finalCount2, setFinalCount2] = useState<any>();
	// const [initialValueSet, setInitialValueSet] = useState(false);

	// useEffect(() => {
	// 	const initialCount = schoolList2.length;
	// 	setFinalCount(initialCount);
	// }, []); // Empty dependency array ensures this effect runs only once

	// useEffect(() => {
	// 	if (finalCount !== undefined && !initialValueSet) {
	// 		setFinalCount2(finalCount);
	// 		setInitialValueSet(true);
	// 	}
	// }, [finalCount, initialValueSet]);

	// console.log("finalCount2", finalCount2);

	// setFinalCount([schoolList2.length]);

	// const finalCount = schoolList2.length;

	return (
		<>
			<div className={`ovelap ${overlay === true && "active"}`}></div>
			{/* <TopBar isDashboard={true} /> */}
			<div className="col-xl-9 col-lg-12 col-md-12 col-xs-12">
				<div className="rightPart noBack">
					<div className="row">
						<div className="col-xs-12 col-lg-12 col-md-12 col-xs-12">
							<BrandBackBtn />
						</div>
						<div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-20">
							<div className="row">
								<div
									className="col-xl-6 col-lg-6 col-md-6 col-xs-12"
									style={{ padding: "0px" }}
								>
									<h2 className="htx5 clearfix mt-10 mb-20">
										Create a Campaign
									</h2>
								</div>
								<div
									className="col-xl-6 col-lg-6 col-md-6 col-xs-12"
									style={{ marginTop: "20px" }}
								>
									<a
										href="javascript:void(0);"
										onClick={() =>
											history.push({
												pathname: parentData.data.brandDetail.isTrial
													? "/brands/brand-details/create-trial-campaign/set-up-campaign"
													: "/brands/brand-details/create-campaign/set-up-campaign",
												state: { from: "createCampaignFlow" },
											})
										}
										style={{ color: "black", position: "relative" }}
									>
										<img
											style={{ position: "absolute", right: "118px" }}
											src={editSvg}
										/>{" "}
										Edit Campaign
									</a>
								</div>
							</div>
						</div>
						<div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-10">
							<h2 className="htx2 clearfix mb-10">
								{parentData?.data?.campaignDetail?.wisrOption
									? "Recommended Schools"
									: "Recommended Schools"}{" "}
								{/* <b>{`(${formData.schools.length}/${totalSchoolCount})`}</b> */}
								<b>{`(${formData.schools.length}/${finalCount2})`}</b>
							</h2>
						</div>
						<div className="col-xl-8 col-lg-8 col-md-12 col-xs-12 mb-20">
							<div className="row">
								<div className="col-xl-6 col-lg-3 col-md-3 col-xs-12 mb-10 pl">
									<CustomSearchInput
										searchInpuOpen={searchInpuOpen}
										setSearchInpuOpen={setSearchInpuOpen}
										clearSearchIcon={clearSearchIcon}
										setClearSearchIcon={setClearSearchIcon}
										searchKeys={searchValue}
										handleChange={handleSearch}
										palaceholder="Search"
									/>
								</div>
								<div className="col-xl-6 col-lg-9 col-md-9 col-xs-12 mb-10 pr">
									<div className="fR filterbox">
										<a
											href="javascript:void(0);"
											className="filterDrop"
											onClick={openFilter}
										>
											<img src={filterSvg} />
											<h3>{`Filter(${
												pageLoading
													? "..."
													: filterByBoard.length === 0 &&
													  selectedState.length === 0 &&
													  filterByCity.length === 0 &&
													  filterByLanguage.length === 0 &&
													  filterBySchoolType.length === 0 &&
													  searchValue === ""
													? 0
													: schoolList2.length
											})`}</h3>
											<img src={filterArrowSvg} />
										</a>
										<div
											className={`filterDropDown ${
												showFilterModal === true && "open"
											}`}
										>
											<div className="filterDropHead" ref={filterRef}>
												<h2>
													Filter{" "}
													<a
														href="javascript:void(0);"
														className="viewwLink"
														onClick={clearAllFilter}
													>
														Clear All
													</a>
												</h2>
											</div>
											<div className="filterDropBody" ref={filterOptionsRef}>
												<div
													className={`filterDropClose ${
														showStateFilter === true && "open"
													}`}
												>
													<h3>
														<a
															href="javascript:void(0);"
															onClick={() => showFilterDropDown("state")}
														>
															{`State ${
																selectedState.length > 0
																	? `(${selectedState.length})`
																	: ""
															}`}{" "}
															<img src={filterArrowSvg} />
														</a>
													</h3>
													<div className="filtercheckbox">
														{console.log("stateList", stateList)}
														{stateList.map((item, index) => (
															<div className="checkbox fnone" key={index}>
																<input
																	type="checkbox"
																	id={item + "_" + index}
																	checked={selectedState.includes(item)}
																	onClick={(e) =>
																		handleAddRemoveFilter("state", item)
																	}
																/>
																<label htmlFor={item + "_" + index}>
																	{titleCase(item)}
																</label>
															</div>
														))}
													</div>
												</div>
												<div
													className={`filterDropClose ${
														showCityFilter === true &&
														selectedState.length !== 0 &&
														"open"
													}`}
												>
													<h3>
														<a
															href="javascript:void(0);"
															onClick={() => showFilterDropDown("city")}
														>
															{`City ${
																filterByCity.length > 0
																	? `(${
																			filterByCity.filter(
																				(city) => city !== "raigarh(mh)"
																			).length
																	  })`
																	: ""
															}`}{" "}
															<img src={filterArrowSvg} />
														</a>
													</h3>
													<div className="filtercheckbox">
														{cityList.map((item, index) => (
															<div className="checkbox fnone" key={index}>
																<input
																	type="checkbox"
																	id={item}
																	checked={filterByCity.includes(item)}
																	onClick={() =>
																		handleAddRemoveFilter("city", item)
																	}
																/>
																<label htmlFor={item}>{titleCase(item)}</label>
															</div>
														))}
													</div>
												</div>
												<div
													className={`filterDropClose ${
														showSchoolTypeFilter === true && "open"
													}`}
												>
													<h3>
														<a
															href="javascript:void(0);"
															onClick={() => showFilterDropDown("schoolType")}
														>
															{/* {`Gender ${
																filterBySchoolType.length > 0
																	? `(${filterBySchoolType.length})`
																	: ""
															}`}{" "} */}
															{`Gender ${
																filterBySchoolType.filter(
																	(type) => type !== "Custom"
																).length > 0
																	? `(${
																			filterBySchoolType.filter(
																				(type) => type !== "Custom"
																			).length
																	  })`
																	: ""
															}`}{" "}
															<img src={filterArrowSvg} />
														</a>
													</h3>
													<div className="filtercheckbox">
														<div
															className={`checkbox fnone ${
																parentData.data.brandDetail.targetGender ===
																"girls"
																	? "disabled-grey"
																	: ""
															}`}
														>
															<input
																type="checkbox"
																id="male"
																disabled={
																	parentData.data.brandDetail.targetGender ===
																	"girls"
																}
																checked={filterBySchoolType.includes("boys")}
																onClick={() =>
																	handleAddRemoveFilter("schoolType", "boys")
																}
															/>
															<label htmlFor="male">Boys</label>
														</div>

														<div
															className={`checkbox fnone ${
																parentData.data.brandDetail.targetGender ===
																"boys"
																	? "disabled-grey"
																	: ""
															}`}
														>
															<input
																type="checkbox"
																id="female"
																disabled={
																	parentData.data.brandDetail.targetGender ===
																	"boys"
																}
																checked={filterBySchoolType.includes("girls")}
																onClick={() =>
																	handleAddRemoveFilter("schoolType", "girls")
																}
															/>
															<label htmlFor="female">Girls</label>
														</div>

														<div className="checkbox fnone">
															<input
																type="checkbox"
																id="co-ed"
																checked={filterBySchoolType.includes("co-ed")}
																onClick={() =>
																	handleAddRemoveFilter("schoolType", "co-ed")
																}
															/>
															<label htmlFor="co-ed">Co-ed</label>
														</div>
													</div>
												</div>
												{/* <div
													className={`filterDropClose ${
														showBoardFilter === true && "open"
													}`}
												>
													<h3>
														<a
															href="javascript:void(0);"
															onClick={() => showFilterDropDown("board")}
														>
															{`Education Board ${
																filterByBoard.length > 0
																	? `(${filterByBoard.length})`
																	: ""
															}`}{" "}
															<img src={filterArrowSvg} />
														</a>
													</h3>
													<div className="filtercheckbox">
														{config.boardOptions
															.filter(
																(item) =>
																	item.value.toLowerCase() !== "custom" &&
																	item.value.toLowerCase() !== "other"
															)
															.map((item, index) => (
																<div className="checkbox fnone" key={index}>
																	<input
																		type="checkbox"
																		id={item.value}
																		checked={filterByBoard.includes(item.value)}
																		onClick={() =>
																			handleAddRemoveFilter("board", item.value)
																		}
																	/>
																	<label htmlFor={item.value}>
																		{item.label}
																	</label>
																</div>
															))}
													</div>
												</div> */}

												<div
													className={`filterDropClose ${
														showBoardFilter === true && "open"
													}`}
												>
													<h3>
														<a
															href="javascript:void(0);"
															onClick={() => showFilterDropDown("board")}
														>
															{`Education Board ${
																filterByBoard.length > 0
																	? `(${filterByBoard.length})`
																	: ""
															}`}{" "}
															<img src={filterArrowSvg} alt="Filter Arrow" />
														</a>
													</h3>
													<div className="filtercheckbox">
														{filterByBoardOptions
															// .sort()
															.map((boardValue, index) => (
																<div className="checkbox fnone" key={index}>
																	<input
																		type="checkbox"
																		id={String(boardValue)} // Explicitly convert to string
																		checked={filterByBoard.includes(boardValue)}
																		onClick={() =>
																			handleAddRemoveFilter("board", boardValue)
																		}
																	/>
																	<label
																		htmlFor={String(boardValue)}
																		style={{ textTransform: "uppercase" }}
																	>
																		{boardValue}
																	</label>
																</div>
															))}
													</div>
												</div>

												<div
													className={`filterDropClose ${
														showLanguageFilter === true && "open"
													}`}
												>
													<h3>
														<a
															href="javascript:void(0);"
															onClick={() => showFilterDropDown("language")}
														>
															{`Language of Study ${
																filterByLanguage.length > 0
																	? `(${filterByLanguage.length})`
																	: ""
															}`}{" "}
															<img src={filterArrowSvg} />
														</a>
													</h3>
													<div className="filtercheckbox">
														{parentData.data.brandDetail.studyMedium.map(
															(item, index) => (
																<div className="checkbox fnone" key={index}>
																	<input
																		type="checkbox"
																		id={item}
																		checked={filterByLanguage.includes(item)}
																		onClick={() =>
																			handleAddRemoveFilter("language", item)
																		}
																	/>
																	<label htmlFor={item}>{item}</label>
																</div>
															)
														)}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<SchoolTable
									tableEditMode={tableEditMode}
									setTableEditMode={setTableEditMode}
									allSchools={schoolList2}
									formData={formData}
									setFormData={setFormData}
									schoolList={paginationSchool}
									setSchoolList={setPaginationSchool}
									totalBudget={totalBudget}
									setTotalBudget={setTotalBudget}
									totalReach={totalReach}
									setTotalReach={setTotalReach}
									totalImpression={totalImpression}
									setTotalImpression={setTotalImpression}
									boysRatio={boysRatio}
									setBoysRatio={setBoysRatio}
									girlsRatio={girlsRatio}
									setGirlsRatio={setGirlsRatio}
									boysCount={boysCount}
									setBoysCount={setBoysCount}
									girlsCount={girlsCount}
									setGirlsCount={setGirlsCount}
									wisrOption={parentData.data.campaignDetail.wisrOption}
									currentPage={currentPage}
									totalPage={totalPage}
									onPageChange={onPageChange}
									onNextClick={onNextClick}
									onPrevClick={onPrevClick}
									pageLoading={pageLoading}
									dataPerPage={dataPerPage}
									showErrorMessage={showErrorMessage}
									setOverlay={setOverlay}
									schoolType={parentData.data.brandDetail.targetGender}
									setEventAndInventory={parentData.data.brandDetail}
								/>
								<div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 pl pr"></div>
								<div className="col-xl-5 col-lg-5 col-md-6 col-xs-12 delete-user mt-30">
									<a
										className="viewwLink"
										style={{ cursor: "pointer" }}
										onClick={openExit}
									>
										Save & Continue Later
									</a>
								</div>
								<div className="col-xl-7 col-lg-7 col-md-6 col-xs-12 end-md mt-30">
									<div className="forminpt">
										<input
											name="Save &amp; next"
											className="btn btn-orange"
											type="button"
											value="Save &amp; next"
											onClick={() => onSave(true, false)}
										/>
									</div>
								</div>
							</div>
						</div>
						<div className="col-xl-4 col-lg-4 col-md-12 col-xs-12 mb-20">
							<div className="w90">
								<div className="box-design mb-20 overheadThree">
									<h2 className="htx3 clearfix mb-10">Campaign Location</h2>
									{isScriptLoaded && isScriptLoadSucceed && (
										<MultiPointMap pincodeList={pincodeList} />
									)}
								</div>
								{!loadingFirstTime && (
									<>
										<CampaignBudget
											totalBudget={totalBudget}
											setTotalBudget={setTotalBudget}
											setOverlay={setOverlay}
											manuallyChangeBudget={manuallyChangeBudget}
											budgetExceeded={budgetExceeded}
											setPageLoading={setPageLoading}
											setEventAndInventory={parentData.data.brandDetail}
										/>
										<GenderSplit
											girlsRatio={girlsRatio}
											boysRatio={boysRatio}
										/>
										<CampaignReach
											totalReach={totalReach}
											setTotalReach={setTotalReach}
											manuallyChangeReach={manuallyChangeReach}
											disable={false}
											min={minReach === maxReach ? 0 : minReach}
											max={maxReach}
											setPageLoading={setPageLoading}
										/>
										<CampaignImpression
											totalImpression={totalImpression}
											setTotalImpression={setTotalImpression}
											manuallyChangeImpression={manuallyChangeImpression}
											disable={false}
											min={minImpression === maxImpression ? 0 : minImpression}
											max={maxImpression}
											setPageLoading={setPageLoading}
											setEventAndInventory={parentData.data.brandDetail}
										/>
									</>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
			<ConfirmDialog
				togglePopup={showExitPopup}
				onModalClose={onExit}
				componentName={""}
				confirmTitle="Do you want to save and exit?"
				isLoading={confirmDialogBtnLoading}
			/>
		</>
	);
};

// export default CampaignRecomendedSchool
export default scriptLoader([
	"https://maps.googleapis.com/maps/api/js?key=" + config.GOOGLE_MAPS_API_KEY,
])(CampaignRecomendedSchool);
